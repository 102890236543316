import React from 'react';
import { useDispatch } from 'react-redux';
import * as ApiTypes from '@rcp/types';
import { CountListWidget } from './count-list-widget';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { BarChartWidget } from './bar-chart-widget';
import { DonutChartWidget } from './donut-chart-widget';
import { dashboardWidgetSlice } from '../dashboard-widgets.slice';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CloseSvg } from 'src/components/svg';
import { useHistory } from 'react-router';
import { alertService } from 'src/redux';
import { chartService } from './chart-service';
import _ from 'lodash';
import { LineChartWidget } from './line-chart-widget';

interface OwnProps {
	dashboardWidget: ApiTypes.DashboardWidget;
	reload: () => void;
	widgetCount?: number;
}
type Props = OwnProps;

export const DashboardWidget: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [subtitle, setSubtitle] = React.useState<string>(props.dashboardWidget.subtitle || '');
	const [showDeleteModal, setShowDeleteModal] = React.useState(false as boolean);

	const updateSubtitleFromDateFilter = (dateFilterValue: string) => {
		let newSubtitle = chartService.getDateFilterDescription(dateFilterValue);
		if (newSubtitle) {
			setSubtitle(newSubtitle);
		}
	};

	const widgetChanged = (widget: ApiTypes.DashboardWidget) => {
		if (props.dashboardWidget.subtitle) {
			return;
		}
		if (widget.dateFilterValue) {
			updateSubtitleFromDateFilter(widget.dateFilterValue);
		}
	};

	React.useEffect(() => {
		if (!props.dashboardWidget.subtitle && props.dashboardWidget.dateFilterValue) {
			updateSubtitleFromDateFilter(props.dashboardWidget.dateFilterValue);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.dashboardWidget.subtitle]);

	const renderWidget = () => {
		switch (props.dashboardWidget.widgetType) {
			case ApiTypes.DashboardWidgetTypes.CountList:
				return <CountListWidget dashboardWidget={props.dashboardWidget}></CountListWidget>;
			case ApiTypes.DashboardWidgetTypes.BarChart:
				return (
					<BarChartWidget dashboardWidget={props.dashboardWidget} onchange={widgetChanged}></BarChartWidget>
				);
			case ApiTypes.DashboardWidgetTypes.DonutChart:
				return (
					<DonutChartWidget
						dashboardWidget={props.dashboardWidget}
						onchange={widgetChanged}
						isCustomWidget={
							!_.get(props.dashboardWidget, 'systemWidgetTemplateId') ? true : false
						}></DonutChartWidget>
				);
			case ApiTypes.DashboardWidgetTypes.LineChart:
				return (
					<LineChartWidget dashboardWidget={props.dashboardWidget} onchange={widgetChanged}></LineChartWidget>
				);
			default:
				return <p> coming soon</p>;
		}
	};

	let onEditLinkClicked = (e: any) => {
		const url = urlService.getReactAuthorityResourceUrl(
			Resource.Dashboard + '/editWidget',
			props.dashboardWidget.dashboardWidgetId
		);
		navigateTo(history, url, e);
	};

	let onShowDeleteModalToggle = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	let deleteDashboardWidget = () => {
		if (props.dashboardWidget.dashboardWidgetId) {
			dispatch(
				dashboardWidgetSlice.deleteOne(
					props.dashboardWidget.dashboardWidgetId,
					false,
					localizationService.getLocalizedString(
						'alertMessages.removeSuccess',
						props.dashboardWidget.title ? props.dashboardWidget.title : ''
					),
					() => {
						setShowDeleteModal(false);
						if (props.widgetCount === 1) {
							alertService.addInfo(
								localizationService.getLocalizedString('dashboard.widgetDetails.widgetsRestored')
							);
						}
						props.reload();
					}
				)
			);
		}
	};

	let duplicateDashboardWidget = () => {
		if (props.dashboardWidget.dashboardWidgetId) {
			const url = `${urlService.getAuthorityResourcesApiUrl(Resource.Dashboard)}/Duplicate/${
				props.dashboardWidget.dashboardWidgetId
			}`;
			apiService
				.getResource(url)
				.then(() => {
					props.reload();
					window.setTimeout(function() {
						alertService.addSuccess(
							localizationService.getLocalizedString(
								'alertMessages.addSuccess',
								localizationService.getLocalizedString('dashboard.dashboardWidget')
							)
						);
					}, 500);
				})
				.catch((e: any) => {
					alertService.addError(e.message);
				});
		}
	};

	return (
		<>
			<section>
				<div className="d-flex justify-content-between section-container">
					<h2>{props.dashboardWidget.title}</h2>
					<div className="epsillion-div">
						<button
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							className="font-size-18px-bold">
							&#8230;
						</button>

						<div className="dropdown-menu dropdown-menu-right">
							<button className="dropdown-item font-size-16px" onClick={onEditLinkClicked}>
								{localizationService.getLocalizedString('screen.buttons.edit')}
							</button>
							<button className="dropdown-item font-size-16px" onClick={duplicateDashboardWidget}>
								{localizationService.getLocalizedString('screen.buttons.duplicate')}
							</button>
							<button className="dropdown-item font-size-16px" onClick={onShowDeleteModalToggle}>
								{localizationService.getLocalizedString('screen.buttons.delete')}
							</button>
						</div>
					</div>
				</div>
				<small className="subtitle">{subtitle}</small>

				{renderWidget()}

				{showDeleteModal && (
					<Modal
						isOpen={true}
						toggle={onShowDeleteModalToggle}
						centered={true}
						backdrop={true}
						size="lg"
						id="deleteDashboardWidgetModal">
						<ModalHeader
							tag="h1"
							className="delete-modal-header"
							toggle={onShowDeleteModalToggle}
							close={
								<button className="close" onClick={onShowDeleteModalToggle}>
									<CloseSvg />
								</button>
							}>
							{localizationService.getLocalizedString('dashboard.deleteModalTitle')}
						</ModalHeader>
						<ModalBody>
							<div>
								<p>{localizationService.getLocalizedString('dashboard.deleteModalMessage')}</p>
							</div>
						</ModalBody>
						<ModalFooter>
							<button className="btn ai-delete ml-auto" id="deleteButton" onClick={deleteDashboardWidget}>
								{localizationService.getLocalizedString('screen.buttons.delete')}
							</button>
							<button className="btn ai-white" onClick={onShowDeleteModalToggle}>
								{localizationService.getLocalizedString('screen.buttons.cancel')}
							</button>
						</ModalFooter>
					</Modal>
				)}
			</section>
		</>
	);
};
