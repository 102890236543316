import {
	ApiError,
	AuthorityOrpSetting,
	DropDownOption,
	SettingType,
	DeviceCleaningFrequencySettingTypes,
	DeviceCleaningFrequencySettingFields
} from '@rcp/types';
import React from 'react';
import { apiService, localizationService, optionsMap, urlService, UtilService } from 'src/services';
import 'src/components/authority/shared/settings/authority-settings.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, loadAuthoritySettings, loadFrequencyList, RootState } from 'src/redux';
import { SingleSelectDropdown } from 'src/components/widgets';

const DeviceCleaningFrequencyComponent: React.FunctionComponent = () => {
	let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;
	const dispatch = useDispatch();

	const [testFrequencies, setTestFrequencies] = React.useState<DropDownOption[]>([]);
	const [authoritySetting, setAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const [originalAuthoritySetting, setOriginalAuthoritySetting] = React.useState(initialAuthorityOrpSetting);

	const reduxAuthoritySettings = useSelector((state: RootState) => state.authoritySettingLookups.authoritySettings);
	let cccTestFrequencies = useSelector((state: RootState) => state.extractors.frequencyList);

	React.useEffect(() => {
		dispatch(loadFrequencyList());
		dispatch(loadAuthoritySettings());
	}, [dispatch]);

	React.useEffect(() => {
		if (!_.isEmpty(cccTestFrequencies)) {
			setTestFrequencies(optionsMap.fromFrequencyList(cccTestFrequencies));
		}
	}, [cccTestFrequencies]);

	React.useEffect(() => {
		if (!_.isEmpty(reduxAuthoritySettings)) {
			let newAuthoritySetting = { ...authoritySetting };
			for (let settingValue of reduxAuthoritySettings) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				newAuthoritySetting[key] = settingValue.value;
			}
			if (
				(!newAuthoritySetting.deviceCleaningScheduleFrequencyId ||
					newAuthoritySetting.deviceCleaningScheduleFrequencyId === 0) &&
				cccTestFrequencies.length > 0
			) {
				newAuthoritySetting.deviceCleaningScheduleFrequencyId = cccTestFrequencies[0].frequencyId;
				setDefaultFrequency(cccTestFrequencies[0].frequencyId as number);
			}
			setAuthoritySetting(newAuthoritySetting);
			setOriginalAuthoritySetting(newAuthoritySetting);
		}
	}, [reduxAuthoritySettings, cccTestFrequencies]);

	const setDefaultFrequency = (frequencyId: number) => {
		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		let dataToUpdate = {
			settingType: SettingType.DeviceCleaningScheduleFrequencyId,
			value: frequencyId
		};
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
			});
	};

	const updateAuthoritySettingValue = (e: any) => {
		const { name, value } = e.target;

		let dataToUpdate = {
			settingType: name,
			value: value
		};
		if (originalAuthoritySetting[name] === value) {
			return;
		}

		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);

		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				let updateAuthoritySetting = { ...originalAuthoritySetting };
				updateAuthoritySetting[name] = value;
				setOriginalAuthoritySetting(updateAuthoritySetting);
				setAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};
	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{localizationService.getLocalizedString('authoritySetting.device.deviceCleaningTitle')}</strong>
				<p>{localizationService.getLocalizedString('authoritySetting.device.deviceCleaningDesc')}</p>
			</div>
			<div className="col-lg-8">
				<section>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input"
							name="deviceCleaningScheduleFrequencyOption"
							checked={String.equalCaseInsensitive(
								authoritySetting.deviceCleaningScheduleFrequencyOption,
								DeviceCleaningFrequencySettingTypes.None
							)}
							id="none"
							onChange={updateAuthoritySettingValue}
							value={DeviceCleaningFrequencySettingTypes.None}
						/>
						<label className="custom-control-label" htmlFor="none">
							<span>
								{localizationService.getLocalizedString('authoritySetting.device.doNotScheduleNext')}
								&nbsp;
								<strong>
									{localizationService
										.getLocalizedString('authoritySetting.device.cleaningEvent')
										.toLowerCase()}
								</strong>
							</span>
						</label>
					</div>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input"
							name="deviceCleaningScheduleFrequencyOption"
							checked={String.equalCaseInsensitive(
								authoritySetting.deviceCleaningScheduleFrequencyOption,
								DeviceCleaningFrequencySettingTypes.Device
							)}
							id="device"
							onChange={updateAuthoritySettingValue}
							value={DeviceCleaningFrequencySettingTypes.Device}
						/>
						<label className="custom-control-label" htmlFor="device">
							<span>
								{localizationService.getLocalizedString(
									'authoritySetting.device.deviceCleaningFrequency'
								)}
								&nbsp;
								<strong>
									{localizationService
										.getLocalizedString('authoritySetting.device.device')
										.toLowerCase()}
								</strong>
							</span>
						</label>
					</div>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input"
							name="deviceCleaningScheduleFrequencyOption"
							checked={String.equalCaseInsensitive(
								authoritySetting.deviceCleaningScheduleFrequencyOption,
								DeviceCleaningFrequencySettingTypes.Selected
							)}
							id="selected"
							onChange={updateAuthoritySettingValue}
							value={DeviceCleaningFrequencySettingTypes.Selected}
						/>
						<label className="custom-control-label" htmlFor="selected">
							<span>
								{localizationService.getLocalizedString(
									'authoritySetting.device.customDeviceCleaningFrequency'
								)}
							</span>
						</label>
					</div>
					{String.equalCaseInsensitive(
						authoritySetting.deviceCleaningScheduleFrequencyOption,
						DeviceCleaningFrequencySettingTypes.Selected
					) &&
						testFrequencies.length > 0 && (
							<SingleSelectDropdown
								id="deviceCleaningScheduleFrequencyId"
								name="deviceCleaningScheduleFrequencyId"
								className="pl-5"
								isRequired={true}
								label={localizationService.getLocalizedString('authoritySetting.frequency.frequency')}
								value={_.toString(authoritySetting.deviceCleaningScheduleFrequencyId)}
								onChange={updateAuthoritySettingValue}
								options={testFrequencies}
								selfOrder={true}
							/>
						)}
					{(String.equalCaseInsensitive(
						authoritySetting.deviceCleaningScheduleFrequencyOption,
						DeviceCleaningFrequencySettingTypes.Selected
					) ||
						String.equalCaseInsensitive(
							authoritySetting.deviceCleaningScheduleFrequencyOption,
							DeviceCleaningFrequencySettingTypes.Device
						)) && (
						<>
							<div className=" mt-2">
								<span>
									{localizationService.getLocalizedString('authoritySetting.test.scheduleNext')}
									&nbsp;
									<strong>
										{localizationService
											.getLocalizedString('authoritySetting.device.Cleaning')
											.toLowerCase()}
									</strong>
									&nbsp;
									{localizationService.getLocalizedString('authoritySetting.test.startingWith')}
								</span>
							</div>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									className="custom-control-input"
									name="deviceCleaningScheduleFrequencyField"
									checked={String.equalCaseInsensitive(
										authoritySetting.deviceCleaningScheduleFrequencyField,
										DeviceCleaningFrequencySettingFields.DueDate
									)}
									id="dueDate"
									onChange={updateAuthoritySettingValue}
									value={DeviceCleaningFrequencySettingFields.DueDate}
								/>
								<label className="custom-control-label" htmlFor="dueDate">
									<span>{localizationService.getLocalizedString('cccTest.dueDate')}</span>
								</label>
							</div>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									className="custom-control-input"
									name="deviceCleaningScheduleFrequencyField"
									checked={String.equalCaseInsensitive(
										authoritySetting.deviceCleaningScheduleFrequencyField,
										DeviceCleaningFrequencySettingFields.CompleteDate
									)}
									id="completeDate"
									onChange={updateAuthoritySettingValue}
									value={DeviceCleaningFrequencySettingFields.CompleteDate}
								/>
								<label className="custom-control-label" htmlFor="completeDate">
									<span>{localizationService.getLocalizedString('cccTest.completeDate')}</span>
								</label>
							</div>

							{String.equalCaseInsensitive(
								authoritySetting.deviceCleaningScheduleFrequencyField,
								DeviceCleaningFrequencySettingFields.DueDate
							) && (
								<div className="ai-form-help">
									{localizationService.getLocalizedString('cccTest.useCompleteDate')}
								</div>
							)}
						</>
					)}
				</section>
			</div>
		</div>
	);
};

export const DeviceCleaningFrequencySettingComponent = DeviceCleaningFrequencyComponent;
