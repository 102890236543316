import * as LinkoTypes from '@rcp/types';
import { History } from 'history';
import React, { FC } from 'react';
import { IppIndustryAttachments, ColumnField, FilterType, LocalStorageName, FilterObjectName } from '@rcp/types';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService, navigateTo, urlService } from 'src/services';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { IppConstants } from 'src/constants';
import { Utils } from 'src/services/utils';
import { ippAttachmentSlice } from './attachments-slice';

interface Props extends LinkoTypes.RouteProps {
	history: History;
}

const IppAttachments: FC<Props> = props => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppIndustryAttachments;
		const url = urlService.getIppIndustryUrl('attachment', dataItem.fileStoreId);
		return (
			<td>
				<a
					href={url}
					onClick={event => {
						navigateTo(props.history, url, event);
					}}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		showRefreshButton: true,
		prefix: 'ipp.attachments.columns',
		storageName: LocalStorageName.IppIndustryAttachmentGrid,
		customFieldQuery: '',
		searchHint: localizationService.getLocalizedString('ipp.reportPackage.package.fileStore.searchHint'),
		showActions: false,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.attachments.pageHeader'),
		sort: [],
		showEditColumnModal: false,
		filterOnlyThisGrid: true,
		showAddModal: false,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('ipp.buttons.createNew'),
		toggleAddModal: () => navigateTo(props.history, urlService.getIppIndustryUrl('attachment/new')),
		showScheduleInspectionModal: false,
		queryParameters: { page: IppConstants.queryParams.initialPage },
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppAttachmentGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPFILESTORE,
				FilterDomainName.ippFileStore,
				FilterObjectName.IppFileStore,
				LocalStorageName.IppAttachmentGridFilterUrl,
				{ IPPFILESTORE: true }
			);
		},
		allColumns: [
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('originalFileName'),
			new ColumnField('description'),
			new ColumnField('reportElementTypeName'),
			new ColumnField('uploadDateTimeUtc', FilterType.Date, Utils.formatDate),
			new ColumnField('uploaderUserFullName'),
			new ColumnField('attachmentStatus')
		],
		defaultColumns: [
			'name',
			'originalFileName',
			'description',
			'reportElementTypeName',
			'uploadDateTimeUtc',
			'uploaderUserFullName',
			'attachmentStatus'
		]
	};

	const attachmentsState = (state: RootState) => state.ippAttachments;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippAttachmentSlice}
				restState={attachmentsState}
				filterUrlStorageName={LocalStorageName.IppAttachmentGridFilterUrl}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppAttachments;
