import { faInfoCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useState } from 'react';
import { Tooltip } from 'reactstrap';
import './tooltip-hover.scss';

interface TooltipHoverProps {
	id: string;
	title: string;
	position?: Position;
	className?: string;
	icon?: IconDefinition;
	iconClassName?: string;
	iconFontClass?: string;
	isWithoutArrow?: boolean;
	onIconClick?: (e: any) => any;
	attachToDiv?: boolean;
	customSvg?: JSX.Element;
}

type Position = 'top' | 'right' | 'bottom' | 'left' | 'auto' | '';

const TooltipHover: FC<TooltipHoverProps> = (props: TooltipHoverProps) => {
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const toggle = () => setTooltipOpen(!tooltipOpen);
	return (
		<div className={props.className ? props.className : ''} id={props.attachToDiv ? props.id : ''}>
			{!props.customSvg && (
				<FontAwesomeIcon
					icon={props.icon ? props.icon : faInfoCircle}
					onClick={props.onIconClick}
					id={!props.attachToDiv ? props.id : ''}
					className={`${props.iconFontClass ? props.iconFontClass : 'font-size-20px-regular'} tooltip-icon ${
						props.iconClassName
					}`}
				/>
			)}

			{props.customSvg}
			<Tooltip
				className={props.isWithoutArrow ? 'custom-tooltip' : ''}
				placement={props.position ? props.position : 'top'}
				isOpen={tooltipOpen}
				target={props.id}
				container="body"
				toggle={toggle}
				offset={'0, 6'}>
				{props.title}
			</Tooltip>
		</div>
	);
};

export default TooltipHover;
