import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, FogFacilityState } from 'src/redux';
import './facility-details-address.scss';
import { localizationService, urlService, navigateTo, Resource } from 'src/services';
import { FacilityDetailsAddressModal } from './facility-details-address-modal';
import { ComplianceStatus, EmailAddress, FacilityExtractorDto, FogFacility, LetterTemplateCategory } from '@rcp/types';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { MapMarker } from './map-marker';
import { FacilitySendEmail } from '../send-email';
import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { FaCircle } from 'react-icons/fa';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

interface CompProps {
	withoutFacilityLink?: boolean;
	endOfPageTitle: string;
	isReadOnly?: boolean;
}

interface StateProps extends FogFacilityState {}

interface Props extends CompProps, StateProps {}

const FacilityAddressComp: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = React.useState(false);
	const [showEmailModal, setShowEmailModal] = React.useState(false);

	const history = useHistory();

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	const navigateTohard = (e: any, device: FacilityExtractorDto) => {
		let link = urlService.getReactAuthorityResourceUrl(Resource.Devices, device.ExtractorId);

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		navigateTo(history, link);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const getFacilityAddress = (facility: FogFacility) => {
		let { addressLine1, addressLine2 } = facility;
		return _.trim(_.toString(addressLine1) + ' ' + _.toString(addressLine2)).trim();
	};
	const getFacilityCityDetails = (facility: FogFacility) => {
		let { cityName, jurisdictionName, zipCode } = facility;
		return _.trim(_.toString(cityName) + ' ' + _.toString(jurisdictionName) + ' ' + _.toString(zipCode)).trim();
	};

	React.useEffect(() => {
		document.title = props.facility.facilityName + props.endOfPageTitle;
	}, [props.facility.facilityName, props.endOfPageTitle]);

	const getContactPersonEmailAddress = () => {
		const emailAddress = {
			name: props.facility.contactName || props.facility.facilityName,
			address: props.facility.email,
			selected: true
		};
		return emailAddress;
	};

	const getCompliantClasses = (complianceStatus: string) => {
		let complianceTextClass = 'compliance-pass';
		if (_.toLower(complianceStatus) === ComplianceStatus.Unknown.toLowerCase()) {
			complianceTextClass = 'compliance-unknown';
		}
		if (_.toLower(complianceStatus) === ComplianceStatus.NonCompliant.toLowerCase()) {
			complianceTextClass = 'compliance-failed';
		}
		if (props.facility.isOverrideComplianceStatus) {
			complianceTextClass = 'compliance-unknown';
		}
		return complianceTextClass;
	};

	const getContactNoticeList = () => {
		let devices: FacilityExtractorDto[] = [];
		if (props.facility.jsonExtractorList) {
			var extractorList: FacilityExtractorDto[] = JSON.parse(props.facility.jsonExtractorList);
			var extractorsForNotices = extractorList.filter(x => x.SendCleaningNoticeToDeviceOwner);
		}
		devices = _.sortBy(extractorsForNotices, 'DeviceNumber');
		return devices;
	};

	let address = props.facility.addressLine1;
	if (props.facility.addressLine2) {
		address = `${props.facility.addressLine1}, ${props.facility.addressLine2}`;
	}

	let complianceStatus = props.facility.complianceStatus as string;
	let complianceTextClass = getCompliantClasses(complianceStatus);

	let translationKey = `facility.${_.toUpper(complianceStatus).replace(/\s/g, '')}`;
	return _.isEmpty(props.facility) ? null : (
		<section className="address-card">
			<div className="d-flex flex-row">
				<div className="pr-2">
					<h2 id="facilityName">
						{props.withoutFacilityLink === false ? (
							props.facility.facilityName
						) : (
							<a
								href={urlService.getFacilityDetailsUrl(props.facility.facilityId)}
								onClick={(e: any) =>
									navigateTo(history, urlService.getFacilityDetailsUrl(props.facility.facilityId), e)
								}>
								{props.facility.facilityName}
							</a>
						)}
						<span id="facilityNumber" className="facility-number">
							{` ${props.facility.referenceNumber}`}
						</span>
						<span className={complianceTextClass}>{props.facility.complianceStatus}</span>
					</h2>
				</div>
				{!props.isReadOnly && (
					<div className="ml-auto d-print-none">
						<a href="#/" onClick={onEditClicked} id="linkEditFacility">
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					</div>
				)}
			</div>
			<div className="row">
				<div className="col mt-2">
					{address || props.facility.cityName || props.facility.jurisdictionName || props.facility.zipCode ? (
						<MapMarker
							streetAddress={getFacilityAddress(props.facility)}
							cityDetails={getFacilityCityDetails(props.facility)}
						/>
					) : (
						<div>{localizationService.getLocalizedString('screen.labels.noAddressYet')}</div>
					)}
				</div>
				<div className="col mt-2">
					<p>
						{props.facility.contactName && (
							<>
								<span id="facilityContactName">
									{localizationService.getLocalizedString('facility.contactName')}
									{': '}
									{props.facility.contactName}
								</span>
								<br />
							</>
						)}

						{props.facility.phone && (
							<>
								<span id="facilityPhone">
									{localizationService.getLocalizedString('facility.phone')}
								</span>
								{': '}
								<a href={'tel:' + props.facility.phone}>{props.facility.phone}</a>
								<br />
							</>
						)}
						{props.facility.email && (
							<>
								<span id="facilityEmail">
									{localizationService.getLocalizedString('facility.email')}
								</span>
								{': '}
								<a
									href={'mailto:' + props.facility.email}
									onClick={e => {
										e.preventDefault();
										setShowEmailModal(true);
									}}>
									{props.facility.email}
								</a>
							</>
						)}
						{!_.isEmpty(getContactNoticeList()) && (
							<div className="noticeList">
								{localizationService.getLocalizedString(
									'facility.receivingNotices',
									_.toString(getContactNoticeList().length)
								)}
								&nbsp;
								<UncontrolledDropdown tag="span" className="pr-1">
									<DropdownToggle tag="a" className="cursor-pointer">
										{localizationService.getLocalizedString(
											getContactNoticeList().length === 1
												? 'facility.receivedNoticeDevice'
												: 'facility.receivedNoticeDevices',
											_.toString(getContactNoticeList().length)
										)}
									</DropdownToggle>

									<DropdownMenu>
										{getContactNoticeList().map(device => (
											<>
												<DropdownItem
													id="deviceDescription"
													tag="a"
													className="cursor-pointer"
													onClick={e => navigateTohard(e, device)}>
													{device.DeviceNumber} {device.DeviceDescription}
												</DropdownItem>
											</>
										))}
									</DropdownMenu>
								</UncontrolledDropdown>
							</div>
						)}
					</p>
				</div>
			</div>

			{isToggle === true && (
				<FacilityDetailsAddressModal
					showModal={isToggle}
					setShowModal={toggle}
					isAddModal={false}
					fogFacility={props.facility}
				/>
			)}
			{showEmailModal && (
				<FacilitySendEmail
					show={true}
					toggle={() => {
						setShowEmailModal(false);
					}}
					letterTemplateCategory={LetterTemplateCategory.FacilityEmail}
					selectedEmail={[getContactPersonEmailAddress()]}
					facilityId={props.facility.facilityId}
				/>
			)}
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

export const FacilityAddress = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(FacilityAddressComp);
