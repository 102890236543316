import { DropDownOption, RegulatoryProgramName } from '@rcp/types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, ApplicationState } from 'src/redux';
import {
	fetchIppAASAuthoritySettingsActionCreator,
	IppAASAuthoritySettingsState,
	saveIppAASAuthoritySettingsActionCreator,
	toggleSaveButtonActionCreator,
	updateIppAASAuthoritySettingsActionCreator
} from '../../../../../redux/ipp/authority/authority-account/settings/authority-settings';
import { apiService, localizationService, optionsMap, tokenService, urlService } from 'src/services';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import CollapsibleCard from '../../../../widgets/collapsible-card/collapsible-card';

interface DispatchProps {
	fetchIppAASAuthoritySettings: (currentSetting: IppAASAuthoritySettingsState) => void;
	updateIppAASAuthoritySettings: (updatedAuthoritySettingsState: IppAASAuthoritySettingsState) => void;
	saveIppAASAuthoritySettings: (finalAuthoritySettingsState: IppAASAuthoritySettingsState) => void;
	toggleSaveButton: (finalAuthoritySettingsState: IppAASAuthoritySettingsState) => void;
}

interface StoreProps {
	ippAASAuthoritySettings: IppAASAuthoritySettingsState;
}

interface Props extends DispatchProps, StoreProps {}

interface State {
	timeZoneOptionValues: DropDownOption[];
}

class IppAASAuthoritySetting extends Component<Props, State> {
	private isSaveButtonClicked = false;
	constructor(props: Props) {
		super(props);
		this.state = { timeZoneOptionValues: [] };
	}

	public componentDidMount() {
		const timeZoneUrl = urlService.getTimeZoneResourceApiUrl();
		apiService
			.getResource(timeZoneUrl)
			.then((timeZones: any) => {
				let optionValues: DropDownOption[] = optionsMap.fromTimeZone(timeZones);
				this.setState({ timeZoneOptionValues: optionValues });
			})
			.catch(err => {
				alertService.addError(err.message);
			});

		this.props.fetchIppAASAuthoritySettings(this.props.ippAASAuthoritySettings);
	}

	public componentDidUpdate() {
		const { notification } = this.props.ippAASAuthoritySettings;
		if (this.isSaveButtonClicked && (notification as any).msg !== '') {
			(notification as any).type === 'success'
				? alertService.addSuccess((notification as any).msg)
				: alertService.addError((notification as any).msg);
			this.isSaveButtonClicked = false;
		}
	}

	public render() {
		const authoritySettingFormKey = 'ipp.authorityAccountSetting.authoritySettingsForm';
		const authoritySettingFormFieldsKey = `${authoritySettingFormKey}.fields`;
		const ippAASAuthoritySettingsData = this.props.ippAASAuthoritySettings.data;
		const enabledSaveBtnClass = 'btn ai-save mb-2';
		const disabledSaveBtnClass = 'btn btn-secondary mb-2 disabled';
		const isAdmin = tokenService.isAdminToken(RegulatoryProgramName.IPP);
		const disableWhenNotAdmin = isAdmin ? {} : { isDisabled: true };

		return (
			<React.Fragment>
				<CollapsibleCard
					accordionHeading={localizationService.getLocalizedString(`${authoritySettingFormKey}.heading`)}
					accordionType={'ipp-aas-authority-settings-accordion'}
					className={'mt-2'}>
					<div className="col-md-8 ml-auto mr-auto">
						<div className="form-row">
							<TextInput
								id="ipp-aas-failed-password-attempts"
								name="failed-password-attempts"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.allowedFailedPasswordAttempts.label`
								)}
								value={`${ippAASAuthoritySettingsData.allowedFailedPasswordAttempts}`}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={
									isAdmin ? this.fieldsChangeHandler('allowedFailedPasswordAttempts') : () => {}
								}
								remainingInputProps={{ min: 1 }}
								type={'number'}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-failed-kbq-attempts"
								name="failed-kbq-attempts"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.allowedFailedKBQAttempts.label`
								)}
								value={`${ippAASAuthoritySettingsData.allowedFailedKBQAttempts}`}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={isAdmin ? this.fieldsChangeHandler('allowedFailedKBQAttempts') : () => {}}
								remainingInputProps={{ min: 1 }}
								type={'number'}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-invitation-expiry-hrs"
								name="invitation-expiry-hrs"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.invitationExpiryHours.label`
								)}
								value={`${ippAASAuthoritySettingsData.invitationExpiryHrsAfterSending}`}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={
									isAdmin ? this.fieldsChangeHandler('invitationExpiryHrsAfterSending') : () => {}
								}
								remainingInputProps={{ min: 1 }}
								type={'number'}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-password-change-days"
								name="password-change-days"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.passwordChangeDays.label`
								)}
								value={`${ippAASAuthoritySettingsData.daysBeforePasswordChange}`}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={isAdmin ? this.fieldsChangeHandler('daysBeforePasswordChange') : () => {}}
								remainingInputProps={{ min: 1 }}
								type={'number'}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-password-in-history"
								name="password-in-history"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.passwordCountInPasswordHistory.label`
								)}
								value={`${ippAASAuthoritySettingsData.passwordsCountInPasswordHistory}`}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={
									isAdmin ? this.fieldsChangeHandler('passwordsCountInPasswordHistory') : () => {}
								}
								remainingInputProps={{ min: 1 }}
								type={'number'}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<SingleSelectDropdown
								selfOrder={true}
								id="ipp-aas-timezone"
								name="timezone"
								className="col-sm-12 p-2 px-5px"
								label={localizationService.getLocalizedString(
									`${authoritySettingFormFieldsKey}.timeZone.label`
								)}
								value={`${ippAASAuthoritySettingsData.timezone}`}
								onChange={isAdmin ? this.fieldsChangeHandler('timezone') : () => {}}
								options={this.state.timeZoneOptionValues}
								showEmptyOption={false}
								{...disableWhenNotAdmin}
							/>
						</div>
						{isAdmin ? (
							<div className="form-row">
								<div className="ml-auto">
									<button
										className={`mr-1 ${enabledSaveBtnClass} ${
											!this.props.ippAASAuthoritySettings.isSaveButtonEnabled ? 'd-none' : ''
										}`}
										id="ipp-aas-btn-save-authority-setting"
										onClick={this.saveButtonHandler}>
										{localizationService.getLocalizedString(
											`${authoritySettingFormKey}.submitBtn.label`
										)}
									</button>
									<button
										className={`mr-1 ${disabledSaveBtnClass} ${
											!this.props.ippAASAuthoritySettings.isSaveButtonEnabled ? '' : 'd-none'
										}`}
										id="ipp-aas-btn-save-authority-setting-disabled"
										disabled={true}
										onClick={() => {}}>
										{localizationService.getLocalizedString(
											`${authoritySettingFormKey}.submitBtn.label`
										)}
									</button>
								</div>
							</div>
						) : (
							<React.Fragment />
						)}
					</div>
				</CollapsibleCard>
			</React.Fragment>
		);
	}

	private fieldsChangeHandler = (fieldStateName: string) => {
		const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const updatedValue = event.target.value;
			const ippAASAuthoritySettingsData = this.props.ippAASAuthoritySettings.data;
			const updatedAuthoritySettings = {
				...this.props.ippAASAuthoritySettings,
				data: { ...ippAASAuthoritySettingsData, [fieldStateName]: updatedValue }
			};
			this.props.updateIppAASAuthoritySettings(updatedAuthoritySettings);
		};
		return changeHandler;
	};

	private saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		this.isSaveButtonClicked = true;
		const { ippAASAuthoritySettings, saveIppAASAuthoritySettings } = this.props;
		saveIppAASAuthoritySettings(ippAASAuthoritySettings);
	};
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
	return {
		ippAASAuthoritySettings: state.ippAASAuthoritySettings
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		fetchIppAASAuthoritySettings: (currentSetting: IppAASAuthoritySettingsState) => {
			dispatch(fetchIppAASAuthoritySettingsActionCreator(currentSetting));
		},
		updateIppAASAuthoritySettings: (updatedAuthoritySettingsState: IppAASAuthoritySettingsState) => {
			dispatch(updateIppAASAuthoritySettingsActionCreator(updatedAuthoritySettingsState));
		},
		saveIppAASAuthoritySettings: (finalAuthoritySettingsState: IppAASAuthoritySettingsState) => {
			dispatch(saveIppAASAuthoritySettingsActionCreator(finalAuthoritySettingsState));
		},
		toggleSaveButton: (finalAuthoritySettingsState: IppAASAuthoritySettingsState) => {
			dispatch(toggleSaveButtonActionCreator(finalAuthoritySettingsState));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppAASAuthoritySetting);
