import _ from 'lodash';
import {
	ApiFilterDefinition,
	ApiFilterGroup,
	ApiFilterProperty,
	DraftFilter,
	Filter,
	FilterObjectName,
	FilterOperator,
	FilterType,
	FogFacility,
	InspectionEvent,
	TreeViewDataItem
} from '@rcp/types';
import {
	timeframeCompleteValueOptionsForQuickFilter,
	timeframeDueValueOptionsForQuickFilter,
	TimeframeField,
	TimeframeType,
	TimeframeValue
} from 'src/features/filter/filter-row-date-is';
import { apiService, localizationService, Logger, Resource, urlService } from 'src/services';
import { nameof } from 'ts-simple-nameof';
import { FilterItem, FilterValidationBody } from 'src/services/data-types/filter-types';
import { MappingFromApiFormat, MappingToApiFormat } from 'src/redux/filter/filterSerializer';
import { filterService } from './filter-service';
import { IsFilterNameValid } from 'src/redux';

export enum FacilityQuickFilterEnum {
	Risk = 'Risk',
	Compliance = 'Compliance',
	Devices = 'Devices',
	InspectionTimeFrame = 'InspectionTimeFrame',
	Inspector = 'Inspector',
	FacilityActive = 'FacilityActive'
}

export enum IncidentQuickFilterEnum {
	Type = 'Type',
	Status = 'Status'
}

export interface QuickFilterProperties {
	filterApplyTo: string;
	filterName: string;
	apiFilterDefinition: ApiFilterDefinition;
	isFilterInvalid: boolean;
	draftFilterId?: number;
}

export interface FilterItemsToList {
	filterItem: FilterItem;
	isEdit: boolean;
}
export interface SavedFilter {
	filterId: number;
	filterName: string;
}

export const getInitialQuickFilterProperties = (filterApplyTo: string): QuickFilterProperties => {
	return {
		filterApplyTo: filterApplyTo,
		filterName: '',
		apiFilterDefinition: MappingToApiFormat([] as FilterItem[]),
		isFilterInvalid: false
	};
};

export const addFilterPropertiesToExistingFilterDefinition = async (
	newQuickFilterProperties: QuickFilterProperties,
	filterPropertiesToAdd: ApiFilterProperty[]
) => {
	let filterProperties = newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties;
	filterProperties = filterProperties.concat(filterPropertiesToAdd);
	let uniqueFilterProperties = _.uniqWith(filterProperties, _.isEqual);
	newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties = uniqueFilterProperties;

	await createOrUpdateDraftFilter(newQuickFilterProperties);
};

export const getApiFilterDefinition = async (
	newQuickFilterProperties: QuickFilterProperties,
	filterId: number | undefined,
	draftFilterId: number | undefined
) => {
	if (filterId) {
		const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
		const filter = await apiService.getResource<Filter>(`${filterUrl}/${filterId}`);
		if (!_.isEmpty(filter.filterDefinition) && filter.filterDefinition) {
			return JSON.parse(filter.filterDefinition) as ApiFilterDefinition;
		}
	} else if (draftFilterId) {
		const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
		const draftFilter = await apiService.getResource<DraftFilter>(`${draftFilterUrl}/${draftFilterId}`);
		if (!_.isEmpty(draftFilter.filterDefinition) && draftFilter.filterDefinition) {
			newQuickFilterProperties.filterName = draftFilter.filterName;
			return JSON.parse(draftFilter.filterDefinition) as ApiFilterDefinition;
		}
	}
	return {
		filterGroups: [
			{
				filterProperties: [],
				logicalOperator: 'and'
			}
		]
	} as ApiFilterDefinition;
};

export const applySavedFilter = async (
	newQuickFilterProperties: QuickFilterProperties,
	populateQuickFilterSelectedIdsFromExistingFilter: (newQuickFilterProperties: QuickFilterProperties) => void,
	filterId: number,
	filterName: string
) => {
	let savedApiFilterDefinition: ApiFilterDefinition = await getApiFilterDefinition(
		newQuickFilterProperties,
		filterId,
		undefined
	);
	if (savedApiFilterDefinition.filterGroups[0].filterProperties) {
		if (!newQuickFilterProperties.draftFilterId && filterName) {
			newQuickFilterProperties.filterName = filterName;
		}

		await addFilterPropertiesToExistingFilterDefinition(
			newQuickFilterProperties,
			savedApiFilterDefinition.filterGroups[0].filterProperties
		);
		populateQuickFilterSelectedIdsFromExistingFilter(newQuickFilterProperties);
	}
};

export const digestQuickFilterAndCreateOrUpdateDraft = async (
	newQuickFilterProperties: QuickFilterProperties,
	populateQuickFilterSelectedIdsFromExistingFilter: (newQuickFilterProperties: QuickFilterProperties) => void,
	checkedFilterProperties: ApiFilterProperty[],
	uncheckedFilterProperties?: ApiFilterProperty[],
	matchValuesToFindIndexOfFilterProperty = false,
	filterNumber?: FacilityQuickFilterEnum | IncidentQuickFilterEnum
) => {
	digestQuickFilterCheckedFilterProperties(
		newQuickFilterProperties.apiFilterDefinition,
		checkedFilterProperties,
		uncheckedFilterProperties,
		matchValuesToFindIndexOfFilterProperty
	);

	if (Logger.isDevelopmentEnvironment()) {
		if (filterNumber) {
			Logger.info(
				`Saving filter '${newQuickFilterProperties.filterName}' for QuickFilterGroup ${filterNumber} changes:`,
				JSON.stringify(newQuickFilterProperties.apiFilterDefinition)
			);
		} else {
			Logger.info(
				`Saving filter '${newQuickFilterProperties.filterName}':`,
				JSON.stringify(newQuickFilterProperties.apiFilterDefinition)
			);
		}
	}
	let uniqueFilterProperties = _.uniqWith(
		newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
		_.isEqual
	);
	newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties = uniqueFilterProperties;
	await createOrUpdateDraftFilter(newQuickFilterProperties);
	populateQuickFilterSelectedIdsFromExistingFilter(newQuickFilterProperties);
};

export const createOrUpdateDraftFilter = async (newQuickFilterProperties: QuickFilterProperties) => {
	let isFilterNameValid = true;
	if (newQuickFilterProperties.filterName) {
		let filterToValid = new FilterValidationBody(
			newQuickFilterProperties.filterName,
			true,
			undefined,
			newQuickFilterProperties.draftFilterId,
			newQuickFilterProperties.filterApplyTo
		);
		isFilterNameValid = await IsFilterNameValid(filterToValid);
	}
	if (String.isUndefinedOrEmpty(newQuickFilterProperties.filterName) || !isFilterNameValid) {
		let filterName = await filterService.getNextFilterName();
		newQuickFilterProperties.filterName = filterName;
	}

	let draftFilterToSent: DraftFilter = {
		applyTo: newQuickFilterProperties.filterApplyTo,
		filterName: newQuickFilterProperties.filterName,
		filterDefinition: JSON.stringify(newQuickFilterProperties.apiFilterDefinition)
	};
	const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
	let draftFilterSaved: DraftFilter | undefined;
	if (!newQuickFilterProperties.draftFilterId) {
		draftFilterSaved = await apiService.postResource<DraftFilter>(draftFilterUrl, draftFilterToSent);
	} else {
		//validate if still draft filter exists
		const draftFilter = await apiService.getResource<DraftFilter>(
			`${draftFilterUrl}/${newQuickFilterProperties.draftFilterId}`
		);
		if (!_.isEmpty(draftFilter.filterDefinition) && draftFilter.filterDefinition) {
			draftFilterSaved = await apiService.patchResource<DraftFilter>(
				`${draftFilterUrl}/${newQuickFilterProperties.draftFilterId}`,
				draftFilterToSent
			);
		} else {
			draftFilterSaved = await apiService.postResource<DraftFilter>(draftFilterUrl, draftFilterToSent);
		}
	}

	draftFilterSaved.filterDefinition = MappingFromApiFormat(
		draftFilterSaved.filterDefinition ? draftFilterSaved.filterDefinition : ''
	);

	newQuickFilterProperties.draftFilterId = draftFilterSaved.draftFilterId;
};

export const getSelectedValuesFromFilterProperty = (
	filterPropertiesFromFilterDefinition: ApiFilterProperty[],
	filterToFind: ApiFilterProperty
) => {
	let index: number = -1;
	filterPropertiesFromFilterDefinition.forEach((item, i) => {
		if (areFiltersSame(item, filterToFind)) {
			index = i;
			return;
		}
	});

	if (index < 0) {
		return [] as any[];
	} else {
		return filterPropertiesFromFilterDefinition[index].values;
	}
};

const passTimeframeValues = (item: TreeViewDataItem, filterProperty: ApiFilterProperty) => {
	if (String.equalCI(item.type, TimeframeType)) {
		if (String.equalCI(TimeframeField.Due, item.value) || String.equalCI(TimeframeField.Completed, item.value)) {
			let values = filterProperty.values;
			if (values.length) {
				values[0] = item.selectedTimeframeValue;
			} else {
				values.push(item.selectedTimeframeValue);
			}
		}
	}
};

export const areFiltersSame = (
	left: ApiFilterProperty,
	right: ApiFilterProperty,
	matchValuesToMakeFilterSame = false
): boolean => {
	let isSame =
		String.equalCI(left.objectName, right.objectName) &&
		String.equalCI(left.propertyName, right.propertyName) &&
		String.equalCI(left.operator, right.operator);
	if (matchValuesToMakeFilterSame && isSame) {
		isSame = _.isEqual(left.values, right.values);
	}
	return isSame;
};

export const findIndexOfFilterProperty = (
	fromFilterGroup: ApiFilterGroup,
	filter: ApiFilterProperty,
	matchValuesToFindIndexOfFilterProperty = false
): number => {
	for (let i = 0; i < fromFilterGroup.filterProperties.length; i++) {
		if (areFiltersSame(fromFilterGroup.filterProperties[i], filter, matchValuesToFindIndexOfFilterProperty)) {
			return i;
		}
	}
	return -1;
};

export const findFilterProperty = (
	fromFilterGroup: ApiFilterGroup,
	filter: ApiFilterProperty
): ApiFilterProperty | undefined => {
	let index = findIndexOfFilterProperty(fromFilterGroup, filter);
	if (index >= 0) {
		return fromFilterGroup.filterProperties[index];
	}
	return undefined;
};

export const hasAllFilterProperties = (
	fromFilterGroup: ApiFilterGroup,
	filters: ApiFilterProperty[] | undefined
): boolean => {
	if (!filters) {
		return false;
	}
	for (let i = 0; i < filters.length; i++) {
		let foundFilterItem = findFilterProperty(fromFilterGroup, filters[i]);
		if (!foundFilterItem) {
			return false;
		}
	}
	return true;
};

export interface QuickFilterGroup {
	filterNumber: FacilityQuickFilterEnum;
	filterOptions: TreeViewDataItem[];
	getCheckedFilterOptions: (
		checkedValues: string[],
		parentNodes: TreeViewDataItem[]
	) => { checkedFilterProperties: ApiFilterProperty[]; uncheckedFilterProperties: ApiFilterProperty[] };
	getSelectedIds: (filterDefinition: ApiFilterDefinition) => (string | null)[];
}

const dueInspectionFilterProperties: ApiFilterProperty[] = [
	{
		objectName: FilterObjectName.Inspection,
		propertyName: nameof<InspectionEvent>(s => s.dueDate),
		propertyType: FilterType.Date,
		operator: FilterOperator.Is,
		values: [TimeframeValue.ThisMonth, null]
	},
	{
		objectName: FilterObjectName.Inspection,
		propertyName: nameof<InspectionEvent>(s => s.completeDate),
		propertyType: FilterType.Date,
		operator: FilterOperator.Is,
		values: [TimeframeValue.Empty, null]
	}
];
class InspectionTimeframeQuickFilterGroup implements QuickFilterGroup {
	filterNumber: FacilityQuickFilterEnum = FacilityQuickFilterEnum.InspectionTimeFrame;
	filterOptions: TreeViewDataItem[] = [
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('map.due'),
					value: TimeframeField.Due,
					type: TimeframeType,
					selectedTimeframeValue: TimeframeValue.ThisMonth,
					filterProperties: dueInspectionFilterProperties
				},
				{
					text: localizationService.getLocalizedString('map.pastDue'),
					value: 'pastDue',
					filterProperties: [
						{
							objectName: FilterObjectName.Facility,
							propertyName: nameof<FogFacility>(s => s.nextInspectionDueDate),
							propertyType: FilterType.Date,
							operator: FilterOperator.LessThan,
							values: [TimeframeValue.Today, null]
						}
					]
				},
				{
					text: localizationService.getLocalizedString('map.neverInspected'),
					value: 'neverInspected',
					filterProperties: [
						{
							objectName: FilterObjectName.Facility,
							propertyName: nameof<FogFacility>(s => s.lastInspectionCompleteDate),
							propertyType: FilterType.Date,
							operator: FilterOperator.Is,
							values: [TimeframeValue.Empty, null]
						}
					]
				},
				{
					text: localizationService.getLocalizedString('map.completed'),
					value: TimeframeField.Completed,
					type: TimeframeType,
					selectedTimeframeValue: TimeframeValue.ThisMonth,
					filterProperties: [
						{
							objectName: FilterObjectName.Inspection,
							propertyName: nameof<InspectionEvent>(s => s.completeDate),
							propertyType: FilterType.Date,
							operator: FilterOperator.Is,
							values: [TimeframeValue.ThisMonth, null]
						}
					]
				}
			]
		}
	];

	getCheckedFilterOptions(checkedValues: string[], parentNodes: TreeViewDataItem[], initialSelectedIds?: any[]) {
		let checkedFilterProperties: ApiFilterProperty[] = [];
		let uncheckedFilterProperties: ApiFilterProperty[] = [];
		let self = this;
		_.forEach(parentNodes, (groupNode: TreeViewDataItem) => {
			_.forEach(groupNode.items, (childItemNode: TreeViewDataItem) => {
				let itemFilterProperties = childItemNode.filterProperties as ApiFilterProperty[];
				if (_.isEmpty(itemFilterProperties)) {
					Logger.warn(
						`Quick filter filterProperties from group ${self.filterNumber} item ${childItemNode.value} is empty.`
					);
					return;
				}
				let isChildItemChecked = _.includes(checkedValues, childItemNode.value);
				_.forEach(itemFilterProperties, (p, index) => {
					let filterProperty = { ...p };
					if (_.includes(initialSelectedIds, childItemNode.value)) {
						uncheckedFilterProperties.push(filterProperty);
					}
				});
				_.forEach(itemFilterProperties, (p, index) => {
					let filterProperty = { ...p };
					if (index === 0) {
						//Base on quick filter definition, only need to pass timeframe dropdown value to first item FilterProperty
						passTimeframeValues(childItemNode, filterProperty);
					}
					if (isChildItemChecked) {
						checkedFilterProperties.push(filterProperty);
					}
				});
			});
		});
		if (Logger.isDevelopmentEnvironment()) {
			Logger.info(
				`Quick filter ${self.filterNumber} checkedFilterProperties:`,
				JSON.stringify(checkedValues),
				JSON.stringify(checkedFilterProperties),
				JSON.stringify(uncheckedFilterProperties)
			);
		}
		return { checkedFilterProperties, uncheckedFilterProperties };
	}

	getSelectedIds(filterDefinition: ApiFilterDefinition): (string | null)[] {
		let selectedIds: (string | null)[] = [];
		let filterGroup = filterDefinition.filterGroups[0];
		let self = this;
		_.forEach(self.filterOptions[0].items, item => {
			if (item.filterProperties) {
				let firstFilterItem = findFilterProperty(filterGroup, item.filterProperties[0]);
				if (firstFilterItem) {
					if (item.filterProperties.length === 1) {
						if (String.equalCI(item.value, TimeframeField.Completed)) {
							let selectedTimeframeItem = timeframeCompleteValueOptionsForQuickFilter.filter(x => {
								return String.equalCI(x.value, firstFilterItem?.values[0]);
							});

							if (selectedTimeframeItem.length == 1) {
								selectedIds.push(item.value as string);
							}
						} else if (findIndexOfFilterProperty(filterGroup, item.filterProperties[0], true) >= 0) {
							selectedIds.push(item.value as string);
						}
					} else if (item.filterProperties.length === 2 && String.equalCI(item.value, TimeframeField.Due)) {
						//due inspection timeframe option
						let secondFilterItem = findFilterProperty(filterGroup, item.filterProperties[1]);
						if (
							secondFilterItem &&
							areFiltersSame(firstFilterItem, dueInspectionFilterProperties[0]) &&
							areFiltersSame(secondFilterItem, dueInspectionFilterProperties[1], true)
						) {
							let selectedTimeframeItem = timeframeDueValueOptionsForQuickFilter.filter(x => {
								return String.equalCI(x.value, firstFilterItem?.values[0]);
							});

							if (selectedTimeframeItem.length == 1) {
								selectedIds.push(item.value as string);
							}
						}
					}
				}
			}
		});

		if (Logger.isDevelopmentEnvironment()) {
			Logger.info(`Quick filter ${self.filterNumber} selectedIds:`, JSON.stringify(selectedIds));
		}
		return selectedIds;
	}
	getPopulatedFilterOptionsWithValueSelection(filterDefinition: ApiFilterDefinition): TreeViewDataItem[] {
		let filterGroup = filterDefinition.filterGroups[0];
		let self = this;
		let newFilterOptions = _.cloneDeep(self.filterOptions);
		_.forEach(newFilterOptions[0].items, item => {
			if (String.equalCI(TimeframeType, item.type)) {
				if (item.filterProperties) {
					let firstFilterItem = findFilterProperty(filterGroup, item.filterProperties[0]);
					if (firstFilterItem) {
						if (String.equalCI(item.value, TimeframeField.Completed)) {
							let selectedTimeframeItem = timeframeCompleteValueOptionsForQuickFilter.filter(x => {
								return String.equalCI(x.value, firstFilterItem?.values[0]);
							});

							if (selectedTimeframeItem.length == 1) {
								item.selectedTimeframeValue = firstFilterItem.values[0];
								item.filterProperties[0].values[0] = firstFilterItem.values[0];
							}
						} else if (String.equalCI(item.value, TimeframeField.Due)) {
							let selectedTimeframeItem = timeframeDueValueOptionsForQuickFilter.filter(x => {
								return String.equalCI(x.value, firstFilterItem?.values[0]);
							});

							if (selectedTimeframeItem.length == 1) {
								item.selectedTimeframeValue = firstFilterItem.values[0];
								item.filterProperties[0].values[0] = firstFilterItem.values[0];
							}
						} else {
							item.selectedTimeframeValue = firstFilterItem.values[0];
							item.filterProperties[0].values[0] = firstFilterItem.values[0];
						}
					}
				}
			}
		});
		return newFilterOptions;
	}
}

export const inspectionTimeframeQuickFilterGroup = new InspectionTimeframeQuickFilterGroup();

export const digestQuickFilterCheckedFilterProperties = (
	filterDefinition: ApiFilterDefinition,
	checkedFilterProperties: ApiFilterProperty[],
	uncheckedFilterProperties?: ApiFilterProperty[],
	matchValuesToFindIndexOfFilterProperty = false
) => {
	let filterGroup = filterDefinition.filterGroups[0];
	if (uncheckedFilterProperties) {
		_.forEach(uncheckedFilterProperties, (uncheckedFilter: ApiFilterProperty) => {
			let index: number = findIndexOfFilterProperty(
				filterGroup,
				uncheckedFilter,
				matchValuesToFindIndexOfFilterProperty
			);
			if (index > -1) {
				filterGroup.filterProperties.splice(index, 1);
			}
		});
	}
	_.forEach(checkedFilterProperties, (checkedFilter: ApiFilterProperty) => {
		let index: number = findIndexOfFilterProperty(
			filterGroup,
			checkedFilter,
			matchValuesToFindIndexOfFilterProperty
		);
		if (index < 0) {
			if (checkedFilter.values.length) {
				filterGroup.filterProperties.push(checkedFilter);
			}
		} else {
			filterGroup.filterProperties[index] = checkedFilter;
		}
	});
};
