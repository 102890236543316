import React from 'react';
import { localizationService, navigateTo } from 'src/services';
import IppApplicationUserSettings from './application-user-settings';
import IppApiSyncUserSettings from './api-sync-user-settings';
import { useEffect } from 'react';
import { alertService, useReduxSelector } from 'src/redux';
import { History } from 'history';

export const IppAuthorityACPConnector: React.FC<{ history: History }> = props => {
	const userProfile = useReduxSelector(state => state.userProfile.userProfile);

	useEffect(() => {
		!userProfile.isInternalAccount && navigateTo(props.history, '/ipp/authority/industries');
	}, [userProfile.isInternalAccount]);

	const copyToClipBoard = (event: any, textToBeCopied: string) => {
		event.preventDefault();
		let textToCopy = textToBeCopied;
		let textArea = document.createElement('textarea');
		document.body.appendChild(textArea);
		textArea.value = textToCopy || '';
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
		alertService.addSuccess(
			localizationService.getLocalizedString('ipp.authorityAcpConnector.applicationUserSettings.copySuccess')
		);
	};

	return (
		<div className="page settings">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('ipp.authorityAcpConnector.pageHeading')}</h1>
			</div>
			<div>
				<IppApplicationUserSettings copyToClipBoard={copyToClipBoard} />
				<hr />
				<IppApiSyncUserSettings copyToClipBoard={copyToClipBoard} />
			</div>
		</div>
	);
};
