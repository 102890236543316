import React, { Component } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as actionCreators from 'src/redux/equipment';
import { EquipmentCard } from './equipment-card';
import { EquipmentEditModal } from './equipment-editor-modal';
import { ApplicationState, FacilityEquipmentsState } from 'src/redux';

import * as ApiTypes from '@rcp/types';
import { localizationService } from 'src/services';
import { EquipmentType } from '@rcp/types';
import _ from 'lodash';
import './equipment-tab.scss';

interface StateProps extends FacilityEquipmentsState {}

interface DispatchProps {
	loadAuthorityEquipmentTypes: () => void;
	loadFacilityEquipments: () => void;
	updateFacilityEquipment: (equipmentId: number, dataToUpdate: ApiTypes.Equipment) => Promise<boolean | undefined>;
	deleteFacilityEquipment: (equipmentId: number, equipmentType: string) => Promise<boolean | undefined>;
	createFacilityEquipment: (equipmentToCreate: ApiTypes.Equipment) => Promise<boolean | undefined>;
}

type Props = StateProps & DispatchProps;

class EquipmentTabPageComp extends Component<Props, any> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showAddEquipmentModal: false
		};
	}

	componentDidMount() {
		this.ensureDataFetched();
	}

	ensureDataFetched() {
		this.props.loadAuthorityEquipmentTypes();
		this.props.loadFacilityEquipments();
	}

	onToggleShowAddEquipmentModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({
			showAddEquipmentModal: !this.state.showAddEquipmentModal
		});
	};

	addEquipment = async (equipmentToCreate: ApiTypes.Equipment) => {
		await this.props.createFacilityEquipment(equipmentToCreate);
	};

	equipmentTypeSort = (i: EquipmentType, j: EquipmentType) => {
		if (String.equalCaseInsensitive(i.equipmentType, j.equipmentType)) return 0;
		if (i.equipmentType > j.equipmentType) return 1;
		return -1;
	};

	render() {
		let equipmentToAdd: ApiTypes.Equipment = {
			equipmentId: -1,
			facilityId: this.state.fogFacilityId,
			equipmentTypeId: -1,
			equipmentType: '',
			equipmentComments: '',
			equipmentQuantity: 0,
			fogFactor: undefined,
			plumbedToGreaseInterceptor: false
		};

		let equipmentTypes = this.props.equipmentTypes || [];
		equipmentTypes.sort(this.equipmentTypeSort);

		let equipmentTypesIdUsed = this.props.equipments.map(i => i.equipmentTypeId as number);
		let validEquipmentTypes = equipmentTypes.filter(i => !_.includes(equipmentTypesIdUsed, i.equipmentTypeId));

		return (
			<>
				<div className="equipment-container">
					{this.props.equipments.map((item: ApiTypes.Equipment, index: number) => {
						let equipmentTypesForEdit = ([] as EquipmentType[]).concat(validEquipmentTypes);
						equipmentTypesForEdit.push({
							equipmentType: item.equipmentType,
							equipmentTypeId: item.equipmentTypeId
						} as EquipmentType);
						equipmentTypesForEdit.sort(this.equipmentTypeSort);
						return (
							<EquipmentCard
								key={`equipment-${index}`}
								equipment={item}
								equipmentTypes={equipmentTypesForEdit}
								updateFacilityEquipment={this.props.updateFacilityEquipment}
								deleteFacilityEquipment={this.props.deleteFacilityEquipment}
							/>
						);
					})}

					<EquipmentEditModal
						title={localizationService.getLocalizedString('equipments.addEquipment')}
						showModel={this.state.showAddEquipmentModal}
						equipment={equipmentToAdd}
						modalToggleFunction={this.onToggleShowAddEquipmentModal}
						equipmentTypes={validEquipmentTypes || []}
						onSaveEditModal={this.addEquipment}
					/>
				</div>
				<p className="mt-2">
					<a href="#/" className="ai-add-link" onClick={e => this.onToggleShowAddEquipmentModal(e)}>
						{localizationService.getLocalizedString('screen.buttons.addEquipment')}&#8230;
					</a>
				</p>
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityEquipments };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadFacilityEquipments: () => dispatch(actionCreators.loadFacilityEquipments()),

		loadAuthorityEquipmentTypes: () => dispatch(actionCreators.loadAuthorityEquipmentTypes()),

		updateFacilityEquipment: (equipmentId: number, dataToUpdate: ApiTypes.Equipment) =>
			dispatch(actionCreators.updateFacilityEquipment(equipmentId, dataToUpdate)),

		deleteFacilityEquipment: (equipmentId: number, equipmentType: string) =>
			dispatch(actionCreators.deleteFacilityEquipment(equipmentId, equipmentType)),

		createFacilityEquipment: (equipmentToCreate: ApiTypes.Equipment) =>
			dispatch(actionCreators.createFacilityEquipment(equipmentToCreate))
	};
};
export const EquipmentTabPage = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(EquipmentTabPageComp);
