import { LookupRow } from '../lookup-types';
import { LookupType, IppRepudiationReasons } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class RepudiationReasonLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: IppRepudiationReasons): LookupRow => {
		return {
			lookupId: apiData.repudiationReasonId,
			code: apiData.name,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let correctiveAction = {
			repudiationReasonId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as IppRepudiationReasons;
		return correctiveAction;
	}

	toLookupRowsFromAPI(apiData: IppRepudiationReasons[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): IppRepudiationReasons {
		let correctiveAction = {
			name: newValue.code,
			isActive: newValue.isActive
		} as IppRepudiationReasons;
		return correctiveAction;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
