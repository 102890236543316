import { Action } from 'redux';

import { IppIndustry, IppReportPackageParameter, IppReportPackageParameterGroup } from '@rcp/types';

export enum ReportPackageParameterGroupActionType {
	LoadReportPackageParameterGroupRequest = 'loadReportPackageParameterGroupRequest',
	LoadReportPackageParameterGroupSuccess = 'loadReportPackageParameterGroupSuccess',
	AddReportPackageParameterGroup = 'addReportPackageParameterGroup',
	UpdateReportPackageParameterGroup = 'updateReportPackageParameterGroup',
	DeleteReportPackageParameterGroup = 'deleteReportPackageParameterGroup'
}

export interface LoadReportPackageParameterGroupRequest extends Action {
	type: ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupRequest;
}

export interface LoadReportPackageParameterGroupSuccess extends Action {
	type: ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupSuccess;
	parameterGroupDetails: IppReportPackageParameterGroup;
	availableParameters: IppReportPackageParameter[];
	availableParameterGroupAssignments: IppIndustry[];
}

export interface AddReportPackageParameterGroupActon extends Action {
	type: ReportPackageParameterGroupActionType.AddReportPackageParameterGroup;
}

export interface UpdateReportPackageParameterGroupActon extends Action {
	type: ReportPackageParameterGroupActionType.UpdateReportPackageParameterGroup;
}

export interface DeleteReportPackageParameterGroupActon extends Action {
	type: ReportPackageParameterGroupActionType.DeleteReportPackageParameterGroup;
}

export type ReportPackageParameterGroupAction =
	| LoadReportPackageParameterGroupRequest
	| LoadReportPackageParameterGroupSuccess
	| AddReportPackageParameterGroupActon
	| UpdateReportPackageParameterGroupActon
	| DeleteReportPackageParameterGroupActon;

export const loadReportPackageParameterGroupRequest = (): LoadReportPackageParameterGroupRequest => ({
	type: ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupRequest
});

export const loadReportPackageParameterGroupSuccess = (
	parameterGroupDetails: IppReportPackageParameterGroup,
	availableParameters: IppReportPackageParameter[],
	availableParameterGroupAssignments: IppIndustry[]
): LoadReportPackageParameterGroupSuccess => ({
	type: ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupSuccess,
	parameterGroupDetails,
	availableParameters,
	availableParameterGroupAssignments
});

export const addReportPackageParameterGroupActon = (): AddReportPackageParameterGroupActon => ({
	type: ReportPackageParameterGroupActionType.AddReportPackageParameterGroup
});

export const updateReportPackageParameterGroupActon = (): UpdateReportPackageParameterGroupActon => ({
	type: ReportPackageParameterGroupActionType.UpdateReportPackageParameterGroup
});

export const deleteReportPackageParameterGroupActon = (): DeleteReportPackageParameterGroupActon => ({
	type: ReportPackageParameterGroupActionType.DeleteReportPackageParameterGroup
});
