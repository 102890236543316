import React from 'react';

interface Props {}

export const ArrowSvg: React.SFC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5.78">
			<path
				data-name="Path 53"
				d="m5.42 5.6 4.46-4.46a.41.41 0 0 0 0-.58L9.44.12a.41.41 0 0 0-.58 0L5 3.98 1.14.12a.41.41 0 0 0-.58 0L.12.56a.41.41 0 0 0 0 .58l4.47 4.47a.606.606 0 0 0 .41.17.631.631 0 0 0 .42-.18Z"
				fill="#3c3e69"
			/>
		</svg>
	);
};
