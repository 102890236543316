import React, { FC, useEffect, useState } from 'react';
import { CloseSvg } from 'src/components/svg';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { AlertMessageType, alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';

interface Props {
	packageId?: number;
	className?: string;
}

const IppPackageReviewsCorButtons: FC<Props> = ({ packageId, className }) => {
	let [packageValidateData, setPackageValidateData] = useState();

	useEffect(() => {
		setPackageValidateData(undefined);
		return () => {
			setPackageValidateData(undefined);
		};
	}, []);

	const downloadCor = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.IppReportPackages}/${packageId}/${Resource.IppDownloadCOR}`
		);
		try {
			await apiService.getResource(url);
		} catch (error) {
			alertService.addError(error.message);
		}
	};

	const validateCor = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.IppReportPackages}/${packageId}/${Resource.IppValidateCOR}`
		);
		try {
			let packageValidateData: any = await apiService.getResource(url);
			setPackageValidateData(packageValidateData);
		} catch (error) {
			alertService.addError(error.message);
		}
	};

	const validateCorInlineAlertContent = () => {
		return (
			<div className={`digital-signature `}>
				<p>
					{localizationService.getLocalizedString(
						`ipp.reportPackage.package.cor.${
							(packageValidateData as any).valid ? 'validMessage' : 'invalidMessage'
						}`
					)}
					<button
						className={`close-validate-${
							(packageValidateData as any).valid ? 'success' : 'error'
						} float-right`}
						onClick={() => setPackageValidateData(undefined)}>
						<CloseSvg size="8" />
					</button>
				</p>
				<p className="mb-0">
					{localizationService.getLocalizedString('ipp.reportPackage.package.cor.signatureMessage')}
				</p>
				<p className="text-break mb-2">{(packageValidateData as any).digitalSignature}</p>
			</div>
		);
	};

	return (
		<>
			<div className={className ? className : ''}>
				{packageValidateData && (
					<InlineAlertItem
						message={validateCorInlineAlertContent()}
						alertType={
							(packageValidateData as any).valid ? AlertMessageType.Success : AlertMessageType.Error
						}
					/>
				)}
				<div className="form-row">
					<div className="ml-auto" id="cor-buttons">
						<button className="btn ai-action ml-auto mr-2" id="download-cors" onClick={() => downloadCor()}>
							{localizationService.getLocalizedString('ipp.reportPackage.package.downloadCors')}
						</button>
						<button className="btn ai-action ml-auto" id="validate-cors" onClick={() => validateCor()}>
							{localizationService.getLocalizedString('ipp.reportPackage.package.validateCors')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default IppPackageReviewsCorButtons;
