import React, { FC } from 'react';
import { History } from 'history';

import './import.scss';
import { Step1, Step2, Step3 } from './import-steps';
import { localizationService, navigateTo, urlService } from 'src/services';
import Stepper from '../../components/widgets/stepper';
import Step from '../../components/widgets/stepper/step';
import { CccImportSteps } from '@rcp/types';

interface Props {
	history: History;
}

export const ImportFile: FC<Props> = props => {
	const importHistoryPath = urlService.getSettingMenuPath('/import/history');

	const getSteps = () => {
		return (
			<section className="min-height-180px px-2 import-file">
				<Stepper vertical={true} initialStep={CccImportSteps.STEP1}>
					<Step
						stepId={CccImportSteps.STEP1}
						title={localizationService.getLocalizedString('cccImportFile.chooseFile')}>
						<Step1 />
					</Step>
					<Step
						stepId={CccImportSteps.STEP2}
						title={localizationService.getLocalizedString('cccImportFile.matchColumnTypes')}>
						<Step2 />
					</Step>
					<Step
						stepId={CccImportSteps.STEP3}
						title={localizationService.getLocalizedString('cccImportFile.Results')}>
						<Step3 />
					</Step>
				</Stepper>
			</section>
		);
	};

	return (
		<div className="page import-setting-card">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('screen.labels.import')}</h1>
				<a href={importHistoryPath} onClick={(e: any) => navigateTo(props.history, importHistoryPath, e)}>
					{localizationService.getLocalizedString('screen.labels.importHistory')}
				</a>
			</div>
			<div className="page-wrapper">
				<div className="main">{getSteps()}</div>
			</div>
		</div>
	);
};

export default ImportFile;
