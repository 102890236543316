import React, { FC, useEffect, useState } from 'react';
import { localizationService, optionsMap } from 'src/services';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { settingLookupsFrequencySlice } from './frequency-lookup.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Frequency } from '@rcp/types';
import FrequencyEditor, { EditorMode } from './frequency-editor';

interface Props<T> {
	showTitle?: boolean;
	embeddedMode?: boolean;
}

export interface FrequencySettingsState {
	selected: Frequency;
	showDelete: boolean;
	showUpdate: boolean;
	showCreate: boolean;
}

const FrequencySettingsComponent: FC<Props<Frequency>> = props => {
	const title = localizationService.getLocalizedString('authoritySetting.frequency.frequency');
	let initialState: FrequencySettingsState = {
		showDelete: false,
		showCreate: false,
		showUpdate: false,
		selected: {
			isActive: true,
			frequencyCode: ''
		}
	};
	let [state, setState] = useState(initialState);

	const dispatch = useDispatch();
	let { result: frequencies } = useSelector((state: RootState) => state.settingFrequencies);

	useEffect(() => {
		dispatch(settingLookupsFrequencySlice.fetchPage({}));
	}, [dispatch]);

	const onDeleteClicked = (frequency: Frequency) => {
		if (frequency) {
			setState({ ...state, selected: frequency, showDelete: true });
		}
	};

	const isEnabled = (field: Frequency) => {
		return field.daysBetweenEvents || field.monthsBetweenEvents || field.yearsBetweenEvents;
	};

	const onIsActiveChanged = (e: any, frequency: Frequency) => {
		let { value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		if (frequency && frequency.frequencyId) {
			dispatch(
				settingLookupsFrequencySlice.patchOne(
					frequency.frequencyId,
					{ isActive: value },
					true,
					value
						? localizationService.getLocalizedString(
								'authoritySetting.activatedMessage',
								frequency.frequencyCode
						  )
						: localizationService.getLocalizedString(
								'authoritySetting.deactivatedMessage',
								frequency.frequencyCode
						  )
				)
			);
		}
	};

	const getTitleRow = () => {
		return (
			<thead>
				<tr className="lookup-row lookup-header-row font-size-14px-semibold">
					<th className="active-checkbox-cell-width">
						{localizationService.getLocalizedString('authoritySetting.frequency.active')}
					</th>
					<th>{title}</th>
					<th className="action-2-buttons">&nbsp;</th>
					<th className="action-2-buttons">&nbsp;</th>
				</tr>
			</thead>
		);
	};

	const onUpdateFrequency = (e: any, frequency: Frequency) => {
		if (frequency) {
			setState({ ...state, selected: frequency, showDelete: false, showUpdate: true, showCreate: false });
		}
	};

	const getRows = () => {
		return (
			<tbody>
				{frequencies
					.filter(field => isEnabled(field))
					.sort((frequency1: Frequency, frequency2: Frequency) => {
						return optionsMap.sortFrequencies(frequency1, frequency2);
					})
					.map((field, index) => {
						return (
							<tr className="lookup-row" key={`option-key-frequency-${index}`}>
								<td id={`active_${index}`}>
									<SingleCheckbox
										className="ml-2"
										id={`cb_active_frequency_${index}`}
										name={`cb_active_frequency_${index}`}
										checked={field.isActive}
										onChange={e => {
											onIsActiveChanged(e, field);
										}}
									/>
								</td>
								<td id={`option_value_frequency_${index}`}>{field.frequencyCode}</td>
								<td>
									<button
										onClick={e => onUpdateFrequency(e, field)}
										id={`btnUpdateFrequency_${index}`}>
										<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon " />
									</button>
								</td>

								<td>
									{!field.isInUse && (
										<button
											onClick={() => onDeleteClicked(field)}
											id={`btnDeleteFrequency_${index}`}>
											<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
										</button>
									)}
								</td>
							</tr>
						);
					})}
			</tbody>
		);
	};

	const onCancelDelete = () => {
		setState({ ...state, showDelete: false });
	};

	const onDeleteConfirmed = () => {
		if (state.selected.frequencyId) {
			dispatch(
				settingLookupsFrequencySlice.deleteOne(
					state.selected.frequencyId,
					true,
					localizationService.getLocalizedString(
						'authoritySetting.deleteSucceedMessage',
						state.selected.frequencyCode,
						title
					),
					() => {
						setState({ ...state, showDelete: false });
					}
				)
			);
		}
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.deleteModalTitle', title)}
				showModal={state.showDelete}
				cancel={() => onCancelDelete()}
				saveButtonClassName="ai-delete ml-auto"
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				save={() => onDeleteConfirmed()}>
				<span
					dangerouslySetInnerHTML={{
						__html: localizationService.getLocalizedString(
							'authoritySetting.deleteModalMessage',
							state.selected.frequencyCode,
							title
						)
					}}
				/>
			</PopoverModal>
		);
	};

	const onAddFrequency = () => {
		setState({ ...state, showDelete: false, showUpdate: false, showCreate: true });
	};

	const onEditorClose = () => {
		setState({ ...state, showUpdate: false, showCreate: false });
	};

	const frequencyList = () => {
		return (
			<div>
				<div>
					<div className="form-row">
						<div className="d-flex full-width margin-bottom-1">
							<button className="btn ai-new mb-1 ml-auto " id="btnAddFrequency" onClick={onAddFrequency}>
								{localizationService.getLocalizedString('authoritySetting.addBtnText')}
							</button>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table">
							{getTitleRow()}
							{getRows()}
						</table>
					</div>
				</div>
			</div>
		);
	};

	const frequencySection = () => {
		return (
			<>
				<div className="row">
					<div className="col-lg-4 settings-info">
						<strong>
							{localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
						</strong>
						<p>{localizationService.getLocalizedString('authoritySetting.frequency.desc')}</p>
					</div>
					<div className="col-lg-8">
						<section>{frequencyList()}</section>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{props.embeddedMode === true ? frequencyList() : frequencySection()}
			{state.showDelete && getDeleteModal()}
			{state.showCreate && (
				<FrequencyEditor mode={EditorMode.Add} show={state.showCreate} onClosed={onEditorClose} />
			)}
			{state.showUpdate && (
				<FrequencyEditor
					mode={EditorMode.Update}
					frequency={state.selected}
					show={state.showUpdate}
					onClosed={onEditorClose}
				/>
			)}
		</>
	);
};

export default FrequencySettingsComponent;
