import React from 'react';
import { TimePicker } from '@progress/kendo-react-dateinputs';

import './widgets.scss';
import { DateUtilService, localizationService } from 'src/services';

interface Props {
	id: string;
	name: string;
	label: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	error?: string;
	onChange?(e: any): void;
	className?: string;
	value?: string; // date time value with timezone
}

export const TimeInput: React.SFC<Props> = props => {
	const [existingDate, setExistingDate] = React.useState<Date | undefined>(undefined);

	React.useEffect(() => {
		let propDate;

		if (!props.value || !DateUtilService.isValidDateTime(props.value)) {
			propDate = undefined;
		} else {
			propDate = DateUtilService.prepareDateForDateTimePicker(props.value);
		}

		setExistingDate(propDate);
	}, [props.value]);

	const handleChange = (e: any) => {
		let newE = {
			target: {
				name: e.target.name,
				value: DateUtilService.prepareDateStringForApiCall(e.target.value)
			}
		};

		if (props.onChange) {
			props.onChange(newE);
		}
	};

	let defaultClass = 'form-group col-sm-6';
	let rowClass = props.className ? props.className : defaultClass;

	return (
		<div className={rowClass + (props.isRequired ? ' required' : '')}>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					{props.label}
				</label>
			) : (
				<label htmlFor={props.id}>{props.label}</label>
			)}
			<TimePicker
				id={props.id}
				name={props.name}
				format={localizationService.getLocalizedString('dateFormats.displayTime')}
				className={'w-100' + (props.error ? ' is-invalid' : '')}
				defaultValue={
					props.value
						? DateUtilService.prepareDateForDateTimePicker(props.value)
						: DateUtilService.prepareDateForDateTimePicker(DateUtilService.getAuthorityTimezoneNow())
				}
				value={existingDate}
				onChange={handleChange}
				required={props.error ? true : false} //Force kendo-ui datepicker display error mode when props.error exists
				disabled={props.isDisabled === true ? true : false}
				popupSettings={{ animate: false }}
			/>
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</div>
	);
};
