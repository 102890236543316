import React, { FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CloseSvg } from 'src/components';
import { PageLoadingContainer } from 'src/components/layout/page-loading-container';

export interface CustomModalProp {
	showModal: boolean;
	title: string;
	bodyClass?: string;
	footerClass?: string;
	onCancelButtonClick: (e?: any) => void;
	onOkayButtonClick: (e?: any) => void;
	okayButtonText?: string;
	message?: string;
}

export const CustomModal: FC<CustomModalProp> = props => {
	const toggleModal = (e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		props.onCancelButtonClick();
	};

	const closeBtn = (
		<button className="close" onClick={(e?: any) => toggleModal(e)}>
			<CloseSvg />
		</button>
	);

	return (
		<Modal
			isOpen={props.showModal}
			toggle={(e?: any) => toggleModal(e)}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}>
			<PageLoadingContainer />
			<ModalHeader tag="h1" toggle={(e?: any) => toggleModal(e)} close={closeBtn}>
				{props.title}
			</ModalHeader>
			<ModalBody className={props.bodyClass || ''}>
				<>
					{props.message && <p>{props.message}</p>}
					{props.children}
				</>
			</ModalBody>
			<ModalFooter className={props.footerClass || ''}>
				<button className="btn ai-action" id="okayBtn" onClick={props.onOkayButtonClick}>
					{props.okayButtonText ? props.okayButtonText : 'Save'}
				</button>
				<button className="btn ai-white" id="cancelBtn" onClick={(e?: any) => toggleModal(e)}>
					Cancel
				</button>
			</ModalFooter>
		</Modal>
	);
};
