import React, { useState } from 'react';
import { ApiError, DropDownOption, Frequency } from '@rcp/types';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { alertService } from 'src/redux';
import { settingLookupsFrequencySlice } from './frequency-lookup.slice';
import { useDispatch } from 'react-redux';

export enum EditorMode {
	Add,
	Update
}

interface Props {
	mode: EditorMode;
	show: boolean;
	onClosed?: () => void;
	frequency?: Frequency | null;
}

export enum FrequencyType {
	DAY = 'days',
	MONTH = 'months',
	YEAR = 'years'
}

const FrequencyEditor: React.FunctionComponent<Props> = props => {
	const title = localizationService.getLocalizedString('authoritySetting.frequency.frequency');
	const dispatch = useDispatch();
	const getInitState = () => {
		let frequencyType = FrequencyType.DAY;
		let frequency = '1';
		let frequencyCode = '';
		if (props.mode === EditorMode.Update && props.frequency) {
			frequencyCode = props.frequency.frequencyCode;
			if (props.frequency.daysBetweenEvents && props.frequency.daysBetweenEvents > 0) {
				frequencyType = FrequencyType.DAY;
				frequency = props.frequency.daysBetweenEvents.toString();
			}
			if (props.frequency.monthsBetweenEvents && props.frequency.monthsBetweenEvents > 0) {
				frequencyType = FrequencyType.MONTH;
				frequency = props.frequency.monthsBetweenEvents.toString();
			}
			if (props.frequency.yearsBetweenEvents && props.frequency.yearsBetweenEvents > 0) {
				frequencyType = FrequencyType.YEAR;
				frequency = props.frequency.yearsBetweenEvents.toString();
			}
		}

		return {
			name: frequencyCode,
			frequency: frequency,
			type: frequencyType,
			nameError: '',
			frequencyError: '',
			show: props.show
		};
	};

	let [state, setState] = useState(getInitState());
	const typeOptions: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('authoritySetting.frequency.days'),
			value: FrequencyType.DAY
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.frequency.months'),
			value: FrequencyType.MONTH
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.frequency.years'),
			value: FrequencyType.YEAR
		}
	];

	const onUpdateSuccess = () => {
		alertService.addSuccess(
			localizationService.getLocalizedString(
				'authoritySetting.updateSucceedMessage',
				title,
				props.frequency ? props.frequency.frequencyCode : ''
			)
		);
		onClose();
	};

	const onCreateSuccess = () => {
		alertService.addSuccess(localizationService.getLocalizedString('authoritySetting.addSucceedMessage', title));
		onClose();
	};

	const onError = (reason: any) => {
		let error = localizationService.getLocalizedString('screen.validationMessage.formValidation');
		if (reason instanceof ApiError) {
			if (
				reason.body &&
				reason.body.internalMessage &&
				reason.body.internalMessage.toLowerCase().indexOf('violation of unique key') >= 0
			) {
				setState({
					...state,
					nameError: localizationService.getLocalizedString(
						'authoritySetting.frequency.duplicateName',
						state.name
					)
				});
			}
		}

		alertService.addError(error);
	};

	const onSaveClicked = async () => {
		if (!state.name) {
			const errorText = localizationService.getLocalizedString('authoritySetting.frequency.nameRequired');
			alertService.addError(errorText);
			setState({ ...state, nameError: errorText });
			return;
		}
		if (Number(state.frequency) <= 0) {
			const errorText = localizationService.getLocalizedString('authoritySetting.frequency.frequencyRequired');
			alertService.addError(errorText);
			setState({ ...state, frequencyError: errorText });
			return;
		}

		let frequency = Number(state.frequency);
		let request: Frequency = { frequencyCode: state.name };

		if (String.equalCaseInsensitive(state.type, FrequencyType.DAY)) {
			request = { ...request, daysBetweenEvents: frequency, monthsBetweenEvents: 0, yearsBetweenEvents: 0 };
		} else if (String.equalCaseInsensitive(state.type, FrequencyType.MONTH)) {
			request = { ...request, monthsBetweenEvents: frequency, daysBetweenEvents: 0, yearsBetweenEvents: 0 };
		} else if (String.equalCaseInsensitive(state.type, FrequencyType.YEAR)) {
			request = { ...request, yearsBetweenEvents: frequency, daysBetweenEvents: 0, monthsBetweenEvents: 0 };
		}

		if (props.mode === EditorMode.Update && props.frequency && props.frequency.frequencyId) {
			dispatch(
				settingLookupsFrequencySlice.patchOne(
					props.frequency.frequencyId,
					request,
					true,
					undefined,
					onUpdateSuccess,
					onError,
					true
				)
			);
		} else if (props.mode === EditorMode.Add) {
			request = { ...request, isActive: true };
			dispatch(settingLookupsFrequencySlice.createOne(request, true, undefined, onCreateSuccess, onError, true));
		}
	};
	const onCancelClicked = () => {
		onClose();
	};

	const onClose = () => {
		setState({ ...state, show: false });
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const getTitle = () => {
		if (props.mode === EditorMode.Add) {
			return localizationService.getLocalizedString('authoritySetting.frequency.addFrequency');
		} else {
			return localizationService.getLocalizedString('authoritySetting.frequency.updateFrequency');
		}
	};

	const footer = () => {
		return (
			<div className="ml-auto">
				<button id="btnSave" className="btn ai-save ml-2" onClick={onSaveClicked}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</button>
				<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		);
	};

	const onNameChanged = (evt: any) => {
		setState({ ...state, name: evt.target.value, nameError: '' });
	};

	const onFrequencyChanged = (evt: any) => {
		setState({ ...state, frequency: evt.target.value, frequencyError: '' });
	};

	const onTypeSelected = (evt: any) => {
		let type = evt.target.value as FrequencyType;
		setState({
			...state,
			type: type,
			frequencyError: '',
			nameError: ''
		});
	};

	return (
		<>
			<PopoverModal
				showModal={props.show && state.show}
				title={getTitle()}
				footer={footer()}
				save={onSaveClicked}
				cancel={onCancelClicked}>
				<TextInput
					id="frequencyName"
					name="frequencyName"
					value={state.name}
					isRequired={true}
					onChange={onNameChanged}
					error={state.nameError}
					label={localizationService.getLocalizedString('authoritySetting.frequency.name')}
				/>

				<div className="form-group required">
					<label>{localizationService.getLocalizedString('authoritySetting.frequency.frequency')}</label>
					<div className="d-flex">
						<input
							id="frequencyFrequency"
							name="frequencyFrequency"
							type="number"
							className={
								'col-2 required mr-2 form-control value ' + (state.frequencyError ? ' is-invalid' : '')
							}
							value={state.frequency}
							onChange={onFrequencyChanged}
							required={true}
							autoComplete="off"
						/>

						<select
							id="frequencyType"
							name="frequencyType"
							className={'custom-select col-4'}
							onChange={onTypeSelected}
							value={state.type}
							required={true}>
							{typeOptions.map((optionItem: DropDownOption, index: number) => (
								<option
									key={'frequencyType-' + index}
									hidden={optionItem.isHidden === true}
									value={optionItem.value}
									title={optionItem.label}>
									{optionItem.label}
								</option>
							))}
						</select>

						<div className="d-flex full-width align-items-center">
							<label>
								{localizationService.getLocalizedString('authoritySetting.frequency.betweenEvents')}
							</label>
						</div>
					</div>

					<span className="d-block invalid-feedback">{state.frequencyError || <>&nbsp;</>}</span>
				</div>
			</PopoverModal>
		</>
	);
};

export default FrequencyEditor;
