import { AddNoticeSteps } from '@rcp/types';
import _ from 'lodash';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { TextInput } from 'src/components/widgets';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState } from 'src/redux';
import { localizationService, urlService, validationService } from 'src/services';

export const Step1: FC = () => {
	const stepperContext = React.useContext(StepperContext);
	const [templateName, setTemplateName] = React.useState('');
	const [templateNameError, setTemplateNameError] = React.useState('');

	let cleaningNoticeTemplateSchedule = (state: RootState) => state.cleaningNoticeTemplate;
	let { selected: cleaningNoticeTemplate } = useSelector(cleaningNoticeTemplateSchedule);

	React.useEffect(() => {
		const step1Data = stepperContext.getData(AddNoticeSteps.STEP1);
		if (step1Data) {
			setTemplateName(step1Data.templateName);
		} else if (cleaningNoticeTemplate) {
			let templateName = cleaningNoticeTemplate.templateName || '';
			setTemplateName(templateName);
		}
	}, [cleaningNoticeTemplate]);

	React.useEffect(() => {
		//Added this useEffect for directly jump to further steps
		if (templateName) {
			_.defer(() => {
				let step = _.toInteger(urlService.getUrlQueryParameter('step') as string);
				if (step > 1) {
					stepperContext.resolve({ templateName });
				}
			}, 200);
		}
	}, [templateName]);

	const onClickNext = () => {
		alertService.clearAllMessages();
		let newState = { templateName, templateNameError };
		validationService.validateRequiredField(
			newState,
			'templateName',
			'templateNameError',
			localizationService.getLocalizedString('authoritySetting.noticesSettings.templateName')
		);
		setTemplateNameError(newState.templateNameError);
		let isFormValid = !validationService.hasError(newState, 'templateNameError');
		isFormValid
			? stepperContext.resolve({ templateName })
			: alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
	};

	return (
		<StepperContent
			id="choose-a-name"
			className="w-100 mr-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<TextInput
				id="name-input"
				label={localizationService.getLocalizedString('authoritySetting.noticesSettings.templateName')}
				name="name-input"
				isRequired={true}
				onChange={e => setTemplateName(e.target.value)}
				value={templateName}
				error={templateNameError}
			/>
		</StepperContent>
	);
};
