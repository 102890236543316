import React, { FC } from 'react';
import { History } from 'history';
import './import-history.scss';
import {
	DataImportResult,
	ColumnField,
	FilterType,
	LocalStorageName,
	RouteProps,
	RegulatoryProgramName,
	Import
} from '@rcp/types';
import { RestSlice } from '../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../data-grid/data-grid';
import { RootState } from 'src/redux';
import { DateUtilService, localizationService, urlService } from 'src/services';
import { cccImportHistorySlice, fogImportHistorySlice } from './import-history.slice';
import { ImportResult } from '../import-history-result';
import { AuthoritySettingsImportHistoryResult } from 'src/components';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const ImportHistoryGrid: FC<Props<DataImportResult | Import>> = props => {
	const regulatoryProgram = urlService.getCurrentRegulatoryProgram();
	const uploadDateTimeCell = (props: any) => {
		const importHistory = props.dataItem;
		return (
			<td>
				{DateUtilService.toDisplayDate(
					importHistory.creationDateTimeUtc,
					localizationService.getLocalizedString('dateFormats.displayDateTime')
				)}
			</td>
		);
	};
	const fileNameToLink = (props: any) => {
		const importHistory = props.dataItem;
		return (
			<td>
				<a href={importHistory.originalFileUrl} target="_blank" rel="noopener noreferrer">
					{importHistory.fileName}
				</a>
			</td>
		);
	};
	const resultToLink = (props: any) => {
		const importResult = props.dataItem;
		if (importResult.importStatus === 'Complete') {
			if (regulatoryProgram === RegulatoryProgramName.FOG) {
				return (
					<td>
						<AuthoritySettingsImportHistoryResult importHistory={importResult as Import} />
					</td>
				);
			}
			return (
				<td>
					<ImportResult importResult={importResult as DataImportResult} />
				</td>
			);
		}
		return <td>{importResult.importStatus}</td>;
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('importHistory.importHistory'),
		prefix: 'cccImportFile.histories',
		storageName: LocalStorageName.cccImportHistoryGrid,
		sortOrderLocalStorageName: LocalStorageName.CccImportHistorySortOrder,
		showEditColumnButton: true,
		showRefreshButton: true,
		showSearch: true,
		searchPlaceholder: localizationService.getLocalizedString('cccImportFile.histories.searchPlaceHolder'),

		allColumns: [
			new ColumnField('creationDateTimeUtc', FilterType.Text, uploadDateTimeCell),
			new ColumnField('fileName', FilterType.Text, fileNameToLink),
			new ColumnField('uploaderFullName', FilterType.Text),
			new ColumnField('results', FilterType.Text, resultToLink),
			new ColumnField('numberOfTotalRows', FilterType.Number),
			new ColumnField('numberOfSuccessRows', FilterType.Number),
			new ColumnField('numberOfErrorRows', FilterType.Number)
		],
		defaultColumns: [
			'creationDateTimeUtc',
			'fileName',
			'uploaderFullName',
			'results',
			'numberOfTotalRows',
			'numberOfSuccessRows',
			'numberOfErrorRows'
		]
	};

	if (urlService.isProgram(RegulatoryProgramName.FOG)) {
		return (
			<>
				<DataGrid
					history={props.history}
					match={props.match}
					restSlice={fogImportHistorySlice}
					restState={(state: RootState) => state.fogImportHistories}
					gridOption={initialGridOption}
				/>
			</>
		);
	} else if (urlService.isProgram(RegulatoryProgramName.Backflow)) {
		return (
			<>
				<DataGrid
					history={props.history}
					match={props.match}
					restSlice={cccImportHistorySlice}
					restState={(state: RootState) => state.cccImportHistories}
					gridOption={initialGridOption}
				/>
			</>
		);
	} else {
		return <></>;
	}
};
