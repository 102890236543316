import { RestSlice } from '../../../redux/rest.slice';
import { DataImportResult, Import } from '@rcp/types';
import { Resource, localizationService } from 'src/services';

export const cccImportHistorySlice = new RestSlice<DataImportResult>(
	localizationService.getLocalizedString('cccImport.histories'),
	Resource.CccImportHistories
);
export const cccImportHistorySliceReducer = cccImportHistorySlice.rests.reducer;

export const fogImportHistorySlice = new RestSlice<Import>(
	localizationService.getLocalizedString('cccImport.histories'),
	Resource.CccImportHistories
);
export const fogImportHistorySliceReducer = fogImportHistorySlice.rests.reducer;
