import React, { FC, useState } from 'react';
import { SingleCheckbox, SingleSelectDropdown, ComboBoxDropdown } from '../../../components/widgets';
import { apiService, localizationService, Logger, Resource, urlService, UtilService } from 'src/services';

import _ from 'lodash';
import {
	ApiError,
	Dictionary,
	DropDownOption,
	emptyOption,
	DataImport,
	CustomFieldApplyToTable,
	CustomFieldDefinition,
	LookupType,
	MaxInteger
} from '@rcp/types';
import { findBestMatch } from 'string-similarity';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { CloseSvg } from 'src/components/svg';
import { RadioGroup } from 'src/components/widgets';
import {
	composeEditorLookupOptions,
	getImportFieldUpdatePolicyOptions,
	getLookupAutoTranslatePolicyOptions,
	getLookupUrl,
	saveConfigurationFieldMapping,
	toTargetFieldOptions,
	updateSelfImportState
} from './self-import-service';
import { useDispatch, useSelector } from 'react-redux';
import { EditorMode } from 'src/components/authority/shared/settings/editors/custom-field-editor';
import { UnMapConfirmationModal } from './step-modals';
import { useHistory } from 'react-router';
import { PaginatedResult } from '@rcp/types/src';
import { FieldMappingSideLookup } from './field-mapping-side-lookup';
import { FieldMappingCustomFieldEditor } from './field-mapping-side-custom-field-editor';

export interface ConfigurationEditorProp {}

interface LocalState {
	fieldOptions: DropDownOption[];
	targetFields: DataImport.ImportField[];
	selectedFieldName: string;
	defaultValueError: string;
	autoTranslateFileValues: boolean;
	selectedFieldOption: DropDownOption;
	showConfirmUnMapColumn: boolean;
	displayLookupTranslation: boolean;
	canAutoAddNewValues: boolean;
	canManualAddSystemValues: boolean;
	canManualMapToSystemValues: boolean;
	importField?: DataImport.ImportField;
	lookupData: DropDownOption[];
	unrecognizedValues: string[];
	translationMap: Dictionary<string>;
}

const initialLocalState: LocalState = {
	fieldOptions: [],
	targetFields: [],
	selectedFieldName: '',
	defaultValueError: '',
	autoTranslateFileValues: true,
	selectedFieldOption: emptyOption,
	showConfirmUnMapColumn: false,
	displayLookupTranslation: false,
	canAutoAddNewValues: false,
	canManualAddSystemValues: false,
	canManualMapToSystemValues: false,
	lookupData: [],
	unrecognizedValues: [],
	translationMap: {}
};

interface CustomFieldState {
	show: boolean;
	mode: EditorMode;
	selected?: CustomFieldDefinition;
}

const initialCustomFieldState: CustomFieldState = {
	show: false,
	mode: EditorMode.Add
};

export const SelfImportFieldMappingSideEditor: FC<ConfigurationEditorProp> = props => {
	const selfImportState = useSelector((state: RootState) => state.selfImport).current;
	const [loadLookupMap, setLoadLookupMap] = useState(false);
	const [state, setState] = useState<LocalState>(initialLocalState);
	const [customFieldState, setCustomFieldState] = useState<CustomFieldState>(initialCustomFieldState);
	const [lookupState, setLookupState] = useState({ show: false, lookupLabel: '' });

	const { userProfile } = useReduxSelector(state => state.userProfile);

	const dispatch = useDispatch();
	const history = useHistory();

	const isAdministratorOrInternalUser = (): boolean => {
		return urlService.isAdministrator() || userProfile.isInternalAccount === true;
	};

	const onCustomFieldClosed = () => {
		setCustomFieldState({ ...customFieldState, show: false });
	};
	const onAddCustomField = (e: any) => {
		e.preventDefault();
		setCustomFieldState({ ...customFieldState, show: true, mode: EditorMode.Add, selected: undefined });
	};
	const onAddLookupClosed = () => {
		setLookupState({ ...lookupState, show: false, lookupLabel: '' });
		setLoadLookupMap(true);
	};
	const onAddLookup = (e: any, lookupLabel: string) => {
		e.preventDefault();
		if (!selfImportState.fieldMapping) {
			return;
		}
		let fieldMapping = selfImportState.fieldMapping;
		if (!fieldMapping.isCustomField) {
			setLookupState({
				...lookupState,
				show: true,
				lookupLabel
			});
			return;
		}

		if (_.toLower(DataImport.ImportEntity.FogFacility) === _.toLower(fieldMapping.importEntity)) {
			const facilityCustomFieldsUrl =
				urlService.getAuthoritySettingResourceApiUrl(LookupType.CustomField) +
				`?appliedToTable=in:${CustomFieldApplyToTable.ApplyToFogFacility}&IncludeIsInUse=true&size=${MaxInteger}&flat=1`;
			apiService.httpGet(facilityCustomFieldsUrl).then((customFields: PaginatedResult<CustomFieldDefinition>) => {
				let customField = _.find(customFields.result, f => f.fieldName === fieldMapping.fieldName);
				if (customField) {
					setCustomFieldState({
						...customFieldState,
						show: true,
						mode: EditorMode.UpdateOptions,
						selected: customField
					});
				}
			});
		}
	};
	const reloadTargetFields = (customFieldDefinition: CustomFieldDefinition, forCreation: boolean) => {
		Logger.debug(
			`Custom field ${customFieldDefinition.fieldLabel}(${customFieldDefinition.fieldName}) ${
				forCreation ? 'added' : 'updated'
			}.`
		);
		const settingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		const importFieldsUrl = `${settingUrl}/ImportFields/${selfImportState.importDataType as string}`;
		return apiService.getResource<DataImport.ImportField[]>(importFieldsUrl).then(targetFields => {
			let fieldOptions = toTargetFieldOptions(targetFields);
			setState({ ...state, targetFields, fieldOptions });
			dispatch(updateSelfImportState({ targetFields: targetFields, fieldOptions: fieldOptions }));
		});
	};
	const getUnmappedFilterOptions = (): DropDownOption[] => {
		let filteredOptions: DropDownOption[] = [];
		let mappedFieldnames = _.map(selfImportState.fieldMappings, f => f.fieldName as string);
		_.forEach(selfImportState.fieldOptions, o => {
			if (!_.includes(mappedFieldnames, o.label)) {
				filteredOptions.push(o);
			}
		});
		return filteredOptions;
	};
	const getBestGuessFieldOptionByDisplayLabel = () => {
		if (!selfImportState.fieldMapping) {
			return;
		}
		let columnName = selfImportState.fieldMapping.importColumnName;
		let unmappedFieldOptions = getUnmappedFilterOptions();
		let labels = unmappedFieldOptions.map(o => o.label);
		if (columnName && !_.isEmpty(labels)) {
			let bestMatches = findBestMatch(selfImportState.fieldMapping.importColumnName, labels);
			if (bestMatches.bestMatch.rating < 0.7) {
				Logger.debug(`bestMatch does not exceed our similarity limit 0.7: ${bestMatches.bestMatch}`);
				return;
			}
			let bestMatchFieldName = bestMatches.bestMatch.target;
			Logger.debug(`bestMatch find target ${bestMatchFieldName}`);
			let bestMatchOption = _.find(
				selfImportState.fieldOptions,
				o => o.label === bestMatchFieldName || o.value === bestMatchFieldName
			);
			if (bestMatchOption) {
				return bestMatchOption;
			}
		}
	};

	const onClose = () => {
		dispatch(updateSelfImportState({ showSidePanel: false }));
	};

	const updateFieldMapping = (fieldMappingOverride: any) => {
		let newFieldMapping: DataImport.ImportFieldMapping = {
			...selfImportState.fieldMapping,
			...fieldMappingOverride
		};
		if (newFieldMapping.fieldUpdatePolicy === DataImport.FieldUpdatePolicy.neverUpdate) {
			newFieldMapping.isRequired = false;
		}
		let isDeletion = newFieldMapping.importFieldMappingId && _.isEmpty(newFieldMapping.fieldName);
		saveConfigurationFieldMapping(
			newFieldMapping,
			dispatch,
			selfImportState.fieldMappings,
			selfImportState.importConfigurationId,
			selfImportState.targetFields
		).then(() => {
			if (isDeletion) {
				//the importFieldMappingId has been delete, no need to show side panel.
				onClose();
			}
		});
	};

	const getTargetImportField = (fieldName: string): DataImport.ImportField | undefined => {
		if (selfImportState.targetFields) {
			let targetField = selfImportState.targetFields.find(f => f.fieldName === fieldName);
			if (targetField) {
				return targetField;
			}
		}
	};

	const handleLookupDefaultValueOrTranslation = (fieldOption: DropDownOption) => {
		if (!selfImportState.fieldMapping) {
			return;
		}

		const theFieldMapping = selfImportState.fieldMapping;
		let targetImportField = getTargetImportField(fieldOption.value);
		if (!targetImportField) {
			Logger.error('Fail to get target mapping field.');
			return;
		}
		let targetField = targetImportField;

		if (!targetField.isLookup) {
			setState(previousState => {
				return {
					...previousState,
					displayLookupTranslation: false,
					importField: targetField,
					canAutoAddNewValues: false,
					canManualAddSystemValues: false,
					canManualMapToSystemValues: false,
					lookupData: []
				};
			});
			return;
		}

		let lookupUrl = getLookupUrl(targetField!);
		let getColumnValuesUrl =
			urlService.getAuthorityResourcesApiUrl(Resource.CccImport) +
			`/DistinctValues?fileId=${selfImportState.importFileId}&columnName=${theFieldMapping.importColumnName}`;
		if (lookupUrl && getColumnValuesUrl) {
			setState(previousState => {
				return {
					...previousState,
					importField: targetField,
					canAutoAddNewValues: targetField.canAutoAddNewValues,
					canManualAddSystemValues: targetField.canManualAddSystemValues,
					canManualMapToSystemValues: targetField.canManualMapToSystemValues,
					lookupData: []
				};
			});
			Promise.all([apiService.getResource<[]>(lookupUrl), apiService.getResource<[]>(getColumnValuesUrl)])
				.then(results => {
					let lookups = _.keys(results[0]);
					let distinctValues: string[] = results[1];
					let lookupData = composeEditorLookupOptions(lookups);
					let translationMap: Dictionary<string>;
					if (_.isEmpty(state.translationMap)) {
						let autoTranslationMap = getAutoMatchRecognizedTranslation(distinctValues, lookups);
						translationMap = autoTranslationMap;
					} else {
						translationMap = state.translationMap;
					}
					let unrecognizedValues = getUnrecognizedTranslation(distinctValues, lookups, translationMap);
					setState(previousState => {
						return {
							...previousState,
							lookupData,
							importField: targetField,
							displayLookupTranslation:
								targetField.canAutoAddNewValues ||
								targetField.canManualAddSystemValues ||
								targetField.canManualMapToSystemValues,
							canAutoAddNewValues: targetField.canAutoAddNewValues,
							canManualAddSystemValues: targetField.canManualAddSystemValues,
							canManualMapToSystemValues: targetField.canManualMapToSystemValues,
							unrecognizedValues,
							translationMap
						};
					});
				})
				.catch((e: ApiError) => {
					alertService.addError(e.body.message);
				});
		}
	};

	const onFieldNameChanged = (e: any) => {
		const pickedFieldName = e.target.value;
		if (!_.isEmpty(pickedFieldName)) {
			if (pickedFieldName === state.selectedFieldOption.value) {
				return;
			}
			var pickedFieldOption = state.fieldOptions.find(o => o.value === pickedFieldName);
			if (pickedFieldOption) {
				setState({ ...initialLocalState, selectedFieldOption: pickedFieldOption });
			} else {
				Logger.warn(`Cannot find picked fieldName ${pickedFieldName}`);
			}
		} else {
			setState({ ...state, showConfirmUnMapColumn: true });
		}
	};

	const deleteCurrentFieldMapping = () => {
		setState({
			...state,
			showConfirmUnMapColumn: false,
			importField: undefined,
			displayLookupTranslation: false,
			canAutoAddNewValues: false,
			canManualAddSystemValues: false,
			canManualMapToSystemValues: false,
			selectedFieldOption: emptyOption
		});
		updateFieldMapping({
			fieldName: '',
			fieldLabel: ''
		});
	};

	const createOrUpdateTranslationChanged = (transFrom: string, transTo: string, isCreation: boolean) => {
		if (!selfImportState.fieldMapping) {
			return;
		}
		let unrecognizedValues = [...state.unrecognizedValues];
		if (isCreation) {
			unrecognizedValues = _.without(unrecognizedValues, transFrom);
		}
		let translationMap = { ...state.translationMap };
		if (_.isEmpty(transTo)) {
			_.unset(translationMap, transFrom);
		} else {
			translationMap[transFrom] = transTo;
		}
		const translationMapInJson = JSON.stringify(translationMap);
		setState({ ...state, translationMap, unrecognizedValues });
		updateFieldMapping({ translationMapInJson });
	};

	const onFieldUpdatePolicyChanged = (e: any) => {
		const { value } = e.target;
		updateFieldMapping({ fieldUpdatePolicy: value });
	};

	const getAutoTranslationPolicyTitle = (resolvedLookupLabel: string) => {
		let autoTranslationPolicyTitle =
			state.unrecognizedValues.length > 0
				? localizationService.getLocalizedString(
						'import.steps.autoTranslateFileValuesPolicyTitleWithNumber',
						_.toString(state.unrecognizedValues.length),
						resolvedLookupLabel
				  )
				: localizationService.getLocalizedString(
						'import.steps.autoTranslateFileValuesPolicyTitleGeneral',
						resolvedLookupLabel
				  );
		return autoTranslationPolicyTitle;
	};

	const getStandardImportLookupTranslation = (lookupLabel: string) => {
		if (!state.canManualAddSystemValues) {
			return <></>;
		}
		const resolvedLookupLabel = UtilService.resolveNoun(state.unrecognizedValues.length, lookupLabel, true);
		if (state.unrecognizedValues.length < 1) {
			return (
				<p>
					<span>
						{localizationService.getLocalizedString(
							'import.steps.standardImportNoNeedAddLookup1',
							resolvedLookupLabel
						)}
					</span>
					<a href="#/" className="ai-link" onClick={e => onAddLookup(e, lookupLabel)}>
						{localizationService.getLocalizedString('import.steps.standardImportNoNeedAddLookup2')}
					</a>
					<span>{localizationService.getLocalizedString('import.steps.standardImportNoNeedAddLookup3')}</span>
				</p>
			);
		}
		return (
			<div>
				<p>
					<span>
						{localizationService.getLocalizedString(
							state.unrecognizedValues.length > 1
								? 'import.steps.standardImportAddLookupsLink1'
								: 'import.steps.standardImportAddLookupLink1',
							_.toString(state.unrecognizedValues.length),
							resolvedLookupLabel
						)}
					</span>
					&nbsp;
					<a href="#/" className="ai-link" onClick={e => onAddLookup(e, lookupLabel)}>
						{localizationService.getLocalizedString(
							'import.steps.standardImportAddLookupLink2',
							resolvedLookupLabel
						)}
					</a>
					{':'}
				</p>
				<ul>
					{state.unrecognizedValues.map((unRecVal, index) => (
						<li key={`unRecVal-${index}`}>{unRecVal}</li>
					))}
				</ul>
			</div>
		);
	};

	const getUserDefinedLookupTranslation = (lookupLabel: string, fieldMapping: DataImport.ImportFieldMapping) => {
		const resolvedLookupLabel = UtilService.resolveNoun(state.unrecognizedValues.length, lookupLabel, true);
		return (
			<>
				{state.canAutoAddNewValues && (
					<RadioGroup
						id="autoTranslateFileValues"
						name="autoTranslateFileValues"
						label={getAutoTranslationPolicyTitle(resolvedLookupLabel)}
						options={getLookupAutoTranslatePolicyOptions(resolvedLookupLabel)}
						value={
							state.autoTranslateFileValues === true
								? localizationService.getLocalizedString('constants.yes')
								: localizationService.getLocalizedString('constants.no')
						}
						isRequired={true}
						readonly={false}
						onChange={onAutoTranslateFileValuesChanged}
					/>
				)}

				<div className="ml-4">
					{state.canManualAddSystemValues &&
						(state.autoTranslateFileValues === false || fieldMapping.isRequired) && (
							<div className="mb-2">
								{localizationService.getLocalizedString('import.steps.addLookupHint')}&nbsp;
								<a href="#/" className="ai-link" onClick={e => onAddLookup(e, lookupLabel)}>
									{localizationService.getLocalizedString(
										'import.steps.addALookupLink',
										UtilService.toSingular(lookupLabel, true)
									)}
								</a>
							</div>
						)}
					{fieldMapping.isRequired && (
						<SingleSelectDropdown
							label={localizationService.getLocalizedString('import.steps.defaultValueLabel')}
							id="defaultValue"
							name="defaultValue"
							className="w-100 mb-2"
							isRequired={fieldMapping.isRequired === true}
							value={fieldMapping.defaultValue}
							onChange={onDefaultValueChanged}
							options={state.lookupData}
						/>
					)}
					{state.canManualMapToSystemValues &&
						state.autoTranslateFileValues === false &&
						state.unrecognizedValues.length > 0 &&
						state.unrecognizedValues.map((unRecVal, index) => (
							<SingleSelectDropdown
								label={
									<>
										<strong>{unRecVal}</strong>
										<span>{localizationService.getLocalizedString('import.steps.mapsTo')}</span>
									</>
								}
								id="unrecognizedToLookUp"
								name="unrecognizedToLookUp"
								key={`unrecognizedToLookUp-${index}`}
								className="w-100 mb-2"
								isRequired={fieldMapping.isRequired === true}
								noEmptyOption={fieldMapping.isRequired === true}
								value={state.translationMap[unRecVal] || ''}
								onChange={e => {
									createOrUpdateTranslationChanged(unRecVal, e.target.value, true);
								}}
								options={state.lookupData}
							/>
						))}
					{state.canManualMapToSystemValues &&
						state.autoTranslateFileValues === false &&
						_.size(state.translationMap) > 0 &&
						_.keys(state.translationMap).map((fromValue, index) => (
							<SingleSelectDropdown
								label={
									<>
										<strong>{fromValue}</strong>
										<span>{localizationService.getLocalizedString('import.steps.mapsTo')}</span>
									</>
								}
								id={`recognizedToLookUp-${index}`}
								name={`recognizedToLookUp-${index}`}
								key={`recognizedToLookUp-${index}`}
								className="w-100 mb-2"
								isDisabled={selfImportState.isSystem}
								isRequired={fieldMapping.isRequired === true}
								noEmptyOption={fieldMapping.isRequired === true}
								value={state.translationMap[fromValue]}
								onChange={e => {
									createOrUpdateTranslationChanged(fromValue, e.target.value, false);
								}}
								options={state.lookupData}
							/>
						))}
				</div>
			</>
		);
	};

	const getLookUpTranslation = () => {
		if (!selfImportState.fieldMapping) {
			return <></>;
		}
		const fieldMapping = selfImportState.fieldMapping;
		if (!fieldMapping.fieldName) {
			return <></>;
		}
		const importField = getTargetImportField(fieldMapping.fieldName);
		if (!importField || !importField.isLookup) {
			return <></>;
		}
		const targetField = importField;
		const lookupLabel = targetField.fieldLabel;

		if (selfImportState.isSystem) {
			return getStandardImportLookupTranslation(lookupLabel);
		}
		return getUserDefinedLookupTranslation(lookupLabel, fieldMapping);
	};

	const getUnrecognizedTranslation = (
		distinctFields: string[],
		lookups: string[],
		translationMap: Dictionary<string>
	): string[] => {
		let lowerLookUps = _.map(lookups, function(lookup) {
			return _.toLower(lookup);
		});
		let nonEmpty = _.compact(distinctFields);
		let unmatchedValues = _.filter(nonEmpty, function(myVal) {
			return !_.includes(lowerLookUps, _.toLower(myVal));
		});
		let unmatchedAndUntranslatedValues = _.filter(unmatchedValues, v => {
			return !_.includes(_.keys(translationMap), v);
		});
		return unmatchedAndUntranslatedValues;
	};

	const getAutoMatchRecognizedTranslation = (distinctFields: string[], lookups: string[]): Dictionary<string> => {
		let lowerLookUps = _.map(lookups, lookup => {
			return _.toLower(lookup);
		});
		let columnValues = _.compact(distinctFields);
		let valueTranslations: Dictionary<string> = {};
		_.forEach(columnValues, c => {
			let idx = _.findIndex(lowerLookUps, l => {
				return l === _.toLower(c);
			});
			if (idx > -1) {
				valueTranslations[c] = lookups[idx];
			}
		});
		return valueTranslations;
	};

	const onIsRequiredChanged = (evt: any) => {
		const { checked } = evt.target;
		let values: any = { isRequired: checked };
		if (checked !== true) {
			//Set default value to null when the user uncheck required checkbox
			values.defaultValue = null;
		}
		updateFieldMapping(values);
	};

	const onDefaultValueChanged = (evt: any) => {
		const { value } = evt.target;
		setState({ ...state, defaultValueError: '' });
		if (value) {
			updateFieldMapping({ defaultValue: value });
		} else {
			if (selfImportState.fieldMapping?.isRequired === true) {
				let defaultValueError = localizationService.getLocalizedString(
					'screen.validationMessage.fieldIsRequired'
				);
				setState({ ...state, defaultValueError });
			} else {
				updateFieldMapping({ defaultValue: null });
			}
		}
	};

	const onAutoTranslateFileValuesChanged = (evt: any) => {
		if (!selfImportState.fieldMapping) {
			return;
		}
		const { value } = evt.target;
		let autoTranslateFileValues =
			_.toLower(value) === _.toLower(localizationService.getLocalizedString('constants.yes'));

		if (value === state.autoTranslateFileValues) {
			return;
		}
		setState({ ...state, autoTranslateFileValues });

		let newFieldMapping: DataImport.ImportFieldMapping = {
			...selfImportState.fieldMapping,
			autoTranslateFileValues
		};

		if (
			newFieldMapping.autoTranslateFileValues === false &&
			_.isEmpty(newFieldMapping.translationMapInJson) &&
			_.size(state.translationMap) > 0
		) {
			newFieldMapping.translationMapInJson = JSON.stringify(state.translationMap);
		}

		updateFieldMapping(newFieldMapping);
	};

	React.useEffect(() => {
		if (!selfImportState.fieldMapping || !selfImportState.targetFields) {
			Logger.warn(`selfImportState.fieldMapping is undefine or empty.`);
			return;
		}

		if (selfImportState.previousColumn !== selfImportState.selectedColumn) {
			setCustomFieldState(initialCustomFieldState);
			setLookupState({ show: false, lookupLabel: '' });
		}

		let newState = {
			...initialLocalState,
			targetFields: selfImportState.targetFields,
			fieldOptions: selfImportState.fieldOptions,
			selectedFieldName: '',
			defaultValueError: '',
			autoTranslateFileValues:
				selfImportState.fieldMapping.autoTranslateFileValues !== undefined
					? selfImportState.fieldMapping.autoTranslateFileValues
					: initialLocalState.autoTranslateFileValues,
			translationMap: {}
		};
		if (selfImportState.fieldMapping.translationMapInJson) {
			let transMap = JSON.parse(selfImportState.fieldMapping.translationMapInJson) as Dictionary<string>;
			newState.translationMap = transMap;
		}
		if (_.isEmpty(selfImportState.fieldMapping.fieldName)) {
			if (selfImportState.importConfigurationId > 0) {
				let bestGuessOption = getBestGuessFieldOptionByDisplayLabel();
				if (bestGuessOption) {
					newState.selectedFieldOption = bestGuessOption;
				}
			} else {
				newState.selectedFieldName = emptyOption.value;
			}
		} else {
			newState.selectedFieldOption = {
				value: selfImportState.fieldMapping.fieldName as string,
				label: selfImportState.fieldMapping.fieldName as string
			};
		}
		setState(newState);

		if (newState.selectedFieldOption.value) {
			setLoadLookupMap(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selfImportState]);

	React.useEffect(() => {
		if (loadLookupMap) {
			setLoadLookupMap(false);
			handleLookupDefaultValueOrTranslation(state.selectedFieldOption);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadLookupMap]);

	React.useEffect(() => {
		if (
			!_.isEmpty(state.selectedFieldOption.value) &&
			state.selectedFieldOption.value !== selfImportState.fieldMapping?.fieldName
		) {
			updateFieldMapping({
				fieldName: state.selectedFieldOption.value,
				fieldLabel: state.selectedFieldOption.label
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedFieldOption]);

	React.useEffect(() => {
		if (state.selectedFieldName) {
			//auto pick newly added custom field for the column mapping
			onFieldNameChanged({ target: { value: state.selectedFieldName } });
			//clear local state newCustomFieldName for reset
			setState(prevState => {
				return { ...prevState, selectedFieldName: '' };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedFieldName]);

	if (!selfImportState.fieldMapping) {
		//render empty editor when selfImportState.fieldMapping is null or undefined.
		return <></>;
	}
	if (customFieldState.show) {
		return (
			<FieldMappingCustomFieldEditor
				mode={customFieldState.mode}
				customField={customFieldState.selected}
				importColumnName={selfImportState.fieldMapping?.importColumnName}
				onClosed={onCustomFieldClosed}
				onSuccessCallback={reloadTargetFields}
			/>
		);
	}
	if (lookupState.show) {
		//render add new lookup view
		return (
			<FieldMappingSideLookup
				fieldMapping={selfImportState.fieldMapping}
				lookupLabel={lookupState.lookupLabel}
				close={onAddLookupClosed}
			/>
		);
	}

	const shouldShowAddCustomFields =
		selfImportState.isSystem !== true && //import configuration is readonly
		selfImportState.importDataType === DataImport.ImportDataTypeValues.FogFacilitiesAndContacts; //fog facility do support impot to custom fields

	return (
		<div className="side pl-0 pl-2 mt-2">
			<div>
				<button className="close" onClick={onClose}>
					<CloseSvg />
				</button>
			</div>
			<h2 className="side-title my-4">
				{localizationService.getLocalizedString('cccImportFile.column') + ' ' + selfImportState.selectedColumn}
			</h2>
			<div className="side-body">
				<div className="form-group mt-2">
					<p>
						<strong>{selfImportState.fieldMapping.importColumnName}</strong>
						<span>{localizationService.getLocalizedString('import.steps.mapsTo')}</span>
					</p>
					<ComboBoxDropdown
						id="mapToFieldName"
						name="mapToFieldName"
						isDisabled={!isAdministratorOrInternalUser() || selfImportState.isSystem}
						value={state.selectedFieldOption.value}
						onChange={onFieldNameChanged}
						clearButton={true}
						options={state.fieldOptions}
						withoutWrappingDiv={true}
					/>
					{shouldShowAddCustomFields && (
						// Display link of adding custom fields
						<div className="ai-form-help">
							{localizationService.getLocalizedString('import.steps.columnMapLinkBefore')}
							<a href="#/" className="ai-link" onClick={onAddCustomField}>
								{localizationService.getLocalizedString('import.steps.columnMapLink')}
							</a>
							{localizationService.getLocalizedString('import.steps.columnMapLinkAfter')}
						</div>
					)}
				</div>
				<SingleCheckbox
					id="isRequiredColumn"
					name="isRequiredColumn"
					label={
						<p>
							<strong>{localizationService.getLocalizedString('import.steps.notNullColumn')}</strong>
							<br />
							<span className="ai-form-help">
								{localizationService.getLocalizedString('import.steps.notNullColumnDescription')}
							</span>
						</p>
					}
					checked={selfImportState.fieldMapping.isRequired}
					onChange={onIsRequiredChanged}
					isDisabled={
						!isAdministratorOrInternalUser() ||
						selfImportState.isSystem ||
						selfImportState.fieldMapping.isNotNull === true ||
						selfImportState.fieldMapping.fieldUpdatePolicy === DataImport.FieldUpdatePolicy.neverUpdate
					}
					className="div-checkbox mt-3"
				/>
				{selfImportState.importEntityPolicy !== DataImport.ImportEntityPolicy.addOnly && (
					<RadioGroup
						id="fieldUpdatePolicy"
						name="fieldUpdatePolicy"
						label={localizationService.getLocalizedString('import.steps.importCellValuePolicyQuestion')}
						options={getImportFieldUpdatePolicyOptions()}
						value={selfImportState.fieldMapping.fieldUpdatePolicy}
						isRequired={true}
						readonly={!isAdministratorOrInternalUser() || selfImportState.isSystem}
						onChange={onFieldUpdatePolicyChanged}
					/>
				)}
				{state.displayLookupTranslation && getLookUpTranslation()}

				{state.showConfirmUnMapColumn && (
					<UnMapConfirmationModal
						onCancel={() => {
							setState({ ...state, showConfirmUnMapColumn: false });
						}}
						onConfirm={deleteCurrentFieldMapping}
						columnName={selfImportState.fieldMapping.importColumnName}
					/>
				)}
			</div>
		</div>
	);
};
