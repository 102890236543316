import React, { useEffect, useState, FC } from 'react';
import { History } from 'history';
import {
	AuthorityUser,
	ColumnField,
	FilterType,
	FogFacility,
	LocalStorageName,
	RouteProps,
	FacilityUser,
	FeatureNames
} from '@rcp/types';
import _ from 'lodash';
import { alertService, RestSlice, RootState, useReduxSelector } from 'src/redux';
import { DataGrid, GridOption } from 'src/features';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { useDispatch } from 'react-redux';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';
import { fogFacilitiesSlice } from 'src/components';
import InviteEditFacilityUser from 'src/components/service-provider/shared/user-modal';
import { manageFacilityAccessSlice } from './manage-access.slice';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { Utils } from 'src/services/utils';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

interface EditActionLinkType extends RouteProps {
	user: FacilityUser;
	linkText?: string;
	isEdit: boolean;
	restSlice: RestSlice<FacilityUser | AuthorityUser>;
	facility: FogFacility;
	isFacilityPortalBlocked?: boolean;
}

const checkAdministratorForFacilityPortal = () => {
	return (urlService.isAdministrator() && urlService.isFacilityPortal) || !urlService.isFacilityPortal();
};

const EditActionLink: FC<EditActionLinkType> = props => {
	const [showModal, setShowModal] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			{checkAdministratorForFacilityPortal() && !props.isFacilityPortalBlocked ? (
				props.facility.blockAccess ? (
					<span>{props.linkText ? props.linkText : props.user.fullName}</span>
				) : (
					<a href="#/" className="ai-link" onClick={e => onEditClicked(e)}>
						{props.linkText ? props.linkText : props.user.fullName}
					</a>
				)
			) : (
				<div>{props.user.fullName}</div>
			)}
			{showModal && (
				<InviteEditFacilityUser
					user={props.user}
					showModal={showModal}
					isEdit={props.isEdit}
					toggleModal={toggleModal}
					serviceName={props.facility.facilityName}
					restSlice={props.restSlice}
					serviceId={props.facility.facilityId}
					match={props.match}
				/>
			)}
		</>
	);
};

export const FacilityPortalUsersGrid: React.FC<Props<FacilityUser | AuthorityUser>> = props => {
	const [facility, setFacility] = React.useState({} as FogFacility);
	const dispatch = useDispatch();
	const [showModal, setShowModal] = React.useState(false);
	const [isFacilityPortalBlocked, setIsFacilityPortalBlocked] = React.useState(false);
	const restSlice = urlService.isServicePortal() ? authorityUsersSlice : manageFacilityAccessSlice;

	useEffect(() => {
		urlService.isFacilityPortal() && Utils.getFacilityPortalStatus(setIsFacilityPortalBlocked);
	}, []);

	useEffect(() => {
		if (props.match.params.fogFacilityId) {
			restSlice.setApiUrlPath(`${Resource.FogFacilities}/${props.match.params.fogFacilityId}/${Resource.Users}`);
			dispatch(
				fogFacilitiesSlice.fetchOne(props.match.params.fogFacilityId, (data: FogFacility) => {
					setFacility(data);
				})
			);
		}
	}, [props.match.params.fogFacilityId]);

	const toggleModal = () => setShowModal(!showModal);

	const userToLink = (prop: any) => {
		const user = prop.dataItem as FacilityUser;

		return (
			<td>
				<EditActionLink
					facility={facility}
					user={user}
					isEdit={true}
					restSlice={restSlice}
					match={props.match}
					isFacilityPortalBlocked={isFacilityPortalBlocked}
				/>
			</td>
		);
	};

	const getStatus = (prop: any) => {
		let user: FacilityUser = prop.dataItem;
		const invitedLabel = localizationService.getLocalizedString('facilityPortal.settings.users.status.invited');

		return String.equalCaseInsensitive(user.facilityUserStatusName, invitedLabel) ? (
			<td>
				{invitedLabel}
				{checkAdministratorForFacilityPortal() && !facility.blockAccess && !isFacilityPortalBlocked && (
					<>
						{' - '}
						<EditActionLink
							isEdit={false}
							user={user}
							linkText={localizationService.getLocalizedString(
								'facilityPortal.settings.users.status.resend'
							)}
							facility={facility}
							restSlice={restSlice}
							match={props.match}
						/>
					</>
				)}
			</td>
		) : (
			<td>{user.facilityUserStatusName}</td>
		);
	};

	const handleAccess = async (e: React.ChangeEvent<HTMLInputElement>) => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities)}/${
			props.match.params.fogFacilityId
		}/${Resource.BlockAccess}`;
		let blockAccess = await apiService.httpPatch(url, { blockAccess: e.target.checked });
		if (blockAccess)
			dispatch(
				fogFacilitiesSlice.fetchOne(props.match.params.fogFacilityId, (data: FogFacility) => {
					setFacility(data);
				})
			);
	};

	let translateColumns = ['facilityUserStatusName', 'role'];

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString(
			`facilityPortal.settings.users.${urlService.isFacilityPortal() ? 'users' : 'manageAccess'}`,
			facility.facilityName || ''
		),
		prefix: 'facilityPortal.settings.users',
		storageName: LocalStorageName.FacilityPortalUsersGrid,
		sortOrderLocalStorageName: LocalStorageName.FacilityPortalUsersGridSortOrder,
		showBackButton: true,
		queryParameters: {
			sort: 'fullName asc',
			includeInactive: '1'
		},
		showEditColumnButton: true,
		showSearch: true,
		searchPlaceholder: localizationService.getLocalizedString('screen.labels.search'),
		showAddButton: checkAdministratorForFacilityPortal() && !facility.blockAccess && !isFacilityPortalBlocked,
		addButtonText: localizationService.getLocalizedString('facilityPortal.settings.users.inviteUser'),
		addModalComponent: (
			<InviteEditFacilityUser
				serviceName={facility.facilityName || ''}
				showModal={showModal}
				isEdit={false}
				toggleModal={toggleModal}
				restSlice={restSlice}
				serviceId={facility.facilityId}
				match={props.match}
			/>
		),
		toggleAddModal: toggleModal,
		defaultSort: [{ field: 'fullName', dir: 'asc' }],
		allColumns: [
			new ColumnField('fullName', FilterType.Text, userToLink),
			new ColumnField('email'),
			new ColumnField('role'),
			new ColumnField('facilityUserStatusName', undefined, getStatus)
		],
		defaultColumns: ['fullName', 'email', 'role', 'facilityUserStatusName'],
		checkbox: urlService.isFacilityPortal()
			? undefined
			: {
					id: 'facility-access',
					name: 'facility-access',
					label: localizationService.getLocalizedString('facilityPortal.settings.users.blockFacilityAccess'),
					checked: facility.blockAccess,
					onChange: handleAccess
			  },
		translateColumns
	};
	return (
		<>
			{!urlService.isFacilityPortal() && !urlService.isAuthorityPortal() ? (
				<AccessDeniedPage />
			) : (
				<DataGrid
					gridOption={initialGridOption}
					restSlice={restSlice}
					history={props.history}
					match={props.match}
					restState={(state: RootState) =>
						urlService.isServicePortal() ? state.authorityUsers : state.authorityFacilityUsers
					}
				/>
			)}
		</>
	);
};
