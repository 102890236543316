import {
	ColumnField,
	Dictionary,
	FeatureNames,
	FilterType,
	IppIndustry,
	IppPendingInvitation,
	LocalStorageName,
	RouteProps
} from '@rcp/types';
import { localizationService, tokenService, urlService } from 'src/services';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { CloseSvg } from 'src/components/svg';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { ippPendingInvitationSlice } from './pending-invitation.slice';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { Utils } from 'src/services/utils';
import { IppConstants } from 'src/constants';
import InviteModal from './invite-modal';
import _ from 'lodash';
import { WithAqiSsoFeatureFlag } from 'src/components/authority/shared/settings/users/with-aqi-sso-feature-flag';

interface Props extends RouteProps {
	history: History;
	isIndustryUserModule: () => boolean;
	industryData?: IppIndustry;
}

const { queryParams } = IppConstants;
const { shortPageSize } = queryParams;

const PendingInvitationList: FC<Props> = props => {
	const dispatch = useDispatch();

	const withAqiIdentityProvider = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.WithAqiIdentityProvider]
	);

	const getQueryParams = () => {
		if (props.isIndustryUserModule() || urlService.isIppIndustryPortal()) {
			return {
				size: shortPageSize,
				industryId: props.match.params.industryId
					? props.match.params.industryId.toString()
					: tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId || ''
			};
		} else {
			return { size: shortPageSize } as Dictionary<string>;
		}
	};

	const resendInvitationIcon = (row: any) => {
		const invitation = row.dataItem as IppPendingInvitation;
		return (
			<td>
				<WithAqiSsoFeatureFlag>
					{invitation.isInvitationExpired && invitation.isAqiIdpAccountInvitation && (
						<a
							id={`resendInvitation${_.replace(
								invitation.invitationId as string,
								new RegExp('-', 'g'),
								''
							)}`}
							href="#/"
							onClick={e => {
								e.preventDefault();
								setSelectedInvitation(invitation.invitationId as string);
								onToggleResendInvitationModal(false);
							}}>
							{localizationService.getLocalizedString('ipp.authorityAccount.resendInvitation')}
						</a>
					)}
				</WithAqiSsoFeatureFlag>
			</td>
		);
	};

	const deleteIcon = (row: any) => {
		const invitation = row.dataItem as IppPendingInvitation;
		return (
			<td>
				<span
					id={`delInvite${_.replace(invitation.invitationId as string, new RegExp('-', 'g'), '')}`}
					className="cursor-pointer cancel-invite"
					onClick={() => {
						setSelectedInvitation(invitation.invitationId || '');
						onToggleDeleteInvitationModal(false);
					}}>
					<CloseSvg />
				</span>
			</td>
		);
	};

	const [inviteModal, setInviteModal] = useState(false);
	const [reRender, setReRender] = useState(false);
	const [deleteInvitationModal, setDeleteInvitationModal] = useState(false);
	const [resendInvitationModal, setResendInvitationModal] = useState(false);
	const [selectedInvitation, setSelectedInvitation] = useState('');
	const getAllColumns = () => {
		let columns = [
			new ColumnField('firstName'),
			new ColumnField('lastName'),
			new ColumnField('emailAddress'),
			new ColumnField('invitationDateTimeUtc', FilterType.Date, Utils.formatDate),
			new ColumnField('expiryDateTimeUtc', FilterType.Date, Utils.formatDate)
		];
		if (withAqiIdentityProvider) {
			columns.push(new ColumnField('ResendInvitation', undefined, resendInvitationIcon));
		}
		columns.push(new ColumnField('Remove', undefined, deleteIcon));
		return columns;
	};
	const getColumns = () => {
		let columns = ['firstName', 'lastName', 'emailAddress', 'invitationDateTimeUtc', 'expiryDateTimeUtc'];
		if (withAqiIdentityProvider) {
			columns.push('ResendInvitation');
		}
		columns.push('Remove');
		return columns;
	};
	const [initialGridOption, setInitialGridOption] = useState({
		showTabList: false,
		showEditColumnButton: false,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.authorityAccount.pendingInvitationsColumns',
		storageName: LocalStorageName.IppPendingInvitations,
		searchPlaceholder: '',
		showActions: false,
		showRefreshButton: true,
		pageTitle: '',
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: getQueryParams(),
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		rerenderOnQueryParamChange: true,
		allColumns: getAllColumns(),
		defaultColumns: getColumns()
	} as GridOption);

	const onToggleInviteModal = (reRender: boolean) => {
		setInviteModal(!inviteModal);
		setReRender(reRender);
	};

	const onToggleDeleteInvitationModal = (reRender: boolean) => {
		setDeleteInvitationModal(!deleteInvitationModal);
		setReRender(reRender);
	};

	const onToggleResendInvitationModal = (reRender: boolean) => {
		setResendInvitationModal(!resendInvitationModal);
		setReRender(reRender);
	};

	const onDeleteInvitation = () => {
		let invitationIdToBeDeleted = props.isIndustryUserModule()
			? `${selectedInvitation}?industryId=${props.match.params.industryId}`
			: selectedInvitation;
		dispatch(
			ippPendingInvitationSlice.deleteOne(
				invitationIdToBeDeleted,
				true,
				localizationService.getLocalizedString('ipp.authorityAccount.deleteMessage'),
				() => onToggleDeleteInvitationModal(true)
			)
		);
	};

	const onResendInvitation = () => {
		let path = `${selectedInvitation}/resendinvitation`;
		if (props.isIndustryUserModule()) {
			path += `?industryId=${props.match.params.industryId}`;
		}
		onToggleResendInvitationModal(true);
		dispatch(
			ippPendingInvitationSlice.fetchOne(path, data => {
				alertService.addSuccess(
					localizationService.getLocalizedString('ipp.authorityAccount.resentInvitationMessage')
				);
				dispatch(ippPendingInvitationSlice.reload());
			})
		);
	};

	useEffect(() => {
		setInitialGridOption({ ...initialGridOption, queryParameters: getQueryParams() });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.href]);

	useEffect(() => {
		if (reRender) {
			setInitialGridOption({ ...initialGridOption, queryParameters: getQueryParams() });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reRender]);

	const pendingInvitationState = (state: RootState) => state.ippPendingInvitation;

	const inviteModalProp = {
		showModal: inviteModal,
		onCancelButtonClick: onToggleInviteModal,
		okayButtonText: localizationService.getLocalizedString('ipp.buttons.search'),
		isIndustryUserModule: props.isIndustryUserModule,
		industryData: props.industryData
	};

	const deleteInvitationModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString(`ipp.authorityAccount.invitationDeleteHeading`),
		message: `${localizationService.getLocalizedString(`ipp.authorityAccount.invitationDeleteMessage`)}`,
		showModal: deleteInvitationModal,
		onCancelButtonClick: () => onToggleDeleteInvitationModal(false),
		onOkayButtonClick: onDeleteInvitation,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const confirmResendInvitationModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString(`ipp.authorityAccount.resendInvitationHeading`),
		message: `${localizationService.getLocalizedString(`ipp.authorityAccount.resendInvitationMessage`)}`,
		showModal: resendInvitationModal,
		onCancelButtonClick: () => onToggleResendInvitationModal(false),
		onOkayButtonClick: onResendInvitation,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.confrim'),
		isDeleteButton: false
	};

	const inviteButton = (
		<div className="d-print-none mr-4 font-size-18px-regular">
			{!urlService.isIppIndustryPortal() && props.isIndustryUserModule() ? (
				props.industryData && (
					<button
						onClick={() => {
							onToggleInviteModal(false);
						}}
						className="btn ai-action pl-2 pr-2 p-1">
						{localizationService.getLocalizedString(`ipp.buttons.inviteAdmin`)}
					</button>
				)
			) : urlService.isAdministrator() && !props.isIndustryUserModule() ? (
				<button
					onClick={() => {
						onToggleInviteModal(false);
					}}
					className="btn ai-action pl-2 pr-2 p-1">
					{localizationService.getLocalizedString(`ipp.buttons.invite`)}
				</button>
			) : null}
		</div>
	);

	return (
		<>
			<div className="accordion-data-grid">
				<CollapsibleCard
					className="mt-3"
					accordionHeaderElements={inviteButton}
					accordionType="invitation"
					accordionHeading={localizationService.getLocalizedString(
						'ipp.authorityAccount.pendingInvitations'
					)}>
					<DataGrid
						history={props.history}
						match={props.match}
						restSlice={ippPendingInvitationSlice}
						restState={pendingInvitationState}
						gridOption={{ ...initialGridOption }}></DataGrid>
				</CollapsibleCard>
				{inviteModal && <InviteModal {...inviteModalProp} />}
				{deleteInvitationModal && <DeleteModal {...deleteInvitationModalProp} key="deleteInvitationModal" />}
				{resendInvitationModal && (
					<DeleteModal {...confirmResendInvitationModalProp} key="confirmResendInvitationModal" />
				)}
			</div>
		</>
	);
};

export default PendingInvitationList;
