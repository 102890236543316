import { IppIndustrySample, IppSampleResult } from '@rcp/types';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import IppPackageItemProgress from 'src/components/authority/ipp/report-packages/packages/package-items/progress';
import { SampleUtils } from 'src/components/authority/ipp/report-packages/packages/package-items/sample-utils';
import { SampleCompliance } from 'src/constants';
import { localizationService } from 'src/services';
import '../../../../../authority/ipp/report-packages/packages/package-items/review-summary.scss';

interface Props {
	className?: string;
	sampleTypes?: IppIndustrySample[];
	importSuccess?: boolean;
}

const IppImportSampleSummary: FC<Props> = props => {
	const [sampleProgressData, setSampleProgressData] = useState({ present: 0, required: 0 });
	const [sampleTypes, setSampleTypes] = useState([] as IppIndustrySample[]);

	useEffect(() => {
		let sampleTypes = _.cloneDeep(props.sampleTypes || []);
		if (sampleTypes) setSampleTypes(sampleTypes);
	}, [props]);

	useEffect(() => {
		let ippSamples = _.cloneDeep(sampleTypes);
		let required: number = 0;
		let present: number = 0;
		for (let i in ippSamples) {
			let massLoadingRequiredCount: number = 0;
			let massLoadingPresentCount: number = 0;
			massLoadingRequiredCount = ippSamples[i].ippSampleResults!.filter(result => result.massLoadingValue).length;
			required += (ippSamples[i].ippSampleResults || []).length + massLoadingRequiredCount;
			massLoadingPresentCount += (ippSamples[i].ippSampleResults || []).filter((result: IppSampleResult) => {
				let massLoadingCompliance = SampleUtils.displayMassLoadingCompliance(result);
				return (
					massLoadingCompliance.type !== SampleCompliance.Bad &&
					result.massLoadingValue !== null &&
					result.massLoadingValue !== undefined
				);
			}).length;
			present +=
				(ippSamples[i].ippSampleResults || []).filter((result: IppSampleResult) => {
					let resultCompliance = SampleUtils.displayConcentrationCompliance(result);
					return resultCompliance.type !== SampleCompliance.Bad;
				}).length + massLoadingPresentCount;
		}
		setSampleProgressData({ present: present || 0, required: required || 0 });
	}, [sampleTypes]);

	return (
		<>
			<div id="report-package-review-samples" className={props.className}>
				<div className={`${props.importSuccess ? '' : 'd-flex'} justify-content-space-between`}>
					<div className="sample-summary-details">
						<h3>
							{localizationService.getLocalizedString(
								'ipp.reportPackage.package.reviewSummary.sampleTitle'
							)}
						</h3>
						{!props.importSuccess && (
							<p>
								{localizationService.getLocalizedString(
									'ipp.reportPackage.package.reviewSummary.sampleNote'
								)}
							</p>
						)}
					</div>
					<div className="ml-auto">
						<IppPackageItemProgress
							{...sampleProgressData}
							isSample={true}
							className="mr-3"
							importSuccess={props.importSuccess}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default IppImportSampleSummary;
