import React from 'react';
import { InspectionEvent } from '@rcp/types';
import { InspectionEventModal } from '../facilities/facility-details/inspection-event-modal';

interface OwnProps {
	inspectionEvent: InspectionEvent;
	isFromInspectionGrid?: boolean;
}

export const InspectionEditActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
				{props.inspectionEvent.inspectionType}
			</a>
			{showModal && (
				<InspectionEventModal
					inspection={props.inspectionEvent}
					isToggle={showModal}
					toggle={toggleModal}
					isFromInspectionGrid={props.isFromInspectionGrid}
				/>
			)}
		</>
	);
};
