import React from 'react';
import { FacilityBulkNoticeSteps } from '@rcp/types';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { SingleCheckbox } from 'src/components';
import { alertService } from 'src/redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { useHistory } from 'react-router';

const { STEP1, STEP2 } = FacilityBulkNoticeSteps;

const initialFormField = {
	isSentNoticesSelected: false,
	isFacilityPrimaryContactSelected: false,
	isFacilityAddressSelected: false
};

export const Step2 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [formField, setFormField] = React.useState(initialFormField);
	const [noCompleteContactExist, setNoCompleteContactExist] = React.useState<boolean>(false);
	const [facilityCount, setFacilityCount] = React.useState<any>({
		totalFacilities: 0,
		facilityWithNoContacts: 0
	});
	const history = useHistory();
	const queryParams = history.location.search;
	const [noFseContactMessage, setNoFseContactMessage] = React.useState<boolean>(false);

	React.useEffect(() => {
		setFormField(stepperContext.getData(STEP2) || initialFormField);
		if (stepperContext.getData(STEP2)) {
			const {
				isSentNoticesSelected = false,
				isFacilityPrimaryContactSelected = false,
				isFacilityAddressSelected = false
			} = stepperContext.getData(STEP2);
			if (
				isSentNoticesSelected !== false ||
				isFacilityPrimaryContactSelected !== false ||
				isFacilityAddressSelected !== false
			) {
				getFacilityCount(isSentNoticesSelected, isFacilityPrimaryContactSelected, isFacilityAddressSelected);
			}
		}
	}, []);

	const getContactsList = () => {
		let contactsWithIncompleteAddress = [];
		const { isSentNoticesSelected, isFacilityPrimaryContactSelected, isFacilityAddressSelected } = formField;
		const resourceToPost = {
			isSentNoticesSelected,
			isFacilityPrimaryContactSelected,
			isFacilityAddressSelected
		};
		let url = `${urlService.getFacilitiesResourceApiUrl()}/${Resource.Contacts}`;
		if (queryParams) {
			url += queryParams;
		}
		apiService
			.postResourceObject<any>(url, resourceToPost as any)
			.then((resp: any[]) => {
				resp.map((contact: any) => {
					let { addressLine1, addressCity, addressJurisdictionCode, addressZipCode } = contact;
					if (!addressLine1 || !addressCity || !addressJurisdictionCode || !addressZipCode) {
						contactsWithIncompleteAddress.push(contact);
					}
				});
				if (contactsWithIncompleteAddress.length === resp.length) {
					setNoCompleteContactExist(true);
					setNoFseContactMessage(false);
				} else {
					stepperContext.resolve({ ...formField, facilityContacts: resp as any[] });
				}
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onClickNext = () => {
		const { isSentNoticesSelected, isFacilityPrimaryContactSelected, isFacilityAddressSelected } = formField;
		if (
			isSentNoticesSelected === true ||
			isFacilityPrimaryContactSelected === true ||
			isFacilityAddressSelected === true
		) {
			getContactsList();
		} else {
			alertService.addError(localizationService.getLocalizedString('bulkNotice.selectRecipientErrorMessage'));
		}
	};

	const onClickBack = () => stepperContext.goAt(STEP1);

	const getFacilityCount = (
		isSentNoticesSelected: boolean,
		isFacilityPrimaryContactSelected: boolean,
		isFacilityAddressSelected: boolean
	) => {
		let url = `${urlService.getFacilitiesResourceApiUrl()}/FacilityContactsCount`;
		if (queryParams) {
			url += queryParams;
		}
		apiService
			.postResourceObject(url, {
				isSentNoticesSelected,
				isFacilityPrimaryContactSelected,
				isFacilityAddressSelected
			})
			.then((response: any) => {
				setFacilityCount(response as any);
				setNoFseContactMessage(true);
				setNoCompleteContactExist(false);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onChangeFormField = (e: any) => {
		const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
		setFormField({ ...formField, [e.target.name]: value });
		if (e.target.name === 'isSentNoticesSelected') {
			getFacilityCount(
				e.target.checked,
				formField.isFacilityPrimaryContactSelected,
				formField.isFacilityAddressSelected
			);
		} else if (e.target.name === 'isFacilityPrimaryContactSelected') {
			getFacilityCount(formField.isSentNoticesSelected, e.target.checked, formField.isFacilityAddressSelected);
		} else if (e.target.name === 'isFacilityAddressSelected') {
			getFacilityCount(
				formField.isSentNoticesSelected,
				formField.isFacilityPrimaryContactSelected,
				e.target.checked
			);
		}
	};

	return (
		<StepperContent
			id={STEP2}
			actions={
				<>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('bulkNotice.back')}
					</StepperAction>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('bulkNotice.next')}
					</StepperAction>
				</>
			}>
			{noCompleteContactExist && (
				<Notification
					icon={<SvgWarning className="custom-svg-size" />}
					className="recipient-warning d-inline-block p-0 mb-2"
					svgClassName=" d-flex pt-1"
					message={localizationService.getLocalizedString('bulkNotice.inCompleteAddressMessage')}
					showCloseButton={false}
				/>
			)}
			{noFseContactMessage &&
				facilityCount.facilitiesWithNoContacts > 0 &&
				(formField.isFacilityPrimaryContactSelected ||
					formField.isSentNoticesSelected ||
					formField.isFacilityAddressSelected) && (
					<Notification
						icon={<SvgWarning className="custom-svg-size" />}
						className="recipient-warning d-inline-block p-0 mb-4"
						svgClassName=" d-flex pt-1"
						message={localizationService.getLocalizedString(
							'bulkNotice.fseCountWithNoContactMessage',
							...([
								`${facilityCount.facilitiesWithNoContacts}`,
								`${facilityCount.totalFacilities}`
							] as string[])
						)}
						showCloseButton={false}
					/>
				)}
			<SingleCheckbox
				id="contactWithSendNotice"
				name="isSentNoticesSelected"
				checked={formField.isSentNoticesSelected}
				onChange={onChangeFormField}
				label={localizationService.getLocalizedString('bulkNotice.contactMarkedSendNoticeDesc')}
			/>
			<SingleCheckbox
				id="facilityPrimaryContact"
				name="isFacilityPrimaryContactSelected"
				checked={formField.isFacilityPrimaryContactSelected}
				onChange={onChangeFormField}
				label={localizationService.getLocalizedString('bulkNotice.facilityPrimaryContact')}
			/>
			<SingleCheckbox
				id="facilityAddressContact"
				name="isFacilityAddressSelected"
				checked={formField.isFacilityAddressSelected}
				onChange={onChangeFormField}
				label={localizationService.getLocalizedString('bulkNotice.facilityAddress')}
			/>
		</StepperContent>
	);
};
