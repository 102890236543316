import React from 'react';

interface Props {
	size?: string | number;
	color?: string;
	className?: string;
}

export const CloseSvg: React.FC<Props> = props => {
	const _size = props.size ? props.size : 12;
	const _color = props.color ? props.color : '#3c3e69';
	const _className = props.className ? props.className : '';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={_size}
			height={_size}
			viewBox={`0 0 12 12`}
			className={_className}>
			<g>
				<path
					d="m7.225 6 4.633-4.633a.5.5 0 0 0 0-.7l-.527-.523a.5.5 0 0 0-.7 0L6 4.777 1.367.144a.5.5 0 0 0-.7 0L.144.669a.5.5 0 0 0 0 .7L4.777 6 .144 10.633a.5.5 0 0 0 0 .7l.525.525a.5.5 0 0 0 .7 0L6 7.225l4.633 4.633a.5.5 0 0 0 .7 0l.525-.525a.5.5 0 0 0 0-.7Z"
					fill={_color}
				/>
			</g>
		</svg>
	);
};
