import { CccHazard, DropDownOption, LookupType } from '@rcp/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateInput, PopoverModal, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components/widgets';

import { alertService, RootState } from 'src/redux';
import { DateUtilService, localizationService, optionsMap, urlService, validationService } from 'src/services';
import { cccHazardSlice } from '../hazards.slice';
import {
	cccLookupHazardCategorySlice,
	cccLookupHazardLineSizeSlice,
	cccLookupHazardProtectionSlice,
	cccLookupHazardServiceStatusSlice,
	cccLookupHazardServiceTypeSlice
} from '../../settings/hazard-lookups.slice';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentHazard: CccHazard;
}

type Props = OwnProps;

interface ServiceForm {
	accountNumber: string;
	serviceNumber: string;
	categoryId?: number;
	meterNumber: string;
	lineSizeId?: number;
	meterLocation: string;
	serviceStatusId?: number;
	protectionId?: number;
	serviceTypeId?: number;
	shutOffDate: string;
	shutOffDateError?: string;
}

const initialServiceForm: ServiceForm = {
	accountNumber: '',
	serviceNumber: '',
	meterNumber: '',
	meterLocation: '',
	shutOffDate: ''
};

export const CccHazardServiceTabModal: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [formState, setFormState] = React.useState(initialServiceForm);
	const [categoryValues, setCategoryValues] = React.useState([] as DropDownOption[]);
	const [protectionValues, setProtectionValues] = React.useState([] as DropDownOption[]);
	const [serviceTypeValues, setServiceTypeValues] = React.useState([] as DropDownOption[]);
	const [serviceStatusValues, setServiceStatusValues] = React.useState([] as DropDownOption[]);
	const [lineSizeValues, setLineSizeValues] = React.useState([] as DropDownOption[]);

	const setFormStateFromProps = (hazard: CccHazard) => {
		let newState = {
			accountNumber: hazard.accountNumber || '',
			serviceNumber: hazard.serviceNumber || '',
			categoryId: hazard.categoryId,
			meterNumber: hazard.meterNumber || '',
			lineSizeId: hazard.lineSizeId,
			meterLocation: hazard.meterLocation || '',
			serviceStatusId: hazard.serviceStatusId,
			protectionId: hazard.protectionId,
			serviceTypeId: hazard.serviceTypeId,
			shutOffDate: hazard.shutOffDate || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.currentHazard);
	}, [props.currentHazard]);

	React.useEffect(() => {
		dispatch(cccLookupHazardServiceTypeSlice.fetchAll(`lookupType=${LookupType.CccHazardServiceType}`));
		dispatch(cccLookupHazardServiceStatusSlice.fetchAll(`lookupType=${LookupType.CccHazardServiceStatus}`));
		dispatch(cccLookupHazardProtectionSlice.fetchAll(`lookupType=${LookupType.CccHazardProtection}`));
		dispatch(cccLookupHazardCategorySlice.fetchAll(`lookupType=${LookupType.CccHazardCategory}`));
		dispatch(cccLookupHazardLineSizeSlice.fetchAll(`lookupType=${LookupType.CccHazardLineSize}`));
	}, [dispatch]);

	let { result: serviceTypes } = useSelector((state: RootState) => state.settingLookupHazardServiceType);
	let { result: serviceStatuses } = useSelector((state: RootState) => state.settingLookupHazardServiceStatus);
	let { result: protections } = useSelector((state: RootState) => state.settingLookupHazardProtection);
	let { result: categories } = useSelector((state: RootState) => state.settingLookupHazardCategory);
	let { result: lineSizes } = useSelector((state: RootState) => state.settingLookupHazardLineSize);

	useEffect(() => {
		let serviceTypeOptions: DropDownOption[] = [];
		let serviceStatusOptions: DropDownOption[] = [];
		let protectionOptions: DropDownOption[] = [];
		let categoryOptions: DropDownOption[] = [];
		let lineSizeOptions: DropDownOption[] = [];
		if (serviceTypes.length > 0) {
			serviceTypeOptions = optionsMap.fromLookups(
				serviceTypes,
				props.currentHazard.serviceTypeId,
				props.currentHazard.serviceType
			);
			setServiceTypeValues(serviceTypeOptions);
		}

		if (serviceStatuses.length > 0) {
			serviceStatusOptions = optionsMap.fromLookups(
				serviceStatuses,
				props.currentHazard.serviceStatusId,
				props.currentHazard.serviceStatus
			);
			setServiceStatusValues(serviceStatusOptions);
		}

		if (protections.length > 0) {
			protectionOptions = optionsMap.fromLookups(
				protections,
				props.currentHazard.protectionId,
				props.currentHazard.protection
			);
			setProtectionValues(protectionOptions);
		}

		if (categories.length > 0) {
			categoryOptions = optionsMap.fromLookups(
				categories,
				props.currentHazard.categoryId,
				props.currentHazard.category
			);
			setCategoryValues(categoryOptions);
		}

		if (lineSizes.length > 0) {
			lineSizeOptions = optionsMap.fromLookups(
				lineSizes,
				props.currentHazard.lineSizeId,
				props.currentHazard.lineSize
			);
			setLineSizeValues(lineSizeOptions);
		}
	}, [serviceTypes, serviceStatuses, categories, protections, lineSizes, props.currentHazard]);

	const validateServiceDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = true;

		if (newState.shutOffDate) {
			if (DateUtilService.isAfterToday(newState.shutOffDate)) {
				isFormValid = false;
				newState.shutOffDateError = localizationService.getLocalizedString(
					'cccHazard.maxShutOffDateValidationMessage'
				);
			}
			validationService.validateMinimumDate(
				newState,
				'shutOffDate',
				'shutOffDateError',
				localizationService.getLocalizedString('cccHazard.shutOffDate')
			);
		}
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const saveService = () => {
		if (!validateServiceDataForSave()) {
			return;
		}
		let hazardToUpdate: CccHazard = {
			accountNumber: formState.accountNumber,
			serviceNumber: formState.serviceNumber,
			categoryId: formState.categoryId,
			meterNumber: formState.meterNumber,
			lineSizeId: formState.lineSizeId,
			meterLocation: formState.meterLocation,
			serviceStatusId: formState.serviceStatusId,
			protectionId: formState.protectionId,
			serviceTypeId: formState.serviceTypeId,
			shutOffDate: formState.shutOffDate
		};
		dispatch(
			cccHazardSlice.patchOne(
				urlService.getCccHazardId(),
				hazardToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccHazard.hazard')
				),
				() => props.toggle()
			)
		);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const cancelSaveService = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('cccHazard.editService')}
				save={saveService}
				cancel={cancelSaveService}>
				<TextInput
					id="accountNumber"
					name="accountNumber"
					label={localizationService.getLocalizedString('cccHazard.accountNumber')}
					value={formState.accountNumber}
					onChange={changeFormState}
				/>
				<TextInput
					id="serviceNumber"
					name="serviceNumber"
					label={localizationService.getLocalizedString('cccHazard.serviceNumber')}
					value={formState.serviceNumber}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="categoryId"
					name="categoryId"
					label={localizationService.getLocalizedString('cccHazard.category')}
					value={_.toString(formState.categoryId)}
					onChange={changeFormState}
					options={categoryValues}
				/>
				<TextInput
					id="meterNumber"
					name="meterNumber"
					label={localizationService.getLocalizedString('cccHazard.meterNumber')}
					value={formState.meterNumber}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="lineSizeId"
					name="lineSizeId"
					label={localizationService.getLocalizedString('cccHazard.lineSize')}
					value={_.toString(formState.lineSizeId)}
					onChange={changeFormState}
					options={lineSizeValues}
				/>
				<TextAreaInput
					id="meterLocation"
					name="meterLocation"
					label={localizationService.getLocalizedString('cccHazard.meterLocation')}
					value={formState.meterLocation}
					onChange={changeFormState}
					isFullWidth={true}
				/>
				<SingleSelectDropdown
					id="serviceStatusId"
					name="serviceStatusId"
					label={localizationService.getLocalizedString('cccHazard.serviceStatus')}
					value={_.toString(formState.serviceStatusId)}
					onChange={changeFormState}
					options={serviceStatusValues}
				/>
				<SingleSelectDropdown
					id="protectionId"
					name="protectionId"
					label={localizationService.getLocalizedString('cccHazard.protection')}
					value={_.toString(formState.protectionId)}
					onChange={changeFormState}
					options={protectionValues}
				/>
				<SingleSelectDropdown
					id="serviceTypeId"
					name="serviceTypeId"
					label={localizationService.getLocalizedString('cccHazard.serviceType')}
					value={_.toString(formState.serviceTypeId)}
					onChange={changeFormState}
					options={serviceTypeValues}
				/>
				<DateInput
					id="shutOffDate"
					name="shutOffDate"
					label={localizationService.getLocalizedString('cccHazard.shutOffDate')}
					value={formState.shutOffDate}
					error={formState.shutOffDateError}
					onChange={changeFormState}
					max={DateUtilService.getAuthorityTimezoneNow()}
				/>
			</PopoverModal>
		</div>
	);
};
