import React from 'react';
import { useHistory } from 'react-router-dom';
import { navigateTo } from 'src/services';
import { chartService } from './chart-service';

interface Props {
	lookIn?: string;
	filterId?: number;
	filterName?: string;
}

export const GotoGridButton: React.FC<Props> = props => {
	const history = useHistory();

	const { label, link } = chartService.getGotoGridLabelAndLink(props.lookIn);
	let href = link;

	return (
		<button
			className="btn ai-white float-right"
			onClick={(e: any) => {
				navigateTo(history, href, e);
			}}>
			{label}
		</button>
	);
};
