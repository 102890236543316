import React, { useState } from 'react';
import { Hauler } from '@rcp/types';
import { InviteHaulerModal, InviteHaulerModalProps } from './hauler-invite-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCircle } from '@fortawesome/free-solid-svg-icons';
import { DateUtilService, localizationService } from 'src/services';
import _ from 'lodash';

interface Props {
	hauler: Hauler;
	redirectToManageAccess: (event: any, id: number) => void;
}

const displayActionNames = {
	invite: localizationService.getLocalizedString('haulerPortal.haulerGrid.invite'),
	resend: localizationService.getLocalizedString('haulerPortal.haulerGrid.resend'),
	manageAccess: localizationService.getLocalizedString('haulerPortal.haulerGrid.manageAccess'),
	blocked: localizationService.getLocalizedString('haulerPortal.haulerGrid.blocked'),
	invited: localizationService.getLocalizedString('haulerPortal.haulerGrid.invited')
};

let { invite, resend, blocked, invited } = displayActionNames;

export const InviteHaulerActionLink: React.FC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const [displayAction, setDisplayAction] = useState(invite);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	React.useEffect(() => {
		let { haulerPortalStatusName } = props.hauler;
		let displayActionName = '';
		displayActionName = _.upperFirst(_.toLower(_.startCase(_.camelCase(haulerPortalStatusName))));
		setDisplayAction(displayActionName);
	}, [props.hauler]);

	const displayInviteAction = () => {
		let displayActionName = '';
		displayActionName = String.equalCaseInsensitive(displayAction, invited) ? resend : displayAction;
		let displayIcon = String.equalCaseInsensitive(displayActionName, invite)
			? faCircle
			: String.equalCaseInsensitive(displayActionName, blocked)
			? faBan
			: null;
		return (
			<span className="d-block">
				{displayIcon && <FontAwesomeIcon className="mr-2" size="xs" icon={displayIcon} />}
				{displayActionName}
			</span>
		);
	};

	const inviteHaulerModalProps: InviteHaulerModalProps = {
		showModal: showModal,
		onCancel: toggleModal,
		hauler: props.hauler,
		title: localizationService.getLocalizedString('haulerPortal.inviteHauler.title'),
		invitationDesc: localizationService.getLocalizedString(
			'haulerPortal.inviteHauler.inviteInformationMessage',
			props.hauler.name || ''
		)
	};

	return (
		<>
			{props.hauler.invitationDateTimeUtc && !props.hauler.haulerOrganizationId && (
				<span>{`${localizationService.getLocalizedString(
					'haulerPortal.haulerGrid.invited'
				)} ${DateUtilService.toDisplayDate(props.hauler.invitationDateTimeUtc)}`}</span>
			)}
			<a
				href="#/"
				className="ai-link"
				onClick={
					String.equalCaseInsensitive(displayAction, invite) ||
					String.equalCaseInsensitive(displayAction, invited)
						? onEditClicked
						: (event: any) => props.redirectToManageAccess(event, Number(props.hauler.haulerId))
				}>
				{displayInviteAction()}
			</a>
			{showModal && <InviteHaulerModal {...inviteHaulerModalProps} />}
		</>
	);
};
