import React, { useEffect, useState } from 'react';
import { LetterTemplateTranslation, NoticeTemplateType, TranslationLanguage } from '@rcp/types';
import { TakeOverModal } from 'src/components/widgets/takeover-modal';
import { localizationService, validationService } from 'src/services';
import _ from 'lodash';
import { DeleteModal, TextInput } from 'src/components/widgets';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Utils } from 'src/services/utils';
import { FormFields } from './steps';
import { FormField } from '../../../facilities/facility-details/facility-notice-steps';
import { translateService } from 'src/services/translate-service';
import { alertService, RootState } from 'src/redux';
import { useSelector } from 'react-redux';

interface Props {
	noticeContent?: FormField | FormFields;
	close: () => void;
	addOrUpdateTranslation: (translation: LetterTemplateTranslation, isEditMode: boolean) => void;
	deleteTranslation: (translation: LetterTemplateTranslation) => void;
	isEditMode: boolean;
	letterTemplateTranslation?: LetterTemplateTranslation;
	language?: TranslationLanguage;
}

export interface TranslationFormFields extends LetterTemplateTranslation {
	templateSubjectError?: string;
	templateContentError?: string;
}

const initialLetterTemplateTranslation: TranslationFormFields = {
	templateContent: '',
	templateSubject: '',
	templateSubjectError: '',
	templateContentError: ''
};

export const LetterTemplateTranslationModal: React.FC<Props> = props => {
	const [currentLetterTemplateTranslation, setCurrentLetterTemplateTranslation] = useState<TranslationFormFields>(
		initialLetterTemplateTranslation
	);
	const [subjectCursorStartPosition, setSubjectCursorStartPosition] = useState(0);
	const [subjectCursorEndPosition, setSubjectCursorEndPosition] = useState(0);
	const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);

	useEffect(() => {
		if (props.letterTemplateTranslation) {
			setCurrentLetterTemplateTranslation(_.cloneDeep(props.letterTemplateTranslation));
		}
		if (props.language) {
			let newState = { ...currentLetterTemplateTranslation };
			newState.languageId = props.language.systemLanguageId;
			newState.languageCode = props.language.code;
			newState.languageName = props.language.name;
			setCurrentLetterTemplateTranslation(newState);
		}
	}, []);

	useEffect(() => {
		if (props.noticeContent && !_.isEmpty(props.noticeContent) && props.language) {
			translateService.translateNoticeContent(
				props.noticeContent,
				setCurrentLetterTemplateTranslation,
				props.language
			);
		}
	}, [props.noticeContent, props.language]);

	const onSaveOrAdd = (event: any) => {
		if (ifFormIsValidToAddOrSave()) {
			props.addOrUpdateTranslation(currentLetterTemplateTranslation, props.isEditMode);
		}
	};

	const ifFormIsValidToAddOrSave = () => {
		let newState = { ...currentLetterTemplateTranslation };

		validationService.validateRequiredField(
			newState,
			'templateSubject',
			'templateSubjectError',
			localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')
		);

		validationService.validateRequiredField(newState, 'templateContent', 'templateContentError');

		const isFromValid = !validationService.hasError(newState, 'templateSubjectError', 'templateContentError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return (
			isFromValid &&
			validationService.validateMergeFields(
				(newState.templateContent || '') + (newState.templateSubject || ''),
				mergeFields
			) &&
			validationService.validateDoNotTranslateFields(
				(newState.templateContent || '') + (newState.templateSubject + '')
			)
		);
	};

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		Utils.onChangeMergeFieldHandler(
			e,
			currentLetterTemplateTranslation,
			subjectCursorStartPosition,
			subjectCursorEndPosition,
			setSubjectCursorStartPosition,
			setSubjectCursorEndPosition,
			setCurrentLetterTemplateTranslation
		);
	};

	const footer = () => {
		return (
			<>
				{props.isEditMode && (
					<div className="display-sm display-lg ie-margin justify-content-start">
						<button
							type="button"
							className="btn ai-secondary-delete"
							onClick={() => setShowDeleteConfirmationModal(true)}>
							{localizationService.getLocalizedString('screen.buttons.delete')}
						</button>
					</div>
				)}
				<div className="ml-auto">
					<button type="button" className="btn ai-action" onClick={onSaveOrAdd}>
						{localizationService.getLocalizedString(
							props.isEditMode ? 'screen.buttons.apply' : 'screen.buttons.add'
						)}
					</button>
					<button className="btn ai-white" onClick={props.close}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};
	return (
		<TakeOverModal
			noForm={true}
			title={localizationService.getLocalizedString(
				props.isEditMode ? 'languages.letterTemplate.editModalTitle' : 'languages.letterTemplate.addModalTitle',
				currentLetterTemplateTranslation.languageName as string
			)}
			cancel={props.close}
			footer={footer()}
			className="filter-modal">
			<div className="form-row">
				<div className="col-lg-8">
					<TextInput
						id="subject"
						name="templateSubject"
						value={currentLetterTemplateTranslation.templateSubject}
						label={localizationService.getLocalizedString('extractor.cleaningNotice.subject')}
						onChange={(evt: any) => {
							setCurrentLetterTemplateTranslation({
								...currentLetterTemplateTranslation,
								templateSubject: evt.target.value
							});
						}}
						isRequired={true}
					/>
				</div>
				<div className="col-lg-4">
					<MergeFieldDropDown
						id="mergeTags"
						name="mergeTags"
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.mergeTags')}
						hintText={localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.mergeTagsHint'
						)}
						onChange={onChangeMergeField}
					/>
				</div>
			</div>
			<div className="form-group required">
				<label>{localizationService.getLocalizedString('extractor.cleaningNotice.body')}</label>
				<div className="cleaning-notice-editor-template">
					<RichTextEditor
						editorContent={currentLetterTemplateTranslation.templateContent}
						noticeTemplateType={NoticeTemplateType.FogCleaningNotice}
						onChange={(evt: any) => {
							setCurrentLetterTemplateTranslation({
								...currentLetterTemplateTranslation,
								templateContent: evt.html
							});
						}}
						height="440px"
					/>
				</div>
			</div>
			{showDeleteConfirmationModal && (
				<DeleteModal
					key="deleteLetterTemplateTranslationConfirmModal"
					title={localizationService.getLocalizedString(
						'languages.letterTemplate.deleteModalTitle',
						currentLetterTemplateTranslation.languageName as string
					)}
					message={localizationService.getLocalizedString(
						'languages.letterTemplate.deleteModalMessage',
						currentLetterTemplateTranslation.languageName as string
					)}
					showModal={showDeleteConfirmationModal}
					onCancelButtonClick={() => setShowDeleteConfirmationModal(false)}
					onOkayButtonClick={() => props.deleteTranslation(currentLetterTemplateTranslation)}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</TakeOverModal>
	);
};
