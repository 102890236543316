import { Jurisdiction, MfaQuestion, OrganizationTypeName } from '@rcp/types';
import { IppUserProfile } from '@rcp/types/src';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IppTestConstants as Constants } from 'src/constants';
import { alertService, ApplicationState } from 'src/redux';
import { loadQuestions, loadStates } from 'src/redux/account/register';
import { apiService, localizationService, navigateTo, tokenService, urlService } from 'src/services';
import DownloadAggreementModal from '../service-provider/industry/download-signatory-agreement-modal';
import CollapsibleCard from '../widgets/collapsible-card/collapsible-card';
import KbqQuestions from './kbq-questions';
import KbqVerification from './kbq-verification';
import ProfileDetailsComponent from './profile-details';
import SecurityQuestions from './security-questions';

interface DispatchProps {
	loadStates: () => void;
	loadQuestions: () => void;
}
interface Props extends DispatchProps {
	states: Jurisdiction[];
	questions: MfaQuestion[];
	history: History;
}

const Profile: FC<Props> = (props: Props) => {
	const [verified, setVerified] = useState(false);
	const [profileDetails, setProfileDetails] = useState({} as IppUserProfile);
	const [showDownloadAgreement, setShowDownloadAgreement] = useState(false);

	useEffect(() => {
		getKbq();
		props.loadStates();
		if (!tokenService.isTokenValid(tokenService.getTokenOrDefault())) {
			navigateTo(props.history, '/signIn');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (verified) {
			getProfileDetails();
			props.loadQuestions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verified]);

	const getProfileDetails = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile?organizationRegulatoryProgramId=${
			tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
		}`;
		try {
			let response: IppUserProfile = await apiService.getResource(url);
			setProfileDetails(response);
		} catch (ex) {
			if (ex.statusCode === Constants.unauthorizedStatusCode) {
				alertService.addError(localizationService.getLocalizedString('errors.notAuthorized'));
				tokenService.clearToken();
				window.location.href = urlService.getLoginUrl('signOut=true');
			} else {
				alertService.addError(ex.message);
			}
		}
	};

	const getKbq = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/Account/KbqChallengeQuestion`;
		await apiService.getResource(url);
	};

	const toggleDownloadAggrementModal = () => {
		setShowDownloadAgreement(!showDownloadAgreement);
	};

	const requestSignatoryButton = (
		<div className="d-print-none mr-4 font-size-18px-regular">
			<button className="btn ai-action pl-2 pr-2 p-1" onClick={() => toggleDownloadAggrementModal()}>
				{localizationService.getLocalizedString(`ipp.buttons.requestSignatory`)}
			</button>
		</div>
	);

	const downloadAggreementModalProps = {
		showModal: showDownloadAgreement,
		modalTitle: localizationService.getLocalizedString(`ipp.accountPortal.downloadSignatoryAgreementModalTitle`),
		onCancel: toggleDownloadAggrementModal
	};

	return (
		<>
			{!verified && <KbqVerification onSuccess={() => setVerified(true)} onFailure={() => {}} />}
			{verified && (
				<div className="mx-auto">
					<div className="page-header">
						<h1>
							{!verified
								? localizationService.getLocalizedString('ipp.accountPortal.knowledgeBasedQuestion')
								: localizationService.getLocalizedString('ipp.accountPortal.profile')}
						</h1>
					</div>
					{localStorage.getItem('lastVisitProgram') === 'ipp' && (
						<CollapsibleCard
							key="profile-details"
							accordionType="profile-details"
							accordionHeaderElements={
								!profileDetails.isSignatory &&
								(tokenService.getTokenOrDefault().portalOrganizationTypeName || '').toLowerCase() ===
									OrganizationTypeName.Industry.toLowerCase()
									? requestSignatoryButton
									: undefined
							}
							accordionHeading={localizationService.getLocalizedString(
								'ipp.accountPortal.profileDetails'
							)}>
							<ProfileDetailsComponent
								getProfileDetails={getProfileDetails}
								states={props.states}
								profileDetails={profileDetails}
							/>
						</CollapsibleCard>
					)}
					<CollapsibleCard
						key="kbq-questions"
						className="mt-3"
						accordionType="kbq-questions"
						isHidden={true}
						accordionHeading={localizationService.getLocalizedString(
							'ipp.accountPortal.knowledgeBasedQuestions'
						)}>
						<KbqQuestions
							showEditButton={true}
							selectedQuestions={
								profileDetails.userQuestionAnswers
									? profileDetails.userQuestionAnswers.filter(
											question => question.questionTypeName === 'KBQ'
									  )
									: []
							}
							questions={
								props.questions
									? props.questions.filter(question => question.questionType === 'KBQ')
									: []
							}
						/>
					</CollapsibleCard>

					<CollapsibleCard
						key="sq-questions"
						className="mt-3"
						accordionType="sq-questions"
						isHidden={true}
						accordionHeading={localizationService.getLocalizedString(
							'ipp.accountPortal.securityQuestions'
						)}>
						<SecurityQuestions
							showEditButton={true}
							hideAgreement={true}
							selectedQuestions={
								profileDetails.userQuestionAnswers
									? profileDetails.userQuestionAnswers.filter(
											question => question.questionTypeName === 'SQ'
									  )
									: []
							}
							questions={
								props.questions
									? props.questions.filter(question => question.questionType === 'SQ')
									: []
							}
						/>
					</CollapsibleCard>
					<DownloadAggreementModal {...downloadAggreementModalProps} />
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.register };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadStates: () => dispatch(loadStates()),
		loadQuestions: () => dispatch(loadQuestions())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
