import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { AttachmentOwnership } from '@rcp/types';
import { ApplicationState, saveFacilityAttachment } from 'src/redux';
import { localizationService } from 'src/services';

interface StateProps {}

interface CompProps {
	facilityId: number;
	attachment: AttachmentOwnership;
}

interface DispatchProps {
	saveFacilityAttachment: (
		facilityId: number,
		facilityAttachmentToUpdate: AttachmentOwnership,
		succeededAlertMessage: string
	) => void;
}

type Props = StateProps & CompProps & DispatchProps;

export const FacilityAttachmentCommentsComp: React.FC<Props> = props => {
	const [isEditMode, setIsEditMode] = React.useState(false);
	const [comments, setComments] = React.useState('');

	React.useEffect(() => {
		setComments(props.attachment.comments ? props.attachment.comments : '');
	}, [props.attachment]);

	const changeComments = (e: any) => {
		const value = e.target.value;
		setComments(value);
	};

	const onEditMode = (e: any) => {
		e.preventDefault();
		setIsEditMode(true);
	};

	const cancelEditMode = (e: any) => {
		setIsEditMode(false);
	};

	const saveComments = (e: any) => {
		if (_.isEmpty(props.attachment.comments) && _.isEmpty(comments)) {
			return;
		}
		if (_.isEqual(props.attachment.comments, comments)) {
			setIsEditMode(false);
			return;
		}
		let attachmentCommentsToUpdate = {
			attachmentOwnershipId: props.attachment.attachmentOwnershipId,
			comments: comments
		} as AttachmentOwnership;
		let message = localizationService.getLocalizedString('alertMessages.savedSuccess', 'screen.labels.comment');

		props.saveFacilityAttachment(props.facilityId, attachmentCommentsToUpdate, message);
		setIsEditMode(false);
	};

	if (_.isEmpty(props.attachment)) {
		return <div />;
	}

	if (isEditMode || _.isEmpty(props.attachment.comments)) {
		const buttonLocalizedKey = isEditMode ? 'screen.buttons.save' : 'screen.buttons.addComment';
		const buttonClassName = isEditMode ? 'ai-save' : 'ai-action';
		return (
			<div className="d-sm-flex flex-row">
				<div className="mb-2">
					<img alt="UserImage" height="35" src="/img/icons/user.svg" className="d-block" />
				</div>
				<div className="flex-grow-1 mx-sm-2">
					<textarea id="comments" onChange={changeComments} className="form-control mb-2" value={comments} />
					<button className={`btn ${buttonClassName}`} onClick={saveComments}>
						{localizationService.getLocalizedString(buttonLocalizedKey)}
					</button>
					{isEditMode && (
						<button className="btn ai-white ml-2" onClick={cancelEditMode}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</button>
					)}
				</div>
			</div>
		);
	}
	return (
		<div className="d-flex flex-row">
			<div className="mb-2">
				<img alt="UserImage" height="35" src="/img/icons/user.svg" className="d-block" />
			</div>
			<div className="w-100 d-flex flex-row">
				<div className="flex-fill m-2">{props.attachment.comments}</div>
				<div className="m-2">
					<a href="#/" onClick={onEditMode}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityAttachments };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => ({
	saveFacilityAttachment: (
		facilityId: number,
		facilityAttachmentToUpdate: AttachmentOwnership,
		succeededAlertMessage: string
	) => dispatch(saveFacilityAttachment(facilityId, facilityAttachmentToUpdate, succeededAlertMessage))
});

export const FacilityAttachmentComments = connect<StateProps, DispatchProps, CompProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityAttachmentCommentsComp);
