import React from 'react';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { DropDownOption, emptyOption } from '@rcp/types';
import _ from 'lodash';
import './combo-box-dropdown.scss';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';

interface Props {
	id?: string;
	name: string;
	label?: string;
	className?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	placeholder?: string;
	withoutWrappingDiv?: boolean;
	value?: string;
	error?: string;
	onFocus?(e: any): void;
	onChange?(e: any): void;
	options: DropDownOption[];
	isCustomSorting?: boolean;
	labelClassName?: string;
	hintText?: string;
	clearButton?: boolean;
	itemRender?: (
		li: React.ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>>,
		itemProps: ListItemProps
	) => React.ReactNode;
	showSearchIcon?: boolean;
	customFilter?: (options: DropDownOption[], filter: FilterDescriptor) => DropDownOption[];
	customHintText?: React.ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>>;
}

export const ComboBoxDropdown: React.FC<Props> = props => {
	const [options, setOptions] = React.useState<DropDownOption[]>(props.options);
	const [selectedOption, setSelectedOption] = React.useState<DropDownOption>(emptyOption);
	const [showClearButton, setShowClearButton] = React.useState<boolean>(false);

	const setSelectedDropDownOptionByValue = (value: string, options: DropDownOption[]) => {
		if (value) {
			let option = options.find(o => _.toString(o.value) === _.toString(value));
			if (option) {
				setSelectedOption(option);
				return;
			}
		}
		setSelectedOption(emptyOption);
	};

	React.useEffect(() => {
		if (!props.isCustomSorting) {
			setOptions(_.sortBy(props.options, 'label'));
		} else {
			setOptions(props.options);
		}
		setSelectedDropDownOptionByValue(props.value as string, props.options);
	}, [props.value, props.options]);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setSelectedOption(value || emptyOption);
		if (props.onChange) {
			let newE = { target: { name: name, value: _.get(value, 'value') } };
			props.onChange(newE);
		}
	};

	const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const option: DropDownOption = itemProps.dataItem;
		const itemChildren = _.isEmpty(option.prefix) ? (
			<span>{option.label}</span>
		) : (
			<>
				<span className="dropdown-option-prefix">{option.prefix} - </span>
				<span>{option.label}</span>
			</>
		);
		return React.cloneElement(li, li.props, itemChildren);
	};

	const filterChange = (event: any) => {
		let filter = event.filter;
		setShowClearButton(!!event.filter.value);
		if (filter && props.options) {
			let newOptions = [...props.options];
			newOptions = props.customFilter ? props.customFilter(newOptions, filter) : filterBy(newOptions, filter);
			setOptions(newOptions);
		}
	};

	const getComboBox = (option: DropDownOption, options: DropDownOption[]) => {
		return (
			<ComboBox
				key={props.name}
				name={props.name}
				className={
					'w-100 value' + (props.error ? ' is-invalid' : '') + (props.showSearchIcon ? ' ai-search' : '')
				}
				data={options}
				textField="label"
				dataItemKey="value"
				value={option}
				onChange={handleChange}
				clearButton={props.clearButton ? props.clearButton : showClearButton}
				suggest={true}
				filterable={true}
				onFilterChange={filterChange}
				placeholder={props.placeholder || 'Search'}
				allowCustom={false}
				itemRender={props.itemRender ? props.itemRender : itemRender}
				disabled={props.isDisabled === true ? true : false}
				popupSettings={{ animate: false }}
				onFocus={props.onFocus}
			/>
		);
	};

	return _.isEmpty(props.label) ? (
		<div id={props.id} className={props.className}>
			{getComboBox(selectedOption, options)}
			{props.customHintText && <>{props.customHintText}</>}
			{props.hintText && <div className="ai-form-help">{props.hintText}</div>}
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</div>
	) : props.withoutWrappingDiv && props.label ? (
		<>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					{props.label}
				</label>
			) : (
				<label htmlFor={props.id}>{props.label}</label>
			)}
			{getComboBox(selectedOption, options)}
			{props.customHintText && <>{props.customHintText}</>}
			{props.hintText && <div className="ai-form-help">{props.hintText}</div>}
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</>
	) : (
		<div id={props.id} className={'form-group' + (props.isRequired ? ' required' : '')}>
			{props.isDisabled ? (
				<label className={props.labelClassName + ' disabled-feedback'} htmlFor={props.id}>
					{props.label}
				</label>
			) : (
				<label className={props.labelClassName} htmlFor={props.id}>
					{props.label}
				</label>
			)}
			{getComboBox(selectedOption, options)}
			{props.customHintText && <>{props.customHintText}</>}
			{props.hintText && <div className="ai-form-help">{props.hintText}</div>}
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</div>
	);
};
