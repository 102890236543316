import React, { FC, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { TextInput } from 'src/components/widgets';
import { AddGeneralNoticeSteps } from '@rcp/types';
import { localizationService, validationService } from 'src/services';
import { alertService, RootState } from 'src/redux';
import { useSelector } from 'react-redux';

export const Step1: FC = () => {
	const stepperContext = useContext(StepperContext);
	const [templateName, setTemplateName] = useState<string>('');
	const [templateNameError, setTemplateNameError] = useState<string>('');

	let generalNoticeTemplateSchedule = (state: RootState) => state.generalNoticeTemplate;
	let { selected: generalNoticeTemplate } = useSelector(generalNoticeTemplateSchedule);

	useEffect(() => {
		const step1Data = stepperContext.getData(AddGeneralNoticeSteps.STEP1);
		if (step1Data) {
			setTemplateName(step1Data.templateName);
		} else if (generalNoticeTemplate) {
			let templateName = generalNoticeTemplate.templateName || '';
			setTemplateName(templateName);
		}
	}, [generalNoticeTemplate]);

	const onClickNext = () => {
		let newState = { templateName, templateNameError };
		validationService.validateRequiredField(
			newState,
			'templateName',
			'templateNameError',
			localizationService.getLocalizedString('authoritySetting.noticesSettings.templateName')
		);
		setTemplateNameError(newState.templateNameError);
		if (newState.templateNameError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return;
		}
		alertService.clearAllMessages();
		stepperContext.resolve({ templateName });
	};

	return (
		<StepperContent
			className="w-100 mr-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<TextInput
				id="templateName"
				name="templateName"
				value={templateName}
				label={localizationService.getLocalizedString('authoritySetting.noticesSettings.templateName')}
				onChange={event => setTemplateName(event.target.value || '')}
				isRequired
				error={templateNameError}
			/>
		</StepperContent>
	);
};
