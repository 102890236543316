import { Action } from 'redux';
export interface LinkoRouteState {
	notFoundState: boolean;
}

export const initialLinkoRouteState: LinkoRouteState = {
	notFoundState: false
};

export enum LinkoRouteActionType {
	loadNotFoundPage = 'loadNotFoundPage',
	restoreInitialState = 'restoreInitialState'
}

export const LinkoRouteReducer = (state = initialLinkoRouteState, action: Action): LinkoRouteState => {
	switch (action.type) {
		case LinkoRouteActionType.loadNotFoundPage:
			state.notFoundState = true;
			return state;
		case LinkoRouteActionType.restoreInitialState:
			state.notFoundState = false;
			return state;
		default:
			return state;
	}
};
