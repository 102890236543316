import { ThunkAction } from 'redux-thunk';
import * as ActionList from './contact-actions';
import { apiService, Resource, urlService, localizationService } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../../alert';
import { ContactType } from '@rcp/types';

export type FacilityContactThunkAction = ThunkAction<
	any,
	ActionList.FacilityContactListState,
	any,
	ActionList.FacilityContactAction
>;

export const loadFacilityContact = (
	facilityId: number,
	includeDeviceContacts: boolean = false
): FacilityContactThunkAction => async (dispatch, getState) => {
	let url = urlService.getFacilityResourceApiUrl(facilityId, Resource.Contacts);
	if (includeDeviceContacts) {
		url = url + '?includeDeviceContacts=true';
	}
	const result = await apiService.httpGet(url);

	dispatch(ActionList.loadFacilityContactListAction(result));
};

/// removes contact from facility
export const removeContactFromFacility = (
	facilityId: number,
	entityContactId: number
): FacilityContactThunkAction => async (dispatch, getState) => {
	const facilityContactUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Contacts, entityContactId);
	await apiService.httpDelete(facilityContactUrl);
	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.removeSuccess', 'screen.labels.contact')
	);

	//after delete the facility contact load again
	dispatch(loadFacilityContact(facilityId, true));
	return true;
};

/// Delete Contact Permanently(does soft delete and will not show into any facility)
export const deleteContact = (facilityId: number, contactId: number): FacilityContactThunkAction => async (
	dispatch,
	getState
) => {
	const url = urlService.getAuthorityResourceApiUrl(Resource.Contacts, contactId);
	await apiService.httpDelete(url);

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.permanentlyRemoveSuccess', 'screen.labels.contact')
	);

	//after delete the facility contact load again
	dispatch(loadFacilityContact(facilityId, true));
	return true;
};

//load contact-type
export const loadContactType = (): FacilityContactThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityLookupResourceApiUrl(Resource.ContactTypes);
	const result = await apiService.httpGet(url);

	dispatch(ActionList.loadContactTypeListAction(result));
};

export const loadContactTypeIncludeInActive = (contactTypeId: number | undefined): FacilityContactThunkAction => async (
	dispatch,
	getState
) => {
	const url = urlService.getAuthorityLookupResourceApiUrl(Resource.ContactTypes);
	const result = await apiService.httpGet(`${url}?includeInactive=true`);
	let finalResult = result.filter((r: ContactType) => r.isActive || r.lookupId === contactTypeId);
	dispatch(ActionList.loadContactTypeListAction(finalResult));
};

export const loadAuthorityContact = (): FacilityContactThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(
		Resource.Contacts,
		`includes=contactId,firstName,lastName,companyName,jsonEmails,jsonNumbers,isBusiness`
	);
	const result = await apiService.httpGet(url);

	dispatch(ActionList.loadAuthorityContactListAction(result));
};

/// add contact for facility
export const addContactForFacility = (
	entityContact: ApiTypes.FacilityEntityContact
): FacilityContactThunkAction => async (dispatch, getState) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	const url = urlService.getFacilityResourceApiUrl(facilityId, Resource.Contacts);

	let postData = {
		items: [] as ApiTypes.FacilityEntityContact[]
	};

	delete entityContact.entityContactId;
	postData.items.push(entityContact);

	await apiService.httpPost(url, postData);

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.contact')
	);

	//after add the facility contact load again
	dispatch(loadFacilityContact(facilityId, true));
	return true;
};

/// update contact for facility
export const updateContactForFacility = (
	organizationRegulatoryProgramContact: ApiTypes.FacilityEntityContact,
	callbackOnSuccess?: () => void,
	facilityId?: number
): FacilityContactThunkAction => async (dispatch, getState) => {
	if (!facilityId) {
		facilityId = urlService.getFogFacilityIdOrThrowError();
	}

	const url = urlService.getFacilityResourceApiUrl(
		facilityId,
		Resource.Contacts,
		organizationRegulatoryProgramContact.entityContactId
	);

	await apiService.httpPatch(url, organizationRegulatoryProgramContact);

	if (callbackOnSuccess) {
		callbackOnSuccess();
	} else {
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'screen.labels.contact')
		);
	}

	//after update the facility contact load again
	dispatch(loadFacilityContact(facilityId, true));
	return true;
};
