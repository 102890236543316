import React from 'react';

import _ from 'lodash';
import { EventType, DropDownOption, Frequency } from '@rcp/types';
import { localizationService, validationService, apiService } from 'src/services';
import { TextInput, PopoverModal, ISettingUrlResolver, SingleSelectDropdown } from 'src/components/widgets';
import { alertService, useReduxSelector } from 'src/redux';
import { Label } from 'reactstrap';

interface OwnProps {
	isCreate: boolean;
	toggleModal: () => void;
	urlResolver: ISettingUrlResolver;
	eventType: EventType;
	callbackAfterSave?: () => void;
}

type Props = OwnProps;

interface InspectionEventTypeEditorState extends EventType {
	nameFormatError?: string;
}

const InspectionEventTypeEditor: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState<InspectionEventTypeEditorState>({});

	const setFormStateFromProps = (eventType?: EventType) => {
		let newState = { ...eventType };
		setFormState(newState);
	};
	const frequencyList = _.orderBy(
		useReduxSelector(state => state.extractors.frequencyList).filter(x => x.isActive),
		['frequencyCode'],
		['asc']
	);
	const existingInspections = useReduxSelector(state => state.authorityEventTypes.inspectionTypes);

	React.useEffect(() => {
		let newState = { ...props.eventType };
		if (props.isCreate) {
			newState.useFrequencyAssignedToFacility = false;
		}
		setFormState(newState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.eventType]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		if (newState.schedulingFrequencyId) {
			newState.useFrequencyAssignedToFacility = false;
		}
		setFormState(newState);
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'eventTypeAbbreviation',
			'nameFormatError',
			localizationService.getLocalizedString('authoritySetting.inspectionTypeSection')
		);

		//check if duplicate
		let duplicateName = false;
		if (props.isCreate) {
			if (
				existingInspections.some(i =>
					String.equalCaseInsensitive(i.eventTypeAbbreviation, formState.eventTypeAbbreviation)
				)
			) {
				duplicateName = true;
			}
		} else {
			if (
				existingInspections.some(
					i =>
						i.eventTypeId !== formState.eventTypeId &&
						i.eventTypeAbbreviation === formState.eventTypeAbbreviation
				)
			) {
				duplicateName = true;
			}
		}
		if (duplicateName === true) {
			newState.nameFormatError = localizationService.getLocalizedString(
				'authoritySetting.lookupValueError',
				localizationService.getLocalizedString('authoritySetting.inspectionTypeName')
			);
		}

		let isFormValid = !validationService.hasError(newState, 'nameFormatError');
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const saveButtonClicked = async () => {
		if (!validateBeforeForSave()) {
			return;
		}
		let inspectionEventTypeToSave = { ...formState };
		let response: any = false;

		try {
			if (props.isCreate) {
				let url = props.urlResolver.getSettingUrls().addUrl;
				await apiService.postResource<EventType>(url, inspectionEventTypeToSave);
				const message = localizationService.getLocalizedString(
					'authoritySetting.addSucceedMessage',
					localizationService.getLocalizedString('authoritySetting.inspectionTypeName')
				);
				alertService.addSuccess(message);
				response = true;
			} else {
				let url = props.urlResolver.getSettingUrls().updateUrl(formState.eventTypeId);
				await apiService.patchResource<EventType>(`${url}`, inspectionEventTypeToSave);
				let message = localizationService.getLocalizedString(
					'authoritySetting.updateSucceedMessage',
					localizationService.getLocalizedString('authoritySetting.inspectionTypeName'),
					_.toString(formState.eventTypeAbbreviation)
				);
				alertService.addSuccess(message);
				response = true;
			}

			props.toggleModal();
		} catch (ex) {
			alertService.addError(ex);
		}

		if (response === true) {
			if (props.callbackAfterSave) {
				props.callbackAfterSave();
			}
		}
	};

	const setUseFrequencyAssignedToFacility = (
		useFrequencyAssignedToFacility: boolean,
		nextEventStartingWith: string | null,
		schedulingFrequencyId: number | null,
		schedulingFrequency: string | null,
		shouldScheduleNextEventAutomatically: boolean = false
	) => {
		var newState = { ...formState };
		newState.useFrequencyAssignedToFacility = useFrequencyAssignedToFacility;
		newState.schedulingFrequencyId = schedulingFrequencyId;
		newState.schedulingFrequency = schedulingFrequency;
		newState.nextEventStartingWith = nextEventStartingWith;
		newState.shouldScheduleNextEventAutomatically = shouldScheduleNextEventAutomatically;
		setFormState(newState);
	};

	const cancelSave = () => {
		setFormStateFromProps(props.eventType);
		props.toggleModal();
	};

	const title = props.isCreate
		? localizationService.getLocalizedString('authoritySetting.addInspectionType')
		: localizationService.getLocalizedString('authoritySetting.editInspectionType');

	const modalFooterDiv = () => {
		return (
			<>
				<div className="ml-auto">
					<button className="btn ai-save ml-2" onClick={saveButtonClicked}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={cancelSave}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	const getFrequency = () => {
		let frequencyOptionValues: DropDownOption[] = [];
		if (frequencyList.length > 0) {
			frequencyOptionValues = frequencyList.map((frequency: Frequency) => {
				return { label: frequency.frequencyCode, value: frequency.frequencyId };
			});
		}

		return frequencyOptionValues;
	};

	let subTitle = props.isCreate
		? localizationService.getLocalizedString('authoritySetting.inspectionEventTypeModelSubTitleAdd')
		: localizationService.getLocalizedString('authoritySetting.inspectionEventTypeModelSubTitleEdit');

	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={title}
				footer={modalFooterDiv()}
				save={saveButtonClicked}
				cancel={cancelSave}>
				<p>{subTitle}</p>
				<TextInput
					id="eventTypeAbbreviation"
					name="eventTypeAbbreviation"
					label={localizationService.getLocalizedString('authoritySetting.inspectionTypeName')}
					value={formState.eventTypeAbbreviation}
					onChange={changeFormState}
					isRequired={true}
					error={formState.nameFormatError}
				/>
				<Label>{localizationService.getLocalizedString('authoritySetting.nextInspectionFrequency')}</Label>
				<div className="custom-control custom-radio">
					<input
						type="radio"
						className="custom-control-input"
						name="doNotScheduleNextInspectionEvent"
						id="doNotScheduleNextInspectionEvent"
						value="false"
						checked={
							formState.useFrequencyAssignedToFacility === false &&
							(formState.schedulingFrequencyId === undefined || formState.schedulingFrequencyId === null)
						}
						onChange={() => setUseFrequencyAssignedToFacility(false, null, null, null)}
					/>
					<label className="custom-control-label" htmlFor="doNotScheduleNextInspectionEvent">
						<span>
							{localizationService.getLocalizedString(
								'authoritySetting.doNotScheduleNextInspectionEvent'
							)}
						</span>
					</label>
				</div>

				<div className="custom-control custom-radio">
					<input
						type="radio"
						className="custom-control-input"
						name="useFrequencyAssignedToFacility"
						id="useFrequencyAssignedToFacility"
						value="false"
						checked={formState.useFrequencyAssignedToFacility === true}
						onChange={() => setUseFrequencyAssignedToFacility(true, 'DueDate', null, null, true)}
					/>
					<label className="custom-control-label" htmlFor="useFrequencyAssignedToFacility">
						<span>
							{localizationService.getLocalizedString('authoritySetting.useFrequencyAssignedToFacility')}
						</span>
					</label>
				</div>

				<div className="custom-control custom-radio ">
					<input
						type="radio"
						className="custom-control-input"
						name="useFrequencyAssignedToFacility"
						id="useAnotherFrequency"
						value="false"
						checked={
							formState.useFrequencyAssignedToFacility === false &&
							formState.schedulingFrequencyId !== undefined &&
							formState.schedulingFrequencyId !== null
						}
						onChange={() =>
							setUseFrequencyAssignedToFacility(
								false,
								'DueDate',
								frequencyList[0].frequencyId as number,
								frequencyList[0].frequencyCode,
								true
							)
						}
					/>
					<label className="custom-control-label" htmlFor="useAnotherFrequency">
						<span>{localizationService.getLocalizedString('authoritySetting.useAnotherFrequency')}</span>
					</label>
				</div>
				{formState.useFrequencyAssignedToFacility === false &&
					formState.schedulingFrequencyId !== undefined &&
					formState.schedulingFrequencyId !== null && (
						<div className="form-group ml-4">
							<SingleSelectDropdown
								id="schedulingFrequencyId"
								name="schedulingFrequencyId"
								label={localizationService.getLocalizedString('authoritySetting.frequency.frequency')}
								value={_.toString(formState.schedulingFrequencyId)}
								noEmptyOption={true}
								onChange={changeFormState}
								options={getFrequency()}
								withoutWrappingDiv={true}
							/>
						</div>
					)}
				{(formState.useFrequencyAssignedToFacility === true ||
					(formState.useFrequencyAssignedToFacility === false &&
						formState.schedulingFrequencyId !== undefined &&
						formState.schedulingFrequencyId !== null)) && (
					<>
						<Label>
							{localizationService.getLocalizedString(
								'authoritySetting.scheduleTheNextInspectionStartingWith'
							)}
						</Label>

						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="nextEventStartingWith"
								id="startingWithDueDate"
								value="DueDate"
								checked={formState.nextEventStartingWith === 'DueDate'}
								onChange={changeFormState}
							/>
							<label className="custom-control-label" htmlFor="startingWithDueDate">
								<span>
									{localizationService.getLocalizedString(
										'authoritySetting.nextEventStartingWithDueDate'
									)}
								</span>
							</label>
						</div>

						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="nextEventStartingWith"
								id="startingWithCompleteDate"
								value="CompleteDate"
								checked={formState.nextEventStartingWith === 'CompleteDate'}
								onChange={changeFormState}
							/>
							<label className="custom-control-label" htmlFor="startingWithCompleteDate">
								<span>
									{localizationService.getLocalizedString(
										'authoritySetting.nextEventStartingWithCompleteDate'
									)}
								</span>
							</label>
						</div>
					</>
				)}
			</PopoverModal>
		</div>
	);
};

export default InspectionEventTypeEditor;
