import React, { FC } from 'react';
import { localizationService, navigateTo, urlService } from 'src/services';
import { ColumnField, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import { GridOption } from 'src/features/data-grid/data-grid';
import IppReportPackageReviewsComponent from 'src/components/authority/ipp/report-packages/packages/reviews/reviews';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppPendingAcceptanceReportPackage: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showTabList: false,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackagePendingAcceptanceGrid,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.PendingAcceptance'),
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: {
			reportStatusName: ReportPackageStatus.PendingAcceptance,
			page: initialPage,
			sort: 'periodEndDateTimeLocal asc'
		},
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppPendingAcceptanceReportPackage;
