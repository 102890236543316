import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { siteSlice } from '../sites.slice';
import { PopoverModal, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { CccSite, DropDownOption } from '@rcp/types';
import { localizationService, optionsMap, urlService, validationService } from 'src/services';
import { alertService, RootState } from 'src/redux';
import _ from 'lodash';
import { settingLookupsSiteTypeSlice } from 'src/components/authority/backflow/settings/sites/site-type-lookup-slice';
import { settingLookupsSiteUseSlice } from '../../settings/sites/site-use-lookup-slice';
import { settingLookupsDataSetSlice } from '../../settings/dataset-lookup.slice';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentCccSite: CccSite;
}

type Props = OwnProps;

interface OverviewForm {
	siteTypeId?: number;
	siteUseId?: number;
	latitude: string;
	longitude: string;
	dataSetId?: number;
	latitudeError?: string;
	longitudeError?: string;
}

const initialOverviewForm: OverviewForm = {
	latitude: '',
	longitude: ''
};

export const CccSiteOverviewTabModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialOverviewForm);
	const [siteTypeValues, setSiteTypeValues] = React.useState([] as DropDownOption[]);
	const [siteUseValues, setSiteUseValues] = React.useState([] as DropDownOption[]);
	const [datasetValues, setDatasetValues] = React.useState([] as DropDownOption[]);
	const setFormStateFromProps = (site: CccSite) => {
		let newState = {
			siteTypeId: site.siteTypeId,
			siteUseId: site.siteUseId,
			latitude: site.latitude || '',
			longitude: site.longitude || '',
			dataSetId: site.dataSetId
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.currentCccSite);
	}, [props.currentCccSite]);

	React.useEffect(() => {
		dispatch(
			settingLookupsSiteTypeSlice.fetchAll(
				urlService.toQueryString({
					includeInactive: 'true'
				})
			)
		);
		dispatch(
			settingLookupsSiteUseSlice.fetchAll(
				urlService.toQueryString({
					includeInactive: 'true'
				})
			)
		);
		dispatch(
			settingLookupsDataSetSlice.fetchAll(
				urlService.toQueryString({
					includeInactive: 'true'
				})
			)
		);
	}, [dispatch]);

	let { result: siteTypes } = useSelector((state: RootState) => state.settingLookupSiteTypes);
	let { result: siteUses } = useSelector((state: RootState) => state.settingLookupSiteUses);
	let { result: dataSets } = useSelector((state: RootState) => state.settingLookupDataSets);

	useEffect(() => {
		let siteTypeOptions: DropDownOption[] = [];
		let siteUseOptions: DropDownOption[] = [];
		let dataSetOptions: DropDownOption[] = [];
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { state, activeDropDownList: siteTyes } = optionsMap.resolveDropdownOptionsWithInactiveOrDeletedId(
			siteTypes,
			props.currentCccSite.siteTypeId as number
		);

		siteTypeOptions = optionsMap.fromLookups(
			siteTyes || [],
			props.currentCccSite.siteTypeId,
			props.currentCccSite.siteType
		);
		setSiteTypeValues(siteTypeOptions);

		const {
			state: siteUseState,
			activeDropDownList: siteUseList
		} = optionsMap.resolveDropdownOptionsWithInactiveOrDeletedId(
			siteUses,
			props.currentCccSite.siteUseId as number
		);

		siteUseOptions = optionsMap.fromLookups(
			siteUseList || [],
			props.currentCccSite.siteUseId,
			props.currentCccSite.siteUse
		);
		setSiteUseValues(siteUseOptions);

		const {
			state: siteDataSetState,
			activeDropDownList: sitedataSetsList
		} = optionsMap.resovleDatesetOptionsWithInactiveOrDeletedId(
			dataSets || [],
			props.currentCccSite.dataSetId as number
		);
		dataSetOptions = optionsMap.fromDataset(
			sitedataSetsList || [],
			props.currentCccSite.dataSetId,
			props.currentCccSite.dataSetName
		);
		setDatasetValues(dataSetOptions);
	}, [siteTypes, siteUses, dataSets, props.currentCccSite]);

	const saveOverview = () => {
		if (!validateOverviewDataForSave()) {
			return;
		}
		let siteToUpdate: CccSite = {
			siteTypeId: formState.siteTypeId,
			siteUseId: formState.siteUseId,
			latitude: formState.latitude,
			longitude: formState.longitude,
			dataSetId: formState.dataSetId
		};
		dispatch(
			siteSlice.patchOne(
				urlService.getCccSiteId(),
				siteToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccSite.site')
				),
				() => props.toggle()
			)
		);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const cancelSaveOverview = () => {
		props.toggle();
	};

	const validateOverviewDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;
		validationService.validateLatitudeField(
			newState,
			'latitude',
			'latitudeError',
			localizationService.getLocalizedString('cccSite.latitude'),
			false
		);
		validationService.validateLongitudeField(
			newState,
			'longitude',
			'longitudeError',
			localizationService.getLocalizedString('cccSite.longitude'),
			false
		);

		isFormValid = !validationService.hasError(newState, 'latitudeError', 'longitudeError');
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('cccSite.editOverview')}
				save={saveOverview}
				cancel={cancelSaveOverview}>
				<SingleSelectDropdown
					id="dataSetId"
					name="dataSetId"
					label={localizationService.getLocalizedString('cccSite.dataSetName')}
					value={_.toString(formState.dataSetId)}
					onChange={changeFormState}
					options={datasetValues}
					noEmptyOption={true}
					isRequired={true}
				/>
				<SingleSelectDropdown
					id="siteTypeId"
					name="siteTypeId"
					label={localizationService.getLocalizedString('cccSite.siteType')}
					value={_.toString(formState.siteTypeId)}
					onChange={changeFormState}
					options={siteTypeValues}
				/>
				<SingleSelectDropdown
					id="siteUseId"
					name="siteUseId"
					label={localizationService.getLocalizedString('cccSite.siteUse')}
					value={_.toString(formState.siteUseId)}
					onChange={changeFormState}
					options={siteUseValues}
				/>
				<div className="form-row">
					<TextInput
						id="latitude"
						name="latitude"
						className="form-group col-sm-6"
						type="number"
						label={localizationService.getLocalizedString('cccSite.latitude')}
						value={formState.latitude}
						error={formState.latitudeError}
						onChange={changeFormState}
					/>
					<TextInput
						id="longitude"
						name="longitude"
						type="number"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('cccSite.longitude')}
						value={formState.longitude}
						error={formState.longitudeError}
						onChange={changeFormState}
					/>
				</div>
			</PopoverModal>
		</div>
	);
};
