import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
	ColumnDefinition,
	IppIndustrySample,
	IppReportPackageParameter,
	IppReportPackageParameterGroup,
	IppSampleResult
} from '@rcp/types';
import _ from 'lodash';
import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { ApplicationState } from 'src/redux';
import { SampleState, setSampleData, setSelectedParametersData } from 'src/redux/ipp/industry/samples';
import { localizationService } from 'src/services';

interface DispatchProps {
	setSampleData: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	setSelectedParameters: (parameters: IppReportPackageParameter[]) => Promise<boolean | undefined>;
}
interface Props extends DispatchProps {
	parameterGroups?: IppReportPackageParameterGroup[];
	sample: IppIndustrySample;
	selectedParameters?: IppReportPackageParameter[];
}

const IppSampleParameterGroupsComponent: FC<Props> = (props: Props) => {
	const gridRef: RefObject<Grid> = createRef();
	const [sort, setSort] = useState([{ field: 'name', dir: 'asc' }] as SortDescriptor[]);
	let columnsKeys = ['name', 'description'];
	useEffect(() => {
		return () => {
			props.setSelectedParameters([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (): ColumnDefinition[] => {
		columnsKeys.push('plus');
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(columnsKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(
				`ipp.reportPackage.parameterGroup.columns.${item.field}`
			);
			if (item.field === 'plus') {
				item.title = localizationService.getLocalizedString(`ipp.availableSelectedGrids.${item.field}`);
			}
		});
		return defaultColumnsDefinitions;
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (i.field === 'plus') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={getAddButton}
							headerClassName="align-center plus-minus-header-width"
						/>
					);
				}
				return (
					<GridColumn
						key={`column_key${i.field}`}
						field={i.field}
						title={i.title}
						cell={localizeCellValue}
						headerClassName="align-center"
					/>
				);
			});
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td className="align-center">{localizationService.formatValue(fieldValue)}</td>;
	};

	const getAddButton = (props: any) => {
		return (
			<>
				<td className="align-right pr-0">
					<button
						type="button"
						className="btn ai-action"
						onClick={() => addSelectedParameters(props.dataItem)}>
						<span className={`k-icon k-i-${props.field}`} />
					</button>
				</td>
			</>
		);
	};

	const addSelectedParameters = (parameterGroup: IppReportPackageParameterGroup) => {
		let parameters = parameterGroup.parameters;
		props.setSelectedParameters([...(props.selectedParameters || []), ...(parameters || [])]);
		let sampleParameters =
			parameters &&
			parameters.map(parameter => {
				return {
					parameterName: parameter.name,
					unitName: parameter.defaultUnitName,
					unitId: parameter.defaultUnitId,
					parameterId: parameter.parameterId
				};
			});
		let groupSelectedParameters = _.keyBy(sampleParameters, parameter => parameter.parameterName);

		let sampleResults: IppSampleResult[] = [...(props.sample.ippSampleResults || [])];
		let groupSampleResultsByParameters = _.keyBy(sampleResults, parameter => parameter.parameterName);
		let addedSampleResults = { ...groupSelectedParameters, ...groupSampleResultsByParameters };

		let sample = { ...props.sample };
		sample.ippSampleResults = Object.values(addedSampleResults) as IppSampleResult[];
		props.setSampleData(sample);
	};
	let columnsDefinitions = getColumnDefinitions();
	let columns = generateColumns(columnsDefinitions);

	return (
		<>
			<CollapsibleCard
				className="mt-3"
				accordionType="parameter-groups"
				accordionHeading={localizationService.getLocalizedString(`ipp.samples.step2.parameterGroups`)}>
				<Grid
					className="table"
					ref={gridRef}
					scrollable="none"
					sort={sort}
					sortable
					onSortChange={e => {
						setSort(e.sort);
					}}
					data={orderBy((props.parameterGroups || []).slice(), sort)}>
					{columns}
				</Grid>
			</CollapsibleCard>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): SampleState => {
	return { ...state.ippIndustrySample };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		setSampleData: (sample: IppIndustrySample) => dispatch(setSampleData(sample)),
		setSelectedParameters: (parameters: IppReportPackageParameter[]) =>
			dispatch(setSelectedParametersData(parameters))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppSampleParameterGroupsComponent);
