import DOMPurify from 'dompurify';
import _ from 'lodash';
import $ from 'jquery';

import { openSignaturePad } from 'src/components/layout/signature-modal';

class DocumentService {
	htmlToText(htmlContent: string): string {
		let tempElement = document.createElement('div');
		tempElement.innerHTML = htmlContent;
		return tempElement.textContent || tempElement.innerText || '';
	}

	private static appendSingleLineClass = (node: Element) => {
		let elementClass = node.getAttribute('class') as string;
		if (!_.toLower(elementClass).includes('input-single-line')) {
			node.setAttribute(
				'class',
				_.isEmpty(elementClass) ? 'input-single-line' : elementClass + ' input-single-line'
			);
		}
	};

	private static createInputElement = (
		id: string,
		name: string,
		value: string | undefined,
		withInputGroup = false
	) => {
		const inputElement = document.createElement('input');
		inputElement.setAttribute('id', id);
		inputElement.setAttribute('name', name);
		inputElement.setAttribute('type', 'text');
		inputElement.setAttribute('value', value || '');
		inputElement.setAttribute('class', 'form-control value');
		inputElement.setAttribute('disabled', 'true');
		if (withInputGroup === false) {
			return inputElement;
		}
		const inputGroupElement = document.createElement('div');
		inputGroupElement.setAttribute('class', 'input-group');
		inputGroupElement.appendChild(inputElement);
		return inputGroupElement;
	};
	static flipFormInputElementToViewMode = (node: Element, elementIdsToHide: string[]) => {
		if (!node.tagName || !node.parentElement) {
			return;
		}
		const tagName = node.tagName.toLowerCase();
		const id = node.getAttribute('id');
		if (id && elementIdsToHide.includes(id)) {
			let hiddenClass = node.className + ' invisible';
			node.setAttribute('class', hiddenClass);
		}
		if (tagName === 'input' && node.getAttribute('type') === 'checkbox') {
			node.setAttribute('disabled', 'true');
			if (node.parentElement) {
				let newClass = node.parentElement.getAttribute('class') + ' readOnlyCheckbox';
				node.parentElement.setAttribute('class', newClass);
			}
			return;
		}
		if (tagName === 'input') {
			if (node.getAttribute('type') === 'number') {
				node.setAttribute('type', 'text');
			}
			node.setAttribute('disabled', 'true');
			node.removeAttribute('style');
			node.removeAttribute('min');
			node.removeAttribute('max');
			DocumentService.appendSingleLineClass(node.parentElement);
			return;
		}
		if (tagName === 'textarea') {
			const textareaEl = node as HTMLTextAreaElement;
			textareaEl.setAttribute('disabled', 'true');
			DocumentService.appendSingleLineClass(textareaEl);
			return;
		}
		if (node.className && _.indexOf(node.className, '.signature-pad') > -1) {
			node.removeAttribute('onclick');
			return;
		}
		if (tagName === 'select') {
			const selectEl = node as HTMLSelectElement;
			let selectId = selectEl.getAttribute('id') || selectEl.getAttribute('name');
			if (!selectId) {
				return;
			}
			let selectedValue = !!selectEl.options.length ? selectEl.options[selectEl.selectedIndex].text : '';
			const newElem = DocumentService.createInputElement(selectId, selectId, selectedValue);
			DocumentService.appendSingleLineClass(node.parentElement);
			node.parentElement.replaceChild(newElem, node);
			return;
		}
		if (tagName === 'span' && node.className.includes('k-widget k-datepicker')) {
			const getFirstSubInputNode = (element: Element): HTMLInputElement | undefined => {
				for (let i = 0; i < element.childElementCount; i++) {
					let childNode = element.childNodes[i] as Element;
					if (_.toLower(childNode.tagName) === 'input') {
						return childNode as HTMLInputElement;
					}
					if (childNode.childElementCount > 0) {
						return getFirstSubInputNode(childNode);
					}
				}
			};
			let inputEl = getFirstSubInputNode(node);
			if (!inputEl) {
				return;
			}
			let inputId = inputEl.getAttribute('id') || inputEl.getAttribute('name');
			if (!inputId || !inputEl.value) {
				return;
			}
			const newElem = DocumentService.createInputElement(inputId, inputId, inputEl.value, true);
			let tweakedClass = node.parentElement.className + ' input-single-line';
			node.parentElement.setAttribute('class', tweakedClass);
			node.parentElement.replaceChild(newElem, node);
			return;
		}
	};

	disableInputFields = (disabled: boolean) => {
		let selector = `input`;
		$(selector).prop('disabled', disabled);
		selector = `select`;
		$(selector).prop('disabled', disabled);
		selector = `textarea`;
		$(selector).prop('disabled', disabled);
		selector = `.signature-pad>img`;
		$(selector).prop('onclick', null);
		if (!disabled) {
			$(selector).on('click', (event: any) => {openSignaturePad(event.target)});
		}
	};

	sanitizeHtmlFormForDisplayOnly = (htmlContent: string, ...elementIdsToHide: string[]): string => {
		DOMPurify.addHook('uponSanitizeElement', node => {
			DocumentService.flipFormInputElementToViewMode(node, elementIdsToHide);
		});
		let sanitizedHtml = DOMPurify.sanitize(htmlContent);
		sanitizedHtml = sanitizedHtml.replaceAll('for="btnFileImport" ', 'for="btnFileImport" hidden="true:" ');
		return sanitizedHtml;
	};
}

export const documentService = new DocumentService();
