import React, { Component } from 'react';
import { RouterProps } from 'react-router';
import { Action } from 'redux';
import { alertService, ApplicationState } from 'src/redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';
import * as LinkoTypes from '@rcp/types';
import { PopoverModal, SingleCheckbox, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { loadUserDetails, updateUser, UsersState } from 'src/redux/ipp/authority/authority-account/users';
import {
	ApiService,
	DateUtilService,
	localizationService,
	navigateTo,
	tokenService,
	urlService,
	validationService
} from 'src/services';
import { DeleteModal, DeleteModalProp } from '../../../../widgets/delete-modal';
import { CustomModal } from 'src/components/widgets/modal/modal';
import UserSecurityQuestions from './user-security-questions';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { WithAqiSsoFeatureFlag } from 'src/components/authority/shared/settings/users/with-aqi-sso-feature-flag';
import copy from 'copy-to-clipboard';
let role = [
	{ role: localizationService.getLocalizedString('ipp.authorityAccount.roles.administrator') },
	{ role: localizationService.getLocalizedString('ipp.authorityAccount.roles.standard') }
];

let optionValues: LinkoTypes.DropDownOption[] = [];

interface DispatchProps {
	loadDetails: (userProfileId: number) => Promise<any>;
	updateUser: (user: LinkoTypes.IppUser, userId?: number, callbackOnSuccess?: any, userName?: string) => Promise<any>;
}

interface State {
	userData: LinkoTypes.IppUser;
	isDataChanging: boolean;
	removeUserModal: boolean;
	resetUserModal: boolean;
	resetPasswordModal: boolean;
	isCopied: boolean;
	passwordResetLink: string;
	lockUserModal: boolean;
	enableUserModal: boolean;
	resetUser: {
		isAccountResetRequired: boolean;
		newEmailAddress?: string;
		newEmailAddressError?: string;
	};
	isAccessDenied: boolean;
}

interface Props extends UsersState, DispatchProps, LinkoTypes.RouteProps, RouterProps {
	userProfileId?: number;
}

class IppUserDetailsComp extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			userData: {} as LinkoTypes.IppUser,
			isDataChanging: false,
			removeUserModal: false,
			resetUserModal: false,
			resetPasswordModal: false,
			isCopied: false,
			passwordResetLink: '',
			lockUserModal: false,
			enableUserModal: false,
			resetUser: {
				isAccountResetRequired: false,
				newEmailAddress: '',
				newEmailAddressError: ''
			},
			isAccessDenied: false
		} as State;
	}

	componentDidMount = async () => {
		let response = await this.props.loadDetails(this.props.match.params.userId);
		if (
			response &&
			response.statusCode === 403 &&
			response.message === localizationService.getLocalizedString('errors.noPermissionToAccess')
		) {
			alertService.clearAllMessages();
			this.setState({ isAccessDenied: true });
		}
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (state.userData !== props.userDetails && !state.isDataChanging) {
			return { userData: { ...props.userDetails } };
		} else {
			return { state };
		}
	}

	changedUserDetails = (userDetailsFromApi: any, changedUserDetails: any) => {
		var details: string[] = _.union(_.keys(userDetailsFromApi), _.keys(changedUserDetails));
		var modifiedDetails: string[] = _.filter(details, (detail: string) => {
			return userDetailsFromApi[detail] !== changedUserDetails[detail];
		});
		return _.pick(this.state.userData, modifiedDetails);
	};

	changeUserStatus = (statusToBeChanged: string) => {
		const unlockEvent = 'ManualActionUnlock';
		const lockEvent = 'ManualAction';
		this.setState(
			prevState => {
				return {
					userData: {
						...prevState.userData,
						[statusToBeChanged]: !(prevState.userData as any)[statusToBeChanged]
					},
					isDataChanging: true
				};
			},
			async () => {
				let userListPath = urlService.isIppIndustryPortal()
					? urlService.getIppIndustryUrl(`industryAccount/users`)
					: '/ipp/authority/authorityAccount/users';
				let dataToBeChanged = this.changedUserDetails(this.props.userDetails, this.state.userData);
				if (_.keys(dataToBeChanged).includes('isAccountLocked')) {
					dataToBeChanged.accountLockEvent = dataToBeChanged.isAccountLocked ? lockEvent : unlockEvent;
				}
				delete dataToBeChanged.role;
				delete dataToBeChanged.isAdministrator;
				await this.props.updateUser(
					dataToBeChanged,
					this.state.userData.ippUserId,
					statusToBeChanged === 'isRemoved' && (() => navigateTo(this.props.history, userListPath)),
					this.state.userData.fullName || ''
				);
				statusToBeChanged === 'isAccountLocked' && this.onToggleLockUserModal();
				statusToBeChanged === 'isEnabled' && this.onToggleEnableUserModal();
				statusToBeChanged !== 'isRemoved' && this.props.loadDetails(this.props.match.params.userId);
			}
		);
	};

	onToggleRemoveUserModal = () => {
		this.setState(prevState => {
			return {
				removeUserModal: !prevState.removeUserModal
			};
		});
	};

	onToggleLockUserModal = () => {
		this.setState(prevState => {
			return {
				lockUserModal: !prevState.lockUserModal
			};
		});
	};

	onToggleEnableUserModal = () => {
		this.setState(prevState => {
			return {
				enableUserModal: !prevState.enableUserModal
			};
		});
	};

	onRemoveUser = () => {
		this.changeUserStatus('isRemoved');
	};

	onToggleResetUserModal = () => {
		this.setState(prevState => {
			return {
				resetUserModal: !prevState.resetUserModal,
				resetUser: {
					...prevState.resetUser,
					newEmailAddress: prevState.userData.email || ''
				}
			};
		});
	};

	onToggleResetPasswordModal = () => {
		this.setState(prevState => {
			return {
				resetPasswordModal: !prevState.resetPasswordModal
			};
		});
	};

	onResetUser = () => {
		let isEmailValid = true;
		this.setState(
			prevState => {
				return {
					resetUser: { ...prevState.resetUser, isAccountResetRequired: true },
					isDataChanging: true
				};
			},
			() => {
				let resetUserData = { ...this.state.resetUser };
				if (resetUserData.newEmailAddress) {
					isEmailValid = validationService.validateEmailFormatField(
						resetUserData,
						'newEmailAddress',
						'newEmailAddressError'
					);
					let emailError = resetUserData.newEmailAddressError;
					this.setState(prevState => {
						return {
							resetUser: {
								...prevState.resetUser,
								newEmailAddressError: emailError
							}
						};
					});
				}
				delete resetUserData.newEmailAddressError;
				if (isEmailValid) {
					let browserPath = window.location.pathname;
					let industryId = '';
					const industryIdIndex = 4;
					this.isIndustryUserModule() && (industryId = browserPath.split('/')[industryIdIndex]);
					let userPath = '/ipp/authority/authorityAccount/users';
					let industryUserPath = `/ipp/authority/industries/${industryId}/users`;
					(resetUserData.newEmailAddress === '' ||
						resetUserData.newEmailAddress === this.state.userData.email) &&
						delete resetUserData.newEmailAddress;
					this.props.updateUser(resetUserData, this.state.userData.ippUserId, () =>
						navigateTo(this.props.history, this.isIndustryUserModule() ? industryUserPath : userPath)
					);
				}
			}
		);
	};

	changeEmailOnReset = (email: string) => {
		this.setState(prevState => {
			return {
				resetUser: {
					...prevState.resetUser,
					newEmailAddress: email,
					newEmailAddressError: !email ? '' : prevState.resetUser.newEmailAddressError
				},
				isDataChanging: true
			};
		});
	};

	formatValue(value: any): any {
		if (typeof value === 'boolean') {
			return value === true
				? localizationService.getLocalizedString('ipp.constants.active')
				: localizationService.getLocalizedString('ipp.constants.inactive');
		}
		return value;
	}

	isIndustryUserModule = () => {
		const authorityIndustryPath = 'authority/industries';
		return window.location.href.includes(authorityIndustryPath);
	};

	getNewPasswordResetLink = async () => {
		if (this.state.userData.userProfileId) {
			let url = urlService.getPasswordResetLinkApiUrl(this.state.userData.userProfileId);
			let result = await new ApiService().httpGet(url);
			this.setState(_prevState => {
				return {
					passwordResetLink: result
				};
			});
		}
	};

	copyTextToClipboard = async (text: string) => {
		copy(text);
		alertService.addInfo(localizationService.getLocalizedString('ipp.users.linkCopiedSuccessMessage'));
	};

	passwordResetModalFooterDiv = () => {
		return (
			<>
				<button
					id="new-link-button"
					type="button"
					className="btn ai-action ml-0"
					onClick={this.getNewPasswordResetLink}>
					{localizationService.getLocalizedString('ipp.users.newLink')}
				</button>
				<button
					id="copy-link-button"
					type="button"
					className="btn ai-white"
					onClick={() => this.copyTextToClipboard(this.state.passwordResetLink)}>
					{localizationService.getLocalizedString('ipp.users.copyLink')}
				</button>
			</>
		);
	};
	render() {
		if (role.length > 0) {
			optionValues = role.map(role => {
				return { label: role.role, value: role.role.replace(/ /g, '') };
			});
		}
		let removeUserModalProp: DeleteModalProp = {
			title: localizationService.getLocalizedString(`ipp.authorityAccount.removeUserHeading`),
			message: localizationService.getLocalizedString(
				'alertMessages.deleteUserInfo',
				this.state.userData.fullName || '',
				tokenService.getTokenOrDefault()!.portalOrganizationName || ''
			),
			showModal: this.state.removeUserModal,
			onCancelButtonClick: this.onToggleRemoveUserModal,
			onOkayButtonClick: this.onRemoveUser,
			okayButtonText: localizationService.getLocalizedString('ipp.buttons.remove'),
			isDeleteButton: true
		};
		let lockModalProp: DeleteModalProp = {
			title: localizationService.getLocalizedString(
				`ipp.authorityAccount.${
					this.props.userDetails.isAccountLocked ? 'unlockUserHeading' : 'lockUserHeading'
				}`
			),
			message: localizationService.getLocalizedString(
				`ipp.authorityAccount.${
					this.props.userDetails.isAccountLocked ? 'unlockUserMessage' : 'lockUserMessage'
				}`
			),
			showModal: this.state.lockUserModal,
			onCancelButtonClick: this.onToggleLockUserModal,
			onOkayButtonClick: () => this.changeUserStatus('isAccountLocked'),
			okayButtonText: localizationService.getLocalizedString(
				`ipp.buttons.${this.props.userDetails.isAccountLocked ? 'unlock' : 'lock'}`
			),
			isDeleteButton: false
		};
		let enableModalProp: DeleteModalProp = {
			title: localizationService.getLocalizedString(
				`ipp.authorityAccount.${this.props.userDetails.isEnabled ? 'disableUserHeading' : 'enableUserHeading'}`
			),
			message: localizationService.getLocalizedString(
				`ipp.authorityAccount.${this.props.userDetails.isEnabled ? 'disableUserMessage' : 'enableUserMessage'}`
			),
			showModal: this.state.enableUserModal,
			onCancelButtonClick: this.onToggleEnableUserModal,
			onOkayButtonClick: () => this.changeUserStatus('isEnabled'),
			okayButtonText: localizationService.getLocalizedString(
				`ipp.buttons.${this.props.userDetails.isEnabled ? 'disable' : 'enable'}`
			),
			isDeleteButton: false
		};
		const shouldDisabledSaveBtn =
			this.props.userDetails.isAccountLocked ||
			(this.isIndustryUserModule() && this.state.userData.isAccountResetRequired);
		const saveBtnClass = shouldDisabledSaveBtn
			? 'btn btn-secondary disabled ml-auto mr-2'
			: 'btn ai-save ml-auto mr-2';
		return this.state.isAccessDenied ? (
			<AccessDeniedPage />
		) : (
			<>
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.authorityAccount.userDetails')}</h1>
				</div>
				<section>
					<div className="d-flex align-items-center">
						<span className="font-size-20px-regular">
							{localizationService.getLocalizedString('ipp.authorityAccount.userInformation')}
						</span>
						{(this.isIndustryUserModule() ||
							(urlService.isAdministrator() &&
								this.state.userData.userProfileId !== this.props.userProfileId)) && (
							<span className="ml-auto">
								{!urlService.isIppIndustryPortal() && (
									<button
										id="lock-unlock-btn"
										className="btn ai-action mr-2"
										onClick={() => {
											this.onToggleLockUserModal();
										}}>
										{this.props.userDetails.isAccountLocked
											? localizationService.getLocalizedString(`ipp.buttons.unlock`)
											: localizationService.getLocalizedString(`ipp.buttons.lock`)}
									</button>
								)}
								{!this.isIndustryUserModule() && (
									<>
										<button
											id="disable-enable-btn"
											className="btn ai-action mr-2"
											onClick={() => {
												this.onToggleEnableUserModal();
											}}>
											{this.props.userDetails.isEnabled
												? localizationService.getLocalizedString(`ipp.buttons.disable`)
												: localizationService.getLocalizedString(`ipp.buttons.enable`)}
										</button>
										<button
											id="toggle-remove-btn"
											className="btn ai-delete mr-2"
											onClick={() => {
												this.onToggleRemoveUserModal();
											}}>
											{localizationService.getLocalizedString(`ipp.buttons.remove`)}
										</button>
									</>
								)}
								{!urlService.isIppIndustryPortal() && (
									<>
										<button
											id="toggle-reset-btn"
											className="btn ai-action mr-2"
											onClick={() => {
												this.onToggleResetUserModal();
											}}>
											{localizationService.getLocalizedString(`ipp.buttons.reset`)}
										</button>
										<button
											id="toggle-reset-btn"
											className="btn ai-white mr-2"
											onClick={() => {
												this.onToggleResetPasswordModal();
											}}>
											{localizationService.getLocalizedString(`ipp.buttons.passwordReset`)}
										</button>
									</>
								)}
							</span>
						)}
					</div>

					<hr />
					<div className="form-row">
						<TextInput
							id="firstName"
							name="FirstName"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.firstName'
							)}
							value={this.state.userData.firstName}
							isDisabled={true}
						/>
						<TextInput
							id="lastName"
							name="LastName"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.lastName'
							)}
							value={this.state.userData.lastName}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="phone"
							name="PhoneNumber"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.phoneNumber'
							)}
							type="tel"
							value={this.state.userData.phoneNumber || ''}
							isDisabled={true}
						/>
						<TextInput
							id="ext"
							name="phoneExt"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.phoneExt'
							)}
							type="tel"
							value={(this.state.userData.phoneExt || '').toString()}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="email"
							name="Email"
							label={localizationService.getLocalizedString('ipp.authorityAccount.userListColumns.email')}
							type="email"
							value={this.state.userData.email}
							isDisabled={true}
						/>
						<TextInput
							id="registrationDateTimeUtc"
							name="registrationDateTimeUtc"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.registrationDateTimeUtc'
							)}
							value={`${DateUtilService.toDisplayDate(
								this.state.userData.registrationDateTimeUtc
							)} ${DateUtilService.toDisplayTime(
								this.state.userData.registrationDateTimeUtc
							).toUpperCase()}`}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="isEnabled"
							name="isEnabled"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.isEnabled'
							)}
							value={this.formatValue(this.props.userDetails.isEnabled)}
							isDisabled={true}
						/>
						<TextInput
							id="isAccountLocked"
							name="isAccountLocked"
							label={localizationService.getLocalizedString(
								'ipp.authorityAccount.userListColumns.isAccountLocked'
							)}
							value={localizationService.formatValue(this.props.userDetails.isAccountLocked)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						{this.isIndustryUserModule() ? (
							<>
								<TextInput
									id="industry-role"
									name="role"
									label={localizationService.getLocalizedString(
										'ipp.authorityAccount.userListColumns.role'
									)}
									value={this.state.userData.role}
									isDisabled={this.isIndustryUserModule()}
								/>
								<SingleCheckbox
									id="signatory"
									name="isSignatory"
									label={localizationService.getLocalizedString(
										`ipp.pendingRegistrationUser.fields.isSignatory.label`
									)}
									checked={this.state.userData.isSignatory || false}
									className="align-self-center p-3"
									isDisabled={urlService.isIppIndustryPortal()}
									onChange={(event: any) => {
										let { checked } = event.currentTarget;
										this.setState(prevState => {
											return {
												...prevState,
												userData: {
													...prevState.userData,
													isSignatory: checked
												},
												isDataChanging: true
											};
										});
									}}
								/>
							</>
						) : (
							<>
								<SingleSelectDropdown
									id="Role"
									name="role"
									label={localizationService.getLocalizedString(
										'ipp.authorityAccount.userListColumns.role'
									)}
									value={this.state.userData.role}
									options={optionValues}
									noEmptyOption={true}
									onChange={(event: any) => {
										let { value } = event.currentTarget;
										this.setState(prevState => {
											return {
												userData: {
													...prevState.userData,
													role: value
												},
												isDataChanging: true
											};
										});
									}}
									isDisabled={!urlService.isAdministrator()}
								/>
								{urlService.isIppIndustryPortal() && (
									<SingleCheckbox
										id="signatory"
										name="isSignatory"
										label={localizationService.getLocalizedString(
											`ipp.pendingRegistrationUser.fields.isSignatory.label`
										)}
										checked={this.state.userData.isSignatory || false}
										className="align-self-center p-3"
										isDisabled={urlService.isIppIndustryPortal()}
										onChange={(event: any) => {
											let { checked } = event.currentTarget;
											this.setState(prevState => {
												return {
													...prevState,
													userData: {
														...prevState.userData,
														isSignatory: checked
													},
													isDataChanging: true
												};
											});
										}}
									/>
								)}
							</>
						)}
					</div>
					<hr />
					{(this.isIndustryUserModule() || urlService.isAdministrator()) && (
						<div className="d-flex">
							<button
								id="save-btn"
								className={`${saveBtnClass}`}
								disabled={shouldDisabledSaveBtn}
								onClick={
									!shouldDisabledSaveBtn
										? () => {
												let dataToBeChanged = this.changedUserDetails(
													this.props.userDetails,
													this.state.userData
												);
												delete dataToBeChanged.isAdministrator;
												this.props.updateUser(dataToBeChanged, this.state.userData.ippUserId);
										  }
										: () => {}
								}>
								{localizationService.getLocalizedString(`ipp.buttons.save`)}
							</button>
						</div>
					)}
				</section>
				<DeleteModal {...lockModalProp} key="lockUserModal" />
				<DeleteModal {...enableModalProp} key="enableUserModal" />
				<DeleteModal {...removeUserModalProp} key="removeUserModal" />
				<CustomModal
					showModal={this.state.resetUserModal}
					okayButtonText={localizationService.getLocalizedString('ipp.buttons.reset')}
					bodyClass="pb-0"
					footerClass="pt-0 ml-auto"
					title={localizationService.getLocalizedString('ipp.authorityAccount.resetUserHeading')}
					onOkayButtonClick={() => {
						this.onResetUser();
					}}
					onCancelButtonClick={() => {
						this.onToggleResetUserModal();
					}}>
					<WithAqiSsoFeatureFlag
						condition={!_.isEmpty(this.props.userDetails.aqiIdpUserId)}
						childrenWhenFeatureFlagIsOffOrConditionIsFalse={
							<p>{localizationService.getLocalizedString('ipp.authorityAccount.resetUserMessage')}</p>
						}>
						<p>{localizationService.getLocalizedString('ipp.authorityAccount.resetAqiSsoUserMessage')}</p>
					</WithAqiSsoFeatureFlag>

					<div className="form-row pl-1 pr-1 pb-0">
						<TextInput
							id="resetEmail"
							className="mx-auto w-100"
							name="newEmailAddress"
							value={this.state.resetUser.newEmailAddress}
							label={localizationService.getLocalizedString('ipp.authorityAccount.userListColumns.email')}
							onChange={(event: any) => {
								this.changeEmailOnReset(event.target.value);
							}}
							error={this.state.resetUser.newEmailAddressError}
						/>
					</div>
				</CustomModal>
				<PopoverModal
					showModal={this.state.resetPasswordModal}
					title={localizationService.getLocalizedString('ipp.users.passwordResetTitle')}
					footer={this.passwordResetModalFooterDiv()}
					cancel={() => this.onToggleResetPasswordModal()}>
					<p>{localizationService.getLocalizedString('ipp.users.passwordResetDescription')}</p>
					<div className="form-row pl-1 pr-1 pb-0">
						<div className="form-group col-md-12">
							<label htmlFor="FacilityFacilityName">
								{localizationService.getLocalizedString('ipp.users.passwordResetLinkLabel')}
							</label>
							<input
								className="form-control"
								id="passwordResetLink"
								name="passwordResetLink"
								type="text"
								value={this.state.passwordResetLink}
								readOnly={true}
							/>
						</div>
					</div>
				</PopoverModal>
				{!urlService.isIppIndustryPortal() && <UserSecurityQuestions userId={this.props.match.params.userId} />}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): UsersState => {
	return { ...state.ippUserDetails, ...state.userProfile.userProfile };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadDetails: (userProfileId: number) => dispatch(loadUserDetails(userProfileId)),
		updateUser: (user: LinkoTypes.IppUser, userId?: number, callbackOnSuccess?: any, userName?: string) =>
			dispatch(updateUser(user, userId as number, callbackOnSuccess, userName))
	};
};

export const IppUserDetails = connect(mapStateToProps, mapDispatchToProps)(IppUserDetailsComp);
