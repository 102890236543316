import React, { useState, useEffect } from 'react';
import { DropDownOption, IppSampleImportTemplate } from '@rcp/types';
import { Utils } from 'src/services/utils';
import { PopoverModal, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components';
import { localizationService } from 'src/services';

interface Props {
	showModal: boolean;
	modalTitle: string;
	templateData: IppSampleImportTemplate;
	editRowId: number | undefined;
	onSave: (e: React.MouseEvent<HTMLElement>) => void;
	onCancel: (e: React.MouseEvent<HTMLElement>) => void;
	onFieldChange: (fieldName: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
}

enum RequiredOptions {
	Optional = 'Optional',
	Required = 'Required',
	Recommended = 'Recommended'
}

enum SystemFieldName {
	MonitoringPoint = 'MonitoringPoint',
	SampleType = 'SampleType',
	CollectionMethod = 'CollectionMethod'
}

const initialRequiredOptions = [
	{
		label: localizationService.getLocalizedString(
			'ipp.sampleImportTemplateList.editModalFields.required.options.recommended'
		),
		value: RequiredOptions.Recommended
	},
	{
		label: localizationService.getLocalizedString(
			'ipp.sampleImportTemplateList.editModalFields.required.options.required'
		),
		value: RequiredOptions.Required
	},
	{
		label: localizationService.getLocalizedString(
			'ipp.sampleImportTemplateList.editModalFields.required.options.optional'
		),
		value: RequiredOptions.Optional
	}
];

function SampleImportTemplateEditModal(props: Props) {
	const { templateData } = props;
	const [requiredOptions, setRequiredOptions] = useState([...initialRequiredOptions]);

	const getRequiredOptions = () => {
		const { isSystemRequired, systemFieldName } = templateData;
		let newRequiredOptions = [...initialRequiredOptions];
		if (isSystemRequired) {
			newRequiredOptions = newRequiredOptions.filter(option => {
				return option.value !== RequiredOptions.Optional;
			});
		}
		if (
			systemFieldName !== SystemFieldName.MonitoringPoint &&
			systemFieldName !== SystemFieldName.SampleType &&
			systemFieldName !== SystemFieldName.CollectionMethod
		) {
			newRequiredOptions = newRequiredOptions.filter(option => {
				return option.value !== RequiredOptions.Recommended;
			});
		}
		return newRequiredOptions;
	};

	useEffect(() => {
		let newRequiredOptions = getRequiredOptions();
		setRequiredOptions([...newRequiredOptions]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateData]);

	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					save={props.onSave}
					cancel={props.onCancel}>
					<div className="form-row">
						<TextInput
							id="column-header"
							name="column-header"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								'ipp.sampleImportTemplateList.editModalFields.columnHeader.label'
							)}
							value={Utils.getValueOrEmptyString(templateData.columnHeader)}
							isDisabled={true}
						/>
						<TextInput
							id="system-field-name"
							name="system-field-name"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								'ipp.sampleImportTemplateList.editModalFields.systemFieldName.label'
							)}
							value={Utils.getValueOrEmptyString(templateData.systemFieldName)}
							isDisabled={true}
							helpText={
								templateData.isSystemRequired
									? localizationService.getLocalizedString(
											'ipp.sampleImportTemplateList.editModalFields.systemFieldName.helpText'
									  )
									: ''
							}
						/>
					</div>
					<div className="form-row">
						<TextAreaInput
							id="column-description"
							name="column-description"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								'ipp.sampleImportTemplateList.editModalFields.columnDescription.label'
							)}
							value={Utils.getValueOrEmptyString(templateData.columnDescription)}
							isDisabled={true}
							rows={3}
						/>
						<SingleSelectDropdown
							id="required"
							name="required"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								'ipp.sampleImportTemplateList.editModalFields.required.label'
							)}
							noEmptyOption={true}
							value={Utils.getValueOrEmptyString(templateData.required)}
							onChange={props.onFieldChange('required')}
							options={requiredOptions as DropDownOption[]}
						/>
					</div>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
}

export default SampleImportTemplateEditModal;
