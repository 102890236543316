import React, { useContext, useEffect } from 'react';
import { AlertMessage, AlertMessageType, alertService } from 'src/redux';
import { authoritySettingService } from 'src/services';
import { translateService } from 'src/services/translate-service';
import authoritySetting from '../authority/ipp/authority-account/settings/authority-setting';
import { urlService } from 'src/services';
import { CloseSvg, AlertErrorSvg, AlertSuccessSvg, AlertInformationSvg, AlertWarningSvg } from '../svg';
import { LanguageContext } from '../widgets/translate/translator-context';
import { Translate } from '../widgets/translate/translator';

interface AlertItemProps {
	message: AlertMessage;
}

export const AlertItem: React.SFC<AlertItemProps> = props => {
	let className = '';
	let iconSvg: any;
	const { language, setLanguage } = useContext(LanguageContext);
	const [message, setMessage] = React.useState('');

	if (String.equalCaseInsensitive(props.message.type, AlertMessageType.Success)) {
		className = 'success';
		iconSvg = AlertErrorSvg;
	} else if (String.equalCaseInsensitive(props.message.type, AlertMessageType.Info)) {
		className = 'information';
	} else if (String.equalCaseInsensitive(props.message.type, AlertMessageType.Warning)) {
		className = 'warning';
	} else {
		className = 'error';
	}

	useEffect(() => {
		translateService.translateHtmlAndSetMessage(props.message.message, setMessage, language);
	}, [language, props.message.message]);

	const closeMessage = (e: any) => {
		alertService.removeMessage(props.message);
	};

	const alertIconSVG = (messageType: AlertMessageType) => {
		if (String.equalCaseInsensitive(messageType, AlertMessageType.Success)) {
			return <AlertSuccessSvg />;
		} else if (String.equalCaseInsensitive(messageType, AlertMessageType.Info)) {
			return <AlertInformationSvg />;
		} else if (String.equalCaseInsensitive(messageType, AlertMessageType.Warning)) {
			return <AlertWarningSvg />;
		} else {
			return <AlertErrorSvg />;
		}
	};

	return (
		!String.isUndefinedOrEmpty(message) && (
			<div className={'ai-alert alert-' + className + ' fade show'} role="alert">
				<div className="ai-alert-icon">{alertIconSVG(props.message.type)}</div>
				<div className="ai-alert-message">
					<span
						dangerouslySetInnerHTML={{
							__html: message
						}}
					/>
					{props.message.count > 1 && (
						<span className="badge" aria-hidden="false">
							{props.message.count}
						</span>
					)}
				</div>
				<div className="close ai-alert-close">
					<button onClick={closeMessage}>
						<CloseSvg size="8" />
					</button>
				</div>
			</div>
		)
	);
};
