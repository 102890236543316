import { PackageActionType, PackageAction } from './packages-actions';
import { PackageState, initialPackageState } from './packages-state';

export const ippPackageReducer = (state = initialPackageState, action: PackageAction): PackageState => {
	switch (action.type) {
		case PackageActionType.LoadAttachmentsRequest:
		case PackageActionType.LoadSamplesRequest:
		case PackageActionType.LoadPackageRequest:
			return { ...state };
		case PackageActionType.LoadPackageSuccess:
		case PackageActionType.CreateReportPackage:
			return {
				...state,
				reportPackage: action.reportPackage
			};
		case PackageActionType.LoadSamplesSuccess:
			return {
				...state,
				ippSamples: action.samples
			};
		case PackageActionType.LoadAttachmentsSuccess:
			return {
				...state,
				attachments: action.attachments
			};
		case PackageActionType.ResetPackage:
			return {
				...initialPackageState
			};
		default:
			return state;
	}
};
