import React from 'react';
import _, { toInteger } from 'lodash';

import './timeline-tab.scss';
import './timeline.scss';
import {
	ApplicationState,
	loadFacilityInspections,
	saveFacilityInspection,
	loadFacilityPumpOutEvents,
	saveFacilityPumpOutEvent,
	deleteFacilityPumpOutEvent,
	loadCurrentFogFacility,
	loadFacilityViolations,
	loadFacilityTimelineEnforcements,
	useReduxSelector,
	alertService,
	loadAuthorityInspections,
	useRootStateSelector,
	RootState,
	loadFacilityCleaningManifest,
	initialApplicationState,
	configureReduxStore,
	loadFacilityIncidentEvents
} from 'src/redux';
import { loadFacilityCallLogs } from 'src/redux/callLog';
import { loadFacilityComments } from 'src/redux/comment';

import * as ApiTypes from '@rcp/types';
import ReactDOMServer from 'react-dom/server';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect, Provider, useSelector } from 'react-redux';
import {
	FaPhone,
	FaUserAlt,
	FaCalendarAlt,
	FaSearch,
	FaTimes,
	FaEnvelope,
	FaExclamationTriangle
} from 'react-icons/fa';

import { TimelineEvent, TimelineType } from './timeline-event';
import { TimelineFilterActionMenu } from './timeline-filter-action-menu';
import { TimelineDateRange } from './timeline-date-range';
import { localizationService, DateUtilService, urlService, Resource } from 'src/services';
import { loadFacilityEmailLogs } from 'src/redux/facility/email';
import { TimelineEventEmail } from './timeline-event-email';
import { TimelineEventComment } from './timeline-event-comment';
import { TimelineEventInspection } from './timeline-event-inspection';
import { TimelineEventEnforcement } from './timeline-event-enforcement';
import {
	loadTimelineSettings,
	saveTimelineSettings,
	getTimelineSettingDateRange,
	reloadTimelineEnforcements
} from './timeline-service';
import { GreaseTrap, MegaPhone } from '../../../../../svg';
import moment from 'moment';
import { SearchInput } from 'src/components/widgets';
import { getTimelineIconColor } from './timeline-helper';
import { CleaningEvent, InspectionEvent, OrganizationTypeName, Violation } from '@rcp/types';
import { GetInitialTimelineSettings, TimelineSettings, TimelineFilterByEventTypeEnum } from './timeline-type';
import { TimelineEventViolation, ViolationTimelineType } from './timeline-event-violation';
import { TimelineEventCallLog } from './timeline-event-call-log';
import { nanoid } from '@reduxjs/toolkit';
import { TimelineEventCleaning } from 'src/features/timeline/timeline-event-cleaning';
import { TimelineEventCleaningManifest } from '../../../../../../features/timeline/timeline-event-cleaning-manifest';
import {
	loadFacilityEvents,
	reloadTimelineEventsFromServer
} from '../../../../../../features/timeline/timeline-service';
import { TimelineEventGeneralSentNotice } from '../../../../../../features/timeline/timeline-event-sentGeneralNotice';
import { TimelineEventCleaningSentNotice } from '../../../../../../features/timeline/timeline-event-sentCleaningNotice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';
import { TimelineGenericEvent } from './timeline-generic-event';
import { TimelineEventIncident } from 'src/components/authority/fog/facilities/facility-details/timeline/timeline-event-incident';
import { IncidentEventSvg } from 'src/components/svg/incident-event.svg';

interface OwnProps {
	userProfile?: any;
}

interface DispatchProps {
	loadFacilityPumpOuts: (startDate?: string, endDate?: string) => void;
	loadFacilityComments: (startDate?: string, endDate?: string) => void;
	loadFacilityCallLogs: (startDate?: string, endDate?: string) => void;
	loadFacilityEmailLogs: (startDate?: string, endDate?: string) => void;
	loadFacilityInspections: (startDate?: string, endDate?: string) => void;
	loadFacilityViolations: (startDate?: string, endDate?: string) => void;
	loadFacilityTimelineEnforcements: (startDate?: string, endDate?: string) => void;
	loadFacilityIncidents: (startDate?: string, endDate?: string) => void;
	saveFacilityInspection: (inspection: ApiTypes.InspectionEvent) => Promise<any>;
	saveFacilityPumpOutEvent: (pumpOutEvent: ApiTypes.CleaningEvent) => Promise<any>;
	deleteFacilityPumpOutEvent: (pumpOutEvent: ApiTypes.CleaningEvent) => Promise<any>;
	loadCurrentFogFacility: () => void;
	loadAuthorityInspections: () => void;
	loadCleaningManifest: (startDate?: string, endDate?: string, showCustomCleaningFormsFeature?: boolean) => void;
	loadFacilityEvents: (startDate?: string, endDate?: string) => void;
}

type Props = OwnProps & DispatchProps;

interface TimeLineEventData {
	sortDateTime: string;
	sortPriority: number;
	showTime: boolean;
	icon: JSX.Element;
	iconBackgroundCss?: string;
	backgroundColor?: string;
	content: JSX.Element;
	eventType: TimelineType;
}

const TimelineComp: React.FC<Props> = (props: Props) => {
	const [timelineEvents, setTimelineEvents] = React.useState([
		<TimelineEvent key="top" isEnding={true} eventType={TimelineType.top} />
	]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [timelineSearchAnchor, setTimelineSearchAnchor] = React.useState();
	const [timelineFilterAnchor, setTimelineFilterAnchor] = React.useState();
	const [showFilterByEventType, setShowFilterByEventType] = React.useState(false);
	const [timelineSettings, setTimelineSettings] = React.useState(GetInitialTimelineSettings());
	const [showDateRangeForm, setShowDateRangeForm] = React.useState(false);

	const fogFacility = useReduxSelector(state => state.fogFacility.facility);
	const facilityViolations = useRootStateSelector(state => state.violation.result);
	const facilityCallLogs = useReduxSelector(state => state.facilityCallLogs.facilityCallLogs);
	const facilityComments = useReduxSelector(state => state.facilityComments.facilityComments);
	const facilityEmailLogs = useReduxSelector(state => state.facilityEmails.facilityEmailLogs);
	const facilityInspections = useReduxSelector(state => state.inspections.facilityInspections);
	const facilityPumpOutEvents = useReduxSelector(state => state.pumpOutEvents.facilityPumpOutEvents);
	const facilityEnforcements = useReduxSelector(state => state.fogFacility.facilityTimelineEnforcements);
	const cleaningManifestListEvents = useReduxSelector(state => state.pumpOutEvents.facilityCleaningManifestEvents);
	const genericEvents = useSelector((state: RootState) => state.facilityEvents.result);
	const timeLine = useSelector((state: RootState) => state.timeline);
	const facilityIncidentEvents = useReduxSelector(state => state.incidentEvents.facilityIncidentEvents);

	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[ApiTypes.FeatureNames.ShowCustomCleaningFormsFeature]
	);

	const history = useHistory();

	const getExcludeList = (timelineSetting: TimelineSettings) => {
		let excludeList = [
			'phoneCalls',
			'violations',
			'comments',
			'pumpOutEvents',
			'enforcements',
			'inspections',
			'cleanings',
			'cleaningsManifest',
			'incident'
		];
		if (!timelineSetting.sentCleaningNotices) {
			excludeList.push(TimelineFilterByEventTypeEnum.sentCleaningNotices);
		} else {
			excludeList = excludeList.filter(value => {
				return value !== TimelineFilterByEventTypeEnum.sentCleaningNotices;
			});
		}
		if (!timelineSetting.sentGeneralNotices) {
			excludeList.push(TimelineFilterByEventTypeEnum.sentGeneralNotices);
		} else {
			excludeList = excludeList.filter(value => {
				return value !== TimelineFilterByEventTypeEnum.sentGeneralNotices;
			});
		}
		return excludeList;
	};

	const reloadEventsFromServer = (timelineFilterSetting: TimelineSettings) => {
		let dateRange = getTimelineSettingDateRange(timelineFilterSetting);
		let startDateStr = dateRange.startDateStr;
		let endDateStr = dateRange.endDateStr;
		const timelineSetting = loadTimelineSettings();

		if (urlService.getCurrentProgramOrDefault() === 'fog') {
			if (timelineFilterSetting.comments) {
				props.loadFacilityComments(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.phoneCalls) {
				props.loadFacilityCallLogs(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.emails) {
				props.loadFacilityEmailLogs(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.inspections) {
				props.loadFacilityInspections(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.pumpOutEvents) {
				props.loadFacilityPumpOuts(startDateStr, endDateStr);
				props.loadCleaningManifest(startDateStr, endDateStr, showCustomCleaningFormsFeature);
			}
			if (timelineFilterSetting.violations) {
				props.loadFacilityViolations(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.enforcements) {
				props.loadFacilityTimelineEnforcements(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.events) {
				props.loadFacilityEvents(startDateStr, endDateStr);
			}
			if (timelineFilterSetting.incidents) {
				props.loadFacilityIncidents(startDateStr, endDateStr);
			}

			reloadTimelineEventsFromServer({
				...timelineSetting,
				excludeList: getExcludeList(timelineSetting)
			});
		}
	};

	const saveFilterSettingToLocalStorage = (timelineFilterSetting: TimelineSettings, loadEvents = false) => {
		setTimelineSettings(timelineFilterSetting);
		saveTimelineSettings(timelineFilterSetting);

		if (loadEvents) {
			reloadEventsFromServer(timelineFilterSetting);
		}
	};

	const loadFilterSettingFromLocalStorage = () => {
		let timelineSetting = loadTimelineSettings();

		reloadEventsFromServer(timelineSetting);
		setTimelineSettings(timelineSetting);
	};

	React.useEffect(() => {
		loadFilterSettingFromLocalStorage();
		const timelineSetting = loadTimelineSettings();
		reloadTimelineEventsFromServer({
			...timelineSetting,
			excludeList: getExcludeList(timelineSetting)
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		let events = composeTimeLineEventsFromRedux();
		setTimelineEvents(events);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		facilityCallLogs,
		facilityComments,
		facilityEmailLogs,
		facilityInspections,
		facilityPumpOutEvents,
		facilityViolations,
		facilityEnforcements,
		timelineSettings,
		genericEvents,
		timeLine,
		cleaningManifestListEvents,
		facilityIncidentEvents
	]);

	const reloadViolations = () => {
		let dateRange = getTimelineSettingDateRange(timelineSettings);
		let startDateStr = dateRange.startDateStr;
		let endDateStr = dateRange.endDateStr;

		props.loadFacilityViolations(startDateStr, endDateStr);
		props.loadCurrentFogFacility();
	};

	const reloadGeneralNotices = () => {
		loadFilterSettingFromLocalStorage();
		const timelineSetting = loadTimelineSettings();
		reloadTimelineEventsFromServer({
			...timelineSetting,
			excludeList: getExcludeList(timelineSetting)
		});
	};

	const mapCommentsTimelineEvent = () => {
		if (_.isEmpty(facilityComments)) {
			return [];
		}
		return facilityComments.map((event: ApiTypes.Comment) => {
			return {
				sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(event.creationDateTimeUtc),
				showTime: true,
				sortPriority: 1,
				icon: <FaUserAlt />,
				iconBackgroundCss: 'timeline-icon-complete-event',
				content: <TimelineEventComment event={event} />,
				eventType: TimelineType.comment
			} as TimeLineEventData;
		});
	};

	const mapCallLogTimelineEvents = () => {
		if (_.isEmpty(facilityCallLogs)) {
			return [];
		}
		return facilityCallLogs.map((event: ApiTypes.CallLog) => {
			return {
				sortDateTime: event.isUserProvidedTime
					? DateUtilService.getDateStringInAuthorityTimeZone(event.callLogDateTime)
					: DateUtilService.combineDateTimeFromTwoDate(event.callLogDateTime, event.creationDateTimeUtc),
				showTime: event.isUserProvidedTime,
				sortPriority: 1,
				iconBackgroundCss: 'timeline-icon-complete-event',
				icon: <FaPhone />,
				content: <TimelineEventCallLog event={event} />,
				eventType: TimelineType.callLog
			} as TimeLineEventData;
		});
	};

	const mapEmailLogTimelineEvents = () => {
		if (_.isEmpty(facilityEmailLogs)) {
			return [];
		}
		return facilityEmailLogs.map((event: ApiTypes.EmailLog, index: number) => {
			return {
				sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(event.sentDateTimeUtc),
				showTime: true,
				sortPriority: 2,
				iconBackgroundCss: 'timeline-icon-complete-event',
				icon: <FaEnvelope />,
				content: <TimelineEventEmail event={event} index={index} />,
				eventType: TimelineType.email
			} as TimeLineEventData;
		});
	};

	const saveFacilityInspection = async (inspectEvent: InspectionEvent) => {
		return props.saveFacilityInspection(inspectEvent).then(props.loadCurrentFogFacility);
	};

	const mapInspectionTimelineEvents = (todayNow: string) => {
		if (_.isEmpty(facilityInspections)) {
			return [];
		}
		return facilityInspections.map((event: ApiTypes.InspectionEvent, index: number) => {
			let dueDate = event.dueDate && DateUtilService.getMomentInAuthorityTimeZone(event.dueDate);
			let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate); //Adding time as today's event will show above the TODAY Line
			let completeDate = event.completeDate;
			//if inspection is complete then show due date otherwise complete date
			let eventTimeStr = completeDate ? completeDate : dueDateEndOfDay;
			let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
			return {
				sortDateTime: eventTime,
				showTime: completeDate ? true : false,
				sortpriority: 'high',
				icon: <FaSearch />,
				iconBackgroundCss: completeDate ? 'timeline-icon-complete-event' : 'timeline-icon-due-event',
				content: (
					<TimelineEventInspection
						key={nanoid()}
						event={event}
						index={index}
						todayNow={todayNow}
						save={props.saveFacilityInspection}
						userProfile={props.userProfile}
						loadFacilityInspections={props.loadFacilityInspections}
						loadCurrentFogFacility={props.loadCurrentFogFacility}
						loadAuthorityInspections={props.loadAuthorityInspections}
					/>
				),
				eventType: TimelineType.inspection
			} as TimeLineEventData;
		});
	};

	const mapViolationTimelineEventData = (
		violation: Violation,
		index: number,
		todayNow: string,
		violationTimelineType: ViolationTimelineType
	): TimeLineEventData => {
		let violationIcon;
		let violationBackgroundCss = '';
		let dateTime;
		let violationSortPriority: number = 3.1;

		if (violationTimelineType === ViolationTimelineType.NonComplianceDate) {
			violationIcon = <FaExclamationTriangle />;
			if (violation.inComplianceDate) {
				violationBackgroundCss = 'timeline-icon-in-compliance';
			} else {
				violationBackgroundCss = 'timeline-icon-non-compliant-violation';
			}
			let nonComplianceDateTime = DateUtilService.combineDateTimeFromTwoDate(
				violation.nonComplianceDate,
				violation.creationDateTimeUtc
			);
			dateTime = DateUtilService.getDateStringInAuthorityTimeZone(nonComplianceDateTime);
			violationSortPriority = 3.1;
		} else if (violationTimelineType === ViolationTimelineType.ComplianceDueDate) {
			violationIcon = <FaExclamationTriangle />;
			violationBackgroundCss = 'timeline-icon-non-compliant-violation';
			dateTime = DateUtilService.ceilingDate(
				DateUtilService.getMomentInAuthorityTimeZone(violation.complianceDueDate)
			);
			violationSortPriority = 3.2;
		} else if (violationTimelineType === ViolationTimelineType.InComplianceDate) {
			violationIcon = <FaExclamationTriangle />;
			violationBackgroundCss = 'timeline-icon-in-compliance';
			let inComplianceDate = DateUtilService.combineDateTimeFromTwoDate(
				violation.inComplianceDate,
				violation.lastModificationDateTimeUtc
			);
			dateTime = DateUtilService.getDateStringInAuthorityTimeZone(inComplianceDate);
			violationSortPriority = 3.4;
		}

		return {
			sortDateTime: dateTime,
			showTime: false,
			sortPriority: violationSortPriority,
			icon: violationIcon,
			iconBackgroundCss: violationBackgroundCss,
			content: (
				<TimelineEventViolation
					violation={violation}
					index={index}
					todayNow={todayNow}
					facilityId={fogFacility.facilityId}
					violationTimelineType={violationTimelineType}
					saveCallBack={reloadViolations}
					deleteCallBack={reloadViolations}
				/>
			),
			eventType: TimelineType.violation
		} as TimeLineEventData;
	};

	const mapViolationTimelineEvents = (todayNow: string) => {
		if (_.isEmpty(facilityViolations)) {
			return [];
		}
		let timelineEventData = [] as TimeLineEventData[];
		for (let index = 0; index < facilityViolations.length; index++) {
			let violation = facilityViolations[index];

			//always show non compliance date
			timelineEventData.push(
				mapViolationTimelineEventData(violation, index, todayNow, ViolationTimelineType.NonComplianceDate)
			);

			if (!violation.inComplianceDate && violation.complianceDueDate) {
				timelineEventData.push(
					mapViolationTimelineEventData(violation, index, todayNow, ViolationTimelineType.ComplianceDueDate)
				);
			}

			if (violation.inComplianceDate) {
				timelineEventData.push(
					mapViolationTimelineEventData(violation, index, todayNow, ViolationTimelineType.InComplianceDate)
				);
			}
		}

		return timelineEventData;
	};

	const mapEnforcementTimelineEvents = (todayNow: string) => {
		if (_.isEmpty(facilityEnforcements)) {
			return [];
		}
		let timelineEventData = [] as TimeLineEventData[];
		for (let index = 0; index < facilityEnforcements.length; index++) {
			let enforcement = facilityEnforcements[index];
			let enforcementIssueDate =
				enforcement.enforcementIssueDate &&
				DateUtilService.combineDateTimeFromTwoDate(
					enforcement.enforcementIssueDate,
					enforcement.lastModificationDateTimeUtc
				);

			let enforcementEventData = {
				sortDateTime:
					enforcementIssueDate && DateUtilService.getDateStringInAuthorityTimeZone(enforcementIssueDate),
				showTime: false,
				sortPriority: 3.3,
				icon: <MegaPhone />,
				iconBackgroundCss: 'timeline-icon-complete-event',
				content: (
					<TimelineEventEnforcement
						enforcement={enforcement}
						index={index}
						todayNow={todayNow}
						saveCallBack={reloadTimelineEnforcements}
						deleteCallBack={reloadTimelineEnforcements}
					/>
				),
				eventType: TimelineType.enforcement
			} as TimeLineEventData;
			timelineEventData.push(enforcementEventData);
		}
		return timelineEventData;
	};

	const mapSentGeneralNotice = (todayNow: string) => {
		if (_.isEmpty(timeLine.result)) {
			return [];
		}
		let timelineEventData = [] as TimeLineEventData[];
		let sentGeneralNotice = [];
		sentGeneralNotice = timeLine.result.filter(timelineObject => {
			if (timelineObject.sentGeneralNotice) return timelineObject.sentGeneralNotice;
		});
		for (let i = 0; i < sentGeneralNotice.length; i++) {
			timelineEventData.push({
				sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(
					sentGeneralNotice[i].sentGeneralNotice.sentDateTime
				),
				iconBackgroundCss: 'timeline-icon-complete-event',
				showTime: false,
				sortPriority: 1,
				icon: (
					<FontAwesomeIcon
						style={{
							color: '#4b6a88'
						}}
						icon={faPaperPlane}
						className="font-awesome-icon"
					/>
				),
				content: (
					<TimelineEventGeneralSentNotice
						event={sentGeneralNotice[i].sentGeneralNotice}
						reloadGeneralNotices={reloadGeneralNotices}
					/>
				),
				eventType: TimelineType.sentGeneralNotice
			} as TimeLineEventData);
		}
		return timelineEventData;
	};

	const mapSentCleaningManifest = (todayNow: string) => {
		return cleaningManifestListEvents.map((event: any, index: number) => {
			let eventTimeStr = '';
			let completeDate = event.completeDate;
			let eventTime = completeDate && DateUtilService.getDateStringInAuthorityTimeZone(completeDate);
			let domainObjectName = urlService.getDomainObjectName();
			let dueDate = event.dueDate && DateUtilService.getMomentInAuthorityTimeZone(event.dueDate);
			let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate);
			if (showCustomCleaningFormsFeature) {
				eventTimeStr = completeDate
					? DateUtilService.combineDateTimeFromTwoDate(completeDate, event.lastModificationDateTimeUtc)
					: dueDateEndOfDay;
			} else {
				eventTimeStr = DateUtilService.combineDateTimeFromTwoDate(completeDate, event.submittedDateTimeLocal);
			}
			return {
				sortDateTime: eventTime,
				showTime: false,
				sortPriority: 2,
				icon: <GreaseTrap hasSlateFill={event.completeDate != undefined} title="Cleaning" />,
				iconBackgroundCss: getTimelineIconColor(todayNow, event.completeDate),
				content:
					event.creatorOrganizationType === OrganizationTypeName.Authority &&
					showCustomCleaningFormsFeature ? (
						<TimelineEventCleaning
							cleaning={{ ...event, pumpOutEventId: event.cleaningEventId }}
							isFacilityDetailsPage={String.equalCaseInsensitive(
								domainObjectName,
								Resource.FogFacilities
							)}
							isDeviceDetailsPage={String.equalCaseInsensitive(domainObjectName, Resource.Devices)}
						/>
					) : (
						<TimelineEventCleaningManifest
							cleaning={event}
							isFacilityDetailsPage={String.equalCaseInsensitive(
								domainObjectName,
								Resource.FogFacilities
							)}
							isDeviceDetailsPage={String.equalCaseInsensitive(domainObjectName, Resource.Devices)}
						/>
					),
				eventType: TimelineType.cleaning
			} as TimeLineEventData;
		});
	};

	const deletePumpOutEvent = async (pumpOutEvent: CleaningEvent) => {
		await props.deleteFacilityPumpOutEvent(pumpOutEvent).then(props.loadCurrentFogFacility);
		if (timelineSettings.pumpOutEvents) {
			let dateRange = getTimelineSettingDateRange();
			let startDateStr = dateRange.startDateStr;
			let endDateStr = dateRange.endDateStr;
			props.loadFacilityPumpOuts(startDateStr, endDateStr);
		}
	};

	const loadIncidentTimelineEvents = () => {
		if (timelineSettings.incidents) {
			let dateRange = getTimelineSettingDateRange();
			let startDateStr = dateRange.startDateStr;
			let endDateStr = dateRange.endDateStr;
			props.loadFacilityIncidents(startDateStr, endDateStr);
		}
	};

	const mapIncidentTimelineEvents = (todayNow: string) => {
		if (_.isEmpty(facilityIncidentEvents)) {
			return [];
		}

		let timelineEventData = [] as TimeLineEventData[];

		for (let i = 0; i < facilityIncidentEvents.length; i++) {
			timelineEventData.push({
				sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(facilityIncidentEvents[i].reportedDate),
				iconBackgroundCss: 'timeline-icon-complete-event',
				showTime: false,
				sortPriority: 1,
				icon: <IncidentEventSvg></IncidentEventSvg>,
				content: (
					<TimelineEventIncident
						incidentContributor={facilityIncidentEvents[i]}
						callBackAfterDelete={loadIncidentTimelineEvents}
						callBackAfterSave={loadIncidentTimelineEvents}></TimelineEventIncident>
				),
				eventType: TimelineType.incident
			} as TimeLineEventData);
		}

		return timelineEventData;
	};

	const mapPumpOutTimelineEvents = (todayNow: string) => {
		if (_.isEmpty(facilityPumpOutEvents)) {
			return [];
		}
		let authorityFacilityPumpOutEvents = _.cloneDeep(facilityPumpOutEvents).filter(
			(cleaning: any) => cleaning.creatorOrganizationType === OrganizationTypeName.Authority
		);
		return authorityFacilityPumpOutEvents.map((event: any, index: number) => {
			let dueDate = event.dueDate && DateUtilService.getMomentInAuthorityTimeZone(event.dueDate);
			let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate);
			let completeDate = event.completeDate;
			let eventTimeStr = completeDate
				? DateUtilService.combineDateTimeFromTwoDate(completeDate, event.lastModificationDateTimeUtc)
				: dueDateEndOfDay;
			let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
			let domainObjectName = urlService.getDomainObjectName();
			let content = (
				<TimelineEventCleaning
					cleaning={event}
					isFacilityDetailsPage={String.equalCaseInsensitive(domainObjectName, Resource.FogFacilities)}
					isDeviceDetailsPage={String.equalCaseInsensitive(domainObjectName, Resource.Devices)}
				/>
			);
			if (showCustomCleaningFormsFeature && completeDate) {
				return {};
			}
			return {
				sortDateTime: eventTime,
				showTime: false,
				sortPriority: 2,
				icon: <GreaseTrap title="Cleaning" hasSlateFill={event.completeDate != undefined} />,
				iconBackgroundCss: getTimelineIconColor(todayNow, event.completeDate, event.dueDate),
				content,
				eventType: TimelineType.cleaning
			} as TimeLineEventData;
		});
	};

	const mapSentCleaningNotice = (todayNow: string) => {
		if (_.isEmpty(timeLine.result)) {
			return [];
		}
		let timelineEventData = [] as TimeLineEventData[];
		let sentCleaningNotice = [];
		let sendingCleaningNotice = [];
		let failedCleaningNotice = [];
		sentCleaningNotice = timeLine.result.filter(timelineObject => {
			if (timelineObject.sentCleaningNotice) return timelineObject.sentCleaningNotice;
		});
		sendingCleaningNotice = timeLine.result.filter(timelineObject => {
			if (timelineObject.sendingCleaningNotice) return timelineObject.sendingCleaningNotice;
		});
		failedCleaningNotice = timeLine.result.filter(timelineObject => {
			if (timelineObject.failedCleaningNotice) return timelineObject.failedCleaningNotice;
		});
		const defaultTimelineDataForNotice = {
			showTime: false,
			sortpriority: 1,
			icon: <FontAwesomeIcon icon={faPaperPlane} className="" />
		};
		if (sentCleaningNotice) {
			for (let i = 0; i < sentCleaningNotice.length; i++) {
				timelineEventData.push({
					...defaultTimelineDataForNotice,
					sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(
						sentCleaningNotice[i].sentCleaningNotice.sentDateTime || ''
					),
					content: <TimelineEventCleaningSentNotice event={sentCleaningNotice[i].sentCleaningNotice} />,
					eventType: TimelineType.sentCleaningNotice,
					iconBackgroundCss: 'timeline-icon-complete-event font-awesome-icon sentNoticeIcon'
				} as TimeLineEventData);
			}
		}
		if (sendingCleaningNotice) {
			for (let i = 0; i < sendingCleaningNotice.length; i++) {
				timelineEventData.push({
					...defaultTimelineDataForNotice,
					sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(
						sendingCleaningNotice[i].sendingCleaningNotice.sentDateTime || ''
					),
					content: <TimelineEventCleaningSentNotice event={sendingCleaningNotice[i].sendingCleaningNotice} />,
					eventType: TimelineType.sendingCleaningNotice,
					iconBackgroundCss: 'timeline-icon-complete-event font-awesome-icon sentNoticeIcon'
				} as TimeLineEventData);
			}
		}
		if (failedCleaningNotice) {
			for (let i = 0; i < failedCleaningNotice.length; i++) {
				timelineEventData.push({
					...defaultTimelineDataForNotice,
					sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(
						failedCleaningNotice[i].failedCleaningNotice.sentDateTime || ''
					),
					content: <TimelineEventCleaningSentNotice event={failedCleaningNotice[i].failedCleaningNotice} />,
					eventType: TimelineType.failedCleaningNotice,
					iconBackgroundCss: 'timeline-icon-failed-email'
				} as TimeLineEventData);
			}
		}
		return timelineEventData;
	};

	const mapGenericEvents = (todayNow: string) => {
		if (_.isEmpty(genericEvents)) {
			return [];
		}
		let timelineEventData = [] as TimeLineEventData[];
		for (let index = 0; index < genericEvents.length; index++) {
			let event = genericEvents[index];
			let dueDate = event.dueDate && DateUtilService.getMomentInAuthorityTimeZone(event.dueDate);
			let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate); //Adding time as today's event will show above the TODAY Line
			let completeDate = event.completeDate;
			let eventTimeStr = completeDate ? completeDate : dueDateEndOfDay;
			let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
			let eventData = {
				sortDateTime: eventTime,
				showTime: false,
				sortpriority: 3.3,
				icon: (
					<FontAwesomeIcon
						icon={faCalendarDay}
						className={`${completeDate ? 'font-awesome-icon sentNoticeIcon' : ''}`}
					/>
				),
				iconBackgroundCss: completeDate ? 'timeline-icon-complete-event' : 'timeline-icon-due-event',
				content: (
					<TimelineGenericEvent
						key={nanoid()}
						event={event}
						index={index}
						todayNow={todayNow}
						saveCallBack={reloadTimelineEnforcements}
						deleteCallBack={reloadTimelineEnforcements}
					/>
				),
				eventType: TimelineType.event
			} as TimeLineEventData;
			timelineEventData.push(eventData);
		}
		return timelineEventData;
	};

	const composeTimeLineEventsFromRedux = () => {
		let todayNow = DateUtilService.getAuthorityTimezoneNow();
		let timeEvents = [] as TimeLineEventData[];
		if (timelineSettings.phoneCalls) {
			timeEvents = timeEvents.concat(mapCallLogTimelineEvents());
		}

		if (timelineSettings.comments) {
			timeEvents = timeEvents.concat(mapCommentsTimelineEvent());
		}

		if (timelineSettings.emails) {
			timeEvents = timeEvents.concat(mapEmailLogTimelineEvents());
		}

		if (timelineSettings.inspections) {
			timeEvents = timeEvents.concat(mapInspectionTimelineEvents(todayNow));
		}

		if (timelineSettings.pumpOutEvents) {
			timeEvents = timeEvents.concat(mapPumpOutTimelineEvents(todayNow));
			timeEvents = timeEvents.concat(mapSentCleaningManifest(todayNow));
		}

		if (timelineSettings.violations) {
			timeEvents = timeEvents.concat(mapViolationTimelineEvents(todayNow));
		}

		if (timelineSettings.enforcements) {
			timeEvents = timeEvents.concat(mapEnforcementTimelineEvents(todayNow));
		}
		if (timelineSettings.sentGeneralNotices) {
			timeEvents = timeEvents.concat(mapSentGeneralNotice(todayNow));
		}
		if (timelineSettings.sentCleaningNotices) {
			timeEvents = timeEvents.concat(mapSentCleaningNotice(todayNow));
		}
		if (timelineSettings.events) {
			timeEvents = timeEvents.concat(mapGenericEvents(todayNow));
		}
		if (timelineSettings.incidents) {
			timeEvents = timeEvents.concat(mapIncidentTimelineEvents(todayNow));
		}

		if (!_.isEmpty(timelineSettings.searchText)) {
			let searchText = _.toLower(timelineSettings.searchText);
			const initialState = window.initialReduxState || initialApplicationState;
			const store = configureReduxStore(history, initialState);
			timeEvents = _.filter(timeEvents, (event: TimeLineEventData) => {
				let content = ReactDOMServer.renderToString(<Provider store={store}>{event.content}</Provider>);
				return _.toLower(content).indexOf(searchText) !== -1;
			}) as TimeLineEventData[];
		}

		timeEvents.sort((i, j) => {
			if (i.sortDateTime === j.sortDateTime) {
				if (i.sortPriority === j.sortPriority) {
					return 0;
				}
				return i.sortPriority < j.sortPriority ? 1 : -1;
			}
			return DateUtilService.isBefore(i.sortDateTime, j.sortDateTime) ? 1 : -1;
		});

		let dateRange = getTimelineSettingDateRange();
		timeEvents = timeEvents.filter((event: TimeLineEventData) => {
			return (
				event.sortDateTime &&
				DateUtilService.isDateInRage(event.sortDateTime, dateRange.startDateStr, dateRange.endDateStr)
			);
		});

		let afterNowTimelineEvents = timeEvents.filter(i => DateUtilService.isAfter(i.sortDateTime, todayNow));
		let beforeNowTimelineEvents = timeEvents.filter(i => DateUtilService.isSameOrBefore(i.sortDateTime, todayNow));

		let combineTimeLineEvent = afterNowTimelineEvents.map((i, j) => {
			return (
				<TimelineEvent
					eventDateTime={i.sortDateTime}
					showTime={i.showTime}
					eventType={i.eventType}
					icon={i.icon}
					iconBackgroundCss={i.iconBackgroundCss}
					key={`timeline-event-today-${j}`}>
					{i.content}
				</TimelineEvent>
			);
		});

		combineTimeLineEvent.push(
			<TimelineEvent key="today" eventType={TimelineType.today} isTodayTimeLineDivider={true} showTime={false} />
		);

		combineTimeLineEvent = combineTimeLineEvent.concat(
			beforeNowTimelineEvents.map((i, j) => {
				return (
					<TimelineEvent
						eventDateTime={i.sortDateTime}
						showTime={i.showTime}
						eventType={i.eventType}
						icon={i.icon}
						iconBackgroundCss={i.iconBackgroundCss}
						key={`timeline-event-before-${j}`}>
						{i.content}
					</TimelineEvent>
				);
			})
		);

		combineTimeLineEvent.unshift(
			<TimelineEvent key="timeline-event-top" eventType={TimelineType.top} isEnding={true} />
		);

		combineTimeLineEvent.push(
			<TimelineEvent key="timeline-event-bottom" eventType={TimelineType.ending} isEnding={true} />
		);

		return combineTimeLineEvent;
	};

	const onSearchTextChange = (e: any) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		newFacilityTimelineSettings.searchText = e.target.value;
		saveFilterSettingToLocalStorage(newFacilityTimelineSettings, false);
	};

	const filterRuleChange = (filterBy: TimelineFilterByEventTypeEnum) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		let exitingValue = _.get(newFacilityTimelineSettings, filterBy) as boolean;
		_.set(newFacilityTimelineSettings, filterBy, !exitingValue);

		saveFilterSettingToLocalStorage(newFacilityTimelineSettings, true);
		filterRuleCount(newFacilityTimelineSettings);
	};

	const filterRuleCount = (filterCount: TimelineSettings) => {
		let allFilters = [
			'comments',
			'phoneCalls',
			'emails',
			'inspections',
			'violations',
			'enforcements',
			'pumpOutEvents',
			'sentCleaningNotices',
			'sentGeneralNotices',
			'events',
			'incidents'
		];

		let count = _.reduce(
			_.pick(filterCount, allFilters),
			(res, val, key) => {
				return res + toInteger(val);
			},
			0
		);

		if (count !== allFilters.length) {
			return (
				<span className="timeline-filter-count ml-2">
					{localizationService.getLocalizedString(
						'screen.timeline.filterCount',
						count.toString(),
						allFilters.length.toString()
					)}
				</span>
			);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const toggleFilterByForm = (e: any) => {
		setShowFilterByEventType(!showFilterByEventType);
		setTimelineSearchAnchor(e && e.target);
	};

	const editDateRangeBtnClicked = (e: any) => {
		setShowDateRangeForm(!showDateRangeForm);
		setTimelineFilterAnchor(e && e.target);
	};

	const dateRangeApplyBtnClicked = (timelineFilterSettings: TimelineSettings) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		_.assign(newFacilityTimelineSettings, timelineFilterSettings);

		if (!DateUtilService.isValidDateTime(newFacilityTimelineSettings.startDate)) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.dateIsNotValid',
					'screen.labels.startDate'
				)
			);
		} else if (!DateUtilService.isValidDateTime(newFacilityTimelineSettings.endDate)) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.dateIsNotValid',
					'screen.labels.endDate'
				)
			);
		} else {
			setShowDateRangeForm(false);
			saveFilterSettingToLocalStorage(newFacilityTimelineSettings, true);
		}
	};

	const closeDateRangeFilterForm = () => {
		setShowDateRangeForm(false);
	};

	const closeFilterByForm = () => {
		setShowFilterByEventType(false);
	};

	const applySearch = (searchTerm: string) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		newFacilityTimelineSettings.searchText = searchTerm;
		setTimelineSettings(newFacilityTimelineSettings);
		saveFilterSettingToLocalStorage(newFacilityTimelineSettings, true);
	};

	let startDateLabel = '';
	if (timelineSettings.startOfTime) {
		startDateLabel = localizationService.getLocalizedString('screen.timeline.startOfTime');
	} else {
		startDateLabel = DateUtilService.toDisplayDate(timelineSettings.startDate);
	}

	let endDateLabel = '';
	if (timelineSettings.endOfTime) {
		endDateLabel = localizationService.getLocalizedString('screen.timeline.endOfTime');
	} else {
		endDateLabel = DateUtilService.toDisplayDate(timelineSettings.endDate);
	}

	return (
		<section className="timeline">
			<div className="d-md-flex">
				<div className="mr-auto">
					<h2>{localizationService.getLocalizedString('screen.timeline.timeline')}</h2>
				</div>
				<div className="form-row">
					<div className="col-auto mb-2">
						<div className="dropdown">
							<button
								className="btn ai-white"
								id="filterBtn"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{localizationService.getLocalizedString('screen.timeline.filter')}
								{filterRuleCount(timelineSettings)}
							</button>
							<TimelineFilterActionMenu
								timelineFilterByForm={timelineSettings}
								filterRuleChanged={filterRuleChange}
								closeModal={closeFilterByForm}
							/>
						</div>
					</div>
					<div className="col-auto mb-2">
						<SearchInput
							inputId="timelineSearch"
							placeholder={localizationService.getLocalizedString('screen.timeline.searchTimeline')}
							ariaLabel="Search"
							onChange={onSearchTextChange}
							search={applySearch}
							searchTerm={timelineSettings.searchText}
						/>
					</div>
					<div className="col-auto mb-2">
						<button className="btn ai-white" id="editDateRangeBtn" onClick={editDateRangeBtnClicked}>
							{startDateLabel} - {endDateLabel}
							<FaCalendarAlt className="ml-2 ai-media-gray" />
						</button>
					</div>
				</div>
			</div>
			<hr />
			<div className="linko-timeline m-md-3">{timelineEvents}</div>
			{showDateRangeForm && (
				<TimelineDateRange
					anchor={(timelineFilterAnchor as unknown) as HTMLElement}
					show={showDateRangeForm}
					timelineFilterForm={timelineSettings}
					applyBtnClicked={dateRangeApplyBtnClicked}
					closeModal={closeDateRangeFilterForm}
				/>
			)}
		</section>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadFacilityPumpOuts: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityPumpOutEvents(startDate, endDate)),
		loadFacilityCallLogs: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityCallLogs(startDate, endDate)),
		loadFacilityComments: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityComments(startDate, endDate)),
		loadFacilityEmailLogs: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityEmailLogs(startDate, endDate)),
		loadFacilityInspections: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityInspections(startDate, endDate)),
		loadFacilityTimelineEnforcements: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityTimelineEnforcements(startDate, endDate)),
		saveFacilityInspection: (inspection: ApiTypes.InspectionEvent) => dispatch(saveFacilityInspection(inspection)),
		saveFacilityPumpOutEvent: (pumpOutEvent: ApiTypes.CleaningEvent) =>
			dispatch(saveFacilityPumpOutEvent(pumpOutEvent)),
		deleteFacilityPumpOutEvent: (pumpOutEvent: ApiTypes.CleaningEvent) =>
			dispatch(deleteFacilityPumpOutEvent(pumpOutEvent)),
		loadCurrentFogFacility: () => dispatch(loadCurrentFogFacility()),
		loadFacilityViolations: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityViolations(startDate, endDate)),
		loadAuthorityInspections: () => dispatch(loadAuthorityInspections()),
		loadCleaningManifest: (startDate?: string, endDate?: string, showCustomCleaningFormsFeature?: boolean) =>
			dispatch(loadFacilityCleaningManifest(startDate, endDate, showCustomCleaningFormsFeature)),
		loadFacilityEvents: (startDate?: string, endDate?: string) => dispatch(loadFacilityEvents(startDate, endDate)),
		loadFacilityIncidents: (startDate?: string, endDate?: string) => {
			dispatch(loadFacilityIncidentEvents(startDate, endDate));
		}
	};
};

export const Timeline = connect<{}, DispatchProps, {}, ApplicationState>(null, mapDispatchToProps)(TimelineComp);
