import { IppReportPackage, IppReportPackageTemplate } from '@rcp/types';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ippTemplateSlice } from 'src/components/authority/ipp/report-packages/templates/templates.slice';
import { DateInput } from 'src/components/widgets';
import { alertService, RootState } from 'src/redux';
import { addReportPackage } from 'src/redux/ipp/authority/report-packages/packages/packages-action-creator';
import { navigateTo, urlService, validationService } from 'src/services';
import { localizationService } from 'src/services/localizationService';
import './report-package.scss';

interface DispatchProps {
	addReportPackage: (
		payload: IppReportPackage,
		callbackOnSuccess: (reportPackage?: IppReportPackage) => void
	) => Promise<boolean | undefined>;
}

interface Props extends DispatchProps {
	history: History;
}

const IppReportPackageStep1: FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const [templates, setTemplates] = useState<IppReportPackageTemplate[]>([]);
	const [selectedTemplateId, setSelectedTemplateId] = useState();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [showDateSection, setShowDateSection] = useState<boolean>(false);
	const templateState = (state: RootState) => state.ippReportPackageTemplates;
	const { result } = useSelector(templateState);

	useEffect(() => {
		dispatch(ippTemplateSlice.fetchAll('isForCreatingDraft=true&sort=name asc'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (result.length) {
			let templates: IppReportPackageTemplate[] = result.filter((template: IppReportPackageTemplate) => {
				return template.isAdhocReportAllowed;
			});
			setTemplates(templates);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result]);

	const onClickTemplate = (event: any) => {
		const { value } = event.target;
		let selectedTemplate: IppReportPackageTemplate = {};
		selectedTemplate = (templates || []).filter((template: IppReportPackageTemplate) => {
			return Number(template.reportPackageTemplateId) === Number(value);
		})[0];
		setShowDateSection(Boolean(selectedTemplate.isPeriodRequired));
		setSelectedTemplateId(value);
	};

	const onChangeDate = (event: any) => {
		const { name, value } = event.target;

		if (name === 'startDate') {
			setStartDate(value);
		} else {
			setEndDate(value);
		}
	};

	const onNext = () => {
		alertService.clearAllMessages();
		if (validateForm()) {
			let payload: IppReportPackage = {
				reportPackageTemplateId: Number(selectedTemplateId),
				periodStartDateTimeLocal: startDate,
				periodEndDateTimeLocal: endDate
			};
			props.addReportPackage(payload, reportPackage =>
				navigateTo(
					props.history,
					urlService.getIppIndustryUrl('reportPackage', (reportPackage && reportPackage.reportPackageId) || 0)
				)
			);
		}
	};

	const validateForm = () => {
		let newState = { endDate, startDate, endDateError: '', startDateError: '' };
		validationService.validateMinimumDate(newState, 'endDate', 'endDateError');
		validationService.validateMinimumDate(newState, 'startDate', 'startDateError');

		if (validationService.hasError(newState, 'endDateError', 'startDateError')) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.minimumDateCheckMessage',
					'screen.labels.date'
				)
			);
			return false;
		}
		if (!selectedTemplateId) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.reportPackage.package.step1.errorTemplateRequired')
			);
			return false;
		}
		if (showDateSection && !startDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.reportPackage.package.step1.errorStartDateRequired')
			);
			return false;
		}
		if (showDateSection && !endDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.reportPackage.package.step1.errorEndDateRequired')
			);
			return false;
		}
		if (showDateSection && startDate > endDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.reportPackage.package.step1.errorEndDateBeforeStartDate')
			);
			return false;
		}

		return true;
	};

	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('ipp.reportPackage.package.step1.title')}</h1>
					</div>
					<section>
						<span className="font-size-20px-regular">
							<label>
								<span className="text-danger mr-1">*</span>
								{localizationService.getLocalizedString(
									'ipp.reportPackage.package.step1.selectTemplates'
								)}
							</label>
						</span>
						<hr />
						<div className="w-75 mx-auto">
							<div className="d-flex justify-content-between flex-wrap">
								{templates && templates.length ? (
									templates.map(({ reportPackageTemplateId, name, description }) => (
										<div key={reportPackageTemplateId} className="radio-input m-2">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													className="custom-control-input z-1"
													name="templates"
													value={reportPackageTemplateId}
													checked={
														Number(selectedTemplateId) === Number(reportPackageTemplateId)
													}
													id={`template-${reportPackageTemplateId}`}
													onChange={e => onClickTemplate(e)}
												/>
												<label
													className="custom-control-label"
													htmlFor={`template-${reportPackageTemplateId}`}>
													<strong>{name}</strong> {description ? ' - ' : ' '}
													<span>{description}</span>
												</label>
											</div>
										</div>
									))
								) : (
									<p id="no-templates-message">
										{localizationService.getLocalizedString(
											'ipp.reportPackage.package.step1.noTemplateMessage'
										)}
									</p>
								)}
							</div>
						</div>
						{showDateSection ? (
							<>
								<hr />
								<div className="my-3">
									<span className="font-size-20px-regular">
										<label className="mb-1">
											{localizationService.getLocalizedString(
												'ipp.reportPackage.package.step1.reportPeriodLabel'
											)}
										</label>
									</span>
									<div className="w-75 mx-auto">
										<div className="d-flex justify-content-between my-2">
											<DateInput
												id="start-date"
												name="startDate"
												label={localizationService.getLocalizedString(
													'ipp.samples.step1.startDate'
												)}
												className="pl-1 date-width"
												onChange={onChangeDate}
												isRequired={true}
												value={startDate}
											/>
											<DateInput
												id="end-date"
												name="endDate"
												label={localizationService.getLocalizedString(
													'ipp.samples.step1.endDate'
												)}
												className="pl-1 ml-auto date-width"
												isRequired={true}
												onChange={onChangeDate}
												value={endDate}
											/>
										</div>
									</div>
								</div>
							</>
						) : null}
						<hr />
						<div className="mt-3 d-flex">
							{templates && templates.length ? (
								<button
									className="btn ai-action ml-auto mr-2"
									id="sample-next"
									onClick={() => onNext()}>
									{localizationService.getLocalizedString('ipp.buttons.createDraft')}
								</button>
							) : null}
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): any => {
	return {
		addReportPackage: (payload: IppReportPackage, callbackOnSuccess: (reportPackage?: IppReportPackage) => void) =>
			dispatch(addReportPackage(payload, callbackOnSuccess))
	};
};

export default connect(null, mapDispatchToProps)(IppReportPackageStep1);
