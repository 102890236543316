import { AttachmentOwnership, InspectionEvent, RouteProps } from '@rcp/types/src';
import React from 'react';
import { TakeOverModal } from 'src/components/widgets/takeover-modal';
import { FacilityAttachmentDetails } from '../..';

interface CompProps {
	cancel: () => void;
	currentInspection: InspectionEvent;
	onAttachmentDelete: () => void;
	onAttachmentChange: (attachment: AttachmentOwnership) => void;
	toggleAttachmentModal: () => void;
	currentAttachment: AttachmentOwnership | null;
	editMode: boolean;
}

type Props = CompProps & RouteProps;

export const FacilityAttachmentModal: React.FC<Props> = props => {
	return (
		<TakeOverModal noForm={true} cancel={props.cancel} className="attachment-modal">
			<FacilityAttachmentDetails
				match={props.match}
				currentInspection={props.currentInspection}
				onDelete={props.onAttachmentDelete}
				onAttachmentChange={props.onAttachmentChange}
				toggleAttachmentModal={props.toggleAttachmentModal}
				currentAttachment={props.currentAttachment ?? {}}
				editMode={props.editMode}
				noNavigation={true}></FacilityAttachmentDetails>
		</TakeOverModal>
	);
};
