import React from 'react';
import {
	loadFogFacility,
	loadInspectionFormTemplates,
	loadCurrentInspection,
	loadCurrentInspectionForms,
	deleteInspectionFormAction,
	alertService,
	completeFacilityInspection,
	saveDraftFacilityInspectionWithForms,
	loadFacilityContact,
	loadCompleteRecentInspections,
	loadUpComingFacilityEvents,
	loadRecentFacilityEvents,
	upComingInspections,
	useReduxDispatch,
	ReduxDispatch,
	useReduxSelector,
	resetInspectionData,
	resetFacilityData,
	resetFacilityAttachment,
	RootState,
	loadInspectionView,
	loadFacilityAttachments
} from 'src/redux';
import * as ApiTypes from '@rcp/types';

import { FacilityAddress } from '../../facilities/facility-details/facility-details-address/facility-details-address';
import { InspectionSummary } from './inspection-summary';
import {
	InspectionQuickView,
	InspectionQuickViewProps,
	InspectionQuickViewRow,
	InspectionQuickViewValue
} from './inspection-quickview';
import _ from 'lodash';
import $ from 'jquery';
import {
	InspectionFormTemplate,
	TemplateCheckboxList,
	InspectionForm,
	TemplateResponse,
	InspectionEvent,
	CompleteInspectionEvent,
	LetterTemplateCategory,
	AttachmentType,
	LoadStatus
} from '@rcp/types';
import { SingleCheckbox, DeleteModal, DeleteModalProp } from 'src/components/widgets';
import {
	urlService,
	apiService,
	localizationService,
	DateUtilService,
	complianceStatusService,
	navigateTo,
	localStorageService,
	Resource,
	documentService
} from 'src/services';
import { FaEnvelope, FaPrint, FaTrashAlt } from 'react-icons/fa';
import { FacilitySendEmail } from '../../facilities/facility-details/send-email';
import { InspectionActionDropdown } from '../inspection-action-dropdown';
import './inspection-details.scss';
import { FacilityAttachments } from '../../facilities';
import { UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { RecordViolationForm } from '../../violation/violation-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FacilityLinks } from '../../facilities/facility-details/links/facility-links';
import { SelectEvents } from '../../facilities/facility-details/event/select-event-modal';
import { EventModal } from '../../facilities/facility-details/event/event-modal';
import { availableEventTypeSlice } from '../../facilities/facility-details/event/available-event-type.slice';
import { TakeOverModal } from 'src/components/widgets/takeover-modal';
import { InspectionViewDetails } from '../public-view/inspection-view-details';
import { FacilityAttachmentModal } from '../../facilities/facility-attachments/facility-attachment-modal';

interface OwnProps {}

interface LocationState {
	editMode: boolean;
}

type Props = OwnProps & ApiTypes.RouteProps;

export const InspectionDetails: React.FC<Props> = props => {
	const [templateCheckboxListState, setTemplateCheckboxListState] = React.useState([] as TemplateCheckboxList[]);
	const [loadStatus, setLoadStatus] = React.useState<LoadStatus>(LoadStatus.Ready);
	const [inspectionFormsState, setInspectionFormsState] = React.useState(new Array<InspectionForm>());
	const [toggleDeleteInspectionModal, setToggleDeleteInspectionModal] = React.useState(false);
	const [toggleDeleteInspectionFormModal, setToggleDeleteInspectionFormModal] = React.useState(false);
	const [deletedInspectionFormTemplateId, setDeletedInspectionFormTemplateId] = React.useState<string | null>();
	const [toggleSendEmailModal, setToggleSendEmailModal] = React.useState(false);
	const [sendEmailAnchor, setSendEmailAnchor] = React.useState<EventTarget>();
	const [recentInspections, setRecentInspections] = React.useState([] as InspectionEvent[]);
	const [upcomingInspections, setUpcomingInspections] = React.useState([] as InspectionEvent[]);
	const [isRecordViolationFormModalOpened, setIsRecordViolationFormModalOpened] = React.useState(false);
	const [showAddEventModal, setShowAddEventModal] = React.useState(false);
	const [showEditEventModal, setShowEditEventModal] = React.useState(false);
	const [selectedEvent, setSelectedEvent] = React.useState<ApiTypes.Event | undefined>(undefined);
	const [recentEvents, setRecentEvents] = React.useState([] as ApiTypes.Event[]);
	const [upcomingEvents, setUpcomingEvents] = React.useState([] as ApiTypes.Event[]);
	const [showPrintPreview, setShowPrintPreview] = React.useState(false);
	const [attachmentSelected, setAttachmentSelected] = React.useState<ApiTypes.AttachmentOwnership | null>(null);
	const [showAttachmentModal, setShowAttachmentModal] = React.useState<boolean>(false);
	const [systemInspectionFormTemplate, setSystemInspectionFormTemplate] = React.useState<InspectionFormTemplate>({});
	const recentInspectionsCount = 3;
	const upcomingInspectionCount = 2;
	const dispatch = useReduxDispatch();
	const facility = useReduxSelector(s => s.fogFacility.facility);
	const currentInspection = useReduxSelector(s => s.inspections.currentInspection);
	const currentInspectionForms = useReduxSelector(s => s.inspections.currentInspectionForms);
	const inspectionFormTemplates = useReduxSelector(s => s.inspections.inspectionFormTemplates);
	const allFacilityEvents = useSelector((state: RootState) => state.availableEventTypes.result);
	const inspectionView = useReduxSelector(s => s.inspections.inspectionView);

	const history = useHistory();
	const location = useLocation<LocationState>();

	const [editMode, setEditMode] = React.useState(location.state !== undefined && location.state.editMode);
	const [formsEditable, setFormsEditable] = React.useState(false);

	const loadPropsValue = (dispatch: ReduxDispatch, fogFacilityId: number, inspectionId: number) => {
		dispatch(loadFogFacility(fogFacilityId));
		dispatch(
			loadInspectionFormTemplates(
				`includes=inspectionFormTemplateId,templateName,isActive,isDefaultTemplate,isRemoved,isSystemTemplate&sort=templateName asc&includeRemoved=true&includeInactive=true&includeSystemTemplate=true`
			)
		);
		dispatch(loadCurrentInspection(inspectionId));

		let currentInspectionId = inspectionId;
		loadCompleteRecentInspections(recentInspectionsCount, currentInspectionId).then(inspections => {
			setRecentInspections(inspections);
		});

		upComingInspections(upcomingInspectionCount, currentInspectionId).then(inspections => {
			setUpcomingInspections(inspections);
		});

		loadInspectionEvents(inspectionId);
	};

	React.useEffect(() => {
		dispatch(loadCurrentInspectionForms(props.match.params.inspectionId));
	}, [currentInspection]);

	const loadInspectionEvents = (inspection?: number) => {
		availableEventTypeSlice.setApiUrlPath(
			`${Resource.FogFacilities}/${urlService.getFogFacilityId()}/${Resource.EventTypes}`
		);
		dispatch(availableEventTypeSlice.fetchAll());
		loadRecentFacilityEvents(recentInspectionsCount).then(events => {
			setRecentEvents(events);
		});

		loadUpComingFacilityEvents().then(events => {
			setUpcomingEvents(events);
		});
	};

	const onClickPrintPreviewCancelButton = (e?: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		setShowPrintPreview(false);
	};
	React.useEffect(() => {
		return () => {
			dispatch(resetFacilityData());
			dispatch(resetInspectionData());
			dispatch(resetFacilityAttachment());
			dispatch(availableEventTypeSlice.resetAll());
		};
	}, []);

	React.useEffect(() => {
		let systemInspectionFormTemplate = inspectionFormTemplates.find(i => i.isSystemTemplate);
		setSystemInspectionFormTemplate(systemInspectionFormTemplate || {});
	}, [inspectionFormTemplates]);

	React.useEffect(() => {
		if (currentInspection && !_.isEmpty(currentInspection)) {
			if (currentInspection.publicId) {
				dispatch(loadInspectionView(currentInspection.publicId));
			}
			if (location.state == undefined || !location.state.editMode) {
				setEditMode(!currentInspection.completeDate);
			}
			!currentInspection.completeDate && setFormsEditable(true);
		}
	}, [currentInspection]);

	React.useEffect(() => {
		documentService.disableInputFields(!formsEditable);
	}, [formsEditable]);

	React.useEffect(() => {
		setLoadStatus(LoadStatus.Loading);
		loadPropsValue(dispatch, props.match.params.fogFacilityId, props.match.params.inspectionId);
		window.onbeforeprint = function() {
			$('textarea').each(function() {
				$(this).height($(this)[0].scrollHeight);
			});
		};
	}, [dispatch, props.match.params.fogFacilityId, props.match.params.inspectionId]);

	React.useEffect(() => {
		let templateCheckboxListState: TemplateCheckboxList[] = [];
		let currentlyIncludedTemplateIds: number[] = currentInspectionForms
			? currentInspectionForms.map(x => x.inspectionFormTemplateId)
			: [];

		if (inspectionFormTemplates.length > 0) {
			inspectionFormTemplates.forEach((inspectionFormTemplate: InspectionFormTemplate) => {
				if (inspectionFormTemplate.isActive && inspectionFormTemplate.isRemoved === false) {
					if (
						!inspectionFormTemplate.isSystemTemplate ||
						(inspectionFormTemplate.isSystemTemplate &&
							currentlyIncludedTemplateIds.includes(
								inspectionFormTemplate.inspectionFormTemplateId as number
							))
					) {
						templateCheckboxListState.push({
							id: _.toString(inspectionFormTemplate.inspectionFormTemplateId),
							templateName: _.toString(inspectionFormTemplate.templateName),
							checked: _.includes(
								currentlyIncludedTemplateIds,
								inspectionFormTemplate.inspectionFormTemplateId
							)
						});
					}
				} else {
					if (_.includes(currentlyIncludedTemplateIds, inspectionFormTemplate.inspectionFormTemplateId)) {
						templateCheckboxListState.push({
							id: _.toString(inspectionFormTemplate.inspectionFormTemplateId),
							templateName: _.toString(inspectionFormTemplate.templateName),
							checked: true
						});
					}
				}
			});
		}
		setTemplateCheckboxListState(templateCheckboxListState);
		let newInspectionFormsState: InspectionForm[] = [];
		currentInspectionForms.forEach((inspectionForm: InspectionForm) => {
			newInspectionFormsState.push(inspectionForm);
			if (inspectionForm.inspectionFormHtmlContent) {
				makeJavaScriptRunnable(inspectionForm.inspectionFormHtmlContent);
			}
		});
		setInspectionFormsState(newInspectionFormsState);

		setLoadStatus(LoadStatus.Loaded);
	}, [inspectionFormTemplates, currentInspectionForms]);

	React.useEffect(() => {
		if (
			_.toNumber(props.match.params.inspectionId) === currentInspection.inspectionEventId &&
			currentInspection.completeDate &&
			String.equalCaseInsensitive(loadStatus, LoadStatus.Loaded)
		) {
			documentService.disableInputFields(true);
			setLoadStatus(LoadStatus.Processed);
		}
		setSignatureBoxHeight();
	}, [props.match.params.inspectionId, currentInspection, inspectionFormsState, loadStatus]);

	const toggleAddEventModal = () => {
		!allFacilityEvents.length
			? alertService.addInfo(localizationService.getLocalizedString('events.noScheduleEventsInspection'))
			: setShowAddEventModal(!showAddEventModal);
	};

	const toggleEditEventModal = () => setShowEditEventModal(!showEditEventModal);

	const toggleAttachmentModal = () => setShowAttachmentModal(!showAttachmentModal);

	const onAttachmentChange = (attachment: ApiTypes.AttachmentOwnership) => {
		setAttachmentSelected(attachment);
	};

	const onAttachmentClick = (attachment: ApiTypes.AttachmentOwnership) => {
		setAttachmentSelected(attachment);
		toggleAttachmentModal();
	};

	const onAttachmentDelete = () => {
		setAttachmentSelected(null);
		dispatch(
			loadFacilityAttachments(
				facility.facilityId!,
				false,
				AttachmentType.InspectionAttachment,
				currentInspection.inspectionEventId
			)
		);
		toggleAttachmentModal();
	};

	const changeFormState = (e: any) => {
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		let newInspectionFormsState = inspectionFormsState.filter(x => _.toString(x.inspectionFormTemplateId) !== name);

		if (value === false) {
			setToggleDeleteInspectionFormModal(true);
			setDeletedInspectionFormTemplateId(name);
		} else {
			let url = urlService.getInspectionFormByTemplateIdUrl(props.match.params.inspectionId, name);

			apiService.getResource<TemplateResponse>(url).then((templateResponse: TemplateResponse) => {
				let cifContent = templateResponse.transformedBody;

				let inspectionForm: InspectionForm = {
					inspectionId: props.match.params.inspectionId,
					inspectionFormTemplateId: name,
					inspectionFormHtmlContent: cifContent
				};

				newInspectionFormsState.push(inspectionForm);
				makeJavaScriptRunnable(cifContent);

				setInspectionFormsState(newInspectionFormsState);
			});
		}
		updateCheckBoxState(name, value);
	};

	const setSignatureBoxHeight = () => {
		let selector = `.signature-pad`;
		if ($(selector)) {
			let width = $(selector).width();
			if (width) {
				$(selector).css('height', width / 3);
			}
		}
	};

	const updateCheckBoxState = (name: string, value: boolean, removeSystemTemplate?: boolean) => {
		let newTemplateCheckboxListState: TemplateCheckboxList[] = [];
		let systemInspectionTemplate = inspectionFormTemplates.find(i => i.isSystemTemplate);
		newTemplateCheckboxListState = templateCheckboxListState
			.map((inspectionFormTemplate: TemplateCheckboxList) => {
				if (name === inspectionFormTemplate.id) {
					_.set(inspectionFormTemplate, 'checked', value);
				}
				return inspectionFormTemplate;
			})
			.filter(i => {
				return !(
					removeSystemTemplate &&
					systemInspectionTemplate &&
					systemInspectionTemplate.inspectionFormTemplateId &&
					String.equalCaseInsensitive(i.id, systemInspectionTemplate.inspectionFormTemplateId.toString())
				);
			});
		setTemplateCheckboxListState(newTemplateCheckboxListState);
	};

	const makeJavaScriptRunnable = (htmlCode: string) => {
		if (/<script>[\s\S]*<\/script>/g.exec(htmlCode)) {
			let extractedScript = /<script>[\s\S]*<\/script>/g.exec(htmlCode)![0];

			if (extractedScript !== undefined) {
				//remove <script> and </script> tags since eval expects only code without html tags
				let cleanScript = extractedScript.replace('<script>', '').replace('</script>', '');
				(window as any).eval(cleanScript);
			}
		}
	};

	const addValuesToTheHtmlContent = () => {
		$('input').each(function() {
			if ($(this).is(':checkbox')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else if ($(this).is(':radio')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else {
				$(this).attr('value', ($(this) as any).val());
			}
		});

		$('textarea').each(function() {
			$(this).html(($(this) as any).val());
		});

		$('select').each(function() {
			$(this)
				.find(':not(:selected)')
				.removeAttr('selected');
		});

		$('select').each(function() {
			$(this)
				.find(':selected')
				.attr('selected', 'selected');
		});
	};

	const getAllFormValues = () => {
		let paramObj: any = {};

		inspectionFormsState.forEach((item: InspectionForm) => {
			let formId = `#inspectionForm_${_.toString(item.inspectionFormTemplateId)}`;
			$.each($(formId).serializeArray(), function(_, kv) {
				paramObj[kv.name] = kv.value;
			});
		});

		return paramObj;
	};

	const getInspectionFormsFromState = (inspectionEventId: number) => {
		let inspectionForms: InspectionForm[] = [];
		inspectionFormsState.forEach((item: InspectionForm) => {
			let formId = `inspectionFormContent_${_.toString(item.inspectionFormTemplateId)}`;
			let node = document.getElementById(formId);
			let htmlContent = node ? node.innerHTML : '';

			item.inspectionId = inspectionEventId;
			item.inspectionFormHtmlContent = htmlContent;
			inspectionForms.push(item);
		});

		return inspectionForms;
	};

	const validateAllInspectionForms = () => {
		var hasError = false;

		inspectionFormsState.forEach((item: InspectionForm) => {
			var validationMethodName = `validateFlexibleForm_${_.toString(item.inspectionFormTemplateId)}`;
			if (
				typeof (window as any)[validationMethodName] == 'function' &&
				!(window as any)[validationMethodName]()
			) {
				hasError = true;
			}
		});

		return hasError;
	};

	const cancelEditInspection = () => {
		setEditMode(false);
		setFormsEditable(false);
		setInspectionFormsState([]);
		dispatch(loadCurrentInspectionForms(props.match.params.inspectionId));
	};

	const saveDraftInspection = () => {
		addValuesToTheHtmlContent();
		let inspectionEvent = { ...currentInspection };
		let inspectionEventId = inspectionEvent.inspectionEventId;
		if (inspectionEventId) {
			let completeInspection: CompleteInspectionEvent = {
				inspectionEventId: inspectionEventId,
				inspectionForms: getInspectionFormsFromState(inspectionEventId)
			};
			dispatch(
				saveDraftFacilityInspectionWithForms(completeInspection, () => {
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.updateSuccess',
							'inspectionForm.inspection'
						)
					);
					loadPropsValue(dispatch, props.match.params.fogFacilityId, props.match.params.inspectionId);
				})
			);
		}
	};

	const saveCompleteInspection = () => {
		addValuesToTheHtmlContent();

		if (validateAllInspectionForms()) {
			return false;
		}

		let allFormValues = getAllFormValues();

		let inspectionEvent = { ...currentInspection };
		let inspectionEventId = inspectionEvent.inspectionEventId;
		if (inspectionEventId) {
			let completeInspection: CompleteInspectionEvent = {
				inspectionEventId: inspectionEventId,
				inspectionFormFields: allFormValues,
				inspectionForms: getInspectionFormsFromState(inspectionEventId)
			};

			dispatch(
				completeFacilityInspection(completeInspection, (nextInspection?: InspectionEvent) => {
					if (nextInspection) {
						alertService.addInfo(
							localizationService.getLocalizedString(
								'inspection.scheduledNextInspectionConfirmation',
								nextInspection.inspectionType as string,
								DateUtilService.toDisplayDate(nextInspection.dueDate)
							)
						);
					}
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.updateSuccess',
							'inspectionForm.inspection'
						)
					);
					loadPropsValue(dispatch, props.match.params.fogFacilityId, props.match.params.inspectionId);
					setEditMode(false);
					setFormsEditable(false);
				})
			);
		}
	};

	const deleteInspectionForm = () => {
		setToggleDeleteInspectionFormModal(false);

		if (deletedInspectionFormTemplateId != null) {
			let newInspectionFormsState = inspectionFormsState.filter(
				x => x.inspectionFormTemplateId.toString() !== deletedInspectionFormTemplateId
			);

			let deletedInspection = inspectionFormsState.find(x =>
				String.equalCaseInsensitive(x.inspectionFormTemplateId.toString(), deletedInspectionFormTemplateId)
			);

			if (deletedInspection && deletedInspection.inspectionFormId) {
				let deletedInspectionFormId: number = deletedInspection.inspectionFormId;

				deleteInspectionFormAction(deletedInspectionFormId).then(() => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.deleteInspectionFormSucceeded')
					);

					updateCheckBoxState(
						deletedInspectionFormTemplateId,
						false,
						systemInspectionFormTemplate.inspectionFormTemplateId != null &&
							String.equalCaseInsensitive(
								deletedInspectionFormTemplateId,
								systemInspectionFormTemplate.inspectionFormTemplateId.toString()
							)
					);
					setInspectionFormsState(newInspectionFormsState);

					setDeletedInspectionFormTemplateId(null);
				});
			} else {
				setInspectionFormsState(newInspectionFormsState);
				updateCheckBoxState(deletedInspectionFormTemplateId, false);

				setDeletedInspectionFormTemplateId(null);
			}
		}
	};

	const getRemoveInspectionFormProps = () => {
		let deletedInspection = inspectionFormTemplates.find(x =>
			String.equalCaseInsensitive(
				_.toString(x.inspectionFormTemplateId),
				deletedInspectionFormTemplateId || undefined
			)
		);

		let deleteTitle = localizationService.getLocalizedString('inspectionForm.deleteModalTitle');

		if (deletedInspection) {
			deleteTitle = localizationService.getLocalizedString(
				'inspectionForm.deleteModalTitle',
				deletedInspection.templateName as string
			);
		}

		const removeInspectionFormModalProp: DeleteModalProp = {
			title: deleteTitle,
			message: localizationService.getLocalizedString('inspectionForm.deleteModalMessage'),
			showModal: toggleDeleteInspectionFormModal,
			onCancelButtonClick: () => {
				setToggleDeleteInspectionFormModal(false);
				return;
			},
			onOkayButtonClick: () => deleteInspectionForm(),
			okayButtonText: localizationService.getLocalizedString('screen.buttons.remove'),
			isDeleteButton: false
		};

		return removeInspectionFormModalProp;
	};

	const deleteInspection = () => {
		//close confirmation popup
		setToggleDeleteInspectionModal(false);

		if (currentInspection.inspectionEventId) {
			let facilityId = currentInspection.organizationId;
			if (!facilityId) {
				throw new Error('Invalid organizationId on inspection');
			}
			const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, 'InspectionEvents');
			const inspectionUrl = `${inspectionsUrl}/${currentInspection.inspectionEventId}`;

			apiService
				.deleteResource(inspectionUrl)
				.then(() => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'Inspection')
					);
					const redirectUrl = localStorageService.getLocalStorage(
						ApiTypes.LocalStorageName.InspectionDetailRedictFromPath
					);
					if (redirectUrl) {
						navigateTo(history, redirectUrl as string);
					} else {
						//route to inspection grid page
						let inspectionsUrl = urlService.getReactResourceUrl('Inspections');
						navigateTo(history, inspectionsUrl);
					}
				})
				.catch(e => {
					alertService.addError(e.message);
				});
		}
	};

	const onSendEmailDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		dispatch(loadFacilityContact(urlService.getFogFacilityId(), true));
		setSendEmailAnchor(e && e.target);
		setToggleSendEmailModal(!toggleSendEmailModal);
	};

	const renderTemplateList = (
		<section className="d-print-none">
			<div className="d-flex flex-row">
				<h2>{localizationService.getLocalizedString('inspectionForm.forms')}</h2>
				<div className="ml-auto d-print-none">
					{editMode && currentInspection.completeDate && !formsEditable && (
						<a className="cursor-pointer" onClick={() => setFormsEditable(true)} id="editInspectionForm">
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					)}
				</div>
			</div>
			<div>
				<p>{localizationService.getLocalizedString('inspectionForm.chooseInspectionForm')}</p>

				{templateCheckboxListState.map((item: TemplateCheckboxList, index: number) => {
					return (
						<SingleCheckbox
							key={`inspectionFormTemplate-${index}`}
							id={item.id}
							name={item.id}
							label={item.templateName}
							checked={item.checked}
							onChange={changeFormState}
							className="div-checkbox"
						/>
					);
				})}
			</div>
		</section>
	);

	const openRecordAViolationModal = () => {
		setIsRecordViolationFormModalOpened(true);
	};

	const closeRecordViolationModal = () => {
		setIsRecordViolationFormModalOpened(false);
	};

	let getRecentInspectionViewParams = (): InspectionQuickViewProps => {
		let valueSetting = {
			rows: [] as InspectionQuickViewRow[]
		} as InspectionQuickViewProps;

		valueSetting.headText = localizationService.getLocalizedString('inspection.recentInspections');
		valueSetting.viewType = 'recentInspection';
		valueSetting.columnNames = [
			localizationService.getLocalizedString('inspection.completeDate'),
			localizationService.getLocalizedString('inspection.type'),
			localizationService.getLocalizedString('inspection.result')
		];
		recentInspections.forEach((inspectionEvent: InspectionEvent) => {
			let row = {
				values: [] as InspectionQuickViewValue[]
			} as InspectionQuickViewRow;

			let completeDateEle = {} as InspectionQuickViewValue;
			completeDateEle.value = DateUtilService.toDisplayDate(inspectionEvent.completeDate);
			completeDateEle.dataType = 'link';
			completeDateEle.href = urlService.getFacilityInspectionDetailsUrl(
				inspectionEvent.organizationId,
				inspectionEvent.inspectionEventId
			);

			row.values.push(completeDateEle);

			let inspectionTypeEle = {} as InspectionQuickViewValue;
			inspectionTypeEle.value = inspectionEvent.inspectionType;
			row.values.push(inspectionTypeEle);

			let inspectionResult = {} as InspectionQuickViewValue;
			inspectionResult.value = inspectionEvent.inspectionResult;
			if (complianceStatusService.isCompliant(inspectionResult.value)) {
				inspectionResult.cssName = 'compliance-status-green';
			} else if (complianceStatusService.isNotCompliant(inspectionResult.value)) {
				inspectionResult.cssName = 'compliance-status-red';
			}

			row.values.push(inspectionResult);
			valueSetting.rows.push(row);
		});

		return valueSetting;
	};

	let getUpcomingInspectionViewParams = (): InspectionQuickViewProps => {
		let valueSetting = {
			rows: [] as InspectionQuickViewRow[]
		} as InspectionQuickViewProps;

		valueSetting.headText = localizationService.getLocalizedString('inspection.upcomingInspections');
		valueSetting.viewType = 'upcomingInspection';
		valueSetting.columnNames = [
			localizationService.getLocalizedString('inspection.dueDate'),
			localizationService.getLocalizedString('inspection.type')
		];

		upcomingInspections.forEach((inspectionEvent: InspectionEvent) => {
			let row = {
				values: [] as InspectionQuickViewValue[]
			} as InspectionQuickViewRow;

			let dueDateEle = {} as InspectionQuickViewValue;
			dueDateEle.value = DateUtilService.toDisplayDate(inspectionEvent.dueDate);
			dueDateEle.dataType = 'link';
			dueDateEle.href = urlService.getFacilityInspectionDetailsUrl(
				inspectionEvent.organizationId,
				inspectionEvent.inspectionEventId
			);

			row.values.push(dueDateEle);

			let inspectionTypeEle = {} as InspectionQuickViewValue;
			inspectionTypeEle.value = inspectionEvent.inspectionType;

			row.values.push(inspectionTypeEle);

			valueSetting.rows.push(row);
		});

		return valueSetting;
	};

	let getRecentEventViewParam = (): InspectionQuickViewProps => {
		let valueSetting = {
			rows: [] as InspectionQuickViewRow[]
		} as InspectionQuickViewProps;

		valueSetting.headText = localizationService.getLocalizedString('inspection.recentEvents');
		valueSetting.viewType = 'recentEvents';
		valueSetting.columnNames = [
			localizationService.getLocalizedString('inspection.completeDate'),
			localizationService.getLocalizedString('inspection.type')
		];
		recentEvents.forEach((event: ApiTypes.Event) => {
			let row = {
				values: [] as InspectionQuickViewValue[]
			} as InspectionQuickViewRow;

			let completeDateEle = {} as InspectionQuickViewValue;
			completeDateEle.value = DateUtilService.toDisplayDate(event.completeDate);
			completeDateEle.dataType = 'modalLink';
			completeDateEle.toggleModal = () => {
				setSelectedEvent(event);
				toggleEditEventModal();
			};

			row.values.push(completeDateEle);

			let inspectionTypeEle = {} as InspectionQuickViewValue;
			inspectionTypeEle.value = event.eventTypeName;
			row.values.push(inspectionTypeEle);

			valueSetting.rows.push(row);
		});

		return valueSetting;
	};

	let getUpcomingEventViewParam = (): InspectionQuickViewProps => {
		let valueSetting = {
			rows: [] as InspectionQuickViewRow[]
		} as InspectionQuickViewProps;

		valueSetting.headText = localizationService.getLocalizedString('inspection.upcomingEvents');
		valueSetting.viewType = 'upcomingEvents';
		valueSetting.columnNames = [
			localizationService.getLocalizedString('inspection.dueDate'),
			localizationService.getLocalizedString('inspection.type')
		];

		upcomingEvents.forEach((event: ApiTypes.Event) => {
			let row = {
				values: [] as InspectionQuickViewValue[]
			} as InspectionQuickViewRow;

			let dueDateEle = {} as InspectionQuickViewValue;
			dueDateEle.value = DateUtilService.toDisplayDate(event.dueDate);
			dueDateEle.dataType = 'modalLink';
			dueDateEle.toggleModal = () => {
				setSelectedEvent(event);
				toggleEditEventModal();
			};

			row.values.push(dueDateEle);

			let inspectionTypeEle = {} as InspectionQuickViewValue;
			inspectionTypeEle.value = event.eventTypeName;

			row.values.push(inspectionTypeEle);

			valueSetting.rows.push(row);
		});

		return valueSetting;
	};
	const callbackSaveViolation = () => {
		dispatch(loadFogFacility(props.match.params.fogFacilityId));
	};

	const footer = () => {
		return (
			<>
				<button
					className="btn ai-action d-print-none"
					id="print-button"
					onClick={(e?: any) => {
						e.preventDefault();
						e.stopPropagation();
						window.print();
					}}>
					{localizationService.getLocalizedString('screen.labels.print')}
				</button>
				<button className="btn d-print-none ai-white" onClick={onClickPrintPreviewCancelButton}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</>
		);
	};

	const printPreviewReport = () => {
		return (
			<TakeOverModal cancel={onClickPrintPreviewCancelButton} isPrintButton={true} footer={footer()}>
				<div className="page">
					<div className="page-header" />
					<InspectionViewDetails inspectionView={inspectionView} isPrintReportPreview={true} />
				</div>
			</TakeOverModal>
		);
	};

	return (
		<>
			{showAttachmentModal && editMode ? (
				<FacilityAttachmentModal
					currentInspection={currentInspection}
					onAttachmentDelete={onAttachmentDelete}
					onAttachmentChange={onAttachmentChange}
					toggleAttachmentModal={toggleAttachmentModal}
					cancel={toggleAttachmentModal}
					currentAttachment={attachmentSelected}
					match={props.match}
					editMode={editMode}></FacilityAttachmentModal>
			) : null}
			<div className={`page ${showPrintPreview ? 'd-print-none' : ''}`}>
				<div className={`page-header ${showPrintPreview && `d-print-none`}`}>
					<h1 className="d-print-none">
						{localizationService.getLocalizedString('inspectionForm.inspection')}
					</h1>
					{editMode ? (
						<div className="pt-3 text-right">
							<div>
								{currentInspection.completeDate ? (
									<button
										id="btnCancelEditInspectionTop"
										className="btn ai-white"
										onClick={cancelEditInspection}>
										{localizationService.getLocalizedString('screen.buttons.cancel')}
									</button>
								) : (
									<button
										id="btnSaveDraftInspectionTop"
										className="btn ai-white"
										onClick={saveDraftInspection}>
										{localizationService.getLocalizedString('screen.buttons.saveDraft')}
									</button>
								)}

								<button
									id="btnSaveCompleteInspectionTop"
									className="btn ai-save ml-2"
									onClick={saveCompleteInspection}>
									{currentInspection.completeDate
										? localizationService.getLocalizedString('screen.buttons.save')
										: localizationService.getLocalizedString('screen.buttons.complete')}
								</button>
							</div>
							<div className="font-size-14px-regular py-2">
								{currentInspection.lastModificationDateTimeUtc &&
									!currentInspection.completeDate &&
									localizationService.getLocalizedString(
										'inspectionForm.draftSavedOn',
										DateUtilService.toDisplayDate(currentInspection.lastModificationDateTimeUtc),
										DateUtilService.toDisplayTime(currentInspection.lastModificationDateTimeUtc)
									)}
							</div>
						</div>
					) : (
						currentInspection.completeDate && (
							<button id="btnEditInspection" className="btn ai-white" onClick={() => setEditMode(true)}>
								{localizationService.getLocalizedString('screen.buttons.edit')}
							</button>
						)
					)}
				</div>

				<div className="d-flex top-action-div d-print-none">
					<div className="form-row w-100 action-panel align-items-center">
						{currentInspection.completeDate && !editMode && (
							<div className="action-item" id="sendEmailLink" onClick={onSendEmailDivClick}>
								<FaEnvelope className="ai-slate" />
								<span>{localizationService.getLocalizedString('screen.labels.sendEmail')}</span>
							</div>
						)}
						<div
							className="action-item"
							id="print-inspection"
							onClick={() => {
								currentInspection.completeDate && !editMode
									? setShowPrintPreview(true)
									: window.print();
							}}>
							<FaPrint className="ai-slate" />
							<span>{localizationService.getLocalizedString('screen.labels.printReport')}</span>
						</div>
						<div
							className="action-item"
							id="delete-inspection"
							onClick={() => {
								setToggleDeleteInspectionModal(true);
							}}>
							<FaTrashAlt className="ai-slate" />
							<span>{localizationService.getLocalizedString('screen.labels.delete')}</span>
						</div>
						{currentInspection.completeDate && !editMode && (
							<UncontrolledDropdown className="action-item-black">
								<DropdownToggle tag="a" className="btn" caret>
									{localizationService.getLocalizedString('screen.labels.action')}
								</DropdownToggle>
								<InspectionActionDropdown
									clickRecordAViolation={openRecordAViolationModal}
									toggleAddEventModal={toggleAddEventModal}
								/>
							</UncontrolledDropdown>
						)}
					</div>
				</div>

				<div className="page-wrapper inspection-content">
					<div className="main">
						<FacilityAddress
							endOfPageTitle={localizationService.getLocalizedString(
								'pageTitle.inspectionDetailsEndOfTitle'
							)}
							isReadOnly={!editMode}
						/>
					</div>

					<div className="sidebar">
						<InspectionSummary
							inspection={currentInspection}
							loadCurrentInspection={loadCurrentInspection}
							isReadOnly={!editMode}
						/>
						<InspectionQuickView {...getRecentInspectionViewParams()} />
						<InspectionQuickView {...getUpcomingInspectionViewParams()} />
						<InspectionQuickView {...getRecentEventViewParam()} />
						<InspectionQuickView {...getUpcomingEventViewParam()} />
						<FacilityAttachments
							facility={facility}
							isReadOnly={!editMode}
							attachmentType={AttachmentType.InspectionAttachment}
							ownerId={currentInspection.inspectionEventId}
							fromInspectionDetails={true}
							onAttachmentClick={onAttachmentClick}
						/>
						<FacilityLinks inspectionId={props.match.params.inspectionId} isReadOnly={!editMode} />
					</div>
					{currentInspection && (
						<div className="main2">
							{renderTemplateList}

							{inspectionFormsState.map((item: InspectionForm, index: number) => {
								return (
									item.inspectionFormHtmlContent && (
										<form
											id={`inspectionForm_${_.toString(item.inspectionFormTemplateId)}`}
											key={`inspectionForm-${index}`}
											autoComplete="off">
											<span
												id={`inspectionFormContent_${_.toString(
													item.inspectionFormTemplateId
												)}`}
												dangerouslySetInnerHTML={{ __html: item.inspectionFormHtmlContent }}
											/>
										</form>
									)
								);
							})}

							<div className="d-flex justify-content-between mb-2">
								{editMode && (
									<div className="pt-3">
										<div className="d-sm-flex">
											{currentInspection.completeDate ? (
												<button
													id="btnCancelEditInspectionBottom"
													className="btn ai-white"
													onClick={cancelEditInspection}>
													{localizationService.getLocalizedString('screen.buttons.cancel')}
												</button>
											) : (
												<button
													id="btnSaveDraftInspectionBottom"
													className="btn ai-white"
													onClick={saveDraftInspection}>
													{localizationService.getLocalizedString('screen.buttons.saveDraft')}
												</button>
											)}

											<button
												id="btnSaveCompleteInspectionBottom"
												className="btn ai-save mx-2"
												onClick={saveCompleteInspection}>
												{currentInspection.completeDate
													? localizationService.getLocalizedString('screen.buttons.save')
													: localizationService.getLocalizedString('screen.buttons.complete')}
											</button>
										</div>
										{currentInspection.lastModificationDateTimeUtc &&
											!currentInspection.completeDate && (
												<div className="py-2">
													{localizationService.getLocalizedString(
														'inspectionForm.draftSavedOn',
														DateUtilService.toDisplayDate(
															currentInspection.lastModificationDateTimeUtc
														),
														DateUtilService.toDisplayTime(
															currentInspection.lastModificationDateTimeUtc
														)
													)}
												</div>
											)}
									</div>
								)}
							</div>
						</div>
					)}
					{toggleDeleteInspectionModal && (
						<DeleteModal
							key="confirmDeleteInspectionModal"
							title={localizationService.getLocalizedString('inspection.deleteModalTitle')}
							message={localizationService.getLocalizedString('inspection.deleteModalMessage')}
							showModal={toggleDeleteInspectionModal}
							onCancelButtonClick={() => {
								setToggleDeleteInspectionModal(false);
							}}
							onOkayButtonClick={deleteInspection}
							okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
							isDeleteButton={true}
						/>
					)}

					{toggleDeleteInspectionFormModal && (
						<DeleteModal {...getRemoveInspectionFormProps()} key="confirmDeleteInspectionModal" />
					)}

					{toggleSendEmailModal && (
						<div>
							<FacilitySendEmail
								show={toggleSendEmailModal}
								anchor={sendEmailAnchor}
								toggle={() => setToggleSendEmailModal(false)}
								letterTemplateCategory={LetterTemplateCategory.InspectionEmail}
								inspectionId={props.match.params.inspectionId}
							/>
						</div>
					)}

					{isRecordViolationFormModalOpened && (
						<div>
							<RecordViolationForm
								closeModal={closeRecordViolationModal}
								facilityId={currentInspection.organizationId || 0}
								sourceId={currentInspection.inspectionEventId}
								sourceType={'inspection'}
								saveCallback={callbackSaveViolation}
								deleteCallback={callbackSaveViolation}
							/>
						</div>
					)}
					{showAddEventModal && (
						<SelectEvents
							showModal={showAddEventModal}
							onCancel={toggleAddEventModal}
							loadInspectionEvents={loadInspectionEvents}
							inspectionId={currentInspection.inspectionEventId}
						/>
					)}
					{showEditEventModal && selectedEvent && (
						<EventModal
							eventId={selectedEvent.genericEventId}
							facilityId={selectedEvent.facilityId}
							title={localizationService.getLocalizedString('events.addEvent')}
							showModal={showEditEventModal}
							eventToEdit={selectedEvent}
							loadInspectionEvent={loadInspectionEvents}
							inspectionId={currentInspection.inspectionEventId}
							onCancel={() => {
								setSelectedEvent(undefined);
								toggleEditEventModal();
							}}
						/>
					)}
				</div>
			</div>
			{showPrintPreview && printPreviewReport()}
		</>
	);
};
