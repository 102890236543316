import { FacilityCleanings } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, Resource } from '../../../../services';

export const facilityDevicesSlice = new RestSlice<FacilityCleanings>(
	localizationService.getLocalizedString('haulerPortal.selectFacility.facilities'),
	Resource.Extractors
);

export const facilityDevicesRestSliceReducer = facilityDevicesSlice.rests.reducer;
