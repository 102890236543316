import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopoverModal, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { ApiEndpoints, AutoNumberDomainType, CccSite, Dataset, DropDownOption, Jurisdiction, Lookup } from '@rcp/types';
import {
	authoritySettingService,
	localizationService,
	navigateTo,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { alertService, loadJurisdiction, RootState } from 'src/redux';
import _ from 'lodash';
import { settingLookupsSiteTypeSlice } from 'src/components/authority/backflow/settings/sites/site-type-lookup-slice';
import { useHistory } from 'react-router-dom';
import { RestApi } from 'src/services/rest.api';
import { settingLookupsDataSetSlice } from '../../settings/dataset-lookup.slice';
import { settingLookupsSiteUseSlice } from '../../settings/sites/site-use-lookup-slice';
interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
}

type Props = OwnProps;

interface CccSiteAddForm {
	siteNumber?: string;
	dataSetId?: number;
	company?: string;
	contactFirstName?: string;
	contactLastName?: string;
	addressLine1?: string;
	addressLine2?: string;
	cityName?: string;
	jurisdictionId?: number;
	zipCode?: string;
	phone?: string;
	email?: string;
	siteTypeId?: number;
	siteUseId?: number;
	latitude: string;
	longitude: string;

	dataSetIdError?: string;
	siteNumberError?: string;
	emailError?: string;
	phoneError?: string;
	companyError?: string;
	contactFirstNameError?: string;
	contactLastNameError?: string;
	emailFormatError?: string;
	latitudeError?: string;
	longitudeError?: string;
}

const initialCccSiteAddForm: CccSiteAddForm = {
	siteNumber: '',
	company: '',
	contactFirstName: '',
	contactLastName: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	zipCode: '',
	email: '',
	phone: '',
	latitude: '',
	longitude: ''
};

export const CccSiteAddModal: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const restApi = new RestApi<any>(ApiEndpoints.CccSites);

	const [formState, setFormState] = React.useState(initialCccSiteAddForm);
	const [jurisdictionValues, setJurisdictionValues] = React.useState([] as DropDownOption[]);
	const [datasetValues, setDatasetValues] = React.useState([] as DropDownOption[]);
	const [siteTypeValues, setSiteTypeValues] = React.useState([] as DropDownOption[]);
	const [siteUseValues, setSiteUseValues] = React.useState([] as DropDownOption[]);

	React.useEffect(() => {
		dispatch(loadJurisdiction());
		dispatch(settingLookupsSiteTypeSlice.fetchAll());
		dispatch(settingLookupsSiteUseSlice.fetchAll());
		dispatch(settingLookupsDataSetSlice.fetchAll());
	}, [dispatch]);

	let { jurisdictionList: jurisdictions } = useSelector((state: RootState) => state.jurisdictions);
	let { result: siteTypes } = useSelector((state: RootState) => state.settingLookupSiteTypes);
	let { result: siteUses } = useSelector((state: RootState) => state.settingLookupSiteUses);
	let { result: dataSets } = useSelector((state: RootState) => state.settingLookupDataSets);

	React.useEffect(() => {
		authoritySettingService.getNextAutoNumber(AutoNumberDomainType.CccSite).then((nextHazardNumber: any) => {
			let newState = { ...initialCccSiteAddForm };
			newState.siteNumber = nextHazardNumber as string;
			setFormState(newState);
		});
	}, []);

	useEffect(() => {
		let jurisdictionOptions: DropDownOption[] = [];

		if (jurisdictions.length > 0) {
			jurisdictionOptions = jurisdictions.map((a: Jurisdiction) => {
				return { label: a.name!, value: a.jurisdictionId };
			});
			setJurisdictionValues(jurisdictionOptions);
		}
	}, [jurisdictions]);

	useEffect(() => {
		let dataSetOptions: DropDownOption[] = [];
		if (dataSets.length > 0) {
			dataSetOptions = dataSets
				.filter(a => a.isActive)
				.map((a: Dataset) => {
					return { label: a.name!, value: a.dataSetId };
				});

			setDatasetValues(dataSetOptions);
		}
	}, [dataSets]);

	useEffect(() => {
		let siteTypeOptions: DropDownOption[] = [];

		if (siteTypes.length > 0) {
			siteTypeOptions = siteTypes
				.filter(a => a.isActive)
				.map((a: Lookup) => {
					return { label: a.code!, value: a.lookupId };
				});
			setSiteTypeValues(siteTypeOptions);
		}
	}, [siteTypes]);

	useEffect(() => {
		let siteUseOptions: DropDownOption[] = [];

		if (siteUses.length > 0) {
			siteUseOptions = siteUses
				.filter(a => a.isActive)
				.map((a: Lookup) => {
					return { label: a.code!, value: a.lookupId };
				});

			setSiteUseValues(siteUseOptions);
		}
	}, [siteUses]);

	const saveDetails = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();
		if (!isFormValidateForSave()) {
			return;
		}
		let siteToAdd: CccSite = {
			siteNumber: formState.siteNumber,
			company: formState.company,
			contactFirstName: formState.contactFirstName,
			contactLastName: formState.contactLastName,
			addressLine1: formState.addressLine1,
			addressLine2: formState.addressLine2,
			cityName: formState.cityName,
			jurisdictionId: formState.jurisdictionId,
			zipCode: formState.zipCode,
			phone: formState.phone,
			email: formState.email,
			siteTypeId: formState.siteTypeId,
			siteUseId: formState.siteUseId,
			latitude: formState.latitude,
			longitude: formState.longitude,
			dataSetId: formState.dataSetId
		};

		restApi
			.createOne(siteToAdd)
			.then((response: any) => {
				let siteId = response.items[0].siteId;
				props.toggle();
				goToSiteDetails(siteId);

				window.setTimeout(function() {
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.addSuccess',
							localizationService.getLocalizedString('cccSite.site')
						)
					);
				}, 500);
			})
			.catch((e: any) => {
				setFormState({ ...formState, siteNumberError: e.message });
				alertService.addError(e.message);
			});
	};

	const goToSiteDetails = (siteId: number) => {
		const url = urlService.getReactResourceUrl(Resource.CccSites);
		navigateTo(history, url + '/' + siteId);
	};

	const validateFields = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			'siteNumber',
			'siteNumberError',
			localizationService.getLocalizedString('cccSite.siteNumber')
		);
		validationService.validateRequiredField(
			newState,
			'dataSetId',
			'dataSetIdError',
			localizationService.getLocalizedString('cccSite.dataSetName')
		);
		validationService.validateFieldLength(
			newState,
			'phone',
			'phoneError',
			30,
			localizationService.getLocalizedString('screen.labels.contactPhone')
		);
		validationService.validateEmailFormatField(newState, 'email', 'emailFormatError');

		validationService.validateFieldLength(
			newState,
			'email',
			'emailError',
			200,
			localizationService.getLocalizedString('screen.labels.contactEmail')
		);
		validationService.validateFieldLength(
			newState,
			'contactFirstName',
			'contactFirstNameError',
			50,
			localizationService.getLocalizedString('screen.labels.contactFirstName')
		);
		validationService.validateFieldLength(
			newState,
			'contactLastName',
			'contactLastNameError',
			50,
			localizationService.getLocalizedString('screen.labels.contactLastName')
		);
		validationService.validateFieldLength(
			newState,
			'company',
			'companyError',
			70,
			localizationService.getLocalizedString('cccSite.company')
		);

		validationService.validateLatitudeField(
			newState,
			'latitude',
			'latitudeError',
			localizationService.getLocalizedString('cccSite.latitude'),
			false
		);

		validationService.validateLongitudeField(
			newState,
			'longitude',
			'longitudeError',
			localizationService.getLocalizedString('cccSite.longitude'),
			false
		);

		setFormState(newState);

		const isFormValid = !validationService.hasError(
			newState,
			'siteNumberError',
			'dataSetIdError',
			'phoneError',
			'emailError',
			'contactFirstNameError',
			'contactLastNameError',
			'companyError',
			'emailFormatError',
			'latitudeError',
			'longitudeError'
		);
		return isFormValid;
	};

	const isFormValidateForSave = (): boolean => {
		const isFormValid = validateFields();
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const cancelSaveOverview = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('cccSite.addSite')}
				save={saveDetails}
				cancel={cancelSaveOverview}>
				<TextInput
					id="siteNumber"
					name="siteNumber"
					label={localizationService.getLocalizedString('cccSite.siteNumber')}
					value={formState.siteNumber}
					onChange={changeFormState}
					isRequired
					error={formState.siteNumberError}
				/>
				<SingleSelectDropdown
					id="dataSetId"
					name="dataSetId"
					label={localizationService.getLocalizedString('cccSite.dataSetName')}
					value={_.toString(formState.dataSetId)}
					onChange={changeFormState}
					options={datasetValues}
					isRequired={true}
					error={formState.dataSetIdError}
				/>
				<TextInput
					id="company"
					name="company"
					label={localizationService.getLocalizedString('cccSite.company')}
					value={formState.company}
					onChange={changeFormState}
					error={formState.companyError}
				/>

				<TextInput
					id="addressLine1"
					name="addressLine1"
					label={localizationService.getLocalizedString('cccSite.addressLine1')}
					helpText={localizationService.getLocalizedString('screen.helpText.address1')}
					value={formState.addressLine1}
					onChange={changeFormState}
				/>
				<TextInput
					id="addressLine2"
					name="addressLine2"
					label={localizationService.getLocalizedString('cccSite.addressLine2')}
					helpText={localizationService.getLocalizedString('screen.helpText.address2')}
					value={formState.addressLine2}
					onChange={changeFormState}
				/>
				<div className="form-row">
					<TextInput
						id="cityName"
						name="cityName"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.cityName')}
						value={formState.cityName}
						onChange={changeFormState}
					/>
					<SingleSelectDropdown
						id="jurisdictionId"
						name="jurisdictionId"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.state')}
						value={_.toString(formState.jurisdictionId)}
						onChange={changeFormState}
						options={jurisdictionValues}
					/>
					<TextInput
						id="zipCode"
						name="zipCode"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.zipCode')}
						value={formState.zipCode}
						onChange={changeFormState}
					/>
				</div>
				<div className="filter-group my-3 px-3 rounded-lg border border-secondary">
					<div className="filter-group-title mx-2">
						{localizationService.getLocalizedString('screen.labels.contactPerson')}
					</div>
					<div className="form-row">
						<TextInput
							id="contactFirstName"
							name="contactFirstName"
							className="form-group col-sm-6"
							label={localizationService.getLocalizedString('screen.labels.contactFirstName')}
							value={formState.contactFirstName}
							onChange={changeFormState}
							error={formState.contactFirstNameError}
						/>
						<TextInput
							id="contactLastName"
							name="contactLastName"
							className="form-group col-sm-6"
							label={localizationService.getLocalizedString('screen.labels.contactLastName')}
							value={formState.contactLastName}
							onChange={changeFormState}
							error={formState.contactLastNameError}
						/>
					</div>
					<TextInput
						id="phone"
						name="phone"
						label={localizationService.getLocalizedString('screen.labels.contactPhone')}
						value={formState.phone}
						onChange={changeFormState}
						error={formState.phoneError}
					/>
					<TextInput
						id="email"
						name="email"
						label={localizationService.getLocalizedString('screen.labels.contactEmail')}
						value={formState.email}
						onChange={changeFormState}
						error={formState.emailError || formState.emailFormatError}
					/>
				</div>
				<SingleSelectDropdown
					id="siteTypeId"
					name="siteTypeId"
					label={localizationService.getLocalizedString('cccSite.siteType')}
					value={_.toString(formState.siteTypeId)}
					onChange={changeFormState}
					options={siteTypeValues}
				/>
				<SingleSelectDropdown
					id="siteUseId"
					name="siteUseId"
					label={localizationService.getLocalizedString('cccSite.siteUse')}
					value={_.toString(formState.siteUseId)}
					onChange={changeFormState}
					options={siteUseValues}
				/>
				<div className="form-row">
					<TextInput
						id="latitude"
						name="latitude"
						type="number"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('cccSite.latitude')}
						value={formState.latitude}
						error={formState.latitudeError}
						onChange={changeFormState}
					/>
					<TextInput
						id="longitude"
						name="longitude"
						type="number"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('cccSite.longitude')}
						value={formState.longitude}
						error={formState.longitudeError}
						onChange={changeFormState}
					/>
				</div>
			</PopoverModal>
		</div>
	);
};
