import React, { useEffect, useState } from 'react';
import Stepper, { Step } from '../../../../../widgets/stepper';
import { Step1, Step2, Step3, Step5, Step4 } from './steps';
import { AddNoticeSteps, FeatureNames, RouteProps } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import { useDispatch } from 'react-redux';
import { cleaningNoticeTemplateSlice } from '../cleaning-notice-template.slice';
import { History } from 'history';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { useReduxSelector } from 'src/redux';

interface Props extends RouteProps {
	history: History;
}
const CleaningNoticeStepper: React.FC<Props> = props => {
	const dispatch = useDispatch();
	let locationState = props.history.location.state;

	const { STEP1, STEP2, STEP3, STEP4, STEP5 } = AddNoticeSteps;

	useEffect(() => {
		const noticeTemplateId =
			props.match.params.noticeTemplateId || (locationState && (locationState as any).noticeTemplateId);
		noticeTemplateId && dispatch(cleaningNoticeTemplateSlice.fetchOne(noticeTemplateId));
		return () => {
			dispatch(cleaningNoticeTemplateSlice.resetAll());
		};
	}, [props.match.params.noticeTemplateId]);

	return !urlService.isAdministrator() ? (
		<AccessDeniedPage />
	) : (
		<div className="page">
			<div className="page-header">
				<h1>
					{localizationService.getLocalizedString(
						`authoritySetting.noticesSettings.cleaning.${
							props.match.params.noticeTemplateId ? 'editCleaningNotice' : 'addCleaningNotice'
						}`
					)}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main-settings">
					<section className="min-height-180px px-2">
						<Stepper vertical initialStep={props.match.params.noticeTemplateId ? STEP5 : STEP1}>
							<Step
								stepId={STEP1}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.chooseName'
								)}>
								<Step1 />
							</Step>
							<Step
								stepId={STEP2}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.selectRecipients'
								)}>
								<Step2 />
							</Step>
							<Step
								stepId={STEP3}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.editContent'
								)}>
								<Step3 />
							</Step>
							<Step
								stepId={STEP4}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.scheduleDeliveryOption'
								)}>
								<Step4 />
							</Step>
							<Step
								disabled={false}
								stepId={STEP5}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.previewAndSave'
								)}>
								<Step5 />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export default CleaningNoticeStepper;
