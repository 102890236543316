import { IndustriesActionType, IndustriesAction } from './industries-actions';
import { IndustriesState, initialIndustriesState } from './industries-state';

export const IndustryDetailsReducer = (state = initialIndustriesState, action: IndustriesAction): IndustriesState => {
	switch (action.type) {
		case IndustriesActionType.LoadIndustryDetailsRequest:
			return { ...state };
		case IndustriesActionType.ResetIndustry:
			return { ...state, industryDetails: {}, templates: [], parameterGroups: [] };
		case IndustriesActionType.LoadIndustryDetailsSuccess:
			return {
				...state,
				industryDetails: { ...action.industryDetails },
				templates: [...action.templates],
				parameterGroups: [...action.parameterGroups]
			};
		default:
			return state;
	}
};
