import _ from 'lodash';
import React, { useState, useEffect, VoidFunctionComponent } from 'react';
import { Button } from 'reactstrap';
import { CloseSvg } from 'src/components/svg';
import { ArrowUpSvg } from 'src/components/svg/arrow-up.svg';
import { ArrowSvg } from 'src/components/svg/arrow.svg';
import { FilterRow, getInitialFilterItem } from 'src/features/filter/filter-row';
import { filterService } from 'src/features/filter/filter-service';
import { alertService } from 'src/redux';
import { localizationService } from 'src/services';
import { FilterItem, FilterProperty } from 'src/services/data-types/filter-types';
interface FilterItemToList {
	filterItem: FilterItem;
	isEdit: boolean;
	propertyLabel?: string;
}

interface OwnProps {
	filterItemToList: FilterItemToList;
	handleFilterItemApply: (originalFilterItem: FilterItem, updatedFilterItem: FilterItem) => void;
	handleFilterItemDelete: (filterItem: FilterItem) => void;
}

export const FilterItemCard: React.FC<OwnProps> = props => {
	const [filterItem, setFilterItem] = React.useState(getInitialFilterItem());
	const [filterItemToList, setFilterItemToList] = React.useState<FilterItemToList>({
		filterItem: getInitialFilterItem(),
		isEdit: true
	});
	const [originalFilterItem, setOriginalFilterItem] = React.useState(getInitialFilterItem());
	const [selectedFilterProperty, setSelectedFilterProperty] = React.useState([] as FilterProperty[]);
	const [hasChange, setHasChange] = React.useState(false);
	const [isNewFilterItem, setIsNewFilterItem] = React.useState(false);

	React.useEffect(() => {
		setFilterItemToList(props.filterItemToList);
		setFilterItem(_.cloneDeep(props.filterItemToList.filterItem));
		setOriginalFilterItem(_.cloneDeep(props.filterItemToList.filterItem));
		if (_.isEqual(props.filterItemToList.filterItem.property, filterService.emptyProperty)) {
			setIsNewFilterItem(true);
		}
	}, [props.filterItemToList.filterItem]);

	React.useEffect(() => {
		let newSelectedFilterProperty = filterItem.domainObject.properties.filter(x => {
			return String.equalCI(x.name, filterItem.property.name);
		});
		setSelectedFilterProperty(newSelectedFilterProperty);
	}, [filterItem]);

	const handleFilterItemUpdate = (filter: FilterItem) => {
		setFilterItem(filter);
		setHasChange(true);
	};

	const handleFilterItemApply = (filter: FilterItem) => {
		let newFilter = _.cloneDeep(filter);
		filterService.validateFilterItem(newFilter);
		var isFilterItemInvalid = filterService.isFilterItemInvalid(newFilter);
		setFilterItem(newFilter);

		if (isFilterItemInvalid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return;
		}
		props.handleFilterItemApply(originalFilterItem, newFilter);
		setHasChange(false);
	};

	const getFilterLabel = (fi: FilterItem) => {
		if (!fi.domainObject || !fi.domainObject.label) {
			throw new Error(`FilterItem is invalid: ` + JSON.stringify(filterItem));
		}
		return `${fi.domainObject.label} ${
			selectedFilterProperty && selectedFilterProperty.length ? `- ${selectedFilterProperty[0].label}` : ''
		}`;
	};

	const handleFilterItemToggle = () => {
		let newFilterItemToList = { ...filterItemToList };
		newFilterItemToList.isEdit = !newFilterItemToList.isEdit;
		setFilterItemToList(newFilterItemToList);
	};

	return (
		<div className={`filter-item-card `}>
			<div className="d-flex font-size-16px-bold ">
				<span className="filter-item-header">{getFilterLabel(filterItem)}</span>
				<div className="ml-auto">
					{filterItemToList.isEdit ? (
						<button className=" mr-3" onClick={() => handleFilterItemToggle()}>
							<ArrowUpSvg />
						</button>
					) : (
						<button className="mr-3" onClick={() => handleFilterItemToggle()}>
							<ArrowSvg />
						</button>
					)}
					<button className="  " onClick={() => props.handleFilterItemDelete(filterItem)}>
						<CloseSvg size="10" />
					</button>
				</div>
			</div>
			{filterItemToList.isEdit && (
				<>
					<FilterRow
						filterItem={filterItem}
						rowNumber={0}
						updateFilter={filter => {
							handleFilterItemUpdate(filter);
						}}
						deleteFilter={() => {}}
						isForMap={true}
					/>
					{hasChange && (
						<div className="d-flex form-group pr-1">
							<Button
								color="btn ai-action ml-auto"
								onClick={() => {
									handleFilterItemApply(filterItem);
								}}>
								{isNewFilterItem
									? localizationService.getLocalizedString('screen.buttons.add')
									: localizationService.getLocalizedString('screen.buttons.update')}
							</Button>
						</div>
					)}
				</>
			)}
		</div>
	);
};
