import React from 'react';

import { localizationService } from 'src/services';
import 'src/components/authority/shared/settings/authority-settings.scss';
import InspectionTypeSettingComponent from './inspection-type.component';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

const InspectionsSettingsComponent: React.FunctionComponent = () => {
	return (
		<div className="page">
			<>
				<div className="page-header">
					<BackArrow />
					<h1>{localizationService.getLocalizedString('authoritySetting.inspections')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main settings">
						<InspectionTypeSettingComponent></InspectionTypeSettingComponent>
					</div>
				</div>
			</>
		</div>
	);
};

export const InspectionSettingFacility = InspectionsSettingsComponent;
