import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localizationService } from 'src/services';

type Props = {
	message?: string;
};

class AccessDeniedComponent extends Component<Props, any> {
	render() {
		return (
			<div className="page">
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('screen.noPermissionToPage.title')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main">
						<section className="min-height-180px">
							<p>
								{this.props.message
									? this.props.message
									: localizationService.getLocalizedString('screen.noPermissionToPage.message')}
							</p>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export const AccessDeniedPage = connect()(AccessDeniedComponent);
