import { Dictionary, FogFacility } from '@rcp/types';
import { MapLegendKey } from 'src/components/widgets/map/map-service';
import { localizationService } from './localizationService';

export class MapDataSortService {
	static comparePossiblyUndefinedStrings = (a: string | undefined, b: string | undefined) => {
		if (a === b) {
			return 0;
		} else if (!a) {
			return 1;
		} else if (!b) {
			return -1;
		} else {
			return a.toUpperCase().localeCompare(b.toUpperCase());
		}
	};

	static getRiskScoreSortValue = (riskScore: string | undefined) => {
		let sortValue = -1;
		switch (riskScore) {
			case localizationService.getLocalizedString('facility.riskScoreHigh'):
				sortValue = 1;
				break;
			case localizationService.getLocalizedString('facility.riskScoreMedium'):
				sortValue = 2;
				break;
			case localizationService.getLocalizedString('facility.riskScoreLow'):
				sortValue = 3;
				break;
			default:
				sortValue = 4;
		}
		return sortValue;
	};

	static getComplianceSortValue = (compliance: string | undefined, complianceValuesDict: Dictionary<number>) => {
		let sortValue = -1;
		switch (compliance) {
			case localizationService.getLocalizedString('facility.nonCompliant'):
				sortValue = 1;
				break;
			case localizationService.getLocalizedString('facility.COMPLIANT'):
				sortValue = 2;
				break;
			default:
				sortValue = compliance && complianceValuesDict[compliance] ? complianceValuesDict[compliance] : 999;
		}
		return sortValue;
	};

	static getDeviceSortValue = (deviceCount: number | undefined) => {
		if (deviceCount && deviceCount > 0) {
			return 1;
		}
		return 0;
	};

	static sortByMapLegend = (
		facilityA: FogFacility,
		facilityB: FogFacility,
		mapLegend: MapLegendKey,
		complianceValuesDict: Dictionary<number> = {}
	) => {
		let sortValue = -1;
		switch (mapLegend) {
			case MapLegendKey.Risk:
				sortValue =
					MapDataSortService.getRiskScoreSortValue(facilityA.riskScore) -
					MapDataSortService.getRiskScoreSortValue(facilityB.riskScore);
				break;
			case MapLegendKey.Compliance:
				sortValue =
					MapDataSortService.getComplianceSortValue(facilityA.complianceStatus, complianceValuesDict) -
					MapDataSortService.getComplianceSortValue(facilityB.complianceStatus, complianceValuesDict);
				break;
			case MapLegendKey.DeviceStatus:
				sortValue =
					MapDataSortService.getDeviceSortValue(facilityA.activeDeviceCount) -
					MapDataSortService.getDeviceSortValue(facilityB.activeDeviceCount);
				break;
			default:
				sortValue = 0;
		}

		return sortValue;
	};
}
