import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
	ColumnDefinition,
	IppFileStore,
	IppPackageElement,
	Dictionary,
	DraftFilter,
	Filter,
	FilterApplyTo,
	FilterObjectName,
	LocalStorageName
} from '@rcp/types';
import _ from 'lodash';
import React, { createRef, FC, RefObject, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchInput, SingleCheckbox } from 'src/components/widgets';
import { ReportPackageStatus } from 'src/constants';
import { FilterEditModal } from 'src/features/filter';
import { FilterQuickViewer } from 'src/features/filter/filter-quick-viewer';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { downloadAttachment } from 'src/redux/ipp/authority/report-packages/reviews';
import { localizationService } from 'src/services';
import { Utils } from 'src/services/utils';

interface Props {
	reportStatus?: string;
	files?: IppFileStore[];
	attachmentTypeId?: number;
	onChangeAttachment?: (event?: any, id?: number, attachmentTypeId?: number) => void;
	showSearch?: boolean;
	showFilter?: boolean;
	attachmentTypes?: IppPackageElement[];
	loadAttachments?: (
		id?: number,
		status?: string,
		attachmentTypes?: IppPackageElement[],
		queryParameters?: Dictionary<string>
	) => Promise<void>;
}

interface CompState {
	queryParameters: Dictionary<string>;
	showFilterMenuPopOver: boolean;
	showFilterEditorModal: boolean;
	anchorElement: any;
	draftFilterId?: number;
}

let initialCompState: CompState = {
	queryParameters: {} as Dictionary<string>,
	showFilterMenuPopOver: false,
	showFilterEditorModal: false,
	draftFilterId: undefined,
	anchorElement: null
};

const IppPackageItemAttachmentFiles: FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const gridRef: RefObject<Grid> = createRef();
	const [pagination, setPagination] = useState({ skip: 0, take: 15 });
	const [sort, setSort] = useState([{ field: 'name', dir: 'asc' }] as SortDescriptor[]);
	const [filterState, setFilterState] = useState<CompState>(initialCompState);
	const [files, setFiles] = useState(props.files);
	let columnsKeys = [
		'name',
		'originalFileName',
		'description',
		'uploaderUserFullName',
		'uploadDateTimeUtc',
		'lastSubmissionDateTimeLocal'
	];

	let draftColumnsKeys = [
		'isAssociatedWithReportPackage',
		'name',
		'originalFileName',
		'description',
		'uploadDateTimeUtc',
		'uploaderUserFullName',
		'lastSubmissionDateTimeLocal'
	];
	useEffect(() => {
		setFiles(props.files);
	}, [props]);

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(
			String.equalCaseInsensitive(props.reportStatus, ReportPackageStatus.Draft) ? draftColumnsKeys : columnsKeys
		);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.reportPackage.package.fileStore.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	const selectAttachment = (row: any) => {
		let dataItem = row.dataItem as IppFileStore;
		return (
			<td>
				{props.files && props.files.length && (
					<SingleCheckbox
						id={`isAssociatedWithReportPackage${dataItem.fileStoreId}${props.attachmentTypeId}`}
						name="isAssociatedWithReportPackage"
						className="mx-auto"
						checked={dataItem.isAssociatedWithReportPackage}
						onChange={(event: any) =>
							props.onChangeAttachment &&
							props.onChangeAttachment(event, dataItem.fileStoreId, props.attachmentTypeId)
						}
					/>
				)}
			</td>
		);
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (String.equalCaseInsensitive(i.field, 'isAssociatedWithReportPackage')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							cell={(row: any) => selectAttachment(row)}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'name')) {
					return (
						<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={nameToLink} />
					);
				}
				if (i.field.includes('DateTimeLocal') || i.field.includes('DateTimeUtc')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={Utils.formatDate}
						/>
					);
				}

				return (
					<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={localizeCellValue} />
				);
			});
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	const nameToLink = (item: any) => {
		return (
			<td>
				<a
					href="#/"
					className="ai-link cursor-pointer"
					onClick={(event: any) => {
						event.preventDefault();
						downloadFile(item.dataItem);
					}}>
					{item.dataItem.name}
				</a>
			</td>
		);
	};

	const pageChange = (event: any) => {
		setPagination({
			skip: event.page.skip,
			take: event.page.take
		});
	};

	const downloadFile = async (file: IppFileStore) => {
		dispatch(downloadAttachment(file.fileStoreId || 0, 'IsDownload=true&'));
	};

	const applySearch = async (searchTerm: string) => {
		let queryParameters = { ...filterState.queryParameters };
		queryParameters['search'] = searchTerm;
		setFilterState({ ...filterState, queryParameters: queryParameters });
		if (props.loadAttachments) {
			await props.loadAttachments(
				props.attachmentTypeId,
				props.reportStatus,
				props.attachmentTypes,
				queryParameters
			);
		}
	};

	const filterEditModalToggle = (isVisible: boolean) => {
		setFilterState({ ...filterState, showFilterEditorModal: isVisible });
	};

	const onClearFilter = async () => {
		let queryParameters = { ...filterState.queryParameters };
		_.unset(queryParameters, 'draftFilterId');
		setFilterState({
			...filterState,
			queryParameters: queryParameters,
			draftFilterId: undefined
		});
		filterService.updateClearFilterUrl();
		if (props.loadAttachments) {
			await props.loadAttachments(
				props.attachmentTypeId,
				props.reportStatus,
				props.attachmentTypes,
				queryParameters
			);
		}
	};

	const applyBtnClicked = async (filterSaved: Filter | undefined, draftFilterSave: DraftFilter | undefined) => {
		let queryParameters = { ...filterState.queryParameters };
		if (draftFilterSave) {
			queryParameters['draftFilterId'] = _.toString(draftFilterSave.draftFilterId);
		}
		setFilterState({
			...filterState,
			queryParameters: queryParameters,
			draftFilterId: draftFilterSave ? draftFilterSave.draftFilterId : undefined
		});

		if (props.loadAttachments) {
			await props.loadAttachments(
				props.attachmentTypeId,
				props.reportStatus,
				props.attachmentTypes,
				queryParameters
			);
		}
	};

	let columnsDefinitions = getColumnDefinitions();
	let columns = generateColumns(columnsDefinitions);

	return (
		<>
			{(props.showFilter || props.showSearch) && (
				<div className="form-row">
					{props.showFilter && (
						<div className="col-auto">
							<button
								className="btn ai-white"
								id="filterMenuBtn"
								onClick={() => {
									filterService.init(
										FilterDomain.IPPFILESTORE,
										FilterDomainName.ippFileStore,
										FilterObjectName.IppFileStore,
										LocalStorageName.IppAttachmentGridFilterUrl,
										{ IPPFILESTORE: true }
									);
									filterEditModalToggle(true);
								}}>
								{localizationService.getLocalizedString('grid.filters')}
							</button>
						</div>
					)}
					{props.showSearch && (
						<div className="col-auto">
							<SearchInput
								ariaLabel="Search"
								search={applySearch}
								placeholderDescription={localizationService.getLocalizedString(
									'ipp.reportPackage.package.fileStore.searchHint'
								)}
							/>
						</div>
					)}
				</div>
			)}

			<div className="mb-2 d-flex mt-1">
				<FilterQuickViewer
					reportPackageElementTypeId={(props.attachmentTypeId || '').toString()}
					draftFilterId={filterState.draftFilterId}
					onClosedClicked={onClearFilter}
					onFilterChanged={applyBtnClicked}
					filterDeleted={onClearFilter}
					onFilterSaved={() => {}}
				/>
			</div>
			<Grid
				className="table table-responsive"
				ref={gridRef}
				scrollable="none"
				data={files && process(files, { sort, ...pagination })}
				skip={pagination.skip}
				take={pagination.take}
				total={files && files.length}
				pageable={true}
				onPageChange={pageChange}
				sortable
				sort={sort}
				onSortChange={e => {
					setSort(e.sort);
				}}>
				{columns}
			</Grid>
			{filterState.showFilterEditorModal && (
				<FilterEditModal
					reportPackageElementTypeId={(props.attachmentTypeId || '').toString()}
					reportPackageElementType="attachment"
					isCreateNew={true}
					title={localizationService.getLocalizedString('screen.filterModal.newFilter')}
					modalToggleFunction={filterEditModalToggle}
					applyBtnClicked={applyBtnClicked}
					filterDeleted={onClearFilter}
					widgetCount={0}
					draftFilterApplyTo={FilterApplyTo.IppFileStore}
				/>
			)}
		</>
	);
};

export default IppPackageItemAttachmentFiles;
