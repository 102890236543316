import * as LinkoTypes from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IppConstants, NotificationTypes, OrganizationTypes, UserTypes } from 'src/constants';
import { Utils } from 'src/services/utils';
import { alertService, ApplicationState } from 'src/redux';
import {
	approveIppPendingRegistrationUserActionCreator,
	denyIppPendingRegistrationUserActionCreator,
	fetchIppPendingRegistrationListActionCreator,
	IppPendingRegistrationUserState,
	updateIppPendingRegistrationUserActionCreator
} from '../../../../redux/ipp/authority/pending-registration-user';
import { DateUtilService, localizationService, tokenService, urlService, validationService } from 'src/services';
import { DeleteModal, SingleCheckbox, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface DispatchProps {
	approveIppPendingRegistrationUser: (
		currentState: IppPendingRegistrationUserState,
		userId: string,
		history: History
	) => void;
	denyIppPendingRegistrationUser: (
		currentState: IppPendingRegistrationUserState,
		userId: string,
		history: History
	) => void;
	fetchPendingRegistrationUser: (currentState: IppPendingRegistrationUserState, userId: string) => void;
	updateIppPendingRegistrationUser: (updatedState: IppPendingRegistrationUserState) => void;
}

interface StoreProps {
	ippPendingRegistrationUser: IppPendingRegistrationUserState;
}

interface Props extends DispatchProps, StoreProps {
	history: History;
	match: LinkoTypes.IppMatchRouteProps;
}

interface State {
	queryParameters: LinkoTypes.Dictionary<string>;
	fieldsErrorObject: Object;
	toggleApprovalModal: boolean;
	toggleDenyModal: boolean;
}

class IppPendingRegistrationUser extends Component<Props, State> {
	private validationFields: Object;
	private isApproveModalButtonClicked = false;
	private isDenyModalButtonClicked = false;
	private roleOptions = [
		{ label: _.startCase(UserTypes.admin), value: _.startCase(UserTypes.admin) },
		{
			label: _.startCase(UserTypes.standard),
			value: _.startCase(UserTypes.standard)
		}
	];
	private authorityPendingRegistrationListPath = '/ipp/authority/pendingRegistrations';

	constructor(props: Props) {
		super(props);

		let queryParameters = urlService.toQueryDictionary();
		this.state = { queryParameters, fieldsErrorObject: {}, toggleApprovalModal: false, toggleDenyModal: false };

		const pendingRegistrationUserLocalizedLabelPrefix = 'ipp.pendingRegistrationUser';
		this.validationFields = {
			role: {
				fieldName: 'role',
				fieldLabel: localizationService.getLocalizedString(
					`${pendingRegistrationUserLocalizedLabelPrefix}.fields.role.label`
				),
				validations: ['required']
			}
		};
	}

	public componentDidMount() {
		const { params } = this.props.match;
		const userId = (params as any).userId;
		this.props.fetchPendingRegistrationUser(this.props.ippPendingRegistrationUser, userId);
	}

	public componentDidUpdate() {
		const { ippPendingRegistrationUser } = this.props;
		const ippPendingRegistrationUserData = ippPendingRegistrationUser.data;
		const { notification } = ippPendingRegistrationUser;
		if ((this.isApproveModalButtonClicked || this.isDenyModalButtonClicked) && (notification as any).msg !== '') {
			if (String.equalCaseInsensitive((notification as any).type, NotificationTypes.success)) {
				alertService.clearAllMessages();
				alertService.addSuccess((notification as any).msg);
			} else {
				alertService.clearAllMessages();
				alertService.addError((notification as any).msg);
			}

			if (this.isApproveModalButtonClicked) {
				this.isApproveModalButtonClicked = false;
			} else {
				this.isDenyModalButtonClicked = false;
			}
		} else if (
			!urlService.isIppIndustryPortal() &&
			!this.isAuthorityTypeRegistration(ippPendingRegistrationUserData.organizationType || '') &&
			!ippPendingRegistrationUserData.role
		) {
			ippPendingRegistrationUserData.role = _.startCase(UserTypes.admin);
			this.props.updateIppPendingRegistrationUser({ ...ippPendingRegistrationUser });
		}
	}

	componentWillUnmount = () => {
		const { ippPendingRegistrationUser } = this.props;
		const ippPendingRegistrationUserData = ippPendingRegistrationUser.data;
		ippPendingRegistrationUserData.role = '';
		this.props.updateIppPendingRegistrationUser({ ...ippPendingRegistrationUser });
	};

	isAuthorityTypeRegistration = (organizationType: string) => {
		return (
			organizationType && String.equalCaseInsensitive(organizationType, LinkoTypes.OrganizationTypeName.Authority)
		);
	};

	public render() {
		const pendingRegistrationUserLocalizedLabelPrefix = 'ipp.pendingRegistrationUser';
		const { ippPendingRegistrationUser } = this.props;
		const ippPendingRegistrationUserData = ippPendingRegistrationUser.data;
		const { fieldsErrorObject } = this.state;
		const isPageAccessible = () =>
			isAdmin ||
			(!urlService.isIppIndustryPortal() &&
				ippPendingRegistrationUserData.organizationType &&
				ippPendingRegistrationUserData.organizationType.toLowerCase() !==
					OrganizationTypes.authority.toLowerCase());

		const isAdmin = tokenService.isAdminToken(LinkoTypes.RegulatoryProgramName.IPP);
		let disableRoleInput = !this.isAuthorityTypeRegistration(ippPendingRegistrationUserData.organizationType || '');

		if (!isAdmin && this.isAuthorityTypeRegistration(ippPendingRegistrationUserData.organizationType || '')) {
			this.props.history.replace(this.authorityPendingRegistrationListPath);
		}

		return (
			<>
				{isPageAccessible() ? (
					<React.Fragment>
						<div className="page-header">
							<h1>
								{localizationService.getLocalizedString(
									`${pendingRegistrationUserLocalizedLabelPrefix}.pageHeading`
								)}
							</h1>
						</div>
						<div className="page-wrapper">
							<div className="main settings">
								<section className="authority px-4">
									{isPageAccessible() ? (
										<React.Fragment>
											<div className="row w-100">
												<Button
													color="btn ai-action ml-auto mr-1"
													onClick={this.approveHandler}>
													{localizationService.getLocalizedString(
														'ipp.pendingRegistrationUser.approveBtnText'
													)}
												</Button>
												<Button
													color="btn ai-action ml-0"
													onClick={() => this.setState({ toggleDenyModal: true })}>
													{localizationService.getLocalizedString(
														'ipp.pendingRegistrationUser.denyBtnText'
													)}
												</Button>
											</div>
										</React.Fragment>
									) : (
										<React.Fragment />
									)}

									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-registered-for"
											name="registered-for"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.registeredFor.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.registeredFor
											)}
											isDisabled={true}
										/>
										<SingleSelectDropdown
											id="ipp-pendreguser-role"
											name="role"
											className="form-group-width-2col"
											isRequired={true}
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.role.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.role)}
											options={[...this.roleOptions]}
											placeholder={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.role.placeholder`
											)}
											onChange={
												urlService.isIppIndustryPortal() || !disableRoleInput
													? this.updateRoleHandler
													: () => {}
											}
											showEmptyOption={false}
											error={'role' in fieldsErrorObject ? fieldsErrorObject['role'] : undefined}
											isDisabled={!urlService.isIppIndustryPortal() && disableRoleInput}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-regdate"
											name="registration-date"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.registrationDate.label`
											)}
											value={`${DateUtilService.toDisplayDate(
												ippPendingRegistrationUserData.registrationDateTimeUtc,
												IppConstants.dateFormats.monthDayYear
											)} ${DateUtilService.toDisplayTime(
												ippPendingRegistrationUserData.registrationDateTimeUtc,
												IppConstants.dateFormats.timeSecondsTwelveHourFormat
											).toUpperCase()}`}
											isDisabled={true}
										/>
										{!urlService.isIppIndustryPortal() &&
										!this.isAuthorityTypeRegistration(
											ippPendingRegistrationUserData.organizationType || ''
										) ? (
											<SingleCheckbox
												id="ipp-pendreguser-issignatory"
												name="is-signatory"
												className="form-group-width-2col d-flex align-items-center"
												label={localizationService.getLocalizedString(
													`${pendingRegistrationUserLocalizedLabelPrefix}.fields.isSignatory.label`
												)}
												checked={ippPendingRegistrationUserData.isSignatory}
												onChange={this.updateSignatoryHandler}
											/>
										) : (
											<React.Fragment />
										)}
									</div>
									<hr />
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-firstname"
											name="firstName"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.firstName.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.firstName
											)}
											isDisabled={true}
										/>
										<TextInput
											id="ipp-pendreguser-lastname"
											name="lastName"
											className="form-group-width-2col"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.lastName.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.lastName)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-username"
											name="username"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.username.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.userName)}
											isDisabled={true}
										/>
										<TextInput
											id="ipp-pendreguser-email"
											name="email"
											className="form-group-width-2col"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.email.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.email)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-orgname"
											name="orgname"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.orgName.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.organizationName
											)}
											isDisabled={true}
										/>
										<TextInput
											id="ipp-pendreguser-title"
											name="title"
											className="form-group-width-2col"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.title.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.title)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-phone"
											name="phone"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.phone.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.phoneNumber
											)}
											isDisabled={true}
										/>
										<TextInput
											id="ipp-pendreguser-ext"
											name="ext"
											className="form-group-width-2col"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.ext.label`
											)}
											value={
												ippPendingRegistrationUserData.phoneExt
													? `${ippPendingRegistrationUserData.phoneExt}`
													: ''
											}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-address1"
											name="address1"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.address1.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.addressLine1
											)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-address2"
											name="address2"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.address2.label`
											)}
											value={Utils.getValueOrEmptyString(
												ippPendingRegistrationUserData.addressLine2
											)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-city"
											name="city"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.city.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.cityName)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-state"
											name="state"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.state.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.state)}
											isDisabled={true}
										/>
									</div>
									<div className="form-row">
										<TextInput
											id="ipp-pendreguser-zipcode"
											name="zipcode"
											className="form-group-width-2col pl-5px"
											label={localizationService.getLocalizedString(
												`${pendingRegistrationUserLocalizedLabelPrefix}.fields.zipCode.label`
											)}
											value={Utils.getValueOrEmptyString(ippPendingRegistrationUserData.zipCode)}
											isDisabled={true}
										/>
									</div>
								</section>
							</div>
						</div>
						<DeleteModal
							key="confirmApprovePendingRegistrations"
							title={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.approveModal.title`
							)}
							message={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.approveModal.message`
							)}
							showModal={this.state.toggleApprovalModal}
							onCancelButtonClick={() => this.setState({ toggleApprovalModal: false })}
							onOkayButtonClick={(event: React.MouseEvent<HTMLElement>) => {
								event.preventDefault();
								this.setState({ toggleApprovalModal: false }, () => {
									this.isApproveModalButtonClicked = true;
									const {
										ippPendingRegistrationUser,
										approveIppPendingRegistrationUser,
										match
									} = this.props;
									approveIppPendingRegistrationUser(
										ippPendingRegistrationUser,
										(match.params as any).userId,
										this.props.history
									);
								});
							}}
							okayButtonText={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.approveModal.okBtnText`
							)}
							cancelButtonText={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.approveModal.cancelBtnText`
							)}
							isDeleteButton={false}
						/>
						<DeleteModal
							key="confirmDenyPendingRegistrations"
							title={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.denyModal.title`
							)}
							message={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.denyModal.message`
							)}
							showModal={this.state.toggleDenyModal}
							onCancelButtonClick={() => this.setState({ toggleDenyModal: false })}
							onOkayButtonClick={(event: React.MouseEvent<HTMLElement>) => {
								event.preventDefault();
								this.setState({ toggleDenyModal: false }, () => {
									this.isDenyModalButtonClicked = true;
									const {
										ippPendingRegistrationUser,
										denyIppPendingRegistrationUser,
										match
									} = this.props;
									denyIppPendingRegistrationUser(
										ippPendingRegistrationUser,
										(match.params as any).userId,
										this.props.history
									);
								});
							}}
							okayButtonText={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.denyModal.okBtnText`
							)}
							cancelButtonText={localizationService.getLocalizedString(
								`${pendingRegistrationUserLocalizedLabelPrefix}.denyModal.cancelBtnText`
							)}
							isDeleteButton={true}
						/>
					</React.Fragment>
				) : (
					<AccessDeniedPage />
				)}
			</>
		);
	}

	private updateRoleHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { ippPendingRegistrationUser } = this.props;
		ippPendingRegistrationUser.data.role = event.target.value;
		this.props.updateIppPendingRegistrationUser({ ...ippPendingRegistrationUser });
	};

	private updateSignatoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { ippPendingRegistrationUser } = this.props;
		ippPendingRegistrationUser.data.isSignatory = event.target.checked;
		this.props.updateIppPendingRegistrationUser({ ...ippPendingRegistrationUser });
	};

	private approveHandler = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		const { ippPendingRegistrationUser } = this.props;
		const ippAASIndustrialPretreatmentSettingsData = ippPendingRegistrationUser.data;
		let validationsFieldsAttributes = [];
		for (const validationField in this.validationFields) {
			validationsFieldsAttributes.push({
				...(this.validationFields as any)[validationField],
				value: (ippAASIndustrialPretreatmentSettingsData as any)[validationField]
			});
		}
		const validationResult = validationService.validateFields(validationsFieldsAttributes as any);
		let hasError = false;
		let fieldsErrorObject = {};
		for (const fieldName in validationResult) {
			const validation = (validationResult as any)[fieldName];
			if (Object.keys(validation).length > 0) {
				hasError = true;
				(fieldsErrorObject as any)[fieldName] = validation[Object.keys(validation)[0]];
			}
		}

		let finalState: Object = { fieldsErrorObject };
		if (hasError) {
			const pendingRegistrationUserLocalizedLabelPrefix = 'ipp.pendingRegistrationUser';
			alertService.addError(
				localizationService.getLocalizedString(`${pendingRegistrationUserLocalizedLabelPrefix}.approveError`)
			);
		} else {
			finalState = { ...finalState, toggleApprovalModal: true };
		}
		this.setState({ ...(finalState as any) });
	};
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		ippPendingRegistrationUser: state.ippPendingRegistrationUser
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		approveIppPendingRegistrationUser: (
			currentState: IppPendingRegistrationUserState,
			userId: string,
			history: History
		) => {
			dispatch(approveIppPendingRegistrationUserActionCreator(currentState, userId, history as any));
		},
		denyIppPendingRegistrationUser: (
			currentState: IppPendingRegistrationUserState,
			userId: string,
			history: History
		) => {
			dispatch(denyIppPendingRegistrationUserActionCreator(currentState, userId, history as any));
		},
		fetchPendingRegistrationUser: (currentState: IppPendingRegistrationUserState, userId: string) => {
			dispatch(fetchIppPendingRegistrationListActionCreator(currentState, userId));
		},
		updateIppPendingRegistrationUser: (updatedState: IppPendingRegistrationUserState) => {
			dispatch(updateIppPendingRegistrationUserActionCreator(updatedState));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppPendingRegistrationUser);
