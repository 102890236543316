import { DeviceCleaningNoticeSteps, CleaningNoticeTemplateSchedule, DropDownOption } from '@rcp/types';
import React from 'react';
import { SingleSelectDropdown } from 'src/components/widgets';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';

export const Step1: React.FC = () => {
	const stepperContext = React.useContext(StepperContext);
	const { STEP1, STEP2, STEP3, STEP4 } = DeviceCleaningNoticeSteps;
	const [noticeTemplates, setNoticeTemplates] = React.useState([] as DropDownOption[]);
	const [selectedTemplate, setSelectedTemplate] = React.useState('');
	const [selectedTemplateError, setSelectedTemplateError] = React.useState('');

	React.useEffect(() => {
		const step1Data = stepperContext.getData(STEP1);
		step1Data && setSelectedTemplate(step1Data.noticeTemplateId || '');
		const url = urlService.getAuthoritySettingResourceApiUrl(
			Resource.CleaningNoticeTemplateSchedules + '?includeInactive=false'
		);
		apiService.getResource<CleaningNoticeTemplateSchedule[]>(url, true).then(data => {
			const dropDownValues = data
				.filter((i: CleaningNoticeTemplateSchedule) => i.isScheduledAutomatically == false)
				.map((option: CleaningNoticeTemplateSchedule) => {
					return {
						label: option.templateName || '',
						value: option.noticeTemplateScheduleId
					};
				});
			setNoticeTemplates(dropDownValues);
		});
	}, []);

	const disableOtherSteps = () => {
		let step2State = { ...stepperContext.getStep(STEP2), completed: false, data: null };
		stepperContext.updateStep(STEP2, step2State);
		let step3State = { ...stepperContext.getStep(STEP3), completed: false, data: null };
		stepperContext.updateStep(STEP3, step3State);
		let step4State = { ...stepperContext.getStep(STEP4), completed: false, data: null };
		stepperContext.updateStep(STEP4, step4State);
	};

	const onClickNext = () => {
		let newState = { selectedTemplate, selectedTemplateError };

		validationService.validateRequiredField(
			newState,
			'selectedTemplate',
			'selectedTemplateError',
			localizationService.getLocalizedString('extractor.cleaningNotice.noticeTemplate')
		);
		setSelectedTemplateError(newState.selectedTemplateError);
		const selectedTemplateData = noticeTemplates.find(noticeTemplate => noticeTemplate.value === +selectedTemplate);
		if (!selectedTemplate) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		} else {
			alertService.clearAllMessages();
			const step1Data = stepperContext.getData(STEP1);
			const isDataChanged = step1Data
				? step1Data.selectedTemplate == newState.selectedTemplate
					? false
					: true
				: true;
			stepperContext.resolve({
				noticeTemplateId: selectedTemplate,
				templateName: selectedTemplateData ? selectedTemplateData.label : '',
				isDataChanged
			});
		}
	};

	return (
		<StepperContent
			id={STEP1}
			className="w-100 mr-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<SingleSelectDropdown
				id="selectTemplate"
				name="templateId"
				options={noticeTemplates}
				value={selectedTemplate}
				label={localizationService.getLocalizedString('extractor.cleaningNotice.noticeTemplate')}
				onChange={(e: any) => {
					setSelectedTemplate(e.target.value);
					disableOtherSteps();
				}}
				error={selectedTemplateError}
				isRequired={true}
			/>
		</StepperContent>
	);
};
