import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';
import * as pluralize from 'pluralize';
import * as ApiTypes from '@rcp/types';
import uuid from 'uuid';
import { DateUtilService } from './date-util-service';

export class UtilService {
	static coalesce(values: any[], defaultValue = ''): string {
		let value = _.find(values, (x: any) => {
			return !_.isEmpty(x);
		});
		if (!value) {
			value = defaultValue;
		}
		return value;
	}

	static endsWithNumber = (str: string) => {
		return /[0-9]+$/.test(str);
	};

	static toPlural = (str: string, toLower = false) => {
		let result: string;
		if (UtilService.endsWithNumber(str)) {
			result = str;
		} else {
			const isPlural = pluralize.isPlural(str);
			result = isPlural ? str : pluralize.plural(str);
		}
		return toLower ? _.toLower(result) : result;
	};

	static toSingular = (str: string, toLower = false) => {
		let result: string;
		if (UtilService.endsWithNumber(str)) {
			result = str;
		} else {
			const isPlural = pluralize.isPlural(str);
			result = isPlural ? pluralize.singular(str) : str;
		}
		return toLower ? _.toLower(result) : result;
	};

	static resolveNoun = (sampleNumber: number, sampleName: string, toLower = false) => {
		if (sampleNumber > 1) {
			return UtilService.toPlural(sampleName, toLower);
		}
		return UtilService.toSingular(sampleName, toLower);
	};

	public static areFieldsEquals(obj1: any, obj2: any, ...fields: string[]): boolean {
		if (fields.length <= 0) {
			throw new Error('input fields is unspecified.');
		}
		for (let i = 0; i < fields.length; i++) {
			if (_.get(obj1, fields[i]) !== _.get(obj2, fields[i])) {
				return false;
			}
		}
		return true;
	}

	static getCustomFieldValue(
		customFieldAware: ApiTypes.CustomFieldAware,
		fieldName: string,
		defaultValue = ''
	): string {
		let customFields = _.get(customFieldAware, 'customFields');
		let camelCaseFieldName = UtilService.toCamelCase(fieldName);
		var value = _.get(customFields, camelCaseFieldName);
		if (!value) {
			value = defaultValue;
		}
		return value;
	}

	static toStr(number?: Number): string {
		return number ? '' + number : '';
	}

	static isNullOrEmpty(value: string | number | null | undefined): boolean {
		if (value === null || value === undefined) {
			return true;
		}
		if (value === '') {
			return true;
		}
		return false;
	}

	static hasValue(value: string | number | null | undefined): boolean {
		return !UtilService.isNullOrEmpty(value);
	}

	static toNumber(str?: string): number | undefined | null {
		if (!_.isEmpty(str)) {
			let returnNumber = _.toNumber(str);
			if (_.isNaN(returnNumber)) {
				throw new Error(str + ' is not a valid number');
			}
			return returnNumber as number;
		} else if (_.isUndefined(str)) {
			return undefined;
		}
		return null;
	}

	static toCamelCase(str: string): string {
		return str
			.replace(/\s(.)/g, function($1) {
				return $1.toUpperCase();
			})
			.replace(/\s/g, '')
			.replace(/^(.)/, function($1) {
				return $1.toLowerCase();
			});
	}

	static toJson(object: any): string {
		let str = JSON.stringify(object);
		return str;
	}

	// Reference for following util code : https://stackoverflow.com/questions/5480570/json-stringify-change-the-case-of-the-key
	static fromJson(json: string): any {
		let obj = JSON.parse(json, function(key, value) {
			if (value && typeof value === 'object')
				for (var k in value) {
					if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
						value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
						delete value[k];
					}
				}
			return value;
		});
		return obj;
	}

	static toHtmlString(element: React.ReactElement): string {
		return ReactDOMServer.renderToString(element);
	}

	static getUniqueString() {
		return uuid.v4();
	}

	static formatValue = (value: any) => {
		return value ? value : '';
	};

	static formatDateTime = (value: any) => {
		let displayDate = DateUtilService.toDisplayDate(value);
		return displayDate;
	};

	static formatBoolean = (value: any) => {
		return ['yes', 'y', 'true', 'on', '1'].includes(_.toLower(_.toString(value))) ? 'Yes' : 'No';
	};

	static areStringsEqual(str1: string, str2: string) {
		return _.toLower(str1) === _.toLower(str2);
	}
}
