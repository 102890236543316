import React, { FC, useEffect, useState } from 'react';
import { localizationService } from 'src/services';
import { InlineChildTextInput, InlineEditProps, PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { settingLookupsUnitSlice } from './unit-lookup.slice';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, RootState } from 'src/redux';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ApiError, default as LinkoTypes, Unit } from '@rcp/types';
import { History } from 'history';
import UnitEditor, { EditorMode } from './unit-editor';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

interface Props {
	embeddedMode?: boolean;
}

export interface UnitSettingsState {
	selected: Unit;
	showDelete: boolean;
	showUpdate: boolean;
	showCreate: boolean;
}

const UnitSettingsComponent: FC<Props> = props => {
	let initialState: UnitSettingsState = {
		showDelete: false,
		showCreate: false,
		showUpdate: false,
		selected: {
			description: ''
		}
	};
	let [state, setState] = useState(initialState);

	let initNewState = { name: '', description: '', nameError: '' };
	let [newState, setNewState] = useState(initNewState);
	let [unitList, setUnitList] = useState<Unit[]>([]);

	const dispatch = useDispatch();
	let { result: units } = useSelector((state: RootState) => state.settingUnits);

	useEffect(() => {
		const listOfUnits = JSON.parse(JSON.stringify(units));
		listOfUnits.sort((field1: any, field2: any) => {
			if (field1.name.toLowerCase() < field2.name.toLowerCase()) {
				return -1;
			}
			if (field1.name.toLowerCase() > field2.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		setUnitList(listOfUnits);
	}, [units]);

	useEffect(() => {
		dispatch(settingLookupsUnitSlice.fetchPage({}));
	}, [dispatch]);

	const onDeleteClicked = (unitId: number) => {
		const unit = units.find(a => a.unitId === unitId);
		if (unit) {
			setState({ ...state, selected: unit, showDelete: true });
		}
	};

	const onIsActiveChanged = (e: any, unitId: number) => {
		let { value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		const unit = units.find(a => a.unitId === unitId);
		if (unit && unit.unitId) {
			dispatch(
				settingLookupsUnitSlice.patchOne(
					unit.unitId,
					{ isActive: value },
					true,
					localizationService.getLocalizedString('authoritySetting.unit.updateSucceed')
				)
			);
		}
	};

	const getTitleRow = () => {
		return (
			<thead>
				<tr className="lookup-row lookup-header-row font-size-14px-semibold">
					<th>{localizationService.getLocalizedString('authoritySetting.unit.active')}</th>
					<th>{localizationService.getLocalizedString('authoritySetting.unit.unit')}</th>
					<th>{localizationService.getLocalizedString('authoritySetting.unit.description')}</th>
					<th className="action-2-buttons">&nbsp;</th>
					<th className="action-2-buttons">&nbsp;</th>
				</tr>
			</thead>
		);
	};

	const onUpdateUnit = (e: any, unitId: number) => {
		const unit = units.find(a => a.unitId === unitId);
		if (unit) {
			setState({ ...state, selected: unit, showDelete: false, showUpdate: true, showCreate: false });
		}
	};

	const getRows = () => {
		return (
			<tbody>
				{unitList.map((field: Unit, index: number) => {
					return (
						<tr className="lookup-row" key={`option-key-unit-${index}`}>
							<td id={`active_${index}_unit`}>
								<SingleCheckbox
									className="ml-2"
									id={`cb_active_unit_${index}`}
									name={`cb_active_unit_${index}`}
									checked={field.isActive}
									onChange={e => {
										onIsActiveChanged(e, field.unitId as number);
									}}
								/>
							</td>
							<td id={`option_value_unit_${index}`}>{field.name}</td>
							<td id={`option_desc_unit_${index}`}>{field.description}</td>
							<td>
								<button
									onClick={e => onUpdateUnit(e, field.unitId as number)}
									id={`btnUpdateUnit_${index}`}>
									<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon " />
								</button>
							</td>

							<td>
								{!field.isInUse && (
									<button
										onClick={() => onDeleteClicked(field.unitId as number)}
										id={`btnDeleteUnit_${index}`}>
										<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
									</button>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};

	const onCancelDelete = () => {
		setState({ ...state, showDelete: false });
	};

	const onDeleteConfirmed = () => {
		if (state.selected.unitId) {
			dispatch(
				settingLookupsUnitSlice.deleteOne(
					state.selected.unitId,
					true,
					localizationService.getLocalizedString('authoritySetting.unit.deleteSucceed'),
					() => {
						setState({ ...state, showDelete: false });
					}
				)
			);
		}
	};

	const onNameChanged = (evt: any) => {
		setNewState({ ...newState, name: evt.target.value, nameError: '' });
	};

	const onDescriptionChanged = (evt: any) => {
		setNewState({ ...newState, description: evt.target.value });
	};

	const getInlineEditProps = () => {
		let editProps = [
			{
				id: `code`,
				name: `tiUnitCode`,
				className: 'mr-sm-1 mb-2',
				label: localizationService.getLocalizedString('authoritySetting.unit.unit'),
				value: newState.name,
				onChange: onNameChanged,
				error: newState.nameError
			} as InlineEditProps
		];
		editProps.push({
			id: `tiUnitDesc`,
			name: `tiUnitDesc`,
			className: 'ml-sm-1 mr-sm-1',
			label: localizationService.getLocalizedString('authoritySetting.description'),
			value: newState.description,
			onChange: onDescriptionChanged
		});

		return editProps;
	};

	const getAddSection = () => {
		return (
			<>
				<InlineChildTextInput rowId={'unitRow'} inlineEditProps={getInlineEditProps()}>
					<button className="btn ai-new mt-1 " id="btnAddUnit" onClick={onAddUnit}>
						{localizationService.getLocalizedString('authoritySetting.addBtnText')}
					</button>
				</InlineChildTextInput>
			</>
		);
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.unit.deleteUnitTitle')}
				showModal={state.showDelete}
				cancel={() => onCancelDelete()}
				saveButtonClassName="ai-delete ml-auto"
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				save={() => onDeleteConfirmed()}>
				<span
					dangerouslySetInnerHTML={{
						__html: localizationService.getLocalizedString(
							'authoritySetting.unit.deleteUnit',
							state.selected.name as string
						)
					}}
				/>
			</PopoverModal>
		);
	};

	const onError = (reason: any) => {
		if (reason instanceof ApiError) {
			let error = localizationService.getLocalizedString('screen.validationMessage.formValidation');
			if (
				reason.body &&
				reason.body.internalMessage &&
				reason.body.internalMessage.toLowerCase().indexOf('violation of unique key') >= 0
			) {
				error = localizationService.getLocalizedString('authoritySetting.unit.duplicateName', newState.name);

				setNewState({
					...newState,
					nameError: error
				});
			}

			alertService.addError(error);
		} else {
			alertService.addError(reason.toString());
		}
	};

	const onCreateSuccess = () => {
		alertService.addSuccess(localizationService.getLocalizedString('authoritySetting.unit.createSucceed'));
		setNewState({ ...newState, name: '', description: '' });
	};

	const onAddUnit = (e: any) => {
		if (!newState.name) {
			const errorText = localizationService.getLocalizedString('authoritySetting.unit.nameRequired');
			alertService.addError(errorText);
			setNewState({ ...newState, nameError: errorText });
			return;
		}

		if (units) {
			const existingUnitName = units.filter((unit: Unit) =>
				String.equalCaseInsensitive(unit.name, newState.name)
			);
			if (existingUnitName.length > 0) {
				const error = localizationService.getLocalizedString(
					'authoritySetting.unit.duplicateName',
					newState.name
				);

				setNewState({
					...newState,
					nameError: error
				});
				alertService.addError(error);
				return;
			}
		}

		let request: Unit = {
			name: newState.name,
			description: newState.description
		};

		dispatch(settingLookupsUnitSlice.createOne(request, true, undefined, onCreateSuccess, onError));
	};

	const onEditorClose = () => {
		setState({ ...state, showUpdate: false, showCreate: false });
	};

	const unitRows = () => {
		return (
			<div>
				<div className="form-row d-sm-flex mb-2">{getAddSection()}</div>
				<div className="table-responsive">
					<table className="table">
						{getTitleRow()}
						{getRows()}
					</table>
				</div>
			</div>
		);
	};

	const unitSection = () => {
		if (props.embeddedMode) {
			return unitRows();
		}

		return (
			<div className="page">
				<div className="page-header">
					<BackArrow />
					<h1>{localizationService.getLocalizedString('authoritySetting.units')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main settings">
						<div className="row">
							<div className="col-lg-4 settings-info">
								<strong>{localizationService.getLocalizedString('authoritySetting.unit.units')}</strong>
								<p>{localizationService.getLocalizedString('authoritySetting.unit.desc')}</p>
							</div>
							<div className="col-lg-8">{unitRows()}</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	return (
		<>
			{unitSection()}
			{state.showDelete && getDeleteModal()}
			{state.showUpdate && (
				<UnitEditor
					mode={EditorMode.Update}
					show={state.showUpdate}
					unit={state.selected}
					onClosed={onEditorClose}
					units={units}
				/>
			)}
		</>
	);
};

export default UnitSettingsComponent;
