import {
	CccHazard,
	CccSite,
	CccSiteContact,
	Contact,
	ContactEmail,
	ContactNumber,
	EntityContact_EntityType,
	FacilityEntityContact,
	FogFacility,
	NumberType
} from '@rcp/types';
import _ from 'lodash';
import { UtilService } from 'src/services';

export const SiteContactType = 'Site';
export const FacilityContactType = 'Facility';

export interface Email {
	email?: string;
	label?: string;
}

export interface PhoneNumber {
	numberType?: string;
	number?: string;
	extension?: string;
	label?: string;
}
export interface Phones {
	business?: PhoneNumber;
	cell?: PhoneNumber;
	fax?: PhoneNumber;
	pager?: PhoneNumber;
	emergency?: PhoneNumber;
	others?: PhoneNumber[];
}
export const extractJsonEmails = (jsonEmails?: string): Email[] | undefined => {
	if (_.isEmpty(jsonEmails)) {
		return;
	}
	let emails: Email[] = [];
	UtilService.fromJson(jsonEmails as string).forEach((contactEmail: ContactEmail): void => {
		let email: Email = {
			email: contactEmail.email,
			label: contactEmail.label
		};
		emails.push(email);
	});
	return emails;
};

export const extractJsonNumbers = (jsonNumbers?: string): Phones | undefined => {
	if (_.isEmpty(jsonNumbers)) {
		return;
	}
	let phones: Phones = {};
	UtilService.fromJson(jsonNumbers as string).forEach((contactNumber: ContactNumber): void => {
		let phoneNumber: PhoneNumber = {
			numberType: contactNumber.numberType,
			number: contactNumber.number,
			extension: contactNumber.extension,
			label: contactNumber.label
		};
		switch (phoneNumber.numberType) {
			case NumberType.BusinessPhone:
				phones.business = phoneNumber;
				break;
			case NumberType.CellPhone:
				phones.cell = phoneNumber;
				break;
			case NumberType.Fax:
				phones.fax = phoneNumber;
				break;
			case NumberType.Pager:
				phones.pager = phoneNumber;
				break;
			case NumberType.EmergencyPhone:
				phones.emergency = phoneNumber;
				break;
			default:
				if (phones.others === undefined) {
					phones.others = [];
				}
				phones.others.push(phoneNumber);
				break;
		}
	});
	return phones;
};

export const extractSiteContact = (site: CccSite, hazard?: CccHazard) => {
	let linkedContact: CccSiteContact = {
		contactType: SiteContactType,
		siteId: site.siteId,
		siteNumber: site.siteNumber,
		linkedContactId: 0,
		contactId: 0,
		firstName: site.contactFirstName,
		lastName: site.contactLastName,
		companyName: site.company,
		addressId: site.addressId,
		addressLine1: site.addressLine1,
		addressLine2: site.addressLine2,
		addressCity: site.cityName,
		addressJurisdictionId: site.jurisdictionId,
		addressJurisdictionCode: site.state,
		addressZipCode: site.zipCode
	};
	if (hazard) {
		linkedContact.hazardId = hazard.hazardId;
		linkedContact.hazardNumber = hazard.hazardNumber;
		linkedContact.sendNotices = hazard.sendTestNoticesToSite;
	}
	if (site.phone) {
		linkedContact.jsonNumbers = JSON.stringify([
			{ numberType: 'BusinessPhone', number: site.phone, label: 'Phone' }
		]);
	}
	if (site.email) {
		linkedContact.jsonEmails = JSON.stringify([{ email: site.email, label: 'Email' }]);
	}
	return linkedContact;
};

export const extractFacilityContact = (facility: FogFacility) => {
	let linkedContact: FacilityEntityContact = {
		contactType: FacilityContactType,
		contactId: 0,
		firstName: facility.contactName,
		entityId: facility.facilityId,
		entityType: EntityContact_EntityType.FogFacility,
		facilityId: facility.facilityId,
		facilityNumber: facility.referenceNumber,
		addressLine1: facility.addressLine1,
		addressLine2: facility.addressLine2,
		addressCity: facility.cityName,
		addressJurisdictionId: facility.jurisdictionId,
		addressJurisdictionCode: facility.jurisdictionCode,
		addressZipCode: facility.zipCode
	};
	if (facility.phone) {
		linkedContact.jsonNumbers = JSON.stringify([
			{ numberType: 'BusinessPhone', number: facility.phone, label: 'Phone' }
		]);
	}
	if (facility.email) {
		linkedContact.jsonEmails = JSON.stringify([{ email: facility.email, label: 'Email' }]);
	}
	return linkedContact;
};

export const getContactDisplayName = (contact: Contact): string => {
	let userFullName = ((contact.firstName || '') + ' ' + (contact.lastName || '')).trim();
	let componyName = contact.companyName || '';
	return (userFullName + ' ' + componyName).trim();
};

export const getContactSearchableText = (contact: Contact): string => {
	let contactSearchableText = getContactDisplayName(contact);
	let emails = extractJsonEmails(contact.jsonEmails);
	if (emails && emails.length > 0) {
		contactSearchableText += ' ' + emails[0].email;
	}
	return contactSearchableText;
};
