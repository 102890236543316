import React from 'react';

interface Props {
	posClass?: string;
	title?: string;
	size?: number;
}

export const NoticesSvg: React.SFC<Props> = props => {
	return (
		<svg
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ? props.size : '50'}
			className={props.posClass}
			height={props.size ? props.size : '50'}
			viewBox="0 0 50 50">
			<rect width="50" height="50" style={{ fill: '#f0f0f0' }} />
			<g style={{ isolation: 'isolate' }}>
				<g style={{ isolation: 'isolate' }}>
					<path
						d="M15.28,25c-3.11-2.26-3.35-2.46-4.06-3a.54.54,0,0,1-.22-.44v-.45A1.12,1.12,0,0,1,12.12,20h9.76A1.12,1.12,0,0,1,23,21.12v.45a.54.54,0,0,1-.22.44c-.71.57-1,.76-4.06,3-.39.29-1.18,1-1.72,1S15.67,25.32,15.28,25Zm7.72-2v4.8A1.12,1.12,0,0,1,21.88,29H12.12A1.12,1.12,0,0,1,11,27.88v-4.8a.14.14,0,0,1,.23-.11c.52.41,1.22.93,3.61,2.67A4.14,4.14,0,0,0,17,26.75a4.17,4.17,0,0,0,2.16-1.11c2.39-1.74,3.09-2.26,3.61-2.67A.15.15,0,0,1,23,23.08Z"
						style={{ fill: '#666' }}
					/>
				</g>
			</g>
			<g style={{ isolation: 'isolate' }}>
				<g style={{ isolation: 'isolate' }}>
					<path
						d="M39,24.12c0,2.7-2.69,4.88-6,4.88a7.14,7.14,0,0,1-2.52-.46,5.33,5.33,0,0,1-3.29,1.21.18.18,0,0,1-.17-.11.2.2,0,0,1,0-.21,6,6,0,0,0,1.29-2.24A4.32,4.32,0,0,1,27,24.12c0-2.69,2.69-4.87,6-4.87S39,21.43,39,24.12Z"
						style={{ fill: '#666' }}
					/>
				</g>
			</g>
		</svg>
	);
};
