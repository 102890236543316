import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { MergeFieldDto } from '@rcp/types';
import { DropDownOption } from '@rcp/types/src';
import React from 'react';
import { Button } from 'reactstrap';
import { useRootStateSelector } from 'src/redux';
import { localizationService } from 'src/services';
import { ComboBoxDropdown } from './combo-box-dropdown';

interface Props {
	hasLanguageSupport?: boolean;
	disabled?: boolean;
	onClick?: (e: any) => void;
}

export const DoNotTranslateButton: React.FC<Props> = props => {
	return props.hasLanguageSupport ? (
		<Button
			id="do-not-translate-button"
			title={localizationService.getLocalizedString('languages.doNotTranslate')}
			disabled={props.disabled}
			className="btn ai-white"
			onClick={props.onClick}>
			{localizationService.getLocalizedString('languages.doNotTranslate')}
		</Button>
	) : (
		<></>
	);
};
