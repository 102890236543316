import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	DateInput,
	PopoverModal,
	SearchableComboBox,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput
} from 'src/components/widgets';
import { CccDevice, CccHazard, DropDownOption, LookupType } from '@rcp/types';
import { DateUtilService, localizationService, optionsMap, UtilService, validationService } from 'src/services';
import { cccHazardSlice } from '../hazards.slice';
import { loadFrequencyList, RootState } from 'src/redux';
import _ from 'lodash';
import {
	cccLookupHazardDeviceOrientationSlice,
	cccLookupHazardDeviceStatusSlice
} from '../../settings/hazard-lookups.slice';

interface OwnProps {
	toggle: () => void;
	hazard: CccHazard;
	isAdd: boolean;
}

type Props = OwnProps;

interface FormState {
	deviceId?: number | null;

	deviceBypass?: boolean;
	fireline?: boolean;

	deviceSerialNumber?: string;
	deviceStatusId?: number;
	deviceStatus?: string;

	deviceOrientationId?: number;
	deviceOrientation?: string;
	deviceLocation?: string;

	installDue?: string;
	installedDate?: string;
	testFrequencyId?: number;

	installDateError?: string;
	installDueError?: string;
}

const initialFormState: FormState = {};

export const CccHazardDeviceModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialFormState);
	const [searchDevices, setSearchDevices] = React.useState<CccDevice[]>([]);
	const [selectedDevice, setSelectedDevice] = React.useState<CccDevice | undefined>(undefined);
	const [deviceStatuses, setDeviceStatuses] = React.useState<DropDownOption[]>([]);
	const [deviceOrientations, setDeviceOrientations] = React.useState<DropDownOption[]>([]);
	const [testFrequencies, setTestFrequencies] = React.useState<DropDownOption[]>([]);

	let cccDevices = useSelector((state: RootState) => state.cccDevices).result;
	let cccDeviceStatuses = useSelector((state: RootState) => state.settingLookupHazardDeviceStatus).result;
	let cccDeviceOrientations = useSelector((state: RootState) => state.settingLookupHazardDeviceOrientation).result;
	let cccTestFrequencies = useSelector((state: RootState) => state.extractors.frequencyList);

	const setFormStateFromProps = (hazard: CccHazard) => {
		let newState = {
			...formState,
			deviceId: hazard.deviceId,

			deviceLeadFree: hazard.deviceLeadFree,
			deviceBypass: hazard.deviceBypass,
			fireline: hazard.fireline,

			deviceSerialNumber: hazard.deviceSerialNumber,
			deviceStatusId: hazard.deviceStatusId,
			deviceStatus: hazard.deviceStatus,

			deviceOrientationId: hazard.deviceOrientationId,
			deviceOrientation: hazard.deviceOrientation,
			deviceLocation: hazard.deviceLocation,

			installDue: hazard.installDue,
			installedDate: hazard.installedDate,
			testFrequencyId: hazard.testFrequencyId
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.hazard);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.hazard]);
	React.useEffect(() => {
		if (!_.isEmpty(cccDeviceStatuses)) {
			let options = optionsMap.fromLookups(
				cccDeviceStatuses,
				props.hazard.deviceStatusId,
				props.hazard.deviceStatus
			);
			setDeviceStatuses(options);
		}
	}, [cccDeviceStatuses, props.hazard]);
	React.useEffect(() => {
		if (!_.isEmpty(cccDeviceOrientations)) {
			let options = optionsMap.fromLookups(
				cccDeviceOrientations,
				props.hazard.deviceOrientationId,
				props.hazard.deviceOrientation
			);
			setDeviceOrientations(options);
		}
	}, [cccDeviceOrientations, props.hazard]);
	React.useEffect(() => {
		if (!_.isEmpty(cccTestFrequencies)) {
			setTestFrequencies(optionsMap.fromFrequencyList(cccTestFrequencies));
		}
	}, [cccTestFrequencies]);

	React.useEffect(() => {
		dispatch(cccLookupHazardDeviceStatusSlice.fetchAll(`lookupType=${LookupType.CccHazardDeviceStatus}`));
		dispatch(cccLookupHazardDeviceOrientationSlice.fetchAll(`lookupType=${LookupType.CccHazardDeviceOrientation}`));
		dispatch(loadFrequencyList());
	}, [dispatch]);

	const composeDeviceFullName = (device: CccDevice): string => {
		let combinedText =
			device.manufacturer + ' ' + device.model + ' ' + device.deviceTypeCode + ' ' + device.deviceSize;
		combinedText = combinedText.trim();
		return combinedText;
	};

	React.useEffect(() => {
		if (cccDevices) {
			let mappedDevices = cccDevices
				.map(c => {
					return {
						...c,
						searchableText: composeDeviceFullName(c)
					};
				})
				.filter(c => c.searchableText.length > 0);
			mappedDevices = _.sortBy(_.uniqBy(mappedDevices, 'searchableText'), 'searchableText');
			setSearchDevices(mappedDevices);
		}
	}, [cccDevices]);

	React.useEffect(() => {
		if (!selectedDevice && searchDevices && formState.deviceId) {
			let device = searchDevices.find(c => c.deviceId === formState.deviceId);
			if (device) {
				setSelectedDevice(device);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.deviceId, searchDevices]);

	const applySearch = (selectedValue: CccDevice) => {
		if (selectedValue && selectedValue.deviceId) {
			setSelectedDevice(selectedValue);
			let newState = { ...formState };
			newState.deviceId = selectedValue.deviceId;
			if (
				UtilService.isNullOrEmpty(newState.testFrequencyId) &&
				UtilService.hasValue(selectedValue.testFrequencyId)
			) {
				newState.testFrequencyId = selectedValue.testFrequencyId;
			}
			setFormState(newState);
		} else {
			setSelectedDevice(undefined);
			let newState = { ...formState };
			newState.deviceId = null;
			setFormState(newState);
		}
	};

	const changeFormState = (e: any) => {
		const { name, value } = e.target;
		let newState = { ...formState };
		if (e.target.type === 'checkbox') {
			_.set(newState, name, e.target.checked);
		} else {
			_.set(newState, name, value);
		}
		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		if (newState.installedDate) {
			if (DateUtilService.isAfterToday(newState.installedDate)) {
				newState.installDateError = localizationService.getLocalizedString(
					'cccDevice.installDateValidationMessage'
				);
			} else {
				_.unset(newState, ['installDateError']);
			}
			validationService.validateMinimumDate(
				newState,
				'installedDate',
				'installDateError',
				localizationService.getLocalizedString('cccHazard.installedDate')
			);
		} else {
			_.unset(newState, ['installDateError']);
		}
		validationService.validateMinimumDate(
			newState,
			'installDue',
			'installDueError',
			localizationService.getLocalizedString('cccHazard.installDue')
		);
		setFormState(newState);
		return !validationService.hasError(newState, 'installDateError', 'installDueError');
	};

	const saveHazardDevice = async (e: any) => {
		if (!isFormValidateForSave()) {
			return;
		}
		let hazardToPatch: CccHazard = {
			deviceId: formState.deviceId,
			deviceBypass: formState.deviceBypass,
			fireline: formState.fireline,
			deviceSerialNumber: formState.deviceSerialNumber,
			deviceStatusId: formState.deviceStatusId,
			deviceOrientationId: formState.deviceOrientationId,
			deviceLocation: formState.deviceLocation,
			installDue: formState.installDue,
			installedDate: formState.installedDate,
			testFrequencyId: formState.testFrequencyId
		};
		dispatch(
			cccHazardSlice.patchOne(
				props.hazard.hazardId as number,
				hazardToPatch,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccHazard.hazard')
				),
				() => props.toggle()
			)
		);
	};

	const cancelSave = () => {
		setFormStateFromProps(props.hazard);
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={localizationService.getLocalizedString(
					props.isAdd ? 'cccHazard.addDevice' : 'cccHazard.editDevice'
				)}
				save={saveHazardDevice}
				cancel={cancelSave}>
				<SearchableComboBox
					id="deviceFullName"
					name="deviceFullName"
					label={localizationService.getLocalizedString('cccDevice.device')}
					textField="searchableText"
					value={selectedDevice}
					isRequired={false}
					dataSource={searchDevices}
					onSelect={applySearch}
					placeholder={localizationService.getLocalizedString('screen.labels.search')}
					description={localizationService.getLocalizedString('cccDevice.searchDeviceDescription')}
				/>
				<TextInput
					id="deviceSerialNumber"
					name="deviceSerialNumber"
					label={localizationService.getLocalizedString('cccHazard.deviceSerialNumber')}
					value={formState.deviceSerialNumber}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="deviceStatusId"
					name="deviceStatusId"
					label={localizationService.getLocalizedString('cccHazard.deviceStatus')}
					value={_.toString(formState.deviceStatusId)}
					onChange={changeFormState}
					options={deviceStatuses}
				/>
				<SingleSelectDropdown
					id="deviceOrientationId"
					name="deviceOrientationId"
					label={localizationService.getLocalizedString('cccHazard.deviceOrientation')}
					value={_.toString(formState.deviceOrientationId)}
					onChange={changeFormState}
					options={deviceOrientations}
				/>
				<TextAreaInput
					id="deviceLocation"
					name="deviceLocation"
					label={localizationService.getLocalizedString('cccHazard.deviceLocation')}
					value={formState.deviceLocation}
					isFullWidth={true}
					rows={3}
					onChange={changeFormState}
				/>
				<SingleCheckbox
					id="deviceBypass"
					name="deviceBypass"
					label={localizationService.getLocalizedString('cccHazard.deviceBypass')}
					checked={formState.deviceBypass}
					onChange={changeFormState}
					className="div-checkbox"
				/>
				<SingleCheckbox
					id="fireline"
					name="fireline"
					label={localizationService.getLocalizedString('cccHazard.fireline')}
					checked={formState.fireline}
					onChange={changeFormState}
					className="div-checkbox"
				/>

				<SingleSelectDropdown
					id="testFrequencyId"
					name="testFrequencyId"
					label={localizationService.getLocalizedString('cccHazard.testFrequency')}
					value={_.toString(formState.testFrequencyId)}
					onChange={changeFormState}
					options={testFrequencies}
					selfOrder={true}
				/>
				<DateInput
					id="installDue"
					name="installDue"
					label={localizationService.getLocalizedString('cccHazard.installDue')}
					value={formState.installDue}
					onChange={changeFormState}
					error={formState.installDueError}
				/>
				<DateInput
					id="installedDate"
					name="installedDate"
					label={localizationService.getLocalizedString('cccHazard.installedDate')}
					value={formState.installedDate}
					onChange={changeFormState}
					error={formState.installDateError}
					max={DateUtilService.getAuthorityTimezoneNow()}
				/>
			</PopoverModal>
		</div>
	);
};
