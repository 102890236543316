import { LookupRow } from '../lookup-types';
import { ImportField, ImportUniqueKey, LookupType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class ImportUniqueKeyDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	fields: ImportField[];
	constructor(lookupType: LookupType, fields: ImportField[]) {
		this.lookupType = lookupType;
		this.fields = fields;
	}

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		return apiData as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		return rowToUpdate;
	}

	getLabel(name: string) {
		const field = this.fields.find(f => f.fieldName === name);
		if (field) {
			return field.fieldLabel;
		}

		return '';
	}

	getLabels(names: string) {
		if (names) {
			const nameList = names.split(',');
			return nameList
				.map(n => {
					return this.getLabel(n);
				})
				.join(', ');
		}

		return '';
	}

	toLookupRowsFromAPI(apiData: any): LookupRow[] {
		const importUniqueKeys = apiData.result as ImportUniqueKey[];
		return importUniqueKeys.map(importUniqueKey => {
			const fieldNames = this.getLabels(importUniqueKey.fieldNames);
			return {
				lookupId: importUniqueKey.importUniqueKeyId,
				isActive: true,
				code: importUniqueKey.uniqueKeyName,
				isInUse: false,
				usedCount: 0,
				codeDescription: fieldNames,
				error: ''
			};
		});
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): LookupRow {
		newValue.lookupType = this.lookupType;
		return newValue;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
