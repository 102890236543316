import { LookupRow } from '../lookup-types';
import { LookupType, CleaningNoticeTemplateSchedule } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class CleaningNoticeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: CleaningNoticeTemplateSchedule): LookupRow => {
		return {
			lookupId: apiData.noticeTemplateScheduleId,
			code: apiData.templateName,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse,
			apiData: apiData
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let correctiveAction = {
			noticeTemplateScheduleId: rowToUpdate.lookupId,
			templateName: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as CleaningNoticeTemplateSchedule;
		return correctiveAction;
	}

	toLookupRowsFromAPI(apiData: CleaningNoticeTemplateSchedule[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): CleaningNoticeTemplateSchedule {
		let correctiveAction = {
			templateName: newValue.code,
			isActive: newValue.isActive
		} as CleaningNoticeTemplateSchedule;
		return correctiveAction;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
