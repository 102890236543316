import { ImportSampleSteps } from '@rcp/types';
import { IppDataSource } from '@rcp/types/src';
import { History } from 'history';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ippDataProviderSlice } from 'src/components/service-provider/industry/data-providers/data-providers-slice';
import { SingleSelectDropdown } from 'src/components/widgets';
import { AlertMessageType, alertService, RootState } from 'src/redux';
import { localizationService, navigateTo, urlService } from 'src/services';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

interface Props {
	vertical?: boolean;
	history: History;
}

const Step1: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	const stepperContext = React.useContext(StepperContext);
	const [selectedDataSourceId, setSelectedDataSourceId] = useState(0);
	const [selectedDataSourceLabel, setSelectedDataSourceLabel] = useState('');
	const dataProvidersState = (state: RootState) => state.ippDataProviders;
	const { result, loading } = useSelector(dataProvidersState);

	useEffect(() => {
		const step1Data: any = stepperContext.getData(ImportSampleSteps.STEP1);
		if (step1Data) {
			setSelectedDataSourceId(step1Data.dataSourceId);
			let selectedDataSource = _.find(result, data => {
				return data.dataSourceId === step1Data.dataSourceId;
			});
			setSelectedDataSourceLabel((selectedDataSource && selectedDataSource.name) || '');
		}
		dispatch(ippDataProviderSlice.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const dataSource: IppDataSource = props.history.location.state as IppDataSource;
		if (result.length === 1) {
			setSelectedDataSourceId(Number(result[0].dataSourceId));
			setSelectedDataSourceLabel(result[0].name || '');
		} else if (result.length > 1 && dataSource && dataSource.dataSourceId) {
			setSelectedDataSourceId(Number(dataSource.dataSourceId));
			setSelectedDataSourceLabel(dataSource.name || '');
		} else {
			setSelectedDataSourceId(0);
			setSelectedDataSourceLabel('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result]);

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();
		if (!Boolean(Number(selectedDataSourceId))) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.importSamples.dataProviderRequired')
			);
		} else {
			stepperContext.resolve({ dataSourceId: selectedDataSourceId });
		}
	};

	const createDataProvider = () => {
		let url = urlService.getIppIndustryUrl(`industryAccount/dataProviders/new`);
		navigateTo(props.history, url);
	};

	const getDataProviders = (): any[] => {
		let options = result.map(dataProvider => ({
			label: dataProvider.name,
			value: dataProvider.dataSourceId
		}));
		options.unshift({ label: 'Select Data Provider', value: 0 });
		return options;
	};

	const onChangeDataProvider = (event: any) => {
		setSelectedDataSourceId(event.target.value);
		let step1State = { ...stepperContext.getStep(ImportSampleSteps.STEP1), loading: false };
		stepperContext.updateStep(ImportSampleSteps.STEP1, step1State);
	};

	const isDataProviderListEmpty = !Boolean(result.length);

	return (
		<StepperContent
			id="selectDataProvider"
			actions={
				<React.Fragment>
					{!isDataProviderListEmpty && (
						<StepperAction
							type="button"
							id="btnNext"
							className="btn ai-action ml-auto"
							onClick={onClickNext}>
							{localizationService.getLocalizedString('screen.buttons.next')}
						</StepperAction>
					)}
				</React.Fragment>
			}>
			<div className="form-row">
				<div className={`col-md-${!isDataProviderListEmpty ? '8' : '12'}`}>
					{!isDataProviderListEmpty ? (
						<SingleSelectDropdown
							id="data-provider"
							name="dataProvider"
							label={localizationService.getLocalizedString('ipp.samples.importSamples.dataProvider')}
							noEmptyOption={true}
							options={getDataProviders()}
							value={selectedDataSourceLabel}
							isRequired={true}
							selfOrder={true}
							onChange={event => onChangeDataProvider(event)}
						/>
					) : (
						!loading && (
							<InlineAlertItem
								message={localizationService.getLocalizedString(
									'ipp.samples.importSamples.dataProviderEmptyInformation'
								)}
								alertType={AlertMessageType.Info}
							/>
						)
					)}
				</div>
				<div className={`col-md-${!isDataProviderListEmpty ? '4' : '12'} justify-content-end`}>
					<div className="form-row">
						<button
							id="create-new-data-provier"
							className="btn ai-action ml-auto mt-3"
							onClick={() => createDataProvider()}>
							{localizationService.getLocalizedString('ipp.samples.importSamples.createDataProvider')}
						</button>
					</div>
				</div>
			</div>
		</StepperContent>
	);
};

export default Step1;
