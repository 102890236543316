import { LocalStorageName, TranslationLanguage } from '@rcp/types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import {
	apiService,
	authoritySettingService,
	localStorageService,
	Resource,
	tokenService,
	urlService
} from 'src/services';
import { translateService } from 'src/services/translate-service';

const defaultLanguage = { code: 'en', name: 'English', nativeName: 'English' } as TranslationLanguage;
export const LanguageContext = createContext({
	language: defaultLanguage,
	setLanguage: (updatedLanguage: TranslationLanguage) => {}
});

const defaultHandler: TranslationHandler = () => {};

type TranslationHandler = {
	(value: string, setTranslation: (translation: string) => void): void;
};

export const TranslateContext: React.Context<TranslationHandler> = createContext(defaultHandler);

type Props = {
	children: string | JSX.Element | JSX.Element[];
};

export default function Translator({ children }: Props): JSX.Element {
	let token = tokenService.getTokenOrDefault();
	let tokenLanguage = token.language;
	if (tokenLanguage) {
		localStorageService.setLocalStorage(LocalStorageName.DisplayLanguage, tokenLanguage);
		let updatedToken = { ...token, language: undefined };
		tokenService.setToken(updatedToken);
	}

	let userLanguageSettings = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage);

	useEffect(() => {
		updateUserLanguageSettings();
	}, []);

	const updateUserLanguageSettings = async () => {
		let isLanguageSupportVisible = await authoritySettingService.isLanguageSelectorAvailable();
		if (!isLanguageSupportVisible || (isLanguageSupportVisible && !userLanguageSettings)) {
			userLanguageSettings = defaultLanguage;
			setLanguage(userLanguageSettings);
			if (!localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage)) {
				localStorageService.setLocalStorage(LocalStorageName.DisplayLanguage, defaultLanguage);
			}
		} else {
			setLanguage(userLanguageSettings);
			localStorageService.setLocalStorage(LocalStorageName.DisplayLanguage, userLanguageSettings);
		}
	};

	const [language, setLanguage] = useState(userLanguageSettings);
	const languageValue = { language, setLanguage };
	const handleTranslationAsync: TranslationHandler = useCallback(
		async (value, setTranslation) => {
			const translation = await translateService.translateString(
				value,
				false,
				language && language.code ? language.code : 'en'
			);

			if (translation) {
				setTranslation(translation);
			}
		},
		[language]
	);

	return (
		<TranslateContext.Provider value={handleTranslationAsync}>
			<LanguageContext.Provider value={languageValue}>{children}</LanguageContext.Provider>
		</TranslateContext.Provider>
	);
}
