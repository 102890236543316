import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	AuthorityTagsState,
	saveFogFacility,
	loadAuthorityTags,
	ExtractorState,
	loadFrequencyList,
	useReduxDispatch
} from 'src/redux';
import { FogFacility, TagItem, DropDownOption, Frequency } from '@rcp/types';
import { PopoverModal, TextInput, SingleSelectDropdown, SingleCheckbox } from 'src/components/widgets';
import { urlService, localizationService, DateUtilService } from 'src/services';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
}

interface StateProps extends FogFacilityState, ExtractorState {
	authorityTags: AuthorityTagsState;
}

interface DispatchProps {
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) => void;
	loadAuthorityTags: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface OverviewForm {
	tagClassification1: string;
	isEnabled: boolean;
	tagClassification2: string;

	inspectionFrequencyId?: number;
}

const initialOverviewForm: OverviewForm = {
	tagClassification1: '',
	isEnabled: false,
	tagClassification2: ''
};

const OverviewModalComp: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [formState, setFormState] = React.useState(initialOverviewForm);
	const [tagClassification1OptionValues, setTagClassification1OptionValues] = React.useState([] as DropDownOption[]);
	const [tagClassification2OptionValues, setTagClassification2OptionValues] = React.useState([] as DropDownOption[]);
	const [frequencyOptionValues, setFrequencyOptionValues] = React.useState([] as DropDownOption[]);

	const setFormStateFromProps = (facility: FogFacility) => {
		let newState = {
			tagClassification1: facility.tagClassification1 || '',
			isEnabled: facility.isEnabled || false,
			tagClassification2: facility.tagClassification2 || '',
			inspectionFrequencyId: facility.inspectionFrequencyId
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.facility);
	}, [props.facility]);

	React.useEffect(() => {
		let optionValues1: DropDownOption[] = [];
		let optionValues2: DropDownOption[] = [];
		if (!_.isEmpty(props.authorityTags.tags)) {
			let allFogTags = _.filter(props.authorityTags.tags, tag =>
				String.equalCaseInsensitive(tag.regulatoryProgramName, 'FOG')
			);
			if (allFogTags && allFogTags.length > 0) {
				let fogTags = allFogTags[0];
				let classification1Item = fogTags.tags.FacilityClassification1 as TagItem[];
				if (classification1Item) {
					optionValues1 = _.filter(classification1Item, i => i.isActive === true).map(a => {
						return { label: a.name, value: a.name };
					});
				}

				let classification2Item = fogTags.tags.FacilityClassification2 as TagItem[];
				if (classification2Item) {
					optionValues2 = _.filter(classification2Item, i => i.isActive === true).map(a => {
						return { label: a.name, value: a.name };
					});
				}
			}
		}
		setTagClassification1OptionValues(optionValues1);
		setTagClassification2OptionValues(optionValues2);
	}, [props.authorityTags]);

	React.useEffect(() => {
		dispatch(loadFrequencyList());
	}, [dispatch]);

	React.useEffect(() => {
		let frequencyOptionValues: DropDownOption[] = [];

		if (props.frequencyList.length > 0) {
			frequencyOptionValues = props.frequencyList
				.filter(x => x.isActive)
				.map((frequency: Frequency) => {
					return { label: frequency.frequencyCode, value: frequency.frequencyId };
				});
		}

		if (props.facility.inspectionFrequencyId) {
			let selectedInspectionFrequency = props.frequencyList.find(
				a => a.frequencyId === props.facility.inspectionFrequencyId
			);
			if (selectedInspectionFrequency && !selectedInspectionFrequency.isActive) {
				frequencyOptionValues.push({
					label: selectedInspectionFrequency.frequencyCode,
					value: selectedInspectionFrequency.frequencyId,
					isHidden: true
				});
			}
		}

		setFrequencyOptionValues(frequencyOptionValues);
	}, [props.frequencyList, props.facility.inspectionFrequencyId]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);
		setFormState(newState);
	};

	const saveOverview = () => {
		let facilityToUpdate: FogFacility = {
			tagClassification1: formState.tagClassification1,
			isEnabled: formState.isEnabled,
			tagClassification2: formState.tagClassification2,
			inspectionFrequencyId: formState.inspectionFrequencyId
		};
		props.saveFogFacility(urlService.getFogFacilityId(), facilityToUpdate, () => {
			props.loadAuthorityTags();
		});
		props.toggle();
	};

	const cancelSaveOverview = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title="Edit Overview"
				save={saveOverview}
				cancel={cancelSaveOverview}>
				<SingleSelectDropdown
					id="tagClassification1"
					name="tagClassification1"
					label={localizationService.getLocalizedString('facility.tagClassification1')}
					value={formState.tagClassification1}
					onChange={changeFormState}
					options={tagClassification1OptionValues}
				/>

				<SingleSelectDropdown
					id="tagClassification2"
					name="tagClassification2"
					label={localizationService.getLocalizedString('facility.tagClassification2')}
					value={formState.tagClassification2}
					onChange={changeFormState}
					options={tagClassification2OptionValues}
				/>

				<SingleSelectDropdown
					id="inspectionFrequencyId"
					name="inspectionFrequencyId"
					label={localizationService.getLocalizedString('facility.inspectionFrequencyCode')}
					value={_.toString(formState.inspectionFrequencyId)}
					onChange={changeFormState}
					options={frequencyOptionValues}
					selfOrder={true}
				/>

				<div className="form-row">
					<TextInput
						id="lastPumpout"
						name="lastPumpout"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.lastPumpOutCompleteDate')}
						value={
							props.facility.lastPumpOutCompleteDate
								? DateUtilService.toDisplayDate(props.facility.lastPumpOutCompleteDate)
								: ''
						}
						isDisabled={true}
					/>
					<TextInput
						id="nextPumpout"
						name="nextPumpout"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.nextPumpOutDueDate')}
						value={
							props.facility.nextPumpOutDueDate
								? DateUtilService.toDisplayDate(props.facility.nextPumpOutDueDate)
								: ''
						}
						isDisabled={true}
					/>
				</div>
				<div className="form-row">
					<TextInput
						id="lastInspection"
						name="lastInspection"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.lastInspectionCompleteDate')}
						value={
							props.facility.lastInspectionCompleteDate
								? DateUtilService.toDisplayDate(props.facility.lastInspectionCompleteDate)
								: ''
						}
						isDisabled={true}
					/>
					<TextInput
						id="nextInspection"
						name="nextInspection"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.nextInspectionDueDate')}
						value={
							props.facility.nextInspectionDueDate
								? DateUtilService.toDisplayDate(props.facility.nextInspectionDueDate)
								: ''
						}
						isDisabled={true}
					/>
				</div>
				<SingleCheckbox
					id="isEnabled"
					name="isEnabled"
					label={localizationService.getLocalizedString('facility.isEnabled')}
					checked={formState.isEnabled}
					onChange={changeFormState}
					className="div-checkbox"
				/>
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, authorityTags: state.authorityTags, ...state.extractors };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate, callbackOnSuccess)),
		loadAuthorityTags: () => dispatch(loadAuthorityTags())
	};
};

export const OverviewModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(OverviewModalComp);
