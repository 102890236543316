import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './facility-details-address.scss';
import { localizationService, QueryParameters } from 'src/services';
import _ from 'lodash';

interface CompProps {
	streetAddress: string;
	cityDetails: string;
	isForMap?: boolean;
	latitude?: number;
	longitude?: number;
}
interface Props extends CompProps {}

export const MapMarker: React.FC<Props> = props => {
	let placeAddress = _.trim(_.toString(props.streetAddress));
	// Check here to see google map url search guide https://developers.google.com/maps/documentation/urls/get-started
	let queryParameters = new QueryParameters()
		.add('api', '1')
		.add('query', `${placeAddress}  ${props.cityDetails}`)
		.toQueryString();

	const googleMapLink = 'https://www.google.com/maps/search/';
	let addressUrl = `${googleMapLink}?${queryParameters}`;

	return (
		<>
			<div className="map-div d-flex flex-row">
				<div className="map-marker pr-2" onClick={() => window.open(addressUrl)}>
					<FaMapMarkerAlt />
				</div>
				{props.isForMap === true && _.isEmpty(props.cityDetails) && _.isEmpty(props.streetAddress) ? (
					<div>
						{localizationService.getLocalizedString('map.noAddress')} <br /> {props.latitude} <br />
						{props.longitude}
					</div>
				) : (
					<a
						href={addressUrl}
						title={localizationService.getLocalizedString('facility.FacilityAddressToolTip')}
						target="_blank"
						rel="noopener noreferrer">
						{props.streetAddress && (
							<>
								{props.streetAddress}
								<br />
							</>
						)}
						{props.cityDetails}
					</a>
				)}
			</div>
		</>
	);
};
