import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageLoadingContainer } from '../layout/page-loading-container';
import { CloseSvg } from '../svg';
import { Translate } from './translate/translator';

export interface DeleteModalProp {
	showModal: boolean;
	title: string;
	message: string;
	onCancelButtonClick: (e?: any) => void;
	onOkayButtonClick: (e?: any) => void;
	okayButtonText?: string;
	cancelButtonText?: string;
	isDeleteButton?: boolean;
}

export const DeleteModal: React.SFC<DeleteModalProp> = props => {
	const toggleModal = (e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		props.onCancelButtonClick();
	};
	const cancelButtonText = props.cancelButtonText ? props.cancelButtonText : 'Cancel';
	const closeBtn = (
		<button className="close" onClick={(e?: any) => toggleModal(e)}>
			<CloseSvg />
		</button>
	);

	return (
		<Modal
			isOpen={props.showModal}
			toggle={(e?: any) => toggleModal(e)}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}>
			<PageLoadingContainer />
			<ModalHeader
				tag="h1"
				className={props.isDeleteButton ? 'delete-modal-header' : ''}
				toggle={(e?: any) => toggleModal(e)}
				close={closeBtn}>
				<Translate>{props.title}</Translate>
			</ModalHeader>
			<ModalBody>
				<p>
					<Translate>{props.message}</Translate>
				</p>
			</ModalBody>
			<ModalFooter>
				<button
					className={`btn ${props.isDeleteButton ? 'ai-delete ml-auto' : 'ai-action'}`}
					id="okayBtn"
					onClick={props.onOkayButtonClick}>
					<Translate>{props.okayButtonText ? props.okayButtonText : 'Save'}</Translate>
				</button>
				<button className="btn ai-white" id="cancelBtn" onClick={(e?: any) => toggleModal(e)}>
					<Translate>{cancelButtonText}</Translate>
				</button>
			</ModalFooter>
		</Modal>
	);
};
