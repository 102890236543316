import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkoRouteActionType } from 'src/redux/linko-route';
import { dispatchAccessor, localizationService } from 'src/services';

type Props = {
	path?: string;
	message?: string;
};

class NotFoundComponent extends Component<Props, any> {
	componentDidUpdate() {
		dispatchAccessor.dispatch({ type: LinkoRouteActionType.restoreInitialState });
	}
	render() {
		return (
			<div className="page">
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('screen.pageNotFound.title')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main">
						<section className="min-height-180px">
							<p>
								{this.props.message
									? this.props.message
									: localizationService.getLocalizedString('screen.pageNotFound.message')}
							</p>
						</section>
					</div>
				</div>
			</div>
		);
	}
}

export const NotFound = connect()(NotFoundComponent);
