import { Jurisdiction, MfaQuestion, QuestionAnswerDtos } from '@rcp/types';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, ApplicationState } from 'src/redux';
import { loadQuestions, loadStates } from 'src/redux/account/register';
import { apiService, localizationService, navigateTo, tokenService, urlService } from 'src/services';
import CollapsibleCard from '../widgets/collapsible-card/collapsible-card';
import KbqQuestions from './kbq-questions';
import SecurityQuestions from './security-questions';

interface Match {
	params: {
		invitationId: number;
	};
}

interface DispatchProps {
	loadQuestions: () => void;
	loadStates: () => void;
}
interface Props extends DispatchProps {
	history: History;
	match: Match;
	states: Jurisdiction[];
	questions: MfaQuestion[];
	location: any;
}

const ConfigureQuestionAnswers: FC<Props> = props => {
	const [step1Status, setStep1Status] = useState(false);
	const [step2Status] = useState(false);
	const [userInfo] = useState({} as any);
	const [kbq, setKbq] = useState([] as QuestionAnswerDtos[]);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		props.loadQuestions();
		props.loadStates();
		if (String.equalCaseInsensitive(localStorage.getItem(':isMfaEnabledProgramAssociated') || undefined, 'false')) {
			navigateTo(props.history, '/signIn');
			tokenService.clearToken();
		}
		document.body.className = 'signinBody';
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onNextKbq = (questions: QuestionAnswerDtos[]) => {
		setStep1Status(true);
		setKbq(questions);
	};

	const onNextSecurityQuestions = async (securityQuestions: QuestionAnswerDtos[], agreement: boolean) => {
		userInfo.agreeTermsAndConditions = agreement || false;
		let registerData = {
			questionAnswerDtos: [...kbq, ...securityQuestions]
		};

		let url = `${urlService.getApiBaseUrl()}/Account/UserProfile/MFADetails`;

		try {
			await apiService.httpPost(url, registerData, {
				Authorization: `Bearer ${props.location.state.accessToken}`,
				Accept: 'application/json',
				'Content-Type': 'application/json'
			});

			setSuccess(true);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const renderSuccessConfigureMfa = (
		<>
			<h1>{localizationService.getLocalizedString('ipp.accountPortal.messages.mfaConfigurationSuccessful')}</h1>
			<p>{localizationService.getLocalizedString('ipp.accountPortal.messages.profileUpdated')}</p>

			<div className="signin-footer">
				<a id="backToSignInLink" href={urlService.getLoginUrl()} className="btn ai-action btn-signin">
					{localizationService.getLocalizedString('authentication.backToSignIn')}
				</a>
			</div>
		</>
	);

	return !success ? (
		<div className="page">
			<div className="page-header">
				<h1>
					{localizationService.getLocalizedString('ipp.accountPortal.messages.configureQuestionAndAnswers')}
				</h1>
			</div>
			<div className="page-wrapper">
				{!step1Status && !step2Status && (
					<CollapsibleCard
						key="kbq-questions"
						className="mt-3"
						accordionType="kbq-questions"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.accountPortal.knowledgeBasedQuestions'
						)}>
						<KbqQuestions
							questions={
								props.questions
									? props.questions.filter(question =>
											String.equalCaseInsensitive(question.questionType, 'KBQ')
									  )
									: []
							}
							showEditButton={false}
							onNextKbq={(questions: QuestionAnswerDtos[]) => onNextKbq(questions)}
						/>
					</CollapsibleCard>
				)}
				{step1Status && !step2Status && (
					<CollapsibleCard
						key="sq-questions"
						className="mt-3"
						accordionType="sq-questions"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.accountPortal.securityQuestions'
						)}>
						<SecurityQuestions
							showEditButton={false}
							submitBtnPrefix="ipp.buttons.submit"
							hideAgreement={true}
							questions={
								props.questions
									? props.questions.filter(question =>
											String.equalCaseInsensitive(question.questionType, 'SQ')
									  )
									: []
							}
							onNextSecurityQuestions={(questions: QuestionAnswerDtos[], agreement: boolean) =>
								onNextSecurityQuestions(questions, agreement)
							}
						/>
					</CollapsibleCard>
				)}
			</div>
		</div>
	) : (
		<>
			<div className="page-header" />
			<div className="page-wrapper">
				<section id="mfaConfigureSuccess" className="d-flex mx-auto">
					<div>{renderSuccessConfigureMfa}</div>
				</section>
			</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.register };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadStates: () => dispatch(loadStates()),
		loadQuestions: () => dispatch(loadQuestions())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureQuestionAnswers);
