import React, { useRef, useState, useContext, useEffect } from 'react';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, validationService } from 'src/services';
import _ from 'lodash';
import { TextInput, SingleSelectDropdown } from 'src/components/widgets';
import { FacilityBulkNoticeSteps, NoticeTemplateType, MarginUnits, printMarginUnitOptions } from '@rcp/types';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { alertService, RootState } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import { generalNoticeTemplateSlice } from '../../../settings/notices/general-notice-template.slice';
import './step.scss';

const { Inches } = MarginUnits;

const initialFormField: any = {
	templateBodyContent: '',
	templateHeaderContent: '',
	templateFooterContent: '',
	templateContent: '',
	templateSubjectContent: '',
	marginLeft: 1,
	marginRight: 1,
	marginBottom: 1,
	marginTop: 1,
	printMarginUnit: Inches,
	templateSubjectError: '',
	templateContentError: ''
};

const { STEP1, STEP2, STEP3 } = FacilityBulkNoticeSteps;

export const Step3 = () => {
	const stepperContext = useContext(StepperContext);
	const [formField, setFormField] = useState<any>(initialFormField);
	const dispatch = useDispatch();
	let generalNoticeTemplateSchedule = (state: RootState) => state.generalNoticeTemplate;
	let { selected: generalNoticeTemplate } = useSelector(generalNoticeTemplateSchedule);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);
	const step1Data = stepperContext.getData(STEP1);
	const updatedStep1 = { ...stepperContext.getStep(STEP1), data: { ...step1Data, isDataChanged: false } };
	const nextButtonClickedRef = useRef<any>(null);
	const noticeContentRef = useRef<any>(null);
	noticeContentRef.current = formField;

	useEffect(() => {
		const step3Data = stepperContext.getData(STEP3);
		const step1Data = stepperContext.getData(STEP1);
		step3Data && setFormField({ ...step3Data });
		dispatch(generalNoticeTemplateSlice.fetchOne(step1Data.noticeTemplateId));
		return () => {
			const isFormValid = !nextButtonClickedRef.current
				? Boolean(noticeContentRef.current.templateBodyContent)
				: true;
			!nextButtonClickedRef.current && updateStepOnUnmount();
			if (!isFormValid) {
				alertService.addError(
					localizationService.getLocalizedString('authoritySetting.noticesSettings.templateBodyRequired')
				);
				stepperContext.goAt(STEP3);
			} else {
				stepperContext.updateStep(STEP1, updatedStep1);
			}
		};
	}, [dispatch]);

	useEffect(() => {
		const step3Data = stepperContext.getData(STEP3);

		step1Data.isDataChanged
			? setFormField({ ...formField, ...generalNoticeTemplate })
			: step3Data
			? setFormField({ ...step3Data })
			: setFormField({ ...formField, ...generalNoticeTemplate });
	}, [generalNoticeTemplate]);

	const updateStepOnUnmount = () => {
		let dataToBeSaved = noticeContentRef.current;
		const updatedStep3 = {
			...stepperContext.getStep(STEP3),
			data: { ...dataToBeSaved }
		};
		stepperContext.updateStep(STEP3, updatedStep3);
	};

	const isFormValid = () => {
		return validationService.validateMergeFields(
			formField.templateBodyContent + formField.templateFooterContent + formField.templateSubjectContent,
			mergeFields
		);
	};

	const onClickNext = () => {
		nextButtonClickedRef.current = true;
		if (!isFormValid()) {
			return;
		}
		if (formField.templateBodyContent) {
			stepperContext.resolve({ ...formField });
			return;
		}
		alertService.addError('bulkNotice.templateBodyRequiredValidation');
	};

	const onClickBack = () => stepperContext.goAt(STEP2);

	const changeFormField = (e: any) => {
		let newState = { ...formField };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setFormField(newState);
	};

	return (
		<StepperContent
			className="w-100"
			actions={
				<>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('bulkNotice.back')}
					</StepperAction>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('bulkNotice.next')}
					</StepperAction>
				</>
			}>
			<div className="add-general-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('bulkNotice.header')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateHeaderContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateHeaderContent: event.html });
					}}
					height="80px"
					isFixedHeight
					editorIndex={0}
					showTableTools={true}
				/>
			</div>
			<div className="add-general-notice-editor-template form-group required general-notice-body">
				<label>{localizationService.getLocalizedString('bulkNotice.body')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateBodyContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateBodyContent: event.html });
					}}
					isLetterTemplate
					height="470px"
					showTableTools={true}
					hidePageNumber
				/>
			</div>
			<div className="add-general-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('bulkNotice.footer')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateFooterContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateFooterContent: event.html });
					}}
					height="60px"
					isFixedHeight
					editorIndex={2}
					showTableTools={true}
				/>
			</div>
			<div>
				<p>{localizationService.getLocalizedString('bulkNotice.printMargins')}</p>
				<div className="form-row">
					<TextInput
						id="marginTop"
						name="marginTop"
						type="number"
						className="col"
						onChange={changeFormField}
						value={formField.marginTop}
						label={localizationService.getLocalizedString('bulkNotice.top')}
					/>

					<TextInput
						id="marginBottom"
						name="marginBottom"
						type="number"
						className="col"
						value={formField.marginBottom}
						onChange={changeFormField}
						label={localizationService.getLocalizedString('bulkNotice.bottom')}
					/>

					<TextInput
						id="marginLeft"
						name="marginLeft"
						className="col"
						value={formField.marginLeft}
						type="number"
						onChange={changeFormField}
						label={localizationService.getLocalizedString('bulkNotice.left')}
					/>

					<TextInput
						id="marginRight"
						name="marginRight"
						className="col"
						value={formField.marginRight}
						onChange={changeFormField}
						type="number"
						label={localizationService.getLocalizedString('bulkNotice.right')}
					/>

					<SingleSelectDropdown
						id="marginUnit"
						name="printMarginUnit"
						className="col"
						onChange={changeFormField}
						value={formField.printMarginUnit}
						noEmptyOption={true}
						label={localizationService.getLocalizedString('bulkNotice.units')}
						options={printMarginUnitOptions}
					/>
				</div>
			</div>
		</StepperContent>
	);
};
