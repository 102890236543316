import { IppAuditLog } from '@rcp/types';
import React from 'react';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { DateUtilService, localizationService } from 'src/services';
import './audit-logs.scss';

interface LogDetailsProps {
	selectedLogDetails: IppAuditLog;
}

const AuditLogsDetailsComponent: React.FC<LogDetailsProps> = (props: LogDetailsProps) => {
	let {
		auditLogTemplateId,
		regulatoryProgramName,
		organizationName,
		creatorOrganizationName,
		userProfileId,
		eventCategory,
		eventType,
		userName,
		userFirstName,
		userLastName,
		userEmailAddress,
		ipAddress,
		hostName,
		requestUserAgent,
		comment,
		creationDateTimeUtc
	} = props.selectedLogDetails;

	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<CollapsibleCard
					accordionType="log-details"
					accordionHeading={localizationService.getLocalizedString('ipp.auditLog.labels.logDetails')}
					className="d-flex flex-column w-100 max-table">
					<div className="table-responsive" id="audit-logs-details">
						<table className="table table-bordered table-condensed table-striped">
							<tbody>
								<tr>
									<td>
										<span className="font-size-14px-medium">
											{localizationService.getLocalizedString('ipp.auditLog.labels.comment')}
										</span>
									</td>
									<td colSpan={3}>
										{comment
											? comment.split(/\r\n/).map((logItem: string, index: number) => (
													<div key={index} className="comment text-break">
														{logItem}
													</div>
											  ))
											: ''}
									</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium col-sm-2">
										{localizationService.getLocalizedString('ipp.auditLog.labels.eventCategory')}
									</td>
									<td className="col-sm-2">{eventCategory ? eventCategory : ''}</td>
									<td className="font-size-14px-medium col-sm-2">
										{localizationService.getLocalizedString('ipp.auditLog.labels.userId')}
									</td>
									<td>{userProfileId ? userProfileId : ''}</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.eventType')}
									</td>
									<td>{eventType ? eventType : ''}</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.userName')}
									</td>
									<td>{userName ? userName : ''}</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.eventCode')}
									</td>
									<td>{auditLogTemplateId ? auditLogTemplateId : ''}</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString(
											'ipp.auditLog.labels.firstNameAndLastName'
										)}
									</td>
									<td>
										{userFirstName ? userFirstName : ''} {userLastName ? userLastName : ''}
									</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.dateAndTime')}
									</td>
									<td>
										{creationDateTimeUtc
											? `${DateUtilService.toDisplayDate(
													creationDateTimeUtc
											  )} ${DateUtilService.toDisplayTime(
													creationDateTimeUtc
											  ).toLocaleUpperCase()}`
											: ''}
									</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.email')}
									</td>
									<td>{userEmailAddress ? userEmailAddress : ''}</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.authority')}
									</td>
									<td>{creatorOrganizationName ? creatorOrganizationName : ''}</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.ipAddress')}
									</td>
									<td>{ipAddress ? ipAddress : ''}</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">Facility</td>
									<td>{organizationName ? organizationName : ''}</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.ipName')}
									</td>
									<td>{hostName ? hostName : ''}</td>
								</tr>
								<tr>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString(
											'ipp.auditLog.labels.regulatoryProgram'
										)}
									</td>
									<td>{regulatoryProgramName ? regulatoryProgramName : ''}</td>
									<td className="font-size-14px-medium">
										{localizationService.getLocalizedString('ipp.auditLog.labels.requestUserAgent')}
									</td>
									<td>{requestUserAgent ? requestUserAgent : ''}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</CollapsibleCard>
			</div>
		</>
	);
};

export default AuditLogsDetailsComponent;
