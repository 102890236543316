import { CccHazard, DropDownOption, LookupType } from '@rcp/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopoverModal, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components/widgets';
import { RootState } from 'src/redux';
import { localizationService, optionsMap, urlService } from 'src/services';
import {
	cccLookupHazardDeviceStatusSlice,
	cccLookupHazardServiceStatusSlice,
	cccLookupHazardServiceTypeSlice
} from '../../settings/hazard-lookups.slice';
import { cccHazardSlice } from '../hazards.slice';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentHazard: CccHazard;
}

type Props = OwnProps;

interface OverviewForm {
	serviceTypeId?: number;
	serviceStatusId?: number;
	serviceNumber: string;
	meterLocation: string;
	deviceStatusId?: number;
	deviceSerialNumber?: string;
	deviceLocation?: string;
}

const initialOverviewForm: OverviewForm = {
	serviceNumber: '',
	meterLocation: '',
	deviceSerialNumber: '',
	deviceLocation: ''
};

export const CccHazardOverviewTabModal: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [formState, setFormState] = React.useState(initialOverviewForm);
	const [serviceTypeValues, setServiceTypeValues] = React.useState([] as DropDownOption[]);
	const [serviceStatusValues, setServiceStatusValues] = React.useState([] as DropDownOption[]);
	const [deviceStatusValues, setDeviceStatusValues] = React.useState([] as DropDownOption[]);

	const setFormStateFromProps = (hazard: CccHazard) => {
		let newState = {
			serviceTypeId: hazard.serviceTypeId,
			serviceStatusId: hazard.serviceStatusId,
			serviceNumber: hazard.serviceNumber || '',
			meterLocation: hazard.meterLocation || '',
			deviceStatusId: hazard.deviceStatusId,
			deviceSerialNumber: hazard.deviceSerialNumber || '',
			deviceLocation: hazard.deviceLocation || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.currentHazard);
	}, [props.currentHazard]);

	React.useEffect(() => {
		dispatch(cccLookupHazardServiceTypeSlice.fetchAll(`lookupType=${LookupType.CccHazardServiceType}`));
		dispatch(cccLookupHazardServiceStatusSlice.fetchAll(`lookupType=${LookupType.CccHazardServiceStatus}`));
		dispatch(cccLookupHazardDeviceStatusSlice.fetchAll(`lookupType=${LookupType.CccHazardDeviceStatus}`));
	}, [dispatch]);

	let { result: serviceTypes } = useSelector((state: RootState) => state.settingLookupHazardServiceType);
	let { result: serviceStatuses } = useSelector((state: RootState) => state.settingLookupHazardServiceStatus);
	let { result: deviceStatuses } = useSelector((state: RootState) => state.settingLookupHazardDeviceStatus);

	useEffect(() => {
		let serviceTypeOptions: DropDownOption[] = [];
		let serviceStatusOptions: DropDownOption[] = [];
		let deviceStatusOptions: DropDownOption[] = [];
		if (serviceTypes.length > 0) {
			serviceTypeOptions = optionsMap.fromLookups(
				serviceTypes,
				props.currentHazard.serviceTypeId,
				props.currentHazard.serviceType
			);
			setServiceTypeValues(serviceTypeOptions);
		}

		if (serviceStatuses.length > 0) {
			serviceStatusOptions = optionsMap.fromLookups(
				serviceStatuses,
				props.currentHazard.serviceStatusId,
				props.currentHazard.serviceStatus
			);
			setServiceStatusValues(serviceStatusOptions);
		}

		if (deviceStatuses.length > 0) {
			deviceStatusOptions = optionsMap.fromLookups(
				deviceStatuses,
				props.currentHazard.deviceStatusId,
				props.currentHazard.deviceStatus
			);
			setDeviceStatusValues(deviceStatusOptions);
		}
	}, [serviceTypes, serviceStatuses, deviceStatuses, props.currentHazard]);

	const saveOverview = () => {
		let hazardToUpdate: CccHazard = {
			serviceTypeId: formState.serviceTypeId,
			serviceStatusId: formState.serviceStatusId,
			serviceNumber: formState.serviceNumber,
			meterLocation: formState.meterLocation,
			deviceStatusId: formState.deviceStatusId,
			deviceSerialNumber: formState.deviceSerialNumber,
			deviceLocation: formState.deviceLocation
		};
		dispatch(
			cccHazardSlice.patchOne(
				urlService.getCccHazardId(),
				hazardToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccHazard.hazard')
				),
				() => props.toggle()
			)
		);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const cancelSaveOverview = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('cccHazard.editOverview')}
				save={saveOverview}
				cancel={cancelSaveOverview}>
				<SingleSelectDropdown
					id="serviceTypeId"
					name="serviceTypeId"
					label={localizationService.getLocalizedString('cccHazard.serviceType')}
					value={_.toString(formState.serviceTypeId)}
					onChange={changeFormState}
					options={serviceTypeValues}
				/>
				<SingleSelectDropdown
					id="serviceStatusId"
					name="serviceStatusId"
					label={localizationService.getLocalizedString('cccHazard.serviceStatus')}
					value={_.toString(formState.serviceStatusId)}
					onChange={changeFormState}
					options={serviceStatusValues}
				/>
				<TextInput
					id="serviceNumber"
					name="serviceNumber"
					label={localizationService.getLocalizedString('cccHazard.serviceNumber')}
					value={formState.serviceNumber}
					onChange={changeFormState}
				/>
				<TextAreaInput
					id="meterLocation"
					name="meterLocation"
					label={localizationService.getLocalizedString('cccHazard.meterLocation')}
					value={formState.meterLocation}
					onChange={changeFormState}
					isFullWidth={true}
				/>
				<SingleSelectDropdown
					id="deviceStatusId"
					name="deviceStatusId"
					label={localizationService.getLocalizedString('cccHazard.deviceStatus')}
					value={_.toString(formState.deviceStatusId)}
					onChange={changeFormState}
					options={deviceStatusValues}
				/>
				<TextInput
					id="deviceSerialNumber"
					name="deviceSerialNumber"
					label={localizationService.getLocalizedString('cccHazard.deviceSerialNumber')}
					value={formState.deviceSerialNumber}
					onChange={changeFormState}
				/>
				<TextAreaInput
					id="deviceLocation"
					name="deviceLocation"
					label={localizationService.getLocalizedString('cccHazard.deviceLocation')}
					value={formState.deviceLocation}
					onChange={changeFormState}
					isFullWidth={true}
				/>
			</PopoverModal>
		</div>
	);
};
