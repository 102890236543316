import React from 'react';
import { useDispatch } from 'react-redux';
import { DateInput, DeleteModal, PopoverModal, SingleSelectDropdown, TextAreaInput } from 'src/components/widgets';
import { DropDownOption, CccTestDto } from '@rcp/types';
import {
	apiService,
	DateUtilService,
	localizationService,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { alertService } from 'src/redux';
import _ from 'lodash';
import { cccHazardTestsSlice } from './hazard-tests.slice';
import { reloadTimelineEventsFromServer } from 'src/features/timeline/timeline-service';
import { cccHazardSlice } from '../hazards.slice';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentTest: CccTestDto;
	completedResultRequired: boolean;
	dueDateRequired: boolean;
	modalTitle: string | undefined;
	isScheduledTest: boolean;
	isFromHazardGrid?: boolean;
	hazardId?: number;
}

type Props = OwnProps;

interface OverviewForm {
	testDueDate?: string;
	initialTestDate?: string;
	initialPass?: string;
	notes?: string;
	testDueDateError?: string;
	initialTestDateError?: string;
	initialPassError?: string;
}

const initialOverviewForm: OverviewForm = {
	testDueDate: '',
	initialTestDate: '',
	initialPass: '',
	notes: ''
};

const testResultDropdownValues: DropDownOption[] = [
	{ label: 'Pass', value: 'Pass' },
	{ label: 'Fail', value: 'Fail' }
];

export const TestDetailModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialOverviewForm);
	const [testResultValues] = React.useState(testResultDropdownValues);
	const [toggleDeleteTestModal, setToggleDeleteTestModal] = React.useState(false);
	const [hasCurrentTest, setHasCurrentTest] = React.useState(false);

	const setFormStateFromProps = (test: CccTestDto) => {
		let newState = {
			testDueDate: test.testDueDate || '',
			initialTestDate: test.initialTestDate || '',
			initialPass: test.initialPass || '',
			notes: test.notes || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		let hasCurrentTest = !_.isEmpty(props.currentTest);
		setHasCurrentTest(hasCurrentTest);

		if (hasCurrentTest) {
			cccHazardTestsSlice.setApiUrlPath(
				`${Resource.CccHazards}/${props.currentTest.hazardId}/${Resource.CccTests}`
			);
		}

		setFormStateFromProps(props.currentTest);
	}, [props.currentTest]);

	React.useEffect(() => {
		if (props.hazardId) {
			cccHazardTestsSlice.setApiUrlPath(`${Resource.CccHazards}/${props.hazardId}/${Resource.CccTests}`);
		}
	}, [props.hazardId]);

	const addTest = () => {
		if (!validateOverviewDataForSave()) {
			return;
		}
		let testToAdd: CccTestDto = {
			testDueDate: formState.testDueDate,
			initialTestDate: formState.initialTestDate,
			initialPass: formState.initialPass,
			notes: formState.notes
		};

		const testsUrl = urlService.getHazardTestUrl(props.hazardId);
		apiService
			.postResource(testsUrl, testToAdd)
			.then(res => {
				alertService.addSuccess(
					localizationService.getLocalizedString(
						'alertMessages.addSuccess',
						localizationService.getLocalizedString('cccTest.test')
					)
				);
				if (res.nextTestDate) {
					alertService.addInfo(
						localizationService.getLocalizedString(
							'cccTest.scheduledNextTestConfirmation',
							DateUtilService.toDisplayDate(res.nextTestDate)
						)
					);
				}

				if (props.isFromHazardGrid) {
					dispatch(cccHazardSlice.reload());
				} else {
					dispatch(cccHazardTestsSlice.fetchAll());
					reloadTimelineEventsFromServer();
				}
				props.toggle();
			})
			.catch(err => alertService.addError(err.message));
	};

	const editTest = () => {
		if (!validateOverviewDataForSave()) {
			return;
		}
		let testToEdit: CccTestDto = {
			testDueDate: formState.testDueDate,
			initialTestDate: formState.initialTestDate,
			initialPass: formState.initialPass,
			notes: formState.notes
		};
		let testId = props.currentTest.testId;

		if (testId) {
			const testsUrl = urlService.getTestsUrl(testId);

			apiService
				.patchResource<CccTestDto>(testsUrl, testToEdit)
				.then((res: CccTestDto) => {
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.updateSuccess',
							localizationService.getLocalizedString('cccTest.test')
						)
					);

					if (res.nextTestDate) {
						alertService.addInfo(
							localizationService.getLocalizedString(
								'cccTest.scheduledNextTestConfirmation',
								DateUtilService.toDisplayDate(res.nextTestDate)
							)
						);
					}

					if (props.isFromHazardGrid) {
						dispatch(cccHazardSlice.reload());
					} else {
						dispatch(cccHazardTestsSlice.fetchAll());
						reloadTimelineEventsFromServer();
					}
					props.toggle();
				})
				.catch(err => alertService.addError(err.message));
		}
	};

	const deleteTest = () => {
		setToggleDeleteTestModal(false);
		let testId = props.currentTest.testId;
		if (testId) {
			dispatch(
				cccHazardTestsSlice.deleteOne(
					testId,
					false,
					localizationService.getLocalizedString(
						'alertMessages.removeSuccess',
						localizationService.getLocalizedString('cccTest.test')
					),
					() => {
						if (props.isFromHazardGrid) {
							dispatch(cccHazardSlice.reload());
						} else {
							dispatch(cccHazardTestsSlice.fetchAll());
							reloadTimelineEventsFromServer();
						}

						props.toggle();
					}
				)
			);
		}
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const validateOverviewDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;
		if (props.dueDateRequired) {
			validationService.validateRequiredDateField(
				newState,
				'testDueDate',
				'testDueDateError',
				localizationService.getLocalizedString('cccTest.dueDate')
			);
		} else {
			validationService.validateNullableDateField(
				newState,
				'testDueDate',
				'testDueDateError',
				localizationService.getLocalizedString('cccTest.dueDate')
			);
		}
		if (props.completedResultRequired) {
			validationService.validateRequiredDateField(
				newState,
				'initialTestDate',
				'initialTestDateError',
				localizationService.getLocalizedString('cccTest.completeDate')
			);
		} else {
			validationService.validateNullableDateField(
				newState,
				'initialTestDate',
				'initialTestDateError',
				localizationService.getLocalizedString('cccTest.completeDate')
			);
		}
		if (props.completedResultRequired) {
			validationService.validateRequiredField(
				newState,
				'initialPass',
				'initialPassError',
				localizationService.getLocalizedString('cccTest.testResult')
			);
		}

		if (formState.initialTestDate) {
			if (formState.initialPass === '') {
				newState = {
					initialPassError: localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('cccTest.testResult')
					),
					...newState
				};
			} else {
				_.unset(newState, 'initialPassError');
			}
		}

		isFormValid = !validationService.hasError(
			newState,
			'testDueDateError',
			'initialTestDateError',
			'initialPassError'
		);
		if (newState.initialTestDate) {
			if (DateUtilService.isAfterToday(newState.initialTestDate)) {
				isFormValid = false;
				newState.initialTestDateError = localizationService.getLocalizedString(
					'cccTest.maxCompleteDateValidationMessage'
				);
			}
		}
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const editTestFooter = () => {
		return (
			<>
				<button className="btn ai-secondary-delete" onClick={() => setToggleDeleteTestModal(true)}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
				<div className="ml-auto">
					<button className="btn ai-save" onClick={editTest}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={props.toggle}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div className="w-100" id="testDetailsModal">
			<PopoverModal
				showModal={props.isToggle}
				title={props.modalTitle ? props.modalTitle : localizationService.getLocalizedString('cccTest.editTest')}
				save={addTest}
				cancel={props.toggle}
				footer={hasCurrentTest ? editTestFooter() : undefined}>
				<DateInput
					id="testDueDate"
					name="testDueDate"
					label={localizationService.getLocalizedString('cccTest.dueDate')}
					value={formState.testDueDate}
					error={formState.testDueDateError}
					onChange={changeFormState}
					isRequired={props.dueDateRequired}
				/>

				{!props.isScheduledTest && (
					<>
						<DateInput
							id="initialTestDate"
							name="initialTestDate"
							label={localizationService.getLocalizedString('cccTest.completeDate')}
							value={formState.initialTestDate}
							error={formState.initialTestDateError}
							onChange={changeFormState}
							isRequired={props.completedResultRequired}
							max={DateUtilService.getAuthorityTimezoneNow()}
						/>
						<SingleSelectDropdown
							id="initialPass"
							name="initialPass"
							label={localizationService.getLocalizedString('cccTest.testResult')}
							options={testResultValues}
							value={_.toString(formState.initialPass)}
							onChange={changeFormState}
							isRequired={props.completedResultRequired || !_.isEmpty(formState.initialTestDate)}
							error={formState.initialPassError}
						/>

						<TextAreaInput
							id="notes"
							name="notes"
							label={localizationService.getLocalizedString('cccTest.comments')}
							value={formState.notes}
							onChange={changeFormState}
							isFullWidth={true}
						/>
					</>
				)}
			</PopoverModal>
			{toggleDeleteTestModal && (
				<DeleteModal
					key="confirmDeleteTestModal"
					title={localizationService.getLocalizedString('cccTest.deleteTestTitle')}
					message={localizationService.getLocalizedString('cccTest.deleteTestMessage')}
					showModal={toggleDeleteTestModal}
					onCancelButtonClick={() => {
						setToggleDeleteTestModal(false);
					}}
					onOkayButtonClick={deleteTest}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
