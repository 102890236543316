import React, { FC } from 'react';
import { History } from 'history';
import { ippDataProviderSlice } from './data-providers-slice';
import * as LinkoTypes from '@rcp/types';
import { IppDataProvider, ColumnField, FilterType, LocalStorageName, ApiEndpoints } from '@rcp/types';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { alertService, RootState } from 'src/redux';
import { apiService, localizationService, navigateTo, tokenService, urlService } from 'src/services';
import { IndustriesState } from 'src/redux/ipp/authority/industry';
import { IppConstants } from 'src/constants';
import { Utils } from 'src/services/utils';

interface Props extends LinkoTypes.RouteProps, IndustriesState {
	history: History;
}

const { initialPage } = IppConstants.queryParams;

const IppDataProviders: FC<Props> = props => {
	const downloadInstructions = async () => {
		let url = `${urlService.getApiBaseUrlWithProgram()}/${ApiEndpoints.IppDataProvidersDownload}`;
		await apiService.getResource(url);
		alertService.addSuccess(
			localizationService.getLocalizedString('ipp.industryAccount.dataProviders.downloadSuccessMessage')
		);
	};

	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppDataProvider;
		const dataProviderPath = urlService.getIppIndustryUrl(`industryAccount/dataProviders`, dataItem.dataSourceId);
		return (
			<td>
				<a
					href={dataProviderPath}
					className="ai-link"
					onClick={(e: any) => navigateTo(props.history, dataProviderPath, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showEditColumnButton: false,
		showFilter: false,
		showSearch: false,
		showRefreshButton: true,
		prefix: 'ipp.industryAccount.dataProviders.columns',
		storageName: LocalStorageName.IppDataProviderGrid,
		customFieldQuery: '',
		searchPlaceholder: '',
		showActions: false,
		pageTitle: `${tokenService.getTokenOrDefault().portalOrganizationName ||
			''} ${localizationService.getLocalizedString('ipp.industryAccount.dataProviders.pageHeader')}`,
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		addButtonText: '',
		multiButtons: [
			{
				text: localizationService.getLocalizedString('ipp.buttons.downloadInstructions'),
				id: 'download-instructions',
				action: () => downloadInstructions()
			},
			{
				text: localizationService.getLocalizedString('ipp.buttons.createNew'),
				id: 'create-data-provider',
				action: () => {
					navigateTo(props.history, urlService.getIppIndustryUrl(`industryAccount/dataProviders/new`));
				}
			}
		],
		showScheduleInspectionModal: false,
		queryParameters: { page: initialPage },
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('dataSourceId', FilterType.Integer),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('description'),
			new ColumnField('lastModifierFullName')
		],
		defaultColumns: ['name', 'description', 'lastModificationDateTimeLocal', 'lastModifierFullName']
	};
	const dataProvidersState = (state: RootState) => state.ippDataProviders;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippDataProviderSlice}
				restState={dataProvidersState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppDataProviders;
