import React, { FC } from 'react';
import { openSignaturePad } from '../layout/signature-modal';
import { Translate } from './translate/translator';

interface Props {
	id: string;
	label?: string;
	readOnly?: boolean;
	signatureError?: boolean;
	signature?: string;
	name?: string;
	isRequired?: boolean;
	handleSignatureUpdate?: (event: any, signature: string) => void;
	hintText?: string;
	showErrorAndHelp?: boolean;
	doNotTranslate?: boolean;
}

export const Signature: FC<Props> = props => {
	return (
		<div className={`form-group w-100 ${props.isRequired ? 'required' : ''}`}>
			<label htmlFor={props.id}>
				<Translate doNotTranslate={props.doNotTranslate}>{props.label || ''}</Translate>
			</label>
			<div id={props.id} className={`user-signature ${props.signatureError ? ' is-invalid' : ''}`}>
				<img
					src={props.signature || ''}
					alt=""
					onClick={(event: any) => {
						event.target.name = props.name;
						!props.readOnly && openSignaturePad(event.target);
					}}
					onError={(event: any) => {
						event.target.name = props.name;
						event.target.style.opacity = 0;
						props.handleSignatureUpdate && props.handleSignatureUpdate(event, '');
					}}
					onLoad={(event: any) => {
						event.target.style.opacity = 1;
						event.target.name = props.name;
						props.handleSignatureUpdate && props.handleSignatureUpdate(event, event.target.src);
					}}
				/>
			</div>
			{props.signatureError && (
				<div className="invalid-feedback">
					<Translate doNotTranslate={props.doNotTranslate}>{props.signatureError}</Translate>
				</div>
			)}
			{props.hintText && (
				<div className="ai-form-help">
					<Translate doNotTranslate={props.doNotTranslate}>{props.hintText}</Translate>
				</div>
			)}
		</div>
	);
};
