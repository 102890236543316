import { LookupType, SubLookupType } from '@rcp/types';
import React from 'react';
import { LookupRowTagValueConverter } from '../../../../widgets/lookup/data-converters';
import { LookupsWidget, SettingAction, TagSettingUrlResolver } from '../../../../widgets/lookup';
import { localizationService } from 'src/services';

interface Props {
	tagSection: string;
	tagSectionDesc1: string;
	tagSectionDesc2: string;
	tagSectionDesc3: string;
	type: SubLookupType;
}

const TagSettingComponent: React.FunctionComponent<Props> = props => {
	let dataConverter = new LookupRowTagValueConverter(props.type);
	let urlResolver = new TagSettingUrlResolver(LookupType.Tag);
	let settingAction = new SettingAction(LookupType.Tag, urlResolver, dataConverter, false);

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{props.tagSection}</strong>
				<p>{props.tagSectionDesc1}</p>
				<p>{props.tagSectionDesc2}</p>
				<p>{props.tagSectionDesc3}</p>
			</div>
			<div className="col-lg-8">
				<section>
					<LookupsWidget
						id="tag"
						key="tag"
						name="tag"
						className="col-sm-12"
						label={localizationService.getLocalizedString('authoritySetting.tag')}
						editDescription={true}
						showDescriptionColum={true}
						settingAction={settingAction}
					/>
				</section>
			</div>
		</div>
	);
};

export default TagSettingComponent;
