import React from 'react';
import _ from 'lodash';
import { localizationService } from 'src/services';
import { HaulerCardComponent } from './hauler-card-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { BasicHauler } from '@rcp/types';

interface OwnProps {
	hauler: BasicHauler;
}

type Props = OwnProps;

export const LastHaulerRightPanel: React.SFC<Props> = props => {
	const haulerDetailsComponent = (
		<div className="media" key={'PrimaryHaulerCard-' + props.hauler.haulerId}>
			<FontAwesomeIcon icon={faTruck} className="align-self-start mr-3 mt-1 icon-font-color" />
			<div className="media-body">
				<HaulerCardComponent hauler={{ ...props.hauler }} />
			</div>
		</div>
	);

	const componentForHauler =
		_.isUndefined(props.hauler.haulerId) || _.isNull(props.hauler.haulerId) || props.hauler.haulerId < 1 ? (
			<div></div>
		) : (
			haulerDetailsComponent
		);

	return (
		<section>
			<div id="hauler" className="d-flex">
				<h3>{localizationService.getLocalizedString('extractor.lastHauler')}</h3>
			</div>

			{componentForHauler}
		</section>
	);
};
