import {
	CustomFieldDefinition,
	CustomFieldDefinitionApplyTo,
	ColumnDefinition,
	CustomFieldDataType,
	FilterType,
	MultiSelectDropDownOption,
	CustomFieldAware,
	ICustomFieldItem
} from '@rcp/types';
import { FilterProperty } from './data-types/filter-types';
import _ from 'lodash';
import { UtilService } from './util-service';

const toFilterProperty = (
	domainName: string,
	fieldName: string,
	fieldType: string,
	fieldLabel: string,
	defaultValueOrOptionalValues?: string
): FilterProperty => {
	let filterProperty: FilterProperty;
	if (_.toString(fieldType) === _.toString(CustomFieldDataType.Date)) {
		filterProperty = new FilterProperty({
			label: domainName,
			name: fieldName,
			filterType: FilterType.Date,
			needLocalize: false,
			showTxt: fieldLabel
		});
	} else if (_.toString(fieldType) === _.toString(CustomFieldDataType.Number)) {
		filterProperty = new FilterProperty({
			label: domainName,
			name: fieldName,
			filterType: FilterType.Number,
			needLocalize: false,
			showTxt: fieldLabel
		});
	} else if (_.toString(fieldType) === _.toString(CustomFieldDataType.SingleValueDropDown)) {
		let values = defaultValueOrOptionalValues ? defaultValueOrOptionalValues.split(',') : [];
		let optionValues = values.map<MultiSelectDropDownOption>(i => {
			return {
				label: i,
				value: i,
				selected: false
			};
		});
		//Here is the reason reason to use FilterType.Text instead of FilterType.Enum For dropdown custom field:
		//The filter expected to see used values instead of all values.
		filterProperty = new FilterProperty({
			label: domainName,
			name: fieldName,
			filterType: FilterType.Text,
			valueOptions: optionValues,
			needLocalize: false,
			showTxt: fieldLabel
		});
	} else {
		filterProperty = new FilterProperty({
			label: domainName,
			name: fieldName,
			filterType: FilterType.Text,
			needLocalize: false,
			showTxt: fieldLabel
		});
	}
	return filterProperty;
};

class CustomDefinedFieldService {
	getCustomDefinedFieldColumnDefinition(customDefinedFields: CustomFieldDefinition[]): ColumnDefinition[] {
		return customDefinedFields.map((cdf: CustomFieldDefinition) => {
			return {
				field: UtilService.toCamelCase(cdf.fieldName as string),
				title: cdf.fieldLabel || (cdf.fieldName as string),
				visible: false,
				isCustomField: true
			};
		});
	}

	getFilterProperties = (
		applyTo: CustomFieldDefinitionApplyTo,
		customFieldDefinitions: CustomFieldDefinition[]
	): FilterProperty[] => {
		let applyToCfds = customFieldDefinitions.filter(i => i.appliedToTable === applyTo);
		let domainName = '';
		if (applyTo === CustomFieldDefinitionApplyTo.FogFacility) {
			domainName = 'facility';
		}
		let filterProperties: FilterProperty[] = [];
		_.forEach(applyToCfds, (cfd: CustomFieldDefinition) => {
			let fieldType = cfd.fieldType as string;
			let fieldName = UtilService.toCamelCase(cfd.fieldName as string);
			let filterProperty = toFilterProperty(
				domainName,
				fieldName,
				fieldType,
				cfd.fieldLabel as string,
				cfd.defaultValueOrOptionalValues
			);
			filterProperty.isCustomField = true;
			filterProperties.push(filterProperty);
		});
		return filterProperties;
	};

	toCustomFieldItems = (
		customFieldDefinitions: CustomFieldDefinition[],
		customAware: CustomFieldAware
	): ICustomFieldItem[] => {
		let customFields: ICustomFieldItem[] = [];
		_.forEach(customFieldDefinitions, (item: CustomFieldDefinition) => {
			let value = UtilService.getCustomFieldValue(customAware, item.fieldName as string);
			customFields.push({
				fieldName: item.fieldName as string,
				label: UtilService.coalesce([item.fieldLabel, item.fieldName]),
				isRequired: item.isRequired as boolean,
				isImportKey: item.isImportKey as boolean,
				originalValue: value,
				value: value,
				fieldDataType: item.fieldType as CustomFieldDataType,
				defaultValueOrOptionalValues: item.defaultValueOrOptionalValues,
				position: item.position,
				appliedToTable: item.appliedToTable
			});
		});
		return customFields;
	};
}

export const customDefinedFieldService = new CustomDefinedFieldService();
