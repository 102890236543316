import { EntityContact, EntityContact_EntityType, Extractor, ContactCardType, FacilityExtractorDto } from '@rcp/types';
import React from 'react';
import { SingleCheckbox } from 'src/components/widgets';
import { ContactCardBody, ContactModal } from 'src/features/contacts';
import { localizationService, Resource, urlService } from 'src/services';
import _ from 'lodash';
interface Props {
	entityContact: EntityContact;
	deviceSendNotice?: boolean;
	switchSendNotice: (deviceContact: EntityContact, checked: boolean) => void;
	reload: () => void;
	device?: Extractor;
	contactCardType: ContactCardType;
	id: number;
	extractorList?: FacilityExtractorDto[];
	isDeviceDetailsPage?: boolean;
	showSpan?: boolean;
}

export const SendNoticeContactCardContainer: React.FC<Props> = props => {
	const [isToggleEditContactModal, setToggleEditContactModal] = React.useState(false);

	const switchContactSendNotices = (e: any) => {
		const { checked } = e.target;
		props.switchSendNotice(props.entityContact, checked);
	};

	const resetModalStateAndReload = () => {
		setToggleEditContactModal(false);
		props.reload();
	};

	const getDeviceList = () => {
		let devices: FacilityExtractorDto[] = [];
		if (props.entityContact.sendNoticeIds && props.extractorList) {
			props.entityContact.sendNoticeIds.forEach(id => {
				if (props.extractorList) {
					let device = props.extractorList.find(a => a.ExtractorId == id && a.SendCleaningNotices);
					if (device) {
						devices.push(device);
					}
				}
			});
		}
		devices = _.sortBy(devices, 'DeviceNumber');
		return devices;
	};

	return (
		<div className="tab-col mb-5">
			{props.entityContact.entityType === EntityContact_EntityType.FogDevice && (
				<p>
					{props.showSpan ? (
						<span>{props.entityContact.contactTypeCode}</span>
					) : (
						<a
							className="mr-2"
							href="#/"
							onClick={e => {
								e.preventDefault();
								setToggleEditContactModal(true);
							}}>
							{props.entityContact.contactTypeCode}
						</a>
					)}

					{props.entityContact.entityType === EntityContact_EntityType.FogDevice && (
						<span className="text-muted">{localizationService.getLocalizedString('extractor.device')}</span>
					)}
				</p>
			)}
			{props.entityContact.contactId ? (
				props.entityContact.entityType === EntityContact_EntityType.FogFacility && (
					<div className="mb-3">
						{props.showSpan ? (
							<span>{props.entityContact.contactTypeCode}</span>
						) : (
							<a
								href="#/"
								onClick={e => {
									e.preventDefault();
									setToggleEditContactModal(true);
								}}>
								{props.entityContact.contactTypeCode}
							</a>
						)}

						{props.entityContact.isPrimary && props.contactCardType === ContactCardType.FogFacility && (
							<span className="text-muted ml-2">
								{localizationService.getLocalizedString('facility.primary')}
							</span>
						)}
					</div>
				)
			) : (
				<div className="mb-3">
					<span>
						{localizationService.getLocalizedString('screen.labels.facility')}
						{props.entityContact.entityType === EntityContact_EntityType.Hauler ? (
							<span className="ml-0 text-muted">
								&nbsp;
								{localizationService.getLocalizedString('extractor.lastHauler')}
							</span>
						) : (
							<>
								{' - '}
								{_.get(props.entityContact, 'facilityNumber', '[FacilityNumber]')}
							</>
						)}
					</span>
				</div>
			)}
			<ContactCardBody
				contact={props.entityContact}
				isDeviceDetailsPage={props.isDeviceDetailsPage}
				showSpan={props.showSpan}
			/>

			{props.contactCardType === ContactCardType.FogDevice &&
				props.entityContact.entityType != EntityContact_EntityType.Hauler && (
					<SingleCheckbox
						id={`contactSendNotices-${props.id}`}
						name={`contactSendNotices-${props.id}`}
						key={props.id}
						label={localizationService.getLocalizedString('extractor.sendNoticesCheckBoxLabel')}
						checked={props.entityContact.sendNotices}
						onChange={switchContactSendNotices}
						isDisabled={!props.deviceSendNotice}
						className="div-checkbox"
					/>
				)}
			{!_.isEmpty(getDeviceList()) &&
				props.extractorList &&
				props.contactCardType == ContactCardType.FogFacility && (
					<p className="noticeList">
						{props.entityContact.entityType == EntityContact_EntityType.FogDevice
							? localizationService.getLocalizedString(
									'facility.receivingNotices',
									_.toString(getDeviceList().length),
									_.toString(_.size(props.entityContact.entityGroupIds) + 1)
							  )
							: localizationService.getLocalizedString(
									'facility.receivingNotices',
									_.toString(getDeviceList().length),
									_.toString(props.extractorList.length)
							  )}
						&#58;
						{getDeviceList().map(device => (
							<>
								&nbsp;
								<a href={urlService.getReactAuthorityResourceUrl(Resource.Devices, device.ExtractorId)}>
									{device.DeviceNumber}
								</a>
							</>
						))}
					</p>
				)}

			{isToggleEditContactModal && (
				<ContactModal
					close={() => {
						setToggleEditContactModal(false);
					}}
					entityContact={props.entityContact}
					reload={resetModalStateAndReload}
				/>
			)}
		</div>
	);
};
