import React, { useEffect, useState } from 'react';
import {
	DateInput,
	PopoverModal,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput
} from 'src/components/widgets';
import { apiService, localizationService, urlService, validationService } from 'src/services';
import { ApiEndpoints, ApiError, CustomFieldDefinition, DropDownOption, ImportUniqueKey } from '@rcp/types';
import { alertService } from 'src/redux';
import SelectOptionEditor, { SelectOption } from './select-option-editor';
import './custom-field-editor.scss';
import { RestApi } from 'src/services/rest.api';
import { ReactComponent as SvgInfo } from '../../../../../assets/img/info-circle-solid.svg';
import { DropdownValueCountDto } from '@rcp/types/src';
import _ from 'lodash';
export enum EditorMode {
	Add,
	Update,
	UpdateOptions
}

export enum CustomFieldType {
	Date = 'Date',
	Link = 'Link',
	Text = 'Text',
	TextArea = 'TextArea',
	SingleValueDropDown = 'SingleValueDropDown',
	Number = 'Number'
}

interface Props {
	description: string;
	mode: EditorMode;
	show: boolean;
	table: string;
	onClosed?: () => void;
	onSuccessCallback?: (customField: CustomFieldDefinition, forCreation: boolean) => void;
	defaultLabel?: string;
	customField?: CustomFieldDefinition | null;
	embeddedMode?: boolean;
}

interface CustomFieldEditorState {
	show: boolean;
	fieldType: string;
	fieldLabel: string;
	fieldName: string;
	isRequired: boolean;
	fieldLabelError: string;
	fieldNameError: string;
	urlError: string;
	isSelectType: boolean;
	defaultValueOrOptionalValues: string;
	previewOptions: DropDownOption[];
	inUsedValues: string[];
	previewValue: string;
	selectOptionError: string;
	dropdownValueCounts: DropdownValueCountDto[];
	originalSelectOptions: SelectOption[];
	newSelectOptions: SelectOption[];
}

interface DropdownValueChangeTrackingItemDto {
	value: string;
	changeTrackingId: number;
}

interface DropdownValueChangeTrackingDto {
	originalValues: DropdownValueChangeTrackingItemDto[];
	newValues: DropdownValueChangeTrackingItemDto[];
}

const CustomFieldEditor: React.FunctionComponent<Props> = props => {
	let initialState: CustomFieldEditorState = {
		show: props.show,
		fieldType: 'Text',
		fieldLabel: '',
		fieldName: '',
		isRequired: false,
		fieldLabelError: '',
		fieldNameError: '',
		isSelectType: false,
		previewValue: '',
		previewOptions: [],
		defaultValueOrOptionalValues: '',
		inUsedValues: [],
		urlError: '',
		selectOptionError: '',
		dropdownValueCounts: [],
		originalSelectOptions: [],
		newSelectOptions: []
	};

	const restApi = new RestApi<ImportUniqueKey>(`Settings/${ApiEndpoints.CustomFieldDefinitions}`);

	const [state, setState] = useState(initialState);

	const isUpdateMode = props.mode === EditorMode.Update || props.mode === EditorMode.UpdateOptions;

	useEffect(() => {
		if (props.defaultLabel) {
			const fieldName = props.defaultLabel.replace(/[^a-zA-Z0-9]/gi, '');
			setState({ ...state, fieldLabel: props.defaultLabel, fieldName: fieldName });
		}
	}, [props.defaultLabel]);

	useEffect(() => {
		if (isUpdateMode && props.customField) {
			initialState = { ...initialState, ...props.customField };
			if (props.customField && props.customField.fieldType === 'SingleValueDropDown') {
				let optionValues = props.customField.defaultValueOrOptionalValues;
				let url = `${urlService.getApiBaseUrlWithProgram()}/Settings/${ApiEndpoints.CustomFieldDefinitions}/${
					props.customField.customFieldId
				}/optionValues?inUseOnly=true`;
				apiService.getResource<string[]>(url).then(inUsedValues => {
					initialState = {
						...initialState,
						isSelectType: true,
						previewOptions: getPreviewOptions(optionValues),
						inUsedValues
					};
					setState(initialState);
				});
			} else {
				setState(initialState);
			}
		}
	}, [props.customField]);

	const getPreviewOptions = (optionValue: string | undefined): DropDownOption[] => {
		if (!optionValue) {
			return [];
		}
		return optionValue.split(',').map(s => {
			return { label: s, value: s };
		});
	};

	const title =
		props.mode === EditorMode.Add
			? localizationService.getLocalizedString('authoritySetting.addCustomField')
			: localizationService.getLocalizedString('authoritySetting.editCustomField');

	const typeOptions: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.Date'),
			value: 'Date'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.Link'),
			value: 'Link'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.Number'),
			value: 'Number'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.SingleValueDropDown'),
			value: 'SingleValueDropDown'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.Text'),
			value: 'Text'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.editorTypeLabel.TextArea'),
			value: 'TextArea'
		}
	];

	const onUpdateSuccess = (value: any) => {
		if (props.onSuccessCallback) {
			props.onSuccessCallback(value, false);
		}
		alertService.addSuccess(
			localizationService.getLocalizedString('authoritySetting.customFieldUpdateSucceed', state.fieldName)
		);
		onClose();
	};

	const onCreateSuccess = (value: any) => {
		if (props.onSuccessCallback && _.isArray(value.items)) {
			props.onSuccessCallback(value.items[0], true);
		}
		alertService.addSuccess(localizationService.getLocalizedString('authoritySetting.customFieldAddSucceed'));
		onClose();
	};

	const onError = (reason: any) => {
		if (reason instanceof ApiError) {
			if (
				reason.body &&
				reason.body.internalMessage &&
				reason.body.internalMessage.toLowerCase().indexOf('violation of unique key') >= 0
			) {
				setState({
					...state,
					fieldNameError: localizationService.getLocalizedString(
						'authoritySetting.duplicateName',
						state.fieldName
					)
				});
			}

			alertService.addError(reason.body.internalMessage);
		} else {
			alertService.addError(reason.toString());
		}
	};

	const prepareChangeTrackingObject = (): DropdownValueChangeTrackingDto => {
		let orginalValues: DropdownValueChangeTrackingItemDto[] = state.originalSelectOptions.map(option => {
			return { value: option.value, changeTrackingId: option.changeTrackingId };
		});
		let newValues: DropdownValueChangeTrackingItemDto[] = state.newSelectOptions.map(option => {
			return { value: option.value, changeTrackingId: option.changeTrackingId };
		});

		let dto: DropdownValueChangeTrackingDto = {
			originalValues: orginalValues,
			newValues: newValues
		};
		return dto;
	};

	const onSaveClicked = async () => {
		if (!state.fieldLabel) {
			const errorText = localizationService.getLocalizedString('authoritySetting.customFieldLabelRequired');
			alertService.addError(errorText);
			setState({ ...state, fieldLabelError: errorText });
			return;
		}

		if (!state.fieldName) {
			const errorText = localizationService.getLocalizedString('authoritySetting.customFieldNameRequired');
			alertService.addError(errorText);
			setState({ ...state, fieldNameError: errorText });
			return;
		}

		if (state.isSelectType && state.previewOptions.length < 2) {
			const errorText = localizationService.getLocalizedString('authoritySetting.customFieldOptionError');
			setState({ ...state, selectOptionError: errorText });
			alertService.addError(errorText);
			return;
		}
		let dto = prepareChangeTrackingObject();
		if (isUpdateMode && props.customField && props.customField.customFieldId) {
			restApi
				.patchOne(props.customField.customFieldId, {
					partialDto: {
						fieldLabel: state.fieldLabel,
						isRequired: state.isRequired,
						defaultValueOrOptionalValues: state.defaultValueOrOptionalValues
					},
					dropdownValueChangeTrackingDto: dto
				})
				.then(value => onUpdateSuccess(value), onError);
		} else if (props.mode === EditorMode.Add) {
			restApi
				.createOne({
					fieldLabel: state.fieldLabel,
					fieldName: state.fieldName,
					fieldType: state.fieldType,
					isRequired: state.isRequired,
					appliedToTable: props.table,
					defaultValueOrOptionalValues: state.defaultValueOrOptionalValues
				})
				.then(value => onCreateSuccess(value), onError);
		}
	};

	const onCancelClicked = () => {
		onClose();
	};

	const onClose = () => {
		setState({ ...state, show: false });
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const footer = () => {
		if (props.customField && props.customField.widgetCount && props.customField.widgetCount > 0)
			return (
				<div className="row">
					<div className="col-1">
						<div className="mb-1">
							<SvgInfo height="15" color="#0083d7" />
						</div>
					</div>
					<div className="col">
						{localizationService.getLocalizedString(
							'authoritySetting.fieldInUseByWidget',
							props.customField.widgetCount.toString(),
							props.customField.widgetCount > 1 ? 's' : ''
						)}
					</div>

					<div className="ml-auto">
						<button id="btnSave" className="btn ai-save ml-2" onClick={onSaveClicked}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</button>
						<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</button>
					</div>
				</div>
			);
	};

	const onFieldTypeSelected = (e: any) => {
		const { value } = e.target;
		setState({
			...state,
			fieldType: value,
			isSelectType: value === 'SingleValueDropDown',
			previewValue: '',
			previewOptions: getPreviewOptions(state.defaultValueOrOptionalValues)
		});
	};

	const onFieldLabelChanged = (e: any) => {
		const { value } = e.target;
		setState({ ...state, fieldLabel: value, fieldLabelError: '' });
	};

	const onFieldNameChanged = (e: any) => {
		let { value } = e.target;
		if (value) {
			value = value.replace(/[^a-zA-Z0-9]/gi, '');
		}

		setState({ ...state, fieldName: value, fieldNameError: '' });
	};

	const onRequiredChanged = (e: any) => {
		const { checked } = e.target;
		setState({ ...state, isRequired: checked });
	};

	const onFieldNameFocus = (e: any) => {
		if ((!state.fieldName && state.fieldLabel) || props.embeddedMode === true) {
			const fieldName = state.fieldLabel.replace(/[^a-zA-Z0-9]/gi, '');
			setState({ ...state, fieldName: fieldName });
		}
	};

	const onSelectOptionChanged = (value: string, originalOptions: SelectOption[], newOptions: SelectOption[]) => {
		let newPreviewOptions = getPreviewOptions(value);

		setState({
			...state,
			defaultValueOrOptionalValues: value,
			previewValue: '',
			previewOptions: newPreviewOptions,
			originalSelectOptions: originalOptions,
			newSelectOptions: newOptions
		});
	};

	const onSelectPreviewChanged = (evt: any) => {
		const { value } = evt.target;
		setState({ ...state, previewValue: value });
	};

	const getSelectPreview = () => {
		return (
			<SingleSelectDropdown
				value={state.previewValue}
				label={getPreviewLabel()}
				id="previewSelect"
				onChange={onSelectPreviewChanged}
				isRequired={state.isRequired}
				className="form-group col-sm-6"
				name="previewSelect"
				options={state.previewOptions}
			/>
		);
	};

	const validatePreviewUrl = (url: string) => {
		let error = '';
		if (url) {
			if (validationService.validateUrl(url, {}, 'preview')) {
				error = '';
			} else {
				error = localizationService.getLocalizedString('screen.validationMessage.invalidUrlFormat');
			}
		}

		return error;
	};

	const getPreviewLabel = () => {
		return state.fieldLabel ? state.fieldLabel : localizationService.getLocalizedString('authoritySetting.label');
	};

	const getTextInputPreview = () => {
		return (
			<>
				<TextInput
					id="previewText"
					name="previewText"
					label={getPreviewLabel()}
					isRequired={state.isRequired}
					onChange={onPreviewValueChanged}
					value={state.previewValue}
					className="form-group col-sm-6"
				/>
			</>
		);
	};

	const onPreviewValueChanged = (evt: any) => {
		let { value } = evt.target;
		setState({
			...state,
			previewValue: value,
			previewOptions: getPreviewOptions(state.defaultValueOrOptionalValues)
		});
	};

	const onPreviewUrlValueChanged = (evt: any) => {
		setState({
			...state,
			urlError: validatePreviewUrl(state.previewValue)
		});
	};

	const getTextAreaPreview = () => {
		return (
			<>
				<TextAreaInput
					id="previewTextArea"
					name="previewTextArea"
					isRequired={state.isRequired}
					label={getPreviewLabel()}
					onChange={onPreviewValueChanged}
					value={state.previewValue}
					className="form-group col-sm-6"
				/>
			</>
		);
	};

	const getDatePreview = () => {
		return (
			<>
				<DateInput
					id="previewDate"
					name="previewDate"
					isRequired={state.isRequired}
					label={getPreviewLabel()}
					value={undefined}
					onChange={onPreviewValueChanged}
					className="form-group col-sm-6"
				/>
			</>
		);
	};

	const getNumberPreview = () => {
		return (
			<>
				<TextInput
					id="previewNumber"
					name="previewNumber"
					type="number"
					isRequired={state.isRequired}
					label={getPreviewLabel()}
					onChange={onPreviewValueChanged}
					value={state.previewValue}
					className="form-group col-sm-6"
				/>
			</>
		);
	};

	const getLinkPreview = () => {
		return (
			<>
				<TextInput
					error={state.urlError}
					id="previewLink"
					name="previewLink"
					isRequired={state.isRequired}
					label={getPreviewLabel()}
					onBlur={onPreviewUrlValueChanged}
					onChange={onPreviewValueChanged}
					value={state.previewValue}
					className="form-group col-sm-6"
				/>
			</>
		);
	};

	const getPreview = () => {
		return (
			<div className="preview pb-3">
				<label>{localizationService.getLocalizedString('authoritySetting.preview')}</label>
				<div className="d-flex flex-column align-items-center grey-border pt-2 pb-2">
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.Number) && getNumberPreview()}
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.Text) && getTextInputPreview()}
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.TextArea) && getTextAreaPreview()}
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.SingleValueDropDown) &&
						getSelectPreview()}
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.Link) && getLinkPreview()}
					{String.equalCaseInsensitive(state.fieldType, CustomFieldType.Date) && getDatePreview()}
				</div>
			</div>
		);
	};

	const isSelectionEditable = () => {
		// @ts-ignore
		if (isUpdateMode && props.customField && props.customField.isImportKey) {
			return false;
		}
		return true;
	};

	const onSelectOptionTextChanged = (value: string) => {
		setState({ ...state, selectOptionError: '' });
	};

	const onSetOptions = (originalOptions: SelectOption[], newOptions: SelectOption[]) => {
		setState({
			...state,
			originalSelectOptions: originalOptions,
			newSelectOptions: newOptions
		});
	};

	const defaultEditor = () => {
		return (
			<>
				<p> {props.description}</p>

				<TextInput
					id="fieldLabel"
					name="fieldLabel"
					value={state.fieldLabel}
					isRequired={true}
					onChange={onFieldLabelChanged}
					onBlur={onFieldNameFocus}
					error={state.fieldLabelError}
					isDisabled={props.mode === EditorMode.UpdateOptions}
					label={localizationService.getLocalizedString('authoritySetting.label')}
				/>

				<TextInput
					id="fieldName"
					name="fieldName"
					value={state.fieldName}
					isDisabled={isUpdateMode || props.embeddedMode === true}
					onChange={onFieldNameChanged}
					isRequired={true}
					error={state.fieldNameError}
					label={localizationService.getLocalizedString('authoritySetting.databaseFieldName')}
				/>

				{props.mode !== EditorMode.UpdateOptions && (
					<SingleCheckbox
						id="isRequired"
						name="isRequired"
						checked={state.isRequired}
						htmlLabel={localizationService.getLocalizedString('authoritySetting.required')}
						onChange={onRequiredChanged}
					/>
				)}

				<SingleSelectDropdown
					id="fieldType"
					name="fieldType"
					noEmptyOption={true}
					isDisabled={isUpdateMode}
					selfOrder={true}
					value={state.fieldType}
					label={localizationService.getLocalizedString('authoritySetting.type')}
					onChange={onFieldTypeSelected}
					options={typeOptions}
				/>
				{state.isSelectType && (
					<SelectOptionEditor
						editable={isSelectionEditable()}
						onChanged={onSelectOptionChanged}
						label={state.fieldLabel}
						textError={state.selectOptionError}
						onTextChanged={onSelectOptionTextChanged}
						defaultValueOrOptionalValues={state.defaultValueOrOptionalValues}
						inUsedValues={state.inUsedValues}
						dropdownValueCounts={state.dropdownValueCounts}
						onSetNewOptions={onSetOptions}
					/>
				)}
				{getPreview()}
			</>
		);
	};

	if (props.embeddedMode === true) {
		return (
			<>
				{defaultEditor()}
				<hr />
				<div className="text-center">
					<button className={`btn ai-save`} onClick={onSaveClicked}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={onCancelClicked}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	}
	return (
		<PopoverModal
			showModal={state.show || props.show}
			title={title}
			footer={footer()}
			save={onSaveClicked}
			cancel={onCancelClicked}>
			{defaultEditor()}
		</PopoverModal>
	);
};

export default CustomFieldEditor;
