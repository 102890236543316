import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import { ApplicationState, FogFacilityState, saveFogFacility, loadAuthorityTags, alertService } from 'src/redux';
import { FogFacility } from '@rcp/types';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { urlService, validationService, localizationService } from 'src/services';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
}

interface StateProps extends FogFacilityState {}

interface DispatchProps {
	saveFogFacilityOperations: (
		facilityId: number,
		facilityToUpdate: FogFacility,
		callbackOnSuccess: () => void
	) => void;
	loadAuthorityTags: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface OperationsForm {
	hoursOfOperation?: string;
	seatingCapacity?: number;
	squareFootage?: number;
	numberMealsServed?: number;
	numberOfEmployees?: number;

	hoursOfOperationError?: string;
	seatingCapacityError?: string;
	squareFootageError?: string;
	numberMealsServedError?: string;
	numberOfEmployeesError?: string;
}

const initialOperationsForm: OperationsForm = {
	hoursOfOperation: '',
	seatingCapacity: undefined,
	squareFootage: undefined,
	numberMealsServed: undefined,
	numberOfEmployees: undefined
};

const OperationsModalComp: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState(initialOperationsForm);

	const setFormStateFromProps = (fogFacility: FogFacility) => {
		let newState: OperationsForm = {
			hoursOfOperation: fogFacility.hoursOfOperation || '',
			seatingCapacity: fogFacility.seatingCapacity,
			squareFootage: fogFacility.squareFootage,
			numberMealsServed: fogFacility.numberMealsServed,
			numberOfEmployees: fogFacility.numberOfEmployees
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.facility);
	}, [props.facility]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const validateOperationsDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;
		validationService.validateIntegerNumberField(newState, 'seatingCapacity', 'seatingCapacityError');
		validationService.validateIntegerNumberField(newState, 'squareFootage', 'squareFootageError');
		validationService.validateIntegerNumberField(newState, 'numberMealsServed', 'numberMealsServedError');
		validationService.validateIntegerNumberField(newState, 'numberOfEmployees', 'numberOfEmployeesError');

		isFormValid = !validationService.hasError(
			newState,
			'seatingCapacityError',
			'squareFootageError',
			'numberMealsServedError',
			'numberOfEmployeesError'
		);
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const saveOperations = () => {
		if (!validateOperationsDataForSave()) {
			return;
		}

		let facilityToUpdate: FogFacility = {
			hoursOfOperation: formState.hoursOfOperation,
			seatingCapacity: formState.seatingCapacity,
			squareFootage: formState.squareFootage,
			numberMealsServed: formState.numberMealsServed,
			numberOfEmployees: formState.numberOfEmployees
		};

		props.saveFogFacilityOperations(urlService.getFogFacilityId(), facilityToUpdate, () => {
			props.loadAuthorityTags();
		});
		props.toggle();
	};

	const cancelSaveOperations = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('facility.editOperations')}
				save={saveOperations}
				cancel={cancelSaveOperations}>
				<TextInput
					label={localizationService.getLocalizedString('facility.hoursOfOperation')}
					id="hoursOfOperation"
					name="hoursOfOperation"
					onChange={changeFormState}
					value={formState.hoursOfOperation}
					error={formState.hoursOfOperationError}
				/>
				<TextInput
					label={localizationService.getLocalizedString('facility.seatingCapacity')}
					id="seatingCapacity"
					name="seatingCapacity"
					onChange={changeFormState}
					value={_.toString(formState.seatingCapacity)}
					error={formState.seatingCapacityError}
				/>
				<TextInput
					label={localizationService.getLocalizedString('facility.squareFootage')}
					type="text"
					id="squareFootage"
					name="squareFootage"
					onChange={changeFormState}
					value={_.toString(formState.squareFootage)}
					error={formState.squareFootageError}
				/>
				<TextInput
					label={localizationService.getLocalizedString('facility.numberMealsServed')}
					type="text"
					id="numberMealsServed"
					name="numberMealsServed"
					onChange={changeFormState}
					value={_.toString(formState.numberMealsServed)}
					error={formState.numberMealsServedError}
				/>
				<TextInput
					label={localizationService.getLocalizedString('facility.numberOfEmployees')}
					type="text"
					id="numberOfEmployees"
					name="numberOfEmployees"
					onChange={changeFormState}
					value={_.toString(formState.numberOfEmployees)}
					error={formState.numberOfEmployeesError}
				/>
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacilityOperations: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate, callbackOnSuccess)),
		loadAuthorityTags: () => dispatch(loadAuthorityTags())
	};
};

export const OperationsModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(OperationsModalComp);
