import { HaulerPortalFacilities } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, Resource } from '../../../../services';

export const haulerPortalSelectedFacilitiesSlice = new RestSlice<HaulerPortalFacilities>(
	localizationService.getLocalizedString('haulerPortal.selectFacility.facilities'),
	Resource.HaulerFacilities
);

export const haulerPortalSelectedFacilitiesRestSliceReducer = haulerPortalSelectedFacilitiesSlice.rests.reducer;
