import React from 'react';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { Incident, IncidentContributor } from '@rcp/types';
import { IncidentModal } from 'src/components/authority/fog/incidents/Incident-modal';
import { incidentsSlice } from '../../../incidents';
import { useDispatch } from 'react-redux';

interface OwnProps {
	incidentContributor: IncidentContributor;
	callBackAfterSave?: () => void;
	callBackAfterDelete?: () => void;
}
type Props = OwnProps;

export const TimelineEventIncident: React.FC<Props> = props => {
	const [showIncidentModal, setShowIncidentModal] = React.useState(false);
	const [incident, setIncident] = React.useState<Incident | null>(null);
	const dispatch = useDispatch();

	const getContributorStatus = () => {
		if (props.incidentContributor.isKnownContributor) {
			return localizationService.getLocalizedString('incidents.knownContributor');
		}

		return localizationService.getLocalizedString('incidents.suspectedContributor');
	};

	const onIncidentTypeClick = (e: any) => {
		e.preventDefault();

		dispatch(
			incidentsSlice.fetchOne(props.incidentContributor.incidentId!, fetchedIncident => {
				setIncident(fetchedIncident);
				toggleIncidentModal();
			})
		);
	};

	const toggleIncidentModal = () => [setShowIncidentModal(!showIncidentModal)];

	return (
		<>
			<div>
				<div className="pointer-cursor">
					{`${getContributorStatus()} to `}
					<a href="#/" onClick={onIncidentTypeClick} id="timelineIncident">
						{props.incidentContributor.incidentTypeCode}{' '}
					</a>
					incident
				</div>
			</div>
			{showIncidentModal && (
				<IncidentModal
					isAddModal={false}
					incident={incident!}
					showModal={showIncidentModal}
					setShowModal={toggleIncidentModal}
					saveIncidentCallback={props.callBackAfterSave}
					deleteIncidentCallback={props.callBackAfterDelete}></IncidentModal>
			)}
		</>
	);
};
