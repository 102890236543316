import React, { FC, useEffect, useState } from 'react';
import { localizationService, urlService } from 'src/services';
import { Step1, Step2, Step3 } from './steps';
import Stepper, { Step } from 'src/components/widgets/stepper';
import { AddGeneralNoticeSteps, FeatureNames, RouteProps } from '@rcp/types';
import { History } from 'history';
import { useDispatch } from 'react-redux';
import { generalNoticeTemplateSlice } from '../general-notice-template.slice';
import { useReduxSelector } from 'src/redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props extends RouteProps {
	history: History;
}

const GeneralNoticeStepper: FC<Props> = props => {
	const dispatch = useDispatch();
	let locationState = props.history.location.state;
	const { STEP1, STEP2, STEP3 } = AddGeneralNoticeSteps;

	useEffect(() => {
		const noticeTemplateId =
			props.match.params.noticeTemplateId || (locationState && (locationState as any).noticeTemplateId);
		noticeTemplateId && dispatch(generalNoticeTemplateSlice.fetchOne(noticeTemplateId));
		return () => {
			dispatch(generalNoticeTemplateSlice.resetAll());
		};
	}, [props.match.params.noticeTemplateId]);

	return !urlService.isAdministrator() ? (
		<AccessDeniedPage />
	) : (
		<div className="page">
			<div className="page-header">
				<h1>
					{localizationService.getLocalizedString(
						`authoritySetting.noticesSettings.general.${
							props.match.params.noticeTemplateId ? 'editGeneralNotice' : 'addGeneralNotice'
						}`
					)}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main-settings">
					<section>
						<Stepper vertical initialStep={props.match.params.noticeTemplateId ? STEP3 : STEP1}>
							<Step
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.chooseName'
								)}
								stepId={STEP1}>
								<Step1 />
							</Step>
							<Step
								stepId={STEP2}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.editContent'
								)}>
								<Step2 />
							</Step>
							<Step
								stepId={STEP3}
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.previewAndSave'
								)}>
								<Step3 />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export default GeneralNoticeStepper;
