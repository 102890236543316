import React, { FC, useEffect, useState } from 'react';
import { History } from 'history';

import './import.scss';
import { localizationService, navigateTo, urlService } from 'src/services';
import Stepper from '../../components/widgets/stepper';
import Step from '../../components/widgets/stepper/step';
import {
	SelfImportStep1,
	SelfImportStep2,
	SelfImportStep3,
	SelfImportStep4,
	SelfImportStep5,
	SelfImportSteps
} from './self-import-steps';

import './self-import.scss';
import _ from 'lodash';
import { RootState } from 'src/redux';
import { SelfImportFieldMappingSideEditor } from './self-import-steps/field-mapping-side-editor';
import { useSelector } from 'react-redux';

interface Props {
	history: History;
}

export const SelfImport: FC<Props> = props => {
	const selfImportState = useSelector((state: RootState) => state.selfImport).current;
	const [showSidePanel, setShowSidePanel] = useState(false);

	const importHistoryPath = urlService.getSettingMenuPath('/import/history');

	let dynamicWidthClass = showSidePanel ? 'max-setting-width-when-side-editor-shown' : 'w-100';
	let mainEl = document.querySelector('main');
	if (mainEl) {
		if (showSidePanel) {
			mainEl.classList.add('pr-0');
		} else {
			mainEl.classList.remove('pr-0');
		}
	}

	useEffect(() => {
		setShowSidePanel(selfImportState.showSidePanel);
	}, [selfImportState.showSidePanel]);

	return (
		<>
			<div className={`d-flex w-100 flex-column flex-lg-row ${showSidePanel ? 'h-100' : ''}`}>
				<div className={`d-flex flex-column ${dynamicWidthClass}`}>
					<div className={`import-setting-card ${showSidePanel ? 'pr-4' : ''}`}>
						<div className="page-header">
							<h1>{localizationService.getLocalizedString('screen.labels.import')}</h1>
							<a
								className="mx-auto"
								href={importHistoryPath}
								onClick={(e: any) => navigateTo(props.history, importHistoryPath, e)}>
								{localizationService.getLocalizedString('screen.labels.importHistory')}
							</a>
						</div>
						<div className="main">
							<section className="min-height-180px px-2 mb-0 import-file">
								<Stepper vertical={true} initialStep={SelfImportSteps.STEP1}>
									<Step
										stepId={SelfImportSteps.STEP1}
										title={localizationService.getLocalizedString('import.steps.step1')}>
										<SelfImportStep1 />
									</Step>
									<Step
										stepId={SelfImportSteps.STEP2}
										title={localizationService.getLocalizedString('import.steps.step2')}>
										<SelfImportStep2 />
									</Step>
									<Step
										stepId={SelfImportSteps.STEP3}
										title={localizationService.getLocalizedString('import.steps.step3')}>
										<SelfImportStep3 />
									</Step>
									<Step
										stepId={SelfImportSteps.STEP4}
										title={localizationService.getLocalizedString('import.steps.step4')}>
										<SelfImportStep4 />
									</Step>
									<Step
										stepId={SelfImportSteps.STEP5}
										title={localizationService.getLocalizedString('import.steps.step5')}>
										<SelfImportStep5 />
									</Step>
								</Stepper>
							</section>
						</div>
					</div>
				</div>
				{showSidePanel && (
					<div className="side-editor d-flex flex-column">
						<SelfImportFieldMappingSideEditor />
					</div>
				)}
			</div>
		</>
	);
};

export default SelfImport;
