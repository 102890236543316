import * as ApiTypes from '@rcp/types';

export interface IppAASAuthoritySettingsState {
	data: ApiTypes.IppAASAuthoritySettingsData;
	isSaveButtonEnabled:boolean;
	notification:Object;
}

export const initialIppAASAuthoritySettingsState: IppAASAuthoritySettingsState = {
	data: {
		allowedFailedPasswordAttempts: '3',
		allowedFailedKBQAttempts: '3',
		invitationExpiryHrsAfterSending: '72',
		daysBeforePasswordChange: '180',
		passwordsCountInPasswordHistory: '10',
		timezone: '6'
	},
	isSaveButtonEnabled:true,
	notification:{msg:'', type:'success'}
};
