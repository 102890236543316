import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { localizationService, localStorageService, tokenService, urlService } from 'src/services';
import { ApplicationState, UserProfileState, loadUserProfile } from 'src/redux';
import _ from 'lodash';
import { UserAvatar } from './user-avatar';
import { closeMainSidebar } from '../triggerMainSidebarHamburgerClick';
import { authenticationService } from 'src/components/account/authentication-service';
import { Translate } from 'src/components/widgets/translate/translator';

interface DispatchProps {
	getUserProfile: () => Promise<void>;
}

interface Props extends DispatchProps {
	userProfile?: UserProfileState;
	program: string;
}

const HeaderUser: React.FC<Props> = props => {
	const history = useHistory();
	const [hidePortalDirector, setHidePortalDirector] = useState<boolean>(true);

	React.useEffect(() => {
		props.getUserProfile();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		setHidePortalDirector(localStorage.getItem('hidePortalDirector') === 'true' ? true : false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('hidePortalDirector')]);

	const signOut = (e: any) => {
		e.preventDefault();
		authenticationService.logout();
	};

	const getProgramSwitcher = (currentProgram: string, issuedPrograms?: string[]) => {
		let mfaEnabledPrograms = tokenService.getTokenOrDefault().mfaEnabledPrograms || '';
		let lastVisitedProgram = localStorageService.getLastProgram();
		let isMFAEnabled = mfaEnabledPrograms.split(',').includes(lastVisitedProgram.toLowerCase());
		let showProfile = isMFAEnabled && !urlService.isPortalDirectorPage();
		let isInviteScreen = urlService.isHaulerInviteScreen();
		const getPortalDirectorUrl = () => {
			let portalDirectorUrl = urlService.getPortalDirectorUrl();
			let url =
				portalDirectorUrl + '/?' + urlService.toQueryString(JSON.parse(localStorage.getItem('token') || ''));
			return url;
		};
		const getProfileUrl = () => {
			let basePath = window.location.pathname
				.split('/')
				.splice(0, 3)
				.join('/');
			let profileUrl =
				_.toLower(window.location.pathname).includes('/ipp/authority/') ||
				_.toLower(window.location.pathname).includes('/industry')
					? basePath + '/profile'
					: basePath + 'authority/profile';
			return profileUrl;
		};
		return (
			<>
				{!hidePortalDirector && !isInviteScreen && (
					<div>
						<a
							href={getPortalDirectorUrl()}
							className="switcher cursor-pointer"
							onClick={(e: any) => {
								e.preventDefault();
								window.location.assign(getPortalDirectorUrl());
							}}>
							<Translate>
								{localizationService.getLocalizedString('screen.labels.switchToPortalDirector')}
							</Translate>
						</a>
					</div>
				)}

				{showProfile && (
					<div className="mt-3">
						<a
							href={getProfileUrl()}
							className="switcher cursor-pointer"
							onClick={(e: any) => {
								e.preventDefault();
								history.push(getProfileUrl());
								closeMainSidebar();
							}}>
							{localizationService.getLocalizedString('ipp.accountPortal.profile')}
						</a>
					</div>
				)}
			</>
		);
	};

	let userProfile = props.userProfile as UserProfileState;
	let userName = _.trim(`${userProfile.userProfile.firstName} ${userProfile.userProfile.lastName}`);
	return userProfile.userProfile.firstName ? (
		<div>
			<a href="#/" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Open menu">
				<UserAvatar userProfile={userProfile.userProfile} />
			</a>
			<div className="dropdown-menu">
				<p className="ai-popover-item">
					<Translate>{localizationService.getLocalizedString('screen.labels.signedInAs')}</Translate>
					{userName}
				</p>
				<div className="ai-popover-item">
					{getProgramSwitcher(props.program, userProfile.userProfile.issuedPrograms)}
					<a className="signOut mt-3" href="#/" onClick={signOut}>
						<Translate>{localizationService.getLocalizedString('screen.labels.signOut')}</Translate>
					</a>
				</div>
				<hr></hr>
				<div className="ai-popover-item">
					<a
						href={
							urlService.isIppIndustryPortal()
								? urlService.getIppIndustryUrl('TermsAndConditions')
								: `/TermsAndConditions`
						}
						className="text-muted">
						<Translate>{localizationService.getLocalizedString('screen.labels.terms')}</Translate>
					</a>
					<span className="text-muted px-2">&middot;</span>
					<a
						href={
							urlService.isIppIndustryPortal()
								? urlService.getIppIndustryUrl('PrivacyPolicy')
								: `/PrivacyPolicy`
						}
						className="text-muted">
						<Translate>{localizationService.getLocalizedString('screen.labels.privacyPolicy')}</Translate>
					</a>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return {
		userProfile: state.userProfile
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		getUserProfile: () => dispatch(loadUserProfile())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUser);
