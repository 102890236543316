import React, { Component, RefObject } from 'react';
import { localizationService } from 'src/services';
import './drag-and-drop.scss';

interface Props {
	handleDrop: (files: any, e?: any) => void;
	className?: string;
	isValid?: boolean;
	dragMessage: string;
}

interface State {
	dragging: boolean;
	isValid: boolean;
}

class DragAndDrop extends Component<Props, State> {
	private dropRef: RefObject<any>;
	private dragCounter: number;

	constructor(props: Props) {
		super(props);
		this.dropRef = React.createRef();
		this.state = {
			dragging: false,
			isValid: false
		};
		this.dragCounter = 0;
	}

	handleDrag = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	handleDragIn = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter++;
		if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
			this.setState({ dragging: true });
		}
	};
	handleDragOut = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.dragCounter--;
		if (this.dragCounter === 0) {
			this.setState({ dragging: false });
		}
	};
	handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ dragging: false });
		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			this.props.handleDrop(e.dataTransfer.files, e);
			e.dataTransfer.clearData();
			this.dragCounter = 0;
		}
	};
	componentDidMount() {
		let div: HTMLElement = this.dropRef.current;
		div.addEventListener('dragenter', this.handleDragIn);
		div.addEventListener('dragleave', this.handleDragOut);
		div.addEventListener('dragover', this.handleDrag);
		div.addEventListener('drop', this.handleDrop);
	}
	componentWillUnmount() {
		let div = this.dropRef.current;
		div.removeEventListener('dragenter', this.handleDragIn);
		div.removeEventListener('dragleave', this.handleDragOut);
		div.removeEventListener('dragover', this.handleDrag);
		div.removeEventListener('drop', this.handleDrop);
	}

	render() {
		return (
			<div
				className={`drag-container ${this.props.className} ${!this.props.isValid ? 'invalid' : ''}`}
				ref={this.dropRef}>
				{this.state.dragging && (
					<div className="dragging">
						<div className="dragging-instruction">
							<div>{this.props.dragMessage}</div>
						</div>
					</div>
				)}

				{this.props.children}
			</div>
		);
	}
}
export default DragAndDrop;
