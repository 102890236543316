import React, { Component } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FaPhone, FaCommentAlt, FaSearch, FaEnvelope } from 'react-icons/fa';
import { GreaseTrap } from 'src/components/svg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle } from 'reactstrap';
import { History } from 'history';
import {
	FogFacilityState,
	loadAuthorityTags,
	AuthorityTagsState,
	ApplicationState,
	FacilityContactListState,
	JurisdictionListState,
	loadFacilityContact,
	saveFacilityInspection,
	UserProfileState,
	saveFacilityPumpOutEvent,
	deleteFacilityPumpOutEvent,
	loadCurrentFogFacility,
	alertService,
	InspectionsState,
	loadFacilityViolations,
	resetFacilityData,
	ExtractorState,
	resetFacilityAttachment,
	loadExtractorList
} from 'src/redux';
import { urlService, apiService, Resource, localizationService, DateUtilService, navigateTo } from 'src/services';
import * as ApiTypes from '@rcp/types';

import { FacilityAddress } from './facility-details-address/facility-details-address';
import { FacilityInfoTab } from './facility-details-info-tab';
import { FacilityTags } from './tags/facility-tags';

import { FacilityComment } from './facility-comment';
import { FacilityCallLog } from './facility-callLog';
import { FacilityAttachments } from '../facility-attachments/facility-attachments';
import { Timeline } from './timeline';

import { PrimaryContactRightPanelComponent } from './contact-tab-page/primary-contact-component';

import { FacilitySendEmail } from './send-email';
import { InspectionEvent, CleaningEvent, LetterTemplateCategory, FeatureNames, EventType } from '@rcp/types';
import { InspectionEventModal } from './inspection-event-modal';
import { FacilityActionDropdown } from './action-dropdown-option';
import _ from 'lodash';
import { NotFound } from 'src/features/home/not-found';
import { IssueEnforcementModal } from './issue-enforcement-modal';
import { reloadTimelineEnforcements } from './timeline/timeline-service';

import { CloseSvg } from '../../../../svg';

import './facility-details.scss';
import { FacilityStartInspectionDropdown } from './start-inspection-dropdown-options';
import { RecordViolationForm } from '../../../..';
import { FacilityLinks } from './links/facility-links';
import { CleaningModal } from 'src/components/authority/fog/cleaning';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { timelineEventsSlice } from 'src/features/timeline/timeline-events.slice';
import SelectDevicesModal from './select-facility-devices';
import { SelectEvents } from './event/select-event-modal';
import { availableEventTypeSlice } from './event/available-event-type.slice';
import { ContractedHaulerRightPanel } from './hauler/contracted-hauler-component';
import { RiskScoreRightPanel } from './risk-score/risk-score-component';
interface DispatchProps {
	loadCurrentFogFacility: (callbackOnSuccess?: () => void, callbackOnError?: () => void) => void;
	loadAuthorityTags: () => void;
	loadExtractorList: () => void;
	loadFacilityContact: (facilityId: number, includeDeviceContacts: boolean) => void;
	saveFacilityInspection: (inspection: InspectionEvent) => Promise<any>;
	saveFacilityPumpOutEvent: (pumpOutEvent: CleaningEvent) => Promise<any>;
	deletePumpOutEvent: (pumpOutEvent: CleaningEvent) => Promise<any>;
	loadFacilityViolations: (startDate?: string, endDate?: string) => void;
	resetFacilityData: () => void;
	resetFacilityAttachment: () => void;
	loadEventTypes: () => void;
	resetEventTypes: () => void;
}

interface StateProps extends FogFacilityState, FacilityContactListState, JurisdictionListState, InspectionsState {
	extractor: ExtractorState;
	authorityTags: AuthorityTagsState;
	userProfile: UserProfileState;
	inspections: InspectionsState;
	eventTypes: EventType[];
}

interface Props extends StateProps, DispatchProps, ApiTypes.RouteProps {
	history: History;
	location: any;
}

class FacilityDetails extends Component<Props, any> {
	constructor(props: Props) {
		super(props);

		this.state = {
			deleteFacilityModal: false,
			isEnforcementModalOpened: false,
			isToggleInspectionModal: false,
			inspectionModalInspection: null,
			IsOpenPumpOutModalModal: false,
			commentModal: false,
			facilityCommentAnchor: HTMLElement,
			callLogModal: false,
			callLogAnchor: HTMLElement,
			sendEmailModal: false,
			sendEmailAnchor: HTMLElement,
			timelineFilterMenuAnchor: HTMLElement,
			showInvalidUrlMessage: false,
			isStartInspection: false,
			isRecordViolationFormModalOpened: false,
			isScheduleCleaningModalOpened: false,
			isEnterCleaningModalOpened: false,
			searchParams: '',
			isAddEventModal: false
		};
	}

	componentDidMount() {
		this.ensureDataFetched();
		timelineEventsSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/${Resource.TimelineEvents}`
		);
		const facilityId = urlService.getFogFacilityId();
		availableEventTypeSlice.setApiUrlPath(`${Resource.FogFacilities}/${facilityId}/${Resource.EventTypes}`);
		this.props.loadEventTypes();
	}

	componentWillUnmount() {
		this.props.resetFacilityData();
		this.props.resetFacilityAttachment();
		this.props.resetEventTypes();
	}

	ensureDataFetched() {
		this.props.loadCurrentFogFacility(
			() => {
				this.props.loadAuthorityTags();
				this.props.loadFacilityContact(urlService.getFogFacilityId(), true);
				this.props.loadExtractorList();
			},
			() => {
				this.setState({
					showInvalidUrlMessage: true
				});
			}
		);
	}

	clickCancelOnDeleteFacility = () => {
		this.setState({
			deleteFacilityModal: !this.state.deleteFacilityModal
		});
	};

	closeEnforcementModal = () => {
		this.setState({
			isEnforcementModalOpened: !this.state.isEnforcementModalOpened
		});
	};

	toggleInspectionModal = () => {
		this.setState({
			isToggleInspectionModal: !this.state.isToggleInspectionModal
		});
	};

	togglePumpOutEventModal = () => {
		this.setState({
			IsOpenPumpOutModalModal: !this.state.IsOpenPumpOutModalModal
		});
	};

	openInspectionAction = (inspection: InspectionEvent) => {
		this.setState({
			isToggleInspectionModal: true,
			inspectionModalInspection: inspection
		});
	};

	onLogCallDivClicked = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.setState({
			callLogAnchor: e && e.target,
			commentModal: false,
			callLogModal: !this.state.callLogModal,
			sendEmailModal: false
		});
	};

	onCommentDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.setState({
			facilityCommentAnchor: e && e.target,
			commentModal: !this.state.commentModal,
			callLogModal: false,
			sendEmailModal: false
		});
	};

	onSendEmailDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		this.setState({
			sendEmailAnchor: e && e.target,
			commentModal: false,
			callLogModal: false,
			sendEmailModal: !this.state.sendEmailModal
		});
	};

	onTimelineSearchClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		this.setState({
			timelineFilterMenuAnchor: e && e.target
		});
	};

	toggleCallLogModal = () => {
		this.setState({
			callLogModal: !this.state.callLogModal
		});
	};

	toggleCommentModal = () => {
		this.setState({
			commentModal: !this.state.commentModal
		});
	};

	toggleSendEmailModal = () => {
		this.setState({
			sendEmailModal: !this.state.sendEmailModal
		});
	};

	deleteFacility = () => {
		let facilitiesUrl = urlService.getReactResourceUrl(Resource.FogFacilities);
		let facilityUrl = urlService.getAuthorityResourceApiUrl(
			Resource.FogFacilities,
			this.props.match.params.fogFacilityId
		);
		let history = this.props.history;

		apiService
			.deleteResource(facilityUrl)
			.then(() => {
				navigateTo(history, facilitiesUrl);
				alertService.addSuccess(localizationService.getLocalizedString('facility.deleteSuccessMessage'));
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
	};

	clickOnIssueEnforcement = () => {
		this.setState({
			isEnforcementModalOpened: true
		});
	};

	clickOnNewInspectionEvent = (isStartInspection: boolean = false) => {
		let event = {
			creatorUserProfileId: _.get(this.props.userProfile, 'userProfile.userProfileId')
		} as InspectionEvent;
		this.setState(
			{
				...this.state,
				isStartInspection: isStartInspection
			},
			() => {
				this.openInspectionAction(event);
			}
		);
	};

	clickOnNewPumpOutEvent = () => {
		this.setState({
			IsOpenPumpOutModalModal: true
		});
	};

	closeRecordViolationModal = () => {
		this.setState({
			isRecordViolationFormModalOpened: false
		});
	};

	openRecordViolationModal = () => {
		this.setState({
			isRecordViolationFormModalOpened: true
		});
	};

	openScheduleCleaningModal = () => {
		this.setState({
			isScheduleCleaningModalOpened: true
		});
	};

	toggleScheduleCleaningModal = () => {
		this.setState({
			isScheduleCleaningModalOpened: !this.state.isScheduleCleaningModalOpened
		});
	};

	openEnterCleaningModal = () => {
		this.setState({
			isEnterCleaningModalOpened: true
		});
	};

	toggleEnterCleaningModal = () => {
		this.setState({
			isEnterCleaningModalOpened: !this.state.isEnterCleaningModalOpened
		});
	};

	openAddEventModal = () => {
		if (!this.state.isAddEventModal) {
			this.props.loadEventTypes();
		}
		if (this.props.eventTypes.length > 0) {
			this.setState({
				isAddEventModal: !this.state.isAddEventModal
			});
		} else {
			alertService.addInfo(localizationService.getLocalizedString('events.noScheduledEvents'));
		}
	};

	static getDerivedStateFromProps(props: Props, state: any) {
		const { sendMail } = urlService.toQueryDictionary(window.location.search);
		if (sendMail === 'true') {
			return {
				...state,
				sendEmailModal: true,
				searchParams: props.location.search
			};
		}
		return { ...state };
	}

	showExtractorScheduleCleaning = () =>
		this.props.extractor.facilityExtractorList.filter(
			(device: ApiTypes.Extractor) => device.isActive && !device.nextCleaningId
		).length > 0;

	showExtractorEnterCleaning = () =>
		this.props.extractor.facilityExtractorList.filter((device: ApiTypes.Extractor) => device.isActive).length > 0;

	render() {
		return (
			<>
				{_.isEmpty(this.props.facility) ? (
					<>{this.state.showInvalidUrlMessage && <NotFound />}</>
				) : (
					<div className="page">
						<div className="page-header">
							<h1>{localizationService.getLocalizedString('screen.labels.facility')}</h1>
						</div>
						<div className="d-flex flex-row top-action-div">
							<div className="form-row w-100 action-panel align-items-center">
								<div className="action-item" id="sendEmailLink" onClick={this.onSendEmailDivClick}>
									<FaEnvelope className="ai-slate" />
									<span>{localizationService.getLocalizedString('screen.labels.sendEmail')}</span>
								</div>
								<div
									className="action-item"
									id="sendNoticeLink"
									onClick={() => {
										navigateTo(
											this.props.history,
											`/fog/facilities/${this.props.facility.facilityId}/sendNotice`
										);
									}}>
									<FontAwesomeIcon icon={faPaperPlane} className="font-awesome-icon notice-icon" />
									<span>{localizationService.getLocalizedString('screen.labels.sendNotice')}</span>
								</div>
								<div className="action-item" id="logCallLink" onClick={this.onLogCallDivClicked}>
									<FaPhone className="ai-slate" />
									<span>{localizationService.getLocalizedString('screen.labels.logACall')}</span>
								</div>
								<div className="action-item" id="commentDiv" onClick={this.onCommentDivClick}>
									<FaCommentAlt className="ai-slate" />
									<span>{localizationService.getLocalizedString('screen.labels.comment')}</span>
								</div>
								{this.showExtractorEnterCleaning() && (
									<div
										className="action-item"
										id="enterCleaningDiv"
										onClick={this.openEnterCleaningModal}>
										<GreaseTrap hasSlateFill={true} />
										<span>{localizationService.getLocalizedString('extractor.enterCleaning')}</span>
									</div>
								)}
								<UncontrolledDropdown className="action-item-black">
									<DropdownToggle tag="a" className="btn">
										<FaSearch className="ai-slate" />
										<span id="startInspection">
											{localizationService.getLocalizedString('inspection.startInspection')}
										</span>
									</DropdownToggle>
									<FacilityStartInspectionDropdown
										clickOnOpenInspection={this.clickOnNewInspectionEvent}
										dueInspectionList={this.props.facilityInspections}
									/>
								</UncontrolledDropdown>

								<UncontrolledDropdown className="action-item-black">
									<DropdownToggle tag="a" className="btn" caret>
										{localizationService.getLocalizedString('screen.labels.action')}
									</DropdownToggle>
									<FacilityActionDropdown
										clickOnIssueEnforcement={this.clickOnIssueEnforcement}
										clickOnOpenInspection={this.clickOnNewInspectionEvent}
										clickOnNewPumpOut={this.clickOnNewPumpOutEvent}
										clickOnDeleteFacility={this.clickCancelOnDeleteFacility}
										openRecordViolationModal={this.openRecordViolationModal}
										openScheduleCleaningModal={this.openScheduleCleaningModal}
										showExtractorCleaning={this.showExtractorScheduleCleaning()}
										openAddEventModal={this.openAddEventModal}
									/>
								</UncontrolledDropdown>
							</div>
						</div>

						<div className="page-wrapper">
							<div className="main">
								<FacilityAddress
									withoutFacilityLink={false}
									endOfPageTitle={localizationService.getLocalizedString(
										'pageTitle.facilityDetailsEndOfTitle'
									)}
								/>
								<FacilityInfoTab />
								<Timeline userProfile={this.props.userProfile} />
							</div>

							<div className="sidebar">
								<PrimaryContactRightPanelComponent />
								<ContractedHaulerRightPanel
									contractedHauler={{
										haulerId: this.props.facility.contractedHaulerId as any,
										name: this.props.facility.contractedHaulerName as any,
										haulerNumber: this.props.facility.contractedHaulerNumber as any,
										phone: this.props.facility.contractedHaulerPhoneNumber as any,
										email: this.props.facility.contractedHaulerEmailAddress as any,
										addressLine1: this.props.facility.contractedHaulerAddressLine1,
										addressLine2: this.props.facility.contractedHaulerAddressLine2,
										addressCity: this.props.facility.contractedHaulerCityName,
										addressJurisdictionCode: this.props.facility.contractedHaulerJurisdictionCode,
										addressZipCode: this.props.facility.contractedHaulerZipCode,
										isPreferred: this.props.facility.isPreferredContractedHauler,
										isActive: this.props.facility.isActiveContractedHauler,
										isRemoved: this.props.facility.isRemovedContractedHauler
									}}
									lastHauler={{
										haulerId: this.props.facility.lastHaulerId as any,
										name: this.props.facility.lastHaulerName as any,
										haulerNumber: this.props.facility.lastHaulerNumber as any,
										phone: this.props.facility.lastHaulerPhoneNumber as any,
										email: this.props.facility.lastHaulerEmailAddress as any,
										addressLine1: this.props.facility.lastHaulerAddressLine1 as any,
										addressLine2: this.props.facility.lastHaulerAddressLine2 as any,
										addressCity: this.props.facility.lastHaulerCityName as any,
										addressJurisdictionCode: this.props.facility.lastHaulerJurisdictionCode as any,
										addressZipCode: this.props.facility.lastHaulerZipCode as any,
										isPreferred: this.props.facility.isPreferredLastHauler,
										isRemoved: this.props.facility.isRemovedLastHauler,
										isActive: this.props.facility.isActiveLastHauler
									}}
								/>
								<RiskScoreRightPanel
									riskScore={this.props.facility.riskScore as any}
									riskScoreNote={this.props.facility.riskScoreNote as any}
								/>
								<FacilityTags />
								<FacilityAttachments isReadOnly={false} facility={this.props.facility} />
								<FacilityLinks
									creatorUserProfileId={_.get(this.props.userProfile, 'userProfile.userProfileId')}
									inspectionId={this.props.match.params.inspectionId}
								/>
							</div>
						</div>

						{this.state.commentModal && (
							<FacilityComment
								show={this.state.commentModal}
								anchor={this.state.facilityCommentAnchor}
								toggle={this.toggleCommentModal}
							/>
						)}

						{this.state.callLogModal && (
							<FacilityCallLog
								show={this.state.callLogModal}
								anchor={this.state.callLogAnchor}
								toggle={this.toggleCallLogModal}
							/>
						)}

						{this.state.sendEmailModal && (
							<FacilitySendEmail
								show={this.state.sendEmailModal}
								anchor={this.state.sendEmailAnchor}
								toggle={this.toggleSendEmailModal}
								letterTemplateCategory={LetterTemplateCategory.FacilityEmail}
								searchParams={this.state.searchParams}
							/>
						)}

						<div>
							{this.state.deleteFacilityModal && (
								<Modal
									isOpen={true}
									toggle={this.clickCancelOnDeleteFacility}
									centered={true}
									backdrop={true}
									size="lg">
									<ModalHeader
										tag="h1"
										className="delete-modal-header"
										toggle={this.clickCancelOnDeleteFacility}
										close={
											<button className="close" onClick={this.clickCancelOnDeleteFacility}>
												<CloseSvg size={'12'} />
											</button>
										}>
										{localizationService.getLocalizedString('facility.deleteModalTitle')}
									</ModalHeader>
									<ModalBody>
										<div className="facility-editor">
											<p>
												{localizationService.getLocalizedString('facility.deleteModalMessage')}
											</p>
										</div>
									</ModalBody>
									<ModalFooter>
										<Button color="btn ai-delete ml-auto" onClick={this.deleteFacility}>
											{localizationService.getLocalizedString('screen.buttons.delete')}
										</Button>{' '}
										<Button color="btn ai-white" onClick={this.clickCancelOnDeleteFacility}>
											{localizationService.getLocalizedString('screen.buttons.cancel')}
										</Button>
									</ModalFooter>
								</Modal>
							)}
							{this.state.IsOpenPumpOutModalModal && (
								/*
									This props provided for this modal will be reconfigured in a future story
									to match the enter cleaning on the device grid/details page
								*/
								<CleaningModal
									facilityId={this.props.facility.facilityId as number}
									isEnterCleaning={true}
									cleaning={{} as CleaningEvent}
									isFacilityPage={true}
									isTimelineReloadRequired={true}
									dueDateIsRequired={false}
									completeDateIsRequired={true}
									modalToggleFunction={this.togglePumpOutEventModal}
								/>
							)}
							{this.state.isToggleInspectionModal && (
								<InspectionEventModal
									isToggle={this.state.isToggleInspectionModal}
									toggle={this.toggleInspectionModal}
									inspection={this.state.inspectionModalInspection}
									save={this.props.saveFacilityInspection}
									isStartInspection={this.state.isStartInspection}
								/>
							)}
							{this.state.isEnforcementModalOpened && (
								<IssueEnforcementModal
									closeModal={this.closeEnforcementModal}
									enforcement={{
										enforcementIssueDate: DateUtilService.getAuthorityTimezoneNow()
									}}
									saveCallback={reloadTimelineEnforcements}
									deleteCallback={reloadTimelineEnforcements}
								/>
							)}
							{this.state.isRecordViolationFormModalOpened && (
								<div>
									<RecordViolationForm
										closeModal={this.closeRecordViolationModal}
										facilityId={urlService.getFogFacilityId()}
										sourceType={'Facility'}
										saveCallback={() => {
											this.props.loadCurrentFogFacility();
											this.props.loadFacilityViolations();
										}}
										deleteCallback={() => this.props.loadCurrentFogFacility()}
									/>
								</div>
							)}
							{this.state.isScheduleCleaningModalOpened && (
								<>
									<CleaningModal
										isScheduleCleaning
										facilityId={this.props.facility.facilityId as number}
										isFacilityPage
										isTimelineReloadRequired
										isAddModal
										dueDateIsRequired={true}
										completeDateIsRequired={false}
										modalToggleFunction={this.toggleScheduleCleaningModal}
										showModal={true}
									/>
								</>
							)}
							{this.state.isEnterCleaningModalOpened && (
								<SelectDevicesModal
									showModal
									onCancel={this.toggleEnterCleaningModal}
									facilityId={this.props.facility.facilityId}
								/>
							)}
							{this.state.isAddEventModal && (
								<SelectEvents
									showModal={this.state.isAddEventModal}
									onCancel={() => {
										this.openAddEventModal();
									}}
									isFacilityDetailPage={true}
								/>
							)}
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	return {
		...state.fogFacility,
		...state.facilityContacts,
		...state.jurisdictions,
		...state.inspections,
		extractor: state.extractors,
		authorityTags: state.authorityTags,
		userProfile: state.userProfile,
		eventTypes: state.availableEventTypes.result
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadCurrentFogFacility: (callbackOnSuccess?: () => void, callbackOnError?: () => void) =>
			dispatch(loadCurrentFogFacility(callbackOnSuccess, callbackOnError)),
		loadAuthorityTags: () => dispatch(loadAuthorityTags()),
		loadFacilityContact: (facilityId: number, loadDeviceContacts: boolean) =>
			dispatch(loadFacilityContact(facilityId, loadDeviceContacts)),
		loadExtractorList: () => dispatch(loadExtractorList()),
		saveFacilityInspection: (inspection: InspectionEvent) => dispatch(saveFacilityInspection(inspection)),
		saveFacilityPumpOutEvent: (pumpOutEvent: CleaningEvent) => dispatch(saveFacilityPumpOutEvent(pumpOutEvent)),
		deletePumpOutEvent: (pumpOutEvent: CleaningEvent) => dispatch(deleteFacilityPumpOutEvent(pumpOutEvent)),
		loadFacilityViolations: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityViolations(startDate, endDate)),
		resetFacilityData: () => dispatch(resetFacilityData()),
		resetFacilityAttachment: () => dispatch(resetFacilityAttachment()),
		loadEventTypes: () => dispatch(availableEventTypeSlice.fetchAll()),
		resetEventTypes: () => dispatch(availableEventTypeSlice.resetAll())
	};
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityDetails);
