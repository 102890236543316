import React from 'react';
import { localizationService } from 'src/services';
import { History } from 'history';
import { ColumnField, FilterType, HaulerCleaningManifest, LocalStorageName, RouteProps } from '@rcp/types';
import { RootState } from 'src/redux';
import { haulerCleaningHistorySlice } from './cleaning-history.slice';
import { DataGrid, GridOption } from 'src/features';
import { CleaningManifestEditActionLink } from './cleaning-manifest-link-cell';
import { Utils } from 'src/services/utils';

interface Props extends RouteProps {
	history: History;
}

const HaulerCleaningHistory: React.FC<Props> = props => {
	const completeDateToLink = (props: any) => {
		const cleaningManifest = props.dataItem as HaulerCleaningManifest;
		return (
			<td>
				<CleaningManifestEditActionLink
					dateField={cleaningManifest.completeDate}
					cleaningManifest={cleaningManifest}
				/>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('haulerPortal.cleaningHistory.cleaningHistory'),
		prefix: 'haulerPortal.cleaningHistory',
		storageName: LocalStorageName.HaulerPortalCleaningHistory,
		showSearch: true,
		showEditColumnButton: true,
		searchHint: localizationService.getLocalizedString('haulerPortal.cleaningHistory.searchHint'),
		queryParameters: {},
		allColumns: [
			new ColumnField('completeDate', FilterType.Date, completeDateToLink),
			new ColumnField('facilityName'),
			new ColumnField('facilityAddressLine1'),
			new ColumnField('facilityCityName'),
			new ColumnField('deviceNumber'),
			new ColumnField('amountPumped'),
			new ColumnField('wasteTypeName'),
			new ColumnField('authorityName'),
			new ColumnField('submittedDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('submitterUserFullName')
		],
		defaultColumns: [
			'completeDate',
			'facilityName',
			'facilityAddressLine1',
			'facilityCityName',
			'deviceNumber',
			'amountPumped',
			'wasteTypeName'
		]
	};

	return (
		<div>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={haulerCleaningHistorySlice}
				restState={(state: RootState) => state.haulerCleaningHistory}
				gridOption={initialGridOption}
			/>
		</div>
	);
};

export default HaulerCleaningHistory;
