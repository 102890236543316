import React from 'react';
import { Violation } from '@rcp/types';
import { RecordViolationForm } from '.';

interface OwnProps {
	violation: Violation;
	deleteViolationCallBack?: () => void;
	getAllViolations?: () => void;
	isFromViolationGrid?: boolean;
}

export const ViolationEditActionLink: React.SFC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	return (
		<>
			<a href="#/" className="ai-link violationType-link" onClick={onEditClicked}>
				{props.violation.violationTypeTypeName}
			</a>
			{showModal && (
				<RecordViolationForm
					closeModal={() => setShowModal(false)}
					facilityId={props.violation.organizationId || 0}
					sourceId={props.violation.sourceId}
					sourceType={props.violation.sourceType}
					violation={props.violation}
					deleteCallback={props.deleteViolationCallBack}
					getAllViolations={props.getAllViolations}
					isFromViolationGrid={props.isFromViolationGrid}
				/>
			)}
		</>
	);
};
