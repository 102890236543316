import React from 'react';
import { useHistory, useParams } from 'react-router';
import { LocalStorageName, ColumnField, RouteProps, HaulerPortalFacilities } from '@rcp/types';
import { Dictionary } from 'lodash';
import { haulerFacilityDevicesSlice } from './devices-slice';
import './hauler-facility-detail.scss';
import { localizationService, Resource, urlService } from '../../../../../services';
import { MapMarker } from '../../../../authority/fog/facilities/facility-details/facility-details-address/map-marker';
import { GridOption, DataGrid } from '../../../../../features/data-grid/data-grid';
import { RootState } from '../../../../../redux';
import { BackArrow } from '../../../../widgets/back-arrow/back-arrow';
import { GreaseTrap } from '../../../..';
import SelectDevicesModal from '../hauler-submit-cleaning/select-devices';
import { useDispatch, useSelector } from 'react-redux';
import { haulerPortalFacilitiesSlice } from '../hauler-facilities-slice';
import _ from 'lodash';
import { HaulerDeviceModal } from './hauler-device-modal';

interface Props extends RouteProps {
	history: History;
}

const HaulerFacilityDetail: React.FC<Props> = (props: Props) => {
	const [showCleaningModal, setShowCleaningModal] = React.useState(false);
	const [showAddModal, setShowAddModal] = React.useState(false);
	const history = useHistory();
	const dispatch = useDispatch();
	const { id }: any = useParams();
	const { selected: facility } = useSelector((state: RootState) => state.haulerPortalFacilities);
	const { result: devices } = useSelector((state: RootState) => state.haulerFacilityDevices);

	React.useEffect(() => {
		if (urlService.isServicePortal()) {
			haulerFacilityDevicesSlice.setApiUrlPath(
				`${Resource.HaulerFacilities}/${Resource.FogFacilities}/${id}/${Resource.Extractors}`
			);
			haulerPortalFacilitiesSlice.setApiUrlPath(
				`${Resource.HaulerFacilities}/${Resource.FogFacilities}/facility`
			);
		} else {
			haulerFacilityDevicesSlice.setApiUrlPath(
				`${Resource.HaulerFacilities}/${Resource.FogFacilities}/${id}/${Resource.Extractors}`
			);
			haulerPortalFacilitiesSlice.setApiUrlPath(
				`${Resource.HaulerFacilities}/${Resource.FogFacilities}/facility`
			);
		}
		dispatch(haulerPortalFacilitiesSlice.fetchOne(id));
	}, []);

	const onCancel = () => {
		setShowCleaningModal(false);
	};

	const onShow = () => {
		setShowCleaningModal(true);
	};

	const handleReloadDevices = () => {
		haulerFacilityDevicesSlice.setApiUrlPath(
			`${Resource.HaulerFacilities}/${Resource.FogFacilities}/${id}/${Resource.Extractors}`
		);
		dispatch(haulerFacilityDevicesSlice.reload());
	};

	const getFacility = () => {
		const getFacilityAddress = (facility?: HaulerPortalFacilities) => {
			if (facility) {
				let { addressLine1, addressLine2 } = facility;
				return _.trim(_.toString(addressLine1) + ' ' + _.toString(addressLine2)).trim();
			} else {
				return '';
			}
		};
		const getFacilityCity = (facility?: HaulerPortalFacilities) => {
			if (facility) {
				let { cityName, jurisdictionName, zipCode } = facility;
				return _.trim(
					_.toString(cityName) + ' ' + _.toString(jurisdictionName) + ' ' + _.toString(zipCode)
				).trim();
			} else {
				return '';
			}
		};
		return (
			<section className="address-card">
				<div className="d-flex flex-row">
					<div className="pr-2">
						<div className="font-size-24px-regular mb-2">
							{localizationService.getLocalizedString(
								'haulerPortal.haulerFacilityDetail.facilityName',
								`${facility && facility.facilityName}`
							)}
							<span id="facilityNumber" className="facility-number">
							{facility && facility.referenceNumber}
							</span>
							{facility ? (
								<MapMarker
									streetAddress={getFacilityAddress(facility)}
									cityDetails={getFacilityCity(facility)}
								/>
							) : (
								<div>{localizationService.getLocalizedString('screen.labels.noAddressYet')}</div>
							)}
							
						</div>
					
					</div>
				</div>
			</section>
		);
	};

	const SubHeading = () => {
		return (
			<>
				{localizationService.getLocalizedString('haulerPortal.haulerFacilityDetail.devices')}
				{facility && facility.isSelected && (
					<span className="float-right">
						<button
							className="btn ai-new"
							id="btnAdd"
							onClick={e => {
								e.preventDefault();
								setShowAddModal(true);
							}}>
							{localizationService.getLocalizedString('haulerPortal.haulerFacilityDetail.addDevice')}
						</button>
					</span>
				)}
			</>
		);
	};

	const GetDevices = () => {
		const initialGridOptions: GridOption = {
			pageTitle: '',
			subHeading: <SubHeading />,
			subHeadingClass: 'font-size-24px-regular mb-2 d-block device',
			removePageHeader: true,
			prefix: 'haulerPortal.haulerFacilityDetail.extractor',
			storageName: LocalStorageName.HaulerPortalFacilityDevices,
			gridUrlLocalStorageName: LocalStorageName.HaulerPortalFacilityDevicesFilterUrl,
			sortOrderLocalStorageName: LocalStorageName.HaulerPortalFacilityDevicesSortOrder,
			queryParameters: { sort: 'deviceNumber asc' } as Dictionary<string>,
			defaultSort: [{ field: 'deviceNumber', dir: 'asc' }],
			allColumns: [
				ColumnField.newText('extractorDescription'),
				ColumnField.newText('extractorType'),
				ColumnField.newText('deviceNumber'),
				ColumnField.newText('cleanFrequencyCode'),
				ColumnField.newDate('nextCleaningDate'),
				ColumnField.newDate('lastCleaningDate')
			],
			defaultColumns: [
				'extractorDescription',
				'extractorType',
				'deviceNumber',
				'cleanFrequencyCode',
				'nextCleaningDate',
				'lastCleaningDate'
			],
			doNotApplyMaxTable: true
		};

		return (
			<DataGrid
				gridOption={initialGridOptions}
				restSlice={haulerFacilityDevicesSlice}
				match={props.match}
				history={history}
				restState={(state: RootState) => state.haulerFacilityDevices}
			/>
		);
	};

	return (
		<div className="page">
			<div className="page-header mb-3">
				<BackArrow />
				<h1 className="ml-2">
					{localizationService.getLocalizedString('haulerPortal.haulerFacilityDetail.facility')}
				</h1>
			</div>
			{urlService.isServicePortal() && facility && facility.isSelected && !!devices.length && (
				<div className="d-flex flex-row cursor-pointer px-2 mb-3">
					<div className="form-row w-100 action-panel align-items-center">
						<div className="action-item" id="submit-cleaning" onClick={onShow}>
							<GreaseTrap hasSlateFill={true} />
							<span className="ml-2">
								{localizationService.getLocalizedString(
									'haulerPortal.haulerFacilityDetail.submitCleaning'
								)}
							</span>
						</div>
					</div>
				</div>
			)}
			<div className="page-wrapper">
				<div className="main">
					{getFacility()}
					{GetDevices()}
				</div>
			</div>
			<SelectDevicesModal
				facility={facility ? facility : ({} as HaulerPortalFacilities)}
				organizationId={id}
				showModal={showCleaningModal}
				onCancel={onCancel}
			/>
			{showAddModal && facility && (
				<HaulerDeviceModal
					showModal={showAddModal}
					authorityOrganizationId={facility.creatorOrganizationId as number}
					toggle={() => setShowAddModal(!showAddModal)}
					facilityId={facility.facilityId as number}
					reloadDevices={handleReloadDevices}
				/>
			)}
		</div>
	);
};

export default HaulerFacilityDetail;
