import React, { useContext } from 'react';
import { History } from 'history';
import _ from 'lodash';
import { ApplicationState, useReduxDispatch, useReduxSelector } from '../../../redux/application-state';
import { PageLoadingActionType } from '../../../redux/page-loading/page-loading';
import { authenticationService } from '../authentication-service';
import { oidcAuthService } from './oidc-auth-service';
import { saveOidcUser } from './oidc-user-action';
import { navigateTo, urlService } from 'src/services';
import { SignIn } from '../authentication-component';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';

interface Props {
	history: History;
}

export const OidcCallback: React.FC<Props> = props => {
	const authenticationResult = useReduxSelector((state: ApplicationState) => state.authenticationResult);
	const dispatch = useReduxDispatch();
	const { language, setLanguage } = useContext(LanguageContext);

	React.useEffect(() => {
		//componentDidMount
		localStorage.removeItem('signInLandingFrom');

		let url = new URL(window.location.href);
		let code = url.searchParams.get('code');
		let state = url.searchParams.get('state');
		let sessionState = url.searchParams.get('session_state');
		if (code && state && sessionState) {
			//Callback after user clicked "Sign in with AQI Account"
			dispatch({ type: PageLoadingActionType.Start });
			try {
				if (oidcAuthService.isAuthenticated()) {
					oidcAuthService.getUser().then(user => {
						dispatch(saveOidcUser(user));
					});
				} else {
					oidcAuthService.signInRedirectCallback().then(user => {
						dispatch(saveOidcUser(user));
					});
				}
			} finally {
				dispatch({ type: PageLoadingActionType.Done });
			}
			return;
		}
		//This is used for AppHome landing to our webapp
		oidcAuthService.signInRedirect();
	}, []);

	React.useEffect(() => {
		let signInResult = authenticationResult.signInResult;
		if (signInResult) {
			if (!_.isEmpty(signInResult.registrationToken)) {
				const registrationUrl = `${urlService.getReactBaseUrl()}/register/${signInResult.registrationToken}`;
				navigateTo(props.history, registrationUrl);
				return;
			}
			if (!_.isEmpty(signInResult.accessToken)) {
				authenticationService.handleSignInResult(authenticationResult.signInResult, props.history, language);
			}
		}
	}, [authenticationResult.signInResult]);

	return (
		authenticationResult.isAuthenticating === false &&
		!_.isEmpty(authenticationResult.errors) && <SignIn history={props.history} />
	);
};
