import React, { useState } from 'react';
import * as ApiTypes from '@rcp/types';
import PreviewModal from 'src/components/widgets/preview-email-notice';
import {
	reloadTimelineEventsFromServer
} from './timeline-service';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { useHistory } from 'react-router';

interface OwnProps {
	event: ApiTypes.TimelineNotice | any;
}

type Props = OwnProps;

export const TimelineEventCleaningSentNotice: React.FC<Props> = props => {
	const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
	const {
		sentNoticeCleaningDetails,
		templateName,
		sendingNoticeCleaningDetails,
		failedNoticeCleaningDetails
	} = props.event;
	const allNoticeDetails = [sentNoticeCleaningDetails, sendingNoticeCleaningDetails, failedNoticeCleaningDetails];
	const noticeDetails = allNoticeDetails.find(noticeDetail => noticeDetail && noticeDetail.length > 0);

	const history = useHistory();

	const sentNoticeDisplayText = () => {
		if (noticeDetails.length === 1) {
			let { contactFirstName, contactLastName, contactFullName, companyName, to } = noticeDetails[0];
			return contactFullName || contactFirstName || contactLastName || companyName || to || '';
		} else {
			return `${noticeDetails.length} ${localizationService
				.getLocalizedString('screen.labels.contacts')
				.toLowerCase()}`;
		}
	};

	const getNoticeStatusForLocalization = () => {
		if (sentNoticeCleaningDetails && sentNoticeCleaningDetails.length) return ApiTypes.NoticeStatus.Sent;
		if (sendingNoticeCleaningDetails && sendingNoticeCleaningDetails.length) return ApiTypes.NoticeStatus.Sending;
		return ApiTypes.NoticeStatus.Fail;
	};

	return (
		<>
			<div>
				{getNoticeStatusForLocalization() == ApiTypes.NoticeStatus.Fail && (
					<span>{localizationService.getLocalizedString(`noticesHistory.numberOfFailureEmails`)}&nbsp;</span>
				)}

				<a
					onClick={(e: any) => {
						e.preventDefault();
						setShowPreviewModal(true);
					}}
					href="#/"
					className="ai-link"
					rel="noopener noreferrer">
					{templateName}
				</a>
				{urlService.getFogFacilityId() < 0 ? (
					<span>
						&nbsp;
						{localizationService.getLocalizedString(
							`screen.timeline.${getNoticeStatusForLocalization()}CleaningNoticeDescription`,
							`${sentNoticeDisplayText()}`
						)}
					</span>
				) : (
					<span>
						&nbsp;
						{localizationService.getLocalizedString(`screen.timeline.sentEmailFacilityTimeline`)}
						&nbsp;
						<a
							href="#/"
							className="ai-link cursor-pointer"
							onClick={(e: any) => {
								e.preventDefault();
								navigateTo(
									history,
									urlService.getReactResourceUrl(Resource.Devices, props.event.deviceId)
								);
							}}>
							{props.event.deviceNumber}
							&nbsp;
						</a>
						{localizationService.getLocalizedString(
							`screen.timeline.${getNoticeStatusForLocalization()}EmailToContacts`,
							`${sentNoticeDisplayText()} `
						)}
					</span>
				)}
			</div>

			{showPreviewModal && (
				<PreviewModal
					showModal={showPreviewModal}
					title={localizationService.getLocalizedString('screen.timeline.cleaningNotices')}
					emailDetails={noticeDetails}
					subtitle={localizationService.getLocalizedString('bulkNotice.cleaningNotice')}
					noticeStatus={getNoticeStatusForLocalization()}
					onToggle={() => {
						setShowPreviewModal(!showPreviewModal);
					}}
					refresh={reloadTimelineEventsFromServer}></PreviewModal>
			)}
		</>
	);
};
