import React, { FC } from 'react';
import * as LinkoTypes from '@rcp/types';
import { IppReportPackage, LocalStorageName } from '@rcp/types';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { ippReviewsSlice } from './reviews.slice';

interface Props<T> extends LinkoTypes.RouteProps {
	history: any;
	match: any;
	initialGridOption: GridOption;
	sortOrderLocalStorageName?: LocalStorageName;
}

const IppReportPackageReviewsComponent: FC<Props<IppReportPackage>> = props => {
	const reportReviewsState = (state: RootState) => state.ippReportPackageReviews;

	return (
		<>
			<DataGrid
				sortOrderLocalStorageName={props.sortOrderLocalStorageName}
				history={props.history}
				match={props.match}
				restSlice={ippReviewsSlice}
				restState={reportReviewsState}
				gridOption={props.initialGridOption}></DataGrid>
		</>
	);
};

export default IppReportPackageReviewsComponent;
