import {
	ApiFilterDefinition,
	ApiFilterProperty,
	Dictionary,
	DraftFilter,
	Filter,
	FilterObjectName,
	FilterOperator,
	FilterType,
	Incident,
	LocalStorageName,
	Lookup,
	LookupType,
	TreeViewDataItem
} from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import {
	applySavedFilter,
	areFiltersSame,
	digestQuickFilterAndCreateOrUpdateDraft,
	FilterItemsToList,
	getApiFilterDefinition,
	getInitialQuickFilterProperties,
	getSelectedValuesFromFilterProperty,
	IncidentQuickFilterEnum,
	QuickFilterProperties,
	SavedFilter
} from 'src/features/filter/quick-filter-service';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { alertService, GetFilters, useReduxDispatch } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { nameof } from 'ts-simple-nameof';
import { FloatList } from './float-list';
import { MappingFromApiFormat, MappingToApiFormat } from 'src/redux/filter/filterSerializer';
import { FilterItem } from 'src/services/data-types/filter-types';
import { QuickFilterDropdown } from 'src/features/filter/quick-filter-dropdown';
import uuid from 'uuid';
import { getInitialFilterItem } from 'src/features/filter/filter-row';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FilterItemCard } from '../filter-item-card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SearchInput } from '../../search-input';
import { SaveQuickFilterModal } from '../modals/save-quick-filter';
import { AdHocFilterItemCard } from '../ad-hoc-filter-item-card';

interface Props {
	handleClose: () => void;
	onApplyFilter: (savedFilterId: number | undefined, savedDraftFilterId: number | undefined) => void;
	onClearFilter: () => void;
	onFilterSaved: (filterId: number) => void;
	draftFilterId?: number;
	filterId?: number;
	facilityRiskMapFooterOverlay: JSX.Element;
	queryParameters: Dictionary<string>;
	setQueryParameters: (queryParameters: Dictionary<string>) => void;
	handleAdHocFilterDeletion: (adHocFilterKey: string) => void;
}

const initialQuickFilterSelectedIds: Dictionary<any[]> = {
	[IncidentQuickFilterEnum.Type]: [],
	[IncidentQuickFilterEnum.Status]: []
};

export const QuickFilterIncidentsSettings: React.FC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [quickFilterProperties, setQuickFilterProperties] = React.useState<QuickFilterProperties>(
		getInitialQuickFilterProperties('Incident')
	);
	const [quickFilterSelectedIds, setQuickFilterSelectedIds] = React.useState<Dictionary<any[]>>(
		initialQuickFilterSelectedIds
	);
	const [showSaveFilterModal, setShowSaveFilterModal] = React.useState(false);
	const toggleSaveFilterModal = () => {
		setShowSaveFilterModal(!showSaveFilterModal);
	};
	const [filterItems, setFilterItems] = React.useState<FilterItemsToList[]>([]);
	const [savedFilters, setSavedFilters] = React.useState([] as SavedFilter[]);
	const [searchTermForSavedFilters, setSearchTermForSavedFilters] = React.useState('');
	const [hasChange, setHasChange] = React.useState(false);
	const [adHocIncidentQueryParameters, setAdHocIncidentQueryParameters] = React.useState<Dictionary<string>>({});

	var allDomainObjects = filterService.getAllDomainObject();

	const [incidentTypeFilterOptions, setIncidentTypeFilterOptions] = React.useState<TreeViewDataItem[]>([
		{
			text: "",
			expanded: true,
			items: [],
			filterProperties: [
				{
					objectName: FilterObjectName.Incident,
					propertyName: nameof<Incident>(s => s.incidentTypeCode),
					propertyType: FilterType.Text,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	]);

	const [incidentStatusFilterOptions, setIncidentStatusFilterOptions] = React.useState<TreeViewDataItem[]>([
		{
			text: "",
			expanded: true,
			items: [],
			filterProperties: [
				{
					objectName: FilterObjectName.Incident,
					propertyName: nameof<Incident>(s => s.incidentStatusCode),
					propertyType: FilterType.Text,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	]);

	React.useEffect(() => {
		dispatch(GetFilters(FilterObjectName.Incident)).then(filters => {
			if (_.isArray(filters)) {
				let newSavedFilters = [] as SavedFilter[];

				filters.forEach(filter => {
					if (filter.filterId) {
						newSavedFilters.push({
							filterId: filter.filterId,
							filterName: filter.filterName
						});
					}
				});

				setSavedFilters(newSavedFilters);
			}
		});
	}, [dispatch]);

	React.useEffect(() => {
		if (props.queryParameters) {
			let queryParameterKeys = _.keys(props.queryParameters);
			let adhocIncidentQueryParameterKeys = queryParameterKeys.filter(i => {
				var key = _.toLower(i);
				return key.includes('incident.');
			});
			let queryParams = urlService.pickParameters(
				{ ...props.queryParameters },
				...adhocIncidentQueryParameterKeys
			);
			setAdHocIncidentQueryParameters(queryParams);
		}
	}, [props.queryParameters]);

	React.useEffect(() => {
		fetchFogIncidentTypes();
		fetchFogIncidentStatuses();

		//reset filter service
		let appliedDomains: { [index: string]: boolean } = {
			INCIDENT: true
		};

		filterService.init(
			FilterDomain.INCIDENT,
			FilterDomainName.incident,
			FilterObjectName.Incident,
			LocalStorageName.FacilityMap,
			appliedDomains
		);
	}, []);

	const fetchFogIncidentTypes = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentType
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentTypes => {
				let newIncidentTypeFilterOptions = [...incidentTypeFilterOptions];
				for (const element of fogIncidentTypes) {
					newIncidentTypeFilterOptions[0].items?.push({
						text: element.code as string,
						value: element.code
					});
				}
				setIncidentTypeFilterOptions(newIncidentTypeFilterOptions);
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const fetchFogIncidentStatuses = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentStatus
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentStatuses => {
				let newIncidentStatusFilterOptions = [...incidentStatusFilterOptions];
				for (let i = 0; i < fogIncidentStatuses.length; i++) {
					newIncidentStatusFilterOptions[0].items?.push({
						text: fogIncidentStatuses[i].code as string,
						value: fogIncidentStatuses[i].code
					});
				}
				setIncidentStatusFilterOptions(newIncidentStatusFilterOptions);
			})
			.catch(ex => alertService.addError(ex.message));
	};

	React.useEffect(() => {
		initializeQuickFilters();
	}, [props.draftFilterId, props.filterId]);

	const initializeQuickFilters = async () => {
		let newQuickFilterProperties = _.cloneDeep(quickFilterProperties);
		newQuickFilterProperties.draftFilterId = props.draftFilterId;
		let apiFilterDefinition: ApiFilterDefinition = await getApiFilterDefinition(
			newQuickFilterProperties,
			props.filterId,
			props.draftFilterId
		);
		newQuickFilterProperties.apiFilterDefinition = _.cloneDeep(apiFilterDefinition);

		populateQuickFilterSelectedIdsFromExistingFilter(newQuickFilterProperties);
	};

	const populateQuickFilterSelectedIdsFromExistingFilter = (newQuickFilterProperties: QuickFilterProperties) => {
		if (newQuickFilterProperties.draftFilterId || props.filterId) {
			let newQuickFilterSelectedIds = _.cloneDeep(quickFilterSelectedIds);

			let hasFilterGroup =
				newQuickFilterProperties.apiFilterDefinition &&
				newQuickFilterProperties.apiFilterDefinition.filterGroups &&
				newQuickFilterProperties.apiFilterDefinition.filterGroups.length > 0;

			if (hasFilterGroup) {
				_.uniqWith(newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties, _.isEqual);

				//incidentTypeFilterOptions
				if (
					incidentTypeFilterOptions[0] &&
					incidentTypeFilterOptions[0].filterProperties &&
					incidentTypeFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						incidentTypeFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == incidentTypeFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}
					newQuickFilterSelectedIds[IncidentQuickFilterEnum.Type] = selectedValue;
				}

				//incidentStatusFilterOptions
				if (
					incidentStatusFilterOptions[0] &&
					incidentStatusFilterOptions[0].filterProperties &&
					incidentStatusFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						incidentStatusFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == incidentStatusFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}

					newQuickFilterSelectedIds[IncidentQuickFilterEnum.Status] = selectedValue;
				}

				setQuickFilterSelectedIds(newQuickFilterSelectedIds);
				let newFilterItemsToList = _.cloneDeep(newQuickFilterProperties.apiFilterDefinition);
				var filterItemsString = MappingFromApiFormat(JSON.stringify(newFilterItemsToList));
				let filterItems = JSON.parse(filterItemsString as string);
				setFilterItems(
					filterItems.map((item: FilterItem) => {
						return { filterItem: item, isEdit: false };
					})
				);
			}
		} else {
			setQuickFilterSelectedIds(initialQuickFilterSelectedIds);
		}
		setQuickFilterProperties(newQuickFilterProperties);
	};

	const onChangeQuickFilter = async (
		checkedFilterProperties: ApiFilterProperty[],
		uncheckedFilterProperties?: ApiFilterProperty[],
		filterNumber?: IncidentQuickFilterEnum,
		selectedIds?: any[]
	) => {
		let newQuickFilterProperties = _.cloneDeep(quickFilterProperties);

		let matchValuesToFindIndexOfFilterProperty = false;

		await digestQuickFilterAndCreateOrUpdateDraft(
			newQuickFilterProperties,
			populateQuickFilterSelectedIdsFromExistingFilter,
			checkedFilterProperties,
			uncheckedFilterProperties,
			matchValuesToFindIndexOfFilterProperty,
			filterNumber
		);

		setHasChange(true);

		let newQuickFilterSelectedIds = _.cloneDeep(quickFilterSelectedIds);
		if (filterNumber && selectedIds) {
			newQuickFilterSelectedIds[filterNumber] = selectedIds;
			setQuickFilterSelectedIds(newQuickFilterSelectedIds);
		}
	};

	const onClearFilter = () => {
		let newQuickFilterProperties = { ...getInitialQuickFilterProperties('Incident') };
		setQuickFilterProperties(newQuickFilterProperties);
		setQuickFilterSelectedIds(initialQuickFilterSelectedIds);
		setFilterItems([]);
		setHasChange(false);
		props.onClearFilter();
	};

	const onSaveButtonClicked = async (filterName?: string) => {
		const saveDraftFilterUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Filters}/${
			Resource.SaveDraftQuickFilterAsFilter
		}`;
		let draftFilterToSave: DraftFilter = {
			draftFilterId: quickFilterProperties.draftFilterId,
			applyTo: quickFilterProperties.filterApplyTo,
			filterName: filterName as string,
			filterDefinition: JSON.stringify(quickFilterProperties.apiFilterDefinition)
		};
		await apiService
			.httpPost(saveDraftFilterUrl, draftFilterToSave)
			.then((savedFilter: Filter) => {
				props.onFilterSaved(savedFilter.filterId as number);
				props.handleClose();
				alertService.addSuccess(
					localizationService.getLocalizedString('alertMessages.savedSuccess', savedFilter.filterName)
				);
				setHasChange(false);
			})
			.catch(e => alertService.addError(e.message));
	};

	const quickFilterList = [
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={IncidentQuickFilterEnum.Type}
			label={localizationService.getLocalizedString('map.incidentType')}
			quickFilterOptions={incidentTypeFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[IncidentQuickFilterEnum.Type]}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={IncidentQuickFilterEnum.Status}
			label={localizationService.getLocalizedString('map.incidentStatus')}
			quickFilterOptions={incidentStatusFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[IncidentQuickFilterEnum.Status]}
		/>
	];

	const addFilterItemClicked = () => {
		let existingFilterItems = _.cloneDeep(filterItems);
		let newFilterItem: FilterItemsToList = { filterItem: getInitialFilterItem(), isEdit: true };
		existingFilterItems.unshift(newFilterItem);
		setFilterItems(existingFilterItems);
	};

	const handleFilterItemApply = async (originalFilterItem: FilterItem, updatedFilterItem: FilterItem) => {
		let originalFilterItemFilterProperties = MappingToApiFormat([originalFilterItem]).filterGroups[0]
			.filterProperties;
		let updatedFilterItemFilterProperties = MappingToApiFormat([updatedFilterItem]).filterGroups[0]
			.filterProperties;
		let filterPropertiesToUpdate = _.cloneDeep(updatedFilterItemFilterProperties);
		let filterPropertiesToDelete = [];
		if (
			originalFilterItemFilterProperties[0].objectName &&
			originalFilterItemFilterProperties[0].propertyName &&
			originalFilterItemFilterProperties[0].operator
		) {
			if (!areFiltersSame(originalFilterItemFilterProperties[0], updatedFilterItemFilterProperties[0])) {
				filterPropertiesToDelete.push(originalFilterItemFilterProperties[0]);
			}
		}

		await onChangeQuickFilter(filterPropertiesToUpdate, filterPropertiesToDelete);
	};

	const handleFilterItemDelete = async (filterItem: FilterItem) => {
		var apiProperties = MappingToApiFormat([filterItem]);
		await onChangeQuickFilter([], apiProperties.filterGroups[0].filterProperties);
	};

	const applySavedFilterSelection = async (filterId: number, filterName: string) => {
		setHasChange(true);
		await applySavedFilter(
			quickFilterProperties,
			populateQuickFilterSelectedIdsFromExistingFilter,
			filterId,
			filterName
		);
	};

	const handleApplyChangesOnMap = () => {
		setHasChange(false);
		props.onApplyFilter(undefined, quickFilterProperties.draftFilterId);
	};

	const handleClose = () => {
		if (hasChange) {
			handleApplyChangesOnMap();
		}
		props.handleClose();
	};

	const getChildren = () => {
		return (
			<>
				<div className="my-3 col-auto pl-0">
					<UncontrolledDropdown className="action-item-black">
						<DropdownToggle tag="a" className="a">
							<FontAwesomeIcon icon={faPlus} className="font-size-16px-regular" />
							<span id="addSavedFilters">
								{localizationService.getLocalizedString('map.addSavedFilter')}
							</span>
						</DropdownToggle>
						<DropdownMenu style={{ maxHeight: '300px', overflowY: 'auto' }}>
							<div className="col">
								<SearchInput
									ariaLabel="Search"
									searchClass="d-flex w-100"
									search={searchTerm => setSearchTermForSavedFilters(searchTerm)}
									onChange={e => setSearchTermForSavedFilters(e.target.value)}
									searchTerm={searchTermForSavedFilters}
								/>
							</div>
							{_.sortBy(savedFilters, f => f.filterName)
								.filter(x => _.includes(_.toLower(x.filterName), _.toLower(searchTermForSavedFilters)))
								.map(filter => (
									<DropdownItem
										style={{ whiteSpace: 'pre-wrap' }}
										key={uuid()}
										className=""
										id={`savedFilterId_${filter.filterId}`}
										name={`savedFilterId_${filter.filterId}`}
										onClick={() => applySavedFilterSelection(filter.filterId, filter.filterName)}>
										{filter.filterName}
									</DropdownItem>
								))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<div className="d-flex flex-row flex-wrap">
					{quickFilterList.map(x => {
						return x;
					})}
					<div className="my-2 col-auto pl-0">
						<button
							className="btn ai-white"
							id="addFilterItem"
							onClick={() => {
								addFilterItemClicked();
							}}>
							{localizationService.getLocalizedString('map.addFilterItem')}
						</button>
					</div>
				</div>
				{props.handleAdHocFilterDeletion &&
					_.keys(adHocIncidentQueryParameters).length > 0 &&
					_.toPairs(adHocIncidentQueryParameters).map(pair => {
						return (
							<AdHocFilterItemCard
								adHocFilterKey={pair[0]}
								adHocFilterValue={pair[1]}
								handleAdHocFilterItemDelete={props.handleAdHocFilterDeletion}
							/>
						);
					})}
				{filterItems.map(item => {
					let domainObject = allDomainObjects.find(i =>
						String.equalCI(i.name, item.filterItem.domainObject.name)
					);
					if (domainObject) {
						item.filterItem.domainObject = domainObject;
					} else {
						throw new Error(`Cannot find domainObject which name is ${item.filterItem.domainObject.name}.`);
					}
					return (
						<FilterItemCard
							key={uuid()}
							filterItemToList={item}
							handleFilterItemDelete={handleFilterItemDelete}
							handleFilterItemApply={handleFilterItemApply}
						/>
					);
				})}
			</>
		);
	};

	const getFooter = (): JSX.Element => {
		return (quickFilterProperties.draftFilterId || props.filterId || 0) > 0 ? (
			<div className="d-flex map-float-right-wrapper-footer">
				<div className="mx-auto justify-content-center">
					{hasChange && (
						<Button color="btn ai-action mr-2" onClick={() => handleApplyChangesOnMap()}>
							{localizationService.getLocalizedString('screen.buttons.apply')}
						</Button>
					)}
					{props.draftFilterId && (
						<Button color="btn ai-save mr-2" onClick={() => setShowSaveFilterModal(true)}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</Button>
					)}
					<Button color="btn ai-white" onClick={onClearFilter}>
						{localizationService.getLocalizedString('screen.buttons.clearAll')}
					</Button>
				</div>
			</div>
		) : (
			<></>
		);
	};

	return (
		<>
			<FloatList
				children={getChildren()}
				title={localizationService.getLocalizedString('map.incidentsFilters')}
				handleClose={handleClose}
				footer={getFooter()}
				showMobileTitle={true}
				facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
			/>
			{showSaveFilterModal && (
				<SaveQuickFilterModal
					draftFilterName={quickFilterProperties.filterName}
					showModal={showSaveFilterModal}
					onCancel={toggleSaveFilterModal}
					onSave={onSaveButtonClicked}
				/>
			)}
		</>
	);
};
