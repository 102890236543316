import React, { useState } from 'react';
import { localizationService } from 'src/services';

import './import.scss';
import Stepper, { Step } from '../../../../../widgets/stepper';
import { Step1, Step2, Step3, Step4, Step5 } from './import-steps';
import { ImportSampleSteps, RouteProps } from '@rcp/types';
import { History } from 'history';

interface Props extends RouteProps {
	history: History;
}

const IppIndustrySampleImportComponent: React.FC<Props> = props => {
	const [hideStepperHeader, setHideStepperHeader] = useState(false);

	return (
		<div className="import-setting-card">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('ipp.samples.importSamples.title')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section className="min-height-180px px-2 import-file">
						<Stepper
							initialStep={ImportSampleSteps.STEP1}
							className={hideStepperHeader ? 'hide-stepper-header' : ''}>
							<Step
								disabled={true}
								stepId={ImportSampleSteps.STEP1}
								title={localizationService.getLocalizedString(
									'ipp.samples.importSamples.selectDataProvider'
								)}>
								<Step1 history={props.history} />
							</Step>
							<Step
								disabled={true}
								stepId={ImportSampleSteps.STEP2}
								title={localizationService.getLocalizedString('ipp.samples.importSamples.selectFile')}>
								<Step2 />
							</Step>
							<Step
								disabled={true}
								stepId={ImportSampleSteps.STEP3}
								title={localizationService.getLocalizedString(
									'ipp.samples.importSamples.selectDataDefaults'
								)}>
								<Step3 history={props.history} />
							</Step>
							<Step
								disabled={true}
								stepId={ImportSampleSteps.STEP4}
								title={localizationService.getLocalizedString(
									'ipp.samples.importSamples.translateData'
								)}>
								<Step4 history={props.history} match={props.match} />
							</Step>
							<Step
								disabled={true}
								stepId={ImportSampleSteps.STEP5}
								title={localizationService.getLocalizedString(
									'ipp.samples.importSamples.previewImport'
								)}>
								<Step5 setHideStepperHeader={setHideStepperHeader} history={props.history} />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export default IppIndustrySampleImportComponent;
