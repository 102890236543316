import React, { useRef } from 'react';

import { localizationService, DateUtilService } from 'src/services';
import { DateInput, TimeInput } from 'src/components/widgets';
import { alertService } from 'src/redux';

interface OwnProps {
	showModal: boolean;
	onCommentChanged: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	onCallDateChanged: (e: any) => void;
	onCallTimeChanged: (e: any) => void;
	comment: string;
	callDate?: string;
	callTime?: string;
	callDateError: string;
	commentError: string;
}
type Props = OwnProps;
export const commentAndDateValidation = (
	comment: string,
	callDate: string | undefined,
	setCommentError: any,
	setCallDateError: any
): boolean => {
	let commentEmpty = false;
	if (comment === '' || comment.trim() === '') {
		let commentErrorTxt = localizationService.getLocalizedString(
			'screen.validationMessage.fieldValueIsRequired',
			'Field'
		);
		setCommentError(commentErrorTxt);
		alertService.addError(commentErrorTxt);
		commentEmpty = true;
	}

	let isDate = DateUtilService.isValidDateTime(callDate);
	if (!isDate) {
		let callDateErrorTxt = localizationService.getLocalizedString(
			'screen.validationMessage.fieldValueIsRequired',
			'Field'
		);
		setCallDateError(callDateErrorTxt);
		alertService.addError(callDateErrorTxt);
	}

	if (commentEmpty || !isDate) {
		return false;
	}
	return true;
};
export const prepareDateAndTimeStringForApiCall = (callDate: string, callTime: string | undefined): string => {
	let callDateStr = DateUtilService.toDisplayDate(callDate, 'YYYY-MM-DD');

	let callTimeStr = '00:00:00';
	if (callTime) {
		callTimeStr = DateUtilService.toDisplayTime(callTime, 'HH:mm:ss');
	}
	return DateUtilService.prepareDateStringForApiCall(`${callDateStr}T${callTimeStr}`);
};
export const CallLogInputFields: React.SFC<Props> = props => {
	const defaultTextEle = useRef<HTMLTextAreaElement>(null);

	return (
		<>
			<div className="form-group required">
				<label htmlFor="comment"> {localizationService.getLocalizedString('screen.labels.comment')} </label>
				<textarea
					className={`form-control` + (props.commentError ? ' is-invalid' : '')}
					id="comment"
					ref={defaultTextEle}
					defaultValue={props.comment}
					onChange={props.onCommentChanged}
				/>
				{props.commentError && <div className="invalid-feedback">{props.commentError}</div>}
			</div>
			<div className="form-row">
				<DateInput
					id="callDate"
					name="callDate"
					className="form-group col-6"
					label={localizationService.getLocalizedString('screen.labels.callDate')}
					isRequired={true}
					value={props.callDate}
					max={DateUtilService.getAuthorityTimezoneNow()}
					onChange={props.onCallDateChanged}
					error={props.callDateError}
				/>

				<TimeInput
					id="callTime"
					name="callTime"
					className="form-group col-6"
					value={props.callTime}
					label="Call Time"
					onChange={props.onCallTimeChanged}
				/>
			</div>
		</>
	);
};
