import React from 'react';

import { AttachmentOwnership, FogFacility } from '@rcp/types';
import { urlService } from 'src/services';

import './attachment.scss';
import { AttachmentContent } from '.';

interface FocusedAttachments {
	currentAttachment: AttachmentOwnership;
	previousAttachment?: AttachmentOwnership;
	nextAttachment?: AttachmentOwnership;
}

type Props = {
	attachments: AttachmentOwnership[];
	facility: FogFacility;
	returnToUrl: string;
	returnToLabel: string;
};

export const ReportAttachmentDetails: React.SFC<Props> = props => {
	const initialFocusedAttachments = {
		currentAttachment: {}
	} as FocusedAttachments;
	const [focusedAttachments] = React.useState(initialFocusedAttachments);

	const getAttachmentUrl = (attachment: AttachmentOwnership): string => {
		return urlService.getAttachmentNavigateUrl(attachment);
	};

	const getAttachmentIdFromUrl = (): number => {
		return urlService.getAttachmentIdFromUrl();
	};

	return (
		<AttachmentContent
			readOnly={false}
			facility={props.facility}
			attachments={props.attachments}
			{...focusedAttachments}
			returnLabel={props.returnToLabel}
			returnUrl={props.returnToUrl}
			getAttachmentUrl={getAttachmentUrl}
			getAttachmentId={getAttachmentIdFromUrl}
		/>
	);
};
