import { ThunkAction } from 'redux-thunk';
import * as ApiTypes from '@rcp/types';
import { apiService, localizationService, urlService } from 'src/services';
import { Resource } from 'src/services/url-service';
import { IppAASIndustrialPretreatmentSettingsState } from './aas-industrial-pretreatment-settings-state';
import EIppAASIndustrialPretreatmentSettingsActionType from './aas-industrial-pretreatment-settings-action-types';
import { IppAASIndustrialPretreatmentSettingsDataMapping } from 'src/constants';
export interface IppAASIndustrialPretreatmentSettingsAction {
	type: EIppAASIndustrialPretreatmentSettingsActionType;
	payload?: IppAASIndustrialPretreatmentSettingsState;
}

type TIppAASIndustrialPretreatmentSettingsThunkAction = ThunkAction<
	any,
	IppAASIndustrialPretreatmentSettingsState,
	any,
	IppAASIndustrialPretreatmentSettingsAction
>;

export const fetchIppAASIndustrialPretreatmentSettingsActionCreator = (
	currentSettingsValue: IppAASIndustrialPretreatmentSettingsState
): TIppAASIndustrialPretreatmentSettingsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings + '/Program');
	const ippAASIndustrialPretreatmentSettings = await apiService.getAuthoritySettings<
		IppAASIndustrialPretreatmentSettingsState
	>(url, IppAASIndustrialPretreatmentSettingsDataMapping, currentSettingsValue);
	const fetchAttachmentTypeResponse = await fetchIppFileUploadAttachmentTypes();
	const { notification, licenseUsedCountResponse } = await fetchLicenseUsedCount();
	if (notification.msg === '') {
		ippAASIndustrialPretreatmentSettings.data.industryLicensesInUse = `${licenseUsedCountResponse.industryLicenseUsedCount}`;
		ippAASIndustrialPretreatmentSettings.data.authorityLicensesInUse = `${licenseUsedCountResponse.authorityUserLicenseUsedCount}`;
	}

	dispatch({
		type: EIppAASIndustrialPretreatmentSettingsActionType.FetchIppAASAuthoritySettingsData,
		payload: {
			...ippAASIndustrialPretreatmentSettings,
			notification: fetchAttachmentTypeResponse.notification,
			fileUploadAttachmentTypes: fetchAttachmentTypeResponse.attachmentTypesResponse as ApiTypes.IppAttachmentType[]
		}
	});
	return true;
};

const fetchIppFileUploadAttachmentTypes = async () => {
	const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.IppFileUploadAttachmentTypes}`;
	let attachmentTypesResponse = await apiService.getResource(url);
	let notification = { msg: '', type: 'success' };
	if (!attachmentTypesResponse || !Array.isArray(attachmentTypesResponse) || attachmentTypesResponse.length === 0) {
		notification = {
			msg: localizationService.getLocalizedString(
				'ipp.authorityAccountSetting.industrialPretreatmentSettingForm.errors.attachmentTypes'
			),
			type: 'error'
		};
		attachmentTypesResponse = [];
	}
	return { notification, attachmentTypesResponse };
};

const fetchLicenseUsedCount = async () => {
	const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.IppUserLicenseUsedCount}`;
	let licenseUsedCountResponse: ApiTypes.IppUserLicenseUsedCount = await apiService.getResource(url);
	let notification = { msg: '', type: 'success' };
	if (!licenseUsedCountResponse) {
		notification = {
			msg: localizationService.getLocalizedString(
				'ipp.authorityAccountSetting.industrialPretreatmentSettingForm.errors.licenseCount'
			),
			type: 'error'
		};
		licenseUsedCountResponse = {} as ApiTypes.IppUserLicenseUsedCount;
	}
	return { notification, licenseUsedCountResponse };
};

export const updateIppAASIndustrialPretreatmentSettingsActionCreator = (
	updatedIndustrialPretreatmentSettings: IppAASIndustrialPretreatmentSettingsState
): TIppAASIndustrialPretreatmentSettingsThunkAction => async (dispatch, getState) => {
	dispatch({
		type: EIppAASIndustrialPretreatmentSettingsActionType.UpdateIppAASAuthoritySettingsData,
		payload: updatedIndustrialPretreatmentSettings
	});
	return true;
};

export const saveIppAASIndustrialPretreatmentSettingsActionCreator = (
	finalIndustrialPretreatmentSettings: IppAASIndustrialPretreatmentSettingsState
): TIppAASIndustrialPretreatmentSettingsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
	const postResponse = await apiService.postAuthoritySettings<IppAASIndustrialPretreatmentSettingsState>(
		url,
		finalIndustrialPretreatmentSettings,
		IppAASIndustrialPretreatmentSettingsDataMapping,
		['industryLicensesInUse', 'authorityLicensesInUse']
	);
	let notification = { msg: '', type: 'success' };
	const industrialPretreatmentSettingFormKey = 'ipp.authorityAccountSetting.industrialPretreatmentSettingForm';
	if (postResponse && postResponse.items) {
		notification = {
			msg: localizationService.getLocalizedString(
				'alertMessages.savedSuccess',
				localizationService.getLocalizedString(`${industrialPretreatmentSettingFormKey}.heading`)
			),
			type: 'success'
		};
	}
	dispatch({
		type: EIppAASIndustrialPretreatmentSettingsActionType.SaveIppAASAuthoritySettingsData,
		payload: { ...finalIndustrialPretreatmentSettings, isSaveButtonEnabled: true, notification: notification }
	});
	return true;
};

export const toggleSaveButtonActionCreator = (
	finalIndustrialPretreatmentSettings: IppAASIndustrialPretreatmentSettingsState
): TIppAASIndustrialPretreatmentSettingsThunkAction => async (dispatch, getState) => {
	dispatch({
		type: EIppAASIndustrialPretreatmentSettingsActionType.ToggleSaveButton,
		payload: {
			...finalIndustrialPretreatmentSettings,
			isSaveButtonEnabled: !finalIndustrialPretreatmentSettings.isSaveButtonEnabled
		}
	});
	return true;
};
