import React, { useState, useEffect } from 'react';

import { FilterOperator, FilterType, MultiSelectDropDownOption, RegulatoryProgramName } from '@rcp/types';
import { DomainObject, FilterProperty, Operator, FilterItem } from '../../services/data-types/filter-types';
import { localizationService, localStorageService, tokenService, urlService } from 'src/services';
import _ from 'lodash';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FilterDate } from './filter-row-date';
import { FilterBoolean } from './filter-row-boolean';
import { FilterNumber } from './filter-row-number';
import { FilterText } from './filter-row-text';
import { FilterEnum } from './filter-row-enum';
import { filterService } from './filter-service';
import { Utils } from 'src/services/utils';

export const getInitialFilterItem = (): FilterItem => {
	let filterItem = new FilterItem(
		filterService.getAllDomainObject()[0],
		filterService.emptyProperty,
		filterService.getOperatorsByFilterType(filterService.emptyProperty.filterType)[0],
		[{}]
	);
	filterItem.property.error = '';
	return filterItem;
};

interface FilterRowProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	deleteFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	reportPackageElementTypeId?: string;
	isForMap?: boolean;
}

export const FilterRow: React.FC<FilterRowProps> = props => {
	let allDomainObjects = filterService.getAllDomainObject();
	let initialFilterItem = getInitialFilterItem();
	let initialOperators = filterService.getOperatorsByFilterType(initialFilterItem.property.filterType);
	const [filterItem, setFilterItem] = useState(initialFilterItem);
	const [properties, setProperties] = useState(initialFilterItem.domainObject.properties);
	const [operators, setOperators] = useState(initialOperators);
	const [valueOptions, setValueOptions] = useState([] as MultiSelectDropDownOption[]);
	const [isFacilityPortalEnabled, setIsFacilityPortalEnabled] = useState(false);
	const [showOnlyContainsOperator, setShowOnlyContainsOperator] = useState(false);
	const propertiesForContainsOperator = [{ lookIn: 'Violation', find: 'comments' }];

	useEffect(() => {
		localStorageService.getLastProgram() === _.toLower(RegulatoryProgramName.FOG) && setFacilityPortalStatus();
	}, []);

	useEffect(() => {
		setFilterItem(props.filterItem);
		if (!_.isEmpty(props.filterItem.domainObject.properties)) {
			let modifiedProperties = props.filterItem.domainObject.properties.filter(property => {
				if (!isFacilityPortalEnabled) {
					const columnsToExclude = ['invitationDateTimeUtc', 'facilityPortalStatusName'];
					return !columnsToExclude.includes(property.name);
				} else {
					return property;
				}
			});
			setProperties(modifiedProperties);
		}
		let showOnlyContainsOperator = false;
		let { domainObject, property } = props.filterItem;
		propertiesForContainsOperator.forEach(element => {
			if (
				_.toLower(element.lookIn) === _.toLower(domainObject.name) &&
				_.toLower(element.find) === _.toLower(property.name)
			) {
				showOnlyContainsOperator = true;
			} else {
				showOnlyContainsOperator = false;
			}
		});
		setShowOnlyContainsOperator(showOnlyContainsOperator);
		let operators = filterService
			.getOperatorsByFilterType(props.filterItem.property.filterType, props.filterItem.operator.name)
			.filter(operator => {
				if (showOnlyContainsOperator) {
					return _.toLower(operator.name) === _.toLower(FilterOperator.Contains);
				} else {
					return operator;
				}
			});
		setOperators(operators);
	}, [props.filterItem, isFacilityPortalEnabled]);

	useEffect(() => {
		let newOperator = _.cloneDeep(props.filterItem.operator);
		if (showOnlyContainsOperator) {
			newOperator = {
				label: localizationService.getLocalizedString('screen.filterModal.contains'),
				name: FilterOperator.Contains,
				error: ''
			};
			setFilterItem({ ...filterItem, operator: newOperator });
		} else {
			setFilterItem(props.filterItem);
		}
	}, [showOnlyContainsOperator]);

	const setFacilityPortalStatus = async () => {
		setIsFacilityPortalEnabled(await Utils.getFacilityPortalStatusForAuthority());
	};

	function updateFilter(newFilterItem: FilterItem) {
		setFilterItem(newFilterItem);
		props.updateFilter(newFilterItem);
	}

	function setNewDomainObject(newDomainObject: DomainObject) {
		let newFilterItem = { ...filterItem, property: filterService.emptyProperty } as FilterItem;
		newFilterItem.domainObject = newDomainObject;
		setProperties(newDomainObject.properties);
		filterService.resetFilterItem(newFilterItem);
		updateFilter(newFilterItem);
	}

	const onDomainObjectChanged = (e: any) => {
		const domainName = e.target.value as string;
		const domainObjectTemp = allDomainObjects.filter(i => i.name === domainName)[0];
		setNewDomainObject(domainObjectTemp);
	};

	const setOperatorListForProperty = (propertyName: string, localProperties: FilterProperty[]) => {
		if (!localProperties) {
			localProperties = filterItem.domainObject.properties;
		}
		let filterProperty = _.find(localProperties, i => i.name === propertyName);
		if (!filterProperty) {
			throw new Error('Cannot find filter property from property defined list, please correct filter-service.ts');
		}

		let newFilterItem = { ...filterItem } as FilterItem;
		newFilterItem.property = filterProperty;

		let filterType = filterProperty.filterType;
		let validFilterTypeOperator = filterService.getOperatorsByFilterType(filterType);
		if (validFilterTypeOperator && validFilterTypeOperator.length > 0) {
			if (!validFilterTypeOperator.find(i => i.name === filterItem.operator.name)) {
				newFilterItem.operator = validFilterTypeOperator[0];
			}
			setOperators(validFilterTypeOperator);
			if (!_.isEmpty(filterProperty.valueOptions)) {
				setValueOptions(filterProperty.valueOptions);
			}
		}

		filterService.resetFilterItem(newFilterItem);
		updateFilter(newFilterItem);
	};

	const onPropertyChanged = (e: any) => {
		let propertyName = e.target.value as string;
		if (propertyName === undefined) {
			throw new Error(`propertyName cannot be undefined`);
		}
		setOperatorListForProperty(propertyName, filterItem.domainObject.properties);
	};

	const onOperatorChanged = (e: any) => {
		let operator = e.target.value as string;
		if (operator === undefined) {
			throw new Error(`operator cannot be undefined`);
		}

		let operators = filterService.getOperatorsByFilterType(filterItem.property.filterType);
		let foundOperator = _.find(operators, i => i.name === operator);
		if (!foundOperator) {
			throw new Error(`operator ${operator} cannot be found, might be a break change`);
		}
		let newFilterItem = { ...filterItem } as FilterItem;
		newFilterItem.operator = foundOperator;
		filterService.resetFilterItem(newFilterItem);
		updateFilter(newFilterItem);
	};

	const onRemoveBtnClicked = () => {
		props.deleteFilter(filterItem);
	};

	const getFilterValueInputsElement = () => {
		let filterType = filterItem.property.filterType;
		switch (filterType) {
			case FilterType.Text:
				return (
					<FilterText
						reportPackageElementTypeId={props.reportPackageElementTypeId}
						rowNumber={props.rowNumber}
						updateFilter={updateFilter}
						filterItem={filterItem}
						isForMap={props.isForMap}
					/>
				);
			case FilterType.Number:
			case FilterType.Integer:
				return (
					<FilterNumber
						rowNumber={props.rowNumber}
						updateFilter={updateFilter}
						filterItem={filterItem}
						isForMap={props.isForMap}
					/>
				);
			case FilterType.Boolean:
				return (
					<FilterBoolean
						rowNumber={props.rowNumber}
						updateFilter={updateFilter}
						filterItem={filterItem}
						isForMap={props.isForMap}
					/>
				);
			case FilterType.Enum:
				return (
					<FilterEnum
						rowNumber={props.rowNumber}
						updateFilter={updateFilter}
						filterItem={filterItem}
						options={valueOptions}
						isForMap={props.isForMap}
					/>
				);
			case FilterType.Date:
				return (
					<FilterDate
						rowNumber={props.rowNumber}
						updateFilter={updateFilter}
						filterItem={filterItem}
						isForMap={props.isForMap}
					/>
				);
			default:
				throw new Error(`Does not support convert filter item to value inputs for filter type ${filterType}`);
		}
	};

	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';

	return (
		<>
			{props.rowNumber > 0 && (
				<div className="form-row">
					<div className="col mb-3">{localizationService.getLocalizedString('screen.filterModal.and')}</div>
				</div>
			)}
			<div className={props.isForMap ? 'my-3 col-auto px-1' : 'form-row mb-3'}>
				<div className={inputGroupCss} tabIndex={0}>
					<label htmlFor={`DomainObjects${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.lookIn.label')}
					</label>
					<select
						id={`DomainObjects${props.rowNumber}`}
						className={`custom-select ${filterItem.domainObject.error ? ' is-invalid' : ' '}`}
						value={filterItem.domainObject.name}
						onChange={onDomainObjectChanged}>
						{allDomainObjects.map((optItem: DomainObject, index: number) => (
							<option key={index} value={optItem.name}>
								{optItem.label}
							</option>
						))}
					</select>
					{filterItem.domainObject.error && (
						<div className="invalid-feedback">{filterItem.domainObject.error}</div>
					)}
				</div>
				<div className={inputGroupCss}>
					<label htmlFor={`PropertyName${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.find')}
					</label>
					<select
						id={`PropertyName${props.rowNumber}`}
						className={`custom-select ${filterItem.property.error ? ' is-invalid' : ' '}`}
						value={filterItem.property.name}
						onChange={onPropertyChanged}>
						<option key={-1} value="" hidden />
						{properties.map((field: FilterProperty, index: number) => (
							<option key={index} value={field.name}>
								{field.label}
							</option>
						))}
					</select>
					{filterItem.property.error && <div className="invalid-feedback">{filterItem.property.error}</div>}
				</div>

				<div className={inputGroupCss}>
					<label htmlFor={`FilterOperator${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.that')}
					</label>
					<select
						id={`FilterOperator${props.rowNumber}`}
						className={`custom-select ${filterItem.operator.error ? ' is-invalid' : ' '}`}
						value={filterItem.operator.name}
						onChange={onOperatorChanged}>
						{operators.map((operator: Operator, index: number) => (
							<option key={index} value={operator.name}>
								{operator.label}
							</option>
						))}
					</select>
					{filterItem.operator.error && <div className="invalid-feedback">{filterItem.operator.error}</div>}
				</div>

				{getFilterValueInputsElement()}
				{!props.isForMap && (
					<div className="filter-icon-div">
						<label htmlFor="trash-icon" />
						<button
							name="trash-icon"
							type="button"
							className=" btn ai-secondary ml-1"
							onClick={onRemoveBtnClicked}>
							<FaRegTrashAlt id="removeMe" />
						</button>
					</div>
				)}
			</div>
		</>
	);
};
