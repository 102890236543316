import React from 'react';
import { DropDownOption } from '@rcp/types';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService, validationService } from 'src/services';
import { SingleSelectDropdown } from '../../components/widgets';
import { filterService } from './filter-service';

export const dateFilterBetweenRangeOptions: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.daysInTheFuture'),
		value: 'DaysInTheFuture'
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.daysInThePast'),
		value: 'DaysInThePast'
	}
];

export const validateDateFilterBetweenRange = (filterItem: FilterItem) => {
	validationService.validateIntegerNumberField(filterItem.values[0], 'value', 'error', true);
	validationService.validateRequiredField(filterItem.values[1], 'value', 'error');
	validationService.validateIntegerNumberField(filterItem.values[2], 'value', 'error', true);
	validationService.validateRequiredField(filterItem.values[3], 'value', 'error');
};

interface FilterDateBetweenRangeProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

export const FilterDateBetweenRange: React.FC<FilterDateBetweenRangeProps> = props => {
	const onValueChanged = (value: string, index: number) => {
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (newFilterItem.values.length !== 4 || index >= 4) {
			throw new Error(
				`resetFilterItem is incorrect that filterItem.values length must be 4 on date between a range filter`
			);
		}
		newFilterItem.values[index] = {
			value: value
		};
		props.updateFilter(newFilterItem);
	};

	let operatorValue0 = filterService.getFilterValue(props.filterItem, 0);
	let operatorValue1 = filterService.getFilterValue(props.filterItem, 1);
	let operatorValue2 = filterService.getFilterValue(props.filterItem, 2);
	let operatorValue3 = filterService.getFilterValue(props.filterItem, 3);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenRangeValue0_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.days')}
				</label>
				<input
					type="text"
					className={`form-control ${operatorValue0.error ? ' is-invalid' : ' '}`}
					id={`DateBetweenRangeValue0_${props.rowNumber}`}
					value={operatorValue0.value}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 0);
					}}
				/>
				{operatorValue0.error && <div className="invalid-feedback">{operatorValue0.error}</div>}
			</div>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenRangeValue1_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenRangeValue1_${props.rowNumber}`}
					name={`DateBetweenRangeValue1_${props.rowNumber}`}
					className={`custom-select ${operatorValue1.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 1);
					}}
					value={operatorValue1.value}
					options={dateFilterBetweenRangeOptions}
					noEmptyOption={true}
					withoutWrappingDiv={true}
				/>
				{operatorValue1.error && <div className="invalid-feedback">{operatorValue1.error}</div>}
			</div>

			<div className="form-inline">
				<label htmlFor="and" />
				<div className="px-1" id="and">
					{localizationService.getLocalizedString('screen.filterModal.and')}
				</div>
			</div>

			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenRangeValue2_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.days')}
				</label>
				<input
					type="text"
					className={`form-control ${operatorValue2.error ? ' is-invalid' : ' '}`}
					id={`DateBetweenRangeValue2_${props.rowNumber}`}
					value={operatorValue2.value}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 2);
					}}
				/>
				{operatorValue2.error && <div className="invalid-feedback">{operatorValue2.error}</div>}
			</div>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenRangeValue3_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenRangeValue3_${props.rowNumber}`}
					name={`DateBetweenRangeValue3_${props.rowNumber}`}
					className={`custom-select ${operatorValue3.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 3);
					}}
					value={operatorValue3.value}
					options={dateFilterBetweenRangeOptions}
					noEmptyOption={true}
					withoutWrappingDiv={true}
				/>
				{operatorValue3.error && <div className="invalid-feedback">{operatorValue3.error}</div>}
			</div>
		</>
	);
};
