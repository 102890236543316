import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';

import { RestApi } from '../services/rest.api';
import { Dictionary, PaginatedResult, ApiError } from '@rcp/types';
import { AppThunk, alertService } from './index';
import { urlService, localizationService, apiService } from 'src/services';

export interface RestState<T> extends PaginatedResult<T> {
	loading: boolean;
	error: string | null;
	selected: T | null | undefined;
}

function getErrorMessage(err: any) {
	return err.message;
}

function getStringValue(value: any): string {
	return String(value);
}

// TODO
// Add Update/Delete/ supports
export class RestSlice<T> {
	public readonly rests: Slice<RestState<T>, any>;
	private readonly typeName: string;
	private readonly getAllStart: any;
	private readonly getAllSuccess: any;
	public readonly fetchSuccess: any;
	private readonly getAllFailure: any;
	private readonly getStart: any;
	private readonly getSuccess: any;
	private readonly getFailure: any;
	private readonly exportStart: any;
	private readonly exportSuccess: any;
	private readonly exportFailure: any;
	private readonly setSelected: any;
	private readonly createStart: any;
	private readonly createSuccess: any;
	private readonly createFailure: any;
	private readonly patchStart: any;
	private readonly patchSuccess: any;
	private readonly patchFailure: any;
	private readonly putStart: any;
	private readonly putSuccess: any;
	private readonly putFailure: any;
	private readonly deleteStart: any;
	private readonly deleteSuccess: any;
	private readonly deleteFailure: any;
	private restApi: RestApi<T>;
	private lastFetchParams?: string;
	private readonly isAdminPath: boolean;

	private startLoading(state: RestState<any>) {
		state.loading = true;
	}

	private loadingFailed(state: RestState<any>, action: PayloadAction<string>) {
		state.loading = false;
		state.error = action.payload;
	}

	public getApiClient() {
		return this.restApi;
	}

	public defaultState(): RestState<T> {
		return {
			page: 1,
			total: 0,
			size: 50,
			result: [],
			loading: false,
			error: null,
			selected: null
		};
	}

	constructor(name: string, urlPath: string, isAdminPath: boolean = false) {
		this.rests = this.createRestReducer(name, this.defaultState());
		const {
			getStart,
			getSuccess,
			getFailure,
			getAllStart,
			getAllSuccess,
			getAllFailure,
			exportStart,
			exportSuccess,
			exportFailure,
			setSelected,
			createStart,
			createSuccess,
			createFailure,
			patchStart,
			patchSuccess,
			patchFailure,
			putStart,
			putSuccess,
			putFailure,
			deleteStart,
			deleteSuccess,
			deleteFailure
		} = this.rests.actions;

		this.typeName = name;
		this.getAllStart = getAllStart;
		this.getAllSuccess = getAllSuccess;
		this.fetchSuccess = getAllSuccess;
		this.getAllFailure = getAllFailure;
		this.exportStart = exportStart;
		this.exportSuccess = exportSuccess;
		this.exportFailure = exportFailure;

		this.getStart = getStart;
		this.getFailure = getFailure;
		this.getSuccess = getSuccess;
		this.setSelected = setSelected;
		this.restApi = new RestApi<T>(urlPath);

		this.createStart = createStart;
		this.createSuccess = createSuccess;
		this.createFailure = createFailure;

		this.patchStart = patchStart;
		this.patchSuccess = patchSuccess;
		this.patchFailure = patchFailure;

		this.putStart = putStart;
		this.putSuccess = putSuccess;
		this.putFailure = putFailure;

		this.deleteStart = deleteStart;
		this.deleteSuccess = deleteSuccess;
		this.deleteFailure = deleteFailure;
		this.isAdminPath = isAdminPath;
	}

	exportAll = (params?: string): AppThunk => async dispatch => {
		try {
			dispatch(this.exportStart(true));
			this.lastFetchParams = params;
			await this.restApi.getAll(params);
			dispatch(this.exportSuccess());
			alertService.addSuccess(
				localizationService.getLocalizedString('exportFile.downloadSuccess', this.typeName)
			);
		} catch (err) {
			dispatch(this.exportFailure(getStringValue(err)));
		}
	};

	resetAll = (clearUrlQueryString?: boolean): AppThunk => async dispatch => {
		if (clearUrlQueryString === true) {
			urlService.removeUrlQueryString();
		}
		dispatch(this.getAllSuccess(this.defaultState()));
	};

	fetchAll = (
		params?: string,
		callbackOnSuccess?: (list?: any) => void,
		callbackOnFailure?: (err: ApiError) => void,
		isGridTodefaultState: boolean = true
	): AppThunk => async dispatch => {
		try {
			if (isGridTodefaultState) dispatch(this.getAllSuccess(this.defaultState()));

			dispatch(this.getAllStart(true));
			this.lastFetchParams = params;
			const list = await this.restApi.getAll(params, this.isAdminPath);
			if (apiService.isSkippedGetResult(list)) {
				return;
			}
			dispatch(this.getAllSuccess(list));
			if (callbackOnSuccess) {
				callbackOnSuccess(list);
			}
		} catch (err) {
			alertService.addError(getErrorMessage(err));
			if (callbackOnFailure && err instanceof ApiError) {
				callbackOnFailure(err as ApiError);
			}
			dispatch(this.getAllFailure(getStringValue(err)));
		}
	};

	reload = (callbackOnSuccess?: (t: T) => void): AppThunk => async dispatch => {
		dispatch(this.fetchAll(this.lastFetchParams, callbackOnSuccess));
	};

	fetchPage = (params: Dictionary<string>, callbackOnSuccess?: () => void): AppThunk => async dispatch => {
		try {
			dispatch(this.getAllSuccess(this.defaultState()));

			dispatch(this.getAllStart(true));
			this.lastFetchParams = urlService.toQueryString(params);
			const list = await this.restApi.getAll(this.lastFetchParams, this.isAdminPath);
			if (apiService.isSkippedGetResult(list)) {
				return;
			}
			dispatch(this.getAllSuccess(list));
			if (callbackOnSuccess) {
				callbackOnSuccess();
			}
		} catch (err) {
			alertService.addError(getErrorMessage(err));
			dispatch(this.getAllFailure(getStringValue(err)));
		}
	};

	fetchOne = (
		id: number | string,
		callbackOnSuccess?: (t: T) => void,
		callbackOnFailure?: (err: ApiError) => void
	): AppThunk => async dispatch => {
		try {
			dispatch(this.getStart(true));
			const object = await this.restApi.getOne(id);
			if (apiService.isSkippedGetResult(object)) {
				return;
			}
			dispatch(this.getSuccess(object));

			if (callbackOnSuccess) {
				callbackOnSuccess(object);
			}
		} catch (err) {
			alertService.addError(getErrorMessage(err));
			if (callbackOnFailure && err instanceof ApiError) {
				callbackOnFailure(err as ApiError);
			}
			dispatch(this.getFailure(getStringValue(err)));
		}
	};

	createOne = (
		body: any,
		reloadGrid: boolean = false,
		successMessage?: string,
		callbackOnSuccess?: (data?: any) => void,
		callbackOnFailure?: (err: ApiError) => void,
		notShowError?: boolean,
		params?: string,
		isGridTodefaultState: boolean = true
	): AppThunk => async dispatch => {
		try {
			dispatch(this.createStart(true));
			const object = await this.restApi.createOne(body, params);
			dispatch(this.createSuccess(object));

			if (callbackOnSuccess) {
				callbackOnSuccess(object);
			}

			if (reloadGrid) {
				dispatch(
					params
						? this.fetchAll(params, undefined, undefined, isGridTodefaultState)
						: this.fetchAll(this.lastFetchParams, undefined, undefined, isGridTodefaultState)
				);
			}

			if (successMessage) {
				alertService.addSuccess(successMessage);
			}
		} catch (err) {
			if (!notShowError) {
				alertService.addError(getErrorMessage(err));
			}

			if (callbackOnFailure && err instanceof ApiError) {
				callbackOnFailure(err as ApiError);
			}
			dispatch(this.createFailure(getStringValue(err)));
		}
	};

	patchOne = (
		id: number | string,
		body: any,
		reloadGrid: boolean = false,
		successMessage?: string,
		callbackOnSuccess?: (data?: any) => void,
		callbackOnFailure?: (err: ApiError) => void,
		notShowError?: boolean,
		params?: string
	): AppThunk => async dispatch => {
		try {
			dispatch(this.patchStart(true));
			const object = await this.restApi.patchOne(id, body, params);
			dispatch(this.patchSuccess(object));

			if (callbackOnSuccess) {
				callbackOnSuccess(object);
			}

			if (reloadGrid) {
				dispatch(params ? this.fetchAll(params) : this.fetchAll(this.lastFetchParams));
			}

			if (successMessage) {
				alertService.addSuccess(successMessage);
			}
		} catch (err) {
			if (!notShowError) {
				alertService.addError(getErrorMessage(err));
			}

			if (callbackOnFailure && err instanceof ApiError) {
				callbackOnFailure(err as ApiError);
			}
			dispatch(this.patchFailure(getStringValue(err)));
		}
	};

	putOne = (
		id: number | string,
		body: any,
		reloadGrid: boolean = false,
		successMessage?: string,
		callbackOnSuccess?: () => void,
		callbackOnFailure?: (err: ApiError) => void,
		notShowError?: boolean,
		params?: string
	): AppThunk => async dispatch => {
		try {
			dispatch(this.putStart(true));
			const object = await this.restApi.putOne(id, body, params);
			dispatch(this.putSuccess(object));

			if (reloadGrid) {
				dispatch(this.fetchAll(this.lastFetchParams));
			}

			if (successMessage) {
				alertService.addSuccess(successMessage);
			}

			if (callbackOnSuccess) {
				callbackOnSuccess();
			}
		} catch (err) {
			if (!notShowError) {
				alertService.addError(getErrorMessage(err));
			}

			if (callbackOnFailure && err instanceof ApiError) {
				callbackOnFailure(err as ApiError);
			}
			dispatch(this.putFailure(getStringValue(err)));
		}
	};

	deleteOne = (
		id: number | string,
		reloadGrid: boolean = false,
		successMessage?: string,
		callbackOnSuccess?: () => void,
		params?: string,
		isGridTodefaultState: boolean = true
	): AppThunk => async dispatch => {
		try {
			dispatch(this.deleteStart(true));
			await this.restApi.deleteOne(id, params);
			dispatch(this.deleteSuccess());

			if (callbackOnSuccess) {
				callbackOnSuccess();
			}

			if (reloadGrid) {
				dispatch(
					params
						? this.fetchAll(params, undefined, undefined, isGridTodefaultState)
						: this.fetchAll(this.lastFetchParams, undefined, undefined, isGridTodefaultState)
				);
			}

			if (successMessage) {
				alertService.addSuccess(successMessage);
			}
		} catch (err) {
			alertService.addError(getErrorMessage(err));
			dispatch(this.deleteFailure(getStringValue(err)));
		}
	};

	deleteAll = (
		params: string,
		reloadGrid: boolean = false,
		successMessage?: string,
		callbackOnSuccess?: (data?: any) => void,
		returnData: boolean = false
	): AppThunk => async dispatch => {
		try {
			dispatch(this.deleteStart(true));
			let object = await this.restApi.deleteAll(params, returnData);
			dispatch(this.deleteSuccess());

			if (reloadGrid) {
				dispatch(this.fetchAll(this.lastFetchParams));
			}

			if (successMessage) {
				alertService.addSuccess(successMessage);
			}

			if (callbackOnSuccess) {
				callbackOnSuccess(object);
			}
		} catch (err) {
			alertService.addError(getErrorMessage(err));
			dispatch(this.deleteFailure(getStringValue(err)));
		}
	};

	setApiUrlPath = (path: string, dispatch?: any) => {
		this.restApi = new RestApi<T>(path);
		const self = this;
		if (dispatch) {
			dispatch(self.resetAll());
		}
	};

	createRestReducer = (name: string, initialState: RestState<T>) => {
		return createSlice({
			name: name,
			initialState: initialState,
			reducers: {
				getStart: this.startLoading,

				getSuccess: (state, { payload }: PayloadAction<any>) => {
					state.selected = payload;
					state.loading = false;
				},
				getFailure: this.loadingFailed,

				getAllStart: this.startLoading,

				getAllSuccess: (state, { payload }: PayloadAction<PaginatedResult<T>>) => {
					if (Array.isArray(payload)) {
						Object.assign(state, { result: payload });
					} else {
						Object.assign(state, payload);
					}
					state.loading = false;
				},

				getAllFailure: this.loadingFailed,

				exportStart: this.startLoading,
				exportSuccess: state => {
					state.loading = false;
				},
				exportFailure: this.loadingFailed,

				setSelected: (state, { payload }: PayloadAction<any>) => {
					state.selected = payload;
				},

				createStart: state => {},

				createSuccess: (state, { payload }: PayloadAction<any>) => {
					state.selected = payload;
					state.loading = false;
				},
				createFailure: this.loadingFailed,

				patchStart: state => {},

				patchSuccess: (state, { payload }: PayloadAction<any>) => {
					state.selected = payload;
					state.loading = false;
				},
				patchFailure: this.loadingFailed,

				putStart: state => {},

				putSuccess(state, { payload }: PayloadAction<any>) {
					state.selected = payload;
					state.loading = false;
				},
				putFailure: this.loadingFailed,

				deleteStart: state => {},
				deleteSuccess: state => {
					state.selected = undefined;
					state.loading = false;
				},
				deleteFailure: this.loadingFailed
			}
		});
	};
}
