import React, { useEffect } from 'react';
import _ from 'lodash';
import { Provider, useSelector } from 'react-redux';
import { DateUtilService, localizationService, Resource, urlService } from 'src/services';

import './timeline-tab.scss';
import './timeline.scss';
import {
	GetInitialTimelineSettings,
	TimeLineEventData,
	TimelineFilterByEventTypeEnum,
	TimelineSettings
} from './timeline-type';
import { DeviceTimelineFilterActionMenu, TimelineFilterActionMenu } from './timeline-filter-action-menu';
import { loadTimelineSettings, reloadTimelineEventsFromServer, saveTimelineSettings } from './timeline-service';
import { TimelineEvent, TimelineType } from './timeline-event';
import { alertService, configureReduxStore, initialApplicationState, RootState, useReduxSelector } from 'src/redux';
import ReactDOMServer from 'react-dom/server';
import {
	FaCalendarAlt,
	FaClipboard,
	FaEnvelope,
	FaUserAlt,
	FaTimes,
	FaCheck,
	FaExclamationTriangle
} from 'react-icons/fa';
import { TimelineEventComment } from './timeline-event-comment';
import { SearchInput } from 'src/components/widgets';
import { TimelineDateRange } from './timeline-date-range';
import {
	CccTestDto,
	CommentDto,
	CleaningEvent,
	TimelineNotice,
	HaulerDeviceCleaning,
	Violation,
	FeatureNames,
	EmailLog
} from '@rcp/types';
import { TimelineEventTest } from './timeline-event-test';
import { TimelineEventSentNotice } from './timeline-event-sentNotice';
import { GreaseTrap } from 'src/components/svg';
import { TimelineEventCleaning } from './timeline-event-cleaning';
import { TimelineEventCleaningManifest } from './timeline-event-cleaning-manifest';
import { TimelineEventCleaningSentNotice } from './timeline-event-sentCleaningNotice';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	TimelineEventViolation,
	ViolationTimelineType
} from 'src/components/authority/fog/facilities/facility-details/timeline/timeline-event-violation';
import { TimelineEventEmail } from '../../components/authority/fog/facilities/facility-details/timeline/timeline-event-email';
import { useHistory } from 'react-router';
import { timelineEventsSlice } from './timeline-events.slice';

interface OwnProps {}

type Props = OwnProps;

export const Timeline = (props: Props) => {
	const [timelineEvents, setTimelineEvents] = React.useState([
		<TimelineEvent key="top" isEnding={true} eventType={TimelineType.top} />
	]);
	const [timelineSettings, setTimelineSettings] = React.useState(GetInitialTimelineSettings());
	const [, setShowFilterByEventType] = React.useState(false);
	const [showDateRangeForm, setShowDateRangeForm] = React.useState(false);
	const [timelineFilterAnchor, setTimelineFilterAnchor] = React.useState();
	const [currentDomainObjectId, setCurrentDomainObjectId] = React.useState(0);
	const [currentDomainObjectName, setCurrentDomainObjectName] = React.useState('');
	const history = useHistory();

	const loadFilterSettingFromLocalStorage = () => {
		let timelineSetting = loadTimelineSettings();

		reloadTimelineEventsFromServer(timelineSetting);
		setTimelineSettings(timelineSetting);
	};

	useEffect(() => {
		if (
			currentDomainObjectName !== urlService.getDomainObjectName() ||
			currentDomainObjectId !== urlService.getDomainObjectId()
		) {
			timelineEventsSlice.setApiUrlPath(
				`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/${Resource.TimelineEvents}`
			);
			loadFilterSettingFromLocalStorage();
			setCurrentDomainObjectName(urlService.getDomainObjectName());
			setCurrentDomainObjectId(urlService.getDomainObjectId());
		}
	}, [history.location.pathname]);

	let timelineState = (state: RootState) => state.timeline;
	let { result: list } = useSelector(timelineState);

	useEffect(() => {
		if (list.length > 0) {
			var events = getTimelineEvents();
			setTimelineEvents(events);
		} else {
			setTimelineEvents([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timelineSettings, list]);

	const filterRuleCount = (filterCount: TimelineSettings, allFilter: string[]) => {
		let allFilters = _.cloneDeep(allFilter);
		let count = allFilters.length - filterCount.excludeList.length;
		if (count !== allFilters.length) {
			return (
				<span className="timeline-filter-count ml-2">
					{localizationService.getLocalizedString(
						'screen.timeline.filterCount',
						count.toString(),
						allFilters.length.toString()
					)}
				</span>
			);
		}
	};
	const filterRuleChange = (filterBy: TimelineFilterByEventTypeEnum) => {
		let newTimelineSettings = { ...timelineSettings };
		if (newTimelineSettings.excludeList.includes(filterBy)) {
			var index = newTimelineSettings.excludeList.indexOf(filterBy);
			newTimelineSettings.excludeList.splice(index, 1);
		} else {
			newTimelineSettings.excludeList.push(filterBy);
		}

		saveFilterSettingToLocalStorage(newTimelineSettings, true);
		getFilterRuleCount(newTimelineSettings);
	};

	const saveFilterSettingToLocalStorage = (timelineFilterSetting: TimelineSettings, loadEvents = false) => {
		setTimelineSettings(timelineFilterSetting);
		saveTimelineSettings(timelineFilterSetting);

		if (loadEvents) {
			reloadTimelineEventsFromServer(timelineFilterSetting);
		}
	};

	const closeFilterByForm = () => {
		setShowFilterByEventType(false);
	};
	const prepTimelineEvents = () => {
		var timelineEvents: TimeLineEventData[] = list.reduce((events: TimeLineEventData[], event) => {
			if (event.comment) {
				events.push(prepTimelineComment(event.comment));
			}
			if (event.test) {
				events.push(prepTimelineTests(event.test));
			}
			if (event.sentNotice) {
				events.push(prepTimelineSentNotice(event.sentNotice));
			}
			if (event.cleaning) {
				events.push(prepTimelineCleaning(event.cleaning));
			}
			if (event.cleaningManifest) {
				events.push(prepTimelineCleaningManifest(event.cleaningManifest));
			}

			if (event.sentCleaningNotice) {
				events.push(prepTimelineSentCleaningNotice(event.sentCleaningNotice));
			}
			if (event.sendingCleaningNotice) {
				events.push(
					prepTimelineSentCleaningNotice(event.sendingCleaningNotice, TimelineType.sendingCleaningNotice)
				);
			}
			if (event.failedCleaningNotice) {
				events.push(
					prepTimelineSentCleaningNotice(event.failedCleaningNotice, TimelineType.failedCleaningNotice)
				);
			}
			if (event.deviceEmailLogs) {
				events.push(prepTimelineEmail(event.deviceEmailLogs));
			}
			if (event.inCompliantViolations) {
				events.push(
					prepTimelineNonComplianceViolation(
						event.inCompliantViolations,
						ViolationTimelineType.InComplianceDate
					)
				);
			}
			if (event.nonCompliantViolations) {
				events.push(
					prepTimelineNonComplianceViolation(
						event.nonCompliantViolations,
						ViolationTimelineType.NonComplianceDate
					)
				);
			}
			if (event.compliantViolations) {
				events.push(
					prepTimelineNonComplianceViolation(
						event.compliantViolations,
						ViolationTimelineType.ComplianceDueDate
					)
				);
			}
			return events;
		}, []);

		return timelineEvents;
	};

	const prepTimelineTests = (test: CccTestDto) => {
		let dueDate = test.testDueDate && DateUtilService.getMomentInAuthorityTimeZone(test.testDueDate);
		let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate);
		let completeDate = test.initialTestDate;
		//if test is complete then show complete date otherwise show due date
		let eventTimeStr = completeDate ? completeDate : dueDateEndOfDay;
		let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr); //Adding time as today's event will show above the TODAY Line
		return {
			sortDateTime: eventTime,
			showTime: false,
			sortPriority: test.initialTestDate ? 1 : 2,
			icon: <FaClipboard />,
			iconBackgroundCss: getTestTimelineColor(test),
			content: <TimelineEventTest test={test} />,
			eventType: TimelineType.test
		} as TimeLineEventData;
	};

	const getTestTimelineColor = (test: CccTestDto) => {
		if (test.initialTestDate) return 'timeline-icon-complete-event';
		return 'timeline-icon-due-event';
	};

	const getCleaningTimelineColor = (cleaning: HaulerDeviceCleaning | CleaningEvent) => {
		if (cleaning.completeDate) return 'timeline-icon-complete-event';
		return 'timeline-icon-due-event';
	};

	const prepTimelineComment = (comment: CommentDto) => {
		return {
			sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(comment.creationDateTimeUtc),
			showTime: true,
			sortpriority: 1,
			icon: <FaUserAlt />,
			iconBackgroundCss: 'timeline-icon-complete-event',
			content: <TimelineEventComment event={comment} />,
			eventType: TimelineType.comment
		} as TimeLineEventData;
	};

	const prepTimelineSentNotice = (sentNotice: TimelineNotice) => {
		return {
			sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(sentNotice.sentDateTime),
			showTime: true,
			sortpriority: 1,
			icon: <FaEnvelope />,
			iconBackgroundCss: 'timeline-icon-complete-event',
			content: <TimelineEventSentNotice event={sentNotice} />,
			eventType: TimelineType.sentNotice
		} as TimeLineEventData;
	};

	const prepTimelineCleaning = (cleaning: CleaningEvent) => {
		let dueDate = cleaning.dueDate && DateUtilService.getMomentInAuthorityTimeZone(cleaning.dueDate);
		let dueDateEndOfDay = dueDate && DateUtilService.ceilingDate(dueDate);
		let completeDate = cleaning.completeDate;
		let eventTimeStr = completeDate
			? DateUtilService.combineDateTimeFromTwoDate(completeDate, cleaning.lastModificationDateTimeUtc)
			: dueDateEndOfDay;
		let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
		let domainObjectName = urlService.getDomainObjectName();
		return {
			sortDateTime: eventTime,
			showTime: false,
			sortpriority: 2,
			icon: <GreaseTrap hasSlateFill={cleaning.completeDate != undefined} title="Cleaning" />,
			iconBackgroundCss: getCleaningTimelineColor(cleaning),
			content: (
				<TimelineEventCleaning
					cleaning={cleaning}
					isFacilityDetailsPage={domainObjectName === Resource.FogFacilities}
					isDeviceDetailsPage={domainObjectName === Resource.Devices}
				/>
			),
			eventType: TimelineType.cleaning
		} as TimeLineEventData;
	};

	const prepTimelineCleaningManifest = (cleaning: HaulerDeviceCleaning) => {
		let completeDate = cleaning.completeDate;
		let eventTimeStr = DateUtilService.combineDateTimeFromTwoDate(completeDate, cleaning.submittedDateTimeLocal);
		let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
		let domainObjectName = urlService.getDomainObjectName();

		return {
			sortDateTime: eventTime,
			showTime: false,
			sortpriority: 1,
			icon: <GreaseTrap hasSlateFill={cleaning.completeDate != undefined} title="Cleaning" />,
			iconBackgroundCss: getCleaningTimelineColor(cleaning),
			content: (
				<TimelineEventCleaningManifest
					cleaning={cleaning}
					isFacilityDetailsPage={domainObjectName === Resource.FogFacilities}
					isDeviceDetailsPage={domainObjectName === Resource.Devices}
				/>
			),
			eventType: TimelineType.cleaning
		} as TimeLineEventData;
	};

	const prepTimelineSentCleaningNotice = (sentNotice: TimelineNotice, eventType?: string) => {
		let isFailedEvent = eventType == TimelineType.failedCleaningNotice;
		return {
			sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(sentNotice.sentDateTime || ''),
			showTime: false,
			sortpriority: 1,
			icon: (
				<FontAwesomeIcon
					icon={faPaperPlane}
					className={isFailedEvent ? '' : 'font-awesome-icon sentNoticeIcon'}
				/>
			),
			iconBackgroundCss: isFailedEvent ? 'timeline-icon-failed-email' : 'timeline-icon-complete-event',
			content: <TimelineEventCleaningSentNotice event={sentNotice} />,
			eventType: eventType || TimelineType.sentCleaningNotice
		} as TimeLineEventData;
	};
	const prepTimelineNonComplianceViolation = (violation: Violation, violationType: ViolationTimelineType) => {
		let icon = <FaExclamationTriangle title="non-compliant-violation" />;
		let iconBackgroundCss = 'timeline-icon-non-compliant-violation';
		let complianceDueDate = violation.nonComplianceDate;
		if (violationType === ViolationTimelineType.ComplianceDueDate) {
			iconBackgroundCss = 'timeline-icon-non-compliant-violation';
			icon = <FaExclamationTriangle title="compliant-violation" />;
			complianceDueDate = DateUtilService.ceilingDate(
				DateUtilService.getMomentInAuthorityTimeZone(violation.complianceDueDate)
			);
		} else if (violationType === ViolationTimelineType.InComplianceDate && violation.inComplianceDate) {
			icon = <FaExclamationTriangle />;
			iconBackgroundCss = 'timeline-icon-in-compliance';
			complianceDueDate = violation.inComplianceDate;
		} else {
			violation.inComplianceDate && (iconBackgroundCss = 'timeline-icon-in-compliance');
		}
		let eventTimeStr = DateUtilService.combineDateTimeFromTwoDate(complianceDueDate, violation.creationDateTimeUtc);
		let eventTime = eventTimeStr && DateUtilService.getDateStringInAuthorityTimeZone(eventTimeStr);
		return {
			sortDateTime: eventTime,
			showTime: false,
			sortpriority: 1,
			icon: icon,
			iconBackgroundCss: iconBackgroundCss,
			content: (
				<TimelineEventViolation
					violation={violation}
					index={violation.violationId || 0}
					todayNow={DateUtilService.getAuthorityTimezoneNow()}
					facilityId={violation.organizationId}
					violationTimelineType={violationType}
					saveCallBack={() => reloadTimelineEventsFromServer(timelineSettings)}
					deleteCallBack={() => reloadTimelineEventsFromServer(timelineSettings)}
				/>
			),
			eventType: TimelineType.violation
		} as TimeLineEventData;
	};

	const prepTimelineEmail = (email: EmailLog) => {
		return {
			sortDateTime: DateUtilService.getDateStringInAuthorityTimeZone(email.sentDateTimeUtc),
			showTime: true,
			sortpriority: 2,
			iconBackgroundCss: 'timeline-icon-complete-event',
			icon: <FaEnvelope />,
			content: <TimelineEventEmail event={email} index={email.emailLogId} isDeviceDetailPage={true} />,
			eventType: TimelineType.email
		} as TimeLineEventData;
	};

	const getTimelineEvents = () => {
		let todayNow = DateUtilService.getAuthorityTimezoneNow();
		let timeEvents = [] as TimeLineEventData[];

		timeEvents = prepTimelineEvents();
		if (!_.isEmpty(timelineSettings.searchText)) {
			let searchText = _.toLower(timelineSettings.searchText);
			const initialState = window.initialReduxState || initialApplicationState;
			const store = configureReduxStore(history, initialState);
			timeEvents = _.filter(timeEvents, (event: TimeLineEventData) => {
				let content = ReactDOMServer.renderToString(<Provider store={store}>{event.content}</Provider>);
				return _.toLower(content).indexOf(searchText) !== -1;
			}) as TimeLineEventData[];
		}

		timeEvents.sort((i, j) => {
			if (i.sortDateTime === j.sortDateTime) {
				if (i.sortPriority === j.sortPriority) {
					return 0;
				}
				return i.sortPriority < j.sortPriority ? 1 : -1;
			}
			return DateUtilService.isBefore(i.sortDateTime, j.sortDateTime) ? 1 : -1;
		});

		let afterNowTimelineEvents = timeEvents.filter(i => DateUtilService.isAfter(i.sortDateTime, todayNow));
		let beforeNowTimelineEvents = timeEvents.filter(i => DateUtilService.isSameOrBefore(i.sortDateTime, todayNow));

		let combineTimeLineEvent = afterNowTimelineEvents.map((i, j) => {
			return (
				<TimelineEvent
					eventDateTime={i.sortDateTime}
					showTime={i.showTime}
					eventType={i.eventType}
					icon={i.icon}
					iconBackgroundCss={i.iconBackgroundCss}
					key={`timeline-event-today-${j}`}>
					{i.content}
				</TimelineEvent>
			);
		});
		combineTimeLineEvent.push(
			<TimelineEvent key="today" eventType={TimelineType.today} isTodayTimeLineDivider={true} showTime={false} />
		);

		combineTimeLineEvent = combineTimeLineEvent.concat(
			beforeNowTimelineEvents.map((i, j) => {
				return (
					<TimelineEvent
						eventDateTime={i.sortDateTime}
						showTime={i.showTime}
						eventType={i.eventType}
						icon={i.icon}
						iconBackgroundCss={i.iconBackgroundCss}
						key={`timeline-event-before-${j}`}>
						{i.content}
					</TimelineEvent>
				);
			})
		);

		combineTimeLineEvent.unshift(
			<TimelineEvent key="timeline-event-top" eventType={TimelineType.top} isEnding={true} />
		);

		combineTimeLineEvent.push(
			<TimelineEvent key="timeline-event-bottom" eventType={TimelineType.ending} isEnding={true} />
		);
		return combineTimeLineEvent;
	};

	const onSearchTextChange = (e: any) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		newFacilityTimelineSettings.searchText = e.target.value;
		saveFilterSettingToLocalStorage(newFacilityTimelineSettings, false);
	};
	const applySearch = (searchTerm: string) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		newFacilityTimelineSettings.searchText = searchTerm;
		setTimelineSettings(newFacilityTimelineSettings);
		saveFilterSettingToLocalStorage(newFacilityTimelineSettings);
	};
	let startDateLabel = '';
	if (timelineSettings.startOfTime) {
		startDateLabel = localizationService.getLocalizedString('screen.timeline.startOfTime');
	} else {
		startDateLabel = DateUtilService.toDisplayDate(timelineSettings.startDate);
	}

	let endDateLabel = '';
	if (timelineSettings.endOfTime) {
		endDateLabel = localizationService.getLocalizedString('screen.timeline.endOfTime');
	} else {
		endDateLabel = DateUtilService.toDisplayDate(timelineSettings.endDate);
	}
	const editDateRangeBtnClicked = (e: any) => {
		setShowDateRangeForm(!showDateRangeForm);
		setTimelineFilterAnchor(e && e.target);
	};
	const dateRangeApplyBtnClicked = (timelineFilterSettings: TimelineSettings) => {
		let newFacilityTimelineSettings = { ...timelineSettings };
		_.assign(newFacilityTimelineSettings, timelineFilterSettings);

		if (!DateUtilService.isValidDateTime(newFacilityTimelineSettings.startDate)) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.dateIsNotValid',
					'screen.labels.startDate'
				)
			);
		} else if (!DateUtilService.isValidDateTime(newFacilityTimelineSettings.endDate)) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.dateIsNotValid',
					'screen.labels.endDate'
				)
			);
		} else {
			setShowDateRangeForm(false);
			saveFilterSettingToLocalStorage(newFacilityTimelineSettings, true);
		}
	};

	const closeDateRangeFilterForm = () => {
		setShowDateRangeForm(false);
	};

	const getFilterRuleCount = (timelineSettings: {
		version: string;
		searchText: string;
		excludeList: string[];
		startDateIndex?: number | undefined;
		endDateIndex?: number | undefined;
		startDate: string;
		endDate: string;
		startOfTime: boolean;
		endOfTime: boolean;
	}) => {
		if (urlService.getDomainObjectName() === Resource.Devices) {
			return filterRuleCount(timelineSettings, [
				TimelineFilterByEventTypeEnum.cleanings,
				TimelineFilterByEventTypeEnum.comments,
				TimelineFilterByEventTypeEnum.violations,
				TimelineFilterByEventTypeEnum.sentCleaningNotices,
				TimelineFilterByEventTypeEnum.deviceEmailLogs
			]);
		} else {
			return filterRuleCount(timelineSettings, [
				TimelineFilterByEventTypeEnum.comments,
				TimelineFilterByEventTypeEnum.tests,
				TimelineFilterByEventTypeEnum.sentNotices,
				TimelineFilterByEventTypeEnum.sentCleaningNotices,
				TimelineFilterByEventTypeEnum.sentGeneralNotices
			]);
		}
	};

	return (
		<section className="timeline">
			<div className="d-md-flex">
				<div className="mr-auto">
					<h2>{localizationService.getLocalizedString('screen.timeline.timeline')}</h2>
				</div>
				<div className="form-row">
					<div className="col-auto mb-2">
						<div className="dropdown">
							<button
								className="btn ai-white"
								id="filterBtn"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false">
								{localizationService.getLocalizedString('screen.timeline.filter')}
								{getFilterRuleCount(timelineSettings)}
							</button>
							{urlService.getDomainObjectName() === Resource.Devices ? (
								<DeviceTimelineFilterActionMenu
									timelineFilterByForm={timelineSettings}
									filterRuleChanged={filterRuleChange}
									closeModal={closeFilterByForm}
								/>
							) : (
								<TimelineFilterActionMenu
									timelineFilterByForm={timelineSettings}
									filterRuleChanged={filterRuleChange}
									closeModal={closeFilterByForm}
								/>
							)}
						</div>
					</div>
					<div className="col-auto mb-2">
						<SearchInput
							inputId="timelineSearch"
							placeholder={localizationService.getLocalizedString('screen.timeline.searchTimeline')}
							ariaLabel="Search"
							onChange={onSearchTextChange}
							search={applySearch}
							searchTerm={timelineSettings.searchText}
						/>
					</div>
					<div className="col-auto mb-2">
						<button className="btn ai-white" id="editDateRangeBtn" onClick={editDateRangeBtnClicked}>
							{startDateLabel} - {endDateLabel}
							<FaCalendarAlt className="ml-2 ai-media-gray" />
						</button>
					</div>
				</div>
			</div>
			<hr />
			<div className="linko-timeline m-md-3">{timelineEvents}</div>
			{showDateRangeForm && (
				<TimelineDateRange
					anchor={(timelineFilterAnchor as unknown) as HTMLElement}
					show={showDateRangeForm}
					timelineFilterForm={timelineSettings}
					applyBtnClicked={dateRangeApplyBtnClicked}
					closeModal={closeDateRangeFilterForm}
				/>
			)}
		</section>
	);
};
