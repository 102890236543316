import React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { localizationService, urlService } from 'src/services';
import { SettingCard } from './setting-card';
import { ReactComponent as SvgAuthorities } from '../../../../assets/img/settings-authorities.svg';
import { ReactComponent as SvgAuthority } from '../../../../assets/img/settings-authority.svg';
import { ReactComponent as SvgContacts } from '../../../../assets/img/settings-contacts.svg';
import { ReactComponent as SvgFacilities } from '../../../../assets/img/settings-facilities.svg';
import { ReactComponent as SvgHaulers } from '../../../../assets/img/settings-haulers.svg';
import { ReactComponent as SvgImport } from '../../../../assets/img/settings-import.svg';
import { ReactComponent as SvgIncidents } from '../../../../assets/img/settings-incidents.svg';
import { ReactComponent as SvgInspections } from '../../../../assets/img/settings-inspections.svg';
import { ReactComponent as SvgNotices } from '../../../../assets/img/settings-notices.svg';
import { ReactComponent as SvgUnitsFrequency } from '../../../../assets/img/settings-units-frequencies.svg';
import { ReactComponent as SvgUser } from '../../../../assets/img/settings-users.svg';
import { ReactComponent as SvgMaintenance } from '../../../../assets/img/settings-maintenance.svg';
import { ReactComponent as SvgCompliance } from '../../../../assets/img/settings-compliance.svg';
import { ReactComponent as SvgDevices } from '../../../../assets/img/settings-devices.svg';
import { ReactComponent as SvgUnits } from '../../../../assets/img/units.svg';
import { ReactComponent as SvgCustomForm } from '../../../../assets/img/custom-form.svg';
import { ReactComponent as SvgOperationLog } from '../../../../assets/img/operation-log.svg';
import { ReactComponent as SvgEmail } from '../../../../assets/img/settings-email.svg';
import { LandingPage } from './landing-page';
import _ from 'lodash';
import { useReduxSelector } from 'src/redux';
import { FeatureNames } from '@rcp/types';

type Props = {
	history: History;
};

const AuthoritySettingsLandingPageComponent: React.FunctionComponent<Props> = props => {
	const featureFlags = useReduxSelector(state => state.featureSettings.featureFlagSettings);
	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowCustomCleaningFormsFeature]
	);
	const showFogIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);
	const authoritySettings = () => {
		let cards = [
			<SettingCard
				id="divSettingAuthority"
				title={localizationService.getLocalizedString('authoritySetting.authority')}
				key={localizationService.getLocalizedString('authoritySetting.authority')}
				description={localizationService.getLocalizedString('authoritySetting.authorityCard')}
				link={urlService.getSettingMenuPath('/authority')}
				history={props.history}
				hidden={!urlService.isAdministrator()}
				iconSource={<SvgAuthority />}
			/>,
			<SettingCard
				id="divSettingHauler"
				title={localizationService.getLocalizedString('authoritySetting.haulers')}
				key={localizationService.getLocalizedString('authoritySetting.haulers')}
				description={localizationService.getLocalizedString('authoritySetting.haulerCard')}
				link={urlService.getSettingMenuPath('/hauler')}
				history={props.history}
				hidden={!urlService.isAdministrator()}
				iconSource={<SvgHaulers />}
			/>,
			<SettingCard
				id="divSettingContacts"
				title={localizationService.getLocalizedString('authoritySetting.contacts')}
				key={localizationService.getLocalizedString('authoritySetting.contacts')}
				description={localizationService.getLocalizedString('authoritySetting.contactsCard')}
				link={urlService.getSettingMenuPath('/contact')}
				history={props.history}
				iconSource={<SvgContacts />}
			/>,
			<SettingCard
				id="divSettingCustomForms"
				title={localizationService.getLocalizedString('authoritySetting.customForms')}
				key={localizationService.getLocalizedString('authoritySetting.customForms')}
				description={localizationService.getLocalizedString('authoritySetting.customFormsCard')}
				link={urlService.getSettingMenuPath('/customForms')}
				history={props.history}
				iconSource={<SvgCustomForm />}
				hidden={!(showCustomCleaningFormsFeature && urlService.isAdministrator())}
			/>,
			<SettingCard
				id="divSettingFacilities"
				title={localizationService.getLocalizedString('authoritySetting.facilities')}
				key={localizationService.getLocalizedString('authoritySetting.facilities')}
				description={localizationService.getLocalizedString('authoritySetting.facilitiesCard')}
				link={urlService.getSettingMenuPath('/facility')}
				history={props.history}
				iconSource={<SvgFacilities />}
			/>,
			<SettingCard
				id="divSettingImport"
				title={localizationService.getLocalizedString('authoritySetting.import')}
				key={localizationService.getLocalizedString('authoritySetting.import')}
				description={localizationService.getLocalizedString('authoritySetting.importCard')}
				link={urlService.getSettingMenuPath('/self-import')}
				history={props.history}
				iconSource={<SvgImport />}
			/>,
			<SettingCard
				id="divSettingIncidents"
				title={localizationService.getLocalizedString('authoritySetting.incidents')}
				key={localizationService.getLocalizedString('authoritySetting.incidents')}
				description={localizationService.getLocalizedString('authoritySetting.incidentsCard')}
				link={urlService.getSettingMenuPath('/incidents')}
				history={props.history}
				iconSource={<SvgIncidents />}
				hidden={!showFogIncidentFeature}
			/>,
			<SettingCard
				id="divSettingInspections"
				title={localizationService.getLocalizedString('authoritySetting.inspections')}
				key={localizationService.getLocalizedString('authoritySetting.inspections')}
				description={localizationService.getLocalizedString('authoritySetting.inspectionsCard')}
				link={urlService.getSettingMenuPath('/inspections')}
				history={props.history}
				iconSource={<SvgInspections />}
			/>,
			<SettingCard
				id="divSettingNotices"
				key={localizationService.getLocalizedString('authoritySetting.notices')}
				title={localizationService.getLocalizedString('authoritySetting.notices')}
				description={localizationService.getLocalizedString('authoritySetting.noticesDesc')}
				link={urlService.getSettingMenuPath('/notices')}
				history={props.history}
				iconSource={<SvgNotices />}
				hidden={!urlService.isAdministrator()}
			/>,
			<SettingCard
				id="divSettingUsers"
				title={localizationService.getLocalizedString('authoritySetting.users')}
				key={localizationService.getLocalizedString('authoritySetting.users')}
				description={localizationService.getLocalizedString('authoritySetting.usersCard')}
				link={urlService.getSettingMenuPath('/users')}
				history={props.history}
				iconSource={<SvgUser />}
			/>,
			<SettingCard
				id="divFrequenciesAndUnit"
				key={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				title={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				description={localizationService.getLocalizedString('authoritySetting.frequency.manageFrequency')}
				link={urlService.getSettingMenuPath('/FrequenciesAndUnits')}
				history={props.history}
				iconSource={<SvgUnitsFrequency />}
			/>,
			<SettingCard
				id="divCompliance"
				key={localizationService.getLocalizedString('authoritySetting.compliance')}
				title={localizationService.getLocalizedString('authoritySetting.compliance')}
				description={localizationService.getLocalizedString('authoritySetting.complianceDesc')}
				link={urlService.getSettingMenuPath('/compliance')}
				history={props.history}
				iconSource={<SvgCompliance />}
			/>,
			<SettingCard
				id="divSettingDevices"
				key={localizationService.getLocalizedString('authoritySetting.devices')}
				title={localizationService.getLocalizedString('authoritySetting.devices')}
				description={localizationService.getLocalizedString('authoritySetting.deviceNumbersDesc')}
				link={urlService.getSettingMenuPath('/devices')}
				history={props.history}
				iconSource={<SvgDevices />}
			/>,
			<SettingCard
				id="divSettingUnits"
				key={localizationService.getLocalizedString('authoritySetting.units')}
				title={localizationService.getLocalizedString('authoritySetting.units')}
				description={localizationService.getLocalizedString('authoritySetting.unitsDesc')}
				link={urlService.getSettingMenuPath('/units')}
				history={props.history}
				iconSource={<SvgUnits />}
			/>,
			<SettingCard
				id="divSettingEvents"
				key={localizationService.getLocalizedString('authoritySetting.events')}
				title={localizationService.getLocalizedString('authoritySetting.events')}
				description={localizationService.getLocalizedString('authoritySetting.eventsDesc')}
				link={urlService.getSettingMenuPath('/events')}
				history={props.history}
				iconSource={<SvgUnits />}
			/>
		];

		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};

	const customerSuccessSettings = () => {
		let cards = [
			<SettingCard
				id="divInspectionTemplate"
				title={localizationService.getLocalizedString('authoritySetting.inspectionTemplates')}
				key={localizationService.getLocalizedString('authoritySetting.inspectionTemplates')}
				description={localizationService.getLocalizedString('authoritySetting.inspectionTemplatesCard')}
				link={urlService.getSettingMenuPath('/inspectionTemplates')}
				history={props.history}
				iconSource={<SvgInspections />}
			/>,
			<SettingCard
				id="divCustomerSuccessUser"
				title={localizationService.getLocalizedString('authoritySetting.customerSuccessUsers')}
				key={localizationService.getLocalizedString('authoritySetting.customerSuccessUsers')}
				description={localizationService.getLocalizedString('authoritySetting.customerSuccessUsersCard')}
				link={urlService.getSettingMenuPath('/customerSuccessUsers')}
				history={props.history}
				iconSource={<SvgUser />}
			/>,
			<SettingCard
				id="divMaintenanceMessage"
				title={localizationService.getLocalizedString('systemSupportOnlySettings.maintenanceMessage')}
				key={localizationService.getLocalizedString('systemSupportOnlySettings.maintenanceMessage')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.maintenanceMessageDescription'
				)}
				link={urlService.getSettingMenuPath('/maintenanceMessage')}
				history={props.history}
				iconSource={<SvgMaintenance />}
			/>,
			<SettingCard
				id="divAuthorities"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.viewAuthoritiesDescription'
				)}
				link={urlService.getSettingMenuPath('/authorities')}
				history={props.history}
				iconSource={<SvgAuthorities />}
			/>,
			<SettingCard
				id="divOperationLog"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.operationLog')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.operationLog')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.operationLogDescription'
				)}
				link={urlService.getSettingMenuPath('/operationLog')}
				history={props.history}
				iconSource={<SvgOperationLog />}
			/>,
			<SettingCard
				id="divDataConnectorNotices"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.dataConnectorNotices')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.dataConnectorNotices')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.dataConnectorNoticesDescription'
				)}
				link={urlService.getSettingMenuPath('/dataConnectorNotices')}
				history={props.history}
				iconSource={<SvgEmail />}
			/>
		];
		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};

	return (
		<LandingPage
			history={props.history}
			authoritySettings={authoritySettings()}
			customerSuccessOnlySettings={customerSuccessSettings()}
		/>
	);
};

export const AuthoritySettingsLandingPage = connect()(AuthoritySettingsLandingPageComponent);
