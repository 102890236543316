import { ApiError, DataImport } from '@rcp/types';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { InlineInfoSvg, InlineWarningSvg, RadioGroup, TextInput } from 'src/components';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService } from 'src/redux';
import { localizationService, navigateTo, urlService } from 'src/services';
import { importConfigurationSlice } from '../import-configuration.slice';
import { SelfImportStep3Data } from './step3-map-configuration';
import { SelfImportStep4Data } from './step4-preview';
import {
	disableSelfImportStepsState,
	getImportDomainObjectLabel,
	getSaveImportConfigurationOptions,
	resetAllSelfImportStepStates,
	SelfImportSteps
} from './self-import-service';
import { SelfImportStep1Data } from './step1-data-type';
import { ImportUtils } from 'src/services/import-utils';
import { Link } from 'react-router-dom';

export interface SelfImportStep5Data {
	saveConfiguration: string;
	configurationName: string;
	saveConfigurationError: string;
	isDraft: boolean;
}

export const initialSelfImportStep5Data: SelfImportStep5Data = {
	saveConfiguration: localizationService.getLocalizedString('constants.yes'),
	configurationName: '',
	saveConfigurationError: '',
	isDraft: false
};

interface Props {}

export const SelfImportStep5: React.FC<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const step1Data: SelfImportStep1Data = stepperContext.getData(SelfImportSteps.STEP1);
	const step3Data: SelfImportStep3Data = stepperContext.getData(SelfImportSteps.STEP3);
	const step4Data: SelfImportStep4Data = stepperContext.getData(SelfImportSteps.STEP4);
	const [state, setState] = React.useState<SelfImportStep5Data>(initialSelfImportStep5Data);
	const importHistoryBaseUrl = urlService.getSettingPath() + '/import/history';

	const history = useHistory();
	const dispatch = useDispatch();

	React.useEffect(() => {
		setState({ ...state, isDraft: step3Data.isDraft });
		stepperContext.resolve('');
		disableSelfImportStepsState(
			stepperContext,
			SelfImportSteps.STEP1,
			SelfImportSteps.STEP2,
			SelfImportSteps.STEP3,
			SelfImportSteps.STEP4
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step3Data]);

	const startAnotherImport = (event: React.FormEvent) => {
		resetAllSelfImportStepStates(stepperContext);
		urlService.removeUrlQueryParam('importFileId', 'configurationId', 'dataType', 'step');
		stepperContext.goAt(SelfImportSteps.STEP1);
	};

	const onSaveImportConfigurationChanged = (e: any) => {
		var value = e.target.value;
		setState({ ...state, saveConfiguration: value });
	};

	const onConfigurationNameChanged = (e: any) => {
		var value = e.target.value;
		setState({ ...state, configurationName: value });
	};

	const saveConfiguration = (e: any) => {
		if (_.isEmpty(state.configurationName)) {
			let error = localizationService.getLocalizedString('screen.validationMessage.fieldIsRequired');
			setState({ ...state, saveConfigurationError: error });
			return;
		}
		dispatch(
			importConfigurationSlice.patchOne(
				step3Data.importConfigurationId,
				{
					name: state.configurationName,
					isDraft: false
				},
				false,
				undefined,
				() => {
					alertService.addSuccess(
						localizationService.getLocalizedString('import.steps.saveImportDataSourceSettingsSuccessfully')
					);
					setState({ ...state, isDraft: false, saveConfigurationError: '' });
				},
				(error: ApiError) => {
					if (error.message) {
						setState({ ...state, saveConfigurationError: error.message });
					}
				},
				true
			)
		);
	};

	const getImportGridLink = (
		importId: number,
		urlPath: string,
		importDataType: DataImport.ImportEntity,
		forNew: boolean,
		number?: number
	) => {
		if (!(number && number > 0)) {
			return <></>;
		}
		let link = `/fog/settings/import/history/${importId}/${urlPath}/${forNew ? 'new' : 'updated'}`;
		let name = ImportUtils.getImportEntityLabel(importDataType, number);
		let localeKey = forNew ? 'import.steps.viewImportNewItems' : 'import.steps.viewImportUpdatedItems';
		let linkText = localizationService.getLocalizedString(localeKey, _.toString(number), name);
		return (
			<p>
				<a href={link} onClick={e => navigateTo(history, link, e)}>
					{linkText}
				</a>
			</p>
		);
	};

	const getImportResultLink = (importId: number, forNew: boolean) => {
		switch (step1Data.dataType) {
			case DataImport.ImportDataTypeValues.FogFacilitiesAndContacts:
				const facilitiesImported = forNew ? step4Data.newFogFacilityNumber : step4Data.updatedFogFacilityNumber;
				return getImportGridLink(
					importId,
					'facilities',
					DataImport.ImportEntity.FogFacility,
					forNew,
					facilitiesImported
				);
			case DataImport.ImportDataTypeValues.FogDevices:
				const devicesImported = forNew ? step4Data.newFogDeviceNumber : step4Data.updatedFogDeviceNumber;
				return getImportGridLink(
					importId,
					'devices',
					DataImport.ImportEntity.FogDevice,
					forNew,
					devicesImported
				);
			case DataImport.ImportDataTypeValues.FogInspections:
				const inspectionsImported = forNew
					? step4Data.newFogInspectionEventNumber
					: step4Data.updatedFogInspectionEventNumber;
				return getImportGridLink(
					importId,
					'inspections',
					DataImport.ImportEntity.FogInspection,
					forNew,
					inspectionsImported
				);
			case DataImport.ImportDataTypeValues.FogHaulers:
				const haulersImported = forNew ? step4Data.newFogHaulerNumber : step4Data.updatedFogHaulerNumber;
				return getImportGridLink(
					importId,
					'haulers',
					DataImport.ImportEntity.FogHauler,
					forNew,
					haulersImported
				);
			case DataImport.ImportDataTypeValues.FogCleanings:
				const cleaningsImported = forNew ? step4Data.newFogCleaningNumber : step4Data.updatedFogCleaningNumber;
				return getImportGridLink(
					importId,
					'cleanings',
					DataImport.ImportEntity.FogCleaning,
					forNew,
					cleaningsImported
				);
			case DataImport.ImportDataTypeValues.FogEvents:
				const eventsImported = forNew
					? step4Data.newFogGenericEventNumber
					: step4Data.updatedFogGenericEventNumber;
				return getImportGridLink(importId, 'events', DataImport.ImportEntity.FogEvent, forNew, eventsImported);
			default:
				return <></>;
		}
	};

	const totalRowNumber = _.get(step3Data, 'totalRowNumber') as number;

	return (
		<StepperContent
			id={SelfImportSteps.STEP5}
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={startAnotherImport}>
						{localizationService.getLocalizedString('screen.buttons.startAnotherImport')}
					</StepperAction>
				</React.Fragment>
			}>
			{totalRowNumber >= 100 && ( //import file is time costly, remind user to check result later.
				<>
					<div className="alert ai-inline-alert alert-success">
						<span className="mr-2">
							<InlineInfoSvg />
						</span>
						<span>
							{localizationService.getLocalizedString('importFile.step3ImportSuccessStart')}&nbsp;
							<Link to={importHistoryBaseUrl} rel="noopener noreferrer">
								{localizationService.getLocalizedString('importHistory.importHistory')}
							</Link>
							&nbsp;
							{localizationService.getLocalizedString('importFile.page')}
						</span>
					</div>
				</>
			)}
			{totalRowNumber < 100 && ( //import file is small, directly display import result
				<>
					{step4Data.successRowNumber > 0 && (
						<>
							<div className="alert ai-inline-alert alert-success">
								<span className="mr-2">
									<InlineInfoSvg />
								</span>
								<span>
									{localizationService.getLocalizedString(
										'import.steps.importSuccessMessage',
										_.toString(step4Data.successRowNumber),
										_.toString(totalRowNumber),
										_.toLower(getImportDomainObjectLabel(step4Data.importDataType))
									)}
								</span>
							</div>
							{getImportResultLink(step4Data.importId, true)}
							{getImportResultLink(step4Data.importId, false)}
						</>
					)}
					{step4Data.errorRowNumber > 0 && (
						<div className="alert ai-inline-alert alert-warning">
							<span className="mr-2">
								<InlineWarningSvg />
							</span>
							<span>
								{localizationService.getLocalizedString(
									'import.steps.importErrorMessage',
									_.toString(step4Data.errorRowNumber)
								)}
								&nbsp;
								{step4Data.errorFileUrl && (
									<>
										<a href={step4Data.errorFileUrl} target="_blank">
											{localizationService.getLocalizedString('import.steps.importErrorLinkText')}
										</a>
										{localizationService.getLocalizedString(
											'import.steps.importErrorLinkFollowingText'
										)}
									</>
								)}
							</span>
						</div>
					)}
				</>
			)}
			{state.isDraft && ( //remind the user save import draft configuration
				<>
					<RadioGroup
						id="saveImportConfiguration"
						name="saveImportConfiguration"
						label={localizationService.getLocalizedString(
							'import.steps.saveImportDataSourceSettingsQuestion'
						)}
						options={getSaveImportConfigurationOptions()}
						value={state.saveConfiguration}
						isRequired={true}
						onChange={onSaveImportConfigurationChanged}
					/>

					{state.saveConfiguration === localizationService.getLocalizedString('constants.yes') && (
						<>
							<div className="form-row">
								<TextInput
									className="form-group col-8"
									id="configurationName"
									isRequired={true}
									name="configurationName"
									showErrorAndHelp={true}
									error={state.saveConfigurationError}
									label={localizationService.getLocalizedString('import.steps.sourceOfData')}
									value={state.configurationName}
									onChange={onConfigurationNameChanged}
								/>
							</div>
							<button type="button" className="btn ai-save" onClick={saveConfiguration}>
								{localizationService.getLocalizedString('screen.buttons.save')}
							</button>
						</>
					)}
				</>
			)}
		</StepperContent>
	);
};
