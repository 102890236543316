import React from 'react';

interface Props {}

export const AlertErrorSvg: React.SFC<Props> = props => {
	return (
		<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<path d="M12.11,16.3H5.89L1.5,11.91V5.7L5.89,1.3h6.22L16.5,5.7v6.21Z" style={{ fill: '#d5312a' }}></path>
			<path
				d="M6.1,1.8,2,5.9v5.8l4.1,4.1h5.8L16,11.7V5.9L11.9,1.8H6.1M5.69.8h6.62L17,5.49v6.63L12.31,16.8H5.69L1,12.12V5.49Z"
				style={{ fill: '#fff' }}></path>
			<path
				d="M10.13,9l2.22-2.22a.5.5,0,0,0,0-.71l-.42-.42a.5.5,0,0,0-.71,0L9,7.87,6.78,5.65a.5.5,0,0,0-.71,0l-.42.42a.5.5,0,0,0,0,.71L7.87,9,5.65,11.22a.5.5,0,0,0,0,.71l.42.42a.5.5,0,0,0,.71,0L9,10.13l2.22,2.22a.5.5,0,0,0,.71,0l.42-.42a.5.5,0,0,0,0-.71Z"
				style={{ fill: '#fff' }}></path>
		</svg>
	);
};
