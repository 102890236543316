import { AuthorityUser, InternalUser } from '@rcp/types';
import { RestSlice } from '../../../../../redux/rest.slice';
import { Resource, localizationService } from 'src/services';

export const authorityUsersSlice = new RestSlice<AuthorityUser>(
	localizationService.getLocalizedString('users.users'),
	Resource.Users
);
export const authorityUsersSliceReducer = authorityUsersSlice.rests.reducer;

export const internalUsersSlice = new RestSlice<InternalUser>(
	localizationService.getLocalizedString('users.customerSuccessUsers'),
	Resource.InternalUsers
);
export const internalUsersSliceReducer = internalUsersSlice.rests.reducer;
