import React from 'react';

interface Props {}

export const InlineWarningSvg: React.FC<Props> = props => {
	return (
		<svg id="inline-warning" xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
			<g id="Group_10617" data-name="Group 10617">
				<g id="Path_689" data-name="Path 689" transform="translate(-0.529 -1.515)" fill="none">
					<path
						d="M18.3,14.969,10.983,2.355a1.678,1.678,0,0,0-2.908,0L.761,14.969a1.684,1.684,0,0,0-.005,1.7,1.663,1.663,0,0,0,1.46.85H16.843a1.663,1.663,0,0,0,1.46-.85A1.684,1.684,0,0,0,18.3,14.969Z"
						stroke="none"
					/>
					<path
						d="M 16.84264755249023 16.51499938964844 C 17.09310722351074 16.51499938964844 17.30863761901855 16.38908004760742 17.4339771270752 16.16951942443848 C 17.56103706359863 15.94694995880127 17.56023597717285 15.69209003448486 17.43201637268066 15.47071075439453 L 10.1190071105957 2.858010292053223 C 9.992166519165039 2.640020370483398 9.777067184448242 2.515000343322754 9.528866767883301 2.515000343322754 C 9.28089714050293 2.515000343322754 9.066466331481934 2.639550447463989 8.940526962280273 2.856730461120605 L 1.625956773757935 15.47030067443848 C 1.497496724128723 15.69209003448486 1.496696710586548 15.94694995880127 1.623756766319275 16.16951942443848 C 1.749096751213074 16.38908004760742 1.964626789093018 16.51499938964844 2.215086698532104 16.51499938964844 L 16.84264755249023 16.51499938964844 M 16.84264755249023 17.51499938964844 L 2.215086698532104 17.51499938964844 C 1.604186773300171 17.51499938964844 1.059166789054871 17.19757080078125 0.7553067803382874 16.6653003692627 C 0.4514467716217041 16.1330394744873 0.4535767734050751 15.49923992156982 0.7606167793273926 14.9691104888916 L 8.075456619262695 2.355080366134644 C 8.380376815795898 1.829230308532715 8.923276901245117 1.515000343322754 9.528866767883301 1.515000343322754 C 10.13445663452148 1.515000343322754 10.6773567199707 1.829230308532715 10.98333644866943 2.355080366134644 L 18.29711723327637 14.9691104888916 C 18.60415649414062 15.49923992156982 18.60628700256348 16.1330394744873 18.30242729187012 16.6653003692627 C 17.99856758117676 17.19757080078125 17.45354652404785 17.51499938964844 16.84264755249023 17.51499938964844 Z"
						stroke="none"
						fill="#241c15"
					/>
				</g>
			</g>
			<g id="Group_10635" data-name="Group 10635" transform="translate(7.953 5.157)">
				<rect
					id="Rectangle_428"
					data-name="Rectangle 428"
					width="1.615"
					height="5.085"
					transform="translate(0.268)"
					fill="#241c15"
				/>
				<path
					id="Path_691"
					data-name="Path 691"
					d="M9.75,13.92A1.023,1.023,0,0,1,9,14.228a1.042,1.042,0,0,1-1.058-1.061,1.057,1.057,0,1,1,1.807.753Z"
					transform="translate(-7.943 -6.228)"
					fill="#241c15"
				/>
			</g>
		</svg>
	);
};

