import React, { useEffect, useState } from 'react';
import { History } from 'history';
import _ from 'lodash';
import {
	ColumnField,
	FilterType,
	LocalStorageName,
	SystemAuthority,
	RouteProps,
	FeatureNames,
	RegulatoryProgramName
} from '@rcp/types';
import { GridOption, DataGrid } from '../../features/data-grid/data-grid';
import { RestSlice } from '../../redux/rest.slice';
import { urlService, localizationService, Resource } from 'src/services';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { systemAuthoritiesSlice } from './systemAuthorities.slice';
import { nameof } from 'ts-simple-nameof';
import { SingleCheckbox } from 'src/components/widgets';
import { useDispatch } from 'react-redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CustomerSuccessSettingAuthoritiesComponent: React.FunctionComponent<Props<SystemAuthority>> = props => {
	const dispatch = useDispatch();
	const currentUser = useReduxSelector(state => state.userProfile);

	const issuedRegulatoryProgramsToLink = (item: any) => {
		const dataItem = item.dataItem as SystemAuthority;
		return (
			<td>
				{Object.keys(dataItem.issuedRegulatoryPrograms).map((key: string, index: number) => (
					<>
						{index > 0 && <br />}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${urlService.getReactBaseUrlWithProgramName(
								_.toLower(dataItem.subDomain),
								_.toLower(key)
							)}/settings/users`}>
							{key} {dataItem.issuedRegulatoryPrograms[key] === false ? '(Disabled)' : ''}
						</a>
					</>
				))}
			</td>
		);
	};
	const subDomainToLink = (item: any) => {
		const dataItem = item.dataItem as SystemAuthority;
		return (
			<td>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${urlService.getReactBaseUrlForSubDomain(_.toLower(dataItem.subDomain))}`}>
					{dataItem.subDomain}
				</a>
			</td>
		);
	};

	const enableHaulerPortalColumn = (item: any) => {
		const dataItem = _.cloneDeep(item.dataItem) as SystemAuthority;
		return (
			<td>
				{!!dataItem.issuedRegulatoryPrograms.FOG && (
					<SingleCheckbox
						id={`enable-hauler-portal-${dataItem.authorityId}`}
						name="enableHaulerPortal"
						checked={dataItem.isHaulerPortalEnabled}
						onChange={(e: any) =>
							toggleModuleAuthorization(e.target.checked, dataItem, Resource.HaulerPortalStatus)
						}
						className="div-checkbox"
					/>
				)}
			</td>
		);
	};

	const enableFacilityPortalColumn = (item: any) => {
		const dataItem = _.cloneDeep(item.dataItem) as SystemAuthority;
		return (
			<td>
				{!!dataItem.issuedRegulatoryPrograms.FOG && (
					<SingleCheckbox
						id={`enable-facility-portal-${dataItem.authorityId}`}
						name="enableFacilityPortal"
						checked={dataItem.isFacilityPortalEnabled}
						onChange={(e: any) =>
							toggleModuleAuthorization(e.target.checked, dataItem, Resource.FacilityPortalStatus)
						}
						className="div-checkbox"
					/>
				)}
			</td>
		);
	};

	const enableFogLanguageTranslation = (item: any) => {
		const dataItem = _.cloneDeep(item.dataItem) as SystemAuthority;
		return (
			<td>
				{!!dataItem.issuedRegulatoryPrograms.FOG && (
					<SingleCheckbox
						id={`enable-fog-language-translation-${dataItem.authorityId}`}
						name="enableFogLanguageTranslation"
						checked={dataItem.isFogLanguageTranslationEnabled}
						onChange={(e: any) =>
							toggleModuleAuthorization(e.target.checked, dataItem, Resource.FogLanguageTranslationStatus)
						}
						className="div-checkbox"
					/>
				)}
			</td>
		);
	};

	const toggleModuleAuthorization = (checked: boolean, data: SystemAuthority, partialApiPath: string) => {
		alertService.clearAllMessages();
		systemAuthoritiesSlice.setApiUrlPath(Resource.SystemAuthorities + '/' + partialApiPath);

		let localizationKey = '';
		let apiBody = {};

		if (String.equalCI(partialApiPath, Resource.FacilityPortalStatus)) {
			localizationKey = 'systemAuthority.enableFacilityPortal';
			apiBody = { isFacilityPortalEnabled: checked };
		} else if (String.equalCI(partialApiPath, Resource.HaulerPortalStatus)) {
			localizationKey = 'systemAuthority.enableHaulerSuccess';
			apiBody = { isHaulerPortalEnabled: checked };
		} else if (String.equalCI(partialApiPath, Resource.FogLanguageTranslationStatus)) {
			localizationKey = 'systemAuthority.enableFogLanguageTranslation';
			apiBody = { isFogLanguageTranslationEnabled: checked };
		}

		dispatch(
			systemAuthoritiesSlice.patchOne(
				+(data.authorityId || 0),
				apiBody,
				true,
				localizationService.getLocalizedString(
					`systemAuthority.${checked ? 'enableModuleSuccess' : 'disableModuleSuccess'}`,
					localizationService.getLocalizedString(localizationKey),
					data.authorityName || ''
				)
			)
		);
		systemAuthoritiesSlice.setApiUrlPath(Resource.SystemAuthorities);
	};

	const [initialGridOption, setInitialGridOption] = useState<GridOption>({
		pageTitle: localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle'),
		prefix: 'systemAuthority',
		storageName: LocalStorageName.SystemAuthority,

		showEditColumnButton: true,
		doClientSideSorting: true,
		showBackButton: String.equalCaseInsensitive(urlService.getCurrentProgramOrDefault(), RegulatoryProgramName.FOG),
		defaultSort: [{ field: nameof<SystemAuthority>(x => x.authorityId), dir: 'desc' }],
		allColumns: [
			new ColumnField('authorityId'),
			new ColumnField('authorityName'),
			new ColumnField('subDomain', FilterType.Text, subDomainToLink),
			new ColumnField('addressLine1'),
			new ColumnField('addressLine2'),
			new ColumnField('cityName'),
			new ColumnField('state'),
			new ColumnField('zipCode'),
			new ColumnField('issuedRegulatoryPrograms', FilterType.Text, issuedRegulatoryProgramsToLink),
			new ColumnField('enableHaulerPortal', FilterType.Boolean, enableHaulerPortalColumn),
			new ColumnField('enableFacilityPortal', FilterType.Boolean, enableFacilityPortalColumn),
			new ColumnField('enableFogLanguageTranslation', FilterType.Boolean, enableFogLanguageTranslation)
		],
		defaultColumns: [
			'authorityId',
			'authorityName',
			'subDomain',
			'addressLine1',
			'cityName',
			'issuedRegulatoryPrograms',
			'enableHaulerPortal',
			'enableFacilityPortal',
			'enableFogLanguageTranslation'
		]
	});

	return (
		<>
			{currentUser.userProfile.isInternalAccount ? (
				<>
					<DataGrid
						history={props.history}
						match={props.match}
						restSlice={systemAuthoritiesSlice}
						restState={(state: RootState) => state.systemAuthorities}
						gridOption={initialGridOption}
					/>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</>
	);
};

export const CustomerSuccessSettingAuthorities = CustomerSuccessSettingAuthoritiesComponent;
