import React from 'react';
import { useDispatch } from 'react-redux';
import * as LinkoTypes from '@rcp/types';
import { ViolationState, loadAuthorityCorrectiveActions, loadAuthorityEnforcementTypes, RootState } from 'src/redux';
import { localizationService } from 'src/services';
import { LocalStorageName, ColumnField, FilterType, FilterObjectName } from '@rcp/types';
import { FacilityNameCell } from '../inspections/facility-name-cell';
import { ViolationEditActionLink } from './violation-edit-action-link';
import { History } from 'history';
import { EnforcementEditActionLink } from './enforcement-edit-action-link';
import { nanoid } from '@reduxjs/toolkit';
import { customFieldQueries, DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { violationSlice } from './violation.slice';
import { FilterDomain, FilterDomainName, filterService } from '../../../../features/filter/filter-service';
import { GridDataTooltip } from '../../../widgets/grid-data-tooltip';

interface OwnProps {
	history: History;
}
interface StateProps extends ViolationState, LinkoTypes.RouteProps {}
type Props = StateProps & OwnProps;

export const ViolationGridView: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const getTitle = (): string => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'screen.labels.violations');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'screen.labels.violations');
		}
		return localizationService.getLocalizedString('screen.labels.violations');
	};

	React.useEffect(() => {
		dispatch(loadAuthorityEnforcementTypes());
		dispatch(loadAuthorityCorrectiveActions(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const violationTypeActionLink = (props: any) => {
		const violation = props.dataItem as LinkoTypes.Violation;
		return (
			<td>
				<ViolationEditActionLink violation={violation} isFromViolationGrid={true} />
			</td>
		);
	};

	const EnforcementTypeActionLink = (props: any) => {
		const violation = props.dataItem as LinkoTypes.Violation;
		return (
			<td key={nanoid()}>
				<EnforcementEditActionLink violation={violation} isFromViolationGrid={true} />
			</td>
		);
	};

	const facilityNameToLink = (props: any) => {
		const violation = props.dataItem as LinkoTypes.Violation;
		return (
			<td>
				<FacilityNameCell
					facilityName={violation.facilityName}
					facilityId={violation.organizationId}
					authorityId={violation.creatorOrganizationId}
				/>
			</td>
		);
	};

	const displayWrappedComments = (props: any) => {
		let dataItem = props.dataItem;
		return <GridDataTooltip data={dataItem.comments} />;
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'violation',
		storageName: LocalStorageName.ViolationGrid,
		gridUrlLocalStorageName: LocalStorageName.ViolationGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.ViolationSortOrder,

		showActions: true,
		showActionExport: true,
		showFilter: true,
		showEditColumnButton: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('violation.searchHint'),
		hasGridMap: true,
		defaultSort: [{ field: 'nonComplianceDate', dir: 'desc' }],
		allColumns: [
			new ColumnField('facilityName', FilterType.Text, facilityNameToLink),
			new ColumnField('referenceNumber', FilterType.Number),
			new ColumnField('addressLine1'),
			new ColumnField('violationTypeTypeName', FilterType.Text, violationTypeActionLink),
			new ColumnField('nonComplianceDate', FilterType.Date),
			new ColumnField('complianceDueDate', FilterType.Date),
			new ColumnField('inComplianceDate', FilterType.Date),
			new ColumnField('daysOpen', FilterType.Number),
			new ColumnField('lastEnforcementType'),
			new ColumnField('lastEnforcementDate', FilterType.Date, EnforcementTypeActionLink),
			new ColumnField('assignedToUserName'),
			new ColumnField('comments', FilterType.Text, displayWrappedComments)
		],
		defaultColumns: [
			'facilityName',
			'referenceNumber',
			'addressLine1',
			'violationTypeTypeName',
			'nonComplianceDate',
			'complianceDueDate',
			'inComplianceDate',
			'daysOpen',
			'lastEnforcementType',
			'lastEnforcementDate',
			'assignedToUserName',
			'comments'
		],
		customFieldQuery: customFieldQueries.Fog,
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.VIOLATION,
				FilterDomainName.violation,
				FilterObjectName.Violation,
				LocalStorageName.ViolationGridFilterUrl,
				appliedDomains
			);
		}
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={violationSlice}
				restState={(state: RootState) => state.violation}
				gridOption={initialGridOption}
			/>
		</>
	);
};
