import { LookupRow } from '../lookup-types';
import { ILookupDataConverter } from './lookup-data-converter';
import _ from 'lodash';

export class CustomizedLookupDataConverter<TResource extends object> implements ILookupDataConverter {
	private toResource: (row: LookupRow) => TResource;
	private toLookupRow: (dto: TResource) => LookupRow;
	private extraLookupRows: LookupRow[];
	constructor(
		toResource: (row: LookupRow) => TResource,
		toLookupRow: (dto: TResource) => LookupRow,
		extraLookupRows: LookupRow[] = []
	) {
		this.toResource = toResource;
		this.toLookupRow = toLookupRow;
		this.extraLookupRows = extraLookupRows;
	}

	toAPIAddDataFromLookups = (existingRows: LookupRow[], newRow: LookupRow): TResource => {
		let dataToPost = this.toResource(newRow);
		_.set(dataToPost, 'isActive', true);
		return dataToPost;
	};

	toAPIDeleteDataFromLookups = (existingRows: LookupRow[], rowToDelete: LookupRow): LookupRow => {
		return rowToDelete;
	};

	toAPIUpdateDataFromLookups = (existingRows: LookupRow[], rowToUpdate: LookupRow): TResource => {
		let dataToUpdate: TResource = this.toResource(rowToUpdate);
		return dataToUpdate;
	};

	toLookupRowsFromAPI = (apiData: any): LookupRow[] => {
		let lookupValues: LookupRow[] = [];
		if (!_.isEmpty(this.extraLookupRows)) {
			_.map(this.extraLookupRows, (r: LookupRow) => {
				lookupValues.push(r);
			});
		}
		if (apiData) {
			let resources: TResource[] = [];
			if (_.isArray(apiData)) {
				resources = apiData as TResource[];
			} else if (_.has(apiData, 'result') && _.isArray(apiData.result)) {
				resources = apiData.result as TResource[];
			} else {
				throw new Error(`Cannot convert api response as list of values`);
			}
			_.map(resources, (resource: TResource) => {
				let lookupRow = this.toLookupRow(resource);
				lookupValues.push(lookupRow);
			});
		}
		return lookupValues;
	};

	toLookupRowFromAPI = (resource: TResource): LookupRow => {
		return this.toLookupRow(resource);
	};
}
