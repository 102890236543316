import React from 'react';
import { Enforcement } from '@rcp/types';
import { localizationService } from 'src/services';
import { IssueEnforcementModal } from '../issue-enforcement-modal';

interface Props {
	enforcement: Enforcement;
	facilityId?: number;
	index: number;
	todayNow: string;
	deleteCallBack: () => void;
	saveCallBack: () => void;
}

export const TimelineEventEnforcement: React.FC<Props> = props => {
	const [editModal, setEditModal] = React.useState(false);

	const openEditModal = (e: any) => {
		e.preventDefault();
		setEditModal(true);
	};

	return (
		<div>
			<div>
				<a href="#/" onClick={openEditModal} id={`linkEnforcement-${props.enforcement.enforcementId}`}>
					{`${props.enforcement.enforcementTypeName}`}
				</a>
				{` ${localizationService.getLocalizedString('enforcement.enforcementTimelineSuffix')}`}
			</div>
			{editModal && (
				<IssueEnforcementModal
					facilityId={props.facilityId}
					closeModal={() => {
						setEditModal(false);
					}}
					enforcement={props.enforcement}
					deleteCallback={props.deleteCallBack}
					saveCallback={props.saveCallBack}
				/>
			)}
		</div>
	);
};
