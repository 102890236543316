import React, { FC } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import './floating-button.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { localizationService } from 'src/services';

interface Props {
	buttonClassName?: string;
	iconClass?: string;
	onClick?: () => void;
}

export const FloatingButton: FC<Props> = props => {
	return (
		<div
			id="floatingButton"
			className={`d-flex floating-button-wrapper cursor-pointer ${props.buttonClassName}`}
			onClick={() => props.onClick && props.onClick()}>
			<div className="floatingButton">
				<span className="floating-text">
					{localizationService.getLocalizedString('customForm.addFormElement')}
				</span>
			</div>
			<div className="floating-icon-wrapper">
				<FontAwesomeIcon icon={faPlus} width={16} height={16} />
			</div>
		</div>
	);
};
