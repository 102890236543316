import {
	ColumnField,
	Dictionary,
	FilterType,
	IppIndustry,
	IppUser,
	LocalStorageName,
	RouteProps,
	SimpleUserProfile
} from '@rcp/types';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { RootState } from 'src/redux';
import { localizationService, navigateTo, tokenService, urlService } from 'src/services';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { ippUserSlice } from './user-list.slice';
import { IppConstants } from 'src/constants';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { nameof } from 'ts-simple-nameof';

interface Props extends RouteProps {
	history: History;
	isIndustryUserModule: () => boolean;
	industryData?: IppIndustry;
	userProfile: SimpleUserProfile;
}

const { queryParams } = IppConstants;
const { initialPage, shortPageSize } = queryParams;

const UserList: FC<Props> = props => {
	const getQueryParams = () => {
		if (props.isIndustryUserModule() || urlService.isIppIndustryPortal()) {
			return {
				page: initialPage,
				size: shortPageSize,
				includeInactive: 'true',
				sort: 'firstName asc, lastName asc',
				industryId: props.match.params.industryId
					? props.match.params.industryId.toString()
					: tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId || ''
			};
		} else {
			return {
				page: initialPage,
				size: shortPageSize,
				includeInactive: 'true',
				sort: 'firstName asc, lastName asc'
			} as Dictionary<string>;
		}
	};

	const userFirstNameToLink = (row: any) => {
		const user = row.dataItem as IppUser;
		const userPath = urlService.getReactAuthorityAccountUserDetailsUrl(user.ippUserId);
		const industryUserPath = urlService.getReactAuthorityResourceUrl(
			`authority/industries/${props.match.params.industryId}/users`,
			user.ippUserId
		);
		const ippIndustryUserPath = urlService.getIppIndustryUrl(`industryAccount/users`, user.ippUserId);
		return (
			<td>
				{!props.isIndustryUserModule() ? (
					urlService.isAdministrator() ? (
						<a
							href={urlService.isIppIndustryPortal() ? ippIndustryUserPath : userPath}
							className="ai-link"
							onClick={(e: any) =>
								navigateTo(
									props.history,
									urlService.isIppIndustryPortal() ? ippIndustryUserPath : userPath,
									e
								)
							}>
							{user.firstName}
						</a>
					) : user.userProfileId === props.userProfile.userProfileId ? (
						<a
							href={userPath}
							className="ai-link"
							onClick={(e: any) =>
								navigateTo(
									props.history,
									urlService.isIppIndustryPortal() ? ippIndustryUserPath : userPath,
									e
								)
							}>
							{user.firstName}
						</a>
					) : (
						user.firstName
					)
				) : (
					<a
						href={userPath}
						className="ai-link"
						onClick={(e: any) =>
							navigateTo(props.history, props.isIndustryUserModule() ? industryUserPath : userPath, e)
						}>
						{user.firstName}
					</a>
				)}
			</td>
		);
	};

	const formatValue = (row: any) => {
		const user = row.dataItem as IppUser;
		return <td>{user.isEnabled ? 'Active' : 'Inactive'}</td>;
	};
	const getAllColumns = () => {
		if (props.isIndustryUserModule()) {
			return [
				new ColumnField('firstName', FilterType.Text, userFirstNameToLink),
				new ColumnField('lastName'),
				new ColumnField('email'),
				new ColumnField('registrationDateTimeUtc', FilterType.Date),
				new ColumnField('phoneNumber'),
				new ColumnField('isEnabled', FilterType.Boolean, formatValue),
				new ColumnField('isSignatory', FilterType.Boolean),
				new ColumnField('isAccountLocked', FilterType.Boolean)
			];
		} else {
			return [
				new ColumnField('firstName', FilterType.Text, userFirstNameToLink),
				new ColumnField('lastName'),
				new ColumnField('email'),
				new ColumnField('registrationDateTimeUtc', FilterType.Date),
				new ColumnField('phoneNumber'),
				new ColumnField('isEnabled', FilterType.Boolean, formatValue),
				new ColumnField('isAccountLocked', FilterType.Boolean)
			];
		}
	};
	const getDefaultColumns = () => {
		if (props.isIndustryUserModule()) {
			return [
				'firstName',
				'lastName',
				'phoneNumber',
				'email',
				'registrationDateTimeUtc',
				'isEnabled',
				'isSignatory',
				'isAccountLocked'
			];
		} else {
			return [
				'firstName',
				'lastName',
				'phoneNumber',
				'email',
				'registrationDateTimeUtc',
				'isEnabled',
				'isAccountLocked'
			];
		}
	};
	const [gridOption, setGridOption] = useState({
		showTabList: false,
		showEditColumnButton: false,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.authorityAccount.userListColumns',
		storageName: LocalStorageName.IppUsersGrid,
		searchPlaceholder: '',
		showActions: false,
		allColumns: getAllColumns(),
		defaultColumns: getDefaultColumns(),
		showRefreshButton: true,
		pageTitle: '',
		activeTab: 'tab-list',
		sort: [],
		defaultSort: [
			{ field: nameof<IppUser>(d => d.firstName), dir: 'asc' },
			{ field: nameof<IppUser>(d => d.lastName), dir: 'asc' }
		],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: getQueryParams(),
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		rerenderOnQueryParamChange: true,
		rerenderOnGridOptionChange: true
	} as GridOption);

	useEffect(() => {
		setGridOption({
			...gridOption,
			allColumns: getAllColumns(),
			defaultColumns: getDefaultColumns(),
			queryParameters: getQueryParams()
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.href]);

	useEffect(() => {
		setGridOption({ ...gridOption, allColumns: getAllColumns(), defaultColumns: getDefaultColumns() });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userProfile.userProfileId]);

	const userState = (state: RootState) => state.ippUsers;
	return (
		<>
			<div className="accordion-data-grid">
				<CollapsibleCard
					accordionType="user-list"
					accordionHeading={localizationService.getLocalizedString('ipp.authorityAccount.users')}>
					<DataGrid
						history={props.history}
						match={props.match}
						restSlice={ippUserSlice}
						restState={userState}
						gridOption={gridOption}></DataGrid>
				</CollapsibleCard>
			</div>
		</>
	);
};

export default UserList;
