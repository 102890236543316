import React from 'react';
import { DropDownOption } from '@rcp/types';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService } from 'src/services';
import { SingleSelectDropdown, DateInput } from '../../components/widgets';
import _ from 'lodash';
import { filterService } from './filter-service';
import { TimeframeValue, valueOfChooseADateOption } from './filter-row-date-is';

export const dateFilterEqOptionsInitial: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.chooseADate'),
		value: valueOfChooseADateOption
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.empty'),
		value: TimeframeValue.Empty
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.notEmpty'),
		value: TimeframeValue.NotEmpty
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.today'),
		value: TimeframeValue.Today
	}
];

export const dateFilterCompareOptionsInitial: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.chooseADate'),
		value: valueOfChooseADateOption
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.today'),
		value: TimeframeValue.Today
	}
];

interface FilterDateCompareProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
	options: DropDownOption[];
}

export const FilterDateCompare: React.FC<FilterDateCompareProps> = props => {
	const onValueChanged = (value: string, index: number) => {
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (newFilterItem.values.length !== 2) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 2 on date eq filter`);
		}
		if (index === 0) {
			newFilterItem.values[0] = {
				value: value
			};
			_.unset(newFilterItem.values[1], 'value');
		} else if (index === 1) {
			newFilterItem.values[1] = {
				value: value
			};
		} else {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on date eq filter`);
		}
		props.updateFilter(newFilterItem);
	};

	let isOptionValue = filterService.getFilterValue(props.filterItem, 0);
	let chooseADateValue = filterService.getFilterValue(props.filterItem, 1);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenValue0_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenValue0_${props.rowNumber}`}
					name={`DateBetweenValue0_${props.rowNumber}`}
					className={`custom-select ${isOptionValue.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 0);
					}}
					value={isOptionValue.value as string}
					options={props.options}
					isRequired={true}
					withoutWrappingDiv={true}
					selfOrder={true}
				/>
				{isOptionValue.error && <div className="invalid-feedback">{isOptionValue.error}</div>}
			</div>

			{isOptionValue.value === valueOfChooseADateOption && (
				<div className={inputGroupCss}>
					<label htmlFor={`DateBetweenValue1_${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.date')}
					</label>
					<DateInput
						id={`DateBetweenValue1_${props.rowNumber}`}
						name={`DateBetweenValue1_${props.rowNumber}`}
						className={` ${chooseADateValue.error ? ' is-invalid' : ' '}`}
						error={chooseADateValue.error}
						value={chooseADateValue.value}
						onChange={(e: any) => {
							onValueChanged(e.target.value, 1);
						}}
						withoutWrappingDiv={true}
					/>
					{chooseADateValue.error && (
						<div className="invalid-feedback error-block">{chooseADateValue.error}</div>
					)}
				</div>
			)}
		</>
	);
};
