import React, { useState } from 'react';
import { CccSite } from '@rcp/types';
import { localizationService } from 'src/services';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { CccSiteDetailsMainModal } from './site-details-address-modal';

interface OwnProps {
	currentCccSite: CccSite;
}

type Props = OwnProps;

interface AddressState {
	siteNumber?: string;
	company?: string;
	contactFirstName?: string;
	contactLastName?: string;
	address?: string;
	cityName?: string;
	state?: string;
	zipCode?: string;
	email?: string;
	phone?: string;
	cityState?: string;
}

const initialAddressState: AddressState = {
	siteNumber: '',
	company: '',
	contactFirstName: '',
	contactLastName: '',
	address: '',
	cityName: '',
	zipCode: '',
	email: '',
	phone: '',
	state: '',
	cityState: ''
};

export const CccSiteDetailsMain: React.FC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);
	const [addressState, setAddressState] = React.useState(initialAddressState);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	// eslint-disable-next-line
	const toggle = () => {
		setIsToggle(!isToggle);
	};

	React.useEffect(() => {
		setAddressStateFromProps(props.currentCccSite);
	}, [props.currentCccSite]);

	const setAddressStateFromProps = (site: CccSite) => {
		let newState = {
			siteNumber: site.siteNumber || '',
			company: site.company || '',
			contactFirstName: site.contactFirstName || '',
			contactLastName: site.contactLastName || '',
			address:
				site.addressLine1 && site.addressLine2
					? `${site.addressLine1}, ${site.addressLine2}`
					: site.addressLine1
					? site.addressLine1
					: '',
			cityState:
				site.cityName && site.state
					? `${site.cityName}, ${site.state}`
					: site.cityName
					? site.cityName
					: site.state
					? site.state
					: '',
			cityName: site.cityName || '',
			state: site.state || '',
			zipCode: site.zipCode || '',
			email: site.email || '',
			phone: site.phone || ''
		};
		setAddressState(newState);
	};

	return (
		<section className="address-card">
			<div className="d-flex flex-row">
				<div className="pr-2">
					<h2 id="addressCompany">{`${addressState.company}`}</h2>
				</div>
				<div className="ml-auto d-print-none">
					<a href="#/" onClick={onEditClicked} id="linkEditCccSite">
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>

			<div>
				<div className="map-div d-flex flex-row ">
					<div className="form-row w-100">
						<div className="form-group-width-2col">
							{addressState.address ||
							addressState.cityName ||
							addressState.cityState ||
							addressState.state ||
							addressState.zipCode ? (
								<>
									<div>
										<span className="map-marker pr-2">
											<FaMapMarkerAlt />
										</span>
										{addressState.address}
									</div>
									<div className="pl-4 mt-n1">{addressState.cityState}</div>
									<div className="pl-4 mt-n1">{addressState.zipCode}</div>
								</>
							) : (
								<div>{localizationService.getLocalizedString('screen.labels.noAddressYet')}</div>
							)}
						</div>

						<div className="form-group-width-2col">
							{(addressState.contactLastName || addressState.contactFirstName) && (
								<div className="pl-4 " id="addressContact">
									{localizationService.getLocalizedString('cccSite.contact')}:&nbsp;
									{addressState.contactFirstName}&nbsp;{addressState.contactLastName}
								</div>
							)}

							{addressState.phone && (
								<div className="mt-n1 pl-4" id="addressPhone">
									{localizationService.getLocalizedString('screen.labels.contactPhone')}:&nbsp;
									<a href={'tel:' + addressState.phone}>{addressState.phone}</a>
								</div>
							)}
							{addressState.email && (
								<div className="mt-n1 pl-4 " id="addressEmail">
									<a href={'mailto:' + addressState.email}>{addressState.email}</a>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			{isToggle && (
				<CccSiteDetailsMainModal isToggle={isToggle} toggle={toggle} currentCccSite={props.currentCccSite} />
			)}
		</section>
	);
};
