import { ThunkAction } from 'redux-thunk';
import * as Authentication from './authentication-actions';
import { apiService, urlService } from 'src/services';
import { alertService } from '../alert/alert-service';
import { TranslationLanguage } from '@rcp/types';

type AuthenticationThunkAction = ThunkAction<
	any,
	Authentication.AuthenticationState,
	any,
	Authentication.AuthenticationAction
>;

export const authenticateUser = (userName: string, password: string): AuthenticationThunkAction => async (
	dispatch,
	getState
) => {
	dispatch(Authentication.authenticationRequest());
	try {
		const userInfo = { userName, password };
		const authenticationUrl = urlService.getAuthenticationResourceApiUrl();
		const signinResult = await apiService.httpPost(authenticationUrl, userInfo);
		dispatch(Authentication.authenticationSuccess(signinResult));
		return signinResult;
	} catch (error) {
		dispatch(Authentication.authenticationError(error));
	}
};

export const temporaryUserChangePassword = (
	email: string,
	oldPassword: string,
	newPassword: string
): AuthenticationThunkAction => async (dispatch, getState) => {
	dispatch(Authentication.authenticationRequest());
	try {
		const userInfo = { email, oldPassword, newPassword };
		const temporaryUserChangePasswordUrl = urlService.getTemporaryChangePasswordApiUrl();
		let signinResult = await apiService.httpPost(temporaryUserChangePasswordUrl, userInfo);
		dispatch(Authentication.authenticationSuccess(signinResult));
		return signinResult;
	} catch (error) {
		alertService.addError(error.message);
		return false;
	}
};
