import React from 'react';
import { InspectionEvent } from '@rcp/types';
import { useHistory } from 'react-router';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { DateUtilService, localizationService, localStorageService, navigateTo, urlService } from 'src/services';
import _ from 'lodash';
import { LocalStorageName } from '@rcp/types';

interface actionProp {
	clickOnOpenInspection: (isStartInspection: boolean) => void;
	dueInspectionList: InspectionEvent[];
	navigateToInspectionDetails?: (e: any) => void;
}

export const FacilityStartInspectionDropdown: React.SFC<actionProp> = props => {
	const history = useHistory();
	const navigateToInspectionDetails = (inspectionEventId: number) => {
		const facilityPath = urlService.getCurrentFacilityDetailsUrl();
		const inspectionPath = facilityPath + '/inspections/' + inspectionEventId;
		localStorageService.setLocalStorage(LocalStorageName.InspectionDetailRedictFromPath as string, facilityPath);
		navigateTo(history, inspectionPath, null, facilityPath);
	};

	return (
		<DropdownMenu>
			<DropdownItem id="newInspectionOption" tag="a" onClick={() => props.clickOnOpenInspection(true)}>
				{localizationService.getLocalizedString('screen.buttons.newInspection')}
			</DropdownItem>
			{_.sortBy(props.dueInspectionList, inspection => {
				return inspection.dueDate;
			}).map((inspection: InspectionEvent) => {
				return (
					!inspection.completeDate && (
						<DropdownItem
							id={`newInspectionOption_${inspection.inspectionEventId}`}
							tag="a"
							onClick={(e: any) => {
								props.navigateToInspectionDetails
									? props.navigateToInspectionDetails(e)
									: navigateToInspectionDetails(inspection.inspectionEventId as number);
							}}>
							{`${inspection.inspectionType} - ${localizationService.getLocalizedString(
								'inspection.scheduledFor'
							)} ${DateUtilService.toDisplayDate(inspection.dueDate, 'MMMM D, YYYY')}`}
						</DropdownItem>
					)
				);
			})}
		</DropdownMenu>
	);
};
