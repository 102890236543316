import React, { FC } from 'react';
import { navigateTo } from 'src/services';
import { History } from 'history';
import './feature-summary.scss';

interface Props {
	title: string;
	count: number;
	backgroundColorClass: string;
	isFontColorWhite?: boolean;
	navigationUrl?: string;
	history?: History;
}

const FeatureSummary: FC<Props> = (props: Props) => {
	return (
		<div className={`feature-container `}>
			<div
				className={`feature-count ${props.backgroundColorClass}`}
				style={{ color: props.isFontColorWhite ? '#fff' : '#444' }}>
				<label>{props.count}</label>
			</div>
			{props.navigationUrl ? (
				<div className="feature-text-container">
					<a
						href={props.navigationUrl}
						onClick={(e: any) => props.history && navigateTo(props.history, props.navigationUrl, e)}
						className="feature-text">
						{props.title}
					</a>
				</div>
			) : (
				<div className="feature-text">{props.title}</div>
			)}
		</div>
	);
};

export default FeatureSummary;
