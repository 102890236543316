import { IppPendingRegistrationUserState, initialIppPendingRegistrationUserState } from './pending-registration-user-state';
import { IppPendingRegistrationUserDataAction } from './pending-registration-user-action-creators';
import EIppPendingRegistrationUserActionType from './pending-registration-user-action-types';

export const ippPendingRegistrationUserReducer = (
	state = initialIppPendingRegistrationUserState,
	action: IppPendingRegistrationUserDataAction
): IppPendingRegistrationUserState => {
	switch (action.type) {
		case EIppPendingRegistrationUserActionType.FetchIppPendingRegistrationUserData:
			return { ...state, ...action.payload };
		case EIppPendingRegistrationUserActionType.UpdateIppPendingRegistrationUserData:
		case EIppPendingRegistrationUserActionType.ApprovePendingRegistration:
		case EIppPendingRegistrationUserActionType.DenyPendingRegistration:
			return {...state, ...action.payload} as IppPendingRegistrationUserState;
		default:
			return state;
	}
};
