import React from 'react';
import { Enforcement, Violation } from '@rcp/types';
import { IssueEnforcementModal } from '../facilities/facility-details/issue-enforcement-modal';
import { apiService, DateUtilService, Resource, urlService } from 'src/services';

interface OwnProps {
	violation: Violation;
	deleteViolationCallBack?: () => void;
	getAllViolations?: () => void;
	isFromViolationGrid?: boolean;
}

export const EnforcementEditActionLink: React.SFC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const [enforcement, setEnforcement] = React.useState({} as Enforcement);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		const enforcementsUrl = urlService.getFacilityResourceApiUrl(
			props.violation.organizationId as number,
			Resource.Enforcements,
			props.violation.lastEnforcementId as number
		);
		apiService.getResource<Enforcement>(enforcementsUrl).then((res: Enforcement) => {
			setEnforcement(res);
			setShowModal(true);
		});
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	return (
		<>
			<a href="#/" className="ai-link violationType-link" onClick={onEditClicked}>
				{props.violation.lastEnforcementDate ? formatDate(props.violation.lastEnforcementDate) : null}
			</a>
			{showModal && (
				<IssueEnforcementModal
					facilityId={enforcement.organizationId}
					closeModal={() => {
						setShowModal(false);
					}}
					key={props.violation.violationId}
					enforcement={enforcement}
					isDispatchLoadEnforcementTypeRequired={false}
					isDispatchLoadCorrectiveActionsRequired={false}
					deleteCallback={props.getAllViolations}
					saveCallback={props.getAllViolations}
					isFromViolationGrid={props.isFromViolationGrid}
				/>
			)}
		</>
	);
};
