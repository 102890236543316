import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageLoadingContainer } from '../layout/page-loading-container';
import { CloseSvg } from '../svg';
import { Translate } from './translate/translator';

interface SubmitProps {
	showModal: boolean;
	title: string;
	saveButtonText?: string;
	save?: (e?: any) => void;
	cancel: (e?: any) => void;
	footer?: JSX.Element;
	className?: string;
	saveButtonColor?: string;
	withoutForm?: boolean;
	saveButtonClassName?: string;
	withoutCloseButton?: boolean;
	subtitle?: string;
	description?: string;
}

export const PopoverModal: React.FC<SubmitProps> = props => {
	React.useEffect(() => {
		document.addEventListener('keydown', escFunction, false);
		return () => {
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const escFunction = React.useCallback(event => {
		if (event.key === 'Escape') {
			toggleModal(event);
			event.preventDefault();
			event.stopPropagation();
		}
	}, []);

	const toggleModal = (e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		props.cancel();
	};

	const closeBtn = (
		<button className="close" onClick={(e?: any) => toggleModal(e)}>
			<CloseSvg />
		</button>
	);

	const getSaveButtonColor = () => {
		if (props.saveButtonClassName) {
			return '';
		}

		return props.saveButtonColor ? props.saveButtonColor : 'btn ai-save';
	};
	const modalComponent = (
		<Modal
			isOpen={props.showModal}
			toggle={(e?: any) => toggleModal(e)}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}
			className={props.className ? props.className : ''}>
			<PageLoadingContainer />
			<ModalHeader
				tag="h1"
				toggle={(e?: any) => toggleModal(e)}
				close={props.withoutCloseButton ? <span /> : closeBtn}>
				<Translate>{props.title}</Translate>
			</ModalHeader>
			{props.subtitle && <h2 className="preview-subtitle">{props.subtitle}</h2>}
			{props.description && <span className="ai-popover-description">{props.description}</span>}
			<ModalBody>{props.children}</ModalBody>
			<ModalFooter>
				{props.footer ? (
					props.footer
				) : (
					<>
						<Button
							color={getSaveButtonColor()}
							className={props.saveButtonClassName ? props.saveButtonClassName : ''}
							onClick={props.save}>
							<Translate>{props.saveButtonText ? props.saveButtonText : 'Save'}</Translate>
						</Button>
						<Button color="btn ai-white" onClick={toggleModal}>
							<Translate>Cancel</Translate>
						</Button>
					</>
				)}
			</ModalFooter>
		</Modal>
	);
	if (true === props.withoutForm) {
		return modalComponent;
	}
	return (
		<form
			onSubmit={e => {
				e.preventDefault();
			}}>
			{modalComponent}
		</form>
	);
};
