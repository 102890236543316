import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiService, Resource, urlService } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { PaginatedResult } from '@rcp/types';

export interface ImportHistoryState {
	paginatedAuthorityImportHistory: PaginatedResult<ApiTypes.Import>;
}

export const initialImportHistoryState: ImportHistoryState = {
	paginatedAuthorityImportHistory: {
		page: 1,
		size: 10,
		total: 0,
		result: []
	}
};

export type ImportHistoryThunkAction = ThunkAction<any, ImportHistoryState, any, Action>;

export enum ImportHistoryActionType {
	LoadImportHistory = 'LoadImportHistory'
}

export const loadAuthorityImportHistory = (queryString: string): ImportHistoryThunkAction => async (dispatch: any) => {
	let url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Imports/Histories`;

	if (queryString) {
		url = `${url}?${queryString}`;
	}

	const result = await apiService.httpGet(`${url}`);

	dispatch({ type: ImportHistoryActionType.LoadImportHistory, paginatedAuthorityImportHistory: result });
};

export const importHistoryReducer = (state = initialImportHistoryState, action: any): ImportHistoryState => {
	switch (action.type) {
		case ImportHistoryActionType.LoadImportHistory:
			return { ...state, paginatedAuthorityImportHistory: action.paginatedAuthorityImportHistory };
		default:
			return { ...state };
	}
};
