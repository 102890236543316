import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { siteSlice } from '../sites.slice';
import { PopoverModal, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { CccSite, DropDownOption, Jurisdiction } from '@rcp/types';
import { localizationService, urlService, validationService } from 'src/services';
import { alertService, loadJurisdiction, RootState } from 'src/redux';
import _ from 'lodash';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentCccSite: CccSite;
}

type Props = OwnProps;

interface OverviewForm {
	siteNumber?: string;
	company?: string;
	contactFirstName?: string;
	contactLastName?: string;
	addressLine1?: string;
	addressLine2?: string;
	cityName?: string;
	jurisdictionId?: number;
	zipCode?: string;
	email?: string;
	phone?: string;
	siteNumberError?: string;
	emailError?: string;
	phoneError?: string;
	companyError?: string;
	contactFirstNameError?: string;
	contactLastNameError?: string;
	emailFormatError?: string;
}

const initialOverviewForm: OverviewForm = {
	siteNumber: '',
	company: '',
	contactFirstName: '',
	contactLastName: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	zipCode: '',
	email: '',
	phone: ''
};

export const CccSiteDetailsMainModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialOverviewForm);
	const [jurisdictionValues, setJurisdictionValues] = React.useState([] as DropDownOption[]);

	const setFormStateFromProps = (site: CccSite) => {
		let newState = {
			siteNumber: site.siteNumber || '',
			company: site.company || '',
			contactFirstName: site.contactFirstName || '',
			contactLastName: site.contactLastName || '',
			addressLine1: site.addressLine1 || '',
			addressLine2: site.addressLine2 || '',
			cityName: site.cityName || '',
			jurisdictionId: site.jurisdictionId,
			zipCode: site.zipCode || '',
			email: site.email || '',
			phone: site.phone || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		setFormStateFromProps(props.currentCccSite);
	}, [props.currentCccSite]);

	React.useEffect(() => {
		dispatch(loadJurisdiction());
	}, [dispatch]);

	let { jurisdictionList: jurisdictions } = useSelector((state: RootState) => state.jurisdictions);

	useEffect(() => {
		let jurisdictionOptions: DropDownOption[] = [];

		if (jurisdictions.length > 0) {
			jurisdictionOptions = jurisdictions.map((a: Jurisdiction) => {
				return { label: a.name!, value: a.jurisdictionId };
			});
			setJurisdictionValues(jurisdictionOptions);
		}
	}, [jurisdictions]);

	const saveDetails = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let siteToUpdate: CccSite = {
			siteNumber: formState.siteNumber,
			company: formState.company,
			contactFirstName: formState.contactFirstName,
			contactLastName: formState.contactLastName,
			addressLine1: formState.addressLine1,
			addressLine2: formState.addressLine2,
			cityName: formState.cityName,
			jurisdictionId: formState.jurisdictionId,
			zipCode: formState.zipCode,
			phone: formState.phone,
			email: formState.email
		};

		dispatch(
			siteSlice.patchOne(
				urlService.getCccSiteId(),
				siteToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccSite.site')
				),
				() => props.toggle()
			)
		);
	};

	const validateFields = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			'siteNumber',
			'siteNumberError',
			localizationService.getLocalizedString('cccSite.siteNumber')
		);
		validationService.validateFieldLength(
			newState,
			'phone',
			'phoneError',
			30,
			localizationService.getLocalizedString('screen.labels.contactPhone')
		);
		validationService.validateEmailFormatField(newState, 'email', 'emailFormatError');

		validationService.validateFieldLength(
			newState,
			'email',
			'emailError',
			200,
			localizationService.getLocalizedString('screen.labels.contactEmail')
		);
		validationService.validateFieldLength(
			newState,
			'contactFirstName',
			'contactFirstNameError',
			50,
			localizationService.getLocalizedString('screen.labels.contactFirstName')
		);
		validationService.validateFieldLength(
			newState,
			'contactLastName',
			'contactLastNameError',
			50,
			localizationService.getLocalizedString('screen.labels.contactLastName')
		);
		validationService.validateFieldLength(
			newState,
			'company',
			'companyError',
			70,
			localizationService.getLocalizedString('cccSite.company')
		);

		setFormState(newState);

		const isFormValid = !validationService.hasError(
			newState,
			'siteNumberError',
			'phoneError',
			'emailError',
			'contactFirstNameError',
			'contactLastNameError',
			'companyError',
			'emailFormatError'
		);
		return isFormValid;
	};
	const isFormValidateForSave = (): boolean => {
		const isFormValid = validateFields();
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const cancelSaveOverview = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('cccSite.editSite')}
				save={saveDetails}
				cancel={cancelSaveOverview}>
				<TextInput
					id="siteNumber"
					name="siteNumber"
					label={localizationService.getLocalizedString('cccSite.siteNumber')}
					value={formState.siteNumber}
					onChange={changeFormState}
					isRequired
					error={formState.siteNumberError}
				/>
				<TextInput
					id="company"
					name="company"
					label={localizationService.getLocalizedString('cccSite.company')}
					value={formState.company}
					onChange={changeFormState}
					error={formState.companyError}
				/>

				<TextInput
					id="addressLine1"
					name="addressLine1"
					label={localizationService.getLocalizedString('cccSite.addressLine1')}
					helpText={localizationService.getLocalizedString('screen.helpText.address1')}
					value={formState.addressLine1}
					onChange={changeFormState}
				/>
				<TextInput
					id="addressLine2"
					name="addressLine2"
					label={localizationService.getLocalizedString('cccSite.addressLine2')}
					helpText={localizationService.getLocalizedString('screen.helpText.address2')}
					value={formState.addressLine2}
					onChange={changeFormState}
				/>
				<div className="form-row">
					<TextInput
						id="cityName"
						name="cityName"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.cityName')}
						value={formState.cityName}
						onChange={changeFormState}
					/>
					<SingleSelectDropdown
						id="jurisdictionId"
						name="jurisdictionId"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.state')}
						value={_.toString(formState.jurisdictionId)}
						onChange={changeFormState}
						options={jurisdictionValues}
					/>
					<TextInput
						id="zipCode"
						name="zipCode"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('cccSite.zipCode')}
						value={formState.zipCode}
						onChange={changeFormState}
					/>
				</div>
				<div className="filter-group my-3 px-3 rounded-lg border border-secondary">
					<div className="filter-group-title mx-2">
						{localizationService.getLocalizedString('screen.labels.contactPerson')}
					</div>
					<div className="form-row">
						<TextInput
							id="contactFirstName"
							name="contactFirstName"
							className="form-group col-sm-6"
							label={localizationService.getLocalizedString('screen.labels.contactFirstName')}
							value={formState.contactFirstName}
							onChange={changeFormState}
							error={formState.contactFirstNameError}
						/>
						<TextInput
							id="contactLastName"
							name="contactLastName"
							className="form-group col-sm-6"
							label={localizationService.getLocalizedString('screen.labels.contactLastName')}
							value={formState.contactLastName}
							onChange={changeFormState}
							error={formState.contactLastNameError}
						/>
					</div>
					<TextInput
						id="phone"
						name="phone"
						label={localizationService.getLocalizedString('screen.labels.contactPhone')}
						value={formState.phone}
						onChange={changeFormState}
						error={formState.phoneError}
					/>
					<TextInput
						id="email"
						name="email"
						label={localizationService.getLocalizedString('screen.labels.contactEmail')}
						value={formState.email}
						onChange={changeFormState}
						error={formState.emailError || formState.emailFormatError}
					/>
				</div>
			</PopoverModal>
		</div>
	);
};
