import React from 'react';
import { InspectionEvent } from '@rcp/types';
import { DateUtilService, localizationService } from 'src/services';

export interface Props {
	inspection: InspectionEvent;
}

export const CurrentInspectionSummary: React.SFC<Props> = props => {
	let inspectionResult = props.inspection.inspectionResult;
	if (Object.keys(props.inspection).length !== 0 && !props.inspection.completeDate) {
		inspectionResult = localizationService.getLocalizedString('inspection.inProgress');
	}
	let edited = (props.inspection.completeDate && props.inspection.lastModificationDateTimeUtc &&
				!DateUtilService.isSame(props.inspection.completeDate, props.inspection.lastModificationDateTimeUtc, 'seconds'));
				
	return (
		<div className="form-row">
			<div className="form-group col-sm-6 col-lg-12">
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">{localizationService.getLocalizedString('inspection.due')}</div>
					<div className="summary-value"> {DateUtilService.toDisplayDate(props.inspection.dueDate)}</div>
				</div>
				{props.inspection.completeDate &&
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">{localizationService.getLocalizedString('inspection.completed')}</div>
					<div className="summary-value"> {DateUtilService.toDisplayDate(props.inspection.completeDate)}</div>
				</div>}
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">{localizationService.getLocalizedString('inspection.type')}</div>
					<div className="summary-value"> {props.inspection.inspectionType}</div>
				</div>
			</div>
			<div className="form-group col-sm-6 col-lg-12 mt-n3 mt-sm-0 mt-lg-n3">
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">
						{localizationService.getLocalizedString('inspection.inspectorName')}
					</div>
					<div className="summary-value"> {props.inspection.inspectorName}</div>
				</div>
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">{localizationService.getLocalizedString('inspection.result')}</div>
					<div className="summary-value">{inspectionResult}</div>
				</div>
			</div>
			<hr/> 
			{edited &&
			<div className="form-group col-sm-6 col-lg-12">
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">
						{localizationService.getLocalizedString('inspection.lastModifiedBy')}
					</div>
					<div className="summary-value">{props.inspection.lastModifierUserFullName}</div>
				</div>
				<div className="d-flex flex-row mt-2">
					<div className="summary-label">{localizationService.getLocalizedString('inspection.lastModifiedDate')}</div>
					<div className="summary-value">{DateUtilService.toDisplayDate(props.inspection.lastModificationDateTimeUtc)} {DateUtilService.toDisplayTime(props.inspection.lastModificationDateTimeUtc)}</div>
				</div>
			</div>
			}
		</div>
	);
};
