import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { InspectionsState, initialInspectionsState, IncidentEventsState, initialIncidentEventsState } from './facility';
import { AuthenticationState, initialAuthenticationState } from './authentication';
import { UserProfileState, initialUserProfileState } from './user';
import { FogFacilitiesState, initialFogFacilitiesState } from './facilities';
import {
	FogFacilityState,
	initialFogFacilityState,
	FacilityAttachmentsState,
	initialFacilityAttachmentsState
} from './facility';
import { FacilityEquipmentsState, initialFacilityEquipmentsState } from './equipment';
import { FacilityCommentsState, initialFacilityCommentsState } from './comment';
import { FacilityCallLogsState, initialFacilityCallLogsState } from './callLog';
import { FacilityContactListState, initialFacilityContactListState } from './facility';
import {
	DeviceAttachmentsState,
	ExtractorState,
	initialDeviceAttachmentsState,
	initialExtractorState
} from './extractor';
import { PumpOutEventsState, initialPumpOutEventsState } from './facility';
import { ViolationState, initialViolationState } from './violation';
import {
	AuthoritySettingLookupsState,
	initialAuthoritySettingLookupsState,
	ImportHistoryState,
	initialImportHistoryState
} from './authority-setting';
import {
	AuthorityTagsState,
	initialAuthorityTagsState,
	CustomFieldDefinitionsState,
	initialCustomFieldDefinitionsState,
	AuthorityEventTypesState,
	AuthorityWasteTypesState,
	InspectionMaintenanceResultsState,
	initialAuthorityEventTypesState,
	initialInspectionMaintenanceResultsState,
	initialInspectionResultsState,
	InspectionResultsState,
	initialAuthorityWasteTypesState,
	FeatureFlagSettingsState,
	initialFeatureSettingsState
} from './lookups';
import { AlertState, initialAlertState } from './alert';
import { JurisdictionListState, initialJurisdictionListState } from './jurisdiction';
import { FilterState, initialFilterState } from './filter';
import { FacilityEmailsState, initialFacilityEmailsState } from './facility/email';
import { LinkoRouteState, initialLinkoRouteState } from './linko-route';
import { PageLoadingState, initialPageLoadingState } from './page-loading';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { MaintenanceMessageState, initialMaintenanceMessageState } from './maintenance-message';
import { initialIndustriesState, IndustriesState } from './ipp/authority/industry';
import { initialUsersState } from './ipp/authority/authority-account/users';
import { UsersState as IppUsersState } from './ipp/authority/authority-account/users/users-state';
import { initialInviteUsersState, InviteUserState } from './ipp/authority/authority-account/pending-invitations';
import {
	IppAASAuthorityInformationState,
	initialIppAASAuthorityInformationState
} from './ipp/authority/authority-account/settings/authority-information';
import {
	IppAASAuthoritySettingsState,
	initialIppAASAuthoritySettingsState
} from './ipp/authority/authority-account/settings/authority-settings';
import {
	IppAASIndustrialPretreatmentSettingsState,
	initialIppAASIndustrialPretreatmentSettingsState
} from './ipp/authority/authority-account/settings/industrial-pretreatment-settings';
import {
	IppPendingRegistrationUserState,
	initialIppPendingRegistrationUserState
} from './ipp/authority/pending-registration-user';
import {
	initialReportPackageTemplateState,
	ReportPackageTemplateState
} from './ipp/authority/report-packages/templates/templates-state';
import {
	initialReportPackageParameterGroupState,
	IppReportPackageParameterGroupState
} from './ipp/authority/report-packages/parameter-groups';
import { initialPackageReviewState, PackageReviewState } from './ipp/authority/report-packages/reviews';
import { initialSampleState, SampleState } from './ipp/industry/samples';
import { initialRegisterState, RegisterState } from './account/register';

import { initialPackageState, PackageState } from './ipp/authority/report-packages/packages/packages-state';
import { RootState } from '.';
import { FacilityLinkState, initialFacilityLinksState } from './facility/facility-links';
import { ActionModalState, initialActionModalState } from './action-modals';
import { initialLanguagesState, LanguagesState } from './languages/languages';

export interface ApplicationState {
	userProfile: UserProfileState;
	fogFacilities: FogFacilitiesState; //grid
	fogFacility: FogFacilityState; //details
	authoritySettingLookups: AuthoritySettingLookupsState;
	authorityTags: AuthorityTagsState;
	facilityLinks: FacilityLinkState;
	authorityEventTypes: AuthorityEventTypesState;
	authorityWasteTypes: AuthorityWasteTypesState;
	inspectionMaintenanceResults: InspectionMaintenanceResultsState;
	inspectionResults: InspectionResultsState;
	customFieldDefinitions: CustomFieldDefinitionsState;
	facilityEquipments: FacilityEquipmentsState;
	facilityContacts: FacilityContactListState;
	facilityAttachments: FacilityAttachmentsState;
	deviceAttachments: DeviceAttachmentsState;
	alerts: AlertState;
	pageLoadingState: PageLoadingState;
	jurisdictions: JurisdictionListState;
	facilityCallLogs: FacilityCallLogsState;
	facilityComments: FacilityCommentsState;
	extractors: ExtractorState;
	filters: FilterState;
	facilityEmails: FacilityEmailsState;
	inspections: InspectionsState;
	pumpOutEvents: PumpOutEventsState;
	featureSettings: FeatureFlagSettingsState;
	linkoRouteDisplay: LinkoRouteState;
	violation: ViolationState;
	authorityImportHistory: ImportHistoryState;
	actionModals: ActionModalState;
	maintenanceMessage: MaintenanceMessageState;
	incidentEvents: IncidentEventsState;
	languages: LanguagesState;

	// IPP states
	ippUserDetails: IppUsersState;
	ippInviteUser: InviteUserState;
	ippAASAuthorityInformation: IppAASAuthorityInformationState;
	ippAASAuthoritySettings: IppAASAuthoritySettingsState;
	ippAASIndustrialPretreatmentSettings: IppAASIndustrialPretreatmentSettingsState;
	ippReportPackageTemplate: ReportPackageTemplateState;
	ippIndustryDetails: IndustriesState;
	ippPendingRegistrationUser: IppPendingRegistrationUserState;
	ippReportPackageParameterGroupDetails: IppReportPackageParameterGroupState;
	ippReportPackageReview: PackageReviewState;
	ippIndustrySample: SampleState;
	register: RegisterState;
	ippReportPackage: PackageState;

	authenticationResult: AuthenticationState;
}

export const initialApplicationState: ApplicationState = {
	userProfile: initialUserProfileState,
	fogFacilities: initialFogFacilitiesState,
	fogFacility: initialFogFacilityState,
	authoritySettingLookups: initialAuthoritySettingLookupsState,
	authorityTags: initialAuthorityTagsState,
	facilityLinks: initialFacilityLinksState,
	authorityEventTypes: initialAuthorityEventTypesState,
	authorityWasteTypes: initialAuthorityWasteTypesState,
	inspectionMaintenanceResults: initialInspectionMaintenanceResultsState,
	inspectionResults: initialInspectionResultsState,
	customFieldDefinitions: initialCustomFieldDefinitionsState,
	facilityEquipments: initialFacilityEquipmentsState,
	facilityContacts: initialFacilityContactListState,
	facilityAttachments: initialFacilityAttachmentsState,
	deviceAttachments: initialDeviceAttachmentsState,
	alerts: initialAlertState,
	jurisdictions: initialJurisdictionListState,
	extractors: initialExtractorState,
	facilityCallLogs: initialFacilityCallLogsState,
	facilityComments: initialFacilityCommentsState,
	filters: initialFilterState,
	facilityEmails: initialFacilityEmailsState,
	inspections: initialInspectionsState,
	pumpOutEvents: initialPumpOutEventsState,
	featureSettings: initialFeatureSettingsState,
	linkoRouteDisplay: initialLinkoRouteState,
	pageLoadingState: initialPageLoadingState,
	violation: initialViolationState,
	authorityImportHistory: initialImportHistoryState,
	maintenanceMessage: initialMaintenanceMessageState,
	incidentEvents: initialIncidentEventsState,
	languages: initialLanguagesState,

	// IPP States for reducers
	ippUserDetails: initialUsersState,
	ippInviteUser: initialInviteUsersState,
	ippAASAuthorityInformation: initialIppAASAuthorityInformationState,
	ippAASAuthoritySettings: initialIppAASAuthoritySettingsState,
	ippAASIndustrialPretreatmentSettings: initialIppAASIndustrialPretreatmentSettingsState,
	ippReportPackageTemplate: initialReportPackageTemplateState,
	ippIndustryDetails: initialIndustriesState,
	ippPendingRegistrationUser: initialIppPendingRegistrationUserState,
	ippReportPackageParameterGroupDetails: initialReportPackageParameterGroupState,
	ippReportPackageReview: initialPackageReviewState,
	ippIndustrySample: initialSampleState,
	register: initialRegisterState,
	ippReportPackage: initialPackageState,

	actionModals: initialActionModalState,
	authenticationResult: initialAuthenticationState //keep this line at the end
};

export const useReduxSelector: TypedUseSelectorHook<ApplicationState> = useSelector;
export const useRootStateSelector: TypedUseSelectorHook<RootState> = useSelector;
export type ReduxDispatch = ThunkDispatch<any, any, Action>;
// Typescript Wrapper over react-redux useDispatch Hook
export const useReduxDispatch = (): ReduxDispatch => {
	return useDispatch<ReduxDispatch>();
};
