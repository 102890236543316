import React from 'react';

import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import {
	LookupType,
	SettingType,
	SubLookupType,
	CustomFieldApplyToTable,
	ImportEntity,
	AutoNumberDomainType,
	NoticeTemplateType,
	MergeFieldDto,
	FeatureNames
} from '@rcp/types';
import {
	AuthoritySettingUrlResolver,
	ListSetting,
	LookupSetting,
	LookupsWidget,
	TagSetting,
	TagSettingUrlResolver,
	TagsSetting,
	TextInput
} from 'src/components/widgets';

import 'src/components/authority/shared/settings/authority-settings.scss';
import { SettingAction } from '../../../../widgets/lookup/setting-action-hooks';
import {
	EquipmentTypeLookRowDataConverter,
	LookupRowTagValueConverter,
	SettingRowValueConverter
} from '../../../../widgets/lookup/data-converters';
import CustomFieldSettingComponent from '../../../shared/settings/custom-field/custom-field-setting';
import { AutoNumberSetting } from '../../../shared/settings/auto-number';
import ImportUniqueKeySettingComponent from 'src/components/authority/shared/settings/import-unique-key/import-unique-key-setting';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import './facility.scss';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { Utils } from 'src/services/utils';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { useSelector } from 'react-redux';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import _ from 'lodash';

interface MessageTemplate {
	templateHeader?: string;
	templateBody?: string;
	templateFooter?: string;
}

interface EmailTemplate {
	templateContent?: string;
	templateSubject?: string;
}

const SendEmailTemplate: React.FC = () => {
	const [messageEmailTemplate, setMessageEmailTemplate] = React.useState<EmailTemplate>({});
	const [subjectCursorPosition, setSubjectCursorPosition] = React.useState(0);
	const [mergeFields, setMergeFields] = React.useState<MergeFieldDto[]>([]);

	React.useEffect(() => {
		getFacilityEmailTemplate();
		getMergeFieldsForFacilityEmail();
	}, []);

	const getFacilityEmailTemplate = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.FacilityEmail}`
		);
		await apiService
			.getResource<EmailTemplate>(url)
			.then(data => setMessageEmailTemplate(data))
			.catch(err => alertService.addError(err.message));
	};

	const getMergeFieldsForFacilityEmail = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.SettingNoticeTemplatesMergeFields}/${NoticeTemplateType.FacilityEmail}`
		);
		await apiService
			.getResource<MergeFieldDto[]>(url)
			.then(data => {
				setMergeFields(data);
			})
			.catch(err => alertService.addError(err.message));
	};

	const updateTemplate = async (updatedMessageTemplate: EmailTemplate) => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.FacilityEmail}`
		);
		await apiService
			.patchResource<EmailTemplate>(url, { ...updatedMessageTemplate })
			.then(data => {
				setMessageEmailTemplate(data);
			})
			.catch(err => alertService.addError(err.message));
	};

	const isFormValid = (updatedMessageTemplate: EmailTemplate) => {
		return validationService.validateMergeFields(
			(updatedMessageTemplate.templateSubject || '') + (updatedMessageTemplate.templateContent || ''),
			mergeFields
		);
	};

	async function updateTemplateContent(editorContent: string, type: string) {
		const updatedMessageTemplate: EmailTemplate = {
			...messageEmailTemplate,
			[type]: editorContent
		};
		isFormValid(updatedMessageTemplate) && updateTemplate(updatedMessageTemplate);
	}

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		const contentToInsert = '{{' + e.target.value + '}}';
		let templateSubject: string = messageEmailTemplate.templateSubject || '';
		templateSubject = [
			templateSubject.slice(0, subjectCursorPosition),
			contentToInsert,
			templateSubject.slice(subjectCursorPosition)
		].join('');
		setMessageEmailTemplate({
			...messageEmailTemplate,
			templateSubject
		});
		updateTemplateContent(templateSubject, 'templateSubject');
		setSubjectCursorPosition(subjectCursorPosition + contentToInsert.length);
	};

	const changeEmailTemplateContent = (e: any) => {
		let newState = { ...messageEmailTemplate };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setMessageEmailTemplate(newState);
	};

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{localizationService.getLocalizedString('authoritySetting.sendEmailTemplate')}</strong>
				<p>{localizationService.getLocalizedString('authoritySetting.sendEmailTemplateDescription')}</p>
			</div>
			<div className="col-lg-8 facility-portal-invitation">
				<section>
					<div className="form-row">
						<TextInput
							id="subject"
							name="templateSubject"
							className="col-sm-7 form-group"
							onChange={changeEmailTemplateContent}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')}
							value={messageEmailTemplate.templateSubject}
							onBlur={(e: any) => {
								setSubjectCursorPosition(e.target.selectionStart);
								updateTemplateContent(e.target.value, 'templateSubject');
							}}
						/>
						<div className="col-sm-5 d-inline-block">
							<MergeFieldDropDown
								id="mergeTags"
								name="mergeTags"
								label={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.mergeTags'
								)}
								hintText={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.mergeTagsHint'
								)}
								onChange={onChangeMergeField}
								customMergeFields={mergeFields}
							/>
						</div>
					</div>
					<div id="facilityInvitationBody" className="mt-2">
						<label>{localizationService.getLocalizedString('authoritySetting.body')}</label>
						<RichTextEditor
							height="500px"
							customMergeFields={mergeFields}
							editorContent={messageEmailTemplate.templateContent}
							useOnlyMergeFieldTool
							saveTemplateData={(editorContent: any) =>
								updateTemplateContent(editorContent, 'templateContent')
							}
							hidePageNumber
						/>
					</div>
				</section>
			</div>
		</div>
	);
};

const GetFacilityPortalInvitationSection: React.FC = () => {
	const [messageTemplate, setMessageTemplate] = React.useState<MessageTemplate>({});
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);
	React.useEffect(() => {
		getTemplate();
	}, []);

	const getTemplate = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.FacilityPortalInvitationLetter}`
		);
		await apiService
			.getResource<MessageTemplate>(url)
			.then(data => setMessageTemplate(data))
			.catch(err => alertService.addError(err.message));
	};

	const updateTemplate = async (updatedMessageTemplate: MessageTemplate) => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.FacilityPortalInvitationLetter}`
		);
		await apiService
			.patchResource<MessageTemplate>(url, { ...updatedMessageTemplate })
			.then(data => {
				setMessageTemplate(data);
			})
			.catch(err => alertService.addError(err.message));
	};

	const isFormValid = (updatedMessageTemplate: MessageTemplate) => {
		return validationService.validateMergeFields(
			(updatedMessageTemplate.templateBody || '') +
				(updatedMessageTemplate.templateHeader || '') +
				(updatedMessageTemplate.templateFooter || ''),
			mergeFields
		);
	};

	async function updateTemplateContent(editorContent: string, type: string) {
		const updatedMessageTemplate: MessageTemplate = {
			...messageTemplate,
			[type]: editorContent
		};
		isFormValid(updatedMessageTemplate) && updateTemplate(updatedMessageTemplate);
	}

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{localizationService.getLocalizedString('authoritySetting.facilityPortalInvitation')}</strong>
				<p>{localizationService.getLocalizedString('authoritySetting.facilityPortalInvitationDesc')}</p>
			</div>
			<div className="col-lg-8 facility-portal-invitation">
				<section>
					<div id="facilityInvitationHeader">
						<label>{localizationService.getLocalizedString('authoritySetting.header')}</label>
						<RichTextEditor
							height="80px"
							noticeTemplateType={NoticeTemplateType.FacilityPortalInvitationLetter}
							saveTemplateData={(editorContent: any) =>
								updateTemplateContent(editorContent, 'templateHeader')
							}
							editorContent={messageTemplate.templateHeader}
							editorIndex={0}
							isFixedHeight
							showTableTools
						/>
					</div>
					<div id="facilityInvitationBody" className="mt-2">
						<label>{localizationService.getLocalizedString('authoritySetting.body')}</label>
						<RichTextEditor
							height="500px"
							noticeTemplateType={NoticeTemplateType.FacilityPortalInvitationLetter}
							editorContent={messageTemplate.templateBody}
							saveTemplateData={(editorContent: any) =>
								updateTemplateContent(editorContent, 'templateBody')
							}
							showTableTools
							hidePageNumber
						/>
					</div>
					<div id="facilityInvitationFooter" className="mt-2">
						<label>{localizationService.getLocalizedString('authoritySetting.footer')}</label>
						<RichTextEditor
							height="60px"
							noticeTemplateType={NoticeTemplateType.FacilityPortalInvitationLetter}
							editorContent={messageTemplate.templateFooter}
							saveTemplateData={(editorContent: any) =>
								updateTemplateContent(editorContent, 'templateFooter')
							}
							isFixedHeight
							editorIndex={2}
							showTableTools
						/>
					</div>
				</section>
			</div>
		</div>
	);
};

const FacilitySettingsComponent: React.FunctionComponent = () => {
	const [isFacilityPortalEnabled, setIsFacilityPortalEnabled] = React.useState<boolean>();

	const featureFlags = useReduxSelector(state => state.featureSettings.featureFlagSettings);

	const setFacilityPortalStatus = async () => {
		setIsFacilityPortalEnabled(await Utils.getFacilityPortalStatusForAuthority());
	};

	React.useEffect(() => {
		setFacilityPortalStatus();
	}, []);

	const getFacilityImportUniqueKeysSection = () => {
		const fieldsPath = `Settings/AuthoritySettings/ImportEntityFields/${ImportEntity.FogFacility}`;

		return (
			<>
				<hr />
				<ImportUniqueKeySettingComponent
					importEntity={ImportEntity.FogFacility}
					fieldsPath={fieldsPath}
					params={''}
					description={localizationService.getLocalizedString(
						'authoritySetting.importUniqueKey.settingDescription',
						'screen.labels.facility'
					)}
				/>
			</>
		);
	};

	return (
		<div className="page">
			<>
				<div className="page-header">
					<BackArrow />
					<h1>{localizationService.getLocalizedString('authoritySetting.facilities')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main settings">
						<TagSetting
							title={localizationService.getLocalizedString('authoritySetting.classification1Section')}
							description={localizationService.getLocalizedString(
								'authoritySetting.classification1SectionDescription'
							)}
							settingId="classification1"
							settingLabel={localizationService.getLocalizedString('authoritySetting.classification1')}
							tagType={SubLookupType.Classification1}
						/>
						<hr />
						<TagSetting
							title={localizationService.getLocalizedString('authoritySetting.classification2Section')}
							description={localizationService.getLocalizedString(
								'authoritySetting.classification2SectionDescription'
							)}
							settingId="classification2"
							settingLabel={localizationService.getLocalizedString('authoritySetting.classification2')}
							tagType={SubLookupType.Classification2}
						/>
						<hr />
						{urlService.isAdministrator() && (
							<>
								<ListSetting
									title={localizationService.getLocalizedString(
										'authoritySetting.complianceStatusSection'
									)}
									description={localizationService.getLocalizedString(
										'authoritySetting.complianceStatusSectionDescription'
									)}
									settingId="complianceStatus"
									settingLabel={localizationService.getLocalizedString(
										'authoritySetting.complianceStatus'
									)}
									hideActiveStatus={true}
									settingAction={
										new SettingAction(
											LookupType.ComplianceStatus,
											new AuthoritySettingUrlResolver(LookupType.ComplianceStatus),
											new SettingRowValueConverter(SettingType.ComplianceStatusValues),
											true
										)
									}
								/>
								<hr />
								<CustomFieldSettingComponent
									appliedToTable={CustomFieldApplyToTable.ApplyToFogFacility}
									description={localizationService.getLocalizedString(
										'authoritySetting.customFieldsSectionFacility'
									)}
								/>
								<hr />
								<ListSetting
									title={localizationService.getLocalizedString(
										'authoritySetting.equipmentTypeSection'
									)}
									description={localizationService.getLocalizedString(
										'authoritySetting.equipmentTypeSectionDescription'
									)}
									settingId="equipmentTypes"
									settingLabel={localizationService.getLocalizedString(
										'authoritySetting.equipmentTypeLabel'
									)}
									settingAction={
										new SettingAction(
											LookupType.EquipmentType,
											undefined,
											new EquipmentTypeLookRowDataConverter(LookupType.EquipmentType)
										)
									}
								/>
								<hr />
								<AutoNumberSetting
									title={localizationService.getLocalizedString(
										'authoritySetting.autoNumberForFacilitySection'
									)}
									autoNumbersLabel={localizationService.getLocalizedString(
										'authoritySetting.facilityNumbers'
									)}
									autoNumberType={AutoNumberDomainType.Facility}
								/>
								<hr />
							</>
						)}
						{isFacilityPortalEnabled && urlService.isAdministrator() && (
							<>
								<GetFacilityPortalInvitationSection />
								<hr />
							</>
						)}
						<LookupSetting
							title={localizationService.getLocalizedString('authoritySetting.mapCategory.sectionText')}
							description={localizationService.getLocalizedString(
								'authoritySetting.mapCategory.sectionDescription'
							)}
							settingId="mapCategory"
							settingLabel={localizationService.getLocalizedString(
								'authoritySetting.mapCategory.editorLabel'
							)}
							lookupType={LookupType.MapCategory}
						/>
						<hr />
						<LookupSetting
							title={localizationService.getLocalizedString(
								'authoritySetting.receivingPlant.sectionText'
							)}
							description={localizationService.getLocalizedString(
								'authoritySetting.receivingPlant.sectionDescription'
							)}
							settingId="receivingPlant"
							settingLabel={localizationService.getLocalizedString(
								'authoritySetting.receivingPlant.editorLabel'
							)}
							lookupType={LookupType.ReceivingPlant}
						/>
						<hr />
						{urlService.isAdministrator() && <SendEmailTemplate />}
						<TagsSetting />
						{urlService.isAdministrator() && getFacilityImportUniqueKeysSection()}
					</div>
				</div>
			</>
		</div>
	);
};

export const AuthoritySettingFacility = FacilitySettingsComponent;
