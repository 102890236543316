import { DropDownOption, EventType, Frequency } from '@rcp/types';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { ISettingUrlResolver, PopoverModal, SingleCheckbox, TextInput } from '../../../..';
import { alertService, useReduxSelector } from '../../../../../redux';
import { apiService, localizationService, validationService } from '../../../../../services';
import { SingleSelectDropdown } from '../../../../widgets';

interface Props {
	isToggle?: boolean;
	isReadOnly?: boolean;
	toggleModal: () => void;
	isCreate: boolean;
	urlResolver: ISettingUrlResolver;
	eventType: EventType;
}

interface EventTypeEditorState extends EventType {
	nameFormatError?: string;
	scheduleFrequencyError?: string;
	nextScheduleDateError?: string;
	scheduleNextEvent?: boolean;
}

export const AddEventModal = (props: Props) => {
	const [formState, setFormState] = useState({} as EventTypeEditorState);
	const [allEventTypes, setAllEventTypes] = useState([] as EventType[]);

	const frequencyList = _.orderBy(
		useReduxSelector(state => state.extractors.frequencyList).filter(x => x.isActive),
		['frequencyCode'],
		['asc']
	);

	useEffect(() => {
		if (props.eventType) {
			setFormState({
				...props.eventType
			});
		}
	}, [props.eventType]);

	useEffect(() => {
		getAllEventTypes();
	}, []);

	const modalFooterDiv = () => {
		return (
			<>
				<div className="ml-auto">
					<button className="btn ai-save" onClick={() => saveButtonClicked()}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>

					<button className="btn ai-white ml-2" onClick={() => props.toggleModal()}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	const changeFormState = (event: any) => {
		let newState = { ...formState };
		if (event.target.type === 'radio' || event.target.type === 'checkbox') {
			String.equalCaseInsensitive(event.target.name, 'shouldScheduleNextEventAutomatically')
				? (newState = {
						...formState,
						shouldScheduleNextEventAutomatically: event.target.checked,
						scheduleFrequencyError: event.target.checked ? formState.scheduleFrequencyError : '',
						nextEventStartingWith: 'dueDate'
				  })
				: (newState = {
						...formState,
						nextEventStartingWith: event.target.name
				  });
		} else {
			newState = {
				...formState,
				[event.target.name]: event.target.value
			};
		}
		if (!newState.shouldScheduleNextEventAutomatically) {
			const { schedulingFrequencyId, nextEventStartingWith, ...restState } = newState;
			setFormState({
				...restState
			});
		} else {
			setFormState({
				...newState
			});
		}
	};

	const getFrequency = () => {
		let frequencyOptionValues: DropDownOption[] = [];
		if (frequencyList.length > 0) {
			frequencyOptionValues = frequencyList.map((frequency: Frequency) => {
				return { label: frequency.frequencyCode, value: frequency.frequencyId };
			});
		}

		return frequencyOptionValues;
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		if (formState.shouldScheduleNextEventAutomatically) {
			validationService.validateRequiredField(
				newState,
				'nextEventStartingWith',
				'nextScheduleDateError',
				localizationService.getLocalizedString('authoritySetting.dateRequired')
			);
			validationService.validateRequiredField(
				newState,
				'schedulingFrequencyId',
				'scheduleFrequencyError',
				localizationService.getLocalizedString('authoritySetting.scheduleFrequency')
			);
		}

		let isDuplicate: boolean;
		const events = [...allEventTypes];
		let editEvents: EventType[] = [];
		if (!props.isCreate && props.eventType) {
			editEvents = events.filter(event => event.eventTypeAbbreviation !== props.eventType.eventTypeAbbreviation);
			isDuplicate = editEvents.some((eventType: EventType) => {
				return String.equalCaseInsensitive(formState.eventTypeAbbreviation, eventType.eventTypeAbbreviation);
			});
		} else {
			isDuplicate = events.some((eventType: EventType) => {
				return String.equalCaseInsensitive(formState.eventTypeAbbreviation, eventType.eventTypeAbbreviation);
			});
		}

		if (isDuplicate) {
			newState.nameFormatError = localizationService.getLocalizedString(
				'authoritySetting.lookupValueError',
				localizationService.getLocalizedString('authoritySetting.eventType')
			);
		} else {
			validationService.validateRequiredField(
				newState,
				'eventTypeAbbreviation',
				'nameFormatError',
				localizationService.getLocalizedString('authoritySetting.name')
			);
		}
		let isFormValid = !validationService.hasError(
			newState,
			'nameFormatError',
			'scheduleFrequencyError',
			'nextScheduleDateError'
		);

		if (isDuplicate) isFormValid = false;
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const getAllEventTypes = async () => {
		let url = props.urlResolver.getSettingUrls().addUrl;
		const responseData = await apiService.getResource<EventType[]>(url);
		setAllEventTypes(responseData);
	};

	const saveButtonClicked = async () => {
		if (!validateBeforeForSave()) {
			return;
		}
		let newState = { ...formState, eventCategoryId: props.eventType.eventCategoryId };
		let EventTypeToSave = _.pick(newState, [
			'eventCategoryId',
			'eventTypeAbbreviation',
			'nextEventStartingWith',
			'schedulingFrequencyId',
			'shouldScheduleNextEventAutomatically',
			'useFrequencyAssignedToFacility'
		]);
		let response: any = false;

		try {
			if (props.isCreate) {
				let url = props.urlResolver.getSettingUrls().addUrl;
				const responseData = await apiService.postResource<EventType>(url, EventTypeToSave);
				const message = localizationService.getLocalizedString(
					'authoritySetting.addSucceedMessage',
					localizationService.getLocalizedString('authoritySetting.eventType')
				);
				alertService.addSuccess(message);
				response = true;
			} else {
				let url = props.urlResolver.getSettingUrls().updateUrl(formState.eventTypeId);
				await apiService.patchResource<EventType>(`${url}`, EventTypeToSave);
				let message = localizationService.getLocalizedString(
					'authoritySetting.updateSucceedMessage',
					localizationService.getLocalizedString('authoritySetting.eventType'),
					_.toString(formState.eventTypeAbbreviation)
				);
				alertService.addSuccess(message);
				response = true;
			}

			props.toggleModal();
		} catch (ex) {
			alertService.addError(ex);
		}
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={
					props.isCreate
						? localizationService.getLocalizedString('eventType.addEventType')
						: localizationService.getLocalizedString('eventType.editEventType')
				}
				footer={modalFooterDiv()}
				cancel={() => props.toggleModal()}>
				<TextInput
					id="eventTypeAbbreviation"
					name="eventTypeAbbreviation"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('eventType.name')}
					isRequired={true}
					value={formState.eventTypeAbbreviation}
					isDisabled={props.isReadOnly}
					error={formState.nameFormatError}
					onChange={changeFormState}
				/>
				<div className="form-row">
					<SingleCheckbox
						id="shouldScheduleNextEventAutomatically"
						name="shouldScheduleNextEventAutomatically"
						label={localizationService.getLocalizedString('eventType.scheduleNextEvent')}
						checked={formState.shouldScheduleNextEventAutomatically}
						onChange={changeFormState}
						className="div-checkbox ml-1"
					/>
				</div>
				{formState.shouldScheduleNextEventAutomatically && (
					<>
						<SingleSelectDropdown
							id="schedulingFrequencyId"
							name="schedulingFrequencyId"
							className="form-group input-single-line"
							label={localizationService.getLocalizedString('eventType.scheduleFrequency')}
							value={formState.schedulingFrequencyId as number}
							isDisabled={props.isReadOnly}
							options={getFrequency()}
							isRequired={formState.shouldScheduleNextEventAutomatically}
							error={formState.scheduleFrequencyError}
							onChange={changeFormState}
						/>
						<div className="form-group required">
							<label>{localizationService.getLocalizedString('eventType.basedOn')}</label>
							{formState.nextScheduleDateError && (
								<div className="invalid-feedback mb-1">{formState.nextScheduleDateError}</div>
							)}
						</div>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="dueDate"
								checked={
									(formState.nextEventStartingWith &&
										formState.nextEventStartingWith.toLocaleLowerCase() ==
											'dueDate'.toLocaleLowerCase()) ||
									false
								}
								id="dueDate"
								onChange={changeFormState}
							/>
							<label className="custom-control-label" htmlFor="dueDate">
								<span>{localizationService.getLocalizedString('eventType.dueDate')}</span>
							</label>
						</div>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="completeDate"
								checked={
									(formState.nextEventStartingWith &&
										formState.nextEventStartingWith.toLocaleLowerCase() ==
											'completeDate'.toLocaleLowerCase()) ||
									false
								}
								id="completeDate"
								onChange={changeFormState}
							/>
							<label className="custom-control-label" htmlFor="completeDate">
								<span>{localizationService.getLocalizedString('eventType.completeDate')}</span>
							</label>
						</div>
					</>
				)}
			</PopoverModal>
		</div>
	);
};
