import { apiService, urlService, Resource, tokenService } from '.';
import { AutoNumberDomainType } from '@rcp/types';
import { alertService } from 'src/redux/alert/alert-service';

export class AuthoritySettingService {
	getNextAutoNumber = async (autoNumberDomainType: AutoNumberDomainType): Promise<string> => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`Settings/${Resource.AuthoritySettings}/autoNumber/${autoNumberDomainType}`
		);
		const result = await apiService.getResource(url);
		return result as Promise<string>;
	};

	getAuthorityComplianceStatus = async (): Promise<string[]> => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`Settings/${Resource.AuthoritySettings}/complianceStatusValues`
		);
		const result = await apiService.getResource<string[]>(url);
		return result;
	};

	getAuthorityComplianceStatusInUse = async (): Promise<string[]> => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`Settings/${Resource.AuthoritySettings}/complianceStatusValuesInUse`
		);
		const result = await apiService.getResource<string[]>(url);
		return result;
	};

	isLanguageSelectorAvailable = async (): Promise<boolean> => {
		if (
			urlService.isFacilityPortal() &&
			!urlService.isTermsAndConditionsPage() &&
			!urlService.isPrivacyPolicyPage()
		) {
			let url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogLanguageTranslationStatus}`;
			try {
				return await apiService.httpGet(url);
			} catch (ex) {
				return false;
			}
		} else if (urlService.isAccountPortal() && urlService.isAccountPortalPageRelevantForFacilityPortalUsers()) {
			return true;
		} else if (urlService.isPublicEmailViewScreen()) {
			return true;
		}
		return false;
	};

	getAuthorityLanguageSupportStatus = (
		setHasLanguageSupport: React.Dispatch<React.SetStateAction<boolean>>,
		showErrorMessage?: boolean
	) => {
		let url = `${urlService.getAuthorityOrpDetailApiUrl()}/${
			tokenService.getTokenOrDefault().portalOrganizationId
		}/${Resource.FogLanguageTranslationStatus}`;
		try {
			apiService.httpGet(url).then(status => {
				setHasLanguageSupport(status);
			});
		} catch (ex) {
			showErrorMessage && alertService.addError(ex.message);
		}
	};
}

export const authoritySettingService = new AuthoritySettingService();
