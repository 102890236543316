import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IppIndustrySample, IppPackageElement, IppSampleResult } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './review-summary.scss';
import { localizationService, urlService } from 'src/services';
import IppPackageItemProgress from './progress';
import _ from 'lodash';
import { SampleUtils } from './sample-utils';
import { SampleCompliance } from 'src/constants';

interface Props {
	className?: string;
	attachmentTypes?: IppPackageElement[];
	certificationTypes?: IppPackageElement[];
	samplesAndResultsTypes?: IppPackageElement[];
	showSampleSummary?: boolean;
}

const IppPackageReviewSummary: FC<Props> = props => {
	const [reportElements, setReportElements] = useState([] as IppPackageElement[]);
	const [progressData, setProgressData] = useState({ present: 0, required: 0 });
	const [sampleProgressData, setSampleProgressData] = useState({ present: 0, required: 0 });
	const [sampleTypes, setSampleTypes] = useState([] as IppIndustrySample[]);

	useEffect(() => {
		let attachmentTypes: IppPackageElement[] = _.cloneDeep(props.attachmentTypes || []);
		let certificationTypes = _.cloneDeep(props.certificationTypes || []);
		let sampleTypes = _.cloneDeep(props.samplesAndResultsTypes || []);
		if (attachmentTypes.length) {
			for (let i in attachmentTypes) {
				if (urlService.isIppIndustryPortal()) {
					attachmentTypes[i].ippFileStores = (attachmentTypes[i].ippFileStores || []).filter(
						file => file.isAssociatedWithReportPackage
					);
				}
				attachmentTypes[i].isIncluded = (attachmentTypes[i].ippFileStores || []).length ? true : false;
			}
		}
		if (sampleTypes.length) {
			if (urlService.isIppIndustryPortal()) {
				sampleTypes[0].ippSamples = (sampleTypes[0].ippSamples || []).filter(
					file => file.isAssociatedWithReportPackage
				);
			}
			sampleTypes[0].isIncluded = (sampleTypes[0].ippSamples || []).length ? true : false;
		}
		if (sampleTypes[0] && sampleTypes[0].ippSamples) setSampleTypes(sampleTypes[0].ippSamples);
		setReportElements([...sampleTypes, ...attachmentTypes, ...certificationTypes]);
	}, [props]);

	useEffect(() => {
		let present = reportElements.filter(item => item.isIncluded && item.isRequired);
		let required = reportElements.filter(item => item.isRequired);
		setProgressData({ present: present.length || 0, required: required.length || 0 });
	}, [reportElements]);

	useEffect(() => {
		let ippSamples = _.cloneDeep(sampleTypes);
		let required: number = 0;
		let present: number = 0;
		for (let i in ippSamples) {
			let massLoadingRequiredCount: number = 0;
			let massLoadingPresentCount: number = 0;
			massLoadingRequiredCount = ippSamples[i].ippSampleResults!.filter(result => result.massLoadingValue).length;
			required += (ippSamples[i].ippSampleResults || []).length + massLoadingRequiredCount;
			massLoadingPresentCount += (ippSamples[i].ippSampleResults || []).filter((result: IppSampleResult) => {
				let massLoadingCompliance = SampleUtils.displayMassLoadingCompliance(result);
				return (
					massLoadingCompliance.type !== SampleCompliance.Bad &&
					result.massLoadingValue !== null &&
					result.massLoadingValue !== undefined
				);
			}).length;
			present +=
				(ippSamples[i].ippSampleResults || []).filter((result: IppSampleResult) => {
					let resultCompliance = SampleUtils.displayConcentrationCompliance(result);
					return resultCompliance.type !== SampleCompliance.Bad;
				}).length + massLoadingPresentCount;
		}
		setSampleProgressData({ present: present || 0, required: required || 0 });
	}, [sampleTypes]);

	return (
		<>
			<CollapsibleCard
				accordionType="report-package-review-summary"
				accordionHeading={localizationService.getLocalizedString(
					`ipp.reportPackage.package.reviewSummary.mainTitle`
				)}
				className={props.className ? props.className : ''}>
				<CollapsibleCard
					cardClass="remove-shadow"
					accordionType="report-package-review-summary-elements"
					accordionHeaderElements={
						<IppPackageItemProgress
							present={progressData.present}
							required={progressData.required}
							className="mr-3"
						/>
					}
					accordionHeading={localizationService.getLocalizedString(
						'ipp.reportPackage.package.reviewSummary.elementTitle'
					)}
					className={props.className ? props.className : ''}>
					<table className="table table-bordered table-condensed">
						<thead>
							<tr>
								<th className="align-center">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.sno'
									)}
								</th>
								<th className="align-center">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.reportElement'
									)}
								</th>
								<th className="align-center">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.required'
									)}
								</th>
								<th className="align-center">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.included'
									)}
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{reportElements &&
								reportElements.map((element, index) => {
									return (
										<tr key={index}>
											<td className="align-center">{index + 1}</td>
											<td className="align-center">{element.reportElementTypeName}</td>
											<td className="align-center">
												{element.isRequired
													? localizationService.getLocalizedString(
															'ipp.reportPackage.package.reviewSummary.yes'
													  )
													: localizationService.getLocalizedString(
															'ipp.reportPackage.package.reviewSummary.no'
													  )}
											</td>
											<td className="align-center">
												{element.isIncluded
													? localizationService.getLocalizedString(
															'ipp.reportPackage.package.reviewSummary.yes'
													  )
													: localizationService.getLocalizedString(
															'ipp.reportPackage.package.reviewSummary.no'
													  )}
											</td>
											<td className="align-center">
												<div className="d-flex justify-content-center">
													{element.isRequired && element.isIncluded && (
														<FontAwesomeIcon
															className="green-icon font-awesome-icon"
															icon={faCheckCircle}
														/>
													)}
													{element.isRequired && !element.isIncluded && (
														<FontAwesomeIcon
															className="red-icon font-awesome-icon"
															icon={faTimesCircle}
														/>
													)}
												</div>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</CollapsibleCard>
				{props.showSampleSummary && (
					<section id="report-package-review-samples">
						<div className="d-flex justify-content-space-between">
							<div className="sample-summary-details">
								<h3>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.sampleTitle'
									)}
								</h3>
								<p>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.reviewSummary.sampleNote'
									)}
								</p>
							</div>
							<div className="ml-auto">
								<IppPackageItemProgress {...sampleProgressData} isSample={true} className="mr-3" />
							</div>
						</div>
					</section>
				)}
			</CollapsibleCard>
		</>
	);
};

export default IppPackageReviewSummary;
