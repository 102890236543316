import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { CccImportSteps, CccImportSummary } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import _ from 'lodash';
import './step3.scss';
import { ImportResultLinks } from '../../import-result-links';
import { AlertMessageType } from 'src/redux';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

interface Props {
	vertical?: boolean;
}

const Step3: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const step2Data: CccImportSummary = stepperContext.getData(CccImportSteps.STEP2);
	const bigImportBar = 100;
	const importHistoryBaseUrl = urlService.getSettingPath() + '/import/history';

	React.useEffect(() => {
		stepperContext.resolve('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickStartAnother = (event: React.FormEvent) => {
		let step1State = { ...stepperContext.getStep(CccImportSteps.STEP1), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP1, step1State);
		let step2State = { ...stepperContext.getStep(CccImportSteps.STEP2), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP2, step2State);
		let step3State = { ...stepperContext.getStep(CccImportSteps.STEP3), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP3, step3State);

		stepperContext.goAt(CccImportSteps.STEP1);
	};

	const inlineErrorAlertContent = () => {
		return (
			<>
				{localizationService.getLocalizedString(
					'cccImportFile.step3ErrorMessagePart1',
					_.toString(step2Data.errorRowNumber),
					step2Data.errorRowNumber > 1 ? 's' : ''
				)}
				&nbsp;
				<a href={step2Data.errorFileUrl} rel="noopener noreferrer" target="_blank">
					{localizationService.getLocalizedString('cccImportFile.step3ErrorMessagePart2')}
					&nbsp;
				</a>
				&nbsp;
				{localizationService.getLocalizedString('cccImportFile.step3ErrorMessagePart3')}
			</>
		);
	};

	return (
		<StepperContent
			id="step3Results"
			className="full-width"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn btn-link" onClick={onClickStartAnother}>
						{localizationService.getLocalizedString('screen.buttons.startAnotherImport')}
					</StepperAction>
				</React.Fragment>
			}>
			{step2Data.totalRowNumber <= bigImportBar ? (
				<p className="mt-n3">
					{step2Data.errorRowNumber > 0 && (
						<div>
							<div className="mt-n3 d-inline-flex">
								<InlineAlertItem
									message={inlineErrorAlertContent()}
									alertType={AlertMessageType.Error}
								/>
							</div>
						</div>
					)}
					{step2Data.successRowNumber > 0 && (
						<div className="mt-2 d-inline-flex">
							<InlineAlertItem
								message={localizationService.getLocalizedString(
									'cccImportFile.step3SuccessMessage',
									_.toString(step2Data.successRowNumber),
									step2Data.successRowNumber > 1 ? 's' : ''
								)}
								alertType={AlertMessageType.Success}
							/>
						</div>
					)}
					<div className="mt-2">
						<ImportResultLinks
							importId={step2Data.importId}
							importEntityResults={step2Data.importHistories}
						/>
					</div>
				</p>
			) : (
				<div>
					<p>
						{localizationService.getLocalizedString('cccImportFile.step3ImportSuccessStart')}&nbsp;
						<a href={importHistoryBaseUrl} rel="noopener noreferrer">
							{localizationService.getLocalizedString('importHistory.importHistory')}
						</a>
						&nbsp;
						{localizationService.getLocalizedString('cccImportFile.page')}
					</p>
				</div>
			)}
		</StepperContent>
	);
};

export default Step3;
