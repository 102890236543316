import {
	JurisdictionListActionType,
	JurisdictionAction,
	JurisdictionListState,
	initialJurisdictionListState
} from './jurisdiction-actions';

export const jurisdictionReducer = (
	state = initialJurisdictionListState,
	action: JurisdictionAction
): JurisdictionListState => {
	switch (action.type) {
		case JurisdictionListActionType.LoadJurisdictionList:
			return { ...state, jurisdictionList: action.jurisdictionList };
		default:
			return state;
	}
};
