import { RestSlice } from 'src/redux/rest.slice';
import { IppReportPackageTemplateElement } from '@rcp/types';
import { Resource, localizationService } from 'src/services';

export const ippElementDetailsSlice = new RestSlice<IppReportPackageTemplateElement>(
	localizationService.getLocalizedString(
		'ipp.reportPackage.reportPackageElements.reportTemplateElementDetails.title'
	),
	Resource.IppReportPackageReportElementTypes
);
export default ippElementDetailsSlice.rests.reducer;
