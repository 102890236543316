import React from 'react';
import _ from 'lodash';
import { CustomFormAttachment } from '@rcp/types';
import { fileUtilService, localizationService } from 'src/services';
import { CustomFormService } from 'src/services/custom-form-service';
import { alertService } from 'src/redux';
import { DragAndDrop } from '../../drag-and-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import {
	faFileCsv,
	faFile,
	faFileAudio,
	faFileExcel,
	faFilePdf,
	faFilePowerpoint,
	faFileVideo,
	faFileWord,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { TakeOverModal } from '../../takeover-modal';
import { ModalAttachmentService } from './modal-attachments-service';

import '../attachment.scss';

interface AttachmentProps {
	attachment: CustomFormAttachment;
	thumbnail: boolean;
}

interface AttachmentsProps {
	service: ModalAttachmentService;
	readonly?: boolean;
	className: string;
	attachments: CustomFormAttachment[];
	isNarrowView?: boolean;
}

const AttachmentItem: React.FC<AttachmentProps> = props => {
	const fileType = _.toLower(props.attachment.fileType);
	const getFaIcon = (): IconDefinition => {
		if (fileUtilService.isPdf(fileType)) {
			return faFilePdf;
		}
		if (fileUtilService.isOfficeDocument(fileType)) {
			return faFileWord;
		}
		if (fileUtilService.isSpreadsheet(fileType)) {
			return faFileExcel;
		}
		if (fileUtilService.isSlide(fileType)) {
			return faFilePowerpoint;
		}
		if (fileUtilService.isAudio(fileType)) {
			return faFileAudio;
		}
		if (fileUtilService.isVideo(fileType)) {
			return faFileVideo;
		}
		if (fileUtilService.isCsv(fileType)) {
			return faFileCsv;
		}
		return faFile;
	};

	const pictureElement = (): JSX.Element => {
		let imgUrl = props.attachment.s3ResourceUrl;
		if (props.thumbnail === true && props.attachment.s3ThumbnailUrl) {
			imgUrl = props.attachment.s3ThumbnailUrl;
		}
		if (props.thumbnail !== true) {
			return (
				<div className="attachment-image">
					<img src={imgUrl} alt={props.attachment.fileName} />
					<div className="invisible attachment-filename">{props.attachment.fileName}</div>
				</div>
			);
		}
		return (
			<>
				<div
					style={{
						backgroundImage: `url(${imgUrl})`
					}}
					title={props.attachment.fileName}
					className="attachment-thumbnail-image"
				/>
				<div className="invisible attachment-filename">{props.attachment.fileName}</div>
			</>
		);
	};

	const iconElement = (): JSX.Element => {
		const faIcon = getFaIcon();
		if (props.thumbnail !== true) {
			return (
				<div className="align-center">
					<div className="mx-auto">
						<FontAwesomeIcon icon={faIcon} className="attachment-icon" />
					</div>
					<div className="mx-auto attachment-filename">{props.attachment.fileType}</div>
				</div>
			);
		}
		return (
			<div className="align-center">
				<div className="mx-auto">
					<FontAwesomeIcon icon={faIcon} className="attachment-thumbnail-icon" />
				</div>
				<div className="mx-auto attachment-filename">{props.attachment.fileName}</div>
			</div>
		);
	};

	const isPicture = fileUtilService.isPicture(fileType);
	return isPicture ? pictureElement() : iconElement();
};

export const ModalAttachments: React.FC<AttachmentsProps> = props => {
	const [viewAttachment, setViewAttachment] = React.useState<CustomFormAttachment | null>(null);

	React.useEffect(() => {
		props.service.loadAttachments();
	}, []);

	const passAttachments = (chosenFiles: any) => {
		let newAttachments = [...props.attachments];
		for (let x = 0; x < chosenFiles.length; x++) {
			let newAttachment = chosenFiles[x] as CustomFormAttachment;
			let filename = newAttachment.name;
			let fileUrl = URL.createObjectURL(newAttachment);
			newAttachment.s3ResourceUrl = fileUrl;
			newAttachment.s3ThumbnailUrl = fileUrl;
			newAttachment.fileName = filename;
			newAttachment.fileType = fileUtilService.getFiletype(filename);
			newAttachments!.push(newAttachment);
		}
		props.service.setCurrentAttachments(newAttachments);
	};
	const handleDrop = (chosenFiles: any) => {
		if (
			chosenFiles == null ||
			chosenFiles.length < 1 ||
			!CustomFormService.validateHasVideo(chosenFiles) ||
			!CustomFormService.validateFileTypes(chosenFiles)
		) {
			return;
		}
		if (!props.service.validatePickedFilesForUpload(chosenFiles)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			return;
		}
		chosenFiles.length <= 1 && alertService.clearAllMessages();
		passAttachments(chosenFiles);
	};
	const onFileChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;

		if (
			chosenFiles == null ||
			chosenFiles.length < 1 ||
			!CustomFormService.validateHasVideo(chosenFiles) ||
			!CustomFormService.validateFileTypes(chosenFiles)
		) {
			return;
		}
		if (!props.service.validatePickedFilesForUpload(chosenFiles)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			return;
		}
		chosenFiles.length <= 1 && alertService.clearAllMessages();
		passAttachments(chosenFiles);
	};
	const removeAttachment = (index: number) => {
		let newAttachments = [...props.attachments];
		newAttachments.splice(index, 1);
		props.service.setCurrentAttachments(newAttachments);
	};
	const downloadFile = () => {
		if (viewAttachment && viewAttachment.s3ResourceUrl && viewAttachment.fileName) {
			let a = document.createElement('a');
			a.href = viewAttachment.s3ResourceUrl;
			a.download = viewAttachment.fileName;
			a.target = '_blank';
			a.click();
		}
	};
	return (
		<div className={`modal-attachments ${props.className}`}>
			<label>{localizationService.getLocalizedString('attachments.title')}</label>
			{props.readonly !== true && (
				<DragAndDrop
					handleDrop={handleDrop}
					className={`drag-submit ${!_.isEmpty(props.attachments) ? 'border-bottom-no-radius' : ''}`}
					dragMessage={localizationService.getLocalizedString('attachments.dragAndDrop')}>
					<div className="file-input-group p-1">
						<label
							className="btn ai-secondary"
							htmlFor="btnModalAttachment"
							aria-describedby="file-input-help">
							{localizationService.getLocalizedString('attachments.chooseFiles')}
						</label>
						<input
							type="file"
							id="btnModalAttachment"
							name="btnModalAttachment"
							className="file-input mr-4"
							onChange={onFileChangeHandler}
							onClick={(e: any) => {
								e.target.value = null;
							}}
							style={{ width: '0px' }}
							multiple
						/>
						<label className={`file-name ${props.isNarrowView ? '' : 'ml-4 pl-4'}`}>
							{localizationService.getLocalizedString('attachments.dragAndDrop')}
						</label>
					</div>
				</DragAndDrop>
			)}
			{!_.isEmpty(props.attachments) && (
				<div className="attachment-list py-1 pl-1">
					{_.map(props.attachments, (attachment: CustomFormAttachment, index: number) => {
						return (
							<div
								key={`modal-attachment-${index}`}
								className="attachment-item"
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									setViewAttachment(attachment);
								}}>
								{props.readonly !== true && (
									<div className="attachment-remove d-print-none">
										<FontAwesomeIcon
											icon={faTrashAlt}
											className="font-awesome-icon"
											onClick={e => {
												e.preventDefault();
												e.stopPropagation();
												removeAttachment(index);
											}}
										/>
									</div>
								)}
								<AttachmentItem attachment={attachment} thumbnail={true} />
							</div>
						);
					})}
				</div>
			)}
			{viewAttachment != null && (
				<TakeOverModal
					title={viewAttachment.fileName}
					isDownloadButton={!viewAttachment.s3ResourceUrl?.startsWith('blob')}
					downloadImage={downloadFile}
					cancel={() => {
						setViewAttachment(null);
					}}
					isAboveAnotherModal>
					<div className="w-100">
						<AttachmentItem attachment={viewAttachment} thumbnail={false} />
					</div>
				</TakeOverModal>
			)}
		</div>
	);
};
