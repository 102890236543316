import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../alert/alert-service';
import { FilterDefinition, FilterValidationBody } from '../../services/data-types/filter-types';
import _ from 'lodash';
import { Filter, MaxInteger, PaginatedResult, UserRecentFilter } from '@rcp/types';
import { MappingToApiFormat, MappingFromApiFormat } from './filterSerializer';

export interface FilterState {
	filters: ApiTypes.Filter[];
	currentDraftFilter: ApiTypes.DraftFilter | undefined;
	currentFilter: ApiTypes.Filter | undefined;
	recentFilters: ApiTypes.Filter[];
}

export const initialFilterState: FilterState = {
	filters: [],
	currentDraftFilter: undefined,
	currentFilter: undefined,
	recentFilters: []
};

export type FilterThunkAction = ThunkAction<any, FilterState, any, Action>;

export enum FilterActionType {
	SaveDraftFilterAsFormalFilterRequestType = 'saveDraftFilterAsFormalFilterRequest',
	SaveDraftFilterAsFormalFilterRequestSucceedType = 'saveDraftFilterAsFormalFilterRequestSucceed',

	saveDraftFilterRequestType = 'saveDraftFilterRequest',
	saveDraftFilterRequestSucceedType = 'saveDraftFilterSucceed',

	saveFilterRequestType = 'saveFilterRequest',
	saveFilterRequestSucceedType = 'saveFilterSucceed',

	GetRecentFiltersRequestType = 'getRecentFiltersRequestType',
	GetRecentFiltersRequestSucceedType = 'getRecentFiltersRequestSucceed',

	GetFiltersRequestType = 'getFiltersRequestType',
	GetFiltersRequestSucceedType = 'getFiltersRequestSucceed',

	GetDraftFilterRequestType = 'getDraftFilterRequestType',
	GetDraftFilterRequestSucceedType = 'getDraftFilterRequestSucceed',

	GetFilterRequestType = 'getFilterRequestType',
	GetFilterRequestSucceedType = 'getFilterRequestSucceed',

	DeleteFilterRequestType = 'deleteFilterRequestType',
	DeleteFilterRequestSucceedType = 'deleteFilterRequestSucceed'
}

export const createOrUpdateDraftFilter = (
	applyTo: string | undefined,
	filterName: string,
	filterDefinition: FilterDefinition
): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.saveDraftFilterRequestType });
	let filtersDefinition = _.cloneDeep(filterDefinition);
	let filters = filtersDefinition.getAllFilterItems();

	let apiFilters = MappingToApiFormat(filters);

	let draftFilterToSent: ApiTypes.DraftFilter = {
		applyTo: applyTo,
		filterName,
		filterDefinition: JSON.stringify(apiFilters)
	};

	if (filterDefinition.filterId) {
		draftFilterToSent.filterId = filterDefinition.filterId;
	}

	const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
	let draftFilterId = filterDefinition.draftFilterId;
	let draftFilterSaved: ApiTypes.DraftFilter | undefined;

	if (!draftFilterId) {
		draftFilterSaved = await apiService.postResource<ApiTypes.DraftFilter>(draftFilterUrl, draftFilterToSent);
	} else {
		draftFilterSaved = await apiService.patchResource<ApiTypes.DraftFilter>(
			`${draftFilterUrl}/${draftFilterId}`,
			draftFilterToSent
		);
	}

	draftFilterSaved.filterDefinition = MappingFromApiFormat(
		draftFilterSaved.filterDefinition ? draftFilterSaved.filterDefinition : ''
	);

	dispatch({ type: FilterActionType.saveDraftFilterRequestSucceedType, draftFilterSaved });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', 'Draft filter'));
	return draftFilterSaved;
};

export const updateDraftFilter = (
	draftFilterId: number,
	applyTo: string,
	filterName: string,
	filterDefinition: FilterDefinition
): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.saveDraftFilterRequestType });
	let filtersDefinition = _.cloneDeep(filterDefinition);
	let filters = filtersDefinition.getAllFilterItems();
	let apiFilters = MappingToApiFormat(filters);
	let draftFilterToUpdate: ApiTypes.DraftFilter = {
		applyTo: applyTo,
		filterName,
		filterId: filterDefinition.filterId,
		filterDefinition: JSON.stringify(apiFilters)
	};
	const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
	const draftFilterSaved = await apiService.patchResource<ApiTypes.DraftFilter>(
		`${draftFilterUrl}/${draftFilterId}`,
		draftFilterToUpdate
	);
	draftFilterSaved.filterDefinition = MappingFromApiFormat(
		draftFilterSaved.filterDefinition ? draftFilterSaved.filterDefinition : ''
	);
	dispatch({ type: FilterActionType.saveDraftFilterRequestSucceedType, draftFilterSaved });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', 'Draft filter'));
	return draftFilterSaved;
};

export const updateFilter = (
	filterId: number,
	applyTo: string | undefined,
	filterName: string,
	filterDefinition: FilterDefinition
): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.saveFilterRequestType });
	let filtersDefinition = _.cloneDeep(filterDefinition);
	let filters = filtersDefinition.getAllFilterItems();
	let apiFilters = MappingToApiFormat(filters);
	let filterToUpdate: ApiTypes.Filter = {
		applyTo: applyTo,
		filterName,
		filterId: filterDefinition.filterId,
		filterDefinition: JSON.stringify(apiFilters)
	};
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
	const filterSaved = await apiService.patchResource<ApiTypes.DraftFilter>(
		`${filterUrl}/${filterId}`,
		filterToUpdate
	);
	filterSaved.filterDefinition = MappingFromApiFormat(
		filterSaved.filterDefinition ? filterSaved.filterDefinition : ''
	);
	dispatch({ type: FilterActionType.saveFilterRequestSucceedType, filterSaved: filterSaved });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', 'Filter'));
	return filterSaved;
};

export const saveDraftFilterAsFormalFilter = (draftFilterId: number): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.SaveDraftFilterAsFormalFilterRequestType });
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
	const filterSaved = await apiService.httpPost(
		`${filterUrl}/SaveDraftFilterAsFilter?draftFilterId=${draftFilterId}`,
		{}
	);
	dispatch({ type: FilterActionType.SaveDraftFilterAsFormalFilterRequestSucceedType, filterSaved: filterSaved });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', 'Filter'));
	return filterSaved;
};

export const GetRecentFilters = (applyToDomain?: string) => async (dispatch: any) => {
	dispatch({ type: FilterActionType.GetRecentFiltersRequestType });
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
	const recentFilters = await apiService.getResource<UserRecentFilter[]>(
		`${filterUrl}/MyRecent${applyToDomain ? `?applyTo=in:${applyToDomain}` : ''}`
	);
	return recentFilters;
};

export const GetFilters = (applyToDomain?: string, getAllFilters?: boolean) => async (dispatch: any) => {
	dispatch({ type: FilterActionType.GetFiltersRequestType });
	let queryParameters = new QueryParameters()
		.put('size', MaxInteger)
		.put('includes', 'filterId,filterName,isSystem,applyTo,widgetCount');
	if (applyToDomain) {
		queryParameters.put('applyTo', `in:${applyToDomain}`);
	}
	if (getAllFilters) {
		queryParameters.put('retrieveAllFilters', 'true');
	}
	let queryString = queryParameters.toQueryString();
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters, queryString);
	const filtersResponse = await apiService.getResource<PaginatedResult<Filter>>(`${filterUrl}`);
	let filters = filtersResponse.result.map(i => {
		if (i.filterDefinition) {
			i.filterDefinition = MappingFromApiFormat(i.filterDefinition);
		}

		return i;
	});

	dispatch({ type: FilterActionType.GetFiltersRequestSucceedType, filters });
	return filters;
};

export const GetDraftFilterById = (draftFilterId: number): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.GetDraftFilterRequestType });
	const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
	const draftFilter = await apiService.getResource<ApiTypes.DraftFilter>(`${draftFilterUrl}/${draftFilterId}`);
	draftFilter.filterDefinition = MappingFromApiFormat(
		draftFilter.filterDefinition ? draftFilter.filterDefinition : ''
	);
	dispatch({ type: FilterActionType.GetDraftFilterRequestSucceedType, draftFilter: draftFilter });
	return draftFilter;
};

export const DeleteDraftFilterById = (draftFilterId: number): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.DeleteFilterRequestType });
	const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
	let deleted = await apiService.deleteResource(`${draftFilterUrl}/${draftFilterId}`);
	return deleted;
};

export const GetFilterById = (filterId: number): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.GetFilterRequestType });
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
	const filter = await apiService.getResource<ApiTypes.Filter>(`${filterUrl}/${filterId}?retrieveAllFilters=true`);
	filter.filterDefinition = MappingFromApiFormat(filter.filterDefinition ? filter.filterDefinition : '');
	dispatch({ type: FilterActionType.GetFilterRequestSucceedType, filter: filter });
	return filter;
};

export const DeleteFilterById = (filterId: number): FilterThunkAction => async (dispatch: any) => {
	dispatch({ type: FilterActionType.DeleteFilterRequestType });
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);
	let deleted = await apiService.deleteResource(`${filterUrl}/${filterId}`);
	return deleted;
};

export const IsFilterNameValid = async (filterValidationBody: FilterValidationBody): Promise<boolean> => {
	if (!filterValidationBody.filterName) return false;
	const filterUrl = urlService.getAuthorityResourcesApiUrl(Resource.Filters);

	let isValid = await apiService.httpPost(`${filterUrl}/ValidateFilter`, filterValidationBody);
	return isValid;
};

export const filterReducer = (state = initialFilterState, action: any): FilterState => {
	switch (action.type) {
		case FilterActionType.GetFiltersRequestSucceedType:
			return { ...state, filters: action.filters };

		case FilterActionType.GetRecentFiltersRequestSucceedType:
			return { ...state, recentFilters: action.recentFilters };

		case FilterActionType.saveDraftFilterRequestSucceedType:
			return { ...state, ...{ currentDraftFilter: action.draftFilterSaved } };

		case FilterActionType.SaveDraftFilterAsFormalFilterRequestSucceedType:
		case FilterActionType.saveFilterRequestSucceedType:
			return { ...state, ...{ currentFilter: action.filterSaved } };

		case FilterActionType.GetDraftFilterRequestSucceedType:
			return { ...state, ...{ currentDraftFilter: action.draftFilter } };

		case FilterActionType.GetFilterRequestSucceedType:
			return { ...state, ...{ currentFilter: action.filter } };

		default:
			return { ...state };
	}
};
