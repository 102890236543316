import React from 'react';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { DateUtilService, localizationService, tokenService } from 'src/services';
import { Translate } from './translate/translator';

interface Props {
	id: string;
	name: string;
	label?: string;
	withoutWrappingDiv?: boolean;
	className?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	value: string | null | undefined; // date time value with timezone
	error?: string;
	format?: string;
	min?: string;
	max?: string;
	onChange?(e: any): void;
	// Set it true if you want that date picker not picks authority timezone by default
	followLocalTimezone?: boolean;
	helpText?: string;
	showErrorAndHelp?: boolean;
	doNotTranslate?: boolean;
}

export const DateInput: React.SFC<Props> = props => {
	const [existingDate, setExistingDate] = React.useState<Date | undefined | null>(null);

	React.useEffect(() => {
		let propDate;
		if (!props.value || !DateUtilService.isValidDateTime(props.value)) {
			propDate = null;
		} else {
			propDate = DateUtilService.prepareDateForDateTimePicker(props.value);
		}
		setExistingDate(propDate);
	}, [props.value]);

	const handleChange = (e: any) => {
		if (DateUtilService.checkDateInBrowserTimeZone(e.target.value)) {
			let newE = {
				target: {
					name: e.target.name,
					value: DateUtilService.prepareDateStringForApiCall(e.target.value)
				}
			};

			if (props.onChange) {
				props.onChange(newE);
			}
		}
	};

	const getWrappingClass = (): string => {
		let classStr = 'form-group';
		if (props.className) {
			classStr = props.className;
			classStr = classStr + (tokenService.hasAccessToIppPortal() ? ' form-group ' : '');
		}

		if (props.isRequired) {
			classStr += ' required';
		}
		return classStr;
	};

	const getDatePicker = () => {
		return (
			<DatePicker
				id={props.id}
				name={props.name}
				format={props.format || localizationService.getLocalizedString('dateFormats.datePicker')}
				max={props.max ? DateUtilService.prepareDateForDateTimePicker(props.max) : undefined}
				min={props.min ? DateUtilService.prepareDateForDateTimePicker(props.min) : undefined}
				className={'w-100' + (props.error ? ' is-invalid' : '')}
				defaultValue={existingDate}
				value={existingDate}
				valid={props.error ? false : true}
				onChange={handleChange}
				required={props.error ? true : false} //Force kendo-ui datepicker display error mode when props.error exists
				disabled={props.isDisabled === true ? true : false}
				popupSettings={{ animate: false }}
			/>
		);
	};

	return props.withoutWrappingDiv === true ? (
		getDatePicker()
	) : (
		<div className={getWrappingClass()} id={`${props.id}_div`}>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
				</label>
			) : (
				<label htmlFor={props.id}>
					<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
				</label>
			)}
			{getDatePicker()}
			{!props.showErrorAndHelp && props.error && (
				<div className="invalid-feedback error-block ">
					<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
				</div>
			)}
			{!props.showErrorAndHelp && !props.error && props.helpText && (
				<div className="ai-form-help">
					<Translate doNotTranslate={props.doNotTranslate}>{props.helpText}</Translate>
				</div>
			)}
			{props.showErrorAndHelp && (props.error || props.helpText) && (
				<span>
					<div className="ai-form-help ai-required">
						<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
					</div>
					<div className="ai-form-help mt-2">
						<Translate doNotTranslate={props.doNotTranslate}>{props.helpText}</Translate>
					</div>
				</span>
			)}
		</div>
	);
};
