import React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { DateInput, SingleSelectDropdown } from 'src/components/widgets';
import { localizationService, DateUtilService, validationService } from 'src/services';

import './timeline.scss';
import { CloseSvg } from '../../../../../svg';
import { DropDownOption, MonthOffset } from '@rcp/types';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { GetInitialTimelineSettings, TimelineSettings } from './timeline-type';

interface OwnProps {
	anchor: HTMLElement;
	show: boolean;
	timelineFilterForm: TimelineSettings;
	applyBtnClicked: (dateRangeForm: TimelineSettings) => void;
	closeModal: () => void;
}

type dateRangePopoverProp = OwnProps;

const StartDateDropdownOptions: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.timeline.oneYearAgo'),
		value: MonthOffset.OneYear
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.sixMonthsAgo'),
		value: MonthOffset.SixMonths
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.threeMonthsAgo'),
		value: MonthOffset.ThreeMonths
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.startOfTime'),
		value: MonthOffset.StartOfTimeOrEndOfTime
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.custom'),
		value: MonthOffset.Custom
	}
];

const EndDateDropdownOptions: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.timeline.oneYearFromNow'),
		value: MonthOffset.OneYear
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.sixMonthsFromNow'),
		value: MonthOffset.SixMonths
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.threeMonthsFromNow'),
		value: MonthOffset.ThreeMonths
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.endOfTime'),
		value: MonthOffset.StartOfTimeOrEndOfTime
	},
	{
		label: localizationService.getLocalizedString('screen.timeline.custom'),
		value: MonthOffset.Custom
	}
];

export const TimelineDateRange: React.SFC<dateRangePopoverProp> = props => {
	const [dateRangeFilterForm, setDateRangeFilterForm] = React.useState(GetInitialTimelineSettings());
	const [showStartDate, setShowStartDate] = React.useState(true);
	const [showEndDate, setShowEndDate] = React.useState(true);
	React.useEffect(() => {
		if (props.timelineFilterForm) {
			let newTimelineFilterForm = { ...props.timelineFilterForm, startDateError: '', endDateError: '' };
			setDateRangeFilterForm(newTimelineFilterForm);
			setShowStartDate(!newTimelineFilterForm.startOfTime);
			setShowEndDate(!newTimelineFilterForm.endOfTime);
		}
	}, [props.timelineFilterForm]);

	let hasAnchor = props.anchor instanceof HTMLElement;

	const onPredefinedStartDateChanged = (e: any) => {
		var selected = _.toNumber(e.target.value as MonthOffset);
		var currentSelectionIndex = dateRangeFilterForm.startDateIndex;
		let newDateRangeForm = {
			...dateRangeFilterForm,
			...{ startDateIndex: selected, startOfTime: selected === MonthOffset.StartOfTimeOrEndOfTime }
		};

		if (selected === MonthOffset.Custom) {
			if (currentSelectionIndex === MonthOffset.StartOfTimeOrEndOfTime) {
				newDateRangeForm.startDate = DateUtilService.getRelativeDate(MonthOffset.OneYear, -1);
			}
		} else {
			newDateRangeForm.startDate = DateUtilService.getRelativeDate(selected, -1);
		}

		setShowStartDate(selected !== MonthOffset.StartOfTimeOrEndOfTime);
		setDateRangeFilterForm(newDateRangeForm);
	};

	const onPredefinedEndDateChanged = (e: any) => {
		var selected = _.toNumber(e.target.value as MonthOffset);
		var currentSelectionIndex = dateRangeFilterForm.endDateIndex;
		let newDateRangeForm = {
			...dateRangeFilterForm,
			...{ endDateIndex: selected, endOfTime: selected === MonthOffset.StartOfTimeOrEndOfTime }
		};

		if (selected === MonthOffset.Custom) {
			if (currentSelectionIndex === MonthOffset.StartOfTimeOrEndOfTime) {
				newDateRangeForm.endDate = DateUtilService.getRelativeDate(MonthOffset.ThreeMonths, 1);
			}
		} else {
			newDateRangeForm.endDate = DateUtilService.getRelativeDate(selected, 1);
		}

		setShowEndDate(selected !== MonthOffset.StartOfTimeOrEndOfTime);
		setDateRangeFilterForm(newDateRangeForm);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...dateRangeFilterForm };
		validationService.validateMinimumDate(
			newState,
			'startDate',
			'startDateError',
			localizationService.getLocalizedString('screen.labels.startDate')
		);
		validationService.validateMinimumDate(
			newState,
			'endDate',
			'endDateError',
			localizationService.getLocalizedString('screen.labels.endDate')
		);
		setDateRangeFilterForm(newState);

		return !validationService.hasError(
			newState,
			'monthlyFeeError',
			'annualFeeError',
			'startDateError',
			'endDateError',
			'closeDateError'
		);
	};

	const onApplyBtnClicked = () => {
		isFormValidateForSave() && props.applyBtnClicked(dateRangeFilterForm);
	};

	const onCancelBtnClicked = () => {
		props.closeModal();
	};

	const onEndDateChanged = (e: any) => {
		if (_.toString(e.target.value) !== _.toString(dateRangeFilterForm.endDate)) {
			let newDateRangeForm = { ...dateRangeFilterForm, ...{ endDate: e.target.value } };
			newDateRangeForm.endDateIndex = MonthOffset.Custom;
			setDateRangeFilterForm(newDateRangeForm);
		}
	};

	const onStartDateChange = (e: any) => {
		if (_.toString(e.target.value) !== _.toString(dateRangeFilterForm.startDate)) {
			let newDateRangeForm = { ...dateRangeFilterForm, ...{ startDate: e.target.value } };
			newDateRangeForm.startDateIndex = MonthOffset.Custom;
			setDateRangeFilterForm(newDateRangeForm);
		}
	};

	return !hasAnchor ? null : (
		<Popup
			anchor={props.anchor}
			show={props.show}
			className="shadow timeline-filter-modal-body"
			animate={false}
			popupAlign={{ horizontal: 'right', vertical: 'top' }}
			anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}>
			<div id="d-sm-flex flex-row">
				<div className="close-div flex-row-reverse cursor-pointer " onClick={props.closeModal}>
					<CloseSvg />
				</div>
				<div id="timelineDateRangeLabel" className="mb-2 ">
					<strong>{localizationService.getLocalizedString('screen.timeline.timelineDateRange')}</strong>
				</div>
				<div className="form-row">
					<SingleSelectDropdown
						id="timelineDateRangeStart"
						name="timelineDateRangeStart"
						className="form-group col-6"
						label={localizationService.getLocalizedString('screen.labels.startDate')}
						value={_.toString(dateRangeFilterForm.startDateIndex)}
						onChange={onPredefinedStartDateChanged}
						options={StartDateDropdownOptions}
						noEmptyOption={true}
						selfOrder={true}
					/>

					<SingleSelectDropdown
						id="timelineDateRangeEnd"
						name="timelineDateRangeEnd"
						className="form-group col-6"
						label={localizationService.getLocalizedString('screen.labels.endDate')}
						value={_.toString(dateRangeFilterForm.endDateIndex)}
						onChange={onPredefinedEndDateChanged}
						options={EndDateDropdownOptions}
						noEmptyOption={true}
						selfOrder={true}
					/>
				</div>

				{(showEndDate || showStartDate) && (
					<div className="form-row">
						{showStartDate ? (
							<DateInput
								className={`form-group col-6`}
								id="timelineDateRangeStart"
								name="timelineDateRangeStart"
								error={dateRangeFilterForm.startDateError}
								value={dateRangeFilterForm.startDate}
								onChange={onStartDateChange}
							/>
						) : (
							<div className="form-group col-6"></div>
						)}

						{showEndDate ? (
							<DateInput
								className={`form-group col-6`}
								id="timelineDateRangeEnd"
								name="timelineDateRangeEnd"
								error={dateRangeFilterForm.endDateError}
								value={dateRangeFilterForm.endDate}
								onChange={onEndDateChanged}
							/>
						) : (
							<div className="form-group col-6"></div>
						)}
					</div>
				)}

				<div className="popup-modal-footer ml-auto mt-3">
					<Button
						color="btn ai-action"
						id="applyDateRangeFilter"
						className="popup-modal-btn"
						onClick={onApplyBtnClicked}>
						{localizationService.getLocalizedString('screen.buttons.apply')}
					</Button>
					<Button
						color="btn ai-white"
						id="cancelDateRangeFilter"
						className="popup-modal-btn"
						onClick={onCancelBtnClicked}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</div>
		</Popup>
	);
};
