import React, { FC } from 'react';
import { History } from 'history';
import {
	RouteProps,
	PendingNotice,
	ColumnField,
	FilterType,
	LocalStorageName,
	DropDownOption,
	Dictionary,
	NoticeStatus,
	SentNoticeHistory,
	PendingNoticeBatchDto
} from '@rcp/types';
import { DataGrid, DataGridContext, GridOption } from 'src/features/data-grid/data-grid';
import { cccPendingNoticeSlice } from './pending-notices.slice';
import { alertService, RestSlice, RootState } from 'src/redux';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { PopoverModal, SingleSelectDropdown } from 'src/components/widgets';

import './pending-notices.scss';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { noticeHistorySlice } from './histories/notice-histories.slice';
import { noticeTemplateScheduleSlice } from '../settings/notices/notice-setting.slice';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const NoticeTypeDropdown: FC<{ noticeType: string; options: DropDownOption[]; onChange?(e: any): void }> = props => {
	const dataGridContext = React.useContext(DataGridContext);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onChange = (evt: any) => {
		const { value } = evt.target;
		if (_.isEmpty(value)) {
			return;
		}
		if (props.onChange) {
			props.onChange(evt);
		}

		let gridState = dataGridContext.getGridState();
		let newQueryParameters: Dictionary<string> = {};
		if (gridState && gridState.queryParameters) {
			newQueryParameters = { ...gridState.queryParameters };
		}
		let templateId = _.toNumber(value);
		if (templateId > 0) {
			_.set(newQueryParameters, 'templateId', templateId);
		} else {
			_.unset(newQueryParameters, 'templateId');
		}
		dataGridContext.setGridState({ queryParameters: newQueryParameters });
	};

	return (
		<div className="d-flex">
			<SingleSelectDropdown
				id="sendNoticeTypes"
				name="sendNoticeTypes"
				className="form-group send-notice"
				label={localizationService.getLocalizedString('ccc.notices.send')}
				isRequired={true}
				noEmptyOption={true}
				value={props.noticeType}
				onChange={onChange}
				options={props.options}
				selfOrder={true}
			/>
		</div>
	);
};

export const CccNotices: FC<Props<PendingNotice>> = props => {
	const [showSentModal, setShowSentModal] = React.useState(false);
	const [showRefreshModal, setShowRefreshModal] = React.useState(false);
	const [numberOfLetters, setNumberOfLetters] = React.useState<number>(0);

	const downloadSinglePdf = (e: any, pendingNotice: PendingNotice) => {
		e.preventDefault();
		checkCurrentBatchStatus().then(res => {
			if (!res) {
				const url = `${urlService.getAuthorityResourcesApiUrl(Resource.CccNotices)}/preview/${
					pendingNotice.pendingNoticeId
				}`;
				apiService.getResource(url);
			}
		});
	};

	const downloadLink = (item: any) => {
		const pendingNotice = item.dataItem as PendingNotice;
		return (
			<td>
				<a href="#/" id="btnPreview" className="ai-link" onClick={e => downloadSinglePdf(e, pendingNotice)}>
					{localizationService.getLocalizedString('ccc.notices.preview')}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('ccc.notices.gridTitle'),
		prefix: 'ccc.notices',
		storageName: LocalStorageName.CccNoticeGrid,
		gridUrlLocalStorageName: LocalStorageName.CccNoticesGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccNoticesSortOrder,

		showEditColumnButton: true,

		defaultSort: [],
		noRecordMessage: localizationService.getLocalizedString('ccc.notices.noDataMessage'),
		doNotValidateParameters: true,
		allColumns: [
			new ColumnField('templateName'),
			new ColumnField('companyName'),
			new ColumnField('contactName'),
			new ColumnField('firstName'),
			new ColumnField('lastName'),
			new ColumnField('numberOfHazards', FilterType.Integer),
			new ColumnField('addressLine1'),
			new ColumnField('addressLine2'),
			new ColumnField('city'),
			new ColumnField('state'),
			new ColumnField('zip'),
			new ColumnField('preview', FilterType.Text, downloadLink)
		],
		defaultColumns: [
			'templateName',
			'companyName',
			'contactName',
			'addressLine1',
			'addressLine2',
			'city',
			'state',
			'zip',
			'numberOfHazards',
			'preview'
		]
	};

	const allNoticesOption: DropDownOption = {
		label: localizationService.getLocalizedString('ccc.notices.sendAllNotices'),
		value: '0'
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [noticeTypeOptionValues, setNoticeTypeOptionValues] = React.useState([allNoticesOption] as DropDownOption[]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [noticeType, setNoticeType] = React.useState<string>('0');
	const [noticeBatchId, setNoticeBatchId] = React.useState<string>();

	const dispatch = useDispatch();
	let { result: noticeTemplateSchedules } = useSelector((state: RootState) => state.noticeTemplateSchedules);
	let { result: pendingNotices } = useSelector((state: RootState) => state.pendingNotices);

	React.useEffect(() => {
		dispatch(noticeTemplateScheduleSlice.fetchPage({}));
	}, [dispatch]);

	React.useEffect(() => {
		if (pendingNotices) {
			setNumberOfLetters(pendingNotices.length);
			if (pendingNotices.length > 0) {
				let currentBatchId = pendingNotices[0].pendingNoticeBatchId;
				setNoticeBatchId(currentBatchId);
			}
		}
	}, [pendingNotices]);

	React.useEffect(() => {
		if (noticeTemplateSchedules) {
			let options: DropDownOption[] = [allNoticesOption];
			// eslint-disable-next-line array-callback-return
			noticeTemplateSchedules.map(n => {
				if (n.isActive) {
					options.push({
						label: n.templateName,
						value: _.toString(n.noticeTemplateScheduleId)
					});
				}
			});
			setNoticeTypeOptionValues(options);
			setNoticeType(urlService.getUrlQueryParameter('templateId', '0') as string);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [noticeTemplateSchedules]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const updateNoticeType = (evt: any) => {
		const { value } = evt.target;
		if (_.isEmpty(value)) {
			return;
		}

		setNoticeType(value);
	};

	const createSentNoticePdf = (noticeUrl: string, response: any) => {
		let url = `${noticeUrl}/createSentNoticePdf/${response}`;
		apiService
			.getResource<SentNoticeHistory>(url, false)
			.then((noticeHistory: SentNoticeHistory) => {
				dispatch(noticeHistorySlice.fetchPage({}));

				if (noticeHistory.noticeStatus) {
					var noticeStatus = noticeHistory.noticeStatus.toLowerCase();
					if (String.equalCaseInsensitive(noticeStatus, NoticeStatus.Success.toString())) {
						alertService.addSuccess(
							localizationService.getLocalizedString('ccc.notices.sendNoticeSuccess')
						);
					}
					if (String.equalCaseInsensitive(noticeStatus, NoticeStatus.Fail.toString())) {
						alertService.addError(noticeHistory.errorDetails);
					}
				}
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
	};

	//Better check this by interval or timeout
	const checkCurrentBatchStatus = async (): Promise<boolean> => {
		let shouldRefreshList = false;

		const noticeUrl = `${urlService.getAuthorityResourcesApiUrl(Resource.CccNotices)}`;
		let url = `${noticeUrl}/${Resource.PendingNoticeBatchStatus}`;
		await apiService
			.getResource<PendingNoticeBatchDto>(url, false)
			.then((batchStatus: PendingNoticeBatchDto) => {
				if (batchStatus.batchId !== noticeBatchId || batchStatus.requireRegenerationPendingNotice) {
					setShowRefreshModal(true);
					shouldRefreshList = true;
				}
			})
			.catch((e: any) => {
				alertService.addError(e.message);
				shouldRefreshList = true;
			});

		return shouldRefreshList;
	};

	const sendNotices = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();

		setShowSentModal(false);

		{
			const noticeUrl = `${urlService.getAuthorityResourcesApiUrl(Resource.CccNotices)}`;
			let url = `${noticeUrl}/sentNotice/${noticeBatchId}`;

			if (noticeType && _.toNumber(noticeType) > 0) {
				url = `${url}/${noticeType}`;
			}

			apiService
				.getResource(url)
				.then((response: any) => {
					goToNoticeHistory(evt);
					alertService.addSuccess(localizationService.getLocalizedString('ccc.notices.sendNoticeStarted'));
					createSentNoticePdf(noticeUrl, response);
				})
				.catch((e: any) => {
					alertService.addError(e.message);
				});
		}
	};

	const cancelSendNotice = () => {
		setShowSentModal(false);
	};

	const cancelRefreshModal = () => {
		setShowRefreshModal(false);
	};

	const modalFooterDiv = (
		<>
			<button className="btn ai-action" onClick={sendNotices}>
				{localizationService.getLocalizedString('ccc.notices.send')}
			</button>
			<button className="btn ai-white" onClick={cancelSendNotice}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</>
	);

	const refreshModalFooterDiv = (
		<>
			<button
				className="btn ai-action"
				onClick={() => {
					setShowRefreshModal(false);
					dispatch(
						cccPendingNoticeSlice.fetchPage({}, () => {
							alertService.addSuccess(
								localizationService.getLocalizedString(
									'alertMessages.updateSuccess',
									localizationService.getLocalizedString('ccc.notices.noticeList')
								)
							);
						})
					);
				}}>
				{localizationService.getLocalizedString('ccc.notices.reloadPage')}
			</button>
		</>
	);

	const openSendNoticeModal = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (numberOfLetters === 0) {
			alertService.addInfo(localizationService.getLocalizedString('ccc.notices.noDataMessage'));
		} else {
			if (
				checkCurrentBatchStatus().then(res => {
					if (!res) {
						setShowSentModal(true);
					}
				})
			) {
			}
		}
	};

	const goToNoticeHistory = (evt: any) => {
		evt.preventDefault();
		evt.stopPropagation();
		const url = urlService.getReactResourceUrl(Resource.NoticeHistories);
		navigateTo(props.history, url);
	};

	const prePageChange = () => {
		checkCurrentBatchStatus();
	};

	const preSortChange = () => {
		checkCurrentBatchStatus();
	};

	return (
		<>
			<div className="w-100">
				<PopoverModal
					showModal={showRefreshModal}
					title={localizationService.getLocalizedString('ccc.notices.refreshModalTitle')}
					cancel={cancelRefreshModal}
					footer={refreshModalFooterDiv}
					withoutCloseButton>
					<p>{localizationService.getLocalizedString('ccc.notices.refreshModalBody')}</p>
				</PopoverModal>
				<PopoverModal
					showModal={showSentModal}
					title={localizationService.getLocalizedString(
						'ccc.notices.sendNoticeModalTitle',
						numberOfLetters.toString()
					)}
					cancel={cancelSendNotice}
					footer={modalFooterDiv}>
					<p>{localizationService.getLocalizedString('ccc.notices.modalDescription')}</p>
				</PopoverModal>
			</div>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccPendingNoticeSlice}
				restState={(state: RootState) => state.pendingNotices}
				gridOption={initialGridOption}
				prePageChange={prePageChange}
				preSortChange={preSortChange}
				headerElem={
					<>
						<h1>{localizationService.getLocalizedString('ccc.notices.gridTitle')}</h1>
						<a href="#/" className="mr-4" onClick={goToNoticeHistory}>
							{localizationService.getLocalizedString('ccc.notices.noticeHistory')}
						</a>
						<button className="btn ai-action filter-item" id="btnSendNotices" onClick={openSendNoticeModal}>
							{localizationService.getLocalizedString('ccc.notices.send')}
						</button>
					</>
				}
				filterElem={
					<NoticeTypeDropdown
						noticeType={noticeType}
						options={noticeTypeOptionValues}
						onChange={updateNoticeType}
					/>
				}
			/>
		</>
	);
};
