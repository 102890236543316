import React from 'react';
import { Extractor, CleaningEvent } from '@rcp/types';

import { apiService, localizationService, urlService, DateUtilService, Resource } from 'src/services';
import { RestSlice } from 'src/redux';
import { CleaningModal } from '../cleaning/cleaning-modal';

interface OwnProps {
	displayText: string;
	facilityId: number;
	deviceGridSlice: RestSlice<Extractor>;
	isEnterCleaning: boolean;
	device?: Extractor;
	completeCleaningId?: number;
	scheduledCleaningId?: number;
}

type Props = OwnProps;

const FogDeviceActionLink: React.FC<Props> = props => {
	const [scheduledCleaning, setScheduledCleaning] = React.useState({} as CleaningEvent);
	const [completeCleaning, setCompleteCleaning] = React.useState({} as CleaningEvent);
	const [showModal, setShowModal] = React.useState(false);
	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleCleaningModal = () => {
		setShowModal(!showModal);
	};

	React.useEffect(() => {
		if (showModal && props.scheduledCleaningId) {
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${props.facilityId}/${Resource.Cleanings}/${
				props.scheduledCleaningId
			}`;
			apiService.getResource<CleaningEvent>(url).then(schedCleaning => {
				setScheduledCleaning(schedCleaning);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.scheduledCleaningId, showModal]);

	React.useEffect(() => {
		if (showModal && props.completeCleaningId) {
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${props.facilityId}/${Resource.Cleanings}/${
				props.completeCleaningId
			}`;
			apiService.getResource<CleaningEvent>(url).then(compCleaning => {
				setCompleteCleaning(compCleaning);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.completeCleaningId, showModal]);

	return props.displayText !== '' ? (
		<>
			{props.isEnterCleaning ? (
				<a
					href="#/"
					id={`${localizationService.getLocalizedString('extractor.enterCleaningId')}`}
					className="ai-link"
					onClick={onEditClicked}>
					{props.displayText}
				</a>
			) : (
				<a
					href="#/"
					id={
						props.scheduledCleaningId
							? `${localizationService.getLocalizedString('extractor.nextCleaningId')}`
							: `${localizationService.getLocalizedString('extractor.completeCleaningId')}`
					}
					className="ai-link"
					onClick={onEditClicked}>
					{DateUtilService.toDisplayDate(props.displayText)}
				</a>
			)}

			{showModal && props.isEnterCleaning && (
				<CleaningModal
					isEnterCleaning={props.isEnterCleaning}
					isDevicePage={true}
					isGridPage={true}
					facilityId={props.facilityId}
					device={props.device}
					cleaning={scheduledCleaning}
					dueDateIsRequired={props.scheduledCleaningId ? true : false}
					completeDateIsRequired={true}
					modalToggleFunction={toggleCleaningModal}
					isAddModal
				/>
			)}
			{showModal && !props.isEnterCleaning && props.scheduledCleaningId && (
				<CleaningModal
					isEnterCleaning={false}
					isDevicePage={true}
					isGridPage={true}
					facilityId={props.facilityId}
					device={props.device}
					cleaning={scheduledCleaning}
					dueDateIsRequired={true}
					completeDateIsRequired={false}
					modalToggleFunction={toggleCleaningModal}
				/>
			)}
			{showModal && props.completeCleaningId && (
				<CleaningModal
					isEnterCleaning={false}
					isDevicePage={true}
					isGridPage={true}
					facilityId={props.facilityId}
					device={props.device}
					cleaning={completeCleaning}
					dueDateIsRequired={false}
					completeDateIsRequired={true}
					modalToggleFunction={toggleCleaningModal}
				/>
			)}
		</>
	) : (
		<></>
	);
};

export default FogDeviceActionLink;
