import React from 'react';

import * as ApiTypes from '@rcp/types';
import { localizationService, DateUtilService } from 'src/services';
import { EditCallLogModal } from '../facility-callLog/edit-callLog';

interface OwnProps {
	event: ApiTypes.CallLog;
}

type Props = OwnProps;

export const TimelineEventCallLog: React.SFC<Props> = props => {
	const [showEditCallLogModal, setShowEditCallLogModal] = React.useState(false);

	const onShowCallLogLinkClicked = (e: any) => {
		e.preventDefault();
		setShowEditCallLogModal(true);
	};

	const toggleEditCallLogModal = () => {
		setShowEditCallLogModal(!showEditCallLogModal);
	};

	const editedOnDiv = () => {
		let lastModifiedDate = DateUtilService.toDisplayDate(props.event.lastModificationDateTimeUtc);
		let lastModifiedTime = DateUtilService.toDisplayTime(props.event.lastModificationDateTimeUtc);
		let lastModifierUser = props.event.lastModifierUserFullName as string;
		let editedAtArguments: string[] = [lastModifiedDate, lastModifiedTime, lastModifierUser];
		return props.event.lastModificationDateTimeUtc !== props.event.creationDateTimeUtc ? (
			<div className="timeline-comment-edited-datetime">
				{localizationService.getLocalizedString('callLog.callLogEditedAt', ...editedAtArguments)}
			</div>
		) : null;
	};

	return (
		<div>
			<div>
				<a href="#/" onClick={onShowCallLogLinkClicked} id={`linkFacilityCallLog`}>
					{localizationService.getLocalizedString('callLog.callLogHref')}
				</a>
				{localizationService.getLocalizedString('callLog.loggedBy', props.event.creatorUserFullName as string)}
			</div>
			<p>{props.event.commentText}</p>
			{editedOnDiv()}
			{showEditCallLogModal && (
				<EditCallLogModal
					showModal={showEditCallLogModal}
					setShowModal={toggleEditCallLogModal}
					callLog={props.event}
				/>
			)}
		</div>
	);
};
