import { DropDownOption, IppUserProfile, Jurisdiction } from '@rcp/types';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { alertService } from 'src/redux/alert/alert-service';
import { apiService, localizationService, tokenService, urlService, validationService } from 'src/services';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import ChangeEmailModal from './change-email-modal';
import { ChangePasswordModal } from './change-password-modal';
import { IppConstants } from 'src/constants';
import { WithAqiSsoFeatureFlag } from '../authority/shared/settings/users/with-aqi-sso-feature-flag';

interface Props {
	onNextUserInformation?: (userInfo: any) => void;
	states: Jurisdiction[];
	profileDetails?: IppUserProfile;
	getProfileDetails: () => Promise<void>;
}

interface FormFields extends IppUserProfile {
	password?: string;
	confirmPassword?: string;
	confirmPasswordError?: string;
	emailError?: string;
	firstNameError?: string;
	fullNameError?: string;
	lastNameError?: string;
	phoneNumberError?: string;
	userNameError?: string;
	titleRoleError?: string;
	addressLine1Error?: string;
	cityNameError?: string;
	stateError?: string;
	zipCodeError?: string;
	passwordError?: string;
	businessNameError?: string;
	titleRole?: string;
	role?: string;
	isSignatory?: boolean;
	state?: string;
}

const initialFormFields: FormFields = {
	email: '',
	firstName: '',
	fullName: '',
	lastName: '',
	phoneNumber: '',
	userName: '',
	phoneExt: '',
	titleRole: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	zipCode: '',
	businessName: '',
	password: '',
	state: '',
	role: '',
	jurisdictionId: 0,
	isSignatory: false
};

const { fieldCharLimit } = IppConstants;

const ProfileDetailsComponent: FC<Props> = (props: Props) => {
	const [formState, setFormState] = useState(initialFormFields);
	const [changeEmailModal, setChangeEmailModal] = useState(false);
	const [changePasswordModal, setChangePasswordModal] = useState(false);
	const [stateOptionValues, setStateOptionValues] = useState([] as DropDownOption[]);
	const [enableEdit, setEnableEdit] = useState(false);

	useEffect(() => {
		const { profileDetails } = props;
		if (profileDetails) {
			setFormState({
				...profileDetails,
				jurisdictionId:
					profileDetails.jurisdictionId && profileDetails.jurisdictionId > -1
						? profileDetails.jurisdictionId
						: undefined
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.profileDetails]);

	useEffect(() => {
		let stateOptions = props.states.map(state => ({ label: state.name, value: state.jurisdictionId }));
		if (stateOptions[0]) {
			setFormState({
				...formState,
				jurisdictionId: stateOptions[0].value,
				state: '' + stateOptions[0].label
			});
		}
		setStateOptionValues(stateOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.states]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (name === 'jurisdictionId') {
			let selectedState = stateOptionValues.find(state => state.value === value);
			setFormState({ ...formState, jurisdictionId: value, state: selectedState ? selectedState.label : '' });
		} else {
			if (e.target.type === 'checkbox') {
				value = e.target.checked;
			}
			_.set(newState, name, value);

			setFormState(newState);
		}
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'firstName',
			'firstNameError',
			localizationService.getLocalizedString('ipp.accountPortal.firstName')
		);
		validationService.validateRequiredField(
			newState,
			'lastName',
			'lastNameError',
			localizationService.getLocalizedString('ipp.accountPortal.lastName')
		);
		validationService.validateRequiredField(
			newState,
			'buisnessName',
			'buisnessNameError',
			localizationService.getLocalizedString('ipp.accountPortal.organizationName')
		);
		validationService.validateRequiredField(
			newState,
			'phoneNumber',
			'phoneNumberError',
			localizationService.getLocalizedString('ipp.accountPortal.phoneNumber')
		);
		validationService.validateRequiredField(
			newState,
			'addressLine1',
			'addressLine1Error',
			localizationService.getLocalizedString('ipp.accountPortal.addressLine1')
		);
		validationService.validateRequiredField(
			newState,
			'cityName',
			'cityNameError',
			localizationService.getLocalizedString('ipp.accountPortal.cityName')
		);
		validationService.validateRequiredField(
			newState,
			'jurisdictionId',
			'stateError',
			localizationService.getLocalizedString('ipp.accountPortal.state')
		);
		validationService.validateRequiredField(
			newState,
			'zipCode',
			'zipCodeError',
			localizationService.getLocalizedString('ipp.accountPortal.zipCode')
		);
		setFormState(newState);
		const isFromValid = !validationService.hasError(
			newState,
			'firstNameError',
			'lastNameError',
			'phoneNumberError',
			'titleRoleError',
			'addressLine1Error',
			'cityNameError',
			'stateError',
			'zipCodeError',
			'businessNameError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const onSave = async () => {
		if (enableEdit) {
			alertService.clearAllMessages();
			let {
				firstName,
				lastName,
				phoneNumber,
				phoneExt,
				titleRole,
				addressLine1,
				addressLine2,
				cityName,
				zipCode,
				businessName,
				jurisdictionId
			} = formState;

			let userInfo = {
				firstName,
				lastName,
				addressLine1,
				addressLine2,
				cityName,
				zipCode,
				businessName,
				titleRole,
				phoneNumber,
				phoneExt,
				jurisdictionId: jurisdictionId,
				userProfileId: props.profileDetails && props.profileDetails.userProfileId
			};

			if (isFormValidateForSave()) {
				let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile?organizationRegulatoryProgramId=${
					tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
				}`;
				try {
					await apiService.httpPatch(url, userInfo);
					alertService.addSuccess(
						localizationService.getLocalizedString('ipp.accountPortal.messages.profileUpdated')
					);
					setEnableEdit(false);
				} catch (ex) {
					alertService.addError(ex.message);
				}
			}
		} else {
			setEnableEdit(true);
		}
	};

	const toggleChangeEmailModal = () => setChangeEmailModal(!changeEmailModal);

	const toggleChangePasswordModal = () => setChangePasswordModal(!changePasswordModal);

	const emailProps = {
		showModal: changeEmailModal,
		modalTitle: localizationService.getLocalizedString('ipp.accountPortal.changeEmailTitle'),
		userProfileId: Number(props.profileDetails && props.profileDetails.userProfileId),
		oldEmail: formState.email || '',
		onCancel: toggleChangeEmailModal,
		getProfileDetails: props.getProfileDetails
	};

	const passwordProps = {
		showModal: changePasswordModal,
		modalTitle: localizationService.getLocalizedString('ipp.accountPortal.changePasswordTitle'),
		userProfileId: Number(props.profileDetails && props.profileDetails.userProfileId),
		onCancel: toggleChangePasswordModal,
		getProfileDetails: props.getProfileDetails
	};

	return (
		<>
			<div className="form-row">
				<TextInput
					id="firstName"
					name="firstName"
					label={localizationService.getLocalizedString('ipp.accountPortal.firstName')}
					isDisabled={!enableEdit}
					isRequired={true}
					className="col-md-6"
					value={formState.firstName}
					error={formState.firstNameError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>

				<TextInput
					id="lastName"
					name="lastName"
					label={localizationService.getLocalizedString('ipp.accountPortal.lastName')}
					isDisabled={!enableEdit}
					isRequired={true}
					className="col-md-6"
					value={formState.lastName}
					error={formState.lastNameError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="organization-name"
					name="businessName"
					label={localizationService.getLocalizedString('ipp.accountPortal.organizationName')}
					isDisabled={!enableEdit}
					isRequired={true}
					className="col-md-6"
					value={formState.businessName}
					error={formState.businessNameError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.organization }}
				/>

				<TextInput
					id="title-role"
					name="titleRole"
					label={localizationService.getLocalizedString('ipp.accountPortal.title')}
					isDisabled={!enableEdit}
					className="col-md-6"
					value={formState.titleRole}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.title }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="address-line1"
					name="addressLine1"
					label={localizationService.getLocalizedString('ipp.accountPortal.addressLine1')}
					isRequired={true}
					className="col-md-6"
					isDisabled={!enableEdit}
					value={formState.addressLine1}
					error={formState.addressLine1Error}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>

				<TextInput
					id="address-line2"
					name="addressLine2"
					label={localizationService.getLocalizedString('ipp.accountPortal.addressLine2')}
					isDisabled={!enableEdit}
					className="col-md-6"
					value={formState.addressLine2}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="city"
					name="cityName"
					label={localizationService.getLocalizedString('ipp.accountPortal.cityName')}
					isRequired={true}
					className="col-md-6"
					isDisabled={!enableEdit}
					value={formState.cityName}
					error={formState.cityNameError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>

				<SingleSelectDropdown
					id="jurisdictionId"
					name="jurisdictionId"
					label={localizationService.getLocalizedString('ipp.accountPortal.state')}
					value={formState.jurisdictionId ? formState.jurisdictionId + '' : ''}
					isDisabled={!enableEdit}
					className="col-md-6"
					options={stateOptionValues}
					isRequired={true}
					error={formState.stateError}
					onChange={changeFormState}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="zip-code"
					name="zipCode"
					label={localizationService.getLocalizedString('ipp.accountPortal.zipCode')}
					isRequired={true}
					isDisabled={!enableEdit}
					className="col-md-6"
					value={formState.zipCode}
					error={formState.zipCodeError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="phone"
					name="phoneNumber"
					label={localizationService.getLocalizedString('ipp.accountPortal.phoneNumber')}
					isDisabled={!enableEdit}
					isRequired={true}
					className="col-md-6"
					value={formState.phoneNumber}
					error={formState.phoneNumberError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
				<TextInput
					id="phoneExt"
					name="phoneExt"
					label={localizationService.getLocalizedString('ipp.accountPortal.phoneExt')}
					isDisabled={!enableEdit}
					className="col-md-6"
					value={formState.phoneExt}
					onChange={changeFormState}
				/>
			</div>
			<hr />
			<div className="form-row">
				<TextInput
					id="email"
					name="email"
					label={localizationService.getLocalizedString('ipp.accountPortal.email')}
					isRequired={true}
					isDisabled={true}
					className="col-md-6"
					value={formState.email}
					error={formState.emailError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.email }}
				/>
				{props.profileDetails && (
					<WithAqiSsoFeatureFlag
						condition={!_.isEmpty(props.profileDetails.aqiIdpUserId)}
						wait={500}
						childrenWhenFeatureFlagIsOffOrConditionIsFalse={
							<a
								href="#/"
								id="change-email"
								className="d-flex align-items-center mt-1 cursor-pointer"
								onClick={(event: any) => {
									event.preventDefault();
									toggleChangeEmailModal();
								}}>
								{localizationService.getLocalizedString('ipp.accountPortal.change')}
							</a>
						}
					/>
				)}
			</div>
			<div className="form-row">
				<TextInput
					id="userName"
					name="userName"
					label={localizationService.getLocalizedString('ipp.accountPortal.userName')}
					isRequired={true}
					isDisabled={true}
					className="col-md-6"
					value={formState.userName}
					error={formState.userNameError}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.userName }}
				/>
			</div>
			{props.profileDetails && (
				<WithAqiSsoFeatureFlag
					condition={!_.isEmpty(props.profileDetails.aqiIdpUserId)}
					wait={500}
					childrenWhenFeatureFlagIsOffOrConditionIsFalse={
						<>
							<div className="form-row">
								<TextInput
									id="password"
									name="password"
									label={localizationService.getLocalizedString('ipp.accountPortal.password')}
									isRequired={true}
									className="col-md-6"
									isDisabled={true}
									type="password"
									error={formState.passwordError}
									onChange={changeFormState}
									value={formState.password}
								/>
								<a
									href="#/"
									id="change-password"
									className="d-flex align-items-center mt-1 mr-1 cursor-pointer"
									onClick={(event: any) => {
										event.preventDefault();
										toggleChangePasswordModal();
									}}>
									{localizationService.getLocalizedString('ipp.accountPortal.change')}
								</a>
							</div>
							<hr />
						</>
					}
				/>
			)}
			<div className="form-row">
				<TextInput
					id="role"
					name="role"
					label={localizationService.getLocalizedString('ipp.accountPortal.role')}
					isRequired={true}
					isDisabled={true}
					className="col-md-6"
					value={formState.role}
					onChange={changeFormState}
				/>
				<TextInput
					id="isSignatory"
					name="isSignatory"
					label={localizationService.getLocalizedString('ipp.accountPortal.isSignatory')}
					isRequired={true}
					isDisabled={true}
					className="col-md-6"
					value={formState.isSignatory === false ? 'No' : 'Yes'}
					onChange={changeFormState}
				/>
			</div>
			<div className="form-row mt-1">
				<div className="col-md-12 d-flex">
					<button className="btn ai-action ml-auto" onClick={() => onSave()}>
						{!enableEdit && localizationService.getLocalizedString('ipp.buttons.edit')}
						{enableEdit && localizationService.getLocalizedString('ipp.buttons.save')}
					</button>
				</div>
			</div>
			<ChangeEmailModal {...emailProps} />
			<ChangePasswordModal {...passwordProps} />
		</>
	);
};

export default ProfileDetailsComponent;
