import React, { useState } from 'react';
import { PopoverModal, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { ApiEndpoints, ApiError, CustomFieldDefinition, DropDownOption, ImportUniqueKey } from '@rcp/types';
import { alertService } from '../../../../../redux/alert';
import './import-unique-key-editor.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Button } from 'reactstrap';
import { RestApi } from 'src/services/rest.api';

export enum EditorMode {
	Add,
	Update
}

const MAX_ALLOWED_FIELDS = 6;

interface Props {
	description: string;
	mode: EditorMode;
	show: boolean;
	table: string;
	onClosed?: () => void;
	fields: CustomFieldDefinition[];
	importUniqueKey?: ImportUniqueKey | null;
}

interface UniqueKeyEditorState {
	show: boolean;
	importUniqueKeyName: string;
	importUniqueKeyNameError: string;
	selectedFieldsError: string;
	selectedFields: CustomFieldDefinition[];
	notSelectedFields: CustomFieldDefinition[];
	fieldSelectOptions: DropDownOption[];
	selectFieldName: string | undefined;
	deleteField: CustomFieldDefinition | null;
	deleteModal: boolean;
}

const ImportUniqueKeyEditor: React.FunctionComponent<Props> = props => {
	const getFieldSelectOptions = (fields: CustomFieldDefinition[]): DropDownOption[] => {
		// @ts-ignore
		const options: DropDownOption[] = fields.map(s => {
			return { label: s.fieldLabel, value: s.fieldName };
		});
		return options;
	};

	const restApi = new RestApi<ImportUniqueKey>(ApiEndpoints.ImportUniqueKey);

	const getNotSelectedFields = (selectedFields: CustomFieldDefinition[]) => {
		const notSelectedFields = props.fields.filter(f => {
			return selectedFields.filter(s => String.equalCaseInsensitive(s.fieldName, f.fieldName)).length === 0;
		});

		return notSelectedFields;
	};

	let selectedCustomFields: CustomFieldDefinition[] = [];
	let notSelectedFields = props.fields;
	let importUniqueKeyName = '';
	let selectedField = props.fields && props.fields.length > 0 ? props.fields[0] : null;

	if (props.mode === EditorMode.Update) {
		if (props.importUniqueKey) {
			const fields = props.importUniqueKey.fieldNames.split(',');
			selectedCustomFields = props.fields.filter(f => {
				return fields.filter(ff => ff === f.fieldName).length > 0;
			});

			importUniqueKeyName = props.importUniqueKey.uniqueKeyName;
			notSelectedFields = getNotSelectedFields(selectedCustomFields);
			if (notSelectedFields.length > 0) {
				selectedField = notSelectedFields[0];
			}
		}
	}

	let initialState: UniqueKeyEditorState = {
		show: props.show,
		selectedFields: selectedCustomFields,
		importUniqueKeyName: importUniqueKeyName,
		notSelectedFields: notSelectedFields,
		fieldSelectOptions: getFieldSelectOptions(notSelectedFields),
		importUniqueKeyNameError: '',
		selectedFieldsError: '',
		selectFieldName: selectedField && selectedField.fieldName ? selectedField.fieldName : '',
		deleteField: null,
		deleteModal: false
	};

	const [state, setState] = useState(initialState);

	const title = localizationService.getLocalizedString('authoritySetting.importUniqueKey.ModalTitle');

	const onError = (reason: any) => {
		const errorText = localizationService.getLocalizedString(
			'authoritySetting.importUniqueKey.DuplicateName',
			state.importUniqueKeyName
		);
		if (reason instanceof ApiError) {
			if (
				reason.body &&
				reason.body.internalMessage &&
				reason.body.internalMessage.toLowerCase().indexOf('violation of unique key') >= 0
			) {
				setState({
					...state,
					importUniqueKeyNameError: errorText
				});
			}

			alertService.addError(errorText);
		} else {
			alertService.addError(reason.toString());
		}
	};

	const onSaveClicked = async () => {
		if (!state.importUniqueKeyName) {
			const errorText = localizationService.getLocalizedString('authoritySetting.importUniqueKey.NameRequired');
			alertService.addError(errorText);
			setState({ ...state, importUniqueKeyNameError: errorText });
			return;
		}

		if (
			!state.selectedFields ||
			state.selectedFields.length === 0 ||
			state.selectedFields.length > MAX_ALLOWED_FIELDS
		) {
			let errorText = localizationService.getLocalizedString(
				'authoritySetting.importUniqueKey.MaximumFieldError',
				MAX_ALLOWED_FIELDS.toString()
			);
			if (state.selectedFields.length === 0) {
				errorText = localizationService.getLocalizedString(
					'authoritySetting.importUniqueKey.CustomFieldRequired'
				);
			}
			alertService.addError(errorText);
			setState({ ...state, selectedFieldsError: errorText });
			return;
		}

		if (props.mode === EditorMode.Update && props.importUniqueKey && props.importUniqueKey.importUniqueKeyId) {
			const importUniqueKey = props.importUniqueKey;
			if (importUniqueKey) {
				restApi
					.patchOne(importUniqueKey.importUniqueKeyId, {
						uniqueKeyName: state.importUniqueKeyName,
						FieldNames: state.selectedFields.map(f => f.fieldName).join(',')
					})
					.then(value => {
						alertService.addSuccess(
							localizationService.getLocalizedString(
								'authoritySetting.importUniqueKey.UpdateSucceed',
								state.importUniqueKeyName
							)
						);
						onClose();
					}, onError);
			}
		} else if (props.mode === EditorMode.Add) {
			restApi
				.createOne({
					uniqueKeyName: state.importUniqueKeyName,
					FieldNames: state.selectedFields.map(f => f.fieldName).join(','),
					entity: props.table
				})
				.then(value => {
					alertService.addSuccess(
						localizationService.getLocalizedString('authoritySetting.importUniqueKey.AddSucceed')
					);
					onClose();
				}, onError);
		}
	};

	const onCancelClicked = () => {
		onClose();
	};

	const onClose = () => {
		setState({ ...state, show: false });
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const footer = () => {
		return (
			<div className="ml-auto">
				<button id="btnSave" className="btn ai-save ml-2" onClick={onSaveClicked}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</button>
				<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		);
	};

	const onFieldNameChanged = (e: any) => {
		let { value } = e.target;
		if (value) {
			value = value.replace(/[^a-zA-Z0-9]/gi, '');
		}

		setState({ ...state, importUniqueKeyName: value, importUniqueKeyNameError: '' });
	};

	const onDeleteConfirmed = () => {
		if (state.deleteField) {
			// @ts-ignore
			const selectedFields = state.selectedFields.filter(f => f.fieldName !== state.deleteField.fieldName);

			let noSelectedFields = getNotSelectedFields(selectedFields);
			let selectFieldName = noSelectedFields && noSelectedFields.length > 0 ? noSelectedFields[0].fieldName : '';

			// @ts-ignore
			setState({
				...state,
				selectedFields,
				fieldSelectOptions: getFieldSelectOptions(noSelectedFields),
				notSelectedFields: noSelectedFields,
				selectFieldName: selectFieldName,
				deleteModal: false,
				deleteField: null
			});
		}
	};

	const onDeleteClicked = (field: CustomFieldDefinition) => {
		setState({ ...state, deleteModal: true, deleteField: field });
	};

	const getTitleRow = () => {
		return (
			<thead>
				<tr className="lookup-row lookup-header-row font-size-14px-semibold">
					<th>{localizationService.getLocalizedString('authoritySetting.importUniqueKey.CustomField')}</th>
					<th className="action-2-buttons"></th>
				</tr>
			</thead>
		);
	};
	const getRows = () => {
		return (
			<tbody>
				{state.selectedFields.map((field, index) => {
					return (
						<tr className="lookup-row" key={`option-key-${index}`}>
							<td id={`option_value_${index}`}>{field.fieldLabel}</td>

							<td>
								<button onClick={() => onDeleteClicked(field)}>
									<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
								</button>
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};

	const showDeleteModel = (show: boolean) => {
		setState({ ...state, deleteModal: show });
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.importUniqueKey.DeleteCustomField')}
				showModal={state.deleteModal}
				cancel={() => showDeleteModel(false)}
				saveButtonClassName="ai-delete ml-auto"
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				save={onDeleteConfirmed}>
				{localizationService.getLocalizedString(
					'authoritySetting.importUniqueKey.DeleteCustomFieldDesc',
					state.deleteField && state.deleteField.fieldLabel ? state.deleteField.fieldLabel : ''
				)}
			</PopoverModal>
		);
	};

	const getCustomFieldListView = () => {
		return (
			<>
				<div className="table-responsive">
					<table className="table">
						{getTitleRow()}
						{getRows()}
					</table>
				</div>
			</>
		);
	};

	const getFieldByName = (name: string) => {
		return props.fields.find(f => f.fieldName === name);
	};

	const onFieldSelected = (evt: any) => {
		let { value } = evt.target;
		// @ts-ignore
		setState({ ...state, selectFieldName: value });
	};

	const hasField = (fields: CustomFieldDefinition[], field: CustomFieldDefinition) => {
		return fields.filter(f => f.fieldName === field.fieldName).length > 0;
	};

	const onAddCustomFieldClicked = (evt: any) => {
		const selectedFields = [...state.selectedFields];
		if (state.selectFieldName) {
			const field = getFieldByName(state.selectFieldName);

			if (field && !hasField(selectedFields, field)) {
				selectedFields.push(field);
			}

			if (selectedFields.length > MAX_ALLOWED_FIELDS) {
				alertService.addError(
					localizationService.getLocalizedString(
						'authoritySetting.importUniqueKey.MaximumFieldError',
						MAX_ALLOWED_FIELDS.toString()
					)
				);
				return;
			}

			let noSelectedFields = getNotSelectedFields(selectedFields);
			let selectFieldName = noSelectedFields && noSelectedFields.length > 0 ? noSelectedFields[0].fieldName : '';

			// @ts-ignore
			setState({
				...state,
				selectedFields,
				fieldSelectOptions: getFieldSelectOptions(noSelectedFields),
				notSelectedFields: noSelectedFields,
				selectFieldName: selectFieldName
			});
		}
	};

	return (
		<>
			<PopoverModal
				showModal={state.show || props.show}
				title={title}
				footer={footer()}
				save={onSaveClicked}
				cancel={onCancelClicked}>
				<p> {props.description}</p>

				<TextInput
					id="uniqueKeyName"
					name="uniqueKeyName"
					value={state.importUniqueKeyName}
					onChange={onFieldNameChanged}
					isRequired={true}
					error={state.importUniqueKeyNameError}
					showErrorAndHelp={true}
					label={localizationService.getLocalizedString('authoritySetting.importUniqueKey.Name')}
					helpText={localizationService.getLocalizedString('authoritySetting.importUniqueKey.NameHelp')}
				/>

				<div className="form-group d-flex">
					<SingleSelectDropdown
						className="flex-grow-1"
						id="customField"
						name="customField"
						noEmptyOption={true}
						selfOrder={true}
						value={state.selectFieldName}
						label={localizationService.getLocalizedString(
							'authoritySetting.importUniqueKey.AddCustomField'
						)}
						onChange={onFieldSelected}
						options={state.fieldSelectOptions}
					/>
					<div className="ml-2 pt-4">
						<Button className="ai-new" id="addBtn" onClick={onAddCustomFieldClicked}>
							{localizationService.getLocalizedString('authoritySetting.addBtnText')}
						</Button>
					</div>
				</div>
				{state.selectedFields && state.selectedFields.length > 0 && getCustomFieldListView()}
			</PopoverModal>

			{state.deleteModal && getDeleteModal()}
		</>
	);
};

export default ImportUniqueKeyEditor;
