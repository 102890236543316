import {
	ColumnField,
	FilterType,
	LocalStorageName,
	IppReportPackage,
	Dictionary,
	Token,
	IppIndustry
} from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import {
	apiService,
	DateUtilService,
	localizationService,
	navigateTo,
	Resource,
	tokenService,
	urlService
} from 'src/services';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { RootState } from 'src/redux';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { ippScheduledReportsSlice } from './scheduled-package-slice';
import _ from 'lodash';
import { ippReviewsSlice } from 'src/components/authority/ipp/report-packages/packages/reviews/reviews.slice';
import { useDispatch } from 'react-redux';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { nameof } from 'ts-simple-nameof';
interface Props {
	history: any;
	match: any;
	subHeading?: string;
	informativeText?: string;
}

const { initialPage } = IppConstants.queryParams;
const { Scheduled, Submitted, Accepted } = ReportPackageStatus;

const IppScheduledReportPackage: FC<Props> = (props: Props) => {
	const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);
	const [dataLoaded, setDataLoaded] = useState<boolean>(false);

	const initialQueryParams: Dictionary<string> = {
		industryId: props.match.params.industryId,
		page: initialPage,
		sort: 'dueDateTimeLocal asc'
	};
	const dispatch = useDispatch();
	const [queryParams] = useState<Dictionary<string>>(initialQueryParams);

	const checkAccessDenied = async () => {
		let token: Token = tokenService.getTokenOrDefault();
		let url = urlService.getAuthorityResourceApiUrl(
			Resource.IppIndustries,
			Number(token.portalOrganizationRegulatoryProgramId)
		);
		try {
			const industryDetail = await apiService.getResource<IppIndustry>(url);
			setDataLoaded(true);
			setIsAccessDenied(!!!industryDetail.showScheduledReport);
		} catch (err) {}
	};

	useEffect(() => {
		if (urlService.isIppIndustryPortal()) {
			checkAccessDenied();
		} else {
			setDataLoaded(true);
		}
	}, []);

	const warningIfNearDueDate = (props: any) => {
		const scheduledReports: IppReportPackage = props.dataItem;
		return (
			<td>
				{DateUtilService.toDisplayDate(scheduledReports.dueDateTimeLocal)}
				{scheduledReports.isNearDueDate && (
					<TooltipHover
						icon={faExclamationTriangle}
						id={`isNearDueDate-${scheduledReports.reportPackageId}`}
						className="d-inline-block ml-2"
						title={localizationService.getLocalizedString(
							'ipp.reportPackage.package.scheduledPackage.dueDateWarning'
						)}
						iconFontClass="ai-required"
					/>
				)}
			</td>
		);
	};

	const reportPackageStatus = (props: any) => {
		let reportPackageStatus = _.startCase(_.camelCase(props.dataItem.scheduledReportStatus));
		reportPackageStatus === Submitted && (reportPackageStatus = Accepted);
		return <td>{reportPackageStatus}</td>;
	};

	const allColumns = [
		new ColumnField(
			nameof<IppReportPackage>(x => x.reportPackageId),
			FilterType.Number
		),
		new ColumnField(nameof<IppReportPackage>(x => x.reportPackageName)),
		new ColumnField(nameof<IppReportPackage>(x => x.description)),
		new ColumnField(
			nameof<IppReportPackage>(x => x.periodStartDateTimeLocal),
			FilterType.Date
		),
		new ColumnField(
			nameof<IppReportPackage>(x => x.periodEndDateTimeLocal),
			FilterType.Date
		),
		new ColumnField(
			nameof<IppReportPackage>(x => x.dueDateTimeLocal),
			FilterType.Date,
			warningIfNearDueDate
		),
		new ColumnField(
			nameof<IppReportPackage>(x => x.scheduledReportStatus),
			FilterType.Text,
			reportPackageStatus
		),
		new ColumnField(
			nameof<IppReportPackage>(x => x.ctsEventId),
			FilterType.Number
		)
	];

	const defaultColumns = [
		nameof<IppReportPackage>(x => x.reportPackageName),
		nameof<IppReportPackage>(x => x.periodStartDateTimeLocal),
		nameof<IppReportPackage>(x => x.periodEndDateTimeLocal),
		nameof<IppReportPackage>(x => x.dueDateTimeLocal),
		nameof<IppReportPackage>(x => x.scheduledReportStatus)
	];

	const createOrViewScheduledReport = (e: any, url: string, reportPackage: IppReportPackage) => {
		e.preventDefault();
		if (url) {
			navigateTo(props.history, url);
		} else {
			let payload = _.pick(reportPackage, [
				'reportPackageTemplateId',
				'periodStartDateTimeLocal',
				'periodEndDateTimeLocal',
				'dueDateTimeUtc',
				'ctsEventId'
			]);
			dispatch(
				ippReviewsSlice.createOne(
					payload,
					false,
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.scheduledPackage.draftCreatedSuccessfully'
					),
					(data: any) => {
						let draftUrl = urlService.getIppIndustryUrl(`reportPackage`, data.items[0].reportPackageId);
						navigateTo(props.history, draftUrl);
					}
				)
			);
		}
	};

	const statusActionLink = (item: any) => {
		const dataItem: IppReportPackage = item.dataItem as IppReportPackage;
		const reportPackageDetailsUrl = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		const view = localizationService.getLocalizedString(
			'ipp.reportPackage.package.scheduledPackage.statusActions.view'
		);
		const createDraft = localizationService.getLocalizedString(
			'ipp.reportPackage.package.scheduledPackage.statusActions.createDraft'
		);
		const displayActionName = dataItem.scheduledReportStatus === Scheduled ? createDraft : view;
		const url = displayActionName === view ? reportPackageDetailsUrl : '';
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => createOrViewScheduledReport(e, url, dataItem)}>
					{displayActionName}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showTabList: false,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageScheduledGrid,
		searchPlaceholder: '',
		showActions: false,
		pageTitle:
			props.subHeading || ''
				? ''
				: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Scheduled'),
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: queryParams,
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		subHeading: props.subHeading || '',
		informativeText: props.informativeText || '',
		allColumns: props.subHeading
			? [...allColumns]
			: [...allColumns, new ColumnField('statusAction', FilterType.Text, statusActionLink)],
		defaultColumns: props.subHeading ? [...defaultColumns] : [...defaultColumns, 'statusAction']
	};

	const scheduleReportsState = (state: RootState) => state.ippScheduledReports;

	return dataLoaded ? (
		isAccessDenied ? (
			<AccessDeniedPage />
		) : (
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippScheduledReportsSlice}
				restState={scheduleReportsState}
				gridOption={initialGridOption}></DataGrid>
		)
	) : (
		<></>
	);
};

export default IppScheduledReportPackage;
