import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { apiService, DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { alertService } from 'src/redux';
import { ImportSampleSteps, IppIndustrySample, IppSampleResult, ValidationError } from '@rcp/types';
import { History } from 'history';
import { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import IppImportSampleSummary from '../import-summary';
import ImportSampleDetailComponent from '../sample-details';
import IppPackageItemSamples from 'src/components/authority/ipp/report-packages/packages/package-items/samples-and-results';

interface Props {
	vertical?: boolean;
	history: History;
	setHideStepperHeader?: any;
}

const Step5: React.FunctionComponent<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const step4Data: any = stepperContext.getData(ImportSampleSteps.STEP4);
	const step2Data: any = stepperContext.getData(ImportSampleSteps.STEP2);
	const [samples, setSamples] = useState([] as IppIndustrySample[]);
	const [newSamples, setNewSamples] = useState(0);
	const [updatedAndNewsampleList, setUpdatedAndNewSampleList] = useState([] as IppIndustrySample[]);
	const [newSampleResults, setNewSampleResults] = useState(0);
	const [updatedSampleResults, setUpdatedSampleResults] = useState(0);
	const [importSuccess, setImportSuccess] = useState(false);

	useEffect(() => {
		let samples = step4Data.samples;
		if (samples) {
			setSamples(samples);
			let newSamples = samples.filter((sample: IppIndustrySample) => sample.sampleId === 0).length || 0;
			setNewSamples(newSamples);
			let totalSampleResults = 0;
			let sampleResults: IppSampleResult[] = [];
			let sampleResultsWithMassLoading: IppSampleResult[] = [];
			let newSampleResultsWithMassLoading: IppSampleResult[] = [];
			let updatedSampleResultsWithMassLoading: IppSampleResult[] = [];
			let updatedAndNewsamples: IppIndustrySample[] = [];

			samples.map((sample: IppIndustrySample) => {
				totalSampleResults += sample.ippSampleResults ? sample.ippSampleResults.length : 0;
				(sample.ippSampleResults || []).map((result: IppSampleResult) => {
					return getResultsWithMassLoading(result, sampleResultsWithMassLoading);
				});

				let sampleResult = sample.ippSampleResults || [];

				sampleResult = sampleResult.filter((result: IppSampleResult) => {
					return result.concentrationSampleResultId === 0 || !result.existingUnchanged;
				});

				updatedAndNewsamples.push({ ...samples, ippSampleResults: sampleResult });

				sampleResults.push(...(sample.ippSampleResults || []));
				return sample;
			});
			setUpdatedAndNewSampleList(updatedAndNewsamples);
			let newSampleResults = sampleResults.filter((result: IppSampleResult) => {
				getResultsWithMassLoading(result, newSampleResultsWithMassLoading);

				return result.concentrationSampleResultId === 0;
			});
			newSampleResultsWithMassLoading = newSampleResultsWithMassLoading.filter(newResult => {
				return newResult.concentrationSampleResultId === 0;
			});
			let updatedSampleResults = sampleResults.filter((result: IppSampleResult) => {
				getResultsWithMassLoading(result, updatedSampleResultsWithMassLoading);
				return result.concentrationSampleResultId !== 0 && !result.existingUnchanged;
			});
			updatedSampleResultsWithMassLoading = updatedSampleResultsWithMassLoading.filter(newResult => {
				return newResult.concentrationSampleResultId !== 0 && !newResult.existingUnchanged;
			});
			setUpdatedSampleResults(updatedSampleResults.length + updatedSampleResultsWithMassLoading.length);
			setNewSampleResults(newSampleResults.length + newSampleResultsWithMassLoading.length);
		}
		// On Component Unmount
		return () => props.setHideStepperHeader(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getResultsWithMassLoading = (result: IppSampleResult, sampleResultsWithMassLoading: IppSampleResult[]) => {
		if (result.massLoadingValue) {
			let sampleResultsWithMassLoadingCopy = sampleResultsWithMassLoading.filter(
				(sampleResult: IppSampleResult) => {
					return sampleResult.parameterId === result.parameterId;
				}
			);
			!sampleResultsWithMassLoadingCopy.length && sampleResultsWithMassLoading.push(result);
		}
	};

	const back = () => stepperContext.goAt(ImportSampleSteps.STEP4);
	const onImport = async () => {
		alertService.clearAllMessages();

		let newSamples = samples.map(sample => {
			sample.startDateTimeLocal = sample.startDateTimeLocal || '';
			sample.endDateTimeLocal = sample.endDateTimeLocal || '';
			let ippSampleResults =
				sample.ippSampleResults &&
				sample.ippSampleResults.map(result => {
					result.analysisDateTimeLocal =
						result.analysisDateTimeLocal && DateUtilService.isValidDateTime(result.analysisDateTimeLocal)
							? result.analysisDateTimeLocal
							: undefined;
					return result;
				});
			sample.ippSampleResults = ippSampleResults;
			return sample;
		});

		const url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppSampleImport}/SaveSample`);
		let postData = {
			items: [
				{
					importTempFileId: step2Data.importTempFileId,
					samples: newSamples
				}
			]
		};
		try {
			let samplesValidation = await apiService.httpPost(url, postData);
			alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.importSamples.importSuccess'));
			stepperContext.resolve({ samples: samplesValidation.samples });
			setImportSuccess(true);
			props.setHideStepperHeader(true);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const getOverallCompliance = (samples: IppIndustrySample[]) => {
		let sampleResults: IppSampleResult[] = [];
		samples.map((sample: IppIndustrySample) => {
			sampleResults.push(...(sample.ippSampleResults || []));
			return sample;
		});
		let inCompliantData =
			sampleResults &&
			sampleResults.filter(result => {
				return result.concentrationResultCompliance === 'Bad' || result.massResultCompliance === 'Bad';
			});

		return {
			compliance: Boolean(!inCompliantData.length),
			count: inCompliantData.length
		};
	};

	const sampleMessage = (length: number, type: string) => {
		if (importSuccess) {
			return localizationService.getLocalizedString(
				`ipp.samples.importSamples.${
					length === 1
						? type === 'draftSample'
							? 'singleDraftSampleImportedMessage'
							: 'singleSampleResultsImportedMessage'
						: type === 'draftSample'
						? 'multipleDraftSampleImportedMessage'
						: 'multipleSampleResultsImportedMessage'
				}`
			);
		} else {
			return localizationService.getLocalizedString(
				`ipp.samples.importSamples.${
					length === 1
						? type === 'draftSample'
							? 'singleDraftImportMessage'
							: 'singleSampleResultsMessage'
						: type === 'draftSample'
						? 'multipleDraftImportMessage'
						: 'multipleSampleResultsMessage'
				}`
			);
		}
	};

	return (
		<StepperContent
			id="validateSampleAndSave"
			actions={
				<React.Fragment>
					{step4Data.errors ? (
						<>
							<StepperAction type="button" className="btn ai-white ml-2 mr-2" id="btnBack" onClick={back}>
								{localizationService.getLocalizedString('screen.buttons.back')}
							</StepperAction>
							<StepperAction
								type="button"
								id="btnImportAnotherFile"
								className="btn ai-action"
								onClick={() => {
									let step1State = {
										...stepperContext.getStep(ImportSampleSteps.STEP1),
										completed: false,
										data: null
									};
									stepperContext.updateStep(ImportSampleSteps.STEP1, step1State);
									stepperContext.goAt(ImportSampleSteps.STEP1);
								}}>
								{localizationService.getLocalizedString('ipp.samples.importSamples.importAnotherFile')}
							</StepperAction>
						</>
					) : !importSuccess ? (
						<>
							<StepperAction type="button" className="btn ai-white ml-2 mr-2" id="btnBack" onClick={back}>
								{localizationService.getLocalizedString('screen.buttons.back')}
							</StepperAction>
							<StepperAction
								type="button"
								id="btnNext"
								className="btn ai-action ml-auto mr-0"
								onClick={onImport}>
								{localizationService.getLocalizedString('ipp.samples.importSamples.import')}
							</StepperAction>
						</>
					) : (
						<>
							<StepperAction
								type="button"
								className="btn ai-action ml-auto mr-2"
								id="btnViewDraft"
								onClick={() => {
									navigateTo(
										props.history,
										urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/draft`)
									);
								}}>
								{localizationService.getLocalizedString('ipp.samples.importSamples.viewDraftSamples')}
							</StepperAction>
							<StepperAction
								type="button"
								id="btnImportAnotherFile"
								className="btn ai-action"
								onClick={() => {
									let step1State = {
										...stepperContext.getStep(ImportSampleSteps.STEP1),
										completed: false,
										data: null
									};
									stepperContext.updateStep(ImportSampleSteps.STEP1, step1State);
									stepperContext.goAt(ImportSampleSteps.STEP1);
								}}>
								{localizationService.getLocalizedString('ipp.samples.importSamples.importAnotherFile')}
							</StepperAction>
						</>
					)}
				</React.Fragment>
			}>
			{step4Data.errors ? (
				<div id="step2-erros" className="mt-3">
					<table className="table table-bordered table-condensed ">
						<thead>
							<tr>
								<th scope="col-md-6">
									{localizationService.getLocalizedString('ipp.samples.importSamples.error')}
								</th>
								<th scope="col-md-6">
									{localizationService.getLocalizedString('ipp.samples.importSamples.rowNumbers')}
								</th>
							</tr>
						</thead>
						<tbody>
							{step4Data.errors.map((error: ValidationError, index: number) => {
								return (
									<tr key={index}>
										<td>{error.errorMessage}</td>
										<td>{error.rowNumbers}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			) : (
				<>
					{importSuccess && (
						<>
							<span className="font-size-20px-regular">
								{localizationService.getLocalizedString(
									'ipp.samples.importSamples.dataImportedSubHeading'
								)}
							</span>
							<hr />
						</>
					)}
					{importSuccess && (
						<span className="font-size-20px-regular px-4">
							{localizationService.getLocalizedString('ipp.samples.importSamples.importSummary')}
						</span>
					)}
					<table className={`${importSuccess ? 'sample-validation-table mt-2' : ''}`}>
						<tbody>
							<tr id="validation-message-sample">
								<td>{`${step4Data.samples.length || 0} ${sampleMessage(
									step4Data.samples.length,
									'draftSample'
								)}`}</td>
								<td className="text-align-left">
									{newSamples !== 0 && (
										<FontAwesomeIcon
											className="font-awesome-icon ml-2 mt-1 text-primary mr-2"
											icon={faCheck}
										/>
									)}
									{`${
										newSamples !== 0
											? newSamples +
											  ' ' +
											  localizationService.getLocalizedString('ipp.samples.importSamples.new')
											: ''
									} `}
									{samples.length - newSamples > 0 && (
										<FontAwesomeIcon
											className="font-awesome-icon ml-2 mt-1 text-warning mr-2"
											icon={faCheck}
										/>
									)}
									{`${
										samples.length - newSamples > 0
											? samples.length -
											  newSamples +
											  ' ' +
											  `${
													samples.length - newSamples > 1
														? localizationService.getLocalizedString(
																'ipp.samples.importSamples.updates'
														  )
														: localizationService.getLocalizedString(
																'ipp.samples.importSamples.update'
														  )
											  }`
											: ''
									}`}
								</td>
							</tr>
							<tr id="validation-message-sample-results">
								<td>{`${updatedSampleResults + newSampleResults || 0} ${sampleMessage(
									updatedSampleResults + newSampleResults,
									'sampleType'
								)}  `}</td>
								<td className="text-align-left">
									{newSampleResults !== 0 && (
										<FontAwesomeIcon
											className="font-awesome-icon ml-2 mt-1 text-primary mr-2"
											icon={faCheck}
										/>
									)}
									{`${
										newSampleResults !== 0
											? newSampleResults +
											  ' ' +
											  localizationService.getLocalizedString('ipp.samples.importSamples.new')
											: ''
									}`}
									{updatedSampleResults > 0 && (
										<FontAwesomeIcon
											className="font-awesome-icon ml-2 mt-1 text-warning mr-2"
											icon={faCheck}
										/>
									)}
									{`${
										updatedSampleResults > 0
											? updatedSampleResults +
											  ' ' +
											  `${
													updatedSampleResults > 1
														? localizationService.getLocalizedString(
																'ipp.samples.importSamples.updates'
														  )
														: localizationService.getLocalizedString(
																'ipp.samples.importSamples.update'
														  )
											  }`
											: ''
									}`}
								</td>
							</tr>
						</tbody>
					</table>

					{!importSuccess && (
						<div id="compliance-message" className="form-row mb-3 mt-3 ml-1">
							{getOverallCompliance(samples).compliance ? (
								<>
									{localizationService.getLocalizedString(
										'ipp.samples.importSamples.allComplianceMessage'
									)}
									<FontAwesomeIcon
										className="green-icon font-awesome-icon ml-2 mt-1"
										icon={faCheckCircle}
									/>
								</>
							) : (
								<>
									{getOverallCompliance(samples).count}
									{getOverallCompliance(samples).count === 1 && (
										<span className="ml-1">
											{localizationService.getLocalizedString(
												'ipp.samples.importSamples.notInComplianceOne'
											)}
										</span>
									)}
									{getOverallCompliance(samples).count > 1 &&
										localizationService.getLocalizedString(
											'ipp.samples.importSamples.notInComplianceMany'
										)}
									<FontAwesomeIcon className="red-icon font-awesome-icon ml-2" icon={faTimesCircle} />
								</>
							)}
						</div>
					)}
					{!importSuccess && (
						<div className="form-row">
							<IppPackageItemSamples
								sampleTypes={samples}
								isImportSample={true}
								customDetailComponent={ImportSampleDetailComponent}
							/>
						</div>
					)}
					<IppImportSampleSummary
						importSuccess={importSuccess}
						sampleTypes={updatedAndNewsampleList}
						className="mt-3 p-4"
					/>
				</>
			)}
		</StepperContent>
	);
};

export default Step5;
