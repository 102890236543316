import {
	ApiError,
	AuthorityOrpSetting,
	DropDownOption,
	SettingType,
	TestFrequencySettingFields,
	TestFrequencySettingTypes
} from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { SingleSelectDropdown } from 'src/components/widgets';
import { alertService, loadAuthoritySettings, loadFrequencyList, RootState } from 'src/redux';
import { apiService, localizationService, optionsMap, urlService, UtilService } from 'src/services';

let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;

const TestSettingsComponent: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	const [testFrequencies, setTestFrequencies] = React.useState<DropDownOption[]>([]);
	const [authoritySetting, setAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const [originalAuthoritySetting, setOriginalAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const reduxAuthoritySettings = useSelector((state: RootState) => state.authoritySettingLookups.authoritySettings);

	let cccTestFrequencies = useSelector((state: RootState) => state.extractors.frequencyList);

	React.useEffect(() => {
		dispatch(loadFrequencyList());
		dispatch(loadAuthoritySettings());
	}, [dispatch]);

	React.useEffect(() => {
		if (!_.isEmpty(cccTestFrequencies)) {
			setTestFrequencies(optionsMap.fromFrequencyList(cccTestFrequencies));
		}
	}, [cccTestFrequencies]);

	React.useEffect(() => {
		if (!_.isEmpty(reduxAuthoritySettings)) {
			let newAuthoritySetting = { ...authoritySetting };
			for (let settingValue of reduxAuthoritySettings) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				newAuthoritySetting[key] = settingValue.value;
			}
			if (
				(!newAuthoritySetting.testScheduleFrequencyId || newAuthoritySetting.testScheduleFrequencyId === 0) &&
				cccTestFrequencies.length > 0
			) {
				newAuthoritySetting.testScheduleFrequencyId = cccTestFrequencies[0].frequencyId;
				setDefaultFrequency(cccTestFrequencies[0].frequencyId as number);
			}
			setAuthoritySetting(newAuthoritySetting);
			setOriginalAuthoritySetting(newAuthoritySetting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reduxAuthoritySettings, cccTestFrequencies]);

	const setDefaultFrequency = (frequencyId: number) => {
		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		let dataToUpdate = {
			settingType: SettingType.TestScheduleFrequencyId,
			value: frequencyId
		};
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
			});
	};

	const updateAuthoritySettingValue = (e: any) => {
		const { name, value } = e.target;

		let dataToUpdate = {
			settingType: name,
			value: value
		};
		if (originalAuthoritySetting[name] === value) {
			return;
		}

		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);

		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				//_.unset(settingError, `${name}Error`);
				let updateAuthoritySetting = { ...originalAuthoritySetting };
				updateAuthoritySetting[name] = value;
				setOriginalAuthoritySetting(updateAuthoritySetting);
				setAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};

	return (
		<>
			<div className="page">
				{urlService.isAdministrator() ? (
					<>
						<div className="page-header">
							<h1>{localizationService.getLocalizedString('cccTest.tests')}</h1>
						</div>
						<div className="page-wrapper">
							<div className="main settings">
								<div className="row">
									<div className="col-lg-4 settings-info">
										<strong>
											{localizationService.getLocalizedString(
												'authoritySetting.test.defaultTestFrequency'
											)}
										</strong>
										<p>
											{localizationService.getLocalizedString(
												'authoritySetting.test.testFrequencyDescription'
											)}
										</p>
									</div>
									<div className="col-lg-8">
										<section>
											<div className="custom-control custom-radio">
												<input
													type="radio"
													className="custom-control-input"
													name="testScheduleFrequencyOption"
													checked={String.equalCaseInsensitive(
														authoritySetting.testScheduleFrequencyOption,
														TestFrequencySettingTypes.None
													)}
													id="none"
													onChange={updateAuthoritySettingValue}
													value={TestFrequencySettingTypes.None}
												/>
												<label className="custom-control-label" htmlFor="none">
													<span>
														{localizationService.getLocalizedString(
															'authoritySetting.test.doNotScheduleNext'
														)}
														&nbsp;
														<strong>
															{localizationService
																.getLocalizedString('cccTest.test')
																.toLowerCase()}
														</strong>
													</span>
												</label>
											</div>
											<div className="custom-control custom-radio">
												<input
													type="radio"
													className="custom-control-input"
													name="testScheduleFrequencyOption"
													checked={String.equalCaseInsensitive(
														authoritySetting.testScheduleFrequencyOption,
														TestFrequencySettingTypes.Hazard
													)}
													id="hazard"
													onChange={updateAuthoritySettingValue}
													value={TestFrequencySettingTypes.Hazard}
												/>
												<label className="custom-control-label" htmlFor="hazard">
													<span>
														{localizationService.getLocalizedString(
															'authoritySetting.test.useFrequencyFrom'
														)}
														&nbsp;
														<strong>
															{localizationService
																.getLocalizedString('cccHazard.hazard')
																.toLowerCase()}
														</strong>
													</span>
												</label>
											</div>
											<div className="custom-control custom-radio">
												<input
													type="radio"
													className="custom-control-input"
													name="testScheduleFrequencyOption"
													checked={String.equalCaseInsensitive(
														authoritySetting.testScheduleFrequencyOption,
														TestFrequencySettingTypes.Selected
													)}
													id="selected"
													onChange={updateAuthoritySettingValue}
													value={TestFrequencySettingTypes.Selected}
												/>
												<label className="custom-control-label" htmlFor="selected">
													<span>
														{localizationService.getLocalizedString(
															'authoritySetting.test.setFrequency'
														)}
													</span>
												</label>
											</div>
											{String.equalCaseInsensitive(
												authoritySetting.testScheduleFrequencyOption,
												TestFrequencySettingTypes.Selected
											) &&
												testFrequencies.length > 0 && (
													<SingleSelectDropdown
														id="testScheduleFrequencyId"
														name="testScheduleFrequencyId"
														className="pl-5"
														isRequired={true}
														label={localizationService.getLocalizedString(
															'authoritySetting.frequency.frequency'
														)}
														value={_.toString(authoritySetting.testScheduleFrequencyId)}
														onChange={updateAuthoritySettingValue}
														options={testFrequencies}
														selfOrder={true}
													/>
												)}
											{(String.equalCaseInsensitive(
												authoritySetting.testScheduleFrequencyOption,
												TestFrequencySettingTypes.Selected
											) ||
												String.equalCaseInsensitive(
													authoritySetting.testScheduleFrequencyOption,
													TestFrequencySettingTypes.Hazard
												)) && (
												<>
													<div className=" mt-2">
														<span>
															{localizationService.getLocalizedString(
																'authoritySetting.test.scheduleNext'
															)}
															&nbsp;
															<strong>
																{localizationService
																	.getLocalizedString('cccTest.test')
																	.toLowerCase()}
															</strong>
															&nbsp;
															{localizationService.getLocalizedString(
																'authoritySetting.test.startingWith'
															)}
														</span>
													</div>
													<div className="custom-control custom-radio">
														<input
															type="radio"
															className="custom-control-input"
															name="testScheduleFrequencyField"
															checked={String.equalCaseInsensitive(
																authoritySetting.testScheduleFrequencyField,
																TestFrequencySettingFields.DueDate
															)}
															id="dueDate"
															onChange={updateAuthoritySettingValue}
															value={TestFrequencySettingFields.DueDate}
														/>
														<label className="custom-control-label" htmlFor="dueDate">
															<span>
																{localizationService.getLocalizedString(
																	'cccTest.dueDate'
																)}
															</span>
														</label>
													</div>
													<div className="custom-control custom-radio">
														<input
															type="radio"
															className="custom-control-input"
															name="testScheduleFrequencyField"
															checked={String.equalCaseInsensitive(
																authoritySetting.testScheduleFrequencyField,
																TestFrequencySettingFields.CompleteDate
															)}
															id="completeDate"
															onChange={updateAuthoritySettingValue}
															value={TestFrequencySettingFields.CompleteDate}
														/>
														<label className="custom-control-label" htmlFor="completeDate">
															<span>
																{localizationService.getLocalizedString(
																	'cccTest.completeDate'
																)}
															</span>
														</label>
													</div>

													{String.equalCaseInsensitive(
														authoritySetting.testScheduleFrequencyField,
														TestFrequencySettingFields.DueDate
													) && (
														<div className="ai-form-help">
															{localizationService.getLocalizedString(
																'cccTest.useCompleteDate'
															)}
														</div>
													)}
												</>
											)}
										</section>
									</div>
								</div>
							</div>
						</div>
					</>
				) : (
					<AccessDeniedPage />
				)}
			</div>
		</>
	);
};

export default TestSettingsComponent;
