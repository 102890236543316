import React, { FC } from 'react';
import { History } from 'history';
import * as LinkoTypes from '@rcp/types';
import { IppReportPackageTemplate, ColumnField, FilterType, LocalStorageName } from '@rcp/types';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { navigateTo, Resource, urlService } from 'src/services';
import { RootState } from 'src/redux';
import { ippAttachmentsSlice } from './attachments.slice';
import { Utils } from 'src/services/utils';
import { IppConstants } from 'src/constants';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
}

const { queryParams } = IppConstants;
const { initialPage, shortPageSize } = queryParams;
const IppReportPackageAttachmentsComponent: FC<Props<IppReportPackageTemplate>> = props => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.IppReportPackageTemplateElement;

		const url = urlService.getIppAuthorityUrl(
			`${Resource.IppReportPackageReportElementTypes}`,
			dataItem.reportElementTypeId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showTabList: false,
		showEditColumnButton: false,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.reportPackage.reportTemplateElement',
		storageName: LocalStorageName.IppReportPackageAttachmentGrid,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: '',
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportElementCategoryName: 'Attachments',
			page: initialPage,
			size: shortPageSize,
			sort: 'name asc'
		},
		defaultSort: [{ field: 'name', dir: 'asc' }],
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isRequiredInTemplate', FilterType.Boolean)
		],

		defaultColumns: ['name', 'description', 'lastModificationDateTimeLocal', 'lastModifierFullName']
	};
	const attachmentsState = (state: RootState) => state.ippReportPackageAttachments;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippAttachmentsSlice}
				restState={attachmentsState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppReportPackageAttachmentsComponent;
