import { IncidentContributor, IncidentContributorEntityType } from '@rcp/types';
import React from 'react';
import {
	ActionModalActionType,
	ActionModalState,
	alertService,
	toggleActionModal,
	useReduxDispatch,
	useRootStateSelector
} from 'src/redux';
import { localizationService } from 'src/services';
import { BatchInspectionOperationModal } from '../facilities/facility-details/batch-inspection-operation-modal';

interface Props {
	actionModalState: ActionModalActionType;
	rootStateSelector: (state: any) => boolean;
	selectedSuspectedFacilities: IncidentContributor[];
	selectedKnownFacilities: IncidentContributor[];
}

export const IncidentBatchScheduleInspection: React.FC<Props> = ({
	actionModalState,
	selectedKnownFacilities,
	selectedSuspectedFacilities,
	rootStateSelector
}) => {
	const showInspectionBatchOperationModal = useRootStateSelector(rootStateSelector);
	const dispatch = useReduxDispatch();

	const onScheduleInspectionLinkClick = (e: any) => {
		e.preventDefault();
		toggleBatchScheduleInspectionsModal();
	};

	const toggleBatchScheduleInspectionsModal = () => {
		if (getIncidentContributorsCount() > 0) {
			dispatch(toggleActionModal(actionModalState));
		} else {
			alertService.addError(localizationService.getLocalizedString('alertMessages.cantScheduleWithNoFacilities'));
		}
	};

	const getIncidentContributorsCount = () => {
		return selectedKnownFacilities.length + selectedSuspectedFacilities.length;
	};

	const getBatchScheduleInspectionQuery = () => {
		let incidentContributorIds = '';
		if (selectedKnownFacilities.length > 0 && selectedSuspectedFacilities.length > 0) {
			incidentContributorIds = `${getIncidentContributorsFacilityIds(
				selectedKnownFacilities
			)},${getIncidentContributorsFacilityIds(selectedSuspectedFacilities)}`;
		} else if (selectedKnownFacilities.length > 0) {
			incidentContributorIds = getIncidentContributorsFacilityIds(selectedKnownFacilities);
		} else if (selectedSuspectedFacilities.length > 0) {
			incidentContributorIds = getIncidentContributorsFacilityIds(selectedSuspectedFacilities);
		}

		return `facility.facilityId=in:${incidentContributorIds}`;
	};

	const getIncidentContributorsFacilityIds = (contributors: IncidentContributor[]) => {
		return contributors
			.filter(x => x.entityType === IncidentContributorEntityType.FogFacility && x.entityId)
			.map(x => x.entityId)
			.toString();
	};

	return (
		<>
			{getIncidentContributorsCount() > 0 && (
				<div className="mt-3">
					<a
						className="map-link"
						id="batchScheduleInspectionLink"
						href="#/"
						onClick={onScheduleInspectionLinkClick}>
						Schedule inspections for incident contributors
					</a>
				</div>
			)}
			{showInspectionBatchOperationModal && (
				<BatchInspectionOperationModal
					actionType={actionModalState}
					toggleModal={toggleBatchScheduleInspectionsModal}
					filteredObjectCount={getIncidentContributorsCount()}
					isPatch={false}
					forIncidentContributor={true}
					query={getBatchScheduleInspectionQuery()}
				/>
			)}
		</>
	);
};
