import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { DateUtilService, localizationService } from '../../../../services';
import { useHistory } from 'react-router';
import { ColumnField, Dictionary, Extractor, LocalStorageName, RouteProps } from '@rcp/types';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState, useRootStateSelector } from '../../../../redux';
import { facilityDevicesSlice } from './facility-devices-slice';
import { SubmitFacilityCleaningActionLink } from '../submit-cleaning.tsx/submit-facility-cleaning-cell';
import AddDeviceModal from './add-device-modal';
import { Utils } from 'src/services/utils';
import { Translate } from 'src/components/widgets/translate/translator';

const FacilityDevices = (props: RouteProps) => {
	const [showFallBack, setShowFallback] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [isFacilityPortalBlocked, setIsFacilityPortalBlocked] = useState(true);
	const history = useHistory();

	const facilityDevices = useRootStateSelector(state => state.facilityPortalDevices);
	useEffect(() => {
		Utils.getFacilityPortalStatus(setIsFacilityPortalBlocked);
	}, []);

	React.useEffect(() => {
		document.body.className = showFallBack ? 'mobile-view' : '';

		return () => {
			document.body.className = '';
		};
	}, [showFallBack]);

	const toggleModal = (e?: any) => {
		e && e.preventDefault();
		setShowModal(!showModal);
	};

	useEffect(() => {
		setShowFallback(facilityDevices.result.length > 0 ? false : true);
	}, [facilityDevices]);

	const EmptyCleaningsGrid = (
		<>
			{!isFacilityPortalBlocked && (
				<div className="pt-4 px-2">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex justify-content-between w-100">
							<h1>
								<Translate>
									{localizationService.getLocalizedString('facilityPortal.cleanings.devices')}
								</Translate>
							</h1>
							<button className="btn ai-new" onClick={toggleModal}>
								<Translate>
									{localizationService.getLocalizedString('facilityPortal.cleanings.addDevice')}
								</Translate>
							</button>
						</div>
					</div>

					<div className="d-flex justify-content-center align-items-center mt-4 no-facilities">
						<div>
							<a href="#/" onClick={toggleModal}>
								<Translate>
									{localizationService.getLocalizedString(
										'facilityPortal.cleanings.addDeviceDescription1'
									)}
								</Translate>
							</a>
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString(
									'facilityPortal.cleanings.addDeviceDescription2'
								)}
							</Translate>
						</div>
					</div>
				</div>
			)}
			{showModal && <AddDeviceModal showModal={showModal} toggleModal={toggleModal} />}
		</>
	);

	const ShowFacilityPortalBlockedUi = (
		<>
			{isFacilityPortalBlocked && (
				<div className="pt-4 px-2">
					<div className="d-flex justify-content-center align-items-center mt-4 no-facilities">
						<div>
							<Translate>
								{localizationService.getLocalizedString(
									'facilityPortal.cleanings.facilityPortalBlocked'
								)}
							</Translate>
						</div>
					</div>
				</div>
			)}
		</>
	);

	const submitCleaning = (rowItem: any) => {
		const facility = _.pick(rowItem.dataItem, [
			'facilityName',
			'facilityAddressLine1',
			'facilityCityName',
			'facilityJurisdictionCode',
			'facilityZipCode'
		]);
		const deviceDetails = _.pick(rowItem.dataItem, [
			'extractorId',
			'extractorDescription',
			'extractorType',
			'deviceNumber',
			'attachmentOwnershipDto'
		]);
		return (
			<td className={rowItem.className}>
				<SubmitFacilityCleaningActionLink facility={facility} deviceDetails={deviceDetails} />
			</td>
		);
	};

	const extractorImage = (rowItem: any) => {
		const extractor: Extractor = rowItem.dataItem;
		return (
			<td>
				{extractor.attachmentOwnershipDto && (
					<img
						src={
							extractor.attachmentOwnershipDto.s3ThumbnailUrl ||
							extractor.attachmentOwnershipDto.s3ResourceUrl
						}
						alt={extractor.attachmentOwnershipDto.fileName}
						width={60}
						height={60}
					/>
				)}
			</td>
		);
	};

	const translateNextCleaningDate = (rowItem: any) => {
		const extractor: Extractor = rowItem.dataItem;

		if (!extractor.nextCleaningDate) {
			return <td></td>;
		}

		const formattedDate = DateUtilService.toDisplayDate(extractor.nextCleaningDate);
		return (
			<td>
				<Translate>{formattedDate}</Translate>
			</td>
		);
	};

	const translateLastCleaningDate = (rowItem: any) => {
		const extractor: Extractor = rowItem.dataItem;

		if (!extractor.lastCleaningDate) {
			return <td></td>;
		}

		const formattedDate = DateUtilService.toDisplayDate(extractor.lastCleaningDate);
		return (
			<td>
				<Translate>{formattedDate}</Translate>
			</td>
		);
	};

	let columns = [
		ColumnField.newText('image', true, extractorImage, undefined, true),
		ColumnField.newText('deviceNumber', true, undefined, undefined, false, true),
		ColumnField.newText('extractorDescription', true),
		ColumnField.newDate('nextCleaningDate', true, translateNextCleaningDate),
		ColumnField.newText('cleanFrequencyCode', true),
		ColumnField.newDate('lastCleaningDate', true, translateLastCleaningDate),
		ColumnField.newText('submitCleaning', true, submitCleaning, true, true)
	];

	let defaultColumns = [
		'image',
		'deviceNumber',
		'extractorDescription',
		'nextCleaningDate',
		'cleanFrequencyCode',
		'lastCleaningDate',
		'submitCleaning'
	];

	let translateColumns = ['extractorDescription', 'cleanFrequencyCode'];

	const searchFacilityDevices = (data: [], searchTerm: string) => {
		const searchColumns = ['deviceNumber', 'extractorDescription'];

		const dataMatchingSearch = data.filter(x => {
			let hasMatch = false;
			for (let column of searchColumns) {
				if (Utils.isContains(searchTerm.trim(), x[column])) {
					hasMatch = true;
				}
			}

			return hasMatch;
		});

		return dataMatchingSearch as [];
	};

	const initialGridOptions: GridOption = {
		pageTitle: localizationService.getLocalizedString('facilityPortal.cleanings.devices'),
		prefix: 'facilityPortal.cleanings',
		showEditColumnButton: true,
		storageName: LocalStorageName.FacilityPortalDevices,
		searchHint: localizationService.getLocalizedString('facilityPortal.cleanings.searchHint'),
		gridUrlLocalStorageName: LocalStorageName.FacilityPortalDevicesFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FacilityPortalDevicesSortOrder,
		showSearch: true,
		showAddModal: showModal,
		toggleAddModal: toggleModal,
		addModalComponent: <>{showModal && <AddDeviceModal showModal={showModal} toggleModal={toggleModal} />}</>,
		showAddButton: !isFacilityPortalBlocked,
		addButtonText: localizationService.getLocalizedString('facilityPortal.cleanings.addDevice'),
		queryParameters: { sort: 'deviceNumber asc' } as Dictionary<string>,
		defaultSort: [{ field: 'deviceNumber', dir: 'asc' }],
		allColumns: columns,
		defaultColumns: defaultColumns,
		translateColumns: translateColumns,
		doClientPagination: true,
		doClientSearch: searchFacilityDevices,
		doClientSideSorting: true
	};

	return (
		<>
			<DataGrid
				gridOption={initialGridOptions}
				restSlice={facilityDevicesSlice}
				restState={(state: RootState) => state.facilityPortalDevices}
				history={history}
				match={props.match}
				fallBackUI={
					isFacilityPortalBlocked
						? ShowFacilityPortalBlockedUi
						: showFallBack
						? EmptyCleaningsGrid
						: undefined
				}
			/>
		</>
	);
};
export default FacilityDevices;
