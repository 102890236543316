import React, { useEffect, useRef, useState } from 'react';
import { alertService, useReduxSelector } from 'src/redux';
import _ from 'lodash';
import { Resource, urlService } from 'src/services/url-service';
import {
	Dictionary,
	EntityMapLocation,
	EntityMapLocationType,
	FeatureNames,
	FogFacility,
	Incident,
	IncidentContributor,
	LocalStorageName,
	MaxInteger,
	MaxIntegerStr
} from '@rcp/types';
import './map.scss';
import * as atlas from 'azure-maps-control';
import { apiService, authoritySettingService, localizationService, localStorageService, Logger } from 'src/services';
import './atlas.css';
import { FacilityRiskMapHeaderOverlay } from './overlays/facility-risk-map-header';
import { FacilityRiskMapFooterOverlay } from './overlays/facility-risk-map-footer';
import { IncludesList, MapLegendKey, mapService, MapSettings } from './map-service';
import { FloatMapSettings } from '././overlays/float-map-settings';
import { FloatMapEntityList } from './overlays/float-map-entity-list';
import { pointsWithinPolygon } from '@turf/turf';
import { isAndroid, isMobile } from 'react-device-detect';
import * as atlasDrawing from 'azure-maps-drawing-tools';
import { FloatAddIncident } from './overlays/float-add-incident';

const mapElementId = 'map';
const mapMarkerAnimationCss = 'animation-icon';

export const FogMap: React.FC<{}> = () => {
	const map = useRef<atlas.Map | null>(null);
	const isSelectKnownFacilityForIncidentMode = useRef<boolean>(false);
	const isSelectSuspectedFacilityForIncidentMode = useRef<boolean>(false);
	const mapDataSource = useRef<atlas.source.DataSource | null>(null);
	const mapDataSourceStreetLevel = useRef<atlas.source.DataSource | null>(null);
	const markerLayer = useRef<atlas.layer.BubbleLayer | null>(null);
	const markerLayerStreetLevel = useRef<atlas.layer.BubbleLayer | null>(null);
	const drawingManager = useRef<any>(null);
	const animationElement = useRef<HTMLElement | null>(null);
	const dataSourceForGeoJsonData = useRef<atlas.source.DataSource | null>(null);
	const popupForGeoJsonData = useRef<atlas.Popup | null>(null);
	const renderCount = useRef<number>(0);
	const mapHeader = useRef();

	const [mapSettings, setMapSettings] = React.useState<MapSettings>(mapService.loadMapSettings());
	const [queryParameters, setQueryParameters] = React.useState<Dictionary<string> | undefined>(undefined);
	const [isMapReady, setIsMapReady] = useState(false);
	const [togglePreference, setTogglePreference] = useState(false);
	const [vh, setVh] = useState(window.innerHeight * 0.01);
	const [facilitiesIncidentsMapFeaturesLoaded, setFacilitiesIncidentsMapFeaturesLoaded] = useState(false);
	const [isMapDataSourceLoaded, setIsMapDataSourceLoaded] = useState(false);
	const [isGeoJsonDataAdded, setIsGeoJsonDataAdded] = useState(false);

	const [facilityMapFeatures, setFacilityMapFeatures] = useState<atlas.data.Feature<atlas.data.Point, any>[] | null>(
		null
	);
	const [incidentMapFeatures, setIncidentMapFeatures] = useState<atlas.data.Feature<atlas.data.Point, any>[] | null>(
		null
	);
	const [complianceValues, setComplianceValues] = React.useState<string[]>([]);

	//add reference to facility for event listener

	const [mapEntityList, setMapEntityList] = React.useState<(FogFacility | Incident)[]>([]);
	const [toggleMapEntityList, setToggleMapEntityList] = React.useState(false);
	const [showSingleCardView, setShowSingleCardView] = React.useState(false);
	const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false);
	const [selectedKnownFacilitiesForIncident, setSelectedKnownFacilitiesForIncident] = React.useState<FogFacility[]>(
		[]
	);
	const [needResetMapCameraFocus, setNeedResetMapCameraFocus] = React.useState(false);

	const [selectedSuspectedFacilitiesForIncident, setSelectedSuspectedFacilitiesForIncident] = React.useState<
		FogFacility[]
	>([]);
	const [toggleIncidentDetail, setToggleIncidentDetail] = React.useState(false);
	const [selectedIncident, setSelectedIncident] = React.useState<Incident | null>(null);
	const [initialWidth, setInitialWidth] = React.useState(window.innerWidth);
	const [initialHeight, setInitialHeight] = React.useState(window.innerHeight);

	const showIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);

	const saveMapLegend = (mapLegend: MapLegendKey) => {
		let newMapSettings = { ...mapSettings, mapLegend: mapLegend };
		mapService.saveMapSettings(newMapSettings);
		setMapSettings(newMapSettings);
	};

	const incidentOperationCallback = () => {
		loadIncidentMapFeatures();
	};

	const handleIncidentSaved = () => {
		incidentOperationCallback();
		resetIncidentContributorSelection();
		clearDrawing();
		setIsFilterPanelOpen(false);
	};

	const handleIncidentDeleted = () => {
		incidentOperationCallback();
		resetIncidentContributorSelection();
		clearDrawing();
		setToggleIncidentDetail(false);
		if (mapEntityList.length === 1 && mapEntityList[0].incidentId && mapEntityList[0].incidentId > 0) {
			setToggleMapEntityList(false);
		}
	};

	const onResize = () => {
		if (isAndroid) {
			if (window.innerHeight < initialHeight) {
				document.documentElement.style.setProperty('overflow', 'auto');
				let viewport = document.querySelector('meta[name=viewport]');
				if (viewport) {
					viewport.setAttribute(
						'content',
						'height=' + initialHeight + 'px, width = device-width, initial-scale=1.0'
					);
				}
			} else {
				document.documentElement.style.setProperty('overflow', 'hidden');
				let viewport = document.querySelector('meta[name=viewport]');
				if (viewport) {
					viewport.setAttribute(
						'content',
						'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
					);
				}
			}
		} else {
			let px = window.innerHeight * 0.01;
			setVh(px);
		}
	};

	const onMapReady = (map: atlas.Map) => {
		setTimeout(() => {
			setIsMapReady(true);
		}, mapSettings.readyTimeout);
		adjustMobileMapControls(map);
	};

	const startElementAnimation = (element: HTMLElement | undefined) => {
		clearElementAnimation();
		if (element) {
			if (!element.classList.contains(mapMarkerAnimationCss)) {
				element.classList.add(mapMarkerAnimationCss);
			}
			animationElement.current = element;
		}
	};

	const clearElementAnimation = () => {
		if (animationElement.current) {
			animationElement.current.classList.remove(mapMarkerAnimationCss);
			animationElement.current = null;
		}
	};

	const repositionMapOverlays = () => {
		const shouldRepositionMapOverlays = isRightPanelOpen();

		if (!isMobile) {
			if (shouldRepositionMapOverlays) {
				map.current?.controls.controlContainer.classList.add('shifted-map-controls');
			} else {
				map.current?.controls.controlContainer.classList.remove('shifted-map-controls');
			}
		}
	};

	const isRightPanelOpen = () => {
		return isFilterPanelOpen || toggleMapEntityList || toggleIncidentDetail;
	};

	const isMobileDrawerOpen = () => {
		return isMobile && isRightPanelOpen();
	};

	const getMobileFacilityRiskMapFooterOverlay = () => {
		return (
			<FacilityRiskMapFooterOverlay
				mapLegend={mapSettings.mapLegend}
				complianceValues={complianceValues}
				setMapLegend={mapLegend => saveMapLegend(mapLegend)}
				inMobileDrawer={true}
			/>
		);
	};

	useEffect(() => {
		repositionMapOverlays();
	}, [isFilterPanelOpen, toggleMapEntityList, toggleIncidentDetail]);

	useEffect(() => {
		adjustMobileMapControls();
	}, [mapHeader, map, map.current, mapHeader.current, map.current?.controls]);

	const adjustMobileMapControls = (currentMap: atlas.Map | null = map.current) => {
		if (isMobile && mapHeader.current && mapHeader.current.clientHeight && currentMap && currentMap.controls) {
			const topRightControlContainer = currentMap.controls.controlContainer.children[1];
			topRightControlContainer.style.top = `${mapHeader.current.clientHeight}px`;
		}
	};

	const clearPopupFloatAndAnimation = () => {
		setToggleMapEntityList(false);
		clearElementAnimation();
	};

	const clearDrawing = () => {
		if (drawingManager.current) {
			drawingManager.current.getSource().clear();
			drawingManager.current.setOptions({ mode: 'idle' });
		}
	};

	const onMapClick = () => {
		//@ts-ignore
		document.querySelector('#SearchInput').blur();
		clearPopupFloatAndAnimation();
	};

	const onMapRender = (e: any) => {
		renderCount.current = renderCount.current + 1;
	};

	useEffect(() => {
		authoritySettingService.getAuthorityComplianceStatusInUse().then(complianceStatusValues => {
			setComplianceValues(complianceStatusValues);
		});

		mapService.initializeMap(mapElementId, onMapReady).then((createdMap: atlas.Map) => {
			createdMap.events.add('click', onMapClick);
			createdMap.events.add('render', onMapRender);
			map.current = createdMap;
		});

		if (isAndroid) {
			let viewport = document.querySelector('meta[name=viewport]');
			if (viewport) {
				viewport.setAttribute(
					'content',
					'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
				);
			}
		}

		window.addEventListener('resize', onResize);
		return () => {
			if (map.current) {
				mapService.disposeMap();
				map.current = null;
			}
			window.removeEventListener('resize', onResize);
			Logger.info(`Map render event called ${renderCount.current} times.`);
		};
	}, []);

	useEffect(() => {
		if (queryParameters !== undefined) {
			const queryParametersForHistory = { ...queryParameters };
			//Save grid url to browser history due to parameter changes
			const url = urlService.setUrlQueryString(queryParametersForHistory);
			//Reserved last visit parameters to local storage for next visit without URL parameters
			localStorageService.setLocalStorage(LocalStorageName.FacilityMap, url);

			loadFacilitiesIncidentsMapFeatures();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryParameters]);

	const loadFacilitiesIncidentsMapFeatures = async () => {
		setFacilitiesIncidentsMapFeaturesLoaded(false);
		//TODO: do not load facilities when only incident filter has changed
		await loadFacilitiesMapFeatures();
		//TODO: do not load incidents when only facility filter has changed
		await loadIncidentMapFeatures();
		setFacilitiesIncidentsMapFeaturesLoaded(true);
	};

	const onIncidentCardLinkClick = (incident: Incident) => {
		setToggleIncidentDetail(true);
		setSelectedIncident(incident);
	};

	useEffect(() => {
		if (
			map.current &&
			isMapReady &&
			facilitiesIncidentsMapFeaturesLoaded &&
			facilityMapFeatures &&
			incidentMapFeatures
		) {
			setIsMapDataSourceLoaded(false);
			resetMapDataSourceAndLayers(map.current, mapSettings.mapLegend);
			setIsMapDataSourceLoaded(true);

			if (needResetMapCameraFocus) {
				let mapBoundingBox = atlas.data.BoundingBox.fromLatLngs(
					[...facilityMapFeatures, ...incidentMapFeatures].map(x => {
						return x.geometry.coordinates;
					})
				);
				map.current.setCamera({
					bounds: mapBoundingBox,
					padding: 80
				});
				setNeedResetMapCameraFocus(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		//DO not add `needResetMapCameraFocus` as dependency
		isMapReady,
		mapSettings.mapLegend,
		facilitiesIncidentsMapFeaturesLoaded,
		facilityMapFeatures,
		incidentMapFeatures
	]);

	useEffect(() => {
		if (!urlService.isDebugMode()) {
			return;
		}

		if (isMapDataSourceLoaded && map.current && isMapReady && dataSourceForGeoJsonData.current == null) {
			dataSourceForGeoJsonData.current = mapService.createDataSourceForGeoJsonData(
				map.current,
				onGeoJsonDataFeatureClicked
			);
			let dataSource = dataSourceForGeoJsonData.current;
			mapService.createDropGeoJsonFileZone(mapElementId, map.current, (geoData: any) => {
				setIsGeoJsonDataAdded(true);
				dataSource.add(geoData);
			});
			if (popupForGeoJsonData.current) {
				popupForGeoJsonData.current.remove();
				popupForGeoJsonData.current = null;
			}
			popupForGeoJsonData.current = new atlas.Popup({
				position: [0, 0]
			});
		}
	}, [isMapDataSourceLoaded]);

	const createDrawingTool = () => {
		if (
			isMapDataSourceLoaded &&
			map.current &&
			isMapReady &&
			mapDataSource.current &&
			drawingManager.current === null
		) {
			if (isMobile) {
				map.current.controls.remove(map.current.controls.getControls());
			}

			let drawingTool = mapService.createDrawingToolForSelection(map.current, handleDrawingSelectedArea);
			if (drawingTool) {
				drawingManager.current = drawingTool;
			}

			if (isMobile) {
				map.current.controls.add(mapService.getBaseMapControls(), {
					position: atlas.ControlPosition.TopRight
				});
			}
		}
	};

	const toggleDrawingTool = (toggle: boolean) => {
		if (drawingManager.current) {
			if (!toggle) {
				drawingManager.current.setOptions({
					toolbar: new atlasDrawing.control.DrawingToolbar({
						visible: false
					})
				});
			} else {
				if (isMobile) {
					map.current.controls.remove(map.current.controls.getControls());
				}

				drawingManager.current.setOptions({
					toolbar: new atlasDrawing.control.DrawingToolbar({
						buttons: ['draw-polygon'],
						position: mapService.getMapControlPosition(),
						style: 'light'
					}),
					mode: atlasDrawing.drawing.DrawingMode.idle
				});

				if (isMobile) {
					map.current.controls.add(mapService.getBaseMapControls(), {
						position: atlas.ControlPosition.TopRight
					});
				}
			}
		}
		clearDrawing();
	};

	const handleDrawingSelectedArea = (poly: atlas.data.Feature<atlas.data.Geometry, any>) => {
		if (mapDataSource.current) {
			//Retrieve the individual points that are in the search area.
			let points = mapDataSource.current.toJson();
			//Calculate all points that are within the polygon area.
			var ptsWithin = pointsWithinPolygon(points, poly);
			let selectedFacilities = ptsWithin.features
				.map((f: any) => f.properties as FogFacility)
				.filter(f => f.facilityId && f.facilityId > 0);

			if (isSelectKnownFacilityForIncidentMode.current) {
				setSelectedKnownFacilitiesForIncident(selectedFacilities);
			} else if (isSelectSuspectedFacilityForIncidentMode.current) {
				setSelectedSuspectedFacilitiesForIncident(selectedFacilities);
			}
		}
	};

	const loadFacilitiesMapFeatures = async () => {
		let queryParams = { ...(queryParameters || {}) };

		['incidentFilterId', 'incidentDraftFilterId'].forEach((key: string) => {
			_.unset(queryParams, key);
		});

		queryParams.size = MaxIntegerStr;

		let queryString = urlService.toQueryString(queryParams);

		await apiService
			.httpGet(
				`${urlService.getApiBaseUrlWithProgram()}/${Resource.EntityMapLocation}/${
					Resource.FogFacilities
				}?${queryString}&includes=${IncludesList.join(',')}`
			)
			.then(res => {
				if (!_.isEmpty(res)) {
					setFacilityMapFeatures(mapService.toFacilityMapFeatures(res));
				} else {
					setFacilityMapFeatures([]);
				}
			});
	};

	const loadIncidentMapFeatures = async () => {
		let queryParams = { ...(queryParameters || {}) };

		['filterId', 'draftFilterId'].forEach((key: string) => {
			_.unset(queryParams, key);
		});

		if (queryParams.incidentFilterId) {
			queryParams['filterId'] = _.toString(queryParams.incidentFilterId);
		} else if (queryParams.incidentDraftFilterId) {
			queryParams['draftFilterId'] = _.toString(queryParams.incidentDraftFilterId);
		}

		['incidentFilterId', 'incidentDraftFilterId'].forEach((key: string) => {
			_.unset(queryParams, key);
		});

		queryParams.size = MaxIntegerStr;
		let queryString = urlService.toQueryString(queryParams);

		await apiService
			.httpGet(
				`${urlService.getApiBaseUrlWithProgram()}/${Resource.EntityMapLocation}/${
					Resource.Incidents
				}?${queryString}`
			)
			.then(res => {
				if (!_.isEmpty(res) && showIncidentFeature) {
					setIncidentMapFeatures(mapService.toIncidentMapFeatures(res));
				} else {
					setIncidentMapFeatures([]);
				}
				if (mapEntityList.length > 0) {
					let updatedIncidentMapLocations: EntityMapLocation[] = res.filter((mapLocation: any) =>
						mapEntityList.find(i => i.incidentId === mapLocation.entityId)
					);
					let updatedIncidentList: Incident[] = updatedIncidentMapLocations.map(
						mapLocation => mapLocation.mapObjectJson && JSON.parse(mapLocation.mapObjectJson)
					);
					//@ts-ignore
					let facilities: Facility[] = mapEntityList.filter(x => x.facilityId && x.facilityId > 0);
					setMapEntityList([...updatedIncidentList, ...facilities]);
				}
			});
	};

	const clearMapDataSourceAndLayers = (mapCurrent: atlas.Map) => {
		if (markerLayer.current) {
			mapCurrent.layers.remove(markerLayer.current);
			markerLayer.current = null;
		}
		if (mapDataSource.current) {
			mapCurrent.sources.remove(mapDataSource.current);
			mapDataSource.current = null;
		}
		if (markerLayerStreetLevel.current) {
			mapCurrent.layers.remove(markerLayerStreetLevel.current);
			markerLayerStreetLevel.current = null;
		}
		if (mapDataSourceStreetLevel.current) {
			mapCurrent.sources.remove(mapDataSourceStreetLevel.current);
			mapDataSourceStreetLevel.current = null;
		}
	};

	const resetMapDataSourceAndLayers = (mapCurrent: atlas.Map, mapLegendKey: MapLegendKey) => {
		clearMapDataSourceAndLayers(mapCurrent);
		let markerComponents = mapService.createMapMarkerLayer(
			mapCurrent,
			mapLegendKey,
			complianceValues,
			addClusterPopupToMap,
			addMarkerPopupToMap
		);
		markerLayer.current = markerComponents.markerLayer;
		mapDataSource.current = markerComponents.dataSource;
		markerLayerStreetLevel.current = markerComponents.markerLayerStreetLevel;
		mapDataSourceStreetLevel.current = markerComponents.dataSourceStreetLevel;

		if (facilityMapFeatures && incidentMapFeatures) {
			mapDataSource.current.setShapes([...facilityMapFeatures, ...incidentMapFeatures]);
			mapDataSourceStreetLevel.current.setShapes([...facilityMapFeatures, ...incidentMapFeatures]);
		}
	};

	const addMarkerPopupToMap = (e: atlas.TargetedEvent) => {
		if (e.target) {
			let marker = e.target as any;
			let facility = _.get(e, 'target.properties') as FogFacility;
			let incident = _.get(e, 'target.properties') as Incident;

			if (facility && facility.facilityId && facility.facilityId > 0) {
				if (isSelectKnownFacilityForIncidentMode.current) {
					setSelectedKnownFacilitiesForIncident([facility]);
				} else if (isSelectSuspectedFacilityForIncidentMode.current) {
					setSelectedSuspectedFacilitiesForIncident([facility]);
				} else {
					_.delay(openMapEntityPopup, 30, marker, facility);
				}
			} else if (incident && incident.incidentId && incident.incidentId > 0) {
				_.delay(openMapEntityPopup, 30, marker, incident);
			}
		}
	};

	const openMapEntityPopup = (marker: any, mapEntity: FogFacility | Incident) => {
		if (map.current) {
			setTogglePreference(false);
			setMapEntityList([mapEntity]);
			setToggleMapEntityList(true);
			setShowSingleCardView(true);
			startElementAnimation(marker.element);
		}
	};

	const addClusterPopupToMap = (e: atlas.TargetedEvent) => {
		let marker = e.target as any;
		if (map.current && mapDataSource.current) {
			let mapCurrent = map.current;
			const isStreetLayer = mapCurrent.getCamera().zoom >= 16;
			let mapDataSourceCurrent = isStreetLayer ? mapDataSourceStreetLevel.current : mapDataSource.current;

			let clusterId = marker.properties.cluster_id;
			mapDataSourceCurrent!.getClusterLeaves(clusterId, MaxInteger, 0).then(features => {
				let clusterMapEntities: (Incident | FogFacility)[] = features.map((f: any) => f.data.properties);
				if (isSelectKnownFacilityForIncidentMode.current) {
					setSelectedKnownFacilitiesForIncident(
						//@ts-ignore
						clusterMapEntities.filter(f => f.facilityId && f.facilityId > 0)
					);
				} else if (isSelectSuspectedFacilityForIncidentMode.current) {
					setSelectedSuspectedFacilitiesForIncident(
						//@ts-ignore
						clusterMapEntities.filter(f => f.facilityId && f.facilityId > 0)
					);
				} else {
					setMapEntityList(clusterMapEntities);
					setTogglePreference(false);
					setToggleMapEntityList(true);
					setShowSingleCardView(isStreetLayer);
					startElementAnimation(marker.element);
				}
			});
		}
	};

	const onGeoJsonDataFeatureClicked = (e: any) => {
		if (map.current && popupForGeoJsonData.current && e.shapes && e.shapes.length > 0) {
			//By default, show the popup where the mouse event occurred.
			var pos = e.position;
			var offset = [0, 0];
			var properties;

			if (e.shapes[0] instanceof atlas.Shape) {
				properties = e.shapes[0].getProperties();

				//If the shape is a point feature, show the popup at the points coordinate.
				if (e.shapes[0].getType() === 'Point') {
					pos = e.shapes[0].getCoordinates();
					offset = [0, -18];
				}
			} else {
				properties = e.shapes[0].properties;

				//If the shape is a point feature, show the popup at the points coordinate.
				if (e.shapes[0].type === 'Point') {
					pos = e.shapes[0].geometry.coordinates;
					offset = [0, -18];
				}
			}

			//Update the content and position of the popup.
			popupForGeoJsonData.current.setOptions({
				//Create a table from the properties in the feature.
				content: atlas.PopupTemplate.applyTemplate(properties),
				position: pos,
				pixelOffset: offset
			});

			//Open the popup.
			popupForGeoJsonData.current.open(map.current);
		}
	};

	const toggleSelectFacilityForIncidentMode = (toggle: boolean, isKnownFacilities: boolean) => {
		if (toggle && isKnownFacilities) {
			isSelectKnownFacilityForIncidentMode.current = true;
			isSelectSuspectedFacilityForIncidentMode.current = false;
		}
		if (toggle && !isKnownFacilities) {
			isSelectSuspectedFacilityForIncidentMode.current = true;
			isSelectKnownFacilityForIncidentMode.current = false;
		}
		if (toggle && drawingManager.current) {
			toggleDrawingTool(true);
		}
		if (toggle && !drawingManager.current) {
			createDrawingTool();
		}

		if (!toggle) {
			isSelectSuspectedFacilityForIncidentMode.current = false;
			isSelectKnownFacilityForIncidentMode.current = false;
			toggleDrawingTool(false);
		}
	};

	const resetIncidentContributorSelection = () => {
		isSelectSuspectedFacilityForIncidentMode.current = false;
		isSelectKnownFacilityForIncidentMode.current = false;
		clearSelectedFacilities();
		toggleDrawingTool(false);
	};

	const clearSelectedFacilities = () => {
		setSelectedKnownFacilitiesForIncident([]);
		setSelectedSuspectedFacilitiesForIncident([]);
	};

	const handleRemoveSelectedContributorForIncident = (contributor: IncidentContributor) => {
		if (contributor.isKnownContributor) {
			setSelectedKnownFacilitiesForIncident(
				selectedKnownFacilitiesForIncident.filter(x => x.facilityId !== contributor.entityId)
			);
		} else {
			setSelectedSuspectedFacilitiesForIncident(
				selectedSuspectedFacilitiesForIncident.filter(x => x.facilityId !== contributor.entityId)
			);
		}
	};

	const getMap = () => {
		return (
			<div>
				<div className="map-container" style={{ height: `calc((${vh}px * 100) - 50px)`, position: 'relative' }}>
					<div
						id="map"
						style={{
							height: `calc((${vh}px * 100) - 50px)`,
							width: '100%',
							position: 'relative'
						}}></div>
					{!isMobileDrawerOpen() && (
						<FacilityRiskMapFooterOverlay
							mapLegend={mapSettings.mapLegend}
							complianceValues={complianceValues}
							setMapLegend={mapLegend => saveMapLegend(mapLegend)}
							inMobileDrawer={false}
						/>
					)}
					<FacilityRiskMapHeaderOverlay
						headerRef={mapHeader}
						queryParameters={queryParameters}
						setQueryParameters={setQueryParameters}
						mapSettings={mapSettings}
						setMapSettings={setMapSettings}
						showClearGeoData={isGeoJsonDataAdded}
						handleSidebarOpen={() => {
							setTogglePreference(false);
							setToggleMapEntityList(false);
							setIsFilterPanelOpen(true);
						}}
						handleSidebarClose={() => {
							setIsFilterPanelOpen(false);
						}}
						facilityRiskMapFooterOverlay={getMobileFacilityRiskMapFooterOverlay()}
						clearGeoData={() => {
							if (dataSourceForGeoJsonData.current != null) {
								dataSourceForGeoJsonData.current.clear();
								setIsGeoJsonDataAdded(false);
							}
						}}
						handleIncidentSaved={handleIncidentSaved}
						toggleSelectFacilityForIncidentMode={toggleSelectFacilityForIncidentMode}
						selectedKnownFacilitiesForIncident={selectedKnownFacilitiesForIncident}
						selectedSuspectedFacilitiesForIncident={selectedSuspectedFacilitiesForIncident}
						clearSelectedFacilities={clearSelectedFacilities}
						resetIncidentContributorSelection={resetIncidentContributorSelection}
						removeSelectedFacilityForIncident={handleRemoveSelectedContributorForIncident}
						setNeedResetMapCameraFocus={setNeedResetMapCameraFocus}
					/>

					{togglePreference && (
						<FloatMapSettings
							preference={mapSettings}
							setPreference={setMapSettings}
							handleClose={() => setTogglePreference(false)}
						/>
					)}
					{toggleMapEntityList && (
						<FloatMapEntityList
							mapEntities={mapEntityList}
							showSingleCardView={showSingleCardView}
							handleClose={() => {
								clearPopupFloatAndAnimation();
								setToggleMapEntityList(false);
							}}
							onIncidentCardLinkClick={onIncidentCardLinkClick}
							facilityRiskMapFooterOverlay={getMobileFacilityRiskMapFooterOverlay()}
							mapLegend={mapSettings.mapLegend}
							complianceValues={complianceValues}
						/>
					)}
					{toggleIncidentDetail && (
						<FloatAddIncident
							incident={selectedIncident!}
							toggleSelectFacilityForIncidentMode={toggleSelectFacilityForIncidentMode}
							selectedKnownFacilities={selectedKnownFacilitiesForIncident}
							selectedSuspectedFacilities={selectedSuspectedFacilitiesForIncident}
							clearSelectedFacilities={clearSelectedFacilities}
							resetIncidentContributorSelection={resetIncidentContributorSelection}
							handleClose={() => {
								setToggleIncidentDetail(false);
							}}
							facilityRiskMapFooterOverlay={getMobileFacilityRiskMapFooterOverlay()}
							removeSelectedContributor={handleRemoveSelectedContributorForIncident}
							isAddPanel={false}
							handleIncidentSaved={() => {
								handleIncidentSaved();
								setToggleIncidentDetail(false);
							}}
							handleIncidentDeleted={handleIncidentDeleted}></FloatAddIncident>
					)}
				</div>
			</div>
		);
	};

	return <>{getMap()}</>;
};
