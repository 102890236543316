import React from 'react';
import _ from 'lodash';
import { DropDownOption } from '@rcp/types';
import { SingleSelectDropdown } from 'src/components/widgets';

interface Props {
	name: string;
	label: string;
	className?: string;
	defaultValue?: string;
	onChange?(e: any): void;
	options: DropDownOption[];
}

export const TagDropdown: React.SFC<Props> = props => {
	const [tag, setTag] = React.useState('');

	const setCurrentSelection = (event: any) => {
		let selectedTag = event.target.value;
		_.set(event.target, 'selectedIndex', 0);
		setTag('');
		if (!_.isEmpty(selectedTag) && props.onChange) {
			props.onChange(selectedTag);
		}
	};

	return (
		<SingleSelectDropdown
			id="addExistingTag"
			name="addExistingTag"
			label="Add existing tag"
			className={props.className + ' facility-tag-dropdown'}
			options={props.options}
			autoFocus={true}
			value={tag}
			onChange={setCurrentSelection}
		/>
	);
};
