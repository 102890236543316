import React, { useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	saveFogFacility,
	useReduxDispatch,
	useReduxSelector,
	RootState
} from 'src/redux';
import { FogFacility, DropDownOption } from '@rcp/types';
import { PopoverModal, TextInput, DateInput, SingleSelectDropdown } from 'src/components/widgets';
import { urlService, validationService, localizationService, optionsMap } from 'src/services';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
}

interface StateProps extends FogFacilityState {}

interface DispatchProps {
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface AccountForm {
	accountNumber: string;
	assignedToUserProfileId?: number;
	inBusinessSince: string;
	closedDate: string;
	meterNumber: string;
	sewerNumber: string;

	monthlyFeeError?: string;
	annualFeeError?: string;
	inBusinessSinceError?: string;
	closedDateError?: string;
}
const initialAccountForm: AccountForm = {
	accountNumber: '',
	inBusinessSince: '',
	closedDate: '',
	meterNumber: '',
	sewerNumber: ''
};

const AccountModalComp: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [formState, setFormState] = useState(initialAccountForm);
	const [authorityUserOptions, setAuthorityUserOptions] = useState([] as DropDownOption[]);
	const setFormStateFromProps = (facility: FogFacility) => {
		let newState: AccountForm = {
			accountNumber: facility.accountNumber || '',
			assignedToUserProfileId: facility.assignedToUserProfileId,
			inBusinessSince: facility.inBusinessSince || '',
			closedDate: facility.closedDate || '',
			meterNumber: facility.meterNumber || '',
			sewerNumber: facility.sewerNumber || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		dispatch(authorityUsersSlice.fetchAll());
	}, [dispatch]);

	const { result: authorityUsers } = useReduxSelector((state: RootState) => state.authorityUsers);

	React.useEffect(() => {
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);
			setAuthorityUserOptions(options);
		}
	}, [authorityUsers]);

	React.useEffect(() => {
		setFormStateFromProps(props.facility);
	}, [props.facility]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		if (String.equalCaseInsensitive(name, 'monthlyFee') || String.equalCaseInsensitive(name, 'annualFee')) {
			validationService.validateNumberField(newState, name, name + 'Error');
		}
		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		validationService.validateNumberField(newState, 'monthlyFee', 'monthlyFeeError');
		validationService.validateNumberField(newState, 'annualFee', 'annualFeeError');
		validationService.validateMinimumDate(
			newState,
			'inBusinessSince',
			'inBusinessSinceError',
			localizationService.getLocalizedString('facility.inBusinessSince')
		);
		validationService.validateMinimumDate(
			newState,
			'closedDate',
			'closedDateError',
			localizationService.getLocalizedString('facility.closedDate')
		);
		setFormState(newState);

		return !validationService.hasError(
			newState,
			'monthlyFeeError',
			'annualFeeError',
			'inBusinessSinceError',
			'closedDateError'
		);
	};

	const saveAccount = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let facilityToUpdate: FogFacility = {
			accountNumber: formState.accountNumber,
			assignedToUserProfileId: formState.assignedToUserProfileId,
			inBusinessSince: formState.inBusinessSince,
			closedDate: formState.closedDate,
			meterNumber: formState.meterNumber,
			sewerNumber: formState.sewerNumber
		};
		props.saveFogFacility(urlService.getFogFacilityId(), facilityToUpdate);
		props.toggle();
	};

	const cancelSaveAccount = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('facility.editAccount')}
				save={saveAccount}
				cancel={cancelSaveAccount}>
				<div className="form-row">
					<DateInput
						id="inBusinessSince1"
						name="inBusinessSince"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.inBusinessSince')}
						value={formState.inBusinessSince}
						error={formState.inBusinessSinceError}
						onChange={changeFormState}
					/>

					<DateInput
						id="closedDate1"
						name="closedDate"
						className="form-group col-sm-6"
						label={localizationService.getLocalizedString('facility.closedDate')}
						value={formState.closedDate}
						error={formState.closedDateError}
						onChange={changeFormState}
					/>
				</div>
				<TextInput
					id="sewerNumber"
					name="sewerNumber"
					label={localizationService.getLocalizedString('facility.sewerNumber')}
					value={formState.sewerNumber}
					onChange={changeFormState}
				/>
				<TextInput
					id="accountNumber1"
					name="accountNumber"
					label={localizationService.getLocalizedString('facility.accountNumber')}
					value={formState.accountNumber}
					onChange={changeFormState}
				/>
				<TextInput
					id="meterNumber"
					name="meterNumber"
					label={localizationService.getLocalizedString('facility.meterNumber')}
					value={formState.meterNumber}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="assignedTo1"
					name="assignedToUserProfileId"
					label={localizationService.getLocalizedString('facility.assignedTo')}
					options={authorityUserOptions}
					value={_.toString(formState.assignedToUserProfileId)}
					onChange={changeFormState}
				/>
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate))
	};
};

export const AccountModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(AccountModalComp);
