import React, { useState } from 'react';
import './iframe.scss';

export const IFrame = ({ children, head, className, src, isPreviewMode, ...props }: any) => {
	const [contentRef, setContentRef] = useState(null);

	const changesForIframeCleaningForm = () => {
		if (document.getElementById('iframe') && document.getElementById('iframe').contentWindow) {
			const element = document.getElementById('iframe').contentWindow.document;
			if (element.getElementsByTagName('header')[0]) {
				element.getElementsByTagName('header')[0].style.display = 'none';
				if (element.getElementsByTagName('main')[0]) {
					element.getElementsByTagName('main')[0]!.style!.padding = '0';
				}
				if (element.getElementsByClassName('form-builder-wrapper')[0])
					element.getElementsByClassName('form-builder-wrapper')[0].style.marginTop = '0';
			}
		}
	};
	React.useEffect(() => {
		changesForIframeCleaningForm();
	});

	return <iframe src={src} id="iframe" className={`mt-3 ${className}`} {...props} ref={setContentRef}></iframe>;
};
