import React from 'react';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';
import { Logger } from 'src/services';

interface SearchInputProps {
	id?: string;
	label?: string;
	name?: string;
	isRequired?: boolean;
	error?: string;

	textField: string;
	value?: any;
	description?: string;
	className?: string;
	placeholder?: string;
	dataSource: any[];
	onSelect(selectedValue: any): void;
}

const pageSize = 10;

export const SearchableComboBox: React.FC<SearchInputProps> = props => {
	const [state, setState] = React.useState({
		subsetData: props.dataSource.slice(0, pageSize),
		skip: 0,
		total: props.dataSource.length
	});
	const [value, setValue] = React.useState(props.value);
	const [filteredData, setFilteredData] = React.useState(props.dataSource.slice(0, pageSize));

	const onChange = (event: any) => {
		if (event.target.value) {
			setValue(event.target.value);
			props.onSelect(event.target.value);
		} else {
			setValue(null);
			props.onSelect(null);
		}
	};

	React.useEffect(() => {
		setValue(props.value);
	}, [props.value]);

	React.useEffect(() => {
		setState({
			subsetData: props.dataSource.slice(0, pageSize),
			skip: 0,
			total: props.dataSource.length
		});
		setFilteredData(props.dataSource);
	}, [props.dataSource]);

	const customizedFilterBy = (data: any[], descriptor: FilterDescriptor | CompositeFilterDescriptor): any[] => {
		var filterDescriptor = descriptor as FilterDescriptor;
		if (filterDescriptor) {
			if (!filterDescriptor.value) {
				return data;
			}
			if (!filterDescriptor.field) {
				return data;
			}
			var fieldName = filterDescriptor.field as string;
			var searchTerms: string[] = filterDescriptor.value.match(/[^ ]+/g);
			searchTerms = searchTerms.map(t => t.toUpperCase());
			let customizedFilteredData = _.filter(data, item => {
				var fieldValue = _.get(item, fieldName);
				if (!fieldValue) {
					return false;
				}
				fieldValue = fieldValue.toUpperCase();
				var matches = 0;
				searchTerms.forEach(word => {
					if (fieldValue.includes(word)) {
						matches += 1;
					}
				});
				return matches === searchTerms.length;
			});
			return customizedFilteredData;
		}
		Logger.warn('Using default filterBy from KendoReact, might get unexpected filter result.');
		let filteredData = filterBy(data, descriptor);
		return filteredData;
	};

	const filterChange = (event: any) => {
		var newFilteredData = customizedFilterBy(props.dataSource.slice(), event.filter);
		setFilteredData(newFilteredData);
		const data = newFilteredData.slice(0, pageSize);
		setState({
			subsetData: data,
			skip: 0,
			total: newFilteredData.length
		});
	};

	const pageChange = (event: any) => {
		const skip = event.page.skip;
		const take = event.page.take;
		const newSubsetData = filteredData.slice(skip, skip + take);

		let newState = { ...state, subsetData: newSubsetData, skip: skip };
		setState(newState);
	};

	const getComboBox = () => {
		return (
			<>
				<ComboBox
					key={props.name}
					name={props.name}
					className={'w-100 ai-search'}
					data={state.subsetData}
					virtual={{
						total: state.total,
						pageSize: pageSize,
						skip: state.skip
					}}
					value={value}
					allowCustom={false}
					placeholder={props.placeholder || 'Search'}
					textField={props.textField}
					filterable={true}
					onChange={onChange}
					onFilterChange={filterChange}
					onPageChange={pageChange}
					popupSettings={{ animate: false }}
				/>
				{props.error && <div className="invalid-feedback error-block">{props.error}</div>}
				{props.description && <div className="font-size-12px-regular">{props.description}</div>}
			</>
		);
	};

	return _.isUndefined(props.id) && _.isUndefined(props.label) ? (
		getComboBox()
	) : (
		<div id={props.id} className={props.className || 'form-group' + (props.isRequired ? ' required' : '')}>
			<label htmlFor={props.id}>{props.label}</label>
			{getComboBox()}
		</div>
	);
};
