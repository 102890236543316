import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { localizationService, Resource } from '../../../../services';
import { useHistory, useParams } from 'react-router';
import { ColumnField, Dictionary, LocalStorageName, RouteProps, FeatureNames } from '@rcp/types';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState, useReduxSelector } from '../../../../redux';
import { facilityCleaningHistorySlice } from './cleaning-history-slice';
import { CleaningDateEditActionLink } from './cleaning-date-action-link';
import { Utils } from 'src/services/utils';

const FacilityCleaningHistories = (props: RouteProps) => {
	const history = useHistory();
	const params: any = useParams();
	const [queryParameters, setQueryParameters] = useState({});
	const [isSlicePathModified, setIsSlicePathModified] = useState<boolean>();
	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowCustomCleaningFormsFeature]
	);

	useEffect(() => {
		if (showCustomCleaningFormsFeature) {
			facilityCleaningHistorySlice.setApiUrlPath(Resource.CustomCleaningManifests);
		}
		setIsSlicePathModified(showCustomCleaningFormsFeature);
	}, [showCustomCleaningFormsFeature]);

	const cleaningDateToLink = (props: any) => {
		const cleaningManifest = props.dataItem as any;
		return (
			<td>
				<CleaningDateEditActionLink queryParameters={queryParameters} cleaningManifest={cleaningManifest} />
			</td>
		);
	};

	let columns = [
		ColumnField.newText('completeDate', true, cleaningDateToLink),
		ColumnField.newText('deviceNumber', true),
		ColumnField.newText('deviceDescription', true),
		ColumnField.newText('amountPumped', true),
		ColumnField.newText('submitterUserFullName', true),
		ColumnField.newText('daysLate', true)
	];

	let defaultColumns = [
		'completeDate',
		'deviceNumber',
		'deviceDescription',
		'amountPumped',
		'submitterUserFullName',
		'daysLate'
	];

	let translateColumns = ['deviceDescription'];

	const searchFacilityCleanings = (data: [], searchTerm: string) => {
		const searchColumns = ['deviceNumber', 'deviceDescription', 'submitterUserFullName'];

		const dataMatchingSearch = data.filter(x => {
			let hasMatch = false;
			for (let column of searchColumns) {
				if (Utils.isContains(searchTerm.trim(), x[column])) {
					hasMatch = true;
				}
			}

			return hasMatch;
		});

		return dataMatchingSearch as [];
	};

	const initialGridOptions: GridOption = {
		pageTitle: localizationService.getLocalizedString('facilityPortal.cleanings.cleaningHistory'),
		prefix: 'facilityPortal.cleanings',
		storageName: LocalStorageName.FacilityPortalCleaningHistories,
		showEditColumnButton: true,
		searchHint: localizationService.getLocalizedString('facilityPortal.cleaningHistory.searchHint'),
		gridUrlLocalStorageName: LocalStorageName.FacilityPortalCleaningHistoryFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FacilityPortalCleaningHistorySortOrder,
		showSearch: true,
		showAddButton: false,
		addButtonText: localizationService.getLocalizedString('haulerPortal.facilities.selectFacilities'),
		queryParameters: {} as Dictionary<string>,
		defaultSort: [],
		allColumns: columns,
		defaultColumns: defaultColumns,
		isSlicePathModified: isSlicePathModified,
		translateColumns,
		doClientPagination: true,
		doClientSearch: searchFacilityCleanings,
		doClientSideSorting: true,
		noRecordMessage: localizationService.getLocalizedString('facilityPortal.cleaningHistory.emptyCleaningHistory')
	};

	return (
		<>
			<DataGrid
				gridOption={initialGridOptions}
				restSlice={facilityCleaningHistorySlice}
				restState={(state: RootState) => state.facilityCleaningHistory}
				history={history}
				match={props.match}
			/>
		</>
	);
};
export default FacilityCleaningHistories;
