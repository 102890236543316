import React, { useState, useRef } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from 'reactstrap';
import { commentsSlice } from './comments.slice';
import { useDispatch } from 'react-redux';
import { alertService } from 'src/redux';
import { localizationService } from 'src/services';
import { reloadTimelineEventsFromServer } from '../timeline/timeline-service';
interface OwnProps {
	anchor: HTMLElement;
	show: boolean;
	toggle: () => void;
}

type Props = OwnProps;

export const Comment = (props: Props) => {
	const dispatch = useDispatch();

	const [comment, setComment] = useState('');
	const defaultTextEle = useRef<HTMLTextAreaElement>(null);
	const [commentError, setCommentError] = useState('');

	const onCommentsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};

	const onSaveBtnClick = () => {
		if (comment === '' || comment.trim() === '') {
			let errorTxt = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				'Field'
			);
			setCommentError(errorTxt);
			alertService.addError(errorTxt);
		} else {
			dispatch(
				commentsSlice.createOne(
					{ commentText: comment },
					true,
					localizationService.getLocalizedString('alertMessages.savedSuccess', 'comments.comment'),
					() => {
						props.toggle();
						reloadTimelineEventsFromServer();
					}
				)
			);
		}
	};

	const onCancelBtnClick = () => {
		props.toggle();
	};

	let hasAnchor = props.anchor instanceof HTMLElement;

	return !hasAnchor ? null : (
		<Popup anchor={props.anchor} show={props.show} animate={false} popupClass="shadow comment-modal-body">
			<div className="d-flex flex-column">
				<div className="form-group  required">
					<label htmlFor="comment"> {localizationService.getLocalizedString('screen.labels.comment')} </label>
					<textarea
						className={`form-control facility-comment ` + (commentError ? ' is-invalid' : '')}
						id="comment"
						ref={defaultTextEle}
						defaultValue={comment || ''}
						onChange={onCommentsChanged}
					/>
					{commentError && <div className="invalid-feedback">{commentError}</div>}
				</div>

				<div className="popup-modal-footer ml-auto">
					<Button color="btn ai-action" id="saveBtn" className="popup-modal-btn" onClick={onSaveBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.addComment')}
					</Button>
					<Button color="btn ai-white" id="cancelBtn" className="popup-modal-btn" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</div>
		</Popup>
	);
};

export default Comment;
