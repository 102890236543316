import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import _ from 'lodash';
import { RootState } from './configure-redux-store';

//Define redux ActionModal state with list of booleans for popover/takeover modals show or close
export interface ActionModalState {
	fogInspectionGridBatchOperationModal: boolean;
	fogFacilityGridBatchScheduleInspectionsModal: boolean;
	fogFacilityBatchUpdateModal: boolean;
	fogMapBatchScheduleInspectionsModal: boolean;
	fogIncidentGridBatchScheduleInspectionsModal: boolean;
}

export const initialActionModalState: ActionModalState = {
	fogInspectionGridBatchOperationModal: false,
	fogFacilityGridBatchScheduleInspectionsModal: false,
	fogFacilityBatchUpdateModal: false,
	fogMapBatchScheduleInspectionsModal: false,
	fogIncidentGridBatchScheduleInspectionsModal: false
};

export enum ActionModalActionType {
	fogInspectionGridBatchOperationModal = 'fogInspectionGridBatchOperationModal',
	fogFacilityGridBatchScheduleInspectionsModal = 'fogFacilityGridBatchScheduleInspectionsModal',
	fogFacilityBatchUpdateModal = 'fogFacilityBatchUpdateModal',
	fogFacilityBulkNotices = 'fogFacilityBulkNotices',
	fogDeviceCleaningNotices = 'fogDeviceCleaningNotices',
	fogMapBatchScheduleInspectionsModal = 'fogMapBatchScheduleInspectionsModal'
}

type ActionModalThunkAction = ThunkAction<any, RootState, any, Action>;

export const closeActionModal = (actionType: ActionModalActionType): ActionModalThunkAction => async (
	dispatch,
	getState
) => {
	dispatch({ type: actionType, value: false });
};

export const toggleActionModal = (actionType: ActionModalActionType): ActionModalThunkAction => async (
	dispatch,
	getState
) => {
	let state = getState().actionModals;
	let toggledValue = _.get(state, actionType, false);
	toggledValue = !toggledValue;
	dispatch({ type: actionType, value: toggledValue });
};

export const actionModalReducer = (state = initialActionModalState, action: any): ActionModalState => {
	let newState = { ...state };
	if (Object.values(ActionModalActionType).includes(action.type)) {
		_.set(newState, action.type, action.value);
	}
	return newState;
};
