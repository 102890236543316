import _ from 'lodash';

export class FileUtilService {
	getFiletype = (filename: string) => {
		return filename.split('.').pop();
	};

	isPicture = (fileType?: string) => {
		//https://docs.microsoft.com/en-us/dotnet/api/system.drawing.imaging.imageformat?view=netframework-4.8
		return _.includes(['bmp', 'emf', 'exif', 'gif', 'icon', 'jpg', 'jpeg', 'png', 'tiff', 'wmf'], fileType);
	};

	isVideo = (fileType: string) => {
		return _.includes(['avi', 'flv', 'wmv', 'mov', 'mp4'], fileType);
	};

	isAudio = (fileType: string) => {
		return _.includes(['wav', 'mp3', 'wma'], fileType);
	};

	isOfficeDocument = (fileType: string) => {
		return _.includes(['doc', 'docx'], fileType);
	};

	isSpreadsheet = (fileType: string) => {
		return _.includes(['xls', 'xlsx'], fileType);
	};

	isSlide = (fileType: string) => {
		return _.includes(['ppt', 'pptx'], fileType);
	};

	isPdf = (fileType: string) => {
		return fileType === 'pdf';
	};

	isCsv = (fileType: string) => {
		return fileType === 'csv';
	};
}

export const fileUtilService = new FileUtilService();
