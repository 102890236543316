import * as React from 'react';
import { StepperContext } from './context';
import { StepConfig, StepId } from './typings';

interface Props extends StepConfig {
	stepId: StepId;
	data?: any;
	loading?: boolean;
	disabled?: boolean;
}

const Step: React.FunctionComponent<Props> = ({ stepId, ...props }) => {
	const { createStep, removeStep, updateStep } = React.useContext(StepperContext);

	React.useEffect(() => {
		createStep(stepId, props);

		return () => removeStep(stepId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		updateStep(stepId, {
			loading: props.loading,
			title: props.title
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.loading, props.title]);

	return null;
};

export default React.memo(Step);
