import React from 'react';
import './custom-card.scss';

interface Props {
	customCardClass?: string;
	customCardHeaderClass?: string;
	customCardBodyClass?: string;
	customCardHeader?: JSX.Element;
	customCardBody?: JSX.Element;
	withHeader?: boolean;
}

export const CustomCard: React.FC<Props> = props => {
	return (
		<>
			<div className={`card custom-card mr-3 ${props.customCardClass || ''} `}>
				<div
					className={`${props.customCardHeaderClass} ${
						props.withHeader ? ' d-flex' : ' hide-header-element'
					}`}>
					{props.customCardHeader}
				</div>
				<div className={`card-body custom-card-body ${props.customCardBodyClass || ''}`}>
					{props.customCardBody}
				</div>
			</div>
		</>
	);
};
