import React, { FC } from 'react';
import { History } from 'history';
import * as LinkoTypes from '@rcp/types';
import { IppReportPackageTemplate } from '@rcp/types';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import IppReportPackageAttachmentsComponent from './attachments';
import IppReportPackageCertificationsComponent from './certifications';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import './elements.scss';
interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
}

const IppReportPackageElementsComponent: FC<Props<IppReportPackageTemplate>> = props => {
	const onCreateNew = (type: string) => {
		if (type === 'attachment') {
			const url = urlService.getIppAuthorityUrl(`${Resource.IppReportPackageReportElementTypes}/Attachments/new`);
			navigateTo(props.history, url);
		} else {
			const url = urlService.getIppAuthorityUrl(
				`${Resource.IppReportPackageReportElementTypes}/Certifications/new`
			);
			navigateTo(props.history, url);
		}
	};
	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row authority-acccount">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('ipp.reportPackage.reportTemplateElement.title')}
						</h1>
					</div>
					<div className="accordion-data-grid">
						<CollapsibleCard
							accordionType="attachments"
							accordionHeaderElements={
								<button
									className="btn ai-action mr-3 px-2 p-1"
									id="element-save"
									onClick={() => onCreateNew('attachment')}>
									{localizationService.getLocalizedString('ipp.buttons.createNew')}
								</button>
							}
							accordionHeading={localizationService.getLocalizedString(
								'ipp.reportPackage.reportTemplateElement.attachments'
							)}>
							<IppReportPackageAttachmentsComponent {...props} />
						</CollapsibleCard>
					</div>
					<div className="accordion-data-grid">
						<CollapsibleCard
							accordionType="certifications"
							accordionHeaderElements={
								<button
									className="btn ai-action mr-3 px-2 p-1"
									id="element-save"
									onClick={() => onCreateNew('certification')}>
									{localizationService.getLocalizedString('ipp.buttons.createNew')}
								</button>
							}
							accordionHeading={localizationService.getLocalizedString(
								'ipp.reportPackage.reportTemplateElement.certifications'
							)}
							className="mt-3">
							<IppReportPackageCertificationsComponent {...props} />
						</CollapsibleCard>
					</div>
				</div>
			</div>
		</>
	);
};

export default IppReportPackageElementsComponent;
