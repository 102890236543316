import React from 'react';

import * as ApiTypes from '@rcp/types';

import { DateUtilService, localizationService, Resource, urlService } from 'src/services';
import { EditCommentModalComponent as EditCommentModal } from '../comments/edit-comment';

interface OwnProps {
	event: ApiTypes.CommentDto;
}

type Props = OwnProps;

export const TimelineEventComment: React.SFC<Props> = props => {
	const [showEditCommentModal, setShowEditCommentModal] = React.useState(false);

	const onShowCommentLinkClicked = (e: any) => {
		e.preventDefault();
		setShowEditCommentModal(true);
	};

	const toggleEditCommentModal = () => {
		setShowEditCommentModal(!showEditCommentModal);
	};

	const editedOnDiv = () => {
		let lastModifiedDate = DateUtilService.toDisplayDate(props.event.lastModificationDateTimeUtc);
		let lastModifiedTime = DateUtilService.toDisplayTime(props.event.lastModificationDateTimeUtc);
		let lastModifiedUsername = props.event.lastModifierUserFullName as string;
		let editedAtArguments: string[] = [lastModifiedDate, lastModifiedTime, lastModifiedUsername];
		return props.event.lastModificationDateTimeUtc !== props.event.creationDateTimeUtc ? (
			<div className="timeline-comment-edited-datetime">
				{localizationService.getLocalizedString('comments.commentEditedAt', ...editedAtArguments)}
			</div>
		) : null;
	};

	return (
		<div>
			<div>
				{`${props.event.creatorUserFullName} `}
				<a href="#/" onClick={onShowCommentLinkClicked} id={`linkFacilityComment`}>
					{localizationService.getLocalizedString('comments.commentHref')}
				</a>
				{props.event.commentType === ApiTypes.CommentType.Hazard && (
					<span>
						{' '}
						on {localizationService.getLocalizedString('cccHazard.hazard')}
						{urlService.getDomainObjectName() !== Resource.CccHazards && (
							<span>
								{' '}
								{localizationService.getLocalizedString('screen.labels.number')}{' '}
								<a
									href={urlService.getReactAuthorityResourceUrl(
										Resource.CccHazards,
										props.event.entityId
									)}>
									{props.event.entityNumber}
								</a>
							</span>
						)}
					</span>
				)}
				{props.event.commentType === ApiTypes.CommentType.Site && (
					<span> on {localizationService.getLocalizedString('screen.labels.site')}</span>
				)}
				{props.event.commentType === ApiTypes.CommentType.FogDevice && (
					<span> on {localizationService.getLocalizedString('screen.labels.device')}</span>
				)}
				{':'}
			</div>
			<p>{props.event.commentText}</p>
			{editedOnDiv()}
			{showEditCommentModal && (
				<EditCommentModal
					showModal={showEditCommentModal}
					setShowModal={toggleEditCommentModal}
					comment={props.event}
				/>
			)}
		</div>
	);
};
