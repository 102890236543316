import React, { useState } from 'react';
import _ from 'lodash';
import {
	apiService,
	localizationService,
	localStorageService,
	navigateTo,
	navigateToRoute,
	Resource,
	tokenService,
	urlService,
	validationService
} from 'src/services';
import { alertService } from 'src/redux/alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PopoverModal, TextInput } from '../../../widgets';
import { FogFacility, LocalStorageName, Portal, PortalConfirmation, SignInPortal } from '@rcp/types';
import { ConfirmPortalInvite } from './confirm-invitation';
import { useDispatch } from 'react-redux';
import { CloseSvg } from 'src/components/svg';
import { OrganizationTypes } from 'src/constants';
import { useHistory } from 'react-router';
import { haulerAuthoritySlice } from '../../hauler/authorities/authority.slice';
import { facilitySettingsSlice } from 'src/components/authority/fog/settings/facility-portal/facilities/facilities-slice';
import { haulerPortalFacilitiesSlice } from '../../hauler/hauler-facilities/hauler-facilities-slice';
import { Translate } from 'src/components/widgets/translate/translator';
interface FormField {
	inviteCode: string;
	inviteCodeError: string;
}

const initialFormFields: FormField = {
	inviteCode: '',
	inviteCodeError: ''
};

export interface OwnProp {
	showModal: boolean;
	onCancelButtonClicked: (e?: any) => void;
}

export const InputInvitationCodeModal: React.SFC<OwnProp> = props => {
	const [formState, setFormState] = useState(initialFormFields);
	const [showInviteCodeInput, setShowInviteCodeInput] = useState(true);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [newlyInvitedFacility, setNewlyInvitedFacility] = useState<FogFacility>({});

	const [portalConfirmationDetail, setPortalConfirmationDetail] = useState<PortalConfirmation>(
		{} as PortalConfirmation
	);
	const history = useHistory();

	const dispatch = useDispatch();
	const isFacilityPortal = urlService.isFacilityPortal();

	//TODO: If the . is not included the output for OrganizationTypes.facility appears as [object Object]
	const invitedEntity = _.toLower(
		isFacilityPortal ? `${OrganizationTypes.facility}` : `${OrganizationTypes.authority}`
	);

	const toggleModal = (e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		props.onCancelButtonClicked();
	};

	const closeModalAndRefreshPage = () => {
		if (isFacilityPortal) {
			dispatch(facilitySettingsSlice.reload());
		} else {
			dispatch(haulerAuthoritySlice.reload());
			dispatch(haulerPortalFacilitiesSlice.reload());
		}
		toggleModal();
	};

	// Save Modal
	const submitInviteCode = () => {
		if (isFormValidateForInvite()) {
			let url = urlService.getAuthorityResourcesApiUrl(
				`${isFacilityPortal ? Resource.FogFacilities : Resource.Authorities}/${Resource.Invite}/${
					formState.inviteCode
				}`
			);
			apiService
				.getResource<PortalConfirmation>(url)
				.then((data: PortalConfirmation) => {
					setPortalConfirmationDetail(data);
					setShowInviteCodeInput(false);
				})
				.catch(err => {
					if (err && typeof err.message == 'string') alertService.addError(err.message);
				});
		}
	};

	const confirmNewAccess = async () => {
		const url = isFacilityPortal
			? urlService.getAuthorityResourcesApiUrl(
					`${Resource.FogFacilities}/${Resource.Register}/${formState.inviteCode}`
			  )
			: urlService.getAuthorityResourcesApiUrl(Resource.ManageAuthorities);
		try {
			if (isFacilityPortal) {
				let invitedFacility = await apiService.httpGet(url);
				setNewlyInvitedFacility(invitedFacility);
				setShowConfirmationModal(true);
			} else {
				await apiService.httpPatch(url, {
					inviteCode: formState.inviteCode,
					creatorOrganizationId: portalConfirmationDetail.creatorOrganizationId
				});

				alertService.addSuccess(localizationService.getLocalizedString('haulerPortal.newAuthorityAdded'));
				closeModalAndRefreshPage();
			}
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const isFormValidateForInvite = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(newState, 'inviteCode', 'inviteCodeError');

		setFormState(newState);
		const isFromValid = !validationService.hasError(newState, 'inviteCodeError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const renterInviteCode = () => {
		setFormState({
			...formState,
			inviteCode: '',
			inviteCodeError: ''
		});
		setShowInviteCodeInput(true);
	};

	const closeBtn = (isConfirmationModal?: boolean) => {
		return (
			<button
				className="close"
				onClick={(e?: any) => (isConfirmationModal ? closeModalAndRefreshPage() : toggleModal(e))}>
				<CloseSvg />
			</button>
		);
	};

	const enterInviteCodeModalContent = () => {
		return (
			<>
				<ModalHeader tag="h1" close={closeBtn()}>
					<Translate>{localizationService.getLocalizedString('screen.labels.inviteCode')}</Translate>
				</ModalHeader>
				<ModalBody>
					<p>
						<Translate>
							{localizationService.getLocalizedString(
								'authentication.enterInviteCodeToAdd',
								invitedEntity
							)}
						</Translate>
					</p>
					<TextInput
						id="invite-code"
						name="inviteCode"
						label={localizationService.getLocalizedString('haulerPortal.inviteCode')}
						onChange={changeFormState}
						value={formState.inviteCode}
						error={formState.inviteCodeError}
						isRequired={true}
					/>
				</ModalBody>
				<ModalFooter>
					<button className="btn ai-action" id="submit-btn" onClick={submitInviteCode}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.submit')}</Translate>
					</button>
					<button className="btn ai-white" id="cancel-btn" onClick={toggleModal}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.cancel')}</Translate>
					</button>
				</ModalFooter>
			</>
		);
	};

	const redirectToNewFacilityPortal = (e?: any) => {
		toggleModal(e);
		window.location.href = urlService.getLandingPortalUrlForFacilityPortalFromFog(newlyInvitedFacility, true);
	};

	const confirmInvitationDetails = () => {
		return (
			<>
				<ModalHeader tag="h1" close={closeBtn()}>
					<Translate>{localizationService.getLocalizedString('haulerPortal.isThisCorrect')}</Translate>
				</ModalHeader>
				<ModalBody>
					<ConfirmPortalInvite
						portalConfirmationDetail={portalConfirmationDetail}
						isFacilityPortal={isFacilityPortal}
						onConfirmClick={confirmNewAccess}
						reEnterInviteCode={renterInviteCode}
						doNotShowTitle={true}
					/>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</>
		);
	};

	const confirmFacilityPortalRedirection = () => {
		return (
			<>
				<ModalHeader tag="h1" toggle={(e?: any) => toggleModal(e)} close={closeBtn(true)}>
					<Translate>
						{localizationService.getLocalizedString('facilityPortal.settings.facilities.facilityAdded')}
					</Translate>
				</ModalHeader>
				<ModalBody>
					<p>
						<Translate>
							{localizationService.getLocalizedString(
								'facilityPortal.settings.facilities.facilityAddedDesc'
							)}
						</Translate>
					</p>
				</ModalBody>
				<ModalFooter>
					<button className={`btn ai-action`} id="redirect-new-fp" onClick={redirectToNewFacilityPortal}>
						<Translate>
							{localizationService.getLocalizedString(
								'facilityPortal.settings.facilities.goToNewFacility'
							)}
						</Translate>
					</button>
					<button className="btn ai-white" id="cancel-redirect-btn" onClick={closeModalAndRefreshPage}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.close')}</Translate>
					</button>
				</ModalFooter>
			</>
		);
	};

	return (
		<Modal
			isOpen={props.showModal}
			toggle={(e?: any) => toggleModal(e)}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}>
			{showInviteCodeInput
				? enterInviteCodeModalContent()
				: showConfirmationModal
				? confirmFacilityPortalRedirection()
				: confirmInvitationDetails()}
		</Modal>
	);
};
