import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MicroEmitter from 'micro-emitter';
import SignaturePad from 'signature_pad';
import { localizationService } from 'src/services';

import './signature-modal.scss';
import { CloseSvg } from '../svg';
import { EmitterEvents } from 'src/constants';
import { Translate } from '../widgets/translate/translator';
let emitter = new MicroEmitter();
if (document) {
	(document as any).emit = (type: string, imageElement: HTMLImageElement) => emitter.emit(type, imageElement);
}

//For typescript, caller use openSignaturePad(IMG_ID), or Javascript use `document.emit('OpenSignaturePad', 'IMG_ID');`
export const openSignaturePad = (targetImageElementId: string) => {
	emitter.emit(EmitterEvents.OpenSignaturePadEvent, targetImageElementId);
};

export const SignatureModal: React.FC = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [targetImageElement, setTargetImageElement] = React.useState<HTMLImageElement | null>(null);

	let canvas: HTMLCanvasElement;
	let signaturePad: SignaturePad;

	const clearSignaturePad = () => {
		if (signaturePad) {
			signaturePad.clear();
		}
	};

	const resizeCanvas = () => {
		let data = signaturePad.toDataURL();

		if (canvas && canvas.parentElement) {
			canvas.width = canvas.parentElement.offsetWidth - 60; //x-axis has 30px padding
			canvas.height = canvas.offsetWidth / 3;

			if (signaturePad) {
				signaturePad.fromDataURL(data, { width: canvas.width, height: canvas.height });
			}
		}
	};

	const initialSignaturePad = () => {
		if (canvas) {
			signaturePad = new SignaturePad(canvas);
			signaturePad.minWidth = 1.25;
			signaturePad.maxWidth = 1.25;
			resizeCanvas();
			signaturePad.on();
		}
	};

	const openSignatureModal = (imageElement: HTMLImageElement) => {
		setTargetImageElement(imageElement);
		setIsOpen(true);
	};

	const resetSignatureModal = () => {
		setTargetImageElement(null);
		setIsOpen(false);
	};

	React.useEffect(() => {
		emitter.addListener(EmitterEvents.OpenSignaturePadEvent, openSignatureModal);
		emitter.on(EmitterEvents.OpenSignaturePadEvent, openSignatureModal);
		if (signaturePad) {
			signaturePad.on();
		}

		return () => {
			emitter.removeListener(EmitterEvents.OpenSignaturePadEvent, openSignatureModal);
			emitter.off(EmitterEvents.OpenSignaturePadEvent, openSignatureModal);
			if (signaturePad) {
				signaturePad.off();
			}
		};
	}, []);

	React.useEffect(() => {
		if (isOpen) {
			window.addEventListener('resize', resizeCanvas);
			window.addEventListener('orientationchange', resizeCanvas);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const applySignature = () => {
		if (signaturePad) {
			let imgDataUrl = signaturePad.toDataURL('image/png');
			if (targetImageElement) {
				targetImageElement.src = signaturePad.isEmpty() ? '' : imgDataUrl;
				targetImageElement.style.opacity = '1';
			} else {
				throw new Error(`Target HTMLImageElement is null`);
			}
		}
		resetSignatureModal();
	};

	const closeBtn = (
		<button className="close" onClick={resetSignatureModal}>
			<CloseSvg />
		</button>
	);

	return (
		<Modal isOpen={isOpen} centered={true} toggle={resetSignatureModal} className="signature-modal">
			<ModalHeader close={closeBtn} tag="h1">
				<Translate>{localizationService.getLocalizedString('screen.labels.drawSignature')}</Translate>
			</ModalHeader>
			<ModalBody>
				<canvas
					ref={(canvasRef: HTMLCanvasElement) => {
						canvas = canvasRef;
						initialSignaturePad();
					}}
					className="signature-canvas"
				/>
			</ModalBody>
			<ModalFooter>
				<Button onClick={clearSignaturePad} className="mr-auto btn btn-btn ai-white">
					<Translate>{localizationService.getLocalizedString('screen.buttons.clear')}</Translate>
				</Button>
				<Button onClick={applySignature} className="btn btn-btn ai-action">
					<Translate>{localizationService.getLocalizedString('screen.buttons.apply')}</Translate>
				</Button>
				<Button onClick={resetSignatureModal} className="btn btn-btn ai-white">
					<Translate>{localizationService.getLocalizedString('screen.buttons.cancel')}</Translate>
				</Button>
			</ModalFooter>
		</Modal>
	);
};
