import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import * as ApiTypes from '@rcp/types';
import { alertService } from 'src/redux';
import { localizationService } from 'src/services';
import { PopoverModal, DeleteModal } from '../../components/widgets';
import { commentsSlice } from './comments.slice';
import { reloadTimelineEventsFromServer } from '../timeline/timeline-service';

interface OwnProps {
	showModal: boolean;
	setShowModal: () => void;
	comment: ApiTypes.CommentDto;
}

type Props = OwnProps;

export const EditCommentModalComponent: React.SFC<Props> = props => {
	const [comment, setComment] = React.useState(props.comment.commentText as string);
	const defaultTextEle = useRef<HTMLTextAreaElement>(null);
	const [toggleDeleteCommentModal, setToggleDeleteCommentModal] = React.useState(false);
	const [commentError, setCommentError] = React.useState('');

	const dispatch = useDispatch();

	const onCommentsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};
	const closeModal = () => {
		props.setShowModal();
	};

	const onSaveBtnClick = () => {
		if (comment === '' || comment.trim() === '') {
			let errorTxt = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				'Field'
			);
			setCommentError(errorTxt);
			alertService.addError(errorTxt);
		} else {
			dispatch(
				commentsSlice.patchOne(
					props.comment.entityCommentId as number,
					{ commentText: comment },
					undefined,
					localizationService.getLocalizedString('alertMessages.updateSuccess', 'comments.comment'),
					() => {
						reloadTimelineEventsFromServer();
					}
				)
			);

			props.setShowModal();
		}
	};

	const onCancelBtnClick = () => {
		props.setShowModal();
	};

	const deleteComment = async () => {
		setToggleDeleteCommentModal(false);

		if (props.comment) {
			dispatch(
				commentsSlice.deleteOne(
					props.comment.entityCommentId as number,
					undefined,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'comments.comment'),
					() => {
						reloadTimelineEventsFromServer();
					}
				)
			);
		}
		props.setShowModal();
	};

	const modalFooterDiv = () => {
		return (
			<>
				<button
					className="btn ai-secondary-delete"
					onClick={() => {
						setToggleDeleteCommentModal(true);
					}}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
				<div className="ml-auto">
					<button className="btn ai-save" onClick={onSaveBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div>
			<PopoverModal
				className="popup-modal-edit"
				showModal={props.showModal}
				title={localizationService.getLocalizedString('comments.editCommentModalTitle')}
				footer={modalFooterDiv()}
				cancel={closeModal}>
				<div className="form-group font-size-16px-regular required">
					<label htmlFor="comment"> {localizationService.getLocalizedString('screen.labels.comment')} </label>
					<textarea
						className={`form-control facility-comment` + (commentError ? ' is-invalid' : '')}
						id="comment"
						ref={defaultTextEle}
						defaultValue={comment || props.comment.commentText}
						onChange={onCommentsChanged}
					/>
					{commentError && <div className="invalid-feedback">{commentError}</div>}
				</div>
			</PopoverModal>
			{toggleDeleteCommentModal && (
				<DeleteModal
					key="confirmDeleteCommentModal"
					title={localizationService.getLocalizedString('comments.deleteCommentTitle')}
					message={localizationService.getLocalizedString('comments.deleteCommentMessage')}
					showModal={toggleDeleteCommentModal}
					onCancelButtonClick={() => {
						setToggleDeleteCommentModal(false);
					}}
					onOkayButtonClick={deleteComment}
					okayButtonText={localizationService.getLocalizedString('comments.deleteButton')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
