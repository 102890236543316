import { LookupRow } from '../lookup-types';
import { CustomFieldDefinition, LookupType, Lookup } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';
import { localizationService } from 'src/services';

export class CustomFieldDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		return apiData as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		return rowToUpdate;
	}

	toLookupRowsFromAPI(apiData: any): LookupRow[] {
		let customFields = apiData.result as CustomFieldDefinition[];
		apiData.result === undefined ? (customFields = apiData) : (customFields = apiData.result);
		return customFields.map(field => {
			return {
				lookupId: field.customFieldId,
				isActive: true,
				code: field.fieldLabel,
				isInUse: field.isInUse,
				usedCount: field.usedCount,
				widgetCount: field.widgetCount,
				codeDescription: localizationService.getLocalizedString(
					'authoritySetting.typeLabel.' + field.fieldType
				),
				error: '',
				position: field.position
			};
		});
	}
	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): LookupRow {
		newValue.lookupType = this.lookupType;
		return newValue;
	}

	toApiListFromLookUpRow(existingLookupRows: Lookup[]): CustomFieldDefinition[] {
		return existingLookupRows.map((field: Lookup) => {
			return {
				customFieldId: field.lookupId,
				isActive: true,
				fieldLabel: field.code,
				isImportKey: field.isInUse,
				usedCount: field.usedCount,
				widgetCount: field.widgetCount,
				fieldType: field.codeDescription,
				position: field.position
			};
		});
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
