import { Action } from 'redux';

import { IppReportPackage } from '@rcp/types';

export enum PackageReviewActionType {
	LoadPackageReviewRequest = 'loadPackageReviewRequest',
	LoadPackageReviewSuccess = 'loadPackageReviewSuccess',
	DownloadPackageReviewRequest = 'DownloadPackageReviewRequest',
	DownloadPackageReviewSuccess = 'DownloadPackageReviewSuccess',
	SubmissionReviewRequest = 'SubmissionReviewRequest',
	SubmissionReviewSuccess = 'SubmissionReviewSuccess',
	RepudiationReviewRequest = 'RepudiationReviewRequest',
	RepudiationReviewSuccess = 'RepudiationReviewSuccess',
	AcceptRejectReviewRequest = 'AcceptRejectReviewRequest',
	AcceptRejectReviewSuccess = 'AcceptRejectReviewSuccess'
}

export interface LoadPackageReviewRequest extends Action {
	type: PackageReviewActionType.LoadPackageReviewRequest;
}

export interface LoadPackageReviewSuccess extends Action {
	type: PackageReviewActionType.LoadPackageReviewSuccess;
	reportPackage: IppReportPackage;
}

export type PackageReviewAction =
	| LoadPackageReviewRequest
	| LoadPackageReviewSuccess
	| DownloadPackageReviewRequest
	| DownloadPackageReviewSuccess
	| SubmissionReviewRequest
	| SubmissionReviewSuccess
	| RepudiationReviewRequest
	| RepudiationReviewSuccess
	| AcceptRejectReviewRequest
	| AcceptRejectReviewSuccess;

export const loadPackageReviewRequest = (): LoadPackageReviewRequest => ({
	type: PackageReviewActionType.LoadPackageReviewRequest
});

export const loadPackageReviewSuccess = (reportPackage: IppReportPackage): LoadPackageReviewSuccess => ({
	type: PackageReviewActionType.LoadPackageReviewSuccess,
	reportPackage
});

export interface DownloadPackageReviewRequest extends Action {
	type: PackageReviewActionType.DownloadPackageReviewRequest;
}

export interface DownloadPackageReviewSuccess extends Action {
	type: PackageReviewActionType.DownloadPackageReviewSuccess;
}

export const downloadPackageReviewRequest = (): DownloadPackageReviewRequest => ({
	type: PackageReviewActionType.DownloadPackageReviewRequest
});

export const downloadPackageReviewSuccess = (): DownloadPackageReviewSuccess => ({
	type: PackageReviewActionType.DownloadPackageReviewSuccess
});

export interface SubmissionReviewRequest extends Action {
	type: PackageReviewActionType.SubmissionReviewRequest;
}

export interface SubmissionReviewSuccess extends Action {
	type: PackageReviewActionType.SubmissionReviewSuccess;
}

export const submissionReviewRequest = (): SubmissionReviewRequest => ({
	type: PackageReviewActionType.SubmissionReviewRequest
});

export const submissionReviewSuccess = (): SubmissionReviewSuccess => ({
	type: PackageReviewActionType.SubmissionReviewSuccess
});

export interface RepudiationReviewRequest extends Action {
	type: PackageReviewActionType.RepudiationReviewRequest;
}

export interface RepudiationReviewSuccess extends Action {
	type: PackageReviewActionType.RepudiationReviewSuccess;
}

export const repudiationReviewRequest = (): RepudiationReviewRequest => ({
	type: PackageReviewActionType.RepudiationReviewRequest
});

export const repudiationReviewSuccess = (): RepudiationReviewSuccess => ({
	type: PackageReviewActionType.RepudiationReviewSuccess
});

export interface AcceptRejectReviewRequest extends Action {
	type: PackageReviewActionType.AcceptRejectReviewRequest;
}

export interface AcceptRejectReviewSuccess extends Action {
	type: PackageReviewActionType.AcceptRejectReviewSuccess;
}
export const acceptRejectReviewRequest = (): AcceptRejectReviewRequest => ({
	type: PackageReviewActionType.AcceptRejectReviewRequest
});

export const acceptRejectReviewSuccess = (): AcceptRejectReviewSuccess => ({
	type: PackageReviewActionType.AcceptRejectReviewSuccess
});
