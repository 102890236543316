import { RestSlice } from '../../../../redux/rest.slice';
import { DashboardWidget } from '@rcp/types';
import { Resource, localizationService } from 'src/services';

export const dashboardWidgetSlice = new RestSlice<DashboardWidget>(
	localizationService.getLocalizedString('dashboard.dashboardWidgetSlice'),
	Resource.Dashboard
);

export default dashboardWidgetSlice.rests.reducer;
