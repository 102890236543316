import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { ColumnField, Dictionary, LocalStorageName, RouteProps } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import { DataGrid, GridOption } from 'src/features';
import { facilitySettingsSlice } from './facilities-slice';
import { RootState } from 'src/redux';
import { AuthorityDetailsActionLink } from 'src/components/service-provider/facility/authority-details/authority-details';
import { FacilityDetailsActionLink } from './facility-details-cell';
import { Utils } from 'src/services/utils';
import { InputInvitationCodeModal } from 'src/components/service-provider/shared/portal-registration/enter-invitation-code-modal';
import { Translate } from 'src/components/widgets/translate/translator';

const FacilitySettings = (props: RouteProps) => {
	const [showAddFacilityModal, setShowAddFacilityModal] = useState<boolean>(false);
	const [isFacilityPortalBlocked, setIsFacilityPortalBlocked] = useState(false);
	const [showInviteCodeModal, setShowInviteCodeModal] = useState(false);
	const history = useHistory();

	useEffect(() => {
		Utils.getFacilityPortalStatus(setIsFacilityPortalBlocked);
	}, []);

	const authorityDetails = (row: any) => {
		const authorityDetails = _.pick(row.dataItem, [
			'authorityName',
			'addressLine1',
			'addressLine2',
			'cityName',
			'jurisdictionCode',
			'zipCode',
			'phoneNumber'
		]);
		return (
			<td>
				<AuthorityDetailsActionLink
					linkText={authorityDetails.authorityName}
					authorityDetails={authorityDetails}
				/>
			</td>
		);
	};

	const subHeading = () => {
		return (
			<p className="font-size-16px-regular">
				<Translate>
					{localizationService.getLocalizedString(
						'facilityPortal.settings.facilities.facilitiesSubHeadingPart1'
					)}
				</Translate>
				<a
					className="ai-link cursor-pointer"
					id="invite-code-modal-subheading"
					onClick={(e?: any) => setShowInviteCodeModal(true)}>
					<Translate>
						{localizationService.getLocalizedString(
							'facilityPortal.settings.facilities.facilitiesSubHeadingPart2'
						)}
					</Translate>
				</a>
				<Translate>
					{localizationService.getLocalizedString(
						'facilityPortal.settings.facilities.facilitiesSubHeadingPart3'
					)}
				</Translate>
			</p>
		);
	};

	const facilityDetails = (row: any) => {
		const facilityDetails = _.pick(row.dataItem, [
			'facilityName',
			'facilityId',
			'facilityAddressLine1',
			'facilityAddressLine2',
			'facilityCityName',
			'facilityJurisdictionCode',
			'facilityZipCode',
			'facilityPhoneNumber'
		]);
		return (
			<td>
				<FacilityDetailsActionLink
					linkText={facilityDetails.facilityName}
					facilityDetails={{ ...facilityDetails }}
					isFacilityPortalBlocked={isFacilityPortalBlocked}
				/>
			</td>
		);
	};

	let columns = [
		ColumnField.newText('facilityName', true, facilityDetails),
		ColumnField.newText('facilityAddressLine1'),
		ColumnField.newText('facilityAddressLine2'),
		ColumnField.newText('facilityCityName'),
		ColumnField.newText('authorityName', true, authorityDetails)
	];

	let defaultColumns = [
		'facilityName',
		'facilityAddressLine1',
		'facilityAddressLine2',
		'facilityCityName',
		'authorityName'
	] as any[];

	const initialGridOptions: GridOption = {
		pageTitle: localizationService.getLocalizedString('facilityPortal.settings.facilities.facilities'),
		prefix: 'facilityPortal.settings.facilities',
		storageName: LocalStorageName.FacilityPortalFacilitiesGrid,
		showEditColumnButton: true,
		sortOrderLocalStorageName: LocalStorageName.FacilityPortalFacilitiesGridSortOrder,
		showBackButton: true,
		showSearch: true,

		queryParameters: { sort: 'facilityName asc' } as Dictionary<string>,
		defaultSort: [{ field: 'facilityName', dir: 'asc' }],
		allColumns: columns,
		defaultColumns: defaultColumns,
		subHeading: urlService.isAdministrator() ? subHeading() : undefined
	};

	return (
		<>
			<DataGrid
				gridOption={initialGridOptions}
				restSlice={facilitySettingsSlice}
				restState={(state: RootState) => state.facilities}
				history={history}
				match={props.match}
			/>
			{showInviteCodeModal && (
				<InputInvitationCodeModal
					showModal={showInviteCodeModal}
					onCancelButtonClicked={setShowInviteCodeModal}
				/>
			)}
		</>
	);
};
export default FacilitySettings;
