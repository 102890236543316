import * as React from 'react';
import FormData from 'form-data';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, urlService, Resource, apiService, validationService } from 'src/services';
import { alertService } from 'src/redux';
import { ApiError, ImportStep1Data, ImportSteps, ImportSummary, UploadMaximumSize10MB } from '@rcp/types';

interface Props {
	vertical?: boolean;
}

const Step1: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [selectedFile, setSelectedFile] = React.useState<File>();
	const [invalidFileErrorMessage, setInvalidFileErrorMessage] = React.useState('');

	React.useEffect(() => {
		const step1Data: ImportStep1Data = stepperContext.getData(ImportSteps.STEP1);
		if (step1Data) {
			setSelectedFile(step1Data.selectedFile);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadFiles = async () => {
		let step1State = { ...stepperContext.getStep(ImportSteps.STEP1), loading: true };
		stepperContext.updateStep(ImportSteps.STEP1, step1State);
		const url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Imports?dryRun=1`;
		let formData = new FormData();
		formData.append('files', selectedFile);
		const headers = {};

		return await apiService.postFormData(url, formData, headers);
	};

	const downloadExample = async (e: any) => {
		e.preventDefault();

		let url = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings/Imports/Example');
		return await apiService.downloadByUrl(url);
	};

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();

		if (selectedFile === undefined || selectedFile === null) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('importFile.noFileSelectedMessage'));
			alertService.addError(localizationService.getLocalizedString('importFile.noFileSelectedMessage'));
			return;
		}

		let isFileInvalid = false;

		if (!validationService.validateUploadSize(selectedFile.size, UploadMaximumSize10MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit10MB')
			);
			isFileInvalid = true;
		}
		let filenameArray = selectedFile.name.split('.');
		let extension = filenameArray[filenameArray.length - 1];
		if (extension !== 'xlsx' && extension !== 'xls') {
			alertService.addError(localizationService.getLocalizedString('importFile.excelFileFormatRequired'));
			isFileInvalid = true;
		}

		if (isFileInvalid) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('importFile.invalidFileErrorMessage'));
			return;
		}

		uploadFiles()
			.then((response: ImportSummary) => {
				stepperContext.resolve({ selectedFile: selectedFile, ...response });
			})
			.catch((error: any) => {
				if (error instanceof ApiError) {
					if (error.statusCode === 413) {
						alertService.addError(
							localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
						);
					} else {
						alertService.addError(error.message);
					}
				} else {
					alertService.addError(error.message.message);
				}

				let step1State = { ...stepperContext.getStep(ImportSteps.STEP1), loading: false };
				stepperContext.updateStep(ImportSteps.STEP1, step1State);
			});
	};

	const resectAllStepStates = () => {
		let step1State = { ...stepperContext.getStep(ImportSteps.STEP1), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP1, step1State);
		let step2State = { ...stepperContext.getStep(ImportSteps.STEP2), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP2, step2State);
		let step3State = { ...stepperContext.getStep(ImportSteps.STEP3), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP3, step3State);
	};

	const onChangeHandler = (event: any) => {
		event.preventDefault();

		const inputElement = event.target as HTMLInputElement;
		const files = inputElement.files as FileList;

		if (files == null || files.length < 1) {
			return;
		}
		setInvalidFileErrorMessage('');
		resectAllStepStates();

		setSelectedFile(files[0]);
	};

	return (
		<StepperContent
			id="step1ChooseFile"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-group">
				{localizationService.getLocalizedString('importFile.step1DownLoadMessagePart1')}{' '}
				<a href="#/" onClick={downloadExample}>
					{localizationService.getLocalizedString('importFile.step1DownLoadMessagePart2')}
				</a>{' '}
				{localizationService.getLocalizedString('importFile.step1DownLoadMessagePart3')}
			</div>

			<div className="ai-file-input">
				<div className="file-input-group">
					<label className="btn ai-secondary" htmlFor="btnFileImport" aria-describedby="file-input-help">
						{localizationService.getLocalizedString('importFile.chooseFile')}
					</label>
					<label className="file-name">{selectedFile && selectedFile.name}</label>
				</div>
				<div id="file-input-help" className="form-text ai-form-help">
					<span className="ai-required">{invalidFileErrorMessage}</span>
					{invalidFileErrorMessage && <>&nbsp;</>}
					{localizationService.getLocalizedString('importFile.step1ImportInstruction')}
				</div>
				<input
					type="file"
					id="btnFileImport"
					className="file-input"
					onChange={onChangeHandler}
					accept=".xls,.xlsx"
					disabled={stepperContext.isLoading()}
				/>
			</div>
		</StepperContent>
	);
};

export default Step1;
