import React from 'react';
import { FogFacility, ApiError, AttachmentType, UploadMaximumSize50MB } from '@rcp/types';
import { apiService, localizationService, urlService, Resource, validationService } from 'src/services';
import { alertService } from 'src/redux';
import _ from 'lodash';

interface Props {
	facility?: FogFacility;
	attachmentType?: AttachmentType;
	ownerId?: number;
	uploadCallBack: () => void;
}

export const AddAttachment: React.SFC<Props> = props => {
	const getUploadUrl = (attachmentType?: AttachmentType, ownerId?: number) => {
		let url = '';
		let facilityId = urlService.getFogFacilityId();
		if (facilityId > 0) {
			let facilityApiUrl = urlService.getAuthorityResourceApiUrl(Resource.FogFacilities, facilityId);
			url = `${facilityApiUrl}/${Resource.Attachments}/Upload`;
		} else {
			let deviceId = urlService.getFogDeviceId();
			let apiUrl = urlService.getAuthorityResourceApiUrl(Resource.Devices, deviceId);
			url = `${apiUrl}/${Resource.Attachments}/Upload`;
		}

		let params = [];

		if (attachmentType) {
			params.push(`attachmentType=${attachmentType}`);
		}
		if (ownerId) {
			params.push(`ownerId=${ownerId}`);
		}

		var paramStr = params.join('&');
		return `${url}?${paramStr}`;
	};

	const onAddClicked = (e: any) => {
		e.preventDefault();
		let addInput = document.getElementById('addAttachmentInput');
		if (addInput) {
			addInput.click();
		}
	};

	const onFileSelected = (e: any) => {
		e.preventDefault();

		const inputElement = e.target as HTMLInputElement;
		const files = inputElement.files as FileList;
		if (files == null || files.length < 1) {
			return;
		}

		let totalFileSize = 0;
		let hasVideo = false;
		for (let x = 0; x < files.length; x++) {
			totalFileSize += files[x].size;
			let fileType = _.toLower(files[x].type);
			if (fileType && fileType.startsWith('video/')) {
				hasVideo = true;
			}
		}
		if (hasVideo) {
			alertService.addError(localizationService.getLocalizedString('attachments.uploadVideoIsUnsupported'));
			inputElement.files = null;
			return;
		}

		if (!validationService.validateUploadSize(totalFileSize, UploadMaximumSize50MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			inputElement.files = null;
			return;
		}

		uploadFiles(files)
			.then(() => {
				props.uploadCallBack();
				//clear the file selector to avoid automatically re-upload when browser go back
				let addFile = window.document.getElementById('#addAttachmentInput');
				if (addFile) {
					addFile.setAttribute('value', '');
				}
				alertService.addSuccess(localizationService.getLocalizedString('attachments.attachmentSuccess'));
			})
			.catch((error: ApiError) => {
				if (error.statusCode === 413) {
					alertService.addError(
						localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
					);
				} else {
					alertService.addError(error.message);
				}
			});
	};

	const uploadFiles = async (files: FileList) => {
		const url = getUploadUrl(props.attachmentType, props.ownerId);
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		let formData = new FormData();
		for (let x = 0; x < files.length; x++) {
			formData.append('files', files[x]);
		}

		return apiService.postFormData(url, formData, headers);
	};

	return (
		<span className="w-50">
			<a href="#/" className="ai-add-link" onClick={onAddClicked}>
				{localizationService.getLocalizedString('attachments.addNew')}&#8230;
			</a>
			<input
				type="file"
				id="addAttachmentInput"
				name="files"
				className="add-attachment-input"
				onChange={onFileSelected}
				multiple
			/>
		</span>
	);
};
