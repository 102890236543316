import React, { FC } from 'react';
import { History } from 'history';
import { ColumnField, FilterType, LocalStorageName, InspectionFormTemplate, RouteProps } from '@rcp/types';
import { DataGrid, GridOption } from '../../../features/data-grid/data-grid';
import { RootState, RestSlice, useReduxSelector } from 'src/redux';
import { localizationService } from 'src/services';
import _ from 'lodash';
import { inspectionFormTemplatesSlice } from './inspection-template-slice';
import { InspectionTemplateModal } from './inspection-template-editor';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const EditActionLink: React.FC<{ model: InspectionFormTemplate }> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};
	const toggleModal = () => {
		setShowModal(!showModal);
	};
	return (
		<>
			<a href="#/" className="ai-link" onClick={e => onEditClicked(e)}>
				{props.model.templateName}
			</a>
			{showModal && (
				<InspectionTemplateModal
					showModal={showModal}
					inspectionTemplate={props.model}
					toggleModal={toggleModal}
					isCreate={false}
				/>
			)}
		</>
	);
};

export const InspectionFormTemplateGrid: FC<Props<InspectionFormTemplate>> = props => {
	const currentUser = useReduxSelector(state => state.userProfile);
	const [showAddModal, setAddShowModal] = React.useState(false);
	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};
	const templateNameCell = (props: any) => {
		const template = props.dataItem as InspectionFormTemplate;
		return (
			<td>
				<EditActionLink model={template} />
			</td>
		);
	};
	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};
	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('inspectionFormTemplates.title'),
		prefix: 'inspectionFormTemplates',
		storageName: LocalStorageName.InspectionFormTemplateGrid,

		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('inspectionFormTemplates.addTemplate'),
		addModalComponent: (
			<>
				{showAddModal && (
					<InspectionTemplateModal showModal={showAddModal} toggleModal={toggleAddModal} isCreate={true} />
				)}
			</>
		),
		toggleAddModal: toggleAddModal,

		queryParameters: { includeInactive: '1' },
		defaultSort: [{ field: 'templateName', dir: 'asc' }],
		allColumns: [
			new ColumnField('templateName', FilterType.Text, templateNameCell),
			new ColumnField('isInternalOnly', FilterType.Boolean, localizeCellValue),
			new ColumnField('isActive', FilterType.Boolean, localizeCellValue)
		],
		defaultColumns: ['templateName', 'isInternalOnly', 'isActive'],
		showBackButton: true
	};

	return (
		<>
			{currentUser.userProfile.isInternalAccount ? (
				<DataGrid
					history={props.history}
					match={props.match}
					restSlice={inspectionFormTemplatesSlice}
					restState={(state: RootState) => state.inspectionFormTemplates}
					gridOption={initialGridOption}
				/>
			) : (
				<AccessDeniedPage />
			)}
		</>
	);
};
