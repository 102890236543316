import { ColumnField, Dictionary, FilterType, LocalStorageName, RegulatoryProgramName, RouteProps } from '@rcp/types';
import React from 'react';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import _ from 'lodash';
import { DataGrid, GridOption } from 'src/features';
import { customFormSlice } from './custom-forms-slice';
import { useHistory } from 'react-router';
import { RootState } from 'src/redux';
import { Utils } from 'src/services/utils';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props extends RouteProps {}

export const AuthoritySettingsCustomForms: React.FC<Props> = props => {
	let history = useHistory();

	const getCustomFormLink = (data: any) => {
		const { templateName, customFormType } = data.dataItem;
		return (
			<td>
				<a
					href="#/"
					onClick={e => {
						let url = urlService.getReactAuthorityResourcePath(
							Resource.CustomFormsSettings,
							customFormType
						);
						let queryDict = urlService.toQueryDictionary();
						_.unset(queryDict, 'sort');
						let queryString = urlService.toQueryString(queryDict);
						if (!_.isEmpty(queryString)) {
							url += `?${queryString}`;
						}
						navigateTo(history, url, e);
					}}>
					{templateName}
				</a>
			</td>
		);
	};
	const gridOptions: GridOption = {
		pageTitle: '',
		prefix: 'customForm',
		storageName: LocalStorageName.CustomForm,
		sortOrderLocalStorageName: LocalStorageName.CustomFormSort,
		queryParameters: {
			sort: 'templateName asc'
		},
		defaultSort: [{ field: 'templateName', dir: 'asc' }],
		allColumns: [
			new ColumnField('templateName', FilterType.Text, getCustomFormLink),
			new ColumnField('lastModificationDateTime', FilterType.Date, Utils.formatDate)
		],
		hideCount: true,
		defaultColumns: ['templateName', 'lastModificationDateTime'],
		doNotApplyMaxTable: true,
		removePageHeader: true
	};

	return (
		<>
			{urlService.isAdministrator() ? (
				<div className="page">
					<div className="page-header">
						{_.toLower(urlService.getCurrentProgramOrDefault()) ===
							_.toLower(RegulatoryProgramName.FOG) && <BackArrow />}
						<h1>{localizationService.getLocalizedString('authoritySetting.customForms')}</h1>
					</div>
					<div id="SettingsCleaningForms" className="page-wrapper">
						<div className="row">
							<div className="col-lg-4 settings-info">
								<p>
									<strong>
										{localizationService.getLocalizedString('customForm.cleaningForms')}
									</strong>
								</p>
								<p>{localizationService.getLocalizedString('customForm.customFormsInfo')}</p>
							</div>
							<div className="col-lg-8">
								<DataGrid
									restSlice={customFormSlice}
									gridOption={gridOptions}
									history={history}
									restState={(state: RootState) => state.customForms}
									match={props.match}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<AccessDeniedPage />
			)}
		</>
	);
};
