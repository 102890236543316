import {
	IppAvailableCtsEventType,
	IppIndustry,
	IppReportPackageTemplate,
	IppReportPackageTemplateElement
} from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { alertService } from 'src/redux';
import { apiService, DateUtilService, localizationService, Resource, urlService } from 'src/services';
import {
	loadReportPackageTemplateRequest,
	loadReportPackageTemplateSuccess,
	ReportPackageTemplateAction
} from './templates-actions';
import { ReportPackageTemplateState } from './templates-state';

type TemplatesThunkAction = ThunkAction<any, ReportPackageTemplateState, any, ReportPackageTemplateAction>;

export const loadTemplateDetails = (id?: any): TemplatesThunkAction => async (dispatch, getState) => {
	dispatch(loadReportPackageTemplateRequest());
	let templateDetails;
	let url;
	if (id && id !== 'new') {
		//Get template data
		url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackageTemplates, id);
		templateDetails = await apiService.getResource<IppReportPackageTemplate>(`${url}?includeInactive=true`);
	} else {
		templateDetails = {
			attachmentTypes: [],
			certificationTypes: [],
			samplesAndResultsTypes: [],
			reportPackageTemplateAssignments: [],
			isSubmissionBySignatoryRequired: true,
			effectiveDateTimeLocal: DateUtilService.getAuthorityTimezoneNow()
		};
	}
	url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppReportPackageReportElementTypes,
		'ReportElementCategoryName=Attachments&sort=name asc'
	);
	let totalAttachments = await apiService.getResource<IppReportPackageTemplateElement[]>(url);
	url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppReportPackageReportElementTypes,
		'ReportElementCategoryName=Certifications&sort=name asc'
	);
	let totalCertifications = await apiService.getResource<IppReportPackageTemplateElement[]>(url);
	url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppReportPackageReportElementTypes,
		'ReportElementCategoryName=SamplesAndResults&sort=name asc'
	);
	let totalSamples = await apiService.getResource<IppReportPackageTemplateElement[]>(url);

	url = urlService.getAuthorityResourcesApiUrl(Resource.IppIndustries, 'sort=industryNumber asc');
	let totalIndustries = await apiService.getResource<IppIndustry[]>(url);

	let attachmentTypes = templateDetails.attachmentTypes ? templateDetails.attachmentTypes : [];
	let certificationTypes = templateDetails.certificationTypes ? templateDetails.certificationTypes : [];
	let samplesTypes = templateDetails.samplesAndResultsTypes ? templateDetails.samplesAndResultsTypes : [];
	let assignedIndustries = templateDetails.reportPackageTemplateAssignments
		? templateDetails.reportPackageTemplateAssignments
		: [];
	let availableAttachmentTypes = getAvailableElements(totalAttachments, attachmentTypes);
	let availableSamplesAndResultsTypes = getAvailableElements(totalSamples, samplesTypes);
	let availableCertifications = getAvailableElements(totalCertifications, certificationTypes);
	let availableIndustries = getAvailableIndustries(
		Array.isArray(totalIndustries) ? totalIndustries : (totalIndustries as any).result,
		assignedIndustries
	);

	url = urlService.getAuthorityResourcesApiUrl(Resource.IppCtsEventTypes);
	let ctsEventTypes = await apiService.getResource<IppAvailableCtsEventType[]>(url);

	dispatch(
		loadReportPackageTemplateSuccess(
			{ ...templateDetails },
			availableSamplesAndResultsTypes,
			availableAttachmentTypes,
			availableCertifications,
			availableIndustries,
			ctsEventTypes
		)
	);
};

export const updateTemplateDetails = (template: IppReportPackageTemplate): TemplatesThunkAction => async (
	dispatch,
	getState
) => {
	const url = urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackageTemplates,
		template.reportPackageTemplateId as number
	);

	await apiService.httpPut(url, template);
	alertService.addSuccess(localizationService.getLocalizedString('ipp.reportPackage.templateDetails.saveMessage'));
	dispatch(loadTemplateDetails(template.reportPackageTemplateId || 0));
	return true;
};

export const addTemplate = (
	template: IppReportPackageTemplate,
	callback: (id: number) => void
): TemplatesThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackageTemplates);
	let postData = {
		...template
	};

	delete template.reportPackageTemplateId;

	let newTemplate = await apiService.httpPost(url, postData);

	alertService.addSuccess(localizationService.getLocalizedString('ipp.reportPackage.templateDetails.createMessage'));

	dispatch(loadTemplateDetails());

	callback(newTemplate[0].reportPackageTemplateId);

	return true;
};

export const deleteTemplate = (templateId: number): TemplatesThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackageTemplates, templateId);
	await apiService.httpDelete(url);

	alertService.addSuccess(localizationService.getLocalizedString('ipp.reportPackage.templateDetails.deleteMessage'));

	dispatch(loadTemplateDetails());
	return;
};

const getAvailableElements = (
	total: IppReportPackageTemplateElement[],
	selected: IppReportPackageTemplateElement[]
): IppReportPackageTemplateElement[] => {
	let selectedIds = selected.map(({ reportElementTypeId }) => reportElementTypeId);
	return total.filter(({ reportElementTypeId }) => !selectedIds.includes(reportElementTypeId));
};
const getAvailableIndustries = (total: IppIndustry[], selected: IppIndustry[]): IppIndustry[] => {
	let selectedIds = selected.map(({ ippIndustryId }) => ippIndustryId);
	return total.filter(({ ippIndustryId }) => !selectedIds.includes(ippIndustryId));
};
