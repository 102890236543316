import React from 'react';
import { useRef } from 'react';
import { localizationService } from 'src/services';

interface EncryptedDataProps {
	title: string;
	username: React.ReactElement;
	password: React.ReactElement;
	isUserSettings?: boolean;
	copyToClipBoard: (event: any, textToCopy: string) => void;
}
const EncryptedData: React.FC<EncryptedDataProps> = props => {
	const encryptedRef = useRef<HTMLDivElement>(null);

	return (
		<div className="mt-4">
			{props.isUserSettings && (
				<>
					<a
						href="#/"
						onClick={(event: any) => props.copyToClipBoard(event, encryptedRef.current!.innerText)}
						className="cursor-pointer">
						{localizationService.getLocalizedString(
							'ipp.authorityAcpConnector.applicationUserSettings.copyText'
						)}
					</a>
					<span>{props.title}</span>
				</>
			)}
			<div ref={encryptedRef} className="encrypted-data">
				{props.isUserSettings && <p className="mb-0">{'{'}</p>}
				{!props.isUserSettings && <p>{props.title}</p>}
				<p id="username">{props.username}</p>
				<p id="password">{props.password}</p>
				{props.isUserSettings && <p className="mb-0">{'}'}</p>}
			</div>
		</div>
	);
};

export default EncryptedData;
