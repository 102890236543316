import { InvitationDetails, Jurisdiction, MfaQuestion, QuestionAnswerDtos, SignInResult } from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { FC, RefObject, useEffect, useState } from 'react';
import { AnyIfEmpty, connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, ApplicationState } from 'src/redux';
import { getInvitationDetails, loadQuestions, loadStates } from 'src/redux/account/register';
import {
	apiService,
	localizationService,
	localStorageService,
	tokenService,
	urlService,
	UtilService
} from 'src/services';
import { Utils } from 'src/services/utils';
import CollapsibleCard from '../widgets/collapsible-card/collapsible-card';
import { CustomModal } from '../widgets/modal/modal';
import IppAccountErrorComponent from './error';
import KbqQuestions from './kbq-questions';
import SecurityQuestions from './security-questions';
import UserInformationComponent from './user-information';

interface Match {
	params: {
		invitationId: number;
	};
}

interface DispatchProps {
	getInvitationDetails: (id: number) => Promise<any>;
	loadQuestions: () => void;
	loadStates: () => void;
}

interface Props extends DispatchProps {
	history: History;
	match: Match;
	invitationDetails: InvitationDetails;
	states: Jurisdiction[];
	questions: MfaQuestion[];
}

export interface UserInfoRef {
	isUserInfoSaved: () => any;
}

export interface KbqRef {
	isKbqSaved: () => QuestionAnswerDtos[] | null;
}

export interface SqRef {
	isSqSaved: () => { questions: QuestionAnswerDtos[]; agreement: boolean } | null;
}

const RegisterComponent: FC<Props> = props => {
	const userInfoRef: RefObject<UserInfoRef> = React.createRef<UserInfoRef>();
	const kbqRef: RefObject<KbqRef> = React.createRef<KbqRef>();
	const sqRef: RefObject<SqRef> = React.createRef<SqRef>();
	const [userInfo, setUserInfo] = useState({} as any);
	const [kbq, setKbq] = useState([] as QuestionAnswerDtos[]);
	const [sqs, setSqs] = useState([] as QuestionAnswerDtos[]);
	const [showLinkExpireMessage, setShowLinkExpireMessage] = useState(false);
	const [isReregister, setIsReregister] = useState(false);
	const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
	const [showSignOutModal, setShowSignOutModal] = useState(false);
	const [showInvitation, setShowInvitation] = useState(false);

	const loadInvitationDetails = async () => {
		let response = await props.getInvitationDetails(props.match.params.invitationId);
		if (
			response.message &&
			response.message.includes(localizationService.getLocalizedString('errors.invalidToken'))
		) {
			setShowLinkExpireMessage(true);
			alertService.clearAllMessages();
		} else {
			setShowInvitation(true);
		}
	};

	useEffect(() => {
		let token = tokenService.getTokenOrDefault();
		if (tokenService.isTokenValid(token)) {
			setShowSignOutModal(true);
		} else {
			setupInvitation();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const { isResetInvitation, userProfileDto, isNewUserToIpp } = props.invitationDetails;
		if (!isResetInvitation && !isNewUserToIpp && userProfileDto) {
			setIsReregister(true);
		}
	}, [props.invitationDetails]);

	const onNextUserInformation = (userInfo: any) => {
		setUserInfo(userInfo);
	};

	const onBackUserInformation = (questions: QuestionAnswerDtos[]) => {
		setKbq(questions);
	};

	const onNextKbq = (questions: QuestionAnswerDtos[]) => {
		setKbq(questions);
	};

	const onBackKbq = (securityQuestions: QuestionAnswerDtos[]) => {
		setSqs(securityQuestions);
	};

	const onNextSecurityQuestions = async (
		securityQuestions: QuestionAnswerDtos[],
		agreement: boolean,
		kbqs: QuestionAnswerDtos[] = [...kbq],
		userData: any = { ...userInfo }
	) => {
		setSqs(securityQuestions);
		userData.agreeTermsAndConditions = agreement || false;
		let registerData = {
			userInfo: userData,
			registrationToken: props.match.params.invitationId,
			questionAnswerDtos: [...kbqs, ...securityQuestions],
			registrationType: props.invitationDetails.isResetInvitation
				? 'ResetRegistration'
				: props.invitationDetails.isNewUserToIpp
				? 'ReRegistration'
				: 'NewRegistration',
			mfaType: 'QuestionAndAnswers'
		};
		if (agreement) {
			let url = `${urlService.getApiBaseUrl()}/Account/RegisterByInvitation`;
			try {
				const response = await apiService.httpPost(url, registerData);
				if (String.equalCaseInsensitive(response.result, 'CanNotUseLastNumberOfPasswords')) {
					alertService.addError(response.errors[0]);
				} else {
					alertService.addSuccess(
						localizationService.getLocalizedString('ipp.accountPortal.messages.registrationSuccess')
					);
					setIsRegistrationSuccessful(true);
				}
			} catch (ex) {
				alertService.addError(ex.message);
			}
		} else {
			alertService.addError(
				localizationService.getLocalizedString('ipp.accountPortal.messages.termsAndConditionError')
			);
		}
	};

	const isAqiIdpUserRegistration = (): boolean => {
		return (
			UtilService.hasValue(props.invitationDetails.aqiIdpUserId) &&
			UtilService.hasValue(props.invitationDetails.aqiIdpUserName)
		);
	};

	const onRegister = () => {
		alertService.clearAllMessages();
		if (userInfoRef.current && kbqRef.current && sqRef.current) {
			let userInfo = userInfoRef.current.isUserInfoSaved();
			let kbqs = kbqRef.current.isKbqSaved();
			let sqs = sqRef.current.isSqSaved();
			if (props.invitationDetails.isNewUserToIpp) {
				delete userInfo.password;
			}
			userInfo && sqs && kbqs && onNextSecurityQuestions(sqs.questions, sqs.agreement, kbqs, userInfo);
		}
	};

	const handleCancelSignOut = () => {
		urlService.switchToDefaultView();
	};

	const handleSignOut = () => {
		tokenService.clearToken();
		localStorageService.removeLocalStorage('allowedPortals');
		localStorage.removeItem('hidePortalDirector');
		window.location.reload();
	};

	const setupInvitation = async () => {
		await loadInvitationDetails();
		props.loadQuestions();
		props.loadStates();
		document.body.className = 'signinBody';
	};

	const getRegistrationDescription = (): string => {
		if (props.invitationDetails.isResetInvitation) {
			if (isAqiIdpUserRegistration()) {
				return localizationService.getLocalizedString(`ipp.accountPortal.messages.resetAqiIdpMessage`);
			} else {
				return localizationService.getLocalizedString(`ipp.accountPortal.messages.resetMessage`);
			}
		}
		if (isReregister) {
			if (isAqiIdpUserRegistration()) {
				return localizationService.getLocalizedString(`ipp.accountPortal.messages.reRegisterAqiIdpMessage`);
			} else {
				return localizationService.getLocalizedString(`ipp.accountPortal.messages.reRegisterMessage`);
			}
		}
		if (props.invitationDetails.isNewUserToIpp) {
			return localizationService.getLocalizedString(`ipp.accountPortal.messages.isNewUserToIpp`);
		}
		return '';
	};

	return showLinkExpireMessage ? (
		<IppAccountErrorComponent
			errorTitle={localizationService.getLocalizedString('ipp.accountErrorMessages.invitationExpireTitle')}
			errorDescription={localizationService.getLocalizedString(
				'ipp.accountErrorMessages.invitationExpireDescription'
			)}
			containerClass="signin"
			showSignInButton={true}
		/>
	) : isRegistrationSuccessful ? (
		<IppAccountErrorComponent
			errorTitle={localizationService.getLocalizedString(
				'ipp.accountPortal.messages.registrationSuccessfulTitle'
			)}
			errorDescription={Utils.registrationSuccessDescription(props.invitationDetails)}
			containerClass="signin"
			showSignInButton={true}
		/>
	) : showInvitation ? (
		<div className="page">
			<div className="page-header">
				<h1>
					{localizationService.getLocalizedString(
						props.invitationDetails.isResetInvitation
							? 'ipp.accountPortal.resetAccount'
							: 'ipp.accountPortal.registration'
					)}
				</h1>
			</div>
			{(props.invitationDetails.isResetInvitation ||
				isReregister ||
				props.invitationDetails.isNewUserToIpp ||
				isAqiIdpUserRegistration()) && (
				<p className="mb-2">
					<strong>{getRegistrationDescription()}</strong>
				</p>
			)}
			<div>
				<CollapsibleCard
					accordionType="user-information"
					accordionHeading={localizationService.getLocalizedString(
						`ipp.accountPortal.${isReregister ? 'registration' : 'userInformation'}`
					)}>
					<UserInformationComponent
						registrationToken={props.match.params.invitationId}
						history={props.history}
						invitationDetails={props.invitationDetails}
						onNextUserInformation={(userInfo: any) => onNextUserInformation(userInfo)}
						states={props.states}
						ref={userInfoRef}
						setIsRegistrationSuccessful={setIsRegistrationSuccessful}
					/>
				</CollapsibleCard>
				{!isReregister && (
					<>
						<CollapsibleCard
							key="kbq-questions"
							className="mt-3"
							accordionType="kbq-questions"
							accordionHeading={localizationService.getLocalizedString(
								'ipp.accountPortal.knowledgeBasedQuestions'
							)}>
							<KbqQuestions
								questions={
									props.questions
										? props.questions.filter(question => question.questionType === 'KBQ')
										: []
								}
								showEditButton={false}
								onNextKbq={(questions: QuestionAnswerDtos[]) => onNextKbq(questions)}
								onBackUserInformation={onBackUserInformation}
								selectedQuestions={kbq}
								kbq={kbq}
								ref={kbqRef}
								isRegister={true}
							/>
						</CollapsibleCard>
						<CollapsibleCard
							key="sq-questions"
							className="mt-3"
							accordionType="sq-questions"
							accordionHeading={localizationService.getLocalizedString(
								'ipp.accountPortal.securityQuestions'
							)}>
							<SecurityQuestions
								showEditButton={false}
								questions={
									props.questions
										? props.questions.filter(question => question.questionType === 'SQ')
										: []
								}
								onNextSecurityQuestions={(questions: QuestionAnswerDtos[], agreement: boolean) =>
									onNextSecurityQuestions(questions, agreement)
								}
								onBackKbq={onBackKbq}
								selectedQuestions={sqs}
								sqs={sqs}
								ref={sqRef}
								isRegister={true}
							/>
						</CollapsibleCard>
						<section className="d-flex mt-3">
							<button className="btn ai-action ml-auto" onClick={() => onRegister()}>
								{localizationService.getLocalizedString('ipp.accountPortal.register')}
							</button>
						</section>
					</>
				)}
			</div>
		</div>
	) : showSignOutModal ? (
		<div className="page">
			<CustomModal
				showModal={showSignOutModal}
				title={localizationService.getLocalizedString('screen.labels.signOut')}
				message={localizationService.getLocalizedString('ipp.accountPortal.messages.signOutBeforeRegister')}
				okayButtonText={localizationService.getLocalizedString('screen.labels.signOut')}
				onCancelButtonClick={handleCancelSignOut}
				onOkayButtonClick={handleSignOut}
			/>
		</div>
	) : (
		<> </>
	);
};

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.register };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		getInvitationDetails: (id: number) => dispatch(getInvitationDetails(id)),
		loadStates: () => dispatch(loadStates()),
		loadQuestions: () => dispatch(loadQuestions())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterComponent);
