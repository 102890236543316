import React, { useState, FC } from 'react';
import { History } from 'history';
import { ColumnField, LocalStorageName, RouteProps, FilterType, Authority } from '@rcp/types';
import { RestSlice } from 'src/redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService, urlService } from 'src/services';
import { haulerAuthoritySlice } from './authority.slice';
import _ from 'lodash';
import './authorities-grid.scss';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { InputInvitationCodeModal } from '../../shared/portal-registration/enter-invitation-code-modal';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const HaulerPortalAuthorities: FC<Props<Authority>> = props => {
	const [showNotification, setShowNotification] = useState(true);
	const [showInviteCodeModal, setShowInviteCodeModal] = useState(false);

	const subHeading = () => {
		return (
			<p className="font-size-16px-regular">
				{localizationService.getLocalizedString('haulerPortal.authority.authoritiesSubHeadingPart1')}
				<a
					className="ai-link cursor-pointer"
					id="invite-code-modal-subheading"
					onClick={(e?: any) => setShowInviteCodeModal(true)}>
					{localizationService.getLocalizedString('haulerPortal.authority.authoritiesSubHeadingPart2')}
				</a>
				{localizationService.getLocalizedString('haulerPortal.authority.authoritiesSubHeadingPart3')}
			</p>
		);
	};

	const websiteToLink = (item: any) => {
		const dataItem = item.dataItem as Authority;
		return (
			<td>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${urlService.getReactBaseUrlForSubDomain(_.toLower(dataItem.websiteUrl))}`}>
					{dataItem.websiteUrl}
				</a>
			</td>
		);
	};

	const showAuthorityAction = (props: any) => {
		const dataItem = props.dataItem as Authority;
		return (
			<td>
				<td>
					<span>{dataItem.accessStatus}</span>
				</td>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('haulerPortal.authority.authorities'),
		prefix: 'haulerPortal.authority',
		storageName: LocalStorageName.HaulerPortalAuthorities,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('haulerPortal.authority.searchHint'),
		showEditColumnButton: true,
		showBackButton: true,
		queryParameters: {},
		allColumns: [
			new ColumnField('organizationName'),
			new ColumnField('cityName'),
			new ColumnField('phoneNumber'),
			new ColumnField('websiteUrl', FilterType.Text, websiteToLink),
			new ColumnField('accessStatus', FilterType.Text, showAuthorityAction)
		],
		defaultColumns: ['organizationName', 'cityName', 'phoneNumber', 'websiteUrl', 'accessStatus'],
		subHeading: subHeading()
	};
	return (
		<>
			{!urlService.isAdministrator() ? (
				<AccessDeniedPage />
			) : (
				<>
					<DataGrid
						history={props.history}
						match={props.match}
						restSlice={haulerAuthoritySlice}
						restState={(state: RootState) => state.haulerAuthorities}
						gridOption={initialGridOption}
					/>
					{showInviteCodeModal && (
						<InputInvitationCodeModal
							showModal={showInviteCodeModal}
							onCancelButtonClicked={setShowInviteCodeModal}
						/>
					)}
				</>
			)}
		</>
	);
};

export default HaulerPortalAuthorities;
