import { DropDownOption, Extractor, Lookup, Unit } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { PopoverModal, SingleCheckbox, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components';
import { alertService } from 'src/redux';
import {
	apiService,
	localizationService,
	optionsMap,
	Resource,
	tokenService,
	urlService,
	validationService
} from 'src/services';
import { haulerFacilityDevicesSlice } from './devices-slice';
interface OwnProps {
	showModal: boolean;
	authorityOrganizationId: number;
	facilityId: number;
	toggle: () => void;
	reloadDevices: () => void;
}
type Props = OwnProps;
interface FormFields {
	extractorTypeId?: number;
	extractorTypeIdError?: string;
	extractorDescription?: string;
	extractorDescriptionError?: string;

	numberOfCompartments?: number;
	numberOfCompartmentsError?: string;
	isAdditivesUsed?: boolean;

	location?: string;

	trapCapacity?: number;
	trapCapacityError?: string;

	trapDepth?: number;
	trapDepthError?: string;

	manufacturer?: string;
	model?: string;
}
export const HaulerDeviceModal: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	const initialFormFields: FormFields = {
		isAdditivesUsed: false,
		location: 'Inside'
	};
	const [formState, setFormState] = React.useState(initialFormFields);
	const [deviceTypeOptionValues, setDeviceTypeOptionValues] = React.useState([] as DropDownOption[]);
	const [authorityUnits, setAuthorityUnits] = React.useState([] as Unit[]);

	React.useEffect(() => {
		let deviceTypesUrl = urlService.getAuthorityLookupUrlForService(
			props.authorityOrganizationId,
			Resource.ExtractorTypes
		);
		let unitTypesUrl = urlService.getAuthoritySettingsUrlForService(props.authorityOrganizationId, Resource.Units);

		apiService
			.getResource<Lookup[]>(deviceTypesUrl)
			.then(deviceTypes => {
				setDeviceTypeOptionValues(optionsMap.fromLookups(deviceTypes));
			})
			.catch(ex => alertService.addError(ex.message));

		apiService
			.getResource<Unit[]>(unitTypesUrl)
			.then(units => {
				setAuthorityUnits(units);
			})
			.catch(ex => alertService.addError(ex.message));
	}, []);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);

		setFormState(newState);
	};

	const isExtractorTypeValid = (object: any): boolean => {
		if (_.isUndefined(object['extractorTypeId']) || object['extractorTypeId'] < 1) {
			object['extractorTypeIdError'] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				localizationService.getLocalizedString('extractor.deviceType')
			);
			return false;
		} else {
			_.unset(object, 'extractorTypeIdError');
			return true;
		}
	};
	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		isExtractorTypeValid(newState);

		validationService.validateIntegerNumberField(newState, 'numberOfCompartments', 'numberOfCompartmentsError');
		validationService.validateNumberField(newState, 'trapCapacity', 'trapCapacityError');
		validationService.validateNumberField(newState, 'trapDepth', 'trapDepthError');
		validationService.validateRequiredField(
			newState,
			'extractorDescription',
			'extractorDescriptionError',
			localizationService.getLocalizedString('extractor.locationDesc')
		);

		setFormState(newState);
		var isFormValid = false;

		isFormValid = !validationService.hasError(
			newState,
			'extractorTypeIdError',
			'numberOfCompartmentsError',
			'trapCapacityError',
			'trapDepthError',
			'extractorDescriptionError'
		);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const addDevice = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		var token = tokenService.getTokenOrDefault();
		//hardcoded for Seattle units
		var inchUnit = authorityUnits.find(a => String.equalCaseInsensitive(a.name, 'in'));
		var galUnit = authorityUnits.find(a => String.equalCaseInsensitive(a.name, 'gal'));
		if (!inchUnit) {
			alertService.addError('Unit inch (in) is missing.');
		}
		if (!galUnit) {
			alertService.addError('Unit gallon (gal) is missing.');
		}
		let deviceToAdd: Extractor = {
			facilityId: props.facilityId,
			extractorTypeId: formState.extractorTypeId,
			extractorDescription: formState.extractorDescription,
			numberOfCompartments: formState.numberOfCompartments,
			isAdditivesUsed: formState.isAdditivesUsed,
			location: formState.location,
			trapCapacity: formState.trapCapacity,
			trapCapacityUnitId: formState.trapCapacity && galUnit && galUnit.unitId,
			trapDepth: formState.trapDepth,
			trapDepthUnitId: formState.trapDepth && inchUnit && inchUnit.unitId,
			isActive: true,
			isRemoved: false,
			manufacturer: formState.manufacturer,
			model: formState.model,
			verified: false
		};
		haulerFacilityDevicesSlice.setApiUrlPath(
			`${Resource.Authorities}/${props.authorityOrganizationId}/${Resource.Extractors}`
		);
		dispatch(
			haulerFacilityDevicesSlice.createOne(
				deviceToAdd,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.addSuccess',
					localizationService.getLocalizedString('extractor.device')
				),
				() => {
					props.toggle();
					props.reloadDevices();
				}
			)
		);
	};

	const cancelSave = () => {
		props.toggle();
	};
	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={localizationService.getLocalizedString('haulerPortal.haulerFacilityDetail.addDevice')}
				save={addDevice}
				saveButtonClassName={'ai-action'}
				saveButtonText={localizationService.getLocalizedString('screen.buttons.add')}
				cancel={cancelSave}>
				<SingleSelectDropdown
					id="extractorTypeId"
					name="extractorTypeId"
					label={localizationService.getLocalizedString('extractor.deviceType')}
					value={_.toString(formState.extractorTypeId)}
					onChange={changeFormState}
					options={deviceTypeOptionValues}
					isRequired={true}
					error={formState.extractorTypeIdError}
				/>
				<div className="form-row">
					<TextInput
						id="manufacturer"
						name="manufacturer"
						label={localizationService.getLocalizedString('extractor.manufacturer')}
						value={formState.manufacturer}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="model"
						name="model"
						label={localizationService.getLocalizedString('extractor.model')}
						value={formState.model}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
				</div>
				<div className="form-group required">
					<label>{localizationService.getLocalizedString('extractor.placement')}</label>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input z-1"
							name="location"
							id="location-inside"
							value="Inside"
							checked={String.equalCaseInsensitive(formState.location, 'Inside')}
							onChange={changeFormState}
						/>
						<label className="custom-control-label" htmlFor="location-inside">
							{localizationService.getLocalizedString('extractor.inside')}
						</label>
					</div>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input z-1"
							name="location"
							id="location-outside"
							value="Outside"
							checked={String.equalCaseInsensitive(formState.location, 'Outside')}
							onChange={changeFormState}
						/>
						<label className="custom-control-label" htmlFor="location-outside">
							{localizationService.getLocalizedString('extractor.outside')}
						</label>
					</div>
				</div>
				<TextAreaInput
					id="extractorDescription"
					name="extractorDescription"
					label={localizationService.getLocalizedString('extractor.locationDesc')}
					value={formState.extractorDescription}
					onChange={changeFormState}
					isRequired={true}
					isFullWidth={true}
					error={formState.extractorDescriptionError}
				/>

				<TextInput
					id="trapCapacity"
					name="trapCapacity"
					label={localizationService.getLocalizedString('extractor.capacityGallons')}
					value={formState.trapCapacity}
					onChange={changeFormState}
					error={formState.trapCapacityError}
					type="number"
				/>

				<TextInput
					id="trapDepth"
					name="trapDepth"
					label={localizationService.getLocalizedString('extractor.depthInches')}
					value={formState.trapDepth}
					onChange={changeFormState}
					error={formState.trapDepthError}
					type="number"
				/>

				<TextInput
					id="numberOfCompartments"
					name="numberOfCompartments"
					label={localizationService.getLocalizedString('extractor.numberOfCompartments')}
					value={formState.numberOfCompartments ? '' + formState.numberOfCompartments : undefined}
					onChange={changeFormState}
					error={formState.numberOfCompartmentsError}
					type="number"
				/>

				<SingleCheckbox
					id="isAdditivesUsed"
					name="isAdditivesUsed"
					label={localizationService.getLocalizedString('extractor.isAdditivesUsed')}
					checked={formState.isAdditivesUsed}
					onChange={changeFormState}
					className="div-checkbox"
				/>
			</PopoverModal>
		</div>
	);
};
