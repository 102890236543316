import { HaulerPortalFacilities } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, Resource } from '../../../../services';

export const haulerPortalFacilitiesSlice = new RestSlice<HaulerPortalFacilities>(
	localizationService.getLocalizedString('haulerPortal.selectFacility.selectFacility'),
	Resource.HaulerFacilities
);

export const haulerPortalFacilitiesRestSliceReducer = haulerPortalFacilitiesSlice.rests.reducer;
