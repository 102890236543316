import { Action } from 'redux';

import { IppReportPackage } from '@rcp/types';
import { IppFileStore, IppIndustrySample } from '@rcp/types/src';

export enum PackageActionType {
	LoadSamplesRequest = 'loadSamplesRequest',
	LoadSamplesSuccess = 'loadSamplesSuccess',
	LoadAttachmentsRequest = 'loadAttachmentsRequest',
	LoadAttachmentsSuccess = 'loadAttachmentsSuccess',
	LoadPackageRequest = 'loadPackageRequest',
	LoadPackageSuccess = 'loadPackageSuccess',
	CreateReportPackage = 'CreateReportPackage',
	UpdateReportPackage = 'UpdateReportPackage',
	ResetPackage = 'ResetPackage'
}

export interface LoadSamplesRequest extends Action {
	type: PackageActionType.LoadSamplesRequest;
}
export interface LoadSamplesSuccess extends Action {
	type: PackageActionType.LoadSamplesSuccess;
	samples: IppIndustrySample[];
}

export interface LoadAttachmentsRequest extends Action {
	type: PackageActionType.LoadAttachmentsRequest;
}

export interface LoadAttachmentsSuccess extends Action {
	type: PackageActionType.LoadAttachmentsSuccess;
	attachments: IppFileStore[];
}

export interface LoadPackageRequest extends Action {
	type: PackageActionType.LoadPackageRequest;
}

export interface LoadPackageSuccess extends Action {
	type: PackageActionType.LoadPackageSuccess;
	reportPackage: IppReportPackage;
}

export interface CreateReportPackage extends Action {
	type: PackageActionType.CreateReportPackage;
	reportPackage: IppReportPackage;
}

export interface UpdateReportPackage extends Action {
	type: PackageActionType.UpdateReportPackage;
	reportPackage: IppReportPackage;
}

export interface ResetPackage extends Action {
	type: PackageActionType.ResetPackage;
}

export type PackageAction =
	| LoadSamplesRequest
	| LoadSamplesSuccess
	| LoadAttachmentsRequest
	| LoadAttachmentsSuccess
	| LoadPackageRequest
	| LoadPackageSuccess
	| CreateReportPackage
	| UpdateReportPackage
	| ResetPackage;

export const loadSamplesRequest = (): LoadSamplesRequest => ({
	type: PackageActionType.LoadSamplesRequest
});

export const loadSamplesSuccess = (samples: IppIndustrySample[]): LoadSamplesSuccess => ({
	type: PackageActionType.LoadSamplesSuccess,
	samples
});

export const loadAttachmentsRequest = (): LoadAttachmentsRequest => ({
	type: PackageActionType.LoadAttachmentsRequest
});

export const loadAttachmentsSuccess = (attachments: IppFileStore[]): LoadAttachmentsSuccess => ({
	type: PackageActionType.LoadAttachmentsSuccess,
	attachments
});

export const loadPackageRequest = (): LoadPackageRequest => ({
	type: PackageActionType.LoadPackageRequest
});

export const loadPackageSuccess = (reportPackage: IppReportPackage): LoadPackageSuccess => ({
	type: PackageActionType.LoadPackageSuccess,
	reportPackage
});

export const createReportPackage = (reportPackage: IppReportPackage): CreateReportPackage => ({
	type: PackageActionType.CreateReportPackage,
	reportPackage: reportPackage
});

export const updateReportPackage = (reportPackage: IppReportPackage): UpdateReportPackage => ({
	type: PackageActionType.UpdateReportPackage,
	reportPackage: reportPackage
});

export const resetReportPackage = (): ResetPackage => ({
	type: PackageActionType.ResetPackage
});
