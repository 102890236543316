import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ApplicationState, FacilityContactListState, JurisdictionListState, loadFacilityContact } from 'src/redux';
import { localizationService } from 'src/services';

import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ContactCard } from 'src/features/contacts/contact-card';
import { ContactCardType, EntityContact_EntityType, EntityContact, FogFacility } from '@rcp/types';
import _ from 'lodash';
import './contact-tab-page-component.scss';
import { ContactModal } from 'src/features/contacts/contact-modal';

interface OwnProps {
	facility: FogFacility;
}

interface StateProps extends FacilityContactListState, JurisdictionListState {}

interface DispatchProps {
	loadFacilityContact: (facilityId: number, includeDeviceContacts: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface CompState {
	showAddModal: boolean;
	contactList: EntityContact[];
}

class FacilityContactTabPage extends Component<Props, CompState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showAddModal: false,
			contactList: []
		};

		this.onToggleShowAddModal = this.onToggleShowAddModal.bind(this);

		this.prepareFacilityContacts = this.prepareFacilityContacts.bind(this);
	}

	componentDidUpdate(prevProps: Props) {
		if (
			this.props.facilityContactList !== prevProps.facilityContactList ||
			this.props.facility.lastHaulerName !== prevProps.facility.lastHaulerName
		) {
			this.prepareFacilityContacts();
		}
	}

	componentDidMount() {
		this.loadFacilityContacts();
		this.prepareFacilityContacts();
	}

	prepareFacilityContacts = () => {
		let newContacts: EntityContact[] = [];
		if (!_.isEmpty(this.props.facilityContactList.fogFacilityContacts)) {
			newContacts = newContacts.concat(
				this.props.facilityContactList.fogFacilityContacts.map(obj => ({
					...obj,
					facilityId: this.props.facility.facilityId,
					facilityName: this.props.facility.facilityName,
					facilityNumber: this.props.facility.referenceNumber,
					duplicateDeviceContacts: [],
					entityType: EntityContact_EntityType.FogFacility,
					sendNoticeIds: _.isEmpty(obj.jsonCleaningNotices)
						? []
						: JSON.parse(obj.jsonCleaningNotices as string)
								.filter((a: any) => a.SendNotices === true)
								.map((b: any) => b.DeviceId)
				}))
			);
		}
		if (!_.isEmpty(this.props.facilityContactList.fogDeviceContacts)) {
			let deviceContacts = this.props.facilityContactList.fogDeviceContacts;
			deviceContacts.forEach((deviceContact, index) => {
				var existingContact = newContacts.find(
					a => a.contactId === deviceContact.contactId && a.contactTypeCode === deviceContact.contactTypeCode
				);
				if (!existingContact) {
					let newContact: EntityContact = {
						...deviceContact,
						facilityId: this.props.facility.facilityId,
						facilityName: this.props.facility.facilityName,
						facilityNumber: this.props.facility.referenceNumber,
						duplicateDeviceContacts: [deviceContact]
					};
					if (newContact.sendNotices) {
						newContact.sendNoticeIds = [];
						newContact.sendNoticeIds.push(newContact.entityId as number);
					}
					newContact.entityGroupIds = [];
					newContacts.push(newContact);
				}
				if (existingContact) {
					if (!existingContact.sendNoticeIds) {
						existingContact.sendNoticeIds = [];
					}
					if (existingContact.duplicateDeviceContacts) {
						existingContact.duplicateDeviceContacts.push(deviceContact);
					}
					if (
						deviceContact.sendNotices &&
						!existingContact.sendNoticeIds.includes(deviceContact.entityId as number)
					) {
						existingContact.sendNoticeIds.push(deviceContact.entityId as number);
					}
					if (_.isEmpty(existingContact.entityGroupIds)) {
						existingContact.entityGroupIds = [];
					}
					if (
						existingContact.entityGroupIds &&
						existingContact.entityType === EntityContact_EntityType.FogDevice
					) {
						existingContact.entityGroupIds.push([
							deviceContact.entityContactId as number,
							deviceContact.entityId as number
						]);
					}
				}
			});
		}
		newContacts.sort((a, b) => {
			if (a.contactTypeCode && b.contactTypeCode) {
				if (a.contactTypeCode < b.contactTypeCode) {
					return -1;
				}
				if (a.contactTypeCode > b.contactTypeCode) {
					return 1;
				}
			}
			return 0;
		});

		let primaryContact = newContacts.find(a => a.isPrimary);
		if (primaryContact) {
			newContacts = newContacts.filter(a => !a.isPrimary);
			newContacts.unshift(primaryContact);
		}

		this.setState({ contactList: newContacts });
	};
	onToggleShowAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({
			showAddModal: !this.state.showAddModal
		});
		this.loadFacilityContacts();
	};

	loadFacilityContacts = () => {
		this.props.loadFacilityContact(this.props.facility.facilityId as number, true);
	};

	containsPrimaryContactCard = () => {
		return this.state.contactList.some((contact: EntityContact) => {
			return contact.isPrimary || contact.entityType === EntityContact_EntityType.FogDevice;
		});
	};

	render() {
		return (
			<>
				<div className="contact-container">
					{this.state.contactList &&
						this.state.contactList.length > 0 &&
						this.state.contactList.map((item: EntityContact, index: number) => (
							<ContactCard
								entityContact={item}
								key={index}
								id={index}
								reload={() => this.loadFacilityContacts()}
								contactCardType={ContactCardType.FogFacility}
								switchSendNotice={() => {}}
								extractorList={
									this.props.facility.jsonExtractorList
										? JSON.parse(this.props.facility.jsonExtractorList as string)
										: []
								}
								isPrimaryOrHaulerContactExist={this.containsPrimaryContactCard()}
								isDeviceDetailsPage={false}
							/>
						))}
				</div>
				<div className="mt-4">
					<a href="#/" className="ai-add-link" onClick={e => this.onToggleShowAddModal(e)}>
						{localizationService.getLocalizedString('contacts.addContact')}&#8230;
					</a>
				</div>
				{this.state.showAddModal && (
					<ContactModal
						close={() => {
							this.onToggleShowAddModal(null);
						}}
						reload={this.loadFacilityContacts}
						associatedContacts={
							this.props.facilityContactList ? this.props.facilityContactList.fogFacilityContacts : []
						}
						entityContact={{
							entityId: this.props.facility.facilityId as number,
							entityType: EntityContact_EntityType.FogFacility
						}}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityContacts, ...state.jurisdictions };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadFacilityContact: (facilityId: number, loadDeviceContacts: boolean) =>
			dispatch(loadFacilityContact(facilityId, loadDeviceContacts))
	};
};

export const ContactTabPageComponent = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityContactTabPage);
