import { IppReportPackage } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { DateUtilService } from 'src/services';
import { localizationService } from 'src/services/localizationService';
import './step2.scss';

const resourceRoot = 'ipp.samples.step2';

interface Props {
	reportPackages: IppReportPackage[];
}

const Reporting: FC<Props> = (props: Props) => {
	let [reportPackages, setReportPackages] = useState<IppReportPackage[]>([]);

	useEffect(() => {
		setReportPackages(props.reportPackages);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reportPackages.length]);

	return (
		<>
			<CollapsibleCard
				className="mt-3"
				accordionType="reporting-details"
				accordionHeading={`${localizationService.getLocalizedString(`${resourceRoot}.reporting`)}`}>
				<div>
					<p className="mb-1">
						{localizationService.getLocalizedString(
							`${resourceRoot}.${reportPackages.length ? 'includedInReports' : 'notIncludedInReports'}`
						)}
					</p>
					{reportPackages.map((reportPackage: any, index: number) => {
						let reportPackageLabel = reportPackage.name;
						if (reportPackage.periodStartDateTimeLocal && reportPackage.periodEndDateTimeLocal) {
							reportPackageLabel +=
								' ' +
								DateUtilService.toDisplayDate(reportPackage.periodStartDateTimeLocal) +
								' - ' +
								DateUtilService.toDisplayDate(reportPackage.periodEndDateTimeLocal);
						}
						return (
							<p key={index} className="text-muted font-size-16px-semibold mb-0">
								<i>{reportPackageLabel}</i>
							</p>
						);
					})}
				</div>
			</CollapsibleCard>
		</>
	);
};

export default Reporting;
