import React, { FC, useState } from 'react';
import { DateInput, DeleteModal, TextInput, DeleteModalProp } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { ReportPackageStatus } from 'src/constants';
import { DateUtilService, localizationService } from 'src/services';

interface Props {
	className?: string;
	lastSentDateTimeLocal?: string;
	lastSenderFirstName?: string;
	lastSenderLastName?: string;
	repudiationReviewDateTimeLocal?: string;
	reportStatusName?: string;
	submissionReviewDateTimeLocal?: string;
	sendToCTS?: () => Promise<boolean | void>;
	enableCTSButton?: boolean;
}

const IppPackageItemSendToCTS: FC<Props> = (props: Props) => {
	const [showCtsModal, setShowCtsModal] = useState(false);

	const hasErrorStatus = String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Error);
	const getLastSent = () => {
		return `${props.lastSenderFirstName || ''} ${props.lastSenderLastName || ''}`;
	};

	const getLastSentDate = () => {
		if (!props.lastSentDateTimeLocal) {
			return '';
		}
		return props.lastSentDateTimeLocal;
	};

	const onSend = async () => {
		props.sendToCTS && (await props.sendToCTS());
		setShowCtsModal(false);
	};

	const showSendToCTSButton = () => {
		const { Submitted, Repudiated } = ReportPackageStatus;
		if (
			(String.equalCaseInsensitive(props.reportStatusName, Submitted) && props.submissionReviewDateTimeLocal) ||
			(String.equalCaseInsensitive(props.reportStatusName, Repudiated) &&
				(props.repudiationReviewDateTimeLocal || props.submissionReviewDateTimeLocal))
		) {
			return true;
		}
		return false;
	};

	const ctsModalProps: DeleteModalProp = {
		showModal: showCtsModal,
		title: localizationService.getLocalizedString('ipp.reportPackage.package.ctsModalTitle'),
		message: localizationService.getLocalizedString('ipp.reportPackage.package.ctsModalMessage'),
		onCancelButtonClick: () => setShowCtsModal(false),
		onOkayButtonClick: onSend,
		okayButtonText: localizationService.getLocalizedString('ipp.buttons.send'),
		isDeleteButton: false
	};

	return (
		<>
			{!hasErrorStatus || (hasErrorStatus && getLastSent() !== '' && getLastSentDate() !== '') ? (
				<CollapsibleCard
					accordionType="report-package-send-to-cts-accordion"
					accordionHeading={localizationService.getLocalizedString(
						'ipp.reportPackage.package.sendToLinkoCts'
					)}
					className={props.className ? props.className : ''}>
					<div id="report-package-send-to-cts" className="w-75 mx-auto">
						<div className="form-row">
							<TextInput
								id="last-reviewed-by"
								name="lastReviewedBy"
								className="col-md-5"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.lastSentToCTSBy'
								)}
								value={getLastSent()}
							/>
							<div className="col-md-1" />

							<DateInput
								id="review-date"
								name="reviewDate"
								className="pl-1"
								format={getLastSentDate() ? 'MMM dd, yyyy h:mm a' : ''}
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.lastSentDateTimeLocal'
								)}
								isDisabled={true}
								value={getLastSentDate()}
							/>
						</div>
					</div>
					<div className="form-row">
						{showSendToCTSButton() && (
							<button
								className="btn ai-action ml-auto mr-2"
								id="send-to-cts-btn"
								disabled={!props.enableCTSButton}
								onClick={() => setShowCtsModal(true)}>
								{localizationService.getLocalizedString('ipp.reportPackage.package.sendToCts')}
							</button>
						)}
					</div>
					<DeleteModal {...ctsModalProps} />
				</CollapsibleCard>
			) : null}
		</>
	);
};

export default IppPackageItemSendToCTS;
