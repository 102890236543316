import React, { useState } from 'react';
import { localizationService, urlService, useForceUpdate } from 'src/services';
import Stepper, { Step, StepperContext } from 'src/components/widgets/stepper';
import { Step1, Step2, Step3, Step4 } from './facility-notice-steps';
import { SendFacilityNoticeSteps, RouteProps, FeatureNames } from '@rcp/types';
import { FogFacilitiesState, loadFacilityContact, useReduxSelector } from 'src/redux';
import { useDispatch } from 'react-redux';
import { Utils } from '../../../../../services/utils';
import { NotFound } from '../../../../../features/home/not-found';

const { STEP1, STEP2, STEP3, STEP4 } = SendFacilityNoticeSteps;

interface Props extends FogFacilitiesState, RouteProps {
	history: History;
}

export const FacilityNotice: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [label, setLabel] = useState(
		localizationService.getLocalizedString('authoritySetting.noticesSettings.selectTemplate')
	);
	const forceUpdate = useForceUpdate();
	React.useEffect(() => {
		dispatch(loadFacilityContact(urlService.getFogFacilityId(), true));
	}, []);

	const changeLabel = (type: string) => {
		if (Utils.isDeviceCleaningNotice(type)) {
			setLabel(
				localizationService.getLocalizedString('authoritySetting.noticesSettings.selectTemplateAndDevices')
			);
		} else {
			setLabel(localizationService.getLocalizedString('authoritySetting.noticesSettings.selectTemplate'));
		}
		forceUpdate();
	};

	return (
		<div className="page">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('authoritySetting.noticesSettings.sendNotice')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main-settings">
					<section>
						<Stepper vertical initialStep={STEP1}>
							<Step title={label} stepId={STEP1}>
								<Step1 match={props.match} history={props.history} changeLabel={changeLabel} />
							</Step>
							<Step
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.chooseRecipients'
								)}
								stepId={STEP2}>
								<Step2 />
							</Step>
							<Step
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.editContent'
								)}
								stepId={STEP3}>
								<Step3 />
							</Step>
							<Step
								title={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.previewAndSend'
								)}
								stepId={STEP4}>
								<Step4 match={props.match} />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};
