import _ from 'lodash';
import * as nodeUrl from 'url';
import { tokenService } from './token-service';
import queryString from 'query-string';
import {
	AcpDomain,
	Token,
	Dictionary,
	ApiEndpoints,
	FogFacility,
	AttachmentOwnership,
	LocalStorageName,
	ApiPortal,
	SignInResult,
	RegulatoryProgramName,
	Environment,
	MfaDetails,
	OrganizationTypeName,
	SignInPortal,
	UrlAction,
	Hauler,
	OrganizationType,
	PermissionGroupName,
	IppIndustry,
	CustomFormType,
	TranslationLanguage
} from '@rcp/types';
import { localizationService } from './localizationService';
import { localStorageService } from './localStorageService';
import { apiService } from './data-service';
import { IppConstants } from 'src/constants';
import { Logger } from './localStorageService';
import { matchPath } from 'react-router';

const location = window.location;

export const Resource = ApiEndpoints;

export const ControlQueryParameterKeys = [
	//All entries must be lower cases
	'page',
	'size',
	'sort',
	'q',
	'includes',
	'debug',
	'cachecontrol',
	'flat',
	'accesstoken',
	'format',
	'search',
	'method',
	'dryrun',
	'includeremoved',
	'includeinactive',
	'includeisinuse',
	'onlyifexist',
	'reportstatusname',
	'samplestatusname',
	'industryid',
	'ispendinguserapproval',
	'reportelementcategoryname',
	'haulerid',
	'frommap'
];

export const GridExtraQueryParameterKeys = ControlQueryParameterKeys.concat(['filterid', 'draftfilterid']);

export class QueryParameters implements Dictionary<string | string[]> {
	[key: string]: any;

	add(key: string, value: string | string[] | number): QueryParameters {
		var existingValue = this[key];
		if (!existingValue) {
			this[key] = value;
		} else {
			let values = Array.isArray(value) ? (value as string[]) : [value as string];
			if (Array.isArray(existingValue)) {
				this[key] = existingValue.concat(values);
			} else {
				this[key] = [existingValue].concat(values);
			}
		}
		return this;
	}

	put(key: string, value: string | string[] | number | null): QueryParameters {
		if (_.isString(value) || _.isArray(value)) {
			this[key] = value;
		} else {
			this[key] = `${value}`;
		}
		return this;
	}

	remove(...keys: string[]): QueryParameters {
		let keysForDelete: string[] = [];
		let currentKeys = _.keys(this);
		for (let i = 0; i < keys.length; i++) {
			let key = keys[i];
			for (let j = 0; j < currentKeys.length; j++) {
				if (String.equalCI(key, currentKeys[j])) {
					keysForDelete.push(currentKeys[j]);
					break;
				}
			}
		}
		if (keysForDelete.length > 0) {
			keysForDelete.forEach(k => {
				delete this[k];
			});
		}
		return this;
	}

	toQueryString(withQuestionMark = false): string {
		let condition: string[] = [];
		_.each(_.keys(this), (key: string) => {
			let value = this[key];
			if (Array.isArray(value)) {
				_.each(value as string[], (val: string) => {
					condition.push(`${key}=${encodeURIComponent(val as string)}`);
				});
			} else {
				condition.push(`${key}=${encodeURIComponent(value as string)}`);
			}
		});
		return (withQuestionMark ? '?' : '') + (condition.length > 0 ? `${condition.join('&')}` : '');
	}
}

const defaultRegulatoryProgram = RegulatoryProgramName.FOG.toLowerCase();

export class UrlService {
	private getCurrentProgramOrNull(path?: string): string {
		let program = null;
		let subDomain = this.getSubDomain(location.href);
		if (!this.isAccountPortal(subDomain)) {
			let paths = path ? path.split('/') : location.pathname.split('/');
			if (paths.length > 0) {
				if (Object.values(RegulatoryProgramName).includes(_.toUpper(paths[1]) as RegulatoryProgramName)) {
					program = paths[1];
				} else if (_.toLower(paths[1]) === OrganizationTypeName.Industry.toLowerCase()) {
					program = RegulatoryProgramName.IPP.toLowerCase();
				}
			}
		}
		if (program == null) {
			program = localStorageService.getLastProgram();
		}
		return program;
	}

	getCurrentProgramOrDefault(path?: string): string {
		let program = this.getCurrentProgramOrNull(path);
		return program ? program : defaultRegulatoryProgram;
	}

	getProgramOrThrowError(): string {
		let program = this.getCurrentProgramOrNull();
		if (program == null) {
			throw new Error('Current program cannot be null');
		}
		return program;
	}

	isProgram(programName: RegulatoryProgramName): boolean {
		let program = this.getProgramOrThrowError();
		return program.toLowerCase() === programName.toLowerCase();
	}

	getCurrentRegulatoryProgram(): RegulatoryProgramName {
		let program = this.getCurrentProgramOrNull();
		if (program != null) {
			if (program.toLowerCase() === RegulatoryProgramName.FOG.toLowerCase()) {
				return RegulatoryProgramName.FOG;
			}
			if (program.toLowerCase() === RegulatoryProgramName.Backflow.toLowerCase()) {
				return RegulatoryProgramName.Backflow;
			}
		}
		throw new Error('Cannot detect current program.');
	}

	getFogFacilityId(): number {
		let paths = location.pathname.split('/');
		// "/fog/Facilities/1346"
		if (
			paths.length >= 2 &&
			paths[1].toLowerCase() === defaultRegulatoryProgram &&
			paths[2].toLowerCase() === Resource.FogFacilities.toLowerCase()
		) {
			return parseInt(paths[3]);
		}
		return -1;
	}

	getFogDeviceId(): number {
		let paths = location.pathname.split('/');
		// "/fog/Devices/1346"
		if (
			paths.length >= 2 &&
			paths[1].toLowerCase() === defaultRegulatoryProgram &&
			paths[2].toLowerCase() === Resource.Devices.toLowerCase()
		) {
			return parseInt(paths[3]);
		}
		return -1;
	}

	getFogFacilityIdOrThrowError(id?: number): number {
		let facilityId: number;
		if (id) {
			facilityId = id;
		} else {
			facilityId = this.getFogFacilityId();
		}
		if (!facilityId || facilityId < 1) {
			throw new Error(localizationService.getLocalizedString('alertMessages.facilityIdNotInUrl'));
		}

		return facilityId;
	}

	getFogDeviceIdOrThrowError(id?: number): number {
		let deviceId: number;
		if (id) {
			deviceId = id;
		} else {
			deviceId = this.getFogDeviceId();
		}
		if (!deviceId || deviceId < 1) {
			throw new Error(localizationService.getLocalizedString('alertMessages.deviceIdNotInUrl'));
		}
		return deviceId;
	}

	getCccSiteId(): number {
		let paths = location.pathname.split('/');
		// "/backflow/Sites/1346"
		if (
			paths.length >= 2 &&
			paths[1].toLowerCase() === 'backflow' &&
			paths[2].toLowerCase() === Resource.CccSites.toLowerCase()
		) {
			return parseInt(paths[3]);
		}
		return -1;
	}

	getCccSiteIdOrThrowError(): number {
		let siteId = this.getCccSiteId();
		if (!siteId || siteId < 1) {
			throw new Error(localizationService.getLocalizedString('alertMessages.cccSiteIdNotInUrl'));
		}

		return siteId;
	}

	isViewCccSite(): boolean {
		let siteId = this.getCccSiteId();
		return siteId > 0;
	}

	isViewCccazard(): boolean {
		let hazardId = this.getCccHazardId();
		return hazardId > 0;
	}

	getCccHazardId(): number {
		let paths = location.pathname.split('/');
		// "/backflow/hazards/1346"
		if (
			paths.length >= 2 &&
			paths[1].toLowerCase() === 'backflow' &&
			paths[2].toLowerCase() === Resource.CccHazards.toLowerCase()
		) {
			return parseInt(paths[3]);
		}
		return -1;
	}

	getCccHazardIdOrThrowError(): number {
		let hazardId = this.getCccHazardId();
		if (!hazardId || hazardId < 1) {
			throw new Error(localizationService.getLocalizedString('alertMessages.cccHazardIdNotInUrl'));
		}

		return hazardId;
	}

	getDomainObjectName(): string {
		let paths = location.pathname.split('/');

		if (paths.length >= 1) {
			return paths[2];
		}
		throw new Error(`Cannot get a domain object from ${location.pathname}`);
	}

	getDomainObjectId(): number {
		let paths = location.pathname.split('/');

		if (paths.length >= 2) {
			return parseInt(paths[3]);
		}
		throw new Error(`Cannot get a domain object ID from ${location.pathname}`);
	}

	getFacilityAttachmentId(): number {
		let paths = location.pathname.split('/');
		// "Facilities/1346/Attachments/1123"
		if (paths[3].toLowerCase() === Resource.Attachments.toLowerCase() && paths.length >= 4) {
			return parseInt(paths[4]);
		}
		return -1;
	}

	getAttachmentIdFromUrl(): number {
		let paths = location.pathname.toLowerCase().split('/attachments/');
		return parseInt(paths[1]);
	}

	getAttachmentReturnToUrl(): string {
		let paths = location.pathname.toLowerCase().split('/attachments/');
		// "Public/InspectionView/3553fe79-8a5d-481c-981a-275efe489cda/attachments/92"
		return paths[0];
	}

	getAttachmentNavigateUrl(attachment: AttachmentOwnership): string {
		let url = this.getAttachmentReturnToUrl();
		url = `${url}/attachments/${attachment.attachmentId}`;
		return `${url}${location.search}`;
	}

	isPublicScreen(): boolean {
		return document.location.pathname.toLowerCase().indexOf('/public/'.toLowerCase()) >= 0;
	}

	isPublicEmailViewScreen(): boolean {
		return document.location.pathname.toLowerCase().indexOf('/public/emailView/'.toLowerCase()) >= 0;
	}

	isHideSideNav(): boolean {
		return (
			this.isPublicScreen() ||
			document.location.pathname.toLowerCase().indexOf('/dashboard/addWidget'.toLowerCase()) >= 0 ||
			document.location.pathname.toLowerCase().indexOf('/dashboard/editWidget/'.toLowerCase()) >= 0
		);
	}

	isMapView(): boolean {
		return document.location.pathname.toLowerCase().indexOf('/fog/map'.toLowerCase()) >= 0;
	}

	isAccountScreen(): boolean {
		return (
			_.toLower(document.location.pathname)
				.toLowerCase()
				.indexOf('/account/') >= 0
		);
	}

	isHaulerInviteScreen(): boolean {
		return (
			_.toLower(document.location.pathname)
				.toLowerCase()
				.indexOf('/invite') >= 0
		);
	}

	isHideMain(): boolean {
		return (
			document.location.pathname.toLowerCase().indexOf('/dashboard/addWidget'.toLowerCase()) >= 0 ||
			document.location.pathname.toLowerCase().indexOf('/dashboard/editWidget/'.toLowerCase()) >= 0
		);
	}

	getDashboardWidgetIdFromUrl(): number | undefined {
		let paths = location.pathname.toLowerCase().split('/dashboard/editWidget/'.toLowerCase());

		if (paths.length === 2) {
			return parseInt(paths[1]);
		}
		return undefined;
	}

	isImportResults(): boolean {
		return window.location.pathname.toLowerCase().includes('/import/');
	}

	isDebugMode(): boolean {
		if (Logger.isDebugEnabled()) {
			//Also return true when a developer manually set the browser logLevel to debug mode, check class Logger comment for detail.
			return true;
		}
		let qs = location.search;
		let queryParameters = queryString.parse(qs);
		let debugParameterValue = queryParameters.debug;
		return debugParameterValue === '1' || debugParameterValue === 'true';
	}

	getSettingPath(): string {
		let url = `/${this.getProgramOrThrowError()}/${_.toLower(Resource.Settings)}`;
		if (this.isHaulerPortal() || this.isFacilityPortal()) {
			let regulateeUrl = this.getUrlWithRegulateeTypeAndGuid() as string;
			url = `/${regulateeUrl.toLowerCase()}/${_.toLower(Resource.Settings)}`;
		}
		return url;
	}

	getInvitePath(): string {
		let regulateeUrl = this.getUrlWithRegulateeTypeAndGuid() as string;
		return `/${regulateeUrl.toLowerCase()}/${_.toLower(Resource.Invite)}`;
	}

	getSettingMenuPath(menuPath: string): string {
		return _.startsWith(menuPath, '/')
			? `${this.getSettingPath()}${menuPath}`
			: `${this.getSettingPath()}/${menuPath}`;
	}

	getHelpPath(): string {
		return `${this.getApiBaseUrlWithProgram()}/help`;
	}

	getReactBaseUrl(): string {
		return location.protocol + '//' + location.host;
	}
	getReactAuthorityResourcesPath(resources: string): string {
		return `/${this.getProgramOrThrowError()}/${resources}`;
	}
	getReactAuthorityResourcePath(resources: string, resourceId?: number): string {
		return `/${this.getProgramOrThrowError()}/${resources}/${resourceId}`;
	}
	getAuthorityBulkUpdatePath(queryString?: string): string {
		let url = `/${this.getProgramOrThrowError()}/${Resource.FogFacilities}/BulkUpdate`;
		if (!_.isEmpty(queryString)) {
			url = `${url}?${queryString}`;
		}

		return url;
	}
	getFogDeviceBulkUpdatePath(queryString?: string): string {
		let url = `/${this.getProgramOrThrowError()}/${Resource.Devices}/BulkUpdate`;
		if (!_.isEmpty(queryString)) {
			url = `${url}?${queryString}`;
		}

		return url;
	}
	getAuthorityBatchUpdateResourcesApiUrl(queryString?: string, resource?: any): string {
		let url = `${this.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}`;

		if (resource) {
			url = `${this.getApiBaseUrlWithProgram()}/${resource}`;
		}
		if (!_.isEmpty(queryString)) {
			url += `${queryString}`;
		}
		return url;
	}

	getReactBaseUrlForSubDomain(subDomain: string): string {
		let hostname = nodeUrl.parse(location.href, true).hostname;

		if (hostname) {
			let oldSubDomain = _.toLower(hostname.split('.')[0]);
			hostname = hostname.replace(oldSubDomain, subDomain);
		}

		return `${location.protocol}//${hostname}`;
	}

	getReactBaseUrlWithProgramName(subDomain: string, programName: string): string {
		return `${this.getReactBaseUrlForSubDomain(subDomain)}/${programName}`;
	}
	getReactBaseUrlWithSubDomain(subDomain: string, resources: string): string {
		let guidWithOrganization = this.getUrlWithRegulateeTypeAndGuid();
		if (guidWithOrganization) {
			return `${this.getReactBaseUrlForSubDomain(subDomain)}${this.composePort(
				location.port
			)}/${guidWithOrganization}/${resources}`;
		}
		return `${this.getReactBaseUrlForSubDomain(subDomain)}${this.composePort(
			location.port
		)}/${this.getProgramOrThrowError()}/${resources}`;
	}
	getReactResourceUrl(resources: string, resourceId?: number | string): string {
		let guidWithOrganization = this.getUrlWithRegulateeTypeAndGuid();
		if (guidWithOrganization) {
			return resourceId
				? `${this.getReactBaseUrl()}/${guidWithOrganization}/${resources}/${resourceId}`
				: `${this.getReactBaseUrl()}/${guidWithOrganization}/${resources}`;
		}
		return this.getReactAuthorityResourceUrl(resources, resourceId);
	}
	getReactAuthorityResourceUrl(resources: string, resourceId?: number | string): string {
		return resourceId
			? `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${resources}/${resourceId}`
			: `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${resources}`;
	}
	getReactFacilityDetailsUrl(facilityId?: number): string {
		return this.getReactAuthorityResourceUrl(Resource.FogFacilities, facilityId);
	}
	getCurrentFacilityDetailsUrl(facilityId?: number): string {
		return this.getReactFacilityDetailsUrl(facilityId || this.getFogFacilityId());
	}
	getFacilityDetailsUrlFromFacility(fogFacility: FogFacility): string {
		return this.getReactAuthorityResourceUrl(Resource.FogFacilities, _.toNumber(fogFacility.facilityId));
	}
	getFacilityDetailsUrl(facilityId: any): string {
		return this.getReactAuthorityResourceUrl(Resource.FogFacilities, facilityId);
	}

	getViolationSourceUrl(facilityId?: number, sourceId?: number, sourceType?: string) {
		//TODO to determine url by sourceType later
		// Right now just return inspection as source
		return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${
			Resource.FogFacilities
		}/${facilityId}/inspections/${sourceId}`;
	}

	getFacilityInspectionDetailsUrl(facilityId?: number, inspectionId?: number): string {
		return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${
			Resource.FogFacilities
		}/${facilityId}/inspections/${inspectionId}`;
	}

	getReactFacilityAttachmentUrl(facilityId?: number, facilityAttachmentId?: number): string {
		return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${Resource.FogFacilities}/${facilityId}/${
			Resource.Attachments
		}/${facilityAttachmentId}`;
	}

	// Ipp Url Fetchers
	getReactAuthorityAccountUserDetailsUrl(resourceId?: number): string {
		return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/authority/authorityAccount/users/user/${resourceId}`;
	}

	isAdministrator(): boolean {
		let token = tokenService.getTokenOrDefault();
		let currentProgram = [
			_.toLower(RegulatoryProgramName.HAULER),
			_.toLower(RegulatoryProgramName.FACILITY)
		].includes(this.getProgramOrThrowError())
			? _.toLower(RegulatoryProgramName.FOG)
			: this.getProgramOrThrowError();
		let issuedPrograms = _.split(token.portalRegulatoryProgramNames as string, ',');
		let issuedRoles = _.split(token.portalPermissionGroupNames as string, ',');
		for (let index = 0; index < issuedPrograms.length; index++) {
			if (_.toLower(issuedPrograms[index]) === _.toLower(currentProgram)) {
				let logonUserIssuedProgramRole = issuedRoles[index];
				return _.toLower(logonUserIssuedProgramRole) === _.toLower('Administrator');
			}
		}
		throw new Error('Cannot locate logon user issued program');
	}

	getApiBaseUrl(portal = ApiPortal.Authority): string {
		let hostname = portal === ApiPortal.Authority ? location.hostname : this.getAccountPortalHostname();
		if (location.host.indexOf(':3000') !== -1) {
			return location.protocol + '//' + hostname + ':5000/api/1.0';
		}
		return location.protocol + '//' + hostname + '/api/1.0';
	}

	getApiAccountBaseUrl(): string {
		return this.getApiBaseUrl(ApiPortal.Account);
	}

	getUserProfileUrl(): string {
		let subdomain = this.getSubDomain(location.href);
		return this.getApiBaseUrl(ApiPortal.Account) + '/Account/UserProfile?subdomain=' + subdomain;
	}

	getApiBaseUrlWithProgram(portal = ApiPortal.Authority): string {
		let organizationTypeWithGuid = this.getUrlWithRegulateeTypeAndGuid();
		if (organizationTypeWithGuid) {
			return `${this.getApiBaseUrl(portal)}/${organizationTypeWithGuid}`;
		}
		return `${this.getApiBaseUrl(portal)}/${this.getProgramOrThrowError()}`;
	}

	getApiAdminBaseUrl(portal = ApiPortal.Authority): string {
		let hostname = portal === ApiPortal.Authority ? location.hostname : this.getAccountPortalHostname();
		if (location.host.indexOf(':3000') !== -1) {
			return location.protocol + '//' + hostname + ':5000/api/1.0/admin';
		}
		return location.protocol + '//' + hostname + '/api/1.0/admin';
	}

	getAuthoritySettingResourceApiUrl(settingType: string, querystring = ''): string {
		let url = `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${settingType}`;
		if (!_.isEmpty(querystring)) {
			url += `?${querystring}`;
		}
		return url;
	}

	getSampleFlowUnitsResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/Units/FlowUnits`;
	}

	getTimeZoneResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/TimeZones`;
	}

	getSmtpSecurityResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${Resource.Lookups}/SmtpEncryptions`;
	}

	getSmtpProtocolResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${Resource.Lookups}/SmtpProtocols`;
	}

	getAuthorityOrpDetailApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.AuthorityDetails}`;
	}

	getAuthorityEventCategoryUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${Resource.Lookups}/EventCategories`;
	}

	getAuthorityLookupResourceApiUrl(lookupType: string, querystring = ''): string {
		let url = `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${Resource.Lookups}/${lookupType}`;
		if (!_.isEmpty(querystring)) {
			url += `?${querystring}`;
		}
		return url;
	}

	getAuthorityProgramResourcesApiUrl(program: RegulatoryProgramName, resources: string, querystring = ''): string {
		let url = `${this.getApiBaseUrl(ApiPortal.Authority)}/${program}/${resources}`;
		if (!_.isEmpty(querystring)) {
			url += `?${querystring}`;
		}
		return url;
	}
	getAuthorityResourcesApiUrl(resources: string, querystring = ''): string {
		let url = `${this.getApiBaseUrlWithProgram()}/${resources}`;
		if (!_.isEmpty(querystring)) {
			url += `?${querystring}`;
		}
		return url;
	}

	getAuthorityLookupUrlForService(
		authorityOrganizationId: number,
		lookupType: string,
		queryString: string = ''
	): string {
		let url = `${Resource.Authorities}/${authorityOrganizationId}/${Resource.Settings}/${Resource.Lookups}/${lookupType}`;
		if (queryString) {
			url += `?${queryString}`;
		}
		return urlService.getAuthorityResourcesApiUrl(url);
	}

	getAuthorityLookupUrlFromEndPoint(
		endpoint: string,
		templateType: string,
		authorityOrganizationId?: number
	): string {
		let endpointLowercase = _.toLower(endpoint);
		if (urlService.isHaulerPortal()) {
			return (
				endpointLowercase &&
				urlService.getAuthorityResourcesApiUrl(
					`${Resource.Authorities}/${authorityOrganizationId || 0}/${endpointLowercase.replace(
						'fog/settings/',
						'settings/'
					)}`
				)
			);
		} else if (urlService.isFacilityPortal()) {
			return (
				endpointLowercase &&
				urlService.getAuthorityResourcesApiUrl(`${endpointLowercase.replace('fog/settings/', 'settings/')}`)
			);
		} else {
			return `${urlService.getApiBaseUrl()}/${endpointLowercase || ''}`;
		}
	}

	getAuthoritySettingsUrlForService(authorityOrganizationId: number, settingType: string): string {
		return urlService.getAuthorityResourcesApiUrl(
			`${Resource.Authorities}/${authorityOrganizationId}/${Resource.Settings}/${settingType}`
		);
	}

	getAuthorityTestEmailUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.Settings}/${Resource.AuthoritySettings}/TestEmails`;
	}

	getAuthorityResourceApiUrl(resources: string, resourceId: number, querystring = ''): string {
		let url = `${this.getApiBaseUrlWithProgram()}/${resources}/${resourceId}`;
		if (!_.isEmpty(querystring)) {
			url += `?${querystring}`;
		}

		return url;
	}

	getFacilityEmailTemplateUrl(templateId: number, facilityId: number): string {
		return `${this.getApiBaseUrlWithProgram()}/LetterTemplateRender/FacilityEmail?facilityId=${facilityId}`;
	}

	getInspectionEmailTemplateUrl(templateId: number, inspectionEventId: number): string {
		return `${this.getApiBaseUrlWithProgram()}/LetterTemplateRender/InspectionEmail?inspectionEventId=${inspectionEventId}`;
	}

	getDeviceEmailTemplateUrl(facilityId: number): string {
		return `${this.getApiBaseUrlWithProgram()}/LetterTemplateRender/DeviceEmail?facilityId=${facilityId}`;
	}

	getFacilitiesResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}`;
	}

	getFacilityResourceApiUrl(facilityId: number, resources: string, resourceId?: number): string {
		if (resourceId) {
			return `${this.getFacilitiesResourceApiUrl()}/${facilityId}/${resources}/${resourceId}`;
		}

		return `${this.getFacilitiesResourceApiUrl()}/${facilityId}/${resources}`;
	}

	getInspectionFormByTemplateIdUrl(inspectionId: number, templateId: number): string {
		return `${this.getApiBaseUrlWithProgram()}/${Resource.InspectionEvents}/${inspectionId}/${
			Resource.InspectionForms
		}/${templateId}`;
	}

	getInspectionPublicUrl(inspectionPublicId: string): string {
		return `${this.getApiBaseUrlWithProgram()}/Public/${Resource.InspectionView}/${inspectionPublicId}`;
	}

	getEmailPublicUrl(publicId: string): string {
		return `${this.getApiBaseUrl(ApiPortal.Account)}/public/${Resource.EmailView}/${publicId}`;
	}

	getSubDomain(url: string): string {
		if (url) {
			let hostname = nodeUrl.parse(url).hostname;
			if (hostname) {
				return _.toLower(hostname.split('.')[0]);
			}
		}

		throw new Error(`'Cannot get subdomain from url ${url}`);
	}

	appendLanguageParameter(url: string, languageCode?: string): string {
		if (!languageCode) {
			let language = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage);
			languageCode = language ? language.code : 'EN';
		}
		if (url.indexOf('?') > 0) {
			return url + `&lang=${languageCode}`;
		}
		return url + `?lang=${languageCode}`;
	}

	private getAccountPortalHostname(): string {
		let subDomain = this.getSubDomain(location.href);
		let accountHostname: string;
		let acpPartPos = subDomain.indexOf('-acp-');
		if (acpPartPos > 0) {
			accountHostname = `account${subDomain.substring(acpPartPos)}.${AcpDomain}`;
		} else {
			accountHostname = `account-acp.${AcpDomain}`;
		}
		return `${accountHostname}`;
	}

	getEnvironment(): Environment {
		let subDomain = this.getSubDomain(location.href);
		if (subDomain.includes('-acp-dev') || subDomain.includes('-acp-adb')) {
			return Environment.Development;
		}
		if (subDomain.includes('-acp-auto')) {
			return Environment.AutoServer;
		}
		if (subDomain.includes('-acp-test')) {
			return Environment.Test;
		}
		if (subDomain.includes('-acp-beta')) {
			return Environment.Beta;
		}
		if (subDomain.includes('-acp-staging')) {
			return Environment.Staging;
		}
		return Environment.Production;
	}

	composePort(port: string): string {
		return port && !(port === '80' || port === '443') ? `:${port}` : '';
	}

	isAccountPortal(inputSubDomain?: string): boolean {
		let subDomain = inputSubDomain || this.getSubDomain(location.href);
		return subDomain.startsWith('account-acp');
	}

	isServicePortal(inputSubDomain?: string): boolean {
		let subDomain = inputSubDomain || this.getSubDomain(location.href);
		return subDomain.startsWith('service-acp');
	}

	isUrlIncludeHaulerSubdomain(inputSubDomain?: string): boolean {
		let subDomain = inputSubDomain || this.getSubDomain(location.href);
		return subDomain.startsWith('hauler-acp');
	}

	isUrlIncludeFacilitySubdomain(inputSubDomain?: string): boolean {
		let subDomain = inputSubDomain || this.getSubDomain(location.href);
		return subDomain.startsWith('facility-acp');
	}

	isSignInPage(): boolean {
		return (
			_.toLower(document.location.pathname)
				.toLowerCase()
				.indexOf('/signin') >= 0
		);
	}

	getAqiOpenIdLoginUrl(): string {
		let subDomain = this.getSubDomain(location.href);
		let returnUrl: string = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
			location.port
		)}/api/1.0/account/authorize/callback`;
		if (this.isUrlIncludeHaulerSubdomain()) {
			returnUrl += `?action=${UrlAction.HaulerRegistration}`;
		} else if (this.isUrlIncludeFacilitySubdomain()) {
			returnUrl += `?action=${UrlAction.FacilityRegistration}`;
		} else if (this.isAccountPortal(subDomain)) {
			//do nothing
		} else {
			let continueUrl = location.href;
			returnUrl += `?continueUrl=${encodeURIComponent(continueUrl)}`;
		}
		let queryParams: Dictionary<string> = {
			ReturnUrl: returnUrl,
			responseType: 'id_token token'
		};
		let loginurl = `https://api-feature-us.aquaticinformatics.net/authentication/login?${this.toQueryString(
			queryParams
		)}`;
		return loginurl;
	}

	getLoginUrl(extraQueryString?: string): string {
		let subDomain = this.getSubDomain(location.href);
		let loginUrl: string;
		if (this.isUrlIncludeHaulerSubdomain()) {
			loginUrl = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
				location.port
			)}/SignIn?action=${UrlAction.HaulerRegistration}`;
		} else if (this.isUrlIncludeFacilitySubdomain()) {
			loginUrl = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
				location.port
			)}/SignIn?action=${UrlAction.FacilityRegistration}`;
		} else if (this.isAccountPortal(subDomain)) {
			loginUrl = `${location.protocol}//${location.host}/SignIn`;
		} else {
			let continueUrl = location.href;
			loginUrl = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
				location.port
			)}/SignIn?continue=${encodeURIComponent(continueUrl)}`;
		}
		if (extraQueryString) {
			loginUrl += loginUrl.indexOf('?') > 0 ? `&${extraQueryString}` : `?${extraQueryString}`;
		}
		return loginUrl;
	}

	getPortalDirectorUrl(): string {
		let subDomain = this.getSubDomain(location.href);
		let portalDirectorUrl: string;
		if (this.isAccountPortal(subDomain)) {
			portalDirectorUrl = `${location.protocol}//${location.host}/account/organizations`;
		} else {
			portalDirectorUrl = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
				location.port
			)}/account/organizations`;
		}
		return portalDirectorUrl;
	}

	getProfilePage(): string {
		let subDomain = this.getSubDomain(location.href);
		let profileUrl: string;
		if (this.isAccountPortal(subDomain)) {
			profileUrl = `${location.protocol}//${location.host}/account/profile`;
		} else {
			profileUrl = `${location.protocol}//${this.getAccountPortalHostname()}${this.composePort(
				location.port
			)}/account/profile`;
		}
		return profileUrl;
	}

	getHaulerFacilityDetailPage(facilityId: number, haulerId?: number): string {
		const facilityString = localizationService.getLocalizedString('screen.labels.facility');
		if (urlService.isServicePortal()) {
			let { guid } = urlService.getPortalStatus();
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${guid}/${facilityString}/${facilityId}`;
		} else {
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${
				Resource.Haulers
			}/${haulerId}/${facilityString}/${facilityId}`;
		}
	}

	getHaulerSelectFacilitiesPage(haulerId?: number): string {
		if (urlService.isServicePortal()) {
			let { guid } = urlService.getPortalStatus();
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${guid}/${
				Resource.FogFacilities
			}/select`;
		} else {
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${Resource.Haulers}/${haulerId}/${
				Resource.FogFacilities
			}/select`;
		}
	}

	getHaulerFacilitiesPage(haulerId?: number): string {
		if (urlService.isServicePortal()) {
			let { guid } = urlService.getPortalStatus();
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${guid}/${Resource.FogFacilities}`;
		} else {
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/${Resource.Haulers}/${haulerId}/${
				Resource.FogFacilities
			}`;
		}
	}

	signInToPortal = (signInResult: SignInResult, language: TranslationLanguage) => {
		let query = nodeUrl.parse(location.href, true).query;
		let continueUrl = query['continue'] as string;
		let forwardUrl: string = continueUrl || signInResult.forwardUrl || '';
		if (forwardUrl) {
			let forwardSubDomain = this.getSubDomain(forwardUrl);
			//Should forward to the portal
			let allowedPortal = _.find(signInResult.allowedPortals, x => {
				return _.toLower(x.subDomain) === _.toLower(forwardSubDomain);
			});
			if (!allowedPortal) {
				Logger.error(`The user is not allowed to access ${forwardUrl}`);
				return;
			}
			let accessProgram = forwardUrl.split('/')[3].toLowerCase();
			let issuedPrograms = allowedPortal.userIssuedPrograms.filter((r: any) => {
				if (r.regulatoryProgramName.toLowerCase() === RegulatoryProgramName.IPP.toLowerCase()) {
					return (
						r.regulatoryProgramName.toLowerCase() === accessProgram ||
						accessProgram === OrganizationTypeName.Industry.toLowerCase()
					);
				} else {
					return r.regulatoryProgramName.toLowerCase() === accessProgram;
				}
			});
			if (issuedPrograms.length === 0) {
				Logger.error(`The user is not allowed to access ${forwardUrl} program ${accessProgram}`);
				return;
			}
			if (!forwardUrl.includes('/ipp/') && !forwardUrl.includes('/fog/') && !forwardUrl.includes('/backflow/')) {
				Logger.error(`Unknown forwardUrl: ${forwardUrl}`);
				return;
			}
			if (_.isEmpty(allowedPortal.userIssuedPrograms)) {
				throw new Error('Server should always return an existing forward subdomain plus one allowed program');
			}

			let userIssuedRegulatoryProgramNames = allowedPortal.userIssuedPrograms
				.map(r => r.regulatoryProgramName.toLowerCase())
				.join(',');
			let userIssuedPermissionGroupNames = allowedPortal.userIssuedPrograms
				.map(r => r.permissionGroupName)
				.join(',');
			let organizationRegulatoryProgramId = issuedPrograms[0].organizationRegulatoryProgramId;
			let mfaEnabledPrograms = urlService.getMfaEnabledPrograms(
				signInResult.regulatoryProgramMFATypeDetails as MfaDetails[]
			);

			let token: Token = {
				accessToken: signInResult.accessToken as string,
				expirationEpochTimeInUtc: signInResult.expirationEpochTimeInUtc as number,
				refreshToken: signInResult.refreshToken as string,
				ssoFrom: signInResult.ssoFrom as string,
				correlationId: signInResult.correlationId,
				portalOrganizationId: allowedPortal.organizationId,
				portalOrganizationName: allowedPortal.organizationName,
				portalOrganizationTypeId: allowedPortal.organizationTypeId,
				portalOrganizationTypeName: allowedPortal.organizationTypeName,
				portalRegulatoryProgramNames: userIssuedRegulatoryProgramNames,
				portalPermissionGroupNames: userIssuedPermissionGroupNames,
				portalSubDomain: allowedPortal.subDomain,
				portalOrganizationRegulatoryProgramId: organizationRegulatoryProgramId.toString(),
				userName: signInResult.userName,
				email: signInResult.email,
				ianaTimeZoneName: allowedPortal.ianaTimeZoneName,
				mfaEnabledPrograms: mfaEnabledPrograms,
				guid: signInResult.guid || allowedPortal.guid,
				language: language
			};
			Logger.info(`Sign on succeeded and move to ${forwardUrl}`);
			return { token, forwardUrl };
		}
		let path = query['path'];
		if (path && this.isAccountPortal()) {
			let forwardAccountUrl = `${urlService.getAccountUrl()}${path}`;
			window.location.href = forwardAccountUrl;
		}
		return undefined;
	};

	removePortalsWithRoleCTSSyncUser = (allowedPortals: any) => {
		const roleNotAllowed = 'CTSSyncUser';
		let newAllowedPortals = _.cloneDeep(allowedPortals);
		newAllowedPortals = newAllowedPortals.filter((allowedPortal: any) => {
			allowedPortal.userIssuedPrograms = allowedPortal.userIssuedPrograms.filter((program: any) => {
				if (program.permissionGroupName !== roleNotAllowed) {
					return true;
				}
				return false;
			});
			if (allowedPortal.userIssuedPrograms.length > 0) {
				return true;
			}
			return false;
		});
		if (!newAllowedPortals.length) {
			return newAllowedPortals;
		}
		return newAllowedPortals;
	};

	getForwardToSinglePortalUrl = (
		signInResult: any,
		forwardPortalName?: SignInPortal,
		language?: TranslationLanguage
	) => {
		signInResult.allowedPortals = this.removePortalsWithRoleCTSSyncUser(signInResult.allowedPortals);
		if (!signInResult.allowedPortals || !signInResult.allowedPortals.length) {
			return;
		}
		let portal = forwardPortalName || signInResult.allowedPortals[0];
		if (_.isEmpty(portal.userIssuedPrograms)) {
			throw new Error('Server should always return an existing forward subdomain plus one allowed program');
		}
		let userIssuedRegulatoryProgramNames = portal.userIssuedPrograms
			.map((r: any) => r.regulatoryProgramName)
			.join(',');
		let userIssuedPermissionGroupNames = portal.userIssuedPrograms.map((r: any) => r.permissionGroupName).join(',');
		let organizationRegulatoryProgramId = portal.userIssuedPrograms[0].organizationRegulatoryProgramId;
		let mfaEnabledPrograms = urlService.getMfaEnabledPrograms(
			signInResult.regulatoryProgramMFATypeDetails as MfaDetails[]
		);
		let token: Token = {
			accessToken: signInResult.accessToken as string,
			expirationEpochTimeInUtc: signInResult.expirationEpochTimeInUtc as number,
			refreshToken: signInResult.refreshToken as string,
			ssoFrom: signInResult.ssoFrom,
			correlationId: signInResult.correlationId,
			portalOrganizationId: portal.organizationId,
			portalOrganizationName: portal.organizationName,
			portalOrganizationTypeId: portal.organizationTypeId,
			portalOrganizationTypeName: portal.organizationTypeName,
			portalRegulatoryProgramNames: userIssuedRegulatoryProgramNames,
			portalPermissionGroupNames: userIssuedPermissionGroupNames,
			portalSubDomain: portal.subDomain,
			portalOrganizationRegulatoryProgramId: organizationRegulatoryProgramId,
			aqiIdpTenantId: portal.aqiIdpTenantId,
			userName: signInResult.userName,
			email: signInResult.email,
			ianaTimeZoneName: portal.ianaTimeZoneName,
			mfaEnabledPrograms: mfaEnabledPrograms,
			guid: portal.guid,
			language: language
		};
		return urlService.getLandingPortalUrl(token, undefined);
	};

	getLandingPortalUrl = (token: Token, portalUrl?: string, program?: string): string => {
		let portalSubDomain = portalUrl ? this.getSubDomain(portalUrl) : token.portalSubDomain;
		let qs = location.search;
		let queryParameters = queryString.parse(qs);
		let queryParams: Dictionary<string> = {
			accessToken: token.accessToken as string,
			expired: `${token.expirationEpochTimeInUtc}`,
			refreshToken: token.refreshToken,
			ssoFrom: token.ssoFrom,
			correlationId: token.correlationId,
			organizationId: `${token.portalOrganizationId}`,
			organizationName: token.portalOrganizationName,
			regulatoryProgramNames: `${token.portalRegulatoryProgramNames}`,
			permissionGroupNames: `${token.portalPermissionGroupNames}`,
			organizationTypeId: `${token.portalOrganizationTypeId}`,
			organizationTypeName: token.portalOrganizationTypeName,
			organizationRegulatoryProgramId: `${token.portalOrganizationRegulatoryProgramId}`,
			aqiIdpTenantId: token.aqiIdpTenantId,
			userName: token.userName,
			email: token.email,
			ianaTimeZoneName: token.ianaTimeZoneName,
			landingUrl: portalUrl,
			selectedProgram: program,
			mfaEnabledPrograms: token.mfaEnabledPrograms,
			helpPath: queryParameters.helpPath,
			redirectUrl: token.redirectUrl,
			guid: token.guid,
			language: JSON.stringify(token.language)
		} as Dictionary<string>;
		let portalLandingUrl = `${location.protocol}//${portalSubDomain}.${AcpDomain}${this.composePort(
			location.port
		)}/?${this.toQueryString(queryParams)}`;
		return portalLandingUrl;
	};

	getLandingPortalUrlForHaulerPortal = (signInResult: any): string | undefined => {
		signInResult.allowedPortals = this.removePortalsWithRoleCTSSyncUser(signInResult.allowedPortals);
		const haulerPortal = signInResult.allowedPortals.find((portal: SignInPortal) => {
			return portal.organizationTypeName === 'Hauler';
		});
		if (!signInResult.allowedPortals || !signInResult.allowedPortals.length || !haulerPortal) {
			return;
		}
		let portal = haulerPortal;
		if (_.isEmpty(portal.userIssuedPrograms)) {
			throw new Error('Server should always return an existing forward subdomain plus one allowed program');
		}
		let userIssuedRegulatoryProgramNames = portal.userIssuedPrograms
			.map((r: any) => r.regulatoryProgramName)
			.join(',');
		let userIssuedPermissionGroupNames = portal.userIssuedPrograms.map((r: any) => r.permissionGroupName).join(',');
		let organizationRegulatoryProgramId = portal.userIssuedPrograms[0].organizationRegulatoryProgramId;
		let mfaEnabledPrograms = urlService.getMfaEnabledPrograms(
			signInResult.regulatoryProgramMFATypeDetails as MfaDetails[]
		);
		let token: Token = {
			accessToken: signInResult.accessToken as string,
			expirationEpochTimeInUtc: signInResult.expirationEpochTimeInUtc as number,
			refreshToken: signInResult.refreshToken as string,
			ssoFrom: signInResult.ssoFrom,
			correlationId: signInResult.correlationId,
			portalOrganizationId: portal.organizationId,
			portalOrganizationName: portal.organizationName,
			portalOrganizationTypeId: portal.organizationTypeId,
			portalOrganizationTypeName: portal.organizationTypeName,
			portalRegulatoryProgramNames: userIssuedRegulatoryProgramNames,
			portalPermissionGroupNames: userIssuedPermissionGroupNames,
			portalSubDomain: portal.subDomain,
			portalOrganizationRegulatoryProgramId: organizationRegulatoryProgramId,
			aqiIdpTenantId: portal.aqiIdpTenantId,
			userName: signInResult.userName,
			email: signInResult.email,
			ianaTimeZoneName: portal.ianaTimeZoneName,
			mfaEnabledPrograms: mfaEnabledPrograms,
			guid: portal.guid
		};
		return urlService.getLandingPortalUrl(token, undefined);
	};

	getLandingPortalUrlForHaulerPortalFromFog = (hauler: Hauler) => {
		var existingToken = tokenService.getTokenOrDefault();
		let token: Token = {
			accessToken: existingToken.accessToken as string,
			expirationEpochTimeInUtc: existingToken.expirationEpochTimeInUtc as number,
			guid: hauler.guid as string,
			ianaTimeZoneName: hauler.ianaTimeZoneName,
			mfaEnabledPrograms: existingToken.mfaEnabledPrograms as string,
			refreshToken: existingToken.refreshToken as string,
			ssoFrom: existingToken.ssoFrom,
			correlationId: existingToken.correlationId,
			portalOrganizationId: hauler.haulerOrganizationId,
			portalOrganizationName: hauler.name,
			portalOrganizationTypeId: OrganizationType.Hauler,
			portalOrganizationTypeName: OrganizationTypeName.Hauler,
			portalRegulatoryProgramNames: RegulatoryProgramName.FOG,
			portalPermissionGroupNames: PermissionGroupName.Administrator,
			portalOrganizationRegulatoryProgramId: Number(hauler.haulerOrganizationRegulatoryProgramId).toString(),
			userName: existingToken.userName,
			email: existingToken.email,
			version: existingToken.version
		};
		return urlService.getLandingPortalUrl(
			token,
			this.getPortalUrl(hauler.guid as string, Resource.Hauler, Resource.FogFacilities)
		);
	};

	getLandingPortalUrlForFacilityPortalFromFog = (facility: FogFacility, setToken?: boolean) => {
		var existingToken = tokenService.getTokenOrDefault();
		let token: Token = {
			accessToken: existingToken.accessToken as string,
			expirationEpochTimeInUtc: existingToken.expirationEpochTimeInUtc as number,
			guid: facility.guid as string,
			ianaTimeZoneName: facility.ianaTimeZoneName,
			mfaEnabledPrograms: existingToken.mfaEnabledPrograms as string,
			refreshToken: existingToken.refreshToken as string,
			ssoFrom: existingToken.ssoFrom,
			portalOrganizationId: facility.facilityId,
			portalOrganizationName: facility.facilityName,
			portalOrganizationTypeId: OrganizationType.Facility,
			portalOrganizationTypeName: OrganizationTypeName.Facility,
			portalRegulatoryProgramNames: RegulatoryProgramName.FOG,
			portalPermissionGroupNames: PermissionGroupName.Administrator,
			portalOrganizationRegulatoryProgramId: Number(facility.facilityOrganizationRegulatoryProgramId).toString(),
			userName: existingToken.userName,
			email: existingToken.email,
			version: existingToken.version
		};
		if (setToken) {
			tokenService.setToken(token);
		}
		return urlService.getLandingPortalUrl(
			token,
			this.getPortalUrl(facility.guid as string, Resource.Facility, Resource.Devices)
		);
	};

	getLandingPortalUrlForIndustryPortalFromIpp = (ippIndustry: IppIndustry) => {
		var existingToken = tokenService.getTokenOrDefault();
		let token: Token = {
			accessToken: existingToken.accessToken as string,
			expirationEpochTimeInUtc: existingToken.expirationEpochTimeInUtc as number,
			guid: ippIndustry.guid as string,
			aqiIdpTenantId: ippIndustry.aqiIdpTenantId,
			ianaTimeZoneName: ippIndustry.ianaTimeZoneName,
			mfaEnabledPrograms: existingToken.mfaEnabledPrograms as string,
			refreshToken: existingToken.refreshToken as string,
			ssoFrom: existingToken.ssoFrom,
			correlationId: existingToken.correlationId,
			portalOrganizationId: ippIndustry.organizationId,
			portalOrganizationName: ippIndustry.industryName,
			portalOrganizationTypeId: OrganizationType.Industry,
			portalOrganizationTypeName: OrganizationTypeName.Industry,
			portalRegulatoryProgramNames: RegulatoryProgramName.IPP,
			portalPermissionGroupNames: PermissionGroupName.Administrator,
			portalOrganizationRegulatoryProgramId: Number(ippIndustry.ippIndustryId).toString(),
			userName: existingToken.userName,
			email: existingToken.email,
			version: existingToken.version
		};
		return urlService.getLandingPortalUrl(
			token,
			this.getPortalUrl(ippIndustry.guid as string, Resource.IppIndustry, Resource.Dashboard)
		);
	};

	private getDefaultForwardUrl = () => {
		let hostname = location.hostname.toLowerCase();
		if (hostname.startsWith('acp-')) {
			return `${location.protocol}//account-${hostname}${this.composePort(location.port)}`;
		} else if (hostname === `acp.${AcpDomain}`) {
			return `${location.protocol}//account-acp.${AcpDomain}${this.composePort(location.port)}`;
		}
		//return undefined so switchToDefaultView would continue handle none-default forward url cases
	};

	gotoLogin = () => {
		//redirect to login url
		let loginUrl = this.getLoginUrl();
		location.href = loginUrl;
	};

	switchToDefaultView = async (guid?: string) => {
		let forwardUrl = this.getDefaultForwardUrl();
		if (forwardUrl) {
			location.href = forwardUrl;
			return;
		}
		let qs = location.search;
		let queryParameters = queryString.parse(qs);
		let accessToken = queryParameters.accessToken;
		if (_.isEmpty(accessToken)) {
			//The user clicked AQI icon or directly set browser path to root
			let currentToken = tokenService.getTokenOrDefault();
			if (tokenService.isTokenValid(currentToken)) {
				let proxyPath = queryParameters.path;
				if (_.isEmpty(proxyPath)) {
					if (location.hostname.includes('service-acp') && guid) {
						location.href = `${this.getReactBaseUrl()}/hauler/${guid}/facilities`;
					} else {
						let portalDirector = this.getPortalDirectorUrl();
						location.href = portalDirector;
					}
				} else {
					let specifiedUrl = `${this.getReactBaseUrl()}/${proxyPath}`;
					location.href = specifiedUrl;
				}
			} else {
				this.gotoLogin();
			}
			return;
		}
		//The user redirect to default view with accessToken, could be accessing help site or portal directory landing to issued portal.
		let portalOrganizationId = Number(queryParameters.organizationId);
		let parsedLanguage = queryParameters.language
			? (JSON.parse(queryParameters.language as string) as TranslationLanguage)
			: undefined;
		let token: Token = {
			accessToken: queryParameters.accessToken as string,
			expirationEpochTimeInUtc: Number(queryParameters.expired),
			refreshToken: queryParameters.refreshToken as string,
			ssoFrom: queryParameters.ssoFrom as string,
			correlationId: queryParameters.correlationId as string,
			portalOrganizationId: portalOrganizationId,
			portalOrganizationName: queryParameters.organizationName as string,
			portalRegulatoryProgramNames: queryParameters.regulatoryProgramNames as string,
			portalPermissionGroupNames: queryParameters.permissionGroupNames as string,
			portalOrganizationTypeId: Number(queryParameters.organizationTypeId),
			portalOrganizationTypeName: queryParameters.organizationTypeName as string,
			portalOrganizationRegulatoryProgramId: queryParameters.organizationRegulatoryProgramId as any,
			aqiIdpTenantId: queryParameters.aqiIdpTenantId as string | undefined,
			userName: queryParameters.userName as string,
			email: queryParameters.email as string,
			ianaTimeZoneName: queryParameters.ianaTimeZoneName as string,
			mfaEnabledPrograms: queryParameters.mfaEnabledPrograms as string,
			guid: queryParameters.guid as string,
			language: parsedLanguage
		};

		tokenService.setToken(token);
		let landingUrl = queryParameters.landingUrl as string;
		if (queryParameters.helpPath) {
			//accessing help site
			let hostname = nodeUrl.parse(landingUrl, true).hostname;
			let helpUrl = location.protocol + '//' + hostname + this.composePort(location.port) + '/api/1.0/fog/help';
			let params: Dictionary<string> = {
				path: queryParameters.helpPath as string,
				accessToken: queryParameters.accessToken as string
			};
			helpUrl = `${helpUrl}/?${this.toQueryString(params)}`;
			location.href = helpUrl;
			return;
		}
		//account portal directory landing to issued portal
		if (landingUrl) {
			await this.createLandingAuditLog(token);
			location.href = landingUrl;
		} else if (
			token.portalOrganizationTypeName === OrganizationTypeName.Authority ||
			token.portalOrganizationTypeName === OrganizationTypeName.Industry ||
			token.portalOrganizationTypeName === OrganizationTypeName.Hauler ||
			token.portalOrganizationTypeName === OrganizationTypeName.Facility
		) {
			let portalDefaultUrl = this.getReactPortalDefaultUrl(queryParameters.selectedProgram as string);
			await this.createLandingAuditLog(token);
			location.href = (queryParameters.redirectUrl as string) || portalDefaultUrl;
		} else {
			//TODO: LINKO-5693: forward to account portal selection screen
			throw new Error('Unsupported portal');
		}
	};

	private createLandingAuditLog = async (token: Token) => {
		if (
			token.portalRegulatoryProgramNames &&
			token.portalRegulatoryProgramNames.toLowerCase().includes(RegulatoryProgramName.IPP.toLowerCase())
		) {
			let programsLogged = localStorageService.getLocalStorage('programsLogged') || [];
			if (!programsLogged.includes('' + token.portalOrganizationRegulatoryProgramId)) {
				programsLogged.push('' + token.portalOrganizationRegulatoryProgramId);
				localStorageService.setLocalStorage('programsLogged', programsLogged);
				const signInAuditLogUrl =
					this.getApiAccountBaseUrl() +
					`/Account/SignInAuditLog?organizationRegulatoryProgramId=${token.portalOrganizationRegulatoryProgramId}`;
				await apiService.getResource(signInAuditLogUrl);
			}
		}
	};

	getAccountUrl = () => {
		let hostname = location.hostname.toLowerCase();
		let index = hostname.indexOf('-acp-');
		let accountUrl =
			index > 0
				? `${location.protocol}//account` + hostname.substring(index) + this.composePort(location.port)
				: `${location.protocol}//account-acp.${AcpDomain}${this.composePort(location.port)}`;
		return accountUrl;
	};

	getReactPortalDefaultUrl(selectedProgram?: string): string {
		let token = tokenService.getTokenOrDefault();
		if (_.isEmpty(token.portalRegulatoryProgramNames)) {
			throw new Error('Login should always get a portal with at least one issued program to the logon user.');
		}

		const userIssuedPrograms = _.split(_.toLower(token.portalRegulatoryProgramNames), ',');
		let program = _.toLower(localStorageService.getLastProgram());

		if (!selectedProgram && !_.includes(userIssuedPrograms, program)) {
			program = userIssuedPrograms[0];
			localStorageService.setLastProgram(program);
		} else {
			program = selectedProgram ? _.toLower(selectedProgram) : program;
			localStorageService.setLastProgram(program as string);
		}

		if (token.portalOrganizationTypeName === OrganizationTypeName.Industry) {
			return this.getReactPortalLandingPage('dashboard', token.portalSubDomain);
		} else if (token.portalOrganizationTypeName === OrganizationTypeName.Hauler) {
			return this.getReactPortalLandingPage('facilities', token.portalSubDomain);
		} else if (token.portalOrganizationTypeName === OrganizationTypeName.Facility) {
			return this.getReactPortalLandingPage('devices', token.portalSubDomain);
		} else if (token.portalOrganizationTypeName === OrganizationTypeName.Authority) {
			switch (program) {
				case _.toLower(RegulatoryProgramName.FOG):
					return this.getReactPortalLandingPage('Facilities', token.portalSubDomain);
				case _.toLower(RegulatoryProgramName.Backflow):
					return this.getReactPortalLandingPage('sites', token.portalSubDomain);
				case _.toLower(RegulatoryProgramName.IPP):
					return this.getReactPortalLandingPage('authority/industries', token.portalSubDomain);
				default:
					throw new Error(localizationService.getLocalizedString('authentication.programTypeNotSupported'));
			}
		} else {
			throw new Error(localizationService.getLocalizedString('authentication.organizationTypeNotSupported'));
		}
	}

	getDefaultUrlForAuthorityProgram(program: string): string {
		// Changing program welcome default path at app-route.tsx
		return `${this.getReactBaseUrl()}/${program}/`;
	}

	getReactPortalLandingPage = (resource: string, subDomain?: string) => {
		if (subDomain) {
			return this.getReactBaseUrlWithSubDomain(subDomain, resource);
		} else {
			return this.getReactResourceUrl(resource);
		}
	};
	getAuthenticationResourceApiUrl(): string {
		return `${this.getApiBaseUrl()}/Account/Authenticate`;
	}

	getTemporaryChangePasswordApiUrl(): string {
		return `${this.getApiBaseUrl()}/Account/TemporaryUserChangePassword`;
	}

	getForgetPasswordApiUrl(): string {
		return `${this.getApiBaseUrl()}/Account/ForgottenPassword`;
	}

	getPasswordResetLinkApiUrl(id: number): string {
		return `${this.getApiBaseUrlWithProgram()}/Users/${id}/PasswordResetLink`;
	}

	getResetPasswordApiUrl(): string {
		return `${this.getApiBaseUrl()}/Account/ResetPassword`;
	}

	getValidateResetTokenApiUrl(): string {
		return `${this.getApiBaseUrl()}/Account/ValidateResetToken`;
	}

	getResetPasswordToken(): string {
		let paths = location.pathname.split('/');
		if (paths[1].toLowerCase() === Resource.ResetPassword.toLowerCase() && paths.length >= 2) {
			return paths[2];
		}
		return '';
	}

	getNoticeTemplateScheduleId(): number {
		let paths = location.pathname.split('/');
		// "/backflow/Settings/NoticeTemplateSchedule/:id"
		if (
			paths.length >= 5 &&
			paths[1].toLowerCase() === 'backflow' &&
			paths[2].toLowerCase() === 'settings' &&
			paths[3].toLowerCase() === 'noticetemplateschedule'
		) {
			return parseInt(paths[4]);
		}
		throw new Error(localizationService.getLocalizedString('authoritySetting.notice.noticeTemplateIdNotInUrl'));
	}

	getUrlQueryParameters(): QueryParameters {
		let queryParams = new QueryParameters();
		let qs = location.search || '';
		let queryParameters = queryString.parse(qs);
		for (const [key, value] of Object.entries(queryParameters)) {
			queryParams[key] = value;
		}
		return queryParams;
	}

	toQueryString(parameters: Dictionary<string>): string {
		let condition: string[] = [];
		_.each(_.keys(parameters), (key: string) => {
			let value = parameters[key];
			if (!_.isEmpty(value) || _.isNumber(value)) {
				condition.push(`${key}=${encodeURIComponent(value)}`);
			}
		});
		return condition.join('&');
	}

	getUrlQueryParameter(key: string, defaultValue?: string): string | undefined {
		let qs = location.search || '';
		let queryParameters = queryString.parse(qs);
		let paramValue = defaultValue;
		let keys = _.keys(queryParameters);
		for (let index = 0; index < keys.length; index++) {
			if (keys[index].toLowerCase() === key.toLowerCase()) {
				paramValue = queryParameters[keys[index]] as string;
				break;
			}
		}
		return paramValue;
	}

	toQueryDictionary(url?: string): Dictionary<string> {
		let qs = url || location.search;
		let queryParameters = queryString.parse(qs);
		let queryDictionary: Dictionary<string> = {} as Dictionary<string>;

		_.each(_.keys(queryParameters), (key: string) => {
			queryDictionary[key] = queryParameters[key] ? (queryParameters[key] as string) : '';
		});
		return queryDictionary;
	}

	removeUrlQueryString(title = '') {
		let newUrl = `${location.origin}${location.pathname}`;
		//DO NOT REFRESH PAGE HERE
		window.history.replaceState({}, title, newUrl);
		return newUrl;
	}

	removeUrlQueryParam(...keys: string[]) {
		let queryDict = this.toQueryDictionary();
		keys.forEach((key: string) => {
			_.unset(queryDict, key);
		});
		let newUrl = `${location.origin}${location.pathname}`;
		if (!_.isEmpty(queryDict)) {
			newUrl += `?${this.toQueryString(queryDict)}`;
		}
		//DO NOT REFRESH PAGE HERE
		window.history.replaceState({}, '', newUrl);
		return newUrl;
	}

	setUrlQueryString(dict: Dictionary<string>, title = '', url?: string): string {
		let newUrl = url;
		if (!newUrl) {
			let search = this.toQueryString(dict);
			newUrl = `${location.origin}${location.pathname}` + (search ? `?${search}` : '');
		}
		//DO NOT REFRESH PAGE HERE
		window.history.replaceState(dict, title, newUrl);
		return newUrl;
	}

	replaceUrlQueryString(dictForReplace: Dictionary<string>, title = ''): string {
		let search = this.toQueryDictionary();
		_.keys(dictForReplace).forEach(key => {
			search[key] = dictForReplace[key];
		});
		let newUrl = `${location.origin}${location.pathname}?` + this.toQueryString(search);
		//DO NOT REFRESH PAGE HERE
		window.history.replaceState(search, title, newUrl);
		return newUrl;
	}

	mergeLocalUrlCache(queryParameters: Dictionary<string>, cacheName: LocalStorageName) {
		if (queryParameters && _.size(queryParameters) > 0) {
			return;
		}
		let cachedUrl = localStorageService.getLocalStorage(cacheName);
		if (cachedUrl) {
			let query = cachedUrl.split('?');
			if (query.length > 1) {
				let queryParametersFromCache = this.toQueryDictionary(query[1]);
				_.each(_.keys(queryParametersFromCache), (key: string) => {
					if (['page'].includes(_.toLower(key))) {
						return;
					}
					queryParameters[key] = queryParametersFromCache[key]
						? (queryParametersFromCache[key] as string)
						: '';
				});
			}
		}
		this.setUrlQueryString(queryParameters);
	}

	pickParameters(dict: Dictionary<string>, ...reservedKeysIfExists: string[]): Dictionary<string> {
		let parameters: Dictionary<string> = {};
		_.each(reservedKeysIfExists, (key: string) => {
			const value = _.get(dict, key);
			if (value) {
				parameters[key] = value;
			}
		});
		return parameters;
	}

	getHazardTestUrl(hazardId?: number) {
		if (hazardId) {
			return `${this.getApiBaseUrlWithProgram()}/Hazards/${hazardId}/Tests`;
		} else {
			return `${this.getApiBaseUrlWithProgram()}/Hazards/${this.getCccHazardId()}/Tests`;
		}
	}

	getTestsUrl(testId: number) {
		return `${this.getApiBaseUrlWithProgram()}/Tests/${testId}`;
	}

	getAllExtractorsUrl() {
		return this.getAuthorityResourcesApiUrl(Resource.Extractors) + '/All';
	}

	getIppAuthorityUrl(resources: string, resourceId?: number): string {
		if (resourceId) {
			return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/authority/${resources}/${resourceId}`;
		}
		return `${this.getReactBaseUrl()}/${this.getProgramOrThrowError()}/authority/${resources}`;
	}

	getIppIndustryUrl(resources: string, resourceId?: number): string {
		let { guid } = urlService.getPortalStatus();
		if (resourceId) {
			return `${this.getReactBaseUrl()}/industry/${guid}/${resources}/${resourceId}`;
		}
		return `${this.getReactBaseUrl()}/industry/${guid}/${resources}`;
	}

	isIppIndustryPortal(): boolean {
		const organizationTypeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		return organizationTypeName
			? organizationTypeName.toLowerCase() === OrganizationTypeName.Industry.toLowerCase()
			: false;
	}

	getPortalUrl(guid: string, portal: string, resources: string) {
		let subDomain = this.getSubDomain(location.href);
		let accountHostname: string;
		let acpPartPos = subDomain.indexOf('-acp-');
		if (acpPartPos > 0) {
			accountHostname = `service${subDomain.substring(acpPartPos)}`;
		} else {
			accountHostname = `service-acp`;
		}
		return `${this.getReactBaseUrlForSubDomain(accountHostname)}${this.composePort(
			location.port
		)}/${portal}/${guid}/${resources}`;
	}

	isHaulerPortal(): boolean {
		const organizationTypeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		return organizationTypeName
			? organizationTypeName.toLowerCase() === OrganizationTypeName.Hauler.toLowerCase()
			: false;
	}

	isFacilityPortal(): boolean {
		const organizationTypeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		return organizationTypeName
			? organizationTypeName.toLowerCase() === OrganizationTypeName.Facility.toLowerCase()
			: false;
	}

	isAuthorityPortal(): boolean {
		const organizationTypeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		return organizationTypeName
			? organizationTypeName.toLowerCase() === OrganizationTypeName.Authority.toLowerCase()
			: false;
	}

	isReportPackageDetailsPage = () => {
		let reportPackagePath = 'reportPackage';
		return (
			window.location.pathname.includes(reportPackagePath) &&
			Boolean(Number(window.location.pathname.split('/').pop()))
		);
	};

	isIppAuthorityPortal(): boolean {
		const authorityUrl = 'ipp/authority';
		return window.location.pathname.includes(authorityUrl);
	}

	isAccountInvitationPage(): boolean {
		const accountInvitation = 'account/invite';
		return window.location.pathname.includes(accountInvitation);
	}

	isCustomFormBuilderPage(): boolean {
		const customFormUrl = '/fog/settings/customForms/*';
		return !!matchPath(window.location.pathname, {
			path: customFormUrl,
			exact: true,
			strict: false
		});
	}

	isIppServiceIndustryPortal(): boolean {
		const serviceDomain = 'service-acp';
		let isIndustryPortal =
			this.getReactBaseUrl().includes(serviceDomain) &&
			window.location.pathname.split('/')[1] === OrganizationTypeName.Industry.toLowerCase() &&
			this.isIppIndustryPortal();
		return isIndustryPortal;
	}

	clearTokenOnSignOut() {
		let signInUrl = new URL(window.location.href);
		let signOut = signInUrl.searchParams.get('signOut');
		if (signOut) {
			localStorageService.removeLocalStorage('allowedPortals');
			tokenService.clearToken();
		}
	}

	getMfaEnabledPrograms(mfaDetails: any) {
		if (typeof mfaDetails === 'string') {
			return mfaDetails;
		}
		return (
			mfaDetails &&
			mfaDetails
				.filter((mfa: MfaDetails) => mfa.isMFAEnabled)
				.map((mfa: MfaDetails) => mfa.regulatoryProgramName && mfa.regulatoryProgramName.toLowerCase())
				.join()
		);
	}

	signOut = async () => {
		_.delay(() => {
			tokenService.clearToken();
			window.location.href = urlService.getLoginUrl('signOut=true');
		}, IppConstants.shortWaitTime);
	};

	isProfilePage = () => {
		const registerPath = '/register/';
		return (
			this.isAccountScreen() ||
			_.toLower(document.location.pathname)
				.toLowerCase()
				.indexOf(registerPath) >= 0
		);
	};

	getPortalStatus = () => {
		let isAccessDenied: boolean = false;
		let token = tokenService.getTokenOrDefault();
		let portalType = token.portalOrganizationTypeName && token.portalOrganizationTypeName.toLowerCase();
		let portalName = window.location.pathname.split('/')[1];

		if (portalType && (portalType.toLowerCase() === 'industry' || portalType.toLowerCase() === 'hauler')) {
			isAccessDenied = portalType !== portalName.toLowerCase();
		} else {
			isAccessDenied = false;
		}
		let guid = tokenService.getTokenOrDefault().guid;
		return {
			portalType,
			isAccessDenied,
			guid
		};
	};

	isPortalDirectorPage = () => {
		return window.location.href.includes(urlService.getPortalDirectorUrl());
	};

	getUrlWithRegulateeTypeAndGuid = () => {
		let { guid } = urlService.getPortalStatus();
		let organizationTypeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		let urlWithRegulateeTypeAndGuid = guid ? `${organizationTypeName!.toLocaleLowerCase()}/${guid}` : undefined;
		return urlWithRegulateeTypeAndGuid;
	};

	isRegulatee = () => {
		let { guid } = urlService.getPortalStatus();
		return guid ? true : false;
	};

	isSampleImport = () => {
		return window.location.href.includes(`${urlService.getIppIndustryUrl('samples/import')}`);
	};

	getHaulerPortalRegisterResourceApiUrl(): string {
		return `${this.getApiBaseUrlWithProgram()}`;
	}

	getHaulerResourceReactUrl(resources: string, resourceId?: number): string {
		let guid = tokenService.getTokenOrDefault().guid;
		if (resourceId) {
			return `/${_.toLower(OrganizationTypeName.Hauler)}/${guid}/${resources}/${resourceId}`;
		}
		return `/${_.toLower(OrganizationTypeName.Hauler)}/${guid}/${resources}`;
	}

	stopBrowserNavigation = (callback: () => any) => {
		window.onunload = () => true;
	};

	startBrowserNavigation = () => {
		window.onunload = null;
	};

	isTermsAndConditionsPage(): boolean {
		return window.location.href.includes(`/TermsAndConditions`);
	}

	isPrivacyPolicyPage(): boolean {
		return window.location.href.includes(`/PrivacyPolicy`);
	}

	isAccountPortalPageRelevantForFacilityPortalUsers(): boolean {
		return (
			window.location.href.includes('SignIn') ||
			window.location.href.includes('action=facilityRegistration') ||
			window.location.href.includes('/account/invite') ||
			window.location.href.includes('/ForgetPassword') ||
			window.location.href.includes('/ResetPassword') ||
			window.location.href.includes('/facility/')
		);
	}
}

export const urlService = new UrlService();
