import _ from 'lodash';
import React from 'react';
import { Action } from 'redux';
import * as ApiTypes from '@rcp/types';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { ExtractorState, ApplicationState, deleteFacilityExtractor } from 'src/redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import './extractor-card.scss';
import { useHistory } from 'react-router-dom';
import { CustomCard } from '../../../../../widgets/custom-card/custom-card';
import { faCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OwnProps {
	facilityExtractor: ApiTypes.FacilityExtractor;
	extractor: ApiTypes.Extractor;
}

interface StateProps extends ExtractorState {}

interface DispatchProps {
	deleteFacilityExtractor: (extractorId: number) => Promise<boolean | undefined>;
}

type Props = StateProps & DispatchProps & OwnProps;

const ExtractorCardComponent: React.SFC<Props> = props => {
	const history = useHistory();

	const extractor = props.extractor;

	let link = urlService.getReactAuthorityResourceUrl(Resource.Devices, extractor.extractorId);
	const navigateTohard = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		navigateTo(history, link);
	};

	const deviceBody = (
		<>
			<div className="mb-3 d-flex justify-content-between">
				<div className="text-break">
					<strong>
						<span className="card-title">{extractor.deviceNumber}</span>
					</strong>
				</div>
				<a href={link} className="float-right" onClick={(e: any) => navigateTohard(e)}>
					{localizationService.getLocalizedString('screen.buttons.view')}
				</a>
			</div>
			<div className="mt-2">
				{extractor.isActive ? (
					<>
						<span>
							<FontAwesomeIcon
								icon={faCircle}
								className="active-circle-color align-self-start mr-1 mt-1 icon-font-color fon-size-12"
							/>
						</span>
						{localizationService.getLocalizedString('extractor.isActive')}
					</>
				) : (
					<>
						<span>
							<FontAwesomeIcon
								icon={faBan}
								className="inactive-circle-color align-self-start mr-1 mt-1 icon-font-color fon-size-12"
							/>
						</span>
						{localizationService.getLocalizedString('extractor.inactive')}
					</>
				)}
			</div>
			{extractor.isActive ? (
				<>
					<p>{extractor.extractorType}</p>

					{_.has(extractor, 'trapCapacity') && extractor.trapCapacity !== null ? (
						<p>
							{`${localizationService.getLocalizedString('extractor.trapCapacity')}: ${
								extractor.trapCapacity
							} ${extractor.trapCapacityUnitCode === undefined ? '' : extractor.trapCapacityUnitCode}`}
						</p>
					) : null}

					{_.has(extractor, 'extractorDescription') && extractor.extractorDescription !== null ? (
						<p>
							{`${localizationService.getLocalizedString('extractor.extractorDescription')}: ${
								extractor.extractorDescription
							}`}
						</p>
					) : null}

					{extractor.cleanFrequencyCode ? (
						<p>
							{`${localizationService.getLocalizedString('extractor.cleaningFrequency')}: ${
								extractor.cleanFrequencyCode
							}`}
						</p>
					) : null}
				</>
			) : (
				<></>
			)}
		</>
	);

	return (
		<div className="device-card-wrapper">
			<CustomCard hideHeader={true} customCardBody={deviceBody} />
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.extractors };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		deleteFacilityExtractor: (extractorId: number) => dispatch(deleteFacilityExtractor(extractorId))
	};
};

export const ExtractorCard = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(ExtractorCardComponent);
