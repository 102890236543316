import { MfaQuestion, QuestionAnswerDtos } from '@rcp/types';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { alertService } from 'src/redux/alert/alert-service';
import { localizationService } from 'src/services';
import Question, { QuestionType } from './question';
import { KbqRef } from './register';

interface Props {
	showEditButton?: boolean;
	onNextKbq?: (questions: QuestionAnswerDtos[]) => void;
	questions: any;
	selectedQuestions?: QuestionAnswerDtos[];
	onBackUserInformation?: (questions: QuestionAnswerDtos[]) => void;
	kbq?: QuestionAnswerDtos[];
	isRegister?: boolean;
}

const KbqQuestions = (props: Props, ref: Ref<KbqRef>) => {
	let defaultQuestion = { ...props.questions[0] };
	const [question1KBQ, setQuestion1KBQ] = useState({ ...defaultQuestion } as MfaQuestion);
	const [question2KBQ, setQuestion2KBQ] = useState({ ...defaultQuestion } as MfaQuestion);
	const [question3KBQ, setQuestion3KBQ] = useState({ ...defaultQuestion } as MfaQuestion);
	const [question4KBQ, setQuestion4KBQ] = useState({ ...defaultQuestion } as MfaQuestion);
	const [question5KBQ, setQuestion5KBQ] = useState({ ...defaultQuestion } as MfaQuestion);
	const [answer1KBQ, setAnswer1KBQ] = useState('');
	const [answer2KBQ, setAnswer2KBQ] = useState('');
	const [answer3KBQ, setAnswer3KBQ] = useState('');
	const [answer4KBQ, setAnswer4KBQ] = useState('');
	const [answer5KBQ, setAnswer5KBQ] = useState('');
	const [question1KBQError, setQuestion1KBQError] = useState('');
	const [question2KBQError, setQuestion2KBQError] = useState('');
	const [question3KBQError, setQuestion3KBQError] = useState('');
	const [question4KBQError, setQuestion4KBQError] = useState('');
	const [question5KBQError, setQuestion5KBQError] = useState('');
	const [answer1KBQError, setAnswer1KBQError] = useState('');
	const [answer2KBQError, setAnswer2KBQError] = useState('');
	const [answer3KBQError, setAnswer3KBQError] = useState('');
	const [answer4KBQError, setAnswer4KBQError] = useState('');
	const [answer5KBQError, setAnswer5KBQError] = useState('');

	const isKbqSaved = () => onNext();

	useImperativeHandle(ref, () => ({ isKbqSaved }));

	let questions: QuestionAnswerDtos[] = [];

	useEffect(() => {
		setQuestion1KBQ(defaultQuestion);
		setQuestion2KBQ(defaultQuestion);
		setQuestion3KBQ(defaultQuestion);
		setQuestion4KBQ(defaultQuestion);
		setQuestion5KBQ(defaultQuestion);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultQuestion.questionId]);

	useEffect(() => {
		if (props.kbq && props.kbq.length) {
			setAnswer1KBQ(props.kbq[0].answer || '');
			setAnswer2KBQ(props.kbq[1].answer || '');
			setAnswer3KBQ(props.kbq[2].answer || '');
			setAnswer4KBQ(props.kbq[3].answer || '');
			setAnswer5KBQ(props.kbq[4].answer || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.kbq && props.kbq.length]);

	useEffect(() => {
		if (!props.selectedQuestions && !props.kbq) {
			setQuestion1KBQ({ ...props.questions[0] });
			setQuestion2KBQ({ ...props.questions[0] });
			setQuestion3KBQ({ ...props.questions[0] });
			setQuestion4KBQ({ ...props.questions[0] });
			setQuestion5KBQ({ ...props.questions[0] });
		}
		if (!question1KBQ || !Object.keys(question1KBQ).length) {
			setQuestion1KBQ(props.questions[0]);
		}
		if (!question2KBQ || !Object.keys(question2KBQ).length) {
			setQuestion2KBQ(props.questions[0]);
		}
		if (!question3KBQ || !Object.keys(question3KBQ).length) {
			setQuestion3KBQ(props.questions[0]);
		}
		if (!question4KBQ || !Object.keys(question4KBQ).length) {
			setQuestion4KBQ(props.questions[0]);
		}
		if (!question5KBQ || !Object.keys(question5KBQ).length) {
			setQuestion5KBQ(props.questions[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.questions]);
	useEffect(() => {
		if (props.selectedQuestions && props.selectedQuestions[0]) {
			let question = props.selectedQuestions[0];
			setQuestion1KBQ(question);
		}
		if (props.selectedQuestions && props.selectedQuestions[1]) {
			let question = props.selectedQuestions[1];
			setQuestion2KBQ(question);
		}
		if (props.selectedQuestions && props.selectedQuestions[2]) {
			let question = props.selectedQuestions[2];
			setQuestion3KBQ(question);
		}
		if (props.selectedQuestions && props.selectedQuestions[3]) {
			let question = props.selectedQuestions[3];
			setQuestion4KBQ(question);
		}
		if (props.selectedQuestions && props.selectedQuestions[4]) {
			let question = props.selectedQuestions[4];
			setQuestion5KBQ(question);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedQuestions]);

	const onNext = (type: string = 'next') => {
		questions = [];
		addQuestion(question1KBQ, answer1KBQ);
		addQuestion(question2KBQ, answer2KBQ);
		addQuestion(question3KBQ, answer3KBQ);
		addQuestion(question4KBQ, answer4KBQ);
		addQuestion(question5KBQ, answer5KBQ);
		if (String.equalCaseInsensitive(type, 'back')) {
			props.onBackUserInformation && props.onBackUserInformation(questions);
			return null;
		}

		validateDuplicateAnswers();
		validateDuplicateQuestions(questions);

		if (
			!answer1KBQ ||
			isAnswerAlreadyFilled(answer1KBQ) ||
			!answer2KBQ ||
			isAnswerAlreadyFilled(answer2KBQ) ||
			!answer3KBQ ||
			isAnswerAlreadyFilled(answer3KBQ) ||
			!answer4KBQ ||
			isAnswerAlreadyFilled(answer4KBQ) ||
			!answer5KBQ ||
			isAnswerAlreadyFilled(answer5KBQ) ||
			isQuestionAlreadyFilled(question1KBQ, questions) ||
			isQuestionAlreadyFilled(question2KBQ, questions) ||
			isQuestionAlreadyFilled(question3KBQ, questions) ||
			isQuestionAlreadyFilled(question4KBQ, questions) ||
			isQuestionAlreadyFilled(question5KBQ, questions)
		) {
			alertService.clearAllMessages();
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return null;
		} else if (
			answer1KBQ !== '' &&
			answer2KBQ !== '' &&
			answer3KBQ !== '' &&
			answer4KBQ !== '' &&
			answer5KBQ !== ''
		) {
			props.onNextKbq && props.onNextKbq(questions);
			return questions;
		} else {
			alertService.addError(localizationService.getLocalizedString('ipp.accountPortal.messages.emptyAnswers'));
			return null;
		}
	};

	const validateDuplicateAnswers = () => {
		setAnswer1KBQError(
			!answer1KBQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer1')
				  )
				: isAnswerAlreadyFilled(answer1KBQ)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setAnswer2KBQError(
			!answer2KBQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer2')
				  )
				: isAnswerAlreadyFilled(answer2KBQ)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setAnswer3KBQError(
			!answer3KBQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer3')
				  )
				: isAnswerAlreadyFilled(answer3KBQ)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setAnswer4KBQError(
			!answer4KBQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer4')
				  )
				: isAnswerAlreadyFilled(answer4KBQ)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setAnswer5KBQError(
			!answer5KBQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer5')
				  )
				: isAnswerAlreadyFilled(answer5KBQ)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
	};

	const validateDuplicateQuestions = (questions: MfaQuestion[]) => {
		setQuestion1KBQError(
			isQuestionAlreadyFilled(question1KBQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setQuestion2KBQError(
			isQuestionAlreadyFilled(question2KBQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setQuestion3KBQError(
			isQuestionAlreadyFilled(question3KBQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setQuestion4KBQError(
			isQuestionAlreadyFilled(question4KBQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
		setQuestion5KBQError(
			isQuestionAlreadyFilled(question5KBQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateKBQ')
				: ''
		);
	};

	const isQuestionAlreadyFilled = (question: MfaQuestion, kbqQuestions: MfaQuestion[]) => {
		let duplicateQuestions = kbqQuestions.filter((kbqQuestion: MfaQuestion) => {
			return kbqQuestion.questionId === question.questionId;
		});
		if (duplicateQuestions.length > 1) {
			return true;
		} else {
			return false;
		}
	};

	const isAnswerAlreadyFilled = (answer: string) => {
		let kbqAnswers: string[] = [answer1KBQ, answer2KBQ, answer3KBQ, answer4KBQ, answer5KBQ];
		let duplicateAnswers = kbqAnswers.filter((kbqAnswer: string) => {
			return String.equalCaseInsensitive(answer, kbqAnswer);
		});
		if (duplicateAnswers.length > 1) {
			return true;
		} else {
			return false;
		}
	};

	const addQuestion = (question: MfaQuestion, answer: string) => {
		questions.push({
			questionId: question.questionId,
			question: question.content || question.question,
			answer: answer,
			questionTypeName: 'KBQ'
		});
	};

	const getUserQuestionAnswerId = (qNo: number) => {
		return (
			props.selectedQuestions && props.selectedQuestions[qNo] && props.selectedQuestions[qNo].userQuestionAnswerId
		);
	};

	return (
		<>
			<>
				<h2>{localizationService.getLocalizedString('ipp.accountPortal.kbqTitle')}</h2>
				<p>{localizationService.getLocalizedString('ipp.accountPortal.kbqSubTitle')}</p>
				<div className="w-75 mx-auto">
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question1')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer1')}
						answer={answer1KBQ}
						type={QuestionType.KBQ}
						questions={props.questions}
						showEditButton={props.showEditButton || false}
						hideAnswer={true}
						selectedQuestion={question1KBQ}
						onChangeAnswer={(e: any) => {
							setAnswer1KBQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(0)}
						onQuestionChange={(question: MfaQuestion) => setQuestion1KBQ(question)}
						questionError={props.showEditButton ? undefined : question1KBQError}
						answerError={props.showEditButton ? undefined : answer1KBQError}
					/>
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question2')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer2')}
						answer={answer2KBQ}
						type={QuestionType.KBQ}
						showEditButton={props.showEditButton || false}
						questions={props.questions}
						selectedQuestion={question2KBQ}
						hideAnswer={true}
						onChangeAnswer={(e: any) => {
							setAnswer2KBQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(1)}
						onQuestionChange={(question: MfaQuestion) => setQuestion2KBQ(question)}
						questionError={props.showEditButton ? undefined : question2KBQError}
						answerError={props.showEditButton ? undefined : answer2KBQError}
					/>
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question3')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer3')}
						answer={answer3KBQ}
						type={QuestionType.KBQ}
						questions={props.questions}
						showEditButton={props.showEditButton || false}
						selectedQuestion={question3KBQ}
						hideAnswer={true}
						onChangeAnswer={(e: any) => {
							setAnswer3KBQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(2)}
						onQuestionChange={(question: MfaQuestion) => setQuestion3KBQ(question)}
						questionError={props.showEditButton ? undefined : question3KBQError}
						answerError={props.showEditButton ? undefined : answer3KBQError}
					/>
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question4')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer4')}
						answer={answer4KBQ}
						type={QuestionType.KBQ}
						questions={props.questions}
						showEditButton={props.showEditButton || false}
						selectedQuestion={question4KBQ}
						hideAnswer={true}
						onChangeAnswer={(e: any) => {
							setAnswer4KBQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(3)}
						onQuestionChange={(question: MfaQuestion) => setQuestion4KBQ(question)}
						questionError={props.showEditButton ? undefined : question4KBQError}
						answerError={props.showEditButton ? undefined : answer4KBQError}
					/>
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question5')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer5')}
						answer={answer5KBQ}
						type={QuestionType.KBQ}
						selectedQuestion={question5KBQ}
						questions={props.questions}
						showEditButton={props.showEditButton || false}
						hideAnswer={true}
						onChangeAnswer={(e: any) => {
							setAnswer5KBQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(4)}
						onQuestionChange={(question: MfaQuestion) => setQuestion5KBQ(question)}
						questionError={props.showEditButton ? undefined : question5KBQError}
						answerError={props.showEditButton ? undefined : answer5KBQError}
					/>
				</div>
				{props.onNextKbq && !props.isRegister && (
					<div className="form-row mt-1">
						<div className="col-md-12 d-flex">
							{props.onBackUserInformation && (
								<button className="btn ai-action mr-auto" onClick={() => onNext('back')}>
									{localizationService.getLocalizedString('ipp.buttons.back')}
								</button>
							)}
							<button className="btn ai-action ml-auto" onClick={() => onNext()}>
								{localizationService.getLocalizedString('ipp.accountPortal.next')}
							</button>
						</div>
					</div>
				)}
			</>
		</>
	);
};

export default forwardRef(KbqQuestions);
