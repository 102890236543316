import React from 'react';
import { MultiSelectDropDownOption } from '@rcp/types';
import { MultiSelect, ListItemProps } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import _ from 'lodash';
import { localizationService } from 'src/services';
import { FilterItem } from '../../services/data-types/filter-types';

interface Props {
	name: string;
	isRequired?: boolean;
	withoutEmptyOption?: boolean;
	className?: string;
	onChange?(selectedValues: string[]): void;
	loadStringOptions?: () => Promise<string[]>;
	options?: MultiSelectDropDownOption[];
	selectedValues?: any[];
	itemRender?: (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => React.ReactNode;
	allowCustomValue?: boolean;
	filterItem: FilterItem;
}

interface VirtualizeState {
	skip: number;
	total: number;
}

const pageSize = 20;
export const MultiSelectDropdown: React.SFC<Props> = props => {
	const [allOptions, setAllOptions] = React.useState<MultiSelectDropDownOption[]>([]);
	const [subsetOptions, setSubsetOptions] = React.useState<MultiSelectDropDownOption[]>([]);
	const [selectedOptions, setSelectedOptions] = React.useState<MultiSelectDropDownOption[]>([]);
	const [lastSkip, setLastSkip] = React.useState<number>(0);
	const [isFilterEnabled, setIsFilterEnabled] = React.useState<boolean>(false);
	const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
	const [isOptionsLoaded, setIsOptionsLoaded] = React.useState(false);
	const [virtualizeState, setVirtualizeState] = React.useState<VirtualizeState>({
		skip: 0,
		total: 0
	});

	const resetOptions = (options: MultiSelectDropDownOption[]) => {
		if (props.withoutEmptyOption !== true) {
			options.unshift({
				label: localizationService.getLocalizedString('screen.filterModal.empty'),
				value: '[NULL]',
				selected: false
			});
		} else if (
			options.length > 0 &&
			options[0].label === localizationService.getLocalizedString('screen.filterModal.empty')
		) {
			options.shift();
		}
		if (props.filterItem.property.isSignInUserTextRequired) {
			options.splice(1, 0, {
				label: localizationService.getLocalizedString('screen.filterModal.signedInUser'),
				value: `[${localizationService.getLocalizedString('screen.filterModal.signedInUser')}]`,
				selected: false
			});
		}
		updateSelectedOptions(options);
	};

	const updateSelectedOptions = (inputOptions?: MultiSelectDropDownOption[]) => {
		let options = inputOptions || allOptions;
		if (!_.isEmpty(props.selectedValues)) {
			let currentSelectedOptions: MultiSelectDropDownOption[] = [];
			options.forEach((option: MultiSelectDropDownOption) => {
				option.selected = _.includes(props.selectedValues, option.value);
				if (option.selected === true) {
					currentSelectedOptions.push({ ...option });
				}
			});
			let optionValues = _.map(options, o => o.value);
			_.forEach(props.selectedValues, selectedValue => {
				if (!_.includes(optionValues, selectedValue)) {
					let removedOrInActiveOrUnavailableOptions: MultiSelectDropDownOption = {
						label: selectedValue,
						value: selectedValue,
						selected: true
					};
					currentSelectedOptions.push(removedOrInActiveOrUnavailableOptions);
				}
			});
			setSelectedOptions(currentSelectedOptions);
		} else {
			options.forEach((option: MultiSelectDropDownOption) => {
				option.selected = false;
			});

			setSelectedOptions([]);
		}
		setAllOptions([...options]);
		(isEditMode || !props.selectedValues || isFilterEnabled) && setSubsetOptions(options.slice(0, pageSize));
		(isEditMode || !props.selectedValues || isFilterEnabled) &&
			setVirtualizeState({
				skip: 0,
				total: options.length
			});
		isFilterEnabled && setIsFilterEnabled(false);
		isEditMode && setIsEditMode(false);
	};

	React.useEffect(() => {
		if (props.loadStringOptions) {
			setIsOptionsLoaded(false);
			props
				.loadStringOptions()
				.then((data: string[]) => {
					let options: MultiSelectDropDownOption[] = [];
					data.forEach((item: string) => {
						if (_.isEmpty(item)) {
							return;
						}
						let option: MultiSelectDropDownOption = {
							label: item,
							value: item,
							selected: false
						};
						options.push({ ...option });
					});
					options = _.sortBy(options, option => {
						return option.label;
					});
					resetOptions(options);
					setIsOptionsLoaded(true);
				})
				.catch((err: any) => {
					console.error('Unexpected error occurred:', err);
				});
		}
		if (props.options && props.withoutEmptyOption) {
			resetOptions(props.options);
		}
		return () => {};
		// eslint-disable-next-line
	}, [props.name, props.withoutEmptyOption]);

	React.useEffect(() => {
		if (props.loadStringOptions && isOptionsLoaded) {
			updateSelectedOptions();
		} else if (!props.loadStringOptions) {
			updateSelectedOptions();
		}
		// eslint-disable-next-line
	}, [props.selectedValues, isOptionsLoaded]);

	React.useEffect(() => {
		setIsEditMode(true);
		if (props.options) {
			let options: MultiSelectDropDownOption[] = [...props.options];
			options.forEach((option: MultiSelectDropDownOption) => {
				option.selected = false;
			});
			resetOptions(options);
		}
		// eslint-disable-next-line
	}, [props.options]);

	const updateDropdownSelectionChanges = (selectedValues: any[]) => {
		let newOptions = [...allOptions];
		newOptions.forEach((option: MultiSelectDropDownOption) => {
			option.selected = _.includes(selectedValues, option.value);
		});
		setAllOptions(newOptions);

		let newSubsetOptions = [...subsetOptions];
		newSubsetOptions.forEach((option: MultiSelectDropDownOption) => {
			option.selected = _.includes(selectedValues, option.value);
		});
		setSubsetOptions(newSubsetOptions);
	};

	const handleChange = (event: any) => {
		let options = event.target.value;
		let newSelectedOptions: any = {};
		options &&
			options.map((option: any) => {
				if (newSelectedOptions[option.value]) {
					delete newSelectedOptions[option.value];
				} else {
					newSelectedOptions[option.value] = option;
				}
			});
		newSelectedOptions = Object.values(newSelectedOptions);

		let lastItem = newSelectedOptions[newSelectedOptions.length - 1];
		let selectedValues: string[] = [];
		if (!newSelectedOptions.includes(undefined)) {
			if (typeof lastItem === 'string') {
				newSelectedOptions[newSelectedOptions.length - 1] = {
					label: lastItem,
					value: lastItem
				};
			}
			selectedValues = newSelectedOptions.map((option: { value: any }) => option.value);
			updateDropdownSelectionChanges(selectedValues);
			setSelectedOptions(newSelectedOptions);
			if (props.onChange) {
				props.onChange(selectedValues);
			}
		}
	};

	const removeValueFromDropdown = (value: string) => {
		const selectedValues = [...selectedOptions];
		const selectedValueIndex = selectedOptions.findIndex(option => option.value === value);
		selectedValues.splice(selectedValueIndex, 1);
		handleChange({
			target: {
				value: selectedValues
			}
		});
	};

	const onPageChange = (e: any) => {
		const skip = e.page.skip;
		const take = e.page.take;
		let isScrollingUp = lastSkip > skip;
		setLastSkip(skip);
		const newSubsetOptions = allOptions.slice(
			isScrollingUp && skip > take ? skip - take : skip,
			isScrollingUp && skip > take ? skip : skip + take
		);
		setSubsetOptions(newSubsetOptions);
		setVirtualizeState({
			skip: skip,
			total: virtualizeState.total
		});
	};

	const onFilterChange = (e: any) => {
		setIsFilterEnabled(true);
		const filter = e.filter;
		filter.field = 'label';
		let filteredData = filterBy(allOptions.slice(), filter);
		setSubsetOptions(filteredData.slice(0, pageSize));
		setVirtualizeState({
			skip: 0,
			total: filteredData.length
		});
	};

	const defaultItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		let item: MultiSelectDropDownOption = itemProps.dataItem;
		const listProps = { ...li.props, key: li.props.id };
		const itemChildren = (
			<div className="custom-control custom-checkbox">
				<input type="checkbox" className="custom-control-input" checked={item.selected} />
				<div className="form-row custom-control-label">
					<span className="font-size-16px-medium">
						{item.label === localizationService.getLocalizedString('screen.filterModal.signedInUser') ||
						item.value ===
							`[${localizationService.getLocalizedString('screen.filterModal.signedInUser')}]` ? (
							<i>{localizationService.getLocalizedString('screen.filterModal.signedInUser')}</i>
						) : (
							item.label
						)}
					</span>
				</div>
			</div>
		);
		return React.cloneElement(li, listProps, itemChildren);
	};

	const tagRender = (tagData: any, li: any) => {
		const itemChildren = (
			<>
				<span id={`${tagData.data[0].value}`}>
					{tagData.data[0].label ===
						localizationService.getLocalizedString('screen.filterModal.signedInUser') ||
					tagData.data[0].value ===
						`[${localizationService.getLocalizedString('screen.filterModal.signedInUser')}]` ? (
						<i>{localizationService.getLocalizedString('screen.filterModal.signedInUser')}</i>
					) : (
						tagData.data[0].label
					)}
				</span>
				<span aria-label="delete" className="k-select">
					<span
						className="k-icon k-i-close"
						onClick={e => {
							removeValueFromDropdown(tagData.data[0].value);
						}}></span>
				</span>
			</>
		);
		return React.cloneElement(li, li.props, itemChildren);
	};
	return (
		<MultiSelect
			name={props.name}
			data={subsetOptions}
			itemRender={props.itemRender || defaultItemRender}
			virtual={{
				total: virtualizeState.total,
				pageSize: pageSize,
				skip: virtualizeState.skip
			}}
			onPageChange={onPageChange}
			filterable={true}
			onFilterChange={onFilterChange}
			onChange={handleChange}
			value={selectedOptions}
			autoClose={false}
			className={props.className}
			popupSettings={{ animate: false }}
			allowCustom={props.allowCustomValue}
			tagRender={tagRender}
			textField="label"
			dataItemKey="value"
		/>
	);
};
