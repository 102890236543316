import React, { useEffect, useState } from 'react';
import { DropDownOption, FogFacility, EventType } from '@rcp/types';
import { ComboBoxDropdown, PopoverModal, SingleCheckbox } from '../../../../..';
import { apiService, localizationService, Resource, urlService, validationService } from '../../../../../../services';
import { EventModal } from './event-modal';
import { alertService, RootState } from '../../../../../../redux';
import { availableEventTypeSlice } from './available-event-type.slice';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import _ from 'lodash';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';

interface Props {
	title?: string;
	showModal: boolean;
	onCancel?: () => void;
	readOnly?: boolean;
	hideFooter?: boolean;
	hideDelete?: boolean;
	saveButtonClassName?: string;
	onSubmit?: () => void;
	saveButtonText?: string;
	hideCancel?: boolean;
	isFacilityDetailPage?: boolean;
	inspectionId?: number;
	isEventGridPage?: true;
	loadInspectionEvents?: (inspectionId?: number) => void;
}

const initialFormState = { facilityId: 0, facilityIdError: '' };

export const SelectEvents: React.FC<Props> = props => {
	const [eventTypes, setEventTypes] = useState<EventType[]>([]);
	const [selectedEventTypes, setSelectedEventTypes] = useState<EventType[]>([]);
	const [showEventModal, setShowEventModal] = useState(false);
	const [facilityOptionValues, setFacilityOptionValues] = useState([] as DropDownOption[]);
	const [formState, setFormState] = useState(initialFormState);
	const dispatch = useDispatch();
	const { loadingCounter } = useSelector((state: RootState) => state.pageLoadingState);
	const types = useSelector((state: RootState) => state.availableEventTypes);

	useEffect(() => {
		props.showModal && props.isEventGridPage && loadFacilities();
	}, [props.showModal]);

	useEffect(() => {
		const facilityId = props.isEventGridPage ? formState.facilityId : urlService.getFogFacilityId();
		const newState = { ...formState };
		newState.facilityId = facilityId;
		setFormState(newState);
		availableEventTypeSlice.setApiUrlPath(`${Resource.FogFacilities}/${facilityId}/${Resource.EventTypes}`);
		facilityId > 0 && dispatch(availableEventTypeSlice.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.facilityId]);

	useEffect(() => {
		setEventTypes(types.result as EventType[]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [types]);

	const loadFacilities = async () => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Events}/${Resource.FogFacilities}?size=2147483647&sort=facilityName asc`
		);
		const facilities = await apiService.getResource<FogFacility[]>(url);
		let facilityOptions: DropDownOption[] = [];
		facilityOptions = facilities.map((facility: FogFacility) => {
			return {
				label: facility.facilityName,
				value: facility.facilityId,
				facilityNumber: facility.referenceNumber,
				addressLine1: facility.addressLine1,
				cityName: facility.cityName,
				jurisdictionCode: facility.jurisdictionCode,
				zipCode: facility.zipCode
			} as DropDownOption;
		});
		setFacilityOptionValues(facilityOptions);
	};

	const resetForm = () => {
		setFormState(initialFormState);
		setSelectedEventTypes([]);
		setEventTypes([]);
	};

	const cancelBothModal = () => {
		onCancel();
		toggle();
	};

	const onCancel = () => {
		props.onCancel && props.onCancel();
		resetForm();
	};

	const validateForm = () => {
		const newState = { ...formState };
		validationService.validateRequiredField(newState, 'facilityId', 'facilityIdError', 'Facility');
		const isFormValid = !validationService.hasError(newState, 'facilityIdError');

		setFormState(newState);
		if (!isFormValid) {
			alertService.clearAllMessages();
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const onSubmit = () => {
		if (selectedEventTypes.length > 0) {
			setShowEventModal(true);
		} else {
			alertService.clearAllMessages();
			alertService.addError(localizationService.getLocalizedString('events.selectEventError'));
		}

		props.isEventGridPage && validateForm();
	};

	const modalFooter = () => {
		return props.hideFooter || (eventTypes.length === 0 && formState.facilityId && props.isEventGridPage) ? (
			<></>
		) : (
			<>
				{!props.readOnly && (
					<button
						className={`btn ${props.saveButtonClassName ? props.saveButtonClassName : 'btn ai-action'}`}
						onClick={onSubmit}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</button>
				)}
				{!props.hideCancel && (
					<button
						className={`btn ai-white ${props.readOnly && props.readOnly ? 'ml-auto' : ''}`}
						onClick={() => {
							onCancel();
						}}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				)}
			</>
		);
	};

	const modalProps = {
		title: localizationService.getLocalizedString('events.addEvent'),
		showModal: props.showModal,
		cancel: onCancel,
		className: '',
		footer: modalFooter()
	};

	const onChange = (e: any) => {
		const selectedEventId = e.target.name;
		const event: EventType[] = [...selectedEventTypes];
		if (e.target.checked) {
			setSelectedEventTypes([
				...event,
				...eventTypes.filter(eventType => selectedEventId == eventType.eventTypeId)
			]);
		} else {
			const eventIndex = selectedEventTypes.findIndex(event => selectedEventId == event.eventTypeId);
			const selectEvents = [...selectedEventTypes];
			selectEvents.splice(eventIndex, 1);
			setSelectedEventTypes(selectEvents);
		}
	};

	const toggle = () => {
		setShowEventModal(!showEventModal);
	};

	const facilityListItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const item = itemProps.dataItem as DropDownOption;

		const itemChildren = <div className="border-bottom w-100">{renderFacilityInfo(item)}</div>;

		return React.cloneElement(li, li.props, itemChildren);
	};

	const renderFacilityInfo = (facility: DropDownOption, strong = true) => {
		return (
			<>
				{strong ? <strong> {facility.label}</strong> : facility.label} <br />
				{facility.value} <br />
				{facility.addressLine1 && (
					<>
						{facility.addressLine1} <br />
					</>
				)}
				{[
					facility.cityName ? facility.cityName + ',' : facility.cityName,
					facility.jurisdictionCode,
					facility.zipCode
				]
					.filter(Boolean)
					.join('\u0020')}
			</>
		);
	};

	const customFilter = (options: DropDownOption[], filter: FilterDescriptor): DropDownOption[] => {
		let newOptions = _.cloneDeep(options);
		newOptions = newOptions.filter((option: DropDownOption) => {
			return (
				(option.addressLine1 && _.toLower(option.addressLine1).includes(_.toLower(filter.value.trim()))) ||
				_.toLower(option.facilityNumber).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.label).includes(_.toLower(filter.value.trim()))
			);
		});
		return newOptions;
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		_.set(newState, name, value);

		setSelectedEventTypes([]);
		setFormState(newState);
	};

	return (
		<>
			<PopoverModal {...modalProps}>
				<>
					{props.isEventGridPage && (
						<ComboBoxDropdown
							options={facilityOptionValues}
							id="facility"
							name="facilityId"
							itemRender={facilityListItemRender}
							className="col-sm-12 p-0 "
							onChange={changeFormState}
							clearButton={false}
							value={_.toString(formState.facilityId)}
							error={formState.facilityIdError}
							label={localizationService.getLocalizedString('facility.facility')}
							customFilter={customFilter}
							hintText={localizationService.getLocalizedString('events.noFacilitySelected')}
							isRequired
							showSearchIcon
						/>
					)}
					{eventTypes.length === 0 && formState.facilityId && loadingCounter === 0 && (
						<Notification
							className="notification d-inline-block p-0 mb-2"
							message={localizationService.getLocalizedString('events.noEventTypesPresentForFacility')}
							showCloseButton={false}
						/>
					)}
					{!!eventTypes.length && (
						<>
							<div>
								<p>{localizationService.getLocalizedString('events.eventSubTitle')}</p>
							</div>
							{eventTypes.map((eventType: EventType) => {
								return (
									<SingleCheckbox
										key={eventType.eventTypeId}
										id={`event${eventType.eventTypeId}`}
										name={`${eventType.eventTypeId}`}
										className="m-0"
										label={eventType.eventTypeAbbreviation}
										onChange={onChange}
									/>
								);
							})}
						</>
					)}
				</>
			</PopoverModal>
			{showEventModal && (
				<EventModal
					selectedEvents={selectedEventTypes}
					showModal={showEventModal}
					onCancel={toggle}
					inspectionId={props.inspectionId}
					facilityId={formState.facilityId}
					isFacilityDetailPage={props.isFacilityDetailPage}
					isEventGridPage={props.isEventGridPage}
					cancelBothModal={cancelBothModal}
					loadInspectionEvent={props.loadInspectionEvents}
				/>
			)}
		</>
	);
};
