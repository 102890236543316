import React from 'react';
import { History } from 'history';
import { RestSlice } from '../../../../redux/rest.slice';
import { customFieldQueries, DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import {
	ActionModalActionType,
	alertService,
	RootState,
	toggleActionModal,
	useReduxDispatch,
	useReduxSelector,
	useRootStateSelector
} from 'src/redux';
import { inspectionsSlice } from './inspections-slice';
import { nameof } from 'ts-simple-nameof';
import { localizationService, urlService, DateUtilService, navigateTo, localStorageService } from 'src/services';
import { ColumnField, InspectionEvent, LocalStorageName, RouteProps, FilterObjectName, FeatureNames } from '@rcp/types';
import { FacilityNameCell } from './facility-name-cell';
import { ComplianceStatusCell } from '../facilities/ComplianceStatusCell';
import { InspectionEditActionLink } from './inspection-link-cell';
import { useHistory } from 'react-router';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { BatchInspectionOperationModal } from '../facilities/facility-details/batch-inspection-operation-modal';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const InspectionGrid: React.FC<Props<InspectionEvent>> = props => {
	const history = useHistory();
	const { numberOfOpenInspections } = useRootStateSelector(s => s.reduxInspections);
	const dispatch = useReduxDispatch();
	const showBatchInspectionsModal = useRootStateSelector(s => s.actionModals.fogInspectionGridBatchOperationModal);

	const toggleBatchPatchInspectionsModal = () => {
		if (numberOfOpenInspections && numberOfOpenInspections > 0) {
			dispatch(toggleActionModal(ActionModalActionType.fogInspectionGridBatchOperationModal));
		} else {
			alertService.addError(
				localizationService.getLocalizedString('alertMessages.cantRescheduleOnlyCompleteInspections')
			);
		}
	};

	const facilityNameToLink = (props: any) => {
		const inspectionEvent = props.dataItem as InspectionEvent;
		return (
			<td>
				<FacilityNameCell
					facilityName={inspectionEvent.facilityName}
					facilityId={inspectionEvent.organizationId}
					authorityId={inspectionEvent.creatorOrganizationId}
				/>
			</td>
		);
	};
	const inspectionTypeToLink = (props: any) => {
		const inspectionEvent = props.dataItem as InspectionEvent;
		return (
			<td>
				<InspectionEditActionLink inspectionEvent={inspectionEvent} isFromInspectionGrid={true} />
			</td>
		);
	};
	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};
	const inspectionDueDateCell = (props: any) => {
		const inspectionEvent = props.dataItem as InspectionEvent;
		return <td>{inspectionEvent.dueDate ? formatDate(inspectionEvent.dueDate) : null}</td>;
	};
	const inspectionCompleteDateCell = (props: any) => {
		const inspectionEvent = props.dataItem as InspectionEvent;
		const link = urlService.getFacilityInspectionDetailsUrl(
			inspectionEvent.organizationId,
			inspectionEvent.inspectionEventId
		);
		if (inspectionEvent.completeDate) {
			return (
				<td>
					<a
						href={link}
						className="ai-link"
						onClick={(e: any) => {
							localStorageService.removeLocalStorage(LocalStorageName.InspectionDetailRedictFromPath);
							navigateTo(history, link, e);
						}}>
						{formatDate(inspectionEvent.completeDate)}
					</a>
				</td>
			);
		} else {
			return (
				<td>
					<a
						href={link}
						className="btn ai-white"
						onClick={(e: any) => {
							localStorageService.removeLocalStorage(LocalStorageName.InspectionDetailRedictFromPath);
							navigateTo(history, link, e);
						}}>
						{localizationService.getLocalizedString(`screen.buttons.start`)}
					</a>
				</td>
			);
		}
	};

	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'screen.labels.inspections');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'screen.labels.inspections');
		}
		return localizationService.getLocalizedString('screen.labels.inspections');
	};

	const getAllColumns = (): ColumnField[] => {
		return [
			ColumnField.newText(
				nameof<InspectionEvent>(i => i.facilityName),
				true,
				facilityNameToLink
			),
			ColumnField.newText(
				nameof<InspectionEvent>(i => i.referenceNumber),
				true
			),
			ColumnField.newText(
				nameof<InspectionEvent>(h => h.complianceStatus),
				true,
				ComplianceStatusCell
			),
			ColumnField.newText(
				nameof<InspectionEvent>(h => h.facilityAddress),
				true
			),
			ColumnField.newText(
				nameof<InspectionEvent>(h => h.inspectionType),
				true,
				inspectionTypeToLink
			),
			ColumnField.newDate(
				nameof<InspectionEvent>(h => h.dueDate),
				true,
				inspectionDueDateCell
			),
			ColumnField.newDate(
				nameof<InspectionEvent>(h => h.completeDate),
				true,
				inspectionCompleteDateCell
			),
			ColumnField.newText(
				nameof<InspectionEvent>(h => h.inspectionResult),
				true
			),
			ColumnField.newText(
				nameof<InspectionEvent>(h => h.inspectorName),
				true
			)
		];
	};
	const getDefaultColumnNames = () => {
		return getAllColumns()
			.filter(f => f.isDefault)
			.map(f => f.name);
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'inspection',
		storageName: LocalStorageName.InspectionGrid,
		gridUrlLocalStorageName: LocalStorageName.InspectionGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.InspectionSortOrder,
		hasGridMap: true,
		showActions: true,
		showActionExport: true,
		customActions: [
			{
				actionType: ActionModalActionType.fogInspectionGridBatchOperationModal,
				actionLabel: localizationService.getLocalizedString('screen.labels.rescheduleInspections'),
				toggle: toggleBatchPatchInspectionsModal
			}
		],
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('inspection.searchHint'),

		customFieldQuery: customFieldQueries.Fog,
		defaultSort: [{ field: nameof<InspectionEvent>(h => h.organizationId), dir: 'desc' }],
		allColumns: getAllColumns(),
		defaultColumns: getDefaultColumnNames(),
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.INSPECTION,
				FilterDomainName.inspection,
				FilterObjectName.Inspection,
				LocalStorageName.InspectionGridFilterUrl,
				appliedDomains
			);
		}
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={inspectionsSlice}
				restState={(state: RootState) => state.reduxInspections}
				gridOption={initialGridOption}>
				{showBatchInspectionsModal && (
					<BatchInspectionOperationModal
						actionType={ActionModalActionType.fogInspectionGridBatchOperationModal}
						toggleModal={toggleBatchPatchInspectionsModal}
						filteredObjectCount={numberOfOpenInspections as number}
						isPatch={true}
					/>
				)}
			</DataGrid>
		</>
	);
};
