import React from 'react';

interface Props {
	isActive?: boolean;
}

export const SortIconSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="7" height="11" viewBox="0 0 7 11">
			<g id="Group_357" data-name="Group 357" transform="translate(-490 -522)">
				<circle
					id="Ellipse_77"
					data-name="Ellipse 77"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(490 522)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
				<circle
					id="Ellipse_78"
					data-name="Ellipse 78"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(494 522)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
				<circle
					id="Ellipse_79"
					data-name="Ellipse 79"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(490 526)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
				<circle
					id="Ellipse_80"
					data-name="Ellipse 80"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(494 526)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
				<circle
					id="Ellipse_81"
					data-name="Ellipse 81"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(490 530)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
				<circle
					id="Ellipse_82"
					data-name="Ellipse 82"
					cx="1.5"
					cy="1.5"
					r="1.5"
					transform="translate(494 530)"
					fill={props.isActive ? '#23409a' : '#91a5b7'}
				/>
			</g>
		</svg>
	);
};
