import React, { FC } from 'react';
import { localizationService } from 'src/services';
import './review-summary.scss';
interface Props {
	present: number;
	required: number;
	isSample?: boolean;
	className?: string;
	importSuccess?: boolean;
}

const IppPackageItemProgress: FC<Props> = (props: Props) => {
	const formatString = (str: string, ...val: string[]) => {
		for (let index = 0; index < val.length; index++) {
			str = str.replace(`{${index}}`, val[index]);
		}
		return str;
	};

	const getGreenProgressWidth = () => {
		// Cover area if no data present
		let width = 100 + '%';
		if (props.required !== 0) {
			return { width: (props.present * 100) / props.required + '%' };
		}
		return { width };
	};

	const getRedProgressWidth = () => {
		// Do not cover area if no data present
		let width = 0 + '%';
		if (props.required !== 0) {
			return { width: ((props.required - props.present) * 100) / props.required + '%' };
		}
		return { width };
	};
	return (
		<>
			<div className={`${props.importSuccess ? 'ml-3' : 'container'} ${props.className ? props.className : ''}`}>
				<div className="form-row">
					{!props.isSample && (
						<p className="font-size-16px-bold progress-label">
							{formatString(
								localizationService.getLocalizedString(
									'ipp.reportPackage.package.progress.elementProgressLabel'
								),
								props.present.toString(),
								props.required.toString()
							)}
						</p>
					)}
					{props.isSample && (
						<p className={`font-size-16px-${props.importSuccess ? 'regular' : 'bold'} progress-label`}>
							{formatString(
								localizationService.getLocalizedString(
									`ipp.reportPackage.package.progress.${
										props.importSuccess ? 'importedSampleProgressLabel' : 'sampleProgressLabel'
									}`
								),
								props.present.toString(),
								props.required.toString()
							)}
						</p>
					)}
				</div>
				<div className="form-row">
					<div className="progress progress-style">
						<div
							className="progress-bar green"
							role="progressbar"
							style={getGreenProgressWidth()}
							aria-valuemin={0}
							aria-valuemax={props.required}></div>
						<div
							className="progress-bar red"
							role="progressbar"
							style={getRedProgressWidth()}
							aria-valuemin={0}
							aria-valuemax={props.required}></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IppPackageItemProgress;
