// @ts-ignore
import _ from 'lodash';
import {
	apiService,
	customDefinedFieldService,
	DateUtilService,
	localizationService,
	localStorageService,
	Resource,
	tokenService,
	urlService
} from 'src/services';
// @ts-ignore
import React from 'react';
import * as LinkoTypes from '@rcp/types';
import {
	ColumnField,
	CustomFieldAware,
	CustomFieldDataType,
	CustomFieldDefinition,
	FilterType,
	ICustomFieldItem,
	NoticesType
} from '@rcp/types';
import { GridColumn, GridColumnProps } from '@progress/kendo-react-grid';
import { alertService } from 'src/redux';
import { SortDescriptor } from '@progress/kendo-data-query';
import { doNotTranslateTags, IppTestConstants } from 'src/constants';
import { SingleCheckbox } from 'src/components/widgets';
import './utils.scss';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { nameof } from 'ts-simple-nameof';
import { Translate } from 'src/components/widgets/translate/translator';
import { translateService } from './translate-service';
import MicroEmitter from 'micro-emitter';
import { FormField } from 'src/components/authority/fog/facilities/facility-details/facility-notice-steps';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FormFields } from 'src/components/authority/fog/settings/notices/cleaning-notice-stepper/steps/step3';

export class Utils {
	public static isStartWithCustomFields = (field: string): boolean => {
		return _.startsWith(_.toLower(field), _.toLower(nameof<CustomFieldAware>(s => s.customFields)));
	};
	public static isCustomField = (field: string, customFields: CustomFieldDefinition[]): boolean => {
		if (Utils.isStartWithCustomFields(field)) {
			return true;
		}
		let customDefinedFields = customFields;
		if (customDefinedFields) {
			let cdf = _.find(
				customDefinedFields,
				(cdf: CustomFieldDefinition) => _.toUpper(cdf.fieldName) === _.toUpper(field)
			);
			if (cdf) {
				return true;
			}
		}
		return false;
	};

	public static formatDateTime(props: any) {
		let dateTime = _.get(props.dataItem, props.field);
		let displayDate = DateUtilService.toDisplayDate(dateTime);
		return <td>{displayDate}</td>;
	}

	public static formatCell = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td className={props.className}>{localizationService.formatValue(fieldValue)}</td>;
	};

	public static extractFieldName = (key: string) => {
		let splitKeyArray = key.split('.');
		if (splitKeyArray.length > 1) {
			return splitKeyArray.slice(1).join('.');
		} else {
			return key;
		}
	};

	public static formatCustomFieldDateCell = (props: any) => {
		let dateTime = _.get(props.dataItem, `customFields.${props.field}`);
		let displayDate = DateUtilService.toDisplayDate(dateTime);
		return <td>{displayDate}</td>;
	};

	public static formatCustomFieldCell = (props: any) => {
		let fieldValue = _.get(props.dataItem, `customFields.${props.field}`);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	public static formatCustomFieldLinkCell = (props: any) => {
		let fieldValue = _.get(props.dataItem, `customFields.${props.field}`);
		return (
			<td>
				<a href={`${fieldValue}`} target="_blank" rel="noopener noreferrer" className="ai-link">
					{fieldValue}
				</a>
			</td>
		);
	};

	public static getAllColumnDefinitions = (
		prefix: string,
		keys: string[],
		defaultColumnKeys: string[],
		customDefinedFields?: LinkoTypes.ColumnDefinition[],
		enableEdit?: boolean
	): LinkoTypes.ColumnDefinition[] => {
		let fields = Utils.getDefaultShowingColumnDefinitions(prefix, defaultColumnKeys, enableEdit);

		_.each(keys, (key: string) => {
			if (!_.includes(defaultColumnKeys, key)) {
				fields.push({
					field: key,
					title: localizationService.getLocalizedString(`${prefix}.${key}`),
					visible: _.includes(defaultColumnKeys, key)
				} as LinkoTypes.ColumnDefinition);
			}
		});

		return customDefinedFields ? _.concat(fields, customDefinedFields) : fields;
	};

	public static isDateColumn(columns: ColumnField[], field: string, customFields: CustomFieldDefinition[]): boolean {
		let column = _.find(columns, (column: ColumnField) => column.name === field);
		if (column) {
			if (column.type === FilterType.Date) {
				return true;
			}
		} else {
			let customDefinedFields = customFields;
			if (customDefinedFields) {
				let cdf = _.find(
					customDefinedFields,
					(cdf: CustomFieldDefinition) => _.toUpper(cdf.fieldName) === _.toUpper(field)
				);
				if (cdf && cdf.fieldType === CustomFieldDataType.Date) {
					return true;
				}
			}
		}
		return false;
	}

	public static isLinkColumn(field: string, customFields: CustomFieldDefinition[]): boolean {
		let customDefinedFields = customFields;
		if (customDefinedFields) {
			let cdf = _.find(
				customDefinedFields,
				(cdf: CustomFieldDefinition) => _.toUpper(cdf.fieldName) === _.toUpper(field)
			);
			if (cdf && cdf.fieldType === CustomFieldDataType.Link) {
				return true;
			}
		}
		return false;
	}

	public static getDefaultShowingColumnDefinitions = (prefix: string, keys: string[], enableEdit?: boolean) => {
		let columns = keys.map((key: string) => {
			var localizedTitleKey = key.includes('.') ? key : `${prefix}.${key}`;
			return {
				field: key,
				title: localizationService.getLocalizedString(localizedTitleKey),
				visible: true
			} as LinkoTypes.ColumnDefinition;
		});
		if (enableEdit) {
			return [
				{
					field: 'selectItem',
					title: ' ',
					visible: enableEdit
				} as LinkoTypes.ColumnDefinition,
				...columns
			];
		} else {
			return columns;
		}
	};

	public static getFieldColumnNames = (fields: ColumnField[]): string[] => {
		return fields.map(x => x.name);
	};

	public static getAllColumns = (
		prefix: string,
		storageName: string,
		allGridColumns: ColumnField[],
		defaultColumnNames: string[],
		customFields: CustomFieldDefinition[]
	): LinkoTypes.ColumnDefinition[] => {
		let allGridColumnNames = Utils.getFieldColumnNames(allGridColumns);

		let customDefinedFields = customDefinedFieldService.getCustomDefinedFieldColumnDefinition(customFields);

		let allColumnDefinitions = Utils.getAllColumnDefinitions(
			prefix,
			allGridColumnNames,
			defaultColumnNames,
			customDefinedFields
		);

		let columnsFromLocalStorage = Utils.getFieldsFromLocalStorageAndAddNewRemoveNotSupportedFields(
			prefix,
			storageName,
			allGridColumnNames,
			customDefinedFields
		);
		if (columnsFromLocalStorage && columnsFromLocalStorage.length > 0) {
			allColumnDefinitions = columnsFromLocalStorage;
		}
		return allColumnDefinitions;
	};
	static getSelectField = (
		props: any,
		selectedItems: any,
		setSelectedItems: any,
		selectIdKey: any,
		validationErrorData: any[],
		clearValidationError?: any
	) => {
		let dataItem = props.dataItem;
		let uniqueKey = dataItem[selectIdKey];
		let message: string = '';
		let showTooltip = false;
		let errorData: any = {};
		if (validationErrorData.length && selectedItems.includes(uniqueKey)) {
			showTooltip = selectedItems.includes(uniqueKey);
			errorData = validationErrorData.filter(validationError => {
				return validationError[selectIdKey] === uniqueKey;
			})[0];
			message = (errorData && errorData.errorMessage) || '';
		}
		return (
			<td>
				<span className="d-inline-flex">
					<SingleCheckbox
						id={`select-item-${uniqueKey}`}
						name={`selectItem${uniqueKey}`}
						className="mx-auto"
						checked={selectedItems.includes(uniqueKey)}
						onChange={(event: any) => {
							clearValidationError && clearValidationError();
							let items: Array<Number> = [];
							if (event.target.checked) {
								items = [...selectedItems, uniqueKey];
							} else {
								items = [...selectedItems];
								let index = items.indexOf(uniqueKey);
								index > -1 ? items.splice(index, 1) : (items = [...selectedItems]);
							}
							setSelectedItems(items);
						}}
					/>
					{showTooltip && (
						<TooltipHover
							id={`prevent-readytoreport-${uniqueKey}`}
							icon={faExclamationCircle}
							className="d-inline-block mr-1"
							iconClassName="ai-required"
							iconFontClass="font-size-16px-regular"
							title={message}
							position={'right'}
						/>
					)}
				</span>
			</td>
		);
	};

	static getSelectAllField = (
		props: any,
		selectAllItems: any,
		setSelectAllItems: any,
		clearValidationError?: any
	) => {
		return (
			<SingleCheckbox
				id={`select-all`}
				name="selectAll"
				className="mx-auto select-all-td"
				checked={selectAllItems}
				onChange={(event: any) => {
					clearValidationError && clearValidationError();
					setSelectAllItems(event.target.checked);
				}}></SingleCheckbox>
		);
	};

	public static generateColumns = (
		prefix: string,
		storageName: string,
		allGridColumns: ColumnField[],
		defaultColumnNames: string[],
		customFields: CustomFieldDefinition[],
		enableEdit?: boolean,
		selectedItems?: Array<number>,
		setSelectedItems?: any,
		selectAllItems?: boolean,
		setSelectAllItems?: any,
		selectIdKey?: string,
		validationErrorData?: any[],
		clearValidationError?: any,
		shouldTranslate?: boolean
	): Promise<any[]> => {
		let allGridColumnNames = Utils.getFieldColumnNames(allGridColumns);

		let customDefinedFields = customDefinedFieldService.getCustomDefinedFieldColumnDefinition(customFields);

		let allColumnDefinitions = Utils.getAllColumnDefinitions(
			prefix,
			allGridColumnNames,
			defaultColumnNames,
			customDefinedFields,
			enableEdit
		);
		let columnsFromLocalStorage = Utils.getFieldsFromLocalStorageAndAddNewRemoveNotSupportedFields(
			prefix,
			storageName,
			allGridColumnNames,
			customDefinedFields
		);
		if (columnsFromLocalStorage && columnsFromLocalStorage.length > 0) {
			allColumnDefinitions = columnsFromLocalStorage;
			allColumnDefinitions.unshift({
				field: 'selectItem',
				title: ' ',
				visible: enableEdit
			} as any);
		}

		if (!shouldTranslate) {
			return Promise.resolve(
				this.mapColumnHeader(
					allColumnDefinitions,
					allGridColumns,
					customFields,
					enableEdit,
					selectedItems,
					setSelectedItems,
					selectAllItems,
					setSelectAllItems,
					selectIdKey,
					validationErrorData,
					clearValidationError
				)
			);
		}

		return translateService.translateColumnHeaders(allColumnDefinitions).then(res => {
			return this.mapColumnHeader(
				res,
				allGridColumns,
				customFields,
				enableEdit,
				selectedItems,
				setSelectedItems,
				selectAllItems,
				setSelectAllItems,
				selectIdKey,
				validationErrorData,
				clearValidationError
			);
		});
	};

	public static newColumnField = (
		name: string,
		gridColumnProps: GridColumnProps,
		filterType: FilterType = FilterType.Text
	) => {
		return new ColumnField(name, filterType, undefined, false, undefined, undefined, false, gridColumnProps);
	};

	public static mapColumnHeader = (
		header: any[],
		allGridColumns: ColumnField[],
		customFields: CustomFieldDefinition[],
		enableEdit?: boolean,
		selectedItems?: Array<number>,
		setSelectedItems?: any,
		selectAllItems?: boolean,
		setSelectAllItems?: any,
		selectIdKey?: string,
		validationErrorData?: any[],
		clearValidationError?: any
	) => {
		return header
			.filter((i: LinkoTypes.ColumnDefinition) => i.visible)
			.map((i: LinkoTypes.ColumnDefinition) => {
				let newTitle = i.translatedTitle || i.title;
				if (enableEdit && i.field === 'selectItem') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							headerClassName="select-item"
							cell={props =>
								Utils.getSelectField(
									props,
									selectedItems,
									setSelectedItems,
									selectIdKey,
									validationErrorData || [],
									clearValidationError
								)
							}
							headerCell={props =>
								Utils.getSelectAllField(props, selectAllItems, setSelectAllItems, clearValidationError)
							}
						/>
					);
				}
				if (Utils.isCustomField(i.field, customFields)) {
					if (Utils.isDateColumn(allGridColumns, i.field, customFields)) {
						return (
							<GridColumn
								key={`column_key${i.field}`}
								field={i.field}
								title={newTitle}
								cell={Utils.formatCustomFieldDateCell}
							/>
						);
					}
					if (Utils.isLinkColumn(i.field, customFields))
						return (
							<GridColumn
								key={`column_key${i.field}`}
								field={i.field}
								title={newTitle}
								cell={Utils.formatCustomFieldLinkCell}
							/>
						);
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={newTitle}
							cell={Utils.formatCustomFieldCell}
						/>
					);
				}

				let column = allGridColumns.filter(
					(j: LinkoTypes.ColumnField) => _.toLower(j.name) === _.toLower(i.field)
				)[0];
				let gridColumnProps: GridColumnProps = {
					field: i.field,
					title: newTitle
				};
				//pass traditional ColumnFields fields and value to gridColumnProps
				if (column) {
					gridColumnProps = {
						...gridColumnProps,
						sortable: column.sortable,
						locked: column.locked
					};
				}
				if (column && column.cell) {
					gridColumnProps.cell = column.cell;
				} else if (Utils.isDateColumn(allGridColumns, i.field, customFields)) {
					gridColumnProps.cell = Utils.formatDateTime;
				} else {
					gridColumnProps.cell = Utils.formatCell;
				}
				//overwrites GridColumnProps if the column.gridColumnPropsOverrides is specified,.
				if (column && column.gridColumnPropsOverrides) {
					gridColumnProps = {
						...gridColumnProps,
						...column.gridColumnPropsOverrides
					};
				}
				return <GridColumn key={`column_key${i.field}`} {...gridColumnProps} />;
			});
	};

	public static getFieldsFromLocalStorageAndAddNewRemoveNotSupportedFields(
		prefix: string,
		storageName: string,
		allSupportedFiledKeysWithOutCustomDefinedField: string[],
		customDefinedFields?: LinkoTypes.ColumnDefinition[]
	): LinkoTypes.ColumnDefinition[] {
		let customDefinedFieldKeys: string[] = customDefinedFields ? customDefinedFields.map(x => x.field) : [];
		let allSupportedFieldKeys: string[] = _.concat(
			allSupportedFiledKeysWithOutCustomDefinedField,
			customDefinedFieldKeys
		);

		let customizedFieldsValue = localStorageService.getLocalStorage(storageName);
		if (customizedFieldsValue) {
			let fields = customizedFieldsValue as LinkoTypes.ColumnDefinition[];

			let allSupportedFields: LinkoTypes.ColumnDefinition[] = [];

			//Remove fields which doesn't support anymore
			_.each(fields, (field: LinkoTypes.ColumnDefinition) => {
				if (_.includes(allSupportedFieldKeys, field.field)) {
					allSupportedFields.push(field);
				}
			});

			//Add newly added fields after saved into LocalStorage
			let addedFields = allSupportedFields.map(x => x.field);

			_.each(allSupportedFieldKeys, (key: string) => {
				if (!_.includes(addedFields, key)) {
					allSupportedFields.push({
						field: key,
						title: key,
						visible: false
					} as LinkoTypes.ColumnDefinition);
				}
			});

			//Update Title
			allSupportedFields.forEach(field => {
				if (_.includes(customDefinedFieldKeys, field.field) && customDefinedFields) {
					//Modifying the title if custom field description is changed after saved into LocalStorage
					field.title = customDefinedFields.filter(x => x.field === field.field)[0].title;
				} else if (_.includes(allSupportedFiledKeysWithOutCustomDefinedField, field.field)) {
					//Modifying the title if existing title translation is changed after saved into LocalStorage
					field.title = localizationService.getLocalizedString(`${prefix}.${field.field}`);
				}
			});

			return allSupportedFields;
		}

		return [];
	}

	// IPP Methods
	public static getValueOrEmptyString(value: string | undefined | null) {
		return value ? value : '';
	}

	public static getDataInTwelveHrFormat(dt: Date, dateSeperator = '/', timeSeperator = ':') {
		let hours: string | number = dt.getHours();
		let minutes: string | number = dt.getMinutes();
		let seconds: string | number = dt.getSeconds();
		let date: string | number = dt.getDate();
		let month: string | number = dt.getMonth() + 1;
		let year: string | number = dt.getFullYear();
		const ampm = hours >= 12 ? 'pm' : 'am';
		hours = hours % 12;
		hours = hours ? hours : 12; // the hour '0' should be '12'
		hours = hours < 10 ? `0${hours}` : hours;
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		seconds = seconds < 10 ? `0${seconds}` : seconds;
		date = date < 10 ? `0${date}` : date;
		month = month < 10 ? `0${month}` : month;
		const strTime = `${month}${dateSeperator}${date}${dateSeperator}${year} ${hours}${timeSeperator}${minutes}${timeSeperator}${seconds} ${ampm}`;
		return strTime;
	}

	public static getDropdownOptions(
		obAr: Object[],
		valueField: string,
		labelField: string
	): LinkoTypes.DropDownOption[] {
		const result = [] as LinkoTypes.DropDownOption[];
		for (const item of obAr) {
			result.push({ label: (item as any)[labelField], value: (item as any)[valueField] });
		}
		return result;
	}

	public static getUnitHash = (unitList: LinkoTypes.Unit[]): any => {
		return _.reduce(
			unitList,
			function(hash, unit) {
				if (unit.name !== undefined) {
					hash[unit.name] = unit.unitId;
				}

				return hash;
			},
			{} as any
		);
	};

	public static formatDate(props: any) {
		let dateTime = _.get(props.dataItem, props.field);
		let isMidnightTimeDisplayed =
			urlService.isIppAuthorityPortal() || urlService.isIppIndustryPortal()
				? dateTime && dateTime.split('T')[1].startsWith('00:00')
					? true
					: false
				: false;
		let displayDateTime = isMidnightTimeDisplayed
			? DateUtilService.toDisplayDate(dateTime)
			: `${DateUtilService.toDisplayDate(dateTime)} ${DateUtilService.toDisplayTime(dateTime).toUpperCase()}`;
		return <td>{displayDateTime}</td>;
	}

	public static registrationSuccessDescription = (invitationDetails: LinkoTypes.InvitationDetails) => {
		const {
			industryCity,
			industryState,
			industryZip,
			industryAddressLine1,
			industryAddressLine2,
			authoritySupportEmail,
			authoritySupportPhone,
			authorityName,
			industryName
		} = invitationDetails;

		return (
			<>
				{localizationService.getLocalizedString('ipp.accountPortal.messages.registrationReceived')}
				<br />
				<span className="d-block mt-3">
					{authorityName
						? `${localizationService.getLocalizedString(
								'ipp.accountPortal.authoritySubHeading'
						  )}: ${authorityName}`
						: ''}
					<br />
					{industryName
						? `${localizationService.getLocalizedString(
								'ipp.accountPortal.industrySubHeading'
						  )}: ${industryName}`
						: ''}
					{industryName ? <br /> : ''}
					{industryAddressLine1} {industryAddressLine2 ? `, ${industryAddressLine2}` : ''}
					{industryCity || industryState || industryZip ? <br /> : ''}{' '}
					{industryCity ? `${industryCity},` : ''} {industryState}&nbsp;
					{industryZip}
				</span>
				<span className="d-block mt-3 mb-3">
					{localizationService.getLocalizedString('ipp.accountPortal.messages.concerns')}
				</span>
				<span className="d-block mt-3 mb-3">
					{authorityName}
					<br />
					{authoritySupportEmail}
					<br />
					{authoritySupportPhone}
				</span>
			</>
		);
	};

	public static resetFailedAttemptCounts = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/${Resource.ResetFailedAttemptCounts}`;
		try {
			await apiService.getResource(url);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	public static sortQueryParams(
		sort: SortDescriptor[],
		queryParameters: LinkoTypes.Dictionary<string>,
		isCustomField?: Function,
		customFields?: CustomFieldDefinition[]
	) {
		if (sort && sort.length > 0) {
			let sortFieldName = sort[0].field;
			let sortFieldDirection = sort[0].dir;
			if (
				!Utils.isStartWithCustomFields(sortFieldName) &&
				isCustomField &&
				(customFields ? isCustomField(sortFieldName, customFields) : isCustomField(sortFieldName))
			) {
				queryParameters['sort'] = `CustomFields.${sortFieldName} ${sortFieldDirection}`;
			} else {
				queryParameters['sort'] = `${sortFieldName} ${sortFieldDirection}`;
			}
		} else {
			_.unset(queryParameters, 'sort');
		}
	}

	public static sortLookup = (a: any, b: any) => {
		if (_.toString(a.label).toLowerCase() < _.toString(b.label).toLowerCase()) {
			return -1;
		} else if (_.toString(a.label).toLowerCase() === _.toString(b.label).toLowerCase()) {
			return 0;
		}
		return 1;
	};

	public static sortCustomField = (customFields: ICustomFieldItem[]) => {
		const fieldSortedByPosition: any[] = customFields.filter((customField: any) => {
			return customField.position > 0;
		});
		const fieldSortedByLabel: any[] = customFields.filter((customField: any) => {
			return customField.position <= 0;
		});
		fieldSortedByLabel.sort(Utils.sortLookup);

		fieldSortedByPosition.sort((a: any, b: any) => {
			return a.position - b.position;
		});
		fieldSortedByPosition.push(...fieldSortedByLabel);
		return fieldSortedByPosition;
	};

	public static loadAllowedPortals = async () => {
		let url = `${urlService.getApiBaseUrl(LinkoTypes.ApiPortal.Account)}/${Resource.AllowedPortals}`;
		try {
			let allowedPortals: any = await apiService.getResource(url);
			allowedPortals = urlService.removePortalsWithRoleCTSSyncUser(allowedPortals);
			return allowedPortals || [];
		} catch (ex) {
			if (ex.statusCode === IppTestConstants.unauthorizedStatusCode) {
				alertService.addError(localizationService.getLocalizedString('errors.notAuthorized'));
				tokenService.clearToken();
				window.location.href = urlService.getLoginUrl('signOut=true');
			} else {
				alertService.addError(ex.message);
			}
		}
	};

	public static isDeviceCleaningNotice = (noticeTemplate?: string) => {
		const { DeviceCleaningNotice } = NoticesType;
		return noticeTemplate === DeviceCleaningNotice;
	};

	public static getFacilityPortalStatusForAuthority = async () => {
		let url = `${urlService.getAuthorityOrpDetailApiUrl()}/${
			tokenService.getTokenOrDefault().portalOrganizationId
		}/${Resource.FacilityPortalStatus}`;
		try {
			return await apiService.httpGet(url);
		} catch (err) {
			const error = err as LinkoTypes.ApiError;
			alertService.addError(error.message);
		}
	};

	public static getFacilityPortalStatus = async (
		setIsFacilityPortalBlocked: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.FacilityAccessStatus);
		try {
			await apiService
				.httpGet(url)
				.then((status: string) => setIsFacilityPortalBlocked(String.equalCaseInsensitive(status, 'blocked')));
		} catch (err) {
			const error = err as LinkoTypes.ApiError;
			alertService.addError(error.message);
		}
	};

	public static isContains = (searchTerm: string, searchString?: any) => {
		return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
	};

	public static onChangeMergeFieldHandler = (
		e: DropDownListChangeEvent,
		noticeContent: FormField | FormFields,
		subjectPointerStartPosition: number,
		subjectPointerEndPosition: number,
		setSubjectPointerStartPosition: React.Dispatch<React.SetStateAction<number>>,
		setSubjectPointerEndPosition: React.Dispatch<React.SetStateAction<number>>,
		setNoticeContent: React.Dispatch<React.SetStateAction<FormField | FormFields>>
	) => {
		const contentToInsert = '{{' + e.target.value + '}}';
		let templateSubject: string = noticeContent.templateSubject || '';
		templateSubject = [
			templateSubject.slice(0, subjectPointerStartPosition),
			contentToInsert,
			templateSubject.slice(subjectPointerEndPosition)
		].join('');
		Utils.updateTemplateAndSubjectPointers(
			setSubjectPointerStartPosition,
			subjectPointerStartPosition,
			templateSubject,
			setSubjectPointerEndPosition,
			subjectPointerEndPosition,
			setNoticeContent,
			noticeContent
		);
	};
	public static onClickDoNotTranslate = (
		noticeContent: FormField | FormFields,
		subjectPointerStartPosition: number,
		subjectPointerEndPosition: number,
		setSubjectPointerStartPosition: React.Dispatch<React.SetStateAction<number>>,
		setSubjectPointerEndPosition: React.Dispatch<React.SetStateAction<number>>,
		setNoticeContent: React.Dispatch<React.SetStateAction<FormField | FormFields>>
	) => {
		let templateSubject: string = noticeContent.templateSubject || '';
		templateSubject = [
			templateSubject.slice(0, subjectPointerStartPosition),
			doNotTranslateTags.start,
			templateSubject.slice(subjectPointerStartPosition, subjectPointerEndPosition),
			doNotTranslateTags.end,
			templateSubject.slice(subjectPointerEndPosition)
		].join('');
		Utils.updateTemplateAndSubjectPointers(
			setSubjectPointerStartPosition,
			subjectPointerStartPosition,
			templateSubject,
			setSubjectPointerEndPosition,
			subjectPointerEndPosition,
			setNoticeContent,
			noticeContent
		);
	};

	public static updateTemplateAndSubjectPointers = (
		setSubjectPointerStartPosition: React.Dispatch<React.SetStateAction<number>>,
		subjectPointerStartPosition: number,
		templateSubject: string,
		setSubjectPointerEndPosition: React.Dispatch<React.SetStateAction<number>>,
		subjectPointerEndPosition: number,
		setNoticeContent: React.Dispatch<React.SetStateAction<FormField | FormFields>>,
		noticeContent: FormField | FormFields
	) => {
		setSubjectPointerStartPosition(subjectPointerStartPosition + templateSubject.length);
		setSubjectPointerEndPosition(subjectPointerEndPosition + templateSubject.length);
		setNoticeContent({
			...noticeContent,
			templateSubject
		});
	};
}
