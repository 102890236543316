import React from 'react';
import { ApiError } from '@rcp/types';

import './api-error.scss';

interface Props {
	title?: string;
	error: ApiError;
}

export const LinkoApiError: React.SFC<Props> = props => {
	const isLocalhost = window.location.host.indexOf('localhost') !== -1;
	const hasDebugAsTrue = /[?&](debug=1|debug=true)/.test(window.location.search);
	const shouldShowStacktrace = isLocalhost || hasDebugAsTrue;
	let title = props.title || 'Error';
	let error = props.error || {};

	return (
		<div className="card w-100">
			<div className="card-body">
				<h5 className="api-error">
					{title}: {error.message}
				</h5>
				{shouldShowStacktrace ? <p className="card-text">{error.stackTrace}</p> : null}
			</div>
		</div>
	);
};
