import {
	Dictionary,
	IppFileStore,
	IppIndustrySample,
	IppPackageElement,
	IppReportPackage,
	IppRepudiateReportPackage,
	IppUser,
	QuestionAnswerDtos,
	RouteProps
} from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import {
	IppConstants,
	IppTestConstants as Constants,
	ReportPackageStatus,
	SignAndSubmitValidation
} from 'src/constants';
import { alertService, ApplicationState } from 'src/redux';
import {
	loadAttachments,
	loadPackageDetails,
	loadSamples,
	PackageState,
	repudiatePackage,
	resetData,
	submitPackage,
	updatePackage,
	addReportPackage
} from 'src/redux/ipp/authority/report-packages/packages';
import { PackageReviewState } from 'src/redux/ipp/authority/report-packages/reviews';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import IppPackageSubmission from './package-elements/report-submission';
import IppPackageRepudiation from './package-elements/repudiate-package';
import IppPackageRejection from './package-elements/report-rejection';
import {
	IppPackageItemAttachments,
	IppPackageItemCertifications,
	IppPackageItemComments,
	IppPackageItemReportDetails,
	IppPackageReviewsCorButtons
} from 'src/components/authority/ipp';
import IppPackageItemSamples from 'src/components/authority/ipp/report-packages/packages/package-items/samples-and-results';
import IppPackageReviewSummary from 'src/components/authority/ipp/report-packages/packages/package-items/review-summary';
import IppPackageItemRepudiationConfirmation from 'src/components/authority/ipp/report-packages/packages/package-items/repudiation-confirmation';
import { ippReviewsSlice as ippPackageSlice } from '../../../authority/ipp/report-packages/packages/reviews/reviews.slice';
import IppPackageFixed from './package-elements/report-fixed';

interface DispatchProps {
	loadPackage: (id?: number) => Promise<IppReportPackage>;
	loadSamples: (queryParameters?: string) => Promise<IppFileStore[]>;
	loadAttachments: (queryParameters?: string) => Promise<IppFileStore[]>;
	updatePackage: (
		payload: IppReportPackage,
		callbackOnSuccess?: (response?: any) => void,
		showSuccessMessage?: boolean
	) => Promise<boolean | undefined>;
	resetData: () => Promise<boolean | undefined>;
	submitReport: (id: number, payload: any) => Promise<any>;
	repudiateReport: (id: number, payload: IppRepudiateReportPackage) => Promise<any>;
	addReportPackage: (
		payload: IppReportPackage,
		callbackOnSuccess: (reportPackage?: IppReportPackage) => void
	) => Promise<boolean | undefined>;
}

interface DispatchState extends PackageReviewState, PackageState {}

interface Props extends DispatchProps, DispatchState, RouteProps {
	history: History;
	reportPackage: IppReportPackage;
}

const { InvalidPassword, IncorrectKbqAnswer, UserLockedPassword, UserLockedKBQ } = SignAndSubmitValidation;
const { initialAuthorityPathName, authorityReportPackageDetailPath } = IppConstants;
const { protocol, host, href } = window.location;
let initialPath = host.replace(urlService.getSubDomain(href) + '.', '');

const IppPackageDetails: FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const [packageDetails, setPackageDetails] = useState({} as IppReportPackage);
	const [sampleTypes, setSampleTypes] = useState([] as IppPackageElement[]);
	const [attachmentTypes, setAttachmentTypes] = useState([] as IppPackageElement[]);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [canCurrentUserSubmitAndRepudiate, setCanCurrentUserSubmitAndRepudiate] = useState<boolean>();
	const [showCopyReportPackageInRepudiation, setShowCopyReportPackageInRepudiation] = useState<boolean>(false);
	const [ippSamples, setIppSamples] = useState<IppIndustrySample[]>([]);

	const loadData = async (
		refreshCounts: boolean = true,
		reportPackageId: number = props.match.params.reportPackageId
	) => {
		refreshCounts && navigateTo(props.history, window.location.pathname);
		let packageDetails = await props.loadPackage(reportPackageId);
		await loadSamples(reportPackageId);
		let attachmentTypes: IppPackageElement[] =
			packageDetails.attachmentTypes && packageDetails.attachmentTypes.length
				? packageDetails.attachmentTypes.slice()
				: [];
		for (let i in attachmentTypes) {
			loadAttachments(
				attachmentTypes[i].reportPackageElementTypeId,
				packageDetails.reportStatusName,
				attachmentTypes
			);
		}
		let isCurrentUserSignatory = await isSignatoryUser();
		let canCurrentUserSubmitAndRepudiate =
			!packageDetails.isSubmissionBySignatoryRequired ||
			(packageDetails.isSubmissionBySignatoryRequired && isCurrentUserSignatory);
		setCanCurrentUserSubmitAndRepudiate(canCurrentUserSubmitAndRepudiate);
	};

	useEffect(() => {
		loadData(false);
		return () => {
			resetState();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (packageDetails.samplesAndResultsTypes && packageDetails.samplesAndResultsTypes) {
			let sampleTypes = _.cloneDeep(packageDetails.samplesAndResultsTypes);
			if (sampleTypes && sampleTypes.length) {
				sampleTypes[0].ippSamples = ippSamples;
				setSampleTypes(sampleTypes);
			}
		}
	}, [ippSamples]);

	useEffect(() => {
		setPackageDetails(props.reportPackage);
	}, [props]);

	useEffect(() => {
		setPackageDetails(props.reportPackage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reportPackage.reportPackageId]);

	const loadSamples = async (
		reportPackageId: number,
		queryParameters: Dictionary<string> = {} as Dictionary<string>
	) => {
		let queryParams = _.cloneDeep(queryParameters);
		queryParams['reportPackageId'] = reportPackageId.toString();
		let ippSamples = await props.loadSamples(urlService.toQueryString(queryParams || ({} as Dictionary<string>)));
		setIppSamples(ippSamples);
	};

	const loadAttachments = async (
		id?: number,
		status?: string,
		attachmentTypes?: IppPackageElement[],
		queryParameters: Dictionary<string> = {} as Dictionary<string>
	) => {
		if (attachmentTypes && attachmentTypes.length) {
			let queryParams = _.cloneDeep(queryParameters);
			queryParams['reportStatusName'] = status || '';
			queryParams['reportPackageElementTypeId'] = (id || '').toString();
			let index = attachmentTypes.findIndex((attachment: IppPackageElement) => {
				return attachment.reportPackageElementTypeId === id;
			});
			let ippFileStores: IppFileStore[] = await props.loadAttachments(
				urlService.toQueryString(queryParams || ({} as Dictionary<string>))
			);
			ippFileStores && (attachmentTypes[index].ippFileStores = [...ippFileStores]);
			setAttachmentTypes([...attachmentTypes]);
		}
	};

	const resetState = async () => {
		resetData();
		setPackageDetails({} as IppReportPackage);
		setSampleTypes([] as IppPackageElement[]);
		setAttachmentTypes([] as IppPackageElement[]);
	};

	const onChangeSample = (event: any, id?: number) => {
		let { checked } = event.target;
		let newSamples: IppPackageElement[] = sampleTypes.slice();
		let index: number = (newSamples[0].ippSamples || []).findIndex(sample => {
			return sample.sampleId === id;
		});
		(newSamples[0].ippSamples as IppIndustrySample[])[index].isAssociatedWithReportPackage = checked;
		setSampleTypes(newSamples);
	};

	const onChangeAttachment = (event: any, id?: number, attachmentTypeId?: number) => {
		let { checked } = event.target;
		let newAttachmentTypes: IppPackageElement[] = [...attachmentTypes];
		let index = newAttachmentTypes.findIndex((attachmentType: IppPackageElement) => {
			return attachmentType.reportPackageElementTypeId === attachmentTypeId;
		});
		let fileStoreIndex = (newAttachmentTypes[index] as any).ippFileStores.findIndex((fileStore: IppFileStore) => {
			return fileStore.fileStoreId === id;
		});
		if (newAttachmentTypes[index].ippFileStores !== undefined) {
			(newAttachmentTypes[index].ippFileStores || [])[fileStoreIndex].isAssociatedWithReportPackage = checked;
		}
		setAttachmentTypes(newAttachmentTypes);
	};

	const onChangeCertification = (event: any, id?: number) => {
		const { checked } = event.target;
		let newCertificationTypes = packageDetails.certificationTypes && packageDetails.certificationTypes.slice();
		let index =
			newCertificationTypes &&
			newCertificationTypes.findIndex((certification: IppPackageElement) => {
				return certification.reportPackageElementTypeId === id;
			});
		if (newCertificationTypes && index !== undefined) {
			newCertificationTypes[index].isIncluded = checked;
		}
		newCertificationTypes &&
			setPackageDetails({ ...packageDetails, certificationTypes: [...newCertificationTypes] });
	};

	const onChangeComments = (event: any) => {
		const { value } = event.target;
		setPackageDetails({ ...packageDetails, comments: value });
	};

	const onSave = async (status?: string) => {
		let reportPackage = { ...packageDetails };
		let selectedSamples = _.cloneDeep(
			(sampleTypes && sampleTypes.length && sampleTypes[0].ippSamples) || []
		).filter((sample: IppIndustrySample) => {
			return sample.isAssociatedWithReportPackage;
		});
		if (
			String.equalCaseInsensitive(status, ReportPackageStatus.ReadyToSubmit) &&
			sampleTypes &&
			sampleTypes.length &&
			sampleTypes[0].isRequired &&
			!selectedSamples.length
		) {
			alertService.clearAllMessages();
			alertService.addError(
				`${sampleTypes[0].reportElementTypeName || ''} ${localizationService.getLocalizedString(
					'ipp.reportPackage.package.requiredErrorMessage'
				)}`
			);
			return;
		}
		let selectedAttachments = _.cloneDeep(attachmentTypes);
		for (let i in selectedAttachments) {
			let selectedFiles = (selectedAttachments[i].ippFileStores || []).filter((ippFileStore: IppFileStore) => {
				return ippFileStore.isAssociatedWithReportPackage;
			});
			selectedAttachments[i].ippFileStores = selectedFiles;
			if (
				String.equalCaseInsensitive(status, ReportPackageStatus.ReadyToSubmit) &&
				selectedAttachments[i].isRequired &&
				!selectedFiles.length
			) {
				alertService.clearAllMessages();
				alertService.addError(
					`${selectedAttachments[i].reportElementTypeName || ''} ${localizationService.getLocalizedString(
						'ipp.reportPackage.package.requiredErrorMessage'
					)}`
				);
				return;
			}
		}
		if (String.equalCaseInsensitive(status, ReportPackageStatus.ReadyToSubmit)) {
			let certificationTypes = _.cloneDeep(reportPackage.certificationTypes || []);
			for (let i in certificationTypes) {
				if (certificationTypes[i].isRequired && !certificationTypes[i].isIncluded) {
					alertService.clearAllMessages();
					alertService.addError(
						`${certificationTypes[i].reportElementTypeName || ''} ${localizationService.getLocalizedString(
							'ipp.reportPackage.package.requiredErrorMessage'
						)}`
					);
					return;
				}
			}
		}
		reportPackage.samplesAndResultsTypes &&
			reportPackage.samplesAndResultsTypes.length &&
			(reportPackage.samplesAndResultsTypes[0].ippSamples = [...selectedSamples]);
		reportPackage.attachmentTypes = [...selectedAttachments];

		reportPackage.reportStatusName = ReportPackageStatus.Draft;
		await props.updatePackage(reportPackage);
		if (String.equalCaseInsensitive(status, ReportPackageStatus.ReadyToSubmit)) {
			reportPackage.reportStatusName = status;
			await props.updatePackage(reportPackage);
		}
		await resetState();
		loadData();
	};

	const updateReportPackageOnCopyAndEdit = async (sampleToBeRemoved: IppIndustrySample, callback: () => void) => {
		let reportPackage = { ...packageDetails };
		let selectedSamples: IppIndustrySample[] = (reportPackage.samplesAndResultsTypes || [])[0].ippSamples || [];
		if (selectedSamples.length) {
			selectedSamples = selectedSamples.filter((sample: IppIndustrySample) => {
				return Number(sample.sampleId) !== Number(sampleToBeRemoved.sampleId);
			});
			(reportPackage.samplesAndResultsTypes || [])[0].ippSamples = selectedSamples;
		}
		await props.updatePackage(
			reportPackage,
			(response: any) => {
				if (response.reportPackageId) {
					callback();
				}
			},
			false
		);
	};

	const onToggleDelete = () => {
		setDeleteModal(!deleteModal);
	};

	const onDelete = () => {
		alertService.clearAllMessages();
		packageDetails &&
			dispatch(
				ippPackageSlice.deleteOne(
					Number(packageDetails.reportPackageId),
					false,
					localizationService.getLocalizedString('ipp.reportPackage.package.draftDeleteSuccessMessage'),
					() =>
						navigateTo(
							props.history,
							urlService.getIppIndustryUrl(
								`reportPackages/${(packageDetails.reportStatusName || '').toLowerCase()}`
							)
						)
				)
			);
	};

	const onPreview = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.IppReportPackages}/${packageDetails.reportPackageId}/${Resource.IppPreviewReportPackage}`
		);
		await apiService.getResource(url);
	};

	const passwordAndKbqValidation = async (response: any, type: string = 'submit', callBackAction?: () => void) => {
		let passwordAndKbqValidationResult = response.passwordAndKbqValidationResult;
		switch (passwordAndKbqValidationResult) {
			case InvalidPassword:
				alertService.addError(
					localizationService.getLocalizedString('ipp.reportPackage.package.incorrectPasswordOrAnswer')
				);
				callBackAction && callBackAction();
				break;
			case IncorrectKbqAnswer:
				alertService.addError(
					localizationService.getLocalizedString('ipp.reportPackage.package.incorrectPasswordOrAnswer')
				);
				callBackAction && callBackAction();
				break;
			case UserLockedPassword:
				alertService.addError(
					localizationService.getLocalizedString('ipp.accountPortal.messages.accountLocked')
				);
				callBackAction && callBackAction();
				urlService.signOut();
				break;
			case UserLockedKBQ:
				alertService.addError(
					localizationService.getLocalizedString('ipp.accountPortal.messages.accountLocked')
				);
				callBackAction && callBackAction();
				urlService.signOut();
				break;
			default:
				(response.statusCode === Constants.successStatusCode ||
					response.status === Constants.noContentsSuccessStatusCode) &&
					alertService.addSuccess(
						localizationService.getLocalizedString(
							`ipp.reportPackage.package.${
								packageDetails.reportStatusName === ReportPackageStatus.Error
									? 'signErrorFixedConfirmation'
									: type === 'submit'
									? packageDetails.submissionAcceptanceRequired
										? 'submitAndPendingSuccessMessage'
										: 'submitSuccessMessage'
									: 'repudiateSuccessMessage'
							}`
						)
					);
				(response.statusCode === Constants.successStatusCode ||
					response.status === Constants.noContentsSuccessStatusCode) &&
					setShowCopyReportPackageInRepudiation(true);
				await resetState();
				await loadData();
				break;
		}
	};

	const onSubmit = async (payload: any) => {
		alertService.clearAllMessages();
		payload.reportPackageIndustryUrl = initialPath + window.location.pathname;
		payload.reportPackageAuthorityUrl = `${initialPath}/${initialAuthorityPathName}/${authorityReportPackageDetailPath}/${packageDetails.reportPackageId}`;
		payload.urlScheme = protocol.split(':')[0];
		let response = await props.submitReport(Number(packageDetails.reportPackageId || 0), payload);
		passwordAndKbqValidation(response);
	};

	const onRepudiate = async (payload: IppRepudiateReportPackage, callBackAction: () => void) => {
		alertService.clearAllMessages();
		payload.reportPackageIndustryUrl = initialPath + window.location.pathname;
		payload.reportPackageAuthorityUrl = `${initialPath}/${initialAuthorityPathName}/${authorityReportPackageDetailPath}/${packageDetails.reportPackageId}`;
		payload.urlScheme = protocol.split(':')[0];
		let response = await props.repudiateReport(
			Number(packageDetails.reportPackageId) || 0,
			payload || ([] as IppRepudiateReportPackage)
		);
		passwordAndKbqValidation(response, 'repudiate', callBackAction);
	};

	const getKbq = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/Account/KbqChallengeQuestion`;
		let kbq = await apiService.getResource<QuestionAnswerDtos>(url);
		return kbq;
	};

	const isSignatoryUser = async () => {
		const url = urlService.getAuthorityResourcesApiUrl(Resource.IppUsers + '/UserProfile');
		let user = await apiService.getResource<IppUser>(url);
		return user.isSignatory;
	};

	const copyReportPackage = async () => {
		let payload = {
			..._.pick(packageDetails, [
				'reportPackageTemplateId',
				'periodStartDateTimeLocal',
				'periodEndDateTimeLocal'
			]),
			copiedFromReportPackageId: packageDetails.reportPackageId
		};

		props.addReportPackage(payload, async (reportPackage?: IppReportPackage) => {
			await resetState();
			let draftUrl = urlService.getIppIndustryUrl(
				'reportPackage',
				(reportPackage && reportPackage.reportPackageId) || 0
			);
			reportPackage && navigateTo(props.history, draftUrl);
			alertService.addSuccess(
				localizationService.getLocalizedString('ipp.reportPackage.package.copyReportPackageSuccess')
			);
			await loadData(true, Number(reportPackage && reportPackage.reportPackageId));
		});
	};

	const deleteReportPackageProps: DeleteModalProp = {
		showModal: deleteModal,
		title: localizationService.getLocalizedString('ipp.reportPackage.package.delete.title'),
		message: localizationService.getLocalizedString('ipp.reportPackage.package.delete.message'),
		okayButtonText: localizationService.getLocalizedString('ipp.buttons.delete'),
		onCancelButtonClick: onToggleDelete,
		onOkayButtonClick: onDelete,
		isDeleteButton: true
	};

	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('ipp.reportPackage.package.reportPackageDetails')}
						</h1>
						{!showCopyReportPackageInRepudiation &&
							packageDetails &&
							packageDetails.repudiationDateTimeLocal && (
								<button className="btn ai-action mr-1" onClick={copyReportPackage}>
									{localizationService.getLocalizedString('ipp.buttons.copyReportPackage')}
								</button>
							)}
						{packageDetails.reportStatusName === ReportPackageStatus.Rejected && (
							<button
								className="btn ai-action ml-auto mr-3"
								onClick={() => {
									onSave(ReportPackageStatus.Draft);
								}}>
								{localizationService.getLocalizedString(`ipp.buttons.editReportPackage`)}
							</button>
						)}
						{packageDetails.reportStatusName && (
							<label
								className={`ml-auto status-btn ${packageDetails.reportStatusName.toLowerCase()}-btn`}
								id="sample-draft-btn">
								{_.startCase(
									_.camelCase(
										packageDetails.reportStatusName === ReportPackageStatus.Error
											? localizationService.getLocalizedString(
													`ipp.reportPackage.package.reportTypes.systemError`
											  )
											: packageDetails.reportStatusName
									)
								)}
							</label>
						)}
					</div>
					<IppPackageItemReportDetails {...packageDetails} />
					{packageDetails.samplesAndResultsTypes && packageDetails.samplesAndResultsTypes.length ? (
						<IppPackageItemSamples
							showSearch={packageDetails.reportStatusName === ReportPackageStatus.Draft ? true : false}
							showFilter={packageDetails.reportStatusName === ReportPackageStatus.Draft ? true : false}
							loadSamples={loadSamples}
							packageDetails={packageDetails}
							reportStatus={packageDetails.reportStatusName}
							isRequired={
								packageDetails.samplesAndResultsTypes &&
								packageDetails.samplesAndResultsTypes[0].isRequired
							}
							onChangeSample={onChangeSample}
							sampleTypes={sampleTypes[0] && sampleTypes[0].ippSamples}
							saveReportPackage={onSave}
							className="mt-3"
							history={props.history}
							addNewSampleButton={true}
							updateReportPackageOnCopyAndEdit={updateReportPackageOnCopyAndEdit}
						/>
					) : null}

					<IppPackageItemAttachments
						showSearch={packageDetails.reportStatusName === ReportPackageStatus.Draft ? true : false}
						showFilter={packageDetails.reportStatusName === ReportPackageStatus.Draft ? true : false}
						reportStatus={packageDetails.reportStatusName}
						onChangeAttachment={onChangeAttachment}
						attachmentTypes={attachmentTypes}
						loadAttachments={loadAttachments}
						className="mt-3"
					/>
					<IppPackageItemCertifications
						reportStatus={packageDetails.reportStatusName}
						onChangeCertification={onChangeCertification}
						certificationTypes={packageDetails.certificationTypes || []}
						className="mt-3"
					/>
					<IppPackageItemComments
						reportStatusName={packageDetails.reportStatusName}
						onChangeComments={onChangeComments}
						comments={packageDetails.comments || ''}
						className="mt-3"
					/>
					<IppPackageReviewSummary
						samplesAndResultsTypes={[...sampleTypes]}
						attachmentTypes={[...attachmentTypes]}
						certificationTypes={
							packageDetails.certificationTypes ? [...packageDetails.certificationTypes] : []
						}
						className="mt-3"
						showSampleSummary={
							packageDetails.samplesAndResultsTypes && !!packageDetails.samplesAndResultsTypes.length
						}
					/>
					{packageDetails.reportStatusName !== ReportPackageStatus.Draft && (
						<IppPackageSubmission
							packageDetails={packageDetails}
							getKbq={getKbq}
							onSubmit={onSubmit}
							className="mt-3"
							reportStatusName={packageDetails.reportStatusName}
							canCurrentUserSubmitAndRepudiate={canCurrentUserSubmitAndRepudiate}
						/>
					)}
					{packageDetails.reportStatusName === ReportPackageStatus.Error && (
						<IppPackageSubmission
							packageDetails={packageDetails}
							getKbq={getKbq}
							onSubmit={onSubmit}
							className="mt-3"
							reportStatusName={packageDetails.reportStatusName}
							canCurrentUserSubmitAndRepudiate={canCurrentUserSubmitAndRepudiate}
							isErrorPackage={true}
						/>
					)}
					{packageDetails.fixedDateTimeLocal && (
						<IppPackageFixed packageDetails={packageDetails} className="mt-3" />
					)}
					{packageDetails.reportStatusName === ReportPackageStatus.Rejected && (
						<IppPackageRejection className="mt-3" packageDetails={packageDetails} />
					)}
					{packageDetails.reportStatusName === ReportPackageStatus.Submitted &&
						canCurrentUserSubmitAndRepudiate && (
							<IppPackageRepudiation
								packageDetails={packageDetails}
								onRepudiate={onRepudiate}
								className="mt-3"
								reportStatusName={packageDetails.reportStatusName}
								getKbq={getKbq}
							/>
						)}
					{packageDetails.repudiationDateTimeLocal && (
						<IppPackageItemRepudiationConfirmation
							{...packageDetails}
							className="mt-3"
							showCopyReportPackage={showCopyReportPackageInRepudiation}
							copyReportPackage={copyReportPackage}
						/>
					)}
				</div>
			</div>
			{packageDetails.reportStatusName === ReportPackageStatus.Draft ||
			packageDetails.reportStatusName === ReportPackageStatus.ReadyToSubmit ||
			packageDetails.reportStatusName === ReportPackageStatus.Error ? (
				<section className="mt-3 d-flex">
					{packageDetails.reportStatusName !== ReportPackageStatus.Error && (
						<button
							className="btn ai-delete mr-auto"
							onClick={() => {
								onToggleDelete();
							}}>
							{localizationService.getLocalizedString(
								`ipp.buttons.${
									packageDetails.reportStatusName === ReportPackageStatus.Draft
										? 'deleteDraft'
										: 'deleteReportPackage'
								}`
							)}
						</button>
					)}

					<div className="ml-auto">
						{packageDetails.reportStatusName === ReportPackageStatus.Draft && (
							<button
								className="btn ai-save"
								onClick={() => {
									onSave();
								}}>
								{localizationService.getLocalizedString('ipp.buttons.saveDraft')}
							</button>
						)}
						{(packageDetails.reportStatusName === ReportPackageStatus.ReadyToSubmit ||
							packageDetails.reportStatusName === ReportPackageStatus.Error) && (
							<button
								className="btn ai-action"
								onClick={() => {
									onPreview();
								}}>
								{localizationService.getLocalizedString('ipp.buttons.preview')}
							</button>
						)}
						{packageDetails.reportStatusName !== ReportPackageStatus.Error && (
							<button
								className="btn ai-action ml-1"
								onClick={() => {
									packageDetails.reportStatusName === ReportPackageStatus.Draft &&
										onSave(ReportPackageStatus.ReadyToSubmit);
									packageDetails.reportStatusName === ReportPackageStatus.ReadyToSubmit &&
										onSave(ReportPackageStatus.Draft);
								}}>
								{localizationService.getLocalizedString(
									`ipp.buttons.${
										packageDetails.reportStatusName === ReportPackageStatus.Draft
											? 'readyToSubmit'
											: 'enableEditing'
									}`
								)}
							</button>
						)}
					</div>
				</section>
			) : (
				<section className="mt-3 d-flex justify-content-end">
					<IppPackageReviewsCorButtons packageId={packageDetails.reportPackageId} />
				</section>
			)}
			<DeleteModal {...deleteReportPackageProps} />
		</>
	);
};

const mapStateToProps = (state: ApplicationState): PackageState => {
	return { ...state.ippReportPackage };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadPackage: (id?: number) => dispatch(loadPackageDetails(id)),
		loadSamples: (queryParameters: string = '') => dispatch(loadSamples(queryParameters)),
		loadAttachments: (queryParameters: string = '') => dispatch(loadAttachments(queryParameters)),
		updatePackage: (
			payload: IppReportPackage,
			callbackOnSuccess?: (response?: any) => void,
			showSuccessMessage?: boolean
		) => dispatch(updatePackage(payload, callbackOnSuccess, showSuccessMessage)),
		resetData: () => dispatch(resetData()),
		submitReport: (id: number, payload: any) => dispatch(submitPackage(id, payload)),
		repudiateReport: (id: number, payload: IppRepudiateReportPackage) => dispatch(repudiatePackage(id, payload)),
		addReportPackage: (payload: IppReportPackage, callbackOnSuccess: (reportPackage?: IppReportPackage) => void) =>
			dispatch(addReportPackage(payload, callbackOnSuccess))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppPackageDetails);
