import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, IppIndustrySample, LocalStorageName } from '@rcp/types';
import { History } from 'history';
import React, { FC } from 'react';
import { Utils } from 'src/services/utils';
import { RootState } from 'src/redux';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, navigateTo, urlService } from 'src/services';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { ippSamplesReadyToReportSlice } from './samples-ready-to-report-slice';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const SampleStatusName = 'ReadyToReport';
const IppIndustrySamplesReadyToReportComponent: FC<Props<IppIndustrySample>> = props => {
	const sampleTypeToLink = (item: any) => {
		const dataItem = item.dataItem as IppIndustrySample;
		const url = urlService.getIppIndustryUrl(`samples/${item.dataItem.sampleId}/details`);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.ctsEventTypeName}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showTabList: false,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		showRefreshButton: true,
		prefix: 'ipp.samples.columns',
		storageName: LocalStorageName.IppIndustryReadyToReportSamples,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: localizationService.getLocalizedString('ipp.samples.samplesReadyToReport'),
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		addButtonText: '',
		showScheduleInspectionModal: false,
		queryParameters: { SampleStatusName, sort: 'startDateTimeLocal asc' },
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('sampleId', FilterType.Integer),
			new ColumnField('name'),
			new ColumnField('monitoringPointName'),
			new ColumnField('ctsEventTypeName', FilterType.Text, sampleTypeToLink),
			new ColumnField('collectionMethodName'),
			new ColumnField('labSampleIdentifier'),
			new ColumnField('startDateTimeLocal', FilterType.Date),
			new ColumnField('endDateTimeLocal', FilterType.Date),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isAssociatedWithReportPackage', FilterType.Boolean),
			new ColumnField('isSampleExcludedFromReports', FilterType.Boolean)
		],

		defaultColumns: [
			'monitoringPointName',
			'ctsEventTypeName',
			'collectionMethodName',
			'startDateTimeLocal',
			'endDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName'
		]
	};
	const samplesState = (state: RootState) => state.ippIndustrySamplesReadyToReport;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippSamplesReadyToReportSlice}
				restState={samplesState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppIndustrySamplesReadyToReportComponent;
