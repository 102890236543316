import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	saveFogFacility,
	CustomFieldDefinitionsState,
	alertService
} from 'src/redux';
import { FogFacility, ICustomFieldItem, Dictionary, CustomFieldDataType } from '@rcp/types';
import { PopoverModal } from 'src/components/widgets';
import {
	urlService,
	validationService,
	customDefinedFieldService,
	UtilService,
	localizationService
} from 'src/services';
import { customFieldGenerator } from './custom-field-service';
import { Utils } from 'src/services/utils';
interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	sortSiteFacilityCustomField: (list: any[]) => any[];
}

interface StateProps extends FogFacilityState {
	customFieldDefinitions: CustomFieldDefinitionsState;
}

interface DispatchProps {
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface CustomFieldForm {
	customFields: ICustomFieldItem[];
}

const initialCustomFieldForm: CustomFieldForm = {
	customFields: []
};

const CustomFieldsModalComp: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState(initialCustomFieldForm);

	const setFormStateFromProps = (
		facility: FogFacility,
		facilityCustomFieldDefinitions: CustomFieldDefinitionsState
	) => {
		if (
			!_.isEmpty(facilityCustomFieldDefinitions) &&
			!_.isEmpty(facilityCustomFieldDefinitions.customFieldDefinitions) &&
			!_.isEmpty(facility)
		) {
			let customFields = customDefinedFieldService.toCustomFieldItems(
				facilityCustomFieldDefinitions.customFieldDefinitions,
				facility
			);
			customFields = Utils.sortCustomField(customFields);
			customFields = props.sortSiteFacilityCustomField(customFields);
			setFormState({ customFields });
		}
	};

	React.useEffect(() => {
		setFormStateFromProps(props.facility, props.customFieldDefinitions);
	}, [props.facility, props.customFieldDefinitions]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.each(newState.customFields, customField => {
			if (String.equalCaseInsensitive(customField.fieldName, name)) {
				customField.value = String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)
					? value
					: value;
				customField.isRequired && validationService.validateRequiredField(customField, 'value', 'error');
			}
		});
		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		_.each(newState.customFields, customField => {
			(customField.isRequired || customField.isImportKey) &&
				validationService.validateRequiredField(customField, 'value', 'error');
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Number)
			) {
				validationService.validateNumberField(customField, 'value', 'error');
			}
			
			if (customField.fieldDataType && String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)) {
				validationService.validateMinimumDate(customField, 'value', 'error', customField.label);
			}
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Link)
			) {
				validationService.validateUrlField(customField, 'value', 'error');
			}
		});
		setFormState(newState);

		let hasError = false;
		_.each(newState.customFields, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};

	const saveCustomFields = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let customFields: Dictionary<string> = {};
		formState.customFields.forEach(item => (customFields[UtilService.toCamelCase(item.fieldName)] = item.value));
		let facilityToUpdate: FogFacility = {
			customFields: customFields
		};
		props.saveFogFacility(urlService.getFogFacilityId(), facilityToUpdate);
		props.toggle();
	};

	const cancelSaveCustomFields = () => {
		setFormStateFromProps(props.facility, props.customFieldDefinitions);
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title="Edit Custom Fields"
				save={saveCustomFields}
				cancel={cancelSaveCustomFields}>
				{formState.customFields.map((customField: ICustomFieldItem, index: number) => {
					if (String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)) {
						return customFieldGenerator.getDateInputElement(customField, index, changeFormState);
					}

					if (
						String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.SingleValueDropDown)
					) {
						return customFieldGenerator.getSingleSelectionValueElement(customField, index, changeFormState);
					}

					if (String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.TextArea)) {
						return customFieldGenerator.getTextAreaInputElement(customField, index, true, changeFormState);
					}

					return customFieldGenerator.getTextInputElement(customField, index, changeFormState);
				})}
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, customFieldDefinitions: state.customFieldDefinitions };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate))
	};
};

export const CustomFieldsModal = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(CustomFieldsModalComp);
