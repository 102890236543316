import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiService, Resource, urlService, localizationService, optionsMap } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../alert';

export interface ExtractorState {
	extractorTypeList: ApiTypes.ExtractorType[];
	extractorList: ApiTypes.Extractor[];
	facilityExtractorList: ApiTypes.FacilityExtractor[];
	frequencyList: ApiTypes.Frequency[];
	unitList: ApiTypes.Unit[];
}

export const initialExtractorState: ExtractorState = {
	extractorTypeList: [],
	extractorList: [],
	facilityExtractorList: [],
	frequencyList: [],
	unitList: []
};

enum ActionType {
	LoadExtractorTypeList = 'loadExtractorTypeList',
	LoadFrequencyList = 'loadFrequencyList',
	LoadUnitList = 'loadUnitList',

	LoadExtractorList = 'loadExtractorList',

	AddFacilityExtractor = 'addFacilityExtractor',
	UpdateAuthorityExtractor = 'updateAuthorityExtractor',
	DeleteFacilityExtractor = 'deleteFacilityExtractor'
}

export type ExtractorThunkAction = ThunkAction<any, ExtractorState, any, Action>;

export const loadExtractorTypeList = () => async (dispatch: any) => {
	var url = urlService.getAuthorityLookupResourceApiUrl(Resource.ExtractorTypes);
	const result = await apiService.httpGet(url);

	dispatch({ type: ActionType.LoadExtractorTypeList, extractorTypeList: result });
};

export const loadFrequencyList = () => async (dispatch: any) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.Frequencies);
	const result = await apiService.httpGet(url);

	dispatch({ type: ActionType.LoadFrequencyList, frequencyList: result });
};

export const loadUnitList = () => async (dispatch: any) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.Units);
	const result = await apiService.httpGet(url);

	dispatch({ type: ActionType.LoadUnitList, unitList: result });
};

export const loadUnitListHauler = (authorityId: number) => async (dispatch: any) => {
	const url = urlService.isHaulerPortal()
		? urlService.getAuthoritySettingsUrlForService(authorityId, Resource.Units)
		: urlService.getAuthoritySettingResourceApiUrl(Resource.Units);
	const result = await apiService.httpGet(url);

	dispatch({ type: ActionType.LoadUnitList, unitList: result });
};

export const loadExtractorList = (facilityID?: number) => async (dispatch: any) => {
	let facilityId = facilityID ? facilityID : urlService.getFogFacilityId();
	let facilityExtractorList = [];
	if (facilityId > 0) {
		const facilityExtractorUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Extractors);
		facilityExtractorList = (await apiService.httpGet(facilityExtractorUrl)).result;
	}

	const url = urlService.getAllExtractorsUrl();
	const extractorList = await apiService.getResource<ApiTypes.Extractor>(url);
	dispatch({
		type: ActionType.LoadExtractorList,
		facilityExtractorList: facilityExtractorList,
		extractorList: extractorList
	});
};

export const addFacilityExtractor = (extractorToCreate: ApiTypes.Extractor) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	const extractorUrl = urlService.getAuthorityResourcesApiUrl(Resource.Extractors);

	let postExtractorData = {
		items: [] as ApiTypes.Extractor[]
	};

	delete extractorToCreate.extractorId;
	postExtractorData.items.push(extractorToCreate);
	postExtractorData.items.forEach(e => (e.facilityId = facilityId));

	await apiService.httpPost(extractorUrl, postExtractorData);

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.extractor')
	);

	dispatch(loadExtractorList(facilityId));
	return true;
};

export const updateAuthorityExtractor = (extractorId: number, dataToUpdate: ApiTypes.Extractor) => async (
	dispatch: any
) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.Extractors) + '/' + extractorId;

	await apiService.httpPatch(url, dataToUpdate);

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.updateSuccess', 'screen.labels.extractor')
	);
	dispatch(loadExtractorList());
	return true;
};

export const deleteFacilityExtractor = (extractorId: number) => async (dispatch: any) => {
	const extractorUrl = urlService.getAuthorityResourcesApiUrl(Resource.Extractors) + '/' + extractorId;
	await apiService.httpDelete(extractorUrl);

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.removeSuccess', 'screen.labels.extractor')
	);
	dispatch(loadExtractorList());
	return true;
};

export const extractorReducer = (state = initialExtractorState, action: any): ExtractorState => {
	switch (action.type) {
		case ActionType.LoadExtractorTypeList:
			return { ...state, extractorTypeList: action.extractorTypeList };
		case ActionType.LoadExtractorList:
			return {
				...state,
				extractorList: action.extractorList,
				facilityExtractorList: action.facilityExtractorList
			};
		case ActionType.UpdateAuthorityExtractor:
			return { ...state };
		case ActionType.AddFacilityExtractor:
			return { ...state };
		case ActionType.DeleteFacilityExtractor:
			return { ...state };
		case ActionType.LoadFrequencyList:
			return { ...state, frequencyList: action.frequencyList.sort(optionsMap.sortFrequencies) };
		case ActionType.LoadUnitList:
			return { ...state, unitList: action.unitList };

		default:
			return { ...state };
	}
};
