import { LookupRow } from '../lookup-types';
import { SettingType, SettingValue } from '@rcp/types';

import { ILookupDataConverter } from './lookup-data-converter';
import _ from 'lodash';

export class SettingRowValueConverter implements ILookupDataConverter {
	private settingValueType: SettingType;

	constructor(settingValueType: SettingType) {
		this.settingValueType = settingValueType;
	}

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		return apiData as LookupRow;
	};

	toAPIAddDataFromLookups = (existingLookupRows: LookupRow[], newValue: LookupRow): SettingValue => {
		let dataToPost = {
			settingType: this.settingValueType,
			value: `${existingLookupRows.map(i => i.code).join(',')},${newValue.code}`
		} as SettingValue;

		return dataToPost;
	};

	toAPIDeleteDataFromLookups = (existingRows: LookupRow[], rowToDelete: LookupRow): SettingValue => {
		var newValues = existingRows.filter(i => i.code !== rowToDelete.code);
		let dataToPost = {
			settingType: this.settingValueType,
			value: `${newValues.map(i => i.code).join(',')}`
		} as SettingValue;

		return dataToPost;
	};

	toAPIUpdateDataFromLookups = (existingRows: LookupRow[], rowToUpdate: LookupRow): SettingValue => {
		var newValues = existingRows.filter(i => i.lookupId !== rowToUpdate.lookupId);
		newValues.push(rowToUpdate);
		let dataToPost = {
			settingType: this.settingValueType,
			value: `${newValues.map(i => i.code).join(',')}`
		} as SettingValue;

		return dataToPost;
	};

	toLookupRowsFromAPI = (apiData: any, inUseData?: any): LookupRow[] => {
		let lookupValues: LookupRow[] = [];
		if (apiData instanceof Array) {
			var strValues = apiData as string[];
			if (apiData) {
				lookupValues = strValues.map((i: string, index: number) => {
					return {
						lookupId: index,
						code: i,
						isActive: true
					} as LookupRow;
				});
				let inUseValues = inUseData as string[];
				if (inUseValues) {
					var inUseValuesInLowerCase = inUseValues.map(v => _.toLower(v));
					lookupValues.forEach(v => {
						v.isInUse = _.includes(inUseValuesInLowerCase, _.toLower(v.code));
					});
				}
			}
		} else {
			let settingValue = apiData as SettingValue;
			if (lookupValues) {
				lookupValues = settingValue.value.split(',').map((i: string, index: number) => {
					return {
						lookupId: index,
						code: i,
						isActive: true
					} as LookupRow;
				});
			}
		}

		return lookupValues;
	};
}
