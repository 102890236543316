import { History } from 'history';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SvgAuthorities } from '../../../../assets/img/settings-authorities.svg';
import { ReactComponent as SvgHaulers } from '../../../../assets/img/settings-haulers.svg';
import { ReactComponent as SvgUser } from '../../../../assets/img/settings-users.svg';
import { localizationService, urlService } from 'src/services';
import { LandingPage } from './landing-page';
import { SettingCard } from './setting-card';

type Props = {
	history: History;
};

const HaulerPortalLandingPageComponent: React.FunctionComponent<Props> = props => {
	const authoritySettings = () => {
		let cards = [
			<SettingCard
				id="divSettingHaulerAuthorities"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.viewAuthoritiesDescription'
				)}
				link={urlService.getSettingMenuPath('/authorities')}
				history={props.history}
				iconSource={<SvgAuthorities />}
				hidden={!urlService.isAdministrator()}
			/>,
			<SettingCard
				id="divSettingHaulerBusinessInformation"
				title={localizationService.getLocalizedString('haulerPortal.settings.businessInformation.title')}
				key={localizationService.getLocalizedString('authoritySetting.haulers')}
				description={localizationService.getLocalizedString(
					'haulerPortal.settings.businessInformation.businessInformationDescription'
				)}
				link={urlService.getSettingMenuPath('/businessInformation')}
				history={props.history}
				iconSource={<SvgHaulers />}
			/>,
			<SettingCard
				id="divSettingHaulerUsers"
				title={localizationService.getLocalizedString('haulerPortal.settings.users.users')}
				key={localizationService.getLocalizedString('haulerPortal.settings.users.users')}
				description={localizationService.getLocalizedString('haulerPortal.settings.users.usersDescription')}
				link={urlService.getSettingMenuPath('/users')}
				history={props.history}
				iconSource={<SvgUser />}
			/>
		];

		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};

	return <LandingPage history={props.history} authoritySettings={authoritySettings()} />;
};

export const HaulerPortalSettingsLandingPage = connect()(HaulerPortalLandingPageComponent);
