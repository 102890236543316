import React from 'react';

import * as ApiTypes from '@rcp/types';

import { localizationService, Resource, urlService } from 'src/services';

interface OwnProps {
	event: ApiTypes.TimelineNotice;
}

type Props = OwnProps;

export const TimelineEventSentNotice: React.SFC<Props> = props => {
	const getHazardNumberSpan = (sentNotice: ApiTypes.TimelineNotice) => {
		if (urlService.getDomainObjectName() !== Resource.CccHazards)
			return (
				<span>
					&nbsp;{localizationService.getLocalizedString('screen.timeline.for')}&nbsp;
					<a href={urlService.getReactAuthorityResourceUrl(Resource.CccHazards, sentNotice.hazardId)}>
						{localizationService.getLocalizedString('cccHazard.hazard')} {sentNotice.hazardNumber}
					</a>
					&nbsp;
				</span>
			);
		return <span />;
	};

	return (
		<div>
			<div>
				<a href={props.event.s3PdfLink} className="ai-link" target={'_blank'} rel="noopener noreferrer">
					{props.event.templateName}
				</a>
				<span>
					&nbsp;
					{localizationService.getLocalizedString(
						'screen.timeline.sentNoticeLetterDescription',
						props.event.contactName ? props.event.contactName : props.event.companyName
					)}
					{getHazardNumberSpan(props.event)}.
				</span>
			</div>
		</div>
	);
};
