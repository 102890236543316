import React, { Component, RefObject } from 'react';
import {
	IppReportPackageTemplateElement,
	IppReportPackageTemplate,
	IppAttachmentType,
	IppAvailableCtsEventType,
	IppIndustry
} from '@rcp/types';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import AvailableSelectedGrids from './available-selected-grids';
import ReportDetailsComponent, { ReportRef } from './report-details';
import { samplesAndTypes, industries, attachmentTypes, certifications } from './template-columns';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import {
	addTemplate,
	deleteTemplate,
	loadTemplateDetails,
	ReportPackageTemplateState,
	updateTemplateDetails
} from 'src/redux/ipp/authority/report-packages/templates';
import { History } from 'history';
import * as LinkoTypes from '@rcp/types';
import { alertService, ApplicationState } from 'src/redux';
import _ from 'lodash';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { RouteProps } from 'react-router';
import { IppConstants } from 'src/constants';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface DispatchProps {
	loadTemplate: (id?: number) => Promise<any>;
	updateTemplate: (template: IppReportPackageTemplate) => Promise<boolean | undefined>;
	addTemplate: (template: IppReportPackageTemplate, callback: (id: number) => void) => Promise<boolean | undefined>;
	deleteTemplate: (templateId: number) => Promise<boolean | undefined>;
}

interface Props extends DispatchProps, RouteProps, LinkoTypes.RouteProps {
	templateDetails: IppReportPackageTemplate;
	availableSamplesAndResultsTypes: IppReportPackageTemplateElement[];
	availableAttachmentTypes: IppAttachmentType[];
	availableCertificationTypes: IppAttachmentType[];
	availableReportPackageTemplateAssignments: IppIndustry[];
	availableCtsEventTypes: IppAvailableCtsEventType[];
	history: History;
	loadSuccess: boolean;
}

interface State {
	availableAttachmentTypes: IppReportPackageTemplateElement[];
	availableSamplesAndResultsTypes: IppReportPackageTemplateElement[];
	availableCertificationTypes: IppReportPackageTemplateElement[];
	availableTemplateAssignments: IppIndustry[];
	templateDetails: IppReportPackageTemplate;
	deleteTemplateModal: boolean;
	isFormValid: boolean;
	isAccessDenied: boolean;
	isDataSet: boolean;
}

interface AttachmentTypesGridsData {
	available: IppReportPackageTemplateElement[];
	selected: IppReportPackageTemplateElement[];
}

interface CertificationsGridsData {
	available: IppReportPackageTemplateElement[];
	selected: IppReportPackageTemplateElement[];
}
interface SamplesAndTypesGridsData {
	available: IppReportPackageTemplateElement[];
	selected: IppReportPackageTemplateElement[];
}
interface TemplateAssignmentsGridsData {
	available: IppIndustry[];
	selected: IppIndustry[];
}

const initialState: State = {
	availableAttachmentTypes: [],
	availableSamplesAndResultsTypes: [],
	availableCertificationTypes: [],
	availableTemplateAssignments: [],
	templateDetails: {
		samplesAndResultsTypes: [],
		attachmentTypes: [],
		certificationTypes: [],
		reportPackageTemplateAssignments: [],
		reportPackageTemplateElementCategories: [],
		effectiveDateTimeLocal: ''
	} as IppReportPackageTemplate,
	deleteTemplateModal: false,
	isFormValid: true,
	isAccessDenied: false,
	isDataSet: false
};

class IppReportPackageTemplateDetailsComponent extends Component<Props, State> {
	private reportHandle: RefObject<ReportRef>;
	constructor(props: Props) {
		super(props);
		this.state = { ...initialState };
		this.reportHandle = React.createRef<ReportRef>();
	}

	async componentDidMount() {
		let response = await this.props.loadTemplate(this.props.match.params.templateId);
		if (
			response &&
			response.statusCode === 403 &&
			String.equalCaseInsensitive(
				response.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			this.setState({ isAccessDenied: true }, () => {
				alertService.clearAllMessages();
			});
		}
	}

	static getDerivedStateFromProps(props: Props, state: State) {
		if (String.equalCaseInsensitive(window.location.pathname.split('/').pop(), 'new')) {
			if (
				props.templateDetails.reportPackageTemplateId ||
				(!props.templateDetails.reportPackageTemplateId && !props.templateDetails.effectiveDateTimeLocal)
			) {
				return null;
			} else if (!state.isDataSet) {
				return {
					templateDetails: { ...(props.templateDetails as IppReportPackageTemplate) },
					availableAttachmentTypes: props.availableAttachmentTypes,
					availableSamplesAndResultsTypes: props.availableSamplesAndResultsTypes,
					availableCertificationTypes: props.availableCertificationTypes,
					availableTemplateAssignments: props.availableReportPackageTemplateAssignments,
					isDataSet: true
				};
			} else {
				return null;
			}
		} else {
			if (!props.templateDetails.reportPackageTemplateId) {
				!state.isAccessDenied &&
					props.loadTemplate(props.match.params.templateId).then(response => {
						if (
							response &&
							response.statusCode === 403 &&
							String.equalCaseInsensitive(
								response.message,
								localizationService.getLocalizedString('errors.noPermissionToAccess')
							)
						) {
							alertService.clearAllMessages();
						}
					});
			}
			if (props.templateDetails.reportPackageTemplateId + '' !== window.location.pathname.split('/').pop()) {
				return null;
			} else if (!state.isDataSet) {
				return {
					templateDetails: { ...(props.templateDetails as IppReportPackageTemplate) },
					availableAttachmentTypes: props.availableAttachmentTypes,
					availableSamplesAndResultsTypes: props.availableSamplesAndResultsTypes,
					availableCertificationTypes: props.availableCertificationTypes,
					availableTemplateAssignments: props.availableReportPackageTemplateAssignments,
					isDataSet: true
				};
			} else {
				return null;
			}
		}
	}

	onSampleTypesChange = (data: SamplesAndTypesGridsData) => {
		this.setState(prevState => ({
			...prevState,
			templateDetails: { ...this.state.templateDetails, samplesAndResultsTypes: data.selected },
			availableSamplesAndResultsTypes: data.available
		}));
	};
	onAttachmentTypesChange = (data: AttachmentTypesGridsData) => {
		this.setState(prevState => ({
			...prevState,
			templateDetails: { ...this.state.templateDetails, attachmentTypes: data.selected },
			availableAttachmentTypes: data.available
		}));
	};
	onCertificationChange = (data: CertificationsGridsData) => {
		this.setState(prevState => ({
			...prevState,
			templateDetails: { ...this.state.templateDetails, certificationTypes: data.selected },
			availableCertificationTypes: data.available
		}));
	};
	onTemplateAssignmentChange = (data: TemplateAssignmentsGridsData) => {
		this.setState(prevState => ({
			...prevState,
			templateDetails: { ...this.state.templateDetails, reportPackageTemplateAssignments: data.selected },
			availableTemplateAssignments: data.available
		}));
	};

	//A delay of 0.2 seconds added to improve the performance.
	onTemplateDetailsChange = _.debounce((templateDetails: IppReportPackageTemplate) => {
		this.setState({
			templateDetails
		});
	}, 200);

	onSave = async () => {
		if (this.reportHandle.current) {
			let reportDetails = this.reportHandle.current.getReportDetails();
			if (reportDetails.effectiveDateTimeLocal) {
				let effectiveDateStr = DateUtilService.toDisplayDate(
					reportDetails.effectiveDateTimeLocal,
					'YYYY-MM-DD'
				);

				reportDetails.effectiveDateTimeLocal = DateUtilService.prepareDateStringForApiCall(
					`${effectiveDateStr}T00:00:00`
				);
			}
			reportDetails.name = (reportDetails && reportDetails.name && reportDetails.name.trim()) || '';
			let template: IppReportPackageTemplate = {
				...reportDetails,
				attachmentTypes: [...(this.state.templateDetails.attachmentTypes || [])],
				samplesAndResultsTypes: [...(this.state.templateDetails.samplesAndResultsTypes || [])],
				certificationTypes: [...(this.state.templateDetails.certificationTypes || [])],
				reportPackageTemplateAssignments: [
					...(this.state.templateDetails.reportPackageTemplateAssignments || [])
				]
			};
			if (String.equalCaseInsensitive(window.location.pathname.split('/').pop(), 'new')) {
				if (this.reportHandle.current.isFormValidateForSave()) {
					await this.props.addTemplate(template, id => {
						navigateTo(
							this.props.history,
							urlService.getIppAuthorityUrl(`${Resource.IppReportPackageTemplates}`, id)
						);
					});
				} else {
					alertService.addError(
						localizationService.getLocalizedString('screen.validationMessage.formValidation')
					);
				}
			} else {
				if (this.reportHandle.current.isFormValidateForSave()) {
					await this.props.updateTemplate(template);
				} else {
					alertService.addError(
						localizationService.getLocalizedString('screen.validationMessage.formValidation')
					);
				}
			}
		}
	};

	deleteTemplate = async () => {
		this.toggleDelete();

		await this.props.deleteTemplate(this.props.match.params.templateId as number);
		navigateTo(this.props.history, urlService.getIppAuthorityUrl(Resource.IppReportPackageTemplates));
	};

	toggleDelete = () => {
		this.setState(prevState => ({
			deleteTemplateModal: !prevState.deleteTemplateModal
		}));
	};

	onDeleteTemplate = () => {
		this.setState({
			deleteTemplateModal: true
		});
	};

	isFormInAddMode = () => {
		return window.location.pathname.includes(IppConstants.newFormText);
	};

	render() {
		let templateDetailsDeleteModalProp: DeleteModalProp = {
			title: localizationService.getLocalizedString('ipp.reportPackage.templateDetails.deleteModalTitle'),
			message: localizationService.getLocalizedString('ipp.reportPackage.templateDetails.deleteModalMessage'),
			showModal: this.state.deleteTemplateModal,
			onCancelButtonClick: this.toggleDelete,
			onOkayButtonClick: this.deleteTemplate,
			okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
			isDeleteButton: true
		};
		return this.state.isAccessDenied ? (
			<AccessDeniedPage />
		) : (
			<>
				<DeleteModal {...templateDetailsDeleteModalProp} key="templateDetailsDeleteModal" />

				<div className="d-flex w-100 flex-column flex-lg-row">
					<div className={`d-flex flex-column w-100`}>
						<div className="page-header">
							<h1>{localizationService.getLocalizedString('ipp.reportPackage.templateDetails.title')}</h1>
							<button className="btn ai-save" id="template-save" onClick={() => this.onSave()}>
								{localizationService.getLocalizedString('ipp.buttons.save')}
							</button>
							{!this.isFormInAddMode() && (
								<button
									className="btn ai-delete ml-2"
									id="template-save"
									onClick={() => this.onDeleteTemplate()}>
									{localizationService.getLocalizedString('ipp.buttons.delete')}
								</button>
							)}
						</div>
						{/* Report Details Section*/}
						<CollapsibleCard accordionType="report-details" accordionHeading="Report Details">
							{!this.isFormInAddMode() && (
								<div id="report-details-subtitle">
									<p>
										{localizationService.getLocalizedString(
											'ipp.reportPackage.templateDetails.subtitle'
										)}
									</p>
								</div>
							)}
							<ReportDetailsComponent
								templateDetails={
									Number(window.location.pathname.split('/').pop()) ===
									this.props.templateDetails.reportPackageTemplateId
										? this.props.templateDetails
										: initialState.templateDetails
								}
								availableCtsEventTypes={this.props.availableCtsEventTypes}
								onTemplateDetailsChange={this.onTemplateDetailsChange}
								ref={this.reportHandle}
								isFormInAddMode={this.isFormInAddMode}
							/>
						</CollapsibleCard>

						{/* Samples and Results Section */}
						<CollapsibleCard
							accordionType="samples-and-results"
							accordionHeading={localizationService.getLocalizedString(
								'ipp.reportPackage.reportTemplateElement.samplesAndResults'
							)}
							className="mt-3">
							<div id="samples-and-results-subtitle">
								<p>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.sampleAndResultsTypeSubtitle'
									)}
								</p>
							</div>
							<AvailableSelectedGrids<IppReportPackageTemplateElement>
								columns={samplesAndTypes}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableSamples'
									),
									selected: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedSamples'
									)
								}}
								available={this.state.availableSamplesAndResultsTypes}
								selected={this.state.templateDetails.samplesAndResultsTypes || []}
								resourceKey="ipp.reportPackage.reportTemplateElement"
								onChange={this.onSampleTypesChange}
								isDraggable={true}
								availableSortable={true}
								selectedSortable={false}
								id="sample-type"
							/>
						</CollapsibleCard>

						{/* Attachments Section */}
						<CollapsibleCard accordionType="attachments" accordionHeading="Attachments" className="mt-3">
							<div id="attachments-subtitle">
								<p>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.attachmentTypeSubtitle'
									)}
								</p>
							</div>
							<AvailableSelectedGrids<IppReportPackageTemplateElement>
								columns={attachmentTypes}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableAttachments'
									),
									selected: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedAttachments'
									)
								}}
								available={this.state.availableAttachmentTypes}
								selected={this.state.templateDetails.attachmentTypes || []}
								resourceKey="ipp.reportPackage.reportTemplateElement"
								onChange={this.onAttachmentTypesChange}
								isDraggable={true}
								availableSortable={true}
								selectedSortable={false}
								id="attachment-type"
							/>
						</CollapsibleCard>

						{/* Certifications Section */}
						<CollapsibleCard
							accordionType="certifications"
							accordionHeading="Certifications"
							className="mt-3">
							<div id="certifications-subtitle">
								<p>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.certificationSubtitle'
									)}
								</p>
							</div>
							<AvailableSelectedGrids<IppReportPackageTemplateElement>
								columns={certifications}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableCertifications'
									),
									selected: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedCertifications'
									)
								}}
								available={this.state.availableCertificationTypes}
								selected={this.state.templateDetails.certificationTypes || []}
								resourceKey="ipp.reportPackage.reportTemplateElement"
								onChange={this.onCertificationChange}
								isDraggable={true}
								availableSortable={true}
								selectedSortable={false}
								id="certifications"
							/>
						</CollapsibleCard>

						{/* Template Assignments Section */}
						<CollapsibleCard
							accordionType="assign-industries"
							accordionHeading="Assign Industries"
							className="mt-3">
							<div id="industries-subtitle">
								<p>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.assignedIndustriesSubtitle'
									)}
								</p>
							</div>
							<AvailableSelectedGrids<IppIndustry>
								columns={industries}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableIndustries'
									),
									selected: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedIndustries'
									)
								}}
								available={this.state.availableTemplateAssignments}
								selected={this.state.templateDetails.reportPackageTemplateAssignments || []}
								resourceKey="ipp.reportPackage.templateAssignment"
								onChange={this.onTemplateAssignmentChange}
								isDraggable={false}
								availableSortable={true}
								selectedSortable={true}
								showAddAll={true}
								showRemoveAll={true}
								showSearch={true}
								id="industries"
							/>
						</CollapsibleCard>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): ReportPackageTemplateState => {
	return { ...state.ippReportPackageTemplate };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadTemplate: (id?: number) => dispatch(loadTemplateDetails(id)),
		deleteTemplate: (id: number) => dispatch(deleteTemplate(id)),
		updateTemplate: (template: IppReportPackageTemplate) => dispatch(updateTemplateDetails({ ...template })),
		addTemplate: (template: IppReportPackageTemplate, callback: (id: number) => void) =>
			dispatch(addTemplate(template, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppReportPackageTemplateDetailsComponent);
