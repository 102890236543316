import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { ImportSteps, ImportSummary } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { AlertMessageType } from 'src/redux';

interface Props {
	vertical?: boolean;
}

const Step3: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const step1Data: ImportSummary = stepperContext.getData(ImportSteps.STEP1);
	const step2Data: ImportSummary = stepperContext.getData(ImportSteps.STEP2);
	const bigImportBar = 100;

	React.useEffect(() => {
		stepperContext.resolve('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickStartAnother = (event: React.FormEvent) => {
		let step1State = { ...stepperContext.getStep(ImportSteps.STEP1), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP1, step1State);
		let step2State = { ...stepperContext.getStep(ImportSteps.STEP2), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP2, step2State);
		let step3State = { ...stepperContext.getStep(ImportSteps.STEP3), completed: false, data: null };
		stepperContext.updateStep(ImportSteps.STEP3, step3State);

		stepperContext.goAt(ImportSteps.STEP1);
	};

	const importErrorContent = () => {
		return (
			<>
				{localizationService.getLocalizedString(
					'importFile.step3ErrorMessagePart1',
					_.toString(step2Data.errorRowNumber)
				)}
				&nbsp;
				<a href={step2Data.errorFileUrl} target="_blank" rel="noopener noreferrer">
					{localizationService.getLocalizedString('importFile.step3ErrorMessagePart2')}
				</a>
				&nbsp;
				{localizationService.getLocalizedString('importFile.step3ErrorMessagePart3')}
			</>
		);
	};
	const getImportHistoryUrl = (importId: number | undefined, forNew: boolean): string => {
		let url = importHistoryBaseUrl + `/${importId}/`;
		return url + (forNew === true ? 'new' : 'updated');
	};
	const importHistoryBaseUrl = urlService.getSettingMenuPath(`/import/history`);

	return (
		<StepperContent
			id="step3Results"
			className="mx-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn ai-secondary" onClick={onClickStartAnother}>
						{localizationService.getLocalizedString('screen.buttons.startAnotherImport')}
					</StepperAction>
				</React.Fragment>
			}>
			{step1Data.totalRowNumber <= bigImportBar ? (
				<p className="mt-n3">
					{step2Data.errorRowNumber > 0 && (
						<div className="mt-n3">
							<InlineAlertItem message={importErrorContent()} alertType={AlertMessageType.Error} />
						</div>
					)}
					{step2Data.successRowNumber > 0 && (
						<div className="mt-2 d-inline-flex">
							<InlineAlertItem
								message={localizationService.getLocalizedString(
									'importFile.step3SuccessMessage',
									_.toString(step2Data.successRowNumber)
								)}
								alertType={AlertMessageType.Success}
							/>
						</div>
					)}
					{((_.isNumber(step2Data.updatedFogFacilityNumber) && step2Data.updatedFogFacilityNumber > 0) ||
						(_.isNumber(step2Data.newFogFacilityNumber) && step2Data.newFogFacilityNumber > 0)) && (
						<p className="d-flex flex-column mt-2">
							{_.isNumber(step2Data.updatedFogFacilityNumber) &&
								step2Data.updatedFogFacilityNumber > 0 && (
									<Link to={getImportHistoryUrl(step2Data.importId, false)}>
										{localizationService.getLocalizedString(
											'importFile.step3ViewUpdatedFacility',
											_.toString(step2Data.updatedFogFacilityNumber)
										)}
									</Link>
								)}
							{_.isNumber(step2Data.newFogFacilityNumber) && step2Data.newFogFacilityNumber > 0 && (
								<Link to={getImportHistoryUrl(step2Data.importId, true)}>
									{localizationService.getLocalizedString(
										'importFile.step3ViewNewFacility',
										_.toString(step2Data.newFogFacilityNumber)
									)}
								</Link>
							)}
						</p>
					)}
				</p>
			) : (
				<div>
					<p>
						{localizationService.getLocalizedString('importFile.step3ImportSuccessStart')}&nbsp;
						<Link to={importHistoryBaseUrl} rel="noopener noreferrer">
							{localizationService.getLocalizedString('importHistory.importHistory')}
						</Link>
						&nbsp;
						{localizationService.getLocalizedString('importFile.page')}
					</p>
				</div>
			)}
		</StepperContent>
	);
};

export default Step3;
