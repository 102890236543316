import React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { SettingCard } from './setting-card';

import { ReactComponent as SvgAuthority } from '../../../../assets/img/settings-authority.svg';
import { ReactComponent as SvgContacts } from '../../../../assets/img/settings-contacts.svg';
import { ReactComponent as SvgDevices } from '../../../../assets/img/settings-device-types.svg';
import { ReactComponent as SvgMaintenance } from '../../../../assets/img/settings-maintenance.svg';
import { ReactComponent as SvgUser } from '../../../../assets/img/settings-users.svg';
import { ReactComponent as SvgSites } from '../../../../assets/img/settings-sites.svg';
import { ReactComponent as SvgHazards } from '../../../../assets/img/settings-hazards.svg';
import { ReactComponent as SvgAuthorities } from '../../../../assets/img/settings-authorities.svg';
import { ReactComponent as SvgTests } from '../../../../assets/img/settings-tests.svg';
import { ReactComponent as SvgImport } from '../../../../assets/img/settings-import.svg';
import { ReactComponent as SvgFrequency } from '../../../../assets/img/settings-frequencies.svg';
import _ from 'lodash';
import { localizationService, urlService } from 'src/services';
import { NoticesSvg } from 'src/components/svg';
import { LandingPage } from './landing-page';

type Props = {
	history: History;
};

const CCCAuthoritySettingsLandingPageComponent: React.FunctionComponent<Props> = props => {
	const authoritySettings = () => {
		let cards = [
			<SettingCard
				id="divSettingAuthority"
				title={localizationService.getLocalizedString('authoritySetting.authority')}
				key={localizationService.getLocalizedString('authoritySetting.authority')}
				description={localizationService.getLocalizedString('authoritySetting.authorityCard')}
				link={urlService.getSettingMenuPath('/authority')}
				history={props.history}
				hidden={!urlService.isAdministrator()}
				iconSource={<SvgAuthority />}
			/>,
			<SettingCard
				id="divSettingContact"
				key={localizationService.getLocalizedString('authoritySetting.contacts')}
				title={localizationService.getLocalizedString('authoritySetting.contacts')}
				description={localizationService.getLocalizedString('authoritySetting.contactsCard')}
				link={urlService.getSettingMenuPath('/contacts')}
				history={props.history}
				iconSource={<SvgContacts />}
			/>,
			<SettingCard
				id="divSettingDevices"
				key={localizationService.getLocalizedString('cccDevice.devices')}
				title={localizationService.getLocalizedString('cccDevice.devices')}
				description={localizationService.getLocalizedString('cccDevice.settingDescription')}
				link={urlService.getSettingMenuPath('/devices')}
				history={props.history}
				iconSource={<SvgDevices />}
			/>,
			<SettingCard
				id="divSettingImport"
				key="Import"
				title="Import"
				description={localizationService.getLocalizedString('authoritySetting.importCard')}
				link={urlService.getSettingMenuPath('/import')}
				history={props.history}
				iconSource={<SvgImport />}
			/>,
			<SettingCard
				id="divSettingSites"
				key={localizationService.getLocalizedString('authoritySetting.sites')}
				title={localizationService.getLocalizedString('authoritySetting.sites')}
				description={localizationService.getLocalizedString('authoritySetting.sitesDesc')}
				link={urlService.getSettingMenuPath('/sites')}
				history={props.history}
				iconSource={<SvgSites />}
			/>,
			<SettingCard
				id="divSettingTests"
				key={localizationService.getLocalizedString('cccTest.tests')}
				title={localizationService.getLocalizedString('cccTest.tests')}
				description={localizationService.getLocalizedString('cccTest.settingDescription')}
				link={urlService.getSettingMenuPath('/tests')}
				history={props.history}
				iconSource={<SvgTests />}
				hidden={!urlService.isAdministrator()}
			/>,
			<SettingCard
				id="divSettingHazards"
				key={localizationService.getLocalizedString('cccHazard.hazards')}
				title={localizationService.getLocalizedString('cccHazard.hazards')}
				description={localizationService.getLocalizedString('authoritySetting.hazardsDesc')}
				link={urlService.getSettingMenuPath('/hazards')}
				history={props.history}
				iconSource={<SvgHazards />}
			/>,
			<SettingCard
				id="divSettingUsers"
				key="Users"
				title="Users"
				description="Manage user accounts and permissions."
				link={urlService.getSettingMenuPath('/users')}
				history={props.history}
				iconSource={<SvgUser />}
			/>,
			<SettingCard
				id="divSettingNotices"
				key="Notices"
				title="Notices"
				description="Manage notice settings."
				link={urlService.getSettingMenuPath('/NoticeTemplateSchedule')}
				history={props.history}
				iconSource={<NoticesSvg />}
			/>,
			<SettingCard
				id="divSettingFrequencies"
				key={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				title={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				description={localizationService.getLocalizedString('authoritySetting.frequency.manageFrequency')}
				link={urlService.getSettingMenuPath('/Frequencies')}
				history={props.history}
				iconSource={<SvgFrequency />}
			/>
		];
		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};

	const customerSuccessOnlySettings = () => {
		let cards = [
			<SettingCard
				id="divCustomerSuccessUser"
				title={localizationService.getLocalizedString('authoritySetting.customerSuccessUsers')}
				key={localizationService.getLocalizedString('authoritySetting.customerSuccessUsers')}
				description={localizationService.getLocalizedString('authoritySetting.customerSuccessUsersCard')}
				link={urlService.getSettingMenuPath('/customerSuccessUsers')}
				history={props.history}
				iconSource={<SvgUser />}
			/>,
			<SettingCard
				id="divMaintenanceMessage"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.maintenanceMessage')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.maintenanceMessage')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.maintenanceMessageDescription'
				)}
				link={urlService.getSettingMenuPath('/maintenanceMessage')}
				history={props.history}
				iconSource={<SvgMaintenance />}
			/>,
			<SettingCard
				id="divAuthorities"
				key={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				title={localizationService.getLocalizedString('systemSupportOnlySettings.viewAuthoritiesTitle')}
				description={localizationService.getLocalizedString(
					'systemSupportOnlySettings.viewAuthoritiesDescription'
				)}
				link={urlService.getSettingMenuPath('/authorities')}
				history={props.history}
				iconSource={<SvgAuthorities />}
			/>
		];
		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};
	return (
		<LandingPage
			history={props.history}
			authoritySettings={authoritySettings()}
			customerSuccessOnlySettings={customerSuccessOnlySettings()}
		/>
	);
};

export const CCCAuthoritySettingsLandingPage = connect()(CCCAuthoritySettingsLandingPageComponent);
