import _ from 'lodash';
import React from 'react';
import { urlService } from 'src/services';
import { translateService } from 'src/services/translate-service';
import { ClearSvg } from '../svg';
import { Translate } from './translate/translator';
import { LanguageContext } from './translate/translator-context';

interface Props {
	id: string;
	name: string;
	label?: string | JSX.Element;
	isRequired?: boolean;
	isDisabled?: boolean;
	value?: string | number;
	placeholder?: string;
	autoComplete?: string;
	piggyback?: string;
	error?: string;
	helpText?: string;
	warningText?: string;
	onClearButtonPress?(text: string, field?: string): void;
	onBlur?(text: string, field?: string): void;
	onEnterKeyPressed?(text: string, field?: string): void;
	type?: string;
	className?: string;
	showErrorAndHelp?: boolean;
	prefix?: string;
	onFocus?(text: string, field?: string): void;
	onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
	remainingInputProps?: Object;
	inputRef?: React.RefObject<HTMLInputElement>;
	hideLabel?: boolean;
	maxLength?: number;
	additionalRowClass?: string;
	doNotTranslate?: boolean; // it is for do not translate label
	doNotTranslateValue?: boolean;
}

export const TextInput: React.FC<Props> = props => {
	const { language } = React.useContext(LanguageContext);
	const [translatedValue, setTranslatedValue] = React.useState<string | number | undefined>(props.value || undefined);

	React.useEffect(() => {
		if (!props.doNotTranslateValue && language && language.code !== 'en' && props.isDisabled) {
			translateService.translateString(props.value).then(res => {
				setTranslatedValue(res);
			});
		} else if (translatedValue !== props.value) {
			setTranslatedValue(props.value);
		}
	}, [props.doNotTranslateValue, props.value, language]);

	const changeValue = (e: any) => {
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const handleKeyPressed = (e: any) => {
		if (e.key === 'Enter') {
			changeValue(e);
			if (props.onEnterKeyPressed) {
				props.onEnterKeyPressed(e);
			}
		}
		if (e.key === 'Escape') {
			e.target.value = props.value || '';
			e.target.blur();
		}
	};

	const handleOnBlur = (e: any) => {
		changeValue(e);
		if (props.onBlur) {
			props.onBlur(e);
		}
	};

	const handleChange = (e: any) => {
		let { value } = e.target;
		setTranslatedValue(value);

		changeValue(e);
	};

	const handleOnFocus = (e: any) => {
		if (props.onFocus) {
			props.onFocus(e);
		}
	};

	const clearInputText = (e: any) => {
		e.target.name = props.name;
		e.target.value = '';

		if (props.onClearButtonPress) {
			props.onClearButtonPress(e);
		}
	};

	let defaultClass = `form-group ${
		(urlService.isIppAuthorityPortal() || urlService.isProfilePage() || urlService.isIppServiceIndustryPortal()) &&
		!urlService.isPortalDirectorPage()
			? ' col-sm-6 '
			: ''
	} `;
	let rowClass = props.className ? props.className : defaultClass;
	rowClass =
		rowClass +
		(urlService.isIppAuthorityPortal() || urlService.isProfilePage() || urlService.isIppServiceIndustryPortal()
			? ' form-group '
			: ' ') +
		(props.additionalRowClass ? props.additionalRowClass : '');

	return (
		<div className={rowClass + (props.isRequired ? ' required' : '')}>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
				</label>
			) : (
				!props.hideLabel && (
					<label htmlFor={props.id}>
						<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
					</label>
				)
			)}
			<div className="input-group">
				{props.prefix && (
					<div className="input-group-prepend">
						<span className="input-group-text">{props.prefix}</span>
					</div>
				)}
				<input
					id={props.id}
					name={props.name}
					data-piggyback={props.piggyback || props.label}
					type={props.type ? props.type : 'text'}
					className={
						'form-control value ' +
						(props.error ? ' is-invalid' : '') +
						(props.warningText ? ' is-warning clearable-input' : '')
					}
					value={translatedValue || ''}
					placeholder={props.placeholder}
					onChange={handleChange}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					onKeyPress={handleKeyPressed}
					required={props.isRequired === true ? true : false}
					disabled={props.isDisabled === true ? true : false}
					autoComplete={props.autoComplete ? props.autoComplete : 'new-password'}
					ref={props.inputRef}
					maxLength={props.maxLength}
					min={0}
					{...props.remainingInputProps}
				/>
				{!_.isEmpty(props.value) && props.warningText && (
					<button
						aria-label="Reset"
						className="ai-clear-search"
						type="reset"
						onClick={(e: any) => clearInputText(e)}>
						<ClearSvg />
					</button>
				)}
				{!props.showErrorAndHelp && props.error && (
					<div className="invalid-feedback">
						<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
					</div>
				)}
			</div>
			{props.warningText && (
				<div className="ai-form-help">
					{' '}
					<Translate doNotTranslate={props.doNotTranslate}>{props.warningText}</Translate>
				</div>
			)}
			{!props.showErrorAndHelp && !props.error && props.helpText && (
				<div className="ai-form-help">
					{' '}
					<Translate doNotTranslate={props.doNotTranslate}>{props.helpText}</Translate>
				</div>
			)}
			{props.showErrorAndHelp && (props.error || props.helpText) && (
				<span>
					<div className="ai-form-help ai-required">
						<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
					</div>
					<div className="ai-form-help mt-2">
						<Translate doNotTranslate={props.doNotTranslate}>{props.helpText}</Translate>
					</div>
				</span>
			)}
		</div>
	);
};
