import React from 'react';

interface Props {}

export const IncidentMapSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24.857" height="22">
			<g data-name="Group 11787">
				<g data-name="Polygon 28" fill="#ff892e" strokeLinecap="round" strokeLinejoin="round">
					<path d="M24.719 21.5H.138l.428-.748 11.428-20 .435-.76.434.76 11.428 20 .428.748Z" />
					<path
						d="M12.429 1 1 21h22.857L12.43 1m0-1a1 1 0 0 1 .868.504l11.428 20A1 1 0 0 1 23.857 22H1a1 1 0 0 1-.868-1.496l11.428-20A1 1 0 0 1 12.43 0Z"
						fill="#241c15"
					/>
				</g>
				<path
					data-name="Path 3986"
					d="m13.707 10.055-.662 5.621h-1.217l-.678-5.62V7.001h2.557Zm-.065 6.585v2.361h-2.435V16.64Z"
					fill="#241c15"
				/>
			</g>
		</svg>
	);
};
