import React from 'react';

import { AttachmentOwnership, AttachmentType } from '@rcp/types';
import { localizationService } from 'src/services';
import './attachment.scss';
import { AttachmentThumbnail } from '.';

interface CompProps {
	attachmentType?: AttachmentType;
	ownerId?: number;
	attachments: AttachmentOwnership[];
}

type Props = CompProps;

export const ReportAttachments: React.SFC<Props> = props => {
	return (
		<section className="attachments">
			<div className="d-flex flex-row">
				<strong>{localizationService.getLocalizedString('attachments.title')}</strong>
			</div>
			<div className="attachment-list mx-n1">
				{props.attachments.map((attachment: AttachmentOwnership, index: number) => {
					let linkTo = `${window.location.href}/attachments/${attachment.attachmentId}`;

					return (
						<AttachmentThumbnail
							attachment={attachment}
							facilityWidget={props.attachmentType == null}
							reportView={true}
							key={`icon-${index}`}
							className="attachment-thumbnail m-1"
							linkTo={linkTo}
						/>
					);
				})}
			</div>
		</section>
	);
};
