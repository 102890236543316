import React, { useEffect, useState, FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import $ from 'jquery';
import {
	DateInput,
	PopoverModal,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput,
	DeleteModal,
	AttachmentThumbnail
} from 'src/components/widgets';
import _ from 'lodash';
import {
	apiService,
	DateUtilService,
	documentService,
	localizationService,
	Logger,
	Resource,
	tokenService,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import {
	alertService,
	deleteFacilityAttachment,
	loadCurrentFogFacility,
	loadFacilityAttachments,
	loadFacilityCleaningManifest,
	loadUnitList,
	useReduxSelector,
	useRootStateSelector
} from 'src/redux';
import {
	AttachmentOwnership,
	AttachmentType,
	CleaningEvent,
	CustomCleaningFields,
	CustomFieldType,
	DropDownOption,
	Extractor,
	FacilityCleaningManifest,
	FacilityPortalAuthorityDetail,
	Hauler,
	CustomFormAttachment,
	PaginatedResult
} from '@rcp/types';
import { useHistory } from 'react-router';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import { openSignaturePad } from 'src/components/layout/signature-modal';
import 'src/components/widgets/attachment/attachment.scss';
import { useDispatch } from 'react-redux';
import { facilityDevicesSlice } from '../cleanings/facility-devices-slice';
import {
	getTimelineSettingDateRange,
	reloadTimelineEventsFromServer
} from '../../../../features/timeline/timeline-service';
import './submit-facility-cleaning.scss';
import { cleaningSlice } from '../../../';
import { ConverterFactory } from 'src/services/converter-factory';
import { CustomFormService } from 'src/services/custom-form-service';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { translateService } from 'src/services/translate-service';
import { Translate } from 'src/components/widgets/translate/translator';
import { OriginalSubmissionModal } from './original-submission-modal';

interface Props {
	title: string;
	showModal: boolean;
	onCancel: () => void;
	onSubmit?: () => void;
	readOnly?: boolean;
	saveButtonClassName?: string;
	saveButtonText?: string;
	hideCancel?: boolean;
	facilityId?: number;
	facility?: FacilityCleaningManifest;
	deviceDetails?: FacilityCleaningManifest;
	cleaningEventId?: number;
	hideFooter?: boolean;
	submittedDate?: string;
	showDeleteButton?: boolean;
	isFacilityDetailsPage?: boolean;
	isEditMode?: boolean;
	customFormId?: number;
}

interface FormErrorFields {
	completeDateError?: string;
	amountPumpedError?: string;
	certificationAcceptedError?: string;
	signatureError?: string;
	trapDepthError?: string;
	fogError?: string;
	solidError?: string;
	isRepairNeededReasonError?: string;
	leavingOutletError?: string;
	disposalLocationError?: string;
}

interface FormFields extends FormErrorFields, FacilityCleaningManifest {
	attachments?: CustomFormAttachment[];
	cleanedBy?: string;

	unitsLoaded?: boolean;
	manifestLoaded?: boolean;
	attachmentsLoaded?: boolean;
	haulerCompanyLoaded?: boolean;

	submissionCustomFormLoaded?: boolean;
	submissionCustomFormInitialized?: boolean;

	submittedCustomFormLoaded?: boolean;

	readyToSanitize?: boolean;
}

const initialFormFields: FormFields = {
	completeDate: DateUtilService.getAuthorityTimezoneNow(),
	amountPumped: '',
	comments: '',
	signature: '',
	fog: undefined,
	solid: undefined,
	isRepairNeeded: false,
	isRepairNeededReason: '',
	percentGrease: undefined
};

const FacilitySubmitCleaningModal: FC<Props> = props => {
	const [deviceDetails, setDeviceDetails] = useState<Extractor>({});
	const [files, setFiles] = useState<CustomFormAttachment[]>([]);
	const [formState, setFormState] = useState<FormFields>({ ...initialFormFields });
	const [haulerCompanyOptions, setHaulerCompanyOptions] = useState<DropDownOption[]>([]);
	const [showDeleteCleaningModal, setShowDeleteCleaningModal] = React.useState(false);
	const [facilityCleaningFormElements, setFacilityCleaningFormElements] = useState<CustomFieldType[]>([]);
	const unitList = useRootStateSelector(s => s.extractors.unitList);
	const [formType, setFormType] = useState('');
	const [templateJson, setTemplateJson] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();
	const [removedAttachments, setRemovedAttachments] = useState<CustomFormAttachment[]>([]);
	const [showViewOriginalSubmission, setShowViewOriginalSubmission] = React.useState(false);

	const language = React.useContext(LanguageContext);
	const showCustomCleaningFormsFeature = true;

	const getAuthorityDetails = async () => {
		let authorityDetailUrl = urlService.getAuthorityResourcesApiUrl(Resource.AuthorityDetails);
		return (await apiService.httpGet(authorityDetailUrl)) as FacilityPortalAuthorityDetail;
	};

	const loadInitialContent = async () => {
		if (props.showModal && (props.readOnly || props.isEditMode)) {
			fetchCleaningManifest();
			const url = urlService.getAuthorityResourcesApiUrl(
				`${Resource.FacilityCleaningEvents}/${props.cleaningEventId}/${Resource.Attachments}`
			);
			if (urlService.isFacilityPortal()) {
				CustomFormService.fetchAttachments(url, setFiles);
			}
		}
		!props.readOnly && fetchHaulerCompanies();
	};

	useEffect(() => {
		dispatch(loadUnitList());
		setFormState({ ...formState, unitsLoaded: true });
	}, [dispatch]);

	useEffect(() => {
		if (formState.unitsLoaded && deviceDetails.extractorId) {
			CustomFormService.loadCustomForm(
				deviceDetails.extractorId,
				setTemplateJson,
				setFormType,
				setFacilityCleaningFormElements,
				() => {
					setFormState({ ...formState, submissionCustomFormLoaded: true });
				}
			);
		}
	}, [formState.unitsLoaded, deviceDetails]);

	useEffect(() => {
		if (formState.unitsLoaded && props.cleaningEventId) {
			loadInitialContent();
		}
	}, [props.cleaningEventId, formState.unitsLoaded]);

	useEffect(() => {
		if (formState.unitsLoaded && formState.submissionCustomFormLoaded) {
			CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState);
		}
	}, [formState.unitsLoaded, formState.submissionCustomFormLoaded, unitList]);

	useEffect(() => {
		if (props.deviceDetails) {
			setDeviceDetails(props.deviceDetails as Extractor);
		}
	}, [props.deviceDetails]);

	useEffect(() => {
		if (formState.manifestLoaded && !props.deviceDetails) {
			CustomFormService.updateForm(
				setTemplateJson,
				setFormType,
				formState,
				files,
				formState.attachments,
				props.isEditMode,
				setFacilityCleaningFormElements,
				formState.templateJson
			);
			setFormState({ ...formState, submittedCustomFormLoaded: true });
		}
	}, [formState.manifestLoaded, formState.templateJson]);

	const fetchHaulerCompanies = () => {
		const url = urlService.getAuthorityResourcesApiUrl(Resource.Haulers);
		apiService
			.getPaginatedResources<Hauler>(url)
			.then((haulers: PaginatedResult<Hauler>) => {
				let options: DropDownOption[] = (haulers.result || haulers).map((hauler: Hauler) => {
					return {
						label: hauler.name || '',
						value: hauler.haulerId
					};
				});
				setHaulerCompanyOptions(options);
				setFormState({ ...formState, haulerCompanyLoaded: true });
			})
			.catch(error => alertService.addError(error.message));
	};

	useEffect(() => {
		if (formState.submittedCustomFormLoaded) {
			let parentDivEl = templateJson
				? document.getElementById('cleaningSubmissionForm')
				: document.getElementById('cleaningManifestHtmlContentDiv');
			if (parentDivEl) {
				_.delay(() => {
					setFormState({ ...formState, readyToSanitize: true });
				}, 1000);
			}
		}
	}, [formState.submittedCustomFormLoaded]);

	useEffect(() => {
		if (props.readOnly && formState.readyToSanitize && formState.cleaningManifestHtmlContent) {
			let parentDivEl = templateJson
				? document.getElementById('cleaningSubmissionForm')
				: document.getElementById('cleaningManifestHtmlContentDiv');
			if (!parentDivEl) {
				Logger.warn('can not find cleaningManifestHtmlContentDiv');
				return;
			}

			let formHtml = templateJson ? parentDivEl.innerHTML : formState.cleaningManifestHtmlContent;
			const sanitizedFormHtml = documentService.sanitizeHtmlFormForDisplayOnly(formHtml, 'attachmentsInput');

			if (parentDivEl) {
				parentDivEl.innerHTML = sanitizedFormHtml;
			}

			_.delay(() => {
				//hook attachments
				let attachmentsElem = document.getElementById('viewModeAttachments');
				let snapshotFormAttachmentElem = document.getElementById('attachmentsInput');

				if (snapshotFormAttachmentElem && snapshotFormAttachmentElem.parentElement && attachmentsElem) {
					let className = _.replace(attachmentsElem.className, 'attachment-hide', 'attachment-show');
					attachmentsElem.setAttribute('class', className);
					snapshotFormAttachmentElem.parentElement.replaceChild(attachmentsElem, snapshotFormAttachmentElem);
				}
			}, 200);
		}
	}, [props.readOnly, formState.cleaningManifestHtmlContent, templateJson, formState.readyToSanitize]);

	const deleteCleaningEvent = () => {
		if (formState.cleaningManifestId) {
			const url = urlService.getAuthorityResourceApiUrl(
				Resource.HaulerCleaningHistory,
				formState.cleaningManifestId || 0
			);
			apiService
				.deleteResource(url)
				.then(data => {
					if (!props.isFacilityDetailsPage) {
						let isFacilityCleaningSubmitView = history.location.pathname.includes(Resource.Cleanings);
						if (isFacilityCleaningSubmitView) {
							dispatch(cleaningSlice.reload());
						} else {
							reloadTimelineEventsFromServer();
						}
					} else {
						dispatch(loadCurrentFogFacility());
						let dateRange = getTimelineSettingDateRange();
						dispatch(loadFacilityCleaningManifest(dateRange.startDateStr, dateRange.endDateStr));
						dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
					}
					alertService.addSuccess(
						localizationService.getLocalizedString('haulerPortal.submitCleaning.deleteCleaningManifest')
					);
					props.onCancel();
				})
				.catch(err => {
					alertService.addError(`${err.message}`);
				});
		}
		setShowDeleteCleaningModal(false);
	};

	const fetchCleaningManifest = () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${
				urlService.isFacilityPortal()
					? Resource.CustomCleaningManifests
					: Resource.FogFacilities + '/' + props.facilityId + `/${Resource.Cleanings}`
			}/${props.customFormId ? props.customFormId : props.cleaningEventId}`
		);
		apiService
			.getResource(url)
			.then((data: any) => {
				let cleaningManifest = { ...(data as any) };
				let keyValuePair: any = {};
				if (cleaningManifest.cleaningManifestFormKeyValuePair) {
					keyValuePair = JSON.parse(cleaningManifest.cleaningManifestFormKeyValuePair);
				}
				keyValuePair.isRepairNeeded === 'on'
					? (keyValuePair.isRepairNeeded = true)
					: (keyValuePair.isRepairNeeded = false);
				delete keyValuePair.completeDate;
				delete keyValuePair.dueDate;
				let newFormState: FormFields = {
					...formState,
					...cleaningManifest,
					...keyValuePair,
					manifestLoaded: true
				};
				if (!urlService.isHaulerPortal() && !_.isEmpty(cleaningManifest.attachments)) {
					setFiles([...cleaningManifest.attachments]);
					newFormState.attachmentsLoaded = true;
				}
				setFormState(newFormState);
			})
			.catch(function(err) {
				err.status && alertService.addError(err.message);
			});
	};

	const onCancel = () => {
		resetState();
		alertService.clearAllMessages();
		props.onCancel();
	};

	const resetState = () => {
		setFormState({ ...initialFormFields });
		setFiles([]);
	};

	const onChange = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value || '');
		setFormState(newState);

		const percentGreaseFields = ['fog', 'solid', 'trapDepth'];
		if (percentGreaseFields.includes(name)) {
			onPercentGreaseOperandChange(newState);
		}
	};

	const setSignature = (imageUrl: string) => {
		let newState = { ...formState };
		_.set(newState, 'signature', imageUrl || '');
		setFormState(newState);
	};

	const setTrapDepthAndCapacity = (deviceDetail: Extractor) => {
		let newState = { ...formState };
		_.set(newState, 'trapDepth', deviceDetail.trapDepth || '');
		setFormState(newState);
	};

	const onPercentGreaseOperandChange = (newState: FormFields) => {
		let trapDepth = newState.trapDepth;
		let fog = newState.fog;
		let solid = newState.solid;

		if (trapDepth && fog && solid && trapDepth > 0) {
			let waste = +fog + +solid;
			let percentGrease = Math.round((+waste / +trapDepth) * 100);
			_.set(newState, 'percentGrease', percentGrease);
		} else {
			_.unset(newState, 'percentGrease');
		}
	};

	const uploadFiles = async (ownerId: number) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		let formData = new FormData();
		for (let x = 0; x < files.length; x++) {
			formData.append('files', files[x]);
		}
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.HaulerDeviceCleaningAttachment}?ownerId=${ownerId}&attachmentType=${AttachmentType.FogDeviceCleaning}`
		);
		await apiService.postFormData(url, formData, headers);
	};

	const addValuesToTheHtmlContent = () => {
		$('input').each(function() {
			if ($(this).is(':checkbox')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else if ($(this).is(':radio')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else {
				$(this).attr('value', ($(this) as any).val());
			}
		});

		$('textarea').each(function() {
			$(this).html(($(this) as any).val());
		});

		$('select').each(function() {
			$(this)
				.find(':not(:selected)')
				.removeAttr('selected');
		});

		$('select').each(function() {
			$(this)
				.find(':selected')
				.attr('selected', 'selected');
		});
	};

	const getAllFormValuePair = () => {
		let paramObj: any = {};
		let formId = `#cleaningSubmissionForm`;
		$.each($(formId).serializeArray(), function(_, kv) {
			paramObj[kv.name] = kv.value;
		});
		return paramObj;
	};

	const getAllFormValues = () => {
		let paramObj: any = getAllFormValuePair();

		return UtilService.toJson(paramObj);
	};

	const getAllFormValuesInEnglish = async () => {
		let paramObj: any = getAllFormValuePair();
		let keys = Object.keys(paramObj);

		for await (const key of keys) {
			await translateService.translateHtml(paramObj[key], 'en', language.language.code).then(value => {
				return (paramObj[key] = value);
			});
		}

		return UtilService.toJson(paramObj);
	};

	const createCustomPayloadFromTemplateJson = () => {
		let payload: any = {};
		_.each(facilityCleaningFormElements, facilityFormElement => {
			const { inputs } = facilityFormElement;
			if (facilityFormElement.name === CustomCleaningFields.Signature) {
				_.each(inputs, input => {
					if (input && input.fieldId) {
						payload[input.fieldId] = input.value;
					}
				});
			}
		});
		return payload;
	};

	const onSubmit = async () => {
		let isFormValid = !templateJson ? isFormValidateForSubmit() : handleValidateCustomField();
		if (isFormValid) {
			_.delay(async () => {
				addValuesToTheHtmlContent();
				let allFromValues = getAllFormValues();
				let formId = `cleaningSubmissionForm`;
				let node = document.getElementById(formId);
				let htmlContent = node ? node.innerHTML : '';
				let htmlContentInEnglish = await translateService.translateHtml(
					htmlContent,
					'en',
					language.language.code
				);
				let allFromValuesInEnglish = await getAllFormValuesInEnglish();

				let token = tokenService.getTokenOrDefault();

				let payload: any = {
					...formState,
					deviceId: deviceDetails.extractorId,
					organizationId: token.portalOrganizationId,
					cleaningManifestHtmlContent: htmlContent,
					cleaningManifestFormKeyValuePair: allFromValues
				};
				if (templateJson) {
					payload = {
						...createCustomPayloadFromTemplateJson(),
						deviceId: deviceDetails.extractorId,
						organizationId: token.portalOrganizationId,
						OriginalSubmittedFormHtmlContent: htmlContent,
						OriginalSubmittedKeyValuePair: allFromValues,
						cleaningManifestHtmlContent: htmlContentInEnglish,
						cleaningManifestFormKeyValuePair: allFromValuesInEnglish,
						SubmittedLanguageCode: language.language.code,
						templateJson: templateJson,
						percentGrease:
							(document.getElementById('percentGrease') &&
								document.getElementById('percentGrease')!.innerText.replace('%', '')) ||
							'0',
						...CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState)
					};
				} else {
					payload = _.pick(payload, [
						'deviceId',
						'organizationId',
						'completeDate',
						'haulerId',
						'amountPumped',
						'amountPumpedUnitId',
						'disposalLocation',
						'comments',
						'certificationAccepted',
						'signature',
						'cleaningManifestHtmlContent',
						'cleaningManifestFormKeyValuePair'
					]);
				}
				let url = urlService.getAuthorityResourcesApiUrl(Resource.FacilityCleaningEvents);
				if (props.isEditMode) {
					url = urlService.getAuthorityResourcesApiUrl(
						`${Resource.FogFacilities}/${props.facilityId}/${Resource.Cleanings}/${props.cleaningEventId}`
					);
					apiService.patchResource<CleaningEvent>(url, payload).then(data => {
						if (!showCustomCleaningFormsFeature && data.pumpOutEventId) {
							if (files.length) {
								uploadFiles(data.pumpOutEventId).catch(err => alertService.addError(err.message));
							}
						} else {
							const attachmentPromisesList: any = [];
							_.each(facilityCleaningFormElements, facilityCleaningElement => {
								const { inputs } = facilityCleaningElement;
								if (facilityCleaningElement.name !== CustomCleaningFields.Attachments) {
									return;
								}
								_.each(inputs, input => {
									input.value &&
										data.pumpOutEventId &&
										attachmentPromisesList.push(
											uploadFile(
												input.value[0] as CustomFormAttachment,
												data.pumpOutEventId,
												input.fieldId || ''
											)
										);
								});
								_.each(removedAttachments, (removedAttachment: CustomFormAttachment) => {
									attachmentPromisesList.push(
										dispatch(
											deleteFacilityAttachment(
												removedAttachment.organizationId || 0,
												removedAttachment.attachmentOwnershipId || 0,
												''
											)
										)
									);
								});
							});
							if (attachmentPromisesList.length) {
								Promise.all(attachmentPromisesList)
									.then(() => onSubmitCallback())
									.catch(err => alertService.addError(err.message));
							} else {
								onSubmitCallback();
							}
						}

						if (data.nextPumpOutEventDate) {
							alertService.addInfo(
								localizationService.getLocalizedString(
									'pumpOut.nextCleaningScheduled',
									DateUtilService.toDisplayDate(data.nextPumpOutEventDate) || ''
								)
							);
						}
					});
				} else {
					apiService
						.postResource(url, payload)
						.then(data => {
							if (files.length) {
								uploadFiles((data as any).pumpOutEventId).catch(err =>
									alertService.addError(err.message)
								);
							}
							_.each(facilityCleaningFormElements, facilityCleaningElement => {
								const { inputs } = facilityCleaningElement;
								if (facilityCleaningElement.name !== CustomCleaningFields.Attachments) {
									return;
								}
								_.each(inputs, input => {
									input.value &&
										uploadFile(
											input.value[0] as CustomFormAttachment,
											(data as any).pumpOutEventId,
											input.fieldId || ''
										).catch(err => alertService.addError(err.message));
								});
							});

							if (data.nextPumpOutEventDate) {
								alertService.addInfo(
									localizationService.getLocalizedString(
										'pumpOut.nextCleaningScheduled',
										DateUtilService.toDisplayDate(data.nextPumpOutEventDate) || ''
									)
								);
							}
						})
						.then(() => {
							onSubmitCallback();
						})
						.catch(err => alertService.addError(err.message));
				}
			}, 400);
		}
	};

	const uploadFile = async (file: CustomFormAttachment, ownerId: number, attachmentSectionKey: string) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		if (!file) return;
		let formData = new FormData();
		formData.append('files', file);
		if (file.fileName) {
			return;
		}

		const queryParameter = `facilityId=${props.facilityId}&ownerId=${ownerId}&attachmentType=${AttachmentType.FogDeviceCleaning}&attachmentSectionKey=${attachmentSectionKey}`;
		let url = props.isEditMode
			? urlService.getAuthorityResourcesApiUrl(
					`${Resource.FogFacilities}/${props.facilityId}/${Resource.HaulerDeviceCleaningAttachment}?${queryParameter}`
			  )
			: urlService.getAuthorityResourcesApiUrl(`${Resource.HaulerDeviceCleaningAttachment}?${queryParameter}`);
		await apiService.postFormData(url, formData, headers);
	};

	const onSubmitCallback = () => {
		alertService.addSuccess(
			props.isEditMode
				? localizationService.getLocalizedString('alertMessages.savedSuccess', 'pumpOut.cleaning')
				: localizationService.getLocalizedString('haulerPortal.submitCleaning.submitSuccess')
		);
		if (props.isEditMode) {
			if (!props.isFacilityDetailsPage) {
				let isFacilityCleaningSubmitView = history.location.pathname.includes(Resource.Cleanings);
				if (isFacilityCleaningSubmitView) {
					dispatch(cleaningSlice.reload());
				} else {
					reloadTimelineEventsFromServer();
				}
			} else {
				dispatch(loadCurrentFogFacility());
				let dateRange = getTimelineSettingDateRange();
				dispatch(loadFacilityCleaningManifest(dateRange.startDateStr, dateRange.endDateStr));
				dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
			}
		}
		props.onCancel();
		!props.isEditMode && dispatch(facilityDevicesSlice.reload());
		resetState();
	};

	const isFormValidateForSubmit = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;

		validationService.validateRequiredDateField(
			newState,
			'completeDate',
			'completeDateError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.cleaningDate')
		);
		validationService.validateNumberField(
			newState,
			'amountPumped',
			'amountPumpedError',
			true,
			localizationService.getLocalizedString('haulerPortal.submitCleaning.wasteRemoved')
		);
		validationService.validateRequiredField(
			newState,
			'certificationAccepted',
			'certificationAcceptedError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.certification')
		);
		validationService.validateRequiredField(
			newState,
			'signature',
			'signatureError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.signature')
		);
		isFormValid = !validationService.hasError(
			newState,
			'completeDateError',
			'amountPumpedError',
			'certificationAcceptedError',
			'signatureError'
		);

		setFormState(newState);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const modalFooter = () => {
		return props.hideFooter ? (
			<></>
		) : (
			<>
				{(props.readOnly || props.isEditMode) && props.showDeleteButton && (
					<button
						type="button"
						className={`btn ${props.isEditMode ? 'ai-secondary-delete' : 'ai-delete'}`}
						onClick={() => {
							setShowDeleteCleaningModal(true);
						}}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.delete')}</Translate>
					</button>
				)}
				{!props.readOnly && (
					<button
						className={`btn ${
							props.saveButtonClassName
								? props.saveButtonClassName
								: `btn ai-${props.isEditMode ? 'save' : 'action'}`
						}`}
						onClick={props.readOnly ? props.onSubmit : onSubmit}>
						<Translate>
							{props.saveButtonText
								? props.saveButtonText
								: localizationService.getLocalizedString(
										`haulerPortal.submitCleaning.buttons.${props.isEditMode ? 'save' : 'submit'}`
								  )}
						</Translate>
					</button>
				)}
				{!props.hideCancel && (
					<button
						className={`btn ai-white ${props.readOnly ? 'ml-auto' : ''}`}
						onClick={() => {
							props.onCancel();
						}}>
						<Translate>{localizationService.getLocalizedString('haulerPortal.buttons.cancel')}</Translate>
					</button>
				)}
			</>
		);
	};

	const modalProps = {
		title: props.title,
		showModal: props.showModal,
		cancel: onCancel,
		className: 'submit-cleaning-modal',
		footer: modalFooter()
	};

	const handleDrop = (chosenFiles: any) => {
		CustomFormService.handleDrop(chosenFiles, files, setFiles);
	};

	const removeFile = (index: number, size: number = 10) => {
		return (
			<span
				id="remove-file"
				className="cursor-pointer p-1"
				onClick={event => {
					event.stopPropagation();
					let selectedFiles = _.cloneDeep(files);
					selectedFiles.splice(index, 1);
					setFiles(selectedFiles);
				}}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};

	const onFileChangeHandler = (event: any) => {
		CustomFormService.onFileChangeHandler(event, files, setFiles);
	};

	const getFacilityAddress = () => {
		let { facilityAddressLine1, facilityCityName, facilityJurisdictionCode, facilityAddress, facilityZipCode } =
			props.facility || formState;
		const address1 = facilityAddressLine1 || facilityAddress || '';
		const city = facilityCityName || '';
		const jurisdiction = facilityJurisdictionCode || '';
		const zip = facilityZipCode || '';
		return (
			<>
				{address1 && (
					<>
						<Translate doNotTranslate={true}>{address1}</Translate>
					</>
				)}
				<br />
				{city && (
					<>
						<Translate doNotTranslate={true}>{city + (jurisdiction ? ', ' : '')}</Translate>
					</>
				)}
				{jurisdiction && (
					<>
						<Translate doNotTranslate={true}>{jurisdiction + ' '}</Translate>
					</>
				)}
				{zip && (
					<>
						<Translate doNotTranslate={true}>{zip}</Translate>
					</>
				)}
			</>
		);
	};

	const viewAttachments = () => {
		if (templateJson) {
			return ConverterFactory.viewAttachments(facilityCleaningFormElements, files, 'viewModeAttachments');
		} else {
			return (
				<div id="viewModeAttachments" className={'mb-3 attachment-hide'}>
					<div className="mb-1">
						{localizationService.getLocalizedString('haulerPortal.submitCleaning.attachments')}
					</div>

					<div className="attachments cleaning-manifest-attachments">
						<div className="attachment-list mx-n1">
							{files &&
								files.map((attachment: AttachmentOwnership, index: number) => {
									return (
										<AttachmentThumbnail
											attachment={attachment}
											key={`icon-${index}`}
											insideModal
											className="attachment-thumbnail m-1"
										/>
									);
								})}
						</div>
					</div>
					{!files.length && (
						<label>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.noAttachments')}
						</label>
					)}
				</div>
			);
		}
	};

	const getGenericCleaningFormHeader = () => (
		<div className="d-flex justify-content-between fieldset cleaning-device-fields">
			{deviceDetails.attachmentOwnershipDto && (
				<img
					src={
						deviceDetails.attachmentOwnershipDto.s3ThumbnailUrl ||
						deviceDetails.attachmentOwnershipDto.s3ResourceUrl
					}
					alt={deviceDetails.attachmentOwnershipDto.fileName}
					width={210}
					height={165}
					className="image-submit-cleaning"
				/>
			)}
			<div className={` ${deviceDetails.attachmentOwnershipDto ? 'cleaning-detail' : ''}`}>
				<p>
					<strong>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.description')}
						</Translate>
					</strong>
					<br />
					<Translate>{deviceDetails.extractorDescription || formState.deviceDescription || ''}</Translate>
				</p>
				<p>
					<strong>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.type')}
						</Translate>
					</strong>
					<br />
					<Translate>
						{deviceDetails.extractorType || formState.extractorType || formState.deviceType || ''}
					</Translate>
				</p>
				<p>
					<strong>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.deviceNumber')}
						</Translate>
					</strong>
					<br />
					<Translate doNotTranslate={true}>
						{deviceDetails.deviceNumber || formState.deviceNumber || ''}
					</Translate>
				</p>
				<p>
					<strong>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.facility')}
						</Translate>
					</strong>
					<br />
					<Translate doNotTranslate={true}>
						{(props.facility && props.facility.facilityName) || formState.facilityName || 'NA'}
					</Translate>
					<br />
					{getFacilityAddress()}
				</p>
			</div>
		</div>
	);

	const getLegacyHardCodedCleaningForm = () => {
		return (
			<>
				{getGenericCleaningFormHeader()}
				{props.readOnly ? (
					<TextInput
						id="cleaning-date"
						className="form-group input-single-line"
						name="completeDate"
						value={DateUtilService.toDisplayDate(formState.completeDate)}
						label={localizationService.getLocalizedString('facilityPortal.submitCleaning.cleaningDate')}
						isRequired={!props.readOnly}
						isDisabled={props.readOnly}
						error={formState.completeDateError}
					/>
				) : (
					<DateInput
						id="complete-date"
						name="completeDate"
						label={localizationService.getLocalizedString('facilityPortal.submitCleaning.cleaningDate')}
						isRequired={!props.readOnly}
						value={formState.completeDate}
						error={formState.completeDateError}
						max={DateUtilService.getAuthorityTimezoneNow()}
						onChange={onChange}
					/>
				)}
				{!props.readOnly ? (
					<SingleSelectDropdown
						id="hauler-company"
						name="haulerId"
						label={localizationService.getLocalizedString('facilityPortal.submitCleaning.haulerCompany')}
						value={formState.haulerId}
						onChange={onChange}
						selfOrder
						options={haulerCompanyOptions || []}
					/>
				) : (
					<TextInput
						id="hauler-company"
						isDisabled={props.readOnly}
						className="form-group input-single-line"
						name="haulerName"
						label={localizationService.getLocalizedString('facilityPortal.submitCleaning.haulerCompany')}
						value={formState.haulerName}
						onChange={onChange}
					/>
				)}

				<TextInput
					id="waste-removed"
					name="amountPumped"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('facilityPortal.submitCleaning.wasteRemoved')}
					isRequired={!props.readOnly}
					value={formState.amountPumped ? formState.amountPumped + '' : undefined}
					isDisabled={props.readOnly}
					onChange={onChange}
					error={formState.amountPumpedError}
				/>

				<TextInput
					id="disposalLocation"
					name="disposalLocation"
					className="form-group input-single-line"
					onChange={onChange}
					isDisabled={props.readOnly}
					label={localizationService.getLocalizedString('facilityPortal.submitCleaning.disposalLocation')}
					value={formState.disposalLocation}
				/>

				{props.readOnly ? (
					viewAttachments()
				) : (
					<div id="attachmentsInput" className="mb-2">
						<label>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.attachments')}
						</label>
						<DragAndDrop
							handleDrop={handleDrop}
							className="drag-submit"
							dragMessage={localizationService.getLocalizedString(
								'haulerPortal.submitCleaning.dragAndDrop'
							)}>
							<div className="file-input-group p-1">
								<label
									className="btn ai-secondary mb-1"
									htmlFor="btnFileImport"
									aria-describedby="file-input-help">
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.chooseFiles')}
								</label>
								<input
									type="file"
									id="btnFileImport"
									className="file-input mr-4 "
									onChange={onFileChangeHandler}
									onClick={(e: any) => (e.target.value = null)}
									style={{ width: '0px' }}
									multiple
								/>
								{files.length ? (
									files.map((fileData: File, index: number) => {
										return (
											<span key={`file-${index}`}>
												<label className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
													<div className="d-inline-flex" title={fileData && fileData.name}>
														<span id="file-badge-name">{fileData && fileData.name}</span>
													</div>
													{removeFile(index)}
												</label>
											</span>
										);
									})
								) : (
									<label className="file-name ml-4 pl-4">
										{localizationService.getLocalizedString(
											'haulerPortal.submitCleaning.dragAndDrop'
										)}
									</label>
								)}
							</div>
						</DragAndDrop>
					</div>
				)}
				{props.readOnly ? (
					<div className="mb-3">
						<div className="mb-1">
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.comments')}
						</div>
						{formState.comments ? (
							<div>{formState.comments}</div>
						) : (
							<div className="file-name">
								{localizationService.getLocalizedString('haulerPortal.submitCleaning.noComments')}
							</div>
						)}
					</div>
				) : (
					<TextAreaInput
						id="comments"
						name="comments"
						isDisabled={props.readOnly}
						value={formState.comments}
						label={localizationService.getLocalizedString('haulerPortal.submitCleaning.comments')}
						isFullWidth
						onChange={onChange}
					/>
				)}
				<SingleCheckbox
					id="certificationAccepted"
					name="certificationAccepted"
					className={`form-group required`}
					label={localizationService.getLocalizedString(
						'haulerPortal.submitCleaning.certificationDescription'
					)}
					fieldLabel={
						<label className="mb-1">
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.certification')}
						</label>
					}
					isDisabled={props.readOnly}
					checked={formState.certificationAccepted}
					onChange={onChange}
					error={formState.certificationAcceptedError}
				/>
				<div className={`form-group ${props.readOnly ? '' : 'required'}`}>
					<label htmlFor="currentUserSignature">
						{localizationService.getLocalizedString('haulerPortal.submitCleaning.signature')}
					</label>
					<div
						id="currentUserSignature"
						className={`user-signature ${formState.signatureError ? ' is-invalid' : ''}`}>
						<img
							src={formState.signature || ''}
							alt=""
							onClick={(event: any) => {
								!props.readOnly && openSignaturePad(event.target);
							}}
							onError={(event: any) => {
								event.target.style.opacity = 0;
								setSignature('');
							}}
							onLoad={(event: any) => {
								event.target.style.opacity = 1;
								setSignature(event.target.src);
							}}
						/>
					</div>
					{formState.signatureError && (
						<div className="ai-form-help ai-required">{formState.signatureError}</div>
					)}
				</div>
			</>
		);
	};

	const getDeleteMessage = (): string => {
		let violationsCount = formState.violationsCount;
		if (violationsCount === 0) return localizationService.getLocalizedString('pumpOut.deleteModalMessage');
		if (violationsCount === 1)
			return localizationService.getLocalizedString(
				'pumpOut.deleteModalMessageViolations',
				violationsCount.toString(),
				''
			);
		return localizationService.getLocalizedString(
			'pumpOut.deleteModalMessageViolations',
			(violationsCount || 0).toString(),
			's'
		);
	};

	const handleValidateCustomField = () => {
		let newFacilityCleaningFormElement = _.cloneDeep(facilityCleaningFormElements);
		_.each(newFacilityCleaningFormElement, facilityCleaningFormElement => {
			validationService.validateCustomFormField(facilityCleaningFormElement.inputs || []);
		});
		let hasError = false;
		_.each(newFacilityCleaningFormElement, facilityCleaningFormElement => {
			_.each(facilityCleaningFormElement.inputs, inputs => {
				if (validationService.hasError(inputs, 'error')) {
					hasError = true;
				}
			});
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		setFacilityCleaningFormElements(newFacilityCleaningFormElement);
		return !hasError;
	};

	const handleChangeInputValue = (e: any, dataItem: CustomFieldType, fieldValue?: any) => {
		CustomFormService.handleChangeInputValue(
			e,
			dataItem,
			facilityCleaningFormElements,
			setFacilityCleaningFormElements,
			removedAttachments,
			setRemovedAttachments,
			fieldValue
		);
	};

	const toggleViewOriginalSubmission = (e?: any) => {
		if (e) e.preventDefault();
		setShowViewOriginalSubmission(!showViewOriginalSubmission);
	};

	return (
		<>
			{props.showModal && (
				<PopoverModal {...modalProps}>
					<div />
					{props.readOnly && (
						<p>
							<Translate>
								{localizationService.getLocalizedString('haulerPortal.submitCleaning.submitted')}
							</Translate>
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString('haulerPortal.submitCleaning.by')}
							</Translate>
							&nbsp;
							{formState.submitterUserFullName}
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString('facilityPortal.submitCleaning.from')}
							</Translate>
							&nbsp;
							{formState.facilityName}
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString('facilityPortal.submitCleaning.on')}
							</Translate>
							&nbsp;
							<Translate>
								{DateUtilService.toDisplayDate(props.submittedDate || formState.creationDateTimeLocal)}
							</Translate>
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString('haulerPortal.submitCleaning.at')}
							</Translate>
							&nbsp;
							<Translate>
								{DateUtilService.toDisplayTime(props.submittedDate || formState.creationDateTimeLocal)}
							</Translate>
							&nbsp;
							<Translate>
								{localizationService.getLocalizedString('haulerPortal.submitCleaning.in')}
							</Translate>
							&nbsp;
							<Translate>{formState.submittedLanguageName}</Translate>.
						</p>
					)}
					{formState.originalSubmittedFormHtmlContent && (
						<>
							{urlService.isAuthorityPortal() &&
								`${localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.submitted'
								)} ${localizationService.getLocalizedString('haulerPortal.submitCleaning.in')} ${
									formState.submittedLanguageName
								}. `}
							<a href="#/" onClick={toggleViewOriginalSubmission}>
								<Translate>
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.viewOriginal')}
								</Translate>
							</a>
							.
						</>
					)}

					{(formState.submittedCustomFormLoaded || formState.submissionCustomFormInitialized) && (
						<>
							{props.readOnly &&
							formState.cleaningManifestHtmlContent &&
							!templateJson &&
							(formState.submittedCustomFormLoaded || formState.submissionCustomFormInitialized) ? (
								<>
									<div id="cleaningManifestHtmlContentDiv" />
								</>
							) : (
								<form id={`cleaningSubmissionForm`} key={`cleaningSubmissionForm`} autoComplete="off">
									{templateJson &&
									(formState.submittedCustomFormLoaded ||
										formState.submissionCustomFormInitialized) ? (
										<>
											{getGenericCleaningFormHeader()}
											{!!facilityCleaningFormElements.length &&
												ConverterFactory.getInputsFromDataItem(
													facilityCleaningFormElements,
													handleChangeInputValue,
													true,
													formType,
													undefined,
													props.readOnly
												).map(formData => {
													return <div>{formData}</div>;
												})}
										</>
									) : (
										getLegacyHardCodedCleaningForm()
									)}
								</form>
							)}
							{props.readOnly && viewAttachments()}
						</>
					)}
				</PopoverModal>
			)}
			{showDeleteCleaningModal && (
				<DeleteModal
					key="confirmDeleteCleaningManifestModal"
					title={localizationService.getLocalizedString('pumpOut.deleteModalTitle')}
					message={getDeleteMessage()}
					showModal={true}
					onCancelButtonClick={() => {
						setShowDeleteCleaningModal(false);
					}}
					onOkayButtonClick={deleteCleaningEvent}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
			{showViewOriginalSubmission && formState.originalSubmittedFormHtmlContent && (
				<OriginalSubmissionModal
					showModal={showViewOriginalSubmission}
					onCancelButtonClick={toggleViewOriginalSubmission}
					originalSubmittedFormHtmlContent={formState.originalSubmittedFormHtmlContent}
					facilityCleaningFormElements={facilityCleaningFormElements}
					files={files}
					submittedLanguageCode={formState.submittedLanguageCode}
				/>
			)}
		</>
	);
};
export default FacilitySubmitCleaningModal;
