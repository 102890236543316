import React from 'react';
import { localizationService, validationService, optionsMap } from 'src/services';
import { TextAreaInput, SingleSelectDropdown, DateInput, PopoverModal, DeleteModal } from 'src/components/widgets';
import { DropDownOption, Violation, Enforcement, EnforcementType, CorrectiveAction } from '@rcp/types';
import {
	alertService,
	saveEnforcement,
	deleteEnforcement,
	loadFacilityAllViolations,
	loadAuthorityEnforcementTypes,
	loadAuthorityCorrectiveActions,
	useReduxSelector,
	useReduxDispatch
} from 'src/redux';
import _ from 'lodash';
import { FaRegTrashAlt } from 'react-icons/fa';
import { violationSlice } from '../../violation/violation.slice';

interface Props {
	closeModal: () => void;
	facilityId?: number;
	enforcement: Enforcement;
	saveCallback?: () => void;
	isDeleteModelVisible?: boolean;
	deleteCallback?: () => void;
	isDispatchLoadCorrectiveActionsRequired?: boolean;
	isDispatchLoadEnforcementTypeRequired?: boolean;
	isFromViolationGrid?: boolean;
}

interface CompState extends Enforcement {
	enforcementTypeIdError?: string;
	enforcementIssueDateError?: string;
	violationError?: string;
	correctiveActionError?: string;
	currentViolation: string;
	violationIds: number[];
	currentCorrectionAction: string;
	correctiveActionIds: number[];
}

export const IssueEnforcementModal: React.FC<Props> = ({
	isDispatchLoadEnforcementTypeRequired = true,
	isDispatchLoadCorrectiveActionsRequired = true,
	...props
}) => {
	const [enforcementTypeOptions, setEnforcementTypeOptions] = React.useState([] as DropDownOption[]);
	const [violationOptions, setViolationOptions] = React.useState([] as DropDownOption[]);
	const [openViolationOptions, setOpenViolationOptions] = React.useState([] as DropDownOption[]);
	const [correctiveActionOptions, setCorrectiveActionOptions] = React.useState([] as DropDownOption[]);
	const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);

	const initialCompState: CompState = {
		currentViolation: '',
		violationIds: [],
		currentCorrectionAction: '',
		correctiveActionIds: []
	};
	const [formState, setFormState] = React.useState<CompState>(initialCompState);

	const dispatch = useReduxDispatch();
	const enforcementTypes = useReduxSelector(state => state.authoritySettingLookups.enforcementTypes);
	const correctiveActions = useReduxSelector(state => state.authoritySettingLookups.correctiveActions);
	const facilityAllViolations = useReduxSelector(state => state.fogFacility.facilityAllViolations);

	React.useEffect(() => {
		isDispatchLoadEnforcementTypeRequired && dispatch(loadAuthorityEnforcementTypes());
		isDispatchLoadCorrectiveActionsRequired && dispatch(loadAuthorityCorrectiveActions());
		dispatch(loadFacilityAllViolations(props.facilityId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	React.useEffect(() => {
		if (enforcementTypes && enforcementTypes.length > 0) {
			let options: DropDownOption[] = optionsMap.toRequiredOptions(
				enforcementTypes,
				(enforcementType: EnforcementType): DropDownOption => {
					return {
						label: enforcementType.name,
						value: enforcementType.enforcementTypeId
					};
				},
				(enforcementType: EnforcementType): boolean => enforcementType.isActive === true
			);
			setEnforcementTypeOptions(options);
		}
	}, [enforcementTypes]);

	React.useEffect(() => {
		if (correctiveActions && correctiveActions.length > 0) {
			let options: DropDownOption[] = optionsMap.toRequiredOptions(
				correctiveActions,
				(correctiveAction: CorrectiveAction): DropDownOption => {
					return {
						label: correctiveAction.description as string,
						value: correctiveAction.correctiveActionId
					};
				},
				(correctiveAction: CorrectiveAction): boolean => correctiveAction.isActive === true
			);
			setCorrectiveActionOptions(options);
		}
	}, [correctiveActions]);

	React.useEffect(() => {
		if (facilityAllViolations.length > 0) {
			let options: DropDownOption[] = optionsMap.toRequiredOptions(
				facilityAllViolations,
				(violation: Violation): DropDownOption => {
					return {
						label: violation.violationTypeTypeName as string,
						value: violation.violationId
					};
				}
			);
			setViolationOptions(options);

			let openOptions: DropDownOption[] = optionsMap.toRequiredOptions(
				_.filter(facilityAllViolations, (violation: Violation) => !violation.inComplianceDate),
				(violation: Violation): DropDownOption => {
					return {
						label: violation.violationTypeTypeName as string,
						value: violation.violationId
					};
				}
			);
			setOpenViolationOptions(openOptions);
		}
	}, [facilityAllViolations]);

	const toIntArray = (ids: string): number[] => {
		let idNumbers: number[] = [];
		if (ids) {
			let idStrings = ids.split(',');
			if (idStrings.length > 0) {
				_.map(idStrings, idString => {
					let idNumber = _.toInteger(idString);
					if (_.isNumber(idNumber)) {
						idNumbers.push(idNumber);
					}
				});
			}
		}
		return idNumbers;
	};

	const setFormStateByEnforcement = (enforcement: Enforcement) => {
		let newFormState = { ...formState, ...enforcement };
		if (newFormState.enforcementViolationIds) {
			newFormState.violationIds = toIntArray(newFormState.enforcementViolationIds);
		}
		if (newFormState.enforcementCorrectiveActionIds) {
			newFormState.correctiveActionIds = toIntArray(newFormState.enforcementCorrectiveActionIds);
		}
		setFormState(newFormState);
	};

	React.useEffect(() => {
		setFormStateByEnforcement(props.enforcement);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.enforcement]);

	const getFilteredOptions = (options: DropDownOption[], formStateField: string, forDropdown: boolean) => {
		let selectedValues: Number[] = _.get(formState, formStateField);
		let filteredOptions = _.filter(options, (option: DropDownOption) => {
			let included = _.includes(selectedValues, option.value);
			if (!forDropdown) {
				return included;
			}
			return !included;
		});
		if (forDropdown) {
			filteredOptions.unshift({
				label: '',
				value: '',
				isHidden: true
			});
		}
		return filteredOptions;
	};

	const addSelectedOption = (formStateField: string, event: any) => {
		let selectedValue = _.toInteger(event.target.value);
		_.set(event.target, 'selectedIndex', 0);
		let newFormState = { ...formState };
		let selectedValues = _.get(newFormState, formStateField) as number[];
		selectedValues.unshift(selectedValue);
		newFormState.currentViolation = '';
		newFormState.currentCorrectionAction = '';
		_.set(newFormState, formStateField, selectedValues);
		setFormState(newFormState);
	};

	const removeSelectedOption = (formStateField: string, valueToRemove: any) => {
		let newFormState = { ...formState };
		let selectedValues = _.get(newFormState, formStateField) as number[];
		_.remove(selectedValues, (value: number) => {
			return value === valueToRemove;
		});
		_.set(newFormState, formStateField, selectedValues);
		setFormState(newFormState);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const cancelSave = () => {
		setFormStateByEnforcement(props.enforcement);
		props.closeModal();
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			'enforcementTypeId',
			'enforcementTypeIdError',
			'enforcement.enforcementType'
		);
		validationService.validateRequiredField(
			newState,
			'enforcementIssueDate',
			'enforcementIssueDateError',
			'enforcement.enforcementIssueDate'
		);
		validationService.validateRequiredField(newState, 'violationIds', 'violationError', 'enforcement.violations');
		validationService.validateRequiredField(
			newState,
			'correctiveActionIds',
			'correctiveActionError',
			'enforcement.correctiveAction'
		);

		setFormState(newState);

		let isFormValid = !validationService.hasError(
			newState,
			'enforcementTypeIdError',
			'enforcementIssueDateError',
			'violationError',
			'correctiveActionError'
		);
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const saveForm = () => {
		if (!isFormValidateForSave()) {
			return;
		}

		let enforcementToSave: Enforcement = {
			...props.enforcement,
			...{
				enforcementTypeId: formState.enforcementTypeId,
				enforcementViolationIds: formState.violationIds.join(','),
				enforcementCorrectiveActionIds: formState.correctiveActionIds.join(','),
				enforcementIssueDate: formState.enforcementIssueDate,
				comments: formState.comments
			}
		};

		dispatch(saveEnforcement(enforcementToSave, props.facilityId))
			.then(() => {
				if (props.saveCallback) {
					props.saveCallback();
				}
				if (props.isFromViolationGrid) {
					dispatch(violationSlice.reload());
				}
			})
			.finally(() => {
				props.closeModal();
			});
	};

	const deleteForm = () => {
		setDeleteModalVisible(false);
		if (props.enforcement && props.enforcement.enforcementId) {
			let facilityId = props.enforcement.organizationId;
			if (!facilityId) {
				throw new Error('Invalid organizationId on inspection');
			}

			dispatch(deleteEnforcement(props.enforcement, facilityId))
				.then(() => {
					if (props.deleteCallback) {
						props.deleteCallback();
					}

					if (props.isFromViolationGrid) {
						dispatch(violationSlice.reload());
					}
				})
				.finally(() => {
					props.closeModal();
				});
		}
	};

	const modalFooterDiv = () => {
		return (
			<>
				{props.enforcement && props.enforcement.enforcementId && (
					<button
						type="button"
						className="btn ai-secondary-delete"
						onClick={() => {
							setDeleteModalVisible(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}

				<div className="ml-auto">
					<button className="btn ai-save" onClick={saveForm}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={cancelSave}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	let dropdownOpenViolationOptions = getFilteredOptions(openViolationOptions, 'violationIds', true);
	let selectedOpenViolations = getFilteredOptions(violationOptions, 'violationIds', false);
	let dropdownCorrectiveActionOptions = getFilteredOptions(correctiveActionOptions, 'correctiveActionIds', true);
	let selectedCorrectiveActions = getFilteredOptions(correctiveActionOptions, 'correctiveActionIds', false);
	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={
					props.enforcement.enforcementTypeId
						? localizationService.getLocalizedString('enforcement.editEnforcement')
						: localizationService.getLocalizedString('enforcement.issueEnforcement')
				}
				footer={modalFooterDiv()}
				save={saveForm}
				cancel={cancelSave}>
				<p>{localizationService.getLocalizedString('enforcement.issueEnforcementDesc')}</p>
				<SingleSelectDropdown
					id="enforcementTypeId"
					name="enforcementTypeId"
					label={localizationService.getLocalizedString('enforcement.enforcementType')}
					value={_.toString(formState.enforcementTypeId)}
					onChange={changeFormState}
					options={enforcementTypeOptions}
					isRequired={true}
					error={formState.enforcementTypeIdError}
				/>
				<DateInput
					id="enforcementIssueDate"
					name="enforcementIssueDate"
					label={localizationService.getLocalizedString('enforcement.enforcementIssueDate')}
					isRequired={true}
					value={formState.enforcementIssueDate}
					onChange={changeFormState}
					error={formState.enforcementIssueDateError}
				/>
				<div className="form-row">
					<SingleSelectDropdown
						id="addViolationIds"
						name="addViolationIds"
						label={localizationService.getLocalizedString('enforcement.addViolations')}
						className="form-group col"
						value={formState.currentViolation}
						options={dropdownOpenViolationOptions}
						isRequired={true}
						onChange={(event: any) => {
							addSelectedOption('violationIds', event);
						}}
						error={formState.violationError}
						preventInternalStateChange
					/>
				</div>
				<div className="form-row">
					{selectedOpenViolations.map((option: DropDownOption, index: number) => (
						<div key={`openViolation${index}`} className="form-row full-width mb-1 mb-2">
							<p className="col ml-1 mb-0">{option.label}</p>
							<button
								name="trash-icon"
								className="btn btn-auto ai-secondary col-auto ml-1"
								onClick={() => {
									removeSelectedOption('violationIds', option.value);
								}}>
								<FaRegTrashAlt className="" />
							</button>
						</div>
					))}
				</div>

				<div className="form-row">
					<SingleSelectDropdown
						id="addCorrectiveActionIds"
						name="addCorrectiveActionIds"
						label={localizationService.getLocalizedString('enforcement.addCorrectiveActions')}
						className="form-group col"
						value={formState.currentCorrectionAction}
						options={dropdownCorrectiveActionOptions}
						isRequired={true}
						onChange={(event: any) => {
							addSelectedOption('correctiveActionIds', event);
						}}
						error={formState.correctiveActionError}
						preventInternalStateChange
					/>
					{selectedCorrectiveActions.map((option: DropDownOption, index: number) => (
						<div key={`correctiveAction${index}`} className="form-row full-width mb-1 mb-2">
							<p className="col ml-1 mb-0">{option.label}</p>
							<button
								name="trash-icon"
								className="btn btn-auto ai-secondary col-auto ml-1"
								onClick={() => {
									removeSelectedOption('correctiveActionIds', option.value);
								}}>
								<FaRegTrashAlt className="" />
							</button>
						</div>
					))}
				</div>
				<TextAreaInput
					id="comments"
					name="comments"
					label={localizationService.getLocalizedString('screen.labels.comments')}
					value={formState.comments}
					onChange={changeFormState}
					isFullWidth={true}
					rows={5}
				/>
			</PopoverModal>
			{props.enforcement && props.enforcement.enforcementId && (
				<DeleteModal
					key="confirmDeleteEnforcementModal"
					title={localizationService.getLocalizedString('enforcement.deleteModalTitle')}
					message={localizationService.getLocalizedString('enforcement.deleteModalMessage')}
					showModal={isDeleteModalVisible}
					onCancelButtonClick={() => {
						setDeleteModalVisible(false);
					}}
					onOkayButtonClick={deleteForm}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
