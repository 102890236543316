import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum RegisterActionType {
	LoadInvitationDetailsRequest = 'loadInvitationDetailsRequest',
	LoadInvitationDetailsSuccess = 'loadInvitationDetailsSuccess',
	LoadQuestionsRequest = 'loadQuestionsRequest',
	LoadQuestionsSuccess = 'loadQuestionsSuccess',
	LoadStatesRequest = 'loadStatesRequest',
	LoadStatesSuccess = 'loadStatesSuccess',
}

export interface LoadInvitationDetailsRequest extends Action {
	type: RegisterActionType.LoadInvitationDetailsRequest;
}

export interface LoadInvitationDetailsSuccess extends Action {
	type: RegisterActionType.LoadInvitationDetailsSuccess;
	invitationDetails: ApiTypes.InvitationDetails
}

export interface LoadQuestionsRequest extends Action {
	type: RegisterActionType.LoadQuestionsRequest;
}

export interface LoadQuestionsSuccess extends Action {
	type: RegisterActionType.LoadQuestionsSuccess;
	questions: ApiTypes.MfaQuestion[]
}

export interface LoadStatesRequest extends Action {
	type: RegisterActionType.LoadStatesRequest;
}

export interface LoadStatesSuccess extends Action {
	type: RegisterActionType.LoadStatesSuccess;
	states: ApiTypes.Jurisdiction[]
}

export type RegisterAction =
	| LoadInvitationDetailsRequest
	| LoadInvitationDetailsSuccess
	| LoadQuestionsRequest
	| LoadQuestionsSuccess
	| LoadStatesRequest
	| LoadStatesSuccess

export const loadInvitationDetailsRequest = (): LoadInvitationDetailsRequest => ({
	type: RegisterActionType.LoadInvitationDetailsRequest
});

export const loadInvitationDetailsSuccess = (
	invitationDetails: ApiTypes.InvitationDetails
): LoadInvitationDetailsSuccess => ({
	type: RegisterActionType.LoadInvitationDetailsSuccess,
	invitationDetails
});

export const loadQuestionsRequest = (): LoadQuestionsRequest => ({
	type: RegisterActionType.LoadQuestionsRequest
});

export const loadQuestionsSuccess = (
	questions: ApiTypes.MfaQuestion[]
): LoadQuestionsSuccess => ({
	type: RegisterActionType.LoadQuestionsSuccess,
	questions
});

export const loadStatesRequest = (): LoadStatesRequest => ({
	type: RegisterActionType.LoadStatesRequest
});

export const loadStatesSuccess = (
	states: ApiTypes.Jurisdiction[]
): LoadStatesSuccess => ({
	type: RegisterActionType.LoadStatesSuccess,
	states
});



