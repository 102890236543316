import React, { useState } from 'react';
import { TabViewField } from 'src/components/widgets';
import { DateUtilService, localizationService, UtilService } from 'src/services';
import { Extractor } from '@rcp/types';
import { ExtractorCardModal } from 'src/components/authority/fog/facilities/facility-details/extractor-tab-page/extractor-card-component-modal';

interface OwnProps {
	currentDevice: Extractor;
}

type Props = OwnProps;

export const DeviceOverviewTab: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('extractor.deviceNumber')}
						value={UtilService.formatValue(props.currentDevice.deviceNumber)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.deviceType')}
						value={UtilService.formatValue(props.currentDevice.extractorType)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.location')}
						value={UtilService.formatValue(props.currentDevice.location)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.extractorDescription')}
						value={UtilService.formatValue(props.currentDevice.extractorDescription)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.trapCapacity')}
						value={UtilService.formatValue(
							props.currentDevice.trapCapacity
								? `${props.currentDevice.trapCapacity} ${props.currentDevice.trapCapacityUnitCode ||
										''}`
								: ''
						)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.trapDepth')}
						value={UtilService.formatValue(
							props.currentDevice.trapDepth
								? `${props.currentDevice.trapDepth} ${props.currentDevice.trapDepthUnitCode || ''}`
								: ''
						)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.cleaningFrequency')}
						value={UtilService.formatValue(props.currentDevice.cleanFrequencyCode)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.numberOfCompartments')}
						value={UtilService.formatValue(props.currentDevice.numberOfCompartments)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.manufacturer')}
						value={UtilService.formatValue(props.currentDevice.manufacturer)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.model')}
						value={UtilService.formatValue(props.currentDevice.model)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.installDate')}
						value={
							props.currentDevice.installDate
								? DateUtilService.toDisplayDate(props.currentDevice.installDate)
								: ''
						}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.latitude')}
						value={UtilService.formatValue(props.currentDevice.latitude)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.longitude')}
						value={UtilService.formatValue(props.currentDevice.longitude)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.mandatoryInstall')}
						value={props.currentDevice.mandatoryInstall ? 'Yes' : 'No'}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.isAdditivesUsed')}
						value={props.currentDevice.isAdditivesUsed ? 'Yes' : 'No'}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('extractor.isInactive')}
						value={props.currentDevice.isActive ? 'No' : 'Yes'}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('extractor.verified')}
						value={props.currentDevice.verified ? 'Yes' : 'No'}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
			{isToggle && (
				<ExtractorCardModal
					extractor={props.currentDevice}
					showExtractorModal={isToggle}
					modalToggleFunction={() => toggle()}
					isAddModal={false}
				/>
			)}
		</>
	);
};
