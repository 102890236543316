import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';
import { filterBy } from '@progress/kendo-data-query';
import {
	ColumnField,
	Dictionary,
	FilterType,
	IppSystemUnit,
	IppUnitTranslations,
	LocalStorageName,
	RouteProps
} from '@rcp/types';
import { History } from 'history';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { IppConstants } from 'src/constants';
import { alertService, RootState } from 'src/redux';
import { RestSlice } from '../../../../../redux/rest.slice';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import UnitTranslationsEditModal from './unit-translations-edit-modal';
import { ippUnitTranslationSlice } from './unit-translations-list-slice';
import './unit-translations.scss';
import _ from 'lodash';

interface DispatchProps {
	fetchIppUnitTranslations: (restSlice: RestSlice<IppUnitTranslations>, queryParameters: Dictionary<string>) => void;
	updateIppUnitTranslations: (
		id: number,
		payload: IppUnitTranslations,
		reloadGrid?: boolean,
		successMessage?: string,
		callbackOnSuccess?: () => void
	) => void;
}

interface Props<T> extends RouteProps, DispatchProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

interface State {
	showModal: boolean;
	modalData: IppUnitTranslations;
	editRowId: number | undefined;
	systemUnits: string[];
}
const { initialPage } = IppConstants.queryParams;

class IppUnitTranslationsList extends Component<Props<IppUnitTranslations>, State> {
	private initialGridOption: GridOption = {} as GridOption;
	private editModalInitialValue: IppUnitTranslations;
	private systemUnitsSaveCopy: string[] = [];

	constructor(props: Props<IppUnitTranslations>) {
		super(props);

		this.initialGridOption = {
			showTabList: false,
			showRefreshButton: false,
			showEditColumnButton: false,
			showFilter: false,
			showSearch: false,
			doNotApplyMaxTable: true,
			prefix: 'ipp.unitTranslations.tableColumns',
			storageName: LocalStorageName.IppUnitTranslationsGrid,
			searchPlaceholder: '',
			showActions: false,
			pageTitle: '',
			activeTab: 'tab-list',
			sort: [],
			showEditColumnModal: false,
			showAddModal: false,
			showAddButton: false,
			showScheduleInspectionModal: false,
			queryParameters: { page: initialPage, sort: 'name asc' },
			showFilterMenuPopOver: false,
			anchorElement: null,
			showFilterEditorModal: false,
			showBulkUpdate: false,
			showExportToSpreadsheet: true,
			description: [
				localizationService.getLocalizedString('ipp.unitTranslations.tableDescription1'),
				localizationService.getLocalizedString('ipp.unitTranslations.tableDescription2')
			],
			allColumns: [
				new ColumnField('name', FilterType.Text),
				new ColumnField('systemUnitName', FilterType.Text),
				new ColumnField('isAvailableToRegulatee', FilterType.Text),
				new ColumnField('showInBadgeCount', FilterType.Text),
				new ColumnField('action', FilterType.Text, this.renderRowActions)
			],
			defaultColumns: ['name', 'systemUnitName', 'isAvailableToRegulatee', 'showInBadgeCount', 'action']
		};

		this.editModalInitialValue = {
			unitId: 1,
			name: '',
			description: '',
			isFlowUnit: false,
			organizationId: 1,
			systemUnitId: 1,
			systemUnitName: '',
			isAvailableToRegulatee: false,
			isReviewed: false,
			isRemoved: false,
			showInBadgeCount: false,
			creationDateTimeUtc: '',
			lastModificationDateTimeUtc: '',
			lastModifierUserId: 1
		};

		this.state = { showModal: false, editRowId: undefined, modalData: this.editModalInitialValue, systemUnits: [] };
	}

	public async componentDidMount() {
		const url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppSystemUnits}`);
		const response: IppSystemUnit[] = await apiService.getResource(url);
		let systemUnits = response.map((unit: IppSystemUnit) => {
			return unit.name;
		});
		systemUnits = _.sortBy(systemUnits, (unit: string) => unit.toLowerCase());
		this.systemUnitsSaveCopy = systemUnits;
		this.setState({ systemUnits });
	}

	saveTranslationData = () => {
		const { editRowId, modalData } = this.state;
		if (editRowId) {
			const systemUnitName = (modalData as any)['systemUnitName'];
			const isAvailableToIndustry = (modalData as any)['isAvailableToRegulatee'];
			const showInBadgeCount = (modalData as any)['showInBadgeCount'];
			if (systemUnitName && showInBadgeCount) {
				alertService.addError(
					localizationService.getLocalizedString('ipp.unitTranslations.errors.showBadgeCountCantBeTrue')
				);
				return;
			}
			if (
				(!systemUnitName || systemUnitName.trim() === '') &&
				isAvailableToIndustry !== undefined &&
				isAvailableToIndustry === true
			) {
				alertService.addError(
					localizationService.getLocalizedString(
						'ipp.unitTranslations.editModalFields.messages.systemUnitNameRequiredErrorMsg'
					)
				);
			} else {
				alertService.clearAllMessages();
				const unitTranslationUrl = '/ipp/authority/unit-translations';
				this.props.updateIppUnitTranslations(
					editRowId,
					modalData,
					true,
					localizationService.getLocalizedString('ipp.unitTranslations.editModalFields.messages.success'),
					() => {
						this.setState((prevState: State) => ({
							showModal: !prevState.showModal,
							editRowId: undefined,
							modalData: this.editModalInitialValue
						}));
					}
				);
				navigateTo(this.props.history, unitTranslationUrl);
			}
		}
	};

	onCancel = (e: React.MouseEvent<HTMLElement>) => {
		this.setState((prevState: State) => ({
			showModal: !prevState.showModal,
			editRowId: undefined,
			modalData: this.editModalInitialValue
		}));
	};

	public render() {
		return (
			<React.Fragment>
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.unitTranslations.pageHeading')}</h1>
				</div>
				<UnitTranslationsEditModal
					showModal={this.state.showModal}
					modalTitle={localizationService.getLocalizedString('ipp.unitTranslations.editModalTitle')}
					templateData={this.state.modalData}
					editRowId={this.state.editRowId}
					systemUnits={this.state.systemUnits}
					onSave={this.saveTranslationData}
					onCancel={(e: React.MouseEvent<HTMLElement>) => this.onCancel(e)}
					onFilterChange={this.filterChange}
					onFieldChange={this.fieldChange}
				/>
				<div className="accordion-data-grid">
					<CollapsibleCard
						className="mt-3"
						accordionType="invitation"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.unitTranslations.accordionTitle'
						)}>
						<DataGrid
							history={this.props.history}
							match={this.props.match}
							restSlice={ippUnitTranslationSlice}
							restState={this.unitTranslationsState}
							gridOption={this.initialGridOption}></DataGrid>
					</CollapsibleCard>
				</div>
			</React.Fragment>
		);
	}

	private unitTranslationsState = (state: RootState) => {
		return state.ippUnitTranslations;
	};

	private renderRowActions = (item: any) => {
		const dataItem = item.dataItem as IppUnitTranslations;
		return (
			<td>
				<button
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						this.setState({ showModal: true, modalData: dataItem, editRowId: dataItem.unitId });
					}}
					title={localizationService.getLocalizedString('screen.buttons.edit')}>
					<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon" />
				</button>
			</td>
		);
	};

	private filterChange = (event: ComboBoxFilterChangeEvent) => {
		this.setState({ systemUnits: this.filterData(event.filter) });
	};

	private filterData = (filter: FilterDescriptor) => {
		const filteredData = this.systemUnitsSaveCopy.slice();
		const filterByOutput = filterBy(filteredData, filter);
		return filterByOutput;
	};

	private fieldChange = (fieldName: string) => {
		return (e: React.ChangeEvent<HTMLInputElement>) => {
			const fieldValue = e.target.checked === undefined ? e.target.value : e.target.checked;
			if (e.target.checked !== undefined && fieldName === 'isAvailableToRegulatee') {
				const systemUnitName = (this.state.modalData as any)['systemUnitName'];
				if ((!systemUnitName || systemUnitName.trim() === '') && e.target.checked === true) {
					alertService.addError(
						localizationService.getLocalizedString(
							'ipp.unitTranslations.editModalFields.messages.systemUnitNameRequiredErrorMsg'
						)
					);
					return false;
				}
			}
			this.setState({ modalData: { ...this.state.modalData, [fieldName]: fieldValue } });
		};
	};
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		fetchIppUnitTranslations: (restSlice: RestSlice<IppUnitTranslations>, queryParameters: Dictionary<string>) => {
			dispatch(restSlice.fetchPage(queryParameters));
		},
		updateIppUnitTranslations: (
			id: number,
			payload: IppUnitTranslations,
			reloadGrid?: boolean,
			successMessage?: string,
			callbackOnSuccess?: () => void
		) =>
			dispatch(
				ippUnitTranslationSlice.patchOne(id, payload, reloadGrid || false, successMessage, callbackOnSuccess)
			)
	};
};

export default connect(null, mapDispatchToProps)(IppUnitTranslationsList);
