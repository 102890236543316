import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Cell, Pie, PieChart, Sector, Tooltip, ResponsiveContainer } from 'recharts';
import { nameof } from 'ts-simple-nameof';
import _ from 'lodash';
import {
	ApiError,
	DonutChartData,
	DashboardWidget,
	Dictionary,
	DashboardWidgetFilterCategories,
	DropDownOption,
	FeatureNames
} from '@rcp/types';
import { apiService, localizationService, Logger, navigateTo, urlService } from 'src/services';
import { alertService, useReduxSelector } from 'src/redux';
import { chartService, ColorScheme, DateFilterValues } from './chart-service';

import './chart.scss';
import { GotoGridButton } from './goto-grid-button';
import { DefaultDateFilterSelect } from './default-date-filter-select';
import { useHistory } from 'react-router';
import uuid from 'uuid';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

interface OwnProps {
	dashboardWidget: DashboardWidget;
	isCustomWidget: boolean;
	previewFilterId?: number;
	onchange?: (widget: DashboardWidget) => void;
}
type Props = OwnProps;

export interface ChartSetting {
	lookIn?: DashboardWidgetFilterCategories;
	groupBy?: string;
	method: string;
	filterId: number;
	filterName?: string;
	dateFilter: DateFilterValues;
	withDateFilter: boolean;
	withButton: boolean;
	//look and feel
	colorScheme: ColorScheme;
	tooltip: boolean;
	height: number; //Width are responsive
	incidentFilterId?: number;
}

const initialChartSetting: ChartSetting = {
	method: 'Count',
	filterId: 0,
	dateFilter: DateFilterValues.Last30Days,
	withDateFilter: true,
	withButton: false,
	//look and feel
	colorScheme: ColorScheme.Purple,
	tooltip: true,
	height: 260
};

interface ChartResponseData {
	total: number;
	numberIgnored: number;
	data: any[];
	type?: string;
}

const initialChartResponseData: ChartResponseData = {
	total: 0,
	numberIgnored: 0,
	data: []
};

export const DonutChartWidget: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [setting, setSetting] = React.useState<ChartSetting>(initialChartSetting);
	const [responseData, setResponseData] = React.useState<ChartResponseData>(initialChartResponseData);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [activeIndex, setActiveIndex] = useState(-1);
	const [availableGroupBys, setAvailableGroupBys] = React.useState<DropDownOption[]>([]);

	const showMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);

	const onMouseEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);

	const onMouseLeave = useCallback(
		_ => {
			setActiveIndex(-1);
		},
		[setActiveIndex]
	);

	const handleChartResult = (result: DonutChartData) => {
		let length = _.isEmpty(result.values) ? 0 : result.values.length;
		if (length < 1 && result.totalSamples <= 0) {
			setResponseData(initialChartResponseData);
			setIsLoading(false);
			return;
		}

		let data: any[] = [];
		_.forEach(result.values, (value: number, index: number) => {
			let label = result.labels[index];
			let slice: any = {
				name: label,
				label: chartService.getDisplayName(label, result.fieldType),
				value: value,
				percentage: chartService.toPercentage(value, result.totalSamples)
			};
			data.push(slice);
		});

		let newData = {
			...data,
			total: result.totalSamples,
			numberIgnored: result.numberOfIgnoredSamples,
			type: result.fieldType,
			data: _.orderBy(data, ['value', 'name'], ['desc', 'asc'])
		};
		Logger.debug('donut chart data:', newData);
		setResponseData(newData);
		setIsLoading(false);
	};

	const loadChartData = (setting: ChartSetting) => {
		let parameters: Dictionary<string> = {};
		parameters[nameof<ChartSetting>(s => s.method)] = setting.method as string;
		if (!setting.groupBy) {
			return;
		}
		parameters[nameof<ChartSetting>(s => s.groupBy)] = setting.groupBy;
		if (!setting.filterId) {
			return;
		}
		parameters[nameof<ChartSetting>(s => s.filterId)] = _.toString(setting.filterId);
		if (!props.isCustomWidget) {
			let dateFilterKey = chartService.getDateFilterField(setting.lookIn);
			let dateFilterValue = chartService.getDateFilterQueryParameterValue(setting.dateFilter);
			if (dateFilterKey && dateFilterValue) {
				parameters[dateFilterKey] = dateFilterValue;
			}
		}

		let queryString = urlService.toQueryString(parameters);
		let url = `${urlService.getApiBaseUrlWithProgram()}/Charts/Donut/${setting.lookIn}?${queryString}`;
		apiService
			.getResource<DonutChartData>(url)
			.then(handleChartResult)
			.catch((e: ApiError) => {
				alertService.addError(e.message);
			});
	};

	React.useEffect(() => {
		if (props.dashboardWidget) {
			let { filterId, filterName } = chartService.getFirstFilter(props.dashboardWidget.dashboardWidgetFilters);
			let isPreviewMode = props.isCustomWidget && props.previewFilterId;
			if (isPreviewMode) {
				filterId = props.previewFilterId;
				filterName = '';
			}
			let properties = props.dashboardWidget.properties ? JSON.parse(props.dashboardWidget.properties) : {};
			let dateFilterValue = props.isCustomWidget
				? DateFilterValues.AllTime
				: props.dashboardWidget.dateFilterValue || initialChartSetting.dateFilter;
			let newSetting: ChartSetting = {
				...setting,
				...properties,
				filterId: filterId,
				filterName: filterName,
				dateFilter: dateFilterValue
			};
			setSetting(newSetting);

			chartService.getAvailableGroupBys(newSetting.lookIn).then(result => {
				setAvailableGroupBys(result);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.dashboardWidget]);

	const changeDateFilter = (e: any) => {
		const { value } = e.target;
		if (props.onchange) {
			props.onchange({ dateFilterValue: value });
		}
		if (value !== setting.dateFilter) {
			var newRequestSetting = { ...setting, dateFilter: value };
			setSetting(newRequestSetting);
		}
	};

	const onClickChart = (e: any, providedGroupByValue?: string, directToMap?: boolean) => {
		if (props.isCustomWidget && props.previewFilterId) return;
		let groupByValue = providedGroupByValue ? providedGroupByValue : e.name;
		if (setting.lookIn && setting.groupBy) {
			let url = chartService.getDrillDownUrl(
				setting.lookIn,
				setting.filterId,
				setting.groupBy,
				groupByValue,
				responseData.type,
				directToMap
			);
			navigateTo(history, url);
		}
	};

	React.useEffect(() => {
		if (setting.lookIn && setting.method && setting.filterId > 0) {
			loadChartData(setting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setting]);

	const renderLegend = () => {
		if (_.isEmpty(responseData.data)) {
			return <></>;
		}

		return (
			<table className="table">
				<thead>
					<tr className="lookup-row lookup-header-row font-size-14px-semibold">
						<td></td>
						<td className="wrapWord">
							{setting.lookIn && setting.groupBy
								? chartService.getGroupByFieldLabel(availableGroupBys, setting.groupBy)
								: ''}
						</td>
						<td>{localizationService.getLocalizedString('dashboard.quantity')}</td>
						<td>{localizationService.getLocalizedString('dashboard.percentage')}</td>
					</tr>
				</thead>
				<tbody>
					{responseData.data.map((entry: any, index: any) => (
						<tr
							key={`legend-${uuid()}`}
							className={activeIndex === index ? 'lookup-row highlight' : 'lookup-row'}
							onMouseEnter={() => onMouseEnter(entry, index)}
							onMouseLeave={() => onMouseLeave(entry)}>
							<td>
								<div
									style={{
										width: '15px',
										height: '15px',
										backgroundColor: getBackgroundColor(index)
									}}></div>
							</td>
							<td className="wrapWord">{entry.label}</td>
							<td>
								{showMapFeature ? (
									<UncontrolledDropdown key={`uncontrolled-${uuid()}`}>
										<DropdownToggle tag="a" className="cursor-pointer">
											{entry.value}
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={e => onClickChart(e, entry.label, true)}>
												{localizationService.getLocalizedString('screen.buttons.showOnMap')}
											</DropdownItem>
											<DropdownItem onClick={e => onClickChart(e, entry.label)}>
												{localizationService.getLocalizedString('screen.buttons.showInTable')}
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								) : (
									<a
										href={
											setting.lookIn && setting.groupBy
												? chartService.getDrillDownUrl(
														setting.lookIn,
														setting.filterId,
														setting.groupBy,
														entry.name,
														responseData.type
												  )
												: '#/'
										}>
										{entry.value}
									</a>
								)}
							</td>

							<td>{`${entry.percentage}%`}</td>
						</tr>
					))}
				</tbody>
			</table>
		);
	};

	const renderActiveShape = (props: any) => {
		const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;

		return (
			<g>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius}
					outerRadius={outerRadius}
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6}
					outerRadius={outerRadius + 10}
					fill={fill}
				/>
			</g>
		);
	};

	const CustomTooltip = (_value: any, _name: any, props: any) => {
		const { payload } = props;
		return payload ? `${payload.value} (${payload.percentage.toFixed(1)}%)` : null;
	};

	const colors = chartService.getColorScheme(setting.colorScheme as ColorScheme);

	const getBackgroundColor = (index: any) => {
		return colors[index % colors.length];
	};

	return (
		<div>
			{_.isEmpty(responseData.data) && !isLoading ? (
				<div className={props.previewFilterId ? 'm-2' : 'empty-chart'}>
					{!props.isCustomWidget
						? localizationService.getLocalizedString('dashboard.noData')
						: localizationService.getLocalizedString('dashboard.noDataAvailable')}
				</div>
			) : (
				<ResponsiveContainer width="100%" height={setting.height}>
					<PieChart
						width={400}
						height={setting.height}
						margin={{
							top: 0,
							right: 0,
							left: 0,
							bottom: 0
						}}>
						{setting.tooltip && (
							<Tooltip wrapperStyle={{ zIndex: chartService.tooltipZIndex }} formatter={CustomTooltip} />
						)}
						<Pie
							activeIndex={activeIndex}
							activeShape={renderActiveShape}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							onClick={e => onClickChart(e)}
							startAngle={90}
							endAngle={450}
							data={responseData.data}
							nameKey="label"
							dataKey="value"
							cx="50%"
							cy="50%"
							innerRadius={60}
							outerRadius={120}
							paddingAngle={0}
							isAnimationActive={true}
							fill="#8884d8">
							{responseData.data.map((_entry, index) => (
								<Cell key={`cell-${index}`} fill={getBackgroundColor(index)} />
							))}
						</Pie>
					</PieChart>
				</ResponsiveContainer>
			)}
			<div className="row mt-3">
				{setting.withDateFilter && (
					<div className="col-4">
						<DefaultDateFilterSelect
							dashboardWidgetId={props.dashboardWidget.dashboardWidgetId}
							value={setting.dateFilter}
							onChange={changeDateFilter}></DefaultDateFilterSelect>
					</div>
				)}
				{setting.withButton && (
					<div className="col">
						<GotoGridButton lookIn={setting.lookIn} />
					</div>
				)}
			</div>
			{_.isEmpty(responseData.data) || isLoading ? (
				<></>
			) : (
				<div className="table-responsive">{renderLegend()}</div>
			)}
		</div>
	);
};
