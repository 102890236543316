import { Dictionary, Environment } from '@rcp/types';
import _ from 'lodash';
import { Action } from 'redux';
import { localStorageService, Logger, urlService } from 'src/services';

export interface PageLoadingState {
	location: string;
	getCallUrls: Dictionary<number>;
	loadingCounter: number;
}

export const initialPageLoadingState: PageLoadingState = {
	location: '',
	getCallUrls: {},
	loadingCounter: 0
};

export enum PageLoadingActionType {
	Start = 'Start',
	Done = 'Done'
}

export const dataLoadingDuplicateXhrGetCallTolerantNumber = localStorageService.getLocalStorageWithDefaultNumber(
	'duplicateXhrGetCallTolerantNumber',
	1
);
const checkDuplicateXhrCalls = (forStart: boolean, state: PageLoadingState, action: Action) => {
	let environment = urlService.getEnvironment();
	if (environment === Environment.Production) {
		return;
	}
	let currentLocation = window.location.pathname;
	if (state.location !== currentLocation) {
		//reset getCallUrls
		state.location = currentLocation;
		state.getCallUrls = {};
	}
	let method = _.get(action, 'method');
	let url = _.get(action, 'url');
	if ('GET' === method && url) {
		let count = _.get(state.getCallUrls, url, 0);
		if (forStart === true) {
			count++;
		} else {
			count--;
		}
		state.getCallUrls[url] = count;
		Logger.trace(`PageLoading check duplicate calls as method,count,url: [${method}][${count}][${url}].`);
		if (forStart === true && count > dataLoadingDuplicateXhrGetCallTolerantNumber) {
			alertDuplicateXhrCall(environment, method, url, count);
		}
	}
};

const alertDuplicateXhrCall = (environment: Environment, method: string, url: string, count: number) => {
	var message = `Found duplicate ${method} calls to url ${url}.`;
	if (environment === Environment.Development || urlService.isDebugMode()) {
		Logger.error(message);
	} else if (environment === Environment.Production) {
		Logger.info(message);
	} else {
		//auto, test, beta, and staging
		Logger.warn(message);
	}
};

const maximumLoadingCount = 20;
export const PageLoadingReducer = (state = initialPageLoadingState, action: Action): PageLoadingState => {
	switch (action.type) {
		case PageLoadingActionType.Start:
			state.loadingCounter++;
			Logger.trace(`PageLoading started - loadingCounter:`, state.loadingCounter);
			if (state.loadingCounter > maximumLoadingCount) {
				//For fault tolerant
				state.loadingCounter = 0;
			}
			checkDuplicateXhrCalls(true, state, action);
			return state;
		case PageLoadingActionType.Done:
			Logger.trace(`PageLoading done - loadingCounter:`, state.loadingCounter);
			state.loadingCounter--;
			if (state.loadingCounter < 0) {
				//For fault tolerant
				state.loadingCounter = 0;
			}
			checkDuplicateXhrCalls(false, state, action);
			return state;
		default:
			return state;
	}
};
