import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { alertService } from 'src/redux';
import { AuthorityUser, InternalUser, RegulatoryProgramName, SimpleUserProfile } from '@rcp/types';
import { PopoverModal, TextInput, SingleCheckbox } from 'src/components/widgets';
import { validationService, localizationService, Resource, urlService, apiService } from 'src/services';
import { authorityUsersSlice, internalUsersSlice } from './user-slice';
import { WithAqiSsoFeatureFlag } from './with-aqi-sso-feature-flag';

interface OwnProps {
	isCreate: boolean;
	showModal: boolean;
	toggleModal: () => void;
	user?: AuthorityUser | InternalUser;
	isForInternalUser?: boolean;
}

type Props = OwnProps;

interface UserEditorFormState extends AuthorityUser {
	isAdministrator?: boolean;
	shouldCreateAqiIdpUser?: boolean;
	emailFormatError?: string;
	isExistingUser?: boolean;
	existingUserWarning?: string;
	firstNameMissingError?: string;
	lastNameMissingError?: string;
	roleMissingError?: string;
}

interface Subscriptions {
	authorityUserCurrentCount?: number;
	authorityUserLicenseTotalCount?: number;
}

export const UserEditorModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState<UserEditorFormState>({});
	const [program, setProgram] = React.useState('fog');
	const [subscriptionsAvailable, setSubscriptionsAvailable] = React.useState<Subscriptions>({
		authorityUserCurrentCount: 2,
		authorityUserLicenseTotalCount: 5
	});

	const dispatch = useDispatch();

	const setFormStateFromProps = (user?: AuthorityUser | InternalUser) => {
		let newState = { ...user };
		setFormState(newState);
	};

	React.useEffect(() => {
		let newState = { ...props.user };
		setFormState(newState);
	}, [props.user]);

	const getAuthorityUserLicenseInfo = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Users)}/GetAuthorityUserLicenseInfo`;
		let subscription: Subscriptions = await apiService.getResource<Subscriptions>(url);
		setSubscriptionsAvailable({
			...subscription
		});
	};

	React.useEffect(() => {
		if (props.showModal && props.isCreate && !props.isForInternalUser) {
			getAuthorityUserLicenseInfo();
		}
		let currentProgram = urlService.getCurrentProgramOrDefault();
		setProgram(currentProgram);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.showModal]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const getInitialStateAfterEmailChanged = (newState: UserEditorFormState) => {
		newState.existingUserWarning = undefined;
		newState.isExistingUser = false;

		return newState;
	};

	const checkUserExist = async (e: any) => {
		if (props.isForInternalUser || !props.isCreate) {
			return;
		}

		const { value } = e.target;

		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'email',
			'emailFormatError',
			localizationService.getLocalizedString('users.email')
		);

		if (newState.email) {
			validationService.validateEmailFormatField(newState, 'email', 'emailFormatError');
		}

		let isFormValid = !validationService.hasError(newState, 'emailFormatError');

		if (isFormValid) {
			try {
				let url = `${urlService.getAuthorityResourcesApiUrl(
					Resource.Users
				)}/GetExistingAuthorityUserIfNotInCurrentProgram/${value}`;
				let existingUser = await apiService.getResource<SimpleUserProfile>(url);

				if (existingUser) {
					alertService.addWarning(localizationService.getLocalizedString('users.existingUserFound'));
					newState.existingUserWarning = localizationService.getLocalizedString(
						'users.existingUserFoundInlineMessage'
					);

					newState.firstName = existingUser.firstName;
					newState.lastName = existingUser.lastName;
					newState.isExistingUser = true;
				}
			} catch (e) {
				newState = getInitialStateAfterEmailChanged(newState);
				if (e.message !== localizationService.getLocalizedString('users.userDoesNotExistWithEmail')) {
					alertService.addError(e.message);
				}
			}

			setFormState(newState);
		} else {
			newState = { ...formState };
			setFormState(getInitialStateAfterEmailChanged(newState));
		}
	};

	const onClearButtonPress = (e: any) => {
		let newState = { ...formState };

		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(getInitialStateAfterEmailChanged(newState));
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'email',
			'emailFormatError',
			localizationService.getLocalizedString('users.email')
		);
		validationService.validateRequiredField(
			newState,
			'firstName',
			'firstNameMissingError',
			localizationService.getLocalizedString('users.firstName')
		);
		validationService.validateRequiredField(
			newState,
			'lastName',
			'lastNameMissingError',
			localizationService.getLocalizedString('users.lastName')
		);
		if (!props.isForInternalUser) {
			validationService.validateRequiredField(
				newState,
				'isAdministrator',
				'roleMissingError',
				localizationService.getLocalizedString('users.role')
			);
		}

		if (newState.email) {
			validationService.validateEmailFormatField(newState, 'email', 'emailFormatError');
		}

		let isFormValid = !validationService.hasError(
			newState,
			'emailFormatError',
			'firstNameMissingError',
			'lastNameMissingError',
			'roleMissingError'
		);
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const saveButtonClicked = async () => {
		if (!validateBeforeForSave()) {
			return;
		}
		let userProfileToSave = { ...formState };
		if (props.isForInternalUser) {
			if (props.isCreate) {
				dispatch(
					internalUsersSlice.createOne(
						userProfileToSave as InternalUser,
						true,
						localizationService.getLocalizedString('alertMessages.addSuccess', 'users.customerSuccessUser'),
						() => {
							setFormState({});
							props.toggleModal();
						}
					)
				);
			} else {
				dispatch(
					internalUsersSlice.patchOne(
						(userProfileToSave as InternalUser).internalUserId as number,
						userProfileToSave as InternalUser,
						true,
						localizationService.getLocalizedString(
							'alertMessages.savedSuccess',
							'users.customerSuccessUser'
						),
						() => {
							props.toggleModal();
						}
					)
				);
			}
		} else {
			if (props.isCreate) {
				dispatch(
					authorityUsersSlice.createOne(
						userProfileToSave as AuthorityUser,
						true,
						localizationService.getLocalizedString('alertMessages.addSuccess', 'users.user'),
						() => {
							setFormState({});
							props.toggleModal();
						}
					)
				);
			} else {
				dispatch(
					authorityUsersSlice.patchOne(
						(userProfileToSave as AuthorityUser).authorityUserId as number,
						userProfileToSave as AuthorityUser,
						true,
						localizationService.getLocalizedString('alertMessages.savedSuccess', 'users.user'),
						() => {
							props.toggleModal();
						}
					)
				);
			}
		}
	};

	const setAdminUser = (isAdministrator: boolean) => {
		var newState = { ...formState };
		newState.isAdministrator = isAdministrator;
		newState.role = isAdministrator ? 'Administrator' : 'Standard';
		setFormState(newState);
	};

	const setCreateAqiIdpUser = (e: any) => {
		var newState = { ...formState };
		newState.shouldCreateAqiIdpUser = e.target.checked;
		setFormState(newState);
	};

	const isActiveChanged = () => {
		var newState = { ...formState };
		newState.isEnabled = !newState.isEnabled;
		setFormState(newState);
	};

	const isAccountLockedChanged = () => {
		var newState = { ...formState };
		newState.isAccountLocked = !newState.isAccountLocked;
		setFormState(newState);
	};

	const cancelSave = () => {
		setFormStateFromProps(props.user);
		props.toggleModal();
	};

	const modalFooterDiv = () => {
		return (
			<>
				<div className="ml-auto">
					{!isDisable() ? (
						<button className="btn ai-save ml-2" onClick={saveButtonClicked}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</button>
					) : null}

					<button className="btn ai-white ml-2" onClick={cancelSave}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	const isDisable = () => {
		if (props.isForInternalUser) return false;

		const { authorityUserCurrentCount, authorityUserLicenseTotalCount } = subscriptionsAvailable;
		if (authorityUserCurrentCount && authorityUserLicenseTotalCount) {
			return authorityUserCurrentCount >= authorityUserLicenseTotalCount;
		}
		return false;
	};
	const { authorityUserCurrentCount, authorityUserLicenseTotalCount } = subscriptionsAvailable;
	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={
					props.isCreate
						? localizationService.getLocalizedString('users.addUser')
						: localizationService.getLocalizedString('users.editUser')
				}
				footer={modalFooterDiv()}
				save={saveButtonClicked}
				cancel={cancelSave}>
				{props.isCreate && !props.isForInternalUser ? (
					<div id="subscriptionText" className="mt-1 mb-2">
						<span>
							{localizationService.getLocalizedString(
								'users.authorityUserSubscriptions',
								`${authorityUserCurrentCount}`,
								`${authorityUserLicenseTotalCount}`
							)}
							.
							{authorityUserCurrentCount &&
							authorityUserLicenseTotalCount &&
							authorityUserCurrentCount >= authorityUserLicenseTotalCount ? (
								<span>
									&nbsp; Contact <a className="contact-email">sales@aquaticinformatics.com</a> to add
									more user accounts.
								</span>
							) : null}
						</span>
					</div>
				) : null}

				<TextInput
					id="email"
					name="email"
					label={localizationService.getLocalizedString('users.email')}
					value={formState.email}
					onChange={changeFormState}
					onBlur={checkUserExist}
					isRequired={true}
					error={formState.emailFormatError}
					warningText={formState.existingUserWarning}
					onClearButtonPress={onClearButtonPress}
					isDisabled={isDisable()}
				/>
				<div className="form-row">
					<TextInput
						id="firstName"
						name="firstName"
						label={localizationService.getLocalizedString('users.firstName')}
						value={formState.firstName}
						onChange={changeFormState}
						isRequired={true}
						error={formState.firstNameMissingError}
						isDisabled={formState.isExistingUser || isDisable()}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="lastName"
						name="lastName"
						label={localizationService.getLocalizedString('users.lastName')}
						value={formState.lastName}
						isRequired={true}
						onChange={changeFormState}
						error={formState.lastNameMissingError}
						isDisabled={formState.isExistingUser || isDisable()}
						className="form-group col-sm-6"
					/>
				</div>
				{!formState.isExistingUser && (
					<>
						<TextInput
							id="titleRole"
							name="titleRole"
							label={localizationService.getLocalizedString('users.title')}
							value={formState.titleRole}
							onChange={changeFormState}
							isDisabled={isDisable()}
						/>
						<TextInput
							id="phoneNumber"
							name="phoneNumber"
							label={localizationService.getLocalizedString('users.phone')}
							value={formState.phoneNumber}
							onChange={changeFormState}
							isDisabled={isDisable()}
						/>
						<TextInput
							id="mobilePhone"
							name="mobilePhoneNumber"
							label={localizationService.getLocalizedString('users.mobilePhone')}
							value={formState.mobilePhoneNumber}
							onChange={changeFormState}
							isDisabled={isDisable()}
						/>
					</>
				)}
				{!props.isForInternalUser ? (
					<>
						<div className="form-group required">
							<label className={`is-invalid ${isDisable() ? 'disabled-feedback' : ''}`}>
								{localizationService.getLocalizedString('users.role')}
							</label>
							<input type="hidden" className="form-control is-invalid" disabled={isDisable()} />
							{formState.roleMissingError && (
								<div className="invalid-feedback">{formState.roleMissingError}</div>
							)}
						</div>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="standardUser"
								id="standardUser"
								value="false"
								checked={formState.isAdministrator === false}
								onChange={() => setAdminUser(false)}
								disabled={isDisable()}
							/>
							<label className="custom-control-label" htmlFor="standardUser">
								<strong>{localizationService.getLocalizedString('users.standard')}</strong> -{' '}
								{program === RegulatoryProgramName.FOG.toLowerCase() && (
									<span>{localizationService.getLocalizedString('users.standardUserNoteFOG')}</span>
								)}
								{program != RegulatoryProgramName.FOG.toLowerCase() && (
									<span>{localizationService.getLocalizedString('users.standardUserNote')}</span>
								)}
							</label>
						</div>

						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="administratorUser"
								value="true"
								id="administratorUser"
								checked={formState.isAdministrator === true}
								onChange={() => setAdminUser(true)}
								disabled={isDisable()}
							/>
							<label className="custom-control-label" htmlFor="administratorUser">
								<strong>{localizationService.getLocalizedString('users.administrator')}</strong> -{' '}
								<span>{localizationService.getLocalizedString('users.administratorUserNote')}</span>
							</label>
						</div>
					</>
				) : null}

				{props.isForInternalUser === false && props.isCreate === true && (
					<WithAqiSsoFeatureFlag>
						<SingleCheckbox
							id="shouldCreateAqiIdpUser"
							name="shouldCreateAqiIdpUser"
							label={localizationService.getLocalizedString('users.createAqiIdpUser')}
							checked={formState.shouldCreateAqiIdpUser === true}
							onChange={setCreateAqiIdpUser}
							className="div-checkbox mt-4"
						/>
					</WithAqiSsoFeatureFlag>
				)}
				{props.isCreate === false ? (
					<>
						{props.isForInternalUser ? (
							<SingleCheckbox
								id="isAccountLocked"
								name="isAccountLocked"
								label={
									<>
										<strong>{localizationService.getLocalizedString('users.lockout')}</strong> -{' '}
										<span>{localizationService.getLocalizedString('users.lockoutUserNote')}</span>
									</>
								}
								checked={formState.isAccountLocked}
								onChange={isAccountLockedChanged}
								className="div-checkbox"
							/>
						) : (
							<SingleCheckbox
								id="isEnabled"
								name="isEnabled"
								label={
									<>
										<strong>{localizationService.getLocalizedString('users.isActive')}</strong> -{' '}
										<span>
											{localizationService.getLocalizedString(
												'users.activeUserWithLicenseLimitNote'
											)}
										</span>
									</>
								}
								checked={formState.isEnabled}
								onChange={isActiveChanged}
								className="div-checkbox"
							/>
						)}
						<WithAqiSsoFeatureFlag>
							{!_.isEmpty(formState.aqiIdpUserId) && (
								<p className="mt-4">{localizationService.getLocalizedString('users.editAqiIdpUser')}</p>
							)}
						</WithAqiSsoFeatureFlag>
					</>
				) : null}
			</PopoverModal>
		</div>
	);
};
