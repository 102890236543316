import { localizationService, DateUtilService } from 'src/services';

export const getDateContent = (todayNow: string, prefix: string, completeDate?: string, dueDate?: string) => {
	let todayNowMoment = DateUtilService.getMomentInAuthorityTimeZone(todayNow).endOf('day');

	let dueDateMoment = dueDate && DateUtilService.getMomentInAuthorityTimeZone(dueDate).endOf('day');
	let contentText = ` ${prefix}`;

	if (completeDate) {
		contentText += ' ' + localizationService.getLocalizedString('screen.timeline.complete');
		let completeDateMoment =
			completeDate && DateUtilService.getMomentInAuthorityTimeZone(completeDate).endOf('day');

		if (completeDateMoment && dueDateMoment) {
			let diffDays = completeDateMoment.diff(dueDateMoment, 'days');
			if (diffDays > 0) {
				contentText += ` ${diffDays} ${localizationService.getLocalizedString('screen.timeline.days')}`;
				contentText += ` ${localizationService.getLocalizedString('screen.timeline.late')}`;
			} else if (diffDays < 0) {
				diffDays *= -1;
				contentText += ` ${diffDays} ${localizationService.getLocalizedString('screen.timeline.days')}`;
				contentText += ` ${localizationService.getLocalizedString('screen.timeline.early')}`;
			}
		}
	} else if (dueDateMoment && dueDateMoment.isAfter(todayNowMoment)) {
		//Due in future
		contentText += ' ' + localizationService.getLocalizedString('screen.timeline.due');
	} else if (dueDateMoment && todayNowMoment.diff(dueDateMoment, 'days') === 0) {
		//Due today
		contentText += ' ' + localizationService.getLocalizedString('screen.timeline.dueToday');
	} else if (dueDateMoment) {
		//Past Due and still Due
		let diffDays = todayNowMoment.diff(dueDateMoment, 'days');
		contentText += `  ${localizationService.getLocalizedString('screen.timeline.is')} ${diffDays}`;
		contentText += `  ${localizationService.getLocalizedString('screen.timeline.daysPastDue')}`;
	}

	return contentText;
};

export const getTimelineIconColor = (todayNow: string, completeDate?: string, dueDate?: string) => {
	let todayNowMoment = DateUtilService.getMomentInAuthorityTimeZone(todayNow).endOf('day');
	let dueDateMoment = dueDate && DateUtilService.getMomentInAuthorityTimeZone(dueDate).endOf('day');
	if (completeDate) {
		return 'timeline-icon-complete-event';
	}
	return 'timeline-icon-due-event';
};
