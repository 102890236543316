import * as React from 'react';
import { Editor, EditorTools, EditorUtils, ProseMirror } from '@progress/kendo-react-editor';
import { InsertMergeFieldTool } from './insert-merge-field-tool';
import ColorPickerTool from './color-picker-tool';
import { NoticeTemplateType } from '@rcp/types/src';
import { mergeFieldSlice } from 'src/components/authority/shared/settings/notices/merge_field.slice';
import { Logger, Resource, urlService } from 'src/services';
import { useDispatch } from 'react-redux';
import './rich-text-editor.scss';
import { InsertPageBreakTool } from './insert-tools';
import { InsertImage } from './insert-image-tool';
import { insertImagePlugin } from './insert-image-plugin';
import { insertImageFiles } from './utils';
import _ from 'lodash';
import { MergeFieldDto } from '@rcp/types';
import { InsertDoNotTranslateTool } from './insert-do-not-translate-tool';

const {
	Bold,
	Italic,
	Underline,
	Indent,
	Outdent,
	AlignLeft,
	AlignRight,
	AlignCenter,
	OrderedList,
	UnorderedList,
	FontSize,
	Link,
	Unlink,
	InsertTable,
	AddRowBefore,
	AddRowAfter,
	AddColumnBefore,
	AddColumnAfter,
	DeleteRow,
	DeleteColumn,
	DeleteTable,
	MergeCells,
	SplitCell,
	Subscript,
	Superscript,
	FormatBlock,
	ViewHtml,
	Undo,
	Redo
} = EditorTools;
const { pasteCleanup, sanitize, sanitizeClassAttr, sanitizeStyleAttr, removeAttribute } = EditorUtils;
interface OwnProps {
	noticeTemplateType?: NoticeTemplateType;
	//use to pass a custom merge field list when not using the fields in merge_fields_en.json
	customMergeFieldList?: string[];
	customMergeFields?: MergeFieldDto[];
	height?: string;
	width?: string;
	//html string for default content of text editor
	defaultContent?: string;
	editorContent?: string;
	hideColorPickerTool?: boolean;
	hideFontSize?: boolean;
	saveTemplateData?: (editorContent: string) => void;
	isLetterTemplate?: boolean;
	showTableTools?: boolean;
	onChange?: (e: any) => void;
	isFixedHeight?: boolean;
	editorIndex?: number;
	hidePageNumber?: boolean;
	useBasicTools?: boolean;
	useOnlyMergeFieldTool?: boolean;
	isLanguageSupportEnabled?: boolean;
}

type Props = OwnProps;

const editorStyles = `
    hr {
        border-top: 1px dotted #ccc
    }
`;

interface editorContentType {
	previousContent: string;
	currentContent: string;
}

export const RichTextEditor: React.FC<Props> = props => {
	const editorRef = React.createRef<Editor>();
	const [editorData, setEditorData] = React.useState<editorContentType>({ previousContent: '', currentContent: '' });
	const [isMergedFieldAdded, setIsMergeFieldAdded] = React.useState(false);
	const dispatch = useDispatch();

	const initialTools = [
		[Bold, Italic, Underline],
		[Subscript, Superscript],
		[AlignLeft, AlignCenter, AlignRight],
		[Indent, Outdent],
		[OrderedList, UnorderedList],
		[Undo, Redo]
	];
	const basicTools = [
		[Bold, Italic],
		[OrderedList, UnorderedList],
		[Link, Unlink],
		[Undo, Redo]
	];
	const linkTools = [[Link, Unlink, InsertImage]];
	const tableTools = [
		[InsertTable],
		[AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
		[DeleteRow, DeleteColumn, DeleteTable],
		[MergeCells, SplitCell]
	];
	const [tools, setTools] = React.useState([...initialTools]);

	React.useEffect(() => {
		if (props.noticeTemplateType && !props.customMergeFieldList && !props.customMergeFields) {
			mergeFieldSlice.setApiUrlPath(Resource.SettingNoticeTemplatesMergeFields + '/' + props.noticeTemplateType);
			dispatch(mergeFieldSlice.fetchAll());
		}
	}, [props.noticeTemplateType]);

	const applyFontFamilyToIFrame = (iFrame: any) => {
		iFrame.contentDocument
			.querySelector('.k-content')
			.setAttribute('style', 'font-family: Arial, Helvetica, sans-serif;');
	};

	React.useEffect(() => {
		//set default font to arial
		const editors = [...document.getElementsByClassName('k-editor')];
		editors.forEach((editor: any) => {
			let iFrame = editor.querySelector('iframe');
			if (iFrame && iFrame.contentDocument) {
				iFrame.contentDocument.querySelector('.k-content')
					? applyFontFamilyToIFrame(iFrame)
					: (iFrame.onload = function() {
							applyFontFamilyToIFrame(iFrame);
					  });
			}
		});
	}, []);

	React.useEffect(() => {
		let MergeFieldTool = ownProps => (
			<InsertMergeFieldTool
				{...ownProps}
				customMergeFieldList={props.customMergeFieldList}
				customMergeFields={props.customMergeFields}
				setIsMergeFieldAdded={setIsMergeFieldAdded}
				hidePageNumber={props.hidePageNumber}
			/>
		);

		let DoNotTranslateTool = ownProps => <InsertDoNotTranslateTool {...ownProps} />;

		setTools((tools: any) => {
			let updatedTools: any = [...initialTools];
			updatedTools = props.isLetterTemplate ? [...updatedTools, InsertPageBreakTool] : updatedTools;
			updatedTools = props.hideColorPickerTool ? updatedTools : [...updatedTools, ColorPickerTool];
			updatedTools = urlService.isDebugMode() ? [...updatedTools, ViewHtml] : updatedTools;
			updatedTools = props.hideFontSize ? updatedTools : [...updatedTools, FontSize];
			updatedTools = [...updatedTools, FormatBlock];
			updatedTools = [...updatedTools, MergeFieldTool];
			if (props.isLanguageSupportEnabled) {
				updatedTools = [...updatedTools, DoNotTranslateTool];
			}
			updatedTools = [...updatedTools, ...linkTools];

			if (props.showTableTools) {
				updatedTools = [...updatedTools, ...tableTools];
			}
			if (props.useBasicTools) {
				updatedTools = [...basicTools];
			}
			if (props.useOnlyMergeFieldTool) {
				updatedTools = [MergeFieldTool];
			}
			return updatedTools;
		});
	}, [
		props.customMergeFieldList && props.customMergeFieldList.length,
		props.customMergeFields && props.customMergeFields.length,
		props.isLanguageSupportEnabled
	]);

	function isOverflown(element: any) {
		if (
			element.scrollHeight > element.clientHeight &&
			editorData.currentContent.length < editorData.previousContent.length
		) {
			return false;
		}
		return element.scrollHeight > element.clientHeight;
	}

	React.useEffect(() => {
		let editorContent = props.editorContent
			? props.editorContent
					.replaceAll('<p><br></p>', '<p></p>')
					.replaceAll('\r\n', '')
					.replaceAll('\n', '')
			: '';
		editorContent = pasteCleanup(sanitize(editorContent), pasteSettings);
		setEditorData({ previousContent: editorContent, currentContent: editorContent });
	}, [props.editorContent]);

	React.useEffect(() => {
		if (props.isFixedHeight) {
			const editors = [...document.getElementsByClassName('k-editor')];
			const editorIndex = props.editorIndex || 0;
			if (editorIndex > editors.length - 1) {
				Logger.error(`Editor at position ${props.editorIndex} not found`);
			} else {
				const editorIframe = editors[editorIndex].querySelector('iframe');
				if (editorIframe && editorIframe.contentDocument) {
					const getEditor = () => editorIframe.contentDocument!.getElementsByClassName('k-content')[0];
					editorIframe.contentDocument.querySelector('.k-content')
						? isOverflown(getEditor()) &&
						  setEditorData({ ...editorData, currentContent: editorData.previousContent })
						: (editorIframe.onload = function() {
								applyFontFamilyToIFrame(editorIframe);
								isOverflown(getEditor()) &&
									setEditorData({ ...editorData, currentContent: editorData.previousContent });
						  });
				}
			}
		}
	}, [editorData.currentContent]);

	React.useEffect(() => {
		if (isMergedFieldAdded) {
			const modifiedData = (getHtml() || '').replaceAll('<p></p>', '<p><br></p>').replaceAll('&nbsp;', ' ');

			if (props.saveTemplateData) {
				props.saveTemplateData(modifiedData);
			} else if (props.onChange) {
				let event = { html: '' };
				event.html = modifiedData;
				props.onChange(event);
			}
		}
		setIsMergeFieldAdded(false);
	}, [isMergedFieldAdded]);

	const getHtml = () => {
		if (editorRef.current) {
			const view = editorRef.current.view;
			if (view) {
				return EditorUtils.getHtml(view.state);
			}
		}
	};

	const onChange = (event: any) => {
		setEditorData({ previousContent: editorData.currentContent, currentContent: event.html });
	};

	const onBlur = (event: any) => {
		const modifiedEditorContent = editorData.currentContent
			.replaceAll('<p></p>', '<p><br></p>')
			.replaceAll('&nbsp;', ' ');
		props.saveTemplateData && props.saveTemplateData(modifiedEditorContent);
		if (props.onChange) {
			let copyEvent = _.cloneDeep(event);
			copyEvent.html = modifiedEditorContent;
			props.onChange(copyEvent);
		}
	};

	const onImageInsert = args => {
		const { files, view, event } = args;
		const nodeType = view.state.schema.nodes.image;
		const position = event.type === 'drop' ? view.posAtCoords({ left: event.clientX, top: event.clientY }) : null;
		insertImageFiles({ view, files, nodeType, position });
		return files.length > 0;
	};

	const pasteSettings = {
		convertMsLists: true,
		// stripTags: 'span|font'
		attributes: {
			// keep class, style attributes
			class: sanitizeClassAttr,
			style: sanitizeStyleAttr,
			// keep `width`, `height` and `src` attributes
			width: () => {},
			height: () => {},
			src: () => {},
			// keep hyper link attributes
			href: () => {},
			title: () => {},
			target: () => {},
			// Removes `lang` attribute
			// lang: removeAttribute,
			// removes other (unspecified above) attributes
			'*': removeAttribute
		}
	};

	const onMount = event => {
		const iframeDocument = event.dom.ownerDocument;
		const style = iframeDocument.createElement('style');
		style.appendChild(iframeDocument.createTextNode(editorStyles));
		iframeDocument.head.appendChild(style);
		const state = event.viewProps.state;
		const plugins = [...state.plugins, insertImagePlugin(onImageInsert)];
		return new ProseMirror.EditorView(
			{ mount: event.dom },
			{
				...event.viewProps,
				state: ProseMirror.EditorState.create({ doc: state.doc, plugins })
			}
		);
	};

	return (
		<Editor
			tools={[...tools]}
			contentStyle={{
				height: !props.height ? '630px' : props.height,
				width: !props.width ? '100%' : props.width
			}}
			defaultContent={props.defaultContent}
			ref={editorRef}
			value={editorData.currentContent}
			onChange={onChange}
			onBlur={onBlur}
			onPasteHtml={e => {
				let html = pasteCleanup(sanitize(e.pastedHtml), pasteSettings);
				return html;
			}}
			onMount={onMount}
		/>
	);
};
