import React from 'react';
import _ from 'lodash';
import { String } from 'typescript-string-operations';

import { FloatList } from './float-list';
import {
	apiService,
	authoritySettingService,
	DateUtilService,
	localizationService,
	optionsMap,
	Resource,
	urlService,
	validationService
} from 'src/services';
import {
	TextInput,
	SingleSelectDropdown,
	TextAreaInput,
	DateInput,
	ModalAttachments,
	ModalAttachmentState,
	ModalAttachmentService,
	DeleteModal,
	DeleteModalProp
} from 'src/components/widgets';

import {
	AttachmentType,
	AutoNumberDomainType,
	DropDownOption,
	FogFacility,
	Incident,
	Lookup,
	LookupType,
	IncidentContributor,
	IncidentContributorEntityType,
	FeatureNames,
	Dictionary
} from '@rcp/types';
import {
	ActionModalActionType,
	alertService,
	RootState,
	toggleActionModal,
	useReduxDispatch,
	useReduxSelector,
	useRootStateSelector
} from 'src/redux';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { incidentsSlice } from 'src/components/authority/fog/incidents';
import { isMobile } from 'react-device-detect';
import { faPlus, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseSvg, FacilitySvg } from 'src/components/svg';
import { DrawButtonSvg } from 'src/components/svg/draw-button.svg';
import NavigationPrompt from 'react-router-navigation-prompt';
import { CustomModal, CustomModalProp } from '../../modal/modal';
import { MapDataSortService } from 'src/services/mapDataSortService';
import { BatchInspectionOperationModal } from 'src/components/authority/fog/facilities/facility-details/batch-inspection-operation-modal';
import { IncidentBatchScheduleInspection } from 'src/components/authority/fog/incidents/incident-batch-schedule-inspection';

interface Props {
	handleClose: () => void;
	handleIncidentSaved: () => void;
	handleIncidentDeleted?: () => void;
	facilityRiskMapFooterOverlay: JSX.Element;
	toggleSelectFacilityForIncidentMode: (toggle: boolean, isKnownFacilities: boolean) => void;
	selectedKnownFacilities: FogFacility[];
	selectedSuspectedFacilities: FogFacility[];
	clearSelectedFacilities: () => void;
	resetIncidentContributorSelection: () => void;
	removeSelectedContributor: (contributor: IncidentContributor) => void;
	incident?: Incident;
	isAddPanel: boolean;
}
interface FormFields {
	incidentId?: number;
	incidentNumber?: string;
	address?: string;
	city?: string;
	latitude?: string;
	longitude?: string;
	reportedDate?: string;
	incidentTypeId?: number;
	incidentTypeCode?: string;
	incidentStatusId?: number;
	incidentStatusCode?: string;
	incidentSourceId?: number;
	incidentSourceCode?: string;
	assetId?: string;
	investigatorId?: number;
	investigatorFullName?: string;
	incidentDescription?: string;
	resolvedDate?: string;
	resolutionDescription?: string;

	incidentNumberError?: string;
	addressError?: string;
	cityError?: string;
	latitudeError?: string;
	longitudeError?: string;
	reportedDateError?: string;
	incidentTypeIdError?: string;
	incidentStatusIdError?: string;
	incidentSourceIdError?: string;
	incidentDescriptionError?: string;
}
const initialFormFields: FormFields = {
	incidentNumber: '',
	address: '',
	city: '',
	latitude: '',
	longitude: '',
	reportedDate: '',
	assetId: '',
	incidentDescription: ''
};

export const FloatAddIncident: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState(initialFormFields);
	const [fogIncidentTypeOptionValues, setFogIncidentTypeValues] = React.useState([] as DropDownOption[]);
	const [fogIncidentStatusOptionValues, setFogIncidentStatusValues] = React.useState([] as DropDownOption[]);
	const [fogIncidentSourceOptionValues, setFogIncidentSourceValues] = React.useState([] as DropDownOption[]);
	const [authorityUserOptions, setAuthorityUserOptions] = React.useState([] as DropDownOption[]);
	const [isSelectKnownFacilityMode, setIsSelectKnownFacilityMode] = React.useState(false);
	const [isSelectSuspectedFacilityMode, setIsSelectSuspectedFacilityMode] = React.useState(false);
	const { result: authorityUsers } = useSelector((state: RootState) => state.authorityUsers);
	const [isTouched, setIsTouched] = React.useState(false);
	const [showLeaveModal, setShowLeaveModal] = React.useState(false);
	const [deleteIncidentModal, setToggleDeleteIncidentModal] = React.useState(false);
	const [selectedKnownFacilities, setSelectedKnownFacilities] = React.useState<IncidentContributor[]>([]);
	const [selectedSuspectedFacilities, setSelectedSuspectedFacilities] = React.useState<IncidentContributor[]>([]);
	const existingIncidentContributorDict = React.useRef<Dictionary<IncidentContributor>>({});
	const dispatch = useReduxDispatch();

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);

		var validateResultSet = _.omit(newState, 'incidentNumber');
		if (_.every(validateResultSet, _.isEmpty)) {
			setIsTouched(false);
		} else {
			setIsTouched(true);
		}
	};
	const [modalAttachmentsState, setModalAttachmentState] = React.useState<ModalAttachmentState>({
		attachmentType: AttachmentType.FogIncident,
		ownerId: props.incident ? props.incident.incidentId : undefined,
		initialAttachments: [],
		currentAttachments: []
	});
	const modalAttachmentsService = new ModalAttachmentService(() => {
		return modalAttachmentsState;
	}, setModalAttachmentState);

	React.useEffect(() => {
		fetchFogIncidentTypes();
		fetchFogIncidentSources();
		fetchFogIncidentStatuses();

		if (props.isAddPanel) {
			authoritySettingService
				.getNextAutoNumber(AutoNumberDomainType.Incident)
				.then((nextIncidentAutoNumber: any) => {
					setFormState(previousState => {
						return { ...previousState, incidentNumber: nextIncidentAutoNumber };
					});
				});
		}
	}, []);

	React.useEffect(() => {
		if (props.selectedKnownFacilities.length > 0 || props.selectedSuspectedFacilities.length > 0) {
			updateSelectedFacilities();
		}
	}, [props.selectedKnownFacilities, props.selectedSuspectedFacilities]);

	React.useEffect(() => {
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);
			setAuthorityUserOptions(options);
		}
	}, [authorityUsers]);

	React.useEffect(() => {
		if (!_.isEmpty(selectedKnownFacilities) || !_.isEmpty(selectedSuspectedFacilities)) {
			setIsTouched(true);
		} else {
			setIsTouched(false);
		}
	}, [selectedKnownFacilities, selectedSuspectedFacilities]);

	React.useEffect(() => {
		if (props.incident) {
			const newState: FormFields = getNewFormFieldState(props.incident);
			setFormState(newState);
		}
	}, [props.incident]);

	React.useEffect(() => {
		const openIncidentStatusLookup = fogIncidentStatusOptionValues.find(x => x.label === 'Open');
		if (props.isAddPanel && openIncidentStatusLookup) {
			setFormState(previousState => {
				return { ...previousState, incidentStatusId: openIncidentStatusLookup.value };
			});
		}
	}, [fogIncidentStatusOptionValues]);

	const getNewFormFieldState = (incident: Incident) => {
		const newState: FormFields = { ...incident };
		const contributors = JSON.parse(incident.incidentContributorsJson!) as IncidentContributor[];
		setSelectedKnownFacilities(contributors.filter(contributor => contributor.isKnownContributor));
		setSelectedSuspectedFacilities(contributors.filter(contributor => !contributor.isKnownContributor));

		existingIncidentContributorDict.current = getContributorDictionary(contributors);
		return newState;
	};

	const updateSelectedFacilities = () => {
		if (isSelectKnownFacilityMode) {
			const knownFacilityDict = getContributorDictionary(selectedKnownFacilities);
			const newKnownContributingFacilities = props.selectedKnownFacilities.filter(
				f => !knownFacilityDict[f.facilityId!]
			);

			const suspectedFacilityDict = getContributorDictionary(selectedSuspectedFacilities);

			const overlapFacilities = newKnownContributingFacilities
				.filter(f => suspectedFacilityDict[f.facilityId!])
				.map(f => f.facilityId);

			setSelectedKnownFacilities([
				...selectedKnownFacilities,
				...mapFacilitiesToIncidentContributor(newKnownContributingFacilities, true)
			]);
			setSelectedSuspectedFacilities(
				selectedSuspectedFacilities.filter(f => !overlapFacilities.includes(f.entityId))
			);

			const contributorsMovedCount = overlapFacilities.length;

			if (contributorsMovedCount > 0) {
				const alertMessage = String.Format(
					localizationService.getLocalizedString('incidents.movedSuspectedContributorToKnownMessage'),
					contributorsMovedCount,
					contributorsMovedCount > 1
						? localizationService.getLocalizedString('incidents.facilities')
						: localizationService.getLocalizedString('incidents.facility')
				);
				alertService.addInfo(alertMessage);
			}
			props.clearSelectedFacilities();
		} else if (isSelectSuspectedFacilityMode) {
			const suspectedFacilityDict = getContributorDictionary(selectedSuspectedFacilities);

			const newSuspectedContributingFacilities = props.selectedSuspectedFacilities.filter(
				f => !suspectedFacilityDict[f.facilityId!]
			);

			const knownFacilityDict = getContributorDictionary(selectedKnownFacilities);

			const overlapFacilities = newSuspectedContributingFacilities
				.filter(f => knownFacilityDict[f.facilityId!])
				.map(f => f.facilityId!);

			setSelectedSuspectedFacilities([
				...selectedSuspectedFacilities,
				...mapFacilitiesToIncidentContributor(newSuspectedContributingFacilities, false)
			]);
			setSelectedKnownFacilities(selectedKnownFacilities.filter(f => !overlapFacilities.includes(f.entityId)));

			const contributorsMovedCount = overlapFacilities.length;

			if (contributorsMovedCount > 0) {
				const alertMessage = String.Format(
					localizationService.getLocalizedString('incidents.movedKnownContributorToSuspectedMessage'),
					contributorsMovedCount,
					contributorsMovedCount > 1
						? localizationService.getLocalizedString('incidents.facilities')
						: localizationService.getLocalizedString('incidents.facility')
				);
				alertService.addInfo(alertMessage);
			}
			props.clearSelectedFacilities();
		}
	};

	const getContributorDictionary = (contributors: IncidentContributor[]) => {
		return _.reduce(
			contributors,
			(acc, contributor) => ({ ...acc, [contributor.entityId!]: contributor }),
			{}
		) as Dictionary<IncidentContributor>;
	};

	const fetchFogIncidentTypes = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentType
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentTypes => {
				setFogIncidentTypeValues(optionsMap.fromLookups(fogIncidentTypes, undefined, undefined, true));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const fetchFogIncidentSources = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentSource
		}&includeInactive=true`;
		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentSources => {
				setFogIncidentSourceValues(optionsMap.fromLookups(fogIncidentSources, undefined, undefined, true));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const fetchFogIncidentStatuses = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentStatus
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentStatuses => {
				setFogIncidentStatusValues(optionsMap.fromLookups(fogIncidentStatuses, undefined, undefined, true));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const onPillRemove = (contributor: IncidentContributor) => {
		props.removeSelectedContributor(contributor);
		if (contributor.isKnownContributor) {
			setSelectedKnownFacilities(selectedKnownFacilities.filter(c => c.entityId !== contributor.entityId));
		} else {
			setSelectedSuspectedFacilities(
				selectedSuspectedFacilities.filter(c => c.entityId !== contributor.entityId)
			);
		}
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'incidentNumber',
			'incidentNumberError',
			localizationService.getLocalizedString('incidents.incidentNumber')
		);
		validationService.validateRequiredField(
			newState,
			'address',
			'addressError',
			localizationService.getLocalizedString('incidents.addressOrCrossStreet')
		);
		validationService.validateRequiredField(
			newState,
			'city',
			'cityError',
			localizationService.getLocalizedString('incidents.city')
		);
		let maxDate = DateUtilService.getAuthorityTimezoneNow();
		validationService.validateRequiredDateField(
			newState,
			'reportedDate',
			'reportedDateError',
			localizationService.getLocalizedString('incidents.reportedDate'),
			maxDate
		);
		validationService.validateRequiredField(
			newState,
			'incidentTypeId',
			'incidentTypeIdError',
			localizationService.getLocalizedString('incidents.type')
		);
		validationService.validateRequiredField(
			newState,
			'incidentStatusId',
			'incidentStatusIdError',
			localizationService.getLocalizedString('incidents.status')
		);
		validationService.validateRequiredField(
			newState,
			'incidentSourceId',
			'incidentSourceIdError',
			localizationService.getLocalizedString('incidents.source')
		);

		validationService.validateRequiredField(
			newState,
			'incidentDescription',
			'incidentDescriptionError',
			localizationService.getLocalizedString('incidents.description')
		);

		validationService.validateLatitudeField(newState, 'latitude', 'latitudeError', 'incidents.latitude', false);
		validationService.validateLongitudeField(newState, 'longitude', 'longitudeError', 'incidents.longitude', false);

		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			'incidentNumberError',
			'cityError',
			'addressError',
			'latitudeError',
			'longitudeError',
			'reportedDateError',
			'incidentTypeIdError',
			'incidentStatusIdError',
			'incidentSourceIdError',
			'incidentDescriptionError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return false;
		}

		if (!modalAttachmentsService.validateBeforeSave(modalAttachmentsState.currentAttachments)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			return false;
		}
		return true;
	};

	const mapFacilitiesToIncidentContributor = (facilities: FogFacility[], isKnownContributor: boolean) => {
		return facilities.map(f => ({
			entityId: f.facilityId,
			entityType: IncidentContributorEntityType.FogFacility.toString(),
			displayName: f.facilityName,
			incidentId: props.incident?.incidentId,
			incidentContributorId: existingIncidentContributorDict.current[f.facilityId!]?.incidentContributorId,
			isKnownContributor
		}));
	};

	const saveIncident = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let incidentToAddOrUpdate: Incident = {};

		incidentToAddOrUpdate.incidentId = formState.incidentId ? formState.incidentId : 0;
		incidentToAddOrUpdate.incidentNumber = formState.incidentNumber;
		incidentToAddOrUpdate.address = formState.address;
		incidentToAddOrUpdate.city = formState.city;
		incidentToAddOrUpdate.latitude = formState.latitude;
		incidentToAddOrUpdate.longitude = formState.longitude;
		incidentToAddOrUpdate.reportedDate = formState.reportedDate;
		incidentToAddOrUpdate.incidentTypeId = formState.incidentTypeId;
		incidentToAddOrUpdate.incidentStatusId = formState.incidentStatusId;
		incidentToAddOrUpdate.incidentSourceId = formState.incidentSourceId;
		incidentToAddOrUpdate.assetId = formState.assetId;
		incidentToAddOrUpdate.investigatorId = formState.investigatorId;
		incidentToAddOrUpdate.incidentDescription = formState.incidentDescription;
		incidentToAddOrUpdate.resolvedDate = formState.resolvedDate;
		incidentToAddOrUpdate.resolutionDescription = formState.resolutionDescription;

		let incidentContributors: IncidentContributor[] = [];
		if (selectedKnownFacilities) {
			incidentContributors = [...incidentContributors, ...selectedKnownFacilities];
		}
		if (selectedSuspectedFacilities) {
			incidentContributors = [...incidentContributors, ...selectedSuspectedFacilities];
		}
		incidentToAddOrUpdate.incidentContributorsJson = JSON.stringify(incidentContributors);

		if (props.isAddPanel) {
			dispatch(
				incidentsSlice.createOne(incidentToAddOrUpdate, true, undefined, (payload: any) => {
					let createdItems = payload.items as Incident[];
					if (createdItems.length > 0) {
						saveIncidentCallbackOnSuccess(createdItems[0], true);
					}
				})
			);
		} else {
			dispatch(
				incidentsSlice.patchOne(
					props.incident!.incidentId!,
					incidentToAddOrUpdate,
					true,
					undefined,
					updatedIncident => {
						saveIncidentCallbackOnSuccess(updatedIncident, false);
					}
				)
			);
		}
	};

	const saveIncidentCallbackOnSuccess = (savedIncident: Incident, isCreation: boolean) => {
		modalAttachmentsService.saveAttachments(savedIncident.incidentId as number, () => {
			alertService.addSuccess(
				localizationService.getLocalizedString(
					isCreation ? 'alertMessages.addSuccess' : 'alertMessages.updateSuccess',
					'incidents.incident'
				)
			);

			props.handleIncidentSaved();
		});
	};

	const resetIncidentForm = () => {
		setFormState(initialFormFields);
		props.resetIncidentContributorSelection();
	};

	const cancelSaveIncident = () => {
		setIsSelectKnownFacilityMode(false);
		setIsSelectSuspectedFacilityMode(false);
		resetIncidentForm();
		setIsTouched(false);
	};

	const onToggleDeleteIncidentModal = () => {
		setToggleDeleteIncidentModal(!deleteIncidentModal);
	};

	const onDeleteIncident = async () => {
		if (props.incident && props.incident.incidentId) {
			dispatch(
				incidentsSlice.deleteOne(
					props.incident.incidentId,
					true,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'incidents.incident'),
					() => {
						onToggleDeleteIncidentModal();
						if (props.handleIncidentDeleted) {
							props.handleIncidentDeleted();
						}
					}
				)
			);
		}
	};

	let deleteIncidentModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('incidents.deleteIncidentTitle'),
		message: localizationService.getLocalizedString(
			'incidents.deleteIncidentMessage',
			props.incident && props.incident.address ? props.incident.address : '',
			props.incident && props.incident.incidentNumber ? props.incident.incidentNumber : ''
		),
		showModal: deleteIncidentModal,
		onCancelButtonClick: onToggleDeleteIncidentModal,
		onOkayButtonClick: onDeleteIncident,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const getFooter = () => {
		return (
			<div
				className={`d-flex justify-content-center ${
					isMobile ? 'map-float-right-wrapper-footer' : 'map-float-right-wrapper-footer-fixed'
				}`}>
				{!props.isAddPanel && (
					<button
						type="button"
						className="btn ai-secondary-delete ml-3"
						onClick={onToggleDeleteIncidentModal}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}
				<button className="btn ai-save ml-auto mr-2" onClick={saveIncident}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</button>
				<button
					className="btn ai-white map-float-right-wrapper-footer-right-button"
					onClick={() => {
						cancelSaveIncident();
						handleClose();
					}}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		);
	};

	const onContributorsClicked = (isKnownFacilities: boolean) => {
		props.toggleSelectFacilityForIncidentMode(true, isKnownFacilities);
		if (isKnownFacilities) {
			setIsSelectKnownFacilityMode(true);
			setIsSelectSuspectedFacilityMode(false);
		} else {
			setIsSelectKnownFacilityMode(false);
			setIsSelectSuspectedFacilityMode(true);
		}
	};

	const handleClose = () => {
		props.handleClose();
		props.resetIncidentContributorSelection();
	};

	const handleSelectCancel = (isKnownFacilities: boolean) => {
		if (isKnownFacilities) {
			setIsSelectKnownFacilityMode(false);
		} else {
			setIsSelectSuspectedFacilityMode(false);
		}

		props.toggleSelectFacilityForIncidentMode(false, isKnownFacilities);
	};

	const getSelectDescription = () => {
		return (
			<span>
				{localizationService.getLocalizedString('incidents.selectFacilityDescription1')}
				<DrawButtonSvg />
				{localizationService.getLocalizedString('incidents.selectFacilityDescription2')}
			</span>
		);
	};

	const getFacilityPill = (contributor: IncidentContributor) => {
		return (
			<div className="facility-pill align-items-center" key={`ai-tag-${contributor.entityId}`}>
				<div className="pl-2">
					<FacilitySvg fillColor={'#666'} />
				</div>
				<div className="pl-2 facility-pill-body">{contributor.displayName}</div>
				<span
					className="close-facility-pill pl-2 pr-2 cursor-pointer"
					onClick={() => onPillRemove(contributor)}>
					<CloseSvg size="8" />
				</span>
			</div>
		);
	};

	const toggleLeaveModal = () => {
		setShowLeaveModal(!showLeaveModal);
	};

	let leaveModalProps: CustomModalProp = {
		showModal: showLeaveModal,
		onOkayButtonClick: () => {},
		onCancelButtonClick: toggleLeaveModal,
		title: localizationService.getLocalizedString('customForm.leavePageTitle'),
		message: localizationService.getLocalizedString('customForm.leavePageMessage'),
		okayButtonText: localizationService.getLocalizedString('customForm.buttons.leavePage')
	};

	const addIncidentCard = (): JSX.Element => {
		return (
			<div className={''}>
				<TextInput
					id="incidentNumber"
					name="incidentNumber"
					label={localizationService.getLocalizedString('incidents.incidentNumber')}
					value={formState.incidentNumber}
					onChange={changeFormState}
					isRequired={true}
					error={formState.incidentNumberError}
				/>
				<TextInput
					id="address"
					name="address"
					label={localizationService.getLocalizedString('incidents.addressOrCrossStreet')}
					value={formState.address}
					onChange={changeFormState}
					isRequired={true}
					error={formState.addressError}
				/>
				<TextInput
					id="city"
					name="city"
					label={localizationService.getLocalizedString('incidents.city')}
					value={formState.city}
					onChange={changeFormState}
					isRequired={true}
					error={formState.cityError}
				/>
				<div className="form-row">
					<TextInput
						id="latitude"
						name="latitude"
						type="number"
						className="form-group col-6"
						label={localizationService.getLocalizedString('incidents.latitude')}
						value={formState.latitude}
						onChange={changeFormState}
						error={formState.latitudeError}
						remainingInputProps={{ min: -90, max: 90 }}
					/>

					<TextInput
						id="longitude"
						name="longitude"
						type="number"
						className="form-group col-6"
						label={localizationService.getLocalizedString('incidents.longitude')}
						value={formState.longitude}
						onChange={changeFormState}
						error={formState.longitudeError}
						remainingInputProps={{ min: -180, max: 180 }}
					/>
				</div>
				<DateInput
					id="reportedDate"
					name="reportedDate"
					label={localizationService.getLocalizedString('incidents.reportedDate')}
					value={formState.reportedDate}
					error={formState.reportedDateError}
					isRequired={true}
					onChange={changeFormState}
					max={DateUtilService.getAuthorityTimezoneNow()}
				/>
				<SingleSelectDropdown
					id="incidentTypeId"
					name="incidentTypeId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.type')}
					value={formState.incidentTypeId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentTypeOptionValues}
					isRequired
					error={formState.incidentTypeIdError}
				/>
				<SingleSelectDropdown
					id="incidentStatusId"
					name="incidentStatusId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.status')}
					value={formState.incidentStatusId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentStatusOptionValues}
					isRequired
					error={formState.incidentStatusIdError}
				/>
				<SingleSelectDropdown
					id="incidentSourceId"
					name="incidentSourceId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.source')}
					value={formState.incidentSourceId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentSourceOptionValues}
					isRequired
					error={formState.incidentSourceIdError}
				/>
				<TextAreaInput
					id="incidentDescription"
					name="incidentDescription"
					label={localizationService.getLocalizedString('incidents.description')}
					value={formState.incidentDescription}
					onChange={changeFormState}
					isFullWidth={true}
					isRequired={true}
					error={formState.incidentDescriptionError}
				/>
				<TextInput
					id="assetId"
					name="assetId"
					label={localizationService.getLocalizedString('incidents.assetId')}
					value={formState.assetId}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="investigatorId"
					name="investigatorId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.investigatorId')}
					value={formState.investigatorId}
					onChange={changeFormState}
					selfOrder
					options={authorityUserOptions}
				/>
				{!props.isAddPanel && (
					<>
						<DateInput
							id="resolvedDate"
							name="resolvedDate"
							label={localizationService.getLocalizedString('incidents.resolvedDate')}
							value={formState.resolvedDate}
							onChange={changeFormState}
							max={DateUtilService.getAuthorityTimezoneNow()}
						/>

						<TextAreaInput
							id="resolutionDescription"
							name="resolutionDescription"
							label={localizationService.getLocalizedString('incidents.resolutionDescription')}
							value={formState.resolutionDescription}
							onChange={changeFormState}
							isFullWidth={true}
						/>
					</>
				)}

				<ModalAttachments
					service={modalAttachmentsService}
					className="form-group"
					attachments={modalAttachmentsState.currentAttachments}
					isNarrowView={true}
				/>
				<div className="form-row">
					<div className="form-group col-6 mb-0">
						<label>{localizationService.getLocalizedString('incidents.suspectedContributors')}:</label>
					</div>
					<div className="form-group col-6 mb-0">
						<div className="ml-auto text-right">
							{!isSelectSuspectedFacilityMode ? (
								<button
									id="btnSusContributors"
									className=""
									onClick={() => onContributorsClicked(false)}>
									<a href="#/" onClick={e => e.preventDefault()}>
										<FontAwesomeIcon icon={faPlus} size="xs" /> Add
									</a>
								</button>
							) : (
								<button
									id="btnSusContributorsClose"
									className=""
									onClick={() => handleSelectCancel(false)}>
									<CloseSvg size="10" />
								</button>
							)}
						</div>
					</div>
				</div>
				{isSelectSuspectedFacilityMode && (
					<div className="form-row">
						<div className="form-group col mb-0">{getSelectDescription()}</div>
					</div>
				)}
				<div className="form-row" id="suspectedContributorsContainer">
					{selectedSuspectedFacilities
						.sort((a, b) =>
							MapDataSortService.comparePossiblyUndefinedStrings(a.displayName, b.displayName)
						)
						.map((facility, index: number) => {
							return getFacilityPill(facility);
						})}
				</div>

				<div className="form-row mt-2">
					<div className="form-group col-6 mb-0">
						<label>{localizationService.getLocalizedString('incidents.knownContributors')}:</label>
					</div>
					<div className="form-group col-6 mb-0">
						<div className="ml-auto text-right">
							{!isSelectKnownFacilityMode ? (
								<button id="btnKnownContributors" onClick={() => onContributorsClicked(true)}>
									<a href="#/" onClick={e => e.preventDefault()}>
										<FontAwesomeIcon icon={faPlus} size="xs" /> Add
									</a>
								</button>
							) : (
								<button
									id="btnKnownContributorsClose"
									className=""
									onClick={() => handleSelectCancel(true)}>
									<CloseSvg size="10" />
								</button>
							)}
						</div>
					</div>
				</div>
				{isSelectKnownFacilityMode && (
					<div className="form-row">
						<div className="form-group col mb-0">{getSelectDescription()}</div>
					</div>
				)}
				<div className="form-row" id="knownContributorsContainer">
					{selectedKnownFacilities
						.sort((a, b) =>
							MapDataSortService.comparePossiblyUndefinedStrings(a.displayName, b.displayName)
						)
						.map((facility, index: number) => {
							return getFacilityPill(facility);
						})}
				</div>
				<IncidentBatchScheduleInspection
					rootStateSelector={s => s.actionModals.fogMapBatchScheduleInspectionsModal}
					actionModalState={ActionModalActionType.fogMapBatchScheduleInspectionsModal}
					selectedSuspectedFacilities={selectedSuspectedFacilities}
					selectedKnownFacilities={selectedKnownFacilities}></IncidentBatchScheduleInspection>
			</div>
		);
	};
	return (
		<>
			<FloatList
				title={
					props.isAddPanel
						? localizationService.getLocalizedString('incidents.addIncident')
						: localizationService.getLocalizedString('incidents.editIncident')
				}
				children={addIncidentCard()}
				handleClose={handleClose}
				facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
				showMobileTitle={true}
				footer={getFooter()}
				hasFixedFooter={true}
			/>
			<NavigationPrompt when={isTouched} allowGoBack={false}>
				{({ onConfirm, onCancel }) => (
					<CustomModal
						{...leaveModalProps}
						showModal={true}
						onCancelButtonClick={onCancel}
						onOkayButtonClick={onConfirm}
					/>
				)}
			</NavigationPrompt>
			<DeleteModal {...deleteIncidentModalProp} key="deleteIncidentModal" />
		</>
	);
};
