import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { ColumnDefinition, Token, OrganizationTypeName, AcpDomain } from '@rcp/types';
import { MfaDetails } from '@rcp/types/src';
import _ from 'lodash';
import React, { createRef, FC, RefObject, useState } from 'react';
import { useEffect } from 'react';
import { localizationService, localStorageService, tokenService, urlService } from 'src/services';
import { SearchInput } from '../widgets';

interface Props {
	gridData: any[];
	accessToken: string;
	expirationEpochTimeInUtc: number;
	refreshToken: string;
	correlationId?: string;
	tokenType?: string;
	tokenProvider?: string;
	userName: string;
	email: string;
	ssoFrom?: string;
	type: string;
	mfaDetails: MfaDetails[];
}
const { Industry } = OrganizationTypeName;
const PortalDirectorGrid: FC<Props> = (props: Props) => {
	const gridRef: RefObject<Grid> = createRef();
	const [pagination, setPagination] = useState({ skip: 0, take: 10 });
	const [sort, setSort] = useState([{ field: 'organizationName', dir: 'asc' }] as SortDescriptor[]);
	const [data, setData] = useState([] as any);

	useEffect(() => {
		setData([...props.gridData]);
	}, [props.gridData.length]);

	let columnsKeys = ['organizationName', 'organizationTypeName'];

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(columnsKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.accountPortal.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (String.equalCaseInsensitive(i.field, 'organizationName')) {
					return (
						<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={nameToLink} />
					);
				}

				return (
					<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={localizeCellValue} />
				);
			});
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	const getForwardToPortalUrl = (portal: any) => {
		if (_.isEmpty(portal.userIssuedPrograms)) {
			throw new Error('Server should always return an existing forward subdomain plus one allowed program');
		}
		let userIssuedRegulatoryProgramNames = portal.userIssuedPrograms
			.map((r: any) => r.regulatoryProgramName)
			.join(',');
		let userIssuedPermissionGroupNames = portal.userIssuedPrograms.map((r: any) => r.permissionGroupName).join(',');
		let organizationRegulatoryProgramId = portal.organizationRegulatoryProgramId;
		let mfaEnabledPrograms = urlService.getMfaEnabledPrograms(props.mfaDetails);
		let existingToken = tokenService.getTokenOrDefault();
		let token: Token = {
			accessToken: existingToken.accessToken,
			expirationEpochTimeInUtc: props.expirationEpochTimeInUtc as number,
			refreshToken: props.refreshToken as string,
			correlationId: props.correlationId as string,
			tokenType: props.tokenType,
			tokenProvider: props.tokenProvider,
			portalOrganizationId: portal.organizationId,
			portalOrganizationName: portal.organizationName,
			portalOrganizationTypeId: portal.organizationTypeId,
			portalOrganizationTypeName: portal.organizationTypeName,
			portalRegulatoryProgramNames: userIssuedRegulatoryProgramNames,
			portalPermissionGroupNames: userIssuedPermissionGroupNames,
			portalSubDomain: portal.subDomain,
			portalOrganizationRegulatoryProgramId: organizationRegulatoryProgramId,
			userName: props.userName,
			aqiIdpTenantId: portal.aqiIdpTenantId,
			email: props.email,
			ssoFrom: props.ssoFrom,
			ianaTimeZoneName: portal.ianaTimeZoneName,
			mfaEnabledPrograms: mfaEnabledPrograms,
			guid: portal.guid,
			language: existingToken.language
		};
		localStorageService.setLastProgram(props.type);
		return urlService.getLandingPortalUrl(token, undefined, props.type);
	};

	const nameToLink = (item: any) => {
		const {
			organizationName,
			organizationTypeName,
			userIssuedPrograms,
			organizationRegulatoryProgramId
		} = item.dataItem;
		let modifiedOrganizationName = organizationName;
		if (String.equalCaseInsensitive(organizationTypeName, Industry)) {
			let referenceNumber = userIssuedPrograms.filter(
				(program: any) =>
					Number(program.organizationRegulatoryProgramId) === Number(organizationRegulatoryProgramId)
			)[0].referenceNumber;
			if (referenceNumber) {
				modifiedOrganizationName += ` (${referenceNumber})`;
			}
		} else {
			if (item.dataItem.isInternal === true) {
				modifiedOrganizationName += ` (Internal)`;
			}
		}
		let landingPortalUrl = getForwardToPortalUrl(item.dataItem);
		return (
			<td>
				<a href={landingPortalUrl} className="ai-link cursor-pointer">
					{modifiedOrganizationName}
				</a>
			</td>
		);
	};

	const pageChange = (event: any) => {
		setPagination({
			skip: event.page.skip,
			take: event.page.take
		});
	};

	const onSearch = (searchTerm: string) => {
		let gridData = _.cloneDeep(props.gridData);
		const isContains = (searchTerm: string, searchString?: any) => {
			return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
		};
		gridData = gridData.filter((portal: any) => {
			let searchString = portal.organizationName + '';
			if (String.equalCaseInsensitive(portal.organizationTypeName, Industry)) {
				let referenceNumber = portal.userIssuedPrograms.filter(
					(program: any) =>
						Number(program.organizationRegulatoryProgramId) ===
						Number(portal.organizationRegulatoryProgramId)
				)[0].referenceNumber;
				if (referenceNumber) {
					searchString += ` (${referenceNumber})`;
				}
			} else if (portal.isInternal) {
				searchString += ` (Internal)`;
			}
			if (isContains(searchTerm.trim(), searchString)) {
				return true;
			}
			return false;
		});
		setData(gridData);
	};

	let columnsDefinitions = getColumnDefinitions();
	let columns = generateColumns(columnsDefinitions);

	return (
		<>
			<span className="d-flex mb-2">
				<SearchInput inputId={props.type + '-search'} ariaLabel="Search" search={onSearch} />
			</span>
			<Grid
				className="table"
				ref={gridRef}
				scrollable="none"
				data={data && process(data, { sort, ...pagination })}
				skip={pagination.skip}
				take={pagination.take}
				total={data && data.length}
				pageable={true}
				onPageChange={pageChange}
				sortable
				sort={sort}
				onSortChange={e => {
					setSort(e.sort);
				}}>
				{columns}
			</Grid>
		</>
	);
};

export default PortalDirectorGrid;
