import classnames from 'classnames';
import * as React from 'react';
import { StepperContext } from './context';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const StepperAction: React.FunctionComponent<Props> = ({ disabled = false, type, className, children, ...props }) => {
	const { isLoading } = React.useContext(StepperContext);

	return (
		<button
			{...props}
			type={type}
			disabled={isLoading() || disabled}
			className={classnames('stepper__action', className)}>
			<span>{children}</span>
		</button>
	);
};

export default StepperAction;
