import React from 'react';

import { LookupType, AutoNumberDomainType } from '@rcp/types';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { localizationService, urlService } from 'src/services';
import { LookupSetting } from '../../../../widgets/lookup';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { AutoNumberSetting } from '../../../shared/settings/auto-number';

const AuthoritySettingsIncidentsComponent: React.FunctionComponent = () => {
	return (
		<div className="page">
			<div className="page-header">
					<BackArrow />
				<h1>{localizationService.getLocalizedString('authoritySetting.incidents')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">
					{urlService.isAdministrator() && (
					<>
						<AutoNumberSetting
							title={localizationService.getLocalizedString(
								'authoritySetting.autoNumberForIncidentsSection'
							)}
							autoNumbersLabel={localizationService.getLocalizedString(
								'authoritySetting.incidentNumbers'
							)}
							autoNumberType={AutoNumberDomainType.Incident}
						/>
						<hr />
					</>)}
					<LookupSetting
						title={localizationService.getLocalizedString('authoritySetting.incidentSourcesSection')}
						description={localizationService.getLocalizedString(
							'authoritySetting.incidentSourcesSectionDescription'
						)}
						settingId="LookupType.FogIncidentSource"
						settingLabel={localizationService.getLocalizedString('authoritySetting.incidentSource')}
						lookupType={LookupType.FogIncidentSource}
					/>
					<hr />
					<LookupSetting
						title={localizationService.getLocalizedString('authoritySetting.incidentStatusesSection')}
						description={localizationService.getLocalizedString(
							'authoritySetting.incidentStatusesSectionDescription'
						)}
						settingId="LookupType.FogIncidentStatus"
						settingLabel={localizationService.getLocalizedString('authoritySetting.incidentStatus')}
						lookupType={LookupType.FogIncidentStatus}
					/>
					<hr />
					<LookupSetting
						title={localizationService.getLocalizedString('authoritySetting.incidentTypesSection')}
						description={localizationService.getLocalizedString(
							'authoritySetting.incidentTypesSectionDescription'
						)}
						settingId="LookupType.FogIncidentType"
						settingLabel={localizationService.getLocalizedString('authoritySetting.incidentType')}
						lookupType={LookupType.FogIncidentType}
					/>
				</div>
			</div>
		</div>
	);
};

export const AuthoritySettingsIncidents = AuthoritySettingsIncidentsComponent;
