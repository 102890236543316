import React from 'react';
import { CleaningEvent, Dictionary, FeatureNames, HaulerDeviceCleaning, OrganizationTypeName } from '@rcp/types';
import { DateUtilService, localizationService } from '../../../../services';
import { CleaningModal } from './cleaning-modal';
import HaulerSubmitCleaningModal from '../../../service-provider/hauler/hauler-facilities/hauler-submit-cleaning/submit-cleaning';
import FacilitySubmitCleaningModal from 'src/components/service-provider/facility/submit-cleaning.tsx/submit-facility-cleaning';
import { SeattleAuthorityConstants } from 'src/constants';
import { useReduxSelector } from 'src/redux';
import { FaPaperclip } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface OwnProps {
	pumpOutEvent: CleaningEvent | HaulerDeviceCleaning;
	dateField?: string;
	queryParameters?: Dictionary<string>;
	isTriggeredFromViolation?: boolean;
	isTriggeredFromHasAttachmentLink?: boolean;
	hasAttachment?: boolean;
	violationText?: string;
}
const { Facility, Hauler, Authority } = OrganizationTypeName;
export const CleaningEditActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowCustomCleaningFormsFeature]
	);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	const getCleaningModal = () => {
		if (String.equalCaseInsensitive(props.pumpOutEvent.creatorOrganizationType, Authority)) {
			return (
				<CleaningModal
					facilityId={props.pumpOutEvent.organizationId as number}
					showModal={showModal}
					isEnterCleaning={true}
					isCleaningGrid={true}
					cleaning={props.pumpOutEvent as CleaningEvent}
					isGridPage={true}
					isFacilityPage={false}
					isTimelineReloadRequired={false}
					dueDateIsRequired={props.pumpOutEvent.completeDate ? false : true}
					completeDateIsRequired={props.pumpOutEvent.completeDate ? true : false}
					modalToggleFunction={toggleModal}
					queryParameters={props.queryParameters}
				/>
			);
		} else if (String.equalCaseInsensitive(props.pumpOutEvent.creatorOrganizationType, Hauler)) {
			return (
				<HaulerSubmitCleaningModal
					showModal={showModal}
					onCancel={toggleModal}
					selectedDevices={[]}
					deviceList={[]}
					cleaningEventId={props.pumpOutEvent.pumpOutEventId}
					facilityId={props.pumpOutEvent.organizationId}
					isFacilityDetailsPage={false}
					isEditMode={showCustomCleaningFormsFeature && !!props.pumpOutEvent.templateJson}
					readOnly={!showCustomCleaningFormsFeature || !props.pumpOutEvent.templateJson}
					authorityOrganizationId={props.pumpOutEvent.authorityOrganizationId}
					title={localizationService.getLocalizedString('haulerPortal.cleaningHistory.submittedCleaning')}
					isSeattleFacility={
						props.pumpOutEvent.facilityAuthorityOrganizationName
							? String.equalCaseInsensitive(
									props.pumpOutEvent.facilityAuthorityOrganizationName,
									SeattleAuthorityConstants.authorityName
							  )
							: false
					}
				/>
			);
		} else if (String.equalCaseInsensitive(props.pumpOutEvent.creatorOrganizationType, Facility)) {
			return (
				<FacilitySubmitCleaningModal
					title={localizationService.getLocalizedString('haulerPortal.submitCleaning.submittedCleaning')}
					showModal={showModal}
					onCancel={toggleModal}
					isEditMode={showCustomCleaningFormsFeature && props.pumpOutEvent.templateJson}
					readOnly={!showCustomCleaningFormsFeature || !props.pumpOutEvent.templateJson}
					facilityId={props.pumpOutEvent.organizationId}
					isFacilityDetailsPage={false}
					showDeleteButton
					cleaningEventId={props.pumpOutEvent.pumpOutEventId}
				/>
			);
		}
	};

	return (
		<>
			{props.isTriggeredFromHasAttachmentLink ? (
				props.hasAttachment ? (
					<a href="#/" className="ai-link" id="has-attachment-link" onClick={onEditClicked}>
						<FaPaperclip />
					</a>
				) : (
					<></>
				)
			) : props.isTriggeredFromViolation ? (
				<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
					{props.violationText}
				</a>
			) : props.pumpOutEvent.completeDate ? (
				<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
					{formatDate(props.dateField)}
				</a>
			) : (
				<a href={'#/'} className="btn ai-white" onClick={onEditClicked}>
					{localizationService.getLocalizedString(`pumpOut.editCleaning`)}
				</a>
			)}
			{showModal && getCleaningModal()}
		</>
	);
};
