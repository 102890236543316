import React, { useRef, useEffect, useState, useContext } from 'react';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import {
	apiService,
	authoritySettingService,
	localizationService,
	Logger,
	Resource,
	urlService,
	validationService
} from 'src/services';
import _ from 'lodash';
import { TextInput, SingleSelectDropdown } from 'src/components/widgets';
import {
	SendFacilityNoticeSteps,
	FacilityNoticeTemplateSchedule,
	NoticeTemplateType,
	MarginUnits,
	printMarginUnitOptions,
	Dictionary,
	LetterTemplateTranslation
} from '@rcp/types';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import './steps.scss';
import { RootState } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import { generalNoticeTemplateSlice } from '../../../settings/notices/general-notice-template.slice';
import { Utils } from '../../../../../../services/utils';
import { DoNotTranslateButton } from 'src/components/widgets/do-not-translate-button';
import authoritySetting from 'src/components/authority/ipp/authority-account/settings/authority-setting';
import { LetterTemplateTranslations } from '../../../settings/notices';

export interface FormField extends FacilityNoticeTemplateSchedule {
	templateSubjectError?: string;
	templateContentError?: string;
}

const initialFormField: FormField = {
	templateBodyContent: '',
	templateHeaderContent: '',
	templateFooterContent: '',
	templateContent: '',
	templateSubject: '',
	marginLeft: 1,
	marginRight: 1,
	marginBottom: 1,
	marginTop: 1,
	printMarginUnit: MarginUnits.Inches,
	templateSubjectError: '',
	templateContentError: ''
};

const { STEP3, STEP2, STEP1 } = SendFacilityNoticeSteps;

export const Step3 = () => {
	const dispatch = useDispatch();
	const stepperContext = useContext(StepperContext);
	const step1Data = stepperContext.getData(STEP1);
	const [formField, setFormField] = useState<FormField>(initialFormField);
	const [noticeContent, setNoticeContent] = useState(initialFormField);
	const [letterTemplateTranslations, setLetterTemplateTranslations] = useState<Dictionary<LetterTemplateTranslation>>(
		{}
	);
	const [subjectPointerStartPosition, setSubjectPointerStartPosition] = useState(0);
	const [subjectPointerEndPosition, setSubjectPointerEndPosition] = useState(0);

	const nextButtonClickedRef = useRef<any>(null);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);
	const noticeContentRef = useRef<any>(null);
	noticeContentRef.current = !Utils.isDeviceCleaningNotice(step1Data.noticeType) ? { ...formField } : noticeContent;
	const step3Data = stepperContext.getData(STEP3);
	const updatedStep1 = { ...stepperContext.getStep(STEP1), data: { ...step1Data, isDataChanged: false } };

	let generalNoticeTemplateSchedule = (state: RootState) => state.generalNoticeTemplate;
	let { selected: generalNoticeTemplate } = useSelector(generalNoticeTemplateSchedule);

	const fetchNoticeTemplate = () => {
		if (!Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			dispatch(generalNoticeTemplateSlice.fetchOne(step1Data.letterTemplateId));
		}
		if (Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${
				Resource.CleaningNoticeTemplateSchedules
			}/${step1Data.noticeTemplateId}`;
			apiService.getResource(url, true).then((data: any) => {
				setNoticeContent(data);
			});
		}
	};

	useEffect(() => {
		if (step1Data.isDataChanged) {
			fetchNoticeTemplate();
		} else if (step3Data && step3Data.noticeContent) {
			setNoticeContent(step3Data.noticeContent);
		} else {
			fetchNoticeTemplate();
		}

		return () => {
			!nextButtonClickedRef.current && updateStepOnUnmount();
			stepperContext.updateStep(STEP1, updatedStep1);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			step1Data.isDataChanged
				? setFormField({ ...formField, ...generalNoticeTemplate })
				: step3Data
				? setFormField(step3Data)
				: setFormField({ ...formField, ...generalNoticeTemplate });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalNoticeTemplate]);

	const updateStepOnUnmount = () => {
		let dataToBeSaved = noticeContentRef.current;
		const updatedStep3 = {
			...stepperContext.getStep(STEP3),
			data: { noticeContent: dataToBeSaved }
		};
		stepperContext.updateStep(STEP3, updatedStep3);
	};

	const isFormValid = () => {
		if (!Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			return validationService.validateMergeFields(
				(formField.templateBodyContent || '') +
					(formField.templateFooterContent || '') +
					(formField.templateHeaderContent || ''),
				mergeFields
			);
		} else {
			return (
				validationService.validateMergeFields(noticeContent.templateContent || '', mergeFields) &&
				validationService.validateDoNotTranslateFields(noticeContent.templateSubject || '') &&
				validationService.validateMergeFields(noticeContent.templateSubject || '', mergeFields) &&
				validationService.validateDoNotTranslateFields(noticeContent.templateContent || '')
			);
		}
	};

	const onClickNext = () => {
		nextButtonClickedRef.current = true;
		if (!isFormValid()) {
			return;
		}
		if (!Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			stepperContext.resolve({ ...formField });
		} else {
			let newState = { ...noticeContent };
			newState.letterTemplateTranslationsJson = JSON.stringify(letterTemplateTranslations);
			Logger.info(
				`Facility send ad-hoc cleaning notice with translations:`,
				newState.letterTemplateTranslationsJson
			);
			stepperContext.resolve({ noticeContent: newState });
		}
	};

	const onClickBack = () => stepperContext.goAt(STEP2);

	const changeFormField = (e: any) => {
		let newState = { ...formField };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setFormField(newState);
	};

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		Utils.onChangeMergeFieldHandler(
			e,
			noticeContent,
			subjectPointerStartPosition,
			subjectPointerEndPosition,
			setSubjectPointerStartPosition,
			setSubjectPointerEndPosition,
			setNoticeContent
		);
	};

	const onClickDoNotTranslateButton = () => {
		Utils.onClickDoNotTranslate(
			noticeContent,
			subjectPointerStartPosition,
			subjectPointerEndPosition,
			setSubjectPointerStartPosition,
			setSubjectPointerEndPosition,
			setNoticeContent
		);
	};

	const [hasLanguageSupport, setHasLanguageSupport] = useState(false);

	useEffect(() => {
		authoritySettingService.getAuthorityLanguageSupportStatus(setHasLanguageSupport);
	}, [hasLanguageSupport]);

	const deviceCleaningTemplateEditor = () => (
		<>
			<div className="form-row">
				<div className="col-lg-8">
					<TextInput
						id="templateSubject"
						name="templateSubject"
						value={noticeContent.templateSubject}
						onChange={e => {
							const { name, value } = e.target;
							setNoticeContent({ ...noticeContent, [name]: value });
						}}
						label={localizationService.getLocalizedString('extractor.cleaningNotice.subject')}
						onBlur={(e: any) => {
							setSubjectPointerStartPosition(e.target.selectionStart);
							setSubjectPointerEndPosition(e.target.selectionEnd);
						}}
					/>
				</div>
				<div className="col-lg-4">
					<MergeFieldDropDown
						id="mergeField"
						name="mergeField"
						onChange={onChangeMergeField}
						hintText={localizationService.getLocalizedString('extractor.cleaningNotice.mergeTagsHint')}
						label={localizationService.getLocalizedString('extractor.cleaningNotice.mergeTags')}
					/>
					<DoNotTranslateButton
						disabled={
							subjectPointerStartPosition == subjectPointerEndPosition ||
							(noticeContent.templateSubject != null &&
								subjectPointerEndPosition > noticeContent.templateSubject.length)
						}
						onClick={onClickDoNotTranslateButton}
						hasLanguageSupport={hasLanguageSupport}
					/>
				</div>
			</div>
			<div className="form-group required">
				<label>{localizationService.getLocalizedString('extractor.cleaningNotice.body')}</label>
				<div className="cleaning-notice-editor-template">
					<RichTextEditor
						editorContent={noticeContent.templateContent}
						noticeTemplateType={NoticeTemplateType.FogCleaningNotice}
						onChange={(event: any) => {
							setNoticeContent({ ...noticeContent, templateContent: event.html });
						}}
						height="440px"
						isLanguageSupportEnabled={true}
					/>
				</div>
			</div>
			{hasLanguageSupport && (
				<LetterTemplateTranslations
					noticeTemplate={noticeContent}
					letterTemplateTranslationsJson={noticeContent.letterTemplateTranslationsJson}
					letterTemplateTranslations={letterTemplateTranslations}
					setLetterTemplateTranslations={setLetterTemplateTranslations}
				/>
			)}
		</>
	);

	const generalTemplateEditor = () => (
		<>
			<div className="facility-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.header')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateHeaderContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateHeaderContent: event.html });
					}}
					height="80px"
					isFixedHeight
					editorIndex={0}
					showTableTools={true}
				/>
			</div>
			<div className="facility-notice-editor-template form-group required general-notice-body">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.body')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateBodyContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateBodyContent: event.html });
					}}
					isLetterTemplate
					height="470px"
					showTableTools={true}
					hidePageNumber
				/>
			</div>
			<div className="facility-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.footer')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateFooterContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateFooterContent: event.html });
					}}
					height="60px"
					editorIndex={2}
					isFixedHeight
					showTableTools={true}
				/>
			</div>
			<div>
				<p>{localizationService.getLocalizedString('authoritySetting.noticesSettings.printMargins')}</p>
				<div className="row align-items-center">
					<TextInput
						id="marginTop"
						name="marginTop"
						className="col"
						type="number"
						onChange={changeFormField}
						value={formField.marginTop}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.top')}
					/>

					<TextInput
						id="marginBottom"
						name="marginBottom"
						className="col"
						type="number"
						value={formField.marginBottom}
						onChange={changeFormField}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.bottom')}
					/>

					<TextInput
						id="marginLeft"
						name="marginLeft"
						value={formField.marginLeft}
						type="number"
						className="col"
						onChange={changeFormField}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.left')}
					/>

					<TextInput
						id="marginRight"
						name="marginRight"
						value={formField.marginRight}
						onChange={changeFormField}
						className="col"
						type="number"
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.right')}
					/>

					<SingleSelectDropdown
						id="marginUnit"
						name="printMarginUnit"
						onChange={changeFormField}
						className="col"
						noEmptyOption={true}
						value={formField.printMarginUnit}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.units')}
						options={printMarginUnitOptions}
					/>
				</div>
			</div>
		</>
	);

	return (
		<StepperContent
			className="w-100"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			{Utils.isDeviceCleaningNotice(step1Data.noticeType)
				? deviceCleaningTemplateEditor()
				: generalTemplateEditor()}
		</StepperContent>
	);
};
