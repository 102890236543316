import React, { useState, FC } from 'react';
import { localizationService, validationService, apiService, urlService, Resource } from 'src/services';
import { TextInput } from 'src/components/widgets';
import EncryptedData from './encrypted-data';
import _ from 'lodash';
import { alertService } from 'src/redux';

interface ApplicationUserFormState {
	username: string;
	password: string;
	passwordFormatError: string;
	usernameRequiredError: string;
}

interface EncryptedCredentials {
	username: string;
	password: string;
}

const IppApplicationUserSettings: FC<{ copyToClipBoard: (event: any, textToCopy: string) => void }> = props => {
	const [formState, setFormState] = useState({} as ApplicationUserFormState);
	const [encryptedResponse, setEncryptedResponse] = useState<EncryptedCredentials>({
		username: '',
		password: ''
	});
	let [showEncryptedResponse, setShowEncryptedResponse] = useState(false);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validatePasswordField(newState, 'password', 'passwordFormatError');
		validationService.validateRequiredField(newState, 'username', 'usernameRequiredError');

		let isFormValid = !validationService.hasError(newState, 'passwordFormatError', 'usernameRequiredError');
		setFormState(newState);
		return isFormValid;
	};

	const encryptCredentials = async () => {
		if (!validateBeforeForSave()) {
			return;
		} else {
			try {
				let url = `${urlService.getAuthorityResourcesApiUrl(
					Resource.IppAcpConnectorSettings
				)}/GenerateHashedApplicationUser`;
				let data = await apiService.httpPost(url, {
					userName: formState.username,
					password: formState.password
				});
				setEncryptedResponse({ ...data });
				setShowEncryptedResponse(true);
			} catch (err) {
				alertService.addError(err.message);
			}
		}
	};

	return (
		<div className="row">
			<div className="col-lg-5">
				<strong id="user-account-subheading">
					{localizationService.getLocalizedString(
						'ipp.authorityAcpConnector.applicationUserSettings.heading'
					)}
				</strong>
				<p id="user-account-information">
					{localizationService.getLocalizedString(
						'ipp.authorityAcpConnector.applicationUserSettings.description'
					)}
				</p>
			</div>
			<div className="col-lg-7">
				<section className="mb-0">
					<div className="d-flex align-items-center flex-column">
						<TextInput
							id="acp-user-username"
							name="username"
							className="col-sm-12 p-0"
							label={localizationService.getLocalizedString(
								'ipp.authorityAcpConnector.applicationUserSettings.username'
							)}
							onChange={changeFormState}
							value={formState.username}
							isRequired={true}
							error={formState.usernameRequiredError}
						/>

						<TextInput
							id="acp-user-password"
							name="password"
							type="password"
							className="col-sm-12 p-0"
							label={localizationService.getLocalizedString(
								'ipp.authorityAcpConnector.applicationUserSettings.password'
							)}
							onChange={changeFormState}
							value={formState.password}
							isRequired={true}
							error={formState.passwordFormatError}
							helpText={localizationService.getLocalizedString('users.passwordHint')}
						/>
					</div>
					<button className="btn ai-action mr-3" id="encrypt" onClick={encryptCredentials}>
						{localizationService.getLocalizedString('ipp.buttons.encrypt')}
					</button>
					{showEncryptedResponse && (
						<EncryptedData
							title={localizationService.getLocalizedString(
								'ipp.authorityAcpConnector.applicationUserSettings.copyPasteData'
							)}
							username={
								<>
									<span className="pl-5">
										{`"${localizationService.getLocalizedString(
											'ipp.authorityAcpConnector.applicationUserSettings.hashedUsername'
										)}":   `}
									</span>
									<span className="pr-1">{`"${encryptedResponse.username}",`}</span>
								</>
							}
							password={
								<>
									<span className="pl-5">
										{`"${localizationService.getLocalizedString(
											'ipp.authorityAcpConnector.applicationUserSettings.hashedPassword'
										)}":   `}
									</span>
									<span className="pr-1"> {`"${encryptedResponse.password}"`}</span>
								</>
							}
							isUserSettings={true}
							copyToClipBoard={props.copyToClipBoard}
						/>
					)}
				</section>
			</div>
		</div>
	);
};

export default IppApplicationUserSettings;
