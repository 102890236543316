import React, { FC, useState } from 'react';
import { PopoverModal, SingleCheckbox, SingleSelectDropdown, ComboBoxDropdown } from '../../../../components/widgets';
import { apiService, localizationService, Logger, navigateTo, Resource, urlService } from 'src/services';
import _ from 'lodash';
import {
	ApiError,
	Dictionary,
	DropDownOption,
	FieldUpdatePolicy,
	ImportEntity,
	ImportField,
	ImportFieldMapping,
	emptyOption
} from '@rcp/types';
import { findBestMatch } from 'string-similarity';
import { useHistory } from 'react-router-dom';
import { alertService } from 'src/redux';
import { DataImport } from '@rcp/types/src/import';

export interface ConfigurationEditorProp {
	title: string;
	column: string;
	importConfigurationId: number;
	importFileId: string;
	targetFields?: DataImport.ImportField[] | ImportField[];
	isConfigurationEditable: boolean;
	isReadOnlyConfiguration: boolean;
	fieldMapping: ImportFieldMapping;
	fieldMappings: ImportFieldMapping[];
	fieldOptions: DropDownOption[];
	onClosed: () => void;
	saveFieldMapping: (fieldMapping: ImportFieldMapping, columnId: string) => Promise<void>;
}

const FieldMappingEditor: FC<ConfigurationEditorProp> = props => {
	const [fieldMapping, setFieldMapping] = useState<ImportFieldMapping>(props.fieldMapping);
	const [selectedFieldOption, setSelectedFieldOption] = useState<DropDownOption>(emptyOption);
	const [displayLookupTranslation, setDisplayLookupTranslation] = useState(false);
	const [lookupData, setLookupData] = useState<DropDownOption[]>([]);
	const [selectedLookUpTranslation, setSelectedLookUpTranslation] = useState<string | undefined>();
	const [unrecognizedValues, setUnrecognizedValues] = useState<string[] | undefined>([]);
	const [translationMap, setTranslationMap] = useState<Dictionary<string>>({});
	const history = useHistory();
	const setBestGuessFieldOptionByDisplayLabel = () => {
		let labels = props.fieldOptions.map(o => o.label);
		let bestMatches = findBestMatch(fieldMapping.importColumnName, labels);
		if (bestMatches.bestMatch.rating < 0.7) {
			Logger.debug('bestMatch does not exceed our similarity limit 0.7:', bestMatches.bestMatch);
			return;
		}
		let bestMatchLabel = bestMatches.bestMatch.target;
		let bestMatchOption = _.find(props.fieldOptions, o => o.label === bestMatchLabel);
		if (bestMatchOption) {
			setSelectedFieldOption(bestMatchOption);
			handleLookupDefaultValueOrTranslation(bestMatchOption);
		}
	};
	const setBestGuessFieldOption = () => {
		if (_.toNumber(props.importConfigurationId) > 0) {
			setBestGuessFieldOptionByDisplayLabel();
		}
	};

	React.useEffect(() => {
		if (_.isEmpty(props.fieldMapping) || _.isEmpty(props.fieldOptions)) {
			return;
		}

		setFieldMapping(props.fieldMapping);
		if (props.fieldMapping.translationMapInJson) {
			var newTransMap = JSON.parse(props.fieldMapping.translationMapInJson) as Dictionary<string>;
			if (unrecognizedValues) {
				// eslint-disable-next-line array-callback-return
				unrecognizedValues.map(v => {
					if (!_.find(newTransMap.keys, v)) {
						newTransMap[v] = ''; // if there is unrecognized value without translation, then initialized with empty string
					}
				});
			}
			setTranslationMap(newTransMap);
		}
		if (props.fieldMapping.importFieldMappingId || _.toNumber(props.importConfigurationId) < 1) {
			let newSelectedOption: DropDownOption = {
				value: props.fieldMapping.fieldName as string,
				label: props.fieldMapping.fieldLabel as string
			};
			setSelectedFieldOption(newSelectedOption);
			handleLookupDefaultValueOrTranslation(newSelectedOption);
			return;
		}

		if (_.isEmpty(props.fieldMapping.fieldName)) {
			setBestGuessFieldOption();
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fieldMapping, props.fieldOptions]);

	const onSaveClicked = async () => {
		props.saveFieldMapping(fieldMapping, props.column);
		onClose();
	};

	const onClose = () => {
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const onCancelClicked = () => {
		onClose();
	};

	// eslint-disable-next-line
	const fieldTypeOptions: DropDownOption[] = [
		{ label: localizationService.getLocalizedString('cccImportFile.String'), value: 'String' },
		{ label: localizationService.getLocalizedString('cccImportFile.Number'), value: 'Number' },
		{ label: localizationService.getLocalizedString('cccImportFile.Timestamp'), value: 'DateTime' },
		{ label: localizationService.getLocalizedString('cccImportFile.Bool'), value: 'Bool' }
	];

	const updateFieldMapping = (fieldMappingOverride: any) => {
		let newFieldMapping: ImportFieldMapping = {
			...fieldMapping,
			...fieldMappingOverride
		};
		if (newFieldMapping.fieldUpdatePolicy === FieldUpdatePolicy.neverUpdate) {
			newFieldMapping.isRequired = false;
		}
		setFieldMapping(newFieldMapping);
	};

	const getTargetImportField = (fieldName: string): ImportField | undefined => {
		if (props.targetFields) {
			let targetField = props.targetFields.find(f => f.fieldName === fieldName);
			return targetField;
		}
	};

	React.useEffect(() => {
		if (!_.isEmpty(selectedFieldOption.value) && selectedFieldOption.value !== fieldMapping.fieldName) {
			updateFieldMapping({
				fieldName: selectedFieldOption.value,
				fieldLabel: selectedFieldOption.label
			});
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFieldOption]);

	React.useEffect(() => {
		if (
			fieldMapping.isRequired &&
			_.isEmpty(selectedLookUpTranslation) &&
			_.isEmpty(fieldMapping.defaultValue) &&
			lookupData[lookupData.length - 1]
		) {
			if (unrecognizedValues) {
				unrecognizedValues.map(v => updateTranslationMap(v, lookupData[lookupData.length - 1].value));
			}

			setSelectedLookUpTranslation(lookupData[lookupData.length - 1].value);
			updateFieldMapping({ defaultValue: lookupData[lookupData.length - 1].value });

			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fieldMapping.isRequired]);

	const getLookupUrl = (targetField: ImportField): string => {
		if (targetField.importEntity === ImportEntity.CccSite) {
			return urlService.getAuthorityResourcesApiUrl(Resource.CccSites) + `/Fields/${targetField.fieldName}`;
		}
		if (targetField.importEntity === ImportEntity.CccHazard) {
			return urlService.getAuthorityResourcesApiUrl(Resource.CccHazards) + `/Fields/${targetField.fieldName}`;
		}
		if (targetField.importEntity === ImportEntity.CccContact) {
			return (
				urlService.getAuthorityResourcesApiUrl(Resource.CccLinkedContacts) + `/Fields/${targetField.fieldName}`
			);
		}
		return '';
	};

	const handleLookupDefaultValueOrTranslation = (fieldOption: DropDownOption) => {
		let targetField = getTargetImportField(fieldOption.value);
		if (!targetField) {
			Logger.error('Fail to get target mapping field.');
			return;
		}

		if (!isLookUp(targetField.fieldName)) {
			setDisplayLookupTranslation(false);
			setSelectedLookUpTranslation(undefined);
			return;
		}
		let lookupUrl = getLookupUrl(targetField);

		let importColumnUrl =
			urlService.getAuthorityResourcesApiUrl(Resource.CccImport) +
			`/DistinctValues?fileId=${props.importFileId}&columnName=${fieldMapping.importColumnName}`;
		if (lookupUrl && importColumnUrl) {
			apiService
				.getResource<[]>(lookupUrl)
				.then((lookups: []) => {
					let lookupdata: DropDownOption[] = [];
					_.forEach(lookups, (lookup: string) => {
						lookupdata.push({
							label: lookup,
							value: lookup
						});
					});
					setLookupData(lookupdata);
					setDisplayLookupTranslation(true);
					apiService
						.getResource<[]>(importColumnUrl)
						.then((distincts: string[]) => {
							setUnrecognizedValues(getNonEmptyTranslation(distincts, lookups));
						})
						.catch((e: ApiError) => {
							alertService.addError(e.body.message);
						});
				})
				.catch((e: ApiError) => {
					alertService.addError(e.body.message);
				});
		}
	};

	const isLookUp = (fieldName: string): boolean => {
		const allLookUp = [
			'DataSet',
			'SiteUse',
			'SiteType',
			'HazardCategory',
			'HazardDeviceStatus',
			'HazardServiceStatus',
			'HazardServiceType',
			'HazardProtection',
			'HazardLineSize',
			'HazardDeviceOrientation',
			'HazardTestFrequency',
			'ContactType'
		];
		return _.includes(allLookUp, fieldName);
	};

	const onFieldNameChanged = (e: any) => {
		const pickedFieldName = e.target.value;
		setSelectedLookUpTranslation(undefined);
		if (!_.isEmpty(pickedFieldName)) {
			var pickedFieldOption = props.fieldOptions.find(o => o.value === pickedFieldName);
			if (pickedFieldOption) {
				setSelectedFieldOption(pickedFieldOption);
				handleLookupDefaultValueOrTranslation(pickedFieldOption);
			} else {
				Logger.warn(`Cannot find picked fieldName ${pickedFieldName}`);
			}
		} else {
			setSelectedFieldOption(emptyOption);
			setDisplayLookupTranslation(false);
			updateFieldMapping({
				fieldName: '',
				fieldLabel: ''
			});
		}
	};
	const onEmptyTranslationChanged = (e: any) => {
		const pickedTranslation = e.target.value as string;
		if (pickedTranslation) {
			setSelectedLookUpTranslation(pickedTranslation);
			updateFieldMapping({
				defaultValue: pickedTranslation
			});
		} else {
			setSelectedLookUpTranslation(undefined);
			updateFieldMapping({ defaultValue: '' });
		}
	};

	const onUnRecognizedTranslationChanged = (e: any, transFrom: string) => {
		const pickedTranslation = e.target.value as string;
		if (pickedTranslation) {
			updateTranslationMap(transFrom, pickedTranslation);
		} else {
			updateTranslationMap(transFrom, '');
		}
	};
	const updateTranslationMap = (translationFrom: string, translationTo: string) => {
		let newTranslationMap = { ...translationMap };
		newTranslationMap[translationFrom] = translationTo;
		fieldMapping.translationMapInJson = JSON.stringify(newTranslationMap);
		setTranslationMap(newTranslationMap);
	};

	const fieldUpdatePolicyOptions: DropDownOption[] = [
		{ label: localizationService.getLocalizedString('cccImportFile.AlwaysUpdate'), value: 'AlwaysUpdate' },
		{
			label: localizationService.getLocalizedString('cccImportFile.UpdateOnlyWhenEmpty'),
			value: 'UpdateOnlyWhenEmpty'
		},
		{ label: localizationService.getLocalizedString('cccImportFile.NeverUpdate'), value: 'NeverUpdate' }
	];
	const onFieldUpdatePolicyChanged = (e: any) => {
		const { value } = e.target;
		updateFieldMapping({ fieldUpdatePolicy: value });
	};

	const footer = () => {
		return !props.isReadOnlyConfiguration ? (
			<div className="ml-auto">
				<button id="btnApply" className="btn ai-action ml-2" onClick={onSaveClicked}>
					{localizationService.getLocalizedString('screen.buttons.apply')}
				</button>
				<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		) : (
			<></>
		);
	};

	const chooseMapToField = () => {
		let settingsUrl = urlService.getSettingPath();
		return (
			<>
				<ComboBoxDropdown
					id="mapToFieldName"
					name="mapToFieldName"
					isDisabled={!props.isConfigurationEditable}
					value={selectedFieldOption.value}
					onChange={onFieldNameChanged}
					options={props.fieldOptions}
					withoutWrappingDiv={true}
					label={localizationService.getLocalizedString('cccImportFile.columnType')}
				/>
				<div className="ai-form-help">
					{localizationService.getLocalizedString('cccImportFile.columnTypeHint1')}
					&nbsp;
					<a href={settingsUrl} className="ai-link" onClick={(e: any) => navigateTo(history, settingsUrl, e)}>
						{localizationService.getLocalizedString('screen.labels.settings')}
					</a>
					&nbsp;
					{localizationService.getLocalizedString('cccImportFile.columnTypeHint2')}
				</div>
			</>
		);
	};

	const chooseAddDataType = () => {
		return (
			<>
				<SingleSelectDropdown
					id="fieldUpdatePolicy"
					name="fieldUpdatePolicy"
					withoutWrappingDiv={false}
					noEmptyOption={true}
					value={fieldMapping.fieldUpdatePolicy}
					isDisabled={!props.isConfigurationEditable}
					onChange={onFieldUpdatePolicyChanged}
					options={fieldUpdatePolicyOptions}
					label={localizationService.getLocalizedString('cccImportFile.addData')}
					hintText={localizationService.getLocalizedString('cccImportFile.addDataHint')}
				/>
			</>
		);
	};
	const getLookUpTranslation = () => {
		let newLookup = urlService.getSettingPath();
		return (
			<>
				<p>
					<strong>{localizationService.getLocalizedString('cccImportFile.values')}</strong>&nbsp;-&nbsp;
					{localizationService.getLocalizedString('cccImportFile.matchValues')}
					&nbsp;
					<a href={newLookup} className="ai-link" onClick={(e: any) => navigateTo(history, newLookup, e)}>
						{localizationService.getLocalizedString('screen.labels.settings')}
					</a>
					&nbsp;
					{localizationService.getLocalizedString('cccImportFile.newValue')}
				</p>

				<div className="form-row">
					<div className="form-group col-sm-6">
						<div>{localizationService.getLocalizedString('cccImportFile.from')}</div>
						<strong>-{localizationService.getLocalizedString('cccImportFile.emptyValue')}-</strong>
					</div>

					<SingleSelectDropdown
						label={localizationService.getLocalizedString('cccImportFile.to')}
						id="emptyToLookUp"
						name="emptyToLookUp"
						className={'form-group col-sm-6'}
						isDisabled={props.isReadOnlyConfiguration}
						isRequired={fieldMapping && fieldMapping.isRequired === true}
						noEmptyOption={fieldMapping && fieldMapping.isRequired === true}
						value={fieldMapping.defaultValue}
						onChange={onEmptyTranslationChanged}
						options={lookupData}
					/>
				</div>
				{unrecognizedValues &&
					unrecognizedValues !== [] &&
					unrecognizedValues.map((unRecVal, index) => (
						<div className="form-row">
							<div className="form-group col-sm-6">
								<div>{localizationService.getLocalizedString('cccImportFile.from')}</div>
								<div
									dangerouslySetInnerHTML={{
										__html: '<strong>' + unRecVal + '</strong>'
									}}></div>
							</div>
							<SingleSelectDropdown
								label={localizationService.getLocalizedString('cccImportFile.to')}
								id="unrecognizedToLookUp"
								name="unrecognizedToLookUp"
								className={'form-group col-sm-6'}
								isDisabled={props.isReadOnlyConfiguration}
								isRequired={fieldMapping && fieldMapping.isRequired === true}
								noEmptyOption={fieldMapping && fieldMapping.isRequired === true}
								value={translationMap[unRecVal] ? translationMap[unRecVal] : ''}
								onChange={e => {
									onUnRecognizedTranslationChanged(e, unRecVal);
								}}
								options={lookupData}
							/>
						</div>
					))}
			</>
		);
	};

	const getNonEmptyTranslation = (distinctFields: string[], lookups: []) => {
		let lowerLookUps = _.map(lookups, function(lookup) {
			return _.toLower(lookup);
		});
		let nonEmpty = _.compact(distinctFields);
		let unmatchedValues = _.filter(nonEmpty, function(myval) {
			return !_.includes(lowerLookUps, _.toLower(myval));
		});
		return unmatchedValues;
	};

	const onIsRequiredChanged = (evt: any) => {
		const { checked } = evt.target;
		updateFieldMapping({ isRequired: checked });
	};

	const getRequiredCheckbox = () => {
		const label = (
			<p
				dangerouslySetInnerHTML={{
					__html:
						fieldMapping.isNotNull === true
							? localizationService.getLocalizedString('cccImportFile.notNullColumn')
							: localizationService.getLocalizedString('cccImportFile.configurationColumnRequired')
				}}></p>
		);

		return (
			<SingleCheckbox
				id="isRequiredColumn"
				name="isRequiredColumn"
				label={label}
				checked={fieldMapping.isRequired}
				onChange={onIsRequiredChanged}
				isDisabled={
					!props.isConfigurationEditable ||
					fieldMapping.isNotNull === true ||
					fieldMapping.fieldUpdatePolicy === FieldUpdatePolicy.neverUpdate
				}
				className="div-checkbox"
			/>
		);
	};

	return (
		<PopoverModal
			showModal={true}
			title={props.title}
			footer={footer()}
			save={onSaveClicked}
			cancel={onCancelClicked}
			withoutForm={true}>
			<div className="form-group">{chooseMapToField()}</div>
			{chooseAddDataType()}
			{getRequiredCheckbox()}
			{displayLookupTranslation && getLookUpTranslation()}
		</PopoverModal>
	);
};

export default FieldMappingEditor;
