import React, { useEffect, useState } from 'react';
import { History } from 'history';
import * as ApiTypes from '@rcp/types';
import { localizationService, localStorageService, navigateTo, Resource, urlService } from 'src/services';
import { DashboardWidget } from './dashboard-widgets/dashboard-widget';
import { dashboardWidgetSlice } from './dashboard-widgets.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux';
import _ from 'lodash';
import ReorderWidgets from './reorder-widgets';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

const Dashboard: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const addWidgetUrl = `${urlService.getReactResourceUrl(Resource.Dashboard)}/addWidget`;
	const [showReorderWidget, setShowReorderWidget] = useState(false);
	const [widgetDefinitions, setWidgetDefinitions] = useState<ApiTypes.DashboardWidgetDefinition[]>([]);
	const [visibleDashboardWidgets, setVisibleDashboardWidgets] = useState<ApiTypes.DashboardWidget[]>([]);

	const loadDashboardWidgets = () => {
		setVisibleDashboardWidgets([]);
		dispatch(dashboardWidgetSlice.fetchAll());
	};

	useEffect(() => {
		loadDashboardWidgets();
	}, [dispatch]);

	let { result: dashboardWidgets } = useSelector((state: RootState) => state.dashboardWidgets);

	useEffect(() => {
		let oldWidgetDefinitions: ApiTypes.DashboardWidgetDefinition[] = localStorageService.getLocalStorage(
			ApiTypes.LocalStorageName.FogDashboardWidgets
		);
		if (_.isEmpty(oldWidgetDefinitions)) {
			oldWidgetDefinitions = [];
		}

		let newWidgetDefinitions: ApiTypes.DashboardWidgetDefinition[] = [];

		let oldDashboardWidgetIds = oldWidgetDefinitions.map(x => x.dashboardWidgetId);
		let newDashboardWidgets: ApiTypes.DashboardWidget[] = _.sortBy(
			dashboardWidgets.filter(x => !_.includes(oldDashboardWidgetIds, x.dashboardWidgetId)),
			dashboardWidget => {
				return dashboardWidget.sortOrder;
			}
		);

		_.forEach(newDashboardWidgets, function(dashboardWidget) {
			if (dashboardWidget.dashboardWidgetId && dashboardWidget.title) {
				let newWidgetDefinition: ApiTypes.DashboardWidgetDefinition = {
					title: dashboardWidget.title,
					visible: true,
					dashboardWidgetId: dashboardWidget.dashboardWidgetId
				};
				newWidgetDefinitions.push(newWidgetDefinition);
			}
		});

		_.forEach(oldWidgetDefinitions, function(oldWidgetDefinition) {
			if (oldWidgetDefinition.dashboardWidgetId) {
				let dashboardWidget = dashboardWidgets
					? dashboardWidgets.filter(x => x.dashboardWidgetId === oldWidgetDefinition.dashboardWidgetId)
					: [];

				if (dashboardWidget.length > 0) {
					let newWidgetDefinition: ApiTypes.DashboardWidgetDefinition = {
						title: dashboardWidget[0].title ? dashboardWidget[0].title : '',
						visible: oldWidgetDefinition.visible,
						dashboardWidgetId: oldWidgetDefinition.dashboardWidgetId
					};
					newWidgetDefinitions.push(newWidgetDefinition);
				}
			}
		});

		setWidgetDefinitions(newWidgetDefinitions);
	}, [dashboardWidgets]);

	useEffect(() => {
		let newVisibleDashboardWidgets: ApiTypes.DashboardWidget[] = [];
		let sortOrder = 1;
		_.forEach(widgetDefinitions, function(widgetDefinition) {
			if (widgetDefinition.visible) {
				let visibleDashboardWidget: ApiTypes.DashboardWidget = {
					...dashboardWidgets.filter(x => x.dashboardWidgetId === widgetDefinition.dashboardWidgetId)[0]
				};
				visibleDashboardWidget.sortOrder = sortOrder;
				sortOrder = sortOrder + 1;
				newVisibleDashboardWidgets.push(visibleDashboardWidget);
			}
		});
		setVisibleDashboardWidgets(newVisibleDashboardWidgets);
	}, [widgetDefinitions]);

	const onEditColumnBtnClicked = () => {
		setShowReorderWidget(true);
	};

	const editColumnModalToggle = () => {
		setShowReorderWidget(!showReorderWidget);
	};

	const applyReordering = () => {
		setWidgetDefinitions(localStorageService.getLocalStorage(ApiTypes.LocalStorageName.FogDashboardWidgets));
	};

	// CSS adjustment
	let dynamicTableWidthClass = showReorderWidget ? 'max-table-width-when-column-editor-shown' : 'w-100 max-table';

	let mainEl = document.querySelector('main');
	if (showReorderWidget && mainEl) {
		mainEl.classList.add('pr-0');
	}
	if (!showReorderWidget && mainEl) {
		mainEl.classList.remove('pr-0');
	}

	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column ${dynamicTableWidthClass}`}>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('dashboard.pageTitle')}</h1>
						<button
							className="btn ai-new"
							id="btnAddWidget"
							onClick={e => navigateTo(props.history, addWidgetUrl, e)}>
							{localizationService.getLocalizedString('dashboard.addWidget')}
						</button>
						<button className="btn ai-white ml-2" onClick={onEditColumnBtnClicked}>
							{localizationService.getLocalizedString('dashboard.dashboardWidgetSettingTitle')}
						</button>
					</div>

					<div className="main">
						<div className="multicolumn">
							{_.sortBy(visibleDashboardWidgets, dashboardWidget => {
								return dashboardWidget.sortOrder;
							}).map((item, i) => (
								<>
									<DashboardWidget
										key={`DashboardWidget_${item.dashboardWidgetId}`}
										dashboardWidget={item}
										reload={loadDashboardWidgets}
										widgetCount={dashboardWidgets.length}
									/>
									<div className="empty-div"></div>
								</>
							))}
						</div>
					</div>
				</div>

				{showReorderWidget && (
					<ReorderWidgets
						showModal={showReorderWidget}
						widgets={widgetDefinitions}
						onChanged={applyReordering}
						toggle={editColumnModalToggle}
						localStorageName={ApiTypes.LocalStorageName.FogDashboardWidgets}
						reload={loadDashboardWidgets}
					/>
				)}
			</div>
		</>
	);
};

export default Dashboard;
