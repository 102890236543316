import { FogFacilitiesActionType, FogFacilitiesAction } from './facilities-actions';
import { FogFacilitiesState, initialFogFacilitiesState } from './facilities-state';

export const fogFacilitiesReducer = (
	state = initialFogFacilitiesState,
	action: FogFacilitiesAction
): FogFacilitiesState => {
	switch (action.type) {
		case FogFacilitiesActionType.LoadFogFacilitiesRequest:
			return { ...state, paginatedFogFacilities: initialFogFacilitiesState.paginatedFogFacilities };
		case FogFacilitiesActionType.LoadFogFacilitiesSuccess:
			return { ...state, paginatedFogFacilities: action.paginatedFogFacilities };
		case FogFacilitiesActionType.DownloadFogFacilitiesRequest:
			return { ...state };
		case FogFacilitiesActionType.DownloadFogFacilitiesSuccess:
			return { ...state };

		default:
			return state;
	}
};
