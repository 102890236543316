import React, { Component } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from 'src/redux';

import { localizationService } from 'src/services';
import { TextInput } from 'src/components/widgets';
import CollapsibleCard from '../../../../widgets/collapsible-card/collapsible-card';
import {
	IppAASAuthorityInformationState,
	fetchIppAASAuthorityInformationDataActionCreator
} from '../../../../../redux/ipp/authority/authority-account/settings/authority-information';
import { Utils } from 'src/services/utils';
interface DispatchProps {
	fetchIppAASAuthorityInformationData: (currentAuthorityInformationState: IppAASAuthorityInformationState) => void;
}

interface StoreProps {
	ippAASAuthorityInformation: IppAASAuthorityInformationState;
}

interface Props extends DispatchProps, StoreProps {}

interface State {}

class IppAASAuthorityInformation extends Component<Props, State> {
	public async componentDidMount() {
		this.props.fetchIppAASAuthorityInformationData(this.props.ippAASAuthorityInformation);
	}

	public render() {
		const authorityInformationFormKey = 'ipp.authorityAccountSetting.authorityInformationForm';
		const authorityInformationFormFieldsKey = `${authorityInformationFormKey}.fields`;
		const authorityInformationData = this.props.ippAASAuthorityInformation.data;
		return (
			<React.Fragment>
				<CollapsibleCard
					accordionType={'ipp-aas-authority-information-accordion'}
					accordionHeading={localizationService.getLocalizedString(`${authorityInformationFormKey}.heading`)}>
					<div className="form-row">
						<TextInput
							id="ipp-aas-authority-name"
							name="authority-name"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.authorityName.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.authorityName)}
							isDisabled={true}
						/>
						<TextInput
							id="ipp-aas-exchange-authority-id"
							name="exchange-authority-id"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.exchangeAuthorityId.label`
							)}
							value={Utils.getValueOrEmptyString(
								(authorityInformationData.organizationRegulatoryProgramId || '').toString()
							)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-address1"
							name="address1"
							className="col-sm-6"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.address1.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.address1)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-address2"
							name="address2"
							className="col-sm-6"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.address2.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.address2)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-city"
							name="city"
							className="col-sm-6"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.city.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.city)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-state"
							name="state"
							className="col-sm-6"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.state.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.state)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-zip-code"
							name="zipcode"
							className="col-sm-6"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.zipCode.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.zipCode)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-phonenumber"
							name="phonenumber"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.phoneNumber.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.phoneNumber)}
							isDisabled={true}
						/>
						<TextInput
							id="ipp-aas-ext"
							name="ext"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.ext.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.phoneNumberExt)}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="ipp-aas-fax-number"
							name="faxnumber"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.faxNumber.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.faxNumber)}
							isDisabled={true}
						/>
						<TextInput
							id="ipp-aas-website-url"
							name="websiteurl"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.websiteURL.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.websiteURL)}
							isDisabled={true}
						/>
					</div>
					<hr className="mb-22px" />
					<div className="form-row">
						<TextInput
							id="ipp-aas-npdes"
							name="npdes"
							className="form-group-width-2col pl-5px"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.npdes.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.referenceNumber)}
							isDisabled={true}
						/>
						<TextInput
							id="ipp-aas-signer"
							name="signer"
							className="form-group-width-2col"
							label={localizationService.getLocalizedString(
								`${authorityInformationFormFieldsKey}.signer.label`
							)}
							value={Utils.getValueOrEmptyString(authorityInformationData.signer)}
							isDisabled={true}
						/>
					</div>
				</CollapsibleCard>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: ApplicationState) => {
	return {
		ippAASAuthorityInformation: state.ippAASAuthorityInformation
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		fetchIppAASAuthorityInformationData: (currentAuthorityInformationState: IppAASAuthorityInformationState) => {
			dispatch(fetchIppAASAuthorityInformationDataActionCreator(currentAuthorityInformationState));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppAASAuthorityInformation);
