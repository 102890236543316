import React, { FC, useState } from 'react';
import {
	Lookup,
	FacilityPortalAuthorityDetail,
	locationValues,
	Extractor,
	AttachmentType,
	LocalStorageName,
	TranslationLanguage,
	UploadMaximumSize10MB
} from '@rcp/types';
import { DropDownOption } from '@rcp/types/src';
import { useDispatch, useSelector } from 'react-redux';
import { PopoverModal, SingleSelectDropdown, TextAreaInput } from 'src/components';
import { alertService, RootState } from 'src/redux';
import {
	apiService,
	localizationService,
	urlService,
	optionsMap,
	Resource,
	tokenService,
	validationService,
	localStorageService
} from 'src/services';
import _ from 'lodash';
import { facilityDevicesSlice } from './facility-devices-slice';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'src/components/widgets/translate/translator';
import { translateService } from 'src/services/translate-service';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';

interface Props {
	showModal: boolean;
	toggleModal: () => void;
}

interface FormField {
	extractorDescription?: string;
	extractorDescriptionError?: string;
	extractorTypeId?: number;
	extractorTypeIdError?: string;
}

const initialFormField: FormField = {
	extractorDescription: ''
};
const supportedFileTypes: string = '.jpg,.jpeg,.bmp,.png,.JPG';
const AddDeviceModal: FC<Props> = props => {
	const [extractorTypeOptionValues, setExtractorTypeOptionValues] = useState<DropDownOption[]>([]);
	const [formState, setFormState] = useState<FormField>(initialFormField);
	const [authorityDetails, setAuthorityDetails] = useState<FacilityPortalAuthorityDetail>();
	const [file, setFile] = useState<File | null>(null);
	const language = React.useContext(LanguageContext);
	const dispatch = useDispatch();

	const isExtractorTypeValid = (object: any): boolean => {
		if (_.isUndefined(object['extractorTypeId']) || object['extractorTypeId'] < 1) {
			object['extractorTypeIdError'] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				localizationService.getLocalizedString('extractor.deviceType')
			);
			return false;
		} else {
			_.unset(object, 'extractorTypeIdError');
			return true;
		}
	};
	const uploadFiles = async (ownerId: number) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		let formData = new FormData();

		file && formData.append('files', file);

		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.HaulerDeviceCleaningAttachment}?ownerId=${ownerId}&attachmentType=${AttachmentType.FogDevice}`
		);
		await apiService.postFormData(url, formData, headers);
	};

	const resetState = () => {
		setFormState({ ...initialFormField });
		setFile(null);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		isExtractorTypeValid(newState);
		validationService.validateRequiredField(
			newState,
			'extractorDescription',
			'extractorDescriptionError',
			localizationService.getLocalizedString('extractor.locationDesc')
		);

		setFormState(newState);
		let isFormValid = false;

		isFormValid = !validationService.hasError(
			newState,
			'extractorTypeIdError',
			'numberOfCompartmentsError',
			'trapCapacityError',
			'trapDepthError',
			'extractorDescriptionError'
		);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const addDevice = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let token = tokenService.getTokenOrDefault();

		let deviceToAdd: Extractor = {
			facilityId: token.portalOrganizationId,
			extractorTypeId: formState.extractorTypeId,
			extractorDescription: formState.extractorDescription,
			isAdditivesUsed: false,
			location: locationValues.inside,
			isActive: true,
			isRemoved: false,
			verified: false
		};

		if (language) {
			translateService
				.translateString(formState.extractorDescription!, false, 'en', true, language.language.code)
				.then(translatedExtractorDescription => {
					deviceToAdd.extractorDescription = translatedExtractorDescription;
					postNewDevice(deviceToAdd);
				})
				.catch(err =>
					alertService.addError(
						localizationService.getLocalizedString(
							'facilityPortal.failedToTranslateBackToEnglish',
							formState.extractorDescription!
						)
					)
				);
		} else {
			postNewDevice(deviceToAdd);
		}
	};

	const postNewDevice = (deviceToAdd: Extractor) => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Authorities}/${authorityDetails!.authorityId || 0}/${Resource.Extractors}`
		);

		apiService
			.postResource(url, deviceToAdd)
			.then(data => {
				if (file) {
					uploadFiles((data as Extractor).extractorId || 0)
						.then(() => onSubmitCallback())
						.catch(err => alertService.addError(err.message));
				} else {
					onSubmitCallback();
				}
			})
			.catch(err => alertService.addError(err.message));
	};

	const onSubmitCallback = () => {
		alertService.addSuccess(
			localizationService.getLocalizedString(
				'alertMessages.addSuccess',
				localizationService.getLocalizedString('extractor.device')
			)
		);
		props.toggleModal();
		dispatch(facilityDevicesSlice.reload());
		resetState();
	};

	const setExtractorTypeValue = async () => {
		let authorityDetailUrl = urlService.getAuthorityResourcesApiUrl(Resource.AuthorityDetails);
		let authorityDetail = (await apiService.httpGet(authorityDetailUrl)) as FacilityPortalAuthorityDetail;
		let deviceTypesUrl = urlService.getAuthorityLookupUrlForService(
			authorityDetail.authorityId || 0,
			Resource.ExtractorTypes
		);
		apiService
			.getResource<Lookup[]>(deviceTypesUrl)
			.then(deviceTypes => {
				setExtractorTypeOptionValues(optionsMap.fromLookups(deviceTypes));
				setAuthorityDetails(authorityDetail);
			})
			.catch(ex => alertService.addError(ex.message));
	};

	React.useEffect(() => {
		setExtractorTypeValue();
	}, []);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);

		setFormState(newState);
	};

	const addModalProps = {
		saveButtonText: localizationService.getLocalizedString('screen.buttons.add'),
		saveButtonClassName: 'ai-action',
		showModal: props.showModal,
		title: localizationService.getLocalizedString('facilityPortal.cleanings.addDevice'),
		cancel: props.toggleModal,
		save: addDevice
	};

	const handleDrop = (chosenFiles: any) => {
		if (!validationService.validateUploadSize(chosenFiles[0].size, UploadMaximumSize10MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit10MB')
			);
			return;
		}
		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}

		if (!isSupportedFileType(chosenFiles[0])) {
			alertService.addError(
				localizationService.getLocalizedString('attachments.fileTypeNotSupported', chosenFiles[0].name)
			);
		} else {
			chosenFiles.length <= 1 && alertService.clearAllMessages();
			setFile(chosenFiles[0]);
		}
	};

	const onChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;
		handleDrop(chosenFiles);
	};

	const isSupportedFileType = (file: File) => {
		let supportedFileExtensions = supportedFileTypes.replace(/[.]/g, '');
		let selectedFileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		return _.toLower(supportedFileExtensions)
			.split(',')
			.includes(_.toLower(selectedFileExtension));
	};

	const removeFile = (size: number = 10) => {
		return (
			<span id="remove-file" className="cursor-pointer p-1" onClick={() => setFile(null)}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};
	return (
		<PopoverModal {...addModalProps}>
			<div className="pb-2">
				<label>
					<Translate>{localizationService.getLocalizedString('facilityPortal.cleanings.image')}</Translate>
				</label>
				<DragAndDrop
					dragMessage={localizationService.getLocalizedString('notices.dragAndDrop')}
					handleDrop={handleDrop}
					className="drag-submit-editor">
					<div className="file-input-group p-1">
						<label
							className="btn ai-secondary mb-1"
							htmlFor="btnFileImport"
							aria-describedby="file-input-help">
							<Translate>{localizationService.getLocalizedString('notices.chooseFile')}</Translate>
						</label>
						<input
							type="file"
							id="btnFileImport"
							className="file-input mr-4 "
							onChange={onChangeHandler}
							onClick={(e: any) => (e.target.value = null)}
							style={{ width: '0px' }}
							accept={supportedFileTypes}
						/>
						{file ? (
							<span key={`file`}>
								<label className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
									<div className="d-inline-flex" title={file && file.name}>
										<span id="file-badge-name">{file && file.name}</span>
									</div>
									{removeFile()}
								</label>
							</span>
						) : (
							<label className="file-name ml-4 pl-4">
								<Translate>{localizationService.getLocalizedString('notices.dragAndDrop')}</Translate>
							</label>
						)}
					</div>
				</DragAndDrop>
			</div>
			<TextAreaInput
				id="extractorDescription"
				name="extractorDescription"
				label={localizationService.getLocalizedString('extractor.locationDescription')}
				value={formState.extractorDescription}
				onChange={changeFormState}
				isRequired={true}
				isFullWidth={true}
				error={formState.extractorDescriptionError}
			/>
			<SingleSelectDropdown
				id="extractorTypeId"
				name="extractorTypeId"
				label={localizationService.getLocalizedString('extractor.deviceType')}
				value={_.toString(formState.extractorTypeId)}
				onChange={changeFormState}
				options={extractorTypeOptionValues}
				isRequired={true}
				error={formState.extractorTypeIdError}
			/>
		</PopoverModal>
	);
};

export default AddDeviceModal;
