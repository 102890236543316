import React from 'react';
import { FilterItem, OperatorCode } from '../../services/data-types/filter-types';
import { localizationService } from 'src/services';
import { filterService } from './filter-service';

interface FilterNumberProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

export const FilterNumber: React.FC<FilterNumberProps> = props => {
	const onValueChange = (value: string) => {
		if (props.filterItem.values.length !== 1) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on number filter`);
		}
		let newFilterItem = { ...props.filterItem } as FilterItem;
		newFilterItem.values[0] = {
			value: value
		};
		props.updateFilter(newFilterItem);
	};

	const onBetweenValueChange = (value: string, isLeft = true) => {
		if (props.filterItem.values.length !== 2) {
			throw new Error(
				`resetFilterItem is incorrect that filterItem.values length must be 1 on number between filter`
			);
		}
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (isLeft) {
			newFilterItem.values[0] = {
				value: value
			};
		} else {
			newFilterItem.values[1] = {
				value: value
			};
		}
		props.updateFilter(newFilterItem);
	};

	let operatorValue = filterService.getFilterValue(props.filterItem, 0);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	if (props.filterItem.operator.name !== OperatorCode.IsBetween) {
		return (
			<div className={inputGroupCss}>
				<label htmlFor={`FilterOperatorValue_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<input
					type="text"
					className={`form-control ${operatorValue.error ? ' is-invalid' : ''}`}
					id={`FilterOperatorValue_${props.rowNumber}`}
					value={operatorValue.value ? operatorValue.value : ''}
					onChange={(e: any) => {
						onValueChange(e.target.value);
					}}
				/>
				{operatorValue.error && <div className="invalid-feedback">{operatorValue.error}</div>}
			</div>
		);
	}
	let operatorValue1 = operatorValue;
	let operatorValue2 = filterService.getFilterValue(props.filterItem, 1);
	return (
		<>
			<div className={inputGroupCss}>
				<label htmlFor={`FilterOperatorValue1_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value1')}
				</label>
				<input
					type="text"
					className={`form-control ${operatorValue1.error ? ' is-invalid' : ''}`}
					id={`FilterOperatorValue1_${props.rowNumber}`}
					value={operatorValue1.value ? operatorValue1.value : ''}
					onChange={(e: any) => {
						onBetweenValueChange(e.target.value);
					}}
				/>
				{operatorValue1.error && <div className="invalid-feedback">{operatorValue1.error}</div>}
			</div>

			<div className="form-inline">
				<label htmlFor="and" />
				<div className="px-1" id="and">
					{localizationService.getLocalizedString('screen.filterModal.and')}
				</div>
			</div>

			<div className={inputGroupCss}>
				<label htmlFor={`FilterOperatorValue2_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value2')}
				</label>
				<input
					type="text"
					className={`form-control ${operatorValue2.error ? ' is-invalid' : ''}`}
					id={`FilterOperatorValue2_${props.rowNumber}`}
					value={operatorValue2.value ? operatorValue2.value : ''}
					onChange={(e: any) => {
						onBetweenValueChange(e.target.value, false);
					}}
				/>
				{operatorValue2.error && <div className="invalid-feedback">{operatorValue2.error}</div>}
			</div>
		</>
	);
};
