import { IppReportPackage } from '@rcp/types';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { alertService, ApplicationState } from 'src/redux';
import {
	acceptRejectReview,
	loadReviewDetails,
	PackageReviewState,
	sendToCts,
	submitRepudiatedReview,
	submitReview
} from 'src/redux/ipp/authority/report-packages/reviews';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { IppPackageItemAttachments, IppPackageSubmissionReview } from '..';
import IppPackageItemCertifications from '../package-items/certifications';
import IppPackageItemComments from '../package-items/comments';
import IppPackageItemReportDetails from '../package-items/report-details';
import IppPackageItemRepudiationConfirmation from '../package-items/repudiation-confirmation';
import IppPackageRepudiationReview from '../package-items/repudiation-review';
import IppPackageReviewSummary from '../package-items/review-summary';
import IppPackageReviewsCorButtons from '../package-items/reviews-cor-buttons';
import IppPackageItemSamples from '../package-items/samples-and-results';
import IppPackageItemSendToCTS from '../package-items/send-to-linko-cts';
import IppPackageItemSubmissionConfirmation from '../package-items/submission-confirmation';
import { History } from 'history';
import IppPackageFixed from '../package-items/report-fixed';
const { industryReportPackageDetailPath } = IppConstants;

interface Match {
	params: {
		reportPackageId?: number;
	};
}
interface DispatchProps {
	loadReview: (id?: number) => Promise<any>;
	submitReview: (reportPackage: IppReportPackage) => Promise<boolean | undefined>;
	submitRepudiatedReview: (reportPackage: IppReportPackage) => Promise<boolean | undefined>;
	acceptRejectReview: (reportPackage: any, callback: () => void) => Promise<boolean | undefined>;
	sendToCts: (reportPackageId: number) => Promise<boolean | undefined>;
}

interface Props extends DispatchProps {
	history: History;
	match: Match;
	reportPackage: IppReportPackage;
}

const IppPackageReviewDetails: FC<Props> = (props: Props) => {
	const [reviewDetails, setReviewDetails] = useState({} as IppReportPackage);
	const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);
	const [hideActionButtons, setHideActionButtons] = useState<boolean>(false);
	const { protocol, host, href } = window.location;
	let initialPath = host.replace(urlService.getSubDomain(href) + '.', '');

	const loadReportPacakgeDetails = async () => {
		let response = await props.loadReview(Number(props.match.params.reportPackageId));
		if (response && response.statusCode === 403) {
			if (
				String.equalCaseInsensitive(
					response.message,
					localizationService.getLocalizedString('errors.noPermissionToAccess')
				)
			) {
				alertService.clearAllMessages();
				setIsAccessDenied(true);
			} else if (
				String.equalCaseInsensitive(
					response.message,
					localizationService.getLocalizedString('ipp.reportPackage.errors.reportPackageNotSubmittedYet')
				)
			) {
				setHideActionButtons(true);
			}
		}
	};

	useEffect(() => {
		loadReportPacakgeDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setReviewDetails(props.reportPackage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.reportPackage.reportPackageId]);

	useEffect(() => {
		setReviewDetails(props.reportPackage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const onSubmitReview = async (
		comments: string,
		submissionIncompleteContent: boolean,
		isSubmissionReviewNoPendingAcceptance: boolean
	) => {
		let reportPackage: any = {
			submissionReviewComments: comments,
			reportPackageId: reviewDetails.reportPackageId,
			submissionIncompleteContent: submissionIncompleteContent,
			reportStatusName: ReportPackageStatus.Submitted,
			isSubmissionReviewNoPendingAcceptance: isSubmissionReviewNoPendingAcceptance
		};
		reportPackage.reportPackageAuthorityUrl = initialPath + window.location.pathname;
		reportPackage.reportPackageIndustryUrl =
			initialPath +
			`/industry/${reviewDetails.guid}/${industryReportPackageDetailPath}/${reviewDetails.reportPackageId}`;
		reportPackage.urlScheme = protocol.split(':')[0];
		props.submitReview(reportPackage);
	};

	const onSubmitRepudiatedReview = (comments: string) => {
		let reportPackage: IppReportPackage = {
			repudiationReviewComments: comments,
			reportPackageId: reviewDetails.reportPackageId
		};
		props.submitRepudiatedReview(reportPackage);
	};

	const onAcceptReject = async (reason: string, isMissingContent: boolean, isAccepted: boolean) => {
		const reportPackage: any = {
			acceptRejectReasons: reason,
			submissionIncompleteContent: isMissingContent,
			reportStatusName: isAccepted ? ReportPackageStatus.Submitted : ReportPackageStatus.Rejected,
			reportPackageId: reviewDetails.reportPackageId
		};
		reportPackage.reportPackageAuthorityUrl = initialPath + window.location.pathname;
		reportPackage.reportPackageIndustryUrl =
			initialPath +
			`/industry/${reviewDetails.guid}/${industryReportPackageDetailPath}/${reviewDetails.reportPackageId}`;
		reportPackage.urlScheme = protocol.split(':')[0];
		await props.acceptRejectReview(reportPackage, () => {
			const url = urlService.getReactResourceUrl(
				`authority/${Resource.IppReportPackages}/${ReportPackageStatus.SubmittedPendingReview}`
			);
			navigateTo(props.history, url);
		});
	};

	const sendToCTS = async () => {
		const result = await props.sendToCts(Number(reviewDetails.reportPackageId));
		return result;
	};

	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('ipp.reportPackage.package.reportPackageDetails')}
						</h1>
						{reviewDetails.reportStatusName && (
							<label
								className={`ml-auto status-btn ${reviewDetails.reportStatusName.toLowerCase()}-btn`}
								id="sample-draft-btn">
								{reviewDetails.reportStatusName !== ReportPackageStatus.Error
									? _.startCase(_.camelCase(reviewDetails.reportStatusName))
									: localizationService.getLocalizedString(
											'ipp.reportPackage.package.reportPackageErrorStatus'
									  )}
							</label>
						)}
					</div>
					<IppPackageItemReportDetails {...reviewDetails} />
					{reviewDetails.samplesAndResultsTypes && reviewDetails.samplesAndResultsTypes.length ? (
						<IppPackageItemSamples
							sampleTypes={
								reviewDetails.samplesAndResultsTypes &&
								reviewDetails.samplesAndResultsTypes[0].ippSamples
									? reviewDetails.samplesAndResultsTypes[0].ippSamples
									: []
							}
							isRequired={
								reviewDetails.samplesAndResultsTypes &&
								reviewDetails.samplesAndResultsTypes[0].isRequired
							}
							className="mt-3"
						/>
					) : null}
					<IppPackageItemAttachments attachmentTypes={reviewDetails.attachmentTypes} className="mt-3" />
					<IppPackageItemCertifications
						certificationTypes={reviewDetails.certificationTypes || []}
						className="mt-3"
					/>
					<IppPackageItemComments comments={reviewDetails.comments || ''} className="mt-3" />
					<IppPackageReviewSummary
						{...reviewDetails}
						className="mt-3"
						showSampleSummary={
							reviewDetails.samplesAndResultsTypes && !!reviewDetails.samplesAndResultsTypes.length
						}
					/>
					<IppPackageItemSubmissionConfirmation {...reviewDetails} className="mt-3" />
					{reviewDetails.fixedDateTimeLocal && (
						<IppPackageFixed packageDetails={reviewDetails} className="mt-3" />
					)}
					{reviewDetails.reportStatusName && reviewDetails.reportStatusName !== ReportPackageStatus.Error && (
						<IppPackageSubmissionReview
							{...reviewDetails}
							className="mt-3"
							onSubmit={onSubmitReview}
							onReject={onAcceptReject}
							hideActionButtons={hideActionButtons}
						/>
					)}
					{reviewDetails.repudiationDateTimeLocal && (
						<IppPackageItemRepudiationConfirmation {...reviewDetails} className="mt-3" />
					)}
					{reviewDetails.repudiationDateTimeLocal && (
						<IppPackageRepudiationReview
							{...reviewDetails}
							className="mt-3"
							onSubmit={onSubmitRepudiatedReview}
						/>
					)}
					{(reviewDetails.submissionReviewDateTimeLocal || reviewDetails.repudiationReviewDateTimeLocal) && (
						<IppPackageItemSendToCTS
							sendToCTS={sendToCTS}
							{...reviewDetails}
							className="mt-3"
							enableCTSButton={
								props.reportPackage.ctsEventTypeId !== null &&
								props.reportPackage.ctsEventTypeId !== undefined &&
								props.reportPackage.ctsEventTypeId !== 0
							}
						/>
					)}
					{!hideActionButtons && (
						<IppPackageReviewsCorButtons packageId={reviewDetails.reportPackageId} className="mt-3" />
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): PackageReviewState => {
	return { ...state.ippReportPackageReview };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadReview: (id?: number) => dispatch(loadReviewDetails(id)),
		submitReview: (reportPackage: IppReportPackage) => dispatch(submitReview(reportPackage)),
		submitRepudiatedReview: (reportPackage: IppReportPackage) => dispatch(submitRepudiatedReview(reportPackage)),
		acceptRejectReview: (reportPackage: any, callback: () => void) =>
			dispatch(acceptRejectReview(reportPackage, callback)),
		sendToCts: (reportPackageId: number) => dispatch(sendToCts(reportPackageId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppPackageReviewDetails);
