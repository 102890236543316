import _ from 'lodash';
import { History } from 'history';
import * as nodeUrl from 'url';
import { Portal, SignInPortal, SignInResult, Token, TranslationLanguage, UrlAction } from '@rcp/types';
import { tokenService } from 'src/services/token-service';
import { urlService } from 'src/services/url-service';
import { localStorageService } from 'src/services';
import { oidcEnvConfig } from 'src/constants';

class AuthenticationService {
	public oidcEnabled = () => {
		return (
			window.location.hostname.endsWith('-acp-beta.aquaticinformatics.net') ||
			window.location.hostname.endsWith('-acp-test.aquaticinformatics.net') ||
			process.env.REACT_APP_OIDC_ENABLED
		);
	};

	public handleSignInResult = (signInResult: SignInResult, history: History, language?: TranslationLanguage) => {
		if (signInResult.allowedPortals && signInResult.allowedPortals.length) {
			signInResult.allowedPortals = urlService.removePortalsWithRoleCTSSyncUser(signInResult.allowedPortals);
		}
		if (!signInResult.allowedPortals || (signInResult.allowedPortals && !signInResult.allowedPortals.length)) {
			window.location.href = urlService.getPortalDirectorUrl();
		}
		//save token for account portal
		if (signInResult && signInResult.expirationEpochTimeInUtc) {
			this.setAccountPortalTokenInLocalStorage(signInResult, language);
		}
		localStorage.setItem(':allowedPortals', JSON.stringify(signInResult.allowedPortals));
		let programs: any = [];
		signInResult.allowedPortals.forEach((portal: any) =>
			portal.userIssuedPrograms.map((prog: any) => programs.push(prog))
		);
		let issuedProgramNames = programs && programs.map((prog: any) => prog.regulatoryProgramName.toLowerCase());

		let mfaEnabledPrograms =
			signInResult.regulatoryProgramMFATypeDetails &&
			signInResult.regulatoryProgramMFATypeDetails
				.filter((prog: any) => prog.isMFAEnabled)
				.map((prog: any) => prog.regulatoryProgramName.toLowerCase());

		let mfaEnabledProgramForUser = _.intersection(issuedProgramNames, mfaEnabledPrograms);

		let userMFATypeDetails = (signInResult as any).userMFATypeDetails;
		let mfaConfigured = true;
		userMFATypeDetails.forEach((mfa: any) => {
			if (!mfa.isMFADetailsConfigured && String.equalCaseInsensitive(mfa.mfaTypeName, 'QuestionAndAnswers')) {
				mfaConfigured = false;
			}
		});

		localStorage.setItem(
			':isMfaEnabledProgramAssociated',
			mfaEnabledProgramForUser.length > 0 && !mfaConfigured ? 'true' : 'false'
		);
		if (!mfaConfigured && mfaEnabledProgramForUser.length > 0) {
			history.replace('/account/configureMfaQuestionAndAnswers', signInResult);
		} else {
			let tokenData: any = urlService.signInToPortal(signInResult, language as TranslationLanguage);
			if (tokenData && tokenData.forwardUrl) {
				//Sign-in with query parameter continue forwardUrl, directly moved to landingPortalUrl with forwardUrl view
				let landingPortalUrl = urlService.getLandingPortalUrl(tokenData.token, tokenData.forwardUrl, undefined);
				window.location.href = landingPortalUrl;
			} else if (this.isHaulerRegistration()) {
				const haulerPortal = signInResult.allowedPortals.find((portal: SignInPortal) => {
					return String.equalCaseInsensitive(portal.organizationTypeName, 'Hauler');
				});
				if (haulerPortal) {
					let landingHaulerPortalUrl = urlService.getLandingPortalUrlForHaulerPortal(signInResult);
					if (landingHaulerPortalUrl) {
						window.location.href = landingHaulerPortalUrl;
					}
				} else if (signInResult.allowedPortals && signInResult.allowedPortals.length !== 0) {
					history.replace('/hauler/invite', signInResult);
				}
			} else if (this.isFacilityRegistration()) {
				const facilityPortal = signInResult.allowedPortals.find((portal: SignInPortal) => {
					return String.equalCaseInsensitive(portal.organizationTypeName, 'Facility');
				});
				if (facilityPortal) {
					let landingFacilityPortalUrl = urlService.getForwardToSinglePortalUrl(
						signInResult,
						facilityPortal,
						language
					);
					if (landingFacilityPortalUrl) {
						window.location.href = landingFacilityPortalUrl;
					}
				} else if (signInResult.allowedPortals && signInResult.allowedPortals.length !== 0) {
					history.replace('/facility/invite', signInResult);
				}
			} else if (
				signInResult.allowedPortals.length === 1 &&
				signInResult.allowedPortals[0].userIssuedPrograms.length === 1
			) {
				//Sign-in user only has one portal, directly moved to the portal landingPortalUrl
				let landingTheOnlyPortalUrl = urlService.getForwardToSinglePortalUrl(signInResult, undefined, language);
				if (landingTheOnlyPortalUrl) {
					window.location.href = landingTheOnlyPortalUrl;
				}
			} else if (window.location.search.includes('forward?LEReportPackageId')) {
				let query = nodeUrl.parse(window.location.href, true).query;
				let continueUrl = query['continue'];
				let forwardUrl = continueUrl as string;
				window.location.href = forwardUrl;
			} else {
				history.replace('/account/organizations', signInResult);
			}
		}
	};

	private setAccountPortalTokenInLocalStorage(signInResult: SignInResult, language?: TranslationLanguage) {
		let token: Token = {
			accessToken: signInResult.accessToken as string,
			expirationEpochTimeInUtc: Number(signInResult.expirationEpochTimeInUtc),
			refreshToken: signInResult.refreshToken,
			correlationId: signInResult.correlationId,
			oidcIdToken: signInResult.oidcIdToken,
			tokenType: signInResult.tokenType,
			tokenProvider: signInResult.tokenProvider,
			userName: signInResult.userName,
			email: signInResult.email,
			ssoFrom: signInResult.ssoFrom,
			signInResult: signInResult,
			language: language
		};

		tokenService.setToken(token);
	}

	isHaulerRegistration(): boolean {
		let currentUrl = new URL(window.location.href);
		let urlAction: string = currentUrl.searchParams.get('action') || '';
		return (
			String.equalCaseInsensitive(urlAction, `${UrlAction.HaulerRegistration}`) ||
			(String.equalCaseInsensitive(urlAction, `${UrlAction.InviteCodeConfirmed}`) &&
				String.equalCaseInsensitive(currentUrl.searchParams.get('portal') || undefined, Portal.hauler))
		);
	}

	isFacilityRegistration(): boolean {
		let currentUrl = new URL(window.location.href);
		let urlAction: string = currentUrl.searchParams.get('action') || '';
		return (
			String.equalCaseInsensitive(urlAction, `${UrlAction.FacilityRegistration}`) ||
			(String.equalCaseInsensitive(urlAction, `${UrlAction.InviteCodeConfirmed}`) &&
				String.equalCaseInsensitive(currentUrl.searchParams.get('portal') || undefined, Portal.facility))
		);
	}

	isInviteCodeConfirmed(): boolean {
		let currentUrl = new URL(window.location.href);
		let urlAction: string = currentUrl.searchParams.get('action') || '';
		return String.equalCaseInsensitive(urlAction, `${UrlAction.InviteCodeConfirmed}`);
	}

	logout(noRedirect = false) {
		const token = tokenService.getTokenOrDefault();
		tokenService.clearToken();
		localStorageService.removeLocalStorage('allowedPortals');
		localStorage.removeItem('hidePortalDirector');

		let signInLandingFrom = localStorage.getItem('signInLandingFrom');
		localStorageService.removeLocalStorage('signInLandingFrom');

		if (String.equalCaseInsensitive(signInLandingFrom || undefined, 'AqiAppHome')) {
			//If the user jump to ACP from Aqi AppHome, then sign out lead the user back to Aqi AppHome
			window.location.href =
				oidcEnvConfig.aqiAppHomeUrl || 'https://feature-us.aquaticinformatics.net/app-home/landing';
		} else {
			const loginUrl = urlService.getLoginUrl('signOut=true');
			if (!noRedirect) {
				window.location.href = loginUrl;
			}
		}
	}
}

export const authenticationService = new AuthenticationService();
