import React from 'react';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService, urlService } from 'src/services';
import { MultiSelectDropDownOption, RegulatoryProgramName } from '@rcp/types';
import { MultiSelectDropdown } from '../../components/widgets';
import { filterService } from './filter-service';

interface FilterBooleanProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

const booleanOptions: MultiSelectDropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.true'),
		value: 'true',
		selected: false
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.false'),
		value: 'false',
		selected: false
	}
];

export const FilterBoolean: React.FC<FilterBooleanProps> = props => {
	const onValueChange = (selectedValues: string[]) => {
		if (props.filterItem.values.length !== 1) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on boolean filter`);
		}
		let newFilterItem = { ...props.filterItem } as FilterItem;
		newFilterItem.values[0] = {
			values: selectedValues
		};
		props.updateFilter(newFilterItem);
	};

	let operatorValue = filterService.getFilterValue(props.filterItem, 0);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<div className={inputGroupCss}>
			<label htmlFor={`FilterOperatorBooleanValue_${props.rowNumber}`}>
				{localizationService.getLocalizedString('screen.filterModal.value')}
			</label>
			<MultiSelectDropdown
				name={`FilterOperatorBooleanValue_${props.rowNumber}`}
				onChange={onValueChange}
				options={booleanOptions}
				className={`kendo-control ${operatorValue.error ? ' is-invalid' : ''}`}
				isRequired={true}
				filterItem={props.filterItem}
				selectedValues={operatorValue.values}
				withoutEmptyOption={props.filterItem.property.isNonNullable}
			/>
			{operatorValue.error && <div className="invalid-feedback">{operatorValue.error}</div>}
		</div>
	);
};
