import React from 'react';
import { DropDownOption } from '@rcp/types';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService, validationService } from 'src/services';
import { SingleSelectDropdown, DateInput } from '../../components/widgets';
import _ from 'lodash';
import { filterService } from './filter-service';

export const valueOfChooseADateOption = 'ChooseADate';

export const TimeframeType = 'timeframe';
export enum TimeframeValue {
	Empty = 'Empty',
	NotEmpty = 'NotEmpty',
	Today = 'Today',
	ThisWeek = 'ThisWeek',
	NextWeek = 'NextWeek',
	LastMonth = 'LastMonth',
	ThisMonth = 'ThisMonth',
	NextMonth = 'NextMonth',
	LastYear = 'LastYear',
	ThisYear = 'ThisYear',
	NextYear = 'NextYear'
}

export enum TimeframeField {
	Due = 'due',
	Completed = 'completed'
}

export const timeframeValueOptions: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.empty'),
		value: TimeframeValue.Empty
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.notEmpty'),
		value: TimeframeValue.NotEmpty
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.today'),
		value: TimeframeValue.Today
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.thisWeek'),
		value: TimeframeValue.ThisWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.nextWeek'),
		value: TimeframeValue.NextWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.lastMonth'),
		value: TimeframeValue.LastMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.thisMonth'),
		value: TimeframeValue.ThisMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.nextMonth'),
		value: TimeframeValue.NextMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.lastYear'),
		value: TimeframeValue.LastYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.thisYear'),
		value: TimeframeValue.ThisYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.nextYear'),
		value: TimeframeValue.NextYear
	}
];

export const timeframeDueValueOptionsForQuickFilter: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.today'),
		value: TimeframeValue.Today
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.thisWeek'),
		value: TimeframeValue.ThisWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.nextWeek'),
		value: TimeframeValue.NextWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.lastMonth'),
		value: TimeframeValue.LastMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.thisMonth'),
		value: TimeframeValue.ThisMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.nextMonth'),
		value: TimeframeValue.NextMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.lastYear'),
		value: TimeframeValue.LastYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.thisYear'),
		value: TimeframeValue.ThisYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeDueValues.nextYear'),
		value: TimeframeValue.NextYear
	}
];

export const timeframeCompleteValueOptionsForQuickFilter: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.today'),
		value: TimeframeValue.Today
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.thisWeek'),
		value: TimeframeValue.ThisWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.nextWeek'),
		value: TimeframeValue.NextWeek
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.lastMonth'),
		value: TimeframeValue.LastMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.thisMonth'),
		value: TimeframeValue.ThisMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.nextMonth'),
		value: TimeframeValue.NextMonth
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.lastYear'),
		value: TimeframeValue.LastYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.thisYear'),
		value: TimeframeValue.ThisYear
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.timeframeCompleteValues.nextYear'),
		value: TimeframeValue.NextYear
	}
];

export const dateFilterIsOptionsInitial: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.chooseADate'),
		value: valueOfChooseADateOption
	}
].concat([...timeframeValueOptions]);

interface FilterDateIsProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

export const validateDateFilterIs = (filterItem: FilterItem) => {
	validationService.validateRequiredField(filterItem.values[0], 'value', 'error');
	if (_.get(filterItem.values[0], 'value') === valueOfChooseADateOption) {
		validationService.validateRequiredDateField(filterItem.values[1], 'value', 'error');
	}
};

export const FilterDateIs: React.FC<FilterDateIsProps> = props => {
	const onValueChanged = (value: string, index: number) => {
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (newFilterItem.values.length !== 2) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 2 on date is filter`);
		}
		if (index === 0) {
			newFilterItem.values[0] = {
				value: value
			};
			_.unset(newFilterItem.values[1], 'value');
		} else if (index === 1) {
			newFilterItem.values[1] = {
				value: value
			};
		} else {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on date is filter`);
		}
		props.updateFilter(newFilterItem);
	};

	let isOptionValue = filterService.getFilterValue(props.filterItem, 0);
	let chooseADateValue = filterService.getFilterValue(props.filterItem, 1);
	let dateFilterIsOptions = dateFilterIsOptionsInitial.filter((option: DropDownOption) => {
		if (props.filterItem.property.isNonNullable) {
			if (option.value !== 'Empty' && option.value !== 'NotEmpty') {
				return option;
			}
		} else {
			return option;
		}
	});
	let validDateFilterOptions = dateFilterIsOptions.filter((option: DropDownOption) => {
		if (props.filterItem.property.isNonNullable) {
			if (option.value !== 'Empty' && option.value !== 'NotEmpty') {
				return true;
			}
		} else {
			return true;
		}
		return false;
	});
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenValue0_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenValue0_${props.rowNumber}`}
					name={`DateBetweenValue0_${props.rowNumber}`}
					className={`custom-select ${isOptionValue.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 0);
					}}
					value={isOptionValue.value as string}
					options={validDateFilterOptions}
					isRequired={true}
					withoutWrappingDiv={true}
					selfOrder={true}
				/>
				{isOptionValue.error && <div className="invalid-feedback">{isOptionValue.error}</div>}
			</div>

			{isOptionValue.value === valueOfChooseADateOption && (
				<div className={inputGroupCss}>
					<label htmlFor={`DateBetweenValue1_${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.date')}
					</label>
					<DateInput
						id={`DateBetweenValue1_${props.rowNumber}`}
						name={`DateBetweenValue1_${props.rowNumber}`}
						className={` ${chooseADateValue.error ? ' is-invalid' : ' '}`}
						error={chooseADateValue.error}
						value={chooseADateValue.value}
						onChange={(e: any) => {
							onValueChanged(e.target.value, 1);
						}}
						withoutWrappingDiv={true}
					/>
					{chooseADateValue.error && (
						<div className="invalid-feedback error-block">{chooseADateValue.error}</div>
					)}
				</div>
			)}
		</>
	);
};
