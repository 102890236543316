import React, { useState } from 'react';
import _ from 'lodash';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { useHistory, useParams } from 'react-router';
import { ColumnField, Dictionary, Hauler, HaulerPortalFacilities, LocalStorageName, RouteProps } from '@rcp/types';
import { SingleCheckbox } from 'src/components/widgets';
import { RootState } from 'src/redux';
import { haulerPortalFacilitiesSlice } from '../hauler-facilities-slice';
import { DataGrid, GridOption } from 'src/features';
import { useDispatch } from 'react-redux';
import { haulersSlice } from '../../../..';

interface Props extends RouteProps {}

const HaulerSelectFacilities = (props: RouteProps) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params: any = useParams();
	const [showNotification, setShowNotification] = useState(true);
	const [hauler, setHauler] = React.useState({} as Hauler);
	const [queryParameters, setQueryParameters] = React.useState<Dictionary<string>>({});

	const setQueryParametersFromParent = (queryParameters: Dictionary<string>) => {
		setQueryParameters(queryParameters);
	};

	React.useEffect(() => {
		if (urlService.isHaulerPortal()) {
			haulerPortalFacilitiesSlice.setApiUrlPath(`${Resource.HaulerFacilities}/${Resource.Select}`);
		} else {
			if (params.haulerId) {
				dispatch(
					haulersSlice.fetchOne(params.haulerId, (data: Hauler) => {
						setHauler(data);
					})
				);
			}
			haulerPortalFacilitiesSlice.setApiUrlPath(
				`${Resource.Haulers}/${params.haulerId}/${Resource.FogFacilities}`
			);
		}
	}, []);

	const facilityNameToLink = (props: any) => {
		const facility = props.dataItem as HaulerPortalFacilities;
		return (
			<td>
				<a
					href={urlService.getHaulerFacilityDetailPage(facility.facilityId as number, params.haulerId)}
					className="ai-link"
					onClick={(e: any) => {
						navigateTo(
							history,
							urlService.getHaulerFacilityDetailPage(facility.facilityId as number, params.haulerId),
							e
						);
					}}>
					{facility.facilityName}
				</a>
			</td>
		);
	};

	const updateFacilities = (event: any, dataItem: HaulerPortalFacilities) => {
		const id = dataItem.haulerFacilityId as number;
		const isServicePortal = urlService.isServicePortal();
		if (event.target.checked) {
			const { haulerFacilityId, ...rest } = dataItem;
			if (params.haulerId) {
				haulerPortalFacilitiesSlice.setApiUrlPath(`${Resource.HaulerFacilities}/${Resource.Select}`);
				dispatch(
					haulerPortalFacilitiesSlice.createOne(
						{ ...rest, haulerId: params.haulerId },
						true,
						'',
						() => {
							if (!isServicePortal) {
								haulerPortalFacilitiesSlice.setApiUrlPath(
									`${Resource.Haulers}/${params.haulerId}/${Resource.FogFacilities}`
								);
							}
						},
						undefined,
						false,
						urlService.toQueryString(queryParameters),
						false
					)
				);
			} else {
				dispatch(
					haulerPortalFacilitiesSlice.createOne(
						{ ...rest },
						true,
						'',
						undefined,
						undefined,
						false,
						urlService.toQueryString(queryParameters),
						false
					)
				);
			}
		} else {
			haulerPortalFacilitiesSlice.setApiUrlPath(`${Resource.HaulerFacilities}/${Resource.Select}`);

			dispatch(
				haulerPortalFacilitiesSlice.deleteOne(
					id,
					true,
					'',
					() => {
						if (!isServicePortal) {
							haulerPortalFacilitiesSlice.setApiUrlPath(
								`${Resource.Haulers}/${params.haulerId}/${Resource.FogFacilities}`
							);
						}
					},
					'',
					false
				)
			);
		}
	};

	const showCheckBoxCleaning = (props: HaulerPortalFacilities) => {
		const dataItem = props.dataItem as HaulerPortalFacilities;

		return (
			<td style={{ width: '0.1%' }}>
				<SingleCheckbox
					id={`facility-selected-${dataItem.facilityId}`}
					name={`facility-selected-${dataItem.facilityId}`}
					className="mx-auto"
					checked={dataItem.isSelected}
					onChange={event => updateFacilities(event, dataItem)}
				/>
			</td>
		);
	};

	const updateNotification = () => {
		setShowNotification(false);
	};

	const initialGridOptions: GridOption = {
		pageTitle: urlService.isHaulerPortal()
			? localizationService.getLocalizedString('haulerPortal.selectFacility.selectFacility')
			: `${localizationService.getLocalizedString(
					'haulerPortal.selectFacility.selectFacility'
			  )} for ${hauler.name || ''}`,
		prefix: `${urlService.isHaulerPortal() ? 'facility.haulerSelectFacilities' : 'facility'}`,
		storageName: LocalStorageName.HaulerPortalSelectFacilities,
		searchHint: localizationService.getLocalizedString('facility.haulerSelectFacilities.searchHint'),
		gridUrlLocalStorageName: LocalStorageName.HaulerPortalSelectFacilitiesFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.HaulerPortalSelectFacilitiesSortOrder,
		showSearch: true,
		showAddButton: true,
		queryParameters: { sort: 'facilityName asc' } as Dictionary<string>,
		defaultSort: [{ field: 'facilityName', dir: 'asc' }],
		allColumns: [
			ColumnField.newText('isSelected', true, showCheckBoxCleaning),
			ColumnField.newText('referenceNumber', true),
			ColumnField.newText('facilityName', true, facilityNameToLink),
			ColumnField.newText('activeDeviceCount', true),
			ColumnField.newText('addressLine1', true),
			ColumnField.newText('cityName', true),
			ColumnField.newText('authorityName', true)
		],
		defaultColumns: urlService.isHaulerPortal()
			? ['isSelected', 'referenceNumber', 'facilityName', 'addressLine1', 'cityName', 'authorityName', 'activeDeviceCount']
			: ['isSelected', 'referenceNumber', 'facilityName', 'addressLine1', 'cityName', 'activeDeviceCount'],
		notification: urlService.isHaulerPortal()
			? {
					show: showNotification,
					message: (
						<span>
							{localizationService.getLocalizedString('haulerPortal.selectFacility.notification1')}
							&nbsp;
							<span
								className="text-underline cursor-pointer"
								onClick={() => {
									navigateTo(history, urlService.getHaulerFacilitiesPage(params.haulerId));
								}}>
								{localizationService.getLocalizedString('haulerPortal.selectFacility.facilities')}
							</span>
							&nbsp;
							{localizationService.getLocalizedString('haulerPortal.selectFacility.notification2')}
						</span>
					),
					onClose: () => updateNotification()
			  }
			: undefined,
		showBackButton: true
	};

	return (
		<>
			<div className="hauler-select-facilities-grid">
				<DataGrid
					gridOption={initialGridOptions}
					restSlice={haulerPortalFacilitiesSlice}
					restState={(state: RootState) => state.haulerPortalFacilities}
					history={history}
					match={props.match}
					setQueryParametersFromParent={setQueryParametersFromParent}
				/>
			</div>
		</>
	);
};
export default HaulerSelectFacilities;
