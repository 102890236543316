import * as React from 'react';
import { TextInput, DateInput, SingleSelectDropdown, TextAreaInput } from 'src/components/widgets';
import { ICustomFieldItem, DropDownOption, CustomFieldDataType } from '@rcp/types';
import _ from 'lodash';
import { validationService } from 'src/services';

class CustomFieldGenerator {
	validateCustomFields = (customFields: ICustomFieldItem[]) => {
		_.each(customFields, customField => {
			(customField.isRequired || customField.isImportKey) &&
				validationService.validateRequiredField(customField, 'value', 'error');
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Number)
			) {
				validationService.validateNumberField(customField, 'value', 'error');
			}
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Link)
			) {
				validationService.validateUrlField(customField, 'value', 'error');
			}
		});
	};

	getSingleSelectionValueElement = (
		customField: ICustomFieldItem,
		key: number,
		onChange?: (e: any) => void,
		classNameStr: string = 'form-group'
	) => {
		let options = [] as DropDownOption[];

		if (customField.defaultValueOrOptionalValues) {
			let defaultValues = customField.defaultValueOrOptionalValues.split(',');
			options = defaultValues.map((item: string) => ({ label: item, value: item }));
		}

		return (
			<SingleSelectDropdown
				key={'customField-input-' + key}
				id={'cf' + customField.fieldName}
				name={customField.fieldName}
				className={classNameStr}
				label={customField.label}
				value={_.toString(customField.value)}
				onChange={onChange}
				isRequired={customField.isRequired}
				error={customField.error}
				options={options}
			/>
		);
	};

	getTextAreaInputElement = (
		customField: ICustomFieldItem,
		key: number,
		isFullWidth: boolean,
		onChange?: (e: any) => void
	) => {
		return (
			<TextAreaInput
				key={'customField-input-' + key}
				id={'cf' + customField.fieldName}
				name={customField.fieldName}
				label={customField.label}
				isFullWidth={isFullWidth}
				value={customField.value}
				onChange={onChange}
				isRequired={customField.isRequired}
				error={customField.error}
			/>
		);
	};

	getTextInputElement = (
		customField: ICustomFieldItem,
		key: number,
		onChange?: (e: any) => void,
		classNameStr: string = 'form-group'
	) => {
		return (
			<TextInput
				key={'customField-input-' + key}
				id={'cf' + customField.fieldName}
				name={customField.fieldName}
				className={classNameStr}
				label={customField.label}
				value={customField.value}
				onChange={onChange}
				isRequired={customField.isRequired}
				error={customField.error}
			/>
		);
	};

	getDateInputElement = (
		customField: ICustomFieldItem,
		key: number,
		onChange?: (e: any) => void,
		classNameStr: string = 'form-group'
	) => {
		return (
			<DateInput
				key={'customField-input-' + key}
				id={'cf' + customField.fieldName}
				name={customField.fieldName}
				className={classNameStr}
				label={customField.label}
				value={customField.value}
				onChange={onChange}
				isRequired={customField.isRequired}
				error={customField.error}
			/>
		);
	};
}

export const customFieldGenerator = new CustomFieldGenerator();
