import classnames from 'classnames';
import * as React from 'react';
import { StepperContext } from './context';
import { dispatchAccessor } from 'src/services';
import { PageLoadingActionType } from 'src/redux';

interface Props extends React.FormHTMLAttributes<HTMLFormElement> {
	actions?: React.ReactNode;
}

const StepperContent: React.FunctionComponent<Props> = ({ className, children, actions, ...props }) => {
	const { isLoading } = React.useContext(StepperContext);

	React.useEffect(() => {
		isLoading()
			? dispatchAccessor.dispatch({ type: PageLoadingActionType.Start })
			: dispatchAccessor.dispatch({ type: PageLoadingActionType.Done });
	}, []);

	return (
		<form {...props} className={classnames('stepper__content', className)}>
			{children}

			{actions && <footer className="stepper__content__actions">{actions}</footer>}
		</form>
	);
};

export default StepperContent;
