import _ from 'lodash';
import React, { useEffect } from 'react';
import { alertService } from 'src/redux';
import { apiService, localizationService, tokenService, urlService, validationService } from 'src/services';
import { PopoverModal, TextInput } from 'src/components/widgets';

interface OwnProps {
	showModal: boolean;
	draftFilterName: string;
	onCancel: () => void;
	onSave: (filterName?: string) => void;
}

interface SaveFilterState {
	filterName: string;
	filterNameError: string;
}

type Props = OwnProps;

export const SaveQuickFilterModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState({} as SaveFilterState);

	useEffect(() => {
		setFormState({ filterName: props.draftFilterName } as SaveFilterState);
	}, [props.draftFilterName]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const closeModal = () => {
		props.onCancel();
		setFormState({} as SaveFilterState);
	};

	const validateBeforeSave = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			'filterName',
			'filterNameError',
			localizationService.getLocalizedString('screen.filterModal.filterName')
		);
		setFormState(newState);
		let isFormValid = false;

		isFormValid = !validationService.hasError(newState, 'filterNameError');

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const saveFilterButtonClicked = () => {
		if (validateBeforeSave()) {
			props.onSave(formState.filterName);
		}
	};

	return (
		<PopoverModal
			showModal={props.showModal}
			title={localizationService.getLocalizedString('screen.filterModal.saveFilter')}
			save={saveFilterButtonClicked}
			cancel={closeModal}>
			<TextInput
				type="filterName"
				id="filterName"
				name="filterName"
				label={localizationService.getLocalizedString('screen.filterModal.filterName')}
				value={formState.filterName}
				onChange={changeFormState}
				isRequired={true}
				error={formState.filterNameError}
			/>
		</PopoverModal>
	);
};
