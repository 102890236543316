import * as React from 'react';
import _ from 'lodash';
import { Button } from '@progress/kendo-react-buttons';
import { EditorView, Slice } from '@progress/kendo-editor-common';
import { doNotTranslateTags } from 'src/constants';
import { localizationService } from 'src/services';

interface OwnProps {
	view: EditorView<any>;
}
type Props = OwnProps;

let doNotTranslateStartTextNode = { type: 'text', text: doNotTranslateTags.start };
let doNotTranslateEndTextNode = { type: 'text', text: doNotTranslateTags.end };
export const InsertDoNotTranslateTool: React.FC<Props> = props => {
	const { view } = props;
	const onClick = (e: any) => {
		e.preventDefault();
		var selectionSliceContent = view.state.selection.content();
		var fragment = selectionSliceContent.content;
		var sliceJson = selectionSliceContent.toJSON();
		if (sliceJson) {
			let fragmentJson = sliceJson.content;
			if (fragment.childCount == 1) {
				let nodes = fragmentJson[0].content as any[];
				let updatedList = [doNotTranslateStartTextNode, ...nodes, doNotTranslateEndTextNode];
				fragmentJson[0].content = updatedList;
			} else if (fragment.childCount > 1) {
				let firstChildNodeList = fragmentJson[0].content as any[];
				let updatedFirstChildNodeList = [doNotTranslateStartTextNode, ...firstChildNodeList];
				fragmentJson[0].content = updatedFirstChildNodeList;

				let lastChildNodeList = fragmentJson[fragment.childCount - 1].content as any[];
				let updatedLastChildNodeList = [...lastChildNodeList, doNotTranslateEndTextNode];
				fragmentJson[fragment.childCount - 1].content = updatedLastChildNodeList;
			}
			sliceJson.content = fragmentJson;
			let newSlice = Slice.fromJSON<any>(view.state.schema, sliceJson);
			view.dispatch(view.state.tr.replace(view.state.selection.from, view.state.selection.to, newSlice));
		}
	};

	return (
		<Button
			onClick={onClick}
			disabled={view && view.state.selection && view.state.selection.from == view.state.selection.to}
			onMouseDown={e => e.preventDefault()}
			onPointerDown={e => e.preventDefault()}
			className="ai-white"
			title={localizationService.getLocalizedString('languages.doNotTranslate')}
			id="do-not-translate">
			{localizationService.getLocalizedString('languages.doNotTranslate')}
		</Button>
	);
};
