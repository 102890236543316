import React, { useContext, useEffect, useState, useRef } from 'react';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, validationService } from 'src/services';
import { TextInput, SingleSelectDropdown } from 'src/components/widgets';
import {
	AddGeneralNoticeSteps,
	GeneralNoticeTemplate,
	MarginUnits,
	NoticeTemplateType,
	printMarginUnitOptions
} from '@rcp/types';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { alertService, RootState } from 'src/redux';
import { useSelector } from 'react-redux';
import './steps.scss';
import _ from 'lodash';

interface FormField extends GeneralNoticeTemplate {}

const initialFormField: FormField = {
	templateBodyContent:
		'<p><br></p><p>{{Facility - Facility Name}} ({{Facility - Facility Number}})<br>{{Facility - Address}} {{Facility - Address 2}}<br>{{Facility - City}} {{Facility - State}} {{Facility - Zip}}</p><p>{{System - Current Date}}</p><p>Dear {{Contact - First Name}} {{Contact - Last Name}}</p><p>Add body text here,</p><p>Sincerely</p><p>{{Authority - Authority Name}}<br>{{Authority - Contact Name}}<br>{{Authority - Email}}<br>{{Authority - Phone}}</p>',
	templateHeaderContent:
		'<table><tbody><tr><td><p>{{Authority - Authority Logo}}</p></td><td><p style="text-align: center;"><strong>{{Authority - Authority Name}}</strong></p></td><td><p style="text-align: right;">{{Authority - Address}}<br>{{Authority - Phone}}<br></p></td></tr></tbody></table>',
	templateFooterContent: '<p style="text-align: right;">{{Letter - Page Number}}</p>',
	marginLeft: 1,
	marginRight: 1,
	marginBottom: 0.5,
	marginTop: 0.5,
	printMarginUnit: MarginUnits.Inches
};

export const Step2 = () => {
	const stepperContext = useContext(StepperContext);
	const [formField, setFormField] = useState<FormField>(initialFormField);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);
	let generalNoticeTemplateSchedule = (state: RootState) => state.generalNoticeTemplate;
	let { selected: generalNoticeTemplate } = useSelector(generalNoticeTemplateSchedule);

	const nextButtonClickedRef = useRef<any>(null);
	const noticeContentRef = useRef<any>(null);
	noticeContentRef.current = formField;

	useEffect(() => {
		const step2Data = stepperContext.getData(AddGeneralNoticeSteps.STEP2);
		if (step2Data) {
			setFormField({ ...formField, ...step2Data });
		} else if (generalNoticeTemplate) {
			let {
				templateBodyContent,
				templateHeaderContent,
				templateFooterContent,
				printMarginUnit,
				marginBottom,
				marginLeft,
				marginRight,
				marginTop
			} = generalNoticeTemplate;
			setFormField({
				templateBodyContent,
				templateHeaderContent,
				templateFooterContent,
				printMarginUnit,
				marginBottom,
				marginLeft,
				marginRight,
				marginTop
			});
		}
		return () => {
			const isFormValid = !nextButtonClickedRef.current ? !!noticeContentRef.current.templateBodyContent : true;
			!nextButtonClickedRef.current && updateStepOnUnmount();
			if (!isFormValid) {
				alertService.addError(
					localizationService.getLocalizedString('authoritySetting.noticesSettings.templateBodyRequired')
				);
				stepperContext.goAt(AddGeneralNoticeSteps.STEP2);
			}
		};
	}, [generalNoticeTemplate]);

	const updateStepOnUnmount = () => {
		let dataToBeSaved = noticeContentRef.current;
		const updatedStep3 = {
			...stepperContext.getStep(AddGeneralNoticeSteps.STEP2),
			data: { ...dataToBeSaved }
		};
		stepperContext.updateStep(AddGeneralNoticeSteps.STEP2, updatedStep3);
	};

	const isFormValid = () => {
		return validationService.validateMergeFields(
			(formField.templateBodyContent || '') +
				(formField.templateFooterContent || '') +
				(formField.templateHeaderContent || ''),
			mergeFields
		);
	};

	const onClickNext = () => {
		nextButtonClickedRef.current = true;
		if (!isFormValid()) {
			return;
		}
		if (formField.templateBodyContent) {
			stepperContext.resolve({ ...formField });
			return;
		}
		alertService.addError(
			localizationService.getLocalizedString('authoritySetting.noticesSettings.templateBodyRequired')
		);
	};

	const onClickBack = () => stepperContext.goAt(AddGeneralNoticeSteps.STEP1);

	const changeFormField = (e: any) => {
		let newState = { ...formField };
		let { type, name, value } = e.target;
		let changedValue = value;
		if (type === 'number') {
			if (value < 0) return;
			if (value.includes('.')) {
				if (value.split('.')[1].length > 3) {
					return;
				}
			}
		}
		_.set(newState, name, value);
		setFormField(newState);
	};
	return (
		<StepperContent
			className="w-100"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="add-general-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.header')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateHeaderContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateHeaderContent: event.html });
					}}
					height="80px"
					isFixedHeight
					editorIndex={0}
					showTableTools={true}
				/>
			</div>
			<div className="add-general-notice-editor-template form-group required general-notice-body">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.body')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateBodyContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateBodyContent: event.html });
					}}
					isLetterTemplate
					height="467px"
					editorIndex={1}
					showTableTools={true}
					hidePageNumber
				/>
			</div>
			<div className="add-general-notice-editor-template mb-3">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.footer')}</label>
				<RichTextEditor
					noticeTemplateType={NoticeTemplateType.FogGeneralFacilityNotice}
					editorContent={formField.templateFooterContent}
					onChange={(event: any) => {
						setFormField({ ...formField, templateFooterContent: event.html });
					}}
					height="60px"
					editorIndex={2}
					isFixedHeight
					showTableTools={true}
				/>
			</div>
			<div>
				<p>{localizationService.getLocalizedString('authoritySetting.noticesSettings.printMargins')}</p>
				<div className="form-row">
					<TextInput
						id="marginTop"
						name="marginTop"
						type="number"
						className="col"
						onChange={changeFormField}
						value={formField.marginTop || '0'}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.top')}
					/>

					<TextInput
						id="marginBottom"
						name="marginBottom"
						type="number"
						className="col"
						value={formField.marginBottom || '0'}
						onChange={changeFormField}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.bottom')}
					/>

					<TextInput
						id="marginLeft"
						name="marginLeft"
						value={formField.marginLeft || '0'}
						type="number"
						className="col"
						onChange={changeFormField}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.left')}
					/>

					<TextInput
						id="marginRight"
						name="marginRight"
						value={formField.marginRight || '0'}
						className="col"
						onChange={changeFormField}
						type="number"
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.right')}
					/>

					<SingleSelectDropdown
						id="printMarginUnit"
						name="printMarginUnit"
						onChange={changeFormField}
						value={formField.printMarginUnit}
						className="col"
						noEmptyOption={true}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.units')}
						options={printMarginUnitOptions}
					/>
				</div>
			</div>
		</StepperContent>
	);
};
