import _ from 'lodash';
import React from 'react';

import {
	PopoverModal,
	TextInput,
	SingleCheckbox,
	SingleSelectDropdown,
	DeleteModalProp,
	TextAreaInput,
	DeleteModal
} from 'src/components/widgets';
import { DropDownOption, Frequency, CccDevice } from '@rcp/types';
import { validationService, localizationService, optionsMap } from 'src/services';
import {
	alertService,
	loadFrequencyList,
	useReduxDispatch,
	loadAuthorityCccDeviceTypes,
	useReduxSelector,
	RootState
} from 'src/redux';
import { cccDeviceSlice } from './devices.slice';
import { useSelector } from 'react-redux';
import { nameof } from 'ts-simple-nameof';

interface OwnProps {
	showEditModal: boolean;
	setShowModal: () => void;
	cccDeviceId?: number;
	isAddModal: boolean;
}

type Props = OwnProps;

interface FormFields {
	deviceId?: number;
	manufacturer?: string;
	model?: string;
	deviceTypeId?: number;
	deviceTypeCode?: string;
	deviceSize?: string;
	description?: string;
	testFrequencyId?: number;
	testFrequencyCode?: string;
	testable?: boolean;
	leadFree?: boolean;
	isActive?: boolean;

	deviceTypeError?: string;
	manufacturerError?: string;
	modelError?: string;
	deviceSizeError?: string;
}

const initialFormFields: FormFields = {
	isActive: true
};

const CccDeviceEditModalComponent: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [currentCccDevice, setCurrentDevice] = React.useState({} as CccDevice);
	const [formState, setFormState] = React.useState(initialFormFields);
	const [frequencyOptionValues, setFrequencyOptionValues] = React.useState([] as DropDownOption[]);
	const [deviceTypeOptionValues, setDeviceTypeOptionValues] = React.useState([] as DropDownOption[]);
	const [deleteCccDeviceModal, setToggleDeleteCccDeviceModal] = React.useState(false);

	const frequencyList = useReduxSelector(state => state.extractors.frequencyList);
	const cccDeviceTypes = useReduxSelector(state => state.authoritySettingLookups.cccDeviceTypes);

	const getNewFormFieldState = (cccDevice: CccDevice) => {
		const newState: FormFields = { ...cccDevice };

		return newState;
	};

	React.useEffect(() => {
		if (!props.isAddModal && props.cccDeviceId) {
			dispatch(cccDeviceSlice.fetchOne(props.cccDeviceId));
		}
	}, [props.isAddModal, props.cccDeviceId, dispatch]);

	let cccDevicesState = (state: RootState) => state.cccDevices;
	let selected = useSelector(cccDevicesState).selected;

	React.useEffect(() => {
		if (!props.isAddModal && selected) {
			setCurrentDevice(selected);
		}
	}, [props.isAddModal, selected]);

	React.useEffect(() => {
		if (!props.isAddModal && currentCccDevice) {
			const newState: FormFields = getNewFormFieldState(currentCccDevice);
			setFormState(newState);
		}
	}, [props.isAddModal, currentCccDevice]);

	React.useEffect(() => {
		dispatch(loadFrequencyList());
		dispatch(loadAuthorityCccDeviceTypes());
	}, [dispatch]);
	React.useEffect(() => {
		let frequencyOptionValues: DropDownOption[] = [];

		if (frequencyList.length > 0) {
			frequencyOptionValues = frequencyList
				.filter(x => x.isActive)
				.map((frequency: Frequency) => {
					return { label: frequency.frequencyCode, value: frequency.frequencyId };
				});
		}

		if (currentCccDevice) {
			let testFrequencyId = currentCccDevice.testFrequencyId;

			if (testFrequencyId) {
				let selectedTestFrequency = frequencyList.find(a => a.frequencyId === testFrequencyId);
				if (selectedTestFrequency && !selectedTestFrequency.isActive) {
					frequencyOptionValues.push({
						label: selectedTestFrequency.frequencyCode,
						value: selectedTestFrequency.frequencyId,
						isHidden: true
					});
				}
			}
			setFrequencyOptionValues(frequencyOptionValues);
		}
	}, [frequencyList, currentCccDevice]);

	React.useEffect(() => {
		if (cccDeviceTypes && cccDeviceTypes.length > 0) {
			let options: DropDownOption[] = optionsMap.fromLookups(cccDeviceTypes);
			setDeviceTypeOptionValues(options);
		}
	}, [cccDeviceTypes]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		_.set(newState, name, value);

		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			nameof<FormFields>(f => f.deviceTypeId),
			nameof<FormFields>(f => f.deviceTypeError)
		);
		validationService.validateRequiredField(
			newState,
			nameof<FormFields>(f => f.manufacturer),
			nameof<FormFields>(f => f.manufacturerError)
		);
		validationService.validateRequiredField(
			newState,
			nameof<FormFields>(f => f.model),
			nameof<FormFields>(f => f.modelError)
		);
		validationService.validateRequiredField(
			newState,
			nameof<FormFields>(f => f.deviceSize),
			nameof<FormFields>(f => f.deviceSizeError)
		);

		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			nameof<FormFields>(f => f.deviceTypeError),
			nameof<FormFields>(f => f.manufacturerError),
			nameof<FormFields>(f => f.modelError),
			nameof<FormFields>(f => f.deviceSizeError)
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const resetCccDeviceForm = () => {
		if (props.isAddModal) {
			setFormState(initialFormFields);
		} else {
			if (currentCccDevice) {
				const newState: FormFields = getNewFormFieldState(currentCccDevice);
				setFormState(newState);
			}
		}
	};

	const saveCccDevice = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let cccDeviceToAddOrUpdate: any = {};

		cccDeviceToAddOrUpdate.manufacturer = formState.manufacturer;
		cccDeviceToAddOrUpdate.model = formState.model;
		cccDeviceToAddOrUpdate.deviceTypeId = formState.deviceTypeId;
		cccDeviceToAddOrUpdate.deviceSize = formState.deviceSize;
		cccDeviceToAddOrUpdate.description = formState.description;
		cccDeviceToAddOrUpdate.testFrequencyId = formState.testFrequencyId;
		cccDeviceToAddOrUpdate.testable = formState.testable;
		cccDeviceToAddOrUpdate.leadFree = formState.leadFree;
		cccDeviceToAddOrUpdate.isActive = formState.isActive !== undefined ? formState.isActive : false;

		if (props.isAddModal) {
			dispatch(
				cccDeviceSlice.createOne(
					cccDeviceToAddOrUpdate,
					true,
					localizationService.getLocalizedString('alertMessages.addSuccess', 'cccDevice.device'),
					() => {
						resetCccDeviceForm();
						props.setShowModal();
					}
				)
			);
		} else {
			if (currentCccDevice) {
				cccDeviceToAddOrUpdate.deviceId = currentCccDevice.deviceId;
				dispatch(
					cccDeviceSlice.patchOne(
						cccDeviceToAddOrUpdate.deviceId,
						cccDeviceToAddOrUpdate,
						true,
						localizationService.getLocalizedString('alertMessages.updateSuccess', 'cccDevice.device'),
						() => {
							resetCccDeviceForm();
							props.setShowModal();
						}
					)
				);
			}
		}
	};

	const cancelSaveCccDevice = () => {
		resetCccDeviceForm();
		props.setShowModal();
	};

	const onDeleteCccDevice = async () => {
		if (currentCccDevice && currentCccDevice.deviceId) {
			dispatch(
				cccDeviceSlice.deleteOne(
					currentCccDevice.deviceId,
					true,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'cccDevice.device'),
					() => {
						onToggleDeleteCccDeviceModal();
						if (props.showEditModal) {
							props.setShowModal();
						}
					}
				)
			);
		}
	};

	const onToggleDeleteCccDeviceModal = () => {
		setToggleDeleteCccDeviceModal(!deleteCccDeviceModal);
	};

	let deleteCccDeviceModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('cccDevice.deleteDevice'),
		message: localizationService.getLocalizedString('cccDevice.deleteDeviceMessage'),
		showModal: deleteCccDeviceModal,
		onCancelButtonClick: onToggleDeleteCccDeviceModal,
		onOkayButtonClick: onDeleteCccDevice,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const modalFooterDiv = (
		<>
			{!props.isAddModal ? (
				<button
					type="button"
					className="btn ai-secondary-delete mr-auto"
					onClick={onToggleDeleteCccDeviceModal}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
			) : null}
			<button className="btn ai-save" onClick={saveCccDevice}>
				{localizationService.getLocalizedString('screen.buttons.save')}
			</button>
			<button className="btn ai-white" onClick={cancelSaveCccDevice}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</>
	);

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showEditModal}
				title={
					props.isAddModal
						? localizationService.getLocalizedString('cccDevice.addDevice')
						: localizationService.getLocalizedString('cccDevice.editDevice')
				}
				save={saveCccDevice}
				cancel={cancelSaveCccDevice}
				footer={modalFooterDiv}>
				<TextInput
					id="manufacturer"
					name="manufacturer"
					label={localizationService.getLocalizedString('cccDevice.manufacturer')}
					value={formState.manufacturer}
					onChange={changeFormState}
					isRequired={true}
					error={formState.manufacturerError}
				/>
				<TextInput
					id="model"
					name="model"
					label={localizationService.getLocalizedString('cccDevice.model')}
					value={formState.model}
					onChange={changeFormState}
					isRequired={true}
					error={formState.modelError}
				/>
				<SingleSelectDropdown
					id="deviceTypeId"
					name="deviceTypeId"
					label={localizationService.getLocalizedString('cccDevice.deviceTypeId')}
					value={_.toString(formState.deviceTypeId)}
					onChange={changeFormState}
					options={deviceTypeOptionValues}
					isRequired={true}
					error={formState.deviceTypeError}
				/>
				<TextInput
					id="deviceSize"
					name="deviceSize"
					label={localizationService.getLocalizedString('cccDevice.deviceSize')}
					value={formState.deviceSize}
					onChange={changeFormState}
					isRequired={true}
					error={formState.deviceSizeError}
				/>

				<TextAreaInput
					id="description"
					name="description"
					label={localizationService.getLocalizedString('cccDevice.description')}
					value={formState.description}
					isFullWidth={true}
					rows={4}
					onChange={changeFormState}
				/>

				<SingleSelectDropdown
					id="testFrequencyId"
					name="testFrequencyId"
					label={localizationService.getLocalizedString('cccDevice.testFrequencyId')}
					value={_.toString(formState.testFrequencyId)}
					onChange={changeFormState}
					options={frequencyOptionValues}
					selfOrder={true}
				/>

				<SingleCheckbox
					id="testable"
					name="testable"
					label={
						<>
							<strong>{localizationService.getLocalizedString('cccDevice.testable')}</strong>
						</>
					}
					checked={formState.testable}
					onChange={changeFormState}
					className="div-checkbox"
				/>

				<SingleCheckbox
					id="leadFree"
					name="leadFree"
					label={
						<>
							<strong>{localizationService.getLocalizedString('cccDevice.leadFree')}</strong>
						</>
					}
					checked={formState.leadFree}
					onChange={changeFormState}
					className="div-checkbox"
				/>

				<SingleCheckbox
					id="isActive"
					name="isActive"
					label={
						<>
							<strong>{localizationService.getLocalizedString('cccDevice.isActive')}</strong> -{' '}
							<span>{localizationService.getLocalizedString('cccDevice.isActiveNote')}</span>
						</>
					}
					checked={formState.isActive}
					onChange={changeFormState}
					className="div-checkbox"
				/>
			</PopoverModal>
			<DeleteModal {...deleteCccDeviceModalProp} key="deleteCccDeviceModal" />
		</div>
	);
};

export const CccDeviceEditModal = CccDeviceEditModalComponent;
