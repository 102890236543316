import { LocalStorageName } from '@rcp/types';
import React, { useContext, useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useRootStateSelector } from 'src/redux';
import { authoritySettingService, localStorageService, urlService } from 'src/services';
import { LanguageContext } from './translator-context';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loadLanguages } from 'src/redux/languages/languages';
import MicroEmitter from 'micro-emitter';
import { EmitterEvents } from 'src/constants';
let emitter = new MicroEmitter();

export const isNewPasswordPage = (viewLanguageSupport: boolean) => {
	emitter.emit(EmitterEvents.NewPasswordLoaded, viewLanguageSupport);
};

export const LanguageSelectDropdown = () => {
	let dispatch = useDispatch();
	const { language, setLanguage } = useContext(LanguageContext);
	const languages = useRootStateSelector(s => s.languages).languages;
	const [isVisible, setIsVisible] = React.useState<boolean>(false);
	const [isNewPasswordPage, setIsNewPasswordPage] = React.useState(false);

	React.useEffect(() => {
		emitter.addListener(EmitterEvents.NewPasswordLoaded, setNewPasswordPage);
		emitter.on(EmitterEvents.NewPasswordLoaded, setNewPasswordPage);

		return () => {
			emitter.removeListener(EmitterEvents.NewPasswordLoaded, setNewPasswordPage);
			emitter.off(EmitterEvents.NewPasswordLoaded, setNewPasswordPage);
		};
	}, []);

	function setNewPasswordPage(viewStatus: boolean) {
		setIsNewPasswordPage(viewStatus);
	}
	useEffect(() => {
		if (isVisible) {
			dispatch(loadLanguages());
		}
	}, [isVisible]);

	useEffect(() => {
		isLanguageVisible();
	}, [window.location.href]);

	const isLanguageVisible = async () => {
		let isLanguageSelectorVisible = await authoritySettingService.isLanguageSelectorAvailable();
		setIsVisible(isLanguageSelectorVisible);
	};

	const uncontrolledDropdown = () => {
		return (isVisible && !urlService.isSignInPage()) || isNewPasswordPage ? (
			<>
				<UncontrolledDropdown className="action-item-black" id="languageSelectDropdown">
					<DropdownToggle id="languageSelectDropdownValue" tag="a" className="btn" caret>
						{language.nativeName}
					</DropdownToggle>
					<DropdownMenu right>
						{languages
							.sort((a, b) => (a.name > b.name ? 1 : -1))
							.map(lang => {
								return (
									<DropdownItem
										tag="a"
										id={`languageSelect_${lang.name}`}
										key={lang.code}
										onClick={() => {
											setLanguage(lang);
											localStorageService.setLocalStorage(LocalStorageName.DisplayLanguage, lang);
										}}>
										{lang.nativeName}
									</DropdownItem>
								);
							})}
					</DropdownMenu>
				</UncontrolledDropdown>
			</>
		) : (
			<></>
		);
	};

	return uncontrolledDropdown();
};
