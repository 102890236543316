import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { Sortable } from '@progress/kendo-react-sortable';
import { CloseSvg } from '../../../svg';
import { localStorageService, localizationService, Resource, urlService, apiService } from 'src/services';

import * as ApiTypes from '@rcp/types';
import { SingleCheckbox } from 'src/components/widgets';
import { alertService } from 'src/redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export interface ReorderWidgetProp {
	showModal: boolean;
	widgets: ApiTypes.DashboardWidgetDefinition[];
	onChanged: () => void;
	toggle: () => void;
	localStorageName: string;
	reload: () => void;
}

const ReorderWidgets: React.FC<ReorderWidgetProp> = props => {
	const [widgetDefinitions, setColumnDefinitions] = useState(
		props.widgets || ([] as ApiTypes.DashboardWidgetDefinition[])
	);
	const [showResetToDefault, setShowResetToDefault] = React.useState(false as boolean);
	const defaultList = useRef<any>(null);

	React.useEffect(() => {
		let columns = _.cloneDeep(props.widgets);
		setColumnDefinitions(columns);
	}, [props.widgets]);

	let onResetToDefault = (e: any) => {
		e.preventDefault();
		const url = `${urlService.getAuthorityResourcesApiUrl(Resource.Dashboard)}/Reset`;
		apiService
			.getResource(url)
			.then(() => {
				setShowResetToDefault(!showResetToDefault);
				props.reload();
				window.setTimeout(function() {
					alertService.addSuccess(localizationService.getLocalizedString('dashboard.resetSuccess'));
				}, 500);
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
	};

	let onResetToDefaultModalToggle = (e: any) => {
		e.preventDefault();
		setShowResetToDefault(!showResetToDefault);
	};

	const onCancelBtnClick = () => {
		props.toggle();
	};

	const savingToLocal = (widgets: ApiTypes.DashboardWidgetDefinition[]) => {
		localStorageService.setLocalStorage(props.localStorageName, widgets);
		setTimeout(() => props.onChanged(), 100);
	};

	const isLastSelectedColumn = (widgets: ApiTypes.DashboardWidgetDefinition[]): boolean => {
		return widgets.filter(c => c.visible === true).length === 1;
	};

	const toggleDisabledIfVisible = (widgets: ApiTypes.DashboardWidgetDefinition[], disabled: boolean) => {
		widgets.forEach(a => {
			if (a.visible) {
				a.disabled = disabled;
			}
		});
	};

	const onCheckBoxChanged = (e: any) => {
		let id = e.target.id;
		let keys = widgetDefinitions.map(i => `id_${i.dashboardWidgetId}`);
		let index = keys.indexOf(id);
		const newWidgetDefinitions = [...widgetDefinitions];
		newWidgetDefinitions[index].visible = e.target.checked;
		if (isLastSelectedColumn(newWidgetDefinitions)) {
			toggleDisabledIfVisible(newWidgetDefinitions, true);
		} else {
			toggleDisabledIfVisible(newWidgetDefinitions, false);
		}
		setColumnDefinitions(newWidgetDefinitions);

		savingToLocal(newWidgetDefinitions);
	};

	const onDragOver = (event: any) => {
		setColumnDefinitions(event.newState);
		savingToLocal(event.newState);
	};

	const getBaseItemStyle = () => ({
		cursor: 'move',
		position: 'relative',
		paddingLeft: '0px'
	});

	const sortableItemUI = (props: any) => {
		const { style, attributes, dataItem, forwardRef } = props;
		return (
			<div
				{...attributes}
				className={'lookup-row d-flex p-2'}
				ref={forwardRef}
				style={{
					...style,
					...getBaseItemStyle()
				}}>
				<SingleCheckbox
					className={'mb-0'}
					classNameForLabel={'custom-control-label cursor-move'}
					id={`id_${dataItem.dashboardWidgetId}`}
					name={`name_${dataItem.dashboardWidgetId}`}
					checked={dataItem.visible}
					isDisabled={dataItem.disabled}
					label={dataItem.title}
					onChange={e => onCheckBoxChanged(e)}
				/>
			</div>
		);
	};

	return props.showModal === false ? null : (
		<>
			<form className="pl-0 pl-lg-3">
				<div className="column-editor d-flex flex-column">
					<h2 className="d-flex">
						{localizationService.getLocalizedString('dashboard.dashboardWidgetSettingTitle')}
						<button className="close ml-auto column-editor-close" onClick={onCancelBtnClick} autoFocus>
							<CloseSvg />
						</button>
					</h2>
					<p>{localizationService.getLocalizedString('dashboard.dashboardWidgetSettingDescriptionPart1')}</p>
					<p>
						<a href="#/" onClick={onResetToDefaultModalToggle}>
							{localizationService.getLocalizedString('dashboard.dashboardWidgetSettingDescriptionPart2')}
						</a>
					</p>
					<div className="lookup-row"></div>
					<Sortable
						ref={defaultList}
						idField={'dashboardWidgetId'}
						style={{ display: 'contents' }}
						disabledField={'disabled'}
						data={widgetDefinitions}
						itemUI={sortableItemUI}
						onDragOver={onDragOver}
					/>
				</div>
			</form>

			{showResetToDefault && (
				<Modal
					isOpen={true}
					toggle={onResetToDefaultModalToggle}
					centered={true}
					backdrop={true}
					size="lg"
					id="resetWidgetsModal">
					<ModalHeader
						tag="h1"
						toggle={onResetToDefaultModalToggle}
						close={
							<button className="close" onClick={onResetToDefaultModalToggle}>
								<CloseSvg />
							</button>
						}>
						{localizationService.getLocalizedString('dashboard.resetModalTitle')}
					</ModalHeader>
					<ModalBody>
						<div>
							<p>{localizationService.getLocalizedString('dashboard.resetModalDescription')}</p>
						</div>
					</ModalBody>
					<ModalFooter>
						<button className="btn ai-delete ml-auto" id="resetButton" onClick={onResetToDefault}>
							{localizationService.getLocalizedString('dashboard.reset')}
						</button>
						<button className="btn ai-white" onClick={onResetToDefaultModalToggle}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};

export default ReorderWidgets;
