import React, { useEffect, useRef, useState } from 'react';
import {
	CleaningNoticeTemplateSchedule,
	DeviceCleaningNoticeSteps,
	Dictionary,
	LetterTemplateTranslation,
	NoticeTemplateType
} from '@rcp/types';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import {
	apiService,
	authoritySettingService,
	localizationService,
	Logger,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { alertService, RootState } from 'src/redux';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import './step3.scss';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TextInput } from 'src/components/widgets';
import { useSelector } from 'react-redux';
import { DoNotTranslateButton } from 'src/components/widgets/do-not-translate-button';
import { Utils } from 'src/services/utils';
import { LetterTemplateTranslations } from '../../../settings/notices';
import _ from 'lodash';

interface FormFields extends CleaningNoticeTemplateSchedule {
	templateSubjectError?: string;
	templateContentError?: string;
}

const initialNoticeContent: FormFields = {
	templateSubjectError: '',
	templateContentError: ''
};

export const Step3 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [noticeContent, setNoticeContent] = React.useState(initialNoticeContent);
	const [letterTemplateTranslations, setLetterTemplateTranslations] = useState<Dictionary<LetterTemplateTranslation>>(
		{}
	);
	const [subjectCursorStartPosition, setSubjectCursorStartPosition] = React.useState(0);
	const [subjectCursorEndPosition, setSubjectCursorEndPosition] = React.useState(0);
	const nextButtonClickedRef = useRef<any>(null);
	const noticeContentRef = useRef<any>(null);
	noticeContentRef.current = noticeContent;

	const { STEP3, STEP2, STEP1, STEP4 } = DeviceCleaningNoticeSteps;
	const step1Data = stepperContext.getData(STEP1);
	const updatedStep1 = { ...stepperContext.getStep(STEP1), data: { ...step1Data, isDataChanged: false } };
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);

	const fetchNoticeTemplate = () => {
		const url = urlService.getAuthoritySettingResourceApiUrl(
			`${Resource.CleaningNoticeTemplateSchedules}/${step1Data.noticeTemplateId}`
		);
		apiService.getResource(url, true).then((data: any) => {
			setNoticeContent(data);
		});
	};

	React.useEffect(() => {
		const step3Data = stepperContext.getData(STEP3);
		if (step1Data.isDataChanged) {
			fetchNoticeTemplate();
		} else if (step3Data && step3Data.noticeContent) {
			setNoticeContent(step3Data.noticeContent);
		} else {
			fetchNoticeTemplate();
		}

		return () => {
			const isFormValid = !nextButtonClickedRef.current ? isFormValidForNext() : true;
			!nextButtonClickedRef.current && updateStepOnUnmount();
			if (!isFormValid) {
				stepperContext.goAt(STEP3);
			} else {
				stepperContext.updateStep(STEP1, updatedStep1);
			}
		};
	}, []);

	const [hasLanguageSupport, setHasLanguageSupport] = useState(false);

	useEffect(() => {
		authoritySettingService.getAuthorityLanguageSupportStatus(setHasLanguageSupport);
	}, [hasLanguageSupport]);

	const updateStepOnUnmount = () => {
		let dataToBeSaved = noticeContentRef.current;
		const updatedStep3 = {
			...stepperContext.getStep(STEP3),
			data: { noticeContent: dataToBeSaved }
		};
		stepperContext.updateStep(STEP3, updatedStep3);
	};

	const onClickBack = () => stepperContext.goAt(STEP2);

	const onClickNext = () => {
		nextButtonClickedRef.current = true;
		if (!isFormValidForNext()) {
			return;
		}
		let newState = { ...noticeContent };
		newState.letterTemplateTranslationsJson = JSON.stringify(letterTemplateTranslations);
		Logger.info(`Device send cleaning notice with translations:`, newState.letterTemplateTranslationsJson);
		stepperContext.resolve({ noticeContent: newState });
	};

	const onChangeNotice = (e: any) => {
		const { name, value } = e.target;
		setNoticeContent({ ...noticeContent, [name]: value });
	};

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		Utils.onChangeMergeFieldHandler(
			e,
			noticeContent,
			subjectCursorStartPosition,
			subjectCursorEndPosition,
			setSubjectCursorStartPosition,
			setSubjectCursorEndPosition,
			setNoticeContent
		);
	};

	const onClickDoNotTranslateButton = () => {
		Utils.onClickDoNotTranslate(
			noticeContent,
			subjectCursorStartPosition,
			subjectCursorEndPosition,
			setSubjectCursorStartPosition,
			setSubjectCursorEndPosition,
			setNoticeContent
		);
	};

	const isFormValidForNext = () => {
		const newState = { ...(nextButtonClickedRef.current ? noticeContent : noticeContentRef.current) };
		validationService.validateRequiredField(
			newState,
			'templateSubject',
			'templateSubjectError',
			localizationService.getLocalizedString('extractor.cleaningNotice.subject')
		);

		validationService.validateRequiredField(newState, 'templateContent', 'templateContentError');

		const isFromValid = !validationService.hasError(newState, 'templateSubjectError', 'templateContentError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return (
			isFromValid &&
			validationService.validateMergeFields(noticeContent.templateContent || '', mergeFields) &&
			validationService.validateDoNotTranslateFields(noticeContent.templateSubject || '') &&
			validationService.validateMergeFields(noticeContent.templateSubject || '', mergeFields) &&
			validationService.validateDoNotTranslateFields(noticeContent.templateContent || '')
		);
	};

	return (
		<StepperContent
			className="w-100"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.back')}
					</StepperAction>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-row mb-2">
				<TextInput
					id="subject"
					name="templateSubject"
					className="col-sm-8 form-group"
					value={noticeContent.templateSubject}
					label={localizationService.getLocalizedString('extractor.cleaningNotice.subject')}
					onChange={onChangeNotice}
					isRequired
					onBlur={(e: any) => {
						setSubjectCursorStartPosition(e.target.selectionStart);
						setSubjectCursorEndPosition(e.target.selectionEnd);
					}}
				/>
				<div className="col-sm-4 d-inline-block">
					<MergeFieldDropDown
						id="mergeTags"
						name="mergeTags"
						className="col-sm-4"
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.mergeTags')}
						hintText={localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.mergeTagsHint'
						)}
						onChange={onChangeMergeField}
					/>
					<DoNotTranslateButton
						disabled={
							subjectCursorStartPosition == subjectCursorEndPosition ||
							(noticeContent.templateSubject != null &&
								subjectCursorEndPosition > noticeContent.templateSubject.length)
						}
						onClick={onClickDoNotTranslateButton}
						hasLanguageSupport={hasLanguageSupport}
					/>
				</div>
			</div>
			<div className="form-group required">
				<label>{localizationService.getLocalizedString('extractor.cleaningNotice.body')}</label>
				<div className="cleaning-notice-editor-template">
					<RichTextEditor
						editorContent={noticeContent.templateContent}
						noticeTemplateType={NoticeTemplateType.FogCleaningNotice}
						onChange={(event: any) => {
							setNoticeContent({ ...noticeContent, templateContent: event.html });
						}}
						height="440px"
						isLanguageSupportEnabled={hasLanguageSupport}
					/>
				</div>
			</div>
			{hasLanguageSupport && (
				<LetterTemplateTranslations
					noticeTemplate={noticeContent}
					letterTemplateTranslationsJson={noticeContent.letterTemplateTranslationsJson}
					letterTemplateTranslations={letterTemplateTranslations}
					setLetterTemplateTranslations={setLetterTemplateTranslations}
				/>
			)}
		</StepperContent>
	);
};
