import { Action } from 'redux';
import { AlertState, alertService, initialAlertState } from './alert-service';

export enum AlertActionType {
	AlertUpdated = 'AlertUpdated'
}

export const alertReducer = (state = initialAlertState, action: Action): AlertState => {
	switch (action.type) {
		case AlertActionType.AlertUpdated:
			return alertService.getAlertState();
		default:
			return state;
	}
};
