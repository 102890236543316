import React from 'react';
import videojs from 'video.js';

// Styles
import 'video.js/dist/video-js.css';
import './video-player.scss';

interface Props {
	src: string;
	type: string;
	className: string;
}

export const VideoPlayer: React.SFC<Props> = props => {
	let player: videojs.Player;
	let videoNode: HTMLVideoElement;

	const initialComponent = () => {
		const videoPlayerProps: videojs.PlayerOptions = {
			autoplay: false,
			controls: true,
			sources: [
				{
					src: props.src,
					type: props.type
				}
			]
		};
		player = videojs(videoNode, videoPlayerProps).ready(function() {
			console.debug('onPlayerReady', this);
		});
	};

	React.useEffect(() => {
		initialComponent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		return () => {
			if (player) {
				player.dispose();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'c-player ' + props.className}>
			<div className="c-player-container" data-vjs-player="true">
				<video
					ref={(node: HTMLVideoElement) => (videoNode = node)}
					className="video-js vjs-big-play-centered"
					width="640"
					height="264"
					controls
					preload="auto"
				/>
			</div>
		</div>
	);
};
