import React from 'react';

interface Props {
	isActive?: boolean;
}

export const ChartCountListSvg: React.FC<Props> = props => {
	return (
		<svg
			aria-hidden="true"
			xmlns="http://www.w3.org/2000/svg"
			width={28}
			height={24}
			viewBox="0 0 28 24"
			{...props}>
			<path
				fill={props.isActive ? '#23409a' : '#444'}
				d="M.88 4.3h18v2.12h-18zM24.67 5.46v.86h-.56v1h-1v-1h-2v-.86l1.4-2.76h1.62v2.76zm-2.58 0h1V3.38zM.88 11.3h18v2.12h-18z"
			/>
			<g
				style={{
					isolation: 'isolate'
				}}
				fill={props.isActive ? '#23409a' : '#444'}>
				<path d="M21 11.51v-1l1.29-.75h.88v4.54h-1v-3.48zM23.78 12a3.1 3.1 0 01.42-1.73 1.54 1.54 0 012.5 0 3.1 3.1 0 01.42 1.73 3.11 3.11 0 01-.42 1.72 1.54 1.54 0 01-2.5 0 3.11 3.11 0 01-.42-1.72zm1 0c0 1 .2 1.45.62 1.45s.63-.48.63-1.45-.21-1.46-.63-1.46-.57.46-.57 1.46z" />
			</g>
			<path
				fill={props.isActive ? '#23409a' : '#444'}
				d="M.88 18.3h18v2.12h-18zM21 16.71h3.1v.87l-1.78 3.72h-1.08L23 17.58h-2z"
			/>
		</svg>
	);
};
