import { ThunkAction } from 'redux-thunk';
import { Utils } from 'src/services/utils';
import { IppAASAuthoritySettingsDataApiMapping } from 'src/constants';
import { apiService, localizationService, urlService } from 'src/services';
import { Resource } from 'src/services/url-service';
import EIppAASAuthoritySettingsActionType from './aas-authority-settings-action-types';
import { IppAASAuthoritySettingsState } from './aas-authority-settings-state';

export interface IppAASAuthoritySettingsAction {
	type: EIppAASAuthoritySettingsActionType;
	payload?: IppAASAuthoritySettingsState;
}

type TIppAASAuthoritySettingsThunkAction = ThunkAction<
	any,
	IppAASAuthoritySettingsState,
	any,
	IppAASAuthoritySettingsAction
>;

export const fetchIppAASAuthoritySettingsActionCreator = (
	currentSettingsValue: IppAASAuthoritySettingsState
): TIppAASAuthoritySettingsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings + '/Organization');
	const ippAASAuthoritySettings = await apiService.getAuthoritySettings<IppAASAuthoritySettingsState>(
		url,
		IppAASAuthoritySettingsDataApiMapping,
		currentSettingsValue
	);
	dispatch({
		type: EIppAASAuthoritySettingsActionType.FetchIppAASAuthoritySettingsData,
		payload: ippAASAuthoritySettings
	});
	return true;
};

export const updateIppAASAuthoritySettingsActionCreator = (
	updatedAuthoritySettings: IppAASAuthoritySettingsState
): TIppAASAuthoritySettingsThunkAction => async (dispatch, getState) => {
	dispatch({
		type: EIppAASAuthoritySettingsActionType.UpdateIppAASAuthoritySettingsData,
		payload: updatedAuthoritySettings
	});
	return true;
};

export const saveIppAASAuthoritySettingsActionCreator = (
	finalAuthoritySettings: IppAASAuthoritySettingsState
): TIppAASAuthoritySettingsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
	const postResponse = await apiService.postAuthoritySettings<IppAASAuthoritySettingsState>(
		url,
		finalAuthoritySettings,
		IppAASAuthoritySettingsDataApiMapping
	);
	let notification = { msg: '', type: 'success' };
	const authoritySettingFormKey = 'ipp.authorityAccountSetting.authoritySettingsForm';
	if (postResponse && postResponse.items) {
		notification = {
			msg: localizationService.getLocalizedString(
				'alertMessages.savedSuccess',
				localizationService.getLocalizedString(`${authoritySettingFormKey}.heading`)
			),
			type: 'success'
		};
	}
	dispatch({
		type: EIppAASAuthoritySettingsActionType.SaveIppAASAuthoritySettingsData,
		payload: { ...finalAuthoritySettings, isSaveButtonEnabled: true, notification: notification }
	});
	return true;
};

export const toggleSaveButtonActionCreator = (
	finalAuthoritySettings: IppAASAuthoritySettingsState
): TIppAASAuthoritySettingsThunkAction => async (dispatch, getState) => {
	dispatch({
		type: EIppAASAuthoritySettingsActionType.ToggleSaveButton,
		payload: { ...finalAuthoritySettings, isSaveButtonEnabled: !finalAuthoritySettings.isSaveButtonEnabled }
	});
	return true;
};
