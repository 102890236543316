import React from 'react';
import { localizationService } from 'src/services';
import Stepper, { Step } from 'src/components/widgets/stepper';
import { Step1, Step2, Step3, Step4 } from './cleaning-notice-stepper';
import { DeviceCleaningNoticeSteps, FeatureNames, RouteProps } from '@rcp/types';
import { useReduxSelector } from 'src/redux';
import { NotFound } from 'src/features/home/not-found';

interface Props extends RouteProps {}

export const DeviceNotice: React.FC<Props> = props => {
	const { STEP1, STEP2, STEP3, STEP4 } = DeviceCleaningNoticeSteps;
	return (
		<div className="page">
			<div className="page-header">
				<h1>
					{localizationService.getLocalizedString(
						`extractor.${(props.match.params as any).deviceId ? 'sendNotice' : 'sendNotices'}`
					)}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main-settings">
					<section className="px-3">
						<Stepper vertical initialStep={STEP1}>
							<Step
								stepId={STEP1}
								title={localizationService.getLocalizedString(
									'extractor.cleaningNotice.selectATemplate'
								)}>
								<Step1 />
							</Step>
							<Step
								stepId={STEP2}
								title={localizationService.getLocalizedString(
									'extractor.cleaningNotice.chooseRecipient'
								)}>
								<Step2 />
							</Step>
							<Step
								stepId={STEP3}
								title={localizationService.getLocalizedString('extractor.cleaningNotice.editContent')}>
								<Step3 />
							</Step>
							<Step
								stepId={STEP4}
								title={localizationService.getLocalizedString(
									'extractor.cleaningNotice.previewAndSend'
								)}>
								<Step4 match={props.match} />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};
