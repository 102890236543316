import React, { useState, useEffect } from 'react';
import * as ApiTypes from '@rcp/types';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import HaulerSubmitCleaningModal from '../../components/service-provider/hauler/hauler-facilities/hauler-submit-cleaning/submit-cleaning';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { RootState, useReduxSelector } from '../../redux';
import { useHistory } from 'react-router';
import { PopoverModal } from 'src/components';
import PreviewModal from 'src/components/widgets/preview-email-notice';
import { PreviewNotice } from '@rcp/types';
import { getDateContent } from './timeline-helper';
import {
	reloadTimelineEventsFromServer
} from './timeline-service';
import FacilitySubmitCleaningModal from 'src/components/service-provider/facility/submit-cleaning.tsx/submit-facility-cleaning';
import { SeattleAuthorityConstants } from 'src/constants';

interface OwnProps {
	cleaning: ApiTypes.HaulerCleaningManifest;
	isFacilityDetailsPage?: boolean;
	isDeviceDetailsPage?: boolean;
	callBackAfterSave?: () => void;
	callBackAfterDelete?: () => void;
}
type Props = OwnProps;
const { Facility, Hauler, Authority } = ApiTypes.OrganizationTypeName;
export const TimelineEventCleaningManifest: React.SFC<Props> = props => {
	const [showCleaningModal, setShowCleaningModal] = React.useState(false);
	const [daysLateMessage, setDaysLateMessage] = useState<string>('');
	const [showHaulerModal, setShowHaulerModal] = React.useState<boolean>(false);
	const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
	const [showFacilityUserModal, setShowFacilityUserModal] = useState(false);
	let deviceState = (state: RootState) => state.fogDevices;
	const { selected } = useSelector(deviceState);
	const history = useHistory();
	const { creatorOrganizationType } = props.cleaning;
	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[ApiTypes.FeatureNames.ShowCustomCleaningFormsFeature]
	);

	useEffect(() => {
		let daysLateText = getDateContent(
			DateUtilService.getAuthorityTimezoneNow(),
			'',
			props.cleaning.completeDate,
			props.cleaning.dueDate
		)
			.trim()
			.split(' ')
			.splice(1)
			.join(' ');
		setDaysLateMessage(daysLateText);
	}, [props.cleaning.completeDate, props.cleaning.dueDate]);

	const onPumpOutDetailsClicked = (e: any) => {
		e.preventDefault();
		setShowCleaningModal(true);
	};

	const toggleModal = () => {
		setShowCleaningModal(!showCleaningModal);
	};

	const toggleHaulerInfoModal = () => setShowHaulerModal(!showHaulerModal);

	const toggleFacilityUserInfoModal = () => setShowFacilityUserModal(!showFacilityUserModal);

	const getHaulerAddress = () => {
		const {
			haulerAddressLine1,
			haulerAddressLine2,
			haulerCityName,
			haulerJurisdictionCode,
			haulerZipCode
		} = props.cleaning;
		return (
			<>
				{haulerAddressLine1 ? haulerAddressLine1 + ' ' : ''}
				{haulerAddressLine2 ? haulerAddressLine2 + ' ' : ''}
				{haulerAddressLine1 || haulerAddressLine2 ? <br /> : <></>}
				{haulerCityName ? haulerCityName + ', ' : ''}
				{haulerJurisdictionCode ? haulerJurisdictionCode + ', ' : ''}
				{haulerZipCode}
			</>
		);
	};

	const noContactsToSendEmail = () => {
		if (props.cleaning) {
			return (props.cleaning && (props.cleaning.cleaningSubmissionEmailLogDtos || []).length === 0) ||
				!props.cleaning.cleaningSubmissionEmailLogDtos
				? true
				: false;
		}
		return false;
	};

	const sentCleaningManifestDisplayText = () => {
		if (props.cleaning && props.cleaning.cleaningSubmissionEmailLogDtos) {
			if (props.cleaning.cleaningSubmissionEmailLogDtos.length === 1) {
				let {
					contactFullName,
					recipientEmailAddress,
					companyName
				} = props.cleaning.cleaningSubmissionEmailLogDtos[0];
				return contactFullName.trim() || companyName || recipientEmailAddress;
			} else {
				return `${
					props.cleaning.cleaningSubmissionEmailLogDtos.length
				} ${localizationService.getLocalizedString('screen.labels.contacts').toLowerCase()}`;
			}
		}
	};

	const mapCleaningEmailDetailWithPreviewModal = () => {
		if (props.cleaning && props.cleaning.cleaningSubmissionEmailLogDtos) {
			return props.cleaning.cleaningSubmissionEmailLogDtos.map(emailDetail => {
				const {
					recipientEmailAddress,
					emailSubject,
					emailContent,
					sendEmailAddress,
					ccRecipientEmailAddresses
				} = emailDetail;
				return {
					to: recipientEmailAddress,
					cc: ccRecipientEmailAddresses,
					templateSubject: emailSubject,
					from: sendEmailAddress,
					templateContent: emailContent
				};
			}) as PreviewNotice[];
		}
	};

	const getCleaningModal = () => {
		const { creatorOrganizationType } = props.cleaning;
		const facilityId = !props.isFacilityDetailsPage
			? selected && (selected.facilityId as number)
			: (urlService.getFogFacilityId() as number);
		if (creatorOrganizationType === Hauler) {
			return (
				<HaulerSubmitCleaningModal
					showModal={showCleaningModal}
					onCancel={toggleModal}
					selectedDevices={[]}
					deviceList={[]}
					facilityId={props.cleaning.organizationId}
					cleaningEventId={props.cleaning.cleaningEventId}
					isFacilityDetailsPage={props.isFacilityDetailsPage}
					isEditMode={showCustomCleaningFormsFeature && !!props.cleaning.templateJson}
					readOnly={!showCustomCleaningFormsFeature || !props.cleaning.templateJson}
					authorityOrganizationId={props.cleaning.authorityOrganizationId}
					title={localizationService.getLocalizedString('haulerPortal.cleaningHistory.submittedCleaning')}
					isSeattleFacility={
						props.cleaning.authorityName
							? props.cleaning.authorityName.toLowerCase() ===
							  SeattleAuthorityConstants.authorityName.toLowerCase()
							: undefined
					}
				/>
			);
		} else if (creatorOrganizationType === Facility) {
			return (
				<FacilitySubmitCleaningModal
					title={localizationService.getLocalizedString('haulerPortal.submitCleaning.submittedCleaning')}
					showModal={showCleaningModal}
					onCancel={toggleModal}
					isEditMode={showCustomCleaningFormsFeature && !!props.cleaning.templateJson}
					readOnly={!showCustomCleaningFormsFeature || !props.cleaning.templateJson}
					facilityId={facilityId || 0}
					isFacilityDetailsPage={props.isFacilityDetailsPage}
					showDeleteButton
					cleaningEventId={props.cleaning.cleaningEventId}
				/>
			);
		}
	};

	return (
		<div>
			<div className="pointer-cursor">
				{props.isFacilityDetailsPage && props.cleaning ? (
					<>
						<a
							href="#/"
							onClick={onPumpOutDetailsClicked}
							id={props.cleaning.completeDate ? `completeTimelineCleaning` : 'timelineCleaning'}>
							{localizationService.getLocalizedString('pumpOut.cleaning')}
						</a>
						{localizationService.getLocalizedString('facility.cleaningTimelineConnector')}
						{props.cleaning.isDeviceRemoved ? (
							<>
								<span>
									{localizationService.getLocalizedString(
										'facility.deviceDetailsTimelineRef',
										props.cleaning.deviceNumber as string
									)}
								</span>
								<span className="text-muted">
									{localizationService.getLocalizedString('facility.deviceRemoved')}
								</span>
							</>
						) : (
							<a
								href="#/"
								onClick={event => {
									navigateTo(
										history,
										urlService.getReactAuthorityResourcePath(
											Resource.Devices,
											props.cleaning.deviceId
										),
										event
									);
								}}
								id={`timelineCleaningDevice-${props.cleaning.deviceNumber}`}
								className="ai-link">
								{localizationService.getLocalizedString(
									'facility.deviceDetailsTimelineRef',
									props.cleaning.deviceNumber as string
								)}
							</a>
						)}
						<span>
							{localizationService.getLocalizedString(
								'pumpOut.haulerPortalTimelineConnector',
								daysLateMessage
							)}
						</span>
						&nbsp;
						{creatorOrganizationType === Facility ? (
							<>
								<span>
									{localizationService.getLocalizedString('pumpOut.the')}
									&nbsp;
									{_.toLower(creatorOrganizationType)}
									&nbsp;
									{localizationService.getLocalizedString('pumpOut.user')}
								</span>
								&nbsp;
								<a
									className="ai-link"
									href="#/"
									onClick={e => {
										e.preventDefault();
										toggleFacilityUserInfoModal();
									}}>
									{props.cleaning.submitterUserFullName}
								</a>
							</>
						) : (
							<a
								href="#/"
								className="ai-link"
								onClick={event => {
									event.preventDefault();
									toggleHaulerInfoModal();
								}}>
								{props.cleaning.haulerNumber}
							</a>
						)}
						{!noContactsToSendEmail() &&
							props.cleaning.creatorOrganizationType !== ApiTypes.OrganizationTypeName.Facility && (
								<span>
									{localizationService.getLocalizedString('pumpOut.and')}
									<a
										href="#/"
										className="ai-link"
										onClick={event => {
											event.preventDefault();
											setShowPreviewModal(!showPreviewModal);
										}}>
										{localizationService.getLocalizedString('pumpOut.cleaningSubmissionEmail')}
									</a>
									&nbsp;
									{localizationService.getLocalizedString('pumpOut.timelineTextConnector')}
									&nbsp;
									{sentCleaningManifestDisplayText()}
								</span>
							)}
					</>
				) : (
					<>
						<a
							href="#/"
							onClick={onPumpOutDetailsClicked}
							id={props.cleaning.completeDate ? `completeTimelineCleaning` : 'timelineCleaning'}>
							{localizationService.getLocalizedString('pumpOut.cleaning')}
						</a>
						&nbsp;
						<span>
							{localizationService.getLocalizedString(
								'pumpOut.haulerPortalTimelineConnector',
								daysLateMessage
							)}
						</span>
						&nbsp;
						{creatorOrganizationType === Facility ? (
							<>
								<span>
									{localizationService.getLocalizedString('pumpOut.the')}
									&nbsp;
									{_.toLower(creatorOrganizationType)}
									&nbsp;
									{localizationService.getLocalizedString('pumpOut.user')}
								</span>
								&nbsp;
								<a
									className="ai-link"
									href="#/"
									onClick={e => {
										e.preventDefault();
										toggleFacilityUserInfoModal();
									}}>
									{props.cleaning.submitterUserFullName}
								</a>
							</>
						) : (
							<a
								href="#/"
								className="ai-link"
								onClick={event => {
									event.preventDefault();
									toggleHaulerInfoModal();
								}}>
								{props.cleaning.haulerNumber}
							</a>
						)}
						{!noContactsToSendEmail() && (
							<span>
								{localizationService.getLocalizedString('pumpOut.and')}
								<a
									href="#/"
									className="ai-link"
									onClick={event => {
										event.preventDefault();
										setShowPreviewModal(!showPreviewModal);
									}}>
									{localizationService.getLocalizedString('pumpOut.cleaningSubmissionEmail')}
								</a>
								&nbsp;
								{localizationService.getLocalizedString('pumpOut.timelineTextConnector')}
								&nbsp;
								{sentCleaningManifestDisplayText()}
							</span>
						)}
					</>
				)}
			</div>
			{showCleaningModal && getCleaningModal()}
			<PopoverModal
				showModal={showHaulerModal}
				title={localizationService.getLocalizedString('haulers.hauler')}
				footer={<></>}
				cancel={toggleHaulerInfoModal}>
				<p>
					{props.cleaning.haulerName}
					<br />
					{getHaulerAddress()}
				</p>
			</PopoverModal>
			{showFacilityUserModal && (
				<PopoverModal
					showModal={true}
					title={localizationService.getLocalizedString('pumpOut.facilityUser')}
					footer={<></>}
					cancel={toggleFacilityUserInfoModal}>
					<p>
						{props.cleaning.submitterUserFullName}
						<br />
						{props.cleaning.submitterUserEmail}
						<br />
						<a href={'tel:' + props.cleaning.submitterUserPhoneNumber}>
							{props.cleaning.submitterUserPhoneNumber}
						</a>
					</p>
				</PopoverModal>
			)}
			{showPreviewModal && (
				<PreviewModal
					showModal={showPreviewModal}
					title={localizationService.getLocalizedString('screen.buttons.sentNotices')}
					emailDetails={mapCleaningEmailDetailWithPreviewModal() as PreviewNotice[]}
					subtitle={localizationService.getLocalizedString('bulkNotice.cleaningNotice')}
					onToggle={() => {
						setShowPreviewModal(!showPreviewModal);
					}}
					refresh={reloadTimelineEventsFromServer}
					></PreviewModal>
			)}
		</div>
	);
};
