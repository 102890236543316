import React, { useState } from 'react';
import * as LinkoTypes from '@rcp/types';
import { FacilitySendEmail } from '../send-email';
import { LetterTemplateCategory } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import { VerifiedSvg } from 'src/components/svg';
import _ from 'lodash';

interface OwnProps {
	hauler: LinkoTypes.BasicHauler;
}

type Props = OwnProps;

export const HaulerCardComponent = (props: Props) => {
	const [sendEmailModal, setSendEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState([] as any[]);

	const sendEmailPopUp = (address: string, name: string) => {
		setSendEmailModal(true);
		const selectEmail: any[] = [
			{
				name,
				address,
				selected: true
			}
		];
		setSelectedEmail(selectEmail);
	};
	const toggleSendEmailModal = () => {
		setSendEmailModal(!sendEmailModal);
	};

	const emailContent = () => {
		return (
			<div>
				<a
					id="facility-hauler-email"
					className="ai-link contact-email"
					onClick={() => {
						props.hauler.email &&
							props.hauler.name &&
							sendEmailPopUp(props.hauler.email, props.hauler.name);
					}}>
					{props.hauler.email}
				</a>
			</div>
		);
	};

	return (
		<>
			<div className="wrapWord">
				<div className="flex-column w-100 mr-3">
					<div className="mb-1">
						<span className="font-size-16px-regular mr-1">
							{props.hauler.name}
							{!props.hauler.isActive && (
								<> ({localizationService.getLocalizedString('screen.labels.inActive')}) </>
							)}
							{props.hauler.isRemoved && (
								<> ({localizationService.getLocalizedString('screen.labels.deleted')}) </>
							)}
						</span>
						{props.hauler.isPreferred && (
							<VerifiedSvg title={localizationService.getLocalizedString('haulers.preferredHauler')} />
						)}
					</div>
					<div>
						{props.hauler.addressLine1 && (
							<>
								{props.hauler.addressLine1}
								{props.hauler.addressLine2 && <>, {props.hauler.addressLine2}</>}
								<br />
							</>
						)}
						{(props.hauler.addressCity ||
							props.hauler.addressJurisdictionCode ||
							props.hauler.addressZipCode) && (
							<>
								{(
									_.toString(props.hauler.addressCity) +
									' ' +
									_.toString(props.hauler.addressJurisdictionCode) +
									' ' +
									_.toString(props.hauler.addressZipCode)
								).trim()}
							</>
						)}
					</div>
					{props.hauler.phone ? (
						<div className="mb-1 mt-3">
							{localizationService.getLocalizedString('haulers.phone')}:&nbsp;
							<a href={'tel:' + props.hauler.phone}>{props.hauler.phone}</a>
						</div>
					) : null}

					{props.hauler.email ? emailContent() : null}
				</div>
			</div>
			{sendEmailModal && (
				<FacilitySendEmail
					show={sendEmailModal}
					toggle={toggleSendEmailModal}
					letterTemplateCategory={
						urlService.getFogDeviceId() <= 0
							? LetterTemplateCategory.FacilityEmail
							: LetterTemplateCategory.DeviceEmail
					}
					selectedEmail={selectedEmail}
				/>
			)}
		</>
	);
};
