import {
	AuthenticationActionType,
	AuthenticationAction,
	AuthenticationState,
	initialAuthenticationState
} from './authentication-actions';

export const authenticationReducer = (
	state = initialAuthenticationState,
	action: AuthenticationAction
): AuthenticationState => {
	switch (action.type) {
		case AuthenticationActionType.AuthenticationRequest:
			return { ...state, errors: [], isAuthenticating: true };
		case AuthenticationActionType.AuthenticationSuccess:
			return { ...state, signInResult: action.signInResult, errors: [], isAuthenticating: false };
		case AuthenticationActionType.AuthenticationError:
			return { ...state, errors: [action.error], isAuthenticating: false };
		default:
			return state;
	}
};
