import React, { FC } from 'react';
import { localizationService, navigateTo, urlService } from 'src/services';
import { ColumnField, FilterObjectName, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import { GridOption } from 'src/features/data-grid/data-grid';
import IppReportPackageReviewsComponent from 'src/components/authority/ipp/report-packages/packages/reviews/reviews';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppSubmittedReportPackage: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageSubmittedGrid,
		searchPlaceholder: '',
		showActions: false,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Submitted'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.Submitted,
			page: initialPage,
			sort: 'submissionDateTimeLocal desc'
		},
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		filterOnlyThisGrid: true,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppReportPackageSubmittedGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPSUBMITTEDREPORTPACKAGE,
				FilterDomainName.ippSubmittedReportPackages,
				FilterObjectName.IppSubmittedReportPackage,
				LocalStorageName.IppReportPackageSubmittedGridFilterUrl,
				{ IPPSUBMITTEDREPORTPACKAGE: true }
			);
		},
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal',
			'submitterFirstName'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppSubmittedReportPackage;
