import React from 'react';

interface Props {}

export const ArrowUpSvg: React.SFC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5.78">
			<path
				d="M4.58.18.12 4.64a.41.41 0 0 0 0 .58l.44.44c.16.16.42.16.58 0L5 1.8l3.86 3.86c.16.16.42.16.58 0l.44-.44a.41.41 0 0 0 0-.58L5.41.17A.583.583 0 0 0 5 0c-.16 0-.31.07-.42.18Z"
				fill="#3c3e69"
			/>
		</svg>
	);
};
