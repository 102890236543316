import React, { useEffect, useState, FC } from 'react';
import _ from 'lodash';
import { DropDownOption, Event, EventType, SubLookupType, FogFacility, AttachmentType } from '@rcp/types';
import {
	ComboBoxDropdown,
	DateInput,
	DeleteModal,
	ModalAttachmentService,
	ModalAttachmentState,
	ModalAttachments,
	PopoverModal,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput
} from 'src/components/widgets';
import {
	apiService,
	DateUtilService,
	localizationService,
	optionsMap,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { useDispatch, useSelector } from 'react-redux';
import { authorityUsersSlice } from '../../../../shared/settings';
import { alertService, loadFacilityAttachments, RootState } from 'src/redux';
import { ReactComponent as ArrowBack } from 'src/assets/img/previous.svg';
import { fogEventsSlice } from '../../../events/events.slice';
import { loadFacilityEvents } from '../../../../../../features/timeline/timeline-service';
import { facilityEventSlice } from './event.slice';
import { availableEventTypeSlice } from './available-event-type.slice';
import { getTimelineSettingDateRange } from '../timeline/timeline-service';

import './event-modal.scss';

interface Props {
	selectedEvents?: EventType[];
	title?: string;
	showModal: boolean;
	onCancel?: () => void;
	readOnly?: boolean;
	hideFooter?: boolean;
	hideDelete?: boolean;
	saveButtonClassName?: string;
	onSubmit?: () => void;
	saveButtonText?: string;
	hideCancel?: boolean;
	isFacilityDetailPage?: boolean;
	eventId?: number;
	isEventGridPage?: boolean;
	facilityId?: number;
	cancelBothModal?: () => void;
	eventToEdit?: Event;
	inspectionId?: number;
	loadInspectionEvent?: (inspectionId?: number) => void;
}

interface FormState extends Event {
	dueDateError?: string;
	facilityIdError?: string;
	completeDateError?: string;
}

const initialFormState: FormState = {
	dueDate: '',
	completeDate: '',
	isEventCompleted: false,
	comments: ''
};

export const EventModal: FC<Props> = props => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [formState, setFormState] = useState({} as FormState);
	const [facilityOptionValues, setFacilityOptionValues] = useState([] as DropDownOption[]);
	const [authorityUserOptions, setAuthorityUserOptions] = React.useState([] as DropDownOption[]);
	const [eventTypes, setEventTypes] = useState<DropDownOption[]>([]);
	const dispatch = useDispatch();

	const { result: authorityUsers } = useSelector((state: RootState) => state.authorityUsers);

	const [modalAttachmentsState, setModalAttachmentState] = React.useState<ModalAttachmentState>({
		attachmentType: AttachmentType.FogGenericEvent,
		ownerId: props.eventId,
		organizationId: props.facilityId && props.facilityId > 0 ? props.facilityId : props.eventToEdit?.facilityId,
		initialAttachments: [],
		currentAttachments: []
	});
	const modalAttachmentsService = new ModalAttachmentService(() => {
		return modalAttachmentsState;
	}, setModalAttachmentState);

	useEffect(() => {
		dispatch(authorityUsersSlice.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	useEffect(() => {
		if (urlService.getFogFacilityId() > 0) {
			const facilityId = urlService.getFogFacilityId();
			props.inspectionId
				? facilityEventSlice.setApiUrlPath(
						`${Resource.FogFacilities}/${facilityId}/${Resource.Inspections}/${props.inspectionId}/${Resource.GenericEvents}`
				  )
				: facilityEventSlice.setApiUrlPath(Resource.FogFacilities + '/' + facilityId + '/' + Resource.Events);
		}
		if (props.eventId) {
			fetchEventTypes();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.eventId]);

	useEffect(() => {
		props.showModal && props.eventId && props.isEventGridPage && loadFacilities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.showModal]);

	useEffect(() => {
		((props.isEventGridPage && !props.eventId && props.facilityId) || props.inspectionId) &&
			setFormState({ ...formState, facilityId: props.facilityId || urlService.getFogFacilityId() });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.facilityId]);

	useEffect(() => {
		fetchEventDetails();
	}, [props.eventToEdit]);

	useEffect(() => {
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);
			setAuthorityUserOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityUsers]);

	const loadFacilities = async () => {
		const url = urlService.getAuthorityResourcesApiUrl(`${Resource.Events}/${Resource.FogFacilities}`);
		const facilities = await apiService.getResource<FogFacility[]>(url);
		let facilityOptions: DropDownOption[] = [];
		facilityOptions = facilities.map((facility: FogFacility) => {
			return {
				label: facility.facilityName,
				value: facility.facilityId,
				facilityNumber: facility.referenceNumber,
				addressLine1: facility.addressLine1,
				cityName: facility.cityName,
				jurisdictionCode: facility.jurisdictionCode,
				zipCode: facility.zipCode
			} as DropDownOption;
		});
		setFacilityOptionValues(facilityOptions);
	};

	const fetchEventDetails = () => {
		if (Object.keys(props.eventToEdit || {}).length) {
			setFormState({ ...formState, ...props.eventToEdit, isEventCompleted: !!props!.eventToEdit!.completeDate });
		}
	};

	const fetchEventTypes = () => {
		const url = urlService.getAuthorityLookupResourceApiUrl(
			Resource.EventTypes,
			`eventCategory=${SubLookupType.GENERIC}&includeInactive=true`
		);

		apiService
			.getResource<EventType[]>(url)
			.then((eventTypes: EventType[]) => {
				const eventDropDown: DropDownOption[] = eventTypes.map(eventType => {
					return {
						label: eventType.eventTypeAbbreviation,
						value: eventType.eventTypeId,
						isHidden: !eventType.isActive
					} as DropDownOption;
				});
				setEventTypes(eventDropDown);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onCancel = () => {
		props.onCancel && props.onCancel();
		props.cancelBothModal && props.cancelBothModal();
	};

	const handleDispatchesAndReloads = () => {
		if (urlService.getFogFacilityId() > 0 && !props.inspectionId) {
			let dateRange = getTimelineSettingDateRange();
			let startDateStr = dateRange.startDateStr;
			let endDateStr = dateRange.endDateStr;
			dispatch(loadFacilityEvents(startDateStr, endDateStr));
			dispatch(availableEventTypeSlice.fetchAll());
		}
		props.inspectionId && props.loadInspectionEvent && props.loadInspectionEvent();
	};

	const submitSuccessMessage = () => {
		if (formState.isEventCompleted) {
			alertService.addSuccess(localizationService.getLocalizedString('events.eventAdded'));
		} else {
			alertService.addSuccess(localizationService.getLocalizedString('events.eventScheduled'));
		}
	};

	const validationBeforeSave = () => {
		let newState = { ...formState };

		validationService.validateRequiredDateField(
			newState,
			'dueDate',
			'dueDateError',
			localizationService.getLocalizedString('events.dueDate')
		);
		if (formState.isEventCompleted) {
			let maxDate = DateUtilService.getAuthorityTimezoneNow();
			validationService.validateRequiredDateField(
				newState,
				'completeDate',
				'completeDateError',
				localizationService.getLocalizedString('events.completeDate'),
				maxDate
			);
		}
		setFormState({
			...formState,
			...newState
		});
		let isFormValid = !validationService.hasError(newState, 'dueDateError', 'completeDateError');
		if (!isFormValid) {
			alertService.clearAllMessages();
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const onSubmitAddCallback = (data: Event) => {
		alertService.clearAllMessages();
		modalAttachmentsService.saveAttachments(data.genericEventId as number, () => {
			if (props.isFacilityDetailPage) {
				dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
			}
			if (!props.eventId && props.selectedEvents && currentIndex !== props.selectedEvents.length - 1) {
				setCurrentIndex(currentIndex + 1);
				setFormState(
					props.isEventGridPage || props.inspectionId
						? { ...initialFormState, facilityId: props.facilityId || urlService.getFogFacilityId() }
						: initialFormState
				);
				submitSuccessMessage();
			} else if (!props.eventId && props.selectedEvents && currentIndex === props.selectedEvents.length - 1) {
				props.cancelBothModal && props.cancelBothModal();
				submitSuccessMessage();
			}
			handleDispatchesAndReloads();
			data &&
				data.nextEventDueDate &&
				alertService.addInfo(
					localizationService.getLocalizedString(
						'events.scheduleNextEvent',
						DateUtilService.toDisplayDate(data.nextEventDueDate)
					)
				);
		});
	};

	const onSubmitEditCallback = (data: Event) => {
		alertService.clearAllMessages();
		modalAttachmentsService.saveAttachments(data.genericEventId as number, () => {
			if (props.isFacilityDetailPage) {
				dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
			}
			setFormState(initialFormState);
			props.onCancel && props.onCancel();
			handleDispatchesAndReloads();
			alertService.addSuccess(localizationService.getLocalizedString('events.eventUpdated'));
			data &&
				data.nextEventDueDate &&
				alertService.addInfo(
					localizationService.getLocalizedString(
						'events.scheduleNextEvent',
						DateUtilService.toDisplayDate(data.nextEventDueDate)
					)
				);
		});
	};

	const onSubmit = () => {
		if ((urlService.getFogFacilityId() > 0 || props.isEventGridPage) && validationBeforeSave()) {
			const newState = {
				...formState,
				eventTypeId:
					formState.eventTypeId ||
					(props.eventToEdit && props.eventToEdit.eventTypeId) ||
					(props.selectedEvents && props.selectedEvents[currentIndex].eventTypeId)
			};

			if (props.eventId) {
				newState.eventTypeId = formState.eventTypeId;
				dispatch(
					(props.isEventGridPage ? fogEventsSlice : facilityEventSlice).patchOne(
						props.eventId,
						newState,
						props.isEventGridPage ? true : false,
						'',
						(data: Event) => onSubmitEditCallback(data)
					)
				);
			} else {
				dispatch(
					(props.isEventGridPage ? fogEventsSlice : facilityEventSlice).createOne(
						newState,
						props.isEventGridPage ? true : false,
						'',
						(data: any) => onSubmitAddCallback(data && data.items && data.items[0])
					)
				);
			}
		}
	};

	const modalFooter = () => {
		return props.hideFooter ? (
			<></>
		) : (
			<>
				{currentIndex == 0 && !props.eventId && (
					<button
						type="button"
						className="m-0 p-0 back-arrow"
						onClick={() => {
							props.onCancel && props.onCancel();
						}}>
						<ArrowBack />
						<span className="back-button">
							{localizationService.getLocalizedString('screen.buttons.back')}
						</span>
					</button>
				)}
				{props.eventId && (
					<button
						type="button"
						className="btn ai-secondary-delete"
						onClick={() => {
							setShowDeleteModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}
				{!props.readOnly && !props.eventId && (
					<button
						className={`btn ${props.saveButtonClassName ? props.saveButtonClassName : ' ai-action'}`}
						onClick={onSubmit}>
						{props.saveButtonText
							? props.saveButtonText
							: (props.selectedEvents && currentIndex === props.selectedEvents.length - 1) ||
							  props.eventId
							? localizationService.getLocalizedString('events.add')
							: localizationService.getLocalizedString('events.addAndNext')}
					</button>
				)}
				{!props.readOnly && props.eventId && (
					<button className="btn ai-save" onClick={onSubmit}>
						{localizationService.getLocalizedString('events.save')}
					</button>
				)}
				{!props.hideCancel && (
					<button
						className={`btn ai-white ${props.readOnly && props.readOnly ? 'ml-auto' : ''}`}
						onClick={() => {
							onCancel();
						}}>
						{localizationService.getLocalizedString('haulerPortal.buttons.cancel')}
					</button>
				)}
			</>
		);
	};

	const modalProps = {
		title: props.eventId
			? localizationService.getLocalizedString('events.editEvent')
			: localizationService.getLocalizedString('events.addEvent'),
		showModal: props.showModal,
		cancel: onCancel,
		className: 'submit-cleaning-modal',
		footer: modalFooter()
	};

	const onChange = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
			if (!e.target.checked) {
				newState.completeDate = '';
				newState.completeDateError = '';
			} else {
				props.eventToEdit && (newState.completeDate = props.eventToEdit.completeDate);
			}
		}
		_.set(newState, name, value);

		setFormState(newState);
	};

	const facilityListItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const item = itemProps.dataItem as DropDownOption;

		const itemChildren = <div className="border-bottom w-100">{renderFacilityInfo(item)}</div>;

		return React.cloneElement(li, li.props, itemChildren);
	};

	const renderFacilityInfo = (facility: DropDownOption, strong = true) => {
		return (
			<>
				{strong ? <strong> {facility.label}</strong> : facility.label} <br />
				{facility.value} <br />
				{facility.addressLine1 && (
					<>
						{facility.addressLine1} <br />
					</>
				)}
				{[
					facility.cityName ? facility.cityName + ',' : facility.cityName,
					facility.jurisdictionCode,
					facility.zipCode
				]
					.filter(Boolean)
					.join('\u0020')}
			</>
		);
	};

	const customFilter = (options: DropDownOption[], filter: FilterDescriptor): DropDownOption[] => {
		let newOptions = _.cloneDeep(options);
		newOptions = newOptions.filter((option: DropDownOption) => {
			return (
				(option.addressLine1 && _.toLower(option.addressLine1).includes(_.toLower(filter.value.trim()))) ||
				_.toLower(option.facilityNumber).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.label).includes(_.toLower(filter.value.trim()))
			);
		});
		return newOptions;
	};

	const deleteEvents = () => {
		setShowDeleteModal(false);
		if (props.eventId) {
			dispatch(
				(props.isEventGridPage ? fogEventsSlice : facilityEventSlice).deleteOne(
					props.eventToEdit!.genericEventId as number,
					props.isEventGridPage ? true : false,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'events.event'),
					() => {
						props.onCancel && props.onCancel();
						handleDispatchesAndReloads();

						if (props.isFacilityDetailPage) {
							dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
						}
					}
				)
			);
		}
	};

	return (
		<>
			<PopoverModal {...modalProps}>
				{props.selectedEvents && props.selectedEvents.length > 1 && (
					<h2 className="device-number mb-3">
						{localizationService.getLocalizedString(
							'events.selectedEventsInfo',
							(currentIndex + 1).toString(),
							(props.selectedEvents ? props.selectedEvents : []).length.toString() as string
						)}
					</h2>
				)}
				{props.isEventGridPage && props.eventId && (
					<ComboBoxDropdown
						options={facilityOptionValues}
						id="facility"
						name="facilityId"
						itemRender={facilityListItemRender}
						className="col-sm-12 p-0 "
						onChange={onChange}
						clearButton={false}
						value={_.toString(formState.facilityId)}
						error={formState.facilityIdError}
						label={localizationService.getLocalizedString('facility.facility')}
						customFilter={customFilter}
						isRequired
						showSearchIcon
					/>
				)}
				<p>
					{((props.selectedEvents || []).length &&
						(props.selectedEvents || [])[currentIndex].eventTypeAbbreviation) ||
						(props.eventId && (
							<SingleSelectDropdown
								id="eventType"
								name="eventTypeId"
								className="form-group input-single-line"
								label={localizationService.getLocalizedString('events.eventType')}
								value={formState.eventTypeId}
								onChange={onChange}
								isDisabled={props.readOnly}
								selfOrder
								options={eventTypes}
								showEmptyOption={false}
								isRequired
							/>
						))}
				</p>
				<DateInput
					id="dueDate"
					name="dueDate"
					label={localizationService.getLocalizedString('events.dueDate')}
					value={formState.dueDate}
					error={formState.dueDateError}
					isRequired={true}
					onChange={onChange}
				/>
				<SingleCheckbox
					id="isEventCompleted"
					name="isEventCompleted"
					className="form-group"
					label={localizationService.getLocalizedString('events.isComplete')}
					checked={formState.isEventCompleted}
					isDisabled={props.readOnly}
					onChange={onChange}
				/>
				{formState.isEventCompleted && (
					<DateInput
						id="completeDate"
						name="completeDate"
						label={localizationService.getLocalizedString('events.completeDate')}
						value={formState.completeDate}
						max={DateUtilService.getAuthorityTimezoneNow()}
						isRequired={formState.isEventCompleted}
						onChange={onChange}
						error={formState.completeDateError}
					/>
				)}
				<SingleSelectDropdown
					id="assignedTo"
					name="assignedTo"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('events.assignTo')}
					value={formState.assignedTo}
					onChange={onChange}
					isDisabled={props.readOnly}
					selfOrder
					options={authorityUserOptions}
				/>
				<TextAreaInput
					id="comments"
					name="comments"
					value={formState.comments}
					label={localizationService.getLocalizedString('events.comments')}
					isFullWidth
					onChange={onChange}
				/>

				<ModalAttachments
					service={modalAttachmentsService}
					className="form-group"
					attachments={modalAttachmentsState.currentAttachments}
				/>
			</PopoverModal>
			{showDeleteModal && (
				<DeleteModal
					key="confirmDeleteEventModal"
					title={localizationService.getLocalizedString('events.deleteModalTitle')}
					message={localizationService.getLocalizedString('events.deleteModalMessage')}
					showModal={true}
					onCancelButtonClick={() => {
						setShowDeleteModal(false);
					}}
					onOkayButtonClick={deleteEvents}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</>
	);
};
