import { PendingInvitationsActionType, PendingInvitationsAction } from './pending-invitations-actions';
import { InviteUserState, initialInviteUsersState } from './pending-invitations-state';

export const IppInviteUserReducer = (
	state = initialInviteUsersState,
	action: PendingInvitationsAction
): InviteUserState => {
	switch (action.type) {
		case PendingInvitationsActionType.CheckEmail:
			return { ...state, userInvitation: { ...action.userInvite } };
		default:
			return state;
	}
};
