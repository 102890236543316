import {
	IppAttachmentType,
	IppAvailableCtsEventType,
	IppReportPackageTemplate,
	IppIndustry,
	IppReportPackageTemplateElement
} from '@rcp/types';

export interface ReportPackageTemplateState {
	templateDetails: IppReportPackageTemplate;
	availableSamplesAndResultsTypes: IppReportPackageTemplateElement[];
	availableAttachmentTypes: IppAttachmentType[];
	availableCertificationTypes: IppAttachmentType[];
	availableReportPackageTemplateAssignments: IppIndustry[];
	availableCtsEventTypes: IppAvailableCtsEventType[];
}
export const initialReportPackageTemplateState: any = {
	availableSamplesAndResultsTypes: [],
	availableAttachmentTypes: [],
	availableCertificationTypes: [],
	availableReportPackageTemplateAssignments: [],
	availableCtsEventTypes: [],
	templateDetails: {}
};
