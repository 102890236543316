import React, { FC } from 'react';
import { default as LinkoTypes } from '@rcp/types';
import { History } from 'history';
import FrequencySettingsComponent from '../../../shared/settings/frequencies/frequency-settings';
import { localizationService } from 'src/services';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
}

const FogFrequencyUnitSettingsComponent: FC<Props<any>> = props => {
	return (
		<div className="page">
			<div className="page-header">
				<BackArrow />
				<h1>{localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}</h1>
			</div>

			<div className="page-wrapper">
				<div className="main settings">
					<FrequencySettingsComponent />
				</div>
			</div>
		</div>
	);
};

export default FogFrequencyUnitSettingsComponent;
