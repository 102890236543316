import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { RouteProps } from '@rcp/types';
import classNames from 'classnames';
import { History } from 'history';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { alertService } from 'src/redux';
import { RestSlice } from 'src/redux/rest.slice';
import { localizationService } from 'src/services';
import './translations.scss';

interface Props<T> extends RouteProps {
	history: History;
	accordionType: string;
	list?: T[];
	restSlice: RestSlice<T>;
	dropDownId: string;
	dropDownData: any;
	dataId: string;
	localizationPrefix: string;
	hideCreateButton?: boolean;
	includeUntranslated?: boolean;
	translatedList?: T[];
	unTranslatedList?: T[];
	disableRemove?: boolean;
	dataSourceId?: number;
	sortTranslationsQueryParam?: string;
}

let monitoringPoints = 'monitoringPoints';
let unitId = 'unitId';
let parameterId = 'parameterId';

const DropDownCell = (props: any, dropDownData?: any[], dropDownId?: string) => {
	let [data, setData] = useState(dropDownData);
	let dataCopy: any[] = dropDownData || [];

	const handleChange = (e: any) => {
		props.onChange({
			dataItem: props.dataItem,
			field: props.field,
			syntheticEvent: e.syntheticEvent,
			value: e.target.value ? e.target.value.name : ''
		});
	};

	const filterData = (filter: FilterDescriptor) => {
		const filteredData = dataCopy.slice();
		const filterByOutput = filteredData.filter(filteredData => {
			if (
				filteredData.name
					.trim()
					.toLowerCase()
					.includes(filter.value.trim().toLowerCase()) ||
				(dropDownId === unitId &&
					filteredData.description &&
					filteredData.description
						.trim()
						.toLowerCase()
						.includes(filter.value.trim().toLowerCase()))
			) {
				return true;
			}
			return false;
		});
		return filterByOutput;
	};

	const { dataItem, field } = props;
	const dataValue = dataItem[field] === null ? '' : dataItem[field];

	const filterChange = (event: ComboBoxFilterChangeEvent) => {
		setData(filterData(event.filter));
	};

	const itemRender = (li: any, itemProps: any) => {
		const itemChildren =
			dropDownId === unitId ? (
				<span>
					{li.props.children} <br /> {itemProps.dataItem.description}
				</span>
			) : (
				<span>{li.props.children}</span>
			);

		return React.cloneElement(li, li.props, itemChildren);
	};
	return (
		<td>
			{dataItem.inEdit ? (
				<ComboBox
					className="w-100"
					data={data}
					value={data && data.find(c => String.equalCaseInsensitive(c.name, dataValue))}
					allowCustom={false}
					itemRender={itemRender}
					textField="name"
					dataItemKey={dropDownId}
					filterable={true}
					onChange={handleChange}
					onFilterChange={filterChange}
				/>
			) : (
				(dataValue && dataValue.toString()) || ''
			)}
		</td>
	);
};

const ActionButtons = (props: any) => {
	const { dataItem } = props;
	const inEdit = dataItem[props.editField];
	const isNewItem = dataItem[props.dataId] === undefined || dataItem[props.dataId] === 0;
	return inEdit ? (
		<td className="k-command-cell text-right pr-0 translations-grid">
			<button
				className="k-button k-grid-save-command translation-grid-buttons"
				onClick={(event: any) => (isNewItem ? props.add(dataItem, event) : props.update(dataItem, event))}>
				{isNewItem
					? localizationService.getLocalizedString('ipp.buttons.add')
					: localizationService.getLocalizedString('ipp.buttons.update')}
			</button>
			<button
				className="k-button k-grid-cancel-command translation-grid-buttons"
				onClick={() => (isNewItem ? props.discard(dataItem) : props.cancel(dataItem))}>
				{isNewItem
					? localizationService.getLocalizedString('ipp.buttons.discard')
					: localizationService.getLocalizedString('ipp.buttons.cancel')}
			</button>
		</td>
	) : (
		<td className="k-command-cell text-right pr-0 translations-grid">
			<button
				className="k-button k-grid-edit-command translation-grid-buttons"
				onClick={() => props.edit(dataItem)}>
				{localizationService.getLocalizedString('ipp.buttons.edit')}
			</button>
			{!props.disableRemove && (
				<button
					className="k-button ai-delete k-grid-remove-command translation-grid-buttons"
					onClick={() => {
						props.setDataItemToBeDeleted(dataItem);
						props.deleteToggle();
					}}>
					{localizationService.getLocalizedString('ipp.buttons.remove')}
				</button>
			)}
		</td>
	);
};

const EditableGrid: <T extends any>(p: Props<T>) => React.ReactElement<Props<T>> = props => {
	const [activeTab, setActiveTab] = useState('untranslated');
	let dispatch = useDispatch();
	let editField = 'inEdit';
	let initialSort: SortDescriptor[] = [{ field: '', dir: 'asc' }];
	let list: any = props.list || [];
	let [state, setState] = useState<any>({
		data: list.slice(0)
	});
	let [deleteModal, setDeleteModal] = useState(false);
	let [dataItemToBeDeleted, setDataItemToBeDeleted] = useState<any>();
	const [sort, setSort] = useState(initialSort as SortDescriptor[]);
	const [isApiRunning, setIsApiRunning] = useState(false);

	const rowInAddMode = () => {
		return Boolean(state.data.filter((data: any) => data[props.dataId] === undefined).length);
	};

	const rowInEditMode = () => {
		return Boolean(state.data.filter((data: any) => data.inEdit).length);
	};

	useEffect(() => {
		rowInAddMode() && setSort(initialSort);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rowInAddMode()]);

	useEffect(() => {
		if (
			props.unTranslatedList &&
			props.includeUntranslated &&
			String.equalCaseInsensitive(activeTab, 'untranslated')
		) {
			setState({
				data: props.unTranslatedList.slice(0)
			});
		} else if (props.translatedList) {
			setState({
				data: props.translatedList.slice(0)
			});
		} else {
			setState({
				data: (props.list && props.list.slice(0)) || []
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.list, props.unTranslatedList, props.translatedList]);

	const CommandCell = (rowData: any) => (
		<ActionButtons
			{...rowData}
			edit={enterEdit}
			add={add}
			discard={discard}
			update={update}
			cancel={cancel}
			editField={editField}
			setDataItemToBeDeleted={setDataItemToBeDeleted}
			deleteToggle={onToggleDeleteModal}
			dataId={props.dataId}
			disableRemove={props.disableRemove}
		/>
	);

	const enterEdit = (dataItem: any) => {
		setState({
			data: state.data.map((item: any) => {
				let key = item.dataSourceId === 0 ? 'importFileTerm' : props.dataId;
				return item[key] === dataItem[key] ? { ...item, inEdit: true } : item;
			})
		});
	};

	const validateFields = (dataItem: any) => {
		if (!dataItem.importFileTerm) {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString(
					'ipp.industryAccount.dataProviders.translations.importFileTermError'
				)
			);
			return false;
		}
		if (!dataItem.linkoExchangeTerm) {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString(
					'ipp.industryAccount.dataProviders.translations.linkoExchangeTermError'
				)
			);
			return false;
		}
		return true;
	};

	const add = (dataItem: any, event: any) => {
		event.preventDefault();
		if (isApiRunning) {
			return;
		}
		let dropDownDataCopy = [...props.dropDownData];
		let payload = { ...dataItem };
		delete payload.Discontinued;

		if (!validateFields(dataItem)) {
			return;
		}
		payload[props.dropDownId] = dropDownDataCopy.filter((dropdownData: any) => {
			return String.equalCaseInsensitive(payload.linkoExchangeTerm, dropdownData.name);
		})[0][props.dropDownId];
		setIsApiRunning(true);
		dispatch(
			props.restSlice.createOne(
				payload,
				true,
				undefined,
				() => {
					dataItem.inEdit = undefined;
					alertService.clearAllMessages();
					setIsApiRunning(false);
				},
				() => {
					setIsApiRunning(false);
				},
				undefined,
				`dataSourceId=${props.match.params.dataSourceId || props.dataSourceId}${
					props.sortTranslationsQueryParam
				}`
			)
		);
	};

	const update = (dataItem: any, event: any) => {
		event.preventDefault();
		if (isApiRunning) {
			return;
		}
		if (props.list && props.list.length) {
			list = props.list;
		}
		if (dataItem.dataSourceId && props.translatedList) {
			list = props.translatedList;
		}
		let updatedItem = { ...dataItem, inEdit: undefined };
		let dropDownDataCopy = [...props.dropDownData];
		delete updatedItem.Discontinued;

		if (!validateFields(dataItem)) {
			return;
		}
		updatedItem[props.dropDownId] = dropDownDataCopy.filter((dropdownData: any) => {
			return updatedItem.linkoExchangeTerm === dropdownData.name;
		})[0][props.dropDownId];
		setIsApiRunning(true);
		dispatch(
			props.restSlice.patchOne(
				updatedItem[props.dataId],
				updatedItem,
				true,
				undefined,
				() => {
					updateItem([...state.data], updatedItem);
					updateItem(list, updatedItem);
					alertService.clearAllMessages();
					setIsApiRunning(false);
				},
				() => {
					setIsApiRunning(false);
				},
				undefined,
				`dataSourceId=${props.match.params.dataSourceId || props.dataSourceId}${
					props.sortTranslationsQueryParam
				}`
			)
		);
	};

	const updateItem = (data: any, item: any) => {
		let index = data && data.findIndex((p: any) => p === item || p[props.dataId] === item[props.dataId]);
		if (index >= 0) {
			let newData: any = [...data];
			newData[index] = { ...item };
			setState({ data: newData });
		}
	};

	const cancel = (dataItem: any) => {
		if (props.list && props.list.length) {
			list = props.list;
		}
		if (dataItem.dataSourceId && props.translatedList) {
			list = props.translatedList;
		}
		if (!dataItem.dataSourceId && props.unTranslatedList) {
			list = props.unTranslatedList;
		}
		const originalItem: any = list.find((item: any) => {
			let key = item.dataSourceId === 0 ? 'importFileTerm' : props.dataId;
			return item[key] === dataItem[key];
		});

		const data = state.data.map((item: any) => {
			let key = item.dataSourceId === 0 ? 'importFileTerm' : props.dataId;
			return item[key] === originalItem[key] ? originalItem : item;
		});

		setState({ data });
	};

	const discard = (dataItem: any) => {
		const data = [...state.data];

		!props.disableRemove ? removeItem(data, dataItem) : (dataItem.inEdit = undefined);

		setState({ data });
	};

	const itemChange = (event: any) => {
		const data = state.data.map((item: any) => {
			let key = item.dataSourceId === 0 ? 'importFileTerm' : props.dataId;
			return item[key] === event.dataItem[key] ? { ...item, [event.field]: event.value } : item;
		});

		setState({ data });
	};

	const addNew = () => {
		if (state.data.length - list.length === 1) return;
		const newDataItem = { inEdit: true, Discontinued: false, [props.dropDownId]: undefined };
		setState({
			data: [newDataItem, ...state.data]
		});
	};

	const removeItem = (data: any, item: any) => {
		let index = data.findIndex(
			(rowItem: any) => rowItem === item || (item[props.dataId] && rowItem[props.dataId] === item[props.dataId])
		);
		if (index >= 0) {
			data.splice(index, 1);
		}
	};

	const onToggleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const onDelete = () => {
		dispatch(
			props.restSlice.deleteOne(
				dataItemToBeDeleted[props.dataId],
				true,
				undefined,
				() => {
					onToggleDeleteModal();
					alertService.clearAllMessages();
				},
				`dataSourceId=${props.match.params.dataSourceId || props.dataSourceId}${
					props.sortTranslationsQueryParam
				}`
			)
		);
	};

	const deleteDataProps: DeleteModalProp = {
		title: localizationService.getLocalizedString(
			`ipp.industryAccount.dataProviders.translations.${props.localizationPrefix}.deleteModalHeading`
		),
		message: localizationService.getLocalizedString(
			'ipp.industryAccount.dataProviders.translations.deleteModalMessage',
			`"${dataItemToBeDeleted ? dataItemToBeDeleted.importFileTerm : ''}"`
		),
		showModal: deleteModal,
		onCancelButtonClick: () => onToggleDeleteModal(),
		onOkayButtonClick: onDelete,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const getGrid = () => (
		<Grid
			scrollable="none"
			data={orderBy(state.data.slice(), sort)}
			onItemChange={itemChange}
			editField={editField}
			sortable
			sort={sort}
			onSortChange={e => {
				!rowInAddMode() && !rowInEditMode() && setSort(e.sort);
			}}>
			{props.unTranslatedList && props.unTranslatedList.length && (
				<GridColumn
					field=" "
					title=" "
					headerClassName="required-header"
					cell={props =>
						!props.dataItem.dataSourceId ? (
							<td>
								<div className="d-flex justify-content-center align-items-center">
									<FontAwesomeIcon
										className="font-awesome-icon red-icon required-icon"
										icon={faAsterisk}
									/>
								</div>
							</td>
						) : (
							<td></td>
						)
					}
				/>
			)}
			<GridColumn
				field="importFileTerm"
				title={localizationService.getLocalizedString(
					'ipp.industryAccount.dataProviders.translations.columns.importFileTerm'
				)}
				editor="text"
				editable={!props.disableRemove}
			/>
			<GridColumn
				field="linkoExchangeTerm"
				title={localizationService.getLocalizedString(
					'ipp.industryAccount.dataProviders.translations.columns.linkoExchangeTerm'
				)}
				cell={(row: any) => DropDownCell(row, props.dropDownData, props.dropDownId)}
			/>
			<GridColumn cell={(row: any) => CommandCell(row)} width="240px" />
		</Grid>
	);

	const toggleTab = (tab: string) => {
		if (tab === 'untranslated' && props.unTranslatedList) {
			setState({ data: props.unTranslatedList.slice(0) });
		}
		if (tab === 'translated' && props.translatedList) {
			setState({ data: props.translatedList.slice(0) });
		}
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const showMassLoadingText = () => {
		if (props.disableRemove) {
			return props.dropDownId === parameterId && props.unTranslatedList && Boolean(props.unTranslatedList.length);
		} else {
			return props.dropDownId === parameterId;
		}
	};

	return (
		<>
			<CollapsibleCard
				isHidden={!props.includeUntranslated && props.unTranslatedList !== undefined}
				accordionHeaderElements={
					!props.hideCreateButton ? (
						<button
							id={`${props.accordionType}-create-btn`}
							title="Add new"
							className="btn ai-action mr-3 px-2 p-1"
							onClick={addNew}>
							{localizationService.getLocalizedString('ipp.buttons.createNew')}
						</button>
					) : (
						<></>
					)
				}
				accordionHeading={localizationService.getLocalizedString(
					`ipp.industryAccount.dataProviders.translations.${props.localizationPrefix}.heading`
				)}
				accordionType={props.accordionType}
				className={String.equalCaseInsensitive(props.localizationPrefix, monitoringPoints) ? '' : 'mt-3'}>
				{showMassLoadingText() && (
					<p
						dangerouslySetInnerHTML={{
							__html: localizationService.getLocalizedString(
								'ipp.industryAccount.dataProviders.translations.parameterGridInfoMessage'
							)
						}}></p>
				)}

				{props.includeUntranslated && (
					<>
						<Nav tabs className="material">
							<NavItem>
								<NavLink
									className={classNames({ active: activeTab === 'untranslated' }) + ' cursor-pointer'}
									onClick={() => {
										toggleTab('untranslated');
									}}>
									{localizationService.getLocalizedString('ipp.samples.importSamples.untranslated')}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									className={
										classNames({ active: String.equalCaseInsensitive(activeTab, 'translated') }) +
										' cursor-pointer'
									}
									onClick={() => {
										toggleTab('translated');
									}}>
									{localizationService.getLocalizedString('ipp.samples.importSamples.translated')}
								</NavLink>
							</NavItem>
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId="untranslated">
								{String.equalCaseInsensitive(activeTab, 'untranslated') && getGrid()}
							</TabPane>
							<TabPane tabId="translated">
								{String.equalCaseInsensitive(activeTab, 'translated') && getGrid()}
							</TabPane>
						</TabContent>
					</>
				)}
				{!props.includeUntranslated && getGrid()}
			</CollapsibleCard>
			<DeleteModal {...deleteDataProps} />
		</>
	);
};

export default EditableGrid;
