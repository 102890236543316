import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useReduxSelector } from 'src/redux';
import { cccHazardSlice } from '../hazards.slice';
import Comment from 'src/features/comments/comment';
import { FaClipboard, FaCommentAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import { History } from 'history';
import * as ApiTypes from '@rcp/types';
import { CccHazard, CccTestDto } from '@rcp/types';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { NotFound } from 'src/features/home/not-found';
import { Timeline } from 'src/features/timeline/timeline';
import { CccHazardTabs } from './hazard-details-tabs';
import { CccHazardAddressInfo } from './hazard-Address-info';
import { timelineEventsSlice } from 'src/features/timeline/timeline-events.slice';
import TagsComp from 'src/features/tags/tags';
import { commentsSlice } from 'src/features/comments/comments.slice';
import { HazardActionDropdown } from './hazard-action-dropdown-menu';
import { CloseSvg } from 'src/components/svg';
import { cccHazardTestsSlice } from '../tests/hazard-tests.slice';
import { TestDetailModal } from '../tests/hazard-test-detail-modal';
import { FeatureNames } from '@rcp/types';
import { settingLookupsTagSlice } from 'src/components/authority/shared/settings/tag/tag-lookup.slice';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

const CccHazardDetails: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [showInvalidUrlMessage, setShowInvalidUrlMessage] = React.useState(false);
	const [currentCccHazard, setCurrentHazard] = React.useState({} as CccHazard);
	const [commentModal, setCommentModal] = React.useState(false as boolean);
	const [hazardCommentAnchor, setHazardCommentAnchor] = React.useState(document.createElement('div'));
	const [deleteHazardModal, setDeleteHazardModal] = React.useState(false as boolean);
	const [testDetailModal, setTestDetailModal] = React.useState(false as boolean);
	const [scheduledTest, setScheduledTest] = React.useState({} as CccTestDto);
	const [isHazardLoading, setIsHazardLoading] = React.useState(true);

	const showInDevelopmentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowInDevelopmentFeature]
	);

	useEffect(() => {
		setIsHazardLoading(true);
		let hazardId = urlService.getCccHazardIdOrThrowError();

		timelineEventsSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/timelineEvents`
		);
		commentsSlice.setApiUrlPath(`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/comments`);
		cccHazardTestsSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/tests`
		);
		dispatch(cccHazardSlice.fetchOne(hazardId));
		dispatch(settingLookupsTagSlice.fetchAll());
		dispatch(cccHazardTestsSlice.fetchAll());
	}, [dispatch]);

	let hazardsState = (state: RootState) => state.cccHazards;
	let { selected, loading } = useSelector(hazardsState);

	let { result: authorityTags } = useSelector((state: RootState) => state.settingLookupTags);
	let { result: hazardTests } = useSelector((state: RootState) => state.cccHazardTests);

	useEffect(() => {
		if (hazardTests) {
			let schedTest = hazardTests.filter(a => !a.initialTestDate)[0];
			setScheduledTest(schedTest);
		}
	}, [hazardTests]);

	useEffect(() => {
		if (selected) {
			setCurrentHazard(selected);
			setIsHazardLoading(false);
			window.scrollTo(0, 0);
		} else {
			setShowInvalidUrlMessage(true);
		}
	}, [selected]);

	let saveTags = (tagNames: string) => {
		if (selected && selected.hazardId) {
			dispatch(
				cccHazardSlice.patchOne(
					selected.hazardId,
					{ tags: tagNames },
					false,
					localizationService.getLocalizedString('alertMessages.hazardSaveSucceeded')
				)
			);
		}
	};

	let onCommentDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setCommentModal(!commentModal);
		setHazardCommentAnchor(e.target as HTMLDivElement);
	};

	let onEnterTest = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setTestDetailModal(!testDetailModal);
	};

	let toggleCommentModal = () => {
		setCommentModal(!commentModal);
	};

	let onDeleteHazardToggle = () => {
		setDeleteHazardModal(!deleteHazardModal);
	};

	let toggleTestDetail = () => {
		setTestDetailModal(!testDetailModal);
	};

	let deleteHazard = () => {
		let hazardsUrl = urlService.getReactResourceUrl(Resource.CccHazards);
		let history = props.history;
		let hazardId = urlService.getCccHazardIdOrThrowError();
		dispatch(
			cccHazardSlice.deleteOne(
				hazardId,
				true,
				localizationService.getLocalizedString('cccHazard.deleteSuccessMessage'),
				() => {
					navigateTo(history, hazardsUrl);
				}
			)
		);
	};

	return (
		<>
			{_.isEmpty(currentCccHazard) && !loading ? (
				<>{showInvalidUrlMessage && <NotFound />}</>
			) : (
				<div className="page">
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('cccHazard.hazard')}&nbsp;
							<span id="hazardNumberLabel">{currentCccHazard.hazardNumber}</span>
						</h1>
					</div>
					<div className="d-flex flex-row top-action-div">
						<div className="form-row w-100 action-panel align-items-center">
							{showInDevelopmentFeature && (
								<>
									<div
										className="action-item"
										id="sendEmailLink"
										onClick={() => console.log('click')}>
										<FaEnvelope className="ai-slate" />
										<span>{localizationService.getLocalizedString('screen.labels.sendEmail')}</span>
									</div>
									<div className="action-item" id="logCallLink" onClick={() => console.log('click')}>
										<FaPhone className="ai-slate" />
										<span>{localizationService.getLocalizedString('screen.labels.logACall')}</span>
									</div>
									<div
										className="action-item"
										id="mailALetterLink"
										onClick={() => console.log('click')}>
										<FaEnvelope className="ai-slate" />
										<span>
											{localizationService.getLocalizedString('screen.labels.mailALetter')}
										</span>
									</div>
								</>
							)}
							<div className="action-item" id="commentDiv" onClick={onCommentDivClick}>
								<FaCommentAlt className="ai-slate" />
								<span>{localizationService.getLocalizedString('screen.labels.comment')}</span>
							</div>
							{currentCccHazard.deviceId && (
								<div className="action-item" id="enterTestDiv" onClick={onEnterTest}>
									<FaClipboard className="ai-slate" />
									<span>{localizationService.getLocalizedString('screen.labels.enterTest')}</span>
								</div>
							)}

							<HazardActionDropdown
								clickOnDeleteHazard={onDeleteHazardToggle}
								deviceId={currentCccHazard.deviceId}
								scheduledTest={scheduledTest}
							/>
						</div>
					</div>

					<div className="page-wrapper">
						<div className="main">
							{isHazardLoading === false && (
								<>
									<CccHazardAddressInfo currentCccHazard={currentCccHazard} />
									<CccHazardTabs currentCccHazard={currentCccHazard} />
									<Timeline />
								</>
							)}
						</div>

						<div className="sidebar">
							<section>
								<p id="testSummary" className="d-flex">
									<h3>{localizationService.getLocalizedString('cccHazard.tests')}</h3>
								</p>
								<p>
									<strong>{localizationService.getLocalizedString('cccHazard.nextTestDate')}</strong>
									<br />
									{currentCccHazard.nextTestDate ? (
										DateUtilService.toDisplayDate(currentCccHazard.nextTestDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>{localizationService.getLocalizedString('cccHazard.lastTestDate')}</strong>
									<br />
									{currentCccHazard.lastTestDate ? (
										DateUtilService.toDisplayDate(currentCccHazard.lastTestDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>
										{localizationService.getLocalizedString('cccHazard.lastTestResult')}
									</strong>
									<br />
									{currentCccHazard.lastTestResult ? currentCccHazard.lastTestResult : <></>}
								</p>
							</section>

							<section>
								<p id="noticeSummary" className="d-flex">
									<h3>{localizationService.getLocalizedString('cccHazard.notices')}</h3>
								</p>
								<p>
									<strong>{localizationService.getLocalizedString('cccHazard.nextNoticeDue')}</strong>
									<br />
									{currentCccHazard.nextDueNoticeDate ? (
										DateUtilService.toDisplayDate(currentCccHazard.nextDueNoticeDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>
										{localizationService.getLocalizedString('cccHazard.lastNoticeDate')}
									</strong>
									<br />
									{currentCccHazard.lastSentNoticeDate ? (
										DateUtilService.toDisplayDate(currentCccHazard.lastSentNoticeDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>{localizationService.getLocalizedString('cccHazard.lastNotice')}</strong>
									<br />
									{currentCccHazard.lastSentNoticeTemplateName ? (
										<a
											href={currentCccHazard.lastSentNoticeS3PdfLink}
											className="ai-link"
											target={'_blank'}
											rel="noopener noreferrer">
											{currentCccHazard.lastSentNoticeTemplateName}
										</a>
									) : (
										<></>
									)}
								</p>
							</section>

							<TagsComp
								authorityTags={authorityTags}
								tagNames={selected && selected.tags ? selected.tags : ''}
								saveTags={saveTags}
								tagType={'Hazard'}
							/>

							{showInDevelopmentFeature && (
								<>
									<section className="attachments">
										<div id="attachments" className="d-flex">
											<h3>{localizationService.getLocalizedString('attachments.title')}</h3>
										</div>
										<div className="attachment-list mx-n1"></div>
										<div className="d-flex">
											<div>
												<a
													href="#/"
													className="ai-add-link"
													onClick={() => console.log('click')}>
													{localizationService.getLocalizedString('attachments.addNew')}
													&#8230;
												</a>
												<input
													type="file"
													id="addAttachmentInput"
													name="files"
													className="add-attachment-input"
													onChange={() => console.log('click')}
													multiple
												/>
											</div>
										</div>
									</section>
								</>
							)}
						</div>

						{commentModal && (
							<Comment show={commentModal} anchor={hazardCommentAnchor} toggle={toggleCommentModal} />
						)}

						{deleteHazardModal && (
							<Modal
								isOpen={true}
								toggle={onDeleteHazardToggle}
								centered={true}
								backdrop={true}
								size="lg"
								id="deleteHazardModal">
								<ModalHeader
									toggle={onDeleteHazardToggle}
									tag="h1"
									close={
										<button className="close" onClick={onDeleteHazardToggle}>
											<CloseSvg />
										</button>
									}>
									{localizationService.getLocalizedString('cccHazard.deleteModalTitle')}
								</ModalHeader>
								<ModalBody>
									<div>
										<p>{localizationService.getLocalizedString('cccHazard.deleteModalMessage')}</p>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button
										id="deleteHazardButton"
										color="btn ai-secondary-delete ml-auto"
										onClick={deleteHazard}>
										{localizationService.getLocalizedString('screen.buttons.delete')}
									</Button>
									<Button color="btn ai-white" onClick={onDeleteHazardToggle}>
										{localizationService.getLocalizedString('screen.buttons.cancel')}
									</Button>
								</ModalFooter>
							</Modal>
						)}

						{testDetailModal && (
							<TestDetailModal
								isToggle={testDetailModal}
								toggle={toggleTestDetail}
								currentTest={scheduledTest ? scheduledTest : ({} as CccTestDto)}
								dueDateRequired={scheduledTest ? true : false}
								completedResultRequired={true}
								modalTitle={
									scheduledTest
										? undefined
										: localizationService.getLocalizedString('cccTest.addTest')
								}
								isScheduledTest={false}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default CccHazardDetails;
