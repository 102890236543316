import { FogFacilityActionType, FogFacilityAction } from './facility-actions';
import { FogFacilityState, initialFogFacilityState } from './facility-state';

export const fogFacilityReducer = (
	state = initialFogFacilityState,
	action: FogFacilityAction | any
): FogFacilityState => {
	switch (action.type) {
		case FogFacilityActionType.LoadFogFacilityRequest:
			return { ...state };
		case FogFacilityActionType.LoadFogFacilitySuccess:
			return { ...state, facility: action.facility };
		case FogFacilityActionType.SaveFogFacilityRequest:
			return { ...state };
		case FogFacilityActionType.SaveFogFacilitySuccess:
			return { ...state, facility: action.facility };
		case FogFacilityActionType.AddFogFacilityRequest:
			return { ...state };
		case FogFacilityActionType.AddFogFacilitySuccess:
			return { ...state, facility: action.facility };
		case FogFacilityActionType.LoadFacilityTimelineEnforcements:
			return { ...state, facilityTimelineEnforcements: action.facilityTimelineEnforcements };
		case FogFacilityActionType.LoadFacilityAllViolations:
			return { ...state, facilityAllViolations: action.facilityAllViolations };
		case FogFacilityActionType.ResetFacilityData:
			return { ...state, facility: {}, facilityAllViolations: [] };
		default:
			return state;
	}
};
