import React, { useState, FC, useEffect } from 'react';
import _ from 'lodash';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { History } from 'history';
import { ReactComponent as ArrowBack } from 'src/assets/img/arrow-back.svg';
import { AlertMessageType, alertService, RootState, useRootStateSelector } from 'src/redux';
import { haulersSlice } from 'src/components/authority/fog/haulers';
import { useDispatch } from 'react-redux';
import { VerifiedSvg } from 'src/components/svg';
import { BasicHauler, FogFacility } from '@rcp/types';
import './hauler.scss';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { Translate } from 'src/components/widgets/translate/translator';

interface Props {
	history: History;
}

export const FacilityPortalSettingHauler: FC<Props> = props => {
	const [selectedHaulerId, setSelectedHaulerId] = useState<number | null | undefined>(null);
	const [selectedHauler, setSelectedHauler] = useState<BasicHauler>();
	const haulerList = useRootStateSelector((state: RootState) => state.haulers);
	const dispatch = useDispatch();

	const getContractedHauler = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.Haulers + '/ContractedHauler');
		const contractedHauler: BasicHauler = await apiService.httpGet(url);
		setSelectedHaulerId(_.isUndefined(contractedHauler) ? null : contractedHauler.haulerId);
		setSelectedHauler(contractedHauler);
	};

	useEffect(() => {
		dispatch(haulersSlice.fetchAll('size=2147483647'));
		getContractedHauler();
	}, []);

	const updateContactedHauler = async (haulerId: number | null | undefined) => {
		let facilityToUpdate: FogFacility = {
			contractedHaulerId: haulerId
		};

		const facilityUrl = urlService.getAuthorityResourcesApiUrl(Resource.Haulers + '/ContractedHauler');
		await apiService.patchResource<BasicHauler>(facilityUrl, facilityToUpdate);

		let successMessage = haulerId
			? localizationService.getLocalizedString('alertMessages.selectedSuccess', 'extractor.contractedHauler')
			: localizationService.getLocalizedString('alertMessages.unselectedSuccess', 'extractor.contractedHauler');

		setSelectedHaulerId(haulerId);

		alertService.addSuccess(successMessage);
	};

	const renderHaulerInfo = (hauler: BasicHauler) => {
		return (
			<>
				<div className="d-flex">
					{<strong className="mr-2"> {hauler.name}</strong>}
					{hauler.isPreferred && (
						<VerifiedSvg title={localizationService.getLocalizedString('haulers.preferredHauler')} />
					)}
					<div className="icon ml-auto"></div>
				</div>
				<div className="d-flex">
					<div className="col-6 pl-0">
						{hauler.addressLine1 && (
							<div className="mt-2">
								{hauler.addressLine1}
								{hauler.addressLine2 && <> {hauler.addressLine2}</>}
							</div>
						)}
						{(hauler.addressCity || hauler.addressJurisdictionCode || hauler.addressZipCode) && (
							<div className="mt-2">
								{(
									_.toString(hauler.addressCity) +
									' ' +
									_.toString(hauler.addressJurisdictionCode) +
									' ' +
									_.toString(hauler.addressZipCode)
								).trim()}
							</div>
						)}
					</div>
					<div className="col-6 pl-0">
						<>
							{hauler.email && (
								<div className="mt-2">
									<Translate>{localizationService.getLocalizedString('haulers.email')}</Translate>
									:&nbsp;
									<a
										id="facility-hauler-email"
										className="ai-link contact-email"
										href={'mailto:' + hauler.email}>
										{hauler.email}
									</a>
								</div>
							)}
							{hauler.phone && (
								<div className="mt-2">
									<Translate>{localizationService.getLocalizedString('haulers.phone')}</Translate>
									:&nbsp;
									<a href={'tel:' + hauler.phone}>{hauler.phone}</a>
								</div>
							)}
						</>
					</div>
				</div>
			</>
		);
	};
	const renderHaulerList = (
		<div>
			{haulerList.result
				.filter(hauler => hauler.isActive)
				.sort((a, b) => {
					return a.name && b.name && a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
				})
				.map((hauler: BasicHauler, index: number) => {
					return (
						<div
							key={index}
							className={'hauler-list-item' + (selectedHaulerId === hauler.haulerId ? ' selected' : '')}
							onClick={(e: any) =>
								updateContactedHauler(selectedHaulerId === hauler.haulerId ? null : hauler.haulerId)
							}>
							{renderHaulerInfo(hauler)}
						</div>
					);
				})}
		</div>
	);
	const getContractedHaulerSection = () => {
		return (
			<div className="row mt-2">
				<div className="col-lg-4 settings-info">
					<strong>
						<Translate>
							{localizationService.getLocalizedString(
								'facilityPortal.settings.haulers.contactedHaulerSectionTitle'
							)}
						</Translate>
					</strong>
					<p>
						<Translate>
							{localizationService.getLocalizedString(
								'facilityPortal.settings.haulers.contactedHaulerSectionDescription'
							)}
						</Translate>
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						{selectedHauler &&
							selectedHauler.haulerId == selectedHaulerId &&
							(selectedHauler.isRemoved || !selectedHauler.isActive) && (
								<div>
									<InlineAlertItem
										message={localizationService.getLocalizedString(
											'facilityPortal.settings.haulers.inactiveOrDeletedContractedHaulerWarning'
										)}
										alertType={AlertMessageType.Warning}
									/>
								</div>
							)}

						<div className="d-flex">
							<div className="ai-form-help ml-auto">
								<VerifiedSvg
									title={localizationService.getLocalizedString('haulers.preferredHauler')}
								/>{' '}
								<Translate>
									{localizationService.getLocalizedString('haulers.preferredHaulerIconHint')}
								</Translate>
							</div>
						</div>
						{renderHaulerList}
					</section>
				</div>
			</div>
		);
	};

	return (
		<div className="page">
			<div className="page-header">
				<div className="arrow-back px-3 py-1 mb-1 mr-2 cursor-pointer" onClick={() => props.history.goBack()}>
					<ArrowBack />
				</div>
				<h1 className="ml-2">
					<Translate>
						{localizationService.getLocalizedString('facilityPortal.settings.haulers.haulerCardTitle')}
					</Translate>
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">{getContractedHaulerSection()}</div>
			</div>
		</div>
	);
};
