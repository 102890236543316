import { CustomFieldType, CustomFormAttachment, Dictionary } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PageLoadingContainer } from 'src/components/layout/page-loading-container';
import { CloseSvg } from 'src/components/svg';
import { Translate } from 'src/components/widgets/translate/translator';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { documentService, localizationService, urlService } from 'src/services';
import { ConverterFactory } from 'src/services/converter-factory';
import { CustomFormService } from 'src/services/custom-form-service';

export interface OriginalSubmissionModalProp {
	showModal: boolean;
	onCancelButtonClick: (e?: any) => void;
	originalSubmittedFormHtmlContent: string;
	facilityCleaningFormElements: CustomFieldType[];
	files: CustomFormAttachment[];
	submittedLanguageCode?: string;
}

export const OriginalSubmissionModal: React.FC<OriginalSubmissionModalProp> = props => {
	const [sanitizedOriginalSubmittedFormHtmlContent, setSanitizedOriginalSubmittedFormHtmlContent] = React.useState(
		''
	);
	const toggleModal = (e?: any) => {
		if (e) e.preventDefault();
		props.onCancelButtonClick();
	};

	const closeBtn = (
		<button className="close" onClick={(e?: any) => toggleModal(e)}>
			<CloseSvg />
		</button>
	);

	React.useEffect(() => {
		if (props.originalSubmittedFormHtmlContent) {
			let sanitizedHtml = documentService.sanitizeHtmlFormForDisplayOnly(
				props.originalSubmittedFormHtmlContent,
				'attachmentsInput'
			);
			sanitizedHtml = sanitizedHtml.replaceAll(
				'id="attachmentsInput"',
				'id="attachmentsInputForOriginalSubmission"'
			);
			setSanitizedOriginalSubmittedFormHtmlContent(sanitizedHtml);
		}
	}, [props.originalSubmittedFormHtmlContent]);

	React.useEffect(() => {
		if (sanitizedOriginalSubmittedFormHtmlContent) {
			_.delay(() => {
				//hook attachments
				let attachmentsElem = document.getElementById('viewModeAttachmentsForOriginalSubmission');
				let snapshotFormAttachmentElem = document.getElementById('attachmentsInputForOriginalSubmission');

				if (snapshotFormAttachmentElem && snapshotFormAttachmentElem.parentElement && attachmentsElem) {
					let className = _.replace(attachmentsElem.className, 'attachment-hide', 'attachment-show');
					attachmentsElem.setAttribute('class', className);
					snapshotFormAttachmentElem.parentElement.replaceChild(attachmentsElem, snapshotFormAttachmentElem);
				}
			}, 200);
		}
	}, [sanitizedOriginalSubmittedFormHtmlContent]);
	return (
		<Modal
			isOpen={props.showModal}
			toggle={toggleModal}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}>
			<PageLoadingContainer />
			<ModalHeader tag="h1" toggle={toggleModal} close={closeBtn}>
				<Translate>
					{localizationService.getLocalizedString('haulerPortal.submitCleaning.originalSubmissionTitle')}
				</Translate>
			</ModalHeader>
			<ModalBody>
				<p>
					<a href="#/" onClick={toggleModal}>
						<Translate>
							{urlService.isAuthorityPortal()
								? localizationService.getLocalizedString(
										'haulerPortal.submitCleaning.originalSubmissionSubTitleForAuthorityPortal'
								  )
								: localizationService.getLocalizedString(
										'haulerPortal.submitCleaning.originalSubmissionSubTitleForFacilityPortal'
								  )}
						</Translate>
					</a>
					.
				</p>
				<div
					className="mail-content"
					dangerouslySetInnerHTML={{
						__html: sanitizedOriginalSubmittedFormHtmlContent
					}}
				/>
				{ConverterFactory.viewAttachments(
					props.facilityCleaningFormElements,
					props.files,
					'viewModeAttachmentsForOriginalSubmission',
					props.submittedLanguageCode,
					'en'
				)}
			</ModalBody>
			<ModalFooter></ModalFooter>
		</Modal>
	);
};
