import React, { FC } from 'react';
import { History } from 'history';
import { cccHazardSlice } from './hazards.slice';
import * as LinkoTypes from '@rcp/types';
import { CccHazard, ColumnField, Dictionary, FilterType, LocalStorageName, CustomFieldApplyToTable } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { customFieldQueries, DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { HazardGridTestActionLink } from './hazard-grid-test-action-link';
import { filterService, FilterDomain, FilterDomainName } from '../../../../features/filter/filter-service';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccHazards: FC<Props<CccHazard>> = props => {
	const hazardNumberToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.CccHazard;
		const url = urlService.getReactAuthorityResourceUrl(Resource.CccHazards, dataItem.hazardId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.hazardNumber}
				</a>
			</td>
		);
	};

	const siteNumberToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.CccHazard;
		const url = urlService.getReactAuthorityResourceUrl(Resource.CccSites, dataItem.siteId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.siteNumber}
				</a>
			</td>
		);
	};

	const enterTestCell = (item: any) => {
		const dataItem = item.dataItem as CccHazard;
		return dataItem.deviceId ? (
			<td>
				<HazardGridTestActionLink
					displayText={localizationService.getLocalizedString('cccHazard.enterTest')}
					isEnterTest={true}
					hazardId={dataItem.hazardId as number}
					testId={dataItem.nextTestId}
				/>
			</td>
		) : (
			<td></td>
		);
	};

	const lastTestDateCell = (item: any) => {
		const dataItem = item.dataItem as CccHazard;
		return (
			<td>
				<HazardGridTestActionLink
					displayText={DateUtilService.toDisplayDate(dataItem.lastTestDate)}
					isEnterTest={false}
					hazardId={dataItem.hazardId as number}
					testId={dataItem.lastTestId}
				/>
			</td>
		);
	};

	const nextTestDateCell = (item: any) => {
		const dataItem = item.dataItem as CccHazard;
		return (
			<td>
				<HazardGridTestActionLink
					displayText={DateUtilService.toDisplayDate(dataItem.nextTestDate)}
					isEnterTest={false}
					hazardId={dataItem.hazardId as number}
					testId={dataItem.nextTestId}
				/>
			</td>
		);
	};

	const getTitle = (): string => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'cccHazard.hazards');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'cccHazard.hazards');
		}
		return localizationService.getLocalizedString('cccHazard.hazards');
	};

	const composeGridQueryParameter = (): Dictionary<string> | undefined => {
		if (props.match.params.importId) {
			let importId = props.match.params.importId.toString();
			let importType = window.location.pathname.includes('/new') ? 'new' : 'update';
			if (importId && importType) {
				return { importId: importId, importType: importType } as Dictionary<string>;
			}
		}
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'cccHazard',
		storageName: LocalStorageName.CccHazardGrid,
		gridUrlLocalStorageName: LocalStorageName.CccHazardGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccHazardSortOrder,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccHazard.searchHint'),

		showAddButton: false,
		addButtonText: localizationService.getLocalizedString('screen.buttons.addHazard'),

		queryParameters: composeGridQueryParameter(),
		customFieldQuery: customFieldQueries.Backflow,
		customFieldsForGrid: [CustomFieldApplyToTable.ApplyToCccHazard],
		resetFilterService: () => {
			filterService.init(
				FilterDomain.CCCHAZARD,
				FilterDomainName.cccHazard,
				LinkoTypes.FilterObjectName.CccHazard,
				LocalStorageName.CccHazardGridFilterUrl,
				{ CCCHAZARD: true, CCCSITE: true }
			);
		},
		allColumns: [
			new ColumnField('hazardNumber', FilterType.Text, hazardNumberToLink),
			new ColumnField('dataSetName'),
			new ColumnField('siteNumber', FilterType.Text, siteNumberToLink),
			new ColumnField('siteType'),
			new ColumnField('siteUse'),
			new ColumnField('company'),
			new ColumnField('siteAddressLine1'),

			new ColumnField('meterNumber'),
			new ColumnField('category'),
			new ColumnField('serviceStatus'),
			new ColumnField('lineSize'),
			new ColumnField('protection'),
			new ColumnField('deviceLocation'),
			new ColumnField('meterLocation'),
			new ColumnField('deviceStatus'),
			new ColumnField('deviceSerialNumber'),
			new ColumnField('deviceType'),
			new ColumnField('sendTestNotices', FilterType.Boolean),
			new ColumnField('sendTestNoticesToSite', FilterType.Boolean),
			new ColumnField('deviceTestable', FilterType.Boolean),
			new ColumnField('deviceLeadFree', FilterType.Boolean),
			new ColumnField('deviceBypass', FilterType.Boolean),
			new ColumnField('fireline', FilterType.Boolean),
			new ColumnField('deviceOrientation'),
			new ColumnField('accountNumber'),
			new ColumnField('serviceNumber'),
			new ColumnField('serviceType'),
			new ColumnField('shutOffDate', FilterType.Date),
			new ColumnField('installDue', FilterType.Date),
			new ColumnField('installedDate', FilterType.Date),
			new ColumnField('lastTestDate', FilterType.Date, lastTestDateCell),
			new ColumnField('lastTestResult'),
			new ColumnField('nextTestDate', FilterType.Date, nextTestDateCell),
			new ColumnField('enterTest', FilterType.Text, enterTestCell),
			new ColumnField('lastSentNoticeDate', FilterType.Date),
			new ColumnField('lastSentNoticeTemplateName'),
			new ColumnField('nextDueNoticeDate', FilterType.Date),
			new ColumnField('nextDueNoticeTemplateName'),
			new ColumnField('testFrequencyCode')
		],

		defaultColumns: [
			'hazardNumber',
			'company',
			'siteAddressLine1',
			'deviceSerialNumber',
			'deviceType',
			'serviceStatus',
			'lastTestDate',
			'nextTestDate',
			'enterTest'
		]
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccHazardSlice}
				restState={(state: RootState) => state.cccHazards}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export default CccHazards;
