import React, { FC, useEffect, useState } from 'react';
import { localizationService } from 'src/services/localizationService';
import {
	DropDownOption,
	IppCollectionMethod,
	IppIndustrySample,
	IppMonitoringPoint,
	IppReportPackage
} from '@rcp/types';
import './step1.scss';
import { alertService, ApplicationState } from 'src/redux';
import {
	loadCollectionMethods,
	loadMonitoringPoints,
	loadParameterGroups,
	SampleState,
	setSampleData
} from 'src/redux/ipp/industry/samples';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { DateUtilService, navigateTo, Resource, urlService, validationService } from 'src/services';
import { History } from 'history';
import { CustomDateTimePicker } from 'src/components/widgets/custom-date-input';
import { SingleSelectDropdown } from 'src/components/widgets';
import _ from 'lodash';

interface DispatchProps {
	loadMonitoringPoints: () => Promise<boolean | undefined>;
	setSampleData: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	loadParameterGroups: (sample?: IppIndustrySample, clearPrev?: boolean) => Promise<boolean | undefined>;
	loadCollectionMethods: () => Promise<boolean | undefined>;
}

interface Props extends DispatchProps {
	history: History;
	monitoringPoints: IppMonitoringPoint[];
	collectionMethods?: IppCollectionMethod[];
}

const IppIndustrySampleStep1: FC<Props> = (props: Props) => {
	const [selectedMonitoringPoint, setSelectedMonitoringPoint] = useState('');
	const [selectedMonitoringPointId, setSelectedMonitoringPointId] = useState();
	const [selectedCollectionMethod, setSelectedCollectionMethod] = useState<IppCollectionMethod | undefined>();
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [sourcePackageId, setSourcePackageId] = useState<number | undefined>(0);

	useEffect(() => {
		props.loadMonitoringPoints();
		props.loadParameterGroups(undefined, true);
		props.loadCollectionMethods();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.history.location.state && (props.history.location.state as any).addSampleToReport) {
			let report: IppReportPackage = { ...(props.history.location.state as IppReportPackage) };
			setSourcePackageId(report.reportPackageId);
		}
	}, [props.history.location.state]);

	const { monitoringPoints } = props;

	const getCollectionMethods = (collectionMethods: IppCollectionMethod[]) => {
		let options: DropDownOption[] = collectionMethods.map(collectionMethod => {
			return {
				label: collectionMethod.name || '',
				value: collectionMethod.collectionMethodId
			};
		});
		options.unshift({
			label: localizationService.getLocalizedString(`ipp.samples.step2.selectCollectionMethod`),
			value: 0
		});
		return options;
	};

	const onChangeCollectionMethod = (e: any) => {
		const { value } = e.target;
		if (props.collectionMethods) {
			let selectedCollectMethod = props.collectionMethods.find(
				item => Number(item.collectionMethodId) === Number(value)
			);
			setSelectedCollectionMethod(selectedCollectMethod);

			if (_.toLower(selectedCollectMethod?.collectionMethodTypeName) === 'grab') {
				setEndDate(startDate);
			}
		}
	};

	const onClickMonitoringPoint = (event: any, monitoringPointId: any) => {
		const { value } = event.target;
		setSelectedMonitoringPoint(value);
		setSelectedMonitoringPointId(monitoringPointId);
	};

	const onChangeDate = (event: any) => {
		if (event.target) {
			const { name, value } = event.target;
			if (name === 'startDate') {
				setStartDate(value);
				setEndDate(value);
			} else {
				setEndDate(value);
				if (_.toLower(selectedCollectionMethod?.collectionMethodTypeName) === 'grab') {
					setStartDate(value);
				}
			}
		}
	};

	const onNext = () => {
		alertService.clearAllMessages();
		if (!validateForm()) {
			props.setSampleData({
				monitoringPointId: selectedMonitoringPointId,
				monitoringPointName: selectedMonitoringPoint,
				collectionMethodName: selectedCollectionMethod && selectedCollectionMethod.name,
				collectionMethodId: selectedCollectionMethod && selectedCollectionMethod.collectionMethodId,
				startDateTimeLocal: startDate,
				endDateTimeLocal: endDate
			});
			const url = urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/new/step2`);
			navigateTo(props.history, url, undefined, {
				monitoringPointId: selectedMonitoringPointId,
				monitoringPointName: selectedMonitoringPoint,
				collectionMethodName: selectedCollectionMethod && selectedCollectionMethod.name,
				collectionMethodId: selectedCollectionMethod && selectedCollectionMethod.collectionMethodId,
				startDateTimeLocal: startDate,
				endDateTimeLocal: endDate,
				sourcePackageId,
				addSampleToReport:
					props.history.location.state && (props.history.location.state as any).addSampleToReport,
				periodStartDateTimeLocal:
					props.history.location.state && (props.history.location.state as any).periodStartDateTimeLocal,
				periodEndDateTimeLocal:
					props.history.location.state && (props.history.location.state as any).periodEndDateTimeLocal,
				reportPackageElementTypeId:
					props.history.location.state &&
					(props.history.location.state as any).samplesAndResultsTypes[0].reportPackageElementTypeId
			});
		}
	};

	const validateForm = () => {
		let newState = { endDate, startDate, endDateError: '', startDateError: '' };
		validationService.validateMinimumDate(newState, 'endDate', 'endDateError');
		validationService.validateMinimumDate(newState, 'startDate', 'startDateError');

		if (validationService.hasError(newState, 'endDateError', 'startDateError')) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.minimumDateCheckMessage',
					'screen.labels.date'
				)
			);
			return true;
		}

		if (!selectedMonitoringPoint) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorMonitoringPointRequiredMessage')
			);
			return true;
		}

		if (!selectedCollectionMethod) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step2.errorCollectionMethodRequiredMessage')
			);
			return true;
		}
		if (endDate && !startDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorStartDateRequiredMessage')
			);
			return true;
		}
		if (startDate && !endDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorEndDateRequiredMessage')
			);
			return true;
		}
		if (!startDate && !endDate) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorDatesRequiredMessage')
			);
			return true;
		}
		return false;
	};

	return (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('ipp.samples.step1.title')}</h1>
					</div>
					<section>
						{props.history.location.state && (props.history.location.state as any).addSampleToReport && (
							<p className="font-size-20px-regular">
								{localizationService.getLocalizedString(
									'ipp.samples.step1.addNewSampleForReportPackageNote',
									props.history.location.state && (props.history.location.state as any).name
								)}
							</p>
						)}
						<span className="font-size-20px-regular">
							<label>
								<span className="text-danger mr-1">*</span>
								{localizationService.getLocalizedString('ipp.samples.step1.selectMonitoringPoint')}
							</label>
						</span>
						<div className="w-100 mx-auto">
							<div className="d-flex justify-content-between flex-wrap">
								{monitoringPoints && monitoringPoints.length ? (
									monitoringPoints.map(({ monitoringPointId, name, description }) => (
										<div key={monitoringPointId} className="radio-input m-2">
											<div className="custom-control custom-radio">
												<input
													type="radio"
													className="custom-control-input z-1"
													name="monitoringPointName"
													value={name}
													checked={selectedMonitoringPoint === name}
													id={`monitoring-${monitoringPointId}`}
													onChange={e => onClickMonitoringPoint(e, monitoringPointId)}
												/>
												<label
													className="custom-control-label"
													htmlFor={`monitoring-${monitoringPointId}`}>
													<strong>{name}</strong> {' - '} <span>{description}</span>
												</label>
											</div>
										</div>
									))
								) : (
									<p id="no-monitoring-point-message">
										{localizationService.getLocalizedString(
											'ipp.samples.step1.noMonitoringPointMessage'
										)}
									</p>
								)}
							</div>
						</div>
						<div className="my-3">
							<SingleSelectDropdown
								id="collection-method"
								name="collectionMethodName"
								label={localizationService.getLocalizedString(`ipp.samples.step2.collectionMethod`)}
								noEmptyOption={true}
								isRequired={true}
								options={getCollectionMethods(props.collectionMethods || [])}
								selfOrder={true}
								onChange={onChangeCollectionMethod}
								className="collection-method-container-width my-2"
							/>
						</div>
						<div className="my-3">
							<span className="font-size-20px-regular">
								<label>
									{localizationService.getLocalizedString('ipp.samples.step1.samplePeriodLabel')}
								</label>
							</span>
							<div className="date-container-width">
								<div className="d-flex my-2">
									<CustomDateTimePicker
										id="start-date"
										name="startDate"
										label={localizationService.getLocalizedString('ipp.samples.step1.startDate')}
										className="pl-1 date-width"
										onChange={onChangeDate}
										isRequired={true}
										value={startDate}
										max={DateUtilService.getAuthorityTimezoneNow()}
										helpText={
											props.history.location.state &&
											(props.history.location.state as any).addSampleToReport &&
											(props.history.location.state as any).periodStartDateTimeLocal &&
											localizationService.getLocalizedString(
												'ipp.samples.step1.addSampleToReportNote'
											)
										}
									/>
									<CustomDateTimePicker
										id="end-date"
										name="endDate"
										label={localizationService.getLocalizedString('ipp.samples.step1.endDate')}
										className="pl-1 date-width"
										isRequired={true}
										onChange={onChangeDate}
										max={DateUtilService.getAuthorityTimezoneNow()}
										value={endDate}
										helpText={
											props.history.location.state &&
											(props.history.location.state as any).addSampleToReport &&
											(props.history.location.state as any).periodEndDateTimeLocal &&
											localizationService.getLocalizedString(
												'ipp.samples.step1.addSampleToReportNote'
											)
										}
									/>
								</div>
							</div>
						</div>
						<div className="mt-3 d-flex">
							<button className="btn ai-action ml-auto mr-2" id="sample-next" onClick={() => onNext()}>
								{localizationService.getLocalizedString('ipp.samples.step1.next')}
							</button>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): SampleState => {
	return { ...state.ippIndustrySample };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadMonitoringPoints: () => dispatch(loadMonitoringPoints()),
		setSampleData: (sample: IppIndustrySample) => dispatch(setSampleData(sample)),
		loadParameterGroups: (sample?: IppIndustrySample, clearPrev?: boolean) =>
			dispatch(loadParameterGroups(sample, clearPrev)),
		loadCollectionMethods: () => dispatch(loadCollectionMethods())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppIndustrySampleStep1);
