import React, { FC, useEffect, useState } from 'react';
import { SingleCheckbox, TextAreaInput, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { DateUtilService, localizationService, validationService } from 'src/services';
import './review-summary.scss';

interface Props {
	onSubmit?: (comments: string, isIncomplete: boolean, isSubmissionReviewNoPendingAcceptance: boolean) => void;
	className?: string;
	hideActionButtons?: boolean;
	submissionReviewDateTimeLocal?: string;
	submissionReviewerFirstName?: string;
	submissionReviewerLastName?: string;
	submissionReviewerTitleRole?: string;
	submissionReviewComments?: string;
	submissionIncompleteContent?: boolean;
	reportStatusName?: string;
	submissionAcceptanceRequired?: boolean;
	onReject: (reason: string, isMissingContent: boolean, isAccepted: boolean) => void;
}

const { fieldCharLimit } = IppConstants;

const IppPackageSubmissionReview: FC<Props> = (props: Props) => {
	const [comments, setComments] = useState('');
	const [commentsError, setCommentsError] = useState('');
	const [submissionIncompleteContent, setSubmissionIncompleteContent] = useState<boolean>(false);
	const [isAccepted, setIsAccepted] = useState<boolean>(true);

	useEffect(() => {
		setComments(props.submissionReviewComments ? props.submissionReviewComments : '');
		setSubmissionIncompleteContent(props.submissionIncompleteContent ? props.submissionIncompleteContent : false);
	}, [props]);

	const onChangeComments = (event: any) => {
		setComments(event.target.value);
	};

	const isFormValid = () => {
		let isFormValid = false;
		let validatePayload = { comments, commentsError };
		isFormValid = validationService.validateRequiredField(validatePayload, 'comments', 'commentsError');
		setCommentsError(validatePayload.commentsError);
		return isFormValid;
	};

	const submit = () => {
		if (isFormValid() && String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Submitted)) {
			props.onSubmit && props.onSubmit(comments, submissionIncompleteContent, true);
		} else if (isFormValid() && props.reportStatusName !== ReportPackageStatus.Submitted) {
			props.onSubmit && props.onSubmit(comments, submissionIncompleteContent, false);
		}
	};

	const getLastReviewedBy = () => {
		return `${props.submissionReviewerFirstName} ${props.submissionReviewerLastName}`;
	};

	const getReviewDate = () => {
		return `${DateUtilService.toDisplayDate(props.submissionReviewDateTimeLocal)} ${DateUtilService.toDisplayTime(
			props.submissionReviewDateTimeLocal
		).toLocaleUpperCase()}`;
	};

	const shouldRenderIncompleteCheckbox = () => {
		const isAcceptedReport =
			props.reportStatusName &&
			isAccepted &&
			(String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.PendingAcceptance) ||
				String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Submitted) ||
				String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Repudiated) ||
				String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Error));
		return isAcceptedReport || !props.submissionAcceptanceRequired;
	};

	return (
		<>
			<CollapsibleCard
				className={props.className ? props.className : ''}
				accordionType="submission-review"
				accordionHeading={localizationService.getLocalizedString('ipp.reportPackage.package.submissionReview')}>
				<div className="w-75 mx-auto">
					{props.reportStatusName &&
						String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.PendingAcceptance) && (
							<>
								<label>
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.submissionAcceptRejectDescription'
									)}
								</label>
								<label className="mt-3">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.acceptRejectLabel'
									)}
									<span className="ai-required"> *</span>
								</label>
								<div className="form-row mt-2 checkbox-left-margin">
									<div className="custom-control custom-radio">
										<input
											type="radio"
											className="custom-control-input"
											name="acceptRejectReportPacakge"
											id="acceptReportPacakge"
											value="false"
											checked={isAccepted}
											onChange={() => {
												setIsAccepted(true);
											}}
										/>
										<label className="custom-control-label" htmlFor="acceptReportPacakge">
											{localizationService.getLocalizedString(
												'ipp.reportPackage.package.acceptReportCompleteSubmissionReview'
											)}
										</label>
									</div>
								</div>
								<div className="form-row mb-3 checkbox-left-margin">
									<div className="custom-control custom-radio">
										<input
											type="radio"
											className="custom-control-input"
											name="acceptRejectReportPacakge"
											id="rejectReportPacakge"
											value="false"
											checked={!isAccepted}
											onChange={() => {
												setIsAccepted(false);
											}}
										/>
										<label className="custom-control-label" htmlFor="rejectReportPacakge">
											{localizationService.getLocalizedString(
												'ipp.reportPackage.package.rejectReportSubmissionReview'
											)}
										</label>
									</div>
								</div>
							</>
						)}
					{props.submissionReviewDateTimeLocal && (
						<div className="form-row">
							<TextInput
								id="last-reviewed-by"
								name="lastReviewedBy"
								className="col-md-5"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.lastReviewedBy'
								)}
								value={getLastReviewedBy()}
							/>
							<div className="col-md-1" />
							<TextInput
								id="review-date"
								name="reviewDate"
								className="col-md-5"
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.submissionReviewDateTimeLocal'
								)}
								isDisabled={true}
								value={getReviewDate()}
							/>
						</div>
					)}

					<div className="form-row">
						<div className="col-sm-5">
							<TextAreaInput
								id="review-comment"
								name="submissionReviewComment"
								isFullWidth={true}
								isDisabled={props.submissionReviewDateTimeLocal ? true : false}
								value={comments}
								label={localizationService.getLocalizedString('ipp.reportPackage.package.comments')}
								onChange={onChangeComments}
								maxLength={fieldCharLimit.commentsAndDescription}
								isRequired={true}
								error={commentsError}
							/>
						</div>
						<div className="col-sm-1"></div>
						{shouldRenderIncompleteCheckbox() && (
							<div
								className={`${
									!!props.submissionReviewDateTimeLocal ? 'col-sm-5' : 'col-sm-12'
								}  pt-3 mb-4 checkbox-left-margin`}>
								<SingleCheckbox
									id="incomplete-contents"
									name="isIncomplete"
									label={localizationService.getLocalizedString(
										'ipp.reportPackage.package.missingContent'
									)}
									isDisabled={!!props.submissionReviewDateTimeLocal}
									className="align-self-center"
									checked={submissionIncompleteContent}
									onChange={(e: any) => setSubmissionIncompleteContent(e.target.checked)}
								/>
							</div>
						)}
					</div>
					<div className="form-row mt-2">
						{(String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Submitted) ||
							(!String.equalCaseInsensitive(props.reportStatusName, ReportPackageStatus.Submitted) &&
								isAccepted)) &&
							!props.submissionReviewDateTimeLocal &&
							!props.hideActionButtons && (
								<button
									className="btn ai-action ml-1"
									id="submission-review-complete-btn"
									onClick={() => submit()}>
									{localizationService.getLocalizedString(
										props.submissionAcceptanceRequired && isAccepted
											? 'ipp.reportPackage.package.acceptSubmissionBtn'
											: 'ipp.reportPackage.package.submissionReviewComplete'
									)}
								</button>
							)}
						{props.reportStatusName !== ReportPackageStatus.Submitted && !isAccepted && (
							<button
								className="btn ai-delete ml-1"
								id="submission-review-reject-btn"
								onClick={() => {
									isFormValid() &&
										props.onReject &&
										props.onReject(comments, submissionIncompleteContent, false);
								}}>
								{localizationService.getLocalizedString(
									'ipp.reportPackage.package.rejectSubmissionBtn'
								)}
							</button>
						)}
					</div>
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageSubmissionReview;
