import React from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import AttachmentProps from './attachment-props';
import { fileUtilService, navigateTo } from 'src/services';
import { AttachmentIcon } from '.';
import { TakeOverModal } from '../takeover-modal';
import { AttachmentOwnership } from '@rcp/types';

interface CompProps {
	noNavigation?: boolean;
	onAttachmentClick?: (attachment: AttachmentOwnership) => void;
}

type Props = AttachmentProps & CompProps;

export const AttachmentThumbnail: React.FC<Props> = props => {
	const history = useHistory();
	const [showModal, setShowModal] = React.useState(false);
	const iconPictureRef = React.useRef<HTMLAnchorElement | null>(null);
	const fileType = _.toLower(props.attachment.fileType);

	if (fileUtilService.isPicture(fileType)) {
		const backgroundImage = props.attachment.s3ThumbnailUrl
			? props.attachment.s3ThumbnailUrl
			: props.attachment.s3ResourceUrl;
		const divStyle = {
			backgroundImage: `url(${backgroundImage})`
		};

		const onToggle = () => setShowModal(!showModal);

		const onPictureClick = (event: any) => {
			if (props.noNavigation && props.onAttachmentClick) {
				return props.onAttachmentClick(props.attachment);
			}
			props.insideModal ? onToggle() : navigateToPage(event);
		};

		const navigateToPage = (event: any) => {
			navigateTo(history, props.linkTo, event, { editMode: props.editMode });
		};

		const downloadImage = async () => {
			if (props.attachment && props.attachment.s3ResourceUrl && props.attachment.fileName) {
				let a = document.createElement('a');
				a.href = props.attachment.s3ResourceUrl;
				a.download = props.attachment.fileName;
				a.target = '_blank';
				a.click();
			}
		};

		return (
			<>
				<a href={props.noNavigation ? undefined : props.linkTo} className="ai-link" onClick={onPictureClick}>
					<div className={props.className} style={divStyle} title={props.attachment.fileName} />
				</a>
				{showModal && (
					<TakeOverModal
						cancel={onToggle}
						title=""
						isAboveAnotherModal
						buttonSize="12"
						isDownloadButton={props.isDownloadButton}
						downloadImage={downloadImage}>
						<div className="d-flex align-items-center justify-content-center">
							<img src={props.attachment.s3ResourceUrl} alt="no-preview"></img>
						</div>
					</TakeOverModal>
				)}
			</>
		);
	}

	const onIconClick = (e: any) => {
		if (props.noNavigation && props.onAttachmentClick) {
			return props.onAttachmentClick(props.attachment);
		}

		props.insideModal
			? iconPictureRef && iconPictureRef.current!.click()
			: navigateTo(history, props.linkTo, e, { editMode: props.editMode });
	};

	return (
		<>
			<a href={props.noNavigation ? undefined : props.linkTo} className="ai-link" onClick={onIconClick}>
				<div className={props.className} title={props.attachment.fileName}>
					<div className="d-flex flex-column">
						<div className="mx-auto" onClick={onIconClick}>
							<AttachmentIcon attachment={props.attachment} className="attachment-icon" />
						</div>
						<div className="mx-auto attachment-file-type wrapWord">
							{props.showFileNameInsteadOfFileType
								? props.attachment.fileName
								: props.attachment.fileType}
						</div>
					</div>
				</div>
			</a>
			<a href={props.attachment.s3ResourceUrl} target="_blank" rel="noopener noreferrer" ref={iconPictureRef} />
		</>
	);
};
