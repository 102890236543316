import _ from 'lodash';
import { localizationService } from './localizationService';
import {
	Lookup,
	SimpleUserProfile,
	DropDownOption,
	EventType,
	Frequency,
	TagItem,
	Jurisdiction,
	TimeZone,
	Dataset,
	Hauler
} from '@rcp/types';
import { tokenService } from './token-service';

class OptionsMap {
	toOptions<T>(
		domainObjects: T[],
		toOption: (domainObject: T) => DropDownOption,
		isRequired = false,
		filter?: (DomainObject: T) => boolean
	): DropDownOption[] {
		let objects = filter ? _.filter(domainObjects, filter) : domainObjects;
		let options = objects.map(toOption);
		if (isRequired === false) {
			options.unshift({
				label: '',
				value: '',
				isHidden: true
			});
		}
		return options;
	}

	toRequiredOptions<T>(
		domainObjects: T[],
		toOption: (domainObject: T) => DropDownOption,
		filter?: (DomainObject: T) => boolean
	): DropDownOption[] {
		return this.toOptions<T>(domainObjects, toOption, true, filter);
	}

	fromEventTypes(userProfiles: EventType[], noHiddenOption?: boolean): DropDownOption[] {
		let options = userProfiles.map(
			(evt: EventType): DropDownOption => {
				return {
					label: _.toString(evt.eventTypeAbbreviation),
					value: evt.eventTypeId
				};
			}
		);
		options.unshift({
			label: '',
			value: '',
			isHidden: noHiddenOption ? false : true
		});
		return options;
	}
	fromUserProfiles(userProfiles: SimpleUserProfile[], isLinkoSupportRequired?: boolean): DropDownOption[] {
		if (isLinkoSupportRequired === false) {
			userProfiles = userProfiles.filter(function(val) {
				return val.fullName !== localizationService.getLocalizedString('users.linkoSupport');
			});
		}
		let options = userProfiles.map(
			(user: SimpleUserProfile): DropDownOption => {
				return {
					label: _.toString(user.fullName),
					value: user.userProfileId
				};
			}
		);

		return options;
	}

	fromHaulers(haulers: Hauler[]): DropDownOption[] {
		let options = haulers
			.filter(hauler => hauler.isActive)
			.map((hauler: Hauler) => {
				return {
					label: hauler.name,
					value: hauler.haulerId
				} as DropDownOption;
			})
			.sort((a, b) => {
				return a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1;
			});

		return options;
	}

	fromFrequencyList(frequencyList: Frequency[]): DropDownOption[] {
		return frequencyList
			.filter(x => x.isActive)
			.map((frequency: Frequency) => {
				return { label: frequency.frequencyCode, value: frequency.frequencyId };
			});
	}

	sortFrequencies(a: Frequency, b: Frequency): number {
		return a.frequencyCode.localeCompare(b.frequencyCode, 'en', { numeric: true });
	}

	fromTimeZone(timeZones: TimeZone[]): DropDownOption[] {
		return timeZones.map((timeZone: TimeZone, index: number) => {
			return {
				label: tokenService.hasAccessToIppPortal() ? timeZone.displayName : timeZone.name,
				value: timeZone.timeZoneId
			};
		});
	}

	fromJurisdiction(jurisdictions: Jurisdiction[]): DropDownOption[] {
		return jurisdictions.map((jurisdiction: Jurisdiction, index: number) => {
			return { label: jurisdiction.name, value: jurisdiction.jurisdictionId };
		});
	}

	fromClassificationList(tagItems: TagItem[]): DropDownOption[] {
		if (!tagItems) {
			return new Array<DropDownOption>();
		}
		return tagItems
			.filter(x => x.isActive)
			.map((tagItem: TagItem) => {
				return { label: tagItem.name, value: tagItem.name };
			});
	}

	fromLookups(lookups: Lookup[], value?: number, label?: string, orderAlphabetic: boolean = false): DropDownOption[] {
		let options = lookups
			.filter(l => l.isActive)
			.map(
				(lookup: Lookup): DropDownOption => {
					return {
						label: _.toString(lookup.code),
						value: _.toString(lookup.lookupId)
					};
				}
			);
		if (orderAlphabetic) {
			options = options.sort((a, b) => {
				if (a.code && b.code) {
					if (a.code.toUpperCase() > b.code.toUpperCase()) {
						return 1;
					}
				}
				return -1;
			});
		}
		this.updateInactiveOptions(options, value, label);
		return options;
	}

	fromDataset(datasets: Dataset[], value?: number, label?: string): DropDownOption[] {
		if (!datasets) {
			return new Array<DropDownOption>();
		}
		let options = datasets
			.filter(a => a.isActive)
			.map((a: Dataset) => {
				return { label: a.name!, value: a.dataSetId, isActive: a.isActive };
			});

		this.updateInactiveOptions(options, value, label);
		return options;
	}

	updateInactiveOptions(options: DropDownOption[], value?: number, label?: string) {
		if (value && !_.find(options, { value: value })) {
			options.push({ label: label as string, value: value, isHidden: true });
		}
	}

	fromStrings(strings: string[]): DropDownOption[] {
		let options = strings.map(
			(str: string): DropDownOption => {
				return {
					label: str,
					value: str
				};
			}
		);
		return options;
	}

	resolveDropdownOptionsWithInactiveOrDeletedId(dropDownList: any[], inactiveOrDeletedId: number) {
		const deletedState: string = localizationService.getLocalizedString('screen.labels.deleted');
		const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
		const activeDropDownList = dropDownList.filter(dropDown => dropDown.isActive === true);
		const selectedValue = dropDownList.find(dropDown => dropDown.lookupId === inactiveOrDeletedId);
		let state: string = '';
		if (selectedValue) {
			state = inactiveState;
		} else {
			state = deletedState;
		}
		return { state, activeDropDownList };
	}

	resovleDatesetOptionsWithInactiveOrDeletedId(dropDownList: any[], inactiveOrDeletedId: number) {
		const deletedState: string = localizationService.getLocalizedString('screen.labels.deleted');
		const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
		const activeDropDownList = dropDownList.filter(dropDown => dropDown.isActive === true);
		const selectedValue = dropDownList.find(dropDown => dropDown.dataSetId === inactiveOrDeletedId);
		let state: string = '';
		if (selectedValue) {
			state = inactiveState;
		} else {
			state = deletedState;
		}
		return { state, activeDropDownList };
	}
}

export const optionsMap = new OptionsMap();
