import { DropDownOption, InvitationDetails, IppUserProfile, Jurisdiction, SignInResult } from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { IppConstants } from 'src/constants';
import { alertService } from 'src/redux/alert/alert-service';
import { apiService, localizationService, urlService, UtilService, validationService } from 'src/services';
import { SingleCheckbox, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { TooltipHover } from '../widgets/tooltip-hover';
import { UserInfoRef } from './register';

interface Props {
	onNextUserInformation?: (userInfo: any) => void;
	invitationDetails?: InvitationDetails;
	states: Jurisdiction[];
	profileDetails?: IppUserProfile;
	registrationToken?: number;
	history: History;
	userInfo?: FormFields;
	setIsRegistrationSuccessful?: any;
	aqiIdpUserName?: string;
}

interface FormFields extends IppUserProfile {
	password?: string;
	confirmPassword?: string;
	confirmPasswordError?: string;
	emailError?: string;
	firstNameError?: string;
	fullNameError?: string;
	lastNameError?: string;
	phoneNumberError?: string;
	userNameError?: string;
	titleRoleError?: string;
	addressLine1Error?: string;
	cityNameError?: string;
	stateError?: string;
	zipCodeError?: string;
	passwordError?: string;
	businessNameError?: string;
	titleRole?: string;
	state?: string;
}

const initialFormFields: FormFields = {
	email: '',
	firstName: '',
	fullName: '',
	lastName: '',
	phoneNumber: '',
	userName: '',
	phoneExt: '',
	titleRole: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	zipCode: '',
	businessName: '',
	password: '',
	state: ''
};

const { fieldCharLimit } = IppConstants;

const UserInformationComponent = (props: Props, ref: Ref<UserInfoRef>) => {
	const [formState, setFormState] = useState(initialFormFields);
	const [stateOptionValues, setStateOptionValues] = useState([] as DropDownOption[]);
	const [disableUserName, setDisableUserName] = useState(false);
	const [isReregister, setIsReregister] = useState(false);
	const [isNewUserToIpp, setIsNewUserToIpp] = useState(false);
	const [agreement, setAgreement] = useState(false);

	const isUserInfoSaved = () => onNext();
	const isAqiIdpUser =
		props.invitationDetails &&
		UtilService.hasValue(props.invitationDetails.aqiIdpUserId) &&
		UtilService.hasValue(props.invitationDetails.aqiIdpUserName);

	useImperativeHandle(ref, () => ({ isUserInfoSaved }));

	React.useEffect(() => {
		if (props.userInfo && Object.keys(props.userInfo).length) {
			let newState = { ...props.userInfo };
			setFormState({ ...newState });
		}
	}, [props.userInfo]);

	React.useEffect(() => {
		if (props.invitationDetails && !(props.userInfo && Object.keys(props.userInfo).length)) {
			const {
				emailAddress,
				firstName,
				lastName,
				isResetInvitation,
				userProfileDto,
				isNewUserToIpp
			} = props.invitationDetails;
			let userName = isAqiIdpUser
				? props.invitationDetails.aqiIdpUserName
				: userProfileDto && userProfileDto.userName
				? userProfileDto.userName
				: emailAddress;
			if (isResetInvitation && userProfileDto) {
				setDisableUserName(true);
				setFormState({
					...formState,
					...userProfileDto,
					userName: userName
				});
			} else if (isNewUserToIpp && userProfileDto) {
				setIsNewUserToIpp(true);
				setDisableUserName(true);
				setFormState({
					...formState,
					...userProfileDto,
					password: '******',
					userName: userName
				});
			} else if (!isResetInvitation && userProfileDto) {
				setDisableUserName(true);
				setFormState({
					...formState,
					...userProfileDto,
					password: '******',
					userName: userName
				});
				setIsReregister(true);
			} else {
				let newFormState = { ...formState, email: emailAddress, firstName: firstName, lastName: lastName };
				if (isAqiIdpUser) {
					newFormState.userName = props.invitationDetails.aqiIdpUserName;
					setDisableUserName(true);
				}
				setFormState(newFormState);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.invitationDetails]);

	React.useEffect(() => {
		let stateOptions = props.states.map(state => ({ label: state.name, value: state.jurisdictionId }));
		setStateOptionValues(stateOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.states]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);

		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'firstName',
			'firstNameError',
			localizationService.getLocalizedString('ipp.accountPortal.firstName')
		);
		validationService.validateRequiredField(
			newState,
			'lastName',
			'lastNameError',
			localizationService.getLocalizedString('ipp.accountPortal.lastName')
		);
		validationService.validateRequiredField(
			newState,
			'businessName',
			'businessNameError',
			localizationService.getLocalizedString('ipp.accountPortal.organizationName')
		);
		validationService.validateRequiredField(
			newState,
			'phoneNumber',
			'phoneNumberError',
			localizationService.getLocalizedString('ipp.accountPortal.phoneNumber')
		);
		validationService.validateRequiredField(
			newState,
			'addressLine1',
			'addressLine1Error',
			localizationService.getLocalizedString('ipp.accountPortal.addressLine1')
		);
		validationService.validateRequiredField(
			newState,
			'cityName',
			'cityNameError',
			localizationService.getLocalizedString('ipp.accountPortal.cityName')
		);
		validationService.validateRequiredField(
			newState,
			'jurisdictionId',
			'stateError',
			localizationService.getLocalizedString('ipp.accountPortal.state')
		);
		validationService.validateRequiredField(
			newState,
			'zipCode',
			'zipCodeError',
			localizationService.getLocalizedString('ipp.accountPortal.zipCode')
		);
		validationService.validateRequiredField(
			newState,
			'email',
			'emailError',
			localizationService.getLocalizedString('ipp.accountPortal.email')
		);
		validationService.validateRequiredField(
			newState,
			'userName',
			'userNameError',
			localizationService.getLocalizedString('ipp.accountPortal.userName')
		);
		if (!(isNewUserToIpp || isAqiIdpUser)) {
			validationService.validateRequiredField(
				newState,
				'password',
				'passwordError',
				localizationService.getLocalizedString('ipp.accountPortal.password')
			);
			validationService.validateRequiredField(
				newState,
				'confirmPassword',
				'confirmPasswordError',
				localizationService.getLocalizedString('ipp.accountPortal.confirmPassword')
			);
			if (formState.password) {
				validationService.validatePasswordField(
					newState,
					'password',
					'passwordError',
					localizationService.getLocalizedString('ipp.accountPortal.password')
				);
			}

			if (formState.confirmPassword) {
				validationService.validatePasswordField(
					newState,
					'confirmPassword',
					'confirmPasswordError',
					localizationService.getLocalizedString('ipp.accountPortal.confirmPassword')
				);
			}

			if (formState.password && formState.confirmPassword && formState.password !== formState.confirmPassword) {
				newState.confirmPasswordError = localizationService.getLocalizedString(
					'ipp.accountPortal.passwordNotMatchMessage'
				);
			}
		}
		setFormState(newState);
		const isFromValid = !validationService.hasError(
			newState,
			'confirmPasswordError',
			'emailError',
			'firstNameError',
			'lastNameError',
			'phoneNumberError',
			'userNameError',
			'titleRoleError',
			'addressLine1Error',
			'cityNameError',
			'zipCodeError',
			'passwordError',
			'businessNameError'
		);
		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const onNext = () => {
		let {
			email,
			firstName,
			lastName,
			phoneNumber,
			userName,
			phoneExt,
			titleRole,
			addressLine1,
			addressLine2,
			cityName,
			zipCode,
			password,
			businessName,
			jurisdictionId
		} = formState;

		let userInfo = {
			firstName,
			lastName,
			userName,
			addressLine1,
			addressLine2,
			cityName,
			zipCode,
			email,
			password,
			businessName: businessName,
			titleRole,
			phoneNumber,
			phoneExt,
			jurisdictionId: jurisdictionId
		};

		if (isFormValidateForSave()) {
			props.onNextUserInformation && props.onNextUserInformation(userInfo);
			return userInfo;
		} else {
			return false;
		}
	};

	const reRegister = async () => {
		let userInfo = _.cloneDeep(formState);
		userInfo.agreeTermsAndConditions = agreement || false;
		delete userInfo.password;
		let registrationType = 'ReRegistration';
		let registerData = {
			userInfo,
			registrationToken: props.registrationToken,
			registrationType: registrationType,
			mfaType: 'QuestionAndAnswers'
		};

		if (agreement) {
			let url = `${urlService.getApiBaseUrl()}/Account/RegisterByInvitation`;
			try {
				await apiService.httpPost(url, registerData);
				alertService.addSuccess(
					localizationService.getLocalizedString('ipp.accountPortal.messages.registrationSuccess')
				);
				props.setIsRegistrationSuccessful(true);
			} catch (ex) {
				alertService.addError(ex.message);
			}
		} else {
			alertService.addError(
				localizationService.getLocalizedString('ipp.accountPortal.messages.termsAndConditionError')
			);
		}
	};

	return (
		<>
			{props.invitationDetails && props.invitationDetails.authorityName && (
				<div className="form-row">
					<div className="col-md-12">
						<label id="authority-name">
							<span className="font-size-16px-semibold ">
								{localizationService.getLocalizedString('ipp.accountPortal.authorityName')}:
							</span>
							{' ' + props.invitationDetails.authorityName}
						</label>
					</div>
				</div>
			)}
			{props.invitationDetails && props.invitationDetails.industryName && (
				<div className="form-row">
					<div className="col-md-12">
						<label id="authority-name">
							<span className="font-size-16px-semibold ">
								{localizationService.getLocalizedString('ipp.accountPortal.industryName')}:
							</span>
							{' ' + props.invitationDetails.industryName}
						</label>
					</div>
				</div>
			)}
			{props.invitationDetails && props.invitationDetails.description && (
				<div className="form-row mt-1">
					<div className="col-md-12">
						<label id="description">
							<span className="font-size-16px-semibold ">
								{localizationService.getLocalizedString('ipp.accountPortal.description')}:
							</span>
							{' ' + props.invitationDetails.description}
						</label>
					</div>
				</div>
			)}

			<hr />

			<div className="form-row">
				<TextInput
					id="firstName"
					name="firstName"
					label={localizationService.getLocalizedString('ipp.accountPortal.firstName')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.firstName}
					error={formState.firstNameError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>

				<TextInput
					id="last-name"
					name="lastName"
					label={localizationService.getLocalizedString('ipp.accountPortal.lastName')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.lastName}
					error={formState.lastNameError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="business-name"
					name="businessName"
					label={localizationService.getLocalizedString('ipp.accountPortal.organizationName')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.businessName}
					error={formState.businessNameError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.organization }}
				/>

				<TextInput
					id="title-role"
					name="titleRole"
					label={localizationService.getLocalizedString('ipp.accountPortal.title')}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.titleRole}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.title }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="phone"
					name="phoneNumber"
					label={localizationService.getLocalizedString('ipp.accountPortal.phoneNumber')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.phoneNumber}
					error={formState.phoneNumberError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
				<TextInput
					id="phoneExt"
					name="phoneExt"
					label={localizationService.getLocalizedString('ipp.accountPortal.phoneExt')}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.phoneExt}
					doNotTranslate={true}
					onChange={changeFormState}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="address-line1"
					name="addressLine1"
					label={localizationService.getLocalizedString('ipp.accountPortal.addressLine1')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.addressLine1}
					error={formState.addressLine1Error}
					onChange={changeFormState}
					doNotTranslate={true}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>

				<TextInput
					id="address-line2"
					name="addressLine2"
					label={localizationService.getLocalizedString('ipp.accountPortal.addressLine2')}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.addressLine2}
					onChange={changeFormState}
					doNotTranslate={true}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="city"
					name="cityName"
					label={localizationService.getLocalizedString('ipp.accountPortal.cityName')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.cityName}
					error={formState.cityNameError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.longInfo }}
				/>

				<SingleSelectDropdown
					id="jurisdictionId"
					name="jurisdictionId"
					label={localizationService.getLocalizedString('ipp.accountPortal.state')}
					isDisabled={isReregister}
					value={_.toString(formState.jurisdictionId)}
					className="col-md-6"
					options={stateOptionValues}
					isRequired={true}
					error={formState.stateError}
					doNotTranslateLabel={true}
					doNotTranslateOptions={true}
					onChange={changeFormState}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="zip-code"
					name="zipCode"
					label={localizationService.getLocalizedString('ipp.accountPortal.zipCode')}
					isRequired={true}
					isDisabled={isReregister}
					className="col-md-6"
					value={formState.zipCode}
					error={formState.zipCodeError}
					doNotTranslate={true}
					onChange={changeFormState}
					remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
				/>
			</div>
			<hr />
			<div className="form-row">
				<TextInput
					id="email"
					name="email"
					label={localizationService.getLocalizedString('ipp.accountPortal.email')}
					isRequired={true}
					isDisabled={true}
					className="col-md-6"
					value={formState.email}
					error={formState.emailError}
					onChange={changeFormState}
					doNotTranslate={true}
					remainingInputProps={{ maxLength: fieldCharLimit.email }}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="userName"
					name="userName"
					label={localizationService.getLocalizedString('ipp.accountPortal.userName')}
					isRequired={true}
					isDisabled={disableUserName}
					className="col-md-6"
					value={formState.userName}
					error={formState.userNameError}
					onChange={changeFormState}
					doNotTranslate={true}
					remainingInputProps={{ maxLength: fieldCharLimit.user.userName }}
				/>
			</div>
			{!isAqiIdpUser && (
				<div className="form-row">
					<TextInput
						id="password"
						name="password"
						label={localizationService.getLocalizedString('ipp.accountPortal.password')}
						isRequired={true}
						isDisabled={isReregister || isNewUserToIpp}
						className="col-md-6"
						type="password"
						error={formState.passwordError}
						onChange={changeFormState}
						value={formState.password}
						doNotTranslate={true}
					/>
					<TooltipHover
						id="password-tooltip"
						className="d-flex align-items-center"
						title={localizationService.getLocalizedString('ipp.accountPortal.passwordTooltip')}
					/>
				</div>
			)}
			{!isReregister && !isNewUserToIpp && !isAqiIdpUser && (
				<div className="form-row">
					<TextInput
						id="confirm-password"
						name="confirmPassword"
						label={localizationService.getLocalizedString('ipp.accountPortal.confirmPassword')}
						type="password"
						isRequired={true}
						className="col-md-6"
						value={formState.confirmPassword}
						error={formState.confirmPasswordError}
						onChange={changeFormState}
						doNotTranslate={true}
					/>
				</div>
			)}
			{isReregister && (
				<div className="form-row">
					<div className="col-md-6">
						<SingleCheckbox
							id="agree-terms-and-condition"
							name="agreeTermsAndCondition"
							label={
								<span>
									{localizationService.getLocalizedString('ipp.accountPortal.agreeLabel')}
									<a href="/TermsAndConditions" target="_blank">
										{localizationService.getLocalizedString('ipp.accountPortal.termsAndConditions')}
									</a>
								</span>
							}
							checked={agreement}
							className="form-group div-checkbox"
							onChange={(e: any) => setAgreement(e.target.checked)}
						/>
					</div>
					<div className="col-md-6 d-flex">
						<button className="btn ai-action ml-auto" onClick={() => reRegister()}>
							{localizationService.getLocalizedString('ipp.accountPortal.register')}
						</button>
					</div>
				</div>
			)}
		</>
	);
};

export default forwardRef(UserInformationComponent);
