import React from 'react';
import { NavLink } from 'react-router-dom';
import { Translate } from 'src/components/widgets/translate/translator';
import { triggerMainSidebarHamburgerClick } from '../triggerMainSidebarHamburgerClick';

interface NavLinkItem {
	title: string;
	href: string;
	itemHeaderClass?: string;
	clickHandler?: (e: any) => void;
	selectionTextClass?: string;
	count?: number;
	countColor?: string;
	hide?: boolean;
}

export function SideNavMenu({
	title,
	link,
	id,
	count,
	countColor,
	itemHeaderClass
}: {
	title: string;
	link: NavLinkItem[] | string;
	id?: string;
	count?: number;
	countColor?: string;
	itemHeaderClass?: string;
	hide?: boolean;
}) {
	if (Array.isArray(link)) {
		const childLinksBlockId = id ? id : 'default-child-links-block-id';
		const selectedPagePathName = window.location.pathname;
		return (
			<>
				<h2 className={itemHeaderClass || ''}>{title}</h2>
				<div id={childLinksBlockId} className={'ml-2'}>
					{link.map((linkItem, index) => {
						const activeClassName = String.equalCaseInsensitive(selectedPagePathName, linkItem.href)
							? 'active'
							: '';
						return (
							!linkItem.hide && (
								<NavLink
									to={linkItem.href}
									key={index}
									onClick={() => {
										if (window.matchMedia('(max-width: 900px)').matches) {
											triggerMainSidebarHamburgerClick();
										}
									}}
									activeClassName={activeClassName}>
									<span
										className={linkItem.selectionTextClass}
										onClick={(e: any) => {
											linkItem.clickHandler && linkItem.clickHandler(e);
										}}>
										{linkItem.title}
									</span>
									{linkItem.count !== 0 && (
										<span className={`badge float-right text-white ${linkItem.countColor}`}>
											{linkItem.count}
										</span>
									)}
								</NavLink>
							)
						);
					})}
				</div>
			</>
		);
	} else {
		return (
			<NavLink
				to={link}
				onClick={() => {
					if (window.matchMedia('(max-width: 900px)').matches) {
						triggerMainSidebarHamburgerClick();
					}
				}}
				activeClassName="active">
				<Translate>{title}</Translate>
				{count !== 0 && <span className={`badge float-right text-white ${countColor}`}>{count}</span>}
			</NavLink>
		);
	}
}
