import * as React from 'react';
import { BulkUpdateSteps, CustomFieldDataType, ApiError, BulkUpdateSummary } from '@rcp/types';
import { CustomField, FogDeviceField, BulkUpdateFields } from './bulk-update-service';
import _ from 'lodash';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, validationService } from 'src/services';
import { alertService, batchUpdateFacilitiesDevices } from 'src/redux';
import { FieldTypeEnum } from '../../../facilities/facility-details/facility-types';
import { facilityFieldGenerator } from '../../../facilities/facility-details/bulk-update/facilityFieldGenerator';

interface Props {
	vertical?: boolean;
}

const InputFieldValuesStep: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [chosenCustomFields, setChosenCustomFields] = React.useState<CustomField[]>([]);
	const [chosenFogDeviceFields, setChosenFogDeviceFields] = React.useState<FogDeviceField[]>([]);

	const back = () => stepperContext.goAt(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);

	React.useEffect(() => {
		let bulkUpdateFields: BulkUpdateFields = { ...stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS) };
		if (bulkUpdateFields) {
			setChosenCustomFields(bulkUpdateFields.customFields);
			setChosenFogDeviceFields(bulkUpdateFields.fogDeviceFields);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	const customFieldsValuesValidateForSave = (): boolean => {
		let newState = chosenCustomFields.filter(i => i.chosen === true).slice();
		_.each(newState, customField => {
			if (customField.chosen === true) {
				(customField.isRequired || customField.isImportKey) &&
					validationService.validateRequiredField(customField, 'value', 'error');
				if (
					customField.fieldDataType &&
					String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Number)
				) {
					validationService.validateNumberField(customField, 'value', 'error');
				}
				if (customField.fieldDataType && customField.fieldDataType === CustomFieldDataType.Link) {
					validationService.validateUrlField(customField, 'value', 'error');
				}
			}
		});

		setChosenCustomFields(newState);
		let hasError = false;
		_.each(newState, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};

	const deviceFieldValuesValidateForSave = (): boolean => {
		let newState = chosenFogDeviceFields.filter(i => i.chosen === true).slice();
		_.each(newState, deviceField => {
			if (deviceField.chosen === true) {
				deviceField.isRequired && validationService.validateRequiredField(deviceField, 'value', 'error');
				if (deviceField.dataType && deviceField.dataType === FieldTypeEnum.Number) {
					validationService.validateNumberField(deviceField, 'value', 'error');
				}
				if (deviceField.dataType && deviceField.dataType === FieldTypeEnum.Integer) {
					validationService.validateIntegerNumberField(deviceField, 'value', 'error');
				}
			}
		});

		setChosenFogDeviceFields(newState);
		let hasError = false;
		_.each(newState, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};
	const validateBeforeSave = () => {
		let customFieldValeValid = customFieldsValuesValidateForSave();
		let deviceFieldValesValid = deviceFieldValuesValidateForSave();

		return customFieldValeValid && deviceFieldValesValid;
	};

	const onClickNext = () => {
		var isValid = validateBeforeSave();

		if (!isValid) {
			return;
		}

		let step2State = { ...stepperContext.getStep(BulkUpdateSteps.STEP2_INPUT_VALUES), loading: true };
		stepperContext.updateStep(BulkUpdateSteps.STEP2_INPUT_VALUES, step2State);
		batchUpdateFacilitiesDevices(
			chosenCustomFields.filter(i => i.chosen === true),
			chosenFogDeviceFields.filter(i => i.chosen === true)
		)
			.then((bulkUpdateSummary: any) => {
				stepperContext.resolve(bulkUpdateSummary);
			})
			.catch((err: ApiError) => {
				let errMsg = `${err.message} ${err.body.internalMessage}`;
				let summary: BulkUpdateSummary = {
					errors: [errMsg]
				} as BulkUpdateSummary;

				stepperContext.resolve(summary);
			});
	};

	const onDeviceFieldChanged = (e: any) => {
		let newState = chosenFogDeviceFields.slice();
		let newValue: any;
		const { name, value } = e.target;
		newValue = value;
		if (value === 'true') {
			newValue = true;
		} else if (value === 'false') {
			newValue = false;
		}

		_.each(newState, deviceField => {
			if (String.equalCaseInsensitive(deviceField.fieldName, name)) {
				deviceField.value = deviceField.dataType === FieldTypeEnum.Date ? newValue : newValue;
				if (name === 'isActive') {
					deviceField.value = newValue;
				}
			}
		});

		setChosenFogDeviceFields(newState);
	};

	const getDeviceFieldsUpdateDiv = () => {
		const chosenItems = chosenFogDeviceFields.filter(i => i.chosen === true);

		if (chosenItems.length < 1) {
			return '';
		}

		const facilityUpdateDivs = chosenItems.map((field, index) => {
			switch (field.dataType) {
				case FieldTypeEnum.Date:
					return facilityFieldGenerator.getDateInputElement(index, field, onDeviceFieldChanged);
				case FieldTypeEnum.SingleValueDropDown:
					return facilityFieldGenerator.getOptionEditDiv(index, field, onDeviceFieldChanged);
				case FieldTypeEnum.String:
					return facilityFieldGenerator.getTextEditDiv(index, field, onDeviceFieldChanged);
				default:
					return facilityFieldGenerator.getTextEditDiv(index, field, onDeviceFieldChanged);
			}
		});

		return (
			<>
				<label className="choose-field-head">
					{localizationService.getLocalizedString('bulkUpdate.deviceFieldHead')}
				</label>
				{facilityUpdateDivs}
			</>
		);
	};
	return (
		<StepperContent
			className="full-width"
			id="step3ChooseFacilityFields"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn btn-link" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction type="button" id="btnUpdate" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('bulkUpdate.update')}
					</StepperAction>
				</React.Fragment>
			}>
			<p>{localizationService.getLocalizedString('bulkUpdate.updateDeviceReminder')}</p>
			{getDeviceFieldsUpdateDiv()}
		</StepperContent>
	);
};

export default InputFieldValuesStep;
