import { IppAASAuthoritySettingsState, initialIppAASAuthoritySettingsState } from './aas-authority-settings-state';
import { IppAASAuthoritySettingsAction } from './aas-authority-settings-action-creators';
import EIppAASAuthoritySettingsActionType from './aas-authority-settings-action-types';

export const ippAASAuthoritySettingsReducer = (
	state = initialIppAASAuthoritySettingsState,
	action: IppAASAuthoritySettingsAction
): IppAASAuthoritySettingsState => {
	switch (action.type) {
		case EIppAASAuthoritySettingsActionType.FetchIppAASAuthoritySettingsData:
		case EIppAASAuthoritySettingsActionType.UpdateIppAASAuthoritySettingsData:
		case EIppAASAuthoritySettingsActionType.ToggleSaveButton:
		case EIppAASAuthoritySettingsActionType.SaveIppAASAuthoritySettingsData:
			return {...state, ...action.payload};
		default:
			return state;
	}
};
