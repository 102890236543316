import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
	apiService,
	localizationService,
	localStorageService,
	Resource,
	tokenService,
	urlService,
	validationService
} from 'src/services';
import { alertService } from 'src/redux/alert';
import { Button } from 'reactstrap';
import { TextInput } from '../../../widgets';
import { useHistory, useParams } from 'react-router';
import { PortalRegistration, LocalStorageName, PortalConfirmation, UrlAction, Portal } from '@rcp/types';
import { loadUserProfile, useRootStateSelector } from 'src/redux';
import IppAccountErrorComponent from 'src/components/account/error';
import { ConfirmPortalInvite } from './confirm-invitation';
import { useDispatch } from 'react-redux';
import { Translate } from 'src/components/widgets/translate/translator';

interface FormField {
	inviteCode: string;
	inviteCodeError: string;
	userName?: string;
}

const initialFormFields: FormField = {
	inviteCode: '',
	inviteCodeError: '',
	userName: ''
};

const InputInvitationCode = () => {
	const [formState, setFormState] = useState(initialFormFields);
	const [showInviteCodeInput, setShowInviteCodeInput] = useState(true);
	const [showInvalidInvitationMessage, setShowInvalidInvitationMessage] = useState(false);
	const [portalConfirmationDetail, setPortalConfirmationDetail] = useState<PortalConfirmation>(
		{} as PortalConfirmation
	);
	const history = useHistory();
	const userProfile = useRootStateSelector(s => s.userProfile);
	const paths = history.location.pathname.split('/');
	const [portalName, setPortalName] = useState<string>(paths && paths.length > 2 ? paths[1] : '');

	const isFacilityPortal = () => {
		if (urlService.isAccountInvitationPage()) {
			return portalConfirmationDetail.haulerId == undefined;
		} else {
			return String.equalCaseInsensitive(portalName, Portal.facility);
		}
	};

	const dispatch = useDispatch();

	React.useEffect(() => {
		document.body.className = 'signinBody';
		return () => {
			document.body.className = '';
		};
	}, []);

	useEffect(() => {
		const authenticationToken = tokenService.isTokenValid();
		if (authenticationToken && Object.keys(userProfile.userProfile).length === 0) {
			dispatch(loadUserProfile());
		}
	}, [tokenService.getTokenOrDefault().accessToken]);

	useEffect(() => {
		let registerUrl = urlService.getApiBaseUrl();
		const url = history.location.pathname;
		const paths = url.split('/');
		const token = paths[2].toLowerCase();
		const invalidTokenString = localizationService.getLocalizedString('facilityPortal.invalidTokenString');
		if (
			urlService.isAccountPortal() &&
			token &&
			token != invalidTokenString &&
			Object.keys(userProfile.userProfile).length === 0
		) {
			registerUrl = `${registerUrl}/${Resource.TempUsers}/${token}`;
			apiService
				.getResource<PortalRegistration>(registerUrl)
				.then((data: PortalRegistration) => {
					const { firstName } = data;
					setFormState({
						...formState,
						userName: firstName || ''
					});
				})
				.catch(err => {
					if (
						err.message &&
						err.message.includes(localizationService.getLocalizedString('haulers.errors.incorrectToken'))
					) {
						setShowInvalidInvitationMessage(true);
						alertService.clearAllMessages();
					}
				});
		} else {
			setFormState({
				...formState,
				userName: userProfile.userProfile.firstName || ''
			});
		}
	}, [userProfile]);

	const removeTokenInLocalStorage = () => {
		let localStorageKey = '';
		if (!isFacilityPortal()) {
			localStorageKey = LocalStorageName.HaulerPortalToken;
		} else {
			localStorageKey = LocalStorageName.FacilityPortalToken;
		}
		localStorageService.removeLocalStorage(localStorageKey);
	};

	const getSignInUrlAction = () => {
		return !isFacilityPortal() ? UrlAction.HaulerRegistration : UrlAction.FacilityRegistration;
	};

	// Save Modal
	const handlerInviteCodeSubmit = () => {
		if (isFormValidateForInvite()) {
			let invitationUrl = urlService.getApiBaseUrl();
			invitationUrl = `${invitationUrl}/${Resource.Invitation}/${formState.inviteCode}`;
			apiService.isAnonymous = true;
			apiService
				.getResource<PortalConfirmation>(invitationUrl)
				.then((data: PortalConfirmation) => {
					setPortalConfirmationDetail(data);
					if (urlService.isAccountInvitationPage()) {
						let updatedPortalName = data.haulerId == undefined ? Portal.facility : Portal.hauler;
						setPortalName(updatedPortalName);
					}
					setShowInviteCodeInput(false);
				})
				.catch(err => {
					apiService.isAnonymous = false;
					if (err && typeof err.message == 'string') alertService.addError(err.message);
				});
		}
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);
		setFormState(newState);
	};

	const isFormValidateForInvite = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(newState, 'inviteCode', 'inviteCodeError');

		setFormState(newState);
		const isFromValid = !validationService.hasError(newState, 'inviteCodeError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const renterInviteCode = () => {
		setFormState({
			...formState,
			inviteCode: '',
			inviteCodeError: ''
		});
		setShowInviteCodeInput(true);
	};

	const confirmRegistrationAndRedirectToLogin = () => {
		const url = history.location.pathname;
		const paths = url.split('/');
		const token = paths[2];
		const { inviteCode } = formState;
		let dataToPost = {};
		if (Object.keys(userProfile.userProfile).length !== 0) {
			dataToPost = {
				userProfileId: userProfile.userProfile.userProfileId,
				inviteCode
			};
		} else {
			dataToPost = {
				token: token,
				inviteCode
			};
		}
		let registerPortalOrganizationUrl = `${urlService.getApiBaseUrl()}/${_.toLower(portalName)}/${
			Resource.Register
		}`;
		apiService.isAnonymous = true;
		apiService
			.postResourceObject<any>(registerPortalOrganizationUrl, dataToPost)
			.then(() => {
				tokenService.clearToken();
				localStorageService.removeLocalStorage('allowedPortals');
				window.location.href = urlService.getLoginUrl(
					`action=${UrlAction.InviteCodeConfirmed}&portal=${portalName}`
				);
				removeTokenInLocalStorage();
				apiService.isAnonymous = false;
			})
			.catch(err => {
				apiService.isAnonymous = false;
				if (err && err.message) alertService.addError(err.message);
			});
	};

	const confirmInvitation = (
		<ConfirmPortalInvite
			portalConfirmationDetail={portalConfirmationDetail}
			isFacilityPortal={isFacilityPortal()}
			onConfirmClick={confirmRegistrationAndRedirectToLogin}
			reEnterInviteCode={renterInviteCode}
		/>
	);

	const invitationCode = (
		<>
			{urlService.isAccountInvitationPage() ? (
				<>
					<h1 className="text-left">
						<Translate>{localizationService.getLocalizedString('screen.labels.inviteCode')}</Translate>
					</h1>

					<p>
						<Translate>
							{localizationService.getLocalizedString('authentication.enterInviteCode')}
						</Translate>
					</p>
				</>
			) : (
				<>
					<h1 className="text-center">
						<Translate>{localizationService.getLocalizedString('haulerPortal.welcomeBack')}</Translate>{' '}
						{formState.userName}
					</h1>

					<p>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.welcomeBackDescription')}
						</Translate>
					</p>
				</>
			)}

			<TextInput
				id="invite-code"
				name="inviteCode"
				label={localizationService.getLocalizedString('haulerPortal.inviteCode')}
				onChange={changeFormState}
				value={formState.inviteCode}
				error={formState.inviteCodeError}
				className={urlService.isAccountInvitationPage() ? 'no-associated-organizations' : undefined}
				isRequired={true}
			/>
			<div className="d-flex justify-content-end align-items-center w-100 mt-2">
				<Button className="ai-action" onClick={() => handlerInviteCodeSubmit()}>
					<Translate>{localizationService.getLocalizedString('screen.buttons.submit')}</Translate>
				</Button>
			</div>
		</>
	);

	return (
		<div className="register-account">
			{showInvalidInvitationMessage ? (
				<IppAccountErrorComponent
					errorTitle={localizationService.getLocalizedString('haulers.errors.incorrectTokenTitle')}
					errorDescription={localizationService.getLocalizedString('haulers.errors.incorrectToken')}
					containerClass="signin"
					showSignInButton={true}
					signInUrlAction={`${getSignInUrlAction()}`}
				/>
			) : (
				<>{showInviteCodeInput ? invitationCode : confirmInvitation}</>
			)}
		</div>
	);
};
export default InputInvitationCode;
