import { IppIndustry, IppReportPackageParameterGroup, IppReportPackageTemplate } from '@rcp/types';

export interface IndustriesState {
	industryDetails: IppIndustry;
	templates: IppReportPackageTemplate[];
	parameterGroups: IppReportPackageParameterGroup[];
}
export const initialIndustriesState: IndustriesState = {
	industryDetails: {},
	templates: [],
	parameterGroups: []
};
