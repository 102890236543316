import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { AuthorityTag, DropDownOption, TagItem } from '@rcp/types';
import { localizationService } from 'src/services';
import { TagDropdown } from './tag-dropdown';
import { CloseSvg } from '../../components/svg';
import './tags.scss';
import * as LinkoTypes from '@rcp/types';

interface TagsProps {
	authorityTags: AuthorityTag[];
	tagNames: string | undefined;
	saveTags: (tags: string) => void;
	tagType: string;
}

const TagsComp: React.SFC<TagsProps> = props => {
	const [isToggle, setIsToggle] = useState(false);
	const [authorityTags, setAuthorityTags] = React.useState([] as DropDownOption[]);
	const [tagList, setTagList] = React.useState([] as string[]);

	React.useEffect(() => {
		let tags: string[] = props.tagNames ? props.tagNames.split(',') : [];
		setTagList(tags);
	}, [props.tagNames]);

	React.useEffect(() => {
		let dropDownOptions: DropDownOption[] = [];
		if (!_.isEmpty(props.authorityTags)) {
			let allCccTags = _.filter(
				props.authorityTags,
				tag => _.toLower(tag.regulatoryProgramName) === _.toLower(LinkoTypes.RegulatoryProgramName.Backflow)
			);
			if (allCccTags && allCccTags.length > 0) {
				let cccTags = allCccTags[0];
				// @ts-ignore
				let items = cccTags.tags[props.tagType] as TagItem[];
				if (items) {
					dropDownOptions = _.filter(items, i => i.isActive === true).map(a => {
						return {
							label: a.name,
							value: a.name
						};
					});
				}
			}
		}
		setAuthorityTags(dropDownOptions);
	}, [props.authorityTags, props.tagType]);

	const onAddClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsToggle(true);
		}
	};
	const toggle = () => {
		setIsToggle(!isToggle);
	};
	const onEditClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsToggle(true);
		}
	};

	const saveTags = () => {
		if (props.saveTags) {
			props.saveTags(tagList.join(','));
		}
		toggle();
	};

	const cancelEditingTags = () => {
		let tags: string[] = props.tagNames ? props.tagNames.split(',') : [];
		setTagList(tags);
		toggle();
	};

	const removeExistingTag = (tag: string) => {
		let tags: string[] = [...tagList];
		let filteredTags = tags.filter(t => t !== tag);
		setTagList(filteredTags);
	};

	const addNewTag = (newTag: string) => {
		let tags: string[] = [...tagList];
		tags.push(newTag);
		setTagList(tags);
	};

	const hasTags = (): boolean => tagList.length > 0;
	const tagOptions = _.filter(authorityTags, authorityTag => !_.includes(tagList, authorityTag.value));

	return (
		<section>
			<div id="facilitiesTags" className="d-flex">
				<h3>{localizationService.getLocalizedString('screen.labels.tags')}</h3>
				{hasTags() && !isToggle && (
					<a href="#/" className="font-size-16px ai-edit-link ml-auto mt-n1" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				)}
			</div>
			{isToggle ? (
				<div>
					<div className={`ai-tags ${hasTags() ? ' py-3' : null}`}>
						{tagList.map((tag: string, index: number) => {
							return (
								<div className="ai-tag align-items-center" key={'ai-tag-' + index}>
									<FontAwesomeIcon icon={faTag} className="tag-icon" />
									<span className="mx-1">{tag}</span>
									<span className="close-icon" onClick={(e: any) => removeExistingTag(tag)}>
										<CloseSvg size="8" />
									</span>
								</div>
							);
						})}
					</div>
					{tagOptions.length > 0 ? (
						<TagDropdown
							name="newTag"
							label="Add existing tags"
							className="w-100 mb-3"
							onChange={addNewTag}
							options={tagOptions}
						/>
					) : (
						//</div>
						<div className="mb-3">
							{localizationService.getLocalizedString('screen.labels.allTagsAdded')}
						</div>
					)}
					<div className="d-flex justify-content-end">
						<Button color="btn ai-action" onClick={saveTags}>
							{localizationService.getLocalizedString('screen.buttons.apply')}
						</Button>
						<Button className="ml-2" color="btn ai-white" onClick={cancelEditingTags}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</Button>
					</div>
				</div>
			) : (
				<div>
					<div className={`ai-tags ${hasTags() ? ' py-3' : null}`}>
						{tagList.map((tag: string, index: number) => {
							return (
								<div className="ai-tag" key={'ai-tag-' + index}>
									<FontAwesomeIcon icon={faTag} className="tag-icon" />
									<span className="mx-1">{tag}</span>
								</div>
							);
						})}
					</div>
					{!hasTags() && (
						<a href="#/" className="font-size-16px ai-add-link mr-auto" onClick={onAddClicked}>
							{localizationService.getLocalizedString('screen.buttons.addTag')}&#8230;
						</a>
					)}
				</div>
			)}
		</section>
	);
};

export default TagsComp;
