import React from 'react';
import { useHistory } from 'react-router-dom';
import { AttachmentOwnership, Extractor, FogFacility } from '@rcp/types';
import { localizationService, tokenService, navigateTo } from 'src/services';

import { FacilityAttachmentComments } from './attachment-comments';
import { AttachmentPreview } from './attachment-preview';
import { AttachmentAttributes } from '.';
import _ from 'lodash';

interface AttachmentSlider {
	readOnly: boolean;
	facility: FogFacility;
	device?: Extractor;
	attachments: AttachmentOwnership[];
	currentAttachment: AttachmentOwnership;
	previousAttachment?: AttachmentOwnership;
	nextAttachment?: AttachmentOwnership;
	returnUrl?: string;
	returnLabel?: string;
	getAttachmentUrl: (attachment: AttachmentOwnership) => string;
	getAttachmentId: () => number;
	onAttachmentChange?: (attachment: AttachmentOwnership) => void;
	toggleAttachmentModal?: () => void;
	navigateCallback?: (attachmentId: number) => void;
	noNavigation?: boolean;
}

interface LocationProps {
	editMode?: boolean;
}

type Props = AttachmentSlider & LocationProps;

export const AttachmentContent: React.FC<Props> = props => {
	const initialAttachmentSlider = {
		currentAttachment: props.currentAttachment ?? {}
	} as AttachmentSlider;

	const [attachmentSlider, setAttachmentSlider] = React.useState(initialAttachmentSlider);
	const editMode = props.editMode !== undefined ? props.editMode : false;

	React.useEffect(() => {
		const registerKeyboardEventHandler = () => {
			document.addEventListener('keydown', handleKeyboardEvent, false);
		};
		registerKeyboardEventHandler();
	}, []);

	React.useEffect(() => {
		if (props.attachments.length > 0) {
			setAttachmentSlider(refreshAttachmentSlider(props.getAttachmentId()));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.attachments, props.currentAttachment]);

	React.useEffect(() => {
		const unregisterKeyboardEventHandler = () => {
			document.removeEventListener('keydown', handleKeyboardEvent, false);
		};

		return () => {
			unregisterKeyboardEventHandler();
		};
	}, []);

	const refreshAttachmentSlider = (currentAttachmentId: number) => {
		let newAttachmentSlider = _.clone(initialAttachmentSlider);
		let attachments = props.attachments;
		let index = attachments.findIndex(item => item.attachmentId === currentAttachmentId);
		if (index !== -1) {
			newAttachmentSlider.currentAttachment = attachments[index];
			if (!props.noNavigation) {
				document.title =
					newAttachmentSlider.currentAttachment.fileName +
					localizationService.getLocalizedString('pageTitle.endOfTitle');
			}

			let previousIndex = index - 1;

			if (previousIndex >= 0) {
				newAttachmentSlider.previousAttachment = attachments[previousIndex];
			} else {
				newAttachmentSlider.previousAttachment = undefined;
			}
			let nextIndex = index + 1;
			if (nextIndex < attachments.length) {
				newAttachmentSlider.nextAttachment = attachments[nextIndex];
			} else {
				newAttachmentSlider.nextAttachment = undefined;
			}
		} else {
			navigateTo(history, props.returnUrl);
		}

		if (props.navigateCallback) {
			props.navigateCallback(newAttachmentSlider.currentAttachment.attachmentId || 0);
		}
		return newAttachmentSlider;
	};

	const moveTo = (e: any, attachment?: AttachmentOwnership): void => {
		e.preventDefault();

		if (attachment) {
			if (!props.noNavigation) {
				window.history.pushState({}, document.title, props.getAttachmentUrl(attachment));
			}
			if (props.onAttachmentChange) {
				props.onAttachmentChange(attachment);
			}
			const movedAttachmentSlider = refreshAttachmentSlider(attachment.attachmentId as number);
			setAttachmentSlider(movedAttachmentSlider);
		}
	};

	const handleKeyboardEvent = (e: any) => {
		if (e.code === 'ArrowLeft' && e.ctrlKey === true) {
			let moveToLeft = document.getElementById('moveToLeft');
			if (moveToLeft) {
				moveToLeft.click();
			}
		}
		if (e.code === 'ArrowRight' && e.ctrlKey === true) {
			let moveToRight = document.getElementById('moveToRight');
			if (moveToRight) {
				moveToRight.click();
			}
		}
	};

	const history = useHistory();
	return (
		<div className="page-wrapper">
			<div className="main">
				<section>
					<div className="d-flex flex-row mb-4">
						<div className="mr-auto text-break">{attachmentSlider.currentAttachment.fileName}</div>
						{!props.editMode && (
							<div className="align-self-center">
								<a
									href={attachmentSlider.currentAttachment.s3ResourceUrl}
									target="_blank"
									rel="noopener noreferrer">
									<img alt="import" className="mb-2 mr-1" src="/img/icons/import.svg" />
									{localizationService.getLocalizedString('screen.buttons.download')}
								</a>
							</div>
						)}
					</div>

					<AttachmentPreview attachment={attachmentSlider.currentAttachment} className="m-auto d-block p-1" />

					<div className="attachment-navigation d-flex">
						{props.returnLabel && props.returnUrl ? (
							<div className="ml-auto p-3">
								<a
									href={props.returnUrl}
									onClick={(e: any) =>
										navigateTo(history, props.returnUrl, e, { editMode: editMode })
									}>
									{localizationService.getLocalizedString(`attachments.details.${props.returnLabel}`)}
								</a>
							</div>
						) : props.attachments.length > 1 ? (
							<div className="ml-auto p-3 attachment-navigation-empty"></div>
						) : null}

						{attachmentSlider.previousAttachment && (
							<div
								className="attachment-page-nav cursor-pointer"
								onClick={(e: any) => moveTo(e, attachmentSlider.previousAttachment)}>
								<a id="moveToLeft" href="#/">
									<img alt="previous" src="/img/icons/previous.svg" />
								</a>
							</div>
						)}

						{attachmentSlider.nextAttachment && (
							<div
								className="attachment-page-nav cursor-pointer"
								onClick={(e: any) => moveTo(e, attachmentSlider.nextAttachment)}>
								<a id="moveToRight" href="#/">
									<img alt="next" src="/img/icons/next.svg" />
								</a>
							</div>
						)}
					</div>
				</section>
				{props.readOnly === false &&
					tokenService.isTokenValid() &&
					!attachmentSlider.currentAttachment.creatorOrganizationType && (
						<section>
							<div className="font-size-16px-semibold mb-2">
								{localizationService.getLocalizedString('screen.labels.comments')}
							</div>
							<FacilityAttachmentComments
								facilityId={props.facility.facilityId ?? -1}
								attachment={attachmentSlider.currentAttachment}
							/>
						</section>
					)}
			</div>

			{!props.noNavigation && (
				<div className="sidebar">
					<AttachmentAttributes
						facility={props.facility}
						device={props.device}
						selectedAttachment={attachmentSlider.currentAttachment}
						onEventLinkClick={props.toggleAttachmentModal}
					/>
				</div>
			)}
		</div>
	);
};
