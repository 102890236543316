import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { localizationService } from 'src/services';

interface actionProp {
	clickRecordAViolation: () => void;
	toggleAddEventModal: () => void;
}

export const InspectionActionDropdown: React.SFC<actionProp> = props => {
	return (
		<DropdownMenu>
			<DropdownItem tag="a" id="recordAViolation" onClick={props.clickRecordAViolation}>
				{localizationService.getLocalizedString('screen.buttons.recordViolation')}
			</DropdownItem>
			<DropdownItem tag="a" id="addEvent" onClick={props.toggleAddEventModal}>
				{localizationService.getLocalizedString('events.addEvent')}
			</DropdownItem>
		</DropdownMenu>
	);
};
