import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState, FogFacilityState, AuthorityTagsState } from 'src/redux';
import { TabViewField } from 'src/components/widgets';
import { OperationsModal } from './facility-operations-modal';
import { localizationService } from 'src/services';

interface StateProps extends FogFacilityState {
	authorityTags: AuthorityTagsState;
}

type Props = StateProps;

const OperationsTabComp: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('facility.hoursOfOperation')}
						value={props.facility.hoursOfOperation}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.seatingCapacity')}
						value={props.facility.seatingCapacity}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.squareFootage')}
						value={props.facility.squareFootage}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.numberMealsServed')}
						value={props.facility.numberMealsServed}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.numberOfEmployees')}
						value={props.facility.numberOfEmployees}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" id="edit-facility-operations" onClick={onEditClicked}>
						Edit
					</a>
				</div>
			</div>
			<OperationsModal isToggle={isToggle} toggle={toggle} />
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, authorityTags: state.authorityTags };
};

export const OperationsTab = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(OperationsTabComp);
