import React, { useState } from 'react';
import _ from 'lodash';
import { ClearSvg } from '../svg';
import { Translate } from './translate/translator';

interface SearchInputProps {
	placeholder?: string;
	ariaLabel?: string;
	searchTerm?: string;
	searchClass?: string;
	search(text: string): void;
	onChange?: (e: any) => void;
	inputId?: string;
	placeholderDescription?: string;
}

export const SearchInput: React.SFC<SearchInputProps> = props => {
	const [lastSearchTerm, setLastSearchTerm] = useState(props.searchTerm || '');
	const [inputText, setInputText] = useState(props.searchTerm || '');

	React.useEffect(() => {
		setInputText(props.searchTerm || '');
	}, [props.searchTerm]);

	const handleSearch = (text: string) => {
		if (lastSearchTerm !== text) {
			setLastSearchTerm(text);
			props.search(text);
		}
	};

	const handleKeyPressed = (e: any) => {
		if (e.key === 'Enter') {
			handleSearch(inputText);
		}
	};

	const handleOnBlur = (e: any) => {
		handleSearch(inputText);
	};

	const handleChange = (e: any) => {
		setInputText(e.target.value);
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const clearSearch = (e: any) => {
		setInputText('');
		setLastSearchTerm('');
		props.search('');
	};

	return (
		<form
			className="ai-search"
			onSubmit={e => {
				e.preventDefault();
			}}>
			<input
				id={props.inputId || 'SearchInput'}
				name={props.inputId || 'SearchInput'}
				type="text"
				placeholder={props.placeholder || 'Search'}
				aria-label={props.ariaLabel || 'Search'}
				value={inputText}
				className={props.searchClass ? 'form-control ' + props.searchClass : 'form-control'}
				onKeyPress={(e: any) => handleKeyPressed(e)}
				onBlur={(e: any) => handleOnBlur(e)}
				onChange={(e: any) => handleChange(e)}
				autoComplete="off"
			/>
			{!_.isEmpty(inputText) && (
				<button
					aria-label="Reset"
					className="ai-clear-search"
					type="reset"
					onClick={(e: any) => clearSearch(e)}>
					<ClearSvg />
				</button>
			)}
			{props.placeholderDescription && (
				<div className="ai-form-help">
					<Translate>{props.placeholderDescription}</Translate>
				</div>
			)}
		</form>
	);
};
