import {
	IppCollectionMethod, IppIndustrySample, IppMonitoringPoint, IppProgramSettings, IppReportPackageParameter,
	IppReportPackageParameterGroup, IppSampleFlowUnit, IppSampleType
} from '@rcp/types';
import { IppMassLoadingUnit } from '@rcp/types/src';

export interface SampleState {
	sample: IppIndustrySample;
	monitoringPoints: IppMonitoringPoint[];
	collectionMethods: IppCollectionMethod[];
	sampleTypes: IppSampleType[];
	flowUnits: IppSampleFlowUnit[],
	parameterGroups: IppReportPackageParameterGroup[],
	selectedParameters: IppReportPackageParameter[],
	floatNumbers: any;
	programSettings: IppProgramSettings;
	massLoadingUnit: IppMassLoadingUnit;
	parameters: IppReportPackageParameter[];
}
export const initialSampleState: SampleState = {
	sample: {
		ippSampleResults: []
	},
	monitoringPoints: [],
	collectionMethods: [],
	sampleTypes: [],
	flowUnits: [],
	parameterGroups: [],
	selectedParameters: [],
	floatNumbers: {},
	programSettings: {},
	massLoadingUnit: {},
	parameters: []
};
