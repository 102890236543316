import React from 'react';
import { DropDownOption } from '@rcp/types';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService, validationService } from 'src/services';
import _ from 'lodash';
import { DateInput, SingleSelectDropdown } from '../../components/widgets';
import { filterService } from './filter-service';

export const dateFilterBetweenOptions: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('screen.filterModal.chooseADate'),
		value: 'ChooseADate'
	},
	{
		label: localizationService.getLocalizedString('screen.filterModal.today'),
		value: 'Today'
	}
];

interface FilterDateBetweenProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

export const validateDateFilterBetween = (filterItem: FilterItem) => {
	validationService.validateRequiredField(filterItem.values[0], 'value', 'error');
	validationService.validateRequiredField(filterItem.values[2], 'value', 'error');
	if (_.get(filterItem.values[0], 'value') === dateFilterBetweenOptions[0].value) {
		validationService.validateRequiredDateField(filterItem.values[1], 'value', 'error');
	}
	if (_.get(filterItem.values[2], 'value') === dateFilterBetweenOptions[0].value) {
		validationService.validateRequiredDateField(filterItem.values[3], 'value', 'error');
	}
};

export const FilterDateBetween: React.FC<FilterDateBetweenProps> = props => {
	const onValueChanged = (value: string, index: number) => {
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (newFilterItem.values.length !== 4 || index >= 4) {
			throw new Error(
				`resetFilterItem is incorrect that filterItem.values length must be 4 on date between filter`
			);
		}
		newFilterItem.values[index] = {
			value: value
		};
		if (value === dateFilterBetweenOptions[1].value && (index === 0 || index === 2)) {
			_.unset(newFilterItem.values[index + 1], 'value');
		}
		props.updateFilter(newFilterItem);
	};

	let operatorValue0 = filterService.getFilterValue(props.filterItem, 0);
	let operatorValue1 = filterService.getFilterValue(props.filterItem, 1);
	let operatorValue2 = filterService.getFilterValue(props.filterItem, 2);
	let operatorValue3 = filterService.getFilterValue(props.filterItem, 3);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<>
			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenValue0_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenValue0_${props.rowNumber}`}
					name={`DateBetweenValue0_${props.rowNumber}`}
					className={`custom-select ${operatorValue0.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 0);
					}}
					value={operatorValue0.value}
					options={dateFilterBetweenOptions}
					noEmptyOption={true}
					withoutWrappingDiv={true}
				/>
				{operatorValue0.error && <div className="invalid-feedback">{operatorValue0.error}</div>}
			</div>
			{operatorValue0.value !== dateFilterBetweenOptions[1].value && (
				<div className={inputGroupCss}>
					<label htmlFor={`DateBetweenValue1_${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.date')}
					</label>
					<DateInput
						id={`DateBetweenValue1_${props.rowNumber}`}
						name={`DateBetweenValue1_${props.rowNumber}`}
						className={` ${operatorValue1.error ? ' is-invalid' : ' '}`}
						error={operatorValue1.error}
						value={operatorValue1.value}
						onChange={(e: any) => {
							onValueChanged(e.target.value, 1);
						}}
						withoutWrappingDiv={true}
					/>
					{operatorValue1.error && <div className="invalid-feedback error-block">{operatorValue1.error}</div>}
				</div>
			)}

			<div className="form-inline">
				<label htmlFor="and" />
				<div className="px-1" id="and">
					{localizationService.getLocalizedString('screen.filterModal.and')}
				</div>
			</div>

			<div className={inputGroupCss}>
				<label htmlFor={`DateBetweenValue2_${props.rowNumber}`}>
					{localizationService.getLocalizedString('screen.filterModal.value')}
				</label>
				<SingleSelectDropdown
					id={`DateBetweenValue2_${props.rowNumber}`}
					name={`DateBetweenValue2_${props.rowNumber}`}
					className={`custom-select ${operatorValue2.error ? ' is-invalid' : ' '}`}
					onChange={(e: any) => {
						onValueChanged(e.target.value, 2);
					}}
					value={operatorValue2.value}
					options={dateFilterBetweenOptions}
					noEmptyOption={true}
					withoutWrappingDiv={true}
				/>
				{operatorValue2.error && <div className="invalid-feedback">{operatorValue2.error}</div>}
			</div>
			{operatorValue2.value !== dateFilterBetweenOptions[1].value && (
				<div className={inputGroupCss}>
					<label htmlFor={`DateBetweenValue3_${props.rowNumber}`}>
						{localizationService.getLocalizedString('screen.filterModal.date')}
					</label>
					<DateInput
						id={`DateBetweenValue3_${props.rowNumber}`}
						name={`DateBetweenValue3_${props.rowNumber}`}
						className={` ${operatorValue3.error ? ' is-invalid' : ' '}`}
						error={operatorValue3.error}
						value={operatorValue3.value}
						onChange={(e: any) => {
							onValueChanged(e.target.value, 3);
						}}
						withoutWrappingDiv={true}
					/>
					{operatorValue3.error && <div className="invalid-feedback error-block">{operatorValue3.error}</div>}
				</div>
			)}
		</>
	);
};
