import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { MergeFieldDto } from '@rcp/types';
import { DropDownOption } from '@rcp/types/src';
import React from 'react';
import { useRootStateSelector } from 'src/redux';
import { localizationService } from 'src/services';
import { ComboBoxDropdown } from './combo-box-dropdown';

interface Props {
	id: string;
	label: string;
	name: string;
	onChange: (e: DropDownListChangeEvent) => void;
	hintText?: string;
	className?: string;
	customMergeFields?: MergeFieldDto[];
}

export const MergeFieldDropDown: React.FC<Props> = props => {
	const mergeFields: MergeFieldDto[] = useRootStateSelector(state => state.mergeFields.result);

	const mergeOptions = (props.customMergeFields || mergeFields).map(field => {
		let option: DropDownOption = {
			label: field.label as string,
			value: `${field.prefix} - ${field.label}`,
			prefix: field.prefix
		};
		return option;
	});

	//Translation link should not be included in the subject
	const filteredMergeOptions = mergeOptions.filter(
		i => !String.equalCaseInsensitive(i.prefix, localizationService.getLocalizedString('languages.translationLink'))
	);

	return (
		<ComboBoxDropdown
			id={props.id}
			value={''}
			name={props.name}
			label={props.label}
			options={filteredMergeOptions}
			onChange={props.onChange}
			className={props.className}
			isCustomSorting={true}
			clearButton={false}
			hintText={props.hintText}
			placeholder={localizationService.getLocalizedString('notices.searchMergeTags')}
		/>
	);
};
