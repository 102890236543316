import React, { useState } from 'react';
import { Action } from 'redux';
import { FacilityEntityContact } from '@rcp/types';
import { FacilityContactListState, ApplicationState } from 'src/redux';
import { localizationService } from 'src/services';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import _ from 'lodash';
import { ContactModal } from 'src/features/contacts/contact-modal';

interface OwnProps {
	facilityContact: FacilityEntityContact;
}

interface StateProps extends FacilityContactListState {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const ContactEditActionButtonComponent: React.SFC<Props> = props => {
	const [isToggle, setToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setToggle(true);
	};

	const toggle = () => {
		setToggle(!isToggle);
	};

	return (
		<>
			<input type="hidden" name="facilityContactId" value={props.facilityContact.entityContactId} />
			<input type="hidden" name="contactId" value={props.facilityContact.contactId} />
			<a data-toggle="dropdown" href="#/" role="button" aria-haspopup="true" aria-expanded="false">
				{props.facilityContact.isBusiness ? (
					<strong className="text-break">{props.facilityContact.companyName}</strong>
				) : (
					<strong className="text-break">
						{props.facilityContact.firstName}{' '}
						{_.isEmpty(props.facilityContact.lastName) ? '' : ' ' + props.facilityContact.lastName}
					</strong>
				)}
			</a>
			{_.has(props.facilityContact, 'isPrimary') && props.facilityContact.isPrimary ? (
				<span className="text-muted ml-1"> Primary </span>
			) : null}

			<div className="dropdown-menu">
				<button className="dropdown-item font-size-16px" onClick={onEditClicked}>
					{localizationService.getLocalizedString('screen.buttons.edit')}
				</button>
			</div>

			{isToggle && <ContactModal entityContact={props.facilityContact} close={toggle} />}
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityContacts };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {};
};

export const ContactEditActionButton = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(ContactEditActionButtonComponent);
