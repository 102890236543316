import { ApiEndpoints, AutoNumberDomainType, ServiceStatus, CccHazard, DropDownOption, LookupType } from '@rcp/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopoverModal, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components/widgets';
import { alertService, RootState } from 'src/redux';
import { authoritySettingService, localizationService, optionsMap, validationService } from 'src/services';

import { RestApi } from 'src/services/rest.api';
import { cccLookupHazardCategorySlice, cccLookupHazardServiceStatusSlice } from '../settings/hazard-lookups.slice';
interface OwnProps {
	isToggle: boolean;
	toggle: (e: any) => void;
	currentHazard: CccHazard;
	siteId: number;
	fetchHazards: () => void;
}

interface DetailsForm {
	hazardNumber?: string;
	accountNumber?: string;
	serviceNumber?: string;
	serviceStatusId?: number;
	categoryId?: number;
	meterNumber?: string;
	meterLocation?: string;
	hazardNumberError?: string;
}

const initialDetailsForm: DetailsForm = {
	hazardNumber: '',
	accountNumber: '',
	serviceNumber: '',
	meterNumber: '',
	meterLocation: ''
};

type Props = OwnProps;

export const HazardDetailsModal: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [formState, setFormState] = React.useState(initialDetailsForm);
	const [serviceStatusValues, setServiceStatusValues] = React.useState([] as DropDownOption[]);
	const [categoryValues, setCategoryValues] = React.useState([] as DropDownOption[]);
	const [activeStatus, setActiveStatus] = React.useState('' as string | undefined);
	const [hasCurrentHazard, setHasCurrentHazard] = React.useState(false);
	const restApi = new RestApi<any>(ApiEndpoints.CccHazards);

	React.useEffect(() => {
		dispatch(cccLookupHazardCategorySlice.fetchAll(`lookupType=${LookupType.CccHazardCategory}`));
		dispatch(cccLookupHazardServiceStatusSlice.fetchAll(`lookupType=${LookupType.CccHazardServiceStatus}`));
	}, [dispatch]);

	React.useEffect(() => {
		let hasCurrentHazard = !_.isEmpty(props.currentHazard);
		setHasCurrentHazard(hasCurrentHazard);

		if (hasCurrentHazard) {
			setFormStateFromProps(props.currentHazard);
		}
		if (!hasCurrentHazard) {
			authoritySettingService.getNextAutoNumber(AutoNumberDomainType.CccHazard).then((nextHazardNumber: any) => {
				let newState = { ...initialDetailsForm };
				newState.hazardNumber = nextHazardNumber as string;
				setFormState(newState);
			});
		}
	}, [props.currentHazard]);

	let { result: hazardCategories } = useSelector((state: RootState) => state.settingLookupHazardCategory);
	let { result: serviceStatus } = useSelector((state: RootState) => state.settingLookupHazardServiceStatus);

	useEffect(() => {
		if (!_.isEmpty(hazardCategories)) {
			setCategoryValues(optionsMap.fromLookups(hazardCategories));
		}
	}, [hazardCategories]);

	useEffect(() => {
		if (!_.isEmpty(serviceStatus)) {
			setServiceStatusValues(optionsMap.fromLookups(serviceStatus));
			if (!hasCurrentHazard) {
				let active = serviceStatus.find(
					a =>
						(a.isActive as boolean) &&
						String.equalCaseInsensitive((a.code as string).toLowerCase(), ServiceStatus.Active)
				);
				if (active) {
					setActiveStatus(active.code);
				}
			}
		}
	}, [serviceStatus, hasCurrentHazard]);

	const setFormStateFromProps = (hazard: CccHazard) => {
		let newState = {
			hazardNumber: hazard.hazardNumber || '',
			accountNumber: hazard.accountNumber || '',
			serviceNumber: hazard.serviceNumber || '',
			meterNumber: hazard.meterNumber || '',
			meterLocation: hazard.meterLocation || '',
			serviceStatusId: hazard.serviceStatusId,
			categoryId: hazard.categoryId
		};

		setFormState(newState);
	};
	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const addHazard = (e: any) => {
		if (!validateOverviewDataForSave()) {
			return;
		}
		let hazardToSave: CccHazard = {
			hazardNumber: formState.hazardNumber,
			accountNumber: formState.accountNumber,
			serviceNumber: formState.serviceNumber,
			meterNumber: formState.meterNumber,
			meterLocation: formState.meterLocation,
			serviceStatusId: formState.serviceStatusId,
			categoryId: formState.categoryId,
			siteId: props.siteId
		};
		restApi.createOne(hazardToSave).then(
			res => {
				props.fetchHazards();
				window.setTimeout(function() {
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.addSuccess',
							localizationService.getLocalizedString('cccHazard.hazard')
						)
					);
				}, 500);
				props.toggle(e);
			},
			err => {
				alertService.addError(err.body.internalMessage);
			}
		);
	};

	const editHazard = () => {};
	const deleteHazard = () => {};

	const validateOverviewDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;
		validationService.validateRequiredField(
			newState,
			'hazardNumber',
			'hazardNumberError',
			localizationService.getLocalizedString('cccHazard.hazardNumber')
		);

		isFormValid = !validationService.hasError(newState, 'hazardNumberError');

		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const editHazardFooter = () => {
		return (
			<>
				<button className="btn ai-secondary-delete ml-auto" onClick={deleteHazard}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
				<div className="ml-auto">
					<button className="btn ai-save" onClick={editHazard}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={props.toggle}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div className="w-100" id="hazardDetailsModal">
			<PopoverModal
				showModal={props.isToggle}
				title={
					hasCurrentHazard
						? localizationService.getLocalizedString('screen.buttons.editHazard')
						: localizationService.getLocalizedString('screen.buttons.addHazard')
				}
				save={addHazard}
				cancel={props.toggle}
				footer={hasCurrentHazard ? editHazardFooter() : undefined}>
				<TextInput
					id="hazardNumber"
					name="hazardNumber"
					label={localizationService.getLocalizedString('cccHazard.hazardNumber')}
					value={formState.hazardNumber}
					onChange={changeFormState}
					isRequired
					error={formState.hazardNumberError}
				/>

				<TextInput
					id="accountNumber"
					name="accountNumber"
					label={localizationService.getLocalizedString('cccHazard.accountNumber')}
					value={formState.accountNumber}
					onChange={changeFormState}
				/>

				<TextInput
					id="serviceNumber"
					name="serviceNumber"
					label={localizationService.getLocalizedString('cccHazard.serviceNumber')}
					value={formState.serviceNumber}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="serviceStatusId"
					name="serviceStatusId"
					label={localizationService.getLocalizedString('cccHazard.serviceStatus')}
					value={
						!hasCurrentHazard && !formState.serviceStatusId
							? activeStatus
							: _.toString(formState.serviceStatusId)
					}
					onChange={changeFormState}
					options={serviceStatusValues}
				/>

				<SingleSelectDropdown
					id="categoryId"
					name="categoryId"
					label={localizationService.getLocalizedString('cccHazard.category')}
					value={_.toString(formState.categoryId)}
					onChange={changeFormState}
					options={categoryValues}
				/>

				<TextInput
					id="meterNumber"
					name="meterNumber"
					label={localizationService.getLocalizedString('cccHazard.meterNumber')}
					value={formState.meterNumber}
					onChange={changeFormState}
				/>

				<TextAreaInput
					id="meterLocation"
					name="meterLocation"
					label={localizationService.getLocalizedString('cccHazard.meterLocation')}
					value={formState.meterLocation}
					onChange={changeFormState}
					isFullWidth={true}
				/>
			</PopoverModal>
		</div>
	);
};
