import { LookupRow } from '../lookup-types';
import { LookupType, EquipmentType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class EquipmentTypeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: EquipmentType): LookupRow => {
		return {
			lookupId: apiData.equipmentTypeId,
			code: apiData.equipmentType,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let equipmentType = {
			equipmentGroupId: rowToUpdate.lookupId,
			equipmentType: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as EquipmentType;
		return equipmentType;
	}

	toLookupRowsFromAPI(apiData: EquipmentType[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): EquipmentType {
		let equipmentType = {
			equipmentType: newValue.code,
			isActive: newValue.isActive
		} as EquipmentType;
		return equipmentType;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
