import React from 'react';
import { localizationService } from 'src/services';

interface Props {
	title?: string;
	size?: string;
}

export const VerifiedSvg: React.SFC<Props> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ? props.size : '14'}
			height={props.size ? props.size : '20'}
			viewBox="0 0 14 13">
			<title>{props.title || localizationService.getLocalizedString('extractor.verified')}</title>
			<path
				d="M15,8,13.447,6.273l.216-2.284-2.3-.508L10.164,1.5,8,2.4,5.836,1.5l-1.2,1.975-2.3.5.216,2.29L1,8,2.553,9.727l-.216,2.29,2.3.508,1.2,1.975L8,13.59l2.164.9,1.2-1.975,2.3-.508-.216-2.284ZM6.785,10.922,4.366,8.563l.942-.916L6.785,9.09l3.723-3.634.942.916Z"
				transform="translate(-1 -1.5)"
				fill="#666"
			/>
		</svg>
	);
};
