import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomFieldType, CustomFormFieldType, CustomCleaningFields, CustomFormType } from '@rcp/types';
import React, { FC, useEffect, useState, createContext } from 'react';
import { DragDropBox } from 'src/components/widgets/drag-drop-box/drag-drop-box';
import { processListBoxDragAndDrop, ListBoxDragEvent } from '@progress/kendo-react-listbox';
import { CloseSvg, SingleCheckbox, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components';
import { localizationService, urlService } from 'src/services';
import _ from 'lodash';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import moment from 'moment';

interface Props {
	handleClose: () => void;
	dataItem: any;
	formType: string;
	handleChangeProperties?: (dataItem: CustomFieldType) => void;
}

export const DragDropCustomSelectContext = createContext<any>({
	handleDeleteCustomItem: (event: any, index: number) => {},
	handleAddCustomItem: (event: any, index: number) => {},
	customItems: [],
	handleOnChangeCustomOption: (event: any, index: number) => {}
});

const { Date, Lookup, CustomSelect, Checkbox } = CustomFormFieldType;
const {
	PercentGreaseCalculator,
	InNeedOfRepair,
	Attachments,
	Certification,
	CompleteDate,
	VolumeOfWasteRemoved,
	FOG,
	Solids
} = CustomCleaningFields;
const { AuthorityCleaningForm } = CustomFormType;

const CustomOptionsRenderer = (props: any) => {
	const [draggable, setDraggable] = useState(false);
	let { dataItem, className, ...others } = props;
	let { inputs } = dataItem;

	const { handleDeleteCustomItem, customItems, handleOnChangeCustomOption } = React.useContext(
		DragDropCustomSelectContext
	);

	return (
		<div className={className} {...others} draggable={draggable}>
			<div className="row action-icons-wrap">
				<TextInput
					label={localizationService.getLocalizedString('customForm.optionLabel')}
					className="form-group col-9"
					id="option"
					onChange={e => handleOnChangeCustomOption(e, dataItem.position || 0)}
					value={dataItem.name}
					name="option"
				/>
				<span className="col-3 align-self-center action-icons">
					<FontAwesomeIcon
						icon={faGripVertical}
						onMouseDown={() => setDraggable(true)}
						onMouseUp={() => setDraggable(false)}
					/>
					<TooltipHover
						id={`custom-delete-${dataItem.position}`}
						title={localizationService.getLocalizedString('screen.buttons.delete')}
						className="d-inline"
						iconClassName="ml-3 cursor-pointer"
						icon={faTrashAlt}
						iconFontClass="font-size-16px-regular"
						isWithoutArrow
						onIconClick={e =>
							handleDeleteCustomItem(
								e,
								customItems.findIndex((customItem: string) => customItem === dataItem.name)
							)
						}
					/>
				</span>
			</div>
		</div>
	);
};

export const EditField: FC<Props> = props => {
	const { inputs, name, displayName, defaultUnit, certStatement, endpoint, converter } = props.dataItem;
	const [customOptions, setCustomOptions] = useState<string[]>([]);
	const [updatedDataItem, setUpdatedDataItem] = useState<CustomFieldType>();
	const [draggedCustomItem, setDraggedCustomItem] = useState('');
	const updatedInputs = updatedDataItem ? updatedDataItem.inputs : undefined;

	useEffect(() => {
		setUpdatedDataItem({ ...props.dataItem });
	}, [props.dataItem]);

	useEffect(() => {
		if (inputs[0].fieldType === CustomSelect) {
			let customOptions = inputs[0].customSelectOptions;
			setCustomOptions(customOptions);
		} else {
			setCustomOptions([]);
		}
	}, [inputs]);

	const handleOnChangeDataItem = (e: any, inputPosition: number = 0, fieldName?: string) => {
		let { name, value } = e.target;
		if (!updatedDataItem) return;
		let newDataItem = _.cloneDeep(updatedDataItem);
		let { inputs } = newDataItem;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		if (newDataItem && fieldName) {
			(newDataItem as any)[fieldName] = value;
		}
		if (newDataItem && newDataItem.name === PercentGreaseCalculator) {
			_.each(inputs, input => {
				(input as any)[name] = value;
			});
		} else if (newDataItem && newDataItem.inputs) {
			newDataItem.inputs[inputPosition] = { ...newDataItem.inputs[inputPosition], [name]: value };
		}
		setUpdatedDataItem(newDataItem);
		newDataItem && props.handleChangeProperties && props.handleChangeProperties(newDataItem);
	};

	const updateParentStateCustomOption = (customSelectOption: string[]) => {
		let updatedInputItem = updatedInputs
			? { ...updatedInputs[0], customSelectOptions: [...customSelectOption] }
			: {};
		setUpdatedDataItem({ ...props.dataItem, inputs: [{ ...updatedInputItem }] });
		props.handleChangeProperties &&
			props.handleChangeProperties({ ...props.dataItem, inputs: [{ ...updatedInputItem }] });
	};

	const addCustomOption = (event: any) => {
		event.preventDefault();
		let newCustomOptions = [...customOptions];
		newCustomOptions.push('');
		setCustomOptions(newCustomOptions);
		updateParentStateCustomOption(newCustomOptions);
	};

	const deleteCustomItem = (event: any, index: number) => {
		event.preventDefault();
		let newCustomOptions = [...customOptions];
		newCustomOptions.splice(index, 1);
		setCustomOptions(newCustomOptions);
		updateParentStateCustomOption(newCustomOptions);
	};

	const handleOnChangeCustomOption = (event: any, index: number) => {
		let newCustomOption = _.cloneDeep(customOptions);
		newCustomOption[index] = event.target.value;
		setCustomOptions(newCustomOption);
		updateParentStateCustomOption(newCustomOption);
	};

	const addAttachment = (event: any) => {
		event.preventDefault();
		let newAttachmentFields = updatedInputs ? [...updatedInputs] : [];
		let sampleAttachmentObject = {
			...newAttachmentFields[0],
			fieldLabel: localizationService.getLocalizedString(
				'customForm.attachmentLabel',
				newAttachmentFields.length + 1 + ''
			),
			fieldId: (newAttachmentFields[0].fieldId || '') + moment.now() + '',
			fieldName: (newAttachmentFields[0].fieldId || '') + moment.now() + '',
			isRequired: false,
			hintText: ''
		};
		newAttachmentFields.push(sampleAttachmentObject);
		setUpdatedDataItem({ ...props.dataItem, inputs: newAttachmentFields });
		props.handleChangeProperties &&
			props.handleChangeProperties({ ...props.dataItem, inputs: newAttachmentFields });
	};

	const removeAttachment = (event: any, index: number) => {
		event.preventDefault();
		let newAttachmentFields = updatedInputs ? [...updatedInputs] : [];
		newAttachmentFields.splice(index, 1);
		setUpdatedDataItem({ ...updatedDataItem, inputs: newAttachmentFields });
		props.handleChangeProperties &&
			props.handleChangeProperties({ ...props.dataItem, inputs: newAttachmentFields });
	};

	const handleDragStart = (e: ListBoxDragEvent) => {
		setDraggedCustomItem(e.dataItem);
	};

	const handleDrop = (e: ListBoxDragEvent) => {
		let newCustomOptions: any = [...customOptions];
		let newDraggedCustomItem: any = _.clone(draggedCustomItem);
		let droppedItem: any = _.clone(e.dataItem);
		newCustomOptions = newCustomOptions.map((customOption: string, index: number) => {
			if (customOption === droppedItem) {
				droppedItem = { name: customOption, position: index };
			}
			if (customOption === newDraggedCustomItem) {
				newDraggedCustomItem = { name: customOption, position: index };
			}
			return { name: customOption, position: index };
		});
		let result = processListBoxDragAndDrop(newCustomOptions || [], [], draggedCustomItem, e.dataItem, 'position');
		newCustomOptions = result.listBoxOneData.map(customOptionResult => customOptionResult.name);
		setCustomOptions(newCustomOptions);
		updateParentStateCustomOption(newCustomOptions);
	};

	const createDragDropContext = (): any => {
		return {
			handleDeleteCustomItem: deleteCustomItem,
			handleAddCustomItem: addCustomOption,
			customItems: customOptions,
			handleOnChangeCustomOption: handleOnChangeCustomOption
		};
	};

	return (
		<div className="edit-field-wrapper position-fixed">
			<h2 className="d-flex">
				<span>{(displayName ? displayName : name) + ' properties'}</span>
				<button
					className="close ml-auto column-editor-close"
					onClick={() => props.handleClose && props.handleClose()}>
					<CloseSvg />
				</button>
			</h2>
			{name !== PercentGreaseCalculator && name !== Attachments && props.formType !== AuthorityCleaningForm && (
				<TextInput
					label={localizationService.getLocalizedString('customForm.labelText')}
					id="fieldLabel"
					name="fieldLabel"
					onChange={handleOnChangeDataItem}
					error={updatedInputs && updatedInputs[0].fieldLabelError}
					value={updatedInputs && updatedInputs[0].fieldLabel}
				/>
			)}
			{name === Certification && (
				<div className="form-group certification-editor">
					<label>{localizationService.getLocalizedString('customForm.textLabel')}</label>
					<RichTextEditor
						editorContent={certStatement}
						onChange={e => handleOnChangeDataItem(e, 0, 'certStatement')}
						height="200px"
						editorIndex={0}
						isFixedHeight
						useBasicTools
					/>
				</div>
			)}
			{inputs[0].fieldType === CustomSelect && (
				<div className="form-group">
					<p>{localizationService.getLocalizedString('customForm.options')}</p>
					{!!customOptions.length && (
						<DragDropCustomSelectContext.Provider value={createDragDropContext()}>
							<DragDropBox
								id="CustomFormOptions"
								title=""
								data={customOptions.map((customOption: string, index: number) => {
									return { name: customOption, position: index };
								})}
								itemRenderer={CustomOptionsRenderer}
								handleDragStart={handleDragStart}
								handleClose={() => {}}
								handleDrop={handleDrop}
								hideDescription
								hideTitle
							/>
						</DragDropCustomSelectContext.Provider>
					)}
					<a href="#/" className="ai-link" onClick={(event: any) => addCustomOption(event)}>
						{localizationService.getLocalizedString('customForm.addOption')}
					</a>
				</div>
			)}
			{inputs[0].fieldType === Lookup && (
				<SingleSelectDropdown
					id="defaultValue"
					label={localizationService.getLocalizedString('customForm.selectedByDefaultLabel')}
					name="defaultValue"
					options={[]}
					onChange={handleOnChangeDataItem}
					converter={inputs[0].converter}
					endpoint={urlService.getAuthorityLookupUrlFromEndPoint(inputs[0].endpoint, props.formType)}
					value={updatedInputs && updatedInputs[0].defaultValue}
					hintText={localizationService.getLocalizedString('customForm.selectedByDefaultHint')}
				/>
			)}
			{props.dataItem.hasOwnProperty('defaultUnit') && (
				<>
					{name !== PercentGreaseCalculator && (
						<SingleSelectDropdown
							id="defaultUnit"
							label={localizationService.getLocalizedString('customForm.unitsLabel')}
							name="defaultUnit"
							onChange={e => handleOnChangeDataItem(e, undefined, e.target.name)}
							value={defaultUnit}
							options={[]}
							endpoint={urlService.getAuthorityLookupUrlFromEndPoint(endpoint, props.formType)}
							converter={converter}
							showEmptyOption={false}
						/>
					)}
					{name !== FOG && name !== Solids && (
						<SingleCheckbox
							id="isPrefilled"
							label={localizationService.getLocalizedString(
								`customForm.prefillDevice${name === VolumeOfWasteRemoved ? 'Capacity' : 'Depth'}`
							)}
							name="isPrefilled"
							checked={updatedInputs && updatedInputs[0].isPrefilled ? true : false}
							onChange={handleOnChangeDataItem}
						/>
					)}
				</>
			)}
			{name === CompleteDate && (
				<SingleCheckbox
					id="isPrefilled"
					label={localizationService.getLocalizedString('customForm.prefillTodaysDateLabel')}
					name="isPrefilled"
					checked={updatedInputs && updatedInputs[0].isPrefilled ? true : false}
					onChange={handleOnChangeDataItem}
				/>
			)}
			{inputs[0].fieldType !== Date && name !== Attachments && (
				<SingleCheckbox
					id="required"
					label={localizationService.getLocalizedString('customForm.requiredLabel')}
					name="isRequired"
					onChange={handleOnChangeDataItem}
					checked={updatedInputs && updatedInputs[0].isRequired ? true : false}
				/>
			)}
			{name !== PercentGreaseCalculator &&
				name !== Attachments &&
				name !== Certification &&
				inputs[0].fieldType !== Checkbox && (
					<TextAreaInput
						id="hintText"
						name="hintText"
						label={localizationService.getLocalizedString('customForm.hintTextLabel')}
						isFullWidth
						onChange={handleOnChangeDataItem}
						value={updatedInputs && updatedInputs[0].hintText}
						helpText={localizationService.getLocalizedString('customForm.addHintTextArea')}
					/>
				)}
			{name === InNeedOfRepair && (
				<>
					<p>{localizationService.getLocalizedString('customForm.inNeedOfRepairInfo')}</p>
					{props.formType !== AuthorityCleaningForm && (
						<TextInput
							label={localizationService.getLocalizedString('customForm.textAreaLabel')}
							id="fieldLabel"
							name="fieldLabel"
							onChange={e => handleOnChangeDataItem(e, 1)}
							value={updatedInputs && updatedInputs.length > 1 ? updatedInputs[1].fieldLabel : ''}
						/>
					)}
					<SingleCheckbox
						id="isTextAreaRequired"
						label={localizationService.getLocalizedString('customForm.textAreaRequired')}
						name="isRequired"
						onChange={e => handleOnChangeDataItem(e, 1)}
						checked={
							updatedInputs && updatedInputs.length > 1 && updatedInputs[1].isRequired ? true : false
						}
					/>
				</>
			)}
			{name === Attachments && (
				<>
					{updatedDataItem &&
						updatedDataItem.inputs &&
						updatedDataItem.inputs.map((attachmentField, index) => {
							return (
								<div className="action-icons-wrap" key={index}>
									<p>
										<strong>{attachmentField.fieldLabel}</strong>
										{updatedDataItem.inputs!.length > 1 && (
											<span className="action-icons">
												<TooltipHover
													id={`custom-delete-attachment-${props.dataItem.position}`}
													title={localizationService.getLocalizedString(
														'screen.buttons.delete'
													)}
													className="float-right"
													iconClassName="ml-3 cursor-pointer"
													icon={faTrashAlt}
													iconFontClass="font-size-16px-regular"
													isWithoutArrow
													onIconClick={event => removeAttachment(event, index)}
												/>
											</span>
										)}
									</p>
									{props.formType !== AuthorityCleaningForm && (
										<TextInput
											label={localizationService.getLocalizedString('customForm.labelText')}
											id={`fieldLabel_${index}`}
											name="fieldLabel"
											onChange={e => handleOnChangeDataItem(e, index)}
											error={attachmentField.fieldLabelError}
											value={attachmentField.fieldLabel}
										/>
									)}
									<SingleCheckbox
										id={`required_${index}`}
										label={localizationService.getLocalizedString('customForm.requiredLabel')}
										name="isRequired"
										onChange={e => handleOnChangeDataItem(e, index)}
										checked={attachmentField.isRequired ? true : false}
									/>
									<TextAreaInput
										id={`hintText_${index}`}
										name="hintText"
										onChange={e => handleOnChangeDataItem(e, index)}
										value={attachmentField.hintText}
										label={localizationService.getLocalizedString('customForm.hintTextLabel')}
										isFullWidth
										helpText={localizationService.getLocalizedString('customForm.addHintTextArea')}
									/>
								</div>
							);
						})}
					<a href="#/" className="ai-link" onClick={(event: any) => addAttachment(event)}>
						{localizationService.getLocalizedString('customForm.addField')}
					</a>
				</>
			)}
		</div>
	);
};
