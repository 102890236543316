import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export interface FacilityContactListState {
	facilityContactList: ApiTypes.FogFacilityContactDto;
	contactTypeList: ApiTypes.ContactType[];
	authorityContactList: ApiTypes.Contact[];
}

export const initialFacilityContactListState: FacilityContactListState = {
	facilityContactList: { fogDeviceContacts: [], fogFacilityContacts: [] },
	contactTypeList: [],
	authorityContactList: []
};

export enum FacilityContactListActionType {
	LoadFacilityContactList = 'loadFacilityContactList',
	RemoveContactFromFacility = 'removeContactFromFacility',
	DeleteContact = 'deleteContact',
	LoadContactTypeList = 'loadContactTypeList',
	LoadAuthorityContactList = 'LoadAuthorityContactList',
	AddContactForFacility = 'addContactForFacility',
	UpdateContactForFacility = 'updateContactForFacility'
}

export interface LoadFacilityContactListAction extends Action {
	type: FacilityContactListActionType.LoadFacilityContactList;
	facilityContactList: ApiTypes.FacilityEntityContact[];
}

export const loadFacilityContactListAction = (
	facilityContactList: ApiTypes.FacilityEntityContact[]
): LoadFacilityContactListAction => ({
	type: FacilityContactListActionType.LoadFacilityContactList,
	facilityContactList: facilityContactList
});

export interface RemoveContactFromFacilityAction extends Action {
	type: FacilityContactListActionType.RemoveContactFromFacility;
}

export const removeContactFromFacilityAction = (
	facilityContactList: ApiTypes.FacilityEntityContact[]
): RemoveContactFromFacilityAction => ({
	type: FacilityContactListActionType.RemoveContactFromFacility
});

export interface DeleteContactAction extends Action {
	type: FacilityContactListActionType.DeleteContact;
}

export const deleteContactAction = (facilityContactList: ApiTypes.FacilityEntityContact[]): DeleteContactAction => ({
	type: FacilityContactListActionType.DeleteContact
});

export interface LoadContactTypeListAction extends Action {
	type: FacilityContactListActionType.LoadContactTypeList;
	contactTypeList: ApiTypes.ContactType[];
}

export const loadContactTypeListAction = (contactTypeList: ApiTypes.ContactType[]): LoadContactTypeListAction => ({
	type: FacilityContactListActionType.LoadContactTypeList,
	contactTypeList: contactTypeList
});

export interface LoadAuthorityContactListAction extends Action {
	type: FacilityContactListActionType.LoadAuthorityContactList;
	authorityContactList: ApiTypes.Contact[];
}

export const loadAuthorityContactListAction = (
	authorityContactList: ApiTypes.Contact[]
): LoadAuthorityContactListAction => ({
	type: FacilityContactListActionType.LoadAuthorityContactList,
	authorityContactList: authorityContactList
});

export interface AddContactForFacilityAction extends Action {
	type: FacilityContactListActionType.AddContactForFacility;
}

export const addContactForFacilityAction = (
	facilityContactList: ApiTypes.FacilityEntityContact[]
): AddContactForFacilityAction => ({
	type: FacilityContactListActionType.AddContactForFacility
});

export interface UpdateContactForFacilityAction extends Action {
	type: FacilityContactListActionType.UpdateContactForFacility;
}

export const updateContactForFacilityAction = (
	facilityContactList: ApiTypes.FacilityEntityContact[]
): UpdateContactForFacilityAction => ({
	type: FacilityContactListActionType.UpdateContactForFacility
});

export type FacilityContactAction =
	| LoadFacilityContactListAction
	| RemoveContactFromFacilityAction
	| DeleteContactAction
	| LoadContactTypeListAction
	| LoadAuthorityContactListAction
	| AddContactForFacilityAction
	| UpdateContactForFacilityAction;
