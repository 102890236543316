import React from 'react';
import { History } from 'history';

import _ from 'lodash';

import { LocalStorageName, Dictionary, ColumnField, RouteProps, FilterObjectName, CleaningEvent } from '@rcp/types';
import { useHistory } from 'react-router';
import { customFieldQueries, DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { RestSlice, RootState } from 'src/redux';
import { cleaningSlice } from 'src/components';
import { CleaningEditActionLink } from './cleaning-link-cell';
import { SelectFacilityAndDeviceModal } from './select-facility-device-modal';
import { nameof } from 'ts-simple-nameof';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const CleaningsGrid: React.FC<Props<any>> = props => {
	const history = useHistory();
	const [queryParameters, setQueryParameters] = React.useState<Dictionary<string>>({});

	const setQueryParametersFromParent = (queryParameters: Dictionary<string>) => {
		setQueryParameters(queryParameters);
	};

	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'screen.labels.cleanings');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'screen.labels.cleanings');
		}
		return localizationService.getLocalizedString('screen.labels.cleanings');
	};

	React.useEffect(() => {
		cleaningSlice.setApiUrlPath(Resource.Cleanings);
	}, []);

	const facilityNameToLink = (props: any) => {
		const pumpoutEvent = props.dataItem as CleaningEvent;
		const facilityPath = urlService.getReactAuthorityResourceUrl(
			Resource.FogFacilities,
			pumpoutEvent.organizationId
		);
		return (
			<td>
				<a href={facilityPath} className="ai-link" onClick={(e: any) => navigateTo(history, facilityPath, e)}>
					{pumpoutEvent.facilityName}
				</a>
			</td>
		);
	};

	const deviceNumberToLink = (props: any) => {
		const pumpOutEvent = props.dataItem as CleaningEvent;
		const devicePath = urlService.getReactAuthorityResourceUrl(Resource.Devices, pumpOutEvent.deviceId);

		if (pumpOutEvent.isDeviceRemoved) {
			return (
				<td>
					{pumpOutEvent.deviceNumber}
					<span className="text-muted">
						{localizationService.getLocalizedString('screen.labels.dropDownItemRemoved')}
					</span>
				</td>
			);
		} else {
			return (
				<td>
					<a href={devicePath} className="ai-link" onClick={(e: any) => navigateTo(history, devicePath, e)}>
						{pumpOutEvent.deviceNumber}
					</a>
				</td>
			);
		}
	};

	const completeDateToLink = (props: any) => {
		const pumpOutEvent = props.dataItem as CleaningEvent;
		return (
			<td>
				<CleaningEditActionLink
					queryParameters={queryParameters}
					dateField={pumpOutEvent.completeDate}
					pumpOutEvent={pumpOutEvent}
				/>
			</td>
		);
	};

	const hasAttachmentLink = (props: any) => {
		const pumpOutEvent = props.dataItem as CleaningEvent;
		return (
			<td>
				<CleaningEditActionLink
					queryParameters={queryParameters}
					dateField={pumpOutEvent.completeDate}
					pumpOutEvent={pumpOutEvent}
					isTriggeredFromHasAttachmentLink={true}
					hasAttachment={pumpOutEvent.hasAttachment}
				/>
			</td>
		);
	};

	const [showAddModal, setAddShowModal] = React.useState(false);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const completeDateCheckForNextNoticeDate = (props: any) => {
		const cleaning = props.dataItem as CleaningEvent;
		return cleaning.completeDate ? (
			<td></td>
		) : (
			<td>{DateUtilService.toDisplayDate(cleaning.nextNoticeDate || '')}</td>
		);
	};

	const completeDateCheckForNextNoticeType = (props: any) => {
		const cleaning = props.dataItem as CleaningEvent;
		return cleaning.completeDate ? <td></td> : <td>{cleaning.nextNoticeTemplateName}</td>;
	};

	const getAllColumns = () => {
		let columns = [
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.facilityName),
				true,
				facilityNameToLink
			),
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.facilityAddress),
				true
			),
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.deviceNumber),
				true,
				deviceNumberToLink
			),
			ColumnField.newDate(
				nameof<CleaningEvent>(f => f.dueDate),
				true
			),
			ColumnField.newDate(
				nameof<CleaningEvent>(f => f.completeDate),
				true,
				completeDateToLink
			),
			ColumnField.newNumber(nameof<CleaningEvent>(f => f.amountPumped)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.amountPumpedUnit)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.haulerName)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.vehicleNumber)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.driverName)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.cleanedBy)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.manifestNumber)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.disposalLocation)),
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.comments),
				true
			),
			ColumnField.newText(nameof<CleaningEvent>(f => f.wasteTypeCode)),
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.extractorType),
				true
			),
			ColumnField.newNumber(nameof<CleaningEvent>(f => f.daysLate)),
			ColumnField.newNumber(nameof<CleaningEvent>(f => f.fog)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.fogUnit)),
			ColumnField.newNumber(nameof<CleaningEvent>(f => f.solid)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.solidUnit)),
			ColumnField.newNumber(nameof<CleaningEvent>(f => f.trapDepth)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.trapDepthUnit)),
			ColumnField.newInteger(nameof<CleaningEvent>(f => f.percentGrease)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.leavingOutlet)),
			ColumnField.newBoolean(nameof<CleaningEvent>(f => f.isRepairNeeded)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.isRepairNeededReason)),
			ColumnField.newBoolean(nameof<CleaningEvent>(f => f.certificationAccepted)),
			ColumnField.newDate(
				nameof<CleaningEvent>(f => f.nextNoticeDate),
				false,
				completeDateCheckForNextNoticeDate
			),
			ColumnField.newText(
				nameof<CleaningEvent>(f => f.nextNoticeTemplateName),
				false,
				completeDateCheckForNextNoticeType
			),
			ColumnField.newDate(nameof<CleaningEvent>(f => f.lastNoticeDate)),
			ColumnField.newText(nameof<CleaningEvent>(f => f.lastNoticeTemplateName)),
			ColumnField.newBoolean(
				nameof<CleaningEvent>(f => f.hasAttachment),
				false,
				hasAttachmentLink
			)
		];
		return columns;
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'pumpOut',
		storageName: LocalStorageName.CleaningGrid,
		gridUrlLocalStorageName: LocalStorageName.CleaningGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CleaningSortOrder,
		hasGridMap: true,
		showActions: true,
		showActionExport: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('pumpOut.searchHint'),
		showEditColumnButton: true,
		showAddButton: props.match.params.importId ? false : true,
		addButtonText: localizationService.getLocalizedString('pumpOut.addCleaning'),
		addModalComponent: (
			<>
				{showAddModal && (
					<SelectFacilityAndDeviceModal
						title={localizationService.getLocalizedString('pumpOut.addCleaning')}
						showModal={showAddModal}
						toggleModal={toggleAddModal}
						queryParameters={queryParameters}
					/>
				)}
			</>
		),
		toggleAddModal: toggleAddModal,

		customFieldQuery: customFieldQueries.Fog,
		defaultSort: [{ field: 'facilityId', dir: 'desc' }],
		allColumns: getAllColumns(),
		defaultColumns: [
			nameof<CleaningEvent>(f => f.dueDate),
			nameof<CleaningEvent>(f => f.completeDate),
			nameof<CleaningEvent>(f => f.deviceNumber),
			nameof<CleaningEvent>(f => f.facilityName),
			nameof<CleaningEvent>(f => f.facilityAddress),
			nameof<CleaningEvent>(f => f.daysLate)
		],
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.CLEANING,
				FilterDomainName.cleaning,
				FilterObjectName.Cleaning,
				LocalStorageName.CleaningGridFilterUrl,
				appliedDomains
			);
		}
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cleaningSlice}
				restState={(state: RootState) => state.cleanings}
				setQueryParametersFromParent={setQueryParametersFromParent}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};
