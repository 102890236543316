import { faCalendarAlt, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListBoxDragEvent, processListBoxDragAndDrop } from '@progress/kendo-react-listbox';
import {
	CustomCleaningFields,
	CustomFieldType,
	CustomForm,
	CustomFormFieldType,
	CustomFormType,
	RouteProps,
	ScreenViewPort
} from '@rcp/types';
import _ from 'lodash';
import React, { createContext, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import NavigationPrompt from 'react-router-navigation-prompt';
import { ReactComponent as SvgCalculator } from 'src/assets/img/calculator-solid.svg';
import { ReactComponent as SvgCheckbox } from 'src/assets/img/check-solid.svg';
import { ReactComponent as SvgCheckboxTextarea } from 'src/assets/img/checkbox-textarea.svg';
import { ReactComponent as SvgNumber } from 'src/assets/img/number-field.svg';
import { ReactComponent as SvgAttachment } from 'src/assets/img/paperclip-solid.svg';
import { ReactComponent as SvgSignature } from 'src/assets/img/signature-solid.svg';
import { ReactComponent as SvgTextarea } from 'src/assets/img/text-area.svg';
import { ReactComponent as SvgText } from 'src/assets/img/text.svg';
import { CustomForm as CustomFormModal } from 'src/components/widgets/custom-form/custom-form';
import { DragDropBox } from 'src/components/widgets/drag-drop-box/drag-drop-box';
import { FloatingButton } from 'src/components/widgets/floating-button/floating-button';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { alertService, RootState } from 'src/redux';
import {
	localizationService,
	localStorageService,
	Logger,
	navigateTo,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { IFrame } from '../../../../../features/iframe/iframe';
import { CustomModal, CustomModalProp } from '../../../../widgets/modal/modal';
import { customFormSlice } from './custom-forms-slice';
import { EditField } from './edit-field';
import { FormBuilderHeader } from './form-builder-header';
import './form-builder.scss';

interface Props extends RouteProps {
	hideHeader?: boolean;
	isPreview?: boolean;
}

interface CustomFormFieldState {
	formElements?: CustomFieldType[];
	usedFormElements?: CustomFieldType[];
	draggedItem?: any;
	customFormName?: string;
}

const {
	TextArea,
	Lookup,
	CustomSelect,
	AttachmentControl,
	Checkbox,
	Signature,
	Number,
	Date,
	Text
} = CustomFormFieldType;
const { PercentGreaseCalculator, InNeedOfRepair, DueDate, CompleteDate } = CustomCleaningFields;

const UnusedFormElement = (prop: any) => {
	let { dataItem, className, ...others } = prop;
	className += ' available-form-element-wrapper cursor-pointer p-2 mb-3';
	const { inputs, name, displayName } = dataItem;
	const type = inputs[0].fieldType;
	let icon = <SvgText />;
	if (name === InNeedOfRepair) {
		icon = <SvgCheckboxTextarea />;
	} else if (name === PercentGreaseCalculator) {
		icon = <SvgCalculator />;
	} else if (type === Number) {
		icon = <SvgNumber />;
	} else if (type === Signature) {
		icon = <SvgSignature />;
	} else if (type === AttachmentControl) {
		icon = <SvgAttachment />;
	} else if (type === TextArea) {
		icon = <SvgTextarea />;
	} else if (type === Checkbox) {
		icon = <SvgCheckbox />;
	} else if (type === Lookup || type === CustomSelect) {
		icon = <FontAwesomeIcon icon={faChevronDown} className="font-awesome-icon select-icon" />;
	} else if (type === Date) {
		icon = <FontAwesomeIcon icon={faCalendarAlt} className="font-awesome-icon select-icon" />;
	}
	return (
		<li className={className} {...others}>
			{icon}
			<span className="element-text pt-1">{displayName ? displayName : name}</span>
		</li>
	);
};

export const DragDropBoxContext = createContext<any>({
	handleDelete: (dataItem: CustomFieldType) => {},
	handlePreview: (dataItem: CustomFieldType) => {},
	handleChangeInputValue: (e: any, dataItem: CustomFieldType) => {},
	isPreviewMode: false,
	formType: ''
});

export const FormBuilder: FC<Props> = props => {
	const [showAddFormElement, setShowAddFormElement] = useState(true);
	const [selectedEditField, setSelectedEditField] = useState<CustomFieldType>();
	const [showLeaveModal, setShowLeaveModal] = useState(false);
	const [state, setState] = React.useState({
		formElements: [],
		usedFormElements: [],
		draggedItem: {},
		customFormName: ''
	} as CustomFormFieldState);
	const [isCleaningFormUpdated, setIsCleaningFormUpdated] = useState(false);
	const [payload, setPayload] = useState<CustomForm>({});
	const [requiredFormItems, setRequiredFormItems] = useState<string[]>([]);
	const [isPreviewMode, setIsPreviewMode] = useState(false);
	const [viewport, setViewportMode] = useState<string>(ScreenViewPort.desktop);
	const [viewportSettingClass, setViewportClass] = useState<string>('');

	const dispatch = useDispatch();
	const history = useHistory();

	const customFormType = window.location.pathname.split('/').pop();

	const { result } = useSelector((state: RootState) => state.customForms);

	const [dataItemsState, setDataItemsState] = useState<CustomFieldType[]>([]);
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [showDiscardModal, setShowDiscardModal] = useState(false);

	useEffect(() => {
		switch (viewport) {
			case ScreenViewPort.mobile:
				setViewportClass('mobile-viewport');
				break;
			case ScreenViewPort.tablet:
				setViewportClass('tablet-viewport');
				break;
		}
	}, [viewport]);

	useEffect(() => {
		if (props.isPreview) {
			setIsPreviewMode(true);
		}
	}, [props.isPreview]);

	useEffect(() => {
		if (isCleaningFormUpdated) {
			setIsInitialRender(false);
			urlService.stopBrowserNavigation(toggleLeaveModal);
		} else {
			!isInitialRender && urlService.startBrowserNavigation();
		}
	}, [isCleaningFormUpdated]);

	useEffect(() => {
		result[0] &&
		state.usedFormElements!.length &&
		JSON.stringify(JSON.parse(result[0].templateJson as string).inputGroups) ===
			JSON.stringify(removeValueErrorFromDataItem())
			? setIsCleaningFormUpdated(false)
			: setIsCleaningFormUpdated(true);
	}, [state, result]);

	useEffect(() => {
		loadCustomForm();
		window.addEventListener('beforeunload', () => {
			localStorageService.removeLocalStorage('customForm');
		});
		window.addEventListener('message', (e: any) => {
			if (e.origin !== urlService.getReactBaseUrl()) {
				return;
			}
			if (_.isEmpty(e.data) || !_.isString(e.data)) {
				return;
			}
			alertService.clearAllMessages();
			try {
				var data = JSON.parse(e.data);
				setState({
					...data
				});
				handleDefaultValue();
			} catch (error) {
				Logger.error('Failed parse json:', e.data, error);
			}
		});

		return () => {
			localStorageService.removeLocalStorage('customForm');
		};
	}, []);

	useEffect(() => {
		if (result.length === 1) {
			setInitialState();
		}
	}, [result]);

	const removeValueErrorFromDataItem = () => {
		let newUsedFormElements = _.cloneDeep(state.usedFormElements);
		_.each(newUsedFormElements, newUsedFormElement => {
			_.each(newUsedFormElement.inputs, formInputs => {
				_.unset(formInputs || [], 'value');
				_.unset(formInputs || [], 'error');
			});
		});
		return newUsedFormElements;
	};

	const toggleMobileView = (viewport: string) => {
		setInitialState();
		setViewportMode(viewport);
		handleDefaultValue();
		const localState = localStorageService.getLocalStorage('customForm');
		document.getElementById('iframe') &&
			document.getElementById('iframe').contentWindow &&
			document.getElementById('iframe').contentWindow.postMessage(localState, urlService.getReactBaseUrl());
	};

	const loadCustomForm = () => {
		let customFormType = window.location.pathname.split('/').pop();
		let url = `customFormType=${customFormType}`;
		let queryDict = urlService.toQueryDictionary();
		_.unset(queryDict, 'sort');
		let queryString = urlService.toQueryString(queryDict);
		if (!_.isEmpty(queryString)) {
			url += `&${queryString}`;
		}
		dispatch(customFormSlice.fetchAll(url));
		setRequiredFormElements(customFormType);
	};

	const setRequiredFormElements = (customFormType?: string) => {
		if (customFormType === CustomFormType.AuthorityCleaningForm) {
			setRequiredFormItems([DueDate, CompleteDate]);
		} else {
			setRequiredFormItems([CompleteDate]);
		}
	};

	const navigateToCustomForms = () => {
		window.onunload = null;
		navigateTo(history, urlService.getReactAuthorityResourcesPath(Resource.CustomFormsSettings));
	};

	const toggleLeaveModal = () => {
		setShowLeaveModal(!showLeaveModal);
		setShowDiscardModal(false);
	};

	const toggleDiscardModal = () => setShowDiscardModal(!showDiscardModal);

	const cleanUpInputFields = () => {
		alertService.clearAllMessages();
		setState({ ...state, usedFormElements: removeValueErrorFromDataItem() });
	};

	const handleDefaultValue = () => {
		const localState = localStorageService.getLocalStorage('customForm');
		let newUsedFormElements = _.clone(JSON.parse(localState).usedFormElements);
		_.each(newUsedFormElements, dataItem => {
			let { inputs } = dataItem;
			if (!inputs) return;
			if (inputs[0].fieldType === Lookup || inputs[0].fieldType === CustomSelect) {
				_.each(inputs, input => {
					input.value = input.defaultValue;
				});
			}
		});
		setState({ ...state, usedFormElements: newUsedFormElements });
	};

	const togglePreview = () => {
		if (validateSelectedFieldLabel()) {
			setIsPreviewMode(!isPreviewMode);
			!isPreviewMode ? handleDefaultValue() : cleanUpInputFields();
			setSelectedEditField(undefined);
			setShowAddFormElement(true);
			setViewportMode(ScreenViewPort.desktop);
		}
	};

	const handleChangeInputValue = (e: any, dataItem: CustomFieldType, fieldValue?: any) => {
		let { name, value } = e.target;
		if (!isPreviewMode) {
			return;
		}
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		if (fieldValue) {
			value = fieldValue;
		}

		let newDataItemInputs = { ...dataItem }.inputs;
		_.each(newDataItemInputs, dataInput => {
			if (
				dataItem.name === InNeedOfRepair &&
				!value &&
				newDataItemInputs &&
				newDataItemInputs[0].fieldId === name
			) {
				dataInput.value = '';
			}
			if (dataInput.fieldId === name) {
				dataInput.value = value;
			}
		});
		let newDataItem = { ...dataItem, inputs: newDataItemInputs };
		let newUsedFormElements = _.cloneDeep(state.usedFormElements);

		newUsedFormElements =
			newUsedFormElements &&
			newUsedFormElements.map(formElement => {
				if (formElement.name === dataItem.name) {
					return newDataItem;
				} else {
					return formElement;
				}
			});
		setState({ ...state, usedFormElements: newUsedFormElements });
	};

	const toggleAddFormElement = () => {
		setShowAddFormElement(!showAddFormElement);
	};

	const handleDragStart = (e: ListBoxDragEvent) => {
		setState({
			...state,
			draggedItem: e.dataItem
		});
	};

	const handleDelete = (dataItem: CustomFieldType) => {
		if (validateSelectedFieldLabel()) {
			let newUsedFormElements = _.cloneDeep(state.usedFormElements);
			newUsedFormElements =
				newUsedFormElements &&
				newUsedFormElements.filter((formElement: any) => {
					return formElement.name !== dataItem.name;
				});
			let newDataItem = dataItemsState.find(newDataItem => newDataItem.name === dataItem.name) || {};
			setState({
				...state,
				formElements: state.formElements ? [newDataItem, ...state.formElements] : [newDataItem],
				usedFormElements: newUsedFormElements
			});
			setSelectedEditField(undefined);
		}
	};

	const handlePreview = (dataItem: CustomFieldType) => {
		validateSelectedFieldLabel() && setSelectedEditField(dataItem);
	};

	const handleDrop = (e: ListBoxDragEvent) => {
		if (
			state.usedFormElements &&
			state.usedFormElements.some(usedFormElement => usedFormElement.name === state.draggedItem.name) &&
			state.formElements &&
			state.formElements.some(formElement => formElement.name === e.dataItem.name)
		) {
			return;
		}
		let result = processListBoxDragAndDrop(
			state.formElements || [],
			state.usedFormElements || [],
			state.draggedItem,
			e.dataItem,
			'name'
		);
		setState({
			...state,
			formElements: result.listBoxOneData,
			usedFormElements: result.listBoxTwoData
		});
	};

	const saveOnLocalStorage = () => {
		localStorageService.setLocalStorage('customForm', JSON.stringify(state));
	};

	const removeAllUsedFormElement = (e: any) => {
		e.preventDefault();
		if (validateSelectedFieldLabel()) {
			let newUsedFormElements = _.cloneDeep(state.usedFormElements);
			let newFormElements = _.cloneDeep(state.formElements);
			newUsedFormElements =
				newUsedFormElements &&
				newUsedFormElements.filter((formElement: CustomFieldType) => {
					if (
						(formElement.name === DueDate &&
							state.customFormName === CustomFormType.AuthorityCleaningForm) ||
						formElement.name === CompleteDate
					) {
						return true;
					} else {
						newFormElements = newFormElements ? [formElement, ...newFormElements] : [formElement];
						return false;
					}
				});
			setState({
				...state,
				formElements: newFormElements,
				usedFormElements: newUsedFormElements
			});
		}
	};

	const setInitialState = (e?: any, isResetToDefault: boolean = false) => {
		e && e.preventDefault();
		let { inputGroups, formName } = JSON.parse(
			result[0][isResetToDefault ? 'defaultTemplateJson' : 'templateJson'] as string
		);
		let availableData = JSON.parse(result[0].mergeFieldsJson as string);
		let remainingData = availableData.filter(
			(availableFormField: CustomFieldType) =>
				!inputGroups.map((usedData: CustomFieldType) => usedData.name).includes(availableFormField.name)
		);
		if (Object.keys(payload).length === 0) setPayload(result[0]);
		isResetToDefault && handleEditFieldClose();
		setDataItemsState([...inputGroups, ...remainingData]);
		const localState = localStorageService.getLocalStorage('customForm');
		if (JSON.parse(localState) && !isResetToDefault) {
			setState({
				...(isPreviewMode ? JSON.parse(localState) : state)
			});
		} else {
			setState({
				...state,
				usedFormElements: [...inputGroups],
				customFormName: formName,
				formElements: [...remainingData]
			});
		}

		setSelectedEditField(undefined);
	};

	const createDragDropContext = (): any => {
		return {
			handleDelete: handleDelete,
			handlePreview: handlePreview,
			handleChangeInputValue: handleChangeInputValue,
			isPreviewMode: isPreviewMode,
			formType: state.customFormName
		};
	};

	const handleEditFieldClose = () => {
		validateSelectedFieldLabel() && setSelectedEditField(undefined);
	};

	const handleChangeProperties = (dataItem: CustomFieldType) => {
		let newUsedFormElements = _.cloneDeep(state.usedFormElements);

		newUsedFormElements =
			newUsedFormElements &&
			newUsedFormElements.map(formElement => {
				if (formElement.name === dataItem.name) {
					return dataItem;
				} else {
					return formElement;
				}
			});
		setSelectedEditField({ ...dataItem });
		setState({
			...state,
			usedFormElements: newUsedFormElements
		});
	};

	const handleValidateCustomField = () => {
		if (!isPreviewMode) {
			return;
		}
		let newUsedFormElement = _.cloneDeep(state.usedFormElements);
		_.each(newUsedFormElement, usedFormElement => {
			validationService.validateCustomFormField(usedFormElement.inputs || []);
		});
		let hasError = false;
		_.each(newUsedFormElement, usedFormElement => {
			_.each(usedFormElement.inputs, inputs => {
				if (
					validationService.hasError(inputs, 'error') ||
					validationService.hasError(inputs, 'fieldLabelError')
				) {
					hasError = true;
				}
			});
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		} else {
			alertService.addSuccess(localizationService.getLocalizedString('customForm.cleaningSubmitted'));
		}

		setState({ ...state, usedFormElements: newUsedFormElement });
	};

	const validateSelectedFieldLabel = (showAlertMessage?: boolean) => {
		let hasError = false;
		if (selectedEditField) {
			let newSelectedEditField = _.clone(selectedEditField);
			const { inputs } = newSelectedEditField;
			_.each(inputs, input => {
				validationService.validateRequiredField(
					input,
					'fieldLabel',
					'fieldLabelError',
					localizationService.getLocalizedString('customForm.labelText')
				);
			});
			_.each(inputs, input => {
				if (validationService.hasError(input, 'fieldLabelError')) {
					hasError = true;
				}
			});

			if (hasError && showAlertMessage) {
				alertService.addError(
					localizationService.getLocalizedString('screen.validationMessage.formValidation')
				);
			}
			setSelectedEditField(newSelectedEditField);
			return !hasError;
		} else {
			return true;
		}
	};

	const handleSubmit = (event: any) => {
		if (validateSelectedFieldLabel(true)) {
			event.preventDefault();
			event.stopPropagation();
			const payloadData: CustomForm = { ...payload };
			const { templateJson } = payloadData;
			let newTemplateJson = JSON.parse(templateJson || '');
			newTemplateJson.inputGroups = state.usedFormElements;
			payloadData.templateJson = JSON.stringify(newTemplateJson);
			dispatch(
				customFormSlice.patchOne(
					payload.customFormTemplateId || 0,
					payloadData,
					false,
					localizationService.getLocalizedString(
						'alertMessages.savedSuccess',
						_.capitalize(_.startCase(payload.templateName)) || ''
					),
					() => {
						loadCustomForm();
						setSelectedEditField(undefined);
						setIsCleaningFormUpdated(false);
					}
				)
			);
		}
	};

	const hideRemoveAll = () => {
		return _.isEqual(
			state.usedFormElements!.map((element: CustomFieldType) => element.name),
			requiredFormItems
		);
	};

	const sortDataByTypeAndName = (formElements: CustomFieldType[]) => {
		return (
			formElements &&
			formElements.sort((formElement1, formElement2) => {
				if (!formElement1 || !formElement2) {
					return -1;
				}
				let elementType1 =
					(formElement1.inputs && formElement1.inputs[0] && formElement1.inputs[0].fieldType) || '';
				let elementType2 =
					(formElement2.inputs && formElement2.inputs[0] && formElement2.inputs[0].fieldType) || '';
				let elementName1 = formElement1.name || '';
				let elementName2 = (formElement2 && formElement2.name) || '';
				if (elementType1 === CustomSelect) elementType1 = Lookup;
				else if (elementType2 === CustomSelect) elementType2 = Lookup;
				if (elementType1 === elementType2) {
					if (elementName1 === PercentGreaseCalculator) {
						return -1;
					}
					if (elementName2 === PercentGreaseCalculator) {
						return 1;
					}
					return elementName1 > elementName2 ? 1 : -1;
				}
				return elementType1 > elementType2 ? 1 : -1;
			})
		);
	};

	const getDescription = () => {
		return (
			<p className="mt-4">
				{localizationService.getLocalizedString(
					`customForm.${
						hideRemoveAll() ? 'allFormElementRemovedDescription' : 'allFormElementAddedDescription'
					}`
				)}
				<span hidden={hideRemoveAll()}>
					&nbsp;
					<a href="#/" onClick={removeAllUsedFormElement}>
						{localizationService.getLocalizedString('customForm.removeAll')}
						{','}
					</a>
				</span>
				&nbsp;
				{hideRemoveAll()
					? _.capitalize(localizationService.getLocalizedString('customForm.or'))
					: localizationService.getLocalizedString('customForm.or')}
				&nbsp;
				<a href="#/" onClick={(e: any) => setInitialState(e, true)}>
					{localizationService.getLocalizedString('customForm.resetToDefault')}
				</a>
				{'.'}
			</p>
		);
	};

	let leaveModalProps: CustomModalProp = {
		showModal: showLeaveModal,
		onOkayButtonClick: () => navigateToCustomForms(),
		onCancelButtonClick: toggleLeaveModal,
		title: localizationService.getLocalizedString('customForm.leavePageTitle'),
		message: localizationService.getLocalizedString('customForm.leavePageMessage'),
		okayButtonText: localizationService.getLocalizedString('customForm.buttons.leavePage')
	};

	return (
		<>
			{!urlService.isAdministrator() ? (
				<AccessDeniedPage />
			) : (
				<DragDropBoxContext.Provider value={createDragDropContext()}>
					{!props.hideHeader && (
						<FormBuilderHeader
							isCleaningFormUpdated={isCleaningFormUpdated}
							title={state.customFormName || ''}
							handleSubmit={handleSubmit}
							togglePreview={togglePreview}
							isPreviewMode={isPreviewMode}
							showDiscardModal={showDiscardModal}
							toggleDiscardModal={toggleDiscardModal}
							navigateToCustomForms={navigateToCustomForms}
							toggleMobileView={toggleMobileView}
							saveOnLocalStorage={saveOnLocalStorage}
						/>
					)}
					<div
						className={` ${
							viewport != ScreenViewPort.desktop && isPreviewMode ? '' : 'iframe-visibility'
						}  d-flex justify-content-center mb-3`}>
						{isPreviewMode && (
							<IFrame
								isPreviewMode={isPreviewMode}
								src={`${urlService.getSettingMenuPath('customForm')}/${customFormType}`}
								className={`mt-4 ${viewportSettingClass}`}></IFrame>
						)}
					</div>
					<div
						className={`form-builder-wrapper ${
							viewport == ScreenViewPort.desktop || !isPreviewMode ? '' : 'iframe-visibility'
						}`}>
						<div className=" unused-element-list-wrapper">
							<>
								{!isPreviewMode &&
									(showAddFormElement ? (
										<FloatingButton
											buttonClassName="floating-button-custom position-fixed"
											onClick={toggleAddFormElement}
										/>
									) : (
										<DragDropBox
											id="customCleaningFormDragArea"
											handleDrop={handleDrop}
											className="position-fixed unused-form-items"
											titleTag="h2"
											handleDragStart={handleDragStart}
											title={localizationService.getLocalizedString('customForm.formElements')}
											data={sortDataByTypeAndName(state.formElements || [])}
											description={getDescription()}
											itemRenderer={UnusedFormElement}
											handleClose={toggleAddFormElement}
										/>
									))}
							</>
						</div>
						<div>
							<section className="custom-cleaning-form custom-cleaning-form-screen-margin px-0">
								<CustomFormModal
									title={localizationService.getLocalizedString('customForm.submitCleaning')}
									data={state.usedFormElements}
									handleDrop={handleDrop}
									handleDragStart={handleDragStart}
									handleValidateCustomField={handleValidateCustomField}
									isPreviewMode={isPreviewMode}
								/>
							</section>
						</div>
						<div className="properties-sidebar">
							{selectedEditField && selectedEditField.name && (
								<EditField
									dataItem={selectedEditField}
									handleClose={handleEditFieldClose}
									handleChangeProperties={handleChangeProperties}
									formType={state.customFormName || ''}
								/>
							)}
						</div>
					</div>
					<NavigationPrompt
						when={isCleaningFormUpdated && !showDiscardModal}
						allowGoBack={false}
						afterCancel={() => {
							setShowDiscardModal(false);
						}}>
						{({ onConfirm, onCancel }) => (
							<CustomModal
								{...leaveModalProps}
								showModal={true}
								onCancelButtonClick={onCancel}
								onOkayButtonClick={onConfirm}
							/>
						)}
					</NavigationPrompt>
				</DragDropBoxContext.Provider>
			)}
		</>
	);
};
