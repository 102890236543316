import {
	Attachment,
	CleaningManifest,
	CustomCleaningFields,
	CustomFieldType,
	CustomFormAttachment,
	Unit,
	UploadMaximumSize50MB
} from '@rcp/types';
import _ from 'lodash';
import { FormFields } from 'src/components/authority/fog/cleaning/cleaning-modal';
import { alertService } from 'src/redux';
import { ConverterFactory } from './converter-factory';
import { apiService } from './data-service';
import { localizationService } from './localizationService';
import { translateService } from './translate-service';
import { Resource, urlService } from './url-service';
import { Utils } from './utils';
import { validationService } from './validation-service';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { Attachments, CompleteDate } = CustomCleaningFields;

const supportedFileTypes: string = '.docx,.doc,.xls,.xlsx,.pdf,.tif,.tiff,.jpg,.jpeg,.bmp,.png,.txt,.csv';

export class CustomFormService {
	static loadCustomForm(
		sourceId: number,
		setTemplateJson: React.Dispatch<React.SetStateAction<string>>,
		setFormType: React.Dispatch<React.SetStateAction<string>>,
		setCustomFormFieldTypes: React.Dispatch<React.SetStateAction<CustomFieldType[]>>,
		onSuccess?: () => void
	) {
		const url = urlService.getAuthorityResourceApiUrl(Resource.CleaningCustomFormTemplate, sourceId);
		apiService.httpGet(url).then(async data => {
			setTemplateJson(data.templateJson);
			setFormType(JSON.parse(data.templateJson).formName);
			CustomFormService.handleDefaultValue(JSON.parse(data.templateJson).inputGroups, setCustomFormFieldTypes);
			if (onSuccess) {
				onSuccess();
			}
		});
	}

	static async handleDefaultValue(
		formElements: CustomFieldType[],
		setCustomFormFieldTypes: React.Dispatch<React.SetStateAction<CustomFieldType[]>>
	) {
		let newFormElements = _.cloneDeep(formElements);
		_.each(newFormElements, dataItem => {
			let { inputs } = dataItem;
			_.each(inputs, input => {
				if ((dataItem.name === CompleteDate || dataItem.hasOwnProperty('defaultUnit')) && !input.isPrefilled) {
					return;
				}
				input.value = input.defaultValue;
			});
		});
		setCustomFormFieldTypes(newFormElements);
	}

	static async translateLabelText(formElements: CustomFieldType[]) {
		var labelBatch = [] as string[];
		_.each(formElements, dataItem => {
			let { inputs } = dataItem;
			_.each(inputs, input => {
				labelBatch.push(input.fieldLabel);
			});
		});
		console.log('translateLabelText', formElements, labelBatch);
		return translateService.translateStringBatch(labelBatch, false);
	}

	static updateForm(
		setTemplateJson: React.Dispatch<React.SetStateAction<string>>,
		setFormType: React.Dispatch<React.SetStateAction<string>>,
		formState: any,
		files: CustomFormAttachment[],
		formStateAttachments: CustomFormAttachment[] = [],
		isEditMode: boolean = false,
		setCustomFormFieldTypes: React.Dispatch<React.SetStateAction<CustomFieldType[]>>,
		templateJson?: string
	) {
		if (templateJson) {
			setTemplateJson(templateJson);
			setFormType(JSON.parse(templateJson).formName);
			CustomFormService.updateValueTemplateJson(
				JSON.parse(templateJson).inputGroups,
				formState,
				files,
				formStateAttachments,
				isEditMode,
				setCustomFormFieldTypes
			);
		}
	}

	static updateValueTemplateJson(
		formElements: CustomFieldType[],
		formState: any,
		files: CustomFormAttachment[],
		formStateAttachments: CustomFormAttachment[] = [],
		canAttachmentsBeEdited: boolean = false,
		setCustomFormFieldTypes: React.Dispatch<React.SetStateAction<CustomFieldType[]>>
	) {
		let newFormElements = _.cloneDeep(formElements);
		let attachments: Attachment[] =
			canAttachmentsBeEdited && formStateAttachments.length > 0 ? formStateAttachments : files;
		_.each(newFormElements, dataItem => {
			let { inputs } = dataItem;
			let attachmentDetail =
				dataItem.name === Attachments &&
				attachments &&
				Object.assign(
					{},
					...attachments.map((x: Attachment) => {
						return { [x.attachmentSectionKey + '']: x };
					})
				);
			_.each(inputs, input => {
				let fieldId = input.fieldId ? input.fieldId : '';
				if (!String.isUndefinedOrEmpty(fieldId)) {
					if (String.equalCaseInsensitive(dataItem.name, Attachments)) {
						input.value = [attachmentDetail[fieldId]];
					} else {
						input.value = formState[fieldId];
					}
				}
			});
		});
		setCustomFormFieldTypes(newFormElements);
	}

	static fetchAttachments(
		url: string,
		setFiles: React.Dispatch<React.SetStateAction<CustomFormAttachment[]>>,
		onSuccess?: () => void
	) {
		apiService
			.getResource(url)
			.then(data => {
				let attachments = [...(data as any)];
				setFiles(attachments);
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch(err => alertService.addError(err.message));
	}

	static handleChangeInputValue(
		e: any,
		dataItem: CustomFieldType,
		formElements: CustomFieldType[],
		setFormElements: React.Dispatch<React.SetStateAction<CustomFieldType[]>>,
		removedAttachments: CustomFormAttachment[],
		setRemovedAttachments: React.Dispatch<React.SetStateAction<CustomFormAttachment[]>>,
		fieldValue?: any
	) {
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked ? true : '';
		}
		if (fieldValue) {
			value = fieldValue;
		}
		let newDataItemInputs = { ...dataItem }.inputs;
		_.each(newDataItemInputs, dataInput => {
			if (dataInput.fieldId === name) {
				if (dataInput.value && dataInput.value[0] && (dataInput.value[0] as any).fileName) {
					setRemovedAttachments([...removedAttachments, dataInput.value[0]]);
				}
				dataInput.value = value;
			}
		});
		let newDataItem = { ...dataItem, inputs: newDataItemInputs };
		let newFormElement = _.cloneDeep(formElements);

		newFormElement =
			newFormElement &&
			newFormElement.map(formElement => {
				if (formElement.name === dataItem.name) {
					return newDataItem;
				} else {
					return formElement;
				}
			});
		setFormElements(newFormElement);
	}

	static handleDrop(
		chosenFiles: any,
		files: CustomFormAttachment[],
		setFiles: React.Dispatch<React.SetStateAction<CustomFormAttachment[]>>
	) {
		let selectedFiles = _.cloneDeep(files);

		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}
		if (!CustomFormService.isTotalFileSizeLessThanMaxAllowedSize(Array.from(chosenFiles), files)) {
			return;
		}

		for (let i = 0; i < chosenFiles.length; i++) {
			if (!CustomFormService.isSupportedFileType(chosenFiles[i])) {
				alertService.addError(
					localizationService.getLocalizedString('attachments.fileTypeNotSupported', chosenFiles[i].name)
				);
			} else {
				chosenFiles.length <= 1 && alertService.clearAllMessages();
				selectedFiles!.push(chosenFiles[i]);
			}
		}
		setFiles([...(selectedFiles as File[])]);
	}

	static isSupportedFileType(file: File, providedFileTypes?: string) {
		let acceptedFileTypes = providedFileTypes ? providedFileTypes : supportedFileTypes;
		let supportedFileExtensions = supportedFileTypes.replace(/[.]/g, '');
		let selectedFileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		return _.toLower(supportedFileExtensions)
			.split(',')
			.includes(_.toLower(selectedFileExtension));
	}

	static validateHasVideo(files: File[] | FileList) {
		for (let x = 0; x < files.length; x++) {
			let fileType = _.toLower(files[x].type);
			if (fileType && fileType.startsWith('video/')) {
				alertService.addError(localizationService.getLocalizedString('attachments.uploadVideoIsUnsupported'));
				return false;
			}
		}
		return true;
	}

	static validateFileTypes(files: File[] | FileList) {
		for (let x = 0; x < files.length; x++) {
			if (!CustomFormService.isSupportedFileType(files[x])) {
				alertService.addError(
					localizationService.getLocalizedString('attachments.fileTypeNotSupported', files[x].name)
				);
				return false;
			}
		}
		return true;
	}

	static isTotalFileSizeLessThanMaxAllowedSize(selectedFiles: File[] = [], files: CustomFormAttachment[]) {
		let allFiles = [...selectedFiles, ...files];
		if (allFiles.length) {
			let fileSizes = allFiles.map((fileData: any) => fileData.size);
			let totalPayloadSizeInMB = CustomFormService.bytesToSize(fileSizes.reduce((a, b) => a + b, 0));
			if (!validationService.validateUploadSize(totalPayloadSizeInMB, UploadMaximumSize50MB)) {
				alertService.addError(
					localizationService.getLocalizedString(
						'haulerPortal.submitCleaning.errors.collectiveFileSize',
						UploadMaximumSize50MB + ''
					)
				);
				return false;
			} else {
				alertService.clearAllMessages();
				return true;
			}
		}
		return false;
	}

	static bytesToSize(bytes: number) {
		var sizeInMB = (bytes / (1024 * 1024)).toFixed(2);
		return Number(sizeInMB);
	}

	static onFileChangeHandler(
		event: any,
		files: CustomFormAttachment[],
		setFiles: React.Dispatch<React.SetStateAction<CustomFormAttachment[]>>
	) {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;
		let selectedFiles = _.cloneDeep(files);

		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}

		let totalFileSize = 0;
		let hasVideo = false;
		for (let x = 0; x < chosenFiles.length; x++) {
			totalFileSize += chosenFiles[x].size;
			let fileType = _.toLower(chosenFiles[x].type);
			if (fileType && fileType.startsWith('video/')) {
				hasVideo = true;
			} else if (!CustomFormService.isSupportedFileType(chosenFiles[x])) {
				alertService.addError(
					localizationService.getLocalizedString('attachments.fileTypeNotSupported', chosenFiles[x].name)
				);
			} else {
				chosenFiles.length <= 1 && alertService.clearAllMessages();
				selectedFiles!.push(chosenFiles[x]);
			}
		}
		if (hasVideo) {
			alertService.addError(localizationService.getLocalizedString('attachments.uploadVideoIsUnsupported'));
			inputElement.files = null;
			return;
		}

		if (!validationService.validateUploadSize(totalFileSize, UploadMaximumSize50MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			inputElement.files = null;
			return;
		}

		setFiles([...(selectedFiles as CustomFormAttachment[])]);
	}

	static createForm(
		formElements: CustomFieldType[],
		handleChangeInputValue: (e: any, dataItem: CustomFieldType, fieldValue?: any) => void,
		isPreviewMode: boolean,
		formType: string,
		authorityOrganizationId?: number
	): any {
		if (!!formElements.length) {
			ConverterFactory.getInputsFromDataItem(
				formElements,
				handleChangeInputValue,
				isPreviewMode,
				formType,
				authorityOrganizationId
			).map(formData => {
				return formData;
			});
		}
		return;
	}

	static async uploadFiles(url: string, files: CustomFormAttachment[]) {
		let formData = new FormData();
		for (let x = 0; x < files.length; x++) {
			formData.append('files', files[x]);
		}
		await apiService.postFormData(url, formData).catch(err => alertService.addError(err.message));
	}

	static initFormUnits(
		templateJson: string,
		unitList: Unit[],
		formState: FormFields | CleaningManifest,
		setFormState:
			| React.Dispatch<React.SetStateAction<FormFields>>
			| React.Dispatch<React.SetStateAction<CleaningManifest>>
	): FormFields {
		const formTemplate = templateJson ? JSON.parse(templateJson) : undefined;

		if (formTemplate && formTemplate.inputGroups) {
			const inputGroups = formTemplate.inputGroups as CustomFieldType[];
			const inputGroupsWithUnits = inputGroups.filter(i => i.defaultUnit);
			const unitIdHash = Utils.getUnitHash(unitList);

			const unitsForForm = {} as any;

			for (let inputGroupWithUnits of inputGroupsWithUnits) {
				if (CustomFormService.isValidUnitFormGroup(inputGroupWithUnits, unitIdHash)) {
					for (let input of inputGroupWithUnits.inputs!) {
						const fieldName = `${input.fieldId}UnitId`;
						unitsForForm[fieldName] = unitIdHash[inputGroupWithUnits.defaultUnit!];
					}
				}
			}

			setFormState({ ...formState, ...unitsForForm, submissionCustomFormInitialized: true });

			return unitsForForm;
		}

		return {};
	}

	static isValidUnitFormGroup(unit: CustomFieldType, unitIdHash: any): boolean {
		return (
			unit &&
			unit.inputs &&
			unit.inputs.length > 0 &&
			unit.inputs[0].fieldId &&
			unit.defaultUnit &&
			unitIdHash[unit.defaultUnit]
		);
	}
}
