import React from 'react';
import { translateService } from 'src/services/translate-service';
import { Translate } from './translate/translator';
import { LanguageContext } from './translate/translator-context';

export interface SingleCheckboxProps {
	id: string;
	name: string;
	label?: string | JSX.Element;
	isDisabled?: boolean;
	checked?: boolean;
	className?: string;
	htmlLabel?: string;
	labelClass?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	classNameForLabel?: string;
	fieldLabel?: JSX.Element;
	error?: string;
	doNotTranslate?: boolean;
}

export const SingleCheckbox: React.FC<SingleCheckboxProps> = props => {
	const [translatedHtmlLabel, setTranslatedHtmlLabel] = React.useState('');
	const language = React.useContext(LanguageContext);

	React.useEffect(() => {
		populateContent();
	}, [props, language]);

	const populateContent = async () => {
		if (props.htmlLabel) {
			if (!props.doNotTranslate && language.language.code !== 'en') {
				let translatedHtmlLabel = await translateService.translateHtml(props.htmlLabel);
				setTranslatedHtmlLabel(translatedHtmlLabel);
			} else {
				setTranslatedHtmlLabel(props.htmlLabel);
			}
		}
	};

	const changeValue = (e: any) => {
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const handleChange = (e: any) => {
		changeValue(e);
	};

	let defaultClass = 'form-group';
	let rowClass = props.className ? props.className : defaultClass;

	let defaultLabelClass = 'custom-control-label';
	let rowLabelClass = props.classNameForLabel ? props.classNameForLabel : defaultLabelClass;

	return (
		<div className={rowClass}>
			{props.fieldLabel ? props.fieldLabel : null}
			<div className={'custom-control custom-checkbox'}>
				<input
					id={props.id}
					name={props.name}
					type="checkbox"
					className="custom-control-input"
					checked={props.checked}
					onChange={handleChange}
					disabled={props.isDisabled === true ? true : false}
				/>
				{!props.htmlLabel && (
					<>
						<label htmlFor={props.id} className={rowLabelClass}>
							{!props.fieldLabel && rowClass.includes('required') && (
								<span className="custom-checkbox-required">*&nbsp;</span>
							)}
							<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
						</label>
					</>
				)}

				{props.htmlLabel && (
					<>
						<label
							htmlFor={props.id}
							className={rowLabelClass}
							dangerouslySetInnerHTML={{ __html: translatedHtmlLabel }}
						/>
					</>
				)}
			</div>
			{props.error && (
				<div className="invalid-feedback">
					<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
				</div>
			)}
		</div>
	);
};
