import * as React from 'react';
import { TextInput, DateInput, SingleSelectDropdown, TextAreaInput } from 'src/components/widgets';
import _ from 'lodash';
import { FacilityField } from './steps/bulk-update-service';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { localizationService } from 'src/services';

class FacilityFieldGenerator {
	getTextEditDiv = (index: number, facilityField: FacilityField, onChange?: (e: any) => void) => {
		let keyStr = `${facilityField.fieldName}-${index}`;
		return (
			<TextInput
				key={keyStr}
				id={facilityField.fieldName}
				name={facilityField.fieldName}
				label={facilityField.title}
				value={facilityField.value}
				onChange={onChange}
				isRequired={facilityField.isRequired}
				error={facilityField.error}
			/>
		);
	};

	getOptionEditDiv = (index: number, facilityField: FacilityField, onChange?: (e: any) => void) => {
		let keyStr = `${facilityField.fieldName}-${index}`;
		let isOverrideComplianceStatusField = String.equalCaseInsensitive(
			facilityField.fieldName,
			'overriddenComplianceStatus'
		);
		let isRiskScoreField = String.equalCaseInsensitive(facilityField.fieldName, 'riskScore');
		return (
			<SingleSelectDropdown
				key={keyStr}
				id={facilityField.fieldName}
				name={facilityField.fieldName}
				label={facilityField.title}
				value={_.toString(facilityField.value)}
				onChange={onChange}
				options={facilityField.optionValues}
				noEmptyOption={facilityField.isRequired}
				selfOrder={isOverrideComplianceStatusField || isRiskScoreField}
				isDisabled={false}
			/>
		);
	};

	getTextAreaInputElement = (
		index: number,
		facilityField: FacilityField,
		isFullWidth: boolean,
		onChange?: (e: any) => void
	) => {
		return (
			<TextAreaInput
				key={`${facilityField.fieldName}-${index}`}
				id={facilityField.fieldName}
				name={facilityField.fieldName}
				label={facilityField.title}
				isFullWidth={isFullWidth}
				value={facilityField.value}
				onChange={onChange}
				isRequired={facilityField.isRequired}
				error={facilityField.error}
			/>
		);
	};

	getRichTextEditorInputElement = (
		index: number,
		facilityField: FacilityField,
		onChange: (e: any, fieldName: string) => void
	) => {
		return (
			<div className="mb-3 risk-score-editor">
				<label>{localizationService.getLocalizedString('facility.riskScoreNotes')}</label>
				<RichTextEditor
					onChange={e => onChange(e, facilityField.fieldName)}
					height="200px"
					editorIndex={0}
					isFixedHeight
					useBasicTools
					key={`${facilityField.fieldName}-${index}`}
				/>
			</div>
		);
	};

	getTextInputElement = (
		index: number,
		facilityField: FacilityField,
		onChange?: (e: any) => void,
		classNameStr?: string
	) => {
		return (
			<TextInput
				key={`${facilityField.fieldName}-${index}`}
				id={facilityField.fieldName}
				name={facilityField.fieldName}
				className={classNameStr}
				label={facilityField.title}
				value={facilityField.value}
				onChange={onChange}
				isRequired={facilityField.isRequired}
				error={facilityField.error}
			/>
		);
	};

	getDateInputElement = (index: number, facilityField: FacilityField, onChange?: (e: any) => void) => {
		return (
			<DateInput
				key={`${facilityField.fieldName}-${index}`}
				id={facilityField.fieldName}
				name={facilityField.fieldName}
				className="form-group"
				label={facilityField.title}
				value={facilityField.value}
				onChange={onChange}
				isRequired={facilityField.isRequired}
				error={facilityField.error}
			/>
		);
	};
}

export const facilityFieldGenerator = new FacilityFieldGenerator();
