import React, { FC, useEffect, useState } from 'react';
import { History } from 'history';
import { ippIndustrySlice } from './industries.slice';
import * as LinkoTypes from '@rcp/types';
import { IppIndustry, ColumnField, FilterType, LocalStorageName, FilterObjectName } from '@rcp/types';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState, useReduxSelector } from 'src/redux';
import { localizationService, urlService, Resource, navigateTo } from 'src/services';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { IppConstants } from 'src/constants';
import _ from 'lodash';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
}

const { initialPage } = IppConstants.queryParams;

const IppIndustries: FC<Props<IppIndustry>> = props => {
	const currentUser = useReduxSelector(state => state.userProfile);
	const industryNameToLink = (item: any) => {
		const dataItem = item.dataItem as IppIndustry;
		const url = urlService.getReactAuthorityResourceUrl(
			'authority/' + Resource.IppIndustries,
			dataItem.ippIndustryId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.industryName}
				</a>
			</td>
		);
	};

	const showBulkUpdate = () => {
		let url = '/ipp/authority/industry/bulkUpdate';
		navigateTo(
			props.history,
			url,
			undefined,
			_.pick(urlService.toQueryDictionary(window.location.search), ['draftFilterId', 'filterId', 'search'])
		);
	};

	const industryPortalCell = (item: any) => {
		const industry = item.dataItem as IppIndustry;
		if (industry.guid) {
			const landingPortalUrl = urlService.getLandingPortalUrlForIndustryPortalFromIpp(industry);
			return (
				<td>
					<a href={landingPortalUrl} id={`industryPortalLink` + industry.guid} className="ai-link">
						{localizationService.getLocalizedString('ipp.industries.industryColumns.accessIndustryPortal')}
					</a>
				</td>
			);
		} else return <td></td>;
	};

	useEffect(() => {
		if (currentUser.userProfile.isInternalAccount) {
			let newAllColumns = _.cloneDeep(initialGridOption.allColumns);
			let newDefaultColumns = _.cloneDeep(initialGridOption.defaultColumns);
			let newColumn = new ColumnField('customerSuccessAccess', FilterType.Text, industryPortalCell);
			newAllColumns.push(newColumn);
			newDefaultColumns.push('customerSuccessAccess');
			setInitialGridOption({
				...initialGridOption,
				allColumns: [...newAllColumns],
				defaultColumns: [...newDefaultColumns]
			});
		}
	}, [currentUser]);

	const [initialGridOption, setInitialGridOption] = useState<GridOption>({
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		showRefreshButton: true,
		prefix: 'ipp.industries.industryColumns',
		storageName: LocalStorageName.IppIndustryGrid,
		customFieldQuery: '',
		searchHint: localizationService.getLocalizedString('ipp.industries.searchHint'),
		showActions: true,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.industries.industryPageHeading'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		addButtonText: '',
		showScheduleInspectionModal: false,
		queryParameters: { page: initialPage, sort: 'industryName asc' },
		showFilterMenuPopOver: false,
		filterOnlyThisGrid: true,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: true,
		bulkUpdate: () => showBulkUpdate(),
		showExportToSpreadsheet: false,
		isPaginatedSearch: true,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppIndustryGridFilterUrl,
		unsetPageOnFilterApply: false,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPINDUSTRY,
				FilterDomainName.ippIndustry,
				FilterObjectName.IppIndustry,
				LocalStorageName.IppIndustryGridFilterUrl,
				{ IPPINDUSTRY: true }
			);
		},
		allColumns: [
			new ColumnField('industryName', FilterType.Text, industryNameToLink),
			new ColumnField('industryNumber'),
			new ColumnField('addressLine1', FilterType.Text),
			new ColumnField('addressLine2'),
			new ColumnField('cityName'),
			new ColumnField('stateName'),
			new ColumnField('zipCode'),
			new ColumnField('classification'),
			new ColumnField('isEnabled', FilterType.Boolean),
			new ColumnField('hasSignatory', FilterType.Boolean),
			new ColumnField('assignedTo'),
			new ColumnField('showScheduledReport'),
			new ColumnField('showAutomaticallyGeneratedParameterGroups')
		],

		defaultColumns: [
			'industryNumber',
			'industryName',
			'addressLine1',
			'classification',
			'isEnabled',
			'hasSignatory',
			'assignedTo',
			'showScheduledReport'
		]
	});
	const industriesState = (state: RootState) => state.ippIndustries;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippIndustrySlice}
				restState={industriesState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppIndustries;
