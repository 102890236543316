import React from 'react';

import { BulkUpdateSteps } from '@rcp/types';
import { localizationService } from 'src/services';
import './bulk-update.scss';
import ChooseFieldsStep from './steps/step1-choose-fields';
import Stepper, { Step } from '../../../../widgets/stepper';
import InputFieldValuesStep from './steps/step2-input-values';
import BulkUpdateSummaryStep from './steps/step3-bulk-update-summary';

const AuthoritiesDeviceBulkUpdateComponent: React.FunctionComponent = () => {
	let title = localizationService.getLocalizedString('bulkUpdate.bulkUpdate');
	let step1Title = localizationService.getLocalizedString('bulkUpdate.chooseFieldsToUpdate');
	let step2Title = localizationService.getLocalizedString('bulkUpdate.update');
	let step3Title = localizationService.getLocalizedString('bulkUpdate.results');
	return (
		<div className="page">
			<div className="page-header">
				<h1>{title}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section>
						<Stepper vertical={true} initialStep={BulkUpdateSteps.STEP1_CHOOSE_FIELDS}>
							<Step stepId={BulkUpdateSteps.STEP1_CHOOSE_FIELDS} title={step1Title}>
								<ChooseFieldsStep />
							</Step>
							<Step stepId={BulkUpdateSteps.STEP2_INPUT_VALUES} title={step2Title}>
								<InputFieldValuesStep />
							</Step>
							<Step stepId={BulkUpdateSteps.STEP3_SUMMARY} title={step3Title}>
								<BulkUpdateSummaryStep />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export const AuthoritiesDeviceBulkUpdate = AuthoritiesDeviceBulkUpdateComponent;
