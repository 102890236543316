import React, { useState, useEffect } from 'react';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { IppReportPackageParameter } from '@rcp/types';
import { PopoverModal } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';
import { filterBy } from '@progress/kendo-data-query';

export interface IppSampleParameterAddModalProps {
	showModal: boolean;
	modalTitle: string;
	parameters: IppReportPackageParameter[];
	selectedParameter: IppReportPackageParameter;
	onSave: (e: React.MouseEvent<HTMLElement>) => void;
	onCancel: (e: React.MouseEvent<HTMLElement>) => void;
	onFieldChange: (e: any) => void;
}

function IppSampleParameterAddModal(props: IppSampleParameterAddModalProps) {
	const [parameters, setParameters] = useState(props.parameters || []);

	useEffect(() => {
		setParameters([...props.parameters]);
	}, [props.parameters]);

	const filterChange = (event: ComboBoxFilterChangeEvent) => {
		let filteredData = filterData(event.filter);
		setParameters([...filteredData]);
	};

	const filterData = (filter: FilterDescriptor) => {
		const filteredData = props.parameters.slice();
		const filterByOutput = filterBy(filteredData, filter);
		return filterByOutput;
	};

	useEffect(() => {
		setParameters(props.parameters);
	}, [props.showModal]);

	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					save={props.onSave}
					cancel={props.onCancel}
					saveButtonClassName="ai-action"
					saveButtonText={localizationService.getLocalizedString('ipp.buttons.add')}>
					<div className="form-row">
						<label className="mr-2">
							{localizationService.getLocalizedString('ipp.samples.step2.addModalSubtitle')}
						</label>
					</div>
					<div className="form-row">
						<div className="form-group">
							<ComboBox
								data={parameters}
								value={props.selectedParameter}
								allowCustom={false}
								filterable={true}
								textField="name"
								dataItemKey="parameterId"
								onChange={(e: any) => props.onFieldChange(e)}
								onFilterChange={filterChange}
								style={{ width: '400px' }}
							/>
						</div>
					</div>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
}

export default IppSampleParameterAddModal;
