import React, { FC, useState } from 'react';
import { Grid, GridColumn, GridHeaderCellProps, GridCellProps } from '@progress/kendo-react-grid';
import './sample-grid.scss';
import FieldMappingEditor from './field-mapping-editor';
import { localizationService, Logger } from 'src/services';
import _, { Dictionary } from 'lodash';
import { DataImport, DropDownOption, ImportField, ImportFieldMapping } from '@rcp/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import {
	clearSelfImportStepsState,
	saveConfigurationFieldMapping,
	SelfImportSteps,
	UnMapConfirmationModal,
	updateSelfImportFieldMapping,
	updateSelfImportState
} from '../../self-import-steps';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux';
import { StepperContext } from 'src/components/widgets';

export interface SampleGridProp {
	data: Dictionary<string>[];
	isConfigurationEditable: boolean;
	fieldOptions: DropDownOption[];
	fieldMappings: ImportFieldMapping[];
	cccSaveFieldMapping?: (fieldMapping: ImportFieldMapping, columnId: string) => Promise<void>;
	importConfigurationId: number;
	importFileId: string;
	targetFields?: DataImport.ImportField[] | ImportField[];
	uploadDataColumns?: string[];
	uploadDataRows?: string[][];
	totalRowNumber: number;
	sideMode: boolean;
	isDraftConfiguration?: boolean;
}

export interface SampleGridState {
	showColumnEditor: boolean;
	selectedColumn: string;
	fieldMappings: ImportFieldMapping[];
	skip: number;
	take: number;
}

const SampleGrid: FC<SampleGridProp> = props => {
	const initialState: SampleGridState = {
		showColumnEditor: false,
		selectedColumn: '',
		fieldMappings: [],
		skip: 0,
		take: 10
	};
	const stepperContext = React.useContext(StepperContext);
	const [state, setState] = useState(initialState);
	const [showConfirmUnMapColumn, setShowConfirmUnMapColumn] = useState(false);
	const [selectedFieldMapping, setSelectedFieldMapping] = useState<ImportFieldMapping>({
		importColumnName: ''
	});
	const selfImportState = useSelector((state: RootState) => state.selfImport);
	const dispatch = useDispatch();

	React.useEffect(() => {
		let fieldMappings = [...props.fieldMappings];
		setState({ ...state, fieldMappings: fieldMappings });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.fieldMappings]);

	const setClickedHeaderSelectedByJavascript = (selectedColumn: string) => {
		let headers = document.querySelectorAll('.sample-grid .k-grid-header th');
		if (headers) {
			for (let i = 0; i < headers.length; i++) {
				let headerEl = headers[i];
				if (headerEl.textContent === selectedColumn) {
					headerEl.classList.add('selected-column-header');
				} else {
					headerEl.classList.remove('selected-column-header');
				}
			}
		}
	};

	const onHeaderCheckedClick = (evt: any, field: GridHeaderCellProps) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (props.cccSaveFieldMapping) {
			onHeaderClick(evt, field);
			return;
		}
		if (!props.isConfigurationEditable) {
			onHeaderClick(evt, field);
			return;
		}
		const column: string = field.field ? field.field : 'UNKNOWN';
		let columnName = _.get(props.data[0], column);
		let existingFieldMapping = state.fieldMappings.find(
			(f: ImportFieldMapping) => _.toUpper(f.importColumnName) === _.toUpper(columnName)
		);
		if (!existingFieldMapping) {
			Logger.error(`FOG click on checked icon should find an existing field mapping.`);
			return;
		}
		let fieldMapping: ImportFieldMapping = { ...existingFieldMapping };
		setSelectedFieldMapping(fieldMapping);
		setShowConfirmUnMapColumn(true);
	};

	const deleteSelectedFieldMapping = () => {
		let fieldMappingForDelete: ImportFieldMapping = { ...selectedFieldMapping, fieldName: '', fieldLabel: '' };
		setShowConfirmUnMapColumn(false);

		dispatch(updateSelfImportState({ showSidePanel: false, selectedColumn: '' }));

		saveConfigurationFieldMapping(
			fieldMappingForDelete,
			dispatch,
			props.fieldMappings,
			props.importConfigurationId,
			props.targetFields as DataImport.ImportField[]
		).then(() => {
			dispatch(updateSelfImportFieldMapping(fieldMappingForDelete));
			if (!props.cccSaveFieldMapping) {
				clearSelfImportStepsState(stepperContext, SelfImportSteps.STEP4, SelfImportSteps.STEP5);
			}
		});
	};

	const onHeaderClick = (evt: any, field: GridHeaderCellProps) => {
		evt.preventDefault();
		evt.stopPropagation();

		if (selfImportState.current.isSaving) {
			// wait till API call is finished
			return;
		}

		const column: string = field.field ? field.field : 'UNKNOWN';
		let columnName = _.get(props.data[0], column);
		let existingFieldMapping = state.fieldMappings.find(
			(f: ImportFieldMapping) => _.toUpper(f.importColumnName) === _.toUpper(columnName)
		);
		let emptyFieldMapping: ImportFieldMapping = {
			importConfigurationId: _.toNumber(props.importConfigurationId),
			importColumnName: columnName,
			fieldName: '',
			fieldLabel: '',
			fieldType: 'String',
			isCustomField: false,
			isRequired: false,
			defaultValue: '',
			fieldUpdatePolicy: DataImport.FieldUpdatePolicy.alwaysUpdate
		};
		let fieldMapping: ImportFieldMapping = { ...(existingFieldMapping || emptyFieldMapping) };
		if (props.targetFields && !_.isEmpty(fieldMapping.fieldName)) {
			let targetField = props.targetFields.find(f => f.fieldName === fieldMapping.fieldName);
			if (targetField) {
				fieldMapping.fieldLabel = targetField.fieldLabel;
				fieldMapping.fieldType = fieldMapping.fieldType || targetField.fieldType;
				fieldMapping.isCustomField = fieldMapping.isCustomField || targetField.isCustomField;
				fieldMapping.isRequired =
					fieldMapping.isRequired || targetField.isNotNull || targetField.isRequiredForImport;
				fieldMapping.defaultValue = fieldMapping.defaultValue || targetField.defaultValue;
				fieldMapping.fieldUpdatePolicy = fieldMapping.fieldUpdatePolicy || targetField.updatePolicy;
				fieldMapping.isNotNull = targetField.isNotNull;
				fieldMapping.isRequiredForDataEnter = targetField.isRequiredForDataEnter;
			}
		}

		let previousColumn = selectedFieldMapping.importColumnName;
		setSelectedFieldMapping(fieldMapping);
		if (props.cccSaveFieldMapping) {
			setState({ ...state, showColumnEditor: true, selectedColumn: column });
		} else {
			dispatch(
				updateSelfImportState({ showSidePanel: true, selectedColumn: column, previousColumn, fieldMapping })
			);
			//kendo grid does not rerender when state.selectedColumn change, added below javascript
			//manipulation to change header style to align to UX design.
			setClickedHeaderSelectedByJavascript(column);
		}
	};

	const getHeaderCell = (columnProps: GridHeaderCellProps) => {
		let column = columnProps.field as string;
		let columnName = _.get(props.data[0], column);
		if (column === ' ') {
			return <span></span>;
		}
		let isMapped =
			_.find(state.fieldMappings, f => _.toUpper(f.importColumnName) === _.toUpper(columnName)) !== undefined;
		if (_.toNumber(props.importConfigurationId) < 1 && !isMapped) {
			let className = 'grey-out';
			if (state.selectedColumn === columnName) {
				className += ' selected-column';
			}
			return <span className={className}>{column}</span>;
		}

		let className = props.isConfigurationEditable ? 'mapped-column-icon' : 'mapped-column-icon grey-out';
		if (state.selectedColumn === columnName) {
			className += ' selected-column';
		}
		return (
			<a href="#/" className="w-100" onClick={event => onHeaderClick(event, columnProps)}>
				{isMapped && (
					<FontAwesomeIcon
						icon={faCheck}
						className={className}
						onClick={event => onHeaderCheckedClick(event, columnProps)}
					/>
				)}
				<span className="mapped">{column}</span>
			</a>
		);
	};

	const onColumnEditorClosed = () => {
		setState({ ...state, showColumnEditor: false });
	};

	const formatCell = (cellProps: GridCellProps) => {
		let column = cellProps.field as string;
		let columnName = _.get(props.data[0], column);
		let isSolid =
			column === ' ' ||
			_.find(state.fieldMappings, f => _.toUpper(f.importColumnName) === _.toUpper(columnName)) !== undefined;
		let fieldValue = _.get(cellProps.dataItem, column);
		let className = isSolid ? '' : 'unmapped-cell';
		if (cellProps.columnIndex && cellProps.dataIndex === 1 && cellProps.columnIndex > 0) {
			className += ' column-cell';
		}
		return <td className={className}>{localizationService.formatValue(fieldValue)}</td>;
	};

	const getColumns = () => {
		let row1 = props.data[0];
		return _.keys(row1).map((column: string) => {
			return (
				<GridColumn
					key={`field_column_key${column}`}
					field={column}
					headerCell={getHeaderCell}
					title={column}
					cell={formatCell}
				/>
			);
		});
	};
	const onPageChange = (event: any) => {
		setState({
			...state,
			skip: event.page.skip,
			take: event.page.take
		});
	};

	const GetDataGridView = () => {
		return (
			props.data && (
				<div className="w-100">
					<Grid
						className="table table-responsive sample-grid"
						selectedField="selected"
						pageable={false}
						data={props.data.slice(state.skip, state.take + state.skip)}
						skip={state.skip}
						take={state.take}
						total={props.data.length}
						onPageChange={onPageChange}
						scrollable="none">
						{getColumns()}
					</Grid>
					{props.uploadDataRows && props.totalRowNumber > props.uploadDataRows.length && (
						<div className="sample-grid-hint">
							{localizationService.getLocalizedString(
								'cccImportFile.ColumnGridPreviewDescription',
								_.toString(props.uploadDataRows.length),
								_.toString(props.totalRowNumber)
							)}
						</div>
					)}
				</div>
			)
		);
	};

	return (
		<>
			{props.isDraftConfiguration ? (
				<label>
					{localizationService.getLocalizedString('import.steps.templateMapTableDraftConfiguration')}
				</label>
			) : (
				<>
					<label>{localizationService.getLocalizedString('import.steps.templateMapTableTitle')}</label>&nbsp;
					{props.isConfigurationEditable && (
						<label>
							{localizationService.getLocalizedString('import.steps.userTemplateMapTableTitle')}
						</label>
					)}
				</>
			)}
			{GetDataGridView()}
			{props.cccSaveFieldMapping && state.showColumnEditor && selectedFieldMapping && (
				<FieldMappingEditor
					onClosed={onColumnEditorClosed}
					isConfigurationEditable={props.isConfigurationEditable}
					isReadOnlyConfiguration={!props.isConfigurationEditable}
					saveFieldMapping={props.cccSaveFieldMapping}
					importConfigurationId={props.importConfigurationId}
					importFileId={props.importFileId}
					targetFields={props.targetFields}
					column={state.selectedColumn}
					fieldMapping={selectedFieldMapping}
					fieldMappings={state.fieldMappings}
					fieldOptions={props.fieldOptions}
					title={
						localizationService.getLocalizedString('cccImportFile.column') +
						' ' +
						state.selectedColumn +
						' - ' +
						selectedFieldMapping.importColumnName
					}></FieldMappingEditor>
			)}
			{showConfirmUnMapColumn && (
				<UnMapConfirmationModal
					onCancel={() => {
						setShowConfirmUnMapColumn(false);
					}}
					onConfirm={deleteSelectedFieldMapping}
					columnName={selectedFieldMapping.importColumnName}
				/>
			)}
		</>
	);
};

export default SampleGrid;
