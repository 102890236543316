import { Dictionary, IppPackageElement } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { ReportPackageStatus } from 'src/constants';
import { localizationService } from 'src/services';
import IppPackageItemAttachmentFiles from './attachment-files';

interface Props {
	reportStatus?: string;
	attachmentTypes?: IppPackageElement[];
	className?: string;
	onChangeAttachment?: (event?: any, id?: number, attachmentTypeId?: number) => void;
	showSearch?: boolean;
	showFilter?: boolean;
	loadAttachments?: (
		id?: number,
		status?: string,
		attachmentTypes?: IppPackageElement[],
		queryParameters?: Dictionary<string>
	) => Promise<void>;
}

const IppPackageItemAttachments: FC<Props> = (props: Props) => {
	const [attachmentTypes, setAttachmentTypes] = useState(props.attachmentTypes);

	useEffect(() => {
		setAttachmentTypes(props.attachmentTypes);
	}, [props]);

	return (
		<>
			{attachmentTypes &&
				attachmentTypes.map((attachment, index) => (
					<CollapsibleCard
						key={index}
						className={props.className ? props.className : ''}
						accordionType={`attachment-${index.toString()}`}
						accordionHeading={
							<span>
								{attachment.reportElementTypeName}
								{attachment.isRequired &&
									String.equalCaseInsensitive(props.reportStatus, ReportPackageStatus.Draft) && (
										<span className="ai-required">{` (${localizationService.getLocalizedString(
											'ipp.reportPackage.package.packageElement.isRequired'
										)})`}</span>
									)}
							</span>
						}>
						<IppPackageItemAttachmentFiles
							showSearch={props.showSearch}
							showFilter={props.showFilter}
							loadAttachments={props.loadAttachments}
							attachmentTypes={attachmentTypes}
							reportStatus={props.reportStatus}
							onChangeAttachment={props.onChangeAttachment}
							attachmentTypeId={attachment.reportPackageElementTypeId}
							files={attachment.ippFileStores}
							key={index}
						/>
					</CollapsibleCard>
				))}
		</>
	);
};

export default IppPackageItemAttachments;
