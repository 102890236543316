import { IppIndustry, IppReportPackageParameter, IppReportPackageParameterGroup } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { DeleteModal, DeleteModalProp, SingleCheckbox, TextAreaInput, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { IppConstants } from 'src/constants';
import { alertService, ApplicationState } from 'src/redux';
import {
	addParameterGroup,
	deleteParameterGroup,
	IppReportPackageParameterGroupState,
	loadParameterGroupDetails,
	updateParameterGroupDetails
} from 'src/redux/ipp/authority/report-packages/parameter-groups';
import { localizationService, navigateTo, Resource, urlService, validationService } from 'src/services';
import { AvailableSelectedGrids } from '../../templates/template-details';
import { industries, parametersColumns } from './parameters-columns';

interface Match {
	params: {
		id?: number;
		type?: string;
	};
}

interface ParametersGridsData {
	available: IppReportPackageParameter[];
	selected: IppReportPackageParameter[];
}
interface ParameterGroupAssignmentGridData {
	available: IppIndustry[];
	selected: IppIndustry[];
}

interface DispatchProps {
	loadParameterGroupDetails: (id?: number) => Promise<any>;
	updateParameterGroupDetails: (
		parameterGroupDetails: IppReportPackageParameterGroup
	) => Promise<boolean | undefined>;
	addParameterGroup: (
		parameterGroupDetails: IppReportPackageParameterGroup,
		callback: (id: number) => void
	) => Promise<boolean | undefined>;
	deleteParameterGroup: (parameterGroupId: number) => Promise<boolean | undefined>;
}

interface Props extends DispatchProps {
	history: any;
	match: Match;
	availableParameters: IppReportPackageParameter[];
	availableParameterGroupAssignments: IppIndustry[];
	parameterGroupDetails: IppReportPackageParameterGroup;
}

interface FormFields {
	name?: string;
	nameError?: string;
}
const initialFormFields: FormFields = {
	name: '',
	nameError: ''
};

const initialParameterGroup: IppReportPackageParameterGroup = {
	name: '',
	description: '',
	parameters: [],
	parameterGroupAssignments: [],
	isActive: false
};

const { fieldCharLimit } = IppConstants;

const IppReportPackageParameterGroupDetailsComponent: FC<Props> = (props: Props) => {
	const [currentParameterGroup, setCurrentParameterGroup] = useState(initialParameterGroup);
	const [availableParameters, setAvailableParameters] = useState([] as IppReportPackageParameter[]);
	const [availableParameterGroupAssignments, setAvailableParameterGroupAssignments] = useState([] as IppIndustry[]);
	const [deleteParameterGroupModal, setDeleteParameterGroupModal] = useState(false);
	const [formState, setFormState] = useState(initialFormFields);
	const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);

	const onChangeDetails = (e: any) => {
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		setCurrentParameterGroup({
			...currentParameterGroup,
			[name]: value
		});
	};

	// Fetch data on component mount
	const loadDetails = async () => {
		let response = await props.loadParameterGroupDetails(props.match.params.id);
		if (
			response &&
			response.statusCode === 403 &&
			String.equalCaseInsensitive(
				response.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			alertService.clearAllMessages();
			setIsAccessDenied(true);
		}
	};

	useEffect(() => {
		loadDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Update local currentParameterGroup state when global parameterGroupDetails state changes
	useEffect(() => {
		if (Number(props.match.params.id) === Number(props.parameterGroupDetails.parameterGroupId)) {
			setCurrentParameterGroup(props.parameterGroupDetails);
		}
		if (!props.match.params.id) {
			setCurrentParameterGroup(initialParameterGroup);
		}
		setAvailableParameters(props.availableParameters);
		setAvailableParameterGroupAssignments(props.availableParameterGroupAssignments);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.parameterGroupDetails]);

	// Verify if form is having valid data
	const isFormValidateForSave = (): boolean => {
		let newState = {
			...formState,
			name: currentParameterGroup.name
		};

		validationService.validateRequiredField(
			newState,
			'name',
			'nameError',
			localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.columns.name')
		);
		let isFromValid = false;
		setFormState(newState);
		isFromValid = !validationService.hasError(newState, 'nameError');

		return isFromValid;
	};

	const onSave = () => {
		if (isFormValidateForSave()) {
			if (currentParameterGroup.parameterGroupId) {
				props.updateParameterGroupDetails(currentParameterGroup);
			} else {
				currentParameterGroup.name =
					(currentParameterGroup && currentParameterGroup.name && currentParameterGroup.name.trim()) || '';
				props.addParameterGroup(currentParameterGroup, id => {
					const url = urlService.getIppAuthorityUrl(
						`${Resource.IppReportPackageParameterGroups}/${id}/details`
					);
					navigateTo(props.history, url);
				});
			}
		}
	};

	const onParametersChange = (data: ParametersGridsData) => {
		setAvailableParameters([...data.available]);
		setCurrentParameterGroup({ ...currentParameterGroup, parameters: data.selected });
	};

	const onParameterGroupAssignmentChange = (data: ParameterGroupAssignmentGridData) => {
		setAvailableParameterGroupAssignments([...data.available]);
		setCurrentParameterGroup({ ...currentParameterGroup, parameterGroupAssignments: data.selected });
	};

	const onDelete = async () => {
		toggleDelete();

		await props.deleteParameterGroup(currentParameterGroup.parameterGroupId as number);
		navigateTo(props.history, urlService.getIppAuthorityUrl(Resource.IppReportPackageParameterGroups));
	};

	const toggleDelete = () => {
		setDeleteParameterGroupModal(!deleteParameterGroupModal);
	};

	const onDeleteParameterGroup = () => {
		setDeleteParameterGroupModal(true);
	};
	let parameterGroupDeleteModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.deleteModalTitle'),
		message: localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.deleteModalMessage'),
		showModal: deleteParameterGroupModal,
		onCancelButtonClick: toggleDelete,
		onOkayButtonClick: onDelete,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<DeleteModal {...parameterGroupDeleteModalProp} key="parameterGroupDeleteModal" />
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.detailsTitle')}
						</h1>
						<button className="btn ai-save" id="element-save" onClick={onSave}>
							{localizationService.getLocalizedString('ipp.buttons.save')}
						</button>
						{props.match.params.id && (
							<button
								className="btn ai-delete ml-2"
								id="parameter-group-delete"
								onClick={onDeleteParameterGroup}>
								{localizationService.getLocalizedString('ipp.buttons.delete')}
							</button>
						)}
					</div>
					{/* Parameter Group Information section */}
					<CollapsibleCard
						accordionType="certificationsTypeDetails"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.reportPackage.parameterGroup.groupInformationTitle'
						)}>
						<div className="w-75 mx-auto">
							<div className="form-row">
								<TextInput
									id="name"
									name="name"
									label={localizationService.getLocalizedString(
										'ipp.reportPackage.parameterGroup.columns.name'
									)}
									isRequired={true}
									error={formState.nameError}
									className="col-md-10"
									value={currentParameterGroup ? currentParameterGroup.name : ''}
									onChange={onChangeDetails}
									remainingInputProps={{ maxLength: fieldCharLimit.parameterName }}
								/>
								<div className="col-md-1 d-flex">
									<TooltipHover
										id="element-name-info"
										title={localizationService.getLocalizedString(
											'ipp.reportPackage.parameterGroup.nameTooltip'
										)}
										className="report-details-tooltip"
									/>
								</div>
								<div className="form-row">
									<SingleCheckbox
										id="active"
										name="isActive"
										label={localizationService.getLocalizedString(
											'ipp.reportPackage.parameterGroup.active'
										)}
										className="align-self-center mt-2 ml-1"
										checked={currentParameterGroup.isActive || false}
										onChange={onChangeDetails}
									/>
								</div>
							</div>
							<div className="form-row">
								<TextAreaInput
									id="description"
									name="description"
									label={localizationService.getLocalizedString(
										'ipp.reportPackage.parameterGroup.columns.description'
									)}
									className="col-md-10"
									value={currentParameterGroup ? currentParameterGroup.description : ''}
									onChange={onChangeDetails}
									maxLength={fieldCharLimit.commentsAndDescription}
								/>
							</div>
						</div>
					</CollapsibleCard>
					{/* Parameter Group Information section ends here */}

					{/* Parameters section */}
					<CollapsibleCard
						className="mt-3"
						accordionType="parameters"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.reportPackage.parameterGroup.parameters.title'
						)}>
						<AvailableSelectedGrids<IppReportPackageParameter>
							columns={parametersColumns}
							available={availableParameters}
							selected={currentParameterGroup.parameters || []}
							resourceKey="ipp.reportPackage.parameterGroup.parameters.columns"
							onChange={onParametersChange}
							availableSortable={true}
							isDraggable={false}
							selectedSortable={true}
							showSearch={true}
							id="parameters"
						/>
					</CollapsibleCard>
					{/* Parameters section ends here */}

					{/* Parameter Group Assignments Section */}
					<CollapsibleCard
						accordionType="assign-industries"
						accordionHeading="Assign Industries"
						className="mt-3">
						<div id="industries-subtitle">
							<p>
								{localizationService.getLocalizedString(
									'ipp.reportPackage.parameterGroup.assignedIndustriesSubtitle'
								)}
							</p>
						</div>
						<AvailableSelectedGrids<IppIndustry>
							columns={industries}
							noRecordMsg={{
								available: localizationService.getLocalizedString(
									'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableIndustries'
								),
								selected: localizationService.getLocalizedString(
									'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedIndustries'
								)
							}}
							available={availableParameterGroupAssignments}
							selected={currentParameterGroup.parameterGroupAssignments || []}
							resourceKey="ipp.reportPackage.templateAssignment"
							onChange={onParameterGroupAssignmentChange}
							isDraggable={false}
							availableSortable={true}
							selectedSortable={true}
							showAddAll={true}
							showRemoveAll={true}
							showSearch={true}
							id="industries"
						/>
					</CollapsibleCard>
				</div>
			</div>
		</>
	);
};
const mapStateToProps = (state: ApplicationState): IppReportPackageParameterGroupState => {
	return { ...state.ippReportPackageParameterGroupDetails };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadParameterGroupDetails: (id?: number) => dispatch(loadParameterGroupDetails(id)),
		updateParameterGroupDetails: (parameterGroupDetails: IppReportPackageParameterGroup) =>
			dispatch(updateParameterGroupDetails(parameterGroupDetails)),
		deleteParameterGroup: (id: number) => dispatch(deleteParameterGroup(id)),
		addParameterGroup: (parameterGroup: IppReportPackageParameterGroup, callback: (id: number) => void) =>
			dispatch(addParameterGroup(parameterGroup, callback))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppReportPackageParameterGroupDetailsComponent);
