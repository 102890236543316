import { IppFileStore, IppIndustrySample, IppReportPackage } from '@rcp/types';
import { IppRepudiateReportPackage } from '@rcp/types/src';
import { ThunkAction } from 'redux-thunk';
import { alertService } from 'src/redux/alert';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import {
	createReportPackage,
	loadAttachmentsRequest,
	loadAttachmentsSuccess,
	loadPackageRequest,
	loadPackageSuccess,
	loadSamplesRequest,
	loadSamplesSuccess,
	PackageAction,
	resetReportPackage
} from './packages-actions';
import { PackageState } from './packages-state';

type PackageThunkAction = ThunkAction<any, PackageState, any, PackageAction>;

export const loadPackageDetails = (id?: any): PackageThunkAction => async dispatch => {
	dispatch(loadPackageRequest());

	let url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackages, id);
	let packageDetails = await apiService.getResource<IppReportPackage>(url);

	dispatch(loadPackageSuccess({ ...packageDetails }));
	return packageDetails;
};

export const loadAttachments = (queryString: string = ''): PackageThunkAction => async (dispatch, getState) => {
	dispatch(loadAttachmentsRequest());

	let url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppReportPackages}/AttachmentFiles`, queryString);
	let attachments = await apiService.getResource<IppFileStore[]>(url);
	dispatch(loadAttachmentsSuccess([...attachments]));
	return attachments;
};

export const loadSamples = (queryString: string = ''): PackageThunkAction => async dispatch => {
	dispatch(loadSamplesRequest());

	let url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppReportPackages}/Samples`, queryString);
	let samples = await apiService.getResource<IppIndustrySample[]>(url);
	dispatch(loadSamplesSuccess([...samples]));
	return samples;
};

export const addReportPackage = (
	payload: IppReportPackage,
	callbackOnSuccess?: (data?: IppReportPackage) => void
): PackageThunkAction => async dispatch => {
	alertService.clearAllMessages();
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages);
	let reportPackage = await apiService.postResource<IppReportPackage>(url, payload);
	if (callbackOnSuccess) {
		callbackOnSuccess(reportPackage);
	}
	dispatch(createReportPackage(reportPackage));
};

export const updatePackage = (
	payload: IppReportPackage,
	callbackOnSuccess?: (data?: IppReportPackage) => void,
	showSuccessMessage: boolean = true
): PackageThunkAction => async () => {
	alertService.clearAllMessages();
	let url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackages, Number(payload.reportPackageId));
	let reportPackage = await apiService.httpPut(url, payload);
	if (callbackOnSuccess) {
		callbackOnSuccess(reportPackage);
	}
	showSuccessMessage &&
		alertService.addSuccess(
			localizationService.getLocalizedString('ipp.reportPackage.package.draftUpdateSuccessMessage')
		);
};

export const submitPackage = (id: number, payload: any): PackageThunkAction => async () => {
	let data = { items: [payload] };
	alertService.clearAllMessages();
	let url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackages, Number(id)) + '/SignAndSubmit';
	let response = await apiService.httpPost(url, data, undefined, false, true);
	return response;
};

export const repudiatePackage = (id: number, payload: IppRepudiateReportPackage): PackageThunkAction => async () => {
	alertService.clearAllMessages();
	let url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackages, Number(id)) + '/RepudiateReport';
	let response = await apiService.httpPatch(url, payload, undefined, false, true);
	return response;
};

export const resetData = (): PackageThunkAction => async dispatch => {
	dispatch(resetReportPackage());
};
