import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	loadCustomFieldDefinitions,
	CustomFieldDefinitionsState,
	useReduxDispatch
} from 'src/redux';
import { ICustomFieldItem, FogFacility, CccSite, CccHazard } from '@rcp/types';
import { TabViewField } from 'src/components/widgets';
import { customDefinedFieldService } from 'src/services';
import { CustomFieldsModal } from './custom-field-modal';
import { CccSiteCustomFieldsModal } from 'src/components/authority/backflow/sites/Details/site-custom-fields-modal';
import { CccHazardCustomFieldsModal } from 'src/components/authority/backflow/hazards/Details/hazard-custom-fields-modal';
import { Utils } from 'src/services/utils';

interface StateProps extends FogFacilityState {
	customFieldDefinitions: CustomFieldDefinitionsState;
}
interface OwnProps {
	customFieldFor: string;
	customFieldParentObject: FogFacility | CccSite | CccHazard;
}

type Props = OwnProps & StateProps;

const CustomFieldsTabComp: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [isToggle, setIsToggle] = React.useState(false);
	const [customFields, setCustomFields] = React.useState(new Array<ICustomFieldItem>());

	useEffect(() => {
		if (props.customFieldFor === 'fog') {
			dispatch(loadCustomFieldDefinitions('tFogFacility'));
		}
		if (props.customFieldFor === 'cccSite') {
			dispatch(loadCustomFieldDefinitions('tCccSite'));
		}
		if (props.customFieldFor === 'cccHazard') {
			dispatch(loadCustomFieldDefinitions('tCccHazard'));
		}
	}, [dispatch, props.customFieldFor]);

	const sortFogFacilityCustomField = (positionCustomField: any[]) => {
		let positionFogCustomField = positionCustomField.filter(field => {
			return field.appliedToTable === 'tFogFacility';
		});
		return [...positionFogCustomField];
	};

	useEffect(() => {
		if (
			!_.isEmpty(props.customFieldDefinitions) &&
			!_.isEmpty(props.customFieldDefinitions.customFieldDefinitions) &&
			!_.isEmpty(props.customFieldParentObject)
		) {
			let customFields = customDefinedFieldService.toCustomFieldItems(
				props.customFieldDefinitions.customFieldDefinitions,
				props.customFieldParentObject
			);

			let positionCustomField = Utils.sortCustomField(customFields);
			if (props.customFieldFor === 'fog') {
				positionCustomField = sortFogFacilityCustomField(positionCustomField);
			}
			setCustomFields(positionCustomField);
		}
	}, [props.customFieldDefinitions, props.customFieldParentObject]);

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					{customFields.map((customField: ICustomFieldItem, index: number) => {
						return (
							<TabViewField
								key={'customField-' + index}
								title={customField.label}
								value={customField.originalValue}
								customFieldDateType={customField.fieldDataType}
							/>
						);
					})}
				</div>
				<div className="ml-3">
					<a href="#/" id="editCustomField" onClick={onEditClicked}>
						Edit
					</a>
				</div>
			</div>
			{props.customFieldFor === 'fog' && (
				<CustomFieldsModal
					sortSiteFacilityCustomField={sortFogFacilityCustomField}
					isToggle={isToggle}
					toggle={toggle}
				/>
			)}
			{props.customFieldFor === 'cccSite' && (
				<CccSiteCustomFieldsModal
					isToggle={isToggle}
					currentCccSite={props.customFieldParentObject}
					toggle={toggle}
				/>
			)}
			{props.customFieldFor === 'cccHazard' && (
				<CccHazardCustomFieldsModal
					isToggle={isToggle}
					currentCccHazard={props.customFieldParentObject}
					toggle={toggle}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, customFieldDefinitions: state.customFieldDefinitions };
};

export const CustomFieldsTab = connect<StateProps, null, {}, ApplicationState>(mapStateToProps)(CustomFieldsTabComp);
