import { faEdit, faSave, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropDownOption, MfaQuestion } from '@rcp/types';
import { QuestionAnswerDtos } from '@rcp/types/src';
import React, { FC, useEffect, useState } from 'react';
import { IppConstants } from 'src/constants';
import { apiService, tokenService, urlService, validationService } from 'src/services';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';

interface Props {
	questionLabel: string;
	answerLabel: string;
	answer: string;
	type: QuestionType;
	questions: any;
	onQuestionChange?: (question: MfaQuestion) => void;
	showEditButton?: boolean;
	onChangeAnswer?: (e: any) => void;
	disableQuestion?: boolean;
	hideAnswer?: boolean;
	selectedQuestion?: QuestionAnswerDtos;
	userQuestionAnswerId?: number;
	hideQuestion?: boolean;
	answerType?: string;
	questionError?: string;
	answerError?: string;
}

export enum QuestionType {
	KBQ = 'KBQ',
	Security = 'SQ'
}

interface Error {
	questionError?: string;
	answerError?: string;
}

const { fieldCharLimit } = IppConstants;

const Question: FC<Props> = (props: Props) => {
	const [questions, setQuestions] = useState([] as DropDownOption[]);
	const [error, setError] = useState({} as Error);
	const [answer, setAnswer] = useState(undefined);
	const [isDisabled, setIsDisabled] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState({} as QuestionAnswerDtos);
	const [prevAnswer, setPrevAnswer] = useState(undefined);
	const [prevSelectedQuestion, setPrevSelectedQuestion] = useState({} as QuestionAnswerDtos);
	const [isAnswerChanged, setIsAnswerChanged] = useState(false);
	const [isQuestionChanged, setIsQuestionChanged] = useState(false);
	useEffect(() => {
		if (props.answer) {
			setAnswer(props.answer as any);
			!isAnswerChanged && setPrevAnswer(props.answer as any);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.answer]);

	useEffect(() => {
		if (props.selectedQuestion) {
			setSelectedQuestion(props.selectedQuestion);
			!isQuestionChanged && setPrevSelectedQuestion(props.selectedQuestion);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedQuestion]);

	useEffect(() => {
		setIsDisabled(props.showEditButton !== undefined ? props.showEditButton : false);
	}, [props.showEditButton]);

	useEffect(() => {
		let questions = [...props.questions];
		let options: DropDownOption[] =
			questions &&
			questions.map((question: any) => ({
				label: question.content || question.question,
				value: question.questionId
			}));
		setQuestions(options);
	}, [props.questions]);

	const isFormValidForSave = (): boolean => {
		let newState = { ...error, answer };
		if (props.questionError === undefined && props.answerError === undefined && !isDisabled) {
			validationService.validateRequiredField(newState, 'answer', 'answerError', props.answerLabel);
		}
		setError(newState);
		const isFromValid = !validationService.hasError(newState, 'answerError');

		return isFromValid;
	};
	useEffect(() => {
		if (answer !== undefined) {
			isFormValidForSave();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [answer]);

	const removeSpace = (str: string) => {
		return str.replace(/\s/g, '');
	};

	const saveQuestion = async () => {
		isFormValidForSave();
		if (answer) {
			setError({ ...error, questionError: undefined, answerError: undefined });
			let patchData = {
				answer,
				userQuestionAnswerId: props.userQuestionAnswerId,
				questionId: selectedQuestion.questionId
			};
			let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile/QuestionAnswer?organizationRegulatoryProgramId=${
				tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
			}`;
			let response = await apiService.httpPatch(url, patchData);
			if (response.validationErrors.length > 0) {
				response.validationErrors[0] && setError({ ...error, questionError: response.validationErrors[0] });
			} else {
				setError({ ...error, questionError: undefined, answerError: undefined });
				setIsDisabled(true);
				setPrevAnswer(answer);
				setPrevSelectedQuestion(selectedQuestion);
			}
		}
	};

	const undoChanges = () => {
		setError({});
		setIsDisabled(true);
		setAnswer(prevAnswer);
		setIsAnswerChanged(false);
		setSelectedQuestion(prevSelectedQuestion);
		setIsQuestionChanged(false);
	};

	return (
		<>
			<div className="form-row">
				{!props.hideQuestion && (
					<SingleSelectDropdown
						id={`${props.type}-${removeSpace(props.questionLabel)}`}
						name={`${props.type}${removeSpace(props.questionLabel)}`}
						label={props.questionLabel}
						noEmptyOption={true}
						value={
							selectedQuestion.questionId
								? selectedQuestion.questionId
								: props.questions[0]
								? props.questions[0].questionId
								: ''
						}
						options={questions}
						selfOrder={true}
						className={props.showEditButton ? 'col-md-11' : 'col-md-12'}
						{...(props.onQuestionChange && {
							onChange: event => {
								let selectedQuestion = props.questions.find(
									(question: any) => Number(question.questionId) === Number(event.target.value)
								);
								setIsQuestionChanged(true);
								setSelectedQuestion(selectedQuestion);
								props.onQuestionChange && props.onQuestionChange(selectedQuestion || {});
							}
						})}
						isDisabled={props.disableQuestion || isDisabled}
					/>
				)}
				{props.showEditButton && (
					<div className="col-md-1 d-flex align-items-center">
						{isDisabled && (
							<FontAwesomeIcon
								className="font-awesome-icon"
								icon={faEdit}
								onClick={() => {
									setIsDisabled(false);
									props.hideAnswer && setAnswer(undefined);
								}}
							/>
						)}
						{!isDisabled && (
							<>
								<FontAwesomeIcon className="font-awesome-icon" icon={faSave} onClick={saveQuestion} />
								<FontAwesomeIcon
									className="font-awesome-icon m-3"
									icon={faUndo}
									onClick={() => undoChanges()}
								/>
							</>
						)}
					</div>
				)}
			</div>
			<div className="form-row mt-2">
				<TextInput
					id={`${props.type}-${removeSpace(props.answerLabel)}`}
					name={`${props.type}-${removeSpace(props.answerLabel)}`}
					label={props.answerLabel}
					className={props.showEditButton ? 'col-md-11' : 'col-md-12'}
					isDisabled={isDisabled}
					isRequired={true}
					value={isDisabled && props.hideAnswer ? '*****' : answer}
					error={error.questionError || error.answerError || props.answerError || props.questionError}
					type={props.answerType ? props.answerType : 'text'}
					onChange={e => {
						setIsAnswerChanged(true);
						setAnswer(e.target.value as any);
						props.onChangeAnswer && props.onChangeAnswer(e);
					}}
					remainingInputProps={{ maxLength: fieldCharLimit.answer }}
				/>
			</div>
		</>
	);
};

export default Question;
