import React from 'react';

import { apiService, localizationService, Resource, urlService, UtilService, validationService } from 'src/services';
import { ApiError, SystemSettingValue, RegulatoryProgramName } from '@rcp/types';
import { alertService } from 'src/redux/alert';

import _ from 'lodash';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { useReduxDispatch, loadUserProfile, useReduxSelector } from 'src/redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { SingleCheckbox, TextAreaInput } from 'src/components';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

export const DataConnectorNoticesComponent: React.FunctionComponent = () => {
	const [errorEmailSetting, setErrorEmailSetting] = React.useState({} as SystemSettingValue);
	const [errorObject, setErrorObject] = React.useState({ error: '' });

	const errorEmailSettingsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.SystemSettings}/${
		Resource.ACPDCErrorEmailRecipients
	}`;
	const dispatch = useReduxDispatch();

	const currentUser = useReduxSelector(state => state.userProfile);

	React.useEffect(() => {
		dispatch(loadUserProfile());
	}, [dispatch]);

	React.useEffect(() => {
		loadErrorEmailSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadErrorEmailSetting = async () => {
		apiService.getResource<SystemSettingValue>(errorEmailSettingsUrl).then((res: SystemSettingValue) => {
			setErrorEmailSetting(res);
		});
	};

	const onEmailListChanged = (e: any) => {
		let newSetting = { ...errorEmailSetting };
		const { value } = e.target;
		_.set(newSetting, 'value', value);
		setErrorEmailSetting(newSetting);
	};

	const updateEmailList = (e: any) => {
		if (!validationService.validateCommaSeparatedEmailValues(errorEmailSetting.value, errorObject, 'error')) {
			setErrorObject({
				error: localizationService.getLocalizedString('screen.validationMessage.invalidCommaSeparatedEmailList')
			});
			return;
		}

		apiService
			.postResourceObject(errorEmailSettingsUrl, errorEmailSetting)
			.then(() => {})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
			});
	};

	return (
		<div className="page">
			{currentUser.userProfile.isInternalAccount ? (
				<>
					<div className="page-header">
						{_.toLower(urlService.getCurrentProgramOrDefault()) ===
							_.toLower(RegulatoryProgramName.FOG) && <BackArrow />}
						<h1>
							{localizationService.getLocalizedString('systemSupportOnlySettings.dataConnectorNotices')}
						</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">{getEmailList()}</div>
					</div>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</div>
	);

	function getEmailList() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<p>
						{localizationService.getLocalizedString(
							'systemSupportOnlySettings.dataConnectorNoticesDescription'
						)}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<TextAreaInput
							id="emailListText"
							name="emailListText"
							label={localizationService.getLocalizedString('systemSupportOnlySettings.emailList')}
							isFullWidth={true}
							value={errorEmailSetting.value}
							rows={4}
							onBlur={updateEmailList}
							onEnterKeyPressed={updateEmailList}
							onChange={onEmailListChanged}
							maxLength={500}
							hintText={localizationService.getLocalizedString('systemSupportOnlySettings.emailListHint')}
							error={errorObject.error}
						/>
					</section>
				</div>
			</div>
		);
	}
};
