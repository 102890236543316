import React from 'react';
import { useDispatch } from 'react-redux';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	LabelList
} from 'recharts';
import { ApiError, BarChartData, DashboardWidget, Dictionary } from '@rcp/types';
import { apiService, localizationService, Logger, urlService } from 'src/services';
import { alertService } from 'src/redux';
import _ from 'lodash';
import { chartService, ColorScheme, DateFilterValues } from './chart-service';

import './chart.scss';
import { nameof } from 'ts-simple-nameof';
import { GotoGridButton } from './goto-grid-button';
import { DefaultDateFilterSelect } from './default-date-filter-select';
import { RenderXTick } from './customized-chart-renders';

interface OwnProps {
	dashboardWidget: DashboardWidget;
	onchange?: (widget: DashboardWidget) => void;
}
type Props = OwnProps;

interface ChartSetting {
	lookIn?: string;
	groupBy?: string;
	filterGroup?: string;
	method: string;
	filterId: number;
	filterName?: string;
	dateFilter: DateFilterValues;
	withDateFilter: boolean;
	withButton: boolean;
	//look and feel
	colorScheme: ColorScheme;
	colors: string[];
	tooltip: boolean;
	legend: boolean;
	barLabel: boolean;
	height: number; //Width are responsive
}
const initialChartSetting: ChartSetting = {
	method: 'Count',
	filterId: 0,
	dateFilter: DateFilterValues.Last30Days,
	withDateFilter: true,
	withButton: true,
	//look and feel
	colorScheme: ColorScheme.Green,
	colors: [],
	tooltip: true,
	legend: true,
	barLabel: true,
	height: 320
};

interface ChartResponseData {
	isLoading: boolean;
	barFilterGroup?: string;
	barGroups: string[];
	total: number;
	numberIgnored: number;
	data: any[];
}
const initialChartResponseData: ChartResponseData = {
	isLoading: true,
	barGroups: [],
	total: 0,
	numberIgnored: 0,
	data: []
};

export const BarChartWidget: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [setting, setSetting] = React.useState<ChartSetting>(initialChartSetting);
	const [responseData, setResponseData] = React.useState<ChartResponseData>(initialChartResponseData);

	const handleResult = (result: BarChartData) => {
		let barGroups = result.barGroups;
		let length = _.isEmpty(barGroups) ? 0 : barGroups.length;
		if (length < 1 && result.totalSamples && result.totalSamples <= 0) {
			setResponseData(initialChartResponseData);
			return;
		}
		let data: any[] = [];
		_.forEach(result.aggregationResult, (values: number[], key: string) => {
			let bar: Dictionary<any> = {};
			bar[result.groupBy] = key;
			if (result.numberOfIgnoredSamples > 0 && key === '[NULL]') {
				bar[result.groupBy] = 'Unassigned';
			}
			for (let index = 0; index < result.barGroups.length; index++) {
				let label = result.barGroups[index];
				bar[label] = values[index];
			}
			data.push(bar);
		});

		var newData: ChartResponseData = {
			...responseData,
			isLoading: false,
			barFilterGroup: result.barFilterGroup,
			barGroups: result.barGroups,
			total: result.totalSamples,
			numberIgnored: result.numberOfIgnoredSamples,
			data: data
		};
		Logger.debug('bar chart data:', newData);
		setResponseData(newData);
	};

	const loadChartData = (setting: ChartSetting) => {
		let parameters: Dictionary<string> = {};
		parameters[nameof<ChartSetting>(s => s.groupBy)] = setting.groupBy as string;
		parameters[nameof<ChartSetting>(s => s.filterGroup)] = setting.filterGroup as string;
		parameters[nameof<ChartSetting>(s => s.method)] = setting.method as string;
		if (setting.filterId) {
			parameters[nameof<ChartSetting>(s => s.filterId)] = _.toString(setting.filterId);
		}
		let dateFilterKey = chartService.getDateFilterField(setting.lookIn);
		let dateFilterValue = chartService.getDateFilterQueryParameterValue(setting.dateFilter);
		if (dateFilterKey && dateFilterValue) {
			parameters[dateFilterKey] = dateFilterValue;
		}
		let queryString = urlService.toQueryString(parameters);
		let url = `${urlService.getApiBaseUrlWithProgram()}/Charts/Bar/${setting.lookIn}?${queryString}`;
		setResponseData({ ...responseData, isLoading: true, data: initialChartResponseData.data });
		apiService
			.getResource<BarChartData>(url)
			.then(handleResult)
			.catch((e: ApiError) => {
				alertService.addError(e.message);
			});
	};

	const changeDateFilter = (e: any) => {
		const { value } = e.target;
		if (props.onchange) {
			props.onchange({ dateFilterValue: value });
		}
		if (value !== setting.dateFilter) {
			var newRequestSetting = { ...setting, dateFilter: value };
			setSetting(newRequestSetting);
		}
	};

	React.useEffect(() => {
		if (props.dashboardWidget) {
			const { filterId, filterName } = chartService.getFirstFilter(props.dashboardWidget.dashboardWidgetFilters);
			const properties = props.dashboardWidget.properties ? JSON.parse(props.dashboardWidget.properties) : {};
			const dateFilterValue = props.dashboardWidget.dateFilterValue || initialChartSetting.dateFilter;
			const colors = _.isEmpty(properties.colors)
				? chartService.getColorScheme(setting.colorScheme as ColorScheme)
				: properties.colors;

			const newSetting: ChartSetting = {
				...setting,
				...properties,
				colors: colors,
				dateFilter: dateFilterValue,
				filterId: _.isNumber(filterId) && filterId > 0 ? filterId : undefined,
				filterName: filterName
			};
			setSetting(newSetting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.dashboardWidget]);

	React.useEffect(() => {
		if (setting.lookIn) {
			loadChartData(setting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setting.lookIn, setting.dateFilter]);

	const renderBarLabel = (props: any) => {
		const { x, y, width, value } = props;
		const radius = 10;

		return (
			<g>
				<text
					x={x + width / 2}
					y={y - radius}
					className="bar-float-label"
					textAnchor="middle"
					dominantBaseline="middle">
					{value}
				</text>
			</g>
		);
	};

	return (
		<div className="chart-spaces">
			{_.isEmpty(responseData.data) ? (
				responseData.isLoading ? (
					<div style={{ height: setting.height }}></div>
				) : (
					<div className="populated-chart">{localizationService.getLocalizedString('dashboard.noData')}</div>
				)
			) : (
				<ResponsiveContainer width="100%" height={setting.height + (setting.legend ? 40 : 0)}>
					<BarChart
						width={400}
						height={setting.height}
						margin={{
							top: 20,
							right: 0,
							left: 0,
							bottom: 5
						}}
						data={responseData.data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							interval={0}
							scale="band"
							dataKey={setting.groupBy}
							tick={<RenderXTick setting={setting} />}
							height={80}
						/>
						<YAxis>
							<Label
								value={chartService.getYAxisLabel(setting.lookIn)}
								position="insideLeft"
								angle={-90}
								className="bar-y-axis-label"
								style={{ textAnchor: 'middle' }}
							/>
						</YAxis>
						{setting.tooltip && <Tooltip wrapperStyle={{ zIndex: chartService.tooltipZIndex }} />}
						{setting.legend && <Legend verticalAlign="bottom" />}
						{responseData.barGroups &&
							responseData.barGroups.map((barGroup, index) => {
								return (
									<Bar
										key={index}
										dataKey={barGroup}
										fill={setting.colors[index % setting.colors.length]}
										minPointSize={1}>
										{setting.barLabel && <LabelList dataKey={barGroup} content={renderBarLabel} />}
									</Bar>
								);
							})}
					</BarChart>
				</ResponsiveContainer>
			)}
			<div className="row mt-3">
				{setting.withDateFilter && (
					<div className="col-4">
						<DefaultDateFilterSelect
							dashboardWidgetId={props.dashboardWidget.dashboardWidgetId}
							value={setting.dateFilter}
							onChange={changeDateFilter}></DefaultDateFilterSelect>
					</div>
				)}
				{setting.withButton && (
					<div className="col">
						<GotoGridButton lookIn={setting.lookIn} />
					</div>
				)}
			</div>
		</div>
	);
};
