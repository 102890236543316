import {
	IppAttachmentType,
	IppAvailableCtsEventType,
	IppIndustry,
	IppReportPackageTemplate,
	IppReportPackageTemplateElement
} from '@rcp/types';
import { Action } from 'redux';

export enum ReportPackageTemplateActionType {
	LoadReportPackageTemplateRequest = 'loadReportPackageTemplateRequest',
	LoadReportPackageTemplateSuccess = 'loadReportPackageTemplateSuccess',
	AddReportPackageTemplate = 'addReportPackageTemplate',
	UpdateReportPackageTemplate = 'updateReportPackageTemplate',
	DeleteReportPackageTemplate = 'deleteReportPackageTemplate'
}

export interface LoadReportPackageTemplateRequest extends Action {
	type: ReportPackageTemplateActionType.LoadReportPackageTemplateRequest;
}

export interface LoadReportPackageTemplateSuccess extends Action {
	type: ReportPackageTemplateActionType.LoadReportPackageTemplateSuccess;
	templateDetails: IppReportPackageTemplate;
	availableSamplesAndResultsTypes: IppReportPackageTemplateElement[];
	availableAttachmentTypes: IppAttachmentType[];
	availableCertificationTypes: IppAttachmentType[];
	availableReportPackageTemplateAssignments: IppIndustry[];
	availableCtsEventTypes: IppAvailableCtsEventType[];
}

export interface AddReportPackageTemplateActon extends Action {
	type: ReportPackageTemplateActionType.AddReportPackageTemplate;
}

export interface UpdateReportPackageTemplateActon extends Action {
	type: ReportPackageTemplateActionType.UpdateReportPackageTemplate;
}

export interface DeleteReportPackageTemplateActon extends Action {
	type: ReportPackageTemplateActionType.DeleteReportPackageTemplate;
}

export type ReportPackageTemplateAction =
	| LoadReportPackageTemplateRequest
	| LoadReportPackageTemplateSuccess
	| AddReportPackageTemplateActon
	| UpdateReportPackageTemplateActon
	| DeleteReportPackageTemplateActon;

export const loadReportPackageTemplateRequest = (): LoadReportPackageTemplateRequest => ({
	type: ReportPackageTemplateActionType.LoadReportPackageTemplateRequest
});

export const loadReportPackageTemplateSuccess = (
	templateDetails: IppReportPackageTemplate,
	availableSamplesAndResultsTypes: IppReportPackageTemplateElement[],
	availableAttachmentTypes: IppAttachmentType[],
	availableCertificationTypes: IppAttachmentType[],
	availableReportPackageTemplateAssignments: IppIndustry[],
	availableCtsEventTypes: IppAvailableCtsEventType[]
): LoadReportPackageTemplateSuccess => ({
	type: ReportPackageTemplateActionType.LoadReportPackageTemplateSuccess,
	templateDetails,
	availableSamplesAndResultsTypes,
	availableAttachmentTypes,
	availableCertificationTypes,
	availableReportPackageTemplateAssignments,
	availableCtsEventTypes
});

export const addReportPackageTemplateActon = (): AddReportPackageTemplateActon => ({
	type: ReportPackageTemplateActionType.AddReportPackageTemplate
});

export const updateReportPackageTemplateActon = (): UpdateReportPackageTemplateActon => ({
	type: ReportPackageTemplateActionType.UpdateReportPackageTemplate
});

export const deleteReportPackageTemplateActon = (): DeleteReportPackageTemplateActon => ({
	type: ReportPackageTemplateActionType.DeleteReportPackageTemplate
});
