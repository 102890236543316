import React, { useState } from 'react';
import _ from 'lodash';
import { localizationService, navigateTo, urlService } from 'src/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { Extractor, LetterTemplateCategory } from '@rcp/types';
import { FacilitySendEmail } from '../../facilities/facility-details/send-email';
import { useHistory } from 'react-router';
import { DomainSvg } from 'src/components/svg/domain-svg';

interface OwnProps {
	device: Extractor;
}

type Props = OwnProps;

export const DeviceOwnerCard: React.SFC<Props> = props => {
	const [sendEmailModal, setSendEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState([] as any[]);
	const history = useHistory();
	const sendEmailPopUp = (address: string, name: string) => {
		setSendEmailModal(true);
		const selectEmail: any[] = [
			{
				name,
				address,
				selected: true
			}
		];
		setSelectedEmail(selectEmail);
	};
	const toggleSendEmailModal = () => {
		setSendEmailModal(!sendEmailModal);
	};
	const emailContent = () => {
		return (
			<div>
				<a
					id="facility-hauler-email"
					className="ai-link contact-email"
					onClick={() => {
						props.device.facilityEmail &&
							props.device.facilityContactName &&
							sendEmailPopUp(props.device.facilityEmail, props.device.facilityContactName);
					}}>
					{props.device.facilityEmail}
				</a>
			</div>
		);
	};

	return (
		<section>
			<div id="deviceOwner" className="d-flex">
				<h3>{localizationService.getLocalizedString('extractor.deviceOwner')}</h3>
			</div>

			<div className="media" key={'DeviceOwnerCard-' + props.device.extractorId}>
				<div className="align-self-start mr-3 ">
					<DomainSvg />
				</div>

				<div className="media-body">
					<div className="wrapWord">
						<div className="flex-column w-100 mr-3">
							<div className="mb-1">
								<span className="font-size-16px-regular mr-1">
									<a
										href={urlService.getFacilityDetailsUrl(props.device.facilityId)}
										onClick={(e: any) =>
											navigateTo(
												history,
												urlService.getFacilityDetailsUrl(props.device.facilityId),
												e
											)
										}>
										{props.device.facilityName}
									</a>
								</span>
							</div>
							<div>
								{props.device.facilityContactName && (
									<>
										{props.device.facilityContactName}
										<br />
									</>
								)}
								{props.device.addressLine1 && (
									<>
										{props.device.addressLine1}
										{props.device.addressLine2 && <>, {props.device.addressLine2}</>}
										<br />
									</>
								)}
								{(props.device.cityName || props.device.jurisdictionCode || props.device.zipCode) && (
									<>
										{(
											_.toString(props.device.cityName) +
											' ' +
											_.toString(props.device.jurisdictionCode) +
											' ' +
											_.toString(props.device.zipCode)
										).trim()}
									</>
								)}
							</div>
							{props.device.facilityPhone ? (
								<div className="mb-1 mt-1">
									{localizationService.getLocalizedString('haulers.phone')}:&nbsp;
									<a href={'tel:' + props.device.facilityPhone}>{props.device.facilityPhone}</a>
								</div>
							) : null}

							{props.device.facilityEmail ? emailContent() : null}
						</div>
					</div>
				</div>
			</div>
			{sendEmailModal && (
				<FacilitySendEmail
					show={sendEmailModal}
					toggle={toggleSendEmailModal}
					letterTemplateCategory={LetterTemplateCategory.DeviceEmail}
					selectedEmail={selectedEmail}
					isDeviceDetail={true}
				/>
			)}
		</section>
	);
};
