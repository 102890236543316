import { RestSlice } from 'src/redux/rest.slice';
import { FogFacility } from '@rcp/types';
import { localizationService } from 'src/services';

export const facilityContactsSlice = new RestSlice<FogFacility>(
	localizationService.getLocalizedString('screen.labels.facility') +
		localizationService.getLocalizedString('screen.labels.contacts'),
	''
);
export default facilityContactsSlice.rests.reducer;
