import React, { FC, RefObject, useContext, useEffect, useState } from 'react';
import { History } from 'history';
import { Grid, GridNoRecords } from '@progress/kendo-react-grid';

import './data-grid.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	ActionModalActionType,
	AlertMessageType,
	alertService,
	RootState,
	useReduxSelector,
	useRootStateSelector
} from 'src/redux';
import {
	ColumnDefinition,
	ColumnField,
	CustomFieldApplyToTable,
	CustomFieldDefinition,
	DashboardWidgetFilterCategories,
	Dictionary,
	DraftFilter,
	FeatureNames,
	Filter,
	LocalStorageName,
	MaxInteger,
	RouteProps,
	MaxIntegerStr
} from '@rcp/types';
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { GridPageChangeEvent } from '@progress/kendo-react-grid/dist/npm/interfaces/events';
import _ from 'lodash';
import { customFieldSlice } from '../../redux/custom-field.slice';
import {
	apiService,
	GridExtraQueryParameterKeys,
	localizationService,
	localStorageService,
	Resource,
	urlService,
	useForceUpdate
} from 'src/services';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledDropdown
} from 'reactstrap';
import { SearchInput, SingleCheckbox, SingleCheckboxProps } from '../../components/widgets';
import ColumnsEditor from '../../components/widgets/columns-editor';
import { RestSlice, RestState } from '../../redux/rest.slice';
import { FilterActionMenu, FilterEditModal } from '../filter';
import { FilterQuickViewer } from '../filter/filter-quick-viewer';
import classNames from 'classnames';
import { FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { Utils } from 'src/services/utils';
import { CloseSvg } from 'src/components';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { InlineAlertItem } from '../inline-alerts/inline-alert';
import { ReactComponent as MapSvg } from '../../assets/img/map-solid.svg';
import { Link } from 'react-router-dom';
import { Translate } from 'src/components/widgets/translate/translator';
import { translateService } from 'src/services/translate-service';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';

export const customFieldQueries = {
	Fog: `size=${MaxInteger}&appliedToTable=in:tFogFacility`,
	Backflow: `size=${MaxInteger}&appliedToTable=in:tCccSite,tCccHazard`
};
export interface GridState {
	activeTab?: string;
	sort?: SortDescriptor[];
	search?: string;
	showEditColumnModal?: boolean;
	showBulkUpdateModal?: boolean;
	showScheduleInspectionModal?: boolean;
	queryParameters?: Dictionary<string>; //Default query parameters is undefined, and when it will be assigned value when the grid is inserted to the DOM tree.
	anchorElement?: any;
	filterId?: number;
	draftFilterId?: number;
	selectedItem?: any;
	pageTitle: string;
}

interface ButtonElements {
	id?: string;
	className?: string;
	text: string;
	action: () => void;
}
interface EditMode {
	buttonClass?: string;
	id?: string;
	editButtonText?: string;
	viewButtonText?: string;
	selectIdKey: string;
	getSelected: (ids: number[]) => void;
	customEditAction?: () => void;
	customCancelAction?: () => void;
	clearValidationErrorData?: () => void;
	populateSelected?: boolean;
	editModeMultiButtons?: ButtonElements[];
	deleteItems: boolean;
	isCustomOperationPerformed: boolean;
	validationErrorData?: any[];
}
interface Notification {
	show: boolean;
	onClose: () => void;
	message?: JSX.Element | string;
	customUI?: () => JSX.Element;
}

export interface DataGridAction {
	actionType: ActionModalActionType;
	actionLabel: string;
	toggle: () => void;
}
export interface GridOption {
	//Define a list of function entries that should visible or invisible to the user.
	pageTitle: JSX.Element | string;
	activeTab?: string; //Default active tab is tab-list
	prefix: string; //Column label localization resource prefix path. E.g. path 'sites.sites' prefix is 'sites'.
	storageName: LocalStorageName; //Grid storage name
	gridUrlLocalStorageName?: LocalStorageName; //Grid last visit url entry in local storage
	sortOrderLocalStorageName?: LocalStorageName; //Grid last sort setting in local storage

	showTabList?: Boolean; //Whether show tab. Default is not show.
	showActions?: Boolean; //Whether show Actions button. Default is not show.
	showActionExport?: Boolean; //Whether show Export to Spreadsheet action button. Default is not show.
	customActions?: DataGridAction[];
	showActionBulkUpdate?: Boolean; //Whether show Bulk Update action button. Default is not show.
	showActionScheduleInspection?: Boolean; //Whether show Schedule Inspections action button. Default is not show.
	showFilter?: Boolean; //Whether show Filters button. Default is not show.
	showEditColumnButton?: Boolean; //Whether show Edit Columns. Default is not show. Default is not show.
	showSearch?: Boolean; //Whether show search input. Default is not show.
	searchPlaceholder?: string; //Specified search input placeholder. Default is 'Search'
	searchHint?: string; //Specified search hint description under the text input. Default is not show.

	showAddButton?: boolean; //Whether show 'Add XXX' button. Default is not show.
	addButtonText?: string; //Add button text. E.g. 'Add Site'
	toggleAddModal?: () => void; //Callback to toggle add modal
	addModalComponent?: JSX.Element; //Add modal element
	queryParameters?: Dictionary<string>; //Enforced grid initial query condition. E.g. {includeInactive: '1'}
	doNotValidateParameters?: boolean;
	customFieldQuery?: string; //Set it when the grid or grid filter requires list of custom fields definitions. It is required when showFilter is turn on or customFieldsForGrid is enabled.
	customFieldsForGrid?: CustomFieldApplyToTable[]; //Set when grid requires list of custom fields. Must be part of the customFieldQueryForFilter above.
	resetFilterService?: () => void; //Callback to reset filterService. It is required when showFilter is turn on.
	defaultSort?: SortDescriptor[]; //Grid default sort column
	allColumns: ColumnField[]; //Grid column definitions
	defaultColumns: string[]; //Grid default column list.
	translateColumns?: string[]; //Grid default column list.
	noRecordMessage?: string;
	doClientSearch?: (items: [], searchTerm: string) => []; //Customize this callback if the grid do client search.
	populateComputedColumns?: (items: []) => []; //Callback to populate computed column values for display.
	filterStorageName?: string;
	showRefreshButton?: Boolean;
	doClientSideSorting?: Boolean;
	// Property(Optional) will be set to true if full width table is not required and max table class has not to be applied
	doNotApplyMaxTable?: boolean;
	refreshBtnBlockClass?: string;
	// Set this property if custom columns has to be exported otherwise columns shown in the grid will be exported only.
	exportColumns?: string[];

	//compState
	sort?: SortDescriptor[];
	showEditColumnModal?: boolean;
	showAddModal?: boolean;
	multiButtons?: ButtonElements[];
	showScheduleInspectionModal?: boolean;
	anchorElement?: any;
	enableFilter?: boolean;
	showFilterMenuPopOver?: boolean;
	showFilterEditorModal?: boolean;
	filterId?: number;
	draftFilterId?: number;
	showBulkUpdate?: boolean;
	showExportToSpreadsheet?: boolean;
	bulkUpdate?: () => void;
	isPaginatedSearch?: boolean;
	rerenderOnQueryParamChange?: boolean;
	rerenderOnGridOptionChange?: boolean;
	// Property(Optional) will be set to string for single line and string array for multi line description that has to be shown before the grid
	description?: string | string[];
	disableFilterWarning?: boolean;
	subHeading?: string | JSX.Element;
	subHeadingClass?: string;
	informativeText?: string;
	editMode?: EditMode;
	unsetPageOnFilterApply?: boolean;
	filterOnlyThisGrid?: boolean;
	showActionDownloadInvites?: boolean;
	downloadInvites?: () => void;
	notification?: Notification;
	showBackButton?: boolean;
	checkbox?: SingleCheckboxProps;
	removePageHeader?: boolean;
	doCustomClientSort?: (items: any[], sortState: any[]) => any[];
	hideCount?: boolean;
	descriptionAboveGrid?: boolean;
	isSlicePathModified?: boolean; // To be removed when customCleaningFormFeatureFlag is removed.
	hasGridMap?: boolean; // To show the location map,
	doClientPagination?: boolean;
}
export interface FilterState {
	showFilterMenuPopOver: boolean;
	showFilterEditorModal: boolean;
}
export interface Props<TModel> extends RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<TModel>;
	restState: (state: RootState) => RestState<TModel>;
	// Callback use to get query parameter from Grid into the target component
	dataGridGetQueryParameterCallback?: (ref: () => Dictionary<string>) => void;
	headerElem?: JSX.Element;
	filterElem?: JSX.Element;
	handlePageChange?: () => void;
	handleSortChange?: (state: GridState, setState: (state: GridState) => void, sort: any) => void;
	getQueryParametersFromParent?: () => Dictionary<string>;
	setQueryParametersFromParent?: (queryParameters: Dictionary<string>) => void;
	sortOrderLocalStorageName?: LocalStorageName;
	filterUrlStorageName?: string;
	prePageChange?: () => void;
	preSortChange?: () => void;
	children?: any;
	fallBackUI?: JSX.Element;
}

export const defaultGridState: GridState = {
	sort: [],
	activeTab: 'tab-list',
	anchorElement: null,
	pageTitle: 'Grid'
};
export const defaultGridOption: GridOption = {
	pageTitle: 'Grid',
	activeTab: 'tab-list',
	prefix: '',
	storageName: LocalStorageName.Unspecified,
	//Default disabled all of the grid actions, and let individual grid turn required actions on manually
	showTabList: false,
	showAddButton: false,
	showActions: false,
	showActionExport: false,
	showActionBulkUpdate: false,
	showActionScheduleInspection: false,
	showFilter: false,
	showEditColumnButton: false,
	showSearch: false,
	searchPlaceholder: localizationService.getLocalizedString('screen.labels.search'),
	searchHint: '',
	defaultSort: [],
	allColumns: [],
	defaultColumns: [],
	translateColumns: [],
	doClientSideSorting: false,
	unsetPageOnFilterApply: true
};
export interface GridContext {
	setGridState: (newState: GridState) => void;
	getGridState: () => GridState;
	gridOption: GridOption;
	gridRefresh: () => void;
}
export const DataGridContext = React.createContext<GridContext>({
	setGridState: () => {},
	getGridState: () => {
		return defaultGridState;
	},
	gridOption: defaultGridOption,
	gridRefresh: () => {}
});

export const DataGrid: FC<Props<any>> = props => {
	const gridRef: RefObject<Grid> = React.createRef<Grid>();
	const dispatch = useDispatch();
	const forceUpdate = useForceUpdate();
	const gridOption = { ...defaultGridOption, ...props.gridOption };
	const [withPaging, setWithPaging] = useState(false);
	const [customFieldsForGrid, setCustomFieldsForGrid] = useState([] as CustomFieldDefinition[]);
	const [state, setState] = useState<GridState>({
		...defaultGridState,
		activeTab: gridOption.activeTab,
		sort: gridOption.defaultSort
	});

	const showFogMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);

	const setGridState = (newState: GridState) => {
		let stateToSave = { ...state, ...newState };
		setState(stateToSave);
	};

	const getGridState = () => {
		return _.cloneDeep(state);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	let { loading, result: list, page, size, error, total, numberOfOpenInspections } = useSelector(props.restState);
	// Custom Fields State
	let { result: customFields } = useRootStateSelector(s => s.customFields);

	const initialFilterState: FilterState = { showFilterEditorModal: false, showFilterMenuPopOver: false };

	let [filterState, setFilterState] = useState(initialFilterState);

	const [isEditableGrid, setIsEditableGrid] = useState(false);

	const [selectedItems, setSelectedItems] = useState([]);
	const [selectAllItems, setSelectAllItems] = useState(false);

	const [isSingleItemDeselected, setIsSingleItemDeselected] = useState(false);

	const [gridData, setGridData] = useState([] as any[]);
	const [columns, setColumns] = useState([] as any[]);
	const [shouldTranslateGrid, setShouldTranslateGrid] = useState(false);
	const [allColumnDefinitions, setAllColumnDefinitions] = useState([] as ColumnDefinition[]);
	const [defaultColumnsDefinitions, setDefaultColumnsDefinitions] = useState([] as ColumnDefinition[]);
	const [pagination, setPagination] = useState({ skip: 0, take: 50 });
	const [searchPlaceholder, setSearchPlaceholder] = useState('');
	let { editMode } = props.gridOption;

	const language = useContext(LanguageContext);

	const validateParameters = (queryParameters: Dictionary<string>) => {
		_.each(_.keys(queryParameters), (key: string) => {
			let doesKeyFromOneOfColumn =
				!GridExtraQueryParameterKeys.includes(key.toLowerCase()) &&
				((_.toLower(key).includes(`${FilterDomainName.incident}.`) &&
					String.equalCaseInsensitive(gridOption.prefix, DashboardWidgetFilterCategories.Incidents)) ||
					(!_.toLower(key).includes(`${FilterDomainName.incident}.`) &&
						!String.equalCaseInsensitive(gridOption.prefix, DashboardWidgetFilterCategories.Incidents)));

			if (GridExtraQueryParameterKeys.includes(key.toLowerCase())) {
				doesKeyFromOneOfColumn = true;
			}

			if (!doesKeyFromOneOfColumn) {
				alertService.addWarning(
					localizationService.getLocalizedString('alertMessages.GridFieldFilteringIsNotSupported', key)
				);
				return;
			}
		});
	};

	const initializeQueryParameters = () => {
		//reset filterService
		if (gridOption.resetFilterService) {
			gridOption.resetFilterService();
		}
		//Initial grid state.queryParameter
		let queryParameters: Dictionary<string> = urlService.toQueryDictionary();
		if (gridOption.doNotValidateParameters !== true) {
			validateParameters(queryParameters);
		}
		let fromMap = queryParameters.fromMap;
		if (fromMap) {
			_.unset(queryParameters, 'fromMap');
		}

		if (props.match.params.importId) {
			queryParameters['importId'] = props.match.params.importId.toString();
			queryParameters['importType'] = window.location.pathname.includes('/new') ? 'new' : 'update';
		} else if (gridOption.gridUrlLocalStorageName && !fromMap) {
			//pass last visit grid parameters
			urlService.mergeLocalUrlCache(queryParameters, gridOption.gridUrlLocalStorageName);
			queryParameters = urlService.toQueryDictionary();
		}
		if (gridOption.queryParameters) {
			queryParameters = _.assign(queryParameters, gridOption.queryParameters);
		}
		let draftFilterId = queryParameters.draftFilterId ? _.toNumber(queryParameters.draftFilterId) : undefined;
		let filterId = queryParameters.filterId ? _.toNumber(queryParameters.filterId) : undefined;
		let sort: SortDescriptor[] = gridOption.defaultSort || [];
		if (gridOption.sortOrderLocalStorageName) {
			let localStorageSort = localStorageService.getLocalStorage(gridOption.sortOrderLocalStorageName);
			if (localStorageSort && localStorageSort.length > 0) {
				sort = localStorageSort;
			}
		}
		if (queryParameters.sort) {
			let sortParts = queryParameters.sort.split(' ');
			if (sortParts && sortParts.length === 2) {
				sort = [{ field: sortParts[0], dir: sortParts[1] === 'asc' ? 'asc' : 'desc' }];
				Utils.sortQueryParams(sort, queryParameters, Utils.isCustomField, customFields);
			}
		}
		if (gridOption.doClientPagination) {
			queryParameters.size = MaxIntegerStr;
		}

		setState({
			...state,
			queryParameters: queryParameters,
			draftFilterId: draftFilterId,
			filterId: filterId,
			search: queryParameters.search,
			sort: sort
		});
	};

	function updateShouldTranslateGrid() {
		let url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogLanguageTranslationStatus}`;

		apiService.httpGet(url).then(translationModuleEnabled => {
			if (urlService.isFacilityPortal() && translationModuleEnabled) {
				setShouldTranslateGrid(true);
			} else {
				setShouldTranslateGrid(false);
			}
		});
	}

	//DataGrid onLoad
	useEffect(() => {
		initializeQueryParameters();
		// eslint-disable-next-line react-hooks/exhaustive-deps

		if (urlService.isFacilityPortal()) {
			updateShouldTranslateGrid();
		}
	}, []);

	const refresh = () => {
		let queryParameters = { ...(state.queryParameters || {}) };
		if (gridOption.doClientSearch) {
			//When grid is set to do client search, take away the search parameter from API call
			_.unset(queryParameters, 'search');
		}
		dispatch(props.restSlice.fetchPage(queryParameters));
	};

	//DataGrid load data
	useEffect(() => {
		if (state.queryParameters !== undefined) {
			const queryParametersForHistory = { ...state.queryParameters };
			if (props.match.params.importId) {
				_.unset(queryParametersForHistory, 'importId');
				_.unset(queryParametersForHistory, 'importType');
			}
			//Save grid url to browser history due to parameter changes
			const url = urlService.setUrlQueryString(queryParametersForHistory);
			if (gridOption.gridUrlLocalStorageName) {
				//Reserved last visit grid parameters to local storage for next visit without URL parameters
				localStorageService.setLocalStorage(gridOption.gridUrlLocalStorageName, url);
			}
			if (props.setQueryParametersFromParent) {
				props.setQueryParametersFromParent(queryParametersForHistory);
			}
			refresh();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.queryParameters]);

	// To be removed when customCleaningFormFeatureFlag is removed.
	useEffect(() => {
		props.gridOption.isSlicePathModified && refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.gridOption.isSlicePathModified]);

	useEffect(() => {
		if (editMode) {
			if (selectAllItems && list.length && props && editMode) {
				let ids = list.map((item: any) => item[editMode && (editMode.selectIdKey as any)]);
				setSelectedItems([...ids] as any);
			}
			if (!selectAllItems && list.length && props && editMode) {
				if (!isSingleItemDeselected) {
					setSelectedItems([]);
				} else {
					setIsSingleItemDeselected(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAllItems]);

	useEffect(() => {
		if (editMode) {
			if (selectedItems.length) {
				let ids = list.map((item: any) => item[editMode && (editMode.selectIdKey as any)]);
				if (ids && selectedItems.length && _.differenceWith(ids, selectedItems, _.isEqual).length === 0) {
					setSelectAllItems(true);
					if (isSingleItemDeselected) {
						setIsSingleItemDeselected(false);
					}
				} else {
					setIsSingleItemDeselected(true);
				}
			} else {
				setSelectAllItems(false);
			}
			if (editMode.populateSelected) {
				let items = list.filter((item: any) => {
					if (editMode) {
						let itemId = item[editMode.selectIdKey];
						return (selectedItems as any).includes(itemId);
					} else {
						return false;
					}
				});
				editMode.getSelected && editMode.getSelected(items);
			} else {
				editMode.getSelected && editMode.getSelected(selectedItems);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedItems, list]);

	useEffect(() => {
		if (list && gridOption.translateColumns && shouldTranslateGrid) {
			translateService.translateGridData(list, gridOption.translateColumns).then(res => {
				setGridData(res);
			});
		} else if (list) {
			setGridData(list);
		}
		if (gridOption.searchPlaceholder && shouldTranslateGrid) {
			translateService.translateString(gridOption.searchPlaceholder).then(res => {
				setSearchPlaceholder(res);
			});
		} else if (gridOption.searchPlaceholder) {
			setSearchPlaceholder(gridOption.searchPlaceholder);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list, language, shouldTranslateGrid]);

	useEffect(() => {
		updateColumnHeaders();
		if (shouldTranslateGrid) {
			updateTranslatedColumnDefinitions();
		}
	}, [language, shouldTranslateGrid, props.gridOption.allColumns, props.gridOption.defaultColumns]);

	useEffect(() => {
		if (isSingleItemDeselected) {
			setSelectAllItems(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSingleItemDeselected]);

	useEffect(() => {
		if (editMode) {
			setSelectedItems([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMode && editMode.deleteItems]);

	useEffect(() => {
		let queryParameters = { ...(state.queryParameters || {}) };
		if (editMode && editMode.validationErrorData && editMode.isCustomOperationPerformed) {
			dispatch(props.restSlice.fetchPage(queryParameters));
			let ids = editMode.validationErrorData.map(
				(errorData: any) => errorData[editMode && (editMode.selectIdKey as any)]
			);
			setSelectedItems([...(ids as never[])]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editMode && editMode.isCustomOperationPerformed]);

	useEffect(() => {
		if (
			!props.match.params.industryId &&
			gridOption.rerenderOnQueryParamChange &&
			urlService.isIppAuthorityPortal()
		) {
			let newQueryParameters = { ...gridOption.queryParameters };
			_.unset(newQueryParameters, 'industryId');
			setState({
				...state,
				queryParameters: newQueryParameters
			});
		}
	}, [window.location.pathname]);

	//DataGrid load custom field definitions
	useEffect(() => {
		dispatch(customFieldSlice.resetAll());
		if (gridOption.customFieldQuery) {
			dispatch(customFieldSlice.fetchAll(gridOption.customFieldQuery));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, gridOption.customFieldQuery]);

	//DataGrid pass custom field definitions to filterService and DataGrid after loaded
	useEffect(() => {
		//pass custom fields to filter
		filterService.updateCustomFields(customFields);
		//pass custom fields to grid
		if (gridOption.customFieldsForGrid) {
			let gridCustomFields = [] as CustomFieldDefinition[];
			gridOption.customFieldsForGrid.forEach(table => {
				var tableFields = customFields.filter(a => a.appliedToTable == table);
				gridCustomFields = [...gridCustomFields, ...tableFields];
			});
			setCustomFieldsForGrid(gridCustomFields);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFields]);

	useEffect(() => {
		let isFullList: boolean = list === undefined || (total === 0 && list.length >= 0);
		setWithPaging(!isFullList);
	}, [list, total]);

	const toggleTab = (tab: string) => {
		if (state.activeTab !== tab) {
			setState({
				...state,
				activeTab: tab
			});
		}
	};

	const pageChange = (event: GridPageChangeEvent) => {
		if (props.prePageChange) {
			props.prePageChange();
		}

		if (props.gridOption.doClientPagination) {
			setPagination({
				skip: event.page.skip,
				take: event.page.take
			});
			return;
		}

		let page = event.page.skip / event.page.take + 1;
		let queryParameters = { ...state.queryParameters };
		queryParameters['page'] = page.toString();
		setState({
			...state,
			queryParameters: queryParameters
		});
	};

	const selectionChange = (event: any) => {
		event.dataItem.selected = !event.dataItem.selected;
		setState({
			...state,
			selectedItem: event.dataItem
		});
	};

	const sortChange = (event: any) => {
		let sort = event.sort;
		if (props.preSortChange) {
			props.preSortChange();
		}
		if (gridOption.doClientSideSorting || gridOption.doCustomClientSort) {
			setState({
				...state,
				sort: sort
			});
			return;
		}
		let queryParameters = { ...state.queryParameters };
		Utils.sortQueryParams(sort, queryParameters, Utils.isCustomField, customFieldsForGrid);
		setState({
			...state,
			sort: sort,
			queryParameters: queryParameters
		});
	};

	const headerSelectionChange = (event: any) => {
		const checked = event.syntheticEvent.target.checked;
		list.forEach((item: any) => (item.isSelectedInView = checked));
		forceUpdate();
	};

	const getAllGridColumns = (): ColumnField[] => {
		return gridOption.allColumns;
	};

	const getDefaultColumnKeysToShow = (): string[] => {
		if (!_.isEmpty(gridOption.defaultColumns)) {
			return gridOption.defaultColumns as string[];
		}
		let defaultColumns = gridOption.allColumns.filter(f => f.isDefault).map(f => f.name);
		return defaultColumns;
	};

	const rowRender = (trElement: any, props: any) => {
		let trProps = { className: `${trElement.props.className}` };
		if (isEditableGrid && editMode) {
			let errorIds = _.cloneDeep(editMode.validationErrorData);
			let available = false;
			let id: number = props.dataItem[editMode.selectIdKey];
			if (errorIds && errorIds.length) {
				for (let i = 0; i < selectedItems.length; i++) {
					if (errorIds[i]) {
						errorIds[i] = errorIds[i][editMode.selectIdKey];
					}
				}
				available = errorIds.includes(id || 0);
			}
			trProps = { className: `${trElement.props.className} ${available ? 'mark-red' : ''}` };
		}
		return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
	};

	const notificationContent = () => {
		return (
			<div className="d-flex align-items-center justify-content-between">
				<div>
					<span>{props.gridOption.notification!.message}</span>
				</div>
				<button className="close float-right" onClick={() => props.gridOption.notification!.onClose()}>
					<CloseSvg />
				</button>
			</div>
		);
	};

	const getDataGridView = () => {
		let tableDescription: JSX.Element[] = [];
		if (gridOption.description) {
			if (Array.isArray(gridOption.description)) {
				const totalDesc = gridOption.description.length;
				tableDescription = gridOption.description.map((desc, index) =>
					index < totalDesc - 1 ? (
						<p key={index} className="mb-0">
							{desc}
						</p>
					) : (
						<p key={index}>{desc}</p>
					)
				);
			} else {
				tableDescription = [<p key={0}>{gridOption.description}</p>];
			}
		}
		let data = gridData || [];
		if (gridOption.populateComputedColumns) {
			data = gridOption.populateComputedColumns(data as []);
		}
		if (gridOption.doClientSearch && state.search) {
			data = gridOption.doClientSearch(data as [], state.search);
		}

		if (gridOption.doCustomClientSort && state.sort) {
			data = gridOption.doCustomClientSort(data as [], state.sort);
		}
		if (gridOption.doClientSideSorting && state.sort) {
			data = orderBy(data, state.sort);
		}

		const lengthBeforePagination = data.length;

		if (gridOption.doClientPagination) {
			data = process(data, pagination).data;
		}

		return (
			<>
				<div className="table-responsive">
					{gridOption.description ? tableDescription : <React.Fragment />}
					<Grid
						ref={gridRef}
						data={data}
						className="table"
						selectedField="selected"
						rowRender={rowRender}
						skip={props.gridOption.doClientPagination ? pagination.skip : (page - 1) * size}
						take={props.gridOption.doClientPagination ? pagination.take : size}
						total={props.gridOption.doClientPagination ? lengthBeforePagination : total}
						pageable={
							withPaging
								? {
										buttonCount: 5,
										info: true,
										type: 'numeric',
										previousNext: true
								  }
								: false
						}
						onPageChange={pageChange}
						scrollable="none"
						onSelectionChange={selectionChange}
						onHeaderSelectionChange={headerSelectionChange}
						//resizable
						sortable
						sort={state.sort}
						onSortChange={sortChange}>
						{!_.isUndefined(gridOption.noRecordMessage) && (
							<GridNoRecords>
								<Translate>{gridOption.noRecordMessage}</Translate>
							</GridNoRecords>
						)}
						{columns}
					</Grid>
					{!withPaging && !_.isEmpty(data) && !gridOption.hideCount && (
						<div className="font-size-14px-regular">
							{data.length} <Translate>items</Translate>
						</div>
					)}
				</div>
			</>
		);
	};

	// Quicker Viewer
	const onClearFilter = () => {
		let queryParameters = urlService.pickParameters(
			{ ...state.queryParameters },
			'size',
			'sort',
			'search',
			'ReportStatusName',
			'SampleStatusName',
			gridOption.unsetPageOnFilterApply ? '' : 'page'
		);
		setState({
			...state,
			queryParameters: queryParameters,
			draftFilterId: undefined,
			filterId: undefined
		});
	};

	const onFilterSaved = (filterId: number) => {
		let queryParameters = { ...state.queryParameters };
		_.unset(queryParameters, 'draftFilterId');
		gridOption.unsetPageOnFilterApply && _.unset(queryParameters, 'page');
		_.set(queryParameters, 'filterId', filterId);
		setState({
			...state,
			queryParameters: queryParameters,
			filterId: filterId,
			draftFilterId: undefined
		});
	};

	const applyBtnClicked = (filterSaved: Filter | undefined, draftFilterSave: DraftFilter | undefined) => {
		let queryParameters = { ...state.queryParameters };
		if (draftFilterSave) {
			queryParameters['draftFilterId'] = _.toString(draftFilterSave.draftFilterId);
			_.unset(queryParameters, 'filterId');
		} else if (filterSaved) {
			queryParameters['filterId'] = _.toString(filterSaved.filterId);
			_.unset(queryParameters, 'draftFilterId');
		}
		gridOption.unsetPageOnFilterApply && _.unset(queryParameters, 'page');
		setState({
			...state,
			queryParameters: queryParameters,
			draftFilterId: draftFilterSave ? draftFilterSave.draftFilterId : undefined,
			filterId: filterSaved ? filterSaved.filterId : undefined
		});
		setFilterState({
			...filterState,
			showFilterMenuPopOver: false
		});
	};

	const exportData = () => {
		if (gridRef.current) {
			let columns = gridOption.exportColumns
				? gridOption.exportColumns
				: gridRef.current.columns.map(i => i.field);
			if (columns) {
				let queryParameters = { ...state.queryParameters };
				queryParameters['format'] = 'excel';
				queryParameters['includes'] = columns.join(',');
				_.unset(queryParameters, 'page');
				dispatch(props.restSlice.exportAll(urlService.toQueryString(queryParameters)));
			}
		}
	};

	const applySearch = (searchTerm: string) => {
		let queryParameters = { ...state.queryParameters };
		if (searchTerm) {
			queryParameters['search'] = searchTerm;
			if (gridOption.isPaginatedSearch) {
				queryParameters['page'] = '1';
			} else {
				_.unset(queryParameters, 'page');
			}
		} else {
			_.unset(queryParameters, 'search');
			if (gridOption.isPaginatedSearch) {
				queryParameters['page'] = '1';
			} else {
				_.unset(queryParameters, 'page');
			}
		}

		if (props.gridOption.doClientSearch) {
			setState({
				...state,
				search: searchTerm ? searchTerm : ''
			});

			return;
		}

		setState({
			...state,
			queryParameters: queryParameters,
			search: searchTerm
		});
	};

	const onFilterActionMenuBtnClicked = (e: React.MouseEvent<HTMLButtonElement, MouseEvent | KeyboardEvent>) => {
		setState({
			...state,
			anchorElement: e && e.target
		});

		setFilterState({ ...filterState, showFilterMenuPopOver: true, showFilterEditorModal: false });
	};

	// Edit Columns
	const editColumnModalToggle = () => {
		setState({
			...state,
			showEditColumnModal: !state.showEditColumnModal
		});
	};

	const columnSettingChanged = () => {
		forceUpdate();
		updateColumnHeaders();

		if (shouldTranslateGrid) {
			updateTranslatedColumnDefinitions();
		}
	};

	const updateTranslatedColumnDefinitions = () => {
		translateService.translateColumnDefinitions(getAllColumnDefinitions()).then(translatedColumnDefinitions => {
			setAllColumnDefinitions(translatedColumnDefinitions);
		});
		translateService
			.translateColumnDefinitions(getDefaultColumnsDefinitions())
			.then(translatedDefaultColumnsDefinitions => {
				setDefaultColumnsDefinitions(translatedDefaultColumnsDefinitions);
			});
	};

	const updateColumnHeaders = () => {
		Utils.generateColumns(
			gridOption.prefix,
			gridOption.storageName,
			getAllGridColumns(),
			getDefaultColumnKeysToShow(),
			customFieldsForGrid,
			isEditableGrid,
			selectedItems,
			setSelectedItems,
			selectAllItems,
			setSelectAllItems,
			editMode && editMode.selectIdKey,
			editMode && editMode.validationErrorData,
			editMode && editMode.clearValidationErrorData,
			shouldTranslateGrid
		).then(res => {
			setColumns(res);
		});
	};

	const onEditColumnBtnClicked = () => {
		setState({
			...state,
			showEditColumnModal: true
		});
	};

	const onAddBtnClicked = (e: any) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}

		if (gridOption.toggleAddModal) {
			gridOption.toggleAddModal();
		}
	};

	const getAllColumnDefinitions = () => {
		return Utils.getAllColumns(
			gridOption.prefix,
			gridOption.storageName,
			getAllGridColumns(),
			getDefaultColumnKeysToShow(),
			customFieldsForGrid
		);
	};

	const toggleShowPatchInspectionsModal = () => {
		const toValue = !state.showScheduleInspectionModal;
		setState({
			...state,
			showScheduleInspectionModal: toValue
		});
	};
	const batchPatchInspections = () => {
		if (numberOfOpenInspections && numberOfOpenInspections > 0) {
			if (gridRef.current) {
				toggleShowPatchInspectionsModal();
			}
		} else {
			alertService.addError(
				localizationService.getLocalizedString('alertMessages.cantRescheduleOnlyCompleteInspections')
			);
		}
	};

	const getDefaultColumnsDefinitions = () => {
		return Utils.getDefaultShowingColumnDefinitions(
			gridOption.prefix,
			getDefaultColumnKeysToShow(),
			isEditableGrid
		);
	};

	// Filter
	const filterEditModalToggle = (isVisible: boolean) => {
		setFilterState({ ...filterState, showFilterEditorModal: isVisible, showFilterMenuPopOver: false });
	};

	const closeFilterActionMenuPopOver = () => {
		setFilterState({ ...filterState, showFilterMenuPopOver: false });
	};

	const createNewFilterCallBack = () => {
		setFilterState({
			...filterState,
			showFilterMenuPopOver: false,
			showFilterEditorModal: true
		});
	};

	const chooseFilterCallback = (filterId: number) => {
		let queryParameters = { ...state.queryParameters };
		queryParameters['filterId'] = _.toString(filterId);
		gridOption.unsetPageOnFilterApply && _.unset(queryParameters, 'page');
		_.unset(queryParameters, 'search');
		_.unset(queryParameters, 'draftFilterId');

		setState({
			...state,
			queryParameters: queryParameters,
			search: queryParameters.search,
			filterId: filterId,
			draftFilterId: undefined
		});
		setFilterState({ ...filterState, showFilterMenuPopOver: false });
	};

	const getActionMenus = () => {
		let actions = [];
		if (props.gridOption.showActionDownloadInvites) {
			actions.push(
				<DropdownItem key="downloadInvites" onClick={props.gridOption.downloadInvites}>
					{localizationService.getLocalizedString('haulerPortal.haulerGrid.downloadInviteAction')}
				</DropdownItem>
			);
		}
		if (props.gridOption.showActionExport) {
			actions.push(
				<DropdownItem key="export" onClick={exportData}>
					{localizationService.getLocalizedString('grid.exportToSpreadSheet')}
				</DropdownItem>
			);
		}
		if (props.gridOption.showActionScheduleInspection) {
			actions.push(
				<DropdownItem key="batchPatchInspections" onClick={batchPatchInspections}>
					{localizationService.getLocalizedString('screen.labels.rescheduleInspections')}
				</DropdownItem>
			);
		}
		if (props.gridOption.showBulkUpdate) {
			actions.push(
				<DropdownItem key="bulkUpdate" onClick={() => gridOption.bulkUpdate && gridOption.bulkUpdate()}>
					{localizationService.getLocalizedString('grid.bulkUpdate')}
				</DropdownItem>
			);
		}
		_.forEach(props.gridOption.customActions, action => {
			actions.push(
				<DropdownItem key={action.actionType} onClick={action.toggle}>
					{action.actionLabel}
				</DropdownItem>
			);
		});
		return actions;
	};

	// CSS adjustment
	let dynamicTableWidthClass = state.showEditColumnModal
		? 'max-table-width-when-column-editor-shown'
		: gridOption.doNotApplyMaxTable
		? 'w-100'
		: 'w-100 max-table'; //max-table does not responsive on window resize, however, it solve the problem data grid expand window horizontal scroll

	if (window.matchMedia('(min-width: 700px)').matches) {
		let mainEl = document.querySelector('main');
		if (state.showEditColumnModal && mainEl) {
			mainEl.classList.add('pr-0');
		}
		if (!state.showEditColumnModal && mainEl) {
			mainEl.classList.remove('pr-0');
		}
	}

	let refreshBtnBlockClass = gridOption.refreshBtnBlockClass ? gridOption.refreshBtnBlockClass : '';

	const enableGridEditing = (type: string) => {
		if (type === 'cancel' && editMode && editMode.customCancelAction) {
			editMode.customCancelAction();
			editMode.clearValidationErrorData && editMode.clearValidationErrorData();
		}
		if (type === 'edit' && editMode && editMode.customEditAction) {
			editMode.customEditAction();
		}
		setSelectedItems([]);
		setIsEditableGrid(!isEditableGrid);
	};

	const createGridContext = (): GridContext => {
		return {
			setGridState: setGridState,
			getGridState: getGridState,
			gridOption: gridOption,
			gridRefresh: refresh
		};
	};

	const getMapUrl = (): string => {
		let localStorageMapURL = _.toString(localStorageService.getLocalStorage(LocalStorageName.FacilityMap));
		let queryParams =
			localStorageMapURL.split('?').length == 2
				? urlService.toQueryDictionary(localStorageMapURL.split('?')[1])
				: ({} as Dictionary<string>);

		let url = `map?fromGrid=true`;

		if (state.queryParameters) {
			let search = state.queryParameters.search;
			if (search) {
				queryParams['search'] = _.toString(search);
			}

			if (
				!String.equalCaseInsensitive(
					filterService.applyTo,
					localizationService.getLocalizedString('incidents.incident')
				)
			) {
				['filterId', 'draftFilterId'].forEach((key: string) => {
					_.unset(queryParams, key);
				});

				let filterId = state.queryParameters.filterId;
				if (filterId) {
					queryParams['filterId'] = _.toString(filterId);
				}
				let draftFilterId = state.queryParameters.draftFilterId;
				if (draftFilterId) {
					queryParams['draftFilterId'] = _.toString(draftFilterId);
				}
			} else if (
				String.equalCaseInsensitive(
					filterService.applyTo,
					localizationService.getLocalizedString('incidents.incident')
				)
			) {
				['incidentFilterId', 'incidentDraftFilterId'].forEach((key: string) => {
					_.unset(queryParams, key);
				});

				let filterId = state.queryParameters.filterId;
				if (filterId) {
					queryParams['incidentFilterId'] = _.toString(filterId);
				}
				let draftFilterId = state.queryParameters.draftFilterId;
				if (draftFilterId) {
					queryParams['incidentDraftFilterId'] = _.toString(draftFilterId);
				}
			}
		}

		let queryString = urlService.toQueryString(queryParams);
		return `${url}&${queryString}`;
	};

	return (
		<>
			{props.fallBackUI && !state.search && list.length <= 0 ? (
				props.fallBackUI
			) : (
				<DataGridContext.Provider value={createGridContext()}>
					<div className={`d-flex w-100 flex-column flex-lg-row ${state.showEditColumnModal ? 'h-100' : ''}`}>
						<div className={`d-flex flex-column ${dynamicTableWidthClass}`}>
							<div className="page-header" hidden={props.gridOption.removePageHeader}>
								{props.headerElem !== undefined ? (
									<DataGridContext.Provider value={createGridContext()}>
										{props.headerElem}
									</DataGridContext.Provider>
								) : (
									<>
										{props.gridOption.showBackButton && <BackArrow />}
										<h1>
											<Translate>{gridOption.pageTitle}</Translate>
										</h1>
										{editMode && (
											<button
												id={editMode.id || 'edit-mode'}
												className={
													editMode.buttonClass || isEditableGrid
														? 'btn ai-white mr-1 ml-1'
														: 'btn ai-action mr-1 ml-1'
												}
												onClick={() => enableGridEditing(isEditableGrid ? 'cancel' : 'edit')}>
												{isEditableGrid
													? editMode.viewButtonText ||
													  localizationService.getLocalizedString('screen.buttons.cancel')
													: editMode.editButtonText ||
													  localizationService.getLocalizedString('screen.buttons.edit')}
											</button>
										)}
										{editMode &&
											isEditableGrid &&
											editMode.editModeMultiButtons &&
											editMode.editModeMultiButtons.map((button, index) => {
												return (
													<button
														key={index}
														id={button.id}
														disabled={selectedItems.length ? false : true}
														className={`btn ai-action mr-1 ml-1 ${button.className}`}
														onClick={button.action}>
														{button.text}
													</button>
												);
											})}
										{!isEditableGrid &&
											gridOption.multiButtons &&
											gridOption.multiButtons.map((button, index) => {
												return (
													<button
														key={index}
														id={button.id}
														className={`btn ai-action mr-1 ml-1 ${button.className}`}
														onClick={button.action}>
														{button.text}
													</button>
												);
											})}
										{!isEditableGrid && gridOption.showAddButton && gridOption.toggleAddModal && (
											<button className="btn ai-new" id="btnAdd" onClick={onAddBtnClicked}>
												<Translate>{gridOption.addButtonText}</Translate>
											</button>
										)}
									</>
								)}
							</div>
							{gridOption.showAddButton && gridOption.addModalComponent ? (
								gridOption.addModalComponent
							) : (
								<></>
							)}
							<section>
								{props.gridOption.checkbox && props.gridOption.checkbox.id && (
									<SingleCheckbox
										id={props.gridOption.checkbox.id as string}
										name={props.gridOption.checkbox.name as string}
										label={props.gridOption.checkbox.label || ''}
										checked={props.gridOption.checkbox.checked}
										onChange={props.gridOption.checkbox.onChange}
									/>
								)}
								{props.gridOption.notification &&
									props.gridOption.notification!.show &&
									!props.gridOption.notification.customUI && (
										<div className="mb-2">
											<InlineAlertItem
												message={notificationContent()}
												alertType={AlertMessageType.Info}
											/>
										</div>
									)}
								{props.gridOption.notification &&
									props.gridOption.notification.customUI &&
									props.gridOption.notification.customUI()}
								{gridOption.subHeading && (
									<span
										className={`${
											gridOption.subHeadingClass
												? gridOption.subHeadingClass
												: 'font-size-20px-regular'
										}`}>
										{gridOption.subHeading}
									</span>
								)}
								{gridOption.informativeText && <p>{gridOption.informativeText}</p>}

								{gridOption.showTabList === true && (
									<Nav tabs className="material">
										<NavItem className="ml-3">
											<NavLink
												id="gridNavLink"
												className={
													classNames({
														active: state.activeTab === 'tab-list'
													}) + ' cursor-pointer'
												}
												onClick={() => {
													toggleTab('tab-list');
												}}>
												List
											</NavLink>
										</NavItem>
									</Nav>
								)}
								<TabContent activeTab={state.activeTab}>
									<TabPane tabId="tab-list">
										<div className="d-sm-flex">
											<div className="flex-grow-1">
												{props.filterElem !== undefined ? (
													<DataGridContext.Provider value={createGridContext()}>
														{props.filterElem}
													</DataGridContext.Provider>
												) : (
													<>
														<div className="form-row">
															{gridOption.showRefreshButton && (
																<div
																	className={`col-auto mb-2 ${refreshBtnBlockClass}`}>
																	<button
																		className={'btn ai-white mr-1'}
																		onClick={event => {
																			event.preventDefault();
																			dispatch(
																				props.restSlice.fetchPage({
																					...(state.queryParameters || {})
																				})
																			);
																		}}>
																		{localizationService.getLocalizedString(
																			'screen.labels.refreshBtnText'
																		)}
																	</button>
																</div>
															)}
															{gridOption.showActions && (
																<div className="col-auto mb-2">
																	<UncontrolledDropdown>
																		<DropdownToggle
																			tag="a"
																			className="btn ai-white cursor-pointer"
																			caret>
																			{localizationService.getLocalizedString(
																				'grid.actions'
																			)}
																		</DropdownToggle>
																		<DropdownMenu> {getActionMenus()}</DropdownMenu>
																	</UncontrolledDropdown>
																</div>
															)}
															{gridOption.showFilter && (
																<div className="col-auto mb-2">
																	<button
																		className="btn ai-white"
																		id="filterMenuBtn"
																		onClick={onFilterActionMenuBtnClicked}>
																		{localizationService.getLocalizedString(
																			'screen.labels.filters'
																		)}
																	</button>
																</div>
															)}

															{gridOption.showSearch && (
																<div className="col-auto mb-2">
																	<SearchInput
																		ariaLabel="Search"
																		search={applySearch}
																		placeholderDescription={gridOption.searchHint}
																		placeholder={searchPlaceholder}
																		searchTerm={state.search}
																	/>
																</div>
															)}
														</div>
													</>
												)}
											</div>
											{gridOption.hasGridMap && showFogMapFeature && (
												<div className="mb-2 mr-2 text-nowrap">
													<NavLink
														tag={Link}
														to={getMapUrl()}
														className="gridBtnRiskMap btn ai-white">
														<MapSvg className="mr-2 ai-dark-purple" />
														{localizationService.getLocalizedString('grid.showOnMap')}
													</NavLink>
												</div>
											)}
											{gridOption.showEditColumnButton && (
												<div className="mb-2 text-nowrap">
													<button
														id="gridBtnShowCustomField"
														className="btn ai-white"
														onClick={onEditColumnBtnClicked}>
														<Translate>
															{localizationService.getLocalizedString('grid.editColumns')}
														</Translate>
													</button>
												</div>
											)}
										</div>
										{gridOption.showFilter === true &&
											((state.draftFilterId !== undefined && state.draftFilterId > 0) ||
												(state.filterId !== undefined && state.filterId > 0)) && (
												<div className="mb-2 d-flex">
													<DataGridContext.Provider value={createGridContext()}>
														<FilterQuickViewer
															filterId={state.filterId}
															draftFilterId={state.draftFilterId}
															onClosedClicked={onClearFilter}
															onFilterChanged={applyBtnClicked}
															filterDeleted={onClearFilter}
															onFilterSaved={onFilterSaved}
														/>
													</DataGridContext.Provider>
												</div>
											)}

										{getDataGridView()}
									</TabPane>
									<TabPane tabId="tab-map">
										<div className="mt-4 mb-1 col-auto">Coming....</div>
									</TabPane>
								</TabContent>
							</section>
						</div>
						{state.showEditColumnModal && (
							<ColumnsEditor
								localStorageName={gridOption.storageName}
								showModal={state.showEditColumnModal}
								toggle={editColumnModalToggle}
								columns={shouldTranslateGrid ? allColumnDefinitions : getAllColumnDefinitions()}
								defaultColumns={
									shouldTranslateGrid ? defaultColumnsDefinitions : getDefaultColumnsDefinitions()
								}
								onChanged={columnSettingChanged}
							/>
						)}
						{gridOption.showFilter &&
							filterState.showFilterMenuPopOver &&
							!filterState.showFilterEditorModal && (
								<FilterActionMenu
									anchor={state.anchorElement}
									chosenFilterCallBack={chooseFilterCallback}
									createNewFilterCallBack={createNewFilterCallBack}
									closeModal={closeFilterActionMenuPopOver}
									domain={_.join(filterService.supportedDomainObjectNames, ',')}
								/>
							)}
						{props.children}
					</div>

					{gridOption.showFilter && filterState.showFilterEditorModal && (
						<FilterEditModal
							isCreateNew={true}
							title={localizationService.getLocalizedString('screen.filterModal.newFilter')}
							modalToggleFunction={filterEditModalToggle}
							applyBtnClicked={applyBtnClicked}
							filterDeleted={onClearFilter}
							widgetCount={0}
						/>
					)}
				</DataGridContext.Provider>
			)}
		</>
	);
};
