import { RestSlice } from '../../redux/rest.slice';
import { Resource, localizationService } from 'src/services';
import { OperationLog } from '@rcp/types';

export const operationLogSlice = new RestSlice<OperationLog>(
	localizationService.getLocalizedString('systemSupportOnlySettings.operationLog'),
	Resource.AdminOperation,
	true
);
export const operationLogReducer = operationLogSlice.rests.reducer;
