import React from 'react';
import _ from 'lodash';
import { Dropdown, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {
	TreeView,
	processTreeViewItems,
	handleTreeViewCheckChange,
	TreeViewExpandChangeEvent
} from '@progress/kendo-react-treeview';
import { ApiFilterProperty, DropDownOption, TreeViewDataItem } from '@rcp/types';
import {
	timeframeCompleteValueOptionsForQuickFilter,
	timeframeDueValueOptionsForQuickFilter,
	TimeframeField,
	TimeframeType,
	TimeframeValue,
	timeframeValueOptions
} from './filter-row-date-is';

import './filter.scss';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	FacilityQuickFilterEnum,
	IncidentQuickFilterEnum,
	inspectionTimeframeQuickFilterGroup
} from './quick-filter-service';
import { ExpandableOption } from 'src/components/widgets';
import { localizationService } from 'src/services';

interface QuickFilterEnumProps {
	filterNumber?: FacilityQuickFilterEnum | IncidentQuickFilterEnum;
	label: string;
	quickFilterOptions: TreeViewDataItem[];
	onChangeQuickFilter: (
		checkedFilterProperties: ApiFilterProperty[],
		uncheckedFilterProperties?: ApiFilterProperty[],
		filterNumber?: any,
		selectedIds?: any[]
	) => Promise<void>;
	selectedIds?: any[];
	isSingleSelectionMode?: boolean;
}

export const QuickFilterDropdown: React.FC<QuickFilterEnumProps> = props => {
	const [items, setItems] = React.useState<Array<any>>([]);
	const [settings, setSettings] = React.useState({ singleMode: false, checkChildren: true, checkParents: true });
	const [check, setCheck] = React.useState({ ids: [] as any[], applyCheckIndeterminate: true, idField: 'value' });
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const [hasChange, setHasChange] = React.useState(false);

	React.useEffect(() => {
		setItems(props.quickFilterOptions.length > 0 ? (props.quickFilterOptions[0].items as []) : []);
	}, [props.quickFilterOptions]);

	React.useEffect(() => {
		let newSettings = { ...settings };
		newSettings.singleMode = props.isSingleSelectionMode || false;
		setSettings(newSettings);
	}, [props.isSingleSelectionMode]);

	React.useEffect(() => {
		let newCheck = { ...check };
		newCheck.ids = props.selectedIds || [];
		setCheck(newCheck);
	}, [props.selectedIds]);

	const onTimeframeOptionChanged = (item: TreeViewDataItem, optionValue: string) => {
		let newItems = [...items];
		let assigned = false;

		for (let j = 0; j < newItems.length; j++) {
			let secondLevelItem = newItems[j];
			if (String.equalCI(TimeframeType, secondLevelItem.type) && secondLevelItem.text === item.text) {
				secondLevelItem.selectedTimeframeValue = optionValue;
				assigned = true;
			}
		}

		if (assigned) {
			setItems(newItems);
			if (props.selectedIds && _.includes(props.selectedIds, item.value)) {
				//Only apply timeframe moving date option change when the hooked quick filter item is checked.
				setHasChange(true);
			}
		}
	};

	const onCheckChange = (event: TreeViewExpandChangeEvent) => {
		let newCheck = handleTreeViewCheckChange(event, check, items, settings);
		setCheck(newCheck);
		setHasChange(true);
	};

	const toggle = () => {
		if (dropdownOpen && hasChange) {
			let checkedValues = check.ids as [];
			applyChange(checkedValues);
		} else {
			setHasChange(false);
		}
		setDropdownOpen(!dropdownOpen);
	};

	const applyChange = (checkedValues: any[]) => {
		if (FacilityQuickFilterEnum.InspectionTimeFrame === props.filterNumber) {
			let {
				checkedFilterProperties,
				uncheckedFilterProperties
			} = inspectionTimeframeQuickFilterGroup.getCheckedFilterOptions(
				checkedValues,
				props.quickFilterOptions,
				props.selectedIds
			);
			props.onChangeQuickFilter(
				checkedFilterProperties,
				uncheckedFilterProperties,
				props.filterNumber,
				checkedValues
			);
			return;
		}

		let checkedFilterProperties: ApiFilterProperty[] = [];
		let uncheckedFilterProperties: ApiFilterProperty[] = [];
		let newQuickFilterOptions = props.quickFilterOptions;
		if (
			newQuickFilterOptions[0].filterProperties &&
			newQuickFilterOptions[0].filterProperties.length === 1 &&
			!newQuickFilterOptions[0].useThisFilterPropertiesIfAllItemsSelected
		) {
			newQuickFilterOptions[0].filterProperties[0].values = checkedValues.filter((x: any) => {
				return x !== undefined;
			});
			if (checkedValues.length) {
				checkedFilterProperties = newQuickFilterOptions[0].filterProperties;
			} else {
				uncheckedFilterProperties = newQuickFilterOptions[0].filterProperties;
			}
		} else if (
			newQuickFilterOptions[0].filterProperties &&
			newQuickFilterOptions[0].filterProperties.length === 1 &&
			newQuickFilterOptions[0].useThisFilterPropertiesIfAllItemsSelected
		) {
			if (checkedValues.length === newQuickFilterOptions[0].items?.length) {
				checkedFilterProperties = newQuickFilterOptions[0].filterProperties;
				_.forEach(newQuickFilterOptions[0].items, (item: TreeViewDataItem) => {
					_.forEach(item.filterProperties, (filterProperty: ApiFilterProperty) => {
						let newFilterProperty = { ...filterProperty };
						uncheckedFilterProperties.push(newFilterProperty);
					});
				});
			} else {
				uncheckedFilterProperties = newQuickFilterOptions[0].filterProperties;
				_.forEach(newQuickFilterOptions[0].items, (item: TreeViewDataItem) => {
					var index = checkedValues.indexOf(item.value);
					if (index > -1 && item.filterProperties) {
						checkedFilterProperties = checkedFilterProperties.concat(item.filterProperties);
					} else {
						_.forEach(item.filterProperties, (filterProperty: ApiFilterProperty) => {
							let newFilterProperty = { ...filterProperty };
							uncheckedFilterProperties.push(newFilterProperty);
						});
					}
				});
			}
		} else {
			_.forEach(newQuickFilterOptions[0].items, (item: TreeViewDataItem) => {
				var index = checkedValues.indexOf(item.value);
				if (index > -1 && item.filterProperties) {
					checkedFilterProperties = checkedFilterProperties.concat(item.filterProperties);
				} else {
					_.forEach(item.filterProperties, (filterProperty: ApiFilterProperty) => {
						let newFilterProperty = { ...filterProperty };
						uncheckedFilterProperties.push(newFilterProperty);
					});
				}
			});
		}

		props.onChangeQuickFilter(
			checkedFilterProperties,
			uncheckedFilterProperties,
			props.filterNumber,
			checkedValues
		);
	};

	const onClickClearAll = (e: any) => {
		e.preventDefault();
		if (check.ids.length) {
			let newCheck = { ids: [] as any[], applyCheckIndeterminate: true, idField: 'value' };
			setCheck(newCheck);
			applyChange([]);
		}
	};

	const treeItem = (itemProps: any) => {
		let item = itemProps.item;
		if (item.type !== TimeframeType) {
			let element = item.isTextItalics ? <i>{item.text}</i> : <>{item.text}</>;
			return item.isTextBold ? <span className="font-size-16px-medium">{element}</span> : <>{element}</>;
		}

		let options: DropDownOption[];
		if (String.equalCI(TimeframeField.Due, item.text)) {
			options = timeframeDueValueOptionsForQuickFilter;
		} else if (String.equalCI(TimeframeField.Completed, item.text)) {
			options = timeframeCompleteValueOptionsForQuickFilter;
		} else {
			options = timeframeValueOptions;
		}

		return (
			<div className="timeframe">
				<ExpandableOption
					value={item.selectedTimeframeValue || TimeframeValue.ThisMonth}
					onValueChange={(selectedValue: string) => {
						onTimeframeOptionChanged(item, selectedValue);
					}}
					options={options}
				/>
			</div>
		);
	};

	let data = processTreeViewItems(items, { check: check });
	return (
		<>
			<div className="filter-dropdown">
				<Dropdown size="md" isOpen={dropdownOpen} toggle={toggle}>
					<DropdownToggle
						tag="a"
						className={`btn ai-white mt-2 ${check.ids.length > 0 && 'filter-selected'}`}
						caret>
						{check.ids.length > 0 && <FontAwesomeIcon icon={faCheck} />}
						<span id={`${props.label}_toggle`} className="px-1">
							{props.label}
						</span>
					</DropdownToggle>
					<DropdownMenu>
						<div className="d-flex mx-2">
							<a href="#/" className="ai-edit-link ml-auto font-size-14px mb-1" onClick={onClickClearAll}>
								{localizationService.getLocalizedString('map.clearFilter')}
							</a>
						</div>
						<TreeView
							className="pr-3 filter-dropdown-menu"
							data={data}
							checkboxes={true}
							onCheckChange={onCheckChange}
							item={treeItem}
						/>
					</DropdownMenu>
				</Dropdown>
			</div>
		</>
	);
};
