import React from 'react';
import { Hauler } from '@rcp/types';
import { HaulerCardModal } from './hauler-card-component-modal';

interface OwnProps {
	hauler: Hauler;
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps;

export const HaulerEditActionLink: React.FC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href="#/" className="ai-link" onClick={onEditClicked}>
				{props.hauler.name}
			</a>
			{showModal && (
				<HaulerCardModal
					hauler={props.hauler}
					showModal={showModal}
					setShowModal={toggleModal}
					isAddModal={false}
				/>
			)}
		</>
	);
};
