import { FeatureNames } from '@rcp/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useReduxSelector } from 'src/redux';
import { tokenService } from 'src/services';

interface Props {
	condition?: boolean;
	children?: React.ReactNode;
	childrenWhenFeatureFlagIsOffOrConditionIsFalse?: React.ReactNode;
	wait?: number;
}

export const WithAqiSsoFeatureFlag: React.FC<Props> = props => {
	const [hidden, setHidden] = React.useState(true);
	const show = () => {
		setHidden(false);
	};
	useEffect(() => {
		if (props.wait && props.wait > 0) {
			setTimeout(() => {
				show();
			}, props.wait);
		}
	}, []);
	const withAqiIdentityProvider = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.WithAqiIdentityProvider]
	);
	const withAqiIdentityProviderForTheAuthority =
		withAqiIdentityProvider &&
		tokenService.isSsoFromAqi() &&
		!_.isEmpty(tokenService.getTokenOrDefault().aqiIdpTenantId);

	if (!hidden) {
		if (withAqiIdentityProviderForTheAuthority) {
			if (props.condition === undefined || props.condition === true) {
				return <>{props.children}</>;
			}
		}
		if (props.childrenWhenFeatureFlagIsOffOrConditionIsFalse) {
			return <>{props.childrenWhenFeatureFlagIsOffOrConditionIsFalse}</>;
		}
	}
	return <></>;
};
