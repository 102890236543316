import React, { useRef, useState } from 'react';
import * as ApiTypes from '@rcp/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
	alertService,
	loadAuthorityWasteTypes,
	loadExtractorList,
	loadUnitList,
	useReduxDispatch,
	useRootStateSelector,
	loadCurrentFogFacility,
	loadFacilityPumpOutEvents,
	loadFacilityAttachments,
	loadFacilityViolations,
	loadFacilityTimelineEnforcements,
	useReduxSelector,
	deleteFacilityAttachment,
	loadFacilityCleaningManifest
} from 'src/redux';
import _ from 'lodash';
import {
	validationService,
	localizationService,
	DateUtilService,
	Resource,
	urlService,
	apiService,
	navigateTo,
	UtilService
} from 'src/services';
import {
	TextInput,
	PopoverModal,
	SingleSelectDropdown,
	TextAreaInput,
	DateInput,
	DeleteModal,
	ComboBoxDropdown,
	SingleCheckbox
} from 'src/components/widgets';
import {
	DropDownOption,
	Unit,
	Hauler,
	WasteType,
	Extractor,
	AttachmentOwnership,
	CustomFieldType,
	CustomFormAttachment
} from '@rcp/types';
import { haulersSlice } from 'src/components/authority/fog/haulers';
import { cleaningSlice } from 'src/components/authority/fog/cleaning';
import { getTimelineSettingDateRange, reloadTimelineEventsFromServer } from 'src/features/timeline/timeline-service';
import { fogDevicesSlice } from '../fog-devices/devices.slice';
import { fogFacilitiesSlice } from '../facilities/facilities-slice';
import { Notification } from 'src/components/widgets/inline-notification';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import './cleaning-modal.scss';
import { nameof } from 'ts-simple-nameof';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import { ConverterFactory } from 'src/services/converter-factory';
import { useHistory } from 'react-router';
import $ from 'jquery';
import { useEffect } from 'react';
import { CustomFormService } from 'src/services/custom-form-service';

const { Attachments, DueDate, CompleteDate, Comments } = ApiTypes.CustomCleaningFields;

interface OwnProps {
	facilityId: number;
	dueDateIsRequired: boolean;
	completeDateIsRequired: boolean;
	device?: ApiTypes.Extractor;
	cleaning?: ApiTypes.CleaningEvent;
	isEnterCleaning?: boolean;
	isScheduleCleaning?: boolean;
	isTimelineReloadRequired?: boolean;
	isDevicePage?: boolean;
	isFacilityPage?: boolean;
	isGridPage?: boolean;
	modalToggleFunction: () => void;
	callBackAfterSave?: () => void;
	callBackAfterDelete?: () => void;
	showModal?: boolean;
	isCleaningGrid?: boolean;
	queryParameters?: ApiTypes.Dictionary<string>;
	isAddModal?: boolean;
	isSubmitCleaning?: boolean;
	isFacilitiesGrid?: boolean;
	totalDevices?: number;
	currentDeviceIndex?: number;
	increaseCurrentDeviceIndex?: () => void;
	closeDeviceModal?: () => void;
	hideDeleteButton?: boolean;
	afterApiCallback?: () => void;
	title?: string;
}

type Props = OwnProps;

export interface FormFields {
	pumpOutEventId?: number;
	pumpOutEvent?: string;
	facilityId?: number;
	facilityIdError?: string;
	dueDate?: string;
	dueDateError?: string;
	completeDate?: string;
	completeDateError?: string;
	deviceId?: number;
	deviceIdError?: string;
	extractorType?: string;
	extractorTypeId?: number;
	amountPumpedUnitId?: number;
	wasteTypeId?: number;
	amountPumped?: number;
	amountPumpedError?: string;
	trapDepth?: number;
	trapDepthUnitId?: number;
	fog?: number;
	fogUnitId?: number;
	solid?: number;
	solidUnitId?: number;
	haulerName?: string;
	haulerId?: number;
	manifestNumber?: string;
	manifestNumberError?: string;
	disposalLocationName?: string;
	disposalLocation?: string;
	comments?: string;
	inComplianceDate?: string;
	inComplianceDateError?: string;
	percentGrease?: number;
	isRepairNeeded?: boolean;
	isRepairNeededReason?: string;
	isRepairNeededReasonError?: string;
	driverName?: string;
	cleanedBy?: string;
	leavingOutlet?: string;
	templateJson?: string;
	attachments?: CustomFormAttachment[];
}

const supportedFileTypes: string = '.docx,.doc,.xls,.xlsx,.pdf,.tif,.tiff,.jpg,.jpeg,.bmp,.png,.txt,.csv';

export const CleaningModal: React.FC<Props> = props => {
	const dispatch = useReduxDispatch();
	const history = useHistory();

	const [formState, setFormState] = React.useState({} as FormFields);
	const [selectedFacilityId, setSelectedFacilityId] = React.useState<number>(0);

	const [facilityOptionValues, setFacilityOptionValues] = React.useState([] as DropDownOption[]);
	const [extractorOptionValues, setExtractorOptionValues] = React.useState([] as DropDownOption[]);
	const [wasteTypeOptionValues, setWasteTypeOptionValues] = React.useState([] as DropDownOption[]);
	const [haulerOptionValues, setHaulerOptionValues] = React.useState([] as DropDownOption[]);
	const [unitOptionValues, setUnitOptionValues] = React.useState([] as DropDownOption[]);
	const [showDeleteCleaningModal, setShowDeleteCleaningModal] = React.useState(false);
	const scrollToTopRef = useRef<HTMLHeadingElement>(null);
	const [files, setFiles] = React.useState<CustomFormAttachment[]>([]);
	const [removedAttachments, setRemovedAttachments] = React.useState<number[]>([]);
	const [cleaningFormElement, setCleaningFormElement] = React.useState<CustomFieldType[]>([]);
	const [formType, setFormType] = React.useState('');
	const [templateJson, setTemplateJson] = useState('');
	const [deletedAttachment, setDeletedAttachment] = useState<CustomFormAttachment[]>([]);

	const haulerList = useRootStateSelector(s => s.haulers);
	const unitList = useRootStateSelector(s => s.extractors.unitList);

	const wasteTypes = useRootStateSelector(s => s.authorityWasteTypes.wasteTypes);

	const facilityExtractors = useRootStateSelector(s => s.extractors).facilityExtractorList;
	const extractors = useRootStateSelector(s => s.extractors).extractorList;

	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[ApiTypes.FeatureNames.ShowCustomCleaningFormsFeature]
	);

	React.useEffect(() => {
		if (
			((props.device && props.device.extractorId) || formState.deviceId) &&
			showCustomCleaningFormsFeature &&
			!Object.keys(props.cleaning || {}).length
		) {
			let deviceId = (props.device && props.device.extractorId) || formState.deviceId || 0;
			CustomFormService.loadCustomForm(deviceId, setTemplateJson, setFormType, setCleaningFormElement);
		}
	}, [props.device, formState.deviceId]);

	useEffect(() => {
		let newTemplateJson = props.cleaning && props.cleaning.pumpOutEventId ? formState.templateJson : templateJson;
		CustomFormService.updateForm(
			setTemplateJson,
			setFormType,
			formState,
			files,
			formState.attachments,
			true,
			setCleaningFormElement,
			newTemplateJson
		);
	}, [formState.templateJson, templateJson, files]);

	React.useEffect(() => {
		CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState);
	}, [templateJson, unitList, props.cleaning]);

	const handleChangeInputValue = (e: any, dataItem: CustomFieldType, fieldValue?: any) => {
		CustomFormService.handleChangeInputValue(
			e,
			dataItem,
			cleaningFormElement,
			setCleaningFormElement,
			deletedAttachment,
			setDeletedAttachment,
			fieldValue
		);
	};
	React.useEffect(() => {
		if (props.cleaning && props.cleaning.pumpOutEventId && !isCreatingNewCleaning()) {
			let url = urlService.getAuthorityResourcesApiUrl(
				`${Resource.FogFacilities}/${props.cleaning.organizationId || 0}/${Resource.Cleanings}/${
					props.cleaning.pumpOutEventId
				}/${Resource.Attachments}`
			);
			CustomFormService.fetchAttachments(url, setFiles);
		}
	}, [props.cleaning]);

	React.useEffect(() => {
		dispatch(loadAuthorityWasteTypes());
		dispatch(loadUnitList());
		dispatch(haulersSlice.fetchAll('size=2147483647'));

		setExtractorOptionValues([]);
		if (props.isFacilitiesGrid) {
			!props.device && props.isAddModal && dispatch(loadExtractorList(props.facilityId));
		} else if (props.facilityId) {
			dispatch(fogFacilitiesSlice.fetchOne(props.facilityId));
			!props.device && props.isAddModal && dispatch(loadExtractorList(props.facilityId));
		} else {
			!props.device && props.isAddModal && dispatch(loadExtractorList());
		}

		props.facilityId && setSelectedFacilityId(props.facilityId);

		if (props.facilityId && ((props.cleaning && props.cleaning.isDeviceRemoved) || extractors.length <= 0)) {
			const lookupTypeUrl = urlService.getFacilityResourceApiUrl(props.facilityId, '');
			apiService.getResource<ApiTypes.FogFacility>(lookupTypeUrl).then((response: ApiTypes.FogFacility) => {
				setFacilityOptionValues([
					{
						label: response.facilityName as string,
						value: response.facilityId,

						facilityNumber: response.facilityId,
						addressLine1: response.addressLine1,
						cityName: response.cityName,
						jurisdictionCode: response.jurisdictionCode,
						zipCode: response.zipCode
					}
				]);
			});
		}
	}, [dispatch, props.facilityId]);

	React.useEffect(() => {
		let newState = { ...formState, ...props.cleaning } as FormFields;
		if (props.device) {
			newState.deviceId = props.device.extractorId;
			newState.extractorTypeId = props.device.extractorTypeId;
			newState.extractorType = props.device.extractorType;
		}
		if (props.facilityId) {
			newState.facilityId = props.facilityId;
		}
		onPercentGreaseOperandChange(newState);
		setFormState(newState);
	}, [props.cleaning, props.device, props.facilityId]);

	React.useEffect(() => {
		if (!haulerList || haulerList.total < 1) return;

		let dropDownOptions: DropDownOption[] = [];
		dropDownOptions = haulerList.result
			.filter(a => a.isActive)
			.map((hauler: Hauler) => {
				return {
					label: hauler.name as string,
					value: hauler.haulerId
				};
			});

		if (formState.haulerId) {
			const deletedState: string = localizationService.getLocalizedString('screen.labels.dropDownItemRemoved');
			const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
			let selectedHaulerDropDown = haulerList.result.find(hauler => hauler.haulerId === formState.haulerId);
			if (selectedHaulerDropDown) {
				if (!selectedHaulerDropDown.isActive) {
					dropDownOptions.push({
						label: selectedHaulerDropDown.isActive
							? (selectedHaulerDropDown.name as string)
							: (`${selectedHaulerDropDown.name} (${inactiveState})` as string),
						value: selectedHaulerDropDown.haulerId,
						isHidden: true
					});
				}
			} else {
				dropDownOptions.push({
					label: `${formState.haulerName} ${deletedState}` as string,
					value: formState.haulerId,
					isHidden: true
				});
			}
		}

		setHaulerOptionValues(dropDownOptions);
	}, [haulerList]);

	React.useEffect(() => {
		if (unitList.length < 1) return;

		let unitOptionValues: DropDownOption[] = [];
		unitOptionValues = unitList
			.filter((unit: Unit) => unit.isActive)
			.map((unit: Unit) => {
				return { label: unit.name as string, value: unit.unitId };
			});
		if (props.cleaning && props.cleaning.amountPumpedUnitId) {
			let amountPumpedUnitId = props.cleaning.amountPumpedUnitId;
			let unitOptionValue = unitOptionValues.find(i => i.value === amountPumpedUnitId);
			if (!unitOptionValue) {
				let unit = unitList.find(i => i.unitId === amountPumpedUnitId);
				if (unit) {
					unitOptionValues.push({
						label: unit.name as string,
						value: amountPumpedUnitId,
						isHidden: true
					});
				}
			}
		}
		setUnitOptionValues(unitOptionValues);
	}, [unitList]);

	React.useEffect(() => {
		if (wasteTypes.length < 1) return;

		let wasteTypeOptionValues: DropDownOption[] = [];
		wasteTypeOptionValues = wasteTypes.map((wasteType: WasteType) => {
			return {
				label: wasteType.wasteTypeCode + '',
				value: wasteType.wasteTypeId,
				isHidden: !wasteType.isActive
			} as DropDownOption;
		});

		setWasteTypeOptionValues(wasteTypeOptionValues);
	}, [wasteTypes]);

	React.useEffect(() => {
		if (extractors.length <= 0) return;
		let facilityOptions: DropDownOption[] = [];
		if (!(props.cleaning && props.cleaning.isDeviceRemoved)) {
			const extractorList = props.facilityId
				? ([extractors.find(ex => ex.facilityId === props.facilityId)] as ApiTypes.Extractor[])
				: extractors;

			facilityOptions = extractorList
				.filter(extractor => extractor)
				.map((extractor: ApiTypes.Extractor) => {
					return {
						label: extractor.facilityName,
						value: extractor.facilityId,

						facilityNumber: extractor.facilityNumber,
						addressLine1: extractor.addressLine1,
						cityName: extractor.cityName,
						jurisdictionCode: extractor.jurisdictionCode,
						zipCode: extractor.zipCode
					} as DropDownOption;
				})
				.filter((schema, index, self) => {
					return index === self.findIndex(obj => obj.value === schema.value);
				})
				.sort((facilityA, facilityB) => {
					return facilityA.label.toUpperCase() > facilityB.label.toUpperCase() ? 1 : -1;
				});
			setFacilityOptionValues(facilityOptions);
		}
		setSelectedFacilityId(props.facilityId ? props.facilityId : 0);
	}, [facilityExtractors, extractors]);

	React.useEffect(() => {
		if (props.device && props.device.facilityId) {
			setSelectedFacilityId(props.device.facilityId);
		}
	}, [props.device]);

	React.useEffect(() => {
		if (props.device) return;
		if (extractors.length < 0) return;

		let extractorOptions: DropDownOption[] = [];

		if (!props.facilityId) {
			extractorOptions = extractors
				.filter(extractor => extractor.facilityId === selectedFacilityId)
				.map(extractor => getDeviceItem(extractor))
				.sort((extractorA, extractorB) => {
					return extractorA.label.toUpperCase() > extractorB.label.toUpperCase() ? -1 : 1;
				});
		}

		setExtractorOptionValues(extractorOptions);
	}, [selectedFacilityId]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		if (name === nameof<ApiTypes.Extractor>(d => d.facilityId)) {
			setSelectedFacilityId(value && Number(value));

			newState.deviceId = undefined;
			newState.extractorType = undefined;
		}

		_.set(newState, name, value);

		setFormState(newState);

		const percentGreaseFields = ['fog', 'solid', 'trapDepth'];
		if (percentGreaseFields.includes(name)) {
			onPercentGreaseOperandChange(newState);
		}
	};

	const onPercentGreaseOperandChange = (newState: FormFields) => {
		let trapDepth = newState.trapDepth;
		let fog = newState.fog;
		let solid = newState.solid;

		if (trapDepth && fog && solid && trapDepth > 0) {
			let waste = +fog + +solid;
			let percentGrease = Math.round((+waste / +trapDepth) * 100);
			_.set(newState, 'percentGrease', percentGrease);
		} else {
			_.unset(newState, 'percentGrease');
		}
	};

	const handleDrop = (chosenFiles: any) => {
		CustomFormService.handleDrop(chosenFiles, files, setFiles);
	};

	const removeFile = (index: number, size: number = 10) => {
		return (
			<span
				id="remove-file"
				className="cursor-pointer p-1"
				onClick={event => {
					event.stopPropagation();
					let selectedFiles = _.cloneDeep(files);
					selectedFiles[index] &&
						selectedFiles[index].attachmentId &&
						setRemovedAttachments([...removedAttachments, selectedFiles[index].attachmentId || 0]);
					selectedFiles.splice(index, 1);
					setFiles(selectedFiles);
				}}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};

	const onFileChangeHandler = (event: any) => {
		CustomFormService.onFileChangeHandler(event, files, setFiles);
	};

	const leavingOutletOptions = () => {
		const leavingOutletOptions = ['Yes', 'No', 'Not observed'];
		return leavingOutletOptions.map(leavingOutletOption => {
			let option: DropDownOption = {
				label: leavingOutletOption,
				value: leavingOutletOption
			};
			return option;
		});
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'facilityId',
			'facilityIdError',
			localizationService.getLocalizedString('facility.facility')
		);
		validationService.validateRequiredField(
			newState,
			'deviceId',
			'deviceIdError',
			localizationService.getLocalizedString('pumpOut.device')
		);

		if (props.dueDateIsRequired) {
			validationService.validateRequiredDateField(
				newState,
				'dueDate',
				'dueDateError',
				localizationService.getLocalizedString('pumpOut.dueDate')
			);
		} else {
			validationService.validateMinimumDate(
				newState,
				'dueDate',
				'dueDateError',
				localizationService.getLocalizedString('pumpOut.dueDate')
			);
		}
		if (props.completeDateIsRequired) {
			let maxDate = DateUtilService.getAuthorityTimezoneNow();
			validationService.validateRequiredDateField(
				newState,
				'completeDate',
				'completeDateError',
				localizationService.getLocalizedString('pumpOut.completeDate'),
				maxDate
			);
		} else {
			let maxDate = DateUtilService.getAuthorityTimezoneNow();
			if (
				validationService.validateMaximumDate(
					newState,
					'completeDate',
					'completeDateError',
					localizationService.getLocalizedString('pumpOut.completeDate'),
					maxDate
				)
			) {
				validationService.validateMinimumDate(
					newState,
					'completeDate',
					'completeDateError',
					localizationService.getLocalizedString('pumpOut.completeDate')
				);
			}
		}
		if (!newState.completeDate && newState.inComplianceDate) {
			newState.inComplianceDateError = localizationService.getLocalizedString(
				'pumpOut.inComplianceDateConditionMessage'
			);
			validationService.validateMinimumDate(
				newState,
				'inComplianceDate',
				'inComplianceDateError',
				localizationService.getLocalizedString('pumpOut.inComplianceDate')
			);
		} else {
			newState.inComplianceDateError = '';
		}

		validationService.validateNumberField(
			newState,
			'amountPumped',
			'amountPumpedError',
			false,
			'pumpOut.amountPumped'
		);

		if (newState.isRepairNeeded) {
			validationService.validateRequiredField(
				newState,
				'isRepairNeededReason',
				'isRepairNeededReasonError',
				localizationService.getLocalizedString('pumpOut.isRepairNeededReason')
			);
		} else {
			_.unset(newState, 'isRepairNeededReasonError');
		}

		setFormState(newState);
		const isFromValid = !validationService.hasError(
			newState,
			'deviceIdError',
			'facilityIdError',
			'dueDateError',
			'completeDateError',
			'amountPumpedError',
			'inComplianceDateError',
			'isRepairNeededReasonError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const afterSubmitCleaningApiCallProcess = () =>
		(props.currentDeviceIndex || 0) + 1 === props.totalDevices
			? (function() {
					afterApiCallProcesses();
					props.modalToggleFunction && props.modalToggleFunction();
					props.closeDeviceModal && props.closeDeviceModal();
			  })()
			: (function() {
					props.increaseCurrentDeviceIndex && props.increaseCurrentDeviceIndex();
					scrollToTopRef.current && scrollToTopRef.current.scrollIntoView({ behavior: 'smooth' });
			  })();

	const uploadFiles = async (ownerId: number) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		let formData = new FormData();
		for (let x = 0; x < files.length; x++) {
			!files[x].fileName && formData.append('files', files[x]);
		}
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.FogFacilities}/${selectedFacilityId}/${Resource.Attachments}/upload?ownerId=${ownerId}&attachmentType=${ApiTypes.AttachmentType.FogDeviceCleaning}`
		);
		await apiService.postFormData(url, formData, headers);
	};

	const uploadFile = async (file: CustomFormAttachment, ownerId: number, attachmentSectionKey: string) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		if (!file) return;

		let formData = new FormData();
		if (file.fileName) {
			return;
		}
		formData.append('files', file);
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.FogFacilities}/${selectedFacilityId}/${Resource.Attachments}/upload?ownerId=${ownerId}&attachmentType=${ApiTypes.AttachmentType.FogDeviceCleaning}&attachmentSectionKey=${attachmentSectionKey}`
		);
		await apiService.postFormData(url, formData, headers);
	};

	const removeFiles = async (ownerId: number) => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.FogFacilities}/${selectedFacilityId}/${Resource.Cleanings}/${ownerId}/${
				Resource.Attachments
			}?attachmentIds=${[...removedAttachments]}`
		);
		await apiService.deleteResource(url);
	};

	const addValuesToTheHtmlContent = () => {
		$('input').each(function() {
			if ($(this).is(':checkbox')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else if ($(this).is(':radio')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else {
				$(this).attr('value', ($(this) as any).val());
			}
		});

		$('textarea').each(function() {
			$(this).html(($(this) as any).val());
		});

		$('select').each(function() {
			$(this)
				.find(':not(:selected)')
				.removeAttr('selected');
		});

		$('select').each(function() {
			$(this)
				.find(':selected')
				.attr('selected', 'selected');
		});
	};

	const getAllFormValues = () => {
		addValuesToTheHtmlContent();

		let paramObj: any = {};
		let formId = `#cleaningSubmissionForm`;
		$.each($(formId).serializeArray(), function(_, kv) {
			paramObj[kv.name] = kv.value;
		});

		return UtilService.toJson(paramObj);
	};

	const handleValidateCustomField = () => {
		let newCleaningFormElement = _.cloneDeep(cleaningFormElement);
		let newState = _.cloneDeep(formState);
		let hasCleaningCompleteDate = false;
		let hasCleaningDueDate = false;

		_.each(newCleaningFormElement, facilityCleaningFormElement => {
			if (facilityCleaningFormElement.name === DueDate) {
				if (facilityCleaningFormElement.inputs) {
					let customField = facilityCleaningFormElement.inputs[0];
					if (customField.value) {
						hasCleaningDueDate = true;
					}
				}
			}
			if (facilityCleaningFormElement.name === CompleteDate) {
				if (facilityCleaningFormElement.inputs) {
					let customField = facilityCleaningFormElement.inputs[0];
					if (customField.value) {
						hasCleaningCompleteDate = true;
					}
				}
			}
		});

		if (!hasCleaningCompleteDate && hasCleaningDueDate) {
			return true;
		}
		_.each(newCleaningFormElement, facilityCleaningFormElement => {
			if (facilityCleaningFormElement.name === CompleteDate) {
				if (facilityCleaningFormElement.inputs) {
					let customField = facilityCleaningFormElement.inputs[0];
					let maxDate = DateUtilService.getAuthorityTimezoneNow();
					if (customField.isRequired) {
						validationService.validateRequiredDateField(
							facilityCleaningFormElement.inputs[0],
							'value',
							'error',
							customField.fieldLabel,
							maxDate
						);
					} else {
						if (
							validationService.validateMaximumDate(
								customField,
								'value',
								'error',
								customField.fieldLabel,
								maxDate
							)
						) {
							validationService.validateMinimumDate(
								customField,
								'value',
								'error',
								customField.fieldLabel
							);
						}
					}
				}

				if (
					facilityCleaningFormElement.inputs &&
					!facilityCleaningFormElement.inputs[0].value &&
					newState.inComplianceDate
				) {
					validationService.validateMinimumDate(
						newState,
						'inComplianceDate',
						'inComplianceDateError',
						localizationService.getLocalizedString('pumpOut.inComplianceDate')
					);
					newState.inComplianceDateError = localizationService.getLocalizedString(
						'pumpOut.inComplianceDateConditionMessage'
					);
				} else {
					newState.inComplianceDateError = '';
				}
			} else {
				validationService.validateCustomFormField(facilityCleaningFormElement.inputs || []);
			}
		});
		let hasError = false;
		_.each(newCleaningFormElement, cleaningFormElement => {
			_.each(cleaningFormElement.inputs, inputs => {
				if (
					validationService.hasError(inputs, 'error') ||
					validationService.hasError(newState, 'inComplianceDateError')
				) {
					hasError = true;
				}
			});
		});

		setFormState(newState);

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		setCleaningFormElement(newCleaningFormElement);
		return !hasError;
	};

	const createCustomPayloadFromTemplateJson = () => {
		let payload: any = {};
		_.each(cleaningFormElement, cleaningFormElement => {
			const { inputs } = cleaningFormElement;
			if (cleaningFormElement.name === Attachments) {
				return;
			}
			_.each(inputs, input => {
				if (input && input.fieldId) {
					payload[input.fieldId] = input.value;
					if (input.fieldId === _.toLower(Comments)) {
						payload['comments'] = input.value;
					}
				}
			});
		});
		return payload;
	};

	const callBackAfterApiCompletion = (nextPumpOutEventDate: string) => {
		props.isSubmitCleaning ? afterSubmitCleaningApiCallProcess() : afterApiCallProcesses();
		if (nextPumpOutEventDate) {
			alertService.addInfo(
				localizationService.getLocalizedString(
					'pumpOut.nextCleaningScheduled',
					DateUtilService.toDisplayDate(nextPumpOutEventDate) || ''
				)
			);
		}
	};

	const saveCleaningEvent = async (e: any) => {
		if (
			showCustomCleaningFormsFeature && !props.isScheduleCleaning && templateJson
				? !handleValidateCustomField()
				: !isFormValidateForSave()
		) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}
		let facilityIdForUrl = selectedFacilityId || props.facilityId;

		let allFromValues = getAllFormValues();
		let formId = `cleaningSubmissionForm`;
		let node = document.getElementById(formId);
		let errorFieldElements = node && node.querySelectorAll('.ai-form-help.ai-required');
		errorFieldElements && errorFieldElements.forEach(errorFieldElement => errorFieldElement.remove());
		let htmlContent = node ? node.innerHTML : '';
		let percentGreaseElement = document.getElementById('percentGrease');
		let percentGrease =
			percentGreaseElement && (percentGreaseElement.textContent || percentGreaseElement.innerText || '');
		let pumpOutEventToAddOrUpdate = {
			...formState,
			cleaningManifestHtmlContent: htmlContent,
			cleaningManifestFormKeyValuePair: allFromValues
		};
		if (showCustomCleaningFormsFeature && templateJson) {
			pumpOutEventToAddOrUpdate.templateJson = templateJson;
		}
		if (showCustomCleaningFormsFeature && !props.isScheduleCleaning && templateJson) {
			pumpOutEventToAddOrUpdate = {
				...createCustomPayloadFromTemplateJson(),
				templateJson: templateJson,
				deviceId: formState.deviceId,
				cleaningManifestHtmlContent: htmlContent,
				cleaningManifestFormKeyValuePair: allFromValues,
				inComplianceDate: formState.inComplianceDate,
				percentGrease: (percentGrease || '').replace('%', ''),
				...CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState)
			};
		}
		cleaningSlice.setApiUrlPath(Resource.FogFacilities + '/' + facilityIdForUrl + '/' + Resource.Cleanings);

		if (isCreatingNewCleaning()) {
			dispatch(
				cleaningSlice.createOne(
					pumpOutEventToAddOrUpdate,
					false,
					localizationService.getLocalizedString('alertMessages.addSuccess', 'pumpOut.cleaning'),
					(data: any) => {
						const { items } = data;
						if (showCustomCleaningFormsFeature && templateJson) {
							let isAttachmentsPresent = false;
							_.each(cleaningFormElement, cleaningFormElement => {
								const { inputs } = cleaningFormElement;
								if (cleaningFormElement.name !== Attachments) {
									return;
								}
								isAttachmentsPresent = true;
								const attachmentPromisesList: any = [];
								_.each(inputs, input => {
									input.value &&
										attachmentPromisesList.push(
											uploadFile(
												(input.value[0] as unknown) as CustomFormAttachment,
												(items[0] as any).pumpOutEventId,
												input.fieldId || ''
											)
										);
								});
								if (attachmentPromisesList.length) {
									Promise.all(attachmentPromisesList)
										.then(() => {
											callBackAfterApiCompletion(items[0].nextPumpOutEventDate);
										})
										.catch(err => alertService.addError(err.message));
								} else {
									callBackAfterApiCompletion(items[0].nextPumpOutEventDate);
								}
							});
							if (!isAttachmentsPresent) {
								callBackAfterApiCompletion(items[0].nextPumpOutEventDate);
							}
						} else {
							if (files.length) {
								uploadFiles((items[0] as any).pumpOutEventId)
									.then(() => {
										callBackAfterApiCompletion(items[0].nextPumpOutEventDate);
									})
									.catch(err => alertService.addError(err.message));
							} else {
								callBackAfterApiCompletion(items[0].nextPumpOutEventDate);
							}
						}
					}
				)
			);
		} else if (props.cleaning && props.cleaning.pumpOutEventId) {
			delete (pumpOutEventToAddOrUpdate as any).attachments;
			delete (pumpOutEventToAddOrUpdate as any).violations;
			dispatch(
				cleaningSlice.patchOne(
					props.cleaning.pumpOutEventId,
					pumpOutEventToAddOrUpdate,
					false,
					localizationService.getLocalizedString('alertMessages.savedSuccess', 'pumpOut.cleaning'),
					async (item: any) => {
						if (showCustomCleaningFormsFeature && templateJson) {
							let isAttachmentsPresent = false;
							_.each(cleaningFormElement, cleaningFormElement => {
								const { inputs } = cleaningFormElement;
								if (cleaningFormElement.name !== Attachments) {
									return;
								}
								isAttachmentsPresent = true;
								const attachmentPromisesList: any = [];
								_.each(inputs, input => {
									input.value &&
										attachmentPromisesList.push(
											uploadFile(
												(input.value[0] as unknown) as CustomFormAttachment,
												(item as any).pumpOutEventId,
												input.fieldId || ''
											)
										);
								});
								_.each(deletedAttachment, (deletedAttachment: CustomFormAttachment) => {
									attachmentPromisesList.push(
										dispatch(
											deleteFacilityAttachment(
												deletedAttachment.organizationId || 0,
												deletedAttachment.attachmentOwnershipId || 0,
												''
											)
										)
									);
								});
								if (attachmentPromisesList.length) {
									Promise.all(attachmentPromisesList)
										.then(() => {
											callBackAfterApiCompletion(item.nextPumpOutEventDate);
										})
										.catch(err => alertService.addError(err.message));
								} else {
									callBackAfterApiCompletion(item.nextPumpOutEventDate);
								}
							});
							if (!isAttachmentsPresent) {
								callBackAfterApiCompletion(item.nextPumpOutEventDate);
							}
						} else {
							if (files.length || removedAttachments.length) {
								files.filter(file => !file.fileName).length &&
									(await uploadFiles((item as any).pumpOutEventId));
								if (urlService.getAttachmentIdFromUrl() > -1) {
									const currentAttachment = removedAttachments.find(
										fileId => fileId == urlService.getAttachmentIdFromUrl()
									);
									if (currentAttachment) {
										const facilityDetailUrl = urlService.getReactFacilityDetailsUrl(
											props.facilityId
										);
										navigateTo(history, facilityDetailUrl);
									}
								}

								removedAttachments.length && (await removeFiles((item as any).pumpOutEventId));
								callBackAfterApiCompletion(item.nextPumpOutEventDate);
							} else {
								callBackAfterApiCompletion(item.nextPumpOutEventDate);
							}
						}
					}
				)
			);
		}
	};

	const afterApiCallProcesses = () => {
		props.modalToggleFunction();
		props.afterApiCallback && props.afterApiCallback();
		setFiles([]);
		setRemovedAttachments([]);
		if (props.isCleaningGrid) {
			let isImportHistoryGrid = window.location.pathname.includes('settings/import/history/');
			if (isImportHistoryGrid) {
				navigateTo(history, window.location.href);
				dispatch(cleaningSlice.reload());
			} else {
				cleaningSlice.setApiUrlPath(`${urlService.getDomainObjectName()}`);
				dispatch(cleaningSlice.fetchAll(urlService.toQueryString(props.queryParameters || {})));
			}
		} else if (props.facilityId && !(urlService.getFacilityAttachmentId() > -1)) {
			handleDispatchesAndReloads();
		}
		if (props.isFacilitiesGrid) {
			dispatch(fogFacilitiesSlice.fetchAll(urlService.toQueryString(props.queryParameters || {})));
		}
	};

	const handleDispatchesAndReloads = () => {
		if (props.isDevicePage && props.isGridPage) {
			dispatch(fogDevicesSlice.reload());
		} else {
			if (props.device) {
				dispatch(fogFacilitiesSlice.fetchOne(props.device.facilityId || (props.facilityId as number)));
				dispatch(fogDevicesSlice.fetchOne(props.device.extractorId as number));
			}
		}

		if (!props.isFacilityPage && props.isTimelineReloadRequired) {
			reloadTimelineEventsFromServer();
		}
		//This is currently the only way to reload the facility details page and
		//load the facility cleaning events in the timeline
		if (props.isFacilityPage) {
			let dateRange = getTimelineSettingDateRange();
			dispatch(
				loadFacilityCleaningManifest(
					dateRange.startDateStr,
					dateRange.endDateStr,
					showCustomCleaningFormsFeature
				)
			);
			dispatch(loadCurrentFogFacility());
			dispatch(loadFacilityPumpOutEvents());
			dispatch(loadExtractorList(props.facilityId));
			dispatch(loadFacilityAttachments(props.facilityId, true));
			dispatch(loadFacilityViolations());
			dispatch(loadFacilityTimelineEnforcements());
		}
	};

	const deleteCleaningEvent = () => {
		setShowDeleteCleaningModal(false);
		if (props.cleaning) {
			cleaningSlice.setApiUrlPath(Resource.FogFacilities + '/' + props.facilityId + '/' + Resource.Cleanings);
			dispatch(
				cleaningSlice.deleteOne(
					props.cleaning.pumpOutEventId as number,
					false,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'pumpOut.cleaning'),
					() => {
						if (urlService.getAttachmentIdFromUrl() > -1) {
							const facilityDetailUrl = urlService.getReactFacilityDetailsUrl(props.facilityId);
							navigateTo(history, facilityDetailUrl);
						}
						afterApiCallProcesses();
					}
				)
			);
		}
	};
	const cancelSave = () => {
		props.modalToggleFunction();
		setFiles([]);
		setRemovedAttachments([]);
	};

	function getDeviceItem(device: ApiTypes.Extractor) {
		let label = getDeviceInfo(device);

		if (label.indexOf('-') === 0) {
			label = label.substring(1);
		}

		return {
			label,
			value: device.extractorId,
			isHidden: !device.isActive
		} as DropDownOption;
	}

	function getDeviceInfo(device: Extractor) {
		const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
		let description = (_.get(device, 'extractorDescription') || '').substring(0, 40);
		return `${description} | ${device.location ? device.location + ' |' : ' |'} ${device.deviceNumber} ${
			device.isActive ? '' : `(${inactiveState})`
		}`.trim();
	}

	const modalFooterDiv = () => {
		return (
			<>
				{!isCreatingNewCleaning() && !props.isSubmitCleaning && !props.hideDeleteButton && (
					<button
						type="button"
						className="btn ai-secondary-delete"
						onClick={() => {
							setShowDeleteCleaningModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}

				<div className="ml-auto">
					{props.isSubmitCleaning ? (
						<button className="btn ai-action" onClick={saveCleaningEvent}>
							{(props.currentDeviceIndex || 0) + 1 === props.totalDevices
								? localizationService.getLocalizedString('haulerPortal.submitCleaning.buttons.submit')
								: localizationService.getLocalizedString(
										'haulerPortal.submitCleaning.buttons.submitAndNext'
								  )}
						</button>
					) : isCreatingNewCleaning() && !props.isScheduleCleaning ? (
						<button className="btn ai-action" onClick={saveCleaningEvent}>
							{localizationService.getLocalizedString('screen.buttons.submit')}
						</button>
					) : (
						<button className="btn ai-save" onClick={saveCleaningEvent}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</button>
					)}
					<button className="btn ai-white ml-2" onClick={cancelSave}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	const isCreatingNewCleaning = () => {
		return (
			(props.isEnterCleaning && (!props.cleaning || (props.cleaning && !props.cleaning.pumpOutEventId))) ||
			props.isScheduleCleaning
		);
	};

	const scheduleCleaningFields = () => {
		return (
			<>
				{props.isFacilityPage && (
					<SingleSelectDropdown
						id="deviceId"
						name="deviceId"
						label={localizationService.getLocalizedString('pumpOut.device')}
						value={_.toString(formState.deviceId)}
						onChange={changeFormState}
						options={facilityExtractors
							.filter((extractor: Extractor) => extractor.isActive && !extractor.nextCleaningId)
							.map(extr => getDeviceItem(extr))
							.sort((extrA, extrB) => (extrA.label.toUpperCase() > extrB.label.toUpperCase() ? -1 : 1))}
						isRequired={true}
						error={formState.deviceIdError}
						hintText={localizationService.getLocalizedString('pumpOut.deviceDropDown')}
					/>
				)}
				<DateInput
					id="dueDate"
					name="dueDate"
					className="form-group"
					label={localizationService.getLocalizedString('pumpOut.dueDate')}
					isRequired={props.dueDateIsRequired}
					value={formState.dueDate}
					onChange={changeFormState}
					error={formState.dueDateError}
				/>
			</>
		);
	};

	const getActiveFacility = (): DropDownOption => {
		let facility = facilityOptionValues
			.filter((f: ApiTypes.DropDownOption) => f.value === selectedFacilityId)
			.pop();

		return facility ? facility : { label: '', value: '' };
	};

	const facilityListItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const item = itemProps.dataItem as DropDownOption;

		const itemChildren = <div className="border-bottom w-100">{renderFacilityInfo(item)}</div>;

		return React.cloneElement(li, li.props, itemChildren);
	};
	const renderFacilityInfo = (facility: DropDownOption, strong = true) => {
		if (!props.isAddModal) {
			const {
				facilityCityName,
				facilityJurisdictionCode,
				facilityName,
				facilityZipCode,
				facilityAddress,
				facilityAddressLine1
			} = props.cleaning || ({} as ApiTypes.CleaningEvent);
			return (
				<>
					{strong ? <strong> {facilityName}</strong> : facilityName} <br />
					{(facilityAddress || facilityAddressLine1) && (
						<>
							{facilityAddress || facilityAddressLine1} <br />
						</>
					)}
					{[
						facilityCityName ? facilityCityName + ',' : facilityCityName,
						facilityJurisdictionCode,
						facilityZipCode
					]
						.filter(Boolean)
						.join('\u0020')}
				</>
			);
		}
		return (
			<>
				{strong ? <strong> {facility.label}</strong> : facility.label} <br />
				{facility.value} <br />
				{facility.addressLine1 && (
					<>
						{facility.addressLine1} <br />
					</>
				)}
				{[
					facility.cityName ? facility.cityName + ',' : facility.cityName,
					facility.jurisdictionCode,
					facility.zipCode
				]
					.filter(Boolean)
					.join('\u0020')}
			</>
		);
	};

	const renderDeviceInfo = () => {
		let description =
			props.cleaning && props.cleaning.deviceDescription ? props.cleaning.deviceDescription.substring(0, 40) : '';
		return (
			<>
				<p>
					<strong>{localizationService.getLocalizedString('extractor.locationDescription')}</strong>
					<br />
					{description || (props.device && props.device.extractorDescription)}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('extractor.location')}</strong>
					<br />
					{(props.cleaning && props.cleaning!.deviceLocation) ||
						(props.device && props.device.location) ||
						''}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('extractor.deviceType')}</strong>
					<br />
					{(props.cleaning && (props.cleaning.extractorType || props.cleaning.deviceType)) ||
						(props.device && props.device!.extractorType) ||
						''}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('pumpOut.deviceNumber')}</strong>
					<br />
					{(props.cleaning && props.cleaning.deviceNumber) ||
						(props.device && props.device!.deviceNumber) ||
						''}
				</p>
			</>
		);
	};

	const generateViolationMessage = () => {
		let cleaningViolations: ApiTypes.CleaningEventViolation[] = props.cleaning!.violations || [];
		const messagePart1 = localizationService.getLocalizedString(
			`pumpOut.cleaningViolationPart1${cleaningViolations.length > 1 ? 'Multiple' : 'Single'}`
		);
		const violationText = localizationService.getLocalizedString(
			`pumpOut.${cleaningViolations.length > 1 ? 'violationsText' : 'violationText'}`
		);
		const messagePart2 = localizationService.getLocalizedString('pumpOut.cleaningViolationPart2');
		let violationMessage = (
			<>
				{messagePart1}
				{cleaningViolations.map((violation, index) => {
					index++;
					return (
						<>
							<span>{violation.violationTypeName}</span>
							{index === 1 &&
								index !== cleaningViolations.length &&
								cleaningViolations.length - index !== 1 &&
								', '}
							{cleaningViolations.length - index === 1 &&
								localizationService.getLocalizedString('pumpOut.and')}
						</>
					);
				})}
				&nbsp;
				{violationText}
				{messagePart2}
			</>
		);
		return violationMessage;
	};

	const getMaxNonComplianceDate = () => {
		if (props.cleaning && props.cleaning.violations && props.cleaning.violations.length > 0) {
			const dates = props.cleaning.violations.map(violation => violation.nonComplianceDate);
			var maxDate = dates.reduce(function(a, b) {
				return a > b ? a : b;
			});
			return maxDate;
		}
	};

	const customFilter = (options: DropDownOption[], filter: FilterDescriptor): DropDownOption[] => {
		let newOptions = _.cloneDeep(options);
		newOptions = newOptions.filter((option: DropDownOption) => {
			return (
				(option.addressLine1 && _.toLower(option.addressLine1).includes(_.toLower(filter.value.trim()))) ||
				_.toLower(option.facilityNumber).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.label).includes(_.toLower(filter.value.trim()))
			);
		});
		return newOptions;
	};

	const cleaningViolation = () => {
		return (
			props.cleaning &&
			props.cleaning!.violations &&
			!!props.cleaning!.violations.length &&
			!props.cleaning.completeDate && (
				<>
					<Notification message={generateViolationMessage()} />
					<DateInput
						id="inComplianceDate"
						name="inComplianceDate"
						className="mt-3 form-group"
						label={localizationService.getLocalizedString('pumpOut.inComplianceDate')}
						min={getMaxNonComplianceDate()}
						value={formState.inComplianceDate}
						onChange={changeFormState}
						error={formState.inComplianceDateError}
					/>
				</>
			)
		);
	};

	const detailedCleaningFields = () => {
		return (
			<>
				{props.isSubmitCleaning && (props.totalDevices || 0) > 1 && (
					<h2 className="device-number" ref={scrollToTopRef}>
						{localizationService.getLocalizedString(
							'haulerPortal.submitCleaning.deviceSelected',
							((props.currentDeviceIndex || 0) + 1).toString(),
							(props.totalDevices || 0).toString() as string
						)}
					</h2>
				)}
				{(props.isDevicePage ||
					props.isSubmitCleaning ||
					(props.device && props.device.extractorId) ||
					(props.cleaning && props.cleaning.pumpOutEventId)) && (
					<div className="fieldset mb-4">
						{props.facilityId > 0 && (
							<p>
								<strong>{localizationService.getLocalizedString('facility.facility')}</strong>
								<br />
								{renderFacilityInfo(getActiveFacility(), false)}{' '}
							</p>
						)}

						{renderDeviceInfo()}
					</div>
				)}

				{!props.device && !props.facilityId && (
					<ComboBoxDropdown
						options={facilityOptionValues}
						id="facility"
						name="facilityId"
						itemRender={facilityListItemRender}
						className="col-sm-12 p-0 "
						onChange={changeFormState}
						clearButton={false}
						value={_.toString(formState.facilityId)}
						error={formState.facilityIdError}
						label={localizationService.getLocalizedString('facility.facility')}
						customFilter={customFilter}
						isRequired
						showSearchIcon
					/>
				)}

				{!props.device && selectedFacilityId > 0 && !(props.cleaning && props.cleaning.pumpOutEventId) && (
					<SingleSelectDropdown
						id="deviceId"
						name="deviceId"
						label={localizationService.getLocalizedString('pumpOut.device')}
						value={_.toString(formState.deviceId)}
						onChange={changeFormState}
						options={extractorOptionValues}
						isRequired={true}
						error={formState.deviceIdError}
						hintText={localizationService.getLocalizedString('pumpOut.deviceDropDown')}
					/>
				)}
				<form id={`cleaningSubmissionForm`} key={`cleaningSubmissionForm`} autoComplete="off">
					{!!cleaningFormElement.length && showCustomCleaningFormsFeature && templateJson ? (
						<>
							{ConverterFactory.getInputsFromDataItem(
								cleaningFormElement,
								handleChangeInputValue,
								true,
								formType
							).map(formData => {
								return <div>{formData}</div>;
							})}
							{cleaningViolation()}
						</>
					) : (
						//Below form will used form imported complete cleaning and legacy cleaning which does not have tCleaningManifest record.
						<>
							<DateInput
								id="dueDate"
								name="dueDate"
								label={localizationService.getLocalizedString('pumpOut.dueDate')}
								isRequired={props.dueDateIsRequired}
								value={formState.dueDate}
								onChange={changeFormState}
								error={formState.dueDateError}
							/>

							<DateInput
								id="completeDate"
								name="completeDate"
								label={localizationService.getLocalizedString('pumpOut.cleaningDate')}
								max={DateUtilService.getAuthorityTimezoneNow()}
								isRequired={props.completeDateIsRequired}
								value={formState.completeDate}
								onChange={changeFormState}
								error={formState.completeDateError}
							/>

							<TextInput
								id="manifestNumber"
								name="manifestNumber"
								label={localizationService.getLocalizedString('pumpOut.manifestNumber')}
								value={formState.manifestNumber}
								onChange={changeFormState}
								error={formState.manifestNumberError}
							/>

							<SingleSelectDropdown
								id="wasteTypeId"
								name="wasteTypeId"
								label={localizationService.getLocalizedString('pumpOut.wasteType')}
								value={_.toString(formState.wasteTypeId)}
								onChange={changeFormState}
								options={wasteTypeOptionValues}
							/>

							<div className="form-row">
								<TextInput
									id="amountPumped"
									name="amountPumped"
									label={localizationService.getLocalizedString('pumpOut.wasteRemoved')}
									value={formState.amountPumped ? '' + formState.amountPumped : undefined}
									onChange={changeFormState}
									className="form-group col-sm-6"
									error={formState.amountPumpedError}
								/>
								<SingleSelectDropdown
									id="amountPumpedUnitId"
									name="amountPumpedUnitId"
									className="form-group col-sm-6"
									label={localizationService.getLocalizedString('pumpOut.unit')}
									value={_.toString(formState.amountPumpedUnitId)}
									onChange={changeFormState}
									options={unitOptionValues}
								/>
							</div>

							<div className="form-row">
								<TextInput
									id="trapDepth"
									name="trapDepth"
									label={localizationService.getLocalizedString('pumpOut.trapDepth')}
									value={formState.trapDepth ? '' + formState.trapDepth : undefined}
									onChange={changeFormState}
									className="form-group col-sm-6"
								/>
								<SingleSelectDropdown
									id="trapDepthUnitId"
									name="trapDepthUnitId"
									className="form-group col-sm-6"
									label={localizationService.getLocalizedString('pumpOut.unit')}
									value={_.toString(formState.trapDepthUnitId)}
									onChange={changeFormState}
									options={unitOptionValues}
								/>
							</div>

							<div className="form-row">
								<TextInput
									id="fog"
									name="fog"
									label={localizationService.getLocalizedString('pumpOut.fogDepth')}
									value={formState.fog ? '' + formState.fog : undefined}
									onChange={changeFormState}
									className="form-group col-sm-6"
								/>
								<SingleSelectDropdown
									id="fogUnitId"
									name="fogUnitId"
									className="form-group col-sm-6"
									label={localizationService.getLocalizedString('pumpOut.unit')}
									value={_.toString(formState.fogUnitId)}
									onChange={changeFormState}
									options={unitOptionValues}
								/>
							</div>

							<div className="form-row">
								<TextInput
									id="solid"
									name="solid"
									label={localizationService.getLocalizedString('pumpOut.solidsDepth')}
									value={formState.solid ? '' + formState.solid : undefined}
									onChange={changeFormState}
									className="form-group col-sm-6"
								/>
								<SingleSelectDropdown
									id="solidUnitId"
									name="solidUnitId"
									className="form-group col-sm-6"
									label={localizationService.getLocalizedString('pumpOut.unit')}
									value={_.toString(formState.solidUnitId)}
									onChange={changeFormState}
									options={unitOptionValues}
								/>
							</div>

							<p>
								{`${localizationService.getLocalizedString('pumpOut.percentGrease')}: `}
								{formState.percentGrease && <strong>{`${formState.percentGrease}%`}</strong>}
							</p>
							<SingleSelectDropdown
								id="leavingOutlet"
								name="leavingOutlet"
								className="form-group"
								label={localizationService.getLocalizedString('pumpOut.leavingOutlet')}
								value={formState.leavingOutlet ? formState.leavingOutlet : ''}
								onChange={changeFormState}
								options={leavingOutletOptions()}
								selfOrder={true}
							/>
							<SingleSelectDropdown
								id="haulerId"
								name="haulerId"
								label={localizationService.getLocalizedString('pumpOut.hauler')}
								value={_.toString(formState.haulerId)}
								onChange={changeFormState}
								options={haulerOptionValues}
							/>

							<TextInput
								id="driverName"
								name="driverName"
								label={localizationService.getLocalizedString('pumpOut.driverName')}
								value={formState.driverName}
								onChange={changeFormState}
							/>

							<TextInput
								id="cleanedBy"
								name="cleanedBy"
								label={localizationService.getLocalizedString('pumpOut.cleanedBy')}
								value={formState.cleanedBy}
								onChange={changeFormState}
							/>

							<TextInput
								id="disposalLocation"
								name="disposalLocation"
								label={localizationService.getLocalizedString('pumpOut.disposalLocation')}
								value={formState.disposalLocation}
								onChange={changeFormState}
							/>
							<SingleCheckbox
								id="isRepairNeeded"
								name="isRepairNeeded"
								className="form-group"
								label={localizationService.getLocalizedString('pumpOut.isRepairNeeded')}
								checked={formState.isRepairNeeded ? formState.isRepairNeeded : false}
								onChange={changeFormState}
							/>
							{formState.isRepairNeeded && (
								<>
									<TextAreaInput
										id="isRepairNeededReason"
										name="isRepairNeededReason"
										className="form-group input-single-line"
										label={localizationService.getLocalizedString('pumpOut.isRepairNeededReason')}
										isFullWidth
										value={formState.isRepairNeededReason ? formState.isRepairNeededReason : ''}
										isRequired={true}
										error={formState.isRepairNeededReasonError}
										onChange={changeFormState}
									/>
								</>
							)}

							<div className="mb-2">
								<label>
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.attachments')}
								</label>
								<DragAndDrop
									handleDrop={handleDrop}
									className="drag-submit"
									dragMessage={localizationService.getLocalizedString(
										'haulerPortal.submitCleaning.dragAndDrop'
									)}>
									<div className="file-input-group p-1">
										<label
											className="btn ai-secondary mb-1"
											htmlFor="btnFileImport"
											aria-describedby="file-input-help">
											{localizationService.getLocalizedString(
												'haulerPortal.submitCleaning.chooseFiles'
											)}
										</label>
										<input
											type="file"
											id="btnFileImport"
											className="file-input mr-4 "
											onChange={onFileChangeHandler}
											onClick={(e: any) => (e.target.value = null)}
											style={{ width: '0px' }}
											multiple
										/>
										{files.length ? (
											files.map((fileData: any, index: number) => {
												return (
													<span key={`file-${index}`}>
														<label className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
															<div
																className="d-inline-flex"
																title={
																	fileData && (fileData.name || fileData.fileName)
																}>
																<span id="file-badge-name">
																	{fileData && (fileData.name || fileData.fileName)}
																</span>
															</div>
															{removeFile(index)}
														</label>
													</span>
												);
											})
										) : (
											<label className="file-name ml-4 pl-4">
												{localizationService.getLocalizedString(
													'haulerPortal.submitCleaning.dragAndDrop'
												)}
											</label>
										)}
									</div>
								</DragAndDrop>
							</div>
							<TextAreaInput
								id="comments"
								name="comments"
								label={localizationService.getLocalizedString('pumpOut.comments')}
								value={formState.comments}
								onChange={changeFormState}
								isFullWidth={true}
								rows={3}
							/>
							{cleaningViolation()}
						</>
					)}
				</form>
			</>
		);
	};

	const getTitle = () => {
		if (props.title) {
			return props.title;
		}
		if (props.isSubmitCleaning) {
			return localizationService.getLocalizedString('pumpOut.submitCleaning');
		} else if (props.isEnterCleaning && props.cleaning && !props.cleaning.pumpOutEventId) {
			if (!props.facilityId) {
				return localizationService.getLocalizedString('pumpOut.addCleaning');
			}
			return localizationService.getLocalizedString('pumpOut.newCleaning');
		} else if (props.isScheduleCleaning) {
			return localizationService.getLocalizedString('pumpOut.scheduleCleaning');
		} else {
			return localizationService.getLocalizedString('pumpOut.editCleaning');
		}
	};

	const getDeleteMessage = (): string => {
		let violations: ApiTypes.CleaningEventViolation[] = props.cleaning!.violations || [];
		if (violations.length === 0) return localizationService.getLocalizedString('pumpOut.deleteModalMessage');
		if (violations.length === 1)
			return localizationService.getLocalizedString(
				'pumpOut.deleteModalMessageViolations',
				violations.length.toString(),
				''
			);
		return localizationService.getLocalizedString(
			'pumpOut.deleteModalMessageViolations',
			violations.length.toString(),
			's'
		);
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal === undefined ? true : props.showModal}
				title={getTitle()}
				save={saveCleaningEvent}
				cancel={cancelSave}
				footer={modalFooterDiv()}>
				{props.isScheduleCleaning ? scheduleCleaningFields() : detailedCleaningFields()}
			</PopoverModal>

			{showDeleteCleaningModal && (
				<DeleteModal
					key="confirmDeletePumpOutModal"
					title={localizationService.getLocalizedString('pumpOut.deleteModalTitle')}
					message={getDeleteMessage()}
					showModal={true}
					onCancelButtonClick={() => {
						setShowDeleteCleaningModal(false);
					}}
					onOkayButtonClick={deleteCleaningEvent}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
