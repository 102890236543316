import React from 'react';

interface Props {}

export const AlertWarningSvg: React.SFC<Props> = props => {
	return (
		<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<path
				d="M17.25,14.1,10.37,2.3a1.58,1.58,0,0,0-2.74,0L.75,14.1a1.59,1.59,0,0,0,1.37,2.39H15.88a1.59,1.59,0,0,0,1.37-2.39Zm-9-7.8H9.82v5.08H8.21Zm1.54,7.62a1.06,1.06,0,0,1-.75.31,1.08,1.08,0,0,1-.76-.31,1.09,1.09,0,0,1-.3-.75,1,1,0,0,1,.31-.75,1.06,1.06,0,0,1,1.5,0,1,1,0,0,1,.31.75A1.06,1.06,0,0,1,9.75,13.92Z"
				style={{ fill: '#ffcc02' }}></path>
			<path
				d="M9,2a1,1,0,0,0-.91.53L1.2,14.37a1.06,1.06,0,0,0,0,1.06,1,1,0,0,0,.92.53H15.88a1,1,0,0,0,.92-.53,1.06,1.06,0,0,0,0-1.06L9.91,2.57A1,1,0,0,0,9,2M9,1a2.09,2.09,0,0,1,1.82,1l6.89,11.81A2.11,2.11,0,0,1,15.88,17H2.12A2.11,2.11,0,0,1,.29,13.84L7.18,2A2.09,2.09,0,0,1,9,1Z"
				style={{ fill: '#fff' }}></path>
		</svg>
	);
};
