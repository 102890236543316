import React from 'react';
import _ from 'lodash';
import './authentication-component.scss';
import { alertService } from 'src/redux';
import { validationService, localizationService, urlService, ApiService } from 'src/services';
import { TextInput } from 'src/components/widgets';
import { Translate } from '../widgets/translate/translator';

export const ForgetPassword: React.FunctionComponent = () => {
	const [userEmail, setUserEmail] = React.useState<string>('');
	const [userEmailError, setUserEmailError] = React.useState<string>();

	React.useEffect(() => {
		document.body.className = 'signinBody';
	}, []);

	const handleUserNameChange = (event: any) => {
		setUserEmail(event.target.value);
	};

	const sendNewPassword = async (event: any) => {
		event.preventDefault();
		let isFromValid = true;

		if (_.isEmpty(userEmail)) {
			isFromValid = false;
			setUserEmailError(
				localizationService.getLocalizedString(
					'screen.validationMessage.fieldValueIsRequired',
					'authentication.email'
				)
			);
		} else if (!_.isEmpty(userEmail) && !validationService.isEmailFormatValid(userEmail)) {
			isFromValid = false;
			setUserEmailError(localizationService.getLocalizedString('screen.validationMessage.invalidEmailFormat'));
		} else {
			setUserEmailError('');
		}
		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return;
		}
		try {
			let url = urlService.getForgetPasswordApiUrl();
			let result = await new ApiService(true).httpPost(url, userEmail);
			alertService.addSuccess(result);
		} catch (error) {
			alertService.addError(error.message);
		}
	};

	return (
		<div className="signin">
			<h1>
				<Translate>{localizationService.getLocalizedString('authentication.forgetPasswordTitle')}</Translate>
			</h1>
			<form>
				<p>
					<Translate>
						{localizationService.getLocalizedString('authentication.forgetPasswordDescription')}
					</Translate>
				</p>

				<div className="form-group">
					<TextInput
						id="userEmail"
						type="email"
						name="userEmail"
						className="w-100"
						label={localizationService.getLocalizedString('authentication.email')}
						value={userEmail}
						onChange={handleUserNameChange}
						isRequired={true}
						error={userEmailError}
					/>
				</div>

				<button id="btnGetNewPassword" className="btn ai-action btn-signin" onClick={sendNewPassword}>
					<Translate>{localizationService.getLocalizedString('authentication.getNewPassword')}</Translate>
				</button>
			</form>
			<div className="signin-footer">
				<a id="backToSignInLink" href={urlService.getLoginUrl()}>
					<Translate>{localizationService.getLocalizedString('authentication.backToSignIn')}</Translate>
				</a>
			</div>
		</div>
	);
};
