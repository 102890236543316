import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterObjectName, FilterType, IppIndustrySample, LocalStorageName } from '@rcp/types';
import { History } from 'history';
import React, { FC } from 'react';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { Utils } from 'src/services/utils';
import { RootState } from 'src/redux';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, navigateTo, urlService } from 'src/services';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { ippSamplesReportedSlice } from './samples-reported-slice';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const SampleStatusName = 'Reported';
const IppIndustrySamplesReportedComponent: FC<Props<IppIndustrySample>> = props => {
	const sampleTypeToLink = (item: any) => {
		const dataItem = item.dataItem as IppIndustrySample;
		const url = urlService.getIppIndustryUrl(`samples/${item.dataItem.sampleId}/details`);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.ctsEventTypeName}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showTabList: false,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		showRefreshButton: true,
		prefix: 'ipp.samples.columns',
		storageName: LocalStorageName.IppIndustryReportedSamples,
		searchPlaceholder: '',
		showActions: false,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.samples.samplesReported'),
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		filterOnlyThisGrid: true,
		showAddModal: false,
		showAddButton: false,
		addButtonText: '',
		showScheduleInspectionModal: false,
		queryParameters: { SampleStatusName, sort: 'startDateTimeLocal desc' },
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppReportedSampleGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPREPORTEDSAMPLE,
				FilterDomainName.ippReportedSample,
				FilterObjectName.IppReportedSample,
				LocalStorageName.IppReportedSampleGridFilterUrl,
				{ IPPREPORTEDSAMPLE: true }
			);
		},
		allColumns: [
			new ColumnField('sampleId', FilterType.Integer),
			new ColumnField('name'),
			new ColumnField('monitoringPointName'),
			new ColumnField('ctsEventTypeName', FilterType.Text, sampleTypeToLink),
			new ColumnField('collectionMethodName'),
			new ColumnField('labSampleIdentifier'),
			new ColumnField('startDateTimeLocal', FilterType.Date),
			new ColumnField('endDateTimeLocal', FilterType.Date),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isAssociatedWithReportPackage', FilterType.Boolean),
			new ColumnField('isSampleExcludedFromReports', FilterType.Boolean)
		],

		defaultColumns: [
			'monitoringPointName',
			'ctsEventTypeName',
			'collectionMethodName',
			'startDateTimeLocal',
			'endDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName'
		]
	};
	const samplesReportedState = (state: RootState) => state.ippIndustrySamplesReported;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippSamplesReportedSlice}
				restState={samplesReportedState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppIndustrySamplesReportedComponent;
