import React from 'react';
import { FaPrint } from 'react-icons/fa';
import { Form } from 'reactstrap';
import { PageLoadingContainer } from '../layout/page-loading-container';
import { CloseSvg, DownloadSvg } from '../svg';
import './take-over.scss';

interface Props {
	title?: string;
	saveButtonText?: string;
	save?: (e?: any) => void;
	cancel: (e?: any) => void;
	footer?: JSX.Element;
	className?: string;
	isAboveAnotherModal?: boolean;
	buttonSize?: string;
	downloadImage?: () => void;
	isDownloadButton?: boolean;
	isPrintButton?: boolean;
	noForm?: boolean;
}

export const TakeOverModal: React.FC<Props> = props => {
	React.useEffect(() => {
		document.body.classList.add('modal-open');
		document.addEventListener('keydown', escFunction, false);
		return () => {
			!props.isAboveAnotherModal && document.body.classList.remove('modal-open');
			document.removeEventListener('keydown', escFunction, false);
		};
	}, []);

	const escFunction = React.useCallback(event => {
		if (event.key === 'Escape') {
			props.cancel();
			event.preventDefault();
			event.stopPropagation();
		}
	}, []);

	const _buttonSize = props.buttonSize ? props.buttonSize : '24';
	return (
		<>
			<div className={`takeover ${props.className ?? ''}`}>
				<PageLoadingContainer className="takeover-loader" />
				<div className="take-over-buttons d-print-none">
					{props.isDownloadButton && (
						<button
							className="download-takeover action-button"
							onClick={() => {
								props.downloadImage && props.downloadImage();
							}}
							aria-label="Close and cancel any changes">
							<DownloadSvg size={_buttonSize} color={'#3c3e69'} />
						</button>
					)}

					{props.isPrintButton && (
						<button
							className="print-takeover action-button"
							id="print-takeover"
							onClick={(e?: any) => {
								e.preventDefault();
								e.stopPropagation();
								window.print();
							}}
							aria-label="Print">
							<FaPrint className="ai-dark-purple" size={_buttonSize} />
						</button>
					)}

					<button
						className="close-takeover action-button"
						onClick={() => {
							props.cancel();
						}}
						aria-label="Close and cancel any changes">
						<CloseSvg size={_buttonSize} color={'#3c3e69'} />
					</button>
				</div>
				<div className={`takeover-content ${props.className ?? ''}`}>
					{props.title && <h1>{props.title}</h1>}
					{props.noForm ? (
						<>
							<div className="form-group">{props.children}</div>
							<div className="takeover-action flex-direction-sm">{props.footer}</div>
						</>
					) : (
						<Form>
							<div className="form-group">{props.children}</div>
							<div className="takeover-action flex-direction-sm">{props.footer}</div>
						</Form>
					)}
				</div>
			</div>
		</>
	);
};
