import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { localizationService } from 'src/services';
import { PopoverModal } from 'src/components/widgets';
import _ from 'lodash';

export interface InviteFacilityModalProps {
	showModal: boolean;
	onCancel: () => void;
	downloadLetter: () => void;
	markAsInvited: () => void;
	generateInviteCode: (setInviteCode: (response: any) => void) => void;
	title: string;
	invitationDesc: string;
	saveButtonText?: string;
}

interface Invite {
	invitationUrl?: string;
	inviteCode?: string;
}

export const InviteFacilityModal: FC<InviteFacilityModalProps> = props => {
	const [formState, setFormState] = useState<Invite>({
		invitationUrl: '',
		inviteCode: ''
	});
	const { showModal, onCancel } = props;

	const setInviteCode = (response: any) => {
		response.invitationUrl && response.inviteCode && setFormState({ ...response });
	};

	useEffect(() => {
		props.generateInviteCode(setInviteCode);
	}, []);

	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={showModal}
					title={props.title}
					saveButtonText={props.saveButtonText}
					cancel={onCancel}
					saveButtonClassName="ml-auto btn ai-action"
					save={props.markAsInvited}>
					<p>{props.invitationDesc}</p>
					<div className="form-group">
						<label htmlFor="web-address">
							{localizationService.getLocalizedString('haulerPortal.inviteHauler.webAddress')}
						</label>
						<input
							id="web-address"
							type="text"
							name="InvitationUrl"
							value={formState.invitationUrl}
							className="form-control input-single-line"
							disabled
						/>
					</div>
					<div className="form-group">
						<label htmlFor={formState.inviteCode}>
							{localizationService.getLocalizedString('haulerPortal.inviteHauler.inviteCode')}
						</label>
						<input
							type="text"
							id={formState.inviteCode}
							name="inviteCode"
							value={formState.inviteCode}
							className="form-control input-single-line"
							disabled
						/>
					</div>
					<h3 className="preview-header">
						{localizationService.getLocalizedString('facilityPortal.shareInvite')}
					</h3>
					<div className="share-invite-border share-invite" onClick={props.downloadLetter}>
						<FontAwesomeIcon className="font-awesome-icon m-3 mail-icon" size="lg" icon={faMailBulk} />
						<span className="font-size-16px-medium">
							{localizationService.getLocalizedString('facilityPortal.downloadLetter')}
						</span>
					</div>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
};
