import React, { useState } from 'react';
import _ from 'lodash';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from '../../../../services';
import { useHistory, useParams } from 'react-router';
import { ColumnField, Dictionary, Hauler, HaulerPortalFacilities, LocalStorageName, RouteProps } from '@rcp/types';
import './hauler-facilities.scss';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState, useRootStateSelector } from '../../../../redux';
import { SubmitCleaningActionLink } from './submit-cleaning-cell';
import { haulerPortalSelectedFacilitiesSlice } from './hauler-selected-facility-slice';
import { Button } from 'reactstrap';
import { BackArrow } from '../../../widgets/back-arrow/back-arrow';
import { haulersSlice } from '../../..';
import { useDispatch } from 'react-redux';

const HaulerFacilities = (props: RouteProps) => {
	const [showNotification, setShowNotification] = useState(true);
	const [hauler, setHauler] = useState({} as Hauler);
	const [showFallBack, setShowFallback] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const params: any = useParams();

	const selectedFacilitiesDetail = useRootStateSelector(state => state.haulerSelectedFacilities);

	React.useEffect(() => {
		document.body.className = showFallBack ? 'mobile-view' : '';
		return () => {
			document.body.className = '';
		};
	}, [showFallBack]);

	React.useEffect(() => {
		if (urlService.isHaulerPortal()) {
			haulerPortalSelectedFacilitiesSlice.setApiUrlPath(Resource.HaulerFacilities);
		}
		if (params.haulerId) {
			haulerPortalSelectedFacilitiesSlice.setApiUrlPath(
				`${Resource.Haulers}/${props.match.params.haulerId}/${Resource.HaulerFacilities}`
			);
			dispatch(
				haulersSlice.fetchOne(props.match.params.haulerId, (data: Hauler) => {
					setHauler(data);
				})
			);
		}
		return () => {
			dispatch(haulerPortalSelectedFacilitiesSlice.resetAll());
			haulerPortalSelectedFacilitiesSlice.setApiUrlPath(Resource.HaulerFacilities);
		};
	}, []);

	React.useEffect(() => {
		if (selectedFacilitiesDetail.result.length) {
			setShowFallback(!selectedFacilitiesDetail.result.length);
		}
	}, [selectedFacilitiesDetail.result]);

	const facilityNameToLink = (props: any) => {
		const facility = props.dataItem as HaulerPortalFacilities;
		return (
			<td>
				<a
					href={urlService.getHaulerFacilityDetailPage(facility.facilityId as number, params.haulerId)}
					className="ai-link"
					onClick={(e: any) => {
						navigateTo(
							history,
							urlService.getHaulerFacilityDetailPage(facility.facilityId as number, params.haulerId),
							e
						);
					}}>
					{facility.facilityName}
				</a>
			</td>
		);
	};

	const submitCleaning = (props: any) => {
		const facility = props.dataItem as HaulerPortalFacilities;
		return (
			<td className={props.className}>
				{!!facility.activeDeviceCount && (
					<SubmitCleaningActionLink organizationId={facility.facilityId} facility={facility} />
				)}
			</td>
		);
	};

	const getLastCleaningDate = (props: any) => {
		let facility: HaulerPortalFacilities = props.dataItem;
		let date = DateUtilService.toDisplayDate(facility.cleaningDateTimeUtc);
		let difference = DateUtilService.getDifferenceFromCurrentDateInDays(facility.cleaningDateTimeUtc + '');
		if (difference === 0) {
			date = localizationService.getLocalizedString('haulerPortal.submitCleaning.today');
		} else if (difference === 1) {
			date = localizationService.getLocalizedString('haulerPortal.submitCleaning.yesterday');
		} else if (difference > 1 && difference <= 7) {
			date = localizationService.getLocalizedString('haulerPortal.submitCleaning.daysAgo', difference);
		}

		return <td>{date}</td>;
	};

	const navigateToSelectFacility = () => {
		const selectFacilities = urlService.getHaulerSelectFacilitiesPage(params.haulerId);
		navigateTo(history, selectFacilities);
	};

	const EmptySelectedFacilities = (
		<>
			<div className="p-4">
				<div className="d-flex justify-content-between align-items-center">
					<div className="d-flex">
						{!urlService.isServicePortal() && <BackArrow className={'mt-1'} history={history} />}
						<h1>{localizationService.getLocalizedString('haulerPortal.facilities.facilities')}</h1>
					</div>
					<Button
						color="btn ai-new"
						onClick={() => {
							navigateToSelectFacility();
						}}>
						{localizationService.getLocalizedString('haulerPortal.facilities.selectFacilities')}
					</Button>
				</div>
				<div className="d-flex justify-content-center align-items-center text-center mt-4 no-facilities">
					<div>
						{localizationService.getLocalizedString('haulerPortal.facilities.facilitiesDescription1')}
						&nbsp;
						<a
							className="ai-link cursor-pointer"
							onClick={e => {
								e.preventDefault();
								navigateToSelectFacility();
							}}>
							{localizationService.getLocalizedString('haulerPortal.facilities.selectFacility')}
						</a>
						&nbsp;
						{localizationService.getLocalizedString('haulerPortal.facilities.facilitiesDescription2')}
					</div>
				</div>
			</div>
		</>
	);

	const updateNotification = () => {
		setShowNotification(false);
	};

	const isHaulerPortal = urlService.isHaulerPortal();

	let columns = [
		ColumnField.newText('addressLine1'),
		ColumnField.newText('addressLine2'),
		ColumnField.newText('cityName'),
		ColumnField.newText('zipCode'),
		ColumnField.newText('referenceNumber'),
		ColumnField.newText('facilityName', true, facilityNameToLink),
		ColumnField.newBoolean('isPermitted'),
		ColumnField.newText('submitCleaning', true, submitCleaning, undefined, true),
		ColumnField.newText('activeDeviceCount', true),
		ColumnField.newText('facilityAddress', true),
		ColumnField.newDate('cleaningDateTimeUtc', isHaulerPortal, getLastCleaningDate),
		ColumnField.newDate('dueDate', true)
	];

	columns = columns.filter(column => {
		if (!urlService.isHaulerPortal() && String.equalCaseInsensitive(column.name, 'submitCleaning')) {
			return false;
		} else {
			return true;
		}
	});

	let defaultColumns = isHaulerPortal
		? [
				'facilityName',
				'addressLine1',
				'cityName',
				'authorityName',
				'activeDeviceCount',
				'dueDate',
				'cleaningDateTimeUtc',
				'submitCleaning'
		  ]
		: [
				'referenceNumber',
				'facilityName',
				'addressLine1',
				'cityName',
				'facilityPhoneNumber',
				'activeDeviceCount',
				'isEnabled'
		  ];

	defaultColumns = defaultColumns.filter(column => {
		if (!urlService.isHaulerPortal() && String.equalCaseInsensitive(column, 'submitCleaning')) {
			return false;
		} else {
			return true;
		}
	});

	const initialGridOptions: GridOption = {
		pageTitle: localizationService.getLocalizedString('facility.facilities'),
		prefix: 'facility',
		storageName: LocalStorageName.HaulerPortalFacilities,
		searchHint: urlService.isHaulerPortal() ? localizationService.getLocalizedString('haulerPortal.facilities.searchHint') :
			localizationService.getLocalizedString('facility.haulerSelectFacilities.searchHint'),
		gridUrlLocalStorageName: LocalStorageName.HaulerPortalFacilitiesFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.HaulerPortalFacilitiesSortOrder,
		showSearch: true,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('haulerPortal.facilities.selectFacilities'),
		toggleAddModal: () => {
			navigateToSelectFacility();
		},
		queryParameters: { sort: 'facilityName asc' } as Dictionary<string>,
		defaultSort: [{ field: 'facilityName', dir: 'asc' }],
		allColumns: columns,
		defaultColumns: defaultColumns,
		notification: {
			show: showNotification,
			customUI: () => (
				<>
					{!urlService.isHaulerPortal() && (
						<span className="ml-2">
							{localizationService.getLocalizedString(
								'haulerPortal.facilities.facilityCleaningMessage',
								hauler.name as string
							)}
						</span>
					)}
				</>
			),
			onClose: () => updateNotification()
		},
		showBackButton: !urlService.isHaulerPortal()
	};

	return (
		<>
			<DataGrid
				gridOption={initialGridOptions}
				restSlice={haulerPortalSelectedFacilitiesSlice}
				restState={(state: RootState) => state.haulerSelectedFacilities}
				history={history}
				match={props.match}
				fallBackUI={showFallBack ? EmptySelectedFacilities : undefined}
			/>
		</>
	);
};
export default HaulerFacilities;
