import {
	CleaningEvent,
	DashboardWidgetFilter,
	DashboardWidgetFilterCategories,
	DropDownOption,
	FilterObjectName,
	FogFacility,
	GridTypes,
	ImportField,
	InspectionEvent,
	LocalStorageName,
	NullValues
} from '@rcp/types';
import _ from 'lodash';
import { Dictionary } from 'lodash';
import { FilterDomainName } from 'src/features/filter/filter-service';
import {
	localStorageService,
	DateUtilService,
	localizationService,
	Logger,
	urlService,
	UtilService,
	apiService,
	QueryParameters,
	dashboardService
} from 'src/services';
import { nameof } from 'ts-simple-nameof';
import { ChartSetting } from './donut-chart-widget';

enum ImportFieldType {
	String = 'String'
}

const NullValue = '[NULL]';

export enum Colors {
	aqua = '#00ffff',
	azure = '#f0ffff',
	beige = '#f5f5dc',
	black = '#000000',
	blue = '#0000ff',
	brown = '#a52a2a',
	cyan = '#00ffff',
	darkblue = '#23409A',
	darkcyan = '#008b8b',
	darkgrey = '#a9a9a9',
	darkgreen = '#205E3B',
	darkkhaki = '#bdb76b',
	darkmagenta = '#8b008b',
	darkolivegreen = '#556b2f',
	darkorange = '#ff8c00',
	darkorchid = '#9932cc',
	darkred = '#990600',
	darksalmon = '#e9967a',
	darkviolet = '#9400d3',
	fuchsia = '#ff00ff',
	gold = '#ffd700',
	green = '#008000',
	indigo = '#4b0082',
	khaki = '#f0e68c',
	lightblue = '#9AC8FA',
	lightcyan = '#e0ffff',
	lightgreen = '#81FFBF',
	lightgrey = '#d3d3d3',
	lightpink = '#ffb6c1',
	lightyellow = '#ffffe0',
	lime = '#00ff00',
	magenta = '#ff00ff',
	maroon = '#800000',
	navy = '#000080',
	olive = '#808000',
	orange = '#ffa500',
	pink = '#ffc0cb',
	purple = '#800080',
	violet = '#800080',
	red = '#ff0000',
	silver = '#c0c0c0',
	white = '#ffffff',
	yellow = '#ffff00'
}

export enum ColorScheme {
	Blue = 'Blue',
	Red = 'Red',
	Green = 'Green',
	Purple = 'Purple',
	Mixed = 'Mixed'
}

export enum DateFilterValues {
	AllTime = 'AllTime',
	Last7Days = 'Last7Days',
	Last30Days = 'Last30Days',
	LastMonth = 'LastMonth',
	ThisMonth = 'ThisMonth',
	LastYear = 'LastYear',
	ThisYear = 'ThisYear'
}

export enum DateFilterKind {
	Default = 'Default',
	Year = 'Year'
}

const FacilityGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('facility.tagClassification1'),
		value: 'TagClassification1',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.tagClassification2'),
		value: 'TagClassification2',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.inspectionFrequencyCode'),
		value: 'InspectionFrequencyCode',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.isEnabled'),
		value: 'isEnabled',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.assignedTo'),
		value: 'assignedTo',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.mapCategory'),
		value: 'mapCategory',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.receivingPlant'),
		value: 'receivingPlant',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('screen.labels.complianceStatus'),
		value: 'complianceStatus',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.cityName'),
		value: 'cityName',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.state'),
		value: 'JurisdictionCode',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.zipCode'),
		value: 'ZipCode',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.tags'),
		value: 'tags',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.lastHaulerName'),
		value: 'lastHaulerName',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.invitationDateTimeUtc'),
		value: 'invitationDateTimeUtc',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.facilityPortalStatusName'),
		value: 'facilityPortalStatusName',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.hasPrimaryContact'),
		value: 'hasPrimaryContact',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.activeDeviceCount'),
		value: 'activeDeviceCount',
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.contractedHaulerName'),
		value: nameof<FogFacility>(f => f.contractedHaulerName),
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.isPreferredContractedHauler'),
		value: nameof<FogFacility>(f => f.isPreferredContractedHauler),
		prefix: FilterObjectName.Facility
	},
	{
		label: localizationService.getLocalizedString('facility.riskScore'),
		value: nameof<FogFacility>(f => f.riskScore),
		prefix: FilterObjectName.Facility
	}
];

const InspectionGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('inspection.inspectionType'),
		value: 'inspectionType',
		prefix: FilterObjectName.Inspection
	},
	{
		label: localizationService.getLocalizedString('inspection.inspectionResult'),
		value: 'inspectionResult',
		prefix: FilterObjectName.Inspection
	},
	{
		label: localizationService.getLocalizedString('inspection.inspectorName'),
		value: 'inspectorName',
		prefix: FilterObjectName.Inspection
	}
];

const ViolationGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('violation.violationTypeTypeName'),
		value: 'ViolationTypeTypeName',
		prefix: FilterObjectName.Violation
	},
	{
		label: localizationService.getLocalizedString('violation.facilityName'),
		value: 'facilityName',
		prefix: FilterObjectName.Violation
	},
	{
		label: localizationService.getLocalizedString('violation.lastEnforcementType'),
		value: 'lastEnforcementType',
		prefix: FilterObjectName.Violation
	},
	{
		label: localizationService.getLocalizedString('violation.assignedToUserName'),
		value: 'assignedToUserName',
		prefix: FilterObjectName.Violation
	}
];

const DeviceGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('extractor.deviceType'),
		value: 'extractorType',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.location'),
		value: 'location',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.trapCapacity'),
		value: 'trapCapacity',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.trapDepth'),
		value: 'trapDepth',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.numberOfCompartments'),
		value: 'numberOfCompartments',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.manufacturer'),
		value: 'manufacturer',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.model'),
		value: 'model',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.extractorDescription'),
		value: 'extractorDescription',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.installDate'),
		value: 'installDate',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.latitude'),
		value: 'latitude',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.longitude'),
		value: 'longitude',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.mandatoryInstall'),
		value: 'mandatoryInstall',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.lastCleaningDate'),
		value: 'lastCleaningDate',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.daysLateLastCleaning'),
		value: 'daysLateLastCleaning',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.nextCleaningDate'),
		value: 'nextCleaningDate',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.daysLateNextCleaning'),
		value: 'daysLateNextCleaning',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.isAdditivesUsed'),
		value: 'isAdditivesUsed',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.trapCapacityUnitCode'),
		value: 'trapCapacityUnitCode',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.trapDepthUnitCode'),
		value: 'trapDepthUnitCode',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.isInactive'),
		value: 'isInactive',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.cleanFrequencyCode'),
		value: 'cleanFrequencyCode',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.lastHaulerName'),
		value: 'lastHaulerName',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.lastNoticeTemplateName'),
		value: 'lastNoticeTemplateName',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.nextNoticeTemplateName'),
		value: 'nextNoticeTemplateName',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.addedBy'),
		value: 'addedBy',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.verified'),
		value: 'verified',
		prefix: FilterObjectName.Device
	},
	{
		label: localizationService.getLocalizedString('extractor.sendNoticeContactsCount'),
		value: 'sendNoticeContactsCount',
		prefix: FilterObjectName.Device
	}
];

const CleaningGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('pumpOut.deviceNumber'),
		value: 'deviceNumber',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.dueDate'),
		value: 'dueDate',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.completeDate'),
		value: 'completeDate',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.amountPumped'),
		value: 'amountPumped',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.wasteType'),
		value: 'wasteTypeCode',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.haulerName'),
		value: 'haulerName',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.manifestNumber'),
		value: 'manifestNumber',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.comments'),
		value: 'comments',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.disposalLocation'),
		value: 'disposalLocation',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.amountPumpedUnit'),
		value: 'amountPumpedUnit',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.daysLate'),
		value: 'daysLate',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.nextNoticeDate'),
		value: 'nextNoticeDate',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.nextNoticeTemplateName'),
		value: 'nextNoticeTemplateName',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.lastNoticeDate'),
		value: 'lastNoticeDate',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.lastNoticeTemplateName'),
		value: 'lastNoticeTemplateName',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.fog'),
		value: 'fog',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.fogUnit'),
		value: 'fogUnit',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.solid'),
		value: 'solid',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.solidUnit'),
		value: 'solidUnit',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.trapDepth'),
		value: 'trapDepth',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.trapDepthUnit'),
		value: 'trapDepthUnit',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.percentGrease'),
		value: 'percentGrease',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.leavingOutlet'),
		value: 'leavingOutlet',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.isRepairNeeded'),
		value: 'isRepairNeeded',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.isRepairNeededReason'),
		value: 'isRepairNeededReason',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.driverName'),
		value: 'driverName',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.vehicleNumber'),
		value: 'vehicleNumber',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.deviceType'),
		value: 'extractorType',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.cleanedBy'),
		value: 'cleanedBy',
		prefix: FilterObjectName.Cleaning
	},
	{
		label: localizationService.getLocalizedString('pumpOut.certificationAccepted'),
		value: 'certificationAccepted',
		prefix: FilterObjectName.Cleaning
	}
];

const IncidentsGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('incidents.incidentNumber'),
		value: 'incidentNumber',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.address'),
		value: 'address',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.latitude'),
		value: 'latitude',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.longitude'),
		value: 'longitude',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.reportedDate'),
		value: 'reportedDate',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.incidentTypeCode'),
		value: 'incidentTypeCode',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.incidentStatusCode'),
		value: 'incidentStatusCode',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.incidentSourceCode'),
		value: 'incidentSourceCode',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.assetId'),
		value: 'assetId',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.investigatorFullName'),
		value: 'investigatorFullName',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.incidentDescription'),
		value: 'incidentDescription',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.resolvedDate'),
		value: 'resolvedDate',
		prefix: FilterObjectName.Incident
	},
	{
		label: localizationService.getLocalizedString('incidents.resolutionDescription'),
		value: 'resolutionDescription',
		prefix: FilterObjectName.Incident
	}
];

const EventsGroupBys: DropDownOption[] = [
	{
		label: localizationService.getLocalizedString('events.eventType'),
		value: 'eventTypeName',
		prefix: FilterObjectName.Event
	},
	{
		label: localizationService.getLocalizedString('events.dueDate'),
		value: 'dueDate',
		prefix: FilterObjectName.Event
	},
	{
		label: localizationService.getLocalizedString('events.daysLate'),
		value: 'daysLate',
		prefix: FilterObjectName.Event
	},
	{
		label: localizationService.getLocalizedString('events.assignedToUserName'),
		value: 'assignedToUserName',
		prefix: FilterObjectName.Event
	},
	{
		label: localizationService.getLocalizedString('events.completeDate'),
		value: 'completeDate',
		prefix: FilterObjectName.Event
	}
];
interface IDateFilter {
	getDateFilterOptions(): DropDownOption[];
	getDateFilterQueryParameterValue(filter?: string): string;
	getDateFilterDescription(filter?: string): string;
}

class DefaultDateFilter implements IDateFilter {
	getDateFilterOptions(): DropDownOption[] {
		return [
			{
				label: localizationService.getLocalizedString('screen.labels.allTime'),
				value: DateFilterValues.AllTime
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.last7Days'),
				value: DateFilterValues.Last7Days
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.last30Days'),
				value: DateFilterValues.Last30Days
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.lastMonth'),
				value: DateFilterValues.LastMonth
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.thisMonth'),
				value: DateFilterValues.ThisMonth
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.lastYear'),
				value: DateFilterValues.LastYear
			},
			{
				label: localizationService.getLocalizedString('screen.filterModal.dateQuickValue.thisYear'),
				value: DateFilterValues.ThisYear
			}
		];
	}
	getDateFilterQueryParameterValue(filter?: string): string {
		switch (filter as DateFilterValues) {
			case DateFilterValues.AllTime:
				return '';
			case DateFilterValues.Last7Days:
				return `in:Last7Days,null`;
			case DateFilterValues.Last30Days:
				return `in:Last30Days`;
			case DateFilterValues.LastMonth:
				return `in:LastMonth`;
			case DateFilterValues.ThisMonth:
				return `in:ThisMonth`;
			case DateFilterValues.LastYear:
				return `in:LastYear`;
			case DateFilterValues.ThisYear:
				return `in:ThisYear`;
			default:
				Logger.warn(`No QueryParameterValue is provided for filter ${filter}.`);
				return '';
		}
	}

	getDateFilterDescription(filter?: string): string {
		switch (filter as DateFilterValues) {
			case DateFilterValues.AllTime:
				return localizationService.getLocalizedString('screen.labels.allTime');
			case DateFilterValues.Last7Days:
				return DateUtilService.getDateRangeByDays(-7, 0);
			case DateFilterValues.Last30Days:
				return DateUtilService.getDateRangeByDays(-30, 0);
			case DateFilterValues.LastMonth:
				return DateUtilService.getDateRangeByMonth(-1);
			case DateFilterValues.ThisMonth:
				return DateUtilService.getDateRangeByMonth(0);
			case DateFilterValues.LastYear:
				return DateUtilService.getDateRangeByYear(-1);
			case DateFilterValues.ThisYear:
				return DateUtilService.getDateRangeByYear(0);
			default:
				Logger.warn(`No description is provided for DateFilterValues ${filter}.`);
				return '';
		}
	}
}

class YearFilter implements IDateFilter {
	getDateFilterOptions(): DropDownOption[] {
		let options: DropDownOption[] = [];
		let currentYear = DateUtilService.getAuthorityTimezoneNowAsMoment().year();
		for (let i = 1; i > -4; i--) {
			let year = currentYear + i;
			options.push({
				label: `${year}`,
				value: `${year}`
			});
		}
		return options;
	}
	getDateFilterQueryParameterValue(filter?: string): string {
		if (String.equalCaseInsensitive(DateFilterValues.AllTime, filter)) {
			return '';
		}
		let year = _.toNumber(filter) || DateUtilService.getAuthorityTimezoneNowAsMoment().year();
		return `betw:${year}-01-01,${year}-12-31`;
	}
	getDateFilterDescription(filter?: string): string {
		if (String.equalCaseInsensitive(DateFilterValues.AllTime, filter)) {
			return localizationService.getLocalizedString('screen.labels.allTime');
		}
		let year = _.toNumber(filter) || DateUtilService.getAuthorityTimezoneNowAsMoment().year();
		return `${year}`;
	}
}

class ChartService {
	tooltipZIndex = 1000;
	private colorSchema: Dictionary<string[]> = {};
	private dateFilters: Dictionary<IDateFilter> = {};

	constructor() {
		this.colorSchema[ColorScheme.Blue] = ['#23409A', '#305ADB', '#3881D1', '#5EA4F0', '#9AC8FA'];
		this.colorSchema[ColorScheme.Red] = ['#990600', '#D6312A', '#E85F5F', '#F56464', '#FF6868'];
		this.colorSchema[ColorScheme.Green] = ['#205E3B', '#1D814C', '#5CB688', '#67CC98', '#6FDBA4', '#81FFBF'];
		this.colorSchema[ColorScheme.Purple] = ['#3C3E69', '#5A4586', '#8859B6', '#9867CC', '#BF9AE3'];
		this.dateFilters[DateFilterKind.Default] = new DefaultDateFilter();
		this.dateFilters[DateFilterKind.Year] = new YearFilter();
	}

	toPercentage(value: number, total: number, decimalPlaces: number = 1): number {
		var power = Math.pow(10.0, decimalPlaces + 2);
		var divisor = Math.pow(10.0, decimalPlaces);
		return Math.round((power * value) / total + Number.EPSILON) / divisor;
	}

	getDateFilter(filterKind: DateFilterKind): IDateFilter {
		let dateFilter = this.dateFilters[filterKind];
		if (!dateFilter) {
			throw new Error(`DateFilterKind ${filterKind} is unsupported.`);
		}
		return dateFilter;
	}

	getYAxisLabel(lookIn?: string): string {
		if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Facilities)) {
			return localizationService.getLocalizedString('facility.numberOfFacilities');
		} else if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Inspections)) {
			return localizationService.getLocalizedString('inspection.numberOfInspections');
		}
		return '';
	}

	getGotoGridLabelAndLink(lookIn?: string): any {
		let label = '';
		let link = '#/';
		if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Facilities)) {
			label = localizationService.getLocalizedString('screen.labels.allFacilties');
			link = urlService.getReactResourceUrl(`${GridTypes.FogFacilities}`);
		} else if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Inspections)) {
			label = localizationService.getLocalizedString('screen.labels.allInspections');
			link = urlService.getReactResourceUrl(`${GridTypes.Inspections}`);
		} else if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Cleanings)) {
			label = localizationService.getLocalizedString('screen.labels.allCleanings');
			link = urlService.getReactResourceUrl(`${GridTypes.Cleanings}`);
		}
		return { label: label, link: link };
	}

	getColorScheme(colorScheme: ColorScheme): string[] {
		return this.colorSchema[colorScheme];
	}

	getFilters(dashboardWidgetFilters?: string): number[] {
		if (dashboardWidgetFilters) {
			let widgetFilters: DashboardWidgetFilter[] = UtilService.fromJson(dashboardWidgetFilters);
			if (widgetFilters) {
				let filterIds = widgetFilters.map(x => x.filterId) as number[];
				if (!_.isEmpty(filterIds)) {
					return filterIds;
				}
			}
		}
		return [];
	}

	getFirstFilter(dashboardWidgetFilters?: string): any {
		if (dashboardWidgetFilters) {
			let widgetFilters: DashboardWidgetFilter[] = UtilService.fromJson(dashboardWidgetFilters);
			if (!_.isEmpty(widgetFilters)) {
				let firstFilter = widgetFilters[0];
				return { filterId: firstFilter.filterId as number, filterName: firstFilter.filterName };
			}
		}
		return { filterId: 0, filterName: '' };
	}

	getDateFilterField(lookIn?: string): string {
		if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Facilities)) {
			return nameof<FogFacility>(f => f.inBusinessSince);
		} else if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Inspections)) {
			return nameof<InspectionEvent>(f => f.dueDate);
		} else if (String.equalCaseInsensitive(lookIn, DashboardWidgetFilterCategories.Cleanings)) {
			return nameof<CleaningEvent>(f => f.dueDate);
		} else {
			throw new Error(``);
		}
	}

	getDateFilterQueryParameterValue(filter?: string): string {
		if (filter) {
			if (/^\d{4}$/.test(filter)) {
				return this.dateFilters[DateFilterKind.Year].getDateFilterQueryParameterValue(filter);
			}
			return this.dateFilters[DateFilterKind.Default].getDateFilterQueryParameterValue(filter);
		}
		return '';
	}

	getDateFilterDescription(filter?: string): string | undefined {
		if (filter) {
			if (/^\d{4}$/.test(filter)) {
				return this.dateFilters[DateFilterKind.Year].getDateFilterDescription(filter);
			}
			return this.dateFilters[DateFilterKind.Default].getDateFilterDescription(filter);
		}
	}

	getAvailableGroupBys = async (filterCategory?: DashboardWidgetFilterCategories): Promise<DropDownOption[]> => {
		if (!filterCategory) {
			return [];
		}

		let resource = 'Charts/AvailableGroupBys/';
		switch (filterCategory) {
			case DashboardWidgetFilterCategories.Facilities:
			case DashboardWidgetFilterCategories.Inspections:
			case DashboardWidgetFilterCategories.Violations:
			case DashboardWidgetFilterCategories.Devices:
			case DashboardWidgetFilterCategories.Cleanings:
			case DashboardWidgetFilterCategories.Events:
			case DashboardWidgetFilterCategories.Incidents:
				resource += filterCategory;
				break;
			default:
				return [];
		}

		let url = urlService.getAuthorityResourcesApiUrl(resource);
		const apiResult = await apiService.getResource<ImportField[]>(url);

		let customGroupByFields: DropDownOption[] = apiResult
			.filter(a => a.isCustomField && String.equalCaseInsensitive(a.fieldType, ImportFieldType.String))
			.map(field => {
				let option: DropDownOption = {
					label: field.fieldLabel,
					value: field.isCustomField === true ? `CustomFields.${field.fieldName}` : field.fieldName
				};
				if (String.equalCaseInsensitive(field.importEntity, 'FogFacility')) {
					option.prefix = FilterObjectName.Facility;
				}
				return option;
			});

		let groupByFields: DropDownOption[] = [];
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Facilities)) {
			groupByFields = [...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Inspections)) {
			groupByFields = [...InspectionGroupBys, ...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Violations)) {
			groupByFields = [...ViolationGroupBys, ...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Devices)) {
			groupByFields = [...DeviceGroupBys, ...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Cleanings)) {
			groupByFields = [...CleaningGroupBys, ...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Events)) {
			groupByFields = [...EventsGroupBys, ...FacilityGroupBys, ...customGroupByFields];
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Incidents)) {
			groupByFields = [...IncidentsGroupBys];
		}

		groupByFields = _.cloneDeep(groupByFields);
		if (filterCategory !== DashboardWidgetFilterCategories.Facilities) {
			groupByFields.forEach(f => {
				if (String.equalCaseInsensitive(f.prefix, FilterObjectName.Facility)) {
					f.value = `${FilterObjectName.Facility}.${f.value}`;
				}
			});
		}
		groupByFields = _.sortBy(groupByFields, f => {
			return `${f.prefix} - ${f.label}`;
		});

		return groupByFields;
	};

	getAvailableGroupBysForFiterQuickViewer = async (
		filterCategory: DashboardWidgetFilterCategories
	): Promise<DropDownOption[]> => {
		let resource = 'Charts/AvailableGroupBys/';
		switch (filterCategory) {
			case DashboardWidgetFilterCategories.Facilities:
			case DashboardWidgetFilterCategories.Inspections:
			case DashboardWidgetFilterCategories.Violations:
			case DashboardWidgetFilterCategories.Devices:
			case DashboardWidgetFilterCategories.Cleanings:
			case DashboardWidgetFilterCategories.Events:
			case DashboardWidgetFilterCategories.Incidents:
				resource += filterCategory;
				break;
			default:
				return [];
		}

		let customGroupByFields: DropDownOption[] = await this.getCustomGroupByFields(resource);

		let groupByFields = String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Incidents)
			? chartService.getIncidentGroupBys()
			: chartService.getGlobalGroupBys(customGroupByFields);

		groupByFields = _.sortBy(groupByFields, f => {
			return `${f.prefix} - ${f.label}`;
		});

		return groupByFields;
	};

	private async getCustomGroupByFields(resource: string) {
		let url = urlService.getAuthorityResourcesApiUrl(resource);
		const apiResult = await apiService.getResource<ImportField[]>(url);

		let customGroupByFields: DropDownOption[] = apiResult
			.filter(a => a.isCustomField && String.equalCaseInsensitive(a.fieldType, ImportFieldType.String))
			.map(field => {
				let option: DropDownOption = {
					label: field.fieldLabel,
					value: field.isCustomField === true ? `CustomFields.${field.fieldName}` : field.fieldName
				};
				if (String.equalCaseInsensitive(field.importEntity, 'FogFacility')) {
					option.prefix = FilterObjectName.Facility;
				}
				return option;
			});
		return customGroupByFields;
	}

	getDrillDownUrl(
		filterCategory: DashboardWidgetFilterCategories,
		filterId: number,
		groupByParameter: string,
		groupByValue: string,
		fieldType?: string,
		isMapUrl?: boolean
	) {
		let parameters =
			String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Incidents) && isMapUrl
				? new QueryParameters().add(
						nameof<ChartSetting>(s => s.incidentFilterId),
						_.toString(filterId) as string
				  )
				: new QueryParameters().add(
						nameof<ChartSetting>(s => s.filterId),
						_.toString(filterId) as string
				  );
		let filterObject = this.getFilterObjectNameFromDashboardWidgetFilterCategory(filterCategory);
		let groupByField =
			!String.isUndefinedOrEmpty(filterObject) &&
			!_.toLower(groupByParameter).includes(`${FilterDomainName.facility}.`)
				? `${filterObject}.${groupByParameter}`
				: groupByParameter;
		if (groupByValue == NullValue || groupByValue == NullValues.Empty) {
			parameters.add(groupByField, NullValues.Null);
		} else if (fieldType && fieldType.toUpperCase() === 'DATETIMEOFFSET') {
			let { startOfDay, endOfTheDay } = DateUtilService.getDatePeriod(groupByValue);
			parameters.add(groupByField, `betw:${startOfDay},${endOfTheDay}`);
		} else {
			parameters.add(groupByField, groupByValue);
		}
		if (isMapUrl) {
			dashboardService.updateMapUrlWithMissingLocalStorageQueryParameters(parameters, filterCategory);
		}

		let queryString = parameters.toQueryString();
		let url =
			'/' +
			urlService.getCurrentProgramOrDefault() +
			'/' +
			`${isMapUrl ? 'map' : filterCategory}` +
			'?' +
			queryString;
		return url;
	}

	getGroupByFieldLabel = (groupByFields: DropDownOption[], groupByField: string) => {
		let fields = groupByFields.filter(x => x.value && String.equalCaseInsensitive(x.value, groupByField));

		if (fields.length > 0) {
			return fields[0].label;
		}
		return '';
	};

	getGlobalGroupBys = (customGroupByFields: DropDownOption[]) => {
		return [
			...FacilityGroupBys,
			...InspectionGroupBys,
			...ViolationGroupBys,
			...EventsGroupBys,
			...CleaningGroupBys,
			...DeviceGroupBys,
			...customGroupByFields
		];
	};

	getIncidentGroupBys = () => {
		return [...IncidentsGroupBys];
	};

	getDisplayName = (label: string, fieldType?: string) => {
		if (label == NullValue) {
			return NullValues.Empty;
		}
		if (fieldType && fieldType.toUpperCase() === 'DATETIMEOFFSET') {
			return DateUtilService.toDisplayDate(label);
		}
		return label;
	};

	getFilterObjectNameFromDashboardWidgetFilterCategory = (
		filterCategory: DashboardWidgetFilterCategories,
		returnDeviceFilterObject?: boolean
	): string => {
		var queryParameter = '';
		switch (filterCategory) {
			case DashboardWidgetFilterCategories.Incidents:
				queryParameter = FilterObjectName.Incident;
				break;
			case DashboardWidgetFilterCategories.Facilities:
				queryParameter = FilterObjectName.Facility;
				break;
			case DashboardWidgetFilterCategories.Inspections:
				queryParameter = FilterObjectName.Inspection;
				break;
			case DashboardWidgetFilterCategories.Violations:
				queryParameter = FilterObjectName.Violation;
				break;
			case DashboardWidgetFilterCategories.Devices:
			case DashboardWidgetFilterCategories.Extractors:
				if (returnDeviceFilterObject) {
					queryParameter = FilterObjectName.Device;
				} else {
					queryParameter = FilterObjectName.Extractor;
				}
				break;
			case DashboardWidgetFilterCategories.Cleanings:
				queryParameter = FilterObjectName.Cleaning;
				break;
			case DashboardWidgetFilterCategories.Events:
				queryParameter = FilterObjectName.Event;
				break;
			default:
				break;
		}
		return queryParameter;
	};

	getDashboardWidgetFilterCategoryFromFilterObjectName = (filterCategory: FilterObjectName): string => {
		var queryParameter = '';
		switch (filterCategory) {
			case FilterObjectName.Incident:
				queryParameter = DashboardWidgetFilterCategories.Incidents;
				break;
			case FilterObjectName.Facility:
				queryParameter = DashboardWidgetFilterCategories.Facilities;
				break;
			case FilterObjectName.Inspection:
				queryParameter = DashboardWidgetFilterCategories.Inspections;
				break;
			case FilterObjectName.Violation:
				queryParameter = DashboardWidgetFilterCategories.Violations;
				break;
			case FilterObjectName.Device:
			case FilterObjectName.Extractor:
				queryParameter = DashboardWidgetFilterCategories.Devices;
				break;
			case FilterObjectName.Cleaning:
				queryParameter = DashboardWidgetFilterCategories.Cleanings;
				break;
			case FilterObjectName.Event:
				queryParameter = DashboardWidgetFilterCategories.Events;
				break;
			default:
				break;
		}
		return queryParameter;
	};
}

export const chartService = new ChartService();
