import React from 'react';
import { navigateTo } from 'src/services';
import { useHistory } from 'react-router-dom';

export interface InspectionQuickViewValue {
	value?: string;
	dataType: string;
	cssName: string;
	href: string;
	toggleModal: () => void;
}

export interface InspectionQuickViewRow {
	values: InspectionQuickViewValue[];
}

export interface InspectionQuickViewProps {
	viewType: string;
	headText: string;
	columnNames: string[];
	rows: InspectionQuickViewRow[];
}

type Props = InspectionQuickViewProps;

export const InspectionQuickView: React.SFC<Props> = props => {
	const history = useHistory();
	const renderRow = (row: InspectionQuickViewRow, rowIndex: number) => {
		return row.values.map((value: InspectionQuickViewValue, colIndex: number) => {
			let keyStr = `${props.viewType}-row-${rowIndex}-col-${colIndex}`;
			let valueStyle = ['mt-2', 'tableRow', 'w-100'];
			if (value.cssName) {
				valueStyle.push(value.cssName);
			}

			if (value.dataType === 'link') {
				return (
					<a
						key={keyStr}
						href={value.href}
						onClick={(e: any) => {
							navigateTo(history, value.href, e);
						}}
						className={valueStyle.join(' ')}>
						{value.value}
					</a>
				);
			}

			if (value.dataType === 'modalLink') {
				return (
					<a
						key={keyStr}
						href="#/"
						onClick={(e: any) => {
							e.preventDefault();
							value.toggleModal();
						}}
						className={valueStyle.join(' ')}>
						{value.value}
					</a>
				);
			}

			return (
				<div key={keyStr} className={valueStyle.join(' ')}>
					{value.value}
				</div>
			);
		});
	};

	const renderColumnNames = () => {
		return props.columnNames.map((name: string, index: number) => {
			let valueStyle = ['tableHead', 'w-100'];

			return (
				<div key={`${props.viewType}-col-${index}`} className={valueStyle.join(' ')}>
					{name}
				</div>
			);
		});
	};

	return (
		<section className="right-panel">
			<div id="inspectionQuickViewHead">
				<h3>{props.headText}</h3>
			</div>
			<div className="d-flex flex-row mt-2 tableHead">{renderColumnNames()}</div>
			{props.rows.map((row: InspectionQuickViewRow, rowIndex: number) => {
				let keyStr = `${props.viewType}-row-${rowIndex}`;
				return (
					<div className="d-flex flex-row tableRow" key={keyStr}>
						{renderRow(row, rowIndex)}
					</div>
				);
			})}
		</section>
	);
};
