import React, { FC } from 'react';
import { History } from 'history';
import { cccCompanySlice } from './companies.slice';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, LocalStorageName, CccCompany } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccCompanies: FC<Props<CccCompany>> = props => {
	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('cccCompany.companies'),
		prefix: 'cccCompany',
		storageName: LocalStorageName.CccCompanyGrid,
		gridUrlLocalStorageName: LocalStorageName.CccTechnicianCompaniesGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccTechnicianCompaniesSortOrder,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccCompany.searchHint'),

		allColumns: [
			new ColumnField('companyId', FilterType.Integer),
			new ColumnField('name'),
			new ColumnField('addressLine1'),
			new ColumnField('addressLine2'),
			new ColumnField('addressCity'),
			new ColumnField('addressZipCode'),
			new ColumnField('addressJurisdictionCode'),
			new ColumnField('addressEmail'),
			new ColumnField('addressPhone'),
			new ColumnField('addressFax'),
			new ColumnField('addressCell'),
			new ColumnField('insuranceAgency'),
			new ColumnField('insuranceCarrier'),
			new ColumnField('insurancePolicyNumber'),
			new ColumnField('insuranceExpirationDate', FilterType.Date),
			new ColumnField('insuranceLiabilityAmount', FilterType.Integer),
			new ColumnField('contact'),
			new ColumnField('notes'),
			new ColumnField('hasTestCertificate', FilterType.Boolean),
			new ColumnField('hasSurveyCertificate', FilterType.Boolean),
			new ColumnField('hasInstallationCertificate', FilterType.Boolean),
			new ColumnField('hasRepairCertificate', FilterType.Boolean),
			new ColumnField('hasLandscapeCertificate', FilterType.Boolean),
			new ColumnField('hasFireCertificate', FilterType.Boolean),
			new ColumnField('hasConfinedSpaceCertificate', FilterType.Boolean)
		],
		defaultColumns: ['companyId', 'name', 'addressLine1']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccCompanySlice}
				restState={(state: RootState) => state.cccCompanies}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export default CccCompanies;
