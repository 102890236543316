import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import { ColumnDefinition, IppSampleResult } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { SampleUtils } from 'src/components/authority/ipp/report-packages/packages/package-items/sample-utils';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { DateUtilService, localizationService } from 'src/services';
import { Utils } from 'src/services/utils';
import uuid from 'uuid';
import './import.scss';

class ImportSampleDetailComponent extends GridDetailRow {
	state = {
		sort: [] as SortDescriptor[]
	};

	private columnsKeys = [
		'importSampleResultsStatus',
		'parameterName',
		'enteredValue',
		'massLoadingValue',
		'analysisMethod',
		'enteredMethodDetectionLimit',
		'analysisDateTimeLocal'
	];

	getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = this.getDefaultShowingColumnDefinitions(this.columnsKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.samples.step2.results.${item.field}`);
			if (item.field === 'importSampleResultsStatus') {
				item.title = ' ';
			}
		});
		return defaultColumnsDefinitions;
	};

	showResult = (rowData: any) => {
		let dataItem = rowData.dataItem as IppSampleResult;
		const { enteredValue, qualifier, unitName } = dataItem;
		let { color, message, type } = SampleUtils.displayConcentrationCompliance(dataItem);
		let icon = SampleUtils.getComplianceIcon(type);
		return (
			<td className="text-right">
				<div className="d-flex align-items-center justify-content-end">
					{`${qualifier || ''} ${enteredValue || ''} ${
						!(qualifier || '').includes('N') ? unitName || '' : ''
					} `}

					{(enteredValue || qualifier === 'ND' || qualifier === 'NF') && (
						<TooltipHover
							id={`enteredValue-tooltip-${uuid()}`}
							icon={icon}
							className="ml-1"
							iconClassName={`circle-icon ${color}`}
							title={message}
						/>
					)}
				</div>
			</td>
		);
	};

	showMassLoadingResult = (rowData: any) => {
		let isMassLoadingResultToUseLessThanSign = this.props.dataItem.isMassLoadingResultToUseLessThanSign;
		let dataItem = rowData.dataItem as IppSampleResult;
		const { massLoadingValue, massLoadingQualifier, massLoadingUnitName } = dataItem;
		let { color, message, type } = SampleUtils.displayMassLoadingCompliance(
			dataItem,
			undefined,
			isMassLoadingResultToUseLessThanSign
		);
		let icon = SampleUtils.getComplianceIcon(type);
		return (
			<td className="text-right">
				<div className="d-flex align-items-center justify-content-end">
					{`${
						massLoadingQualifier === '<'
							? isMassLoadingResultToUseLessThanSign
								? massLoadingQualifier
								: ''
							: massLoadingQualifier || ''
					} ${massLoadingValue || ''} ${
						!(massLoadingQualifier || '').includes('N') ? massLoadingUnitName || '' : ''
					} `}
					{massLoadingValue && (
						<TooltipHover
							id={`massLoading-tooltip-${uuid()}`}
							icon={icon}
							className="ml-1"
							iconClassName={`circle-icon ${color}`}
							title={message}
						/>
					)}
				</div>
			</td>
		);
	};

	getSampleResultsStatus = (props: any) => {
		let result: IppSampleResult = props.dataItem;
		return (
			<td>
				{result.concentrationSampleResultId === 0 ? (
					<FontAwesomeIcon className="font-awesome-icon text-primary" icon={faCheck} />
				) : (
					!result.existingUnchanged && (
						<FontAwesomeIcon className="font-awesome-icon text-warning" icon={faCheck} />
					)
				)}
			</td>
		);
	};
	generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (i.field === 'enteredValue') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={(item: any) => this.showResult(item)}
						/>
					);
				}
				if (i.field === 'importSampleResultsStatus') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							cell={(item: any) => this.getSampleResultsStatus(item)}
							headerClassName="importStatus"
						/>
					);
				}
				if (i.field === 'massLoadingValue') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={(item: any) => this.showMassLoadingResult(item)}
						/>
					);
				}
				if (i.field.includes('DateTimeLocal')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={Utils.formatDate}
						/>
					);
				}

				return (
					<GridColumn
						key={`column_key${i.field}`}
						field={i.field}
						title={i.title}
						cell={this.localizeCellValue}
					/>
				);
			});
	};

	formatDate = (item: any, field: string) => {
		let dataItem: any = item.dataItem;
		return <td>{DateUtilService.toDisplayDate(dataItem[field])}</td>;
	};

	localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	render() {
		let columnsDefinitions = this.getColumnDefinitions();
		let columns = this.generateColumns(columnsDefinitions);
		const data = this.props.dataItem.ippSampleResults;
		if (data) {
			return (
				<Grid
					data={orderBy(data.slice(), this.state.sort)}
					scrollable="none"
					sortable
					sort={this.state.sort}
					onSortChange={(e: any) => this.setState({ sort: e.sort })}
					className="table sample-detail-grid">
					{columns}
				</Grid>
			);
		}
		return (
			<div style={{ height: '50px', width: '100%' }}>
				<div style={{ position: 'absolute', width: '100%' }}>
					<div className="k-loading-image" />
				</div>
			</div>
		);
	}
}

export default ImportSampleDetailComponent;
