import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { PaginatedResult, CleaningEvent, MaxInteger, HaulerDeviceCleaning } from '@rcp/types';
import _ from 'lodash';
import { alertService } from '../..';

export interface PumpOutEventsState {
	facilityPumpOutEvents: CleaningEvent[];
	facilityCleaningManifestEvents: HaulerDeviceCleaning[];
	authorityPumpOutEvents: PaginatedResult<CleaningEvent>;
}

export const initialPumpOutEventsState: PumpOutEventsState = {
	facilityPumpOutEvents: [],
	facilityCleaningManifestEvents: [],
	authorityPumpOutEvents: {
		page: 1,
		size: 10,
		total: 0,
		result: []
	}
};

enum PumpOutEventActionType {
	LoadFacilityPumpOutEvents = 'LoadFacilityPumpOutEventsSucceed',
	LoadCleaningManifestEvents = 'LoadCleaningManifestEvents',
	DeletePumpOutEvents = 'deletePumpOutEventsSucceed'
}

export type PumpOutEventsThunkAction = ThunkAction<any, PumpOutEventsState, any, Action>;

export const deleteFacilityPumpOutEvent = (pumpOutEvent: CleaningEvent) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	const facilityPumpOutEventsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Cleanings);
	await apiService.deleteResource(`${facilityPumpOutEventsUrl}/${pumpOutEvent.pumpOutEventId}`);
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.removeSuccess', 'pumpOut.pumpOut'));

	dispatch({ type: PumpOutEventActionType.DeletePumpOutEvents, deletedPumpOut: pumpOutEvent });
};

export const loadFacilityPumpOutEvents = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	let incompletePumpOutQueryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		incompletePumpOutQueryParams
			.put('dueDate', `gte:${startDate}`)
			.put('dueDate', `lte:${endDate}`)
			.put('completeDate', null);
	}
	const facilityPumpOutEventsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Cleanings);
	const pumpOutEventsResultByDueDate = await apiService.httpGet(
		`${facilityPumpOutEventsUrl}?${incompletePumpOutQueryParams.toQueryString()}`
	);
	const facilityPumpOutEventsByDueDate = pumpOutEventsResultByDueDate.result;

	let facilityPumpOutEventsByCompleteDate: any = [];
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		let completePumpOutQueryParams = new QueryParameters()
			.put('size', MaxInteger)
			.put('completeDate', `gte:${startDate}`)
			.put('completeDate', `lte:${endDate}`);
		const pumpOutResultByCompleteDate = await apiService.httpGet(
			`${facilityPumpOutEventsUrl}?${completePumpOutQueryParams.toQueryString()}`
		);
		facilityPumpOutEventsByCompleteDate = pumpOutResultByCompleteDate.result;
	}
	const facilityPumpOutEvents = _.concat(facilityPumpOutEventsByDueDate, facilityPumpOutEventsByCompleteDate);
	dispatch({ type: PumpOutEventActionType.LoadFacilityPumpOutEvents, facilityPumpOutEvents: facilityPumpOutEvents });
};

export const saveFacilityPumpOutEvent = (pumpOutEvent: CleaningEvent, callbackOnSuccess?: () => void) => async (
	dispatch: any
) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	const pumpOutUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Cleanings);
	if (pumpOutEvent.pumpOutEventId && pumpOutEvent.pumpOutEventId > 0) {
		await apiService.patchResource(pumpOutUrl + '/' + pumpOutEvent.pumpOutEventId, pumpOutEvent);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'pumpOut.pumpOut')
		);
	} else {
		await apiService.postResource<CleaningEvent>(pumpOutUrl, pumpOutEvent);
		alertService.addSuccess(localizationService.getLocalizedString('alertMessages.addSuccess', 'pumpOut.pumpOut'));
	}
	if (callbackOnSuccess) {
		callbackOnSuccess();
	}
	dispatch(loadFacilityPumpOutEvents());
	return true;
};

export const loadFacilityCleaningManifest = (
	startDate?: string,
	endDate?: string,
	showCustomCleaningFormsFeature: boolean = true
) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	const facilityPumpOutEventsUrl = urlService.getFacilityResourceApiUrl(
		facilityId,
		showCustomCleaningFormsFeature ? Resource.CleaningCustomForm : Resource.HaulerCleaningHistory
	);

	let facilityPumpOutEventsByCompleteDate: any = [];
	let completePumpOutQueryParams = new QueryParameters();
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		completePumpOutQueryParams = new QueryParameters()
			.put('size', MaxInteger)
			.put('completeDate', `gte:${startDate}`)
			.put('completeDate', `lte:${endDate}`);
	} else {
		completePumpOutQueryParams = new QueryParameters().put('size', MaxInteger);
	}
	let pumpOutResultByCompleteDate = await apiService.httpGet(
		`${facilityPumpOutEventsUrl}?${completePumpOutQueryParams.toQueryString()}`
	);
	facilityPumpOutEventsByCompleteDate = pumpOutResultByCompleteDate;
	dispatch({
		type: PumpOutEventActionType.LoadCleaningManifestEvents,
		facilityCleaningManifestEvents: facilityPumpOutEventsByCompleteDate
	});
};

export const pumpoutEventsReducer = (state = initialPumpOutEventsState, action: any): PumpOutEventsState => {
	switch (action.type) {
		case PumpOutEventActionType.LoadFacilityPumpOutEvents:
			return {
				...state,
				facilityPumpOutEvents: action.facilityPumpOutEvents
			};
		case PumpOutEventActionType.DeletePumpOutEvents:
			removePumpout(state.facilityPumpOutEvents, action.deletedPumpOut);
			removePumpout(state.authorityPumpOutEvents.result, action.deletedPumpOut);
			return { ...state };
		case PumpOutEventActionType.LoadCleaningManifestEvents:
			return {
				...state,
				facilityCleaningManifestEvents: action.facilityCleaningManifestEvents
			};

		default:
			return { ...state };
	}
};

function removePumpout(pumpOutEvents: CleaningEvent[], pumpOutEvent: CleaningEvent) {
	var index = pumpOutEvents.findIndex(i => i.pumpOutEventId === pumpOutEvent.pumpOutEventId);
	if (index >= 0) {
		pumpOutEvents.splice(index, 1);
	}
}
