import { HaulerPortalFacilities } from '@rcp/types';
import React from 'react';
import { localizationService } from '../../../../services';
import SelectDevicesModal from './hauler-submit-cleaning/select-devices';

interface OwnProps {
	organizationId?: number;
	facility: HaulerPortalFacilities;
}

export const SubmitCleaningActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href={'#/'} className="btn ai-white" onClick={onEditClicked}>
				{localizationService.getLocalizedString(`haulerPortal.haulerFacilityDetail.submitCleaning`)}
			</a>
			{showModal && (
				<SelectDevicesModal
					showModal={showModal}
					organizationId={props.organizationId}
					facility={props.facility ? props.facility : ({} as HaulerPortalFacilities)}
					onCancel={toggleModal}
					isTriggeredFromGrid={true}
				/>
			)}
		</>
	);
};
