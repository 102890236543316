import { ApiError, DataImport, Dictionary } from '@rcp/types';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup } from 'src/components';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { apiService, localizationService, Logger, QueryParameters, Resource, urlService } from 'src/services';
import { importConfigurationSlice } from '../import-configuration.slice';
import SampleGrid from '../import-steps/configuration/sample-grid';
import { SelfImportStep2Data } from './step2-choose-file';
import {
	getImportEntityPolicyOptions,
	getMapConfigurationTableData,
	SelfImportSteps,
	updateSelfImportState,
	toTargetFieldOptions,
	closeSideEditor,
	clearSelfImportStepsState,
	SelfImportState,
	initialSelfImportState
} from './self-import-service';

export interface SelfImportStep3Data extends SelfImportState {
	importDataType: string;
	importConfigurationId: number;
	importConfigurationName: string;
	importFileId: string;
	importEntityPolicy: string;
	isSystem: boolean;
	isDraft: boolean;
	isSaving: boolean;
	isFileImportedBefore?: boolean;
}

export const initialSelfImportStep3Data: SelfImportStep3Data = {
	...initialSelfImportState,
	importDataType: DataImport.ImportDataTypeValues.FogFacilitiesAndContacts
};

interface Props {}

export const SelfImportStep3: React.FC<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const step2Data: SelfImportStep2Data = stepperContext.getData(SelfImportSteps.STEP2);
	const [state, setState] = React.useState<SelfImportStep3Data>(initialSelfImportStep3Data);
	const [clickedPreview, setClickedPreview] = React.useState(false);

	const selfImportState = useSelector((state: RootState) => state.selfImport);
	const dispatch = useDispatch();

	const { userProfile } = useReduxSelector(state => state.userProfile);
	const isAdministratorOrInternalUser = (): boolean => {
		return urlService.isAdministrator() || userProfile.isInternalAccount === true;
	};

	const isEditable = () => {
		return isAdministratorOrInternalUser() && state.importConfigurationId > 0 && !state.isSystem;
	};

	const clearSelfImportStepsWhenCurrentStepChanged = () => {
		clearSelfImportStepsState(stepperContext, SelfImportSteps.STEP4, SelfImportSteps.STEP5);
	};

	const onImportPolicyChanged = (e: any) => {
		if (!isEditable()) {
			return;
		}
		const { value } = e.target;
		if (value !== state.importEntityPolicy) {
			setState({ ...state, importEntityPolicy: value });
			clearSelfImportStepsWhenCurrentStepChanged();
			dispatch(
				importConfigurationSlice.patchOne(
					state.importConfigurationId,
					{ importEntityPolicy: value },
					false,
					undefined,
					() => {
						dispatch(updateSelfImportState({ importEntityPolicy: value }));
					}
				)
			);
		}
	};

	const previewImport = async () => {
		let stepState = { ...stepperContext.getStep(SelfImportSteps.STEP3), loading: false, ...state };
		return stepperContext.resolve({ ...stepState });
	};

	const onClickNext = (event: React.FormEvent) => {
		closeSideEditor(selfImportState.current, dispatch);
		setClickedPreview(true);
	};

	React.useEffect(() => {
		const step3Data: SelfImportStep3Data = stepperContext.getData(SelfImportSteps.STEP3);
		if (step3Data) {
			let newState = { ...step3Data, ...selfImportState.current };
			setState(newState);
			urlService.replaceUrlQueryString({
				dataType: newState.importDataType,
				configurationId: _.toString(newState.importConfigurationId),
				importFileId: newState.importFileId,
				step: '3'
			});
		} else {
			let newState = { ...state, ...step2Data, dataType: _.get(step2Data, 'importDataType') };
			let step = urlService.getUrlQueryParameter('step') as string;
			if (_.toNumber(step) < 3) {
				step = '3';
			}
			urlService.replaceUrlQueryString({
				dataType: newState.importDataType,
				configurationId: _.toString(newState.importConfigurationId),
				importFileId: newState.importFileId,
				step: step
			});

			setState({ ...newState });
			updateReduxFromStep2();

			if (_.toNumber(step) > 3) {
				Logger.debug(`Step 3 loaded: Url has step ${step}.`);
				//When the user could apply browser refresh at step 4
				setClickedPreview(true);
			}
		}
	}, []);

	React.useEffect(() => {
		if (clickedPreview === true && state.importDataType && state.importFileId) {
			setClickedPreview(false);
			previewImport();
		}
	}, [clickedPreview, state]);

	const updateReduxFromStep2 = () => {
		if (step2Data) {
			let data = getMapConfigurationTableData(step2Data.uploadDataColumns, step2Data.uploadDataRows);
			let fieldOptions = toTargetFieldOptions(step2Data.targetFields);
			dispatch(
				updateSelfImportState({
					...step2Data,
					fieldOptions,
					data
				})
			);
		}
	};

	React.useEffect(() => {
		if (selfImportState) {
			Logger.debug(`SelfImportState change:`, selfImportState.current);
			setState(state => {
				return { ...state, ...selfImportState.current };
			});
		}
	}, [selfImportState.current]);

	return (
		<StepperContent
			id={SelfImportSteps.STEP3}
			actions={
				<React.Fragment>
					<StepperAction
						type="button"
						className="btn btn-link"
						id="btnBack"
						onClick={() => {
							closeSideEditor(selfImportState.current, dispatch);
							urlService.replaceUrlQueryString({ step: '2' });
							stepperContext.goAt(SelfImportSteps.STEP2);
						}}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction
						type="button"
						id="btnNext"
						className="btn btn-link"
						disabled={clickedPreview === true || _.size(state.fieldMappings) < 1 || state.isSaving}
						onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<RadioGroup
				id="importPolicy"
				name="importChooseAFile"
				label={localizationService.getLocalizedString('import.steps.importPolicyTitle')}
				options={getImportEntityPolicyOptions()}
				value={state.importEntityPolicy}
				isRequired={true}
				readonly={!isEditable()}
				onChange={onImportPolicyChanged}
			/>
			<div className={state.showSidePanel ? 'simple-data-grid-with-side-editor' : 'simple-data-grid'}>
				<SampleGrid
					data={state.data}
					isConfigurationEditable={isEditable()}
					isDraftConfiguration={step2Data.isDraft}
					fieldOptions={state.fieldOptions}
					fieldMappings={state.fieldMappings}
					importConfigurationId={step2Data.importConfigurationId}
					importFileId={step2Data.importFileId}
					targetFields={step2Data.targetFields}
					uploadDataColumns={step2Data.uploadDataColumns}
					uploadDataRows={step2Data.uploadDataRows}
					totalRowNumber={_.size(step2Data.uploadDataRows)}
					sideMode={true}
				/>
			</div>

			{state.error && <div className="ai-required">{state.error}</div>}
		</StepperContent>
	);
};
