import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';

import { AttachmentOwnership, AttachmentType, Extractor } from '@rcp/types';
import {
	ApplicationState,
	defaultNumberOfDeviceAttachments,
	DeviceAttachmentsState,
	loadDeviceAttachments
} from 'src/redux';
import { urlService, localizationService } from 'src/services';
import { CloseSvg } from '../../../../svg';

import 'src/components/widgets/attachment/attachment.scss';
import _ from 'lodash';
import { AttachmentThumbnail } from 'src/components/widgets';
import { useRouteMatch } from 'react-router-dom';
import { AddAttachment } from '../../facilities/facility-attachments/add-attachment';

interface CompProps {
	device: Extractor;
	attachmentType?: AttachmentType;
	ownerId?: number;
	attachments?: AttachmentOwnership[];
	onAttachmentClick?: (attachment: AttachmentOwnership) => void;
}

interface StateProps extends DeviceAttachmentsState {}

interface DispatchProps {
	loadDeviceAttachments: (
		deviceId: number,
		doesGetAll: boolean,
		attachmentType?: AttachmentType,
		ownerId?: number
	) => void;
}

type Props = StateProps & DispatchProps & CompProps;

const DeviceAttachmentsComp: React.SFC<Props> = props => {
	const loadDeviceAttachments = (doesGetAll = true) => {
		let deviceId = urlService.getFogDeviceId();

		if (deviceId <= 0 && props.device) {
			if (props.device.extractorId) {
				deviceId = props.device.extractorId;
			}
		}
		props.loadDeviceAttachments(deviceId, doesGetAll, props.attachmentType, props.ownerId);
	};

	let { url } = useRouteMatch();

	React.useEffect(() => {
		loadDeviceAttachments(false);
	}, [props.ownerId]);

	const cancelShowAll = (e: any) => {
		e.preventDefault();
		if (props.deviceAttachments.length > defaultNumberOfDeviceAttachments) {
			loadDeviceAttachments(false);
		}
	};

	const onShowAllClicked = (e: any) => {
		e.preventDefault();
		loadDeviceAttachments();
	};

	const getAttachmentDetailUrl = (attachment: AttachmentOwnership) => {
		let returnRef = `${url}/attachments/${attachment.attachmentId}`;
		returnRef += `?backToLabel=backToDevice`;
		return returnRef;
	};

	let attachments = props.deviceAttachments;

	return (
		<section className="attachments">
			<div className="d-flex">
				<h3>{localizationService.getLocalizedString('attachments.title')}</h3>
				{props.totalDeviceAttachments > defaultNumberOfDeviceAttachments &&
					attachments.length === props.totalDeviceAttachments && (
						<button className="close ml-auto" onClick={cancelShowAll}>
							<CloseSvg />
						</button>
					)}
			</div>
			<div className="attachment-list mx-n1">
				{attachments.map((attachment: AttachmentOwnership, index: number) => {
					const linkTo = getAttachmentDetailUrl(attachment);
					return (
						<AttachmentThumbnail
							attachment={attachment}
							facilityWidget={props.attachmentType == null}
							key={`icon-${index}`}
							className="attachment-thumbnail m-1"
							linkTo={linkTo}
							onAttachmentClick={props.onAttachmentClick}
						/>
					);
				})}
			</div>
			<div className="d-flex">
				<div className="w-90">
					<AddAttachment
						attachmentType={props.attachmentType}
						ownerId={props.ownerId}
						uploadCallBack={loadDeviceAttachments}
					/>
				</div>
				{props.totalDeviceAttachments > defaultNumberOfDeviceAttachments &&
					props.totalDeviceAttachments !== attachments.length && (
						<button
							type="button"
							className="pr-0 ml-auto text-right ai-edit-link btn btn-link"
							onClick={onShowAllClicked}>
							{localizationService.getLocalizedString('attachments.showAll')}
						</button>
					)}
			</div>
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.deviceAttachments };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => ({
	loadDeviceAttachments: (deviceId: number, doesGetAll: boolean, attachmentType?: AttachmentType, ownerId?: number) =>
		dispatch(loadDeviceAttachments(deviceId, doesGetAll, attachmentType, ownerId))
});

export const DeviceAttachments = connect<StateProps, DispatchProps, CompProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(DeviceAttachmentsComp);
