import React, { FC, useState } from 'react';
import { History } from 'history';
import { ColumnField, FilterType, LocalStorageName, Incident, RouteProps, FilterObjectName } from '@rcp/types';
import { RestSlice } from 'src/redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';
import { incidentsSlice } from './incidents-slice';
import { IncidentModal } from './Incident-modal';
import { nameof } from 'ts-simple-nameof';
import { IncidentEditActionLink } from './incident-edit-action-link';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const IncidentsGrid: FC<Props<Incident>> = props => {
	const [showAddModal, setAddShowModal] = useState(false);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const editIncidentCell = (props: any) => {
		const incident = props.dataItem as Incident;
		return <td>{<IncidentEditActionLink incident={incident} />}</td>;
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('incidents.incidents'),
		prefix: 'incidents',
		storageName: LocalStorageName.IncidentsGrid,
		gridUrlLocalStorageName: LocalStorageName.IncidentsFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.IncidentsSortOrder,
		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('incidents.searchHint'),
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('incidents.addIncident'),
		filterOnlyThisGrid: true,
		showAddModal: showAddModal,
		hasGridMap: true,
		toggleAddModal: toggleAddModal,
		unsetPageOnFilterApply: false,
		addModalComponent: (
			<>
				{showAddModal && (
					<IncidentModal showModal={showAddModal} setShowModal={toggleAddModal} isAddModal={true} />
				)}
			</>
		),
		resetFilterService: () => {
			filterService.init(
				FilterDomain.INCIDENT,
				FilterDomainName.incident,
				FilterObjectName.Incident,
				LocalStorageName.IncidentsFilterUrl,
				{ INCIDENT: true }
			);
		},
		allColumns: [
			new ColumnField(
				nameof<Incident>(h => h.incidentNumber),
				FilterType.Text,
				editIncidentCell
			),
			new ColumnField(
				nameof<Incident>(h => h.address),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.city),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.latitude),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.longitude),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.reportedDate),
				FilterType.Date
			),
			new ColumnField(
				nameof<Incident>(h => h.incidentTypeCode),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.incidentStatusCode),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.incidentSourceCode),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.assetId),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.investigatorFullName),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.incidentDescription),
				FilterType.Text
			),
			new ColumnField(
				nameof<Incident>(h => h.resolvedDate),
				FilterType.Date
			),
			new ColumnField(
				nameof<Incident>(h => h.resolutionDescription),
				FilterType.Text
			)
		],
		defaultColumns: [
			nameof<Incident>(h => h.incidentNumber),
			nameof<Incident>(h => h.address),
			nameof<Incident>(h => h.city),
			nameof<Incident>(h => h.reportedDate),
			nameof<Incident>(h => h.incidentTypeCode),
			nameof<Incident>(h => h.incidentStatusCode),
			nameof<Incident>(h => h.incidentSourceCode),
			nameof<Incident>(h => h.investigatorFullName),
			nameof<Incident>(h => h.incidentDescription)
		]
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={incidentsSlice}
				restState={(state: RootState) => state.incidents}
				gridOption={initialGridOption}
			/>
		</>
	);
};
