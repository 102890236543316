import React, { useState } from 'react';
import { InspectionEvent } from '@rcp/types';
import { localizationService } from 'src/services';
import { InspectionEventModal } from '../../facilities/facility-details/inspection-event-modal';

import { CurrentInspectionSummary } from './current-inspection-summary';

interface OwnProps {
	inspection: InspectionEvent;
	loadCurrentInspection?: Function;
	isReadOnly?: boolean;
}

type Props = OwnProps;

export const InspectionSummary: React.SFC<Props> = props => {
	const [showEditModal, setShowEditModal] = useState(false);

	const hideShowEditModal = () => {
		setShowEditModal(!showEditModal);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		hideShowEditModal();
	};

	return (
		<section>
			<div id="inspectionSummary" className="d-flex">
				<h3>{localizationService.getLocalizedString('inspection.inspectionSummary')}</h3>

				{!props.isReadOnly &&
				<a
					href="#/"
					onClick={onEditClicked}
					id="linkEditInspectionSummary"
					className="font-size-16px ai-edit-link ml-auto mt-n1">
					{localizationService.getLocalizedString('screen.buttons.edit')}
				</a>}
			</div>
			<CurrentInspectionSummary inspection={props.inspection} />
			{showEditModal && (
				<InspectionEventModal
					inspection={props.inspection}
					isToggle={showEditModal}
					toggle={hideShowEditModal}
					isFromInspectionDetails={true}
					loadCurrentInspection={props.loadCurrentInspection}
				/>
			)}
		</section>
	);
};
