import _ from 'lodash';
import React from 'react';
import { DataImport, Dictionary } from '@rcp/types';
import { useDispatch, useSelector } from 'react-redux';
import { RadioGroup, RadioOption } from 'src/components/widgets';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState } from 'src/redux';
import { localizationService, Logger, urlService } from 'src/services';
import { selfImportDataTypeSlice } from '../self-import.slice';
import { clearSelfImportStepsState, closeSideEditor, SelfImportSteps } from './self-import-service';

export interface SelfImportStep1Data {
	dataType: string;
}

export const initialSelfImportStep1Data: SelfImportStep1Data = {
	dataType: DataImport.ImportDataTypeValues.FogFacilitiesAndContacts
};

export const SelfImportStep1: React.FC<{}> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [state, setState] = React.useState<SelfImportStep1Data>(initialSelfImportStep1Data);
	const [dataTypeOptions, setDataTypeOptions] = React.useState<RadioOption[]>([]);
	const importDataTypes: DataImport.ImportDataType[] = useSelector(
		(state: RootState) => state.selfImportDataTypes.result
	);
	const selfImportState = useSelector((state: RootState) => state.selfImport).current;
	const dispatch = useDispatch();

	React.useEffect(() => {
		//user can directly click step 1 title move from step 3
		closeSideEditor(selfImportState, dispatch);

		if (_.isEmpty(dataTypeOptions)) {
			dispatch(selfImportDataTypeSlice.fetchAll());
		}

		const step1Data = stepperContext.getData(SelfImportSteps.STEP1);
		if (step1Data) {
			let newState = { ...state, ...step1Data };
			urlService.replaceUrlQueryString({ dataType: newState.dataType, step: '1' });
			setState(newState);
		} else {
			let dataType = urlService.getUrlQueryParameter('dataType') as string;
			if (dataType) {
				setState({ ...state, dataType: dataType });
			}
			let queryParams: Dictionary<string> = { dataType };
			let step = urlService.getUrlQueryParameter('step') as string;
			queryParams.step = step || '1';
			urlService.replaceUrlQueryString(queryParams);

			if (step && _.toNumber(step) > 1) {
				Logger.debug(`Step 1 loaded: Url has step ${step}.`);
				stepperContext.resolve({ dataType });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (_.isEmpty(importDataTypes)) {
			return;
		}

		let newDataTypeOptions: RadioOption[] = [];
		importDataTypes.forEach(dataType => {
			let label = dataType.i18nKey ? localizationService.getLocalizedString(dataType.i18nKey) : dataType.label;
			let isDisabled = dataType.isSupported !== true;
			if (!isDisabled || Logger.isDevelopmentEnvironment()) {
				if (isDisabled) {
					label += ' (Incoming)';
				}
				let option: RadioOption = {
					label: label,
					value: dataType.importDataType,
					isDisabled: isDisabled,
					id: `importDataType${dataType.importDataType}`
				};
				newDataTypeOptions.push(option);
			}
		});
		setDataTypeOptions(newDataTypeOptions);
	}, [importDataTypes]);

	const clearSelfImportStepsWhenCurrentStepChanged = () => {
		urlService.removeUrlQueryParam('configurationId', 'importFileId');
		clearSelfImportStepsState(
			stepperContext,
			SelfImportSteps.STEP2,
			SelfImportSteps.STEP3,
			SelfImportSteps.STEP4,
			SelfImportSteps.STEP5
		);
	};

	const onDataTypeSelected = (e: any) => {
		const { value } = e.target;
		const selectedDataType = value;
		if (selectedDataType !== state.dataType) {
			let newState = { ...state, dataType: selectedDataType };
			urlService.replaceUrlQueryString({ dataType: newState.dataType, step: '1' });
			setState(newState);
			clearSelfImportStepsWhenCurrentStepChanged();
		}
	};

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();

		urlService.replaceUrlQueryString({ step: '2' });
		stepperContext.resolve({ dataType: state.dataType });
		return;
	};

	return (
		<StepperContent
			id={SelfImportSteps.STEP1}
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="w-100">
				<RadioGroup
					id="importDataType"
					name="importDataType"
					label={localizationService.getLocalizedString('import.steps.step1Question')}
					options={dataTypeOptions}
					value={state.dataType}
					isRequired={true}
					onChange={onDataTypeSelected}
				/>
			</div>
		</StepperContent>
	);
};
