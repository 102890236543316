import _ from 'lodash';
import React from 'react';
import {
	PopoverModal,
	TextInput,
	SingleCheckbox,
	SingleSelectDropdown,
	DeleteModalProp,
	TextAreaInput,
	DeleteModal
} from 'src/components/widgets';
import { alertService, useReduxSelector, useReduxDispatch, loadJurisdiction } from 'src/redux';
import { Hauler, DropDownOption, Jurisdiction, AutoNumberDomainType, FeatureNames } from '@rcp/types';
import { validationService, localizationService, authoritySettingService } from 'src/services';
import { haulersSlice } from '.';

interface Props {
	showModal: boolean;
	setShowModal: () => void;
	hauler?: Hauler;
	isAddModal: boolean;
}

interface FormFields {
	haulerId?: number;
	haulerNumber?: string;
	name?: string;
	addressId?: number;
	addressLine1?: string;
	addressLine2?: string;
	addressCity?: string;
	addressJurisdictionId?: number;
	addressJurisdictionCode?: string;
	addressZipCode?: string;
	phone?: string;
	email?: string;
	comments?: string;
	isActive?: boolean;
	isPreferred?: boolean;

	haulerNumberError?: string;
	nameError?: string;

	emailError?: string;
}

const initialFormFields: FormFields = {
	haulerNumber: '',
	name: '',
	addressLine1: '',
	addressLine2: '',
	addressCity: '',
	addressJurisdictionCode: '',
	addressZipCode: '',
	email: '',
	phone: '',
	comments: '',
	isActive: true,
	isPreferred: false
};

export const HaulerCardModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState(initialFormFields);
	const [stateProvinceOptionValues, setStateProvinceOptionValues] = React.useState([] as DropDownOption[]);
	const [deleteHaulerModal, setToggleDeleteHaulerModal] = React.useState(false);

	const dispatch = useReduxDispatch();
	const jurisdictions = useReduxSelector(s => s.jurisdictions.jurisdictionList);

	const getNewFormFieldState = (hauler: Hauler) => {
		const newState: FormFields = { ...hauler };

		return newState;
	};

	React.useEffect(() => {
		if (props.showModal === true && props.isAddModal) {
			authoritySettingService.getNextAutoNumber(AutoNumberDomainType.Hauler).then((nextHaulerAutoNumber: any) => {
				let newState = { ...initialFormFields };
				newState.haulerNumber = nextHaulerAutoNumber as string;
				setFormState(newState);
			});
		}
	}, [props.showModal, props.isAddModal]);

	React.useEffect(() => {
		if (props.hauler) {
			const newState: FormFields = getNewFormFieldState(props.hauler);

			setFormState(newState);
		}
	}, [props.hauler]);

	React.useEffect(() => {
		dispatch(loadJurisdiction());
	}, [dispatch]);

	React.useEffect(() => {
		let optionValues: DropDownOption[] = [];

		if (jurisdictions && jurisdictions.length > 0) {
			optionValues = jurisdictions.map((jurisdiction: Jurisdiction) => {
				return { label: jurisdiction.name, value: jurisdiction.jurisdictionId };
			});
		}

		setStateProvinceOptionValues(optionValues);
	}, [jurisdictions]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		_.set(newState, name, value);

		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(newState, 'haulerNumber', 'haulerNumberError');
		validationService.validateRequiredField(newState, 'name', 'nameError');
		validationService.validateEmailFormatField(newState, 'email', 'emailError');

		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			'haulerNumberError',
			'nameError',
			'phoneError',
			'emailError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const resetHaulerForm = () => {
		if (props.isAddModal) {
			setFormState(initialFormFields);
		} else {
			if (props.hauler) {
				const newState: FormFields = getNewFormFieldState(props.hauler);
				setFormState(newState);
			}
		}
	};

	const saveHauler = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let haulerToAddOrUpdate: any = {};

		haulerToAddOrUpdate.haulerId = formState.haulerId ? formState.haulerId : 0;
		haulerToAddOrUpdate.haulerNumber = formState.haulerNumber;
		haulerToAddOrUpdate.name = formState.name;
		haulerToAddOrUpdate.addressLine1 = formState.addressLine1;
		haulerToAddOrUpdate.addressLine2 = formState.addressLine2;
		haulerToAddOrUpdate.addressCity = formState.addressCity;

		if (jurisdictions && formState.addressJurisdictionId) {
			let filteredJurisdictionList = jurisdictions
				.filter(x => x.jurisdictionId === _.toNumber(formState.addressJurisdictionId))
				.map(x => x.code);
			haulerToAddOrUpdate.addressJurisdictionCode =
				filteredJurisdictionList.length > 0 ? filteredJurisdictionList[0] : '';
		}

		haulerToAddOrUpdate.addressZipCode = formState.addressZipCode;
		haulerToAddOrUpdate.phone = formState.phone;
		haulerToAddOrUpdate.email = formState.email;
		haulerToAddOrUpdate.comments = formState.comments;
		haulerToAddOrUpdate.isActive = formState.isActive !== undefined ? formState.isActive : false;
		haulerToAddOrUpdate.isPreferred = formState.isPreferred !== undefined ? formState.isPreferred : false;

		if (props.isAddModal) {
			dispatch(
				haulersSlice.createOne(
					haulerToAddOrUpdate,
					true,
					localizationService.getLocalizedString('alertMessages.addSuccess', 'haulers.hauler'),
					() => {
						resetHaulerForm();
						props.setShowModal();
					}
				)
			);
		} else {
			if (props.hauler && props.hauler.haulerId) {
				haulerToAddOrUpdate.haulerId = props.hauler.haulerId;
				dispatch(
					haulersSlice.putOne(
						props.hauler.haulerId,
						haulerToAddOrUpdate,
						true,
						localizationService.getLocalizedString('alertMessages.updateSuccess', 'haulers.hauler'),
						() => {
							resetHaulerForm();
							props.setShowModal();
						}
					)
				);
			}
		}
	};

	const cancelSaveHauler = () => {
		resetHaulerForm();
		props.setShowModal();
	};

	const onDeleteHauler = async () => {
		if (props.hauler && props.hauler.haulerId) {
			dispatch(
				haulersSlice.deleteOne(
					props.hauler.haulerId,
					true,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'haulers.hauler'),
					() => {
						onToggleDeleteHaulerModal();
						if (props.showModal) {
							props.setShowModal();
						}
					}
				)
			);
		}
	};

	const onToggleDeleteHaulerModal = () => {
		setToggleDeleteHaulerModal(!deleteHaulerModal);
	};

	let deleteHaulerModalProp: DeleteModalProp = {
		title: 'Delete Hauler?',
		message: `${props.hauler ? props.hauler.name : ''} (${
			props.hauler ? props.hauler.haulerNumber : ''
		}) will be removed.`,
		showModal: deleteHaulerModal,
		onCancelButtonClick: onToggleDeleteHaulerModal,
		onOkayButtonClick: onDeleteHauler,
		okayButtonText: 'Delete',
		isDeleteButton: true
	};

	const modalFooterDiv = (
		<>
			{!props.isAddModal && (
				<button type="button" className="btn ai-secondary-delete" onClick={onToggleDeleteHaulerModal}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
			)}
			<button className="btn ai-save ml-auto" onClick={saveHauler}>
				{localizationService.getLocalizedString('screen.buttons.save')}
			</button>
			<button className="btn ai-white" onClick={cancelSaveHauler}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</>
	);

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={
					props.isAddModal
						? localizationService.getLocalizedString('haulers.addHauler')
						: localizationService.getLocalizedString('haulers.editHauler')
				}
				save={saveHauler}
				cancel={cancelSaveHauler}
				footer={modalFooterDiv}>
				<TextInput
					id="name"
					name="name"
					label={localizationService.getLocalizedString('haulers.name')}
					value={formState.name}
					onChange={changeFormState}
					isRequired={true}
					error={formState.nameError}
				/>

				<TextInput
					id="haulerNumber"
					name="haulerNumber"
					label={localizationService.getLocalizedString('haulers.haulerNumber')}
					value={formState.haulerNumber}
					onChange={changeFormState}
					isRequired={true}
					error={formState.haulerNumberError}
				/>

				<TextInput
					id="phone"
					name="phone"
					label={localizationService.getLocalizedString('haulers.phone')}
					type="tel"
					value={formState.phone}
					onChange={changeFormState}
				/>

				<TextInput
					id="email"
					name="email"
					label={localizationService.getLocalizedString('haulers.email')}
					type="email"
					value={formState.email}
					onChange={changeFormState}
					error={formState.emailError}
				/>

				<TextInput
					id="addressLine1"
					name="addressLine1"
					label={localizationService.getLocalizedString('haulers.address')}
					helpText={localizationService.getLocalizedString('screen.helpText.address1')}
					value={formState.addressLine1}
					onChange={changeFormState}
				/>

				<TextInput
					id="addressLine2"
					name="addressLine2"
					label={localizationService.getLocalizedString('haulers.address2')}
					helpText={localizationService.getLocalizedString('screen.helpText.address2')}
					value={formState.addressLine2}
					onChange={changeFormState}
				/>

				<div className="form-row">
					<TextInput
						id="addressCity"
						name="addressCity"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('haulers.city')}
						value={formState.addressCity}
						onChange={changeFormState}
					/>
					<SingleSelectDropdown
						id="addressJurisdictionId"
						name="addressJurisdictionId"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('haulers.state')}
						value={_.toString(formState.addressJurisdictionId)}
						onChange={changeFormState}
						options={stateProvinceOptionValues}
					/>
					<TextInput
						id="addressZipCode"
						name="addressZipCode"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('haulers.zip')}
						value={formState.addressZipCode}
						onChange={changeFormState}
					/>
				</div>

				<TextAreaInput
					id="comments"
					name="comments"
					label={localizationService.getLocalizedString('haulers.comments')}
					value={formState.comments}
					onChange={changeFormState}
					isFullWidth={true}
				/>

				<SingleCheckbox
					id="isActive"
					name="isActive"
					label={localizationService.getLocalizedString('haulers.active')}
					checked={formState.isActive}
					onChange={changeFormState}
					className="div-checkbox"
				/>
				<SingleCheckbox
					id="isPreferred"
					name="isPreferred"
					label={localizationService.getLocalizedString('haulers.isPreferred')}
					checked={formState.isPreferred}
					onChange={changeFormState}
					className="div-checkbox"
				/>
			</PopoverModal>
			<DeleteModal {...deleteHaulerModalProp} key="deleteHaulerModal" />
		</div>
	);
};
