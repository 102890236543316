import React, { FC, useState } from 'react';
import { FacilityCleaningManifest } from '@rcp/types';
import { localizationService } from '../../../../services';
import FacilitySubmitCleaningModal from './submit-facility-cleaning';
import { Translate } from 'src/components/widgets/translate/translator';

interface OwnProps {
	organizationId?: number;
	facility: FacilityCleaningManifest;
	deviceDetails: FacilityCleaningManifest;
}

export const SubmitFacilityCleaningActionLink: FC<OwnProps> = props => {
	const [showModal, setShowModal] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const submitCleaningProps = {
		showModal: showModal,
		title: localizationService.getLocalizedString('haulerPortal.submitCleaning.submitCleaning'),
		onCancel: toggleModal,
		facility: props.facility,
		deviceDetails: props.deviceDetails
	};

	return (
		<>
			<a href={'#/'} className="btn ai-white" onClick={onEditClicked}>
				<Translate>{localizationService.getLocalizedString(`pumpOut.submitCleaning`)}</Translate>
			</a>
			{showModal && <FacilitySubmitCleaningModal {...submitCleaningProps} />}
		</>
	);
};
