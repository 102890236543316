import React, { FC } from 'react';
import { History } from 'history';
import { ColumnField, FilterType, LocalStorageName, RouteProps, InternalUser, RegulatoryProgramName } from '@rcp/types';
import { DataGrid, GridOption } from '../../features/data-grid/data-grid';
import { RootState, RestSlice } from 'src/redux';
import { localizationService, urlService } from 'src/services';
import { UserEditorModal } from '../authority/shared/settings/users/user-editor';
import { internalUsersSlice } from '../authority/shared/settings/users/user-slice';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const EditActionLink: React.FC<{ user: InternalUser }> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};
	const toggleModal = () => {
		setShowModal(!showModal);
	};
	return (
		<>
			{urlService.isAdministrator() ? (
				<a href="#/" className="ai-link" onClick={e => onEditClicked(e)}>
					{props.user.fullName}
				</a>
			) : (
				<div>{props.user.fullName}</div>
			)}
			{showModal && (
				<UserEditorModal
					showModal={showModal}
					user={props.user}
					toggleModal={toggleModal}
					isCreate={false}
					isForInternalUser={true}
				/>
			)}
		</>
	);
};

export const CustomerSuccessUserGrid: FC<Props<InternalUser>> = props => {
	const [showAddModal, setAddShowModal] = React.useState(false);
	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};
	const userToLink = (props: any) => {
		const user = props.dataItem as InternalUser;
		return (
			<td>
				<EditActionLink user={user} />
			</td>
		);
	};

	const filterOnUsers = (users: [], searchTerm: string): [] => {
		const isContains = (searchTerm: string, searchString?: string) => {
			return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
		};

		let filteredUsers = users.filter((user: InternalUser) => {
			return (
				user &&
				(isContains(searchTerm, user.fullName) ||
					isContains(searchTerm, user.email) ||
					isContains(searchTerm, user.status))
			);
		});
		return filteredUsers as [];
	};

	const populateStatusText = (users: []): [] => {
		let newUsers = users.map((user: InternalUser) => {
			let status = user.isRemoved ? 'Deleted' : user.isAccountLocked ? 'Locked' : 'Active';
			return { ...user, status: status };
		});
		return newUsers as [];
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('users.customerSuccessUsers'),
		prefix: 'users',
		storageName: LocalStorageName.UserGrid,
		sortOrderLocalStorageName: LocalStorageName.UserGridSortOrder,

		showEditColumnButton: true,
		showSearch: true,
		searchPlaceholder: localizationService.getLocalizedString('users.search'),

		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('users.addUser'),
		addModalComponent: (
			<>
				{showAddModal && (
					<UserEditorModal
						showModal={showAddModal}
						toggleModal={toggleAddModal}
						isCreate={true}
						isForInternalUser={true}
					/>
				)}
			</>
		),
		toggleAddModal: toggleAddModal,

		queryParameters: { includeRemoved: '1', includeInactive: '1' },
		defaultSort: [{ field: 'fullName', dir: 'asc' }],
		allColumns: [
			new ColumnField('fullName', FilterType.Text, userToLink),
			new ColumnField('email'),
			new ColumnField('status')
		],
		defaultColumns: ['fullName', 'email', 'status'],

		doClientSearch: filterOnUsers,
		populateComputedColumns: populateStatusText,
		showBackButton: String.equalCaseInsensitive(urlService.getCurrentProgramOrDefault(), RegulatoryProgramName.FOG)
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={internalUsersSlice}
				restState={(state: RootState) => state.internalUsers}
				gridOption={initialGridOption}
			/>
		</>
	);
};
