import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum PendingInvitationsActionType {
	CheckEmail = 'checkEmail'
}

export interface CheckEmailAction extends Action {
	type: PendingInvitationsActionType.CheckEmail;
	userInvite: ApiTypes.IppUserInvite;
}

export type PendingInvitationsAction = CheckEmailAction;

export const checkEmail = (userInvite: ApiTypes.IppUserInvite): CheckEmailAction => ({
	type: PendingInvitationsActionType.CheckEmail,
	userInvite: userInvite
});
