import React, { useState } from 'react';
import { TabViewField } from 'src/components/widgets';
import { localizationService, UtilService } from 'src/services';
import { CccHazard } from '@rcp/types';
import { CccHazardOverviewTabModal } from './hazard-overview-tab-modal';

interface OwnProps {
	currentCccHazard: CccHazard;
}

type Props = OwnProps;

export const CccHazardOverviewTab: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.serviceType')}
						value={UtilService.formatValue(props.currentCccHazard.serviceType)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.serviceStatus')}
						value={UtilService.formatValue(props.currentCccHazard.serviceStatus)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.serviceNumber')}
						value={UtilService.formatValue(props.currentCccHazard.serviceNumber)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.meterLocation')}
						value={UtilService.formatValue(props.currentCccHazard.meterLocation)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceStatus')}
						value={UtilService.formatValue(props.currentCccHazard.deviceStatus)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceSerialNumber')}
						value={UtilService.formatValue(props.currentCccHazard.deviceSerialNumber)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceLocation')}
						value={UtilService.formatValue(props.currentCccHazard.deviceLocation)}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
			{isToggle && (
				<CccHazardOverviewTabModal currentHazard={props.currentCccHazard} isToggle={isToggle} toggle={toggle} />
			)}
		</>
	);
};
