import { EntityContact, EntityContact_EntityType } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { localizationService, urlService } from 'src/services';
import { extractJsonEmails, extractJsonNumbers } from '.';
import { LetterTemplateCategory } from '@rcp/types';
import { FacilitySendEmail } from '../../components/authority/fog/facilities/facility-details/send-email';
import './contact-card.scss';
import { FacilitySvg, GreaseTrap } from 'src/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faTruck } from '@fortawesome/free-solid-svg-icons';
import { deviceOwner } from 'src/components/authority/fog/fog-devices/details/device-contacts-tab';
import { DomainSvg } from 'src/components/svg/domain-svg';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';

interface Props {
	contact: EntityContact;
	isDeviceDetailsPage?: boolean;
	showSpan?: boolean;
}

export const FogContactCardBody: React.FC<Props> = props => {
	const phones = extractJsonNumbers(props.contact.jsonNumbers);
	const emails = extractJsonEmails(props.contact.jsonEmails);
	const [selectedEmail, setSelectedEmail] = React.useState([] as any[]);
	const [showEmailModal, setShowEmailModal] = React.useState(false);

	const sendEmailPopUp = (address: string, name: string) => {
		const selectEmail: any[] = [
			{
				name,
				address,
				selected: true
			}
		];
		setSelectedEmail(selectEmail);
		setShowEmailModal(true);
	};
	let contactAddress = props.contact.addressLine1;
	if (contactAddress && props.contact.addressLine2) {
		contactAddress = `${contactAddress}, ${props.contact.addressLine2}`;
	}
	let contactCity = props.contact.addressCity || '';
	if (props.contact.addressJurisdictionCode) {
		if (contactCity) {
			contactCity += ' ' + props.contact.addressJurisdictionCode;
		} else {
			contactCity = props.contact.addressJurisdictionCode;
		}
	}
	contactCity += ' ' + (props.contact.addressZipCode || '');
	contactCity = contactCity.trim();

	const getName = () => {
		const name =
			props.contact.firstName && props.contact.lastName
				? `${props.contact.firstName} ${props.contact.lastName}`
				: props.contact.firstName || props.contact.lastName;
		return name;
	};

	const isFacilityContact =
		props.contact.entityType === EntityContact_EntityType.FogFacility || props.contact.entityType == deviceOwner;

	const getIcon = () => {
		if (isFacilityContact)
			return (
				<TooltipHover
					id={`facilityIcon${_.uniqueId()}`}
					title={localizationService.getLocalizedString('facility.contactCardHeader')}
					attachToDiv
					className="facility-contact-icon"
					customSvg={<FacilitySvg fillColor={'#3C3E69'} size={20} />}
				/>
			);
		if (props.contact.entityType === EntityContact_EntityType.Hauler)
			return <TooltipHover id="haulerContact" title={'Hauler Contact'} attachToDiv icon={faTruck} />;
		if (props.contact.entityType === EntityContact_EntityType.FogDevice)
			return (
				<TooltipHover
					id={`deviceIcon${_.uniqueId()}`}
					className="device-contact-icon"
					title={localizationService.getLocalizedString('extractor.deviceContact')}
					attachToDiv
					customSvg={<GreaseTrap fillColor={'#3C3E69'} />}
				/>
			);
	};

	return (
		<>
			<div className="d-flex mb-2">
				<div className={`contact-card-icon mt-0 align-self-start ${isFacilityContact ? 'facility-icon' : ''} `}>
					{getIcon()}
				</div>
				<div className="d-flex flex-column ml-2 align-self-center">
					{props.contact.entityType == deviceOwner ? (
						<>
							{props.contact.facilityName && (
								<>
									<strong>{props.contact.facilityName}</strong>
									<strong>{getName()}</strong>
								</>
							)}
						</>
					) : (
						<>
							{props.contact.companyName ? (
								<strong>{props.contact.companyName}</strong>
							) : (
								<strong>{getName()}</strong>
							)}
							{props.contact.contactTypeCode && <div>{props.contact.contactTypeCode}</div>}
						</>
					)}
				</div>
			</div>
			{props.contact.companyName && <p>{getName()}</p>}
			<p>
				{props.contact.addressLine1 && (
					<>
						{props.contact.addressLine1}
						<br />
					</>
				)}
				{props.contact.addressLine2 && (
					<>
						{props.contact.addressLine2}
						<br />
					</>
				)}
				{contactCity && (
					<>
						{contactCity}
						<br />
					</>
				)}
			</p>

			{(!_.isEmpty(phones) || !_.isEmpty(emails)) && (
				<p>
					{phones && phones.business && (
						<>
							{phones.business.label}&#58;{' '}
							{props.showSpan ? (
								<span>{phones.business.number}</span>
							) : (
								<a href={'tel:' + phones.business.number}>{phones.business.number}</a>
							)}
							{phones.business.extension && (
								<>
									&nbsp;
									{localizationService.getLocalizedString('screen.labels.phoneExtensionPrefix')}
									&#58;
									{phones.business.extension}
								</>
							)}
							<br />
						</>
					)}
					{phones && phones.cell && (
						<>
							{phones.cell.label}&#58;{' '}
							{props.showSpan ? (
								<span>{phones.cell.number}</span>
							) : (
								<a href={'tel:' + phones.cell.number}>{phones.cell.number}</a>
							)}
							<br />
						</>
					)}
					{emails && emails.length > 0 && (
						<>
							<span>{localizationService.getLocalizedString('authentication.email')}: </span>
							<a
								href="#/"
								onClick={e => {
									e.preventDefault();
									sendEmailPopUp(
										emails[0].email as string,
										getName() || props.contact.companyName || (emails[0].email as string)
									);
								}}>
								{emails[0].email}
							</a>
						</>
					)}
				</p>
			)}
			{props.contact.entityType == deviceOwner && (
				<div className="mt-2">
					<DomainSvg /> &nbsp;
					{localizationService.getLocalizedString('extractor.deviceOwner')}
				</div>
			)}
			{props.contact.isPrimary && !props.isDeviceDetailsPage && (
				<div className="mt-2 mb-2">
					<FontAwesomeIcon icon={faStar} color="#666666" /> &nbsp;
					{localizationService.getLocalizedString('screen.labels.primaryContact')}
				</div>
			)}

			{showEmailModal && (
				<FacilitySendEmail
					show={showEmailModal}
					toggle={() => {
						setShowEmailModal(!showEmailModal);
					}}
					letterTemplateCategory={
						urlService.getFogDeviceId() <= 0
							? LetterTemplateCategory.FacilityEmail
							: LetterTemplateCategory.DeviceEmail
					}
					selectedEmail={selectedEmail}
					isDeviceDetail={urlService.getFogDeviceId() > 0}
				/>
			)}
		</>
	);
};
