import React from 'react';
import { LookupType, SubLookupType } from '@rcp/types';
import { localizationService, Resource, urlService } from 'src/services';
import {
	CustomizedSettingUrlResolver,
	LookupRow,
	LookupsWidget,
	ResourceAction,
	SettingAction,
	SettingActionHooks,
	TagSettingUrlResolver
} from './';
import { DefaultLookRowDataConverter, LookupRowTagValueConverter } from './data-converters';
import _ from 'lodash';

interface LookupSettingProps {
	title: string;
	description?: string;
	settingId: string;
	settingLabel: string;
	editDescription?: boolean;
	lookupType: LookupType;
	hideActiveStatus?: boolean;
	embeddedMode?: boolean;
}

export const LookupSetting: React.FC<LookupSettingProps> = props => {
	let lookupUrlResolver = new CustomizedSettingUrlResolver(
		`${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups`,
		`lookupType=${props.lookupType}&includeInactive=true&includeIsInUse=true`
	);
	let dataConverter = new DefaultLookRowDataConverter(props.lookupType);
	return (
		<ListSetting
			title={props.title}
			description={props.description}
			settingId={props.settingId}
			settingLabel={props.settingLabel}
			editDescription={props.editDescription}
			settingAction={new ResourceAction(lookupUrlResolver, dataConverter)}
			hideActiveStatus={props.hideActiveStatus || false}
			embeddedMode={props.embeddedMode}
		/>
	);
};

interface TagSettingProps {
	title: string;
	description?: string;
	settingId: string;
	settingLabel: string;
	tagType: SubLookupType;
	embeddedMode?: boolean;
}

export const TagSetting: React.FC<TagSettingProps> = props => {
	let dataConverter = new LookupRowTagValueConverter(props.tagType);
	let urlResolver = new TagSettingUrlResolver(LookupType.Tag);
	let settingAction = new SettingAction(LookupType.Tag, urlResolver, dataConverter, false);

	return (
		<ListSetting
			title={props.title}
			description={props.description}
			settingId={props.settingId}
			settingLabel={props.settingLabel}
			editDescription={true}
			settingAction={settingAction}
			embeddedMode={props.embeddedMode}
		/>
	);
};

interface TagsSettingProps {
	embeddedMode?: boolean;
}

export const TagsSetting: React.FC<TagsSettingProps> = props => {
	let dataConverter = new LookupRowTagValueConverter();
	let urlResolver = new TagSettingUrlResolver(LookupType.Tag);
	let settingAction = new SettingAction(LookupType.Tag, urlResolver, dataConverter, false);

	let lookupWidget = (
		<LookupsWidget
			id="tag"
			key="tag"
			name="tag"
			className="col-sm-12"
			label={localizationService.getLocalizedString('authoritySetting.tag')}
			editDescription={true}
			showDescriptionColum={true}
			settingAction={settingAction}
			embeddedMode={props.embeddedMode}
		/>
	);

	if (props.embeddedMode) {
		return lookupWidget;
	}

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{localizationService.getLocalizedString('authoritySetting.tagsSection')}</strong>
				<p>{localizationService.getLocalizedString('authoritySetting.tagsSectionDescription1')}</p>
				<p>{localizationService.getLocalizedString('authoritySetting.tagsSectionDescription2')}</p>
				<p>{localizationService.getLocalizedString('authoritySetting.tagsSectionDescription3')}</p>
			</div>
			<div className="col-lg-8">
				<section>{lookupWidget}</section>
			</div>
		</div>
	);
};

interface SettingProps {
	title?: string;
	description?: string;
	settingId: string;
	settingLabel: string;
	hideActiveStatus?: boolean;
	hideAddButton?: boolean;
	editDescription?: boolean;
	settingAction: SettingActionHooks;
	embeddedMode?: boolean;
	deleteMessage?: (lookup: LookupRow) => string;
	deleteSucceedMessage?: (lookup: LookupRow) => string;
	updateSucceedMessage?: (lookup: LookupRow) => string;
	overridenEditModalTitle?: string;
	overridenEditModalLabel?: string;
}

export const ListSetting: React.FC<SettingProps> = props => {
	if (props.embeddedMode === true) {
		return (
			<LookupsWidget
				id={props.settingId}
				name={props.settingId}
				className="w-100"
				label={props.settingLabel}
				editDescription={props.editDescription === true}
				showDescriptionColum={props.editDescription === true}
				hideAddButton={props.hideAddButton === true}
				hideActiveStatus={props.hideActiveStatus === true}
				settingAction={props.settingAction}
				embeddedMode={props.embeddedMode}
				deleteMessage={props.deleteMessage}
				deleteSucceedMessage={props.deleteSucceedMessage}
				updateSucceedMessage={props.updateSucceedMessage}
				overridenEditModalTitle={props.overridenEditModalTitle}
				overridenEditModalLabel={props.overridenEditModalLabel}
			/>
		);
	}
	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				{!_.isEmpty(props.title) && <strong>{props.title}</strong>}
				{!_.isEmpty(props.description) && <p>{props.description}</p>}
			</div>
			<div className="col-lg-8">
				<section>
					<LookupsWidget
						id={props.settingId}
						name={props.settingId}
						className="col-sm-12"
						label={props.settingLabel}
						editDescription={props.editDescription === true}
						showDescriptionColum={props.editDescription === true}
						hideAddButton={props.hideAddButton === true}
						hideActiveStatus={props.hideActiveStatus === true}
						settingAction={props.settingAction}
						deleteMessage={props.deleteMessage}
						deleteSucceedMessage={props.deleteSucceedMessage}
						updateSucceedMessage={props.updateSucceedMessage}
						overridenEditModalTitle={props.overridenEditModalTitle}
						overridenEditModalLabel={props.overridenEditModalLabel}
					/>
				</section>
			</div>
		</div>
	);
};
