import { LookupRow } from '../lookup-types';
import { LookupType, ViolationType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class ViolationTypeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: ViolationType): LookupRow => {
		return {
			lookupId: apiData.violationTypeId,
			code: apiData.name,
			codeDescription: apiData.description,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let ViolationType = {
			violationTypeId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			description: rowToUpdate.codeDescription,
			isActive: rowToUpdate.isActive
		} as ViolationType;
		return ViolationType;
	}

	toLookupRowsFromAPI(apiData: ViolationType[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): ViolationType {
		let violationType = {
			name: newValue.code,
			description: newValue.codeDescription,
			isActive: newValue.isActive
		} as ViolationType;
		return violationType;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
