import React from 'react';
import { useDispatch } from 'react-redux';
import {
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	LineChart,
	Line,
	Brush,
	Label
} from 'recharts';
import { ApiError, LineChartData, DashboardWidget, Dictionary } from '@rcp/types';
import { apiService, DateUtilService, localizationService, Logger, urlService } from 'src/services';
import { alertService } from 'src/redux';
import _ from 'lodash';
import { chartService, ColorScheme, DateFilterKind } from './chart-service';

import './chart.scss';
import { nameof } from 'ts-simple-nameof';
import { GotoGridButton } from './goto-grid-button';
import { DefaultDateFilterSelect } from './default-date-filter-select';
import { CurveType } from 'recharts/types/shape/Curve';
import { AxisInterval } from 'recharts/types/util/types';
import { RenderXTick } from './customized-chart-renders';
import uuid from 'uuid';

interface OwnProps {
	dashboardWidget: DashboardWidget;
	onchange?: (widget: DashboardWidget) => void;
}
type Props = OwnProps;

interface ChartSetting {
	lookIn?: string;
	groupBy?: string;
	method: string;
	linePeriod: string;
	xFieldName?: string;
	filterId: number;
	filterName?: string;
	dateFilter: string;
	dateFilterKind: DateFilterKind;
	withDateFilter: boolean;
	withButton: boolean;
	orderBy?: string;
	//look and feel
	colorScheme: ColorScheme;
	colors: string[];
	tooltip: boolean;
	legend: boolean;
	curveType: CurveType;
	withBrush: boolean;
	xAxisInterval: AxisInterval;
	height: number; //Width are responsive
}
const initialChartSetting: ChartSetting = {
	method: 'Count',
	filterId: 0,
	linePeriod: 'Month',
	dateFilterKind: DateFilterKind.Year,
	dateFilter: `${DateUtilService.getAuthorityTimezoneNowAsMoment().year()}`,
	withDateFilter: true,
	withButton: true,
	//look and feel
	colorScheme: ColorScheme.Green,
	colors: [],
	tooltip: true,
	legend: true,
	curveType: 'linear', //basis, linear, natural, monotone, step
	xAxisInterval: 0,
	withBrush: false,
	height: 320
};

interface ChartResponseData {
	isLoading: boolean;
	groupBy?: string;
	linePeriod: string;
	xFieldName: string;
	aggregationMethod?: string;
	aggregationGroups: string[];
	aggregationGroupTotals: number[];
	total: number;
	numberIgnored: number;
	data: any[];
}

const default12MonthData = [
	{ dueDate: '2020-01-01' },
	{ dueDate: '2020-02-01' },
	{ dueDate: '2020-03-01' },
	{ dueDate: '2020-04-01' },
	{ dueDate: '2020-05-01' },
	{ dueDate: '2020-06-01' },
	{ dueDate: '2020-07-01' },
	{ dueDate: '2020-08-01' },
	{ dueDate: '2020-09-01' },
	{ dueDate: '2020-10-01' },
	{ dueDate: '2020-11-01' },
	{ dueDate: '2020-12-01' }
];
const initialChartResponseData: ChartResponseData = {
	isLoading: true,
	linePeriod: 'Month',
	xFieldName: 'dueDate',
	aggregationGroups: [],
	aggregationGroupTotals: [],
	total: 0,
	numberIgnored: 0,
	data: default12MonthData
};

export const LineChartWidget: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [setting, setSetting] = React.useState<ChartSetting>(initialChartSetting);
	const [responseData, setResponseData] = React.useState<ChartResponseData>(initialChartResponseData);

	const handleResult = (result: LineChartData) => {
		let aggregationGroups = result.aggregationGroups;
		let length = _.isEmpty(aggregationGroups) ? 0 : aggregationGroups.length;
		if (length < 1 && result.totalSamples && result.totalSamples <= 0) {
			setResponseData(initialChartResponseData);
			return;
		}
		let data: any[] = [];
		_.forEach(result.aggregationResult, (values: number[], key: string) => {
			let item: Dictionary<any> = {};
			item[result.xFieldName] = key;
			for (let index = 0; index < result.aggregationGroups.length; index++) {
				let label = result.aggregationGroups[index];
				item[label] = values[index];
			}
			data.push(item);
		});

		if (data.length > 20) {
			setSetting({ ...setting, withBrush: true, xAxisInterval: 'preserveEnd' });
		}

		var newData: ChartResponseData = {
			...responseData,
			isLoading: false,
			groupBy: result.groupBy,
			linePeriod: result.linePeriod,
			xFieldName: result.xFieldName,
			aggregationMethod: result.aggregationMethod,
			aggregationGroups: result.aggregationGroups,
			aggregationGroupTotals: result.aggregationGroupTotals,
			total: result.totalSamples,
			numberIgnored: result.numberOfIgnoredSamples,
			data: data
		};
		Logger.debug('line chart data:', newData);
		setResponseData(newData);
	};

	const loadChartData = (setting: ChartSetting) => {
		let parameters: Dictionary<string> = {};
		parameters[nameof<ChartSetting>(s => s.groupBy)] = _.get(setting, 'filterGroup') || setting.groupBy || '';
		parameters[nameof<ChartSetting>(s => s.method)] = setting.method as string;
		parameters[nameof<ChartSetting>(s => s.linePeriod)] = setting.linePeriod as string;
		parameters[nameof<ChartSetting>(s => s.xFieldName)] = setting.xFieldName as string;
		if (setting.filterId) {
			parameters[nameof<ChartSetting>(s => s.filterId)] = _.toString(setting.filterId);
		}
		if (setting.orderBy) {
			parameters[nameof<ChartSetting>(s => s.orderBy)] = setting.orderBy as string;
		}
		let dateFilterKey = chartService.getDateFilterField(setting.lookIn);
		let dateFilterValue = chartService.getDateFilterQueryParameterValue(setting.dateFilter);
		if (dateFilterKey && dateFilterValue) {
			parameters[dateFilterKey] = dateFilterValue;
		}
		let queryString = urlService.toQueryString(parameters);
		let url = `${urlService.getApiBaseUrlWithProgram()}/Charts/Line/${setting.lookIn}?${queryString}`;
		setResponseData({ ...responseData, isLoading: true });
		apiService
			.getResource<LineChartData>(url)
			.then(handleResult)
			.catch((e: ApiError) => {
				alertService.addError(e.message);
			});
	};

	const changeDateFilter = (e: any) => {
		const { value } = e.target;
		if (props.onchange) {
			props.onchange({ dateFilterValue: value });
		}
		if (value !== setting.dateFilter) {
			var newRequestSetting = { ...setting, dateFilter: value };
			setSetting(newRequestSetting);
		}
	};

	React.useEffect(() => {
		if (props.dashboardWidget) {
			const { filterId, filterName } = chartService.getFirstFilter(props.dashboardWidget.dashboardWidgetFilters);
			const properties = props.dashboardWidget.properties ? JSON.parse(props.dashboardWidget.properties) : {};
			const dateFilterValue = props.dashboardWidget.dateFilterValue || initialChartSetting.dateFilter;
			const colors = _.isEmpty(properties.colors)
				? chartService.getColorScheme(setting.colorScheme as ColorScheme)
				: properties.colors;
			const orderBy = _.isEmpty(properties.orderBy) ? undefined : properties.orderBy;

			let newSetting: ChartSetting = {
				...setting,
				...properties,
				colors: colors,
				orderBy: orderBy,
				dateFilter: dateFilterValue,
				filterId: _.isNumber(filterId) && filterId > 0 ? filterId : undefined,
				filterName: filterName
			};
			setSetting(newSetting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.dashboardWidget]);

	React.useEffect(() => {
		if (setting.lookIn) {
			loadChartData(setting);
		}
	}, [setting.lookIn, setting.dateFilter]);

	const CustomizedLegend = (legendProps: any) => {
		const { payload } = legendProps;

		return (
			<ul className="recharts-default-legend" style={{ padding: 0, margin: 0, textAlign: 'center' }}>
				{payload.map((entry: any, index: number) => {
					const groupTotal = responseData.aggregationGroupTotals[index];
					const percentage = chartService.toPercentage(groupTotal, responseData.total);
					const group = responseData.aggregationGroups[index];
					const title = percentage < 100 ? `${groupTotal} (${percentage}%)` : `${groupTotal}`;
					const color = setting.colors[index % setting.colors.length];
					return (
						<li
							key={`legend-${uuid()}`}
							className="recharts-legend-item"
							style={{ display: 'inline-block', marginRight: 10 }}>
							<span className="recharts-legend-item-title">{title}</span>
							<svg
								className="recharts-surface"
								width="14"
								height="14"
								viewBox="0 0 32 32"
								version="1.1"
								style={{
									display: 'inline-block',
									verticalAlign: 'middle',
									marginRight: 4,
									color: color
								}}>
								<path
									className="recharts-legend-icon"
									strokeWidth="3"
									fill={'#FFF'}
									stroke={color}
									d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"></path>
							</svg>
							<span className="recharts-legend-item-text">{group}</span>
						</li>
					);
				})}
			</ul>
		);
	};

	if (_.isUndefined(responseData.data)) {
		return <div></div>;
	}

	return (
		<div className="chart-spaces">
			{_.isEmpty(responseData.data) ? (
				_.isUndefined(responseData.data) ? (
					<></>
				) : (
					<div className="populated-chart">{localizationService.getLocalizedString('dashboard.noData')}</div>
				)
			) : (
				<ResponsiveContainer width="100%" height={setting.height + (setting.legend ? 40 : 0)}>
					<LineChart
						width={400}
						height={setting.height}
						data={responseData.data}
						margin={{
							top: 20,
							right: 10,
							left: 0,
							bottom: 5
						}}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis
							scale="band"
							dataKey={responseData.xFieldName}
							tick={<RenderXTick setting={setting} />}
							height={60}
							interval={0}
						/>
						<YAxis>
							<Label
								value={chartService.getYAxisLabel(setting.lookIn)}
								position="insideLeft"
								angle={-90}
								className="line-y-axis-label"
								style={{ textAnchor: 'middle' }}
							/>
						</YAxis>
						{setting.tooltip && <Tooltip wrapperStyle={{ zIndex: chartService.tooltipZIndex }} />}
						{setting.legend && <Legend verticalAlign="bottom" content={<CustomizedLegend />} />}
						{responseData.aggregationGroups.map((line, index) => {
							return (
								<Line
									name={line}
									type={setting.curveType}
									key={index}
									dataKey={line}
									stroke={setting.colors[index % setting.colors.length]}
									dot={{ strokeWidth: 2, r: 4 }}
								/>
							);
						})}
						{setting.withBrush && <Brush />}
					</LineChart>
				</ResponsiveContainer>
			)}
			<div className="row mt-3">
				{setting.withDateFilter && (
					<div className="col-4">
						<DefaultDateFilterSelect
							dashboardWidgetId={props.dashboardWidget.dashboardWidgetId}
							dateFilterKind={setting.dateFilterKind}
							value={setting.dateFilter}
							onChange={changeDateFilter}></DefaultDateFilterSelect>
					</div>
				)}
				{setting.withButton && (
					<div className="col">
						<GotoGridButton lookIn={setting.lookIn} />
					</div>
				)}
			</div>
		</div>
	);
};
