import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from 'reactstrap';

import * as actionCreators from '../../redux/filter';
import './filter.scss';
import { localizationService } from 'src/services';
import { DropDownOption, Filter, UserRecentFilter } from '@rcp/types';
import _ from 'lodash';
import { useReduxDispatch } from 'src/redux';
import { ComboBoxDropdown } from '../../components/widgets';
import { PopupCloseSvg } from '../../components/svg';

interface OwnProps {
	anchor: HTMLElement;
	chosenFilterCallBack: (filterId: number) => void;
	createNewFilterCallBack: () => void;
	closeModal: () => void;
	domain?: string;
}

type filterActionMenuProp = OwnProps;

const FilterActionMenuComp: React.SFC<filterActionMenuProp> = props => {
	const dispatch = useReduxDispatch();
	const [recentFilters, setRecentFilters] = useState([] as UserRecentFilter[]);
	const [filters, setFilters] = useState([] as Filter[]);

	useEffect(() => {
		dispatch(actionCreators.GetRecentFilters(props.domain)).then(userRecentFilters => {
			if (_.isArray(userRecentFilters)) {
				setRecentFilters(userRecentFilters);
			}
		});
		dispatch(actionCreators.GetFilters(props.domain)).then(filters => {
			if (_.isArray(filters)) setFilters(filters);
		});
	}, [dispatch]);

	const onNewFilterBtnClicked = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		props.createNewFilterCallBack();
	};

	const onFilterChosen = (e: any, filterId: number | undefined) => {
		let chosenFilterId = filterId || _.toInteger(e.target.value);
		if (chosenFilterId > 0) {
			props.chosenFilterCallBack(chosenFilterId);
		}
	};

	let hasAnchor = props.anchor instanceof HTMLElement;

	return !hasAnchor ? null : (
		<Popup anchor={props.anchor} show={true} animate={false} className="shadow filter-action-menu-modal-body ">
			<div id="filter-action-menu-modal-body">
				<button className="close-dropdown flex-row-reverse" onClick={props.closeModal}>
					<PopupCloseSvg />
				</button>
				{recentFilters.length > 0 && (
					<>
						<label>{localizationService.getLocalizedString('screen.labels.recentFilters')}</label>
						<div className="mb-2">
							{recentFilters.map((i: UserRecentFilter) => {
								return (
									<button
										key={`recent-filter-${i.filterId}`}
										type="button"
										className="btn recent-filter"
										onClick={e => onFilterChosen(e, i.filterId)}>
										{i.filterName}
									</button>
								);
							})}
						</div>
					</>
				)}

				<ComboBoxDropdown
					id="filterSelection"
					name="filterSelection"
					label={localizationService.getLocalizedString('screen.labels.savedFilters')}
					className="form-group"
					onChange={e => onFilterChosen(e, undefined)}
					isCustomSorting={true}
					options={filters
						.map(i => ({ label: i.filterName, value: i.filterId }))
						.sort((filter1: DropDownOption, filter2: DropDownOption) => {
							return filter1.label.toLowerCase().localeCompare(filter2.label, 'en', { numeric: true });
						})}
				/>

				<div className="popup-modal-footer ml-auto">
					<Button
						color="btn ai-action"
						id="newFilter"
						className="popup-modal-btn"
						onClick={onNewFilterBtnClicked}>
						{localizationService.getLocalizedString('screen.filterModal.newFilter')}
					</Button>
					<Button color="btn ai-white" className="popup-modal-btn" onClick={props.closeModal}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</div>
		</Popup>
	);
};

export const FilterActionMenu = connect<{}, {}, OwnProps>(null)(FilterActionMenuComp);
