import { dispatchAccessor } from 'src/services';
import { LinkoRouteActionType } from '../../redux/linko-route';

export const triggerMainSidebarHamburgerClick = () => {
	dispatchAccessor.dispatch({ type: LinkoRouteActionType.restoreInitialState });

	let className = 'active';
	let el = document.querySelectorAll('.menu-toggle');

	for (let i = 0; i < el.length; i++) {
		if (!el[i].classList) {
			el[i].classList.toggle(className);
		} else {
			var classes = el[i].className.split(' ');
			var existingIndex = -1;
			for (var j = classes.length; j--; ) {
				if (String.equalCaseInsensitive(classes[j], className)) existingIndex = j;
			}

			if (existingIndex >= 0) classes.splice(existingIndex, 1);
			else classes.push(className);

			el[i].className = classes.join(' ');
		}
	}
};

export const closeMainSidebar = () => {
	dispatchAccessor.dispatch({ type: LinkoRouteActionType.restoreInitialState });

	let className = 'active';
	let el = document.querySelectorAll('.menu-toggle');

	for (let i = 0; i < el.length; i++) {
		if (!el[i].classList) {
			el[i].classList.toggle(className);
		} else {
			var classes = el[i].className.split(' ');
			const index = classes.indexOf(className);
			index > -1 && classes.splice(index, 1);
			el[i].className = classes.join(' ');
		}
	}
};
