import { faCheck, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { orderBy, process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridDetailRow } from '@progress/kendo-react-grid';
import {
	ColumnDefinition,
	IppIndustrySample,
	IppPackageElement,
	IppReportPackage,
	IppSampleResult,
	Dictionary,
	DraftFilter,
	Filter,
	FilterApplyTo,
	FilterObjectName,
	LocalStorageName
} from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { PopoverModal, SearchInput, SingleCheckbox } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { ReportPackageStatus, SampleCompliance, SampleStatus } from 'src/constants';
import { FilterEditModal } from 'src/features/filter';
import { FilterQuickViewer } from 'src/features/filter/filter-quick-viewer';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { alertService } from 'src/redux';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { Utils } from 'src/services/utils';
import './sample-and-results.scss';
import { SampleUtils } from './sample-utils';
import uuid from 'uuid';

interface IppPackageItemSamplesProps {
	reportStatus?: string;
	className?: string;
	sampleTypes?: IppPackageElement[];
	isRequired?: boolean;
	onChangeSample?: (samples?: any, id?: number) => void;
	isImportSample?: boolean;
	customDetailComponent?: any;
	loadSamples?: (reportPackageId: number, queryParameters?: Dictionary<string>) => Promise<void>;
	packageDetails?: IppReportPackage;
	showFilter?: boolean;
	showSearch?: boolean;
	history?: History;
	addNewSampleButton?: boolean;
	updateReportPackageOnCopyAndEdit?: (sample: IppIndustrySample, callback: () => void) => Promise<any>;
	saveReportPackage?: () => any;
}

const { Draft } = SampleStatus;
class DetailComponent extends GridDetailRow {
	state = {
		sort: [{ field: 'parameterName', dir: 'asc' }] as SortDescriptor[]
	};

	private columnsKeys = [
		'parameterName',
		'enteredValue',
		'massLoadingValue',
		'analysisMethod',
		'enteredMethodDetectionLimit',
		'analysisDateTimeLocal'
	];

	getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = this.getDefaultShowingColumnDefinitions(this.columnsKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.samples.step2.results.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	showResult = (rowData: any) => {
		let dataItem = rowData.dataItem as IppSampleResult;
		const { enteredValue, qualifier, unitName } = dataItem;
		let { color, message, type } = SampleUtils.displayConcentrationCompliance(dataItem);
		let icon = SampleUtils.getComplianceIcon(type);
		return (
			<td className="text-right">
				<div className="d-flex align-items-center justify-content-end">
					{`${qualifier || ''} ${enteredValue || ''} ${
						!(qualifier || '').includes('N') ? unitName || '' : ''
					} `}

					{(enteredValue ||
						String.equalCaseInsensitive(qualifier, 'ND') ||
						String.equalCaseInsensitive(qualifier, 'NF')) && (
						<TooltipHover
							id={`enteredValue-tooltip-${uuid()}`}
							icon={icon}
							className="ml-1"
							iconClassName={`circle-icon ${color}`}
							title={message}
							position="auto"
						/>
					)}
				</div>
			</td>
		);
	};

	showMassLoadingResult = (rowData: any) => {
		let isMassLoadingResultToUseLessThanSign = this.props.dataItem.isMassLoadingResultToUseLessThanSign;
		let dataItem = rowData.dataItem as IppSampleResult;
		const { massLoadingValue, massLoadingQualifier, massLoadingUnitName, concentrationSampleResultId } = dataItem;
		let { color, message, type } = SampleUtils.displayMassLoadingCompliance(
			dataItem,
			undefined,
			isMassLoadingResultToUseLessThanSign
		);
		let icon = SampleUtils.getComplianceIcon(type);
		return (
			<td className="text-right">
				<div className="d-flex align-items-center justify-content-end">
					{`${
						massLoadingQualifier === '<'
							? isMassLoadingResultToUseLessThanSign
								? massLoadingQualifier
								: ''
							: massLoadingQualifier || ''
					} ${massLoadingValue || ''} ${
						!(massLoadingQualifier || '').includes('N') ? massLoadingUnitName || '' : ''
					} `}
					{massLoadingValue && (
						<TooltipHover
							id={`massLoading-tooltip-${uuid()}`}
							icon={icon}
							className="ml-1"
							iconClassName={`circle-icon ${color}`}
							title={message}
							position="auto"
						/>
					)}
				</div>
			</td>
		);
	};

	generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (String.equalCaseInsensitive(i.field, 'enteredValue')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={(item: any) => this.showResult(item)}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'massLoadingValue')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={(item: any) => this.showMassLoadingResult(item)}
						/>
					);
				}
				if (i.field.includes('DateTimeLocal')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={Utils.formatDate}
						/>
					);
				}

				return (
					<GridColumn
						key={`column_key${i.field}`}
						field={i.field}
						title={i.title}
						cell={this.localizeCellValue}
					/>
				);
			});
	};

	formatDate = (item: any, field: string) => {
		let dataItem: any = item.dataItem;
		return <td>{DateUtilService.toDisplayDate(dataItem[field])}</td>;
	};

	localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	render() {
		let columnsDefinitions = this.getColumnDefinitions();
		let columns = this.generateColumns(columnsDefinitions);
		const data = this.props.dataItem.ippSampleResults;
		if (data) {
			return (
				<Grid
					data={orderBy(data.slice(), this.state.sort)}
					scrollable="none"
					sortable
					sort={this.state.sort}
					onSortChange={(e: any) => this.setState({ sort: e.sort })}
					className="table sample-detail-grid">
					{columns}
				</Grid>
			);
		}
		return (
			<div style={{ height: '50px', width: '100%' }}>
				<div style={{ position: 'absolute', width: '100%' }}>
					<div className="k-loading-image" />
				</div>
			</div>
		);
	}
}

interface CompState {
	queryParameters: Dictionary<string>;
	showFilterMenuPopOver: boolean;
	showFilterEditorModal: boolean;
	anchorElement: any;
	draftFilterId?: number;
}

let initialCompState: CompState = {
	queryParameters: {} as Dictionary<string>,
	showFilterMenuPopOver: false,
	showFilterEditorModal: false,
	draftFilterId: undefined,
	anchorElement: null
};

const defaultSort: Array<SortDescriptor> = [
	{ field: 'monitoringPointName', dir: 'asc' },
	{ field: 'collectionMethodName', dir: 'asc' },
	{ field: 'startDateTimeLocal', dir: 'asc' }
];

const IppPackageItemSamples: FC<IppPackageItemSamplesProps> = (props: IppPackageItemSamplesProps) => {
	let [state, setState] = useState({ samples: [] as IppIndustrySample[] });
	let [sort, setSort] = useState(defaultSort as SortDescriptor[]);
	const [pagination, setPagination] = useState({ skip: 0, take: 15 });
	const [filterState, setFilterState] = useState<CompState>(initialCompState);
	const [isSampleAssociated, setIsSampleAssociated] = useState<boolean>(false);
	const [activeSample, setActiveSample] = useState({} as IppIndustrySample);

	const expandChange = (event: any) => {
		event.dataItem.expanded = event.value;
		setState({ ...state });

		if (!event.value || event.dataItem.details) {
			return;
		}
	};

	useEffect(() => {
		props.sampleTypes && setState({ samples: props.sampleTypes });
	}, [props.sampleTypes]);

	useEffect(() => {
		return () => {
			setState({ samples: [] as IppIndustrySample[] });
		};
	}, []);

	let draftColumnsKeys = [
		'isAssociatedWithReportPackage',
		'compliantStatus',
		'monitoringPointName',
		'name',
		'collectionMethodName',
		'startDateTimeLocal',
		'endDateTimeLocal',
		'labSampleIdentifier',
		'lastModificationDateTimeLocal',
		'edit'
	];

	let columnsKeys = [
		'compliantStatus',
		'monitoringPointName',
		'name',
		'collectionMethodName',
		'startDateTimeLocal',
		'endDateTimeLocal',
		'labSampleIdentifier',
		'lastModificationDateTimeLocal'
	];

	let importSampleColumnsKeys = [
		'importSampleStatus',
		'compliantStatus',
		'monitoringPointName',
		'ctsEventTypeName',
		'collectionMethodName',
		'startDateTimeLocal',
		'endDateTimeLocal',
		'labSampleIdentifier',
		'flowValue'
	];

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(
			String.equalCaseInsensitive(props.reportStatus, ReportPackageStatus.Draft)
				? draftColumnsKeys
				: props.isImportSample
				? importSampleColumnsKeys
				: columnsKeys
		);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.samples.columns.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	const selectSample = (row: any) => {
		let dataItem = row.dataItem as IppIndustrySample;
		return (
			<td>
				<SingleCheckbox
					id={`isAssociatedWithReportPackage${dataItem.sampleId}`}
					name="isAssociatedWithReportPackage"
					className="mx-auto"
					checked={dataItem.isAssociatedWithReportPackage}
					onChange={(event: any) => props.onChangeSample && props.onChangeSample(event, dataItem.sampleId)}
				/>
			</td>
		);
	};

	const getOverallCompliance = (item: any) => {
		let dataItem = item.dataItem as IppIndustrySample;
		let inCompliantData =
			(dataItem.ippSampleResults &&
				dataItem.ippSampleResults.filter(result => {
					let resultCompliance = SampleUtils.displayConcentrationCompliance(result);
					let massLoadingCompliance = SampleUtils.displayMassLoadingCompliance(result);
					return (
						String.equalCaseInsensitive(resultCompliance.type, SampleCompliance.Bad) ||
						String.equalCaseInsensitive(massLoadingCompliance.type, SampleCompliance.Bad)
					);
				})) ||
			[];

		return (
			<td className="align-center">
				<div className="d-flex justify-content-center">
					{inCompliantData.length ? (
						<TooltipHover
							id={`incompliance-tooltip-${uuid()}`}
							icon={faTimesCircle}
							className="ml-1"
							iconClassName={`red-icon font-awesome-icon`}
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.package.samplesInComplianceMessage'
							)}
							iconFontClass="font-size-16px-regular"
						/>
					) : (
						<TooltipHover
							id={`compliance-tooltip-${uuid()}`}
							icon={faCheckCircle}
							className="ml-1"
							iconClassName={`green-icon font-awesome-icon`}
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.package.samplesComplianceMessage'
							)}
							iconFontClass="font-size-16px-regular"
						/>
					)}
				</div>
			</td>
		);
	};

	const importSampleStatus = (props: any) => {
		let sample: IppIndustrySample = props.dataItem;
		return (
			<td>
				{sample && sample.sampleId === 0 ? (
					<FontAwesomeIcon className="font-awesome-icon text-primary" icon={faCheck} />
				) : (
					<FontAwesomeIcon className="font-awesome-icon text-warning" icon={faCheck} />
				)}
			</td>
		);
	};

	const showFlowUnit = (props: any) => {
		const { flowUnitName, flowValue } = props.dataItem;
		return <td>{`${flowValue > 0 ? flowValue : ''}  ${flowUnitName || ''}`}</td>;
	};

	const getEditButton = (item: any) => {
		const dataItem = item.dataItem as IppIndustrySample;
		let associatedReportPackageIds: any[] = [];
		if (dataItem.associatedReportPackages) {
			associatedReportPackageIds = dataItem.associatedReportPackages.map(
				associatedReportPackage => associatedReportPackage.reportPackageId
			);
		}
		let isEdit =
			associatedReportPackageIds.length === 0 ||
			(associatedReportPackageIds.length === 1 &&
				props.packageDetails &&
				associatedReportPackageIds[0] === props.packageDetails.reportPackageId);
		return (
			<td>
				{isEdit ? (
					<a
						href="#/"
						className="text-primary edit-sample"
						onClick={e => {
							e.preventDefault();
							setIsSampleAssociated(false);
							onEditSample(dataItem);
						}}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				) : (
					<a
						href="#/"
						className="text-primary edit-sample"
						onClick={e => {
							e.preventDefault();
							setIsSampleAssociated(true);
							setActiveSample(dataItem);
						}}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				)}
			</td>
		);
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (String.equalCaseInsensitive(i.field, 'isAssociatedWithReportPackage')) {
					return <GridColumn key={`column_key${i.field}`} field={i.field} title=" " cell={selectSample} />;
				}
				if (String.equalCaseInsensitive(i.field, 'edit')) {
					return (
						<GridColumn
							width={100}
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							cell={getEditButton}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'compliantStatus')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							cell={getOverallCompliance}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'importSampleStatus')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title=" "
							cell={importSampleStatus}
							headerClassName="importStatus"
						/>
					);
				}

				if (i.field.includes('DateTimeLocal')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={Utils.formatDate}
						/>
					);
				}

				if (String.equalCaseInsensitive(i.field, 'flowValue')) {
					return (
						<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={showFlowUnit} />
					);
				}

				return (
					<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={localizeCellValue} />
				);
			});
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	const pageChange = (event: any) => {
		setPagination({
			skip: event.page.skip,
			take: event.page.take
		});
	};

	const getReportPackageElementTypeId = () => {
		return (
			props.packageDetails &&
			props.packageDetails.samplesAndResultsTypes &&
			(props.packageDetails.samplesAndResultsTypes[0].reportPackageElementTypeId || '').toString()
		);
	};

	const applySearch = (searchTerm: string) => {
		let queryParameters = { ...filterState.queryParameters };
		queryParameters['search'] = searchTerm;
		setFilterState({ ...filterState, queryParameters: queryParameters });
		if (props.packageDetails && props.loadSamples) {
			props.loadSamples(props.packageDetails.reportPackageId || 0, queryParameters);
		}
	};

	const filterEditModalToggle = (isVisible: boolean) => {
		setFilterState({ ...filterState, showFilterEditorModal: isVisible });
	};

	const onClearFilter = () => {
		let queryParameters = { ...filterState.queryParameters };
		_.unset(queryParameters, 'draftFilterId');
		setFilterState({
			...filterState,
			queryParameters: queryParameters,
			draftFilterId: undefined
		});
		filterService.updateClearFilterUrl();
		if (props.packageDetails && props.loadSamples) {
			props.loadSamples(props.packageDetails.reportPackageId || 0, queryParameters);
		}
	};

	const applyBtnClicked = (filterSaved: Filter | undefined, draftFilterSave: DraftFilter | undefined) => {
		let queryParameters = { ...filterState.queryParameters };
		if (draftFilterSave) {
			queryParameters['draftFilterId'] = _.toString(draftFilterSave.draftFilterId);
		}
		setFilterState({
			...filterState,
			queryParameters: queryParameters,
			draftFilterId: draftFilterSave ? draftFilterSave.draftFilterId : undefined
		});

		if (props.packageDetails && props.loadSamples) {
			props.loadSamples(props.packageDetails.reportPackageId || 0, queryParameters);
		}
	};

	const onAddNewSample = async (event: React.MouseEvent<HTMLButtonElement>) => {
		props.saveReportPackage && (await props.saveReportPackage());
		const url = urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/new/step1`);
		let packageDetails = {
			...props.packageDetails,
			addSampleToReport: true
		};
		navigateTo(props.history as any, url, undefined, packageDetails);
	};

	const onCopyAndEditSample = async (sample: any) => {
		props.saveReportPackage && (await props.saveReportPackage());
		alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.step2.copyMessage'));
		let newSample: IppIndustrySample = {
			...sample,
			reportPackageElementTypeId:
				props.packageDetails &&
				props.packageDetails.samplesAndResultsTypes &&
				props.packageDetails.samplesAndResultsTypes[0].reportPackageElementTypeId,
			copyAndEdit: true,
			periodStartDateTimeLocal: props.packageDetails && props.packageDetails.periodStartDateTimeLocal,
			periodEndDateTimeLocal: props.packageDetails && props.packageDetails.periodEndDateTimeLocal,
			sourcePackageId: (props.packageDetails && props.packageDetails.reportPackageId) || null
		};
		navigateTo(
			props.history as any,
			urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/new/step2`),
			undefined,
			newSample
		);
	};

	const onEditSample = async (sample: any) => {
		props.saveReportPackage && (await props.saveReportPackage());
		let newSample: IppIndustrySample = {
			...sample,
			edit: true,
			sourcePackageId: (props.packageDetails && props.packageDetails.reportPackageId) || null,
			periodStartDateTimeLocal: (props.packageDetails && props.packageDetails.periodStartDateTimeLocal) || null,
			periodEndDateTimeLocal: (props.packageDetails && props.packageDetails.periodEndDateTimeLocal) || null
		};
		navigateTo(
			props.history as any,
			urlService.getIppIndustryUrl(`samples/${sample.sampleId}/details`),
			undefined,
			newSample
		);
	};

	let columnsDefinitions = getColumnDefinitions();
	let columns = generateColumns(columnsDefinitions);
	return (
		<>
			<PopoverModal
				showModal={isSampleAssociated}
				saveButtonText={localizationService.getLocalizedString('ipp.buttons.saveDraft')}
				title={localizationService.getLocalizedString(
					'ipp.reportPackage.package.packageElement.sampleConfirmationHeader'
				)}
				save={() => {
					onCopyAndEditSample(activeSample);
				}}
				cancel={() => {
					setIsSampleAssociated(false);
				}}>
				<label>
					{localizationService.getLocalizedString(
						'ipp.reportPackage.package.packageElement.sampleConfirmationText'
					)}
				</label>
				<label className="mt-2">
					{localizationService.getLocalizedString(
						'ipp.reportPackage.package.packageElement.sampleConfirmationText2'
					)}
				</label>
			</PopoverModal>

			<CollapsibleCard
				accordionType="report-package-samples-accordion"
				className={props.className ? props.className : ''}
				accordionHeading={
					<span>
						{localizationService.getLocalizedString(
							'ipp.reportPackage.package.packageElement.sampleAndResultTypes'
						)}
						{props.isRequired &&
							String.equalCaseInsensitive(props.reportStatus, ReportPackageStatus.Draft) && (
								<span className="ai-required">{` (${localizationService.getLocalizedString(
									'ipp.reportPackage.package.packageElement.isRequired'
								)})`}</span>
							)}
					</span>
				}>
				<>
					{props.packageDetails &&
					String.equalCaseInsensitive(props.packageDetails.reportStatusName, Draft) ? (
						<label className="mb-3">
							{localizationService.getLocalizedString(
								'ipp.reportPackage.package.packageElement.sampleEditNote'
							)}
						</label>
					) : (
						<></>
					)}
					<div id="report-package-samples">
						{(props.showFilter || props.showSearch || props.addNewSampleButton) && (
							<div className="form-row">
								{props.showFilter && (
									<div className="col-auto">
										<button
											className="btn ai-white"
											id="filterMenuBtn"
											onClick={() => {
												filterService.init(
													FilterDomain.IPPSAMPLE,
													FilterDomainName.ippSample,
													FilterObjectName.IppSample,
													LocalStorageName.IppSampleGridFilterUrl,
													{ IPPSAMPLE: true }
												);
												filterEditModalToggle(true);
											}}>
											{localizationService.getLocalizedString('grid.filters')}
										</button>
									</div>
								)}
								{props.showSearch && (
									<div className="col-auto">
										<SearchInput
											ariaLabel="Search"
											search={applySearch}
											placeholderDescription={localizationService.getLocalizedString(
												'ipp.samples.step2.searchHint'
											)}
										/>
									</div>
								)}
								{props.packageDetails &&
									String.equalCaseInsensitive(props.packageDetails.reportStatusName, Draft) && (
										<div className="col-auto  ml-auto">
											<button className="btn ai-white" id="addNewSample" onClick={onAddNewSample}>
												{localizationService.getLocalizedString('screen.buttons.addNewSample')}
											</button>
										</div>
									)}
							</div>
						)}

						<div className="mb-2 d-flex mt-1">
							<FilterQuickViewer
								reportPackageElementTypeId={getReportPackageElementTypeId()}
								draftFilterId={filterState.draftFilterId}
								onClosedClicked={onClearFilter}
								onFilterChanged={applyBtnClicked}
								filterDeleted={onClearFilter}
								onFilterSaved={() => {}}
							/>
						</div>
						<Grid
							className="table table-responsive"
							data={state.samples.length ? process(state.samples, { sort, ...pagination }) : []}
							scrollable="none"
							skip={pagination.skip}
							take={pagination.take}
							total={state.samples && state.samples.length}
							onPageChange={pageChange}
							pageable={true}
							sortable
							sort={sort}
							onSortChange={(e: any) => {
								e.sort[0] &&
								(String.equalCaseInsensitive(e.sort[0].field, 'compliantStatus') ||
									String.equalCaseInsensitive(e.sort[0].field, 'isAssociatedWithReportPackage'))
									? e.preventDefault && e.preventDefault()
									: setSort(e.sort);
							}}
							detail={props.customDetailComponent ? props.customDetailComponent : DetailComponent}
							expandField="expanded"
							onExpandChange={expandChange}>
							{columns}
						</Grid>
						{filterState.showFilterEditorModal && (
							<FilterEditModal
								reportPackageElementTypeId={getReportPackageElementTypeId()}
								isCreateNew={true}
								title={localizationService.getLocalizedString('screen.filterModal.newFilter')}
								modalToggleFunction={filterEditModalToggle}
								applyBtnClicked={applyBtnClicked}
								filterDeleted={onClearFilter}
								widgetCount={0}
								draftFilterApplyTo={FilterApplyTo.IppSample}
							/>
						)}
					</div>
				</>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageItemSamples;
