import React from 'react';
import * as ApiTypes from '@rcp/types';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DeleteModal } from 'src/components';
import { alertService } from 'src/redux';

interface OwnProps {
	event: ApiTypes.TimelineNotice;
	reloadGeneralNotices: () => void;
}

type Props = OwnProps;

export const TimelineEventGeneralSentNotice: React.FC<Props> = props => {
	const { sentNoticeGeneralDetails, templateName } = props.event;

	const [showDeleteGeneralNoticeModal, setShowDeleteGeneralNoticeModal] = React.useState(false);

	const sentNoticeDisplayText = () => {
		if (sentNoticeGeneralDetails && sentNoticeGeneralDetails.length === 1) {
			let { firstName, lastName, fullName, companyName, email, addressLine1 } = sentNoticeGeneralDetails[0];
			return fullName || firstName || lastName || companyName || email || addressLine1 || '';
		} else {
			return `${sentNoticeGeneralDetails!.length} ${localizationService
				.getLocalizedString('screen.labels.contacts')
				.toLowerCase()}`;
		}
	};

	const toggleDeleteGeneralNoticeModal = () => {
		setShowDeleteGeneralNoticeModal(!showDeleteGeneralNoticeModal);
	};

	const onShowGeneralNoticeDetailsLinkClicked = (e: any) => {
		e.preventDefault();
	};

	const deleteGeneralNotice = () => {
		if (props.event.sentNoticeGeneralId) {
			const generalEventsUrl = `${urlService.getFacilitiesResourceApiUrl()}/${Resource.GeneralNotices}/${
				props.event.sentNoticeGeneralId
			}`;
			apiService
				.deleteResource(generalEventsUrl)
				.then(() => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'General notice')
					);
					props.reloadGeneralNotices();
				})
				.catch(e => {
					alertService.addError(e.message);
				});
		}
		toggleDeleteGeneralNoticeModal();
	};

	return (
		<>
			<UncontrolledDropdown className="action-item-black general-notice-dropdown" direction="right">
				<DropdownToggle
					tag="a"
					href="#/"
					className="ai-link"
					onClick={onShowGeneralNoticeDetailsLinkClicked}
					id={`linkGeneralNotice-${props.event.sentNoticeGeneralId}`}>
					{templateName}
				</DropdownToggle>
				<DropdownMenu className="drop-down-position">
					<DropdownItem id="viewGeneralNotice" tag="a">
						<a href={props.event.s3PdfLink} className="ai-link" target={'_blank'} rel="noopener noreferrer">
							{localizationService.getLocalizedString('screen.buttons.view')}
						</a>
					</DropdownItem>
					<DropdownItem id="deleteGeneralNotice" tag="a" onClick={toggleDeleteGeneralNoticeModal}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			<span>
				&nbsp;
				{localizationService.getLocalizedString(
					'screen.timeline.sentNoticeLetterDescription',
					`${sentNoticeDisplayText()}`
				)}
			</span>
			{showDeleteGeneralNoticeModal && (
				<DeleteModal
					key="confirmDeleteGeneralNoticeModal"
					title={localizationService.getLocalizedString(
						'authoritySetting.noticesSettings.general.deleteModalTitle'
					)}
					message={localizationService.getLocalizedString(
						'authoritySetting.noticesSettings.general.deleteModalMessage'
					)}
					showModal={showDeleteGeneralNoticeModal}
					onCancelButtonClick={toggleDeleteGeneralNoticeModal}
					onOkayButtonClick={deleteGeneralNotice}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</>
	);
};
