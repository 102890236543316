import React, { FC, useEffect, useState } from 'react';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { noticeTemplateScheduleSlice } from './notice-setting.slice';
import { useDispatch, useSelector } from 'react-redux';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { DueDateTimeUnit } from './notice-template-editor';
import { default as LinkoTypes, NoticeTemplateSchedule } from '@rcp/types';
import { History } from 'history';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { alertService, RootState } from 'src/redux';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
}

export interface NoticeSettingsState {
	selected: NoticeTemplateSchedule;
	showDelete: boolean;
}

const NoticeSettingsComponent: FC<Props<NoticeTemplateSchedule>> = props => {
	let initialState: NoticeSettingsState = {
		showDelete: false,
		selected: {
			templateName: '',
			isActive: true,
			noticeTemplateScheduleId: 0,
			isBeforeDueDate: true,
			numberOfDaysAfterPreviousNotice: 0,
			scheduleDueDateTimeUnitName: DueDateTimeUnit[DueDateTimeUnit.DAY],
			scheduleDueDateTimeUnitNumber: 0,
			isShutoff: false
		}
	};
	let [state, setState] = useState(initialState);

	const dispatch = useDispatch();
	let { result: noticeTemplateSchedules } = useSelector((state: RootState) => state.noticeTemplateSchedules);

	useEffect(() => {
		dispatch(noticeTemplateScheduleSlice.fetchPage({}));
	}, [dispatch]);

	const onDeleteClicked = (index: number) => {
		const template = noticeTemplateSchedules[index];
		if (template) {
			setState({ ...state, selected: template, showDelete: true });
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const onDuplicateClicked = (index: number) => {
		const template = noticeTemplateSchedules[index];

		if (template) {
			const newTemplate = Object.assign({}, template);
			newTemplate.templateName = 'Copy of ' + template.templateName;
			if (newTemplate.templateName.length > 50) {
				alertService.addError(
					localizationService.getLocalizedString('authoritySetting.notice.nameMaximumLength', '50')
				);
				return;
			}
			newTemplate.noticeTemplateScheduleId = 0;
			dispatch(
				noticeTemplateScheduleSlice.createOne(
					newTemplate,
					true,
					localizationService.getLocalizedString('authoritySetting.notice.createSucceed')
				)
			);
		}
	};

	const onIsActiveChanged = (e: any, index: number) => {
		let { value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		const template = noticeTemplateSchedules[index];
		if (template) {
			dispatch(
				noticeTemplateScheduleSlice.patchOne(
					template.noticeTemplateScheduleId,
					{ isActive: value },
					true,
					localizationService.getLocalizedString('authoritySetting.notice.updateSucceed')
				)
			);
		}
	};

	const getTitleRow = () => {
		return (
			<thead>
				<tr className="lookup-row lookup-header-row font-size-14px-semibold">
					<th>{localizationService.getLocalizedString('authoritySetting.notice.active')}</th>
					<th>{localizationService.getLocalizedString('authoritySetting.notice.template')}</th>
					<th>{localizationService.getLocalizedString('authoritySetting.notice.schedule')}</th>
					<th className="action-2-buttons">&nbsp;</th>
					{/*<th className="action-2-buttons">&nbsp;</th>*/}
					<th className="action-2-buttons">&nbsp;</th>
				</tr>
			</thead>
		);
	};

	const onUpdateNoticeTemplate = (e: any, index: number) => {
		const template = noticeTemplateSchedules[index];
		if (template) {
			const url = urlService.getReactAuthorityResourceUrl(
				Resource.SettingNoticeTemplateSchedule,
				template.noticeTemplateScheduleId
			);
			navigateTo(props.history, url);
		}
	};

	const getScheduleDescription = (t: NoticeTemplateSchedule) => {
		const before = localizationService.getLocalizedString('authoritySetting.notice.before');
		const after = localizationService.getLocalizedString('authoritySetting.notice.after');
		let dueDate =
			`${t.scheduleDueDateTimeUnitNumber}` +
			` ${localizationService.getLocalizedString('authoritySetting.notice.day')} ` +
			` ${t.isBeforeDueDate ? before : after}`;
		if (t.scheduleDueDateTimeUnitNumber === 0) {
			dueDate = localizationService.getLocalizedString('authoritySetting.notice.on');
		}

		return (
			dueDate +
			` ${localizationService.getLocalizedString('authoritySetting.notice.schedule_1')}` +
			`${t.numberOfDaysAfterPreviousNotice} ${localizationService.getLocalizedString(
				'authoritySetting.notice.schedule_2'
			)}`
		);
	};

	const getRows = () => {
		return (
			<tbody>
				{noticeTemplateSchedules.map((field, index) => {
					return (
						<tr className="lookup-row" key={`option-key-${index}`}>
							<td id={`active_${index}`}>
								<SingleCheckbox
									className="ml-2"
									id={`cb_active_${index}`}
									name={`cb_active_${index}`}
									checked={field.isActive}
									onChange={e => {
										onIsActiveChanged(e, index);
									}}
								/>
							</td>
							<td id={`option_value_${index}`}>{field.templateName}</td>
							<td id={`option_value_${index}`}>{getScheduleDescription(field)}</td>
							<td>
								<button onClick={e => onUpdateNoticeTemplate(e, index)}>
									<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon " />
								</button>
							</td>

							{/*<td>*/}
							{/*    <button onClick={() => onDuplicateClicked(index)}>*/}
							{/*        <FontAwesomeIcon icon={faClone} className="font-awesome-icon "/>*/}
							{/*    </button>*/}
							{/*</td>*/}
							<td>
								<button onClick={() => onDeleteClicked(index)}>
									<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
								</button>
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};

	const onCancelDelete = () => {
		setState({ ...state, showDelete: false });
	};

	const onDeleteConfirmed = () => {
		dispatch(
			noticeTemplateScheduleSlice.deleteOne(
				state.selected.noticeTemplateScheduleId,
				true,
				localizationService.getLocalizedString('authoritySetting.notice.deleteSucceed'),
				() => {
					setState({ ...state, showDelete: false });
				}
			)
		);
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.notice.deleteTemplateTitle')}
				showModal={state.showDelete}
				cancel={() => onCancelDelete()}
				saveButtonClassName="ai-secondary-delete ml-auto"
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				save={() => onDeleteConfirmed()}>
				<span
					dangerouslySetInnerHTML={{
						__html: localizationService.getLocalizedString(
							'authoritySetting.notice.deleteTemplate',
							state.selected.templateName
						)
					}}
				/>
			</PopoverModal>
		);
	};

	const onAddNoticeTemplate = (e: any) => {
		const url = urlService.getReactAuthorityResourceUrl(Resource.SettingNoticeTemplateSchedule, '0');
		navigateTo(props.history, url, e);
	};

	const templateSection = () => {
		return (
			<>
				<div className="row">
					<div className="col-lg-4 settings-info">
						<strong>{localizationService.getLocalizedString('authoritySetting.notice.template')}</strong>
						<p>{localizationService.getLocalizedString('authoritySetting.notice.settingDescription')}</p>
					</div>
					<div className="col-lg-8">
						<section>
							<div>
								<div>
									<div className="form-row">
										<div className="d-flex full-width margin-bottom-1">
											<button
												className="btn ai-new mb-1 ml-auto "
												id="btnAdd"
												onClick={onAddNoticeTemplate}>
												{localizationService.getLocalizedString('authoritySetting.addBtnText')}
											</button>
										</div>
									</div>
									<div className="table-responsive">
										<table className="table">
											{getTitleRow()}
											{getRows()}
										</table>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="page">
				<>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('authoritySetting.notices')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">{urlService.isAdministrator() && <>{templateSection()}</>}</div>
					</div>
				</>
			</div>

			{state.showDelete && getDeleteModal()}
		</>
	);
};

export default NoticeSettingsComponent;
