import React from 'react';
import { Switch, Route, RouteProps, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { LinkoRouteState } from './redux/linko-route';
import { AlertMessageType, alertService, ApplicationState, useReduxSelector } from './redux';
import { localizationService, tokenService, urlService } from './services';
import { FeatureNames, GridTypes } from '@rcp/types';
import { ImportSettingsComponent } from './features/import-file/import-steps/configuration/import-settings';
import profile from './components/account/profile';
import PortalDirector from './components/account/portal-director';
import ForwardToReportPackage from './features/redirect/forward-to-report-pacakge';
import { AuthoritiesDeviceBulkUpdate } from './components/authority/fog/fog-devices/bulk-update/bulk-update';
import FogDeviceDetails from './components/authority/fog/fog-devices/details/device-details';
import { AuthoritiesBulkUpdate } from './components/authority/fog/facilities/facility-details/bulk-update/bulk-update';
import KbqVerification from './components/account/kbq-verification';
import UnitSettingsComponent from './components/authority/shared/settings/units/unit-settings';
import NoticeHistories from './components/authority/backflow/notices/histories/notice-histories';
import { CCCAuthoritySettingsLandingPage } from './components/authority/shared/settings/ccc-landing-page';
import NoticeSettingsComponent from './components/authority/backflow/settings/notices/notice-settings';
import NoticeTemplateEditorComponent from './components/authority/backflow/settings/notices/notice-template-editor';
import CccFrequencySettingsComponent from './components/authority/backflow/settings/ccc-frequency/ccc-frequency-settings';
import FogFrequencyUnitSettingsComponent from './components/authority/fog/settings/fog-frequency-unit/fog-frequency-unit-settings';
import IndustriesBulkUpdate from './components/authority/ipp/industries/bulk-update-industries';
import IppAuditLogsComponent from './components/authority/ipp/audit-logs/audit-logs';
import IppReportPackageTemplatesComponent from './components/authority/ipp/report-packages/templates/templates';
import IppReportPackageElementsComponent from './components/authority/ipp/report-packages/report-elements/elements';
import IppReportPackageElementDetailsComponent from './components/authority/ipp/report-packages/report-elements/element-details/element-details';
import {
	IndustryHomepage,
	IppAttachmentDetails,
	IppAttachments,
	IppDataProviderDetails,
	IppDataProviders,
	IppDraftReportPackage,
	IppIndustrySampleStep1,
	IppIndustrySampleStep2,
	IppPackageDetails,
	IppReportPackageStep1,
	IppScheduledReportPackage
} from './components/service-provider';
import IppReportPackageParameterGroupsComponent from './components/authority/ipp/report-packages/parameter-groups/parameter-groups';
import IppPackageSubmittedPendingReviews from './components/authority/ipp/report-packages/packages/reviews/submitted-pending-reviews';
import IppPackageRepudiatedPendingReviews from './components/authority/ipp/report-packages/packages/reviews/repudiated-pending-reviews';
import IppReportPackagesAllReviews from './components/authority/ipp/report-packages/packages/reviews/all-reviews';
import Translations from './components/service-provider/industry/data-providers/translations/translations';
import IppIndustrySamplesComponent from './components/service-provider/industry/samples/samples';
import IppIndustrySampleImportComponent from './components/service-provider/industry/samples/import/import-samples/import-samples';
import IppIndustrySamplesReadyToReportComponent from './components/service-provider/industry/samples/samples-ready-to-report';
import IppIndustrySamplesReportedComponent from './components/service-provider/industry/samples/samples-reported';
import IppReadyToSubmitReportPackage from './components/service-provider/industry/report-packages/ready-to-submit-package-list';
import IppPendingAcceptanceReportPackage from './components/service-provider/industry/report-packages/pending-acceptance-list';
import IppSubmittedReportPackage from './components/service-provider/industry/report-packages/submitted-package-list';
import IppRejectedReportPackage from './components/service-provider/industry/report-packages/rejected-package-list';
import IppRepudiatedReportPackage from './components/service-provider/industry/report-packages/repudiated-package-list';
import { NotFound } from './features/home/not-found';
import { AccessDeniedPage } from './features/home/access-denied';
import {
	AuthoritySettingFacility,
	AuthoritySettingHauler,
	AuthoritySettingsAuthority,
	AuthoritySettingsContact,
	AuthoritySettingsImportFile,
	AuthoritySettingsLandingPage,
	AuthorityUserGrid,
	CccAuthoritySettingDevices,
	CustomerSuccessSettingAuthorities,
	CustomerSuccessUserGrid,
	InspectionFormTemplateGrid,
	SystemSettingMaintenanceMessage,
	HaulerPortalSettingsLandingPage,
	FacilityPortalSettingsLandingPage
} from './components/authority/shared/settings';
import { ConstructionView } from './features/home/construction';
import { ScrollToTop } from './components/widgets';
import { ImportHistoryGrid } from './features/import-file/import-history';
import {
	BusinessInformation,
	CccCompanies,
	CccHazardDetails,
	CccHazards,
	CccNotices,
	CccSiteDetails,
	CccSites,
	CccTechnicians,
	CccTestKits,
	ComplianceSettingFacility,
	Dashboard,
	DashboardWidgetDetails,
	DeviceSettingComponent,
	FacilitiesGrid,
	FacilityAttachmentDetails,
	FacilityDetails,
	FogDevicesGrid,
	HaulerGrid,
	HaulerPortalUsersGrid,
	HazardSettingsComponent,
	InspectionDetails,
	InspectionGrid,
	InspectionSettingFacility,
	InspectionView,
	IppAuthorityAccountSetting,
	IppAuthorityACPConnector,
	IppIndustriesComponent,
	IppIndustryDetails,
	IppPackageReviewDetails,
	IppPendingRegistrationsList,
	IppPendingRegistrationUser,
	IppReportPackageParameterGroupDetailsComponent,
	IppReportPackageTemplateDetailsComponent,
	IppSampleImportTemplatesList,
	IppUnitTranslationsList,
	IppUserDetails,
	IppUsers,
	SiteSettingsComponent,
	TestSettingsComponent,
	ViolationGridView,
	FacilityPortalUsersGrid
} from './components/authority';
import { ImportFile, SelfImport } from './features/import-file';
import { Home } from './features/home/home';
import {
	ConfigureQuestionAnswers,
	ForgetPassword,
	RegisterComponent,
	ResetPassword,
	SignIn
} from './components/account';
import { OidcCallback } from './components/account/oidc';
import { PrivacyPolicy } from './features/privacyPolicy/privacyPolicy';
import { TermsAndCondition } from './features/termCondition/termCondition';
import { CleaningsGrid } from './components/authority/fog/cleaning/cleaning-grid';
import { UserAccountRegistration } from './components/service-provider/shared/portal-registration/user-registration';
import InputInvitationCode from './components/service-provider/shared/portal-registration/enter-invitation-code-page';
import HaulerFacilities from './components/service-provider/hauler/hauler-facilities/hauler-facilities';
import HaulerPortalAuthorities from './components/service-provider/hauler/authorities/authorities-grid';
import HaulerFacilityDetail from './components/service-provider/hauler/hauler-facilities/hauler-facility-detail/hauler-facility-detail';
import HaulerSelectFacilities from './components/service-provider/hauler/hauler-facilities/hauler-facility-detail/hauler-select-facilities';
import HaulerCleaningHistory from './components/service-provider/hauler/cleaning-history/cleaning-history';
import { Editor } from './components/customerSuccess/editor';
import { AuthoritySettingsNotices } from './components/authority/fog/settings/notices';
import CleaningNoticeStepper from './components/authority/fog/settings/notices/cleaning-notice-stepper/cleaning-notice-stepper';
import { DeviceNotice } from './components/authority/fog/fog-devices/details/cleaning-notice';
import GeneralNoticeStepper from './components/authority/fog/settings/notices/general-notice-stepper/general-notice-stepper';
import { FacilityNotice } from './components/authority/fog/facilities/facility-details/facility-notice';
import { FogPendingNoticeGrid } from './components/authority/fog/notices/pending-notices';
import { NoticeHistories as FogNoticeHistories } from './components/authority/fog/notices/notices-history';
import { FacilitiesBulkNotice } from './components/authority/fog/facilities/facilities-bulk-notice-stepper/facilities-bulk-notice-stepper';
import IppErrorsReportPackage from './components/service-provider/industry/report-packages/error-package-list';
import IppReportPackagesErrorsReviews from './components/authority/ipp/report-packages/packages/reviews/error-reviews';
import FacilityDevices from './components/service-provider/facility/cleanings/facility-devices';
import FacilityCleaningHistories from './components/service-provider/facility/cleaning-history/cleaning-history';
import FacilitySettings from './components/authority/fog/settings/facility-portal/facilities/facilities';
import { EventsGrid } from './components/authority/fog/events/events-grid';
import { IncidentsGrid } from './components/authority/fog/incidents/incidents-grid';
import EventsSettingComponent from './components/authority/shared/settings/events/events-setting';
import { AuthoritySettingsCustomForms } from './components/authority/shared/settings/custom-forms/custom-forms';
import { FormBuilder } from './components/authority/shared/settings/custom-forms/form-builder';
import { CustomerSuccessOperationLogComponent } from './components/customerSuccess/operation-log';
import { DataConnectorNoticesComponent } from './components/customerSuccess/data-connector-notices';
import { IframeFormBuilder } from './components/authority/shared/settings/custom-forms/iFrame-form-builder';
import { FacilityPortalSettingHauler } from './components/authority/fog/settings/facility-portal/hauler/hauler';
import { AuthoritySettingsIncidents } from './components/authority/fog/settings/incidents';
import { MapComponent } from './components/authority/fog/map/map';
import { PublicEmailView } from './components/authority/shared/public/email-view';

interface LinkoRouteProps extends RouteProps, LinkoRouteState {
	// tslint:disable-next-line:no-any
	component: any;
	title?: string;
}

const mapStateToProps = (state: ApplicationState): LinkoRouteState => {
	return {
		...state.linkoRouteDisplay
	};
};

function setTitle(title: string | undefined) {
	if (title) {
		if (
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.pageNotFound')) ||
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.signInTitle'))
		) {
			document.title = title;
		} else if (
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.inspection')) ||
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.facility')) ||
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.attachment')) ||
			String.equalCaseInsensitive(title, localizationService.getLocalizedString('pageTitle.inspectionView'))
		) {
			// Inspection and Facility page titles are mounted from FacilityAddress component
			// Inspection View page title is mounted from InspectionViewDetails component
			// Any route with the Attachment page title is mounted from the AttachmentContent component
			document.title = localizationService.getLocalizedString('pageTitle.defaultTitle');
		} else {
			document.title = title + localizationService.getLocalizedString('pageTitle.endOfTitle');
		}
	} else {
		document.title = localizationService.getLocalizedString('pageTitle.defaultTitle');
	}
}

export const PrivateRouteComponent: React.FC<LinkoRouteProps> = props => {
	const location = useLocation();
	React.useEffect(() => {
		setTitle(props.title);
		alertService.clearMessageByType(AlertMessageType.Error);
	}, [props.title, location.pathname]);

	const { component: Component, ...rest } = props;

	const renderAuthenticatedComp = (routeProps: any) => {
		if (props.notFoundState === true) {
			return <NotFound path={location.pathname} />;
		}
		return (
			<>
				<ScrollToTop />
				<Component {...routeProps} />
			</>
		);
	};

	const renderFn = (routeProps: any) => {
		if (tokenService.isTokenValid()) {
			return renderAuthenticatedComp(routeProps);
		}
		//redirect to login url
		let loginUrl = urlService.getLoginUrl();
		window.location.href = loginUrl;
		return <></>;
	};

	return <Route {...rest} render={renderFn} />;
};
export const PrivateRoute = connect(mapStateToProps)(PrivateRouteComponent);

const PublicRouteComponent: React.FC<LinkoRouteProps> = props => {
	React.useEffect(() => {
		setTitle(props.title);
	}, [props.title]);

	const { component: Component, ...rest } = props;

	return (
		<Route
			{...rest}
			render={routeProps =>
				props.notFoundState === true ? (
					<NotFound />
				) : (
					<>
						<ScrollToTop />
						<Component {...routeProps} />
					</>
				)
			}
		/>
	);
};
export const PublicRoute = connect(mapStateToProps)(PublicRouteComponent);

interface IRoute {
	title: string;
	path: string;
	component: any;
}

const fogImportPrivateExactRoutes: IRoute[] = [
	{
		title: localizationService.getLocalizedString('importHistory.importHistory'),
		path: '/fog/settings/import/history',
		component: ImportHistoryGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'screen.labels.facilities'),
		path: '/fog/settings/import/history/:importId/facilities/new',
		component: FacilitiesGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'screen.labels.facilities'),
		path: '/fog/settings/import/history/:importId/facilities/updated',
		component: FacilitiesGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'import.dataTypes.fogDevice'),
		path: '/fog/settings/import/history/:importId/devices/new',
		component: FogDevicesGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'import.dataTypes.fogDevice'),
		path: '/fog/settings/import/history/:importId/devices/updated',
		component: FogDevicesGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'import.dataTypes.fogInspection'),
		path: '/fog/settings/import/history/:importId/inspections/new',
		component: InspectionGrid
	},
	{
		title: localizationService.getLocalizedString(
			'importHistory.updatedEntities',
			'import.dataTypes.fogInspection'
		),
		path: '/fog/settings/import/history/:importId/inspections/updated',
		component: InspectionGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'import.dataTypes.fogHauler'),
		path: '/fog/settings/import/history/:importId/haulers/new',
		component: HaulerGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'import.dataTypes.fogHauler'),
		path: '/fog/settings/import/history/:importId/haulers/updated',
		component: HaulerGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'import.dataTypes.fogCleaning'),
		path: '/fog/settings/import/history/:importId/cleanings/new',
		component: CleaningsGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'import.dataTypes.fogCleaning'),
		path: '/fog/settings/import/history/:importId/cleanings/updated',
		component: CleaningsGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'import.dataTypes.fogEvents'),
		path: '/fog/settings/import/history/:importId/events/new',
		component: EventsGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'import.dataTypes.fogEvents'),
		path: '/fog/settings/import/history/:importId/events/updated',
		component: EventsGrid
	}
];

const fogSelfServiceImportPrivateExactRoutes: IRoute[] = [
	{
		title: localizationService.getLocalizedString('screen.labels.import'),
		path: '/fog/settings/self-import',
		component: SelfImport
	},
	{
		title: localizationService.getLocalizedString('screen.labels.importSettings'),
		path: '/fog/settings/self-import/data-source-settings/:dataType',
		component: ImportSettingsComponent
	}
];

const backflowImportPrivateExactRoutes: IRoute[] = [
	{
		title: localizationService.getLocalizedString('screen.labels.import'),
		path: '/backflow/settings/import',
		component: ImportFile
	},
	{
		title: localizationService.getLocalizedString('screen.labels.importSettings'),
		path: '/backflow/Settings/import/settings',
		component: ImportSettingsComponent
	},
	{
		title: localizationService.getLocalizedString('screen.labels.importHistory'),
		path: '/backflow/Settings/import/history',
		component: ImportHistoryGrid
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'cccSite.sites'),
		path: '/backflow/settings/import/history/:importId/new/sites',
		component: CccSites
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'cccSite.sites'),
		path: '/backflow/settings/import/history/:importId/updated/sites',
		component: CccSites
	},
	{
		title: localizationService.getLocalizedString('importHistory.newEntities', 'cccHazard.hazards'),
		path: '/backflow/settings/import/history/:importId/new/hazards',
		component: CccHazards
	},
	{
		title: localizationService.getLocalizedString('importHistory.updatedEntities', 'cccHazard.hazards'),
		path: '/backflow/settings/import/history/:importId/updated/hazards',
		component: CccHazards
	}
];

export const LinkoRoute = () => {
	const featureSettings = useReduxSelector(state => state.featureSettings.featureFlagSettings);
	const showCustomCleaningFormsFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowCustomCleaningFormsFeature]
	);

	const showFogIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);

	const showFogMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);

	let { portalType, isAccessDenied, guid } = urlService.getPortalStatus();
	if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
		urlService.switchToDefaultView(guid);
		return <></>;
	}
	if (!urlService.isAccountPortal() && tokenService.isTokenValid()) {
		urlService.removeUrlQueryParam('accessToken');
	}
	let index = 0;
	let industryBasePath = `/industry/${guid}`;
	let haulerBasePath = `/hauler/:id`;
	let facilityPortalBasePath = `/facility/:id`;
	return (
		<Switch>
			<PublicRoute exact path="/" component={Home} />
			<PublicRoute
				title={localizationService.getLocalizedString('pageTitle.signInTitle')}
				exact
				path="/SignIn"
				component={SignIn}
			/>
			<PublicRoute title="OidcCallback" exact path="/Callback" component={OidcCallback} />
			<PublicRoute title="Sign In Help" exact path="/ForgetPassword" component={ForgetPassword} />
			<PublicRoute title="New Password" exact path="/ResetPassword/:token" component={ResetPassword} />
			<PublicRoute title="Privacy Policy" exact path="/PrivacyPolicy" component={PrivacyPolicy} />
			<PublicRoute title="Terms and Conditions" exact path="/TermsAndConditions" component={TermsAndCondition} />
			<PublicRoute
				title="Privacy Policy"
				exact
				path={`${industryBasePath}/PrivacyPolicy`}
				component={PrivacyPolicy}
			/>
			<PublicRoute
				title="Terms and Conditions"
				exact
				path={`${industryBasePath}/TermsAndConditions`}
				component={TermsAndCondition}
			/>
			<PublicRoute
				title="Knowledge Based Question"
				exact
				path="/account/KbqChallenge"
				component={KbqVerification}
			/>
			<PublicRoute title="Profile" exact path={`${industryBasePath}/profile`} component={profile} />
			<PublicRoute title="Profile" exact path="/ipp/authority/profile" component={profile} />
			<PublicRoute title="Register" exact path="/register/:invitationId" component={RegisterComponent} />
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.organizations')}
				exact
				path="/account/organizations"
				component={PortalDirector}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.invitation')}
				exact
				path={`/account/invite`}
				component={InputInvitationCode}
			/>
			<PublicRoute
				title="Configure MFA"
				exact
				path="/account/configureMfaQuestionAndAnswers"
				component={ConfigureQuestionAnswers}
			/>
			<PublicRoute title="Forward To Report Package" exact path="/forward" component={ForwardToReportPackage} />

			{/*FOG Public Views*/}
			<PublicRoute
				title="Inspection View"
				exact
				path="/Public/InspectionView/:inspectionPublicId"
				component={InspectionView}
			/>
			<PublicRoute
				title="Attachment"
				exact
				path="/Public/InspectionView/:inspectionPublicId/attachments/:attachmentId"
				component={InspectionView}
			/>
			<PublicRoute title="Email" exact path="/public/emailView/:publicId" component={PublicEmailView} />
			{/* Hauler Public paths */}
			<PublicRoute
				title="Register Account"
				exact
				path={`/:portalName/register`}
				component={UserAccountRegistration}
			/>
			<PublicRoute
				title={localizationService.getLocalizedString('screen.labels.invitation')}
				exact
				path={`/hauler/:guid`}
				component={InputInvitationCode}
			/>
			<PublicRoute
				title={localizationService.getLocalizedString('screen.labels.invitation')}
				exact
				path={`/facility/:guid`}
				component={InputInvitationCode}
			/>
			{isAccessDenied && portalType !== 'authority' && (
				<PrivateRoute title="Access Denied" path="/ipp/authority" component={AccessDeniedPage} />
			)}
			{isAccessDenied && portalType !== 'industry' && (
				<PrivateRoute title="Access Denied" path={`${industryBasePath}`} component={AccessDeniedPage} />
			)}
			{/*FOG SideNav*/}
			<PrivateRoute title="Dashboard" exact path={[`/fog/`, `/fog/dashboard`]} component={Dashboard} />
			<PrivateRoute
				title="Add Widget"
				exact
				path={`/fog/dashboard/addWidget`}
				component={DashboardWidgetDetails}
			/>
			<PrivateRoute
				title="Edit Widget"
				exact
				path={`/fog/dashboard/editWidget/:id`}
				component={DashboardWidgetDetails}
			/>
			{showFogMapFeature && <PrivateRoute title="Map" exact path={[`/fog/map`]} component={MapComponent} />}

			<PrivateRoute title="Facilities" exact path={`/fog/Facilities`} component={FacilitiesGrid} />
			<PrivateRoute title="Haulers" exact path="/fog/haulers" component={HaulerGrid} />
			<PrivateRoute title="Notice outbox" exact path={'/fog/notices'} component={FogPendingNoticeGrid} />
			<PrivateRoute
				title="Notice History"
				exact
				path={'/fog/SentNoticeHistories'}
				component={FogNoticeHistories}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerSelectFacility')}
				exact
				path={`/fog/haulers/:haulerId/facilities/select`}
				component={HaulerSelectFacilities}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilityDetail')}
				exact
				path={`/fog/haulers/:haulerId/facility/:id`}
				component={HaulerFacilityDetail}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilities')}
				exact
				path={`/fog/haulers/:haulerId/facilities`}
				component={HaulerFacilities}
			/>
			<PrivateRoute title="Inspections" exact path={`/fog/inspections`} component={InspectionGrid} />
			<PrivateRoute title="Violations" exact path={`/fog/violations`} component={ViolationGridView} />
			<PrivateRoute title="Devices" exact path={'/fog/devices'} component={FogDevicesGrid} />
			<PrivateRoute title="Cleaning" exact path={'/fog/cleanings'} component={CleaningsGrid} />
			{/*FOG Individual View*/}
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.deviceBulkUpdate')}
				exact
				path={`/fog/devices/BulkUpdate`}
				component={AuthoritiesDeviceBulkUpdate}
			/>
			<PrivateRoute
				title="SendCleaningNotice"
				path={'/fog/devices/:deviceId/sendCleaningNotice'}
				component={DeviceNotice}
			/>
			<PrivateRoute
				title="Send Cleaning Notice"
				exact
				path={'/fog/devices/sendCleaningNotice'}
				component={DeviceNotice}
			/>
			<PrivateRoute
				title="Attachment"
				exact
				path={`/fog/Devices/:deviceId/attachments/:attachmentId`}
				component={FacilityAttachmentDetails}
			/>
			<PrivateRoute title="Device" path={'/fog/devices/:deviceId'} component={FogDeviceDetails} />
			<PrivateRoute title="Device" exact path={'/fog/devices/:deviceId/:tab'} component={FogDeviceDetails} />
			<PrivateRoute
				title="Facility Bulk Update"
				exact
				path={`/fog/Facilities/BulkUpdate`}
				component={AuthoritiesBulkUpdate}
			/>
			<PrivateRoute
				title="Facility Bulk Notice"
				exact
				path={`/fog/Facilities/BulkNotice`}
				component={FacilitiesBulkNotice}
			/>
			<PrivateRoute
				title="Attachment"
				exact
				path={`/fog/Facilities/:fogFacilityId/attachments/:attachmentId`}
				component={FacilityAttachmentDetails}
			/>
			<PrivateRoute
				title="Facility Notice"
				path={'/fog/Facilities/:fogFacilityId/sendNotice'}
				component={FacilityNotice}
			/>
			<PrivateRoute
				title="Inspection"
				exact
				path={`/fog/Facilities/:fogFacilityId/${GridTypes.Inspections}/:inspectionId`}
				component={InspectionDetails}
			/>
			<PrivateRoute
				title="Attachment"
				exact
				path={`/fog/Facilities/:fogFacilityId/${GridTypes.Inspections}/:inspectionId/attachments/:attachmentId`}
				component={FacilityAttachmentDetails}
			/>
			<PrivateRoute title="Facility" path={`/fog/Facilities/:fogFacilityId`} component={FacilityDetails} />
			<PrivateRoute
				title="Facility"
				exact
				path={`/fog/Facilities/:fogFacilityId/:tab`}
				component={FacilityDetails}
			/>
			{fogSelfServiceImportPrivateExactRoutes.map(props => (
				<Route key={`selfImport-${index++}`} {...props} exact />
			))}
			{fogImportPrivateExactRoutes.map(props => (
				<Route key={`import-${index++}`} {...props} exact />
			))}
			{/*FOG Authority Settings*/}
			<PrivateRoute title="Settings" exact path="/fog/settings" component={AuthoritySettingsLandingPage} />
			<PrivateRoute
				title="Authority"
				exact
				path="/fog/settings/authority"
				component={AuthoritySettingsAuthority}
			/>
			<PrivateRoute title="Contacts" exact path="/fog/settings/contact" component={AuthoritySettingsContact} />
			<PrivateRoute title="Units" exact path="/fog/settings/units" component={UnitSettingsComponent} />
			<PrivateRoute title="Users" exact path="/fog/settings/users" component={AuthorityUserGrid} />
			<PrivateRoute title="Users" exact path="/backflow/settings/users" component={AuthorityUserGrid} />
			<PrivateRoute title="Haulers" path="/fog/settings/hauler" component={AuthoritySettingHauler} />
			<PrivateRoute title="Facilities" exact path="/fog/settings/facility" component={AuthoritySettingFacility} />
			{showCustomCleaningFormsFeature && (
				<PrivateRoute
					title={localizationService.getLocalizedString('authoritySetting.customForms')}
					exact
					path="/fog/settings/customForms"
					component={AuthoritySettingsCustomForms}
				/>
			)}
			{showCustomCleaningFormsFeature && (
				<PrivateRoute
					title={localizationService.getLocalizedString('authoritySetting.customForms')}
					exact
					path="/fog/settings/customForms/:customFormType"
					component={FormBuilder}
				/>
			)}
			{showCustomCleaningFormsFeature && (
				<PrivateRoute
					title={localizationService.getLocalizedString('authoritySetting.customForms')}
					exact
					path="/fog/settings/customForm/:customFormType"
					component={IframeFormBuilder}
				/>
			)}
			<PrivateRoute
				title="Inspections"
				exact
				path="/fog/settings/inspections"
				component={InspectionSettingFacility}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('violation.compliance')}
				exact
				path="/fog/settings/compliance"
				component={ComplianceSettingFacility}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('authoritySetting.devices')}
				exact
				path="/fog/settings/devices"
				component={DeviceSettingComponent}
			/>
			{showFogIncidentFeature && (
				<PrivateRoute
					title={localizationService.getLocalizedString('authoritySetting.incidents')}
					exact
					path="/fog/settings/incidents"
					component={AuthoritySettingsIncidents}
				/>
			)}
			{showFogIncidentFeature && (
				<PrivateRoute
					title={localizationService.getLocalizedString('incidents.incidents')}
					exact
					path="/fog/incidents"
					component={IncidentsGrid}
				/>
			)}

			{/*InternalAccount Settings*/}
			<PrivateRoute
				title="Inspection Templates"
				exact
				path="/fog/settings/inspectionTemplates"
				component={InspectionFormTemplateGrid}
			/>
			<PrivateRoute
				title="Customer Success Users"
				exact
				path="/fog/settings/customerSuccessUsers"
				component={CustomerSuccessUserGrid}
			/>
			<PrivateRoute
				title="Operation Log"
				exact
				path="/fog/settings/operationLog"
				component={CustomerSuccessOperationLogComponent}
			/>
			<PrivateRoute
				title="Data Connector Notices"
				exact
				path="/fog/settings/dataConnectorNotices"
				component={DataConnectorNoticesComponent}
			/>
			<PrivateRoute title="RichEditor" exact path="/fog/settings/editor" component={Editor} />
			<PrivateRoute title="RichEditor" exact path="/backflow/settings/editor" component={Editor} />
			<PrivateRoute
				title="Customer Success Users"
				exact
				path="/backflow/settings/customerSuccessUsers"
				component={CustomerSuccessUserGrid}
			/>
			<PrivateRoute
				title="Maintenance Message"
				exact
				path="/fog/settings/maintenanceMessage"
				component={SystemSettingMaintenanceMessage}
			/>
			<PrivateRoute
				title="Maintenance Message"
				exact
				path="/backflow/settings/maintenanceMessage"
				component={SystemSettingMaintenanceMessage}
			/>
			<PrivateRoute
				title="Authorities"
				exact
				path="/fog/settings/authorities"
				component={CustomerSuccessSettingAuthorities}
			/>
			<PrivateRoute
				title="Authorities"
				exact
				path="/backflow/settings/authorities"
				component={CustomerSuccessSettingAuthorities}
			/>
			{/*Backflow SideNav*/}
			<PrivateRoute title="Sites" exact path={['/backflow/', '/backflow/sites']} component={CccSites} />
			<PrivateRoute title="Hazards" exact path={`/backflow/hazards`} component={CccHazards} />
			<PrivateRoute title="Tests" exact path={`/backflow/tests`} component={ConstructionView} />
			<PrivateRoute title="Notices" exact path={`/backflow/notices`} component={CccNotices} />
			<PrivateRoute title="Inspections" exact path="/backflow/inspections" component={ConstructionView} />
			<PrivateRoute title="Installations" exact path={`/backflow/installations`} component={ConstructionView} />
			<PrivateRoute title="Repairs" exact path={`/backflow/repairs`} component={ConstructionView} />
			<PrivateRoute title="Technician Companies" exact path={`/backflow/companies`} component={CccCompanies} />
			<PrivateRoute title="Violations" exact path={`/backflow/violations`} component={ConstructionView} />
			<PrivateRoute title="Technicians" exact path={`/backflow/technicians`} component={CccTechnicians} />
			<PrivateRoute title="Test Kits" exact path={`/backflow/testKits`} component={CccTestKits} />
			<PrivateRoute title="Letter History" exact path={`/backflow/letterHistory`} component={ConstructionView} />
			<PrivateRoute title="Site" path={`/backflow/sites/:cccSiteId`} component={CccSiteDetails} />
			<PrivateRoute title="Site" exact path={`/backflow/sites/:cccSiteId/:tab`} component={CccSiteDetails} />
			<PrivateRoute title="Hazard" path={`/backflow/hazards/:cccHazardId`} component={CccHazardDetails} />
			<PrivateRoute
				title="Hazard"
				exact
				path={`/backflow/hazards/:cccHazardId/:tab`}
				component={CccHazardDetails}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('ccc.noticeHistory.title')}
				exact
				path={`/backflow/SentNoticeHistories`}
				component={NoticeHistories}
			/>
			{/*Backflow settings*/}
			<PrivateRoute
				title="Authority"
				exact
				path={`/backflow/settings/authority`}
				component={AuthoritySettingsAuthority}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.settings')}
				exact
				path={`/backflow/Settings`}
				component={CCCAuthoritySettingsLandingPage}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('authoritySetting.contacts')}
				exact
				path="/backflow/settings/contacts"
				component={AuthoritySettingsContact}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('cccDevice.devices')}
				exact
				path={`/backflow/Settings/Devices`}
				component={CccAuthoritySettingDevices}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.sites')}
				exact
				path={`/backflow/Settings/sites`}
				component={SiteSettingsComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('cccHazard.hazards')}
				exact
				path={`/backflow/Settings/hazards`}
				component={HazardSettingsComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('cccTest.tests')}
				exact
				path={`/backflow/Settings/tests`}
				component={TestSettingsComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.noticeSettings')}
				exact
				path={`/backflow/Settings/NoticeTemplateSchedule`}
				component={NoticeSettingsComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('authoritySetting.notice.template')}
				exact
				path={`/backflow/Settings/NoticeTemplateSchedule/:noticeTemplateScheduleId`}
				component={NoticeTemplateEditorComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				exact
				path={`/backflow/Settings/Frequencies`}
				component={CccFrequencySettingsComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('authoritySetting.frequency.frequencies')}
				exact
				path={`/fog/Settings/FrequenciesAndUnits`}
				component={FogFrequencyUnitSettingsComponent}
			/>
			{backflowImportPrivateExactRoutes.map(props => (
				<Route key={index++} {...props} exact />
			))}
			{/* IPP Routes */}
			<PrivateRoute
				title="Industries"
				exact
				path={['/ipp/', '/ipp/authority/', '/ipp/authority/industries']}
				component={IppIndustriesComponent}
			/>
			<PrivateRoute
				title="Industries - Bulk Update"
				exact
				path="/ipp/authority/industry/bulkUpdate"
				component={IndustriesBulkUpdate}
			/>
			<PrivateRoute
				title="Industry Details"
				exact
				path={`/ipp/authority/industries/:industryId`}
				component={IppIndustryDetails}
			/>
			<PrivateRoute
				title="User"
				exact
				path="/ipp/authority/authorityAccount/users/user/:userId"
				component={IppUserDetails}
			/>
			<PrivateRoute
				title="Authority Account"
				exact
				path="/ipp/authority/authorityAccount/users"
				component={IppUsers}
			/>
			<PrivateRoute
				title="User"
				exact
				path="/ipp/authority/industries/:industryId/users/:userId"
				component={IppUserDetails}
			/>
			<PrivateRoute
				title="Industry Users"
				exact
				path="/ipp/authority/industries/:industryId/users"
				component={IppUsers}
			/>
			<PrivateRoute
				title="Authority Account Settings"
				exact
				path={`/ipp/authority/authorityAccount/settings`}
				component={IppAuthorityAccountSetting}
			/>
			<PrivateRoute
				title="ACP Connector Settings"
				exact
				path={`/ipp/authority/authorityAccount/acpConnector`}
				component={IppAuthorityACPConnector}
			/>
			<PrivateRoute title="Audit Logs" exact path="/ipp/authority/auditLogs" component={IppAuditLogsComponent} />
			<PrivateRoute
				title="Pending Registration"
				exact
				path={`/ipp/authority/pendingRegistrations`}
				component={IppPendingRegistrationsList}
			/>
			<PrivateRoute
				title="Pending Registration - User Details"
				exact
				path={`/ipp/authority/pendingRegistrations/users/:userId`}
				component={IppPendingRegistrationUser}
			/>
			<PrivateRoute
				title="Report Package Templates"
				exact
				path="/ipp/authority/reportSettings/reportPackageTemplates"
				component={IppReportPackageTemplatesComponent}
			/>
			<PrivateRoute
				title="Report Package Template"
				exact
				path="/ipp/authority/reportSettings/reportPackageTemplates/:templateId"
				component={IppReportPackageTemplateDetailsComponent}
			/>
			<PrivateRoute
				title="Report Package Template"
				exact
				path="/ipp/authority/reportSettings/reportPackageTemplates/new"
				component={IppReportPackageTemplateDetailsComponent}
			/>
			<PrivateRoute
				title="Report Package Elements"
				exact
				path="/ipp/authority/reportSettings/reportElementTypes"
				component={IppReportPackageElementsComponent}
			/>
			<PrivateRoute
				title="Report Package Element Details"
				exact
				path="/ipp/authority/reportSettings/reportElementTypes/:type/new"
				component={IppReportPackageElementDetailsComponent}
			/>
			<PrivateRoute
				title="Report Package Element Details"
				exact
				path="/ipp/authority/reportSettings/reportElementTypes/:id"
				component={IppReportPackageElementDetailsComponent}
			/>
			<PrivateRoute title="Industry Dashboard" exact path={`${industryBasePath}/`} component={IndustryHomepage} />
			<PrivateRoute
				title="Industry Dashboard"
				exact
				path={`${industryBasePath}/dashboard`}
				component={IndustryHomepage}
			/>
			<PrivateRoute
				title="Industry Account Users"
				exact
				path={`${industryBasePath}/industryAccount/users`}
				component={IppUsers}
			/>
			<PrivateRoute
				title="Industry Account - User Details"
				exact
				path={`${industryBasePath}/industryAccount/users/:userId`}
				component={IppUserDetails}
			/>
			<PrivateRoute
				title="Industry Account Settings"
				exact
				path={`${industryBasePath}/industryAccount/settings`}
				component={IppIndustryDetails}
			/>
			<PrivateRoute
				title="Data Providers"
				exact
				path={`${industryBasePath}/industryAccount/dataProviders`}
				component={IppDataProviders}
			/>
			<PrivateRoute
				title="Data Provider Details"
				exact
				path={`${industryBasePath}/industryAccount/dataProviders/new`}
				component={IppDataProviderDetails}
			/>
			<PrivateRoute
				title="Data Provider Details"
				exact
				path={`${industryBasePath}/industryAccount/dataProviders/:dataSourceId`}
				component={IppDataProviderDetails}
			/>
			<PrivateRoute
				title="Parameter Groups"
				exact
				path="/ipp/authority/reportSettings/parameterGroups"
				component={IppReportPackageParameterGroupsComponent}
			/>
			<PrivateRoute
				title="Parameter Groups"
				exact
				path="/ipp/authority/reportSettings/parameterGroups/new"
				component={IppReportPackageParameterGroupDetailsComponent}
			/>
			<PrivateRoute
				title="Parameter Groups"
				exact
				path="/ipp/authority/reportSettings/parameterGroups/:id/details"
				component={IppReportPackageParameterGroupDetailsComponent}
			/>
			<PrivateRoute
				title="Report Packages"
				exact
				path="/ipp/authority/reportPackages/SubmittedPendingReview"
				component={IppPackageSubmittedPendingReviews}
			/>
			<PrivateRoute
				title="Report Packages"
				exact
				path="/ipp/authority/reportPackages/RepudiatedPendingReview"
				component={IppPackageRepudiatedPendingReviews}
			/>
			<PrivateRoute
				title="Report Packages"
				exact
				path="/ipp/authority/reportPackages/All"
				component={IppReportPackagesAllReviews}
			/>
			<PrivateRoute
				title="Report Packages"
				exact
				path="/ipp/authority/reportPackages/ErrorsReview"
				component={IppReportPackagesErrorsReviews}
			/>
			<PrivateRoute
				title="Report Package Review"
				exact
				path="/ipp/authority/reportPackages/:reportPackageId"
				component={IppPackageReviewDetails}
			/>
			<PrivateRoute
				title="Sample Flow Templates"
				exact
				path="/ipp/authority/sample-import-templates"
				component={IppSampleImportTemplatesList}
			/>
			<PrivateRoute
				title="Unit Translations"
				exact
				path="/ipp/authority/unit-translations"
				component={IppUnitTranslationsList}
			/>
			<PrivateRoute
				title="Pending Registration"
				exact
				path={`${industryBasePath}/industryAccount/pendingRegistrations`}
				component={IppPendingRegistrationsList}
			/>
			<PrivateRoute
				title="Pending Registration - User Details"
				exact
				path={`${industryBasePath}/industryAccount/pendingRegistrations/users/:userId`}
				component={IppPendingRegistrationUser}
			/>
			<PrivateRoute
				title="Translations"
				exact
				path={`${industryBasePath}/industryAccount/dataProviders/:dataSourceId/translations`}
				component={Translations}
			/>
			<PrivateRoute
				title="Samples Draft"
				exact
				path={`${industryBasePath}/samples/draft`}
				component={IppIndustrySamplesComponent}
			/>
			<PrivateRoute
				title="Import Samples"
				exact
				path={`${industryBasePath}/samples/import`}
				component={IppIndustrySampleImportComponent}
			/>
			<PrivateRoute
				title="New Sample Step 1"
				exact
				path={`${industryBasePath}/samples/new/step1`}
				component={IppIndustrySampleStep1}
			/>
			<PrivateRoute
				title="New Sample Step 2"
				exact
				path={`${industryBasePath}/samples/new/step2`}
				component={IppIndustrySampleStep2}
			/>
			<PrivateRoute
				title="Sample Details"
				exact
				path={`${industryBasePath}/samples/:sampleId/details`}
				component={IppIndustrySampleStep2}
			/>
			<PrivateRoute
				title="Samples - Ready To Report"
				exact
				path={`${industryBasePath}/samples/ReadyToReport`}
				component={IppIndustrySamplesReadyToReportComponent}
			/>
			<PrivateRoute
				title="Samples - Reported"
				exact
				path={`${industryBasePath}/samples/reported`}
				component={IppIndustrySamplesReportedComponent}
			/>
			<PrivateRoute
				title="Attachments"
				exact
				path={`${industryBasePath}/attachment`}
				component={IppAttachments}
			/>
			<PrivateRoute
				title="AttachmentDetails"
				exact
				path={`${industryBasePath}/attachment/:industryAttachmentId`}
				component={IppAttachmentDetails}
			/>
			<PrivateRoute
				title="Report Packages - Draft"
				exact
				path={`${industryBasePath}/reportPackages/draft`}
				component={IppDraftReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Ready to Submit"
				exact
				path={`${industryBasePath}/reportPackages/readyToSubmit`}
				component={IppReadyToSubmitReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Pending Acceptance"
				exact
				path={`${industryBasePath}/reportPackages/pendingAcceptance`}
				component={IppPendingAcceptanceReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Submitted"
				exact
				path={`${industryBasePath}/reportPackages/submitted`}
				component={IppSubmittedReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Rejected"
				exact
				path={`${industryBasePath}/reportPackages/rejected`}
				component={IppRejectedReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Repudiated"
				exact
				path={`${industryBasePath}/reportPackages/repudiated`}
				component={IppRepudiatedReportPackage}
			/>
			<PrivateRoute
				title="Report Packages - Errors"
				exact
				path={`${industryBasePath}/reportPackages/errors`}
				component={IppErrorsReportPackage}
			/>
			<PrivateRoute
				title="Report Package Details"
				exact
				path={`${industryBasePath}/reportPackage/new`}
				component={IppReportPackageStep1}
			/>
			<PrivateRoute
				title="Report Package Details"
				exact
				path={`${industryBasePath}/reportPackage/:reportPackageId`}
				component={IppPackageDetails}
			/>
			<PrivateRoute
				title="Report Package Details"
				exact
				path={`${industryBasePath}/reportPackages/scheduled`}
				component={IppScheduledReportPackage}
			/>
			{/* Hauler Portal Routes */}
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilities')}
				exact
				path={`${haulerBasePath}/facilities`}
				component={HaulerFacilities}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilityDetail')}
				exact
				path={`${haulerBasePath}/facility/:id`}
				component={HaulerFacilityDetail}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerSelectFacility')}
				exact
				path={`${haulerBasePath}/facilities/select`}
				component={HaulerSelectFacilities}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.invitation')}
				exact
				path={`/hauler/invite`}
				component={InputInvitationCode}
			/>
			<PrivateRoute
				title="Settings"
				exact
				path={`${haulerBasePath}/settings`}
				component={HaulerPortalSettingsLandingPage}
			/>
			<PrivateRoute
				title="Settings - Business Information"
				exact
				path={`${haulerBasePath}/settings/businessInformation`}
				component={BusinessInformation}
			/>
			<PrivateRoute
				title="Settings - Hauler Users"
				exact
				path={`${haulerBasePath}/settings/users`}
				component={HaulerPortalUsersGrid}
			/>
			<PrivateRoute
				title="Settings - Hauler Authorities"
				exact
				path={`${haulerBasePath}/settings/authorities`}
				component={HaulerPortalAuthorities}
			/>
			<PrivateRoute
				title="Manage access - Hauler "
				exact
				path={'/fog/hauler/:haulerId/manageAccess'}
				component={HaulerPortalUsersGrid}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerCleaningHistory')}
				exact
				path={`${haulerBasePath}/cleaningHistory`}
				component={HaulerCleaningHistory}
			/>

			{/*Facility Portal*/}
			<PrivateRoute
				title="Manage access - Facility "
				exact
				path={'/fog/facility/:fogFacilityId/manageAccess'}
				component={FacilityPortalUsersGrid}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilities')}
				exact
				path={`${facilityPortalBasePath}/devices`}
				component={FacilityDevices}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.haulerFacilities')}
				exact
				path={`${facilityPortalBasePath}/cleaninghistory`}
				component={FacilityCleaningHistories}
			/>
			<PrivateRoute
				title="Settings"
				exact
				path={`${facilityPortalBasePath}/settings`}
				component={FacilityPortalSettingsLandingPage}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.facilityPortalSettingsFacilities')}
				exact
				path={`${facilityPortalBasePath}/settings/facilities`}
				component={FacilitySettings}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.facilityPortalSettingsHauler')}
				exact
				path={`${facilityPortalBasePath}/settings/hauler`}
				component={FacilityPortalSettingHauler}
			/>
			<PrivateRoute
				title="Settings - Facility Users"
				exact
				path={`${facilityPortalBasePath}/settings/users`}
				component={FacilityPortalUsersGrid}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.invitation')}
				exact
				path={`/facility/invite`}
				component={InputInvitationCode}
			/>

			{/* Notices Routes */}
			<PrivateRoute title="Notices" exact path="/fog/settings/notices" component={AuthoritySettingsNotices} />
			<PrivateRoute
				title="Cleaning Notice"
				exact
				path="/fog/settings/notices/cleaningNotice"
				component={CleaningNoticeStepper}
			/>
			<PrivateRoute
				title="Cleaning Notice"
				exact
				path="/fog/settings/notices/cleaningNotice/:noticeTemplateId"
				component={CleaningNoticeStepper}
			/>
			<PrivateRoute
				title="General Notice"
				exact
				path="/fog/settings/notices/generalNotice"
				component={GeneralNoticeStepper}
			/>
			<PrivateRoute
				title="General Notice"
				exact
				path="/fog/settings/notices/generalNotice/:noticeTemplateId"
				component={GeneralNoticeStepper}
			/>
			{/* Events Routes */}
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.events')}
				exact
				path="/fog/settings/events"
				component={EventsSettingComponent}
			/>
			<PrivateRoute
				title={localizationService.getLocalizedString('screen.labels.events')}
				exact
				path="/fog/events"
				component={EventsGrid}
			/>

			{/*NotFound*/}
			<PrivateRoute title="Page Not Found" component={NotFound} />
		</Switch>
	);
};
