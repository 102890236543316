import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SingleCheckbox, TextInput } from 'src/components/widgets';
import { ApplicationState, useReduxSelector } from 'src/redux';
import { InviteUserState, searchEmailAddress } from 'src/redux/ipp/authority/authority-account/pending-invitations';
import * as LinkoTypes from '@rcp/types';
import { localizationService, tokenService, urlService, validationService } from 'src/services';
import _ from 'lodash';
import { ippPendingInvitationSlice } from './pending-invitation.slice';
import { IppConstants } from 'src/constants';
import { PageLoadingContainer } from 'src/components/layout/page-loading-container';

interface Props extends DispatchProps, InviteUserState {
	showModal: boolean;
	onCancelButtonClick: (reRender: boolean, e?: any) => void;
	okayButtonText?: string;
	isIndustryUserModule: () => boolean;
	industryData?: LinkoTypes.IppIndustry;
}

interface DispatchProps {
	searchEmailAddress: (userInvite: LinkoTypes.IppUserInvite) => Promise<boolean>;
	sendInvitation: (
		userInvite: LinkoTypes.IppUserInvite,
		reloadGrid?: boolean,
		successMessage?: string,
		callbackOnSuccess?: () => void
	) => void;
}

interface Error {
	emailError?: string;
	firstNameError?: string;
	lastNameError?: string;
}

interface State {
	userInvite: LinkoTypes.IppUserInvite;
	isEmailSearched: boolean;
	error: Error;
	isDataChanging: boolean;
}

const { fieldCharLimit } = IppConstants;

export const InviteModal: FC<Props> = props => {
	const [state, setState] = useState<State>({
		userInvite: {} as LinkoTypes.IppUserInvite,
		isEmailSearched: false,
		error: {} as Error,
		isDataChanging: false
	});

	const withAqiIdentityProvider = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[LinkoTypes.FeatureNames.WithAqiIdentityProvider]
	);
	const withAqiIdentityProviderForTheAuthority = urlService.isIppIndustryPortal()
		? !_.isEmpty(tokenService.getTokenOrDefault().aqiIdpTenantId)
		: withAqiIdentityProvider && !_.isEmpty(tokenService.getTokenOrDefault().aqiIdpTenantId);

	useEffect(() => {
		if (state.isEmailSearched && props.userInvitation && !state.isDataChanging) {
			let newState = { ...state, userInvite: { ...props.userInvitation } };
			setState(newState);
		}
	}, [props.userInvitation, state.isEmailSearched, state.isDataChanging]);

	const toggleModal = (reRender: boolean, e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		resetState();
		props.onCancelButtonClick(reRender);
	};

	const resetState = () => {
		setState({
			...state,
			userInvite: {} as LinkoTypes.IppUserInvite,
			isEmailSearched: false,
			error: {} as Error
		});
	};

	const searchEmail = () => {
		let userInvite = { ...state.userInvite };
		let dataToValidate = { ...userInvite, ...state.error };
		let isEmail = userInvite.emailAddress
			? validationService.validateEmailFormatField(dataToValidate, 'emailAddress', 'emailError')
			: validationService.validateRequiredField(dataToValidate, 'emailAddress', 'emailError');
		if (isEmail) {
			delete userInvite.firstName;
			delete userInvite.lastName;
			(props.isIndustryUserModule() || urlService.isIppIndustryPortal()) &&
				props.industryData &&
				(userInvite.recipientOrganizationRegulatoryProgramId = props.industryData.ippIndustryId);
			let isEmailSearched = props.searchEmailAddress(userInvite);
			isEmailSearched
				.then((response: boolean) => {
					response === true &&
						setState({ ...state, isEmailSearched: true, error: {} as Error, isDataChanging: false });
				})
				.catch(error => {
					return error;
				});
		} else {
			setState({
				...state,
				error: { ...state.error, emailError: dataToValidate.emailError }
			});
		}
	};

	const hasAqiSsoAccountInvitation = (invite: LinkoTypes.IppUserInvite) => {
		return invite.isAqiIdpAccountInvitation === true && invite.hasPendingInvitation === true;
	};
	const isAqiSsoAccount = (invite: LinkoTypes.IppUserInvite) => {
		return !_.isEmpty(invite.aqiIdpUserId);
	};

	const changeHandler = (event: any) => {
		let { name, value } = event.target;
		if (event.target.type === 'checkbox') {
			value = event.target.checked;
		}
		setState({
			...state,
			userInvite: {
				...state.userInvite,
				[name]: value
			},
			isDataChanging: true
		});
	};

	const sendInvite = () => {
		let invitationDetails = { ...state.userInvite, ...state.error };
		let isFirstNameValid = validationService.validateRequiredField(
			invitationDetails,
			'firstName',
			'firstNameError'
		);
		let isLastNameValid = validationService.validateRequiredField(invitationDetails, 'lastName', 'lastNameError');
		if (isFirstNameValid && isLastNameValid) {
			let invitationPayload: LinkoTypes.IppUserInvite = _.pick(state.userInvite, [
				'emailAddress',
				'firstName',
				'lastName',
				'invitationType',
				'recipientOrganizationRegulatoryProgramId',
				'accountBaseUrl'
			]);
			if (withAqiIdentityProviderForTheAuthority && state.userInvite.shouldInviteAsAqiIdpUser === true) {
				invitationPayload.aqiIdpTenantId = tokenService.getTokenOrDefault().aqiIdpTenantId;
				invitationPayload.shouldInviteAsAqiIdpUser = true;
			}
			if ((props.isIndustryUserModule() || urlService.isIppIndustryPortal()) && props.industryData) {
				invitationPayload.recipientOrganizationRegulatoryProgramId = props.industryData.ippIndustryId;
				invitationPayload.invitationType = urlService.isIppIndustryPortal()
					? 'IndustryToIndustry'
					: 'AuthorityToIndustry';
			} else {
				delete invitationPayload.recipientOrganizationRegulatoryProgramId;
				invitationPayload.invitationType = 'AuthorityToAuthority';
			}
			invitationPayload.accountBaseUrl = urlService
				.getApiAccountBaseUrl()
				.split('/')[2]
				.replace('5000', '3000');
			props.sendInvitation(
				invitationPayload,
				true,
				localizationService.getLocalizedString('ipp.authorityAccount.inviteSuccessMessage'),
				() => toggleModal(true)
			);
		} else {
			setState({
				...state,
				error: {
					...state.error,
					firstNameError: invitationDetails.firstNameError || '',
					lastNameError: invitationDetails.lastNameError || ''
				}
			});
		}
	};

	let alertMessageAfterSearch: string = '';
	if (state.isEmailSearched && state.userInvite.isAcpConnectorUser) {
		alertMessageAfterSearch = localizationService.getLocalizedString('ipp.authorityAccount.inviteAcpUserMessage');
	} else if (state.isEmailSearched && state.userInvite.isUserActiveInSameProgram) {
		alertMessageAfterSearch = localizationService.getLocalizedString('ipp.authorityAccount.inviteErrorMessage');
	} else if (state.isEmailSearched && hasAqiSsoAccountInvitation(state.userInvite)) {
		alertMessageAfterSearch = localizationService.getLocalizedString(
			'ipp.authorityAccount.hasAqiIdpAccountPendingInvitation'
		);
	} else if (state.isEmailSearched && !state.userInvite.isUserActiveInSystem) {
		alertMessageAfterSearch = localizationService.getLocalizedString('ipp.authorityAccount.inviteUserMessage');
	} else if (
		!state.userInvite.isUserActiveInSameProgram &&
		state.isEmailSearched &&
		state.userInvite.isUserActiveInSystem
	) {
		if (withAqiIdentityProviderForTheAuthority && isAqiSsoAccount(state.userInvite)) {
			alertMessageAfterSearch = localizationService.getLocalizedString(
				'ipp.authorityAccount.aqiIdpUserNotInSameProgram'
			);
		} else {
			alertMessageAfterSearch = localizationService.getLocalizedString(
				'ipp.authorityAccount.userNotInSameProgram'
			);
		}
	} else {
		alertMessageAfterSearch = '';
	}
	return (
		<Modal
			isOpen={props.showModal}
			toggle={(e?: any) => toggleModal(false, e)}
			centered={true}
			scrollable={false}
			backdrop={'static'}
			keyboard={false}>
			<PageLoadingContainer />
			<ModalHeader tag="h1" toggle={(e?: any) => toggleModal(false, e)}>
				{localizationService.getLocalizedString(
					`${
						!state.isEmailSearched
							? 'ipp.authorityAccount.beforeSearchEmailAddressHeading'
							: 'ipp.authorityAccount.afterSearchEmailAddressHeading'
					}`
				)}
			</ModalHeader>
			<ModalBody>
				<p>{alertMessageAfterSearch}</p>
				<div className="form-row">
					<TextInput
						className="mx-auto w-100"
						id="email"
						name="emailAddress"
						label={localizationService.getLocalizedString(
							'ipp.authorityAccount.pendingInvitationsColumns.emailAddress'
						)}
						value={state.userInvite.emailAddress}
						onChange={(event: any) => {
							changeHandler(event);
						}}
						isRequired={true}
						error={state.error.emailError}
						isDisabled={state.isEmailSearched}
						remainingInputProps={{ maxLength: fieldCharLimit.email }}
					/>
				</div>
				{state.isEmailSearched ? (
					<>
						<div className="form-row">
							<TextInput
								className="mx-auto w-100"
								id="firstName"
								name="firstName"
								label={localizationService.getLocalizedString(
									'ipp.authorityAccount.pendingInvitationsColumns.firstName'
								)}
								autoComplete="off"
								value={state.userInvite.firstName}
								isDisabled={
									state.userInvite.isUserActiveInSystem ||
									hasAqiSsoAccountInvitation(state.userInvite)
								}
								onChange={changeHandler}
								isRequired={true}
								error={state.error.firstNameError}
								remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								className="mx-auto w-100"
								id="lastName"
								name="lastName"
								autoComplete="off"
								label={localizationService.getLocalizedString(
									'ipp.authorityAccount.pendingInvitationsColumns.lastName'
								)}
								value={state.userInvite.lastName}
								isDisabled={
									state.userInvite.isUserActiveInSystem ||
									hasAqiSsoAccountInvitation(state.userInvite)
								}
								onChange={changeHandler}
								isRequired={true}
								error={state.error.lastNameError}
								remainingInputProps={{ maxLength: fieldCharLimit.user.shortInfo }}
							/>
						</div>
						{withAqiIdentityProviderForTheAuthority &&
							tokenService.isSsoFromAqi() &&
							!(
								state.userInvite.isUserActiveInSystem ||
								state.userInvite.isAcpConnectorUser ||
								(state.userInvite.hasPendingInvitation && !state.userInvite.isAqiIdpAccountInvitation)
							) && (
								<SingleCheckbox
									id="shouldInviteAsAqiIdpUser"
									name="shouldInviteAsAqiIdpUser"
									label={localizationService.getLocalizedString(
										'ipp.authorityAccount.inviteAsAqiIdpUser'
									)}
									isDisabled={hasAqiSsoAccountInvitation(state.userInvite)}
									checked={
										state.userInvite.shouldInviteAsAqiIdpUser === true ||
										hasAqiSsoAccountInvitation(state.userInvite)
									}
									onChange={changeHandler}
									className="form-row mb-4"
								/>
							)}
					</>
				) : null}
				<p>
					{state.isEmailSearched &&
						!state.userInvite.isUserActiveInSameProgram &&
						!state.userInvite.isAcpConnectorUser &&
						!hasAqiSsoAccountInvitation(state.userInvite) &&
						`${localizationService.getLocalizedString('ipp.authorityAccount.confirmInviteUser')} "${
							(props.isIndustryUserModule() || urlService.isIppIndustryPortal()) && props.industryData
								? props.industryData.industryName
								: tokenService.getTokenOrDefault().portalOrganizationName
						}" ${localizationService.getLocalizedString(
							`ipp.${
								(props.isIndustryUserModule() || urlService.isIppIndustryPortal()) && props.industryData
									? 'industries.industryPortalMessage'
									: 'authorityAccount.authorityPortalMessage'
							}`
						)}`}
				</p>
			</ModalBody>
			<ModalFooter className="pt-0 ml-auto">
				{!state.isEmailSearched && (
					<button className={`btn ai-action`} id="okayBtn" onClick={() => searchEmail()}>
						{localizationService.getLocalizedString('ipp.buttons.search')}
					</button>
				)}
				{state.userInvite.isUserActiveInSameProgram ||
				state.userInvite.isAcpConnectorUser ||
				hasAqiSsoAccountInvitation(state.userInvite)
					? null
					: state.isEmailSearched && (
							<button className={`btn ai-action`} id="okayBtn" onClick={() => sendInvite()}>
								{localizationService.getLocalizedString('ipp.buttons.send')}
							</button>
					  )}
				<button className="btn ai-white" id="cancelBtn" onClick={(e?: any) => toggleModal(false, e)}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</ModalFooter>
		</Modal>
	);
};

const mapStateToProps = (state: ApplicationState): InviteUserState => {
	return { ...state.ippInviteUser };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		searchEmailAddress: (userInvite: LinkoTypes.IppUserInvite) => dispatch(searchEmailAddress(userInvite)),
		sendInvitation: (
			userInvite: LinkoTypes.IppUserInvite,
			reloadGrid?: boolean,
			successMessage?: string,
			callbackOnSuccess?: () => void
		) =>
			dispatch(
				ippPendingInvitationSlice.createOne(userInvite, reloadGrid || false, successMessage, callbackOnSuccess)
			)
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteModal);
