import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState, FogFacilityState } from 'src/redux';
import { TabViewField } from 'src/components/widgets';
import { AccountModal } from './facility-account-modal';
import { localizationService } from 'src/services';

interface OwnProps {}

interface StateProps extends FogFacilityState {}

type Props = StateProps & OwnProps;

const AccountTabComp: React.SFC<Props> = props => {
	const [isToggle, setToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setToggle(true);
	};

	const toggle = () => {
		setToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('facility.inBusinessSince')}
						value={props.facility.inBusinessSince}
						isDate={true}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.closedDate')}
						value={props.facility.closedDate}
						isDate={true}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.sewerNumber')}
						value={props.facility.sewerNumber}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.accountNumber')}
						value={props.facility.accountNumber}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.meterNumber')}
						value={props.facility.meterNumber}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.assignedTo')}
						value={props.facility.assignedTo}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						Edit
					</a>
				</div>
			</div>
			<div>{isToggle && <AccountModal isToggle={isToggle} toggle={toggle} />}</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

export const AccountTab = connect<StateProps, {}, OwnProps, ApplicationState>(mapStateToProps)(AccountTabComp);
