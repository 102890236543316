import React, { ReactElement, useRef } from 'react';

import { localizationService, Logger } from 'src/services';
import { SingleCheckbox } from '../single-checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { CloseSvg } from '../../svg';
import '../widgets.scss';
import _ from 'lodash';
import { DeleteModalProp, DeleteModal, TextInput } from '..';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { alertService } from 'src/redux';
import { InlineEditProps, InlineChildTextInput } from '../inline-child-text-input';
import { LookupRow } from './lookup-types';
import { SettingActionHooks } from './setting-action-hooks';
import { ReactComponent as Copy } from 'src/assets/img/copy.svg';
import { DropDownOption, scheduleDueDateTimeOptions } from '@rcp/types';

interface Props {
	id: string;
	name: string;
	label: string;
	error?: string;
	editDescription?: boolean;
	showDescriptionColum?: boolean;
	showScheduleColumn?: boolean;
	embeddedMode?: boolean;
	settingAction: SettingActionHooks;
	hideActiveStatus?: boolean;
	lookupState?: LookupState;
	onChange?(text: string, field?: string): void;

	onBlur?(text: string, field?: string): void;

	onEnterKeyPressed?(text: string, field?: string): void;

	onAddButtonClick?(lookups: LookupRow[]): void;

	onAddLookupEditChanged?(e: any): void;

	onEditButtonClick?(id?: any): void;

	onCopyIconButtonClick?(id?: any, existingRows?: LookupRow[]): void;

	onDeleteButtonClick?(): void;

	className?: string;

	hideAddButton?: boolean;
	descriptionLabel?: string;

	hideAddModal?: boolean;
	hideEditModal?: boolean;
	disableRemoval?: boolean;
	maxNumberOfKey?: number;
	editorModal?: JSX.Element;
	deleteMessage?: (lookup: LookupRow) => string;
	deleteSucceedMessage?: (lookup: LookupRow) => string;
	updateSucceedMessage?: (lookup: LookupRow) => string;
	itemRenderer?: (props: any) => ReactElement;
	isCustomSort?: boolean;
	editInputClass?: string;
	reloadGrid?: boolean;
	disableClientSideSorting?: boolean;
	showCopy?: boolean;

	overridenEditModalTitle?: string;
	overridenEditModalLabel?: string;
}

export interface LookupState {
	lookupRow: LookupRow;
	newLookupRow: LookupRow;
	showModal: boolean;
}

export const LookupsWidget: React.FC<Props> = props => {
	const [newLookupToAdd, setNewLookupToAdd] = React.useState({} as LookupRow);
	const [lookups, setLookups] = React.useState([] as LookupRow[]);
	const [lookupsSortApiList, setLookupsSortApiList] = React.useState([] as LookupRow[]);
	const [currentLookup, setCurrentLookup] = React.useState({} as LookupRow);
	const [showEditModal, setShowEditModal] = React.useState(false);
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);

	React.useEffect(() => {
		getList();
	}, [props.settingAction, props.reloadGrid]);

	React.useEffect(() => {
		if (!props.lookupState) {
			setCurrentLookup({} as LookupRow);
			setNewLookupToAdd({} as LookupRow);
		} else {
			setCurrentLookup({ ...props.lookupState.lookupRow });
			setNewLookupToAdd({ ...props.lookupState.newLookupRow });
			setShowEditModal(props.lookupState.showModal);
		}
	}, [props.lookupState]);

	const getList = () => {
		props.settingAction.get().then(rows => {
			sortLookUpPositionWise(rows);
		});
	};

	const sortLookUpPositionWise = (rows: LookupRow[]) => {
		let sortedRows: LookupRow[] = [];
		if (!props.isCustomSort) {
			!props.disableClientSideSorting && rows.sort(sortLookup);
			sortedRows = rows;
		} else {
			const positionalRows = rows.filter((row: LookupRow) => {
				return _.toNumber(row.position) > 0;
			});
			const otherRows = rows.filter((row: LookupRow) => {
				return _.toNumber(row.position) <= 0;
			});
			positionalRows.sort((a: LookupRow, b: LookupRow) => {
				return _.toNumber(a.position) - _.toNumber(b.position);
			});
			otherRows.sort(sortLookup);
			positionalRows.push(...otherRows);
			sortedRows = positionalRows;
		}
		setLookups(sortedRows);
	};

	const closeBtn = (
		<button className="close" onClick={() => onToggleEditModel()}>
			<CloseSvg />
		</button>
	);

	const changeValueNewLookUp = (e: any) => {
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const handleKeyPressedInNewLookUp = (e: any) => {
		if (e.key === 'Enter') {
			changeValueNewLookUp(e);
		}
		if (e.key === 'Escape') {
			e.target.blur();
		}
	};

	const handleOnBlurInNewLookUp = (e: any) => {
		changeValueNewLookUp(e);
		if (props.onBlur) {
			props.onBlur(e);
		}
	};

	const handleNewLookUpChanged = (e: any) => {
		if (props.onAddLookupEditChanged) {
			props.onAddLookupEditChanged(e);
		}

		let { name, value } = e.target;
		var lookup = { ...newLookupToAdd };
		_.set(lookup, name, value);

		setNewLookupToAdd(lookup);
		isDuplicatedWithExisting(lookup);
	};

	const onCurrentLookupChanged = (e: any) => {
		let { value, name } = e.target;
		var lookup = { ...currentLookup };

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		_.set(lookup, name, value);
		setCurrentLookup(lookup);
		isDuplicatedWithExisting(lookup);
	};

	const onCurrentLookupDescriptionChanged = (e: any) => {
		let { value, name } = e.target;
		var lookup = { ...currentLookup };
		_.set(lookup, name, value);
		setCurrentLookup(lookup);
	};

	const onLookupIsActiveChanged = async (e: any, lookup: LookupRow) => {
		let { value, name } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		let oldValue = lookup.code;

		_.set(lookup, name, value);
		try {
			let newValue = await props.settingAction.update(lookups, lookup);
			setLookups(newValue);
			let message = localizationService.getLocalizedString(
				'authoritySetting.activatedMessage',
				_.toString(oldValue)
			);
			if (!value) {
				message = localizationService.getLocalizedString(
					'authoritySetting.deactivatedMessage',
					_.toString(oldValue)
				);
			}
			alertService.addSuccess(message);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const sortLookup = (a: LookupRow, b: LookupRow) => {
		if (_.toString(a.code).toLowerCase() < _.toString(b.code).toLowerCase()) {
			return -1;
		} else if (_.toString(a.code).toLowerCase() === _.toString(b.code).toLowerCase()) {
			return 0;
		}
		return 1;
	};

	const onAddButtonClick = async () => {
		if (!props.hideAddModal) {
			var isNewCodeEmpty = isCodeEmpty(newLookupToAdd);
			if (isNewCodeEmpty) {
				var errorText = localizationService.getLocalizedString('authoritySetting.codeCanNotBeEmpty');
				alertService.addError(errorText);
				var newLookupToAddWithError = { ...newLookupToAdd };
				newLookupToAddWithError.error = errorText;
				setNewLookupToAdd(newLookupToAddWithError);
				return;
			}

			let lookup = findDuplicated(newLookupToAdd);
			if (lookup) {
				const error = localizationService.getLocalizedString(
					'authoritySetting.lookupValueError',
					_.toLower(props.label)
				);
				alertService.addError(error);
				newLookupToAdd.error = error;
				setNewLookupToAdd({ ...newLookupToAdd });
				return;
			}

			setCurrentLookup({ ...newLookupToAdd });
			setNewLookupToAdd({ ...newLookupToAdd });
		}

		if (props.onAddButtonClick) {
			props.onAddButtonClick(lookups);
		}

		if (!props.hideAddModal) {
			AddOneNewSettingValue();
		}
	};

	const AddOneNewSettingValue = async () => {
		try {
			let createdLookup = await props.settingAction.add(lookups, newLookupToAdd);
			const message = localizationService.getLocalizedString(
				'authoritySetting.addSucceedMessage',
				_.toLower(props.label)
			);
			alertService.addSuccess(message);
			let lookupRow = {
				lookupId: createdLookup.lookupId ? createdLookup.lookupId : lookups.length,
				code: newLookupToAdd.code,
				codeDescription: newLookupToAdd.codeDescription,
				isActive: true,
				isInUse: createdLookup.isInUse
			} as LookupRow;
			let currentLookups = lookups.slice();
			currentLookups.push(lookupRow);
			sortLookUpPositionWise(currentLookups);
			setLookups(currentLookups);
			setNewLookupToAdd({} as LookupRow);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const onDeleteIconButtonClick = (lookupToDelete: LookupRow) => {
		if (!lookupToDelete.code) {
			return;
		}

		setCurrentLookup(lookupToDelete);
		setShowDeleteModal(true);
	};

	const onEditIconButtonClick = (lookupToEdit: LookupRow) => {
		if (!props.hideEditModal) {
			setCurrentLookup(lookupToEdit);
			setShowEditModal(true);
		}

		if (props.onEditButtonClick) {
			props.onEditButtonClick(lookupToEdit.lookupId);
		}
	};

	const onCopyIconButtonClick = (lookupToCopy: LookupRow) => {
		if (props.onCopyIconButtonClick) {
			props.onCopyIconButtonClick(lookupToCopy.lookupId, lookups);
		}
	};

	const onRemoveBtnFromDeleteModalClicked = async () => {
		if (props.onDeleteButtonClick) {
			props.onDeleteButtonClick();
		}

		try {
			let newValues = await props.settingAction.delete(lookups, currentLookup);
			sortLookUpPositionWise(newValues);
			setLookups(newValues);
			setCurrentLookup({} as LookupRow);

			const message = props.deleteSucceedMessage
				? props.deleteSucceedMessage(currentLookup)
				: localizationService.getLocalizedString(
						'authoritySetting.deleteSucceedMessage',
						_.toLower(props.label),
						_.toString(currentLookup.code)
				  );
			alertService.addSuccess(message);
		} catch (ex) {
			alertService.addError(ex.message);
		} finally {
			setShowDeleteModal(false);
		}
	};

	const updateOneLookupValue = async () => {
		let newValues = await props.settingAction.update(lookups, currentLookup);
		sortLookUpPositionWise(newValues);
		setLookups(newValues);
		const message = props.updateSucceedMessage
			? props.updateSucceedMessage(currentLookup)
			: localizationService.getLocalizedString(
					'authoritySetting.updateSucceedMessage',
					_.toLower(props.label),
					_.toString(currentLookup.code)
			  );
		alertService.addSuccess(message);
	};

	const onSaveBtnFromEditModelClicked = async () => {
		if (props.onEditButtonClick) {
			props.onEditButtonClick();
		}

		let errorText: string = '';

		if (isCodeEmpty(currentLookup)) {
			errorText = localizationService.getLocalizedString('authoritySetting.codeCanNotBeEmpty');
		}

		if (isDuplicatedWithExisting(currentLookup)) {
			errorText = localizationService.getLocalizedString(
				'authoritySetting.lookupValueError',
				_.toLower(props.label)
			);
		}

		if (errorText) {
			alertService.addError(errorText);
			var currentLookupWithError = { ...currentLookup };
			currentLookupWithError.error = errorText;
			setCurrentLookup(currentLookupWithError);
			return;
		}

		setCurrentLookup({ ...currentLookup });
		try {
			await updateOneLookupValue();
			let newLookups = lookups.slice(0);
			for (let lp of lookups) {
				if (lp.lookupId === currentLookup.lookupId) {
					lp.code = currentLookup.code;
					lp.codeDescription = currentLookup.codeDescription;
					lp.isActive = currentLookup.isActive;
					lp.isInUse = currentLookup.isInUse;
					break;
				}
			}

			setLookups(newLookups);
		} catch (ex) {
			alertService.addError(ex.message);
		} finally {
			onToggleEditModel();
		}
	};

	const discardEditLookup = () => {
		setShowEditModal(false);
		setCurrentLookup({} as LookupRow);
		setNewLookupToAdd({} as LookupRow);
	};

	const onToggleEditModel = () => {
		discardEditLookup();
	};

	const isDuplicatedWithExisting = (lookup: LookupRow): boolean => {
		let duplicated = findDuplicated(lookup);
		if (duplicated) {
			return true;
		}
		lookup.error = '';
		return false;
	};

	const findDuplicated = (lookup: LookupRow): LookupRow | undefined => {
		let searchFunc = (i: LookupRow): boolean => {
			return (
				_.toString(i.code).toLowerCase() === _.toString(lookup.code).toLowerCase() &&
				!_.eq(i.lookupId, lookup.lookupId)
			);
		};

		return lookups.find(searchFunc);
	};

	const isCodeEmpty = (lookup: LookupRow): boolean => {
		if (_.toString(lookup.code) === '' || _.toString(lookup.code).trim() === '') {
			lookup.error = localizationService.getLocalizedString('authoritySetting.codeCanNotBeEmpty');
			return true;
		}

		return false;
	};

	const getRemoveLookupTypeModalProps = () => {
		let deleteTitle = localizationService.getLocalizedString(
			'authoritySetting.deleteModalTitle',
			_.toLower(props.label)
		);

		let message = '';
		if (props.deleteMessage) {
			message = props.deleteMessage(currentLookup);
		}

		if (_.isEmpty(message)) {
			message = localizationService.getLocalizedString(
				'authoritySetting.deleteModalMessage',
				_.toString(currentLookup.code),
				_.toLower(props.label)
			);
		}

		const removeLookupTypeModalProp: DeleteModalProp = {
			title: deleteTitle,
			message: message,
			isDeleteButton: true,
			showModal: showDeleteModal,
			onCancelButtonClick: () => {
				setShowDeleteModal(false);
			},
			onOkayButtonClick: onRemoveBtnFromDeleteModalClicked,
			okayButtonText: localizationService.getLocalizedString('screen.buttons.delete')
		};

		return removeLookupTypeModalProp;
	};

	const editLookupTypeModal = function() {
		if (props.editorModal) {
			return props.editorModal;
		}
		let title = props.overridenEditModalTitle
			? props.overridenEditModalTitle
			: localizationService.getLocalizedString('authoritySetting.editModalTitle', _.toLower(props.label));
		let widthClass = props.editDescription ? 'form-group col-sm-6' : 'form-group';
		return (
			<Modal isOpen={showEditModal} size="sm" centered={true} scrollable={false}>
				<ModalHeader tag="h1" toggle={onToggleEditModel} close={closeBtn}>
					{title}
				</ModalHeader>
				<ModalBody>
					{props.editDescription ? (
						<div className="form-row">
							<TextInput
								id="code"
								name="code"
								className={widthClass}
								isRequired={true}
								label={props.label}
								value={currentLookup.code}
								onBlur={onCurrentLookupChanged}
								onEnterKeyPressed={onCurrentLookupChanged}
								onChange={onCurrentLookupChanged}
								error={currentLookup.error}
							/>
							<TextInput
								id="codeDescription"
								name="codeDescription"
								className={widthClass}
								label={localizationService.getLocalizedString('authoritySetting.description')}
								value={currentLookup.codeDescription}
								onBlur={onCurrentLookupDescriptionChanged}
								onEnterKeyPressed={onCurrentLookupDescriptionChanged}
								onChange={onCurrentLookupDescriptionChanged}
							/>
						</div>
					) : (
						<TextInput
							id="code"
							name="code"
							isRequired={true}
							className={props.editInputClass}
							label={props.overridenEditModalLabel ? props.overridenEditModalLabel : props.label}
							value={currentLookup.code}
							onBlur={onCurrentLookupChanged}
							onEnterKeyPressed={onCurrentLookupChanged}
							onChange={onCurrentLookupChanged}
							error={currentLookup.error}
						/>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color="btn ai-save" id="saveBtn" onClick={onSaveBtnFromEditModelClicked}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</Button>
					<Button color="btn ai-white" id="cancelBtn" onClick={discardEditLookup}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	};

	const getInlineEditProps = () => {
		let editProps = [
			{
				id: `code`,
				name: `code`,
				className: `mr-sm-1 mb-2`,
				label: props.label,
				value: newLookupToAdd.code || '',
				isRequired: true,
				onBlur: handleOnBlurInNewLookUp,
				onChange: handleNewLookUpChanged,
				onEnterKeyPressed: handleKeyPressedInNewLookUp,
				error: newLookupToAdd.error
			} as InlineEditProps
		];
		if (props.editDescription) {
			editProps.push({
				id: `codeDescription`,
				name: `codeDescription`,
				className: 'ml-sm-1 mr-sm-1',
				label: localizationService.getLocalizedString('authoritySetting.description'),
				value: newLookupToAdd.codeDescription || '',
				onBlur: handleOnBlurInNewLookUp,
				onChange: handleNewLookUpChanged,
				onEnterKeyPressed: handleKeyPressedInNewLookUp
			});
		}
		return editProps;
	};

	const getScheduleRowCleaningNoticeData = (lookupRow: LookupRow) => {
		const {
			isScheduledAutomatically,
			isAddedToOutboxOnScheduledDay,
			scheduleDueDateTimeUnitNumber,
			scheduleDueDateTimeUnitName,
			isBeforeDueDate,
			numberOfDaysAfterPreviousNotice
		} = lookupRow.apiData || {};
		let scheduleDueDateTimeUnitNameLabel = scheduleDueDateTimeOptions.filter((scheduleOption: DropDownOption) => {
			return scheduleOption.value === scheduleDueDateTimeUnitName;
		})[0].label;
		return (
			<td>
				{isScheduledAutomatically
					? localizationService.getLocalizedString(
							`authoritySetting.notice.${
								isAddedToOutboxOnScheduledDay ? 'addToOutBox' : 'scheduleAutomatically'
							}`,
							scheduleDueDateTimeUnitNumber + '',
							scheduleDueDateTimeUnitNameLabel,
							localizationService.getLocalizedString(
								`authoritySetting.notice.${isBeforeDueDate ? 'before' : 'after'}`
							),
							numberOfDaysAfterPreviousNotice + ''
					  )
					: ''}
			</td>
		);
	};

	const getLookUpRows = () => {
		return lookups.map((lookupRow, index) => {
			// @ts-ignore
			return (
				<tr className="lookup-row" key={`lookup-row-${index}`}>
					{!props.hideActiveStatus && (
						<td>
							<SingleCheckbox
								id={`${props.id}isActive_${index}`}
								name="isActive"
								key={`${props.id}checkbox${index}`}
								label={''}
								checked={lookupRow.isActive}
								isDisabled={lookupRow.isSystem === true}
								onChange={(e: any) => onLookupIsActiveChanged(e, lookupRow)}
								className="ml-2"
							/>
						</td>
					)}
					<td id={`code_${index}`}>{lookupRow.code}</td>
					{props.showDescriptionColum && <td>{lookupRow.codeDescription}</td>}
					{props.showScheduleColumn && getScheduleRowCleaningNoticeData(lookupRow)}
					{props.showCopy && (
						<td align="right">
							{lookupRow.isSystem !== true && (
								<button
									onClick={() => {
										onCopyIconButtonClick(lookupRow);
									}}>
									<Copy />
								</button>
							)}
						</td>
					)}
					<td align="right">
						{lookupRow.isSystem !== true && (
							<button onClick={() => onEditIconButtonClick(lookupRow)}>
								<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon" />
							</button>
						)}
					</td>
					<td>
						{lookupRow.isSystem !== true && !lookupRow.isInUse && props.disableRemoval !== true && (
							<button onClick={() => onDeleteIconButtonClick(lookupRow)}>
								<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon" />
							</button>
						)}
					</td>
				</tr>
			);
		});
	};

	const getRows = (propsData?: any) => {
		// @ts-ignore
		const { style, attributes, dataItem, forwardRef } = propsData;
		return (
			<tr
				{...attributes}
				style={{
					...style,
					outline: 'none',
					cursor: 'move',
					userSelect: 'none',
					position: 'relative'
				}}
				className={'lookup-row p-2'}
				ref={forwardRef}
				key={`lookup-row-${dataItem.lookupId}`}>
				{!props.hideActiveStatus && (
					<td>
						<SingleCheckbox
							id={`${props.id}isActive_${dataItem.lookupId}`}
							name="isActive"
							key={`${props.id}checkbox`}
							label={''}
							checked={dataItem.isActive}
							isDisabled={dataItem.isSystem === true}
							onChange={(e: any) => onLookupIsActiveChanged(e, dataItem)}
							className="ml-2"
						/>
					</td>
				)}
				<td id={`code_${dataItem.lookupId}`}>{dataItem.code}</td>
				{props.showDescriptionColum && <td>{dataItem.codeDescription}</td>}
				{props.showCopy && (
					<td align="right">
						{dataItem.isSystem !== true && (
							<button
								onClick={() => {
									onCopyIconButtonClick(dataItem);
								}}>
								<Copy />
							</button>
						)}
					</td>
				)}
				<td align="right">
					{dataItem.isSystem !== true && (
						<button onClick={() => onEditIconButtonClick(dataItem)}>
							<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon" />
						</button>
					)}
				</td>
				<td>
					{dataItem.isSystem !== true && !dataItem.isInUse && (
						<button onClick={() => onDeleteIconButtonClick(dataItem)}>
							<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon" />
						</button>
					)}
				</td>
			</tr>
		);
		// });
	};

	const getTitleRow = () => {
		return (
			<tr className="lookup-row lookup-header-row font-size-14px-semibold">
				{!props.hideActiveStatus && (
					<th className="active-checkbox-cell-width">
						{localizationService.getLocalizedString('authoritySetting.isActive')}
					</th>
				)}
				<th>{props.label}</th>
				{props.showDescriptionColum && (
					<th>
						{props.descriptionLabel ||
							localizationService.getLocalizedString('authoritySetting.description')}
					</th>
				)}
				{props.showScheduleColumn && (
					<th>
						{props.descriptionLabel || localizationService.getLocalizedString('authoritySetting.schedule')}
					</th>
				)}
				<th className="action-2-buttons">&nbsp;</th>
				<th className="action-2-buttons">&nbsp;</th>
				{props.showCopy && <th className="action-2-buttons">&nbsp;</th>}
			</tr>
		);
	};
	const defaultList = useRef<any>(null);

	function upsert(array: any[], item: any) {
		const i = array.findIndex(_item => _item.lookupId === item.lookupId);
		if (i > -1) array[i] = item;
		else array.push(item);
	}

	const onDragOver = (event: any) => {
		setLookups(event.newState);

		lookups
			.map((value, index) => {
				const updatedField = event.newState.find((lookup: any, lookupIndex: number) => {
					return lookup.lookupId !== value.lookupId && index === lookupIndex;
				});
				if (updatedField) {
					return { ...updatedField, position: index + 1 };
				}
			})
			.filter(lookup => {
				if (lookup !== undefined) {
					upsert(lookupsSortApiList, lookup);
				}
			});
		setLookupsSortApiList([...lookupsSortApiList]);
	};

	const onDragEnd = (event: any) => {
		if (props.settingAction.bulkUpdate && lookupsSortApiList.length > 0)
			props.settingAction
				.bulkUpdate(lookupsSortApiList as LookupRow[])
				.then(() => {
					setLookupsSortApiList([]);
				})
				.catch((error: any) => {
					Logger.error(error);
					if (error.statusCode === 400) {
						alertService.addError(
							localizationService.getLocalizedString(
								'screen.validationMessage.fieldValueIsRequired',
								'customFieldId'
							)
						);
					}
					setLookupsSortApiList([]);
				});
	};

	return (
		<div>
			{!props.hideAddButton &&
				(_.isUndefined(props.maxNumberOfKey) ? true : _.size(lookups) < props.maxNumberOfKey) && (
					<div className={`form-group d-sm-flex ${props.embeddedMode === true ? 'required' : ''}`}>
						{props.hideAddModal ? (
							<button className="btn ai-new mb-1 ml-auto " id="btnAdd" onClick={onAddButtonClick}>
								{localizationService.getLocalizedString('authoritySetting.addBtnText')}
							</button>
						) : (
							<InlineChildTextInput rowId={props.id} inlineEditProps={getInlineEditProps()}>
								<button className="btn ai-new mt-1 " id="btnAdd" onClick={onAddButtonClick}>
									{localizationService.getLocalizedString('authoritySetting.addBtnText')}
								</button>
							</InlineChildTextInput>
						)}
					</div>
				)}

			{lookups.length > 0 ? (
				<div className="table-responsive overflow-hidden">
					<table className="table">
						<thead>{getTitleRow()}</thead>
						{!props.itemRenderer ? (
							<tbody>{getLookUpRows()}</tbody>
						) : (
							<tbody>
								{props.itemRenderer({
									data: lookups,
									itemUI: getRows,
									ref: defaultList,
									onDragEnd: onDragEnd,
									onDragOver: onDragOver
								})}
							</tbody>
						)}
					</table>
				</div>
			) : (
				<div>
					{localizationService.getLocalizedString(
						'authoritySetting.NoLookupsInSystem',
						_.toLower(props.label)
					)}
				</div>
			)}

			{showDeleteModal && (
				<DeleteModal {...getRemoveLookupTypeModalProps()} key={`${props.label}removeLookupModal`} />
			)}
			{showEditModal && editLookupTypeModal()}
		</div>
	);
};
