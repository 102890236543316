import React from 'react';
import * as LinkoTypes from '@rcp/types';
import { CccDeviceEditModal } from './ccc-device-edit-modal';

interface OwnProps {
	cccDevice: LinkoTypes.CccDevice;
}

type Props = OwnProps;

const CccDeviceEditActionLinkComponent: React.SFC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href="#/" className="ai-link" onClick={onEditClicked}>
				{props.cccDevice.manufacturer}
			</a>
			{showModal && (
				<CccDeviceEditModal
					cccDeviceId={props.cccDevice.deviceId}
					showEditModal={showModal}
					setShowModal={toggleModal}
					isAddModal={false}
				/>
			)}
		</>
	);
};

export const CccDeviceEditActionLink = CccDeviceEditActionLinkComponent;
