import React from 'react';
import { SingleCheckbox } from '../single-checkbox';
import './multiple-checkbox.scss';

interface SingleCheckboxProps {
	id: string;
	name: string;
	label?: string | JSX.Element;
	isDisabled?: boolean;
	checked?: boolean;
	className?: string;
	htmlLabel?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface MultipleCheckboxProp {
	label: string;
	checkboxes: SingleCheckboxProps[];
	isLabelDisabled: boolean;
	isRequired?: boolean;
	className?: string;
	error?: string;
}

const MultipleCheckbox: React.FC<MultipleCheckboxProp> = props => {
	let defaultClass = '';
	let rowClass = props.className ? props.className : defaultClass;
	return (
		<React.Fragment>
			<div
				className={
					'form-group pl-5px ' +
					rowClass +
					(props.isRequired ? ' required' : '') +
					(props.isLabelDisabled ? ' disabled-feedback' : '')
				}>
				<label>{props.label}</label>
				<div className="input-group mw-input-groups">
					{props.checkboxes.map((checkbox, index) => {
						return <SingleCheckbox className="mr-3" {...checkbox} key={`${checkbox.label}-${index}`} />;
					})}
				</div>
				{props.error ? <div className="invalid-feedback d-block">{props.error}</div> : <React.Fragment />}
			</div>
		</React.Fragment>
	);
};

export default MultipleCheckbox;
