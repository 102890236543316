import { AttachmentType, CustomFormAttachment, UploadMaximumSize50MB } from '@rcp/types';
import _ from 'lodash';
import { alertService } from 'src/redux';
import { apiService, QueryParameters, urlService, validationService } from 'src/services';

export interface ModalAttachmentState {
	attachmentType: AttachmentType;
	ownerId?: number;
	organizationId?: number;
	currentAttachments: CustomFormAttachment[];
	initialAttachments: CustomFormAttachment[];
}

export class ModalAttachmentService {
	private getState: () => ModalAttachmentState;
	setState: (state: ModalAttachmentState) => void;

	constructor(getState: () => ModalAttachmentState, setState: (state: ModalAttachmentState) => void) {
		this.getState = getState;
		this.setState = setState;
	}

	private getAttachmentsUrl = (attachmentType: AttachmentType, ownerId: number, extraQs: string = '') => {
		let state = this.getState();
		let queryParams = new QueryParameters();
		queryParams.add('attachmentType', attachmentType);
		queryParams.add('ownerId', ownerId);
		if (state.organizationId) {
			queryParams.add('organizationId', state.organizationId);
		}
		let queryString = queryParams.toQueryString();
		if (extraQs) {
			queryString += '&' + extraQs;
		}
		let url = `${urlService.getApiBaseUrl()}/fog/OwnedAttachments?${queryString}`;
		return url;
	};

	loadAttachments = async () => {
		let state = this.getState();
		if (state.ownerId) {
			apiService
				.getResource<CustomFormAttachment[]>(this.getAttachmentsUrl(state.attachmentType, state.ownerId))
				.then(data => {
					//to avoid callback use same array reference, initialAttachment use a copied array
					let newState: ModalAttachmentState = {
						...state,
						initialAttachments: data,
						currentAttachments: data
					};
					this.setState(newState);
				})
				.catch((ex: any) => {
					alertService.addError(ex.message);
				});
		}
	};

	setCurrentAttachments = async (currentAttachments: CustomFormAttachment[]) => {
		let newState: ModalAttachmentState = {
			...this.getState(),
			currentAttachments: currentAttachments
		};
		this.setState(newState);
	};

	validateBeforeSave = (attachments: CustomFormAttachment[]) => {
		let attachmentsNeedToBeUploaded = _.filter(attachments, a => a.s3ResourceUrl?.startsWith('blob'));
		if (!_.isEmpty(attachmentsNeedToBeUploaded)) {
			let uploadAttachmentSize = _.sumBy(attachmentsNeedToBeUploaded, 'size');
			// validate upload size is allowed
			return validationService.validateUploadSize(uploadAttachmentSize, UploadMaximumSize50MB);
		}
		return true;
	};

	validatePickedFilesForUpload = (attachments: FileList) => {
		let uploadAttachmentSize = _.sumBy(attachments, 'size');
		// validate upload size is allowed
		return validationService.validateUploadSize(uploadAttachmentSize, UploadMaximumSize50MB);
	};

	saveAttachments = (ownerId: number, postSave: () => void) => {
		let state = this.getState();
		let attachmentsNeedToBeUploaded = _.filter(state.currentAttachments, a => a.s3ResourceUrl?.startsWith('blob'));
		var promises: Promise<any>[] = [];
		if (!_.isEmpty(attachmentsNeedToBeUploaded)) {
			// upload attachment files
			let formData = new FormData();
			for (let x = 0; x < attachmentsNeedToBeUploaded.length; x++) {
				formData.append('files', attachmentsNeedToBeUploaded[x] as File);
			}
			var uploadPromise = apiService.postFormData(
				this.getAttachmentsUrl(state.attachmentType, ownerId),
				formData,
				{}
			);
			promises.push(uploadPromise);
		}
		let ownerAttachmentIds = _.map(state.initialAttachments, 'attachmentOwnershipId') as number[];
		let remainAttachmentIds = _.map(
			_.filter(state.currentAttachments, (a: any) => a.attachmentOwnershipId && a.attachmentOwnershipId > 0),
			'attachmentOwnershipId'
		) as number[];
		let attachmentIdsForDelete = _.filter(ownerAttachmentIds, id => !_.includes(remainAttachmentIds, id));
		if (!_.isEmpty(attachmentIdsForDelete)) {
			// delete removed ownedAttachments
			let deleteIds = attachmentIdsForDelete.join(',');
			let extraQueryString = `ownedAttachmentIds=${deleteIds}`;
			var deletePromise = apiService.deleteResource(
				`${this.getAttachmentsUrl(state.attachmentType, ownerId, extraQueryString)}`
			);
			promises.push(deletePromise);
		}
		if (promises.length > 0) {
			Promise.all(promises)
				.then((responses: any) => {
					postSave();
				})
				.catch(ex => {
					alertService.addError(ex.message);
				});
		} else {
			postSave();
		}
	};
}
