import React from 'react';
import { History } from 'history';
import { cccDeviceSlice } from './devices.slice';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, LocalStorageName, CccDevice, FilterType } from '@rcp/types';
import { RestSlice } from '../../../../../redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';
import { CccDeviceEditActionLink } from './ccc-device-edit-action-link';
import { CccDeviceEditModal } from './ccc-device-edit-modal';
import { nameof } from 'ts-simple-nameof';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccAuthoritySettingDevicesComponent: React.FunctionComponent<Props<CccDevice>> = props => {
	const manufacturerToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.CccDevice;
		return (
			<td>
				<CccDeviceEditActionLink cccDevice={dataItem} />
			</td>
		);
	};

	const [showAddModal, setAddShowModal] = React.useState(false);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('cccDevice.devices'),
		prefix: 'cccDevice',
		storageName: LocalStorageName.CccDevice,
		gridUrlLocalStorageName: LocalStorageName.CccDeviceGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccDeviceSortOrder,

		showActions: true,
		showActionExport: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccDevice.searchHint'),

		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('cccDevice.addDevice'),
		addModalComponent: (
			<>
				{showAddModal && (
					<CccDeviceEditModal showEditModal={showAddModal} setShowModal={toggleAddModal} isAddModal={true} />
				)}
			</>
		),
		toggleAddModal: toggleAddModal,

		queryParameters: {
			includeInactive: '1',
			sort: 'manufacturer asc, model asc, deviceTypeCode asc, deviceSize asc'
		},
		defaultSort: [
			{ field: nameof<CccDevice>(d => d.manufacturer), dir: 'asc' },
			{ field: nameof<CccDevice>(d => d.model), dir: 'asc' },
			{ field: nameof<CccDevice>(d => d.deviceTypeCode), dir: 'asc' },
			{ field: nameof<CccDevice>(d => d.deviceSize), dir: 'asc' }
		],
		allColumns: [
			new ColumnField('manufacturer', FilterType.Text, manufacturerToLink),
			new ColumnField('model'),
			new ColumnField('deviceTypeCode'),
			new ColumnField('deviceSize')
		],
		defaultColumns: ['manufacturer', 'model', 'deviceTypeCode', 'deviceSize']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccDeviceSlice}
				restState={(state: RootState) => state.cccDevices}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export const CccAuthoritySettingDevices = CccAuthoritySettingDevicesComponent;
