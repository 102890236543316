import React, { FC, useContext, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { localizationService, urlService } from 'src/services';
import { translateService } from 'src/services/translate-service';
import { Translate } from '../widgets/translate/translator';
import { LanguageContext } from '../widgets/translate/translator-context';

interface Props {
	errorTitle: string;
	errorDescription: string | React.ReactElement;
	containerClass?: string;
	showSignInButton?: boolean;
	signInUrlAction?: string;
	buttonContainerClass?: string;
}

const IppAccountErrorComponent: FC<Props> = props => {
	const containerClass = props.containerClass || '';
	const buttonContainerClass = props.buttonContainerClass || 'signin-footer';
	const signInUrl = props.signInUrlAction
		? `${urlService.getLoginUrl()}?action=${props.signInUrlAction}`
		: urlService.getLoginUrl();
	const [errorDescription, setErrorDescription] = useState('');
	const { language, setLanguage } = useContext(LanguageContext);

	useEffect(() => {
		translateErrorDescription();
	}, [language, errorDescription]);

	const translateErrorDescription = async () => {
		let message =
			typeof props.errorDescription === 'string'
				? props.errorDescription
				: ReactDOMServer.renderToString(props.errorDescription as React.ReactElement);
		translateService.translateHtmlAndSetMessage(message, setErrorDescription, language);
	};
	return (
		<>
			<div className={containerClass}>
				<h1>
					<Translate>{props.errorTitle}</Translate>
				</h1>
				<p>
					{typeof props.errorDescription === 'string' ? (
						<>{errorDescription}</>
					) : (
						<span dangerouslySetInnerHTML={{ __html: errorDescription }}></span>
					)}
				</p>
				{props.showSignInButton && (
					<div className={buttonContainerClass}>
						<a id="backToSignInLink" href={signInUrl} className="btn ai-action btn-signin">
							<Translate>
								{localizationService.getLocalizedString('authentication.backToSignIn')}
							</Translate>
						</a>
					</div>
				)}
			</div>
		</>
	);
};

export default IppAccountErrorComponent;
