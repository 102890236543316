import React from 'react';
import { connect } from 'react-redux';
import { AlertMessage, AlertState, ApplicationState } from 'src/redux';
import { AlertItem } from './alert-item';

interface StateProps extends AlertState {}

type Props = StateProps;

const AlertContainerComp: React.SFC<Props> = props => {
	return (
		<div className="ai-alerts">
			{props.messages.map((message: AlertMessage, index: number) => (
				<AlertItem key={'alert-' + index} message={message} />
			))}
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.alerts };
};

export const AlertContainer = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(AlertContainerComp);
