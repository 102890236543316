import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { Violation, PaginatedResult, MaxInteger } from '@rcp/types';
import _ from 'lodash';
import { alertService } from '..';
import { violationSlice } from '../../components/authority/fog/violation/violation.slice';

export interface ViolationState {
	facilityViolations: Violation[];
	authorityViolations: PaginatedResult<Violation>;
}

export const initialViolationState: ViolationState = {
	facilityViolations: [],
	authorityViolations: {
		page: 1,
		size: 10,
		total: 0,
		result: []
	}
};

enum ViolationActionType {
	ViolationCreated = 'ViolationCreated',
	ViolationUpdate = 'ViolationUpdated',
	LoadFacilityViolations = 'LoadFacilityViolationsType',
	LoadAuthorityViolations = 'LoadAuthorityViolations',
	DeleteViolationFinishedSuccessfully = 'DeleteViolationFinishedSuccessfully'
}

export type ViolationThunkAction = ThunkAction<any, ViolationState, any, Action>;

const deleteFacilityViolation = (
	facilityId: number,
	violation: Violation,
	queryString?: string
): ViolationThunkAction => async dispatch => {
	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const violationUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Violations);
	await apiService.deleteResource(`${violationUrl}/${violation.violationId}`);
	let key = localizationService.getLocalizedString('violation.violation');
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.removeSuccess', key));

	return true;
};

export const saveFacilityViolation = (
	facilityId: number,
	violation: Violation,
	queryString?: string
): ViolationThunkAction => async (dispatch, getState) => {
	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	let savedViolation: Violation;
	const violationsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Violations);
	if (violation.violationId && violation.violationId > 0) {
		savedViolation = await apiService.patchResource(violationsUrl + '/' + violation.violationId, violation);
		alertService.addSuccess(localizationService.getLocalizedString('alertMessages.updateViolationSucceeded'));
		dispatch({ type: ViolationActionType.ViolationUpdate, violation: savedViolation });
	} else {
		savedViolation = await apiService.postResource<Violation>(violationsUrl, violation);
		alertService.addSuccess(localizationService.getLocalizedString('alertMessages.createViolationSucceeded'));
		dispatch({ type: ViolationActionType.ViolationCreated, violation: savedViolation });
	}

	return true;
};

export const saveViolation = (violation: Violation): ViolationThunkAction => async dispatch => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	dispatch(saveFacilityViolation(facilityId, violation));
	return true;
};

export const loadFacilityViolations = (
	startDate?: string,
	endDate?: string
): ViolationThunkAction => async dispatch => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	let nonComplianceDateQueryParams = new QueryParameters().put('size', MaxInteger);
	let complianceDueDateQueryParams = new QueryParameters().put('size', MaxInteger);
	let inComplianceDateQueryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		nonComplianceDateQueryParams
			.put('nonComplianceDate', `gte:${startDate}`)
			.put('nonComplianceDate', `lte:${endDate}`);
		complianceDueDateQueryParams
			.put('complianceDueDate', `gte:${startDate}`)
			.put('complianceDueDate', `lte:${endDate}`);
		inComplianceDateQueryParams
			.put('inComplianceDate', `gte:${startDate}`)
			.put('inComplianceDate', `lte:${endDate}`);
	}

	const violationUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Violations);
	let promises = [
		apiService.getPaginatedResources<Violation>(`${violationUrl}?${nonComplianceDateQueryParams.toQueryString()}`),
		apiService.getPaginatedResources<Violation>(`${violationUrl}?${complianceDueDateQueryParams.toQueryString()}`),
		apiService.getPaginatedResources<Violation>(`${violationUrl}?${inComplianceDateQueryParams.toQueryString()}`)
	];

	Promise.all(promises).then(values => {
		let results = _.concat(values[0].result, values[1].result, values[2].result);
		let violations = _.unionBy(results, 'violationId');
		dispatch(violationSlice.fetchSuccess(violations));
	});
};

export const loadAuthorityViolations = (queryString?: string): ViolationThunkAction => async dispatch => {
	const authorityViolationsUrl = urlService.getAuthorityResourcesApiUrl(Resource.Violations);
	let url = authorityViolationsUrl;
	if (queryString) {
		url = `${url}?${queryString}`;
	} else {
		url = `${url}?sort=nonComplianceDate desc`;
	}

	let violations = await apiService.httpGet(url);
	dispatch({ type: ViolationActionType.LoadAuthorityViolations, violations: violations });
};

export const authorityDeleteFacilityViolation = (
	facilityId: number,
	violation: Violation
): ViolationThunkAction => async dispatch => {
	return await dispatch(deleteFacilityViolation(facilityId, violation));
};

export const deleteViolation = (violation: Violation): ViolationThunkAction => async dispatch => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	return await deleteFacilityViolation(facilityId, violation);
};

export const exportAuthorityViolations = (querystring: string): ViolationThunkAction => async dispatch => {
	const authorityViolationsUrl = urlService.getAuthorityResourcesApiUrl(Resource.Violations);
	await apiService.httpGet(`${authorityViolationsUrl}?${querystring}`);
	alertService.addSuccess(localizationService.getLocalizedString('exportFile.downloadSuccess'));
};

export const violationReducer = (state = initialViolationState, action: any): ViolationState => {
	switch (action.type) {
		case ViolationActionType.LoadFacilityViolations:
			return { ...state, facilityViolations: action.violations };
		case ViolationActionType.LoadAuthorityViolations:
			return { ...state, authorityViolations: action.violations };
		case ViolationActionType.ViolationUpdate:
			if (action.violation) {
				let { authorityViolations, facilityViolations } = state;

				authorityViolations.result = authorityViolations.result.slice();
				facilityViolations = facilityViolations.slice();
				authorityViolations = { ...authorityViolations };

				let index = authorityViolations.result.findIndex(i => i.violationId === action.violation.violationId);
				if (index > -1) {
					authorityViolations.result[index] = action.violation;
				}

				index = facilityViolations.findIndex(i => i.violationId === action.violation.violationId);
				if (index > -1) {
					facilityViolations[index] = action.violation;
				}

				return { ...state, facilityViolations, authorityViolations };
			}

			return { ...state };
		default:
			return { ...state };
	}
};
