import React, { FC } from 'react';
import { TextAreaInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { localizationService } from 'src/services';

interface Props {
	reportStatusName?: string;
	comments: string;
	className: string;
	onChangeComments?: (event: any) => void;
}

const { fieldCharLimit } = IppConstants;

const IppPackageItemComments: FC<Props> = ({ reportStatusName, comments, className, onChangeComments }) => {
	return (
		<>
			<CollapsibleCard
				accordionType="report-package-comments-accordion"
				accordionHeading={localizationService.getLocalizedString('ipp.reportPackage.package.comments')}
				className={className ? className : ''}>
				<div id="report-package-comments">
					{String.equalCaseInsensitive(reportStatusName, ReportPackageStatus.Draft) ? (
						<TextAreaInput
							isFullWidth={true}
							id="comments"
							name="comments"
							placeholder=""
							label=""
							value={comments || ''}
							onChange={onChangeComments}
							maxLength={fieldCharLimit.commentsAndDescription}
						/>
					) : (
						<p>{comments}</p>
					)}
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageItemComments;
