import React from 'react';
import {
	Attachment,
	CustomCleaningFields,
	CustomFieldType,
	CustomFormAttachment,
	CustomFormFieldType,
	Dictionary,
	DropDownOption,
	ICustomFormInputElement,
	LookupType
} from '@rcp/types';
import { LookupRow } from 'src/components/widgets/lookup/lookup-types';
import { WasteTypeLookRowDataConverter } from 'src/components/widgets/lookup/data-converters/waste-type-data-converters';
import { UnitRowLookDataConverter } from 'src/components/widgets/lookup/data-converters/unit-data-converter';
import { HaulerRowLookDataConverter } from 'src/components/widgets/lookup/data-converters/hauler-data-converter';
import _ from 'lodash';
import {
	DateInput,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput,
	Signature as SignatureModal,
	AttachmentThumbnail
} from 'src/components/widgets';
import { UploadAttachment } from 'src/components/widgets/upload-attachments';
import { DateUtilService, localizationService, urlService } from '.';
import { Translate } from 'src/components/widgets/translate/translator';

const { Text, TextArea, Date, Lookup, CustomSelect, AttachmentControl, Checkbox, Signature } = CustomFormFieldType;

export class ConverterFactory {
	static createDropDownOptionValue(lookupRow: LookupRow[], useIdAsValue: boolean = false): DropDownOption[] {
		return lookupRow.map(lookupData => {
			return {
				label: lookupData.code,
				value: useIdAsValue ? lookupData.lookupId : lookupData.code,
				isHidden: !lookupData.isActive
			} as DropDownOption;
		});
	}

	static create(converterName: string, apiData: any): any {
		if (converterName === 'WasteTypeLookRowDataConverter') {
			return this.createDropDownOptionValue(
				new WasteTypeLookRowDataConverter(LookupType.WasteType).toLookupRowsFromAPI(apiData),
				true
			);
		} else if (converterName === 'UnitRowLookDataConverter') {
			return this.createDropDownOptionValue(
				new UnitRowLookDataConverter(LookupType.Unit).toLookupRowsFromAPI(apiData)
			);
		} else if (converterName === 'HaulerRowLookDataConverter') {
			return this.createDropDownOptionValue(
				new HaulerRowLookDataConverter(LookupType.Hauler).toLookupRowsFromAPI(apiData),
				true
			);
		}
		throw new Error('Cannot create converter ' + converterName);
	}

	static getInputsFromDataItem(
		dataItems: CustomFieldType[],
		handleChangeInputValue: (e: any, dataItem: CustomFieldType, fieldValue?: any) => void,
		isPreviewMode: boolean,
		formType: string,
		authorityOrganizationId?: number,
		isReadOnly?: boolean,
		isSeattleFacility?: boolean
	) {
		return dataItems.map(dataItem => {
			const { inputs, defaultUnit, certStatement, name } = dataItem;

			const calculatePercent = () => {
				let trapDepth = '';
				let fog = '';
				let solid = '';
				_.each(inputs, inputItem => {
					if (inputItem.fieldId === 'trapDepth') {
						trapDepth = inputItem.value || '';
					} else if (inputItem.fieldId === 'fog') {
						fog = inputItem.value || '';
					} else if (inputItem.fieldId === 'solid') {
						solid = inputItem.value || '';
					}
				});

				if (trapDepth && fog && solid && +trapDepth > 0) {
					let waste = +fog + +solid;
					let percentGrease = Math.round((+waste / +trapDepth) * 100);
					return percentGrease;
				} else {
					return 0;
				}
			};
			const getOptions = () => {
				if (inputs && inputs[0].customSelectOptions && inputs[0].customSelectOptions.length) {
					if (!isPreviewMode) {
						return;
					}
					let customOptions: any = inputs[0].customSelectOptions;
					customOptions = customOptions.map((customOption: string) => {
						return {
							label: customOption,
							value: customOption
						};
					});
					return ({ ...customOptions, [_.camelCase(name)]: customOptions } as any)[_.camelCase(name || '')];
				} else {
					return [];
				}
			};

			return (
				<>
					<div
						className={'form-row px-2'}
						id={name === CustomCleaningFields.Attachments ? 'attachmentsInput' : undefined}>
						{(inputs || []).map((input: any, index: number) => {
							if (
								input.fieldId === 'isRepairNeededReason' &&
								isPreviewMode &&
								inputs &&
								!inputs[0].value
							) {
								return <></>;
							}
							switch (input.fieldType) {
								case Text:
								case CustomFormFieldType.Number:
									return (
										<TextInput
											key={index + input.fieldId}
											className={`col form-group ${
												input.fieldId === 'fog' &&
												name === CustomCleaningFields.PercentGreaseCalculator
													? ''
													: 'px-0'
											}`}
											id={input.fieldId}
											name={input.fieldId}
											label={input.fieldLabel + ` ${defaultUnit ? `(${defaultUnit})` : ''}`}
											value={input.value}
											onChange={e => {
												handleChangeInputValue(e, dataItem);
											}}
											isRequired={input.isRequired ? true : false}
											helpText={input.hintText}
											error={input.error}
											showErrorAndHelp
											type={input.fieldType === Text ? 'text' : 'number'}
											isDisabled={isReadOnly}
										/>
									);

								case Date:
									return (
										<DateInput
											key={index + input.fieldId}
											className={`col px-0 form-group`}
											id={input.fieldId}
											name={input.fieldId}
											label={input.fieldLabel}
											value={input.value}
											onChange={e => {
												handleChangeInputValue(e, dataItem);
											}}
											max={
												dataItem.name === CustomCleaningFields.CompleteDate
													? DateUtilService.getAuthorityTimezoneNow()
													: ''
											}
											isRequired={input.isRequired ? true : false}
											helpText={input.hintText}
											error={input.error}
											showErrorAndHelp
											isDisabled={isReadOnly}
										/>
									);
								case Checkbox:
									return (
										<SingleCheckbox
											key={index + input.fieldId}
											id={input.fieldId}
											name={input.fieldId}
											className={`form-group ${input.isRequired ? 'required' : ''}`}
											htmlLabel={
												dataItem.name === CustomCleaningFields.Certification
													? certStatement
													: undefined
											}
											label={
												dataItem.name !== CustomCleaningFields.Certification
													? input.fieldLabel
													: undefined
											}
											onChange={e => {
												handleChangeInputValue(e, dataItem);
											}}
											fieldLabel={
												dataItem.name === CustomCleaningFields.Certification ? (
													<label>
														<Translate>{input.fieldLabel}</Translate>
													</label>
												) : (
													undefined
												)
											}
											checked={input.value}
											error={input.error}
											isDisabled={isReadOnly}
										/>
									);
								case Signature:
									return (
										<SignatureModal
											key={index}
											id={input.fieldId + ''}
											name={input.fieldId}
											label={input.fieldLabel}
											signature={input.value}
											handleSignatureUpdate={(event: any, signature: string) => {
												handleChangeInputValue(event, dataItem, signature);
											}}
											signatureError={input.error}
											isRequired={input.isRequired}
											readOnly={!isPreviewMode}
											hintText={input.hintText}
										/>
									);
								case Lookup:
								case CustomSelect:
									return (
										<SingleSelectDropdown
											key={index + input.fieldId}
											id={input.fieldId}
											name={input.fieldId}
											label={input.fieldLabel}
											className="form-group w-100"
											isRequired={input.isRequired ? true : false}
											selfOrder={input.fieldType === CustomSelect}
											hintText={input.hintText}
											value={input.value}
											options={isPreviewMode ? getOptions() : []}
											onChange={e => {
												handleChangeInputValue(e, dataItem);
											}}
											endpoint={
												isPreviewMode
													? urlService.getAuthorityLookupUrlFromEndPoint(
															input.endpoint,
															formType,
															authorityOrganizationId
													  )
													: ''
											}
											converter={isPreviewMode ? input.converter : ''}
											error={input.error}
											doNotTranslateOptions={dataItem.name === CustomCleaningFields.Hauler}
											readonly={isReadOnly}
										/>
									);
								case TextArea:
									return (
										<TextAreaInput
											key={index + input.fieldId}
											id={input.fieldId}
											name={input.fieldId}
											label={input.fieldLabel}
											isRequired={input.isRequired ? true : false}
											helpText={input.hintText}
											className="form-group w-100"
											isFullWidth
											rows={3}
											value={input.value}
											onChange={e => {
												handleChangeInputValue(e, dataItem);
											}}
											error={input.error}
											isDisabled={isReadOnly}
										/>
									);
								case AttachmentControl:
									return (
										<div className={`col-6 attachment-box form-group`} key={index + input.fieldId}>
											<UploadAttachment
												id={isPreviewMode ? input.fieldId : undefined}
												name={input.fieldId}
												label={input.fieldLabel}
												helpText={input.hintText}
												hideInput
												handleFileUpload={(e, files) => {
													handleChangeInputValue(e, dataItem, files);
												}}
												files={input.value}
												acceptMultipleFile={false}
												isRequired={input.isRequired}
												error={input.error}
											/>
										</div>
									);
							}
						})}
					</div>
					{name === CustomCleaningFields.Attachments && isSeattleFacility && (
						<div className="mb-3 form-text ai-form-help px-1">
							<Translate>
								{localizationService.getLocalizedString('facilityPortal.submitCleaning.imageNote')}
							</Translate>
						</div>
					)}
					{name === CustomCleaningFields.PercentGreaseCalculator && (
						<p className="mr-1 percentage-grease-calc">
							<Translate>{`${localizationService.getLocalizedString(
								'haulerPortal.submitCleaning.percentGrease'
							)}`}</Translate>
							{!!calculatePercent() && <strong id="percentGrease">{`${calculatePercent()}%`}</strong>}
						</p>
					)}
				</>
			);
		});
	}

	static getAttachmentSectionForReadOnlyMode(
		dataItems: CustomFieldType[],
		attachments: Dictionary<Attachment>,
		divId: string,
		toLanguageCode?: string,
		fromLanguageCode?: string
	) {
		return dataItems
			.filter(x => {
				return x.name === CustomCleaningFields.Attachments;
			})
			.map(dataItem => {
				const { inputs } = dataItem;

				return (
					<>
						<div className={'form-row attachment-hide'} id={'' + divId}>
							{(inputs || []).map((input: ICustomFormInputElement, index: number) => {
								switch (input.fieldType) {
									case AttachmentControl:
										return (
											<div
												className={`col-6 px-2 form-group attachments`}
												key={index + input.fieldId}>
												<div>
													<Translate
														toLanguageCode={toLanguageCode}
														fromLanguageCode={fromLanguageCode}
														mustTranslateOnAuthorityPortal={true}>
														{input.fieldLabel}
													</Translate>
												</div>
												{attachments[input.fieldId] != undefined ? (
													<AttachmentThumbnail
														attachment={attachments[input.fieldId]}
														key={`icon-${input.fieldId}`}
														insideModal
														className="attachment-picture cursor-pointer"
														isDownloadButton={true}
														showFileNameInsteadOfFileType={true}
													/>
												) : (
													<div className="attachment-picture">
														<div className="d-flex flex-column"></div>
													</div>
												)}
												{input.hintText && (
													<div className="ai-form-help">
														<Translate
															toLanguageCode={toLanguageCode}
															fromLanguageCode={fromLanguageCode}
															mustTranslateOnAuthorityPortal={true}>
															{input.hintText}
														</Translate>
													</div>
												)}
											</div>
										);
								}
							})}
						</div>
					</>
				);
			});
	}

	static viewAttachments(
		dataItems: CustomFieldType[],
		files: CustomFormAttachment[],
		divId: string,
		toLanguageCode?: string,
		fromLanguageCode?: string
	) {
		if (!!dataItems.length) {
			let attachments: Dictionary<CustomFormAttachment> = {};
			files.forEach(attachment => {
				if (attachment.attachmentSectionKey) {
					attachments[attachment.attachmentSectionKey] = attachment;
				}
			});
			return ConverterFactory.getAttachmentSectionForReadOnlyMode(
				dataItems,
				attachments,
				divId,
				toLanguageCode,
				fromLanguageCode
			).map(formData => {
				return <div>{formData}</div>;
			});
		}
	}
}
