import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum IndustriesActionType {
	LoadIndustryDetailsRequest = 'loadIndustryDetailsRequest',
	LoadIndustryDetailsSuccess = 'loadIndustryDetailsSuccess',
	UpdateIndustry = 'updateIndustry',
	ResetIndustry = 'resetIndustry'
}

export interface LoadIndustryDetailsRequest extends Action {
	type: IndustriesActionType.LoadIndustryDetailsRequest;
}

export interface LoadIndustryDetailsSuccess extends Action {
	type: IndustriesActionType.LoadIndustryDetailsSuccess;
	industryDetails: ApiTypes.IppIndustry;
	templates: ApiTypes.IppReportPackageTemplate[];
	parameterGroups: ApiTypes.IppReportPackageParameterGroup[];
}

export interface UpdateIndustryAction extends Action {
	type: IndustriesActionType.UpdateIndustry;
}

export interface ResetIndustryAction extends Action {
	type: IndustriesActionType.ResetIndustry;
}

export type IndustriesAction =
	| LoadIndustryDetailsRequest
	| LoadIndustryDetailsSuccess
	| UpdateIndustryAction
	| ResetIndustryAction;

export const loadIndustryDetailsRequest = (): LoadIndustryDetailsRequest => ({
	type: IndustriesActionType.LoadIndustryDetailsRequest
});

export const loadIndustryDetailsSuccess = (
	industryDetails: ApiTypes.IppIndustry,
	templates: ApiTypes.IppReportPackageTemplate[],
	parameterGroups: ApiTypes.IppReportPackageParameterGroup[]
): LoadIndustryDetailsSuccess => ({
	type: IndustriesActionType.LoadIndustryDetailsSuccess,
	industryDetails,
	templates,
	parameterGroups
});

export const updateIndustryAction = (): UpdateIndustryAction => ({
	type: IndustriesActionType.UpdateIndustry
});

export const resetIndustryAction = (): ResetIndustryAction => ({
	type: IndustriesActionType.ResetIndustry
});
