import React, { FC, useState } from 'react';
import { History } from 'history';
import { ColumnField, FilterType, LocalStorageName, Event, RouteProps, FilterObjectName } from '@rcp/types';
import { RestSlice } from 'src/redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { fogEventsSlice } from './events.slice';
import { SelectEvents } from '../facilities/facility-details/event/select-event-modal';
import { EventModal } from '../facilities/facility-details/event/event-modal';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const EventsGrid: FC<Props<Event>> = props => {
	const [showModal, setShowModal] = useState(false);
	const [showEditEventModal, setShowEditEventModal] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState<Event>({});

	const toggleModal = (event?: any) => {
		event && event.preventDefault();
		setShowModal(!showModal);
	};

	const toggleEditEventModal = (event?: any, rowData?: Event) => {
		if (rowData) {
			setSelectedEvent(rowData);
		}
		event && event.preventDefault();
		setShowEditEventModal(!showEditEventModal);
	};

	const facilityNamesCell = (item: any) => {
		const dataItem = item.dataItem as Event;
		const url = dataItem.facilityId
			? urlService.getReactAuthorityResourceUrl(Resource.FogFacilities, dataItem.facilityId)
			: null;
		return url ? (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.facilityName}
				</a>
			</td>
		) : (
			<td></td>
		);
	};

	const editEventCell = (item: any) => {
		const dataItem = item.dataItem as Event;
		return (
			<td>
				{dataItem.completeDate ? (
					<a
						href="#/"
						className="ai-link"
						onClick={(event: any) => {
							event.preventDefault();
							toggleEditEventModal(event, dataItem);
						}}>
						{DateUtilService.toDisplayDate(dataItem.completeDate)}
					</a>
				) : (
					<button className="btn ai-white" onClick={(event: any) => toggleEditEventModal(event, dataItem)}>
						{localizationService.getLocalizedString('events.editEvent')}
					</button>
				)}
			</td>
		);
	};
	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'events.events');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'events.events');
		}
		return localizationService.getLocalizedString('events.events');
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'events',
		storageName: LocalStorageName.EventsGrid,
		gridUrlLocalStorageName: LocalStorageName.EventsFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.EventsSortOrder,
		hasGridMap: true,
		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('events.searchHint'),
		showAddButton: props.match.params.importId ? false : true,
		addButtonText: localizationService.getLocalizedString('events.addEvent'),
		showAddModal: showModal,
		toggleAddModal: toggleModal,
		addModalComponent: (
			<>{showModal && <SelectEvents showModal={showModal} onCancel={toggleModal} isEventGridPage />}</>
		),
		allColumns: [
			new ColumnField('eventTypeName', FilterType.Text),
			new ColumnField('dueDate', FilterType.Date),
			new ColumnField('daysLate', FilterType.Number),
			new ColumnField('assignedToUserName', FilterType.Text),
			new ColumnField('facilityName', FilterType.Text, facilityNamesCell),
			new ColumnField('facilityNumber', FilterType.Text),
			new ColumnField('facilityAddressLine1', FilterType.Boolean),
			new ColumnField('completeDate', FilterType.Date, editEventCell),
			new ColumnField('comments', FilterType.Text)
		],
		defaultColumns: [
			'eventTypeName',
			'dueDate',
			'daysLate',
			'assignedToUserName',
			'facilityName',
			'facilityNumber',
			'facilityAddressLine1',
			'completeDate'
		],
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.EVENT,
				FilterDomainName.event,
				FilterObjectName.Event,
				LocalStorageName.EventsFilterUrl,
				appliedDomains
			);
		}
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={fogEventsSlice}
				restState={(state: RootState) => state.fogEvents}
				gridOption={initialGridOption}
			/>
			{showEditEventModal && (
				<EventModal
					showModal={showEditEventModal}
					onCancel={toggleEditEventModal}
					title={localizationService.getLocalizedString('events.editEvent')}
					isEventGridPage
					eventId={selectedEvent.genericEventId}
					facilityId={selectedEvent.facilityId}
					eventToEdit={selectedEvent}
					saveButtonClassName="ai-save"
				/>
			)}
		</>
	);
};
