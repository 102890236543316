import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';

import * as ApiTypes from '@rcp/types';
import { apiService, urlService, Resource } from 'src/services';
import { alertService } from '../alert/alert-service';
import { AttachmentType } from '@rcp/types';

export interface DeviceAttachmentsState {
	totalDeviceAttachments: number;
	deviceAttachments: ApiTypes.AttachmentOwnership[];
}

export const initialDeviceAttachmentsState: DeviceAttachmentsState = {
	totalDeviceAttachments: 0,
	deviceAttachments: []
};

enum ActionType {
	LoadDeviceAttachmentsRequest = 'loadDeviceAttachmentsRequest',
	LoadDeviceAttachmentsSuccess = 'loadDeviceAttachmentsSuccess',
	SaveDeviceAttachmentRequest = 'saveDeviceAttachmentRequest',
	SaveDeviceAttachmentSuccess = 'saveDeviceAttachmentSuccess',
	DeleteDeviceAttachmentRequest = 'deleteDeviceAttachmentRequest',
	DeleteDeviceAttachmentSuccess = 'deleteDeviceAttachmentSuccess',
	ResetDeviceAttachment = 'resetDeviceAttachment'
}

export type DeviceAttachmentsThunkAction = ThunkAction<any, DeviceAttachmentsState, any, Action>;

export const defaultNumberOfDeviceAttachments = 6;
export const maximumOfNumberOfDeviceAttachments = 10000;
export const loadDeviceAttachments = (
	deviceId: number,
	doesGetAll: boolean,
	attachmentType?: AttachmentType,
	ownerId?: number
): DeviceAttachmentsThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.LoadDeviceAttachmentsRequest });

	let number = doesGetAll === true ? maximumOfNumberOfDeviceAttachments : defaultNumberOfDeviceAttachments;
	let url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Devices}/${deviceId}/Attachments?size=${number}`;

	if (attachmentType) {
		url = `${url}&attachmentType=${attachmentType}`;
	}
	if (ownerId) {
		url = `${url}&ownerId=${ownerId}`;
	}
	let deviceAttachments = await apiService.getPaginatedResources<ApiTypes.AttachmentOwnership>(url);

	dispatch({
		type: ActionType.LoadDeviceAttachmentsSuccess,
		deviceAttachments: !ownerId
			? deviceAttachments.result
			: deviceAttachments.result.filter(i => i.ownerId === Number(ownerId)),
		totalDeviceAttachments: deviceAttachments.total
	});
};

export const resetDeviceAttachment = () => async (dispatch: any) => {
	dispatch({ type: ActionType.ResetDeviceAttachment });
};

export const deviceAttachmentsReducer = (
	state = initialDeviceAttachmentsState,
	action: any
): DeviceAttachmentsState => {
	switch (action.type) {
		case ActionType.LoadDeviceAttachmentsRequest:
			return { ...state, totalDeviceAttachments: 0, deviceAttachments: [] };
		case ActionType.LoadDeviceAttachmentsSuccess:
			return {
				...state,
				totalDeviceAttachments: action.totalDeviceAttachments,
				deviceAttachments: action.deviceAttachments
			};
		case ActionType.ResetDeviceAttachment:
			return { ...state, deviceAttachments: [] };
		default:
			return state;
	}
};
