import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import _ from 'lodash';

import { ApplicationState, FacilityContactListState, updateContactForFacility, alertService } from 'src/redux';
import { ContactCardComponent } from './contact-card-component';
import { DropDownOption, FacilityEntityContact } from '@rcp/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { SingleSelectDropdown } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { FacilitySvg } from 'src/components/svg';
import { getContactDisplayName } from 'src/features/contacts/contacts.service';

interface OwnProps {}

interface StateProps extends FacilityContactListState {}

interface DispatchProps {
	updateContactForFacility: (
		entityContact: FacilityEntityContact,
		callbackOnSuccess?: () => void
	) => Promise<boolean | undefined>;
}

type Props = StateProps & DispatchProps & OwnProps;

const PrimaryContactRightPanel: React.SFC<Props> = props => {
	const [editMode, setEditMode] = React.useState(false);
	const [contactOptionValues, setContactOptionValues] = React.useState([] as DropDownOption[]);
	const [selectedPrimaryContactValue, setSelectedPrimaryContactValue] = React.useState('');
	const [primaryContact, setPrimaryContact] = React.useState({} as FacilityEntityContact);
	const resetDropdown = (facilityContactList: FacilityEntityContact[]) => {
		let filteredContactList = facilityContactList.filter(a => a.isPrimary);
		if (filteredContactList.length > 0) {
			setSelectedPrimaryContactValue('' + filteredContactList[0].entityContactId);
		} else {
			setSelectedPrimaryContactValue('');
		}
	};

	const onToggleShowModal = (e: any) => {
		e.preventDefault();
		resetDropdown(props.facilityContactList.fogFacilityContacts);
		setEditMode(!editMode);
	};

	React.useEffect(() => {
		let contactOptionValues: DropDownOption[] = [];

		if (props.facilityContactList.fogFacilityContacts && props.facilityContactList.fogFacilityContacts.length > 0) {
			contactOptionValues = props.facilityContactList.fogFacilityContacts.map(
				(contact: FacilityEntityContact, index: number) => {
					let contactName = getContactDisplayName(contact);
					return {
						label: contactName,
						value: contact.entityContactId
					} as DropDownOption;
				}
			);

			resetDropdown(props.facilityContactList.fogFacilityContacts);
		}
		getPrimaryContact();
		setContactOptionValues(contactOptionValues);
	}, [props.facilityContactList.fogFacilityContacts]);

	const changeFormState = (e: any) => {
		let selectedValue = e.target.value;
		setSelectedPrimaryContactValue(selectedValue);
	};

	const changePrimaryContact = async (e: any) => {
		let primaryContactId = _.toNumber(selectedPrimaryContactValue);
		let contactToSave: FacilityEntityContact | undefined;
		if (Number.isInteger(primaryContactId) && primaryContactId > 0) {
			let selectedContact = props.facilityContactList.fogFacilityContacts.find(
				a => a.entityContactId === primaryContactId
			);
			if (selectedContact) {
				selectedContact.isPrimary = true;
				contactToSave = {
					entityContactId: selectedContact.entityContactId,
					isPrimary: true
				};
				await props.updateContactForFacility(contactToSave, () => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.addedPrimaryContact')
					);
				});
			}
		} else {
			//unset primary contact
			let primaryContact = props.facilityContactList.fogFacilityContacts.find(a => a.isPrimary === true);
			if (primaryContact) {
				primaryContact.isPrimary = false;
				contactToSave = {
					entityContactId: primaryContact.entityContactId,
					isPrimary: false
				};
				await props.updateContactForFacility(contactToSave, () => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.removedPrimaryContact')
					);
				});
			}
		}
		onToggleShowModal(e);
	};

	const getPrimaryContact = () => {
		if (props.facilityContactList.fogFacilityContacts) {
			let primaryContact = props.facilityContactList.fogFacilityContacts.find(a => a.isPrimary);
			if (primaryContact) {
				setPrimaryContact(primaryContact);
			} else {
				setPrimaryContact({});
			}
		}
	};

	const componentForEditMode = (
		<div>
			<SingleSelectDropdown
				id="primaryContactDropDown"
				name="primaryContactDropDown"
				label=""
				className="w-100 mb-3"
				value={_.toString(selectedPrimaryContactValue)}
				onChange={changeFormState}
				options={contactOptionValues}
				autoFocus={true}
			/>
			<div className="d-flex justify-content-end ">
				<button type="button" className="btn ai-action" onClick={e => changePrimaryContact(e)}>
					{localizationService.getLocalizedString('screen.buttons.apply')}
				</button>
				<button type="button" className="ml-2 btn ai-white" onClick={e => onToggleShowModal(e)}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		</div>
	);

	const addPrimaryContactComponent = (
		<div>
			<a href="#/" className="ai-add-link" onClick={e => onToggleShowModal(e)}>
				{localizationService.getLocalizedString('screen.buttons.addContact')}…
			</a>
		</div>
	);

	const primaryContactDetailsComponent = (
		<div className="media" key={'PrimaryContactCard'}>
			<span className="align-self-start mr-3 ">
				<FontAwesomeIcon icon={faStar} color="#666666" size="xs" />
			</span>
			<div className="media-body">
				<ContactCardComponent item={primaryContact} isForRightPanel={true} />
			</div>
		</div>
	);

	const componentForNonEditMode = _.isEmpty(primaryContact)
		? addPrimaryContactComponent
		: primaryContactDetailsComponent;

	return (
		<section>
			<div id="primaryContacts" className="d-flex">
				<h3>{localizationService.getLocalizedString('screen.labels.primaryContact')}</h3>
				{_.isEmpty(primaryContact) ? null : (
					<a href="#/" className="font-size-16px ai-edit-link ml-auto mt-n1" onClick={onToggleShowModal}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				)}
			</div>

			{editMode ? componentForEditMode : componentForNonEditMode}
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityContacts };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		updateContactForFacility: (entityContact: FacilityEntityContact, callbackOnSuccess?: () => void) =>
			dispatch(updateContactForFacility(entityContact, callbackOnSuccess))
	};
};

export const PrimaryContactRightPanelComponent = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(PrimaryContactRightPanel);
