import { History } from 'history';
import React from 'react';

export const navigateTo = (history: History, path: string | undefined, e?: any, state?: any) => {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}
	if (path) {
		let indexOfProtocol = path.indexOf('://');
		if (indexOfProtocol > 0) {
			let baseUrl = window.location.protocol + '//' + window.location.host;
			let sanitizedPath = path.replace(baseUrl, '');
			history.push(sanitizedPath, state);
		} else {
			history.push(path, state);
		}
	}
};

export const navigateToRoute = <T>(history: History, tab: T, url: string) => {
	history.push({
		pathname: `${url}/${tab}`,
		state: {
			tabId: tab
		}
	});
};

//React FC(Function Component) forceUpdate hook
export const useForceUpdate = () => {
	const [value, setValue] = React.useState(0);
	return () => setValue(value => value + 1);
};
