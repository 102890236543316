import React, { useState } from 'react';
import { History } from 'history';
import _ from 'lodash';
import {
	ColumnField,
	FilterType,
	LocalStorageName,
	SystemAuthority,
	RouteProps,
	RegulatoryProgramName
} from '@rcp/types';
import { GridOption, DataGrid } from '../../features/data-grid/data-grid';
import { RestSlice } from '../../redux/rest.slice';
import { urlService, localizationService, apiService, Logger } from 'src/services';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { nameof } from 'ts-simple-nameof';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { OperationLog } from '@rcp/types/src';
import { operationLogSlice } from './operation-log.slice';
import { useDispatch } from 'react-redux';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const CustomerSuccessOperationLogComponent: React.FunctionComponent<Props<SystemAuthority>> = props => {
	const currentUser = useReduxSelector(state => state.userProfile);
	const dispatch = useDispatch();
	const retriggerOperationLog = (log: OperationLog) => {
		Logger.info(log);
		try {
			apiService
				.httpGet(
					`${urlService.getApiAdminBaseUrl()}/operation/FireAuthorityDailyCleaningNotice/${
						log.authorityOrganizationId
					}/${log.operationLogId}`
				)
				.then((response: string) => {
					alertService.addSuccess(localizationService.getLocalizedString('operationLog.operationRestarted'));
					dispatch(operationLogSlice.fetchAll());
				});
		} catch (ex) {
			alertService.addError(ex);
		}
	};

	const subDomainToLink = (item: any) => {
		const dataItem = item.dataItem as OperationLog;
		return (
			<td>
				<a
					target="_blank"
					rel="noopener noreferrer"
					href={`${urlService.getReactBaseUrlForSubDomain(_.toLower(dataItem.authoritySubdomain))}`}>
					{dataItem.authoritySubdomain}
				</a>
			</td>
		);
	};

	const actionCell = (item: any) => {
		const dataItem = item.dataItem as OperationLog;
		if (dataItem.status == 'Failed') {
			return (
				<td>
					<a
						href="#/"
						onClick={e => {
							e.preventDefault();
							retriggerOperationLog(dataItem);
						}}
						id={`retriggerLink_` + dataItem.operationLogId}
						className="ai-link">
						{localizationService.getLocalizedString('operationLog.retrigger')}
					</a>
				</td>
			);
		} else return <td></td>;
	};

	const [initialGridOption, setInitialGridOption] = useState<GridOption>({
		pageTitle: localizationService.getLocalizedString('systemSupportOnlySettings.operationLog'),
		prefix: 'operationLog',
		storageName: LocalStorageName.OperationLog,
		showEditColumnButton: true,
		showRefreshButton: true,
		showBackButton: _.toLower(urlService.getCurrentProgramOrDefault()) === _.toLower(RegulatoryProgramName.FOG),
		defaultSort: [{ field: nameof<OperationLog>(x => x.logDateTimeUtc), dir: 'desc' }],
		allColumns: [
			new ColumnField('authorityName'),
			new ColumnField('authoritySubdomain', FilterType.Text, subDomainToLink),
			new ColumnField('operationLogType'),
			new ColumnField('description'),
			new ColumnField('status'),
			new ColumnField('logDateTimeUtcString'),
			new ColumnField('operationUserName'),
			new ColumnField('actions', FilterType.Text, actionCell, undefined, false)
		],
		defaultColumns: [
			'authorityName',
			'authoritySubdomain',
			'operationLogType',
			'description',
			'status',
			'logDateTimeUtcString',
			'operationUserName',
			'actions'
		]
	});

	return (
		<>
			{currentUser.userProfile.isInternalAccount ? (
				<>
					<DataGrid
						history={props.history}
						match={props.match}
						restSlice={operationLogSlice}
						restState={(state: RootState) => state.operationLog}
						gridOption={initialGridOption}
					/>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</>
	);
};
