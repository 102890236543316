import React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { DeviceCleaningNoticeSteps, Recipient } from '@rcp/types';
import { localizationService, Resource, urlService } from 'src/services';
import { recipientSlice } from './recipient-slice';
import { useDispatch } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { useHistory } from 'react-router';
import { SingleCheckbox } from 'src/components/widgets';

export const Step2 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [recipient, setRecipient] = React.useState(false);
	const [showWarningIfNoContactWithEmail, setShowWarningIfNoContactWithEmail] = React.useState<boolean>(false);
	const [loading, setLoading] = React.useState<boolean>(true);
	const { STEP2, STEP1 } = DeviceCleaningNoticeSteps;
	const dispatch = useDispatch();
	const history = useHistory();
	const queryParams = history.location.search;

	React.useEffect(() => {
		loadContacts();
		const step2Data = stepperContext.getData(STEP2);
		step2Data && setRecipient(!!step2Data.shouldSendNoticeToFacilityAssignedToUser);
	}, []);

	const loadContacts = () => {
		let contactWithEmails: Recipient[] = [];
		let url = `${Resource.Extractors}/${urlService.getFogDeviceId() ? urlService.getFogDeviceId() + '/' : ''}${
			Resource.SendNoticeContacts
		}`;
		if (queryParams) {
			url += queryParams;
		}
		recipientSlice.setApiUrlPath(url);
		dispatch(
			recipientSlice.fetchAll(undefined, (contacts: Recipient[]) => {
				contacts.map((contact: Recipient) => {
					contact.email && contactWithEmails.push(contact);
				});

				setShowWarningIfNoContactWithEmail(!contactWithEmails.length);
				setLoading(false);
			})
		);
	};

	const onClickBack = () => stepperContext.goAt(STEP1);

	const onClickNext = () => stepperContext.resolve({ shouldSendNoticeToFacilityAssignedToUser: recipient });

	return (
		<StepperContent
			id={STEP2}
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.back')}
					</StepperAction>
					<StepperAction
						disabled={showWarningIfNoContactWithEmail || loading}
						type="button"
						id="btn-next"
						className="btn btn-link"
						onClick={onClickNext}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.next')}
					</StepperAction>
				</React.Fragment>
			}>
			{showWarningIfNoContactWithEmail && (
				<Notification
					icon={<SvgWarning />}
					className="recipient-warning d-inline-block p-0 mb-3"
					message={localizationService.getLocalizedString('extractor.cleaningNotice.noEmailWarning')}
					showCloseButton={false}
				/>
			)}
			<p>{localizationService.getLocalizedString('extractor.cleaningNotice.recipientDesc')}</p>
			<SingleCheckbox
				id="shouldSendNoticeToFacilityAssignedToUser"
				name="recipient"
				checked={recipient}
				label={localizationService.getLocalizedString('extractor.cleaningNotice.recipientCheck')}
				onChange={() => setRecipient(!recipient)}
			/>
		</StepperContent>
	);
};
