import React from 'react';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, tokenService, urlService } from 'src/services';
import { PopoverModal } from 'src/components/widgets';

interface Props {
	showModal: boolean;
	modalTitle: string;
	onCancel: () => void;
}

const DownloadAggreementModal: React.FC<Props> = (props: Props) => {
	const onDownload = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}${Resource.DownloadSignatory}?organizationRegulatoryProgramId=${
			tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
		}`;
		try {
			await apiService.getResource(url);
			props.onCancel();
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};
	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					save={onDownload}
					saveButtonText={localizationService.getLocalizedString('ipp.buttons.downloadSignatoryAgreement')}
					cancel={props.onCancel}>
					<p id="downloadSignatoryAgreementMessage">
						{localizationService.getLocalizedString(
							'ipp.accountPortal.downloadSignatoryAgreementModalMessage'
						)}
					</p>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
};

export default DownloadAggreementModal;
