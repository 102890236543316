import React, { FC } from 'react';
import { History } from 'history';
import { ippTemplateSlice } from './templates.slice';
import * as LinkoTypes from '@rcp/types';
import { IppReportPackageTemplate, ColumnField, FilterType, LocalStorageName } from '@rcp/types';
import { RestSlice } from '../../../../../redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { IppConstants } from 'src/constants';
import { Utils } from 'src/services/utils';
import { nameof } from 'ts-simple-nameof';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const { initialPage } = IppConstants.queryParams;

const IppReportPackageTemplatesComponent: FC<Props<IppReportPackageTemplate>> = props => {
	const templateNameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackageTemplate;
		const url = urlService.getReactAuthorityResourceUrl(
			`authority/${Resource.IppReportPackageTemplates}`,
			dataItem.reportPackageTemplateId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: true,
		prefix: 'ipp.reportPackage.templates',
		storageName: LocalStorageName.IppReportPackageTemplateGrid,
		searchHint: localizationService.getLocalizedString('ipp.reportPackage.templates.searchHint'),
		showActions: false,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.templates.title'),
		sort: [],
		defaultSort: [{ field: nameof<IppReportPackageTemplate>(d => d.name), dir: 'asc' }],
		showEditColumnModal: false,
		showAddModal: true,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('ipp.buttons.createNew'),
		toggleAddModal: () => {
			const url = urlService.getIppAuthorityUrl(`${Resource.IppReportPackageTemplates}/new`);
			navigateTo(props.history, url);
		},
		showScheduleInspectionModal: false,
		queryParameters: { page: initialPage, includeInactive: 'true', sort: 'name asc' },
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		isPaginatedSearch: true,
		allColumns: [
			new ColumnField('name', FilterType.Text, templateNameToLink),
			new ColumnField('description'),
			new ColumnField('activeStatus'),
			new ColumnField('effectiveDateTimeLocal', FilterType.Date),
			new ColumnField('isSubmissionBySignatoryRequired', FilterType.Boolean),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isAdhocReportAllowed', FilterType.Boolean),
			new ColumnField('isPeriodRequired', FilterType.Boolean),
			new ColumnField('submissionAcceptanceRequired', FilterType.Boolean)
		],

		defaultColumns: [
			'name',
			'description',
			'effectiveDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName',
			'activeStatus'
		]
	};
	const templateState = (state: RootState) => state.ippReportPackageTemplates;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippTemplateSlice}
				restState={templateState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppReportPackageTemplatesComponent;
