import React from 'react';
import { Step1, Step2, Step3, Step4 } from './facilities-bulk-notice-steps';
import Stepper, { StepperAction, Step } from 'src/components/widgets/stepper';
import { FacilityBulkNoticeSteps, FeatureNames } from '@rcp/types';
import { localizationService } from 'src/services';
import { History } from 'history';
import { RouteProps } from '@rcp/types/src';
import { useReduxSelector } from '../../../../../redux';
import { NotFound } from '../../../../../features/home/not-found';

const { STEP1, STEP2, STEP3, STEP4 } = FacilityBulkNoticeSteps;

interface Props extends RouteProps {
	history: History;
}
export const FacilitiesBulkNotice: React.FC<Props> = props => {
	return (
		<div className="page">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('bulkNotice.sendNotice')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main-settings">
					<section>
						<Stepper vertical initialStep={STEP1}>
							<Step
								title={localizationService.getLocalizedString('bulkNotice.selectATemplate')}
								stepId={STEP1}>
								<Step1 />
							</Step>
							<Step
								stepId={STEP2}
								title={localizationService.getLocalizedString('bulkNotice.chooseRecipients')}>
								<Step2 />
							</Step>
							<Step
								stepId={STEP3}
								title={localizationService.getLocalizedString('bulkNotice.editContent')}>
								<Step3 />
							</Step>
							<Step
								stepId={STEP4}
								title={localizationService.getLocalizedString('bulkNotice.previewAndSend')}>
								<Step4 match={props.match} history={props.history} />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};
