import React from 'react';

import { apiService, localizationService, Resource, urlService, UtilService, validationService } from 'src/services';
import {
	ApiError,
	SystemSettingValue,
	MaintenanceMessageSetting,
	SystemSettingType,
	RegulatoryProgramName
} from '@rcp/types';
import { alertService } from 'src/redux/alert';

import _ from 'lodash';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { useReduxDispatch, loadUserProfile, useReduxSelector } from 'src/redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { SingleCheckbox, TextAreaInput } from 'src/components';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

interface SystemSettingError {
	systemMaintenanceMessageTextError?: string;
}
let initialSystemSettingError = {} as SystemSettingError;
let initialMaintenanceMessageSetting = {} as MaintenanceMessageSetting;

const MaintenanceMessageSettingsComponent: React.FunctionComponent = () => {
	const [maintenanceMessageSetting, setMaintenanceMessageSetting] = React.useState(initialMaintenanceMessageSetting);
	const [originalMaintenanceMessageSetting, setOriginalMaintenanceMessageSetting] = React.useState(
		initialMaintenanceMessageSetting
	);
	const [systemSettingError, setSystemSettingError] = React.useState(initialSystemSettingError);

	const maintenanceMessageSettingsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.SystemSettings}/${
		Resource.MaintenanceMessage
	}`;
	const dispatch = useReduxDispatch();

	const currentUser = useReduxSelector(state => state.userProfile);

	React.useEffect(() => {
		dispatch(loadUserProfile());
	}, [dispatch]);

	React.useEffect(() => {
		loadMaintenanceMessageSettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadMaintenanceMessageSettings = async () => {
		apiService
			.getResource<SystemSettingValue[]>(maintenanceMessageSettingsUrl)
			.then((values: SystemSettingValue[]) => {
				let initialMaintenanceMessageSetting = { ...maintenanceMessageSetting };
				for (let systemSettingValue of values) {
					let key = UtilService.toCamelCase(systemSettingValue.systemSettingType);
					if (String.equalCaseInsensitive(key, SystemSettingType.SystemMaintenanceMessageIsEnabled)) {
						initialMaintenanceMessageSetting[key] = systemSettingValue.value === 'True';
					} else {
						initialMaintenanceMessageSetting[key] = systemSettingValue.value;
					}
				}
				setMaintenanceMessageSetting(initialMaintenanceMessageSetting);
				setOriginalMaintenanceMessageSetting(initialMaintenanceMessageSetting);
			});
	};

	const onMaintenanceMessageSettingChanged = (e: any) => {
		let newSetting = { ...maintenanceMessageSetting };
		const { name, value } = e.target;
		_.set(newSetting, name, value);
		setMaintenanceMessageSetting(newSetting);
	};

	const updateMaintenanceMessageValue = (e: any) => {
		let { name, value, type, required } = e.target;
		if (required) {
			let errorObject = { ...systemSettingError };
			let isValid = true;
			isValid = validationService.validateRequiredValue(value, errorObject, `${name}Error`);
			setSystemSettingError(errorObject);
			if (isValid === false) {
				return;
			}
		}
		if (type === 'checkbox') {
			value = e.target.checked;
		}

		let dataToUpdate = {
			systemSettingType: name,
			value: value
		};

		if (originalMaintenanceMessageSetting[name] === value) {
			return;
		}

		let newSetting = { ...maintenanceMessageSetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setMaintenanceMessageSetting(newSetting);

		apiService
			.postResource(maintenanceMessageSettingsUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				_.unset(systemSettingError, `${name}Error`);
				let updateMaintenanceMessageSetting = { ...originalMaintenanceMessageSetting };
				updateMaintenanceMessageSetting[name] = value;
				setOriginalMaintenanceMessageSetting(updateMaintenanceMessageSetting);
				setMaintenanceMessageSetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setMaintenanceMessageSetting(newSetting);
			});
	};

	return (
		<div className="page">
			{currentUser.userProfile.isInternalAccount ? (
				<>
					<div className="page-header">
						{String.equalCaseInsensitive(
							urlService.getCurrentProgramOrDefault(),
							RegulatoryProgramName.FOG
						) && <BackArrow />}
						<h1>
							{localizationService.getLocalizedString('systemSupportOnlySettings.maintenanceMessage')}
						</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">{getMaintenanceMessage()}</div>
					</div>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</div>
	);

	function getMaintenanceMessage() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<p>
						{localizationService.getLocalizedString(
							'systemSupportOnlySettings.maintenanceMessageDescription'
						)}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<SingleCheckbox
							id="systemMaintenanceMessageIsEnabled"
							name="systemMaintenanceMessageIsEnabled"
							label={localizationService.getLocalizedString(
								'systemSupportOnlySettings.maintenanceMessageIsEnabled'
							)}
							checked={maintenanceMessageSetting.systemMaintenanceMessageIsEnabled === true}
							onChange={updateMaintenanceMessageValue}
						/>
						<TextAreaInput
							id="systemMaintenanceMessageText"
							name="systemMaintenanceMessageText"
							label={localizationService.getLocalizedString(
								'systemSupportOnlySettings.maintenanceMessageText'
							)}
							isFullWidth={true}
							value={maintenanceMessageSetting.systemMaintenanceMessageText}
							rows={4}
							onBlur={updateMaintenanceMessageValue}
							onEnterKeyPressed={updateMaintenanceMessageValue}
							onChange={onMaintenanceMessageSettingChanged}
							maxLength={500}
							isRequired={true}
							error={systemSettingError.systemMaintenanceMessageTextError}
						/>
					</section>
				</div>
			</div>
		);
	}
};

export const SystemSettingMaintenanceMessage = MaintenanceMessageSettingsComponent;
