import { LookupRow } from '../lookup-types';
import { LookupType, CorrectiveAction } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class CorrectiveActionLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: CorrectiveAction): LookupRow => {
		return {
			lookupId: apiData.correctiveActionId,
			code: apiData.description,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let correctiveAction = {
			correctiveActionId: rowToUpdate.lookupId,
			description: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as CorrectiveAction;
		return correctiveAction;
	}

	toLookupRowsFromAPI(apiData: CorrectiveAction[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): CorrectiveAction {
		let correctiveAction = {
			description: newValue.code,
			isActive: newValue.isActive
		} as CorrectiveAction;
		return correctiveAction;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
