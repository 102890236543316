import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum UsersActionType {
	LoadUserDetailsRequest = 'loadUserDetailsRequest',
	LoadUserDetailsSuccess = 'loadUserDetailsSuccess',
	UpdateUser = 'updateUser'
}

export interface LoadUserDetailsRequest extends Action {
	type: UsersActionType.LoadUserDetailsRequest;
}

export interface LoadUserDetailsSuccess extends Action {
	type: UsersActionType.LoadUserDetailsSuccess;
	userDetails: ApiTypes.IppUser;
}

export interface UpdateUserAction extends Action {
	type: UsersActionType.UpdateUser;
}

export type UsersAction = LoadUserDetailsRequest | LoadUserDetailsSuccess | UpdateUserAction;

export const loadUserDetailsRequest = (): LoadUserDetailsRequest => ({
	type: UsersActionType.LoadUserDetailsRequest
});

export const loadUserDetailsSuccess = (userDetails: ApiTypes.IppUser): LoadUserDetailsSuccess => ({
	type: UsersActionType.LoadUserDetailsSuccess,
	userDetails: userDetails
});

export const updateUserAction = (): UpdateUserAction => ({
	type: UsersActionType.UpdateUser
});
