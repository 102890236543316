import React from 'react';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService } from 'src/services';
import { MultiSelectDropDownOption } from '@rcp/types';
import { MultiSelectDropdown } from '../../components/widgets';
import { filterService } from './filter-service';

interface FilterEnumProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	options: MultiSelectDropDownOption[];
	isForMap?: boolean;
}

export const FilterEnum: React.FC<FilterEnumProps> = props => {
	const onValueChange = (selectedValues: string[]) => {
		if (props.filterItem.values.length !== 1) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on enum filter`);
		}
		let newFilterItem = { ...props.filterItem } as FilterItem;
		newFilterItem.values[0] = {
			values: selectedValues
		};
		props.updateFilter(newFilterItem);
	};

	let operatorValue = filterService.getFilterValue(props.filterItem, 0);

	return (
		<div className="col">
			<label htmlFor={`FilterOperatorEnumValue_${props.rowNumber}`}>
				{localizationService.getLocalizedString('screen.filterModal.value')}
			</label>
			<MultiSelectDropdown
				name={`FilterOperatorBooleanValue_${props.rowNumber}`}
				onChange={onValueChange}
				options={props.options}
				filterItem={props.filterItem}
				className={`kendo-control ${operatorValue.error ? ' is-invalid' : ''}`}
				isRequired={true}
				selectedValues={operatorValue.values}
			/>
			{operatorValue.error && <div className="invalid-feedback">{operatorValue.error}</div>}
		</div>
	);
};
