import { DataImport } from '@rcp/types';
import { localizationService } from './localizationService';

export class ImportUtils {
	public static getImportEntityLabel = (importEntity: DataImport.ImportEntity, count: number) => {
		switch (importEntity) {
			case DataImport.ImportEntity.FogFacility:
				return count > 1
					? localizationService.getLocalizedString('incidents.facilities')
					: localizationService.getLocalizedString('incidents.facility');
			case DataImport.ImportEntity.FogDevice:
				return count > 1
					? localizationService.getLocalizedString('extractor.deviceMultiple')
					: localizationService.getLocalizedString('extractor.deviceSingle');
			case DataImport.ImportEntity.FogInspection:
				return count > 1
					? localizationService.getLocalizedString('screen.labels.inspections')
					: localizationService.getLocalizedString('screen.labels.inspection');
			case DataImport.ImportEntity.FogHauler:
				return count > 1
					? localizationService.getLocalizedString('haulers.haulers')
					: localizationService.getLocalizedString('haulers.hauler');
			case DataImport.ImportEntity.FogEvent:
				return count > 1
					? localizationService.getLocalizedString('events.events')
					: localizationService.getLocalizedString('events.event');
			case DataImport.ImportEntity.FogCleaning:
				return count > 1
					? localizationService.getLocalizedString('pumpOut.cleanings')
					: localizationService.getLocalizedString('pumpOut.pumpOut');
			default:
				throw new Error(`Not implemented for import data type ${importEntity}`);
		}
	};

	public static getImportHistoryEntityFromImportDataType = (importDataType: string) => {
		switch (importDataType) {
			case DataImport.ImportDataTypeValues.FogFacilitiesAndContacts:
				return DataImport.ImportEntity.FogFacility;
			case DataImport.ImportDataTypeValues.FogDevices:
				return DataImport.ImportEntity.FogDevice;
			case DataImport.ImportDataTypeValues.FogInspections:
				return DataImport.ImportEntity.FogInspection;
			case DataImport.ImportDataTypeValues.FogHaulers:
				return DataImport.ImportEntity.FogHauler;
			case DataImport.ImportDataTypeValues.FogCleanings:
				return DataImport.ImportEntity.FogCleaning;
			case DataImport.ImportDataTypeValues.FogEvents:
				return DataImport.ImportEntity.FogEvent;
			default:
				throw new Error(`Not implemented for import data type ${importDataType}`);
		}
	};

	public static getImportDataTypeWithSpaces = (importDataType: string) => {
		switch (importDataType) {
			case DataImport.ImportDataTypeValues.FogFacilitiesAndContacts:
				return localizationService.getLocalizedString('import.dataTypes.fogFacilitiesAndContacts');
			case DataImport.ImportDataTypeValues.FogDevices:
				return localizationService.getLocalizedString('import.dataTypes.fogDevice');
			case DataImport.ImportDataTypeValues.FogInspections:
				return localizationService.getLocalizedString('import.dataTypes.fogInspections');
			case DataImport.ImportDataTypeValues.FogHaulers:
				return localizationService.getLocalizedString('import.dataTypes.fogHaulers');
			case DataImport.ImportDataTypeValues.FogCleanings:
				return localizationService.getLocalizedString('import.dataTypes.fogCleanings');
			case DataImport.ImportDataTypeValues.FogEvents:
				return localizationService.getLocalizedString('import.dataTypes.fogEvents');
			default:
				throw new Error(`Not implemented for import data type ${importDataType}`);
		}
	};
}
