import { DashboardWidgetFilterCategories, FilterObjectName, NullValues } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { chartService } from 'src/components/authority/fog/dashboard/dashboard-widgets/chart-service';
import { CloseSvg } from 'src/components/svg';
interface OwnProps {
	adHocFilterKey: string;
	adHocFilterValue: string;
	handleAdHocFilterItemDelete: (adHocFilterLabel: string) => void;
}

export const AdHocFilterItemCard: React.FC<OwnProps> = props => {
	const [adHocFilterLabel, setAdHocFilterLabel] = React.useState('');

	React.useEffect(() => {
		var splitKey = props.adHocFilterKey.split('.');
		var domainObject = splitKey[0];
		var parameter = splitKey.slice(1).join('.');
		var value = props.adHocFilterValue;

		var filterCategoryGroupBy = chartService.getDashboardWidgetFilterCategoryFromFilterObjectName(
			domainObject as FilterObjectName
		);
		chartService
			.getAvailableGroupBysForFiterQuickViewer(filterCategoryGroupBy as DashboardWidgetFilterCategories)
			.then(groupByFields => {
				let groupByField = groupByFields.find(a => String.equalCaseInsensitive(parameter, a.value));
				value = value == NullValues.Null ? NullValues.Empty : value;
				setAdHocFilterLabel(`${filterCategoryGroupBy} - ${groupByField?.label} - ${value}`);
			});
	}, [props.adHocFilterKey, props.adHocFilterValue]);

	return (
		<>
			{!String.isUndefinedOrEmpty(adHocFilterLabel) && (
				<div className="filter-item-card ad-hoc">
					<div className="d-flex font-size-16px-semibold ">
						<span className="filter-item-header">{adHocFilterLabel}</span>
						<div className="d-flex ml-auto">
							<button
								className="m-auto"
								onClick={() => props.handleAdHocFilterItemDelete(props.adHocFilterKey)}>
								<CloseSvg size="10" />
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
