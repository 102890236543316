import React, { useState } from 'react';
import _ from 'lodash';
import './authentication-component.scss';
import { AlertMessageType, alertService } from 'src/redux';
import { validationService, localizationService, urlService, ApiService, apiService } from 'src/services';
import { TextInput } from 'src/components/widgets';
import { QuestionAnswerDtos, ResetPasswordTokenMfaDetails } from '@rcp/types';
import Question, { QuestionType } from './question';
import IppAccountErrorComponent from './error';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { Translate } from '../widgets/translate/translator';

interface ResetPasswordFormState {
	token: string;
	password: string;
	confirmPassword: string;
	passwordFormatError: string;
	confirmPasswordFormatError: string;
}
export const ResetPassword: React.FunctionComponent = props => {
	const [formState, setFormState] = React.useState({} as ResetPasswordFormState);
	const [successResult, setSuccessResult] = React.useState<string>();
	const [isTokenValid, setIsTokenValid] = React.useState<boolean>();
	const [mfaEnabled, setMfaEnabled] = useState(false);
	const [answer, setAnswer] = useState('');
	const [question, setQuestion] = useState([] as any[]);
	const [mfaType, setMfaType] = useState('');
	const [questionData, setQuestionData] = useState({} as QuestionAnswerDtos);
	const [showAccountLockError, setShowAccountLockError] = useState(false);

	React.useEffect(() => {
		document.body.className = 'signinBody';

		let newState = {} as ResetPasswordFormState;
		newState.token = urlService.getResetPasswordToken();
		getMfaDetails(newState.token);
		setFormState(newState);
		let url = urlService.getValidateResetTokenApiUrl();

		new ApiService(true)
			.httpGet(`${url}/${newState.token}`)
			.then(result => {
				setIsTokenValid(result);
			})
			.catch(error => {});
	}, []);

	const getMfaDetails = async (token: string) => {
		let url = `${urlService.getApiBaseUrl()}/Account/MfaDetails/${token}`;

		let mfaDetails = await apiService.httpGet(url);
		let mfa = mfaDetails.filter((mfa: ResetPasswordTokenMfaDetails) => mfa.isMFADetailsConfigured === true);
		let mfaType = mfa.length && mfa[0].mfaTypeName;
		setMfaEnabled(true);
		setMfaType(mfaType);
		if (String.equalCaseInsensitive(mfaType, 'QuestionAndAnswers')) {
			setQuestionData(mfa[0].userQuestionAnswerDto);
			setQuestion([
				{
					questionId: mfa[0].userQuestionAnswerDto.questionId,
					questionType: mfa[0].userQuestionAnswerDto.questionTypeName,
					content: mfa[0].userQuestionAnswerDto.question,
					userQuestionAnswerId: mfa[0].userQuestionAnswerDto.userQuestionAnswerId
				}
			]);
		}
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const resetPasswordBtnClicked = async (event: any) => {
		event.preventDefault();
		alertService.clearAllMessages();
		if (!validateBeforeForSave()) {
			return;
		}
		let { token, password } = { ...formState };
		let postData: any = {
			token,
			newPassword: password
		};
		if (mfaEnabled && String.equalCaseInsensitive(mfaType, 'QuestionAndAnswers')) {
			postData = {
				...postData,
				questionId: questionData.questionId,
				userQuestionAnswerId: questionData.userQuestionAnswerId,
				answer
			};
		}
		try {
			let url = urlService.getResetPasswordApiUrl();
			let result = await new ApiService(true).httpPost(url, postData);
			setSuccessResult(result);
		} catch (error) {
			if (String.equalCaseInsensitive(error.message, 'Account Locked')) {
				setShowAccountLockError(true);
			} else {
				alertService.addError(error.message);
			}
		}
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validatePasswordField(newState, 'password', 'passwordFormatError');

		if (newState.confirmPassword !== newState.password) {
			newState.confirmPasswordFormatError = localizationService.getLocalizedString('users.passwordsNotMatch');
		} else {
			_.unset(newState, 'confirmPasswordFormatError');
		}

		let isFormValid = !validationService.hasError(newState, 'passwordFormatError', 'confirmPasswordFormatError');
		if (!isFormValid) {
			setFormState(newState);
		}

		return isFormValid;
	};

	const renderTokenInvalidUI = (
		<>
			<h1>
				<Translate>{localizationService.getLocalizedString('users.incorrectLink')}</Translate>
			</h1>

			<p>
				<Translate>{localizationService.getLocalizedString('users.nonValidResetPasswordTokenPart1')}</Translate>{' '}
				&nbsp;
				<a href={`${window.location.protocol}//${window.location.host}/ForgetPassword`} id="forgetPasswordLink">
					<Translate>{localizationService.getLocalizedString('users.signInHelp')}</Translate>
				</a>
				&nbsp;
				<Translate>{localizationService.getLocalizedString('users.nonValidResetPasswordTokenPart2')}</Translate>
			</p>
		</>
	);

	const renderResetPasswordUI = (
		<>
			<h1>
				<Translate>{localizationService.getLocalizedString('users.newPassword')}</Translate>
			</h1>
			{formState.passwordFormatError || formState.confirmPasswordFormatError ? (
				<InlineAlertItem
					message={formState.passwordFormatError || formState.confirmPasswordFormatError}
					alertContainerId="reset-signin-alert"
					alertType={AlertMessageType.Error}
				/>
			) : null}
			<form>
				<div className="form-group">
					<TextInput
						type="password"
						id="password"
						name="password"
						className="w-100"
						label={localizationService.getLocalizedString('users.newPassword')}
						value={formState.password}
						onChange={changeFormState}
						isRequired={true}
						error={formState.passwordFormatError}
					/>
					<div className="form-text mt-2 ai-form-help">
						<Translate>{localizationService.getLocalizedString('users.passwordHint')}</Translate>
					</div>
				</div>
				<div className="form-group">
					<TextInput
						type="password"
						id="confirmPassword"
						name="confirmPassword"
						className="w-100"
						label={localizationService.getLocalizedString('users.confirmNewPassword')}
						value={formState.confirmPassword}
						onChange={changeFormState}
						isRequired={true}
						error={formState.confirmPasswordFormatError}
					/>
				</div>
				{mfaEnabled && String.equalCaseInsensitive(mfaType, 'QuestionAndAnswers') && (
					<>
						<div className="mt-3">
							<Translate>{localizationService.getLocalizedString('users.kbqHint')}</Translate>
						</div>
						<Question
							questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question')}
							answerLabel={questionData.question || ''}
							answer={answer}
							hideQuestion={true}
							type={QuestionType.KBQ}
							questions={question}
							showEditButton={false}
							answerType="password"
							onChangeAnswer={(e: any) => {
								setAnswer(e.target.value);
							}}
							disableQuestion={true}
						/>
					</>
				)}
				<button
					id="btnSetNewPassword"
					className="btn mt-3 ai-action btn-signin"
					onClick={resetPasswordBtnClicked}>
					<Translate>{localizationService.getLocalizedString('users.setNewPassword')}</Translate>
				</button>
			</form>
		</>
	);

	const renderResetPasswordSuccessResultUI = (
		<>
			<h1>
				<Translate>{localizationService.getLocalizedString('users.passwordUpdateSuccessMessage')}</Translate>
			</h1>

			<div className="signin-footer">
				<a id="backToSignInLink" href={urlService.getLoginUrl()} className="btn ai-action btn-signin">
					<Translate>{localizationService.getLocalizedString('authentication.backToSignIn')}</Translate>
				</a>
			</div>
		</>
	);

	const renderAccountLockedUI = (
		<>
			<IppAccountErrorComponent
				errorTitle={localizationService.getLocalizedString('ipp.accountErrorMessages.accountLockedTitle')}
				errorDescription={localizationService.getLocalizedString(
					'ipp.accountErrorMessages.accountLockedDescription'
				)}
				showSignInButton={true}
			/>
		</>
	);

	return (
		<div className={`signin ${showAccountLockError ? 'px-3' : ''}`}>
			{isTokenValid === false ? (
				renderTokenInvalidUI
			) : (
				<>
					{successResult
						? renderResetPasswordSuccessResultUI
						: showAccountLockError
						? renderAccountLockedUI
						: renderResetPasswordUI}
				</>
			)}
		</div>
	);
};
