import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AuthorityTag } from '@rcp/types';
import { apiService, Resource, urlService } from 'src/services';

export interface AuthorityTagsState {
	tags: AuthorityTag[];
}

export const initialAuthorityTagsState: AuthorityTagsState = {
	tags: []
};

enum ActionType {
	LoadAuthorityTagsRequest = 'loadAuthorityTagsRequest',
	LoadAuthorityTagsSuccess = 'loadAuthorityTagsSuccess'
}

export type AuthorityTagsThunkAction = ThunkAction<any, AuthorityTagsState, any, Action>;

export const loadAuthorityTags = (): AuthorityTagsThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.LoadAuthorityTagsRequest });

	let url = urlService.getAuthorityLookupResourceApiUrl(Resource.Tags);
	let authorityTags = await apiService.getResource<AuthorityTag[]>(url);
	dispatch({ type: ActionType.LoadAuthorityTagsSuccess, authorityTags });
};

export const authorityTagsReducer = (state = initialAuthorityTagsState, action: any): AuthorityTagsState => {
	switch (action.type) {
		case ActionType.LoadAuthorityTagsRequest:
			return { ...state, tags: [] };
		case ActionType.LoadAuthorityTagsSuccess:
			return { ...state, tags: action.authorityTags };
		default:
			return state;
	}
};
