import React, { FC } from 'react';
import { TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { urlService } from 'src/services';
import { DateUtilService } from 'src/services/date-util-service';
import { localizationService } from 'src/services/localizationService';

interface ReportDetails {
	reportPackageId?: number;
	name?: string;
	periodStartDateTimeLocal?: string;
	periodEndDateTimeLocal?: string;
	organizationReferenceNumber?: string;
	organizationName?: string;
	ctsEventTypeName?: string;
	ctsEventCategoryName?: string;
	ctsEventId?: number;
}

interface Props extends ReportDetails {}

const IppPackageItemReportDetails: FC<Props> = (props: Props) => {
	const getReportPeriod = (): string => {
		if (props.periodStartDateTimeLocal && props.periodEndDateTimeLocal) {
			if (
				DateUtilService.isValidDateTime(props.periodStartDateTimeLocal) &&
				DateUtilService.isValidDateTime(props.periodEndDateTimeLocal)
			) {
				let startDate = DateUtilService.toDisplayDate(props.periodStartDateTimeLocal);
				let endDate = DateUtilService.toDisplayDate(props.periodEndDateTimeLocal);
				return `${startDate} - ${endDate}`;
			} else {
				return props.periodStartDateTimeLocal || props.periodEndDateTimeLocal;
			}
		}
		return '';
	};
	return (
		<>
			<CollapsibleCard
				accordionType="report-details"
				accordionHeading={localizationService.getLocalizedString('ipp.reportPackage.package.report')}>
				<div className="w-100 mx-auto">
					<div className="form-row">
						<TextInput
							id="report-name"
							name="name"
							label={localizationService.getLocalizedString('ipp.reportPackage.package.columns.name')}
							className="col-md-5"
							isDisabled={true}
							value={props.name}
						/>
						<TextInput
							id="report-period"
							name="reportPeriod"
							label={localizationService.getLocalizedString('ipp.reportPackage.package.reportPeriod')}
							className="col-md-5"
							isDisabled={true}
							value={getReportPeriod()}
						/>
						<TextInput
							id="report-ctsEventId"
							name="ctsEventId"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.ctsEventId'
							)}
							className="col-md-2"
							isDisabled={true}
							value={props.ctsEventId}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="industry-name"
							name="organizationName"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.organizationName'
							)}
							className="col-md-5"
							isDisabled={true}
							value={props.organizationName || ''}
						/>

						<TextInput
							id="industry-no"
							name="organizationReferenceNumber"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.organizationReferenceNumber'
							)}
							className="col-md-5"
							isDisabled={true}
							value={props.organizationReferenceNumber || ''}
						/>
					</div>
					{!urlService.isIppIndustryPortal() && (
						<div className="form-row">
							<TextInput
								id="report-package-id"
								name="reportPackageId"
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.reportPackageId'
								)}
								className="col-md-5"
								isDisabled={true}
								value={(props.reportPackageId && props.reportPackageId.toString()) || ''}
							/>

							<TextInput
								id="cts-event-type"
								name="ctsEventTypeName"
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.ctsEventTypeName'
								)}
								className="col-md-5"
								isDisabled={true}
								value={`${props.ctsEventTypeName || ''} ${
									props.ctsEventCategoryName ? '(' + props.ctsEventCategoryName + ')' : ''
								}`}
							/>
						</div>
					)}
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageItemReportDetails;
