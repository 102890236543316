import { DateUtilService } from './date-util-service';
import _ from 'lodash';
import { localizationService } from './localizationService';
import {
	minimumAllowedDateForDatePicker,
	MergeFieldDto,
	ICustomFormInputElement,
	CustomFormFieldType,
	UploadMaximumSize50MB
} from '@rcp/types';
import { alertService } from 'src/redux';

type TValidation = 'required' | 'password' | 'url' | 'date' | 'email';
type TFieldToValidate = { fieldName: string; fieldLabel: string; value: any; validations: TValidation[] };
const { Date } = CustomFormFieldType;
const LATITUDE_COORDINATE_LIMIT: number = 90;
const LONGITUDE_COORDINATE_LIMIT: number = 180;

class ValidationService {
	validateCustomFormField = (customFields: ICustomFormInputElement[]) => {
		_.each(customFields, customField => {
			if (customField.fieldId === 'isRepairNeededReason' && !customFields[0].value) {
				return _.unset(customField, 'error');
			}
			switch (customField.fieldType) {
				case Date:
					customField.isRequired
						? this.validateRequiredDateField(customField, 'value', 'error', customField.fieldLabel)
						: this.validateMinimumDate(customField, 'value', 'error', customField.fieldLabel);
					break;
				case CustomFormFieldType.Number:
					this.validateNumberField(
						customField,
						'value',
						'error',
						customField.isRequired,
						customField.fieldLabel
					);
					break;
				default:
					customField.isRequired &&
						this.validateRequiredField(customField, 'value', 'error', customField.fieldLabel);
			}
		});
	};
	validateMergeFields(content: string, mergeFields: MergeFieldDto[]): boolean {
		let mergeFieldRegex = /[^{{]+(?=}\})/g;
		let curMatch;
		let requiredMergeFields: any[] = [];
		let mergeOptions: Array<any> = mergeFields.map(field => {
			let mergeOption = field.prefix ? `${field.prefix} - ${field.label}` : field.label;
			field.required && requiredMergeFields.push(mergeOption);
			return mergeOption;
		});

		let divElement = document.createElement('div');
		divElement.innerHTML = content.replaceAll('&nbsp;', ' ');
		let incorrectMergeFields = [];
		let correctMergeFields: string[] = [];
		let validationArray = [];

		for (let i = 0; i <= divElement.innerText.length; i++) {
			if (divElement.innerText.substring(i, i + 2) == '{{') {
				validationArray.push('{{');
				i = i + 2;
			} else if (divElement.innerText.substring(i, i + 2) == '}}' && validationArray.pop() !== '{{') {
				alertService.clearAllMessages();
				alertService.addError(
					localizationService.getLocalizedString(
						'extractor.cleaningNotice.mergeFieldSyntaxWarning',
						'extractor.cleaningNotice.opening'
					)
				);
				i = i + 2;
				return false;
			}
		}

		if (validationArray.length > 0) {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString(
					'extractor.cleaningNotice.mergeFieldSyntaxWarning',
					'extractor.cleaningNotice.closing'
				)
			);
			return false;
		}

		while ((curMatch = mergeFieldRegex.exec(divElement.innerText))) {
			if (
				mergeOptions.includes(curMatch[0]) ||
				mergeOptions.includes(_.trimEnd(_.first(_.split(curMatch[0], '|'))))
			) {
				correctMergeFields.push(curMatch[0]);
			} else {
				incorrectMergeFields.push('{{' + curMatch[0] + '}}');
			}
		}
		let incorrectMergeFieldsLength = incorrectMergeFields.length;
		alertService.clearAllMessages();
		incorrectMergeFieldsLength &&
			alertService.addError(
				`${incorrectMergeFields.join(' ')} ${localizationService.getLocalizedString(
					'extractor.cleaningNotice.incorrectMergeTagWarning',
					incorrectMergeFieldsLength > 1 ? 'are' : 'is',
					incorrectMergeFieldsLength > 1 ? 'Tags' : 'Tag'
				)}`
			);

		let containsAllRequiredFields = true;
		if (requiredMergeFields.length > 0) {
			for (var requiredMergeField of requiredMergeFields) {
				if (!correctMergeFields.includes(requiredMergeField)) {
					containsAllRequiredFields = false;
					alertService.addError(
						localizationService.getLocalizedString('errors.requiredMergeTagNotFound', requiredMergeField)
					);
				}
			}
		}
		return incorrectMergeFields.length > 0 && containsAllRequiredFields ? false : true;
	}

	validateDoNotTranslateFields(content: string): boolean {
		let divElement = document.createElement('div');
		divElement.innerHTML = content.replaceAll('&nbsp;', ' ');
		let validationArray: number[] = [];
		let doNotTranslateStartLocations: number[] = [];
		let doNotTranslateEndLocations: number[] = [];

		for (let i = 0; i <= divElement.innerText.length; i++) {
			if (divElement.innerText.substring(i, i + 2) == '[[') {
				validationArray.push(i);
				i += 1;
			} else if (divElement.innerText.substring(i, i + 2) == ']]') {
				let errorsFound = this.validateDoNotTranslateOrdering(
					validationArray,
					doNotTranslateStartLocations,
					doNotTranslateEndLocations,
					divElement.innerText,
					i
				);

				if (errorsFound != null) {
					return errorsFound;
				}
				i += 1;
			}
		}

		if (doNotTranslateStartLocations.length > 0) {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString('extractor.cleaningNotice.doNotTranslateOrderWarning')
			);
			return false;
		}

		if (validationArray.length > 0) {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString(
					'extractor.cleaningNotice.doNotTranslateSyntaxWarning',
					'extractor.cleaningNotice.closing'
				)
			);
			return false;
		}

		return true;
	}

	validateDoNotTranslateOrdering(
		validationArray: number[],
		doNotTranslateStartLocations: number[],
		doNotTranslateEndLocations: number[],
		divElementInnerText: string,
		index: number
	): boolean | undefined {
		var poppedValidateArrayElement = validationArray.pop();
		if (poppedValidateArrayElement != null) {
			let possibleDoNotTranslateTag = divElementInnerText.substring(poppedValidateArrayElement + 2, index).trim();
			if (
				String.equalCaseInsensitive(
					possibleDoNotTranslateTag,
					localizationService.getLocalizedString('languages.doNotTranslateStart')
				)
			) {
				doNotTranslateStartLocations.push(index);
			} else if (
				String.equalCaseInsensitive(
					possibleDoNotTranslateTag,
					localizationService.getLocalizedString('languages.doNotTranslateEnd')
				)
			) {
				// Do not translate start and end do not match up
				let lastDoNotTranslateStartLocation = doNotTranslateStartLocations.pop();
				if (!lastDoNotTranslateStartLocation) {
					alertService.clearAllMessages();
					alertService.addError(
						localizationService.getLocalizedString('extractor.cleaningNotice.doNotTranslateOrderWarning')
					);
					return false;
				}
			}
		} else {
			alertService.clearAllMessages();
			alertService.addError(
				localizationService.getLocalizedString(
					'extractor.cleaningNotice.doNotTranslateSyntaxWarning',
					'extractor.cleaningNotice.opening'
				)
			);
			return false;
		}
	}

	validatePasswordField(object: any, field: string, fieldToPassError: string, fieldLabel?: string): boolean {
		let password = _.get(object, field);
		let passwordValidator = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

		var pass = passwordValidator.test(password);
		if (!pass) {
			object[fieldToPassError] = localizationService.getLocalizedString('users.passwordRequirementsNotMet');
			return false;
		}
		_.unset(object, fieldToPassError);
		return true;
	}

	validateUploadSize(size: number, maxUploadSize: number = UploadMaximumSize50MB) {
		return Number(size) <= Number(maxUploadSize);
	}

	validateRequiredValue(
		value: any,
		errorObject: any,
		fieldToPassError: string,
		fieldLabel?: string,
		customRequiredMessage?: string
	): boolean {
		if (
			value === undefined ||
			value === null ||
			(_.isString(value) && value.trim().length === 0) ||
			(_.isArray(value) && value.length < 1)
		) {
			if (customRequiredMessage) {
				errorObject[fieldToPassError] = customRequiredMessage;
			} else if (fieldLabel) {
				errorObject[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.fieldValueIsRequired',
					fieldLabel
				);
			} else {
				errorObject[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.fieldIsRequired'
				);
			}
			return false;
		} else {
			_.unset(errorObject, fieldToPassError);
			return true;
		}
	}

	validateRequiredField(
		object: any,
		field: string,
		fieldToPassError: string,
		fieldLabel?: string,
		customRequiredMessage?: string
	): boolean {
		var value = _.get(object, field);
		return this.validateRequiredValue(value, object, fieldToPassError, fieldLabel, customRequiredMessage);
	}

	validateFieldLength(
		object: any,
		field: string,
		fieldToPassError: string,
		maxLength: number,
		fieldLabel?: string
	): boolean {
		var value = _.get(object, field);
		if (!_.isEmpty(value) && value.length > maxLength) {
			object[fieldToPassError] = localizationService.getLocalizedString(
				'screen.validationMessage.exceedMaxLengthString',
				maxLength.toString()
			);
			return false;
		} else {
			_.unset(object, fieldToPassError);
			return true;
		}
	}

	validateMinimumDate(object: any, field: string, fieldToPassError: string, fieldLabel?: string) {
		let date = _.get(object, field);
		let minDate = DateUtilService.prepareDateStringForApiCall(minimumAllowedDateForDatePicker);
		if (date && (_.isString(date) || _.isDate(date))) {
			if (DateUtilService.isBefore(date as string, minDate)) {
				object[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.minimumDateCheckMessage',
					fieldLabel ? fieldLabel : 'screen.validationMessage.field'
				);

				return false;
			}

			_.unset(object, fieldToPassError);
			return true;
		}
	}

	validateMaximumDate(
		object: any,
		field: string,
		fieldToPassError: string,
		fieldLabel?: string,
		maxDate: string | undefined = undefined
	) {
		let date = _.get(object, field);
		if (date && (_.isString(date) || _.isDate(date))) {
			if (maxDate) {
				if (DateUtilService.isAfterByDay(date as string, maxDate)) {
					if (fieldLabel) {
						object[fieldToPassError] = localizationService.getLocalizedString(
							'screen.validationMessage.exceedMaximumValue',
							fieldLabel,
							DateUtilService.toDisplayDate(maxDate)
						);
					} else {
						object[fieldToPassError] = localizationService.getLocalizedString(
							'screen.validationMessage.exceedMaximumValue',
							'screen.validationMessage.field',
							DateUtilService.toDisplayDate(maxDate)
						);
					}
					return false;
				}
			}
		}
		_.unset(object, fieldToPassError);
		return true;
	}

	validateRequiredDateField(
		object: any,
		field: string,
		fieldToPassError: string,
		fieldLabel?: string,
		maxDate: string | undefined = undefined
	): boolean {
		let date = _.get(object, field);
		if (date && (_.isString(date) || _.isDate(date))) {
			if (!this.validateMinimumDate(object, field, fieldToPassError, fieldLabel)) {
				return false;
			}
			if (!this.validateMaximumDate(object, field, fieldToPassError, fieldLabel, maxDate)) {
				return false;
			}
			_.unset(object, fieldToPassError);
			return true;
		}
		if (fieldLabel) {
			object[fieldToPassError] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				fieldLabel
			);
		} else {
			object[fieldToPassError] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldIsRequired'
			);
		}
		return false;
	}

	validateNullableDateField(
		object: any,
		field: string,
		fieldToPassError: string,
		fieldLabel?: string,
		maxDate: string | undefined = undefined
	): boolean {
		let date = _.get(object, field);
		if (date === undefined) {
			return true;
		}
		if (_.isString(date) || _.isDate(date)) {
			if (!this.validateMinimumDate(object, field, fieldToPassError, fieldLabel)) {
				return false;
			}
			if (maxDate) {
				if (DateUtilService.isAfter(date as string, maxDate)) {
					if (fieldLabel) {
						object[fieldToPassError] = localizationService.getLocalizedString(
							'screen.validationMessage.exceedMaximumValue',
							fieldLabel,
							date as string
						);
					} else {
						object[fieldToPassError] = localizationService.getLocalizedString(
							'screen.validationMessage.exceedMaximumValue',
							'screen.validationMessage.field',
							date as string
						);
					}
					return false;
				}
			}
			_.unset(object, fieldToPassError);
			return true;
		}
		if (fieldLabel) {
			object[fieldToPassError] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				fieldLabel
			);
		} else {
			object[fieldToPassError] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldIsRequired'
			);
		}
		return false;
	}

	validateNumberField(
		object: any,
		numberFieldName: string,
		fieldToPassError: string,
		required?: boolean,
		fieldLabel?: string
	): number | undefined {
		const value = _.get(object, numberFieldName);
		if (!_.isEmpty(value) || typeof value == 'number') {
			if (_.isNaN(_.toNumber(value))) {
				if (fieldLabel) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.notValidDecimalNumber',
						fieldLabel
					);
				} else {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldIsNan'
					);
				}
			} else {
				_.unset(object, fieldToPassError);
				return Number(value);
			}
		} else {
			if (required === true) {
				if (fieldLabel) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						fieldLabel
					);
				} else {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldIsRequired'
					);
				}
			} else {
				_.unset(object, fieldToPassError);
			}
		}
	}

	validatePumpOutManifest(object: any, numberFieldName: string, fieldToPassError: string) {
		const value = _.get(object, numberFieldName) as string;

		if (value) {
			let pattern = /^[0-9-]+/;
			let isValid = _.every(value, c => {
				let match = c.match(pattern);
				return match != null;
			});

			if (!isValid) {
				object[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.inValidManifest'
				);
			} else {
				_.unset(object, fieldToPassError);
			}
		} else {
			_.unset(object, fieldToPassError);
		}
	}

	validateIntegerNumberField(
		object: any,
		numberFieldName: string,
		fieldToPassError: string,
		required?: boolean,
		maxValue?: number,
		minValue?: number,
		fieldLabel?: string
	): number | undefined {
		const value = _.get(object, numberFieldName);
		if (!_.isEmpty(value)) {
			if (!Number.isInteger(_.toNumber(value)) || value.indexOf('.') >= 0) {
				object[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.notValidIntegerNumber',
					fieldLabel ? fieldLabel : 'Field'
				);
			} else {
				// it is an integer
				if (
					!_.isUndefined(maxValue) &&
					Number.isInteger(_.toNumber(maxValue)) &&
					_.toNumber(value) > maxValue
				) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.exceedMaximumValue',
						fieldLabel ? fieldLabel : 'Field',
						'' + maxValue
					);
					return;
				}
				if (
					!_.isUndefined(minValue) &&
					Number.isInteger(_.toNumber(minValue)) &&
					_.toNumber(value) < minValue
				) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.exceedMinimumValue',
						fieldLabel ? fieldLabel : 'Field',
						'' + minValue
					);
					return;
				}
				_.unset(object, fieldToPassError);
				return Number(value);
			}
		} else {
			if (required === true) {
				if (fieldLabel) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						fieldLabel
					);
				} else {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldIsRequired'
					);
				}
			} else {
				_.unset(object, fieldToPassError);
			}
		}
	}

	validateEmailValue(emailStr: string, errorObject: any, fieldToPassError: string): boolean {
		if (!_.isEmpty(emailStr)) {
			if (!this.isEmailFormatValid(emailStr)) {
				errorObject[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.invalidEmailFormat'
				);
				return false;
			}
		}
		_.unset(errorObject, fieldToPassError);
		return true;
	}

	validateCommaSeparatedEmailValues(emailStr: string, errorObject: any, fieldToPassError: string): boolean {
		var emails = emailStr.split(',');
		for (var i = 0; i < emails.length; i++) {
			if (!this.validateEmailValue(emails[i].trim(), errorObject, fieldToPassError)) return false;
		}
		return true;
	}

	validateFieldSetHavingAtLeastOneValue(object: any, ...fieldsetFields: string[]): boolean {
		for (var field of fieldsetFields) {
			const value = object[field];
			if (!_.isEmpty(value)) {
				return true;
			}
		}
		return false;
	}

	validateEmailFormatField(object: any, emailFieldName: string, fieldToPassError: string): boolean {
		const value = object[emailFieldName] as string;
		return this.validateEmailValue(value, object, fieldToPassError);
	}

	isEmailFormatValid = (value: string): boolean => {
		let emailRegex = /^(([^<>()[\]\\.#{}!,$`~*;:\s@"]+(\.[^<>()[\]\\.{},;$#*`!~:\s@"]+)*)|(".+"))@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		return emailRegex.test(value);
	};

	validateUrl(url: string, errObject: any, fieldToPassError: string): boolean {
		if (!_.isEmpty(url)) {
			let urlRegex = /^(http|https):\/\/[^ "]+$/;
			if (!urlRegex.test(url)) {
				errObject[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.invalidUrlFormat'
				);
				return false;
			}
		}
		_.unset(errObject, fieldToPassError);
		return true;
	}

	validateUrlField(object: any, urlFieldName: string, fieldToPassError: string): boolean {
		const url = object[urlFieldName];
		return this.validateUrl(url, object, fieldToPassError);
	}

	hasError(object: any, ...errorFieldNames: string[]): boolean {
		let hasError = false;
		_.each(errorFieldNames, (errorField: string) => {
			if (_.has(object, errorField) && object[errorField]) {
				hasError = true;
				return false;
			}
		});
		return hasError;
	}

	validateFields(fields: TFieldToValidate[]) {
		let errorObject = {};
		let fieldToPassError;
		let result = {};

		for (const fieldAttributes of fields) {
			for (const check of fieldAttributes.validations) {
				fieldToPassError = check;
				errorObject = {};
				switch (check) {
					case 'required':
						this.validateRequiredValue(
							fieldAttributes.value,
							errorObject,
							fieldToPassError,
							fieldAttributes.fieldLabel
						);
						break;
					case 'email':
						this.validateEmailValue(fieldAttributes.value, errorObject, fieldToPassError);
						break;
				}
			}
			(result as any)[fieldAttributes.fieldName] = errorObject;
		}
		return result;
	}

	clearErrors(object: any): void {
		let errorFields: string[] = [];
		Object.keys(object).forEach(key => {
			if (key.endsWith('Error')) {
				errorFields.push(key);
			}
		});
		errorFields.forEach(errorField => {
			_.unset(object, errorField);
		});
	}

	validateLatitudeField(
		object: any,
		numberFieldName: string,
		fieldToPassError: string,
		fieldLabel: string,
		required?: boolean
	): number | undefined {
		return this.validateMaxAbsoluteValueField(
			object,
			numberFieldName,
			fieldToPassError,
			fieldLabel,
			LATITUDE_COORDINATE_LIMIT,
			required
		);
	}

	validateLongitudeField(
		object: any,
		numberFieldName: string,
		fieldToPassError: string,
		fieldLabel: string,
		required?: boolean
	): number | undefined {
		return this.validateMaxAbsoluteValueField(
			object,
			numberFieldName,
			fieldToPassError,
			fieldLabel,
			LONGITUDE_COORDINATE_LIMIT,
			required
		);
	}

	validateMaxAbsoluteValueField(
		object: any,
		numberFieldName: string,
		fieldToPassError: string,
		fieldLabel: string,
		maxValue: Number,
		required?: boolean
	): number | undefined {
		const value = _.get(object, numberFieldName);
		if (!_.isEmpty(value) || typeof value == 'number') {
			if (_.isNaN(_.toNumber(value))) {
				object[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.notValidDecimalNumber',
					fieldLabel
				);
			} else {
				if (Math.abs(value) > maxValue) {
					object[fieldToPassError] = localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsInvalid',
						fieldLabel
					);
				} else {
					_.unset(object, fieldToPassError);
					return Number(value);
				}
			}
		} else {
			if (required === true) {
				object[fieldToPassError] = localizationService.getLocalizedString(
					'screen.validationMessage.fieldValueIsRequired',
					fieldLabel
				);
			} else {
				_.unset(object, fieldToPassError);
			}
		}
	}
}

export const validationService = new ValidationService();
