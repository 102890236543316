import React from 'react';



export const DomainSvg: React.FC<{}> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.4" viewBox="0 0 16 14.4">
			<path
				id="domain"
				d="M10,6.2V3H2V17.4H18V6.2ZM5.2,15.8H3.6V14.2H5.2Zm0-3.2H3.6V11H5.2Zm0-3.2H3.6V7.8H5.2Zm0-3.2H3.6V4.6H5.2Zm3.2,9.6H6.8V14.2H8.4Zm0-3.2H6.8V11H8.4Zm0-3.2H6.8V7.8H8.4Zm0-3.2H6.8V4.6H8.4Zm8,9.6H10V14.2h1.6V12.6H10V11h1.6V9.4H10V7.8h6.4ZM14.8,9.4H13.2V11h1.6Zm0,3.2H13.2v1.6h1.6Z"
				transform="translate(-2 -3)"
				fill="#666"
			/>
		</svg>
	);
};
