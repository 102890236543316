import React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { ColumnDefinition, EntityContact, FacilityBulkNoticeSteps, RouteProps } from '@rcp/types';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import { History } from 'history';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';
import { alertService } from '../../../../../../redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { useHistory } from 'react-router';

const { STEP1, STEP2, STEP3, STEP4 } = FacilityBulkNoticeSteps;

interface Props extends RouteProps {
	history: History;
}
export const Step4: React.FC<Props> = props => {
	const stepperContext = React.useContext(StepperContext);
	const { facilityContacts } = stepperContext.getData(STEP2);
	const [showDiscardModal, setShowDiscardModal] = React.useState(false);
	const [facilityContactList, setFacilityContactList] = React.useState<EntityContact[]>([]);
	const [sort, setSort] = React.useState<SortDescriptor[]>([]);
	const [numberOfContactWithIncompleteAddress, setNumberOfContactWithIncompleteAddress] = React.useState<number>(0);
	const history = useHistory();

	React.useEffect(() => {
		let contactsWithIncompleteAddress = [];
		facilityContacts.filter((contact: EntityContact) => {
			let { addressLine1, addressCity, addressJurisdictionCode, addressZipCode } = contact;
			if (!addressLine1 || !addressCity || !addressJurisdictionCode || !addressZipCode) {
				contactsWithIncompleteAddress.push(contact);
			}
		});
		setFacilityContactList(facilityContacts);
		setNumberOfContactWithIncompleteAddress(contactsWithIncompleteAddress.length);
	}, [facilityContacts]);

	const onClickBack = () => stepperContext.goAt(STEP3);

	const toggleDiscardModal = () => setShowDiscardModal(!showDiscardModal);

	const onClickDiscard = (e: any) => {
		e.preventDefault();
		toggleDiscardModal();
	};

	const onClickSend = (e: any) => {
		e.preventDefault();
		let payload = fetchStepperContent();
		payload = _.pick(payload, [
			'templateName',
			'marginTop',
			'marginBottom',
			'marginRight',
			'marginLeft',
			'printMarginUnit',
			'templateHeaderContent',
			'templateFooterContent',
			'templateBodyContent'
		]);
		const url = `${urlService.getFacilitiesResourceApiUrl()}/${Resource.DownloadPreviewNotices}`;
		const facilityContacts = facilityContactList
			.filter((contact: EntityContact) => {
				const { addressLine1, addressCity, addressJurisdictionCode, addressZipCode } = contact;
				if (addressLine1 && addressCity && addressJurisdictionCode && addressZipCode) {
					return contact;
				}
			})
			.map(contact => {
				const { facilityId, contactId, contactTypeId } = contact;
				return { facilityId, contactId, contactTypeId };
			});
		const ObjectTOPost = {
			facilityContactIds: facilityContacts,
			...payload
		};
		apiService
			.postResourceObject(url, ObjectTOPost)
			.then(() => {
				alertService.addSuccess(localizationService.getLocalizedString('facility.sendGeneralNoticeMessage'));
				navigateTo(
					history,
					`${urlService.getReactBaseUrl()}/${urlService.getProgramOrThrowError()}/${Resource.FogFacilities}`
				);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onDiscard = () => {
		navigateTo(
			history,
			`${urlService.getReactBaseUrl()}/${urlService.getProgramOrThrowError()}/${Resource.FogFacilities}`
		);
	};

	const customModalProps: CustomModalProp = {
		showModal: showDiscardModal,
		message: localizationService.getLocalizedString('bulkNotice.discardNoticeDesc'),
		title: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardTitle'),
		onCancelButtonClick: toggleDiscardModal,
		onOkayButtonClick: onDiscard,
		okayButtonText: localizationService.getLocalizedString('bulkNotice.discard')
	};

	const fetchStepperContent = () => {
		const step1Data = stepperContext.getData(STEP1);
		const step2Data = stepperContext.getData(STEP2);
		const step3Data = stepperContext.getData(STEP3);
		const step4Data = stepperContext.getData(STEP4);
		return { ...step1Data, ...step2Data, ...step3Data, ...step4Data };
	};

	const downloadNotice = (event: any, facilityId: number, contactId: number, contactTypeId: number) => {
		event.preventDefault();
		let url = urlService.getFacilityResourceApiUrl(facilityId, Resource.PreviewNotice);
		let payload = fetchStepperContent();
		payload = _.pick(payload, [
			'templateName',
			'marginTop',
			'marginBottom',
			'marginRight',
			'marginLeft',
			'printMarginUnit',
			'templateHeaderContent',
			'templateFooterContent',
			'templateBodyContent'
		]);
		payload.selectedContactTypeContactIds =
			contactId === 0 && contactTypeId === 0 ? [] : [{ contactId: contactId, contactTypeId: contactTypeId }];
		apiService.httpPost(url, payload);
	};

	const getDownloadButton = (props: any) => {
		let dataItem: any = props.dataItem;
		let contactsWithIncompleteAddress = false;
		let { addressLine1, addressCity, addressJurisdictionCode, addressZipCode } = dataItem;
		if (!addressLine1 || !addressCity || !addressJurisdictionCode || !addressZipCode) {
			contactsWithIncompleteAddress = true;
		}
		return contactsWithIncompleteAddress ? (
			<td></td>
		) : (
			<td>
				<a
					href="#/"
					className="ai-link"
					onClick={(event: any) => {
						downloadNotice(
							event,
							+dataItem.facilityId,
							+(dataItem.contactId || 0),
							+(dataItem.contactTypeId || 0)
						);
					}}>
					{localizationService.getLocalizedString('facility.download')}
				</a>
			</td>
		);
	};

	const columns = ['facilityNumber', 'contactType', 'contactName', 'addressLine1', 'preview'];

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(columns);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`facility.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	const getCompleteAddress = (props: any) => {
		let dataItem: any = props.dataItem;
		let address = [];
		let { addressLine1, addressLine2, addressCity, addressJurisdictionCode, addressZipCode } = dataItem;
		addressLine1 && address.push(addressLine1);
		addressLine2 && address.push(addressLine2);
		addressCity && address.push(addressCity);
		addressJurisdictionCode && address.push(addressJurisdictionCode);
		addressZipCode && address.push(addressZipCode);
		return <td>{address.join(', ')}</td>;
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (i.field === 'preview') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={getDownloadButton}
						/>
					);
				}
				if (i.field === 'addressLine1') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={getCompleteAddress}
						/>
					);
				}
				return (
					<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={localizeCellValue} />
				);
			});
	};

	const generalNoticeGrid = () => {
		let columnsDefinitions = getColumnDefinitions();
		let columns = generateColumns(columnsDefinitions);
		return (
			<>
				<div className="recipient-grid pr-3">
					<Grid
						className="table table-responsive"
						data={facilityContactList.length ? process(facilityContactList, { sort }) : []}
						scrollable="none"
						sortable
						sort={sort}
						onSortChange={(event: any) => {
							setSort(event.sort);
						}}>
						{columns}
					</Grid>
				</div>
			</>
		);
	};

	return (
		<>
			<StepperContent
				className="mx-4"
				actions={
					<React.Fragment>
						<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
							{localizationService.getLocalizedString('bulkNotice.back')}
						</StepperAction>
						<StepperAction type="button" id="btn-discard" className="btn btn-link" onClick={onClickDiscard}>
							{localizationService.getLocalizedString('bulkNotice.discard')}
						</StepperAction>
					</React.Fragment>
				}>
				<p className="notices-stepper-info">
					{localizationService.getLocalizedString('bulkNotice.previewAndSendDesc')}
				</p>
				{numberOfContactWithIncompleteAddress > 0 && (
					<Notification
						icon={<SvgWarning />}
						className="recipient-warning d-inline-block p-0 mb-2"
						message={localizationService.getLocalizedString(
							`authoritySetting.noticesSettings.${
								numberOfContactWithIncompleteAddress === 1
									? 'incompleteAddressWarning'
									: 'incompleteAddressWarningMultipleContacts'
							}`,
							numberOfContactWithIncompleteAddress + ''
						)}
						showCloseButton={false}
					/>
				)}
				<div className="facility-recipient-grid">{generalNoticeGrid()}</div>
				{facilityContactList.length > 0 ? (
					<button id="send" className="btn ai-action" onClick={onClickSend}>
						{localizationService.getLocalizedString('bulkNotice.send')}
					</button>
				) : null}
			</StepperContent>
			<CustomModal {...customModalProps} />
		</>
	);
};
