import React from 'react';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { FormState } from './facility-links';

interface Props {
	isToggle: boolean;
	toggle: () => void;
	changeFormField: (event: any) => void;
	formState: FormState;
	saveLink: (e: any) => void;
}

export const EditLinkModal: React.SFC<Props> = props => {
	const cancelSaveLocation = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('Edit Link')}
				save={props.saveLink}
				// footer={footer()}
				cancel={cancelSaveLocation}>
				<TextInput
					id="name"
					name="displayText"
					label={localizationService.getLocalizedString('Link name')}
					value={props.formState.displayText}
					onChange={props.changeFormField}
					isRequired={true}
					error={props.formState.nameError}
					maxLength={30}
				/>

				<TextInput
					id="url"
					name="url"
					label={localizationService.getLocalizedString('facility.link.linkUrl')}
					value={props.formState.url}
					onChange={props.changeFormField}
					type="url"
					isRequired={true}
					error={props.formState.linkError}
				/>
			</PopoverModal>
		</div>
	);
};
