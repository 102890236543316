import React from 'react';

interface Props {
	strokeColor?: string;
	fillColor?: string;
	size?: number;
}

export const InlineInfoSvg: React.FC<Props> = props => {
	const strokeColor = props.strokeColor || '#241c15';
	const fillColor = props.fillColor || '#241c15';
	const size = props.size || 16;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16">
			<g id="inline-info" transform="translate(-1038 -335)">
				<g
					id="Ellipse_53"
					data-name="Ellipse 53"
					transform="translate(1038 335)"
					fill="none"
					stroke={strokeColor}
					strokeWidth="1">
					<circle cx="8" cy="8" r="8" stroke="none" />
					<circle cx="8" cy="8" r="7.5" fill="none" />
				</g>
				<path
					id="Path_381"
					data-name="Path 381"
					d="M.313-2.121A.312.312,0,0,0,0-1.809v.746A.312.312,0,0,0,.313-.75H2.688A.312.312,0,0,0,3-1.062v-.746a.312.312,0,0,0-.312-.312H2.375V-5.437a.312.312,0,0,0-.312-.312H.313A.312.312,0,0,0,0-5.437v.746a.312.312,0,0,0,.313.313H.625v2.257ZM1.5-8.75A1.125,1.125,0,0,0,.375-7.625,1.125,1.125,0,0,0,1.5-6.5,1.125,1.125,0,0,0,2.625-7.625,1.125,1.125,0,0,0,1.5-8.75Z"
					transform="translate(1044.75 347.75)"
					fill={fillColor}
				/>
			</g>
		</svg>
	);
};

