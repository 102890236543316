import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import React, { useState } from 'react';
import { DateUtilService, localizationService, tokenService } from 'src/services';
import './custom-date-input.scss';
interface Props {
	id: string;
	name: string;
	label?: string;
	withoutWrappingDiv?: boolean;
	className?: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	value?: any; // date time value with timezone
	error?: string;
	format?: string;
	min?: string;
	max?: string;
	onChange?: (e: any) => void;
	formatPlaceholder?: any;
	helpText?: string;
	formatWithoutTime?: string;
}

const CustomDateTimePicker = (props: Props) => {
	const [selectedTime, setSelectedTime] = useState(0);
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [existingDate, setExistingDate] = React.useState<Date | undefined | null>(new Date());
	const [format, setFormat] = useState<string>(localizationService.getLocalizedString('dateFormats.datePicker'));

	const onChangeTime = (e: any) => {
		let value = e.value;
		let time = value.getTime();
		setSelectedTime(time);
		handleChange(e, 'time');
	};

	React.useEffect(() => {
		let propDate;

		if (!props.value || !DateUtilService.isValidDateTime(props.value)) {
			propDate = undefined;
		} else {
			propDate = DateUtilService.prepareDateForDateTimePicker(props.value);
			if (propDate.getHours() === 0 && propDate.getMinutes() === 0) {
				setFormat(localizationService.getLocalizedString('dateFormats.datePicker'));
			} else {
				setFormat(props.format || localizationService.getLocalizedString('dateFormats.dateTimePicker'));
			}
		}

		setExistingDate(propDate);
	}, [props.value]);

	const handleChange = (e: any, type?: string) => {
		if (type === 'date') setSelectedDate(e.value);
		let value = e.target.value;
		value && value.setHours(value.getHours());
		value && value.setMinutes(value.getMinutes());
		if (value && DateUtilService.checkDateInBrowserTimeZone(value)) {
			let newE = {
				target: {
					name: props.name,
					value: DateUtilService.prepareDateStringForApiCall(value)
				}
			};

			if (props.onChange) {
				props.onChange(newE);
			}
		}
	};
	const getWrappingClass = (): string => {
		let classStr = 'd-flex justify-content-flex-start.';
		if (props.className) {
			classStr = props.className;
			classStr = classStr + (tokenService.hasAccessToIppPortal() ? ' form-group ' : '');
		}

		if (props.isRequired) {
			classStr += ' required';
		}
		return classStr;
	};

	const getCustomDateTimePicker = () => {
		return (
			<div className={`custom-date-time-picker-container d-flex ${props.isDisabled ? 'border-0' : ''}`}>
				<DatePicker
					id={`${props.id}-custom-date-picker`}
					name={`${props.name}-custom-date-time-picker`}
					value={existingDate}
					format={format}
					max={props.max ? DateUtilService.prepareDateForDateTimePicker(props.max) : undefined}
					min={props.min ? DateUtilService.prepareDateForDateTimePicker(props.min) : undefined}
					required={props.error ? true : false}
					disabled={props.isDisabled === true ? true : false}
					onChange={(e?: any) => handleChange(e, 'date')}
					className="custom-date-picker"
				/>
				<TimePicker
					dateInput={() => <></>}
					className={`${props.isDisabled ? 'd-none' : 'pl-1'} custom-time-picker`}
					width={30}
					onChange={onChangeTime}
					disabled={props.isDisabled === true ? true : false}
					value={existingDate}
					defaultValue={existingDate || undefined}
				/>
			</div>
		);
	};
	return props.withoutWrappingDiv === true ? (
		getCustomDateTimePicker()
	) : (
		<div className={getWrappingClass()} id={`${props.id}_div`}>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					{props.label}
				</label>
			) : (
				<label htmlFor={props.id}>{props.label}</label>
			)}
			{getCustomDateTimePicker()}
			{props.error && <div className="invalid-feedback error-block ">{props.error}</div>}
			{props.helpText && <div className="ai-form-help">{props.helpText}</div>}
		</div>
	);
};

export default CustomDateTimePicker;
