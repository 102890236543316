import React, { FC } from 'react';
import { IndustriesBulkUpdate as BulkUpdate } from '@rcp/types';
import Stepper, { Step } from '../../../widgets/stepper';
import { localizationService } from 'src/services';
import Step1 from './bulk-update-steps/step1';
import Step2 from './bulk-update-steps/step2';
import { History } from 'history';

interface Props {
	history: History;
}

const IndustriesBulkUpdate: FC<Props> = props => {
	let title = localizationService.getLocalizedString('bulkUpdate.bulkUpdate');
	let step1Title = localizationService.getLocalizedString('ipp.industries.bulkUpdate.updateScheduledReports');
	let step2Title = localizationService.getLocalizedString('bulkUpdate.results');
	return (
		<div className="page">
			<div className="page-header">
				<h1>{title}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section>
						<Stepper vertical={true} initialStep={BulkUpdate.STEP1_INPUT_VALUES}>
							<Step stepId={BulkUpdate.STEP1_INPUT_VALUES} title={step1Title}>
								<Step1 history={props.history} />
							</Step>
							<Step stepId={BulkUpdate.STEP2_SUMMARY} title={step2Title}>
								<Step2 history={props.history} />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export default IndustriesBulkUpdate;
