import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterObjectName, FilterType, IppAuditLog, LocalStorageName } from '@rcp/types';
import { History } from 'history';
import React, { FC, useState } from 'react';
import { IppConstants } from 'src/constants';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';
import AuditLogsDetailsComponent from './audit-logs-details';
import { ippAuditLogSlice } from './audit-logs.slice';
import { Utils } from 'src/services/utils';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const { queryParams } = IppConstants;
const { shortPageSize } = queryParams;

const IppAuditLogsComponent: FC<Props<IppAuditLog>> = props => {
	const [selectedLog, setSelectedLog] = useState({});

	const eventCateogryToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.IppAuditLog;

		return (
			<td className="event-category" onClick={() => onEventCategoryClick(dataItem)}>
				<span>{dataItem.eventCategory}</span>
			</td>
		);
	};

	const onEventCategoryClick = (selectedItem: LinkoTypes.IppAuditLog) => {
		setSelectedLog(selectedItem);
	};

	const initialGridOption: GridOption = {
		showEditColumnButton: false,
		showFilter: true,
		showSearch: true,
		prefix: 'ipp.auditLog',
		storageName: LocalStorageName.IppAuditLogGrid,
		searchHint: localizationService.getLocalizedString('ipp.auditLog.searchHint'),
		showActions: true,
		enableFilter: true,
		showActionExport: true,
		pageTitle: localizationService.getLocalizedString('ipp.auditLog.auditLogs'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: { size: shortPageSize },
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: true,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppAuditLogGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPCROMERRAUDITLOG,
				FilterDomainName.ippCromerrAuditLog,
				FilterObjectName.IppCromerrAuditLog,
				LocalStorageName.IppAuditLogGridFilterUrl,
				{ IPPCROMERRAUDITLOG: true }
			);
		},
		allColumns: [
			new ColumnField('cromerrAuditLogId', FilterType.Integer),
			new ColumnField('auditLogTemplateId', FilterType.Integer),
			new ColumnField('regulatoryProgramId', FilterType.Integer),
			new ColumnField('regulatoryProgramName'),
			new ColumnField('organizationId', FilterType.Integer),
			new ColumnField('creatorOrganizationId', FilterType.Integer),
			new ColumnField('creatorOrganizationName'),
			new ColumnField('userProfileId', FilterType.Integer),
			new ColumnField('eventCategory', FilterType.Text, eventCateogryToLink),
			new ColumnField('eventType'),
			new ColumnField('userName'),
			new ColumnField('userFirstName'),
			new ColumnField('userLastName'),
			new ColumnField('userEmailAddress'),
			new ColumnField('ipAddress'),
			new ColumnField('hostName'),
			new ColumnField('comment'),
			new ColumnField('organizationName'),
			new ColumnField('creationDateTimeLocal', FilterType.Text, Utils.formatDate)
		],

		defaultColumns: ['eventCategory', 'eventType', 'creationDateTimeLocal', 'userEmailAddress'],
		exportColumns: [
			'eventCategory',
			'eventType',
			'creationDateTimeLocal',
			'auditLogTemplateId',
			'creatorOrganizationName',
			'organizationName',
			'regulatoryProgramName',
			'userProfileId',
			'userName',
			'userFirstName',
			'userLastName',
			'userEmailAddress',
			'ipAddress',
			'hostName',
			'comment'
		],
		showRefreshButton: true
	};
	const auditLogsState = (state: RootState) => state.ippAuditLogs;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippAuditLogSlice}
				restState={auditLogsState}
				filterUrlStorageName={LocalStorageName.IppAuditLogGridFilterUrl}
				gridOption={initialGridOption}></DataGrid>
			<AuditLogsDetailsComponent selectedLogDetails={selectedLog} />
		</>
	);
};

export default IppAuditLogsComponent;
