import { ReportPackageParameterGroupActionType, ReportPackageParameterGroupAction } from './parameter-groups-actions';
import { IppReportPackageParameterGroupState, initialReportPackageParameterGroupState } from './parameter-groups-state';

export const ippReportPackageParameterGroupDetailsReducer = (
	state = initialReportPackageParameterGroupState,
	action: ReportPackageParameterGroupAction
): IppReportPackageParameterGroupState => {
	switch (action.type) {
		case ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupRequest:
		case ReportPackageParameterGroupActionType.UpdateReportPackageParameterGroup:
		case ReportPackageParameterGroupActionType.DeleteReportPackageParameterGroup:
		case ReportPackageParameterGroupActionType.AddReportPackageParameterGroup:
			return { ...state };
		case ReportPackageParameterGroupActionType.LoadReportPackageParameterGroupSuccess:
			return {
				...state,
				parameterGroupDetails: action.parameterGroupDetails,
				availableParameters: action.availableParameters,
				availableParameterGroupAssignments: action.availableParameterGroupAssignments
			};
		default:
			return state;
	}
};
