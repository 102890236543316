import { IppReportPackage, QuestionAnswerDtos } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { DateUtilService, localizationService, validationService } from 'src/services';
import { Utils } from 'src/services/utils';

interface Props {
	packageDetails?: IppReportPackage;
	reportStatusName?: string;
	className?: string;
	onSubmit?: (event: any) => void;
	getKbq?: () => Promise<QuestionAnswerDtos>;
	canCurrentUserSubmitAndRepudiate?: boolean;
	isErrorPackage?: boolean;
}

const { fieldCharLimit } = IppConstants;

const IppPackageSubmission: FC<Props> = ({
	packageDetails,
	reportStatusName,
	className,
	onSubmit,
	getKbq,
	canCurrentUserSubmitAndRepudiate,
	isErrorPackage = false
}) => {
	let [password, setPassword] = useState();
	let [questionAnswer, setQuestionAnswer] = useState<QuestionAnswerDtos>();
	let [errors, setErrors] = useState({ passwordError: '', answerError: '' });
	const { ReadyToSubmit } = ReportPackageStatus;
	useEffect(() => {
		if (
			(packageDetails!.reportStatusName === ReadyToSubmit || isErrorPackage) &&
			canCurrentUserSubmitAndRepudiate
		) {
			randomQuestion();
			Utils.resetFailedAttemptCounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageDetails, canCurrentUserSubmitAndRepudiate]);

	const randomQuestion = async () => {
		if (getKbq) {
			setQuestionAnswer(await getKbq());
		}
	};

	const changeAnswer = (value: string) => {
		setQuestionAnswer({ ...questionAnswer, answer: value });
	};

	const signAndSubmit = () => {
		let payload = { password, ...questionAnswer, ...errors };
		let isPasswordValid = validationService.validateRequiredField(payload, 'password', 'passwordError', 'password');
		let isAnswerValid = validationService.validateRequiredField(payload, 'answer', 'answerError', 'KBQ answer');
		setErrors({ answerError: payload.answerError || '', passwordError: payload.passwordError || '' });
		if (isPasswordValid && isAnswerValid && onSubmit) {
			onSubmit(payload);
		}
	};

	return (
		<>
			{reportStatusName === ReadyToSubmit || isErrorPackage ? (
				canCurrentUserSubmitAndRepudiate && (
					<CollapsibleCard
						accordionType="report-package-submission-accordion"
						accordionHeading={localizationService.getLocalizedString(
							isErrorPackage
								? `ipp.reportPackage.package.signAndFix`
								: `ipp.reportPackage.package.${
										reportStatusName !== ReadyToSubmit && !isErrorPackage
											? 'submissionConfirmation'
											: 'signAndSubmitReport'
								  }`
						)}
						className={className ? className : ''}>
						{packageDetails?.ctsEventId && packageDetails?.isCtsScheduledRemoved && (
							<>
								<p className="ai-required">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.ctsScheduleRemovedErrorMessageLine1'
									)}
								</p>
								<p className="ai-required">
									{localizationService.getLocalizedString(
										'ipp.reportPackage.package.ctsScheduleRemovedErrorMessageLine2'
									)}
								</p>
							</>
						)}
						{!packageDetails?.isCtsScheduledRemoved && (
							<>
								<div id="report-package-submission" className="mx-auto" style={{ width: '500px' }}>
									<TextInput
										type="password"
										id="password"
										name="password"
										className="mx-auto"
										label={localizationService.getLocalizedString(
											'ipp.reportPackage.package.submitReport.passwordLabel'
										)}
										value={password}
										onChange={(event: any) => setPassword(event.target.value)}
										isRequired={true}
										error={errors.passwordError}
									/>
									<TextInput
										type="password"
										id="questionAnswer"
										name="answer"
										className="mx-auto"
										label={(questionAnswer && questionAnswer.question) || ''}
										value={questionAnswer && questionAnswer.answer}
										onChange={(event: any) => changeAnswer(event.target.value)}
										isRequired={true}
										error={errors.answerError}
										remainingInputProps={{ maxLength: fieldCharLimit.answer }}
									/>
								</div>
								<div className="d-flex">
									<button
										id="sign-submit-btn"
										className="btn ai-action ml-auto"
										onClick={signAndSubmit}>
										{localizationService.getLocalizedString(
											isErrorPackage
												? `ipp.reportPackage.package.signAndFixButton`
												: 'ipp.buttons.signAndSubmit'
										)}
									</button>
								</div>
							</>
						)}
					</CollapsibleCard>
				)
			) : (
				<CollapsibleCard
					accordionType="report-package-submission-accordion"
					accordionHeading={localizationService.getLocalizedString(
						`ipp.reportPackage.package.${
							reportStatusName !== ReportPackageStatus.ReadyToSubmit
								? 'submissionConfirmation'
								: 'signAndSubmitReport'
						}`
					)}
					className={className ? className : ''}>
					<div id="submission-details" className="form-row">
						<TextInput
							id="submitted-by"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.submitReport.submittedBy'
							)}
							className="col-sm-4"
							name="submittedBy"
							value={
								packageDetails &&
								`${packageDetails.submitterFirstName} ${packageDetails.submitterLastName}`.trim()
							}
							isDisabled={true}
						/>
						<TextInput
							id="title"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.submitReport.title'
							)}
							className="col-sm-4"
							name="title"
							value={packageDetails && packageDetails.submitterTitleRole}
							isDisabled={true}
						/>
						<TextInput
							id="submitted"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.submitReport.submitted'
							)}
							className="col-sm-4"
							name="submitted"
							value={
								packageDetails &&
								DateUtilService.toDisplayDate(packageDetails.submissionDateTimeLocal) +
									' ' +
									DateUtilService.toDisplayTime(packageDetails.submissionDateTimeLocal)
							}
							isDisabled={true}
						/>
					</div>
				</CollapsibleCard>
			)}
		</>
	);
};

export default IppPackageSubmission;
