import { DateUtilService } from 'src/services';
import { MonthOffset } from '@rcp/types';

export enum TimelineFilterByEventTypeEnum {
	comments = 'comments',
	phoneCalls = 'phoneCalls',
	emails = 'emails',
	inspections = 'inspections',
	pumpOutEvents = 'pumpOutEvents',
	violations = 'violations',
	enforcements = 'enforcements',
	sentCleaningNotices = 'sentCleaningNotices',
	sentGeneralNotices = 'sentGeneralNotices',
	events = 'events',
	incidents = 'incidents'
}

export interface TimelineSettings {
	version: string;

	comments: boolean;
	phoneCalls: boolean;
	emails: boolean;
	inspections: boolean;
	pumpOutEvents: boolean;
	violations: boolean;
	enforcements: boolean;
	searchText: string;
	sentCleaningNotices?: boolean;
	sentGeneralNotices?: boolean;
	cleaningManifest?: boolean;
	incidents: boolean;
	startDateIndex?: number;
	endDateIndex?: number;
	startDate: string;
	endDate: string;
	startOfTime: boolean;
	endOfTime: boolean;
	events: boolean;
	startDateError?: string;
	endDateError?: string;
}

export const GetInitialTimelineSettings = (): TimelineSettings => {
	return {
		version: '1.1', //Whenever change TimelineFilterByForm schema, let's bump the version, and discard browser localStorage saved filter

		comments: true,
		phoneCalls: true,
		emails: true,
		inspections: true,
		pumpOutEvents: true,
		violations: true,
		enforcements: true,
		sentCleaningNotices: true,
		sentGeneralNotices: true,
		cleaningManifest: true,
		searchText: '',
		events: true,
		incidents: true,

		startDateIndex: MonthOffset.OneYear,
		endDateIndex: MonthOffset.ThreeMonths,
		startDate: DateUtilService.getRelativeDate(MonthOffset.OneYear, -1),
		endDate: DateUtilService.getRelativeDate(MonthOffset.ThreeMonths, 1),
		startOfTime: false,
		endOfTime: false
	};
};
