import React from 'react';
import _ from 'lodash';
import { BrowserView, isMobile, MobileView } from 'react-device-detect';
import { CloseSvg } from 'src/components';
import MobileDrawer from './mobile-drawer';

interface Props {
	title: string;
	children: JSX.Element;
	footer?: JSX.Element;
	handleClose: () => void;
	showMobileTitle?: boolean;
	facilityRiskMapFooterOverlay: JSX.Element;
	hasFixedFooter?: boolean;
}

export const FloatList: React.FC<Props> = props => {
	return (
		<>
			<BrowserView>
				<div className="map-float-right">
					<div
						className={`position-fixed d-flex flex-column justify-content-between ${
							props.hasFixedFooter ? 'map-float-right-wrapper-fixed' : 'map-float-right-wrapper'
						}`}>
						<div>
							<h2 className="d-flex">
								<span>{props.title}</span>
								<button className="close ml-auto column-editor-close" onClick={props.handleClose}>
									<CloseSvg size="14" />
								</button>
							</h2>
							<div className="map-float-right-wrapper-body">{props.children}</div>
						</div>

						{props.footer && !props.hasFixedFooter && <>{props.footer}</>}
					</div>
					{props.footer && props.hasFixedFooter && <>{props.footer}</>}
				</div>
			</BrowserView>
			<MobileView>
				<MobileDrawer isVisible={true} onClose={props.handleClose} footer={props.footer}>
					{props.facilityRiskMapFooterOverlay}
					{props.showMobileTitle && (
						<h2 className="d-flex">
							<span>{props.title}</span>
							<button className="close ml-auto column-editor-close" onClick={props.handleClose}>
								<CloseSvg size="14" />
							</button>
						</h2>
					)}
					{props.children}
				</MobileDrawer>
			</MobileView>
		</>
	);
};
