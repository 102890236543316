import React, { useState, useRef } from 'react';
import _ from 'lodash';
import { Sortable } from '@progress/kendo-react-sortable';
import { SingleCheckbox } from '.';
import { CloseSvg } from '../svg';
import { localStorageService, localizationService, urlService } from 'src/services';

import * as LinkoTypes from '@rcp/types';
import { ColumnDefinition } from '@rcp/types';
import { Translate } from './translate/translator';

export interface ColumnsEditorProp {
	localStorageName: string;
	showModal: boolean;
	columns: LinkoTypes.ColumnDefinition[];
	defaultColumns: LinkoTypes.ColumnDefinition[];
	onChanged: () => void;
	toggle: () => void;
}

const ColumnsEditor: React.SFC<ColumnsEditorProp> = props => {
	const [columnDefinitions, setColumnDefinitions] = useState(props.columns || ([] as LinkoTypes.ColumnDefinition[]));
	const defaultList = useRef<any>(null);
	React.useEffect(() => {
		let columns = _.cloneDeep(props.columns);
		setColumnDefinitions(columns);
	}, [props.columns]);

	const onCancelBtnClick = () => {
		props.toggle();
	};

	const savingToLocal = (columns: LinkoTypes.ColumnDefinition[]) => {
		localStorageService.setLocalStorage(props.localStorageName, columns);
		setTimeout(() => props.onChanged(), 100);
	};

	const onResetToDefault = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		const columns = _.cloneDeep(props.defaultColumns);
		setColumnDefinitions(columns);
		savingToLocal(columns);
	};

	const isLastSelectedColumn = (columns: ColumnDefinition[]): boolean => {
		return columns.filter(c => c.visible === true).length === 1;
	};

	const toggleDisabledIfVisible = (columns: ColumnDefinition[], disabled: boolean) => {
		columns.forEach(a => {
			if (a.visible) {
				a.disabled = disabled;
			}
		});
	};

	const onCheckBoxChanged = (e: any) => {
		let id = e.target.id;
		let keys = columnDefinitions.map(i => `id_${i.field}`);
		let index = keys.indexOf(id);
		const newColumnDefinitions = [...columnDefinitions];
		newColumnDefinitions[index].visible = e.target.checked;
		if (isLastSelectedColumn(newColumnDefinitions)) {
			toggleDisabledIfVisible(newColumnDefinitions, true);
		} else {
			toggleDisabledIfVisible(newColumnDefinitions, false);
		}
		setColumnDefinitions(newColumnDefinitions);

		savingToLocal(newColumnDefinitions);
	};

	const onDragOver = (event: any) => {
		const scrollAmount = 200; // Amount of scroll
		const headerOffset = 180; // Offset to skip header
		// Scroll Up
		if (event.target.state.clientY < headerOffset) {
			window.scrollTo(0, window.scrollY - scrollAmount);
		}
		// Scroll Down
		if (event.target.state.clientY > window.screen.availHeight - headerOffset) {
			window.scrollTo(0, window.scrollY + scrollAmount);
		}

		setColumnDefinitions(event.newState);
		savingToLocal(event.newState);
	};

	const getBaseItemStyle = () => ({
		cursor: 'move',
		position: 'relative',
		paddingLeft: '0px'
	});

	const sortableItemUI = (props: any) => {
		const { style, attributes, dataItem, forwardRef } = props;
		return (
			<tr
				{...attributes}
				className={'d-flex'}
				ref={forwardRef}
				style={{
					...style,
					...getBaseItemStyle()
				}}>
				<td>
					<SingleCheckbox
						className={'mb-0'}
						id={`id_${dataItem.field}`}
						name={`name_${dataItem.field}`}
						checked={dataItem.visible}
						isDisabled={dataItem.disabled}
						label={dataItem.title}
						onChange={e => onCheckBoxChanged(e)}
					/>
				</td>
			</tr>
		);
	};

	return props.showModal === false ? null : (
		<form className="pl-0 pl-lg-3">
			<div className="column-editor d-flex flex-column">
				<h2 className="d-flex">
					<Translate>{localizationService.getLocalizedString('grid.editColumns')}</Translate>
					<button className="close ml-auto column-editor-close" onClick={onCancelBtnClick} autoFocus>
						<CloseSvg />
					</button>
				</h2>
				<p>
					<Translate>{localizationService.getLocalizedString('grid.editColumnDescriptionPart1')}</Translate>
					&nbsp;
					<a href="#/" onClick={onResetToDefault}>
						<Translate>
							{localizationService.getLocalizedString('grid.editColumnDescriptionPart2')}
						</Translate>
					</a>
				</p>
				<table className="edit-columns-table">
					<tbody>
						<Sortable
							ref={defaultList}
							idField={'field'}
							style={{ display: 'contents' }}
							disabledField={'disabled'}
							data={columnDefinitions}
							itemUI={sortableItemUI}
							onDragOver={onDragOver}
						/>
					</tbody>
				</table>
			</div>
		</form>
	);
};

export default ColumnsEditor;
