import React from 'react';
import { Incident } from '@rcp/types';
import { IncidentModal } from './Incident-modal';

interface OwnProps {
	incident: Incident;
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps;

export const IncidentEditActionLink: React.FC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href="#/" className="ai-link" onClick={onEditClicked}>
				{props.incident.incidentNumber}
			</a>
			{showModal && (
				<IncidentModal
					incident={props.incident}
					showModal={showModal}
					setShowModal={toggleModal}
					isAddModal={false}
				/>
			)}
		</>
	);
};
