import { DataImport } from '@rcp/types';
import { RestSlice } from '../../redux/rest.slice';

export const selfImportDataTypeSlice = new RestSlice<DataImport.ImportDataType>(
	'DataImport.ImportDataType',
	'Settings/AuthoritySettings/ImportSupportedDataTypes'
);
export const selfImportDataTypesReducer = selfImportDataTypeSlice.rests.reducer;

export const selfImportFieldsSlice = new RestSlice<DataImport.ImportField>(
	'DataImport.ImportField',
	'Settings/AuthoritySettings/ImportFields'
);

export const selfImportFieldsReducer = selfImportFieldsSlice.rests.reducer;

export const selfImportConfigurationSlice = new RestSlice<DataImport.ImportConfiguration>(
	'DataImport.ImportConfiguration',
	'Settings/AuthoritySettings/ImportConfigurations'
);
export const selfImportConfigurationsReducer = selfImportConfigurationSlice.rests.reducer;
