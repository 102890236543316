type Dispatch<A> = (value: A) => void;

class DispatchAccessor {
	store: any;
	dispatch: Dispatch<any> = () => {};

	setDispatch(dispatch: Dispatch<any>, store: any) {
		this.dispatch = dispatch;
		this.store = store;
	}
}

export const dispatchAccessor = new DispatchAccessor();
