import React, { FC, useEffect, useState } from 'react';
import UserList from './user-list';
import PendingInvitationList from '../pending-invitations/pending-invitation-list';
import { localizationService, tokenService, urlService } from 'src/services';
import { History } from 'history';
import { RouteProps, IppIndustry } from '@rcp/types';
import '../authority-account.scss';
import { connect } from 'react-redux';
import { IndustriesState, loadIndustryDetails } from 'src/redux/ipp/authority/industry';
import { alertService, ApplicationState, UserProfileState } from 'src/redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface DispatchProps {
	loadDetails: (industryId: number) => Promise<any>;
}

interface DispatchState extends IndustriesState, UserProfileState {}

interface Props extends RouteProps, DispatchProps, DispatchState {
	history: History;
}

const Users: FC<Props> = props => {
	const isIndustryUserModule = () => {
		return window.location.href.includes('ipp/authority/industries');
	};

	const [industryData, setIndustryData] = useState({} as IppIndustry);
	const [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);

	const fetchIndustryDetails = async (industryId: number) => {
		let response = await props.loadDetails(Number(industryId));
		if (
			response &&
			response.statusCode === 403 &&
			String.equalCaseInsensitive(
				response.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			alertService.clearAllMessages();
			setIsAccessDenied(true);
		}
	};

	useEffect(() => {
		let industryId = props.match.params.industryId;
		if (urlService.isIppIndustryPortal()) {
			industryId = Number(tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId);
		}
		if (isIndustryUserModule() || urlService.isIppIndustryPortal()) {
			fetchIndustryDetails(industryId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(isIndustryUserModule() || urlService.isIppIndustryPortal()) && setIndustryData(props.industryDetails);
	}, [props.industryDetails]);

	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row authority-account">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{(isIndustryUserModule()
								? industryData.industryName || ''
								: tokenService.getTokenOrDefault().portalOrganizationName) +
								' ' +
								localizationService.getLocalizedString('ipp.authorityAccount.users')}
						</h1>
					</div>
					<UserList
						isIndustryUserModule={isIndustryUserModule}
						industryData={industryData}
						history={props.history}
						match={props.match}
						userProfile={props.userProfile}
					/>
					<PendingInvitationList
						isIndustryUserModule={isIndustryUserModule}
						industryData={industryData}
						history={props.history}
						match={props.match}
					/>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): DispatchState => {
	return { ...state.ippIndustryDetails, ...state.userProfile };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadDetails: (industryId: number) => dispatch(loadIndustryDetails(industryId))
	};
};

export const IppUsers = connect(mapStateToProps, mapDispatchToProps)(Users);
