import React, { useState, FC, useEffect } from 'react';
import _ from 'lodash';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { DropDownOption, FormFieldBusiness, Jurisdiction, TimeZone } from '@rcp/types';
import { History } from 'history';
import { ReactComponent as ArrowBack } from 'src/assets/img/arrow-back.svg';
import './business-information.scss';

interface FormFieldError {
	businessNameError?: string;
	jurisdictionIdError?: string;
	addressLine1Error?: string;
	cityNameError?: string;
	zipCodeError?: string;
	emailError?: string;
	timeZoneIdError?: string;
}

interface FormState extends FormFieldError, FormFieldBusiness {}

const initialFormFieldsBusiness: FormState = {
	businessName: '',
	email: '',
	phoneNumber: '',
	mobilePhoneNumber: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	jurisdictionId: undefined,
	zipCode: '',
	timeZoneId: undefined,
	businessNameError: '',
	jurisdictionIdError: '',
	addressLine1Error: '',
	cityNameError: '',
	zipCodeError: '',
	emailError: '',
	timeZoneIdError: ''
};

interface Props {
	history: History;
}

const BusinessInformationComponent: FC<Props> = props => {
	const [oldState, setOldState] = useState<FormState>(initialFormFieldsBusiness);
	const [formStateBusiness, setFormStateBusiness] = useState<FormState>(initialFormFieldsBusiness);
	const [stateOptions, setStateOption] = useState<DropDownOption[]>([]);
	const [timezoneOptions, setTimezoneOptions] = useState<DropDownOption[]>([]);

	const loadStates = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.Jurisdictions);
		let states = await apiService.httpGet(url);
		let stateOptions = states.map((state: Jurisdiction) => ({ label: state.name, value: state.jurisdictionId }));
		setStateOption(stateOptions);
	};

	const loadTimezones = async () => {
		const url = urlService.getTimeZoneResourceApiUrl();
		let timezones = await apiService.httpGet(url);
		let timezoneOptions = timezones.map((timeZone: TimeZone) => ({
			label: timeZone.displayName,
			value: timeZone.timeZoneId
		}));
		setTimezoneOptions(timezoneOptions);
	};

	const loadBusinessInformationAndTimeZone = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.Haulers + '/BusinessInformation');
		const businessInformation: FormFieldBusiness = await apiService.httpGet(url);
		setFormStateBusiness({ ...formStateBusiness, ...businessInformation });
		setOldState({ ...formStateBusiness, ...businessInformation });
		await loadStates();
		await loadTimezones();
	};

	useEffect(() => {
		loadBusinessInformationAndTimeZone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (e: any) => {
		let newState = { ...formStateBusiness };
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value || '');
		setFormStateBusiness(newState);
	};

	const isFormValidateForBusiness = (field: string, isRequired: boolean, data: FormState): boolean => {
		let newState = { ...data };
		let isFromValid = false;
		if (isRequired) {
			validationService.validateRequiredField(
				newState,
				field,
				`${field}Error`,
				localizationService.getLocalizedString(`haulerPortal.settings.businessInformation.${field}`)
			);
			setFormStateBusiness(newState);
			isFromValid = !validationService.hasError(newState, `${field}Error`);
		} else if (field === 'email') {
			newState[field]
				? validationService.validateEmailFormatField(newState, field, 'emailError')
				: (newState['emailError'] = '');
			setFormStateBusiness(newState);
			isFromValid = !validationService.hasError(newState, `${field}Error`);
		} else {
			isFromValid = true;
		}
		return isFromValid;
	};

	const validateAndUpdate = async (field: string, fieldValue: string | number, isRequired: boolean = true) => {
		let updatedBusinessInformation: FormState = { ...formStateBusiness };
		let oldBusinessInformation: FormFieldBusiness = { ...oldState };
		if (field === 'jurisdictionId' || field === 'timeZoneId') {
			updatedBusinessInformation = { ...updatedBusinessInformation, [field]: +fieldValue };
		}
		let isValueChanged =
			((oldBusinessInformation as any)[field] || '').toString().trim() !== fieldValue.toString().trim();
		if (isFormValidateForBusiness(field, isRequired, updatedBusinessInformation) && isValueChanged) {
			let url = urlService.getAuthorityResourcesApiUrl(Resource.Haulers + '/BusinessInformation');
			let payload = _.pick(updatedBusinessInformation, [field]);
			await apiService.httpPatch(url, payload);
			let updatedData = await apiService.httpGet(url);
			setFormStateBusiness({ ...formStateBusiness, ...updatedData });
			setOldState({ ...oldBusinessInformation, [field]: (updatedBusinessInformation as any)[field] });
		}
	};

	const getContactInformation = () => {
		return (
			<div className="row mt-2">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString(
							'haulerPortal.settings.businessInformation.contactInformation'
						)}
					</strong>
					<p>
						{localizationService.getLocalizedString(
							'haulerPortal.settings.businessInformation.contactInformationDescription'
						)}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<TextInput
							id="business-name"
							name="businessName"
							isRequired={urlService.isAdministrator()}
							className="form-group input-single-line"
							error={formStateBusiness.businessNameError}
							isDisabled={!urlService.isAdministrator()}
							onChange={onChange}
							onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
							value={formStateBusiness.businessName}
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.businessName'
							)}
						/>
						<TextInput
							id="email"
							name="email"
							className="form-group input-single-line"
							value={formStateBusiness.email}
							onChange={onChange}
							isDisabled={!urlService.isAdministrator()}
							onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value, false)}
							error={formStateBusiness.emailError}
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.email'
							)}
						/>
						<div className="row">
							<div className="col-md-6">
								<TextInput
									id="phoneNumber"
									name="phoneNumber"
									className="form-group input-single-line"
									value={formStateBusiness.phoneNumber}
									onChange={onChange}
									isDisabled={!urlService.isAdministrator()}
									onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value, false)}
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.businessInformation.phoneNumber'
									)}
									maxLength={20}
								/>
							</div>
							<div className="col-md-6">
								<TextInput
									id="mobilePhoneNumber"
									name="mobilePhoneNumber"
									className="form-group input-single-line"
									value={formStateBusiness.mobilePhoneNumber}
									onChange={onChange}
									isDisabled={!urlService.isAdministrator()}
									onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value, false)}
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.businessInformation.mobilePhoneNumber'
									)}
									maxLength={20}
								/>
							</div>
						</div>
						<TextInput
							id="addressLine1"
							name="addressLine1"
							isRequired={urlService.isAdministrator()}
							className="form-group input-single-line"
							onChange={onChange}
							onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
							value={formStateBusiness.addressLine1}
							error={formStateBusiness.addressLine1Error}
							isDisabled={!urlService.isAdministrator()}
							helpText={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.addressLine1Help'
							)}
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.addressLine1'
							)}
						/>
						<TextInput
							id="addressLine2"
							name="addressLine2"
							onChange={onChange}
							className="form-group input-single-line"
							onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value, false)}
							value={formStateBusiness.addressLine2}
							isDisabled={!urlService.isAdministrator()}
							helpText={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.addressLine2Help'
							)}
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.addressLine2'
							)}
						/>
						<div className="row">
							<div className="col-md-4">
								<TextInput
									id="cityName"
									name="cityName"
									isRequired={urlService.isAdministrator()}
									className="form-group input-single-line"
									value={formStateBusiness.cityName}
									error={formStateBusiness.cityNameError}
									onChange={onChange}
									isDisabled={!urlService.isAdministrator()}
									onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.businessInformation.cityName'
									)}
								/>
							</div>
							<div className="col-md-4">
								<SingleSelectDropdown
									id="jurisdictionId"
									name="jurisdictionId"
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.businessInformation.jurisdictionId'
									)}
									readonly={!urlService.isAdministrator()}
									value={formStateBusiness.jurisdictionId}
									options={stateOptions}
									isRequired={urlService.isAdministrator()}
									error={formStateBusiness.jurisdictionIdError}
									onChange={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
								/>
							</div>
							<div className="col-md-4">
								<TextInput
									id="zipCode"
									name="zipCode"
									isRequired={urlService.isAdministrator()}
									value={formStateBusiness.zipCode}
									error={formStateBusiness.zipCodeError}
									onChange={onChange}
									className="form-group input-single-line"
									isDisabled={!urlService.isAdministrator()}
									onBlur={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.businessInformation.zipCode'
									)}
								/>
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	};

	const getTimeZone = () => {
		return (
			<div className="row mt-4">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString('haulerPortal.settings.businessInformation.timeZone')}
					</strong>
					<p>
						{localizationService.getLocalizedString(
							'haulerPortal.settings.businessInformation.timeZoneDescription'
						)}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<SingleSelectDropdown
							isRequired
							id="timeZoneId"
							name="timeZoneId"
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.businessInformation.timeZone'
							)}
							readonly={!urlService.isAdministrator()}
							options={timezoneOptions}
							value={formStateBusiness.timeZoneId}
							error={formStateBusiness.timeZoneIdError}
							onChange={(e: any) => validateAndUpdate(e.target.name, e.target.value)}
						/>
					</section>
				</div>
			</div>
		);
	};
	return (
		<div className="page">
			<div className="page-header">
				<div className="arrow-back px-3 py-1 mb-1 mr-2 cursor-pointer" onClick={() => props.history.goBack()}>
					<ArrowBack />
				</div>
				<h1 className="ml-2">
					{localizationService.getLocalizedString('haulerPortal.settings.businessInformation.title')}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">{getContactInformation()}</div>
			</div>
			<hr />
			<div className="page-wrapper">
				<div className="main settings">{getTimeZone()}</div>
			</div>
		</div>
	);
};

export const BusinessInformation = BusinessInformationComponent;
