import React from 'react';
import './back-arrow.scss';
import { History } from 'history';
import { ReactComponent as ArrowBack } from 'src/assets/img/arrow-back.svg';
import { useHistory } from 'react-router';

interface Props {
	history?: History;
	className?: string;
}

export const BackArrow: React.FC<Props> = (props: Props) => {
	const history = useHistory();
	return (
		<div
			id="backArrow"
			className={`${props.className ||
				''} d-flex align-items-center justify-content-center icon-arrow-left  cursor-pointer mr-3`}
			onClick={history.goBack}>
			<ArrowBack />
		</div>
	);
};
