import * as ApiTypes from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { apiService, Resource, urlService } from 'src/services';
import * as PendingInvitations from './pending-invitations-actions';
import { InviteUserState } from './pending-invitations-state';

type PendingInvitationsThunkAction = ThunkAction<
	any,
	InviteUserState,
	any,
	PendingInvitations.PendingInvitationsAction
>;

export const searchEmailAddress = (userInvite: ApiTypes.IppUserInvite): PendingInvitationsThunkAction => async (
	dispatch,
	getState
) => {
	let payload = {
		items: [] as ApiTypes.IppUserInvite[]
	};
	payload.items.push(userInvite);
	const url = urlService.getAuthorityResourcesApiUrl(Resource.IppPendingInvitations) + '/InviteCheckEmail';
	const userExistence = await apiService.httpPost(url, payload);
	dispatch(PendingInvitations.checkEmail(userExistence.items[0]));
	return true;
};
