import React, { useRef, FC, useState } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import './grid-data-tooltip.scss';

interface OwnProps {
	data?: string;
}

type Props = OwnProps;

export const GridDataTooltip: FC<Props> = props => {
	const [showPopup, setShowPopup] = useState(false);
	const anchor = useRef<HTMLTableDataCellElement | null>(null);
	const togglePopup = (showPopup: boolean) => {
		setShowPopup(showPopup);
	};

	const lastHaulerPopup = () => {
		return (
			<td>
				<span
					ref={anchor}
					className="wrap-table-data"
					onMouseEnter={() => togglePopup(true)}
					onMouseOver={() => togglePopup(true)}
					onMouseOut={() => togglePopup(false)}
					onMouseLeave={() => togglePopup(false)}>
					{props.data}
				</span>
				<Popup anchor={anchor.current as HTMLElement | undefined} show={showPopup} popupClass="ai-popover">
					{lastHaulerPopupContent()}
				</Popup>
			</td>
		);
	};

	const lastHaulerPopupContent = () => {
		return (
			<>
				<div className="break-white-space">{props.data}</div>
			</>
		);
	};

	return <>{lastHaulerPopup()}</>;
};
