import React, { useState } from 'react';
import { ApiError, Unit } from '@rcp/types';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { alertService } from 'src/redux';
import { settingLookupsUnitSlice } from './unit-lookup.slice';
import { useDispatch } from 'react-redux';

export enum EditorMode {
	Add,
	Update
}

interface Props {
	mode: EditorMode;
	show: boolean;
	onClosed?: () => void;
	unit?: Unit | null;
	units?: Unit[];
}

const UnitEditor: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	const getInitState = () => {
		let name = '';
		let description = '';
		if (props.mode === EditorMode.Update && props.unit) {
			name = props.unit.name as string;
			description = props.unit.description as string;
		}

		return {
			name: name,
			description: description,
			nameError: '',
			descriptionError: '',
			show: props.show
		};
	};

	let [state, setState] = useState(getInitState());

	const onUpdateSuccess = () => {
		alertService.addSuccess(localizationService.getLocalizedString('authoritySetting.unit.updateSucceed'));
		onClose();
	};

	const onCreateSuccess = () => {
		alertService.addSuccess(localizationService.getLocalizedString('authoritySetting.unit.updateSucceed'));
		onClose();
	};

	const onError = (reason: any) => {
		if (reason instanceof ApiError) {
			let error = localizationService.getLocalizedString('screen.validationMessage.formValidation');
			if (
				reason.body &&
				reason.body.internalMessage &&
				reason.body.internalMessage.toLowerCase().indexOf('violation of unique key') >= 0
			) {
				error = localizationService.getLocalizedString('authoritySetting.unit.duplicateName', state.name);

				setState({
					...state,
					nameError: error
				});
			}

			alertService.addError(error);
		} else {
			alertService.addError(reason.toString());
		}
	};

	const onSaveClicked = async () => {
		if (!state.name) {
			const errorText = localizationService.getLocalizedString('authoritySetting.unit.nameRequired');
			alertService.addError(errorText);
			setState({ ...state, nameError: errorText });
			return;
		}

		if (props.units) {
			const existingUnitName = props.units.filter((unit: Unit) =>
				String.equalCaseInsensitive(unit.name, state.name)
			);
			if (existingUnitName.length > 0) {
				if (props.unit && existingUnitName[0].name !== props.unit.name) {
					const error = localizationService.getLocalizedString(
						'authoritySetting.unit.duplicateName',
						state.name
					);
					setState({
						...state,
						nameError: error
					});
					return;
				}
			}
		}

		if (props.mode === EditorMode.Update && props.unit && props.unit.unitId) {
			dispatch(
				settingLookupsUnitSlice.patchOne(
					props.unit.unitId,
					{
						name: state.name,
						description: state.description
					},
					true,
					undefined,
					onUpdateSuccess,
					onError
				)
			);
		} else if (props.mode === EditorMode.Add) {
			let request: Unit = {
				name: state.name,
				description: state.description
			};

			dispatch(settingLookupsUnitSlice.createOne(request, true, undefined, onCreateSuccess, onError));
		}
	};
	const onCancelClicked = () => {
		onClose();
	};

	const onClose = () => {
		setState({ ...state, show: false });
		if (props.onClosed) {
			props.onClosed();
		}
	};

	const getTitle = () => {
		if (props.mode === EditorMode.Add) {
			return localizationService.getLocalizedString('authoritySetting.unit.addUnit');
		} else {
			return localizationService.getLocalizedString('authoritySetting.unit.updateUnit');
		}
	};

	const footer = () => {
		return (
			<div className="ml-auto mt-4">
				<button id="btnSave" className="btn ai-save ml-2" onClick={onSaveClicked}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</button>
				<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		);
	};

	const onNameChanged = (evt: any) => {
		setState({ ...state, name: evt.target.value, nameError: '' });
	};

	const onDescriptionChanged = (evt: any) => {
		setState({ ...state, description: evt.target.value });
	};

	return (
		<>
			<PopoverModal
				showModal={props.show && state.show}
				title={getTitle()}
				footer={footer()}
				save={onSaveClicked}
				cancel={onCancelClicked}>
				<TextInput
					id="code"
					name="unitName"
					value={state.name}
					isRequired={true}
					onChange={onNameChanged}
					error={state.nameError}
					label={localizationService.getLocalizedString('authoritySetting.unit.unit')}
				/>

				<TextInput
					id="unitDescription"
					name="unitDescription"
					className="ml-1 mr-1"
					value={state.description}
					onChange={onDescriptionChanged}
					isRequired={true}
					error=""
					label={localizationService.getLocalizedString('authoritySetting.unit.description')}
				/>
			</PopoverModal>
		</>
	);
};

export default UnitEditor;
