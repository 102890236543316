import React, { FC, useContext, useEffect, useState } from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import {
	ColumnDefinition,
	EntityContact,
	SendFacilityNoticeSteps,
	FacilityNoticeDevicesRecipient,
	PreviewNotice,
	RouteProps
} from '@rcp/types';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { apiService, localizationService, navigateTo, Resource, urlService, UtilService } from 'src/services';
import PreviewModal from 'src/components/widgets/preview-email-notice';
import { alertService, loadCurrentFogFacility, RootState } from 'src/redux';
import { process, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Utils } from '../../../../../../services/utils';
import { useHistory } from 'react-router';
import { Notification } from 'src/components/widgets/inline-notification';
import './steps.scss';
import { extractFacilityContact } from 'src/features';

interface Props extends RouteProps {}

const { STEP2, STEP3, STEP1, STEP4 } = SendFacilityNoticeSteps;

export const Step4: FC<Props> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const stepperContext = useContext(StepperContext);
	const [showModal, setShowModal] = useState(false);
	const [contactList, setContactList] = useState<EntityContact[]>([]);
	const [noticeDevicesRecipient, setNoticeDevicesRecipient] = useState<FacilityNoticeDevicesRecipient[]>([]);
	const [sort, setSort] = useState<SortDescriptor[]>([]);
	const [emailDetail, setEmailDetail] = useState([] as PreviewNotice[]);
	const [numberOfEmailContact, setNumberOfEmailContact] = useState(0);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const step1Data = stepperContext.getData(STEP1);

	let facilityDetail = (state: RootState) => state.fogFacility;
	let facility = useSelector(facilityDetail);
	const pageLoading = useSelector((state: RootState) => state.pageLoadingState);

	const getSelectedCleaningNoticeContact = () => {
		const deviceIds = step1Data.deviceIds;
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${
			Resource.SendNoticeContacts
		}?sort=deviceNumber asc, contactType asc, contactName asc`;
		apiService.postResourceObject<FacilityNoticeDevicesRecipient[]>(url, deviceIds).then(response => {
			setNoticeDevicesRecipient(response);
		});
	};

	useEffect(() => {
		if (noticeDevicesRecipient.length > 0) {
			const numberOfContact = noticeDevicesRecipient.reduce((acc, recipient) => {
				if (!recipient.email) {
					return acc + 1;
				}
				return acc + 0;
			}, 0);
			setNumberOfEmailContact(numberOfContact);
		}
	}, [noticeDevicesRecipient]);

	useEffect(() => {
		let step2Data = stepperContext.getData(STEP2);
		let step1Data = stepperContext.getData(STEP1);
		if (Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			getSelectedCleaningNoticeContact();
		} else {
			dispatch(loadCurrentFogFacility());
			let selectedContacts = _.cloneDeep(step2Data.selectedContactList);
			if (
				!_.isEmpty(facility) &&
				facility &&
				step2Data.selectedContactIds.includes(`${facility.facility.facilityId}-facility`)
			) {
				let facilityContact: EntityContact = extractFacilityContact(facility.facility);
				selectedContacts.unshift(facilityContact);
			}
			setContactList(selectedContacts);
		}
		stepperContext.resolve({});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onToggleModal = () => setShowModal(!showModal);

	const onTogglePreviewModal = () => {
		setShowPreviewModal(!showPreviewModal);
	};

	const navigateToFacilityDetails = () => {
		let url = urlService.getReactFacilityDetailsUrl(props.match.params.fogFacilityId);
		navigateTo(history, url);
	};

	const onDiscardModal = () => {
		navigateToFacilityDetails();
	};

	const onClickBack = () => stepperContext.goAt(STEP3);

	const getPreviewData = (rowData: any) => {
		const { deviceIds, templateName, noticeTemplateId } = step1Data;
		const step2Data = stepperContext.getData(STEP2);
		const { shouldSendNoticeToFacilityAssignedToUser } = step2Data;
		const step3Data = stepperContext.getData(STEP3);
		const {
			noticeContent: { templateContent, templateSubject }
		} = step3Data;
		const notice = {
			shouldSendNoticeToFacilityAssignedToUser,
			extractorIds: deviceIds,
			templateContent,
			templateSubject,
			templateName,
			noticeTemplateScheduleId: noticeTemplateId
		};
		let baseUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Extractors}/${rowData.extractorId}/${
			Resource.PreviewNotices
		}`;
		const url = rowData.contactId ? `${baseUrl}/${rowData.contactId}` : `${baseUrl}`;

		apiService
			.postResourceObject(url, notice)
			.then((res: any) => {
				const recipientWithEmail = res.filter((recipient: any) => {
					return UtilService.hasValue(recipient.email) && recipient.contactTypeId === rowData.contactTypeId;
				});
				setEmailDetail(recipientWithEmail);
				onTogglePreviewModal();
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const fetchStepperContent = () => {
		const step1Data = stepperContext.getData(STEP1);
		const step2Data = stepperContext.getData(STEP2);
		const step3Data = stepperContext.getData(STEP3);
		const step4Data = stepperContext.getData(STEP4);
		return { ...step1Data, ...step2Data, ...step3Data, ...step4Data };
	};

	const discardModalProps: CustomModalProp = {
		title: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardTitle'),
		showModal: showModal,
		onCancelButtonClick: onToggleModal,
		okayButtonText: localizationService.getLocalizedString('authoritySetting.noticesSettings.discard'),
		message: localizationService.getLocalizedString('authoritySetting.noticesSettings.sendNoticeDiscarDMessage'),
		onOkayButtonClick: onDiscardModal
	};

	let contactColumnKeys = ['referenceNumber', 'contactTypeCode', 'firstName', 'addressLine1', 'preview'];

	let cleaningNoticeDeviceColumnKeys = ['deviceNumber', 'contactType', 'contactName', 'email', 'preview'];

	const getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	const getColumnDefinitions = (columns?: string[]): ColumnDefinition[] => {
		let defaultColumnsDefinitions = getDefaultShowingColumnDefinitions(columns || contactColumnKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`facility.${item.field}`);
		});
		return defaultColumnsDefinitions;
	};

	const localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td>{localizationService.formatValue(fieldValue)}</td>;
	};

	const previewNotice = (e: any, data: any) => {
		e.preventDefault();
		Utils.isDeviceCleaningNotice(step1Data.noticeType) && getPreviewData(data.dataItem);
	};

	const downloadNotice = (event: any, contactId: number, contactTypeId: number) => {
		event.preventDefault();
		let url = urlService.getFacilityResourceApiUrl(props.match.params.fogFacilityId, Resource.PreviewNotice);
		let payload = fetchStepperContent();
		payload = _.pick(payload, [
			'templateName',
			'marginTop',
			'marginBottom',
			'marginRight',
			'marginLeft',
			'printMarginUnit',
			'templateHeaderContent',
			'templateFooterContent',
			'templateBodyContent'
		]);
		payload.selectedContactTypeContactIds =
			contactId && contactTypeId ? [{ contactId: contactId, contactTypeId: contactTypeId }] : [];
		apiService.httpPost(url, payload);
	};

	const onSendGeneralNotice = () => {
		let url = urlService.getFacilityResourceApiUrl(
			props.match.params.fogFacilityId,
			Resource.DownloadPreviewNotices
		);
		let payload = fetchStepperContent();
		payload = _.pick(payload, [
			'letterTemplateId',
			'templateName',
			'marginTop',
			'marginBottom',
			'marginRight',
			'marginLeft',
			'printMarginUnit',
			'templateHeaderContent',
			'templateFooterContent',
			'templateBodyContent',
			'selectedContactIds',
			'letterTemplateId',
			'isFacilityAddressSelected'
		]);
		let totalSelectedContactsCount = payload.selectedContactIds.length;
		payload.isFacilityAddressSelected = payload.selectedContactIds.includes(
			`${facility.facility.facilityId}-facility`
		);
		payload.selectedContactIds = payload.selectedContactIds.filter(
			(selectedContactId: string) => !selectedContactId.includes('facility')
		);
		payload.selectedContactTypeContactIds = payload.selectedContactIds.map((contactId: string) => {
			return { contactId: +contactId.split('-')[0], contactTypeId: +contactId.split('-')[1] };
		});
		apiService
			.httpPost(url, payload)
			.then(() => {
				alertService.addSuccess(
					localizationService.getLocalizedString(
						`facility.sendGeneralNoticeSuccess${totalSelectedContactsCount > 1 ? 'Multiple' : 'Single'}`,
						totalSelectedContactsCount + ''
					)
				);
				navigateToFacilityDetails();
			})
			.catch(error => alertService.addError(error.message));
	};

	const getDownloadButton = (props: any) => {
		let dataItem: EntityContact = props.dataItem;

		return (
			<td>
				<a
					href="#/"
					className="ai-link"
					onClick={(event: any) => {
						if (!Utils.isDeviceCleaningNotice()) {
							downloadNotice(event, +(dataItem.contactId || 0), +(dataItem.contactTypeId || 0));
						} else {
							previewNotice(event, dataItem);
						}
					}}>
					{localizationService.getLocalizedString('facility.download')}
				</a>
			</td>
		);
	};

	const getCompleteAddress = (props: any) => {
		let dataItem: EntityContact = props.dataItem;
		let address = [];
		let { addressLine1, addressLine2, addressCity, addressJurisdictionCode, addressZipCode } = dataItem;
		addressLine1 && address.push(addressLine1);
		addressLine2 && address.push(addressLine2);
		addressCity && address.push(addressCity);
		addressJurisdictionCode && address.push(addressJurisdictionCode);
		addressZipCode && address.push(addressZipCode);
		return <td>{address.join(', ')}</td>;
	};

	const getPreviewButton = (data: any) => {
		return (
			<>
				{data.dataItem.email && pageLoading.loadingCounter === 0 ? (
					<td>
						<a href="#/" className="ai-link" onClick={e => previewNotice(e, data)}>
							{localizationService.getLocalizedString('facility.preview')}
						</a>
					</td>
				) : (
					<td></td>
				)}
			</>
		);
	};

	const getContactTypeForFacilityAddress = (data: any) => {
		return (
			<>
				<td>{data.dataItem.contactTypeCode || data.dataItem.contactType}</td>
			</>
		);
	};

	const generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition) => {
				if (String.equalCaseInsensitive(i.field, 'preview')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={
								Utils.isDeviceCleaningNotice(step1Data.noticeType)
									? getPreviewButton
									: getDownloadButton
							}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'addressLine1')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={getCompleteAddress}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'contactTypeCode')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={getContactTypeForFacilityAddress}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'referenceNumber')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={() => <td>{facility.facility.referenceNumber}</td>}
						/>
					);
				}
				if (String.equalCaseInsensitive(i.field, 'firstName')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={(props: any) => (
								<td>
									{props.dataItem.firstName || props.dataItem.lastName
										? `${props.dataItem.firstName || ''} ${props.dataItem.lastName || ''}`
										: `${props.dataItem.companyName || ''}`}
								</td>
							)}
						/>
					);
				}
				return (
					<GridColumn key={`column_key${i.field}`} field={i.field} title={i.title} cell={localizeCellValue} />
				);
			});
	};

	const getSelectedDeviceContactList = () => {
		let noticeDeviceContactsColumnsDefinitions = getColumnDefinitions(cleaningNoticeDeviceColumnKeys);
		let cleaningNoticeDeviceContactsColumns = generateColumns(noticeDeviceContactsColumnsDefinitions);

		return (
			<>
				{numberOfEmailContact > 0 && (
					<Notification
						icon={<SvgWarning />}
						className="recipient-warning d-inline-block p-0 mb-2"
						message={localizationService.getLocalizedString(
							'extractor.cleaningNotice.emailWarn',
							numberOfEmailContact + ' ' + (numberOfEmailContact === 1 ? 'contact' : 'contacts')
						)}
						showCloseButton={false}
					/>
				)}
				<div className="recipient-grid pr-3 mt-2">
					<Grid
						className="table table-responsive"
						data={noticeDevicesRecipient.length ? process(noticeDevicesRecipient, { sort }) : []}
						scrollable="none"
						sortable
						sort={sort}
						onSortChange={(e: any) => {
							setSort(e.sort);
						}}>
						{cleaningNoticeDeviceContactsColumns}
					</Grid>
				</div>
				{showPreviewModal && (
					<PreviewModal
						showModal={showPreviewModal}
						emailDetails={emailDetail}
						onToggle={() => setShowPreviewModal(!showPreviewModal)}></PreviewModal>
				)}
			</>
		);
	};

	const generalNoticeGrid = () => {
		let columnsDefinitions = getColumnDefinitions();
		let columns = generateColumns(columnsDefinitions);
		return (
			<>
				<p className="notices-stepper-info">
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.previewAndSendDesc')}
				</p>
				<div className="recipient-grid pr-3">
					<Grid
						className="table table-responsive"
						data={contactList.length ? process(contactList, { sort }) : []}
						scrollable="none"
						sortable
						sort={sort}
						onSortChange={(event: any) => {
							setSort(event.sort);
						}}>
						{columns}
					</Grid>
				</div>
			</>
		);
	};

	const sendNotice = () => {
		const { deviceIds, templateName, noticeTemplateId } = step1Data;
		const step2Data = stepperContext.getData(STEP2);
		const { shouldSendNoticeToFacilityAssignedToUser } = step2Data;
		const step3Data = stepperContext.getData(STEP3);
		const {
			noticeContent: { templateContent, templateSubject, letterTemplateTranslationsJson }
		} = step3Data;
		const notice = {
			shouldSendNoticeToFacilityAssignedToUser,
			extractorIds: deviceIds,
			templateContent,
			templateSubject,
			templateName,
			letterTemplateTranslationsJson,
			noticeTemplateScheduleId: noticeTemplateId
		};

		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${Resource.SendNotices}`;
		apiService
			.postResourceObject(url, notice)
			.then(() => {
				alertService.addSuccess(localizationService.getLocalizedString('facility.sendCleaningNoticeSuccess'));
				let url = urlService.getReactFacilityDetailsUrl(props.match.params.fogFacilityId);
				navigateTo(history, url);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	return (
		<>
			<StepperContent
				className="ml-4"
				actions={
					<React.Fragment>
						<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
						</StepperAction>
						<StepperAction type="button" id="btn-discard" className="btn btn-link" onClick={onToggleModal}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.discard')}
						</StepperAction>
					</React.Fragment>
				}>
				<div>
					<div className="facility-recipient-grid">
						{!Utils.isDeviceCleaningNotice(step1Data.noticeType)
							? generalNoticeGrid()
							: getSelectedDeviceContactList()}
					</div>
					<button
						id="send"
						className="btn ai-action"
						onClick={(e: any) => {
							e.preventDefault();
							Utils.isDeviceCleaningNotice(step1Data.noticeType) ? sendNotice() : onSendGeneralNotice();
						}}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.send')}
					</button>
				</div>
			</StepperContent>
			<CustomModal {...discardModalProps} />
		</>
	);
};
