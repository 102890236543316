import React from 'react';
import { FilterItem, OperatorCode } from '../../services/data-types/filter-types';
import { FilterDateBetween } from './filter-row-date-between';
import { FilterDateBetweenRange } from './filter-row-date-between-range';
import {
	dateFilterCompareOptionsInitial,
	dateFilterEqOptionsInitial,
	FilterDateCompare
} from './filter-row-date-compare';
import { FilterDateIs } from './filter-row-date-is';

interface FilterDateProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	isForMap?: boolean;
}

export const FilterDate: React.FC<FilterDateProps> = props => {
	switch (props.filterItem.operator.name) {
		case OperatorCode.Is:
			return (
				<FilterDateIs
					rowNumber={props.rowNumber}
					updateFilter={props.updateFilter}
					filterItem={props.filterItem}
					isForMap={props.isForMap}
				/>
			);
		case OperatorCode.IsBetween:
			return (
				<FilterDateBetween
					rowNumber={props.rowNumber}
					updateFilter={props.updateFilter}
					filterItem={props.filterItem}
					isForMap={props.isForMap}
				/>
			);
		case OperatorCode.IsBetween2:
			return (
				<FilterDateBetweenRange
					rowNumber={props.rowNumber}
					updateFilter={props.updateFilter}
					filterItem={props.filterItem}
					isForMap={props.isForMap}
				/>
			);
		case OperatorCode.Eq:
			return (
				<FilterDateCompare
					rowNumber={props.rowNumber}
					updateFilter={props.updateFilter}
					filterItem={props.filterItem}
					isForMap={props.isForMap}
					options={dateFilterEqOptionsInitial}
				/>
			);
		case OperatorCode.LessThan:
		case OperatorCode.LessThanEqual:
		case OperatorCode.GreaterThan:
		case OperatorCode.GreaterThanEqual:
			return (
				<FilterDateCompare
					rowNumber={props.rowNumber}
					updateFilter={props.updateFilter}
					filterItem={props.filterItem}
					isForMap={props.isForMap}
					options={dateFilterCompareOptionsInitial}
				/>
			);
		default:
			throw new Error(`Date filter with operator ${props.filterItem.operator.name} is unsupported`);
	}
};
