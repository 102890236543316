import React from 'react';
import { Event } from '@rcp/types';
import { EventModal } from '../event/event-modal';
import { getDateContent } from 'src/features/timeline/timeline-helper';

interface Props {
	event: Event;
	facilityId?: number;
	index: number;
	todayNow: string;
	deleteCallBack: () => void;
	saveCallBack: () => void;
}

export const TimelineGenericEvent: React.FC<Props> = props => {
	const [editModal, setEditModal] = React.useState(false);

	const openEditModal = (e: any) => {
		e.preventDefault();
		setEditModal(true);
	};

	const oncancel = () => {
		setEditModal(false);
	};

	return (
		<div>
			<div>
				<a href="#/" onClick={openEditModal} id={`linkEvent-${props.event.genericEventId}`}>
					{`${props.event.eventTypeName}`}
				</a>
				&nbsp;
				<span>{getDateContent(props.todayNow, 'event', props.event.completeDate, props.event.dueDate)}</span>
			</div>
			{editModal && (
				<EventModal
					showModal={editModal}
					eventId={props.event.genericEventId}
					facilityId={props.event.facilityId}
					eventToEdit={props.event}
					onCancel={oncancel}
					isFacilityDetailPage={true}
				/>
			)}
		</div>
	);
};
