import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnField, Dictionary, FilterType, IppSampleImportTemplate, LocalStorageName, RouteProps } from '@rcp/types';
import { History } from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, RootState } from 'src/redux';
import { RestSlice } from '../../../../../redux/rest.slice';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import SampleImportTemplateEditModal from './sample-import-edit-modal';
import { ippSampleImportTemplateSlice } from './sample-import-template-list-slice';

interface DispatchProps {
	fetchIppSampleFlowTemplates: (
		restSlice: RestSlice<IppSampleImportTemplate>,
		queryParameters: Dictionary<string>
	) => void;
	updateIppSampleFlowTemplates: (
		id: number,
		userInvite: IppSampleImportTemplate,
		reloadGrid?: boolean,
		successMessage?: string,
		callbackOnSuccess?: () => void
	) => void;
}

interface Props<T> extends RouteProps, DispatchProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

interface State {
	showModal: boolean;
	modalData: IppSampleImportTemplate;
	editRowId: number | undefined;
}

class IppSampleImportTemplatesList extends Component<Props<IppSampleImportTemplate>, State> {
	private initialGridOption: GridOption = {} as GridOption;
	private editModalInitialValue: IppSampleImportTemplate;

	constructor(props: Props<IppSampleImportTemplate>) {
		super(props);

		this.initialGridOption = {
			showTabList: false,
			showRefreshButton: false,
			showEditColumnButton: false,
			showFilter: false,
			showSearch: false,
			doNotApplyMaxTable: true,
			prefix: 'ipp.sampleImportTemplateList.tableColumns',
			storageName: LocalStorageName.IppSampleImportTemplatesGrid,
			searchPlaceholder: '',
			showActions: false,
			pageTitle: '',
			activeTab: 'tab-list',
			sort: [],
			showEditColumnModal: false,
			showAddModal: false,
			showAddButton: false,
			showScheduleInspectionModal: false,
			queryParameters: {},
			showFilterMenuPopOver: false,
			anchorElement: null,
			showFilterEditorModal: false,
			showBulkUpdate: false,
			showExportToSpreadsheet: true,
			description: localizationService.getLocalizedString('ipp.sampleImportTemplateList.tableDescription'),
			allColumns: [
				new ColumnField('columnHeader', FilterType.Text),
				new ColumnField('columnDescription', FilterType.Text),
				new ColumnField('dataFormat', FilterType.Text),
				new ColumnField('required', FilterType.Text),
				new ColumnField('action', FilterType.Text, this.renderRowActions)
			],
			defaultColumns: ['columnHeader', 'columnDescription', 'dataFormat', 'required', 'action']
		};

		this.editModalInitialValue = {
			fileVersionFieldId: 1,
			columnHeader: '',
			systemFieldName: '',
			columnDescription: '',
			dataFormat: '',
			required: 'Recommended',
			dataOptionalityId: 1
		};

		this.state = { showModal: false, editRowId: undefined, modalData: this.editModalInitialValue };
	}

	onSave = () => {
		const { editRowId, modalData } = this.state;
		if (editRowId) {
			this.props.updateIppSampleFlowTemplates(
				editRowId,
				modalData,
				true,
				localizationService.getLocalizedString('ipp.sampleImportTemplateList.editModalFields.messages.success'),
				() => {
					this.setState((prevState: State) => ({
						showModal: !prevState.showModal,
						editRowId: undefined,
						modalData: this.editModalInitialValue
					}));
				}
			);
		}
	};

	public render() {
		return (
			<React.Fragment>
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.sampleImportTemplateList.pageHeading')}</h1>
				</div>
				<SampleImportTemplateEditModal
					showModal={this.state.showModal}
					templateData={this.state.modalData}
					modalTitle={localizationService.getLocalizedString('ipp.sampleImportTemplateList.editModalTitle')}
					onSave={this.onSave}
					onCancel={(e: React.MouseEvent<HTMLElement>) => {
						this.setState((prevState: State) => ({
							showModal: !prevState.showModal,
							editRowId: undefined,
							modalData: this.editModalInitialValue
						}));
					}}
					onFieldChange={this.fieldChangeHandler}
					editRowId={this.state.editRowId}
				/>
				<div className="accordion-data-grid">
					<CollapsibleCard
						className="mt-3"
						accordionHeaderElements={
							<button
								className="btn ai-action pl-2 pr-2 p-1 mr-4"
								onClick={async (e: React.MouseEvent<HTMLElement>) => {
									e.preventDefault();
									const url = urlService.getAuthorityResourcesApiUrl(
										`${Resource.IppDownloadSampleFlowTemplate}`
									);
									await apiService.getResource(url);
									alertService.addSuccess(
										localizationService.getLocalizedString(
											'ipp.sampleImportTemplateList.downloadSuccessMsg'
										)
									);
								}}>
								{localizationService.getLocalizedString(
									'ipp.sampleImportTemplateList.downloadInstructionsBtn'
								)}
							</button>
						}
						accordionType="invitation"
						accordionHeading={localizationService.getLocalizedString(
							'ipp.sampleImportTemplateList.accordionTitle'
						)}>
						<DataGrid
							history={this.props.history}
							match={this.props.match}
							restSlice={ippSampleImportTemplateSlice}
							restState={this.sampleImportTemplatesState}
							gridOption={this.initialGridOption}></DataGrid>
					</CollapsibleCard>
				</div>
			</React.Fragment>
		);
	}

	private sampleImportTemplatesState = (state: RootState) => {
		return state.ippSampleImportTemplates;
	};

	private renderRowActions = (item: any) => {
		const dataItem = item.dataItem as IppSampleImportTemplate;
		return (
			<td>
				<button
					onClick={(e: React.MouseEvent<HTMLElement>) => {
						e.preventDefault();
						this.setState({ showModal: true, modalData: dataItem, editRowId: dataItem.fileVersionFieldId });
					}}
					title={localizationService.getLocalizedString('screen.buttons.edit')}>
					<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon" />
				</button>
			</td>
		);
	};

	private fieldChangeHandler = (fieldName: string) => {
		return (event: React.ChangeEvent<HTMLInputElement>) => {
			this.setState({ modalData: { ...this.state.modalData, [fieldName]: event.target.value } });
		};
	};
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		fetchIppSampleFlowTemplates: (
			restSlice: RestSlice<IppSampleImportTemplate>,
			queryParameters: Dictionary<string>
		) => {
			dispatch(restSlice.fetchPage(queryParameters));
		},
		updateIppSampleFlowTemplates: (
			id: number,
			payload: IppSampleImportTemplate,
			reloadGrid?: boolean,
			successMessage?: string,
			callbackOnSuccess?: () => void
		) =>
			dispatch(
				ippSampleImportTemplateSlice.patchOne(
					id,
					payload,
					reloadGrid || false,
					successMessage,
					callbackOnSuccess
				)
			)
	};
};

export default connect(null, mapDispatchToProps)(IppSampleImportTemplatesList);
