import React from 'react';

interface Props {}

export const AlertInformationSvg: React.SFC<Props> = props => {
	return (
		<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<circle cx="9" cy="9" r="7.5" style={{ fill: '#3455db' }}></circle>
			<circle cx="9" cy="9" r="7.5" style={{ fill: 'none', stroke: '#fff' }}></circle>
			<path
				d="M7.81,11.63a.31.31,0,0,0-.31.31h0v.75a.31.31,0,0,0,.31.31h2.38a.31.31,0,0,0,.31-.31h0v-.75a.31.31,0,0,0-.31-.31H9.88V8.31A.31.31,0,0,0,9.56,8H7.81a.31.31,0,0,0-.31.31h0v.75a.31.31,0,0,0,.31.31h.32v2.26ZM9,5a1.13,1.13,0,1,0,1.13,1.12A1.12,1.12,0,0,0,9,5Z"
				style={{ fill: '#fff' }}></path>
		</svg>
	);
};
