import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
	Middleware,
	MiddlewareAPI,
	Action,
	AnyAction
} from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { History } from 'history';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import { applicationReducers } from './application-reducers';
import { dispatchAccessor, urlService, localizationService } from 'src/services';
import { alertService } from './alert/alert-service';
import { ApiError } from '@rcp/types';
import _ from 'lodash';
import { LinkoRouteActionType } from './linko-route';
const rootReducer = combineReducers({
	...applicationReducers,
	routing: routerReducer
});
export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export function configureReduxStore(history: History, initialState: any) {
	// In development, use the browser's Redux dev tools extension if installed
	const enhancers = [];
	const isDevelopment = process.env.NODE_ENV === 'development';
	if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
	}

	//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ || compose;
	const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
			? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
					// Specify extensions options like name, actionsBlacklist, actionsCreators, serialize...
					trace: true,
					traceLimit: 25
			  })
			: compose;

	const handleError = (error: any) => {
		let debugHint = urlService.isDebugMode() ? '. Check console log for more details.' : '';
		if (error instanceof ApiError) {
			if (error.statusCode === 401 && window) {
				alertService.addError(localizationService.getLocalizedString('errors.notAuthorized'));
				if (setTimeout && window) {
					setTimeout(() => {
						window.localStorage.removeItem('token');
						window.location.href = urlService.getLoginUrl('signOut=true');
					}, 2000);
				}
			} else if (error.statusCode === 404 && window) {
				if (dispatchAccessor.dispatch) {
					dispatchAccessor.dispatch({ type: LinkoRouteActionType.loadNotFoundPage });
				} else {
					throw new Error('setDispatch has to be called after create redux store.');
				}
			} else {
				alertService.addError((error as ApiError).message + debugHint, error);
			}
		} else if (error instanceof Error) {
			let message = (error as Error).message;

			if (_.isEqual(message, 'Failed to fetch')) {
				message = localizationService.getLocalizedString('errors.noInternetConnection');
			}

			alertService.addError(message + debugHint, error);
		} else {
			alertService.addError('Service is not available, please retry later' + debugHint, error);
		}
	};

	const errorHandlingMiddleware: Middleware = ({ dispatch }: MiddlewareAPI) => next => <T extends Action>(
		action: AnyAction | Promise<T>
	) => {
		if (action instanceof Promise) {
			return action.then(dispatch).catch(error => {
				handleError(error);
				return error;
			});
		}
		const returnValue = next(action);
		if (returnValue instanceof Promise) {
			return returnValue.catch(error => {
				handleError(error);
				return error;
			});
		}
		return returnValue;
	};

	const middleware = [errorHandlingMiddleware, thunk, routerMiddleware(history)];

	const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
	dispatchAccessor.setDispatch(store.dispatch, store);

	return store;
}
