import React from 'react';
import { PopoverModal } from 'src/components';
import { localizationService } from 'src/services';

interface Props {
	onCancel: () => void;
	onConfirm: () => void;
	columnName: string;
}

export const UnMapConfirmationModal: React.FC<Props> = (props: Props) => {
	return (
		<PopoverModal
			title={localizationService.getLocalizedString('import.steps.unMapColumnConfirmationModalTitle')}
			showModal={true}
			withoutForm={true}
			cancel={props.onCancel}
			saveButtonText={localizationService.getLocalizedString('screen.buttons.confirm')}
			save={props.onConfirm}>
			{localizationService.getLocalizedString(
				'import.steps.unMapColumnConfirmationModalDescription',
				props.columnName
			)}
		</PopoverModal>
	);
};
