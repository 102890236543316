import { DropDownOption, Dictionary } from '@rcp/types';
import { History } from 'history';
import React, { useState } from 'react';
import { SingleSelectDropdown } from 'src/components/widgets';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';

interface Props {
	history?: History;
}

const Step1: React.FunctionComponent<Props> = props => {
	const stepperContext = React.useContext(StepperContext);
	const [showScheduledReport, setShowScheduledReport] = useState(true);
	const queryParameters = (props.history ? props.history.location.state : {}) as Dictionary<string>;
	let options: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('ipp.buttons.yes'),
			value: true
		},
		{
			label: localizationService.getLocalizedString('ipp.buttons.no'),
			value: false
		}
	];

	const onClickNext = async (event: React.FormEvent) => {
		alertService.clearAllMessages();
		let url = urlService.getAuthorityResourcesApiUrl(
			Resource.IppIndustries,
			urlService.toQueryString(queryParameters)
		);
		try {
			let response = await apiService.httpPatch(url, { showScheduledReport });
			stepperContext.resolve(response);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	return (
		<StepperContent
			id="updateField"
			actions={
				<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
					{localizationService.getLocalizedString('screen.buttons.next')}
				</StepperAction>
			}>
			<SingleSelectDropdown
				id="show-scheduled-reports"
				name="showScheduledReports"
				value={showScheduledReport.toString()}
				className="w-100"
				label={localizationService.getLocalizedString('ipp.industries.industryColumns.showScheduledReport')}
				options={options}
				selfOrder={true}
				noEmptyOption={true}
				onChange={(e: any) => setShowScheduledReport(e.target.value.toString())}
			/>
		</StepperContent>
	);
};

export default Step1;
