import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from 'reactstrap';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import { alertService } from 'src/redux';
import * as actionCreators from 'src/redux/comment';

import { localizationService } from 'src/services';

import './facility-comment.scss';
interface OwnProps {
	anchor: HTMLElement;
	show: boolean;
	toggle: () => void;
}

interface DispatchProps {
	saveFogFacilityComment: (commentText: string) => Promise<boolean | undefined>;
}

type FacilityCommentProp = OwnProps & DispatchProps;

const FacilityCommentComp: React.SFC<FacilityCommentProp> = props => {
	const [comment, setComment] = useState('');
	const defaultTextEle = useRef<HTMLTextAreaElement>(null);
	const [commentError, setCommentError] = useState('');

	React.useEffect(() => {
		if (defaultTextEle && defaultTextEle.current && props.show) {
			defaultTextEle.current.focus();
		}
		if (!props.show) {
			setCommentError('');
		}
	}, [props.show]);

	const onCommentsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};

	const onSaveBtnClick = () => {
		if (comment === '' || comment.trim() === '') {
			let errorTxt = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				'Field'
			);
			setCommentError(errorTxt);
			alertService.addError(errorTxt);
		} else {
			props.saveFogFacilityComment(comment).then((result: boolean | undefined) => {
				if (result === true) {
					props.toggle();
				}
			});
		}
	};

	const onCancelBtnClick = () => {
		props.toggle();
	};

	let hasAnchor = props.anchor instanceof HTMLElement;

	return !hasAnchor ? null : (
		<Popup anchor={props.anchor} show={props.show} animate={false} popupClass="shadow comment-modal-body">
			<div className="dm-sm-flex flex-column">
				<div className="form-group required">
					<label htmlFor="comment"> {localizationService.getLocalizedString('screen.labels.comment')} </label>
					<textarea
						className={`form-control ` + (commentError ? ' is-invalid' : '')}
						id="comment"
						ref={defaultTextEle}
						defaultValue={comment || ''}
						onChange={onCommentsChanged}
					/>
					{commentError && <div className="invalid-feedback">{commentError}</div>}
				</div>

				<div className="popup-modal-footer ml-auto">
					<Button color="btn ai-action" id="saveBtn" className="popup-modal-btn" onClick={onSaveBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.addComment')}
					</Button>
					<Button color="btn ai-white" id="cancelBtn" className="popup-modal-btn" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</div>
		</Popup>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacilityComment: (commentText: string) => dispatch(actionCreators.saveFogFacilityComment(commentText))
	};
};

export const FacilityComment = connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(FacilityCommentComp);
