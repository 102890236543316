import { GetInitialTimelineSettings, TimelineSettings } from './timeline-type';
import { localStorageService, DateUtilService, dispatchAccessor } from 'src/services';
import { LocalStorageName } from '@rcp/types';
import { loadFacilityTimelineEnforcements } from 'src/redux';

export const loadTimelineSettings = (): TimelineSettings => {
	let timelineSettingsToSave = { ...GetInitialTimelineSettings() };

	let savedTimelineSettings = localStorageService.getLocalStorageWithCallback<TimelineSettings>(
		LocalStorageName.FacilityTimelineFilter,
		(filter: TimelineSettings) => {
			filter.startDate = DateUtilService.getDateStringInAuthorityTimeZone(filter.startDate);
			filter.endDate = DateUtilService.getDateStringInAuthorityTimeZone(filter.endDate);
		}
	);
	//discard browser localStorage saved filter if the version is different
	const initialTimelineSettings = GetInitialTimelineSettings();
	if (
		savedTimelineSettings &&
		savedTimelineSettings.version &&
		String.equalCaseInsensitive(savedTimelineSettings.version, initialTimelineSettings.version)
	) {
		//overwrite react component state value from localStorage
		timelineSettingsToSave = { ...savedTimelineSettings };
	}

	return timelineSettingsToSave;
};

export const saveTimelineSettings = (timelineSettings: TimelineSettings) => {
	localStorageService.setLocalStorage(LocalStorageName.FacilityTimelineFilter, timelineSettings);
};

export const getTimelineSettingDateRange = (timelineSettings: any = null) => {
	let currentSettings = timelineSettings ? timelineSettings : loadTimelineSettings();
	let startDateStr = DateUtilService.floorDate(
		DateUtilService.getMomentInAuthorityTimeZone(currentSettings.startDate)
	);
	let endDateStr = DateUtilService.ceilingDate(DateUtilService.getMomentInAuthorityTimeZone(currentSettings.endDate));

	return {
		startDateStr,
		endDateStr
	};
};

export const reloadTimelineEnforcements = () => {
	let dateRange = getTimelineSettingDateRange();
	let startDateStr = dateRange.startDateStr;
	let endDateStr = dateRange.endDateStr;

	dispatchAccessor.dispatch(loadFacilityTimelineEnforcements(startDateStr, endDateStr));
};
