import _ from 'lodash';
import * as LinkoTypes from '@rcp/types';
import moment from 'moment-timezone';
import { Logger } from './localStorageService';

const expectedTokenVersion = '2020-07-30';
export class TokenService {
	setToken(token: LinkoTypes.Token): void {
		token.version = expectedTokenVersion;
		localStorage.setItem('token', JSON.stringify(token));
	}

	getTokenOrDefault(): LinkoTypes.Token {
		let tokenJson = localStorage.getItem('token');
		if (tokenJson) {
			let token = JSON.parse(tokenJson) as LinkoTypes.Token;
			return token;
		}
		return {
			version: expectedTokenVersion,
			accessToken: '',
			expirationEpochTimeInUtc: 0,
			refreshToken: '',
			portalOrganizationId: 0,
			portalOrganizationName: '',
			portalRegulatoryProgramNames: '',
			portalPermissionGroupNames: '',
			portalOrganizationTypeId: 0,
			portalOrganizationTypeName: '',
			ianaTimeZoneName: ''
		};
	}

	isTokenValid(token: LinkoTypes.Token | undefined = undefined): boolean {
		if (_.isUndefined(token)) {
			token = this.getTokenOrDefault();
		}
		if (token.version !== expectedTokenVersion) {
			Logger.info(`token version ${token.version} is unexpected, clear token.`);
			this.clearToken();
			return false;
		}
		let nowEpochTimeInSeconds = moment().unix();
		return token.expirationEpochTimeInUtc >= nowEpochTimeInSeconds;
	}

	clearToken(): void {
		localStorage.removeItem('token');
		localStorage.removeItem(':haulerPortalToken');
	}

	isAdminToken(programName: string): boolean {
		const tokenJson = this.getTokenOrDefault();
		const availablePrograms = tokenJson.portalRegulatoryProgramNames;
		const permissionGroups = tokenJson.portalPermissionGroupNames;
		if (availablePrograms && availablePrograms !== '' && permissionGroups && permissionGroups !== '') {
			const splittedPermissionGroupNames = permissionGroups.split(',').map(groupName => groupName.toLowerCase());
			const splittedAvailablePrograms = availablePrograms.split(',').map(program => program.toLowerCase());
			const currentProgramIndex = splittedAvailablePrograms.indexOf(programName.toLowerCase());
			if (
				currentProgramIndex > -1 &&
				currentProgramIndex < splittedPermissionGroupNames.length &&
				splittedPermissionGroupNames[currentProgramIndex].startsWith('admin')
			) {
				return true;
			}
		}
		return false;
	}

	isSsoFromAqi(): boolean {
		let token = this.getTokenOrDefault();
		return token.ssoFrom === 'AQICloudIdentity';
	}

	hasAccessToIppPortal(): boolean {
		return (this.getTokenOrDefault().portalRegulatoryProgramNames || '')
			.toLowerCase()
			.includes(LinkoTypes.RegulatoryProgramName.IPP.toLowerCase());
	}
}
export const tokenService = new TokenService();
