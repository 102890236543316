import { IppReportPackage } from '@rcp/types';

export interface PackageReviewState {
	reportPackage: IppReportPackage;
}
export const initialPackageReviewState: PackageReviewState = {
	reportPackage: {
		attachmentTypes: [],
		certificationTypes: []
	}
};
