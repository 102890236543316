import React, { useEffect, useState } from 'react';
import { localizationService } from 'src/services';
import { ImportUniqueKeyDataConverter } from '../../../../widgets/lookup/data-converters/import-unique-key-data-converter';
import { ApiError, ImportEntity, ImportField, ImportUniqueKey, LookupType } from '@rcp/types';
import { ImportUniqueKeyUrlResolver, LookupRow, LookupsWidget, SettingAction } from '../../../../widgets/lookup';
import { EditorMode } from '../editors/custom-field-editor';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, RootState } from 'src/redux';
import { importUniqueKeySlice } from './import-unique-key.slice';
import _ from 'lodash';
import ImportUniqueKeyEditor from '../editors/import-unique-key-editor';
import { RestApi } from 'src/services/rest.api';

interface Props {
	importEntity: ImportEntity;
	description: string;
	fieldsPath: string;
	params: string;
	maxNumberOfKey?: number;
	disableRemoval?: boolean;
}

interface ImportUniqueKeySettingState {
	show: boolean;
	selected?: ImportUniqueKey;
	uniqueKeys: ImportUniqueKey[];
	fields: ImportField[];
	mode: EditorMode;
}

const ImportUniqueKeySettingComponent: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();

	const restApi = new RestApi<ImportField>(props.fieldsPath);

	const onError = (reason: any) => {
		if (reason instanceof ApiError) {
			alertService.addError(reason.message);
		} else {
			alertService.addError(reason.toString());
		}
	};

	const initialState: ImportUniqueKeySettingState = {
		show: false,
		mode: EditorMode.Add,
		uniqueKeys: [],
		fields: []
	};

	let { selected, loading } = useSelector((state: RootState) => state.importUniqueKeys);
	const [uniqueKeyState, setUniqueKeyState] = useState(initialState);

	useEffect(() => {
		restApi.getAll(props.params).then(value => {
			if (value.result instanceof Array) {
				onFieldsUpdated(value.result);
			} else {
				onFieldsUpdated(value);
			}
		}, onError);

		// eslint-disable-next-line
	}, []);

	const onUniqueKeyClosed = () => {
		setUniqueKeyState({ ...uniqueKeyState, show: false });
	};

	const onAddUniqueKey = (lookups: LookupRow[]) => {
		const maxNumberOfKey = props.maxNumberOfKey || 3;
		if (lookups && lookups.length >= maxNumberOfKey) {
			let errorMessageKey = 'authoritySetting.importUniqueKey.MaximumThreeUniqueErrors';
			if (maxNumberOfKey === 1) {
				errorMessageKey = 'authoritySetting.importUniqueKey.MaximumOneUniqueErrors';
			}
			alertService.addError(localizationService.getLocalizedString(errorMessageKey));
			return;
		}

		setUniqueKeyState({
			...uniqueKeyState,
			show: true,
			mode: EditorMode.Add,
			selected: undefined
		});
	};

	const onUpdateUniqueKey = (id: any) => {
		dispatch(importUniqueKeySlice.fetchOne(id));

		setUniqueKeyState({ ...uniqueKeyState, show: true, mode: EditorMode.Update, selected: undefined });
	};

	const getDeleteMessage = (row: LookupRow) => {
		return localizationService.getLocalizedString(
			'authoritySetting.importUniqueKey.DeleteUniqueKey',
			_.toString(row.code)
		);
	};

	const onFieldsUpdated = (fields: any) => {
		setUniqueKeyState({ ...uniqueKeyState, fields: fields });
	};

	function getUniqueKeysSection() {
		let dataConverter = new ImportUniqueKeyDataConverter(LookupType.ImportUniqueKey, uniqueKeyState.fields);
		let urlResolver = new ImportUniqueKeyUrlResolver(LookupType.ImportUniqueKey, props.importEntity);
		let settingAction = new SettingAction(LookupType.ImportUniqueKey, urlResolver, dataConverter, false);

		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.importUniqueKey.Title')}</strong>
					<p>{props.description}</p>
				</div>
				<div className="col-lg-8">
					<section>
						<LookupsWidget
							id="uniqueKey"
							name="Unique Keys"
							className="col-sm-12"
							label={localizationService.getLocalizedString('authoritySetting.importUniqueKey.Name')}
							descriptionLabel={localizationService.getLocalizedString(
								'authoritySetting.importUniqueKey.CustomFields'
							)}
							hideActiveStatus={true}
							editDescription={true}
							showDescriptionColum={true}
							onAddButtonClick={onAddUniqueKey}
							deleteMessage={getDeleteMessage}
							hideAddModal={true}
							hideEditModal={true}
							disableRemoval={props.disableRemoval}
							onEditButtonClick={onUpdateUniqueKey}
							settingAction={settingAction}
							maxNumberOfKey={props.maxNumberOfKey}
						/>
					</section>
				</div>
				{uniqueKeyState.show && !loading && (
					<ImportUniqueKeyEditor
						importUniqueKey={selected}
						fields={uniqueKeyState.fields}
						table={props.importEntity}
						mode={uniqueKeyState.mode}
						show={uniqueKeyState.show}
						description={props.description}
						onClosed={onUniqueKeyClosed}
					/>
				)}
			</div>
		);
	}

	return <>{uniqueKeyState.fields && uniqueKeyState.fields.length > 0 && getUniqueKeysSection()}</>;
};

export default ImportUniqueKeySettingComponent;
