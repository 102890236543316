import { faMinus, faPlus, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import './collapsible-card.scss';

interface Props {
	accordionType: string;
	accordionHeading: React.ReactElement | string;
	children?: React.ReactNode;
	accordionHeaderElements?: React.ReactElement;
	className?: string;
	cardClass?: string;
	isHidden?: boolean;
}

interface State {
	activeIcon: IconDefinition;
}

export default class CollapsibleCard extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			activeIcon: props.isHidden ? faPlus : faMinus
		};
	}
	componentDidUpdate(prevProps: Props) {
		if (prevProps.isHidden !== this.props.isHidden) {
			this.setState({
				activeIcon: this.props.isHidden ? faPlus : faMinus
			});
		}
	}
	render() {
		return (
			<>
				<div
					className={`${this.props.className || ''} accordion w-100`}
					id={`${this.props.accordionType}-accordion`}>
					<div className={`card ${this.props.cardClass ? this.props.cardClass : ''}`}>
						<div className="card-header p-2 pl-3 pr-3" id={`${this.props.accordionType}-heading`}>
							<h2 className="mb-0 d-flex">
								<span className="font-size-20px-regular accordion-header align-self-center">
									{this.props.accordionHeading}
								</span>
								<div className="d-flex align-items-center ml-auto accordian-controls">
									{this.props.accordionHeaderElements}
									<FontAwesomeIcon
										icon={this.state.activeIcon}
										className="font-size-20px-regular accordion-header mt-1 cursor-pointer"
										data-toggle="collapse"
										data-target={`#${this.props.accordionType}`}
										onClick={event => {
											let isExpanded = event.currentTarget.getAttribute('aria-expanded');
											this.setState({
												activeIcon: isExpanded === 'true' ? faMinus : faPlus
											});
										}}
									/>
								</div>
							</h2>
						</div>
						<div
							id={`${this.props.accordionType}`}
							className={`collapse ${this.props.isHidden ? '' : 'show'}`}
							data-parent={`#${this.props.accordionType}-accordion`}>
							<div className="card-body">{this.props.children}</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}
