import { CccSiteContact } from '@rcp/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { SingleCheckbox } from 'src/components/widgets';
import { ContactCardBody, ContactModal } from 'src/features';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { useRootStateSelector } from 'src/redux';

interface Props {
	contact: CccSiteContact;
	hazardSendNotice: boolean;
	switchSendNotice: (hazardContact: CccSiteContact, checked: boolean) => void;
	reload: () => void;
}

export const HazardContactCard: React.FC<Props> = props => {
	const history = useHistory();

	const cccHazardContacts = useRootStateSelector(s => s.hazardContacts.result);
	const [isToggleEditContactModal, setToggleEditContactModal] = React.useState(false);

	const switchContactSendNotices = (e: any) => {
		const { checked } = e.target;
		props.switchSendNotice(props.contact, checked);
	};

	const resetModalStateAndReload = () => {
		setToggleEditContactModal(false);
		props.reload();
	};

	return (
		<div className="tab-col mb-5">
			{props.contact.contactId ? (
				<div className="mb-3">
					<a
						href="#/"
						onClick={(e: any) => {
							e.preventDefault();
							setToggleEditContactModal(true);
						}}>
						{props.contact.contactType}
					</a>
					&nbsp;&nbsp;
					{props.contact.isPrimary && (
						<span className="text-muted">{localizationService.getLocalizedString('cccSite.primary')}</span>
					)}
				</div>
			) : (
				<div className="mb-3">
					<a data-toggle="dropdown" href="#/" role="button" aria-haspopup="true" aria-expanded="false">
						{localizationService.getLocalizedString('screen.labels.site')}&nbsp;{props.contact.siteNumber}
					</a>
					<div className="dropdown-menu">
						<button
							className="dropdown-item font-size-16px"
							onClick={e => {
								navigateTo(
									history,
									urlService.getReactAuthorityResourcePath(Resource.CccSites, props.contact.siteId),
									e
								);
							}}>
							{localizationService.getLocalizedString('screen.buttons.view')}
						</button>
					</div>
				</div>
			)}

			<ContactCardBody contact={props.contact} />

			<SingleCheckbox
				id={`contactSendNotices-${props.contact.linkedContactId}`}
				name={`contactSendNotices-${props.contact.linkedContactId}`}
				key={props.contact.linkedContactId || 0}
				label={localizationService.getLocalizedString('cccHazard.sendNoticesCheckBoxLabel')}
				checked={props.contact.sendNotices}
				onChange={switchContactSendNotices}
				isDisabled={!props.hazardSendNotice}
				className="div-checkbox"
			/>

			{isToggleEditContactModal && (
				<ContactModal
					close={() => {
						setToggleEditContactModal(false);
					}}
					linkedContact={props.contact}
					associatedContacts={cccHazardContacts}
					reload={resetModalStateAndReload}
				/>
			)}
		</div>
	);
};
