import { PaginatedResult, FogFacility, CustomFieldDefinition } from '@rcp/types';

export interface FogFacilitiesState {
	customFieldDefinitions: CustomFieldDefinition[];
	paginatedFogFacilities: PaginatedResult<FogFacility>;
}
export const initialFogFacilitiesState: FogFacilitiesState = {
	customFieldDefinitions: [],
	paginatedFogFacilities: {
		page: 1,
		size: 10,
		total: 0,
		result: []
	}
};
