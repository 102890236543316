import React, { FC, useEffect, useState } from 'react';
import { apiService, tokenService, urlService, navigateTo, Logger, localizationService } from 'src/services';
import queryString from 'query-string';
import { alertService } from 'src/redux';
import { Token, SignInPortal, MfaDetails } from '@rcp/types';
import _ from 'lodash';
import { History } from 'history';
import { NotFound } from '../home/not-found';
import { AccessDeniedPage } from '../home/access-denied';

interface Props {
	history: History;
}

const ForwardToReportPackage: FC<Props> = props => {
	const [error, setError] = useState('');
	useEffect(() => {
		let queryParameters = queryString.parse(_.toLower(window.location.search));
		let leReportPackageId = queryParameters.lereportpackageid;
		let currentToken = tokenService.getTokenOrDefault();
		if (currentToken && tokenService.isTokenValid(currentToken) && currentToken.signInResult) {
			let signInResult = currentToken.signInResult;
			if (leReportPackageId && signInResult) {
				let tokenParams = {
					accessToken: currentToken.accessToken
				};
				let getForwardUrl = `${urlService.getApiBaseUrl()}/Forward/LEReportPackage/${leReportPackageId}?${urlService.toQueryString(
					tokenParams
				)}`;
				apiService
					.httpGet(getForwardUrl)
					.then((forwardUrl: string) => {
						Logger.info(`ReportPackage forwarded to: ${forwardUrl}`);
						let isIndustryPortal = forwardUrl.indexOf('service-acp') > 0;
						let portal: SignInPortal;
						if (isIndustryPortal) {
							portal = signInResult.allowedPortals.find(
								p => forwardUrl.indexOf(p.guid) > 0
							) as SignInPortal;
						} else {
							portal = signInResult.allowedPortals.find(
								p => forwardUrl.indexOf(p.subDomain) > 0
							) as SignInPortal;
						}
						let userIssuedRegulatoryProgramNames = _.map(
							portal.userIssuedPrograms,
							(r: any) => r.regulatoryProgramName
						).join(',');
						let userIssuedPermissionGroupNames = _.map(
							portal.userIssuedPrograms,
							(r: any) => r.permissionGroupName
						).join(',');
						let organizationRegulatoryProgramId: string | undefined = undefined;
						if (portal.userIssuedPrograms && portal.userIssuedPrograms.length) {
							organizationRegulatoryProgramId = _.toString(
								portal.userIssuedPrograms[0].organizationRegulatoryProgramId
							);
						}
						let mfaEnabledPrograms = urlService.getMfaEnabledPrograms(
							signInResult.regulatoryProgramMFATypeDetails as MfaDetails[]
						);
						let token: Token = {
							accessToken: signInResult.accessToken as string,
							expirationEpochTimeInUtc: signInResult.expirationEpochTimeInUtc as number,
							refreshToken: signInResult.refreshToken as string,
							portalOrganizationId: portal.organizationId,
							portalOrganizationName: portal.organizationName,
							portalOrganizationTypeId: portal.organizationTypeId,
							portalOrganizationTypeName: portal.organizationTypeName,
							portalRegulatoryProgramNames: userIssuedRegulatoryProgramNames,
							portalPermissionGroupNames: userIssuedPermissionGroupNames,
							portalSubDomain: portal.subDomain,
							portalOrganizationRegulatoryProgramId: organizationRegulatoryProgramId,
							userName: signInResult.userName,
							ianaTimeZoneName: portal.ianaTimeZoneName,
							mfaEnabledPrograms: mfaEnabledPrograms,
							guid: portal.guid
						};
						urlService.forwardToPortal(token, forwardUrl);
					})
					.catch((e: any) => {
						setError(e.message);
					});
				return;
			} else {
				alertService.addError('LEReportPackageId not found');
			}
		} else {
			if (leReportPackageId) {
				let params = {
					path: `/forward?LEReportPackageId=${leReportPackageId}`
				};
				navigateTo(props.history, `/SignIn?${urlService.toQueryString(params)}`);
			} else {
				navigateTo(props.history, '/SignIn');
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (error == localizationService.getLocalizedString('errors.reportPackageDoesNotMatch')) {
		return <NotFound message={error} />;
	}
	if (error == localizationService.getLocalizedString('errors.noPermissionReportPackage')) {
		return <AccessDeniedPage message={error} />;
	}
	return <></>;
};

export default ForwardToReportPackage;
