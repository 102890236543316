import React from 'react';

interface Props {}

export const IncidentEventSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.25">
			<path
				d="M15.866 12.75 8.866.5a1 1 0 0 0-1.732 0l-7 12.25A1 1 0 0 0 1 14.25h14a1 1 0 0 0 .868-1.5m-7.124-.725H7.248v-1.447h1.494Zm.04-5.482-.4 3.444h-.753l-.415-3.444V4.671h1.566Z"
				fill="#4b6a88"
			/>
		</svg>
	);
};
