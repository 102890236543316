import * as React from 'react';
import * as ReactDOM from 'react-dom';
import _ from 'lodash';
import { insertImageFiles } from './utils';
import { TextInput } from 'src/components';
import { localizationService, apiService, urlService, Resource, validationService } from 'src/services';
import { CustomModal } from '../modal/modal';
import { ImageDialogFields, UploadMaximumSize10MB } from '@rcp/types';
import { DragAndDrop } from '../drag-and-drop';
import { alertService } from 'src/redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const initialFormField: ImageDialogFields = {
	file: null,
	alternateText: '',
	width: null,
	height: null,
	title: '',
	src: ''
};

const supportedFileTypes: string = '.jpg,.jpeg,.bmp,.png,.JPG';

export const InsertImageDialog = props => {
	const [file, setFile] = React.useState<File | null>(null);
	const [formField, setFormField] = React.useState(initialFormField);

	const changeFormState = (e: any) => {
		let newState = { ...formField };
		let { name, value } = e.target;
		if (e.target.type === 'number') value = Math.abs(value);
		_.set(newState, name, value);
		setFormField(newState);
	};

	const onClose = () => {
		props.onClose.call(undefined);
	};

	const onInsert = () => {
		const { view, imageNode } = props;
		const nodes = view.state.schema.nodes;
		const nodeType = nodes[imageNode];
		const position = null;

		const data = {
			title: formField.title || null,
			alt: formField.alternateText || null,
			width: formField.width || null,
			height: formField.height || null,
			src: formField.src
		};
		let formData = new FormData();
		formData.append('files', file as File);
		const headers = {};
		//default make the image URL expires with 10 years
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.UploadAttachmentImage}?expirationDays=3650`;

		apiService.postFormData(url, formData, headers).then(resp => {
			data.src = resp.imageUrl;
			const attrs = Object.keys(data)
				.filter(key => data[key] !== null && data[key] !== '')
				.reduce((acc, curr) => Object.assign(acc, { [curr]: data[curr] }), {});

			if (file) {
				insertImageFiles({ view, files: [file], nodeType, position, attrs });
				onClose();
				view.focus();
			} else {
				alertService.addError(localizationService.getLocalizedString('attachments.filesRequired'));
			}
		});
	};

	const handleDrop = (chosenFiles: any) => {
		if (!validationService.validateUploadSize(chosenFiles[0].size, UploadMaximumSize10MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit10MB')
			);
			return;
		}
		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}

		if (!isSupportedFileType(chosenFiles[0])) {
			alertService.addError(
				localizationService.getLocalizedString(
					'haulerPortal.submitCleaning.errors.fileTypeNotSupported',
					chosenFiles[0].name
				)
			);
		} else {
			chosenFiles.length <= 1 && alertService.clearAllMessages();
			setFile(chosenFiles[0]);
		}
	};

	const onChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;

		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}

		if (!isSupportedFileType(chosenFiles[0])) {
			alertService.addError(localizationService.getLocalizedString('attachments.fileTypeNotSupported'));
		} else if (!validationService.validateUploadSize(chosenFiles[0].size, UploadMaximumSize10MB)) {
			alertService.addError(localizationService.getLocalizedString('importFile.exceedUploadSizeLimit10MB'));
			inputElement.files = null;
			return;
		} else {
			chosenFiles.length <= 1 && alertService.clearAllMessages();
			setFile(chosenFiles[0]);
		}
	};

	const isSupportedFileType = (file: File) => {
		let supportedFileExtensions = supportedFileTypes.replace(/[.]/g, '');
		let selectedFileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		return _.toLower(supportedFileExtensions)
			.split(',')
			.includes(_.toLower(selectedFileExtension));
	};

	const removeFile = (size: number = 10) => {
		return (
			<span id="remove-file" className="cursor-pointer p-1" onClick={() => setFile(null)}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};

	return ReactDOM.createPortal(
		<CustomModal
			title={localizationService.getLocalizedString('notices.insertImage')}
			onCancelButtonClick={props.onClose}
			onOkayButtonClick={onInsert}
			okayButtonText={localizationService.getLocalizedString('notices.insert')}
			showModal={props.show}>
			<div className="form-group required">
				<label>{localizationService.getLocalizedString('notices.image')}</label>
				<DragAndDrop
					dragMessage={localizationService.getLocalizedString('notices.dragAndDrop')}
					handleDrop={handleDrop}
					className="drag-submit-editor">
					<div className="file-input-group p-1">
						<label
							className="btn ai-secondary mb-1"
							htmlFor="btnFileImport"
							aria-describedby="file-input-help">
							{localizationService.getLocalizedString('notices.chooseFile')}
						</label>
						<input
							type="file"
							id="btnFileImport"
							className="file-input mr-4 "
							onChange={onChangeHandler}
							onClick={(e: any) => (e.target.value = null)}
							style={{ width: '0px' }}
							accept={supportedFileTypes}
						/>
						{file ? (
							<span key={`file`}>
								<label className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
									<div className="d-inline-flex" title={file && file.name}>
										<span id="file-badge-name">{file && file.name}</span>
									</div>
									{removeFile()}
								</label>
							</span>
						) : (
							<label className="file-name ml-4 pl-4">
								{localizationService.getLocalizedString('notices.dragAndDrop')}
							</label>
						)}
					</div>
				</DragAndDrop>
			</div>

			<TextInput
				id="alternateText"
				name="alternateText"
				onChange={changeFormState}
				label={localizationService.getLocalizedString('notices.alternateText')}
				value={formField.alternateText}
			/>
			<TextInput
				id="title"
				name="title"
				onChange={changeFormState}
				label={localizationService.getLocalizedString('notices.title')}
				value={formField.title}
			/>
			<div className="form-row mb-3">
				<TextInput
					id="width"
					name="width"
					className="col-lg-6"
					type="number"
					value={formField.width || ''}
					onChange={changeFormState}
					label={localizationService.getLocalizedString('notices.width')}
				/>
				<TextInput
					id="height"
					name="height"
					className="col-lg-6"
					type="number"
					onChange={changeFormState}
					label={localizationService.getLocalizedString('notices.height')}
					value={formField.height || ''}
				/>
			</div>
		</CustomModal>,
		document.body
	);
};
