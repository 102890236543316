import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, customDefinedFieldService, authoritySettingService } from 'src/services';
import {
	loadCustomFieldDefinitions,
	useReduxSelector,
	loadFrequencyList,
	loadAuthorityTags,
	loadAuthorityMapCategories,
	loadAuthorityReceivingPlants,
	loadJurisdiction,
	RootState
} from 'src/redux';
import { BulkUpdateSteps } from '@rcp/types';
import { SingleCheckbox } from 'src/components/widgets';
import { useDispatch, useSelector } from 'react-redux';
import {
	CustomField,
	processCustomField,
	BulkUpdateFields,
	setFacilityFrequencyFieldsList,
	selectableItem,
	setFacilityTagFieldsList,
	BulkUpdateFieldList,
	setReceivingPlantFieldList,
	setMapCategoryFieldList,
	setAssignedToFieldList,
	setJurisdictionFieldList,
	setComplianceStatusFieldList,
	setRiskScoreValues,
	setHaulersFieldList
} from './bulk-update-service';
import { Facility } from '../../facility-types';

import _ from 'lodash';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';
import { haulersSlice } from 'src/components/authority/fog/haulers';

interface Props {
	vertical?: boolean;
}

const initialBulkUpdateFields: BulkUpdateFields = {
	customFields: [],
	facilityFields: [...BulkUpdateFieldList]
};

const ChooseFieldsStep: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [bulkUpdateFields, setBulkUpdateFields] = React.useState<BulkUpdateFields>(initialBulkUpdateFields);
	const dispatch = useDispatch();

	const facilityCustomFieldDefinitions = useReduxSelector(
		state => state.customFieldDefinitions.customFieldDefinitions
	);
	const authorityFrequencies = useReduxSelector(state => state.extractors.frequencyList);
	const authorityTags = useReduxSelector(state => state.authorityTags.tags);
	const { result: authorityUsers } = useSelector((state: RootState) => state.authorityUsers);
	const { result: haulers } = useSelector((state: RootState) => state.haulers);
	const mapCategories = useReduxSelector(state => state.authoritySettingLookups.mapCategories);
	const receivingPlants = useReduxSelector(state => state.authoritySettingLookups.receivingPlants);
	const jurisdictions = useReduxSelector(state => state.jurisdictions.jurisdictionList);

	React.useEffect(() => {
		let step1Data: BulkUpdateFields = stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
		if (step1Data == null || step1Data.customFields.length < 1 || step1Data.facilityFields.length < 0) {
			dispatch(loadCustomFieldDefinitions('tFogFacility'));
			dispatch(loadFrequencyList());
			dispatch(loadAuthorityTags());
			dispatch(authorityUsersSlice.fetchAll());
			dispatch(loadAuthorityMapCategories());
			dispatch(loadAuthorityReceivingPlants());
			dispatch(loadJurisdiction());
			dispatch(haulersSlice.fetchAll('size=2147483647'));
			authoritySettingService.getAuthorityComplianceStatus().then((complianceStatusList: string[]) => {
				setComplianceStatusFieldList(complianceStatusList);
			});
			setRiskScoreValues(setBulkUpdateFields, bulkUpdateFields);
			step1Data = { ...initialBulkUpdateFields };
			step1Data.customFields.forEach(x => (x.chosen = false));
			step1Data.facilityFields.forEach(x => (x.chosen = false));
			step1Data.facilityFields.forEach(x => (x.value = null));
		}
		setBulkUpdateFields(step1Data);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	React.useEffect(() => {
		if (authorityFrequencies.length > 0) {
			setFacilityFrequencyFieldsList(
				authorityFrequencies.filter(i => i.isActive === true),
				setBulkUpdateFields,
				bulkUpdateFields
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityFrequencies]);

	React.useEffect(() => {
		if (authorityTags.length > 0) {
			let allFogTags = _.filter(authorityTags, tag => tag.regulatoryProgramName === 'FOG');
			if (allFogTags && allFogTags.length > 0) {
				let fogTags = allFogTags[0];
				setFacilityTagFieldsList(fogTags.tags, setBulkUpdateFields, bulkUpdateFields);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityTags]);

	React.useEffect(() => {
		if (authorityUsers.length > 0) {
			setAssignedToFieldList(authorityUsers, setBulkUpdateFields, bulkUpdateFields);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityUsers]);

	React.useEffect(() => {
		if (haulers.length > 0) {
			setHaulersFieldList(haulers, setBulkUpdateFields, bulkUpdateFields);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [haulers]);

	React.useEffect(() => {
		if (mapCategories && mapCategories.length > 0) {
			setMapCategoryFieldList(mapCategories, setBulkUpdateFields, bulkUpdateFields);
		}
	}, [mapCategories]);

	React.useEffect(() => {
		if (receivingPlants && receivingPlants.length > 0) {
			setReceivingPlantFieldList(receivingPlants, setBulkUpdateFields, bulkUpdateFields);
		}
	}, [receivingPlants]);

	React.useEffect(() => {
		if (jurisdictions && jurisdictions.length > 0) {
			setJurisdictionFieldList(jurisdictions, setBulkUpdateFields, bulkUpdateFields);
		}
	}, [jurisdictions]);

	React.useEffect(() => {
		if (facilityCustomFieldDefinitions.length > 0) {
			let newValues = { ...bulkUpdateFields };
			let dummyFacility = {} as Facility;
			let customFieldItems = customDefinedFieldService.toCustomFieldItems(
				facilityCustomFieldDefinitions.filter(i => !i.isImportKey),
				dummyFacility
			);
			let customFields = customFieldItems.map(i => {
				return {
					...i,
					title: i.label,
					chosen: false
				} as CustomField;
			});

			newValues.customFields = customFields.map(i => processCustomField(i));
			newValues.customFields = newValues.customFields.slice();
			setBulkUpdateFields({ ...newValues });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [facilityCustomFieldDefinitions]);

	React.useEffect(() => {
		const step1Data: BulkUpdateFields = stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);

		if (step1Data) {
			setBulkUpdateFields({ ...step1Data });
		} else {
			const step1Step = stepperContext.getStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
			if (step1Step) {
				step1Step.data = JSON.parse(JSON.stringify(initialBulkUpdateFields));
				stepperContext.updateStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS, step1Data);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	const onClickNext = () => {
		if (canGoNextStep() === false) return;
		stepperContext.resolve(bulkUpdateFields);
	};

	const fieldChosenChanged = (field: selectableItem) => {
		field.chosen = !field.chosen;
		let newValues = { ...bulkUpdateFields };
		setBulkUpdateFields(newValues);
	};

	const getFieldsDiv = (fields: selectableItem[], key: string) => {
		return fields.map(field => {
			let keyStr = `${key}-${field.fieldName}`;
			return (
				<div className="form-row" key={keyStr}>
					<SingleCheckbox
						id={keyStr}
						name={keyStr}
						label={field.title}
						checked={field.chosen}
						onChange={() => fieldChosenChanged(field)}
					/>
				</div>
			);
		});
	};

	const canGoNextStep = (): boolean => {
		let chosenFacilityFields = bulkUpdateFields.facilityFields.find(i => i.chosen === true);
		if (chosenFacilityFields !== undefined) {
			return true;
		}

		let chosenCustomFields = bulkUpdateFields.customFields.find(i => i.chosen === true);
		if (chosenCustomFields !== undefined) {
			return true;
		}

		return false;
	};
	return (
		<StepperContent
			id="step3ChooseCustomFields"
			className=" full-width"
			actions={
				<React.Fragment>
					<StepperAction
						type="button"
						id="btnNext"
						className="btn btn-link"
						onClick={onClickNext}
						disabled={stepperContext.isLoading()}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-group">
				<div className="d-flex flex-row">
					<div className="flex-fill">
						<label className="choose-field-head">
							{localizationService.getLocalizedString('bulkUpdate.facilityFieldHead')}
						</label>
						{getFieldsDiv(bulkUpdateFields.facilityFields, 'FacilityField')}
					</div>
					<div className="flex-fill">
						<label className="choose-field-head">
							{localizationService.getLocalizedString('bulkUpdate.customFieldsHead')}
						</label>
						{getFieldsDiv(bulkUpdateFields.customFields, 'CustomField')}
					</div>
				</div>
			</div>
		</StepperContent>
	);
};

export default ChooseFieldsStep;
