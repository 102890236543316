import {
	ApiPortal,
	ColumnDefinition,
	DropDownOption,
	LetterTemplateTranslation,
	LocalStorageName,
	TranslationLanguage
} from '@rcp/types';
import _ from 'lodash';
import { FormField } from 'src/components/authority/fog/facilities/facility-details/facility-notice-steps';
import { FormFields } from 'src/components/authority/fog/settings/notices/cleaning-notice-stepper/steps';
import { alertService } from 'src/redux';
import { authoritySettingService } from './authoritySettingService';
import { apiService, ApiService } from './data-service';
import { localStorageService } from './localStorageService';
import { Resource, urlService } from './url-service';
export interface TranslatedString {
	fromString: string;
	toString: string;
}
const baseUrl = 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0';
const azureApiService = new ApiService(true);
const settingUrl = `${urlService.getApiBaseUrl(ApiPortal.Account)}/${Resource.SystemSettings}/Translate`;
class TranslateService {
	headers = {
		'Ocp-Apim-Subscription-Key': '',
		'Content-type': 'application/json; charset=UTF-8',
		'Ocp-Apim-Subscription-Region': 'eastus'
	};

	async translateString(
		text: string | number = '',
		isHtml?: boolean,
		toLanguageCode?: string,
		useCache: boolean = true,
		fromLanguageCode: string = 'en'
	): Promise<string | number> {
		if (this.headers['Ocp-Apim-Subscription-Key'] === '') {
			let key = await azureApiService.httpGet(settingUrl);
			this.headers['Ocp-Apim-Subscription-Key'] = key;
		}

		let languageCode = toLanguageCode;
		if (!languageCode) {
			let toLanguage = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as
				| TranslationLanguage
				| undefined;
			if (!toLanguage) {
				return text;
			}
			languageCode = toLanguage.code;
		}

		if (String.equalCI(languageCode, fromLanguageCode)) {
			return text;
		}

		let translation = useCache ? localStorageService.getTranslationValueFromCache(text, languageCode) : null;
		if (!translation) {
			// auto translate

			var endpoint = `${baseUrl}&from=${fromLanguageCode}&to=${languageCode}`;
			if (isHtml) {
				endpoint = endpoint + '&textType=html';
			}
			let data = [
				{
					text: text
				}
			];
			let translatedValue = '';
			let errorFound = false;
			let result = await azureApiService.httpPost(endpoint, data, this.headers, undefined, undefined).catch(e => {
				let errorMessage = JSON.parse(e.text);
				alertService.addError(errorMessage.error.message);
				errorFound = true;
			});
			if (errorFound) {
				return text;
			}
			translatedValue = result[0].translations[0].text;
			// add to cache
			if (useCache) {
				localStorageService.addTranslationValueToCache(text, languageCode, translatedValue);
			}

			return translatedValue;
		} else {
			return translation;
		}
	}

	async translateStringBatch(
		textBatch: string[],
		isHtml: boolean,
		useCache: boolean = true,
		language?: TranslationLanguage
	) {
		if (this.headers['Ocp-Apim-Subscription-Key'] == '') {
			let key = await apiService.getResource<string>(settingUrl);
			this.headers['Ocp-Apim-Subscription-Key'] = key;
		}
		var toLanguage = language
			? language
			: (localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as TranslationLanguage);
		if (!toLanguage || !toLanguage.code || toLanguage.code == 'en') {
			return textBatch;
		}

		let textBatchToReturn = [] as TranslatedString[];
		let textBatchToTranslated = [] as string[];
		_.each(textBatch, text => {
			let translation = useCache ? localStorageService.getTranslationValueFromCache(text, toLanguage.code) : null;
			if (translation) {
				textBatchToReturn.push({ fromString: text, toString: translation } as TranslatedString);
			} else {
				textBatchToTranslated.push(text);
			}
		});

		if (!_.isEmpty(textBatchToTranslated)) {
			var endpoint = `${baseUrl}&from=en&to=${toLanguage.code}`;
			if (isHtml) {
				endpoint = endpoint + '&textType=html';
			}

			let data = textBatchToTranslated.map(item => ({
				text: item
			}));
			let errorFound = false;
			let result = await azureApiService.httpPost(endpoint, data, this.headers, undefined, undefined).catch(e => {
				let errorMessage = JSON.parse(e.text);
				alertService.addError(errorMessage.error.message);
				errorFound = true;
			});
			if (errorFound) {
				return textBatch;
			}
			let translatedValues = result;
			_.each(translatedValues, function(translation, i: number) {
				textBatchToReturn.push({
					fromString: textBatchToTranslated[i],
					toString: translation.translations[0].text
				});
				if (useCache) {
					localStorageService.addTranslationValueToCache(
						textBatchToTranslated[i],
						toLanguage.code,
						translation.translations[0].text
					);
				}
			});
		}
		return textBatchToReturn;
	}

	async translateGridData(gridData: any[], columnsToTranslate: string[]): Promise<any[]> {
		var toLanguage = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as TranslationLanguage;
		if (!toLanguage || toLanguage.code == 'en') {
			return gridData;
		}
		var newGridData = _.cloneDeep(gridData);
		let stringsToTranslate = [] as TranslatedString[];
		for (const column of columnsToTranslate) {
			_.each(gridData, data => {
				if (data[column]) {
					stringsToTranslate.push({ fromString: data[column], toString: '' });
				}
			});
		}

		var result = (await this.translateStringBatch(
			stringsToTranslate.map(x => x.fromString),
			false
		)) as TranslatedString[];

		for (const column of columnsToTranslate) {
			_.each(newGridData, data => {
				if (data[column] && result) {
					var newValue = result.find(x => x.fromString == data[column]);
					if (newValue) {
						data[column] = newValue.toString;
					}
				}
			});
		}

		return newGridData;
	}

	async translateColumnHeaders(columnsToTranslate: ColumnDefinition[]): Promise<ColumnDefinition[]> {
		var toLanguage = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as TranslationLanguage;
		if (!toLanguage || toLanguage.code == 'en') {
			return columnsToTranslate;
		}
		var newColumns = _.cloneDeep(columnsToTranslate);
		let stringsToTranslate = [] as TranslatedString[];

		_.each(newColumns, column => {
			stringsToTranslate.push({ fromString: column.title, toString: '' });
		});

		var results = (await this.translateStringBatch(
			stringsToTranslate.map(x => x.fromString),
			false
		)) as TranslatedString[];

		_.each(newColumns, column => {
			var result = results.find(x => x.fromString == column.title);
			if (result) {
				column.translatedTitle = result.toString;
			}
		});
		return newColumns;
	}

	async translateColumnDefinitions(columnsToTranslate: ColumnDefinition[]): Promise<ColumnDefinition[]> {
		var toLanguage = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as TranslationLanguage;
		if (!toLanguage || toLanguage.code == 'en') {
			return columnsToTranslate;
		}
		var newColumns = _.cloneDeep(columnsToTranslate);
		let stringsToTranslate = [] as TranslatedString[];

		_.each(newColumns, column => {
			stringsToTranslate.push({ fromString: column.title, toString: '' });
		});

		var results = (await this.translateStringBatch(
			stringsToTranslate.map(x => x.fromString),
			false
		)) as TranslatedString[];

		_.each(newColumns, column => {
			var result = results.find(x => x.fromString == column.title);
			if (result) {
				column.title = result.toString;
			}
		});
		return newColumns;
	}

	async translateDropdown(dropdownValues: DropDownOption[]): Promise<DropDownOption[]> {
		var toLanguage = localStorageService.getLocalStorage(LocalStorageName.DisplayLanguage) as TranslationLanguage;
		if (!toLanguage || toLanguage.code == 'en') {
			return dropdownValues;
		}
		var newValues = _.cloneDeep(dropdownValues);
		let stringsToTranslate = [] as TranslatedString[];

		_.each(newValues, value => {
			stringsToTranslate.push({ fromString: value.label, toString: '' });
		});

		var results = (await this.translateStringBatch(
			stringsToTranslate.map(x => x.fromString),
			false
		)) as TranslatedString[];

		_.each(newValues, value => {
			var result = results.find(x => x.fromString == value.label);
			if (result) {
				value.label = result.toString;
			}
		});
		return newValues;
	}

	async translateHtml(htmlString: string, toLanguageCode?: string, fromLanguageCode: string = 'en'): Promise<string> {
		return await this.translateString(htmlString, true, toLanguageCode, false, fromLanguageCode);
	}

	async translateHtmlAndSetMessage(
		message: string,
		setMessage: React.Dispatch<React.SetStateAction<string>>,
		language: TranslationLanguage
	) {
		let isLanguageSupportAvailable = await authoritySettingService.isLanguageSelectorAvailable();

		if (isLanguageSupportAvailable && language && language.code) {
			let translatedMessage = await translateService.translateHtml(message);
			setMessage(translatedMessage);
		} else {
			setMessage(message);
		}
	}

	removeDoNotTranslateHtmlFromSubject(subject: string) {
		return subject
			.replaceAll("<span class='notranslate'>", '')
			.replaceAll('<span class="notranslate">', '')
			.replaceAll('</span>', '');
	}

	async translateNoticeContent(
		noticeContent: FormField | FormFields,
		setLanguageTranslation: React.Dispatch<React.SetStateAction<LetterTemplateTranslation>>,
		language: TranslationLanguage
	) {
		let templateSubject = this.prepareTemplateSectionForTranslation(noticeContent.templateSubject || '');
		let templateContent = this.prepareTemplateSectionForTranslation(noticeContent.templateContent || '');

		let translatedStrings = await this.translateStringBatch(
			[templateSubject, templateContent],
			true,
			false,
			language
		);

		if (translatedStrings && typeof translatedStrings[0] !== 'string' && typeof translatedStrings[1] !== 'string') {
			let translatedNoticeContent: LetterTemplateTranslation = {
				templateContent: this.undoPreparationsForTranslationFromTemplateSubject(translatedStrings[1].toString),
				templateSubject: this.undoPreparationsForTranslationFromTemplateSubject(translatedStrings[0].toString),
				languageId: language.systemLanguageId,
				languageCode: language.code,
				languageName: language.name
			};

			setLanguageTranslation(translatedNoticeContent);
		}
	}

	prepareTemplateSectionForTranslation(templateSection: string) {
		return templateSection
			.replaceAll('{{', "<span class='notranslate'>{{")
			.replaceAll('}}', '}}</span>')
			.replaceAll('[[Do not translate start]]', "<span class='notranslate'>[[Do not translate start]]")
			.replaceAll('[[Do not translate end]]', '[[Do not translate end]]</span>');
	}

	undoPreparationsForTranslationFromTemplateSubject(templateSection: string) {
		return templateSection
			.replaceAll("<span class='notranslate'>{{", '{{')
			.replaceAll('}}</span>', '}}')
			.replaceAll("<span class='notranslate'>[[Do not translate start]]", '[[Do not translate start]]')
			.replaceAll('[[Do not translate end]]</span>', '[[Do not translate end]]');
	}
}

export const translateService = new TranslateService();
