import React, { useState, FC, useEffect } from 'react';
import { CleaningEvent, Dictionary, DropDownOption, Extractor } from '@rcp/types';
import { ComboBoxDropdown, PopoverModal, SingleSelectDropdown } from 'src/components';
import { localizationService, validationService } from 'src/services';
import { alertService, loadExtractorList, useRootStateSelector } from 'src/redux';
import { useDispatch } from 'react-redux';
import { FilterDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';
import { nameof } from 'ts-simple-nameof';
import { ListItemProps } from '@progress/kendo-react-dropdowns';
import { CleaningModal } from '.';

interface Props {
	showModal: boolean;
	toggleModal: () => void;
	title: string;
	queryParameters?: Dictionary<string>;
}

interface FormField {
	selectedFacilityId?: number;
	selectedExtractorId?: number;
	selectedFacilityIdError?: string;
	selectedExtractorIdError?: string;
}

export const SelectFacilityAndDeviceModal: FC<Props> = props => {
	const { showModal, title, toggleModal, queryParameters } = props;
	const [formState, setFormState] = useState<FormField>({});
	const extractors = useRootStateSelector(s => s.extractors).extractorList;
	const [facilityOptionValues, setFacilityOptionValues] = useState<DropDownOption[]>([]);
	const [extractorOptionValues, setExtractorOptionValues] = useState<DropDownOption[]>([]);
	const [showCleaningModal, setShowCleaningModal] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setFormState({ ...formState, selectedExtractorId: undefined });
	}, [formState.selectedFacilityId]);

	useEffect(() => {
		dispatch(loadExtractorList());
	}, []);

	useEffect(() => {
		if (extractors.length <= 0) return;
		let facilityOptions: DropDownOption[] = [];

		facilityOptions = extractors
			.filter(extractor => extractor)
			.map((extractor: Extractor) => {
				return {
					label: extractor.facilityName,
					value: extractor.facilityId,

					facilityNumber: extractor.facilityNumber,
					addressLine1: extractor.addressLine1,
					cityName: extractor.cityName,
					jurisdictionCode: extractor.jurisdictionCode,
					zipCode: extractor.zipCode
				} as DropDownOption;
			})
			.filter((schema, index, self) => {
				return index === self.findIndex(obj => obj.value === schema.value);
			})
			.sort((facilA, facilB) => {
				return facilA.label.toUpperCase() > facilB.label.toUpperCase() ? 1 : -1;
			});
		setFacilityOptionValues(facilityOptions);
	}, [extractors]);

	useEffect(() => {
		if (extractors.length < 0) return;

		let extractorOptions: DropDownOption[] = [];

		if (formState.selectedFacilityId) {
			extractorOptions = extractors
				.filter(extr => extr.facilityId === formState.selectedFacilityId)
				.map(extr => getDeviceItem(extr))
				.sort((extrA, extrB) => {
					return extrA.label.toUpperCase() > extrB.label.toUpperCase() ? -1 : 1;
				});
			setExtractorOptionValues(extractorOptions);
		}
	}, [formState.selectedFacilityId]);

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'selectedFacilityId',
			'selectedFacilityIdError',
			localizationService.getLocalizedString('facility.facility')
		);
		formState.selectedFacilityId &&
			validationService.validateRequiredField(
				newState,
				'selectedExtractorId',
				'selectedExtractorIdError',
				localizationService.getLocalizedString('pumpOut.device')
			);

		setFormState(newState);
		const isFromValid = !validationService.hasError(
			newState,
			'selectedExtractorIdError',
			'selectedFacilityIdError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const toggleCleaningModal = () => {
		setShowCleaningModal(!showCleaningModal);
	};

	const resetFormState = () => setFormState({});

	const onSubmit = () => {
		if (isFormValidateForSave()) {
			toggleCleaningModal();
		}
	};

	const onModalCancel = () => {
		props.toggleModal();
		resetFormState();
	};

	const modalProps = {
		title: title,
		showModal: showModal,
		cancel: onModalCancel,
		save: onSubmit,
		saveButtonText: localizationService.getLocalizedString('screen.buttons.next'),
		saveButtonClassName: 'btn ai-action'
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		if (name === nameof<Extractor>(d => d.facilityId)) {
			(newState as any)[name] = value && Number(value);
			newState.selectedExtractorId = 0;
		}

		_.set(newState, name, value);

		setFormState(newState);
	};

	function getDeviceItem(device: Extractor) {
		let label = getDeviceInfo(device);

		if (label.indexOf('-') === 0) {
			label = label.substring(1);
		}

		return {
			label,
			value: device.extractorId,
			isHidden: !device.isActive
		} as DropDownOption;
	}

	function getDeviceInfo(device: Extractor) {
		const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
		let description = (_.get(device, 'extractorDescription') || '').substring(0, 40);
		return `${description} | ${device.location ? device.location + ' |' : ' |'} ${device.deviceNumber} ${
			device.isActive ? '' : `(${inactiveState})`
		}`.trim();
	}

	const facilityListItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const item = itemProps.dataItem as DropDownOption;

		const itemChildren = <div className="border-bottom w-100">{renderFacilityInfo(item)}</div>;

		return React.cloneElement(li, li.props, itemChildren);
	};

	const renderFacilityInfo = (facility: DropDownOption, strong = true) => {
		return (
			<>
				{strong ? <strong> {facility.label}</strong> : facility.label} <br />
				{facility.value} <br />
				{facility.addressLine1 && (
					<>
						{facility.addressLine1} <br />
					</>
				)}
				{[
					facility.cityName ? facility.cityName + ',' : facility.cityName,
					facility.jurisdictionCode,
					facility.zipCode
				]
					.filter(Boolean)
					.join('\u0020')}
			</>
		);
	};

	const customFilter = (options: DropDownOption[], filter: FilterDescriptor): DropDownOption[] => {
		let newOptions = _.cloneDeep(options);
		newOptions = newOptions.filter((option: DropDownOption) => {
			return (
				(option.addressLine1 && _.toLower(option.addressLine1).includes(_.toLower(filter.value.trim()))) ||
				_.toLower(option.facilityNumber).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.label).includes(_.toLower(filter.value.trim()))
			);
		});
		return newOptions;
	};

	return (
		<div className="w-100">
			<PopoverModal {...modalProps}>
				<ComboBoxDropdown
					options={facilityOptionValues}
					id="facility"
					name="selectedFacilityId"
					itemRender={facilityListItemRender}
					className="col-sm-12 p-0 "
					onChange={changeFormState}
					clearButton={false}
					value={_.toString(formState.selectedFacilityId ? formState.selectedFacilityId : '')}
					error={formState.selectedFacilityIdError}
					label={localizationService.getLocalizedString('facility.facility')}
					customFilter={customFilter}
					isRequired
					showSearchIcon
				/>
				{formState.selectedFacilityId && (
					<SingleSelectDropdown
						id="deviceId"
						name="selectedExtractorId"
						label={localizationService.getLocalizedString('pumpOut.device')}
						value={_.toString(formState.selectedExtractorId ? formState.selectedExtractorId : '')}
						onChange={changeFormState}
						options={extractorOptionValues}
						isRequired={true}
						error={formState.selectedExtractorIdError}
						hintText={localizationService.getLocalizedString('pumpOut.deviceDropDown')}
					/>
				)}
				{formState.selectedExtractorId && formState.selectedFacilityId && showCleaningModal && (
					<CleaningModal
						facilityId={formState.selectedFacilityId || 0}
						device={extractors.find(
							(device: Extractor) => device.extractorId == formState.selectedExtractorId
						)}
						showModal={showCleaningModal}
						isEnterCleaning={true}
						isCleaningGrid={true}
						cleaning={{} as CleaningEvent}
						isGridPage={true}
						isFacilityPage={false}
						dueDateIsRequired={true}
						completeDateIsRequired={false}
						modalToggleFunction={toggleCleaningModal}
						afterApiCallback={() => {
							props.toggleModal();
							resetFormState();
						}}
						title={localizationService.getLocalizedString('pumpOut.addCleaning')}
						queryParameters={queryParameters || {}}
						isAddModal={true}
					/>
				)}
			</PopoverModal>
		</div>
	);
};
