import _ from 'lodash';
import React from 'react';
import {
	apiService,
	authoritySettingService,
	localizationService,
	optionsMap,
	Resource,
	urlService
} from 'src/services';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertService, GetFilters, RootState, useReduxDispatch, useRootStateSelector } from 'src/redux';
import { useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { nameof } from 'ts-simple-nameof';
import {
	FilterObjectName,
	FogFacility,
	TreeViewDataItem,
	FilterOperator,
	FilterType,
	InspectionEvent,
	ApiFilterProperty,
	ApiFilterDefinition,
	DraftFilter,
	Dictionary,
	Filter,
	LocalStorageName
} from '@rcp/types';
import { FilterItem } from 'src/services/data-types/filter-types';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { MappingFromApiFormat, MappingToApiFormat } from 'src/redux/filter/filterSerializer';
import uuid from 'uuid';
import { FloatList } from './float-list';
import { getInitialFilterItem } from 'src/features/filter/filter-row';

import { FilterItemCard } from '../filter-item-card';
import { SaveQuickFilterModal } from '../modals/save-quick-filter';
import {
	applySavedFilter,
	areFiltersSame,
	digestQuickFilterAndCreateOrUpdateDraft,
	FacilityQuickFilterEnum,
	FilterItemsToList,
	getApiFilterDefinition,
	getInitialQuickFilterProperties,
	getSelectedValuesFromFilterProperty,
	inspectionTimeframeQuickFilterGroup,
	QuickFilterProperties,
	SavedFilter
} from 'src/features/filter/quick-filter-service';
import { QuickFilterDropdown } from 'src/features/filter/quick-filter-dropdown';
import { SearchInput } from 'src/components';
import { AdHocFilterItemCard } from '../ad-hoc-filter-item-card';

interface Props {
	handleClose: () => void;
	onApplyFilter: (savedFilterId: number | undefined, savedDraftFilterId: number | undefined) => void;
	onClearFilter: () => void;
	onFilterSaved: (filterId: number) => void;
	draftFilterId?: number;
	filterId?: number;
	facilityRiskMapFooterOverlay: JSX.Element;
	queryParameters: Dictionary<string>;
	setQueryParameters: (queryParameters: Dictionary<string>) => void;
	handleAdHocFilterDeletion: (adHocFilterKey: string) => void;
}

const initialQuickFilterSelectedIds: Dictionary<any[]> = {
	[FacilityQuickFilterEnum.Risk]: [],
	[FacilityQuickFilterEnum.Compliance]: [],
	[FacilityQuickFilterEnum.Devices]: [],
	[FacilityQuickFilterEnum.InspectionTimeFrame]: [],
	[FacilityQuickFilterEnum.Inspector]: [],
	[FacilityQuickFilterEnum.FacilityActive]: []
};

export const QuickFilterFacilitiesSettings: React.FC<Props> = props => {
	const dispatch = useReduxDispatch();
	let { result: customFields } = useRootStateSelector(s => s.customFields);
	let { result: authorityUsers } = useSelector((state: RootState) => state.authorityUsers);
	const [quickFilterProperties, setQuickFilterProperties] = React.useState<QuickFilterProperties>(
		getInitialQuickFilterProperties('Facility')
	);
	const [quickFilterSelectedIds, setQuickFilterSelectedIds] = React.useState<Dictionary<any[]>>(
		initialQuickFilterSelectedIds
	);
	const [showSaveFilterModal, setShowSaveFilterModal] = React.useState(false);
	const toggleSaveFilterModal = () => {
		setShowSaveFilterModal(!showSaveFilterModal);
	};
	const [filterItems, setFilterItems] = React.useState<FilterItemsToList[]>([]);
	const [savedFilters, setSavedFilters] = React.useState([] as SavedFilter[]);
	const [searchTermForSavedFilters, setSearchTermForSavedFilters] = React.useState('');

	const [hasChange, setHasChange] = React.useState(false);

	const [adHocFacilityQueryParameters, setAdHocFacilityQueryParameters] = React.useState<Dictionary<string>>({});

	var allDomainObjects = filterService.getAllDomainObject();

	React.useEffect(() => {
		authoritySettingService.getAuthorityComplianceStatusInUse().then(complianceStatusValues => {
			let newComplianceFilterOptions = [...complianceFilterOptions];
			for (let i = 0; i < complianceStatusValues.length; i++) {
				newComplianceFilterOptions[0].items?.push({
					text: complianceStatusValues[i],
					value: complianceStatusValues[i]
				});
			}
			newComplianceFilterOptions[0].items = _.uniqWith(newComplianceFilterOptions[0].items, _.isEqual);

			setComplianceFilterOptions(newComplianceFilterOptions);
		});
		//reset filter service
		let appliedDomains: { [index: string]: boolean } = {
			FACILITY: true,
			INSPECTION: true,
			EXTRACTOR: true,
			VIOLATION: true,
			CLEANING: true,
			EVENT: true
		};
		filterService.init(
			FilterDomain.FACILITY,
			FilterDomainName.facility,
			FilterObjectName.Facility,
			LocalStorageName.FacilityMap,
			appliedDomains
		);
	}, []);

	React.useEffect(() => {
		dispatch(GetFilters(FilterObjectName.Facility)).then(filters => {
			if (_.isArray(filters)) {
				let newSavedFilters = [] as SavedFilter[];

				filters.forEach(filter => {
					if (filter.filterId) {
						newSavedFilters.push({
							filterId: filter.filterId,
							filterName: filter.filterName
						});
					}
				});

				setSavedFilters(newSavedFilters);
			}
		});
	}, [dispatch]);

	React.useEffect(() => {
		if (props.queryParameters) {
			let queryParameterKeys = _.keys(props.queryParameters);
			let adhocFacilityQueryParameterKeys = queryParameterKeys.filter(i => {
				var key = _.toLower(i);
				return (
					!key.includes('incident.') &&
					!key.includes('search') &&
					!key.includes('filterid') &&
					!key.includes('draftfilterid')
				);
			});
			let queryParams = urlService.pickParameters(
				{ ...props.queryParameters },
				...adhocFacilityQueryParameterKeys
			);
			setAdHocFacilityQueryParameters(queryParams);
		}
	}, [props.queryParameters]);

	React.useEffect(() => {
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);

			let newInspectorFilterOptions = [...inspectorFilterOptions];
			for (let i = 0; i < options.length; i++) {
				newInspectorFilterOptions[0].items?.push({
					text: options[i].label,
					value: options[i].label
				});
			}
			newInspectorFilterOptions[0].items = _.uniqWith(newInspectorFilterOptions[0].items, _.isEqual);

			setInspectorFilterOptions(newInspectorFilterOptions);
		}
	}, [authorityUsers]);

	React.useEffect(() => {
		//pass custom fields to filter
		filterService.updateCustomFields(customFields);
	}, [customFields]);

	React.useEffect(() => {
		initializeQuickFilters();
	}, [props.draftFilterId, props.filterId]);

	const initializeQuickFilters = async () => {
		let newQuickFilterProperties = _.cloneDeep(quickFilterProperties);
		newQuickFilterProperties.draftFilterId = props.draftFilterId;
		let apiFilterDefinition: ApiFilterDefinition = await getApiFilterDefinition(
			newQuickFilterProperties,
			props.filterId,
			props.draftFilterId
		);
		newQuickFilterProperties.apiFilterDefinition = _.cloneDeep(apiFilterDefinition);

		populateQuickFilterSelectedIdsFromExistingFilter(newQuickFilterProperties);
	};

	const riskQuickFilterOptions: TreeViewDataItem[] = [
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('facility.riskScoreLegend.highRisk'),
					value: 'High'
				},
				{
					text: localizationService.getLocalizedString('facility.riskScoreLegend.mediumRisk'),
					value: 'Medium'
				},
				{
					text: localizationService.getLocalizedString('facility.riskScoreLegend.lowRisk'),
					value: 'Low'
				},
				{
					text: localizationService.getLocalizedString('facility.riskScoreLegend.noRiskSet'),
					value: '[NULL]'
				}
			],
			filterProperties: [
				{
					objectName: FilterObjectName.Facility,
					propertyName: nameof<FogFacility>(s => s.riskScore),
					propertyType: FilterType.Text,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	];

	const [complianceFilterOptions, setComplianceFilterOptions] = React.useState<TreeViewDataItem[]>([
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('facility.COMPLIANT'),
					value: 'Compliant'
				},
				{
					text: localizationService.getLocalizedString('facility.nonCompliant'),
					value: 'Non Compliant'
				}
			],
			filterProperties: [
				{
					objectName: FilterObjectName.Facility,
					propertyName: nameof<FogFacility>(s => s.complianceStatus),
					propertyType: FilterType.Text,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	]);

	const deviceQuickFilterOptions: TreeViewDataItem[] = [
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('map.noDevice'),
					value: 'noDevice',
					filterProperties: [
						{
							objectName: FilterObjectName.Facility,
							propertyName: nameof<FogFacility>(s => s.activeDeviceCount),
							propertyType: FilterType.Integer,
							operator: FilterOperator.Eq,
							values: ['0']
						}
					]
				},
				{
					text: localizationService.getLocalizedString('map.hasDevice'),
					value: 'hasDevice',
					filterProperties: [
						{
							objectName: FilterObjectName.Facility,
							propertyName: nameof<FogFacility>(s => s.activeDeviceCount),
							propertyType: FilterType.Integer,
							operator: FilterOperator.GreaterThan,
							values: ['0']
						}
					]
				}
			],
			useThisFilterPropertiesIfAllItemsSelected: true,
			filterProperties: [
				{
					objectName: FilterObjectName.Facility,
					propertyName: nameof<FogFacility>(s => s.activeDeviceCount),
					propertyType: FilterType.Integer,
					operator: FilterOperator.GreaterThanEqual,
					values: ['0']
				}
			]
		}
	];

	const [inspectorFilterOptions, setInspectorFilterOptions] = React.useState<TreeViewDataItem[]>([
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('map.noInspectorAssigned'),
					value: '[NULL]',
					isTextBold: true
				},
				{
					text: localizationService.getLocalizedString('screen.filterModal.signedInUser'),
					value: `[${localizationService.getLocalizedString('screen.filterModal.signedInUser')}]`,
					isTextItalics: true,
					isTextBold: true
				}
			],
			filterProperties: [
				{
					objectName: FilterObjectName.Inspection,
					propertyName: nameof<InspectionEvent>(s => s.inspectorName),
					propertyType: FilterType.Text,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	]);

	const facilityActiveQuickFilterOptions: TreeViewDataItem[] = [
		{
			text: '',
			expanded: true,
			items: [
				{
					text: localizationService.getLocalizedString('map.active'),
					value: 'true'
				},
				{
					text: localizationService.getLocalizedString('map.notActive'),
					value: 'false'
				}
			],
			filterProperties: [
				{
					objectName: FilterObjectName.Facility,
					propertyName: nameof<FogFacility>(s => s.isEnabled),
					propertyType: FilterType.Boolean,
					operator: FilterOperator.Is,
					values: []
				}
			]
		}
	];

	const onChangeQuickFilter = async (
		checkedFilterProperties: ApiFilterProperty[],
		uncheckedFilterProperties?: ApiFilterProperty[],
		filterNumber?: FacilityQuickFilterEnum,
		selectedIds?: any[]
	) => {
		let newQuickFilterProperties = _.cloneDeep(quickFilterProperties);
		let matchValuesToFindIndexOfFilterProperty = false;

		await digestQuickFilterAndCreateOrUpdateDraft(
			newQuickFilterProperties,
			populateQuickFilterSelectedIdsFromExistingFilter,
			checkedFilterProperties,
			uncheckedFilterProperties,
			matchValuesToFindIndexOfFilterProperty,
			filterNumber
		);

		setHasChange(true);

		let newQuickFilterSelectedIds = _.cloneDeep(quickFilterSelectedIds);
		if (filterNumber && selectedIds) {
			newQuickFilterSelectedIds[filterNumber] = selectedIds;
			setQuickFilterSelectedIds(newQuickFilterSelectedIds);
		}
	};

	const onClearFilter = () => {
		let newQuickFilterProperties = { ...getInitialQuickFilterProperties('Facility') };
		setQuickFilterProperties(newQuickFilterProperties);
		setQuickFilterSelectedIds(initialQuickFilterSelectedIds);
		setFilterItems([]);
		setHasChange(false);
		props.onClearFilter();
	};

	const populateQuickFilterSelectedIdsFromExistingFilter = (newQuickFilterProperties: QuickFilterProperties) => {
		if (newQuickFilterProperties.draftFilterId || props.filterId) {
			let newQuickFilterSelectedIds = _.cloneDeep(quickFilterSelectedIds);

			let hasFilterGroup =
				newQuickFilterProperties.apiFilterDefinition &&
				newQuickFilterProperties.apiFilterDefinition.filterGroups &&
				newQuickFilterProperties.apiFilterDefinition.filterGroups.length > 0;

			if (hasFilterGroup) {
				_.uniqWith(newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties, _.isEqual);

				//riskQuickFilterOptions
				if (
					riskQuickFilterOptions[0] &&
					riskQuickFilterOptions[0].filterProperties &&
					riskQuickFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						riskQuickFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == riskQuickFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}

					newQuickFilterSelectedIds[FacilityQuickFilterEnum.Risk] = selectedValue;
				}

				//complianceFilterOptions
				if (
					complianceFilterOptions[0] &&
					complianceFilterOptions[0].filterProperties &&
					complianceFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						complianceFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == complianceFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}

					newQuickFilterSelectedIds[FacilityQuickFilterEnum.Compliance] = selectedValue;
				}

				//deviceQuickFilterOptions
				newQuickFilterSelectedIds[FacilityQuickFilterEnum.Devices] = []; // this is needed to reset the selections

				if (
					deviceQuickFilterOptions[0] &&
					deviceQuickFilterOptions[0].filterProperties &&
					deviceQuickFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						deviceQuickFilterOptions[0].filterProperties[0]
					);
					if (_.isEqual(deviceQuickFilterOptions[0].filterProperties[0].values, selectedValue)) {
						newQuickFilterSelectedIds[FacilityQuickFilterEnum.Devices] = ['noDevice', 'hasDevice'];
					}
				}

				deviceQuickFilterOptions[0].items?.forEach(item => {
					if (item.filterProperties && item.filterProperties.length > 0) {
						let selectedValue = getSelectedValuesFromFilterProperty(
							newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
							item.filterProperties[0]
						);
						if (_.isEqual(item.filterProperties[0].values, selectedValue)) {
							newQuickFilterSelectedIds[FacilityQuickFilterEnum.Devices] = [item.value];
						}
					}
				});

				//inspectorFilterOptions
				if (
					inspectorFilterOptions[0] &&
					inspectorFilterOptions[0].filterProperties &&
					inspectorFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						inspectorFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == inspectorFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}
					newQuickFilterSelectedIds[FacilityQuickFilterEnum.Inspector] = selectedValue;
				}

				//facilityActiveQuickFilterOptions
				if (
					facilityActiveQuickFilterOptions[0] &&
					facilityActiveQuickFilterOptions[0].filterProperties &&
					facilityActiveQuickFilterOptions[0].filterProperties.length > 0
				) {
					let selectedValue = getSelectedValuesFromFilterProperty(
						newQuickFilterProperties.apiFilterDefinition.filterGroups[0].filterProperties,
						facilityActiveQuickFilterOptions[0].filterProperties[0]
					);

					if (selectedValue.length == facilityActiveQuickFilterOptions[0].items?.length) {
						selectedValue = [...selectedValue, undefined];
					}

					newQuickFilterSelectedIds[FacilityQuickFilterEnum.FacilityActive] = selectedValue;
				}

				//inspectionTimeframeQuickFilterGroup
				newQuickFilterSelectedIds[
					FacilityQuickFilterEnum.InspectionTimeFrame
				] = inspectionTimeframeQuickFilterGroup.getSelectedIds(newQuickFilterProperties.apiFilterDefinition);

				setQuickFilterSelectedIds(newQuickFilterSelectedIds);
				let newFilterItemsToList = _.cloneDeep(newQuickFilterProperties.apiFilterDefinition);
				let filterItemsString = MappingFromApiFormat(JSON.stringify(newFilterItemsToList));
				let filterItems = JSON.parse(filterItemsString as string);
				setFilterItems(
					filterItems.map((item: FilterItem) => {
						return { filterItem: item, isEdit: false };
					})
				);
			}
		} else {
			setQuickFilterSelectedIds(initialQuickFilterSelectedIds);
		}
		setQuickFilterProperties(newQuickFilterProperties);
	};

	const onSaveButtonClicked = async (filterName?: string) => {
		const saveDraftFilterUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Filters}/${
			Resource.SaveDraftQuickFilterAsFilter
		}`;
		let draftFilterToSave: DraftFilter = {
			draftFilterId: quickFilterProperties.draftFilterId,
			applyTo: quickFilterProperties.filterApplyTo,
			filterName: filterName as string,
			filterDefinition: JSON.stringify(quickFilterProperties.apiFilterDefinition)
		};
		await apiService
			.httpPost(saveDraftFilterUrl, draftFilterToSave)
			.then((savedFilter: Filter) => {
				props.onFilterSaved(savedFilter.filterId as number);
				props.handleClose();
				alertService.addSuccess(
					localizationService.getLocalizedString('alertMessages.savedSuccess', savedFilter.filterName)
				);
				setHasChange(false);
			})
			.catch(e => alertService.addError(e.message));
	};

	const quickFilterList = [
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.Risk}
			label={localizationService.getLocalizedString('map.risk')}
			quickFilterOptions={riskQuickFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.Risk]}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.Compliance}
			label={localizationService.getLocalizedString('map.compliance')}
			quickFilterOptions={complianceFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.Compliance]}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.Devices}
			label={localizationService.getLocalizedString('map.devices')}
			quickFilterOptions={deviceQuickFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.Devices]}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.InspectionTimeFrame}
			label={localizationService.getLocalizedString('map.inspectionTimeframe')}
			quickFilterOptions={inspectionTimeframeQuickFilterGroup.getPopulatedFilterOptionsWithValueSelection(
				quickFilterProperties.apiFilterDefinition
			)}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.InspectionTimeFrame]}
			isSingleSelectionMode={true}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.Inspector}
			label={localizationService.getLocalizedString('map.inspector')}
			quickFilterOptions={inspectorFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.Inspector]}
		/>,
		<QuickFilterDropdown
			key={`FilterQuickFilter_${uuid()}`}
			filterNumber={FacilityQuickFilterEnum.FacilityActive}
			label={localizationService.getLocalizedString('map.active')}
			quickFilterOptions={facilityActiveQuickFilterOptions}
			onChangeQuickFilter={onChangeQuickFilter}
			selectedIds={quickFilterSelectedIds[FacilityQuickFilterEnum.FacilityActive]}
		/>
	];

	const addFilterItemClicked = () => {
		let existingFilterItems = _.cloneDeep(filterItems);
		let newFilterItem: FilterItemsToList = { filterItem: getInitialFilterItem(), isEdit: true };
		existingFilterItems.unshift(newFilterItem);
		setFilterItems(existingFilterItems);
	};

	const handleFilterItemApply = async (originalFilterItem: FilterItem, updatedFilterItem: FilterItem) => {
		let originalFilterItemFilterProperties = MappingToApiFormat([originalFilterItem]).filterGroups[0]
			.filterProperties;
		let updatedFilterItemFilterProperties = MappingToApiFormat([updatedFilterItem]).filterGroups[0]
			.filterProperties;
		let filterPropertiesToUpdate = _.cloneDeep(updatedFilterItemFilterProperties);
		let filterPropertiesToDelete = [];
		if (
			originalFilterItemFilterProperties[0].objectName &&
			originalFilterItemFilterProperties[0].propertyName &&
			originalFilterItemFilterProperties[0].operator
		) {
			if (!areFiltersSame(originalFilterItemFilterProperties[0], updatedFilterItemFilterProperties[0])) {
				filterPropertiesToDelete.push(originalFilterItemFilterProperties[0]);
			}
		}

		await onChangeQuickFilter(filterPropertiesToUpdate, filterPropertiesToDelete);
	};

	const handleFilterItemDelete = async (filterItem: FilterItem) => {
		var apiProperties = MappingToApiFormat([filterItem]);
		await onChangeQuickFilter([], apiProperties.filterGroups[0].filterProperties);
	};

	const applySavedFilterSelection = async (filterId: number, filterName: string) => {
		setHasChange(true);
		await applySavedFilter(
			quickFilterProperties,
			populateQuickFilterSelectedIdsFromExistingFilter,
			filterId,
			filterName
		);
	};

	const handleApplyChangesOnMap = () => {
		setHasChange(false);
		props.onApplyFilter(undefined, quickFilterProperties.draftFilterId);
	};

	const handleClose = () => {
		if (hasChange) {
			handleApplyChangesOnMap();
		}
		props.handleClose();
	};

	const getChildren = () => {
		return (
			<>
				<div className="my-3 col-auto pl-0">
					<UncontrolledDropdown className="action-item-black">
						<DropdownToggle tag="a" className="a">
							<FontAwesomeIcon icon={faPlus} className="font-size-16px-regular" />
							<span id="addSavedFilters">
								{localizationService.getLocalizedString('map.addSavedFilter')}
							</span>
						</DropdownToggle>
						<DropdownMenu style={{ maxHeight: '300px', overflowY: 'auto' }}>
							<div className="col">
								<SearchInput
									ariaLabel="Search"
									searchClass="d-flex w-100"
									search={searchTerm => setSearchTermForSavedFilters(searchTerm)}
									onChange={e => setSearchTermForSavedFilters(e.target.value)}
									searchTerm={searchTermForSavedFilters}
								/>
							</div>
							{_.sortBy(savedFilters, f => f.filterName)
								.filter(x => _.includes(_.toLower(x.filterName), _.toLower(searchTermForSavedFilters)))
								.map(filter => (
									<DropdownItem
										style={{ whiteSpace: 'pre-wrap' }}
										key={uuid()}
										className=""
										id={`savedFilterId_${filter.filterId}`}
										name={`savedFilterId_${filter.filterId}`}
										onClick={() => applySavedFilterSelection(filter.filterId, filter.filterName)}>
										{filter.filterName}
									</DropdownItem>
								))}
						</DropdownMenu>
					</UncontrolledDropdown>
				</div>
				<div className="d-flex flex-row flex-wrap">
					{quickFilterList.map(x => {
						return x;
					})}
					<div className="my-2 col-auto pl-0">
						<button
							className="btn ai-white"
							id="addFilterItem"
							onClick={() => {
								addFilterItemClicked();
							}}>
							{localizationService.getLocalizedString('map.addFilterItem')}
						</button>
					</div>
				</div>
				{_.keys(adHocFacilityQueryParameters).length > 0 &&
					_.toPairs(adHocFacilityQueryParameters).map(pair => {
						return (
							<AdHocFilterItemCard
								adHocFilterKey={pair[0]}
								adHocFilterValue={pair[1]}
								handleAdHocFilterItemDelete={props.handleAdHocFilterDeletion}
							/>
						);
					})}
				{filterItems.map(item => {
					let domainObject = allDomainObjects.find(i =>
						String.equalCI(i.name, item.filterItem.domainObject.name)
					);
					if (domainObject) {
						item.filterItem.domainObject = domainObject;
					} else {
						throw new Error(`Cannot find domainObject which name is ${item.filterItem.domainObject.name}.`);
					}
					return (
						<FilterItemCard
							key={uuid()}
							filterItemToList={item}
							handleFilterItemDelete={handleFilterItemDelete}
							handleFilterItemApply={handleFilterItemApply}
						/>
					);
				})}
			</>
		);
	};

	const getFooter = (): JSX.Element => {
		return (quickFilterProperties.draftFilterId || props.filterId || 0) > 0 ? (
			<div className="d-flex map-float-right-wrapper-footer">
				<div className="mx-auto justify-content-center">
					{hasChange && (
						<Button color="btn ai-action mr-2" onClick={() => handleApplyChangesOnMap()}>
							{localizationService.getLocalizedString('screen.buttons.apply')}
						</Button>
					)}
					{quickFilterProperties.draftFilterId && (
						<Button color="btn ai-save mr-2" onClick={() => setShowSaveFilterModal(true)}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</Button>
					)}
					<Button color="btn ai-white" onClick={onClearFilter}>
						{localizationService.getLocalizedString('screen.buttons.clearAll')}
					</Button>
				</div>
			</div>
		) : (
			<></>
		);
	};

	return (
		<>
			<FloatList
				children={getChildren()}
				title={localizationService.getLocalizedString('map.facilitiesFilters')}
				handleClose={handleClose}
				footer={getFooter()}
				showMobileTitle={true}
				facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
			/>
			{showSaveFilterModal && (
				<SaveQuickFilterModal
					draftFilterName={quickFilterProperties.filterName}
					showModal={showSaveFilterModal}
					onCancel={toggleSaveFilterModal}
					onSave={onSaveButtonClicked}
				/>
			)}
		</>
	);
};
