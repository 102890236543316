import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	saveFogFacility,
	loadAuthorityTags,
	alertService,
	useReduxDispatch,
	useReduxSelector,
	loadAuthorityMapCategories,
	loadAuthorityReceivingPlants
} from 'src/redux';
import { FogFacility, DropDownOption } from '@rcp/types';
import { PopoverModal, TextInput, SingleSelectDropdown } from 'src/components/widgets';
import { urlService, validationService, localizationService, optionsMap } from 'src/services';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
}

interface StateProps extends FogFacilityState {}

interface DispatchProps {
	saveFogFacilityLocation: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) => void;
	loadAuthorityTags: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface LocationForm {
	mapCategoryId?: number;
	latitude: string;
	longitude: string;
	receivingPlantId?: number;
	receivingPlant?: string;
	trunkLine: string;
	elevation: string;
	gisNumber: string;
	mapCategory?: string;
	latitudeError?: string;
	longitudeError?: string;
	elevationError?: string;
	mapCategoryIdError?: string;
	receivingPlantIdError?: string;
}

const initialLocationForm: LocationForm = {
	latitude: '',
	longitude: '',
	trunkLine: '',
	elevation: '',
	gisNumber: '',
	mapCategory: '',
	receivingPlant: ''
};

const LocationModalComp: React.SFC<Props> = props => {
	const [mapCategoryOptions, setMapCategoryOptions] = React.useState([] as DropDownOption[]);
	const [receivingPlantOptions, setReceivingPlantOptions] = React.useState([] as DropDownOption[]);
	const [formState, setFormState] = React.useState(initialLocationForm);

	const mapCategories = useReduxSelector(state => state.authoritySettingLookups.mapCategories);
	const receivingPlants = useReduxSelector(state => state.authoritySettingLookups.receivingPlants);

	const dispatch = useReduxDispatch();
	React.useEffect(() => {
		dispatch(
			loadAuthorityMapCategories(
				urlService.toQueryString({
					includeInactive: 'true'
				})
			)
		);
		dispatch(
			loadAuthorityReceivingPlants(
				urlService.toQueryString({
					includeInactive: 'true'
				})
			)
		);
	}, [dispatch]);

	const deletedState: string = localizationService.getLocalizedString('screen.labels.deleted');
	const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');

	React.useEffect(() => {
		const { state, activeDropDownList } = optionsMap.resolveDropdownOptionsWithInactiveOrDeletedId(
			mapCategories,
			formState.mapCategoryId as number
		);
		let options: DropDownOption[] = optionsMap.fromLookups(
			activeDropDownList,
			formState.mapCategoryId,
			formState.mapCategory
		);
		setMapCategoryOptions(options);
	}, [mapCategories]);

	React.useEffect(() => {
		const { state, activeDropDownList } = optionsMap.resolveDropdownOptionsWithInactiveOrDeletedId(
			receivingPlants,
			formState.receivingPlantId as number
		);
		let options: DropDownOption[] = optionsMap.fromLookups(
			activeDropDownList || [],
			formState.receivingPlantId,
			formState.receivingPlant
		);
		setReceivingPlantOptions(options);
	}, [receivingPlants]);

	React.useEffect(() => {
		const setFormStateFromProps = () => {
			let newState: LocationForm = {
				mapCategoryId: props.facility.mapCategoryId,
				latitude: props.facility.latitude || '',
				longitude: props.facility.longitude || '',
				receivingPlantId: props.facility.receivingPlantId,
				trunkLine: props.facility.trunkLine || '',
				elevation: props.facility.elevation || '',
				gisNumber: props.facility.gisNumber || '',
				mapCategory: props.facility.mapCategory || '',
				receivingPlant: props.facility.receivingPlant || ''
			};
			setFormState(newState);
		};
		setFormStateFromProps();
	}, [props.facility]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const validateLocationDataForSave = (): boolean => {
		let newState = { ...formState };
		let isFormValid = false;
		validationService.validateLatitudeField(newState, 'latitude', 'latitudeError', 'facility.latitude', false);
		validationService.validateLongitudeField(newState, 'longitude', 'longitudeError', 'facility.longitude', false);
		validationService.validateIntegerNumberField(
			newState,
			'elevation',
			'elevationError',
			false,
			undefined,
			undefined,
			'facility.elevation'
		);

		isFormValid = !validationService.hasError(newState, 'latitudeError', 'longitudeError', 'elevationError');
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const saveLocation = () => {
		if (!validateLocationDataForSave()) {
			return;
		}

		let facilityToUpdate: FogFacility = {
			mapCategoryId: formState.mapCategoryId,
			latitude: formState.latitude,
			longitude: formState.longitude,
			receivingPlantId: formState.receivingPlantId,
			trunkLine: formState.trunkLine,
			elevation: formState.elevation,
			gisNumber: formState.gisNumber
		};

		props.saveFogFacilityLocation(urlService.getFogFacilityId(), facilityToUpdate, () => {
			props.loadAuthorityTags();
		});
		props.toggle();
	};

	const cancelSaveLocation = () => {
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString('facility.editLocation')}
				save={saveLocation}
				cancel={cancelSaveLocation}>
				<SingleSelectDropdown
					id="locationMapCategory"
					name="mapCategoryId"
					label={localizationService.getLocalizedString('facility.mapCategory')}
					value={_.toString(formState.mapCategoryId)}
					onChange={changeFormState}
					options={mapCategoryOptions}
					error={formState.mapCategoryIdError}
				/>
				<div className="form-row">
					<TextInput
						id="locationLatitude"
						name="latitude"
						type="number"
						label={localizationService.getLocalizedString('facility.latitude')}
						onChange={changeFormState}
						value={formState.latitude}
						error={formState.latitudeError}
						className="form-group col-sm-6"
						remainingInputProps={{ min: -90, max: 90 }}
					/>

					<TextInput
						id="locationLongitude"
						name="longitude"
						type="number"
						label={localizationService.getLocalizedString('facility.longitude')}
						onChange={changeFormState}
						value={formState.longitude}
						error={formState.longitudeError}
						className="form-group col-sm-6"
						remainingInputProps={{ min: -180, max: 180 }}
					/>
				</div>
				<SingleSelectDropdown
					id="locationReceivingPlant"
					name="receivingPlantId"
					label={localizationService.getLocalizedString('facility.receivingPlant')}
					value={_.toString(formState.receivingPlantId)}
					onChange={changeFormState}
					options={receivingPlantOptions}
					error={formState.receivingPlantIdError}
				/>
				<TextInput
					id="locationTrunkLine"
					name="trunkLine"
					label={localizationService.getLocalizedString('facility.trunkLine')}
					onChange={changeFormState}
					value={formState.trunkLine}
				/>
				<TextInput
					id="locationSiteElevation"
					name="elevation"
					label={localizationService.getLocalizedString('facility.elevation')}
					onChange={changeFormState}
					value={formState.elevation}
					error={formState.elevationError}
				/>
				<TextInput
					id="locationGisNumber"
					name="gisNumber"
					label={localizationService.getLocalizedString('facility.gisNumber')}
					onChange={changeFormState}
					value={formState.gisNumber}
				/>
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacilityLocation: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate, callbackOnSuccess)),
		loadAuthorityTags: () => dispatch(loadAuthorityTags())
	};
};

export const LocationModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(LocationModalComp);
