import React, { FC } from 'react';
import { ColumnField, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { GridOption } from 'src/features/data-grid/data-grid';
import { localizationService, navigateTo, urlService } from 'src/services';
import IppReportPackageReviewsComponent from 'src/components/authority/ipp/report-packages/packages/reviews/reviews';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppRejectedReportPackage: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageRejectedGrid,
		searchPlaceholder: '',
		showActions: false,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Rejected'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.Rejected,
			page: initialPage,
			sort: 'rejectionDateTimeLocal asc'
		},
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		disableFilterWarning: true,
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('submitterUserName'),
			new ColumnField('acceptRejectReasons'),
			new ColumnField('rejectionDateTimeLocal', FilterType.Date),
			new ColumnField('rejectorFirstName'),
			new ColumnField('rejectorLastName'),
			new ColumnField('dueDateTimeLocal', FilterType.Date),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'dueDateTimeLocal',
			'rejectionDateTimeLocal',
			'rejectorFirstName',
			'submissionDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppRejectedReportPackage;
