import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, useReduxSelector } from 'src/redux';
import { siteSlice } from '../sites.slice';
import Comment from 'src/features/comments/comment';
import { FaEnvelope, FaPhone, FaCommentAlt } from 'react-icons/fa';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import _ from 'lodash';
import { History } from 'history';
import * as ApiTypes from '@rcp/types';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { NotFound } from 'src/features/home/not-found';
import { CccSite } from '@rcp/types';
import { CccSiteInfoTab } from './site-details-info-tab';
import { timelineEventsSlice } from 'src/features/timeline/timeline-events.slice';
import { commentsSlice } from 'src/features/comments/comments.slice';
import { Timeline } from 'src/features/timeline/timeline';
import { CccSiteDetailsMain } from './site-details-address';
import { SiteActionDropdown } from './site-action-dropdown-menu';
import { CloseSvg } from 'src/components/svg';
import { FeatureNames } from '@rcp/types';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

const CccSiteDetails: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [showInvalidUrlMessage, setShowInvalidUrlMessage] = React.useState(false);
	const [currentCccSite, setCurrentSite] = React.useState({} as CccSite);
	const [commentModal, setCommentModal] = React.useState(false as boolean);
	const [siteCommentAnchor, setSiteCommentAnchor] = React.useState(document.createElement('div'));
	const [deleteSiteModal, setDeleteSiteModal] = React.useState(false as boolean);

	const showInDevelopmentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowInDevelopmentFeature]
	);

	useEffect(() => {
		let siteId = urlService.getCccSiteIdOrThrowError();
		dispatch(siteSlice.fetchOne(siteId));
		timelineEventsSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/timelineEvents`
		);
		commentsSlice.setApiUrlPath(`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/comments`);
	}, [dispatch]);

	let sitesState = (state: RootState) => state.cccSites;
	let { selected, loading } = useSelector(sitesState);

	useEffect(() => {
		if (selected) {
			setCurrentSite(selected);
		} else {
			setShowInvalidUrlMessage(true);
		}
	}, [selected]);

	let onCommentDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setCommentModal(!commentModal);
		setSiteCommentAnchor(e.target as HTMLDivElement);
	};

	let toggleCommentModal = () => {
		setCommentModal(!commentModal);
	};

	let onDeleteSiteToggle = () => {
		setDeleteSiteModal(!deleteSiteModal);
	};

	let deleteSite = () => {
		let sitesUrl = urlService.getReactResourceUrl(Resource.CccSites);
		let history = props.history;
		let siteId = urlService.getCccSiteId();
		dispatch(
			siteSlice.deleteOne(
				siteId,
				true,
				localizationService.getLocalizedString('cccSite.deleteSuccessMessage'),
				() => {
					navigateTo(history, sitesUrl);
				}
			)
		);
	};

	return (
		<>
			{_.isEmpty(currentCccSite) && !loading ? (
				<>{showInvalidUrlMessage && <NotFound />}</>
			) : (
				<div className="page">
					<div className="page-header">
						<h1 id="addressSiteNumber">
							{localizationService.getLocalizedString('screen.labels.site')} {currentCccSite.siteNumber}
						</h1>
					</div>
					<div className="d-flex flex-row top-action-div">
						<div className="form-row w-100 action-panel align-items-center">
							{showInDevelopmentFeature && (
								<>
									<div
										className="action-item"
										id="sendEmailLink"
										onClick={() => console.log('click')}>
										<FaEnvelope className="ai-slate" />
										<span>{localizationService.getLocalizedString('screen.labels.sendEmail')}</span>
									</div>
									<div className="action-item" id="logCallLink" onClick={() => console.log('click')}>
										<FaPhone className="ai-slate" />
										<span>{localizationService.getLocalizedString('screen.labels.logACall')}</span>
									</div>
								</>
							)}
							<div className="action-item" id="commentDiv" onClick={onCommentDivClick}>
								<FaCommentAlt className="ai-slate" />
								<span>{localizationService.getLocalizedString('screen.labels.comment')}</span>
							</div>
							<SiteActionDropdown clickOnDeleteSite={onDeleteSiteToggle} />
						</div>
					</div>

					<div className="page-wrapper">
						<div className="main">
							<CccSiteDetailsMain currentCccSite={currentCccSite} />
							<CccSiteInfoTab currentCccSite={currentCccSite} />
							<Timeline />
						</div>

						<div className="sidebar">
							{showInDevelopmentFeature && (
								<>
									<section>
										<div id="primaryContacts" className="d-flex">
											<h3>
												{localizationService.getLocalizedString('screen.labels.primaryContact')}
											</h3>
										</div>
										<a href="#/" className="ai-add-link" onClick={() => console.log('click')}>
											{localizationService.getLocalizedString('screen.buttons.addContact')}…
										</a>
									</section>
									<section>
										<div id="lastTestedBy" className="d-flex">
											<h3>
												{localizationService.getLocalizedString('screen.labels.lastTestedBy')}
											</h3>
										</div>
									</section>

									<section>
										<div id="facilitiesTags" className="d-flex">
											<h3>{localizationService.getLocalizedString('screen.labels.tags')}</h3>
										</div>
										<div className={`ai-tags`}></div>
										<a href="#/" className="ai-add-link" onClick={() => console.log('click')}>
											{localizationService.getLocalizedString('screen.buttons.addTag')}&#8230;
										</a>
									</section>

									<section className="attachments">
										<div id="attachments" className="d-flex">
											<h3>{localizationService.getLocalizedString('attachments.title')}</h3>
										</div>
										<div className="attachment-list mx-n1"></div>
										<div className="d-flex">
											<a href="#/" className="ai-add-link" onClick={() => console.log('click')}>
												{localizationService.getLocalizedString('attachments.addNew')}&#8230;
											</a>
											<input
												type="file"
												id="addAttachmentInput"
												name="files"
												className="add-attachment-input"
												onChange={() => console.log('click')}
												multiple
											/>
										</div>
									</section>
								</>
							)}
						</div>

						{commentModal && (
							<Comment show={commentModal} anchor={siteCommentAnchor} toggle={toggleCommentModal} />
						)}

						{deleteSiteModal && (
							<Modal
								isOpen={true}
								toggle={onDeleteSiteToggle}
								centered={true}
								backdrop={true}
								size="lg"
								id="deleteSiteModal">
								<ModalHeader
									toggle={onDeleteSiteToggle}
									tag="h1"
									close={
										<button className="close" onClick={onDeleteSiteToggle}>
											<CloseSvg />
										</button>
									}>
									{localizationService.getLocalizedString('cccSite.deleteModalTitle')}
								</ModalHeader>
								<ModalBody>
									<div>
										<p>{localizationService.getLocalizedString('cccSite.deleteModalMessage')}</p>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button color="btn ai-delete ml-auto" id="deleteSiteButton" onClick={deleteSite}>
										{localizationService.getLocalizedString('screen.buttons.delete')}
									</Button>
									<Button color="btn ai-white" onClick={onDeleteSiteToggle}>
										{localizationService.getLocalizedString('screen.buttons.cancel')}
									</Button>
								</ModalFooter>
							</Modal>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default CccSiteDetails;
