import { History } from 'history';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { ReactComponent as SvgFacilities } from '../../../../assets/img/settings-facilities.svg';
import { ReactComponent as SvgUser } from '../../../../assets/img/settings-users.svg';
import { ReactComponent as SvgHaulers } from '../../../../assets/img/settings-haulers.svg';
import { localizationService, Resource, urlService } from 'src/services';
import { LandingPage } from './landing-page';
import { SettingCard } from './setting-card';
import { useReduxSelector } from 'src/redux';
import { FeatureNames } from '@rcp/types';

type Props = {
	history: History;
};

const FacilityPortalLandingPageComponent: React.FunctionComponent<Props> = props => {
	const authoritySettings = () => {
		let cards = [
			<SettingCard
				id="divSettingFacilityPortalFacilities"
				title={localizationService.getLocalizedString('facilityPortal.settings.facilities.facilities')}
				key={localizationService.getLocalizedString('facilityPortal.settings.facilities.facilities')}
				description={localizationService.getLocalizedString(
					'facilityPortal.settings.facilities.facilitiesDescription'
				)}
				link={urlService.getSettingMenuPath('/facilities')}
				history={props.history}
				iconSource={<SvgFacilities />}
			/>,
			<SettingCard
				id="divSettingHauler"
				title={localizationService.getLocalizedString('facilityPortal.settings.haulers.haulerCardTitle')}
				key={localizationService.getLocalizedString('facilityPortal.settings.haulers.haulerCardTitle')}
				description={localizationService.getLocalizedString(
					'facilityPortal.settings.haulers.haulerCardDescription'
				)}
				link={urlService.getSettingMenuPath('/hauler')}
				history={props.history}
				hidden={!urlService.isAdministrator()}
				iconSource={<SvgHaulers />}
			/>,
			<SettingCard
				id="divSettingFacilityUsers"
				title={localizationService.getLocalizedString('facilityPortal.settings.users.users')}
				key={localizationService.getLocalizedString('facilityPortal.settings.users.users')}
				description={localizationService.getLocalizedString('haulerPortal.settings.users.usersDescription')}
				link={urlService.getSettingMenuPath(Resource.Users)}
				history={props.history}
				iconSource={<SvgUser />}
			/>
		];

		cards = _.sortBy(cards, option => {
			return option.key;
		});
		return cards;
	};

	return <LandingPage history={props.history} authoritySettings={authoritySettings()} />;
};

export const FacilityPortalSettingsLandingPage = connect()(FacilityPortalLandingPageComponent);
