import React, { useState, useEffect, FC } from 'react';
import { DeleteModal, DeleteModalProp, PopoverModal } from 'src/components';
import { localizationService, localStorageService, tokenService, urlService } from 'src/services';
import { FacilityPortalFacilityDetail } from '@rcp/types';
import { facilitySettingsSlice } from './facilities-slice';
import { useDispatch } from 'react-redux';
import { Translate } from 'src/components/widgets/translate/translator';

interface OwnProps {
	linkText?: string;
	facilityDetails?: FacilityPortalFacilityDetail;
	isFacilityPortalBlocked?: boolean;
}

const initialFacilityInformation = {
	facilityName: '',
	facilityAddressLine1: '',
	facilityAddressLine2: '',
	facilityCityName: '',
	facilityJurisdictionCode: '',
	facilityZipCode: ''
};

export const FacilityDetailsActionLink: FC<OwnProps> = props => {
	const [showModal, setShowModal] = useState(false);
	const [facilityDetails, setFacilityDetails] = useState<any>(initialFacilityInformation);
	const [showDeleteFacilityModal, setShowDeleteFacilityModal] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setFacilityDetails(props.facilityDetails);
	}, [props.facilityDetails]);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const showFacilityDetails = () => {
		const {
			facilityName,
			facilityAddressLine1,
			facilityAddressLine2,
			facilityCityName,
			facilityJurisdictionCode,
			facilityZipCode
		} = facilityDetails;
		return (
			<p id="facility-address">
				{facilityName && <>{facilityName}</>}
				{facilityName && <br />}
				{facilityAddressLine1 && <>{facilityAddressLine1}</>}
				{facilityAddressLine1 && <br />}
				{facilityAddressLine2 && <>{facilityAddressLine2}</>}
				{facilityAddressLine2 && <br />}
				{facilityCityName && <>{facilityCityName + (facilityJurisdictionCode ? ', ' : '')}</>}
				{facilityJurisdictionCode && <>{facilityJurisdictionCode + ' '}</>}
				{facilityZipCode && <>{facilityZipCode}</>}
			</p>
		);
	};

	const footer = () => {
		return (
			<button
				hidden={!urlService.isAdministrator() || props.isFacilityPortalBlocked}
				className="btn ai-secondary-delete"
				onClick={event => {
					event.preventDefault();
					setShowDeleteFacilityModal(true);
				}}>
				<Translate>{localizationService.getLocalizedString('screen.buttons.delete')}</Translate>
			</button>
		);
	};

	const removeFacility = () => {
		const isLoggedInFacility =
			tokenService.getTokenOrDefault()!.portalOrganizationId + '' === props.facilityDetails!.facilityId + '';
		dispatch(
			facilitySettingsSlice.deleteOne(
				facilityDetails.facilityId,
				!isLoggedInFacility,
				localizationService.getLocalizedString('alertMessages.removeSuccess', facilityDetails.facilityName),
				() => {
					if (isLoggedInFacility) {
						tokenService.clearToken();
						localStorageService.removeLocalStorage('allowedPortals');
						localStorage.removeItem('hidePortalDirector');
						window.location.href = urlService.getLoginUrl(
							`signOut=true&facilityDeleted=${facilityDetails.facilityName}`
						);
					} else {
						setShowModal(false);
						setShowDeleteFacilityModal(false);
					}
				}
			)
		);
	};

	const deleteModalProps: DeleteModalProp = {
		showModal: showDeleteFacilityModal,
		title: localizationService.getLocalizedString('facilityPortal.settings.facilities.deleteFacilityTitle'),
		message: localizationService.getLocalizedString('facilityPortal.settings.facilities.deleteFacilityMessage'),
		onCancelButtonClick: () => setShowDeleteFacilityModal(false),
		onOkayButtonClick: removeFacility,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	return (
		<>
			<a href={'#/'} className="ai-link cursor-pointer" onClick={onEditClicked}>
				{props.linkText}
			</a>
			{showModal && (
				<PopoverModal
					withoutForm={true}
					showModal={showModal}
					title={localizationService.getLocalizedString('facility.facility')}
					cancel={toggleModal}
					footer={footer()}>
					{showFacilityDetails()}
				</PopoverModal>
			)}
			{showDeleteFacilityModal && <DeleteModal {...deleteModalProps} />}
		</>
	);
};
