import React, { useState } from 'react';
import { connect } from 'react-redux';
import { localizationService, Logger, Resource, urlService } from 'src/services';

import * as ApiTypes from '@rcp/types';

import { CloseSvg } from 'src/components/svg';
import { filterService } from './filter-service';
import { FilterDefinition, FilterItem, FilterValidationBody } from 'src/services/data-types/filter-types';

import 'src/components/widgets/widgets.scss';

import * as actionCreators from 'src/redux/filter';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Filter, DraftFilter } from '@rcp/types';
import { FilterEditModal } from './filter-editor-modal';
import { alertService, IsFilterNameValid, ApplicationState, FilterState, useReduxDispatch } from 'src/redux';

import { useLocation } from 'react-router';
import _ from 'lodash';
import { chartService } from 'src/components/authority/fog/dashboard/dashboard-widgets/chart-service';
import { Utils } from 'src/services/utils';
import { NullValues } from '@rcp/types';

interface OwnProps {
	draftFilterId?: number;
	filterId?: number;
	reportPackageElementTypeId?: string;

	filterDeleted: () => void;
	onClosedClicked: () => void;
	onFilterChanged: (filterSaved: Filter | undefined, daftFilterSaved: DraftFilter | undefined) => void;
	onFilterSaved: (savedFilterId: number) => void;
}
interface QueryProperty {
	label: string;
	value: string;
}

interface StateProps extends FilterState {}

interface DispatchProps {
	saveDraftFilterAsFormalFilter: (draftFilterId: number) => Filter;
}

type FilterQuickViewerProp = StateProps & OwnProps & DispatchProps;

const FilterQuickViewerComp: React.SFC<FilterQuickViewerProp> = props => {
	const dispatch = useReduxDispatch();
	const [showQuickView, setShowQuickView] = useState(false);
	const [showActions, setShowActions] = useState(false);
	const [filterName, setFilterName] = useState('');
	const [showSaveBtn, setShowBtn] = useState(true);
	const [showFilterEditorModal, setShowFilterEditorModal] = useState(false);
	const [widgetCount, setWidgetCount] = useState(0);
	const [drillDownParameters, setDrillDownParameters] = useState([] as QueryProperty[]);

	// const customFieldDefinitions = filterService.cu;
	const location = useLocation();

	React.useEffect(() => {
		if (props.filterId) {
			setShowBtn(false);
			dispatch(actionCreators.GetFilterById(props.filterId)).then((currentFilter: Filter) => {
				if (currentFilter.filterId) {
					setShowQuickView(true);
					setFilterName(currentFilter.filterName);
					setWidgetCount(currentFilter.widgetCount ? currentFilter.widgetCount : 0);
				} else {
					Logger.info(`Filter ${props.filterId} no longer exist, clear it and continue.`);
					filterService.clearCachedUrlItem();
				}
			});
			setShowActions(true);
		} else if (props.draftFilterId) {
			setShowBtn(props.reportPackageElementTypeId ? false : true);
			dispatch(actionCreators.GetDraftFilterById(props.draftFilterId)).then((currentDraftFilter: DraftFilter) => {
				if (currentDraftFilter.draftFilterId) {
					setShowQuickView(true);
					setFilterName(currentDraftFilter.filterName);
				} else {
					Logger.info(`DraftFilter ${props.draftFilterId} no longer exist, clear it and continue.`);
					filterService.clearCachedUrlItem();
				}
			});
			setShowActions(true);
		} else {
			setShowActions(false);
		}
	}, [dispatch, props.draftFilterId, props.filterId]);

	React.useEffect(() => {
		let queryParameters = urlService.toQueryDictionary();
		_.unset(queryParameters, 'filterId');
		_.unset(queryParameters, 'sort');
		_.unset(queryParameters, 'page');
		setupDrillDownValues(queryParameters);
	}, [location.search]);

	const setupDrillDownValues = (queryParameters: ApiTypes.Dictionary<string>) => {
		let domainObject = urlService.getDomainObjectName();
		chartService
			.getAvailableGroupBysForFiterQuickViewer(domainObject as ApiTypes.DashboardWidgetFilterCategories)
			.then((groupByFields: ApiTypes.DropDownOption[]) => {
				let queryArray: QueryProperty[] = [];
				_.forIn(queryParameters, function(value, key) {
					let groupByField = groupByFields.find(a => {
						let fieldName = Utils.extractFieldName(key);
						return String.equalCaseInsensitive(fieldName, a.value);
					});
					if (groupByField) {
						value = value == NullValues.Null ? NullValues.Empty : value;
						queryArray = [{ label: groupByField.label as string, value: value }, ...queryArray];
					}
				});
				setDrillDownParameters(queryArray);
			});
	};

	const resetLocalState = () => {
		setFilterName('');
		setShowBtn(true);
		setShowFilterEditorModal(false);
	};

	const onCloseIconClicked = () => {
		if (props.onClosedClicked) {
			filterService.clearFilter();
			props.onClosedClicked();

			resetLocalState();
		}
	};

	const onSaveButtonClicked = async (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		if (props.draftFilterId) {
			let filterId = props.filterId;
			if (!props.filterId && props.currentFilter) {
				filterId = props.currentFilter.filterId;
			}
			if (!filterId && props.currentDraftFilter) {
				filterId = props.currentDraftFilter.filterId;
			}

			let filterToValid = new FilterValidationBody(filterName, false, filterId, props.draftFilterId);
			let isFilterNameValid = await IsFilterNameValid(filterToValid);
			if (!isFilterNameValid) {
				alertService.addError(localizationService.getLocalizedString('screen.filterModal.invalidFilterName'));
				return;
			}

			const savedFilter = await props.saveDraftFilterAsFormalFilter(props.draftFilterId);
			if (savedFilter.filterId) {
				setShowBtn(false);
				setFilterName(savedFilter.filterName);
				props.onFilterSaved(savedFilter.filterId);
			}
		}
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShowFilterEditorModal(true);
	};

	const filterEditModalSaved = (
		filterSaved: ApiTypes.Filter | undefined,
		draftFilterSaved: ApiTypes.DraftFilter | undefined
	) => {
		setShowFilterEditorModal(false);
		props.onFilterChanged(filterSaved, draftFilterSaved);

		let filterName = '';
		if (filterSaved) {
			filterName = filterSaved.filterName;
		} else if (draftFilterSaved) {
			filterName = draftFilterSaved.filterName;
		}
		setFilterName(filterName);
	};

	const loadFilterDefinition = () => {
		let filterItems: FilterItem[] = [];
		let filterId = props.filterId;
		let draftFilterId = props.draftFilterId;

		let existingFilterName = '';
		let filterDefinition: FilterDefinition | undefined;
		try {
			if (filterId && props.currentFilter) {
				if (!props.currentFilter.filterDefinition) {
					alertService.addError(
						localizationService.getLocalizedString(
							'screen.validationMessage.filter.invalidFilterDefinition'
						)
					);
					return;
				}

				existingFilterName = props.currentFilter.filterName;
				filterItems = JSON.parse(props.currentFilter.filterDefinition);
				filterId = props.currentFilter.filterId;
			} else if (draftFilterId && props.currentDraftFilter) {
				const draftFilter = props.currentDraftFilter;
				if (!draftFilter || !draftFilter.filterDefinition) {
					alertService.addError(
						localizationService.getLocalizedString(
							'screen.validationMessage.filter.invalidFilterDefinition'
						)
					);
					return;
				}

				existingFilterName = draftFilter.filterName;
				filterItems = JSON.parse(draftFilter.filterDefinition);
				draftFilterId = draftFilter.draftFilterId;
				filterId = draftFilter.filterId;
			}
		} catch (error) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.filter.invalidFilterDefinition')
			);
		}

		if (filterItems && filterItems.length > 0) {
			filterDefinition = filterService.loadFilterDefinitions(
				draftFilterId,
				filterId,
				existingFilterName,
				filterItems,
				filterService.getCustomFields()
			);
		}

		return filterDefinition;
	};

	const filterDeleted = () => {
		resetLocalState();
		props.filterDeleted();
	};

	if (!showActions) {
		return <div />;
	}
	let saveDiv: JSX.Element;
	if (showSaveBtn) {
		saveDiv = (
			<div>
				<a className="mx-1 font-size-12px" href="#/" id="saveDraftFilterLink" onClick={onSaveButtonClicked}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</a>
			</div>
		);
	} else {
		saveDiv = <div />;
	}

	let filterDefinition = loadFilterDefinition();
	return showQuickView ? (
		<div id="filter-quick-view" className="p-2 filter-quick-view ">
			<div className="d-flex flex-row">
				<div className="mr-2" id="appliedFilterName">
					{localizationService.getLocalizedString('screen.labels.currentFilter')}:&nbsp;{filterName}
				</div>

				{_.isEmpty(drillDownParameters) ? (
					<div>
						<a
							className="mx-1 font-size-12px"
							href="#/"
							id="editFilterLink"
							onClick={e => onEditClicked(e)}>
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					</div>
				) : (
					<div id="appliedGroupBy">
						{drillDownParameters.map(param => (
							<span>
								/&nbsp;{param.label} - {param.value}&nbsp;
							</span>
						))}
					</div>
				)}

				{saveDiv}
				<div className="cursor-pointer mx-1" id="closeQuickView" onClick={onCloseIconClicked}>
					<CloseSvg size="8" />
				</div>
			</div>
			{showFilterEditorModal && (
				<FilterEditModal
					isCreateNew={false}
					title={localizationService.getLocalizedString('screen.filterModal.editFilter')}
					modalToggleFunction={setShowFilterEditorModal}
					applyBtnClicked={filterEditModalSaved}
					filterDefinition={filterDefinition}
					filterDeleted={filterDeleted}
					reportPackageElementTypeId={props.reportPackageElementTypeId}
					widgetCount={widgetCount}
				/>
			)}
		</div>
	) : (
		<></>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.filters };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveDraftFilterAsFormalFilter: (draftFilterId: number) =>
			dispatch(actionCreators.saveDraftFilterAsFormalFilter(draftFilterId))
	};
};

export const FilterQuickViewer = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FilterQuickViewerComp);
