import { AcpDomain, Dictionary, DropDownOption, LocalStorageName, TranslationLanguage, UrlAction } from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
	AlertMessageType,
	alertService,
	ApplicationState,
	loadMaintenanceMessage,
	MaintenanceMessageState
} from 'src/redux';
import { authenticateUser, AuthenticationState } from '../../redux/authentication';
import { localizationService, localStorageService, navigateTo, tokenService, urlService } from 'src/services';
import './authentication-component.scss';
import { ChangePassword } from './change-password';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { oidcEnvConfig } from 'src/constants';
import { oidcAuthService } from './oidc/oidc-auth-service';
import { authenticationService } from './authentication-service';
import { Translate } from '../widgets/translate/translator';
import { LanguageContext } from '../widgets/translate/translator-context';
import { SingleSelectDropdown } from '../widgets';
import { loadLanguages } from 'src/redux/languages/languages';
import { isNewPasswordPage } from '../widgets/translate/language-select-dropdown';

interface RequiredDispatcher {
	authenticateUser: (username: string, userPassword: string) => any;
	loadMaintenanceMessage: () => any;
	loadLanguages: () => any;
}

interface Props extends AuthenticationState, MaintenanceMessageState, RequiredDispatcher {
	history: History;
	isRegisterComponent?: boolean;
	onSignInClick?: () => void;
	renderCustomForm?: (props: any) => ReactElement;
	languages?: TranslationLanguage[];
}

const initialState = {
	userName: '',
	userPassword: '',
	title: localizationService.getLocalizedString('authentication.signIn'),
	mfaType: '',
	oidcEnabled: false,
	oidcConfig: oidcEnvConfig
};

class AuthenticationComponent extends Component<Props, any> {
	constructor(props: Props) {
		super(props);
		this.state = initialState;

		this.handleUserNameChange = this.handleUserNameChange.bind(this);
		this.handleUserPasswordChange = this.handleUserPasswordChange.bind(this);
	}

	componentDidMount() {
		let value = this.context;
		this.setState({ language: value.language });
		let signInUrl = new URL(window.location.href);
		let signOut = signInUrl.searchParams.get('signOut');
		let continueUrl: string = signInUrl.searchParams.get('continue') || '';
		let facilityDeletedFromFacilityPortal = signInUrl.searchParams.get('facilityDeleted');

		isNewPasswordPage(false);
		let token = tokenService.getTokenOrDefault();
		localStorage.removeItem('hidePortalDirector');
		localStorage.removeItem('signInLandingFrom');

		for (let key in localStorage) {
			if (key && key.includes('programsLogged')) {
				localStorage.removeItem(key);
			}
		}

		if (facilityDeletedFromFacilityPortal) {
			alertService.addSuccess(
				localizationService.getLocalizedString('alertMessages.removeSuccess', facilityDeletedFromFacilityPortal)
			);
			navigateTo(this.props.history, `SignIn?action=${UrlAction.FacilityRegistration}`);
		}

		if (signOut || (continueUrl && urlService.getSubDomain(continueUrl) !== token.portalSubDomain)) {
			authenticationService.logout(true);
			token = tokenService.getTokenOrDefault();
			this.setState({ ...initialState });
		}

		if (tokenService.isTokenValid(token)) {
			let queryParams: Dictionary<string> = {
				accessToken: token.accessToken as string,
				expired: `${token.expirationEpochTimeInUtc}`,
				refreshToken: token.refreshToken,
				tokenType: token.tokenType,
				tokenProvider: token.tokenProvider,
				organizationId: `${token.portalOrganizationId}`,
				organizationName: token.portalOrganizationName,
				regulatoryProgramNames: `${token.portalRegulatoryProgramNames}`,
				permissionGroupNames: `${token.portalPermissionGroupNames}`,
				organizationTypeId: `${token.portalOrganizationTypeId}`,
				organizationTypeName: token.portalOrganizationTypeName,
				organizationRegulatoryProgramId: `${token.portalOrganizationRegulatoryProgramId}`,
				userName: token.userName,
				ianaTimeZoneName: token.ianaTimeZoneName,
				mfaEnabledPrograms: token.mfaEnabledPrograms,
				guid: token.guid
			} as Dictionary<string>;
			let portalUrl = `${window.location.protocol}//${token.portalSubDomain}.${AcpDomain}${urlService.composePort(
				window.location.port
			)}?${urlService.toQueryString(queryParams)}`;
			if (token.portalSubDomain) {
				window.location.href = portalUrl;
			} else {
				tokenService.clearToken();
			}
		} else {
			this.setState({ oidcEnabled: authenticationService.oidcEnabled() });
		}
		document.body.className = 'signinBody';
		this.props.loadMaintenanceMessage();
		this.props.loadLanguages();
	}

	componentWillUnmount() {
		document.body.className = '';
	}

	handleUserNameChange(event: any) {
		this.setState({ userName: event.target.value });
	}

	handleUserPasswordChange(event: any) {
		this.setState({ userPassword: event.target.value });
	}

	clickSignInButton = (event: any, userName?: string, password?: string) => {
		event.preventDefault();
		this.fireLogin(userName, password);
	};

	signInWithAqi = (event: any) => {
		event.preventDefault();
		oidcAuthService.signInRedirect();
	};

	handleKeyPressed = (event: any) => {
		if (String.equalCaseInsensitive(event.key, 'Enter')) {
			event.preventDefault();
			this.fireLogin();
		}
	};

	fireLogin = async (userName?: string, password?: string) => {
		alertService.clearAllMessages();
		await this.props.authenticateUser(userName || this.state.userName, password || this.state.userPassword);
	};

	componentWillReceiveProps(newProps: Props) {
		if (_.has(newProps, 'isTemporaryUser')) {
			return;
		}
		if (_.has(newProps, 'signInResult.accessToken')) {
			let signInResult = newProps.signInResult;
			authenticationService.handleSignInResult(signInResult, this.props.history, this.state.language);
		}
	}

	convertMaintenanceMessageToHtml(text: string): JSX.Element {
		return React.createElement('span', {}, text);
	}

	isMaintenanceMessageVisible(): boolean {
		return this.props.maintenanceMessage.isEnabled === true && !_.isEmpty(this.props.maintenanceMessage.text);
	}

	handleLanguageChange(e: any, setLanguage: (updatedLanguage: TranslationLanguage) => void) {
		let languageCode = e.target.value;
		let newLanguage = this.props.languages?.find(i => String.equalCaseInsensitive(i.code, languageCode));
		if (newLanguage && newLanguage.code) {
			setLanguage(newLanguage);
			localStorageService.setLocalStorage(LocalStorageName.DisplayLanguage, newLanguage);

			this.setState({ language: newLanguage });
		}
	}

	createLanguageOptions = (): DropDownOption[] => {
		let languageOptionsToCreate: DropDownOption[] = [];
		if (this.props.languages && this.props.languages.length > 0) {
			languageOptionsToCreate = this.props.languages.map((a: TranslationLanguage) => {
				return { label: a.nativeName, value: a.code };
			});
		}
		return languageOptionsToCreate;
	};

	render() {
		let languageOptions = this.createLanguageOptions();
		if (this.props.signInResult.isTemporaryUser === true) {
			return <ChangePassword email={this.state.userName} oldPassword={this.state.userPassword} />;
		}
		return (
			<LanguageContext.Consumer>
				{({ language, setLanguage }) => (
					<div className={this.props.renderCustomForm ? 'register' : 'signin'}>
						{!this.props.renderCustomForm ? (
							<>
								<h1>
									<Translate>{this.state.title}</Translate>
								</h1>
								{this.props.errors.length > 0 ? (
									<InlineAlertItem
										message={this.props.errors[0].message}
										alertType={AlertMessageType.Error}
										alertContainerId="signin-alert"
									/>
								) : null}
								{this.isMaintenanceMessageVisible() ? (
									<InlineAlertItem
										message={this.props.maintenanceMessage.text as string}
										alertType={AlertMessageType.Info}
										alertContainerId="signin-maintenance-message"
										isMaintenanceMessage={true}
									/>
								) : null}
								{authenticationService.isInviteCodeConfirmed() ? (
									<InlineAlertItem
										message={localizationService.getLocalizedString(
											'haulerPortal.inviteCodeConfirmedMessage'
										)}
										alertType={AlertMessageType.Success}
										alertContainerId="signin-success-message"
									/>
								) : null}
								{authenticationService.isHaulerRegistration() ||
								authenticationService.isFacilityRegistration() ? (
									<p>
										<Translate>
											{localizationService.getLocalizedString('haulerPortal.userRegister')}
										</Translate>
										<a
											className="ai-link cursor-pointer"
											onClick={() => {
												const redirectUrl = authenticationService.isHaulerRegistration()
													? `hauler/register?action=${UrlAction.HaulerRegistration}`
													: `facility/register?action=${UrlAction.FacilityRegistration}`;
												navigateTo(this.props.history, redirectUrl);
											}}>
											<Translate>
												{localizationService.getLocalizedString('haulerPortal.signUpForFree')}
											</Translate>
										</a>
									</p>
								) : null}
								<form>
									<div className="form-group">
										<label htmlFor="userName" className="ai-label">
											<Translate>
												{localizationService.getLocalizedString(
													'authentication.emailOrUserName'
												)}
											</Translate>
										</label>
										<input
											type="email"
											className="form-control"
											id="userName"
											value={this.state.userName}
											onChange={this.handleUserNameChange}
											onKeyPress={this.handleKeyPressed}
										/>
									</div>
									<div className="form-group">
										<label htmlFor="userPassword" className="ai-label">
											<Translate>
												{localizationService.getLocalizedString('authentication.password')}
											</Translate>
										</label>
										<input
											type="password"
											className="form-control"
											id="userPassword"
											value={this.state.userPassword}
											onChange={this.handleUserPasswordChange}
											onKeyPress={this.handleKeyPressed}
										/>
									</div>
									<div className="form-group">
										<SingleSelectDropdown
											id="languageSelect"
											name="languageSelect"
											doNotTranslateOptions={true}
											label={localizationService.getLocalizedString('languages.language')}
											value={
												this.state.language && this.state.language.code
													? this.state.language.code
													: 'en'
											}
											onChange={(e: any) => {
												this.handleLanguageChange(e, setLanguage);
											}}
											noEmptyOption={true}
											options={languageOptions}
										/>
									</div>

									<div className="form-group">
										<button
											id="signin-btn"
											className="btn ai-action btn-signin"
											onClick={this.clickSignInButton}>
											<Translate>
												{localizationService.getLocalizedString('screen.buttons.signin')}
											</Translate>
										</button>
									</div>
								</form>

								<div className="signin-footer">
									<div>
										<div className="ai-form-help">
											<Translate>
												{localizationService.getLocalizedString(
													'authentication.forgetPassword'
												)}
											</Translate>
											&nbsp;
											<a
												href={`${window.location.protocol}//${window.location.host}/ForgetPassword`}
												id="forgetPasswordLink">
												<Translate>
													{localizationService.getLocalizedString('authentication.getHelp')}
												</Translate>
											</a>
											<Translate>
												{localizationService.getLocalizedString('authentication.period')}
											</Translate>
										</div>
									</div>
									{this.state.oidcEnabled && (
										<>
											<p className="or-separator">
												<span>
													<Translate>or sign in with</Translate>
												</span>
											</p>
											<button
												id="signin-with-aqi-btn"
												className="btn ai-white aquaticinformatics"
												onClick={this.signInWithAqi}>
												{localizationService.getLocalizedString(
													'authentication.signInWithAqiAccount'
												)}
											</button>
										</>
									)}
								</div>
							</>
						) : (
							this.props.renderCustomForm &&
							this.props.renderCustomForm({
								error: this.props.errors,
								clickSignInButton: this.clickSignInButton
							})
						)}
					</div>
				)}
			</LanguageContext.Consumer>
		);
	}
}

AuthenticationComponent.contextType = LanguageContext;

const mapStateToProps = (state: ApplicationState) => {
	return { ...state.authenticationResult, ...state.maintenanceMessage, ...state.languages };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): RequiredDispatcher => {
	return {
		authenticateUser: (username: string, password: string) => dispatch(authenticateUser(username, password)),
		loadMaintenanceMessage: () => dispatch(loadMaintenanceMessage()),
		loadLanguages: () => dispatch(loadLanguages())
	};
};

export const SignIn = connect(mapStateToProps, mapDispatchToProps)(AuthenticationComponent);
