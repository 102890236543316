import { ApiEndpoints, DropDownOption } from '@rcp/types';
import React from 'react';
import { alertService } from 'src/redux';
import { Logger } from 'src/services';
import { RestApi } from 'src/services/rest.api';
import { chartService, DateFilterKind } from './chart-service';

interface Props {
	dashboardWidgetId?: number;
	name?: string;
	className?: string;
	dateFilterKind?: DateFilterKind;
	value: string;
	onChange: (e: any) => void;
}

export const DefaultDateFilterSelect: React.FC<Props> = props => {
	const [dateFilterValue, setDateFilterValue] = React.useState(props.value);
	const restApi = new RestApi<any>(ApiEndpoints.Dashboard);
	const dateFilterKind = props.dateFilterKind || DateFilterKind.Default;
	const dateFilter = chartService.getDateFilter(dateFilterKind);

	React.useEffect(() => {
		setDateFilterValue(props.value);
	}, [props.value]);

	const changeDateFilter = (e: any) => {
		const { value } = e.target;

		if (value !== dateFilterValue) {
			setDateFilterValue(value);
			if (props.dashboardWidgetId) {
				//save filter
				restApi
					.patchOne(props.dashboardWidgetId, { dateFilterValue: value })
					.then(() => {
						Logger.debug(`Saved dateFilterValue as ${value}`);
					})
					.catch((e: any) => {
						alertService.addError(e.message);
					});
			}
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};

	const name = props.name || 'dateFilter';
	const className = props.className || 'custom-select float-left';
	return (
		<select name={name} className={className} value={props.value} onChange={changeDateFilter}>
			{dateFilter.getDateFilterOptions().map((item: DropDownOption, index) => {
				return (
					<option key={`dateFilter-${name}-${index}`} value={item.value}>
						{item.label}
					</option>
				);
			})}
		</select>
	);
};
