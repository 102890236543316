import React from 'react';
import _ from 'lodash';
import { localizationService } from 'src/services';
import { HaulerCardComponent } from './hauler-card-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { BasicHauler } from '@rcp/types';
import { LastHaulerRightPanel } from './last-hauler-component';
import { ContactedHaulerModal } from './contracted-hauler-modal';

interface OwnProps {
	contractedHauler: BasicHauler;
	lastHauler: BasicHauler;
}

type Props = OwnProps;

export const ContractedHaulerRightPanel: React.SFC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onToggleShowModal = (e: any) => {
		e.preventDefault();
		setShowModal(!showModal);
	};

	const toggle = () => {
		setShowModal(!showModal);
	};

	const contractedHaulerDetailsComponent = (
		<div className="media" key={'ContractedHaulerCard-' + props.contractedHauler.haulerId}>
			<FontAwesomeIcon icon={faTruck} className="align-self-start mr-3 mt-1 icon-font-color" />
			<div className="media-body">
				{props.contractedHauler.haulerId === props.lastHauler.haulerId && (
					<span className="gray-tag">
						{localizationService.getLocalizedString('facility.lastHaulerName')}
					</span>
				)}
				<HaulerCardComponent hauler={{ ...props.contractedHauler }} />
			</div>
		</div>
	);

	const componentForHauler = props.contractedHauler.haulerId ? (
		contractedHaulerDetailsComponent
	) : (
		<div>
			<a href="#/" className="ai-add-link" onClick={e => onToggleShowModal(e)}>
				{localizationService.getLocalizedString('screen.buttons.addHauler')}…
			</a>
		</div>
	);

	return (
		<>
			<section>
				<div id="hauler" className="d-flex">
					<h3>{localizationService.getLocalizedString('extractor.contractedHauler')}</h3>
					{props.contractedHauler.haulerId && (
						<a href="#/" className="font-size-16px ai-edit-link ml-auto mt-n1" onClick={onToggleShowModal}>
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					)}
				</div>

				{componentForHauler}
			</section>
			{props.contractedHauler.haulerId !== props.lastHauler.haulerId && props.lastHauler.haulerId && (
				<LastHaulerRightPanel hauler={{ ...props.lastHauler }} />
			)}
			{showModal && (
				<ContactedHaulerModal
					showModal={showModal}
					toggleModal={toggle}
					selectedContractedHauler={props.contractedHauler}
				/>
			)}
		</>
	);
};
