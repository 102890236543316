import { RestSlice } from 'src/redux/rest.slice';
import { localizationService, Resource } from 'src/services';
import { Lookup } from '@rcp/types';

export const cccLookupHazardCategorySlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.category'),
	Resource.SettingLookup
);
export const cccLookupHazardCategoryReducer = cccLookupHazardCategorySlice.rests.reducer;

export const cccLookupHazardProtectionSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.protection'),
	Resource.SettingLookup
);
export const cccLookupHazardProtectionReducer = cccLookupHazardProtectionSlice.rests.reducer;

export const cccLookupHazardDeviceStatusSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.deviceStatus'),
	Resource.SettingLookup
);
export const cccLookupHazardDeviceStatusReducer = cccLookupHazardDeviceStatusSlice.rests.reducer;

export const cccLookupHazardDeviceOrientationSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.deviceOrientation'),
	Resource.SettingLookup
);
export const cccLookupHazardDeviceOrientationReducer = cccLookupHazardDeviceOrientationSlice.rests.reducer;

export const cccLookupHazardServiceStatusSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.serviceStatus'),
	Resource.SettingLookup
);
export const cccLookupHazardServiceStatusReducer = cccLookupHazardServiceStatusSlice.rests.reducer;

export const cccLookupHazardServiceTypeSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.serviceType'),
	Resource.SettingLookup
);
export const cccLookupHazardServiceTypeReducer = cccLookupHazardServiceTypeSlice.rests.reducer;

export const cccLookupHazardLineSizeSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('cccHazard.lineSize'),
	Resource.SettingLookup
);
export const cccLookupHazardLineSizeReducer = cccLookupHazardLineSizeSlice.rests.reducer;
