import { IppIndustry, IppReportPackageParameter, IppReportPackageParameterGroup } from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import {
	loadReportPackageParameterGroupRequest,
	loadReportPackageParameterGroupSuccess,
	ReportPackageParameterGroupAction
} from './parameter-groups-actions';
import { IppReportPackageParameterGroupState } from './parameter-groups-state';

type ParameterGroupsThunkAction = ThunkAction<
	any,
	IppReportPackageParameterGroupState,
	any,
	ReportPackageParameterGroupAction
>;

export const loadParameterGroupDetails = (id?: any): ParameterGroupsThunkAction => async (dispatch, getState) => {
	dispatch(loadReportPackageParameterGroupRequest());
	let parameterGroupDetails;
	let url;
	if (id && id !== 'new') {
		url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackageParameterGroups, id);
		parameterGroupDetails = await apiService.getResource<IppReportPackageParameterGroup>(
			`${url}?includeInactive=true`
		);
	} else {
		parameterGroupDetails = {
			parameters: [],
			parameterGroupAssignments: [],
			name: '',
			description: ''
		};
	}
	url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackageParameters, 'sort=name asc');
	let totalParameters = await apiService.getResource<IppReportPackageParameter[]>(url);

	let parameters = parameterGroupDetails.parameters ? parameterGroupDetails.parameters : [];

	url = urlService.getAuthorityResourcesApiUrl(Resource.IppIndustries, 'sort=industryNumber asc');
	let totalIndustries = await apiService.getResource<IppIndustry[]>(url);
	let assignedIndustries = parameterGroupDetails.parameterGroupAssignments
		? parameterGroupDetails.parameterGroupAssignments
		: [];

	let availableParameters = getAvailableParameters(totalParameters, parameters);
	let availableIndustries = getAvailableIndustries(
		Array.isArray(totalIndustries) ? totalIndustries : (totalIndustries as any).result,
		assignedIndustries
	);

	dispatch(
		loadReportPackageParameterGroupSuccess({ ...parameterGroupDetails }, availableParameters, availableIndustries)
	);
};

export const updateParameterGroupDetails = (
	parameterGroupDetails: IppReportPackageParameterGroup
): ParameterGroupsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackageParameterGroups,
		parameterGroupDetails.parameterGroupId as number
	);

	await apiService.httpPut(url, parameterGroupDetails);
	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.saveSuccessMessage')
	);
	dispatch(loadParameterGroupDetails(parameterGroupDetails.parameterGroupId || 0));
	return true;
};

export const addParameterGroup = (
	parameterGroupDetails: IppReportPackageParameterGroup,
	callback: (id: number) => void
): ParameterGroupsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackageParameterGroups);
	let postData = {
		...parameterGroupDetails
	};

	delete parameterGroupDetails.parameterGroupId;

	let newParameterGroupDetails = await apiService.httpPost(url, postData);

	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.createSuccessMessage')
	);

	dispatch(loadParameterGroupDetails(newParameterGroupDetails[0].parameterGroupId));

	callback(newParameterGroupDetails[0].parameterGroupId);

	return true;
};

export const deleteParameterGroup = (parameterGroupId: number): ParameterGroupsThunkAction => async (
	dispatch,
	getState
) => {
	const url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackageParameterGroups, parameterGroupId);
	await apiService.httpDelete(url);

	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.deleteSuccessMessage')
	);

	dispatch(loadParameterGroupDetails());
	return;
};

const getAvailableParameters = (
	total: IppReportPackageParameter[],
	selected: IppReportPackageParameter[]
): IppReportPackageParameter[] => {
	let selectedIds = selected.map(({ parameterId }) => parameterId);
	return total.filter(({ parameterId }) => !selectedIds.includes(parameterId));
};
const getAvailableIndustries = (total: IppIndustry[], selected: IppIndustry[]): IppIndustry[] => {
	let selectedIds = selected.map(({ ippIndustryId }) => ippIndustryId);
	return total.filter(({ ippIndustryId }) => !selectedIds.includes(ippIndustryId));
};
