import React, { useState } from 'react';
import { FilterItem } from '../../services/data-types/filter-types';
import { localizationService } from 'src/services';
import { FilterMultiText } from './filter-row-multi-text';
import _ from 'lodash';
import { filterService } from './filter-service';

interface FilterTextProps {
	rowNumber: number;
	updateFilter: (filterItem: FilterItem) => void;
	filterItem: FilterItem;
	reportPackageElementTypeId?: string;
	isForMap?: boolean;
}

export const FilterText: React.FC<FilterTextProps> = props => {
	const [withoutEmptyOption, setWithoutEmptyOption] = useState(false);
	React.useEffect(() => {
		setWithoutEmptyOption(
			props.filterItem.operator.label == localizationService.getLocalizedString('screen.filterModal.contains') ||
				!props.filterItem.property.label ||
				Boolean(props.filterItem.property.isNonNullable)
		);
	}, [props.filterItem.operator.label, props.filterItem.property.label]);

	const isMultiTextInput = (): boolean => {
		let domainObjectNameInUpperCase = _.toString(props.filterItem.domainObject.name).toUpperCase();
		return (
			_.includes(filterService.supportedDomainObjectNames, domainObjectNameInUpperCase) &&
			props.filterItem.operator.label !== localizationService.getLocalizedString('screen.filterModal.contains')
		);
	};

	const onValueChange = (e: any) => {
		if (props.filterItem.values.length !== 1) {
			throw new Error(`resetFilterItem is incorrect that filterItem.values length must be 1 on text filter`);
		}
		let newValue = e.target.value as string;
		let newFilterItem = { ...props.filterItem } as FilterItem;
		if (props.filterItem.operator.label === localizationService.getLocalizedString('screen.filterModal.contains')) {
			newFilterItem.values[0] = {
				values: newValue ? [newValue] : []
			};
		} else {
			newFilterItem.values[0] = {
				value: newValue
			};
		}
		props.updateFilter(newFilterItem);
	};

	const onValuesChange = (selectedValues: string[]) => {
		if (props.filterItem.values.length !== 1) {
			throw new Error(
				`resetFilterItem is incorrect that filterItem.values length must be 1 on multi-text filter`
			);
		}
		let newFilterItem = { ...props.filterItem } as FilterItem;
		newFilterItem.values[0] = {
			values: selectedValues
		};
		props.updateFilter(newFilterItem);
	};

	if (isMultiTextInput()) {
		return (
			<FilterMultiText
				reportPackageElementTypeId={props.reportPackageElementTypeId}
				rowNumber={props.rowNumber}
				onChange={onValuesChange}
				filterItem={props.filterItem}
				withoutEmptyOption={withoutEmptyOption}
				allowCustomValue={
					props.filterItem.operator.label ===
					localizationService.getLocalizedString('screen.filterModal.contains')
				}
				isForMap={props.isForMap}
			/>
		);
	}

	let operatorValue = filterService.getFilterValue(props.filterItem, 0);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<div className={inputGroupCss}>
			<label htmlFor={`FilterOperatorValue_${props.rowNumber}`}>
				{localizationService.getLocalizedString('screen.filterModal.value')}
			</label>
			<input
				type="text"
				className={`form-control ${operatorValue.error ? ' is-invalid' : ' '}`}
				id={`FilterOperatorValue_${props.rowNumber}`}
				value={operatorValue.value || (operatorValue.values && operatorValue.values[0]) || ''}
				onChange={onValueChange}
			/>
			{operatorValue.error && <div className="invalid-feedback">{operatorValue.error}</div>}
		</div>
	);
};
