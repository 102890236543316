import { LookupRow } from '../lookup-types';
import { LookupType, EnforcementType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class EnforcementTypeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: EnforcementType): LookupRow => {
		return {
			lookupId: apiData.enforcementTypeId,
			code: apiData.name,
			codeDescription: apiData.description,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let enforcementType = {
			enforcementTypeId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			description: rowToUpdate.codeDescription,
			isActive: rowToUpdate.isActive
		} as EnforcementType;
		return enforcementType;
	}

	toLookupRowsFromAPI(apiData: EnforcementType[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): EnforcementType {
		let enforcementType = {
			name: newValue.code,
			description: newValue.codeDescription,
			isActive: newValue.isActive
		} as EnforcementType;
		return enforcementType;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
