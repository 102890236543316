import { tokenService } from './token-service';
import _ from 'lodash';
import { urlService } from './url-service';
import { Environment } from '@rcp/types';

export class LocalStorageService {
	getLocalStorage(itemId: string): any {
		const itemKey = this.getLocalStorageKey(itemId);
		const localValue = localStorage.getItem(itemKey);
		if (!localValue) {
			return null;
		}

		return JSON.parse(localValue);
	}

	getLocalStorageWithDefaultValue(itemId: string, defaultValue: any): any {
		let lsValue = this.getLocalStorage(itemId);
		if (lsValue) {
			return lsValue;
		}
		return defaultValue;
	}

	getLocalStorageWithDefaultNumber(itemId: string, defaultValue: number): number {
		let lsValue = this.getLocalStorage(itemId);
		if (lsValue) {
			return _.toNumber(lsValue);
		}
		return defaultValue;
	}

	//Local Storage date values are converted to string after JSON.stringify and JSON.parse
	//The caller can manual compose a callback to do something like set text back date
	getLocalStorageWithCallback<T>(itemId: string, callback: (item: T) => void): T | null {
		let item = this.getLocalStorage(itemId) as T;
		if (item == null) {
			return null;
		}
		if (callback) {
			callback(item);
		}
		return item;
	}

	setLocalStorage(itemId: string, itemValue: any) {
		const itemKey = this.getLocalStorageKey(itemId);

		if (itemValue) {
			localStorage.setItem(itemKey, JSON.stringify(itemValue));
		} else {
			localStorage.setItem(itemKey, '');
		}
	}

	removeLocalStorage(itemId: string) {
		const key = this.getLocalStorageKey(itemId);
		localStorage.removeItem(key);
	}

	getLocalStorageKey(itemId: string): string {
		let token = tokenService.getTokenOrDefault();
		let key = '';
		if (token.userName) {
			key = `${_.toLower(token.userName)}`;
		}
		return `${key}:${itemId}`;
	}

	getLastProgram(): string {
		const value = localStorage.getItem('lastVisitProgram');
		return value ? `${value}` : 'fog';
	}

	setLastProgram(program: string): void {
		program && localStorage.setItem('lastVisitProgram', program);
	}

	getTranslationValueFromCache(key: string | number, language: string): string | null {
		let translations = this.getLocalStorage(`Translations-${language}`);
		if (_.has(translations, key)) {
			return translations[key];
		}
		return null;
	}

	addTranslationValueToCache(key: string | number, language: string, value: string): void {
		let translations = this.getLocalStorage(`Translations-${language}`);
		let newTranslations = { ...translations, [key]: value };
		this.setLocalStorage(`Translations-${language}`, newTranslations);
	}
}

export const localStorageService = new LocalStorageService();

//LogLevel definition is following [RFC 5424 severity levels](https://en.wikipedia.org/wiki/Syslog#Severity_levels)
enum LogLevel {
	Error = 3,
	Warning = 4,
	Information = 6,
	Debug = 7,
	Trace = 8
}

/**
 * Add localstorage entry key as "logLevel" to turn browser logging on, the entry value as:
 * 3 for errors
 * 4 for warnings
 * 6 for information
 * 7 for debug
 * 8 for trace
 */
export class Logger {
	static getLoggerLevel(): LogLevel {
		let logLevel = LogLevel.Information;
		const value = localStorage.getItem('logLevel');
		if (value) {
			let v = _.toUpper(value);
			if (['3', 'ERROR'].includes(v)) {
				logLevel = LogLevel.Error;
			} else if (['4', 'WARNING', 'WARN'].includes(v)) {
				logLevel = LogLevel.Warning;
			} else if (['6', 'INFORMATION', 'INFO'].includes(v)) {
				logLevel = LogLevel.Information;
			} else if (['7', 'DEBUG'].includes(v)) {
				logLevel = LogLevel.Debug;
			} else if (['8', 'TRACE'].includes(v)) {
				logLevel = LogLevel.Trace;
			}
		}
		return logLevel;
	}

	static isDevelopmentEnvironment(): boolean {
		let environment = urlService.getEnvironment();
		return environment === Environment.Development;
	}

	static isDebugEnabled(): boolean {
		if (window && window.location && window.location.host.indexOf(':3000') !== -1) {
			return true;
		}
		return Logger.getLoggerLevel() >= LogLevel.Debug;
	}

	static trace = (message?: any, ...optionalParams: any[]): void => {
		if (Logger.getLoggerLevel() >= LogLevel.Trace) {
			if (optionalParams.length > 0) {
				console.trace('[TRACE] ' + message, ...optionalParams);
			} else {
				console.trace('[TRACE] ' + message);
			}
		}
	};

	static debug = (message?: any, ...optionalParams: any[]): void => {
		if (Logger.getLoggerLevel() >= LogLevel.Debug) {
			if (optionalParams.length > 0) {
				console.debug('[DEBUG] ' + message, ...optionalParams);
			} else {
				console.debug('[DEBUG] ' + message);
			}
		}
	};

	static info = (message?: any, ...optionalParams: any[]): void => {
		if (Logger.getLoggerLevel() >= LogLevel.Information) {
			if (optionalParams.length > 0) {
				console.info('[INFO ] ' + message, ...optionalParams);
			} else {
				console.info('[INFO ] ' + message);
			}
		}
	};

	static warn = (message?: any, ...optionalParams: any[]): void => {
		if (Logger.getLoggerLevel() >= LogLevel.Warning) {
			if (optionalParams.length > 0) {
				console.warn('[WARN ] ' + message, ...optionalParams);
			} else {
				console.warn('[WARN ] ' + message);
			}
		}
	};

	static error = (message?: any, ...optionalParams: any[]): void => {
		if (Logger.getLoggerLevel() >= LogLevel.Error) {
			if (optionalParams.length > 0) {
				console.error('[ERROR] ' + message, ...optionalParams);
			} else {
				console.error('[ERROR] ' + message);
			}
		}
	};
}
