import React, { FC, useEffect, useState } from 'react';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { apiService, localizationService, Resource, tokenService, urlService } from 'src/services';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, RootState } from 'src/redux';
import {
	DropDownOption,
	Extractor,
	HaulerPortalFacilities,
	WasteType,
	FeatureSetting as FeatureFlagSetting,
	Dictionary
} from '@rcp/types';
import HaulerSubmitCleaningModal from './submit-cleaning';
import { haulerFacilityDevicesSlice } from '../hauler-facility-detail/devices-slice';
import { SeattleAuthorityConstants } from 'src/constants';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { useParams } from 'react-router';

interface Props {
	showModal: boolean;
	onCancel: () => void;
	organizationId?: number;
	facility?: HaulerPortalFacilities;
	isTriggeredFromGrid?: boolean;
}

const SelectDevicesModal: FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [deviceList, setDeviceList] = useState<Extractor[]>([]);
	const { result } = useSelector((state: RootState) => state.haulerFacilityDevices);
	const [selectedDevices, setSelectedDevices] = useState<Array<string>>([]);
	const [wasteTypeOptions, setWasteTypeOptions] = useState<DropDownOption[]>([]);
	const [renderDeviceModal, setRenderDeviceModal] = useState<boolean>(false);
	const [authorityFeatureFlags, setAuthorityFeatureFlags] = useState<Dictionary<boolean>>({});
	const params: any = useParams();
	useEffect(() => {
		setDeviceList(result);
	}, [result]);

	useEffect(() => {
		if (deviceList.length === 1 && props.showModal) {
			setRenderDeviceModal(false);
			setSelectedDevices([deviceList[0].deviceNumber || '']);
			setShowModal(true);
		} else {
			!props.isTriggeredFromGrid && setRenderDeviceModal(true);
			setShowModal(false);
			setSelectedDevices([]);
		}
	}, [deviceList.length, props.showModal]);

	const loadFeatureFlagForAuthority = (authorityId: number) => {
		let url = urlService.getAuthorityResourcesApiUrl(`${Resource.Authorities}/${authorityId}/${Resource.Settings}`);
		apiService.getResource<FeatureFlagSetting[]>(`${url}/Features`).then(data => {
			let authorityFeatureFlags = Object.assign(
				{},
				...data.map((x: FeatureFlagSetting) => {
					const name = x.name;
					return { [name]: x.isEnabled ? x.isEnabled : false };
				})
			);
			setAuthorityFeatureFlags(authorityFeatureFlags);
		});
	};

	useEffect(() => {
		if (props.organizationId && props.showModal) {
			if (props.isTriggeredFromGrid) {
				haulerFacilityDevicesSlice.setApiUrlPath(
					`${Resource.HaulerFacilities}/` +
						Resource.FogFacilities +
						'/' +
						props.organizationId +
						'/' +
						Resource.Extractors
				);
				dispatch(
					haulerFacilityDevicesSlice.fetchAll('sort=deviceNumber asc', () => {
						setRenderDeviceModal(true);
					})
				);
			}
			props.facility!.authorityId && loadFeatureFlagForAuthority(props.facility!.authorityId);
			let url = urlService.getAuthorityLookupUrlForService(
				props.facility!.authorityId as number,
				Resource.WasteTypes
			);
			apiService
				.getResource<WasteType[]>(url)
				.then(wasteTypes => {
					getWasteTypeOptions(wasteTypes);
				})
				.catch(ex => alertService.addError(ex.message));
		}
	}, [props.organizationId, props.showModal]);

	const getWasteTypeOptions = (wasteTypes: WasteType[]) => {
		let options: DropDownOption[] = wasteTypes.map(wasteType => ({
			label: wasteType.wasteTypeCode || '',
			value: wasteType.wasteTypeId
		}));
		options.unshift({ label: '', value: 0 });
		setWasteTypeOptions(options);
	};

	const onCancelModal = () => {
		setShowModal(false);
		if (deviceList.length === 1 && props.showModal) {
			props.onCancel();
			setSelectedDevices([]);
			if (props.isTriggeredFromGrid) {
				setDeviceList([]);
				setRenderDeviceModal(false);
			}
		}
	};

	const handlerSubmit = () => {
		selectedDevices.length
			? setShowModal(true)
			: alertService.addError(
					localizationService.getLocalizedString(
						'haulerPortal.selectCleaningDevice.emptySelectedDevicesWarning'
					)
			  );
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.type === 'checkbox') {
			e.target.checked
				? setSelectedDevices([...selectedDevices, e.target.name])
				: setSelectedDevices(selectedDevices.filter(deviceNumber => deviceNumber !== e.target.name));
		}
	};

	const onCancel = () => {
		props.onCancel();
		setSelectedDevices([]);
	};

	const submitCleaningModal = {
		showModal: showModal,
		onCancel: onCancelModal,
		selectedDevices: selectedDevices,
		deviceList: deviceList,
		title: localizationService.getLocalizedString('haulerPortal.submitCleaning.submitCleaning'),
		wasteTypeOptions: wasteTypeOptions,
		facilityId: props.organizationId,
		closeSelectDevicePopup: () => {
			props.onCancel();
			setSelectedDevices([]);
		},
		facility: props.facility,
		isTriggeredFromGrid: props.isTriggeredFromGrid,
		authorityOrganizationId: props.facility ? props.facility.authorityId : undefined,
		isSeattleFacility: props.facility
			? String.equalCaseInsensitive(props.facility.authorityName, SeattleAuthorityConstants.authorityName)
			: undefined,
		authorityFeatureFlags: authorityFeatureFlags
	};

	const ModalFooter = () => {
		return (
			<>
				<Button color={'btn ai-action'} onClick={handlerSubmit}>
					{localizationService.getLocalizedString('haulerPortal.buttons.next')}
				</Button>
				<Button color="btn ai-white" onClick={onCancel}>
					Cancel
				</Button>
			</>
		);
	};

	const modalProps = {
		title: localizationService.getLocalizedString('haulerPortal.selectCleaningDevice.submitCleaning'),
		showModal: props.showModal,
		footer: <ModalFooter />,
		cancel: onCancel
	};

	return (
		<>
			{renderDeviceModal && (
				<PopoverModal {...modalProps}>
					<p>
						{localizationService.getLocalizedString('haulerPortal.selectCleaningDevice.selectDevicesHint')}
					</p>
					{deviceList.map((deviceDetail: Extractor, index: number) => {
						const label = (
							<>
								<strong>{deviceDetail.extractorDescription}</strong>
								<span className="d-block mb-1 mt-1">{deviceDetail.extractorType || ''}</span>
								<span className="d-block">{deviceDetail.deviceNumber}</span>
							</>
						);
						return (
							<SingleCheckbox
								key={index}
								id={deviceDetail.deviceNumber as string}
								name={deviceDetail.deviceNumber as string}
								label={label}
								checked={selectedDevices.includes(deviceDetail.deviceNumber as string)}
								onChange={onChange}
							/>
						);
					})}
				</PopoverModal>
			)}
			{showModal && <HaulerSubmitCleaningModal {...submitCleaningModal} />}
		</>
	);
};
export default SelectDevicesModal;
