import { LookupRow } from '../lookup-types';
import { LookupType, GeneralNoticeTemplate } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class GeneralNoticeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: GeneralNoticeTemplate): LookupRow => {
		return {
			lookupId: apiData.noticeTemplateId,
			code: apiData.templateName,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let correctiveAction = {
			noticeTemplateId: rowToUpdate.lookupId,
			templateName: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as GeneralNoticeTemplate;
		return correctiveAction;
	}

	toLookupRowsFromAPI(apiData: GeneralNoticeTemplate[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): GeneralNoticeTemplate {
		let correctiveAction = {
			templateName: newValue.code,
			isActive: newValue.isActive
		} as GeneralNoticeTemplate;
		return correctiveAction;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
