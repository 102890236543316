import React from 'react';
import * as ApiTypes from '@rcp/types';
import { useDispatch, useSelector } from 'react-redux';
import * as actionCreators from 'src/redux/extractor';
import { alertService, RootState, loadFrequencyList, loadUnitList, loadExtractorTypeList } from 'src/redux';
import _ from 'lodash';
import { authoritySettingService, validationService, localizationService, Resource, urlService } from 'src/services';
import {
	TextInput,
	PopoverModal,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	DateInput
} from 'src/components/widgets';
import { DropDownOption, ExtractorType, Frequency, Unit, locationValues } from '@rcp/types';
import { fogDevicesSlice } from 'src/components/authority/fog/fog-devices/devices.slice';
import { facilityDevicesSlice } from 'src/components/authority/fog/fog-devices/facility-devices.slice';

interface OwnProps {
	showExtractorModal: boolean;
	extractor?: ApiTypes.Extractor;
	modalToggleFunction: (e: any) => void;
	facilityId?: number;
	isAddModal: boolean;
}

type Props = OwnProps;

interface FormFields {
	extractorId?: number;
	extractorTypeId?: number;
	extractorTypeIdError?: string;
	extractorDescription?: string;
	extractorDescriptionError?: string;
	mandatoryInstall?: boolean;
	installDate?: string;

	isInadequatelySized?: boolean;
	isImproperlyInstalled?: boolean;
	isRepairNeeded?: boolean;
	isMaintenanceRequired?: boolean;
	numberOfCompartments?: number;
	numberOfCompartmentsError?: string;
	isAdditivesUsed?: boolean;
	percentRemovalEfficiency?: number;
	percentRemovalEfficiencyError?: string;
	location?: string;
	locationError?: string;
	isGreaseLimitsApply?: boolean;

	cleanFrequencyId?: number;
	latitude?: string;
	latitudeError?: string;
	longitude?: string;
	longitudeError?: string;
	trapCapacity?: number;
	trapCapacityError?: string;
	trapCapacityUnitId?: number;
	trapCapacityUnitName?: string;
	trapDepth?: number;
	trapDepthError?: string;
	trapDepthUnitId?: number;
	trapDepthUnitName?: string;
	isActive?: boolean;
	isInactive: boolean;
	verified?: boolean;
	manufacturer?: string;
	model?: string;
	complianceStatus?: string;

	deviceNumber?: string;
	deviceNumberError?: string;
	installDateError?: string;
}

export const ExtractorCardModal: React.SFC<Props> = props => {
	const dispatch = useDispatch();
	const initialFormFields: FormFields = {
		extractorDescription: '',
		mandatoryInstall: false,
		installDate: '',

		isInadequatelySized: false,
		isImproperlyInstalled: false,
		isRepairNeeded: false,
		isMaintenanceRequired: false,
		isAdditivesUsed: false,
		isGreaseLimitsApply: false,
		location: locationValues.inside,
		latitude: '',
		longitude: '',
		isActive: true,
		isInactive: false,
		verified: false,
		manufacturer: '',
		model: '',
		deviceNumber: ''
	};
	const [formState, setFormState] = React.useState(initialFormFields);
	const [extractorTypeOptionValues, setExtractorTypeOptionValues] = React.useState([] as DropDownOption[]);
	const [frequencyOptionValues, setFrequencyOptionValues] = React.useState([] as DropDownOption[]);
	const [unitOptionValues, setUnitOptionValues] = React.useState([] as DropDownOption[]);

	let frequencies = useSelector((state: RootState) => state.extractors.frequencyList);
	let extractorTypes = useSelector((state: RootState) => state.extractors.extractorTypeList);
	let units = useSelector((state: RootState) => state.extractors.unitList);
	const currentUser = useSelector((state: RootState) => state.userProfile);
	const errorMessages = useSelector((state: RootState) => state.alerts);

	const getNewFormFieldState = (extractor: ApiTypes.Extractor): FormFields => {
		const newState: FormFields = { ...extractor, isInactive: !(extractor.isActive ? extractor.isActive : false) };

		return newState;
	};

	React.useEffect(() => {
		dispatch(loadFrequencyList());
		dispatch(loadUnitList());
		dispatch(loadExtractorTypeList());
	}, [dispatch]);

	React.useEffect(() => {
		if (formState.deviceNumber) {
			if (
				errorMessages.messages.length > 0 &&
				errorMessages.messages.find(i => i.message.includes(formState.deviceNumber as string))
			) {
				setFormState({
					...formState,
					deviceNumberError: localizationService.getLocalizedString(
						'extractor.deviceNumReferenceError',
						formState.deviceNumber
					)
				});
			}
		}
	}, [errorMessages]);

	React.useEffect(() => {
		if (props.extractor) {
			const newState: FormFields = getNewFormFieldState(props.extractor);
			setFormState(newState);
		}
		if (!props.extractor) {
			authoritySettingService
				.getNextAutoNumber(ApiTypes.AutoNumberDomainType.Devices)
				.then((nextDeviceNumber: any) => {
					let newState = { ...initialFormFields };
					newState.deviceNumber = nextDeviceNumber as string;
					setFormState(newState);
				});
		}
	}, [props.extractor, props.showExtractorModal]);

	React.useEffect(() => {
		let extractorTypeOptionValues: DropDownOption[] = [];
		if (extractorTypes.length > 0) {
			extractorTypeOptionValues = extractorTypes
				.filter(x => x.isActive)
				.map((extractorType: ExtractorType) => {
					return { label: extractorType.code as string, value: extractorType.lookupId as number };
				});
		}
		setExtractorTypeOptionValues(extractorTypeOptionValues);
	}, [extractorTypes]);

	React.useEffect(() => {
		let frequencyOptionValues: DropDownOption[] = [];
		if (frequencies.length > 0) {
			frequencyOptionValues = frequencies
				.filter(x => x.isActive)
				.map((frequency: Frequency) => {
					return { label: frequency.frequencyCode, value: frequency.frequencyId };
				});
		}
		setFrequencyOptionValues(frequencyOptionValues);
	}, [frequencies]);

	React.useEffect(() => {
		if (props.extractor !== undefined) {
			const extractor = props.extractor;
			let unitOptionValues: DropDownOption[] = [];
			const selectedtrapCapacityUnit = units.find((unit: Unit) => {
				return unit.unitId === extractor.trapCapacityUnitId;
			});
			const selectedtrapDepthUnit = units.find((unit: Unit) => {
				return unit.unitId === extractor.trapDepthUnitId;
			});
			if (units.length > 0) {
				unitOptionValues = units
					.filter((unit: Unit) => unit.isActive)
					.map((unit: Unit) => {
						return { label: unit.name as string, value: unit.unitId };
					});
			}

			if (selectedtrapDepthUnit) {
				unitOptionValues.push({
					label: selectedtrapDepthUnit.name as string,
					value: selectedtrapDepthUnit.unitId,
					isHidden: true
				});
			}

			if (selectedtrapCapacityUnit) {
				unitOptionValues.push({
					label: selectedtrapCapacityUnit.name as string,
					value: selectedtrapCapacityUnit.unitId,
					isHidden: true
				});
			}

			setUnitOptionValues(unitOptionValues);
		} else {
			setUnitOptionValues(
				units
					.filter((unit: Unit) => unit.isActive)
					.map((unit: Unit) => {
						return { label: unit.name as string, value: unit.unitId };
					})
			);
		}
	}, [units, props.extractor, props.showExtractorModal]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (String.equalCaseInsensitive(e.target.type, 'checkbox')) {
			value = e.target.checked;
		}
		_.set(newState, name, value);

		if (String.equalCaseInsensitive(name, 'isInactive')) {
			name = 'isActive';
			value = !value;
			_.set(newState, name, value);
		}

		setFormState(newState);
	};

	const isExtractorTypeValid = (object: any): boolean => {
		if (_.isUndefined(object['extractorTypeId']) || object['extractorTypeId'] < 1) {
			object['extractorTypeIdError'] = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				localizationService.getLocalizedString('extractor.deviceType')
			);
			return false;
		} else {
			_.unset(object, 'extractorTypeIdError');
			return true;
		}
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		isExtractorTypeValid(newState);
		validationService.validateRequiredField(
			newState,
			'deviceNumber',
			'deviceNumberError',
			localizationService.getLocalizedString('extractor.deviceNumber')
		);
		validationService.validateIntegerNumberField(newState, 'numberOfCompartments', 'numberOfCompartmentsError');
		validationService.validateNumberField(newState, 'percentRemovalEfficiency', 'percentRemovalEfficiencyError');
		validationService.validateNumberField(newState, 'trapCapacity', 'trapCapacityError');
		validationService.validateNumberField(newState, 'trapDepth', 'trapDepthError');
		validationService.validateLatitudeField(newState, 'latitude', 'latitudeError', 'extractor.latitude', false);
		validationService.validateLongitudeField(newState, 'longitude', 'longitudeError', 'extractor.longitude', false);
		validationService.validateRequiredField(newState, 'location', 'locationError', 'extractor.location');
		validationService.validateRequiredField(
			newState,
			'extractorDescription',
			'extractorDescriptionError',
			localizationService.getLocalizedString('extractor.extractorDescription')
		);
		validationService.validateMinimumDate(
			newState,
			'installDate',
			'installDateError',
			localizationService.getLocalizedString('extractor.installDate')
		);

		setFormState(newState);
		var isFormValid = false;

		isFormValid = !validationService.hasError(
			newState,
			'deviceNumberError',
			'latitudeError',
			'longitudeError',
			'extractorTypeIdError',
			'numberOfCompartmentsError',
			'percentRemovalEfficiencyError',
			'trapCapacityError',
			'trapDepthError',
			'extractorDescriptionError',
			'locationError',
			'installDateError'
		);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const resetExtractorForm = () => {
		if (props.isAddModal) {
			setFormState(initialFormFields);
		} else {
			if (props.extractor) {
				const newState: FormFields = getNewFormFieldState(props.extractor);
				setFormState(newState);
			}
		}
	};

	const saveExtractor = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let extractorToAddOrUpdate: ApiTypes.Extractor = {
			facilityId: props.facilityId || urlService.getFogFacilityId(),
			extractorTypeId: formState.extractorTypeId || -1,
			extractorDescription: formState.extractorDescription,
			mandatoryInstall: formState.mandatoryInstall,
			installDate: formState.installDate,
			isInadequatelySized: formState.isInadequatelySized,
			isImproperlyInstalled: formState.isImproperlyInstalled,
			isRepairNeeded: formState.isRepairNeeded,
			isMaintenanceRequired: formState.isMaintenanceRequired,
			numberOfCompartments: formState.numberOfCompartments,
			isAdditivesUsed: formState.isAdditivesUsed,
			percentRemovalEfficiency: formState.percentRemovalEfficiency,
			location: formState.location,
			isGreaseLimitsApply: formState.isGreaseLimitsApply,
			cleanFrequencyId: formState.cleanFrequencyId,
			latitude: formState.latitude,
			longitude: formState.longitude,
			trapCapacity: formState.trapCapacity,
			trapCapacityUnitId: formState.trapCapacityUnitId,
			trapDepth: formState.trapDepth,
			trapDepthUnitId: formState.trapDepthUnitId,
			isActive: formState.isActive,
			isRemoved: false,
			manufacturer: formState.manufacturer,
			model: formState.model,
			deviceNumber: formState.deviceNumber,
			verified: formState.verified
		};

		if (props.isAddModal) {
			extractorToAddOrUpdate.sendCleaningNotices = false;
			extractorToAddOrUpdate.sendCleaningNoticesToLastHauler = false;
			dispatch(
				fogDevicesSlice.createOne(
					extractorToAddOrUpdate,
					undefined,
					localizationService.getLocalizedString(
						'alertMessages.addSuccess',
						localizationService.getLocalizedString('extractor.device')
					),
					() => {
						dispatch(actionCreators.loadExtractorList(props.facilityId));
						resetExtractorForm();
						props.modalToggleFunction(e);
					}
				)
			);
		} else {
			if (props.extractor && props.extractor.extractorId) {
				dispatch(
					fogDevicesSlice.patchOne(
						props.extractor.extractorId,
						extractorToAddOrUpdate,
						undefined,
						localizationService.getLocalizedString(
							'alertMessages.updateSuccess',
							localizationService.getLocalizedString('extractor.device')
						),
						() => {
							resetExtractorForm();
							props.modalToggleFunction(e);
						}
					)
				);
			}
		}
	};

	const cancelSaveExtractor = (e: any) => {
		resetExtractorForm();
		props.modalToggleFunction(e);
	};

	const getModalTitle = () => {
		if (props.isAddModal) return localizationService.getLocalizedString('extractor.addDevice');
		else return localizationService.getLocalizedString('extractor.editDevice');
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showExtractorModal}
				title={getModalTitle()}
				save={saveExtractor}
				cancel={cancelSaveExtractor}>
				<TextInput
					id="deviceNumber"
					name="deviceNumber"
					label={localizationService.getLocalizedString('extractor.deviceNumber')}
					value={formState.deviceNumber}
					onChange={changeFormState}
					isRequired
					error={formState.deviceNumberError}
				/>
				<SingleSelectDropdown
					id="extractorTypeId"
					name="extractorTypeId"
					label={localizationService.getLocalizedString('extractor.deviceType')}
					value={_.toString(formState.extractorTypeId)}
					onChange={changeFormState}
					options={extractorTypeOptionValues}
					isRequired={true}
					error={formState.extractorTypeIdError}
				/>
				<div className="form-row">
					<TextInput
						id="manufacturer"
						name="manufacturer"
						label={localizationService.getLocalizedString('extractor.manufacturer')}
						value={formState.manufacturer}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="model"
						name="model"
						label={localizationService.getLocalizedString('extractor.model')}
						value={formState.model}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
				</div>
				<div className="form-group required">
					<label className="mb-1">{localizationService.getLocalizedString('extractor.location')}</label>
					<div className="custom-control custom-radio invite-radio">
						<input
							id="inside"
							type="radio"
							name="location"
							className="custom-control-input"
							value={locationValues.inside}
							checked={String.equalCaseInsensitive(formState.location, locationValues.inside)}
							onChange={changeFormState}
						/>
						<label className="custom-control-label" htmlFor="inside">
							<span>{`${localizationService.getLocalizedString('extractor.inside')}`}</span>
						</label>
					</div>
					<div className="custom-control custom-radio invite-radio">
						<input
							id="outside"
							type="radio"
							name="location"
							className="custom-control-input"
							value={locationValues.outside}
							checked={String.equalCaseInsensitive(formState.location, locationValues.outside)}
							onChange={changeFormState}
						/>
						<label className="custom-control-label" htmlFor="outside">
							<span>{`${localizationService.getLocalizedString('extractor.outside')}`}</span>
						</label>
					</div>
					{formState.locationError && (
						<div className="ai-form-help ai-required">{formState.locationError}</div>
					)}
				</div>
				<TextAreaInput
					id="extractorDescription"
					name="extractorDescription"
					label={localizationService.getLocalizedString('extractor.locationDescription')}
					value={formState.extractorDescription}
					onChange={changeFormState}
					isRequired={true}
					isFullWidth={true}
					error={formState.extractorDescriptionError}
				/>

				<div className="form-row">
					<TextInput
						id="trapCapacity"
						name="trapCapacity"
						type="number"
						label={localizationService.getLocalizedString('extractor.trapCapacity')}
						value={formState.trapCapacity ? '' + formState.trapCapacity : undefined}
						onChange={changeFormState}
						className="form-group col-sm-6"
						error={formState.trapCapacityError}
					/>
					<SingleSelectDropdown
						id="trapCapacityUnitId"
						name="trapCapacityUnitId"
						label={localizationService.getLocalizedString('extractor.unit')}
						value={_.toString(formState.trapCapacityUnitId)}
						onChange={changeFormState}
						options={unitOptionValues}
						isRemovePadding={false}
						className="form-group col-sm-6"
					/>
				</div>

				<div className="form-row">
					<TextInput
						id="trapDepth"
						name="trapDepth"
						type="number"
						label={localizationService.getLocalizedString('extractor.trapDepth')}
						value={formState.trapDepth ? '' + formState.trapDepth : undefined}
						onChange={changeFormState}
						className="form-group col-sm-6"
						error={formState.trapDepthError}
					/>
					<SingleSelectDropdown
						id="trapDepthUnitId"
						name="trapDepthUnitId"
						label={localizationService.getLocalizedString('extractor.unit')}
						value={_.toString(formState.trapDepthUnitId)}
						onChange={changeFormState}
						options={unitOptionValues}
						isRemovePadding={false}
						className="form-group col-sm-6"
					/>
				</div>

				<div className="form-row">
					<SingleSelectDropdown
						id="cleanFrequencyId"
						name="cleanFrequencyId"
						label={localizationService.getLocalizedString('extractor.cleaningFrequency')}
						value={_.toString(formState.cleanFrequencyId)}
						onChange={changeFormState}
						options={frequencyOptionValues}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="numberOfCompartments"
						name="numberOfCompartments"
						type="number"
						label={localizationService.getLocalizedString('extractor.numberOfCompartments')}
						value={formState.numberOfCompartments ? '' + formState.numberOfCompartments : undefined}
						onChange={changeFormState}
						error={formState.numberOfCompartmentsError}
						className="form-group col-sm-6"
					/>
				</div>

				<DateInput
					id="installDate"
					name="installDate"
					className="form-group"
					label={localizationService.getLocalizedString('extractor.installDate')}
					value={formState.installDate}
					error={formState.installDateError}
					onChange={changeFormState}
				/>
				<div className="form-row">
					<TextInput
						id="latitude"
						name="latitude"
						type="number"
						label={localizationService.getLocalizedString('extractor.latitude')}
						value={formState.latitude}
						onChange={changeFormState}
						error={formState.latitudeError}
						className="form-group col-sm-6"
						remainingInputProps={{ min: -90, max: 90 }}
					/>
					<TextInput
						id="longitude"
						name="longitude"
						type="number"
						label={localizationService.getLocalizedString('extractor.longitude')}
						value={formState.longitude}
						onChange={changeFormState}
						error={formState.longitudeError}
						className="form-group col-sm-6"
						remainingInputProps={{ min: -180, max: 180 }}
					/>
				</div>

				<SingleCheckbox
					id="mandatoryInstall"
					name="mandatoryInstall"
					label={localizationService.getLocalizedString('extractor.mandatoryInstall')}
					checked={formState.mandatoryInstall}
					onChange={changeFormState}
					className="div-checkbox"
				/>
				<SingleCheckbox
					id="isAdditivesUsed"
					name="isAdditivesUsed"
					label={localizationService.getLocalizedString('extractor.isAdditivesUsed')}
					checked={formState.isAdditivesUsed}
					onChange={changeFormState}
					className="div-checkbox"
				/>
				<SingleCheckbox
					id="isInactive"
					name="isInactive"
					label={localizationService.getLocalizedString('extractor.isInactive')}
					checked={formState.isInactive}
					onChange={changeFormState}
					className="div-checkbox"
				/>
				{props.extractor && !props.isAddModal && (
					<label className="mt-2" id="addedByLabel">
						{localizationService.getLocalizedString(
							'extractor.deviceAddedBy',
							props.extractor.creatorUserFullName
								? `${props.extractor.creatorUserFullName} ${localizationService.getLocalizedString(
										'extractor.from'
								  )} ${props.extractor.addedBy}`
								: props.extractor.addedBy
						)}
					</label>
				)}
				<SingleCheckbox
					id="verified"
					name="verified"
					label={localizationService.getLocalizedString('extractor.verifiedLabel')}
					checked={formState.verified}
					onChange={changeFormState}
					className="div-checkbox mt-1"
				/>
			</PopoverModal>
		</div>
	);
};
