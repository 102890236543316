import React, { useState } from 'react';
import { localizationService, navigateToRoute } from 'src/services';
import { Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Extractor } from '@rcp/types/src';
import { DeviceTab } from './device-tab.enum';
import { DeviceOverviewTab } from './device-overview-tab';
import { DeviceContactsTab } from './device-contacts-tab';
import { ExtractorTabPage } from '../../facilities/facility-details/extractor-tab-page/extractor-tab-page-component';
interface OwnProps {
	currentDevice: Extractor;
}
type Props = OwnProps;

export const DeviceTabs: React.FC<Props> = props => {
	const [activeTab, setActiveTab] = useState('tab-overview');
	const history = useHistory();
	let { url } = useRouteMatch();
	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	React.useEffect(() => {
		const tab = history.location.pathname.split('/') as string[];
		const urlLength = 4;
		if (tab.length > urlLength) {
			toggle(tab[tab.length - 1]);
		} else {
			toggle(DeviceTab.Overview);
		}
	}, [history.location.pathname]);

	return (
		<section className="info-card">
			<Nav tabs className="material">
				<NavItem>
					<NavLink
						onClick={() => navigateToRoute<DeviceTab>(history, DeviceTab.Overview, url)}
						id={DeviceTab.Overview}
						className={classNames({ active: activeTab === DeviceTab.Overview }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('screen.labels.overview')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						onClick={() => navigateToRoute<DeviceTab>(history, DeviceTab.Contacts, url)}
						id={DeviceTab.Contacts}
						className={classNames({ active: activeTab === DeviceTab.Contacts }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('screen.labels.contactsAndNotices')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						onClick={() => navigateToRoute<DeviceTab>(history, DeviceTab.FacilitiesDevices, url)}
						id={DeviceTab.FacilitiesDevices}
						className={
							classNames({ active: activeTab === DeviceTab.FacilitiesDevices }) + ' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.facilityDevices')}
					</NavLink>
				</NavItem>
			</Nav>
			<div className="mt-1">
				<TabContent activeTab={activeTab}>
					<TabPane tabId={DeviceTab.Overview}>
						<DeviceOverviewTab currentDevice={props.currentDevice} />
					</TabPane>
					<TabPane tabId={DeviceTab.Contacts}>
						{activeTab === DeviceTab.Contacts && <DeviceContactsTab device={props.currentDevice} />}
					</TabPane>
					<TabPane tabId={DeviceTab.FacilitiesDevices}>
						{activeTab === DeviceTab.FacilitiesDevices && (
							<ExtractorTabPage
								facilityId={props.currentDevice.facilityId}
								deviceId={props.currentDevice.extractorId}
							/>
						)}
					</TabPane>
				</TabContent>
			</div>
		</section>
	);
};
