import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { ColorPicker } from '@progress/kendo-react-inputs';
import { localizationService } from 'src/services';
const paletteSettings = {
	palette: ['#000000', '#808080', '#16405B', '#23409A', '#4F0193', '#D5312A', '#520408', '#C5512B', '#008000'],
	columns: 3,
	tileSize: 30
};
const ColorPickerTool = props => {
	const onChange = event => {
		const { view } = props;
		if (view) {
			EditorUtils.applyInlineStyle(view, {
				style: 'color',
				value: event.value
			});
		}
	};
	return (
		<>
			<ColorPicker
				view="palette"
				defaultValue={'black'}
				icon="edit-tools"
				onChange={onChange}
				onMouseDown={e => e.preventDefault()}
				onPointerDown={e => e.preventDefault()}
				paletteSettings={paletteSettings}
				title={localizationService.getLocalizedString('notices.fontColor')}
			/>
		</>
	);
};

export default ColorPickerTool;
