import {
	FacilityContactListActionType,
	FacilityContactAction,
	FacilityContactListState,
	initialFacilityContactListState
} from './contact-actions';

export const facilityContactReducer = (
	state = initialFacilityContactListState,
	action: FacilityContactAction
): FacilityContactListState => {
	switch (action.type) {
		case FacilityContactListActionType.LoadFacilityContactList:
			return { ...state, facilityContactList: action.facilityContactList };
		case FacilityContactListActionType.RemoveContactFromFacility:
			return { ...state };
		case FacilityContactListActionType.DeleteContact:
			return { ...state };
		case FacilityContactListActionType.LoadContactTypeList:
			return { ...state, contactTypeList: action.contactTypeList };
		case FacilityContactListActionType.LoadAuthorityContactList:
			return { ...state, authorityContactList: action.authorityContactList };
		case FacilityContactListActionType.AddContactForFacility:
			return { ...state };
		case FacilityContactListActionType.UpdateContactForFacility:
			return { ...state };
		default:
			return state;
	}
};
