import React, { useRef } from 'react';
import { connect } from 'react-redux';

import * as ApiTypes from '@rcp/types';
import { ApplicationState, alertService } from 'src/redux';
import { localizationService } from 'src/services';
import * as actionCreators from 'src/redux/comment';
import { PopoverModal, DeleteModal } from 'src/components/widgets';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface OwnProps {
	showModal: boolean;
	setShowModal: () => void;
	comment: ApiTypes.Comment;
}

interface DispatchProps {
	editFogFacilityComment: (commentId: number, commentText: string) => Promise<boolean | undefined>;
	deleteFogFacilityComment: (commentId: number) => Promise<boolean | undefined>;
}

type Props = DispatchProps & OwnProps;

const EditCommentModalComponent: React.SFC<Props> = props => {
	const [comment, setComment] = React.useState(props.comment.commentText);
	const defaultTextEle = useRef<HTMLTextAreaElement>(null);
	const [toggleDeleteCommentModal, setToggleDeleteCommentModal] = React.useState(false);
	const [commentError, setCommentError] = React.useState('');

	const onCommentsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};
	const closeModal = () => {
		props.setShowModal();
	};

	const onSaveBtnClick = () => {
		if (comment === '' || comment.trim() === '') {
			let errorTxt = localizationService.getLocalizedString(
				'screen.validationMessage.fieldValueIsRequired',
				'Field'
			);
			setCommentError(errorTxt);
			alertService.addError(errorTxt);
		} else {
			props.editFogFacilityComment(props.comment.commentId as number, comment);
			props.setShowModal();
		}
	};

	const onCancelBtnClick = () => {
		props.setShowModal();
	};

	const deleteComment = async () => {
		setToggleDeleteCommentModal(false);

		if (props.comment) {
			let commentId = props.comment.commentId;
			await props.deleteFogFacilityComment(commentId as number);
		}
		props.setShowModal();
	};

	const modalFooterDiv = () => {
		return (
			<>
				<button
					className="btn ai-secondary-delete"
					onClick={() => {
						setToggleDeleteCommentModal(true);
					}}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
				<div className="ml-auto">
					<button className="btn ai-save" onClick={onSaveBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div>
			<PopoverModal
				className="popup-modal-edit"
				showModal={props.showModal}
				title={localizationService.getLocalizedString('comments.editCommentModalTitle')}
				footer={modalFooterDiv()}
				cancel={closeModal}>
				<div className="form-row">
					<div className="form-group font-size-16px-regular col required">
						<label htmlFor="comment">
							{localizationService.getLocalizedString('screen.labels.comment')}
						</label>
						<textarea
							className={`form-control facility-comment` + (commentError ? ' is-invalid' : '')}
							id="comment"
							ref={defaultTextEle}
							defaultValue={comment || props.comment.commentText}
							onChange={onCommentsChanged}
						/>
						{commentError && <div className="invalid-feedback">{commentError}</div>}
					</div>
				</div>
			</PopoverModal>
			{toggleDeleteCommentModal && (
				<DeleteModal
					key="confirmDeleteCommentModal"
					title={localizationService.getLocalizedString('comments.deleteCommentTitle')}
					message={localizationService.getLocalizedString('comments.deleteCommentMessage')}
					showModal={toggleDeleteCommentModal}
					onCancelButtonClick={() => {
						setToggleDeleteCommentModal(false);
					}}
					onOkayButtonClick={deleteComment}
					okayButtonText={localizationService.getLocalizedString('comments.deleteButton')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		editFogFacilityComment: (commentId: number, commentText: string) =>
			dispatch(actionCreators.editFogFacilityComment(commentId, commentText)),
		deleteFogFacilityComment: (commentId: number) => dispatch(actionCreators.deleteFogFacilityComment(commentId))
	};
};
export const EditCommentModal = connect<{}, DispatchProps, OwnProps, ApplicationState>(
	null,
	mapDispatchToProps
)(EditCommentModalComponent);
