import { CccTestDto, FeatureNames } from '@rcp/types';
import React from 'react';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useReduxSelector } from 'src/redux';
import { localizationService } from 'src/services';
import { TestDetailModal } from '../tests/hazard-test-detail-modal';

interface actionProp {
	clickOnDeleteHazard: () => void;
	deviceId?: number | null;
	scheduledTest: CccTestDto;
}

export const HazardActionDropdown: React.SFC<actionProp> = props => {
	const [testDetailModal, setTestDetailModal] = React.useState(false as boolean);

	const showInDevelopmentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowInDevelopmentFeature]
	);

	let onScheduleTest = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setTestDetailModal(!testDetailModal);
	};

	let toggleTestDetail = () => {
		setTestDetailModal(!testDetailModal);
	};
	return (
		<>
			<UncontrolledDropdown id="actionDropdown" className="action-item-black">
				<DropdownToggle tag="a" className="btn" caret>
					{localizationService.getLocalizedString('screen.labels.action')}
				</DropdownToggle>
				<DropdownMenu>
					{/* {props.deviceId === undefined && (
					<DropdownItem id="addDevice" tag="a" onClick={() => console.log('click')}>
						{localizationService.getLocalizedString('screen.buttons.addDevice')}
					</DropdownItem>
				)} */}
					{props.deviceId && (
						<>
							{showInDevelopmentFeature && (
								<>
									<DropdownItem id="changeDevice" tag="a" onClick={() => console.log('click')}>
										{localizationService.getLocalizedString('screen.buttons.changeDevice')}
									</DropdownItem>
									<DropdownItem id="replaceDevice" tag="a" onClick={() => console.log('click')}>
										{localizationService.getLocalizedString('screen.buttons.replaceDevice')}
									</DropdownItem>
									<DropdownItem id="removeDevice" tag="a" onClick={() => console.log('click')}>
										{localizationService.getLocalizedString('screen.buttons.removeDevice')}
									</DropdownItem>
								</>
							)}
							{!props.scheduledTest && (
								<DropdownItem id="scheduleTest" tag="a" onClick={onScheduleTest}>
									{localizationService.getLocalizedString('screen.buttons.scheduleTest')}
								</DropdownItem>
							)}
						</>
					)}
					{showInDevelopmentFeature && (
						<DropdownItem id="scheduleInstall" tag="a" onClick={() => console.log('click')}>
							{localizationService.getLocalizedString('screen.buttons.scheduleInstall')}
						</DropdownItem>
					)}

					<DropdownItem id="deleteHazardDropdownOption" tag="a" onClick={props.clickOnDeleteHazard}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			{testDetailModal && (
				<TestDetailModal
					isToggle={testDetailModal}
					toggle={toggleTestDetail}
					currentTest={{} as CccTestDto}
					dueDateRequired={true}
					completedResultRequired={false}
					modalTitle={localizationService.getLocalizedString('cccTest.scheduleTest')}
					isScheduledTest={true}
				/>
			)}
		</>
	);
};
