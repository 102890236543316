import React, { useContext, useEffect, useState } from 'react';
import { urlService } from 'src/services';
import { translateService } from 'src/services/translate-service';

import { LanguageContext, TranslateContext } from './translator-context';

export function Translate({
	children: value,
	doNotTranslate: doNotTranslate,
	toLanguageCode: toLanguageCode,
	fromLanguageCode: fromLanguageCode,
	mustTranslateOnAuthorityPortal: mustTranslateOnAuthorityPortal
}: {
	children?: string;
	doNotTranslate?: boolean;
	toLanguageCode?: string;
	fromLanguageCode?: string;
	mustTranslateOnAuthorityPortal?: boolean;
}): JSX.Element {
	const language = useContext(LanguageContext);
	const handleTranslate = useContext(TranslateContext);
	const [translation, setTranslation] = useState('');

	const resolvedDoNotTranslate =
		doNotTranslate == true ||
		!(urlService.isFacilityPortal() || urlService.isAccountPortal() || urlService.isPublicEmailViewScreen());

	useEffect(() => {
		if (value) {
			if (resolvedDoNotTranslate && !mustTranslateOnAuthorityPortal) {
				setTranslation(value);
			} else if (toLanguageCode && fromLanguageCode) {
				translateService
					.translateString(value, false, toLanguageCode, true, fromLanguageCode)
					.then(translatedValue => {
						setTranslation(translatedValue as string);
					});
			} else {
				handleTranslate(value, setTranslation);
			}
		}
	}, [value, language]);

	return resolvedDoNotTranslate && !mustTranslateOnAuthorityPortal ? (
		<span className="notranslate">{translation}</span>
	) : (
		<React.Fragment>{translation}</React.Fragment>
	);
}
