import { QuestionAnswerDtos } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { alertService } from 'src/redux';
import { apiService, localizationService, tokenService, urlService } from 'src/services';
import { Utils } from 'src/services/utils';
import CollapsibleCard from '../widgets/collapsible-card/collapsible-card';
import Question, { QuestionType } from './question';

interface Props {
	onSuccess: () => void;
	onFailure: () => void;
}

const KbqVerification: FC<Props> = props => {
	const [question, setQuestion] = useState([] as any[]);
	const [answer, setAnswer] = useState('');

	useEffect(() => {
		loadQuestion();
		Utils.resetFailedAttemptCounts();
	}, []);

	const loadQuestion = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/Account/KbqChallengeQuestion`;
		let question: QuestionAnswerDtos = await apiService.getResource(url);
		setQuestion([
			{
				questionId: question.questionId,
				questionType: question.questionTypeName,
				content: question.question,
				userQuestionAnswerId: question.userQuestionAnswerId
			}
		]);
	};

	const onsubmit = async () => {
		alertService.clearAllMessages();
		let url = `${urlService.getApiAccountBaseUrl()}/Account/ValidateKbqChallengeAnswer?organizationRegulatoryProgramId=${
			tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
		}`;

		let postData = {
			userQuestionAnswerId: question[0].userQuestionAnswerId,
			questionId: question[0].questionId,
			question: question[0].content,
			answer: answer,
			questionTypeName: 'KBQ'
		};
		if (answer) {
			let response: QuestionAnswerDtos = await apiService.httpPost(url, postData);

			if (
				response.validationErrors &&
				response.validationErrors.length &&
				!response.validationErrors.includes('Account Locked')
			) {
				alertService.addError(
					localizationService.getLocalizedString('ipp.accountPortal.messages.incorrectAnswer')
				);
				props.onFailure();
			}
			if (
				response.validationErrors &&
				response.validationErrors.length &&
				response.validationErrors.includes('Account Locked')
			) {
				props.onFailure();
				//Redirect to signin
				alertService.addError(
					localizationService.getLocalizedString('ipp.accountPortal.messages.accountLocked')
				);
				urlService.signOut();
			} else if (!response.validationErrors || response.validationErrors.length === 0) {
				props.onSuccess();
			}
			setQuestion([
				{
					questionId: response.questionId,
					questionType: response.questionTypeName,
					content: response.question,
					userQuestionAnswerId: response.userQuestionAnswerId
				}
			]);
		} else {
			alertService.addError(
				localizationService.getLocalizedString('ipp.accountPortal.messages.answerFieldEmpty')
			);
		}
	};

	return (
		<>
			<div className="page">
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.accountPortal.knowledgeBasedQuestion')}</h1>
				</div>
				<CollapsibleCard
					key="kbq-questions"
					className="mt-3"
					accordionType="kbq-questions"
					accordionHeading={localizationService.getLocalizedString(
						'ipp.accountPortal.knowledgeBasedQuestion'
					)}>
					<>
						<Question
							questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question')}
							answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer')}
							answer={answer}
							type={QuestionType.KBQ}
							questions={question}
							showEditButton={false}
							answerType="password"
							onChangeAnswer={(e: any) => {
								setAnswer(e.target.value);
							}}
							disableQuestion={true}
						/>

						<div className="form-row mt-1">
							<button className="btn ai-action ml-auto" onClick={onsubmit}>
								{localizationService.getLocalizedString('ipp.accountPortal.submit')}
							</button>
						</div>
					</>
				</CollapsibleCard>
			</div>
		</>
	);
};

export default KbqVerification;
