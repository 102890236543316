import { IppReportPackage } from '@rcp/types';
import React, { FC } from 'react';
import { TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { DateUtilService, localizationService } from 'src/services';

interface Props {
	packageDetails?: IppReportPackage;
	className?: string;
}

const IppPackageFixed: FC<Props> = ({ packageDetails, className }) => {
	return (
		<>
			<CollapsibleCard
				accordionType="report-package-fixed-accordion"
				accordionHeading={localizationService.getLocalizedString(
					`ipp.reportPackage.package.systemErrorsFixedConfirmation`
				)}
				className={className ? className : ''}>
				<div id="fixed-details" className="form-row">
					<TextInput
						id="fixed-by"
						label={localizationService.getLocalizedString('ipp.reportPackage.package.fixedReport.fixedBy')}
						className="col-sm-4"
						name="fixedBy"
						value={
							packageDetails &&
							`${packageDetails.fixedByFirstName} ${packageDetails.fixedByLastName}`.trim()
						}
						isDisabled={true}
					/>
					<TextInput
						id="title"
						label={localizationService.getLocalizedString('ipp.reportPackage.package.fixedReport.title')}
						className="col-sm-4"
						name="title"
						value={packageDetails && packageDetails.fixedByTitleRole}
						isDisabled={true}
					/>
					<TextInput
						id="fixedDate"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.package.fixedReport.fixedDate'
						)}
						className="col-sm-4"
						name="fixedDate"
						value={
							packageDetails &&
							DateUtilService.toDisplayDate(packageDetails.fixedDateTimeLocal) +
								' ' +
								DateUtilService.toDisplayTime(packageDetails.fixedDateTimeLocal)
						}
						isDisabled={true}
					/>
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageFixed;
