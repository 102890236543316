import React from 'react';
import { History } from 'history';

import { localizationService, urlService, navigateTo } from 'src/services';

import './import.scss';
import Stepper, { Step } from '../../../../widgets/stepper';
import { Step1, Step2, Step3 } from './import-steps';
import { ImportSteps } from '@rcp/types';

interface Props {
	history: History;
}

const AuthoritySettingsImportFileComponent: React.FC<Props> = props => {
	const importHistoryPath = urlService.getSettingMenuPath('/import/history');
	return (
		<div className="page import-setting-card">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('screen.labels.import')}</h1>
				<a href={importHistoryPath} onClick={(e: any) => navigateTo(props.history, importHistoryPath, e)}>
					{localizationService.getLocalizedString('screen.labels.importHistory')}
				</a>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section className="min-height-180px px-2 import-file">
						<Stepper vertical={true} initialStep={ImportSteps.STEP1}>
							<Step stepId={ImportSteps.STEP1} title="Choose a file">
								<Step1 />
							</Step>
							<Step stepId={ImportSteps.STEP2} title="Preview">
								<Step2 />
							</Step>
							<Step stepId={ImportSteps.STEP3} title="Results">
								<Step3 />
							</Step>
						</Stepper>
					</section>
				</div>
			</div>
		</div>
	);
};

export const AuthoritySettingsImportFile = AuthoritySettingsImportFileComponent;
