import React, { useEffect, FC, useState } from 'react';
import { PopoverModal as Modal } from 'src/components/widgets';
import { AlertMessageType } from 'src/redux';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft, faChevronRight, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import {
	localizationService,
	validationService,
	urlService,
	navigateTo,
	Resource,
	DateUtilService
} from 'src/services';
import { DeleteModal } from 'src/components';
import { NoticeStatus, PreviewNotice } from '@rcp/types';
import _ from 'lodash';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { FaEllipsisH, FaPencilAlt } from 'react-icons/fa';
import './preview-email-notice.scss';
import { InlineTextInput } from './inline-input';
import { TooltipHover } from './tooltip-hover';

import { useDispatch } from 'react-redux';
import { deleteEmailLog as deleteEmail, deleteEmailLogs, resendEmailLogs } from 'src/redux/facility/email';
import { ResendEmailDto } from '@rcp/types/src';

import { useHistory } from 'react-router';
import { translateService } from 'src/services/translate-service';

interface Props {
	showModal: boolean;
	onToggle: () => void;
	emailDetails: PreviewNotice[];
	hideFooter?: boolean;
	selectedIndex?: number;
	title?: string;
	subtitle?: string;
	noticeStatus?: string;
	isNoticeHistoryGrid?: boolean;
	refresh?: () => void;
}

interface formState {
	to?: string;
	toError?: string;
	from?: string;
	templateSubject?: string;
	cc?: string;
	showEditTo?: boolean;
}

const { Sending, Fail, Sent } = NoticeStatus;
const { Info, Error } = AlertMessageType;
const EmailModal: FC<Props> = props => {
	const history = useHistory();
	const { noticeStatus } = props;
	const [selectedItem, setSelectedItem] = React.useState(props.selectedIndex || 0);
	const [totalItems, setTotalItem] = React.useState(0);
	const [formState, setFormState] = React.useState<formState>({});
	const [emailDetailState, setEmailDetailState] = useState<PreviewNotice[]>([]);
	const [deleteState, setDeleteState] = React.useState({
		showDelete: false,
		deleteAll: false
	});
	const [showDetails, setShowDetails] = React.useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setFormState({ ...props.emailDetails[selectedItem] });
	}, [selectedItem, props.emailDetails]);

	const onClickPrevious = () => {
		if (selectedItem - 1 >= 0) setSelectedItem(selectedItem - 1);
		if (selectedItem - 1 < 0) setSelectedItem(totalItems - 1);
	};

	const onClickNext = () => {
		if (selectedItem + 1 < totalItems) setSelectedItem(selectedItem + 1);
		if (selectedItem + 1 === totalItems) setSelectedItem(0);
	};

	useEffect(() => {
		props.emailDetails && setTotalItem(props.emailDetails.length);
		setEmailDetailState([...props.emailDetails]);
	}, [props.emailDetails]);

	const facilityLink = () => {
		let noticeDetails: PreviewNotice = props.emailDetails[selectedItem];
		let url = urlService.getReactAuthorityResourcePath(Resource.FogFacilities, noticeDetails.organizationId);
		return (
			<a href="#/" className="ai-link" onClick={(event: any) => navigateTo(history, url, event)}>
				{noticeDetails.facilityName} ({noticeDetails.facilityNumber})
			</a>
		);
	};

	const deviceLink = () => {
		let noticeDetails: PreviewNotice = props.emailDetails[selectedItem];
		let url = urlService.getReactAuthorityResourcePath(Resource.Devices, noticeDetails.deviceId);
		return (
			<a href="#/" className="ai-link" onClick={(event: any) => navigateTo(history, url, event)}>
				{noticeDetails.deviceNumber}
			</a>
		);
	};

	const footerContent = () => {
		return (
			<>
				<hr className="preview-email-line" />
				<div className="preview-email-footer mx-1 pb-3">
					<div className={`d-flex align-items-center cursor-pointer`} onClick={onClickPrevious}>
						<FontAwesomeIcon icon={faChevronLeft} className="preview-email-chevron font-size-18px" />
						<span className="pl-3 font-size-16px">
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.previous')}
						</span>
					</div>
					<div className="selected-item">
						{selectedItem +
							1 +
							' ' +
							localizationService.getLocalizedString('authoritySetting.noticesSettings.of') +
							' ' +
							totalItems +
							' ' +
							localizationService.getLocalizedString('authoritySetting.noticesSettings.items')}
					</div>
					<div className={`d-flex align-items-center cursor-pointer`} onClick={onClickNext}>
						<span className="pr-3 font-size-16px">
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
						</span>
						<FontAwesomeIcon icon={faChevronRight} className="preview-email-chevron font-size-18px" />
					</div>
				</div>
			</>
		);
	};

	const resendEmailLog = () => {
		dispatch(
			resendEmailLogs(
				[
					{
						emailLogId: props.emailDetails[selectedItem].emailLogId as number,
						emailOverwrite:
							emailDetailState[selectedItem].to !== props.emailDetails[selectedItem].to
								? emailDetailState[selectedItem].to
								: null
					} as ResendEmailDto
				],
				props.refresh
			)
		);
		props.onToggle();
	};

	const changeDisplay = () => {
		setShowDetails(!showDetails);
	};
	const resendAll = () => {
		dispatch(
			resendEmailLogs(
				emailDetailState.map(
					x =>
						({
							emailLogId: x.emailLogId,
							emailOverwrite: x.to !== props.emailDetails[selectedItem].to ? x.to : null
						} as ResendEmailDto)
				),
				props.refresh
			)
		);
		props.onToggle();
	};

	const deleteAll = () => {
		if (props.emailDetails) {
			dispatch(
				deleteEmailLogs(
					emailDetailState.map(x => x.emailLogId as number),
					props.refresh
				)
			);
		}
		props.onToggle();
	};

	const deleteEmailLog = () => {
		if (emailDetailState[selectedItem].emailLogId) {
			dispatch(deleteEmail(emailDetailState[selectedItem].emailLogId as number, props.refresh));
			props.onToggle();
		}
	};

	const onDeleteConfirmed = () => {
		setDeleteState({ showDelete: false, deleteAll: false });
		deleteState.deleteAll ? deleteAll() : deleteEmailLog();
	};

	const getDeleteModal = () => {
		return (
			<DeleteModal
				title={localizationService.getLocalizedString(
					'noticesHistory.failedNotices.deleteModalTitle',
					deleteState.deleteAll ? 's' : ''
				)}
				showModal={deleteState.showDelete}
				onCancelButtonClick={() => setDeleteState({ showDelete: false, deleteAll: false })}
				isDeleteButton
				okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				onOkayButtonClick={() => onDeleteConfirmed()}
				message={localizationService.getLocalizedString(
					'noticesHistory.failedNotices.deleteModalDesc',
					deleteState.deleteAll ? 's' : ''
				)}
			/>
		);
	};

	const getEllipsisMenu = () => {
		return (
			<>
				<DropdownItem key="resendAll" onClick={resendAll}>
					{localizationService.getLocalizedString('extractor.cleaningNotice.resendAll')}
				</DropdownItem>
				<DropdownItem key="deleteAll" onClick={() => setDeleteState({ showDelete: true, deleteAll: true })}>
					{localizationService.getLocalizedString('extractor.cleaningNotice.deleteAll')}
				</DropdownItem>
			</>
		);
	};
	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'to',
			'toError',
			localizationService.getLocalizedString('authoritySetting.noticesSettings.to')
		);

		newState.to && validationService.validateEmailFormatField(newState, 'to', 'toError');

		setFormState(newState);

		const isFormValid = !validationService.hasError(newState, 'toError');

		return isFormValid;
	};

	const formatDateStringForEmailPreviewDetails = (dateString?: string) => {
		if (dateString) {
			return `${DateUtilService.toDisplayDate(
				dateString,
				localizationService.getLocalizedString('dateFormats.displayDate')
			)} at ${DateUtilService.toDisplayDate(
				dateString,
				localizationService.getLocalizedString('dateFormats.displayTime')
			)}`;
		} else {
			return '';
		}
	};

	const submitEditInput = () => {
		if (isFormValidateForSave()) {
			let newEmailDetailState = _.cloneDeep(emailDetailState);
			newEmailDetailState[selectedItem] = { ...newEmailDetailState[selectedItem], ...formState };
			setEmailDetailState(newEmailDetailState);
			return true;
		}
		return false;
	};

	return (
		<>
			<Modal
				showModal={props.showModal as boolean}
				cancel={() => {
					props.onToggle();
					setSelectedItem(0);
				}}
				title={props.title ? props.title : localizationService.getLocalizedString('screen.buttons.preview')}
				footer={<></>}>
				<>
					{props.isNoticeHistoryGrid && (
						<div className="fieldset cleaning-device-fields mt-0">
							<span id="notice-details" className="cursor-pointer font-size-14px" onClick={changeDisplay}>
								{showDetails
									? localizationService.getLocalizedString('screen.buttons.hideDetails')
									: localizationService.getLocalizedString('screen.buttons.details')}
							</span>
							<div id="notice-description">
								<p>
									<strong>
										{localizationService.getLocalizedString(
											'extractor.cleaningNotice.noticeTemplate'
										)}
									</strong>
									<br />
									{emailDetailState[selectedItem] && emailDetailState[selectedItem].templateName}
								</p>
								<p>
									<strong>
										{localizationService.getLocalizedString(
											'haulerPortal.submitCleaning.deviceNumber'
										)}
									</strong>
									<br />
									{emailDetailState[selectedItem] && deviceLink()}
								</p>
								<p>
									<strong>
										{localizationService.getLocalizedString('haulerPortal.submitCleaning.facility')}
									</strong>
									<br />
									{emailDetailState[selectedItem] &&
										emailDetailState[selectedItem].organizationId !== undefined &&
										facilityLink()}
								</p>
								{showDetails && (
									<>
										<p>
											<strong>
												{localizationService.getLocalizedString('noticesHistory.sentDateTime')}
											</strong>
											<br />
											{emailDetailState[selectedItem] &&
												formatDateStringForEmailPreviewDetails(
													emailDetailState[selectedItem].sentDate
												)}
										</p>
										<p>
											<strong>
												{localizationService.getLocalizedString(
													'noticesHistory.sentByUserFullName'
												)}
											</strong>
											<br />
											{emailDetailState[selectedItem] && emailDetailState[selectedItem].sentBy}
										</p>
										{emailDetailState[selectedItem] && emailDetailState[selectedItem].resentDate && (
											<>
												<p>
													<strong>
														{localizationService.getLocalizedString(
															'noticesHistory.lastResentDateTime'
														)}
													</strong>
													<br />
													{emailDetailState[selectedItem] &&
														formatDateStringForEmailPreviewDetails(
															emailDetailState[selectedItem].resentDate
														)}
												</p>
												<p>
													<strong>
														{localizationService.getLocalizedString(
															'noticesHistory.resentByUserFullName'
														)}
													</strong>
													<br />
													{emailDetailState[selectedItem] &&
														emailDetailState[selectedItem].resentBy}
												</p>
											</>
										)}
									</>
								)}
							</div>
						</div>
					)}

					{noticeStatus == Fail && (
						<div>
							<div className="mb-2">
								<InlineAlertItem
									message={localizationService.getLocalizedString(
										'screen.timeline.failedToSend',
										(emailDetailState[selectedItem] &&
											emailDetailState[selectedItem].errorMessage) ||
											''
									)}
									alertType={Error}
									alertContainerId="preview-modal-message"
								/>
							</div>
							<div className="d-flex justify-content-end mb-2 preview-email-icon">
								<TooltipHover
									id="resend-email"
									icon={faPaperPlane}
									iconClassName="font-awesome-icon mr-3 cursor-pointer"
									iconFontClass="font-size-16px-regular"
									position="bottom"
									title={localizationService.getLocalizedString('extractor.cleaningNotice.resend')}
									isWithoutArrow
									onIconClick={() => resendEmailLog()}
								/>
								<TooltipHover
									id="delete-email"
									icon={faTrashAlt}
									iconClassName="mr-3 cursor-pointer"
									iconFontClass="font-size-16px-regular"
									position="bottom"
									title={localizationService.getLocalizedString('extractor.cleaningNotice.delete')}
									isWithoutArrow
									onIconClick={() => setDeleteState({ showDelete: true, deleteAll: false })}
								/>
								{props.emailDetails.length > 1 && (
									<UncontrolledDropdown className="preview-email-ellipsis">
										<DropdownToggle tag="span">
											<FaEllipsisH className="ai-dark-purple mb-2 cursor-pointer" />
										</DropdownToggle>
										<DropdownMenu>{getEllipsisMenu()}</DropdownMenu>
									</UncontrolledDropdown>
								)}
							</div>
						</div>
					)}
					{emailDetailState[selectedItem] && emailDetailState[selectedItem]!.to
						? renderEmailHeader(
								localizationService.getLocalizedString('authoritySetting.noticesSettings.to'),
								emailDetailState[selectedItem]!.to || '',
								'to',
								'to',
								formState.showEditTo ? true : false,
								'',
								noticeStatus == Fail,
								() =>
									setFormState({
										...formState,
										showEditTo: !formState.showEditTo,
										toError: '',
										to: emailDetailState[selectedItem].to
									}),
								formState.toError
						  )
						: renderEmailHeader(
								localizationService.getLocalizedString('authoritySetting.noticesSettings.to'),
								localizationService.getLocalizedString('haulerPortal.inviteHauler.noEmailFound'),
								'to',
								'to',
								false,
								'api-error'
						  )}
					{emailDetailState[selectedItem] &&
						emailDetailState[selectedItem]!.cc &&
						renderEmailHeader(
							localizationService.getLocalizedString('authoritySetting.noticesSettings.cc'),
							emailDetailState[selectedItem]!.cc || '',
							'cc',
							'cc',
							false
						)}
					{renderEmailHeader(
						localizationService.getLocalizedString('authoritySetting.noticesSettings.from'),
						(emailDetailState[selectedItem] && emailDetailState[selectedItem]!.from) || '',
						'from',
						'from',
						false
					)}
					{renderEmailHeader(
						localizationService.getLocalizedString('authoritySetting.noticesSettings.subject'),
						emailDetailState[selectedItem] &&
							translateService.removeDoNotTranslateHtmlFromSubject(
								emailDetailState[selectedItem]!.templateSubject || ''
							),
						'templateSubject',
						'templateSubject',
						false
					)}
					<div
						id="email-content"
						className="mt-2"
						dangerouslySetInnerHTML={{
							__html:
								(emailDetailState[selectedItem] && emailDetailState[selectedItem]!.templateContent) ||
								''
						}}
					/>
					{!props.hideFooter && totalItems > 1 && footerContent()}
				</>
			</Modal>
			{deleteState.showDelete && getDeleteModal()}
		</>
	);

	function renderEmailHeader(
		label: string,
		value: string,
		id: string,
		name: string,
		showEditInputField: boolean,
		valueClass?: string,
		showEdit?: boolean,
		toggleEditInput?: () => void,
		error?: string
	) {
		return (
			<div id={`${_.toLower(label)}`} className="d-flex align-items-start mb-1">
				<strong className={`mr-1 ${showEdit ? 'mt-2' : ''}`}>{label + ':'}</strong>
				{showEdit ? (
					showEditInputField ? (
						<>
							<InlineTextInput
								id={id}
								name={name}
								value={formState[name as 'to' | 'from' | 'templateSubject']}
								onChange={changeFormState}
								onClear={toggleEditInput}
								onSubmit={submitEditInput}
								error={error}
							/>
						</>
					) : (
						<div className="label-email-header-wrapper">
							<label className={valueClass + ' text-break'}>{' ' + value}</label>
							<FaPencilAlt className="ai-dark-purple ml-2 cursor-pointer" onClick={toggleEditInput} />
						</div>
					)
				) : (
					<label className={valueClass + ' label-email-header'}>{' ' + value}</label>
				)}
			</div>
		);
	}
};

export default EmailModal;
