import React from 'react';
import { useHistory } from 'react-router-dom';
import * as ApiTypes from '@rcp/types';
import { localizationService, DateUtilService, navigateTo } from 'src/services';
import { PopoverModal } from 'src/components/widgets';
import _ from 'lodash';

import './import.scss';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { AlertMessageType } from 'src/redux';
import { DataImport } from '@rcp/types';
import { ImportUtils } from 'src/services/import-utils';

interface OwnProps {
	importHistory: ApiTypes.Import;
}

type Props = OwnProps;

const AuthoritySettingsImportHistoryResultComponent: React.SFC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const showResultModal = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const modalFooterDiv = () => {
		return <></>;
	};

	const importErrorContent = () => {
		return (
			<>
				{localizationService.getLocalizedString(
					'importFile.step3ErrorMessagePart1',
					_.toString(props.importHistory.numberOfErrorRows)
				)}{' '}
				<a href={props.importHistory.errorFileUrl} target="_blank" rel="noopener noreferrer">
					{localizationService.getLocalizedString('importFile.step3ErrorMessagePart2')}
				</a>{' '}
				{localizationService.getLocalizedString('importFile.step3ErrorMessagePart3')}
			</>
		);
	};

	const history = useHistory();

	const getImportHistoryToDisplay = (domainType: string) => {
		const importHistory = props.importHistory.importHistories.find(x => x.domainType === domainType);

		return importHistory;
	};

	const getImportResultLink = (toUpdateGrid: boolean) => {
		let importGridPartialPath = '';
		let importedRowsCount = 0;
		let domainObjectLabel = '';
		switch (props.importHistory.dataType) {
			case DataImport.ImportDataTypeValues.FogFacilitiesAndContacts:
				importGridPartialPath = 'facilities';
				const facilityImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogFacility
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? facilityImportHistory.numberOfUpdatedObject
					: facilityImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogFacility,
					importedRowsCount
				);
				break;
			case DataImport.ImportDataTypeValues.FogDevices:
				importGridPartialPath = 'devices';
				const deviceImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogDevice
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? deviceImportHistory.numberOfUpdatedObject
					: deviceImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogDevice,
					importedRowsCount
				);
				break;
			case DataImport.ImportDataTypeValues.FogInspections:
				importGridPartialPath = 'inspections';
				const inspectionsImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogInspection
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? inspectionsImportHistory.numberOfUpdatedObject
					: inspectionsImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogInspection,
					importedRowsCount
				);
				break;
			case DataImport.ImportDataTypeValues.FogHaulers:
				importGridPartialPath = 'haulers';
				const haulerImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogHauler
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? haulerImportHistory.numberOfUpdatedObject
					: haulerImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogHauler,
					importedRowsCount
				);
				break;
			case DataImport.ImportDataTypeValues.FogCleanings:
				importGridPartialPath = 'cleanings';
				const cleaningImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogCleaning
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? cleaningImportHistory.numberOfUpdatedObject
					: cleaningImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogCleaning,
					importedRowsCount
				);
				break;
			case DataImport.ImportDataTypeValues.FogEvents:
				importGridPartialPath = 'events';
				const eventsImportHistory = getImportHistoryToDisplay(
					DataImport.ImportEntity.FogEvent
				) as ApiTypes.ImportHistory;
				importedRowsCount = toUpdateGrid
					? eventsImportHistory.numberOfUpdatedObject
					: eventsImportHistory.numberOfNewObject;
				domainObjectLabel = ImportUtils.getImportEntityLabel(
					DataImport.ImportEntity.FogEvent,
					importedRowsCount
				);
				break;
			default:
				throw new Error(`Not implemented for import data type ${props.importHistory.dataType}`);
		}

		const importHistoryLink = `/fog/settings/import/history/${
			props.importHistory.importId
		}/${importGridPartialPath}/${toUpdateGrid ? 'updated' : 'new'}`;

		if (importedRowsCount < 1) {
			return <></>;
		}

		return (
			<div className="text-center">
				<a href={importHistoryLink} onClick={(e: any) => navigateTo(history, importHistoryLink, e)}>
					{localizationService.getLocalizedString(
						toUpdateGrid ? 'import.steps.viewImportUpdatedItems' : 'import.steps.viewImportNewItems',
						_.toString(importedRowsCount),
						domainObjectLabel
					)}
				</a>
			</div>
		);
	};

	const hasImportHistoryToDisplay = () => {
		const importHistoryEntity = ImportUtils.getImportHistoryEntityFromImportDataType(props.importHistory.dataType);
		return props.importHistory.importHistories.length > 0 && getImportHistoryToDisplay(importHistoryEntity);
	};

	const getNoEntityAddedOrUpdatedSection = () => {
		const importHistoryEntity = ImportUtils.getImportHistoryEntityFromImportDataType(props.importHistory.dataType);
		const emptyMessage = localizationService.getLocalizedString(
			'importHistory.NoEntityAddedOrUpdated',
			_.toLower(ImportUtils.getImportEntityLabel(importHistoryEntity, 2))
		);

		return <div className="text-center">{emptyMessage}</div>;
	};

	return (
		<>
			<a href="#/" onClick={showResultModal}>
				{localizationService.getLocalizedString('importHistory.view')}
			</a>

			{showModal && (
				<div className="w-100">
					<PopoverModal
						showModal={showModal}
						title={localizationService.getLocalizedString('importHistory.results')}
						footer={modalFooterDiv()}
						cancel={closeModal}
						className="import-history-grid-modal">
						<div className="w-100 d-flex flex-column justify-content-center import-file">
							<p className="text-center">
								<a href={props.importHistory.originalFileUrl} target="_blank" rel="noopener noreferrer">
									{props.importHistory.fileName}
								</a>{' '}
								{localizationService.getLocalizedString(
									'importHistory.importedBy',
									DateUtilService.toDisplayDate(props.importHistory.creationDateTimeUtc),
									DateUtilService.toDisplayTime(props.importHistory.creationDateTimeUtc),
									props.importHistory.uploaderFullName || ''
								)}
							</p>
							{props.importHistory.numberOfErrorRows > 0 && (
								<div className="d-flex justify-content-center">
									<InlineAlertItem
										message={importErrorContent()}
										alertType={AlertMessageType.Error}
									/>
								</div>
							)}

							{props.importHistory.numberOfSuccessRows > 0 && (
								<div className="d-flex justify-content-center">
									<InlineAlertItem
										message={localizationService.getLocalizedString(
											'importFile.step3SuccessMessage',
											_.toString(props.importHistory.numberOfSuccessRows)
										)}
										alertType={AlertMessageType.Success}
									/>
								</div>
							)}

							{hasImportHistoryToDisplay() ? (
								<>
									{props.importHistory.numberOfSuccessRows > 0 && getImportResultLink(true)}
									{props.importHistory.numberOfSuccessRows > 0 && getImportResultLink(false)}
								</>
							) : (
								getNoEntityAddedOrUpdatedSection()
							)}
						</div>
					</PopoverModal>
				</div>
			)}
		</>
	);
};

export const AuthoritySettingsImportHistoryResult = AuthoritySettingsImportHistoryResultComponent;
