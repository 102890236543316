import React, { useContext, useEffect, useState } from 'react';
import { localizationService, urlService } from 'src/services';
import { useReduxSelector } from 'src/redux';
import { FeatureNames } from '@rcp/types';
import { Translate } from 'src/components/widgets/translate/translator';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { translateService } from 'src/services/translate-service';

interface OwnProps {}
type FeedbackFormProps = OwnProps;

export const HeaderFeedbackForm: React.SFC<FeedbackFormProps> = props => {

	const showFeedBackForm = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.FeedBackForm]
	);

	if (!showFeedBackForm) {
		return <></>;
	}

	return (
		<div>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://docs.google.com/forms/d/e/1FAIpQLScdNAwjAkjhRNtsynzBsxF7KZhjw1hXro_WC--a2O4h2y7Bnw/viewform?usp=sf_link">
				<img id="feedback-img" src="img/icons/feedback.svg" alt="" />
				<br />
				<span className="header-title">
					<Translate>{localizationService.getLocalizedString('screen.labels.feedback')}</Translate>
				</span>
			</a>
		</div>
	);
};
