import React from 'react';

interface Props {
	posClass?: string;
	pathClass?: string;
	title?: string;
	size?: number;
	hasSlateFill?: boolean;
	fillColor?: string;
}

export const GreaseTrap: React.SFC<Props> = props => {
	const pStyle = {
		fill: props.hasSlateFill ? '#4b6a88' : props.fillColor ? props.fillColor : 'white'
	};

	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			style={pStyle}>
			<title>{props.title}</title>
			<path
				style={pStyle}
				d="M15.62,6.85H14v-2H2v2H.38A.37.37,0,0,0,0,7.22V9.47a.38.38,0,0,0,.38.38H2v3.62a.36.36,0,0,0,.32.38H13.68a.35.35,0,0,0,.32-.37V9.85h1.62A.38.38,0,0,0,16,9.47V7.22A.37.37,0,0,0,15.62,6.85Z"
			/>
			<rect style={pStyle} x="1" y="1.85" width="14" height="2" rx="0.38" />
		</svg>
	);
};
