import { AutoNumberDomainType, LookupType } from '@rcp/types';
import React from 'react';
import { ListSetting, SettingAction } from 'src/components/widgets';
import { localizationService } from 'src/services';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { AutoNumberSetting } from '../../../shared/settings/auto-number';
import { DeviceCleaningFrequencySettingComponent } from './device-cleaning-event-frequency';
import { NewDeviceCleaningFrequencySettingComponent } from './new-device-cleaning-event-frequency';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { WasteTypeLookRowDataConverter } from '../../../../widgets/lookup/data-converters/waste-type-data-converters';

const DevicesSettingsComponent: React.FunctionComponent = () => {
	return (
		<div className="page">
			<div className="page-header">
				<BackArrow />
				<h1>{localizationService.getLocalizedString('authoritySetting.devices')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">
					<>
						<DeviceCleaningFrequencySettingComponent />
						<hr />
					</>
					<>
						<NewDeviceCleaningFrequencySettingComponent />
						<hr />
					</>

					<AutoNumberSetting
						title={localizationService.getLocalizedString('authoritySetting.deviceNumber')}
						autoNumbersLabel={localizationService.getLocalizedString('authoritySetting.deviceNumbers')}
						autoNumberType={AutoNumberDomainType.Devices}
					/>

					<hr />
					<ListSetting
						title={localizationService.getLocalizedString('authoritySetting.deviceTypeSection')}
						description={localizationService.getLocalizedString(
							'authoritySetting.deviceTypeSectionDescription'
						)}
						settingId="extractorType"
						settingLabel={localizationService.getLocalizedString('authoritySetting.deviceTypeLabel')}
						settingAction={new SettingAction(LookupType.ExtractorTypes)}
					/>

					<hr />
					{/* <LookupSetting
						title={localizationService.getLocalizedString('authoritySetting.disposalLocation')}
						description={localizationService.getLocalizedString(
							'authoritySetting.disposalLocationDescription'
						)}
						settingId="LookupType.disposalLocation"
						settingLabel={localizationService.getLocalizedString('authoritySetting.disposalLocation')}
						lookupType={LookupType.FogDeviceCleaningDisposalLocation}
						hideActiveStatus={true}
					/> */}

					<ListSetting
						title={localizationService.getLocalizedString('authoritySetting.wasteType')}
						settingId="wasteType"
						description={localizationService.getLocalizedString('authoritySetting.wasteTypeDescription')}
						settingLabel={localizationService.getLocalizedString('authoritySetting.wasteType')}
						editDescription={true}
						settingAction={
							new SettingAction(
								LookupType.WasteType,
								undefined,
								new WasteTypeLookRowDataConverter(LookupType.WasteType)
							)
						}
					/>
				</div>
			</div>
		</div>
	);
};

export const DeviceSettingComponent = DevicesSettingsComponent;
