import { DateUtilService } from 'src/services';
import { MonthOffset } from '@rcp/types';
import { TimelineType } from './timeline-event';

export enum TimelineFilterByEventTypeEnum {
	comments = 'comments',
	phoneCalls = 'phoneCalls',
	emails = 'emails',
	inspections = 'inspections',
	pumpOutEvents = 'pumpOutEvents',
	violations = 'violations',
	enforcements = 'enforcements',
	tests = 'tests',
	sentNotices = 'sentNotices',
	sentCleaningNotices = 'sentCleaningNotices',
	sentGeneralNotices = 'sentGeneralNotices',
	cleanings = 'cleanings',
	deviceEmailLogs = 'deviceEmailLogs'
}

export interface TimeLineEventData {
	sortDateTime: string;
	sortPriority: number;
	showTime: boolean;
	icon: JSX.Element;
	iconBackgroundCss?: string;
	backgroundColor?: string;
	content: JSX.Element;
	eventType: TimelineType;
}

export interface TimelineSettings {
	version: string;
	searchText: string;
	excludeList: string[];
	startDateIndex?: number;
	endDateIndex?: number;
	startDate: string;
	endDate: string;
	startOfTime: boolean;
	endOfTime: boolean;
	startDateError?: string;
	endDateError?: string;
}

export const GetInitialTimelineSettings = (): TimelineSettings => {
	return {
		version: '1.1', //Whenever change TimelineFilterByForm schema, let's bump the version, and discard browser localStorage saved filter
		searchText: '',
		excludeList: [],
		startDateIndex: MonthOffset.OneYear,
		endDateIndex: MonthOffset.ThreeMonths,
		startDate: DateUtilService.getRelativeDate(MonthOffset.OneYear, -1),
		endDate: DateUtilService.getRelativeDate(MonthOffset.ThreeMonths, 1),
		startOfTime: false,
		endOfTime: false
	};
};
