import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../alert/alert-service';
import _ from 'lodash';
import { MaxInteger } from '@rcp/types';

export interface FacilityCallLogsState {
	facilityCallLogs: ApiTypes.CallLog[];
}

export const initialFacilityCallLogsState: FacilityCallLogsState = {
	facilityCallLogs: []
};

export type CallLogThunkAction = ThunkAction<any, FacilityCallLogsState, any, Action>;

export enum CallLogActionType {
	SaveCallLogRequest = 'saveCallLogRequest',
	SaveCallLogSucceed = 'saveCallLogSucceed',
	EditCallLogSucceed = 'editCallLogSucceed',
	DeleteCallLogSucceed = 'deleteCallLogSucceed',
	ReceivedCallLogsType = 'receivedCallLogs'
}

const createFacilityCallLogURL = (callLogId?: number): string => {
	let facilityId = urlService.getFogFacilityId();
	return urlService.getFacilityResourceApiUrl(facilityId, Resource.CallLogs, callLogId);
};

export const saveFogFacilityCallLog = (
	commentText: string,
	callDateTime: string,
	isUserProvidedTime: boolean
): CallLogThunkAction => async (dispatch: any) => {
	let callLogToCreate: ApiTypes.CallLog = {
		commentText: commentText,
		callLogDateTime: callDateTime,
		isUserProvidedTime: isUserProvidedTime
	};
	const facilityCallLogUrl = createFacilityCallLogURL();
	const callLogSaved = await apiService.postResource<ApiTypes.CallLog>(facilityCallLogUrl, callLogToCreate);
	dispatch({ type: CallLogActionType.SaveCallLogSucceed, callLogSaved });
	alertService.addSuccess(localizationService.getLocalizedString('callLog.callLogCreatedSuccessfully'));
	return true;
};

export const editFogFacilityCallLog = (
	callLogId: number,
	commentText: string,
	callDateTime: string,
	isUserProvidedTime: boolean
): CallLogThunkAction => async dispatch => {
	let callLogToUpdate: ApiTypes.CallLog = {
		commentText: commentText,
		callLogDateTime: callDateTime,
		isUserProvidedTime: isUserProvidedTime
	};
	let facilityCommentUrl = createFacilityCallLogURL(callLogId);

	let callLogEdited = await apiService.patchResource(facilityCommentUrl, callLogToUpdate);
	dispatch({ type: CallLogActionType.EditCallLogSucceed, callLogEdited });
	alertService.addSuccess(localizationService.getLocalizedString('callLog.callLogUpdatedSuccessfully'));

	return true;
};

export const deleteFogFacilityCallLog = (callLogId: number): CallLogThunkAction => async dispatch => {
	let facilityCommentUrl = createFacilityCallLogURL(callLogId);

	await apiService.deleteResource(facilityCommentUrl);
	dispatch({ type: CallLogActionType.DeleteCallLogSucceed, callLogId });
	alertService.addSuccess(localizationService.getLocalizedString('callLog.callLogDeletedSuccessfully'));

	return true;
};

export const loadFacilityCallLogs = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityId();
	if (!facilityId || facilityId < 1) return;

	let queryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		queryParams.put('callLogDateTime', `gte:${startDate}`).put('callLogDateTime', `lte:${endDate}`);
	}
	const callLogsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.CallLogs);
	const callLogsResult = await apiService.httpGet(`${callLogsUrl}?${queryParams.toQueryString()}`);
	const facilityCallLogs = callLogsResult.result;

	dispatch({ type: CallLogActionType.ReceivedCallLogsType, facilityCallLogs });
};

export const facilityCallLogsReducer = (state = initialFacilityCallLogsState, action: any): FacilityCallLogsState => {
	let callLogs = [] as ApiTypes.CallLog[];
	callLogs = callLogs.concat(state.facilityCallLogs);
	switch (action.type) {
		case CallLogActionType.ReceivedCallLogsType:
			return { ...state, facilityCallLogs: action.facilityCallLogs };
		case CallLogActionType.SaveCallLogSucceed:
			callLogs.unshift(action.callLogSaved);
			return { ...state, facilityCallLogs: callLogs };
		case CallLogActionType.EditCallLogSucceed:
			for (var index in callLogs) {
				if (callLogs[index].callLogId === action.callLogEdited.callLogId) {
					callLogs[index] = action.callLogEdited;
				}
			}
			return { ...state, facilityCallLogs: callLogs };
		case CallLogActionType.DeleteCallLogSucceed:
			let updatedCallLogs: ApiTypes.CallLog[] = [];
			for (var callLog of callLogs) {
				if (callLog.callLogId !== action.callLogId) {
					updatedCallLogs.push(callLog);
				}
			}
			return { ...state, facilityCallLogs: updatedCallLogs };
		default:
			return { ...state };
	}
};
