import React from 'react';
import _ from 'lodash';
import AttachmentProps from './attachment-props';

import { fileUtilService } from 'src/services';
import { AttachmentIcon } from './attachment-icon';

import { VideoPlayer } from './video-player';

export const AttachmentPreview: React.SFC<AttachmentProps> = props => {
	const fileType = _.toLower(props.attachment.fileType);

	if (fileUtilService.isPicture(fileType)) {
		return (
			<div className="attachment-preview-image d-flex">
				<img src={props.attachment.s3ResourceUrl} className={props.className} alt={props.attachment.fileName} />
			</div>
		);
	} else if (fileUtilService.isVideo(fileType)) {
		return (
			<VideoPlayer
				src={props.attachment.s3ResourceUrl as string}
				type={props.attachment.mediaType as string}
				className={props.className}
			/>
		);
	} else if (fileUtilService.isAudio(fileType)) {
		let audioDiv = `<audio controls>
            <source src="${props.attachment.s3ResourceUrl}" type="${props.attachment.mediaType}">
            Your browser does not support the audio element.
        </audio>`;
		return <div dangerouslySetInnerHTML={{ __html: audioDiv }} />;
	} else {
		return (
			<div className="attachment-preview-file d-flex">
				<div className="mx-auto align-self-center">
					<AttachmentIcon attachment={props.attachment} className="attachment-preview-icon" />
					<div className="attachment-preview-file-type">
						<a href={props.attachment.s3ResourceUrl} target="_blank" rel="noopener noreferrer">
							{props.attachment.fileType}
						</a>
					</div>
				</div>
			</div>
		);
	}
};
