import React from 'react';

import { DateUtilService, localizationService, validationService } from 'src/services';
import { DateInput, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { InspectionEvent, DropDownOption } from '@rcp/types';
import _ from 'lodash';
import { alertService } from 'src/redux';

interface OwnProps {
	formState: InspectionEventFormState;
	changeFormState: (e: any) => void;
	inspectionTypeOptions: DropDownOption[];
	isFormDisabled: boolean;
	authorityUserOptions: DropDownOption[];
	isPatch?: boolean;
	isInspectorOptionsSelfOrdered?: boolean;
	isStartInspection?: boolean;
	isReadOnly?: boolean;
}

interface InspectionEventFormState extends InspectionEvent {
	inspectionTypeIdError?: string;
	dueDateError?: string;
	completeDateError?: string;
}
type Props = OwnProps;
export const validateInspectionForSave = (
	formState: InspectionEventFormState,
	setFormState: (f: InspectionEventFormState) => void,
	isPatch?: boolean
): boolean => {
	let newState = { ...formState };
	let isFormValid = false;
	if (!isPatch) {
		validationService.validateRequiredField(
			newState,
			'inspectionTypeId',
			'inspectionTypeIdError',
			'inspection.inspectionType'
		);
		validationService.validateRequiredDateField(newState, 'dueDate', 'dueDateError', 'inspection.dueDate');
		formState.originalSubmissionDate &&
			validationService.validateRequiredDateField(
				newState,
				'completeDate',
				'completeDateError',
				'inspection.completeDate'
			);
		isFormValid = !validationService.hasError(
			newState,
			'inspectionTypeIdError',
			'dueDateError',
			'completeDateError'
		);

		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
	} else {
		isFormValid = isAtLeastOneFieldDefined(newState);
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('alertMessages.noValuesProvided'));
		}
	}
	return isFormValid;
};

const isAtLeastOneFieldDefined = (newState: any): boolean => {
	let dueDate = newState.dueDate;
	let completeDate = newState.completeDate;
	let inspectorId = newState.inspectorId;
	let inspectionTypeId = newState.inspectionTypeId;
	return (
		dueDate !== undefined ||
		inspectorId !== undefined ||
		inspectionTypeId !== undefined ||
		completeDate !== undefined
	);
};

export const InspectionEventInputFields: React.SFC<Props> = props => {
	return (
		<>
			{props.isReadOnly ? (
				<>
					<TextInput
						id="inspectionTypeId"
						name="inspectionTypeId"
						className="form-group input-single-line"
						label={localizationService.getLocalizedString('inspection.inspectionType')}
						isRequired={!props.isReadOnly}
						value={_.toString(props.formState.inspectionType)}
						isDisabled={props.isReadOnly}
					/>
					<TextInput
						id="dueDate"
						name="dueDate"
						className="form-group input-single-line"
						label={localizationService.getLocalizedString('inspection.dueDate')}
						isRequired={!props.isPatch}
						value={DateUtilService.toDisplayDate(props.formState.dueDate)}
						error={props.formState.dueDateError}
						isDisabled={props.isReadOnly}
					/>
					{props.formState.originalSubmissionDate && (
						<TextInput
							id="completeDate"
							name="completeDate"
							className="form-group input-single-line"
							label={localizationService.getLocalizedString('inspection.completeDate')}
							isRequired={!props.isPatch}
							value={DateUtilService.toDisplayDate(props.formState.completeDate)}
							error={props.formState.completeDateError}
							isDisabled={props.isReadOnly}
						/>
					)}
					<TextInput
						id="inspectorId"
						name="inspectorId"
						label={localizationService.getLocalizedString('inspection.inspectorName')}
						value={_.toString(props.formState.inspectorName)}
						className="form-group input-single-line"
						isRequired={!props.isPatch}
						error={props.formState.dueDateError}
						isDisabled={props.isReadOnly}
					/>
				</>
			) : (
				<>
					{props.isPatch ? (
						<p>{localizationService.getLocalizedString('inspection.batchPatchInspectionModalSubtitle')}</p>
					) : null}
					<SingleSelectDropdown
						id="inspectionTypeId"
						name="inspectionTypeId"
						label={localizationService.getLocalizedString('inspection.inspectionType')}
						value={_.toString(props.formState.inspectionTypeId)}
						isRequired={!props.isPatch}
						onChange={props.changeFormState}
						options={props.inspectionTypeOptions}
						error={props.formState.inspectionTypeIdError}
						isDisabled={props.isFormDisabled}
					/>
					{props.isStartInspection ? null : (
						<>
							<DateInput
								id="dueDate"
								name="dueDate"
								label={localizationService.getLocalizedString('inspection.dueDate')}
								isRequired={!props.isPatch}
								value={props.formState.dueDate}
								onChange={props.changeFormState}
								error={props.formState.dueDateError}
								isDisabled={props.isFormDisabled}
							/>
							{props.formState.originalSubmissionDate && (
								<DateInput
									id="completeDate"
									name="completeDate"
									label={localizationService.getLocalizedString('inspection.completeDate')}
									isRequired={!props.isPatch}
									max={DateUtilService.getAuthorityTimezoneNow()}
									value={props.formState.completeDate}
									onChange={props.changeFormState}
									error={props.formState.completeDateError}
									isDisabled={props.isFormDisabled}
								/>
							)}
							<SingleSelectDropdown
								id="inspectorId"
								name="inspectorId"
								label={localizationService.getLocalizedString('inspection.inspectorName')}
								value={_.toString(props.formState.inspectorName)}
								onChange={props.changeFormState}
								options={props.authorityUserOptions}
								isDisabled={props.isFormDisabled}
								selfOrder={props.isInspectorOptionsSelfOrdered}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
