import { IncidentContributor, IncidentContributorEntityType, MaxInteger } from '@rcp/types';
import _ from 'lodash';
import { apiService, QueryParameters, Resource, urlService } from 'src/services';

export interface IncidentEventsState {
	facilityIncidentEvents: IncidentContributor[];
}

export const initialIncidentEventsState: IncidentEventsState = {
	facilityIncidentEvents: []
};

enum IncidentEventActionType {
	LoadFacilityIncidentEvents = 'LoadFacilityIncidentEventsSucceed'
}

export const loadFacilityIncidentEvents = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	let incompleteIncidentQueryParams = new QueryParameters().put('size', MaxInteger);

	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		incompleteIncidentQueryParams.put('reportedDate', `betw:${startDate},${endDate}`);
	}
	incompleteIncidentQueryParams.put('entityType', IncidentContributorEntityType.FogFacility);
	incompleteIncidentQueryParams.put('entityId', facilityId);

	const facilityIncidentEventsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.IncidentContributors}`;

	const { result } = await apiService.httpGet(
		`${facilityIncidentEventsUrl}?${incompleteIncidentQueryParams.toQueryString()}`
	);

	dispatch({
		type: IncidentEventActionType.LoadFacilityIncidentEvents,
		facilityIncidentEvents: result
	});
};

export const incidentEventsReducer = (state = initialIncidentEventsState, action: any): IncidentEventsState => {
	switch (action.type) {
		case IncidentEventActionType.LoadFacilityIncidentEvents:
			return {
				...state,
				facilityIncidentEvents: action.facilityIncidentEvents
			};
		default:
			return { ...state };
	}
};
