import React, { FC } from 'react';
import { History } from 'history';
import './sites.scss';
import { siteSlice } from './sites.slice';
import * as LinkoTypes from '@rcp/types';
import { CccSite, ColumnField, Dictionary, FilterType, LocalStorageName, CustomFieldApplyToTable } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { customFieldQueries, DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService, navigateTo, Resource, urlService, UtilService } from 'src/services';
import { CccSiteAddModal } from './Details/site-add-modal';
import { filterService, FilterDomain, FilterDomainName } from '../../../../features/filter/filter-service';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccSites: FC<Props<CccSite>> = props => {
	const [showAddModal, setAddShowModal] = React.useState(false);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const siteNumberToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.CccSite;
		const url = urlService.getReactAuthorityResourceUrl(Resource.CccSites, dataItem.siteId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.siteNumber}
				</a>
			</td>
		);
	};

	const getTitle = (): string => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'cccSite.sites');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'cccSite.sites');
		}
		return localizationService.getLocalizedString('cccSite.sites');
	};

	const composeGridQueryParameter = (): Dictionary<string> | undefined => {
		if (props.match.params.importId) {
			let importId = props.match.params.importId.toString();
			let importType = window.location.pathname.includes('/new') ? 'new' : 'update';
			if (importId && importType) {
				return { importId: importId, importType: importType } as Dictionary<string>;
			}
		}
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'cccSite',
		storageName: LocalStorageName.SiteGrid,
		gridUrlLocalStorageName: LocalStorageName.CccSiteGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccSiteSortOrder,

		showAddButton: UtilService.isNullOrEmpty(props.match.params.importId),
		addButtonText: localizationService.getLocalizedString('screen.buttons.addSite'),
		addModalComponent: <>{showAddModal && <CccSiteAddModal isToggle={showAddModal} toggle={toggleAddModal} />}</>,
		toggleAddModal: toggleAddModal,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccSite.searchHint'),
		customFieldQuery: customFieldQueries.Backflow,
		customFieldsForGrid: [CustomFieldApplyToTable.ApplyToCccSite],
		resetFilterService: () => {
			filterService.init(
				FilterDomain.CCCSITE,
				FilterDomainName.cccSite,
				LinkoTypes.FilterObjectName.CccSite,
				LocalStorageName.CccSiteGridFilterUrl,
				{ CCCHAZARD: true, CCCSITE: true }
			);
		},
		queryParameters: composeGridQueryParameter(),
		allColumns: [
			new ColumnField('siteNumber', FilterType.Text, siteNumberToLink),
			new ColumnField('company'),
			new ColumnField('addressLine1'),
			new ColumnField('addressLine2'),
			new ColumnField('cityName'),
			new ColumnField('state'),
			new ColumnField('zipCode'),
			new ColumnField('siteType'),
			new ColumnField('siteUse'),
			new ColumnField('dataSetName'),
			new ColumnField('hazardCount', FilterType.Integer),
			new ColumnField('latitude'),
			new ColumnField('longitude')
		],
		defaultColumns: ['siteNumber', 'company', 'addressLine1', 'siteType', 'siteUse', 'dataSetName', 'hazardCount']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={siteSlice}
				restState={(state: RootState) => state.cccSites}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export default CccSites;
