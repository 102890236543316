import React from 'react';
import { useHistory } from 'react-router';
import { localizationService, navigateTo, Resource, urlService, useForceUpdate } from 'src/services';
import { LookupsWidget, CustomizedSettingUrlResolver, ResourceAction, LookupRow } from 'src/components/widgets';
import { LookupType, FeatureNames } from '@rcp/types';
import { CleaningNoticeLookRowDataConverter } from 'src/components/widgets/lookup/data-converters/cleaning-notice-data-convertor';
import { GeneralNoticeLookRowDataConverter } from 'src/components/widgets/lookup/data-converters/general-notice-data-converter';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import { useDispatch } from 'react-redux';
import { generalNoticeTemplateSlice } from './general-notice-template.slice';
import { cleaningNoticeTemplateSlice } from './cleaning-notice-template.slice';
import { alertService } from 'src/redux';
import { useReduxSelector } from 'src/redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';

const NoticesSettingsComponent: React.FunctionComponent = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const forceUpdate = useForceUpdate();

	const getUniqueName = (fileName: string, index = 0, fileList: string[]): string => {
		let checkName = fileName;
		if (index) {
			checkName = `${checkName} (${index})`;
		}
		const nameExists = fileList.filter(f => f === checkName).length > 0;
		return nameExists ? getUniqueName(fileName, index + 1, fileList) : checkName;
	};

	const onCopy = async (id: number, label: string, type: string, existingRows: LookupRow[]) => {
		const existingTemplateNames = existingRows.map((row: LookupRow) => row.code);
		const restSlice = type === 'cleaning' ? cleaningNoticeTemplateSlice : generalNoticeTemplateSlice;
		dispatch(
			restSlice.fetchOne(id, (data: any) => {
				let payload = { ...data };
				type === 'cleaning' ? delete payload.noticeTemplateScheduleId : delete payload.noticeTemplateId;
				let copyName =
					localizationService.getLocalizedString('authoritySetting.noticesSettings.copiedTemplate') +
					payload.templateName;
				payload.templateName = getUniqueName(copyName, undefined, existingTemplateNames as string[]);
				if (payload.templateName.length > 50) {
					alertService.addError(
						localizationService.getLocalizedString('authoritySetting.noticesSettings.copyError')
					);
					return;
				}
				dispatch(
					restSlice.createOne(
						payload,
						false,
						localizationService.getLocalizedString('authoritySetting.copySucceedMessage', `${label}`),
						() => {
							dispatch(generalNoticeTemplateSlice.resetAll());
							forceUpdate();
						}
					)
				);
			})
		);
	};
	return !urlService.isAdministrator() ? (
		<AccessDeniedPage />
	) : (
		<div className="page">
			<div className="page-header">
				<BackArrow />
				<h1 className="ml-2">
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.notices')}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">
					{getCleaningSettings()}
					<hr />
					{getGeneralSettings()}
				</div>
			</div>
		</div>
	);

	function getGeneralSettings() {
		let lookupUrlResolver = new CustomizedSettingUrlResolver(
			`${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/${LookupType.GeneralNoticeTemplate}`,
			`includeInactive=true&includeIsInUse=true`
		);
		let dataConverter = new GeneralNoticeLookRowDataConverter(LookupType.GeneralNoticeTemplate);
		let label = localizationService.getLocalizedString('authoritySetting.noticesSettings.noticeTemplate');

		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.general.general')}
					</strong>
					<p>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.general.generalDesc')}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<LookupsWidget
							id="general-notice-template"
							name="general-notice-template"
							label={label}
							hideActiveStatus={false}
							onAddButtonClick={() => navigateTo(history, '/fog/settings/notices/generalNotice')}
							hideAddModal={true}
							onEditButtonClick={id => navigateTo(history, `/fog/settings/notices/generalNotice/${id}`)}
							hideEditModal={true}
							showCopy={true}
							onCopyIconButtonClick={(id, existingRows) =>
								onCopy(id, label, 'general', existingRows || [])
							}
							settingAction={new ResourceAction(lookupUrlResolver, dataConverter)}
						/>
					</section>
				</div>
			</div>
		);
	}

	function getCleaningSettings() {
		let lookupUrlResolver = new CustomizedSettingUrlResolver(
			`${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/${
				LookupType.CleaningNoticeTemplateSchedule
			}`,
			`includeInactive=true&includeIsInUse=true`
		);
		let dataConverter = new CleaningNoticeLookRowDataConverter(LookupType.CleaningNoticeTemplateSchedule);
		let label = localizationService.getLocalizedString('authoritySetting.noticesSettings.noticeTemplate');
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.cleaning.cleaning')}
					</strong>
					<p>
						{localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.cleaning.cleaningDesc'
						)}
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<LookupsWidget
							id="cleaning-notice-template"
							name="cleaning-notice-template"
							label={label}
							hideActiveStatus={false}
							onAddButtonClick={() => navigateTo(history, '/fog/settings/notices/cleaningNotice')}
							hideAddModal={true}
							onEditButtonClick={id => navigateTo(history, `/fog/settings/notices/cleaningNotice/${id}`)}
							hideEditModal={true}
							showScheduleColumn={true}
							showCopy={true}
							onCopyIconButtonClick={(id, existingRows: LookupRow[]) => {
								onCopy(id, label, 'cleaning', existingRows || []);
							}}
							settingAction={new ResourceAction(lookupUrlResolver, dataConverter)}
							disableClientSideSorting={true}
						/>
					</section>
				</div>
			</div>
		);
	}
};

export const AuthoritySettingsNotices = NoticesSettingsComponent;
