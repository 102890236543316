import { LookupType, SettingValue } from '@rcp/types';
import { apiService } from 'src/services';
import { LookupRow } from './lookup-types';
import { SettingUrlResolver, SettingUrls, ISettingUrlResolver } from './setting-url-resolver';
import { ILookupDataConverter, DefaultLookRowDataConverter } from './data-converters';

export interface SettingActionHooks {
	delete: (existingRows: LookupRow[], rowToDelete: LookupRow) => Promise<LookupRow[]>;
	update: (existingRows: LookupRow[], rowToUpdate: LookupRow) => Promise<LookupRow[]>;
	add: (existingRows: LookupRow[], rowToAdd: LookupRow) => Promise<LookupRow>;
	get: () => Promise<LookupRow[]>;
	bulkUpdate?: (existingRows: LookupRow[]) => Promise<any>;
}

export class SettingAction implements SettingActionHooks {
	private isAuthoritySetting: boolean = false;

	settingUrls: SettingUrls;
	dataConverter: ILookupDataConverter;

	constructor(
		lookupType: LookupType,
		settingUrls: ISettingUrlResolver = new SettingUrlResolver(lookupType),
		dataConverter: ILookupDataConverter = new DefaultLookRowDataConverter(lookupType),
		isAuthoritySetting: boolean = false
	) {
		this.dataConverter = dataConverter;
		this.isAuthoritySetting = isAuthoritySetting;
		this.settingUrls = settingUrls.getSettingUrls();
		this.isAuthoritySetting = isAuthoritySetting;
	}

	delete = async (existingRows: LookupRow[], rowToDelete: LookupRow) => {
		const url = this.settingUrls.deleteUrl(rowToDelete.lookupId || 0);

		if (this.isAuthoritySetting) {
			var dataToUpdate = this.dataConverter.toAPIDeleteDataFromLookups(existingRows, rowToDelete);
			await apiService.postResource(url, dataToUpdate);
		} else {
			await apiService.httpDelete(url);
		}

		var newValues = existingRows.filter(i => i.code !== rowToDelete.code);
		return newValues.slice(0);
	};

	update = async (existingRows: LookupRow[], rowToUpdate: LookupRow): Promise<LookupRow[]> => {
		const url = this.settingUrls.updateUrl(rowToUpdate.lookupId || 0);
		if (this.isAuthoritySetting) {
			var dataToUpdate = this.dataConverter.toAPIUpdateDataFromLookups(existingRows, rowToUpdate);
			let settingValue = await apiService.postResource<SettingValue>(url, dataToUpdate as SettingValue);
			let lookupRows = this.dataConverter.toLookupRowsFromAPI(settingValue);
			return lookupRows as LookupRow[];
		} else {
			let dataToPatch = this.dataConverter.toAPIUpdateDataFromLookups(existingRows, rowToUpdate);
			let patchedLookup = await apiService.httpPatch(url, dataToPatch);
			patchedLookup = this.dataConverter.toLookupRowsFromAPI([patchedLookup])[0];
			let currentLookups = existingRows.slice();
			let index = currentLookups.findIndex(i => i.lookupId === patchedLookup.lookupId);

			if (index > -1) {
				currentLookups[index].code = patchedLookup.code;
				currentLookups[index].isActive = patchedLookup.isActive;
				currentLookups[index].isInUse = patchedLookup.isInUse;
			}
			return currentLookups;
		}
	};

	bulkUpdate = async (existingRows: LookupRow[]): Promise<any> => {
		const url = this.settingUrls.updateUrl();
		if (this.dataConverter.toApiListFromLookUpRow) {
			let partialDto: any[] = this.dataConverter.toApiListFromLookUpRow(existingRows);
			const dataToPatch = partialDto.map(data => {
				return {
					partialDto: data,
					dropdownValueChangeTrackingDto: {
						newValues: [],
						originalValues: []
					}
				};
			});
			return apiService.patchResource(url, dataToPatch);
		}
	};

	add = async (existingRows: LookupRow[], rowToAdd: LookupRow): Promise<LookupRow> => {
		const url = this.settingUrls.addUrl;
		let dataToAdd = this.dataConverter.toAPIAddDataFromLookups(existingRows, rowToAdd);

		let createdLookupResp = await apiService.postResource(url, dataToAdd);
		var createdLookup = this.dataConverter.toLookupRowFromAPI(createdLookupResp);
		return createdLookup as LookupRow;
	};

	get = async (): Promise<LookupRow[]> => {
		const url = this.settingUrls.getUrl;
		const lookupValues = await apiService.httpGet(url);

		const inUseUrl = this.settingUrls.getInUseUrl;
		if (!inUseUrl) {
			const rows = this.dataConverter.toLookupRowsFromAPI(lookupValues);
			return rows as LookupRow[];
		}
		const inUseLookupValues = await apiService.httpGet(inUseUrl);
		const rows = this.dataConverter.toLookupRowsFromAPI(lookupValues, inUseLookupValues);
		return rows as LookupRow[];
	};
}

export class ResourceAction implements SettingActionHooks {
	settingUrls: SettingUrls;
	dataConverter: ILookupDataConverter;

	constructor(urlResolver: ISettingUrlResolver, dataConverter: ILookupDataConverter) {
		this.dataConverter = dataConverter;
		this.settingUrls = urlResolver.getSettingUrls();
	}

	delete = async (existingRows: LookupRow[], rowToDelete: LookupRow) => {
		const url = this.settingUrls.deleteUrl(rowToDelete.lookupId || 0);
		await apiService.httpDelete(url);
		var newValues = existingRows.filter(i => i.code !== rowToDelete.code);
		return newValues.slice(0);
	};

	update = async (existingRows: LookupRow[], rowToUpdate: LookupRow): Promise<LookupRow[]> => {
		const url = this.settingUrls.updateUrl(rowToUpdate.lookupId || 0);

		let dataToPatch = this.dataConverter.toAPIUpdateDataFromLookups(existingRows, rowToUpdate);
		let patchedLookup = await apiService.httpPatch(url, dataToPatch);
		patchedLookup = this.dataConverter.toLookupRowsFromAPI([patchedLookup])[0];
		let currentLookups = existingRows.slice();
		let index = currentLookups.findIndex(i => i.lookupId === patchedLookup.lookupId);

		if (index > -1) {
			currentLookups[index].code = patchedLookup.code;
			currentLookups[index].isActive = patchedLookup.isActive;
			currentLookups[index].isInUse = patchedLookup.isInUse;
		}
		return currentLookups;
	};

	add = async (existingRows: LookupRow[], rowToAdd: LookupRow): Promise<LookupRow> => {
		const url = this.settingUrls.addUrl;
		let dataToAdd = this.dataConverter.toAPIAddDataFromLookups(existingRows, rowToAdd);

		let createdLookupResp = await apiService.postResource(url, dataToAdd);
		var createdLookup = this.dataConverter.toLookupRowFromAPI(createdLookupResp);
		return createdLookup as LookupRow;
	};

	get = async (): Promise<LookupRow[]> => {
		const url = this.settingUrls.getUrl;
		const lookupValues = await apiService.httpGet(url);
		const rows = this.dataConverter.toLookupRowsFromAPI(lookupValues);
		return rows as LookupRow[];
	};
}
