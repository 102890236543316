import React, { FC } from 'react';
import { History } from 'history';
import { fogDevicesSlice } from './devices.slice';
import * as LinkoTypes from '@rcp/types';
import {
	ColumnField,
	FilterType,
	LocalStorageName,
	Extractor,
	Dictionary,
	FeatureNames,
	FogFacility
} from '@rcp/types';
import { RestSlice } from 'src/redux/rest.slice';
import { customFieldQueries, DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState, useReduxSelector } from 'src/redux';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import FogDeviceActionLink from './fog-device-grid-action-link';
import { FogDeviceGridTooltip } from './fog-device-grid-tooltip';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { ActionModalActionType } from 'src/redux/action-modals';
import { useHistory } from 'react-router';
import _ from 'lodash';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const FogDevicesGrid: FC<Props<Extractor>> = props => {
	const history = useHistory();
	const [queryParameters, setQueryParameters] = React.useState<LinkoTypes.Dictionary<string>>({});

	const deviceNumberToLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.Extractor;
		const url = urlService.getReactAuthorityResourceUrl(Resource.Devices, dataItem.extractorId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.deviceNumber}
				</a>
			</td>
		);
	};
	const enterCleaningCell = (item: any) => {
		const dataItem = item.dataItem as Extractor;
		const displayText = localizationService.getLocalizedString('pumpOut.newCleaning');
		return dataItem.isActive ? (
			<td>
				<FogDeviceActionLink
					displayText={displayText}
					facilityId={dataItem.facilityId as number}
					isEnterCleaning={true}
					device={dataItem}
					deviceGridSlice={fogDevicesSlice}
					scheduledCleaningId={dataItem.nextCleaningId}
				/>
			</td>
		) : (
			<td></td>
		);
	};
	const nextCleaningCell = (item: any) => {
		const dataItem = item.dataItem as Extractor;
		return (
			<td>
				<FogDeviceActionLink
					displayText={dataItem.nextCleaningDate ? dataItem.nextCleaningDate : ''}
					facilityId={dataItem.facilityId as number}
					isEnterCleaning={false}
					device={dataItem}
					deviceGridSlice={fogDevicesSlice}
					scheduledCleaningId={dataItem.nextCleaningId}
				/>
			</td>
		);
	};
	const lastCleaningCell = (item: any) => {
		const dataItem = item.dataItem as Extractor;
		return (
			<td>
				<FogDeviceActionLink
					displayText={dataItem.lastCleaningDate ? dataItem.lastCleaningDate : ''}
					facilityId={dataItem.facilityId as number}
					isEnterCleaning={false}
					device={dataItem}
					deviceGridSlice={fogDevicesSlice}
					completeCleaningId={dataItem.lastCleaningId}
				/>
			</td>
		);
	};

	const facilityNamesCell = (item: any) => {
		const dataItem = item.dataItem as Extractor;
		const url = dataItem.facilityId
			? urlService.getReactAuthorityResourceUrl(Resource.FogFacilities, dataItem.facilityId)
			: null;
		return url ? (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.facilityName}
				</a>
			</td>
		) : (
			<td></td>
		);
	};

	const lastHaulerCell = (item: any) => {
		const dataItem = item.dataItem as Extractor;
		const lastHauler: LinkoTypes.BasicHauler = {
			name: dataItem.lastHaulerName,
			haulerNumber: dataItem.lastHaulerNumber,
			phone: dataItem.lastHaulerPhoneNumber,
			email: dataItem.lastHaulerEmailAddress
		};
		return dataItem.lastHaulerId ? <FogDeviceGridTooltip lastHauler={lastHauler} /> : <td></td>;
	};

	const bulkUpdate = () => {
		const queryParams = urlService.toQueryString(queryParameters);
		let path = urlService.getFogDeviceBulkUpdatePath(queryParams);
		history.push(path);
	};

	const sendCleaningNotices = () => {
		let queryString = urlService.toQueryString(_.pick(queryParameters, ['search', 'filterId', 'draftFilterId']));
		history.push(`${Resource.Devices}/${Resource.SendCleaningNotice}?${queryString.trim()}`);
	};

	const setQueryParametersFromParent = (queryParameters: Dictionary<string>) => {
		setQueryParameters(queryParameters);
	};

	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'extractor.deviceMultiple');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'extractor.deviceMultiple');
		}
		return localizationService.getLocalizedString('extractor.devices');
	};

	const getAllColumns = () => {
		let columns = [
			new ColumnField('deviceNumber', FilterType.Text, deviceNumberToLink),
			new ColumnField('extractorType'),
			new ColumnField('location'),
			new ColumnField('extractorDescription'),
			new ColumnField('cleanFrequencyCode'),
			new ColumnField('addressLine1'),
			new ColumnField('addressLine2'),
			new ColumnField('cityName'),
			new ColumnField('zipCode'),
			new ColumnField('jurisdictionName'),
			new ColumnField('trapCapacity', FilterType.Number),
			new ColumnField('trapCapacityUnitCode'),
			new ColumnField('trapDepth', FilterType.Number),
			new ColumnField('trapDepthUnitCode'),

			new ColumnField('manufacturer'),
			new ColumnField('model'),
			new ColumnField('longitude'),
			new ColumnField('latitude'),
			new ColumnField('isActive', FilterType.Boolean),
			new ColumnField('isAdditivesUsed', FilterType.Boolean),
			new ColumnField('mandatoryInstall', FilterType.Boolean),
			new ColumnField('numberOfCompartments', FilterType.Number),
			new ColumnField('installDate', FilterType.Date),
			new ColumnField('lastCleaningDate', FilterType.Date, lastCleaningCell),
			new ColumnField('daysLateLastCleaning', FilterType.Integer),
			new ColumnField('nextCleaningDate', FilterType.Date, nextCleaningCell),
			new ColumnField('daysLateNextCleaning', FilterType.Integer),
			new ColumnField('lastHaulerName', FilterType.Text, lastHaulerCell),
			new ColumnField('facilityName', FilterType.Text, facilityNamesCell),
			new ColumnField('facilityNumber', FilterType.Text),
			new ColumnField('enterCleaning', FilterType.Text, enterCleaningCell),
			new ColumnField('verified', FilterType.Boolean),
			new ColumnField('addedBy'),
			new ColumnField('sendNoticeContactsCount', FilterType.Number),
			new ColumnField('lastNoticeTemplateName', FilterType.Text),
			new ColumnField('lastNoticeDate', FilterType.Date),
			new ColumnField('nextNoticeTemplateName', FilterType.Text),
			new ColumnField('nextNoticeDate', FilterType.Date)
		];
		return columns;
	};

	const initialGridOption: GridOption = {
		pageTitle: getTitle(),
		prefix: 'extractor',
		storageName: LocalStorageName.FogDeviceGrid,
		gridUrlLocalStorageName: LocalStorageName.FogDeviceGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FogDeviceSortOrder,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('extractor.searchHint'),
		customActions: [
			{
				actionType: ActionModalActionType.fogFacilityBatchUpdateModal,
				actionLabel: localizationService.getLocalizedString('grid.bulkUpdate'),
				toggle: bulkUpdate
			},
			{
				actionType: ActionModalActionType.fogDeviceCleaningNotices,
				actionLabel: localizationService.getLocalizedString('grid.sendNotices'),
				toggle: sendCleaningNotices
			}
		],

		customFieldQuery: customFieldQueries.Fog,
		allColumns: getAllColumns(),
		defaultColumns: [
			'deviceNumber',
			'extractorDescription',
			'extractorType',
			'trapCapacity',
			'facilityName',
			'addressLine1',
			'cleanFrequencyCode',
			'lastCleaningDate',
			'nextCleaningDate'
		],
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.EXTRACTOR,
				FilterDomainName.extractor,
				LinkoTypes.FilterObjectName.Extractor,
				LocalStorageName.FogDeviceGridFilterUrl,
				appliedDomains
			);
		},
		hasGridMap: true
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				setQueryParametersFromParent={setQueryParametersFromParent}
				restSlice={fogDevicesSlice}
				restState={(state: RootState) => state.fogDevices}
				gridOption={initialGridOption}
			/>
		</>
	);
};
