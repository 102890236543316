import React, { useState } from 'react';
import { localizationService } from 'src/services';
import { CustomFieldDataConverter } from '../../../../widgets/lookup/data-converters/custom-field-data-converter';
import { CustomFieldApplyToTable, LookupType } from '@rcp/types';
import { CustomFieldUrlResolver, LookupRow, LookupsWidget, SettingAction } from '../../../../widgets/lookup';
import CustomFieldEditor, { EditorMode } from '../editors/custom-field-editor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux';
import { customFieldSlice } from '../../../../../redux/custom-field.slice';
import { Sortable } from '@progress/kendo-react-sortable';

interface Props {
	appliedToTable: CustomFieldApplyToTable;
	description: string;
}

const CustomFieldSettingComponent: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	// eslint-disable-next-line
	let { selected: selected, loading } = useSelector((state: RootState) => state.customFields);

	const [customFieldState, setCustomFieldState] = useState({ show: false, mode: EditorMode.Add, selected: null });
	const onCustomFieldClosed = () => {
		setCustomFieldState({ ...customFieldState, show: false });
	};

	const onAddCustomField = () => {
		setCustomFieldState({ ...customFieldState, show: true, mode: EditorMode.Add, selected: null });
	};

	const onUpdateCustomField = (id: any) => {
		dispatch(customFieldSlice.fetchOne(id));
		setCustomFieldState({ ...customFieldState, show: true, mode: EditorMode.Update, selected: null });
	};

	const getDeleteMessage = (lookup: LookupRow): string => {
		let hasValues = lookup.usedCount && lookup.usedCount > 0;
		let hasWidgets = lookup.widgetCount && lookup.widgetCount > 0;
		let error = '';
		if (hasValues || hasWidgets)
			error += localizationService.getLocalizedString('authoritySetting.customFieldDeleteConfirmationInUse');
		else error += localizationService.getLocalizedString('authoritySetting.customFieldDeleteConfirmationBasic');

		return error;
	};

	const itemRenderer = (props: any) => {
		return <Sortable {...props} idField={'lookupId'} style={{ display: 'contents' }} disabledField={'disabled'} />;
	};

	function getCustomFieldsSection() {
		let dataConverter = new CustomFieldDataConverter(LookupType.CustomField);
		let urlResolver = new CustomFieldUrlResolver(LookupType.CustomField, props.appliedToTable);
		let settingAction = new SettingAction(LookupType.CustomField, urlResolver, dataConverter, false);

		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.customFields')}</strong>
					<p>{props.description}</p>
				</div>
				<div className="col-lg-8">
					<section>
						<LookupsWidget
							id="customField"
							name="Custom Fields"
							className="col-sm-12"
							label={localizationService.getLocalizedString('authoritySetting.customField')}
							descriptionLabel={localizationService.getLocalizedString('authoritySetting.type')}
							hideActiveStatus={true}
							editDescription={true}
							showDescriptionColum={true}
							onAddButtonClick={onAddCustomField}
							hideAddModal={true}
							hideEditModal={true}
							deleteMessage={getDeleteMessage}
							onEditButtonClick={onUpdateCustomField}
							settingAction={settingAction}
							itemRenderer={itemRenderer}
							isCustomSort={true}
						/>
					</section>
				</div>
				{customFieldState.show && !loading && (
					<CustomFieldEditor
						customField={selected}
						table={props.appliedToTable}
						mode={customFieldState.mode}
						show={customFieldState.show}
						description={props.description}
						onClosed={onCustomFieldClosed}
					/>
				)}
			</div>
		);
	}

	return <>{getCustomFieldsSection()}</>;
};

export default CustomFieldSettingComponent;
