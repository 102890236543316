import { ColumnField, FilterObjectName, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import React, { FC } from 'react';
import { GridOption } from 'src/features/data-grid/data-grid';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { localizationService, localStorageService, navigateTo, Resource, urlService } from 'src/services';
import IppReportPackageReviewsComponent from './reviews';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppPackageRepudiatedPendingReviews: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getReactAuthorityResourceUrl(
			`authority/${Resource.IppReportPackages}`,
			dataItem.reportPackageId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: false,
		enableFilter: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageRepudiatedPendingReviewGrid,
		filterStorageName: LocalStorageName.IppReportPackageRepudiatedPendingReviewGridFilterUrl,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: localizationService.getLocalizedString(
			'ipp.reportPackage.package.reportTypes.RepudiatedPendingReview'
		),
		sort:
			localStorageService.getLocalStorage(LocalStorageName.IppRepudiationPendingReviewsReportPackageSortOrder) ||
			[],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		filterOnlyThisGrid: true,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.RepudiatedPendingReview,
			page: initialPage,
			sort: 'repudiationDateTimeLocal asc'
		},
		showFilterMenuPopOver: true,
		disableFilterWarning: true,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE,
				FilterDomainName.ippRepudiatedPendingReviewReportPackages,
				FilterObjectName.IppRepudiatedPendingReviewReportPackage,
				LocalStorageName.IppReportPackageRepudiatedPendingReviewGridFilterUrl,
				{ IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE: true }
			);
		},
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('ctsEventTypeName'),
			new ColumnField('ctsEventCategoryName'),
			new ColumnField('isSubmissionBySignatoryRequired', FilterType.Boolean),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('submitterUserName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField('repudiationDateTimeLocal', FilterType.Date),
			new ColumnField('lastSentDateTimeLocal', FilterType.Date),
			new ColumnField('repudiatorFirstName'),
			new ColumnField('repudiatorLastName'),
			new ColumnField('repudiationReasonName'),
			new ColumnField('repudiationComments'),
			new ColumnField('assignedTo'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'organizationReferenceNumber',
			'organizationName',
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal',
			'repudiationDateTimeLocal',
			'lastSentDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			sortOrderLocalStorageName={LocalStorageName.IppRepudiationPendingReviewsReportPackageSortOrder}
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppPackageRepudiatedPendingReviews;
