import * as ApiTypes from '@rcp/types';

export interface IppPendingRegistrationUserState {
	data: ApiTypes.IppPendingRegistrationUser;
	notification:Object;
}

export const initialIppPendingRegistrationUserState: IppPendingRegistrationUserState = {
	data:{
		email:'',
		firstName:'',
		fullName:'',
		lastName:'',
		organizationType:'',
		phoneNumber:'',
		registeredFor:'',
		registrationDateTimeUtc:'',
		role:'',
		userName:'',
		phoneExt:'',
		title:'',
		addressLine1:'',
		addressLine2:'',
		cityName:'',
		state:'',
		zipCode:'',
		organizationName:'',
		isSignatory:false
	},
	notification:{msg:'', type:'success'}
};
