import React, { useState, FC, useEffect } from 'react';
import { BasicHauler, DropDownOption, FogFacility } from '@rcp/types';
import { ComboBoxDropdown, PopoverModal } from 'src/components';
import { VerifiedSvg } from 'src/components/svg';
import { haulersSlice } from 'src/components/authority/fog/haulers';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { alertService, loadCurrentFogFacility, RootState, useRootStateSelector } from 'src/redux';
import { useDispatch } from 'react-redux';
import { FilterDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';
import { ListItemProps } from '@progress/kendo-react-dropdowns';

interface Props {
	showModal: boolean;
	toggleModal: () => void;
	selectedContractedHauler: BasicHauler;
}

export const ContactedHaulerModal: FC<Props> = props => {
	const [selectedHaulerId, setSelectedHaulerId] = useState<number | null | undefined>(null);
	const haulerList = useRootStateSelector((state: RootState) => state.haulers);
	const [haulerOptionValues, setHaulerOptionValues] = useState<DropDownOption[]>([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(haulersSlice.fetchAll('size=2147483647'));
	}, []);

	useEffect(() => {
		setSelectedHaulerId(props.selectedContractedHauler.haulerId);
	}, [props.selectedContractedHauler.haulerId]);

	useEffect(() => {
		if (!haulerList || haulerList.total < 1) return;

		let dropDownOptions: DropDownOption[] = [];

		dropDownOptions = haulerList.result
			.filter(hauler => hauler.isActive)
			.map((hauler: BasicHauler) => {
				return {
					label: hauler.name,
					value: hauler.haulerId,

					name: hauler.name,
					addressLine1: hauler.addressLine1,
					addressLine2: hauler.addressLine2,
					addressCity: hauler.addressCity,
					addressJurisdictionCode: hauler.addressJurisdictionCode,
					addressZipCode: hauler.addressZipCode,
					isPreferred: hauler.isPreferred
				} as DropDownOption;
			})
			.sort((a, b) => {
				return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
			});

		if (selectedHaulerId) {
			const deletedState: string = localizationService.getLocalizedString('screen.labels.deleted');
			const inactiveState: string = localizationService.getLocalizedString('screen.labels.inActive');
			let selectedHaulerDropDown = haulerList.result.find(hauler => hauler.haulerId === selectedHaulerId);
			if (selectedHaulerDropDown) {
				if (!selectedHaulerDropDown.isActive) {
					dropDownOptions.push({
						label: selectedHaulerDropDown.isActive
							? (selectedHaulerDropDown.name as string)
							: (`${selectedHaulerDropDown.name} (${inactiveState})` as string),
						value: selectedHaulerDropDown.haulerId,

						addressLine1: selectedHaulerDropDown.addressLine1,
						addressLine2: selectedHaulerDropDown.addressLine2,
						addressCity: selectedHaulerDropDown.addressCity,
						addressJurisdictionCode: selectedHaulerDropDown.addressJurisdictionCode,
						addressZipCode: selectedHaulerDropDown.addressZipCode,
						isPreferred: selectedHaulerDropDown.isPreferred,
						isHidden: true
					});
				}
			} else {
				dropDownOptions.push({
					label: `${props.selectedContractedHauler.name} (${deletedState})` as string,
					value: selectedHaulerId,

					addressLine1: props.selectedContractedHauler.addressLine1,
					addressLine2: props.selectedContractedHauler.addressLine2,
					addressCity: props.selectedContractedHauler.addressCity,
					addressJurisdictionCode: props.selectedContractedHauler.addressJurisdictionCode,
					addressZipCode: props.selectedContractedHauler.addressZipCode,
					isPreferred: props.selectedContractedHauler.isPreferred,

					isHidden: true
				});
			}
		}
		setHaulerOptionValues(dropDownOptions);
	}, [haulerList, selectedHaulerId]);

	const onSubmit = async () => {
		let facilityToUpdate: FogFacility = {
			contractedHaulerId: selectedHaulerId ? selectedHaulerId : null
		};

		let facilityId = urlService.getFogFacilityId();

		const facilityUrl = urlService.getAuthorityResourceApiUrl(Resource.FogFacilities, facilityId);

		await apiService.patchResource<FogFacility>(facilityUrl, facilityToUpdate);
		dispatch(loadCurrentFogFacility());
		let successMessage = props.selectedContractedHauler.haulerId
			? selectedHaulerId
				? localizationService.getLocalizedString('alertMessages.updateSuccess', 'extractor.contractedHauler')
				: localizationService.getLocalizedString('alertMessages.removeSuccess', 'extractor.contractedHauler')
			: localizationService.getLocalizedString('alertMessages.addSuccess', 'extractor.contractedHauler');
		alertService.addSuccess(successMessage);

		props.toggleModal();
	};

	const onModalCancel = () => {
		props.toggleModal();
	};

	const modalProps = {
		title: props.selectedContractedHauler.haulerId
			? localizationService.getLocalizedString('extractor.editContractedHauler')
			: localizationService.getLocalizedString('extractor.addContractedHauler'),
		showModal: props.showModal,
		cancel: onModalCancel,
		save: onSubmit,
		saveButtonText: props.selectedContractedHauler.haulerId
			? localizationService.getLocalizedString('screen.buttons.save')
			: localizationService.getLocalizedString('screen.buttons.add'),
		saveButtonClassName: props.selectedContractedHauler.haulerId ? 'btn ai-save' : 'btn ai-action'
	};

	const changeFormState = (e: any) => {
		let { name, value } = e.target;
		setSelectedHaulerId(value);
	};

	const contactedHaulerListItemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const item = itemProps.dataItem as DropDownOption;

		const itemChildren = <div className="w-100 border-bottom">{renderHaulerInfo(item)}</div>;

		return React.cloneElement(li, li.props, itemChildren);
	};

	const renderHaulerInfo = (option: DropDownOption) => {
		return (
			<div className="m-1">
				<div className="d-flex">
					{<strong className="mr-2"> {option.label}</strong>}
					{option.isPreferred && (
						<VerifiedSvg title={localizationService.getLocalizedString('haulers.preferredHauler')} />
					)}
				</div>
				<div>
					{option.addressLine1 && (
						<div>
							{option.addressLine1}
							{option.addressLine2 && <> {option.addressLine2}</>}
						</div>
					)}
					{(
						option.addressCity +
						' ' +
						option.addressJurisdictionCode +
						' ' +
						option.addressZipCode
					).trim() && (
						<div>
							{(
								(option.addressCity ? option.addressCity : '') +
								' ' +
								(option.addressJurisdictionCode ? option.addressJurisdictionCode : '') +
								' ' +
								(option.addressZipCode ? option.addressZipCode : '')
							).trim()}
						</div>
					)}
				</div>
			</div>
		);
	};

	const customFilter = (options: DropDownOption[], filter: FilterDescriptor): DropDownOption[] => {
		let newOptions = _.cloneDeep(options);
		newOptions = newOptions.filter((option: DropDownOption) => {
			return (
				_.toLower(option.addressLine1).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.addressLine2).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.addressCity).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.addressJurisdictionCode).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.addressZipCode).includes(_.toLower(filter.value.trim())) ||
				_.toLower(option.name).includes(_.toLower(filter.value.trim()))
			);
		});
		return newOptions;
	};

	return (
		<div className="w-100">
			<PopoverModal {...modalProps}>
				<ComboBoxDropdown
					options={haulerOptionValues}
					id="facilityContactedHauler"
					name="selectedContactedHaulerId"
					itemRender={contactedHaulerListItemRender}
					className="col-sm-12 p-0 "
					onChange={changeFormState}
					clearButton={true}
					value={_.toString(selectedHaulerId ? selectedHaulerId : '')}
					label={localizationService.getLocalizedString('haulers.hauler')}
					customFilter={customFilter}
					showSearchIcon
					customHintText={
						<div className="ai-form-help">
							<VerifiedSvg title={localizationService.getLocalizedString('haulers.preferredHauler')} />{' '}
							{localizationService.getLocalizedString('haulers.preferredHaulerIconHint')}
						</div>
					}
				/>
			</PopoverModal>
		</div>
	);
};
