import React, { FC } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
import './inline-input.scss';

interface Props {
	id: string;
	name: string;
	label?: string;
	isRequired?: boolean;
	value?: string | number;
	placeholder?: string;
	autoComplete?: string;
	error?: string;
	helpText?: string;
	type?: string;
	className?: string;
	showErrorAndHelp?: boolean;
	prefix?: string;
	onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
	onSubmit?: () => void;
	onClear?: () => void;
	inputRef?: React.RefObject<HTMLInputElement>;
	hideLabel?: boolean;
	maxLength?: number;
	remainingInputProps?: Object;
	additionalRowClass?: string;
	isDisabled?: boolean;
}

export const InlineTextInput: FC<Props> = props => {
	const handleChange = (e: any) => {
		props.onChange && props.onChange(e);
	};

	const handleSubmit = (e: any) => {
		props.onSubmit && props.onSubmit() && props.onClear && props.onClear();
	};

	const rowClass = props.className ? 'form-group inline-input w-100' : 'inline-input w-100';

	return (
		<div className={`${rowClass} ${props.isRequired ? 'required' : ''}`}>
			{props.label && <label className={`${props.isDisabled ? 'disabled-feedback' : ''}`}>{props.label}</label>}
			<div className={`input-group ${props.error ? ' is-invalid' : ''}`}>
				<input
					id={props.id}
					name={props.name}
					type={props.type ? props.type : 'text'}
					className={'form-control value'}
					value={props.value || ''}
					placeholder={props.placeholder}
					onChange={handleChange}
					required={props.isRequired === true ? true : false}
					disabled={props.isDisabled === true ? true : false}
					autoComplete={props.autoComplete ? props.autoComplete : 'new-password'}
					ref={props.inputRef}
					maxLength={props.maxLength}
					min={0}
					{...props.remainingInputProps}
				/>
				<div className="input-group-append">
					<span className="input-group-text">
						<FaCheck className="ai-dark-purple cursor-pointer" onClick={handleSubmit} />
						<FaTimes
							className="ai-dark-purple ml-2 cursor-pointer"
							onClick={() => props.onClear && props.onClear()}
						/>
					</span>
				</div>
			</div>
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</div>
	);
};
