import React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { localizationService } from 'src/services';

import { loadUserProfile, useReduxDispatch, useReduxSelector } from 'src/redux';
import { Translate } from 'src/components/widgets/translate/translator';

type Props = {
	history: History;
	authoritySettings: JSX.Element[];
	customerSuccessOnlySettings?: JSX.Element[];
};

const LandingPageComponent: React.FunctionComponent<Props> = props => {
	const dispatch = useReduxDispatch();

	const currentUser = useReduxSelector(state => state.userProfile);

	React.useEffect(() => {
		dispatch(loadUserProfile());
	}, [dispatch]);

	return (
		<div className="page">
			<div className="page-header">
				<h1>
					<Translate>{localizationService.getLocalizedString('screen.labels.settings')}</Translate>
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section className="setting-section">
						<div className="row">{props.authoritySettings}</div>
					</section>
					{currentUser.userProfile.isInternalAccount && props.customerSuccessOnlySettings && (
						<>
							<h2>
								{localizationService.getLocalizedString(
									'inspectionFormTemplates.customerSuccessSettings'
								)}
							</h2>
							<section className="min-height-180px">
								<div className="row">{props.customerSuccessOnlySettings}</div>
							</section>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const LandingPage = connect()(LandingPageComponent);
