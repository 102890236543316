import {
	ApiError,
	CccImportStep1Data,
	CccImportSteps,
	CccImportSummary,
	DropDownOption,
	UploadMaximumSize10MB
} from '@rcp/types';
import FormData from 'form-data';
import _ from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import { apiService, localizationService, navigateTo, Resource, urlService, validationService } from 'src/services';
import { importConfigurationSlice } from '../../import-configuration.slice';
import './step1.scss';

interface Props {
	vertical?: boolean;
}

interface Step1State {
	configurationOption: ConfigurationOption;
	configurationName?: string;
	configurationId: number;
	isConfigurationEditable: boolean;
	showEditorModal: boolean;
}

export enum ConfigurationOption {
	NEW = 'NEW',
	CHOOSE = 'CHOOSE'
}

const Step1: React.FunctionComponent<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const [selectedFile, setSelectedFile] = React.useState<File>();
	const [invalidFileErrorMessage, setInvalidFileErrorMessage] = React.useState('');
	const [configurationNameError, setConfigurationNameError] = useState('');
	const history = useHistory();
	const importConfigurationState = (state: RootState) => state.cccImportConfigurations;
	const { result: importConfigurations } = useSelector(importConfigurationState);

	const { userProfile } = useReduxSelector(state => state.userProfile);
	const dispatch = useDispatch();
	const isAdministratorOrInternalUser = (): boolean => {
		return urlService.isAdministrator() || userProfile.isInternalAccount === true;
	};
	const [configurationOptions, setConfigurationOptions] = useState<DropDownOption[]>([]);

	const initState: Step1State = {
		configurationId: -1,
		configurationOption: ConfigurationOption.CHOOSE,
		isConfigurationEditable: false,
		showEditorModal: false
	};

	React.useEffect(() => {
		const step1Data: CccImportStep1Data = stepperContext.getData(CccImportSteps.STEP1);
		dispatch(importConfigurationSlice.fetchPage({}));
		var queryParams = urlService.toQueryDictionary();
		let queryParamConfigurationId = _.get(queryParams, 'configurationId');
		if (step1Data) {
			//When move back from step2 to step1
			setSelectedFile(step1Data.selectedFile);
			let configurationId = _.toNumber(
				queryParamConfigurationId || step1Data.importConfigurationId || step1Data.configurationId
			);
			setState({
				...state,
				configurationId: configurationId,
				// @ts-ignore
				configurationOption: ConfigurationOption.CHOOSE,
				configurationName: step1Data.configurationName || step1Data.importConfigurationName,
				isConfigurationEditable: isAdministratorOrInternalUser() && configurationId > 0
			});
		} else {
			//Directly open import view
			let fileId = _.get(queryParams, 'fileId');
			if (!_.isEmpty(queryParamConfigurationId)) {
				let configurationId = _.toNumber(queryParamConfigurationId);
				if (fileId) {
					const url = `${urlService.getAuthoritySettingResourceApiUrl(
						Resource.AuthoritySettings
					)}/Imports/Prepare`;
					let formData = new FormData();
					formData.append('ImportConfigurationId', configurationId);
					formData.append('ImportFileId', fileId);
					apiService
						.postFormData(url, formData, {})
						.then((response: CccImportSummary) => {
							stepperContext.resolve({ selectedFile: selectedFile, ...response, ...state });
						})
						.catch((error: ApiError) => {
							urlService.removeUrlQueryString();
							alertService.addError(error.message);
						});
				} else {
					setState({
						...state,
						configurationId: configurationId,
						configurationOption: ConfigurationOption.CHOOSE,
						isConfigurationEditable: isAdministratorOrInternalUser() && configurationId > 0
					});
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onConfigurationSelected = (e: any) => {
		const { value } = e.target;
		const selectedConfigurationId = _.toNumber(value);
		let selectedConfigurationOption = _.find(configurationOptions, c => c.value === value);
		if (selectedConfigurationOption) {
			urlService.setUrlQueryString({
				configurationId: value
			});
			setState({
				...state,
				configurationId: selectedConfigurationId,
				configurationName: selectedConfigurationOption.label
			});
		}
	};

	const [state, setState] = React.useState(initState);

	React.useEffect(() => {
		if (importConfigurations) {
			let newConfigurationOptions: DropDownOption[] = [];
			newConfigurationOptions.push({
				label: localizationService.getLocalizedString('cccImportFile.Default'),
				value: '-1'
			});
			importConfigurations.forEach(configuration => {
				newConfigurationOptions.push({
					label: configuration.name,
					value: _.toString(configuration.importConfigurationId)
				});
				if (configuration.importConfigurationId === state.configurationId) {
					setState({ ...state, configurationName: configuration.name });
				}
			});
			setConfigurationOptions(newConfigurationOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [importConfigurations, state.configurationId]);

	const uploadFiles = async () => {
		let step1State = {
			...stepperContext.getStep(CccImportSteps.STEP1),
			loading: true,
			importConfigurationId: state.configurationId,
			importConfigurationName: state.configurationName,
			importOption: state.configurationOption
		};

		stepperContext.updateStep(CccImportSteps.STEP1, step1State);

		const url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Imports/Prepare`;
		let formData = new FormData();
		formData.append('files', selectedFile);
		if (state.configurationOption === ConfigurationOption.CHOOSE) {
			const configurationId = _.toString(state.configurationId);
			if (state.configurationId) {
				formData.append('ImportConfigurationId', configurationId);
			}
		} else {
			const configurationName = _.toString(state.configurationName);
			formData.append('ImportConfigurationName', configurationName);
		}
		const headers = {};

		return await apiService.postFormData(url, formData, headers);
	};

	const downloadExample = async (e: any) => {
		e.preventDefault();

		let url = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings/Imports/Example');
		return await apiService.downloadByUrl(url);
	};

	const onConfigurationOptionSelected = (e: any) => {
		setConfigurationNameError('');
		const { value } = e.target;
		setState({ ...state, configurationOption: value, configurationName: '' });
	};

	const onConfigurationNameChanged = (e: any) => {
		const { value } = e.target;
		setConfigurationNameError('');

		setState({ ...state, configurationName: value });
	};

	const newConfiguration = () => {
		return (
			<div className="form-row">
				<TextInput
					className="col"
					id="configurationName"
					isRequired
					name="configurationName"
					showErrorAndHelp={true}
					error={configurationNameError}
					label={localizationService.getLocalizedString('cccImportFile.newConfigurationName')}
					value={state.configurationName}
					helpText={localizationService.getLocalizedString('cccImportFile.newConfigurationHelp')}
					onChange={onConfigurationNameChanged}
				/>
			</div>
		);
	};

	const chooseConfiguration = () => {
		return (
			<div className="margin-top-1 form-row">
				<SingleSelectDropdown
					className="col"
					id="configurationId"
					name="configurationId"
					noEmptyOption={true}
					selfOrder={true}
					value={_.toString(state.configurationId)}
					label={localizationService.getLocalizedString('cccImportFile.configuration')}
					onChange={onConfigurationSelected}
					options={configurationOptions}
				/>
				{urlService.isAdministrator() && (
					<div className="col-12 ai-form-help">
						<a
							id="editConfigurationLink"
							href={urlService.getSettingMenuPath('import/settings')}
							onClick={(e: any) =>
								navigateTo(history, urlService.getSettingMenuPath('import/settings'), e)
							}>
							{localizationService.getLocalizedString('screen.labels.editConfiguration')}
						</a>
					</div>
				)}
			</div>
		);
	};

	const configurationOption = () => {
		return (
			<>
				{urlService.isAdministrator() && (
					<>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="configurationOptionChoose"
								value={ConfigurationOption.CHOOSE}
								checked={state.configurationOption === ConfigurationOption.CHOOSE}
								id="configurationOptionChoose"
								onChange={onConfigurationOptionSelected}
							/>
							<label className="custom-control-label" htmlFor="configurationOptionChoose">
								<span>{localizationService.getLocalizedString('cccImportFile.selectionExisting')}</span>
							</label>
						</div>

						<div className="custom-control custom-radio form-group">
							<input
								type="radio"
								className="custom-control-input"
								name="configurationOptionNew"
								value={ConfigurationOption.NEW}
								checked={state.configurationOption === ConfigurationOption.NEW}
								id="configurationOptionNew"
								onChange={onConfigurationOptionSelected}
							/>
							<label className="custom-control-label" htmlFor="configurationOptionNew">
								<span>{localizationService.getLocalizedString('cccImportFile.newConfiguration')}</span>
							</label>
						</div>
					</>
				)}
				{state.configurationOption === ConfigurationOption.CHOOSE && chooseConfiguration()}
				{state.configurationOption === ConfigurationOption.NEW && newConfiguration()}
			</>
		);
	};

	const validateConfiguration = (): boolean => {
		if (state.configurationOption === ConfigurationOption.NEW && !state.configurationName) {
			setConfigurationNameError(
				localizationService.getLocalizedString('cccImportFile.newConfigurationNameRequired')
			);
			return false;
		}
		return true;
	};

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();

		if (selectedFile === undefined || selectedFile === null) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('cccImportFile.noFileSelectedMessage'));
			alertService.addError(localizationService.getLocalizedString('cccImportFile.noFileSelectedMessage'));
			return;
		}

		if (!validateConfiguration()) {
			return;
		}

		let isFileInvalid = false;

		if (!validationService.validateUploadSize(selectedFile.size, UploadMaximumSize10MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit10MB')
			);
			isFileInvalid = true;
		}
		let filenameArray = selectedFile.name.split('.');
		let extension = filenameArray[filenameArray.length - 1];
		if (extension !== 'xlsx' && extension !== 'xls') {
			alertService.addError(localizationService.getLocalizedString('cccImportFile.excelFileFormatRequired'));
			isFileInvalid = true;
		}

		if (isFileInvalid) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('cccImportFile.invalidFileErrorMessage'));
			return;
		}
		uploadFiles()
			.then((response: CccImportSummary) => {
				if (state.configurationOption === ConfigurationOption.NEW) {
					const successMessage = localizationService.getLocalizedString(
						'alertMessages.importConfigurationSaveSucceeded',
						response.importConfigurationName
					);
					alertService.addSuccess(`${successMessage}`);
				}
				stepperContext.resolve({ selectedFile: selectedFile, ...response, ...state });
			})
			.catch((error: any) => {
				console.error(error);
				if (error instanceof ApiError) {
					if (error.statusCode === 413) {
						alertService.addError(
							localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
						);
					}
					if (error.statusCode === 400) {
						setConfigurationNameError(error.message);
						alertService.addError(error.message);
					} else {
						alertService.addError(error.message);
					}
				} else {
					alertService.addError(error.message.message);
				}

				let step1State = { ...stepperContext.getStep(CccImportSteps.STEP1), loading: false };
				stepperContext.updateStep(CccImportSteps.STEP1, step1State);
			});
	};

	const resectAllStepStates = () => {
		let step1State = { ...stepperContext.getStep(CccImportSteps.STEP1), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP1, step1State);
		let step2State = { ...stepperContext.getStep(CccImportSteps.STEP2), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP2, step2State);
		let step3State = { ...stepperContext.getStep(CccImportSteps.STEP3), completed: false, data: null };
		stepperContext.updateStep(CccImportSteps.STEP3, step3State);
	};

	const onChangeHandler = (event: any) => {
		event.preventDefault();

		const inputElement = event.target as HTMLInputElement;
		const files = inputElement.files as FileList;

		if (files == null || files.length < 1) {
			return;
		}

		setInvalidFileErrorMessage('');
		resectAllStepStates();

		setSelectedFile(files[0]);
	};

	return (
		<StepperContent
			id="step1ChooseFile"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="ai-file-input">
				<div className="file-input-group">
					<label className="btn ai-secondary" htmlFor="btnFileImport" aria-describedby="file-input-help">
						{localizationService.getLocalizedString('cccImportFile.chooseFile')}
					</label>
					<label className="file-name">{selectedFile && selectedFile.name}</label>
				</div>

				<input
					type="file"
					id="btnFileImport"
					className="file-input"
					onChange={onChangeHandler}
					accept=".xls,.xlsx"
					disabled={stepperContext.isLoading()}
				/>

				<div className="ai-form-help">
					{invalidFileErrorMessage && (
						<span className="ai-required">{_.capitalize(invalidFileErrorMessage)} &nbsp;</span>
					)}
					{localizationService.getLocalizedString('cccImportFile.step1DownLoadMessagePart1')}
					<a href="#/" onClick={downloadExample}>
						{localizationService.getLocalizedString('cccImportFile.step1DownLoadMessagePart2')}
					</a>
					&nbsp;
					{localizationService.getLocalizedString('cccImportFile.step1DownLoadMessagePart3')}
				</div>
			</div>
			<div className="margin-top-1">{configurationOption()}</div>
		</StepperContent>
	);
};

export default Step1;
