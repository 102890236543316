// This stepper is modified from React Material Stepper and removed all material design and make compatible with TypeScript
// More information about the functionality see https://github.com/apuchenkin/react-stepper

import classnames from 'classnames';
import * as React from 'react';
import StepperProvider, { OnReject, OnResolve, StepperController } from './context';
import Header from './header';
import { StepId } from './typings';

interface Props {
	initialStep: StepId;
	onResolve?: OnResolve;
	onReject?: OnReject;
	contextRef?: React.MutableRefObject<StepperController>;
	className?: string;
	vertical?: boolean;
}

const CLASSNAME = 'stepper';

const Stepper: React.FunctionComponent<Props> = ({
	onResolve,
	onReject,
	initialStep,
	className,
	vertical = false,
	contextRef,
	children
}) => (
	<StepperProvider initialStep={initialStep} onResolve={onResolve} onReject={onReject} contextRef={contextRef}>
		{({ getSteps, getCurrentStep }) => {
			const currentStep = getCurrentStep();
			const steps = getSteps();
			const horizontal = !vertical;

			return (
				<div
					className={classnames('stepper', className, {
						[`stepper--horizontal`]: horizontal,
						[`stepper--vertical`]: vertical
					})}>
					{children}
					<header className={`${CLASSNAME}__header`}>
						{steps.map((step, idx) => (
							<React.Fragment key={step.stepId}>
								<Header stepId={step.stepId} index={idx + 1} />
								{idx + 1 < steps.length && <hr className={`${CLASSNAME}__connector`} />}
							</React.Fragment>
						))}
					</header>
					{steps.map((step, idx) => (
						<React.Fragment key={step.stepId}>
							{vertical && <Header stepId={step.stepId} index={idx + 1} />}
							<div className={`${CLASSNAME}__section`}>
								{vertical && idx + 1 < steps.length && <hr className={`${CLASSNAME}__connector`} />}
								{step === currentStep && step.children}
							</div>
						</React.Fragment>
					))}
				</div>
			);
		}}
	</StepperProvider>
);

export default Stepper;
