import _ from 'lodash';
import { AlertActionType } from './alert-reducer';
import { dispatchAccessor, localizationService, Logger } from 'src/services';
import { ApiError } from '@rcp/types';

export enum AlertMessageType {
	Error = 'Error',
	Warning = 'Warning',
	Success = 'Success',
	Info = 'Info'
}

export interface AlertMessage {
	message: string;
	type: AlertMessageType;
	timeoutInMilliseconds: number;
	created: number;
	updated: number;
	count: number;
}

export interface AlertState {
	messages: AlertMessage[];
}

let timer: any;

class AlertService {
	private queue: AlertMessage[] = [];
	private manuallyDismissDelay = 0;
	private defaultAutoClearDelay = 5000;

	private notifyAlertUpdated() {
		if (dispatchAccessor.dispatch) {
			dispatchAccessor.dispatch({ type: AlertActionType.AlertUpdated });
		} else {
			throw new Error('setDispatch has to be called after create redux store.');
		}
	}

	private pushNewMessageToQueue(
		message: string,
		type: AlertMessageType,
		timeoutInMilliseconds: number
	): AlertMessage {
		var messageObject: AlertMessage = {
			message: message,
			type: type,
			timeoutInMilliseconds: timeoutInMilliseconds,
			created: Date.now(),
			updated: Date.now(),
			count: 1
		};
		this.queue.push(messageObject);
		return messageObject;
	}

	private updateCountAndTimestampOnDuplicateMessage(messageObject: AlertMessage) {
		messageObject.count += 1;
		messageObject.updated = Date.now();
		if (messageObject.type === AlertMessageType.Success) {
			clearTimeout(timer);
			this.setAutoClearTimer(messageObject);
		}
	}

	private setAutoClearTimer(messageObject: AlertMessage) {
		const service = this;
		timer = setTimeout(() => {
			service.removeMessage(messageObject);
		}, messageObject.timeoutInMilliseconds);
	}

	private addMessage(message: string, type: AlertMessageType, timeoutInMilliseconds?: number): AlertMessage {
		const autoClearInMilliseconds =
			timeoutInMilliseconds !== undefined ? timeoutInMilliseconds : this.defaultAutoClearDelay;
		const duplicateMessage = _.find(this.queue, { message: message, type: type });
		let messageObject: AlertMessage;
		if (_.isUndefined(duplicateMessage)) {
			messageObject = this.pushNewMessageToQueue(message, type, autoClearInMilliseconds);
			if (autoClearInMilliseconds > 0) {
				this.setAutoClearTimer(messageObject);
			}
		} else {
			messageObject = duplicateMessage;
			this.updateCountAndTimestampOnDuplicateMessage(duplicateMessage);
		}
		return messageObject;
	}

	removeMessage(messageObject: AlertMessage) {
		let index = this.queue.indexOf(messageObject);

		if (index > -1) {
			this.queue.splice(index, 1);
			this.notifyAlertUpdated();
		}
	}

	clearAllMessages() {
		this.queue = [];
		this.notifyAlertUpdated();
	}

	clearMessageByType(type: AlertMessageType) {
		_.remove(this.queue, (messageObject: AlertMessage) => {
			return messageObject.type === type;
		});
		this.notifyAlertUpdated();
	}

	private getSafeMessage(message: string | undefined | null): string {
		const minimumErrorMessageSize = 3;
		if (_.isEmpty(message) || _.size(message) <= minimumErrorMessageSize) {
			return localizationService.getLocalizedString('errors.somethingWrong');
		}
		return message as string;
	}

	addError(message: string, error?: any, autoHide = false): void {
		Logger.error('Unexpected error occurred:', message, error);
		this.addMessage(this.getSafeMessage(message), AlertMessageType.Error, this.manuallyDismissDelay);
		this.notifyAlertUpdated();
	}
	addWarning(message: string, error?: any): void {
		Logger.warn('Unexpected warning occurred:', message, error);
		this.addMessage(this.getSafeMessage(message), AlertMessageType.Warning);
		this.notifyAlertUpdated();
	}
	addSuccess(message: string): void {
		this.addMessage(message, AlertMessageType.Success);
		this.notifyAlertUpdated();
	}
	addInfo(message: string): void {
		this.addMessage(message, AlertMessageType.Info);
		this.notifyAlertUpdated();
	}

	alertError(error: any): void {
		let errorMessage = _.isString(error) ? (error as string) : (_.get(error, 'message') as string);
		if (!errorMessage) {
			Logger.warn(`Got unexpected error:`, error);
			return;
		}
		Logger.error('Unexpected error occurred:', errorMessage, error);
		this.addMessage(this.getSafeMessage(errorMessage), AlertMessageType.Error, this.manuallyDismissDelay);
		this.notifyAlertUpdated();
	}

	getAlertState(): AlertState {
		return { messages: [...this.queue] };
	}
}

export const alertService = new AlertService();

export const initialAlertState: AlertState = alertService.getAlertState();
