import { ColumnField, FilterObjectName, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import React, { FC } from 'react';
import { GridOption } from 'src/features/data-grid/data-grid';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { localizationService, localStorageService, navigateTo, Resource, urlService } from 'src/services';
import IppReportPackageReviewsComponent from './reviews';
import { nameof } from 'ts-simple-nameof';
interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppReportPackagesAllReviews: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getReactAuthorityResourceUrl(
			`authority/${Resource.IppReportPackages}`,
			dataItem.reportPackageId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showTabList: false,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageAll,
		filterStorageName: LocalStorageName.IppReportPackageAllGridFilterUrl,
		searchHint: localizationService.getLocalizedString('ipp.reportPackage.package.searchHint'),
		showActions: false,
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.All'),
		activeTab: 'tab-list',
		sort: localStorageService.getLocalStorage(LocalStorageName.IppAllReportPackageSortOrder) || [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		filterOnlyThisGrid: true,
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.All,
			sort: 'submissionDateTimeLocal desc'
		},
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		disableFilterWarning: true,
		gridUrlLocalStorageName: LocalStorageName.IppReportPackageAllGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPALLREPORTPACKAGE,
				FilterDomainName.ippAllReportPackages,
				FilterObjectName.IppAllReportPackage,
				LocalStorageName.IppReportPackageAllGridFilterUrl,
				{ IPPALLREPORTPACKAGE: true }
			);
		},
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('ctsEventTypeName'),
			new ColumnField('ctsEventCategoryName'),
			new ColumnField('isSubmissionBySignatoryRequired', FilterType.Boolean),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('submitterUserName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField('repudiationDateTimeLocal', FilterType.Date),
			new ColumnField('lastSentDateTimeLocal', FilterType.Date),
			new ColumnField('repudiatorFirstName'),
			new ColumnField('repudiatorLastName'),
			new ColumnField('repudiationReasonName'),
			new ColumnField('repudiationComments'),
			new ColumnField('repudiationReviewerFirstName'),
			new ColumnField('repudiationReviewerLastName'),
			new ColumnField('repudiationReviewComments'),
			new ColumnField('assignedTo'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'organizationReferenceNumber',
			'organizationName',
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal',
			'repudiationDateTimeLocal',
			'lastSentDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			sortOrderLocalStorageName={LocalStorageName.IppAllReportPackageSortOrder}
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppReportPackagesAllReviews;
