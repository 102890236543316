import React, { useEffect, useState } from 'react';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import './select-option-editor.scss';
import _ from 'lodash';
import { alertService } from '../../../../../redux/alert';
import { DropdownValueCountDto } from '@rcp/types/src';

export interface SelectOptionProps {
	defaultValueOrOptionalValues: string;
	inUsedValues: string[];
	onChanged: (value: string, originalOptions: SelectOption[], newOptions: SelectOption[]) => void;
	label: string;
	editable: boolean;
	textError: string;
	onTextChanged: (value: string) => void;
	dropdownValueCounts: DropdownValueCountDto[];
	onSetNewOptions: (originalOptions: SelectOption[], newOptions: SelectOption[]) => void;
}

export interface SelectOption {
	value: string;
	index: number;
	isDefault: boolean;
	valueCount: number;
	changeTrackingId: number;
	isInUse?: boolean;
}

export interface SelectOptionState {
	options: SelectOption[];
	deleteModal: boolean;
	editModal: boolean;
	editText: string;
	editError: string;
	selectedOption: SelectOption;
	error: string;
	text: string;
	firstOptionDefault: boolean;
}

const sortOptions = (options: SelectOption[]) => {
	return (options = _.orderBy(options, ['value'], ['asc']));
};

const SelectOptionEditor: React.FunctionComponent<SelectOptionProps> = props => {
	const duplicateOptionError = localizationService.getLocalizedString('authoritySetting.duplicateOptionError');

	const initialState: SelectOptionState = {
		options: [],
		deleteModal: false,
		editModal: false,
		error: '',
		text: '',
		editText: '',
		editError: '',
		firstOptionDefault: false,
		selectedOption: { value: '', index: 0, isDefault: false, valueCount: 0, changeTrackingId: 0 }
	};

	const label = localizationService.getLocalizedString('authoritySetting.option');

	const [state, setState] = useState(initialState);
	const [nextChangeTrackingId, setNextChangeTrackingId] = useState(0);
	const [originalOptions, setOriginalOptions] = useState([] as SelectOption[]);

	useEffect(() => {
		let options: SelectOption[] = [];
		if (!_.isEmpty(props.defaultValueOrOptionalValues)) {
			let inUsedValuesInLower = props.inUsedValues.map(v => v.toLowerCase());
			options = props.defaultValueOrOptionalValues.split(',').map((s, index) => {
				if (index > nextChangeTrackingId) {
					setNextChangeTrackingId(index);
				}

				return {
					value: s,
					index: 0,
					isDefault: false,
					isInUse: _.includes(inUsedValuesInLower, _.toLower(s)),
					valueCount: 0,
					changeTrackingId: index
				};
			});
		}
		if (!_.isEmpty(props.dropdownValueCounts)) {
			props.dropdownValueCounts.forEach(dto => {
				let option = options.find(a => a.value === dto.value);
				if (option) {
					option.valueCount = dto.valueCount;
				}
			});
		}
		options = sortOptions(options);
		setOriginalOptions(_.cloneDeep(options));

		setState({ ...state, options: options });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onEditClicked = (option: SelectOption, index: number) => {
		option.index = index;
		setState({
			...state,
			selectedOption: option,
			editText: option.value,
			editModal: true,
			editError: ''
		});
	};
	const onDeleteClicked = (option: SelectOption, index: number) => {
		option.index = index;
		setState({ ...state, selectedOption: option, deleteModal: true });
	};
	const getTitleRow = () => {
		return (
			<thead>
				<tr className="lookup-row lookup-header-row font-size-14px-semibold">
					<th>{label}</th>
					{props.editable && (
						<>
							<th className="action-2-buttons"></th>
							<th className="action-2-buttons"></th>
						</>
					)}
				</tr>
			</thead>
		);
	};

	const getRows = () => {
		return state.options.map((option, index) => {
			return (
				<tr className="lookup-row" key={`option-key-${index}`}>
					<td id={`option_value_${index}`}>{option.value}</td>

					{props.editable && (
						<>
							<td align="right">
								<button onClick={() => onEditClicked(option, index)} disabled={!props.editable}>
									<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon " />
								</button>
							</td>
							<td>
								{option.valueCount === 0 && option.isInUse !== true && (
									<button onClick={() => onDeleteClicked(option, index)} disabled={!props.editable}>
										<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
									</button>
								)}
							</td>
						</>
					)}
				</tr>
			);
		});
	};

	const indexOfOption = (value: string): number => {
		for (let i = 0; i < state.options.length; i++) {
			if (String.equalCaseInsensitive(state.options[i].value, value)) {
				return i;
			}
		}
		return -1;
	};

	const onOptionKeyPressed = (e: any) => {
		if (String.equalCaseInsensitive(e.key, 'Enter')) {
			onAddNewOption(null);
		}
	};

	const onAddNewOption = (evt: any) => {
		if (!state.text || _.isEmpty(state.text.trim())) {
			const error = localizationService.getLocalizedString('authoritySetting.customFieldOptionError');
			alertService.addError(error);
			setState({ ...state, error: error });
			return;
		}
		let newChangeTrackingId = nextChangeTrackingId + 1;
		let option = { ...initialState.selectedOption, value: state.text, changeTrackingId: newChangeTrackingId };
		if (indexOfOption(option.value) >= 0) {
			setState({ ...state, error: duplicateOptionError });
		} else {
			const options = state.options;
			options.push(option);
			refreshOptions(options, {});
			setNextChangeTrackingId(newChangeTrackingId);
		}
	};

	const updateSelectOption = () => {
		const value = state.options.map(a => a.value).join(',');
		props.onChanged(value, originalOptions, state.options);
	};

	const refreshOptions = (options: SelectOption[], others: Object) => {
		setState({
			...state,
			options: sortOptions(options),
			...others,
			text: ''
		});
		updateSelectOption();
	};

	const onDeleteConfirmed = () => {
		const options = state.options;
		options.splice(state.selectedOption.index, 1);
		refreshOptions(options, { deleteModal: false });
	};

	const showDeleteModel = (b: boolean) => {
		setState({ ...state, deleteModal: b });
	};

	const onEditConfirmed = () => {
		const index = state.selectedOption.index;

		if (!state.editText || _.isEmpty(state.editText.trim())) {
			const error = localizationService.getLocalizedString('authoritySetting.customFieldOptionError');
			alertService.addError(error);
			setState({ ...state, editError: error });
			return;
		}

		const editIndex = indexOfOption(state.editText);
		if (index !== editIndex && editIndex >= 0) {
			setState({ ...state, editError: duplicateOptionError });
			return;
		}
		const options = state.options;
		options[index].value = state.editText;
		refreshOptions(options, { editModal: false });
	};

	const onOptionChanged = (evt: any) => {
		let { value } = evt.target;
		setState({ ...state, text: value, error: '' });
		if (props.onTextChanged) {
			props.onTextChanged(value);
		}
	};

	const clearError = () => {
		setState({ ...state, error: '' });
	};

	const getInput = () => {
		return (
			<>
				<input
					id="idOption"
					name="idOption"
					data-piggyback="Option"
					type="text"
					className={'form-control value ' + (state.error || props.textError ? ' is-invalid' : '')}
					value={state.text}
					placeholder={''}
					onChange={onOptionChanged}
					onKeyPress={(evt: any) => onOptionKeyPressed(evt)}
					onFocus={() => clearError()}
					autoComplete={'new-password'}
				/>
				{(state.error || props.textError) && (
					<div className="invalid-feedback">{state.error || props.textError}</div>
				)}
			</>
		);
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.deleteSelectOptionTitle')}
				showModal={state.deleteModal}
				cancel={() => showDeleteModel(false)}
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				saveButtonClassName="ai-delete ml-auto"
				save={onDeleteConfirmed}>
				<p>
					{localizationService.getLocalizedString(
						'authoritySetting.deleteSelectOptionDesc',
						state.selectedOption.value
					)}
				</p>
			</PopoverModal>
		);
	};

	const onEditOptionChanged = (evt: any) => {
		let { value } = evt.target;
		setState({ ...state, editText: value, editError: '' });
	};

	const onEditCancel = () => {
		setState({ ...state, editModal: false });
	};

	const getEditModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('authoritySetting.editSelectionOptionTitle')}
				showModal={state.editModal}
				cancel={onEditCancel}
				saveButtonText={localizationService.getLocalizedString('screen.buttons.save')}
				save={onEditConfirmed}>
				<TextInput
					id="editText"
					name="editText"
					value={state.editText}
					isRequired={false}
					onChange={onEditOptionChanged}
					error={state.editError}
					label={localizationService.getLocalizedString('authoritySetting.option')}
				/>
			</PopoverModal>
		);
	};

	const getSelectView = () => {
		return (
			<>
				<div className="table-responsive">
					<table className="table">
						{getTitleRow()}
						<tbody>{getRows()}</tbody>
					</table>
				</div>
			</>
		);
	};

	return (
		<>
			<div>
				<div>
					<div className="flex-fill">
						<div className="form-group">
							<label htmlFor="idOption">{label}</label>
							<div className="d-flex">
								<div className="d-flex flex-fill flex-row ">
									<div className="flex-fill mr-2">{getInput()}</div>
								</div>
								<div className="ml-auto">
									<button className="btn ai-new mb-1 " id="btnAdd" onClick={onAddNewOption}>
										{localizationService.getLocalizedString('authoritySetting.addBtnText')}
									</button>
								</div>
							</div>
						</div>
					</div>

					{state.options.length > 0 && getSelectView()}
				</div>
				{state.deleteModal && getDeleteModal()}
				{state.editModal && getEditModal()}
			</div>
		</>
	);
};

export default SelectOptionEditor;
