import React from 'react';
import { localizationService } from 'src/services';
import { useDispatch, useSelector } from 'react-redux';
import { CccHazard, CccSiteContact } from '@rcp/types';
import { HazardContactCard } from './hazard-contact-card';
import { cccHazardSlice } from '../hazards.slice';
import { siteSlice } from '../../sites/sites.slice';
import { SingleCheckbox } from 'src/components/widgets';
import {
	SiteContactType,
	extractSiteContact,
	ContactModal,
	cccHazardContactsSlice
} from 'src/features';
import { RootState } from 'src/redux';
import _ from 'lodash';

import './hazard-contacts-tab.scss';

interface OwnProps {
	hazard: CccHazard;
}

type Props = OwnProps;
export const HazardContactsTab: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [contactList, setContactList] = React.useState<CccSiteContact[]>([]);

	let hazardContactsState = (state: RootState) => state.hazardContacts;
	let hazardContactList = useSelector(hazardContactsState).result;
	let hazardSiteState = (state: RootState) => state.cccSites;
	let hazardSite = useSelector(hazardSiteState).selected;

	const loadHazardContacts = () => {
		dispatch(siteSlice.fetchOne(props.hazard.siteId as number));
		dispatch(cccHazardContactsSlice.fetchAll(`hazardId=${props.hazard.hazardId}`));
	};

	React.useEffect(() => {
		loadHazardContacts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.hazard]);

	React.useEffect(() => {
		let newContacts: CccSiteContact[] = [];
		if (!_.isEmpty(hazardContactList)) {
			newContacts = newContacts.concat(hazardContactList);
			newContacts.sort((a, b) => {
				if (a.contactType && b.contactType) {
					if (a.contactType < b.contactType) {
						return -1;
					}
					if (a.contactType > b.contactType) {
						return 1;
					}
				}
				return 0;
			});
		}

		if (!_.isEmpty(hazardSite) && hazardSite) {
			let siteContact: CccSiteContact = extractSiteContact(hazardSite, props.hazard);
			newContacts.unshift(siteContact);
		}
		setContactList(newContacts);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hazardSite, hazardContactList]);

	const [showAddContactModal, setShowAddContactModal] = React.useState(false);
	const onToggleAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		setShowAddContactModal(!showAddContactModal);
	};

	const switchHazardSendNotices = (e: any) => {
		const { checked } = e.target;
		dispatch(
			cccHazardSlice.patchOne(
				props.hazard.hazardId as number,
				{ sendTestNotices: checked },
				false,
				localizationService.getLocalizedString(
					'alertMessages.updatedContactNoticesUpdatedSucceeded',
					'cccHazard.hazard'
				)
			)
		);
	};

	const switchHazardContactSendNotices = (hazardContact: CccSiteContact, checked: boolean) => {
		if (hazardContact.sendNotices === checked) {
			return;
		}
		if (hazardContact.linkedContactId && hazardContact.linkedContactId > 0) {
			dispatch(
				cccHazardContactsSlice.patchOne(
					hazardContact.linkedContactId,
					{ sendNotices: checked },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.contact'
					),
					() => {
						loadHazardContacts();
						if (props.hazard.hazardId) {
							dispatch(cccHazardSlice.fetchOne(props.hazard.hazardId));
						}
					}
				)
			);
			return;
		}
		if (String.equalCaseInsensitive(hazardContact.contactType, SiteContactType)) {
			dispatch(
				cccHazardSlice.patchOne(
					props.hazard.hazardId as number,
					{ sendTestNoticesToSite: checked },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.site'
					),
					() => {
						loadHazardContacts();
						if (props.hazard.hazardId) {
							dispatch(cccHazardSlice.fetchOne(props.hazard.hazardId));
						}
					}
				)
			);
			return;
		}
	};

	return (
		<>
			<div className="hazard-notice">
				<SingleCheckbox
					id="hazardSendNotices"
					name="hazardSendNotices"
					label={localizationService.getLocalizedString('cccHazard.hazardSendNoticesCheckBoxLabel')}
					checked={props.hazard.sendTestNotices}
					onChange={switchHazardSendNotices}
					className="hazard-notice-checkbox"
				/>
			</div>
			<div className="row">
				{contactList &&
					contactList.length > 0 &&
					contactList.map((item: CccSiteContact, index: number) => (
						<HazardContactCard
							contact={item}
							key={index}
							switchSendNotice={switchHazardContactSendNotices}
							hazardSendNotice={!!props.hazard.sendTestNotices}
							reload={loadHazardContacts}
						/>
					))}
			</div>
			<a href="#/" className="ai-add-link" onClick={onToggleAddModal}>
				{localizationService.getLocalizedString('screen.buttons.addContact')}&#8230;
			</a>
			{showAddContactModal && (
				<ContactModal
					close={() => {
						setShowAddContactModal(false);
					}}
					reload={loadHazardContacts}
					linkedContact={{
						siteId: props.hazard.siteId,
						hazardId: props.hazard.hazardId,
						hazardNumber: props.hazard.hazardNumber
					}}
				/>
			)}
		</>
	);
};
