import React, { useEffect } from 'react';
import * as ApiTypes from '@rcp/types';
import { Extractor, FeatureNames, CleaningEvent, EntityContact_EntityType, LetterTemplateCategory } from '@rcp/types';
import { RootState, useReduxSelector } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import { apiService, DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import _ from 'lodash';
import { fogDevicesSlice } from '../devices.slice';
import { FaCommentAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import { DeviceActionDropdown } from './device-action-dropdown-menu';
import { DeviceTabs } from './device-details-tabs';
import { CleaningModal } from 'src/components/authority/fog/cleaning/cleaning-modal';
import { Timeline } from 'src/features/timeline/timeline';
import { timelineEventsSlice } from 'src/features/timeline/timeline-events.slice';
import { cleaningSlice } from 'src/components/authority/fog/cleaning';
import Comment from 'src/features/comments/comment';
import { commentsSlice } from 'src/features/comments/comments.slice';
import { GreaseTrap } from 'src/components/svg';
import { LastHaulerRightPanel } from 'src/components/authority/fog/facilities/facility-details/hauler/last-hauler-component';
import { NotFound } from 'src/features/home/not-found';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { entityContactsSlice } from '../../../../../features/contacts/entity-contacts.slice';
import { facilityContactsSlice } from '../../facilities/facility-contacts-slice';
import { FacilitySendEmail } from '../../facilities/facility-details/send-email';
import { DeviceOwnerCard } from './device-owner-card';
import { fogFacilitiesSlice } from '../../facilities';
import { DeviceAttachments } from './device-attachments';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

const FogDeviceDetails: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [currentDevice, setCurrentDevice] = React.useState({} as Extractor);
	const [scheduledCleaning, setScheduledCleaning] = React.useState({} as CleaningEvent);
	const [showEnterCleaningModal, setShowEnterCleaningModal] = React.useState(false);
	const [showInvalidUrlMessage, setShowInvalidUrlMessage] = React.useState(false);
	const [commentModal, setCommentModal] = React.useState<boolean>(false);
	const [sendEmail, setSendEmail] = React.useState<boolean>(false);
	const [sendEmailAnchor, setSendEmailAnchor] = React.useState<any>(null);
	const [deviceContactNotAvailableToSendNotice, setDeviceContactNotAvailableToSendNotice] = React.useState(false);
	const [deviceCommentAnchor, setDeviceCommentAnchor] = React.useState(document.createElement('div'));

	const history = useHistory();
	const showInDevelopmentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowInDevelopmentFeature]
	);

	let facilityContacts = useSelector((state: RootState) => state.fogFacilityContacts.result);
	let deviceContacts = useSelector((state: RootState) => state.entityContacts.result);

	let deviceState = (state: RootState) => state.fogDevices;
	let { selected, loading } = useSelector(deviceState);

	const loadDeviceContacts = (device?: any) => {
		facilityContactsSlice.setApiUrlPath(Resource.FogFacilities + '/' + device.facilityId + '/' + Resource.Contacts);
		entityContactsSlice.setApiUrlPath(Resource.FogDevices + '/' + device.extractorId + '/' + Resource.Contacts);
		dispatch(fogFacilitiesSlice.fetchOne(device.facilityId as number));
		dispatch(facilityContactsSlice.fetchAll());
		dispatch(entityContactsSlice.fetchAll());
	};

	useEffect(() => {
		let newContacts: ApiTypes.EntityContact[] = [];
		if (!_.isEmpty(deviceContacts)) {
			newContacts = [...deviceContacts];
		}
		if (!_.isEmpty(facilityContacts) && selected) {
			newContacts = newContacts.concat(
				facilityContacts.map(obj => ({
					...obj,
					entityType: EntityContact_EntityType.FogFacility,
					sendNotices: _.isEmpty(obj.jsonCleaningNotices)
						? false
						: JSON.parse(obj.jsonCleaningNotices as string).findIndex(
								(a: any) => a.DeviceId == selected.extractorId && a.SendNotices == true
						  ) > -1
						? true
						: false
				}))
			);
		}
		if (currentDevice.sendCleaningNoticeToDeviceOwner) {
			setDeviceContactNotAvailableToSendNotice(false);
		} else {
			setDeviceContactNotAvailableToSendNotice(
				newContacts.every(contact => {
					return !contact.sendNotices;
				})
			);
		}
	}, [facilityContacts, deviceContacts, selected, currentDevice]);

	useEffect(() => {
		let deviceId = urlService.getFogDeviceIdOrThrowError();

		timelineEventsSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/${Resource.TimelineEvents}`
		);

		cleaningSlice.setApiUrlPath(
			`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/${Resource.CleaningsByDomainObject}`
		);

		commentsSlice.setApiUrlPath(`${urlService.getDomainObjectName()}/${urlService.getDomainObjectId()}/comments`);

		dispatch(fogDevicesSlice.fetchOne(deviceId));
		dispatch(cleaningSlice.fetchAll());
	}, [dispatch, urlService.getFogDeviceId()]);

	useEffect(() => {
		if (selected) {
			if (!selected.extractorId) return;
			setCurrentDevice(selected);
			loadDeviceContacts(selected);
			window.scrollTo(0, 0);
		} else {
			setShowInvalidUrlMessage(true);
		}
	}, [selected]);

	let toggleCommentModal = () => {
		setCommentModal(!commentModal);
	};

	let onCommentDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		setCommentModal(!commentModal);
		setDeviceCommentAnchor(e.target as HTMLDivElement);
	};

	const toggleEnterCleaningModal = () => {
		setShowEnterCleaningModal(!showEnterCleaningModal);
	};

	React.useEffect(() => {
		if (showEnterCleaningModal && currentDevice.nextCleaningId) {
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${currentDevice.facilityId}/${Resource.Cleanings}/${
				currentDevice.nextCleaningId
			}`;
			apiService.getResource<CleaningEvent>(url).then(scheduleCleaning => {
				setScheduledCleaning(scheduleCleaning);
			});
		} else {
			setScheduledCleaning({} as CleaningEvent);
		}
	}, [showEnterCleaningModal]);

	const onEnterCleaning = () => {
		setCommentModal(false);
		setShowEnterCleaningModal(!showEnterCleaningModal);
	};

	return (
		<>
			{_.isEmpty(currentDevice) && !loading ? (
				<>{showInvalidUrlMessage && <NotFound />}</>
			) : (
				<div className="page">
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString('extractor.device')}&nbsp;
							<span id="deviceNumberLabel">{currentDevice.deviceNumber}</span>
							{currentDevice && !currentDevice.isActive && (
								<span className="text-dark">
									{' - ' + localizationService.getLocalizedString('extractor.isInactive')}
								</span>
							)}
						</h1>
					</div>
					<div className="d-flex flex-row top-action-div">
						<div className="form-row w-100 action-panel align-items-center">
							{showInDevelopmentFeature && (
								<div
									onClick={e => {
										setSendEmail(true);
										setSendEmailAnchor(e && e.target);
									}}
									className="action-item"
									id="sendEmailLink">
									<FaEnvelope className="ai-slate" />
									<span>{localizationService.getLocalizedString('screen.labels.sendEmail')}</span>
								</div>
							)}

							{currentDevice.sendCleaningNotices && !deviceContactNotAvailableToSendNotice && (
								<div
									className="action-item"
									id="sendNoticeLink"
									onClick={() => {
										const url = urlService.getReactResourceUrl(
											Resource.Devices,
											urlService.getFogDeviceId()
										);
										navigateTo(history, `${url}/sendCleaningNotice`);
									}}>
									<FontAwesomeIcon
										style={{
											height: '0.8rem',
											marginTop: '3px',
											color: '#4b6a88'
										}}
										icon={faPaperPlane}
										className="font-awesome-icon"
									/>
									<span>{localizationService.getLocalizedString('screen.labels.sendNotice')}</span>
								</div>
							)}

							{showInDevelopmentFeature && (
								<>
									<div className="action-item" id="logCallLink">
										<FaPhone className="ai-slate" />
										<span>{localizationService.getLocalizedString('screen.labels.logACall')}</span>
									</div>
									<div className="action-item" id="mailALetterLink">
										<FaEnvelope className="ai-slate" />
										<span>
											{localizationService.getLocalizedString('screen.labels.mailALetter')}
										</span>
									</div>
								</>
							)}
							<div className="action-item" id="commentDiv" onClick={onCommentDivClick}>
								<FaCommentAlt className="ai-slate" />
								<span>{localizationService.getLocalizedString('screen.labels.comment')}</span>
							</div>
							{currentDevice && currentDevice.isActive && (
								<div className="action-item" id="enterCleaningDiv" onClick={onEnterCleaning}>
									<GreaseTrap hasSlateFill={true} />
									<span>{localizationService.getLocalizedString('extractor.enterCleaning')}</span>
								</div>
							)}
							<DeviceActionDropdown currentDevice={currentDevice} history={props.history} />
						</div>
					</div>
					<div className="page-wrapper">
						<div className="main">
							<DeviceTabs currentDevice={currentDevice} />
							<Timeline />
						</div>
						<div className="sidebar">
							<DeviceOwnerCard device={currentDevice} />
							<LastHaulerRightPanel
								hauler={{
									haulerId: currentDevice.lastHaulerId,
									haulerNumber: currentDevice.lastHaulerNumber,
									name: currentDevice.lastHaulerName,
									phone: currentDevice.lastHaulerPhoneNumber,
									email: currentDevice.lastHaulerEmailAddress,
									addressLine1: currentDevice.lastHaulerAddressLine1,
									addressLine2: currentDevice.lastHaulerAddressLine2,
									addressCity: currentDevice.lastHaulerCityName,
									addressJurisdictionCode: currentDevice.lastHaulerJurisdictionCode,
									addressZipCode: currentDevice.lastHaulerZipCode,
									isPreferred: currentDevice.isPreferredLastHauler,
									isRemoved: currentDevice.isRemovedLastHauler,
									isActive: currentDevice.isActiveLastHauler
								}}
							/>
							<section>
								<div id="cleaningSummary" className="d-flex">
									<h3>{localizationService.getLocalizedString('extractor.cleanings')}</h3>
								</div>

								<p>
									<strong>
										{localizationService.getLocalizedString('extractor.nextCleaningDue')}
									</strong>
									<br />
									{currentDevice.nextCleaningDate ? (
										DateUtilService.toDisplayDate(currentDevice.nextCleaningDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>
										{localizationService.getLocalizedString('extractor.lastCleaningDue')}
									</strong>
									<br />
									{currentDevice.lastCleaningDate ? (
										DateUtilService.toDisplayDate(currentDevice.lastCleaningDate)
									) : (
										<></>
									)}
								</p>
							</section>

							<section>
								<h3>{localizationService.getLocalizedString('extractor.cleaningNotices')}</h3>
								<p>
									<strong>
										{localizationService.getLocalizedString('extractor.nextNoticeDate')}
									</strong>
									<br />
									{currentDevice.nextNoticeDate ? (
										DateUtilService.toDisplayDate(currentDevice.nextNoticeDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>{localizationService.getLocalizedString('extractor.nextNotice')}</strong>
									<br />
									{currentDevice.nextNoticeTemplateName ? (
										currentDevice.nextNoticeTemplateName
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>
										{localizationService.getLocalizedString('extractor.lastNoticeDate')}
									</strong>
									<br />
									{currentDevice.lastNoticeDate ? (
										DateUtilService.toDisplayDate(currentDevice.lastNoticeDate)
									) : (
										<></>
									)}
								</p>

								<p>
									<strong>{localizationService.getLocalizedString('extractor.lastNotice')}</strong>
									<br />
									{currentDevice.lastNoticeTemplateName ? (
										currentDevice.lastNoticeTemplateName
									) : (
										<></>
									)}
								</p>
							</section>
							<DeviceAttachments
								device={currentDevice}
								attachmentType={ApiTypes.AttachmentType.FogDevice}
								ownerId={currentDevice.extractorId}
							/>
						</div>
					</div>

					{showEnterCleaningModal && (
						<CleaningModal
							isEnterCleaning={true}
							facilityId={currentDevice.facilityId as number}
							device={currentDevice}
							cleaning={scheduledCleaning}
							isDevicePage={true}
							isTimelineReloadRequired={true}
							dueDateIsRequired={currentDevice.nextCleaningId ? true : false}
							completeDateIsRequired={true}
							modalToggleFunction={toggleEnterCleaningModal}
							isAddModal={true}
						/>
					)}
				</div>
			)}
			<div>
				{commentModal && (
					<Comment show={commentModal} anchor={deviceCommentAnchor} toggle={toggleCommentModal} />
				)}
				{sendEmail && (
					<FacilitySendEmail
						show={sendEmail}
						anchor={sendEmailAnchor}
						toggle={() => {
							setSendEmail(!sendEmail);
						}}
						letterTemplateCategory={LetterTemplateCategory.DeviceEmail}
						isDeviceDetail={true}
					/>
				)}
			</div>
		</>
	);
};

export default FogDeviceDetails;
