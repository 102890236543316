import { FacilityCleaningManifest } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { localizationService, Resource } from '../../../../services';

export const facilityCleaningHistorySlice = new RestSlice<FacilityCleaningManifest>(
	localizationService.getLocalizedString('haulerPortal.selectFacility.facilities'),
	Resource.FacilityCleaningHistory
);

export const facilityCleaningHistoryRestSliceReducer = facilityCleaningHistorySlice.rests.reducer;
