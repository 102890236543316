import { LookupRow } from '../lookup-types';
import { LookupType, Hauler } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class HaulerRowLookDataConverter implements ILookupDataConverter {
	lookupType: LookupType;

	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: Hauler): LookupRow => {
		return {
			lookupId: apiData.haulerId,
			code: apiData.name,
			isActive: apiData.isActive
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let hauler = {
			unitId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			isActive: rowToUpdate.isActive
		} as Hauler;
		return hauler;
	}

	toLookupRowsFromAPI(apiData: Hauler[]): LookupRow[] {
		return apiData && apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): Hauler {
		let hauler = {
			name: newValue.code,
			description: newValue.codeDescription,
			isActive: newValue.isActive || true
		} as Hauler;
		return hauler;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
