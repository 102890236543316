import React from 'react';
import { Dictionary, FogFacility, InspectionEvent } from '@rcp/types';
import { apiService, DateUtilService, Resource, urlService } from 'src/services';
import { InspectionEventModal } from './facility-details/inspection-event-modal';
import { useDispatch } from 'react-redux';

interface OwnProps {
	fogFacility: FogFacility;
	loadFacilityList: Function;
	queryParameters: Dictionary<string>;
}

export const NextInspectionEditActionLink: React.SFC<OwnProps> = props => {
	const [showStartInspectionModal, toggleStartInspectionModal] = React.useState(false);
	const [inspection, setInspection] = React.useState<InspectionEvent>({} as InspectionEvent);

	const toggleModal = () => {
		toggleStartInspectionModal(!showStartInspectionModal);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		const facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(
			props.fogFacility.facilityId as number,
			Resource.InspectionEvents,
			props.fogFacility.nextInspectionId
		);
		let url = facilityInspectionsUrl;
		apiService.getResource<InspectionEvent>(url).then((res: InspectionEvent) => {
			setInspection(res);
			toggleModal();
		});
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	const loadFacilities = () => {
		props.loadFacilityList(urlService.toQueryString(props.queryParameters));
	};

	return (
		<>
			<a href="#/" className="ai-link violationType-link" onClick={onEditClicked}>
				{props.fogFacility.nextInspectionDueDate ? formatDate(props.fogFacility.nextInspectionDueDate) : null}
			</a>
			{showStartInspectionModal && (
				<InspectionEventModal
					isToggle={showStartInspectionModal}
					toggle={toggleModal}
					inspection={inspection}
					isStartInspection={false}
					loadFacilities={loadFacilities}
				/>
			)}
		</>
	);
};
