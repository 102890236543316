import classnames from 'classnames';
import * as React from 'react';
import { StepperContext } from './context';
import { StepId, StepState } from './typings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';

interface Props {
	index: number;
	stepId: StepId;
}

const CLASS_NAME = 'stepper__head';

const Header: React.FunctionComponent<Props> = ({ index, stepId }) => {
	const { isLoading, goAt, getStep, getCurrentStep } = React.useContext(StepperContext);
	const current = getCurrentStep();
	const { loading, completed, error, disabled, description, className, title } = getStep(stepId) as StepState;

	const active = Boolean(current && current.stepId === stepId);
	const isDisabled = disabled || isLoading() || !Boolean(active || completed || error);

	const onClick = () => goAt(stepId);

	return (
		<button
			tabIndex={index}
			disabled={isDisabled}
			onClick={onClick}
			className={classnames(className, CLASS_NAME, {
				[`stepper__head--loading`]: loading,
				[`stepper__head--completed`]: completed,
				[`stepper__head--error`]: error,
				[`stepper__head--active`]: active,
				[`stepper__head--disabled`]: isDisabled
			})}>
			<span className={`${CLASS_NAME}__index`}>
				{error && <FontAwesomeIcon icon={faExclamation} className={`${CLASS_NAME}__icon`} />}
				{!error && completed && <FontAwesomeIcon icon={faCheck} className={`${CLASS_NAME}__icon`} />}
				{!error && !completed && index}
			</span>
			<span className={`${CLASS_NAME}__title`}>
				{error ? error.msg : title}
				{error && error.description && (
					<span className={`${CLASS_NAME}__description`}>{error.description}</span>
				)}
				{!error && description && <span className={`${CLASS_NAME}__description`}>{description}</span>}
			</span>
		</button>
	);
};

export default React.memo(Header);
