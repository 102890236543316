import React from 'react';
import { HaulerCleaningManifest } from '@rcp/types';
import { DateUtilService, localizationService } from '../../../../services';
import HaulerSubmitCleaningModal from '../hauler-facilities/hauler-submit-cleaning/submit-cleaning';
import { SeattleAuthorityConstants } from 'src/constants';

interface OwnProps {
	cleaningManifest: HaulerCleaningManifest;
	dateField?: string;
}

export const CleaningManifestEditActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	const onCancelModal = () => {
		setShowModal(false);
	};

	const submitCleaningModal = {
		showModal: showModal,
		onCancel: onCancelModal,
		selectedDevices: [],
		deviceList: [],
		title: localizationService.getLocalizedString('haulerPortal.cleaningHistory.submittedCleaning'),
		hideCancel: true,
		readOnly: true,
		cleaningEventId: props.cleaningManifest.cleaningEventId,
		customFormId: props.cleaningManifest.customFormId,
		hideFooter: true,
		submittedDate: props.cleaningManifest.submittedDateTimeLocal,
		authorityOrganizationId: props.cleaningManifest.authorityOrganizationId,
		isSeattleFacility: props.cleaningManifest.authorityName
			? String.equalCaseInsensitive(props.cleaningManifest.authorityName, SeattleAuthorityConstants.authorityName)
			: false
	};

	return (
		<>
			<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
				{formatDate(props.dateField)}
			</a>

			{showModal && <HaulerSubmitCleaningModal {...submitCleaningModal} />}
		</>
	);
};
