import { CccSiteContact, EntityContact, EntityContact_EntityType } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { localizationService, urlService } from 'src/services';
import { extractJsonEmails, extractJsonNumbers } from '.';
import { LetterTemplateCategory } from '@rcp/types';
import { FacilitySendEmail } from '../../components/authority/fog/facilities/facility-details/send-email';
import './contact-card.scss';

interface Props {
	contact: CccSiteContact | EntityContact;
	isDeviceDetailsPage?: boolean;
	showSpan?: boolean;
}

export const ContactCardBody: React.FC<Props> = props => {
	const phones = extractJsonNumbers(props.contact.jsonNumbers);
	const emails = extractJsonEmails(props.contact.jsonEmails);
	const [selectedEmail, setSelectedEmail] = React.useState([] as any[]);
	const [showEmailModal, setShowEmailModal] = React.useState(false);

	const sendEmailPopUp = (address: string, name: string) => {
		const selectEmail: any[] = [
			{
				name,
				address,
				selected: true
			}
		];
		setSelectedEmail(selectEmail);
		setShowEmailModal(true);
	};
	let contactAddress = props.contact.addressLine1;
	if (contactAddress && props.contact.addressLine2) {
		contactAddress = `${contactAddress}, ${props.contact.addressLine2}`;
	}
	let contactCity = props.contact.addressCity || '';
	if (props.contact.addressJurisdictionCode) {
		if (contactCity) {
			contactCity += ' ' + props.contact.addressJurisdictionCode;
		} else {
			contactCity = props.contact.addressJurisdictionCode;
		}
	}
	contactCity += ' ' + (props.contact.addressZipCode || '');
	contactCity = contactCity.trim();

	const getName = () => {
		const name =
			props.contact.firstName && props.contact.lastName
				? `${props.contact.firstName} ${props.contact.lastName}`
				: props.contact.firstName || props.contact.lastName;
		return name;
	};

	return (
		<>
			{props.contact.companyName && <p>{props.contact.companyName}</p>}

			{(props.contact.firstName || props.contact.lastName) && <p>{getName()}</p>}
			{props.contact.title && <p>{props.contact.title}</p>}
			<p>
				{props.contact.addressLine1 && (
					<>
						{props.contact.addressLine1}
						<br />
					</>
				)}
				{props.contact.addressLine2 && (
					<>
						{props.contact.addressLine2}
						<br />
					</>
				)}
				{contactCity && (
					<>
						{contactCity}
						<br />
					</>
				)}
			</p>

			{(!_.isEmpty(phones) || !_.isEmpty(emails)) && (
				<p>
					{phones && phones.business && (
						<>
							{phones.business.label}&#58;{' '}
							{props.showSpan ? (
								<span>{phones.business.number}</span>
							) : (
								<a href={'tel:' + phones.business.number}>{phones.business.number}</a>
							)}
							{phones.business.extension && (
								<>
									&nbsp;
									{localizationService.getLocalizedString('screen.labels.phoneExtensionPrefix')}
									&#58;
									{phones.business.extension}
								</>
							)}
							<br />
						</>
					)}
					{phones && phones.cell && (
						<>
							{phones.cell.label}&#58;{' '}
							{props.showSpan ? (
								<span>{phones.cell.number}</span>
							) : (
								<a href={'tel:' + phones.cell.number}>{phones.cell.number}</a>
							)}
							<br />
						</>
					)}
					{emails && emails.length > 0 && (
						<>
							<span>{localizationService.getLocalizedString('authentication.email')}: </span>
							<a
								href="#/"
								onClick={e => {
									e.preventDefault();
									sendEmailPopUp(
										emails[0].email as string,
										getName() || props.contact.companyName || (emails[0].email as string)
									);
								}}>
								{emails[0].email}
							</a>
						</>
					)}
				</p>
			)}

			{showEmailModal && (
				<FacilitySendEmail
					show={showEmailModal}
					toggle={() => {
						setShowEmailModal(!showEmailModal);
					}}
					letterTemplateCategory={
						urlService.getFogDeviceId() <= 0
							? LetterTemplateCategory.FacilityEmail
							: LetterTemplateCategory.DeviceEmail
					}
					selectedEmail={selectedEmail}
					isDeviceDetail={urlService.getFogDeviceId() > 0}
				/>
			)}
		</>
	);
};
