import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum SampleActionType {
	LoadSampleRequest = 'loadSampleRequest',
	LoadSampleSuccess = 'loadSampleSuccess',
	LoadMonitoringPointsRequest = 'loadMonitoringPointsRequest',
	LoadMonitoringPointsSuccess = 'loadMonitoringPointsSuccess',
	LoadCollectionMethodsRequest = 'loadCollectionMethodsRequest',
	LoadCollectionMethodsSuccess = 'loadCollectionMethodsSuccess',
	LoadSampleTypesRequest = 'loadSampleTypesRequest',
	LoadSampleTypesSuccess = 'loadSampleTypesSuccess',
	LoadFlowUnitsRequest = 'loadFlowUnitsRequest',
	LoadFlowUnitsSuccess = 'loadFlowUnitsSuccess',
	SetSampleDataRequest = 'setSampleDataRequest',
	SetSampleDataSuccess = 'setSampleDataSuccess',
	LoadParameterGroupsRequest = 'loadParameterGroupsRequest',
	LoadParameterGroupsSuccess = 'loadParameterGroupsSuccess',
	LoadSelectedParametersRequest = 'loadSelectedParametersRequest',
	LoadSelectedParametersSuccess = 'loadSelectedParametersSuccess',
	LoadFloatNumbersRequest = 'loadFloatNumbersRequest',
	LoadFloatNumbersSuccess = 'loadFloatNumbersSuccess',
	LoadProgramSettingsRequest = 'loadProgramSettingsRequest',
	LoadProgramSettingsSuccess = 'loadProgramSettingsSuccess',
	LoadMassLoadingUnitRequest = 'loadMassLoadingUnitRequest',
	LoadMassLoadingUnitSuccess = 'loadMassLoadingUnitSuccess',
	UpdateSampleRequest = 'UpdateSampleRequest',
	UpdateSampleSuccess = 'UpdateSampleSuccess',
	LoadParametersRequest = 'loadParametersRequest',
	LoadParametersSuccess = 'loadParametersSuccess'
}

export interface LoadSampleRequest extends Action {
	type: SampleActionType.LoadSampleRequest;
}

export interface LoadSampleSuccess extends Action {
	type: SampleActionType.LoadSampleSuccess;
	sample: ApiTypes.IppIndustrySample;
}


export interface LoadMonitoringPointsRequest extends Action {
	type: SampleActionType.LoadMonitoringPointsRequest;
}

export interface LoadMonitoringPointsSuccess extends Action {
	type: SampleActionType.LoadMonitoringPointsSuccess;
	monitoringPoints: ApiTypes.IppMonitoringPoint[];
}

export interface LoadCollectionMethodsRequest extends Action {
	type: SampleActionType.LoadCollectionMethodsRequest;
}

export interface LoadCollectionMethodsSuccess extends Action {
	type: SampleActionType.LoadCollectionMethodsSuccess;
	collectionMethods: ApiTypes.IppCollectionMethod[];
}

export interface LoadSampleTypesRequest extends Action {
	type: SampleActionType.LoadSampleTypesRequest;
}

export interface LoadSampleTypesSuccess extends Action {
	type: SampleActionType.LoadSampleTypesSuccess;
	sampleTypes: ApiTypes.IppSampleType[];
}

export interface LoadFlowUnitsRequest extends Action {
	type: SampleActionType.LoadFlowUnitsRequest;
}

export interface LoadFlowUnitsSuccess extends Action {
	type: SampleActionType.LoadFlowUnitsSuccess;
	flowUnits: ApiTypes.IppSampleFlowUnit[];
}

export interface SetSampleDataRequest extends Action {
	type: SampleActionType.SetSampleDataRequest;
}

export interface SetSampleDataSuccess extends Action {
	type: SampleActionType.SetSampleDataSuccess;
	sample: ApiTypes.IppIndustrySample;
}

export interface LoadParameterGroupsRequest extends Action {
	type: SampleActionType.LoadParameterGroupsRequest;
}

export interface LoadParameterGroupsSuccess extends Action {
	type: SampleActionType.LoadParameterGroupsSuccess;
	parameterGroups: ApiTypes.IppReportPackageParameterGroup[];
}

export interface LoadSelectedParametersRequest extends Action {
	type: SampleActionType.LoadSelectedParametersRequest;
}

export interface LoadSelectedParametersSuccess extends Action {
	type: SampleActionType.LoadSelectedParametersSuccess;
	selectedParameters: ApiTypes.IppReportPackageParameter[];
}

export interface LoadFloatNumbersRequest extends Action {
	type: SampleActionType.LoadFloatNumbersRequest;
}

export interface LoadFloatNumbersSuccess extends Action {
	type: SampleActionType.LoadFloatNumbersSuccess;
	floatNumbers: any;
}
export interface LoadProgramSettingsRequest extends Action {
	type: SampleActionType.LoadProgramSettingsRequest;
}

export interface LoadProgramSettingsSuccess extends Action {
	type: SampleActionType.LoadProgramSettingsSuccess;
	programSettings: ApiTypes.IppProgramSettings;
}
export interface LoadMassLoadingUnitRequest extends Action {
	type: SampleActionType.LoadMassLoadingUnitRequest;
}

export interface LoadMassLoadingUnitSuccess extends Action {
	type: SampleActionType.LoadMassLoadingUnitSuccess;
	massLoadingUnit: ApiTypes.IppMassLoadingUnit;
}

export interface UpdateSampleRequest extends Action {
	type: SampleActionType.UpdateSampleRequest;
}

export interface UpdateSampleSuccess extends Action {
	type: SampleActionType.UpdateSampleSuccess;
	sample: ApiTypes.IppIndustrySample;
}

export interface LoadParametersRequest extends Action {
	type: SampleActionType.LoadParametersRequest;
}

export interface LoadParametersSuccess extends Action {
	type: SampleActionType.LoadParametersSuccess;
	parameters: ApiTypes.IppReportPackageParameter[];
}

export type SampleAction =
	| LoadSampleRequest
	| LoadSampleSuccess
	| LoadMonitoringPointsRequest
	| LoadMonitoringPointsSuccess
	| LoadCollectionMethodsRequest
	| LoadCollectionMethodsSuccess
	| LoadSampleTypesRequest
	| LoadSampleTypesSuccess
	| LoadFlowUnitsRequest
	| LoadFlowUnitsSuccess
	| SetSampleDataRequest
	| SetSampleDataSuccess
	| LoadParameterGroupsRequest
	| LoadParameterGroupsSuccess
	| LoadSelectedParametersRequest
	| LoadSelectedParametersSuccess
	| LoadFloatNumbersRequest
	| LoadFloatNumbersSuccess
	| LoadProgramSettingsRequest
	| LoadProgramSettingsSuccess
	| LoadMassLoadingUnitRequest
	| LoadMassLoadingUnitSuccess
	| UpdateSampleRequest
	| UpdateSampleSuccess
	| LoadParametersRequest
	| LoadParametersSuccess

export const loadSampleRequest = (): LoadSampleRequest => ({
	type: SampleActionType.LoadSampleRequest
});

export const loadSampleSuccess = (
	sample: ApiTypes.IppIndustrySample,
): LoadSampleSuccess => ({
	type: SampleActionType.LoadSampleSuccess,
	sample
});

export const loadMonitoringPointsRequest = (): LoadMonitoringPointsRequest => ({
	type: SampleActionType.LoadMonitoringPointsRequest
});

export const loadMonitoringPointsSuccess = (
	monitoringPoints: ApiTypes.IppMonitoringPoint[],
): LoadMonitoringPointsSuccess => ({
	type: SampleActionType.LoadMonitoringPointsSuccess,
	monitoringPoints: monitoringPoints,
});

export const loadCollectionMethodsRequest = (): LoadCollectionMethodsRequest => ({
	type: SampleActionType.LoadCollectionMethodsRequest
});

export const loadCollectionMethodsSuccess = (
	collectionMethods: ApiTypes.IppCollectionMethod[],
): LoadCollectionMethodsSuccess => ({
	type: SampleActionType.LoadCollectionMethodsSuccess,
	collectionMethods
});

export const loadSampleTypesRequest = (): LoadSampleTypesRequest => ({
	type: SampleActionType.LoadSampleTypesRequest
});

export const loadSampleTypesSuccess = (
	sampleTypes: ApiTypes.IppSampleType[],
): LoadSampleTypesSuccess => ({
	type: SampleActionType.LoadSampleTypesSuccess,
	sampleTypes
});

export const loadFlowUnitsRequest = (): LoadFlowUnitsRequest => ({
	type: SampleActionType.LoadFlowUnitsRequest
});

export const loadFlowUnitsSuccess = (
	flowUnits: ApiTypes.IppSampleFlowUnit[],
): LoadFlowUnitsSuccess => ({
	type: SampleActionType.LoadFlowUnitsSuccess,
	flowUnits
});

export const setSampleDataRequest = (): SetSampleDataRequest => ({
	type: SampleActionType.SetSampleDataRequest
});

export const setSampleDataSuccess = (
	sample: ApiTypes.IppIndustrySample,
): SetSampleDataSuccess => ({
	type: SampleActionType.SetSampleDataSuccess,
	sample
});

export const loadParameterGroupsRequest = (): LoadParameterGroupsRequest => ({
	type: SampleActionType.LoadParameterGroupsRequest
});

export const loadParameterGroupsSuccess = (
	parameterGroups: ApiTypes.IppReportPackageParameterGroup[],
): LoadParameterGroupsSuccess => ({
	type: SampleActionType.LoadParameterGroupsSuccess,
	parameterGroups
});

export const loadSelectedParametersRequest = (): LoadSelectedParametersRequest => ({
	type: SampleActionType.LoadSelectedParametersRequest
});

export const loadSelectedParametersSuccess = (
	selectedParameters: ApiTypes.IppReportPackageParameter[],
): LoadSelectedParametersSuccess => ({
	type: SampleActionType.LoadSelectedParametersSuccess,
	selectedParameters
});

export const loadFloatNumbersRequest = (): LoadFloatNumbersRequest => ({
	type: SampleActionType.LoadFloatNumbersRequest
});

export const loadFloatNumbersSuccess = (
	floatNumbers: any,
): LoadFloatNumbersSuccess => ({
	type: SampleActionType.LoadFloatNumbersSuccess,
	floatNumbers
});
export const loadProgramSettingsRequest = (): LoadProgramSettingsRequest => ({
	type: SampleActionType.LoadProgramSettingsRequest
});

export const loadProgramSettingsSuccess = (
	programSettings: ApiTypes.IppProgramSettings,
): LoadProgramSettingsSuccess => ({
	type: SampleActionType.LoadProgramSettingsSuccess,
	programSettings
});
export const loadMassLoadingUnitRequest = (): LoadMassLoadingUnitRequest => ({
	type: SampleActionType.LoadMassLoadingUnitRequest
});

export const loadMassLoadingUnitSuccess = (
	massLoadingUnit: ApiTypes.IppMassLoadingUnit,
): LoadMassLoadingUnitSuccess => ({
	type: SampleActionType.LoadMassLoadingUnitSuccess,
	massLoadingUnit
});
export const updateSampleRequest = (): UpdateSampleRequest => ({
	type: SampleActionType.UpdateSampleRequest
});

export const updateSampleSuccess = (
	sample: ApiTypes.IppIndustrySample,
): UpdateSampleSuccess => ({
	type: SampleActionType.UpdateSampleSuccess,
	sample
});

export const loadParametersRequest = (): LoadParametersRequest => ({
	type: SampleActionType.LoadParametersRequest
});

export const loadParametersSuccess = (
	parameters: ApiTypes.IppReportPackageParameter[],
): LoadParametersSuccess => ({
	type: SampleActionType.LoadParametersSuccess,
	parameters
});
