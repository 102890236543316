import {
	IppRepudiateReportPackage,
	IppRepudiationReasons,
	DropDownOption,
	IppReportPackage,
	QuestionAnswerDtos
} from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import {
	DeleteModal as RepudiationConfirmationModal,
	DeleteModalProp as RepudiationConfirmationModalProp,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput
} from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import { Utils } from 'src/services/utils';

interface Props {
	packageDetails?: IppReportPackage;
	reportStatusName?: string;
	className?: string;
	onRepudiate: (payload: IppRepudiateReportPackage, callBackAction: () => void) => void;
	getKbq?: () => Promise<QuestionAnswerDtos>;
}

const { fieldCharLimit } = IppConstants;

const IppPackageRepudiation: FC<Props> = ({ packageDetails, reportStatusName, className, onRepudiate, getKbq }) => {
	let [reasons, setReasons] = useState<DropDownOption[]>([]);
	let [repudiatePackage, setRepudiatePackage] = useState<IppRepudiateReportPackage>({} as IppRepudiateReportPackage);
	let [errors, setErrors] = useState({ reasonError: '', passwordError: '', answerError: '' });
	let [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
	const { Submitted } = ReportPackageStatus;

	const getRandomQuestion = async () => {
		if (getKbq) {
			let questionAnswer = await getKbq();
			setRepudiatePackage({ ...repudiatePackage, ...questionAnswer });
		}
	};

	const loadReasons = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages + '/RepudiationReasons');
		let reasons: IppRepudiationReasons[] = await apiService.getResource(url);
		return reasons;
	};

	const loadReasonOptions = async () => {
		let reasons: IppRepudiationReasons[] = await loadReasons();
		let reasonsOptions: DropDownOption[] = [];
		if (reasons && reasons.length) {
			reasonsOptions = reasons.map((item: IppRepudiationReasons) => {
				let options: DropDownOption = {
					label: item.name || '',
					value: item.repudiationReasonId
				};
				return options;
			});
		}

		reasonsOptions.unshift({
			label: localizationService.getLocalizedString('ipp.reportPackage.package.defaultReason'),
			value: 0,
			isHidden: false
		});

		reasonsOptions && setReasons(reasonsOptions);
	};

	useEffect(() => {
		if (packageDetails!.reportStatusName === Submitted && packageDetails!.canRepudiateReportPackage) {
			loadReasonOptions();
			getRandomQuestion();
			Utils.resetFailedAttemptCounts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [packageDetails && packageDetails.reportPackageId]);
	const onChange = (event: any) => {
		const { name, value } = event.target;
		if (name === 'repudiationReasonId') {
			setRepudiatePackage({ ...repudiatePackage, repudiationReasonId: Number(value) || undefined });
		} else {
			setRepudiatePackage({ ...repudiatePackage, [name]: value });
		}
	};

	const validateAndRepudiate = () => {
		let payload = { ...repudiatePackage, ...errors };
		let isReasonValid = validationService.validateRequiredField(
			payload,
			'repudiationReasonId',
			'reasonError',
			'reason'
		);
		let isPasswordValid = validationService.validateRequiredField(payload, 'password', 'passwordError', 'password');
		let isAnswerValid = validationService.validateRequiredField(payload, 'answer', 'answerError', 'KBQ answer');
		setErrors({
			reasonError: payload.reasonError || '',
			answerError: payload.answerError || '',
			passwordError: payload.passwordError || ''
		});
		if (isReasonValid && isPasswordValid && isAnswerValid) {
			toggleRepudiateModal();
		}
	};

	const toggleRepudiateModal = () => {
		setShowConfirmationModal(!showConfirmationModal);
	};

	const repudiateReportPackageProps: RepudiationConfirmationModalProp = {
		showModal: showConfirmationModal,
		title: localizationService.getLocalizedString('ipp.reportPackage.package.repudiateTitle'),
		message: localizationService.getLocalizedString('ipp.reportPackage.package.repudiateDescription'),
		okayButtonText: localizationService.getLocalizedString('ipp.buttons.yes'),
		onOkayButtonClick: () => onRepudiate(repudiatePackage, toggleRepudiateModal),
		cancelButtonText: localizationService.getLocalizedString('ipp.buttons.no'),
		onCancelButtonClick: toggleRepudiateModal,
		isDeleteButton: true
	};

	return (
		<>
			<CollapsibleCard
				accordionType="report-package-submission-accordion"
				accordionHeading={localizationService.getLocalizedString('ipp.reportPackage.package.repudiateReport')}
				className={className ? className : ''}>
				{reportStatusName === Submitted && (
					<>
						{packageDetails && !packageDetails.canRepudiateReportPackage ? (
							<p>
								{localizationService.getLocalizedString(
									'ipp.reportPackage.package.repudiationTimeExpiredMessage'
								)}
							</p>
						) : null}
						<div id="report-package-submission" className="mx-auto" style={{ width: '500px' }}>
							<SingleSelectDropdown
								id="reason"
								name="repudiationReasonId"
								className="mx-auto"
								isDisabled={packageDetails && !packageDetails.canRepudiateReportPackage}
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.repudiationReasonName'
								)}
								value={
									(repudiatePackage &&
										repudiatePackage.repudiationReasonId &&
										repudiatePackage.repudiationReasonId.toString()) ||
									'0'
								}
								options={reasons}
								onChange={onChange}
								selfOrder={true}
								isRequired={true}
								error={errors.reasonError}
							/>
							<TextAreaInput
								id="comments"
								name="comments"
								className="mx-auto p-0"
								isFullWidth={true}
								isDisabled={packageDetails && !packageDetails.canRepudiateReportPackage}
								placeholder={localizationService.getLocalizedString(
									'ipp.reportPackage.package.commentsPlaceholder'
								)}
								label={localizationService.getLocalizedString('ipp.reportPackage.package.comments')}
								value={repudiatePackage && repudiatePackage.comments}
								onChange={onChange}
								maxLength={fieldCharLimit.commentsAndDescription}
							/>
							<TextInput
								type="password"
								id="password"
								name="password"
								className="mx-auto"
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.submitReport.passwordLabel'
								)}
								value={repudiatePackage && repudiatePackage.password}
								onChange={onChange}
								isDisabled={packageDetails && !packageDetails.canRepudiateReportPackage}
								isRequired={true}
								error={errors.passwordError}
							/>
							{repudiatePackage && repudiatePackage.question && (
								<TextInput
									type="password"
									id="questionAnswer"
									name="answer"
									className="mx-auto"
									label={(repudiatePackage && repudiatePackage.question) || ''}
									value={repudiatePackage && repudiatePackage.answer}
									onChange={(event: any) => onChange(event)}
									isDisabled={packageDetails && !packageDetails.canRepudiateReportPackage}
									isRequired={true}
									error={errors.answerError}
									remainingInputProps={{ maxLength: fieldCharLimit.answer }}
								/>
							)}
						</div>
						<div className="d-flex">
							<button
								id="repudiate-btn"
								className="btn ai-action ml-auto"
								onClick={validateAndRepudiate}
								disabled={packageDetails && !packageDetails.canRepudiateReportPackage}>
								{localizationService.getLocalizedString('ipp.buttons.repudiate')}
							</button>
						</div>
						<RepudiationConfirmationModal {...repudiateReportPackageProps} />
					</>
				)}
			</CollapsibleCard>
		</>
	);
};

export default IppPackageRepudiation;
