import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Dictionary, FogFacility, Incident, InspectionEvent, PaginatedResult } from '@rcp/types';
import { apiService, DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { MapMarker } from 'src/components/authority/fog/facilities/facility-details/facility-details-address/map-marker';
import { FaCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { MapLegendKey, PropertyKeyForResolvedLatitude, PropertyKeyForResolvedLongitude } from '../map-service';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FloatList } from './float-list';
import { saveFacilityInspection, useReduxSelector } from 'src/redux';
import { InspectionEventModal } from 'src/components/authority/fog/facilities/facility-details/inspection-event-modal';
import { useDispatch } from 'react-redux';
import { ReactComponent as OpenInNewTab } from 'src/assets/img/open-in-new.svg';
import { FacilityActionDropdown } from 'src/components/authority/fog/facilities/facility-details/action-dropdown-option';
import { FacilityStartInspectionDropdown } from 'src/components/authority';
import { MapDataSortService } from 'src/services/mapDataSortService';

const FacilityCard: React.FC<{ facility: FogFacility; showSingleCardView: boolean }> = props => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [inspections, setInspections] = React.useState<InspectionEvent[]>([]);
	const userProfile = useReduxSelector(state => state.userProfile.userProfile);
	const [addNewInspection, setAddNewInspection] = useState(false);

	function getFacilityAddress(facility: FogFacility) {
		let { addressLine1, addressLine2 } = facility;
		return _.trim(_.toString(addressLine1) + ' ' + _.toString(addressLine2)).trim();
	}
	const getFacilityCityDetails = (facility: FogFacility) => {
		let { cityName, jurisdictionName, zipCode } = facility;
		return _.trim(_.toString(cityName) + ' ' + _.toString(jurisdictionName) + ' ' + _.toString(zipCode)).trim();
	};
	const getComplianceClass = () => {
		let complianceClass = props.facility.isOverrideComplianceStatus
			? 'compliance-status-custom'
			: props.facility.complianceStatus == localizationService.getLocalizedString('facility.COMPLIANT')
			? 'compliance-status-green'
			: props.facility.complianceStatus == localizationService.getLocalizedString('facility.nonCompliant')
			? 'compliance-status-red'
			: 'compliance-status-custom';
		return complianceClass;
	};
	const getRiskClass = () => {
		let riskClass = '';
		switch (props.facility.riskScore) {
			case localizationService.getLocalizedString('facility.riskScoreHigh'):
				riskClass = 'compliance-status-red';
				break;
			case localizationService.getLocalizedString('facility.riskScoreMedium'):
				riskClass = 'compliance-status-yellow';
				break;
			case localizationService.getLocalizedString('facility.riskScoreLow'):
				riskClass = 'compliance-status-green';
				break;
			default:
				riskClass = 'compliance-status-custom';
		}
		return riskClass;
	};

	const getDeviceClass = () => {
		if (props.facility.activeDeviceCount && props.facility.activeDeviceCount > 0) {
			return 'compliance-status-green';
		}

		return 'compliance-status-red';
	};

	const getDeviceDescription = () => {
		let deviceDescription = '';
		if (props.facility.activeDeviceCount === 1) {
			deviceDescription = localizationService.getLocalizedString('map.hasDeviceSingle');
		} else if (props.facility.activeDeviceCount && props.facility.activeDeviceCount > 1) {
			deviceDescription = localizationService.getLocalizedString(
				'map.hasDeviceMultiple',
				_.toString(props.facility.activeDeviceCount)
			);
		} else {
			deviceDescription = localizationService.getLocalizedString('map.noDevice');
		}

		return deviceDescription;
	};

	const facilityUrl = urlService.getCurrentFacilityDetailsUrl(props.facility.facilityId);
	const facilityComplianceStatusText = props.facility.complianceStatus;
	const facilityRiskStatus = props.facility.riskScore
		? `${props.facility.riskScore} ${localizationService.getLocalizedString('facility.risk')}`
		: localizationService.getLocalizedString('facility.riskScoreLegend.noRiskSet');

	const saveInspection = async (inspection: InspectionEvent) => {
		return dispatch(saveFacilityInspection(inspection, props.facility.facilityId));
	};
	const facilityStatus = (statusClass: string, statusText?: string) => {
		return (
			<>
				<div className="d-flex flex-row flex-wrap">
					<div className="map-circle-icon">
						<FaCircle size={10} className={statusClass} />
					</div>
					<div className="map-icon-text">{statusText}</div>
				</div>
			</>
		);
	};
	const navigateToExistingInspection = (e: any) => {
		let inspectionEventId = e.target.id.split('_')[1];
		const facilityPath = urlService.getCurrentFacilityDetailsUrl(props.facility.facilityId);
		const inspectionPath = facilityPath + '/inspections/' + inspectionEventId;
		navigateTo(history, inspectionPath);
	};

	const getAssociatedInspections = (e: any) => {
		const facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(
			props.facility.facilityId as number,
			Resource.InspectionEvents
		);

		apiService
			.getPaginatedResources<InspectionEvent>(facilityInspectionsUrl)
			.then((res: PaginatedResult<InspectionEvent>) => setInspections(res.result));
	};

	return (
		<>
			<div className="map-card">
				<div className="d-flex flex-row font-weight-bold card-title">
					<a href={facilityUrl}>{props.facility.facilityName}</a>
					<button className="open-new-tab-button">
						<a
							href={facilityUrl}
							target="_blank"
							title={localizationService.getLocalizedString('screen.labels.screenReader.openNewWindow')}>
							<OpenInNewTab />
						</a>
					</button>
				</div>
				<div className="d-flex flex-row flex-wrap mt-2">
					<MapMarker
						streetAddress={getFacilityAddress(props.facility)}
						cityDetails={getFacilityCityDetails(props.facility)}
						isForMap={true}
						latitude={_.get(props.facility, PropertyKeyForResolvedLatitude) as number}
						longitude={_.get(props.facility, PropertyKeyForResolvedLongitude) as number}
					/>
				</div>
				<div
					className={`d-flex flex-row flex-wrap facility-status ${!props.showSingleCardView &&
						`no-inspection-fields`}`}>
					{facilityStatus(getComplianceClass(), facilityComplianceStatusText)}
					{facilityStatus(getRiskClass(), facilityRiskStatus)}
					{facilityStatus(getDeviceClass(), getDeviceDescription())}
				</div>
				{props.showSingleCardView && (
					<>
						<div className="d-flex flex-row flex-wrap next-inspection">
							<div className="pl-0">
								{props.facility.nextInspectionDueDate && (
									<>
										<p>
											<strong>
												{localizationService.getLocalizedString(
													'facility.nextInspectionDueDate'
												)}
											</strong>
											<br />
											{`${props.facility.nextInspectionType} - ${DateUtilService.toDisplayDate(
												props.facility.nextInspectionDueDate
											)}`}
										</p>
									</>
								)}
							</div>
						</div>
						<div className="d-flex flex-row flex-wrap start-inspection">
							<div className="col-md-4 pl-0">
								<UncontrolledDropdown className="action-item-black" onClick={getAssociatedInspections}>
									<DropdownToggle
										tag="a"
										className="btn ai-white test-button"
										id="popupDropdownButton">
										<span id="startInspection">
											{localizationService.getLocalizedString('inspection.startInspection')}
										</span>
									</DropdownToggle>
									<FacilityStartInspectionDropdown
										clickOnOpenInspection={() => setAddNewInspection(true)}
										dueInspectionList={inspections}
										navigateToInspectionDetails={navigateToExistingInspection}
									/>
								</UncontrolledDropdown>
							</div>
						</div>
					</>
				)}
			</div>
			{addNewInspection && (
				<InspectionEventModal
					isToggle={true}
					toggle={() => setAddNewInspection(!addNewInspection)}
					inspection={{
						creatorUserProfileId: userProfile.userProfileId,
						organizationId: props.facility.facilityId
					}}
					facilityId={props.facility.facilityId}
					isStartInspection={true}
					save={saveInspection}
				/>
			)}
		</>
	);
};

const IncidentCard: React.FC<{
	incident: Incident;
	onCardLinkClick: (incident: Incident) => void;
}> = props => {
	return (
		<>
			<div className="map-card">
				<div className="d-flex flex-row font-weight-bold mt-2">
					<a
						className="ai-link incident-type cursor-pointer"
						id="incidentType"
						onClick={() => props.onCardLinkClick(props.incident)}>
						{props.incident.incidentTypeCode}
					</a>
				</div>
				<div className="d-flex flex-row flex-wrap mt-2">
					<div className=" d-flex  ">
						<MapMarker
							streetAddress={props.incident.address ?? ''}
							cityDetails={props.incident.city ?? ''}
							isForMap={true}
							latitude={_.get(props.incident, 'latitude', 0) as number}
							longitude={_.get(props.incident, 'longitude', 0) as number}
						/>
					</div>
				</div>

				<p>
					<strong>{localizationService.getLocalizedString('incidents.reported')} </strong>
					<br />
					{DateUtilService.toDisplayDate(props.incident.reportedDate)}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('incidents.status')}</strong>
					<br />
					{props.incident.incidentStatusCode}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('incidents.source')}</strong>
					<br />
					{props.incident.incidentSourceCode}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('incidents.description')}</strong>
					<br />
					{props.incident.incidentDescription}
				</p>
			</div>
		</>
	);
};

interface Props {
	mapEntities: (FogFacility | Incident)[];
	showSingleCardView: boolean;
	handleClose: () => void;
	facilityRiskMapFooterOverlay: JSX.Element;
	mapLegend: MapLegendKey;
	complianceValues: string[];
	onIncidentCardLinkClick: (incident: Incident) => void;
}

export const FloatMapEntityList: React.FC<Props> = props => {
	const [facilities, setFacilities] = React.useState<FogFacility[]>([]);
	const [incidents, setIncidents] = React.useState<Incident[]>([]);

	React.useEffect(() => {
		//@ts-ignore
		setFacilities(props.mapEntities.filter(x => x.facilityId && x.facilityId > 0));
		//@ts-ignore
		setIncidents(props.mapEntities.filter(x => x.incidentId && x.incidentId > 0));
	}, [props.mapEntities]);

	const facilitiesList = (): JSX.Element => {
		const complianceValuesDict = _.reduce(
			props.complianceValues,
			function(dict, complianceValue, idx) {
				dict[complianceValue] = 3 + idx;
				return dict;
			},
			{} as Dictionary<number>
		);

		return (
			<>
				{incidents
					.sort((a, b) => {
						return MapDataSortService.comparePossiblyUndefinedStrings(
							a.incidentTypeCode,
							b.incidentTypeCode
						);
					})
					.map(incident => {
						return (
							<IncidentCard
								onCardLinkClick={props.onIncidentCardLinkClick}
								key={incident.incidentNumber}
								incident={incident}
							/>
						);
					})}
				{incidents.length > 0 && facilities.length > 0 && (
					<h2 className="pb-3 pt-2">{localizationService.getLocalizedString('screen.labels.facilities')}</h2>
				)}
				{facilities
					.sort((a, b) => {
						return (
							MapDataSortService.sortByMapLegend(a, b, props.mapLegend, complianceValuesDict) ||
							MapDataSortService.comparePossiblyUndefinedStrings(a.cityName, b.cityName) ||
							MapDataSortService.comparePossiblyUndefinedStrings(a.addressLine1, b.addressLine1) ||
							MapDataSortService.comparePossiblyUndefinedStrings(a.addressLine2, b.addressLine2) ||
							MapDataSortService.comparePossiblyUndefinedStrings(a.facilityName, b.facilityName)
						);
					})
					.map(facility => {
						return (
							<FacilityCard
								key={facility.referenceNumber}
								facility={facility}
								showSingleCardView={props.showSingleCardView}
							/>
						);
					})}
			</>
		);
	};
	return (
		<FloatList
			title={
				incidents.length > 0
					? localizationService.getLocalizedString('incidents.incidents')
					: localizationService.getLocalizedString('screen.labels.facilities')
			}
			children={facilitiesList()}
			handleClose={props.handleClose}
			facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
			showMobileTitle={false}
		/>
	);
};
