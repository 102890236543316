import { RestSlice } from '../../../../redux/rest.slice';
import { Extractor, RegulatoryProgramName } from '@rcp/types';
import { localizationService } from 'src/services';

export const facilityDevicesSlice = new RestSlice<Extractor>(
	RegulatoryProgramName.FOG +
		localizationService.getLocalizedString('facility.facility') +
		localizationService.getLocalizedString('extractor.devices'),
	''
);
export const facilityDevicesReducer = facilityDevicesSlice.rests.reducer;
