import React from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import {
	ApplicationState,
	alertService,
	loadAuthorityInspectionTypes,
	AuthorityEventTypesState,
	InspectionMaintenanceResultsState,
	InspectionResultsState,
	loadFacilityInspections,
	loadAuthorityInspections,
	loadCurrentFogFacility,
	saveFacilityInspection,
	useReduxDispatch,
	useReduxSelector,
	RootState
} from 'src/redux';
import { InspectionEvent, DropDownOption, Dictionary, LocalStorageName } from '@rcp/types';
import { PopoverModal, DeleteModal } from 'src/components/widgets';
import {
	localizationService,
	optionsMap,
	urlService,
	apiService,
	navigateTo,
	localStorageService,
	DateUtilService
} from 'src/services';
import { InspectionEventInputFields, validateInspectionForSave } from './inspection-event-inputs';
import moment from 'moment';
import { inspectionsSlice } from 'src/components/authority/fog/inspections';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	inspection: InspectionEvent;
	save?: (inspection: InspectionEvent) => Promise<any>;
	callbackAfterSave?: () => void;
	isFromInspectionGrid?: boolean;
	isFromInspectionDetails?: boolean;
	loadCurrentInspection?: Function;
	isStartInspection?: boolean;
	loadFacilities?: Function;
	queryParameter?: Dictionary<string>;
	isReadOnly?: boolean;
	facilityId?: number;
}

interface StateProps extends AuthorityEventTypesState, InspectionMaintenanceResultsState, InspectionResultsState {}

interface DispatchProps {
	loadFacilityInspections: (startDate?: string, endDate?: string) => void;
	saveInspection: (inspection: InspectionEvent, facilityId?: number, callbackOnSuccess?: () => void) => void;
	loadAuthorityInspections: (queryParams: string) => void;
	loadCurrentFogFacility: () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface InspectionEventFormState extends InspectionEvent {
	inspectionTypeIdError?: string;
	dueDateError?: string;
}

const InspectionEventModalComp: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState<InspectionEventFormState>({});
	const [isFormDisabled, setIsFormDisabled] = React.useState<boolean>(false);
	const [inspectionTypeOptions, setInspectionTypeOptions] = React.useState([] as DropDownOption[]);
	const [authorityUserOptions, setAuthorityUserOptions] = React.useState([] as DropDownOption[]);
	const [toggleDeleteInspectionModal, setToggleDeleteInspectionModal] = React.useState(false);
	const isFormInspectionSummary: boolean = props.isFromInspectionDetails ? props.isFromInspectionDetails : false;
	const dispatch = useReduxDispatch();
	const { result: authorityUsers } = useReduxSelector((state: RootState) => state.authorityUsers);

	React.useEffect(() => {
		dispatch(loadAuthorityInspectionTypes());
		!props.isStartInspection && dispatch(authorityUsersSlice.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const setFormStateFromProps = (inspection: InspectionEvent) => {
		let newState = { ...inspection };
		setFormState(newState);
	};

	React.useEffect(() => {
		if (props.inspection) {
			setFormStateFromProps({ ...props.inspection });
		}
		if (props.inspectionTypes) {
			let options = optionsMap.fromEventTypes(props.inspectionTypes);
			if (props.inspection.inspectionTypeId) {
				if (!_.find(options, { value: props.inspection.inspectionTypeId })) {
					options.push({
						label: props.inspection.inspectionType as string,
						value: props.inspection.inspectionTypeId,
						isHidden: true
					});
				}
			}
			setInspectionTypeOptions(options);
		}
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);
			setAuthorityUserOptions(options);
		}
	}, [
		props.inspection,
		props.inspectionTypes,
		authorityUsers,
		props.inspectionMaintenanceResults,
		props.inspectionResults
	]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const navigateToInspectionDetails = (inspectionEventId: number) => {
		const facilityPath = urlService.getCurrentFacilityDetailsUrl(props.facilityId);
		const inspectionPath = facilityPath + '/inspections/' + inspectionEventId;
		localStorageService.setLocalStorage(LocalStorageName.InspectionDetailRedictFromPath as string, facilityPath);
		navigateTo(history, inspectionPath);
	};

	const saveInspection = async () => {
		if (props.isStartInspection) {
			formState.dueDate = DateUtilService.getAuthorityTimezoneNow();
		}
		if (!validateInspectionForSave(formState, setFormState)) {
			return;
		}
		let facilityId = props.inspection.organizationId;
		let inspectionEvent = { ...formState };
		if (props.save) {
			const inspectionReturned = await props.save(inspectionEvent);
			props.isStartInspection && navigateToInspectionDetails(inspectionReturned.inspectionEventId);
		} else if (props.isFromInspectionGrid) {
			await props.saveInspection(inspectionEvent, facilityId, () => {
				dispatch(inspectionsSlice.reload());
			});
		} else {
			await props.saveInspection(inspectionEvent, facilityId);
			if (props.loadFacilities) {
				props.loadFacilities();
			} else {
				props.loadCurrentInspection
					? dispatch(props.loadCurrentInspection(props.inspection.inspectionEventId))
					: window.location.reload();
			}
		}

		if (props.callbackAfterSave) {
			props.callbackAfterSave();
		}
		props.toggle();
	};

	const deleteInspection = () => {
		//close confirmation popup
		setToggleDeleteInspectionModal(false);
		if (props.inspection.inspectionEventId) {
			let facilityId = props.inspection.organizationId;
			if (!facilityId) {
				throw new Error('Invalid organizationId on inspection');
			}
			const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, 'InspectionEvents');
			const inspectionUrl = `${inspectionsUrl}/${props.inspection.inspectionEventId}`;
			apiService
				.deleteResource(inspectionUrl)
				.then(() => {
					alertService.addSuccess(
						localizationService.getLocalizedString(
							'alertMessages.removeSuccess',
							'inspectionForm.inspection'
						)
					);
					if (urlService.getFogFacilityId() > 0) {
						props.loadFacilityInspections();
						props.loadCurrentFogFacility();
					} else {
						dispatch(inspectionsSlice.reload());
					}
					if (props.loadFacilities) {
						props.loadFacilities();
					}
				})
				.catch(e => {
					alertService.addError(e.message);
				});
			//close inspection modal
			props.toggle();
		}
	};

	const cancelSaveInspection = () => {
		setFormStateFromProps(props.inspection);
		props.toggle();
	};

	const history = useHistory();

	const modalFooterDiv = () => {
		let inspectionPath: string | undefined = undefined;
		if (props.inspection.inspectionEventId) {
			let fogFacilityId = props.inspection.organizationId;
			if (!fogFacilityId) {
				throw new Error('Invalid Inspection');
			}
			inspectionPath = urlService.getFacilityInspectionDetailsUrl(
				fogFacilityId,
				props.inspection.inspectionEventId
			);
		}

		return (
			<>
				{props.inspection.inspectionEventId && !isFormInspectionSummary && (
					<button
						type="button"
						className="btn ai-secondary-delete"
						onClick={() => {
							setToggleDeleteInspectionModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}

				<div className="ml-auto">
					{!isFormDisabled && !props.isReadOnly && (
						<button
							className={`btn ${
								!isFormInspectionSummary && !props.isStartInspection ? 'ai-save' : 'ai-action'
							}`}
							onClick={saveInspection}>
							{!isFormInspectionSummary
								? props.isStartInspection
									? localizationService.getLocalizedString('screen.buttons.start')
									: localizationService.getLocalizedString('screen.buttons.save')
								: localizationService.getLocalizedString('screen.buttons.apply')}
						</button>
					)}
					<button className="btn ai-white ml-2" onClick={cancelSaveInspection}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title={localizationService.getLocalizedString(
					props.inspection.inspectionEventId
						? isFormInspectionSummary
							? 'inspection.editInspectionSummary'
							: 'inspection.editInspection'
						: props.isStartInspection
						? 'inspection.startNewInspection'
						: 'screen.buttons.scheduleInspection'
				)}
				footer={modalFooterDiv()}
				save={saveInspection}
				cancel={cancelSaveInspection}>
				<InspectionEventInputFields
					formState={formState}
					changeFormState={changeFormState}
					inspectionTypeOptions={inspectionTypeOptions}
					isFormDisabled={isFormDisabled}
					authorityUserOptions={authorityUserOptions}
					isStartInspection={props.isStartInspection}
					isReadOnly={props.isReadOnly}
				/>
			</PopoverModal>
			{toggleDeleteInspectionModal && (
				<DeleteModal
					key="confirmDeleteInspectionModal"
					title={localizationService.getLocalizedString('inspection.deleteModalTitle')}
					message={localizationService.getLocalizedString('inspection.deleteModalMessage')}
					showModal={toggleDeleteInspectionModal}
					onCancelButtonClick={() => {
						setToggleDeleteInspectionModal(false);
					}}
					onOkayButtonClick={deleteInspection}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return {
		...state.authorityEventTypes,
		...state.inspectionMaintenanceResults,
		...state.inspectionResults
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadFacilityInspections: (startDate?: string, endDate?: string) =>
			dispatch(loadFacilityInspections(startDate, endDate)),
		loadAuthorityInspections: queryParams => dispatch(loadAuthorityInspections(queryParams)),
		loadCurrentFogFacility: () => dispatch(loadCurrentFogFacility()),
		saveInspection: (inspection: InspectionEvent, facilityId?: number, callbackOnSuccess?: () => void) =>
			dispatch(saveFacilityInspection(inspection, facilityId, callbackOnSuccess))
	};
};

export const InspectionEventModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(InspectionEventModalComp);
