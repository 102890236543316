import React, { useEffect, useState, FC } from 'react';
import { localizationService, apiService, urlService, Resource } from 'src/services';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { alertService } from 'src/redux';
import { ApiStatus } from '@rcp/types';

interface EncryptedCredentials {
	username: string;
	password: string;
}

interface UserAvailable {
	isEnabled: boolean;
	isAcpConnectorUserAvailable: boolean;
}
const emptyStatus = (): ApiStatus =>
	({
		buildTime: 'a',
		serverTime: '',
		serverVersion: '',
		ctsDatabaseMinVersion: '',
		ctsDatabaseMinPatch: '',
		adcMinVersion: ''
	} as ApiStatus);

const IppApiSyncUserSettings: FC<{ copyToClipBoard: (event: any, textToCopy: string) => void }> = props => {
	const [showGenerateModal, setShowGenerateModal] = useState(false);
	const [showACPData, setACPData] = useState(false);
	const [acpUserExists, setAcpUserExists] = useState<UserAvailable>({
		isEnabled: false,
		isAcpConnectorUserAvailable: false
	});
	const [encryptedResponse, setEncryptedResponse] = useState<EncryptedCredentials>({
		username: '',
		password: ''
	});
	const [statusACP, setStatusACP] = useState<ApiStatus>(emptyStatus());

	useEffect(() => {
		checkACPUserExists();
		getACPStatus();
	}, []);

	const enableOrDisableUser = async (isEnabled: boolean) => {
		try {
			let url = `${urlService.getAuthorityResourcesApiUrl(
				Resource.IppAcpConnectorSettings
			)}/DisableOrEnableApiUser`;
			let response = await apiService.httpPatch(url, { isEnabled: isEnabled });
			alertService.addSuccess(
				localizationService.getLocalizedString(
					`ipp.authorityAcpConnector.apiSyncUserSettings.${
						!isEnabled ? 'syncUserStatusDisabled' : 'syncUserStatusEnabled'
					}`
				)
			);
			return response;
		} catch (err) {
			alertService.addError(err.message);
		}
	};

	const toggleDisableUser = async (e: any) => {
		const { checked } = e.target;
		await enableOrDisableUser(!checked);
		await checkACPUserExists();
	};

	const generateSyncUser = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.IppAcpConnectorSettings)}/GenerateApiUser`;
		let data: EncryptedCredentials = await apiService.getResource(url);
		setEncryptedResponse({ ...data });
		setACPData(true);
		setShowGenerateModal(false);
		await checkACPUserExists();
	};

	const openDialogOrGenerateUser = () => {
		setShowGenerateModal(acpUserExists.isAcpConnectorUserAvailable);
		if (!acpUserExists.isAcpConnectorUserAvailable) {
			generateSyncUser();
		}
	};

	const checkACPUserExists = async () => {
		try {
			let url = `${urlService.getAuthorityResourcesApiUrl(Resource.IppAcpConnectorSettings)}/CheckApiUserExist`;
			let data: UserAvailable = await apiService.getResource(url);
			setAcpUserExists({ ...data });
		} catch (err) {
			alertService.addError(err.message);
		}
	};

	const getACPStatus = async () => {
		let url = `${urlService.getApiBaseUrl()}/status`;
		let data: ApiStatus = await apiService.getResource(url);
		setStatusACP({ ...data });
	};

	return (
		<div className="row">
			<div className="col-lg-5">
				<strong id="acp-config-subheading">
					{localizationService.getLocalizedString('ipp.authorityAcpConnector.apiSyncUserSettings.heading')}
				</strong>
				<p id="acp-config-info">
					{localizationService.getLocalizedString(
						'ipp.authorityAcpConnector.apiSyncUserSettings.description'
					)}
				</p>
				<p id="acp-config-version">
					{localizationService.getLocalizedString(
						'ipp.authorityAcpConnector.apiSyncUserSettings.minVersion',
						statusACP.adcMinVersion,
						statusACP.ctsDatabaseMinVersion.concat(
							statusACP.ctsDatabaseMinPatch ? ` ${statusACP.ctsDatabaseMinPatch}` : ''
						)
					)}
				</p>
			</div>
			<div className="col-lg-7">
				<section>
					<div>
						<button className="btn ai-action" id="generate" onClick={openDialogOrGenerateUser}>
							{localizationService.getLocalizedString('ipp.buttons.generate')}
						</button>
					</div>
					{showACPData && (
						<>
							<p className="mt-3">
								{localizationService.getLocalizedString(
									'ipp.authorityAcpConnector.apiSyncUserSettings.editor'
								)}
							</p>
							<label className="d-block" htmlFor="sync-username">
								{localizationService.getLocalizedString(
									'ipp.authorityAcpConnector.apiSyncUserSettings.acpUsername'
								)}
							</label>
							<p id="sync-username" className="p-2 acp-sync-credentials mb-0">
								{encryptedResponse.username}
							</p>
							<a
								href="#/"
								className="d-inline-block mb-3 font-size-14px-medium"
								onClick={(event: any) => props.copyToClipBoard(event, encryptedResponse.username)}>
								{localizationService.getLocalizedString(
									'ipp.authorityAcpConnector.apiSyncUserSettings.copyAcpUsername'
								)}
							</a>
							<label className="d-block" htmlFor="sync-password">
								{localizationService.getLocalizedString(
									'ipp.authorityAcpConnector.apiSyncUserSettings.acpPassword'
								)}
							</label>
							<p id="sync-password" className="p-2 acp-sync-credentials mb-0">
								{encryptedResponse.password}
							</p>
							<a
								href="#/"
								className="d-inline-block mb-3 font-size-14px-medium"
								onClick={(event: any) => props.copyToClipBoard(event, encryptedResponse.password)}>
								{localizationService.getLocalizedString(
									'ipp.authorityAcpConnector.apiSyncUserSettings.copyAcpPassword'
								)}
							</a>
						</>
					)}
					<div className="mt-3">
						{acpUserExists.isAcpConnectorUserAvailable && (
							<div>
								<SingleCheckbox
									id="disable-acp-connector-user"
									name="isEnabled"
									checked={!acpUserExists.isEnabled}
									label={localizationService.getLocalizedString(
										'ipp.authorityAcpConnector.apiSyncUserSettings.disableUser'
									)}
									className="d-inline-block"
									onChange={(e: any) => toggleDisableUser(e)}
								/>
							</div>
						)}
					</div>
				</section>
			</div>
			<PopoverModal
				showModal={showGenerateModal}
				saveButtonText={localizationService.getLocalizedString('ipp.buttons.generate')}
				title={localizationService.getLocalizedString(
					'ipp.authorityAcpConnector.apiSyncUserSettings.generateConfirmationModal.heading'
				)}
				save={generateSyncUser}
				saveButtonClassName="ai-action"
				cancel={() => setShowGenerateModal(false)}>
				<p>
					{localizationService.getLocalizedString(
						'ipp.authorityAcpConnector.apiSyncUserSettings.generateConfirmationModal.message'
					)}
				</p>
			</PopoverModal>
		</div>
	);
};

export default IppApiSyncUserSettings;
