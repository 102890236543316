import React from 'react';

interface Props {
	size?: number;
	fillColor: string;
	backgroundColor: string;
}

export const RiskCircleSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
			<g id="Low_risk_circle" data-name="Low risk circle" transform="translate(1 1)">
				<g id="Group_11398" data-name="Group 11398" transform="translate(-472 -2983)">
					<g id="Type_1_-_default" data-name="Type 1 - default" transform="translate(371 2604)">
						<g
							id="Path_3964"
							data-name="Path 3964"
							transform="translate(101 379)"
							fill={props.backgroundColor}>
							<path
								d="M 11 22.5 C 7.928239822387695 22.5 5.040339946746826 21.30378913879395 2.868269920349121 19.13172912597656 C 0.6962100267410278 16.95965957641602 -0.5 14.0717601776123 -0.5 11 C -0.5 7.928239822387695 0.6962100267410278 5.040339946746826 2.868269920349121 2.868269920349121 C 5.040339946746826 0.6962100267410278 7.928239822387695 -0.5 11 -0.5 C 14.0717601776123 -0.5 16.95965957641602 0.6962100267410278 19.13172912597656 2.868269920349121 C 21.30378913879395 5.040339946746826 22.5 7.928239822387695 22.5 11 C 22.5 14.0717601776123 21.30378913879395 16.95965957641602 19.13172912597656 19.13172912597656 C 16.95965957641602 21.30378913879395 14.0717601776123 22.5 11 22.5 Z"
								stroke="none"
							/>
							<path
								d="M 11 0 C 4.924869537353516 0 0 4.924869537353516 0 11 C 0 17.07513046264648 4.924869537353516 22 11 22 C 17.07513046264648 22 22 17.07513046264648 22 11 C 22 4.924869537353516 17.07513046264648 0 11 0 M 11 -1 C 14.20532035827637 -1 17.21878051757812 0.2482204437255859 19.48527908325195 2.514720916748047 C 21.75177955627441 4.781219482421875 23 7.794679641723633 23 11 C 23 14.20532035827637 21.75177955627441 17.21878051757812 19.48527908325195 19.48527908325195 C 17.21878051757812 21.75177955627441 14.20532035827637 23 11 23 C 7.794679641723633 23 4.781219482421875 21.75177955627441 2.514720916748047 19.48527908325195 C 0.2482204437255859 17.21878051757812 -1 14.20532035827637 -1 11 C -1 7.794679641723633 0.2482204437255859 4.781219482421875 2.514720916748047 2.514720916748047 C 4.781219482421875 0.2482204437255859 7.794679641723633 -1 11 -1 Z"
								stroke="none"
								fill="#fff"
							/>
						</g>
						<path
							id="utensils-solid"
							d="M4.873.355A28.656,28.656,0,0,1,5.25,3.374,2.471,2.471,0,0,1,3.635,5.825l.3,5.58A.564.564,0,0,1,3.375,12h-1.5a.564.564,0,0,1-.562-.593l.3-5.58A2.47,2.47,0,0,1,0,3.374,28.571,28.571,0,0,1,.377.355C.452-.121,1.439-.128,1.5.381V3.69c.03.08.354.075.375,0C1.908,3.1,2.06.428,2.062.367c.077-.487,1.048-.487,1.123,0,0,.063.155,2.73.187,3.323.021.075.347.08.375,0V.381c.061-.506,1.05-.5,1.125-.026Zm2.794,6.7-.352,4.338a.562.562,0,0,0,.56.609H9.187a.561.561,0,0,0,.562-.562V.561A.563.563,0,0,0,9.187,0C7.254,0,4,4.182,7.666,7.051Z"
							transform="translate(107 384.001)"
							fill={props.fillColor}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
