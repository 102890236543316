import { TranslationLanguage } from '@rcp/types';
import _ from 'lodash';
import { apiService, Resource, urlService } from 'src/services';

export interface LanguagesState {
	languages: TranslationLanguage[];
}

export const initialLanguagesState: LanguagesState = { languages: [] };

enum LanguageActionType {
	LoadLanguages = 'LoadLanguages'
}

export const loadLanguages = () => async (dispatch: any) => {
	let url = `${urlService.getApiBaseUrl()}/${Resource.TranslationLanguages}`;
	let languages = await apiService.httpGet(url);

	dispatch({
		type: LanguageActionType.LoadLanguages,
		languages: languages
	});
};

export const languagesReducer = (state = initialLanguagesState, action: any): LanguagesState => {
	switch (action.type) {
		case LanguageActionType.LoadLanguages:
			return {
				...state,
				languages: action.languages as TranslationLanguage[]
			};
		default:
			return { ...state };
	}
};
