import {
	AddNoticeSteps,
	CleaningNoticeTemplateSchedule,
	Dictionary,
	LetterTemplateTranslation,
	NoticeTemplateType
} from '@rcp/types';
import React, { useState, useEffect, useContext, useRef } from 'react';
import _ from 'lodash';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { authoritySettingService, localizationService, Logger, validationService } from 'src/services';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { alertService, RootState } from 'src/redux';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useSelector } from 'react-redux';
import './steps.scss';
import { TextInput } from 'src/components/widgets';
import { DoNotTranslateButton } from 'src/components/widgets/do-not-translate-button';
import { Utils } from 'src/services/utils';
import { LetterTemplateTranslations } from '../letter-template-translations';

export interface FormFields extends CleaningNoticeTemplateSchedule {
	templateSubjectError?: string;
	templateContentError?: string;
}

const initialNoticeContent: FormFields = {
	templateSubjectError: '',
	templateContentError: ''
};

export const Step3 = () => {
	const stepperContext = useContext(StepperContext);
	const [noticeContent, setNoticeContent] = useState(initialNoticeContent);
	const [letterTemplateTranslations, setLetterTemplateTranslations] = useState<Dictionary<LetterTemplateTranslation>>(
		{}
	);
	const noticeContentRef = useRef<any>(null);
	const [subjectCursorStartPosition, setSubjectCursorStartPosition] = useState(0);
	const [subjectCursorEndPosition, setSubjectCursorEndPosition] = useState(0);
	noticeContentRef.current = noticeContent;
	const nextButtonClickedRef = useRef<any>(null);

	let cleaningNoticeTemplateSchedule = (state: RootState) => state.cleaningNoticeTemplate;
	let { selected: cleaningNoticeTemplate } = useSelector(cleaningNoticeTemplateSchedule);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);

	const updateStepOnUnmount = () => {
		let dataToBeSaved = noticeContentRef.current;
		const updatedStep3 = {
			...stepperContext.getStep(AddNoticeSteps.STEP3),
			data: { noticeContent: dataToBeSaved }
		};
		stepperContext.updateStep(AddNoticeSteps.STEP3, updatedStep3);
	};

	useEffect(() => {
		const step3Content = stepperContext.getData(AddNoticeSteps.STEP3);
		if (step3Content) {
			setNoticeContent(step3Content.noticeContent || initialNoticeContent);
		} else if (cleaningNoticeTemplate) {
			setNoticeContent({
				templateSubject: cleaningNoticeTemplate.templateSubject,
				templateContent: cleaningNoticeTemplate.templateContent,
				letterTemplateTranslationsJson: cleaningNoticeTemplate.letterTemplateTranslationsJson
			});
		}
		return () => {
			const isFormValid = !nextButtonClickedRef.current ? isFormValidateForNext() : true;
			!nextButtonClickedRef.current && updateStepOnUnmount();
			if (!isFormValid) {
				stepperContext.goAt(AddNoticeSteps.STEP3);
			}
		};
	}, [cleaningNoticeTemplate]);

	const [hasLanguageSupport, setHasLanguageSupport] = useState(false);

	useEffect(() => {
		authoritySettingService.getAuthorityLanguageSupportStatus(setHasLanguageSupport);
	}, [hasLanguageSupport]);

	const onClickBack = () => stepperContext.goAt(AddNoticeSteps.STEP2);

	const onClickNext = () => {
		nextButtonClickedRef.current = true;
		if (isFormValidateForNext()) {
			let newState = { ...noticeContent };
			newState.letterTemplateTranslationsJson = JSON.stringify(letterTemplateTranslations);
			Logger.info(
				`Setting template will save cleaning notice template with translations:`,
				newState.letterTemplateTranslationsJson
			);
			stepperContext.resolve({ noticeContent: newState });
		}
	};

	const changeNoticeContent = (e: any) => {
		let newState = { ...noticeContent };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setNoticeContent(newState);
	};

	const isFormValidateForNext = (): boolean => {
		let newState = { ...(nextButtonClickedRef.current ? noticeContent : noticeContentRef.current) };

		validationService.validateRequiredField(
			newState,
			'templateSubject',
			'templateSubjectError',
			localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')
		);

		validationService.validateRequiredField(newState, 'templateContent', 'templateContentError');

		setNoticeContent(newState);

		const isFromValid = !validationService.hasError(newState, 'templateSubjectError', 'templateContentError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return (
			isFromValid &&
			validationService.validateMergeFields(newState.templateContent + newState.templateSubject, mergeFields) &&
			validationService.validateDoNotTranslateFields(
				(noticeContent.templateContent || '') + (noticeContent.templateSubject + '')
			)
		);
	};

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		Utils.onChangeMergeFieldHandler(
			e,
			noticeContent,
			subjectCursorStartPosition,
			subjectCursorEndPosition,
			setSubjectCursorStartPosition,
			setSubjectCursorEndPosition,
			setNoticeContent
		);
	};

	const onClickDoNotTranslateButton = () => {
		Utils.onClickDoNotTranslate(
			noticeContent,
			subjectCursorStartPosition,
			subjectCursorEndPosition,
			setSubjectCursorStartPosition,
			setSubjectCursorEndPosition,
			setNoticeContent
		);
	};

	return (
		<StepperContent
			id="edit-content"
			className="w-100 mr-3"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnBack" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-row">
				<TextInput
					id="subject"
					name="templateSubject"
					className="col-sm-8 form-group"
					onChange={changeNoticeContent}
					label={localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')}
					isRequired={true}
					value={noticeContent.templateSubject}
					onBlur={(e: any) => {
						setSubjectCursorStartPosition(e.target.selectionStart);
						setSubjectCursorEndPosition(e.target.selectionEnd);
					}}
				/>
				<div className="col-sm-4 d-inline-block">
					<MergeFieldDropDown
						id="mergeTags"
						name="mergeTags"
						className="col-sm-4"
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.mergeTags')}
						hintText={localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.mergeTagsHint'
						)}
						onChange={onChangeMergeField}
					/>
					<DoNotTranslateButton
						disabled={
							subjectCursorStartPosition == subjectCursorEndPosition ||
							(noticeContent.templateSubject != null &&
								subjectCursorEndPosition > noticeContent.templateSubject.length)
						}
						onClick={onClickDoNotTranslateButton}
						hasLanguageSupport={hasLanguageSupport}
					/>
				</div>
			</div>
			<div className="form-group required">
				<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.body')}</label>
				<div className="add-notice-editor-template">
					<RichTextEditor
						noticeTemplateType={NoticeTemplateType.FogCleaningNotice}
						editorContent={noticeContent.templateContent}
						onChange={(event: any) => {
							setNoticeContent({
								...noticeContent,
								templateContent: event.html
							});
						}}
						height="366px"
						isLanguageSupportEnabled={hasLanguageSupport}
					/>
				</div>
			</div>
			{hasLanguageSupport && (
				<LetterTemplateTranslations
					noticeTemplate={noticeContent}
					letterTemplateTranslationsJson={noticeContent.letterTemplateTranslationsJson}
					letterTemplateTranslations={letterTemplateTranslations}
					setLetterTemplateTranslations={setLetterTemplateTranslations}
				/>
			)}
		</StepperContent>
	);
};
