import React, { FC, useEffect, useState } from 'react';
import { History } from 'history';
import { localizationService, navigateTo, urlService, validationService } from 'src/services';
import * as LinkoTypes from '@rcp/types';
import { DeleteModal, DeleteModalProp, TextAreaInput, TextInput } from 'src/components/widgets';
import { ippDataProviderDetailSlice } from './data-provider-details-slice';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, RootState } from 'src/redux';
import _ from 'lodash';
import { NotFound } from 'src/features/home/not-found';
import { IppConstants } from 'src/constants';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props extends LinkoTypes.RouteProps {
	history: History;
}

const initialDataprovider: LinkoTypes.IppDataProvider = {
	name: '',
	description: ''
};

interface ErrorState {
	nameError: string;
}

const initialErrorState: ErrorState = {
	nameError: ''
};

const { textAreaRows } = IppConstants;
const { fieldCharLimit } = IppConstants;
let getGuidWithRegulateeName = urlService.getUrlWithRegulateeTypeAndGuid();
const importSamplesUrl = `/${getGuidWithRegulateeName}/samples/import`;

const IppDataProviderDetails: FC<Props> = props => {
	const { dataSourceId } = props.match.params;
	const dataProviderPath = `/${getGuidWithRegulateeName}/industryAccount/dataProviders`;

	let newForm: string = 'new';
	const dispatch = useDispatch();
	let dataProviderDetailState = (state: RootState) => state.ippDataProviderDetails;
	let { selected } = useSelector(dataProviderDetailState);

	let [state, setState] = useState(initialDataprovider);
	let [errorState, setErrorState] = useState(initialErrorState);
	let [deleteModal, setDeleteModal] = useState<boolean>(false);
	let [isAccessDenied, setIsAccessDenied] = useState<boolean>(false);

	const isFormInAddMode = () => {
		if (window.location.pathname.includes(newForm)) {
			return true;
		} else {
			return false;
		}
	};

	const isInvalidUrl = () => {
		return !isFormInAddMode() && !Number(dataSourceId) ? true : false;
	};

	useEffect(() => {
		if (isFormInAddMode()) {
			setState(initialDataprovider);
		} else {
			Number(dataSourceId) &&
				dispatch(
					ippDataProviderDetailSlice.fetchOne(
						dataSourceId as number,
						undefined,
						(error: LinkoTypes.ApiError) => {
							if (
								error &&
								error.statusCode === 403 &&
								String.equalCaseInsensitive(
									error.message,
									localizationService.getLocalizedString('errors.noPermissionToAccess')
								)
							) {
								alertService.clearAllMessages();
								setIsAccessDenied(true);
							}
						}
					)
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		!isFormInAddMode() && selected && setState({ ...selected });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	const onChange = (event: any) => {
		let { value, name } = event.currentTarget;
		setState({ ...state, [name]: value });
	};

	const isFormValidForSave = (): boolean => {
		let formData = { ...state, ...errorState };
		validationService.validateRequiredField(formData, 'name', 'nameError');
		setErrorState(_.pick(formData, ['nameError']));
		let isFormValid = false;
		isFormValid = !validationService.hasError(formData, 'nameError');
		return isFormValid;
	};

	const onSaveCallback = (data?: any) => {
		let id = data.dataSourceId || data.items[0].dataSourceId;
		setState(initialDataprovider);
		navigateTo(props.history, `${dataProviderPath}/${id}`);
		dispatch(ippDataProviderDetailSlice.fetchOne(id));
	};

	const onSave = () => {
		let payload = { ...state };
		isFormValidForSave() &&
			isFormInAddMode() &&
			dispatch(
				ippDataProviderDetailSlice.createOne(
					payload,
					false,
					localizationService.getLocalizedString(
						'ipp.industryAccount.dataProviders.dataProviderAddSuccessMessage'
					),
					(data: any) => onSaveCallback(data)
				)
			);
		isFormValidForSave() &&
			!isFormInAddMode() &&
			dispatch(
				ippDataProviderDetailSlice.patchOne(
					Number(dataSourceId),
					payload,
					false,
					localizationService.getLocalizedString(
						'ipp.industryAccount.dataProviders.dataProviderUpdateSuccessMessage'
					),
					(data: any) => onSaveCallback(data)
				)
			);
	};

	const onToggleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const onDeleteDataProvider = () => {
		dispatch(
			ippDataProviderDetailSlice.deleteOne(
				dataSourceId,
				false,
				localizationService.getLocalizedString(
					'ipp.industryAccount.dataProviders.dataProviderDeleteSuccessMessage'
				),
				() => navigateTo(props.history, dataProviderPath)
			)
		);
	};

	const deleteDataProviderModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString(`ipp.industryAccount.dataProviders.dataProviderDeleteHeading`),
		message: localizationService.getLocalizedString('ipp.industryAccount.dataProviders.deleteConfirmText'),
		showModal: deleteModal,
		onCancelButtonClick: () => onToggleDeleteModal(),
		onOkayButtonClick: onDeleteDataProvider,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	return isInvalidUrl() ? (
		<NotFound />
	) : isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('ipp.industryAccount.dataProviders.detailsPageHeader')}</h1>
			</div>
			<section>
				<div className="d-flex align-items-center">
					<span className="font-size-20px-regular">
						{localizationService.getLocalizedString('ipp.industryAccount.dataProviders.detailsSubHeader')}
					</span>
					<div className="ml-auto">
						{!isFormInAddMode() && (
							<>
								<button
									id="import-sample-btn"
									className="btn ai-action mr-2"
									onClick={(e: any) => navigateTo(props.history, importSamplesUrl, e, state)}>
									{localizationService.getLocalizedString('ipp.buttons.importSamples')}
								</button>
								<button
									id="translations-btn"
									className="btn ai-action"
									onClick={() =>
										navigateTo(props.history, `${window.location.pathname}/translations`)
									}>
									{localizationService.getLocalizedString(`ipp.buttons.viewTranslations`)}
								</button>
							</>
						)}
					</div>
				</div>
				<hr />
				<p className="data-provider-information">
					{localizationService.getLocalizedString('ipp.industryAccount.dataProviders.dataProvidersInfo')}
				</p>
				<div className="col-md-8 ml-auto mr-auto">
					<div className="form-row">
						<TextInput
							id="name"
							className="col-sm-12"
							name="name"
							label={localizationService.getLocalizedString(
								'ipp.industryAccount.dataProviders.columns.name'
							)}
							value={state.name}
							error={errorState.nameError}
							isRequired
							onChange={event => onChange(event)}
							remainingInputProps={{ maxLength: fieldCharLimit.shortElementName }}
						/>
					</div>
					<div className="form-row">
						<TextAreaInput
							id="description"
							className="col-sm-12"
							name="description"
							label={localizationService.getLocalizedString(
								'ipp.industryAccount.dataProviders.columns.description'
							)}
							value={state.description}
							rows={textAreaRows}
							onChange={event => onChange(event)}
							maxLength={fieldCharLimit.commentsAndDescription}
						/>
					</div>
				</div>
				<hr />
				<div className="d-flex">
					<div className="data-provider-buttons ml-auto">
						<button id="save-btn" className="btn ai-save" onClick={onSave}>
							{localizationService.getLocalizedString(`ipp.buttons.save`)}
						</button>
						{isFormInAddMode() ? (
							<button
								id="cancel-btn"
								className="btn ai-action ml-1"
								onClick={() => navigateTo(props.history, dataProviderPath)}>
								{localizationService.getLocalizedString(`screen.buttons.cancel`)}
							</button>
						) : (
							<button
								id="cancel-btn"
								className="btn ai-delete ml-1"
								onClick={() => onToggleDeleteModal()}>
								{localizationService.getLocalizedString(`screen.buttons.delete`)}
							</button>
						)}
					</div>
				</div>
			</section>
			<DeleteModal {...deleteDataProviderModalProp} key="deleteDataProvider" />
		</>
	);
};

export default IppDataProviderDetails;
