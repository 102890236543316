import { timelineEventsSlice } from './timeline-events.slice';
import { GetInitialTimelineSettings, TimelineSettings } from './timeline-type';
import {
	localStorageService,
	DateUtilService,
	dispatchAccessor,
	QueryParameters,
	urlService,
	Resource,
	apiService
} from 'src/services';
import { LocalStorageName, MaxInteger, RegulatoryProgramName } from '@rcp/types';
import _ from 'lodash';
import { cccHazardSlice } from 'src/components/authority/backflow/hazards/hazards.slice';
import { facilityEventSlice } from '../../components/authority/fog/facilities/facility-details/event/event.slice';

export const loadTimelineSettings = (): TimelineSettings => {
	let timelineSettingsToSave = { ...GetInitialTimelineSettings() };

	let savedTimelineSettings = localStorageService.getLocalStorageWithCallback<TimelineSettings>(
		LocalStorageName.CccTimelineFilter,
		(filter: TimelineSettings) => {
			filter.startDate = DateUtilService.getDateStringInAuthorityTimeZone(filter.startDate);
			filter.endDate = DateUtilService.getDateStringInAuthorityTimeZone(filter.endDate);
		}
	);
	//discard browser localStorage saved filter if the version is different
	const initialTimelineSettings = GetInitialTimelineSettings();
	if (
		savedTimelineSettings &&
		savedTimelineSettings.version &&
		savedTimelineSettings.version === initialTimelineSettings.version
	) {
		//overwrite react component state value from localStorage
		timelineSettingsToSave = { ...savedTimelineSettings };
	}

	return timelineSettingsToSave;
};

export const saveTimelineSettings = (timelineSettings: TimelineSettings) => {
	localStorageService.setLocalStorage(LocalStorageName.CccTimelineFilter, timelineSettings);
};

export const getTimelineSettingDateRange = (timelineSettings: any = null) => {
	let currentSettings = timelineSettings ? timelineSettings : loadTimelineSettings();
	let startDateStr = DateUtilService.floorDate(
		DateUtilService.getMomentInAuthorityTimeZone(currentSettings.startDate)
	);
	let endDateStr = DateUtilService.ceilingDate(DateUtilService.getMomentInAuthorityTimeZone(currentSettings.endDate));

	return {
		startDateStr,
		endDateStr
	};
};

export const reloadTimelineEventsFromServer = (timelineFilterSetting?: TimelineSettings) => {
	if (timelineFilterSetting == null) {
		timelineFilterSetting = loadTimelineSettings();
	}

	let dateRange = getTimelineSettingDateRange(timelineFilterSetting);
	let startDateStr = dateRange.startDateStr;
	let endDateStr = dateRange.endDateStr;
	let excludeList = timelineFilterSetting.excludeList;

	loadTimelineData(startDateStr, endDateStr, excludeList);
};

export const loadFacilityEvents = (stateDateStr?: string, endDateStr?: string) => async (dispatch: any) => {
	//Fetch by due date
	let dueDateQueryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(stateDateStr) && !_.isEmpty(endDateStr)) {
		dueDateQueryParams.put('dueDate', `gte:${stateDateStr}`);
		dueDateQueryParams.put('dueDate', `lte:${endDateStr}`);
		dueDateQueryParams.put('completeDate', null);
	}
	const facilityId = urlService.getFogFacilityId();
	const eventsUrlByDueDate = `${urlService.getFacilityResourceApiUrl(
		facilityId,
		Resource.Events
	)}?${dueDateQueryParams.toQueryString()}`;
	let eventsByDueDate: any = {};
	eventsByDueDate = await apiService.getResource(eventsUrlByDueDate);
	//Fetch by completeDate
	let eventsByCompleteDate: any = {};
	let completeDateQueryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(stateDateStr) && !_.isEmpty(endDateStr)) {
		completeDateQueryParams.put('completeDate', `gte:${stateDateStr}`);
		completeDateQueryParams.put('completeDate', `lte:${endDateStr}`);
	}
	const eventsUrlByCompleteDate = `${urlService.getFacilityResourceApiUrl(
		facilityId,
		Resource.Events
	)}?${completeDateQueryParams.toQueryString()}`;
	eventsByCompleteDate = await apiService.getResource(eventsUrlByCompleteDate);
	const events = _.concat(eventsByDueDate.result, eventsByCompleteDate.result);
	dispatch(facilityEventSlice.fetchSuccess(events));
};

const loadTimelineData = (stateDateStr?: string, endDateStr?: string, excludeList: string[] = []) => {
	let queryParams = new QueryParameters();

	if (!_.isEmpty(stateDateStr) && !_.isEmpty(endDateStr)) {
		queryParams.put('startDate', `gte:${stateDateStr}`);
		queryParams.put('endDate', `lte:${endDateStr}`);
	}

	if (excludeList.length > 0) {
		queryParams.put('excludeList', excludeList.join());
	}
	dispatchAccessor.dispatch(timelineEventsSlice.fetchAll(queryParams.toQueryString(), undefined, undefined, false));

	let hazardId = urlService.getCccHazardId();
	if (hazardId && hazardId > 0) {
		dispatchAccessor.dispatch(cccHazardSlice.fetchOne(hazardId));
	}
};
