import {
	DropDownOption,
	IppCollectionMethod,
	IppIndustrySample,
	IppMonitoringPoint,
	IppSampleFlowUnit,
	IppSampleType,
	IppProgramSettings
} from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SingleCheckbox, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { CustomDateTimePicker } from 'src/components/widgets/custom-date-input';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { IppConstants, ReportToSampleStatus } from 'src/constants';
import { ApplicationState } from 'src/redux';
import {
	updateSample,
	loadCollectionMethods,
	loadFlowUnits,
	loadParameterGroups,
	loadSampleTypes,
	SampleState,
	setSampleData,
	loadParameters
} from 'src/redux/ipp/industry/samples';
import { DateUtilService } from 'src/services';
import { localizationService } from 'src/services/localizationService';
import './step2.scss';

const resourceRoot = 'ipp.samples.step2';
interface DispatchProps {
	loadCollectionMethods: () => Promise<boolean | undefined>;
	loadSampleTypes: () => Promise<boolean | undefined>;
	loadFlowUnits: () => Promise<boolean | undefined>;
	setSampleData: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	loadParameterGroups: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	updateSample: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	loadParameters: (queryString?: string) => Promise<boolean | undefined>;
}

interface Props extends DispatchProps {
	sample: IppIndustrySample;
	monitoringPoints?: IppMonitoringPoint[];
	collectionMethods?: IppCollectionMethod[];
	sampleTypes?: IppSampleType[];
	flowUnits?: IppSampleFlowUnit[];
	disableForm?: () => boolean;
	history?: any;
	reportToSampleStatus?: string;
	programSettings?: IppProgramSettings;
	updateAndRerender?: (sample: IppIndustrySample) => Promise<boolean>;
	isNewOrCopySample?: boolean;
}

const { fieldCharLimit } = IppConstants;
const { CopyAndEdit, AddSampleToReport } = ReportToSampleStatus;

const IppMonitoringPointComponent: FC<Props> = (props: Props) => {
	const [dateHelpText, setDateHelpText] = useState('');
	useEffect(() => {
		props.loadCollectionMethods();
		props.loadSampleTypes();
		props.loadFlowUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let sample = { ...props.sample, flowUnitValidValues: props.flowUnits };
		props.setSampleData(sample);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.flowUnits]);

	useEffect(() => {
		if (props.sample) {
			props.loadParameterGroups(props.sample);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props.sample.collectionMethodId,
		props.sample.monitoringPointId,
		props.sample.sampleStatusName,
		props.sample.startDateTimeLocal,
		props.sample.endDateTimeLocal
	]);

	useEffect(() => {
		if (props.history.location.state) {
			((props.history.location.state as any).periodStartDateTimeLocal ||
				(props.history.location.state as any).periodEndDateTimeLocal) &&
				setDateHelpText(localizationService.getLocalizedString('ipp.samples.step1.addSampleToReportNote'));
		}
	}, [props.history.location]);

	const getCollectionMethods = (collectionMethods: IppCollectionMethod[]) => {
		let options: DropDownOption[] = collectionMethods.map(collectionMethod => {
			return {
				label: collectionMethod.name || '',
				value: collectionMethod.collectionMethodId
			};
		});
		options.unshift({
			label: localizationService.getLocalizedString(`${resourceRoot}.selectCollectionMethod`),
			value: 0
		});
		return options;
	};

	const getSampleTypes = (sampleTypes: IppSampleType[]) => {
		let options: DropDownOption[] = sampleTypes.map(sampleType => {
			return {
				label: sampleType.name || '',
				value: sampleType.ctsEventTypeId
			};
		});
		options.unshift({
			label: localizationService.getLocalizedString(`${resourceRoot}.selectSampleType`),
			value: 0
		});
		return options;
	};

	const getFlowUnits = (flowUnits: IppSampleFlowUnit[]) => {
		let settings = props.programSettings!['settings'];
		let flowUnitsValidValues =
			settings && settings.length
				? settings.filter(settings => {
						return settings.settingType === 'FlowUnitValidValues';
				  })[0].value
				: '';
		flowUnits = flowUnits.filter((flowUnit: IppSampleFlowUnit) =>
			flowUnitsValidValues!.includes(flowUnit.name || '')
		);
		let options: DropDownOption[] = flowUnits.map(flowUnit => {
			return {
				label: flowUnit.name || '',
				value: flowUnit.unitId
			};
		});
		options.unshift({
			label: localizationService.getLocalizedString(`${resourceRoot}.selectFlowUnit`),
			value: 0
		});
		return options;
	};

	const updateSampleData = async (e: any) => {
		let { name, value, checked } = e.target;
		let sample: IppIndustrySample = { ...props.sample };
		if (e.target.type === 'checkbox') {
			sample = { ...sample, [name]: checked };
			if (name === 'isSampleExcludedFromReports') {
				if (!props.isNewOrCopySample) {
					props.updateAndRerender && (await props.updateAndRerender(sample));
				} else {
					props.setSampleData(sample);
				}
			}
		} else {
			sample = { ...sample, [name]: value };
			props.setSampleData(sample);
		}
		if (name === 'startDateTimeLocal' || name === 'endDateTimeLocal') {
			props.loadParameters(
				`sort=name asc&monitoringPointId=${props.sample.monitoringPointId}&startDateTime=${
					name === 'startDateTimeLocal' ? value : props.sample.startDateTimeLocal
				}&endDateTime=${name === 'endDateTimeLocal' ? value : props.sample.endDateTimeLocal}`
			);
		}
	};

	const updateCollectionMethod = (e: any) => {
		const { value } = e.target;
		if (props.collectionMethods) {
			let selectedCollectMethod = props.collectionMethods.find(
				item => Number(item.collectionMethodId) === Number(value)
			);
			let sample = {
				...props.sample,
				collectionMethodName: selectedCollectMethod && selectedCollectMethod.name,
				collectionMethodId: Number(value)
			};
			props.setSampleData(sample);
		}
	};

	const updateSampleType = (e: any) => {
		const { value } = e.target;
		if (props.sampleTypes) {
			let selectedSampleType = props.sampleTypes.find(item => Number(item.ctsEventTypeId) === Number(value));
			let sample = {
				...props.sample,
				ctsEventTypeName: selectedSampleType && selectedSampleType.name,
				ctsEventTypeId: Number(value),
				ctsEventCategoryName: selectedSampleType && selectedSampleType.ctsEventCategoryName
			};
			props.setSampleData(sample);
		}
	};
	const updateFlowUnit = (e: any) => {
		const { value } = e.target;
		if (props.flowUnits) {
			let selectedFlowUnits = props.flowUnits.find(item => Number(item.unitId) === Number(value));
			let sample = {
				...props.sample,
				flowUnitName: selectedFlowUnits && selectedFlowUnits.name,
				flowUnitId: Number(value)
			};
			props.setSampleData(sample);
		}
	};

	return (
		<>
			<CollapsibleCard
				accordionType="monitoring-point-details"
				accordionHeading={`${localizationService.getLocalizedString(`${resourceRoot}.monitoringPoint`)}: ${
					props.sample && props.sample.monitoringPointName ? props.sample.monitoringPointName : ''
				}`}>
				<div className="w-80 mx-auto">
					<div className="form-row">
						<SingleSelectDropdown
							id="collection-method"
							name="collectionMethodName"
							label={localizationService.getLocalizedString(`${resourceRoot}.collectionMethod`)}
							noEmptyOption={true}
							isRequired={true}
							options={getCollectionMethods(props.collectionMethods || [])}
							selfOrder={true}
							value={props.sample && props.sample.collectionMethodName}
							onChange={updateCollectionMethod}
							className="col-md-3"
							isDisabled={props.disableForm && props.disableForm()}
						/>
						<div className="col-md-1" />
						<SingleSelectDropdown
							id="sample-type"
							name="ctsEventTypeName"
							label={localizationService.getLocalizedString(`${resourceRoot}.sampleType`)}
							noEmptyOption={true}
							options={getSampleTypes(props.sampleTypes || [])}
							onChange={updateSampleType}
							isRequired={true}
							selfOrder={true}
							value={props.sample && props.sample.ctsEventTypeName}
							className="col-md-4"
							isDisabled={props.disableForm && props.disableForm()}
						/>
						<div className="col-md-1" />
						<CustomDateTimePicker
							id="start-date"
							name="startDateTimeLocal"
							isRequired={true}
							max={DateUtilService.getAuthorityTimezoneNow()}
							value={props.sample && props.sample.startDateTimeLocal}
							label={localizationService.getLocalizedString(`${resourceRoot}.startDate`)}
							onChange={updateSampleData}
							className="col-md-3 date-width"
							isDisabled={props.disableForm && props.disableForm()}
							helpText={dateHelpText}
						/>
					</div>
					<div className="form-row mt-1">
						<TextInput
							id="lab-sample-id"
							name="labSampleIdentifier"
							label={localizationService.getLocalizedString(`${resourceRoot}.labSampleId`)}
							className="col-md-3"
							value={props.sample && props.sample.labSampleIdentifier}
							onChange={updateSampleData}
							isDisabled={props.disableForm && props.disableForm()}
							remainingInputProps={{ maxLength: fieldCharLimit.shortElementName }}
						/>
						<div className="col-md-1" />
						<TextInput
							id="flow-value"
							name="flowEnteredValue"
							label={localizationService.getLocalizedString(`${resourceRoot}.flowEnteredValue`)}
							className="col-md-2"
							type="number"
							value={props.sample && props.sample.flowEnteredValue}
							onChange={updateSampleData}
							isDisabled={props.disableForm && props.disableForm()}
						/>
						<SingleSelectDropdown
							id="flow-unit"
							name="flowUnitName"
							noEmptyOption={true}
							options={getFlowUnits(props.flowUnits || [])}
							selfOrder={true}
							onChange={updateFlowUnit}
							value={props.sample && props.sample.flowUnitName}
							className="col-md-2 flowUnitName"
							isDisabled={props.disableForm && props.disableForm()}
						/>
						<div className="col-md-1" />
						<CustomDateTimePicker
							id="end-date"
							name="endDateTimeLocal"
							className="col-md-3 date-width"
							isRequired={true}
							max={DateUtilService.getAuthorityTimezoneNow()}
							label={localizationService.getLocalizedString(`${resourceRoot}.endDate`)}
							value={props.sample && props.sample.endDateTimeLocal}
							onChange={updateSampleData}
							isDisabled={props.disableForm && props.disableForm()}
							helpText={dateHelpText}
						/>
					</div>
					{props.reportToSampleStatus === AddSampleToReport ||
					props.reportToSampleStatus === CopyAndEdit ? null : (
						<>
							<hr />
							<div className="form-row mt-1">
								<div className="d-flex col-md-4">
									<SingleCheckbox
										id="repudiate-sample"
										name="isSampleExcludedFromReports"
										label={localizationService.getLocalizedString(
											`${resourceRoot}.excludeFromReports`
										)}
										className="d-inline-block align-self-center"
										checked={(props.sample && props.sample.isSampleExcludedFromReports) || false}
										onChange={updateSampleData}
									/>
									<TooltipHover
										id="exclude-sample-from-reports-info"
										title={localizationService.getLocalizedString(
											`${resourceRoot}.excludeFromReportsTooltip`
										)}
										className="d-inline-block ml-1 align-self-center"
									/>
								</div>
							</div>
						</>
					)}
				</div>
			</CollapsibleCard>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): SampleState => {
	return { ...state.ippIndustrySample };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadCollectionMethods: () => dispatch(loadCollectionMethods()),
		loadSampleTypes: () => dispatch(loadSampleTypes()),
		loadFlowUnits: () => dispatch(loadFlowUnits()),
		setSampleData: (sample: IppIndustrySample) => dispatch(setSampleData(sample)),
		loadParameterGroups: (sample: IppIndustrySample) => dispatch(loadParameterGroups(sample)),
		updateSample: (sample: IppIndustrySample) => dispatch(updateSample(sample)),
		loadParameters: (queryString?: string) => dispatch(loadParameters(queryString))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppMonitoringPointComponent);
