import React, { FC, useState, useEffect } from 'react';
import { alertService } from 'src/redux/alert/alert-service';
import { apiService, localizationService, Resource, tokenService, urlService, validationService } from 'src/services';
import { ReactComponent as WarningIcon } from 'src/assets/img/inline-info.svg';
import _ from 'lodash';
import { DeleteModal, DeleteModalProp, PopoverModal, TextInput } from 'src/components/widgets';
import {
	HaulerUser,
	AuthorityUser,
	FacilityUser,
	ApiError,
	RouteProps,
	OrganizationTypeName,
	ServiceUser
} from '@rcp/types';
import { useDispatch } from 'react-redux';
import { IppTestConstants } from 'src/constants';
import { RestSlice } from 'src/redux';
import './user-modal.scss';
import { Translate } from 'src/components/widgets/translate/translator';

interface Props extends RouteProps {
	showModal: boolean;
	isEdit: boolean;
	user?: ServiceUser;
	toggleModal: () => void;
	serviceName?: string;
	serviceId?: number;
	restSlice: RestSlice<HaulerUser | AuthorityUser | FacilityUser>;
	inviteUserDesc?: string;
}

interface FormStateError {
	emailError: string;
	firstNameError: string;
	lastNameError: string;
	existingUserWarning?: string;
}

interface FormState extends ServiceUser, FormStateError {
	serviceId?: number;
}

const initialFormFields: FormState = {
	email: '',
	firstName: '',
	lastName: '',
	role: IppTestConstants.standardRole,
	phoneNumber: '',
	mobilePhoneNumber: '',
	emailError: '',
	firstNameError: '',
	lastNameError: '',
	existingUserWarning: '',
	isExistingUser: false,
	isAdministrator: false
};
const { Hauler, Facility, Authority } = OrganizationTypeName;

const InviteEditUser: FC<Props> = (props: Props) => {
	const dispatch = useDispatch();
	const { showModal, toggleModal } = props;
	const [formState, setFormState] = useState(initialFormFields);
	const [isDisabled, setIsDisabled] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [organizationTypeName, setOrganizationTypeName] = useState(
		tokenService.getTokenOrDefault().portalOrganizationTypeName
	);
	const [organizationTypeNameId, setOrganizationTypeNameId] = useState(_.toLower(`${organizationTypeName}Id`));
	const [organizationTypeNameResource, setOrganizationTypeNameResource] = useState('');

	useEffect(() => {
		setOrganizationTypeNameId(_.toLower(`${organizationTypeName}Id`));
	}, [organizationTypeName]);

	useEffect(() => {
		if (props.match && organizationTypeName === Authority) {
			const params = props.match.params;
			let resourceType = '';
			let organizationName = '';
			if (params.haulerId) {
				resourceType = Resource.Haulers;
				organizationName = Hauler;
			} else if (params.fogFacilityId) {
				resourceType = Resource.FogFacilities;
				organizationName = Facility;
			}
			setOrganizationTypeNameResource(resourceType);
			setOrganizationTypeName(organizationName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.match]);

	useEffect(() => {
		setFormState({ ...formState, ...props.user });
		if (props.user && props.user.email && !props.isEdit) setIsDisabled(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user]);

	const resetModal = () => {
		setFormState({ ...initialFormFields });
		setIsDisabled(false);
	};

	useEffect(() => {
		setFormState({ ...formState, ...props.user });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user]);

	// Cancel Modal
	const onClose = () => {
		resetModal();
		toggleModal();
	};

	const getInitialStateAfterEmailChanged = (newState: FormState) => {
		newState.existingUserWarning = undefined;
		newState.isExistingUser = false;

		return newState;
	};

	const getExistingServiceUserUrl = () => `users/getExisting${organizationTypeName}UserIfNotInCurrentProgram`;

	const checkEmailExist = async (event: any) => {
		alertService.clearAllMessages();
		if (props.isEdit) {
			return;
		}
		const { name, value } = event.target;

		let newState = { ...formState };
		_.set(newState, name, value);

		validationService.validateRequiredField(
			newState,
			'email',
			'emailError',
			localizationService.getLocalizedString('users.email')
		);

		if (newState.email) {
			validationService.validateEmailFormatField(newState, 'email', 'emailError');
		}

		let isFormValid = !validationService.hasError(newState, 'emailError');

		if (isFormValid) {
			try {
				let url = `${urlService.getAuthorityResourcesApiUrl(
					urlService.isServicePortal()
						? getExistingServiceUserUrl()
						: `${organizationTypeNameResource}/${props.serviceId}/${getExistingServiceUserUrl()}`
				)}/${value.trim()}`;
				let existingUser: any = await apiService.getResource(url);

				if (existingUser) {
					alertService.clearAllMessages();
					newState.existingUserWarning = localizationService.getLocalizedString(
						'users.existingUserFoundInlineMessage'
					);

					newState.firstName = existingUser.firstName;
					newState.lastName = existingUser.lastName;
					newState.firstNameError = '';
					newState.lastNameError = '';
					newState.isExistingUser = true;
					setIsDisabled(true);
				}
			} catch (error) {
				let ex = error as ApiError;
				newState = getInitialStateAfterEmailChanged(newState);
				if (
					ex.message ===
					localizationService.getLocalizedString('userManagementErrors.userAlreadyInThisProgramWithEmail')
				) {
					alertService.addError(ex.message);
					newState.emailError = localizationService.getLocalizedString(
						'haulerPortal.settings.users.inviteHaulerUser.userExistsInProgram'
					);
				} else if (ex.message !== localizationService.getLocalizedString('users.userDoesNotExistWithEmail')) {
					alertService.addError(ex.message);
					newState.emailError = '';
				}
			}

			setFormState(newState);
		} else {
			newState = { ...formState };
			setFormState(getInitialStateAfterEmailChanged(newState));
		}
	};

	const onChange = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		_.set(newState, name, value);
		setFormState(newState);
	};

	const setAdminUser = (isAdministrator: boolean) => {
		var newState = { ...formState };
		newState.isAdministrator = isAdministrator;
		newState.role = isAdministrator ? IppTestConstants.adminRole : IppTestConstants.standardRole;
		setFormState(newState);
	};

	const isFormValidateForInvite = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'firstName',
			'firstNameError',
			localizationService.getLocalizedString('haulerPortal.settings.users.inviteHaulerUser.firstName')
		);

		validationService.validateRequiredField(
			newState,
			'email',
			'emailError',
			localizationService.getLocalizedString('haulerPortal.settings.users.inviteHaulerUser.email')
		);

		if (newState.email) {
			validationService.validateEmailFormatField(newState, 'email', 'emailError');
		}

		validationService.validateRequiredField(
			newState,
			'lastName',
			'lastNameError',
			localizationService.getLocalizedString('haulerPortal.settings.users.inviteHaulerUser.lastName')
		);

		setFormState(newState);
		const isFromValid = !validationService.hasError(newState, 'firstNameError', 'lastNameError', 'emailError');

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	// Save Modal
	const onInvite = async () => {
		if (isFormValidateForInvite()) {
			let payload = _.pick(formState, [
				'email',
				'firstName',
				'lastName',
				'isAdministrator',
				'role',
				`${organizationTypeNameId}`
			]);
			if (!props.isEdit) {
				if (!!props.user) {
					let url = `${urlService.getAuthorityResourcesApiUrl(
						urlService.isServicePortal()
							? Resource.ResendHaulerInvite
							: `${organizationTypeNameResource}/${props.serviceId}/${Resource.ResendHaulerInvite}`
					)}/${(payload.email || '').trim()}`;
					try {
						await apiService.httpPost(url, {});
						alertService.addSuccess(
							localizationService.getLocalizedString(
								`${_.toLower(
									organizationTypeName
								)}Portal.settings.users.invite${organizationTypeName}User.resendInvite`
							)
						);
						toggleModal();
						resetModal();
					} catch (ex) {
						alertService.addError(ex.message);
					}
				} else {
					!urlService.isServicePortal()
						? ((payload as any)[organizationTypeNameId] = props.serviceId)
						: delete (payload as any)[organizationTypeNameId];
					!urlService.isServicePortal() &&
						props.restSlice.setApiUrlPath((Resource as any)[`ManageAccess${organizationTypeName}User`]);
					dispatch(
						props.restSlice.createOne(
							payload,
							true,
							localizationService.getLocalizedString(
								`${_.toLower(
									organizationTypeName
								)}Portal.settings.users.invite${organizationTypeName}User.addUserSuccess`
							),
							() => {
								!urlService.isServicePortal() &&
									props.restSlice.setApiUrlPath(
										`${organizationTypeNameResource}/${props.serviceId}/${Resource.Users}`
									);
								toggleModal();
								resetModal();
							},
							undefined,
							undefined
						)
					);
				}
			} else {
				let payload = _.pick(formState, [
					'email',
					'firstName',
					'lastName',
					'isAdministrator',
					'role',
					'phoneNumber',
					'mobilePhoneNumber'
				]);
				let userId: number = props.user ? props.user.facilityUserId || props.user.authorityUserId || 0 : 0;
				!urlService.isServicePortal() &&
					props.restSlice.setApiUrlPath((Resource as any)[`ManageAccess${organizationTypeName}User`]);
				dispatch(
					props.restSlice.patchOne(
						userId,
						payload,
						true,
						localizationService.getLocalizedString(
							`${_.toLower(
								organizationTypeName
							)}Portal.settings.users.invite${organizationTypeName}User.editUserSuccess`
						),
						() =>
							!urlService.isServicePortal() &&
							props.restSlice.setApiUrlPath(
								`${organizationTypeNameResource}/${props.serviceId}/${Resource.Users}`
							),
						undefined,
						undefined
					)
				);
			}
		}
	};

	const toggleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const onDelete = () => {
		if (props.user) {
			let userId: number = props.user ? props.user.facilityUserId || props.user.authorityUserId || 0 : 0;
			!urlService.isServicePortal() &&
				props.restSlice.setApiUrlPath((Resource as any)[`ManageAccess${organizationTypeName}User`]);
			dispatch(
				props.restSlice.deleteOne(
					userId,
					true,
					localizationService.getLocalizedString(
						'alertMessages.deleteUserSuccess',
						props.user && props.user.fullName ? props.user.fullName : '',
						tokenService.getTokenOrDefault()!.portalOrganizationName || ''
					),
					() => {
						!urlService.isServicePortal() &&
							props.restSlice.setApiUrlPath(
								`${organizationTypeNameResource}/${props.serviceId}/${Resource.Users}`
							);
						toggleDeleteModal();
						props.toggleModal();
					}
				)
			);
		}
	};

	const modalFooterDiv = (
		<>
			{props.isEdit && (
				<button className="btn ai-secondary-delete mr-auto" onClick={toggleDeleteModal}>
					<Translate>{localizationService.getLocalizedString('haulerPortal.buttons.delete')}</Translate>
				</button>
			)}
			<button className={`btn ${props.isEdit ? 'ai-save' : 'ai-action'} ml-auto`} onClick={onInvite}>
				<Translate>
					{localizationService.getLocalizedString(`haulerPortal.buttons.${props.isEdit ? 'save' : 'invite'}`)}
				</Translate>
			</button>
			<button className="btn ai-white ml-2" onClick={onClose}>
				<Translate>{localizationService.getLocalizedString('screen.buttons.cancel')}</Translate>
			</button>
		</>
	);

	const deleteUserModalProps: DeleteModalProp = {
		showModal: deleteModal,
		title: localizationService.getLocalizedString(
			`${_.toLower(organizationTypeName)}Portal.settings.users.invite${organizationTypeName}User.deleteUserTitle`
		),
		okayButtonText: localizationService.getLocalizedString(
			`${_.toLower(organizationTypeName)}Portal.buttons.delete`
		),
		message: localizationService.getLocalizedString(
			'alertMessages.deleteUserInfo',
			props.user && props.user.fullName ? props.user.fullName : '',
			tokenService.getTokenOrDefault()!.portalOrganizationName || ''
		),
		onOkayButtonClick: onDelete,
		isDeleteButton: true,
		onCancelButtonClick: toggleDeleteModal
	};

	return (
		<>
			<div className="w-100">
				<PopoverModal
					showModal={showModal}
					title={localizationService.getLocalizedString(
						`haulerPortal.settings.users.inviteHaulerUser.${props.isEdit ? 'editUser' : 'inviteUser'}`
					)}
					saveButtonText={localizationService.getLocalizedString(
						`haulerPortal.buttons.${props.isEdit ? 'invite' : 'edit'}`
					)}
					cancel={onClose}
					save={onInvite}
					footer={modalFooterDiv}>
					<div>
						{!props.isEdit ? (
							formState.isExistingUser ? (
								<div className="d-flex notification mb-3 p-2">
									<div className="mr-2">
										<WarningIcon />
									</div>
									<div className="font-size-16px">
										<Translate>
											{localizationService.getLocalizedString(
												`${_.lowerCase(
													organizationTypeName
												)}Portal.settings.users.invite${organizationTypeName}User.emailExistsAlertPart1`,
												formState.email || ''
											)}
										</Translate>
										&nbsp;
										<span className="font-size-14px-bold">{formState.email}</span>
										<Translate>
											{localizationService.getLocalizedString(
												`${_.lowerCase(
													organizationTypeName
												)}Portal.settings.users.invite${organizationTypeName}User.emailExistsAlertPart2`,
												!urlService.isServicePortal()
													? props.serviceName || ''
													: tokenService.getTokenOrDefault().portalOrganizationName || ''
											)}
										</Translate>
									</div>
								</div>
							) : (
								<p id="invite-user">
									<Translate>
										{localizationService.getLocalizedString(
											`${_.toLower(
												organizationTypeName
											)}Portal.settings.users.invite${organizationTypeName}User.inviteUsersHint${
												organizationTypeName === Facility ? 'Facility' : ''
											}`,
											!urlService.isServicePortal()
												? props.serviceName || ''
												: tokenService.getTokenOrDefault().portalOrganizationName || ''
										)}
									</Translate>
								</p>
							)
						) : null}
						<TextInput
							id="email"
							name="email"
							label={localizationService.getLocalizedString(
								'haulerPortal.settings.users.inviteHaulerUser.email'
							)}
							value={formState.email}
							error={formState.emailError}
							onChange={onChange}
							onBlur={checkEmailExist}
							className="form-group input-single-line"
							isRequired={!isDisabled}
							isDisabled={formState.isExistingUser || isDisabled}
						/>
						<div className="form-row">
							<div className="col-md-6">
								<TextInput
									id="firstName"
									name="firstName"
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.users.inviteHaulerUser.firstName'
									)}
									value={formState.firstName}
									error={formState.firstNameError}
									onChange={onChange}
									className="form-group input-single-line"
									isRequired={!isDisabled}
									isDisabled={formState.isExistingUser || isDisabled}
								/>
							</div>
							<div className="col-md-6">
								<TextInput
									id="lastName"
									name="lastName"
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.users.inviteHaulerUser.lastName'
									)}
									value={formState.lastName}
									error={formState.lastNameError}
									onChange={onChange}
									isRequired={!isDisabled}
									className="form-group input-single-line"
									isDisabled={formState.isExistingUser || isDisabled}
								/>
							</div>
						</div>
						{props.isEdit && (
							<>
								<TextInput
									id="phone-number"
									name="phoneNumber"
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.users.inviteHaulerUser.phoneNumber'
									)}
									value={formState.phoneNumber}
									onChange={onChange}
								/>
								<TextInput
									id="mobile-phone-number"
									name="mobilePhoneNumber"
									label={localizationService.getLocalizedString(
										'haulerPortal.settings.users.inviteHaulerUser.mobilePhoneNumber'
									)}
									value={formState.mobilePhoneNumber}
									onChange={onChange}
								/>
							</>
						)}
						<label className="mb-1">
							<Translate>
								{localizationService.getLocalizedString(
									'haulerPortal.settings.users.inviteHaulerUser.role.label'
								)}
							</Translate>
						</label>
						<div className="custom-control custom-radio invite-radio">
							<input
								id="user"
								type="radio"
								name="isAdministrator"
								className="custom-control-input"
								value="false"
								disabled={!props.isEdit && !!props.user}
								checked={formState.isAdministrator === false}
								onChange={() => setAdminUser(false)}
							/>
							<label className="custom-control-label" htmlFor="user">
								<span>
									<Translate>
										{localizationService.getLocalizedString(
											`${_.toLower(
												organizationTypeName
											)}Portal.settings.users.invite${organizationTypeName}User.role.${_.toLower(
												organizationTypeName
											)}User`
										)}
									</Translate>{' '}
									-{' '}
									<Translate>
										{localizationService.getLocalizedString(
											`${_.toLower(
												organizationTypeName
											)}Portal.settings.users.invite${organizationTypeName}User.role.${_.toLower(
												organizationTypeName
											)}UserRole`
										)}
									</Translate>{' '}
								</span>
							</label>
						</div>
						<div className="custom-control custom-radio invite-radio">
							<input
								id="administrator"
								type="radio"
								name="isAdministrator"
								className="custom-control-input"
								disabled={!props.isEdit && !!props.user}
								value="true"
								checked={formState.isAdministrator === true}
								onChange={() => setAdminUser(true)}
							/>
							<label className="custom-control-label" htmlFor="administrator">
								<span>
									<Translate>{`${localizationService.getLocalizedString(
										`${_.toLower(
											organizationTypeName
										)}Portal.settings.users.invite${organizationTypeName}User.role.${_.toLower(
											organizationTypeName
										)}Administrator`
									)} -
									${localizationService.getLocalizedString(
										`${_.toLower(
											organizationTypeName
										)}Portal.settings.users.invite${organizationTypeName}User.role.${_.toLower(
											organizationTypeName
										)}AdministratorRole`
									)}`}</Translate>
								</span>
							</label>
						</div>
					</div>
				</PopoverModal>
				<DeleteModal {...deleteUserModalProps} />
			</div>
		</>
	);
};
export default InviteEditUser;
