import React from 'react';

import { ImportEntity, LookupType, RegulatoryProgramName } from '@rcp/types';

import 'src/components/authority/shared/settings/authority-settings.scss';
import { localizationService, urlService } from 'src/services';
import { LookupSetting } from '../../../../../components/widgets/lookup';
import ImportUniqueKeySettingComponent from '../import-unique-key/import-unique-key-setting';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';
import _ from 'lodash';

const AuthoritySettingsContactComponent: React.FunctionComponent = () => {
	return (
		<div className="page">
			<div className="page-header">
				{String.equalCaseInsensitive(urlService.getCurrentProgramOrDefault(), RegulatoryProgramName.FOG) && (
					<BackArrow />
				)}
				<h1>{localizationService.getLocalizedString('authoritySetting.contacts')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">
					<LookupSetting
						title={localizationService.getLocalizedString('authoritySetting.contactTypesSection')}
						description={localizationService.getLocalizedString(
							'authoritySetting.contactTypesSectionDescription'
						)}
						settingId="LookupType.ContactType"
						settingLabel={localizationService.getLocalizedString('authoritySetting.contactType')}
						lookupType={LookupType.ContactType}
					/>
					{urlService.isAdministrator() && urlService.isProgram(RegulatoryProgramName.Backflow) && (
						<>
							<hr />
							<ImportUniqueKeySettingComponent
								importEntity={ImportEntity.CccContact}
								fieldsPath={`Settings/AuthoritySettings/ImportEntityFields/${ImportEntity.CccContact}`}
								params={'isForeignKey=neq:true'}
								description={localizationService.getLocalizedString(
									'authoritySetting.importUniqueKey.upToOneKeyDescription'
								)}
							/>
						</>
					)}
					{urlService.isAdministrator() && urlService.isProgram(RegulatoryProgramName.FOG) && (
						<>
							<hr />
							<ImportUniqueKeySettingComponent
								importEntity={ImportEntity.FogContact}
								fieldsPath={`Settings/AuthoritySettings/ImportEntityFields/${ImportEntity.FogContact}`}
								params={'isForeignKey=neq:true'}
								description={localizationService.getLocalizedString(
									'authoritySetting.importUniqueKey.upToOneKeyDescription'
								)}
								maxNumberOfKey={1}
								disableRemoval={true}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export const AuthoritySettingsContact = AuthoritySettingsContactComponent;
