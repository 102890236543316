import React, { useState } from 'react';
import { Action } from 'redux';
import { connect, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import _ from 'lodash';

import {
	ApplicationState,
	FogFacilityState,
	saveFogFacility,
	JurisdictionListState,
	alertService,
	loadJurisdiction,
	addFogFacility,
	loadFogFacilities,
	useReduxDispatch,
	RootState
} from 'src/redux';
import { FogFacility, DropDownOption, AutoNumberDomainType } from '@rcp/types';
import { PopoverModal, TextInput, SingleSelectDropdown, DateInput } from 'src/components/widgets';
import {
	urlService,
	validationService,
	localizationService,
	Resource,
	authoritySettingService,
	optionsMap,
	navigateTo
} from 'src/services';
import { useHistory } from 'react-router-dom';
import { nameof } from 'ts-simple-nameof';

interface OwnProps {
	showModal: boolean;
	setShowModal: () => void;
	fogFacility?: FogFacility;
	isAddModal: boolean;
	querystring?: string;
}

interface StateProps extends FogFacilityState, JurisdictionListState {}

interface DispatchProps {
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) => void;
	addFogFacility: (facilityToCreate: FogFacility, callbackOnSuccess: (facility: FogFacility) => void) => void;
	loadFogFacilitiesGrid: (querystring: string) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface FacilityForm {
	facilityName: string;
	referenceNumber: string;

	addressLine1: string;
	addressLine2: string;
	addressCity: string;
	addressJurisdictionId?: number;
	addressJurisdictionCode: string;
	addressZipCode: string;
	inBusinessSince?: string;
	contactName?: string;
	email?: string;
	phone?: string;

	facilityNameError?: string;
	referenceNumberError?: string;

	overriddenComplianceStatus?: string;
	isOverrideComplianceStatus: boolean;
	isEnabled?: boolean;
	inBusinessSinceError?: string;
	contactNameError?: string;
	contactPersonLastNameError?: string;
	emailError?: string;
	emailFormatError?: string;
	phoneError?: string;
}

const initialFacilityAddressForm: FacilityForm = {
	facilityName: '',
	referenceNumber: '',

	addressLine1: '',
	addressLine2: '',
	addressCity: '',
	addressJurisdictionCode: '',
	addressZipCode: '',
	overriddenComplianceStatus: '',
	isOverrideComplianceStatus: false,
	isEnabled: true
};

const FacilityDetailsAddressModalComp: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [formState, setFormState] = useState(initialFacilityAddressForm);
	const [stateProvinceOptionValues, setStateProvinceOptionValues] = React.useState([] as DropDownOption[]);
	const [userComplianceStatus, setUserComplianceStatus] = useState([] as DropDownOption[]);

	const history = useHistory();
	const errorMessages = useSelector((state: RootState) => state.alerts);

	React.useEffect(() => {
		if (
			errorMessages.messages.length > 0 &&
			errorMessages.messages.find(i => i.message.includes(formState.referenceNumber as string))
		)
			setFormState({
				...formState,
				referenceNumberError: localizationService.getLocalizedString(
					'facility.facilityReferenceError',
					formState.referenceNumber
				)
			});
	}, [errorMessages]);

	const setFormStateFromProps = (facility: FogFacility) => {
		let newState: FacilityForm = {
			facilityName: facility.facilityName || '',
			referenceNumber: facility.referenceNumber || '',
			addressLine1: facility.addressLine1 || '',
			addressLine2: facility.addressLine2 || '',
			addressCity: facility.cityName || '',
			addressJurisdictionId: facility.jurisdictionId,
			addressJurisdictionCode: facility.jurisdictionName || '',
			addressZipCode: facility.zipCode || '',
			overriddenComplianceStatus: facility.overriddenComplianceStatus || '',
			isOverrideComplianceStatus: facility.isOverrideComplianceStatus || false,
			contactName: facility.contactName || '',
			email: facility.email || '',
			phone: facility.phone || ''
		};
		setFormState(newState);
	};

	React.useEffect(() => {
		dispatch(loadJurisdiction());

		if (props.showModal && props.isAddModal) {
			authoritySettingService
				.getNextAutoNumber(AutoNumberDomainType.Facility)
				.then((nextFacilityAutoNumber: any) => {
					let newState = { ...initialFacilityAddressForm };
					newState.referenceNumber = nextFacilityAutoNumber as string;
					setFormState(newState);
				});
		}

		if (props.showModal && !props.isAddModal) {
			authoritySettingService.getAuthorityComplianceStatus().then(complianceStatusValues => {
				let userComplianceStatusOptions = complianceStatusValues.map(
					i => ({ label: i, value: i, isHidden: false } as DropDownOption)
				);
				setUserComplianceStatus(userComplianceStatusOptions);
			});
		}
	}, [dispatch, props.showModal, props.isAddModal]);

	React.useEffect(() => {
		if (props.fogFacility) {
			setFormStateFromProps(props.fogFacility);
		}
	}, [props.fogFacility]);

	React.useEffect(() => {
		let optionValues: DropDownOption[] = [];

		if (props.jurisdictionList.length > 0) {
			optionValues = optionsMap.fromJurisdiction(props.jurisdictionList);
		}

		setStateProvinceOptionValues(optionValues);
	}, [props.jurisdictionList]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			nameof<FacilityForm>(s => s.facilityName),
			nameof<FacilityForm>(s => s.facilityNameError)
		);
		validationService.validateRequiredField(
			newState,
			nameof<FacilityForm>(s => s.referenceNumber),
			nameof<FacilityForm>(s => s.referenceNumberError)
		);
		validationService.validateMinimumDate(
			newState,
			nameof<FacilityForm>(s => s.inBusinessSince),
			nameof<FacilityForm>(s => s.inBusinessSinceError),
			localizationService.getLocalizedString('facility.inBusinessSince')
		);

		validationService.validateFieldLength(
			newState,
			nameof<FacilityForm>(s => s.phone),
			nameof<FacilityForm>(s => s.phoneError),
			25,
			localizationService.getLocalizedString('facility.phone')
		);
		validationService.validateEmailFormatField(
			newState,
			nameof<FacilityForm>(s => s.email),
			nameof<FacilityForm>(s => s.emailFormatError)
		);
		validationService.validateFieldLength(
			newState,
			nameof<FacilityForm>(s => s.email),
			nameof<FacilityForm>(s => s.emailError),
			200,
			localizationService.getLocalizedString('facility.email')
		);
		validationService.validateFieldLength(
			newState,
			nameof<FacilityForm>(s => s.contactName),
			nameof<FacilityForm>(s => s.contactNameError),
			200,
			localizationService.getLocalizedString('facility.contactName')
		);
		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			nameof<FacilityForm>(s => s.facilityNameError),
			nameof<FacilityForm>(s => s.referenceNumberError),
			nameof<FacilityForm>(s => s.inBusinessSinceError),
			nameof<FacilityForm>(s => s.phoneError),
			nameof<FacilityForm>(s => s.emailError),
			nameof<FacilityForm>(s => s.emailFormatError),
			nameof<FacilityForm>(s => s.contactNameError)
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const resetFacilityAddressForm = () => {
		if (props.isAddModal) {
			setFormState(initialFacilityAddressForm);
		} else {
			if (props.facility) {
				setFormStateFromProps(props.facility);
			}
		}
	};

	const saveAccount = async () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let facilityToAddOrUpdate: FogFacility = {
			facilityName: formState.facilityName,
			referenceNumber: formState.referenceNumber,
			addressLine1: formState.addressLine1,
			addressLine2: formState.addressLine2,
			jurisdictionId: formState.addressJurisdictionId,
			cityName: formState.addressCity,
			zipCode: formState.addressZipCode,
			overriddenComplianceStatus: formState.overriddenComplianceStatus,
			isOverrideComplianceStatus: formState.isOverrideComplianceStatus,
			isEnabled: formState.isEnabled,
			contactName: formState.contactName,
			email: formState.email,
			phone: formState.phone
		};

		if (facilityToAddOrUpdate.isOverrideComplianceStatus === false) {
			delete facilityToAddOrUpdate.OverriddenComplianceStatus;
		}

		let addUpdateResult: any = false;

		if (props.isAddModal) {
			facilityToAddOrUpdate.inBusinessSince = formState.inBusinessSince;
			addUpdateResult = await props.addFogFacility(facilityToAddOrUpdate, (facility: FogFacility) => {
				if (facility.facilityId) {
					let facilityUrl = urlService.getReactAuthorityResourceUrl(
						Resource.FogFacilities,
						facility.facilityId
					);
					navigateTo(history, facilityUrl);
					window.setTimeout(function() {
						alertService.addSuccess(
							localizationService.getLocalizedString(
								'alertMessages.addSuccess',
								localizationService.getLocalizedString('facility.facility')
							)
						);
					}, 500);
				}
			});
		} else {
			if (props.facility) {
				addUpdateResult = await props.saveFogFacility(urlService.getFogFacilityId(), facilityToAddOrUpdate);
			}
		}

		if (addUpdateResult === true) {
			if (props.isAddModal) {
				setFormState(initialFacilityAddressForm);
			}
			props.setShowModal();
		}
	};

	const cancelSaveAccount = () => {
		resetFacilityAddressForm();
		props.setShowModal();
	};

	const isOverrideComplianceStatusChanged = (overrideComplianceStatus: boolean) => {
		let newState = { ...formState };
		newState.isOverrideComplianceStatus = overrideComplianceStatus;

		if (overrideComplianceStatus === true) {
			if (userComplianceStatus.length > 0) {
				newState.overriddenComplianceStatus = userComplianceStatus[0].value;
			}
		}
		setFormState(newState);
	};

	const onUserComplianceStatusChanged = (e: any) => {
		var newComplianceStatus = e.target.value;
		let newState = { ...formState };
		newState.overriddenComplianceStatus = newComplianceStatus;
		setFormState(newState);
	};

	function getDefaultUserComplianceStatus() {
		if (formState.overriddenComplianceStatus) {
			return formState.overriddenComplianceStatus;
		}

		if (userComplianceStatus.length > 0) {
			return userComplianceStatus[0].value;
		}

		return '';
	}

	let complianceStatusBlock = (
		<div id="complianceStatusBlock">
			<label>{localizationService.getLocalizedString('screen.labels.complianceStatus')}</label>
			<div className="custom-control custom-radio">
				<input
					type="radio"
					className="custom-control-input"
					name="isOverrideComplianceStatus"
					id="isOverrideComplianceStatusFalse"
					value="false"
					checked={formState.isOverrideComplianceStatus === false}
					onChange={() => isOverrideComplianceStatusChanged(false)}
				/>
				<label className="custom-control-label" htmlFor="isOverrideComplianceStatusFalse">
					{localizationService.getLocalizedString('screen.labels.useAutomaticComplianceStatus')}
				</label>
			</div>

			<div className="custom-control custom-radio">
				<input
					type="radio"
					className="custom-control-input"
					name="isOverrideComplianceStatus"
					id="isOverrideComplianceStatusTrue"
					value="true"
					checked={formState.isOverrideComplianceStatus === true}
					onChange={() => isOverrideComplianceStatusChanged(true)}
				/>
				<label className="custom-control-label" htmlFor="isOverrideComplianceStatusTrue">
					{localizationService.getLocalizedString('screen.labels.overrideComplianceStatus')}
				</label>
			</div>

			{formState.isOverrideComplianceStatus && (
				<SingleSelectDropdown
					className="form-group pt-2"
					id="userComplianceStatusList"
					name="userComplianceStatusListId"
					noEmptyOption={true}
					value={getDefaultUserComplianceStatus()}
					onChange={onUserComplianceStatusChanged}
					options={userComplianceStatus}
				/>
			)}
		</div>
	);

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={
					props.isAddModal
						? localizationService.getLocalizedString('facility.addFacility')
						: localizationService.getLocalizedString('facility.editFacility')
				}
				save={saveAccount}
				cancel={cancelSaveAccount}>
				<TextInput
					id="facilityName"
					name="facilityName"
					label={localizationService.getLocalizedString('facility.facilityName')}
					value={formState.facilityName}
					onChange={changeFormState}
					isRequired={true}
					error={formState.facilityNameError}
				/>
				<TextInput
					id="referenceNumber"
					name="referenceNumber"
					label={localizationService.getLocalizedString('facility.referenceNumber')}
					value={formState.referenceNumber}
					onChange={changeFormState}
					isRequired={true}
					error={formState.referenceNumberError}
				/>
				<TextInput
					id="addressLine1"
					name="addressLine1"
					label={localizationService.getLocalizedString('facility.addressLine1')}
					helpText={localizationService.getLocalizedString('screen.helpText.address1')}
					value={formState.addressLine1}
					onChange={changeFormState}
				/>
				<TextInput
					id="addressLine2"
					name="addressLine2"
					label={localizationService.getLocalizedString('facility.addressLine2')}
					helpText={localizationService.getLocalizedString('screen.helpText.address2')}
					value={formState.addressLine2}
					onChange={changeFormState}
				/>
				<div className="form-row">
					<TextInput
						id="addressCity"
						name="addressCity"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('facility.cityName')}
						value={formState.addressCity}
						onChange={changeFormState}
					/>
					<SingleSelectDropdown
						id="addressJurisdictionId"
						name="addressJurisdictionId"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('facility.state')}
						value={_.toString(formState.addressJurisdictionId)}
						onChange={changeFormState}
						options={stateProvinceOptionValues}
					/>
					<TextInput
						id="addressZipCode"
						name="addressZipCode"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('facility.zipCode')}
						value={formState.addressZipCode}
						onChange={changeFormState}
					/>
				</div>

				<TextInput
					id={nameof<FacilityForm>(s => s.contactName)}
					name={nameof<FacilityForm>(s => s.contactName)}
					label={localizationService.getLocalizedString('facility.contactName')}
					value={formState.contactName}
					onChange={changeFormState}
					error={formState.contactNameError}
				/>
				<TextInput
					id={nameof<FacilityForm>(s => s.phone)}
					name={nameof<FacilityForm>(s => s.phone)}
					label={localizationService.getLocalizedString('facility.phone')}
					value={formState.phone}
					onChange={changeFormState}
					error={formState.phoneError}
				/>
				<TextInput
					id={nameof<FacilityForm>(s => s.email)}
					name={nameof<FacilityForm>(s => s.email)}
					label={localizationService.getLocalizedString('facility.email')}
					value={formState.email}
					onChange={changeFormState}
					error={formState.emailError || formState.emailFormatError}
				/>

				{props.isAddModal ? (
					<div className="form-row">
						<DateInput
							id="inBusinessSince"
							name="inBusinessSince"
							className="form-group col"
							label={localizationService.getLocalizedString('facility.inBusinessSince')}
							error={formState.inBusinessSinceError}
							value={formState.inBusinessSince}
							onChange={changeFormState}
						/>
					</div>
				) : (
					complianceStatusBlock
				)}
			</PopoverModal>
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, ...state.jurisdictions };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		addFogFacility: (facilityToCreate: FogFacility, callbackOnSuccess: (facility: FogFacility) => void) =>
			dispatch(addFogFacility(facilityToCreate, callbackOnSuccess)),
		loadFogFacilitiesGrid: (querystring: string) => dispatch(loadFogFacilities(querystring)),
		saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility) =>
			dispatch(saveFogFacility(facilityId, facilityToUpdate))
	};
};

export const FacilityDetailsAddressModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityDetailsAddressModalComp);
