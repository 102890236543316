import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import $ from 'jquery';
import {
	DateInput,
	DeleteModal,
	PopoverModal,
	SingleCheckbox,
	SingleSelectDropdown,
	TextAreaInput,
	TextInput
} from 'src/components/widgets';
import _ from 'lodash';
import {
	apiService,
	DateUtilService,
	documentService,
	localizationService,
	Logger,
	navigateTo,
	Resource,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import {
	alertService,
	loadCurrentFogFacility,
	loadFacilityAttachments,
	loadFacilityCleaningManifest,
	useReduxSelector,
	deleteFacilityAttachment,
	useRootStateSelector,
	loadUnitListHauler
} from 'src/redux';
import {
	AttachmentOwnership,
	AttachmentType,
	CustomCleaningFields,
	CustomFieldType,
	DropDownOption,
	Extractor,
	FeatureNames,
	HaulerCleaningManifest,
	HaulerPortalFacilities,
	WasteType,
	FeatureSetting as FeatureFlagSetting,
	CustomFormAttachment
} from '@rcp/types';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import { openSignaturePad } from 'src/components/layout/signature-modal';
import { useHistory } from 'react-router';
import {
	getTimelineSettingDateRange,
	reloadTimelineEventsFromServer
} from '../../../../../features/timeline/timeline-service';
import './submit-cleaning.scss';
import { useDispatch } from 'react-redux';
import { haulerPortalSelectedFacilitiesSlice } from '../hauler-selected-facility-slice';
import { AttachmentThumbnail } from 'src/components/widgets';
import 'src/components/widgets/attachment/attachment.scss';
import { cleaningSlice } from '../../../..';
import { SeattleAuthorityConstants } from 'src/constants';
import { ConverterFactory } from 'src/services/converter-factory';
import { CustomFormService } from 'src/services/custom-form-service';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { Translate } from 'src/components/widgets/translate/translator';
import { OriginalSubmissionModal } from 'src/components/service-provider/facility/submit-cleaning.tsx/original-submission-modal';
import { translateService } from 'src/services/translate-service';

interface Props {
	title: string;
	selectedDevices: Array<string>;
	showModal: boolean;
	onCancel: () => void;
	onSubmit?: () => void;
	readOnly?: boolean;
	deviceList: Array<any>;
	saveButtonClassName?: string;
	saveButtonText?: string;
	hideCancel?: boolean;
	wasteTypeOptions?: DropDownOption[];
	facilityId?: number;
	closeSelectDevicePopup?: () => void;
	facility?: HaulerPortalFacilities;
	cleaningEventId?: number;
	hideFooter?: boolean;
	isFacilityDetailsPage?: boolean;
	submittedDate?: string;
	isTriggeredFromGrid?: boolean;
	isSeattleFacility?: boolean;
	hideDelete?: boolean;
	authorityOrganizationId?: number;
	isEditMode?: boolean;
	customFormId?: number;
}

interface FormErrorFields {
	completeDateError?: string;
	wasteTypeError?: string;
	amountPumpedError?: string;
	signatureError?: string;
	trapDepthError?: string;
	fogError?: string;
	solidError?: string;
	isRepairNeededReasonError?: string;
	leavingOutletError?: string;
	certificationAcceptedError?: string;
}

interface FormFields extends FormErrorFields, HaulerCleaningManifest {
	attachments?: CustomFormAttachment[];
}

const initialFormFields: FormFields = {
	completeDate: DateUtilService.getAuthorityTimezoneNow(),
	manifestNumber: '',
	driverName: '',
	vehicleNumber: '',
	wasteTypeId: '',
	amountPumped: '',
	comments: '',
	signature: '',
	fog: undefined,
	solid: undefined,
	isRepairNeeded: false,
	isRepairNeededReason: '',
	percentGrease: undefined
};

const { Attachments } = CustomCleaningFields;
const HaulerSubmitCleaningModal: React.FC<Props> = props => {
	const scrollToTopRef = useRef<HTMLDivElement>(null);
	const [authorityOrganizationId, setAuthorityOrganizationId] = React.useState<number>(0);
	const [selectedDeviceIndex, setSelectedDevicesIndex] = React.useState(0);
	const [selectedDeviceDetail, setSelectedDeviceDetail] = React.useState<Extractor>({});
	const [files, setFiles] = React.useState<CustomFormAttachment[]>([]);
	const [showDeleteCleaningModal, setShowDeleteCleaningModal] = React.useState(false);
	const [formState, setFormState] = React.useState<FormFields>({ ...initialFormFields });
	const [authorityWasteTypes, setAuthorityWasteTypes] = React.useState([] as WasteType[]);
	const [wasteTypeDropDownOptions, setWasteTypeDropDownOptions] = React.useState([] as DropDownOption[]);
	const [haulerCleaningFormElements, setHaulerCleaningFormElements] = useState<CustomFieldType[]>([]);
	const history = useHistory();
	const dispatch = useDispatch();
	const [formType, setFormType] = useState('');
	const [templateJson, setTemplateJson] = useState('');
	const [showCustomCleaningFormsFeature, setShowCustomCleaningFormsFeature] = useState(false);
	const [removedAttachments, setRemovedAttachments] = useState<CustomFormAttachment[]>([]);
	const [showViewOriginalSubmission, setShowViewOriginalSubmission] = React.useState(false);

	const language = React.useContext(LanguageContext);

	useEffect(() => {
		let authorityId = 0;

		if (props.authorityOrganizationId && props.authorityOrganizationId != 0) {
			authorityId = props.authorityOrganizationId;
		} else if (props.facility && props.facility.authorityId && props.facility.authorityId != 0) {
			authorityId = props.facility.authorityId;
		}

		setAuthorityOrganizationId(authorityId);
	}, [props.authorityOrganizationId, props.facility]);

	const loadFeatureFlagForAuthority = (authorityId: number) => {
		let url = urlService.isAuthorityPortal()
			? urlService.getAuthorityResourcesApiUrl(`${Resource.Settings}`)
			: urlService.getAuthorityResourcesApiUrl(`${Resource.Authorities}/${authorityId}/${Resource.Settings}`);
		apiService.getResource<FeatureFlagSetting[]>(`${url}/Features`).then(data => {
			let authorityFeatureFlags = Object.assign(
				{},
				...data.map((x: FeatureFlagSetting) => {
					const name = x.name;
					return { [name]: x.isEnabled ? x.isEnabled : false };
				})
			);
			if (urlService.isServicePortal() && authorityFeatureFlags) {
				setShowCustomCleaningFormsFeature(authorityFeatureFlags[FeatureNames.ShowCustomCleaningFormsFeature]);
			} else if (urlService.isAuthorityPortal()) {
				setShowCustomCleaningFormsFeature(showCustomCleaningFormsFeatureAuthority);
			}
		});
	};

	const showCustomCleaningFormsFeatureAuthority = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowCustomCleaningFormsFeature]
	);
	const unitList = useRootStateSelector(s => s.extractors.unitList);

	useEffect(() => {
		if (props.showModal && (props.readOnly || props.isEditMode)) {
			fetchCleaningManifest();
			if (urlService.isHaulerPortal()) {
				const attachmentUrl = urlService.getAuthorityResourcesApiUrl(
					`${Resource.HaulerCleaningEvents}/${props.cleaningEventId}/${Resource.Attachments}`
				);
				CustomFormService.fetchAttachments(attachmentUrl, setFiles);
			} else if (urlService.isFacilityPortal()) {
				const attachmentUrl = urlService.getAuthorityResourcesApiUrl(
					`${Resource.FacilityCleaningEvents}/${props.cleaningEventId}/${Resource.Attachments}`
				);
				CustomFormService.fetchAttachments(attachmentUrl, setFiles);
			}
		}
	}, [props.cleaningEventId, props.showModal, showCustomCleaningFormsFeature]);

	useEffect(() => {
		if (authorityOrganizationId && authorityOrganizationId != 0) {
			loadFeatureFlagForAuthority(authorityOrganizationId);
			dispatch(loadUnitListHauler(authorityOrganizationId));

			let wasteTypesUrl = '';
			if (urlService.isHaulerPortal()) {
				wasteTypesUrl = urlService.getAuthorityLookupUrlForService(
					authorityOrganizationId,
					Resource.WasteTypes,
					'includeInactive=true'
				);
			} else {
				wasteTypesUrl = urlService.getAuthorityLookupResourceApiUrl(
					Resource.WasteTypes,
					'includeInactive=true'
				);
			}

			apiService
				.getResource<WasteType[]>(wasteTypesUrl)
				.then(wasteTypes => {
					setAuthorityWasteTypes(wasteTypes);
				})
				.catch(ex => alertService.addError(ex.message));
		}
	}, [dispatch, authorityOrganizationId]);

	useEffect(() => {
		CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState);
	}, [templateJson, unitList]);

	useEffect(() => {
		if (selectedDeviceDetail.extractorId && showCustomCleaningFormsFeature) {
			CustomFormService.loadCustomForm(
				selectedDeviceDetail.extractorId,
				setTemplateJson,
				setFormType,
				setHaulerCleaningFormElements
			);
		}
	}, [selectedDeviceDetail.extractorId, showCustomCleaningFormsFeature]);

	useEffect(() => {
		if (authorityWasteTypes) {
			if (authorityWasteTypes.length < 1) return;

			let dropDownOptions: DropDownOption[] = [];
			authorityWasteTypes.map((wasteType: WasteType) => {
				dropDownOptions.push({
					label: wasteType.wasteTypeCode || '',
					value: wasteType.wasteTypeId,
					isHidden: !wasteType.isActive
				});
			});
			setWasteTypeDropDownOptions(dropDownOptions);
		}
	}, [authorityWasteTypes]);

	useEffect(() => {
		CustomFormService.updateForm(
			setTemplateJson,
			setFormType,
			formState,
			files,
			formState.attachments,
			props.isEditMode,
			setHaulerCleaningFormElements,
			formState.templateJson
		);
	}, [formState.templateJson]);

	useEffect(() => {
		_.delay(() => {
			if (props.readOnly && formState.cleaningManifestHtmlContent) {
				let parentDivEl = templateJson
					? document.getElementById('cleaningSubmissionForm')
					: document.getElementById('cleaningManifestHtmlContentDiv');
				if (!parentDivEl) {
					Logger.warn('can not find cleaningManifestHtmlContentDiv');
					return;
				}
				let formHtml = templateJson ? parentDivEl.innerHTML : formState.cleaningManifestHtmlContent;

				const sanitizedHtml = documentService.sanitizeHtmlFormForDisplayOnly(formHtml, 'attachmentsInput');
				if (parentDivEl) {
					parentDivEl.innerHTML = sanitizedHtml;
				}

				_.delay(() => {
					//hook attachments
					let attachmentsElem = document.getElementById('viewModeAttachments');
					let snapshotFormAttachmentElem = document.getElementById('attachmentsInput');
					if (snapshotFormAttachmentElem && snapshotFormAttachmentElem.parentElement && attachmentsElem) {
						let className = _.replace(
							_.replace(attachmentsElem.className, 'invisible', ''),
							'attachment-hide',
							'attachment-show'
						);
						attachmentsElem.setAttribute('class', className);
						snapshotFormAttachmentElem.parentElement.replaceChild(
							attachmentsElem,
							snapshotFormAttachmentElem
						);
					}
				}, 200);
			}
		}, 200);
	}, [props.readOnly, formState.cleaningManifestHtmlContent]);

	const handleValidateCustomField = () => {
		let newHaulerCleaningFormElement = _.cloneDeep(haulerCleaningFormElements);
		_.each(newHaulerCleaningFormElement, haulerFormElement => {
			validationService.validateCustomFormField(haulerFormElement.inputs || []);
		});
		let hasError = false;
		_.each(newHaulerCleaningFormElement, haulerFormElement => {
			_.each(haulerFormElement.inputs, inputs => {
				if (validationService.hasError(inputs, 'error')) {
					hasError = true;
				}
			});
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		setHaulerCleaningFormElements(newHaulerCleaningFormElement);
		return !hasError;
	};

	const handleChangeInputValue = (e: any, dataItem: CustomFieldType, fieldValue?: any) => {
		CustomFormService.handleChangeInputValue(
			e,
			dataItem,
			haulerCleaningFormElements,
			setHaulerCleaningFormElements,
			removedAttachments,
			setRemovedAttachments,
			fieldValue
		);
	};

	const fetchCleaningManifest = () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${
				urlService.isHaulerPortal() || urlService.isFacilityPortal()
					? showCustomCleaningFormsFeature
						? Resource.CustomCleaningManifests
						: Resource.HaulerCleaningEvents
					: Resource.FogFacilities + '/' + props.facilityId + `/${Resource.Cleanings}`
			}/${showCustomCleaningFormsFeature && props.customFormId ? props.customFormId : props.cleaningEventId}`
		);
		apiService
			.getResource(url)
			.then(data => {
				let cleaningManifest = { ...(data as any) };
				let keyValuePair: any = {};
				if (cleaningManifest.cleaningManifestFormKeyValuePair) {
					keyValuePair = JSON.parse(cleaningManifest.cleaningManifestFormKeyValuePair);
				}
				keyValuePair.isRepairNeeded === 'on'
					? (keyValuePair.isRepairNeeded = true)
					: (keyValuePair.isRepairNeeded = false);
				delete keyValuePair.completeDate;
				delete keyValuePair.dueDate;
				setFormState({ ...cleaningManifest, ...keyValuePair });
				if (!urlService.isHaulerPortal()) setFiles([...cleaningManifest.attachments]);
			})
			.catch(function(err) {
				err.status && alertService.addError(err.message);
			});
	};

	const onCancel = () => {
		setSelectedDevicesIndex(0);
		resetState();
		alertService.clearAllMessages();
		props.onCancel();
	};

	const resetState = () => {
		setFiles([]);
		setFormState({ ...initialFormFields });
	};

	const onChange = (e: any, fieldValue?: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}
		if (fieldValue) {
			value = fieldValue;
		}
		_.set(newState, name, value || '');
		setFormState(newState);

		const percentGreaseFields = ['fog', 'solid', 'trapDepth'];
		if (percentGreaseFields.includes(name)) {
			onPercentGreaseOperandChange(newState);
		}
	};

	const setSignature = (imageUrl: string) => {
		let newState = { ...formState };
		_.set(newState, 'signature', imageUrl || '');
		setFormState(newState);
	};

	const setTrapDepthAndCapacity = (deviceDetail: Extractor) => {
		let newState = { ...formState };
		_.set(newState, 'amountPumped', deviceDetail.trapCapacity || '');
		_.set(newState, 'trapDepth', deviceDetail.trapDepth || '');
		setFormState(newState);
	};

	const onPercentGreaseOperandChange = (newState: FormFields) => {
		let trapDepth = newState.trapDepth;
		let fog = newState.fog;
		let solid = newState.solid;

		if (trapDepth && fog && solid && trapDepth > 0) {
			let waste = +fog + +solid;
			let percentGrease = Math.round((+waste / +trapDepth) * 100);
			_.set(newState, 'percentGrease', percentGrease);
		} else {
			_.unset(newState, 'percentGrease');
		}
	};

	useEffect(() => {
		const deviceDetail =
			props.deviceList.find((deviceDetail: Extractor) =>
				String.equalCaseInsensitive(deviceDetail.deviceNumber, props.selectedDevices[selectedDeviceIndex])
			) || {};
		setSelectedDeviceDetail(deviceDetail);
		if (urlService.isHaulerPortal()) {
			if (props.readOnly) {
				setFormState({ ...deviceDetail });
				deviceDetail.files && deviceDetail.files.length && setFiles(deviceDetail.files);
			} else {
				if (props.isSeattleFacility) {
					setTrapDepthAndCapacity(deviceDetail);
				}
			}
		}
	}, [selectedDeviceIndex, props]);

	const addValuesToTheHtmlContent = () => {
		$('input').each(function() {
			if ($(this).is(':checkbox')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else if ($(this).is(':radio')) {
				$(this).attr('checked', $(this).prop('checked'));
			} else {
				$(this).attr('value', ($(this) as any).val());
			}
		});

		$('textarea').each(function() {
			$(this).html(($(this) as any).val());
		});

		$('select').each(function() {
			$(this)
				.find(':not(:selected)')
				.removeAttr('selected');
		});

		$('select').each(function() {
			$(this)
				.find(':selected')
				.attr('selected', 'selected');
		});
	};

	const getAllFormValues = () => {
		addValuesToTheHtmlContent();

		let paramObj: any = {};
		let formId = `#cleaningSubmissionForm`;
		$.each($(formId).serializeArray(), function(_, kv) {
			paramObj[kv.name] = kv.value;
		});

		return UtilService.toJson(paramObj);
	};

	const uploadFile = async (file: CustomFormAttachment, ownerId: number, attachmentSectionKey: string) => {
		const headers = {
			//'Content-Type': 'multipart/form-data; boundary=X-LINKO-ONLINE-BOUNDARY'
		};
		if (!file) return;

		let formData = new FormData();
		if (file.fileName) {
			return;
		}
		formData.append('files', file);
		const queryParameter = `facilityId=${props.facilityId}&ownerId=${ownerId}&attachmentType=${AttachmentType.FogDeviceCleaning}&attachmentSectionKey=${attachmentSectionKey}`;
		let url =
			props.isEditMode && showCustomCleaningFormsFeature
				? urlService.getAuthorityResourcesApiUrl(
						`${Resource.FogFacilities}/${props.facilityId}/${Resource.HaulerDeviceCleaningAttachment}?${queryParameter}`
				  )
				: urlService.getAuthorityResourcesApiUrl(
						`${Resource.HaulerDeviceCleaningAttachment}?${queryParameter}`
				  );
		await apiService.postFormData(url, formData, headers);
	};

	const createCustomPayloadFromTemplateJson = () => {
		let payload: any = {};
		_.each(haulerCleaningFormElements, haulerFormElement => {
			const { inputs } = haulerFormElement;
			if (haulerFormElement.name === Attachments) {
				return;
			}
			_.each(inputs, input => {
				if (input && input.fieldId) {
					payload[input.fieldId] = input.value;
				}
			});
		});
		return payload;
	};

	const onSubmit = async () => {
		if (
			!showCustomCleaningFormsFeature && !templateJson ? isFormValidateForSubmit() : handleValidateCustomField()
		) {
			_.delay(() => {
				let allFromValues = getAllFormValues();
				let formId = `cleaningSubmissionForm`;
				let node = document.getElementById(formId);
				let htmlContent = node ? node.innerHTML : '';

				let payload: any = {
					...formState,
					deviceId: selectedDeviceDetail.extractorId,
					organizationId: selectedDeviceDetail.organizationId,
					cleaningManifestHtmlContent: htmlContent,
					cleaningManifestFormKeyValuePair: allFromValues
				};

				if (showCustomCleaningFormsFeature && templateJson) {
					payload = {
						...createCustomPayloadFromTemplateJson(),
						deviceId: selectedDeviceDetail.extractorId,
						organizationId: selectedDeviceDetail.organizationId,
						OriginalSubmittedFormHtmlContent: htmlContent,
						OriginalSubmittedKeyValuePair: allFromValues,
						cleaningManifestHtmlContent: htmlContent,
						cleaningManifestFormKeyValuePair: allFromValues,
						SubmittedLanguageCode: language.language.code,
						templateJson: templateJson,
						percentGrease:
							(document.getElementById('percentGrease') &&
								document.getElementById('percentGrease')!.innerText.replace('%', '')) ||
							'0',
						...CustomFormService.initFormUnits(templateJson, unitList, formState, setFormState)
					};
				} else {
					if (props.isSeattleFacility) {
						payload = _.pick(payload, [
							'deviceId',
							'organizationId',
							'completeDate',
							'manifestNumber',
							'driverName',
							'amountPumped',
							'amountPumpedUnitId',
							'percentGrease',
							'fog',
							'fogUnitId',
							'solid',
							'solidUnitId',
							'trapDepth',
							'trapDepthUnitId',
							'isRepairNeeded',
							'isRepairNeededReason',
							'leavingOutlet',
							'comments',
							'certificationAccepted',
							'signature',
							'cleaningManifestHtmlContent',
							'cleaningManifestFormKeyValuePair'
						]);
					} else {
						payload = _.pick(payload, [
							'deviceId',
							'organizationId',
							'completeDate',
							'manifestNumber',
							'driverName',
							'vehicleNumber',
							'wasteTypeId',
							'amountPumped',
							'amountPumpedUnitId',
							'comments',
							'certificationAccepted',
							'signature',
							'cleaningManifestHtmlContent',
							'cleaningManifestFormKeyValuePair'
						]);
					}
				}

				let url = urlService.getAuthorityResourcesApiUrl(Resource.HaulerCleaningEvents);
				if (props.isEditMode) {
					url = urlService.getAuthorityResourcesApiUrl(
						`${Resource.FogFacilities}/${props.facilityId}/${Resource.Cleanings}/${formState.pumpOutEventId}`
					);
					apiService.patchResource(url, payload).then((data: any) => {
						if (files.length && !showCustomCleaningFormsFeature) {
							const queryParameters = `facilityId=${props.facilityId}&ownerId=${
								(data as any).pumpOutEventId
							}&attachmentType=${AttachmentType.FogDeviceCleaning}`;
							let url = urlService.getAuthorityResourcesApiUrl(
								`${Resource.HaulerDeviceCleaningAttachment}?${queryParameters}`
							);
							CustomFormService.uploadFiles(url, files);
						} else if (showCustomCleaningFormsFeature) {
							const attachmentPromisesList: any = [];
							_.each(haulerCleaningFormElements, haulerElement => {
								const { inputs } = haulerElement;
								if (haulerElement.name !== Attachments) {
									return;
								}
								_.each(inputs, input => {
									input.value &&
										attachmentPromisesList.push(
											uploadFile(
												(input.value[0] as unknown) as CustomFormAttachment,
												(data as any).pumpOutEventId,
												input.fieldId || ''
											)
										);
								});
								_.each(removedAttachments, (removedAttachment: CustomFormAttachment) => {
									attachmentPromisesList.push(
										dispatch(
											deleteFacilityAttachment(
												removedAttachment.organizationId || 0,
												removedAttachment.attachmentOwnershipId || 0,
												''
											)
										)
									);
								});
							});
							if (attachmentPromisesList.length) {
								Promise.all(attachmentPromisesList)
									.then(() => onSubmitCallback())
									.catch(err => alertService.addError(err.message));
							} else {
								onSubmitCallback();
							}
						}

						if (data.nextPumpOutEventDate) {
							alertService.addInfo(
								localizationService.getLocalizedString(
									'pumpOut.nextCleaningScheduled',
									DateUtilService.toDisplayDate(data.nextPumpOutEventDate) || ''
								)
							);
						}
					});
				} else {
					apiService
						.postResource(url, payload)
						.then(data => {
							if (files.length) {
								const queryParameters = `facilityId=${props.facilityId}&ownerId=${
									(data as any).pumpOutEventId
								}&attachmentType=${AttachmentType.FogDeviceCleaning}`;
								let url = urlService.getAuthorityResourcesApiUrl(
									`${Resource.HaulerDeviceCleaningAttachment}?${queryParameters}`
								);
								CustomFormService.uploadFiles(url, files);
							}
							_.each(haulerCleaningFormElements, haulerElement => {
								const { inputs } = haulerElement;
								if (haulerElement.name !== Attachments) {
									return;
								}
								_.each(inputs, input => {
									input.value &&
										uploadFile(
											(input.value[0] as unknown) as CustomFormAttachment,
											(data as any).pumpOutEventId,
											input.fieldId || ''
										).catch(err => alertService.addError(err.message));
								});
							});
							if (data.nextPumpOutEventDate) {
								alertService.addInfo(
									localizationService.getLocalizedString(
										'pumpOut.nextCleaningScheduled',
										DateUtilService.toDisplayDate(data.nextPumpOutEventDate) || ''
									)
								);
							}
						})
						.then(() => onSubmitCallback())
						.catch(err => alertService.addError(err.message));
				}
			}, 200);
		}
	};

	const onSubmitCallback = () => {
		alertService.addSuccess(
			props.isEditMode
				? localizationService.getLocalizedString('alertMessages.savedSuccess', 'pumpOut.cleaning')
				: localizationService.getLocalizedString('haulerPortal.submitCleaning.submitSuccess')
		);
		if (props.isEditMode) {
			if (!props.isFacilityDetailsPage) {
				let isHaulerSubmittingCleanView = history.location.pathname.includes(Resource.Cleanings);
				if (isHaulerSubmittingCleanView) {
					dispatch(cleaningSlice.reload());
				} else {
					reloadTimelineEventsFromServer();
				}
			} else {
				dispatch(loadCurrentFogFacility());
				let dateRange = getTimelineSettingDateRange();
				dispatch(
					loadFacilityCleaningManifest(
						dateRange.startDateStr,
						dateRange.endDateStr,
						showCustomCleaningFormsFeature
					)
				);
				dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
			}
			props.onCancel();
		} else if (selectedDeviceIndex !== props.selectedDevices.length - 1) {
			setSelectedDevicesIndex(selectedDeviceIndex + 1);
			scrollToTopRef.current!.scrollIntoView({ behavior: 'smooth' });
		} else {
			props.onCancel();
			props.closeSelectDevicePopup && props.closeSelectDevicePopup();
			if (props.isTriggeredFromGrid) {
				dispatch(haulerPortalSelectedFacilitiesSlice.fetchAll(window.location.search.split('?')[1]));
			} else {
				navigateTo(history, urlService.getHaulerResourceReactUrl(Resource.FogFacilities));
			}
		}
		resetState();
	};

	const isFormValidateForSubmit = (): boolean => {
		let newState = { ...formState };

		let isFormValid = false;

		validateCommonFields(newState);

		if (props.isSeattleFacility) {
			isFormValid = validateSeattleFields(newState, isFormValid);
		} else {
			isFormValid = validateStandardFields(newState, isFormValid);
		}

		setFormState(newState);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const validateCommonFields = (newState: FormFields) => {
		validationService.validateRequiredDateField(
			newState,
			'completeDate',
			'completeDateError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.cleaningDate')
		);
		validationService.validateRequiredField(
			newState,
			'certificationAccepted',
			'certificationAcceptedError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.certification')
		);

		validationService.validateNumberField(
			newState,
			'amountPumped',
			'amountPumpedError',
			true,
			props.isSeattleFacility
				? localizationService.getLocalizedString('haulerPortal.submitCleaning.gallonsWasteRemoved')
				: localizationService.getLocalizedString('haulerPortal.submitCleaning.wasteRemoved')
		);
	};

	const validateStandardFields = (newState: FormFields, isFormValid: boolean) => {
		validationService.validateRequiredField(
			newState,
			'wasteTypeId',
			'wasteTypeError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.wasteType')
		);

		validationService.validateRequiredField(
			newState,
			'signature',
			'signatureError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.signatureError')
		);

		isFormValid = !validationService.hasError(
			newState,
			'completeDateError',
			'wasteTypeError',
			'amountPumpedError',
			'signatureError',
			'certificationAcceptedError'
		);

		return isFormValid;
	};

	const validateSeattleFields = (newState: FormFields, isFormValid: boolean) => {
		validationService.validateNumberField(
			newState,
			'fog',
			'fogError',
			true,
			localizationService.getLocalizedString('haulerPortal.submitCleaning.fog')
		);
		validationService.validateNumberField(
			newState,
			'trapDepth',
			'trapDepthError',
			true,
			localizationService.getLocalizedString('haulerPortal.submitCleaning.trapDepth')
		);
		validationService.validateNumberField(
			newState,
			'solid',
			'solidError',
			true,
			localizationService.getLocalizedString('haulerPortal.submitCleaning.solids')
		);
		if (newState.isRepairNeeded) {
			validationService.validateRequiredField(
				newState,
				'isRepairNeededReason',
				'isRepairNeededReasonError',
				localizationService.getLocalizedString('haulerPortal.submitCleaning.isRepairNeededReason')
			);
		} else {
			_.unset(newState, 'isRepairNeededReasonError');
		}
		validationService.validateRequiredField(
			newState,
			'leavingOutlet',
			'leavingOutletError',
			localizationService.getLocalizedString('haulerPortal.submitCleaning.leavingOutlet')
		);

		isFormValid = !validationService.hasError(
			newState,
			'completeDateError',
			'fogError',
			'amountPumpedError',
			'solidsError',
			'trapDepthError',
			'isRepairNeededReasonError',
			'leavingOutletError',
			'certificationAcceptedError'
		);
		return isFormValid;
	};

	const modalFooter = () => {
		return props.hideFooter ? (
			<></>
		) : (
			<>
				{(props.readOnly || props.isEditMode) && !props.hideDelete && (
					<button
						type="button"
						className={`btn ${props.isEditMode ? 'ai-secondary-delete' : 'ai-delete'}`}
						onClick={() => {
							setShowDeleteCleaningModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}
				{!props.readOnly && (
					<button
						className={`btn ${
							props.saveButtonClassName
								? props.saveButtonClassName
								: `btn ${props.isEditMode ? 'ai-save' : 'ai-action'}`
						}`}
						onClick={props.readOnly ? props.onSubmit : onSubmit}>
						{props.saveButtonText
							? props.saveButtonText
							: selectedDeviceIndex === props.selectedDevices.length - 1 || props.isEditMode
							? localizationService.getLocalizedString(
									`haulerPortal.submitCleaning.buttons.${props.isEditMode ? 'save' : 'submit'}`
							  )
							: localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.buttons.submitAndNext'
							  )}
					</button>
				)}
				{!props.hideCancel && (
					<button
						className={`btn ai-white ${props.readOnly && props.readOnly ? 'ml-auto' : ''}`}
						onClick={() => {
							props.onCancel();
						}}>
						{localizationService.getLocalizedString('haulerPortal.buttons.cancel')}
					</button>
				)}
			</>
		);
	};

	const modalProps = {
		title: props.title,
		showModal: props.showModal,
		cancel: onCancel,
		className: 'submit-cleaning-modal',
		footer: modalFooter()
	};

	const handleDrop = (chosenFiles: any) => {
		CustomFormService.handleDrop(chosenFiles, files, setFiles);
	};

	const removeFile = (index: number, size: number = 10) => {
		return (
			<span
				id="remove-file"
				className="cursor-pointer p-1"
				onClick={event => {
					event.stopPropagation();
					let selectedFiles = _.cloneDeep(files);
					selectedFiles.splice(index, 1);
					setFiles(selectedFiles);
				}}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};

	const onFileChangeHandler = (event: any) => {
		CustomFormService.onFileChangeHandler(event, files, setFiles);
	};

	const deleteCleaningEvent = () => {
		if (formState.cleaningManifestId) {
			const url = urlService.getAuthorityResourceApiUrl(
				Resource.HaulerCleaningHistory,
				formState.cleaningManifestId
			);
			apiService
				.deleteResource(url)
				.then(data => {
					if (!props.isFacilityDetailsPage) {
						let isHaulerSubmittingCleanView = history.location.pathname.includes(Resource.Cleanings);
						if (isHaulerSubmittingCleanView) {
							dispatch(cleaningSlice.reload());
						} else {
							reloadTimelineEventsFromServer();
						}
					} else {
						dispatch(loadCurrentFogFacility());
						let dateRange = getTimelineSettingDateRange();
						dispatch(
							loadFacilityCleaningManifest(
								dateRange.startDateStr,
								dateRange.endDateStr,
								showCustomCleaningFormsFeature
							)
						);
						dispatch(loadFacilityAttachments(urlService.getFogFacilityId(), true));
					}
					alertService.addSuccess(
						localizationService.getLocalizedString('haulerPortal.submitCleaning.deleteCleaningManifest')
					);
					props.onCancel();
				})
				.catch(err => {
					alertService.addError(`${err.message}`);
				});
		}
		setShowDeleteCleaningModal(false);
	};

	const getFacilityAddress = () => {
		let { addressLine1, cityName, jurisdictionName, zipCode } = props.facility || {};
		let {
			facilityAddress,
			facilityAddressLine1,
			facilityCityName,
			facilityJurisdictionName,
			facilityZipCode
		} = formState;
		const address1 = addressLine1 || facilityAddressLine1 || facilityAddress || '';
		const city = cityName || facilityCityName || '';
		const jurisdiction = jurisdictionName || facilityJurisdictionName || '';
		const zip = zipCode || facilityZipCode || '';
		return (
			<>
				{address1 && (
					<>
						<Translate doNotTranslate={true}>{address1}</Translate>
					</>
				)}
				<br />
				{city && (
					<>
						<Translate doNotTranslate={true}>{city + (jurisdiction ? ', ' : '')}</Translate>
					</>
				)}
				{jurisdiction && (
					<>
						<Translate doNotTranslate={true}>{jurisdiction + ' '}</Translate>
					</>
				)}
				{zip && (
					<>
						<Translate doNotTranslate={true}>{zip}</Translate>
					</>
				)}
			</>
		);
	};

	const seattleCertStatementLabel = () => {
		return (
			<>
				<span>{SeattleAuthorityConstants.certificationStatementPart1}</span>
				<br />
				<br />
				<span>{SeattleAuthorityConstants.certificationStatementPart2}</span>
				<a href={SeattleAuthorityConstants.privacyStatementWebsite} target="_blank" rel="noopener noreferrer">
					{SeattleAuthorityConstants.privacyStatement}
				</a>
				.
			</>
		);
	};

	const leavingOutletOptions = () => {
		const leavingOutletOptions = ['Yes', 'No', 'Not observed'];
		return leavingOutletOptions.map(leavingOutletOption => {
			let option: DropDownOption = {
				label: leavingOutletOption,
				value: leavingOutletOption
			};
			return option;
		});
	};

	const viewAttachments = () => {
		if (showCustomCleaningFormsFeature && templateJson) {
			return ConverterFactory.viewAttachments(haulerCleaningFormElements, files, 'viewModeAttachments');
		} else {
			return (
				<div
					id="viewModeAttachments"
					className={'mb-3 ' + _.isEmpty(formState.cleaningManifestHtmlContent) ? '' : 'invisible'}>
					<div className="mb-1">
						{localizationService.getLocalizedString('haulerPortal.submitCleaning.attachments')}
					</div>

					<div className="attachments cleaning-manifest-attachments">
						<div className="attachment-list mx-n1">
							{files &&
								files.map((attachment: AttachmentOwnership, index: number) => {
									return (
										<AttachmentThumbnail
											attachment={attachment}
											key={`icon-${index}`}
											insideModal
											className="attachment-thumbnail m-1"
										/>
									);
								})}
						</div>
					</div>
					{!files.length && (
						<label>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.noAttachments')}
						</label>
					)}
				</div>
			);
		}
	};

	const formElement = () => {
		return (
			<form id={`cleaningSubmissionForm`} key={`cleaningSubmissionForm`} autoComplete="off">
				{getCleaningFormHeader()}
				{props.isSeattleFacility && (
					<p className="required font-size-12px-regular mt-3">
						{localizationService.getLocalizedString('screen.labels.indicatesRequiredField')}
					</p>
				)}
				{props.readOnly ? (
					<TextInput
						id="completeDate"
						className="form-group input-single-line"
						name="completeDate"
						value={DateUtilService.toDisplayDate(formState.completeDate)}
						label={localizationService.getLocalizedString('haulerPortal.submitCleaning.cleaningDate')}
						isRequired={true}
						isDisabled={props.readOnly}
						error={formState.completeDateError}
					/>
				) : (
					<DateInput
						id="completeDate"
						name="completeDate"
						label={localizationService.getLocalizedString('haulerPortal.submitCleaning.cleaningDate')}
						value={formState.completeDate}
						error={formState.completeDateError}
						max={DateUtilService.getAuthorityTimezoneNow()}
						isRequired={true}
						onChange={onChange}
					/>
				)}
				<TextInput
					id="manifestNumber"
					className="form-group input-single-line"
					name="manifestNumber"
					value={formState.manifestNumber ? formState.manifestNumber : ''}
					label={
						props.isSeattleFacility
							? localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.manifestWorkOrderNumber'
							  )
							: localizationService.getLocalizedString('haulerPortal.submitCleaning.manifestNumber')
					}
					isDisabled={props.readOnly}
					onChange={onChange}
				/>
				<TextInput
					id="driverName"
					name="driverName"
					className="form-group input-single-line"
					value={formState.driverName ? formState.driverName : ''}
					label={localizationService.getLocalizedString('haulerPortal.submitCleaning.driverName')}
					isDisabled={props.readOnly}
					onChange={onChange}
				/>
				{!props.isSeattleFacility && (
					<TextInput
						id="vehicleNumber"
						name="vehicleNumber"
						className="form-group input-single-line"
						value={formState.vehicleNumber ? formState.vehicleNumber : ''}
						label={localizationService.getLocalizedString('haulerPortal.submitCleaning.vehicleNumber')}
						isDisabled={props.readOnly}
						onChange={onChange}
					/>
				)}
				{!props.isSeattleFacility &&
					(props.readOnly ? (
						<TextInput
							id="wasteTypeId"
							name="wasteTypeId"
							className="form-group input-single-line"
							value={formState.wasteTypeCode}
							label={localizationService.getLocalizedString('haulerPortal.submitCleaning.wasteType')}
							isDisabled={props.readOnly}
							isRequired={!props.isSeattleFacility}
							onChange={onChange}
						/>
					) : (
						<SingleSelectDropdown
							id="wasteTypeId"
							name="wasteTypeId"
							className="form-group input-single-line"
							label={localizationService.getLocalizedString('haulerPortal.submitCleaning.wasteType')}
							value={formState.wasteTypeId ? formState.wasteTypeId : ''}
							onChange={onChange}
							isDisabled={props.readOnly}
							isRequired={!props.isSeattleFacility}
							selfOrder
							options={wasteTypeDropDownOptions}
							error={formState.wasteTypeError}
						/>
					))}
				<TextInput
					id="amountPumped"
					name="amountPumped"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('haulerPortal.submitCleaning.gallonsWasteRemoved')}
					value={formState.amountPumped ? formState.amountPumped : undefined}
					isDisabled={props.readOnly}
					isRequired={true}
					onChange={onChange}
					type="number"
					error={formState.amountPumpedError}
				/>
				{props.isSeattleFacility && (
					<>
						<div className="form-row">
							<TextInput
								id="trapDepth"
								name="trapDepth"
								className="form-group col-sm-4 input-single-line"
								label={localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.trapDepthInches'
								)}
								value={formState.trapDepth ? formState.trapDepth : undefined}
								isDisabled={props.readOnly}
								isRequired={true}
								onChange={onChange}
								type="number"
								error={formState.trapDepthError}
							/>
							<TextInput
								id="fog"
								name="fog"
								className="form-group col-sm-4 input-single-line"
								label={localizationService.getLocalizedString('haulerPortal.submitCleaning.fogInches')}
								value={formState.fog ? formState.fog : undefined}
								isDisabled={props.readOnly}
								isRequired={true}
								onChange={onChange}
								type="number"
								error={formState.fogError}
							/>
							<TextInput
								id="solid"
								name="solid"
								className="form-group col-sm-4 input-single-line"
								label={localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.solidsInches'
								)}
								value={formState.solid ? formState.solid : undefined}
								isDisabled={props.readOnly}
								isRequired={true}
								onChange={onChange}
								type="number"
								error={formState.solidError}
							/>
						</div>
						<p>
							{`${localizationService.getLocalizedString('haulerPortal.submitCleaning.percentGrease')}`}
							{formState.percentGrease && (
								<strong id="percentGrease">{`${formState.percentGrease}%`}</strong>
							)}
						</p>
						<SingleSelectDropdown
							id="leavingOutlet"
							name="leavingOutlet"
							className="form-group"
							label={localizationService.getLocalizedString('haulerPortal.submitCleaning.leavingOutlet')}
							value={formState.leavingOutlet ? formState.leavingOutlet : ''}
							onChange={onChange}
							options={leavingOutletOptions()}
							displayAsRequired={true}
							selfOrder={true}
							readonly={props.readOnly}
							error={formState.leavingOutletError}
						/>
						<SingleCheckbox
							id="isRepairNeeded"
							name="isRepairNeeded"
							className="form-group"
							label={localizationService.getLocalizedString('haulerPortal.submitCleaning.isRepairNeeded')}
							checked={formState.isRepairNeeded ? formState.isRepairNeeded : false}
							isDisabled={props.readOnly}
							onChange={onChange}
						/>
						{formState.isRepairNeeded && (
							<>
								{props.readOnly ? (
									<TextInput
										id="isRepairNeededReason"
										name="isRepairNeededReason"
										className="form-group input-single-line"
										label={localizationService.getLocalizedString(
											'haulerPortal.submitCleaning.isRepairNeededReason'
										)}
										value={formState.isRepairNeededReason ? formState.isRepairNeededReason : ''}
										isDisabled={props.readOnly}
										isRequired={true}
										onChange={onChange}
										error={formState.isRepairNeededReasonError}
									/>
								) : (
									<TextAreaInput
										id="isRepairNeededReason"
										name="isRepairNeededReason"
										value={formState.isRepairNeededReason ? formState.isRepairNeededReason : ''}
										label={localizationService.getLocalizedString(
											'haulerPortal.submitCleaning.isRepairNeededReason'
										)}
										isRequired={formState.isRepairNeeded}
										isFullWidth
										onChange={onChange}
										error={formState.isRepairNeededReasonError}
									/>
								)}
							</>
						)}
					</>
				)}
				{props.readOnly ? (
					viewAttachments()
				) : (
					<div id="attachmentsInput" className="mb-2">
						<label>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.attachments')}
						</label>
						<DragAndDrop
							handleDrop={handleDrop}
							className="drag-submit"
							dragMessage={localizationService.getLocalizedString(
								'haulerPortal.submitCleaning.dragAndDrop'
							)}>
							<div className="file-input-group p-1">
								<label
									className="btn ai-secondary mb-1"
									htmlFor="btnFileImport"
									aria-describedby="file-input-help">
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.chooseFiles')}
								</label>
								<input
									type="file"
									id="btnFileImport"
									className="file-input mr-4 "
									onChange={onFileChangeHandler}
									onClick={(e: any) => (e.target.value = null)}
									style={{ width: '0px' }}
									multiple
								/>
								{files.length ? (
									files.map((fileData: File, index: number) => {
										return (
											<span key={`file-${index}`}>
												<label className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
													<div className="d-inline-flex" title={fileData && fileData.name}>
														<span id="file-badge-name">{fileData && fileData.name}</span>
													</div>
													{removeFile(index)}
												</label>
											</span>
										);
									})
								) : (
									<label className="file-name ml-4 pl-4">
										{localizationService.getLocalizedString(
											'haulerPortal.submitCleaning.dragAndDrop'
										)}
									</label>
								)}
							</div>
						</DragAndDrop>
					</div>
				)}
				{props.readOnly ? (
					<div className="mb-3">
						<label htmlFor="comments">
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.comments')}
						</label>
						<textarea
							id="comments"
							name="comments"
							disabled
							className="form-control value input-single-line">
							{formState.comments}
						</textarea>
					</div>
				) : (
					<TextAreaInput
						id="comments"
						name="comments"
						className="form-group input-single-line"
						isDisabled={props.readOnly}
						value={formState.comments}
						label={localizationService.getLocalizedString('haulerPortal.submitCleaning.comments')}
						isFullWidth
						onChange={onChange}
					/>
				)}
				<SingleCheckbox
					id="certificationAccepted"
					name="certificationAccepted"
					className={`form-group required`}
					label={
						props.isSeattleFacility
							? seattleCertStatementLabel()
							: localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.certificationDescription'
							  )
					}
					isDisabled={props.readOnly}
					checked={formState.certificationAccepted}
					fieldLabel={
						<label className="mb-1">
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.certification')}
						</label>
					}
					onChange={onChange}
					error={formState.certificationAcceptedError}
				/>
				{!props.isSeattleFacility ? (
					<div className="form-group required">
						<label htmlFor="currentUserSignature">
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.signature')}
						</label>
						<div id="signature" className="user-signature">
							<img
								src={formState.signature || ''}
								alt=""
								onClick={(event: any) => {
									!props.readOnly && openSignaturePad(event.target);
								}}
								onError={(event: any) => {
									event.target.style.opacity = 0;
									setSignature('');
								}}
								onLoad={(event: any) => {
									event.target.style.opacity = 1;
									setSignature(event.target.src);
								}}
							/>
						</div>
					</div>
				) : null}
			</form>
		);
	};

	const getDeleteMessage = (): string => {
		let violationsCount = formState.violationsCount;
		if (violationsCount) {
			if (violationsCount === 0) return localizationService.getLocalizedString('pumpOut.deleteModalMessage');
			if (violationsCount === 1) {
				return localizationService.getLocalizedString(
					'pumpOut.deleteModalMessageViolations',
					violationsCount.toString(),
					''
				);
			} else {
				return localizationService.getLocalizedString(
					'pumpOut.deleteModalMessageViolations',
					violationsCount.toString(),
					's'
				);
			}
		}
		return localizationService.getLocalizedString('pumpOut.deleteModalMessage');
	};

	const getCleaningFormHeader = () => {
		return (
			<div
				className={`fieldset ${
					props.isSeattleFacility && !showCustomCleaningFormsFeature
						? 'seattle-cleaning-device-fields'
						: 'cleaning-device-fields'
				}`}>
				<p>
					<strong>{localizationService.getLocalizedString('haulerPortal.submitCleaning.description')}</strong>
					<br />
					<Translate>
						{selectedDeviceDetail.extractorDescription || formState.deviceDescription || ''}
					</Translate>
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('haulerPortal.submitCleaning.type')}</strong>
					<br />
					<Translate>{selectedDeviceDetail.extractorType || formState.extractorType || ''}</Translate>
				</p>
				<p>
					<strong>
						<Translate doNotTranslate={true}>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.deviceNumber')}
						</Translate>
					</strong>
					<br />
					{selectedDeviceDetail.deviceNumber || formState.deviceNumber || ''}
				</p>
				<p>
					<strong>{localizationService.getLocalizedString('haulerPortal.submitCleaning.facility')}</strong>
					<br />
					<Translate doNotTranslate={true}>
						{(props.facility && props.facility.facilityName) || formState.facilityName || 'NA'}
					</Translate>
					<br />
					{getFacilityAddress()}
				</p>
			</div>
		);
	};

	const toggleViewOriginalSubmission = (e?: any) => {
		if (e) e.preventDefault();
		setShowViewOriginalSubmission(!showViewOriginalSubmission);
	};

	return (
		<>
			<PopoverModal {...modalProps}>
				<div ref={scrollToTopRef} />
				{!props.readOnly && props.selectedDevices.length > 1 && (
					<h2 className="device-number">
						{localizationService.getLocalizedString(
							'haulerPortal.submitCleaning.deviceSelected',
							(selectedDeviceIndex + 1).toString(),
							props.selectedDevices.length.toString() as string
						)}
					</h2>
				)}
				{props.readOnly && (
					<p>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.submitted')}
						</Translate>
						&nbsp;
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.by')}
						</Translate>
						&nbsp;
						{formState.submitterUserFullName}
						&nbsp;
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.of')}
						</Translate>
						&nbsp;
						{formState.haulerName}
						&nbsp;
						<Translate>
							{localizationService.getLocalizedString('facilityPortal.submitCleaning.on')}
						</Translate>
						&nbsp;
						<Translate>
							{DateUtilService.toDisplayDate(props.submittedDate || formState.creationDateTimeLocal)}
						</Translate>
						&nbsp;
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.submitCleaning.at')}
						</Translate>
						&nbsp;
						<Translate>
							{DateUtilService.toDisplayTime(props.submittedDate || formState.creationDateTimeLocal)}
						</Translate>
						{!urlService.isHaulerPortal() && (
							<>
								&nbsp;
								<Translate>
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.in')}
								</Translate>
								&nbsp;
								<Translate>{formState.submittedLanguageName}</Translate>
							</>
						)}
						.
					</p>
				)}
				{formState.originalSubmittedFormHtmlContent &&
					(urlService.isAuthorityPortal() || urlService.isFacilityPortal()) && (
						<>
							{urlService.isAuthorityPortal() &&
								`${localizationService.getLocalizedString(
									'haulerPortal.submitCleaning.submitted'
								)} ${localizationService.getLocalizedString('haulerPortal.submitCleaning.in')} ${
									formState.submittedLanguageName
								}. `}
							<a href="#/" onClick={toggleViewOriginalSubmission}>
								<Translate>
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.viewOriginal')}
								</Translate>
							</a>
							.
						</>
					)}
				{props.readOnly &&
				formState.cleaningManifestHtmlContent &&
				(urlService.isHaulerPortal() || !templateJson) ? (
					<>
						<div id="cleaningManifestHtmlContentDiv" />
					</>
				) : (
					<>
						{showCustomCleaningFormsFeature && templateJson ? (
							<>
								<form id={`cleaningSubmissionForm`} key={`cleaningSubmissionForm`} autoComplete="off">
									{getCleaningFormHeader()}
									{props.isSeattleFacility && (
										<p className="required font-size-12px-regular mt-3">
											{localizationService.getLocalizedString(
												'screen.labels.indicatesRequiredField'
											)}
										</p>
									)}
									{!!haulerCleaningFormElements.length &&
										ConverterFactory.getInputsFromDataItem(
											haulerCleaningFormElements,
											handleChangeInputValue,
											true,
											formType,
											authorityOrganizationId,
											props.readOnly
										).map(formData => {
											return <div>{formData}</div>;
										})}
								</form>
							</>
						) : (
							formElement()
						)}
					</>
				)}
				{props.readOnly && viewAttachments()}
			</PopoverModal>
			{showDeleteCleaningModal && (
				<DeleteModal
					key="confirmDeleteCleaningManifestModal"
					title={localizationService.getLocalizedString('pumpOut.deleteModalTitle')}
					message={getDeleteMessage()}
					showModal={true}
					onCancelButtonClick={() => {
						setShowDeleteCleaningModal(false);
					}}
					onOkayButtonClick={deleteCleaningEvent}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
			{showViewOriginalSubmission && formState.originalSubmittedFormHtmlContent && (
				<OriginalSubmissionModal
					showModal={showViewOriginalSubmission}
					onCancelButtonClick={toggleViewOriginalSubmission}
					originalSubmittedFormHtmlContent={formState.originalSubmittedFormHtmlContent}
					facilityCleaningFormElements={haulerCleaningFormElements}
					files={files}
					submittedLanguageCode={formState.submittedLanguageCode}
				/>
			)}
		</>
	);
};
export default HaulerSubmitCleaningModal;
