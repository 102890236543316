import { IppIndustry, IppReportPackageParameterGroup, IppReportPackageTemplate, RouteProps } from '@rcp/types';
import _ from 'lodash';
import React, { Component } from 'react';
import { RouterProps } from 'react-router';
import { DeleteModal, DeleteModalProp, SingleCheckbox, TextInput } from 'src/components/widgets';
import {
	IndustriesState,
	loadIndustryDetails,
	resetIndustryAction,
	updateIndustry
} from 'src/redux/ipp/authority/industry';
import { DateUtilService, localizationService, navigateTo, tokenService, urlService } from 'src/services';
import { AvailableSelectedGrids } from '../report-packages/templates/template-details';
import { parameterGroupColumns, templates } from '../report-packages/templates/template-details/template-columns';
import { IppScheduledReportPackage } from '../../../service-provider/industry/report-packages';
import { History } from 'history';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, ApplicationState } from 'src/redux';
import { IppConstants } from 'src/constants';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import './industries.scss';

interface State {
	industryData: IppIndustry;
	statusIndustryModal: boolean;
	isDataChanging: boolean;
	isAccessDenied: boolean;
	availableTemplates: IppReportPackageTemplate[];
	availableParameterGroups: IppReportPackageParameterGroup[];
}

interface DispatchProps {
	loadDetails: (industryId: number) => Promise<any>;
	updateDetails: (industry: IppIndustry, industryId?: number, callbackOnSuccess?: any, isPatchCall?: boolean) => void;
	resetDetails: () => void;
}

interface Props extends RouteProps, RouterProps, DispatchProps, IndustriesState {
	history: History;
}

interface TemplateAssignmentsGridsData {
	available: IppReportPackageTemplate[];
	selected: IppReportPackageTemplate[];
}

interface ParameterGroupAssignmentsGridsData {
	available: IppReportPackageTemplate[];
	selected: IppReportPackageParameterGroup[];
}

class IppIndustryDetail extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			industryData: {} as IppIndustry,
			statusIndustryModal: false,
			isDataChanging: false,
			isAccessDenied: false,
			availableTemplates: [],
			availableParameterGroups: []
		};
	}

	componentDidMount = async () => {
		let industryId =
			this.props.match.params.industryId ||
			Number(tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId);
		let response = await this.props.loadDetails(industryId);
		if (
			response &&
			response.statusCode === 403 &&
			String.equalCaseInsensitive(
				response.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			alertService.clearAllMessages();
			this.setState({ isAccessDenied: true });
		}
	};

	componentWillUnmount = () => {
		this.props.resetDetails();
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (
			urlService.isIppAuthorityPortal() &&
			Number(props.industryDetails.ippIndustryId) !== Number(window.location.pathname.split('/').pop())
		) {
			return null;
		} else if (urlService.isIppIndustryPortal()) {
			return {
				industryData: { ...props.industryDetails }
			};
		} else if (!state.isDataChanging) {
			return {
				industryData: { ...props.industryDetails },
				availableTemplates: [...props.templates],
				availableParameterGroups: [...props.parameterGroups],
				isDataChanging: true
			};
		} else {
			return { ...state };
		}
	}

	changedUserDetails = (userDetailsFromApi: any, changedUserDetails: any) => {
		var details: string[] = _.union(_.keys(userDetailsFromApi), _.keys(changedUserDetails));
		var modifiedDetails: string[] = _.filter(details, (detail: string) => {
			return userDetailsFromApi[detail] !== changedUserDetails[detail];
		});
		return _.pick(this.state.industryData, modifiedDetails);
	};

	onToggleStatusIndustryModal = () => {
		this.setState(prevState => {
			return { statusIndustryModal: !prevState.statusIndustryModal };
		});
	};

	onTemplateAssignmentChange = (data: TemplateAssignmentsGridsData) => {
		this.setState(prevState => ({
			...prevState,
			industryData: { ...prevState.industryData, assignedReportPackageTemplates: data.selected },
			availableTemplates: data.available
		}));
	};

	onParameterGroupAssignmentChange = (data: ParameterGroupAssignmentsGridsData) => {
		this.setState(prevState => ({
			...prevState,
			industryData: { ...prevState.industryData, assignedParameterGroups: data.selected },
			availableParameterGroups: data.available
		}));
	};

	onChange = (event: any) => {
		if (event.target.type === 'checkbox') {
			const { name, checked } = event.target;
			this.setState(prevState => {
				return {
					industryData: {
						...prevState.industryData,
						[name]: checked
					},
					isDataChanging: true
				};
			});
		}
	};

	onChangeStatusOfIndustry = () => {
		this.setState(
			prevState => {
				return {
					industryData: {
						...prevState.industryData,
						isEnabled: !prevState.industryData.isEnabled
					},
					isDataChanging: true
				};
			},
			() => {
				let industry = _.pick(this.state.industryData, ['isEnabled']);
				this.props.updateDetails(
					industry,
					this.state.industryData.ippIndustryId,
					this.onToggleStatusIndustryModal
				);
			}
		);
	};

	onSave = () => {
		this.props.updateDetails(this.state.industryData, this.state.industryData.ippIndustryId, () => {}, false);
	};

	render() {
		let disableIndustryModalProps: DeleteModalProp = {
			title: localizationService.getLocalizedString(
				`ipp.industries.${this.props.industryDetails.isEnabled ? 'disableHeading' : 'enableHeading'}`
			),
			message: localizationService.getLocalizedString(
				`ipp.industries.${this.props.industryDetails.isEnabled ? 'disableInfoMessage' : 'enableInfoMessage'}`
			),
			showModal: this.state.statusIndustryModal,
			onCancelButtonClick: this.onToggleStatusIndustryModal,
			onOkayButtonClick: this.onChangeStatusOfIndustry,
			okayButtonText: localizationService.getLocalizedString(
				`ipp.buttons.${this.props.industryDetails.isEnabled ? 'disable' : 'enable'}`
			),
			isDeleteButton: this.props.industryDetails.isEnabled
		};
		return this.state.isAccessDenied ? (
			<AccessDeniedPage />
		) : (
			<>
				<div className="page-header">
					<h1>
						{!urlService.isIppIndustryPortal()
							? localizationService.getLocalizedString('ipp.industries.industryDetailsPageHeading')
							: localizationService.getLocalizedString('ipp.industryAccount.settings.pageHeader')}
					</h1>
				</div>
				<section>
					<div className="d-flex align-items-center">
						<span className="font-size-20px-regular">
							{localizationService.getLocalizedString('ipp.industries.industryInformation')}
						</span>
						{!urlService.isIppIndustryPortal() && (
							<span className="ml-auto">
								<button id="save-btn" className="btn ai-save mr-2" onClick={() => this.onSave()}>
									{localizationService.getLocalizedString('ipp.buttons.save')}
								</button>
								<button
									id="enable-disable-btn"
									className="btn ai-action mr-2"
									onClick={() => {
										this.onToggleStatusIndustryModal();
									}}>
									{localizationService.getLocalizedString(
										`ipp.buttons.${this.props.industryDetails.isEnabled ? 'disable' : 'enable'}`
									)}
								</button>
								<button
									id="users-btn"
									className="btn ai-action"
									onClick={() => {
										let usersUrl = `/ipp/authority/industries/${this.props.match.params.industryId}/users`;
										navigateTo(this.props.history, usersUrl);
									}}>
									{localizationService.getLocalizedString(`ipp.buttons.users`)}
								</button>
							</span>
						)}
					</div>
					<hr />
					<div className="form-row">
						<TextInput
							id="industry-name"
							name="industryName"
							label={localizationService.getLocalizedString(
								'ipp.industries.industryColumns.industryName'
							)}
							value={this.state.industryData.industryName}
							isDisabled={true}
						/>
						<TextInput
							id="industry-number"
							name="industryNumber"
							label={localizationService.getLocalizedString(
								'ipp.industries.industryColumns.industryNumber'
							)}
							value={(this.state.industryData.industryNumber || '').toString()}
							isDisabled={true}
						/>
					</div>
					{!urlService.isIppIndustryPortal() && (
						<>
							<div className="form-row">
								<TextInput
									id="enabled"
									name="isEnabled"
									label={localizationService.getLocalizedString(
										'ipp.industries.industryColumns.isEnabled'
									)}
									type="text"
									value={localizationService.formatValue(this.props.industryDetails.isEnabled)}
									isDisabled={true}
								/>
								<TextInput
									id="signatory"
									name="hasSignatory"
									label={localizationService.getLocalizedString(
										'ipp.industries.industryColumns.hasSignatory'
									)}
									type="text"
									value={localizationService.formatValue(this.state.industryData.hasSignatory)}
									isDisabled={true}
								/>
							</div>
							<div className="form-row">
								<TextInput
									id="assigned"
									name="assignedTo"
									label={localizationService.getLocalizedString(
										'ipp.industries.industryColumns.assignedTo'
									)}
									type="assignedTo"
									value={this.state.industryData.assignedTo || ''}
									isDisabled={true}
								/>
								<TextInput
									id="last-submission"
									name="lastSubmission"
									label={localizationService.getLocalizedString(
										'ipp.industries.industryColumns.lastSubmission'
									)}
									value={`${DateUtilService.toDisplayDate(
										this.state.industryData.lastSubmission || '',
										IppConstants.dateFormats.monthDayYear
									)} ${DateUtilService.toDisplayTime(
										this.state.industryData.lastSubmission || '',
										IppConstants.dateFormats.timeSecondsTwelveHourFormat
									).toUpperCase()}`}
									isDisabled={true}
								/>
							</div>
							<hr />
						</>
					)}
					<div className="form-row">
						<TextInput
							id="address-line1"
							name="addressLine1"
							label={localizationService.getLocalizedString('ipp.industries.addressLine1')}
							value={this.state.industryData.addressLine1 || ''}
							isDisabled={true}
						/>
						{!urlService.isIppIndustryPortal() && (
							<SingleCheckbox
								id="show-scheduled-reports"
								name="showScheduledReport"
								className="align-self-center ml-2 mt-2"
								label={localizationService.getLocalizedString('ipp.industries.showScheduledReports')}
								checked={Boolean(this.state.industryData.showScheduledReport)}
								onChange={this.onChange}
							/>
						)}
					</div>
					<div className="form-row">
						<TextInput
							id="address-line2"
							name="addressLine2"
							label={localizationService.getLocalizedString(
								'ipp.industries.industryColumns.addressLine2'
							)}
							value={this.state.industryData.addressLine2 || ''}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="city-name"
							name="cityName"
							label={localizationService.getLocalizedString('ipp.industries.industryColumns.cityName')}
							value={this.state.industryData.cityName || ''}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="state-name"
							name="stateName"
							label={localizationService.getLocalizedString('ipp.industries.industryColumns.stateName')}
							value={this.state.industryData.stateName || ''}
							isDisabled={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="zip-code"
							name="zipCode"
							label={localizationService.getLocalizedString('ipp.industries.industryColumns.zipCode')}
							value={(this.state.industryData.zipCode || '').toString()}
							isDisabled={true}
						/>
					</div>
				</section>
				{!urlService.isIppIndustryPortal() && (
					<>
						<section>
							<div className="font-size-20px-regular mb-4">
								{localizationService.getLocalizedString('ipp.industries.templateAssignment.title')}
							</div>
							<AvailableSelectedGrids<IppReportPackageTemplate>
								columns={templates}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.availableTemplates'
									),
									selected: localizationService.getLocalizedString(
										'ipp.reportPackage.templateDetails.gridNoRecordMsg.selectedTemplates'
									)
								}}
								available={this.state.availableTemplates}
								selected={this.state.industryData.assignedReportPackageTemplates || []}
								resourceKey="ipp.reportPackage.templates"
								onChange={this.onTemplateAssignmentChange}
								isDraggable={false}
								availableSortable={true}
								selectedSortable={true}
								showAddAll={true}
								showRemoveAll={true}
								showSearch={true}
								showActiveTemplatesToggle={true}
								id="templates"
							/>
						</section>
						<section>
							<div className="font-size-20px-regular mb-4 d-flex">
								<span>
									{localizationService.getLocalizedString(
										'ipp.industries.parameterGroupAssignment.title'
									)}
								</span>
								<SingleCheckbox
									id="show-automatically-generated-parameter-groups"
									name="showAutomaticallyGeneratedParameterGroups"
									className="ml-auto"
									label={localizationService.getLocalizedString(
										'ipp.industries.industryColumns.showAutomaticallyGeneratedParameterGroups'
									)}
									checked={Boolean(this.state.industryData.showAutomaticallyGeneratedParameterGroups)}
									onChange={this.onChange}
								/>
							</div>
							<AvailableSelectedGrids<IppReportPackageParameterGroup>
								columns={parameterGroupColumns}
								noRecordMsg={{
									available: localizationService.getLocalizedString(
										'ipp.industries.parameterGroupAssignment.gridNoRecordMsg.availableParameterGroups'
									),
									selected: localizationService.getLocalizedString(
										'ipp.industries.parameterGroupAssignment.gridNoRecordMsg.selectedParameterGroups'
									)
								}}
								available={this.state.availableParameterGroups}
								selected={this.state.industryData.assignedParameterGroups || []}
								resourceKey="ipp.industries.parameterGroupAssignment"
								onChange={this.onParameterGroupAssignmentChange}
								isDraggable={false}
								availableSortable={true}
								selectedSortable={true}
								showAddAll={true}
								showRemoveAll={true}
								showSearch={true}
								showActiveTemplatesToggle={true}
								id="parameterGroups"
							/>
						</section>
						<div id="authority-industry-scheduled">
							{this.props.match.params.industryId && (
								<IppScheduledReportPackage
									history={this.props.history}
									match={this.props.match}
									subHeading={localizationService.getLocalizedString(
										'ipp.reportPackage.package.reportTypes.Scheduled'
									)}
									informativeText={localizationService.getLocalizedString(
										'ipp.reportPackage.package.scheduledPackage.informativeText'
									)}
								/>
							)}
						</div>
					</>
				)}
				<DeleteModal {...disableIndustryModalProps} key="removeUserModal" />
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): IndustriesState => {
	return {
		...state.ippIndustryDetails
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadDetails: (industryId: number) => dispatch(loadIndustryDetails(industryId)),
		updateDetails: (industry: IppIndustry, industryId?: number, callbackOnSuccess?: any, isPatchCall?: boolean) =>
			dispatch(updateIndustry(industry, industryId, callbackOnSuccess, isPatchCall)),
		resetDetails: () => dispatch(resetIndustryAction())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppIndustryDetail);
