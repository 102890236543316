import React from 'react';

export interface SingleRadioProps {
	id: string;
	name: string;
	label?: string | JSX.Element;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SingleRadio: React.FC<SingleRadioProps> = props => {
	const changeValue = (e: any) => {
		if (props.onChange) {
			props.onChange(e);
		}
	};

	return (
		<div className="custom-control custom-radio">
			<input
				type="radio"
				className="custom-control-input"
				name={props.name}
				id={props.id}
				value="false"
				checked={props.checked}
				onChange={changeValue}
			/>
			<label className="custom-control-label" htmlFor={props.name}>
				<span>{props.label}</span>
			</label>
		</div>
	);
};
