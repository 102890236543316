import {
	CleaningEvent,
	CustomFieldDataType,
	CustomFieldDefinition,
	CustomFieldDefinitionApplyTo,
	FilterOperator,
	FilterType,
	FogFacility,
	GridTypes,
	LocalStorageName,
	RegulatoryProgramName
} from '@rcp/types';
import {
	DomainObject,
	FilterDefinition,
	FilterItem,
	FilterProperty,
	FilterValue,
	IFilterElement,
	Operator,
	OperatorCode
} from '../../services/data-types/filter-types';
import {
	apiService,
	customDefinedFieldService,
	localizationService,
	localStorageService,
	Logger,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { dateFilterBetweenOptions, validateDateFilterBetween } from './filter-row-date-between';
import { dateFilterBetweenRangeOptions, validateDateFilterBetweenRange } from './filter-row-date-between-range';
import { validateDateFilterIs } from './filter-row-date-is';
import _ from 'lodash';
import store from '../../index';
import { FilterObjectName } from '@rcp/types/src';
import { nameof } from 'ts-simple-nameof';
import { ReportPackageStatus } from 'src/constants';

export class FilterState {
	domainObject: DomainObject;
	customFieldNames: CustomFieldDefinitionApplyTo[];
	domainObjectName: string;

	constructor(
		domainObject: DomainObject,
		customFieldNames: CustomFieldDefinitionApplyTo[],
		domainObjectName: string
	) {
		this.domainObject = domainObject;
		this.customFieldNames = customFieldNames;
		this.domainObjectName = domainObjectName;
	}
}

export enum FilterDomain {
	CCCSITE = 'CCCSITE',
	CCCHAZARD = 'CCCHAZARD',
	VIOLATION = 'VIOLATION',
	FACILITY = 'FACILITY',
	INSPECTION = 'INSPECTION',
	EXTRACTOR = 'EXTRACTOR',
	CLEANING = 'CLEANING',
	PUMPOUT = 'PUMPOUT',
	EVENT = 'EVENT',
	INCIDENT = 'INCIDENT',
	IPPCROMERRAUDITLOG = 'IPPCROMERRAUDITLOG',
	IPPALLREPORTPACKAGE = 'IPPALLREPORTPACKAGE',
	IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE = 'IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE',
	IPPSUBMITTEDREPORTPACKAGE = 'IPPSUBMITTEDREPORTPACKAGE',
	IPPREPUDIATEDREPORTPACKAGE = 'IPPREPUDIATEDREPORTPACKAGE',
	IPPFILESTORE = 'IPPFILESTORE',
	IPPREPORTEDSAMPLE = 'IPPREPORTEDSAMPLE',
	IPPINDUSTRY = 'IPPINDUSTRY',
	IPPSAMPLE = 'IPPSAMPLE',
	IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE = 'IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE'
}

export enum FilterDomainName {
	cccSite = 'cccSite',
	cccHazard = 'cccHazard',
	violation = 'violation',
	facility = 'facility',
	inspection = 'inspection',
	extractor = 'extractor',
	cleaning = 'cleaning',
	pumpOut = 'pumpout',
	event = 'event',
	incident = 'incident',
	ippCromerrAuditLog = 'ippCromerrAuditLog',
	ippAllReportPackages = 'ippAllReportPackage',
	ippSubmittedPendingReviewReportPackages = 'ippSubmittedPendingReviewReportPackage',
	ippSubmittedReportPackages = 'ippSubmittedReportPackage',
	ippRepudiatedReportPackages = 'ippRepudiatedReportPackage',
	ippRejectedReportPackages = 'ippRejectedReportPackage',
	ippFileStore = 'ippFileStore',
	ippReportedSample = 'ippReportedSample',
	ippIndustry = 'ippIndustry',
	ippSample = 'ippSample',
	ippRepudiatedPendingReviewReportPackages = 'ippRepudiatedPendingReviewReportPackage'
}

class FilterService {
	domainObjectsForLoadFieldDefinition: DomainObject[] = [];
	emptyProperty: FilterProperty = new FilterProperty({ label: '', name: '' });
	supportedDomainObjectNames: string[] = [];

	filterTypeOperators: Map<FilterType, Operator[]> = new Map<FilterType, Operator[]>();
	operatorsForNumeric: Operator[] = [];
	operatorForDateUI: Operator[] = [];
	emptyFilterValue: FilterValue = {};
	applyTo: string = '';
	emptyDomainObjectName = '';
	domainsApplied: { [index: string]: boolean } = {};

	private fieldStates: FilterState[] = [];

	private static _instance: FilterService;

	private customFields: CustomFieldDefinition[] = [];

	private filterUrlStorageName: LocalStorageName | undefined;

	private extractorFields = [
		new FilterProperty({ label: 'extractor', name: 'extractorType' }),
		new FilterProperty({ label: 'extractor', name: 'location' }),
		new FilterProperty({ label: 'extractor', name: 'trapCapacity', filterType: FilterType.Number }),
		new FilterProperty({ label: 'extractor', name: 'trapCapacityUnitCode' }),
		new FilterProperty({ label: 'extractor', name: 'trapDepth', filterType: FilterType.Number }),
		new FilterProperty({ label: 'extractor', name: 'trapDepthUnitCode' }),
		new FilterProperty({ label: 'extractor', name: 'cleanFrequencyCode' }),
		new FilterProperty({ label: 'extractor', name: 'numberOfCompartments', filterType: FilterType.Integer }),
		new FilterProperty({ label: 'extractor', name: 'manufacturer' }),
		new FilterProperty({ label: 'extractor', name: 'model' }),
		new FilterProperty({ label: 'extractor', name: 'installDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'extractor', name: 'latitude' }),
		new FilterProperty({ label: 'extractor', name: 'longitude' }),
		new FilterProperty({
			label: 'extractor',
			name: 'mandatoryInstall',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'extractor',
			name: 'isAdditivesUsed',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'extractor',
			name: 'isInactive',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'extractor', name: 'lastCleaningDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'extractor', name: 'daysLateLastCleaning', filterType: FilterType.Number }),
		new FilterProperty({ label: 'extractor', name: 'nextCleaningDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'extractor', name: 'daysLateNextCleaning', filterType: FilterType.Number }),
		new FilterProperty({ label: 'extractor', name: 'lastHaulerName' }),
		new FilterProperty({ label: 'extractor', name: 'lastNoticeDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'extractor', name: 'lastNoticeTemplateName' }),
		new FilterProperty({ label: 'extractor', name: 'nextNoticeDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'extractor', name: 'nextNoticeTemplateName' }),
		new FilterProperty({
			label: 'extractor',
			name: 'verified',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'extractor',
			name: 'addedBy'
		}),
		new FilterProperty({
			label: 'extractor',
			name: 'sendNoticeContactsCount',
			filterType: FilterType.Number,
			isNonNullable: true
		})
	].sort(this.defaultFilterElementStringSort);

	private siteFields = [
		new FilterProperty({ label: 'cccSite', name: 'siteNumber' }),
		new FilterProperty({ label: 'cccSite', name: 'company' }),
		new FilterProperty({ label: 'cccSite', name: 'addressLine1' }),
		new FilterProperty({ label: 'cccSite', name: 'siteType' }),
		new FilterProperty({ label: 'cccSite', name: 'siteUse' }),
		new FilterProperty({ label: 'cccSite', name: 'dataSetName' }),
		new FilterProperty({ label: 'cccSite', name: 'hazardCount', filterType: FilterType.Number }),
		new FilterProperty({ label: 'cccSite', name: 'addressLine2' }),
		new FilterProperty({ label: 'cccSite', name: 'cityName' }),
		new FilterProperty({ label: 'cccSite', name: 'state' }),
		new FilterProperty({ label: 'cccSite', name: 'zipCode' }),
		new FilterProperty({ label: 'cccSite', name: 'latitude' }),
		new FilterProperty({ label: 'cccSite', name: 'longitude' })
	];

	private cleaningFields = [
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.deviceNumber) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.dueDate),
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.completeDate),
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.amountPumped),
			filterType: FilterType.Number
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.amountPumpedUnit) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.wasteTypeCode) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.driverName) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.vehicleNumber) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.haulerName) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.manifestNumber) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.disposalLocation) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.comments) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.extractorType) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.daysLate),
			filterType: FilterType.Number
		}),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.fog),
			filterType: FilterType.Number
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.fogUnit) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.solid),
			filterType: FilterType.Number
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.solidUnit) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.trapDepth),
			filterType: FilterType.Number
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.trapDepthUnit) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.percentGrease),
			filterType: FilterType.Number
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.leavingOutlet) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.isRepairNeeded),
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.isRepairNeededReason) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.certificationAccepted),
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.nextNoticeDate),
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.nextNoticeTemplateName) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.lastNoticeDate),
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.lastNoticeTemplateName) }),
		new FilterProperty({ label: 'pumpOut', name: nameof<CleaningEvent>(f => f.cleanedBy) }),
		new FilterProperty({
			label: 'pumpOut',
			name: nameof<CleaningEvent>(f => f.hasAttachment),
			filterType: FilterType.Boolean,
			isNonNullable: true
		})
	];

	private eventFields = [
		new FilterProperty({ label: 'events', name: 'eventTypeName' }),
		new FilterProperty({ label: 'events', name: 'dueDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'events', name: 'daysLate', filterType: FilterType.Number }),
		new FilterProperty({ label: 'events', name: 'assignedToUserName', isSignInUserTextRequired: true }),
		new FilterProperty({ label: 'events', name: 'completeDate', filterType: FilterType.Date })
	].sort(this.defaultFilterElementStringSort);

	private incidentFields = [
		new FilterProperty({ label: 'incidents', name: 'incidentNumber' }),
		new FilterProperty({ label: 'incidents', name: 'address' }),
		new FilterProperty({ label: 'incidents', name: 'latitude' }),
		new FilterProperty({ label: 'incidents', name: 'longitude' }),
		new FilterProperty({
			label: 'incidents',
			name: 'reportedDate',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'incidents', name: 'incidentTypeCode' }),
		new FilterProperty({ label: 'incidents', name: 'incidentStatusCode' }),
		new FilterProperty({ label: 'incidents', name: 'incidentSourceCode' }),
		new FilterProperty({ label: 'incidents', name: 'assetId' }),
		new FilterProperty({ label: 'incidents', name: 'investigatorFullName' }),
		new FilterProperty({ label: 'incidents', name: 'incidentDescription' }),
		new FilterProperty({ label: 'incidents', name: 'resolvedDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'incidents', name: 'resolutionDescription' })
	].sort(this.defaultFilterElementStringSort);

	private violationFields = [
		new FilterProperty({ label: 'violation', name: 'violationTypeTypeName' }),
		new FilterProperty({
			label: 'violation',
			name: 'nonComplianceDate',
			filterType: FilterType.Date,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'violation', name: 'complianceDueDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'violation', name: 'inComplianceDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'violation', name: 'lastEnforcementType' }),
		new FilterProperty({ label: 'violation', name: 'lastEnforcementDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'violation', name: 'assignedToUserName', isSignInUserTextRequired: true }),
		new FilterProperty({ label: 'violation', name: 'daysOpen', filterType: FilterType.Number }),
		new FilterProperty({ label: 'violation', name: 'comments' })
	].sort(this.defaultFilterElementStringSort);

	private hazardFields = [
		new FilterProperty({ label: 'cccHazard', name: 'hazardNumber' }),
		new FilterProperty({ label: 'cccHazard', name: 'accountNumber' }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceType' }),
		new FilterProperty({ label: 'cccHazard', name: 'serviceStatus' }),
		new FilterProperty({ label: 'cccHazard', name: 'meterNumber' }),
		new FilterProperty({ label: 'cccHazard', name: 'category' }),
		new FilterProperty({ label: 'cccHazard', name: 'lineSize' }),
		new FilterProperty({ label: 'cccHazard', name: 'protection' }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceLocation' }),
		new FilterProperty({ label: 'cccHazard', name: 'meterLocation' }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceStatus' }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceSerialNumber' }),
		new FilterProperty({ label: 'cccHazard', name: 'sendTestNotices', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'sendTestNoticesToSite', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceTestable', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceLeadFree', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceBypass', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'fireline', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'cccHazard', name: 'deviceOrientation' }),
		new FilterProperty({ label: 'cccHazard', name: 'serviceNumber' }),
		new FilterProperty({ label: 'cccHazard', name: 'serviceType' }),
		new FilterProperty({ label: 'cccHazard', name: 'shutOffDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'installDue', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'installedDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'lastTestDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'lastTestResult' }),
		new FilterProperty({ label: 'cccHazard', name: 'nextTestDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'lastSentNoticeDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'lastSentNoticeTemplateName' }),
		new FilterProperty({ label: 'cccHazard', name: 'nextDueNoticeDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'cccHazard', name: 'nextDueNoticeTemplateName' }),
		new FilterProperty({ label: 'cccHazard', name: 'testFrequencyCode' })
	];

	private auditLogFields = [
		new FilterProperty({ label: 'ipp.auditLog', name: 'auditLogTemplateId', filterType: FilterType.Number }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'regulatoryProgramName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'creatorOrganizationName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'userProfileId', filterType: FilterType.Number }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'eventCategory' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'eventType' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'userName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'userFirstName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'userLastName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'userEmailAddress' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'ipAddress' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'hostName' }),
		new FilterProperty({ label: 'ipp.auditLog', name: 'organizationName' }),
		new FilterProperty({
			label: 'ipp.auditLog',
			name: 'creationDateTimeLocal',
			filterType: FilterType.Date,
			isNonNullable: true
		})
	];

	private allReportPackageFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'name' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodStartDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodEndDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationReferenceNumber' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationName' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'submissionDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'repudiationDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'lastSentDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'assignedTo' })
	];

	private submittedPendingReviewReportPackageFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'name' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodStartDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodEndDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationReferenceNumber' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationName' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'submissionDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'assignedTo' })
	];

	private repudiatedPendingReviewReportPackageFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'name' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodStartDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodEndDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationReferenceNumber' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'organizationName' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'submissionDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'repudiationDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'lastSentDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'assignedTo' })
	];
	private submittedReportPackageFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'name' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodStartDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodEndDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'submissionDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'submitterFirstName' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'submitterLastName' })
	];

	private repudiatedReportPackageFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'name' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodStartDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'periodEndDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'submissionDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'submitterFirstName' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'submitterLastName' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.columns',
			name: 'repudiationDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'repudiatorFirstName' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.columns', name: 'repudiatorLastName' })
	];

	private attachmentFields = [
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'name' }),
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'originalFileName' }),
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'description' }),
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'reportElementTypeName' }),
		new FilterProperty({
			label: 'ipp.attachments.columns',
			name: 'uploadDateTimeUtc',
			filterType: FilterType.Date,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'uploaderUserFullName' }),
		new FilterProperty({ label: 'ipp.attachments.columns', name: 'attachmentStatus' })
	];

	private reportedSampleFields = [
		new FilterProperty({ label: 'ipp.samples.columns', name: 'monitoringPointName' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'ctsEventTypeName' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'collectionMethodName' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'startDateTimeLocal', filterType: FilterType.Date }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'endDateTimeLocal', filterType: FilterType.Date }),
		new FilterProperty({
			label: 'ipp.samples.columns',
			name: 'lastModificationDateTimeLocal',
			filterType: FilterType.Date
		}),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'lastModifierFullName' })
	];

	private industryFields = [
		new FilterProperty({ label: 'ipp.industries.industryColumns', name: 'industryNumber' }),
		new FilterProperty({ label: 'ipp.industries.industryColumns', name: 'industryName' }),
		new FilterProperty({ label: 'ipp.industries.industryColumns', name: 'addressLine1' }),
		new FilterProperty({ label: 'ipp.industries.industryColumns', name: 'classification' }),
		new FilterProperty({
			label: 'ipp.industries.industryColumns',
			name: 'isEnabled',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'ipp.industries.industryColumns',
			name: 'hasSignatory',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'ipp.industries.industryColumns', name: 'assignedTo' }),
		new FilterProperty({
			label: 'ipp.industries.industryColumns',
			name: 'showScheduledReport',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'ipp.industries.industryColumns',
			name: 'showAutomaticallyGeneratedParameterGroups',
			filterType: FilterType.Boolean,
			isNonNullable: true
		})
	];

	private reportPackageSampleFields = [
		new FilterProperty({ label: 'ipp.samples.columns', name: 'monitoringPointName' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'name' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'collectionMethodName' }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'startDateTimeLocal', filterType: FilterType.Date }),
		new FilterProperty({ label: 'ipp.samples.columns', name: 'endDateTimeLocal', filterType: FilterType.Date }),
		new FilterProperty({
			label: 'ipp.samples.columns',
			name: 'labSampleIdentifier'
		}),
		new FilterProperty({
			label: 'ipp.samples.columns',
			name: 'lastModificationDateTimeLocal',
			filterType: FilterType.Date
		})
	];

	private reportPackageAttachmentFields = [
		new FilterProperty({ label: 'ipp.reportPackage.package.fileStore', name: 'name' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.fileStore', name: 'originalFileName' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.fileStore', name: 'description' }),
		new FilterProperty({ label: 'ipp.reportPackage.package.fileStore', name: 'uploaderUserFullName' }),
		new FilterProperty({
			label: 'ipp.reportPackage.package.fileStore',
			name: 'uploadDateTimeUtc',
			filterType: FilterType.Date,
			isNonNullable: true
		}),
		new FilterProperty({
			label: 'ipp.reportPackage.package.fileStore',
			name: 'lastSubmissionDateTimeLocal',
			filterType: FilterType.Date
		})
	];

	private facilityFields = [
		new FilterProperty({ label: 'facility', name: 'referenceNumber' }),
		new FilterProperty({ label: 'facility', name: 'facilityName' }),
		new FilterProperty({ label: 'facility', name: 'complianceStatus' }),
		new FilterProperty({ label: 'facility', name: 'addressLine1' }),
		new FilterProperty({
			label: 'facility',
			name: 'isEnabled',
			filterType: FilterType.Boolean,
			isNonNullable: true
		}),
		new FilterProperty({ label: 'facility', name: 'addressLine2' }),
		new FilterProperty({ label: 'facility', name: 'cityName' }),
		new FilterProperty({ label: 'facility', name: 'jurisdictionName' }),
		new FilterProperty({ label: 'facility', name: 'zipCode' }),
		new FilterProperty({ label: 'facility', name: 'contactName' }),
		new FilterProperty({ label: 'facility', name: 'email' }),
		new FilterProperty({ label: 'facility', name: 'phone' }),
		new FilterProperty({ label: 'facility', name: 'longitude' }),
		new FilterProperty({ label: 'facility', name: 'latitude' }),
		new FilterProperty({ label: 'facility', name: 'elevation' }),
		new FilterProperty({ label: 'facility', name: 'gisNumber' }),
		new FilterProperty({ label: 'facility', name: 'mapCategory' }),
		new FilterProperty({
			label: 'facility',
			name: 'assignedTo',
			isSignInUserTextRequired: true
		}),
		new FilterProperty({ label: 'facility', name: 'lastHaulerName' }),
		new FilterProperty({ label: 'facility', name: 'meterNumber' }),
		new FilterProperty({ label: 'facility', name: 'sewerNumber' }),
		new FilterProperty({ label: 'facility', name: 'accountNumber' }),
		new FilterProperty({ label: 'facility', name: 'trunkLine' }),
		new FilterProperty({ label: 'facility', name: 'receivingPlant' }),
		new FilterProperty({ label: 'facility', name: 'inspectionFrequencyCode' }),
		new FilterProperty({ label: 'facility', name: 'inBusinessSince', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'closedDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'numberOfEmployees', filterType: FilterType.Number }),
		new FilterProperty({ label: 'facility', name: 'tagClassification1' }), //FilterType.Enum),
		new FilterProperty({ label: 'facility', name: 'tagClassification2' }), //FilterType.Enum),
		new FilterProperty({ label: 'facility', name: 'tags' }),
		new FilterProperty({ label: 'facility', name: 'hoursOfOperation' }),
		new FilterProperty({ label: 'facility', name: 'facilityPortalStatusName', isNonNullable: true }),
		new FilterProperty({ label: 'facility', name: 'squareFootage', filterType: FilterType.Integer }),
		new FilterProperty({ label: 'facility', name: 'seatingCapacity', filterType: FilterType.Integer }),
		new FilterProperty({ label: 'facility', name: 'numberMealsServed', filterType: FilterType.Integer }),
		new FilterProperty({ label: 'facility', name: 'lastInspectionCompleteDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'lastPumpOutCompleteDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'nextInspectionDueDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'nextPumpOutDueDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'invitationDateTimeUtc', filterType: FilterType.Date }),
		new FilterProperty({ label: 'facility', name: 'hasPrimaryContact', filterType: FilterType.Boolean }),
		new FilterProperty({ label: 'facility', name: 'activeDeviceCount', filterType: FilterType.Integer }),
		new FilterProperty({ label: 'facility', name: 'contractedHaulerName' }),
		new FilterProperty({ label: 'facility', name: nameof<FogFacility>(f => f.riskScore) }),
		new FilterProperty({ label: 'facility', name: 'isPreferredContractedHauler', filterType: FilterType.Boolean })
	].sort(this.defaultFilterElementStringSort);

	private inspectionFields = [
		new FilterProperty({ label: 'inspection', name: 'inspectionType' }),
		new FilterProperty({ label: 'inspection', name: 'dueDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'inspection', name: 'completeDate', filterType: FilterType.Date }),
		new FilterProperty({ label: 'inspection', name: 'inspectorName', isSignInUserTextRequired: true }),
		new FilterProperty({ label: 'inspection', name: 'creatorName' }),
		new FilterProperty({ label: 'inspection', name: 'inspectionResult' })
	].sort(this.defaultFilterElementStringSort);

	private fieldStateDefinitions: { [key in FilterDomain]?: FilterState[] } = {};

	private initFieldStateDefinitions() {
		if (urlService.getCurrentProgramOrDefault() === RegulatoryProgramName.IPP.toLowerCase()) {
			urlService.isIppIndustryPortal()
				? this.initIppIndustryFieldStateDefinitions()
				: this.initIppAuthorityFieldStateDefinitions();
		} else {
			this.initCccSiteFieldStateDefinitions();
			this.initFogStateDefinitions();
		}
	}

	private initFogStateDefinitions() {
		const fieldStates: FilterState[] = [];
		fieldStates.push(
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.facilities'),
					FilterDomainName.facility,
					this.facilityFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.FogFacilities)
				),
				[CustomFieldDefinitionApplyTo.FogFacility],
				FilterDomain.FACILITY
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.inspections'),
					FilterDomainName.inspection,
					this.inspectionFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.InspectionEvents)
				),
				[],
				FilterDomain.INSPECTION
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.extractors'),
					FilterDomainName.extractor,
					this.extractorFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.Extractors)
				),
				[],
				FilterDomain.EXTRACTOR
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.cleaning'),
					FilterDomainName.cleaning,
					this.cleaningFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.Cleanings)
				),
				[],
				FilterDomain.CLEANING
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.violations'),
					FilterDomainName.violation,
					this.violationFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.Violations)
				),
				[],
				FilterDomain.VIOLATION
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('screen.filterModal.lookIn.events'),
					FilterDomainName.event,
					this.eventFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.Events)
				),
				[],
				FilterDomain.EVENT
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('incidents.incidents'),
					FilterDomainName.incident,
					this.incidentFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.FOG, Resource.Incidents)
				),
				[],
				FilterDomain.INCIDENT
			)
		);
		this.fieldStateDefinitions[FilterDomain.VIOLATION] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.FACILITY] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.INSPECTION] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.EXTRACTOR] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.CLEANING] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.EVENT] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.INCIDENT] = fieldStates;
	}

	private initCccSiteFieldStateDefinitions() {
		const fieldStates: FilterState[] = [];
		fieldStates.push(
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('cccSite.sites'),
					FilterDomainName.cccSite,
					this.siteFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.Backflow, Resource.CccSites)
				),
				[CustomFieldDefinitionApplyTo.CccSite],
				FilterDomain.CCCSITE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('cccHazard.hazards'),
					FilterDomainName.cccHazard,
					this.hazardFields,
					urlService.getAuthorityProgramResourcesApiUrl(RegulatoryProgramName.Backflow, Resource.CccHazards)
				),
				[CustomFieldDefinitionApplyTo.CccHazard],
				FilterDomain.CCCHAZARD
			)
		);

		this.fieldStateDefinitions[FilterDomain.CCCSITE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.CCCHAZARD] = fieldStates;
	}

	private initIppAuthorityFieldStateDefinitions() {
		const fieldStates: FilterState[] = [];
		fieldStates.push(
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('ipp.auditLog.auditLogs'),
					FilterDomainName.ippCromerrAuditLog,
					this.auditLogFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppAuditLogs)
				),
				[],
				FilterDomain.IPPCROMERRAUDITLOG
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.filterDisplayName.allReportPackage'
					),
					FilterDomainName.ippAllReportPackages,
					this.allReportPackageFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages)
				),
				[],
				FilterDomain.IPPALLREPORTPACKAGE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('ipp.industries.industryPageHeading'),
					FilterDomainName.ippIndustry,
					this.industryFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppIndustries)
				),
				[],
				FilterDomain.IPPINDUSTRY
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.filterDisplayName.submittedPendingReviewReportPackage'
					),
					FilterDomainName.ippSubmittedPendingReviewReportPackages,
					this.submittedPendingReviewReportPackageFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages)
				),
				[],
				FilterDomain.IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.filterDisplayName.repudiatedPendingReviewReportPackage'
					),
					FilterDomainName.ippRepudiatedPendingReviewReportPackages,
					this.repudiatedPendingReviewReportPackageFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages)
				),
				[],
				FilterDomain.IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE
			)
		);

		this.fieldStateDefinitions[FilterDomain.IPPCROMERRAUDITLOG] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPALLREPORTPACKAGE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPINDUSTRY] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE] = fieldStates;
	}

	private initIppIndustryFieldStateDefinitions() {
		const fieldStates: FilterState[] = [];
		fieldStates.push(
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.filterDisplayName.submittedReportPackage'
					),
					FilterDomainName.ippSubmittedReportPackages,
					this.submittedReportPackageFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages)
				),
				[],
				FilterDomain.IPPSUBMITTEDREPORTPACKAGE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.filterDisplayName.repudiatedReportPackage'
					),
					FilterDomainName.ippRepudiatedReportPackages,
					this.repudiatedReportPackageFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages)
				),
				[],
				FilterDomain.IPPREPUDIATEDREPORTPACKAGE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('ipp.attachments.pageHeader'),
					FilterDomainName.ippFileStore,
					urlService.isReportPackageDetailsPage()
						? this.reportPackageAttachmentFields
						: this.attachmentFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppFileStores)
				),
				[],
				FilterDomain.IPPFILESTORE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString('ipp.samples.samplesReported'),
					FilterDomainName.ippReportedSample,
					this.reportedSampleFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples)
				),
				[],
				FilterDomain.IPPREPORTEDSAMPLE
			),
			new FilterState(
				new DomainObject(
					localizationService.getLocalizedString(
						'ipp.reportPackage.package.packageElement.sampleAndResultTypes'
					),
					FilterDomainName.ippSample,
					this.reportPackageSampleFields,
					urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples)
				),
				[],
				FilterDomain.IPPSAMPLE
			)
		);

		this.fieldStateDefinitions[FilterDomain.IPPREPORTEDSAMPLE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPSAMPLE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPFILESTORE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPSUBMITTEDREPORTPACKAGE] = fieldStates;
		this.fieldStateDefinitions[FilterDomain.IPPREPUDIATEDREPORTPACKAGE] = fieldStates;
	}

	private constructor() {
		this.initFieldStateDefinitions();
		this.initOperators();
	}

	public static get Instance() {
		// Do you need arguments? Make it a regular static method instead.
		return this._instance || (this._instance = new this());
	}

	public updateCustomFields(customFields: CustomFieldDefinition[]) {
		this.customFields = customFields || [];
	}

	public init(
		filterDomain: FilterDomain,
		emptyDomainObjectName: FilterDomainName,
		applyTo: FilterObjectName,
		filterUrlStorageName: LocalStorageName,
		domainsApplied: { [index: string]: boolean }
	) {
		// @ts-ignore
		this.fieldStates = this.fieldStateDefinitions[filterDomain];
		this.applyTo = applyTo;
		this.emptyDomainObjectName = emptyDomainObjectName;
		this.filterUrlStorageName = filterUrlStorageName;

		this.domainObjectsForLoadFieldDefinition = [];
		this.supportedDomainObjectNames = [];
		this.domainsApplied = domainsApplied;
		for (let field of this.fieldStates) {
			if (this.domainsApplied.hasOwnProperty(field.domainObjectName)) {
				this.domainObjectsForLoadFieldDefinition.push(field.domainObject);
				this.supportedDomainObjectNames.push(field.domainObjectName);
			}
		}
		this.emptyProperty = new FilterProperty({ label: emptyDomainObjectName, name: '' });
	}

	private initOperators() {
		this.operatorsForNumeric = [
			new Operator(localizationService.getLocalizedString('screen.filterModal.eq'), OperatorCode.Eq),
			new Operator(localizationService.getLocalizedString('screen.filterModal.neq'), OperatorCode.NotEqual),
			new Operator(localizationService.getLocalizedString('screen.filterModal.lth'), OperatorCode.LessThan),
			new Operator(
				localizationService.getLocalizedString('screen.filterModal.ltheq'),
				OperatorCode.LessThanEqual
			),
			new Operator(localizationService.getLocalizedString('screen.filterModal.gtr'), OperatorCode.GreaterThan),
			new Operator(
				localizationService.getLocalizedString('screen.filterModal.gtreq'),
				OperatorCode.GreaterThanEqual
			),
			new Operator(localizationService.getLocalizedString('screen.filterModal.betw'), OperatorCode.IsBetween)
		].sort(this.defaultFilterElementStringSort);

		this.filterTypeOperators.set(FilterType.Enum, [
			new Operator(localizationService.getLocalizedString('screen.filterModal.is'), OperatorCode.Is),
			new Operator(localizationService.getLocalizedString('screen.filterModal.isNot'), OperatorCode.IsNot)
		]);

		this.filterTypeOperators.set(FilterType.Text, [
			new Operator(localizationService.getLocalizedString('screen.filterModal.is'), OperatorCode.Is),
			new Operator(localizationService.getLocalizedString('screen.filterModal.isNot'), OperatorCode.IsNot),
			new Operator(localizationService.getLocalizedString('screen.filterModal.contains'), OperatorCode.Contains)
		]);

		this.filterTypeOperators.set(FilterType.Boolean, [
			new Operator(localizationService.getLocalizedString('screen.filterModal.is'), OperatorCode.Is),
			new Operator(localizationService.getLocalizedString('screen.filterModal.isNot'), OperatorCode.IsNot)
		]);

		this.filterTypeOperators.set(FilterType.Number, this.operatorsForNumeric);
		this.filterTypeOperators.set(FilterType.Integer, this.operatorsForNumeric);

		this.operatorForDateUI = [
			new Operator(localizationService.getLocalizedString('screen.filterModal.is'), OperatorCode.Is),
			new Operator(localizationService.getLocalizedString('screen.filterModal.betw'), OperatorCode.IsBetween),
			new Operator(localizationService.getLocalizedString('screen.filterModal.betw2'), OperatorCode.IsBetween2),
			new Operator(localizationService.getLocalizedString('screen.filterModal.lth'), OperatorCode.LessThan)
		];
		this.filterTypeOperators.set(FilterType.Date, [
			new Operator(localizationService.getLocalizedString('screen.filterModal.is'), OperatorCode.Is),
			new Operator(localizationService.getLocalizedString('screen.filterModal.betw'), OperatorCode.IsBetween),
			new Operator(localizationService.getLocalizedString('screen.filterModal.betw2'), OperatorCode.IsBetween2),
			new Operator(localizationService.getLocalizedString('screen.filterModal.eq'), OperatorCode.Eq),
			new Operator(localizationService.getLocalizedString('screen.filterModal.lth'), OperatorCode.LessThan),
			new Operator(
				localizationService.getLocalizedString('screen.filterModal.ltheq'),
				OperatorCode.LessThanEqual
			),
			new Operator(localizationService.getLocalizedString('screen.filterModal.gtr'), OperatorCode.GreaterThan),
			new Operator(
				localizationService.getLocalizedString('screen.filterModal.gtreq'),
				OperatorCode.GreaterThanEqual
			)
		]);
	}

	getAllDomainObject = () => {
		let customFieldDefinitions: CustomFieldDefinition[] = this.customFields;

		if (this.customFields.length === 0 && store) {
			let applicationState = store.getState();
			let definitions = applicationState.customFieldDefinitions;
			customFieldDefinitions = definitions.customFieldDefinitions;
		}

		const domainObjects: DomainObject[] = [];

		for (let fieldState of this.fieldStates) {
			if (!this.domainsApplied.hasOwnProperty(fieldState.domainObjectName)) {
				continue;
			}
			let combinedFields: FilterProperty[] = [];
			for (let property of fieldState.domainObject.properties) {
				if (!property.isCustomField) {
					combinedFields.push(property);
				}
			}

			if (customFieldDefinitions && customFieldDefinitions.length > 0 && fieldState.customFieldNames) {
				let customFields: FilterProperty[] = [];
				for (let customFieldName of fieldState.customFieldNames) {
					customFields = customFields.concat(
						customDefinedFieldService.getFilterProperties(customFieldName, customFieldDefinitions)
					);
				}
				combinedFields = combinedFields.concat(customFields).sort(this.defaultFilterElementStringSort);
			}
			domainObjects.push(
				new DomainObject(
					fieldState.domainObject.label,
					fieldState.domainObject.name,
					combinedFields,
					fieldState.domainObject.url
				)
			);
		}

		return domainObjects;
	};

	getOperatorsByFilterType = (filterType: FilterType, operatorName?: String) => {
		let supportedOperators = this.filterTypeOperators.get(filterType);
		if (!supportedOperators) {
			throw new Error(`No operators exist for filter type ${filterType}`);
		}

		let operators: Operator[] = [];
		if (filterType === FilterType.Date) {
			operators = [...this.operatorForDateUI];
		} else {
			operators = [...supportedOperators];
		}
		if (operatorName) {
			let index = _.findIndex(operators, o => String.equalCI(o.name, operatorName as string));
			if (index < 0) {
				//when passing operatorName does not exist from the UI operators, then try to find it and push from supported operators.
				let theOperator = _.find(supportedOperators, o => String.equalCI(o.name, operatorName as string));
				if (theOperator) {
					operators.push(theOperator);
				}
			}
		}
		return operators;
	};

	decodeHtmlCode(values: string[]) {
		const results: string[] = [];
		if (values) {
			for (let value of values) {
				results.push(value.replace(/&#44;/g, ','));
			}
		}

		return results;
	}

	getFilterValue = (filterItem: FilterItem, valueIndex: number): FilterValue => {
		if (filterItem.values && filterItem.values.length > valueIndex) {
			let values = filterItem.values[valueIndex];
			if (
				filterItem.operator &&
				(filterItem.operator.name === FilterOperator.Is || filterItem.operator.name === FilterOperator.IsNot) &&
				values.values
			) {
				if (values.values) {
					values.values = this.decodeHtmlCode(values.values);
				}
			}
			return values;
		}
		return { ...this.emptyFilterValue };
	};

	loadFilterOperator = (filterProperty: FilterProperty, operatorName: string): Operator => {
		let propertyTypeOperators = this.getOperatorsByFilterType(
			filterProperty.filterType as FilterType,
			operatorName
		);
		let operator = new Operator('', '');
		if (propertyTypeOperators) {
			let tempOperator = propertyTypeOperators.find(i => i.name === operatorName);
			if (tempOperator) {
				operator = tempOperator;
			}
		}
		return operator;
	};

	toFilterTypeFromCustomFieldType = (fieldType: string | undefined): FilterType => {
		if (fieldType === CustomFieldDataType.Date) {
			return FilterType.Date;
		}
		if (fieldType === CustomFieldDataType.Number) {
			return FilterType.Number;
		}
		return FilterType.Text;
	};

	loadFilterItems = (filterItems: FilterItem[], customFieldDefinitions: CustomFieldDefinition[]) => {
		let newFilterItems: FilterItem[] = [];
		filterItems.forEach((filterItem: FilterItem) => {
			let domainObjectInnerValue = _.get(filterItem, 'domainObject.name');
			let domainObject = this.domainObjectsForLoadFieldDefinition.find(i =>
				String.equalCaseInsensitive(i.name, domainObjectInnerValue)
			);
			if (!domainObject) {
				Logger.warn(`invalid filterItem which domainObject ${domainObjectInnerValue} no longer supported.`);
				return;
			}

			let propertyName = _.get(filterItem, 'property.name');
			let operatorName = _.get(filterItem, 'operator.name');
			if (filterItem.property.isCustomField === true) {
				let customFieldDefinition = customFieldDefinitions.find(
					c => _.toLower(c.fieldName) === _.toLower(propertyName)
				);
				if (customFieldDefinition) {
					let propertyType = this.toFilterTypeFromCustomFieldType(customFieldDefinition.fieldType);
					let property = new FilterProperty({
						label: this.emptyDomainObjectName,
						name: propertyName,
						filterType: propertyType
					});
					property.isCustomField = true;
					let operator = this.loadFilterOperator(property, operatorName);
					newFilterItems.push(
						new FilterItem(domainObject, property, operator, filterItem.values, filterItem.key)
					);
				} else {
					if (_.isEmpty(customFieldDefinitions)) {
						Logger.warn(`Cannot find custom field filterItem which property is ${propertyName}`);
					} else {
						Logger.warn(
							`invalid custom field filterItem which property ${propertyName} no longer supported.`
						);
					}
				}
			} else {
				let property = domainObject.properties.find(i => i.name === propertyName);
				if (!property) {
					throw new Error(`invalid filterItem which property ${propertyName} no longer supported.`);
				}
				let operator = this.loadFilterOperator(property, operatorName);
				newFilterItems.push(
					new FilterItem(domainObject, property, operator, filterItem.values, filterItem.key)
				);
			}
		});

		return newFilterItems;
	};

	resetFilterItem = (filterItem: FilterItem) => {
		let filterType = filterItem.property.filterType;
		switch (filterType) {
			case FilterType.Boolean:
			case FilterType.Enum:
			case FilterType.Text:
				filterItem.values = [{}];
				break;
			case FilterType.Number:
			case FilterType.Integer:
				if (filterItem.operator.name === OperatorCode.IsBetween) {
					filterItem.values = [{}, {}];
				} else {
					filterItem.values = [{}];
				}
				break;
			case FilterType.Date:
				if (
					filterItem.operator.name === OperatorCode.Is ||
					filterItem.operator.name === OperatorCode.LessThan
				) {
					filterItem.values = [{ value: dateFilterBetweenOptions[0].value }, {}];
				} else if (filterItem.operator.name === OperatorCode.IsBetween) {
					filterItem.values = [
						{ value: dateFilterBetweenOptions[0].value },
						{},
						{ value: dateFilterBetweenOptions[0].value },
						{}
					];
				} else if (filterItem.operator.name === OperatorCode.IsBetween2) {
					filterItem.values = [
						{},
						{ value: dateFilterBetweenRangeOptions[0].value },
						{},
						{ value: dateFilterBetweenRangeOptions[0].value }
					];
				} else {
					throw new Error(`Date filter with Operator ${filterItem.operator.name} is unsupported`);
				}
				break;
			default:
				throw new Error(`FilterType ${FilterType.Date} is unsupported`);
		}
	};

	validateFilterItem = (filterItem: FilterItem) => {
		validationService.validateRequiredField(filterItem.domainObject, 'name', 'error');
		validationService.validateRequiredField(filterItem.property, 'name', 'error');
		validationService.validateRequiredField(filterItem.operator, 'name', 'error');

		let filterType = filterItem.property.filterType;
		switch (filterType) {
			case FilterType.Boolean:
			case FilterType.Enum:
			case FilterType.Text:
				validationService.validateRequiredField(filterItem.values[0], 'values', 'error');
				break;
			case FilterType.Number:
			case FilterType.Integer:
				validationService.validateRequiredField(filterItem.values[0], 'value', 'error');
				validationService.validateIntegerNumberField(filterItem.values[0], 'value', 'error', true);
				if (filterItem.operator.name === OperatorCode.IsBetween) {
					validationService.validateRequiredField(filterItem.values[1], 'value', 'error');
					validationService.validateIntegerNumberField(filterItem.values[1], 'value', 'error', true);
				}
				break;
			case FilterType.Date:
				if (
					filterItem.operator.name === OperatorCode.Is ||
					filterItem.operator.name === OperatorCode.LessThan
				) {
					validateDateFilterIs(filterItem);
				} else if (filterItem.operator.name === OperatorCode.IsBetween) {
					validateDateFilterBetween(filterItem);
				} else if (filterItem.operator.name === OperatorCode.IsBetween2) {
					validateDateFilterBetweenRange(filterItem);
				} else {
					throw new Error(`Date filter with Operator ${filterItem.operator.name} is unsupported`);
				}
				break;
			default:
				throw new Error(`FilterType ${FilterType.Date} is unsupported`);
		}
	};

	isFilterItemInvalid = (filterItem: FilterItem): boolean => {
		if (filterItem.domainObject.error || filterItem.property.error || filterItem.operator.error) {
			return true;
		}
		if (filterItem.values && filterItem.values.some(v => !_.isEmpty(v.error))) {
			return true;
		}
		return false;
	};

	loadFilterDefinitions = (
		draftFilterId: number | undefined,
		filterId: number | undefined,
		filterName: string,
		filterItems: FilterItem[],
		customFieldDefinitions: CustomFieldDefinition[]
	) => {
		return new FilterDefinition(
			this.loadFilterItems(filterItems, customFieldDefinitions),
			draftFilterId,
			filterId,
			filterName
		);
	};

	getNextFilterName = () => {
		const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
		const nextFilterNameUrl = `${draftFilterUrl}/NextFilterName`;
		return apiService.getResource<string>(nextFilterNameUrl);
	};

	clearFilter = async () => {
		this.updateClearFilterUrl();
		let queryDict = urlService.toQueryDictionary();
		let draftFilterId = queryDict.draftFilterId;
		//IF it is draft Filter, clear from database
		if (draftFilterId) {
			const draftFilterUrl = urlService.getAuthorityResourcesApiUrl(Resource.DraftFilters);
			const draftFilterToDeleteUrl = `${draftFilterUrl}/${draftFilterId}`;
			await apiService.deleteResource(draftFilterToDeleteUrl);
		}
	};

	updateClearFilterUrl = () => {
		let queryDict = urlService.toQueryDictionary();
		_.unset(queryDict, 'draftFilterId');
		_.unset(queryDict, 'filterId');
		if (!urlService.isImportResults()) {
			const itemId = this.getGridFileUrlName();
			localStorageService.removeLocalStorage(itemId);
		}
		urlService.setUrlQueryString(queryDict, 'UnFilteredGrid');
	};

	updateSavedDraftFilterUrl = (draftFilterId?: number) => {
		let newUrl = `${window.location.href}`;
		let queryDict = urlService.toQueryDictionary();
		_.unset(queryDict, 'filterId');
		_.set(queryDict, 'draftFilterId', draftFilterId);

		let search = urlService.toQueryString(queryDict);
		newUrl = `${window.location.origin}${window.location.pathname}?${search}`;

		if (!urlService.isImportResults()) {
			const itemId = this.getGridFileUrlName();
			localStorageService.setLocalStorage(itemId, newUrl);
		}
		urlService.setUrlQueryString(queryDict, 'FilteredGrid', newUrl);
	};

	updateSavedFilterUrl = (filterId?: number) => {
		let newUrl = `${window.location.href}`;
		let queryDict = urlService.toQueryDictionary();
		_.unset(queryDict, 'draftFilterId');
		_.set(queryDict, 'filterId', filterId);
		_.unset(queryDict, 'page');
		let search = urlService.toQueryString(queryDict);
		newUrl = `${window.location.origin}${window.location.pathname}?${search}`;
		if (!urlService.isImportResults()) {
			const itemId = this.getGridFileUrlName();
			localStorageService.setLocalStorage(itemId, newUrl);
		}
		urlService.setUrlQueryString(queryDict, 'FilteredGrid', newUrl);
	};

	clearCachedUrlItem = () => {
		let newUrl = `${window.location.href}`;
		let queryDict = urlService.toQueryDictionary();
		_.unset(queryDict, 'draftFilterId');
		_.unset(queryDict, 'filterId');
		let search = urlService.toQueryString(queryDict);
		newUrl = `${window.location.origin}${window.location.pathname}?${search}`;

		if (!urlService.isImportResults()) {
			const itemId = this.getGridFileUrlName();
			localStorageService.removeLocalStorage(itemId);
		}
		urlService.setUrlQueryString(queryDict, 'FilteredGrid', newUrl);
	};

	defaultFilterElementStringSort(i: IFilterElement, j: IFilterElement): number {
		if (_.toString(i.label) > _.toString(j.label)) return 1;
		return -1;
	}

	getGridFileUrlName(): string {
		const url = window.location.href;
		if (_.toLower(url).indexOf(_.toLower(GridTypes.Inspections)) > 0) {
			return LocalStorageName.InspectionGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.FogFacilities)) > 0) {
			return LocalStorageName.FacilityGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.CccSites)) > 0) {
			return LocalStorageName.CccSiteGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.CccHazards)) > 0) {
			return LocalStorageName.CccHazardGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.Violations)) > 0) {
			return LocalStorageName.ViolationGridFilterUrl;
		}
		if (_.toLower(url).indexOf(_.toLower(Resource.Cleanings)) > 0) {
			return LocalStorageName.CleaningGridFilterUrl;
		}
		if (_.toLower(url).indexOf(_.toLower(Resource.Incidents)) > 0) {
			return LocalStorageName.IncidentsFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppAuditLogs)) > 0) {
			return LocalStorageName.IppAuditLogGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppIndustries)) > 0) {
			return LocalStorageName.IppIndustryGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppReportPackages + `/${ReportPackageStatus.All}`)) > 0) {
			return LocalStorageName.IppReportPackageAllGridFilterUrl;
		}

		if (
			_.toLower(url).indexOf(
				_.toLower(Resource.IppReportPackages + `/${ReportPackageStatus.SubmittedPendingReview}`)
			) > 0
		) {
			return LocalStorageName.IppReportPackageSubmittedPendingReviewGridFilterUrl;
		}

		if (
			_.toLower(url).indexOf(
				_.toLower(Resource.IppReportPackages + `/${ReportPackageStatus.RepudiatedPendingReview}`)
			) > 0
		) {
			return LocalStorageName.IppReportPackageRepudiatedPendingReviewGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppReportPackages + '/submitted')) > 0) {
			return LocalStorageName.IppReportPackageSubmittedGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppReportPackages + '/repudiated')) > 0) {
			return LocalStorageName.IppReportPackageRepudiatedGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower('attachment')) > 0) {
			return LocalStorageName.IppAttachmentGridFilterUrl;
		}

		if (_.toLower(url).indexOf(_.toLower(Resource.IppIndustrySamples + '/reported')) > 0) {
			return LocalStorageName.IppReportedSampleGridFilterUrl;
		}

		return '';
	}

	getCustomFields() {
		return this.customFields;
	}

	getFilterUrlStorageName() {
		return this.filterUrlStorageName;
	}

	htmlEncode = (field: FilterDefinition) => {
		for (let item of field.filterItems) {
			if (
				item.operator &&
				(item.operator.name === FilterOperator.Is || item.operator.name === FilterOperator.IsNot)
			) {
				for (let value of item.values) {
					let newValues = [];
					if (value.values && value.values.length > 0) {
						for (let v of value.values) {
							newValues.push(v.replace(/,/g, '&#44;'));
						}
						value.values = newValues;
					}
				}
			}
		}
		return field;
	};
}

export const filterService = FilterService.Instance;
