import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { EventType, Lookup } from '@rcp/types';
import { apiService, Resource, urlService } from 'src/services';
import _ from 'lodash';

export interface AuthorityEventTypesState {
	inspectionTypes: EventType[];
	eventCategories: Lookup[];
}

export const initialAuthorityEventTypesState: AuthorityEventTypesState = {
	inspectionTypes: [],
	eventCategories: []
};

enum ActionType {
	LoadAuthorityInspectionTypesSuccess = 'loadAuthorityInspectionTypesSuccess',
	LoadAuthorityEventCategoriesSuccess = 'LoadAuthorityEventCategoriesSuccess'
}

export type AuthorityEventTypesThunkAction = ThunkAction<any, AuthorityEventTypesState, any, Action>;

export const loadOneAuthorityInspectionTypes = async (eventTypeId: number, querystring = ''): Promise<EventType> => {
	let url = urlService.getAuthorityLookupResourceApiUrl(Resource.EventTypes);
	url = `${url}/${eventTypeId}`;

	if (!_.isEmpty(querystring)) {
		url += `?${querystring}`;
	}

	let authorityEventType = await apiService.getResource<EventType>(`${url}`);
	return authorityEventType;
};

export const loadAuthorityEventCategories = (): AuthorityEventTypesThunkAction => async (dispatch: any) => {
	let url = urlService.getAuthorityEventCategoryUrl();

	let eventCategories = await apiService.getResource<Lookup[]>(url);
	dispatch({ type: ActionType.LoadAuthorityEventCategoriesSuccess, eventCategories });
};

export const loadAuthorityInspectionTypes = (): AuthorityEventTypesThunkAction => async (dispatch: any) => {
	let url = urlService.getAuthorityLookupResourceApiUrl(Resource.InspectionEventTypes);

	let authorityInspectionTypes = await apiService.getResource<EventType[]>(url);
	dispatch({ type: ActionType.LoadAuthorityInspectionTypesSuccess, authorityInspectionTypes });
};

export const authorityEventTypesReducer = (
	state = initialAuthorityEventTypesState,
	action: any
): AuthorityEventTypesState => {
	switch (action.type) {
		case ActionType.LoadAuthorityEventCategoriesSuccess:
			let eventCategories = action.eventCategories as Lookup[];
			let newState = { ...state, eventCategories };
			return { ...newState };
		case ActionType.LoadAuthorityInspectionTypesSuccess:
			return { ...state, inspectionTypes: action.authorityInspectionTypes };
		default:
			return state;
	}
};
