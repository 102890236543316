import { IppAASIndustrialPretreatmentSettingsState, initialIppAASIndustrialPretreatmentSettingsState } from './aas-industrial-pretreatment-settings-state';
import { IppAASIndustrialPretreatmentSettingsAction } from './aas-industrial-pretreatment-settings-action-creators';
import EIppAASIndustrialPretreatmentSettingsActionType from './aas-industrial-pretreatment-settings-action-types';

export const ippAASIndustrialPretreatmentSettingsReducer = (
	state = initialIppAASIndustrialPretreatmentSettingsState,
	action: IppAASIndustrialPretreatmentSettingsAction
): IppAASIndustrialPretreatmentSettingsState => {
	switch (action.type) {
		case EIppAASIndustrialPretreatmentSettingsActionType.FetchIppAASAuthoritySettingsData:
		case EIppAASIndustrialPretreatmentSettingsActionType.FetchAttachmentTypes:
		case EIppAASIndustrialPretreatmentSettingsActionType.UpdateIppAASAuthoritySettingsData:
		case EIppAASIndustrialPretreatmentSettingsActionType.ToggleSaveButton:
		case EIppAASIndustrialPretreatmentSettingsActionType.SaveIppAASAuthoritySettingsData:
			return {...state, ...action.payload};
		default:
			return state;
	}
};
