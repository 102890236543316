import React from 'react';
import _ from 'lodash';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { DropDownOption, FogFacility } from '@rcp/types';
import { loadCurrentFogFacility, alertService } from 'src/redux';
import { useDispatch } from 'react-redux';
import { SegmentedControl } from 'src/components/widgets';

interface OwnProps {
	riskScore?: string;
	riskScoreNote?: string;
}

type Props = OwnProps;

export const RiskScoreRightPanel: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [editMode, setEditMode] = React.useState(false);
	const [selectedRiskScoreValue, setSelectedRiskScoreValue] = React.useState<string>();
	const [selectedRiskScoreNoteValue, setSelectedRiskScoreNoteValue] = React.useState<string>();

	const riskScoreDropDown: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('facility.riskScoreLow'),
			value: localizationService.getLocalizedString('facility.riskScoreLow')
		},
		{
			label: localizationService.getLocalizedString('facility.riskScoreMedium'),
			value: localizationService.getLocalizedString('facility.riskScoreMedium')
		},
		{
			label: localizationService.getLocalizedString('facility.riskScoreHigh'),
			value: localizationService.getLocalizedString('facility.riskScoreHigh')
		}
	];

	React.useEffect(() => {
		setSelectedRiskScoreValue(props.riskScore);
		setSelectedRiskScoreNoteValue(props.riskScoreNote);
	}, [props.riskScore, props.riskScoreNote]);

	const onToggleShowModal = (e: any) => {
		e.preventDefault();

		setEditMode(!editMode);
	};

	const changeRiskScoreState = (selectedValue: string) => {
		setSelectedRiskScoreValue(selectedValue);
	};

	const changeRiskScoreNoteState = (e: any) => {
		let selectedValue = e.target.value;
		setSelectedRiskScoreNoteValue(selectedValue);
	};

	const saveRiskScore = async (e: any) => {
		let facilityToUpdate: FogFacility = {
			riskScore: selectedRiskScoreValue,
			riskScoreNote: selectedRiskScoreNoteValue
		};

		let facilityId = urlService.getFogFacilityId();

		const facilityUrl = urlService.getAuthorityResourceApiUrl(Resource.FogFacilities, facilityId);

		await apiService.patchResource<FogFacility>(facilityUrl, facilityToUpdate);
		dispatch(loadCurrentFogFacility());

		let successMessage = localizationService.getLocalizedString(
			'alertMessages.updateSuccess',
			localizationService.getLocalizedString('facility.riskScore')
		);

		if (selectedRiskScoreValue) {
			successMessage = localizationService.getLocalizedString(
				'facility.riskScoreApplied',
				selectedRiskScoreValue as string
			);
		}
		alertService.addSuccess(successMessage);

		onToggleShowModal(e);
	};

	const componentForEditMode = (
		<>
			<SegmentedControl
				id={'RiskScoreSegmentedControl'}
				name={'RiskScoreSegmentedControl'}
				options={riskScoreDropDown}
				value={selectedRiskScoreValue}
				onChange={changeRiskScoreState}
			/>

			<div className="form-group risk-score-editor mt-3">
				<label>{localizationService.getLocalizedString('facility.riskScoreNote')}</label>
				<RichTextEditor
					editorContent={props.riskScoreNote}
					onChange={changeRiskScoreNoteState}
					height="200px"
					editorIndex={0}
					isFixedHeight
					useBasicTools
				/>
			</div>
			<div className="d-flex justify-content-end">
				<button type="button" className="btn ai-action" onClick={e => saveRiskScore(e)}>
					{localizationService.getLocalizedString('screen.buttons.apply')}
				</button>
				<button type="button" className="ml-2 btn ai-white" onClick={e => onToggleShowModal(e)}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		</>
	);

	const addRiskScoreComponent = (
		<div>
			<a id="addRiskScore" href="#/" className="ai-add-link" onClick={e => onToggleShowModal(e)}>
				{localizationService.getLocalizedString('facility.addRiskScore')}…
			</a>
		</div>
	);

	const riskScoreDetailsComponent = (
		<>
			{props.riskScore && (
				<SegmentedControl
					id={'RiskScoreSegmentedControl'}
					name={'RiskScoreSegmentedControl'}
					options={riskScoreDropDown}
					value={selectedRiskScoreValue}
					readonly={true}
				/>
			)}

			{props.riskScoreNote && (
				<div className="form-group mt-3">
					<span
						id={'riskScoreNote'}
						dangerouslySetInnerHTML={{
							__html: props.riskScoreNote
						}}
					/>
				</div>
			)}
		</>
	);

	const componentForNonEditMode =
		props.riskScore || props.riskScoreNote ? riskScoreDetailsComponent : addRiskScoreComponent;

	return (
		<>
			<section>
				<div id="riskScore" className="d-flex">
					<h3>{localizationService.getLocalizedString('facility.riskScore')}</h3>
					{(props.riskScore || props.riskScoreNote) && !editMode && (
						<a href="#/" className="font-size-16px ai-edit-link ml-auto mt-n1" onClick={onToggleShowModal}>
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					)}
				</div>

				{editMode ? componentForEditMode : componentForNonEditMode}
			</section>
		</>
	);
};
