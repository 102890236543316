import React from 'react';
import { localizationService, urlService } from 'src/services';
import { DataImport, ImportConfiguration, Lookup, RegulatoryProgramName, RouteProps } from '@rcp/types';
import { CustomizedLookupDataConverter } from 'src/components/widgets/lookup/data-converters';
import { CustomizedSettingUrlResolver, ListSetting, LookupRow, ResourceAction } from 'src/components/widgets';
import { AccessDeniedPage } from '../../../home/access-denied';
import { ImportUtils } from 'src/services/import-utils';
import { NotFound } from 'src/features/home/not-found';
import _ from 'lodash';

export const ImportSettingsComponent: React.FC<RouteProps> = props => {
	if (!urlService.isAdministrator()) {
		return <AccessDeniedPage />;
	}
	//@ts-ignore
	else if (
		urlService.isProgram(RegulatoryProgramName.FOG) &&
		!DataImport.ImportDataTypeValues[props.match.params.dataType]
	) {
		return <NotFound />;
	}

	const getLookupSettingOverride = (jsonPath: string) => {
		if (!urlService.isProgram(RegulatoryProgramName.FOG)) {
			return undefined;
		}

		return (lookup: LookupRow) => localizationService.getLocalizedString(jsonPath, _.toString(lookup.code));
	};

	const getImportConfigurationsSection = () => {
		let defaultConfigurationLookupRow: LookupRow = {
			lookupId: -1,
			code: localizationService.getLocalizedString('cccImportFile.Default'),
			isActive: true,
			isInUse: true,
			isSystem: true
		} as LookupRow;
		let extraLookupRows: LookupRow[] = [];
		if (!urlService.isProgram(RegulatoryProgramName.FOG)) {
			extraLookupRows = [defaultConfigurationLookupRow];
		}
		let dataConverter = new CustomizedLookupDataConverter<ImportConfiguration>(
			(row: Lookup) => {
				return {
					name: row.code,
					isActive: row.isActive
				} as ImportConfiguration;
			},
			(dto: ImportConfiguration) => {
				return {
					lookupId: dto.importConfigurationId,
					code: dto.name,
					isActive: dto.isActive,
					isInUse: false
				} as LookupRow;
			},
			extraLookupRows
		);
		let urlResolver = new CustomizedSettingUrlResolver(
			urlService.getAuthoritySettingResourceApiUrl('AuthoritySettings/ImportConfigurations'),
			urlService.isProgram(RegulatoryProgramName.FOG)
				? `dataType=${props.match.params.dataType}&size=100000&includeInactive=true&isDraft=false`
				: 'includeInactive=true&size=100000'
		);
		return (
			<ListSetting
				description={
					urlService.isProgram(RegulatoryProgramName.FOG)
						? localizationService.getLocalizedString(
								'authoritySetting.importDataSourceSettings.description',
								_.toLower(ImportUtils.getImportDataTypeWithSpaces(props.match.params.dataType))
						  )
						: localizationService.getLocalizedString(
								'cccImportFile.settings.importConfigurations.description'
						  )
				}
				settingId="importConfiguration"
				settingLabel={
					urlService.isProgram(RegulatoryProgramName.FOG)
						? localizationService.getLocalizedString('authoritySetting.importDataSourceSettings.title')
						: localizationService.getLocalizedString('cccImportFile.settings.importConfigurations.name')
				}
				hideAddButton={true}
				settingAction={new ResourceAction(urlResolver, dataConverter)}
				deleteMessage={getLookupSettingOverride('authoritySetting.importDataSourceSettings.deleteModalMessage')}
				deleteSucceedMessage={getLookupSettingOverride(
					'authoritySetting.importDataSourceSettings.deleteSucceedMessage'
				)}
				updateSucceedMessage={getLookupSettingOverride(
					'authoritySetting.importDataSourceSettings.updateSucceedMessage'
				)}
				overridenEditModalTitle={
					urlService.isProgram(RegulatoryProgramName.FOG)
						? localizationService.getLocalizedString(
								'authoritySetting.importDataSourceSettings.editModalTitle'
						  )
						: undefined
				}
				overridenEditModalLabel={
					urlService.isProgram(RegulatoryProgramName.FOG)
						? localizationService.getLocalizedString(
								'authoritySetting.importDataSourceSettings.editModalInputLabel'
						  )
						: undefined
				}
			/>
		);
	};

	return (
		<div className="page">
			<div className="page-header">
				<h1>
					{urlService.isProgram(RegulatoryProgramName.FOG)
						? localizationService.getLocalizedString('authoritySetting.importDataSourceSettings.title')
						: localizationService.getLocalizedString('cccImportFile.settings.importConfigurations.title')}
				</h1>
			</div>
			<div className="page-wrapper">
				<div className="main settings">{getImportConfigurationsSection()}</div>
			</div>
		</div>
	);
};
