import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import * as Users from './users-actions';
import * as ApiTypes from '@rcp/types';
import { apiService, localizationService, Resource, tokenService, urlService } from 'src/services';
import { UsersState } from './users-state';
import { alertService } from '../../../../alert';

type UsersThunkAction = ThunkAction<any, UsersState, any, Users.UsersAction>;
const userStatus = {
	isAccountLocked: 'isAccountLocked',
	isEnabled: 'isEnabled',
	isRemoved: 'isRemoved',
	isAccountResetRequired: 'isAccountResetRequired',
	isSignatory: 'isSignatory',
	role: 'role'
};
const { isAccountLocked, isEnabled, isRemoved, isSignatory, isAccountResetRequired, role } = userStatus;

export const loadUserDetails = (ippUserId: number): UsersThunkAction => async (dispatch, getState) => {
	let fifthIndex = 4;
	let browserPath = window.location.pathname;
	let isIndustryModule = browserPath.includes('industries');
	let industryId = '';
	isIndustryModule && (industryId = browserPath.split('/')[fifthIndex]);
	urlService.isIppIndustryPortal() &&
		(industryId = tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId || '');
	dispatch(Users.loadUserDetailsRequest());
	let url = `${urlService.getAuthorityResourceApiUrl(Resource.IppUsers, ippUserId)}?includeInactive=true${
		urlService.isIppIndustryPortal() || isIndustryModule === true ? `&industryId=${industryId}` : ''
	}`;
	let user = await apiService.getResource<ApiTypes.IppUser>(url);
	dispatch(Users.loadUserDetailsSuccess(user));
};

export const updateUser = (
	user: ApiTypes.IppUser,
	ippUserId: number,
	callbackOnSuccess?: any,
	userName?: string
): UsersThunkAction => async (dispatch, getState) => {
	let browserPath = window.location.pathname;
	let isIndustryModule = browserPath.includes('industries');
	let industryId = '';
	isIndustryModule && (industryId = browserPath.split('/')[4]);
	if (urlService.isIppIndustryPortal() && tokenService.getTokenOrDefault()) {
		industryId = tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId || '';
	}
	let userMessage: string = '';
	let detailToBeChanged = _.keys(user)[0];
	switch (detailToBeChanged) {
		case isAccountLocked:
			userMessage = localizationService.getLocalizedString(
				`ipp.authorityAccount.${user.isAccountLocked ? 'userLockSuccessAlert' : 'userUnlockSuccessAlert'}`
			);
			break;
		case isEnabled:
			userMessage = localizationService.getLocalizedString(
				`ipp.authorityAccount.${user.isEnabled ? 'userEnableSuccessAlert' : 'userDisableSuccessAlert'}`
			);
			break;
		case isRemoved:
			userMessage = localizationService.getLocalizedString(
				'alertMessages.deleteUserSuccess',
				userName || '',
				tokenService.getTokenOrDefault()!.portalOrganizationName || ''
			);
			break;
		case isAccountResetRequired:
			userMessage = localizationService.getLocalizedString('ipp.authorityAccount.userResetSuccessAlert');
			user.accountBaseUrl = urlService
				.getApiAccountBaseUrl()
				.split('/')[2]
				.replace('5000', '3000');
			break;
		case isSignatory:
			userMessage = localizationService.getLocalizedString(
				`ipp.industries.${user.isSignatory ? 'signatoryGranted' : 'signatoryRemoved'}`
			);
			break;
		case role:
			userMessage = localizationService.getLocalizedString('ipp.authorityAccount.userUpdateSuccessAlert');
			break;
		default:
			userMessage = localizationService.getLocalizedString('ipp.authorityAccount.userDetailsUpdateSuccessAlert');
			break;
	}
	const url = `${urlService.getAuthorityResourceApiUrl(Resource.IppUsers, ippUserId as number)}${
		isIndustryModule === true || urlService.isIppIndustryPortal() ? `?industryId=${industryId}` : ''
	}`;
	let userData: ApiTypes.IppUser = await apiService.httpPatch(url, user);
	alertService.addSuccess(userMessage);
	const loggedInUserId = (getState() as any).userProfile.userProfile.userProfileId;
	if (detailToBeChanged === role && loggedInUserId === userData.userProfileId) {
		updateRoleInLocalStorage(user);
	}
	if (callbackOnSuccess) {
		callbackOnSuccess();
	} else {
		dispatch(loadUserDetails(ippUserId as number));
	}
	return userData;
};

const updateRoleInLocalStorage = (user: ApiTypes.IppUser) => {
	let token = tokenService.getTokenOrDefault();
	let currentProgram = urlService.getProgramOrThrowError();
	let issuedPrograms = _.split(token.portalRegulatoryProgramNames as string, ',');
	let issuedRoles = _.split(token.portalPermissionGroupNames as string, ',');
	for (let index = 0; index < issuedPrograms.length; index++) {
		if (_.toLower(issuedPrograms[index]) === _.toLower(currentProgram)) {
			issuedRoles[index] = user.role || issuedRoles[index];
		}
	}
	tokenService.setToken({ ...token, portalPermissionGroupNames: issuedRoles.join(',') });
};
