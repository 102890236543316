import React, { FC, useState } from 'react';
import { History } from 'history';
import { ippSamplesSlice } from './samples.slice';
import * as LinkoTypes from '@rcp/types';
import { IppIndustrySample, ColumnField, FilterType, LocalStorageName } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { alertService, RootState } from 'src/redux';
import { localizationService, urlService, Resource, navigateTo, apiService } from 'src/services';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { Utils } from 'src/services/utils';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const SampleStatusName = 'Draft';
const IppIndustrySamplesComponent: FC<Props<IppIndustrySample>> = props => {
	const [selectedSamples, setSelectedSamples] = useState([] as IppIndustrySample[]);
	const [selectedSamplesIds, setSelectedSamplesIds] = useState([] as number[]);
	const [isDeleted, setIsDeleted] = useState(false);
	const [isReadyToReported, setIsReadyToReported] = useState(false);
	const [validationErrors, setValidationErrors] = useState<any[]>([]);
	const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
	const dispatch = useDispatch();
	const sampleTypeToLink = (item: any) => {
		const dataItem = item.dataItem as IppIndustrySample;
		const url = urlService.getIppIndustryUrl(`samples/${item.dataItem.sampleId}/details`);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.ctsEventTypeName}
				</a>
			</td>
		);
	};

	const bulkDeleteCallback = (response: any) => {
		setIsDeleted(true);
		alertService.addSuccess(
			localizationService.getLocalizedString(
				`ipp.samples.${
					response.deletedRows > 1 ? 'bulkDeleteSuccessfullyMultiple' : 'bulkDeleteSuccessfullySingle'
				}`,
				response.deletedRows
			)
		);
		toggleDeleteModal();
		navigateTo(props.history, window.location.href);
	};

	const onBulkDelete = () => {
		alertService.clearAllMessages();
		dispatch(
			ippSamplesSlice.deleteAll(
				`sampleId=${selectedSamplesIds.toString()}`,
				true,
				undefined,
				(response: any) => bulkDeleteCallback(response),
				true
			)
		);
	};

	const onBulkReadyToReport = async () => {
		alertService.clearAllMessages();
		let samples = _.cloneDeep(selectedSamples);
		samples.forEach((sample: IppIndustrySample) => {
			sample.isReadyToReport = true;
		});
		const url = urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples);
		try {
			let response = await apiService.httpPut(url, samples);
			if (response.errors && response.errors.length) {
				alertService.addError(localizationService.getLocalizedString(response.errorMessage));
				setValidationErrors(response.errors);
			}
			if (response.updatedRows > 0) {
				setIsReadyToReported(true);
				alertService.addSuccess(
					localizationService.getLocalizedString(
						`ipp.samples.${
							response.updatedRows > 1
								? 'bulkReadyToReportSuccessMultiple'
								: 'bulkReadyToReportSuccessSingle'
						}`,
						response.updatedRows
					)
				);
				navigateTo(props.history, window.location.href);
			}
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const toggleDeleteModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('ipp.samples.samplesDraft'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: true,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('ipp.buttons.createNew'),
		toggleAddModal: () => {
			const url = urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/new/step1`);
			navigateTo(props.history, url);
		},
		multiButtons: [
			{
				text: localizationService.getLocalizedString('ipp.samples.import'),
				id: 'import-sample',
				action: () => {
					navigateTo(props.history, urlService.getIppIndustryUrl(`samples/import`));
				}
			}
		],
		showScheduleInspectionModal: false,
		queryParameters: { SampleStatusName, sort: 'startDateTimeLocal asc' },
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		editMode: {
			id: 'sample-edit-mode',
			editModeMultiButtons: [
				{
					text: localizationService.getLocalizedString('ipp.buttons.delete'),
					id: 'bulk-delete',
					className: 'ai-delete',
					action: () => {
						toggleDeleteModal();
					}
				},
				{
					text: localizationService.getLocalizedString('ipp.samples.step2.readyToReport'),
					id: 'bulk-ready-to-report',
					action: () => {
						onBulkReadyToReport();
					}
				}
			],
			selectIdKey: 'sampleId',
			deleteItems: isDeleted,
			isCustomOperationPerformed: isReadyToReported,
			getSelected: (selectedItems: any) => {
				if (isDeleted) {
					setIsDeleted(false);
				}
				if (isReadyToReported) {
					setIsReadyToReported(false);
				}
				let sampleIds: number[] = [];
				for (let i = 0; i < selectedItems.length; i++) {
					sampleIds[i] = selectedItems[i]['sampleId'];
				}
				setSelectedSamplesIds(sampleIds);
				setSelectedSamples(selectedItems);
			},
			clearValidationErrorData: () => setValidationErrors([]),
			populateSelected: true,
			validationErrorData: validationErrors
		},
		showTabList: false,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		showRefreshButton: true,
		prefix: 'ipp.samples.columns',
		storageName: LocalStorageName.IppIndustrySamples,
		searchPlaceholder: '',
		showActions: false,
		allColumns: [
			new ColumnField('sampleId', FilterType.Integer),
			new ColumnField('name'),
			new ColumnField('monitoringPointName'),
			new ColumnField('ctsEventTypeName', FilterType.Text, sampleTypeToLink),
			new ColumnField('collectionMethodName'),
			new ColumnField('labSampleIdentifier'),
			new ColumnField('startDateTimeLocal', FilterType.Date),
			new ColumnField('endDateTimeLocal', FilterType.Date),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isAssociatedWithReportPackage', FilterType.Boolean),
			new ColumnField('isSampleExcludedFromReports', FilterType.Boolean)
		],

		defaultColumns: [
			'monitoringPointName',
			'ctsEventTypeName',
			'collectionMethodName',
			'startDateTimeLocal',
			'endDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName'
		]
	};
	const samplesState = (state: RootState) => state.ippIndustrySamples;

	const deleteModalProps: DeleteModalProp = {
		title: localizationService.getLocalizedString('ipp.samples.deleteModalTitle'),
		showModal: showDeleteModal,
		onOkayButtonClick: onBulkDelete,
		onCancelButtonClick: toggleDeleteModal,
		message: localizationService.getLocalizedString('ipp.samples.deleteModalMessage'),
		isDeleteButton: true,
		okayButtonText: localizationService.getLocalizedString('ipp.buttons.delete')
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippSamplesSlice}
				restState={samplesState}
				gridOption={initialGridOption}></DataGrid>
			<DeleteModal {...deleteModalProps} />
		</>
	);
};

export default IppIndustrySamplesComponent;
