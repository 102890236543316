import React, { FC } from 'react';
import { ReactComponent as InlineInfo } from 'src/assets/img/inline-info.svg';
import { CloseSvg } from 'src/components';

interface Props {
	message?: string | JSX.Element;
	icon?: JSX.Element;
	className?: string;
	showCloseButton?: boolean;
	onClose?: () => void;
	svgClassName?: string;
}

export const Notification: FC<Props> = props => {
	let defaultClassName = props.className || 'notification';
	let svgClassName = props.svgClassName || '';
	return (
		<div className={` ${defaultClassName} `}>
			<div className="d-flex align-items-center justify-content-between py-2 px-2">
				<div className={`${svgClassName} d-flex notices-stepper-info`}>
					<div>{props.icon ? props.icon : <InlineInfo />}</div>
					<span className="ml-2">{props.message}</span>
				</div>
				{props.showCloseButton && (
					<button className="float-right" onClick={props.onClose}>
						<CloseSvg />
					</button>
				)}
			</div>
		</div>
	);
};
