import {
	ColumnField,
	Dictionary,
	DropDownOption,
	FilterType,
	FogPendingNotice,
	LocalStorageName,
	NoticeStatus,
	PendingNoticeBatchDto,
	SentNoticeHistory
} from '@rcp/types';
import { RouteProps } from '@rcp/types/src';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { History } from 'history';
import { fogPendingNoticeSlice } from './pending-notices-slice';
import { CancelSendSvg, PopoverModal, SendSvg, SingleSelectDropdown } from 'src/components';
import { DataGrid, DataGridContext, GridOption } from 'src/features';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { alertService, RootState } from 'src/redux';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import EmailModal from 'src/components/widgets/preview-email-notice';
import { useDispatch, useSelector } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { fogNoticesHistoriesSlice } from './notices-history-slice';
import './pending-notices.scss';
import { Utils } from 'src/services/utils';

interface NoticeTypeProps {
	noticeType: string;
	onChange?: (event: any) => void;
	noticeOptions: DropDownOption[];
}

interface Props extends RouteProps {
	history: History;
}

const NoticeTypeDropdown: React.FC<NoticeTypeProps> = props => {
	const dataGridContext = React.useContext(DataGridContext);

	const onChange = (event: any) => {
		const { value } = event.target;
		if (!value) return;
		props.onChange && props.onChange(event);
		let gridState = dataGridContext.getGridState();
		let newQueryParameters: Dictionary<string> = {};
		if (gridState && gridState.queryParameters) {
			newQueryParameters = { ...gridState.queryParameters };
		}
		let templateId = _.toNumber(value);
		if (templateId > 0) {
			_.set(newQueryParameters, 'templateId', templateId);
		} else {
			_.unset(newQueryParameters, 'templateId');
		}
		dataGridContext.setGridState({ ...gridState, queryParameters: newQueryParameters });
	};

	return (
		<div className="d-flex">
			<SingleSelectDropdown
				id="sendNoticeType"
				name="sendNoticeType"
				label={localizationService.getLocalizedString('notices.send')}
				value={props.noticeType}
				isRequired={true}
				noEmptyOption={true}
				options={props.noticeOptions}
				onChange={onChange}
				selfOrder={true}
			/>
		</div>
	);
};

const allNoticesOption: DropDownOption = {
	label: localizationService.getLocalizedString('ccc.notices.sendAllNotices'),
	value: '0'
};

export const FogPendingNoticeGrid: React.FC<Props> = props => {
	const [noticeType, setNoticeType] = React.useState('0');
	const [showSendModal, setShowSendModal] = React.useState(false);
	const [showPreviewModal, setShowPreviewModal] = React.useState(false);
	const [emailDetails, setEmailDetails] = React.useState<any>({});
	const [templateOptions, setTemplateOptions] = useState<DropDownOption[]>([allNoticesOption]);
	const [numberOfLetters, setNumberOfLetters] = React.useState<number>(0);
	const [noticeBatchId, setNoticeBatchId] = React.useState<string>();
	const [contactsWithIncompleteAddress, setContactsWithIncompleteAddress] = React.useState<number>(0);
	const [numberOfLettersMarkedDoNotSend, setNumberOfLettersMarkedDoNotSend] = React.useState<number>(0);
	const [showRefreshModal, setShowRefreshModal] = React.useState<boolean>(false);
	const dispatch = useDispatch();

	let { result: pendingNotices } = useSelector((state: RootState) => state.fogPendingNotices);

	useEffect(() => {
		fetchDropdownData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let dataWithNoEmail = [];
		if (pendingNotices) {
			pendingNotices.map((notice: FogPendingNotice) => {
				if (!notice.emailAddress) {
					dataWithNoEmail.push(notice);
				}
			});
			setContactsWithIncompleteAddress(dataWithNoEmail.length);
			setNumberOfLetters(pendingNotices.length);
			if (pendingNotices.length > 0) {
				let currentBatchId = pendingNotices[0].pendingNoticeBatchId;
				setNoticeBatchId(currentBatchId);
			}
			setNumberOfLettersMarkedDoNotSend(pendingNotices.filter(i => i.doNotSend && i.emailAddress).length);
		}
	}, [pendingNotices]);

	const fetchDropdownData = () => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.SettingNoticeTemplateSchedule}?showScheduledNoticeTemplateOnly=true&isAddedToOutboxOnScheduledDay=true`
		);
		apiService
			.getResource(url)
			.then((data: any) => {
				let options = [allNoticesOption];
				data.map((template: any) => {
					options.push({
						label: template.templateName,
						value: template.noticeTemplateScheduleId
					});
				});
				let selectedTemplate = urlService.getUrlQueryParameter('templateId', '0') as string;
				const noticeTemplates = options.map(option => +option.value);
				if (!noticeTemplates.includes(+selectedTemplate)) {
					urlService.removeUrlQueryParam('templateId');
					dispatch(
						fogPendingNoticeSlice.fetchPage(urlService.toQueryDictionary(window.location.search), () => {})
					);
					selectedTemplate = '0';
				}
				setTemplateOptions(options);
				setNoticeType(selectedTemplate);
			})
			.catch(error => alertService.addError(error.message));
	};

	const onPreview = async (event: any, pendingNoticeId: number) => {
		event.preventDefault();
		const url = `${urlService.getAuthorityResourceApiUrl(`${Resource.CccNotices}`, pendingNoticeId)}/${
			Resource.Preview
		}`;
		apiService
			.getResource(url)
			.then((emailDetail: any) => {
				setEmailDetails({
					to: emailDetail.to,
					cc: emailDetail.cc,
					from: emailDetail.from,
					templateSubject: emailDetail.templateSubject,
					templateContent: emailDetail.templateContent
				});
				onTogglePreviewModal();
			})
			.catch(error => {
				alertService.addError(error.message);
			});
	};

	const onSetDoNotSent = async (
		event: any,
		dataItem: FogPendingNotice,
		pendingNoticeCleaningId: number,
		doNotSend: boolean
	) => {
		event.preventDefault();
		if (!dataItem && !pendingNoticeCleaningId) {
			return;
		}
		const url = `${urlService.getAuthorityResourceApiUrl(`${Resource.CccNotices}`, pendingNoticeCleaningId)}`;
		apiService
			.patchResource<FogPendingNotice>(url, {
				pendingNoticeCleaningId: pendingNoticeCleaningId,
				doNotSend: doNotSend
			})
			.then((updatedNotice: FogPendingNotice) => {
				dispatch(fogPendingNoticeSlice.reload());
			})
			.catch(error => {
				alertService.addError(error.message);
			});
	};

	const onTogglePreviewModal = () => setShowPreviewModal(!showPreviewModal);
	const actionsElement = (item: any) => {
		const dataItem: FogPendingNotice = item.dataItem || {};
		const pendingNoticeId = dataItem.pendingNoticeId || 0;
		const pendingNoticeCleaningId = dataItem.pendingNoticeCleaningId || 0;
		if (!dataItem.emailAddress) {
			return <td></td>;
		}
		return (
			<td>
				<div className="d-inline-block mr-3">
					<a href="#/" onClick={event => onPreview(event, pendingNoticeId)}>
						{localizationService.getLocalizedString('notices.preview')}
					</a>
				</div>
				<div className="d-inline-block">
					{dataItem.doNotSend === true ? (
						<a href="#/" onClick={event => onSetDoNotSent(event, dataItem, pendingNoticeCleaningId, false)}>
							{localizationService.getLocalizedString('notices.sendNoticeLabel')}
						</a>
					) : (
						<a href="#/" onClick={event => onSetDoNotSent(event, dataItem, pendingNoticeCleaningId, true)}>
							{localizationService.getLocalizedString('notices.doNotSendLabel')}
						</a>
					)}
				</div>
			</td>
		);
	};

	const iconElement = (item: any) => {
		let dataItem: FogPendingNotice = item.dataItem;
		return (
			<td>
				{dataItem.doNotSend ? (
					<CancelSendSvg title={localizationService.getLocalizedString('notices.doNotSendLabel')} />
				) : (
					<SendSvg title={localizationService.getLocalizedString('notices.sendNoticeLabel')} />
				)}
			</td>
		);
	};

	const textElement = (item: any) => {
		let dataItem: FogPendingNotice = item.dataItem;
		let tdProps = {};
		if (dataItem.doNotSend) {
			tdProps = {
				className: 'text-decoration-strike'
			};
		}
		return <td {...tdProps}>{_.get(dataItem, item.field)}</td>;
	};

	const deviceLink = (item: any) => {
		let dataItem: FogPendingNotice = item.dataItem;
		let url = urlService.getReactAuthorityResourcePath(Resource.Devices, dataItem.extractorId);
		return (
			<td>
				<a href="#/" onClick={(event: any) => navigateTo(props.history, url, event)}>
					{dataItem.deviceNumber}
				</a>
			</td>
		);
	};

	const onToggleSendNoticeModal = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		if (numberOfLetters - contactsWithIncompleteAddress <= 0) {
			alertService.addInfo(localizationService.getLocalizedString('ccc.notices.noDataMessage'));
		} else {
			checkCurrentBatchStatus().then(res => {
				if (!res) {
					setShowSendModal(!showSendModal);
				}
			});
		}
	};

	//Better check this by interval or timeout
	const checkCurrentBatchStatus = async (): Promise<boolean> => {
		let shouldRefreshList = false;

		const noticeUrl = `${urlService.getAuthorityResourcesApiUrl(Resource.CccNotices)}`;
		let url = `${noticeUrl}/${Resource.PendingNoticeBatchStatus}`;
		await apiService
			.getResource<PendingNoticeBatchDto>(url, false)
			.then((batchStatus: PendingNoticeBatchDto) => {
				if (batchStatus.batchId !== noticeBatchId || batchStatus.requireRegenerationPendingNotice) {
					setShowRefreshModal(true);
					shouldRefreshList = true;
				}
			})
			.catch((e: any) => {
				alertService.addError(e.message);
				shouldRefreshList = true;
			});

		return shouldRefreshList;
	};

	const sendEmails = (sendNoticeId: number) => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.CccNotices}/${Resource.SendEmails}/${sendNoticeId}`
		);
		apiService
			.getResource<any>(url, false)
			.then((noticeHistory: SentNoticeHistory) => {
				if (
					numberOfLettersMarkedDoNotSend > 0 &&
					numberOfLetters - contactsWithIncompleteAddress == numberOfLettersMarkedDoNotSend
				) {
					dispatch(fogPendingNoticeSlice.fetchPage({}));
				} else {
					dispatch(fogNoticesHistoriesSlice.fetchPage({}));

					if (noticeHistory.noticeStatus) {
						var noticeStatus = noticeHistory.noticeStatus.toLowerCase();
						if (String.equalCaseInsensitive(noticeStatus, NoticeStatus.Success)) {
							alertService.addSuccess(
								localizationService.getLocalizedString('ccc.notices.sendNoticeSuccess')
							);
						}
						if (String.equalCaseInsensitive(noticeStatus, NoticeStatus.Fail.toString())) {
							alertService.addError(noticeHistory.errorDetails);
						}
					}
				}
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
	};

	const sendNotices = (event: any) => {
		const templateId = urlService.getUrlQueryParameter('templateId') || '';
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.CccNotices}/${Resource.SendNotices}/${noticeBatchId}/${templateId}`
		);
		apiService
			.getResource<number>(url)
			.then((response: number) => {
				if (
					numberOfLettersMarkedDoNotSend > 0 &&
					numberOfLetters - contactsWithIncompleteAddress == numberOfLettersMarkedDoNotSend
				) {
					alertService.addSuccess(
						localizationService.getLocalizedString('notices.noticesClearedSuccessfully')
					);
				} else {
					goToNoticesHistories();
					alertService.addSuccess(localizationService.getLocalizedString('ccc.notices.sendNoticeStarted'));
				}

				setShowSendModal(!showSendModal);
				sendEmails(response);
			})
			.catch(error => alertService.addError(error.message));
	};

	const cancelRefreshModal = () => {
		setShowRefreshModal(false);
	};

	const refreshModalFooterDiv = (
		<>
			<button
				className="btn ai-action"
				onClick={() => {
					setShowRefreshModal(false);
					dispatch(
						fogPendingNoticeSlice.fetchPage({}, () => {
							alertService.addSuccess(
								localizationService.getLocalizedString(
									'alertMessages.updateSuccess',
									localizationService.getLocalizedString('notices.noticeList')
								)
							);
						})
					);
				}}>
				{localizationService.getLocalizedString('ccc.notices.reloadPage')}
			</button>
		</>
	);

	const goToNoticesHistories = (event?: any) => {
		event && event.preventDefault();
		event && event.stopPropagation();
		const url = urlService.getReactResourceUrl(Resource.FogNoticesHistories);
		navigateTo(props.history, url);
	};

	const getSendNoticesTitle = (): string => {
		const grossNumberOfLetters = numberOfLetters - contactsWithIncompleteAddress;
		if (numberOfLettersMarkedDoNotSend == 0) {
			const netNumberOfLettersBeingSent = numberOfLetters - numberOfLettersMarkedDoNotSend;
			return localizationService.getLocalizedString(
				`notices.sendNoticesTitle`,
				localizationService.formatPlural(netNumberOfLettersBeingSent)
			);
		} else if (grossNumberOfLetters == numberOfLettersMarkedDoNotSend) {
			return localizationService.getLocalizedString(
				`notices.clearNoticesTitle`,
				localizationService.formatPlural(numberOfLettersMarkedDoNotSend)
			);
		} else {
			return localizationService.getLocalizedString(
				`notices.sendNoticesTitle`,
				localizationService.formatPlural(grossNumberOfLetters - numberOfLettersMarkedDoNotSend)
			);
		}
	};

	const getSendNoticesDesc = (): any => {
		const grossLettersThatCanBeSent = numberOfLetters - contactsWithIncompleteAddress;
		if (numberOfLettersMarkedDoNotSend == 0) {
			return localizationService.getLocalizedString(
				'notices.sendNoticesDesc',
				grossLettersThatCanBeSent + '',
				localizationService.formatPlural(grossLettersThatCanBeSent)
			);
		} else if (grossLettersThatCanBeSent == numberOfLettersMarkedDoNotSend) {
			return localizationService.getLocalizedString(
				'notices.willNotSendNoticesDesc',
				numberOfLettersMarkedDoNotSend + '',
				localizationService.formatPlural(numberOfLettersMarkedDoNotSend)
			);
		} else {
			const numberOfLettersThatCanBeSent = grossLettersThatCanBeSent - numberOfLettersMarkedDoNotSend;
			return (
				<>
					<p>
						{localizationService.getLocalizedString(
							'notices.sendNoticesDesc',
							grossLettersThatCanBeSent - numberOfLettersMarkedDoNotSend + '',
							localizationService.formatPlural(numberOfLettersThatCanBeSent)
						)}
					</p>
					<p>
						{localizationService.getLocalizedString(
							'notices.willNotSendNoticesDesc',
							numberOfLettersMarkedDoNotSend + '',
							localizationService.formatPlural(numberOfLettersMarkedDoNotSend)
						)}
					</p>
				</>
			);
		}
	};

	const getSendNoticesOkayButtonText = (): string => {
		return (numberOfLettersMarkedDoNotSend > 0 &&
			numberOfLetters - contactsWithIncompleteAddress - numberOfLettersMarkedDoNotSend > 0) ||
			numberOfLettersMarkedDoNotSend == 0
			? localizationService.getLocalizedString('screen.buttons.send')
			: localizationService.getLocalizedString('screen.buttons.clear');
	};

	const sendNoticeModalProps: CustomModalProp = {
		showModal: showSendModal,
		title: getSendNoticesTitle(),
		message: getSendNoticesDesc(),
		okayButtonText: getSendNoticesOkayButtonText(),
		onCancelButtonClick: () => setShowSendModal(!showSendModal),
		onOkayButtonClick: sendNotices
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('notices.notices'),
		prefix: 'notices',
		storageName: LocalStorageName.FogNoticeGrid,
		gridUrlLocalStorageName: LocalStorageName.FogNoticesGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FogNoticesSortOrder,
		defaultSort: [],
		noRecordMessage: localizationService.getLocalizedString('notices.noDataMessage'),
		doNotValidateParameters: true,
		allColumns: [
			Utils.newColumnField('doNotSend', {
				cell: iconElement,
				filterable: false,
				sortable: true,
				locked: true,
				minResizableWidth: 32
			}),
			new ColumnField('templateName', FilterType.Text, textElement),
			new ColumnField('contactName', FilterType.Text, textElement),
			new ColumnField('companyName', FilterType.Text, textElement),
			new ColumnField('emailAddress', FilterType.Text, textElement),
			new ColumnField('deviceNumber', FilterType.Text, deviceLink),
			new ColumnField('actions', FilterType.Text, actionsElement, false, false)
		],
		defaultColumns: [
			'doNotSend',
			'templateName',
			'contactName',
			'companyName',
			'emailAddress',
			'deviceNumber',
			'actions'
		]
	};

	const updateNoticeType = (evt: any) => {
		const { value } = evt.target;
		if (_.isEmpty(value)) {
			return;
		}

		setNoticeType(value);
	};

	const prePageChange = () => {
		checkCurrentBatchStatus();
	};

	const preSortChange = () => {
		checkCurrentBatchStatus();
	};

	return (
		<div className="pending-notices">
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={fogPendingNoticeSlice}
				restState={(state: RootState) => state.fogPendingNotices}
				gridOption={initialGridOption}
				prePageChange={prePageChange}
				preSortChange={preSortChange}
				headerElem={
					<>
						<h1>{localizationService.getLocalizedString('notices.notices')}</h1>
						<a href="#/" className="mr-4" onClick={(event: any) => goToNoticesHistories(event)}>
							{localizationService.getLocalizedString('notices.noticeHistory')}
						</a>
						<button
							className="btn ai-action filter-item"
							id="btnSendNotices"
							onClick={onToggleSendNoticeModal}>
							{localizationService.getLocalizedString('notices.send')}
						</button>
					</>
				}
				filterElem={
					<>
						<NoticeTypeDropdown
							noticeType={noticeType}
							noticeOptions={templateOptions}
							onChange={updateNoticeType}
						/>
						{contactsWithIncompleteAddress > 0 && (
							<Notification
								className="recipient-warning p-0 d-inline-block mb-3"
								message={localizationService.getLocalizedString(
									`notices.emailMissingWarning${
										contactsWithIncompleteAddress === 1 ? 'Single' : 'Multiple'
									}`,
									contactsWithIncompleteAddress + ''
								)}
								icon={<SvgWarning />}
							/>
						)}
					</>
				}
			/>
			<CustomModal {...sendNoticeModalProps} />
			{showPreviewModal && (
				<EmailModal
					showModal={showPreviewModal}
					emailDetails={[emailDetails]}
					onToggle={onTogglePreviewModal}
					hideFooter={true}
				/>
			)}
			<PopoverModal
				showModal={showRefreshModal}
				title={localizationService.getLocalizedString('ccc.notices.refreshModalTitle')}
				cancel={cancelRefreshModal}
				footer={refreshModalFooterDiv}
				withoutCloseButton>
				<p>{localizationService.getLocalizedString('ccc.notices.refreshModalBody')}</p>
			</PopoverModal>
		</div>
	);
};
