import React from 'react';
import CustomFieldSettingComponent from '../../../shared/settings/custom-field/custom-field-setting';
import { localizationService, urlService } from 'src/services';
import { AutoNumberDomainType, CustomFieldApplyToTable, ImportEntity, LookupType, SubLookupType } from '@rcp/types';
import { AutoNumberSetting } from 'src/components/authority/shared/settings/auto-number';
import { LookupSetting } from 'src/components/widgets';
import ImportUniqueKeySettingComponent from 'src/components/authority/shared/settings/import-unique-key/import-unique-key-setting';
import TagSettingComponent from 'src/components/authority/shared/settings/tag/tag-setting';

const HazardSettingsComponent: React.FunctionComponent = () => {
	const customFieldDescription = localizationService.getLocalizedString('authoritySetting.customFieldsSectionHazard');
	const params = '';

	return (
		<>
			<div className="page">
				<>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('cccHazard.hazards')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">
							{urlService.isAdministrator() && (
								<>
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.category')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.categoryDescription'
										)}
										settingId="hazardCategory"
										settingLabel={localizationService.getLocalizedString('cccHazard.category')}
										lookupType={LookupType.CccHazardCategory}
										editDescription={true}
									/>
									<hr />
									<CustomFieldSettingComponent
										description={customFieldDescription}
										appliedToTable={CustomFieldApplyToTable.ApplyToCccHazard}
									/>
									<hr />
									<ImportUniqueKeySettingComponent
										importEntity={ImportEntity.CccHazard}
										fieldsPath={`Settings/AuthoritySettings/Imports/Fields/${ImportEntity.CccHazard}`}
										params={params}
										description={localizationService.getLocalizedString(
											'authoritySetting.importUniqueKey.settingDescription',
											'cccHazard.hazard'
										)}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.deviceStatus')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.deviceStatusDescription'
										)}
										settingId="hazardDeviceStatus"
										settingLabel={localizationService.getLocalizedString('cccHazard.deviceStatus')}
										lookupType={LookupType.CccHazardDeviceStatus}
										editDescription={true}
									/>
									<hr />
									<AutoNumberSetting
										title={localizationService.getLocalizedString(
											'authoritySetting.cccHazardAutoNumber'
										)}
										autoNumbersLabel={localizationService.getLocalizedString(
											'authoritySetting.cccHazardNumbers'
										)}
										autoNumberType={AutoNumberDomainType.CccHazard}></AutoNumberSetting>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.lineSize')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.lineSizeDescription'
										)}
										settingId="hazardLineSize"
										settingLabel={localizationService.getLocalizedString('cccHazard.lineSize')}
										lookupType={LookupType.CccHazardLineSize}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.deviceOrientation')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.orientationDescription'
										)}
										settingId="hazardDeviceOrientation"
										settingLabel={localizationService.getLocalizedString(
											'cccHazard.deviceOrientation'
										)}
										lookupType={LookupType.CccHazardDeviceOrientation}
										editDescription={true}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.protection')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.protectionDescription'
										)}
										settingId="hazardProtection"
										settingLabel={localizationService.getLocalizedString('cccHazard.protection')}
										lookupType={LookupType.CccHazardProtection}
										editDescription={true}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.serviceStatus')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.serviceStatusDescription'
										)}
										settingId="hazardServiceStatus"
										settingLabel={localizationService.getLocalizedString('cccHazard.serviceStatus')}
										lookupType={LookupType.CccHazardServiceStatus}
										editDescription={true}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccHazard.serviceType')}
										description={localizationService.getLocalizedString(
											'cccHazard.setting.serviceTypeDescription'
										)}
										settingId="hazardServiceType"
										settingLabel={localizationService.getLocalizedString('cccHazard.serviceType')}
										lookupType={LookupType.CccHazardServiceType}
										editDescription={true}
									/>
									<hr />
									<TagSettingComponent
										type={SubLookupType.Hazard}
										tagSection={localizationService.getLocalizedString(
											'authoritySetting.tagsSection'
										)}
										tagSectionDesc1={localizationService.getLocalizedString(
											'authoritySetting.tagsHazardSectionDescription1'
										)}
										tagSectionDesc2={localizationService.getLocalizedString(
											'authoritySetting.tagsHazardSectionDescription2'
										)}
										tagSectionDesc3={localizationService.getLocalizedString(
											'authoritySetting.tagsHazardSectionDescription3'
										)}
									/>
								</>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
};

export default HazardSettingsComponent;
