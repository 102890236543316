import { ApiError, IppReportPackageTemplateElement } from '@rcp/types';
import { History } from 'history';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { DeleteModal, DeleteModalProp, TextAreaInput, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { IppConstants } from 'src/constants';
import { alertService, RootState } from 'src/redux';
import { localizationService, navigateTo, Resource, urlService, validationService } from 'src/services';
import { ippAttachmentsSlice } from '../attachments.slice';
import { ippElementDetailsSlice } from './element-details.slice';

interface Match {
	params: {
		id?: number;
		type?: string;
	};
}

interface Props {
	history: History;
	match: Match;
}

interface FormFields {
	name?: string;
	content?: string;
	nameError?: string;
	contentError?: string;
}
const initialFormFields: FormFields = {
	name: '',
	content: '',
	nameError: '',
	contentError: ''
};

const initialReportElement: IppReportPackageTemplateElement = {
	name: '',
	description: '',
	reportElementCategoryName: '',
	content: ''
};

const { fieldCharLimit } = IppConstants;

const IppReportPackageElementDetailsComponent: FC<Props> = ({ history, match }) => {
	const [currentReportElement, setCurrentReportElement] = useState(initialReportElement);
	const [formState, setFormState] = useState(initialFormFields);
	const [elementTitle, setElementTitle] = useState('');
	const [elementSubtitle, setElementSubtitle] = useState('');
	const [deleteElementModal, setDeleteElementModal] = useState(false);
	const [isAccessDenied, setIsAccessDenied] = useState(false);
	let elementState = (state: RootState) => state.ippReportPackageElementDetails;
	let selected = useSelector(elementState).selected;

	const dispatch = useDispatch();

	const onAccessDenied = (error: ApiError) => {
		if (
			error &&
			error.statusCode === 403 &&
			String.equalCaseInsensitive(
				error.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			alertService.clearAllMessages();
			setIsAccessDenied(true);
		}
	};

	useEffect(() => {
		if (match.params.id) {
			dispatch(ippElementDetailsSlice.fetchOne(match.params.id, undefined, error => onAccessDenied(error)));
		} else {
			setCurrentReportElement(initialReportElement);
		}
		setTitleSubtitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const setTitleSubtitle = () => {
		if (
			String.equalCaseInsensitive(match.params.type, 'Attachments') ||
			(selected &&
				String.equalCaseInsensitive(selected.reportElementCategoryName, 'Attachments') &&
				match.params.id)
		) {
			setElementTitle(
				localizationService.getLocalizedString(
					'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.attachmentTitle'
				)
			);
			setElementSubtitle(
				localizationService.getLocalizedString(
					'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.attachmentSubtitle'
				)
			);
		} else {
			setElementTitle(
				localizationService.getLocalizedString(
					'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.certificationTitle'
				)
			);
			setElementSubtitle(
				localizationService.getLocalizedString(
					'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.certificationSubtitle'
				)
			);
		}
	};

	useEffect(() => {
		if (selected && match.params.id) {
			setCurrentReportElement(selected);
		} else {
			setCurrentReportElement(initialReportElement);
		}
		setTitleSubtitle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	const onChangeDetails = (e: any) => {
		let { name, value } = e.target;

		setCurrentReportElement({
			...currentReportElement,
			[name]: value
		});
	};

	const isFormValidateForSave = (): boolean => {
		let newState = {
			...formState,
			name: currentReportElement.name,
			content: currentReportElement.content
		};

		validationService.validateRequiredField(
			newState,
			'name',
			'nameError',
			localizationService.getLocalizedString('ipp.reportPackage.reportTemplateElement.name')
		);
		validationService.validateRequiredDateField(
			newState,
			'content',
			'contentError',
			localizationService.getLocalizedString('ipp.reportPackage.reportTemplateElement.content')
		);
		let isFromValid = false;
		setFormState(newState);
		if (String.equalCaseInsensitive(currentReportElement.reportElementCategoryName, 'Certifications')) {
			isFromValid = !validationService.hasError(newState, 'nameError', 'contentError');
		} else {
			isFromValid = !validationService.hasError(newState, 'nameError');
		}

		return isFromValid;
	};

	const onCallBack = () => {
		const url = urlService.getIppAuthorityUrl(Resource.IppReportPackageReportElementTypes);
		navigateTo(history, url);
	};

	const onSave = () => {
		if (match.params.type) {
			let newReportElement = { ...currentReportElement, reportElementCategoryName: match.params.type };
			if (isFormValidateForSave()) {
				dispatch(
					ippElementDetailsSlice.createOne(
						newReportElement,
						false,
						localizationService.getLocalizedString(
							'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.saveSuccessMessage'
						),
						() => onCallBack()
					)
				);
			}
		} else {
			if (currentReportElement.reportElementTypeId && isFormValidateForSave()) {
				dispatch(
					ippElementDetailsSlice.patchOne(
						currentReportElement.reportElementTypeId,
						currentReportElement,
						false,
						localizationService.getLocalizedString(
							'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.updateSuccessMessage'
						),
						() => onCallBack()
					)
				);
			}
		}
	};

	const onDelete = () => {
		if (match.params.id) {
			dispatch(
				ippAttachmentsSlice.deleteOne(
					match.params.id,
					false,
					localizationService.getLocalizedString(
						'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.deleteMessage'
					),
					() => onCallBack()
				)
			);
		}
	};

	const onDeleteElement = () => {
		setDeleteElementModal(true);
	};

	const toggleDelete = () => {
		setDeleteElementModal(!deleteElementModal);
	};
	let templateDetailsDeleteModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString(
			'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.deleteModalTitle'
		),
		message: localizationService.getLocalizedString(
			'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.deleteModalMessage'
		),
		showModal: deleteElementModal,
		onCancelButtonClick: toggleDelete,
		onOkayButtonClick: onDelete,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};
	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<DeleteModal {...templateDetailsDeleteModalProp} key="elementDeleteModal" />
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString(
								'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.title'
							)}
						</h1>
						<button className="btn ai-save" id="element-save" onClick={onSave}>
							{localizationService.getLocalizedString('ipp.buttons.save')}
						</button>
						{match.params.id && (
							<button className="btn ai-delete ml-2" id="template-delete" onClick={onDeleteElement}>
								{localizationService.getLocalizedString('ipp.buttons.delete')}
							</button>
						)}
					</div>
					<CollapsibleCard accordionType="certificationsTypeDetails" accordionHeading={elementTitle}>
						<div className="form-row">
							<p>{match.params.id ? elementSubtitle : ''}</p>
						</div>
						<div className="w-75 mx-auto">
							<div className="form-row">
								<TextInput
									id="element-name"
									name="name"
									label={localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.elementName'
									)}
									isRequired={true}
									error={formState.nameError}
									className="col-md-11"
									value={currentReportElement ? currentReportElement.name : ''}
									onChange={onChangeDetails}
									remainingInputProps={{ maxLength: fieldCharLimit.longElementName }}
								/>
								<div className="col-md-1 d-flex">
									<TooltipHover
										id="element-name-info"
										title={localizationService.getLocalizedString(
											'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.elementNameTooltip'
										)}
										className="report-details-tooltip"
									/>
								</div>
							</div>
							<div className="form-row">
								<TextAreaInput
									id="description"
									name="description"
									label={localizationService.getLocalizedString(
										'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.description'
									)}
									className="col-md-11"
									value={currentReportElement ? currentReportElement.description : ''}
									onChange={onChangeDetails}
									maxLength={fieldCharLimit.commentsAndDescription}
								/>
							</div>
						</div>
						{(String.equalCaseInsensitive(match.params.type, 'Certifications') ||
							String.equalCaseInsensitive(
								currentReportElement.reportElementCategoryName,
								'Certifications'
							)) && (
							<>
								<hr />
								<div className="w-75 mx-auto">
									<div className="form-row">
										<TextAreaInput
											id="certification-text"
											name="content"
											label={localizationService.getLocalizedString(
												'ipp.reportPackage.reportTemplateElement.reportTemplateElementDetails.certificationText'
											)}
											isRequired={true}
											className="col-md-11"
											isFullWidth={true}
											error={formState.contentError}
											rows={8}
											value={currentReportElement ? currentReportElement.content : ''}
											onChange={onChangeDetails}
											maxLength={fieldCharLimit.certificationText}
										/>
									</div>
								</div>
							</>
						)}
					</CollapsibleCard>
				</div>
			</div>
		</>
	);
};
export default IppReportPackageElementDetailsComponent;
