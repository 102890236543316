import { FilterType, FogNoticesHistory, NoticeStatus, PreviewNotice, RouteProps } from '@rcp/types';
import React from 'react';
import { DataGrid, GridOption } from 'src/features';
import { RootState, useReduxSelector } from 'src/redux';
import { History } from 'history';
import { fogNoticesHistoriesSlice } from './notices-history-slice';
import { ColumnField, LocalStorageName, FeatureNames } from '@rcp/types';
import { DateUtilService, localizationService } from 'src/services';
import EmailModal from 'src/components/widgets/preview-email-notice';
import { DeleteModal } from 'src/components';
import { useDispatch } from 'react-redux';

interface Props extends RouteProps {
	history: History;
}

export const NoticeHistories: React.FC<Props> = props => {
	const [showPreviewModal, setShowPreviewModal] = React.useState(false);
	const [previewTitle, setPreviewTitle] = React.useState<string>('');
	const [emailDetails, setEmailDetails] = React.useState<PreviewNotice[]>([
		{
			to: '',
			templateContent: '',
			templateSubject: ''
		}
	]);
	const [deleteState, setDeleteState] = React.useState({
		showDelete: false,
		id: -1,
		sentDateTime: '',
		numberOfLetters: 0
	});
	const [noticeStatus, setNoticeStatus] = React.useState<string>('');
	const dispatch = useDispatch();
	const onTogglePreviewModal = () => setShowPreviewModal(!showPreviewModal);

	const getModalTitle = (status: string) => {
		let title =
			status == NoticeStatus.Success
				? 'numberOfSuccessEmails'
				: status == NoticeStatus.Sending
				? 'numberOfSendingEmails'
				: 'numberOfFailureEmails';
		return localizationService.getLocalizedString(`noticesHistory.${title}`);
	};

	const previewEmails = (event: any, status: string, dataItem?: any) => {
		event.preventDefault();
		setNoticeStatus(status);
		const cleaningHistoryPreviews = [
			...dataItem[
				status == NoticeStatus.Success
					? 'successCleaningNoticePreviews'
					: status == NoticeStatus.Sending
					? 'sendingCleaningNoticePreviews'
					: 'failureCleaningNoticePreviews'
			]
		];

		const previewDetails: PreviewNotice[] = [];
		cleaningHistoryPreviews.map(preview => {
			const {
				to,
				from,
				cc,
				emailLog,
				errorMessage,
				templateContent,
				templateSubject,
				templateName,
				organizationId,
				facilityName,
				facilityNumber,
				deviceDescription,
				extractorId,
				deviceNumber,
				sentBy,
				sentDate,
				resentBy,
				resentDate
			} = preview;
			previewDetails.push({
				to: to || '',
				from,
				cc,
				emailLogId: emailLog.emailLogId,
				errorMessage,
				templateSubject,
				templateContent,
				templateName,
				organizationId,
				facilityName,
				facilityNumber,
				deviceId: extractorId,
				deviceNumber,
				deviceDescription,
				sentBy,
				sentDate,
				resentBy,
				resentDate
			});
		});
		setPreviewTitle(getModalTitle(status));
		setEmailDetails(previewDetails);
		onTogglePreviewModal();
	};

	const deleteHistory = (e: any, dataItem: FogNoticesHistory) => {
		e.preventDefault();
		setDeleteState({
			...deleteState,
			showDelete: true,
			id: dataItem.sentNoticeHistoryId || 0,
			numberOfLetters: dataItem.numberOfSuccessEmails || 0,
			sentDateTime: dataItem.sentDateTime || ''
		});
	};

	const deleteLink = (item: any) => {
		const dataItem: FogNoticesHistory = item.dataItem || {};
		return (
			<td>
				{!dataItem.numberOfSendingEmails && (
					<a className="ai-link" href="/delete" onClick={(e: any) => deleteHistory(e, dataItem)}>
						{localizationService.getLocalizedString('screen.labels.delete')}
					</a>
				)}
			</td>
		);
	};

	const successEmailsPreview = (item: any) => {
		const dataItem: FogNoticesHistory = item.dataItem || {};
		return (
			<td>
				<a href="#/" onClick={event => previewEmails(event, NoticeStatus.Success, dataItem)}>
					{!!dataItem.numberOfSuccessEmails ? dataItem.numberOfSuccessEmails : ''}
				</a>
			</td>
		);
	};

	const sendingEmailsPreview = (items: any) => {
		const dataItem: FogNoticesHistory = items.dataItem || {};
		return (
			<td>
				<a href="#/" onClick={(event: any) => previewEmails(event, NoticeStatus.Sending, dataItem)}>
					{!!dataItem.numberOfSendingEmails ? dataItem.numberOfSendingEmails : ''}
				</a>
			</td>
		);
	};

	const failureEmailsPreview = (items: any) => {
		const dataItem: FogNoticesHistory = items.dataItem || {};
		return (
			<td>
				<a href="#/" onClick={(event: any) => previewEmails(event, NoticeStatus.Fail, dataItem)}>
					{!!dataItem.numberOfFailureEmails ? dataItem.numberOfFailureEmails : ''}
				</a>
			</td>
		);
	};

	const sentDateTimeCell = (item: any) => {
		return dateTimeCellFormatting(item, false);
	};

	const lastResentDateTimeCell = (item: any) => {
		return dateTimeCellFormatting(item, true);
	};

	const dateTimeCellFormatting = (item: any, isLastResentDateTime?: boolean) => {
		const dataItem = item.dataItem as FogNoticesHistory;
		if (isLastResentDateTime) {
			return (
				<td>
					{DateUtilService.toDisplayDate(
						dataItem.lastResentDateTime,
						localizationService.getLocalizedString('dateFormats.displayDateTime')
					)}
				</td>
			);
		} else {
			return (
				<td>
					{DateUtilService.toDisplayDate(
						dataItem.sentDateTime,
						localizationService.getLocalizedString('dateFormats.displayDateTime')
					)}
				</td>
			);
		}
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('noticesHistory.noticeHistory'),
		prefix: 'noticesHistory',
		storageName: LocalStorageName.FogNoticesHistoryGrid,
		gridUrlLocalStorageName: LocalStorageName.FogNoticesHistoryFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FogNoticesHistorySortOrder,
		defaultSort: [],
		doNotValidateParameters: true,
		showSearch: true,
		allColumns: [
			new ColumnField('sentDateTime', FilterType.Date, sentDateTimeCell),
			new ColumnField('sentByUserFullName'),
			new ColumnField('lastResentDateTime', FilterType.Date, lastResentDateTimeCell),
			new ColumnField('numberOfSendingEmails', FilterType.Number, sendingEmailsPreview),
			new ColumnField('numberOfSuccessEmails', FilterType.Number, successEmailsPreview),
			new ColumnField('numberOfFailureEmails', FilterType.Number, failureEmailsPreview),
			new ColumnField('actions', FilterType.Text, deleteLink, true, false)
		],
		defaultColumns: [
			'sentDateTime',
			'sentByUserFullName',
			'lastResentDateTime',
			'numberOfSendingEmails',
			'numberOfSuccessEmails',
			'numberOfFailureEmails',
			'actions'
		]
	};

	const onCancelDelete = () => {
		setDeleteState({ ...deleteState, showDelete: false, id: -1 });
	};

	const onDeleteConfirmed = () => {
		dispatch(
			fogNoticesHistoriesSlice.deleteOne(
				deleteState.id,
				true,
				localizationService.getLocalizedString(
					'noticesHistory.deletedSucceed',
					DateUtilService.toDisplayDate(deleteState.sentDateTime.toString()),
					DateUtilService.toDisplayTime(deleteState.sentDateTime.toString())
				)
			)
		);
		onCancelDelete();
	};

	const getDeleteModal = () => {
		return (
			<DeleteModal
				title={localizationService.getLocalizedString('ccc.noticeHistory.deleteModalTitle')}
				showModal={deleteState.showDelete}
				onCancelButtonClick={() => onCancelDelete()}
				isDeleteButton
				okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				onOkayButtonClick={() => onDeleteConfirmed()}
				message={localizationService.getLocalizedString(
					'noticesHistory.deleteModalDesc',
					DateUtilService.toDisplayDate(deleteState.sentDateTime.toString()),
					DateUtilService.toDisplayTime(deleteState.sentDateTime.toString())
				)}
			/>
		);
	};

	const refresh = () => {
		dispatch(fogNoticesHistoriesSlice.fetchAll());
	};

	return (
		<>
			<DataGrid
				gridOption={initialGridOption}
				restSlice={fogNoticesHistoriesSlice}
				restState={(state: RootState) => state.fogNoticesHistory}
				match={props.match}
				history={props.history}
			/>
			{showPreviewModal && (
				<EmailModal
					title={previewTitle}
					noticeStatus={noticeStatus}
					showModal={showPreviewModal}
					emailDetails={emailDetails}
					onToggle={onTogglePreviewModal}
					refresh={refresh}
					isNoticeHistoryGrid
				/>
			)}
			{deleteState.showDelete && getDeleteModal()}
		</>
	);
};
