import {
	IppIndustrySample,
	IppReportPackage,
	IppSampleResultValidationResult,
	ValidationResultError,
	IppProgramSettings,
	IppCollectionMethod
} from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { alertService, ApplicationState } from 'src/redux';
import {
	deleteSample,
	loadCollectionMethods,
	loadSample,
	SampleState,
	setSampleData,
	updateSample
} from 'src/redux/ipp/industry/samples';
import {
	apiService,
	localizationService,
	navigateTo,
	Resource,
	urlService,
	DateUtilService,
	validationService
} from 'src/services';
import IppMonitoringPointComponent from './monitoring-point';
import IppSampleParameterGroupsComponent from './parameter-groups';
import IppSampleResultsComponent from './sample-results';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { SampleStatus, ReportToSampleStatus } from 'src/constants';
import Reporting from './reporting';
import _ from 'lodash';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface DispatchProps {
	setSampleData: (
		sample: IppIndustrySample,
		createSample?: boolean,
		callback?: (newSample: IppIndustrySample) => void,
		reportPackageElementTypeId?: number
	) => Promise<boolean | undefined>;
	updateSample: (
		sample: IppIndustrySample,
		shouldLoadUpdatedSample?: boolean,
		sourceReportPackageId?: number
	) => Promise<boolean | IppSampleResultValidationResult | undefined>;
	loadSample: (id: number) => Promise<any>;
	deleteSample: (id: number, sourceReportPackageId?: number) => Promise<boolean | undefined>;
	loadCollectionMethods: () => Promise<boolean | undefined>;
}

interface Match {
	params: {
		sampleId?: number;
		edit?: boolean;
	};
}

interface Props extends DispatchProps {
	sample: IppIndustrySample;
	history: any;
	match: Match;
	programSettings?: IppProgramSettings;
	collectionMethods?: IppCollectionMethod[];
}

const { Draft, Reported } = SampleStatus;

const IppIndustrySampleStep2: FC<Props> = (props: Props) => {
	const [deleteSampleModal, setDeleteSampleModal] = useState(false);
	const [validationResults, setValidationResults] = useState<ValidationResultError[]>([]);
	const [sourcePackageId, setSourcePackageId] = useState('');
	const [addSampleToReport, setAddSampleToReport] = useState(false);
	const [reportToSampleStatus, setReportToSampleStatus] = useState<string | undefined>('');
	const [isAccessDenied, setIsAccessDenied] = useState(false);
	const { Edit, CopyAndEdit, AddSampleToReport } = ReportToSampleStatus;

	useEffect(() => {
		props.loadCollectionMethods();
		return () => {
			props.setSampleData({} as IppIndustrySample);
		};
	}, []);

	useEffect(() => {
		let state = props.history.location.state;
		if (state) {
			if (
				(state.monitoringPointId && state.monitoringPointName, state.startDateTimeLocal, state.endDateTimeLocal)
			) {
				props.setSampleData({
					monitoringPointId: state.monitoringPointId,
					monitoringPointName: state.monitoringPointName,
					startDateTimeLocal: state.startDateTimeLocal,
					endDateTimeLocal: state.endDateTimeLocal,
					collectionMethodName: state.collectionMethodName,
					collectionMethodId: state.collectionMethodId
				});
			}
			if (state[Edit]) {
				setReportToSampleStatus(Edit);
			} else if (state[CopyAndEdit]) {
				setReportToSampleStatus(CopyAndEdit);
			} else if (state[AddSampleToReport]) {
				setReportToSampleStatus(AddSampleToReport);
			} else {
				setReportToSampleStatus(undefined);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.history.location.state]);

	useEffect(() => {
		let state = props.history.location.state;
		if (
			state &&
			(reportToSampleStatus === CopyAndEdit ||
				reportToSampleStatus === Edit ||
				reportToSampleStatus === AddSampleToReport)
		) {
			setSourcePackageId(state.sourcePackageId);
			if (state.addSampleToReport) {
				setAddSampleToReport(true);
				props.setSampleData({ ...props.sample, isReadyToReport: true });
			}
			if (state.copyAndEdit) {
				let copiedSample = {
					...state,
					isSampleExcludedFromReports: false,
					sampleStatusName: Draft,
					isReadyToReport: false,
					associatedReportPackages: [] as IppReportPackage[]
				};
				props.setSampleData(copiedSample);
			}
			if (state.edit) {
				props.setSampleData({ ...state, sampleStatusName: Draft, isReadyToReport: false });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportToSampleStatus]);

	const loadSampleDetails = async (id: number) => {
		let response = await props.loadSample(id);
		if (
			response &&
			response.statusCode === 403 &&
			response.message === localizationService.getLocalizedString('errors.noPermissionToAccess')
		) {
			alertService.clearAllMessages();
			setIsAccessDenied(true);
		}
	};

	useEffect(() => {
		let id = props.match.params.sampleId;
		let state = props.history.location.state;
		if (id && !(state && state.edit)) {
			loadSampleDetails(id);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.match.params.sampleId]);

	const setProgramSettingFields = (sample: IppIndustrySample) => {
		let settings = props.programSettings && props.programSettings['settings'];
		if (settings && settings.length) {
			let massLoadingCalculationDecimalPlaces = _.find(settings, setting => {
				return setting.settingType === 'MassLoadingCalculationDecimalPlaces';
			});
			let massLoadingConversionFactorPounds = _.find(settings, setting => {
				return setting.settingType === 'MassLoadingConversionFactorPounds';
			});
			let resultQualifierValidValues = _.find(settings, setting => {
				return setting.settingType === 'ResultQualifierValidValues';
			});

			sample.massLoadingCalculationDecimalPlaces =
				Number(massLoadingCalculationDecimalPlaces!.value) || undefined;
			sample.massLoadingConversionFactorPounds = Number(massLoadingConversionFactorPounds!.value) || undefined;
			sample.resultQualifierValidValues = resultQualifierValidValues!.value;
		}
	};

	const disableForm = () => {
		if (props.match.params.sampleId && props.sample && props.sample.sampleStatusName !== Draft) {
			return true;
		} else {
			return false;
		}
	};

	const onSave = () => {
		if (!validateForm()) {
			let sample = { ...props.sample };
			setProgramSettingFields(sample);
			if (props.match.params.sampleId) {
				setValidationResults([]);
				props.updateSample(sample);
			} else {
				props.setSampleData(sample, true, newSample => {
					const url = urlService.getIppIndustryUrl(`samples/${newSample.sampleId}/details`);
					navigateTo(props.history, url, undefined, {
						sourcePackageId
					});
				});
			}
		}
	};

	const validateForm = () => {
		const {
			collectionMethodId,
			collectionMethodName,
			ctsEventTypeName,
			startDateTimeLocal,
			endDateTimeLocal,
			flowEnteredValue,
			flowUnitName
		} = props.sample;
		const { location } = props.history;
		const { periodEndDateTimeLocal, periodStartDateTimeLocal } = location.state || {};
		const startDate = startDateTimeLocal && DateUtilService.toDisplayDate(startDateTimeLocal);
		const endDate = endDateTimeLocal && DateUtilService.toDisplayDate(endDateTimeLocal);
		const periodEndDateLocal = periodEndDateTimeLocal && DateUtilService.toDisplayDate(periodEndDateTimeLocal);
		const periodStartDateLocal =
			periodStartDateTimeLocal && DateUtilService.toDisplayDate(periodStartDateTimeLocal);

		let dateState = { startDateTimeLocal, endDateTimeLocal, endDateError: '', startDateError: '' };
		validationService.validateMinimumDate(dateState, 'startDateTimeLocal', 'endDateError');
		validationService.validateMinimumDate(dateState, 'endDateTimeLocal', 'startDateError');

		if (!collectionMethodName) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step2.errorCollectionMethodRequiredMessage')
			);
			return true;
		}
		if (!ctsEventTypeName) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step2.errorSampleTypeRequiredMessage')
			);
			return true;
		}
		if (!startDateTimeLocal) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorStartDateRequiredMessage')
			);
			return true;
		}
		if (!endDateTimeLocal) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step1.errorEndDateRequiredMessage')
			);
			return true;
		}
		if (validationService.hasError(dateState, 'endDateError', 'startDateError')) {
			alertService.addError(
				localizationService.getLocalizedString(
					'screen.validationMessage.minimumDateCheckMessage',
					'screen.labels.date'
				)
			);
			return true;
		}
		if ((flowEnteredValue || '').toString() && !flowUnitName) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.step2.errorFlowUnitNameRequiredMessage')
			);
			return true;
		}
		if (
			periodStartDateTimeLocal &&
			endDate &&
			startDate &&
			!(
				new Date(periodStartDateLocal) <= new Date(startDate) &&
				new Date(startDate) <= new Date(periodEndDateLocal)
			) &&
			!(new Date(periodStartDateLocal) <= new Date(endDate) && new Date(endDate) <= new Date(periodEndDateLocal))
		) {
			alertService.addError(
				localizationService.getLocalizedString(
					'ipp.samples.step2.restrictToReportPeriodDate',
					DateUtilService.toDisplayDate(periodStartDateTimeLocal),
					DateUtilService.toDisplayDate(periodEndDateTimeLocal)
				)
			);
			return true;
		}

		if (props.collectionMethods) {
			let selectedCollectMethod = props.collectionMethods.find(
				item => Number(item.collectionMethodId) === Number(collectionMethodId)
			);
			if (
				_.toLower(selectedCollectMethod?.collectionMethodTypeName) === 'grab' &&
				startDateTimeLocal !== endDateTimeLocal
			) {
				alertService.addError(
					localizationService.getLocalizedString('ipp.samples.step2.grabSampleDateWarning')
				);
				return true;
			}
		}
		return false;
	};

	const deleteSample = async () => {
		toggleDelete();

		await props.deleteSample(props.match.params.sampleId as number, Number(sourcePackageId));
		if (reportToSampleStatus === Edit) {
			navigateToSourceReportPackage();
		} else {
			navigateTo(
				props.history,
				urlService.getIppIndustryUrl(
					`${Resource.IppIndustrySamples}${props.sample.isReadyToReport ? '/readyToReport' : '/draft'}`
				)
			);
		}
	};

	const toggleDelete = () => {
		setDeleteSampleModal(!deleteSampleModal);
	};

	const onDeleteSample = () => {
		setDeleteSampleModal(true);
	};
	let sampleDetailsDeleteModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('ipp.samples.step2.deleteModalTitle'),
		message: localizationService.getLocalizedString('ipp.samples.step2.deleteModalMessage'),
		showModal: deleteSampleModal,
		onCancelButtonClick: toggleDelete,
		onOkayButtonClick: deleteSample,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const updateAndRerender = async (sample: IppIndustrySample) => {
		setProgramSettingFields(sample);
		let response = await props.updateSample(sample);
		if (typeof response === 'object' && response.errors) {
			setValidationResults(response.errors || []);
			navigateTo(props.history, window.location.href);
			return false;
		} else {
			setValidationResults([]);
			navigateTo(props.history, window.location.href);
			return true;
		}
	};

	const onReadyToReport = async (enableEditing?: boolean) => {
		let readyToReport = enableEditing ? false : true;
		if (!sourcePackageId) {
			let sample = { ...props.sample, isReadyToReport: readyToReport };
			updateAndRerender(sample);
		} else {
			let url = urlService.getAuthorityResourcesApiUrl(
				`${Resource.IppReportPackages}/${sourcePackageId}/RemoveSample`
			);
			apiService
				.httpPatch(url, {
					sampleId: props.sample.sampleId
				})
				.then(async () => {
					if (!validateForm()) {
						let sample = {
							...props.sample,
							sampleStatusName: Draft,
							isReadyToReport: true
						};
						delete sample.sampleId;
						await props.setSampleData(
							sample,
							true,
							() => {
								navigateToSourceReportPackage();
							},
							props.history.location.state.reportPackageElementTypeId
						);
					}
				});
		}
	};

	const onCopySample = () => {
		setValidationResults([]);
		alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.step2.copyMessage'));
		let newSample: IppIndustrySample = {
			...props.sample,
			isReadyToReport: false,
			sampleStatusName: Draft,
			associatedReportPackages: [] as IppReportPackage[]
		};
		props.setSampleData(newSample);
		navigateTo(props.history, urlService.getIppIndustryUrl(`${Resource.IppIndustrySamples}/new/step2`));
	};

	const onEditSampleFromReport = async () => {
		if (!validateForm()) {
			let sample = { ...props.sample, isReadyToReport: true };
			setProgramSettingFields(sample);
			let response: any = await props.updateSample(sample, true, +sourcePackageId);
			if (!(response && response.message)) navigateToSourceReportPackage();
		}
	};
	const onAddSampleToReport = async () => {
		let sample = { ...props.sample };
		setProgramSettingFields(sample);
		if (!validateForm()) {
			await props.setSampleData(
				sample,
				true,
				() => {
					navigateToSourceReportPackage();
				},
				props.history.location.state.reportPackageElementTypeId
			);
		}
	};

	const navigateToSourceReportPackage = () => {
		const url = urlService.getIppIndustryUrl(`reportPackage/${sourcePackageId}`);
		navigateTo(props.history, url);
	};

	const onCancel = () => {
		navigateToSourceReportPackage();
	};
	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<DeleteModal {...sampleDetailsDeleteModalProp} key="sampleDetailsDeleteModal" />
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className={`d-flex flex-column w-100 max-table`}>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('ipp.samples.sampleDetails')}</h1>
						{reportToSampleStatus === AddSampleToReport ||
						reportToSampleStatus === CopyAndEdit ||
						reportToSampleStatus === Edit ? null : (
							<button
								className="btn ai-action ml-auto mr-2"
								id="copy-sample-btn"
								onClick={() => onCopySample()}>
								{localizationService.getLocalizedString('ipp.samples.step2.copySample')}
							</button>
						)}
						{props.sample.sampleStatusName !== Reported ? (
							<>
								{props.match.params.sampleId && props.sample.isReadyToReport ? (
									<label
										className="status-btn ml-auto mr-2 ready-to-report"
										id="sample-ready-to-report">
										{localizationService.getLocalizedString('ipp.samples.step2.readyToReport')}
									</label>
								) : (
									<label className="status-btn ml-auto draft-btn" id="sample-draft-btn">
										{localizationService.getLocalizedString('ipp.samples.step2.draft')}
									</label>
								)}
							</>
						) : (
							<label className="status-btn ml-auto reported" id="sample-reported-btn">
								{localizationService.getLocalizedString('ipp.samples.step2.reported')}
							</label>
						)}
					</div>
					<IppMonitoringPointComponent
						history={props.history}
						reportToSampleStatus={reportToSampleStatus}
						disableForm={disableForm}
						updateAndRerender={updateAndRerender}
						isNewOrCopySample={!!!props.match.params.sampleId}
					/>
					<Reporting
						reportPackages={[...(props.sample.associatedReportPackages || ([] as IppReportPackage[]))]}
					/>
					{!disableForm() && <IppSampleParameterGroupsComponent />}
					<IppSampleResultsComponent
						setValidationResults={setValidationResults}
						validationResults={validationResults}
						history={props.history}
						match={props.match}
						disableForm={disableForm}
					/>
					{props.sample.sampleStatusName !== Reported && (
						<section className="mt-3">
							<div className="form-row">
								{props.match.params.sampleId && (
									<button
										className="btn ai-secondary-delete mr-auto"
										id="sample-delete"
										onClick={() => onDeleteSample()}>
										{localizationService.getLocalizedString('screen.buttons.delete')}
									</button>
								)}
								<div className="ml-auto" id="cor-buttons">
									{addSampleToReport && (
										<>
											<button
												className="btn ai-action ml-auto mr-2"
												id="add-sample-to-report"
												onClick={() => onAddSampleToReport()}>
												{localizationService.getLocalizedString(
													'ipp.samples.step2.addToReport'
												)}
											</button>
											{sourcePackageId && (
												<button
													className="btn ai-white ml-auto mr-2"
													id="sample-save"
													onClick={() => onCancel()}>
													{localizationService.getLocalizedString('ipp.samples.cancel')}
												</button>
											)}
										</>
									)}
									{sourcePackageId &&
										props.history.location.state &&
										props.history.location.state.edit && (
											<button
												className="btn ai-save ml-auto mr-2"
												id="sample-save"
												onClick={() => onEditSampleFromReport()}>
												{localizationService.getLocalizedString('ipp.samples.save')}
											</button>
										)}
									{!addSampleToReport &&
										(!props.sample.isReadyToReport ? (
											<>
												{!sourcePackageId && (
													<button
														className="btn ai-save ml-auto mr-2"
														id="sample-save"
														onClick={() => onSave()}>
														{localizationService.getLocalizedString('ipp.samples.save')}
													</button>
												)}
												{reportToSampleStatus !== Edit &&
													(props.match.params.sampleId ||
														reportToSampleStatus === CopyAndEdit) && (
														<button
															className="btn ai-action ml-auto mr-2"
															id="sample-ready-to-report"
															onClick={() => onReadyToReport()}>
															{localizationService.getLocalizedString(
																'ipp.samples.step2.readyToReport'
															)}
														</button>
													)}
												{sourcePackageId && (
													<button
														className="btn ai-white ml-auto mr-2"
														id="sample-save"
														onClick={() => onCancel()}>
														{localizationService.getLocalizedString('ipp.samples.cancel')}
													</button>
												)}
											</>
										) : (
											props.match.params.sampleId && (
												<button
													className="btn ai-action ml-auto mr-2"
													id="sample-enable-editing"
													onClick={() => onReadyToReport(true)}>
													{localizationService.getLocalizedString(
														'ipp.samples.step2.enableEditing'
													)}
												</button>
											)
										))}
								</div>
							</div>
						</section>
					)}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: ApplicationState): SampleState => {
	return { ...state.ippIndustrySample };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadCollectionMethods: () => dispatch(loadCollectionMethods()),
		setSampleData: (
			sample: IppIndustrySample,
			createSample?: boolean,
			callback?: (newSample: IppIndustrySample) => void,
			reportPackageElementTypeId?: number
		) => dispatch(setSampleData(sample, createSample, callback, reportPackageElementTypeId)),
		updateSample: (sample: IppIndustrySample, shouldLoadUpdatedSample?: boolean, sourceReportPackageId?: number) =>
			dispatch(updateSample(sample, shouldLoadUpdatedSample, sourceReportPackageId)),
		loadSample: (id: number) => dispatch(loadSample(id)),
		deleteSample: (id: number, sourceReportPackageId?: number) => dispatch(deleteSample(id, sourceReportPackageId))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppIndustrySampleStep2);
