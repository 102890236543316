import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';

import { ContactTabPageComponent } from './contact-tab-page/facility-contact-tab-page-component';
import { ApplicationState, FogFacilityState } from 'src/redux';
import { OverviewTab } from './overview-tab/facility-overview-tab';
import { AccountTab } from './account-tab/facility-account-tab';
import { LocationTab } from './location-tab/facility-location-tab';
import { OperationsTab } from './operations-tab/facility-operations-tab';
import { CustomFieldsTab } from './custom-fields-tab/custom-field-tab';
import { EquipmentTabPage } from './equipment-tabpage/equipment-tabpage';
import { ExtractorTabPage } from './extractor-tab-page/extractor-tab-page-component';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { localizationService, navigateToRoute } from 'src/services';

interface StateProps extends FogFacilityState {}

type Props = StateProps;
const FacilityInfoTabComp: React.SFC<Props> = props => {
	let { url } = useRouteMatch();
	const [activeTab, setActiveTab] = useState('tab-overview');
	const history = useHistory();

	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			//TODO check the tab id exists or not
			setActiveTab(tab);
		}
	};

	React.useEffect(() => {
		const tab = history.location.pathname.split('/');
		const urlLength = 4;
		if (tab.length > urlLength) {
			toggle(tab[tab.length - 1]);
		} else {
			toggle('tab-overview');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history.location.pathname]);

	return (
		<section className="info-card">
			<Nav tabs className="material">
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-overview', url)}
						className={classNames({ active: activeTab === 'tab-overview' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.overview')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-account', url)}
						className={classNames({ active: activeTab === 'tab-account' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.account')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-location', url)}
						className={classNames({ active: activeTab === 'tab-location' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.location')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-operations', url)}
						className={classNames({ active: activeTab === 'tab-operations' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.operations')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-custom-fields', url)}
						className={classNames({ active: activeTab === 'tab-custom-fields' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.customFields')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-devices', url)}
						className={classNames({ active: activeTab === 'tab-devices' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.devices')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-contact', url)}
						className={classNames({ active: activeTab === 'tab-contact' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.contactsAndNotices')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<string>(history, 'tab-equipment', url)}
						className={classNames({ active: activeTab === 'tab-equipment' }) + ' cursor-pointer'}>
						{localizationService.getLocalizedString('facility.tabs.equipment')}
					</NavLink>
				</NavItem>
			</Nav>

			<div className="margin-top-1">
				<Switch>
					<TabContent activeTab={activeTab}>
						<TabPane tabId="tab-overview">{activeTab === 'tab-overview' && <OverviewTab />}</TabPane>
						<TabPane tabId="tab-account">{activeTab === 'tab-account' && <AccountTab />}</TabPane>

						<TabPane tabId="tab-location">{activeTab === 'tab-location' && <LocationTab />}</TabPane>
						<TabPane tabId="tab-operations">{activeTab === 'tab-operations' && <OperationsTab />}</TabPane>
						<TabPane tabId="tab-devices">{activeTab === 'tab-devices' && <ExtractorTabPage />}</TabPane>

						<TabPane tabId="tab-extractors">
							{activeTab === 'tab-extractors' && <ExtractorTabPage />}
						</TabPane>

						<TabPane tabId="tab-equipment">{activeTab === 'tab-equipment' && <EquipmentTabPage />}</TabPane>
						<TabPane tabId="tab-contact">
							{activeTab === 'tab-contact' && <ContactTabPageComponent facility={props.facility} />}
						</TabPane>
						<TabPane tabId="tab-custom-fields">
							{activeTab === 'tab-custom-fields' && (
								<CustomFieldsTab customFieldFor="fog" customFieldParentObject={props.facility} />
							)}
						</TabPane>
					</TabContent>
				</Switch>
			</div>
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility };
};

export const FacilityInfoTab = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(FacilityInfoTabComp);
