import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from 'reactstrap';

import * as actionCreators from 'src/redux/callLog';

import './facility-callLog.scss';
import { localizationService, DateUtilService } from 'src/services';
import {
	CallLogInputFields,
	commentAndDateValidation,
	prepareDateAndTimeStringForApiCall
} from './callLog-input-fields';

interface OwnProps {
	anchor: HTMLElement;
	show: boolean;
	toggle: () => void;
}

interface DispatchProps {
	saveFogFacilityCallLog: (
		commentText: string,
		callLogDateTime: string,
		isUserProvidedTime: boolean
	) => Promise<boolean | undefined>;
}

type FacilityCallLogProp = OwnProps & DispatchProps;

const FacilityCallLogComp: React.SFC<FacilityCallLogProp> = props => {
	const [comment, setComment] = useState('');
	const [callDate, setCallDate] = useState(DateUtilService.getAuthorityTimezoneNow());
	const [callTime, setCallTime] = useState(DateUtilService.getAuthorityTimezoneNow());
	const [commentError, setCommentError] = useState('');
	const [callDateError, setCallDateError] = useState('');

	const defaultTextEle = useRef<HTMLTextAreaElement>(null);

	React.useEffect(() => {
		if (defaultTextEle && defaultTextEle.current && props.show) {
			defaultTextEle.current.focus();
		}
	}, [props.show]);

	const onCommentsChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};

	const onCallDateChanged = (e: any) => {
		const callDate = e.target.value;
		setCallDate(callDate);
	};

	const onCallTimeChanged = (e: any) => {
		const callTime = e.target.value;
		setCallTime(callTime);
	};

	const onLogCallBtnClick = () => {
		if (!commentAndDateValidation(comment, callDate, setCommentError, setCallDateError)) {
			return;
		}
		let callDateTimeStr = prepareDateAndTimeStringForApiCall(callDate as string, callTime);
		props
			.saveFogFacilityCallLog(comment, callDateTimeStr, callTime !== null)
			.then((result: boolean | undefined) => {
				if (result === true) {
					props.toggle();
				}
			});
	};

	const onCancelBtnClick = () => {
		props.toggle();
	};

	let hasAnchor = props.anchor instanceof HTMLElement;

	return !hasAnchor ? null : (
		<Popup anchor={props.anchor} show={props.show} animate={false} popupClass="shadow call-log-modal">
			<div className="d-sm-flex flex-column">
				<CallLogInputFields
					showModal={props.show}
					onCommentChanged={onCommentsChanged}
					onCallDateChanged={onCallDateChanged}
					onCallTimeChanged={onCallTimeChanged}
					comment={comment}
					callDate={callDate}
					callTime={callTime}
					callDateError={callDateError}
					commentError={commentError}
				/>
				<div className="popup-modal-footer ml-auto">
					<Button
						color="btn ai-action"
						id="logCallBtn"
						className="popup-modal-btn"
						onClick={onLogCallBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.logCall')}
					</Button>
					<Button color="btn ai-white" id="cancelBtn" className="popup-modal-btn" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</div>
		</Popup>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		saveFogFacilityCallLog: (commentText: string, callLogDateTime: string, isUserProvidedTime: boolean) =>
			dispatch(actionCreators.saveFogFacilityCallLog(commentText, callLogDateTime, isUserProvidedTime))
	};
};

export const FacilityCallLog = connect<{}, DispatchProps, OwnProps>(null, mapDispatchToProps)(FacilityCallLogComp);
