import React from 'react';
import { apiService, localizationService, urlService, UtilService } from 'src/services';
import { ApiError, AuthorityOrpSetting, AutoNumberDomainType } from '@rcp/types';
import { alertService } from '../../../../redux/alert';
import { TextInput } from 'src/components/widgets';

import _ from 'lodash';
import 'src/components/authority/shared/settings/authority-settings.scss';

import { loadAuthoritySettings, useReduxDispatch, useReduxSelector } from 'src/redux';

let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;

interface Props {
	title: string;
	autoNumbersLabel: string;
	autoNumberType: AutoNumberDomainType;
}

export const AutoNumberSetting: React.FC<Props> = props => {
	const [authoritySetting, setAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const [originalAuthoritySetting, setOriginalAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const reduxAuthoritySettings = useReduxSelector(state => state.authoritySettingLookups.authoritySettings);
	const dispatch = useReduxDispatch();
	let autoNumberPrefix = `autoNumberPrefixFor${props.autoNumberType}`;
	let autoNumberSuffix = `autoNumberSuffixFor${props.autoNumberType}`;

	const prefixLabel = localizationService.getLocalizedString('authoritySetting.prefixFor');
	const suffixLabel = localizationService.getLocalizedString('authoritySetting.suffixFor');

	React.useEffect(() => {
		dispatch(loadAuthoritySettings());
	}, [dispatch]);

	React.useEffect(() => {
		if (!_.isEmpty(reduxAuthoritySettings)) {
			let newAuthoritySetting = { ...authoritySetting };
			for (let settingValue of reduxAuthoritySettings) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				newAuthoritySetting[key] = settingValue.value;
			}
			setAuthoritySetting(newAuthoritySetting);
			setOriginalAuthoritySetting(newAuthoritySetting);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reduxAuthoritySettings]);

	const onAuthorityOrpSettingChanged = (e: any) => {
		let newSetting = { ...authoritySetting };
		const { name, value } = e.target;
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);
	};

	const updateAuthoritySettingValue = (e: any, label: string) => {
		const { name, value } = e.target;

		if (originalAuthoritySetting[name] === value) {
			return;
		}

		let newSetting = { ...originalAuthoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);

		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, {
				settingType: name,
				value: value
			})
			.then(() => {
				alertService.addSuccess(
					localizationService.getLocalizedString('authoritySetting.updateSucceedMessage', props.title, label)
				);
				setAuthoritySetting(newSetting);
				setOriginalAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{props.title}</strong>
				<p>
					{localizationService.getLocalizedString(
						'authoritySetting.autoNumberForDescription1',
						props.autoNumbersLabel
					)}
				</p>
				<p>
					{localizationService.getLocalizedString('authoritySetting.autoNumberForDescription2')}
					<i>{localizationService.getLocalizedString('authoritySetting.autoNumberForDescription3')}</i>
				</p>
			</div>
			<div className="col-lg-8">
				<section>
					<div className="form-row">
						<TextInput
							id={autoNumberPrefix}
							name={autoNumberPrefix}
							className="col-sm-6"
							label={prefixLabel}
							value={_.get(authoritySetting, autoNumberPrefix, '') as string}
							onBlur={e => {
								updateAuthoritySettingValue(e, prefixLabel);
							}}
							onEnterKeyPressed={e => {
								updateAuthoritySettingValue(e, prefixLabel);
							}}
							onChange={onAuthorityOrpSettingChanged}
						/>

						<TextInput
							id={autoNumberSuffix}
							name={autoNumberSuffix}
							className="col-sm-6"
							label={localizationService.getLocalizedString('authoritySetting.suffixFor')}
							value={_.get(authoritySetting, autoNumberSuffix, '') as string}
							onBlur={e => {
								updateAuthoritySettingValue(e, suffixLabel);
							}}
							onEnterKeyPressed={e => {
								updateAuthoritySettingValue(e, suffixLabel);
							}}
							onChange={onAuthorityOrpSettingChanged}
						/>
					</div>
				</section>
			</div>
		</div>
	);
};
