import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState, FogFacilityState, AuthorityTagsState } from 'src/redux';
import { TabViewField } from 'src/components/widgets';
import { OverviewModal } from './facility-overview-modal';
import { localizationService, DateUtilService } from 'src/services';

interface StateProps extends FogFacilityState {
	authorityTags: AuthorityTagsState;
}

type Props = StateProps;

const OverviewTabComp: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row ">
					<TabViewField
						title={localizationService.getLocalizedString('facility.isEnabled')}
						value={props.facility.isEnabled ? 'Yes' : 'No'}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.tagClassification1')}
						value={props.facility.tagClassification1}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.tagClassification2')}
						value={props.facility.tagClassification2}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.inspectionFrequencyCode')}
						value={props.facility.inspectionFrequencyCode ? props.facility.inspectionFrequencyCode : ''}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.lastPumpOutCompleteDate')}
						value={
							props.facility.lastPumpOutCompleteDate
								? DateUtilService.toDisplayDate(props.facility.lastPumpOutCompleteDate)
								: ''
						}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.nextPumpOutDueDate')}
						value={
							props.facility.nextPumpOutDueDate
								? DateUtilService.toDisplayDate(props.facility.nextPumpOutDueDate)
								: ''
						}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.lastInspectionCompleteDate')}
						value={
							props.facility.lastInspectionCompleteDate
								? DateUtilService.toDisplayDate(props.facility.lastInspectionCompleteDate)
								: ''
						}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.nextInspectionDueDate')}
						value={
							props.facility.nextInspectionDueDate
								? DateUtilService.toDisplayDate(props.facility.nextInspectionDueDate)
								: ''
						}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						Edit
					</a>
				</div>
			</div>
			{isToggle && <OverviewModal isToggle={isToggle} toggle={toggle} />}
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, authorityTags: state.authorityTags };
};

export const OverviewTab = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(OverviewTabComp);
