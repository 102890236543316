import React from 'react';
import { LinkoRoute } from './app-route';
import { Layout } from './components';
import Translator from './components/widgets/translate/translator-context';
import { NotCompatible } from './features/home/incompatible-browser';
import { loadFeatureFlagSettings, useReduxDispatch } from './redux';
import { urlService } from './services';

export const App = () => {
	const dispatch = useReduxDispatch();
	const isIE = (): boolean => {
		return navigator.userAgent.indexOf('MSIE ') > -1 || navigator.userAgent.indexOf('Trident/') > -1;
	};

	if (window.location.pathname === '/' || window.location.pathname === '/index.html') {
		urlService.switchToDefaultView();
	}

	dispatch(loadFeatureFlagSettings());
	return (
		<Translator>
			<Layout>{isIE() ? <NotCompatible /> : <LinkoRoute />}</Layout>
		</Translator>
	);
};
