import React, { FC } from 'react';
import { TextAreaInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TextInput } from 'src/components/widgets/text-input';
import { DateUtilService } from 'src/services';
import { localizationService } from 'src/services/localizationService';

interface Props {
	repudiationDateTimeLocal?: string;
	repudiationDateTimeUtc?: string;
	repudiatorUserId?: number;
	repudiatorFirstName?: string;
	repudiatorLastName?: string;
	repudiatorTitleRole?: string;
	repudiationReasonId?: number;
	repudiationReasonName?: string;
	repudiationComments?: string;
	className?: string;
	showCopyReportPackage?: boolean;
	copyReportPackage?: () => void;
}

const IppPackageItemRepudiationConfirmation: FC<Props> = (props: Props) => {
	const getRepudiatedDate = () => {
		return `${DateUtilService.toDisplayDate(props.repudiationDateTimeLocal)} ${DateUtilService.toDisplayTime(
			props.repudiationDateTimeLocal
		).toLocaleUpperCase()}`;
	};

	const getRepudiatedBy = () => {
		return `${props.repudiatorFirstName || ''} ${props.repudiatorLastName || ''}`;
	};
	return (
		<>
			<CollapsibleCard
				className={props.className ? props.className : ''}
				accordionType="repudiated-confirmation"
				accordionHeading={localizationService.getLocalizedString(
					'ipp.reportPackage.package.repudiationConfirmation'
				)}>
				<div className="w-75 mx-auto">
					<div className="form-row">
						<TextInput
							id="repudiated-by"
							name="repudiatedBy"
							label={localizationService.getLocalizedString('ipp.reportPackage.package.repudiatedBy')}
							className="col-md-6"
							isDisabled={true}
							value={getRepudiatedBy()}
						/>
						<div className="col-md-1" />
						<TextInput
							id="title"
							name="repudiatorTitleRole"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.repudiatorTitleRole'
							)}
							className="col-md-5"
							isDisabled={true}
							value={props.repudiatorTitleRole}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="repudiationDate"
							name="repudiationDate"
							className="col-md-6"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.repudiationDateTimeLocal'
							)}
							isDisabled={true}
							value={getRepudiatedDate()}
						/>
					</div>
					<div className="form-row mt-2">
						<TextInput
							id="repudiation-reason"
							name="repudiation-reason"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.repudiationReasonName'
							)}
							className="col-md-6"
							isDisabled={true}
							value={props.repudiationReasonName}
						/>
					</div>
					<div className="form-row">
						<TextAreaInput
							id="repudiation-comment"
							name="repudiationComment"
							className="col-md-6"
							isDisabled={true}
							value={props.repudiationComments}
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.repudiationComments'
							)}
						/>
					</div>
				</div>
				<div className="form-row justify-content-end">
					{props.showCopyReportPackage ? (
						<>
							<span className="copyReportPackageMessage">
								{localizationService.getLocalizedString(
									'ipp.reportPackage.package.copyReportPackageMessage'
								)}
							</span>
							<button className="btn ai-action h-100 align-self-center" onClick={props.copyReportPackage}>
								{localizationService.getLocalizedString('ipp.buttons.copyReportPackage')}
							</button>
						</>
					) : null}
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageItemRepudiationConfirmation;
