import { apiService, Resource, urlService } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { MaintenanceMessage } from '@rcp/types';

export interface MaintenanceMessageState {
	maintenanceMessage: MaintenanceMessage;
}

export const initialMaintenanceMessageState: MaintenanceMessageState = {
	maintenanceMessage: {}
};

enum MaintenanceMessageActionType {
	LoadMaintenanceMessage = 'LoadMaintenanceMessage'
}

export type MaintenanceMessageThunkAction = ThunkAction<any, MaintenanceMessageState, any, Action>;

export const loadMaintenanceMessage = (): MaintenanceMessageThunkAction => async dispatch => {
	const maintenanceMesssageUrl = `${urlService.getApiBaseUrl()}/Account/${Resource.MaintenanceMessage}`;

	let maintenanceMessage: MaintenanceMessage = await apiService.httpGet(maintenanceMesssageUrl);
	dispatch({ type: MaintenanceMessageActionType.LoadMaintenanceMessage, maintenanceMessage: maintenanceMessage });
};

export const maintenanceMessageReducer = (
	state = initialMaintenanceMessageState,
	action: any
): MaintenanceMessageState => {
	switch (action.type) {
		case MaintenanceMessageActionType.LoadMaintenanceMessage:
			return {
				...state,
				maintenanceMessage: action.maintenanceMessage
			};
		default:
			return { ...state };
	}
};
