import React from 'react';
import { RouteProps } from 'react-router';
import { FormBuilder } from './form-builder';

interface Props extends RouteProps {}

export const IframeFormBuilder = (props: Props) => {
	const newProps = {
		...props,
		hideHeader: true,
		isPreview: true
	};

	return (
		<>
			<FormBuilder {...newProps} />
		</>
	);
};
