import React from 'react';
import { useHistory } from 'react-router-dom';
import { navigateTo, urlService } from 'src/services';

interface OwnProps {
	facilityName?: string;
	facilityId?: number;
	authorityId?: number;
}

export const FacilityNameCell: React.SFC<OwnProps> = props => {
	const history = useHistory();
	let facilityPath = urlService.getReactFacilityDetailsUrl(props.facilityId);
	return (
		<>
			<a href={facilityPath} className="ai-link" onClick={(e: any) => navigateTo(history, facilityPath, e)}>
				{props.facilityName}
			</a>
		</>
	);
};
