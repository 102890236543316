import { DropDownOption, IppAvailableCtsEventType, IppReportPackageTemplate } from '@rcp/types';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { DateInput, SingleCheckbox, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components/widgets';
import { IppConstants } from 'src/constants';
import { DateUtilService, localizationService, validationService } from 'src/services';
import { TooltipHover } from '../../../../../widgets/tooltip-hover';
import './report-details.scss';

interface Props {
	templateDetails: IppReportPackageTemplate;
	availableCtsEventTypes: IppAvailableCtsEventType[];
	onTemplateDetailsChange: (reportDetails: IppReportPackageTemplate) => void;
	isFormInAddMode: () => boolean;
}

const { fieldCharLimit } = IppConstants;

const initialReportDetails: IppReportPackageTemplate = {
	name: '',
	description: '',
	effectiveDateTimeLocal: DateUtilService.getAuthorityTimezoneNow(),
	isActive: false,
	isSubmissionBySignatoryRequired: true,
	submissionAcceptanceRequired: false,
	isAdhocReportAllowed: false,
	isPeriodRequired: false,
	ctsEventTypeName: '',
	ctsEventTypeId: 0,
	ctsCategoryName: ''
};

interface FormFields {
	name?: string;
	effectiveDateTimeLocal?: string;
	nameError?: string;
	effectiveDateTimeLocalError?: string;
}
const initialFormFields: FormFields = {
	name: '',
	effectiveDateTimeLocal: '',
	nameError: '',
	effectiveDateTimeLocalError: ''
};

export interface ReportRef {
	getReportDetails: () => IppReportPackageTemplate;
	isFormValidateForSave: () => boolean;
}

const ReportDetailsComponent = (props: Props, ref: Ref<ReportRef>) => {
	const [reportDetails, setReportDetails] = useState(initialReportDetails);
	const [formState, setFormState] = useState(initialFormFields);
	const [ctsDropDownOptions, setCtsDropDownOptions] = useState([
		{
			label: '',
			value: ''
		}
	]);
	useEffect(() => {
		if (props.templateDetails) {
			let newReportDetails: IppReportPackageTemplate = {
				...props.templateDetails,
				isSubmissionBySignatoryRequired:
					props.isFormInAddMode() || props.templateDetails.isSubmissionBySignatoryRequired
			};
			setReportDetails(newReportDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.templateDetails]);

	useEffect(() => {
		let ctsOptions: DropDownOption[] = [];
		if (props.availableCtsEventTypes && props.availableCtsEventTypes.length) {
			ctsOptions = props.availableCtsEventTypes.map(item => {
				let options: DropDownOption = {
					label: `${item.name} (${item.ctsEventCategoryName})`,
					value: item.ctsEventTypeId,
					isHidden: item.isEnabled
				};
				return options;
			});
		}
		ctsOptions.unshift({
			label: localizationService.getLocalizedString('ipp.reportPackage.templateDetails.defaultCTSEventType'),
			value: 0,
			isHidden: false
		});
		ctsOptions && setCtsDropDownOptions(ctsOptions);
	}, [props.availableCtsEventTypes]);

	useEffect(() => {
		if (!reportDetails.isPeriodRequired)
			setReportDetails({
				...reportDetails,
				isPeriodRequired: isCtsSelfMonitoringCategory()
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reportDetails.ctsCategoryName]);

	const isCtsSelfMonitoringCategory = (reportData: IppReportPackageTemplate = reportDetails) => {
		return String.equalCaseInsensitive(reportData.ctsCategoryName || '', 'self monitoring');
	};

	const onChangeDetails = (e: any) => {
		let { name, value } = e.target;
		let ctsEventType = {} as IppAvailableCtsEventType | undefined;
		if (String.equalCaseInsensitive(name, 'ctsEventTypeName')) {
			ctsEventType = props.availableCtsEventTypes.find(item => item.ctsEventTypeId === Number(value));
			setReportDetails({
				...reportDetails,
				ctsEventTypeId: ctsEventType ? ctsEventType.ctsEventTypeId : undefined,
				ctsEventTypeName: ctsEventType ? ctsEventType.name : '',
				ctsCategoryName: ctsEventType ? ctsEventType.ctsEventCategoryName : ''
			});
		} else if (String.equalCaseInsensitive(name, 'effectiveDateTimeLocal')) {
			setReportDetails({
				...reportDetails,
				[name]: value
			});
		} else {
			if (e.target.type === 'checkbox') {
				value = e.target.checked;
			}
			setReportDetails({
				...reportDetails,
				[name]: value
			});
		}
	};
	useImperativeHandle(ref, () => ({ getReportDetails, isFormValidateForSave }));
	const getReportDetails = (): IppReportPackageTemplate => {
		return reportDetails;
	};

	const isFormValidateForSave = (): boolean => {
		let newState = {
			...formState,
			name: reportDetails.name,
			effectiveDateTimeLocal: reportDetails.effectiveDateTimeLocal
		};

		validationService.validateRequiredField(
			newState,
			'name',
			'nameError',
			localizationService.getLocalizedString('ipp.reportPackage.templateDetails.templateName')
		);
		validationService.validateRequiredDateField(
			newState,
			'effectiveDateTimeLocal',
			'effectiveDateTimeLocalError',
			localizationService.getLocalizedString('ipp.reportPackage.templateDetails.effectiveDateTimeLocal')
		);

		setFormState(newState);

		const isFromValid = !validationService.hasError(newState, 'nameError', 'effectiveDateTimeLocalError');

		return isFromValid;
	};
	return (
		<>
			<div className="w-75 mx-auto">
				<div className="form-row">
					<TextInput
						id="template-name"
						name="name"
						label={localizationService.getLocalizedString('ipp.reportPackage.templateDetails.templateName')}
						isRequired={true}
						value={reportDetails.name}
						onChange={onChangeDetails}
						error={formState.nameError}
						className="col-md-8"
						remainingInputProps={{ maxLength: fieldCharLimit.longElementName }}
					/>
					<div className="col-md-1 d-flex">
						<TooltipHover
							id="tempalate-name-info"
							title={localizationService.getLocalizedString('ipp.reportPackage.tooltip.templateInfo')}
							className="report-details-tooltip"
						/>
					</div>
					<SingleCheckbox
						id="active"
						name="isActive"
						label={localizationService.getLocalizedString('ipp.reportPackage.templateDetails.active')}
						className="align-self-center mt-2 ml-auto"
						checked={reportDetails.isActive || false}
						onChange={onChangeDetails}
					/>
				</div>
				<div className="form-row">
					<TextAreaInput
						id="description"
						name="description"
						label={localizationService.getLocalizedString('ipp.reportPackage.templates.description')}
						className="col-md-8"
						value={reportDetails.description}
						onChange={onChangeDetails}
						maxLength={fieldCharLimit.commentsAndDescription}
					/>
					<div className="col-md-1"></div>
					<SingleCheckbox
						id="signatory-required"
						name="isSubmissionBySignatoryRequired"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.templates.isSubmissionBySignatoryRequired'
						)}
						className=" align-self-center mr-2 ml-auto"
						checked={reportDetails.isSubmissionBySignatoryRequired || false}
						onChange={onChangeDetails}
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="signatory-required-info"
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.tooltip.signatoryRequiredInfo'
							)}
							className="d-flex align-self-center justify-content-center"
						/>
					</div>
				</div>
			</div>
			<hr />
			<div className="w-75 mx-auto">
				<div className="form-row">
					<DateInput
						id="effective-date-time-local"
						name="effectiveDateTimeLocal"
						format="EEEE, MMMM dd, yyyy"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.templateDetails.effectiveDateTimeLocal'
						)}
						error={formState.effectiveDateTimeLocalError}
						isRequired={true}
						className="form-group col-md-6"
						value={reportDetails.effectiveDateTimeLocal}
						onChange={onChangeDetails}
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="effective-date-time-local-info"
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.tooltip.effectiveDateInfo'
							)}
							className="report-details-tooltip ml-1"
						/>
					</div>
					<div className="col-sm-1"></div>
					<SingleCheckbox
						id="allow-adhoc-reporting"
						name="isAdhocReportAllowed"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.templates.isAdhocReportAllowed'
						)}
						className="align-self-center mr-2 ml-auto"
						checked={reportDetails.isAdhocReportAllowed || false}
						onChange={onChangeDetails}
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="allow-adhoc-reporting-info"
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.tooltip.allowAdhocReportingInfo'
							)}
							className="d-flex align-self-center justify-content-center"
						/>
					</div>
					<SingleCheckbox
						id="reporting-period-required"
						name="isPeriodRequired"
						label={localizationService.getLocalizedString('ipp.reportPackage.templates.isPeriodRequired')}
						className="align-self-center mr-2 ml-auto"
						checked={reportDetails.isPeriodRequired || false}
						onChange={onChangeDetails}
						isDisabled={isCtsSelfMonitoringCategory()}
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="reporting-period-required-info"
							title={localizationService.getLocalizedString(
								`ipp.reportPackage.tooltip.${
									isCtsSelfMonitoringCategory()
										? 'conditionalReportingPeriodRequiredInfo'
										: 'reportingPeriodRequiredInfo'
								}`
							)}
							className="d-flex align-self-center justify-content-center"
						/>
					</div>
				</div>
				<div className="form-row">
					<SingleSelectDropdown
						id="available-cts-event-types"
						name="ctsEventTypeName"
						label={localizationService.getLocalizedString('ipp.reportPackage.templateDetails.ctsEventType')}
						noEmptyOption={true}
						value={
							reportDetails.ctsEventTypeName && reportDetails.ctsCategoryName
								? `${reportDetails.ctsEventTypeName} (${reportDetails.ctsCategoryName})`
								: ''
						}
						options={ctsDropDownOptions}
						selfOrder={true}
						onChange={onChangeDetails}
						className="col-md-6"
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="cts-event-type-name"
							title={localizationService.getLocalizedString('ipp.reportPackage.tooltip.ctsEventTypeInfo')}
							className="align-self-center ml-1 mt-3"
						/>
					</div>
					<div className="col-sm-1"></div>
					<SingleCheckbox
						id="acceptance-required"
						name="submissionAcceptanceRequired"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.templates.submissionAcceptanceRequired'
						)}
						className="align-self-center mr-2 ml-auto"
						checked={reportDetails.submissionAcceptanceRequired || false}
						onChange={onChangeDetails}
					/>
					<div className="d-flex justify-content-start">
						<TooltipHover
							id="acceptance-required-info"
							title={localizationService.getLocalizedString(
								'ipp.reportPackage.tooltip.acceptanceRequiredInfo'
							)}
							className="d-flex align-self-center justify-content-center"
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default forwardRef(ReportDetailsComponent);
