import React from 'react';
import { localizationService, urlService } from 'src/services';
import { AutoNumberDomainType, CustomFieldApplyToTable, Dataset, ImportEntity, Lookup, LookupType } from '@rcp/types';
import { AutoNumberSetting } from 'src/components/authority/shared/settings/auto-number';
import {
	CustomizedSettingUrlResolver,
	ListSetting,
	LookupRow,
	LookupSetting,
	ResourceAction
} from 'src/components/widgets';
import { CustomizedLookupDataConverter } from 'src/components/widgets/lookup/data-converters';
import CustomFieldSettingComponent from 'src/components/authority/shared/settings/custom-field/custom-field-setting';
import ImportUniqueKeySettingComponent from 'src/components/authority/shared/settings/import-unique-key/import-unique-key-setting';

const SiteSettingsComponent: React.FunctionComponent = () => {
	const datasetConverter = new CustomizedLookupDataConverter<Dataset>(
		(row: Lookup) => {
			return {
				name: row.code,
				isActive: row.isActive
			} as Dataset;
		},
		(dto: Dataset) => {
			return {
				lookupId: dto.dataSetId,
				code: dto.name,
				isActive: dto.isActive,
				isInUse: dto.isInUse
			} as LookupRow;
		}
	);
	let datasetSettingAction = new ResourceAction(
		new CustomizedSettingUrlResolver(
			`${urlService.getApiBaseUrlWithProgram()}/Datasets`,
			`&includeInactive=true&includeIsInUse=true`
		),
		datasetConverter
	);
	return (
		<>
			<div className="page">
				<>
					<div className="page-header">
						<h1>{localizationService.getLocalizedString('authoritySetting.sites')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">
							{urlService.isAdministrator() && (
								<>
									<CustomFieldSettingComponent
										description={localizationService.getLocalizedString(
											'authoritySetting.customFieldsSectionSite'
										)}
										appliedToTable={CustomFieldApplyToTable.ApplyToCccSite}
									/>
									<hr />
									<ListSetting
										title={localizationService.getLocalizedString('cccSite.dataSetName')}
										description={localizationService.getLocalizedString(
											'cccSite.setting.datasetDescription'
										)}
										settingId="dataset"
										settingLabel={localizationService.getLocalizedString('cccSite.dataSetName')}
										settingAction={datasetSettingAction}
									/>
									<hr />
									<AutoNumberSetting
										title={localizationService.getLocalizedString(
											'authoritySetting.cccSiteAutoNumber'
										)}
										autoNumbersLabel={localizationService.getLocalizedString(
											'authoritySetting.cccSiteNumbers'
										)}
										autoNumberType={AutoNumberDomainType.CccSite}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccSite.siteType')}
										description={localizationService.getLocalizedString(
											'cccSite.setting.siteTypeDescription'
										)}
										settingId="siteType"
										settingLabel={localizationService.getLocalizedString('cccSite.siteType')}
										lookupType={LookupType.CccSiteType}
									/>
									<hr />
									<LookupSetting
										title={localizationService.getLocalizedString('cccSite.siteUse')}
										description={localizationService.getLocalizedString(
											'cccSite.setting.siteUseDescription'
										)}
										settingId="siteUse"
										settingLabel={localizationService.getLocalizedString('cccSite.siteUse')}
										lookupType={LookupType.CccSiteUse}
									/>
									<hr />
									<ImportUniqueKeySettingComponent
										importEntity={ImportEntity.CccSite}
										fieldsPath={`Settings/AuthoritySettings/Imports/Fields/${ImportEntity.CccSite}`}
										params=""
										description={localizationService.getLocalizedString(
											'authoritySetting.importUniqueKey.settingDescription',
											'screen.labels.site'
										)}
									/>
								</>
							)}
						</div>
					</div>
				</>
			</div>
		</>
	);
};

export default SiteSettingsComponent;
