import {
	EntityContact,
	Extractor,
	ContactCardType,
	EntityContact_EntityType,
	Dictionary,
	NumberType
} from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { facilityContactsSlice } from 'src/components/authority/fog/facilities/facility-contacts-slice';
import { SingleCheckbox } from 'src/components/widgets';
import { ContactModal } from 'src/features/contacts';
import { ContactCard } from 'src/features/contacts/contact-card';
import { entityContactsSlice } from 'src/features/contacts/entity-contacts.slice';
import { RootState } from 'src/redux';
import { localizationService, Resource } from 'src/services';
import { fogFacilitiesSlice } from '../../facilities/facilities-slice';
import { fogDevicesSlice } from '../devices.slice';
import './device-contacts-tab.scss';
interface OwnProps {
	device: Extractor;
}

type Props = OwnProps;

export const deviceOwner = 'DeviceOwner';

export const DeviceContactsTab: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [contactList, setContactList] = React.useState<EntityContact[]>([]);
	const [allContacts, setAllContacts] = React.useState<EntityContact[]>([]);
	const [showAddContactModal, setShowAddContactModal] = React.useState(false);
	const [showFullContactList, setShowFullContactList] = React.useState(false);

	let facilityContacts = useSelector((state: RootState) => state.fogFacilityContacts.result);
	let deviceContacts = useSelector((state: RootState) => state.entityContacts.result);

	React.useEffect(() => {
		let newContacts: EntityContact[] = [];
		if (!_.isEmpty(deviceContacts)) {
			newContacts = deviceContacts.map(contact => ({
				...contact,
				facilityId: props.device.facilityId,
				facilityName: props.device.facilityName,
				facilityNumber: props.device.facilityNumber,
				deviceId: props.device.extractorId,
				deviceNumber: props.device.deviceNumber
			}));
		}

		newContacts.sort((a, b) => {
			if (a.contactTypeCode && b.contactTypeCode) {
				return a.contactTypeCode < b.contactTypeCode ? -1 : 1;
			}
			return 0;
		});

		if (!_.isEmpty(facilityContacts)) {
			facilityContacts.forEach(c => {
				//Always add facility contact since the send notice checkbox does not work on grouped contact.
				let contact: EntityContact = {
					...c,
					facilityId: props.device.facilityId,
					facilityName: props.device.facilityName,
					facilityNumber: props.device.facilityNumber,
					deviceId: props.device.extractorId,
					deviceNumber: props.device.deviceNumber,
					entityType: EntityContact_EntityType.FogFacility,
					sendNotices: _.isEmpty(c.jsonCleaningNotices)
						? false
						: JSON.parse(c.jsonCleaningNotices as string).findIndex(
								(a: any) => a.DeviceId == props.device.extractorId && a.SendNotices == true
						  ) > -1
						? true
						: false
				};
				newContacts.push(contact);
			});
		}

		let contact: EntityContact = {
			facilityId: props.device.facilityId,
			facilityName: props.device.facilityName,
			facilityNumber: props.device.facilityNumber,
			deviceId: props.device.extractorId,
			deviceNumber: props.device.deviceNumber,
			entityType: deviceOwner,
			sendNotices: props.device.sendCleaningNoticeToDeviceOwner,
			firstName: props.device.facilityContactName,
			addressLine1: props.device.addressLine1,
			addressLine2: props.device.addressLine2,
			addressCity: props.device.cityName,
			addressJurisdictionCode: props.device.jurisdictionCode,
			addressZipCode: props.device.zipCode,
			jsonNumbers: props.device.facilityPhone
				? JSON.stringify([
						{ numberType: NumberType.BusinessPhone, number: props.device.facilityPhone, label: 'Phone' }
				  ])
				: '',
			jsonEmails: props.device.facilityEmail ? JSON.stringify([{ email: props.device.facilityEmail }]) : ''
		};
		newContacts.push(contact);

		setAllContacts(newContacts);
		if (!showFullContactList) {
			setContactList(newContacts.slice(0, 9));
		} else {
			setContactList(newContacts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [deviceContacts, facilityContacts]);

	const switchDeviceSendNotices = (e: any) => {
		const { checked } = e.target;
		dispatch(
			fogDevicesSlice.patchOne(
				props.device.extractorId as number,
				{ sendCleaningNotices: checked },
				false,
				checked + '' === 'true'
					? localizationService.getLocalizedString('alertMessages.enableContactNoticesUpdatedSucceeded')
					: localizationService.getLocalizedString('alertMessages.disabledContactNoticesUpdatedSucceeded')
			)
		);
	};

	const onToggleAllContacts = (e: any) => {
		e.preventDefault();
		if (showFullContactList) {
			setContactList(allContacts.slice(0, 9));
		} else {
			setContactList(allContacts);
		}
		setShowFullContactList(!showFullContactList);
	};

	const loadDeviceContacts = () => {
		facilityContactsSlice.setApiUrlPath(
			Resource.FogFacilities + '/' + props.device.facilityId + '/' + Resource.Contacts
		);
		entityContactsSlice.setApiUrlPath(
			Resource.FogDevices + '/' + props.device.extractorId + '/' + Resource.Contacts
		);
		dispatch(fogFacilitiesSlice.fetchOne(props.device.facilityId as number));
		dispatch(facilityContactsSlice.fetchAll());
		dispatch(entityContactsSlice.fetchAll());
	};

	const onToggleAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		setShowAddContactModal(!showAddContactModal);
	};

	const switchContactSendNotices = (entityContact: EntityContact, checked: boolean) => {
		if (entityContact.sendNotices === checked) {
			return;
		}
		if (
			entityContact.entityType == EntityContact_EntityType.FogDevice &&
			entityContact.entityContactId &&
			props.device.extractorId
		) {
			entityContactsSlice.setApiUrlPath(
				Resource.FogDevices + '/' + props.device.extractorId + '/' + Resource.Contacts
			);
			var cleaningNoticeObjectList = [{ sendNotices: checked, deviceId: props.device.extractorId }];
			dispatch(
				entityContactsSlice.patchOne(
					entityContact.entityContactId,
					{ ...entityContact, jsonCleaningNotices: JSON.stringify(cleaningNoticeObjectList) },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.contact'
					),
					() => {
						let newContactList = [...contactList];
						let index = newContactList.findIndex(a => a.entityContactId == entityContact.entityContactId);
						let newDeviceContact = { ...newContactList[index] };
						newDeviceContact.sendNotices = checked;
						newContactList.splice(index, 1, newDeviceContact);
						setContactList(newContactList);
						loadDeviceContacts();
					}
				)
			);
			return;
		}
		if (entityContact.entityType == EntityContact_EntityType.Hauler && props.device.extractorId) {
			dispatch(
				fogDevicesSlice.patchOne(
					props.device.extractorId,
					{ sendCleaningNoticesToLastHauler: checked },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.contact'
					),
					() => {
						let newContactList = [...contactList];
						let index = newContactList.findIndex(a => a.haulerId == entityContact.haulerId);
						newContactList[index].sendNotices = checked;
						setContactList(newContactList);
					}
				)
			);
			return;
		}
		if (entityContact.entityType == deviceOwner && props.device.extractorId) {
			dispatch(
				fogDevicesSlice.patchOne(
					props.device.extractorId,
					{ sendCleaningNoticeToDeviceOwner: checked },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.contact'
					),
					() => {
						let newContactList = [...contactList];
						let index = newContactList.findIndex(a => a.entityType == deviceOwner);
						newContactList[index].sendNotices = checked;
						setContactList(newContactList);
					}
				)
			);
			return;
		}

		if (
			entityContact.entityType == EntityContact_EntityType.FogFacility &&
			entityContact.entityContactId &&
			props.device.extractorId
		) {
			var cleaningNoticeObjectList = [{ sendNotices: checked, deviceId: props.device.extractorId }];
			dispatch(
				facilityContactsSlice.patchOne(
					entityContact.entityContactId,
					{ ...entityContact, jsonCleaningNotices: JSON.stringify(cleaningNoticeObjectList) },
					false,
					localizationService.getLocalizedString(
						'alertMessages.updatedContactNoticesUpdatedSucceeded',
						'screen.labels.contact'
					),
					() => {
						let newContactList = [...contactList];
						let index = newContactList.findIndex(a => a.entityContactId == entityContact.entityContactId);
						newContactList[index].sendNotices = checked;
						setContactList(newContactList);
						loadDeviceContacts();
					}
				)
			);
			return;
		}
	};

	const containsPrimaryHaulerCard = () => {
		return contactList.some((contact: EntityContact) => {
			return (
				contact.isPrimary ||
				contact.entityType == EntityContact_EntityType.Hauler ||
				contact.entityType == EntityContact_EntityType.FogDevice
			);
		});
	};

	return (
		<>
			<div className="device-notice">
				<SingleCheckbox
					id="deviceSendNotices"
					name="deviceSendNotices"
					label={localizationService.getLocalizedString('extractor.deviceSendNoticesCheckBoxLabel')}
					checked={props.device.sendCleaningNotices}
					onChange={switchDeviceSendNotices}
					className="device-notice-checkbox"
				/>
			</div>
			<div className="contact-container">
				{contactList &&
					contactList.length > 0 &&
					contactList.map((item: EntityContact, index: number) => (
						<ContactCard
							entityContact={item}
							key={index}
							id={index}
							switchSendNotice={switchContactSendNotices}
							deviceSendNotice={!!props.device.sendCleaningNotices}
							reload={loadDeviceContacts}
							device={props.device}
							contactCardType={ContactCardType.FogDevice}
							isDeviceDetailsPage={true}
							isPrimaryOrHaulerContactExist={containsPrimaryHaulerCard()}
						/>
					))}
			</div>
			{allContacts.length > 9 && !showFullContactList && (
				<div>
					<button className="btn ai-white w-100" onClick={onToggleAllContacts}>
						{localizationService.getLocalizedString('screen.buttons.showMore')}
					</button>
				</div>
			)}
			{allContacts.length > 9 && showFullContactList && (
				<div>
					<button className="btn ai-white w-100" onClick={onToggleAllContacts}>
						{localizationService.getLocalizedString('screen.buttons.showLess')}
					</button>
				</div>
			)}
			<div className="mt-4">
				<a href="#/" className="ai-add-link" onClick={onToggleAddModal}>
					{localizationService.getLocalizedString('screen.buttons.addContact')}&#8230;
				</a>
			</div>

			{showAddContactModal && (
				<ContactModal
					close={() => {
						setShowAddContactModal(false);
					}}
					reload={loadDeviceContacts}
					entityContact={{
						entityId: props.device.extractorId,
						entityType: EntityContact_EntityType.FogDevice
					}}
					associatedContacts={deviceContacts}
				/>
			)}
		</>
	);
};
