import React from 'react';

interface Props {
	title?: string;
}

export const SendSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#23409A">
			<path d="M0 0h24v24H0z" fill="none" />
			<path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z">
				<title>{props.title}</title>
			</path>
		</svg>
	);
};
