import { UserManager, WebStorageStateStore, Log } from 'oidc-client';
import { urlService } from 'src/services';
import { oidcEnvConfig, oidcMetadata } from '../../../constants';

const getOidcIdentityConfig = () => {
	let accountBaseUrl = urlService.getAccountUrl();
	return {
		authority: oidcEnvConfig.oidcAuthUrl, //The URL of the OIDC provider.
		client_id: oidcEnvConfig.oidcClientId, //Your OIDC client application's identifier as registered with the OIDC provider.
		client_secret: oidcEnvConfig.oidClientSecret, //Your OIDC client application's secret as registered with the OIDC provider.
		redirect_uri: `${accountBaseUrl}/Callback`, //The URI of your client application to receive a response from the OIDC provider.
		login: oidcEnvConfig.oidcAuthUrl + '/authentication/login',
		automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
		loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
		silent_redirect_uri: `${accountBaseUrl}/Callback`, //(string): The URL for the page containing the code handling the silent renew.
		post_logout_redirect_uri: `${accountBaseUrl}/Logout`, // (string): The OIDC post-logout redirect URI.
		audience: `${accountBaseUrl}/api/1.0/account/authorize/audience`, //is there a way to specific the audience when making the jwt
		response_type: 'code', //(string, default: 'id_token'): The type of response desired from the OIDC provider.
		scope: 'openid FFAccessAPI email', //(string, default: 'openid'): The scope being requested from the OIDC provider.
		webAuthResponseType: 'id_token token'
	};
};

export class OidcAuthService {
	userManager: UserManager;
	constructor() {
		const oidcIdentityConfig = getOidcIdentityConfig();
		this.userManager = new UserManager({
			...oidcIdentityConfig,
			userStore: new WebStorageStateStore({ store: window.sessionStorage }),
			metadata: {
				...oidcMetadata
			}
		});
		// Logger
		Log.logger = console;
		Log.level = Log.WARN;
		this.userManager.events.addUserLoaded(user => {
			Log.info('user loaded:', user);
		});
		this.userManager.events.addSilentRenewError(e => {
			Log.info('silent renew error', e.message);
		});
		this.userManager.events.addAccessTokenExpired(() => {
			Log.info('token expired');
			this.signInSilent();
		});
	}
	signInRedirectCallback = () => {
		Log.info('signinRedirectCallback called.');
		return this.userManager.signinRedirectCallback();
	};
	getUser = async () => {
		let user = await this.userManager.getUser();
		if (!user) {
			user = await this.userManager.signinRedirectCallback();
		}
		Log.info('user:', user);
		return user;
	};
	parseJwt = (token: string) => {
		Log.info('token:', token);
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace('-', '+').replace('_', '/');
		return JSON.parse(window.atob(base64));
	};
	signInRedirect = () => {
		localStorage.setItem('redirectUri', window.location.pathname);
		this.userManager.signinRedirect({});
	};
	isAuthenticated = () => {
		const oidcStorage = JSON.parse(
			sessionStorage.getItem(
				`oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
			) as string
		);
		return !!oidcStorage && !!oidcStorage.access_token;
	};
	signInSilent = () => {
		this.userManager
			.signinSilent()
			.then(user => {
				Log.info('signed in', user);
			})
			.catch(err => {
				Log.info(err);
			});
	};
	signInSilentCallback = () => {
		this.userManager.signinSilentCallback();
	};
	createSignInRequest = () => {
		return this.userManager.createSigninRequest();
	};
	logout = () => {
		this.userManager.signoutRedirect({
			id_token_hint: localStorage.getItem('id_token')
		});
		this.userManager.clearStaleState();
	};
	signOutRedirectCallback = () => {
		return this.userManager
			.signoutRedirectCallback()
			.then(() => {
				localStorage.clear();
				window.location.replace(oidcEnvConfig.oidcPublicUrl as string);
			})
			.finally(() => {
				this.userManager.clearStaleState();
			});
	};
}

export const oidcAuthService = new OidcAuthService();
