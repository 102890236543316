import { LookupRow } from '../lookup-types';
import { LookupType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class DefaultLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		return apiData as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		return rowToUpdate;
	}
	toLookupRowsFromAPI(apiData: LookupRow[]): LookupRow[] {
		return apiData;
	}
	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): LookupRow {
		newValue.lookupType = this.lookupType;
		return newValue;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
