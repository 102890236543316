import {
	DropDownOption,
	SendFacilityNoticeSteps,
	FilterType,
	LocalStorageName,
	ColumnField,
	RouteProps,
	Extractor,
	FogNoticeTemplate
} from '@rcp/types';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { History } from 'history';
import { SingleCheckbox, SingleSelectDropdown } from 'src/components';
import { StepperContent, StepperContext, StepperAction } from 'src/components/widgets/stepper';
import { alertService, RootState } from 'src/redux';
import {
	apiService,
	DateUtilService,
	localizationService,
	navigateTo,
	Resource,
	urlService,
	validationService
} from 'src/services';
import { GridOption, DataGrid } from 'src/features';
import { orderBy } from '@progress/kendo-data-query';
import { fogDevicesSlice } from 'src/components/authority/fog/fog-devices/devices.slice';
import { Utils } from '../../../../../../services/utils';
import { useSelector } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';

interface FormField extends FogNoticeTemplate {
	deviceIds: number[];
	templateNameError?: string;
}

interface Device extends Extractor {
	sendNotice?: boolean;
}

const initialFormField: FormField = {
	noticeType: '',
	templateName: '',
	templateNameError: '',
	deviceIds: []
};
const { STEP1, STEP2, STEP3, STEP4 } = SendFacilityNoticeSteps;
interface Props extends RouteProps {
	history: History;
	changeLabel?: (type: string) => void;
}

export const Step1 = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const [formField, setFormField] = React.useState(initialFormField);
	const [notices, setNotices] = React.useState<FogNoticeTemplate[]>([]);
	const [noticeOptions, setNoticeOptions] = React.useState<DropDownOption[]>([]);
	const [sendNoticeDisabledContacts, setSendNoticeDisabledContacts] = React.useState<number>(0);
	const [fogDeviceList, setFogDeviceList] = React.useState<Device[]>([]);

	const { result } = useSelector((state: RootState) => state.fogDevices);

	useEffect(() => {
		const numberOfDisabledContacts = result.reduce((acc, item) => {
			if (!item.sendCleaningNotices) {
				return acc + 1;
			}
			return acc + 0;
		}, 0);
		setSendNoticeDisabledContacts(numberOfDisabledContacts);
	}, [result]);

	const autoCheckDeviceCheckedSendCleaningNotices = false;
	useEffect(() => {
		const step1Data = stepperContext.getData(STEP1);
		if (step1Data === undefined) {
			let extractorIdList: number[] = autoCheckDeviceCheckedSendCleaningNotices
				? (result
						.filter((extractor: Extractor) => {
							return extractor.sendCleaningNotices;
						})
						.map(extractor => extractor.extractorId) as number[])
				: [];
			setFormField({
				...formField,
				deviceIds: extractorIdList
			});
			let list = result.map((extractor: Extractor) => {
				return {
					...extractor,
					sendNotice: autoCheckDeviceCheckedSendCleaningNotices ? extractor.sendCleaningNotices : false
				};
			});
			setFogDeviceList(list);
		} else {
			let list = result.map((extractor: Extractor) => {
				if (step1Data.deviceIds.includes(extractor.extractorId)) {
					return { ...extractor, sendNotice: extractor.sendCleaningNotices };
				} else {
					return { ...extractor, sendNotice: false };
				}
			});
			setFogDeviceList(list);
		}
	}, [result]);

	useEffect(() => {
		const step1Data = stepperContext.getData(STEP1);
		getNoticeTemplates();
		if (step1Data) {
			setFormField({ ...step1Data, templateNameError: '' });
		}
		fogDevicesSlice.setApiUrlPath(
			`${Resource.FogFacilities}/${urlService.getFogFacilityId()}/${Resource.NoticeExtractors}`
		);
		return () => fogDevicesSlice.setApiUrlPath(Resource.Extractors);
	}, []);

	const disableOtherSteps = () => {
		let step2State = { ...stepperContext.getStep(STEP2), completed: false, data: null };
		stepperContext.updateStep(STEP2, step2State);
		let step3State = { ...stepperContext.getStep(STEP3), completed: false, data: null };
		stepperContext.updateStep(STEP3, step3State);
		let step4State = { ...stepperContext.getStep(STEP4), completed: false, data: null };
		stepperContext.updateStep(STEP4, step4State);
	};

	const getNoticeTemplates = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${
			Resource.NoticeTemplates
		}?includeInactive=false`;
		apiService.getResource<FogNoticeTemplate[]>(url).then((templates: FogNoticeTemplate[]) => {
			let updatedTemplates = templates
				.filter((i: FogNoticeTemplate) => i.isScheduledAutomatically == false)
				.map((template, index) => {
					template.noticeTemplateIndex = index + 1;
					return template;
				});
			setNotices(updatedTemplates);
			const dropDownOptions = templates
				.filter((i: FogNoticeTemplate) => i.isScheduledAutomatically == false)
				.map((template: FogNoticeTemplate, index: number) => {
					return {
						label: template.templateName,
						value: index + 1,
						type: template.noticeType
					} as DropDownOption;
				});
			setNoticeOptions(dropDownOptions);
		});
	};

	const onClickNext = () => {
		let newState = { ...formField };
		const step1Data = stepperContext.getData(STEP1);
		const isDataChanged = step1Data ? (step1Data.templateName == newState.templateName ? false : true) : true;
		let deviceContactCount = 0;
		fogDeviceList.forEach(fogDevice => {
			fogDevice.sendNotice && (deviceContactCount += fogDevice.sendNoticeContactsCount || 0);
		});
		if (Utils.isDeviceCleaningNotice(formField.noticeType)) {
			newState.templateNameError = '';
			setFormField(newState);
			if (formField.noticeTemplateId && formField.deviceIds.length > 0) {
				alertService.clearAllMessages();
				stepperContext.resolve({ ...formField, isDataChanged, deviceContactCount });
				return;
			} else {
				alertService.addError(localizationService.getLocalizedString('facility.noDevicesSelected'));
			}
		} else {
			if (formField.letterTemplateId) {
				newState.templateNameError = '';
				setFormField(newState);
				alertService.clearAllMessages();
				stepperContext.resolve({ ...formField, isDataChanged });
				return;
			}
			validationService.validateRequiredField(
				newState,
				'letterTemplateId',
				'templateNameError',
				localizationService.getLocalizedString('authoritySetting.noticesSettings.noticeTemplate')
			);
			setFormField(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
	};

	const changeFormField = (e: any) => {
		let { value } = e.target;
		let selectedNotice =
			notices.find(notice => {
				return value + '' === notice.noticeTemplateIndex + '';
			}) || {};
		props.changeLabel && props.changeLabel(selectedNotice.noticeType || '');
		setFormField({ ...formField, ...selectedNotice });
		disableOtherSteps();
	};

	const deviceSendNoticeCheckBox = (items: any) => {
		const dataItem = items.dataItem as Extractor;
		const currentExtractorIndex: number = fogDeviceList.findIndex(
			device => device.extractorId === dataItem.extractorId
		);
		const step1Data = stepperContext.getData(STEP1);
		return dataItem.sendCleaningNotices ? (
			<td style={{ width: '0.1%' }}>
				<SingleCheckbox
					id={`device-${dataItem.extractorId}`}
					className="mx-auto"
					checked={
						currentExtractorIndex >= 0 && fogDeviceList.length > 0
							? fogDeviceList[currentExtractorIndex].sendNotice
							: false
					}
					onChange={(event?: any) => {
						disableOtherSteps();
						const { deviceIds } = step1Data || formField;
						if (event.target.checked) {
							deviceIds.push(dataItem.extractorId as number);
						} else {
							const removeDeviceIndex = deviceIds.findIndex((contact: number) => {
								return contact === dataItem.extractorId;
							});
							deviceIds.splice(removeDeviceIndex, 1);
						}
						if (currentExtractorIndex >= 0)
							fogDeviceList[currentExtractorIndex].sendNotice = event.target.checked;
						setFogDeviceList([...fogDeviceList]);
						setFormField({
							...formField,
							deviceIds: deviceIds
						});
					}}
					name="deviceId"
				/>
			</td>
		) : (
			<td style={{ width: '0.1%' }}></td>
		);
	};

	const deviceNumberToLink = (item?: any) => {
		const dataItem = item.dataItem;
		const url =
			window.location.origin + urlService.getReactAuthorityResourcePath(Resource.Devices, dataItem.extractorId);
		return (
			<td style={{ width: '0.5%' }}>
				<a
					href={url}
					onClick={(event?: any) => {
						navigateTo(props.history, url, event);
					}}>
					{dataItem.deviceNumber}
				</a>
			</td>
		);
	};

	const nextCleaningDate = (item?: any) => {
		const dataItem = item.dataItem;
		return <td style={{ width: '0.1%' }}>{DateUtilService.toDisplayDate(dataItem.nextCleaningDate)}</td>;
	};

	const getExtractorState = (deviceId: any) =>
		fogDeviceList.length > 0
			? fogDeviceList[fogDeviceList.findIndex(device => device.extractorId === deviceId)].sendNotice
			: false;

	const sortDevices = (devices: Device[], sortState: any[]): Device[] => {
		let sortedDevices = _.clone(devices);
		if (sortState[0] && sortState[0].field === 'sendNotice') {
			sortedDevices.sort(function(firstDevice: Device, secondDevice: Device) {
				const direction = sortState[0].dir == 'asc' ? 1 : -1;
				const firstDeviceState = getExtractorState(firstDevice.extractorId);
				const secondDeviceState = getExtractorState(secondDevice.extractorId);

				if (firstDeviceState == true && secondDeviceState == false && secondDevice.sendCleaningNotices)
					return 1 * direction;
				if (firstDeviceState == false && secondDeviceState == true && firstDevice.sendCleaningNotices)
					return -1 * direction;
				if (!firstDevice.sendCleaningNotices) return -1 * direction;
				if (!secondDevice.sendCleaningNotices) return 1 * direction;
				return 0;
			});
		} else {
			sortedDevices = orderBy(sortedDevices, sortState);
		}
		return sortedDevices;
	};

	const initialGridOptions: GridOption = {
		pageTitle: '',
		prefix: 'extractor',
		storageName: LocalStorageName.FogFacilityDevicesGrid,
		gridUrlLocalStorageName: LocalStorageName.FogFacilityDevicesFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FogFacilityDevicesSortOrder,
		defaultSort: [],
		doNotValidateParameters: true,
		allColumns: [
			new ColumnField('sendNotice', FilterType.Text, deviceSendNoticeCheckBox),
			new ColumnField('deviceNumber', FilterType.Text, deviceNumberToLink),
			new ColumnField('extractorDescription'),
			new ColumnField('nextCleaningDate', FilterType.Date, nextCleaningDate)
		],
		doCustomClientSort: sortDevices,
		defaultColumns: ['sendNotice', 'deviceNumber', 'extractorDescription', 'nextCleaningDate'],
		doNotApplyMaxTable: true
	};

	const deviceGrid = () => {
		return (
			<>
				<div className="recipient-grid device-grid-notices">
					<label className="device-grid-label mb-3">
						{localizationService.getLocalizedString('extractor.devices')}
					</label>
					<DataGrid
						gridOption={initialGridOptions}
						restSlice={fogDevicesSlice}
						restState={(state: RootState) => state.fogDevices}
						match={props.match}
						history={props.history}
					/>
				</div>
			</>
		);
	};

	return (
		<StepperContent
			className="w-100 mr-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<SingleSelectDropdown
				id="selectTemplate"
				name="noticeTemplateIndex"
				label={localizationService.getLocalizedString('authoritySetting.noticesSettings.noticeTemplate')}
				value={formField.noticeTemplateIndex}
				onChange={changeFormField}
				options={noticeOptions}
				isRequired={true}
				error={formField.templateNameError}
			/>
			{Utils.isDeviceCleaningNotice(formField.noticeType) && (
				<>
					{sendNoticeDisabledContacts > 0 && (
						<Notification
							icon={<SvgWarning />}
							className="recipient-warning d-inline-block p-0 mb-0"
							message={localizationService.getLocalizedString(
								'facility.noticeDisabledForDevice',
								sendNoticeDisabledContacts + '',
								_.toLower(
									localizationService.getLocalizedString(
										`extractor.${sendNoticeDisabledContacts > 1 ? 'devices' : 'device'}`
									)
								)
							)}
							showCloseButton={false}
						/>
					)}
					{deviceGrid()}
				</>
			)}
		</StepperContent>
	);
};
