import { urlService, DateUtilService, localizationService } from 'src/services';
import { Violation, InspectionEvent } from '@rcp/types';
import _ from 'lodash';
import { ViolationSourceType } from '.';

class ViolationService {
	getViolationSourceUrl = (violation: Violation, facilityId?: number) => {
		let sourceId = violation.sourceId;
		let sourceType = violation.sourceType;

		let url = urlService.getViolationSourceUrl(facilityId, sourceId, sourceType);
		return url;
	};

	getViolationSourceUrlText = (violation: Violation, inspectionEvent?: InspectionEvent, cleaningDueDate?: string) => {
		if (String.equalCaseInsensitive(violation.sourceType, ViolationSourceType.FogDeviceCleaning)) {
			return localizationService.getLocalizedString(
				'violation.cleaningViolation',
				DateUtilService.toDisplayDate(cleaningDueDate)
			);
		}
		if (inspectionEvent) {
			return `${inspectionEvent.inspectionType || ''} inspection on ${DateUtilService.toDisplayDate(
				inspectionEvent.completeDate
			)} `;
		}

		return '';
	};
}

export const violationService = new ViolationService();
