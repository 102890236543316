import React, { useEffect, useState } from 'react';
import { Dictionary, LetterTemplateTranslation, TranslationLanguage } from '@rcp/types';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useRootStateSelector } from 'src/redux';
import { loadLanguages } from 'src/redux/languages/languages';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { localizationService } from 'src/services';
import { LetterTemplateTranslationModal } from './letter-template-translation-modal';

import './letter-template-translation.scss';
import { FormField } from '../../../facilities/facility-details/facility-notice-steps';
import { FormFields } from './steps';

interface Props {
	noticeTemplate: FormField | FormFields;
	letterTemplateTranslationsJson?: string;
	letterTemplateTranslations: Dictionary<LetterTemplateTranslation>;
	setLetterTemplateTranslations: React.Dispatch<React.SetStateAction<Dictionary<LetterTemplateTranslation>>>;
}

export const LetterTemplateTranslations: React.FC<Props> = props => {
	const [loaded, setLoaded] = useState<boolean>(false);
	const [letterTemplateTranslations, setLetterTemplateTranslations] = useState<Dictionary<LetterTemplateTranslation>>(
		props.letterTemplateTranslations
	);
	const [languageOptions, setLanguageOptions] = React.useState<TranslationLanguage[]>([]);
	const [addingLetterTemplateTranslationLanguage, setAddingLetterTemplateTranslationLanguage] = React.useState<
		TranslationLanguage | undefined
	>(undefined);
	const [editingLetterTemplateTranslation, setEditingLetterTemplateTranslation] = React.useState<
		LetterTemplateTranslation | undefined
	>(undefined);

	const dispatch = useDispatch();
	const languages = useRootStateSelector(s => s.languages).languages;

	useEffect(() => {
		if (props.letterTemplateTranslationsJson) {
			let translations = JSON.parse(props.letterTemplateTranslationsJson);
			setLetterTemplateTranslations(translations);
			props.setLetterTemplateTranslations(translations);
		} else {
			setLetterTemplateTranslations({});
			props.setLetterTemplateTranslations({});
		}
	}, [props.letterTemplateTranslationsJson]);

	useEffect(() => {
		if (_.isEmpty(languages)) {
			dispatch(loadLanguages());
		}
	}, [languages]);

	const updateLanguageDropdown = (
		languages: TranslationLanguage[],
		translations: Dictionary<LetterTemplateTranslation>
	) => {
		let existingTranslationLanguageCodes = _.keys(translations);
		let options: TranslationLanguage[] = [];
		for (let language of languages) {
			if (!String.equalCI(language.code, 'en') && !_.includes(existingTranslationLanguageCodes, language.code)) {
				options.push(language);
			}
		}
		setLanguageOptions(options);
	};

	React.useEffect(() => {
		if (languages && languages.length > 0) {
			updateLanguageDropdown(languages, letterTemplateTranslations);
		}
	}, [letterTemplateTranslations, languages]);

	const closeLetterTranslationModal = () => {
		setAddingLetterTemplateTranslationLanguage(undefined);
		setEditingLetterTemplateTranslation(undefined);
	};
	const openAddLanguateTranslationModal = (language: TranslationLanguage) => {
		setAddingLetterTemplateTranslationLanguage(language);
	};
	const editLetterTemplateTranslation = (letterTemplateTranslation: LetterTemplateTranslation) => {
		setEditingLetterTemplateTranslation(letterTemplateTranslation);
	};
	const updateTranslations = (newState: Dictionary<LetterTemplateTranslation>) => {
		setLetterTemplateTranslations(newState);
		updateLanguageDropdown(languages, newState);
		props.setLetterTemplateTranslations(newState);
		closeLetterTranslationModal();
	};
	const addOrUpdateTranslation = (letterTemplateTranslation: LetterTemplateTranslation, isEditMode: boolean) => {
		let newLetterTemplateTranslations = _.cloneDeep(letterTemplateTranslations);
		newLetterTemplateTranslations[letterTemplateTranslation.languageCode as string] = letterTemplateTranslation;
		updateTranslations(newLetterTemplateTranslations);
	};
	const deleteTranslation = (letterTemplateTranslation: LetterTemplateTranslation) => {
		let newLetterTemplateTranslations = _.cloneDeep(letterTemplateTranslations);
		delete newLetterTemplateTranslations[letterTemplateTranslation.languageCode as string];
		updateTranslations(newLetterTemplateTranslations);
	};

	return (
		<div>
			<UncontrolledDropdown id="addTranslationDropdown" className="d-inline">
				<DropdownToggle i="languageSelectDropdownValue" tag="a" className="btn ai-white cursor-pointer" caret>
					{localizationService.getLocalizedString('screen.buttons.addTranslation')}
				</DropdownToggle>
				<DropdownMenu right>
					{languageOptions
						.sort((a, b) => (a.name > b.name ? 1 : -1))
						.map(lang => {
							return (
								<DropdownItem
									tag="a"
									id={`languageSelect_${lang.name}`}
									key={lang.code}
									onClick={() => openAddLanguateTranslationModal(lang)}>
									{lang.name}
								</DropdownItem>
							);
						})}
				</DropdownMenu>
			</UncontrolledDropdown>
			{_.map(
				_.sortBy(letterTemplateTranslations, ['languageName']),
				(translation: LetterTemplateTranslation, languageCode: string) => {
					return (
						<div key={languageCode} className="ai-translation-tag ml-2">
							<span>{translation.languageName}</span>
							<button
								onClick={event => {
									event.preventDefault();
									editLetterTemplateTranslation(translation);
								}}
								className="ml-2">
								<FontAwesomeIcon icon={faPencilAlt} className="font-awesome-icon" />
							</button>
						</div>
					);
				}
			)}
			{addingLetterTemplateTranslationLanguage && (
				<LetterTemplateTranslationModal
					noticeContent={props.noticeTemplate}
					close={closeLetterTranslationModal}
					addOrUpdateTranslation={addOrUpdateTranslation}
					deleteTranslation={deleteTranslation}
					language={addingLetterTemplateTranslationLanguage}
					isEditMode={false}
				/>
			)}
			{editingLetterTemplateTranslation && (
				<LetterTemplateTranslationModal
					close={closeLetterTranslationModal}
					addOrUpdateTranslation={addOrUpdateTranslation}
					deleteTranslation={deleteTranslation}
					letterTemplateTranslation={editingLetterTemplateTranslation}
					isEditMode={true}
				/>
			)}
		</div>
	);
};
