import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';

import { AttachmentOwnership, FogFacility, AttachmentType } from '@rcp/types';
import {
	ApplicationState,
	FacilityAttachmentsState,
	loadFacilityAttachments,
	defaultNumberOfAttachments
} from 'src/redux';
import { urlService, localizationService } from 'src/services';
import { CloseSvg } from '../../../../svg';
import { AddAttachment } from './add-attachment';

import 'src/components/widgets/attachment/attachment.scss';
import _ from 'lodash';
import { AttachmentThumbnail } from 'src/components/widgets';
import { useRouteMatch } from 'react-router-dom';

interface CompProps {
	facility?: FogFacility;
	attachmentType?: AttachmentType;
	reportView?: boolean;
	isReadOnly?: boolean;
	ownerId?: number;
	attachments?: AttachmentOwnership[];
	fromInspectionDetails?: boolean;
	onAttachmentClick?: (attachment: AttachmentOwnership) => void;
}

interface StateProps extends FacilityAttachmentsState {}

interface DispatchProps {
	loadFacilityAttachments: (
		facilityId: number,
		doesGetAll: boolean,
		attachmentType?: AttachmentType,
		ownerId?: number
	) => void;
}

type Props = StateProps & DispatchProps & CompProps;

const FacilityAttachmentsComp: React.SFC<Props> = props => {
	const loadFacilityAttachments = (doesGetAll = true) => {
		let facilityId = urlService.getFogFacilityId();

		if (facilityId < 1 && props.facility) {
			if (props.facility.facilityId) {
				facilityId = props.facility.facilityId;
			}
		}
		props.loadFacilityAttachments(facilityId, doesGetAll, props.attachmentType, props.ownerId);
	};

	let { url } = useRouteMatch();

	React.useEffect(() => {
		if (String.equalCaseInsensitive(props.attachmentType, AttachmentType.InspectionAttachment)) {
			if (props.ownerId) loadFacilityAttachments(false);
		} else {
			loadFacilityAttachments(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.ownerId]);

	const cancelShowAll = (e: any) => {
		e.preventDefault();
		if (props.facilityAttachments.length > defaultNumberOfAttachments) {
			loadFacilityAttachments(false);
		}
	};

	const onShowAllClicked = (e: any) => {
		e.preventDefault();
		loadFacilityAttachments();
	};

	const getAttachmentDetailUrl = (attachment: AttachmentOwnership) => {
		let returnRef = `${url}/attachments/${attachment.attachmentId}`;
		if (
			props.attachmentType &&
			String.equalCaseInsensitive(attachment.attachmentType, AttachmentType.InspectionAttachment)
		) {
			returnRef += `?backToLabel=backToInspection`;
		}
		return returnRef;
	};

	let attachments = props.facilityAttachments;

	return (
		<section className="attachments">
			<div className="d-flex">
				<h3>{localizationService.getLocalizedString('attachments.title')}</h3>
				{props.totalFacilityAttachments > defaultNumberOfAttachments &&
					attachments.length === props.totalFacilityAttachments && (
						<button className="close ml-auto" onClick={cancelShowAll}>
							<CloseSvg />
						</button>
					)}
			</div>
			<div className="attachment-list mx-n1">
				{attachments.map((attachment: AttachmentOwnership, index: number) => {
					const linkTo = getAttachmentDetailUrl(attachment);
					return (
						<AttachmentThumbnail
							attachment={attachment}
							facilityWidget={props.attachmentType == null}
							reportView={props.reportView}
							key={`icon-${index}`}
							className="attachment-thumbnail m-1"
							linkTo={linkTo}
							editMode={!props.isReadOnly && props.fromInspectionDetails}
							noNavigation={!props.isReadOnly}
							onAttachmentClick={props.onAttachmentClick}
						/>
					);
				})}
			</div>
			<div className="d-flex">
				<div className="w-90">
					{props.isReadOnly === false && (
						<AddAttachment
							facility={props.facility}
							attachmentType={props.attachmentType}
							ownerId={props.ownerId}
							uploadCallBack={loadFacilityAttachments}
						/>
					)}
				</div>
				{props.totalFacilityAttachments > defaultNumberOfAttachments &&
					props.totalFacilityAttachments !== attachments.length && (
						<button
							type="button"
							className="pr-0 ml-auto text-right ai-edit-link btn btn-link"
							onClick={onShowAllClicked}>
							{localizationService.getLocalizedString('attachments.showAll')}
						</button>
					)}
			</div>
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityAttachments };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => ({
	loadFacilityAttachments: (
		facilityId: number,
		doesGetAll: boolean,
		attachmentType?: AttachmentType,
		ownerId?: number
	) => dispatch(loadFacilityAttachments(facilityId, doesGetAll, attachmentType, ownerId))
});

export const FacilityAttachments = connect<StateProps, DispatchProps, CompProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityAttachmentsComp);
