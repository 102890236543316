import * as ApiTypes from '@rcp/types';

export interface FogFacilityState {
	facility: ApiTypes.FogFacility;
	isSaving: boolean;
	facilityTimelineEnforcements: ApiTypes.Enforcement[];
	facilityAllViolations: ApiTypes.Violation[];
}

export const initialFogFacilityState: FogFacilityState = {
	facility: {},
	isSaving: false,
	facilityTimelineEnforcements: [],
	facilityAllViolations: []
};
