import {
	ApiError,
	AuthorityOrpSetting,
	DropDownOption,
	SettingType,
	DeviceCleaningFrequencySettingTypes
} from '@rcp/types';
import React, { useEffect, useState } from 'react';
import { apiService, localizationService, optionsMap, Resource, urlService, UtilService } from 'src/services';
import 'src/components/authority/shared/settings/authority-settings.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { alertService, loadAuthoritySettings, loadFrequencyList, RootState } from 'src/redux';
import { SingleSelectDropdown } from 'src/components/widgets';

const NewDeviceCleaningFrequencyComponent: React.FunctionComponent = () => {
	let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;
	const dispatch = useDispatch();

	const [testFrequencies, setTestFrequencies] = useState<DropDownOption[]>([]);
	const [authoritySetting, setAuthoritySettingForNewDevice] = useState(initialAuthorityOrpSetting);
	const [originalAuthoritySetting, setOriginalAuthoritySetting] = useState(initialAuthorityOrpSetting);

	const reduxAuthoritySettings = useSelector((state: RootState) => state.authoritySettingLookups.authoritySettings);
	let frequencies = useSelector((state: RootState) => state.extractors.frequencyList);

	useEffect(() => {
		if (!_.isEmpty(frequencies)) {
			setTestFrequencies(optionsMap.fromFrequencyList(frequencies));
		}
	}, [frequencies]);

	useEffect(() => {
		if (!_.isEmpty(reduxAuthoritySettings)) {
			let newAuthoritySetting = { ...authoritySetting };
			for (let settingValue of reduxAuthoritySettings) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				newAuthoritySetting[key] = settingValue.value;
			}

			if (
				(!newAuthoritySetting.defaultDeviceCleaningScheduleFrequencyId ||
					newAuthoritySetting.defaultDeviceCleaningScheduleFrequencyId === 0) &&
				frequencies.length > 0
			) {
				newAuthoritySetting.defaultDeviceCleaningScheduleFrequencyId = frequencies[0].frequencyId;
				setDefaultFrequency(frequencies[0].frequencyId as number);
			}
			setAuthoritySettingForNewDevice(newAuthoritySetting);
			setOriginalAuthoritySetting(newAuthoritySetting);
		}
	}, [reduxAuthoritySettings, frequencies]);

	const setDefaultFrequency = (frequencyId: number) => {
		let authoritySettingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		let dataToUpdate = {
			settingType: SettingType.DefaultDeviceCleaningScheduleFrequencyId,
			value: frequencyId
		};
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
			});
	};

	const updateAuthoritySettingValueNewDevice = (e: any) => {
		const { name, value } = e.target;
		let dataToUpdate = {
			settingType: name,
			value: value
		};
		if (originalAuthoritySetting[name] === value) {
			return;
		}
		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthoritySettingForNewDevice(newSetting);
		let authoritySettingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				let updateAuthoritySetting = { ...originalAuthoritySetting };
				updateAuthoritySetting[name] = value;
				setOriginalAuthoritySetting(updateAuthoritySetting);
				setAuthoritySettingForNewDevice(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySettingForNewDevice(newSetting);
			});
	};

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>
					{localizationService.getLocalizedString('authoritySetting.device.newDeviceCleaningTitle')}
				</strong>
				<p>{localizationService.getLocalizedString('authoritySetting.device.newDeviceCleaningDesc')}</p>
			</div>
			<div className="col-lg-8">
				<section>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input"
							name="defaultDeviceCleaningScheduleFrequencyOption"
							checked={String.equalCaseInsensitive(
								authoritySetting.defaultDeviceCleaningScheduleFrequencyOption,
								DeviceCleaningFrequencySettingTypes.None
							)}
							id="noneNewDevice"
							onChange={updateAuthoritySettingValueNewDevice}
							value={DeviceCleaningFrequencySettingTypes.None}
						/>
						<label className="custom-control-label" htmlFor="noneNewDevice">
							<span>
								{localizationService.getLocalizedString(
									'authoritySetting.device.newDeviceDoNotScheduleNext'
								)}
								&nbsp;
								<strong>
									{localizationService.getLocalizedString(
										'authoritySetting.device.cleaningFrequency'
									)}
								</strong>
							</span>
						</label>
					</div>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input"
							name="defaultDeviceCleaningScheduleFrequencyOption"
							checked={String.equalCaseInsensitive(
								authoritySetting.defaultDeviceCleaningScheduleFrequencyOption,
								DeviceCleaningFrequencySettingTypes.Selected
							)}
							id="selectedNewDevice"
							onChange={updateAuthoritySettingValueNewDevice}
							value={DeviceCleaningFrequencySettingTypes.Selected}
						/>
						<label className="custom-control-label" htmlFor="selectedNewDevice">
							<span>
								{localizationService.getLocalizedString(
									'authoritySetting.device.customDeviceCleaningFrequency'
								)}
							</span>
						</label>
					</div>
					{String.equalCaseInsensitive(
						authoritySetting.defaultDeviceCleaningScheduleFrequencyOption,
						DeviceCleaningFrequencySettingTypes.Selected
					) &&
						testFrequencies.length > 0 && (
							<SingleSelectDropdown
								id="defaultdeviceCleaningScheduleFrequencyId"
								name="defaultdeviceCleaningScheduleFrequencyId"
								className="pl-5"
								isRequired={true}
								label={localizationService.getLocalizedString('authoritySetting.frequency.frequency')}
								value={_.toString(authoritySetting.defaultDeviceCleaningScheduleFrequencyId)}
								onChange={updateAuthoritySettingValueNewDevice}
								options={testFrequencies}
								selfOrder={true}
							/>
						)}
				</section>
			</div>
		</div>
	);
};

export const NewDeviceCleaningFrequencySettingComponent = NewDeviceCleaningFrequencyComponent;
