import React from 'react';

import {
	CallLogInputFields,
	commentAndDateValidation,
	prepareDateAndTimeStringForApiCall
} from './callLog-input-fields';
import * as ApiTypes from '@rcp/types';
import { useReduxDispatch } from 'src/redux';
import { localizationService } from 'src/services';
import { PopoverModal, DeleteModal } from 'src/components/widgets';
import { editFogFacilityCallLog, deleteFogFacilityCallLog } from 'src/redux/callLog';

interface OwnProps {
	showModal: boolean;
	setShowModal: () => void;
	callLog: ApiTypes.CallLog;
}
type Props = OwnProps;

export const EditCallLogModal: React.SFC<Props> = props => {
	const [comment, setComment] = React.useState(props.callLog.commentText);
	const [callDate, setCallDate] = React.useState(props.callLog.callLogDateTime);
	const [callTime, setCallTime] = React.useState(props.callLog.callLogDateTime);
	const [callDateError, setCallDateError] = React.useState('');
	const [toggleDeleteCallLogModal, setToggleDeleteCallLogModal] = React.useState(false);
	const [commentError, setCommentError] = React.useState('');

	const dispatch = useReduxDispatch();

	const onCommentChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		e.preventDefault();
		e.stopPropagation();

		setComment(e.target.value);
	};

	const onCallDateChanged = (e: any) => {
		const callDate = e.target.value;
		setCallDate(callDate);
	};

	const onCallTimeChanged = (e: any) => {
		const callTime = e.target.value;
		setCallTime(callTime);
	};

	const closeModal = () => {
		props.setShowModal();
	};

	const onSaveBtnClick = () => {
		if (!commentAndDateValidation(comment, callDate, setCommentError, setCallDateError)) {
			return;
		}
		let callDateTimeStr = prepareDateAndTimeStringForApiCall(callDate as string, callTime);
		let callLogId = props.callLog.callLogId as number;
		dispatch(editFogFacilityCallLog(callLogId, comment, callDateTimeStr, callTime !== null));
		props.setShowModal();
	};

	const onCancelBtnClick = () => {
		props.setShowModal();
	};

	const deleteCallLog = async () => {
		setToggleDeleteCallLogModal(false);
		if (props.callLog) {
			let callLogId = props.callLog.callLogId as number;
			dispatch(deleteFogFacilityCallLog(callLogId));
		}
		props.setShowModal();
	};

	const modalFooterDiv = () => {
		return (
			<>
				<button
					id="deleteBtn"
					className="btn ai-secondary-delete"
					onClick={() => {
						setToggleDeleteCallLogModal(true);
					}}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
				<div className="ml-auto">
					<button id="saveBtn" className="btn ai-save" onClick={onSaveBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button id="cancelBtn" className="btn ai-white ml-2" onClick={onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div>
			<PopoverModal
				className="popup-modal-edit"
				showModal={props.showModal}
				title={localizationService.getLocalizedString('callLog.editCallLogModalTitle')}
				footer={modalFooterDiv()}
				cancel={closeModal}>
				<CallLogInputFields
					showModal={props.showModal}
					onCommentChanged={onCommentChanged}
					onCallDateChanged={onCallDateChanged}
					onCallTimeChanged={onCallTimeChanged}
					comment={comment}
					callDate={callDate}
					callTime={callTime}
					callDateError={callDateError}
					commentError={commentError}
				/>
			</PopoverModal>
			{toggleDeleteCallLogModal && (
				<DeleteModal
					key="confirmDeleteCallLogModal"
					title={localizationService.getLocalizedString('callLog.deleteCallLogTitle')}
					message={localizationService.getLocalizedString('callLog.deleteCallLogMessage')}
					showModal={toggleDeleteCallLogModal}
					onCancelButtonClick={() => {
						setToggleDeleteCallLogModal(false);
					}}
					onOkayButtonClick={deleteCallLog}
					okayButtonText={localizationService.getLocalizedString('callLog.deleteCallLogButton')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
