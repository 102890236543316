import React, { FC } from 'react';
import { localizationService } from 'src/services';
import _ from 'lodash';
import { DataImport, CustomFieldApplyToTable, CustomFieldDefinition } from '@rcp/types';
import { RootState } from 'src/redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import CustomFieldEditor, { EditorMode } from 'src/components/authority/shared/settings/editors/custom-field-editor';

export interface ConfigurationEditorProp {}

interface Props {
	mode: EditorMode;
	importColumnName?: string;
	customField?: CustomFieldDefinition;
	onClosed: () => void;
	onSuccessCallback: (customField: CustomFieldDefinition, forCreation: boolean) => void;
}

export const FieldMappingCustomFieldEditor: FC<Props> = props => {
	const selfImportState = useSelector((state: RootState) => state.selfImport).current;

	return (
		<div className="side pl-0 pl-2 mt-2">
			<div>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className="preview-email-chevron font-size-18px"
					onClick={props.onClosed}
				/>
			</div>
			<h2 className="side-title my-4">
				{localizationService.getLocalizedString(
					props.mode === EditorMode.Add
						? 'authoritySetting.addCustomField'
						: 'authoritySetting.editCustomField'
				)}
			</h2>

			{selfImportState.importDataType === DataImport.ImportDataTypeValues.FogFacilitiesAndContacts && (
				<CustomFieldEditor
					table={CustomFieldApplyToTable.ApplyToFogFacility}
					show={true}
					mode={props.mode}
					customField={props.customField}
					description={localizationService.getLocalizedString(
						props.mode === EditorMode.Add
							? 'import.steps.addCustomFieldDescription'
							: 'import.steps.updateCustomFieldOptions',
						_.toLower(localizationService.getLocalizedString('screen.labels.facility'))
					)}
					onClosed={props.onClosed}
					onSuccessCallback={props.onSuccessCallback}
					defaultLabel={props.importColumnName}
					embeddedMode={true}
				/>
			)}
		</div>
	);
};
