import { Action } from 'redux';
import * as ApiTypes from '@rcp/types';

export enum FogFacilityActionType {
	LoadFogFacilityRequest = 'loadFogFacilityRequest',
	LoadFogFacilitySuccess = 'loadFogFacilitySuccess',
	SaveFogFacilityRequest = 'saveFogFacilityRequest',
	SaveFogFacilitySuccess = 'saveFogFacilitySuccess',
	SaveFogFacilityCommentRequest = 'saveFogFacilityCommentRequest',
	SaveFogFacilityCommentSuccess = 'saveFogFacilityCommentSuccess',
	AddFogFacilityRequest = 'addFogFacilityRequest',
	AddFogFacilitySuccess = 'addFogFacilitySuccess',
	LoadFacilityTimelineEnforcements = 'LoadFacilityTimelineEnforcements',
	LoadFacilityAllViolations = 'LoadFacilityAllViolations',
	ResetFacilityData = 'ResetFacilityData'
}

export interface LoadFogFacilityRequest extends Action {
	type: FogFacilityActionType.LoadFogFacilityRequest;
}

export interface LoadFogFacilitySuccess extends Action {
	type: FogFacilityActionType.LoadFogFacilitySuccess;
	facility: ApiTypes.FogFacility;
}

export interface SaveFogFacilityRequest extends Action {
	type: FogFacilityActionType.SaveFogFacilityRequest;
}

export interface SaveFogFacilitySuccess extends Action {
	type: FogFacilityActionType.SaveFogFacilitySuccess;
	facility: ApiTypes.FogFacility;
}

export interface SaveFogFacilityCommentRequest extends Action {
	type: FogFacilityActionType.SaveFogFacilityCommentRequest;
}

export interface SaveFogFacilityCommentSuccess extends Action {
	type: FogFacilityActionType.SaveFogFacilityCommentSuccess;
}

export interface AddFogFacilityRequest extends Action {
	type: FogFacilityActionType.AddFogFacilityRequest;
}

export interface AddFogFacilitySuccess extends Action {
	type: FogFacilityActionType.AddFogFacilitySuccess;
	facility: ApiTypes.FogFacility;
}

export interface ResetFacilityData extends Action {
	type: FogFacilityActionType.ResetFacilityData;
}

export type FogFacilityAction =
	| LoadFogFacilityRequest
	| LoadFogFacilitySuccess
	| SaveFogFacilityRequest
	| SaveFogFacilitySuccess
	| SaveFogFacilityCommentRequest
	| SaveFogFacilityCommentSuccess
	| AddFogFacilityRequest
	| AddFogFacilitySuccess
	| ResetFacilityData;

export const loadFogFacilityRequest = (): LoadFogFacilityRequest => ({
	type: FogFacilityActionType.LoadFogFacilityRequest
});

export const loadFogFacilitySuccess = (facility: ApiTypes.FogFacility): LoadFogFacilitySuccess => ({
	type: FogFacilityActionType.LoadFogFacilitySuccess,
	facility
});

export const saveFogFacilityRequest = (): SaveFogFacilityRequest => ({
	type: FogFacilityActionType.SaveFogFacilityRequest
});

export const saveFogFacilitySuccess = (savedFacility: ApiTypes.FogFacility): SaveFogFacilitySuccess => ({
	type: FogFacilityActionType.SaveFogFacilitySuccess,
	facility: savedFacility
});

export const saveFogFacilityCommentRequest = (): SaveFogFacilityCommentRequest => ({
	type: FogFacilityActionType.SaveFogFacilityCommentRequest
});

export const saveFogFacilityCommentSuccess = (): SaveFogFacilityCommentSuccess => ({
	type: FogFacilityActionType.SaveFogFacilityCommentSuccess
});

export const addFogFacilityRequest = (): AddFogFacilityRequest => ({
	type: FogFacilityActionType.AddFogFacilityRequest
});

export const addFogFacilitySuccess = (facility: ApiTypes.FogFacility): AddFogFacilitySuccess => ({
	type: FogFacilityActionType.AddFogFacilitySuccess,
	facility: facility
});

export const resetFacilityData = (): ResetFacilityData => ({
	type: FogFacilityActionType.ResetFacilityData
});
