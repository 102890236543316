import { apiService, urlService, Resource, QueryParameters, localStorageService } from '.';
import * as ApiTypes from '@rcp/types';
import _ from 'lodash';
import { DashboardWidgetFilterCategories } from '@rcp/types';

export class DashboardService {
	getGridPath = (filterId: number, filterCategory?: ApiTypes.DashboardWidgetFilterCategories) => {
		let queryParameters = new QueryParameters();
		if (filterCategory) {
			this.updateMapUrlWithMissingLocalStorageQueryParameters(queryParameters, filterCategory);
		}
		return `/${urlService.getCurrentProgramOrDefault()}/${filterCategory}?filterId=${filterId}&${queryParameters.toQueryString()}`;
	};

	getCountResultByFilter = async (
		filterId?: number,
		filterCategory?: ApiTypes.DashboardWidgetFilterCategories,
		showLoading?: boolean
	): Promise<number> => {
		if (!filterId || !filterCategory) {
			return 0;
		}

		let resource = '';

		switch (filterCategory) {
			case ApiTypes.DashboardWidgetFilterCategories.Facilities:
				resource = ApiTypes.ApiEndpoints.FogFacilities;
				break;
			case ApiTypes.DashboardWidgetFilterCategories.Inspections:
				resource = ApiTypes.ApiEndpoints.InspectionEvents;
				break;
			case ApiTypes.DashboardWidgetFilterCategories.Violations:
				resource = ApiTypes.ApiEndpoints.Violations;
				break;
			default:
				resource = filterCategory;
		}

		let queryParameters: ApiTypes.Dictionary<string> = {} as ApiTypes.Dictionary<string>;
		_.set(queryParameters, 'filterId', filterId);
		_.set(queryParameters, 'size', 1);
		let url = urlService.getAuthorityResourcesApiUrl(resource, urlService.toQueryString(queryParameters));
		const apiResult = await apiService.getPaginatedResources(url, showLoading);
		return apiResult.total;
	};

	getSystemWidgetTemplates = async (): Promise<ApiTypes.DashboardSystemWidgetTemplate[]> => {
		const url = `${urlService.getAuthorityResourcesApiUrl(Resource.Dashboard)}/SystemTemplates/`;
		return apiService.getResource<ApiTypes.DashboardSystemWidgetTemplate[]>(url);
	};

	getMapPath = (filter: ApiTypes.DashboardWidgetFilter) => {
		let queryParameters = new QueryParameters();
		if (filter.filterCategory) {
			this.updateMapUrlWithMissingLocalStorageQueryParameters(queryParameters, filter.filterCategory);
		}

		if (String.equalCaseInsensitive(filter.filterCategory, ApiTypes.DashboardWidgetFilterCategories.Incidents)) {
			queryParameters.add('incidentFilterId', _.toString(filter.filterId));
		} else {
			queryParameters.add('filterId', _.toString(filter.filterId));
		}

		return `/${urlService.getCurrentProgramOrDefault()}/map?${queryParameters.toQueryString()}`;
	};

	updateMapUrlWithMissingLocalStorageQueryParameters = (
		parameters: QueryParameters,
		filterCategory: DashboardWidgetFilterCategories
	) => {
		let localStorageMapURL = _.toString(localStorageService.getLocalStorage(ApiTypes.LocalStorageName.FacilityMap));
		let queryParams =
			localStorageMapURL.split('?').length == 2
				? urlService.toQueryDictionary(localStorageMapURL.split('?')[1])
				: ({} as ApiTypes.Dictionary<string>);
		let queryParamKeys = _.keys(queryParams);
		if (queryParamKeys.includes('search')) {
			parameters.add('search', queryParams['search']);
		}
		if (String.equalCaseInsensitive(filterCategory, DashboardWidgetFilterCategories.Incidents)) {
			if (queryParamKeys.includes('filterId')) {
				parameters.add('filterId', queryParams['filterId']);
			} else if (queryParamKeys.includes('draftFilterId')) {
				parameters.add('draftFilterId', queryParams['draftFilterId']);
			}
		} else {
			if (queryParamKeys.includes('incidentFilterId')) {
				parameters.add('incidentFilterId', queryParams['incidentFilterId']);
			} else if (queryParamKeys.includes('incidentDraftFilterId')) {
				parameters.add('incidentDraftFilterId', queryParams['incidentDraftFilterId']);
			}
		}
	};
}

export const dashboardService = new DashboardService();
