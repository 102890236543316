import React, { useEffect, useState } from 'react';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { DropDownOption, NoticeTemplateSchedule, NoticeTemplateType } from '@rcp/types';
import './notice-template-editor.scss';
import { useDispatch, useSelector } from 'react-redux';
import { noticeTemplateScheduleSlice } from './notice-setting.slice';
import { History } from 'history';
import { alertService, RootState } from 'src/redux';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';

export enum EditorMode {
	Add,
	Update,
	Hide
}

interface Props {
	show: boolean;
	history: History;
}

interface NoticeTemplateEditorState {
	loading: boolean;
	mode: EditorMode;
	noticeTemplateSchedule: NoticeTemplateSchedule;
	isBeforeDueDate: string;
	templateNameError: string | undefined;
	scheduleNumberError: string | undefined;
	scheduleNameError: string | undefined;
	previousDaysNoticeError: string | undefined;
}

export enum DueDateTimeUnit {
	WEEK = 'WEEK',
	DAY = 'DAY',
	MONTH = 'MONTH'
}

const NoticeTemplateEditorComponent: React.FunctionComponent<Props> = props => {
	const noticeTemplateScheduleId = urlService.getNoticeTemplateScheduleId();
	const dispatch = useDispatch();

	let { result: list } = useSelector((state: RootState) => state.noticeTemplateSchedules);

	let initialMode: EditorMode = EditorMode.Add;
	let initLoading = false;
	let initialNoticeTemplate: NoticeTemplateSchedule = {
		templateName: '',
		isActive: true,
		noticeTemplateScheduleId: 0,
		isBeforeDueDate: true,
		numberOfDaysAfterPreviousNotice: 0,
		scheduleDueDateTimeUnitName: DueDateTimeUnit[DueDateTimeUnit.DAY],
		scheduleDueDateTimeUnitNumber: 0,
		isShutoff: false
	};

	if (noticeTemplateScheduleId > 0) {
		initialMode = EditorMode.Update;
	}

	let initialState: NoticeTemplateEditorState = {
		noticeTemplateSchedule: initialNoticeTemplate,
		mode: initialMode,
		loading: initLoading,
		templateNameError: '',
		scheduleNumberError: '',
		scheduleNameError: '',
		previousDaysNoticeError: '',
		isBeforeDueDate: 'true'
	};

	let [state, setState] = useState(initialState);
	useEffect(() => {
		dispatch(noticeTemplateScheduleSlice.fetchAll());
		if (noticeTemplateScheduleId > 0) {
			dispatch(
				noticeTemplateScheduleSlice.fetchOne(noticeTemplateScheduleId, schedule => {
					const isBeforeDueDate = schedule.isBeforeDueDate ? 'true' : 'false';
					setState(s => {
						return { ...s, noticeTemplateSchedule: schedule, isBeforeDueDate: isBeforeDueDate };
					});
				})
			);
		}
	}, [dispatch, noticeTemplateScheduleId]);

	const beforeAfterOptions: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('authoritySetting.notice.before'),
			value: 'true'
		},
		{
			label: localizationService.getLocalizedString('authoritySetting.notice.after'),
			value: 'false'
		}
	];

	const hasName = (name: string, id: number) => {
		if (list && list.length > 0) {
			for (let template of list) {
				if (
					String.equalCaseInsensitive(name, template.templateName) &&
					template.noticeTemplateScheduleId !== id
				) {
					return true;
				}
			}
		}

		return false;
	};

	const onSaveClicked = async () => {
		if (state.mode === EditorMode.Add) {
			if (hasName(state.noticeTemplateSchedule.templateName, 0)) {
				setState({
					...state,
					templateNameError: localizationService.getLocalizedString('authoritySetting.notice.nameExists')
				});
				alertService.addError(localizationService.getLocalizedString('authoritySetting.notice.nameExists'));
				return;
			}
			let name = state.noticeTemplateSchedule.templateName.trim();
			if (!name) {
				setState({
					...state,
					templateNameError: localizationService.getLocalizedString('authoritySetting.notice.nameRequired')
				});
				alertService.addError(localizationService.getLocalizedString('authoritySetting.notice.nameRequired'));
				return;
			}

			if (name.length > 50) {
				alertService.addError(
					localizationService.getLocalizedString('authoritySetting.notice.nameMaximumLength', '50')
				);
				return;
			}
			dispatch(
				noticeTemplateScheduleSlice.createOne(state.noticeTemplateSchedule, false, undefined, () => {
					onClose();
					window.setTimeout(function() {
						alertService.addSuccess(
							localizationService.getLocalizedString('authoritySetting.notice.createSucceed')
						);
					}, 500);
				})
			);
		} else if (state.mode === EditorMode.Update) {
			if (
				hasName(
					state.noticeTemplateSchedule.templateName,
					state.noticeTemplateSchedule.noticeTemplateScheduleId
				)
			) {
				setState({
					...state,
					templateNameError: localizationService.getLocalizedString('authoritySetting.notice.nameExists')
				});
				alertService.addError(localizationService.getLocalizedString('authoritySetting.notice.nameExists'));
				return;
			}

			const name = state.noticeTemplateSchedule.templateName.trim();
			if (!name) {
				setState({
					...state,
					templateNameError: localizationService.getLocalizedString('authoritySetting.notice.nameRequired')
				});
				alertService.addError(localizationService.getLocalizedString('authoritySetting.notice.nameRequired'));
				return;
			}

			if (name.length > 50) {
				alertService.addError(
					localizationService.getLocalizedString('authoritySetting.notice.nameMaximumLength', '50')
				);
				return;
			}

			dispatch(
				noticeTemplateScheduleSlice.patchOne(
					state.noticeTemplateSchedule.noticeTemplateScheduleId,
					state.noticeTemplateSchedule,
					false,
					undefined,
					() => {
						onClose();
						window.setTimeout(function() {
							alertService.addSuccess(
								localizationService.getLocalizedString('authoritySetting.notice.updateSucceed')
							);
						}, 500);
					}
				)
			);
		}
	};

	const onCancelClicked = () => {
		onClose();
	};

	const onClose = () => {
		const url = urlService.getReactResourceUrl(Resource.SettingNoticeTemplateSchedule);
		navigateTo(props.history, url);
	};

	const onPreviewClicked = () => {
		const url = `${urlService.getAuthorityResourcesApiUrl(Resource.SettingNoticeTemplateSchedule)}/preview/${
			state.noticeTemplateSchedule.templateName
		}`;
		return apiService.getResource(url);
	};

	const footer = () => {
		return (
			<div className="mt-4">
				<div className="ml-auto d-flex flex-row justify-content-between align-items-center">
					<div>
						<button id="btnPreview" className="btn ai-action btn-secondary" onClick={onPreviewClicked}>
							{localizationService.getLocalizedString('screen.buttons.preview')}
						</button>
					</div>

					<div className="d-flex flex-row">
						<button id="btnSave" className="btn ai-save ml-2" onClick={onSaveClicked}>
							{localizationService.getLocalizedString('screen.buttons.save')}
						</button>
						<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</button>
					</div>
				</div>
			</div>
		);
	};

	const onTemplateNameChanged = (e: any) => {
		let { value } = e.target;
		setState({
			...state,
			templateNameError: '',
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, templateName: value }
		});
	};

	const onScheduleNumberChanged = (e: any) => {
		let { value } = e.target;
		if (value < 0) {
			e.preventDefault();
			return;
		}

		setState({
			...state,
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, scheduleDueDateTimeUnitNumber: value }
		});
	};

	const onScheduleNumberBlur = (e: any) => {
		let { value } = e.target;
		if (!value || value.trim().length === 0) {
			value = '0';
		}

		setState({
			...state,
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, scheduleDueDateTimeUnitNumber: value }
		});
	};

	const onPreviousNoticeDaysBlur = (e: any) => {
		let { value } = e.target;
		if (!value || value.trim().length === 0) {
			value = '0';
		}

		setState({
			...state,
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, numberOfDaysAfterPreviousNotice: value }
		});
	};

	const onPreviousNoticeDaysChanged = (e: any) => {
		let { value } = e.target;
		if (value < 0) {
			e.preventDefault();
			return;
		}
		setState({
			...state,
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, numberOfDaysAfterPreviousNotice: value }
		});
	};

	const onIsBeforeDueDateChanged = (e: any) => {
		let { value } = e.target;
		let isBeforeDueDate = false;
		if (String.equalCaseInsensitive(value, 'true')) {
			isBeforeDueDate = true;
		}
		setState({
			...state,
			isBeforeDueDate: value,
			noticeTemplateSchedule: { ...state.noticeTemplateSchedule, isBeforeDueDate: isBeforeDueDate }
		});
	};

	// @ts-ignore
	return (
		<>
			<div className="page">
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('authoritySetting.notice.template')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main">
						<section>
							<TextInput
								id="templateName"
								name="templateName"
								value={state.noticeTemplateSchedule.templateName}
								isRequired={true}
								onChange={onTemplateNameChanged}
								error={state.templateNameError}
								label={localizationService.getLocalizedString('authoritySetting.notice.name')}
							/>

							<div className="form-inline">
								<input
									id="schedule_number"
									name="schedule_number"
									data-piggyback=""
									type="number"
									className={
										'form-control value col-1 inline-input' +
										(state.scheduleNumberError ? ' is-invalid' : '')
									}
									value={state.noticeTemplateSchedule.scheduleDueDateTimeUnitNumber}
									placeholder={''}
									onChange={onScheduleNumberChanged}
									onBlur={onScheduleNumberBlur}
									required={true}
									autoComplete={'off'}
								/>

								<span className="m-2 ">
									{localizationService.getLocalizedString('authoritySetting.notice.day')}
								</span>

								<SingleSelectDropdown
									className="p-0 m-0"
									id="schedule_name"
									name="schedule_name"
									noEmptyOption={true}
									selfOrder={true}
									value={state.isBeforeDueDate}
									label={''}
									onChange={onIsBeforeDueDateChanged}
									options={beforeAfterOptions}
								/>
								<span className={'m-2'}>
									{localizationService.getLocalizedString('authoritySetting.notice.schedule_1')}
								</span>

								<input
									id="previous_notice_days"
									name="previous_notice_days"
									data-piggyback=""
									type="number"
									className={
										'form-control col-1 value inline-input' +
										(state.previousDaysNoticeError ? ' is-invalid' : '')
									}
									value={state.noticeTemplateSchedule.numberOfDaysAfterPreviousNotice}
									placeholder={''}
									onChange={onPreviousNoticeDaysChanged}
									onBlur={onPreviousNoticeDaysBlur}
									required={true}
									autoComplete={'off'}
								/>

								<span className={'m-2'}>
									{localizationService.getLocalizedString('authoritySetting.notice.schedule_2')}
								</span>
							</div>
							<div className="form-row"></div>

							{footer()}
						</section>
					</div>
				</div>
			</div>
		</>
	);
};

export default NoticeTemplateEditorComponent;
