import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { ImportSteps, ImportStep1Data, ImportSummary, ApiError } from '@rcp/types';
import { localizationService, urlService, Resource, apiService } from 'src/services';
import _ from 'lodash';
import { AlertMessageType, alertService } from 'src/redux';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

interface Props {
	vertical?: boolean;
}

const Step2: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const step1Data: ImportStep1Data = stepperContext.getData(ImportSteps.STEP1);
	const step2State = stepperContext.getStep(ImportSteps.STEP2);

	const [clickedImport, setClickedImport] = React.useState(false);

	const back = () => stepperContext.goAt(ImportSteps.STEP1);

	const importFile = async () => {
		let step2State = { ...stepperContext.getStep(ImportSteps.STEP2), loading: false };
		stepperContext.updateStep(ImportSteps.STEP2, step2State);
		let authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		const url = `${authoritySettingsUrl}/Imports/${step1Data.importFileId}`;
		return apiService.httpPost(url, {});
	};
	const bigImportBar = 100;

	const onClickNext = () => {
		if (step2State && step2State.completed) {
			stepperContext.goAt(ImportSteps.STEP3);
			return;
		}

		if (step1Data.totalRowNumber > bigImportBar) {
			alertService.addSuccess(localizationService.getLocalizedString('importFile.step2ImportStartAlert'));
			importFile().catch((error: ApiError) => {
				if (error.statusCode !== 502) {
					alertService.addError(error.message);
				}
			});
			stepperContext.resolve('');
			return;
		}

		setClickedImport(true);

		let newStep2State = { ...stepperContext.getStep(ImportSteps.STEP2), loading: true };
		stepperContext.updateStep(ImportSteps.STEP2, newStep2State);

		importFile()
			.then((response: ImportSummary) => {
				stepperContext.resolve({ ...response });
			})
			.catch((error: ApiError) => {
				alertService.addError(error.message);
				let step2State = { ...stepperContext.getStep(ImportSteps.STEP2), loading: false };
				stepperContext.updateStep(ImportSteps.STEP2, step2State);
			})
			.finally(() => {
				setClickedImport(false);
			});
	};

	const importErrorContent = () => {
		return (
			<>
				{localizationService.getLocalizedString(
					'importFile.step2ErrorMessagePart1',
					_.toString(step1Data.errorRowNumber)
				)}{' '}
				<a href={step1Data.errorFileUrl} target="_blank" rel="noopener noreferrer">
					{localizationService.getLocalizedString('importFile.step2ErrorMessagePart2')}
				</a>{' '}
				{localizationService.getLocalizedString('importFile.step2ErrorMessagePart3')}
			</>
		);
	};

	return (
		<StepperContent
			id="step2Preview"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn btn-link" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction
						type="button"
						className="btn btn-link btn-disabled"
						id="btnNext"
						onClick={onClickNext}
						disabled={
							clickedImport === true ||
							step1Data.successRowNumber < 1 ||
							(step2State && step2State.completed)
						}>
						{localizationService.getLocalizedString('screen.buttons.import')}
					</StepperAction>
				</React.Fragment>
			}>
			{step1Data.errorRowNumber > 0 && (
				<div className="mt-n3">
					<InlineAlertItem message={importErrorContent()} alertType={AlertMessageType.Error} />
				</div>
			)}
			{step1Data.successRowNumber > 0 && (
				<div className="mt-2 d-inline-flex">
					<InlineAlertItem
						message={localizationService.getLocalizedString(
							'importFile.step2SuccessMessage',
							_.toString(step1Data.successRowNumber),
							_.toString(step1Data.totalRowNumber)
						)}
						alertType={AlertMessageType.Success}
					/>
				</div>
			)}
		</StepperContent>
	);
};

export default Step2;
