import { apiService, Resource, urlService } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { EnforcementType, Enforcement, CorrectiveAction, ViolationType, Lookup, SettingValue } from '@rcp/types';

export interface AuthoritySettingLookupsState {
	authoritySettings: SettingValue[];
	violationTypes: ViolationType[];
	correctiveActions: CorrectiveAction[];
	enforcementTypes: EnforcementType[];
	mapCategories: Lookup[];
	receivingPlants: Lookup[];
	cccDeviceTypes: Lookup[];
}

export const initialAuthoritySettingLookupsState: AuthoritySettingLookupsState = {
	authoritySettings: [],
	violationTypes: [],
	enforcementTypes: [],
	correctiveActions: [],
	mapCategories: [],
	receivingPlants: [],
	cccDeviceTypes: []
};

enum AuthoritySettingLookupsActionType {
	LoadAuthoritySettings = 'LoadAuthoritySettings',
	LoadAuthorityViolationTypes = 'LoadAuthorityViolationTypes',
	LoadAuthorityEnforcementTypes = 'LoadAuthorityEnforcementTypes',
	LoadAuthorityCorrectiveActions = 'LoadAuthorityCorrectiveActions',
	LoadAuthorityMapCategories = 'LoadAuthorityMapCategories',
	LoadAuthorityReceivingPlants = 'LoadAuthorityReceivingPlants',
	LoadAuthorityCccDeviceTypes = 'LoadAuthorityCccDeviceTypes'
}

type AuthorityLookupTypesThunkAction = ThunkAction<Promise<void>, AuthoritySettingLookupsActionType, any, Action>;

export const loadAuthoritySettings = (): AuthorityLookupTypesThunkAction => async dispatch => {
	const authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
	let authoritySettings = await apiService.getResource<SettingValue[]>(authoritySettingsUrl);
	dispatch({ type: AuthoritySettingLookupsActionType.LoadAuthoritySettings, authoritySettings: authoritySettings });
};

export const loadAuthorityViolationTypes = (isInUse?: boolean): AuthorityLookupTypesThunkAction => async dispatch => {
	let violationTypesUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.ViolationTypes);
	if (isInUse) {
		violationTypesUrl = violationTypesUrl + '?includeIsInUse=true';
	}
	let violationTypes = await apiService.getPaginatedResources<ViolationType>(violationTypesUrl);
	dispatch({ type: AuthoritySettingLookupsActionType.LoadAuthorityViolationTypes, violationTypes: violationTypes });
};

export const loadAuthorityCorrectiveActions = (
	isInUse?: boolean
): AuthorityLookupTypesThunkAction => async dispatch => {
	let correctiveActionsUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.CorrectiveActions);
	if (isInUse) {
		correctiveActionsUrl = correctiveActionsUrl + '?includeIsInUse=true';
	}
	let correctiveActions = await apiService.getPaginatedResources<CorrectiveAction>(correctiveActionsUrl);
	dispatch({
		type: AuthoritySettingLookupsActionType.LoadAuthorityCorrectiveActions,
		correctiveActions: correctiveActions
	});
};

export const loadAuthorityEnforcementTypes = (isInUse?: boolean): AuthorityLookupTypesThunkAction => async dispatch => {
	let enforcementTypesUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.EnforcementTypes);
	if (isInUse) {
		enforcementTypesUrl = enforcementTypesUrl + '?includeIsInUse=true';
	}
	let enforcementTypes = await apiService.getPaginatedResources<Enforcement>(enforcementTypesUrl);
	dispatch({
		type: AuthoritySettingLookupsActionType.LoadAuthorityEnforcementTypes,
		enforcementTypes: enforcementTypes
	});
};

export const loadAuthorityMapCategories = (queryParams?: string): AuthorityLookupTypesThunkAction => async dispatch => {
	let mapCategoriesUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.MapCategories);
	if (queryParams) {
		mapCategoriesUrl = mapCategoriesUrl + `?${queryParams}`;
	}
	let mapCategories = await apiService.getPaginatedResources<Lookup>(mapCategoriesUrl);
	dispatch({ type: AuthoritySettingLookupsActionType.LoadAuthorityMapCategories, mapCategories: mapCategories });
};

export const loadAuthorityReceivingPlants = (
	queryParams?: string
): AuthorityLookupTypesThunkAction => async dispatch => {
	let receivingPlantsUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.ReceivingPlants);
	if (queryParams) {
		receivingPlantsUrl = receivingPlantsUrl + `?${queryParams}`;
	}
	let receivingPlants = await apiService.getPaginatedResources<Lookup>(receivingPlantsUrl);
	dispatch({
		type: AuthoritySettingLookupsActionType.LoadAuthorityReceivingPlants,
		receivingPlants: receivingPlants
	});
};

export const loadAuthorityCccDeviceTypes = (): AuthorityLookupTypesThunkAction => async dispatch => {
	const url = urlService.getAuthorityLookupResourceApiUrl(Resource.CccDeviceType);
	let results = await apiService.getPaginatedResources<Lookup>(url);
	dispatch({
		type: AuthoritySettingLookupsActionType.LoadAuthorityCccDeviceTypes,
		cccDeviceTypes: results
	});
};

export const authoritySettingLookupsReducer = (
	state = initialAuthoritySettingLookupsState,
	action: any
): AuthoritySettingLookupsState => {
	switch (action.type) {
		case AuthoritySettingLookupsActionType.LoadAuthoritySettings:
			return { ...state, authoritySettings: action.authoritySettings };
		case AuthoritySettingLookupsActionType.LoadAuthorityViolationTypes:
			return { ...state, violationTypes: action.violationTypes };
		case AuthoritySettingLookupsActionType.LoadAuthorityCorrectiveActions:
			return { ...state, correctiveActions: action.correctiveActions };
		case AuthoritySettingLookupsActionType.LoadAuthorityEnforcementTypes:
			return { ...state, enforcementTypes: action.enforcementTypes };
		case AuthoritySettingLookupsActionType.LoadAuthorityMapCategories:
			return { ...state, mapCategories: action.mapCategories };
		case AuthoritySettingLookupsActionType.LoadAuthorityReceivingPlants:
			return { ...state, receivingPlants: action.receivingPlants };
		case AuthoritySettingLookupsActionType.LoadAuthorityCccDeviceTypes:
			return { ...state, cccDeviceTypes: action.cccDeviceTypes };
		default:
			return { ...state };
	}
};
