import React, { FC, useEffect, useState } from 'react';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { alertService } from 'src/redux';
import { Extractor, CleaningEvent } from '@rcp/types';
import { CleaningModal } from 'src/components/authority/fog/cleaning/cleaning-modal';

interface Props {
	showModal: boolean;
	onCancel: () => void;
	facilityId?: number;
}

const SelectDevicesModal: FC<Props> = (props: Props) => {
	const [renderDeviceModal, setRenderDeviceModal] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [deviceList, setDeviceList] = useState<Extractor[]>([]);
	const [selectedDevices, setSelectedDevices] = useState<String[]>([]);
	const [currentDeviceIndex, setCurrentDeviceIndex] = useState(0);
	const [scheduledCleaning, setScheduledCleaning] = useState<CleaningEvent>({});

	useEffect(() => {
		if (deviceList.length === 1 && props.showModal) {
			setRenderDeviceModal(false);
			setSelectedDevices([deviceList[0].deviceNumber || '']);
			setCurrentDeviceIndex(0);
			setShowModal(true);
		} else {
			setShowModal(false);
			setSelectedDevices([]);
		}
	}, [deviceList.length, props.showModal]);

	useEffect(() => {
		let device =
			deviceList.find((device: Extractor) => device.deviceNumber === selectedDevices[currentDeviceIndex]) || {};
		if (showModal && device.nextCleaningId) {
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${props.facilityId}/${Resource.Cleanings}/${
				device.nextCleaningId
			}`;
			apiService.getResource<CleaningEvent>(url).then(scheduleCleaning => {
				setScheduledCleaning(scheduleCleaning);
			});
		} else {
			setScheduledCleaning({} as CleaningEvent);
		}
	}, [currentDeviceIndex, deviceList, showModal]);

	useEffect(() => {
		if (props.facilityId && props.showModal) {
			const facilityExtractorUrl = urlService.getFacilityResourceApiUrl(props.facilityId, Resource.Extractors);
			apiService.httpGet(facilityExtractorUrl, true).then(data => {
				const devices = data.result
					.filter((device: Extractor) => device.isActive)
					.sort((device1: Extractor, device2: Extractor) => {
						const device1Number = device1!.deviceNumber || '';
						const device2Number = device2!.deviceNumber || '';
						return device1Number > device2Number ? 1 : -1;
					});
				setDeviceList(devices);
				setRenderDeviceModal(true);
			});
		}
	}, [props.facilityId, props.showModal]);

	const onCancelModal = () => {
		setShowModal(false);
		if (deviceList.length === 1 && props.showModal) {
			props.onCancel();
			setSelectedDevices([]);
		}
	};

	const handlerSubmit = () => {
		selectedDevices.length
			? setShowModal(true)
			: alertService.addError(
					localizationService.getLocalizedString(
						'haulerPortal.selectCleaningDevice.emptySelectedDevicesWarning'
					)
			  );
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.type === 'checkbox') {
			e.target.checked
				? setSelectedDevices([...selectedDevices, e.target.name])
				: setSelectedDevices(selectedDevices.filter(deviceNumber => deviceNumber !== e.target.name));
		}
	};

	const increaseCurrentDeviceIndex = () => {
		currentDeviceIndex + 1 < selectedDevices.length && setCurrentDeviceIndex(currentDeviceIndex + 1);
	};

	const modalProps = {
		title: localizationService.getLocalizedString('haulerPortal.selectCleaningDevice.submitCleaning'),
		showModal: props.showModal,
		cancel: () => {
			props.onCancel();
			setSelectedDevices([]);
		},
		saveButtonText: localizationService.getLocalizedString('haulerPortal.buttons.next'),
		saveButtonColor: 'btn ai-action',
		save: handlerSubmit
	};

	return (
		<>
			{renderDeviceModal && (
				<PopoverModal {...modalProps}>
					<p>
						{localizationService.getLocalizedString('haulerPortal.selectCleaningDevice.selectDevicesHint')}
					</p>
					{deviceList.map((deviceDetail: Extractor, index: number) => {
						const label = (
							<>
								<strong>{deviceDetail.extractorDescription}</strong>
								<span className="d-block mb-1 mt-1">{deviceDetail.extractorType || ''}</span>
								<span className="d-block">{deviceDetail.deviceNumber}</span>
							</>
						);
						return (
							<SingleCheckbox
								key={index}
								id={deviceDetail.deviceNumber as string}
								name={deviceDetail.deviceNumber as string}
								label={label}
								checked={selectedDevices.includes(deviceDetail.deviceNumber as string)}
								onChange={onChange}
							/>
						);
					})}
				</PopoverModal>
			)}
			{showModal && (
				<CleaningModal
					isEnterCleaning={true}
					cleaning={scheduledCleaning}
					facilityId={props.facilityId as number}
					device={deviceList.find(
						(device: Extractor) => device.deviceNumber === selectedDevices[currentDeviceIndex]
					)}
					isTimelineReloadRequired
					completeDateIsRequired
					dueDateIsRequired={
						(
							deviceList.find(
								(device: Extractor) => device.deviceNumber === selectedDevices[currentDeviceIndex]
							) || {}
						).nextCleaningId
							? true
							: false
					}
					isSubmitCleaning
					isFacilityPage
					totalDevices={selectedDevices.length}
					currentDeviceIndex={currentDeviceIndex}
					modalToggleFunction={onCancelModal}
					increaseCurrentDeviceIndex={increaseCurrentDeviceIndex}
					closeDeviceModal={() => {
						setShowModal(false);
						props.onCancel();
					}}
					isAddModal={true}
				/>
			)}
		</>
	);
};
export default SelectDevicesModal;
