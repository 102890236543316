import React from 'react';
import { BasicHauler } from '@rcp/types';

import { localizationService } from 'src/services';
import { Popup } from '@progress/kendo-react-popup';
import { PopupCloseSvg } from '../../../svg';

interface OwnProps {
	facilityNames?: string[];
	lastHauler?: BasicHauler;
}

type Props = OwnProps;

export const FogDeviceGridTooltip: React.FC<Props> = props => {
	const [showPopup, setShowPopup] = React.useState(false);
	const anchor = React.useRef<HTMLTableDataCellElement | null>(null);
	const togglePopup = (e: any) => {
		e.preventDefault();
		setShowPopup(!showPopup);
	};

	const facilityNamesPopup = (facilityNames: string[]) => {
		return (
			<td ref={anchor}>
				<a href="#/" className="ai-link" onClick={togglePopup}>
					{localizationService.getLocalizedString('extractor.multipleFacilities', `${facilityNames.length}`)}
				</a>
				<Popup
					anchor={anchor.current as HTMLElement | undefined}
					show={showPopup}
					popupClass="ai-popover"
					anchorAlign={{ horizontal: 'right', vertical: 'center' }}
					popupAlign={{ horizontal: 'left', vertical: 'center' }}
					collision={{ horizontal: 'flip', vertical: 'fit' }}>
					{facilityNamesPopupContent(facilityNames)}
				</Popup>
			</td>
		);
	};

	const facilityNamesPopupContent = (facilityNames: string[]) => {
		return (
			<>
				<button className="close-dropdown flex-row-reverse" onClick={togglePopup}>
					<PopupCloseSvg />
				</button>
				{facilityNames.map(facilityName => (
					<div key={facilityName}>
						{facilityName}
						<br />
					</div>
				))}
			</>
		);
	};

	const lastHaulerPopup = (lastHauler: BasicHauler) => {
		return (
			<td ref={anchor}>
				<a href="#/" className="ai-link" onClick={togglePopup}>
					{lastHauler.name}
				</a>
				<Popup
					anchor={anchor.current as HTMLElement | undefined}
					show={showPopup}
					popupClass="ai-popover"
					anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
					popupAlign={{ horizontal: 'left', vertical: 'bottom' }}
					collision={{ horizontal: 'flip', vertical: 'fit' }}>
					{lastHaulerPopupContent(lastHauler)}
				</Popup>
			</td>
		);
	};

	const lastHaulerPopupContent = (lastHauler: BasicHauler) => {
		return (
			<>
				<button className="close-dropdown flex-row-reverse" onClick={togglePopup}>
					<PopupCloseSvg />
				</button>
				<div className="wrapWord ">
					<div className="flex-column w-100 mr-3">
						<div className="form-row">
							<span className="font-size-16px-regular">{lastHauler.name}</span>
							<span className="text-muted ml-1"> #{lastHauler.haulerNumber} </span>
						</div>

						{lastHauler.phone ? (
							<div className="form-row">
								{localizationService.getLocalizedString('haulers.phone')}:&nbsp;
								{lastHauler.phone}
							</div>
						) : null}

						{lastHauler.email ? (
							<div className="form-row">
								{localizationService.getLocalizedString('haulers.email')}:&nbsp;
								{lastHauler.email}
							</div>
						) : null}
					</div>
				</div>
			</>
		);
	};

	return (
		<>
			{props.facilityNames ? facilityNamesPopup(props.facilityNames) : null}
			{props.lastHauler ? lastHaulerPopup(props.lastHauler) : null}
		</>
	);
};
