import React from 'react';
import _ from 'lodash';
import AttachmentProps from './attachment-props';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFile,
	faFileExcel,
	faFilePdf,
	faFileWord,
	faFilePowerpoint,
	faFileCsv,
	faFileAudio,
	faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { fileUtilService } from 'src/services';

export const AttachmentIcon: React.SFC<AttachmentProps> = props => {
	let faIcon;
	let fileType = _.toLower(props.attachment.fileType);
	if (fileUtilService.isPdf(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFilePdf} className={props.className} />;
	} else if (fileUtilService.isOfficeDocument(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFileWord} className={props.className} />;
	} else if (fileUtilService.isSpreadsheet(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFileExcel} className={props.className} />;
	} else if (fileUtilService.isSlide(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFilePowerpoint} className={props.className} />;
	} else if (fileUtilService.isAudio(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFileAudio} className={props.className} />;
	} else if (fileUtilService.isVideo(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFileVideo} className={props.className} />;
	} else if (fileUtilService.isCsv(fileType)) {
		faIcon = <FontAwesomeIcon icon={faFileCsv} className={props.className} />;
	} else {
		faIcon = <FontAwesomeIcon icon={faFile} className={props.className} />;
	}
	return faIcon;
};
