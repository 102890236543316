import React from 'react';

import {
	useReduxDispatch,
	loadAuthorityInspectionTypes,
	batchCreateFacilityInspections,
	batchPatchFacilityInspections,
	PageLoadingActionType,
	useRootStateSelector,
	closeActionModal,
	ActionModalActionType
} from 'src/redux';
import { localizationService, optionsMap, dispatchAccessor, urlService } from 'src/services';
import { PopoverModal } from 'src/components/widgets';
import { InspectionEventInputFields, validateInspectionForSave } from './inspection-event-inputs';
import { InspectionEvent, DropDownOption, UserProfile, EventType, Dictionary } from '@rcp/types';
import _ from 'lodash';
import { DataGridContext } from 'src/features/data-grid/data-grid';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';

interface OwnProps {
	actionType: ActionModalActionType;
	toggleModal: () => void;
	filteredObjectCount: number;
	isPatch?: boolean;
	postAction?: () => void;
	forIncidentContributor?: boolean;
	query?: string;
}
type Props = OwnProps;

interface InspectionEventFormState extends InspectionEvent {
	inspectionTypeIdError?: string;
	dueDateError?: string;
}

export const BatchInspectionOperationModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState<InspectionEventFormState>({});
	const [inspectionTypeOptions, setInspectionTypeOptions] = React.useState([] as DropDownOption[]);
	const [authorityUserOptions, setAuthorityUserOptions] = React.useState([] as DropDownOption[]);
	const [isSavingStatus, setSavingStatus] = React.useState(false);
	const dispatch = useReduxDispatch();
	const { result: authorityUsers } = useRootStateSelector(s => s.authorityUsers);
	const inspectionTypes = useRootStateSelector(s => s.authorityEventTypes.inspectionTypes);

	const filteredObjectCountString = `${props.filteredObjectCount}`;

	React.useEffect(() => {
		dispatch(loadAuthorityInspectionTypes());
		dispatch(authorityUsersSlice.fetchAll());
	}, [dispatch]);

	React.useEffect(() => {
		return () => {
			//umount reset modal state
			closeModal();
		};
	}, []);

	React.useEffect(() => {
		if (authorityUsers) {
			createInspectorOptions(authorityUsers);
		}
		if (inspectionTypes) {
			createInspectionTypeOptions(inspectionTypes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityUsers, inspectionTypes]);

	const createInspectorOptions = (authorityUsers: UserProfile[]) => {
		let options: DropDownOption[] = createAndSortAuthorityUserOptions(authorityUsers);
		let inspectorAssignedToTheFacilityOption: DropDownOption = {
			label: localizationService.getLocalizedString('inspection.inspectorAssignedToFacility'),
			value: -1
		};
		options.unshift(inspectorAssignedToTheFacilityOption);
		if (props.isPatch) {
			let removeInspectorOption: DropDownOption = {
				label: localizationService.getLocalizedString('inspection.removeInspector'),
				value: 0
			};
			options.unshift(removeInspectorOption);
		}
		setAuthorityUserOptions(options);
	};

	const createAndSortAuthorityUserOptions = (authorityUsers: UserProfile[]): DropDownOption[] => {
		let authUsers = optionsMap.fromUserProfiles(authorityUsers, false);
		return _.sortBy(authUsers, ['label']);
	};

	const createInspectionTypeOptions = (inspectionTypes: EventType[]) => {
		let options: DropDownOption[] = [];
		options.push(...optionsMap.fromEventTypes(inspectionTypes, props.isPatch ? true : false));

		setInspectionTypeOptions(options);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		if (props.isPatch && value === '') {
			_.unset(newState, name);
		} else {
			_.set(newState, name, value);
		}
		setFormState(newState);
	};

	const dataGridContext = React.useContext(DataGridContext);
	const onSaveBtnClick = () => {
		if (!validateInspectionForSave(formState, setFormState, props.isPatch)) {
			return;
		}
		let gridState = dataGridContext.getGridState();
		let queryString = props.forIncidentContributor
			? props.query!
			: urlService.toQueryString(gridState.queryParameters as Dictionary<string>);
		let inspectionEvent = { ...formState };
		if (props.isPatch) {
			dispatch(
				batchPatchFacilityInspections(queryString, inspectionEvent, setSavingStatus, () => {
					closeModal();
					dataGridContext.gridRefresh();
				})
			);
		} else {
			dispatch(
				batchCreateFacilityInspections(queryString, inspectionEvent, setSavingStatus, () => {
					closeModal();
					if (!props.forIncidentContributor) {
						dataGridContext.gridRefresh();
					}
				})
			);
		}
	};

	const [title, setTitle] = React.useState('');
	React.useEffect(() => {
		let title: string;
		if (props.filteredObjectCount === 1) {
			if (props.isPatch) {
				title = localizationService.getLocalizedString('inspection.batchPatchInspectionModalTitleSingle');
			} else {
				title = localizationService.getLocalizedString('screen.buttons.scheduleInspection');
			}
		} else {
			if (props.isPatch) {
				title = localizationService.getLocalizedString(
					'inspection.batchPatchInspectionModalTitleMultiple',
					filteredObjectCountString
				);
			} else {
				title = localizationService.getLocalizedString('screen.buttons.scheduleInspection');
			}
		}
		setTitle(title);
	}, [props.filteredObjectCount, props.isPatch]);

	const getModalDescription = () => {
		if (props.isPatch) {
			return undefined;
		}

		let description: string;
		if (props.filteredObjectCount === 1) {
			description = localizationService.getLocalizedString(
				'inspection.batchScheduleInspectionModalDescriptionSingle'
			);
		} else {
			description = localizationService.getLocalizedString(
				'inspection.batchScheduleInspectionModalDescriptionMultiple',
				filteredObjectCountString
			);
		}

		return description;
	};

	const onCancelBtnClick = () => {
		closeModal();
	};

	const closeModal = () => {
		dispatch(closeActionModal(props.actionType));
	};

	const modalFooterDiv = () => {
		isSavingStatus
			? dispatchAccessor.dispatch({ type: PageLoadingActionType.Start })
			: dispatchAccessor.dispatch({ type: PageLoadingActionType.Done });

		return (
			<>
				<div className="ml-auto">
					<button
						id="saveBtn"
						className={`btn ${props.isPatch ? 'ai-save' : 'ai-action'}`}
						onClick={onSaveBtnClick}
						disabled={isSavingStatus}>
						{props.isPatch
							? localizationService.getLocalizedString('screen.buttons.save')
							: localizationService.getLocalizedString('authoritySetting.notice.schedule')}
					</button>
					<button
						id="cancelBtn"
						className="btn ai-white ml-2"
						onClick={onCancelBtnClick}
						disabled={isSavingStatus}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	return (
		<div>
			<PopoverModal
				className="popup-modal-edit"
				showModal={true}
				title={title}
				footer={modalFooterDiv()}
				cancel={closeModal}
				description={getModalDescription()}>
				<InspectionEventInputFields
					formState={formState}
					changeFormState={changeFormState}
					inspectionTypeOptions={inspectionTypeOptions}
					isFormDisabled={false}
					authorityUserOptions={authorityUserOptions}
					isPatch={props.isPatch}
					isInspectorOptionsSelfOrdered={true}
				/>
			</PopoverModal>
		</div>
	);
};
