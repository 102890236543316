import { LookupRow, EventTypeLookupRow } from '../lookup-types';
import { SubLookupType, EventType } from '@rcp/types';
import { ILookupDataConverter } from './lookup-data-converter';
import _ from 'lodash';
import { localizationService } from '../../../../services';

export class LookupRowEventTypeValueConverter implements ILookupDataConverter {
	subLookupType: SubLookupType;
	constructor(subLookupType: SubLookupType) {
		this.subLookupType = subLookupType;
	}

	getDescription = (eventType: EventTypeLookupRow): string => {
		return eventType.shouldScheduleNextEventAutomatically &&
			(eventType.nextEventStartingWith === 'dueDate' || eventType.nextEventStartingWith === 'DueDate')
			? localizationService.getLocalizedString(
					'eventType.dueDateScheduleText',
					eventType.schedulingFrequency as string,
					_.toLower(localizationService.getLocalizedString('eventType.dueDate'))
			  )
			: eventType.shouldScheduleNextEventAutomatically &&
			  (eventType.nextEventStartingWith === 'completeDate' || eventType.nextEventStartingWith === 'CompleteDate')
			? localizationService.getLocalizedString(
					'eventType.dueDateScheduleText',
					eventType.schedulingFrequency as string,
					_.toLower(localizationService.getLocalizedString('eventType.completeDate'))
			  )
			: '';
	};

	toAPIAddDataFromLookups = (existingLookupRows: EventTypeLookupRow[], newValue: EventTypeLookupRow): EventType => {
		let dataToPost = {
			eventTypeAbbreviation: newValue.code,
			eventTypeDescription: newValue.codeDescription,
			eventCategory: this.subLookupType,
			eventCategoryId: newValue.eventCategoryId,
			schedulingFrequencyId: newValue.schedulingFrequencyId,
			useFrequencyAssignedToFacility: newValue.useFrequencyAssignedToFacility
		} as EventType;

		return dataToPost;
	};

	toAPIDeleteDataFromLookups = (existingRows: EventTypeLookupRow[], rowToDelete: EventTypeLookupRow): EventType => {
		return rowToDelete;
	};

	toAPIUpdateDataFromLookups = (existingRows: EventTypeLookupRow[], rowToUpdate: EventTypeLookupRow): EventType => {
		let dataToUpdate: EventType = {
			eventTypeId: rowToUpdate.lookupId,
			eventTypeAbbreviation: rowToUpdate.code,
			eventTypeDescription: rowToUpdate.codeDescription,
			eventCategory: this.subLookupType,
			eventCategoryId: rowToUpdate.eventCategoryId,
			schedulingFrequencyId: rowToUpdate.schedulingFrequencyId,
			useFrequencyAssignedToFacility: rowToUpdate.useFrequencyAssignedToFacility,
			isActive: rowToUpdate.isActive
		};
		return dataToUpdate;
	};

	toLookupRowsFromAPI = (apiData: any): LookupRow[] => {
		let lookupValues: EventTypeLookupRow[] = [];
		if (apiData && _.isArray(apiData)) {
			let events = apiData as EventType[];
			events = events.filter(i => i.eventCategory === this.subLookupType);
			lookupValues = events.map((i: EventType) => {
				return {
					lookupId: i.eventTypeId,
					code: i.eventTypeAbbreviation,
					codeDescription: this.getDescription(i),
					isActive: i.isActive,
					isInUse: i.isInUse
				} as EventTypeLookupRow;
			});
		}
		return lookupValues as LookupRow[];
	};

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		let eventType = apiData as EventType;
		return {
			lookupId: eventType.eventTypeId,
			code: eventType.eventTypeAbbreviation,
			codeDescription: this.getDescription(eventType),
			isActive: eventType.isActive,
			isInUse: eventType.isInUse
		} as LookupRow;
	};
}
