import React from 'react';
import { CccTestDto } from '@rcp/types';
import { apiService, localizationService, urlService } from 'src/services';
import { TestDetailModal } from './tests/hazard-test-detail-modal';

interface OwnProps {
	displayText: string;
	hazardId: number;
	isEnterTest: boolean;
	testId?: number;
}

type Props = OwnProps;

export const HazardGridTestActionLink: React.FC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const [scheduledTest, setScheduledTest] = React.useState({} as CccTestDto);

	React.useEffect(() => {
		if (showModal) {
			if (props.testId) {
				const url = `${urlService.getTestsUrl(props.testId)}`;

				apiService.getResource<CccTestDto>(url).then((res: CccTestDto) => {
					setScheduledTest(res);
				});
			}
		}
	}, [props.testId, showModal]);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	return (
		<>
			<a href="#/" className="ai-link" onClick={onEditClicked}>
				{props.displayText}
			</a>
			{showModal && !props.isEnterTest && (
				<TestDetailModal
					isToggle={showModal}
					toggle={toggleModal}
					currentTest={scheduledTest}
					dueDateRequired={scheduledTest.initialTestDate ? false : true}
					completedResultRequired={scheduledTest.initialTestDate ? true : false}
					modalTitle={undefined}
					isScheduledTest={false}
					isFromHazardGrid={true}
					hazardId={props.hazardId}
				/>
			)}
			{showModal && props.isEnterTest && (
				<TestDetailModal
					isToggle={showModal}
					toggle={toggleModal}
					currentTest={scheduledTest}
					dueDateRequired={props.testId ? true : false}
					completedResultRequired={true}
					modalTitle={props.testId ? undefined : localizationService.getLocalizedString('cccTest.addTest')}
					isScheduledTest={false}
					isFromHazardGrid={true}
					hazardId={props.hazardId}
				/>
			)}
		</>
	);
};
