import { FilterType, FilterOperator, MultiSelectDropDownOption } from '@rcp/types';
import { localizationService, UtilService } from '..';

export interface IFilterElement {
	label: string;
}

interface FilterPropertyParams {
	label: string;
	name: string;
	filterType?: FilterType;
	valueOptions?: MultiSelectDropDownOption[];
	error?: string;
	isCustomField?: boolean;
	isNonNullable?: boolean;
	isSignInUserTextRequired?: boolean;
	needLocalize?: boolean;
	showTxt?: string;
}

const defaultFilterPropertyParams: FilterPropertyParams = {
	label: '',
	name: '',
	filterType: FilterType.Text,
	valueOptions: [],
	isCustomField: false,
	isNonNullable: false,
	isSignInUserTextRequired: false,
	needLocalize: true,
	showTxt: ''
};

export class FilterProperty implements IFilterElement {
	label: string;
	name: string;
	filterType: FilterType;
	valueOptions: MultiSelectDropDownOption[];
	error?: string;
	isCustomField?: boolean;
	isNonNullable?: boolean;
	isSignInUserTextRequired?: boolean;
	constructor(parameters: FilterPropertyParams) {
		let args = { ...defaultFilterPropertyParams, ...parameters };
		let displayValueKey = `${args.label}.${args.name}`;
		let displayText = '';
		this.isNonNullable = args.isNonNullable;
		this.isSignInUserTextRequired = args.isSignInUserTextRequired;
		if (args.needLocalize) {
			displayText =
				args.label === '' || args.name === '' ? '' : localizationService.getLocalizedString(displayValueKey);
			this.label = displayText;
		} else {
			this.label = args.showTxt as string;
		}
		this.name = args.name;
		this.filterType = args.filterType || FilterType.Text;
		this.valueOptions = args.valueOptions || [];
		this.isCustomField = false;
	}
}

export class DomainObject implements IFilterElement {
	label: string;
	name: string;
	properties: FilterProperty[];
	error?: string;
	url?: string;

	constructor(displayName: string, innerValue: string, properties: FilterProperty[], url: string = '') {
		this.label = displayName;
		this.name = innerValue;
		this.properties = properties;
		this.url = url;
	}
}

export class Operator implements IFilterElement {
	label: string;
	name: string;
	error?: string;
	constructor(displayValue: string, innerValue: string) {
		this.label = displayValue;
		this.name = innerValue;
	}
}

export interface FilterValue {
	value?: string | null; //filter value placeholder for input value manipulating a single value, e.g. date input, number input, single text input
	values?: string[]; //filter value placeholder for multi-select dropdown selected values
	error?: string;
}

export const OperatorCode = {
	...FilterOperator
};

export class FilterItem {
	domainObject: DomainObject;
	property: FilterProperty;
	operator: Operator;
	values: FilterValue[]; //depends on property.filterType and operator, this values can be a single or multiple FilterValue
	key: string;

	constructor(
		domainObject: DomainObject,
		property: FilterProperty,
		operator: Operator,
		values?: FilterValue[],
		key?: string
	) {
		this.key = key ? key : UtilService.getUniqueString();
		this.domainObject = domainObject;
		this.property = property;
		this.operator = operator;
		this.values = values || [];
	}
}

export class FilterDefinition {
	filterName: string;
	filterId?: number;
	draftFilterId?: number;
	filterItems: FilterItem[];
	constructor(filterItems: FilterItem[], draftFilterId = 0, filterId = 0, filterName = '') {
		this.draftFilterId = draftFilterId;
		this.filterId = filterId;
		this.filterName = filterName;
		this.filterItems = filterItems;
	}

	getFilterItemByIndex(index: number) {
		return this.filterItems[index];
	}

	getFilterItem(key: string) {
		return this.filterItems.find(i => i.key === key);
	}

	addOrUpdateFilterItem(filterItem: FilterItem) {
		let index: number = -1;
		this.filterItems.forEach((item, i) => {
			if (item.key === filterItem.key) {
				index = i;
				return;
			}
		});

		if (index < 0) {
			this.filterItems.push(filterItem);
		} else {
			this.filterItems[index] = filterItem;
		}
	}

	deleteFilterItemByKey(key: string) {
		let index: number = -1;
		this.filterItems.forEach((item, i) => {
			if (item.key === key) {
				index = i;
				return;
			}
		});

		if (index < 0) return;
		this.filterItems.splice(index, 1);
	}

	deleteFilterItemByIndex(index: number) {
		this.filterItems.splice(index, 1);
	}

	getAllFilterItems() {
		return this.filterItems;
	}
}

export class FilterValidationBody {
	filterName: string;
	filterId?: number;
	draftFilterId?: number;
	isUpdate: boolean;
	saveAsDraftFilter: boolean;
	applyTo?: string;

	constructor(
		filterName: string,
		saveAsDraftFilter: boolean,
		filterId?: number,
		draftFilterId?: number,
		applyTo?: string
	) {
		this.filterName = filterName;
		this.draftFilterId = draftFilterId;
		this.filterId = filterId;
		this.saveAsDraftFilter = saveAsDraftFilter;
		this.isUpdate = false;
		this.applyTo = applyTo;

		if (saveAsDraftFilter) {
			if (draftFilterId) {
				this.isUpdate = true;
			}
		} else {
			if (filterId) {
				this.isUpdate = true;
			}
		}
	}
}
