import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export interface JurisdictionListState {
	jurisdictionList: ApiTypes.Jurisdiction[];
}

export const initialJurisdictionListState: JurisdictionListState = {
	jurisdictionList: []
};

export enum JurisdictionListActionType {
	LoadJurisdictionList = 'loadJurisdictionList'
}

export interface LoadJurisdictionListAction extends Action {
	type: JurisdictionListActionType.LoadJurisdictionList;
	jurisdictionList: ApiTypes.Jurisdiction[];
}

export const loadJurisdictionListAction = (jurisdictionList: ApiTypes.Jurisdiction[]): LoadJurisdictionListAction => ({
	type: JurisdictionListActionType.LoadJurisdictionList,
	jurisdictionList: jurisdictionList
});

export type JurisdictionAction = LoadJurisdictionListAction;
