import React, { useState } from 'react';
import { connect } from 'react-redux';

import { ApplicationState, FogFacilityState, AuthorityTagsState } from 'src/redux';
import { TabViewField } from 'src/components/widgets';
import { LocationModal } from './facility-location-modal';
import { localizationService } from 'src/services';

interface StateProps extends FogFacilityState {
	authorityTags: AuthorityTagsState;
}

type Props = StateProps;

const LocationTabComp: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('facility.mapCategory')}
						value={props.facility.mapCategory}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.latitude')}
						value={props.facility.latitude}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.longitude')}
						value={props.facility.longitude}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.receivingPlant')}
						value={props.facility.receivingPlant}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.trunkLine')}
						value={props.facility.trunkLine}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.elevation')}
						value={props.facility.elevation}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('facility.gisNumber')}
						value={props.facility.gisNumber}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" id="edit-facility-location" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
			{isToggle && <LocationModal isToggle={isToggle} toggle={toggle} />}
		</>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, authorityTags: state.authorityTags };
};

export const LocationTab = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(LocationTabComp);
