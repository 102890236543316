import React from 'react';
import {
	AuthorityMapSettingsDto,
	Dictionary,
	EntityMapLocation,
	EntityMapLocationType,
	FogFacility,
	Incident,
	LocalStorageName,
	RiskScoreValues
} from '@rcp/types';
import * as atlas from 'azure-maps-control';
import * as atlasDrawing from 'azure-maps-drawing-tools';
import _ from 'lodash';
import { renderToStaticMarkup } from 'react-dom/server';
import { FacilityPinInRed, FacilityPinInGreen, FacilityPinInYellow, FacilityPinInCustom } from './icons/icons';

import { IncidentPin } from './icons/incident-icons';
import { apiService, localStorageService, Logger, Resource, urlService } from 'src/services';
import { HtmlMarkerLayer, PieChartMarker } from './html-marker-layer';
import { isMobile } from 'react-device-detect';
import { nameof } from 'ts-simple-nameof';

export enum MapType {
	FacilityRiskMap
}

export enum MapLegendKey {
	Risk = 'Risk',
	Compliance = 'Compliance',
	DeviceStatus = 'Device status'
}

enum MapPinIcon {
	FacilityNoRisk = 'noRiskIcon',
	FacilityLowRisk = 'lowRiskIcon',
	FacilityMediumRisk = 'mediumRiskIcon',
	FacilityHighRisk = 'highRiskIcon',
	FacilityCompliant = 'compliantIcon',
	FacilityNoneCompliant = 'noneCompliantIcon',
	FacilityCustomCompliant = 'customCompliantIcon',
	FacilityHasDevice = 'hasDeviceIcon',
	FacilityNoDevice = 'noDeviceIcon',
	Incident = 'incidentIcon'
}

export const MapPinBgColor = {
	Custom: '#3C3E69',
	Green: '#3C7F2F',
	Yellow: '#FFC857',
	Red: '#990600',
	Orange: '#ff892e'
};

export const MapPinFillColor = {
	Light: '#F5F5F7',
	Dark: '#393939'
};

export const PropertyKeyForResolvedRiskScore = '_map_risk_score_';
export const PropertyKeyForResolvedComplianceStatus = '_map_compliance_status_';
export const PropertyKeyForResolvedDeviceStatus = '_map_device_status_';
export const PropertyKeyForResolvedLatitude = '_map_latitude_';
export const PropertyKeyForResolvedLongitude = '_map_longitude_';
export const IncludesList = [
	nameof<FogFacility>(f => f.facilityName),
	nameof<FogFacility>(f => f.referenceNumber),
	nameof<FogFacility>(f => f.addressLine1),
	nameof<FogFacility>(f => f.addressLine2),
	nameof<FogFacility>(f => f.cityName),
	nameof<FogFacility>(f => f.jurisdictionCode),
	nameof<FogFacility>(f => f.riskScore),
	nameof<FogFacility>(f => f.zipCode),
	nameof<FogFacility>(f => f.complianceStatus),
	nameof<FogFacility>(f => f.nextInspectionDueDate),
	nameof<FogFacility>(f => f.nextInspectionType),
	nameof<FogFacility>(f => f.facilityId),
	nameof<FogFacility>(f => f.isOverrideComplianceStatus),
	nameof<FogFacility>(f => f.overriddenComplianceStatus),
	nameof<FogFacility>(f => f.activeDeviceCount)
];
const customSuffix = '_custom';

enum ClusterRiskScore {
	Low = 'Low risk',
	Medium = 'Medium risk',
	High = 'High risk',
	NotSet = 'No risk set'
}

enum ClusterComplianceStatus {
	Compliant = 'Compliant',
	NoneCompliant = 'Non Compliant'
}

enum ClusterDeviceStatus {
	HasDevice = 'Has device',
	NoDevice = 'No device'
}

const incidentMarkerType = 'Incidents';

const toClusterRiskScore = (risk: string | undefined | null): string => {
	if (risk === RiskScoreValues.Low) {
		return ClusterRiskScore.Low;
	}
	if (risk === RiskScoreValues.Medium) {
		return ClusterRiskScore.Medium;
	}
	if (risk === RiskScoreValues.High) {
		return ClusterRiskScore.High;
	}
	return ClusterRiskScore.NotSet;
};

const toClusterDeviceStatus = (activeDeviceCount: number | undefined): string => {
	if (activeDeviceCount && activeDeviceCount > 0) {
		return ClusterDeviceStatus.HasDevice;
	}
	return ClusterDeviceStatus.NoDevice;
};

const mapPinHtmlContent = (): Dictionary<string> => {
	let mapPins: Dictionary<string> = {};
	mapPins[MapPinIcon.FacilityNoRisk] = renderToStaticMarkup(<FacilityPinInCustom />);
	mapPins[MapPinIcon.FacilityLowRisk] = renderToStaticMarkup(<FacilityPinInGreen />);
	mapPins[MapPinIcon.FacilityMediumRisk] = renderToStaticMarkup(<FacilityPinInYellow />);
	mapPins[MapPinIcon.FacilityHighRisk] = renderToStaticMarkup(<FacilityPinInRed />);
	mapPins[MapPinIcon.FacilityCompliant] = renderToStaticMarkup(<FacilityPinInGreen />);
	mapPins[MapPinIcon.FacilityNoneCompliant] = renderToStaticMarkup(<FacilityPinInRed />);
	mapPins[MapPinIcon.FacilityCustomCompliant] = renderToStaticMarkup(<FacilityPinInCustom />);
	mapPins[MapPinIcon.FacilityHasDevice] = renderToStaticMarkup(<FacilityPinInGreen />);
	mapPins[MapPinIcon.FacilityNoDevice] = renderToStaticMarkup(<FacilityPinInRed />);
	mapPins[MapPinIcon.Incident] = renderToStaticMarkup(<IncidentPin />);
	return mapPins;
};

interface MapClusterConfiguration {
	pinTypes: string[];
	pinColors: string[];
	pinIcons: MapPinIcon[];
	clusterValues: number[];
	clusterProperties: Record<string, atlas.AggregateExpression>;
}

const buildClusterProperties = (clusterFieldName: string, clusterFieldValues: string[]) => {
	let clusterProperties: Record<string, atlas.AggregateExpression> = {};
	clusterFieldValues.forEach(fieldValue => {
		clusterProperties[fieldValue] = ['+', ['case', ['==', ['get', clusterFieldName], fieldValue], 1, 0]];
	});
	clusterProperties.Incidents = ['+', ['case', ['has', 'incidentId'], 1, 0]];
	return clusterProperties;
};

const getFacilityClusterPropertyTypes = (
	mapLegend: MapLegendKey,
	customComplianceValues: string[]
): MapClusterConfiguration => {
	if (mapLegend === MapLegendKey.Risk) {
		const pinTypes = [
			incidentMarkerType,
			ClusterRiskScore.Low,
			ClusterRiskScore.Medium,
			ClusterRiskScore.High,
			ClusterRiskScore.NotSet
		];
		return {
			pinTypes: pinTypes,
			pinColors: [
				MapPinBgColor.Orange,
				MapPinBgColor.Green,
				MapPinBgColor.Yellow,
				MapPinBgColor.Red,
				MapPinBgColor.Custom
			],
			pinIcons: [
				MapPinIcon.Incident,
				MapPinIcon.FacilityLowRisk,
				MapPinIcon.FacilityMediumRisk,
				MapPinIcon.FacilityHighRisk,
				MapPinIcon.FacilityNoRisk
			],
			clusterValues: [0, 0, 0, 0, 0],
			clusterProperties: buildClusterProperties(PropertyKeyForResolvedRiskScore, pinTypes)
		};
	}

	if (mapLegend === MapLegendKey.Compliance) {
		let pinTypes: string[] = [
			incidentMarkerType,
			ClusterComplianceStatus.Compliant,
			ClusterComplianceStatus.NoneCompliant
		];
		let pinColors = [MapPinBgColor.Orange, MapPinBgColor.Green, MapPinBgColor.Red];
		let pinIcons = [MapPinIcon.Incident, MapPinIcon.FacilityCompliant, MapPinIcon.FacilityNoneCompliant];
		let clusterValues: number[] = [0, 0, 0];
		customComplianceValues.forEach((customComplianceValue: string) => {
			pinTypes.push(customComplianceValue + customSuffix);
			pinColors.push(MapPinBgColor.Custom);
			pinIcons.push(MapPinIcon.FacilityCustomCompliant);
			clusterValues.push(0);
		});
		return {
			pinTypes: pinTypes,
			pinColors: pinColors,
			pinIcons: pinIcons,
			clusterValues: clusterValues,
			clusterProperties: buildClusterProperties(PropertyKeyForResolvedComplianceStatus, pinTypes)
		};
	}

	if (mapLegend === MapLegendKey.DeviceStatus) {
		const pinTypes = [incidentMarkerType, ClusterDeviceStatus.HasDevice, ClusterDeviceStatus.NoDevice];
		return {
			pinTypes: pinTypes,
			pinColors: [MapPinBgColor.Orange, MapPinBgColor.Green, MapPinBgColor.Red],
			pinIcons: [MapPinIcon.Incident, MapPinIcon.FacilityHasDevice, MapPinIcon.FacilityNoDevice],
			clusterValues: [0, 0, 0, 0, 0],
			clusterProperties: buildClusterProperties(PropertyKeyForResolvedDeviceStatus, pinTypes)
		};
	}
	throw new Error(`MapLegend ${mapLegend} is unsupported.`);
};

const getMapPinHtmlContent = (
	mapLegend: MapLegendKey,
	properties: any,
	mapConfig: MapClusterConfiguration
): MapPinIcon => {
	let resolvedValue = '';
	if (mapLegend === MapLegendKey.Risk) {
		resolvedValue = properties[PropertyKeyForResolvedRiskScore];
	} else if (mapLegend === MapLegendKey.Compliance) {
		resolvedValue = properties[PropertyKeyForResolvedComplianceStatus];
	} else if (mapLegend === MapLegendKey.DeviceStatus) {
		resolvedValue = properties[PropertyKeyForResolvedDeviceStatus];
	} else {
		throw new Error(`MapLegend ${mapLegend} is unsupported.`);
	}

	if (properties.incidentId > 0) {
		resolvedValue = incidentMarkerType;
	}

	if (_.isEmpty(resolvedValue)) {
		throw new Error(`Map pin resolved values should not be null or empty.`);
	}

	let index = _.findIndex(mapConfig.pinTypes, pinType => {
		return String.equalCaseInsensitive(resolvedValue, pinType);
	});
	if (index === -1) {
		throw new Error(`Cannot find ${resolvedValue} from pin values ${JSON.stringify(mapConfig.pinTypes)}.`);
	}
	let mapPinIcon = mapConfig.pinIcons[index];
	if (mapPinIcon) {
		return mapPinIcon;
	}
	throw new Error(`Cannot find map pin ${MapLegendKey.Risk} for index ${index}.`);
};
interface CameraStorage {
	cameraOptions: atlas.CameraOptions;
	styleOptions: atlas.StyleOptions;
}
export interface MapSettings {
	readyTimeout: number; //milliseconds timeout to avoid "Error: Style is not done loading", default set to 500
	mapLegend: MapLegendKey;
}
const MapSettingsLocalStorageKey = 'mapSettings';
const initialMapSettings: MapSettings = {
	readyTimeout: 500,
	mapLegend: MapLegendKey.Risk
};

class MapService {
	map: atlas.Map | null = null;

	disposeMap() {
		if (this.map != null) {
			this.map.dispose();
			this.map = null;
		}
	}

	initializeMap = async (mapElementId: string, onMapReady: (map: atlas.Map) => void) => {
		if (this.map != null) {
			this.disposeMap();
		}
		const cameraStorage = localStorageService.getLocalStorage(LocalStorageName.FacilityMapCamera) as CameraStorage;
		const authoritySettingsUrl = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/map${
			!cameraStorage || !cameraStorage.cameraOptions ? '?includeAuthorityLocation=true' : ''
		}`;
		const dto = await apiService.getResource<AuthorityMapSettingsDto>(authoritySettingsUrl);
		this.map = new atlas.Map(mapElementId, {
			center:
				cameraStorage && cameraStorage.cameraOptions && cameraStorage.cameraOptions.center
					? cameraStorage.cameraOptions.center
					: new atlas.data.Position(dto.defaultLongitude || 0, dto.defaultLatitude || 0),
			zoom:
				cameraStorage && cameraStorage.cameraOptions && cameraStorage.cameraOptions.zoom
					? cameraStorage.cameraOptions.zoom
					: 12,
			language: 'en-US',
			authOptions: {
				authType: atlas.AuthenticationType.subscriptionKey,
				subscriptionKey: dto.azureSubscriptionKey
			},
			showLogo: false,
			showFeedbackLink: false,
			view: 'Auto',
			style:
				cameraStorage && cameraStorage.styleOptions && cameraStorage.styleOptions.style
					? cameraStorage.styleOptions.style
					: 'road',
			minZoom: 1,
			maxZoom: 17.9
		});
		await this.loadMapImageSprite(this.map);
		this.addMapEvents(this.map, onMapReady);
		return this.map;
	};

	private addMapEvents(map: atlas.Map, onMapLoaded: (map: atlas.Map) => void) {
		const self = this;
		map.events.add('moveend', () => self.saveCamera(map));
		map.events.add('styledata', () => self.saveStyle(map));
		map.events.add('ready', () => {
			self.addMapControls(map);
			onMapLoaded(map);
		});
	}

	private async loadMapImageSprite(map: atlas.Map) {
		let mapPins = mapPinHtmlContent();
		let iconPromises: Promise<void>[] = [];
		Object.entries(mapPins).find(([key, value]) => {
			iconPromises.push(map.imageSprite.add(key, value));
		});
		await Promise.all(iconPromises);
	}

	private addMapControls(map: atlas.Map) {
		let mapControls = this.getBaseMapControls();

		if (!isMobile) {
			mapControls.splice(1, 0, new window.atlas.control.ZoomControl());
		}

		map.controls.add([...mapControls], {
			position: this.getMapControlPosition()
		});
	}

	public getMapControlPosition = () => {
		return isMobile ? atlas.ControlPosition.TopRight : atlas.ControlPosition.BottomRight;
	};

	public getBaseMapControls = () => {
		return [
			new window.atlas.control.GeolocationControl({
				style: 'auto'
			}),
			new window.atlas.control.StyleControl({
				mapStyles: ['road', 'satellite_road_labels', 'night']
			}),
			new window.atlas.control.PitchControl(),
			new window.atlas.control.CompassControl()
		];
	};

	private saveCamera(map: atlas.Map) {
		let cameraStorage = localStorageService.getLocalStorage(LocalStorageName.FacilityMapCamera) as CameraStorage;
		localStorageService.setLocalStorage(LocalStorageName.FacilityMapCamera, {
			cameraOptions: map.getCamera(),
			styleOptions: cameraStorage && cameraStorage.styleOptions ? cameraStorage.styleOptions : null
		} as CameraStorage);
	}

	private saveStyle(map: atlas.Map) {
		let cameraStorage = localStorageService.getLocalStorage(LocalStorageName.FacilityMapCamera) as CameraStorage;
		localStorageService.setLocalStorage(LocalStorageName.FacilityMapCamera, {
			cameraOptions: cameraStorage && cameraStorage.cameraOptions ? cameraStorage.cameraOptions : null,
			styleOptions: map.getStyle()
		} as CameraStorage);
	}

	loadMapSettings(defaultSettings?: MapSettings): MapSettings {
		return localStorageService.getLocalStorageWithDefaultValue(
			MapSettingsLocalStorageKey,
			defaultSettings || initialMapSettings
		);
	}

	saveMapSettings(mapSettingsToSave: MapSettings) {
		localStorageService.setLocalStorage(MapSettingsLocalStorageKey, mapSettingsToSave);
	}

	toFacilityMapFeatures = (mapLocations: EntityMapLocation[]) => {
		let mapFeatures: atlas.data.Feature<atlas.data.Point, any>[] = [];
		_.map(mapLocations, mapLocation => {
			if (mapLocation.entityId && mapLocation.entityType && mapLocation.mapObjectJson) {
				if (String.equalCaseInsensitive(mapLocation.entityType, EntityMapLocationType.FogFacility)) {
					let facility: FogFacility = JSON.parse(mapLocation.mapObjectJson);
					_.set(facility, PropertyKeyForResolvedRiskScore, toClusterRiskScore(facility.riskScore));
					let complianceStatusClusterValue =
						facility.isOverrideComplianceStatus === true
							? facility.overriddenComplianceStatus + customSuffix
							: facility.complianceStatus;
					_.set(facility, PropertyKeyForResolvedComplianceStatus, complianceStatusClusterValue);
					_.set(
						facility,
						PropertyKeyForResolvedDeviceStatus,
						toClusterDeviceStatus(facility.activeDeviceCount)
					);

					let longitude = _.toNumber(mapLocation.longitude);
					let latitude = _.toNumber(mapLocation.latitude);
					_.set(facility, PropertyKeyForResolvedLongitude, longitude);
					_.set(facility, PropertyKeyForResolvedLatitude, latitude);

					mapFeatures.push(
						new atlas.data.Feature(
							new atlas.data.Point([longitude, latitude]),
							facility,
							`${facility.facilityId}|${facility.referenceNumber}`
						)
					);
				}
			}
		});
		return mapFeatures;
	};

	toIncidentMapFeatures = (mapLocations: EntityMapLocation[]) => {
		let mapFeatures: atlas.data.Feature<atlas.data.Point, any>[] = [];
		_.map(mapLocations, mapLocation => {
			if (mapLocation.entityId && mapLocation.entityType && mapLocation.mapObjectJson) {
				if (String.equalCaseInsensitive(mapLocation.entityType, EntityMapLocationType.Incident)) {
					let incident: Incident = JSON.parse(mapLocation.mapObjectJson);

					let longitude = _.toNumber(mapLocation.longitude);
					let latitude = _.toNumber(mapLocation.latitude);

					mapFeatures.push(
						new atlas.data.Feature(
							new atlas.data.Point([longitude, latitude]),
							incident,
							`${incident.incidentId}|${incident.incidentNumber}`
						)
					);
				}
			}
		});
		return mapFeatures;
	};

	getFacilityCoordinate = (facilityProperties: any): atlas.data.Position => {
		let longitude = _.get(facilityProperties, PropertyKeyForResolvedLongitude, 0);
		let latitude = _.get(facilityProperties, PropertyKeyForResolvedLatitude, 0);
		let coordinate: atlas.data.Position = [longitude, latitude];
		return coordinate;
	};

	getIncidentCoordinate = (incidentProperties: any): atlas.data.Position => {
		let longitude = _.get(incidentProperties, 'longitude', 0);
		let latitude = _.get(incidentProperties, 'latitude', 0);
		let coordinate: atlas.data.Position = [longitude, latitude];
		return coordinate;
	};

	getPieChartMarkerOptions = (
		position: atlas.data.Position,
		properties: any,
		mapConfig: MapClusterConfiguration
	): PieChartMarker => {
		let radius = 20;

		if (properties.point_count > 1000) {
			radius = 48;
		} else if (properties.point_count > 100) {
			radius = 42;
		} else if (properties.point_count > 50) {
			radius = 36;
		} else if (properties.point_count > 10) {
			radius = 30;
		} else if (properties.point_count > 5) {
			radius = 24;
		}
		let values: number[] = [...mapConfig.clusterValues];
		for (let i = 0; i < mapConfig.pinTypes.length; i++) {
			if (properties[mapConfig.pinTypes[i]]) {
				values[i] = properties[mapConfig.pinTypes[i]];
			}
		}

		let pieChartMarkerOptions = {
			position: position,
			values: values,
			colors: mapConfig.pinColors,
			radius: radius,
			fillColor: 'white',
			strokeWidth: 1,
			strokeColor: 'white',
			innerRadius: radius * 0.45,
			text: properties.point_count_abbreviated,
			textClassName: `cluster-text`,
			tooltipCallback: (marker: any, sliceIdx: number) => {
				return `${mapConfig.pinTypes[sliceIdx]}: ${marker.getSliceValue(sliceIdx)} (${marker.getSlicePercentage(
					sliceIdx
				)}%)`;
			}
		};
		return new PieChartMarker(pieChartMarkerOptions);
	};

	createMapMarkerLayer(
		map: atlas.Map,
		mapLegend: MapLegendKey,
		complianceValues: string[],
		mapClusterClickEvent: (e: atlas.TargetedEvent) => void,
		mapPinClickEvent: (e: atlas.TargetedEvent) => void
	) {
		if (
			mapLegend !== MapLegendKey.Risk &&
			mapLegend !== MapLegendKey.Compliance &&
			mapLegend !== MapLegendKey.DeviceStatus
		) {
			throw new Error(`MapLegend ${mapLegend} is unsupported.`);
		}

		const mapPins = mapPinHtmlContent();
		const mapConfig = getFacilityClusterPropertyTypes(mapLegend, complianceValues);
		let dataSource = new atlas.source.DataSource(_.uniqueId(), {
			cluster: true,
			clusterRadius: 50,
			clusterProperties: mapConfig.clusterProperties,
			clusterMaxZoom: 15
		});
		map.sources.add(dataSource);

		let dataSourceStreetLevel = new atlas.source.DataSource(_.uniqueId(), {
			cluster: true,
			clusterRadius: 5,
			clusterProperties: mapConfig.clusterProperties,
			clusterMaxZoom: 18
		});
		map.sources.add(dataSourceStreetLevel);

		const markerCallback = function(id: string, position: atlas.data.Position, properties: any) {
			if (properties.cluster) {
				return mapService.getPieChartMarkerOptions(position, properties, mapConfig);
			}

			let mapPinIconKey = getMapPinHtmlContent(mapLegend, properties, mapConfig);
			let mapPinHtmlContent = mapPins[mapPinIconKey];
			let marker = new atlas.HtmlMarker({
				htmlContent: mapPinHtmlContent,
				position: position
			});
			return marker;
		};
		let markerLayer = new HtmlMarkerLayer(dataSource, _.uniqueId(), {
			markerCallback,
			maxZoom: 16
		});

		let markerLayerStreetLevel = new HtmlMarkerLayer(dataSourceStreetLevel, _.uniqueId(), {
			markerCallback,
			minZoom: 16
		});

		this.configureLayerEventListeners(map, markerLayer, mapClusterClickEvent, mapPinClickEvent);
		this.configureLayerEventListeners(map, markerLayerStreetLevel, mapClusterClickEvent, mapPinClickEvent);

		//Add marker layer to the map.
		map.layers.add(markerLayer);
		map.layers.add(markerLayerStreetLevel);

		return { dataSource: dataSource, markerLayer: markerLayer, dataSourceStreetLevel, markerLayerStreetLevel };
	}

	private configureLayerEventListeners(
		map: atlas.Map,
		layer: HtmlMarkerLayer,
		clusterClickEvent: (e: atlas.TargetedEvent) => void,
		pinClickEvent: (e: atlas.TargetedEvent) => void
	) {
		map.events.add('mouseover', layer, function() {
			map.getCanvasContainer().style.cursor = 'pointer';
		});
		map.events.add('mouseout', layer, function() {
			map.getCanvasContainer().style.cursor = 'grab';
		});
		map.events.add('click', layer, e => {
			let marker = e.target as any;
			let properties = marker.properties as any;
			if (properties.cluster) {
				clusterClickEvent(e);
			} else {
				pinClickEvent(e);
			}
		});
	}

	createDrawingToolForSelection(
		map: atlas.Map,
		handleSelectedArea: (poly: atlas.data.Feature<atlas.data.Geometry, any>) => void
	) {
		let drawingTool = new atlasDrawing.drawing.DrawingManager(map, {
			toolbar: new atlasDrawing.control.DrawingToolbar({
				buttons: ['draw-polygon'],
				position: this.getMapControlPosition(),
				style: 'light'
			}),
			interactionType: atlasDrawing.drawing.DrawingInteractionType.freehand
		});
		let drawLayer = drawingTool.getLayers();
		let poligonLayer = drawLayer.polygonLayer;
		if (poligonLayer) {
			poligonLayer.setOptions({ visible: false });

			map.events.add('drawingmodechanged', drawingTool, (drawingMode: atlasDrawing.drawing.DrawingMode) => {
				if (drawingMode === atlasDrawing.drawing.DrawingMode.idle) {
					drawingTool.getSource().clear();
				}
			});

			//Monitor for when a polygon drawing has been completed.
			map.events.add('drawingcomplete', drawingTool, (searchArea: atlas.Shape) => {
				if (searchArea.isCircle()) {
					//If the search area is a circle, create a polygon from its circle coordinates.
					Logger.info('Circle coodinates:', searchArea.getCircleCoordinates());
					searchArea = new atlas.Shape(new atlas.data.Polygon([searchArea.getCircleCoordinates()]));
				} else if (searchArea.isRectangle()) {
					Logger.info('Rectangle coodinates:', searchArea.getCoordinates());
				} else {
					Logger.info('Polygon coodinates:', searchArea.getCoordinates());
				}
				//Retrieve the individual points that are in the search area.
				var poly = searchArea.toJson();
				handleSelectedArea(poly);
			});
			return drawingTool;
		}
	}

	createDataSourceForGeoJsonData(map: atlas.Map, onFeatureClicked: (e: any) => void) {
		let datasource = new atlas.source.DataSource();
		map.sources.add(datasource);

		//Add a layer for rendering the polygons.
		let polygonLayer = new atlas.layer.PolygonLayer(datasource, _.uniqueId(), {
			fillColor: '#1e90ff',
			filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']] //Only render Polygon or MultiPolygon in this layer.
		});
		map.events.add('click', polygonLayer, onFeatureClicked);

		//Add a layer for rendering line data.
		var lineLayer = new atlas.layer.LineLayer(datasource, _.uniqueId(), {
			strokeColor: '#1e90ff',
			strokeWidth: 4,
			filter: ['any', ['==', ['geometry-type'], 'LineString'], ['==', ['geometry-type'], 'MultiLineString']] //Only render LineString or MultiLineString in this layer.
		});
		map.events.add('click', lineLayer, onFeatureClicked);

		//Add a layer for rendering point data.
		var pointLayer = new atlas.layer.SymbolLayer(datasource, _.uniqueId(), {
			iconOptions: {
				allowOverlap: true,
				ignorePlacement: true
			},
			filter: ['any', ['==', ['geometry-type'], 'Point'], ['==', ['geometry-type'], 'MultiPoint']] //Only render Point or MultiPoints in this layer.
		});
		map.events.add('click', pointLayer, onFeatureClicked);

		//Add polygon and line layers to the map, below the labels..
		map.layers.add(
			[
				polygonLayer,
				//Add a layer for rendering the outline of polygons.
				new atlas.layer.LineLayer(datasource, _.uniqueId(), {
					strokeColor: 'black',
					filter: ['any', ['==', ['geometry-type'], 'Polygon'], ['==', ['geometry-type'], 'MultiPolygon']] //Only render Polygon or MultiPolygon in this layer.
				}),

				lineLayer,
				pointLayer
			],
			'labels'
		);
		//Add the point layer above the labels.
		map.layers.add(pointLayer);
		return datasource;
	}

	createDropGeoJsonFileZone(mapElementId: string, map: atlas.Map, loadGeoJSONData: (files: any[]) => void) {
		let dropZone = document.getElementById(mapElementId);
		if (!dropZone) {
			throw new Error(`Cannot find HTML element which id is ${mapElementId}`);
		}
		dropZone.addEventListener(
			'dragover',
			evt => {
				evt.stopPropagation();
				evt.preventDefault();

				evt.dataTransfer.dropEffect = 'copy';
			},
			false
		);
		dropZone.addEventListener(
			'drop',
			evt => {
				evt.stopPropagation();
				evt.preventDefault();

				//The list of files that have been dragged and dropped onto the map.
				var files = evt.dataTransfer.files;

				//Keep track of the bounding box of all the data from all files dropped into the map.
				var dataBounds = null;

				//Loop through and attempt to read each file.
				for (var i = 0; i < files.length; i++) {
					var reader = new FileReader();

					reader.onload = function(e) {
						try {
							var geojsonData = JSON.parse(e.target.result);

							loadGeoJSONData(geojsonData);

							//Calculate the bounding box of the GeoJSON data.
							var bounds = atlas.data.BoundingBox.fromData(geojsonData);

							//If data is already loaded from another GeoJSON file, merge the bounding boxes together.
							if (dataBounds) {
								dataBounds = atlas.data.BoundingBox.merge(dataBounds, bounds);
							} else {
								dataBounds = bounds;
							}

							//Update the map view to show the data.
							map.setCamera({
								bounds: dataBounds,
								padding: 50
							});
						} catch (e) {
							alert('Unable to read file as GeoJSON.');
						}
					};

					//Read the file as text.
					reader.readAsText(files[i]);
				}
			},
			false
		);
	}
}

export const mapService = new MapService();
