import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { siteSlice } from '../sites.slice';
import { PopoverModal } from 'src/components/widgets';
import { CccSite, CustomFieldDataType, ICustomFieldItem } from '@rcp/types';
import {
	customDefinedFieldService,
	localizationService,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import { alertService, CustomFieldDefinitionsState, RootState } from 'src/redux';
import _, { Dictionary } from 'lodash';
import { customFieldGenerator } from 'src/components/authority/fog/facilities/facility-details/custom-fields-tab/custom-field-service';
import { Utils } from 'src/services/utils';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentCccSite: CccSite;
}

type Props = OwnProps;

interface CustomFieldForm {
	customFields: ICustomFieldItem[];
}

const initialCustomFieldForm: CustomFieldForm = {
	customFields: []
};

export const CccSiteCustomFieldsModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialCustomFieldForm);

	let customFieldDefinitions = useSelector((state: RootState) => state.customFieldDefinitions);

	const setFormStateFromProps = (site: CccSite, siteCustomFieldDefinitions: CustomFieldDefinitionsState) => {
		if (
			!_.isEmpty(siteCustomFieldDefinitions) &&
			!_.isEmpty(siteCustomFieldDefinitions.customFieldDefinitions) &&
			!_.isEmpty(site)
		) {
			let customFields = customDefinedFieldService.toCustomFieldItems(
				siteCustomFieldDefinitions.customFieldDefinitions,
				site
			);
			customFields = Utils.sortCustomField(customFields);
			setFormState({ customFields });
		}
	};

	useEffect(() => {
		if (customFieldDefinitions) {
			setFormStateFromProps(props.currentCccSite, customFieldDefinitions);
		}
	}, [customFieldDefinitions, props.currentCccSite]);

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		_.each(newState.customFields, customField => {
			(customField.isRequired || customField.isImportKey) &&
				validationService.validateRequiredField(customField, 'value', 'error');
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Number)
			) {
				validationService.validateNumberField(customField, 'value', 'error');
			}
			if (customField.fieldDataType && String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)) {
				validationService.validateMinimumDate(customField, 'value', 'error', customField.label);
			}
			if (
				customField.fieldDataType &&
				String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Link)
			) {
				validationService.validateUrlField(customField, 'value', 'error');
			}
		});
		setFormState(newState);

		let hasError = false;
		_.each(newState.customFields, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};
	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.each(newState.customFields, customField => {
			if (String.equalCaseInsensitive(customField.fieldName, name)) {
				customField.value = String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)
					? value
					: value;
				customField.isRequired && validationService.validateRequiredField(customField, 'value', 'error');
			}
		});
		setFormState(newState);
	};
	const saveCustomFields = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let customFields: Dictionary<string> = {};
		formState.customFields.forEach(item => (customFields[UtilService.toCamelCase(item.fieldName)] = item.value));
		let siteToUpdate: CccSite = {
			customFields: customFields
		};
		dispatch(
			siteSlice.patchOne(
				urlService.getCccSiteId(),
				siteToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccSite.site')
				),
				() => props.toggle()
			)
		);
	};

	const cancelSaveCustomFields = () => {
		setFormStateFromProps(props.currentCccSite, customFieldDefinitions);
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title="Edit Custom Fields"
				save={saveCustomFields}
				cancel={cancelSaveCustomFields}>
				{formState.customFields.map((customField: ICustomFieldItem, index: number) => {
					if (String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)) {
						return customFieldGenerator.getDateInputElement(customField, index, changeFormState);
					}

					if (
						String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.SingleValueDropDown)
					) {
						return customFieldGenerator.getSingleSelectionValueElement(customField, index, changeFormState);
					}

					if (String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.TextArea)) {
						return customFieldGenerator.getTextAreaInputElement(customField, index, true, changeFormState);
					}

					return customFieldGenerator.getTextInputElement(customField, index, changeFormState);
				})}
			</PopoverModal>
		</div>
	);
};
