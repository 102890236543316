import _ from 'lodash';

export class ComplianceStatusService {
	isCompliant = (complianceStatus?: string): boolean => {
		return _.toString(complianceStatus).includes('pass') || _.toString(complianceStatus).includes('incompliant');
	};

	isNotCompliant = (complianceStatus?: string): boolean => {
		return _.toString(complianceStatus).includes('fail') || _.toString(complianceStatus).includes('noncompliant');
	};
}

export const complianceStatusService = new ComplianceStatusService();
