import React from 'react';

interface Props {}

export const DrawButtonSvg: React.FC<Props> = props => {
	return (
		<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16">
			<path
				d="M11.82 12.018H.18V.607L7.6 5.362 11.821.098v11.92zm-10.64-1h9.64V2.944L7.818 6.689 1.18 2.435v8.583z"
				style={{ fill: '#83888D' }}
			/>
		</svg>
	);
};
