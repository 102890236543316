import React, { FC } from 'react';
import { localizationService } from 'src/services';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { Translate } from 'src/components/widgets/translate/translator';

export interface OwnProps {
	portalConfirmationDetail: any;
	isFacilityPortal: boolean;
	onConfirmClick: () => void;
	reEnterInviteCode?: () => void;
	doNotShowTitle?: boolean;
}

export const ConfirmPortalInvite: FC<OwnProps> = props => {
	const {
		authorityOrganizationName,
		authorityAddressLine1,
		authorityAddressLine2,
		authorityAddressCity,
		authorityCity,
		authorityAddressJurisdictionCode,
		authorityJurisdictionCode,
		authorityAddressZipCode,
		authorityZipCode,
		name,
		addressLine1,
		addressLine2,
		addressCity,
		addressJurisdictionCode,
		addressZipCode
	} = props.portalConfirmationDetail;
	const authorityCityName = authorityCity || authorityAddressCity;
	const authorityStateCode = authorityJurisdictionCode || authorityAddressJurisdictionCode;

	React.useEffect(() => {
		document.body.className = 'signinBody';
		return () => {
			document.body.className = '';
		};
	}, []);

	return (
		<>
			<div>
				{!props.doNotShowTitle ? (
					<h1 className="text-center">
						<Translate>{localizationService.getLocalizedString('haulerPortal.isThisCorrect')}</Translate>{' '}
					</h1>
				) : null}
				<div className="row mt-4">
					<div className="col-lg-6">
						<strong className="font-size-16px-medium confirm-subheading">
							<Translate>
								{localizationService.getLocalizedString('haulerPortal.youWereInvited')}
							</Translate>
						</strong>
						<p className="mt-3 confirm-detail">
							<ul className="pl-0">
								<li>{authorityOrganizationName}</li>
								<li>{authorityAddressLine1}</li>
								<li>{authorityAddressLine2}</li>
								<li>
									{authorityCityName && `${authorityCityName},`}{' '}
									{authorityStateCode && authorityStateCode}{' '}
									{authorityAddressZipCode || authorityZipCode}
								</li>
							</ul>
						</p>
					</div>
					<div className="col-lg-6">
						<strong className="font-size-16px-medium confirm-subheading">
							<Translate>
								{props.isFacilityPortal
									? localizationService.getLocalizedString('facilityPortal.yourFacility')
									: localizationService.getLocalizedString('haulerPortal.yourBusiness')}
							</Translate>
						</strong>
						<p className="mt-3 confirm-detail">
							<ul className="pl-0">
								<li>{name}</li>
								<li>{addressLine1}</li>
								<li>{addressLine2}</li>
								<li>
									{addressCity && `${addressCity},`}{' '}
									{addressJurisdictionCode && addressJurisdictionCode} {addressZipCode}
								</li>
							</ul>
						</p>
					</div>
				</div>
				<div>
					<span>
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.isThisCorrectDescription1')}
						</Translate>
					</span>
					<a
						onClick={() => {
							props.reEnterInviteCode && props.reEnterInviteCode();
						}}
						className="text-decoration-none cursor-pointer">
						{' '}
						<Translate>
							{localizationService.getLocalizedString('haulerPortal.isThisCorrectDescription2')}
						</Translate>
					</a>
				</div>
				<div className="d-flex justify-content-end align-items-center w-100 mt-4">
					<Button id="confirm" className="ai-action" onClick={e => props.onConfirmClick()}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.confirm')}</Translate>
					</Button>
					<Button
						color="btn ai-white ml-2"
						onClick={e => props.reEnterInviteCode && props.reEnterInviteCode()}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.cancel')}</Translate>
					</Button>
				</div>
			</div>
		</>
	);
};
