import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { alertService } from 'src/redux';
import { InspectionFormTemplate } from '@rcp/types';
import { PopoverModal, TextInput, SingleCheckbox, TextAreaInput } from 'src/components/widgets';
import { validationService, localizationService } from 'src/services';
import { inspectionFormTemplatesSlice } from './inspection-template-slice';

interface OwnProps {
	isCreate: boolean;
	showModal: boolean;
	toggleModal: () => void;
	inspectionTemplate?: InspectionFormTemplate;
}

type Props = OwnProps;

interface InspectionTemplateFormState extends InspectionFormTemplate {
	templateNameMissingError?: string;
	templateContentMissingError?: string;
}

export const InspectionTemplateModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState<InspectionTemplateFormState>({});

	const dispatch = useDispatch();

	const setFormStateFromProps = (inspectionTemplate?: InspectionFormTemplate) => {
		let newState = { ...inspectionTemplate };
		setFormState(newState);
	};

	React.useEffect(() => {
		let newState = { ...props.inspectionTemplate };
		setFormState(newState);
	}, [props.inspectionTemplate]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		_.set(newState, name, value);

		setFormState(newState);
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'templateName',
			'templateNameMissingError',
			localizationService.getLocalizedString('inspectionFormTemplates.templateName')
		);
		validationService.validateRequiredField(
			newState,
			'templateContent',
			'templateContentMissingError',
			localizationService.getLocalizedString('inspectionFormTemplates.templateContent')
		);

		let isFormValid = !validationService.hasError(
			newState,
			'templateNameMissingError',
			'templateContentMissingError'
		);
		if (!isFormValid) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
		return isFormValid;
	};

	const saveButtonClicked = async () => {
		if (!validateBeforeForSave()) {
			return;
		}
		let templateToSave = { ...formState };

		if (templateToSave.inspectionFormTemplateId) {
			dispatch(
				inspectionFormTemplatesSlice.patchOne(
					templateToSave.inspectionFormTemplateId,
					templateToSave,
					true,
					localizationService.getLocalizedString(
						'alertMessages.savedSuccess',
						'inspectionFormTemplates.template'
					),
					() => {
						props.toggleModal();
					}
				)
			);
		} else {
			dispatch(
				inspectionFormTemplatesSlice.createOne(
					templateToSave,
					true,
					localizationService.getLocalizedString(
						'alertMessages.addSuccess',
						'inspectionFormTemplates.template'
					),
					() => {
						setFormState({});
						props.toggleModal();
					}
				)
			);
		}
	};

	const cancelSave = () => {
		setFormStateFromProps(props.inspectionTemplate);
		props.toggleModal();
	};

	const modalFooterDiv = () => {
		return formState.isRemoved ? (
			<></>
		) : (
			<div className="ml-auto">
				<button id="btnSave" className="btn ai-save ml-2" onClick={saveButtonClicked}>
					{localizationService.getLocalizedString('screen.buttons.save')}
				</button>
				<button id="btnCancel" className="btn ai-white ml-2" onClick={cancelSave}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		);
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={
					props.isCreate
						? localizationService.getLocalizedString('inspectionFormTemplates.add')
						: localizationService.getLocalizedString('inspectionFormTemplates.edit')
				}
				footer={modalFooterDiv()}
				save={saveButtonClicked}
				cancel={cancelSave}>
				<p>
					<a
						href="https://danaher.sharepoint.com/:w:/s/AQIACPWarriors/EYadGCypQo9CsZaG9msplCcBrDcPeyxPRrlaDOFJ-O6SzQ?e=uIz71T"
						target="_blank"
						rel="noopener noreferrer">
						{localizationService.getLocalizedString('inspectionFormTemplates.developerGuideline')}
					</a>
				</p>
				<TextInput
					id="templateName"
					name="templateName"
					label={localizationService.getLocalizedString('inspectionFormTemplates.templateName')}
					value={formState.templateName}
					onChange={changeFormState}
					isRequired={true}
					error={formState.templateNameMissingError}
					isDisabled={formState.isRemoved}
				/>
				<SingleCheckbox
					id="isInternalOnly"
					name="isInternalOnly"
					label={localizationService.getLocalizedString(
						'inspectionFormTemplates.isInternalOnlyWithDescription'
					)}
					checked={formState.isInternalOnly}
					onChange={changeFormState}
					className="div-checkbox"
					isDisabled={formState.isRemoved}
				/>
				{/* <SingleCheckbox
					id="isDefaultTemplate"
					name="isDefaultTemplate"
					label={localizationService.getLocalizedString('inspectionFormTemplates.isDefaultTemplateWithDescription')}
					checked={formState.isDefaultTemplate}
					onChange={changeFormState}
					className="form-group div-checkbox"
					isDisabled={formState.isRemoved}
				/> */}
				<SingleCheckbox
					id="isActive"
					name="isActive"
					label={localizationService.getLocalizedString('inspectionFormTemplates.isActiveWithDescription')}
					checked={formState.isActive}
					onChange={changeFormState}
					className="div-checkbox"
					isDisabled={formState.isRemoved}
				/>

				<TextAreaInput
					id="templateContent"
					name="templateContent"
					label={localizationService.getLocalizedString('inspectionFormTemplates.templateContent')}
					value={formState.templateContent}
					onChange={changeFormState}
					isRequired={true}
					error={formState.templateContentMissingError}
					isFullWidth={true}
					rows={10}
					isDisabled={formState.isRemoved}
				/>
			</PopoverModal>
		</div>
	);
};
