import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState, PageLoadingState } from 'src/redux';

interface StateProps extends PageLoadingState {
	className?: string;
}

type Props = StateProps;

const PageLoadingContainerComp: React.SFC<Props> = props => {
	return (
		<div className="loading-area">
			{props.loadingCounter > 0 && (
				<div className={`loading ${props.className}`}>
					<div className="loading-bar"></div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.pageLoadingState };
};

export const PageLoadingContainer = connect<StateProps, {}, {}, ApplicationState>(mapStateToProps)(
	PageLoadingContainerComp
);
