import React, { FC } from 'react';
import './switch-button.scss';
interface Props {
	handleClick: (e: any) => void;
	isChecked?: boolean;
}
export const SwitchButton: FC<Props> = props => {
	return (
		<label className="switch">
			<input type="checkbox" checked={props.isChecked} onClick={e => props.handleClick && props.handleClick(e)} />
			<span className="slider round"></span>
		</label>
	);
};
