import { FilterType, ApiFilterProperty, ApiFilterDefinition } from '@rcp/types';
import {
	OperatorCode,
	FilterItem,
	DomainObject,
	FilterProperty,
	Operator,
	FilterValue
} from '../../services/data-types/filter-types';
import _ from 'lodash';
import { alertService } from '../alert';
import { filterService } from '../../features/filter/filter-service';

export const MappingFromApiFormat = (filterSchemaTxt: string): string | undefined => {
	try {
		let apiFilterSchema: ApiFilterDefinition = _.isEmpty(filterSchemaTxt)
			? {
					filterGroups: [
						{
							filterProperties: [],
							logicalOperator: 'and'
						}
					]
			  }
			: (JSON.parse(filterSchemaTxt) as ApiFilterDefinition);

		let apiFilterDefinitions = apiFilterSchema.filterGroups[0].filterProperties;
		let filterDefinitions = apiFilterDefinitions.map((apiFilterDefinition: ApiFilterProperty) => {
			let filterProperty = new FilterProperty({
				label: apiFilterDefinition.objectName,
				name: apiFilterDefinition.propertyName,
				filterType: apiFilterDefinition.propertyType
			});
			if (apiFilterDefinition.isCustomField === true) {
				filterProperty.isCustomField = true;
			}
			let filterItem = new FilterItem(
				new DomainObject('', apiFilterDefinition.objectName, []),
				filterProperty,
				new Operator('', apiFilterDefinition.operator)
			);

			switch (apiFilterDefinition.propertyType) {
				case FilterType.Boolean:
				case FilterType.Enum:
					filterItem.values = [
						{
							values: apiFilterDefinition.values
						}
					];
					return filterItem;
				case FilterType.Text:
					let domainObjectNameInUpperCase = _.toString(apiFilterDefinition.objectName).toUpperCase();

					if (_.includes(filterService.supportedDomainObjectNames, domainObjectNameInUpperCase)) {
						filterItem.values = [
							{
								values: apiFilterDefinition.values
							}
						];
						return filterItem;
					} else {
						throw new Error(
							`Cannot handle text filter on domain object ${apiFilterDefinition.objectName}, please add the domain object text field support`
						);
					}

				case FilterType.Integer:
				case FilterType.Number:
					if (_.toString(apiFilterDefinition.operator) === OperatorCode.IsBetween) {
						filterItem.values = [
							{
								value: apiFilterDefinition.values[0]
							},
							{
								value: apiFilterDefinition.values[1]
							}
						];
					} else if (apiFilterDefinition.values.length === 1) {
						filterItem.values = [
							{
								value: apiFilterDefinition.values[0]
							}
						];
					} else {
						throw new Error('Cannot convert the API filter property for number with multiple values.');
					}
					return filterItem;
				case FilterType.Date:
					filterItem.values = apiFilterDefinition.values.map<FilterValue>(value => {
						return {
							value: value
						};
					});
					return filterItem;
				default:
					throw new Error(
						`Cannot deserialize API filter item to view model for filter type ${apiFilterDefinition.propertyType}`
					);
			}
		});

		return JSON.stringify(filterDefinitions);
	} catch (error) {
		alertService.addError(error);
	}
};

export const MappingToApiFormat = (filterItems: FilterItem[]) => {
	let filterProperties = filterItems.map((filterItem: FilterItem) => {
		let apiFilterDefinition: ApiFilterProperty = {
			objectName: _.get(filterItem, 'domainObject.name'),
			propertyName: _.get(filterItem, 'property.name'),
			propertyType: _.get(filterItem, 'property.filterType'),
			operator: _.get(filterItem, 'operator.name'),
			values: getApiPropertyValuesFromFilterItem(filterItem)
		} as ApiFilterProperty;
		if (filterItem.property.isCustomField === true) {
			apiFilterDefinition.isCustomField = true;
		}
		return apiFilterDefinition;
	});

	let filterSchema: ApiFilterDefinition = {
		filterGroups: [
			{
				filterProperties,
				logicalOperator: 'and'
			}
		]
	};

	return filterSchema;
};

function getApiPropertyValuesFromFilterItem(filterItem: FilterItem): string[] {
	switch (filterItem.property.filterType) {
		case FilterType.Boolean:
		case FilterType.Enum:
		case FilterType.Text:
			return filterItem.values[0].values as string[];
		case FilterType.Number:
		case FilterType.Integer:
		case FilterType.Date:
			return filterItem.values.map<string>(ov => {
				return ov.value as string;
			});
		default:
			break;
	}
	return [];
}
