import React from 'react';

interface Props {
	posClass?: string;
	pathClass?: string;
	title?: string;
	size?: number;
}

export const ClearSvg: React.SFC<Props> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ? props.size : '12'}
			height={props.size ? props.size : '12'}
			viewBox="0 0 12 12"
			className={props.posClass}>
			<circle cx="6" cy="6" r="6" style={{ fill: '#3c3e69' }} />
			<path
				d="M6.79,6,8.7,4.09a.4.4,0,0,0,0-.56L8.47,3.3a.43.43,0,0,0-.28-.11.41.41,0,0,0-.28.11L6,5.21,4.09,3.3a.41.41,0,0,0-.28-.11.43.43,0,0,0-.28.11l-.23.23a.4.4,0,0,0,0,.56L5.21,6,3.3,7.91a.4.4,0,0,0,0,.56l.23.23a.43.43,0,0,0,.28.11.41.41,0,0,0,.28-.11L6,6.79,7.91,8.7a.41.41,0,0,0,.28.11.43.43,0,0,0,.28-.11l.23-.23a.4.4,0,0,0,0-.56Z"
				style={{ fill: '#fff' }}
			/>
		</svg>
	);
};
