import React from 'react';

import * as ApiTypes from '@rcp/types';
import { ReadEmailModal } from '../send-email/read-email';

interface OwnProps {
	event: ApiTypes.EmailLog;
	index?: number;
	isDeviceDetailPage?: boolean;
}
type Props = OwnProps;

export const TimelineEventEmail: React.SFC<Props> = props => {
	const [showReadEmailModal, setShowReadEmailModal] = React.useState(false);

	const onShowEmailDetailsLinkClicked = (e: any) => {
		e.preventDefault();
		setShowReadEmailModal(true);
	};

	const toggleReadEmailModal = () => {
		setShowReadEmailModal(!showReadEmailModal);
	};
	return props.event.emailMessage ? (
		<div>
			<div>
				<a href="#/" onClick={onShowEmailDetailsLinkClicked} id={`linkFacilitySentEmail-${props.index}`}>
					{props.event.emailMessage.subject}
				</a>{' '}
				email sent by{' '}
				{props.event.emailMessage.fromEmailAddress
					? props.event.emailMessage.fromEmailAddress.name
						? props.event.emailMessage.fromEmailAddress.name
						: props.event.emailMessage.fromEmailAddress.address
					: ''}{' '}
				to{' '}
				{props.event.emailMessage.recipientEmailAddresses
					? props.event.emailMessage.recipientEmailAddresses
							.map(x => {
								return x.name.includes('undefined') ? x.address : x.name;
							})
							.join(', ')
					: ''}
			</div>
			{showReadEmailModal && (
				<ReadEmailModal
					showModal={showReadEmailModal}
					setShowModal={toggleReadEmailModal}
					emailLog={props.event}
					isDeviceDetailPage={props.isDeviceDetailPage}
				/>
			)}
		</div>
	) : (
		<></>
	);
};
