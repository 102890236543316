import React, { FC, useEffect } from 'react';
import { useState } from 'react';
import { TextInput, DateInput, SingleCheckbox, SingleSelectDropdown, TextAreaInput } from '..';
import { DragDropBox } from '../drag-drop-box/drag-drop-box';
import './custom-form.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { DragDropBoxContext } from 'src/components/authority/shared/settings/custom-forms/form-builder';
import { useContext } from 'react';
import { Signature as SignatureModal } from '../signature';
import { UploadAttachment } from '../upload-attachments';
import { CustomCleaningFields, CustomFieldType, CustomFormFieldType, CustomFormType, DropDownOption } from '@rcp/types';
import { localizationService, urlService } from 'src/services';
import { ReactComponent as SvgDrag } from 'src/assets/img/drag-indicator.svg';
import { AlertMessageType } from 'src/redux';
import _ from 'lodash';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

interface Props {
	title?: string;
	isPreviewMode?: boolean;
	handleDrop: (e: any) => void;
	handleDragStart: (e: any) => void;
	data?: CustomFieldType[];
	handleDelete?: (dataItem: any) => void;
	handleValidateCustomField?: () => void;
}

interface CustomFormDropdownOption {
	haulers: DropDownOption[];
	wasteTypes: DropDownOption[];
	fogLeavingOutlet: DropDownOption[];
	lookUpData: DropDownOption[];
}

const { Text, TextArea, Date, Lookup, CustomSelect, AttachmentControl, Checkbox, Signature } = CustomFormFieldType;
const { DueDate, CompleteDate, Certification, PercentGreaseCalculator } = CustomCleaningFields;
const { AuthorityCleaningForm } = CustomFormType;

const CustomFormRenderer = (prop: any) => {
	const [showIcons, setShowIcons] = useState(false);
	const [draggable, setDraggable] = useState(false);
	const [options, setOptions] = useState<CustomFormDropdownOption>({
		haulers: [],
		wasteTypes: [],
		fogLeavingOutlet: [],
		lookUpData: []
	});
	let { dataItem, className, ...others } = prop;
	let { inputs, name, defaultUnit, certStatement } = dataItem;
	className += ` custom-form-input-group ${
		window.matchMedia('(max-width: 450px)').matches ? 'm-3 px-2 pt-1' : 'px-4 pt-2'
	}`;
	const { handleDelete, handlePreview, handleChangeInputValue, isPreviewMode, formType } = useContext(
		DragDropBoxContext
	);
	const isDateField =
		(name === DueDate && _.toLower(formType) === _.toLower(AuthorityCleaningForm)) || name === CompleteDate;
	const [percentGrease, setPercentGrease] = useState(0);

	useEffect(() => {
		name === PercentGreaseCalculator && calculatePercent();
	}, [dataItem]);

	const calculatePercent = () => {
		let trapDepth = 0;
		let fog = 0;
		let solid = 0;
		_.each(inputs, inputItem => {
			if (inputItem.fieldId === 'trapDepth') {
				trapDepth = inputItem.value;
			} else if (inputItem.fieldId === 'fog') {
				fog = inputItem.value;
			} else if (inputItem.fieldId === 'solid') {
				solid = inputItem.value;
			}
		});

		if (trapDepth && fog && solid && trapDepth > 0) {
			let waste = +fog + +solid;
			let percentGrease = Math.round((+waste / +trapDepth) * 100);
			setPercentGrease(percentGrease);
		} else {
			setPercentGrease(0);
		}
	};

	useEffect(() => {
		if (!isPreviewMode) {
			return;
		}
		if (
			inputs[0].fieldType === CustomSelect &&
			inputs[0].customSelectOptions &&
			!!inputs[0].customSelectOptions.length
		) {
			let customOptions = inputs[0].customSelectOptions;
			customOptions = customOptions.map((customOption: string) => {
				return {
					label: customOption,
					value: customOption
				};
			});
			setOptions({ ...options, [_.camelCase(name)]: customOptions });
		}
	}, [dataItem, isPreviewMode]);

	const getOptions = () => {
		if (inputs[0].customSelectOptions && inputs[0].customSelectOptions.length) {
			return (options as any)[_.camelCase(name || '')];
		} else {
			return [];
		}
	};

	return (
		<div
			className={className + (isPreviewMode ? ' form-preview-mode' : ' ')}
			onMouseLeave={() => setShowIcons(false)}
			onMouseOver={() => setShowIcons(!isPreviewMode)}
			{...others}
			draggable={draggable}>
			<div
				hidden={!showIcons || isPreviewMode}
				className="custom-form-hover-icons-wrapper"
				onMouseLeave={() => setShowIcons(false)}
				onMouseOver={() => setShowIcons(true)}>
				<div className="icons-sub-wrapper">
					<div
						className={`custom-form-hover-icon custom-icon-drag mt-0 ${
							isDateField ? 'two-icon-view' : ''
						}`}>
						<SvgDrag onMouseDown={() => setDraggable(true)} onMouseUp={() => setDraggable(false)} />
					</div>
					<div
						className={`custom-form-hover-icon custom-icon-properties ${
							isDateField ? 'two-icon-view' : ''
						}`}
						onClick={() => handlePreview(dataItem)}>
						<FontAwesomeIcon id="setting-icon" icon={faCog} className="font-awesome-icon" />
						<span className="font-size-12px-regular pl-2 text-center">
							{localizationService.getLocalizedString('customForm.properties')}
						</span>
					</div>
					<div
						className="custom-form-hover-icon custom-icon-delete"
						onClick={() => handleDelete(dataItem)}
						hidden={isDateField}>
						<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon" />
						<span className="font-size-12px-regular pl-2 text-center">
							{localizationService.getLocalizedString('screen.buttons.delete')}
						</span>
					</div>
				</div>
			</div>
			<div className={'row px-3'}>
				{inputs.map((input: any, index: number) => {
					if (input.fieldId === 'isRepairNeededReason' && isPreviewMode && !inputs[0].value) {
						return <></>;
					}
					switch (input.fieldType) {
						case Text:
						case CustomFormFieldType.Number:
							return (
								<TextInput
									key={index + input.fieldId}
									className={`col-sm form-group text-input-custom-form ${
										input.fieldId === 'fog' && name === PercentGreaseCalculator ? '' : 'px-0'
									}`}
									id={input.fieldId}
									name={input.fieldId}
									label={input.fieldLabel + ` ${defaultUnit ? `(${defaultUnit})` : ''}`}
									value={input.value}
									onChange={e => {
										handleChangeInputValue(e, dataItem);
									}}
									isRequired={input.isRequired ? true : false}
									helpText={input.hintText}
									error={input.error}
									showErrorAndHelp
								/>
							);
						case Date:
							return (
								<DateInput
									key={index + input.fieldId}
									className={`col px-0 form-group`}
									id={input.fieldId}
									name={input.fieldId}
									label={input.fieldLabel}
									value={input.value}
									onChange={e => {
										handleChangeInputValue(e, dataItem);
									}}
									isRequired={input.isRequired ? true : false}
									helpText={input.hintText}
									error={input.error}
									showErrorAndHelp
								/>
							);
						case Checkbox:
							return (
								<SingleCheckbox
									key={index + input.fieldId}
									id={input.fieldId}
									name={input.fieldId}
									className={`form-group ${input.isRequired ? 'required' : ''}`}
									htmlLabel={dataItem.name === Certification ? certStatement : undefined}
									label={dataItem.name !== Certification ? input.fieldLabel : undefined}
									onChange={e => {
										handleChangeInputValue(e, dataItem);
									}}
									fieldLabel={
										dataItem.name === Certification ? <label>{input.fieldLabel}</label> : undefined
									}
									checked={input.value}
									error={input.error}
								/>
							);
						case Signature:
							return (
								<SignatureModal
									key={index}
									id={input.fieldId}
									name={input.fieldId}
									label={input.fieldLabel}
									signature={input.value}
									handleSignatureUpdate={(event, signature) => {
										handleChangeInputValue(event, dataItem, signature);
									}}
									signatureError={input.error}
									isRequired={input.isRequired}
									readOnly={!isPreviewMode}
									hintText={input.hintText}
								/>
							);
						case Lookup:
						case CustomSelect:
							return (
								<SingleSelectDropdown
									key={index + input.fieldId}
									id={input.fieldId}
									name={input.fieldId}
									label={input.fieldLabel}
									className="form-group w-100"
									isRequired={input.isRequired ? true : false}
									selfOrder={input.fieldType === CustomSelect}
									hintText={input.hintText}
									value={input.value}
									options={isPreviewMode ? getOptions() : []}
									onChange={e => {
										handleChangeInputValue(e, dataItem);
									}}
									endpoint={
										isPreviewMode
											? urlService.getAuthorityLookupUrlFromEndPoint(input.endpoint, formType, 0)
											: ''
									}
									converter={isPreviewMode ? input.converter : ''}
									error={input.error}
									resetOptions={!isPreviewMode}
								/>
							);
						case TextArea:
							return (
								<TextAreaInput
									key={index + input.fieldId}
									id={input.fieldId}
									name={input.fieldId}
									label={input.fieldLabel}
									isRequired={input.isRequired ? true : false}
									hintText={input.hintText}
									className="form-group w-100"
									isFullWidth
									rows={3}
									value={input.value}
									onChange={e => {
										handleChangeInputValue(e, dataItem);
									}}
									error={input.error}
								/>
							);
						case AttachmentControl:
							return (
								<div className="col-md-6 col-sm-12 attachment-box" key={index + input.fieldId}>
									<UploadAttachment
										id={input.fieldId}
										name={input.fieldId}
										label={input.fieldLabel}
										helpText={input.hintText}
										hideInput
										handleFileUpload={(e, files) => {
											handleChangeInputValue(e, dataItem, files);
										}}
										files={[input.value]}
										isRequired={input.isRequired}
										error={input.error}
										inputDisabled={!isPreviewMode}
									/>
								</div>
							);
					}
				})}
			</div>
			{name === PercentGreaseCalculator && (
				<p className="mx-1 mb-3">
					{`${localizationService.getLocalizedString('haulerPortal.submitCleaning.percentGrease')}`}
					{!!percentGrease && <strong id="percentGrease">{`${percentGrease}%`}</strong>}
				</p>
			)}
		</div>
	);
};

export const CustomForm: FC<Props> = props => {
	const [showNotification, setShowNotification] = useState(true);

	useEffect(() => {
		setShowNotification(!!!props.isPreviewMode);
	}, [props.isPreviewMode]);

	return (
		<>
			{showNotification && (
				<div className="mx-4">
					<InlineAlertItem
						message={localizationService.getLocalizedString('customForm.editInfo')}
						alertType={AlertMessageType.Info}
						alertContainerId="custom-form-info"
						hasCloseButton
						handleCloseClick={() => {
							setShowNotification(false);
						}}
					/>
				</div>
			)}
			<DragDropBox
				id="submitCleaningFormDropArea"
				data={props.data || []}
				className="used-elements"
				title={props.title || ''}
				handleDragStart={props.handleDragStart}
				handleDrop={props.handleDrop}
				itemRenderer={CustomFormRenderer}
				handleClose={() => {}}
			/>
			<div className="d-flex mr-3 justify-content-end">
				<button
					className="btn ai-action mr-2"
					onClick={() =>
						props.isPreviewMode && props.handleValidateCustomField && props.handleValidateCustomField()
					}>
					{localizationService.getLocalizedString('screen.buttons.submit')}
				</button>
				<button className="btn ai-white">
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</button>
			</div>
		</>
	);
};
