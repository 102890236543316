import React, { useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { FogFacility, TagItem, DropDownOption } from '@rcp/types';
import { ApplicationState, FogFacilityState, AuthorityTagsState, saveFogFacility, loadAuthorityTags } from 'src/redux';
import { urlService, localizationService } from 'src/services';
import { TagDropdown } from './facility-tag-dropdown';
import { CloseSvg } from '../../../../../svg';
import 'src/features/tags/tags.scss';

interface StateProps extends FogFacilityState {
	authorityTags: AuthorityTagsState;
}

interface DispatchProps {
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess: () => void) => void;
	loadAuthorityTags: () => void;
}

type Props = StateProps & DispatchProps;

const FacilityTagsComp: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);
	const [authorityFacilityTags, setAuthorityFacilityTags] = React.useState([] as DropDownOption[]);
	const [facilityTags, setFacilityTags] = React.useState([] as string[]);

	React.useEffect(() => {
		let tags: string[] = props.facility.tags ? props.facility.tags.split(',') : [];
		setFacilityTags(tags);
	}, [props.facility.tags]);

	React.useEffect(() => {
		let authorityTags: DropDownOption[] = [];
		if (!_.isEmpty(props.authorityTags.tags)) {
			let allFogTags = _.filter(props.authorityTags.tags, tag =>
				String.equalCaseInsensitive(tag.regulatoryProgramName, 'FOG')
			);
			if (allFogTags && allFogTags.length > 0) {
				let fogTags = allFogTags[0];
				let items = fogTags.tags.Facility as TagItem[];
				if (items) {
					authorityTags = _.filter(items, i => i.isActive === true).map(a => {
						return {
							label: a.name,
							value: a.name
						};
					});
				}
			}
		}
		setAuthorityFacilityTags(authorityTags);
	}, [props.authorityTags.tags]);

	const onAddClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsToggle(true);
		}
	};
	const toggle = () => {
		setIsToggle(!isToggle);
	};
	const onEditClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsToggle(true);
		}
	};

	const saveTags = () => {
		let facilityToUpdate: FogFacility = {
			tags: facilityTags.join(',')
		};
		props.saveFogFacility(urlService.getFogFacilityId(), facilityToUpdate, () => {
			props.loadAuthorityTags();
		});
		toggle();
	};

	const cancelEditingTags = () => {
		let tags: string[] = props.facility.tags ? props.facility.tags.split(',') : [];
		setFacilityTags(tags);
		toggle();
	};

	const removeExistingTag = (tag: string) => {
		let tags: string[] = [...facilityTags];
		let filteredTags = tags.filter(t => t !== tag);
		setFacilityTags(filteredTags);
	};

	const addNewTag = (newTag: string) => {
		let tags: string[] = [...facilityTags];
		tags.push(newTag);
		setFacilityTags(tags);
	};

	const hasFacilityTags = (): boolean => facilityTags.length > 0;
	const tagOptions = _.filter(authorityFacilityTags, authorityTag => !_.includes(facilityTags, authorityTag.value));

	return (
		<section>
			<div id="facilitiesTags" className="d-flex">
				<h3>{localizationService.getLocalizedString('screen.labels.tags')}</h3>
				{hasFacilityTags() && !isToggle && (
					<a href="#/" className="font-size-16px ai-edit-link ml-auto mt-n1" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				)}
			</div>
			{isToggle ? (
				<div>
					<div className={`ai-tags ${hasFacilityTags() ? ' py-3' : null}`}>
						{facilityTags.map((tag: string, index: number) => {
							return (
								<div className="ai-tag align-items-center" key={'ai-tag-' + index}>
									<FontAwesomeIcon icon={faTag} className="tag-icon" />
									<span className="mx-1">{tag}</span>
									<span className="close-icon" onClick={(e: any) => removeExistingTag(tag)}>
										<CloseSvg size="8" />
									</span>
								</div>
							);
						})}
					</div>
					{tagOptions.length > 0 ? (
						<TagDropdown
							name="newTag"
							label="Add existing tags"
							className="w-100 mb-3"
							onChange={addNewTag}
							options={tagOptions}
						/>
					) : (
						//</div>
						<div className="mb-3">
							{localizationService.getLocalizedString('screen.labels.allTagsAdded')}
						</div>
					)}
					<div className="d-flex justify-content-end">
						<Button color="btn ai-action" onClick={saveTags}>
							{localizationService.getLocalizedString('screen.buttons.apply')}
						</Button>
						<Button className="ml-2" color="btn ai-white" onClick={cancelEditingTags}>
							{localizationService.getLocalizedString('screen.buttons.cancel')}
						</Button>
					</div>
				</div>
			) : (
				<div>
					<div className={`ai-tags ${hasFacilityTags() ? ' py-3' : null}`}>
						{facilityTags.map((tag: string, index: number) => {
							return (
								<div className="ai-tag" key={'ai-tag-' + index}>
									<FontAwesomeIcon icon={faTag} className="tag-icon" />
									<span className="mx-1">{tag}</span>
								</div>
							);
						})}
					</div>
					{!hasFacilityTags() && (
						<a href="#/" className="ai-add-link" onClick={onAddClicked}>
							{localizationService.getLocalizedString('screen.buttons.addTag')}&#8230;
						</a>
					)}
				</div>
			)}
		</section>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, authorityTags: state.authorityTags };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => ({
	saveFogFacility: (facilityId: number, facilityToUpdate: FogFacility, callbackOnSuccess?: () => void) =>
		dispatch(saveFogFacility(facilityId, facilityToUpdate, undefined, callbackOnSuccess)),
	loadAuthorityTags: () => dispatch(loadAuthorityTags())
});

export const FacilityTags = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityTagsComp);
