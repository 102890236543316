import React from 'react';
import { SendEmailDropDownOption } from '@rcp/types';
import {
	MultiSelect,
	ListItemProps,
	MultiSelectBlurEvent,
	MultiSelectFilterChangeEvent
} from '@progress/kendo-react-dropdowns';
import { localizationService, UtilService, validationService } from 'src/services';
import { nanoid } from '@reduxjs/toolkit';

interface Props {
	name: string;
	label: string;
	isRequired?: boolean;
	className?: string;
	error?: string;
	onChange?(e: any, type?: string): void;
	options: SendEmailDropDownOption[];
	allowCustom?: boolean;
	handleError?: (error: string) => void;
}

export const SendEmailMultiSelectDropdown: React.SFC<Props> = props => {
	const [selectedValues, setSelectedValues] = React.useState([] as any[]);
	const [data, setData] = React.useState([] as any[]);
	const [validationMessage, setValidationMessage] = React.useState('');
	const [multiSelectInputValue, setMultiSelectInputValue] = React.useState('');

	React.useEffect(() => {
		if (props.options && props.options.length > 0) {
			let data = props.options;
			let newFromState = data.filter((item: SendEmailDropDownOption) => item.selected === true);
			setSelectedValues(newFromState);
			setData(data);
		}
	}, [props.options]);

	const isEmail = (email: any) => {
		return validationService.isEmailFormatValid(email);
	};

	const isInList = (item: string) => {
		const valueIndex = selectedValues.findIndex(value => {
			return String.equalCaseInsensitive(value.email, item);
		});

		return valueIndex !== -1;
	};

	const isCustom = (item: any) => {
		const valueIndex = props.options.findIndex(option => {
			return String.equalCaseInsensitive(option.email, item.email);
		});
		return valueIndex === -1;
	};

	const updateDropdownSelectionChanges = (selectedDataValues: any[]) => {
		let newSelectedData = data
			.filter(d => selectedDataValues.includes(d.value))
			.map(value => ({ ...value, selected: true }));
		let newNonSelectedData = data
			.filter(d => !selectedDataValues.includes(d.value))
			.map(value => ({ ...value, selected: false }));
		setData([...newSelectedData, ...newNonSelectedData]);
	};

	const isValid = (lastItem: any, fromDropDown: boolean) => {
		let error: string;
		if (lastItem && !isEmail(lastItem.email)) {
			error = localizationService.getLocalizedString('sendEmail.invalidErrorMessage', lastItem.email);
		} else if (lastItem && !fromDropDown && isInList(lastItem.email)) {
			error = localizationService.getLocalizedString('sendEmail.inUseErrorMessage', lastItem.email);
		} else {
			return true;
		}
		setValidationMessage(error);
		props.handleError && props.handleError(error);
		return false;
	};

	const handleChange = (event: any) => {
		const values = event.target.value;
		const lastItem = values[values.length - 1];
		let fromDropDown: boolean = true;
		const ccRecipientIdentifier = (lastItem && lastItem.email) || (lastItem && lastItem.name);
		if (!values.includes(undefined)) {
			if (lastItem && lastItem['value'] === undefined) {
				Object.assign(lastItem, {
					email: ccRecipientIdentifier,
					name: lastItem.name || ccRecipientIdentifier,
					value: UtilService.toJson({
						name: '',
						address: ccRecipientIdentifier
					})
				});
				fromDropDown = false;
			}
			if (isValid(lastItem, fromDropDown)) {
				if (lastItem && isCustom(lastItem)) {
					values.pop();
					const sameItem = values.find(value => String.equalCaseInsensitive(value.email, lastItem.email));
					if (sameItem === undefined) {
						values.push(lastItem);
					}
				}
				setSelectedValues(values);
				if (data.length > 0) {
					let selectedDataValues = values.map(value => value.value);
					updateDropdownSelectionChanges(selectedDataValues);
				}
				setValidationMessage('');
				props.handleError && props.handleError('');
				if (props.onChange) {
					props.onChange(event, props.name);
				}
				setMultiSelectInputValue('');
			}
		}
	};

	const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		let item: SendEmailDropDownOption = itemProps.dataItem;
		const itemChildren = (
			<div className="custom-control custom-checkbox" key={nanoid()}>
				<input type="checkbox" className="custom-control-input" checked={item.selected} readOnly />
				<div className="form-row custom-control-label">
					<span className="font-size-16px-medium">{item.name}</span>
					<span className="text-muted ml-2"> {item.group} </span>
				</div>
				<div className="form-row">
					<span className="font-size-14px-regular">{item.email}</span>
				</div>
			</div>
		);

		return React.cloneElement(li, li.props, itemChildren);
	};

	const handleBlur = (event: MultiSelectBlurEvent) => {
		if (multiSelectInputValue) {
			const evtObject = {
				target: {
					name: multiSelectInputValue,
					value: [...selectedValues, { email: multiSelectInputValue, name: multiSelectInputValue }]
				}
			};
			multiSelectInputValue && handleChange(evtObject);
		} else {
			setValidationMessage('');
			props.handleError && props.handleError('');
		}
	};

	const handleFilterChange = (evt: MultiSelectFilterChangeEvent) => {
		setMultiSelectInputValue(evt.filter.value);
	};

	return (
		<div
			className={
				'form-group ' + (props.className || 'form-group-width-2col') + (props.isRequired ? ' required' : '')
			}>
			<label htmlFor={props.name}>{props.label}</label>

			<MultiSelect
				name={props.name}
				data={data}
				itemRender={itemRender}
				value={selectedValues}
				onChange={handleChange}
				textField="name"
				dataItemKey="value"
				autoClose={false}
				className={'kendo-control' + (props.error ? ' is-invalid' : '')}
				allowCustom={props.allowCustom}
				onBlur={handleBlur}
				filterable={true}
				onFilterChange={handleFilterChange}
				validationMessage={validationMessage}
				filter={multiSelectInputValue}
			/>
			{props.error && <div className="invalid-feedback">{props.error}</div>}
		</div>
	);
};
