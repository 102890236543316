import React from 'react';
import { localizationService, urlService, Resource, navigateTo } from 'src/services';
import { CccHazard } from '@rcp/types';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useHistory } from 'react-router';

interface OwnProps {
	currentCccHazard: CccHazard;
}

type Props = OwnProps;

export const CccHazardAddressInfo: React.SFC<Props> = props => {
	const [currentCccHazard] = React.useState(props.currentCccHazard);
	const history = useHistory();

	let address = currentCccHazard.siteAddressLine1;
	if (currentCccHazard.siteAddressLine2) {
		address = `${currentCccHazard.siteAddressLine1}, ${currentCccHazard.siteAddressLine2}`;
	}
	let addressEle;
	if (address) {
		addressEle = (
			<p>
				{address} <br /> {currentCccHazard.siteCityName ? currentCccHazard.siteCityName + ' ,' : null}{' '}
				{currentCccHazard.siteState} {currentCccHazard.siteZipCode}
			</p>
		);
	} else {
		addressEle = (
			<p>
				{currentCccHazard.siteCityName}, {currentCccHazard.siteState} {currentCccHazard.siteZipCode}
			</p>
		);
	}
	return (
		<section className="address-card">
			<h2>{localizationService.getLocalizedString('screen.labels.site')}</h2>
			<div className="row">
				<div className="col-sm-6 mb-3">
					<div className="d-flex">
						{address ||
						currentCccHazard.siteCityName ||
						currentCccHazard.siteState ||
						currentCccHazard.siteZipCode ? (
							<div className="map-div d-flex flex-row">
								<div className="map-marker pr-2">
									<FaMapMarkerAlt />
								</div>
								<p>{addressEle}</p>
							</div>
						) : (
							<div>{localizationService.getLocalizedString('screen.labels.noAddressYet')}</div>
						)}
					</div>
				</div>
				<div className="col-sm-6">
					<table>
						<tbody>
							<tr>
								<td style={{ width: '120px' }}>
									<strong>{localizationService.getLocalizedString('cccHazard.siteType')}</strong>
								</td>
								<td>{props.currentCccHazard.siteType}</td>
							</tr>
							<tr>
								<td>
									<strong>{localizationService.getLocalizedString('cccHazard.siteUse')}</strong>
								</td>
								<td>{props.currentCccHazard.siteUse}</td>
							</tr>
							<tr>
								<td>
									<strong>{localizationService.getLocalizedString('cccSite.siteNumber')}</strong>
								</td>
								<td className="break-word">
									<a
										href={urlService.getReactAuthorityResourceUrl(
											Resource.CccSites,
											props.currentCccHazard.siteId
										)}
										className="ai-link"
										onClick={(e: any) =>
											navigateTo(
												history,
												urlService.getReactAuthorityResourceUrl(
													Resource.CccSites,
													props.currentCccHazard.siteId
												),
												e
											)
										}>
										{props.currentCccHazard.siteNumber}
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</section>
	);
};
