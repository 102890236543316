import React from 'react';
import _ from 'lodash';
import { SimpleUserProfile } from '@rcp/types';

interface Props {
	userProfile?: SimpleUserProfile;
	width?: number;
}

interface State {
	fullName: string;
	initialName: string;
	bgColor: string;
	fontColor: string;
}

//Check XD design for below colors: https://xd.adobe.com/view/ce2aad7f-0256-484a-a8e0-8d231030525a-9b79/specs/
const avatarBackgroundColors = [
	'#990600', //AA
	'#0AA0A0', //BB
	'#015B8A', //CC
	'#A614AD', //DD
	'#520408', //EE
	'#C5512B', //FF
	'#198038', //GG
	'#B71863', //HH
	'#B28600', //II
	'#9A3F29', //JJ
	'#4F0193', //KK
	'#16405B', //LL
	'#01676B', //MM
	'#581845', //NN
	'#6B48FF', //OO
	'#072872', //PP
	'#5C715E', //QQ
	'#6A6979', //RR
	'#2E383F', //SS
	'#34699A', //TT
	'#925A5F', //UU
	'#21003E', //VV
	'#0F5A3C', //WW
	'#31015A', //XX
	'#624A03', //YY
	'#771515' //ZZ
];

const avatarFontColor = '#FFFFFF';

export const UserAvatar: React.FC<Props> = props => {
	const defaultState: State = {
		fullName: '',
		initialName: '',
		bgColor: '#FFFFFF',
		fontColor: avatarFontColor
	};
	const [state, setState] = React.useState(defaultState);

	const getInitialName = (fullName: string): string => {
		let initialName = fullName
			.split(' ')
			.map(n => (n.length > 0 ? n[0] : ''))
			.join('')
			.toUpperCase();
		if (initialName.length > 2) {
			initialName = initialName.charAt(0) + initialName.charAt(initialName.length - 1);
		}
		return initialName;
	};

	const getAvatarBackgroundColors = (name: string): string => {
		let firstChar = name[0];
		let randomColorIndex = firstChar.charCodeAt(0) - 'A'.charCodeAt(0);
		return avatarBackgroundColors[randomColorIndex % avatarBackgroundColors.length];
	};

	React.useEffect(() => {
		if (props.userProfile) {
			let fullName = _.trim((props.userProfile.firstName || '') + ' ' + (props.userProfile.lastName || ''));
			if (_.isEmpty(fullName)) {
				setState(defaultState);
				return;
			}
			let initialName = getInitialName(fullName);
			let color = getAvatarBackgroundColors(initialName);
			let newState = {
				fullName: fullName,
				initialName: initialName,
				bgColor: color,
				fontColor: avatarFontColor
			};
			setState(newState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.userProfile]);

	let height = props.width || 35;
	const radius = height / 2;
	if (!props.userProfile) {
		return (
			<img
				id="userImage"
				alt="UserImage"
				height={height}
				src="/img/icons/user.svg"
				className="d-block"
				title="Anonymous"
			/>
		);
	}
	return (
		<svg className="avatar" xmlns="http://www.w3.org/2000/svg" pointerEvents="none" width={height} height={height}>
			<circle cx={radius} cy={radius} r={radius} fill={state.bgColor} />
			<text
				style={{ fill: state.fontColor }}
				className="font-size-14px-semibold"
				alignmentBaseline="middle"
				textAnchor="middle"
				y="50%"
				x="50%"
				pointerEvents="auto">
				{state.initialName}
			</text>
		</svg>
	);
};
