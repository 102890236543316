import { InvitationDetails, Jurisdiction, MfaQuestion } from '@rcp/types';

export interface RegisterState {
	invitationDetails: InvitationDetails;
	questions: MfaQuestion[],
	states: Jurisdiction[]
}
export const initialRegisterState: RegisterState = {
	invitationDetails: {},
	questions: [],
	states: []
};
