import React from 'react';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { ListSetting, SettingAction } from 'src/components/widgets';
import { LookupType } from '@rcp/types';
import { ViolationTypeLookRowDataConverter } from '../../../../widgets/lookup/data-converters/violation-type-data-converters';
import { EnforcementTypeLookRowDataConverter } from '../../../../widgets/lookup/data-converters/enforcement-type-data-converters';
import { CorrectiveActionLookRowDataConverter } from '../../../../widgets/lookup/data-converters/corrective-action-data-converter';
import { localizationService } from 'src/services';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

const ComplianceSettingsComponent: React.FunctionComponent = () => {
	return (
		<div className="page">
			<>
				<div className="page-header">
					<BackArrow />
					<h1>{localizationService.getLocalizedString('authoritySetting.compliance')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main settings">
						<ListSetting
							title={localizationService.getLocalizedString('enforcement.correctiveAction')}
							settingId="correctiveActionTypes"
							description={localizationService.getLocalizedString(
								'enforcement.correctiveActionDescription'
							)}
							settingLabel={localizationService.getLocalizedString('enforcement.correctiveAction')}
							settingAction={
								new SettingAction(
									LookupType.CorrectiveActions,
									undefined,
									new CorrectiveActionLookRowDataConverter(LookupType.CorrectiveActions)
								)
							}
						/>
						<hr />
						<ListSetting
							title={localizationService.getLocalizedString('enforcement.enforcementType')}
							settingId="enforcementTypes"
							description={localizationService.getLocalizedString('enforcement.enforcementDescription')}
							settingLabel={localizationService.getLocalizedString('enforcement.enforcementType')}
							editDescription={true}
							settingAction={
								new SettingAction(
									LookupType.EnforcementTypes,
									undefined,
									new EnforcementTypeLookRowDataConverter(LookupType.EnforcementTypes)
								)
							}
						/>
						<hr />
						<ListSetting
							title={localizationService.getLocalizedString('violation.violationType')}
							settingId="violationType"
							description={localizationService.getLocalizedString('violation.violationTypeDescription')}
							settingLabel={localizationService.getLocalizedString('violation.violationType')}
							editDescription={true}
							settingAction={
								new SettingAction(
									LookupType.ViolationTypes,
									undefined,
									new ViolationTypeLookRowDataConverter(LookupType.ViolationTypes)
								)
							}
						/>
					</div>
				</div>
			</>
		</div>
	);
};

export const ComplianceSettingFacility = ComplianceSettingsComponent;
