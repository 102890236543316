import * as React from 'react';
import { BulkUpdateSteps } from '@rcp/types';
import { useDispatch, useSelector } from 'react-redux';
import {
	BulkUpdateFields,
	setFacilityFrequencyFieldsList,
	selectableItem,
	setDeviceTypeFieldsList,
	BulkUpdateFieldList,
	setTrapDepthUnitList,
	setTrapCapacityUnitList
} from './bulk-update-service';

import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { loadExtractorTypeList, loadFrequencyList, loadUnitList, RootState, useReduxSelector } from 'src/redux';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';
import { authoritySettingService, localizationService } from 'src/services';
import { setComplianceStatusFieldList } from '../../../facilities/facility-details/bulk-update/steps/bulk-update-service';
import { SingleCheckbox } from 'src/components';

interface Props {
	vertical?: boolean;
}

const initialBulkUpdateFields: BulkUpdateFields = {
	customFields: [],
	fogDeviceFields: [...BulkUpdateFieldList]
};

const ChooseFieldsStep: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [bulkUpdateFields, setBulkUpdateFields] = React.useState<BulkUpdateFields>({ ...initialBulkUpdateFields });

	const dispatch = useDispatch();
	const authorityFrequencies = useReduxSelector(state => state.extractors.frequencyList);
	let extractorTypes = useSelector((state: RootState) => state.extractors.extractorTypeList);
	let units = useSelector((state: RootState) => state.extractors.unitList);

	React.useEffect(() => {
		let step1Data: BulkUpdateFields = stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
		if (step1Data == null || step1Data.fogDeviceFields.length < 0) {
			dispatch(loadFrequencyList());
			dispatch(loadUnitList());
			dispatch(loadExtractorTypeList());
			dispatch(authorityUsersSlice.fetchAll());
			authoritySettingService.getAuthorityComplianceStatus().then((complianceStatusList: string[]) => {
				setComplianceStatusFieldList(complianceStatusList, setBulkUpdateFields, bulkUpdateFields);
			});
			step1Data = { ...initialBulkUpdateFields };
			step1Data.fogDeviceFields.forEach(x => (x.chosen = false));
			step1Data.fogDeviceFields.forEach(x => (x.value = null));
		}
		setBulkUpdateFields({ ...step1Data });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	React.useEffect(() => {
		if (authorityFrequencies.length > 0) {
			setFacilityFrequencyFieldsList(
				authorityFrequencies.filter(i => i.isActive === true),
				setBulkUpdateFields,
				bulkUpdateFields
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityFrequencies]);

	React.useEffect(() => {
		if (extractorTypes && extractorTypes.length > 0) {
			setDeviceTypeFieldsList(extractorTypes, setBulkUpdateFields, bulkUpdateFields);
		}
	}, [extractorTypes]);

	React.useEffect(() => {
		if (units && units.length > 0) {
			setTrapDepthUnitList(units, setBulkUpdateFields, bulkUpdateFields);
			setTrapCapacityUnitList(units, setBulkUpdateFields, bulkUpdateFields);
		}
	}, [units]);

	React.useEffect(() => {
		const step1Data: BulkUpdateFields = stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
		if (step1Data) {
			setBulkUpdateFields(step1Data);
		} else {
			const step1Step = stepperContext.getStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
			if (step1Step) {
				step1Step.data = JSON.parse(JSON.stringify(initialBulkUpdateFields));
				stepperContext.updateStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS, step1Data);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	const onClickNext = () => {
		if (canGoNextStep() === false) return;
		stepperContext.resolve(bulkUpdateFields);
	};

	const fieldChosenChanged = (field: selectableItem) => {
		field.chosen = !field.chosen;
		let newValues = { ...bulkUpdateFields };
		setBulkUpdateFields(newValues);
	};

	const getFieldsDiv = (fields: selectableItem[], key: string) => {
		return fields.map(field => {
			let keyStr = `${key}-${field.fieldName}`;
			return (
				<div className="form-row" key={keyStr}>
					<SingleCheckbox
						id={keyStr}
						name={keyStr}
						label={field.title}
						checked={field.chosen}
						onChange={() => fieldChosenChanged(field)}
					/>
				</div>
			);
		});
	};

	const canGoNextStep = (): boolean => {
		let chosenfogDeviceFields = bulkUpdateFields.fogDeviceFields.find(i => i.chosen === true);
		if (chosenfogDeviceFields !== undefined) {
			return true;
		}
		let chosenCustomFields = bulkUpdateFields.customFields.find(i => i.chosen === true);
		if (chosenCustomFields !== undefined) {
			return true;
		}
		return false;
	};

	return (
		<StepperContent
			id="step3ChooseCustomFields"
			className=" full-width"
			actions={
				<React.Fragment>
					<StepperAction
						type="button"
						id="btnNext"
						className="btn btn-link"
						onClick={onClickNext}
						disabled={stepperContext.isLoading()}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-group">
				<div className="d-flex flex-row">
					<div className="flex-fill">
						<label className="choose-field-head mb-2">
							{localizationService.getLocalizedString('bulkUpdate.deviceFieldHead')}
						</label>
						{getFieldsDiv(bulkUpdateFields.fogDeviceFields, 'DeviceFieldHead')}
					</div>
				</div>
			</div>
		</StepperContent>
	);
};

export default ChooseFieldsStep;
