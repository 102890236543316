import React from 'react';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { IppUnitTranslations } from '@rcp/types';
import { PopoverModal, SingleCheckbox } from 'src/components/widgets';
import { localizationService } from 'src/services';

interface Props {
	showModal: boolean;
	modalTitle: string;
	templateData: IppUnitTranslations;
	editRowId: number | undefined;
	systemUnits: string[];
	onSave: (e: React.MouseEvent<HTMLElement>) => void;
	onCancel: (e: React.MouseEvent<HTMLElement>) => void;
	onFilterChange: (event: ComboBoxFilterChangeEvent) => void;
	onFieldChange: (fieldName: string) => (e: any) => void;
}

function UnitTranslationsEditModal(props: Props) {
	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					save={props.onSave}
					cancel={props.onCancel}>
					<div className="flex-column mb-2 combobox-container">
						<label className="mr-2">
							{localizationService.getLocalizedString(
								'ipp.unitTranslations.editModalFields.systemUnit.label'
							)}
						</label>
						<ComboBox
							data={props.systemUnits}
							value={props.templateData.systemUnitName}
							allowCustom={false}
							filterable={true}
							className={'mb-2'}
							onChange={props.onFieldChange('systemUnitName')}
							onFilterChange={props.onFilterChange}
						/>
					</div>
					<div className="form-row">
						<SingleCheckbox
							id="is-available-to-industry"
							name="is-available-to-industry"
							checked={props.templateData.isAvailableToRegulatee}
							labelClass={'checkbox-label'}
							label={localizationService.getLocalizedString(
								'ipp.unitTranslations.editModalFields.isAvailableToIndustry.label'
							)}
							onChange={props.onFieldChange('isAvailableToRegulatee')}
						/>
					</div>
					<div className="form-row">
						<SingleCheckbox
							id="show-in-badge-count"
							name="show-in-badge-count"
							checked={props.templateData.showInBadgeCount}
							labelClass={'checkbox-label'}
							label={localizationService.getLocalizedString(
								'ipp.unitTranslations.editModalFields.showInBadgeCount.label'
							)}
							onChange={props.onFieldChange('showInBadgeCount')}
						/>
					</div>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
}

export default UnitTranslationsEditModal;
