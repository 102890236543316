import {
	AddNoticeSteps,
	CleaningNoticeTemplateSchedule,
	DropDownOption,
	ViolationType,
	scheduleDueDateTimeOptions,
	dueDateOptions
} from '@rcp/types';
import React from 'react';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import _ from 'lodash';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import { alertService, RootState } from 'src/redux';
import { useSelector } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';
import { SingleCheckbox, SingleSelectDropdown } from 'src/components/widgets';

export enum DueDateTimeUnit {
	WEEK = 'WEEK',
	DAY = 'DAY',
	MONTH = 'MONTH',
	YEAR = 'YEAR'
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { DAY, WEEK, MONTH, YEAR } = DueDateTimeUnit;

interface FormFields extends CleaningNoticeTemplateSchedule {
	violationTypeError?: string;
}

const initialDeliveryOptions: FormFields = {
	isAddedToOutboxOnScheduledDay: false,
	violation: false,
	isScheduledAutomatically: true,
	scheduleDueDateTimeUnitNumber: 0,
	scheduleDueDateTimeUnitName: DAY,
	isBeforeDueDate: true,
	numberOfDaysAfterPreviousNotice: 0
};

export const Step4 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [deliveryOptions, setDeliveryOptions] = React.useState(initialDeliveryOptions);
	const [violationTypes, setViolationTypes] = React.useState<DropDownOption[]>([]);

	let cleaningNoticeTemplateSchedule = (state: RootState) => state.cleaningNoticeTemplate;
	let { selected: cleaningNoticeTemplate } = useSelector(cleaningNoticeTemplateSchedule);

	const onClickBack = () => stepperContext.goAt(AddNoticeSteps.STEP3);

	React.useEffect(() => {
		getViolationTypes();
		const step4Data = stepperContext.getData(AddNoticeSteps.STEP4);
		if (step4Data) {
			setDeliveryOptions({ ...step4Data.deliveryOptions, violationTypeError: '' });
		} else if (cleaningNoticeTemplate) {
			const {
				isAddedToOutboxOnScheduledDay,
				isScheduledAutomatically,
				scheduleDueDateTimeUnitNumber,
				scheduleDueDateTimeUnitName,
				isBeforeDueDate,
				numberOfDaysAfterPreviousNotice,
				violationTypeId
			} = cleaningNoticeTemplate;
			setDeliveryOptions({
				isAddedToOutboxOnScheduledDay,
				isScheduledAutomatically,
				scheduleDueDateTimeUnitNumber,
				scheduleDueDateTimeUnitName,
				isBeforeDueDate,
				numberOfDaysAfterPreviousNotice,
				violationTypeId,
				violation: !!violationTypeId
			});
		}
	}, [cleaningNoticeTemplate]);

	const getViolationTypes = () => {
		let url = urlService.getAuthorityResourcesApiUrl(`${Resource.SettingLookup}/${Resource.ViolationTypes}`);
		apiService
			.getResource<ViolationType[]>(url)
			.then(data => {
				let options: DropDownOption[] = data
					.filter(i => i.isActive)
					.map((violationType: ViolationType) => {
						return { label: violationType.name, value: violationType.violationTypeId };
					});

				setViolationTypes(options);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onClickNext = () => {
		if (isFormValidateForNext()) {
			stepperContext.resolve({ deliveryOptions });
		}
	};

	const changeDeliveryOption = (e: any) => {
		let newState = { ...deliveryOptions };
		let { name, value, type } = e.target;
		if (String.equalCaseInsensitive(name, 'isScheduledAutomatically')) {
			!e.target.checked && (newState = { ...initialDeliveryOptions });
		}
		if (String.equalCaseInsensitive(name, 'violation')) {
			!e.target.checked && (newState.violationTypeId = undefined);
		}
		if (String.equalCaseInsensitive(e.target.type, 'checkbox')) {
			value = e.target.checked;
		}
		if (String.equalCaseInsensitive(type, 'number') && value < 0) {
			return;
		}
		_.set(newState, name, value);
		setDeliveryOptions(newState);
	};

	const isFormValidateForNext = (): boolean => {
		let newState = { ...deliveryOptions };
		if (!newState.isScheduledAutomatically) {
			return true;
		}

		deliveryOptions.violation && deliveryOptions.isBeforeDueDate + '' === 'false'
			? validationService.validateRequiredField(
					newState,
					'violationTypeId',
					'violationTypeError',
					localizationService.getLocalizedString('authoritySetting.noticesSettings.violationType')
			  )
			: _.set(newState, 'violationTypeError', '');

		const isFromValid = !validationService.hasError(newState, 'violationTypeError');
		setDeliveryOptions(newState);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	return (
		<StepperContent
			id="schedule-delivery-option"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnBack" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div>
				<SingleCheckbox
					id="schedule-automatically"
					label={localizationService.getLocalizedString(
						'authoritySetting.noticesSettings.scheduleNoticeAutomatically'
					)}
					className="mb-0"
					name="isScheduledAutomatically"
					checked={deliveryOptions.isScheduledAutomatically}
					onChange={changeDeliveryOption}
				/>
				{!deliveryOptions.isScheduledAutomatically && (
					<Notification
						className="d-inline-block notification px-2"
						message={localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.noticeScheduleWarning'
						)}
						showCloseButton={false}
					/>
				)}
				{deliveryOptions.isScheduledAutomatically && (
					<div className="form-inline">
						<input
							id="scheduleDueDateTimeUnitNumber"
							name="scheduleDueDateTimeUnitNumber"
							type="number"
							className={'form-control value col-1 inline-input mr-2'}
							value={deliveryOptions!.scheduleDueDateTimeUnitNumber}
							onChange={changeDeliveryOption}
							autoComplete={'off'}
						/>

						<SingleSelectDropdown
							id="scheduleDueDateTimeUnitName"
							name="scheduleDueDateTimeUnitName"
							className="mr-2"
							data-piggyback=""
							noEmptyOption={true}
							selfOrder={false}
							value={deliveryOptions!.scheduleDueDateTimeUnitName as string}
							onChange={changeDeliveryOption}
							options={scheduleDueDateTimeOptions}
						/>
						<SingleSelectDropdown
							id="isBeforeDueDate"
							name="isBeforeDueDate"
							noEmptyOption={true}
							selfOrder={true}
							value={deliveryOptions!.isBeforeDueDate + ''}
							onChange={changeDeliveryOption}
							options={dueDateOptions}
							label={''}
						/>

						<span className="m-2">
							{localizationService.getLocalizedString('authoritySetting.notice.schedule_1')}
						</span>

						<input
							id="numberOfDaysAfterPreviousNotice"
							name="numberOfDaysAfterPreviousNotice"
							data-piggyback=""
							type="number"
							className={'form-control col-1 value inline-input'}
							placeholder={''}
							required={true}
							autoComplete={'off'}
							onChange={changeDeliveryOption}
							value={deliveryOptions!.numberOfDaysAfterPreviousNotice}
						/>

						<span className="m-2">
							{localizationService.getLocalizedString('authoritySetting.notice.schedule_2')}
						</span>
					</div>
				)}
			</div>
			{deliveryOptions.isScheduledAutomatically && (
				<div className="form-group required mt-3">
					<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.delivery')}</label>
					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input z-1"
							name="isAddedToOutboxOnScheduledDay"
							id="delivery-opt-1"
							value="false"
							checked={deliveryOptions.isAddedToOutboxOnScheduledDay + '' === 'false'}
							onChange={changeDeliveryOption}
						/>
						<label className="custom-control-label" htmlFor="delivery-opt-1">
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.deliveryOpt1')}
						</label>
					</div>

					<div className="custom-control custom-radio">
						<input
							type="radio"
							className="custom-control-input z-1"
							name="isAddedToOutboxOnScheduledDay"
							id="delivery-opt-2"
							value="true"
							checked={deliveryOptions.isAddedToOutboxOnScheduledDay + '' === 'true'}
							onChange={changeDeliveryOption}
						/>
						<label className="custom-control-label" htmlFor="delivery-opt-2">
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.deliveryOpt2')}
						</label>
					</div>
				</div>
			)}
			{deliveryOptions.isBeforeDueDate + '' === 'false' && deliveryOptions.isScheduledAutomatically && (
				<>
					<label>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.violation')}
					</label>
					<SingleCheckbox
						id="violation"
						label={localizationService.getLocalizedString(
							'authoritySetting.noticesSettings.violationCheckDesc'
						)}
						name="violation"
						onChange={changeDeliveryOption}
						checked={deliveryOptions.violation}
					/>
				</>
			)}
			{deliveryOptions.isBeforeDueDate + '' === 'false' && deliveryOptions.violation && (
				<SingleSelectDropdown
					label={localizationService.getLocalizedString('authoritySetting.noticesSettings.violationType')}
					id="violationTypeId"
					name="violationTypeId"
					className="violation-id form-group"
					isRequired={true}
					options={violationTypes}
					onChange={changeDeliveryOption}
					error={deliveryOptions.violationTypeError || ''}
					value={_.toString(deliveryOptions.violationTypeId)}
				/>
			)}
		</StepperContent>
	);
};
