import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import * as Facilities from './facilities-actions';
import * as ApiTypes from '@rcp/types';
import { apiService, Resource, urlService, localizationService, UtilService, DateUtilService } from 'src/services';
import { FogFacilitiesState } from './facilities-state';
import { alertService } from '../alert';
import { InspectionEvent, CustomFieldDataType } from '@rcp/types';
import { ICustomFieldItem, Dictionary, CustomFieldAware } from '@rcp/types';
import {
	IFacilityItem,
	FieldTypeEnum
} from '../../components/authority/fog/facilities/facility-details/facility-types';

type FogFacilitiesThunkAction = ThunkAction<any, FogFacilitiesState, any, Facilities.FogFacilitiesAction>;

export const loadFogFacilities = (querystring: string): FogFacilitiesThunkAction => async dispatch => {
	dispatch(Facilities.loadFogFacilitiesRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities, querystring);
	let facilities = await apiService.getPaginatedResources<ApiTypes.FogFacility>(url);

	_.each(facilities.result, (item: ApiTypes.FogFacility) => {
		item.isSelectedInView = false;
		var translationKey = item.hasPrimaryContact === true ? 'constants.yes' : 'constants.no';
		item.hasPrimaryContactDisplayValue = localizationService.getLocalizedString(translationKey);
	});
	dispatch(Facilities.loadFogFacilitiesSuccess(facilities));
};

export const downloadFogFacilities = (querystring: string): FogFacilitiesThunkAction => async dispatch => {
	dispatch(Facilities.downloadFogFacilitiesRequest());

	let url = urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities, querystring);
	await apiService.downloadByUrl(url);

	dispatch(Facilities.downloadFogFacilitiesSuccess());
	alertService.addSuccess(localizationService.getLocalizedString('exportFile.downloadSuccess'));
};

export const batchCreateFacilityInspections = (
	querystring: string,
	batchCreateInspectionRequest: InspectionEvent,
	callbackLoading: (status: boolean) => void,
	onSuccess: () => void
): FogFacilitiesThunkAction => async dispatch => {
	dispatch(Facilities.loadFogFacilitiesRequest());
	let url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.FogFacilities
	}/BatchCreateInspections?${querystring}`;
	callbackLoading(true);
	apiService
		.httpPost(url, batchCreateInspectionRequest)
		.then((createdInspectionCount: number) => {
			callbackLoading(false);
			alertService.addSuccess(
				localizationService.getLocalizedString(
					'alertMessages.xInspectionsScheduled',
					`${createdInspectionCount}`
				)
			);
			onSuccess();
		})
		.catch((e: any) => {
			callbackLoading(false);
			alertService.addError(e.message);
		});
};

let toApiDateTimeString = (dateStrValue: string): string => {
	let dateStr = DateUtilService.toDisplayDate(dateStrValue, 'YYYY-MM-DD');
	let timeStr = '00:00:00';
	return DateUtilService.prepareDateStringForApiCall(`${dateStr}T${timeStr}`);
};

export const batchUpdateFacilities = (customFields: ICustomFieldItem[], facilityFields: IFacilityItem[]) => {
	let queryString = window.location.search;

	let bulkUpdate: CustomFieldAware = {};
	let customFieldsDict: Dictionary<string> = {};

	facilityFields.forEach(item => {
		let key = UtilService.toCamelCase(item.fieldName);
		_.set(bulkUpdate, key, item.value || null);

		if (item.dataType === FieldTypeEnum.Date) {
			let valueStr = toApiDateTimeString(item.value);
			item.value = valueStr;
		}
	});

	let index = facilityFields.findIndex(i => i.fieldName === 'overriddenComplianceStatus');
	if (index > -1) {
		let field = facilityFields[index];
		if (!field.value && field.optionValues && field.optionValues.length > 0) {
			field.value = field.optionValues[0].value;
		}
		const removeOverrideStatus = field.value === 'removeOverrideStatus';
		_.set(bulkUpdate, 'isOverrideComplianceStatus', !removeOverrideStatus);

		if (removeOverrideStatus) {
			_.unset(bulkUpdate, 'overriddenComplianceStatus');
		}
	}

	customFields.forEach(item => {
		customFieldsDict[UtilService.toCamelCase(item.fieldName)] = item.value || null;
		if (item.fieldDataType === CustomFieldDataType.Date) {
			let valueStr = toApiDateTimeString(item.value);
			item.value = valueStr;
		}
	});

	bulkUpdate.customFields = customFieldsDict;
	let url = urlService.getAuthorityBatchUpdateResourcesApiUrl(queryString);
	return apiService.httpPatch(url, bulkUpdate);
};

export const batchUpdateFacilitiesDevices = (customFields: ICustomFieldItem[], deviceFields: IFacilityItem[]) => {
	let queryString = window.location.search;

	let bulkUpdate: CustomFieldAware = {};
	let customFieldsDict: Dictionary<string> = {};

	deviceFields.forEach(item => {
		let key = UtilService.toCamelCase(item.fieldName);
		_.set(bulkUpdate, key, item.value || item.value === false ? item.value : null);

		if (item.dataType === FieldTypeEnum.Date) {
			let valueStr = toApiDateTimeString(item.value);
			item.value = valueStr;
		}
	});

	customFields.forEach(item => {
		customFieldsDict[UtilService.toCamelCase(item.fieldName)] = item.value || null;
		if (item.fieldDataType === CustomFieldDataType.Date) {
			let valueStr = toApiDateTimeString(item.value);
			item.value = valueStr;
		}
	});
	bulkUpdate.customFields = customFieldsDict;
	let url = urlService.getAuthorityBatchUpdateResourcesApiUrl(queryString, Resource.Devices);
	return apiService.httpPatch(url, bulkUpdate);
};
