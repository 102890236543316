import React, { FC, useState } from 'react';
import { History } from 'history';
import { noticeHistorySlice } from './notice-histories.slice';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, LocalStorageName, SentNoticeHistory } from '@rcp/types';
import { RestSlice } from '../../../../../redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { DateUtilService, localizationService } from 'src/services';
import { useDispatch } from 'react-redux';
import { PopoverModal } from 'src/components/widgets';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const NoticeHistories: FC<Props<SentNoticeHistory>> = props => {
	const dispatch = useDispatch();
	const [deleteState, setDeleteState] = useState({
		showDelete: false,
		id: -1,
		name: '',
		sentDateTime: '',
		numberOfLetters: 0
	});
	const downloadLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.SentNoticeHistory;
		const url = dataItem.s3PdfLink;
		const noticeStatus = dataItem.noticeStatus.toLowerCase();

		return String.equalCaseInsensitive(noticeStatus, LinkoTypes.NoticeStatus.Success.toString()) ? (
			<td>
				<a href={url} className="ai-link" target={'_blank'} rel="noopener noreferrer">
					{getFileDetail(dataItem)}
				</a>
			</td>
		) : String.equalCaseInsensitive(noticeStatus, LinkoTypes.NoticeStatus.Sending.toString()) ? (
			<td>{localizationService.getLocalizedString('ccc.noticeHistory.sending')}</td>
		) : (
			<td>{localizationService.getLocalizedString('ccc.noticeHistory.fail')}</td>
		);
	};

	const getFileDetail = (dataItem: SentNoticeHistory) => {
		return localizationService.getLocalizedString(
			'ccc.noticeHistory.fileDetail',
			dataItem.numberOfLetters.toString(),
			dataItem.pdfSize
		);
	};

	const getDeleteModal = () => {
		return (
			<PopoverModal
				title={localizationService.getLocalizedString('ccc.noticeHistory.deleteModalTitle')}
				showModal={deleteState.showDelete}
				cancel={() => onCancelDelete()}
				saveButtonClassName="ai-secondary-delete ml-auto"
				saveButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
				save={() => onDeleteConfirmed()}>
				{localizationService.getLocalizedString(
					'ccc.noticeHistory.deleteModalDesc',
					deleteState.numberOfLetters.toString(),
					DateUtilService.toDisplayDate(deleteState.sentDateTime.toString()),
					DateUtilService.toDisplayTime(deleteState.sentDateTime.toString())
				)}
			</PopoverModal>
		);
	};

	const onDeleteConfirmed = () => {
		dispatch(
			noticeHistorySlice.deleteOne(
				deleteState.id,
				true,
				localizationService.getLocalizedString(
					'ccc.noticeHistory.deletedSucceed',
					deleteState.numberOfLetters.toString(),
					DateUtilService.toDisplayDate(deleteState.sentDateTime.toString()),
					DateUtilService.toDisplayTime(deleteState.sentDateTime.toString())
				)
			)
		);
		onCancelDelete();
	};

	const onCancelDelete = () => {
		setDeleteState({ ...deleteState, showDelete: false, id: -1, name: '' });
	};

	const deleteHistory = (e: any, dataItem: SentNoticeHistory) => {
		e.preventDefault();
		setDeleteState({
			...deleteState,
			showDelete: true,
			id: dataItem.sentNoticeHistoryId,
			name: getFileDetail(dataItem),
			numberOfLetters: dataItem.numberOfLetters,
			sentDateTime: dataItem.sentDateTime
		});
	};

	const deleteLink = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.SentNoticeHistory;
		const noticeStatus = dataItem.noticeStatus.toLowerCase();
		return String.equalCaseInsensitive(noticeStatus, LinkoTypes.NoticeStatus.Sending.toString()) ? (
			<td></td>
		) : (
			<td>
				<a className="ai-link" href="/delete" onClick={(e: any) => deleteHistory(e, dataItem)}>
					{localizationService.getLocalizedString('screen.labels.delete')}
				</a>
			</td>
		);
	};

	const sentDateTimeCell = (item: any) => {
		const dataItem = item.dataItem as LinkoTypes.SentNoticeHistory;
		return (
			<td>
				{DateUtilService.toDisplayDate(
					dataItem.sentDateTime,
					localizationService.getLocalizedString('dateFormats.displayDateTime')
				)}
			</td>
		);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('ccc.noticeHistory.title'),
		prefix: 'ccc.noticeHistory',
		storageName: LocalStorageName.NoticeHistory,

		allColumns: [
			new ColumnField('sentDateTime', FilterType.Date, sentDateTimeCell),
			new ColumnField('sentByUserFullName'),
			new ColumnField('numberOfLetters', FilterType.Text, downloadLink),
			new ColumnField('actions', FilterType.Text, deleteLink, true, false)
		],
		defaultColumns: ['sentDateTime', 'sentByUserFullName', 'numberOfLetters', 'actions']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={noticeHistorySlice}
				restState={(state: RootState) => state.noticeHistories}
				gridOption={initialGridOption}
			/>
			{deleteState.showDelete && getDeleteModal()}
		</>
	);
};

export default NoticeHistories;
