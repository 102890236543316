import React, { useState } from 'react';
import { useRootStateSelector } from 'src/redux';
import {
	apiService,
	localizationService,
	Resource,
	tokenService,
	urlService,
	UtilService,
	navigateToRoute,
	validationService
} from 'src/services';
import {
	ApiError,
	AuthorityOrpSetting,
	NoticeTemplateType,
	SettingValue,
	printMarginUnitOptions,
	PreviewNotice,
	invitationLetterTemplate,
	MergeFieldDto
} from '@rcp/types';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { alertService } from '../../../../../redux/alert';
import { TextInput, SingleSelectDropdown } from 'src/components/widgets';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import { Switch, useHistory, useRouteMatch } from 'react-router';
import { useDispatch } from 'react-redux';

import { mergeFieldSlice } from 'src/components/authority/shared/settings/notices/merge_field.slice';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;

const GetHaulerPortalInvitationSection: React.FC = () => {
	const [emailFormField, setEmailFormField] = useState<PreviewNotice>({});
	const [letterFormField, setLetterFormField] = useState<invitationLetterTemplate>({});

	enum InvitationTypes {
		Email = 'invitation-email',
		Letter = 'invitation-letter'
	}
	const dispatch = useDispatch();
	const mergeFields: MergeFieldDto[] = useRootStateSelector(s => s.mergeFields.result);
	const [letterMergeFieldList, setLetterMergeFieldList] = React.useState<string[]>([]);
	const [emailMergeFieldList, setEmailMergeFieldList] = React.useState<string[]>([]);
	const [letterMergeFields, setLetterMergeFields] = React.useState<MergeFieldDto[]>([]);
	const [activeTab, setActiveTab] = useState(InvitationTypes.Email);
	const history = useHistory();
	let { url } = useRouteMatch();

	React.useEffect(() => {
		getInvitationTemplates();
		getMergeFields(NoticeTemplateType.HaulerPortalInvitationLetter);
		getMergeFields(NoticeTemplateType.HaulerPortalInvitationEmail);
	}, []);

	React.useEffect(() => {
		let isLetter = mergeFields
			.map(field => (field.prefix ? field.prefix.toUpperCase() == 'LETTER' : false))
			.includes(true);
		let mappedFields = mergeFields.map(field =>
			field.prefix ? `${field.prefix} - ${field.label}` : (field.label as string)
		);
		if (isLetter) {
			setLetterMergeFields(mergeFields);
			setLetterMergeFieldList(mappedFields);
			return;
		}
		setEmailMergeFieldList(mappedFields);
	}, [mergeFields]);

	React.useEffect(() => {
		const tab = history.location.pathname.split('/') as string[];
		const urlLength = 4;
		if (tab.length > urlLength) {
			toggle(tab[tab.length - 1] as InvitationTypes);
		} else {
			toggle(InvitationTypes.Email);
		}
	}, [history.location.pathname]);

	const getMergeFields = async (type: NoticeTemplateType) => {
		mergeFieldSlice.setApiUrlPath(Resource.SettingNoticeTemplatesMergeFields + '/' + type);
		dispatch(mergeFieldSlice.fetchAll());
	};

	const toggle = (tab: InvitationTypes) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const getInvitationTemplates = async () => {
		let letterUrl = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.HaulerPortalInvitationLetter}`
		);
		await apiService
			.getResource<invitationLetterTemplate>(letterUrl)
			.then(data => {
				setLetterFormField(data);
			})
			.catch(err => alertService.addError(err.message));

		let emailUrl = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.HaulerPortalInvitationEmail}`
		);

		await apiService
			.getResource<PreviewNotice>(emailUrl)
			.then(data => {
				setEmailFormField(data);
			})
			.catch(err => alertService.addError(err.message));
	};

	const isFormValid = (updatedFormField: any) => {
		if (activeTab == InvitationTypes.Letter) {
			return validationService.validateMergeFields(
				(updatedFormField.templateBodyContent || '') +
					(updatedFormField.templateFooterContent || '') +
					(updatedFormField.templateHeaderContent || ''),
				letterMergeFields
			);
		} else {
			return validationService.validateMergeFields(updatedFormField.templateContent || '', mergeFields);
		}
	};

	const updateInvitationTemplates = async (content: string | Number, key: string) => {
		let updatedFormField;
		if (activeTab == InvitationTypes.Letter) {
			let letterUrl = urlService.getAuthorityResourcesApiUrl(
				`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.HaulerPortalInvitationLetter}`
			);
			updatedFormField = { ...letterFormField, [key]: content };
			isFormValid(updatedFormField) &&
				(await apiService
					.patchResource(letterUrl, updatedFormField)
					.then(data => {
						setLetterFormField(data as invitationLetterTemplate);
					})
					.catch(err => alertService.addError(err.message)));
		} else {
			let emailUrl = urlService.getAuthorityResourcesApiUrl(
				`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.HaulerPortalInvitationEmail}`
			);
			updatedFormField = { ...emailFormField, [key]: content };
			isFormValid(updatedFormField) &&
				(await apiService
					.patchResource(emailUrl, updatedFormField)
					.then(data => setEmailFormField(data as PreviewNotice))
					.catch(err => alertService.addError(err.message)));
		}
	};

	const updateMargin = async (e: any) => {
		let { type, name, value } = e.target;
		if (type === 'number') {
			if (value < 0) return;
			if (value.includes('.')) {
				if (value.split('.')[1].length > 3) {
					return;
				}
			}
		}
		let newState = { ...letterFormField, [name]: value };
		setLetterFormField(newState);
	};
	function getHaulerLetterEditor() {
		return activeTab === InvitationTypes.Letter ? (
			<>
				<div className="add-general-notice-editor-template mb-3">
					<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.header')}</label>
					<RichTextEditor
						customMergeFieldList={letterMergeFieldList}
						editorContent={letterFormField.templateHeaderContent}
						saveTemplateData={(content: string) => {
							updateInvitationTemplates(content, 'templateHeaderContent');
						}}
						height="80px"
						showTableTools={true}
						isFixedHeight
						editorIndex={0}
					/>
				</div>
				<div className="add-general-notice-editor-template form-group required general-notice-body">
					<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.body')}</label>
					<RichTextEditor
						customMergeFieldList={letterMergeFieldList}
						editorContent={letterFormField.templateBodyContent}
						saveTemplateData={(content: string) => {
							updateInvitationTemplates(content, 'templateBodyContent');
						}}
						isLetterTemplate
						height="467px"
						showTableTools={true}
						isFixedHeight
						editorIndex={1}
						hidePageNumber
					/>
				</div>
				<div className="add-general-notice-editor-template mb-3">
					<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.footer')}</label>
					<RichTextEditor
						customMergeFieldList={letterMergeFieldList}
						editorContent={letterFormField.templateFooterContent}
						saveTemplateData={(content: string) => {
							updateInvitationTemplates(content, 'templateFooterContent');
						}}
						height="60px"
						showTableTools={true}
						isFixedHeight
						editorIndex={2}
					/>
				</div>
				<div>
					<p>{localizationService.getLocalizedString('authoritySetting.noticesSettings.printMargins')}</p>
					<div className="form-row">
						<TextInput
							id="marginTop"
							name="marginTop"
							type="number"
							className="col"
							onBlur={(event: any) => updateInvitationTemplates(event.target.value, event.target.name)}
							onChange={updateMargin}
							value={letterFormField.marginTop || '0'}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.top')}
						/>

						<TextInput
							id="marginBottom"
							name="marginBottom"
							type="number"
							className="col"
							value={letterFormField.marginBottom || '0'}
							onBlur={(event: any) => updateInvitationTemplates(event.target.value, event.target.name)}
							onChange={updateMargin}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.bottom')}
						/>

						<TextInput
							id="marginLeft"
							name="marginLeft"
							value={letterFormField.marginLeft || '0'}
							type="number"
							className="col"
							onBlur={(event: any) => updateInvitationTemplates(event.target.value, event.target.name)}
							onChange={updateMargin}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.left')}
						/>

						<TextInput
							id="marginRight"
							name="marginRight"
							value={letterFormField.marginRight || '0'}
							className="col"
							onBlur={(event: any) => updateInvitationTemplates(event.target.value, event.target.name)}
							onChange={updateMargin}
							type="number"
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.right')}
						/>

						<SingleSelectDropdown
							id="printMarginUnit"
							name="printMarginUnit"
							onChange={(event: any) => {
								updateInvitationTemplates(event.target.value, event.target.name);
							}}
							value={letterFormField.printMarginUnit}
							className="col"
							noEmptyOption={true}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.units')}
							options={printMarginUnitOptions}
						/>
					</div>
				</div>
			</>
		) : (
			<></>
		);
	}
	function getHaulerEmailEditor() {
		return activeTab === InvitationTypes.Email ? (
			<>
				<div className="form-row">
					<TextInput
						id="subject"
						name="templateSubject"
						className="col-sm-8 form-group"
						onChange={(event: any) => {
							setEmailFormField({ ...emailFormField, templateSubject: event.target.value });
						}}
						label={localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')}
						isRequired={true}
						value={emailFormField.templateSubject}
						onBlur={(e: any) => {
							updateInvitationTemplates(e.target.value, 'templateSubject');
						}}
					/>
				</div>
				<div className="form-group required">
					<label>{localizationService.getLocalizedString('authoritySetting.noticesSettings.body')}</label>
					<div className="add-notice-editor-template">
						<RichTextEditor
							customMergeFieldList={emailMergeFieldList}
							editorContent={emailFormField.templateContent}
							saveTemplateData={(content: string) => {
								updateInvitationTemplates(content, 'templateContent');
							}}
							height="467px"
							showTableTools={true}
						/>
					</div>
				</div>
			</>
		) : (
			<></>
		);
	}

	return (
		<>
			<hr />
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString('authoritySetting.haulerPortalInvitation.title')}
					</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.haulerPortalInvitation.desc')}</p>
				</div>
				<div className="col-lg-8">
					<section className="hauler-portal-editor-template">
						<Nav tabs className="material">
							<NavItem>
								<NavLink
									tag={Link}
									onClick={() => navigateToRoute<string>(history, InvitationTypes.Email, url)}
									className={
										classNames({ active: activeTab === InvitationTypes.Email }) + ' cursor-pointer'
									}>
									{localizationService.getLocalizedString(
										'authoritySetting.haulerPortalInvitation.tabEmail'
									)}
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									tag={Link}
									onClick={() => navigateToRoute<string>(history, InvitationTypes.Letter, url)}
									className={
										classNames({ active: activeTab === InvitationTypes.Letter }) + ' cursor-pointer'
									}>
									{localizationService.getLocalizedString(
										'authoritySetting.haulerPortalInvitation.tabLetter'
									)}
								</NavLink>
							</NavItem>
						</Nav>

						<div className="margin-top-1">
							<Switch>
								<TabContent activeTab={activeTab}>
									<TabPane tabId="invitation-email">{getHaulerEmailEditor()}</TabPane>
									<TabPane tabId="invitation-letter">{getHaulerLetterEditor()}</TabPane>
								</TabContent>
							</Switch>
						</div>
					</section>
				</div>
			</div>
		</>
	);
};

const HaulerSettingsComponent: React.FunctionComponent = () => {
	const [authoritySetting, setAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const [originalAuthorityOrpSetting, setOriginalAuthorityOrpSetting] = React.useState(initialAuthorityOrpSetting);
	const [isHaulerPortalEnabled, setIsHaulerPortalEnabled] = React.useState<boolean>(false);

	React.useEffect(() => {
		loadAuthoritySettings();

		getHaulerPortalStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadAuthoritySettings = async () => {
		const authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		apiService.getResource<SettingValue[]>(authoritySettingsUrl).then((values: SettingValue[]) => {
			let originalAuthoritySetting = { ...authoritySetting };
			for (let settingValue of values) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				originalAuthoritySetting[key] = settingValue.value;
			}
			setAuthoritySetting(originalAuthoritySetting);
			setOriginalAuthorityOrpSetting(originalAuthoritySetting);
		});
	};

	const onAuthorityOrpSettingChanged = (e: any) => {
		let newSetting = { ...authoritySetting };
		const { name, value } = e.target;
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);
	};

	const updateAuthoritySettingValue = (e: any) => {
		const { name, value } = e.target;

		let dataToUpdate = {
			settingType: name,
			value: value
		};
		if (originalAuthorityOrpSetting[name] === value) {
			return;
		}

		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);

		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				let updateAuthorityOrpSetting = { ...originalAuthorityOrpSetting };
				updateAuthorityOrpSetting[name] = value;
				setOriginalAuthorityOrpSetting(updateAuthorityOrpSetting);
				setAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};

	const getHaulerPortalStatus = async () => {
		let url = `${urlService.getAuthorityOrpDetailApiUrl()}/${
			tokenService.getTokenOrDefault().portalOrganizationId
		}/HaulerPortalStatus`;
		try {
			await apiService.httpGet(url).then(data => setIsHaulerPortalEnabled(data as boolean));
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	return (
		<div className="page">
			{urlService.isAdministrator() ? (
				<>
					<div className="page-header">
						<BackArrow />
						<h1>{localizationService.getLocalizedString('authoritySetting.haulers')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">
							{getHaulerAutoNumberSection()}
							{isHaulerPortalEnabled && (
								<>
									<GetHaulerPortalInvitationSection />
								</>
							)}
						</div>
					</div>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</div>
	);

	function getHaulerAutoNumberSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>
						{localizationService.getLocalizedString('authoritySetting.autoNumberForHaulersSection')}
					</strong>
					<p>
						{localizationService.getLocalizedString(
							'authoritySetting.autoNumberForDescription1',
							'authoritySetting.haulerNumbers'
						)}
						<div className="mt-3">
							{localizationService.getLocalizedString('authoritySetting.autoNumberForDescription2')}
							<i>
								{localizationService.getLocalizedString('authoritySetting.autoNumberForDescription3')}
							</i>
						</div>
					</p>
				</div>
				<div className="col-lg-8">
					<section>
						<div className="form-row">
							<TextInput
								id="autoNumberPrefixForHauler"
								name="autoNumberPrefixForHauler"
								className="form-group col-sm-6"
								label={localizationService.getLocalizedString('authoritySetting.prefixFor')}
								value={authoritySetting.autoNumberPrefixForHauler}
								onBlur={updateAuthoritySettingValue}
								onEnterKeyPressed={updateAuthoritySettingValue}
								onChange={onAuthorityOrpSettingChanged}
							/>
							<TextInput
								id="autoNumberSuffixForHauler"
								name="autoNumberSuffixForHauler"
								className="form-group col-sm-6"
								label={localizationService.getLocalizedString('authoritySetting.suffixFor')}
								value={authoritySetting.autoNumberSuffixForHauler}
								onBlur={updateAuthoritySettingValue}
								onEnterKeyPressed={updateAuthoritySettingValue}
								onChange={onAuthorityOrpSettingChanged}
							/>
						</div>
					</section>
				</div>
			</div>
		);
	}
};

export const AuthoritySettingHauler = HaulerSettingsComponent;
