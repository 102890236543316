import * as ApiTypes from '@rcp/types';

export interface Facility extends ApiTypes.FogFacility {
	selected?: boolean;
}

export interface IFacilityOverviewProps {
	referenceNumber: string;
	accountNumber: string;
	effectiveDate: string;
	applicationDate: string;

	isEnabled?: boolean;
	tagClassification1: string;
	tagClassification2: string;
	numberOfGreaseDevices?: number;
	mapCategory: string;
	assignedTo: string;
	pumpOutFrequency: string;
	averagePumpOutFrequency: string;
}
export interface IFacilityAddress {
	facilityName: string;
	referenceNumber: string;
	complianceStatus: string;
}
export interface IFacilityDetailsProps extends IFacilityOverviewProps {
	facilityName: string;
	email: string;
	phoneNumber: string;
	complianceStatus: string;
	invoiceDate: string;
	inBusinessSince: string;
	closedDate: string;
	meterNumber: string;
	sewerNumber: string;

	seatingCapacity: string;
	numberMealsServed: string;
	numberOfEmployees: string;
	hoursOfOperation: string;
	squareFootage: string;
}

export interface IFacilityDetailsParamProp {
	items: any;
	keyPrefix: string;
	onEditClicked?: any;
}

export enum FieldTypeEnum {
	String,
	Number,
	Integer,
	Date,
	DateTime,
	Boolean,
	SingleValueDropDown,
	TextArea,
	RichTextEditor
}

export interface IFacilityItem {
	fieldName: string;
	title: string;
	dataType: FieldTypeEnum;
	dropdown?: boolean;
	isRequired: boolean;
	value: any;
	labelValue?: string;
	optionValues?: any[];
	error?: string;
}

export interface IEditItemsProps {
	title: string;
	keyPrefix: string;
	showModel: boolean;
	items: IFacilityItem[];
	editModalToggleFunction: (e?: any) => void;
	saveFunction: (items: any) => void;
	onItemValueChanged?: (e: any, item: IFacilityItem) => void;
}
