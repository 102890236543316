import React, { FC } from 'react';
import { PortalRegistration, LocalStorageName, RouteProps, UrlAction, Portal, ServicePortalType } from '@rcp/types';
import { SingleCheckbox, TextInput } from 'src/components';
import {
	apiService,
	localizationService,
	localStorageService,
	navigateTo,
	Resource,
	tokenService,
	urlService,
	validationService
} from '../../../../services';
import './register-account.scss';
import { AlertMessageType, alertService, ApplicationState, AuthenticationState } from '../../../../redux';
import { useHistory, useParams } from 'react-router';
import { SignIn } from 'src/components/account';
import { NotFound } from '../../../../features/home/not-found';
import { useSelector } from 'react-redux';
import { ReactComponent as InlineInfo } from 'src/assets/img/inline-info.svg';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { Translate } from 'src/components/widgets/translate/translator';

interface Props<T> extends AuthenticationState, RouteProps {}

interface FormData {
	email?: string;
	firstName?: string;
	lastName?: string;
	mobilePhoneNumber?: string;
	phoneNumber?: string;
	password?: string;
	confirmPassword?: string;
	agreeTermsAndConditions?: boolean;
	emailError?: string;
	firstNameRequiredError?: string;
	lastNameRequiredError?: string;
	passwordError?: string;
	confirmPasswordError?: string;
	isAgreeTermConditionRequiredError?: string;
	servicePortalType?: string;
}

export const UserAccountRegistration: FC<Props<any>> = props => {
	const history = useHistory();
	const paths = history.location.pathname.split('/');
	const portalName = history.location.pathname.includes('facility') ? Portal.facility : Portal.hauler;
	const [formState, setFormData] = React.useState<FormData>({} as FormData);
	const [registerForm, setRegisterForm] = React.useState<boolean>(false);
	const [isUerAlreadyExist, setUerExist] = React.useState<boolean>(false);

	const authState = useSelector((state: ApplicationState) => state.authenticationResult);

	React.useEffect(() => {
		document.body.className = 'signinBody';
		return () => {
			document.body.className = '';
		};
	}, []);

	const isFacilityPortal = () => {
		return portalName.toLowerCase() === Portal.facility.toLowerCase();
	};

	const redirectToSignIn = () => {
		let signInUrl = `/signIn?action=`;
		if (!isFacilityPortal()) {
			signInUrl = `${signInUrl}${UrlAction.HaulerRegistration}`;
		} else {
			signInUrl = `${signInUrl}${UrlAction.FacilityRegistration}`;
		}
		navigateTo(history, signInUrl);
	};

	const setTokenInLocalStorage = (data: PortalRegistration) => {
		let localStorageKey = '';
		if (!isFacilityPortal()) {
			localStorageKey = LocalStorageName.HaulerPortalToken;
		} else {
			localStorageKey = LocalStorageName.FacilityPortalToken;
		}
		localStorageService.setLocalStorage(localStorageKey, data);
	};

	const isEmailExist = (email: string) => {
		let url = urlService.getApiBaseUrl();
		url = `${url}/${Resource.CheckIsExistingUser}/${email}`;
		if (email !== '') {
			apiService
				.getResource<PortalRegistration>(url)
				.then((data: any) => {
					const { isExistingUser } = data;
					setUerExist(isExistingUser);
				})
				.catch(err => {
					alertService.addError(localizationService.getLocalizedString('errors.somethingWrong'));
				});
		} else {
			setUerExist(false);
		}
	};

	const changeFormState = (e: any) => {
		if (e.target.name === 'agreeTermsAndConditions') {
			setFormData({
				...formState,
				[e.target.name]: e.target.checked
			});
		} else {
			setFormData({
				...formState,
				[e.target.name]: e.target.value
			});
		}
	};

	const isPasswordSame = () => {
		if (formState.password !== formState.confirmPassword) {
			setFormData({
				...formState,
				confirmPasswordError: localizationService.getLocalizedString(
					'ipp.accountPortal.passwordNotMatchMessage'
				)
			});
			return false;
		} else {
			return true;
		}
	};

	const validateRegistrationForm = () => {
		let newState = { ...formState };
		if (newState.email === '' || newState.email === undefined) {
			validationService.validateRequiredField(
				newState,
				'email',
				'emailError',
				localizationService.getLocalizedString('authentication.email')
			);
		} else {
			validationService.validateEmailFormatField(newState, 'email', 'emailError');
		}
		validationService.validateRequiredField(
			newState,
			'firstName',
			'firstNameRequiredError',
			localizationService.getLocalizedString('users.firstName')
		);
		validationService.validateRequiredField(
			newState,
			'lastName',
			'lastNameRequiredError',
			localizationService.getLocalizedString('users.lastName')
		);
		validationService.validateRequiredField(
			newState,
			'password',
			'passwordError',
			localizationService.getLocalizedString('authentication.password')
		);
		validationService.validateRequiredField(
			newState,
			'confirmPassword',
			'confirmPasswordError',
			localizationService.getLocalizedString('ipp.accountPortal.confirmPassword')
		);

		validationService.validatePasswordField(newState, 'password', 'passwordError');

		let isFromValid = !validationService.hasError(
			newState,
			'emailError',
			'firstNameRequiredError',
			'lastNameRequiredError',
			'passwordError',
			'confirmPasswordError'
		);
		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		if (!newState.agreeTermsAndConditions)
			newState.isAgreeTermConditionRequiredError = isFromValid
				? localizationService.getLocalizedString('errors.agreeToTermsAndConditions')
				: '';
		else newState.isAgreeTermConditionRequiredError = '';
		if (!isPasswordSame()) {
			alertService.addError(localizationService.getLocalizedString('ipp.accountPortal.passwordNotMatchMessage'));
			isFromValid = false;
		}
		setFormData({ ...newState });
		return isFromValid && !newState.isAgreeTermConditionRequiredError;
	};

	const afterApiCallProcesses = (data: PortalRegistration) => {
		setRegisterForm(!registerForm);
		if (data) {
			setTokenInLocalStorage(data);
		}
	};

	const onRegister = () => {
		if (validateRegistrationForm()) {
			let registerUrl = `${urlService.getApiBaseUrl()}/${Resource.TempUsers}/${Resource.Register}`;
			let registerData = { ...formState };
			if (isFacilityPortal()) {
				registerData = { ...registerData, servicePortalType: ServicePortalType.FacilityPortal };
			}

			apiService
				.postResourceObject<PortalRegistration>(registerUrl, registerData)
				.then((data: PortalRegistration) => {
					alertService.addSuccess(
						localizationService.getLocalizedString('haulerPortal.SuccessRegisterMessage')
					);
					afterApiCallProcesses(data);
				})
				.catch(err => {
					alertService.addError(err.message);
				});
		}
	};

	const showRegisterForm = () => {
		setFormData({} as FormData);
		setRegisterForm(false);
	};

	const termsAndCondition = () => {
		return (
			<span>
				<Translate>{localizationService.getLocalizedString('ipp.accountPortal.agreeLabel')}</Translate>{' '}
				<a target="_blank" href="/TermsAndConditions" className="ai-link cursor-pointer">
					<Translate>{localizationService.getLocalizedString('screen.labels.termsAndConditions')}</Translate>
				</a>
			</span>
		);
	};

	const emailField = (
		<TextInput
			id="email"
			name="email"
			className="form-group col-sm-12"
			label={localizationService.getLocalizedString('authentication.emailFacilityRegistration')}
			isRequired={true}
			value={formState.email}
			onChange={changeFormState}
			autoComplete="new-password"
			error={formState.emailError}
			onBlur={(event: any) => {
				isEmailExist(event.target && event.target.value);
			}}
		/>
	);

	const registerAccount = () => (
		<>
			<h1 className="text-center">
				<Translate>{localizationService.getLocalizedString('haulerPortal.registerAccount')}</Translate>
			</h1>
			<p>
				<span>
					<Translate>
						{localizationService.getLocalizedString('haulerPortal.registerAccountDescription')}
					</Translate>
				</span>{' '}
				<a
					className="ai-link cursor-pointer"
					onClick={() => {
						redirectToSignIn();
					}}>
					<Translate>{localizationService.getLocalizedString('screen.buttons.signin')}</Translate>
				</a>
			</p>
			<div className="form-row">{emailField}</div>
			<div className="form-row">
				<TextInput
					id="firstName"
					name="firstName"
					className="form-group col-sm-6"
					label={localizationService.getLocalizedString('users.firstName')}
					isRequired={true}
					value={formState.firstName}
					autoComplete="new-password"
					onChange={changeFormState}
					error={formState.firstNameRequiredError}
				/>

				<TextInput
					id="lastName"
					name="lastName"
					className="form-group col-sm-6"
					label={localizationService.getLocalizedString('users.lastName')}
					isRequired={true}
					value={formState.lastName}
					autoComplete="new-password"
					onChange={changeFormState}
					error={formState.lastNameRequiredError}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="phoneNumber"
					name="phoneNumber"
					className="form-group col-sm-12"
					label={localizationService.getLocalizedString('users.phone')}
					isRequired={false}
					value={formState.phoneNumber}
					maxLength={20}
					onChange={changeFormState}
					autoComplete="new-password"
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="mobilePhoneNumber"
					name="mobilePhoneNumber"
					className="form-group col-sm-12"
					label={localizationService.getLocalizedString('users.mobilePhone')}
					isRequired={false}
					value={formState.mobilePhoneNumber}
					maxLength={20}
					onChange={changeFormState}
					autoComplete="new-password"
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="password"
					name="password"
					type={'password'}
					className="form-group col-sm-12"
					label={localizationService.getLocalizedString('authentication.password')}
					isRequired={true}
					value={formState.password}
					onChange={changeFormState}
					helpText={localizationService.getLocalizedString('users.passwordHint')}
					error={formState.passwordError}
					showErrorAndHelp
					autoComplete="new-password"
					showErrorAndHelp={true}
				/>
			</div>
			<div className="form-row">
				<TextInput
					id="confirmPassword"
					name="confirmPassword"
					className="form-group col-sm-12"
					type={'password'}
					label={localizationService.getLocalizedString('ipp.accountPortal.confirmPassword')}
					isRequired={true}
					value={formState.confirmPassword}
					onChange={changeFormState}
					error={formState.confirmPasswordError}
					autoComplete="new-password"
				/>
			</div>
			<div className="form-row">
				<SingleCheckbox
					id="agreeTermsAndConditions"
					name="agreeTermsAndConditions"
					label={termsAndCondition()}
					error={formState.isAgreeTermConditionRequiredError}
					checked={formState.agreeTermsAndConditions || false}
					onChange={changeFormState}
					className="div-checkbox ml-1"
					doNotTranslate={true}
					error={formState.isAgreeTermConditionRequiredError}
				/>
			</div>
			<div className="form-row">
				<button className="btn ai-action ml-auto" onClick={e => onRegister()}>
					<Translate>{localizationService.getLocalizedString('screen.buttons.register')}</Translate>
				</button>
			</div>
		</>
	);

	const registerEmailCheck = () => (
		<>
			<h1 className="text-center"><Translate>{localizationService.getLocalizedString('haulerPortal.checkYourEmail')}</Translate></h1>
			<div className="text-center">
				<p>
					<Translate>{localizationService.getLocalizedString('haulerPortal.emailCheckTextStart')}</Translate>
					&nbsp;
					<strong><Translate>{formState.email}</Translate></strong>
					&nbsp;
					<Translate>{localizationService.getLocalizedString('haulerPortal.emailCheckTextEnd')}</Translate>
				</p>
				<p>
					<strong><Translate>{localizationService.getLocalizedString('haulerPortal.emailNotSendTitle')}</Translate></strong>
					<br />
					<span>
						<Translate>{localizationService.getLocalizedString('haulerPortal.emailNotSendText')}</Translate>
						<a
							className="ai-link cursor-pointer"
							onClick={() => {
								showRegisterForm();
							}}>
							<Translate>{localizationService.getLocalizedString('haulerPortal.registerAgain')}</Translate>
						</a>
						.
					</span>
				</p>
			</div>
		</>
	);

	const userRegisterForm = (signInData: any) => {
		return (
			<>
				<h1 className="text-center">
					<Translate>{localizationService.getLocalizedString('haulerPortal.registerAccount')}</Translate>
				</h1>
				<p>
					<div id="register-info-message">
						<InlineAlertItem
							message={
								isFacilityPortal()
									? localizationService.getLocalizedString('facilityPortal.acpExistingUser')
									: localizationService.getLocalizedString('haulerPortal.acpExistingUser')
							}
							alertType={AlertMessageType.Info}
						/>
					</div>
				</p>
				{signInData.error && signInData.error.length > 0 ? (
					<div className="w-100">
						<InlineAlertItem
							message={signInData.error[0].message}
							alertContainerId="signin-alert"
							alertType={AlertMessageType.Error}
						/>
					</div>
				) : null}
				<div className="form-row">{emailField}</div>
				<div className="form-row">
					<TextInput
						id="password"
						name="password"
						type={'password'}
						className="form-group col-sm-12"
						label={localizationService.getLocalizedString('authentication.password')}
						isRequired={true}
						value={formState.password}
						onChange={changeFormState}
						autoComplete="new-password"
						error={formState.passwordError}
					/>
				</div>
				<div className="form-row">
					<button
						className="btn ai-action ml-auto"
						onClick={e => signInData.clickSignInButton(e, formState.email, formState.password)}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.signin')}</Translate>
					</button>
					<button className="btn ai-white ml-2" onClick={e => redirectToSignIn()}>
						<Translate>{localizationService.getLocalizedString('screen.buttons.cancel')}</Translate>
					</button>
				</div>
			</>
		);
	};

	return (
		<>
			{isUerAlreadyExist ? (
				<SignIn history={history} renderCustomForm={userRegisterForm} />
			) : tokenService.isTokenValid() ? (
				<NotFound path={history.location.pathname} />
			) : (
				<div className="register-account">{registerForm ? registerEmailCheck() : registerAccount()}</div>
			)}
		</>
	);
};
