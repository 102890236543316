import { LookupRow } from '../lookup-types';
import { LookupType, WasteType } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class WasteTypeLookRowDataConverter implements ILookupDataConverter {
	lookupType: LookupType;
	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: WasteType): LookupRow => {
		return {
			lookupId: apiData.wasteTypeId,
			code: apiData.wasteTypeCode,
			codeDescription: apiData.wasteTypeDescription,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let wasteType = {
			wasteTypeId: rowToUpdate.lookupId,
			wasteTypeCode: rowToUpdate.code,
			wasteTypeDescription: rowToUpdate.codeDescription,
			isActive: rowToUpdate.isActive
		} as WasteType;
		return wasteType;
	}

	toLookupRowsFromAPI(apiData: WasteType[]): LookupRow[] {
		return apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): WasteType {
		let wasteType = {
			wasteTypeCode: newValue.code,
			wasteTypeDescription: newValue.codeDescription,
			isActive: newValue.isActive || true
		} as WasteType;
		return wasteType;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
