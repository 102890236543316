import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as ApiTypes from '@rcp/types';
import { SingleCheckbox } from 'src/components/widgets';
import { validationService, localizationService } from 'src/services';
import { CloseSvg } from '../../../../../svg';

import { alertService } from 'src/redux';

export interface EquipmentEditModalProp {
	title: string;
	showModel: boolean;
	equipment: ApiTypes.Equipment;
	modalToggleFunction: (e: any) => void;
	equipmentTypes: ApiTypes.EquipmentType[];
	onSaveEditModal: (equipment: ApiTypes.Equipment) => void;
	toggleDeleteModal?: () => void;
}

export class EquipmentEditModal extends Component<EquipmentEditModalProp, any> {
	stringLimit = {
		commentsLimit: 1024
	};

	MaxQuantity = 1000;
	MinQuantity = 0;

	state = {
		...this.props.equipment,
		equipmentTypeError: false,
		quantityError: false,
		commentsError: false
	};

	componentWillReceiveProps = (newProps: EquipmentEditModalProp) => {
		if (!newProps.showModel) {
			this.resetModal();
		} else {
			if (newProps.equipment && newProps.equipment.equipmentId && newProps.equipment.equipmentId > 0) {
				this.setState(newProps.equipment);
			} else if (newProps.equipmentTypes && newProps.equipmentTypes.length > 0) {
				this.onEquipmentTypeChanged(newProps.equipmentTypes[0].equipmentTypeId as number);
			}
		}
	};

	handleCancelBtnClickEvent = (e: any) => {
		this.toggleModal(e);
	};

	resetModal = () => {
		this.setState({
			equipmentId: -1,
			equipmentComments: '',
			equipmentQuantity: 1,
			equipmentTypeId: 0,
			equipmentType: '',
			plumbedToGreaseInterceptor: false,
			equipmentTypeError: false,
			quantityError: false,
			commentsError: false
		});
	};

	handleSaveBtnClickEvent = (e: any) => {
		if (!this.validateFormForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let newEquipment = {
			equipmentId: this.props.equipment.equipmentId,
			equipmentComments: this.state.equipmentComments,
			equipmentQuantity: this.state.equipmentQuantity,
			equipmentTypeId: this.state.equipmentTypeId,
			equipmentType: this.state.equipmentType,
			plumbedToGreaseInterceptor: this.state.plumbedToGreaseInterceptor
		};

		this.props.onSaveEditModal({ ...this.props.equipment, ...newEquipment });
		this.props.modalToggleFunction(e);
		this.resetModal();
	};

	onEquipmentQuantityChanged = (e: any) => {
		this.setState({
			equipmentQuantity: e.target.value,
			quantityError: false
		});
	};

	onCommentsChanged = (e: any) => {
		if (e.target.value.length > this.stringLimit.commentsLimit) {
			this.setState({
				commentsError: true
			});
		} else {
			this.setState({
				equipmentComments: e.target.value,
				commentsError: false
			});
		}
	};

	onEquipmentTypeChanged = (equipmentTypeId: number) => {
		let newEquipmentType = '';
		this.props.equipmentTypes.forEach(i => {
			if (i.equipmentTypeId === equipmentTypeId) {
				newEquipmentType = i.equipmentType;
			}
		});

		this.setState({
			equipmentTypeId: equipmentTypeId,
			equipmentType: newEquipmentType,
			quantityError: false
		});
	};

	validateFormForSave = (): boolean => {
		let newState = { ...this.state };
		let isFormValid = false;
		validationService.validateIntegerNumberField(
			newState,
			'equipmentQuantity',
			'quantityError',
			false,
			this.MaxQuantity,
			this.MinQuantity
		);
		validationService.validateRequiredField(newState, 'equipmentType', 'equipmentTypeError');
		isFormValid =
			!validationService.hasError(newState, 'quantityError') &&
			!this.state.commentsError &&
			this.state.equipmentTypeId > 0;

		if (!isFormValid) {
			this.setState(newState);
		}
		let errors = [];
		if (newState.equipmentTypeError) {
			errors.push(newState.equipmentTypeError);
		}
		if (newState.quantityError) {
			errors.push(newState.quantityError);
		}
		if (newState.commentsError) {
			errors.push(newState.commentsError);
		}
		if (errors.length > 0) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	onCheckBoxChanged = (e: any) => {
		this.setState({ plumbedToGreaseInterceptor: e.target.checked });
	};

	toggleModal = (e: any) => {
		if (this.props.showModel) {
			this.setState(this.props.equipment);
		}

		this.props.modalToggleFunction(e);
	};

	render() {
		if (this.state.equipmentTypeId === -1 && this.props.equipmentTypes.length > 0) {
			let firstEquipmentType = this.props.equipmentTypes[0];
			this.setState({
				equipmentTypeId: firstEquipmentType.equipmentTypeId
			});
		}

		return (
			<Modal isOpen={this.props.showModel} toggle={this.toggleModal} size="sm" centered={true} scrollable={false}>
				<ModalHeader
					toggle={this.toggleModal}
					tag="h1"
					close={
						<button className="close" onClick={this.toggleModal}>
							<CloseSvg />
						</button>
					}>
					{this.props.title}
				</ModalHeader>
				<ModalBody>
					<div className="form-group required">
						<label htmlFor="EquipmentType">
							{localizationService.getLocalizedString('equipments.equipmentType')}
						</label>
						<select
							id="EquipmentType"
							className={`custom-select ${this.state.equipmentTypeError ? ' is-invalid' : ' '}`}
							value={this.state.equipmentTypeId}
							onChange={(e: any) => this.onEquipmentTypeChanged(Number.parseInt(e.target.value))}>
							{this.props.equipmentTypes &&
								this.props.equipmentTypes.map((optItem: ApiTypes.EquipmentType, index: number) => (
									<option key={index} value={optItem.equipmentTypeId}>
										{optItem.equipmentType}
									</option>
								))}
						</select>
						{this.state.equipmentTypeError && (
							<div className="invalid-feedback">{this.state.equipmentTypeError}</div>
						)}
					</div>

					<div className="form-group">
						<label htmlFor="Quantity">
							{localizationService.getLocalizedString('equipments.quantity')}
						</label>
						<input
							type="text"
							className={`form-control ${this.state.quantityError ? ' is-invalid' : ' '}`}
							id="Quantity"
							value={this.state.equipmentQuantity}
							onChange={this.onEquipmentQuantityChanged}
						/>
						{this.state.quantityError && <div className="invalid-feedback">{this.state.quantityError}</div>}
					</div>
					<div className="form-group">
						<label htmlFor="Comments">
							{localizationService.getLocalizedString('screen.labels.comments')}
						</label>
						<textarea
							className={`form-control ${this.state.commentsError ? ' is-invalid' : ' '}`}
							id="Comments"
							maxLength={1024}
							rows={3}
							defaultValue={this.state.equipmentComments || ''}
							onChange={this.onCommentsChanged}
						/>
						{this.state.commentsError && (
							<div className="invalid-feedback">Maximum length is {this.stringLimit.commentsLimit} </div>
						)}
					</div>
					<SingleCheckbox
						id="PlumbedGreaseInterceptor"
						name="PlumbedGreaseInterceptor"
						label={localizationService.getLocalizedString('equipments.plumbedExtractor')}
						checked={this.state.plumbedToGreaseInterceptor}
						onChange={this.onCheckBoxChanged}
						className="div-checkbox"
					/>
				</ModalBody>
				<ModalFooter>
					{this.props.equipment && this.props.equipment.equipmentId > 0 && (
						<button
							type="button"
							className="btn ai-secondary-delete"
							onClick={() => {
								this.props.toggleDeleteModal && this.props.toggleDeleteModal();
							}}>
							{localizationService.getLocalizedString('screen.buttons.delete')}
						</button>
					)}

					<Button color="btn ai-save" id="saveBtn" onClick={this.handleSaveBtnClickEvent}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</Button>
					<Button color="btn ai-white" id="cancelBtn" onClick={this.handleCancelBtnClickEvent}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
}
