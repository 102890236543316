import React from 'react';
import { connect } from 'react-redux';
import { loadInspectionView, ApplicationState, InspectionsState, useReduxDispatch } from 'src/redux';
import _ from 'lodash';
import $ from 'jquery';
import { localizationService } from 'src/services';

import '../inspection-details/inspection-details.scss';
import { ReportAttachmentDetails } from 'src/components/widgets';
import { InspectionViewDetails } from './inspection-view-details';
interface OwnProps {}

interface RouteProps {
	match: {
		params: {
			inspectionPublicId: string;
			attachmentId: number;
		};
	};
}

interface StateProps extends InspectionsState {}

type Props = StateProps & OwnProps & RouteProps;

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.inspections };
};

const InspectionViewComponent: React.SFC<Props> = props => {
	const dispatch = useReduxDispatch();

	React.useEffect(() => {
		document.body.className = 'publicPage';
	}, []);

	React.useEffect(() => {
		dispatch(loadInspectionView(props.match.params.inspectionPublicId));

		window.onbeforeprint = function() {
			$('textarea').each(function() {
				$(this).height($(this)[0].scrollHeight);
			});
		};
	}, [dispatch, props.match.params.inspectionPublicId]);

	React.useEffect(() => {
		if (_.isEmpty(props.inspectionView)) {
			return;
		}
	}, [props.inspectionView]);

	if (_.isEmpty(props.inspectionView)) {
		return (
			<div className="page">
				<div className="page-header">
					<h1 className="d-print-none">
						{localizationService.getLocalizedString('inspection.inspectionReport')}
					</h1>
				</div>
				<div className="page-wrapper">
					<div className="left-container">
						<section>Cannot find Inspection Report</section>
					</div>
					<div className="sidebar"></div>
				</div>
			</div>
		);
	}

	let showAttachmentDetails = props.match.params.attachmentId !== undefined;
	let returnInspectionUrl = window.location.href;
	let content = <div />;
	if (showAttachmentDetails && props.inspectionView.fogFacility) {
		let pos = window.location.href.indexOf('attachments');
		returnInspectionUrl = returnInspectionUrl.substring(0, pos - 1);
		content = (
			<ReportAttachmentDetails
				returnToUrl={returnInspectionUrl}
				returnToLabel={'backToInspection'}
				attachments={props.inspectionView.inspectionAttachments || []}
				facility={props.inspectionView.fogFacility}
			/>
		);
	} else if (props.inspectionView.fogFacility) {
		content = <InspectionViewDetails inspectionView={props.inspectionView} />;
	}

	let reportTitle = localizationService.getLocalizedString('inspection.inspectionReport');
	if (props.match.params.attachmentId) {
		reportTitle = localizationService.getLocalizedString('attachments.details.title');
	}

	return (
		<div className="page">
			<div className="page-header">
				<h1 className="d-print-none">{reportTitle}</h1>
			</div>
			{content}
		</div>
	);
};

export const InspectionView = connect<StateProps, null, OwnProps, ApplicationState>(mapStateToProps)(
	InspectionViewComponent
);
