import React from 'react';
import * as ApiTypes from '@rcp/types';
import { FogMap } from 'src/components/widgets/map/fog-map';
import _ from 'lodash';
import '../../../widgets/map/map.scss';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

export const MapComponent: React.FC<Props> = props => {
	return (
		<div className="w-100">
			<div className="table-responsive">
				<FogMap />
			</div>
		</div>
	);
};
