import { MfaQuestion, QuestionAnswerDtos } from '@rcp/types';
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { alertService } from 'src/redux';
import { localizationService } from 'src/services';
import { SingleCheckbox } from 'src/components/widgets';
import Question, { QuestionType } from './question';
import { SqRef } from './register';

interface Props {
	showEditButton?: boolean;
	onNextSecurityQuestions?: (questions: QuestionAnswerDtos[], agreement: boolean) => void;
	questions: MfaQuestion[];
	selectedQuestions?: QuestionAnswerDtos[];
	hideAgreement?: boolean;
	submitBtnPrefix?: string;
	onBackKbq?: (questions: QuestionAnswerDtos[]) => void;
	sqs?: QuestionAnswerDtos[];
	isRegister?: boolean;
}

const SecurityQuestions = (props: Props, ref: Ref<SqRef>) => {
	let defaultQuestion = { ...props.questions[0] };
	const [question1SQ, setQuestion1SQ] = useState(defaultQuestion as MfaQuestion);
	const [question2SQ, setQuestion2SQ] = useState(defaultQuestion as MfaQuestion);
	const [answer1SQ, setAnswer1SQ] = useState('');
	const [answer2SQ, setAnswer2SQ] = useState('');
	const [agreement, setAgreement] = useState(false);
	const [question1SQError, setQuestion1SQError] = useState('');
	const [question2SQError, setQuestion2SQError] = useState('');
	const [answer1SQError, setAnswer1SQError] = useState('');
	const [answer2SQError, setAnswer2SQError] = useState('');

	const isSqSaved = () => onNext();

	useImperativeHandle(ref, () => ({ isSqSaved }));

	let questions: QuestionAnswerDtos[] = [];

	useEffect(() => {
		if (props.sqs && props.sqs.length) {
			setAnswer1SQ(props.sqs[0].answer || '');
			setAnswer2SQ(props.sqs[1].answer || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.sqs && props.sqs.length]);

	useEffect(() => {
		if (props.selectedQuestions && props.selectedQuestions[0]) {
			let question = props.selectedQuestions[0];
			setAnswer1SQ(question.answer || '');
			setQuestion1SQ(question);
		}
		if (props.selectedQuestions && props.selectedQuestions[1]) {
			let question = props.selectedQuestions[1];
			setAnswer2SQ(question.answer || '');
			setQuestion2SQ(question);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedQuestions]);

	useEffect(() => {
		if (!props.selectedQuestions && !props.sqs) {
			setQuestion1SQ(props.questions[0]);
			setQuestion2SQ(props.questions[0]);
		}
		if (!question1SQ || !Object.keys(question1SQ).length) {
			setQuestion1SQ(props.questions[0]);
		}
		if (!question2SQ || !Object.keys(question2SQ).length) {
			setQuestion2SQ(props.questions[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.questions]);

	const onNext = (type: string = 'next') => {
		questions = [];
		addQuestion(question1SQ, answer1SQ);
		addQuestion(question2SQ, answer2SQ);
		if (type === 'back') {
			props.onBackKbq && props.onBackKbq(questions);
			return null;
		}
		validateDuplicateAnswers();
		validateDuplicateQuestions(questions);
		if (
			!answer1SQ ||
			isSecurityAnswerAlreadyFilled() ||
			!answer2SQ ||
			isSecurityAnswerAlreadyFilled() ||
			isSecurityQuestionAlreadyFilled(question1SQ, questions) ||
			isSecurityQuestionAlreadyFilled(question2SQ, questions)
		) {
			alertService.clearAllMessages();
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return null;
		} else if (answer1SQ !== '' && answer2SQ !== '') {
			if (props.isRegister) {
				return { questions: questions, agreement };
			} else {
				props.onNextSecurityQuestions && props.onNextSecurityQuestions(questions, agreement);
				return null;
			}
		} else {
			alertService.addError(localizationService.getLocalizedString('ipp.accountPortal.messages.emptyAnswers'));
			return null;
		}
	};

	const validateDuplicateAnswers = () => {
		setAnswer1SQError(
			!answer1SQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer1')
				  )
				: isSecurityAnswerAlreadyFilled()
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateSQ')
				: ''
		);
		setAnswer2SQError(
			!answer2SQ
				? localizationService.getLocalizedString(
						'screen.validationMessage.fieldValueIsRequired',
						localizationService.getLocalizedString('ipp.accountPortal.answer2')
				  )
				: isSecurityAnswerAlreadyFilled()
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateSQ')
				: ''
		);
	};

	const validateDuplicateQuestions = (questions: MfaQuestion[]) => {
		setQuestion1SQError(
			isSecurityQuestionAlreadyFilled(question1SQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateSQ')
				: ''
		);
		setQuestion2SQError(
			isSecurityQuestionAlreadyFilled(question2SQ, questions)
				? localizationService.getLocalizedString('ipp.accountPortal.messages.duplicateSQ')
				: ''
		);
	};

	const isSecurityQuestionAlreadyFilled = (question: MfaQuestion, sqQuestions: MfaQuestion[]) => {
		let duplicateQuestions = sqQuestions.filter((sqQuestion: MfaQuestion) => {
			return sqQuestion.questionId === question.questionId;
		});
		if (duplicateQuestions.length > 1) {
			return true;
		} else {
			return false;
		}
	};

	const isSecurityAnswerAlreadyFilled = () => {
		if (String.equalCaseInsensitive(answer1SQ, answer2SQ)) {
			return true;
		} else {
			return false;
		}
	};

	const addQuestion = (question: MfaQuestion, answer: string) => {
		questions.push({
			questionId: question.questionId,
			question: question.content || (question as any).question,
			answer: answer,
			questionTypeName: 'SQ'
		});
	};

	const getUserQuestionAnswerId = (qNo: number) => {
		return (
			props.selectedQuestions && props.selectedQuestions[qNo] && props.selectedQuestions[qNo].userQuestionAnswerId
		);
	};

	return (
		<>
			<>
				<h2>{localizationService.getLocalizedString('ipp.accountPortal.sqTitle')}</h2>
				<p>{localizationService.getLocalizedString('ipp.accountPortal.sqSubTitle')}</p>
				<div className="w-75 mx-auto">
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question1')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer1')}
						answer={answer1SQ}
						type={QuestionType.Security}
						questions={props.questions}
						showEditButton={props.showEditButton || false}
						selectedQuestion={question1SQ}
						onChangeAnswer={e => {
							setAnswer1SQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(0)}
						onQuestionChange={(question: MfaQuestion) => setQuestion1SQ(question)}
						questionError={props.showEditButton ? undefined : question1SQError}
						answerError={props.showEditButton ? undefined : answer1SQError}
					/>
					<Question
						questionLabel={localizationService.getLocalizedString('ipp.accountPortal.question2')}
						answerLabel={localizationService.getLocalizedString('ipp.accountPortal.answer2')}
						answer={answer2SQ}
						type={QuestionType.Security}
						showEditButton={props.showEditButton || false}
						selectedQuestion={question2SQ}
						questions={props.questions}
						onChangeAnswer={e => {
							setAnswer2SQ(e.target.value);
						}}
						userQuestionAnswerId={getUserQuestionAnswerId(1)}
						onQuestionChange={(question: MfaQuestion) => setQuestion2SQ(question)}
						questionError={props.showEditButton ? undefined : question2SQError}
						answerError={props.showEditButton ? undefined : answer2SQError}
					/>
					{!props.hideAgreement && (
						<div className="form-row">
							<SingleCheckbox
								id="agree-terms-and-condition"
								name="agreeTermsAndCondition"
								label={
									<span>
										{localizationService.getLocalizedString('ipp.accountPortal.agreeLabel')}
										<a href="/TermsAndConditions" target="_blank">
											{localizationService.getLocalizedString(
												'ipp.accountPortal.termsAndConditions'
											)}
										</a>
									</span>
								}
								checked={agreement}
								className="form-group div-checkbox"
								onChange={(e: any) => setAgreement(e.target.checked)}
							/>
						</div>
					)}
				</div>
				{props.onNextSecurityQuestions && !props.isRegister && (
					<div className="form-row mt-1">
						<div className="col-md-12 d-flex">
							{props.onBackKbq && (
								<button className="btn ai-action mr-auto" onClick={() => onNext('back')}>
									{localizationService.getLocalizedString(
										props.submitBtnPrefix ? props.submitBtnPrefix : 'ipp.buttons.back'
									)}
								</button>
							)}
							<button className="btn ai-action ml-auto" onClick={() => onNext()}>
								{localizationService.getLocalizedString(
									props.submitBtnPrefix ? props.submitBtnPrefix : 'ipp.accountPortal.register'
								)}
							</button>
						</div>
					</div>
				)}
			</>
		</>
	);
};

export default forwardRef(SecurityQuestions);
