import React from 'react';
import { DateUtilService } from 'src/services';
import { localizationService } from 'src/services';

import './timeline.scss';

export enum TimelineType {
	today = 'todayLine',
	top = 'top',
	ending = 'ending',
	comment = 'comment',
	callLog = 'callLog',
	pumpOut = 'pumpOut',
	email = 'email',
	inspection = 'inspection',
	violation = 'violation',
	enforcement = 'enforcement',
	pastDue = 'pastDue',
	missing = 'missing',
	edit = 'edit',
	test = 'test',
	sentNotice = 'sentNotice',
	cleaning = 'cleaning',
	cleaningManifest = 'cleaningManifest',
	sentGeneralNotice = 'sentGeneralNotice',
	sendingCleaningNotice = 'sendingCleaningNotice',
	sentCleaningNotice = 'sentCleaningNotice',
	failedCleaningNotice = 'failedCleaningNotice'
}

interface TimelineEventProps {
	eventDateTime?: string;
	showTime?: boolean;
	icon?: JSX.Element;
	iconBackgroundCss?: string;
	isTodayTimeLineDivider?: boolean;
	isEnding?: boolean;
	eventType: TimelineType;
}
const TimelineEventComp: React.SFC<TimelineEventProps> = props => {
	let dateContent;
	let timeContent;
	let content = (
		<div className="timeline-content-panel flex-fill">
			{props.isTodayTimeLineDivider === true ? (
				<div className="today" />
			) : (
				<div className="timeline-content flex-fill">{props.children}</div>
			)}
		</div>
	);

	let dateCss = '';
	let iconCss = ['timeline-icon'];

	if (props.isTodayTimeLineDivider === true) {
		iconCss.push('invisible');
		dateCss = 'today-date';
		dateContent = localizationService.getLocalizedString('screen.timeline.today');
		timeContent = '';
	}

	let timelineCss = ['linko-timeline-event', 'd-flex', props.eventType];
	if (props.isEnding === true) {
		iconCss.push('invisible');
		timelineCss.push('timeline-ending');
		dateContent = '';
		timeContent = '';
		content = (
			<div className="dash-timeline">
				<div className="timeline-content"> </div>
			</div>
		);
	}

	if (props.iconBackgroundCss) {
		iconCss.push(props.iconBackgroundCss);
	}

	if (props.eventDateTime && !props.isTodayTimeLineDivider && DateUtilService.isValidDateTime(props.eventDateTime)) {
		dateContent = DateUtilService.toDisplayDate(props.eventDateTime);
		timeContent = DateUtilService.toDisplayTime(props.eventDateTime);
	}

	return (
		<div className={timelineCss.join(' ')}>
			<div className="timeline-date-time">
				<div className={dateCss}>{dateContent}</div>
				{props.showTime && <div className="time">{timeContent}</div>}
			</div>
			<div className="timeline-icon-panel d-flex flex-row">
				<div className={iconCss.join(' ')}>{props.icon}</div>
			</div>
			{content}
		</div>
	);
};

export const TimelineEvent = TimelineEventComp;
