import React from 'react';

interface Props {}

export const AlertSuccessSvg: React.SFC<Props> = props => {
	return (
		<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
			<path d="M9,16.5A7.5,7.5,0,1,1,16.5,9,7.5,7.5,0,0,1,9,16.5Z" style={{ fill: 'green' }}></path>
			<path d="M9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2M9,1A8,8,0,1,1,1,9,8,8,0,0,1,9,1Z" style={{ fill: '#fff' }}></path>
			<path
				d="M12.93,8,8.69,12.2a1,1,0,0,1-1.42,0L5.43,10.36A1,1,0,0,1,5.43,9,1,1,0,0,1,6.85,9L8,10.08l3.53-3.53a1,1,0,0,1,1.42,0A1,1,0,0,1,12.93,8Z"
				style={{ fill: '#fff' }}></path>
		</svg>
	);
};
