import { IndustriesBulkUpdate } from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { localizationService } from 'src/services';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { AlertMessageType } from 'src/redux';

interface Props {
	history?: History;
}

const Step1: React.FunctionComponent<Props> = props => {
	const stepperContext = React.useContext(StepperContext);
	const bulkUpdateSummary = stepperContext.getData(IndustriesBulkUpdate.STEP1_INPUT_VALUES);

	useEffect(() => {
		stepperContext.resolve('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let updatedRows = bulkUpdateSummary.updatedRows || 0;
	return (
		<StepperContent
			id="updateField"
			actions={
				<StepperAction
					type="button"
					className="btn ai-secondary mt-2"
					onClick={() => props.history && props.history.goBack()}>
					{localizationService.getLocalizedString('ipp.buttons.goBackToIndustries')}
				</StepperAction>
			}>
			{updatedRows > 0 && (
				<div className="mt-2 d-inline-flex">
					<InlineAlertItem
						message={localizationService.getLocalizedString(
							'ipp.industries.bulkUpdate.rowsUpdatedSuccessMessage',
							_.toString(bulkUpdateSummary.updatedRows)
						)}
						alertType={AlertMessageType.Success}
					/>
				</div>
			)}
		</StepperContent>
	);
};

export default Step1;
