import { QuestionAnswerDtos } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { TextInput } from 'src/components/widgets';
import { apiService, localizationService, urlService } from 'src/services';
import CollapsibleCard from '../../../../widgets/collapsible-card/collapsible-card';

interface Props {
	userId?: number;
}
const UserSecurityQuestions: FC<Props> = props => {
	const [securityQuestionAnswers, setSecurityQuestionAnswers] = useState<QuestionAnswerDtos[]>();

	const loadSecurityQuestions = async () => {
		let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile/SecurityQuestionAnswers?userId=${
			props.userId
		}`;
		let securityQuestionAnswers = await apiService.getResource<QuestionAnswerDtos[]>(url);
		setSecurityQuestionAnswers(securityQuestionAnswers);
	};

	useEffect(() => {
		loadSecurityQuestions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderQuestionAnswers = () => {
		return securityQuestionAnswers && Boolean(securityQuestionAnswers.length) ? (
			securityQuestionAnswers.map((questionAnswer, index) => {
				return (
					<div key={index}>
						<TextInput
							id={`SQ-Question${index + 1}`}
							name="question"
							label={`${localizationService.getLocalizedString('ipp.accountPortal.question')} ${index +
								1}`}
							type="text"
							className="col-sm-6 mx-auto"
							value={questionAnswer.question || ''}
							isDisabled={true}
						/>
						<TextInput
							id={`SQ-Answer${index + 1}`}
							name="answer"
							label={`${localizationService.getLocalizedString('ipp.accountPortal.answer')} ${index + 1}`}
							className="col-sm-6 mx-auto"
							type="text"
							value={questionAnswer.answer || ''}
							isDisabled={true}
						/>
					</div>
				);
			})
		) : (
			<span className="ai-required">
				{localizationService.getLocalizedString('ipp.accountPortal.messages.mfaNotConfigured')}
			</span>
		);
	};
	return (
		<>
			<div className="accordion-data-grid">
				<CollapsibleCard
					key="sq-questions"
					className="mt-3"
					accordionType="sq-questions"
					isHidden={true}
					accordionHeading={localizationService.getLocalizedString('ipp.accountPortal.securityQuestions')}>
					<div className="mt-3">{renderQuestionAnswers()}</div>
				</CollapsibleCard>
			</div>
		</>
	);
};

export default UserSecurityQuestions;
