import * as React from 'react';
import FormData from 'form-data';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, urlService, Resource, apiService, validationService } from 'src/services';
import { alertService } from 'src/redux';
import {
	ApiError,
	ImportSampleSteps,
	IppSampleMissingRequiredDataFields,
	ValidationError,
	UploadMaximumSize10MB
} from '@rcp/types';
import { useEffect, useState } from 'react';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import _ from 'lodash';

interface Props {
	vertical?: boolean;
	onError?: (isError: boolean) => void;
}

const Step2: React.FunctionComponent<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const [selectedFile, setSelectedFile] = useState<File>();
	const [invalidFileErrorMessage, setInvalidFileErrorMessage] = useState('');
	const [errors, setErrors] = useState([] as ValidationError[]);

	useEffect(() => {
		let step3State = { ...stepperContext.getStep(ImportSampleSteps.STEP3), completed: false, data: null };
		stepperContext.updateStep(ImportSampleSteps.STEP3, step3State);
		let step4State = { ...stepperContext.getStep(ImportSampleSteps.STEP4), completed: false, data: null };
		stepperContext.updateStep(ImportSampleSteps.STEP4, step4State);
		let step5State = { ...stepperContext.getStep(ImportSampleSteps.STEP5), completed: false, data: null };
		stepperContext.updateStep(ImportSampleSteps.STEP5, step5State);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadFiles = async () => {
		let step2State = { ...stepperContext.getStep(ImportSampleSteps.STEP2), loading: true };
		stepperContext.updateStep(ImportSampleSteps.STEP2, step2State);
		const url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppSampleImport}/sampleFile`);
		let formData = new FormData();
		formData.append('files', selectedFile);
		const headers = {};

		return await apiService.postFormData(url, formData, headers);
	};

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();

		if (selectedFile === undefined || selectedFile === null) {
			return;
		}

		let isFileInvalid = false;

		if (!validationService.validateUploadSize(selectedFile.size, UploadMaximumSize10MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit10MB')
			);
			isFileInvalid = true;
		}
		let filenameArray = selectedFile.name.split('.');
		let extension = _.toLower(filenameArray[filenameArray.length - 1]);
		if (!(extension === 'xlsx' || extension === 'xls')) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.errors.importSampleInvalidExtension')
			);
			isFileInvalid = true;
		}

		if (isFileInvalid) {
			props.onError && props.onError(true);
			setInvalidFileErrorMessage(
				localizationService.getLocalizedString('ipp.samples.errors.importSampleInvalidExtension')
			);
			return;
		}

		uploadFiles()
			.then(async response => {
				if (response.validationErrors.length > 0) {
					setErrors(response.validationErrors);
					throw new Error('ValidationError');
				}

				const url = urlService.getAuthorityResourcesApiUrl(
					`${Resource.IppSampleImport}/${response.importTempFileId}/MissingRequiredDataFields`
				);

				let missingDefaults = await apiService.getResource<IppSampleMissingRequiredDataFields>(url);
				alertService.addSuccess(
					localizationService.getLocalizedString('ipp.samples.importSamples.fileValidationSuccess')
				);
				stepperContext.resolve({
					selectedFile: selectedFile,
					missingDefaults: missingDefaults.missingDataDefaults,
					...response
				});
			})
			.catch((error: any) => {
				if (error.message === 'ValidationError') {
					let step2State = { ...stepperContext.getStep(ImportSampleSteps.STEP2), loading: false };
					stepperContext.updateStep(ImportSampleSteps.STEP2, step2State);
					alertService.addError(
						localizationService.getLocalizedString('ipp.samples.errors.fileImportFailed')
					);
				} else if (error instanceof ApiError) {
					if (error.statusCode === 413) {
						alertService.addError(
							localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
						);
					} else {
						alertService.addError(error.message);
					}
				} else {
					alertService.addError(error.message.message);
				}

				let step2State = { ...stepperContext.getStep(ImportSampleSteps.STEP2), loading: false };
				stepperContext.updateStep(ImportSampleSteps.STEP2, step2State);
			});
	};

	const onChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const files = inputElement.files as FileList;
		if (files == null || files.length < 1) {
			return;
		}
		setSelectedFile(files[0]);
	};

	const handleDrop = (files: any) => {
		setSelectedFile(files[0]);
	};

	const back = () => stepperContext.goAt(ImportSampleSteps.STEP1);

	return (
		<StepperContent
			id="step1ChooseFile"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn ai-white mr-2" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					{selectedFile && (
						<StepperAction
							type="button"
							id="btnNext"
							className="btn ai-action ml-auto"
							onClick={onClickNext}>
							{localizationService.getLocalizedString('screen.buttons.next')}
						</StepperAction>
					)}
				</React.Fragment>
			}>
			<DragAndDrop
				handleDrop={handleDrop}
				isValid={invalidFileErrorMessage === ''}
				className="d-flex flex-column justify-content-center align-items-center"
				dragMessage={localizationService.getLocalizedString('importFile.step1DragAndDrop')}>
				<div className="ai-file-input d-flex justify-content-center align-items-center">
					<div className="file-input-group">
						<label className="btn ai-secondary" htmlFor="btnFileImport" aria-describedby="file-input-help">
							{localizationService.getLocalizedString('ipp.samples.importSamples.selectFile')}
						</label>
						<label className="file-name">{selectedFile && selectedFile.name}</label>
					</div>

					<input
						type="file"
						id="btnFileImport"
						className="file-input "
						onChange={onChangeHandler}
						onClick={(e: any) => (e.target.value = null)}
						accept=".xlsx,.xls"
						disabled={stepperContext.isLoading()}
					/>
				</div>
				<div className="text-center font-size-16px-regular mt-2">
					{localizationService.getLocalizedString('importFile.step1DragAndDrop')}
				</div>
			</DragAndDrop>
			<div className="ai-form-help mt-2">
				<span className="ai-required">{invalidFileErrorMessage}</span>
				{invalidFileErrorMessage && <>&nbsp;</>}
				{localizationService.getLocalizedString('importFile.ippStep1ImportInstruction')}
			</div>
			{errors && errors.length > 0 && (
				<div id="step2-errors" className="mt-3">
					<table className="table table-bordered table-condensed ">
						<thead>
							<tr>
								<th scope="col-md-6">
									{localizationService.getLocalizedString('ipp.samples.importSamples.error')}
								</th>
								<th scope="col-md-6">
									{localizationService.getLocalizedString('ipp.samples.importSamples.rowNumbers')}
								</th>
							</tr>
						</thead>
						<tbody>
							{errors.map((error: ValidationError, index: number) => {
								return (
									<tr key={index}>
										<td>{error.errorMessage}</td>
										<td>{error.rowNumbers}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}
		</StepperContent>
	);
};

export default Step2;
