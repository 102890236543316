import React, { FC, useState, useEffect } from 'react';
import { History } from 'history';
import { ColumnField, FilterType, LocalStorageName, HaulerUser, RouteProps, Hauler, AuthorityUser } from '@rcp/types';
import _ from 'lodash';
import { RestSlice, RootState } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import InviteEditHaulerUser from 'src/components/service-provider/shared/user-modal';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';
import { useDispatch } from 'react-redux';
import { haulersSlice } from '../../../haulers';
import { manageHaulerAccessSlice } from './manage-access.slice';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
	user: HaulerUser;
}

interface EditActionLinkType extends RouteProps {
	user: HaulerUser;
	linkText?: string;
	isEdit: boolean;
	restSlice: RestSlice<HaulerUser | AuthorityUser>;
	hauler: Hauler;
}

const checkAdministratorForHaulerPortal = () => {
	return (urlService.isAdministrator() && urlService.isHaulerPortal) || !urlService.isHaulerPortal();
};

const EditActionLink: FC<EditActionLinkType> = props => {
	const [showModal, setShowModal] = useState(false);
	const onEditClicked = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setShowModal(true);
	};
	const toggleModal = () => {
		setShowModal(!showModal);
	};
	return (
		<>
			{checkAdministratorForHaulerPortal() ? (
				props.hauler.blockAccess ? (
					<span>{props.linkText ? props.linkText : props.user.fullName}</span>
				) : (
					<a href="#/" className="ai-link" onClick={e => onEditClicked(e)}>
						{props.linkText ? props.linkText : props.user.fullName}
					</a>
				)
			) : (
				<div>{props.user.fullName}</div>
			)}
			{showModal && (
				<InviteEditHaulerUser
					user={props.user}
					showModal={showModal}
					isEdit={props.isEdit}
					toggleModal={toggleModal}
					serviceName={props.hauler.name}
					restSlice={props.restSlice}
					serviceId={props.hauler.haulerId}
					match={props.match}
				/>
			)}
		</>
	);
};

export const HaulerPortalUsersGrid: FC<Props<HaulerUser>> = props => {
	const [showAddModal, setAddShowModal] = React.useState(false);
	const [hauler, setHauler] = React.useState({} as Hauler);
	const dispatch = useDispatch();
	const restSlice = urlService.isServicePortal() ? authorityUsersSlice : manageHaulerAccessSlice;

	useEffect(() => {
		if (props.match.params.haulerId) {
			restSlice.setApiUrlPath(`${Resource.Haulers}/${props.match.params.haulerId}/${Resource.Users}`);
			dispatch(
				haulersSlice.fetchOne(props.match.params.haulerId, (data: Hauler) => {
					setHauler(data);
				})
			);
		}
	}, [props.match.params.haulerId]);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const userToLink = (item: any) => {
		const user = item.dataItem as HaulerUser;
		return (
			<td>
				<EditActionLink hauler={hauler} user={user} isEdit={true} restSlice={restSlice} match={props.match} />
			</td>
		);
	};

	const filterOnUsers = (users: [], searchTerm: string): [] => {
		const isContains = (searchTerm: string, searchString?: string) => {
			return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
		};

		let filteredUsers = users.filter((user: HaulerUser) => {
			return user && (isContains(searchTerm, user.fullName) || isContains(searchTerm, user.email));
		});
		return filteredUsers as [];
	};

	const getStatus = (item: any) => {
		let user: HaulerUser = item.dataItem;
		const invitedLabel = localizationService.getLocalizedString('haulerPortal.settings.users.status.invited');
		return String.equalCaseInsensitive(user.haulerUserStatusName, invitedLabel) ? (
			<td>
				{invitedLabel}
				{checkAdministratorForHaulerPortal() && !hauler.blockAccess && (
					<>
						{' - '}
						<EditActionLink
							isEdit={false}
							user={user}
							linkText={localizationService.getLocalizedString(
								'haulerPortal.settings.users.status.resend'
							)}
							hauler={hauler}
							restSlice={restSlice}
							match={props.match}
						/>
					</>
				)}
			</td>
		) : (
			<td>{user.haulerUserStatusName}</td>
		);
	};

	const handleAccess = async (e: React.ChangeEvent<HTMLInputElement>) => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/${props.match.params.haulerId}/${
			Resource.BlockAccess
		}`;
		let blockAccess = await apiService.httpPatch(url, { blockAccess: e.target.checked });
		if (blockAccess)
			dispatch(
				haulersSlice.fetchOne(props.match.params.haulerId, (data: Hauler) => {
					setHauler(data);
				})
			);
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString(
			`haulerPortal.settings.users.${urlService.isHaulerPortal() ? 'users' : 'manageAccess'}`,
			hauler.name || ''
		),
		prefix: 'haulerPortal.settings.users.columns',
		storageName: LocalStorageName.HaulerPortalUsersGrid,
		sortOrderLocalStorageName: LocalStorageName.HaulerPortalUserGridSortOrder,
		showBackButton: true,
		queryParameters: {
			sort: 'fullName asc',
			includeInactive: '1'
		},
		showEditColumnButton: true,
		showSearch: true,
		searchPlaceholder: localizationService.getLocalizedString('haulerPortal.settings.users.search'),
		searchHint: localizationService.getLocalizedString('haulerPortal.settings.users.searchHint'),
		showAddButton: checkAdministratorForHaulerPortal() && !hauler.blockAccess,
		addButtonText: localizationService.getLocalizedString('haulerPortal.settings.users.inviteUser'),
		addModalComponent: (
			<InviteEditHaulerUser
				serviceName={hauler.name || ''}
				showModal={showAddModal}
				isEdit={false}
				toggleModal={toggleAddModal}
				restSlice={restSlice}
				serviceId={hauler.haulerId}
				match={props.match}
			/>
		),
		toggleAddModal: toggleAddModal,
		defaultSort: [{ field: 'fullName', dir: 'asc' }],
		allColumns: [
			new ColumnField('fullName', FilterType.Text, userToLink),
			new ColumnField('email'),
			new ColumnField('role'),
			new ColumnField('haulerUserStatusName', undefined, getStatus)
		],
		defaultColumns: ['fullName', 'email', 'role', 'haulerUserStatusName'],
		doClientSearch: filterOnUsers,
		checkbox: urlService.isHaulerPortal()
			? undefined
			: {
					id: 'hauler-access',
					name: 'hauler-access',
					label: localizationService.getLocalizedString('haulerPortal.settings.users.blockHaulerAccess'),
					checked: hauler.blockAccess,
					onChange: handleAccess
			  }
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={restSlice}
				restState={(state: RootState) =>
					urlService.isServicePortal() ? state.authorityUsers : state.authorityHaulerUsers
				}
				gridOption={initialGridOption}
			/>
		</>
	);
};
