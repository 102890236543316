import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService } from 'src/services';
import { BulkUpdateSteps, BulkUpdateSummary } from '@rcp/types';
import _ from 'lodash';
import { Button } from 'reactstrap';
import { AlertMessageType } from 'src/redux';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

interface Props {
	vertical?: boolean;
}

const BulkUpdateSummaryStep: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const bulkUpdateSummary: BulkUpdateSummary = stepperContext.getData(BulkUpdateSteps.STEP2_INPUT_VALUES);

	React.useEffect(() => {
		stepperContext.resolve('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onClickRestart = () => {
		let step1State = {
			...stepperContext.getStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS),
			completed: false,
			data: null
		};
		stepperContext.updateStep(BulkUpdateSteps.STEP1_CHOOSE_FIELDS, step1State);
		let step2State = {
			...stepperContext.getStep(BulkUpdateSteps.STEP2_INPUT_VALUES),
			completed: false,
			data: null
		};
		stepperContext.updateStep(BulkUpdateSteps.STEP2_INPUT_VALUES, step2State);
		let step3State = { ...stepperContext.getStep(BulkUpdateSteps.STEP3_SUMMARY), completed: false, data: null };
		stepperContext.updateStep(BulkUpdateSteps.STEP3_SUMMARY, step3State);
		stepperContext.goAt(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
	};

	const back = () => stepperContext.goAt(BulkUpdateSteps.STEP2_INPUT_VALUES);

	let updatedRows = bulkUpdateSummary.updatedRows || 0;
	let errors = bulkUpdateSummary.errors || [];
	return (
		<StepperContent
			id="step3Results"
			className="mx-4"
			actions={
				<React.Fragment>
					<div className="d-flex flex-column">
						{updatedRows < 1 && (
							<StepperAction type="button" className="btn btn-link mt-2" id="btnBack" onClick={back}>
								{localizationService.getLocalizedString('screen.buttons.back')}
							</StepperAction>
						)}
						<Button type="button" className="btn ai-secondary mt-2" onClick={onClickRestart}>
							{localizationService.getLocalizedString('bulkUpdate.startAnotherBulkUpdate')}
						</Button>
					</div>
				</React.Fragment>
			}>
			<p className="mt-n3">
				{updatedRows > 0 && (
					<div className="mt-2 d-inline-flex">
						<InlineAlertItem
							message={localizationService.getLocalizedString(
								'bulkUpdate.rowsUpdatedSuccessMessage',
								_.toString(bulkUpdateSummary.updatedRows)
							)}
							alertType={AlertMessageType.Success}
						/>
					</div>
				)}

				{errors.length > 0 && (
					<div className="mt-2 full-width d-inline-flex">
						<InlineAlertItem
							message={`${localizationService.getLocalizedString('bulkUpdate.updateErrorMessage')}`}
							alertType={AlertMessageType.Error}
						/>
					</div>
				)}
			</p>
		</StepperContent>
	);
};

export default BulkUpdateSummaryStep;
