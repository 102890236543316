import React, { Component } from 'react';
import { History } from 'history';
import * as LinkoTypes from '@rcp/types';

import { urlService, localizationService } from 'src/services';

import IppAASAuthorityInformation from './authority-information';
import IppAASAuthoritySetting from './authority-setting';
import IppAASIndustrialPretreatmentSetting from './industrial-pretreatment-setting';
import { RepudiationReasonSettings } from './repudiation-reasons';

interface Props extends LinkoTypes.RouteProps {
	history: History;
}

interface State {
	queryParameters: LinkoTypes.Dictionary<string>;
}

// Route: /ipp/authority/authority-account-setting
export class IppAuthorityAccountSetting extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		let queryParameters = urlService.toQueryDictionary();
		this.state = { queryParameters };
	}

	public render() {
		return (
			<React.Fragment>
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.authorityAccountSetting.pageHeading')}</h1>
				</div>
				<div className="page-wrapper">
					<div className="main settings">
						<div className="row">
							<div className="col-md-12">
								<IppAASAuthorityInformation />
								<IppAASAuthoritySetting />
								<IppAASIndustrialPretreatmentSetting />
								<RepudiationReasonSettings />
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
