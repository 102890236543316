import { ColumnField, FilterObjectName, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import React, { FC } from 'react';
import { GridOption } from 'src/features/data-grid/data-grid';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { localizationService, localStorageService, navigateTo, Resource, urlService } from 'src/services';
import IppReportPackageReviewsComponent from './reviews';
import { nameof } from 'ts-simple-nameof';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppPackageSubmittedPendingReviews: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getReactAuthorityResourceUrl(
			`authority/${Resource.IppReportPackages}`,
			dataItem.reportPackageId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: false,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageSubmittedPendingReviewGrid,
		filterStorageName: LocalStorageName.IppReportPackageSubmittedPendingReviewGridFilterUrl,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: localizationService.getLocalizedString(
			'ipp.reportPackage.package.reportTypes.SubmittedPendingReview'
		),
		sort:
			localStorageService.getLocalStorage(LocalStorageName.IppSubmittedPendingReviewsReportPackageSortOrder) ||
			[],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		filterOnlyThisGrid: true,
		enableFilter: true,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.PendingReview,
			page: initialPage,
			sort: 'periodEndDateTimeLocal asc, submissionDateTimeLocal desc'
		},
		showFilterMenuPopOver: true,
		disableFilterWarning: true,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE,
				FilterDomainName.ippSubmittedPendingReviewReportPackages,
				FilterObjectName.IppSubmittedPendingReviewReportPackage,
				LocalStorageName.IppReportPackageSubmittedPendingReviewGridFilterUrl,
				{ IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE: true }
			);
		},
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('ctsEventTypeName'),
			new ColumnField('ctsEventCategoryName'),
			new ColumnField('isSubmissionBySignatoryRequired', FilterType.Boolean),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('submitterUserName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField('isAdhocReportAllowed', FilterType.Boolean),
			new ColumnField('isPeriodRequired', FilterType.Boolean),
			new ColumnField('submissionAcceptanceRequired', FilterType.Boolean),
			new ColumnField('assignedTo'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'organizationReferenceNumber',
			'organizationName',
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			sortOrderLocalStorageName={LocalStorageName.IppSubmittedPendingReviewsReportPackageSortOrder}
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppPackageSubmittedPendingReviews;
