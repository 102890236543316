import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopoverModal } from 'src/components/widgets';
import { CccHazard, CccSite, CustomFieldDataType, ICustomFieldItem } from '@rcp/types';
import {
	customDefinedFieldService,
	localizationService,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import { alertService, CustomFieldDefinitionsState, RootState } from 'src/redux';
import _, { Dictionary } from 'lodash';
import { customFieldGenerator } from 'src/components/authority/fog/facilities/facility-details/custom-fields-tab/custom-field-service';
import { cccHazardSlice } from '../hazards.slice';
import { Utils } from 'src/services/utils';

interface OwnProps {
	isToggle: boolean;
	toggle: () => void;
	currentCccHazard: CccHazard;
}

type Props = OwnProps;

interface CustomFieldForm {
	customFields: ICustomFieldItem[];
}

const initialCustomFieldForm: CustomFieldForm = {
	customFields: []
};

export const CccHazardCustomFieldsModal: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [formState, setFormState] = React.useState(initialCustomFieldForm);

	let customFieldDefinitions = useSelector((state: RootState) => state.customFieldDefinitions);

	const setFormStateFromProps = (hazard: CccHazard, hazardCustomFieldDefinitions: CustomFieldDefinitionsState) => {
		if (
			!_.isEmpty(hazardCustomFieldDefinitions) &&
			!_.isEmpty(hazardCustomFieldDefinitions.customFieldDefinitions) &&
			!_.isEmpty(hazard)
		) {
			let customFields = customDefinedFieldService.toCustomFieldItems(
				hazardCustomFieldDefinitions.customFieldDefinitions,
				hazard
			);
			customFields = Utils.sortCustomField(customFields);
			setFormState({ customFields });
		}
	};

	useEffect(() => {
		if (customFieldDefinitions) {
			setFormStateFromProps(props.currentCccHazard, customFieldDefinitions);
		}
	}, [customFieldDefinitions, props.currentCccHazard]);

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		_.each(newState.customFields, customField => {
			(customField.isRequired || customField.isImportKey) &&
				validationService.validateRequiredField(customField, 'value', 'error');
			if (customField.fieldDataType && customField.fieldDataType === CustomFieldDataType.Number) {
				validationService.validateNumberField(customField, 'value', 'error');
			}
			if (customField.fieldDataType && customField.fieldDataType === CustomFieldDataType.Link) {
				validationService.validateUrlField(customField, 'value', 'error');
			}
		});
		setFormState(newState);

		let hasError = false;
		_.each(newState.customFields, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			setFormState(newState);
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};
	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.each(newState.customFields, customField => {
			if (customField.fieldName === name) {
				customField.value = customField.fieldDataType === CustomFieldDataType.Date ? value : value;
				customField.isRequired && validationService.validateRequiredField(customField, 'value', 'error');
			}
		});
		setFormState(newState);
	};
	const saveCustomFields = () => {
		if (!isFormValidateForSave()) {
			return;
		}
		let customFields: Dictionary<string> = {};
		formState.customFields.forEach(item => (customFields[UtilService.toCamelCase(item.fieldName)] = item.value));
		let siteToUpdate: CccSite = {
			customFields: customFields
		};
		dispatch(
			cccHazardSlice.patchOne(
				urlService.getCccHazardId(),
				siteToUpdate,
				undefined,
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					localizationService.getLocalizedString('cccHazard.hazard')
				),
				() => props.toggle()
			)
		);
	};

	const cancelSaveCustomFields = () => {
		setFormStateFromProps(props.currentCccHazard, customFieldDefinitions);
		props.toggle();
	};

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.isToggle}
				title="Edit Custom Fields"
				save={saveCustomFields}
				cancel={cancelSaveCustomFields}>
				{formState.customFields.map((customField: ICustomFieldItem, index: number) => {
					if (customField.fieldDataType === CustomFieldDataType.Date) {
						return customFieldGenerator.getDateInputElement(customField, index, changeFormState);
					}

					if (customField.fieldDataType === CustomFieldDataType.SingleValueDropDown) {
						return customFieldGenerator.getSingleSelectionValueElement(customField, index, changeFormState);
					}

					if (customField.fieldDataType === CustomFieldDataType.TextArea) {
						return customFieldGenerator.getTextAreaInputElement(customField, index, true, changeFormState);
					}

					return customFieldGenerator.getTextInputElement(customField, index, changeFormState);
				})}
			</PopoverModal>
		</div>
	);
};
