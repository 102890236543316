import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
	AttachmentOwnership,
	AttachmentType,
	CleaningEvent,
	Event,
	Extractor,
	FogFacility,
	Hauler,
	InspectionEvent
} from '@rcp/types';
import { DateUtilService, localizationService, urlService, navigateTo, Resource, apiService } from 'src/services';
import { alertService } from '../../../redux';
import { PopoverModal } from '..';
import HaulerSubmitCleaningModal from '../../service-provider/hauler/hauler-facilities/hauler-submit-cleaning/submit-cleaning';
import FacilitySubmitCleaningModal from '../../service-provider/facility/submit-cleaning.tsx/submit-facility-cleaning';
import { SeattleAuthorityConstants } from 'src/constants';
import { CleaningModal } from '../..';
import { EventModal } from 'src/components/authority/fog/facilities/facility-details/event/event-modal';

interface Props {
	facility: FogFacility;
	device?: Extractor;
	selectedAttachment: AttachmentOwnership;
	onEventLinkClick?: () => void;
}

export const AttachmentAttributes: React.SFC<Props> = props => {
	const [showCleaningModal, setShowCleaningModal] = useState<boolean>(false);
	const [showHaulerModal, setShowHaulerModal] = useState<boolean>(false);
	const [showGenericEventModal, setShowGenericEventModal] = useState<boolean>(false);
	const [inspection, setInspection] = useState<InspectionEvent>({});
	const [genericEvent, setGenericEvent] = useState<Event>({});
	const [hauler, setHauler] = useState<Hauler>({});
	const [uploaderName, setUploaderName] = useState<string>('');
	const [cleaningEvent, setCleaningEvent] = React.useState({} as CleaningEvent);
	const history = useHistory();
	const facilityPath = urlService.getFacilityDetailsUrlFromFacility(props.facility);
	const devicePath = props.device
		? urlService.getReactAuthorityResourceUrl(Resource.Devices, props.device.extractorId)
		: undefined;

	const toggleModal = () => {
		setShowCleaningModal(!showCleaningModal);
	};
	React.useEffect(() => {
		let url = '';
		if (props.selectedAttachment.inspectionEventId) {
			url = urlService.getFacilityResourceApiUrl(
				props.selectedAttachment.organizationId as number,
				Resource.InspectionEvents,
				props.selectedAttachment.inspectionEventId
			);
			apiService
				.getResource<InspectionEvent>(url)
				.then(resp => {
					setInspection(resp);
				})
				.catch(err => {
					alertService.addError(err.message);
				});
		}
		if (props.selectedAttachment.haulerId) {
			url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Haulers}/${props.selectedAttachment.haulerId}`;
			apiService
				.getResource<Hauler>(url)
				.then(resp => {
					setHauler(resp);
				})
				.catch(err => {
					alertService.addError(err.message);
				});
		}
		if (props.selectedAttachment.cleaningEventId) {
			const facilityId = urlService.getFogFacilityId();
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${facilityId}/${Resource.Cleanings}/${
				props.selectedAttachment.cleaningEventId
			}`;
			apiService
				.getResource<CleaningEvent>(url)
				.then(cleaning => {
					setCleaningEvent(cleaning);
				})
				.catch(err => {
					alertService.addError(err.message);
				});
		}
		if (props.selectedAttachment.genericEventId) {
			const facilityId = urlService.getFogFacilityId();
			let url = `${urlService.getFacilitiesResourceApiUrl()}/${facilityId}/${Resource.Events}/${
				props.selectedAttachment.genericEventId
			}`;
			apiService
				.getResource<Event>(url)
				.then(event => {
					setGenericEvent(event);
				})
				.catch(err => {
					alertService.addError(err.message);
				});
		}
		if (
			props.selectedAttachment.attachmentType == AttachmentType.FogDevice &&
			props.selectedAttachment.creatorOrganizationId != props.selectedAttachment.uploaderOrganizationId
		) {
			setUploaderName(props.selectedAttachment.uploaderOrganizationName);
		} else {
			setUploaderName(
				`${props.selectedAttachment.uploaderFirstName} ${props.selectedAttachment.uploaderLastName}`
			);
		}
	}, [props.selectedAttachment]);

	const getHaulerAddress = () => {
		const { addressLine1, addressLine2, addressCity, addressJurisdictionCode, addressZipCode } = hauler;
		return (
			<>
				{addressLine1 ? addressLine1 + ' ' : ''}
				{addressLine2 ? addressLine2 + ' ' : ''}
				{addressCity ? addressCity + ', ' : ''}
				{addressJurisdictionCode ? addressJurisdictionCode + ', ' : ''}
				{addressZipCode}
			</>
		);
	};

	const navigateToInspectionDetail = () => {
		const facilityPath = urlService.getCurrentFacilityDetailsUrl();
		const inspectionPath = `${facilityPath}/${Resource.Inspections}/${inspection.inspectionEventId}`;
		navigateTo(history, inspectionPath);
	};

	const checkCleaningModalType = (creatorOrganizationType: string) => {
		return (
			showCleaningModal &&
			props.selectedAttachment &&
			props.selectedAttachment.creatorOrganizationType &&
			props.selectedAttachment.creatorOrganizationType.toLowerCase() === creatorOrganizationType
		);
	};

	return (
		<>
			<section>
				<h3>{localizationService.getLocalizedString('screen.labels.facility')}</h3>
				<div>
					<a href={facilityPath} onClick={(e: any) => navigateTo(history, facilityPath, e)}>
						{props.facility.facilityName}
					</a>
					{(props.facility.addressLine1 ||
						props.facility.addressLine2 ||
						props.facility.cityName ||
						props.facility.jurisdictionCode ||
						props.facility.zipCode) && (
						<div className="attachment-address d-flex flex-row">
							<div className="d-flex flex-column">
								<div className="address-text">
									{[props.facility.addressLine1, props.facility.addressLine2].join(' ')}
								</div>
								<div className="address-text">
									{[
										props.facility.cityName,
										props.facility.jurisdictionCode,
										props.facility.zipCode
									].join(' ')}
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
			{props.device && (
				<section>
					<h3>{localizationService.getLocalizedString('pumpOut.device')}</h3>
					<a href={devicePath} onClick={(e: any) => navigateTo(history, devicePath, e)}>
						{props.device.deviceNumber}
					</a>
					<div className="d-flex flex-column">
						<div>{props.device.extractorType}</div>
						{props.device.trapCapacity && (
							<div>{[props.device.trapCapacity, props.device.trapCapacityUnitCode].join(' ')}</div>
						)}
					</div>
				</section>
			)}
			<section>
				<h3>{localizationService.getLocalizedString('attachments.details.uploadedDetails')}</h3>
				<div className="row">
					<div className="col-5 pr-0 text-right">
						<div id="uploadedOn" className="mt-1">
							{localizationService.getLocalizedString('attachments.details.uploadedOn')}:
						</div>
						<div id="uploadedBy ">
							{localizationService.getLocalizedString('attachments.details.uploadedBy')}:
						</div>
						{props.selectedAttachment &&
							props.selectedAttachment.attachmentType &&
							props.selectedAttachment.attachmentType != AttachmentType.FogDevice && (
								<>
									{props.selectedAttachment.haulerId && (
										<div className="mt-1">
											{localizationService.getLocalizedString('pumpOut.hauler')}:
										</div>
									)}
									<div id="event" className="mt-1">
										{localizationService.getLocalizedString('attachments.event')}:
									</div>
								</>
							)}
					</div>
					<div className="col-7 pl-2">
						<div id="uploadedOnDate" className="mt-1">
							{DateUtilService.toDisplayDate(props.selectedAttachment.uploadDateTimeUtc)}
						</div>
						<div id="uploadedByName">{uploaderName}</div>
						{props.selectedAttachment && props.selectedAttachment.attachmentType && (
							<>
								{props.selectedAttachment.haulerId && (
									<div className="mt-1">
										<a
											id="eventLinkHauler"
											href="#/"
											onClick={e => {
												e.preventDefault();
												setShowHaulerModal(!showHaulerModal);
											}}
											className="ai-link">
											{props.selectedAttachment.haulerName}
										</a>
									</div>
								)}
								{props.selectedAttachment.cleaningEventId && (
									<div className="mt-1">
										<a
											id="eventLinkFacility"
											href="#/"
											onClick={e => {
												e.preventDefault();
												toggleModal();
											}}
											className="ai-link">
											{localizationService.getLocalizedString('pumpOut.extractor')}{' '}
											{props.selectedAttachment.deviceNumber}{' '}
											{localizationService.getLocalizedString('pumpOut.pumpOut')}
										</a>
									</div>
								)}
								{props.selectedAttachment.inspectionEventId && (
									<div className="mt-1">
										<a
											id="eventLinkInspection"
											href="#/"
											onClick={e => {
												e.preventDefault();
												navigateToInspectionDetail();
												if (props.onEventLinkClick) {
													props.onEventLinkClick();
												}
											}}
											className="ai-link">
											{props.selectedAttachment.inspectionType}
											&nbsp;
											{localizationService.getLocalizedString('inspectionForm.inspection')}
										</a>
									</div>
								)}
								{props.selectedAttachment.genericEventId && (
									<div className="mt-1">
										<a
											id="eventLinkGenericEvent"
											href="#/"
											onClick={e => {
												e.preventDefault();
												setShowGenericEventModal(!showGenericEventModal);
											}}
											className="ai-link">
											{props.selectedAttachment.genericEventTypeName}
										</a>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</section>
			<section>
				<h3>{localizationService.getLocalizedString('attachments.details.fileType')}</h3>
				<div>{props.selectedAttachment.fileType}</div>
			</section>
			{showGenericEventModal && (
				<EventModal
					showModal={showGenericEventModal}
					eventId={genericEvent.genericEventId}
					facilityId={genericEvent.facilityId}
					eventToEdit={genericEvent}
					onCancel={() => setShowGenericEventModal(false)}
					isFacilityDetailPage={true}
				/>
			)}
			{showHaulerModal && (
				<PopoverModal
					showModal={showHaulerModal}
					title={localizationService.getLocalizedString('haulers.hauler')}
					footer={<></>}
					cancel={() => {
						setShowHaulerModal(!showHaulerModal);
					}}>
					<p>
						{hauler.name}
						<br />
						{getHaulerAddress()}
					</p>
				</PopoverModal>
			)}
			{checkCleaningModalType('hauler') && (
				<HaulerSubmitCleaningModal
					showModal={showCleaningModal}
					onCancel={toggleModal}
					selectedDevices={[]}
					deviceList={[]}
					cleaningEventId={props.selectedAttachment.cleaningEventId}
					facilityId={props.selectedAttachment.organizationId}
					isFacilityDetailsPage={false}
					readOnly={true}
					hideCancel={true}
					authorityOrganizationId={props.facility.authorityId}
					title={localizationService.getLocalizedString('haulerPortal.cleaningHistory.submittedCleaning')}
					hideDelete={true}
					isSeattleFacility={
						props.facility && props.facility.authorityName
							? props.facility.authorityName.toLowerCase() ===
							  SeattleAuthorityConstants.authorityName.toLowerCase()
							: false
					}
				/>
			)}
			{checkCleaningModalType('facility') && (
				<FacilitySubmitCleaningModal
					showModal={showCleaningModal}
					title={localizationService.getLocalizedString('haulerPortal.submitCleaning.submitCleaning')}
					onCancel={toggleModal}
					facility={props.facility}
					facilityId={props.facility.facilityId}
					readOnly={true}
					cleaningEventId={props.selectedAttachment.cleaningEventId}
				/>
			)}
			{props.selectedAttachment.cleaningEventId && checkCleaningModalType('authority') && (
				<CleaningModal
					facilityId={cleaningEvent.organizationId as number}
					cleaning={cleaningEvent}
					isFacilityPage={true}
					isEnterCleaning={false}
					dueDateIsRequired={cleaningEvent.completeDate ? false : true}
					completeDateIsRequired={cleaningEvent.completeDate ? true : false}
					modalToggleFunction={toggleModal}
				/>
			)}
		</>
	);
};
