import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { localizationService } from 'src/services';

export interface InspectionResultsState {
	inspectionResults: string[];
}

export const initialInspectionResultsState: InspectionResultsState = {
	inspectionResults: []
};

enum ActionType {
	LoadInspectionResultsSuccess = 'loadInspectionResultsSuccess'
}

export type InspectionResultsThunkAction = ThunkAction<any, InspectionResultsState, any, Action>;

export const loadInspectionResults = (): InspectionResultsThunkAction => async (dispatch: any) => {
	let inspectionResults = [
		localizationService.getLocalizedString('constants.pass'),
		localizationService.getLocalizedString('constants.fail')
	];
	dispatch({ type: ActionType.LoadInspectionResultsSuccess, inspectionResults });
};

export const inspectionResultsReducer = (
	state = initialInspectionResultsState,
	action: any
): InspectionResultsState => {
	switch (action.type) {
		case ActionType.LoadInspectionResultsSuccess:
			return { ...state, inspectionResults: action.inspectionResults };
		default:
			return state;
	}
};
