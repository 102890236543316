import { RegisterAction, RegisterActionType } from "./register-actions";
import { initialRegisterState, RegisterState } from "./register-state";

export const RegisterReducer = (state = initialRegisterState, action: RegisterAction): RegisterState => {
	switch (action.type) {
		case RegisterActionType.LoadInvitationDetailsRequest:
		case RegisterActionType.LoadQuestionsRequest:
		case RegisterActionType.LoadStatesRequest:
			return { ...state };
		case RegisterActionType.LoadInvitationDetailsSuccess:
			return { ...state, invitationDetails: action.invitationDetails }
		case RegisterActionType.LoadQuestionsSuccess:
			return { ...state, questions: action.questions }
		case RegisterActionType.LoadStatesSuccess:
			return { ...state, states: action.states }
		default:
			return state;
	}
};
