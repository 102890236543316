import React from 'react';
import { RiskCircleSvg } from 'src/components/svg/risk-circle.svg';
import { MapPinBgColor, MapPinFillColor } from '../map-service';

export const FacilityPinInCustom = () => {
	return <RiskCircleSvg fillColor={MapPinFillColor.Light} backgroundColor={MapPinBgColor.Custom} />;
};

export const FacilityPinInGreen = () => {
	return <RiskCircleSvg fillColor={MapPinFillColor.Light} backgroundColor={MapPinBgColor.Green} />;
};

export const FacilityPinInYellow = () => {
	return <RiskCircleSvg fillColor={MapPinFillColor.Dark} backgroundColor={MapPinBgColor.Yellow} />;
};

export const FacilityPinInRed = () => {
	return <RiskCircleSvg fillColor={MapPinFillColor.Light} backgroundColor={MapPinBgColor.Red} />;
};
