import { RestSlice } from '../../redux/rest.slice';
import { CccSiteContact, Contact, Lookup } from '@rcp/types';
import { Resource, localizationService } from 'src/services';

export const contactsSlice = new RestSlice<Contact>(
	localizationService.getLocalizedString('screen.labels.contacts'),
	Resource.Contacts
);
export const contactsSliceReducer = contactsSlice.rests.reducer;

export const settingLookupsContactTypesSlice = new RestSlice<Lookup>(
	localizationService.getLocalizedString('authoritySetting.contactType'),
	Resource.SettingLookup + '/ContactTypes'
);
export const settingLookupsContactTypesReducer = settingLookupsContactTypesSlice.rests.reducer;

export const cccSiteContactsSlice = new RestSlice<CccSiteContact>(
	localizationService.getLocalizedString('cccSite.siteContacts'),
	Resource.CccLinkedContacts
);
export const cccSiteContactsReducer = cccSiteContactsSlice.rests.reducer;

export const cccHazardContactsSlice = new RestSlice<CccSiteContact>(
	localizationService.getLocalizedString('cccSite.siteContacts'),
	Resource.CccLinkedContacts
);
export const cccHazardContactsReducer = cccHazardContactsSlice.rests.reducer;
