import React from 'react';

import * as ApiTypes from '@rcp/types';
import { CleaningModal } from '../../components/authority/fog/cleaning';
import { getDateContent } from './timeline-helper';
import { apiService, DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { useHistory } from 'react-router';

interface OwnProps {
	cleaning: ApiTypes.CleaningEvent;
	isFacilityDetailsPage?: boolean;
	isDeviceDetailsPage?: boolean;
	callBackAfterSave?: () => void;
	callBackAfterDelete?: () => void;
}
type Props = OwnProps;

export const TimelineEventCleaning: React.SFC<Props> = props => {
	const [showCleaningModal, setShowCleaningModal] = React.useState(false);
	const [device, setDevice] = React.useState({} as ApiTypes.Extractor);
	const history = useHistory();
	const deviceUrl = urlService.getReactAuthorityResourceUrl(
		Resource.Devices,
		props.cleaning.deviceId || props.cleaning.deviceId
	);

	const onPumpOutDetailsClicked = (e: any) => {
		e.preventDefault();
		setShowCleaningModal(true);
	};

	const toggleModal = () => {
		setShowCleaningModal(!showCleaningModal);
	};

	React.useEffect(() => {
		if (showCleaningModal && props.isDeviceDetailsPage) {
			let deviceId = props.cleaning.deviceId;

			let url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Devices}/${deviceId}`;
			apiService.getResource<ApiTypes.Extractor>(url).then(device => {
				setDevice(device);
			});
		}
	}, [props.isDeviceDetailsPage, showCleaningModal]);

	return (
		<div>
			<div className="pointer-cursor">
				{props.isFacilityDetailsPage && props.cleaning ? (
					<>
						<a
							href="#/"
							onClick={onPumpOutDetailsClicked}
							id={props.cleaning.completeDate ? `completeTimelineCleaning` : 'timelineCleaning'}>
							{localizationService.getLocalizedString('pumpOut.cleaning')}
						</a>
						{props.cleaning.deviceNumber ? (
							<>
								{localizationService.getLocalizedString('facility.cleaningTimelineConnector')}
								{props.cleaning.isDeviceRemoved ? (
									<>
										<span>
											{localizationService.getLocalizedString(
												'facility.deviceDetailsTimelineRef',
												props.cleaning.deviceNumber as string
											)}
										</span>
										<span className="text-muted">
											{localizationService.getLocalizedString('facility.deviceRemoved')}
										</span>
									</>
								) : (
									<a
										href={deviceUrl}
										id={`timelineCleaningDevice-${props.cleaning.deviceNumber}`}
										className="ai-link"
										onClick={(e: any) => navigateTo(history, deviceUrl, e)}>
										{localizationService.getLocalizedString(
											'facility.deviceDetailsTimelineRef',
											props.cleaning.deviceNumber as string
										)}
									</a>
								)}
							</>
						) : null}
						{getDateContent(
							DateUtilService.getAuthorityTimezoneNow(),
							'',
							props.cleaning.completeDate,
							props.cleaning.dueDate
						)}
					</>
				) : (
					<>
						<a
							href="#/"
							onClick={onPumpOutDetailsClicked}
							id={props.cleaning.completeDate ? `completeTimelineCleaning` : 'timelineCleaning'}>
							{localizationService.getLocalizedString('pumpOut.cleaning')}
						</a>
						{getDateContent(
							DateUtilService.getAuthorityTimezoneNow(),
							'',
							props.cleaning.completeDate,
							props.cleaning.dueDate
						)}
					</>
				)}
			</div>
			{showCleaningModal && props.isFacilityDetailsPage && (
				/*
					Currently the facility details page has a separate way of loading it's timeline
					which is documented and handled in the cleaning modal component
				*/
				<CleaningModal
					facilityId={props.cleaning.organizationId as number}
					cleaning={props.cleaning}
					isFacilityPage={props.isFacilityDetailsPage}
					isEnterCleaning={false}
					dueDateIsRequired={props.cleaning.completeDate ? false : true}
					completeDateIsRequired={props.cleaning.completeDate ? true : false}
					modalToggleFunction={toggleModal}
				/>
			)}
			{showCleaningModal && props.isDeviceDetailsPage && device.extractorId && (
				<CleaningModal
					facilityId={props.cleaning.organizationId as number}
					cleaning={props.cleaning}
					isDevicePage={props.isDeviceDetailsPage}
					device={device}
					isTimelineReloadRequired={true}
					isEnterCleaning={false}
					dueDateIsRequired={props.cleaning.completeDate ? false : true}
					completeDateIsRequired={props.cleaning.completeDate ? true : false}
					modalToggleFunction={toggleModal}
				/>
			)}
		</div>
	);
};
