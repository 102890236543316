import React, { Component, createRef, RefObject } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import _ from 'lodash';
import * as LinkoTypes from '@rcp/types';
import { DateUtilService, localizationService } from 'src/services';
import './available-selected-grids.scss';
import { SearchInput, SingleCheckbox } from 'src/components/widgets';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';

interface GridsData<T> {
	available: T[];
	selected: T[];
}

interface Props<T> {
	onChange?: (data: GridsData<T>) => void;
	columns: string[];
	noRecordMsg?: { available: string; selected: string };
	available: T[];
	selected: T[];
	resourceKey: string;
	isDraggable: boolean;
	id: string;
	availableSortable?: boolean;
	selectedSortable?: boolean;
	showSearch?: boolean;
	showAddAll?: boolean;
	showRemoveAll?: boolean;
	showActiveTemplatesToggle?: boolean;
}

interface State<T> {
	activeItem: any;
	active: T;
	availableSort: SortDescriptor[];
	selectedSort: SortDescriptor[];
	availableGridData: T[];
	selectedGridData: T[];
	sort: boolean;
	search: boolean;
	availableSearchTerm: string;
	selectedSearchTerm: string;
	addedAll: boolean;
	removedAll: boolean;
	add: boolean;
	remove: boolean;
	drag: boolean;
	checkboxUpdate: boolean;
	toggleAvailableActiveTemplates: boolean;
	toggleSelectedActiveTemplates: boolean;
}

let initialRender = true;

class AvailableSelectedGrids<T> extends Component<Props<T>, State<T>> {
	private gridAvailableRef: RefObject<Grid>;
	private gridSelectedRef: RefObject<Grid>;
	private activeItem: T;
	constructor(props: Props<T>) {
		super(props);
		this.gridAvailableRef = createRef();
		this.gridSelectedRef = createRef();
		this.state = {
			availableGridData: [] as T[],
			selectedGridData: [] as T[],
			activeItem: null as any,
			active: {} as T,
			availableSort: [{ field: this.props.columns[0], dir: 'asc' }],
			selectedSort: [{ field: this.props.columns[0], dir: 'asc' }],
			sort: true,
			search: false,
			availableSearchTerm: '',
			selectedSearchTerm: '',
			addedAll: false,
			removedAll: false,
			add: false,
			remove: false,
			drag: false,
			checkboxUpdate: false,
			toggleAvailableActiveTemplates: true,
			toggleSelectedActiveTemplates: true
		};
		this.reorder = this.reorder.bind(this);
		this.dragStart = this.dragStart.bind(this);
		this.activeItem = {} as T;
	}

	static getDerivedStateFromProps(props: any, state: any) {
		if (
			!state.search &&
			!state.drag &&
			(initialRender || (!state.toggleAvailableActiveTemplates && !state.toggleSelectedActiveTemplates))
		) {
			if (state.availableSearchTerm && !state.selectedSearchTerm) {
				return {
					selectedGridData: [...props.selected]
				};
			} else if (state.selectedSearchTerm && !state.availableSearchTerm) {
				return {
					availableGridData: [...props.available]
				};
			} else if (state.availableSearchTerm && state.selectedSearchTerm) {
				return state;
			}
			if (props.id === 'templates' && initialRender) {
				return {
					availableGridData: [...props.available.filter((template: any) => template.isActive)],
					selectedGridData: [...props.selected.filter((template: any) => template.isActive)]
				};
			}
			return {
				availableGridData: [...props.available],
				selectedGridData: [...props.selected]
			};
		} else {
			return {
				...state,
				search: false
			};
		}
	}

	componentDidMount() {
		initialRender = true;
	}

	componentDidUpdate(prevProps: Props<T>) {
		if (
			this.state.addedAll ||
			this.state.removedAll ||
			this.state.add ||
			this.state.remove ||
			this.state.checkboxUpdate ||
			this.state.sort ||
			prevProps.selected !== this.props.selected
		) {
			this.onSearch(this.state.availableSearchTerm, 'available');
			this.onSearch(this.state.selectedSearchTerm, 'selected');
			if (this.props.showActiveTemplatesToggle) {
				this.changeTemplateData(false, 'available');
				this.changeTemplateData(false, 'selected');
			}
		}
		if (this.state.sort) {
			this.setState({ sort: false });
		}

		if (this.state.addedAll) {
			this.setState({ addedAll: false });
		}
		if (this.state.removedAll) {
			this.setState({ removedAll: false });
		}

		if (this.state.add) {
			this.setState({ add: false });
		}

		if (this.state.remove) {
			this.setState({ remove: false });
		}
		if (this.state.checkboxUpdate) {
			this.setState({ checkboxUpdate: false });
		}
		if (this.state.drag) {
			this.props.onChange &&
				this.props.onChange({
					available: this.state.availableGridData,
					selected: this.state.selectedGridData
				});
			this.setState({ drag: false });
		}
	}

	//Drag
	//Note:- Drag and search can not come together
	reorder(dataItem: T, action: string) {
		if (this.state.activeItem === dataItem) {
			return;
		}
		let gridData = action === 'minus' ? this.state.selectedGridData : this.state.availableGridData;
		let reorderedData = gridData.slice();
		let prevIndex = reorderedData.findIndex((item: T) => item === this.activeItem);
		let nextIndex = reorderedData.findIndex((item: T) => item === dataItem);
		//Removing 1 item from prevIndex and adding it to the nextIndex
		reorderedData.splice(prevIndex, 1);
		reorderedData.splice(nextIndex, 0, this.activeItem);
		this.setState(prevState => ({
			active: prevState.activeItem,
			selectedGridData: action === 'minus' ? reorderedData : prevState.selectedGridData,
			availableGridData: action === 'plus' ? reorderedData : prevState.availableGridData,
			drag: true
		}));
	}

	dragStart(dataItem: T, action: string) {
		this.activeItem = dataItem;
	}

	getDragCell = (props: any, col: LinkoTypes.ColumnDefinition[]) => {
		let action = col[col.length - 1].field;
		let dataItem = props.dataItem;
		return (
			<td
				id={this.props.id + '-td'}
				onDragOver={e => {
					e.preventDefault();
					e.dataTransfer.dropEffect = 'copy';
				}}
				onDragStart={e => {
					e.currentTarget.classList.add('dragging-item');
				}}
				onDrop={e => {
					if (e.dataTransfer.types[0] === this.props.id) {
						this.reorder(dataItem, action);
					}
				}}
				onDragLeave={e => {
					e.currentTarget.classList.remove('dragging-item');
				}}>
				<span
					id={this.props.id + '-drag'}
					className="k-icon k-i-reorder"
					draggable="true"
					style={{ cursor: 'move' }}
					onDragStart={e => {
						this.dragStart(dataItem, action);
						e.dataTransfer.setData(this.props.id, '');
					}}
				/>
			</td>
		);
	};

	localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td className="align-center">{localizationService.formatValue(fieldValue)}</td>;
	};
	getActionButton = (props: any) => {
		return (
			<>
				<td className="align-center pr-0">
					<button
						type="button"
						className={`btn ${props.field === 'plus' ? 'ai-action' : 'ai-delete'}`}
						onClick={() => this.onAddOrRemove(props)}>
						<span className={`k-icon k-i-${props.field}`} />
					</button>
				</td>
			</>
		);
	};

	getCheckBox = (element: any) => {
		return (
			<td>
				<SingleCheckbox
					id={`${this.props.id}-required-${element.dataItem.reportElementTypeId}`}
					name="required"
					checked={element.dataItem.isRequiredInTemplate}
					className="d-flex align-self-center justify-content-center"
					onChange={e => this.onRequiredUpdate(e, element)}
				/>
			</td>
		);
	};

	onRequiredUpdate = (e: any, props: any) => {
		let updatedDataItem = { ...props.dataItem, isRequiredInTemplate: e.target.checked ? true : false };
		var index = _.findIndex(this.props.selected, props.dataItem);
		let gridData = [...this.props.selected];

		gridData.splice(index, 1, updatedDataItem);
		this.props.onChange &&
			this.props.onChange({
				available: this.props.available,
				selected: gridData
			});

		this.setState({
			checkboxUpdate: true
		});
	};

	onAddOrRemove = (props: any) => {
		let available: T[];
		let selected: T[];
		if (props.field === 'plus') {
			available = _.differenceWith(this.props.available, [props.dataItem], _.isEqual);
			selected = [...this.props.selected, { ...props.dataItem }];
		} else {
			selected = _.differenceWith(this.props.selected, [props.dataItem], _.isEqual);
			available = [...this.props.available, { ...props.dataItem }];
			available = this.getSortedList(available, 'available');
		}
		this.props.onChange && this.props.onChange({ available, selected });
		if (props.field === 'plus') {
			this.setState({ add: true });
		}
		if (props.field === 'minus') {
			this.setState({ remove: true });
		}
	};

	filterActiveTemplates = (isButtonClicked: boolean, type: string, gridData: T[]) => {
		let showActive: boolean;
		if (isButtonClicked) {
			showActive =
				type === 'selected'
					? !this.state.toggleSelectedActiveTemplates
					: !this.state.toggleAvailableActiveTemplates;
		} else {
			showActive =
				type === 'selected'
					? this.state.toggleSelectedActiveTemplates
					: this.state.toggleAvailableActiveTemplates;
		}
		if (showActive) {
			gridData = gridData.filter((template: LinkoTypes.IppReportPackageTemplate) => {
				return template.isActive;
			});
		}
		return gridData;
	};

	changeTemplateData = (isButtonClicked: boolean, type: string) => {
		initialRender = false;
		let templates = type === 'selected' ? [...this.props.selected] : [...this.props.available];
		templates = this.filterActiveTemplates(isButtonClicked, type, templates);
		let searchTerm = type === 'selected' ? this.state.selectedSearchTerm : this.state.availableSearchTerm;
		templates = this.searchGrid(searchTerm, templates);
		templates = this.getSortedList(templates, type);
		if (type === 'selected') {
			this.setState(prevState => {
				return {
					...prevState,
					selectedGridData: [...templates],
					toggleSelectedActiveTemplates: isButtonClicked
						? !prevState.toggleSelectedActiveTemplates
						: prevState.toggleSelectedActiveTemplates
				};
			});
		} else {
			this.setState(prevState => {
				return {
					...prevState,
					availableGridData: [...templates],
					toggleAvailableActiveTemplates: isButtonClicked
						? !prevState.toggleAvailableActiveTemplates
						: prevState.toggleAvailableActiveTemplates
				};
			});
		}
	};

	getSortedList = (data: any[], gridType: string) => {
		let activeSortField =
			gridType === 'selected' ? this.state.selectedSort[0].field : this.state.availableSort[0].field;
		let activeSortOrder =
			(gridType === 'selected' ? this.state.selectedSort[0].dir : this.state.availableSort[0].dir) || false;
		return [
			..._.orderBy(
				data,
				[
					(item: any) =>
						typeof item[activeSortField] === 'string'
							? item[activeSortField].toLowerCase()
							: item[activeSortField]
				],
				[activeSortOrder]
			)
		];
	};

	addAll = () => {
		let selected: T[] = [...this.props.selected, ...this.state.availableGridData];
		selected = this.getSortedList(selected, 'selected');
		this.props.onChange &&
			this.props.onChange({
				available: _.differenceWith(this.props.available, this.state.availableGridData, _.isEqual),
				selected
			});
		this.setState({
			addedAll: true
		});
	};

	removeAll = () => {
		let available: T[] = [...this.props.available, ...this.state.selectedGridData];
		available = this.getSortedList(available, 'available');
		this.props.onChange &&
			this.props.onChange({
				available,
				selected: _.differenceWith(this.props.selected, this.state.selectedGridData, _.isEqual)
			});
		this.setState({
			removedAll: true
		});
	};

	searchGrid = (searchValue: string, gridData: T[]) => {
		let searchTerm = searchValue;
		const isContains = (searchTerm: string, searchString?: any) => {
			return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
		};
		gridData = gridData.filter(template => {
			for (let key in template) {
				let searchString = template[key] + '';
				if (isContains(searchTerm.trim(), searchString)) {
					return true;
				}
			}
			return false;
		});
		return gridData;
	};

	onSearch = (searchTerm: string, type: string) => {
		let gridData = type === 'selected' ? [...this.props.selected] : [...this.props.available];
		gridData = this.searchGrid(searchTerm, gridData);
		if (this.props.showActiveTemplatesToggle) {
			gridData = this.filterActiveTemplates(false, type, gridData);
		}
		if (type === 'selected') {
			this.setState({ selectedGridData: gridData, search: true, selectedSearchTerm: searchTerm });
		} else {
			this.setState({ availableGridData: gridData, search: true, availableSearchTerm: searchTerm });
		}
	};

	formatDateTime = (rowItems: any) => {
		let dateTime = _.get(rowItems.dataItem, rowItems.field);
		let displayDate = DateUtilService.toDisplayDate(dateTime);
		return <td className="align-center">{displayDate}</td>;
	};

	generateColumns = (columnDefinitions: LinkoTypes.ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: LinkoTypes.ColumnDefinition) => i.visible)
			.map((i: LinkoTypes.ColumnDefinition) => {
				if (this.props.isDraggable && i.field === 'draggable') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							className="align-center"
							cell={props => this.getDragCell(props, columnDefinitions)}
						/>
					);
				}
				if (i.field === 'plus' || i.field === 'minus') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={this.getActionButton}
							headerClassName="align-center plus-minus-header-width"
						/>
					);
				}
				if (i.field === 'isRequiredInTemplate') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field="required"
							title={localizationService.getLocalizedString(`${this.props.resourceKey}.${i.field}`)}
							className="align-center"
							cell={this.getCheckBox}
							headerClassName="align-center"
						/>
					);
				}
				if (i.field.includes('DateTime')) {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={this.formatDateTime}
							headerClassName="align-center"
						/>
					);
				}
				return (
					<GridColumn
						key={`column_key${i.field}`}
						field={i.field}
						title={i.title}
						cell={this.localizeCellValue}
						headerClassName="align-center"
					/>
				);
			});
	};

	getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as LinkoTypes.ColumnDefinition;
		});
	};

	getColumnDefinitions = (action: string): LinkoTypes.ColumnDefinition[] => {
		let keys: string[] = [...this.props.columns, action];
		action === 'minus' && this.props.isDraggable && keys.unshift('draggable');
		let defaultColumnsDefinitions = this.getDefaultShowingColumnDefinitions(keys);
		defaultColumnsDefinitions.forEach(item => {
			if (item.field !== 'minus' && item.field !== 'plus' && item.field !== 'draggable') {
				item.title = localizationService.getLocalizedString(`${this.props.resourceKey}.${item.field}`);
			}
			if (action === 'plus' && item.field === 'isRequiredInTemplate') {
				item.visible = false;
			}

			if (item.field === 'minus' || item.field === 'plus') {
				item.title = localizationService.getLocalizedString(`ipp.availableSelectedGrids.${item.field}`);
			}
			if (item.field === 'draggable') {
				item.title = localizationService.getLocalizedString(`ipp.availableSelectedGrids.${item.field}`);
			}
		});

		return defaultColumnsDefinitions;
	};

	sortChange = (event: any, type: string) => {
		let sortedData = [...this.getSortedData(event.sort, type)];
		if (type === 'selected') {
			this.props.onChange &&
				this.props.onChange({
					available: this.props.available,
					selected: sortedData
				});
			this.setState({
				selectedSort: event.sort,
				sort: true
			});
		} else {
			this.props.onChange &&
				this.props.onChange({
					available: sortedData,
					selected: this.props.selected
				});
			this.setState({
				availableSort: event.sort,
				sort: true
			});
		}
	};

	getSortedData = (sort: SortDescriptor[], type: string) => {
		return orderBy(type === 'selected' ? [...this.props.selected] : [...this.props.available], sort);
	};

	render() {
		let availableColumnsDefinitions = this.getColumnDefinitions('plus');
		let selectedColumnsDefinitions = this.getColumnDefinitions('minus');
		let availableColumns = this.generateColumns(availableColumnsDefinitions);
		let selectedColumns = this.generateColumns(selectedColumnsDefinitions);
		let availableData = this.state.availableGridData;
		let selectedData = this.state.selectedGridData;
		const noRecordAvailableMsg = this.props.noRecordMsg ? this.props.noRecordMsg.available : undefined;
		const noRecordSelectedMsg = this.props.noRecordMsg ? this.props.noRecordMsg.selected : undefined;
		return (
			<>
				<div className="row">
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-4">
								<h3>
									{localizationService.getLocalizedString(`ipp.availableSelectedGrids.available`)}
								</h3>
							</div>
							<div className="col-md-8 mb-2">
								<div id="add-all" className="d-flex ml-auto">
									{this.props.showActiveTemplatesToggle ? (
										<button
											className="btn ai-action ml-auto"
											name="toggleAvailableActiveTemplates"
											onClick={() => this.changeTemplateData(true, 'available')}>
											{localizationService.getLocalizedString(
												`ipp.availableSelectedGrids.${
													this.state.toggleAvailableActiveTemplates ? 'showAll' : 'showActive'
												}`
											)}
										</button>
									) : (
										''
									)}
									{this.props.showAddAll ? (
										<button
											className={`btn ai-action pl-4 pr-4 ${
												this.props.showActiveTemplatesToggle ? 'ml-1' : 'ml-auto'
											}`}
											onClick={this.addAll}>
											{localizationService.getLocalizedString(
												`ipp.availableSelectedGrids.addAll`
											)}
										</button>
									) : (
										''
									)}
								</div>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-12 text-right">
								{this.props.showSearch ? (
									<SearchInput
										inputId={this.props.id + '-available-search-input'}
										search={searchTerm => this.onSearch(searchTerm, 'available')}
										searchClass="d-flex ml-auto w-100"
									/>
								) : (
									''
								)}
							</div>
						</div>
						<div className="table-responsive">
							<Grid
								className="table"
								ref={this.gridAvailableRef}
								scrollable="none"
								data={availableData}
								sortable={
									this.props.availableSortable
										? {
												allowUnsort: false,
												mode: 'single'
										  }
										: false
								}
								{...(this.props.availableSortable && { sort: this.state.availableSort })}
								onSortChange={event => this.sortChange(event, 'available')}>
								{!_.isUndefined(noRecordAvailableMsg) && (
									<GridNoRecords>{noRecordAvailableMsg}</GridNoRecords>
								)}
								{availableColumns}
							</Grid>
						</div>
					</div>
					<div className="col-md-6">
						<div className="row">
							<div className="col-md-4">
								<h3>
									{localizationService.getLocalizedString(
										`ipp.availableSelectedGrids.${
											this.props.showActiveTemplatesToggle ? 'assigned' : 'selected'
										}`
									)}
								</h3>
							</div>
							<div className="col-md-8 mb-2">
								<div id="remove-all" className="d-flex ml-auto">
									{this.props.showActiveTemplatesToggle ? (
										<button
											className="btn ai-action ml-auto"
											name="toggleSelectedActiveTemplates"
											onClick={() => this.changeTemplateData(true, 'selected')}>
											{localizationService.getLocalizedString(
												`ipp.availableSelectedGrids.${
													this.state.toggleSelectedActiveTemplates ? 'showAll' : 'showActive'
												}`
											)}
										</button>
									) : (
										''
									)}
									{this.props.showRemoveAll ? (
										<button
											className={`btn ai-action ai-delete ${
												this.props.showActiveTemplatesToggle ? 'ml-1' : 'ml-auto'
											}`}
											onClick={this.removeAll}>
											{localizationService.getLocalizedString(
												`ipp.availableSelectedGrids.removeAll`
											)}
										</button>
									) : (
										''
									)}
								</div>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col-md-12 text-right">
								{this.props.showSearch ? (
									<SearchInput
										inputId={this.props.id + '-selected-search-input'}
										search={searchTerm => this.onSearch(searchTerm, 'selected')}
										searchClass="d-flex ml-auto w-100"
									/>
								) : (
									''
								)}
							</div>
						</div>
						<div className="table-responsive">
							<Grid
								className="table"
								ref={this.gridSelectedRef}
								scrollable="none"
								data={selectedData}
								sortable={
									this.props.selectedSortable
										? {
												allowUnsort: false,
												mode: 'single'
										  }
										: false
								}
								{...(this.props.selectedSortable && { sort: this.state.selectedSort })}
								onSortChange={event => this.sortChange(event, 'selected')}>
								{!_.isUndefined(noRecordSelectedMsg) && (
									<GridNoRecords>{noRecordSelectedMsg}</GridNoRecords>
								)}
								{selectedColumns}
							</Grid>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default AvailableSelectedGrids;
