import { apiService, ApiService, urlService } from './index';
import { PaginatedResult } from '@rcp/types';

export class RestApi<T> extends ApiService {
	private readonly path: string;
	constructor(protected _path: string) {
		super();
		this.path = _path;
	}

	getUrl = (isAdminPath: boolean = false): string => {
		if (!isAdminPath) {
			return `${urlService.getApiBaseUrlWithProgram()}/${this.path}`;
		} else {
			return `${urlService.getApiAdminBaseUrl()}/${this.path}`;
		}
	};

	getAll = async (params?: string, isAdminPath: boolean = false): Promise<PaginatedResult<T>> => {
		let url = params ? `${this.getUrl(isAdminPath)}?${params}` : this.getUrl(isAdminPath);
		const result = apiService.getResource(url);
		return result as Promise<PaginatedResult<T>>;
	};

	getOne = async (id: number | string): Promise<T> => {
		const getOneUrl = `${this.getUrl()}/${id}`;
		const result = apiService.getResource(getOneUrl);
		return result as Promise<T>;
	};

	createOne = async (body: any, params?: string): Promise<T> => {
		let url = params ? `${this.getUrl()}?${params}` : this.getUrl();
		const result = apiService.httpPost(url, { items: [body] });
		return result as Promise<T>;
	};

	patchOne = async (id: number | string, body: any, params?: string): Promise<T> => {
		let url = params ? `${this.getUrl()}/${id}?${params}` : `${this.getUrl()}/${id}`;
		const result = apiService.httpPatch(url, body);
		return result as Promise<T>;
	};

	putOne = async (id: number | string, body: any, params?: string): Promise<T> => {
		let url = params ? `${this.getUrl()}/${id}?${params}` : `${this.getUrl()}/${id}`;
		const result = apiService.httpPut(url, body);
		return result as Promise<T>;
	};

	deleteOne = async (id: number | string, params?: string): Promise<T> => {
		let url = params ? `${this.getUrl()}/${id}?${params}` : `${this.getUrl()}/${id}`;
		const result = apiService.httpDelete(url);
		return result as Promise<T>;
	};

	deleteAll = async (params: string, returnData: boolean = false): Promise<T> => {
		const url = `${this.getUrl()}?${params}`;
		const result = await apiService.httpDelete(url, undefined, returnData);
		return result as Promise<T>;
	};
}
