import React from 'react';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { localizationService } from 'src/services';

interface actionProp {
	clickOnDeleteSite: () => void;
}

export const SiteActionDropdown: React.SFC<actionProp> = props => {
	return (
		<UncontrolledDropdown className="action-item-black" id="actionDropdown">
			<DropdownToggle tag="a" className="btn" caret>
				{localizationService.getLocalizedString('screen.labels.action')}
			</DropdownToggle>
			<DropdownMenu>
				<DropdownItem tag="a" id="deleteSiteDropdownOption" onClick={props.clickOnDeleteSite}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</DropdownItem>
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};
