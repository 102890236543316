import React from 'react';

import './home.scss';

type Props = {
	location: any;
};

export const Home: React.SFC<Props> = props => {
	return <div className="page"></div>;
};
