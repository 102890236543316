import React from 'react';
import { SingleCheckbox } from '../../components/widgets';
import { localizationService } from 'src/services';

import './timeline.scss';

import { TimelineSettings, TimelineFilterByEventTypeEnum } from './timeline-type';
import { CloseSvg } from '../../components/svg/close-svg';

interface OwnProps {
	timelineFilterByForm: TimelineSettings;
	filterRuleChanged: (filterBy: TimelineFilterByEventTypeEnum) => void;
	closeModal: () => void;
}

type filterActionMenuProp = OwnProps;

export const TimelineFilterActionMenu: React.SFC<filterActionMenuProp> = props => {
	return (
		<div className="dropdown-menu timeline-filter-action-menu-div" aria-labelledby="filterBtn">
			<button
				className="close-dropdown flex-row-reverse"
				aria-label="Close and cancel any changes"
				onClick={props.closeModal}>
				<CloseSvg />
			</button>
			<form>
				<SingleCheckbox
					id="filterByCommentsCbx"
					name="filterByCommentsCbx"
					label={localizationService.getLocalizedString('screen.timeline.comments')}
					checked={!props.timelineFilterByForm.excludeList.includes('comments')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.comments)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByTestsCbx"
					name="filterByTestsCbx"
					label={localizationService.getLocalizedString('screen.timeline.tests')}
					checked={!props.timelineFilterByForm.excludeList.includes('tests')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.tests)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterBySentNoticesCbx"
					name="filterBySentNoticesCbx"
					label={localizationService.getLocalizedString('screen.timeline.sentNotices')}
					checked={!props.timelineFilterByForm.excludeList.includes('sentNotices')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentNotices)}
					className="dropdown-item"
				/>

				<SingleCheckbox
					id="filterByGeneralNoticeCbx"
					name="filterByGeneralNoticeCbx"
					label={localizationService.getLocalizedString('screen.labels.generalNotices')}
					checked={!props.timelineFilterByForm.excludeList.includes('sentGeneralNotices')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentGeneralNotices)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByCleaningNoticeCbx"
					name="filterByCleaningNoticeCbx"
					label={localizationService.getLocalizedString('screen.labels.cleaningNotices')}
					checked={!props.timelineFilterByForm.excludeList.includes('sentCleaningNotices')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentCleaningNotices)}
					className="dropdown-item"
				/>
			</form>
		</div>
	);
};

export const DeviceTimelineFilterActionMenu: React.SFC<filterActionMenuProp> = props => {
	return (
		<div className="dropdown-menu timeline-filter-action-menu-div" aria-labelledby="filterBtn">
			<button
				className="close-dropdown flex-row-reverse"
				aria-label="Close and cancel any changes"
				onClick={props.closeModal}>
				<CloseSvg />
			</button>
			<form>
				<SingleCheckbox
					id="filterByCleaningsCbx"
					name="filterByCleaningsCbx"
					label={localizationService.getLocalizedString('screen.labels.cleanings')}
					checked={!props.timelineFilterByForm.excludeList.includes('cleanings')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.cleanings)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByCommentsCbx"
					name="filterByCommentsCbx"
					label={localizationService.getLocalizedString('screen.labels.comments')}
					checked={!props.timelineFilterByForm.excludeList.includes('comments')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.comments)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByViolationCheckbox"
					name="filterByViolationCheckbox"
					label={localizationService.getLocalizedString('screen.labels.violations')}
					checked={!props.timelineFilterByForm.excludeList.includes('violations')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.violations)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByCleaningNoticeCbx"
					name="filterByCleaningNoticeCbx"
					label={localizationService.getLocalizedString('screen.labels.cleaningNotices')}
					checked={!props.timelineFilterByForm.excludeList.includes('sentCleaningNotices')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentCleaningNotices)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByEmailCheckbox"
					name="filterByEmailCheckbox"
					label={localizationService.getLocalizedString('screen.labels.email')}
					checked={!props.timelineFilterByForm.excludeList.includes('deviceEmailLogs')}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.deviceEmailLogs)}
					className="dropdown-item"
				/>
			</form>
		</div>
	);
};
