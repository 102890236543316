import React, { useEffect } from 'react';
import { localizationService } from 'src/services';
import { useDispatch, useSelector } from 'react-redux';
import { cccHazardSlice } from 'src/components/authority/backflow/hazards/hazards.slice';
import { RootState } from 'src/redux';
import { CccHazard } from '@rcp/types';
import { HazardCard } from './hazard-card';
import { HazardDetailsModal } from './hazard-details-modal';

interface OwnProps {
	siteId: number;
}

type Props = OwnProps;
export const SiteHazardsTab: React.FC<Props> = props => {
	const dispatch = useDispatch();

	const [hazardList, setHazardList] = React.useState([] as CccHazard[]);
	const [hazardModalToggle, setHazardModalToggle] = React.useState(false);

	const onToggleAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		setHazardModalToggle(!hazardModalToggle);
	};

	const fetchHazards = () => {
		props.siteId && dispatch(cccHazardSlice.fetchPage({ siteId: props.siteId.toString() }));
	};

	useEffect(() => {
		fetchHazards();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.siteId]);
	let hazardState = (state: RootState) => state.cccHazards;
	let { result: list } = useSelector(hazardState);
	let isLoading = useSelector(hazardState).loading;

	useEffect(() => {
		setHazardList(list);
	}, [list]);

	return (
		<>
			<div className="row">
				{isLoading === false &&
					hazardList.map((item: CccHazard, index: number) => <HazardCard hazard={item} key={index} />)}
			</div>
			<a id="addHazard" href="#/" className="ai-add-link" onClick={e => onToggleAddModal(e)}>
				{localizationService.getLocalizedString('screen.buttons.addHazard')}&#8230;
			</a>
			{hazardModalToggle && (
				<HazardDetailsModal
					currentHazard={{} as CccHazard}
					isToggle={hazardModalToggle}
					toggle={onToggleAddModal}
					siteId={props.siteId}
					fetchHazards={fetchHazards}
				/>
			)}
		</>
	);
};
