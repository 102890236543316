import { IppReportPackage } from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { ReportPackageStatus } from 'src/constants';
import { alertService } from 'src/redux/alert/alert-service';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import {
	acceptRejectReviewRequest,
	acceptRejectReviewSuccess,
	downloadPackageReviewRequest,
	downloadPackageReviewSuccess,
	loadPackageReviewRequest,
	loadPackageReviewSuccess,
	PackageReviewAction,
	repudiationReviewRequest,
	repudiationReviewSuccess,
	submissionReviewRequest,
	submissionReviewSuccess
} from './reviews-actions';
import { PackageReviewState } from './reviews-state';

type ReviewsThunkAction = ThunkAction<any, PackageReviewState, any, PackageReviewAction>;

export const loadReviewDetails = (id?: any): ReviewsThunkAction => async (dispatch, getState) => {
	dispatch(loadPackageReviewRequest());

	let url = urlService.getAuthorityResourceApiUrl(Resource.IppReportPackages, id);
	let reviewDetails = await apiService.getResource<IppReportPackage>(url);

	dispatch(loadPackageReviewSuccess({ ...reviewDetails }));
};

export const downloadAttachment = (id: number, querystring: string): ReviewsThunkAction => async (
	dispatch,
	getState
) => {
	dispatch(downloadPackageReviewRequest());

	let url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppFileStores}/${id}`, querystring);
	await apiService.downloadByUrl(url);

	dispatch(downloadPackageReviewSuccess());
};

export const submitReview = (reportPackage: IppReportPackage): ReviewsThunkAction => async (dispatch, getState) => {
	dispatch(submissionReviewRequest());
	const url = urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackages,
		reportPackage.reportPackageId as number
	);

	await apiService.httpPatch(url, reportPackage);
	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.package.reviewSubmitSuccessMessage')
	);
	dispatch(loadReviewDetails(reportPackage.reportPackageId));
	dispatch(submissionReviewSuccess());
	return true;
};

export const submitRepudiatedReview = (reportPackage: IppReportPackage): ReviewsThunkAction => async (
	dispatch,
	getState
) => {
	dispatch(repudiationReviewRequest());
	const url = urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackages,
		reportPackage.reportPackageId as number
	);

	await apiService.httpPatch(url, reportPackage);
	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.package.reviewRepudiateSuccessMessage')
	);
	dispatch(loadReviewDetails(reportPackage.reportPackageId));
	dispatch(repudiationReviewSuccess());
	return true;
};

export const acceptRejectReview = (reportPackage: any, callback: () => void): ReviewsThunkAction => async (
	dispatch,
	getState
) => {
	dispatch(acceptRejectReviewRequest());
	const url = urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackages,
		reportPackage.reportPackageId as number
	);

	await apiService.httpPatch(url, reportPackage);
	reportPackage.reportStatusName === ReportPackageStatus.Rejected &&
		alertService.addSuccess(
			localizationService.getLocalizedString(`ipp.reportPackage.package.reviewRejectSuccessMessage`)
		);
	reportPackage.reportStatusName === ReportPackageStatus.Rejected && callback();
	dispatch(loadReviewDetails(reportPackage.reportPackageId));
	dispatch(acceptRejectReviewSuccess());
	return true;
};

export const sendToCts = (reportPackageId: number): ReviewsThunkAction => async (dispatch, getState) => {
	let webBaseUrl = window.location.href.split('/');
	window.location.href.split('/');
	webBaseUrl.pop();
	let newWebBaseUrl = webBaseUrl.join('/');
	const url = `${urlService.getAuthorityResourceApiUrl(
		Resource.IppReportPackages,
		reportPackageId as number
	)}/Parse?WebBaseUrl=${newWebBaseUrl}/`;
	await apiService.getResource<IppReportPackage>(url);
	alertService.addSuccess(
		localizationService.getLocalizedString('ipp.reportPackage.package.sendToCtsSuccessMessage')
	);
	dispatch(loadReviewDetails(reportPackageId));
	return true;
};
