import { ResendEmailDto } from './../../../../../../packages/types/src/index';
import { apiService, Resource, urlService, QueryParameters, localizationService } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { alertService } from '../..';
import { EmailMessage, EmailLog, MaxInteger } from '@rcp/types';
import _ from 'lodash';
import { reloadTimelineEventsFromServer } from '../../../features/timeline/timeline-service';

export interface FacilityEmailsState {
	facilityEmailLogs: EmailLog[];
}

export const initialFacilityEmailsState: FacilityEmailsState = {
	facilityEmailLogs: []
};

enum FacilityEmailActionType {
	LoadFacilityEmail = 'loadFacilityEmail',
	DeleteFacilityEmail = 'deleteFacilityEmail'
}

export type FacilityEmailThunkAction = ThunkAction<any, FacilityEmailsState, any, Action>;

export const sendFacilityEmail = (emailMessage: EmailMessage): FacilityEmailThunkAction => async (
	dispatch,
	getState
) => {
	let facilityId = urlService.getFogFacilityId();
	let emailsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Emails);
	let response = await apiService.httpPost(emailsUrl, emailMessage);
	alertService.addSuccess(response);
	dispatch(loadFacilityEmailLogs());
	return true;
};

export const sendDeviceEmail = (
	emailMessage: EmailMessage,
	facilityId: number,
	deviceId: number
): FacilityEmailThunkAction => async (dispatch, getState) => {
	let emailsUrl = `${urlService.getFacilityResourceApiUrl(facilityId, Resource.Devices, deviceId)}/${
		Resource.Emails
	}`;
	let response = await apiService.httpPost(emailsUrl, emailMessage);
	alertService.addSuccess(response);
	reloadTimelineEventsFromServer();
	return true;
};

export const deleteFacilityEmail = (emailLogId: number): FacilityEmailThunkAction => async (dispatch, getState) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	let emailsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Emails, emailLogId);
	await apiService.deleteResource(emailsUrl);
	alertService.addSuccess('Email was deleted successfully');
	dispatch({ type: FacilityEmailActionType.DeleteFacilityEmail, emailLogId });
	return true;
};

export const deleteDeviceEmail = (
	emailLogId: number,
	facilityId: number,
	deviceId: number
): FacilityEmailThunkAction => async (dispatch, getState) => {
	let emailsUrl = `${urlService.getFacilityResourceApiUrl(facilityId, Resource.Devices, deviceId)}/${
		Resource.Emails
	}/${emailLogId}`;
	await apiService.deleteResource(emailsUrl);
	alertService.addSuccess(localizationService.getLocalizedString('screen.timeline.emailDeletedMessage'));
	reloadTimelineEventsFromServer();
	return true;
};

export const deleteEmailLog = (emailLogId: number, refresh?: Function): FacilityEmailThunkAction => async (dispatch, getState) => {
	let emailsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.EmailLogs}/${emailLogId}`;
	await apiService.deleteResource(emailsUrl);
	refresh && refresh();
	alertService.addSuccess(localizationService.getLocalizedString('screen.timeline.noticeDeletedMessage'));
	return true;
};

export const deleteEmailLogs = (emailLogIds: number[], refresh?: Function): FacilityEmailThunkAction => async (dispatch, getState) => {
	let emailsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.EmailLogs}?ids=${emailLogIds}`;
	await apiService.deleteResource(emailsUrl);
	refresh && refresh();
	alertService.addSuccess(localizationService.getLocalizedString('screen.timeline.noticesDeletedMessage'));
	return true;
};

export const resendEmailLogs = (dtos: ResendEmailDto[], refresh?: Function): FacilityEmailThunkAction => async (dispatch, getState) => {
	let emailsUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.EmailLogs}/resend`;
	await apiService.postResourceObject(emailsUrl, dtos);
	refresh && refresh();
	alertService.addSuccess(
		localizationService.getLocalizedString('screen.timeline.noticeResent', dtos.length > 1 ? 's' : '')
	);
	return true;
};

export const loadFacilityEmailLogs = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	let queryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		queryParams.put('sentDateTimeUtc', `gte:${startDate}`).put('sentDateTimeUtc', `lte:${endDate}`);
	}

	const emailLogsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Emails);
	const facilityEmailLogs = await apiService.httpGet(`${emailLogsUrl}?${queryParams.toQueryString()}`);
	dispatch({ type: FacilityEmailActionType.LoadFacilityEmail, facilityEmailLogs });
};

export const facilityEmailsReducer = (state = initialFacilityEmailsState, action: any): FacilityEmailsState => {
	switch (action.type) {
		case FacilityEmailActionType.LoadFacilityEmail:
			return { ...state, facilityEmailLogs: action.facilityEmailLogs };
		case FacilityEmailActionType.DeleteFacilityEmail:
			let initialEmailLogs = state.facilityEmailLogs;
			let updatedEmailLogs: EmailLog[] = [];
			for (var index in initialEmailLogs) {
				if (initialEmailLogs[index].emailLogId !== action.emailLogId) {
					updatedEmailLogs.push(initialEmailLogs[index]);
				}
			}
			return { ...state, facilityEmailLogs: updatedEmailLogs };
		default:
			return { ...state };
	}
};
