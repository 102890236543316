import _ from 'lodash';
import React, { useState } from 'react';
import * as LinkoTypes from '@rcp/types';
import { ContactEditActionButton } from './contact-edit-action-button';
import { urlService, UtilService } from 'src/services';
import { ContactEmail, ContactNumber, NumberType } from '@rcp/types';
import { FacilitySendEmail } from '../send-email';
import { LetterTemplateCategory } from '@rcp/types';
import { getContactDisplayName } from 'src/features/contacts/contacts.service';

export const ContactCardComponent = (props: { item: LinkoTypes.FacilityEntityContact; isForRightPanel: boolean }) => {
	const [sendEmailModal, setSendEmailModal] = useState(false);
	const [selectedEmail, setSelectedEmail] = useState([] as any[]);
	const facilityContact = props.item;

	let filteredContactNumbers = UtilService.fromJson(facilityContact.jsonNumbers as string).filter(
		(contactNumber: ContactNumber) => {
			return (
				String.equalCaseInsensitive(contactNumber.numberType, NumberType.CellPhone) ||
				String.equalCaseInsensitive(contactNumber.numberType, NumberType.BusinessPhone)
			);
		}
	);

	const sendEmailPopUp = (address: string) => {
		const name = getContactDisplayName(facilityContact);
		setSendEmailModal(true);
		const selectEmail: any[] = [
			{
				name,
				address,
				selected: true
			}
		];
		setSelectedEmail(selectEmail);
	};

	const toggleSendEmailModal = () => {
		setSendEmailModal(!sendEmailModal);
	};

	return (
		<>
			<div className={props.isForRightPanel ? 'wrapWord' : 'tab-col'}>
				{props.isForRightPanel ? (
					<div className="font-size-16px-regular mb-1">{getContactDisplayName(facilityContact)}</div>
				) : (
					<ContactEditActionButton facilityContact={facilityContact} />
				)}
				{_.has(facilityContact, 'contactTypeCode') ? (
					<div className="mb-1">{facilityContact.contactTypeCode}</div>
				) : null}

				{filteredContactNumbers.map((contactNumber: ContactNumber, index: number) => (
					<div className="mb-1" key={'ContactNumber-' + index}>
						{contactNumber.label}:&nbsp;<a href={'tel:' + contactNumber.number}>{contactNumber.number}</a>
						{contactNumber.extension ? <span> {'ext-' + contactNumber.extension} </span> : null}
					</div>
				))}

				{UtilService.fromJson(facilityContact.jsonEmails as string).map(
					(contactEmail: ContactEmail, index: number) => (
						<div className="mb-0" key={'contactEmail-' + index}>
							<a
								id="facility-contact-email"
								className="text-break contact-email"
								onClick={() => sendEmailPopUp(contactEmail.email)}>
								{contactEmail.email}
							</a>
						</div>
					)
				)}
			</div>
			{sendEmailModal && (
				<FacilitySendEmail
					show={sendEmailModal}
					toggle={toggleSendEmailModal}
					letterTemplateCategory={
						urlService.getFogDeviceId() <= 0
							? LetterTemplateCategory.FacilityEmail
							: LetterTemplateCategory.DeviceEmail
					}
					selectedEmail={selectedEmail}
					isDeviceDetail={urlService.getFogDeviceId() > 0}
				/>
			)}
		</>
	);
};
