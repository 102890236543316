import React from 'react';
import { History } from 'history';
import _ from 'lodash';
import { urlService, localizationService, DateUtilService, navigateTo, Resource, apiService } from 'src/services';
import { ComplianceStatusCell } from './ComplianceStatusCell';
import {
	alertService,
	RestSlice,
	RootState,
	useReduxDispatch,
	useRootStateSelector,
	toggleActionModal,
	ActionModalActionType,
	useReduxSelector
} from 'src/redux';

import './facilities.scss';
import {
	LocalStorageName,
	Dictionary,
	FogFacility,
	ColumnField,
	RouteProps,
	FilterObjectName,
	CustomFieldApplyToTable} from '@rcp/types';
import { FacilityDetailsAddressModal } from './facility-details/facility-details-address/facility-details-address-modal';
import { BatchInspectionOperationModal } from './facility-details/batch-inspection-operation-modal';
import { useHistory } from 'react-router';
import { customFieldQueries, DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { fogFacilitiesSlice } from './facilities-slice';
import { nanoid } from '@reduxjs/toolkit';
import { NextInspectionEditActionLink } from './next-inspection-edit-action-link';
import InvitationFacilityPortal from './invite-facility/invite-facility-portal-action-link';
import { Utils } from 'src/services/utils';
import { FacilitiesCleaningEditActionLink } from './facilities-cleaning-link-cell';
import { nameof } from 'ts-simple-nameof';
import { FaCircle } from 'react-icons/fa';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

type Item = FogFacility;
export const FacilitiesGrid: React.FC<Props<Item>> = props => {
	const history = useHistory();
	const dispatch = useReduxDispatch();
	const [showAddModal, setAddShowModal] = React.useState(false);
	const [queryParameters, setQueryParameters] = React.useState<Dictionary<string>>({});
	const [isFacilityPortalEnabled, setIsFacilityPortalEnabled] = React.useState(false);
	const fogFacilities = useRootStateSelector(s => s.reduxFogFacilities);
	const showInspectionBatchOperationModal = useRootStateSelector(
		s => s.actionModals.fogFacilityGridBatchScheduleInspectionsModal
	);

	const toggleBatchScheduleInspectionsModal = () => {
		if (fogFacilities.total > 0) {
			dispatch(toggleActionModal(ActionModalActionType.fogFacilityGridBatchScheduleInspectionsModal));
		} else {
			alertService.addError(localizationService.getLocalizedString('alertMessages.cantScheduleWithNoFacilities'));
		}
	};

	const currentUser = useReduxSelector(state => state.userProfile);

	const bulkUpdate = () => {
		let queryString = urlService.toQueryString(queryParameters);
		let path = urlService.getAuthorityBulkUpdatePath(queryString.trim());
		history.push(path);
	};

	const setQueryParametersFromParent = (queryParameters: Dictionary<string>) => {
		setQueryParameters(queryParameters);
	};

	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'incidents.facilities');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'incidents.facilities');
		}
		return localizationService.getLocalizedString('screen.labels.facilities');
	};

	const facilityNameToLink = (props: any) => {
		const facility = props.dataItem as FogFacility;
		const facilityPath = urlService.getReactAuthorityResourceUrl(Resource.FogFacilities, facility.facilityId);
		return (
			<td>
				<a href={facilityPath} className="ai-link" onClick={(e: any) => navigateTo(history, facilityPath, e)}>
					{facility.facilityName}
				</a>
			</td>
		);
	};

	const inspectionDateToLink = (props: any) => {
		const facility = props.dataItem as FogFacility;
		const facilityDate = facility.lastInspectionCompleteDate;
		const inspectionPath = urlService.getFacilityInspectionDetailsUrl(
			facility.facilityId,
			facility.lastInspectionId
		);
		return (
			<td>
				<a
					href={inspectionPath}
					className="ai-link"
					onClick={(e: any) => navigateTo(history, inspectionPath, e)}>
					{facility.lastInspectionCompleteDate ? DateUtilService.toDisplayDate(facilityDate) : facilityDate}
				</a>
			</td>
		);
	};

	const nextInspectionDate = (props: any) => {
		const fogFacility = props.dataItem as FogFacility;
		return (
			<td key={nanoid()}>
				<NextInspectionEditActionLink
					fogFacility={fogFacility}
					loadFacilityList={() => {
						dispatch(fogFacilitiesSlice.reload());
					}}
					queryParameters={queryParameters}
				/>
			</td>
		);
	};

	const lastCleaningDateLink = (props: any) => {
		const fogFacility = props.dataItem as FogFacility;
		const { lastPumpOutCompleteDate, lastPumpOutEventId, facilityId } = fogFacility;
		return (
			<td>
				{lastPumpOutCompleteDate && (
					<FacilitiesCleaningEditActionLink
						dateField={lastPumpOutCompleteDate}
						pumpOutEventId={lastPumpOutEventId || 0}
						organizationId={facilityId || 0}
						queryParameters={queryParameters}
					/>
				)}
			</td>
		);
	};

	const nextCleaningDateLink = (props: any) => {
		const fogFacility = props.dataItem as FogFacility;
		const { nextPumpOutEventId, nextPumpOutDueDate, facilityId } = fogFacility;
		return (
			<td>
				{nextPumpOutDueDate && (
					<FacilitiesCleaningEditActionLink
						dateField={nextPumpOutDueDate || ''}
						pumpOutEventId={nextPumpOutEventId || 0}
						organizationId={facilityId || 0}
						queryParameters={queryParameters}
					/>
				)}
			</td>
		);
	};

	const riskScoreColumn = (props: any) => {
		const fogFacility = props.dataItem as FogFacility;
		return fogFacility.riskScore ? (
			<td>
				<FaCircle size={8} className={`risk-score ${_.toLower(fogFacility.riskScore)} mr-1`} />
				<span>{fogFacility.riskScore}</span>
			</td>
		) : (
			<td></td>
		);
	};

	const redirectToManageFacilityPortalAccess = (event: any, id: number) => {
		navigateTo(props.history, `/fog/facility/${id}/manageAccess`, event);
	};

	const facilityPortalInvitationAction = (items: any) => {
		const dataItem = _.cloneDeep(items.dataItem) as FogFacility;
		return (
			<td>
				<InvitationFacilityPortal
					redirectToManageAccess={redirectToManageFacilityPortalAccess}
					facility={dataItem}
				/>
			</td>
		);
	};

	const bulkNotice = () => {
		let queryString = urlService.toQueryString(queryParameters);
		navigateTo(history, `Facilities/bulkNotice?${queryString.trim()}`);
	};

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const downloadInvites = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities)}/DownloadInvites${
			window.location.search
		}`;
		try {
			await apiService.httpGet(url);
			dispatch(fogFacilitiesSlice.fetchAll(window.location.search.substring(1)));
			alertService.addSuccess(localizationService.getLocalizedString('facility.downloadInvitesSuccess'));
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const facilityPortalCell = (item: any) => {
		const dataItem = item.dataItem as FogFacility;
		if (dataItem.guid && dataItem.isEnabled && dataItem.registrationDateTimeUtc) {
			const landingHaulerPortalUrl = urlService.getLandingPortalUrlForFacilityPortalFromFog(dataItem);
			return (
				<td>
					<a href={landingHaulerPortalUrl} id={`facilityPortalLink_` + dataItem.guid} className="ai-link">
						{localizationService.getLocalizedString('facility.accessFacilityPortal')}
					</a>
				</td>
			);
		} else return <td></td>;
	};

	const initialGridOption = {
		pageTitle: getTitle(),
		prefix: 'facility',
		storageName: LocalStorageName.FacilityGrid,
		gridUrlLocalStorageName: LocalStorageName.FacilityGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.FacilitySortOrder,
		showActionDownloadInvites: true,
		downloadInvites: downloadInvites,
		showActions: true,
		showActionExport: true,
		customActions: [
			{
				actionType: ActionModalActionType.fogFacilityGridBatchScheduleInspectionsModal,
				actionLabel: localizationService.getLocalizedString('grid.scheduleInspections'),
				toggle: toggleBatchScheduleInspectionsModal
			},
			{
				actionType: ActionModalActionType.fogFacilityBatchUpdateModal,
				actionLabel: localizationService.getLocalizedString('grid.bulkUpdate'),
				toggle: bulkUpdate
			},
			{
				actionType: ActionModalActionType.fogFacilityBulkNotices,
				actionLabel: localizationService.getLocalizedString('grid.sendNotices'),
				toggle: bulkNotice
			}
		].filter(action => {
			if (String.equalCaseInsensitive(action.actionType, ActionModalActionType.fogFacilityBulkNotices)) {
				return action;
			} else if (action.actionType !== ActionModalActionType.fogFacilityBulkNotices) {
				return action;
			}
		}),
		showFilter: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('facility.searchHint'),
		showEditColumnButton: true,

		showAddButton: !props.match.params.importId,
		addButtonText: localizationService.getLocalizedString('facility.addFacility'),
		addModalComponent: (
			<>
				{showAddModal && (
					<FacilityDetailsAddressModal
						showModal={showAddModal}
						setShowModal={toggleAddModal}
						isAddModal={true}
					/>
				)}
			</>
		),
		toggleAddModal: toggleAddModal,
		customFieldQuery: customFieldQueries.Fog,
		customFieldsForGrid: [CustomFieldApplyToTable.ApplyToFogFacility],
		hasGridMap: true,
		defaultSort: [{ field: nameof<FogFacility>(f => f.facilityId), dir: 'desc' }],
		allColumns: [
			ColumnField.newText(
				nameof<FogFacility>(f => f.addressLine1),
				true
			),
			ColumnField.newText(nameof<FogFacility>(f => f.addressLine2)),
			ColumnField.newText(nameof<FogFacility>(f => f.cityName)),
			ColumnField.newText(nameof<FogFacility>(f => f.jurisdictionName)),
			ColumnField.newText(nameof<FogFacility>(f => f.zipCode)),
			ColumnField.newText(nameof<FogFacility>(f => f.longitude)),
			ColumnField.newText(nameof<FogFacility>(f => f.latitude)),
			ColumnField.newText(nameof<FogFacility>(f => f.elevation)),
			ColumnField.newText(nameof<FogFacility>(f => f.gisNumber)),
			ColumnField.newText(nameof<FogFacility>(f => f.mapCategory)),
			ColumnField.newText(
				nameof<FogFacility>(f => f.referenceNumber),
				true
			),
			ColumnField.newText(
				nameof<FogFacility>(f => f.facilityName),
				true,
				facilityNameToLink
			),
			ColumnField.newBoolean(nameof<FogFacility>(f => f.isPermitted)),
			ColumnField.newText(nameof<FogFacility>(f => f.assignedTo)),
			ColumnField.newText(nameof<FogFacility>(f => f.lastHaulerName)),
			ColumnField.newText(nameof<FogFacility>(f => f.meterNumber)),
			ColumnField.newText(nameof<FogFacility>(f => f.sewerNumber)),
			ColumnField.newText(nameof<FogFacility>(f => f.accountNumber)),
			ColumnField.newText(nameof<FogFacility>(f => f.trunkLine)),
			ColumnField.newText(nameof<FogFacility>(f => f.receivingPlant)),
			ColumnField.newText(nameof<FogFacility>(f => f.inspectionFrequencyCode)),
			ColumnField.newDate(nameof<FogFacility>(f => f.inBusinessSince)),
			ColumnField.newDate(nameof<FogFacility>(f => f.closedDate)),
			ColumnField.newInteger(nameof<FogFacility>(f => f.numberOfEmployees)),
			ColumnField.newBoolean(
				nameof<FogFacility>(f => f.isEnabled),
				true
			),
			ColumnField.newText(nameof<FogFacility>(f => f.tagClassification1)),
			ColumnField.newText(nameof<FogFacility>(f => f.tagClassification2)),
			ColumnField.newText(nameof<FogFacility>(f => f.hoursOfOperation)),
			ColumnField.newText(nameof<FogFacility>(f => f.squareFootage)),
			ColumnField.newText(nameof<FogFacility>(f => f.seatingCapacity)),
			ColumnField.newText(nameof<FogFacility>(f => f.numberMealsServed)),
			ColumnField.newText(
				nameof<FogFacility>(f => f.complianceStatus),
				true,
				ComplianceStatusCell
			),
			ColumnField.newDate(
				nameof<FogFacility>(f => f.lastInspectionCompleteDate),
				false,
				inspectionDateToLink
			),
			ColumnField.newDate(
				nameof<FogFacility>(f => f.nextInspectionDueDate),
				false,
				nextInspectionDate
			),
			ColumnField.newDate(
				nameof<FogFacility>(f => f.lastPumpOutCompleteDate),
				false,
				lastCleaningDateLink
			),
			ColumnField.newDate(
				nameof<FogFacility>(f => f.nextPumpOutDueDate),
				false,
				nextCleaningDateLink
			),
			ColumnField.newText(nameof<FogFacility>(f => f.tags)),
			ColumnField.newText(
				nameof<FogFacility>(f => f.facilityPortalStatusName),
				false,
				facilityPortalInvitationAction
			),
			ColumnField.newBoolean(
				nameof<FogFacility>(f => f.hasPrimaryContact),
				false
			),
			ColumnField.newText(nameof<FogFacility>(f => f.contactName)),
			ColumnField.newText(nameof<FogFacility>(f => f.email)),
			ColumnField.newText(nameof<FogFacility>(f => f.phone)),
			ColumnField.newBoolean(
				nameof<FogFacility>(f => f.activeDeviceCount),
				false
			),
			ColumnField.newText(nameof<FogFacility>(f => f.contractedHaulerName)),
			ColumnField.newBoolean(nameof<FogFacility>(f => f.isPreferredContractedHauler)),
			ColumnField.newText(
				nameof<FogFacility>(f => f.riskScore),
				false,
				riskScoreColumn
			)
		],
		defaultColumns: [
			nameof<FogFacility>(f => f.referenceNumber),
			nameof<FogFacility>(f => f.facilityName),
			nameof<FogFacility>(f => f.complianceStatus),
			nameof<FogFacility>(f => f.addressLine1),
			nameof<FogFacility>(f => f.facilityPortalStatusName),
			nameof<FogFacility>(f => f.isEnabled)
		],
		resetFilterService: () => {
			let appliedDomains: { [index: string]: boolean } = {
				FACILITY: true,
				INSPECTION: true,
				EXTRACTOR: true,
				VIOLATION: true,
				CLEANING: true,
				EVENT: true
			};
			filterService.init(
				FilterDomain.FACILITY,
				FilterDomainName.facility,
				FilterObjectName.Facility,
				LocalStorageName.FacilityGridFilterUrl,
				appliedDomains
			);
		}
	};

	const setFacilityPortalStatus = async () => {
		setIsFacilityPortalEnabled(await Utils.getFacilityPortalStatusForAuthority());
	};

	React.useEffect(() => {
		setFacilityPortalStatus();
	}, []);

	const getInitialGridOption = () => {
		let newAllColumns = _.cloneDeep(initialGridOption.allColumns);
		let newDefaultColumns = _.cloneDeep(initialGridOption.defaultColumns);

		if (!isFacilityPortalEnabled) {
			newAllColumns = newAllColumns.filter((column: ColumnField) => column.name !== 'facilityPortalStatusName');
			newDefaultColumns = newDefaultColumns.filter((column: string) => column !== 'facilityPortalStatusName');
			newDefaultColumns = newDefaultColumns.filter((column: string) => column !== 'customerSuccessAccess');
			return {
				...initialGridOption,
				showActionDownloadInvites: false,
				downloadInvites: undefined,
				allColumns: [...newAllColumns],
				defaultColumns: [...newDefaultColumns]
			} as GridOption;
		}
		if (isFacilityPortalEnabled && currentUser.userProfile.isInternalAccount) {
			let newColumn = ColumnField.newText('customerSuccessAccess', false, facilityPortalCell);
			newAllColumns.push(newColumn);
			newDefaultColumns.push('customerSuccessAccess');
			return {
				...initialGridOption,
				showActionDownloadInvites: false,
				downloadInvites: undefined,
				allColumns: [...newAllColumns],
				defaultColumns: [...newDefaultColumns]
			} as GridOption;
		} else {
			return { ...initialGridOption, allColumns: [...newAllColumns] } as GridOption;
		}
	};

	return (
		<DataGrid
			history={props.history}
			match={props.match}
			restSlice={fogFacilitiesSlice}
			restState={(state: RootState) => state.reduxFogFacilities}
			setQueryParametersFromParent={setQueryParametersFromParent}
			gridOption={getInitialGridOption()}>
			{showInspectionBatchOperationModal && (
				<BatchInspectionOperationModal
					actionType={ActionModalActionType.fogFacilityGridBatchScheduleInspectionsModal}
					toggleModal={toggleBatchScheduleInspectionsModal}
					filteredObjectCount={fogFacilities.total}
				/>
			)}
		</DataGrid>
	);
};
