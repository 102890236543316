/* eslint-disable react/display-name */
import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { Button } from '@progress/kendo-react-buttons';
import { localizationService } from 'src/services';
const createInsertTool = settings => props => {
	const { view } = props;
	const nodeType = view && view.state.schema.nodes[settings.nodeType];
	const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
	return (
		<Button
			onClick={e => {
				e.preventDefault();
				EditorUtils.insertNode(view, nodeType.createAndFill(settings.attrs));
			}}
			disabled={!canInsert}
			onMouseDown={e => e.preventDefault()}
			onPointerDown={e => e.preventDefault()}
			{...settings.props}
		/>
	);
};

const InsertPageBreakTool = createInsertTool({
	nodeType: 'horizontal_rule',
	props: {
		title: localizationService.getLocalizedString('notices.insertPageBreak'),
		imageUrl: '/img/icons/insert-page-break.svg'
	}
});

export { InsertPageBreakTool };
