import React, { FC } from 'react';
import { History } from 'history';
import {
	ColumnField,
	FilterType,
	LocalStorageName,
	AuthorityUser,
	RouteProps,
	RegulatoryProgramName
} from '@rcp/types';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState, RestSlice } from 'src/redux';
import { localizationService, urlService } from 'src/services';
import { UserEditorModal } from './user-editor';
import { authorityUsersSlice } from './user-slice';

interface Props<T> extends RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const EditActionLink: React.FC<{ user: AuthorityUser }> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};
	const toggleModal = () => {
		setShowModal(!showModal);
	};
	return (
		<>
			{urlService.isAdministrator() ? (
				<a href="#/" className="ai-link" onClick={e => onEditClicked(e)}>
					{props.user.fullName}
				</a>
			) : (
				<div>{props.user.fullName}</div>
			)}
			{showModal && (
				<UserEditorModal
					showModal={showModal}
					user={props.user}
					toggleModal={toggleModal}
					isCreate={false}
					isForInternalUser={false}
				/>
			)}
		</>
	);
};

export const AuthorityUserGrid: FC<Props<AuthorityUser>> = props => {
	const [showAddModal, setAddShowModal] = React.useState(false);
	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};
	const userToLink = (props: any) => {
		const user = props.dataItem as AuthorityUser;
		return (
			<td>
				<EditActionLink user={user} />
			</td>
		);
	};

	const filterOnUsers = (users: [], searchTerm: string): [] => {
		const isContains = (searchTerm: string, searchString?: string) => {
			return searchString && searchString.toLowerCase().includes(searchTerm.toLowerCase());
		};

		let filteredUsers = users.filter((user: AuthorityUser) => {
			return (
				user &&
				(isContains(searchTerm, user.fullName) ||
					isContains(searchTerm, user.email) ||
					isContains(searchTerm, user.role) ||
					isContains(searchTerm, user.status))
			);
		});
		return filteredUsers as [];
	};

	const populateStatusText = (users: []): [] => {
		let newUsers = users.map((user: AuthorityUser) => {
			var status = user.isRemoved
				? 'Deleted'
				: user.isAccountLocked
				? 'Locked'
				: user.isEnabled
				? 'Active'
				: 'Disabled';
			return { ...user, status: status } as AuthorityUser;
		});
		return newUsers as [];
	};

	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('users.users'),
		prefix: 'users',
		storageName: LocalStorageName.UserGrid,
		sortOrderLocalStorageName: LocalStorageName.UserGridSortOrder,
		queryParameters: {
			sort: 'fullName asc',
			includeInactive: '1'
		},

		showEditColumnButton: true,
		showSearch: true,
		searchPlaceholder: localizationService.getLocalizedString('users.search'),

		showAddButton: urlService.isAdministrator(),
		addButtonText: localizationService.getLocalizedString('users.addUser'),
		addModalComponent: (
			<>
				{showAddModal && (
					<UserEditorModal
						showModal={showAddModal}
						toggleModal={toggleAddModal}
						isCreate={true}
						isForInternalUser={false}
					/>
				)}
			</>
		),
		toggleAddModal: toggleAddModal,
		defaultSort: [{ field: 'fullName', dir: 'asc' }],
		allColumns: [
			new ColumnField('fullName', FilterType.Text, userToLink),
			new ColumnField('email'),
			new ColumnField('role'),
			new ColumnField('status')
		],
		defaultColumns: ['fullName', 'email', 'role', 'status'],
		doClientSearch: filterOnUsers,
		populateComputedColumns: populateStatusText,
		showBackButton: String.equalCaseInsensitive(urlService.getCurrentProgramOrDefault(), RegulatoryProgramName.FOG)
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={authorityUsersSlice}
				restState={(state: RootState) => state.authorityUsers}
				gridOption={initialGridOption}
			/>
		</>
	);
};
