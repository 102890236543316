import React, { FC, useState, useEffect } from 'react';
import { History } from 'history';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, LocalStorageName, Hauler, FeatureNames } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption, GridState } from '../../../../features/data-grid/data-grid';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import {
	localizationService,
	localStorageService,
	apiService,
	Resource,
	tokenService,
	urlService,
	navigateTo
} from 'src/services';
import { haulersSlice } from './haulers-slice';
import { Dictionary } from 'lodash';
import { nameof } from 'ts-simple-nameof';
import { HaulerCardModal } from './hauler-card-component-modal';
import { HaulerEditActionLink } from './hauler-edit-action-link';
import _ from 'lodash';
import { Utils } from 'src/services/utils';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { InviteHaulerActionLink } from './invite-hauler/invite-hauler-action-link';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

export const HaulerGrid: FC<Props<Hauler>> = props => {
	const dispatch = useDispatch();
	const history = useHistory();
	const currentUser = useReduxSelector(state => state.userProfile);

	const haulerNameToLink = (props: any) => {
		const hauler = props.dataItem as LinkoTypes.Hauler;
		return (
			<td>
				<HaulerEditActionLink hauler={hauler} />
			</td>
		);
	};
	const [showAddModal, setAddShowModal] = useState(false);

	const toggleAddModal = () => {
		setAddShowModal(!showAddModal);
	};

	const redirectToManageAccess = (event: any, id: number) => {
		navigateTo(props.history, `/fog/hauler/${id}/manageAccess`, event);
	};

	const invitationAction = (props: any) => {
		const hauler = props.dataItem as LinkoTypes.Hauler;
		return (
			<td>
				<InviteHaulerActionLink redirectToManageAccess={redirectToManageAccess} hauler={hauler} />
			</td>
		);
	};

	const getNumberOfFacilities = (props: any) => {
		const hauler = props.dataItem as LinkoTypes.Hauler;
		return (
			<td>
				<a
					href={urlService.getHaulerFacilitiesPage(hauler.haulerId)}
					onClick={(e: any) => {
						navigateTo(history, urlService.getHaulerFacilitiesPage(hauler.haulerId), e);
					}}>
					{hauler.facilitiesCount}
				</a>
			</td>
		);
	};

	const downloadInvites = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/DownloadInvites${
			window.location.search
		}`;
		try {
			await apiService.httpGet(url);
			dispatch(haulersSlice.fetchAll(window.location.search.substring(1)));
			alertService.addSuccess(
				localizationService.getLocalizedString('haulerPortal.haulerGrid.downloadInvitesSuccess')
			);
			url = `${urlService.getAuthorityResourcesApiUrl(
				Resource.Haulers
			)}/UnregisteredHaulersWithIncompleteMailingAddressCount${window.location.search}`;
			await apiService.httpGet(url).then(unregisteredHaulersWithIncompleteMailingAddressCount => {
				if (unregisteredHaulersWithIncompleteMailingAddressCount > 0) {
					alertService.addError(
						localizationService.getLocalizedString(
							'haulers.errors.noHaulerInvitedDueToIncompleteAddress',
							unregisteredHaulersWithIncompleteMailingAddressCount,
							unregisteredHaulersWithIncompleteMailingAddressCount > 1 ? 's' : '',
							unregisteredHaulersWithIncompleteMailingAddressCount > 1 ? 'were' : 'was'
						)
					);
				}
			});
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const getTitle = () => {
		if (props.match.params.importId) {
			if (window.location.pathname.includes('/new')) {
				return localizationService.getLocalizedString('importHistory.newEntities', 'haulers.haulers');
			}
			return localizationService.getLocalizedString('importHistory.updatedEntities', 'haulers.haulers');
		}
		return localizationService.getLocalizedString('haulers.haulers');
	};

	const [initialGridOption, setInitialGridOption] = useState<GridOption>({
		pageTitle: getTitle(),
		prefix: 'haulers',
		storageName: LocalStorageName.HaulerGrid,
		gridUrlLocalStorageName: LocalStorageName.HaulerGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.HaulerSortOrder,
		showActionDownloadInvites: true,
		downloadInvites: downloadInvites,
		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showSearch: true,

		searchHint: localizationService.getLocalizedString('haulers.searchHint'),

		showAddButton: props.match.params.importId ? false : true,
		addButtonText: localizationService.getLocalizedString('haulers.addHauler'),
		toggleAddModal: toggleAddModal,

		queryParameters: {} as Dictionary<string>,
		defaultSort: [{ field: nameof<Hauler>(h => h.haulerId), dir: 'desc' }],
		allColumns: [
			new ColumnField(nameof<Hauler>(h => h.haulerNumber)),
			new ColumnField(
				nameof<Hauler>(h => h.name),
				FilterType.Text,
				haulerNameToLink
			),
			new ColumnField(nameof<Hauler>(h => h.addressLine1)),
			new ColumnField(nameof<Hauler>(h => h.addressLine2)),
			new ColumnField(nameof<Hauler>(h => h.addressCity)),
			new ColumnField(nameof<Hauler>(h => h.addressJurisdictionCode)),
			new ColumnField(nameof<Hauler>(h => h.addressZipCode)),
			new ColumnField(nameof<Hauler>(h => h.phone)),
			new ColumnField(nameof<Hauler>(h => h.email)),
			new ColumnField(
				nameof<Hauler>(h => h.haulerPortalStatusName),
				FilterType.Text,
				invitationAction
			),
			new ColumnField(
				nameof<Hauler>(h => h.facilitiesCount),
				FilterType.Number,
				getNumberOfFacilities
			),
			new ColumnField(
				nameof<Hauler>(h => h.isActive),
				FilterType.Boolean
			),
			new ColumnField(
				nameof<Hauler>(h => h.isPreferred),
				FilterType.Boolean
			),
			new ColumnField(nameof<Hauler>(h => h.comments))
		],
		defaultColumns: [
			nameof<Hauler>(h => h.haulerNumber),
			nameof<Hauler>(h => h.name),
			nameof<Hauler>(h => h.addressLine1),
			nameof<Hauler>(h => h.phone),
			nameof<Hauler>(h => h.email),
			nameof<Hauler>(h => h.haulerPortalStatusName),
			nameof<Hauler>(h => h.facilitiesCount),
			nameof<Hauler>(h => h.isActive),
			nameof<Hauler>(h => h.comments)
		]
	});

	const getHaulerPortalStatus = async () => {
		let url = `${urlService.getAuthorityOrpDetailApiUrl()}/${
			tokenService.getTokenOrDefault().portalOrganizationId
		}/HaulerPortalStatus`;
		try {
			return await apiService.httpGet(url);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const haulerPortalCell = (item: any) => {
		const dataItem = item.dataItem as Hauler;
		if (dataItem.guid) {
			const landingHaulerPortalUrl = urlService.getLandingPortalUrlForHaulerPortalFromFog(dataItem);
			return (
				<td>
					<a href={landingHaulerPortalUrl} id={`haulerPortalLink_` + dataItem.guid} className="ai-link">
						{localizationService.getLocalizedString('haulers.accessHaulerPortal')}
					</a>
				</td>
			);
		} else return <td></td>;
	};

	const checkIfHaulerPortalOrPreferredHaulerFeatureIsEnabled = async () => {
		let newAllColumns = _.cloneDeep(initialGridOption.allColumns);
		let newDefaultColumns = _.cloneDeep(initialGridOption.defaultColumns);
		let isHaulerPortalEnabled = await getHaulerPortalStatus();
		if (!isHaulerPortalEnabled) {
			newAllColumns = newAllColumns.filter(
				(column: ColumnField) =>
					column.name !== nameof<Hauler>(h => h.haulerPortalStatusName) &&
					column.name !== nameof<Hauler>(h => h.facilitiesCount)
			);
			newDefaultColumns = newDefaultColumns.filter(
				(column: string) =>
					column !== nameof<Hauler>(h => h.haulerPortalStatusName) &&
					column !== nameof<Hauler>(h => h.facilitiesCount)
			);
		}
		if (isHaulerPortalEnabled && currentUser.userProfile.isInternalAccount) {
			let newColumn = new ColumnField('customerSuccessAccess', FilterType.Text, haulerPortalCell);
			newAllColumns.push(newColumn);
			newDefaultColumns.push('customerSuccessAccess');
		}

		setInitialGridOption({
			...initialGridOption,
			showActionDownloadInvites: isHaulerPortalEnabled,
			downloadInvites: isHaulerPortalEnabled ? downloadInvites : undefined,
			allColumns: [...newAllColumns],
			defaultColumns: [...newDefaultColumns]
		});
	};

	useEffect(() => {
		if (!_.isEmpty(currentUser)) {
			checkIfHaulerPortalOrPreferredHaulerFeatureIsEnabled();
		}
	}, [currentUser]);

	const handleSortChange = (state: GridState, setState: (state: GridState) => void, sort: any) => {
		localStorageService.setLocalStorage(LocalStorageName.HaulerSortOrder, sort);
		let queryParameters = { ...state.queryParameters };
		Utils.sortQueryParams(sort, queryParameters, Utils.isCustomField);
		if (sort && sort.length > 0) {
			let sortFieldName = sort[0].field;
			let sortFieldDirection = sort[0].dir;

			if (
				String.equalCaseInsensitive(
					sortFieldName,
					nameof<Hauler>(h => h.addressWithCity)
				)
			) {
				let sort = [
					`${nameof<Hauler>(h => h.addressLine1)} ${sortFieldDirection}`,
					`${nameof<Hauler>(h => h.addressLine2)} ${sortFieldDirection}`,
					`${nameof<Hauler>(h => h.addressCity)} ${sortFieldDirection}`
				];
				queryParameters['sort'] = sort.join(',');
			} else {
				queryParameters['sort'] = `${sortFieldName} ${sortFieldDirection}`;
			}
		} else {
			_.unset(queryParameters, 'sort');
		}
		setState({
			...state,
			sort: sort,
			queryParameters: queryParameters
		});
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={haulersSlice}
				restState={(state: RootState) => state.haulers}
				gridOption={{
					...initialGridOption,
					addModalComponent: (
						<>
							{showAddModal && (
								<HaulerCardModal
									showModal={showAddModal}
									setShowModal={toggleAddModal}
									isAddModal={true}
								/>
							)}
						</>
					)
				}}
				handleSortChange={handleSortChange}
			/>
		</>
	);
};
