import React from 'react';
import { localizationService } from '../../../../../services';
import CollapsibleCard from '../../../../widgets/collapsible-card/collapsible-card';
import { CustomizedSettingUrlResolver, LookupsWidget, ResourceAction } from 'src/components/widgets';
import { RepudiationReasonLookRowDataConverter } from 'src/components/widgets/lookup/data-converters/repudiation-reason-data-convertor';
import { Resource, urlService } from 'src/services';
import { LookupType } from '@rcp/types';

interface SettingProps {}

export const RepudiationReasons: React.FC<SettingProps> = props => {
	let lookupUrlResolver = new CustomizedSettingUrlResolver(
		`${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups/${LookupType.RepudiationReason}`,
		`includeInactive=true&includeIsInUse=true`
	);
	let dataConverter = new RepudiationReasonLookRowDataConverter(LookupType.CorrectiveActions);
	return (
		<CollapsibleCard
			accordionHeading={localizationService.getLocalizedString(
				`ipp.authorityAccountSetting.repudiationReasons.heading`
			)}
			accordionType={'ipp-aas-authority-settings-accordion'}
			className="mt-2">
			<span>
				{localizationService.getLocalizedString(
					'ipp.authorityAccountSetting.repudiationReasons.informationText'
				)}
			</span>
			<section className="w-75 d-block mx-auto">
				<LookupsWidget
					id={'repudiation-reasons'}
					name={'repudiation-reasons'}
					label={localizationService.getLocalizedString('ipp.reportPackage.package.reason')}
					hideAddButton={false}
					hideActiveStatus={false}
					editInputClass="form-group"
					settingAction={new ResourceAction(lookupUrlResolver, dataConverter)}
				/>
			</section>
		</CollapsibleCard>
	);
};
export const RepudiationReasonSettings = RepudiationReasons;
