import _ from 'lodash';
import enResources from 'src/assets/localization/resource_en.json';
import { String } from 'typescript-string-operations';

export class LocalizationService {
	locale: string;
	resources: any;

	constructor() {
		//TODO to determine the locale from SOMEWHERE
		this.locale = 'en';
		if (this.locale === 'en') {
			this.resources = enResources;
		}
	}

	getLocalizationStrings() {
		return this.resources;
	}

	// Examples:
	// localizationService.getLocalizedString('errors.failed')
	// localizationService.getLocalizedString('errors.failedPattern', 'domainObjects.extractor');
	// localizationService.getLocalizedString('errors.failedPattern', '2013-01-02');
	getLocalizedString(jsonPath: string, ...argumentJsonPaths: string[]): string {
		return this.getLocalizedStringAndLog(jsonPath, argumentJsonPaths, true);
	}

	formatValue(value: any): any {
		if (typeof value === 'boolean') {
			return value === true
				? localizationService.getLocalizedString('constants.yes')
				: localizationService.getLocalizedString('constants.no');
		}
		return value;
	}

	formatPlural(value: number): string {
		return value > 1 ? "s" : "";
	}
 
	private getLocalizedStringAndLog(jsonPath: string, argumentJsonPaths: string[], logAsWarning: boolean): string {
		if (!jsonPath) {
			return '';
		}

		let localizedText = _.get(this.resources, jsonPath);
		if (localizedText) {
			if (argumentJsonPaths.length > 0) {
				let localizedArguments = argumentJsonPaths.map(x => {
					if (_.indexOf(x, '.') < 1) {
						return x;
					}
					return this.getLocalizedStringAndLog(x, [], false);
				});
				return String.Format(localizedText, ...localizedArguments);
			}
			return localizedText;
		}
		if (logAsWarning) {
			console.warn(`translation for ${jsonPath} is missing.`);
		}
		return jsonPath;
	}
}

export const localizationService = new LocalizationService();
