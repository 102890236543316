import React from 'react';
import { TextInput } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { FormState } from './facility-links';
import './facility-link.scss';

interface Props {
	changeFormField?: (event: any) => void;
	formState: FormState;
}

export const FacilityLinkForm: React.SFC<Props> = props => {
	return (
		<>
			<TextInput
				id="name"
				name="displayText"
				label={localizationService.getLocalizedString('facility.link.linkName')}
				value={props.formState.displayText}
				onChange={props.changeFormField}
				isRequired={true}
				error={props.formState.nameError}
				maxLength={30}
			/>
			<TextInput
				id="url"
				name="url"
				isRequired={true}
				label={localizationService.getLocalizedString('facility.link.linkUrl')}
				value={props.formState.url}
				onChange={props.changeFormField}
				type="url"
				error={props.formState.linkError}
				helpText={localizationService.getLocalizedString('facility.link.exampleUrl')}
			/>
		</>
	);
};
