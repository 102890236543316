import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { localizationService } from 'src/services';
import { MapLegendKey } from '../map-service';
import { FacilityPinInRed, FacilityPinInGreen, FacilityPinInYellow, FacilityPinInCustom } from '../icons/icons';
import { isMobile } from 'react-device-detect';
import { IncidentMapSvg } from 'src/components/svg';

interface Props {
	mapLegend: MapLegendKey;
	complianceValues: string[];
	setMapLegend: (mapLegend: MapLegendKey) => void;
	inMobileDrawer: boolean;
}
export const FacilityRiskMapFooterOverlay: React.FC<Props> = props => {
	return (
		<div className="d-flex justify-content-center">
			<div className={`footer-overlay ${props.inMobileDrawer ? 'footer-overlay-mobile' : ''}`}>
				<div className={`legend-container${isMobile ? ' legend-container-mobile' : ''}`}>
					<UncontrolledDropdown>
						<DropdownToggle tag="a" className="btn toggle-legend mr-2" caret>
							{props.mapLegend.toString()}
						</DropdownToggle>
						<DropdownMenu>
							{props.mapLegend != MapLegendKey.Compliance && (
								<DropdownItem
									id="legendCompliance"
									tag="a"
									onClick={() => props.setMapLegend(MapLegendKey.Compliance)}>
									{MapLegendKey.Compliance.toString()}
								</DropdownItem>
							)}
							{props.mapLegend != MapLegendKey.Risk && (
								<DropdownItem
									id="legendRisk"
									tag="a"
									onClick={() => props.setMapLegend(MapLegendKey.Risk)}>
									{MapLegendKey.Risk.toString()}
								</DropdownItem>
							)}
							{props.mapLegend != MapLegendKey.DeviceStatus && (
								<DropdownItem
									id="legendDeviceStatus"
									tag="a"
									onClick={() => props.setMapLegend(MapLegendKey.DeviceStatus)}>
									{MapLegendKey.DeviceStatus.toString()}
								</DropdownItem>
							)}
						</DropdownMenu>
					</UncontrolledDropdown>
					{props.mapLegend == MapLegendKey.Risk && (
						<div className="map-legend form-row">
							<div className="col-auto">
								<FacilityPinInRed />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.riskScoreLegend.highRisk')}
								</span>
							</div>
							<div className="col-auto">
								<FacilityPinInYellow />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.riskScoreLegend.mediumRisk')}
								</span>
							</div>
							<div className="col-auto">
								<FacilityPinInGreen />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.riskScoreLegend.lowRisk')}
								</span>
							</div>
							<div className="col-auto">
								<FacilityPinInCustom />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.riskScoreLegend.noRiskSet')}
								</span>
							</div>
							<div className="col-auto">
								<IncidentMapSvg />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('incidents.incident')}
								</span>
							</div>
						</div>
					)}
					{props.mapLegend == MapLegendKey.Compliance && (
						<div className="map-legend form-row">
							<div className="col-auto">
								<FacilityPinInRed />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.nonCompliant')}
								</span>
							</div>
							<div className="col-auto">
								<FacilityPinInGreen />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('facility.COMPLIANT')}
								</span>
							</div>
							{props.complianceValues.map(value => {
								return (
									<div className="col-auto" key={value}>
										<FacilityPinInCustom />
										<span className="ml-2 mr-3">{value}</span>
									</div>
								);
							})}
							<div className="col-auto">
								<IncidentMapSvg />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('incidents.incident')}
								</span>
							</div>
						</div>
					)}
					{props.mapLegend == MapLegendKey.DeviceStatus && (
						<div className="map-legend form-row">
							<div className="col-auto">
								<FacilityPinInRed />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('map.noDevice')}
								</span>
							</div>
							<div className="col-auto">
								<FacilityPinInGreen />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('map.hasDevice')}
								</span>
							</div>
							<div className="col-auto">
								<IncidentMapSvg />
								<span className="ml-2 mr-3">
									{localizationService.getLocalizedString('incidents.incident')}
								</span>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
