import { IppIndustrySample, IppReportPackage } from '@rcp/types';
import { IppFileStore } from '@rcp/types/src';

export interface PackageState {
	reportPackage: IppReportPackage;
	ippSamples: IppIndustrySample[];
	attachments: IppFileStore[];
}
export const initialPackageState: PackageState = {
	reportPackage: {},
	ippSamples: [],
	attachments: []
};
