import React from 'react';
import './chart.scss';
interface Props {
	label: string;
	onClick?: (e: any) => void;
	isActive?: boolean;
	iconSource?: JSX.Element;
	isCustom?: boolean;
}

export const WidgetTypeButton: React.FC<Props> = props => {
	let cssClass = 'btn active ai-white widget-type-btn font-weight-bold';
	if (props.isActive) {
		cssClass += ' widget-active';
	}
	if (props.isCustom) {
		cssClass += ' widget-custom';
	}
	return (
		<button className={cssClass} onClick={props.onClick}>
			{props.iconSource && (
				<span className="mr-1" style={{ color: 'blue' }}>
					{props.iconSource}
				</span>
			)}
			{props.label}
		</button>
	);
};
