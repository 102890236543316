import React from 'react';
import { Dictionary, FacilityCleaningManifest } from '@rcp/types';
import { DateUtilService, localizationService } from '../../../../services';
import FacilitySubmitCleaningModal from '../submit-cleaning.tsx/submit-facility-cleaning';
import { Translate } from 'src/components/widgets/translate/translator';

interface OwnProps {
	cleaningManifest: FacilityCleaningManifest;
	queryParameters?: Dictionary<string>;
}

export const CleaningDateEditActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	return (
		<>
			{props.cleaningManifest.completeDate && (
				<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
					<Translate>{formatDate(`${props.cleaningManifest.completeDate}`)!}</Translate>
				</a>
			)}
			{showModal && (
				<>
					<FacilitySubmitCleaningModal
						showModal={showModal}
						title={localizationService.getLocalizedString('haulerPortal.submitCleaning.submittedCleaning')}
						onCancel={() => toggleModal()}
						hideCancel={true}
						readOnly={true}
						cleaningEventId={props.cleaningManifest.cleaningEventId}
						customFormId={props.cleaningManifest.customFormId}
						hideFooter={true}
						submittedDate={props.cleaningManifest.submittedDateTimeLocal}
					/>
				</>
			)}
		</>
	);
};
