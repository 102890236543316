import { Attachment, UploadMaximumSize50MB } from '@rcp/types';
import React, { FC, useState, useEffect } from 'react';
import { alertService } from 'src/redux';
import { fileUtilService, localizationService, validationService } from 'src/services';
import { DragAndDrop } from './drag-and-drop';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
	faFile,
	faFileExcel,
	faFilePdf,
	faFileWord,
	faFilePowerpoint,
	faFileCsv,
	faFileAudio,
	faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { Translate } from './translate/translator';
import { AttachmentThumbnail } from 'src/components/widgets';

import 'src/components/widgets/attachment/attachment.scss';

interface Props {
	id: string;
	name: string;
	label?: string;
	files?: File[] | Attachment[];
	handleFileUpload?: (e: any, files: File[]) => void;
	helpText?: string;
	error?: string;
	showErrorAndHelp?: boolean;
	hideInput?: boolean;
	isRequired?: boolean;
	inputDisabled?: boolean;
	acceptMultipleFile?: boolean;
	doNotTranslate?: boolean;
}

const supportedAttachmentFileTypes: string = '.docx,.doc,.xls,.xlsx,.pdf,.tif,.tiff,.jpg,.jpeg,.bmp,.png,.txt,.csv';

export const UploadAttachment: FC<Props> = props => {
	const [objectUrl, setObjectUrl] = useState('');
	const [selectedFileName, setSelectedFileName] = useState('');

	const handleIconDelete = () => {
		props.handleFileUpload && props.handleFileUpload({ target: { name: props.name, value: '' } }, []);
		setObjectUrl('');
		setSelectedFileName('');
	};

	const isSupportedFileType = (file: File, supportedFileTypes: string) => {
		let supportedFileExtensions = supportedFileTypes.replace(/[.]/g, '');
		let selectedFileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		return _.toLower(supportedFileExtensions)
			.split(',')
			.includes(_.toLower(selectedFileExtension));
	};

	useEffect(() => {
		if (props.files && !props.files[0]) {
			setObjectUrl('');
			setSelectedFileName('');
		} else if (props.files && props.files[0]) {
			props.files[0].s3ThumbnailUrl && setObjectUrl(props.files[0].s3ThumbnailUrl);
			props.files[0].fileName && setSelectedFileName(props.files[0].fileName);
		}
	}, [props.files]);

	const onFileChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;
		let selectedFiles = _.cloneDeep(props.files || []);

		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}

		let totalFileSize = 0;
		let hasVideo = false;
		for (let x = 0; x < chosenFiles.length; x++) {
			totalFileSize += chosenFiles[x].size;
			let fileType = _.toLower(chosenFiles[x].type);
			if (fileType && fileType.startsWith('video/')) {
				hasVideo = true;
			} else if (!isSupportedFileType(chosenFiles[x], supportedAttachmentFileTypes)) {
				alertService.addError(
					localizationService.getLocalizedString('attachments.fileTypeNotSupported', chosenFiles[x].name)
				);
			} else {
				chosenFiles.length <= 1 && alertService.clearAllMessages();
				selectedFiles!.push(chosenFiles[x]);
			}
		}
		if (hasVideo) {
			alertService.addError(localizationService.getLocalizedString('attachments.uploadVideoIsUnsupported'));
			inputElement.files = null;
			return;
		}

		if (!validationService.validateUploadSize(totalFileSize, UploadMaximumSize50MB)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			inputElement.files = null;
			return;
		}

		setObjectUrl(window.URL.createObjectURL(event.target.files[0] as Blob));
		setSelectedFileName(event.target.files[0].name);
		selectedFiles = selectedFiles.filter(files => files) as File[];
		props.handleFileUpload && props.handleFileUpload(event, [...(selectedFiles as File[])]);
	};

	const handleDrop = (chosenFiles: any, event?: any) => {
		let selectedFiles = _.cloneDeep(props.files || []);

		setObjectUrl(window.URL.createObjectURL(event.dataTransfer.files[0] as Blob));
		setSelectedFileName(event.dataTransfer.files[0].name);

		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}
		for (let i = 0; i < chosenFiles.length; i++) {
			if (!isSupportedFileType(chosenFiles[i], supportedAttachmentFileTypes)) {
				alertService.addError(
					localizationService.getLocalizedString(
						'haulerPortal.submitCleaning.errors.fileTypeNotSupported',
						chosenFiles[i].name
					)
				);
			} else {
				chosenFiles.length <= 1 && alertService.clearAllMessages();
				selectedFiles!.push(chosenFiles[i]);
			}
		}
		selectedFiles = selectedFiles.filter(files => files) as File[];
		props.handleFileUpload && props.handleFileUpload(event, [...(selectedFiles as File[])]);
	};

	const getFilePreview = () => {
		if (!props.files || !props.files[0]) {
			return <></>;
		}

		if (props.files[0].s3ResourceUrl) {
			return (
				<AttachmentThumbnail
					attachment={props.files[0]}
					key={`icon-${props.id}`}
					insideModal
					className="attachment-picture cursor-pointer"
					isDownloadButton={true}
					showFileNameInsteadOfFileType={true}
				/>
			);
		}

		let faIcon;
		let fileType = _.toLower(selectedFileName.slice(((selectedFileName.lastIndexOf('.') - 1) >>> 0) + 2));
		if (fileUtilService.isPicture(fileType)) {
			let divStyle = {
				backgroundImage: `url(${objectUrl})`
			};
			return <div className="attachment-picture" style={divStyle} title={selectedFileName} />;
		} else if (fileUtilService.isPdf(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFilePdf} className="attachment-icon" />;
		} else if (fileUtilService.isOfficeDocument(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFileWord} className="attachment-icon" />;
		} else if (fileUtilService.isSpreadsheet(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFileExcel} className="attachment-icon" />;
		} else if (fileUtilService.isSlide(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFilePowerpoint} className="attachment-icon" />;
		} else if (fileUtilService.isAudio(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFileAudio} className="attachment-icon" />;
		} else if (fileUtilService.isVideo(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFileVideo} className="attachment-icon" />;
		} else if (fileUtilService.isCsv(fileType)) {
			faIcon = <FontAwesomeIcon icon={faFileCsv} className="attachment-icon" />;
		} else {
			faIcon = <FontAwesomeIcon icon={faFile} className="attachment-icon" />;
		}
		return (
			<div className="d-flex flex-column attachment-picture" title={selectedFileName}>
				<div className="mx-auto">{faIcon}</div>
				<div className="mx-auto attachment-file-type wrapWord">{selectedFileName}</div>
			</div>
		);
	};

	return (
		<>
			<div className={`${props.isRequired ? 'form-group required' : ''}`}>
				<div className="d-flex my-1">
					<label className="mt-1 wrapWord">
						<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
					</label>
					{selectedFileName && (
						<div className="thumbnail-delete ml-auto" onClick={handleIconDelete}>
							<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon" />
						</div>
					)}
				</div>
				{selectedFileName ? (
					<>{getFilePreview()}</>
				) : (
					<DragAndDrop
						handleDrop={handleDrop}
						className="drag-submit"
						dragMessage={localizationService.getLocalizedString('haulerPortal.submitCleaning.dragAndDrop')}>
						<div className="file-input-group p-1">
							<label
								className="btn ai-secondary mb-1"
								htmlFor={props.id}
								aria-describedby="file-input-help">
								<Translate doNotTranslate={props.doNotTranslate}>
									{localizationService.getLocalizedString('haulerPortal.submitCleaning.chooseFile')}
								</Translate>
							</label>
							<input
								type="file"
								id={props.id}
								name={props.name}
								className="file-input mr-4 "
								disabled={props.inputDisabled}
								onChange={onFileChangeHandler}
								onClick={(e: any) => (e.target.value = null)}
								style={{ width: '0px' }}
								multiple={props.acceptMultipleFile}
								hidden={props.hideInput ? true : false}
							/>
						</div>
					</DragAndDrop>
				)}
			</div>
			{props.error && (
				<div className="invalid-feedback">
					<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
				</div>
			)}
			{props.helpText && (
				<div className="ai-form-help">
					<Translate doNotTranslate={props.doNotTranslate}>{props.helpText}</Translate>
				</div>
			)}
		</>
	);
};
