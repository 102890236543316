import { ThunkAction } from 'redux-thunk';
import * as Facility from './facility-actions';
import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../alert';
import _ from 'lodash';
import { Enforcement, Violation, MaxInteger } from '@rcp/types';
import { FogFacilityActionType } from './facility-actions';

type FogFacilityThunkAction = ThunkAction<any, any, any, Facility.FogFacilityAction>;

export const loadFogFacility = (
	facilityId: number,
	callbackOnSuccess?: () => void,
	callbackOnError?: () => void
): FogFacilityThunkAction => async dispatch => {
	dispatch(Facility.loadFogFacilityRequest());
	try {
		const fogFacilityUrl = urlService.getAuthorityResourceApiUrl(Resource.FogFacilities, facilityId);
		const fogFacility = await apiService.httpGet(fogFacilityUrl);

		if (callbackOnSuccess) {
			callbackOnSuccess();
		}
		dispatch(Facility.loadFogFacilitySuccess(fogFacility));
	} catch (error) {
		if (callbackOnError) {
			callbackOnError();
		} else {
			Promise.reject(error);
		}
	}
};

export const loadCurrentFogFacility = (
	callbackOnSuccess?: () => void,
	callbackOnError?: () => void,
	fogFacilityId?: number
): FogFacilityThunkAction => async dispatch => {
	try {
		let facilityId = fogFacilityId || urlService.getFogFacilityIdOrThrowError();
		dispatch(loadFogFacility(facilityId, callbackOnSuccess, callbackOnError));
	} catch (error) {
		if (callbackOnError) {
			callbackOnError();
		} else {
			Promise.reject(error);
		}
	}
};

export const saveFogFacility = (
	facilityId: number,
	facilityToUpdate: ApiTypes.FogFacility,
	callbackOnSuccess?: () => void
): FogFacilityThunkAction => async dispatch => {
	dispatch(Facility.saveFogFacilityRequest());

	const facilityUrl = urlService.getAuthorityResourceApiUrl(Resource.FogFacilities, facilityId);
	const updatedFogFacility = await apiService.patchResource<ApiTypes.FogFacility>(facilityUrl, facilityToUpdate);

	dispatch(Facility.saveFogFacilitySuccess(updatedFogFacility));
	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.savedSuccess', 'screen.labels.facility')
	);

	if (callbackOnSuccess) {
		callbackOnSuccess();
	}

	return true;
};

export const addFogFacility = (
	facilityToCreate: ApiTypes.FogFacility,
	callbackOnSuccess?: (facility: ApiTypes.FogFacility) => void
) => async (dispatch: any) => {
	dispatch(Facility.addFogFacilityRequest());
	const fogFacilityUrl = urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities);

	delete facilityToCreate.facilityId;

	let createdFacility = await apiService.postResource(fogFacilityUrl, facilityToCreate);

	dispatch(Facility.addFogFacilitySuccess(createdFacility));

	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.facility')
	);

	if (callbackOnSuccess) {
		callbackOnSuccess(createdFacility);
	}

	return true;
};

export const loadFacilityTimelineEnforcements = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const enforcementsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Enforcements);
	let queryParameters = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate)) {
		queryParameters.put('enforcementIssueDate', `gte:${startDate}`);
	}
	if (!_.isEmpty(endDate)) {
		queryParameters.put('enforcementIssueDate', `lte:${endDate}`);
	}
	let paginatedResult = await apiService.getPaginatedResources<Enforcement>(
		`${enforcementsUrl}?${queryParameters.toQueryString()}`
	);
	dispatch({
		type: FogFacilityActionType.LoadFacilityTimelineEnforcements,
		facilityTimelineEnforcements: paginatedResult.result
	});
};

export const loadFacilityAllViolations = (id?: number) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError(id);

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const violationsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Violations);

	let paginatedResult = await apiService.getPaginatedResources<Violation>(`${violationsUrl}?size=1000&`);
	dispatch({ type: FogFacilityActionType.LoadFacilityAllViolations, facilityAllViolations: paginatedResult.result });
};
