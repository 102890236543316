import React from 'react';

interface Props {
	isActive?: boolean;
}

export const ChartDonutSvg: React.FC<Props> = props => {
	return (
		<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={28} height={24} viewBox="0 0 28 24">
			<path
				d="M14.5 0v6a6 6 0 11-4.64 10.3l-4.24 4.28A12 12 0 1014.5 0z"
				fill={props.isActive ? '#23409a' : '#444'}
			/>
			<path d="M9.21 15.6A6 6 0 0113.5 6V0A12 12 0 005 19.86z" fill={props.isActive ? '#23409a' : '#444'} />
		</svg>
	);
};
