import { UsersActionType, UsersAction } from './users-actions';
import { UsersState, initialUsersState } from './users-state';

export const UsersReducer = (state = initialUsersState, action: UsersAction): UsersState => {
	switch (action.type) {
		case UsersActionType.LoadUserDetailsRequest:
		case UsersActionType.UpdateUser:
			return { ...state };
		case UsersActionType.LoadUserDetailsSuccess:
			return { ...state, userDetails: { ...action.userDetails } };
		default:
			return state;
	}
};
