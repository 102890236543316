import { EntityContact, Extractor, ContactCardType, FacilityExtractorDto, EntityContact_EntityType } from '@rcp/types';
import React from 'react';
import { SingleCheckbox } from 'src/components/widgets';
import { ContactCardBody, ContactModal } from 'src/features/contacts';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import _ from 'lodash';
import './contact-card.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faUser } from '@fortawesome/free-solid-svg-icons';
import { FacilitySvg, GreaseTrap } from '../../components';
import { CustomCard } from '../../components/widgets/custom-card/custom-card';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useHistory } from 'react-router';
import { FogContactCardBody } from './fog-contact-card-body';
import { deviceOwner } from 'src/components/authority/fog/fog-devices/details/device-contacts-tab';
interface Props {
	entityContact: EntityContact;
	deviceSendNotice?: boolean;
	switchSendNotice: (deviceContact: EntityContact, checked: boolean) => void;
	reload: () => void;
	device?: Extractor;
	id: number;
	contactCardType: ContactCardType;
	extractorList?: FacilityExtractorDto[];
	isDeviceDetailsPage?: boolean;
	isPrimaryOrHaulerContactExist?: boolean;
}

export const ContactCard: React.FC<Props> = props => {
	const [isToggleEditContactModal, setToggleEditContactModal] = React.useState(false);

	const history = useHistory();

	const switchContactSendNotices = (e: any) => {
		const { checked } = e.target;
		props.switchSendNotice(props.entityContact, checked);
	};

	const resetModalStateAndReload = () => {
		setToggleEditContactModal(false);
		props.reload();
	};

	const getDeviceList = () => {
		let devices: FacilityExtractorDto[] = [];
		if (props.entityContact.sendNoticeIds && props.extractorList) {
			props.entityContact.sendNoticeIds.forEach(id => {
				if (props.extractorList) {
					let device = props.extractorList.find(a => a.ExtractorId == id && a.SendCleaningNotices);
					if (device) {
						devices.push(device);
					}
				}
			});
		}
		devices = _.sortBy(devices, 'DeviceNumber');
		return devices;
	};

	const navigateTohard = (e: any, device: FacilityExtractorDto) => {
		let link = urlService.getReactAuthorityResourceUrl(Resource.Devices, device.ExtractorId);

		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		navigateTo(history, link);
	};

	const getCustomCardBody = (
		<>
			{((props.entityContact.contactId &&
				props.entityContact.entityType === EntityContact_EntityType.FogFacility) ||
				props.entityContact.entityType === EntityContact_EntityType.Hauler ||
				props.entityContact.entityType === EntityContact_EntityType.FogDevice) && (
				<div className="d-flex justify-content-end">
					{props.entityContact.entityType !== EntityContact_EntityType.Hauler && (
						<a
							href="#/"
							className="float-right"
							onClick={e => {
								e.preventDefault();
								setToggleEditContactModal(true);
							}}>
							{' '}
							{localizationService.getLocalizedString('screen.buttons.edit')}
						</a>
					)}
				</div>
			)}
			{props.entityContact.entityType == deviceOwner && props.device && (
				<div className="d-flex justify-content-end">
					<a
						className="float-right"
						href={urlService.getFacilityDetailsUrl(props.device.facilityId)}
						onClick={(e: any) =>
							navigateTo(history, urlService.getFacilityDetailsUrl(props.device.facilityId), e)
						}>
						{localizationService.getLocalizedString('screen.buttons.view')}
					</a>
				</div>
			)}
			<FogContactCardBody contact={props.entityContact} isDeviceDetailsPage={props.isDeviceDetailsPage} />

			{props.contactCardType === ContactCardType.FogDevice &&
				props.entityContact.entityType != EntityContact_EntityType.Hauler && (
					<div className="mt-3">
						<SingleCheckbox
							id={`contactSendNotices-${props.id}`}
							name={`contactSendNotices-${props.id}`}
							key={props.id}
							label={localizationService.getLocalizedString('extractor.sendNoticesCheckBoxLabel')}
							checked={props.entityContact.sendNotices}
							onChange={switchContactSendNotices}
							isDisabled={!props.deviceSendNotice}
							className=""
						/>
					</div>
				)}
			{!_.isEmpty(getDeviceList()) &&
				props.extractorList &&
				props.contactCardType == ContactCardType.FogFacility && (
					<p className="noticeList">
						<span>
							{localizationService.getLocalizedString(
								'facility.receivingNotices',
								_.toString(getDeviceList().length)
							)}
							&nbsp;
							<UncontrolledDropdown>
								<DropdownToggle tag="a" className="cursor-pointer">
									{localizationService.getLocalizedString(
										getDeviceList().length === 1
											? 'facility.receivedNoticeDevice'
											: 'facility.receivedNoticeDevices',
										_.toString(getDeviceList().length)
									)}
								</DropdownToggle>

								<DropdownMenu>
									{getDeviceList().map(device => (
										<>
											<DropdownItem
												id="deviceDescription"
												tag="a"
												className="cursor-pointer"
												onClick={e => navigateTohard(e, device)}>
												{device.DeviceNumber} {device.DeviceDescription}
											</DropdownItem>
										</>
									))}
								</DropdownMenu>
							</UncontrolledDropdown>
						</span>
					</p>
				)}

			{isToggleEditContactModal && (
				<ContactModal
					close={() => {
						setToggleEditContactModal(false);
					}}
					entityContact={props.entityContact}
					reload={resetModalStateAndReload}
				/>
			)}
		</>
	);

	return (
		<div className="card-wrapper">
			<CustomCard
				customCardBody={getCustomCardBody}
				customCardBodyClass={'custom-contact-body'}
				customCardHeaderClass={`custom-contact-card-header align-items-center`}
				withHeader={false}
			/>
		</div>
	);
};
