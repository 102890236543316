import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { History } from 'history';
import {
	IppPendingRegistrationUser,
	ColumnField,
	FilterType,
	RouteProps,
	LocalStorageName,
	RegulatoryProgramName
} from '@rcp/types';
import { ippPendingRegistrationSlice } from './pending-registrations-list-slice';

import { urlService, localizationService, tokenService } from 'src/services';
import { RootState } from 'src/redux';
import { RestSlice } from 'src/redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';

import { OrganizationTypes } from 'src/constants';
import { nameof } from 'ts-simple-nameof';

interface Props<T> extends RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

export class IppPendingRegistrationsList extends Component<Props<IppPendingRegistrationUser>> {
	private initialGridOption: GridOption = {} as GridOption;

	constructor(props: Props<IppPendingRegistrationUser>) {
		super(props);
		let queryParameters = urlService.toQueryDictionary();
		this.state = { queryParameters };
		this.initialGridOption = {
			showRefreshButton: true,
			showEditColumnButton: true,
			showFilter: false,
			showSearch: false,
			prefix: 'ipp.pendingRegistrationsList.tableColumns',
			storageName: LocalStorageName.IppPendingRegistrationGrid,
			searchPlaceholder: '',
			showActions: false,
			pageTitle: localizationService.getLocalizedString('ipp.pendingRegistrationsList.pageHeading'),
			sort: [],
			defaultSort: [
				{ field: nameof<IppPendingRegistrationUser>(d => d.firstName), dir: 'asc' },
				{ field: nameof<IppPendingRegistrationUser>(d => d.lastName), dir: 'asc' }
			],
			showEditColumnModal: false,
			showAddModal: false,
			showAddButton: false,
			showScheduleInspectionModal: false,
			queryParameters: { isPendingUserApproval: 'true', sort: 'firstName asc, lastName asc' },
			showFilterMenuPopOver: false,
			disableFilterWarning: true,
			anchorElement: null,
			showFilterEditorModal: false,
			showBulkUpdate: false,
			showExportToSpreadsheet: true,
			allColumns: [
				new ColumnField('firstName', FilterType.Text, this.firstNameToLink),
				new ColumnField('lastName', FilterType.Text),
				new ColumnField('email', FilterType.Text),
				new ColumnField('organizationType', FilterType.Text),
				new ColumnField('registeredFor', FilterType.Text),
				new ColumnField('registrationDateTimeUtc', FilterType.Date)
			],

			defaultColumns: urlService.isIppIndustryPortal()
				? ['firstName', 'lastName', 'email', 'registeredFor', 'registrationDateTimeUtc']
				: ['firstName', 'lastName', 'email', 'organizationType', 'registeredFor', 'registrationDateTimeUtc']
		};
	}

	public render() {
		return (
			<React.Fragment>
				<div className="page-wrapper">
					<div className="main settings">
						<div className="row">
							<DataGrid
								history={this.props.history}
								match={this.props.match}
								restSlice={ippPendingRegistrationSlice}
								restState={this.pendingRegistrationState}
								gridOption={this.initialGridOption}></DataGrid>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}

	private firstNameToLink = (item: any) => {
		const isAdmin = tokenService.isAdminToken(RegulatoryProgramName.IPP);
		const dataItem = item.dataItem as IppPendingRegistrationUser;
		const url = `${window.location.pathname}/users/${dataItem.ippUserId}`;
		if (
			isAdmin ||
			(!urlService.isIppIndustryPortal() &&
				dataItem.organizationType &&
				dataItem.organizationType.toLowerCase() !== OrganizationTypes.authority.toLowerCase())
		) {
			return (
				<td>
					<Link to={url}>{dataItem.firstName}</Link>
				</td>
			);
		}
		return <td>{dataItem.firstName}</td>;
	};

	private pendingRegistrationState = (state: RootState) => {
		return state.ippPendingRegistrationUsers;
	};
}
