import * as React from 'react';
import { EditorUtils } from '@progress/kendo-react-editor';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useRootStateSelector } from 'src/redux';
import { MergeFieldDto, DropDownOption } from '@rcp/types/src';
import { ComboBoxDropdown } from '../combo-box-dropdown';
import { localizationService } from 'src/services';
import _ from 'lodash';

interface OwnProps {
	customMergeFieldList?: string[];
	customMergeFields?: MergeFieldDto[];
	setIsMergeFieldAdded: any;
	hidePageNumber?: boolean;
}
type Props = OwnProps;

const InsertMergeFieldTool: React.FC<Props> = props => {
	const mergeFields: MergeFieldDto[] = useRootStateSelector(s => s.mergeFields.result);
	const { view } = props;
	const nodeType = view && view.state.schema.nodes['text'];
	const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
	let data = [];
	let mergeOptions: DropDownOption[] = [];
	if (props.customMergeFieldList) {
		data = props.customMergeFieldList;
	} else {
		mergeOptions = (props.customMergeFields || mergeFields).map(field => {
			let option: DropDownOption = {
				label: field.label as string,
				value: field.prefix ? `${field.prefix} - ${field.label}` : field.label,
				prefix: field.prefix
			};
			return option;
		});
		data = mergeFields;
	}

	const onChange = (event: DropDownListChangeEvent) => {
		const fieldToInsert = event.target.value;
		if (fieldToInsert) {
			EditorUtils.insertNode(view, view.state.schema.text('{{' + fieldToInsert + '}}'));
			props.setIsMergeFieldAdded(true);
		}
	};

	if (props.customMergeFieldList) {
		return (
			<DropDownList
				data={(data as any[]).filter((mergeField: any) =>
					props.hidePageNumber ? !_.toLower(mergeField).includes('page number') : mergeField
				)}
				onChange={onChange}
				defaultValue={localizationService.getLocalizedString('notices.mergeTags')}
				value={localizationService.getLocalizedString('notices.mergeTags')}
				className="merge-field-dropdown"></DropDownList>
		);
	}

	return (
		<ComboBoxDropdown
			id={'mergeField'}
			name={'mergeField'}
			value={''}
			onChange={onChange}
			options={mergeOptions.filter((mergeOption: DropDownOption) => {
				return props.hidePageNumber ? _.toLower(mergeOption.label) !== 'page number' : mergeOption;
			})}
			isCustomSorting={true}
			clearButton={false}
			placeholder={localizationService.getLocalizedString('notices.searchMergeTags')}
		/>
	);
};

export { InsertMergeFieldTool };
