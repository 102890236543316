import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { AttachmentOwnership, AttachmentType, RouteProps } from '@rcp/types';
import {
	ApplicationState,
	FacilityAttachmentsState,
	loadFacilityAttachments,
	defaultNumberOfAttachments,
	loadFogFacility,
	FogFacilityState,
	deleteFacilityAttachment,
	DeviceAttachmentsState,
	loadDeviceAttachments,
	RootState
} from 'src/redux';
import { urlService, localizationService, navigateTo } from 'src/services';

import 'src/components/widgets/attachment/attachment.scss';
import { DeleteModal, DeleteModalProp, AttachmentContent } from 'src/components/widgets';
import { useHistory, useLocation } from 'react-router-dom';
import { InspectionEvent } from '@rcp/types/src';
import { fogDevicesSlice } from '../../fog-devices/devices.slice';

interface FocusedAttachments {
	currentAttachment: AttachmentOwnership;
	previousAttachment?: AttachmentOwnership;
	nextAttachment?: AttachmentOwnership;
}

interface StateProps extends FacilityAttachmentsState, FogFacilityState, DeviceAttachmentsState {}

interface DispatchProps {
	loadFogFacility: (facilityId: number) => void;
	loadFacilityAttachments: (facilityId: number, doesGetAll: boolean, ownerId?: number) => void;
	loadDeviceAttachments: (deviceId: number, doesGetAll: boolean, ownerId?: number) => void;
	deleteFacilityAttachment: (
		facilityId: number,
		facilityAttachmentId: number,
		succeededAlertMessage: string,
		callbackOnSuccess?: () => void
	) => void;
}

interface LocationState {
	editMode: boolean;
}

interface CompProps {
	currentInspection?: InspectionEvent;
	currentAttachment: AttachmentOwnership;
	editMode?: boolean;
	noNavigation?: boolean;
	onDelete?: () => void;
	onAttachmentChange?: (attachment: AttachmentOwnership) => void;
	toggleAttachmentModal?: () => void;
}

type Props = StateProps & DispatchProps & RouteProps & CompProps;

const FacilityAttachmentDetailsComp: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const initialFocusedAttachments = {
		currentAttachment: props.currentAttachment ?? {}
	} as FocusedAttachments;

	const [focusedAttachments] = React.useState(initialFocusedAttachments);
	const [isToggleDelete, setIsToggleDelete] = React.useState(false);
	const [isDeviceAttachments, setIsDeviceAttachments] = React.useState(false);
	const [deleteAttachmentEnabled, setDeleteAttachmentEnabled] = React.useState(false);
	const history = useHistory();
	const location = useLocation<LocationState>();

	let selectedDevice = useSelector((state: RootState) => state.fogDevices).selected;

	const onToggleDeleteModal = () => {
		setIsToggleDelete(!isToggleDelete);
	};

	let queryParameters = urlService.toQueryDictionary();
	let returnRef = urlService.getAttachmentReturnToUrl();
	let returnToLabel = queryParameters['backToLabel'] || 'backToFacility';
	let editMode = props.editMode ?? (location.state !== undefined && location.state.editMode);

	const getAttachmentId = () => props.currentAttachment?.attachmentId ?? getAttachmentIdFromUrl();

	const deleteAttachment = () => {
		let facilityId = urlService.getFogFacilityId();
		if (facilityId <= 0 && selectedDevice?.facilityId) {
			facilityId = selectedDevice.facilityId;
		}
		const facilityAttachmentId = getAttachmentId();
		const successDeleteMessage = localizationService.getLocalizedString('attachments.deleteSuccess');
		props.deleteFacilityAttachment(facilityId, facilityAttachmentId, successDeleteMessage, () => {
			if (props.noNavigation && props.onDelete) {
				onToggleDeleteModal();
				props.onDelete();
			} else {
				navigateTo(history, returnRef, null, { editMode: editMode });
			}
		});
	};

	let deleteModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('attachments.confirmDeleteTitle'),
		message: localizationService.getLocalizedString('attachments.confirmDeleteMessage'),
		showModal: isToggleDelete,
		onCancelButtonClick: onToggleDeleteModal,
		onOkayButtonClick: deleteAttachment,
		okayButtonText: 'Delete',
		isDeleteButton: true
	};

	React.useEffect(() => {
		const facilityId = urlService.getFogFacilityId();

		if (facilityId > 0) {
			if (_.isEmpty(props.facility)) {
				props.loadFogFacility(facilityId);
			}
			if (
				_.isEmpty(props.facilityAttachments) ||
				props.facilityAttachments.length <= defaultNumberOfAttachments
			) {
				let inspectionId = props.currentInspection?.inspectionEventId ?? props.match.params.inspectionId;
				props.loadFacilityAttachments(facilityId, true, inspectionId);
			}
		} else {
			const deviceId = urlService.getFogDeviceId();
			dispatch(fogDevicesSlice.fetchOne(deviceId));
			props.loadDeviceAttachments(deviceId, true);
			setIsDeviceAttachments(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.facility]);

	React.useEffect(() => {
		if (selectedDevice) {
			if (!selectedDevice.extractorId || !selectedDevice.facilityId) return;
			if (_.isEmpty(props.facility)) {
				props.loadFogFacility(selectedDevice.facilityId);
			}
		}
	}, [selectedDevice]);

	const getAttachmentUrl = (attachment: AttachmentOwnership): string => {
		return urlService.getAttachmentNavigateUrl(attachment);
	};

	const getAttachmentIdFromUrl = (): number => {
		return urlService.getAttachmentIdFromUrl();
	};

	const navigateCallback = (attachmentId: number) => {
		let attachments = isDeviceAttachments ? props.deviceAttachments : props.facilityAttachments;
		const currentAttachment = attachments.find(attachment => {
			return attachment.attachmentId === attachmentId;
		});

		setDeleteAttachmentEnabled(
			(currentAttachment &&
				(currentAttachment.attachmentType == null ||
					currentAttachment.attachmentType == AttachmentType.FogDevice)) ||
				editMode
		);
	};

	return (
		<div className="page">
			<div className="page-header">
				<h1 className="text-left">{localizationService.getLocalizedString('attachments.details.title')}</h1>
				{deleteAttachmentEnabled && (
					<div>
						<button className="btn ai-secondary-delete" onClick={onToggleDeleteModal}>
							{localizationService.getLocalizedString('screen.buttons.delete')}
						</button>
					</div>
				)}
			</div>
			{(isDeviceAttachments ? props.deviceAttachments : props.facilityAttachments) && (
				<AttachmentContent
					readOnly={false}
					facility={props.facility}
					device={selectedDevice}
					attachments={isDeviceAttachments ? props.deviceAttachments : props.facilityAttachments}
					{...focusedAttachments}
					returnLabel={props.noNavigation ? undefined : returnToLabel}
					returnUrl={props.noNavigation ? undefined : returnRef}
					getAttachmentUrl={getAttachmentUrl}
					getAttachmentId={getAttachmentId}
					navigateCallback={navigateCallback}
					onAttachmentChange={props.onAttachmentChange}
					toggleAttachmentModal={props.toggleAttachmentModal}
					editMode={editMode}
					noNavigation={props.noNavigation}
				/>
			)}
			<DeleteModal {...deleteModalProp} key="deleteAttachmentModal" />
		</div>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.fogFacility, ...state.facilityAttachments, ...state.deviceAttachments };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => ({
	loadFogFacility: (facilityId: number) => dispatch(loadFogFacility(facilityId)),
	loadFacilityAttachments: (facilityId: number, doesGetAll: boolean, ownerId?: number) =>
		dispatch(loadFacilityAttachments(facilityId, doesGetAll, undefined, ownerId)),
	loadDeviceAttachments: (deviceId: number, doesGetAll: boolean, ownerId?: number) =>
		dispatch(loadDeviceAttachments(deviceId, doesGetAll, AttachmentType.FogDevice, ownerId)),
	deleteFacilityAttachment: (
		facilityId: number,
		facilityAttachmentId: number,
		succeededAlertMessage: string,
		callbackOnSuccess?: () => void
	) => dispatch(deleteFacilityAttachment(facilityId, facilityAttachmentId, succeededAlertMessage, callbackOnSuccess))
});

export const FacilityAttachmentDetails = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilityAttachmentDetailsComp);
