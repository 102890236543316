import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { tokenService } from './token-service';
import { MonthOffset } from '@rcp/types';
import { localizationService } from './localizationService';

export class DateUtilService {
	static floorDate(date: moment.Moment, useAuthorityTimeZone: boolean = true): string {
		let timezone = useAuthorityTimeZone ? DateUtilService.getAuthorityTimezone() : moment.tz.guess(true);
		return moment
			.tz(
				moment(date)
					.startOf('day')
					.format('YYYY-MM-DDTHH:mm:ss'),
				timezone
			)
			.format();
	}

	static ceilingDate(date: moment.Moment, useAuthorityTimeZone: boolean = true): string {
		let timezone = useAuthorityTimeZone ? DateUtilService.getAuthorityTimezone() : moment.tz.guess(true);
		return moment
			.tz(
				moment(date)
					.endOf('day')
					.format('YYYY-MM-DDTHH:mm:ss'),
				timezone
			)
			.format();
	}

	static getDateRangeByDays(offsetStartDays: number, offsetEndDays: number): string {
		let format = 'MMM D, YYYY';
		let st = DateUtilService.getAuthorityTimezoneNowAsMoment().add(offsetStartDays, 'days');
		let et = DateUtilService.getAuthorityTimezoneNowAsMoment().add(offsetEndDays, 'days');
		return st.format(format) + ' - ' + et.format(format);
	}

	static getDateRangeByMonth(offsetMonth: number): string {
		let now = DateUtilService.getAuthorityTimezoneNowAsMoment();
		let dayOfTheMonth = now.add(offsetMonth, 'months');
		return dayOfTheMonth.format('MMM, YYYY');
	}

	static getDateRangeByYear(offsetYear: number): string {
		let now = DateUtilService.getAuthorityTimezoneNowAsMoment();
		let dayOfTheYear = now.add(offsetYear, 'years');
		return dayOfTheYear.format('YYYY');
	}

	static isValidDateTime(dateTimeStr: any): boolean {
		if (dateTimeStr instanceof Date) {
			return !isNaN(dateTimeStr.getTime());
		}

		if (_.isNil(dateTimeStr) || _.isEmpty(dateTimeStr)) {
			return false;
		}
		var date = new Date(dateTimeStr);
		return date instanceof Date && !isNaN(date.getTime());
	}

	static getAuthorityTimezone(): string {
		return tokenService.getTokenOrDefault().ianaTimeZoneName as string;
	}

	static getMomentInAuthorityTimeZone(dateTimeStr?: string): moment.Moment {
		let authorityTimeZone = DateUtilService.getAuthorityTimezone();

		if (!authorityTimeZone || authorityTimeZone.trim().length === 0) {
			console.error('Authority TimeZone not found');
			return moment.tz(dateTimeStr, moment.tz.guess(true));
		} else {
			return moment.tz(dateTimeStr, authorityTimeZone);
		}
	}

	static getDatePeriod(theDayStr: string): { startOfDay: string; endOfTheDay: string } {
		let timezone = DateUtilService.getAuthorityTimezone() || moment.tz.guess(true);
		let startOfTheDay = moment(theDayStr)
			.tz(timezone)
			.startOf('day')
			.format('YYYY-MM-DDTHH:mm:ssZ');
		let endOfTheDay = moment(theDayStr)
			.tz(timezone)
			.endOf('day')
			.format('YYYY-MM-DDTHH:mm:ssZ');
		return { startOfDay: startOfTheDay, endOfTheDay: endOfTheDay };
	}

	static getDateStringInAuthorityTimeZone(dateTimeStr?: string, displayFormat?: string): string {
		if (!dateTimeStr || !DateUtilService.isValidDateTime(dateTimeStr)) {
			return '';
		}

		let newDateTime = DateUtilService.getMomentInAuthorityTimeZone(dateTimeStr);

		if (displayFormat) {
			return newDateTime.format(displayFormat);
		}
		return newDateTime.format();
	}

	static getAuthorityTimezoneNowAsMoment(): moment.Moment {
		let authorityTimeZone = DateUtilService.getAuthorityTimezone();

		if (!authorityTimeZone || authorityTimeZone.trim().length === 0) {
			console.error('Authority TimeZone not found');
			return moment().tz(moment.tz.guess(true));
		} else {
			return moment().tz(DateUtilService.getAuthorityTimezone());
		}
	}

	static getAuthorityTimezoneNow(): string {
		return DateUtilService.getAuthorityTimezoneNowAsMoment().format();
	}

	static toDisplayDate(
		dateTimeStr?: string,
		format: string = localizationService.getLocalizedString('dateFormats.displayDate')
	): string {
		return DateUtilService.getDateStringInAuthorityTimeZone(dateTimeStr, format) as string;
	}

	static toDisplayTime(
		dateTimeStr?: string,
		format: string = localizationService.getLocalizedString('dateFormats.displayTime')
	): string {
		return DateUtilService.getDateStringInAuthorityTimeZone(dateTimeStr, format) as string;
	}

	static combineDateTimeFromTwoDate(dateTimeStringOfDatePart?: string, dateTimeStringOfTimePart?: string) {
		let dateString = DateUtilService.getDateStringInAuthorityTimeZone(
			dateTimeStringOfDatePart,
			'YYYY-MM-DD'
		) as string;
		let timeString = DateUtilService.getDateStringInAuthorityTimeZone(
			dateTimeStringOfTimePart,
			'HH:mm:ss'
		) as string;
		return `${dateString}T${timeString}`;
	}

	static prepareDateStringForApiCall(dateTimeStrInBrowserTimezone: string | undefined): string {
		if (!dateTimeStrInBrowserTimezone || !DateUtilService.isValidDateTime(dateTimeStrInBrowserTimezone)) {
			return '';
		}

		return `${moment(dateTimeStrInBrowserTimezone)
			.tz(moment.tz.guess(true))
			.format('YYYY-MM-DDTHH:mm:ss')}${moment(dateTimeStrInBrowserTimezone)
			.tz(DateUtilService.getAuthorityTimezone())
			.format('Z')}`;
	}

	static prepareDateForDateTimePicker(
		dateTimeStrInAuthorityTimezone: string | moment.Moment,
		followLocalTimezone = false
	): Date {
		let time;
		if (followLocalTimezone) {
			time = moment(
				`${moment(dateTimeStrInAuthorityTimezone)
					.tz(moment.tz.guess(true))
					.format('YYYY-MM-DDTHH:mm:ss')}${moment(dateTimeStrInAuthorityTimezone)
					.tz(moment.tz.guess(true))
					.format('Z')}`
			).toDate();
		} else {
			time = moment(
				`${moment(dateTimeStrInAuthorityTimezone)
					.tz(DateUtilService.getAuthorityTimezone())
					.format('YYYY-MM-DDTHH:mm:ss')}${moment(dateTimeStrInAuthorityTimezone)
					.tz(moment.tz.guess(true))
					.format('Z')}`
			).toDate();
		}
		return time;
	}

	static checkDateInBrowserTimeZone(dateTimeStr: string | moment.Moment): boolean {
		const MIN_YEAR = 1900;
		// year should be valid and 4 digit to get correct timezone offset from moment.
		if (!dateTimeStr || moment(dateTimeStr).year() < MIN_YEAR) {
			return true;
		}
		let browserTimeZone = moment(dateTimeStr)
			.tz(moment.tz.guess(true))
			.format('Z');
		let inputDateTimeZone = moment(dateTimeStr).format('Z');
		return browserTimeZone === inputDateTimeZone;
	}

	static isDateInRage(dateToCheck: string, startDate: string, endDate: string): boolean {
		return (
			DateUtilService.isSameOrAfter(dateToCheck, startDate) &&
			DateUtilService.isSameOrBefore(dateToCheck, endDate)
		);
	}

	static isAfterToday(dateToCheck: string): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isAfter(
			DateUtilService.getAuthorityTimezoneNowAsMoment(),
			'day'
		);
	}

	static isBeforeToday(dateToCheck: string): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isBefore(
			DateUtilService.getAuthorityTimezoneNowAsMoment(),
			'day'
		);
	}

	static getDifferenceFromCurrentDateInDays(dateToCheck: string): any {
		return DateUtilService.getAuthorityTimezoneNowAsMoment().diff(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCheck),
			'day'
		);
	}

	static isBefore(dateToCheck: string, dateToCompareWith: string, granularity?: moment.unitOfTime.StartOf): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isBefore(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCompareWith),
			granularity
		);
	}

	static isSameOrBefore(dateToCheck: string, dateToCompareWith: string): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isSameOrBefore(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCompareWith)
		);
	}

	static isSame(dateToCheck: string, dateToCompareWith: string, granularity?: moment.unitOfTime.StartOf): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isSame(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCompareWith),
			granularity
		);
	}

	static isAfter(dateToCheck: string, dateToCompareWith: string, granularity?: moment.unitOfTime.StartOf): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isAfter(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCompareWith),
			granularity
		);
	}

	static isAfterByDay(dateToCheck: string, dateToCompareWith: string): boolean {
		return DateUtilService.isAfter(dateToCheck, dateToCompareWith, 'days');
	}

	static isSameOrAfter(dateToCheck: string, dateToCompareWith: string): boolean {
		return DateUtilService.getMomentInAuthorityTimeZone(dateToCheck).isSameOrAfter(
			DateUtilService.getMomentInAuthorityTimeZone(dateToCompareWith)
		);
	}

	static getRelativeDate(offset: MonthOffset, offsetMonthMultiplier: number): string {
		let offsetMonths = offset * offsetMonthMultiplier;
		let authorityNow = DateUtilService.getAuthorityTimezoneNowAsMoment();
		return DateUtilService.getDateStringInAuthorityTimeZone(authorityNow.add(offsetMonths, 'month').format());
	}

	static getDateByFormat(date: string, format: string) {
		return moment(date).format(format);
	}

	static formatDate = (date: string) => {
		return moment(date).format('YYYY-MM-DD') + ' ' + moment(date).format('HH:mm a');
	};

	static daysBetweenDates(endDate: string, startDate: string) {
		return DateUtilService.getMomentInAuthorityTimeZone(endDate).diff(
			DateUtilService.getMomentInAuthorityTimeZone(startDate),
			'days'
		);
	}
}
