import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as ApiTypes from '@rcp/types';
import { apiService, urlService, Resource } from 'src/services';
import _ from 'lodash';

export interface CustomFieldDefinitionsState {
	customFieldDefinitions: ApiTypes.CustomFieldDefinition[];
}

export const initialCustomFieldDefinitionsState: CustomFieldDefinitionsState = {
	customFieldDefinitions: []
};

enum LoadCustomFieldDefinitionsActionType {
	LoadCustomFieldDefinitionsRequest = 'loadCustomFieldDefinitionsRequest',
	LoadCustomFieldDefinitionsSuccess = 'loadCustomFieldDefinitionsSuccess'
}

export interface LoadCustomFieldDefinitionsRequest extends Action {
	type: LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsRequest;
}

export interface LoadCustomFieldDefinitionsSuccess extends Action {
	type: LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsSuccess;
	customFieldDefinitions: ApiTypes.CustomFieldDefinition[];
}

export type LoadCustomFieldDefinitionsAction = LoadCustomFieldDefinitionsRequest | LoadCustomFieldDefinitionsSuccess;

export const loadCustomFieldDefinitionsRequest = (): LoadCustomFieldDefinitionsRequest => ({
	type: LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsRequest
});

export const loadCustomFieldDefinitionsSuccess = (
	customFieldDefinitions: ApiTypes.CustomFieldDefinition[]
): LoadCustomFieldDefinitionsSuccess => ({
	type: LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsSuccess,
	customFieldDefinitions
});

export type LoadCustomFieldDefinitionsThunkAction = ThunkAction<
	any,
	CustomFieldDefinitionsState,
	any,
	LoadCustomFieldDefinitionsAction
>;

export const loadCustomFieldDefinitions = (appliedToTable: string): LoadCustomFieldDefinitionsThunkAction => async (
	dispatch: any
) => {
	dispatch(loadCustomFieldDefinitionsRequest());
	let queryString = `size=50&appliedToTable=in:${appliedToTable}`;
	let url = urlService.getAuthoritySettingResourceApiUrl(Resource.CustomFieldDefinitions, queryString);
	let paginatedResult = await apiService.getPaginatedResources<ApiTypes.CustomFieldDefinition>(url);
	var customFieldDefinitions = paginatedResult.result;
	let uniqueCustomFields = _.remove(customFieldDefinitions, x => {
		return x.isImportKey === true;
	});
	let requiredCustomFields = _.remove(customFieldDefinitions, x => {
		return x.isRequired === true;
	});
	let customFields = _.orderBy(uniqueCustomFields, 'fieldLabel')
		.concat(_.orderBy(requiredCustomFields, 'fieldLabel'))
		.concat(_.orderBy(customFieldDefinitions, 'fieldLabel'));
	dispatch(loadCustomFieldDefinitionsSuccess(customFields));
};

export const customFieldDefinitionsReducer = (
	state = initialCustomFieldDefinitionsState,
	action: LoadCustomFieldDefinitionsAction
): CustomFieldDefinitionsState => {
	switch (action.type) {
		case LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsRequest:
			return { ...state, customFieldDefinitions: [] };
		case LoadCustomFieldDefinitionsActionType.LoadCustomFieldDefinitionsSuccess:
			return { ...state, customFieldDefinitions: action.customFieldDefinitions };
		default:
			return state;
	}
};
