import _ from 'lodash';
import React from 'react';
import { MultiSelectDropdown } from '../../components/widgets';
import { FilterItem } from '../../services/data-types/filter-types';
import { apiService, localizationService, urlService } from 'src/services';
import { FilterDomain, filterService } from './filter-service';
import { ReportPackageStatus } from 'src/constants';

interface FilterMultiTextProps {
	rowNumber: number;
	onChange?(selectedValues: string[]): void;
	filterItem: FilterItem;
	allowCustomValue?: boolean;
	withoutEmptyOption?: boolean;
	reportPackageElementTypeId?: string;
	isForMap?: boolean;
}

export const FilterMultiText: React.FC<FilterMultiTextProps> = props => {
	let getValueLoader = (): Promise<string[]> => {
		if (props.filterItem.domainObject.name && props.filterItem.property.name) {
			let lookupUrl = props.filterItem.domainObject.url;
			let filterQueryParam: string = '';
			let reportPackageSampleQueryParam: string = `?reportStatusName=Draft&reportPackageElementTypeId=${props.reportPackageElementTypeId}`;
			let filterDomainObjectName = _.toUpper(props.filterItem.domainObject.name);
			if (filterDomainObjectName === FilterDomain.IPPALLREPORTPACKAGE) {
				filterQueryParam = `?reportStatusName=${ReportPackageStatus.All}`;
			} else if (filterDomainObjectName === FilterDomain.IPPSUBMITTEDPENDINGREVIEWREPORTPACKAGE) {
				filterQueryParam = `?reportStatusName=${ReportPackageStatus.PendingReview}`;
			} else if (filterDomainObjectName === FilterDomain.IPPREPUDIATEDPENDINGREVIEWREPORTPACKAGE) {
				filterQueryParam = `?reportStatusName=${ReportPackageStatus.RepudiatedPendingReview}`;
			} else if (filterDomainObjectName === FilterDomain.IPPSUBMITTEDREPORTPACKAGE) {
				filterQueryParam = `?reportStatusName=${ReportPackageStatus.Submitted}`;
			} else if (filterDomainObjectName === FilterDomain.IPPREPUDIATEDREPORTPACKAGE) {
				filterQueryParam = `?reportStatusName=${ReportPackageStatus.Repudiated}`;
			} else if (filterDomainObjectName === FilterDomain.IPPREPORTEDSAMPLE) {
				filterQueryParam = `?sampleStatusName=Reported`;
			} else {
				filterQueryParam = '';
			}

			let fieldName = props.filterItem.property.name;
			if (props.filterItem.property.isCustomField) {
				fieldName = `customFields.${fieldName}`;
			}
			return apiService.getResource<string[]>(
				`${lookupUrl}/Fields/${fieldName}${
					urlService.isReportPackageDetailsPage() ? reportPackageSampleQueryParam : filterQueryParam
				}`
			);
		}
		return Promise.resolve([]);
	};

	let name = `FilterOperatorTextValue_${props.rowNumber}-${props.filterItem.property.name}`;
	let operatorValue = filterService.getFilterValue(props.filterItem, 0);
	let inputGroupCss = props.isForMap ? 'form-group' : 'form-group col-lg';
	return (
		<div className={inputGroupCss}>
			<label htmlFor={name}>{localizationService.getLocalizedString('screen.filterModal.value')}</label>
			<MultiSelectDropdown
				name={name}
				onChange={props.onChange}
				withoutEmptyOption={props.withoutEmptyOption}
				loadStringOptions={getValueLoader}
				className={`kendo-control ${operatorValue.error ? ' is-invalid' : ''}`}
				isRequired={true}
				filterItem={props.filterItem}
				selectedValues={operatorValue.values}
				allowCustomValue={props.allowCustomValue}
			/>
			{operatorValue.error && <div className="invalid-feedback">{operatorValue.error}</div>}
		</div>
	);
};
