import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { alertService } from '../alert';

export interface FacilityEquipmentsState {
	equipments: ApiTypes.Equipment[];
	equipmentTypes: ApiTypes.EquipmentType[];
}

export const initialFacilityEquipmentsState: FacilityEquipmentsState = {
	equipmentTypes: [],
	equipments: []
};

enum ActionType {
	ReceivedAuthorityEquipmentTypesType = 'receivedAuthorityEquipmentTypes',
	ReceivedFacilityEquipmentsType = 'receivedFacilityEquipments',
	FacilityEquipmentUpdatedType = 'facilityEquipmentUpdated',
	FacilityEquipmentDeletedType = 'facilityEquipmentDeleted',
	FacilityEquipmentCreatedType = 'facilityEquipmentCreated'
}

export type CurrentFacilityEquipmentThunkAction = ThunkAction<any, FacilityEquipmentsState, any, Action>;

export const loadAuthorityEquipmentTypes = () => async (dispatch: any) => {
	const equipmentTypesUrl = urlService.getAuthorityLookupResourceApiUrl(Resource.EquipmentTypes);
	const equipmentTypes = await apiService.httpGet(equipmentTypesUrl);

	dispatch({ type: ActionType.ReceivedAuthorityEquipmentTypesType, equipmentTypes });
};

export const loadFacilityEquipments = () => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityId();
	if (!facilityId || facilityId < 1) return;

	const equipmentsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Equipment);
	const equipmentsResult = await apiService.httpGet(equipmentsUrl);
	const equipments = equipmentsResult.result;

	dispatch({ type: ActionType.ReceivedFacilityEquipmentsType, equipments });
};

export const updateFacilityEquipment = (equipmentId: number, dataToUpdate: ApiTypes.Equipment) => async (
	dispatch: any
) => {
	let facilityId = urlService.getFogFacilityId();

	if (!facilityId || facilityId < 1) return;
	const equipmentsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Equipment, equipmentId);

	const updatedEquipment = await apiService.httpPatch(equipmentsUrl, dataToUpdate);
	dispatch({ type: ActionType.FacilityEquipmentUpdatedType, updatedEquipment });
	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.updateSuccess', updatedEquipment.equipmentType)
	);
	return true;
};

export const createFacilityEquipment = (equipmentToCreate: ApiTypes.Equipment) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityId();
	if (!facilityId || facilityId < 1) return;
	const equipmentsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Equipment);

	let postData = {
		items: [] as ApiTypes.Equipment[]
	};
	delete equipmentToCreate.equipmentId;
	postData.items.push(equipmentToCreate);

	const createdResponse = await apiService.httpPost(equipmentsUrl, postData);
	let createdEquipment = createdResponse.items[0];
	dispatch({ type: ActionType.FacilityEquipmentCreatedType, createdEquipment });
	alertService.addSuccess(
		localizationService.getLocalizedString('alertMessages.addSuccess', createdEquipment.equipmentType)
	);
	return true;
};

export const deleteFacilityEquipment = (equipmentId: number, equipmentType: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityId();

	if (!facilityId || facilityId < 1) return;
	const equipmentsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Equipment, equipmentId);

	await apiService.httpDelete(equipmentsUrl);
	dispatch({ type: ActionType.FacilityEquipmentDeletedType, equipmentId });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.removeSuccess', equipmentType));
	return true;
};

export const equipmentReducer = (state = initialFacilityEquipmentsState, action: any): FacilityEquipmentsState => {
	switch (action.type) {
		case ActionType.ReceivedAuthorityEquipmentTypesType:
			return { ...state, equipmentTypes: action.equipmentTypes };
		case ActionType.ReceivedFacilityEquipmentsType:
			return { ...state, equipments: action.equipments };
		case ActionType.FacilityEquipmentUpdatedType: {
			let equipments = state.equipments as ApiTypes.Equipment[];
			if (equipments) {
				let newEquipments = equipments.map(ele => {
					if (ele.equipmentId === action.updatedEquipment.equipmentId) {
						return action.updatedEquipment;
					}
					return ele;
				});

				equipments = newEquipments;
			}
			return {
				...state,
				equipments: equipments
			};
		}
		case ActionType.FacilityEquipmentDeletedType: {
			let equipments = state.equipments as ApiTypes.Equipment[];
			if (equipments) {
				let newEquipments = equipments.filter(ele => ele.equipmentId !== action.equipmentId);
				state.equipments = newEquipments;
			}
			return {
				...state
			};
		}
		case ActionType.FacilityEquipmentCreatedType: {
			let equipments = state.equipments as ApiTypes.Equipment[];
			let newEquipments = equipments.map(i => i);
			newEquipments.push(action.createdEquipment);

			state.equipments = newEquipments;
			return {
				...state
			};
		}

		default:
			return { ...state };
	}
};
