import { apiService, Resource, urlService, localizationService, QueryParameters } from 'src/services';
import * as ApiTypes from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { alertService } from '..';
import _ from 'lodash';
import { MaxInteger } from '@rcp/types';

export interface FacilityCommentsState {
	facilityComments: ApiTypes.Comment[];
}

export const initialFacilityCommentsState: FacilityCommentsState = {
	facilityComments: []
};

enum CommentActionType {
	SaveCommentRequestType = 'saveCommentRequest',
	SaveCommentSucceedType = 'saveCommentSucceed',
	EditCommentSucceedType = 'editCommentSucceed',
	DeleteCommentSucceedType = 'deleteCommentSucceed',
	ReceivedCommentsType = 'receivedComments'
}

export type CommentThunkAction = ThunkAction<any, FacilityCommentsState, any, Action>;

const createFacilityCommentURL = (commentId?: number): string => {
	let facilityId = urlService.getFogFacilityId();
	return urlService.getFacilityResourceApiUrl(facilityId, Resource.Comments, commentId);
};

export const saveFogFacilityComment = (commentText: string): CommentThunkAction => async dispatch => {
	let comment: ApiTypes.Comment = {
		commentText: commentText
	};
	let facilityCommentUrl = createFacilityCommentURL();

	let commentCreated = await apiService.postResource(facilityCommentUrl, comment);
	dispatch({ type: CommentActionType.SaveCommentSucceedType, commentCreated });

	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', 'comments.comment'));
	return true;
};

export const editFogFacilityComment = (
	commentId: number,
	commentText: string
): CommentThunkAction => async dispatch => {
	let comment: ApiTypes.Comment = {
		commentText: commentText
	};
	let facilityCommentUrl = createFacilityCommentURL(commentId);

	let commentEdited = await apiService.patchResource(facilityCommentUrl, comment);
	dispatch({ type: CommentActionType.EditCommentSucceedType, commentEdited });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.updateSuccess', 'comments.comment'));
	return true;
};

export const deleteFogFacilityComment = (commentId: number): CommentThunkAction => async dispatch => {
	let facilityCommentUrl = createFacilityCommentURL(commentId);

	await apiService.deleteResource(facilityCommentUrl);
	dispatch({ type: CommentActionType.DeleteCommentSucceedType, commentId });
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.removeSuccess', 'comments.comment'));

	return true;
};

export const loadFacilityComments = (startDate?: string, endDate?: string) => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityId();
	if (!facilityId || facilityId < 1) return;

	let queryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		queryParams
			.put('lastModificationDateTimeUtc', `gte:${startDate}`)
			.put('lastModificationDateTimeUtc', `lte:${endDate}`);
	}

	const commentsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Comments);
	const commentsResults = await apiService.httpGet(`${commentsUrl}?${queryParams.toQueryString()}`);
	const facilityComments = commentsResults.result;

	dispatch({ type: CommentActionType.ReceivedCommentsType, facilityComments });
};

export const facilityCommentsReducer = (state = initialFacilityCommentsState, action: any): FacilityCommentsState => {
	let comments = [] as ApiTypes.Comment[];
	comments = comments.concat(state.facilityComments);
	switch (action.type) {
		case CommentActionType.ReceivedCommentsType:
			return { ...state, facilityComments: action.facilityComments };
		case CommentActionType.SaveCommentSucceedType:
			comments.unshift(action.commentCreated);
			return { ...state, facilityComments: comments };
		case CommentActionType.EditCommentSucceedType:
			for (let index in comments) {
				if (comments[index].commentId === action.commentEdited.commentId) {
					comments[index] = action.commentEdited;
				}
			}
			return { ...state, facilityComments: comments };
		case CommentActionType.DeleteCommentSucceedType:
			let updatedComments = [] as ApiTypes.Comment[];
			for (let comment of comments) {
				if (comment.commentId !== action.commentId) {
					updatedComments.push(comment);
				}
			}
			return { ...state, facilityComments: updatedComments };
		default:
			return { ...state };
	}
};
