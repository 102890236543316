import React, { FC, useContext } from 'react';
import { ListBox } from '@progress/kendo-react-listbox';
import './drag-drop-box.scss';
import { CloseSvg } from 'src/components';
import { DragDropBoxContext } from 'src/components/authority/shared/settings/custom-forms/form-builder';

interface Props {
	data: any;
	itemRenderer: React.ComponentType<any>;
	handleDrop: (e: any) => void;
	handleDragStart: (e: any) => void;
	title: string;
	handleClose: () => void;
	description?: React.ReactElement;
	hideList?: boolean;
	titleTag?: string;
	className?: string;
	onItemClick?: (dataItem: any) => void;
	id: string;
	hideTitle?: boolean;
	hideDescription?: boolean;
}

export const DragDropBox: FC<Props> = props => {
	const { isPreviewMode } = useContext(DragDropBoxContext);
	const titleRenderer = (showCloseSvg: boolean = true) => {
		return (
			<>
				{props.title}
				{showCloseSvg && (
					<button
						className="close ml-auto column-editor-close"
						onClick={() => props.handleClose && props.handleClose()}>
						<CloseSvg />
					</button>
				)}
			</>
		);
	};
	return (
		<div id={props.id} className={`list-box-wrapper ${props.className || ''}`}>
			{!props.hideTitle &&
				(props.titleTag === 'h2' ? (
					<h2 className="d-flex edit-field-header">{titleRenderer()}</h2>
				) : (
					<h1 className="d-flex px-4">{titleRenderer(isPreviewMode)}</h1>
				))}
			{props.description && !props.hideDescription && <>{props.description}</>}
			{!props.hideList && (
				<ListBox
					textField="name"
					data={props.data}
					className="list-box-height"
					style={{ width: '100%' }}
					onDragStart={props.handleDragStart}
					onDrop={props.handleDrop}
					onItemClick={props.onItemClick}
					selectedField="selected"
					item={props.itemRenderer}
				/>
			)}
		</div>
	);
};
