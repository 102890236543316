import React from 'react';
import _ from 'lodash';

import './radio-group.scss';

export interface RadioOption {
	label: string | React.ReactNode;
	value: any;
	isDisabled?: boolean;
	id?: string;
}

interface Props {
	id: string;
	name: string;
	label: string;
	options: RadioOption[];
	isRequired?: boolean;
	readonly?: boolean;
	value?: any;
	onChange?(e: any): void;
	formHelp?: React.ReactNode;
}

export const RadioGroup: React.FC<Props> = props => {
	const [value, setValue] = React.useState(props.value);
	const handleChange = (e: any) => {
		let newValue = e.target.value;
		if (newValue !== value) {
			setValue(newValue);
			if (props.onChange) {
				props.onChange(e);
			}
		}
	};
	React.useEffect(() => {
		if (props.value !== undefined && props.value !== value) {
			setValue(props.value);
		}
	}, [props]);

	return (
		<div className="form-group required">
			<label className={`mb-1 ${props.readonly === true ? 'ai-light' : ''}`}>{props.label}</label>
			<fieldset id={props.id}>
				{!_.isEmpty(props.options) &&
					props.options.map((option, index) => {
						const radioInputId = option.id ? option.id : `${props.id}-Opt${index}`;
						return (
							<div className="custom-control custom-radio" key={`${props.name}-radio-${index}`}>
								<input
									type="radio"
									className={`custom-control-input z-1 `}
									name={props.name}
									id={radioInputId}
									value={option.value}
									checked={option.value === value}
									onChange={handleChange}
									disabled={!_.isUndefined(option.isDisabled) ? option.isDisabled : props.readonly}
								/>
								<label className="custom-control-label" htmlFor={radioInputId}>
									{option.label}
								</label>
							</div>
						);
					})}
			</fieldset>
			{props.formHelp}
		</div>
	);
};
