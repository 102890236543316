import { IppIndustry, Token } from '@rcp/types';
import React, { FC, useEffect, useState } from 'react';
import { apiService, localizationService, navigateTo, Resource, tokenService, urlService } from 'src/services';
import FeatureSummary from './feature-summary';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './homepage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IppHelpUrls } from 'src/constants';
import { History } from 'history';
import { Notification } from 'src/components/widgets/inline-notification';
import { ReactComponent as SvgAlert } from 'src/assets/img/inline-alert.svg';
import _ from 'lodash';
interface Props {
	history: History;
}

const Homepage: FC<Props> = (props: Props) => {
	let { guid } = urlService.getPortalStatus();
	const industryBaseUrl = '/industry/' + guid;
	const industryReportPackageBaseUrl = industryBaseUrl + '/reportPackages';
	const industrySampleBaseUrl = industryBaseUrl + '/samples';
	const [hideScheduledReport, setHideScheduledReport] = useState<boolean>(false);
	const [reportPackagesCount, setReportPackagesCount] = useState<any>();
	const [samplesCount, setSamplesCount] = useState<any>();
	const [draftSampleCount, setDraftSampleCount] = useState(0);
	const [readyToReportSampleCount, setReadyToReportSampleCount] = useState(0);
	const [draftReportCount, setDraftReportCount] = useState(0);
	const [rejectedReportCount, setRejectedReportCount] = useState(0);
	const [pendingAcceptanceReportCount, setPendingAcceptanceReportCount] = useState(0);
	const [readyToSubmitReportCount, setReadyToSubmitReportCount] = useState(0);
	const [scheduledReportCount, setScheduledtReportCount] = useState(0);
	const [errorReportCount, setErrorReportCount] = useState(0);

	const getReportPackagesCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages + '/Count');
		const reportPackageCount = await apiService.getResource(url);
		return reportPackageCount;
	};

	const getHelpUrl = () => {
		let helpUrl = '#';
		let currentToken = tokenService.getTokenOrDefault();
		if (tokenService.isTokenValid(currentToken)) {
			let params = {
				path: IppHelpUrls.industry,
				accessToken: currentToken.accessToken
			};
			helpUrl = urlService.getHelpPath() + `?` + urlService.toQueryString(params);
		}
		return helpUrl;
	};

	const getSamplesCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples + '/Count');
		const sampleCounts = await apiService.getResource(url);
		return sampleCounts;
	};

	const loadCounts = async () => {
		setReportPackagesCount(await getReportPackagesCount());
		setSamplesCount(await getSamplesCount());
	};

	useEffect(() => {
		loadCounts();
		addScheduledReports();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.href, props]);

	const loadIndustryDetails = async () => {
		let token: Token = tokenService.getTokenOrDefault();
		let url = urlService.getAuthorityResourceApiUrl(
			Resource.IppIndustries,
			Number(token.portalOrganizationRegulatoryProgramId)
		);
		let industry = await apiService.getResource<IppIndustry>(url);
		return industry;
	};
	const addScheduledReports = async () => {
		let industry = await loadIndustryDetails();
		setHideScheduledReport(!Boolean(industry.showScheduledReport));
	};

	useEffect(() => {
		if (samplesCount) {
			let draft = samplesCount.find((sample: any) => String.equalCaseInsensitive(sample.sampleStatus, 'Draft'));
			let readyToReport = samplesCount.find((sample: any) =>
				String.equalCaseInsensitive(sample.sampleStatus, 'ReadyToReport')
			);
			setDraftSampleCount(draft.count);
			setReadyToReportSampleCount(readyToReport.count);
		}

		if (reportPackagesCount) {
			let draft = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'Draft')
			);
			let readyToSubmit = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'ReadyToSubmit')
			);
			let scheduled = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'Scheduled')
			);
			let rejected = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'Rejected')
			);
			let pendingAcceptance = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'PendingAcceptance')
			);
			let error = reportPackagesCount.find((report: any) =>
				String.equalCaseInsensitive(report.reportPackageStatus, 'Error')
			);
			setDraftReportCount(draft.count);
			setReadyToSubmitReportCount(readyToSubmit.count);
			setScheduledtReportCount(scheduled.count);
			setRejectedReportCount(rejected.count);
			setPendingAcceptanceReportCount(pendingAcceptance.count);
			setErrorReportCount(error.count);
		}
	}, [samplesCount, reportPackagesCount]);

	return (
		<div className="d-flex w-100 flex-column flex-lg-row">
			<div className={`d-flex flex-column w-100 max-table`}>
				<div className="page-header">
					<h1>{localizationService.getLocalizedString('ipp.industryHomepage.dashboard')}</h1>
				</div>
				{errorReportCount > 0 && (
					<Notification
						icon={<SvgAlert className="custom-svg-size" />}
						className="error-alert d-inline-block "
						svgClassName=" d-flex"
						message={
							<span>
								{localizationService.getLocalizedString(
									'ipp.industryHomepage.errorAlert1',
									_.toString(errorReportCount)
								)}
								<span
									className="cursor-pointer"
									onClick={e => {
										props.history &&
											navigateTo(props.history, industryReportPackageBaseUrl + '/errors', e);
									}}>
									<u>{localizationService.getLocalizedString('ipp.industryHomepage.errors')}</u>
								</span>
								{localizationService.getLocalizedString('ipp.industryHomepage.errorAlert2')}
							</span>
						}
						showCloseButton={false}
					/>
				)}

				<div>
					<div className="mt-3">
						<div className="d-flex flex-wrap justify-content-space-between section-wrapper">
							<div className="d-flex w-50 dashboard-section-container">
								<section className="d-flex flex-column justify-content-start mr-4 section-container">
									<label className="font-size-24px-semibold ml-2 mb-2">
										{localizationService.getLocalizedString(
											'ipp.industryHomepage.reportPackagesTitle'
										)}
										<a
											target="_blank"
											rel="noopener noreferrer"
											href={getHelpUrl()}
											className="help-link">
											<FontAwesomeIcon icon={faQuestionCircle} className="help-icon" />
										</a>
									</label>
									{!hideScheduledReport && (
										<>
											<FeatureSummary
												history={props.history}
												navigationUrl={industryReportPackageBaseUrl + '/scheduled'}
												count={scheduledReportCount}
												title={localizationService.getLocalizedString(
													'ipp.industryHomepage.scheduledReportTitle'
												)}
												backgroundColorClass="repudiated-btn"
												isFontColorWhite={true}
											/>
											<div className="divider-container">
												<hr className="divider" />
											</div>
										</>
									)}
									<FeatureSummary
										history={props.history}
										navigationUrl={industryReportPackageBaseUrl + '/draft'}
										count={draftReportCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.draftReportPackage'
										)}
										backgroundColorClass="draft-btn"
										isFontColorWhite={true}
									/>
									<div className="divider-container">
										<hr className="divider" />
									</div>
									<FeatureSummary
										history={props.history}
										navigationUrl={industryReportPackageBaseUrl + '/readyToSubmit'}
										count={readyToSubmitReportCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.readyToSubmitReportTitle'
										)}
										backgroundColorClass="ready-to-submit"
										isFontColorWhite={true}
									/>
									<div className="divider-container">
										<hr className="divider" />
									</div>
									<FeatureSummary
										history={props.history}
										navigationUrl={industryReportPackageBaseUrl + '/pendingAcceptance'}
										count={pendingAcceptanceReportCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.pendingAcceptanceReportPackage'
										)}
										backgroundColorClass="pendingacceptance-btn"
										isFontColorWhite={true}
									/>
									<div className="divider-container">
										<hr className="divider" />
									</div>
									<FeatureSummary
										history={props.history}
										navigationUrl={industryReportPackageBaseUrl + '/rejected'}
										count={rejectedReportCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.rejectedReportPackage'
										)}
										backgroundColorClass="rejected-btn"
										isFontColorWhite={true}
									/>
								</section>
							</div>

							<div className="d-flex w-50 dashboard-section-container">
								<section className="d-flex flex-column justify-content-start  section-container">
									<label className="font-size-24px-semibold ml-2 mb-2">
										{localizationService.getLocalizedString('ipp.industryHomepage.samples')}
									</label>
									<FeatureSummary
										history={props.history}
										navigationUrl={industrySampleBaseUrl + '/draft'}
										count={draftSampleCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.draftSamples'
										)}
										backgroundColorClass="draft-btn"
										isFontColorWhite={true}
									/>
									<div className="divider-container">
										<hr className="divider" />
									</div>
									<FeatureSummary
										history={props.history}
										navigationUrl={industrySampleBaseUrl + '/readyToReport'}
										count={readyToReportSampleCount}
										title={localizationService.getLocalizedString(
											'ipp.industryHomepage.readyToReportSamples'
										)}
										backgroundColorClass="ready-to-report"
										isFontColorWhite={true}
									/>
								</section>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Homepage;
