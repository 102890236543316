import React, { FC } from 'react';
import { History } from 'history';
import { cccTechnicianSlice } from './technicians.slice';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, LocalStorageName, CccTechnician } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccTechnicians: FC<Props<CccTechnician>> = props => {
	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('cccTechnician.technicians'),
		prefix: 'cccTechnician',
		storageName: LocalStorageName.CccTechnicianGrid,
		gridUrlLocalStorageName: LocalStorageName.CccTechniciansGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccTechniciansSortOrder,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccTechnician.searchHint'),

		allColumns: [
			new ColumnField('fullName'),
			new ColumnField('fee'),
			new ColumnField('feePaid', FilterType.Date),
			new ColumnField('defaultCompanyName'),
			new ColumnField('addressLine1'),
			new ColumnField('addressEmail'),
			new ColumnField('addressPhone'),
			new ColumnField('addressLine2'),
			new ColumnField('addressCity'),
			new ColumnField('addressZipCode'),
			new ColumnField('addressJurisdictionCode'),
			new ColumnField('addressFax'),
			new ColumnField('addressCell'),
			new ColumnField('insuranceAgency'),
			new ColumnField('insuranceCarrier'),
			new ColumnField('insurancePolicyNumber'),
			new ColumnField('insuranceExpirationDate', FilterType.Date),
			new ColumnField('insuranceLiabilityAmount', FilterType.Integer),
			new ColumnField('notes')
		],
		defaultColumns: ['fullName', 'defaultCompanyName', 'addressLine1', 'addressPhone', 'addressEmail']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccTechnicianSlice}
				restState={(state: RootState) => state.cccTechnicians}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export default CccTechnicians;
