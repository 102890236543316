import { ApiEndpoints, IppSampleFlowUnit, RegulatoryProgramName } from '@rcp/types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Utils } from 'src/services/utils';
import { alertService, ApplicationState } from 'src/redux';
import {
	fetchIppAASIndustrialPretreatmentSettingsActionCreator,
	IppAASIndustrialPretreatmentSettingsState,
	saveIppAASIndustrialPretreatmentSettingsActionCreator,
	toggleSaveButtonActionCreator,
	updateIppAASIndustrialPretreatmentSettingsActionCreator
} from '../../../../../redux/ipp/authority/authority-account/settings/industrial-pretreatment-settings';
import { apiService, localizationService, tokenService, urlService, validationService } from 'src/services';
import { SingleSelectDropdown, TextInput } from 'src/components/widgets';
import CollapsibleCard from '../../../../widgets/collapsible-card/collapsible-card';
import MultipleCheckbox from '../../../../widgets/multiple-checkbox';

interface DispatchProps {
	fetchIppAASIndustrialPretreatmentSettings: (currentSetting: IppAASIndustrialPretreatmentSettingsState) => void;
	updateIppAASIndustrialPretreatmentSettings: (
		updatedAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState
	) => void;
	saveIppAASIndustrialPretreatmentSettings: (
		finalAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState
	) => void;
	toggleSaveButton: (finalAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState) => void;
}

interface StoreProps {
	ippAASIndustrialPretreatmentSettings: IppAASIndustrialPretreatmentSettingsState;
}

interface Props extends DispatchProps, StoreProps {}

interface State {
	fieldsErrorObject: any;
	sampleFlowUnits: IppSampleFlowUnit[];
}

class IppAASIndustrialPretreatmentSetting extends Component<Props, State> {
	private validationFields: Object;
	private isSaveButtonClicked = false;

	constructor(props: Props) {
		super(props);
		this.state = { fieldsErrorObject: {}, sampleFlowUnits: [] as IppSampleFlowUnit[] };
		const industrialPretreatmentSettingFormKey = 'ipp.authorityAccountSetting.industrialPretreatmentSettingForm';
		const industrialPretreatmentSettingFormFieldsKey = `${industrialPretreatmentSettingFormKey}.fields`;
		this.validationFields = {
			attachmentType: {
				fieldName: 'attachment-type',
				fieldLabel: localizationService.getLocalizedString(
					`${industrialPretreatmentSettingFormFieldsKey}.attachmentType.label`
				),
				validations: ['required']
			},
			sampleFlowUnits: {
				fieldName: 'sample-flow-units-check',
				fieldLabel: localizationService.getLocalizedString(
					`${industrialPretreatmentSettingFormFieldsKey}.sampleFlowUnitsCheck.label`
				),
				validations: ['required']
			},
			contactNameOnEmails: {
				fieldName: 'contact-info-name-emails',
				fieldLabel: localizationService.getLocalizedString(
					`${industrialPretreatmentSettingFormFieldsKey}.contactInfoNameOnEmails.label`
				),
				validations: ['required']
			},
			contactPhoneOnEmails: {
				fieldName: 'contact-info-phone-emails',
				fieldLabel: localizationService.getLocalizedString(
					`${industrialPretreatmentSettingFormFieldsKey}.contactInfoPhoneOnEmails.label`
				),
				validations: ['required']
			},
			contactEmailAddressOnEmails: {
				fieldName: 'contact-info-emailaddr-emails',
				fieldLabel: localizationService.getLocalizedString(
					`${industrialPretreatmentSettingFormFieldsKey}.contactInfoEmailAddrOnEmails.label`
				),
				validations: ['email']
			}
		};
	}

	public componentDidMount() {
		const sampleFlowUnitUrl = urlService.getAuthoritySettingResourceApiUrl(ApiEndpoints.IppSampleFlowUnits);
		apiService
			.getResource(sampleFlowUnitUrl)
			.then(sampleFlowUnits => {
				this.setState({ sampleFlowUnits: sampleFlowUnits as IppSampleFlowUnit[] });
			})
			.catch(error => {
				alertService.addError(error.body.message);
			});

		this.props.fetchIppAASIndustrialPretreatmentSettings(this.props.ippAASIndustrialPretreatmentSettings);
	}

	public componentDidUpdate() {
		const { notification } = this.props.ippAASIndustrialPretreatmentSettings;
		if (this.isSaveButtonClicked && (notification as any).msg !== '') {
			(notification as any).type === 'success'
				? alertService.addSuccess((notification as any).msg)
				: alertService.addError((notification as any).msg);
			this.isSaveButtonClicked = false;
		}
	}

	getDecimalValue = (numberOfDecimals: string) => {
		if (Number.isNaN(parseInt(numberOfDecimals))) return numberOfDecimals;
		return Number(0)
			.toFixed(+numberOfDecimals)
			.substring(1);
	};

	public render() {
		const industrialPretreatmentSettingFormKey = 'ipp.authorityAccountSetting.industrialPretreatmentSettingForm';
		const industrialPretreatmentSettingFormFieldsKey = `${industrialPretreatmentSettingFormKey}.fields`;
		const { ippAASIndustrialPretreatmentSettings } = this.props;
		const industrialPretreatmentSettingsData = ippAASIndustrialPretreatmentSettings.data;
		const { fieldsErrorObject } = this.state;
		const enabledSaveBtnClass = 'btn ai-save mb-2';
		const disabledSaveBtnClass = 'btn btn-secondary mb-2 disabled';

		const isAdmin = tokenService.isAdminToken(RegulatoryProgramName.IPP);
		const disableWhenNotAdmin = isAdmin ? {} : { isDisabled: true };

		return (
			<React.Fragment>
				<CollapsibleCard
					accordionHeading={localizationService.getLocalizedString(
						`${industrialPretreatmentSettingFormKey}.heading`
					)}
					accordionType={'ipp-aas-ind-pretreatment-settings-accordion'}
					className={'mt-2'}>
					<div className="col-md-8 ml-auto mr-auto">
						<div className="form-row">
							<SingleSelectDropdown
								id="ipp-aas-attachment-type"
								name="attachment-type"
								className="col-sm-12 p-2 px-5px"
								isRequired={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.attachmentType.label`
								)}
								value={Utils.getValueOrEmptyString(industrialPretreatmentSettingsData.attachmentType)}
								onChange={isAdmin ? this.fieldsChangeHandler('attachmentType') : () => {}}
								options={
									ippAASIndustrialPretreatmentSettings.fileUploadAttachmentTypes.length > 0
										? Utils.getDropdownOptions(
												ippAASIndustrialPretreatmentSettings.fileUploadAttachmentTypes,
												'reportElementTypeId',
												'name'
										  )
										: []
								}
								error={
									'attachment-type' in fieldsErrorObject ? fieldsErrorObject['attachment-type'] : ''
								}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-report-end-date-repudiate-gap"
								name="report-end-date-repudiate-gap"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.reportEndDateAndRepudiateGap.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.maxDaysToRepudiateAfterReportEndDate
								)}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={
									isAdmin
										? this.fieldsChangeHandler('maxDaysToRepudiateAfterReportEndDate')
										: () => {}
								}
								remainingInputProps={{ min: 1 }}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<MultipleCheckbox
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.resultQualifierCheck.label`
								)}
								isLabelDisabled={!isAdmin}
								checkboxes={[
									{
										label: '<',
										id: 'ipp-aas-smaller-than-qualifier-check',
										name: 'result-qualifier-check',
										checked:
											Utils.getValueOrEmptyString(
												industrialPretreatmentSettingsData.resultQualifier
											).indexOf('<') > -1
												? true
												: false,
										isDisabled: !isAdmin,
										onChange: isAdmin ? this.checkboxChangeHandler : () => {}
									},
									{
										label: '>',
										id: 'ipp-aas-greater-than-qualifier-check',
										name: 'result-qualifier-check',
										checked:
											Utils.getValueOrEmptyString(
												industrialPretreatmentSettingsData.resultQualifier
											).indexOf('>') > -1
												? true
												: false,
										isDisabled: !isAdmin,
										onChange: isAdmin ? this.checkboxChangeHandler : () => {}
									},
									{
										label: 'ND',
										id: 'ipp-aas-nd-qualifier-check',
										name: 'result-qualifier-check',
										checked:
											Utils.getValueOrEmptyString(
												industrialPretreatmentSettingsData.resultQualifier
											).indexOf('ND') > -1
												? true
												: false,
										isDisabled: !isAdmin,
										onChange: isAdmin ? this.checkboxChangeHandler : () => {}
									},
									{
										label: 'NF',
										id: 'ipp-aas-nf-qualifier-check',
										name: 'result-qualifier-check',
										checked:
											Utils.getValueOrEmptyString(
												industrialPretreatmentSettingsData.resultQualifier
											).indexOf('NF') > -1
												? true
												: false,
										isDisabled: !isAdmin,
										onChange: isAdmin ? this.checkboxChangeHandler : () => {}
									}
								]}
							/>
						</div>
						<div className="form-row">
							<MultipleCheckbox
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.sampleFlowUnitsCheck.label`
								)}
								isLabelDisabled={!isAdmin}
								checkboxes={this.state.sampleFlowUnits.map((flowUnit: IppSampleFlowUnit) => {
									return {
										label: flowUnit.name,
										id: `${flowUnit.unitId}`,
										name: 'sample-flow-units-check',
										checked:
											flowUnit.name &&
											Utils.getValueOrEmptyString(
												industrialPretreatmentSettingsData.sampleFlowUnits
											).indexOf(flowUnit.name) > -1
												? true
												: false,
										isDisabled: !isAdmin,
										onChange: isAdmin ? this.checkboxChangeHandler : () => {}
									};
								})}
								isRequired={true}
								error={
									'sample-flow-units-check' in fieldsErrorObject
										? fieldsErrorObject['sample-flow-units-check']
										: ''
								}
							/>
						</div>
						<div className="form-row">
							<SingleSelectDropdown
								id="ipp-aas-create-sample-name"
								name="create-sample-name"
								className="col-sm-12 p-2 px-5px"
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.createSampleName.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.createSampleNameUsing
								)}
								onChange={isAdmin ? this.fieldsChangeHandler('createSampleNameUsing') : () => {}}
								options={[
									{
										label: localizationService.getLocalizedString(
											`${industrialPretreatmentSettingFormFieldsKey}.createSampleName.options.sampleEventType`
										),
										value: 'SampleEventType'
									},
									{
										label: localizationService.getLocalizedString(
											`${industrialPretreatmentSettingFormFieldsKey}.createSampleName.options.sampleEventTypeAndCollectionMethod`
										),
										value: 'SampleEventTypeCollectionMethod'
									}
								]}
								noEmptyOption={true}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-compliance-determination-date"
								name="compliance-determination-date"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.complianceDeterminationDate.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.complianceDeterminationDate
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-mass-load-conversion-factor"
								name="mass-load-conversion-factor"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.massLoadingConversionFactor.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.massLoadingConversionFactor
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-sign-for-mass-loading"
								name="sign-for-mass-loading"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.signForMassLoadingResults.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.massLoadingResults
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-decimal-places-for-loading-calc"
								name="decimal-places-for-loading-calc"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.decimalPlacesForLoadingCalculations.label`
								)}
								value={
									this.getDecimalValue(
										Utils.getValueOrEmptyString(
											industrialPretreatmentSettingsData.decimalPlacesForLoadingCalculations
										)
									) || ''
								}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-available-authority-license-count"
								name="available-authority-license-count"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.availableAuthorityLicenseCount.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.availableAuthorityLicenses
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-authority-license-inuse-count"
								name="authority-license-inuse-count"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.authorityLicenseInUseCount.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.authorityLicensesInUse
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-available-industry-license-count"
								name="available-industry-license-count"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.availableIndustryLicenseCount.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.availableIndustryLicenses
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-industry-license-inuse-count"
								name="industry-license-inuse-count"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.industryLicenseInUseCount.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.industryLicensesInUse
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-industry-user-per-industry-count"
								name="industry-user-per-industry-count"
								className="col-sm-12"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.industryUsersPerIndustryCount.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.industryUsersCountPerIndustry
								)}
								remainingInputProps={{ min: 1 }}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-contact-info-name-emails"
								name="contact-info-name-emails"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.contactInfoNameOnEmails.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.contactNameOnEmails
								)}
								onBlur={() => {}}
								isRequired={true}
								onEnterKeyPressed={() => {}}
								onChange={isAdmin ? this.fieldsChangeHandler('contactNameOnEmails') : () => {}}
								error={
									'contact-info-name-emails' in fieldsErrorObject
										? fieldsErrorObject['contact-info-name-emails']
										: ''
								}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-contact-info-phone-emails"
								name="contact-info-phone-emails"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.contactInfoPhoneOnEmails.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.contactPhoneOnEmails
								)}
								onBlur={() => {}}
								isRequired={true}
								onEnterKeyPressed={() => {}}
								onChange={isAdmin ? this.fieldsChangeHandler('contactPhoneOnEmails') : () => {}}
								error={
									'contact-info-phone-emails' in fieldsErrorObject
										? fieldsErrorObject['contact-info-phone-emails']
										: ''
								}
								{...disableWhenNotAdmin}
							/>
						</div>
						<div className="form-row">
							<TextInput
								id="ipp-aas-contact-info-emailaddr-emails"
								name="contact-info-emailaddr-emails"
								className="col-sm-12"
								label={localizationService.getLocalizedString(
									`${industrialPretreatmentSettingFormFieldsKey}.contactInfoEmailAddrOnEmails.label`
								)}
								value={Utils.getValueOrEmptyString(
									industrialPretreatmentSettingsData.contactEmailAddressOnEmails
								)}
								onBlur={() => {}}
								onEnterKeyPressed={() => {}}
								onChange={isAdmin ? this.fieldsChangeHandler('contactEmailAddressOnEmails') : () => {}}
								{...disableWhenNotAdmin}
							/>
						</div>
						{isAdmin ? (
							<div className="form-row">
								<div className="ml-auto">
									<button
										className={`mr-1 ${enabledSaveBtnClass} ${
											!ippAASIndustrialPretreatmentSettings.isSaveButtonEnabled ? 'd-none' : ''
										}`}
										id="ipp-aas-btn-save-industry-pretreatment-settings"
										onClick={this.saveButtonHandler}>
										{localizationService.getLocalizedString(
											`${industrialPretreatmentSettingFormKey}.submitBtn.label`
										)}
									</button>
									<button
										className={`mr-1 ${disabledSaveBtnClass} ${
											!ippAASIndustrialPretreatmentSettings.isSaveButtonEnabled ? '' : 'd-none'
										}`}
										id="ipp-aas-btn-save-industry-pretreatment-settings-disabled"
										disabled={true}
										onClick={() => {}}>
										{localizationService.getLocalizedString(
											`${industrialPretreatmentSettingFormKey}.submitBtn.label`
										)}
									</button>
								</div>
							</div>
						) : (
							<React.Fragment />
						)}
					</div>
				</CollapsibleCard>
			</React.Fragment>
		);
	}

	private fieldsChangeHandler = (fieldStateName: string) => {
		const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			const updatedValue = event.target.value;
			this.updateIndustrialPretreatmentSettings({ [fieldStateName]: updatedValue });
		};
		return changeHandler;
	};

	private checkboxChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.persist();
		const { ippAASIndustrialPretreatmentSettings } = this.props;
		const industrialPretreatmentSettingsData = ippAASIndustrialPretreatmentSettings.data;
		const checkboxesValues = {
			'result-qualifier-check': Utils.getValueOrEmptyString(industrialPretreatmentSettingsData.resultQualifier),
			'sample-flow-units-check': Utils.getValueOrEmptyString(industrialPretreatmentSettingsData.sampleFlowUnits)
		};
		if (event.target.checked) {
			let checkbox = (checkboxesValues as any)[event.target.name].split(',').map((item: string) => {
				return item.trim();
			});
			if (event.target.nextSibling) {
				if (!checkbox || (checkbox.length === 1 && checkbox[0] === '')) {
					checkbox = [] as string[];
				}
				const selectedValue = (event.target.nextSibling.textContent as any).trim();
				selectedValue && selectedValue !== '' && checkbox.push(selectedValue);
				(checkboxesValues as any)[event.target.name] = checkbox.join(',');
			}
		} else {
			let checkbox = (checkboxesValues as any)[event.target.name].split(',').map((item: string) => {
				return item.trim();
			});
			if (event.target.nextSibling) {
				const selectedValue = (event.target.nextSibling.textContent as any).trim();
				if (selectedValue && selectedValue !== '') {
					checkbox.splice(checkbox.indexOf(selectedValue), 1);
				}
				(checkboxesValues as any)[event.target.name] = checkbox.join(',');
			}
		}

		this.updateIndustrialPretreatmentSettings({
			resultQualifier: checkboxesValues['result-qualifier-check'],
			sampleFlowUnits: checkboxesValues['sample-flow-units-check']
		});
	};

	private saveButtonHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const { ippAASIndustrialPretreatmentSettings, saveIppAASIndustrialPretreatmentSettings } = this.props;
		const ippAASIndustrialPretreatmentSettingsData = ippAASIndustrialPretreatmentSettings.data;
		let validationsFieldsAttributes = [];
		for (const validationField in this.validationFields) {
			validationsFieldsAttributes.push({
				...(this.validationFields as any)[validationField],
				value: (ippAASIndustrialPretreatmentSettingsData as any)[validationField]
			});
		}
		const validationResult = validationService.validateFields(validationsFieldsAttributes as any);
		let hasError = false;
		let fieldsErrorObject = {};
		for (const fieldName in validationResult) {
			const validation = (validationResult as any)[fieldName];
			if (Object.keys(validation).length > 0) {
				hasError = true;
				(fieldsErrorObject as any)[fieldName] = validation[Object.keys(validation)[0]];
			}
		}
		this.setState({ fieldsErrorObject });
		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		} else {
			this.props.toggleSaveButton(this.props.ippAASIndustrialPretreatmentSettings);
			this.isSaveButtonClicked = true;
			saveIppAASIndustrialPretreatmentSettings(ippAASIndustrialPretreatmentSettings);
		}
	};

	private updateIndustrialPretreatmentSettings = (value: Object) => {
		const ippAASIndustrialPretreatmentSettingsData = this.props.ippAASIndustrialPretreatmentSettings.data;
		const updatedIndustrialPretreatmentSettings = {
			...this.props.ippAASIndustrialPretreatmentSettings,
			data: { ...ippAASIndustrialPretreatmentSettingsData, ...value }
		};
		this.props.updateIppAASIndustrialPretreatmentSettings(updatedIndustrialPretreatmentSettings);
	};
}

const mapStateToProps = (state: ApplicationState): StoreProps => {
	return {
		ippAASIndustrialPretreatmentSettings: state.ippAASIndustrialPretreatmentSettings
	};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		fetchIppAASIndustrialPretreatmentSettings: (currentSetting: IppAASIndustrialPretreatmentSettingsState) => {
			dispatch(fetchIppAASIndustrialPretreatmentSettingsActionCreator(currentSetting));
		},
		updateIppAASIndustrialPretreatmentSettings: (
			updatedAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState
		) => {
			dispatch(updateIppAASIndustrialPretreatmentSettingsActionCreator(updatedAuthoritySettingsState));
		},
		saveIppAASIndustrialPretreatmentSettings: (
			finalAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState
		) => {
			dispatch(saveIppAASIndustrialPretreatmentSettingsActionCreator(finalAuthoritySettingsState));
		},
		toggleSaveButton: (finalAuthoritySettingsState: IppAASIndustrialPretreatmentSettingsState) => {
			dispatch(toggleSaveButtonActionCreator(finalAuthoritySettingsState));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppAASIndustrialPretreatmentSetting);
