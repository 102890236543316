import React, { Component } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import * as actionCreators from 'src/redux/extractor';
import { ExtractorCard } from './extractor-card-component';
import { ApplicationState, ExtractorState, FeatureFlagSettingsState } from 'src/redux';

import * as ApiTypes from '@rcp/types';
import _ from 'lodash';
import { ExtractorCardModal } from './extractor-card-component-modal';
import { localizationService } from 'src/services';

interface StateProps extends ExtractorState, FeatureFlagSettingsState {}

interface DispatchProps {
	loadExtractorTypeList: () => void;
	LoadExtractorList: (facilityId?: number) => void;
	loadFrequencyList: () => void;
	loadUnitList: () => void;
}

interface Props extends StateProps, DispatchProps {
	facilityId?: number;
	deviceId?: number;
}

interface CompState {
	showAddModal: boolean;
}

class ExtractorTabPageComp extends Component<Props, CompState> {
	constructor(props: Props) {
		super(props);

		this.state = {
			showAddModal: false
		};

		this.onToggleShowAddModal = this.onToggleShowAddModal.bind(this);
	}

	componentDidMount() {
		this.ensureDataFetched();
	}

	ensureDataFetched() {
		this.props.loadExtractorTypeList();
		this.props.LoadExtractorList(this.props.facilityId);
		this.props.loadFrequencyList();
		this.props.loadUnitList();
	}

	onToggleShowAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		this.setState({
			showAddModal: !this.state.showAddModal
		});
	};

	render() {
		let sortedFacilityExtractorList = this.props.facilityExtractorList.sort((i, j) => {
			if (i.isActive === j.isActive) {
				if (_.isUndefined(i.deviceNumber) || _.isUndefined(j.deviceNumber)) {
					return 0;
				} else {
					return i.deviceNumber.toLowerCase() < j.deviceNumber.toLowerCase() ? -1 : 1;
				}
			}

			if (_.isUndefined(i.isActive) || _.isUndefined(j.isActive)) {
				return 0;
			} else {
				return i.isActive < j.isActive ? 1 : -1;
			}
		});

		return (
			<>
				<div className="device-container">
					{sortedFacilityExtractorList
						.filter(x => this.props.deviceId !== x.extractorId)
						.map((item: ApiTypes.FacilityExtractor, index: number) => {
							return (
								<ExtractorCard
									key={'facilityExtractor-' + index}
									facilityExtractor={item}
									extractor={
										this.props.extractorList.filter(x => x.extractorId === item.extractorId)[0]
									}
								/>
							);
						})}
				</div>
				<div className="mt-2">
					<a href="/#" className="ai-add-link " onClick={e => this.onToggleShowAddModal(e)}>
						{localizationService.getLocalizedString('screen.buttons.addDevice')}
						&#8230;
					</a>
				</div>
				{this.state.showAddModal && (
					<ExtractorCardModal
						showExtractorModal={this.state.showAddModal}
						modalToggleFunction={this.onToggleShowAddModal}
						isAddModal={true}
						facilityId={this.props.facilityId}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.extractors, ...state.featureSettings };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		loadExtractorTypeList: () => dispatch(actionCreators.loadExtractorTypeList()),
		LoadExtractorList: (facilityId?: number) => dispatch(actionCreators.loadExtractorList(facilityId)),
		loadFrequencyList: () => dispatch(actionCreators.loadFrequencyList()),
		loadUnitList: () => dispatch(actionCreators.loadUnitList())
	};
};
export const ExtractorTabPage = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(ExtractorTabPageComp);
