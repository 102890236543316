import React from 'react';
import { FogFacility } from '@rcp/types';
import _ from 'lodash';
import { DateUtilService, localizationService, Resource, urlService, apiService } from 'src/services';
import { faCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alertService } from 'src/redux';
import { fogFacilitiesSlice } from '../facilities-slice';
import { InviteFacilityModal, InviteFacilityModalProps } from './facility-invite-modal';
import { useDispatch } from 'react-redux';

interface Props {
	redirectToManageAccess: (event: any, id: number) => void;
	facility: FogFacility;
}

const FacilityPortalInvitationActionTypes = {
	invite: localizationService.getLocalizedString('facility.invite'),
	resend: localizationService.getLocalizedString('facility.resend'),
	manageAccess: localizationService.getLocalizedString('facility.manageAccess'),
	blocked: localizationService.getLocalizedString('facility.blocked'),
	invited: localizationService.getLocalizedString('facility.invited')
};

const { invite, resend, manageAccess, blocked, invited } = FacilityPortalInvitationActionTypes;

const InvitationFacilityPortal: React.FC<Props> = props => {
	const [displayAction, setDisplayAction] = React.useState(invite);
	const [showModal, setShowModal] = React.useState(false);
	const dispatch = useDispatch();

	React.useEffect(() => {
		let { facilityPortalStatusName } = props.facility;
		let displayActionName = '';
		displayActionName = (facilityPortalStatusName || invite) + '';
		setDisplayAction(displayActionName);
	}, [props.facility]);

	const displayInviteAction = () => {
		let displayActionName = '';
		displayActionName = displayAction;
		let displayIcon = String.equalCaseInsensitive(displayActionName, invite)
			? faCircle
			: String.equalCaseInsensitive(displayActionName, blocked)
			? faBan
			: null;
		return (
			<span className="d-block">
				{displayIcon && <FontAwesomeIcon className="mr-2" size="xs" icon={displayIcon} />}
				{displayActionName}
			</span>
		);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const downloadLetter = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities)}/${Resource.DownloadInvite}/${
			props.facility.facilityId
		}`;
		try {
			await apiService.httpGet(url);
			alertService.addSuccess(localizationService.getLocalizedString('facility.downloadLetterSuccess'));
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const markAsInvited = () => {
		dispatch(
			fogFacilitiesSlice.patchOne(
				props.facility.facilityId || 0,
				{ isMarkedAsInvited: true },
				true,
				localizationService.getLocalizedString('facility.markedAsInvitedSuccessful')
			)
		);
	};

	const onToggleModal = () => setShowModal(!showModal);

	const generateInviteCode = async (setInviteCode: (response: any) => void) => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.FogFacilities)}/${Resource.GenerateInviteCode}/${
			props.facility.facilityId
		}`;
		try {
			const response = await apiService.httpGet(url);
			setInviteCode(response);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const inviteModalProps: InviteFacilityModalProps = {
		showModal,
		onCancel: onToggleModal,
		generateInviteCode,
		downloadLetter,
		title: localizationService.getLocalizedString('facility.invite'),
		invitationDesc: localizationService.getLocalizedString('facility.inviteDesc', props.facility.facilityName + ''),
		markAsInvited,
		saveButtonText: localizationService.getLocalizedString('facility.markAsInvited')
	};

	return (
		<>
			{!_.isEmpty(props.facility.invitationDateTimeUtc) && String.equalCaseInsensitive(displayAction, resend) && (
				<span>{`${localizationService.getLocalizedString('facility.invited')} ${DateUtilService.toDisplayDate(
					props.facility.invitationDateTimeUtc
				)}`}</span>
			)}
			<a
				href="#/"
				className="ai-link"
				onClick={
					String.equalCaseInsensitive(displayAction, invite) ||
					String.equalCaseInsensitive(displayAction, resend)
						? onEditClicked
						: (event: any) => props.redirectToManageAccess(event, Number(props.facility.facilityId))
				}>
				{displayInviteAction()}
			</a>
			{showModal && <InviteFacilityModal {...inviteModalProps} />}
		</>
	);
};

export default InvitationFacilityPortal;
