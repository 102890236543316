import React, { FC } from 'react';
import { History } from 'history';
import { cccTestKitSlice } from './test-kit.slice';
import * as LinkoTypes from '@rcp/types';
import { ColumnField, FilterType, LocalStorageName, CccTestKit } from '@rcp/types';
import { RestSlice } from '../../../../redux/rest.slice';
import { DataGrid, GridOption } from '../../../../features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService } from 'src/services';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	restSlice: RestSlice<T>;
}

const CccTestKits: FC<Props<CccTestKit>> = props => {
	const initialGridOption: GridOption = {
		pageTitle: localizationService.getLocalizedString('cccTestKit.testKits'),
		prefix: 'cccTestKit',
		storageName: LocalStorageName.CccTestKitGrid,
		gridUrlLocalStorageName: LocalStorageName.CccTestKitsGridFilterUrl,
		sortOrderLocalStorageName: LocalStorageName.CccTestKitsSortOrder,

		showActions: true,
		showActionExport: true,
		showEditColumnButton: true,
		showSearch: true,
		searchHint: localizationService.getLocalizedString('cccTestKit.searchHint'),

		allColumns: [
			new ColumnField('manufacturer'),
			new ColumnField('model'),
			new ColumnField('serialNumber'),
			new ColumnField('purchaseDate', FilterType.Date),
			new ColumnField('expirationDate', FilterType.Date),
			new ColumnField('technicianFullName'),
			new ColumnField('companyName'),
			new ColumnField('statusType'),
			new ColumnField('lastCalibrationDate', FilterType.Date),
			new ColumnField('calibrationByCompanyName'),
			new ColumnField('calibrationByCompanyAddressLine1'),
			new ColumnField('calibrationByCompanyAddressLine2'),
			new ColumnField('calibrationByCompanyAddressCity'),
			new ColumnField('calibrationByCompanyAddressZipCode'),
			new ColumnField('calibrationByCompanyAddressJurisdictionCode'),
			new ColumnField('calibrationByCompanyAddressPhone'),
			new ColumnField('calibrationByCompanyAddressCell'),
			new ColumnField('calibrationByCompanyAddressEmail'),
			new ColumnField('calibrationByCompanyAddressFax'),
			new ColumnField('notes')
		],
		defaultColumns: ['manufacturer', 'model', 'serialNumber', 'expirationDate']
	};

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={cccTestKitSlice}
				restState={(state: RootState) => state.cccTestKits}
				gridOption={initialGridOption}
			/>
		</>
	);
};

export default CccTestKits;
