import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { localizationService, validationService } from 'src/services';
import { BulkUpdateSteps, CustomFieldDataType, ApiError, BulkUpdateSummary } from '@rcp/types';
import { CustomField, FacilityField, BulkUpdateFields, FieldName } from './bulk-update-service';
import _ from 'lodash';
import { customFieldGenerator } from '../../custom-fields-tab/custom-field-service';
import { FieldTypeEnum } from '../../facility-types';
import { alertService, batchUpdateFacilities } from 'src/redux';
import { facilityFieldGenerator } from '../facilityFieldGenerator';

interface Props {
	vertical?: boolean;
}

const InputFieldValuesStep: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const [chosenCustomFields, setChosenCustomFields] = React.useState<CustomField[]>([]);
	const [chosenFacilityFields, setChosenFacilityFields] = React.useState<FacilityField[]>([]);

	const back = () => stepperContext.goAt(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);

	React.useEffect(() => {
		let bulkUpdateFields: BulkUpdateFields = stepperContext.getData(BulkUpdateSteps.STEP1_CHOOSE_FIELDS);
		if (bulkUpdateFields) {
			setChosenCustomFields(bulkUpdateFields.customFields);
			setChosenFacilityFields(bulkUpdateFields.facilityFields);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stepperContext]);

	const customFieldsValuesValidateForSave = (): boolean => {
		let newState = chosenCustomFields.filter(i => i.chosen === true).slice();
		_.each(newState, customField => {
			if (customField.chosen === true) {
				(customField.isRequired || customField.isImportKey) &&
					validationService.validateRequiredField(customField, 'value', 'error');
				if (
					customField.fieldDataType &&
					String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Number)
				) {
					validationService.validateNumberField(customField, 'value', 'error');
				}
				if (
					customField.fieldDataType &&
					String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Link)
				) {
					validationService.validateUrlField(customField, 'value', 'error');
				}
			}
		});

		setChosenCustomFields(newState);
		let hasError = false;
		_.each(newState, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});

		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};

	const facilityFieldValuesValidateForSave = (): boolean => {
		let newState = chosenFacilityFields.filter(i => i.chosen === true).slice();
		_.each(newState, facilityField => {
			if (facilityField.chosen === true) {
				facilityField.isRequired && validationService.validateRequiredField(facilityField, 'value', 'error');
				if (facilityField.dataType && facilityField.dataType === FieldTypeEnum.Number) {
					validationService.validateNumberField(facilityField, 'value', 'error');
				}
			}
		});

		setChosenFacilityFields(newState);
		let hasError = false;
		_.each(newState, customField => {
			if (_.has(customField, 'error')) {
				hasError = true;
			}
		});
		if (hasError) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return !hasError;
	};
	const validateBeforeSave = () => {
		let customFieldValeValid = customFieldsValuesValidateForSave();
		let facilityFieldValesValid = facilityFieldValuesValidateForSave();
		return customFieldValeValid && facilityFieldValesValid;
	};

	const onClickNext = () => {
		var isValid = validateBeforeSave();

		if (!isValid) {
			return;
		}

		let step2State = { ...stepperContext.getStep(BulkUpdateSteps.STEP2_INPUT_VALUES), loading: true };
		stepperContext.updateStep(BulkUpdateSteps.STEP2_INPUT_VALUES, step2State);

		batchUpdateFacilities(
			chosenCustomFields.filter(i => i.chosen === true),
			chosenFacilityFields.filter(i => i.chosen === true)
		)
			.then((bulkUpdateSummary: any) => {
				stepperContext.resolve(bulkUpdateSummary);
			})
			.catch((err: ApiError) => {
				let errMsg = `${err.message} ${err.body.internalMessage}`;
				let summary: BulkUpdateSummary = {
					errors: [errMsg]
				} as BulkUpdateSummary;

				stepperContext.resolve(summary);
			});
	};

	const onFacilityFieldChanged = (e: any) => {
		let newState = chosenFacilityFields.slice();
		const { name, value } = e.target;

		_.each(newState, facilityField => {
			if (String.equalCaseInsensitive(facilityField.fieldName, name)) {
				facilityField.value = value;
			}
		});
		setChosenFacilityFields(newState);
	};

	const onRichTextAreaChanged = (e: any, fieldName: string) => {
		let newState = chosenFacilityFields.slice();
		const value = e.html;
		_.each(newState, facilityField => {
			if (String.equalCaseInsensitive(facilityField.fieldName, fieldName)) {
				facilityField.value = value;
			}
		});
		setChosenFacilityFields(newState);
	};

	const onCustomFieldChanged = (e: any) => {
		let newState = chosenCustomFields.slice();
		const { name, value } = e.target;
		_.each(newState, customField => {
			if (String.equalCaseInsensitive(customField.fieldName, name)) {
				customField.value = String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.Date)
					? value
					: value;
			}
		});
		setChosenCustomFields(newState);
	};

	const getCustomFieldUpdateDiv = () => {
		const chosenItems = chosenCustomFields.filter(i => i.chosen === true);
		if (chosenItems.length < 1) {
			return '';
		}

		const customFieldUpdateDivs = chosenItems.map((customField, index) => {
			switch (customField.fieldDataType) {
				case CustomFieldDataType.Date:
					return customFieldGenerator.getDateInputElement(customField, index, onCustomFieldChanged);
				case CustomFieldDataType.SingleValueDropDown:
					return customFieldGenerator.getSingleSelectionValueElement(
						customField,
						index,
						onCustomFieldChanged
					);
				case CustomFieldDataType.TextArea:
					return customFieldGenerator.getTextAreaInputElement(customField, index, true, onCustomFieldChanged);
				default:
					return customFieldGenerator.getTextInputElement(customField, index, onCustomFieldChanged);
			}
		});

		return (
			<>
				<label className="choose-field-head">
					{localizationService.getLocalizedString('bulkUpdate.customFieldsHead')}
				</label>
				{customFieldUpdateDivs}
			</>
		);
	};

	const getFacilityFieldsUpdateDiv = () => {
		const chosenItems = chosenFacilityFields.filter(i => i.chosen === true);

		if (chosenItems.length < 1) {
			return '';
		}

		const facilityUpdateDivs = chosenItems.map((field, index) => {
			switch (field.dataType) {
				case FieldTypeEnum.Date:
					return facilityFieldGenerator.getDateInputElement(index, field, onFacilityFieldChanged);
				case FieldTypeEnum.SingleValueDropDown:
					return facilityFieldGenerator.getOptionEditDiv(index, field, onFacilityFieldChanged);
				case FieldTypeEnum.String:
					return facilityFieldGenerator.getTextEditDiv(index, field, onFacilityFieldChanged);
				case FieldTypeEnum.TextArea:
					return facilityFieldGenerator.getTextAreaInputElement(index, field, true, onFacilityFieldChanged);
				case FieldTypeEnum.RichTextEditor:
					return facilityFieldGenerator.getRichTextEditorInputElement(index, field, onRichTextAreaChanged);
				default:
					return facilityFieldGenerator.getTextEditDiv(index, field, onFacilityFieldChanged);
			}
		});
		return (
			<>
				<label className="choose-field-head">
					{localizationService.getLocalizedString('bulkUpdate.facilityFieldHead')}
				</label>
				{facilityUpdateDivs}
			</>
		);
	};

	return (
		<StepperContent
			className="full-width"
			id="step3ChooseFacilityFields"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn btn-link" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction type="button" id="btnUpdate" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('bulkUpdate.update')}
					</StepperAction>
				</React.Fragment>
			}>
			<p>{localizationService.getLocalizedString('bulkUpdate.updateReminder')}</p>
			{getFacilityFieldsUpdateDiv()}
			{getCustomFieldUpdateDiv()}
		</StepperContent>
	);
};

export default InputFieldValuesStep;
