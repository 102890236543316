import { CccHazard } from '@rcp/types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DateUtilService, localizationService, navigateTo, Resource, urlService } from 'src/services';

interface OwnProps {
	hazard: CccHazard;
}

type Props = OwnProps;
export const HazardCard: React.FC<Props> = props => {
	const history = useHistory();
	let dateFormat = localizationService.getLocalizedString('dateFormats.displayDate');
	let link = urlService.getReactAuthorityResourceUrl(Resource.CccHazards, props.hazard.hazardId);
	const navigateTohard = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		navigateTo(history, link);
	};
	return (
		<div className="tab-col">
			<div className="mb-3">
				<a href={link} onClick={(e: any) => navigateTohard(e)}>
					{props.hazard.hazardNumber}
				</a>
				&nbsp;&nbsp;
				<span className="text-muted">{props.hazard.serviceStatus} </span>
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.category')}</strong>
				{props.hazard.category ? <div>{props.hazard.category}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.hazardLocation')}</strong>
				{props.hazard.deviceLocation ? <div>{props.hazard.deviceLocation}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.accountNumber')}</strong>
				{props.hazard.accountNumber ? <div>{props.hazard.accountNumber}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.serviceNumber')}</strong>
				{props.hazard.serviceNumber ? <div>{props.hazard.serviceNumber}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.meterNumber')}</strong>
				{props.hazard.meterNumber ? <div>{props.hazard.meterNumber}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.meterLocation')}</strong>
				{props.hazard.meterLocation ? <div>{props.hazard.meterLocation}</div> : <div>&nbsp;</div>}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.deviceType')}</strong>
				{props.hazard.deviceType ? (
					<div>
						{props.hazard.deviceType} &nbsp;
						<span className="text-muted">{props.hazard.deviceStatus} </span>
					</div>
				) : (
					<div>{localizationService.getLocalizedString('cccHazard.noDevice')}</div>
				)}
			</div>

			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.lastTest')}</strong>
				{props.hazard.lastTestDate ? (
					<div>{DateUtilService.getDateStringInAuthorityTimeZone(props.hazard.lastTestDate, dateFormat)}</div>
				) : (
					<div>&nbsp;</div>
				)}
			</div>
			<div className="form-group wrapWord">
				<strong>{localizationService.getLocalizedString('cccHazard.nextTest')}</strong>
				{props.hazard.nextTestDate ? (
					<div>{DateUtilService.getDateStringInAuthorityTimeZone(props.hazard.nextTestDate, dateFormat)}</div>
				) : (
					<div>&nbsp;</div>
				)}
			</div>

			{props.hazard.deviceType && (
				<div className="form-group wrapWord">
					<strong>{localizationService.getLocalizedString('cccHazard.sendNotices')}</strong>
					<div id="sendNotices">
						{props.hazard.sendTestNotices
							? localizationService.getLocalizedString('constants.yes')
							: localizationService.getLocalizedString('constants.no')}
					</div>
				</div>
			)}
		</div>
	);
};
