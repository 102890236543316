import React, { useState } from 'react';
import { localizationService, navigateToRoute } from 'src/services';
import { Nav, TabContent, TabPane, NavItem, NavLink } from 'reactstrap';
import { CustomFieldsTab } from '../../../fog/facilities/facility-details/custom-fields-tab/custom-field-tab';
import classNames from 'classnames';
import { CccHazardOverviewTab } from './hazard-overview-tab';
import { CccHazard } from '@rcp/types';
import { CccHazardServiceTab } from './hazard-service-tab';
import { CccHazardDeviceTab } from './hazard-device-tab';
import { SiteHazardsTab as HazardsTab } from 'src/components/authority/backflow/hazards-tab/site-hazards-tab';
import { HazardTab } from './hazard-tab.enum';
import { HazardContactsTab } from './hazard-contacts-tab';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';

interface OwnProps {
	currentCccHazard: CccHazard;
}
type Props = OwnProps;

export const CccHazardTabs: React.SFC<Props> = props => {
	const [activeTab, setActiveTab] = useState('tab-overview');
	const history = useHistory();
	let { url } = useRouteMatch();
	const toggle = (tab: string) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	React.useEffect(() => {
		const tab = history.location.pathname.split('/') as string[];
		const urlLength = 4;
		if (tab.length > urlLength) {
			toggle(tab[tab.length - 1]);
		} else {
			toggle(HazardTab.Overview);
		}
	}, [history.location.pathname]);

	let deviceNameEle;
	if (props.currentCccHazard.deviceId && props.currentCccHazard.deviceId > 0) {
		deviceNameEle = (
			<span>
				{`${props.currentCccHazard.deviceManufacturer} ${props.currentCccHazard.deviceModel} ${props.currentCccHazard.deviceType} ${props.currentCccHazard.deviceSize}`}
			</span>
		);
	} else {
		deviceNameEle = <span>{localizationService.getLocalizedString('cccHazard.noDevice')}</span>;
	}

	return (
		<section className="info-card">
			<div className="d-flex flex-row">
				<div className="pr-2">
					<h2 className="hazard-details">
						{localizationService.getLocalizedString('screen.labels.details')}
						<span className="ai-light">{deviceNameEle}</span>
					</h2>
				</div>
			</div>
			<Nav tabs className="material">
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.Overview, url)}
						id={HazardTab.Overview}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.Overview) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.overview')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.Service, url)}
						id={HazardTab.Service}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.Service) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.service')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.Device, url)}
						id={HazardTab.Device}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.Device) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('cccHazard.device')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.CustomFields, url)}
						id={HazardTab.CustomFields}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.CustomFields) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.customFields')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.Contacts, url)}
						id={HazardTab.Contacts}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.Contacts) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.contactsAndNotices')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<HazardTab>(history, HazardTab.SiteHazards, url)}
						id={HazardTab.SiteHazards}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, HazardTab.SiteHazards) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.siteHazards')}
					</NavLink>
				</NavItem>
			</Nav>

			<div className="mt-1">
				<TabContent activeTab={activeTab}>
					<TabPane tabId={HazardTab.Overview}>
						<CccHazardOverviewTab currentCccHazard={props.currentCccHazard} />
					</TabPane>
					<TabPane tabId={HazardTab.Service}>
						{String.equalCaseInsensitive(activeTab, HazardTab.Service) && (
							<CccHazardServiceTab currentCccHazard={props.currentCccHazard} />
						)}
					</TabPane>
					<TabPane tabId={HazardTab.Device}>
						{String.equalCaseInsensitive(activeTab, HazardTab.Device) && (
							<CccHazardDeviceTab currentCccHazard={props.currentCccHazard} />
						)}
					</TabPane>
					<TabPane tabId={HazardTab.CustomFields}>
						{String.equalCaseInsensitive(activeTab, HazardTab.CustomFields) && (
							<CustomFieldsTab
								customFieldFor="cccHazard"
								customFieldParentObject={props.currentCccHazard}
							/>
						)}
					</TabPane>
					<TabPane tabId={HazardTab.Contacts}>
						{String.equalCaseInsensitive(activeTab, HazardTab.Contacts) && (
							<HazardContactsTab hazard={props.currentCccHazard} />
						)}
					</TabPane>
					<TabPane tabId={HazardTab.SiteHazards}>
						{String.equalCaseInsensitive(activeTab, HazardTab.SiteHazards) && (
							<HazardsTab siteId={props.currentCccHazard.siteId as number} />
						)}
					</TabPane>
				</TabContent>
			</div>
		</section>
	);
};
