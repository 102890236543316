import React from 'react';

import {
	apiService,
	localizationService,
	optionsMap,
	Resource,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import {
	ApiError,
	AuthorityDetails,
	AuthorityOrpSetting,
	DropDownOption,
	EmailMessage,
	RegulatoryProgramName,
	SettingType,
	SettingValue
} from '@rcp/types';
import { alertService } from 'src/redux/alert';

import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { loadJurisdiction, useReduxSelector } from 'src/redux';

import 'src/components/authority/shared/settings/authority-settings.scss';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { SingleSelectDropdown, TextInput } from 'src/components';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

let initialAuthorityOrpSetting = {} as AuthorityOrpSetting;
let initialAuthorityDetails = {} as AuthorityDetails;

interface SettingError {
	nameError?: string;
	referenceNumberError?: string;
	contactEmailError?: string;
	webSiteUrlError?: string;
	emailContactInfoNameError?: string;
	emailContactInfoEmailAddressError?: string;
	emailContactReplyToEmailAddressError?: string;
	emailServerSenderDefaultEmailAddressError?: string;
	testEmailAddressError?: string;
	sendEmailTypeError?: string;
	emailServerAddressError?: string;
}

let initialSettingError = {} as SettingError;

export enum EmailServerType {
	Default = 'Default',
	Custom = 'Custom'
}

export enum ReplyToEmailType {
	Default = 'Default',
	Custom = 'Custom'
}

const AuthoritySettingsAuthorityComponent: React.FunctionComponent = () => {
	const [logoS3ResourcePath, setLogoS3ResourcePath] = React.useState();
	const [timeZoneOptionValues, setTimeZoneOptionValues] = React.useState([] as DropDownOption[]);
	const [stateProvinceOptionValues, setStateProvinceOptionValues] = React.useState([] as DropDownOption[]);
	const [smtpSecurityOptionValues, setSmtpSecurityOptionValues] = React.useState([] as DropDownOption[]);
	const [smtpProtocolsOptionValues, setSmtpProtocolsOptionValues] = React.useState([] as DropDownOption[]);

	const [authoritySetting, setAuthoritySetting] = React.useState(initialAuthorityOrpSetting);
	const [authorityDetails, setAuthorityDetails] = React.useState(initialAuthorityDetails);
	const [originalAuthorityOrpSetting, setOriginalAuthorityOrpSetting] = React.useState(initialAuthorityOrpSetting);
	const [originalAuthorityDetails, setOriginalAuthorityDetails] = React.useState(initialAuthorityDetails);
	const [settingError, setSettingError] = React.useState(initialSettingError);
	const [replyToEmail, setReplyToEmail] = React.useState<string>(ReplyToEmailType.Default);

	const dispatch = useDispatch();

	const jurisdictions = useReduxSelector(state => state.jurisdictions.jurisdictionList);

	React.useEffect(() => {
		loadAuthoritySettings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (jurisdictions && jurisdictions.length > 0) {
			let optionValues: DropDownOption[] = optionsMap.fromJurisdiction(jurisdictions);
			setStateProvinceOptionValues(optionValues);
		}
	}, [jurisdictions]);

	const loadAuthoritySettings = async () => {
		dispatch(loadJurisdiction());

		const url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Logo`;
		apiService.getResource(url).then((response: any) => {
			setLogoS3ResourcePath(response.logoS3ResourcePath);
		});

		const authorityDetailsUrl = urlService.getAuthorityOrpDetailApiUrl();
		apiService.getResource(authorityDetailsUrl).then((response: any) => {
			setOriginalAuthorityDetails(response);
			setAuthorityDetails(response);
		});

		const timeZoneUrl = urlService.getTimeZoneResourceApiUrl();
		apiService.getResource(timeZoneUrl).then((timeZones: any) => {
			let optionValues: DropDownOption[] = optionsMap.fromTimeZone(timeZones);
			setTimeZoneOptionValues(optionValues);
		});

		const smtpSecurityUrl = urlService.getSmtpSecurityResourceApiUrl();
		apiService.getResource(smtpSecurityUrl).then((securities: any) => {
			let optionValues: DropDownOption[] = optionsMap.fromStrings(securities);
			setSmtpSecurityOptionValues(optionValues);
		});
		const smtpProtocolUrl = urlService.getSmtpProtocolResourceApiUrl();
		apiService.getResource(smtpProtocolUrl).then((protocols: any) => {
			let optionValues: DropDownOption[] = optionsMap.fromStrings(protocols);
			setSmtpProtocolsOptionValues(optionValues);
		});

		let settingTypesWithoutDefaultValues: string[] = [_.toLower(SettingType.EmailServerSenderDefaultEmailAddress)];

		const authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		apiService.getResource<SettingValue[]>(authoritySettingsUrl).then((values: SettingValue[]) => {
			let originalAuthoritySetting = { ...authoritySetting };
			for (let settingValue of values) {
				let key = UtilService.toCamelCase(settingValue.settingType);
				if (_.includes(settingTypesWithoutDefaultValues, _.toLower(settingValue.settingType))) {
					originalAuthoritySetting[key] = settingValue.value;
				} else {
					originalAuthoritySetting[key] = settingValue.value || settingValue.defaultValue;
				}
			}
			if (originalAuthoritySetting.emailContactReplyToEmailAddress !== '') {
				setReplyToEmail(ReplyToEmailType.Custom);
			}
			setAuthoritySetting(originalAuthoritySetting);
			setOriginalAuthorityOrpSetting(originalAuthoritySetting);
		});
	};

	const uploadFiles = async (selectedFile: File) => {
		const url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Logo`;
		let formData = new FormData();
		formData.append('files', selectedFile);
		const headers = {};

		return await apiService.postFormData(url, formData, headers);
	};

	const onLogImageChanged = (event: any) => {
		event.preventDefault();

		const inputElement = event.target as HTMLInputElement;
		const files = inputElement.files as FileList;

		if (files == null || files.length < 1) {
			return;
		}

		uploadFiles(files[0])
			.then((response: any) => {
				setLogoS3ResourcePath(response.logoS3ResourcePath);
			})
			.catch((error: any) => {
				if (error instanceof ApiError) {
					if (error.statusCode === 413) {
						alertService.addError(
							localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
						);
					} else {
						alertService.addError(error.message);
					}
				} else {
					alertService.addError(error.message.message);
				}
			});
	};

	const onAuthorityOrpFieldValueChanged = (e: any) => {
		let newAuthorityDetails = { ...authorityDetails };
		const { name, value } = e.target;
		_.set(newAuthorityDetails, name, value);
		setAuthorityDetails(newAuthorityDetails);
	};

	const fieldValidation = (name: string, value: any, type: any, required: boolean, dataset: any): boolean => {
		let errorObject = { ...settingError };
		let isValid = true;
		let piggyback = '' + dataset['piggyback'] || '';
		if (type === 'url') {
			isValid = validationService.validateUrl(value, errorObject, `${name}Error`);
			setSettingError(errorObject);
			if (isValid === false) {
				return isValid;
			}
		}

		if (type === 'email') {
			isValid = validationService.validateEmailValue(value, errorObject, `${name}Error`);
			setSettingError(errorObject);
			if (isValid === false) {
				return isValid;
			}
		}

		if (required) {
			isValid = validationService.validateRequiredValue(value, errorObject, `${name}Error`, piggyback);
			setSettingError(errorObject);
			if (isValid === false) {
				return isValid;
			}
		}
		return isValid;
	};
	const updateAuthorityDetailsValue = (e: any) => {
		const { name, value, type, required, dataset } = e.target;

		if (!fieldValidation(name, value, type, required, dataset)) {
			return;
		}

		let dataToUpdate = {};
		_.set(dataToUpdate, name, value);
		if (originalAuthorityDetails[name] === value) {
			return;
		}
		let newSetting = { ...authorityDetails };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthorityDetails(newSetting);

		const authorityDetailsUrl = urlService.getAuthorityOrpDetailApiUrl();
		apiService
			.patchResource(authorityDetailsUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				_.unset(settingError, `${name}Error`);
				setAuthorityDetails(newSetting);
				let updatedOriginalAuthorityDetails = { ...originalAuthorityDetails };
				updatedOriginalAuthorityDetails[name] = value;
				setOriginalAuthorityDetails(updatedOriginalAuthorityDetails);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthorityDetails(newSetting);
			});
	};

	const onAuthorityOrpSettingChanged = (e: any) => {
		let newSetting = { ...authoritySetting };
		const { name, value } = e.target;
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);
	};

	const updateReplyToEmail = (e: any) => {
		const {
			target: { name, value }
		} = e;
		if (String.equalCaseInsensitive(value, ReplyToEmailType.Default)) {
			setAuthoritySetting({
				...authoritySetting,
				emailContactReplyToEmailAddress: ''
			});
			setSettingError({ ...setSettingError, emailContactReplyToEmailAddressError: '' });
			updateDefaultReplyAuthoritySetting();
		}
		setReplyToEmail(value);
	};

	const updateDefaultReplyAuthoritySetting = () => {
		const name = 'emailContactReplyToEmailAddress';
		const value = '';
		let dataToUpdate = {
			settingType: name,
			value: value
		};
		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				_.unset(settingError, `${name}Error`);
				let updateAuthorityOrpSetting = { ...originalAuthorityOrpSetting };
				updateAuthorityOrpSetting[name] = value;
				setOriginalAuthorityOrpSetting(updateAuthorityOrpSetting);
				setAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};

	const updateAuthoritySettingValue = (e: any) => {
		const { name, value, type, required, dataset } = e.target;
		if (!fieldValidation(name, value, type, required, dataset)) {
			return;
		}

		let dataToUpdate = {
			settingType: name,
			value: value
		};
		if (originalAuthorityOrpSetting[name] === value) {
			return;
		}

		let newSetting = { ...authoritySetting };
		let oldValue = _.get(newSetting, name);
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);

		let authoritySettingUrl = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		apiService
			.postResource(authoritySettingUrl, dataToUpdate)
			.then(() => {
				_.set(newSetting, name, value);
				_.unset(settingError, `${name}Error`);
				let updateAuthorityOrpSetting = { ...originalAuthorityOrpSetting };
				updateAuthorityOrpSetting[name] = value;
				setOriginalAuthorityOrpSetting(updateAuthorityOrpSetting);
				setAuthoritySetting(newSetting);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.body.message);
				_.set(newSetting, name, oldValue);
				setAuthoritySetting(newSetting);
			});
	};

	const onTestEmailFieldBlur = (e: any) => {
		const { name, value, type, required, dataset } = e.target;
		if (!fieldValidation(name, value, type, required, dataset)) {
			return;
		}
		let updateError = { ...settingError };
		_.unset(updateError, `${name}Error`);
		setSettingError(updateError);
	};

	const onTestEmailChanged = (e: any) => {
		let newSetting = { ...authoritySetting };
		const { name, value } = e.target;
		_.set(newSetting, name, value);
		setAuthoritySetting(newSetting);
	};

	const isValidToSendTestEmail = (): boolean => {
		if (
			!fieldValidation('testEmailAddress', authoritySetting.testEmailAddress, 'email', false, {
				piggyback: localizationService.getLocalizedString('authoritySetting.testEmailAddress')
			})
		) {
			return false;
		}

		if (String.equalCaseInsensitive(authoritySetting.emailServerType, EmailServerType.Custom)) {
			return (
				fieldValidation('emailServerAddress', authoritySetting.emailServerAddress, 'text', true, {
					piggyback: localizationService.getLocalizedString('authoritySetting.emailServerAddress')
				}) &&
				fieldValidation(
					'emailServerSenderDefaultEmailAddress',
					authoritySetting.emailServerSenderDefaultEmailAddress,
					'email',
					true,
					{
						piggyback: localizationService.getLocalizedString(
							'authoritySetting.emailServerSenderDefaultEmailAddress'
						)
					}
				)
			);
		}
		return true;
	};

	const validateTestEmailInput = (testEmail: string, settingError: SettingError) => {
		const error = { ...settingError };
		const testEmailText = localizationService.getLocalizedString('authoritySetting.testEmailAddress');
		if (!validationService.validateRequiredValue(testEmail, error, `testEmailAddressError`, testEmailText)) {
			setSettingError(error);
			alertService.addError(error.testEmailAddressError as string);
			return false;
		} else if (!isValidToSendTestEmail()) {
			alertService.addError(settingError.testEmailAddressError as string);
			return false;
		}
		return true;
	};

	const sendTestEmail = () => {
		let testEmail = authoritySetting.testEmailAddress || '';
		if (!validateTestEmailInput(testEmail, settingError)) {
			return;
		}

		if (validationService.validateEmailValue(testEmail, { ...settingError }, `testEmailAddressError`)) {
			const testEmailUrl = urlService.getAuthorityTestEmailUrl();
			const testEmailMessage: EmailMessage = {
				subject: localizationService.getLocalizedString('authoritySetting.testEmailSubject'),
				recipientEmailAddresses: [
					{
						name: '',
						address: testEmail
					}
				],
				content: localizationService.getLocalizedString(
					'authoritySetting.testEmailContent',
					window.location.origin
				)
			};

			apiService
				.httpPost(testEmailUrl, testEmailMessage)
				.then((res: any) => {
					alertService.addSuccess(res);
				})
				.catch((e: ApiError) => {
					alertService.addError(e.body.message);
				});
		}
	};
	return (
		<div className="page">
			{urlService.isAdministrator() ? (
				<>
					<div className="page-header">
						{String.equalCaseInsensitive(
							urlService.getCurrentProgramOrDefault(),
							RegulatoryProgramName.FOG
						) && <BackArrow />}
						<h1>{localizationService.getLocalizedString('authoritySetting.authority')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">
							{getContactSection()}
							<hr />
							{getAuthoritySection()}
							<hr />
							{getReplyToEmailSection()}
							<hr />
							{getSendEmailSection()}
							<hr />
							{getTimeZoneSection()}
						</div>
					</div>
				</>
			) : (
				<AccessDeniedPage />
			)}
		</div>
	);

	function getAuthoritySection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.details')}</strong>
				</div>
				<div className="col-lg-8">
					<section>
						<TextInput
							id="name"
							name="name"
							piggyback={localizationService.getLocalizedString('authoritySetting.authorityName')}
							isRequired={true}
							label={localizationService.getLocalizedString('authoritySetting.authorityName')}
							value={authorityDetails.name}
							onBlur={updateAuthorityDetailsValue}
							onEnterKeyPressed={updateAuthorityDetailsValue}
							onChange={onAuthorityOrpFieldValueChanged}
							error={settingError.nameError}
						/>
						<TextInput
							id="webSiteUrl"
							name="webSiteUrl"
							type="url"
							label={localizationService.getLocalizedString('authoritySetting.webSite')}
							value={authorityDetails.webSiteUrl}
							onBlur={updateAuthorityDetailsValue}
							onEnterKeyPressed={updateAuthorityDetailsValue}
							onChange={onAuthorityOrpFieldValueChanged}
							error={settingError.webSiteUrlError}
						/>
						<TextInput
							id="referenceNumber"
							name="referenceNumber"
							label={localizationService.getLocalizedString('authoritySetting.npdesPermitNumber')}
							value={authorityDetails.referenceNumber}
							onBlur={updateAuthorityDetailsValue}
							onEnterKeyPressed={updateAuthorityDetailsValue}
							onChange={onAuthorityOrpFieldValueChanged}
							error={settingError.referenceNumberError}
						/>

						<label>{localizationService.getLocalizedString('authoritySetting.logo')}</label>
						<div className="form-group">
							<div className="form-group ai-file-input">
								<label
									className="btn ai-secondary"
									htmlFor="btnFileImport"
									aria-describedby="file-input-help">
									{localizationService.getLocalizedString('importFile.chooseFile')}
								</label>
								<div id="file-input-help" className="form-text ai-form-help">
									{localizationService.getLocalizedString('authoritySetting.imageHint')}
								</div>
								<input type="file" id="btnFileImport" onChange={onLogImageChanged} accept=".jpg,.png" />
							</div>
							<div>
								{logoS3ResourcePath && (
									<img
										id="imageAuthorityLogo"
										src={logoS3ResourcePath}
										alt="Authority Logo"
										className="img-fluid"
										height="50"
										width="150"
									/>
								)}
							</div>
						</div>
					</section>
				</div>
			</div>
		);
	}

	function getContactSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.contactInformation')}</strong>
				</div>
				<div className="col-lg-8">
					<section>
						<TextInput
							id="emailContactInfoName"
							name="emailContactInfoName"
							isRequired={true}
							label={localizationService.getLocalizedString('authoritySetting.emailContactInfoName')}
							value={authoritySetting.emailContactInfoName}
							onBlur={updateAuthoritySettingValue}
							onEnterKeyPressed={updateAuthoritySettingValue}
							onChange={onAuthorityOrpSettingChanged}
							error={settingError.emailContactInfoNameError}
						/>
						<TextInput
							id="emailContactInfoEmailAddress"
							name="emailContactInfoEmailAddress"
							type="email"
							isRequired={true}
							label={localizationService.getLocalizedString('authoritySetting.emailContactInfoEmail')}
							value={authoritySetting.emailContactInfoEmailAddress}
							onBlur={updateAuthoritySettingValue}
							onEnterKeyPressed={updateAuthoritySettingValue}
							onChange={onAuthorityOrpSettingChanged}
							error={settingError.emailContactInfoEmailAddressError}
						/>
						<TextInput
							id="emailContactInfoPhone"
							name="emailContactInfoPhone"
							label={localizationService.getLocalizedString('authoritySetting.emailContactInfoPhone')}
							value={authoritySetting.emailContactInfoPhone}
							onBlur={updateAuthoritySettingValue}
							onEnterKeyPressed={updateAuthoritySettingValue}
							onChange={onAuthorityOrpSettingChanged}
						/>
						<TextInput
							id="addressLine1"
							name="addressLine1"
							label={localizationService.getLocalizedString('authoritySetting.addressLine1')}
							value={authorityDetails.addressLine1}
							onBlur={updateAuthorityDetailsValue}
							onEnterKeyPressed={updateAuthorityDetailsValue}
							onChange={onAuthorityOrpFieldValueChanged}
						/>
						<TextInput
							id="addressLine2"
							name="addressLine2"
							label={localizationService.getLocalizedString('authoritySetting.addressLine2')}
							value={authorityDetails.addressLine2}
							onBlur={updateAuthorityDetailsValue}
							onEnterKeyPressed={updateAuthorityDetailsValue}
							onChange={onAuthorityOrpFieldValueChanged}
						/>
						<div className="form-row">
							<TextInput
								id="cityName"
								name="cityName"
								label={localizationService.getLocalizedString('authoritySetting.city')}
								value={authorityDetails.cityName}
								onBlur={updateAuthorityDetailsValue}
								onEnterKeyPressed={updateAuthorityDetailsValue}
								onChange={onAuthorityOrpFieldValueChanged}
								className="form-group col-sm-4"
							/>
							<SingleSelectDropdown
								id="jurisdictionId"
								name="jurisdictionId"
								label={localizationService.getLocalizedString('authoritySetting.stateProvince')}
								value={_.toString(authorityDetails.jurisdictionId)}
								onChange={updateAuthorityDetailsValue}
								options={stateProvinceOptionValues}
								className="form-group col-sm-4"
							/>
							<TextInput
								id="zipCode"
								name="zipCode"
								label={localizationService.getLocalizedString('authoritySetting.zipCode')}
								value={authorityDetails.zipCode}
								onBlur={updateAuthorityDetailsValue}
								onEnterKeyPressed={updateAuthorityDetailsValue}
								onChange={onAuthorityOrpFieldValueChanged}
								className="form-group col-sm-4"
							/>
						</div>
					</section>
				</div>
			</div>
		);
	}

	function getTimeZoneSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.timeZone')}</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.timeZoneSectionDescription')}</p>
				</div>
				<div className="col-lg-8">
					<section>
						<SingleSelectDropdown
							id="timeZone"
							name="timeZone"
							isRequired={true}
							label={localizationService.getLocalizedString('authoritySetting.timeZone')}
							value={_.toString(authoritySetting.timeZone)}
							onChange={updateAuthoritySettingValue}
							options={timeZoneOptionValues}
						/>
					</section>
				</div>
			</div>
		);
	}

	function getReplyToEmailSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.replyToEmailSection')}</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.replyToEmailSectionDescription')}</p>
				</div>
				<div className="col-lg-8">
					<section>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="replyToEmailAddress"
								value={ReplyToEmailType.Default}
								checked={String.equalCaseInsensitive(replyToEmail, ReplyToEmailType.Default)}
								id="emailReplyToTypeDefault"
								onChange={updateReplyToEmail}
							/>
							<label className="custom-control-label" htmlFor="emailReplyToTypeDefault">
								<strong>
									{localizationService.getLocalizedString('authoritySetting.sendEmailDefault')}
								</strong>{' '}
								-{' '}
								<span>
									{localizationService.getLocalizedString('authoritySetting.replyToEmailAsDefault')}
								</span>
							</label>
						</div>
						<div className="custom-control custom-radio">
							<input
								type="radio"
								className="custom-control-input"
								name="replyToEmailAddress"
								value={ReplyToEmailType.Custom}
								checked={String.equalCaseInsensitive(replyToEmail, ReplyToEmailType.Custom)}
								id="emailReplyToTypeCustom"
								onChange={updateReplyToEmail}
							/>
							<label className="custom-control-label" htmlFor="emailReplyToTypeCustom">
								<strong>
									{localizationService.getLocalizedString('authoritySetting.CustomReplyEmail')}
								</strong>{' '}
								-{' '}
								<span>
									{localizationService.getLocalizedString('authoritySetting.replyToEmailAsCustom')}
								</span>
							</label>
						</div>
						{String.equalCaseInsensitive(replyToEmail, ReplyToEmailType.Custom) ? (
							<div className="ml-4">
								<TextInput
									id="emailContactReplyToEmailAddress"
									name="emailContactReplyToEmailAddress"
									type="email"
									label={localizationService.getLocalizedString(
										'authoritySetting.emailContactInfoEmail'
									)}
									value={authoritySetting.emailContactReplyToEmailAddress}
									onBlur={updateAuthoritySettingValue}
									onEnterKeyPressed={updateAuthoritySettingValue}
									onChange={onAuthorityOrpSettingChanged}
									error={settingError.emailContactReplyToEmailAddressError}
									isRequired
								/>
							</div>
						) : null}
					</section>
				</div>
			</div>
		);
	}

	function getSendEmailSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.sendEmailSection')}</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.sendEmailDescription')}</p>
					<p>{localizationService.getLocalizedString('authoritySetting.sendEmailDescriptionSMTP')}</p>
				</div>
				<div className="col-lg-8">
					{String.equalCaseInsensitive(authoritySetting.emailServerType, undefined) ? (
						<div></div>
					) : (
						<section>
							<div className="custom-control custom-radio">
								<input
									type="radio"
									className="custom-control-input"
									name="emailServerType"
									value={EmailServerType.Default}
									checked={String.equalCaseInsensitive(
										authoritySetting.emailServerType,
										EmailServerType.Default
									)}
									id="emailServerTypeDefault"
									onChange={updateAuthoritySettingValue}
								/>
								<label className="custom-control-label" htmlFor="emailServerTypeDefault">
									<strong>
										{localizationService.getLocalizedString('authoritySetting.sendEmailDefault')}
									</strong>{' '}
									-{' '}
									<span>
										{localizationService.getLocalizedString(
											'authoritySetting.sendEmailDefaultDescription',
											authoritySetting!.systemEmailServerSenderDefaultEmailAddress as string
										)}
									</span>
								</label>
							</div>

							<div className="custom-control custom-radio form-group">
								<input
									type="radio"
									className="custom-control-input"
									name="emailServerType"
									value={EmailServerType.Custom}
									checked={String.equalCaseInsensitive(
										authoritySetting.emailServerType,
										EmailServerType.Custom
									)}
									id="emailServerTypeCustom"
									onChange={updateAuthoritySettingValue}
								/>
								<label className="custom-control-label" htmlFor="emailServerTypeCustom">
									<strong>
										{localizationService.getLocalizedString('authoritySetting.sendEmailCustom')}
									</strong>{' '}
									-{' '}
									<span>
										{localizationService.getLocalizedString(
											'authoritySetting.sendEmailCustomDescription'
										)}
									</span>
								</label>
							</div>
							{String.equalCaseInsensitive(authoritySetting.emailServerType, EmailServerType.Default) ? (
								<div></div>
							) : (
								getSmtpSection()
							)}
							{getTestEmailSection()}
						</section>
					)}
				</div>
			</div>
		);
	}

	function getTestEmailSection() {
		return (
			<div className="d-flex">
				<div className="flex-grow-1">
					<TextInput
						id="testEmailAddress"
						name="testEmailAddress"
						type="email"
						className="full-width-no-padding"
						label={localizationService.getLocalizedString('authoritySetting.testEmailAddress')}
						value={authoritySetting.testEmailAddress}
						onBlur={onTestEmailFieldBlur}
						onChange={onTestEmailChanged}
						error={settingError.testEmailAddressError}
						helpText={localizationService.getLocalizedString('authoritySetting.testEmailText')}
					/>
				</div>
				<div className="ml-2 mt-4 mt-sm-4">
					<button className="btn ai-action mb-2" id="btnSendTestEmail" onClick={() => sendTestEmail()}>
						{localizationService.getLocalizedString('authoritySetting.sendTestEmail')}
					</button>
				</div>
			</div>
		);
	}

	function getSmtpSection() {
		return (
			<div className="form-row">
				<TextInput
					className="col-sm-6"
					id="emailServerAddress"
					isRequired
					error={settingError.emailServerAddressError}
					name="emailServerAddress"
					label={localizationService.getLocalizedString('authoritySetting.emailServerAddress')}
					value={authoritySetting.emailServerAddress}
					onBlur={updateAuthoritySettingValue}
					onEnterKeyPressed={updateAuthoritySettingValue}
					onChange={onAuthorityOrpSettingChanged}
				/>
				<TextInput
					id="emailServerPortNumber"
					name="emailServerPortNumber"
					className="col-sm-6"
					type="number"
					label={localizationService.getLocalizedString('authoritySetting.emailServerPortNumber')}
					value={authoritySetting.emailServerPortNumber}
					onBlur={updateAuthoritySettingValue}
					onEnterKeyPressed={updateAuthoritySettingValue}
					onChange={onAuthorityOrpSettingChanged}
				/>
				<SingleSelectDropdown
					className="col-sm-6"
					id="emailServerEncryption"
					name="emailServerEncryption"
					noEmptyOption={true}
					label={localizationService.getLocalizedString('authoritySetting.emailServerEncryption')}
					value={_.toString(authoritySetting.emailServerEncryption)}
					onChange={updateAuthoritySettingValue}
					options={smtpSecurityOptionValues}
				/>
				<SingleSelectDropdown
					className="col-sm-6"
					id="emailProtocol"
					name="emailProtocol"
					label={localizationService.getLocalizedString('authoritySetting.emailProtocol')}
					value={authoritySetting.emailProtocol}
					onChange={updateAuthoritySettingValue}
					options={smtpProtocolsOptionValues}
				/>
				<TextInput
					id="emailServerUserName"
					name="emailServerUserName"
					className="col-sm-6"
					label={localizationService.getLocalizedString('authoritySetting.emailServerUserName')}
					value={authoritySetting.emailServerUserName}
					onBlur={updateAuthoritySettingValue}
					onEnterKeyPressed={updateAuthoritySettingValue}
					onChange={onAuthorityOrpSettingChanged}
				/>
				<TextInput
					className="col-sm-6"
					id="emailServerPassword"
					name="emailServerPassword"
					type="password"
					label={localizationService.getLocalizedString('authoritySetting.emailServerPassword')}
					value={authoritySetting.emailServerPassword}
					onBlur={updateAuthoritySettingValue}
					onEnterKeyPressed={updateAuthoritySettingValue}
					onChange={onAuthorityOrpSettingChanged}
				/>
				<TextInput
					id="emailServerSenderDefaultEmailAddress"
					name="emailServerSenderDefaultEmailAddress"
					type="email"
					isRequired
					piggyback={localizationService.getLocalizedString(
						'authoritySetting.emailServerSenderDefaultEmailAddress'
					)}
					className="col-sm-6"
					label={localizationService.getLocalizedString(
						'authoritySetting.emailServerSenderDefaultEmailAddress'
					)}
					value={authoritySetting.emailServerSenderDefaultEmailAddress}
					onBlur={updateAuthoritySettingValue}
					onEnterKeyPressed={updateAuthoritySettingValue}
					onChange={onAuthorityOrpSettingChanged}
					error={settingError.emailServerSenderDefaultEmailAddressError}
				/>
			</div>
		);
	}
};

export const AuthoritySettingsAuthority = AuthoritySettingsAuthorityComponent;
