import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';

import * as ApiTypes from '@rcp/types';
import { apiService, urlService, Resource } from 'src/services';
import { alertService } from '../alert/alert-service';
import { AttachmentType } from '@rcp/types';

export interface FacilityAttachmentsState {
	totalFacilityAttachments: number;
	facilityAttachments: ApiTypes.AttachmentOwnership[];
}

export const initialFacilityAttachmentsState: FacilityAttachmentsState = {
	totalFacilityAttachments: 0,
	facilityAttachments: []
};

enum ActionType {
	LoadFacilityAttachmentsRequest = 'loadFacilityAttachmentsRequest',
	LoadFacilityAttachmentsSuccess = 'loadFacilityAttachmentsSuccess',
	SaveFacilityAttachmentRequest = 'saveFacilityAttachmentRequest',
	SaveFacilityAttachmentSuccess = 'saveFacilityAttachmentSuccess',
	DeleteFacilityAttachmentRequest = 'deleteFacilityAttachmentRequest',
	DeleteFacilityAttachmentSuccess = 'deleteFacilityAttachmentSuccess',
	ResetFacilityAttachment = 'resetFacilityAttachment'
}

export type FacilityAttachmentsThunkAction = ThunkAction<any, FacilityAttachmentsState, any, Action>;

export const defaultNumberOfAttachments = 6;
export const maximumOfNumberOfAttachments = 10000;
export const loadFacilityAttachments = (
	facilityId: number,
	doesGetAll: boolean,
	attachmentType?: AttachmentType,
	ownerId?: number
): FacilityAttachmentsThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.LoadFacilityAttachmentsRequest });

	let number = doesGetAll === true ? maximumOfNumberOfAttachments : defaultNumberOfAttachments;
	let url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.FogFacilities
	}/${facilityId}/Attachments?size=${number}`;

	if (attachmentType) {
		url = `${url}&attachmentType=${attachmentType}`;
	}
	if (ownerId) {
		url = `${url}&ownerId=${ownerId}`;
	}
	let facilityAttachments = await apiService.getPaginatedResources<ApiTypes.AttachmentOwnership>(url);

	dispatch({
		type: ActionType.LoadFacilityAttachmentsSuccess,
		facilityAttachments: !ownerId
			? facilityAttachments.result
			: facilityAttachments.result.filter(i => i.ownerId === Number(ownerId)),
		totalFacilityAttachments: facilityAttachments.total
	});
};

export const saveFacilityAttachment = (
	facilityId: number,
	attachmentToUpdate: ApiTypes.AttachmentOwnership,
	succeededAlertMessage: string
): FacilityAttachmentsThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.SaveFacilityAttachmentRequest });

	const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${facilityId}/Attachments/${
		attachmentToUpdate.attachmentOwnershipId
	}`;

	const updatedFacilityAttachment = await apiService.patchResource<ApiTypes.AttachmentOwnership>(
		url,
		attachmentToUpdate
	);
	dispatch({ type: ActionType.SaveFacilityAttachmentSuccess, facilityAttachment: updatedFacilityAttachment });
	alertService.addSuccess(succeededAlertMessage);
};

export const deleteFacilityAttachment = (
	facilityId: number,
	facilityAttachmentId: number,
	succeededAlertMessage: string,
	callbackOnSuccess?: () => void
): FacilityAttachmentsThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.DeleteFacilityAttachmentRequest });

	const url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.FogFacilities
	}/${facilityId}/Attachments/${facilityAttachmentId}`;

	await apiService.deleteResource(url);
	dispatch({ type: ActionType.DeleteFacilityAttachmentSuccess });
	succeededAlertMessage && alertService.addSuccess(succeededAlertMessage);

	if (callbackOnSuccess) {
		callbackOnSuccess();
	}
};

export const resetFacilityAttachment = () => async (dispatch: any) => {
	dispatch({ type: ActionType.ResetFacilityAttachment });
};

export const facilityAttachmentsReducer = (
	state = initialFacilityAttachmentsState,
	action: any
): FacilityAttachmentsState => {
	switch (action.type) {
		case ActionType.LoadFacilityAttachmentsRequest:
			return { ...state, totalFacilityAttachments: 0, facilityAttachments: [] };
		case ActionType.LoadFacilityAttachmentsSuccess:
			return {
				...state,
				totalFacilityAttachments: action.totalFacilityAttachments,
				facilityAttachments: action.facilityAttachments
			};
		case ActionType.SaveFacilityAttachmentRequest:
			return { ...state };
		case ActionType.SaveFacilityAttachmentSuccess:
			let savedFacilityAttachment = action.facilityAttachment;
			let facilityAttachments = _.cloneDeep(state.facilityAttachments);
			let index = facilityAttachments.findIndex(
				item => item.attachmentId === savedFacilityAttachment.attachmentId
			);
			if (index !== -1) {
				facilityAttachments[index] = savedFacilityAttachment;
			}
			return { ...state, facilityAttachments: facilityAttachments };
		case ActionType.ResetFacilityAttachment:
			return { ...state, facilityAttachments: [] };
		case ActionType.DeleteFacilityAttachmentRequest:
		case ActionType.DeleteFacilityAttachmentSuccess:
		default:
			return state;
	}
};
