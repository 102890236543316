import { SampleActionType, SampleAction } from './samples-actions';
import { SampleState, initialSampleState } from './samples-state';

export const IppSampleReducer = (state = initialSampleState, action: SampleAction): SampleState => {
	switch (action.type) {
		case SampleActionType.LoadSampleRequest:
		case SampleActionType.LoadMonitoringPointsRequest:
		case SampleActionType.LoadCollectionMethodsRequest:
		case SampleActionType.LoadSampleTypesRequest:
		case SampleActionType.LoadParameterGroupsRequest:
		case SampleActionType.LoadFlowUnitsRequest:
		case SampleActionType.LoadSelectedParametersRequest:
		case SampleActionType.LoadFloatNumbersRequest:
		case SampleActionType.SetSampleDataRequest:
		case SampleActionType.LoadProgramSettingsRequest:
		case SampleActionType.LoadMassLoadingUnitRequest:
		case SampleActionType.UpdateSampleRequest:
		case SampleActionType.LoadParametersRequest:
			return { ...state };
		case SampleActionType.LoadSampleSuccess:
		case SampleActionType.SetSampleDataSuccess:
		case SampleActionType.UpdateSampleSuccess:
			return { ...state, sample: action.sample };
		case SampleActionType.LoadCollectionMethodsSuccess:
			return { ...state, collectionMethods: action.collectionMethods };
		case SampleActionType.LoadSampleTypesSuccess:
			return { ...state, sampleTypes: action.sampleTypes };
		case SampleActionType.LoadFlowUnitsSuccess:
			return { ...state, flowUnits: action.flowUnits };
		case SampleActionType.LoadMonitoringPointsSuccess:
			return { ...state, monitoringPoints: action.monitoringPoints };
		case SampleActionType.LoadParameterGroupsSuccess:
			return { ...state, parameterGroups: action.parameterGroups };
		case SampleActionType.LoadSelectedParametersSuccess:
			return { ...state, selectedParameters: action.selectedParameters };
		case SampleActionType.LoadFloatNumbersSuccess:
			return { ...state, floatNumbers: action.floatNumbers };
		case SampleActionType.LoadProgramSettingsSuccess:
			return { ...state, programSettings: action.programSettings };
		case SampleActionType.LoadMassLoadingUnitSuccess:
			return { ...state, massLoadingUnit: action.massLoadingUnit };
		case SampleActionType.LoadParametersSuccess:
			return { ...state, parameters: action.parameters };
		default:
			return state;
	}
};
