import * as ApiTypes from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import {
	loadInvitationDetailsRequest,
	loadInvitationDetailsSuccess,
	loadQuestionsRequest,
	loadQuestionsSuccess,
	loadStatesRequest,
	loadStatesSuccess,
	RegisterAction,
	RegisterState
} from '.';
import { apiService, Resource, urlService } from 'src/services';

type RegisterThunkAction = ThunkAction<any, RegisterState, any, RegisterAction>;

export const getInvitationDetails = (id: number): RegisterThunkAction => async (dispatch, getState) => {
	dispatch(loadInvitationDetailsRequest());
	let url = `${urlService.getApiAccountBaseUrl()}/Account/${Resource.InvitationDetails}/${id}`;

	let invitationDetails = await apiService.getResource<ApiTypes.InvitationDetails>(url);
	dispatch(loadInvitationDetailsSuccess(invitationDetails));
	return invitationDetails;
};

export const loadQuestions = (): RegisterThunkAction => async (dispatch, getState) => {
	dispatch(loadQuestionsRequest());
	let url = `${urlService.getApiAccountBaseUrl()}/Account/Questions`;

	let Questions = await apiService.getResource<ApiTypes.MfaQuestion[]>(url);
	dispatch(loadQuestionsSuccess(Questions));
};

export const loadStates = (): RegisterThunkAction => async (dispatch, getState) => {
	dispatch(loadStatesRequest());
	let url = `${urlService.getApiAccountBaseUrl()}/Account/Jurisdictions`;

	let States = await apiService.getResource<ApiTypes.Jurisdiction[]>(url);
	dispatch(loadStatesSuccess(States));
};
