import { ColumnField, FilterObjectName, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import React, { FC } from 'react';
import { GridOption } from 'src/features/data-grid/data-grid';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import { localizationService, localStorageService, navigateTo, Resource, urlService } from 'src/services';
import IppReportPackageReviewsComponent from './reviews';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppReportPackagesErrorsReviews: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getReactAuthorityResourceUrl(
			`authority/${Resource.IppReportPackages}`,
			dataItem.reportPackageId
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showSearch: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageError,
		searchHint: localizationService.getLocalizedString('ipp.reportPackage.package.searchHint'),
		subHeading: localizationService.getLocalizedString('ipp.reportPackage.package.errorReviewHelperText'),
		subHeadingClass: 'font-size-16px-regular',
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Errors'),
		sort: localStorageService.getLocalStorage(LocalStorageName.IppErrorReportPackageSortOrder) || [],
		showEditColumnModal: true,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.Error,
			page: initialPage,
			sort: 'submissionDateTimeLocal desc'
		},
		anchorElement: null,
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('ctsEventTypeName'),
			new ColumnField('ctsEventCategoryName'),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('submitterUserName'),
			new ColumnField('lastSentDateTimeLocal', FilterType.Date)
		],

		defaultColumns: [
			'organizationReferenceNumber',
			'organizationName',
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal',
			'lastSentDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			sortOrderLocalStorageName={LocalStorageName.IppErrorReportPackageSortOrder}
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppReportPackagesErrorsReviews;
