import { ThunkAction } from 'redux-thunk';
import * as ActionList from './jurisdiction-actions';
import { apiService, Resource, urlService } from 'src/services';

export type JurisdictionThunkAction = ThunkAction<
	any,
	ActionList.JurisdictionListState,
	any,
	ActionList.JurisdictionAction
>;

export const loadJurisdiction = (): JurisdictionThunkAction => async (dispatch, getState) => {
	const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Jurisdictions}`;
	const result = await apiService.httpGet(url);

	dispatch(ActionList.loadJurisdictionListAction(result));
};
