import { ThunkAction } from 'redux-thunk';
import * as ApiTypes from '@rcp/types';
import { apiService, DateUtilService, localizationService, Resource, urlService } from 'src/services';
import { SampleState } from './samples-state';
import {
	loadCollectionMethodsRequest,
	loadCollectionMethodsSuccess,
	loadFlowUnitsRequest,
	loadFlowUnitsSuccess,
	loadMonitoringPointsRequest,
	loadMonitoringPointsSuccess,
	loadParameterGroupsRequest,
	loadParameterGroupsSuccess,
	loadSampleRequest,
	loadSelectedParametersRequest,
	loadSelectedParametersSuccess,
	loadSampleSuccess,
	loadSampleTypesRequest,
	loadSampleTypesSuccess,
	SampleAction,
	setSampleDataRequest,
	setSampleDataSuccess,
	loadFloatNumbersRequest,
	loadFloatNumbersSuccess,
	loadProgramSettingsRequest,
	loadProgramSettingsSuccess,
	loadMassLoadingUnitRequest,
	loadMassLoadingUnitSuccess,
	loadParametersRequest,
	loadParametersSuccess,
	updateSampleRequest
} from './samples-actions';
import { IppIndustrySample, IppReportPackageParameter } from '@rcp/types';
import { alertService } from 'src/redux';
import { SampleStatus } from 'src/constants';

type SampleThunkAction = ThunkAction<any, SampleState, any, SampleAction>;

export const loadMonitoringPoints = (querystring = ''): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadMonitoringPointsRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppMonitoringPoints);
	let monitoringPoints = await apiService.getResource<ApiTypes.IppMonitoringPoint[]>(url);

	dispatch(loadMonitoringPointsSuccess(monitoringPoints));
};

export const loadCollectionMethods = (querystring = ''): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadCollectionMethodsRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppCollectionMethods, 'sort=name asc');
	let collectionMethods = await apiService.getResource<ApiTypes.IppCollectionMethod[]>(url);
	dispatch(loadCollectionMethodsSuccess(collectionMethods));
};

export const loadSampleTypes = (querystring = ''): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadSampleTypesRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleTypes);
	let sampleTypes = await apiService.getResource<ApiTypes.IppSampleType[]>(url);

	dispatch(loadSampleTypesSuccess(sampleTypes));
};

export const loadFlowUnits = (querystring = ''): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadFlowUnitsRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppFlowUnits);
	let flowUnits = await apiService.getResource<ApiTypes.IppSampleFlowUnit[]>(url);

	dispatch(loadFlowUnitsSuccess(flowUnits));
};

export const loadSample = (id: number): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadSampleRequest());
	let url = urlService.getAuthorityResourceApiUrl(Resource.IppIndustrySamples, id);
	let sample = await apiService.getResource<ApiTypes.IppIndustrySample>(url);

	dispatch(loadSampleSuccess(sample));
};

export const setSampleData = (
	sample: IppIndustrySample,
	createSample?: boolean,
	cb?: (newSample: IppIndustrySample) => void,
	reportPackageElementTypeId?: number
): SampleThunkAction => async (dispatch, getState) => {
	dispatch(setSampleDataRequest());

	if (createSample) {
		let url =
			urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples) +
			(!!reportPackageElementTypeId ? `?reportPackageElementTypeId=${reportPackageElementTypeId}` : '');
		sample.startDateTimeLocal = sample.startDateTimeLocal || '';
		sample.endDateTimeLocal = sample.endDateTimeLocal || '';
		delete sample.sampleId;
		let ippSampleResults =
			sample.ippSampleResults &&
			sample.ippSampleResults.map(result => {
				delete result.massLoadingSampleResultId;
				delete result.concentrationSampleResultId;
				result.massLoadingQualifier = result.qualifier;
				result.analysisDateTimeLocal =
					result.analysisDateTimeLocal && DateUtilService.isValidDateTime(result.analysisDateTimeLocal)
						? result.analysisDateTimeLocal
						: undefined;
				return result;
			});
		sample.ippSampleResults = ippSampleResults;
		let newSample = await apiService.httpPost(url, sample);
		alertService.clearAllMessages();
		alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.step2.createMessage'));

		cb && cb(newSample[0]);
	}
	dispatch(setSampleDataSuccess(sample));
};

export const setSelectedParametersData = (parameters: IppReportPackageParameter[]): SampleThunkAction => async (
	dispatch,
	getState
) => {
	dispatch(loadSelectedParametersRequest());
	dispatch(loadSelectedParametersSuccess(parameters));
};

export const loadParameterGroups = (
	sample?: ApiTypes.IppIndustrySample,
	clearPrev?: boolean
): SampleThunkAction => async (dispatch, getState) => {
	if (clearPrev) {
		dispatch(loadParameterGroupsRequest());
		dispatch(loadParameterGroupsSuccess([]));
	}

	if (sample) {
		const url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleParameterGroups);
		dispatch(loadParameterGroupsRequest());
		let postData = {
			monitoringPointId: sample.monitoringPointId,
			startDateTime: sample.startDateTimeLocal,
			endDateTime: sample.endDateTimeLocal,
			collectionMethodId: sample.collectionMethodId
		};
		if (
			postData.monitoringPointId &&
			postData.startDateTime &&
			postData.endDateTime &&
			postData.collectionMethodId &&
			sample.sampleStatusName !== SampleStatus.Reported &&
			sample.sampleStatusName !== SampleStatus.ReadyToReport
		) {
			let parameterGroups = await apiService.httpPost(url, postData);
			dispatch(loadParameterGroupsSuccess(parameterGroups));
		}
	}
	return true;
};

export const loadFloatNumbers = (
	floatData: any,
	cb: (floatNumbers: ApiTypes.IppSampleFloatNumbers) => {}
): SampleThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleFloatNumbers);
	dispatch(loadFloatNumbersRequest());

	let floatNumbers = await apiService.httpPost(url, floatData);
	cb(floatNumbers);
	dispatch(loadFloatNumbersSuccess(floatNumbers));
	return true;
};

export const loadSettings = (): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadProgramSettingsRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppProgramSettings);
	let settings = await apiService.getResource<ApiTypes.IppProgramSettings>(url);

	dispatch(loadProgramSettingsSuccess(settings));
};

export const loadMassLoadingUnit = (): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadMassLoadingUnitRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppMassLoadingUnit);
	let massLoadingUnit = await apiService.getResource<ApiTypes.IppMassLoadingUnit>(url);

	dispatch(loadMassLoadingUnitSuccess(massLoadingUnit));
};

export const loadParameters = (querystring = ''): SampleThunkAction => async (dispatch, getState) => {
	dispatch(loadParametersRequest());
	let url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleParameters, querystring);
	let parameters = await apiService.getResource<ApiTypes.IppReportPackageParameter[]>(url);

	dispatch(loadParametersSuccess(parameters));
};

export const deleteSample = (sampleId: number, sourceReportPackageId?: number): SampleThunkAction => async (
	dispatch,
	getState
) => {
	const url =
		urlService.getAuthorityResourceApiUrl(Resource.IppIndustrySamples, sampleId) +
		(!!sourceReportPackageId ? `?currentReportPackageId=${sourceReportPackageId}` : '');
	await apiService.httpDelete(url);

	alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.step2.deleteMessage'));

	return true;
};

export const updateSample = (
	sample: IppIndustrySample,
	shouldLoadUpdatedSample: boolean = true,
	sourceReportPackageId?: number
): SampleThunkAction => async (dispatch, getState) => {
	const url =
		urlService.getAuthorityResourceApiUrl(Resource.IppIndustrySamples, sample.sampleId as number) +
		(!!sourceReportPackageId ? `?currentReportPackageId=${sourceReportPackageId}` : '');

	dispatch(updateSampleRequest());
	sample.startDateTimeLocal = sample.startDateTimeLocal || '';
	sample.endDateTimeLocal = sample.endDateTimeLocal || '';
	let ippSampleResults =
		sample.ippSampleResults &&
		sample.ippSampleResults.map(result => {
			result.massLoadingQualifier = result.qualifier;
			result.analysisDateTimeLocal =
				result.analysisDateTimeLocal && DateUtilService.isValidDateTime(result.analysisDateTimeLocal)
					? result.analysisDateTimeLocal
					: undefined;
			return result;
		});
	sample.ippSampleResults = ippSampleResults;
	sample = await apiService.httpPut(url, sample);
	alertService.clearAllMessages();
	if (sample.validationResult) {
		alertService.addError(sample.validationResult.errorMessage || '');
		return sample.validationResult;
	} else {
		alertService.addSuccess(localizationService.getLocalizedString('ipp.samples.step2.updateMessage'));
		shouldLoadUpdatedSample && dispatch(loadSample(sample.sampleId as number));
		return true;
	}
};
