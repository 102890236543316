import React, { useState } from 'react';
import { TabViewField } from 'src/components/widgets';
import { localizationService, UtilService } from 'src/services';
import { CccHazard } from '@rcp/types';
import { CccHazardDeviceModal } from './hazard-device-modal';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { cccDeviceSlice } from 'src/components/authority/backflow/settings/ccc-device-types/devices.slice';

interface OwnProps {
	currentCccHazard: CccHazard;
}

type Props = OwnProps;

export const CccHazardDeviceTab: React.SFC<Props> = props => {
	const dispatch = useDispatch();
	const [isToggle, setIsToggle] = useState(false);

	React.useEffect(() => {
		dispatch(
			cccDeviceSlice.fetchAll(
				`size=2147483647&includes=deviceId,model,deviceTypeId,deviceTypeCode,manufacturer,deviceSize,testFrequencyId`
			)
		);
	}, [dispatch]);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	let doesDeviceEexist =
		(props.currentCccHazard.deviceId && props.currentCccHazard.deviceId > 0) ||
		!_.isEmpty(props.currentCccHazard.deviceTestable) ||
		!_.isEmpty(props.currentCccHazard.deviceLeadFree) ||
		!_.isEmpty(props.currentCccHazard.deviceSerialNumber) ||
		!_.isEmpty(props.currentCccHazard.deviceStatus) ||
		!_.isEmpty(props.currentCccHazard.deviceOrientation) ||
		!_.isEmpty(props.currentCccHazard.deviceLocation) ||
		!_.isEmpty(props.currentCccHazard.deviceBypass) ||
		!_.isEmpty(props.currentCccHazard.fireline) ||
		(props.currentCccHazard.testFrequencyId && props.currentCccHazard.testFrequencyId < 0) ||
		!_.isEmpty(props.currentCccHazard.installDue) ||
		!_.isEmpty(props.currentCccHazard.installedDate);

	if (!doesDeviceEexist) {
		return (
			<>
				<div className="d-flex flex-row">
					<a href="#/" className="ai-add-link" onClick={onEditClicked}>
						{localizationService.getLocalizedString('cccDevice.addDevice')}&#8230;
					</a>
				</div>
				{isToggle && <CccHazardDeviceModal toggle={toggle} hazard={props.currentCccHazard} isAdd={true} />}
			</>
		);
	}

	return (
		<>
			<div className="d-flex flex-row">
				<div className="form-row">
					<TabViewField
						title={localizationService.getLocalizedString('cccDevice.manufacturer')}
						value={UtilService.formatValue(props.currentCccHazard.deviceManufacturer)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccDevice.model')}
						value={UtilService.formatValue(props.currentCccHazard.deviceModel)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccDevice.deviceTypeCode')}
						value={UtilService.formatValue(props.currentCccHazard.deviceType)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccDevice.deviceSize')}
						value={UtilService.formatValue(props.currentCccHazard.deviceSize)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceTestable')}
						value={UtilService.formatBoolean(props.currentCccHazard.deviceTestable)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceLeadFree')}
						value={UtilService.formatBoolean(props.currentCccHazard.deviceLeadFree)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceSerialNumber')}
						value={UtilService.formatValue(props.currentCccHazard.deviceSerialNumber)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceStatus')}
						value={UtilService.formatValue(props.currentCccHazard.deviceStatus)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceOrientation')}
						value={UtilService.formatValue(props.currentCccHazard.deviceOrientation)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceLocation')}
						value={UtilService.formatValue(props.currentCccHazard.deviceLocation)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.deviceBypass')}
						value={UtilService.formatBoolean(props.currentCccHazard.deviceBypass)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.fireline')}
						value={UtilService.formatBoolean(props.currentCccHazard.fireline)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.testFrequency')}
						value={UtilService.formatValue(props.currentCccHazard.testFrequencyCode)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.installDue')}
						value={UtilService.formatDateTime(props.currentCccHazard.installDue)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccHazard.installedDate')}
						value={UtilService.formatDateTime(props.currentCccHazard.installedDate)}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
			{isToggle && <CccHazardDeviceModal toggle={toggle} hazard={props.currentCccHazard} isAdd={false} />}
		</>
	);
};
