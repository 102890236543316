import React, { FC, useEffect, useState } from 'react';
import { TextAreaInput, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { IppConstants } from 'src/constants';
import { DateUtilService, localizationService, validationService } from 'src/services';

interface Props {
	onSubmit?: (comments: string) => void;
	className?: string;
	repudiationReviewDateTimeLocal?: string;
	repudiationReviewerFirstName?: string;
	repudiationReviewerLastName?: string;
	repudiationReviewerTitleRole?: string;
	repudiationReviewComments?: string;
}

const { fieldCharLimit } = IppConstants;

const IppPackageRepudiationReview: FC<Props> = (props: Props) => {
	const [comments, setComments] = useState('');
	const [commentsError, setCommentsError] = useState('');

	useEffect(() => {
		setComments(props.repudiationReviewComments ? props.repudiationReviewComments : '');
	}, [props]);

	const onChangeComments = (event: any) => {
		setComments(event.target.value);
	};

	const submit = () => {
		let isFormValid = false;
		let validatePayload = { comments, commentsError };
		isFormValid = validationService.validateRequiredField(validatePayload, 'comments', 'commentsError');
		setCommentsError(validatePayload.commentsError);
		if (isFormValid) {
			props.onSubmit && props.onSubmit(comments);
		}
	};

	const getLastReviewedBy = () => {
		return `${props.repudiationReviewerFirstName} ${props.repudiationReviewerLastName}`;
	};

	const getReviewDate = () => {
		return `${DateUtilService.toDisplayDate(props.repudiationReviewDateTimeLocal)} ${DateUtilService.toDisplayTime(
			props.repudiationReviewDateTimeLocal
		).toLocaleUpperCase()}`;
	};
	return (
		<>
			<CollapsibleCard
				className={props.className ? props.className : ''}
				accordionType="repudiation-review"
				accordionHeading={localizationService.getLocalizedString(
					'ipp.reportPackage.package.repudiationReview'
				)}>
				<div className="w-75 mx-auto">
					{props.repudiationReviewDateTimeLocal && (
						<div className="form-row">
							<TextInput
								id="last-reviewed-by"
								name="lastReviewedBy"
								className="col-md-5"
								isDisabled={true}
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.lastReviewedBy'
								)}
								value={getLastReviewedBy()}
							/>
							<div className="col-md-1" />
							<TextInput
								id="review-date"
								name="reviewDate"
								className="pl-1"
								label={localizationService.getLocalizedString(
									'ipp.reportPackage.package.columns.repudiationDateTimeLocal'
								)}
								isDisabled={true}
								value={getReviewDate()}
							/>
						</div>
					)}

					<div className="form-row">
						<TextAreaInput
							id="review-comment"
							name="repudiationReviewComment"
							className="col-md-5 form-group"
							isRequired={true}
							isDisabled={props.repudiationReviewDateTimeLocal ? true : false}
							value={comments}
							label={localizationService.getLocalizedString('ipp.reportPackage.package.comments')}
							onChange={onChangeComments}
							maxLength={fieldCharLimit.commentsAndDescription}
							error={commentsError}
						/>
					</div>
				</div>
				<div className="form-row">
					{!props.repudiationReviewDateTimeLocal && (
						<button
							className="btn ai-action ml-auto mr-2"
							id="repudiation-review-complete-btn"
							onClick={() => submit()}>
							{localizationService.getLocalizedString(
								'ipp.reportPackage.package.repudiationReviewComplete'
							)}
						</button>
					)}
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageRepudiationReview;
