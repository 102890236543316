import React from 'react';

export interface InlineEditProps {
	id: string;
	name: string;
	label: string;
	isRequired?: boolean;
	isDisabled?: boolean;
	value?: string;
	placeholder?: string;
	autoComplete?: string;
	piggyback?: string;
	error?: string;
	helpText?: string;
	onChange?(text: string, field?: string): void;
	onBlur?(text: string, field?: string): void;
	onEnterKeyPressed?(text: string, field?: string): void;
	type?: string;
	className?: string;
}
interface Props {
	rowId: string;
	inlineEditProps: InlineEditProps[];
}

export const InlineChildTextInput: React.SFC<Props> = props => {
	const changeValue = (e: any, editProp: InlineEditProps) => {
		if (editProp.onChange) {
			editProp.onChange(e);
		}
	};

	const handleKeyPressed = (e: any, editProp: InlineEditProps) => {
		if (e.key === 'Enter') {
			changeValue(e, editProp);
			if (editProp.onEnterKeyPressed) {
				editProp.onEnterKeyPressed(e);
			}
		}
		if (e.key === 'Escape') {
			e.target.value = editProp.value || '';
			e.target.blur();
		}
	};

	const handleOnBlur = (e: any, editProp: InlineEditProps) => {
		changeValue(e, editProp);
		if (editProp.onBlur) {
			editProp.onBlur(e);
		}
	};

	const handleChange = (e: any, editProp: InlineEditProps) => {
		changeValue(e, editProp);
	};

	const getInput = (editProp: InlineEditProps) => {
		return (
			<>
				<input
					id={editProp.id}
					name={editProp.name}
					data-piggyback={editProp.piggyback || editProp.label}
					type={editProp.type ? editProp.type : 'text'}
					className={'form-control value ' + (editProp.error ? ' is-invalid' : '')}
					value={editProp.value || ''}
					placeholder={editProp.placeholder}
					onChange={e => handleChange(e, editProp)}
					onBlur={e => handleOnBlur(e, editProp)}
					onKeyPress={e => handleKeyPressed(e, editProp)}
					required={editProp.isRequired === true ? true : false}
					disabled={editProp.isDisabled === true ? true : false}
					autoComplete={editProp.autoComplete ? editProp.autoComplete : 'new-password'}
				/>
				{editProp.error && <div className="invalid-feedback">{editProp.error}</div>}
				{!editProp.error && editProp.helpText && <div className="ai-form-help"> {editProp.helpText}</div>}
			</>
		);
	};

	let getInlineInputLayout = () => {
		return (
			<>
				{props.inlineEditProps.map((editProp: InlineEditProps, index: number) => {
					let rowClass = editProp.className ? editProp.className : '';

					if (index < props.inlineEditProps.length - 1) {
						return (
							<div
								key={`inline${index}`}
								className={
									'flex-grow-1 ' +
									rowClass +
									(editProp.isRequired ? ' ai-required-input required' : '')
								}>
								<label htmlFor={editProp.id}>{editProp.label}</label>
								{getInput(editProp)}
							</div>
						);
					} else {
						return (
							<>
								<div
									key={`inline${index}`}
									className={
										'flex-grow-1 ' +
										rowClass +
										(editProp.isRequired ? ' ai-required-input required' : '')
									}>
									<label htmlFor={editProp.id}>{editProp.label}</label>
									{getInput(editProp)}
								</div>
								<div className="ml-sm-1 ai-inline-button">{props.children}</div>
							</>
						);
					}
				})}
			</>
		);
	};

	return getInlineInputLayout();
};
