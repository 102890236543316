import React from 'react';
import { urlService, apiService, localizationService } from 'src/services';
import * as LinkoTypes from '@rcp/types';
import _ from 'lodash';

const TermsAndConditionComponent: React.FunctionComponent = () => {
	const [termCondition, setTermCondition] = React.useState<LinkoTypes.TermCondition>();

	React.useEffect(() => {
		let url = `${urlService.getApiBaseUrl()}/TermCondition`;

		apiService.getResource(url).then((response: any) => {
			setTermCondition(response);
		});
	}, []);

	return (
		<div className="page">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('screen.labels.termsAndConditions')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section>
						{termCondition && (
							<span
								id={`termConditionContent${_.toString(termCondition.termConditionId)}`}
								dangerouslySetInnerHTML={{ __html: termCondition.content }}
							/>
						)}
					</section>
				</div>
			</div>
		</div>
	);
};

export const TermsAndCondition = TermsAndConditionComponent;
