import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Lookup } from '@rcp/types';
import { apiService, Resource, urlService } from 'src/services';

export interface InspectionMaintenanceResultsState {
	inspectionMaintenanceResults: Lookup[];
}

export const initialInspectionMaintenanceResultsState: InspectionMaintenanceResultsState = {
	inspectionMaintenanceResults: []
};

enum ActionType {
	LoadInspectionMaintenanceResultsSuccess = 'loadInspectionMaintenanceResultsSuccess'
}

export type AuthorityMaintenanceResultsThunkAction = ThunkAction<any, InspectionMaintenanceResultsState, any, Action>;

export const loadInspectionMaintenanceResults = (): AuthorityMaintenanceResultsThunkAction => async (dispatch: any) => {
	let url = urlService.getAuthorityLookupResourceApiUrl(Resource.InspectionMaintenanceLogs);

	let inspectionMaintenanceResults = await apiService.getResource<Lookup[]>(url);
	dispatch({ type: ActionType.LoadInspectionMaintenanceResultsSuccess, inspectionMaintenanceResults });
};

export const inspectionMaintenanceResultsReducer = (
	state = initialInspectionMaintenanceResultsState,
	action: any
): InspectionMaintenanceResultsState => {
	switch (action.type) {
		case ActionType.LoadInspectionMaintenanceResultsSuccess:
			return { ...state, inspectionMaintenanceResults: action.inspectionMaintenanceResults };
		default:
			return state;
	}
};
