import { ThunkAction } from 'redux-thunk';
import { SimpleUserProfile } from '@rcp/types';
import { apiService, urlService, tokenService } from 'src/services';
import * as User from './user-actions';

type UserProfileThunkAction = ThunkAction<any, User.UserProfileState, any, User.UserProfileAction>;

export const loadUserProfile = (): UserProfileThunkAction => async (dispatch, getState) => {
	if (!tokenService.isTokenValid()) {
		return;
	}

	dispatch(User.loadUserProfileRequest());

	const url = urlService.getUserProfileUrl();
	const simpleUserProfile = await apiService.getResource<SimpleUserProfile>(url);

	dispatch(User.loadUserProfileSuccess(simpleUserProfile));
};
