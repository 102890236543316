import { ApiError, ApiSignalTimeoutOneMin, DataImport } from '@rcp/types';
import _ from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteModal, InlineInfoSvg, InlineWarningSvg, PopoverModal } from 'src/components';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState } from 'src/redux';
import { apiService, localizationService, QueryParameters, Resource, urlService } from 'src/services';
import { initialSelfImportStep3Data, SelfImportStep3Data } from './step3-map-configuration';
import { closeSideEditor, getImportDomainObjectLabel, SelfImportSteps } from './self-import-service';

export interface SelfImportStep4Data extends SelfImportStep3Data, DataImport.FogImportSummary {
	confirmImportModal: boolean;
	confirmImportModalTitle: string;
	confirmImportModalMessage: string;
}

export const initialSelfImportStep4Data: SelfImportStep4Data = {
	...initialSelfImportStep3Data,
	isDryRun: true,
	fileName: '',
	importId: 0,
	importFileUrl: '',
	errorFileUrl: '',
	successRowNumber: 0,
	errorRowNumber: 0,
	totalRowNumber: 0,
	importHistories: [],
	confirmImportModal: false,
	confirmImportModalTitle: '',
	confirmImportModalMessage: ''
};

interface Props {}

export const SelfImportStep4: React.FC<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const step3Data: SelfImportStep3Data = stepperContext.getData(SelfImportSteps.STEP3);
	const [state, setState] = React.useState<SelfImportStep4Data>(initialSelfImportStep4Data);
	const [clickedImport, setClickedImport] = React.useState(false);
	const [isPreviewApiRunning, setIsPreviewApiRunning] = React.useState(false);
	const [isPreviewAborted, setIsPreviewAborted] = React.useState(false);

	const selfImportState = useSelector((state: RootState) => state.selfImport).current;
	const dispatch = useDispatch();

	React.useEffect(() => {
		//user can directly click step 4 title and step 3 by title when both steps are resolved
		closeSideEditor(selfImportState, dispatch);

		const step4Data: SelfImportStep4Data = stepperContext.getData(SelfImportSteps.STEP4);
		let newState = { ...state };
		if (step4Data) {
			newState = { ...step4Data };
			urlService.replaceUrlQueryString({
				step: '4'
			});
		} else {
			newState = { ...state, ...step3Data };
			let step = urlService.getUrlQueryParameter('step') as string;
			if (_.toNumber(step) < 4) {
				step = '4';
			}
			urlService.replaceUrlQueryString({
				step: step
			});
		}
		setState(newState);
		if (!isPreviewApiRunning) {
			doDryRun(newState);
		}
	}, []);

	React.useEffect(() => {
		if (clickedImport === true) {
			setClickedImport(false);
			importFile();
		}
	}, [clickedImport]);

	const doDryRun = async (currentState: SelfImportStep4Data) => {
		setIsPreviewApiRunning(true);
		let settingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		let queryParams = new QueryParameters()
			.add('importConfigurationId', currentState.importConfigurationId)
			.add('dryRun', 'true');
		let previewUrl = `${settingUrl}/Imports/${currentState.importDataType}/${
			currentState.importFileId
		}?${queryParams.toQueryString()}`;

		return apiService
			.httpPost(previewUrl, {}, undefined, undefined, undefined, ApiSignalTimeoutOneMin)
			.then((response: DataImport.FogImportSummary) => {
				setState({ ...currentState, ...response });
			})
			.catch((error: any) => {
				if (error.name === 'AbortError') {
					setIsPreviewAborted(true);
				} else {
					alertService.addError(error.message);
					if (error.statusCode === 400) {
						let step4State = { ...stepperContext.getStep(SelfImportSteps.STEP4), loading: false };
						stepperContext.updateStep(SelfImportSteps.STEP4, step4State);
					}
				}
			})
			.finally(() => {
				setIsPreviewApiRunning(false);
			});
	};

	const importFile = async () => {
		let stepState = { ...stepperContext.getStep(SelfImportSteps.STEP4), loading: false };
		stepperContext.updateStep(SelfImportSteps.STEP4, stepState);

		let settingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		let queryParams = new QueryParameters()
			.add('importConfigurationId', state.importConfigurationId)
			.add('dryRun', 'false');
		let importUrl = `${settingUrl}/Imports/${state.importDataType}/${
			state.importFileId
		}?${queryParams.toQueryString()}`;

		let apiResponse = apiService.httpPost(importUrl, {}, undefined, undefined, undefined, ApiSignalTimeoutOneMin);

		const totalRowNumber = _.get(step3Data, 'totalRowNumber') as number;
		if (totalRowNumber >= 100) {
			stepperContext.resolve('');
			return;
		}

		return apiResponse
			.then((response: DataImport.FogImportSummary) => {
				//remove query parameters to avoid the user
				urlService.removeUrlQueryParam('dataType', 'importFileId', 'configurationId', 'step');
				stepperContext.resolve({ ...response });
			})
			.catch((error: any) => {
				if (error.name === 'AbortError') {
					// no need to show error on UI.
				} else {
					alertService.addError(error.message);
					if (error.statusCode === 400) {
						let step3State = { ...stepperContext.getStep(SelfImportSteps.STEP4), loading: false };
						stepperContext.updateStep(SelfImportSteps.STEP4, step3State);
					}
				}
			});
	};

	const onClickNext = (event: React.FormEvent) => {
		if (step3Data.isFileImportedBefore === true && step3Data.fileName) {
			//the file with same filename and content has been imported before.
			setState({
				...state,
				confirmImportModal: true,
				confirmImportModalTitle: localizationService.getLocalizedString('cccImportFile.fileImportedTitle'),
				confirmImportModalMessage: localizationService.getLocalizedString(
					'cccImportFile.fileImportedBefore',
					step3Data.fileName
				)
			});
		} else {
			setClickedImport(true);
		}
	};

	const importObjectName = _.toLower(getImportDomainObjectLabel(state.importDataType));
	return (
		<StepperContent
			id={SelfImportSteps.STEP4}
			actions={
				<React.Fragment>
					<StepperAction
						type="button"
						className="btn btn-link"
						id="btnBack"
						onClick={() => {
							urlService.replaceUrlQueryString({ step: '3' });
							stepperContext.goAt(SelfImportSteps.STEP3);
						}}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction
						type="button"
						id="btnNext"
						className="btn ai-action"
						disabled={
							!isPreviewAborted &&
							(clickedImport === true || _.toNumber(state.successRowNumber) < 1 || isPreviewApiRunning)
						}
						onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.import')}
					</StepperAction>
				</React.Fragment>
			}>
			{isPreviewApiRunning && (
				<div className="alert ai-inline-alert alert-information">
					<span className="mr-2">
						<InlineInfoSvg />
					</span>
					<span>{localizationService.getLocalizedString('import.steps.previewRunningMessage')}</span>
				</div>
			)}
			{isPreviewAborted && (
				<div className="alert ai-inline-alert alert-information">
					<span className="mr-2">
						<InlineInfoSvg />
					</span>
					<span>
						{localizationService.getLocalizedString('import.steps.previewAbortedMessageLine1')}
						<br />
						<br />
						{localizationService.getLocalizedString('import.steps.previewAbortedMessageLine2')}
					</span>
				</div>
			)}
			{state.successRowNumber > 0 && (
				<div className="alert ai-inline-alert alert-information">
					<span className="mr-2">
						<InlineInfoSvg />
					</span>
					<span>
						{localizationService.getLocalizedString(
							'import.steps.previewSuccessMessage',
							_.toString(state.successRowNumber),
							importObjectName
						)}
					</span>
				</div>
			)}
			{state.errorRowNumber > 0 && (
				<div className="alert ai-inline-alert alert-warning">
					<span className="mr-2">
						<InlineWarningSvg />
					</span>
					<span>
						{localizationService.getLocalizedString(
							'import.steps.previewErrorMessage',
							_.toString(state.errorRowNumber)
						)}
						&nbsp;
						{state.errorFileUrl && (
							<>
								<a href={state.errorFileUrl} target="_blank">
									{localizationService.getLocalizedString('import.steps.previewErrorLinkText')}
								</a>
								{localizationService.getLocalizedString('import.steps.previewErrorLinkFollowingText')}
							</>
						)}
					</span>
				</div>
			)}
			{state.confirmImportModal && (
				<PopoverModal
					title={state.confirmImportModalTitle}
					showModal={true}
					withoutForm={true}
					className="same-file-import-confirmation"
					cancel={() => {
						setState({
							...state,
							confirmImportModal: false,
							confirmImportModalTitle: '',
							confirmImportModalMessage: ''
						});
					}}
					saveButtonClassName="btn ai-action confirm-import"
					saveButtonText={localizationService.getLocalizedString('screen.buttons.importAgain')}
					save={() => {
						setState({
							...state,
							confirmImportModal: false,
							confirmImportModalTitle: '',
							confirmImportModalMessage: ''
						});
						setClickedImport(true);
					}}>
					<p>{state.confirmImportModalMessage}</p>
				</PopoverModal>
			)}
		</StepperContent>
	);
};
