import { IppIndustry, IppReportPackageParameter, IppReportPackageParameterGroup } from '@rcp/types';

export interface IppReportPackageParameterGroupState {
	parameterGroupDetails: IppReportPackageParameterGroup;
	availableParameters: IppReportPackageParameter[];
	availableParameterGroupAssignments: IppIndustry[];
}
export const initialReportPackageParameterGroupState: IppReportPackageParameterGroupState = {
	availableParameters: [],
	availableParameterGroupAssignments: [],
	parameterGroupDetails: {}
};
