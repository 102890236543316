import React from 'react';
import * as ApiTypes from '@rcp/types';
import { DateUtilService, localizationService, Resource, urlService } from 'src/services';
import { CccTestDto } from '@rcp/types';
import moment from 'moment';
import { TestDetailModal } from 'src/components/authority/backflow/hazards/tests/hazard-test-detail-modal';

interface OwnProps {
	test: ApiTypes.CccTestDto;
}

type Props = OwnProps;

export const TimelineEventTest: React.SFC<Props> = props => {
	const [testDetailsToggle, setTestDetailsToggle] = React.useState(false);

	const editTestDetails = (e?: any) => {
		if (e) e.preventDefault();
		setTestDetailsToggle(!testDetailsToggle);
	};

	const getHazardNumberSpan = (test: CccTestDto) => {
		if (urlService.getDomainObjectName() !== Resource.CccHazards)
			return (
				<span>
					{localizationService.getLocalizedString('screen.timeline.for')}&nbsp;
					<a href={urlService.getReactAuthorityResourceUrl(Resource.CccHazards, test.hazardId)}>
						{localizationService.getLocalizedString('cccHazard.hazard')} {test.entityNumber}
					</a>
					&nbsp;
				</span>
			);
		return <span />;
	};

	const setupIncompleteEvent = (test: CccTestDto) => {
		let forHazard = getHazardNumberSpan(test);
		if (DateUtilService.isAfterToday(test.testDueDate as string))
			return (
				<div className="timelineEventTest">
					<a href="#/" onClick={editTestDetails}>
						{localizationService.getLocalizedString('cccTest.test')}
					</a>
					&nbsp;
					{forHazard}
					{localizationService.getLocalizedString('screen.timeline.due')}.
				</div>
			);
		if (DateUtilService.isBeforeToday(test.testDueDate as string)) {
			let currentDateTime = DateUtilService.getAuthorityTimezoneNow();
			const daysBefore = moment(currentDateTime).diff(test.testDueDate, 'days');
			return (
				<div className="timelineEventTest">
					<a href="#/" onClick={editTestDetails}>
						{localizationService.getLocalizedString('cccTest.test')}
					</a>
					&nbsp;
					{forHazard}
					{localizationService.getLocalizedString('screen.timeline.is')} {daysBefore}&nbsp;
					{localizationService.getLocalizedString('screen.timeline.daysPastDue')}.
				</div>
			);
		}

		return (
			<div className="timelineEventTest">
				<a href="#/" onClick={editTestDetails}>
					{localizationService.getLocalizedString('cccTest.test')}
				</a>
				&nbsp;
				{forHazard}
				{localizationService.getLocalizedString('screen.timeline.dueToday')}.
			</div>
		);
	};

	const setupCompleteEvents = (test: CccTestDto) => {
		const testResult =
			test.initialPass === 'Pass' ? 'passed' : test.initialPass === 'Fail' ? 'failed' : 'completed';
		let forHazard = getHazardNumberSpan(test);

		if (!test.testDueDate) {
			return (
				<div className="timelineEventTest">
					<a href="#/" onClick={editTestDetails}>
						{localizationService.getLocalizedString('cccTest.test')}
					</a>
					&nbsp;
					{forHazard}
					{testResult}.
				</div>
			);
		}
		const daysBefore = moment(test.testDueDate).diff(test.initialTestDate, 'days');

		if (daysBefore > 0) {
			return (
				<div className="timelineEventTest">
					<a href="#/" onClick={editTestDetails}>
						{localizationService.getLocalizedString('cccTest.test')}
					</a>
					&nbsp;
					{forHazard}
					{testResult}. {localizationService.getLocalizedString('screen.timeline.completed')}
					&nbsp;
					{daysBefore} {localizationService.getLocalizedString('screen.timeline.daysEarly')}.
				</div>
			);
		}
		if (daysBefore < 0) {
			return (
				<div className="timelineEventTest">
					<a href="#/" onClick={editTestDetails}>
						{localizationService.getLocalizedString('cccTest.test')}
					</a>
					&nbsp;
					{forHazard}
					{testResult}. {localizationService.getLocalizedString('screen.timeline.completed')}
					&nbsp;
					{Math.abs(daysBefore)} {localizationService.getLocalizedString('screen.timeline.dayslate')}.
				</div>
			);
		}

		return (
			<div className="timelineEventTest">
				<a href="#/" onClick={editTestDetails}>
					{localizationService.getLocalizedString('cccTest.test')}
				</a>
				&nbsp;
				{forHazard}
				{testResult}.
			</div>
		);
	};
	return (
		<>
			{!props.test.initialTestDate && setupIncompleteEvent(props.test)}
			{props.test.initialTestDate && setupCompleteEvents(props.test)}
			{testDetailsToggle && (
				<TestDetailModal
					isToggle={testDetailsToggle}
					toggle={editTestDetails}
					currentTest={props.test}
					dueDateRequired={props.test.initialTestDate ? false : true}
					completedResultRequired={props.test.initialTestDate ? true : false}
					modalTitle={undefined}
					isScheduledTest={false}
				/>
			)}
		</>
	);
};
