import { LookupType, SubLookupType } from '@rcp/types';
import { urlService, Resource } from 'src/services';

export interface SettingUrls {
	getUrl: string;
	addUrl: string;
	updateUrl: (idToUpdate?: Number | string) => string;
	deleteUrl: (idToDelete?: Number | string) => string;
	getInUseUrl?: string;
}

export interface ISettingUrlResolver {
	getSettingUrls: () => SettingUrls;
}

export class CustomizedSettingUrlResolver implements ISettingUrlResolver {
	private url: string;
	private queryString?: string;

	constructor(url: string, queryString?: string) {
		this.url = url;
		this.queryString = queryString;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.queryString ? `${this.url}?${this.queryString}` : this.url,
			addUrl: this.url,
			updateUrl: (idToUpdate?: Number | string) => {
				return `${this.url}/${idToUpdate}` + (this.queryString ? `?${this.queryString}` : '');
			},
			deleteUrl: (idToDelete?: Number | string) => {
				return `${this.url}/${idToDelete}` + (this.queryString ? `?${this.queryString}` : '');
			}
		} as SettingUrls;
	};
}

export class SettingUrlResolver implements ISettingUrlResolver {
	private lookupType: LookupType;

	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(this.lookupType),
			addUrl: this.getAddSettingUrl(this.lookupType),
			updateUrl: (idToUpdate: number) => this.getDeleteSettingUrl(idToUpdate),
			deleteUrl: (idToDelete: number) => this.getDeleteSettingUrl(idToDelete)
		} as SettingUrls;
	};

	private getRetrieveSettingUrl = (lookupType: LookupType): string => {
		let lookupTypeUrlKey = this.lookupTypeUrlKeyString();
		const url = urlService.getAuthorityLookupResourceApiUrl(lookupTypeUrlKey);
		return `${url}?includeInactive=true&includeIsInUse=true`;
	};

	private getDeleteSettingUrl = (idToDelete: number | string): string => {
		let lookupTypeUrlKey = this.lookupTypeUrlKeyString();
		const url = `${urlService.getApiBaseUrlWithProgram()}/${
			Resource.Settings
		}/lookups/${lookupTypeUrlKey}/${idToDelete}?includeInactive=true&includeIsInUse=true`;

		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		let lookupTypeUrlKey = this.lookupTypeUrlKeyString();
		const url = `${urlService.getApiBaseUrlWithProgram()}/${
			Resource.Settings
		}/lookups/${lookupTypeUrlKey}/${idToUpdate}?includeInactive=true&includeIsInUse=true`;

		return url;
	};

	private getAddSettingUrl = (lookupType: LookupType): string => {
		var defaultUrl = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/lookups`;
		if (this.lookupType === LookupType.EquipmentType) {
			return `${defaultUrl}/equipmentTypes`;
		} else if (this.lookupType === LookupType.ViolationTypes) {
			return `${defaultUrl}/ViolationTypes`;
		} else if (this.lookupType === LookupType.EnforcementTypes) {
			return `${defaultUrl}/EnforcementTypes`;
		} else if (this.lookupType === LookupType.CorrectiveActions) {
			return `${defaultUrl}/CorrectiveActions`;
		} else if (this.lookupType === LookupType.WasteType) {
			return `${defaultUrl}/WasteTypes`;
		}
		return defaultUrl;
	};

	private lookupTypeUrlKeyString = () => {
		if (this.lookupType === LookupType.ContactType) {
			return 'ContactTypes';
		}
		if (this.lookupType === LookupType.MapCategory) {
			return Resource.MapCategories;
		}
		if (this.lookupType === LookupType.ReceivingPlant) {
			return Resource.ReceivingPlants;
		}
		return this.lookupType;
	};
}

export class EventTypeSettingUrlResolver implements ISettingUrlResolver {
	private subLookupType: SubLookupType;
	private lookupType: LookupType = LookupType.EventType;
	constructor(subLookupType: SubLookupType) {
		this.subLookupType = subLookupType;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(),
			addUrl: this.getAddSettingUrl(),
			updateUrl: (idToUpdate: number) => this.getUpdateSettingUrl(idToUpdate),
			deleteUrl: (idToDelete: number) => this.getDeleteInspectionTypeSettingUrl(idToDelete)
		} as SettingUrls;
	};

	private getRetrieveSettingUrl = (): string => {
		const url = urlService.getAuthorityLookupResourceApiUrl(this.lookupType);
		return `${url}?eventCategory=${this.subLookupType}&includeInactive=true&includeIsInUse=true`;
	};

	private getDeleteInspectionTypeSettingUrl = (idToDelete: number | string): string => {
		let url = urlService.getAuthorityLookupResourceApiUrl(this.lookupType);
		url = `${url}/${idToDelete}?eventCategory=${this.subLookupType}&includeInactive=true&includeIsInUse=true`;
		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		let url = urlService.getAuthorityLookupResourceApiUrl(this.lookupType);
		url = `${url}/${idToUpdate}?eventCategory=${this.subLookupType}&includeInactive=true&includeIsInUse=true`;
		return url;
	};

	private getAddSettingUrl = (): string => {
		const url = urlService.getAuthorityLookupResourceApiUrl(this.lookupType);
		return `${url}?eventCategory=${this.subLookupType}&includeInactive=true&includeIsInUse=true`;
	};
}

export class TagSettingUrlResolver implements ISettingUrlResolver {
	private lookupType: LookupType;

	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(this.lookupType),
			addUrl: this.getAddSettingUrl(this.lookupType),
			updateUrl: (idToUpdate: number) => this.getDeleteTagSettingUrl(idToUpdate),
			deleteUrl: (idToDelete: number) => this.getDeleteTagSettingUrl(idToDelete)
		} as SettingUrls;
	};

	private getRetrieveSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}?includeInactive=true&includeIsInUse=true`;
	};

	private getDeleteTagSettingUrl = (idToDelete: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`Settings/tags/${idToDelete}?includeInactive=true&includeIsInUse=true`
		);
		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl(
			'Settings/AuthoritySettings?includeInactive=true&includeIsInUse=true'
		);
		return url;
	};

	private getAddSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}?includeInactive=true&includeIsInUse=true`;
	};
}

export class AuthoritySettingUrlResolver implements ISettingUrlResolver {
	private lookupType: LookupType;

	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(this.lookupType),
			getInUseUrl: this.getInUseSettingUrl(this.lookupType),
			addUrl: this.getAddSettingUrl(this.lookupType),
			updateUrl: (idToUpdate: number) => this.getUpdateSettingUrl(idToUpdate),
			deleteUrl: (idToDelete: number) => this.getDeleteSettingUrl(idToDelete)
		} as SettingUrls;
	};

	private getRetrieveSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl('AuthoritySettings');
		return `${url}/complianceStatusValues?includeInactive=true&includeIsInUse=true`;
	};

	private getInUseSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl('AuthoritySettings');
		return `${url}/complianceStatusValues?includeInactive=true&inUseOnly=true`;
	};

	private getDeleteSettingUrl = (idToDelete: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings');
		return url;
	};

	private getAddSettingUrl = (lookupType: LookupType): string => {
		return urlService.getAuthoritySettingResourceApiUrl('AuthoritySettings');
	};
}

export class CustomFieldUrlResolver implements ISettingUrlResolver {
	private lookupType: LookupType;

	private appliedToTable: string;
	private maxSize: number = 200;

	constructor(lookupType: LookupType, appliedToTable: string) {
		this.lookupType = lookupType;
		this.appliedToTable = appliedToTable;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(this.lookupType),
			addUrl: this.getAddSettingUrl(this.lookupType),
			updateUrl: (idToUpdate?: number) => this.getDeleteTagSettingUrl(idToUpdate),
			deleteUrl: (idToDelete?: number) => this.getDeleteTagSettingUrl(idToDelete)
		} as SettingUrls;
	};

	// Settings/CustomFieldDefinitions?size=50&appliedToTable=in:tFogFacility
	private getRetrieveSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}?appliedToTable=in:${this.appliedToTable}&IncludeIsInUse=true&size=${this.maxSize}&flat=1`;
	};

	private getDeleteTagSettingUrl = (idToDelete?: number | string): string => {
		let url = '';
		if (idToDelete) {
			url = urlService.getAuthorityResourcesApiUrl(
				`Settings/CustomFieldDefinitions/${idToDelete}?includeInactive=true&includeIsInUse=true`
			);
		} else {
			url = urlService.getAuthorityResourcesApiUrl(
				`Settings/CustomFieldDefinitions?includeInactive=true&includeIsInUse=true`
			);
		}
		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl(
			'Settings/CustomFieldDefinitions?includeInactive=true&includeIsInUse=true'
		);
		return url;
	};

	private getAddSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}/CustomFieldDefinitions?includeInactive=true&includeIsInUse=true`;
	};
}

export class ImportUniqueKeyUrlResolver implements ISettingUrlResolver {
	private lookupType: LookupType;

	private entity: string;
	private maxSize: number = 200;

	constructor(lookupType: LookupType, entity: string) {
		this.lookupType = lookupType;
		this.entity = entity;
	}

	getSettingUrls = (): SettingUrls => {
		return {
			getUrl: this.getRetrieveSettingUrl(this.lookupType),
			addUrl: this.getAddSettingUrl(this.lookupType),
			updateUrl: (idToUpdate: number) => this.getDeleteTagSettingUrl(idToUpdate),
			deleteUrl: (idToDelete: number) => this.getDeleteTagSettingUrl(idToDelete)
		} as SettingUrls;
	};

	// Settings/CustomFieldDefinitions?size=50&entity=in:tFogFacility
	private getRetrieveSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}?Entity=in:${this.entity}&size=${this.maxSize}&flat=1`;
	};

	private getDeleteTagSettingUrl = (idToDelete: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl(
			`Settings/ImportUniqueKey/${idToDelete}?includeInactive=true&includeIsInUse=true`
		);
		return url;
	};

	private getUpdateSettingUrl = (idToUpdate: number | string): string => {
		const url = urlService.getAuthorityResourcesApiUrl(
			'Settings/ImportUniqueKey?includeInactive=true&includeIsInUse=true'
		);
		return url;
	};

	private getAddSettingUrl = (lookupType: LookupType): string => {
		const url = urlService.getAuthoritySettingResourceApiUrl(lookupType);
		return `${url}/ImportUniqueKey?includeInactive=true&includeIsInUse=true`;
	};
}
