import React from 'react';
import { CustomFieldDataType } from '@rcp/types';
import { DateUtilService, localizationService } from 'src/services';

interface Props {
	title: string;
	value?: any;
	isDate?: boolean;
	customFieldDateType?: CustomFieldDataType;
	format?: string;
	className?: string;
}

export const TabViewField: React.SFC<Props> = props => {
	let dateFormat = props.format;

	if ((props.isDate || props.customFieldDateType === CustomFieldDataType.Date) && !dateFormat) {
		dateFormat = localizationService.getLocalizedString('dateFormats.displayDate');
	}

	let defaultClass = 'tab-col wrapWord';
	let divClass = props.className ? props.className : defaultClass;

	return (
		<div className={divClass}>
			<strong>{props.title}</strong>
			{(() => {
				if (props.value && (props.customFieldDateType === CustomFieldDataType.Date || props.isDate)) {
					return <p>{DateUtilService.toDisplayDate(props.value, dateFormat)}</p>;
				} else if (props.customFieldDateType === CustomFieldDataType.Link) {
					return (
						<p>
							<a href={props.value} rel="noopener noreferrer" target="_blank">
								{props.value}
							</a>
						</p>
					);
				} else {
					return <p>{props.value}</p>;
				}
			})()}
		</div>
	);
};
