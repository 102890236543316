import { importHistoryReducer } from './authority-setting/importHistory';
import { fogFacilitiesReducer } from './facilities';
import {
	fogFacilityReducer,
	facilityContactReducer,
	facilityAttachmentsReducer,
	pumpoutEventsReducer,
	inspectionsReducer,
	incidentEventsReducer
} from './facility';
import { authenticationReducer } from './authentication';
import { userProfileReducer } from './user';
import { authoritySettingLookupsReducer } from './authority-setting';
import {
	authorityTagsReducer,
	customFieldDefinitionsReducer,
	authorityEventTypesReducer,
	inspectionMaintenanceResultsReducer,
	inspectionResultsReducer,
	authorityWasteTypesReducer,
	featureSettingsReducer
} from './lookups';
import { equipmentReducer } from './equipment';
import { jurisdictionReducer } from './jurisdiction';
import { alertReducer } from './alert';
import { deviceAttachmentsReducer, extractorReducer } from './extractor';

import { facilityCallLogsReducer } from './callLog';
import { facilityCommentsReducer } from './comment';
import { filterReducer } from './filter';
import { facilityEmailsReducer } from './facility/email';
import { LinkoRouteReducer } from './linko-route';
import { PageLoadingReducer } from './page-loading';
import systemAuthoritiesReducer from '../components/customerSuccess/systemAuthorities.slice';
import dashboardWidgetsReducer from '../components/authority/fog/dashboard/dashboard-widgets.slice';
import sitesReducer from '../components/authority/backflow/sites/sites.slice';
import devicesReducer from '../components/authority/backflow/settings/ccc-device-types/devices.slice';
import cccCompanyReducer from '../components/authority/backflow/companies/companies.slice';
import cccTechnicianReducer from '../components/authority/backflow/technicians/technicians.slice';
import cccTestKitReducer from '../components/authority/backflow/test-kits/test-kit.slice';
import commentsReducer from '../features/comments/comments.slice';
import entityContactsReducer from '../features/contacts/entity-contacts.slice';
import timelineReducer from '../features/timeline/timeline-events.slice';
import customFieldReducer from './custom-field.slice';
import { maintenanceMessageReducer } from './maintenance-message';
import cccImportConfigurationReducer from '../features/import-file/import-configuration.slice';
import importUniqueKeyReducer from 'src/components/authority/shared/settings/import-unique-key/import-unique-key.slice';
import ippIndustrySlice from 'src/components/authority/ipp/industries/industries.slice';
import { IndustryDetailsReducer } from './ipp/authority/industry';

import ippAuditLogReducer from '../components/authority/ipp/audit-logs/audit-logs.slice';
import ippUsersReducer from 'src/components/authority/ipp/authority-account/users/user-list.slice';
import { UsersReducer as IppUserDetailsReducer } from './ipp/authority/authority-account/users';
import ippPendingInvitationsReducer from 'src/components/authority/ipp/authority-account/pending-invitations/pending-invitation.slice';
import { IppInviteUserReducer } from './ipp/authority/authority-account/pending-invitations';

import ippPendingRegistrationUsersReducer from 'src/components/authority/ipp/pending-registrations-list/pending-registrations-list-slice';
import { ippAASAuthorityInformationReducer } from './ipp/authority/authority-account/settings/authority-information';
import { ippAASAuthoritySettingsReducer } from './ipp/authority/authority-account/settings/authority-settings';
import { ippAASIndustrialPretreatmentSettingsReducer } from './ipp/authority/authority-account/settings/industrial-pretreatment-settings';
import ippReportPackageTemplatesReducer from 'src/components/authority/ipp/report-packages/templates/templates.slice';
import { ippReportPackageTemplateReducer } from './ipp/authority/report-packages/templates/templates-reducer';
import ippReportPackageAttachmentsReducer from 'src/components/authority/ipp/report-packages/report-elements/attachments.slice';
import ippReportPackageElementDetailsReducer from 'src/components/authority/ipp/report-packages/report-elements/element-details/element-details.slice';
import ippReportPackageCertificationsReducer from 'src/components/authority/ipp/report-packages/report-elements/certifications.slice';
import { ippPendingRegistrationUserReducer } from './ipp/authority/pending-registration-user';
import ippDataProviderSlice from 'src/components/service-provider/industry/data-providers/data-providers-slice';
import ippDataProviderDetailSlice from 'src/components/service-provider/industry/data-providers/data-provider-details-slice';
import ippReportPackageParameterGroupReducer from 'src/components/authority/ipp/report-packages/parameter-groups/parameter-groups.slice';
import { ippReportPackageParameterGroupDetailsReducer } from './ipp/authority/report-packages/parameter-groups';
import IppReportPackageReviews from 'src/components/authority/ipp/report-packages/packages/reviews/reviews.slice';
import { ippReportPackageReducer } from './ipp/authority/report-packages/reviews/reviews-reducer';

import ippSampleFlowTemplatesReducer from 'src/components/authority/ipp/import-file-settings/sample-import-template/sample-import-template-list-slice';
import ippUnitTranslationsReducer from 'src/components/authority/ipp/import-file-settings/unit-translations/unit-translations-list-slice';
import ippIndustrySamplesReducer from '../components/service-provider/industry/samples/samples.slice';
import ippIndustrySamplesReadyToReportReducer from '../components/service-provider/industry/samples/samples-ready-to-report-slice';

import settingLookupTagReducer from 'src/components/authority/shared/settings/tag/tag-lookup.slice';
import { IppSampleReducer } from './ipp/industry/samples/samples-reducer';
import ippMonitoringPointSlice from 'src/components/service-provider/industry/data-providers/translations/monitoring-point-slice';
import ippCollectionMethodSlice from 'src/components/service-provider/industry/data-providers/translations/collection-method-slice';
import ippCtsEventTypeSlice from 'src/components/service-provider/industry/data-providers/translations/cts-event-type-slice';
import ippParameterSlice from 'src/components/service-provider/industry/data-providers/translations/parameter-slice';
import ippUnitSlice from 'src/components/service-provider/industry/data-providers/translations/units-slice';
import ippIndustrySamplesReportedReducer from '../components/service-provider/industry/samples/samples-reported-slice';
import ippAttachmentSlice from 'src/components/service-provider/industry/attachments/attachments-slice';
import { RegisterReducer } from './account/register';
import { ippPackageReducer } from './ipp/authority/report-packages/packages/packages-reducer';
import ippSampleImportTranslationSlice from 'src/components/service-provider/industry/samples/import/import-samples/import-translation-slice';
import noticeTemplateScheduleReducer from 'src/components/authority/backflow/settings/notices/notice-setting.slice';
import noitceTemplateMergeFieldsReducer from 'src/components/authority/shared/settings/notices/merge_field.slice';
import noticeHistoriesReducer from '../components/authority/backflow/notices/histories/notice-histories.slice';
import pendingNoticesReducer from '../components/authority/backflow/notices/pending-notices.slice';
import settingLookupSiteTypeReducer from 'src/components/authority/backflow/settings/sites/site-type-lookup-slice';
import settingLookupSiteUseReducer from 'src/components/authority/backflow/settings/sites/site-use-lookup-slice';
import settingLookupDataSetsReducer from 'src/components/authority/backflow/settings/dataset-lookup.slice';
import cccHazardTestsReducer from '../components/authority/backflow/hazards/tests/hazard-tests.slice';
import settingFrequencyReducer from '../components/authority/shared/settings/frequencies/frequency-lookup.slice';
import settingUnitReducer from '../components/authority/shared/settings/units/unit-lookup.slice';
import {
	cccLookupHazardCategoryReducer,
	cccLookupHazardDeviceOrientationReducer,
	cccLookupHazardDeviceStatusReducer,
	cccLookupHazardServiceTypeReducer,
	cccLookupHazardServiceStatusReducer,
	cccLookupHazardProtectionReducer,
	cccLookupHazardLineSizeReducer
} from 'src/components/authority/backflow/settings/hazard-lookups.slice';
import { settingJurisdictionsReducer } from './application.rest.slice';
import { cccHazardsReducer } from 'src/components/authority/backflow/hazards/hazards.slice';
import ippScheduledReportsSlice from 'src/components/service-provider/industry/report-packages/scheduled-package-slice';
import { violationsReducer } from '../components/authority/fog/violation/violation.slice';
import { facilityLinksReducer } from './facility/facility-links';
import { haulersRestSliceReducer } from 'src/components/authority/fog/haulers';
import { inspectionsSliceReducer } from 'src/components/authority/fog/inspections';
import { authorityUsersSliceReducer, internalUsersSliceReducer } from 'src/components/authority/shared/settings/users';
import { fogDevicesReducer } from 'src/components/authority/fog/fog-devices/devices.slice';
import {
	cccImportHistorySliceReducer,
	fogImportHistorySliceReducer
} from 'src/features/import-file/import-history/import-history.slice';
import { inspectionFormTemplatesSliceReducer } from 'src/components/customerSuccess/inspection-template/inspection-template-slice';
import { actionModalReducer } from './action-modals';
import { facilityDevicesReducer } from 'src/components/authority/fog/fog-devices/facility-devices.slice';
import facilityContactsSliceReducer from 'src/components/authority/fog/facilities/facility-contacts-slice';
import cleaningReducer from 'src/components/authority/fog/cleaning/cleaning.slice';
import { fogFacilitiesSliceReducer } from 'src/components/authority/fog/facilities/facilities-slice';
import {
	cccHazardContactsReducer,
	cccSiteContactsReducer,
	contactsSliceReducer,
	settingLookupsContactTypesReducer
} from 'src/features/contacts/contacts.slice';
import haulerAuthoritySlice from 'src/components/service-provider/hauler/authorities/authority.slice';
import { haulerPortalFacilitiesRestSliceReducer } from '../components/service-provider/hauler/hauler-facilities/hauler-facilities-slice';
import { haulerFacilityDevicesSliceReducer } from '../components/service-provider/hauler/hauler-facilities/hauler-facility-detail/devices-slice';
import { haulerPortalSelectedFacilitiesRestSliceReducer } from '../components/service-provider/hauler/hauler-facilities/hauler-selected-facility-slice';
import haulerCleaningHistorySlice from 'src/components/service-provider/hauler/cleaning-history/cleaning-history.slice';
import manageHaulerAccessSlice from 'src/components/authority/fog/settings/hauler-portal/users/manage-access.slice';
import cleaningNoticeTemplateSlice from 'src/components/authority/fog/settings/notices/cleaning-notice-template.slice';
import { recipientSliceReducer } from 'src/components/authority/fog/fog-devices/details/cleaning-notice-stepper/recipient-slice';
import generalNoticeTemplateSlice from 'src/components/authority/fog/settings/notices/general-notice-template.slice';
import { fogPendingNoticeReducer } from 'src/components/authority/fog/notices/pending-notices-slice';
import { fogNoticesHistorySliceReducer } from 'src/components/authority/fog/notices/notices-history-slice';
import { facilityDevicesRestSliceReducer } from '../components/service-provider/facility/cleanings/facility-devices-slice';
import manageFacilityAccessSlice from 'src/components/authority/fog/settings/facility-portal/users/manage-access.slice';
import { facilityCleaningHistoryRestSliceReducer } from '../components/service-provider/facility/cleaning-history/cleaning-history-slice';
import { facilitySettingsRestSliceReducer } from 'src/components/authority/fog/settings/facility-portal/facilities/facilities-slice';
import { fogEventsReducer } from 'src/components/authority/fog/events/events.slice';
import { incidentsReducer } from 'src/components/authority/fog/incidents/incidents-slice';
import { availableEventTypeReducer } from '../components/authority/fog/facilities/facility-details/event/available-event-type.slice';
import { FacilityEventReducer } from '../components/authority/fog/facilities/facility-details/event/event.slice';
import { customFormReducer } from 'src/components/authority/shared/settings/custom-forms/custom-forms-slice';
import { operationLogReducer } from 'src/components/customerSuccess/operation-log.slice';
import { entityMapLocationsSliceReducer } from '../components/authority/fog/map/entity-map-location-slice';
import {
	selfImportConfigurationsReducer,
	selfImportDataTypesReducer,
	selfImportFieldsReducer
} from 'src/features/import-file/self-import.slice';
import { selfImportReducer } from 'src/features';
import { languagesReducer } from './languages/languages';

export const applicationReducers = {
	fogFacilities: fogFacilitiesReducer,
	reduxFogFacilities: fogFacilitiesSliceReducer,
	cleanings: cleaningReducer,
	fogFacility: fogFacilityReducer,
	userProfile: userProfileReducer,
	alerts: alertReducer,
	pageLoadingState: PageLoadingReducer,
	authoritySettingLookups: authoritySettingLookupsReducer,
	authorityTags: authorityTagsReducer,
	authorityEventTypes: authorityEventTypesReducer,
	inspectionMaintenanceResults: inspectionMaintenanceResultsReducer,
	inspectionResults: inspectionResultsReducer,
	customFieldDefinitions: customFieldDefinitionsReducer,
	facilityEquipments: equipmentReducer,
	facilityContacts: facilityContactReducer,
	facilityAttachments: facilityAttachmentsReducer,
	deviceAttachments: deviceAttachmentsReducer,
	facilityLinks: facilityLinksReducer,
	jurisdictions: jurisdictionReducer,
	haulers: haulersRestSliceReducer,
	extractors: extractorReducer,
	facilityCallLogs: facilityCallLogsReducer,
	facilityComments: facilityCommentsReducer,
	filters: filterReducer,
	facilityEmails: facilityEmailsReducer,
	inspections: inspectionsReducer,
	reduxInspections: inspectionsSliceReducer,
	pumpOutEvents: pumpoutEventsReducer,
	authorityWasteTypes: authorityWasteTypesReducer,
	featureSettings: featureSettingsReducer,
	linkoRouteDisplay: LinkoRouteReducer,
	violation: violationsReducer,
	authorityImportHistory: importHistoryReducer,
	maintenanceMessage: maintenanceMessageReducer,
	comments: commentsReducer,
	entityContacts: entityContactsReducer,
	timeline: timelineReducer,
	fogImportHistories: fogImportHistorySliceReducer,
	fogDevices: fogDevicesReducer,
	facilityDevices: facilityDevicesReducer,
	systemAuthorities: systemAuthoritiesReducer,
	operationLog: operationLogReducer,
	inspectionFormTemplates: inspectionFormTemplatesSliceReducer,
	authorityContacts: contactsSliceReducer,
	fogFacilityContacts: facilityContactsSliceReducer,
	recipients: recipientSliceReducer,

	selfImportDataTypes: selfImportDataTypesReducer,
	selfImportFields: selfImportFieldsReducer,
	selfImportConfigurations: selfImportConfigurationsReducer,
	selfImport: selfImportReducer,
	languages: languagesReducer,

	dashboardWidgets: dashboardWidgetsReducer,
	cccSites: sitesReducer,
	cccDevices: devicesReducer,
	cccHazards: cccHazardsReducer,
	cccCompanies: cccCompanyReducer,
	cccTechnicians: cccTechnicianReducer,
	customFields: customFieldReducer,
	cccTestKits: cccTestKitReducer,
	cccHazardTests: cccHazardTestsReducer,
	cccImportConfigurations: cccImportConfigurationReducer,
	cccImportHistories: cccImportHistorySliceReducer,
	importUniqueKeys: importUniqueKeyReducer,
	settingJurisdictions: settingJurisdictionsReducer,
	settingContactTypes: settingLookupsContactTypesReducer,
	settingLookupTags: settingLookupTagReducer,
	settingLookupSiteTypes: settingLookupSiteTypeReducer,
	settingLookupSiteUses: settingLookupSiteUseReducer,
	settingLookupDataSets: settingLookupDataSetsReducer,
	settingLookupHazardCategory: cccLookupHazardCategoryReducer,
	settingLookupHazardProtection: cccLookupHazardProtectionReducer,
	settingLookupHazardDeviceStatus: cccLookupHazardDeviceStatusReducer,
	settingLookupHazardDeviceOrientation: cccLookupHazardDeviceOrientationReducer,
	settingLookupHazardServiceStatus: cccLookupHazardServiceStatusReducer,
	settingLookupHazardServiceType: cccLookupHazardServiceTypeReducer,
	settingLookupHazardLineSize: cccLookupHazardLineSizeReducer,
	noticeTemplateSchedules: noticeTemplateScheduleReducer,
	noticeHistories: noticeHistoriesReducer,
	siteContacts: cccSiteContactsReducer,
	hazardContacts: cccHazardContactsReducer,
	pendingNotices: pendingNoticesReducer,
	settingUnits: settingUnitReducer,
	settingFrequencies: settingFrequencyReducer,
	mergeFields: noitceTemplateMergeFieldsReducer,

	//Ipp
	ippIndustries: ippIndustrySlice,
	ippIndustryDetails: IndustryDetailsReducer,
	ippAuditLogs: ippAuditLogReducer,
	ippUsers: ippUsersReducer,
	ippPendingInvitation: ippPendingInvitationsReducer,
	ippUserDetails: IppUserDetailsReducer,
	ippInviteUser: IppInviteUserReducer,
	ippAASAuthorityInformation: ippAASAuthorityInformationReducer,
	ippAASAuthoritySettings: ippAASAuthoritySettingsReducer,
	ippAASIndustrialPretreatmentSettings: ippAASIndustrialPretreatmentSettingsReducer,
	ippReportPackageTemplates: ippReportPackageTemplatesReducer,
	ippReportPackageTemplate: ippReportPackageTemplateReducer,
	ippReportPackageAttachments: ippReportPackageAttachmentsReducer,
	ippReportPackageCertifications: ippReportPackageCertificationsReducer,
	ippPendingRegistrationUser: ippPendingRegistrationUserReducer,
	ippPendingRegistrationUsers: ippPendingRegistrationUsersReducer,
	ippReportPackageElementDetails: ippReportPackageElementDetailsReducer,
	ippDataProviders: ippDataProviderSlice,
	ippDataProviderDetails: ippDataProviderDetailSlice,
	ippReportPackageParameterGroups: ippReportPackageParameterGroupReducer,
	ippReportPackageParameterGroupDetails: ippReportPackageParameterGroupDetailsReducer,
	ippReportPackageReviews: IppReportPackageReviews,
	ippReportPackageReview: ippReportPackageReducer,
	ippSampleImportTemplates: ippSampleFlowTemplatesReducer,
	ippUnitTranslations: ippUnitTranslationsReducer,
	ippMonitoringPoint: ippMonitoringPointSlice,
	ippCollectionMethod: ippCollectionMethodSlice,
	ippCtsEventType: ippCtsEventTypeSlice,
	ippParameter: ippParameterSlice,
	ippUnit: ippUnitSlice,
	ippIndustrySamples: ippIndustrySamplesReducer,
	ippIndustrySample: IppSampleReducer,
	ippIndustrySamplesReadyToReport: ippIndustrySamplesReadyToReportReducer,
	ippIndustrySamplesReported: ippIndustrySamplesReportedReducer,
	ippAttachments: ippAttachmentSlice,
	register: RegisterReducer,
	ippReportPackage: ippPackageReducer,
	ippSampleImportTranslation: ippSampleImportTranslationSlice,
	ippScheduledReports: ippScheduledReportsSlice,

	authorityUsers: authorityUsersSliceReducer,
	internalUsers: internalUsersSliceReducer,

	haulerAuthorities: haulerAuthoritySlice,
	authorityHaulerUsers: manageHaulerAccessSlice,

	actionModals: actionModalReducer,
	fogPendingNotices: fogPendingNoticeReducer,

	//hauler portal
	haulerPortalFacilities: haulerPortalFacilitiesRestSliceReducer,
	haulerFacilityDevices: haulerFacilityDevicesSliceReducer,
	haulerSelectedFacilities: haulerPortalSelectedFacilitiesRestSliceReducer,
	haulerCleaningHistory: haulerCleaningHistorySlice,

	// Fog Notices
	cleaningNoticeTemplate: cleaningNoticeTemplateSlice,
	generalNoticeTemplate: generalNoticeTemplateSlice,
	fogNoticesHistory: fogNoticesHistorySliceReducer,

	//facility portal
	facilityPortalDevices: facilityDevicesRestSliceReducer,
	facilities: facilitySettingsRestSliceReducer,
	authorityFacilityUsers: manageFacilityAccessSlice,
	facilityCleaningHistory: facilityCleaningHistoryRestSliceReducer,

	//Events
	fogEvents: fogEventsReducer,
	availableEventTypes: availableEventTypeReducer,
	facilityEvents: FacilityEventReducer,
	incidentEvents: incidentEventsReducer,

	//Events
	incidents: incidentsReducer,

	//CustomForms
	customForms: customFormReducer,

	//Entity Map Locations
	entityMapLocations: entityMapLocationsSliceReducer,

	authenticationResult: authenticationReducer //keep this line at the end for simple code review
};
