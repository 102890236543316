import * as React from 'react';
import { useEffect, useState } from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import {
	ApiError,
	CccImportStep1Data,
	CccImportSteps,
	CccImportSummary,
	DropDownOption,
	ImportField,
	ImportFieldMapping,
	ImportEntity
} from '@rcp/types';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import _, { Dictionary } from 'lodash';
import { alertService, useReduxSelector } from 'src/redux';
import { SingleSelectDropdown } from 'src/components/widgets';
import SampleGrid from '../configuration/sample-grid';
import './step2.scss';
import { importConfigurationSlice } from '../../import-configuration.slice';
import { useDispatch } from 'react-redux';

interface Props {
	vertical?: boolean;
}

interface Step2State {
	updatePolicy: string;
	isConfigurationEditable: boolean;
	isReadOnlyConfiguration: boolean;
	fieldOptions: DropDownOption[];
	fieldMappings: ImportFieldMapping[];
	data: any[];
	error?: string;
	processing: boolean;
}

const Step2: React.FunctionComponent<Props> = () => {
	const stepperContext = React.useContext(StepperContext);
	const step1Data: CccImportStep1Data = stepperContext.getData(CccImportSteps.STEP1);
	const step2State = stepperContext.getStep(CccImportSteps.STEP2);
	const updatePolicyOptions: DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('cccImportFile.UpdatePolicyInsertAndUpdate'),
			value: 'AddAndUpdate'
		},
		{ label: localizationService.getLocalizedString('cccImportFile.UpdatePolicyInsertOnly'), value: 'AddOnly' },
		{ label: localizationService.getLocalizedString('cccImportFile.UpdatePolicyUpdateOnly'), value: 'UpdateOnly' }
	];

	const initState: Step2State = {
		updatePolicy: 'AddAndUpdate',
		isConfigurationEditable: false,
		isReadOnlyConfiguration: false,
		processing: false,
		fieldOptions: [],
		fieldMappings: [],
		data: [
			//Data format as excel style, see mock up: https://github.com/AquaticInformatics/RCP/blob/develop/images/screenshots/import-preview.png
		]
	};
	const [state, setState] = useState(initState);
	const [clickedImport, setClickedImport] = useState(false);
	const { userProfile } = useReduxSelector(state => state.userProfile);
	const dispatch = useDispatch();
	const bigImportBar = 100;

	const isAdministratorOrInternalUser = (): boolean => {
		return urlService.isAdministrator() || userProfile.isInternalAccount === true;
	};

	const onUpdatePolicyChanged = (e: any) => {
		const { value } = e.target;

		dispatch(
			importConfigurationSlice.patchOne(parseInt(step1Data.importConfigurationId), { importEntityPolicy: value })
		);
		setState({ ...state, updatePolicy: value, error: '' });
	};

	const spaceKey = String.fromCharCode(32);
	const toExcelRow = (data: string[], rowNumber: number): Dictionary<string> => {
		var row: Dictionary<string> = {};
		row[spaceKey] = _.toString(rowNumber);
		for (let i = 0; i < data.length; i++) {
			let quotient = Math.floor(i / 26);
			let remainder: number = i % 26;
			let prefix = quotient > 0 ? String.fromCharCode(65 + quotient - 1) : '';
			let key = prefix + String.fromCharCode(65 + remainder);
			row[key] = data[i];
		}
		return row;
	};
	const getPreviewData = (): Dictionary<string>[] => {
		let data: Dictionary<string>[] = [];
		if (step1Data.uploadDataColumns && step1Data.uploadDataRows) {
			let rowNumber = 1;
			var header = toExcelRow(step1Data.uploadDataColumns, rowNumber++);
			data.push(header);
			for (let i = 0; i < step1Data.uploadDataRows.length; i++) {
				var row = toExcelRow(step1Data.uploadDataRows[i], rowNumber++);
				data.push(row);
			}
		}
		return data;
	};

	const getFieldOptionEntity = (importEntity: string): string | null => {
		if (importEntity === ImportEntity.CccSite) {
			return localizationService.getLocalizedString('screen.labels.site');
		}
		if (importEntity === ImportEntity.CccHazard) {
			return localizationService.getLocalizedString('cccHazard.hazard');
		}
		if (importEntity === ImportEntity.CccContact) {
			return localizationService.getLocalizedString('screen.labels.contact');
		}
		return null;
	};

	const toFieldOptions = (): DropDownOption[] => {
		var options: DropDownOption[] = [];
		if (step1Data && step1Data.targetFields && step1Data.targetFields.length > 0) {
			_.forEach(step1Data.targetFields, (targetField: ImportField) => {
				options.push({
					label: targetField.fieldLabel,
					value: targetField.fieldName,
					prefix: getFieldOptionEntity(targetField.importEntity)
				});
			});
		}
		return options;
	};

	useEffect(() => {
		if (step1Data.importConfigurationId) {
			let queryParams: any = {
				configurationId: step1Data.importConfigurationId,
				fileId: step1Data.importFileId
			};
			urlService.setUrlQueryString(queryParams);
		}
		if (step1Data) {
			let previewData = getPreviewData();
			let fieldOptions = toFieldOptions();
			let newState = {
				...state,
				fieldOptions: fieldOptions,
				updatePolicy: step1Data.importEntityPolicy,
				isConfigurationEditable:
					isAdministratorOrInternalUser() && _.toNumber(step1Data.importConfigurationId) > 0,
				isReadOnlyConfiguration: _.toNumber(step1Data.importConfigurationId) <= 0,
				fieldMappings: step1Data.fieldMappings || [],
				data: previewData
			};
			setState(newState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [step1Data]);

	const getFieldMappingsUrl = (): string => {
		let importConfigurationId = _.toNumber(step1Data.importConfigurationId);
		let authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		return `${authoritySettingsUrl}/ImportConfigurations/${importConfigurationId}/FieldMappings`;
	};

	const onErrorSaveFieldMapping = (error: ApiError) => {
		alertService.addError(error.message);
		if (error.statusCode === 400) {
			let step2State = { ...stepperContext.getStep(CccImportSteps.STEP2), loading: false };
			stepperContext.updateStep(CccImportSteps.STEP2, step2State);
		}
	};

	const onSuccessSaveFieldMapping = (savedFieldMapping: ImportFieldMapping, columnId: string) => {
		let newFieldMappings = [...state.fieldMappings];
		let fieldMappingIdsShouldDelete: number[] = newFieldMappings
			.filter(
				f =>
					f.fieldName === savedFieldMapping.fieldName &&
					f.importFieldMappingId !== savedFieldMapping.importFieldMappingId
			)
			.map(f => f.importFieldMappingId as number);
		_.remove(
			newFieldMappings,
			f =>
				f.fieldName === savedFieldMapping.fieldName ||
				f.importFieldMappingId === savedFieldMapping.importFieldMappingId
		);
		newFieldMappings.push(savedFieldMapping);
		if (columnId) {
			const alertString = localizationService.getLocalizedString(
				'alertMessages.ColumnUpdatedSuccess',
				columnId,
				savedFieldMapping.fieldName as string
			);
			alertService.addSuccess(`${alertString}`);
		}
		setState({ ...state, fieldMappings: newFieldMappings });
		if (fieldMappingIdsShouldDelete.length > 0) {
			fieldMappingIdsShouldDelete.forEach(id => {
				apiService.deleteResource(`${getFieldMappingsUrl()}/${id}`);
			});
		}
	};

	const onSuccessDeleteFieldMapping = (importFieldMappingId: number) => {
		let newFieldMappings = [...state.fieldMappings];
		_.remove(newFieldMappings, f => f.importFieldMappingId === importFieldMappingId);
		setState({ ...state, fieldMappings: newFieldMappings });
	};

	const saveFieldMapping = async (fieldMapping: ImportFieldMapping, columnId: string = ''): Promise<void> => {
		if (step1Data.targetFields && !_.isEmpty(fieldMapping.fieldName)) {
			let targetField = step1Data.targetFields.find(f => f.fieldName === fieldMapping.fieldName);
			if (targetField) {
				fieldMapping.fieldLabel = targetField.fieldLabel;
				fieldMapping.isCustomField = targetField.isCustomField;
				fieldMapping.isNotNull = targetField.isNotNull;
				fieldMapping.isRequiredForDataEnter = targetField.isRequiredForDataEnter;
				fieldMapping.fieldType = targetField.fieldType;
			}
		}
		if (fieldMapping.importFieldMappingId) {
			let fieldMappingUrl = `${getFieldMappingsUrl()}/${fieldMapping.importFieldMappingId}`;
			if (_.isEmpty(fieldMapping.fieldName)) {
				return apiService
					.deleteResource(fieldMappingUrl)
					.then(() => {
						onSuccessDeleteFieldMapping(fieldMapping.importFieldMappingId as number);
						alertService.addSuccess(
							localizationService.getLocalizedString('alertMessages.ColumnUnMapped', columnId)
						);
					})
					.catch(onErrorSaveFieldMapping);
			} else {
				return apiService
					.patchResource<ImportFieldMapping>(fieldMappingUrl, fieldMapping)
					.then(savedFieldMapping => {
						onSuccessSaveFieldMapping(savedFieldMapping, columnId);
					})
					.catch(onErrorSaveFieldMapping);
			}
		} else {
			if (!_.isEmpty(fieldMapping.fieldName)) {
				return apiService
					.postResource<ImportFieldMapping>(getFieldMappingsUrl(), fieldMapping)
					.then(savedFieldMapping => {
						onSuccessSaveFieldMapping(savedFieldMapping, columnId);
					})
					.catch(onErrorSaveFieldMapping);
			}
		}
	};

	const chooseUpdatePolicy = () => {
		return (
			<div className="row">
				<SingleSelectDropdown
					id="updatePolicyId"
					name="updatePolicyId"
					className="col-auto"
					noEmptyOption={true}
					isDisabled={!state.isConfigurationEditable}
					value={state.updatePolicy}
					label={localizationService.getLocalizedString('cccImportFile.UpdatePolicy')}
					onChange={onUpdatePolicyChanged}
					options={updatePolicyOptions}
				/>
			</div>
		);
	};

	const back = () => stepperContext.goAt(CccImportSteps.STEP1);

	const importFile = async () => {
		let step2State = { ...stepperContext.getStep(CccImportSteps.STEP2), loading: false };
		stepperContext.updateStep(CccImportSteps.STEP2, step2State);
		let authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		let url = `${authoritySettingsUrl}/Imports/${step1Data.importFileId}`;
		if (step1Data.importConfigurationId) {
			url += '?' + urlService.toQueryString({ importConfigurationId: step1Data.importConfigurationId });
		}
		return apiService.httpPost(url, {});
	};

	const onClickNext = (event: React.FormEvent) => {
		if (step2State && step2State.completed) {
			stepperContext.goAt(CccImportSteps.STEP3);
			return;
		}
		if (state.fieldMappings.length < 1) {
			alertService.addError(
				localizationService.getLocalizedString('cccImportFile.cannotImportWithoutFieldMappings')
			);
			return;
		}

		setClickedImport(true);
		importFile()
			.then((response: CccImportSummary) => {
				if (response.totalRowNumber > bigImportBar) {
					alertService.addSuccess(
						localizationService.getLocalizedString('cccImportFile.step2ImportStartAlert')
					);
				}
				urlService.setUrlQueryString({});
				stepperContext.resolve({ ...response });
			})
			.catch((error: ApiError) => {
				alertService.addError(error.message);
				if (error.statusCode === 400) {
					let step2State = { ...stepperContext.getStep(CccImportSteps.STEP2), loading: false };
					stepperContext.updateStep(CccImportSteps.STEP2, step2State);
				}
			})
			.finally(() => {
				setClickedImport(false);
			});
	};

	return (
		<>
			<StepperContent
				id="step2Preview"
				actions={
					<React.Fragment>
						<StepperAction type="button" className="btn btn-link" id="btnBack" onClick={back}>
							{localizationService.getLocalizedString('screen.buttons.back')}
						</StepperAction>
						<StepperAction
							type="button"
							className="btn btn-link btn-disabled"
							id="btnNext"
							disabled={clickedImport === true}
							onClick={onClickNext}>
							{localizationService.getLocalizedString('screen.buttons.import')}
						</StepperAction>
					</React.Fragment>
				}>
				{chooseUpdatePolicy()}

				<div className="simple-data-grid">
					<SampleGrid
						data={state.data}
						isConfigurationEditable={state.isConfigurationEditable}
						fieldOptions={state.fieldOptions}
						fieldMappings={state.fieldMappings}
						cccSaveFieldMapping={saveFieldMapping}
						importConfigurationId={_.toNumber(step1Data.importConfigurationId)}
						importFileId={step1Data.importFileId}
						targetFields={step1Data.targetFields}
						uploadDataColumns={step1Data.uploadDataColumns}
						uploadDataRows={step1Data.uploadDataRows}
						totalRowNumber={_.size(step1Data.uploadDataRows)}
						sideMode={false}
					/>
				</div>

				{state.error && <div className="ai-required">{state.error}</div>}
			</StepperContent>
		</>
	);
};

export default Step2;
