import React, { FC, useState } from 'react';
import './form-builder-header.scss';
import './custom-form.scss';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { ReactComponent as ArrowBack } from 'src/assets/img/arrow-back.svg';
import { useHistory } from 'react-router';
import { SwitchButton } from 'src/components/widgets/switch-button';
import _ from 'lodash';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt, faTabletAlt } from '@fortawesome/free-solid-svg-icons';
import { ScreenViewPort } from '@rcp/types';

interface Props {
	title: string;
	isCleaningFormUpdated?: boolean;
	handleSubmit: (event: any) => void;
	togglePreview?: () => void;
	isPreviewMode?: boolean;
	toggleDiscardModal: () => void;
	showDiscardModal: boolean;
	navigateToCustomForms: () => void;
	toggleMobileView: (viewport: string) => void;
	saveOnLocalStorage: () => void;
}

export const FormBuilderHeader: FC<Props> = props => {
	const history = useHistory();
	const [activeTab, setActiveTab] = useState<string>(ScreenViewPort.desktop);

	const navigateToCustomForms = () => {
		navigateTo(history, urlService.getReactAuthorityResourcesPath(Resource.CustomFormsSettings));
	};

	let discardModalProps: CustomModalProp = {
		showModal: props.showDiscardModal,
		onOkayButtonClick: props.navigateToCustomForms,
		onCancelButtonClick: props.toggleDiscardModal,
		title: localizationService.getLocalizedString('customForm.discardTitle'),
		message: localizationService.getLocalizedString('customForm.discardMessage'),
		okayButtonText: localizationService.getLocalizedString('screen.buttons.discard')
	};

	const setActiveClass = (name: string) => {
		setActiveTab(name);
	};

	const isActive = (id: string) => {
		return activeTab === id;
	};

	return (
		<header className="form-builder-header">
			<nav>
				<div className="d-flex align-items-center justify-content-between font-size-18px cursor-pointer">
					{!props.isPreviewMode &&
						(props.isCleaningFormUpdated ? (
							<div className="d-flex pl-2">
								<button className="btn ai-white mr-2" onClick={props.handleSubmit}>
									{localizationService.getLocalizedString('screen.buttons.save')}
								</button>
								<button className="form-discard-button" onClick={() => props.toggleDiscardModal()}>
									{localizationService.getLocalizedString('screen.buttons.discard')}
								</button>
							</div>
						) : (
							<div onClick={navigateToCustomForms}>
								<ArrowBack width={18} height={18} viewBox="0 0 18 18" />
								<span className="ml-2">
									{localizationService.getLocalizedString('customForm.back')}
								</span>
							</div>
						))}
					{props.showDiscardModal && <CustomModal {...discardModalProps} />}
				</div>
				<h2 id="cleaningFormHeaderTitle" className={`${props.isPreviewMode ? 'cleaningFormHeader' : ''}`}>
					{_.capitalize(_.startCase(props.title))}
				</h2>
				<div className="switch-preview font-size-18px d-flex justify-content-end cursor-pointer">
					{props.isPreviewMode && (
						<>
							<div
								id="mobile"
								className={`d-flex ml-3 viewport-tab mr-1 flex-column justify-content-center p-1 ${
									isActive(ScreenViewPort.mobile) ? 'active-tab-class' : ''
								}`}>
								<a
									className="tab-content-form-builder"
									onClick={e => {
										e.preventDefault();
										setActiveClass(ScreenViewPort.mobile);
										props.toggleMobileView(ScreenViewPort.mobile);
									}}>
									<span>
										<FontAwesomeIcon
											id="mobile-icon"
											color="white"
											icon={faMobileAlt}
											className="font-awesome-icon viewport-icon"
										/>
									</span>
									<span className="viewport-text">
										{localizationService.getLocalizedString('customForm.screenView.phone')}
									</span>
								</a>
							</div>
							<div
								className={`d-flex viewport-tab flex-column justify-content-center p-1 ${
									isActive(ScreenViewPort.tablet) ? 'active-tab-class' : ''
								}`}
								id="tablet">
								<a
									className="tab-content-form-builder"
									onClick={e => {
										e.preventDefault();
										setActiveClass(ScreenViewPort.tablet);
										props.toggleMobileView(ScreenViewPort.tablet);
									}}>
									<span>
										<FontAwesomeIcon
											id="mobile-icon"
											color="white"
											icon={faTabletAlt}
											className="font-awesome-icon viewport-icon"
										/>
									</span>
									<span className="viewport-text">
										{localizationService.getLocalizedString('customForm.screenView.tablet')}
									</span>
								</a>
							</div>
							<div
								className={`d-flex viewport-tab flex-column ml-2 mr-5 justify-content-center p-1 ${
									isActive(ScreenViewPort.desktop) ? 'active-tab-class' : ''
								}`}
								id="desktop">
								<a
									className="tab-content-form-builder"
									onClick={e => {
										e.preventDefault();
										setActiveClass(ScreenViewPort.desktop);
										props.toggleMobileView(ScreenViewPort.desktop);
									}}>
									<span>
										<FontAwesomeIcon
											id="mobile-icon"
											color="white"
											icon={faDesktop}
											className="font-awesome-icon viewport-icon"
										/>
									</span>
									<span className="viewport-text">
										{localizationService.getLocalizedString('customForm.screenView.desktop')}
									</span>
								</a>
							</div>
						</>
					)}

					<div className="d-flex align-items-center switch-div ml-2">
						<SwitchButton
							handleClick={(e: any) => {
								props.saveOnLocalStorage();
								props.togglePreview && props.togglePreview();
								setActiveClass(ScreenViewPort.desktop);
							}}
						/>
						<span className="ml-2">{localizationService.getLocalizedString('customForm.preview')}</span>
					</div>
				</div>
			</nav>
		</header>
	);
};
