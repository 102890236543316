import { AddNoticeSteps } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { SingleCheckbox } from 'src/components/widgets';
import { StepperContent, StepperAction, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState } from 'src/redux';
import { localizationService, urlService } from 'src/services';

export const Step2 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [shouldSendToFacilityAssignedToUser, setShouldSendToFacilityAssignedToUser] = React.useState(false);
	const [loaded, setLoaded] = React.useState(false);

	let cleaningNoticeTemplateSchedule = (state: RootState) => state.cleaningNoticeTemplate;
	let { selected: cleaningNoticeTemplate } = useSelector(cleaningNoticeTemplateSchedule);

	React.useEffect(() => {
		const step2Content = stepperContext.getData(AddNoticeSteps.STEP2);
		if (step2Content) {
			setShouldSendToFacilityAssignedToUser(step2Content.shouldSendToFacilityAssignedToUser as boolean);
		} else if (cleaningNoticeTemplate) {
			setShouldSendToFacilityAssignedToUser(cleaningNoticeTemplate.shouldSendToFacilityAssignedToUser || false);
		}
		setLoaded(true);
	}, [cleaningNoticeTemplate]);

	React.useEffect(() => {
		//Added this useEffect for directly jump to further steps
		if (loaded) {
			_.defer(() => {
				let step = _.toInteger(urlService.getUrlQueryParameter('step') as string);
				if (step > 2) {
					onClickNext();
				}
			}, 200);
		}
	}, [loaded]);

	const onClickNext = () => {
		alertService.clearAllMessages();
		stepperContext.resolve({ shouldSendToFacilityAssignedToUser });
	};

	const onClickBack = () => stepperContext.goAt(AddNoticeSteps.STEP1);

	return (
		<StepperContent
			id="select-recipients"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btnBack" className="btn btn-link" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<p>{localizationService.getLocalizedString('authoritySetting.noticesSettings.selectRecipientsDesc')}</p>
			<SingleCheckbox
				id="select-recipients-checkbox"
				name="recipients"
				checked={shouldSendToFacilityAssignedToUser}
				label={localizationService.getLocalizedString(
					'authoritySetting.noticesSettings.selectRecipientsCheckBoxLabel'
				)}
				onChange={e => {
					setShouldSendToFacilityAssignedToUser(e.target.checked);
				}}></SingleCheckbox>
		</StepperContent>
	);
};
