import { ApiError, DataImport, MaxInteger, UploadMaximumSize10MB } from '@rcp/types';
import FormData from 'form-data';
import _, { Dictionary } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RadioGroup, RadioOption } from 'src/components/widgets';
import { DragAndDrop } from 'src/components/widgets/drag-and-drop';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { alertService, RootState, useReduxSelector } from 'src/redux';
import {
	apiService,
	localizationService,
	Logger,
	navigateTo,
	QueryParameters,
	Resource,
	tokenService,
	urlService,
	validationService
} from 'src/services';
import { ImportUtils } from 'src/services/import-utils';
import { selfImportConfigurationSlice } from '../self-import.slice';
import {
	clearSelfImportStepsState,
	closeSideEditor,
	getSelfImportDataType,
	resetAllSelfImportStepStates,
	SelfImportSteps,
	StandardImportConfigurationName
} from './self-import-service';

export interface SelfImportStep2Data {
	dataType?: string;
	selectedFile?: File;
	importConfigurationId: number;
	importConfigurationName: string;
	isDraft: boolean;
	importFileId: string;
	fileName: string;

	targetFields?: DataImport.ImportField[];
	uploadDataColumns?: string[];
	uploadDataRows?: string[][];
}
const initialSelfImportStep2Data: SelfImportStep2Data = {
	importConfigurationId: -1,
	importConfigurationName: StandardImportConfigurationName,
	isDraft: false,
	importFileId: '',
	fileName: ''
};

interface Props {}

export const SelfImportStep2: React.FC<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const [selectedFile, setSelectedFile] = React.useState<File>();
	const [state, setState] = React.useState<SelfImportStep2Data>(initialSelfImportStep2Data);
	const [invalidFileErrorMessage, setInvalidFileErrorMessage] = React.useState('');
	const [configurationOptions, setConfigurationOptions] = useState<RadioOption[]>([]);

	const selfImportState = useSelector((state: RootState) => state.selfImport).current;
	const { userProfile } = useReduxSelector(state => state.userProfile);
	const isAdministratorOrInternalUser = (): boolean => {
		return urlService.isAdministrator() || userProfile.isInternalAccount === true;
	};

	const dispatch = useDispatch();
	const history = useHistory();

	const importConfigurations = useSelector((state: RootState) => state.selfImportConfigurations.result);

	React.useEffect(() => {
		//user can directly click step 2 title move from step 3
		closeSideEditor(selfImportState, dispatch);

		let dataType = getSelfImportDataType(stepperContext);
		if (!dataType) {
			Logger.warn(`step1Data.dataType is required for Step 2.`);
			resetAllSelfImportStepStates(stepperContext);
			stepperContext.goAt(SelfImportSteps.STEP1);
			return;
		}

		const step2Data = stepperContext.getData(SelfImportSteps.STEP2);
		if (step2Data) {
			let newState = { ...step2Data, dataType: dataType };
			let queryParams: Dictionary<string> = {
				dataType,
				configurationId: newState.importConfigurationId,
				step: '2'
			};
			urlService.replaceUrlQueryString(queryParams);
			setState(newState);
		} else {
			let newState = { ...state, dataType };
			let queryParams: Dictionary<string> = { dataType };
			let configurationId = urlService.getUrlQueryParameter('configurationId') as string;
			if (configurationId) {
				newState.importConfigurationId = _.toNumber(configurationId);
				queryParams.configurationId = configurationId;
			}
			let step = urlService.getUrlQueryParameter('step') as string;
			if (_.toNumber(step) < 2) {
				step = '2';
			}
			let importFileId = urlService.getUrlQueryParameter('importFileId') as string;
			if (importFileId) {
				newState.importFileId = importFileId;
				queryParams.importFileId = importFileId;
			} else if (_.toNumber(step) > 2) {
				//Cannot move to step 3 or further step without importFileId
				queryParams.step = '2';
			}
			urlService.replaceUrlQueryString(queryParams);
			setState(newState);

			if (_.toNumber(step) > 2) {
				Logger.debug(`Step 2 loaded: Url has step ${step}.`);
				//uploadFiles will move to next step
				uploadFiles(configurationId, importFileId, dataType);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (state.dataType) {
			dispatch(
				selfImportConfigurationSlice.fetchPage({
					dataType: state.dataType,
					size: `${MaxInteger}`
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.dataType]);

	React.useEffect(() => {
		let newConfigurationOptions: RadioOption[] = [];
		newConfigurationOptions.push({
			value: -1, //use default template
			label: (
				<span>
					{localizationService.getLocalizedString('import.steps.step2Option1')}
					&nbsp;
					<a
						href={getStandardTemplateUrl()}
						onClick={e => {
							downloadExample(e, true);
						}}>
						{localizationService.getLocalizedString('import.steps.step2Option1Download')}
					</a>
				</span>
			),
			id: 'importConfigurationStandard'
		});
		importConfigurations.forEach(config => {
			if (config.isDraft === false) {
				//public shared configuration
				let newOption = {
					value: config.importConfigurationId,
					label: config.name,
					id: `importConfiguration${config.importConfigurationId}`
				};
				newConfigurationOptions.push(newOption);
			} else {
				// private draft configuration
				if (state.importConfigurationId === config.importConfigurationId) {
					//browser refresh with query parameter draft configurationId
					let newOption = {
						value: config.importConfigurationId,
						label: (
							<span>
								<i>
									{localizationService.getLocalizedString('import.steps.step2DraftConfigurationName')}
								</i>
							</span>
						),
						id: `importConfiguration${config.importConfigurationId}`
					};
					newConfigurationOptions.push(newOption);
				}
			}
		});
		if (isAdministratorOrInternalUser()) {
			//Only authority admin or internal user can import file from scratch;
			//standard user can only import with system default import or preConfigured import
			newConfigurationOptions.push({
				value: 0, //use draft template
				label: localizationService.getLocalizedString(
					importConfigurations.length > 0 ? 'import.steps.step2OptionX' : 'import.steps.step2OptionXInitial'
				),
				id: 'importConfigurationCreateDraft'
			});
		}
		setConfigurationOptions(newConfigurationOptions);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [importConfigurations]);

	const isFacilityOwnedImportEntity = () => {
		return (
			String.equalCaseInsensitive(state.dataType, DataImport.ImportDataTypeValues.FogInspections) ||
			String.equalCaseInsensitive(state.dataType, DataImport.ImportDataTypeValues.FogDevices)
		);
	};

	const getStandardTemplateUrl = (withAccessToken = true) => {
		let url = urlService.getAuthorityResourcesApiUrl(`Settings/AuthoritySettings/ImportFields/${state.dataType}`);
		let queryParams = new QueryParameters().put('format', 'excel');
		if (withAccessToken) {
			queryParams.put('accessToken', tokenService.getTokenOrDefault().accessToken);
		}
		if (isFacilityOwnedImportEntity()) {
			queryParams.put('removeNonIdentityFacilityFields', 'true');
		}
		url += queryParams.toQueryString(true);
		return url;
	};

	const downloadExample = async (e: any, forTemplate: boolean) => {
		e.preventDefault();

		let url = forTemplate
			? getStandardTemplateUrl(false)
			: urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings/Imports/Example');

		return await apiService.downloadByUrl(url);
	};

	const clearSelfImportStepsWhenCurrentStepChanged = () => {
		clearSelfImportStepsState(stepperContext, SelfImportSteps.STEP3, SelfImportSteps.STEP4, SelfImportSteps.STEP5);
	};

	const onConfigurationSelected = (e: any) => {
		const { value } = e.target;
		const selectedConfigurationId = _.toNumber(value);
		if (selectedConfigurationId !== state.importConfigurationId) {
			let selectedConfigurationOption = _.find(configurationOptions, c => c.value === value);
			let newState = {
				...state,
				importConfigurationId: selectedConfigurationId,
				importConfigurationName:
					selectedConfigurationId === -1
						? StandardImportConfigurationName
						: (selectedConfigurationOption?.label as string) || 'Unknown',
				isDraft: selectedConfigurationId === 0
			};
			urlService.replaceUrlQueryString({ configurationId: value });
			setState(newState);
			clearSelfImportStepsWhenCurrentStepChanged();
		}
	};

	const uploadFiles = (configurationId?: string, importFileId?: string, dataType?: string) => {
		stepperContext.updateStep(SelfImportSteps.STEP2, {
			...stepperContext.getStep(SelfImportSteps.STEP2),
			loading: true
		});

		const settingUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		const url = `${settingUrl}/Imports/${dataType || state.dataType}/Upload`;
		let formData = new FormData();
		formData.append('files', selectedFile);
		formData.append('importConfigurationId', configurationId || state.importConfigurationId);
		formData.append('isDraft', state.isDraft);
		if (importFileId) {
			formData.append('importFileId', importFileId);
		}

		const headers = {};
		return apiService
			.postFormData(url, formData, headers)
			.then((response: DataImport.ImportSummary) => {
				const step2NewData = { selectedFile: selectedFile, ...state, ...response };
				stepperContext.resolve(step2NewData);
			})
			.catch((error: any) => {
				Logger.error(error);
				if (error instanceof ApiError) {
					if (error.statusCode === 413) {
						alertService.addError(
							localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
						);
					}
					alertService.addError(error.message);
				} else {
					alertService.addError(error.message.message);
				}

				stepperContext.updateStep(SelfImportSteps.STEP2, {
					...stepperContext.getStep(SelfImportSteps.STEP2),
					loading: false
				});
			});
	};

	const onClickNext = async (event: React.FormEvent) => {
		alertService.clearAllMessages();

		if (!_.isEmpty(state.fileName) && !_.isEmpty(state.importFileId)) {
			//The import already uploaded file, and the user is move to step 2 from further steps.
			await uploadFiles(_.toString(state.importConfigurationId), state.importFileId, state.dataType);
			return;
		}

		if (selectedFile === undefined || selectedFile === null) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('importFile.noFileSelectedMessage'));
			alertService.addError(localizationService.getLocalizedString('importFile.noFileSelectedMessage'));
			return;
		}

		let isFileInvalid = false;

		if (!validationService.validateUploadSize(selectedFile.size, UploadMaximumSize10MB)) {
			alertService.addError(localizationService.getLocalizedString('importFile.exceedUploadSizeLimit10MB'));
			isFileInvalid = true;
		}
		let filenameArray = selectedFile.name.split('.');
		let extension = filenameArray[filenameArray.length - 1];
		if (extension !== 'xlsx' && extension !== 'xls') {
			alertService.addError(localizationService.getLocalizedString('importFile.excelFileFormatRequired'));
			isFileInvalid = true;
		}

		if (isFileInvalid) {
			setInvalidFileErrorMessage(localizationService.getLocalizedString('importFile.invalidFileErrorMessage'));
			return;
		}
		await uploadFiles();
	};

	const onFileChangeHandler = (event: any) => {
		event.preventDefault();

		const inputElement = event.target as HTMLInputElement;
		const files = inputElement.files as FileList;

		if (files == null || files.length < 1) {
			return;
		}

		setInvalidFileErrorMessage('');

		setSelectedFile(files[0]);
		urlService.removeUrlQueryParam('importFileId');
		setState({ ...state, fileName: files[0].name, importFileId: '' });
		clearSelfImportStepsWhenCurrentStepChanged();
	};

	const handleDrop = (files: any) => {
		setSelectedFile(files[0]);
		urlService.removeUrlQueryParam('importFileId');
		setState({ ...state, fileName: files[0].name, importFileId: '' });
		clearSelfImportStepsWhenCurrentStepChanged();
	};

	const shouldRenderEditConfigurationLink = () => {
		return (
			isAdministratorOrInternalUser() &&
			importConfigurations.find(
				x => !x.isDraft && !x.isSystem && x.importConfigurationId && x.importConfigurationId > 0
			)
		);
	};

	return (
		<StepperContent
			id={SelfImportSteps.STEP2}
			actions={
				<React.Fragment>
					<StepperAction
						type="button"
						className="btn btn-link"
						id="btnBack"
						onClick={() => {
							urlService.replaceUrlQueryString({ step: '1' });
							stepperContext.goAt(SelfImportSteps.STEP1);
						}}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction type="button" id="btnNext" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			{state.dataType && (
				<RadioGroup
					id="importChooseAFile"
					name="importChooseAFile"
					label={localizationService.getLocalizedString(
						'import.steps.step2Question',
						_.toLower(ImportUtils.getImportDataTypeWithSpaces(state.dataType))
					)}
					options={configurationOptions}
					value={state.importConfigurationId}
					isRequired={true}
					onChange={onConfigurationSelected}
					formHelp={
						shouldRenderEditConfigurationLink() ? (
							<a
								className="ai-form-help"
								id="editConfigurationLink"
								href={urlService.getSettingMenuPath(
									`self-import/data-source-settings/${state.dataType}`
								)}
								onClick={(e: any) =>
									navigateTo(
										history,
										urlService.getSettingMenuPath(
											`self-import/data-source-settings/${state.dataType}`
										),
										e
									)
								}>
								{localizationService.getLocalizedString(
									'import.steps.step2DataSourceSettingsEditMessage'
								)}
							</a>
						) : (
							undefined
						)
					}
				/>
			)}

			<DragAndDrop
				handleDrop={handleDrop}
				className="dashed-box-1"
				isValid={true}
				dragMessage={localizationService.getLocalizedString('import.steps.step2DragAndDrop')}>
				<div className="file-input-group p-2">
					<label className="btn ai-secondary" htmlFor="btnFileImport" aria-describedby="file-input-help">
						{localizationService.getLocalizedString('importFile.chooseFile')}
					</label>
					<input
						type="file"
						id="btnFileImport"
						name="btnFileImport"
						className="file-input mr-2"
						onChange={onFileChangeHandler}
						onClick={(e: any) => {
							e.target.value = null;
						}}
						style={{ width: '0px' }}
					/>
					{!_.isEmpty(state.fileName) ? (
						<label id="importFilename" className="font-size-14px-regular ml-2">
							{state.fileName}
						</label>
					) : (
						<label className="ml-4 pl-4">
							{localizationService.getLocalizedString('import.steps.step2DragAndDrop')}
						</label>
					)}
				</div>
			</DragAndDrop>
			<div className="ai-form-help">
				{invalidFileErrorMessage && (
					<span className="ai-required">{_.capitalize(invalidFileErrorMessage)} &nbsp;</span>
				)}
				{localizationService.getLocalizedString('import.steps.step2ChooseFileDescription')}
			</div>
		</StepperContent>
	);
};
