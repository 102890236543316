import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export interface UserProfileState {
	userProfile: ApiTypes.SimpleUserProfile;
}

export const initialUserProfileState: UserProfileState = {
	userProfile: {}
};

export enum UserProfileActionType {
	LoadUserProfileRequest = 'loadUserProfileRequest',
	LoadUserProfileSuccess = 'loadUserProfileSuccess'
}

export interface LoadUserProfileRequest extends Action {
	type: UserProfileActionType.LoadUserProfileRequest;
}

export interface LoadUserProfileRequest extends Action {
	type: UserProfileActionType.LoadUserProfileRequest;
}
export interface LoadUserProfileSuccess extends Action {
	type: UserProfileActionType.LoadUserProfileSuccess;
	userProfile: ApiTypes.SimpleUserProfile;
}

export type UserProfileAction = LoadUserProfileRequest | LoadUserProfileSuccess;

export const loadUserProfileRequest = (): LoadUserProfileRequest => ({
	type: UserProfileActionType.LoadUserProfileRequest
});

export const loadUserProfileSuccess = (userProfile: ApiTypes.SimpleUserProfile): LoadUserProfileSuccess => ({
	type: UserProfileActionType.LoadUserProfileSuccess,
	userProfile
});
