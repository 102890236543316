import React from 'react';
import { useDispatch } from 'react-redux';
import { localizationService, validationService, DateUtilService, navigateTo, Resource } from 'src/services';
import {
	TextAreaInput,
	SingleSelectDropdown,
	DateInput,
	PopoverModal,
	DeleteModal,
	TextInput
} from 'src/components/widgets';
import { DropDownOption, Violation, ViolationType, InspectionEvent, AuthorityUser, CleaningEvent } from '@rcp/types';
import {
	alertService,
	loadAuthorityViolationTypes,
	saveViolation,
	deleteViolation,
	loadCurrentInspection,
	useReduxSelector,
	loadFacilityInspection,
	saveFacilityViolation,
	authorityDeleteFacilityViolation,
	useRootStateSelector
} from 'src/redux';

import _ from 'lodash';
import { violationService } from './violation-service';
import { useHistory } from 'react-router-dom';
import { violationSlice } from './violation.slice';
import { nameof } from 'ts-simple-nameof';
import { authorityUsersSlice } from '../../shared/settings';
import { CleaningEditActionLink } from '../cleaning/cleaning-link-cell';
import { cleaningSlice } from 'src/components/authority/fog/cleaning';

export enum ViolationSourceType {
	Inspection = 'Inspection',
	Facility = 'Facility',
	FogDeviceCleaning = 'FogDeviceCleaning'
}

interface OwnProps {
	closeModal: () => void;
	facilityId?: number;
	sourceId?: number;
	sourceType?: string;
	violation?: Violation;
	saveCallback?: () => void;
	deleteCallback?: () => void;
	getAllViolations?: () => void;
	isFromViolationGrid?: boolean;
}

interface StateProps {}

type Props = StateProps & OwnProps;

interface RecordViolationFormState extends Violation {
	violationTypeIdError?: string;
	nonComplianceDateError?: string;
	complianceDueDateError?: string;
	inComplianceDateError?: string;
	assignedToUserProfileId?: number;
	authorityUserIdError?: string;
}

interface ViolationSourceState {
	violationSourceType: ViolationSourceType;
	inspectionEvent?: InspectionEvent;
	cleaningEvent?: CleaningEvent;
}

const initialViolationSourceState: ViolationSourceState = {
	violationSourceType: (null as unknown) as ViolationSourceType
};

export const RecordViolationForm: React.FC<Props> = props => {
	let initialValues = {
		nonComplianceDate: DateUtilService.floorDate(DateUtilService.getAuthorityTimezoneNowAsMoment())
	} as RecordViolationFormState;

	const [formState, setFormState] = React.useState<RecordViolationFormState>(initialValues);
	const [violationTypeOptionsValues, setViolationTypeOptionsValues] = React.useState([] as DropDownOption[]);
	const [showDeleteViolationModal, setShowDeleteViolationModal] = React.useState(false);
	const [violationSource, setViolationSource] = React.useState(initialViolationSourceState);
	const [authorityUserOptionsValues, setAuthorityUserOptionsValues] = React.useState([] as DropDownOption[]);

	const history = useHistory();
	const dispatch = useDispatch();
	const violationTypes = useReduxSelector(s => s.authoritySettingLookups.violationTypes);
	const inspectionEvent = useReduxSelector(s => s.inspections.currentInspection);
	const { result: authorityUsers } = useRootStateSelector(s => s.authorityUsers) as any;
	const currentUser = useReduxSelector(state => state.userProfile);
	const { selected: pumpOutEvent } = useRootStateSelector(state => state.cleanings);

	React.useEffect(() => {
		dispatch(loadAuthorityViolationTypes());
		dispatch(authorityUsersSlice.fetchAll());
	}, [dispatch]);

	React.useEffect(() => {
		if (violationTypes.length > 0) {
			let violationTypeOptions: DropDownOption[] = [];
			violationTypeOptions = violationTypes
				.filter(i => i.isActive)
				.map((violationType: ViolationType) => {
					return { label: violationType.name, value: violationType.violationTypeId };
				});
			setViolationTypeOptionsValues(violationTypeOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [violationTypes]);

	React.useEffect(() => {
		if (authorityUsers.length > 0) {
			let authorityUsersOptions: DropDownOption[] = [];
			authorityUsersOptions = authorityUsers
				.filter(i => i.isEnabled && !i.isAccountLocked && !i.isRemoved)
				.map((user: AuthorityUser) => {
					return { label: user.fullName, value: user.userProfileId };
				}) as DropDownOption[];

			let currentUserId = authorityUsersOptions.filter(
				(user: any) => user.value === currentUser.userProfile.userProfileId
			);
			if (props.violation === undefined) {
				if (currentUserId.length <= 0) {
					authorityUsersOptions.push({
						label: currentUser.userProfile.fullName || '',
						value: currentUser.userProfile.userProfileId
					});
				}
				setFormState({
					...formState,
					assignedToUserProfileId:
						currentUserId.length > 0 ? currentUserId[0].value : currentUser.userProfile.userProfileId
				});
			} else {
				const assignedUser = authorityUsersOptions.filter(
					(user: any) => props.violation && user.value === props.violation.assignedToUserProfileId
				);
				if (assignedUser.length <= 0) {
					authorityUsersOptions.push({
						label: props.violation.assignedToUserName || '',
						value: props.violation.assignedToUserProfileId
					});
				}
				if (currentUserId.length <= 0) {
					authorityUsersOptions.push({
						label: currentUser.userProfile.fullName || '',
						value: currentUser.userProfile.userProfileId
					});
				}
				setFormState({
					...formState,
					assignedToUserProfileId: props.violation.assignedToUserProfileId
				});
			}
			setAuthorityUserOptionsValues(authorityUsersOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorityUsers]);

	React.useEffect(() => {
		if (props.violation) {
			const { inComplianceDate, nonComplianceDate } = props.violation;
			let daysOpen: number = 0;
			if (nonComplianceDate) {
				const inCompliance = inComplianceDate ? inComplianceDate : DateUtilService.getAuthorityTimezoneNow();
				daysOpen = DateUtilService.daysBetweenDates(inCompliance, nonComplianceDate);
			}
			let newState = { ...formState, ...props.violation, daysOpen };
			setFormState(newState);

			if (
				String.equalCaseInsensitive(props.violation.sourceType, ViolationSourceType.Inspection) &&
				props.violation.sourceId
			) {
				if (props.facilityId && props.sourceId) {
					dispatch(loadFacilityInspection(props.facilityId, props.violation.sourceId));
				} else {
					dispatch(loadCurrentInspection(props.violation.sourceId));
				}
			}
			if (
				String.equalCaseInsensitive(props.violation.sourceType, ViolationSourceType.FogDeviceCleaning) &&
				props.violation.sourceId
			) {
				cleaningSlice.setApiUrlPath(
					Resource.FogFacilities + '/' + props.violation.organizationId + '/' + Resource.Cleanings
				);
				dispatch(cleaningSlice.fetchOne(props.violation.sourceId));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.violation]);

	React.useEffect(() => {
		if (inspectionEvent) {
			let sourceState = { ...violationSource };
			sourceState.inspectionEvent = inspectionEvent;
			setViolationSource(sourceState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inspectionEvent]);

	React.useEffect(() => {
		if (pumpOutEvent && props.violation) {
			let sourceState = { ...violationSource };
			sourceState.cleaningEvent = pumpOutEvent;
			setViolationSource(sourceState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pumpOutEvent]);

	const setFormStateFromProps = (violation: RecordViolationFormState) => {
		let newState = { ...violation, ...{ sourceId: props.sourceId, sourceType: props.sourceType } };
		setFormState(newState);
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState, ...{ sourceId: props.sourceId, sourceType: props.sourceType } };
		const { name, value } = e.target;
		_.set(newState, name, value);
		let daysOpen: number = 0;

		if (
			String.equalCaseInsensitive(
				name,
				nameof<Violation>(s => s.inComplianceDate)
			)
		) {
			let { nonComplianceDate } = formState;
			let inCompliance = value ? value : DateUtilService.getAuthorityTimezoneNow();
			let nonCompliance = nonComplianceDate;
			daysOpen = nonCompliance ? DateUtilService.daysBetweenDates(inCompliance, nonCompliance) : 0;
			_.set(newState, 'daysOpen', daysOpen);
		} else if (
			String.equalCaseInsensitive(
				name,
				nameof<Violation>(s => s.nonComplianceDate)
			)
		) {
			let { inComplianceDate } = formState;
			let nonCompliance = value;
			let inCompliance = inComplianceDate ? inComplianceDate : DateUtilService.getAuthorityTimezoneNow();
			daysOpen = nonCompliance ? DateUtilService.daysBetweenDates(inCompliance, nonCompliance) : 0;
			_.set(newState, 'daysOpen', daysOpen);
		}

		setFormState(newState);
	};

	const cancelClicked = () => {
		if (props.violation) setFormStateFromProps(props.violation);
		else {
			setFormStateFromProps({} as Violation);
		}
		props.closeModal();
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };
		validationService.validateRequiredField(
			newState,
			'violationTypeId',
			'violationTypeIdError',
			'violation.violationType'
		);
		validationService.validateRequiredDateField(
			newState,
			'nonComplianceDate',
			'nonComplianceDateError',
			'violation.nonComplianceDate'
		);

		validationService.validateMinimumDate(
			newState,
			'complianceDueDate',
			'complianceDueDateError',
			localizationService.getLocalizedString('violation.complianceDueDate')
		);

		validationService.validateMinimumDate(
			newState,
			'inComplianceDate',
			'inComplianceDateError',
			localizationService.getLocalizedString('violation.inComplianceDate')
		);

		let isFormValid =
			!validationService.hasError(newState, 'nonComplianceDateError', 'violationTypeIdError') &&
			!validationService.hasError(newState, 'complianceDueDateError', 'inComplianceDateError');

		if (newState.nonComplianceDate) {
			if (DateUtilService.isAfterToday(newState.nonComplianceDate)) {
				isFormValid = false;
				newState.nonComplianceDateError = localizationService.getLocalizedString(
					'violation.nonComplianceDateTooLate'
				);
			}

			if (
				newState.complianceDueDate &&
				DateUtilService.isBefore(newState.complianceDueDate, newState.nonComplianceDate, 'day')
			) {
				isFormValid = false;
				newState.complianceDueDateError = localizationService.getLocalizedString(
					'violation.complianceDueDateTooEarly'
				);
			}
			if (
				newState.inComplianceDate &&
				DateUtilService.isBefore(newState.inComplianceDate, newState.nonComplianceDate, 'day')
			) {
				isFormValid = false;
				newState.inComplianceDateError = localizationService.getLocalizedString(
					'violation.inComplianceDateTooEarly'
				);
			}
		}

		setFormState(newState);
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFormValid;
	};

	const saveClicked = async () => {
		if (!isFormValidateForSave()) {
			return;
		}

		let violationToSave: Violation = { ...formState };
		let saveSucceed;
		if (props.facilityId) {
			saveSucceed = await dispatch(saveFacilityViolation(props.facilityId, violationToSave));
		} else {
			saveSucceed = await dispatch(saveViolation(violationToSave));
		}

		if (props.getAllViolations) {
			props.getAllViolations();
		}

		if (props.isFromViolationGrid) {
			dispatch(violationSlice.fetchAll());
		}

		if (saveSucceed.toString() === true.toString()) {
			props.closeModal();
		}

		if (props.saveCallback) {
			props.saveCallback();
		}
	};

	const deleteClicked = async () => {
		setShowDeleteViolationModal(false);
		if (props.violation && props.violation.violationId) {
			let facilityId = props.violation.organizationId;
			if (!facilityId) {
				throw new Error('Invalid organizationId on inspection');
			}

			if (props.violation) {
				let response: any = false;
				if (props.facilityId) {
					response = await dispatch(authorityDeleteFacilityViolation(props.facilityId, props.violation));
				} else {
					response = await dispatch(deleteViolation(props.violation));
				}
				if (response === true) {
					props.closeModal();

					if (props.deleteCallback) {
						props.deleteCallback();
					}

					if (props.getAllViolations) {
						props.getAllViolations();
					}

					if (props.isFromViolationGrid) {
						dispatch(violationSlice.reload());
					}
				}
			}
		}
	};

	const modalFooterDiv = () => {
		if (props.violation && props.violation.violationId) {
			let fogFacilityId = props.violation.organizationId;
			if (!fogFacilityId) {
				throw new Error('Invalid Violation');
			}
		}
		return (
			<>
				{props.violation && props.violation.violationId && (
					<button
						type="button"
						className="btn ai-secondary-delete"
						onClick={() => {
							setShowDeleteViolationModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</button>
				)}

				<div className="ml-auto">
					<button className="btn ai-save" onClick={saveClicked}>
						{localizationService.getLocalizedString('screen.buttons.save')}
					</button>
					<button className="btn ai-white ml-2" onClick={cancelClicked}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</button>
				</div>
			</>
		);
	};

	let violationSourceUrl = '';
	let violationSourceText = '';
	if (props.violation) {
		violationSourceUrl = violationService.getViolationSourceUrl(props.violation, props.facilityId);
		violationSourceText = violationService.getViolationSourceUrlText(
			props.violation,
			inspectionEvent,
			violationSource.cleaningEvent ? violationSource.cleaningEvent.dueDate : ''
		);
	}

	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={localizationService.getLocalizedString(
					props.violation && props.violation.violationId
						? 'violation.editViolation'
						: 'violation.recordViolation'
				)}
				footer={modalFooterDiv()}
				save={saveClicked}
				cancel={cancelClicked}>
				<p>{localizationService.getLocalizedString('violation.recordAViolationDesc')}</p>
				{props.violation && props.violation.sourceId && (
					<p>
						{`${localizationService.getLocalizedString('violation.sourceOfNoCompliance')}: `}
						{String.equalCaseInsensitive(
							props.violation.sourceType,
							ViolationSourceType.FogDeviceCleaning
						) ? (
							<CleaningEditActionLink
								isTriggeredFromViolation
								violationText={violationSourceText}
								pumpOutEvent={pumpOutEvent || {}}
							/>
						) : (
							<a
								href={violationSourceUrl}
								onClick={(e: any) => {
									navigateTo(history, violationSourceUrl, e);
								}}>
								{violationSourceText}
							</a>
						)}
					</p>
				)}
				<SingleSelectDropdown
					id="violationTypeId"
					name="violationTypeId"
					label={localizationService.getLocalizedString('violation.violationType')}
					value={_.toString(formState.violationTypeId)}
					onChange={changeFormState}
					options={violationTypeOptionsValues}
					isRequired={true}
					error={formState.violationTypeIdError}
				/>

				<DateInput
					id="nonComplianceDate"
					name="nonComplianceDate"
					label={localizationService.getLocalizedString('violation.nonComplianceDate')}
					isRequired={true}
					max={DateUtilService.getAuthorityTimezoneNow()}
					value={formState.nonComplianceDate}
					onChange={changeFormState}
					error={formState.nonComplianceDateError}
				/>

				<DateInput
					id="complianceDueDate"
					name="complianceDueDate"
					label={localizationService.getLocalizedString('violation.complianceDueDate')}
					min={formState.nonComplianceDate ? formState.nonComplianceDate : undefined}
					value={formState.complianceDueDate}
					onChange={changeFormState}
					error={formState.complianceDueDateError}
				/>

				<DateInput
					id="inComplianceDate"
					name="inComplianceDate"
					min={formState.nonComplianceDate ? formState.nonComplianceDate : undefined}
					label={localizationService.getLocalizedString('violation.inComplianceDate')}
					value={formState.inComplianceDate}
					onChange={changeFormState}
					error={formState.inComplianceDateError}
				/>
				<SingleSelectDropdown
					id="assignedToUserProfileName"
					name="assignedToUserProfileId"
					label={localizationService.getLocalizedString('violation.assignedToUserName')}
					value={_.toString(formState.assignedToUserProfileId)}
					onChange={changeFormState}
					options={authorityUserOptionsValues}
					error={formState.authorityUserIdError}
				/>
				{props.violation ? (
					<TextInput
						id="daysOpen"
						name="daysOpen"
						isDisabled={true}
						label={localizationService.getLocalizedString('violation.daysOpen')}
						value={`${formState.daysOpen}`}
					/>
				) : null}

				<TextAreaInput
					id="comments"
					name="comments"
					label={localizationService.getLocalizedString('screen.labels.comments')}
					value={formState.comments}
					onChange={changeFormState}
					isFullWidth={true}
					rows={7}
				/>
			</PopoverModal>
			{showDeleteViolationModal && (
				<DeleteModal
					key="confirmDeleteViolationModal"
					title={localizationService.getLocalizedString('violation.deleteModalTitle')}
					message={localizationService.getLocalizedString('violation.deleteModalMessage')}
					showModal={showDeleteViolationModal}
					onCancelButtonClick={() => {
						setShowDeleteViolationModal(false);
					}}
					onOkayButtonClick={deleteClicked}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};
