import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export interface AuthenticationState {
	isAuthenticating?: boolean;
	forwardSubDomain?: string;
	signInResult: ApiTypes.SignInResult;
	errors: ApiTypes.ApiError[];
}

export const initialAuthenticationState: AuthenticationState = {
	isAuthenticating: true,
	signInResult: {
		allowedPortals: []
	},
	errors: []
};

export enum AuthenticationActionType {
	AuthenticationRequest = 'authenticationRequest',
	AuthenticationSuccess = 'authenticationSuccess',
	AuthenticationError = 'authenticationError'
}

export interface AuthenticationRequest extends Action {
	type: AuthenticationActionType.AuthenticationRequest;
}

export interface AuthenticationSuccess extends Action {
	type: AuthenticationActionType.AuthenticationSuccess;
	signInResult: ApiTypes.SignInResult;
}

export interface AuthenticationError extends Action {
	type: AuthenticationActionType.AuthenticationError;
	error: ApiTypes.ApiError;
}

export type AuthenticationAction = AuthenticationRequest | AuthenticationSuccess | AuthenticationError;

export const authenticationRequest = (): AuthenticationRequest => ({
	type: AuthenticationActionType.AuthenticationRequest
});

export const authenticationSuccess = (signInResult: ApiTypes.SignInResult): AuthenticationSuccess => ({
	type: AuthenticationActionType.AuthenticationSuccess,
	signInResult
});

export const authenticationError = (error: ApiTypes.ApiError): AuthenticationError => ({
	type: AuthenticationActionType.AuthenticationError,
	error
});
