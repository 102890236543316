import React from 'react';
import { localizationService, Resource } from 'src/services';
import { useDispatch } from 'react-redux';
import { CccHazard, CccSiteContact, Dictionary } from '@rcp/types';
import { ContactModal } from 'src/features';
import { SiteContactCard } from './site-contact-card';
import { RestApi } from 'src/services/rest.api';
import _ from 'lodash';

interface OwnProps {
	siteId: number;
}

type Props = OwnProps;

export interface SiteDisplayContact extends CccSiteContact {
	hazardContacts: CccSiteContact[];
	siteOnlyContact?: CccSiteContact;
}

export const SiteContactsTab: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [contactList, setContactList] = React.useState<SiteDisplayContact[]>([]);

	let hazardApi: RestApi<CccHazard> = new RestApi<CccHazard>(Resource.CccHazards);
	let contactApi: RestApi<CccSiteContact> = new RestApi<CccSiteContact>(Resource.CccLinkedContacts);

	const mergeContacts = (hazardList: CccHazard[], cccSiteContacts: CccSiteContact[]) => {
		let contacts: SiteDisplayContact[] = _.map(cccSiteContacts, item => ({
			...item,
			hazardContacts: []
		}));

		let contactMap: Dictionary<SiteDisplayContact> = {};
		for (let contact of contacts) {
			// take out site contact
			let mapKey = (contact.contactType ? contact.contactType : '') + '-' + contact.contactId;

			if (contact.sendNotices === true && contact.hazardId && hazardList) {
				let hazard = hazardList.find(h => h.hazardId === contact.hazardId);
				if (hazard && hazard.sendTestNotices !== true) {
					contact.sendNotices = false;
				}
			}

			if (!contactMap.hasOwnProperty(mapKey)) {
				contactMap[mapKey] = { ...contact };
			} else {
				let mapItem = contactMap[mapKey];
				mapItem.sendNotices = mapItem.sendNotices || contact.sendNotices;
			}
			if (contact.hazardId) {
				contactMap[mapKey].hazardContacts.push(contact);
			} else {
				contactMap[mapKey].siteOnlyContact = contact;
			}
		}

		let mergedContacts = Object.keys(contactMap)
			.sort()
			.map(key => contactMap[key]);

		setContactList(mergedContacts);
	};

	const [showAddContactModal, setShowAddContactModal] = React.useState(false);
	const onToggleAddModal = (e: any) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		setShowAddContactModal(!showAddContactModal);
	};

	const loadSiteContacts = () => {
		Promise.all([
			hazardApi.getAll(`siteId=${props.siteId}&includes=hazardId,sendTestNotices`),
			contactApi.getAll(`siteId=${props.siteId}`)
		]).then(results => {
			let hazards = results[0].result;
			let contacts: CccSiteContact[] = results[1] ? ((results[1] as unknown) as CccSiteContact[]) : [];
			mergeContacts(hazards, contacts);
		});
	};

	React.useEffect(() => {
		loadSiteContacts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, props.siteId]);

	return (
		<>
			<div className="row">
				{contactList &&
					contactList.length > 0 &&
					contactList.map((item: SiteDisplayContact, index: number) => (
						<SiteContactCard contact={item} key={index} reload={loadSiteContacts} />
					))}
			</div>
			<a href="#/" id="addContact" className="ai-add-link" onClick={onToggleAddModal}>
				{localizationService.getLocalizedString('screen.buttons.addContact')}&#8230;
			</a>
			{showAddContactModal && (
				<ContactModal
					close={() => {
						setShowAddContactModal(false);
					}}
					reload={loadSiteContacts}
					linkedContact={{
						siteId: props.siteId
					}}
				/>
			)}
		</>
	);
};
