import React, { FC } from 'react';
import { History } from 'history';
import { ippParameterGroupsSlice } from './parameter-groups.slice';
import * as LinkoTypes from '@rcp/types';
import { IppReportPackageParameterGroup, ColumnField, FilterType, LocalStorageName } from '@rcp/types';
import { RestSlice } from '../../../../../redux/rest.slice';
import { DataGrid, GridOption } from 'src/features/data-grid/data-grid';
import { RootState } from 'src/redux';
import { localizationService, urlService, Resource, navigateTo } from 'src/services';
import { Utils } from 'src/services/utils';

interface Props<T> extends LinkoTypes.RouteProps {
	history: History;
	gridOption: GridOption;
	restSlice: RestSlice<T>;
}

const IppReportPackageParameterGroupsComponent: FC<Props<IppReportPackageParameterGroup>> = props => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackageParameterGroup;
		const url = urlService.getIppAuthorityUrl(
			`${Resource.IppReportPackageParameterGroups}/${dataItem.parameterGroupId}/details`
		);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const convertToActiveInactive = (item: any) => {
		let parameter = item.dataItem as IppReportPackageParameterGroup;
		return (
			<td>
				{parameter.isActive
					? localizationService.getLocalizedString('ipp.reportPackage.templates.activeTitle')
					: localizationService.getLocalizedString('ipp.reportPackage.templates.inactiveTitle')}
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showActions: false,
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.reportPackage.parameterGroup.columns',
		storageName: LocalStorageName.IppReportPackageParameterGroupsGrid,
		searchPlaceholder: '',
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.parameterGroup.title'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: true,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('ipp.buttons.createNew'),
		toggleAddModal: () => {
			const url = urlService.getIppAuthorityUrl(`${Resource.IppReportPackageParameterGroups}/new`);
			navigateTo(props.history, url);
		},
		showScheduleInspectionModal: false,
		queryParameters: { includeInactive: 'true', sort: 'name asc' },
		defaultSort: [{ field: 'name', dir: 'asc' }],
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('description'),
			new ColumnField('isActive', FilterType.Boolean, convertToActiveInactive),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date, Utils.formatDate),
			new ColumnField('lastModifierFullName')
		],

		defaultColumns: ['name', 'description', 'lastModificationDateTimeLocal', 'lastModifierFullName', 'isActive']
	};
	const parameterGroupsState = (state: RootState) => state.ippReportPackageParameterGroups;

	return (
		<>
			<DataGrid
				history={props.history}
				match={props.match}
				restSlice={ippParameterGroupsSlice}
				restState={parameterGroupsState}
				gridOption={initialGridOption}></DataGrid>
		</>
	);
};

export default IppReportPackageParameterGroupsComponent;
