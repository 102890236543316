import * as ApiTypes from '@rcp/types';
import { ThunkAction } from 'redux-thunk';
import { alertService } from 'src/redux/alert';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import * as Industries from './industries-actions';
import { IndustriesState } from './industries-state';

type IndustriesThunkAction = ThunkAction<any, IndustriesState, any, Industries.IndustriesAction>;

export const loadIndustryDetails = (ippIndustryId: number): IndustriesThunkAction => async (dispatch, getState) => {
	let totalTemplates: ApiTypes.IppReportPackageTemplate[] = [];
	let assignedTemplates: ApiTypes.IppReportPackageTemplate[] = [];
	let availableTemplates: ApiTypes.IppReportPackageTemplate[] = [];
	let totalParameterGroups: ApiTypes.IppReportPackageParameterGroup[] = [];
	let assignedParameterGroups: ApiTypes.IppReportPackageParameterGroup[] = [];
	let availableParameterGroups: ApiTypes.IppReportPackageParameterGroup[] = [];
	dispatch(Industries.loadIndustryDetailsRequest());
	let url = urlService.getAuthorityResourceApiUrl(
		Resource.IppIndustries,
		ippIndustryId,
		'showAssignedReportPackageTemplatesAndParameterGroups=true'
	);
	let industry = await apiService.getResource<ApiTypes.IppIndustry>(url);
	if (urlService.isIppAuthorityPortal()) {
		url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackageTemplates, 'includeInactive=true');
		totalTemplates = await apiService.getResource<ApiTypes.IppReportPackageTemplate[]>(url);
		assignedTemplates = industry.assignedReportPackageTemplates ? industry.assignedReportPackageTemplates : [];
		availableTemplates = getAvailableTemplates(
			Array.isArray(totalTemplates) ? totalTemplates : (totalTemplates as any).result,
			assignedTemplates
		);
		url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackageParameterGroups, 'includeInactive=true');
		totalParameterGroups = await apiService.getResource<ApiTypes.IppReportPackageParameterGroup[]>(url);
		assignedParameterGroups = industry.assignedParameterGroups ? industry.assignedParameterGroups : [];
		availableParameterGroups = getAvailableParameterGroups(
			Array.isArray(totalParameterGroups) ? totalParameterGroups : (totalParameterGroups as any).result,
			assignedParameterGroups
		);
	}
	dispatch(Industries.loadIndustryDetailsSuccess(industry, availableTemplates, availableParameterGroups));
};

export const updateIndustry = (
	industry: ApiTypes.IppIndustry,
	ippIndustryId?: number,
	callbackOnSuccess?: any,
	isPatchCall: boolean = true
): IndustriesThunkAction => async (dispatch, getState) => {
	const statusText = industry.isEnabled ? 'enabled' : 'disabled';
	const url = urlService.getAuthorityResourceApiUrl(Resource.IppIndustries, ippIndustryId as number);
	if (isPatchCall) {
		await apiService.httpPatch(url, industry);
		alertService.addSuccess(
			localizationService.getLocalizedString('ipp.industries.changeStatusMessage', statusText)
		);
	} else {
		await apiService.httpPut(url, industry);
		alertService.addSuccess(localizationService.getLocalizedString('ipp.industries.updateSuccessfulMessage'));
	}
	// after update the Industry load details/list again
	dispatch(loadIndustryDetails(ippIndustryId as number));
	callbackOnSuccess();
	return true;
};

const getAvailableTemplates = (
	total: ApiTypes.IppReportPackageTemplate[],
	selected: ApiTypes.IppReportPackageTemplate[]
): ApiTypes.IppReportPackageTemplate[] => {
	let selectedIds = selected.map(({ reportPackageTemplateId }) => reportPackageTemplateId);
	return total.filter(({ reportPackageTemplateId }) => !selectedIds.includes(reportPackageTemplateId));
};

const getAvailableParameterGroups = (
	total: ApiTypes.IppReportPackageParameterGroup[],
	selected: ApiTypes.IppReportPackageParameterGroup[]
): ApiTypes.IppReportPackageParameterGroup[] => {
	let selectedIds = selected.map(({ parameterGroupId }) => parameterGroupId);
	return total.filter(({ parameterGroupId }) => !selectedIds.includes(parameterGroupId));
};
