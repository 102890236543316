import { ThunkAction } from 'redux-thunk';
import { apiService, localizationService, urlService } from 'src/services';
import { IppPendingRegistrationUserState } from './pending-registration-user-state';
import EIppPendingRegistrationUserActionType from './pending-registration-user-action-types';
import { Resource } from 'src/services';
import { alertService } from 'src/redux/alert';
import { NotificationTypes, UserTypes } from 'src/constants';

export interface IppPendingRegistrationUserDataAction {
	type: EIppPendingRegistrationUserActionType;
	payload?: IppPendingRegistrationUserState;
}

type FetchIppPendingRegistrationUserDataThunkAction = ThunkAction<
	any,
	IppPendingRegistrationUserState,
	any,
	IppPendingRegistrationUserDataAction
>;

const waitTimeBeforeRedirect = 500;

const getPendingRegistrationListPath = (): string => {
	let routeNavList = window.location.pathname.split('/');
	return routeNavList.splice(0, routeNavList.length - 2).join('/');
};

export const fetchIppPendingRegistrationListActionCreator = (
	currentPendingRegistrationsState: IppPendingRegistrationUserState,
	userId: string
): FetchIppPendingRegistrationUserDataThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppPendingRegistrationUserDetail.replace(':userId', userId)
	);
	try {
		const ippPendingRegistrationResponse = await apiService.getResource<any>(url);
		if (!('ippUserId' in ippPendingRegistrationResponse)) {
			if ('message' in ippPendingRegistrationResponse) {
				alertService.addError(ippPendingRegistrationResponse.message);
			} else {
				alertService.addError(localizationService.getLocalizedString('ipp.errors.apiRequestFailed'));
			}
			return;
		} else {
			dispatch({
				type: EIppPendingRegistrationUserActionType.FetchIppPendingRegistrationUserData,
				payload: { ...currentPendingRegistrationsState, data: ippPendingRegistrationResponse }
			});
		}
	} catch (e) {
		e.statusCode === 403 && alertService.clearAllMessages();
	}
};

export const updateIppPendingRegistrationUserActionCreator = (
	updatedPendingRegistrationUserState: IppPendingRegistrationUserState
): FetchIppPendingRegistrationUserDataThunkAction => async (dispatch, getState) => {
	dispatch({
		type: EIppPendingRegistrationUserActionType.UpdateIppPendingRegistrationUserData,
		payload: { ...updatedPendingRegistrationUserState }
	});
};

export const approveIppPendingRegistrationUserActionCreator = (
	updatedPendingRegistrationUserState: IppPendingRegistrationUserState,
	userId: string,
	history: History
): FetchIppPendingRegistrationUserDataThunkAction => async (dispatch, getState) => {
	const { protocol, host, href } = window.location;
	let domain = host.replace(urlService.getSubDomain(href) + '.', '');
	const url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppPendingRegistrationUserDetail.replace(':userId', userId)
	);
	let notification = { msg: '', type: NotificationTypes.success };

	const userSelectedRole = updatedPendingRegistrationUserState.data.role;

	await apiService.httpPatch(url, {
		isRegistrationApproved: true,
		isAdministrator: userSelectedRole && userSelectedRole.toLowerCase() === UserTypes.admin.toLowerCase(),
		isSignatory: updatedPendingRegistrationUserState.data.isSignatory,
		urlScheme: protocol.split(':')[0],
		urlDomain: domain
	});
	notification = {
		msg: localizationService.getLocalizedString('ipp.pendingRegistrationUser.approveAPISuccess'),
		type: NotificationTypes.success
	};
	dispatch({
		type: EIppPendingRegistrationUserActionType.ApprovePendingRegistration,
		payload: { ...updatedPendingRegistrationUserState, notification }
	});
	// Redirect to Pending Registrations after 500ms
	setTimeout(() => {
		(history as any).replace(getPendingRegistrationListPath());
	}, waitTimeBeforeRedirect);
	return true;
};

export const denyIppPendingRegistrationUserActionCreator = (
	updatedPendingRegistrationUserState: IppPendingRegistrationUserState,
	userId: string,
	history: History
): FetchIppPendingRegistrationUserDataThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(
		Resource.IppPendingRegistrationUserDetail.replace(':userId', userId)
	);
	let notification = { msg: '', type: NotificationTypes.success };

	const userSelectedRole = updatedPendingRegistrationUserState.data.role;
	await apiService.httpPatch(url, {
		isRegistrationDenied: true,
		isAdministrator: userSelectedRole && userSelectedRole.toLowerCase() === 'administrator' ? true : false
	});
	notification = {
		msg: localizationService.getLocalizedString('ipp.pendingRegistrationUser.denyAPISuccess'),
		type: NotificationTypes.success
	};

	dispatch({
		type: EIppPendingRegistrationUserActionType.DenyPendingRegistration,
		payload: { ...updatedPendingRegistrationUserState, notification }
	});

	// Redirect to Pending Registrations after 500ms
	setTimeout(() => {
		(history as any).replace(getPendingRegistrationListPath());
	}, waitTimeBeforeRedirect);
	return true;
};
