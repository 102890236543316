import _ from 'lodash';
import React from 'react';
import {
	PopoverModal,
	TextInput,
	SearchableComboBox,
	SingleCheckbox,
	SingleSelectDropdown,
	DeleteModal
} from '../../components/widgets';
import { alertService, settingJurisdictionsSlice, useReduxSelector, useRootStateSelector } from 'src/redux';
import {
	CccSiteContact,
	DropDownOption,
	NumberType,
	Jurisdiction,
	Contact,
	ApiError,
	RegulatoryProgramName,
	EntityContact,
	EntityContact_EntityType,
	FeatureNames,
	TranslationLanguage
} from '@rcp/types';
import { useDispatch } from 'react-redux';
import {
	validationService,
	localizationService,
	UtilService,
	urlService,
	Resource,
	apiService,
	optionsMap,
	Logger
} from 'src/services';
import {
	cccHazardContactsSlice,
	cccSiteContactsSlice,
	contactsSlice,
	settingLookupsContactTypesSlice
} from './contacts.slice';
import {
	extractJsonEmails,
	extractJsonNumbers,
	getContactDisplayName,
	getContactSearchableText
} from './contacts.service';
import { entityContactsSlice } from './entity-contacts.slice';
import { facilityContactsSlice } from 'src/components/authority/fog/facilities/facility-contacts-slice';
import { FacilityEntityContact } from '@rcp/types/src';
import { loadLanguages } from 'src/redux/languages/languages';

interface Props {
	linkedContact?: CccSiteContact;
	associatedContacts?: CccSiteContact[] | EntityContact[];
	entityContact?: EntityContact;
	close: (e: any) => void;
	reload?: () => void;
}

interface FormFields extends CccSiteContact {
	initialized?: boolean;
	email?: string;
	phoneNumber?: string;
	mobileNumber?: string;
	faxNumber?: string;
	emergencyNumber?: string;
	pagerNumber?: string;

	fieldSetError?: string;
	contactTypeIdError?: string;
	emailFormatError?: string;
	websiteAddressError?: string;
}

const initialFormFields: FormFields = {
	initialized: false,
	firstName: '',
	lastName: '',
	title: '',
	companyName: '',
	addressLine1: '',
	addressLine2: '',
	addressCity: '',
	addressJurisdictionCode: '',
	addressZipCode: '',
	comments: '',
	websiteAddress: '',
	email: '',
	phoneNumber: '',
	mobileNumber: '',
	faxNumber: '',
	emergencyNumber: '',
	pagerNumber: '',

	contactType: ''
};

export const ContactModal: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const [formState, setFormState] = React.useState(initialFormFields);
	const [contactTypeOptionValues, setContactTypeOptionValues] = React.useState([] as DropDownOption[]);
	const [stateProvinceOptionValues, setStateProvinceOptionValues] = React.useState([] as DropDownOption[]);
	const [languageOptionValues, setLanguageOptionValues] = React.useState([] as DropDownOption[]);
	const [searchableContacts, setSearchableContacts] = React.useState<Contact[]>([]);
	const [isAddMode, setIsAddMode] = React.useState(false);
	const [isToggleRemoveAssociationModal, setIsToggleRemoveAssociationModal] = React.useState(false);
	const [isTogglePermanentRemoveModal, setIsTogglePermanentRemoveModal] = React.useState(false);

	const featureFlags = useReduxSelector(state => state.featureSettings.featureFlagSettings);
	const languages = useRootStateSelector(s => s.languages).languages;

	const getNewFormFieldState = (fromContact: Contact): FormFields => {
		const newState: FormFields = { ...fromContact };

		let emails = extractJsonEmails(fromContact.jsonEmails);
		if (emails && emails.length > 0) {
			newState.email = emails[0].email;
		}
		let phones = extractJsonNumbers(fromContact.jsonNumbers);
		if (phones) {
			if (phones.business) {
				newState.phoneNumber = phones.business.number;
			} else if (phones.others && phones.others.length > 0) {
				newState.phoneNumber = phones.others[0].number;
			}

			if (phones.cell) {
				newState.mobileNumber = phones.cell.number;
			}
		}
		return newState;
	};

	const jurisdictions = useRootStateSelector(s => s.settingJurisdictions.result);
	const contactTypes = useRootStateSelector(s => s.settingContactTypes.result);
	const authorityContacts = useRootStateSelector(s => s.authorityContacts.result);
	const isAccessingBackflow = urlService.isProgram(RegulatoryProgramName.Backflow);
	const isAccessingFog = urlService.isProgram(RegulatoryProgramName.FOG);

	React.useEffect(() => {
		if (isAccessingBackflow && props.linkedContact) {
			setIsAddMode(UtilService.isNullOrEmpty(props.linkedContact.linkedContactId));
			return;
		}
		if (isAccessingFog && props.entityContact) {
			setIsAddMode(UtilService.isNullOrEmpty(props.entityContact.entityContactId));
			if (EntityContact_EntityType.FogDevice === props.entityContact.entityType) {
				entityContactsSlice.setApiUrlPath(
					Resource.FogDevices + '/' + props.entityContact.entityId + '/' + Resource.Contacts
				);
				return;
			}
			if (EntityContact_EntityType.FogFacility === props.entityContact.entityType) {
				facilityContactsSlice.setApiUrlPath(
					Resource.FogFacilities + '/' + props.entityContact.entityId + '/' + Resource.Contacts
				);
				return;
			}
		}
	}, []);

	React.useEffect(() => {
		if (formState.initialized !== true && props.linkedContact) {
			let newFormState = getNewFormFieldState(props.linkedContact);
			newFormState.initialized = true;
			setFormState(newFormState);
		}
		if (formState.initialized !== true && props.entityContact) {
			let newFormState = getNewFormFieldState(props.entityContact);
			newFormState.initialized = true;
			setFormState(newFormState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.linkedContact]);

	React.useEffect(() => {
		if (_.isEmpty(jurisdictions)) {
			dispatch(settingJurisdictionsSlice.fetchAll());
		}
		if (_.isEmpty(languages)) {
			dispatch(loadLanguages());
		}
		dispatch(settingLookupsContactTypesSlice.fetchAll());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	React.useEffect(() => {
		if (isAddMode) {
			dispatch(
				contactsSlice.fetchAll(
					`includes=contactId,firstName,lastName,companyName,jsonEmails,jsonNumbers,isBusiness`
				)
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAddMode]);

	React.useEffect(() => {
		let contactTypeOptionValues: DropDownOption[] = [];
		if (contactTypes && contactTypes.length > 0 && props.linkedContact) {
			contactTypeOptionValues = optionsMap.fromLookups(
				contactTypes,
				props.linkedContact.contactTypeId,
				props.linkedContact.contactType
			);
		}
		if (contactTypes && contactTypes.length > 0 && props.entityContact) {
			contactTypeOptionValues = optionsMap.fromLookups(
				contactTypes,
				props.entityContact.contactTypeId,
				props.entityContact.contactTypeCode
			);
		}

		setContactTypeOptionValues(contactTypeOptionValues);
	}, [contactTypes, props.linkedContact, props.entityContact]);

	React.useEffect(() => {
		if (jurisdictions && jurisdictions.length > 0) {
			let optionValues: DropDownOption[] = [];
			optionValues = jurisdictions.map((jurisdiction: Jurisdiction, index: number) => {
				return { label: jurisdiction.name, value: jurisdiction.jurisdictionId };
			});
			setStateProvinceOptionValues(optionValues);
		}
	}, [jurisdictions]);

	React.useEffect(() => {
		if (languages && languages.length > 0) {
			let optionValues: DropDownOption[] = [];
			optionValues = languages.map((language: TranslationLanguage, index: number) => {
				return { label: language.name, value: language.systemLanguageId as number };
			});
			setLanguageOptionValues(optionValues);
		}
	}, [languages]);

	React.useEffect(() => {
		if (!authorityContacts || _.isEmpty(authorityContacts)) {
			return;
		}

		let addedContactIds: number[] = [];
		if (props.associatedContacts && props.associatedContacts.length > 0) {
			addedContactIds = props.associatedContacts.map(c => c.contactId as number);
		}

		let filteredContactList = authorityContacts.filter(a => a.contactId && !addedContactIds.includes(a.contactId));
		let mappedContactList = filteredContactList
			.map(c => {
				return {
					...c,
					searchableText: getContactSearchableText(c)
				};
			})
			.filter(c => c.searchableText && c.searchableText.length > 0) as Contact[];

		mappedContactList = _.uniqBy(mappedContactList, 'contactId');
		setSearchableContacts(mappedContactList);
	}, [authorityContacts, props.associatedContacts, props.linkedContact, props.entityContact]);

	const isContactTypeValid = (object: any): boolean => {
		if (_.isUndefined(object['contactTypeId']) || object['contactTypeId'] < 1) {
			object['contactTypeIdError'] = 'Field is required';
			return false;
		} else {
			_.unset(object, 'contactTypeIdError');
			return true;
		}
	};

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (e.target.type === 'checkbox') {
			value = e.target.checked;
		}

		_.set(newState, name, value);

		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		isContactTypeValid(newState);

		validationService.validateUrl(newState.websiteAddress as string, newState, 'websiteAddressError');
		validationService.validateEmailFormatField(newState, 'email', 'emailFormatError');

		if (
			!validationService.validateFieldSetHavingAtLeastOneValue(
				newState,
				'firstName',
				'lastName',
				'companyName',
				'addressLine1'
			)
		) {
			newState.fieldSetError = localizationService.getLocalizedString('contacts.missingFieldsValue');
		} else {
			_.unset(newState, 'fieldSetError');
		}
		const isFormValid = !validationService.hasError(
			newState,
			'contactTypeIdError',
			'emailFormatError',
			'fieldSetError',
			'websiteAddressError'
		);
		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			setFormState(newState);
			return false;
		}
		return true;
	};

	const setFormBadRequestFieldsError = (error: ApiError) => {
		if (!(error && error.message && error.errorFields && error.statusCode === 400)) {
			return;
		}
		let newState = { ...formState };
		error.errorFields.forEach((errorField: string) => {
			let key = errorField + 'Error';
			key = UtilService.toCamelCase(key);
			_.set(newState, key, error.message);
		});
		setFormState(newState);
	};

	const saveContact = async (e: any) => {
		if (!isFormValidateForSave()) {
			return;
		}
		if (isAccessingFog && props.entityContact) {
			let contactSlice: any = null;
			if (props.entityContact.entityType === EntityContact_EntityType.FogFacility) {
				contactSlice = facilityContactsSlice;
			} else if (props.entityContact.entityType === EntityContact_EntityType.FogDevice) {
				contactSlice = entityContactsSlice;
			}
			if (contactSlice == null) {
				throw new Error(
					`Program error: cannot save entity contact with entity type ${props.entityContact.entityType}`
				);
			}
			let entityContactToAddOrUpdate: EntityContact = {
				entityContactId: props.entityContact.entityContactId,
				entityId: props.entityContact.entityId,
				entityType: props.entityContact.entityType
			};
			entityContactToAddOrUpdate = { ...formState, ...entityContactToAddOrUpdate };
			if (formState.addressJurisdictionId && jurisdictions) {
				_.set(entityContactToAddOrUpdate, 'addressJurisdictionId', formState.addressJurisdictionId);
				let filteredJurisdictionList = jurisdictions
					.filter(x => x.jurisdictionId === _.toNumber(formState.addressJurisdictionId))
					.map(x => x.code);
				entityContactToAddOrUpdate.addressJurisdictionCode =
					filteredJurisdictionList.length > 0 ? filteredJurisdictionList[0] : '';
			} else {
				_.set(entityContactToAddOrUpdate, 'addressJurisdictionId', '');
				entityContactToAddOrUpdate.addressJurisdictionCode = '';
			}
			if (formState.email) {
				entityContactToAddOrUpdate.jsonEmails = UtilService.toJson([
					{ Email: formState.email, Label: 'Email' }
				]);
			} else {
				entityContactToAddOrUpdate.jsonEmails = '';
			}
			let phoneNumbers = [];
			if (formState.phoneNumber) {
				phoneNumbers.push({
					NumberType: NumberType.BusinessPhone,
					Number: formState.phoneNumber,
					Label: 'Phone'
				});
			}
			if (formState.mobileNumber) {
				phoneNumbers.push({
					NumberType: NumberType.CellPhone,
					Number: formState.mobileNumber,
					Label: 'Mobile'
				});
			}
			if (phoneNumbers.length > 0) {
				entityContactToAddOrUpdate.jsonNumbers = UtilService.toJson(phoneNumbers);
			} else {
				entityContactToAddOrUpdate.jsonNumbers = '';
			}
			delete entityContactToAddOrUpdate.sendNotices;
			delete entityContactToAddOrUpdate.sendNoticeIds;
			delete entityContactToAddOrUpdate.duplicateDeviceContacts;
			if (isAddMode) {
				dispatch(
					contactSlice.createOne(
						entityContactToAddOrUpdate,
						false,
						localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.contact'),
						() => {
							props.close(e);
							if (props.reload) {
								props.reload();
							}
						},
						(err: ApiError) => {
							setFormBadRequestFieldsError(err);
						}
					)
				);
			} else {
				entityContactToAddOrUpdate.entityContactId = props.entityContact.entityContactId;
				let groupByIds = entityContactToAddOrUpdate.entityGroupIds;
				delete entityContactToAddOrUpdate.entityGroupIds;

				dispatch(
					contactSlice.patchOne(
						entityContactToAddOrUpdate.entityContactId as number,
						entityContactToAddOrUpdate,
						false,
						localizationService.getLocalizedString('alertMessages.updateSuccess', 'screen.labels.contact'),
						() => {
							if (groupByIds && groupByIds.length > 0) {
								groupByIds.forEach((ids: number[]) => {
									entityContactToAddOrUpdate.entityContactId = ids[0];
									entityContactToAddOrUpdate.entityId = ids[1];
									dispatch(
										entityContactsSlice.patchOne(
											ids[0],
											entityContactToAddOrUpdate,
											false,
											undefined,
											() => {
												if (props.reload) {
													props.reload();
												}
											}
										)
									);
								});
							}
							props.close(e);
							if (props.reload) {
								props.reload();
							}
						},
						(err: ApiError) => {
							setFormBadRequestFieldsError(err);
						}
					)
				);
			}
		}
		if (isAccessingBackflow && props.linkedContact) {
			let linkedContactToAddOrUpdate: CccSiteContact = {
				linkedContactId: props.linkedContact.linkedContactId,
				siteId: props.linkedContact.siteId,
				hazardId: props.linkedContact.hazardId
			};

			linkedContactToAddOrUpdate.contactTypeId = formState.contactTypeId ? formState.contactTypeId : 0;
			if (urlService.isViewCccazard()) {
				linkedContactToAddOrUpdate.sendNotices = formState.sendNotices === true;
			} else {
				delete linkedContactToAddOrUpdate.sendNotices;
			}

			linkedContactToAddOrUpdate.contactId = formState.contactId ? formState.contactId : 0;
			linkedContactToAddOrUpdate.firstName = formState.firstName;
			linkedContactToAddOrUpdate.lastName = formState.lastName;
			linkedContactToAddOrUpdate.companyName = formState.companyName;
			linkedContactToAddOrUpdate.isBusiness = UtilService.hasValue(formState.companyName);
			linkedContactToAddOrUpdate.title = formState.title;
			linkedContactToAddOrUpdate.addressLine1 = formState.addressLine1;
			linkedContactToAddOrUpdate.addressLine2 = formState.addressLine2;
			linkedContactToAddOrUpdate.addressCity = formState.addressCity;
			linkedContactToAddOrUpdate.websiteAddress = formState.websiteAddress;
			linkedContactToAddOrUpdate.addressId = formState.addressId ? formState.addressId : 0;
			if (formState.addressJurisdictionId && jurisdictions) {
				_.set(linkedContactToAddOrUpdate, 'addressJurisdictionId', formState.addressJurisdictionId);
				let filteredJurisdictionList = jurisdictions
					.filter(x => x.jurisdictionId === _.toNumber(formState.addressJurisdictionId))
					.map(x => x.code);
				linkedContactToAddOrUpdate.addressJurisdictionCode =
					filteredJurisdictionList.length > 0 ? filteredJurisdictionList[0] : '';
			} else {
				_.set(linkedContactToAddOrUpdate, 'addressJurisdictionId', '');
				linkedContactToAddOrUpdate.addressJurisdictionCode = '';
			}
			linkedContactToAddOrUpdate.addressZipCode = formState.addressZipCode;

			if (formState.email) {
				linkedContactToAddOrUpdate.jsonEmails = UtilService.toJson([
					{ Email: formState.email, Label: 'Email' }
				]);
			} else {
				linkedContactToAddOrUpdate.jsonEmails = '';
			}
			let phoneNumbers = [];
			if (formState.phoneNumber) {
				phoneNumbers.push({
					NumberType: NumberType.BusinessPhone,
					Number: formState.phoneNumber,
					Label: 'Phone'
				});
			}
			if (formState.mobileNumber) {
				phoneNumbers.push({
					NumberType: NumberType.CellPhone,
					Number: formState.mobileNumber,
					Label: 'Mobile'
				});
			}
			if (phoneNumbers.length > 0) {
				linkedContactToAddOrUpdate.jsonNumbers = UtilService.toJson(phoneNumbers);
			} else {
				linkedContactToAddOrUpdate.jsonNumbers = '';
			}
			if (isAddMode) {
				dispatch(
					cccSiteContactsSlice.createOne(
						linkedContactToAddOrUpdate,
						false,
						localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.contact'),
						() => {
							props.close(e);
							props.reload && props.reload();
						},
						(err: ApiError) => {
							setFormBadRequestFieldsError(err);
						}
					)
				);
			} else if (props.linkedContact) {
				linkedContactToAddOrUpdate.linkedContactId = props.linkedContact.linkedContactId;
				dispatch(
					cccSiteContactsSlice.patchOne(
						linkedContactToAddOrUpdate.linkedContactId as number,
						linkedContactToAddOrUpdate,
						false,
						localizationService.getLocalizedString('alertMessages.updateSuccess', 'screen.labels.contact'),
						() => {
							props.close(e);
							props.reload && props.reload();
						},
						(err: ApiError) => {
							setFormBadRequestFieldsError(err);
						}
					)
				);
			}
		}
	};

	const cancelSaveContact = (e: any) => {
		props.close(e);
	};

	const applySearch = (selectedValue: any) => {
		if (selectedValue && selectedValue.contactId) {
			const selectedContact: FormFields = getNewFormFieldState(selectedValue);
			const newFormState: FormFields = { ...formState, ...selectedContact };
			setFormState(newFormState);

			//Since selectedValue is a simplified Contact, reload full contact and update local state from the full contact
			const contactUrl = urlService.getAuthorityResourceApiUrl(Resource.Contacts, selectedValue.contactId);
			apiService.httpGet(contactUrl).then((contact: Contact) => {
				const selectedContact: FormFields = getNewFormFieldState(contact);
				const newFormState: FormFields = { ...formState, ...selectedContact };
				setFormState(newFormState);
			});
		}
	};

	const getRemoveContactButtonLabel = (): string => {
		if (isAccessingBackflow) {
			if (urlService.isViewCccSite()) {
				return localizationService.getLocalizedString('contacts.removeContactFromCccSiteAndHazard');
			}
			if (urlService.isViewCccazard()) {
				return localizationService.getLocalizedString('contacts.removeContactFromCccHazard');
			}
		}
		if (isAccessingFog && props.entityContact) {
			const isAccessingFogFacility = urlService.getFogFacilityId() > 0;
			if (isAccessingFogFacility) {
				let facilityEntityContact = props.entityContact as FacilityEntityContact;
				let numberOfDeviceContacts = _.size(facilityEntityContact.duplicateDeviceContacts);
				if (numberOfDeviceContacts > 1) {
					return localizationService.getLocalizedString(
						'screen.buttons.removeFromFacilityAndDevices',
						`${numberOfDeviceContacts}`
					);
				}
				if (numberOfDeviceContacts === 1) {
					return localizationService.getLocalizedString('screen.buttons.removeFromFacilityAndDevice');
				}
				return localizationService.getLocalizedString('screen.buttons.removeFromFacility');
			}
			const isAccessingFogDevice = urlService.getFogDeviceId() > 0;
			if (isAccessingFogDevice) {
				return localizationService.getLocalizedString('screen.buttons.removeFromDevice');
			}
		}
		Logger.error(`Program error: cannot compose remove contact title.`);
		return localizationService.getLocalizedString('screen.buttons.removeContactDefault');
	};
	const getRemoveContactTitle = (): string => {
		if (isAccessingBackflow) {
			if (urlService.isViewCccSite()) {
				return localizationService.getLocalizedString('contacts.removeContactFrom', 'cccSite.siteAndHazards');
			}
			if (urlService.isViewCccazard()) {
				return localizationService.getLocalizedString('contacts.removeContactFrom', 'cccHazard.hazard');
			}
		}
		if (isAccessingFog && props.entityContact) {
			return localizationService.getLocalizedString('contacts.removeContact');
		}
		Logger.error(`Program error: cannot compose remove contact title.`);
		return localizationService.getLocalizedString('contacts.removeContact');
	};
	const getRemoveContactMessage = (): string => {
		let contactName: string = '';
		if (isAccessingBackflow && props.linkedContact) {
			contactName = getContactDisplayName(props.linkedContact);
			if (urlService.isViewCccSite()) {
				return localizationService.getLocalizedString('contacts.deleteFromCccSiteAndHazard', contactName);
			}
			if (urlService.isViewCccazard()) {
				return localizationService.getLocalizedString('contacts.deleteFromCccHazard', contactName);
			}
		}
		if (isAccessingFog && props.entityContact) {
			let contactType = props.entityContact.contactType || props.entityContact.contactTypeCode || '';
			const isAccessingFogFacility = urlService.getFogFacilityId() > 0;
			if (isAccessingFogFacility) {
				let numberOfDeviceContacts = _.size(props.entityContact.duplicateDeviceContacts);
				if (numberOfDeviceContacts > 1) {
					return localizationService.getLocalizedString(
						'contacts.deleteFromFogFacilityAndDevices',
						contactType,
						props.entityContact.facilityName as string,
						`${numberOfDeviceContacts}`
					);
				}
				if (numberOfDeviceContacts === 1) {
					return localizationService.getLocalizedString(
						'contacts.deleteFromFogFacilityAndDevice',
						contactType,
						props.entityContact.facilityName as string
					);
				}
				return localizationService.getLocalizedString(
					'contacts.deleteFromFogFacility',
					contactType,
					props.entityContact.facilityName as string
				);
			}
			const isAccessingFogDevice = urlService.getFogDeviceId() > 0;
			if (isAccessingFogDevice) {
				return localizationService.getLocalizedString(
					'contacts.deleteFromFogDevice',
					contactType,
					props.entityContact.deviceNumber as string,
					props.entityContact.facilityName as string
				);
			}
		}
		Logger.error(`Program error: cannot compose remove contact message.`);
		return localizationService.getLocalizedString('contacts.deleteLinkDefault', contactName);
	};

	const onRemoveContactAssociation = async (e: any) => {
		if (isAccessingBackflow && props.linkedContact) {
			if (urlService.isViewCccSite()) {
				return dispatch(
					cccSiteContactsSlice.deleteAll(
						`siteId=${props.linkedContact.siteId}&contactTypeId=${props.linkedContact.contactTypeId}&contactId=${props.linkedContact.contactId}`,
						false,
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'screen.labels.contact'),
						props.reload
					)
				);
			}
			if (urlService.isViewCccazard()) {
				return dispatch(
					cccHazardContactsSlice.deleteOne(
						props.linkedContact.linkedContactId as number,
						false,
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'screen.labels.contact'),
						props.reload
					)
				);
			}
		}
		if (isAccessingFog && props.entityContact) {
			const isAccessingFogFacility = urlService.getFogFacilityId() > 0;
			if (isAccessingFogFacility) {
				//remove the contact association from the facility and all below assigned devices.
				return dispatch(
					facilityContactsSlice.deleteOne(
						props.entityContact.entityContactId as number,
						false,
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'screen.labels.contact'),
						props.reload,
						`includeDeviceContacts=true`
					)
				);
			}
			const isAccessingFogDevice = urlService.getFogDeviceId() > 0;
			if (isAccessingFogDevice) {
				if (EntityContact_EntityType.FogFacility === props.entityContact.entityType) {
					//remove the contact association from the facility only
					return dispatch(
						facilityContactsSlice.deleteOne(
							props.entityContact.entityContactId as number,
							false,
							localizationService.getLocalizedString(
								'alertMessages.removeSuccess',
								'screen.labels.contact'
							),
							props.reload
						)
					);
				}
				if (EntityContact_EntityType.FogDevice === props.entityContact.entityType) {
					//remove the contact association from the device only
					return dispatch(
						entityContactsSlice.deleteOne(
							props.entityContact.entityContactId as number,
							false,
							localizationService.getLocalizedString(
								'alertMessages.removeSuccess',
								'screen.labels.contact'
							),
							props.reload
						)
					);
				}
			}
		}
		Logger.error(`Cannot remove contact association due to unsupported workflow.`);
	};

	const onPermanentDeleteContact = (e: any) => {
		if (isAccessingBackflow && props.linkedContact) {
			return dispatch(
				contactsSlice.deleteOne(
					props.linkedContact.contactId as number,
					false,
					localizationService.getLocalizedString(
						'alertMessages.permanentlyRemoveSuccess',
						'screen.labels.contact'
					),
					props.reload
				)
			);
		}
		if (isAccessingFog && props.entityContact) {
			return dispatch(
				contactsSlice.deleteOne(
					props.entityContact.contactId as number,
					false,
					localizationService.getLocalizedString(
						'alertMessages.permanentlyRemoveSuccess',
						'screen.labels.contact'
					),
					props.reload
				)
			);
		}
		Logger.error(`Cannot permanent delete contact due to unsupported workflow.`);
	};

	const modalFooterDiv = (
		<>
			{!isAddMode ? (
				<button
					data-toggle="dropdown"
					type="button"
					aria-haspopup="true"
					aria-expanded="false"
					className="btn dropdown-toggle ai-secondary-delete mr-auto">
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
			) : null}
			<button className="btn ai-save" onClick={saveContact}>
				{localizationService.getLocalizedString('screen.buttons.save')}
			</button>
			<button className="btn ai-white" onClick={cancelSaveContact}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>

			{!isAddMode ? (
				<div className="dropdown-menu">
					<button
						className="dropdown-item font-size-16px remove-contact-association"
						onClick={(e: any) => setIsToggleRemoveAssociationModal(true)}>
						{getRemoveContactButtonLabel()}
					</button>
					<button
						className="dropdown-item font-size-16px"
						onClick={(e: any) => setIsTogglePermanentRemoveModal(true)}>
						{localizationService.getLocalizedString('screen.buttons.deletePermanently')}
					</button>
				</div>
			) : null}
			{isAccessingBackflow && props.linkedContact && (
				<>
					{isToggleRemoveAssociationModal && (
						<DeleteModal
							key={`removeContactAssociationModal-${props.linkedContact.linkedContactId}`}
							title={getRemoveContactTitle()}
							message={getRemoveContactMessage()}
							showModal={true}
							onCancelButtonClick={() => setIsToggleRemoveAssociationModal(false)}
							onOkayButtonClick={onRemoveContactAssociation}
							okayButtonText={localizationService.getLocalizedString('screen.buttons.remove')}
							isDeleteButton={true}
						/>
					)}
					{isTogglePermanentRemoveModal && (
						<DeleteModal
							key={`removeContactAssociationModal-${props.linkedContact.linkedContactId}`}
							title={localizationService.getLocalizedString('contacts.deleteContact')}
							message={localizationService.getLocalizedString('contacts.deletePermanentlyFromCcc')}
							showModal={true}
							onCancelButtonClick={() => setIsTogglePermanentRemoveModal(false)}
							onOkayButtonClick={onPermanentDeleteContact}
							okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
							isDeleteButton={true}
						/>
					)}
				</>
			)}
			{isAccessingFog && props.entityContact && (
				<>
					{isToggleRemoveAssociationModal && (
						<DeleteModal
							key={`removeContactAssociationModal-${props.entityContact.entityContactId}`}
							title={getRemoveContactTitle()}
							message={getRemoveContactMessage()}
							showModal={true}
							onCancelButtonClick={() => setIsToggleRemoveAssociationModal(false)}
							onOkayButtonClick={onRemoveContactAssociation}
							okayButtonText={localizationService.getLocalizedString('screen.buttons.remove')}
							isDeleteButton={true}
						/>
					)}
					{isTogglePermanentRemoveModal && (
						<DeleteModal
							key={`permanentDeleteContactModal-${props.entityContact.entityContactId}`}
							title={localizationService.getLocalizedString('contacts.deleteContact')}
							message={localizationService.getLocalizedString('contacts.deletePermanentlyFromFog')}
							showModal={true}
							onCancelButtonClick={() => setIsTogglePermanentRemoveModal(false)}
							onOkayButtonClick={onPermanentDeleteContact}
							okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
							isDeleteButton={true}
						/>
					)}
				</>
			)}
		</>
	);

	return (
		<div className="w-100">
			<PopoverModal
				showModal={true}
				title={localizationService.getLocalizedString(
					isAddMode ? 'contacts.addContact' : 'contacts.editContact'
				)}
				save={saveContact}
				cancel={cancelSaveContact}
				footer={modalFooterDiv}>
				{isAddMode && (
					<div className="form-group">
						<SearchableComboBox
							textField="searchableText"
							dataSource={searchableContacts}
							onSelect={applySearch}
							placeholder={localizationService.getLocalizedString('contacts.searchContacts')}
							description={localizationService.getLocalizedString('contacts.searchHintBusinessName')}
						/>
					</div>
				)}
				{!isAddMode && featureFlags[FeatureNames.ShowInDevelopmentFeature] === true && (
					<div className="form-group">
						<TextInput
							id="contactNumber"
							name="contactNumber"
							label={localizationService.getLocalizedString('contacts.contactNumber')}
							value={formState.contactNumber}
							isRequired={true}
							isDisabled={true}
							helpText={localizationService.getLocalizedString('contacts.contactNumberHelpText')}
						/>
					</div>
				)}
				<SingleSelectDropdown
					id="contactTypeId"
					name="contactTypeId"
					label={localizationService.getLocalizedString('contacts.contactType')}
					value={_.toString(formState.contactTypeId)}
					onChange={changeFormState}
					options={contactTypeOptionValues}
					isRequired={true}
					error={formState.contactTypeIdError}
				/>
				<div className={formState.fieldSetError ? 'fieldset border-danger' : 'fieldset'}>
					<div className="form-row">
						<TextInput
							id="firstName"
							name="firstName"
							label={localizationService.getLocalizedString('contacts.firstName')}
							value={formState.firstName}
							onChange={changeFormState}
							className="form-group col-sm-6"
						/>
						<TextInput
							id="lastName"
							name="lastName"
							label={localizationService.getLocalizedString('contacts.lastName')}
							value={formState.lastName}
							onChange={changeFormState}
							className="form-group col-sm-6"
						/>
					</div>
					<TextInput
						id="companyName"
						name="companyName"
						label={localizationService.getLocalizedString('contacts.businessName')}
						value={formState.companyName}
						onChange={changeFormState}
					/>
					<TextInput
						id="addressLine1"
						name="addressLine1"
						label={localizationService.getLocalizedString('contacts.address1')}
						value={formState.addressLine1}
						onChange={changeFormState}
						helpText={localizationService.getLocalizedString('screen.helpText.address1')}
					/>
				</div>
				{formState.fieldSetError ? (
					<div className="fieldset-error">{formState.fieldSetError}</div>
				) : (
					<div className="ai-form-help mb-3">
						{localizationService.getLocalizedString('contacts.provideAtLeastOneValue')}
					</div>
				)}
				<div className="form-row">
					<TextInput
						id="addressLine2"
						name="addressLine2"
						className="form-group col"
						label={localizationService.getLocalizedString('contacts.address2')}
						value={formState.addressLine2}
						onChange={changeFormState}
						helpText={localizationService.getLocalizedString('screen.helpText.address2')}
					/>
				</div>
				<div className="form-row">
					<TextInput
						id="addressCity"
						name="addressCity"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('contacts.city')}
						value={formState.addressCity}
						onChange={changeFormState}
					/>
					<SingleSelectDropdown
						id="addressJurisdictionId"
						name="addressJurisdictionId"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('contacts.state')}
						value={_.toString(formState.addressJurisdictionId)}
						onChange={changeFormState}
						options={stateProvinceOptionValues}
					/>
					<TextInput
						id="addressZipCode"
						name="addressZipCode"
						className="form-group col-sm-4"
						label={localizationService.getLocalizedString('contacts.zip')}
						value={formState.addressZipCode}
						onChange={changeFormState}
					/>
				</div>
				<TextInput
					id="email"
					name="email"
					label={localizationService.getLocalizedString('contacts.email')}
					value={formState.email}
					onChange={changeFormState}
					error={formState.emailFormatError}
				/>
				<div className="form-row">
					<TextInput
						id="phoneNumber"
						name="phoneNumber"
						label={localizationService.getLocalizedString('contacts.phone')}
						value={formState.phoneNumber}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="mobileNumber"
						name="mobileNumber"
						label={localizationService.getLocalizedString('contacts.mobile')}
						value={formState.mobileNumber}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
				</div>
				<div className="form-row">
					<TextInput
						id="title"
						name="title"
						label={localizationService.getLocalizedString('contacts.title')}
						value={formState.title}
						onChange={changeFormState}
						className="form-group col-sm-6"
					/>
					<TextInput
						id="websiteAddress"
						name="websiteAddress"
						label={localizationService.getLocalizedString('contacts.website')}
						value={formState.websiteAddress}
						onChange={changeFormState}
						className="form-group col-sm-6"
						error={formState.websiteAddressError}
					/>
				</div>
				{props.linkedContact && props.linkedContact.hazardNumber && urlService.isViewCccazard() && (
					<SingleCheckbox
						id="sendNotices"
						name="sendNotices"
						className="div-checkbox"
						label={localizationService.getLocalizedString(
							'contacts.hazardInfoForSendNotices',
							props.linkedContact.hazardNumber
						)}
						checked={formState.sendNotices}
						onChange={changeFormState}
					/>
				)}
				<SingleSelectDropdown
					id="languageId"
					name="languageId"
					label={localizationService.getLocalizedString('contacts.languageCode')}
					value={_.toString(formState.languageId)}
					onChange={changeFormState}
					options={languageOptionValues}
				/>
			</PopoverModal>
		</div>
	);
};
