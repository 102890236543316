import { ReportPackageTemplateAction, ReportPackageTemplateActionType } from './templates-actions';
import { initialReportPackageTemplateState, ReportPackageTemplateState } from './templates-state';

export const ippReportPackageTemplateReducer = (
	state = initialReportPackageTemplateState,
	action: ReportPackageTemplateAction
): ReportPackageTemplateState => {
	switch (action.type) {
		case ReportPackageTemplateActionType.LoadReportPackageTemplateRequest:
		case ReportPackageTemplateActionType.UpdateReportPackageTemplate:
		case ReportPackageTemplateActionType.DeleteReportPackageTemplate:
		case ReportPackageTemplateActionType.AddReportPackageTemplate:
			return { ...state };
		case ReportPackageTemplateActionType.LoadReportPackageTemplateSuccess:
			return {
				...state,
				templateDetails: action.templateDetails,
				availableCtsEventTypes: action.availableCtsEventTypes,
				availableSamplesAndResultsTypes: action.availableSamplesAndResultsTypes,
				availableAttachmentTypes: action.availableAttachmentTypes,
				availableCertificationTypes: action.availableCertificationTypes,
				availableReportPackageTemplateAssignments: action.availableReportPackageTemplateAssignments
			};
		default:
			return state;
	}
};
