import { LookupRow } from '../lookup-types';
import { TagDto, SubLookupType } from '@rcp/types';
import { ILookupDataConverter } from './lookup-data-converter';
import _ from 'lodash';

export class LookupRowTagValueConverter implements ILookupDataConverter {
	private subType: SubLookupType;
	constructor(subType: SubLookupType = SubLookupType.Facility) {
		this.subType = subType;
	}

	toAPIAddDataFromLookups = (existingLookupRows: LookupRow[], newValue: LookupRow): TagDto => {
		let dataToPost = {
			name: newValue.code,
			tagDescription: newValue.codeDescription,
			tagType: this.subType,
			isActive: true
		} as TagDto;

		return dataToPost;
	};

	toAPIDeleteDataFromLookups = (existingRows: LookupRow[], rowToDelete: LookupRow): TagDto => {
		return rowToDelete;
	};

	toAPIUpdateDataFromLookups = (existingRows: LookupRow[], rowToUpdate: LookupRow): TagDto => {
		let dataToUpdate: TagDto = {
			tagId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			tagDescription: rowToUpdate.codeDescription,
			tagType: this.subType,
			isActive: rowToUpdate.isActive
		};
		return dataToUpdate;
	};

	toLookupRowsFromAPI = (apiData: any): LookupRow[] => {
		let lookupValues: LookupRow[] = [];
		if (apiData && _.isArray(apiData)) {
			let authorityTags = apiData as TagDto[];
			authorityTags = authorityTags.filter(i => i.tagType === this.subType);
			lookupValues = authorityTags.map((i: TagDto) => {
				return {
					lookupId: i.tagId,
					code: i.name,
					codeDescription: i.tagDescription,
					isActive: i.isActive,
					isInUse: i.isInUse
				} as LookupRow;
			});
		}
		return lookupValues;
	};

	toLookupRowFromAPI = (apiData: any): LookupRow => {
		let tagDto = apiData as TagDto;
		return {
			lookupId: tagDto.tagId,
			code: tagDto.name,
			codeDescription: tagDto.tagDescription,
			isActive: tagDto.isActive,
			isInUse: tagDto.isInUse
		} as LookupRow;
	};
}
