import { ThunkAction } from 'redux-thunk';
import { apiService, urlService } from 'src/services';
import { Resource } from 'src/services/url-service';
import * as IppAASAuthorityInformationActions from './aas-authority-information-actions';
import { IppAASAuthorityInformationState } from './aas-authority-information-state';
import EIppAASAuthorityInformationActionType from './aas-authority-information-action-types';
import { IppAASAuthorityInformationDataApiMapping } from 'src/constants';

type FetchIppAASAuthorityInformationDataThunkAction = ThunkAction<
	any,
	IppAASAuthorityInformationState,
	any,
	IppAASAuthorityInformationActions.FetchIppAASAuthorityInformationDataAction
>;

export const fetchIppAASAuthorityInformationDataActionCreator = (
	currentAuthorityInformationState: IppAASAuthorityInformationState
): FetchIppAASAuthorityInformationDataThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.AuthorityDetails);
	const ippAASAuthorityInformation = await apiService.getAuthorityInformation<IppAASAuthorityInformationState>(
		url,
		IppAASAuthorityInformationDataApiMapping,
		currentAuthorityInformationState
	);
	dispatch({
		type: EIppAASAuthorityInformationActionType.FetchIppAASAuthorityInformationData,
		payload: ippAASAuthorityInformation
	});
};
