import React, { FC, useEffect, useState } from 'react';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { PopoverModal } from 'src/components/widgets';
import _ from 'lodash';
import { alertService } from 'src/redux/alert';
import { haulersSlice } from 'src/components/authority/fog/haulers';
import { useDispatch } from 'react-redux';
import { Hauler } from '@rcp/types';
import EmailModal from 'src/components/widgets/preview-email-notice';

export interface InviteHaulerModalProps {
	showModal: boolean;
	onCancel: () => void;
	hauler: Hauler;
	title: string;
	invitationDesc: string;
}

interface Invite {
	invitationUrl?: string;
	inviteCode?: string;
}

export const InviteHaulerModal: FC<InviteHaulerModalProps> = props => {
	const [formState, setFormState] = useState<Invite>({
		invitationUrl: '',
		inviteCode: ''
	});
	const { showModal, onCancel } = props;
	const [showPreviewModal, setShowPreviewModal] = React.useState(false);
	const [invitationErrorMessage, setInvitationErrorMessage] = React.useState('');
	const [inviteByEmail, setInviteByEmail] = React.useState(true);
	const [emailDetails, setEmailDetails] = React.useState<any>({});
	const dispatch = useDispatch();

	const downloadInvitationPdf = async () => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/${props.hauler.haulerId}/${
			Resource.DownloadInvite
		}`;
		try {
			await apiService.httpGet(url);
			alertService.addSuccess(
				localizationService.getLocalizedString('haulerPortal.inviteHauler.downloadLetterSuccess')
			);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const previewInvitation = async () => {
		if (inviteByEmail) {
			let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/${props.hauler.haulerId}/${
				Resource.PreviewInviteEmail
			}`;
			apiService
				.getResource(url)
				.then((emailDetail: any) => {
					setEmailDetails({
						to: emailDetail.to,
						from: emailDetail.from,
						templateSubject: emailDetail.templateSubject,
						templateContent: emailDetail.templateContent
					});
					setShowPreviewModal(true);
				})
				.catch(error => {
					alertService.addError(error.message);
				});
		} else {
			await downloadInvitationPdf();
		}
	};

	const inviteHauler = async () => {
		setInvitationErrorMessage('');

		if (inviteByEmail) {
			if (props.hauler.email) {
				let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/${props.hauler.haulerId}/${
					Resource.SendInviteEmail
				}`;
				try {
					await apiService.httpPost(url, {});
				} catch (e) {
					alertService.addError(e.message);
					return;
				}
			} else {
				let errorMessage = localizationService.getLocalizedString('haulerPortal.inviteHauler.emailRequired');
				setInvitationErrorMessage(errorMessage);
				alertService.addError(errorMessage);
				return;
			}
		} else {
			if (
				props.hauler.addressLine1 &&
				props.hauler.addressCity &&
				props.hauler.addressJurisdictionCode &&
				props.hauler.addressZipCode
			) {
				await downloadInvitationPdf();
			} else {
				let errorMessage = localizationService.getLocalizedString('haulerPortal.inviteHauler.addressRequired');
				setInvitationErrorMessage(errorMessage);
				alertService.addError(errorMessage);
				return;
			}
		}
		dispatch(
			haulersSlice.patchOne(
				props.hauler.haulerId || 0,
				{ isMarkedAsInvited: true },
				true,
				localizationService.getLocalizedString('haulerPortal.inviteHauler.inviteSuccessfulMessage')
			)
		);
	};

	const generateInviteCode = async (setInviteCode: (response: any) => void) => {
		let url = `${urlService.getAuthorityResourcesApiUrl(Resource.Haulers)}/${Resource.GenerateInviteCode}/${
			props.hauler.haulerId
		}`;
		try {
			const response = await apiService.httpGet(url);

			setInviteCode(response);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const setInviteCode = (response: any) => {
		response.invitationUrl && response.inviteCode && setFormState({ ...response });
	};

	useEffect(() => {
		generateInviteCode(setInviteCode);
	}, []);

	const modalFooterDiv = (
		<>
			<button className="btn ai-white mr-auto" onClick={previewInvitation}>
				{localizationService.getLocalizedString('haulerPortal.buttons.preview')}
			</button>
			<button className="ml-auto btn ai-action" onClick={inviteHauler}>
				{localizationService.getLocalizedString('haulerPortal.buttons.invite')}
			</button>
			<button className="btn ai-white ml-2" onClick={onCancel}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</>
	);

	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal showModal={showModal} title={props.title} footer={modalFooterDiv} cancel={onCancel}>
					<p>{props.invitationDesc}</p>
					<div className="form-group">
						<label htmlFor="web-address">
							{localizationService.getLocalizedString('haulerPortal.inviteHauler.webAddress')}
						</label>
						<input
							id="web-address"
							type="text"
							name="InvitationUrl"
							value={formState.invitationUrl}
							className="form-control input-single-line pl-0"
							disabled
						/>
					</div>
					<div className="form-group">
						<label htmlFor={formState.inviteCode}>
							{localizationService.getLocalizedString('haulerPortal.inviteHauler.inviteCode')}
						</label>
						<input
							type="text"
							id={formState.inviteCode}
							name="inviteCode"
							value={formState.inviteCode}
							className="form-control input-single-line pl-0"
							disabled
						/>
					</div>
					<label className="mb-1">
						{localizationService.getLocalizedString('haulerPortal.inviteHauler.inviteBy')}
					</label>
					<div className="custom-control custom-radio invite-radio">
						<input
							id="inviteByEmail"
							type="radio"
							name="inviteByEmail"
							className="custom-control-input"
							value="true"
							checked={inviteByEmail === true}
							onChange={() => {
								setInvitationErrorMessage('');
								setInviteByEmail(true);
							}}
						/>
						<label className="custom-control-label" htmlFor="inviteByEmail">
							<span>{`${localizationService.getLocalizedString(
								'haulerPortal.inviteHauler.inviteByEmail'
							)}`}</span>
						</label>
					</div>
					<div className="custom-control custom-radio invite-radio">
						<input
							id="inviteByLetter"
							type="radio"
							name="inviteByLetter"
							className="custom-control-input"
							value="true"
							checked={inviteByEmail === false}
							onChange={() => {
								setInvitationErrorMessage('');
								setInviteByEmail(false);
							}}
						/>
						<label className="custom-control-label" htmlFor="inviteByLetter">
							<span>{`${localizationService.getLocalizedString(
								'haulerPortal.inviteHauler.inviteByLetter'
							)}`}</span>
						</label>
					</div>
					{invitationErrorMessage && (
						<label className="mb-2 invalid-feedback">{invitationErrorMessage}</label>
					)}
				</PopoverModal>
			</div>
			{showPreviewModal && (
				<EmailModal
					showModal={showPreviewModal}
					emailDetails={[emailDetails]}
					onToggle={() => setShowPreviewModal(false)}
					hideFooter={true}
				/>
			)}
		</React.Fragment>
	);
};
