import {
	Frequency,
	ICustomFieldItem,
	Lookup,
	ExtractorType,
	Unit,
	yesNoBooleanOptions,
	noLongerInServiceDropDown
} from '@rcp/types';
import { localizationService, optionsMap } from 'src/services';
import { FieldTypeEnum, IFacilityItem } from '../../../facilities/facility-details/facility-types';

export interface selectableItem {
	fieldName: string;
	title: string;
	chosen: boolean;
}
export interface CustomField extends ICustomFieldItem, selectableItem {
	options?: any[];
}

export interface FacilityField extends IFacilityItem, selectableItem {}
export interface FogDeviceField extends IFacilityItem, selectableItem {}

export interface BulkUpdateFields {
	fogDeviceFields: FogDeviceField[];
	customFields: CustomField[];
}

enum FieldName {
	deviceNumber = 'deviceNumber',
	extractorTypeId = 'extractorTypeId',
	location = 'location',
	extractorDescription = 'extractorDescription',
	trapCapacity = 'trapCapacity',
	trapDepth = 'trapDepth',
	cleanFrequencyId = 'cleanFrequencyId',
	numberOfCompartments = 'numberOfCompartments',
	manufacturer = 'manufacturer',
	model = 'model',
	installDate = 'installDate',
	latitude = 'latitude',
	longitude = 'longitude',
	mandatoryInstall = 'mandatoryInstall',
	isAdditivesUsed = 'isAdditivesUsed',
	isActive = 'isActive',
	trapCapacityUnitCode = 'trapCapacityUnitCode',
	trapCapacityUnitId = 'trapCapacityUnitId',
	trapDepthUnitCode = 'trapDepthUnitCode',
	trapDepthUnitId = 'trapDepthUnitId'
}

export const BulkUpdateFieldList: FogDeviceField[] = [
	{
		fieldName: FieldName.extractorTypeId,
		title: localizationService.getLocalizedString('extractor.extractorType'),
		isRequired: true,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FogDeviceField,
	{
		fieldName: FieldName.location,
		title: localizationService.getLocalizedString('extractor.location'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown,
		optionValues: [
			{ label: 'Inside', value: 'Inside' },
			{ label: 'Outside', value: 'Outside' }
		]
	} as FogDeviceField,
	{
		fieldName: FieldName.extractorDescription,
		title: localizationService.getLocalizedString('extractor.extractorDescription'),
		isRequired: true,
		dataType: FieldTypeEnum.String
	} as FogDeviceField,
	{
		fieldName: FieldName.trapCapacity,
		title: localizationService.getLocalizedString('extractor.trapCapacity'),
		isRequired: false,
		dataType: FieldTypeEnum.Number
	} as FogDeviceField,
	{
		fieldName: FieldName.trapCapacityUnitId,
		title: localizationService.getLocalizedString('extractor.trapCapacityUnitCode'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FogDeviceField,
	{
		fieldName: FieldName.trapDepthUnitId,
		title: localizationService.getLocalizedString('extractor.trapDepthUnitCode'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FogDeviceField,
	{
		fieldName: FieldName.trapDepth,
		title: localizationService.getLocalizedString('extractor.trapDepth'),
		isRequired: false,
		dataType: FieldTypeEnum.Number
	} as FogDeviceField,
	{
		fieldName: FieldName.cleanFrequencyId,
		title: localizationService.getLocalizedString('extractor.cleanFrequencyCode'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FogDeviceField,
	{
		fieldName: FieldName.numberOfCompartments,
		title: localizationService.getLocalizedString('extractor.numberOfCompartments'),
		isRequired: false,
		dataType: FieldTypeEnum.Integer
	} as FogDeviceField,
	{
		fieldName: FieldName.manufacturer,
		title: localizationService.getLocalizedString('extractor.manufacturer'),
		isRequired: false,
		dataType: FieldTypeEnum.String
	} as FogDeviceField,
	{
		fieldName: FieldName.model,
		title: localizationService.getLocalizedString('extractor.model'),
		isRequired: false,
		dataType: FieldTypeEnum.String
	} as FogDeviceField,
	{
		fieldName: FieldName.installDate,
		title: localizationService.getLocalizedString('extractor.installDate'),
		isRequired: false,
		dataType: FieldTypeEnum.Date
	} as FogDeviceField,
	{
		fieldName: FieldName.latitude,
		title: localizationService.getLocalizedString('extractor.latitude'),
		isRequired: false,
		dataType: FieldTypeEnum.Number
	} as FogDeviceField,
	{
		fieldName: FieldName.longitude,
		title: localizationService.getLocalizedString('extractor.longitude'),
		isRequired: false,
		dataType: FieldTypeEnum.Number
	} as FogDeviceField,
	{
		fieldName: FieldName.mandatoryInstall,
		title: localizationService.getLocalizedString('extractor.mandatoryInstall'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown,
		optionValues: yesNoBooleanOptions
	} as FogDeviceField,
	{
		fieldName: FieldName.isAdditivesUsed,
		title: localizationService.getLocalizedString('extractor.isAdditivesUsed'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown,
		optionValues: yesNoBooleanOptions
	} as FogDeviceField,
	{
		fieldName: FieldName.isActive,
		title: localizationService.getLocalizedString('extractor.isInactive'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown,
		optionValues: noLongerInServiceDropDown
	} as FogDeviceField
].sort((i: FogDeviceField, j: FogDeviceField) => {
	if (i.title > j.title) return 1;
	return -1;
});

export const setFacilityFrequencyFieldsList = (frequencies: Frequency[], setState: Function, state: any) => {
	BulkUpdateFieldList.forEach(field => {
		if (String.equalCaseInsensitive(field.fieldName, FieldName.cleanFrequencyId)) {
			field.optionValues = optionsMap.fromFrequencyList(frequencies).slice();
		}
	});
	setState({ ...state, fogDeviceFields: BulkUpdateFieldList });
};

export const setDeviceTypeFieldsList = (extractors: ExtractorType[], setState: Function, state: any) => {
	BulkUpdateFieldList.forEach(field => {
		if (String.equalCaseInsensitive(field.fieldName, FieldName.extractorTypeId)) {
			field.optionValues = optionsMap.fromLookups(extractors as Lookup[]);
		}
	});
	setState({ ...state, fogDeviceFields: BulkUpdateFieldList });
};

export const setTrapCapacityUnitList = (units: Unit[], setState: Function, state: any) => {
	const dropDownUnit = units
		.filter((unit: Unit) => unit.isActive)
		.map((unit: Unit) => {
			return { label: unit.name as string, value: unit.unitId };
		});
	BulkUpdateFieldList.forEach(field => {
		if (String.equalCaseInsensitive(field.fieldName, FieldName.trapCapacityUnitId)) {
			field.optionValues = dropDownUnit;
		}
	});
	setState({ ...state, fogDeviceFields: BulkUpdateFieldList });
};

export const setTrapDepthUnitList = (units: Unit[], setState: Function, state: any) => {
	const dropDownUnit = units
		.filter((unit: Unit) => unit.isActive)
		.map((unit: Unit) => {
			return { label: unit.name as string, value: unit.unitId };
		});
	BulkUpdateFieldList.forEach(field => {
		if (String.equalCaseInsensitive(field.fieldName, FieldName.trapDepthUnitId)) {
			field.optionValues = dropDownUnit;
		}
	});
	setState({ ...state, fogDeviceFields: BulkUpdateFieldList });
};
