import { ImportSampleSteps, IppCollectionMethod, IppMonitoringPoint } from '@rcp/types';
import { IppSampleType } from '@rcp/types/src';
import { History } from 'history';
import _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SingleSelectDropdown } from 'src/components/widgets';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';

interface Props {
	vertical?: boolean;
	history: History;
}

const Step3: React.FunctionComponent<Props> = (props: Props) => {
	const stepperContext = React.useContext(StepperContext);
	const step2Data: any = stepperContext.getData(ImportSampleSteps.STEP2);
	const [collectionMethods, setCollectionMethods] = useState([] as IppCollectionMethod[]);
	const [monitoringPoints, setMonitoringPoints] = useState([] as IppMonitoringPoint[]);
	const [sampleTypes, setSampleTypes] = useState([] as IppSampleType[]);
	const [selectedMonitoringPointId, setSelectedMonitoringPointId] = useState(null);
	const [selectedMonitoringPointLabel, setSelectedMonitoringPointLabel] = useState('');
	const [selectedCollectionMethodId, setSelectedCollectionMethodId] = useState(null);
	const [selectedCollectionMethodLabel, setSelectedCollectionMethodLabel] = useState('');
	const [selectedSampleTypeId, setSelectedSampleTypeId] = useState(null);
	const [selectedSampleTypeLabel, setSelectedSampleTypeLabel] = useState('');

	const updateStateOnBack = () => {
		const step3Data: any = stepperContext.getData(ImportSampleSteps.STEP3);
		if (step3Data) {
			// Set monitoring point
			setSelectedMonitoringPointId(step3Data.defaultMonitoringPointId);
			let selectedMonitoringPoint = _.find(monitoringPoints, data => {
				return data.monitoringPointId === step3Data.defaultMonitoringPointId;
			});
			setSelectedMonitoringPointLabel((selectedMonitoringPoint && selectedMonitoringPoint.name) || '');

			// Set collection method
			setSelectedCollectionMethodId(step3Data.defaultCollectionMethodId);
			let selectedCollectionMethod = _.find(collectionMethods, data => {
				return data.collectionMethodId === step3Data.defaultCollectionMethodId;
			});
			setSelectedCollectionMethodLabel((selectedCollectionMethod && selectedCollectionMethod.name) || '');

			// Set Sample Type
			setSelectedSampleTypeId(step3Data.defaultSampleTypeId);
			let selectedSampleType = _.find(sampleTypes, data => {
				return data.ctsEventTypeId === step3Data.defaultSampleTypeId;
			});
			setSelectedSampleTypeLabel((selectedSampleType && selectedSampleType.name) || '');
		}
		let step4State = { ...stepperContext.getStep(ImportSampleSteps.STEP4), completed: false, data: null };
		stepperContext.updateStep(ImportSampleSteps.STEP4, step4State);
		let step5State = { ...stepperContext.getStep(ImportSampleSteps.STEP5), completed: false, data: null };
		stepperContext.updateStep(ImportSampleSteps.STEP5, step5State);
	};

	useEffect(() => {
		loadCollectionMethods();
		loadMonitoringPoints();
		loadSampleType();
	}, []);

	useEffect(() => {
		updateStateOnBack();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [collectionMethods, monitoringPoints, sampleTypes]);

	const onClickNext = (event: React.FormEvent) => {
		alertService.clearAllMessages();
		if (
			!Boolean(Number(selectedMonitoringPointId)) &&
			step2Data.missingDefaults.length > 0 &&
			step2Data.missingDefaults.includes('MonitoringPoint')
		) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.importSamples.defaultMonitoringPointRequired')
			);
		} else if (
			!Boolean(Number(selectedCollectionMethodId)) &&
			step2Data.missingDefaults.length > 0 &&
			step2Data.missingDefaults.includes('CollectionMethod')
		) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.importSamples.defaultCollectionMethodRequired')
			);
		} else if (
			!Boolean(Number(selectedSampleTypeId)) &&
			step2Data.missingDefaults.length > 0 &&
			step2Data.missingDefaults.includes('SampleType')
		) {
			alertService.addError(
				localizationService.getLocalizedString('ipp.samples.importSamples.defaultSampleTypeRequired')
			);
		} else {
			stepperContext.resolve({
				...(selectedMonitoringPointId !== null && { defaultMonitoringPointId: selectedMonitoringPointId }),
				...(selectedMonitoringPointLabel !== '' && {
					defaultMonitoringPointName: selectedMonitoringPointLabel
				}),
				...(selectedCollectionMethodId !== null && { defaultCollectionMethodId: selectedCollectionMethodId }),
				...(selectedCollectionMethodLabel !== '' && {
					defaultCollectionMethodName: selectedCollectionMethodLabel
				}),
				...(selectedSampleTypeId !== null && {
					defaultSampleTypeId: selectedSampleTypeId
				}),
				...(selectedSampleTypeLabel !== '' && {
					defaultSampleTypeName: selectedSampleTypeLabel
				})
			});
		}
	};

	const loadCollectionMethods = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppCollectionMethods);
		let collectionMethods: any = await apiService.getResource(url);
		setCollectionMethods(collectionMethods);
	};

	const getCollectionMethods = () => {
		let options = collectionMethods.map((collectionMethod: IppCollectionMethod) => ({
			label: collectionMethod.name || '',
			value: collectionMethod.collectionMethodId || 0
		}));
		options.unshift({
			label: localizationService.getLocalizedString('ipp.samples.importSamples.selectACollectionMethod'),
			value: 0
		});
		return options;
	};

	const loadMonitoringPoints = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppMonitoringPoints);
		let monitoringPoints: any = await apiService.getResource(url);
		setMonitoringPoints(monitoringPoints);
	};

	const getMonitoringPoints = () => {
		let options = monitoringPoints.map((monitoringPoint: IppMonitoringPoint) => ({
			label: monitoringPoint.name || '',
			value: monitoringPoint.monitoringPointId || 0
		}));
		options.unshift({
			label: localizationService.getLocalizedString('ipp.samples.importSamples.selectAMonitoringPoint'),
			value: 0
		});
		return options;
	};
	const loadSampleType = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleTypes);
		let sampleTypes: any = await apiService.getResource(url);
		setSampleTypes(sampleTypes);
	};

	const getSampleTypes = () => {
		let options = sampleTypes.map((sampleType: IppSampleType) => ({
			label: sampleType.name || '',
			value: sampleType.ctsEventTypeId || 0
		}));
		options.unshift({
			label: localizationService.getLocalizedString('ipp.samples.importSamples.selectASampleType'),
			value: 0
		});
		return options;
	};

	const back = () => stepperContext.goAt(ImportSampleSteps.STEP2);

	const onChangeMonitoringPoint = (event: any) => {
		setSelectedMonitoringPointId(event.target.value);
		let selectedMonitoringPoint = _.find(monitoringPoints, data => {
			return Number(data.monitoringPointId) === Number(event.target.value);
		});
		setSelectedMonitoringPointLabel((selectedMonitoringPoint && selectedMonitoringPoint.name) || '');
		let step3State = { ...stepperContext.getStep(ImportSampleSteps.STEP3), loading: false };
		stepperContext.updateStep(ImportSampleSteps.STEP3, step3State);
	};

	const onChangeCollectionMethod = (event: any) => {
		setSelectedCollectionMethodId(event.target.value);
		let selectedCollectionMethod = _.find(collectionMethods, data => {
			return Number(data.collectionMethodId) === Number(event.target.value);
		});
		setSelectedCollectionMethodLabel((selectedCollectionMethod && selectedCollectionMethod.name) || '');
		let step3State = { ...stepperContext.getStep(ImportSampleSteps.STEP3), loading: false };
		stepperContext.updateStep(ImportSampleSteps.STEP3, step3State);
	};

	const onChangeSampleType = (event: any) => {
		setSelectedSampleTypeId(event.target.value);
		let selectedSampleType = _.find(sampleTypes, data => {
			return Number(data.ctsEventTypeId) === Number(event.target.value);
		});
		setSelectedSampleTypeLabel((selectedSampleType && selectedSampleType.name) || '');
		let step3State = { ...stepperContext.getStep(ImportSampleSteps.STEP3), loading: false };
		stepperContext.updateStep(ImportSampleSteps.STEP3, step3State);
	};

	return (
		<StepperContent
			id="selectMissingData"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn ai-white ml-3 mr-2" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					<StepperAction type="button" id="btnNext" className="btn ai-action ml-auto" onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-row">
				{step2Data &&
					!Boolean(step2Data.missingDefaults.length) &&
					!step2Data.missingDefaults.includes('MonitoringPoint') &&
					!step2Data.missingDefaults.includes('CollectionMethod') &&
					!step2Data.missingDefaults.includes('SampleType') && (
						<div className="form-row ml-3">
							{localizationService.getLocalizedString('ipp.samples.importSamples.step3NoMissingDefaults')}
						</div>
					)}
				{step2Data &&
					Boolean(step2Data.missingDefaults.length) &&
					(step2Data.missingDefaults.includes('MonitoringPoint') ||
						step2Data.missingDefaults.includes('CollectionMethod') ||
						step2Data.missingDefaults.includes('SampleType')) && (
						<div className="form-row ml-3 mb-3">
							{localizationService.getLocalizedString('ipp.samples.importSamples.step3MissingDefaults')}
						</div>
					)}
				{step2Data &&
					step2Data.missingDefaults.length > 0 &&
					step2Data.missingDefaults.includes('MonitoringPoint') && (
						<div className="col-md-8 col-sm-12 ">
							<SingleSelectDropdown
								id="monitoring-point"
								name="monitoringPoint"
								label={localizationService.getLocalizedString(
									'ipp.samples.importSamples.defaultMonitoringPoint'
								)}
								noEmptyOption={true}
								options={getMonitoringPoints()}
								selfOrder={true}
								isRequired={true}
								onChange={onChangeMonitoringPoint}
								value={selectedMonitoringPointId}
							/>
						</div>
					)}
				{step2Data &&
					step2Data.missingDefaults.length > 0 &&
					step2Data.missingDefaults.includes('CollectionMethod') && (
						<div className="col-md-8 col-sm-12">
							<SingleSelectDropdown
								id="collection-method"
								name="collectionMethod"
								label={localizationService.getLocalizedString(
									'ipp.samples.importSamples.defaultCollectionMethod'
								)}
								noEmptyOption={true}
								options={getCollectionMethods()}
								selfOrder={true}
								isRequired={true}
								onChange={onChangeCollectionMethod}
								value={selectedCollectionMethodId}
							/>
						</div>
					)}

				{step2Data && step2Data.missingDefaults.length > 0 && step2Data.missingDefaults.includes('SampleType') && (
					<div className="col-md-8 col-sm-12">
						<SingleSelectDropdown
							id="sample-type"
							name="sampleType"
							label={localizationService.getLocalizedString(
								'ipp.samples.importSamples.defaultSampleType'
							)}
							noEmptyOption={true}
							options={getSampleTypes()}
							selfOrder={true}
							isRequired={true}
							onChange={onChangeSampleType}
							value={selectedSampleTypeId}
						/>
					</div>
				)}
			</div>
		</StepperContent>
	);
};

export default Step3;
