import { ColumnField, FilterType, IppReportPackage, LocalStorageName } from '@rcp/types';
import React, { FC } from 'react';
import { GridOption } from 'src/features/data-grid/data-grid';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { localizationService, navigateTo, urlService } from 'src/services';
import IppReportPackageReviewsComponent from 'src/components/authority/ipp/report-packages/packages/reviews/reviews';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppDraftReportPackage: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showEditColumnButton: true,
		showFilter: false,
		showSearch: false,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageDraftGrid,
		searchPlaceholder: '',
		showActions: false,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Draft'),
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: true,
		addButtonText: localizationService.getLocalizedString('ipp.buttons.createNew'),
		toggleAddModal: () => navigateTo(props.history, urlService.getIppIndustryUrl('reportPackage/new')),
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.Draft,
			page: initialPage,
			sort: 'periodEndDateTimeLocal asc'
		},
		showFilterMenuPopOver: false,
		anchorElement: null,
		showFilterEditorModal: false,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('isSubmissionBySignatoryRequired', FilterType.Boolean),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'lastModificationDateTimeLocal',
			'lastModifierFullName'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppDraftReportPackage;
