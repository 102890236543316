import React, { useState } from 'react';
import { apiService, localizationService, urlService, tokenService, validationService } from 'src/services';
import { PopoverModal, TextInput } from 'src/components/widgets';
import { alertService } from 'src/redux';
import { IppConstants } from 'src/constants';

interface Props {
	showModal: boolean;
	modalTitle: string;
	userProfileId: number;
	oldEmail: string;
	onCancel: () => void;
	getProfileDetails: () => Promise<void>;
}

const { fieldCharLimit } = IppConstants;

const ChangeEmailModal: React.FC<Props> = (props: Props) => {
	const [newEmailAddress, setNewEmail] = useState<string>();
	const [newEmailError, setNewEmailError] = useState<string>();

	const closeModal = () => {
		props.onCancel();
		setNewEmail('');
		setNewEmailError('');
	};

	const changeEmail = async () => {
		alertService.clearAllMessages();
		let payload = { userProfileId: props.userProfileId, newEmailAddress: newEmailAddress, newEmailError };
		let isEmailValid = validationService.validateRequiredField(payload, 'newEmailAddress', 'newEmailError');
		isEmailValid = validationService.validateEmailFormatField(payload, 'newEmailAddress', 'newEmailError');
		setNewEmailError(payload.newEmailError);
		if (isEmailValid) {
			let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile/Email?organizationRegulatoryProgramId=${
				tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
			}`;
			try {
				await apiService.httpPatch(url, payload);
				alertService.addSuccess(
					localizationService.getLocalizedString('ipp.accountPortal.messages.emailUpdated')
				);
				await props.getProfileDetails();
				closeModal();
			} catch (ex) {
				alertService.addError(ex.message);
			}
		}
	};

	return (
		<React.Fragment>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					save={changeEmail}
					saveButtonText={localizationService.getLocalizedString('ipp.buttons.changeEmailAddress')}
					cancel={closeModal}>
					<div className="form-row">
						<TextInput
							id="old-email"
							name="old-email"
							className="w-100"
							label={localizationService.getLocalizedString('ipp.accountPortal.oldEmail')}
							value={props.oldEmail}
							isDisabled={true}
							isRequired={true}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="new-email"
							name="newEmailAddress"
							className="w-100"
							label={localizationService.getLocalizedString('ipp.accountPortal.newEmail')}
							value={newEmailAddress}
							isRequired={true}
							onChange={(event: any) => setNewEmail(event.target.value)}
							error={newEmailError}
							remainingInputProps={{ maxLength: fieldCharLimit.email }}
						/>
					</div>
				</PopoverModal>
			</div>
		</React.Fragment>
	);
};

export default ChangeEmailModal;
