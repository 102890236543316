import React, { useState } from 'react';
import { localizationService, navigateToRoute } from 'src/services';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { CustomFieldsTab } from '../../../fog/facilities/facility-details/custom-fields-tab/custom-field-tab';
import classNames from 'classnames';
import { CccSiteOverviewTab } from './site-overview-tab';
import { CccSite } from '@rcp/types';
import { SiteHazardsTab as HazardsTab } from 'src/components/authority/backflow/hazards-tab/site-hazards-tab';
import { SiteTab } from './site-tab.enum';
import { SiteContactsTab } from './site-contacts-tab';
import { Link, Switch, useHistory, useRouteMatch } from 'react-router-dom';

interface OwnProps {
	currentCccSite: CccSite;
}
type Props = OwnProps;

export const CccSiteInfoTab: React.FC<Props> = props => {
	const [activeTab, setActiveTab] = useState(SiteTab.Overview);
	const history = useHistory();
	let { url } = useRouteMatch();
	const toggle = (tab: SiteTab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};
	React.useEffect(() => {
		const tab = history.location.pathname.split('/') as SiteTab[];
		const urlLength = 4;
		if (tab.length > urlLength) {
			toggle(tab[tab.length - 1]);
		} else {
			toggle(SiteTab.Overview);
		}
	}, [history.location.pathname]);

	return (
		<section className="info-card">
			<Nav tabs className="material">
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<SiteTab>(history, SiteTab.Overview, url)}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, SiteTab.Overview) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.overview')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<SiteTab>(history, SiteTab.CustomFields, url)}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, SiteTab.CustomFields) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.customFields')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<SiteTab>(history, SiteTab.Hazards, url)}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, SiteTab.Hazards) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('cccHazard.hazards')}
					</NavLink>
				</NavItem>
				<NavItem>
					<NavLink
						tag={Link}
						onClick={() => navigateToRoute<SiteTab>(history, SiteTab.Contacts, url)}
						className={
							classNames({ active: String.equalCaseInsensitive(activeTab, SiteTab.Contacts) }) +
							' cursor-pointer'
						}>
						{localizationService.getLocalizedString('screen.labels.contactsAndNotices')}
					</NavLink>
				</NavItem>
			</Nav>

			<div className="margin-top-1">
				<Switch>
					<TabContent activeTab={activeTab}>
						<TabPane tabId={SiteTab.Overview}>
							<CccSiteOverviewTab currentCccSite={props.currentCccSite} />
						</TabPane>
						<TabPane tabId={SiteTab.CustomFields}>
							{String.equalCaseInsensitive(activeTab, SiteTab.CustomFields) && (
								<CustomFieldsTab
									customFieldFor="cccSite"
									customFieldParentObject={props.currentCccSite}
								/>
							)}
						</TabPane>
						<TabPane tabId={SiteTab.Hazards}>
							{String.equalCaseInsensitive(activeTab, SiteTab.Hazards) && (
								<HazardsTab siteId={props.currentCccSite.siteId as number} />
							)}
						</TabPane>
						<TabPane tabId={SiteTab.Contacts}>
							{String.equalCaseInsensitive(activeTab, SiteTab.Contacts) && (
								<SiteContactsTab siteId={props.currentCccSite.siteId as number} />
							)}
						</TabPane>
					</TabContent>
				</Switch>
			</div>
		</section>
	);
};
