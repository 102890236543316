import React from 'react';
import _ from 'lodash';

import { InspectionView, FogFacility } from '@rcp/types';
import { InspectionForm, AttachmentType } from '@rcp/types';
import { localizationService, DateUtilService, documentService } from 'src/services';
import $ from 'jquery';
import { ReportAttachments } from 'src/components/widgets';

type Props = {
	inspectionView: InspectionView;
	isPrintReportPreview?: boolean;
};

export const InspectionViewDetails: React.SFC<Props> = props => {
	let inspection = props.inspectionView.inspectionEvent;
	let authority = props.inspectionView.authority;
	let printPreviewInspectionForms = props.inspectionView.inspectionForms ? props.inspectionView.inspectionForms : [];
	let inspectionForms = props.inspectionView.inspectionForms
		? props.inspectionView.inspectionForms.filter((i: InspectionForm) => !i.isInternalOnly)
		: [];

	let attachments = props.inspectionView.inspectionAttachments || [];

	React.useEffect(() => {
		if (props.inspectionView.fogFacility) {
			var fogFacility = props.inspectionView.fogFacility as FogFacility;
			document.title =
				(fogFacility.facilityName as string) +
				localizationService.getLocalizedString('pageTitle.inspectionViewEndOfTitle');
		}
	}, [props.inspectionView.fogFacility]);

	React.useEffect(() => {
		if (!props.isPrintReportPreview) {
			window.onbeforeprint = function() {
				$('#print-view-attachments').removeAttr('hidden');
				$('#regular-view-attachments').attr('hidden', true);
				$('#inspection-sidebar').removeClass('sidebar');
			};

			window.onafterprint = function() {
				$('#regular-view-attachments').removeAttr('hidden');
				$('#print-view-attachments').attr('hidden', true);
				$('#inspection-sidebar').addClass('sidebar');
			};
		}
		documentService.disableInputFields(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.inspectionView]);

	return (
		<div className={`page-wrapper inspection-content ${props.isPrintReportPreview ? 'print-preview-report' : ''}`}>
			{authority && (
				<div className="main">
					<section className="mb-2">
						<div className="form-row">
							<div className="col-md-4">
								{authority.logoS3ResourcePath ? (
									<img
										src={authority.logoS3ResourcePath}
										alt="Authority Logo"
										className="img-fluid"
										height="50"
										width="150"
									/>
								) : (
									<img
										src="/img/AuthorityLogo.png"
										alt="Authority Logo"
										className="img-fluid"
										height="75"
										width="225"
									/>
								)}
							</div>
							<div className="col-md-4 mt-2 mt-md-0">
								<div className="wrapWord">{authority.organizationName}</div>
								<div className="wrapWord">
									{authority.addressLine1} {authority.addressLine2}
								</div>
								<div className="wrapWord">
									{authority.cityName} {authority.state} {authority.zipCode}
								</div>
							</div>
							<div className="col-md-4">
								{authority.emailContactInfoPhone && (
									<div className="wrapWord">
										<a href={'tel:' + authority.emailContactInfoPhone}>
											{authority.emailContactInfoPhone}
										</a>
									</div>
								)}

								{authority.emailContactInfoEmailAddress && (
									<div className="wrapWord">
										<a href={'mailto:' + authority.emailContactInfoEmailAddress}>
											{authority.emailContactInfoEmailAddress}
										</a>
									</div>
								)}

								{authority.websiteUrl && (
									<div className="wrapWord">
										<a href={authority.websiteUrl}>{authority.websiteUrl}</a>
									</div>
								)}
							</div>
						</div>
					</section>
				</div>
			)}

			{inspection && (
				<div id="inspection-sidebar" className={!props.isPrintReportPreview ? 'sidebar' : ''}>
					<section>
						<div className="form-row">
							<div className={`form-group col-sm-6 ${!props.isPrintReportPreview && 'col-lg-12'}`}>
								<div className="d-flex flex-row mt-2">
									<div className="summary-label">
										<strong>{localizationService.getLocalizedString('inspection.result')}</strong>
									</div>
									<div className="ml-2"> {inspection.inspectionResult}</div>
								</div>
								<div className="d-flex flex-row mt-2">
									<div className="summary-label">
										<strong>
											{localizationService.getLocalizedString('inspection.completed')}
										</strong>
									</div>
									<div className="ml-2">
										{' '}
										{DateUtilService.toDisplayDate(inspection.completeDate)}
									</div>
								</div>
							</div>
							<div
								className={`form-group col-sm-6  ${!props.isPrintReportPreview &&
									'mt-n3 mt-sm-0 mt-lg-n3 col-lg-12'}`}>
								<div className="d-flex flex-row mt-2">
									<div className="summary-label">
										<strong>{localizationService.getLocalizedString('inspection.type')}</strong>
									</div>
									<div className="ml-2"> {inspection.inspectionType}</div>
								</div>
								<div className="d-flex flex-row mt-2">
									<div className="summary-label">
										<strong>
											{localizationService.getLocalizedString('inspection.inspectorName')}
										</strong>
									</div>
									<div className="ml-2"> {inspection.inspectorName}</div>
								</div>
							</div>
						</div>
					</section>
					{!props.isPrintReportPreview && (
						<div id="regular-view-attachments">
							<ReportAttachments
								attachmentType={AttachmentType.InspectionAttachment}
								attachments={attachments}
								ownerId={inspection.inspectionEventId}
							/>
						</div>
					)}
				</div>
			)}

			<div className="main2">
				{!props.isPrintReportPreview &&
					inspectionForms &&
					inspectionForms.map((item: InspectionForm, index: number) => {
						return formattedInspectionForms(item, index);
					})}
				{props.isPrintReportPreview &&
					printPreviewInspectionForms &&
					printPreviewInspectionForms.map((item: InspectionForm, index: number) => {
						return formattedInspectionForms(item, index);
					})}
			</div>
			{inspection && (
				<div id="print-view-attachments" hidden={!props.isPrintReportPreview}>
					<ReportAttachments
						attachmentType={AttachmentType.InspectionAttachment}
						attachments={attachments}
						ownerId={inspection.inspectionEventId}
					/>
				</div>
			)}
		</div>
	);
};
function formattedInspectionForms(item: InspectionForm, index: number): JSX.Element {
	return (
		<>
			{item.inspectionFormHtmlContent && (
				<form
					id={`inspectionForm_${_.toString(item.inspectionFormTemplateId)}`}
					key={`inspectionForm-${index}`}
					autoComplete="off">
					<span
						id={`inspectionFormContent_${_.toString(item.inspectionFormTemplateId)}`}
						dangerouslySetInnerHTML={{ __html: item.inspectionFormHtmlContent }}
					/>
				</form>
			)}
		</>
	);
}
