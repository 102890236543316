import React from 'react';
import { useHistory } from 'react-router-dom';
import { localizationService, navigateTo, urlService } from 'src/services';
import _ from 'lodash';

import './import.scss';
import { ImportEntity, ImportEntityResult } from '@rcp/types';

interface Props {
	importId: number;
	importEntityResults: ImportEntityResult[];
	className?: string;
}

interface IResultLink {
	href: string;
	label: string;
}

export const ImportResultLinks: React.FC<Props> = props => {
	const history = useHistory();
	const importHistoryLink = urlService.getSettingMenuPath(`/import/history/${props.importId}`);
	const importType = ImportEntity;
	const getImportResultsByType = (domainType: string, importEntityResults: ImportEntityResult[]): IResultLink[] => {
		let results: IResultLink[] = [];
		_.forEach(
			_.filter(importEntityResults, r => r.domainType === domainType),
			entityResult => {
				if (entityResult.numberOfNewObject > 0) {
					switch (entityResult.domainType) {
						case importType.CccSite:
							results.push({
								href: `${importHistoryLink}/new/sites`,
								label: localizationService.getLocalizedString(
									'cccImportFile.step3ViewNewSites',
									_.toString(entityResult.numberOfNewObject)
								)
							});
							break;
						case importType.CccHazard:
							results.push({
								href: `${importHistoryLink}/new/hazards`,
								label: localizationService.getLocalizedString(
									'cccImportFile.step3ViewNewHazard',
									_.toString(entityResult.numberOfNewObject)
								)
							});
							break;
					}
				}
				if (entityResult.numberOfUpdatedObject > 0) {
					switch (entityResult.domainType) {
						case importType.CccSite:
							results.push({
								href: `${importHistoryLink}/updated/sites`,
								label: localizationService.getLocalizedString(
									'cccImportFile.step3ViewUpdatedSites',
									_.toString(entityResult.numberOfUpdatedObject)
								)
							});
							break;
						case importType.CccHazard:
							results.push({
								href: `${importHistoryLink}/updated/hazards`,
								label: localizationService.getLocalizedString(
									'cccImportFile.step3ViewUpdatedHazard',
									_.toString(entityResult.numberOfUpdatedObject)
								)
							});
							break;
					}
				}
			}
		);
		return results;
	};
	const getImportResults = (importEntityResults: ImportEntityResult[]): IResultLink[] => {
		if (_.isEmpty(importEntityResults)) {
			return [];
		}
		let results: IResultLink[] = getImportResultsByType(importType.CccSite, props.importEntityResults);
		results = results.concat(getImportResultsByType(importType.CccHazard, importEntityResults));
		return results;
	};

	let results = getImportResults(props.importEntityResults);
	return (
		<>
			{results.map((result, index) => {
				return (
					<div key={`result-${index}`} className={props.className}>
						<a href={result.href} onClick={(e: any) => navigateTo(history, result.href, e)}>
							{result.label}
						</a>
					</div>
				);
			})}
		</>
	);
};
