import { Action } from 'redux';

import * as ApiTypes from '@rcp/types';

export enum FogFacilitiesActionType {
	LoadFogFacilitiesRequest = 'loadFogFacilitiesRequest',
	LoadFogFacilitiesSuccess = 'loadFogFacilitiesSuccess',
	DownloadFogFacilitiesRequest = 'downloadFogFacilitiesRequest',
	DownloadFogFacilitiesSuccess = 'downloadFogFacilitiesSuccess'
}

export interface LoadFogFacilitiesRequest extends Action {
	type: FogFacilitiesActionType.LoadFogFacilitiesRequest;
}

export interface LoadFogFacilitiesSuccess extends Action {
	type: FogFacilitiesActionType.LoadFogFacilitiesSuccess;
	paginatedFogFacilities: ApiTypes.PaginatedResult<ApiTypes.FogFacility>;
}

export interface DownloadFogFacilitiesRequest extends Action {
	type: FogFacilitiesActionType.DownloadFogFacilitiesRequest;
}

export interface DownloadFogFacilitiesSuccess extends Action {
	type: FogFacilitiesActionType.DownloadFogFacilitiesSuccess;
}

export type FogFacilitiesAction =
	| LoadFogFacilitiesRequest
	| LoadFogFacilitiesSuccess
	| DownloadFogFacilitiesRequest
	| DownloadFogFacilitiesSuccess;

export const loadFogFacilitiesRequest = (): LoadFogFacilitiesRequest => ({
	type: FogFacilitiesActionType.LoadFogFacilitiesRequest
});

export const loadFogFacilitiesSuccess = (
	paginatedFogFacilities: ApiTypes.PaginatedResult<ApiTypes.FogFacility>
): LoadFogFacilitiesSuccess => ({
	type: FogFacilitiesActionType.LoadFogFacilitiesSuccess,
	paginatedFogFacilities: paginatedFogFacilities
});

export const downloadFogFacilitiesRequest = (): DownloadFogFacilitiesRequest => ({
	type: FogFacilitiesActionType.DownloadFogFacilitiesRequest
});

export const downloadFogFacilitiesSuccess = (): DownloadFogFacilitiesSuccess => ({
	type: FogFacilitiesActionType.DownloadFogFacilitiesSuccess
});
