import _ from 'lodash';
import React from 'react';
import { CloseSvg } from 'src/components';
import { localizationService } from 'src/services';
import { MapSettings } from '../map-service';

interface Props {
	preference: MapSettings;
	setPreference: (mapPreference: MapSettings) => void;
	handleClose: () => void;
}

export const FloatMapSettings: React.FC<Props> = props => {
	return (
		<div className="map-float-right">
			<div className="map-float-right-wrapper position-fixed">
				<h2 className="d-flex">
					<span>{localizationService.getLocalizedString('screen.labels.preference')}</span>
					<button className="close ml-auto column-editor-close" onClick={props.handleClose}>
						<CloseSvg />
					</button>
				</h2>
				<div className="map-float-right-wrapper-body">Stay tuned.</div>
			</div>
		</div>
	);
};
