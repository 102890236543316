import React from 'react';
import { localizationService } from 'src/services';

export const ConstructionView: React.FC<{}> = props => {
	return (
		<div className="page">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('screen.construction.title')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section className="min-height-180px">
						<p>{localizationService.getLocalizedString('screen.construction.message')}</p>
					</section>
				</div>
			</div>
		</div>
	);
};
