import React, { FC } from 'react';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TextInput } from 'src/components/widgets/text-input';
import { DateUtilService } from 'src/services';
import { localizationService } from 'src/services/localizationService';

interface Props {
	submissionDateTimeLocal?: string;
	submitterFirstName?: string;
	submitterLastName?: string;
	submitterTitleRole?: string;
	className?: string;
}

const IppPackageItemSubmissionConfirmation: FC<Props> = (props: Props) => {
	const getSubmittedDate = () => {
		return `${DateUtilService.toDisplayDate(props.submissionDateTimeLocal)} ${DateUtilService.toDisplayTime(
			props.submissionDateTimeLocal
		).toLocaleUpperCase()}`;
	};

	const getSubmittedBy = () => {
		return `${props.submitterFirstName || ''} ${props.submitterLastName || ''}`;
	};
	return (
		<>
			<CollapsibleCard
				className={props.className ? props.className : ''}
				accordionType="submission-confirmation"
				accordionHeading={localizationService.getLocalizedString(
					'ipp.reportPackage.package.submissionConfirmation'
				)}>
				<div className="w-75 mx-auto">
					<div className="form-row">
						<TextInput
							id="submitted-by"
							name="submittedBy"
							label={localizationService.getLocalizedString('ipp.reportPackage.package.submittedBy')}
							className="col-md-5"
							isDisabled={true}
							value={getSubmittedBy()}
						/>
						<div className="col-md-1" />
						<TextInput
							id="title"
							name="submitterTitleRole"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.submitterTitleRole'
							)}
							className="col-md-5"
							isDisabled={true}
							value={props.submitterTitleRole}
						/>
					</div>
					<div className="form-row">
						<TextInput
							id="submission-date"
							name="submittedDate"
							label={localizationService.getLocalizedString(
								'ipp.reportPackage.package.columns.submissionDateTimeLocal'
							)}
							className="col-md-5"
							isDisabled={true}
							value={getSubmittedDate()}
						/>
					</div>
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageItemSubmissionConfirmation;
