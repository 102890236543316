import * as React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import { alertService, RootState } from 'src/redux';
import {
	ImportSampleSteps,
	IppDataSourceCollectionMethod,
	IppDataSourceCtsEventType,
	IppDataSourceMonitoringPoint,
	IppDataSourceParameter,
	IppDataSourceUnit,
	IppMassLoadingUnit,
	RouteProps
} from '@rcp/types';
import { History } from 'history';
import EditableGrid from 'src/components/service-provider/industry/data-providers/translations/editable-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { ippMonitoringPointSlice } from 'src/components/service-provider/industry/data-providers/translations/monitoring-point-slice';
import { ippCollectionMethodSlice } from 'src/components/service-provider/industry/data-providers/translations/collection-method-slice';
import { ippCtsEventTypeSlice } from 'src/components/service-provider/industry/data-providers/translations/cts-event-type-slice';
import { ippParameterSlice } from 'src/components/service-provider/industry/data-providers/translations/parameter-slice';
import { ippUnitSlice } from 'src/components/service-provider/industry/data-providers/translations/units-slice';
import { ippSampleImportTranslationSlice } from '../import-translation-slice';
import _ from 'lodash';
import { IppConstants } from 'src/constants';

interface Props extends RouteProps {
	vertical?: boolean;
	history: History;
}

const { sortDropDownQueryParam, sortTranslationsQueryParam } = IppConstants.translations;

const Step4: React.FunctionComponent<Props> = props => {
	const stepperContext = React.useContext(StepperContext);
	const step1Data: any = stepperContext.getData(ImportSampleSteps.STEP1);
	const step2Data: any = stepperContext.getData(ImportSampleSteps.STEP2);
	const step3Data: any = stepperContext.getData(ImportSampleSteps.STEP3);

	const dispatch = useDispatch();

	let monitoringPointState = (state: RootState) => state.ippMonitoringPoint;
	let dataSourceMonitoringPointState = useSelector(monitoringPointState);

	let collectionMethodState = (state: RootState) => state.ippCollectionMethod;
	let dataSourceCollectionMethodState = useSelector(collectionMethodState);

	let eventTypeState = (state: RootState) => state.ippCtsEventType;
	let dataSourceCtsEventTypeState = useSelector(eventTypeState);

	let parameterState = (state: RootState) => state.ippParameter;
	let dataSourceParameterState = useSelector(parameterState);

	let unitState = (state: RootState) => state.ippUnit;
	let dataSourceUnitState = useSelector(unitState);

	let importTranslationState = (state: RootState) => state.ippSampleImportTranslation;
	let dataSourceImportTranslation = useSelector(importTranslationState);

	let [dataSourceMonitoringPoints, setDataSourceMonitoringPoints] = React.useState<IppDataSourceMonitoringPoint[]>();
	let [dataSourceCollectionMethods, setDataSourceCollectionMethods] = useState<IppDataSourceCollectionMethod[]>();
	let [dataSourceCtsEventTypes, setDataSourceCtsEventTypes] = useState<IppDataSourceCtsEventType[]>();
	let [dataSourceParameters, setDataSourceParameters] = useState<IppDataSourceParameter[]>();
	let [dataSourceUnits, setDataSourceUnits] = useState<IppDataSourceUnit[]>();
	let [dataSourceUntranslatedMonitoringPoints, setDataSourceUntranslatedMonitoringPoints] = React.useState<
		IppDataSourceMonitoringPoint[]
	>();
	let [dataSourceUntranslatedCollectionMethods, setDataSourceUntranslatedCollectionMethods] = useState<
		IppDataSourceCollectionMethod[]
	>();
	let [dataSourceUntranslatedCtsEventTypes, setDataSourceUntranslatedCtsEventTypes] = useState<
		IppDataSourceCtsEventType[]
	>();
	let [dataSourceUntranslatedParameters, setDataSourceUntranslatedParameters] = useState<IppDataSourceParameter[]>();
	let [dataSourceUntranslatedUnits, setDataSourceUntranslatedUnits] = useState<IppDataSourceUnit[]>();

	let [monitoringPoints, setMonitoringPoints] = useState();
	let [collectionMethods, setCollectionMethods] = useState();
	let [eventTypes, setEventTypes] = useState();
	let [parameters, setParameters] = useState();
	let [units, setUnits] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const getMonitoringPoints = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppMonitoringPoints, sortDropDownQueryParam);
		let monitoringPoints: any = await apiService.getResource(url);
		setMonitoringPoints(monitoringPoints);
	};

	const getCollectionMethods = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppCollectionMethods, sortDropDownQueryParam);
		let collectionMethods: any = await apiService.getResource(url);
		setCollectionMethods(collectionMethods);
	};

	const getEventTypes = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.CtsEventTypes + '?isForSample=true');
		let eventTypes: any = await apiService.getResource(url);
		setEventTypes(eventTypes);
	};

	const getParameters = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppParameters, sortDropDownQueryParam);
		let parameters: any = await apiService.getResource(url);
		setParameters(parameters);
	};

	const getUnits = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppUnits, sortDropDownQueryParam);
		let units: any = await apiService.getResource(url);
		units = units.filter((unit: IppMassLoadingUnit) => unit.isActive && unit.isAvailableToRegulatee);
		setUnits(units);
	};

	useEffect(() => {
		dispatch(
			ippMonitoringPointSlice.fetchAll(`dataSourceId=${step1Data.dataSourceId}${sortTranslationsQueryParam}`)
		);
		dispatch(
			ippCollectionMethodSlice.fetchAll(`dataSourceId=${step1Data.dataSourceId}${sortTranslationsQueryParam}`)
		);
		dispatch(ippCtsEventTypeSlice.fetchAll(`dataSourceId=${step1Data.dataSourceId}${sortTranslationsQueryParam}`));
		dispatch(ippParameterSlice.fetchAll(`dataSourceId=${step1Data.dataSourceId}${sortTranslationsQueryParam}`));
		dispatch(ippUnitSlice.fetchAll(`dataSourceId=${step1Data.dataSourceId}${sortTranslationsQueryParam}`));
		dispatch(
			ippSampleImportTranslationSlice.createOne({
				dataSourceId: step1Data.dataSourceId,
				importTempFileId: step2Data.importTempFileId
			})
		);
		getMonitoringPoints();
		getCollectionMethods();
		getEventTypes();
		getParameters();
		getUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(
			ippSampleImportTranslationSlice.createOne({
				dataSourceId: step1Data.dataSourceId,
				importTempFileId: step2Data.importTempFileId
			})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dataSourceMonitoringPointState.result,
		dataSourceMonitoringPointState.result.length,
		dataSourceCollectionMethodState.result,
		dataSourceCollectionMethodState.result.length,
		dataSourceCtsEventTypeState.result,
		dataSourceCtsEventTypeState.result.length,
		dataSourceParameterState.result,
		dataSourceParameterState.result.length,
		dataSourceUnitState.result,
		dataSourceUnitState.result.length
	]);

	useEffect(() => {
		let dataTranslations = dataSourceImportTranslation.selected;
		let collectionMethods = (dataTranslations && dataTranslations.dataSourceCollectionMethods) || [];
		let monitoringPoints = (dataTranslations && dataTranslations.dataSourceMonitoringPoints) || [];
		let ctsEventTypes = (dataTranslations && dataTranslations.dataSourceCtsEventTypes) || [];
		let parameters = (dataTranslations && dataTranslations.dataSourceParameters) || [];
		let units = (dataTranslations && dataTranslations.dataSourceUnits) || [];

		let translatedCollectionMethods = getPartitionedData(collectionMethods)[0];
		let untranslatedCollectionMethods = getPartitionedData(collectionMethods)[1];
		let translatedMonitoringPoints = getPartitionedData(monitoringPoints)[0];
		let untranslatedMonitoringPoints = getPartitionedData(monitoringPoints)[1];
		let translatedCtsEventTypes = getPartitionedData(ctsEventTypes)[0];
		let untranslatedCtsEventTypes = getPartitionedData(ctsEventTypes)[1];
		let translatedParameters = getPartitionedData(parameters)[0];
		let untranslatedParameters = getPartitionedData(parameters)[1];
		let translatedUnits = getPartitionedData(units)[0];
		let untranslatedUnits = getPartitionedData(units)[1];

		setDataSourceCollectionMethods(translatedCollectionMethods);
		setDataSourceUntranslatedCollectionMethods(untranslatedCollectionMethods);
		setDataSourceMonitoringPoints(translatedMonitoringPoints);
		setDataSourceUntranslatedMonitoringPoints(untranslatedMonitoringPoints);
		setDataSourceCtsEventTypes(translatedCtsEventTypes);
		setDataSourceUntranslatedCtsEventTypes(untranslatedCtsEventTypes);
		setDataSourceParameters(translatedParameters);
		setDataSourceUntranslatedParameters(untranslatedParameters);
		setDataSourceUnits(translatedUnits);
		setDataSourceUntranslatedUnits(untranslatedUnits);
	}, [dataSourceImportTranslation]);

	useEffect(() => {
		if (dataSourceImportTranslation.selected) {
			setIsLoading(false);
		}
	}, [dataSourceImportTranslation.selected]);

	const getPartitionedData = (entity: any) => {
		return _.partition(entity, item => item.dataSourceId !== 0);
	};

	const onClickNext = async (event: React.FormEvent) => {
		alertService.clearAllMessages();
		let postData = {
			items: [
				{
					dataSourceId: step1Data.dataSourceId,
					importTempFileId: step2Data.importTempFileId,
					...step3Data
				}
			]
		};
		try {
			const url = urlService.getAuthorityResourcesApiUrl(`${Resource.IppSampleImport}/ValidateData`);
			let samplesValidation = await apiService.httpPost(url, postData);
			if (samplesValidation.validationErrors && samplesValidation.validationErrors.length) {
				alertService.addError(localizationService.getLocalizedString('ipp.samples.errors.invalidTranslations'));
				stepperContext.resolve({
					errors: samplesValidation.validationErrors,
					samples: undefined
				});
			} else {
				alertService.addSuccess(
					localizationService.getLocalizedString('ipp.samples.importSamples.step4ValidationSuccessMessage')
				);
				stepperContext.resolve({ samples: samplesValidation.samples });
			}
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const back = () => stepperContext.goAt(ImportSampleSteps.STEP3);

	return (
		<StepperContent
			id="updateDataTranslations"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn ai-white mr-2" id="btnBack" onClick={back}>
						{localizationService.getLocalizedString('screen.buttons.back')}
					</StepperAction>
					{}
					<StepperAction
						type="button"
						id="btnNext"
						className="btn ai-action ml-auto mr-0"
						onClick={onClickNext}>
						{localizationService.getLocalizedString('screen.buttons.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<div className="form-row">
				{!isLoading && (
					<p>
						{(dataSourceUntranslatedMonitoringPoints && dataSourceUntranslatedMonitoringPoints.length) ||
						(dataSourceUntranslatedCollectionMethods && dataSourceUntranslatedCollectionMethods.length) ||
						(dataSourceUntranslatedCtsEventTypes && dataSourceUntranslatedCtsEventTypes.length) ||
						(dataSourceUntranslatedParameters && dataSourceUntranslatedParameters.length) ||
						(dataSourceUntranslatedUnits && dataSourceUntranslatedUnits.length)
							? localizationService.getLocalizedString(
									'ipp.samples.importSamples.step4MessageForMissingData'
							  )
							: localizationService.getLocalizedString(
									'ipp.samples.importSamples.step4MessageForNoMissingData'
							  )}
					</p>
				)}
				<EditableGrid<IppDataSourceMonitoringPoint>
					history={props.history}
					accordionType="monitoring-points"
					dropDownData={monitoringPoints}
					restSlice={ippMonitoringPointSlice}
					match={props.match}
					dropDownId="monitoringPointId"
					dataId="dataSourceMonitoringPointId"
					localizationPrefix="monitoringPoints"
					hideCreateButton={true}
					dataSourceId={step1Data.dataSourceId}
					includeUntranslated={Boolean(
						dataSourceUntranslatedMonitoringPoints && dataSourceUntranslatedMonitoringPoints.length
					)}
					translatedList={dataSourceMonitoringPoints || []}
					unTranslatedList={dataSourceUntranslatedMonitoringPoints || []}
					disableRemove={true}
					sortTranslationsQueryParam={sortTranslationsQueryParam}
				/>
				<EditableGrid<IppDataSourceCollectionMethod>
					history={props.history}
					accordionType="collection-methods"
					dropDownData={collectionMethods}
					restSlice={ippCollectionMethodSlice}
					match={props.match}
					dropDownId="collectionMethodId"
					dataId="dataSourceCollectionMethodId"
					localizationPrefix="collectionMethods"
					hideCreateButton={true}
					dataSourceId={step1Data.dataSourceId}
					includeUntranslated={Boolean(
						dataSourceUntranslatedCollectionMethods && dataSourceUntranslatedCollectionMethods.length
					)}
					translatedList={dataSourceCollectionMethods || []}
					unTranslatedList={dataSourceUntranslatedCollectionMethods || []}
					disableRemove={true}
					sortTranslationsQueryParam={sortTranslationsQueryParam}
				/>
				<EditableGrid<IppDataSourceCtsEventType>
					history={props.history}
					accordionType="sample-types"
					dropDownData={eventTypes}
					restSlice={ippCtsEventTypeSlice}
					match={props.match}
					dropDownId="ctsEventTypeId"
					dataId="dataSourceCtsEventTypeId"
					localizationPrefix="sampleTypes"
					hideCreateButton={true}
					dataSourceId={step1Data.dataSourceId}
					includeUntranslated={Boolean(
						dataSourceUntranslatedCtsEventTypes && dataSourceUntranslatedCtsEventTypes.length
					)}
					translatedList={dataSourceCtsEventTypes || []}
					unTranslatedList={dataSourceUntranslatedCtsEventTypes || []}
					disableRemove={true}
					sortTranslationsQueryParam={sortTranslationsQueryParam}
				/>
				<EditableGrid<IppDataSourceUnit>
					history={props.history}
					accordionType="unit"
					dropDownData={units}
					restSlice={ippUnitSlice}
					match={props.match}
					dropDownId="unitId"
					dataId="dataSourceUnitId"
					localizationPrefix="units"
					hideCreateButton={true}
					dataSourceId={step1Data.dataSourceId}
					includeUntranslated={Boolean(dataSourceUntranslatedUnits && dataSourceUntranslatedUnits.length)}
					translatedList={dataSourceUnits || []}
					unTranslatedList={dataSourceUntranslatedUnits || []}
					disableRemove={true}
					sortTranslationsQueryParam={sortTranslationsQueryParam}
				/>
				<EditableGrid<IppDataSourceParameter>
					history={props.history}
					accordionType="parameters"
					dropDownData={parameters}
					restSlice={ippParameterSlice}
					match={props.match}
					dropDownId="parameterId"
					dataId="dataSourceParameterId"
					localizationPrefix="parameters"
					hideCreateButton={true}
					dataSourceId={step1Data.dataSourceId}
					includeUntranslated={Boolean(
						dataSourceUntranslatedParameters && dataSourceUntranslatedParameters.length
					)}
					translatedList={dataSourceParameters || []}
					unTranslatedList={dataSourceUntranslatedParameters || []}
					disableRemove={true}
					sortTranslationsQueryParam={sortTranslationsQueryParam}
				/>
			</div>
		</StepperContent>
	);
};

export default Step4;
