import React, { useEffect, useState } from 'react';
import { SideNavMenu } from './side-nav-menu';
import { tokenService, urlService, Resource, localizationService, apiService } from 'src/services';
import _ from 'lodash';
import { useReduxSelector } from 'src/redux';
import { FeatureNames, Token, IppIndustry, OrganizationTypeName, FormFieldBusiness } from '@rcp/types';
import './side-nav.scss';
import { IppConstants, RegulatoryProgramName } from 'src/constants';
import { useLocation } from 'react-router-dom';

type Prop = {
	program: string;
	hideScheduledReports?: boolean;
};

export const SideNav: React.FC<Prop> = props => {
	const location = useLocation();
	const [reportPackagesCount, setReportPackagesCount] = useState<any>();
	const [usersCount, setUsersCount] = useState<any>();
	const [importFileSettingsCount, setImportFileSettingsCount] = useState<any>();
	const [samplesCount, setSamplesCount] = useState<any>();
	const [businessInformation, setBusinessInformation] = useState<FormFieldBusiness>({});

	const showFogMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);
	const showFogIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);

	const getReportPackagesCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppReportPackages + '/Count');
		const reportPackageCount = await apiService.getResource(url);
		setReportPackagesCount(reportPackageCount);
	};

	const getUsersCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppUsers + '/Count');
		const usersCount = await apiService.getResource(url);
		setUsersCount(usersCount);
	};

	const getImportFileSettingsCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppUnitTranslations + '/Count');
		const importFileSettingsCount = await apiService.getResource(url);
		setImportFileSettingsCount(importFileSettingsCount);
	};

	const getSamplesCount = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppIndustrySamples + '/Count');
		const sampleCounts = await apiService.getResource(url);
		setSamplesCount(sampleCounts);
	};

	const loadCounts = async () => {
		let regulatoryProgramNames = tokenService.getTokenOrDefault().portalRegulatoryProgramNames;
		let programs = regulatoryProgramNames && regulatoryProgramNames.toLowerCase().split(',');
		if (programs && programs.includes(RegulatoryProgramName.ipp.toLowerCase())) {
			getReportPackagesCount();
			getUsersCount();
			if (urlService.isIppIndustryPortal()) {
				getSamplesCount();
			} else if (urlService.isIppAuthorityPortal()) {
				getImportFileSettingsCount();
			}
		}
	};

	const loadBusinessInformation = async () => {
		const portalOrganizationName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
		let businessInformationEndPoint = '';
		if (String.equalCaseInsensitive(portalOrganizationName, OrganizationTypeName.Hauler)) {
			businessInformationEndPoint = Resource.Haulers + '/' + Resource.BusinessInformation;
		} else if (String.equalCaseInsensitive(portalOrganizationName, OrganizationTypeName.Facility)) {
			businessInformationEndPoint = Resource.FogFacilities + '/' + Resource.BusinessInformation;
		}
		let url = urlService.getAuthorityResourcesApiUrl(businessInformationEndPoint);
		const businessInformation: FormFieldBusiness = await apiService.httpGet(url);
		setBusinessInformation(businessInformation);
	};

	useEffect(() => {
		(urlService.isHaulerPortal() || urlService.isFacilityPortal()) && loadBusinessInformation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tokenService.getTokenOrDefault().guid]);

	useEffect(() => {
		if (urlService.isIppAuthorityPortal() || urlService.isIppIndustryPortal()) loadCounts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	const showInDevelopmentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowInDevelopmentFeature]
	);

	const ReportPackageSideNavOptions = (reportPackageCounts?: any) => {
		let sideNavOptions = [
			{
				title: localizationService.getLocalizedString('ipp.sideNav.pendingReviews'),
				href: authorityReportPackagesBaseUrl + '/SubmittedPendingReview',
				count:
					reportPackageCounts && reportPackageCounts.length
						? Number(reportPackageCounts[IppConstants.submittedPendingReviewIndex].count)
						: 0,
				countColor: 'submitted-btn'
			},
			{
				title: localizationService.getLocalizedString('ipp.sideNav.newRepudiations'),
				href: authorityReportPackagesBaseUrl + '/RepudiatedPendingReview',
				count:
					reportPackageCounts && reportPackageCounts.length
						? Number(reportPackageCounts[IppConstants.repudiatedReviewIndex].count)
						: 0,
				countColor: 'repudiated-btn'
			},
			{
				title: localizationService.getLocalizedString('ipp.sideNav.all'),
				href: authorityReportPackagesBaseUrl + '/All'
			}
		];

		if (
			reportPackageCounts &&
			reportPackageCounts.length &&
			Number(reportPackageCounts[IppConstants.corruptedCORReviewIndex].count) > 0
		) {
			sideNavOptions.push({
				title: localizationService.getLocalizedString('ipp.sideNav.errors'),
				count: Number(reportPackageCounts[IppConstants.corruptedCORReviewIndex].count),
				href: authorityReportPackagesBaseUrl + '/ErrorsReview',
				countColor: 'error-btn'
			});
		}

		return sideNavOptions;
	};

	const FogSideNav = () => (
		<aside className="menu-toggle d-print-none">
			<div className="aside-content">
				<nav role="navigation" aria-label="Primary menu">
					<SideNavMenu title="Dashboard" link={`/fog/${Resource.Dashboard}`} />
					{showFogMapFeature && <SideNavMenu title="Map" link={'/fog/map'} />}
					<SideNavMenu title="Facilities" link={`/fog/${Resource.FogFacilities}`} />
					<SideNavMenu title="Inspections" link={'/fog/Inspections'} />
					<SideNavMenu title="Cleanings" link={`/fog/${Resource.Cleanings}`} />
					<SideNavMenu title="Devices" link={'/fog/devices'} />
					<SideNavMenu title="Events" link={'/fog/events'} />
					<SideNavMenu title="Violations" link={'/fog/violations'} />
					<SideNavMenu title="Haulers" link={'/fog/haulers'} />
					<SideNavMenu title="Notice outbox" link={'/fog/notices'} />
					{showFogIncidentFeature && (
						<SideNavMenu
							title={localizationService.getLocalizedString('incidents.incidents')}
							link={'/fog/incidents'}
						/>
					)}
				</nav>
			</div>
		</aside>
	);

	const showOrganizationDetails = () => {
		const { businessName, addressLine1 } = businessInformation;
		return (
			<div className="font-size-14px-regular">
				{businessName && (
					<>
						<hr />
						<p className="text-break">
							{businessName}
							{addressLine1 && <>,&nbsp;{addressLine1}</>}
						</p>
					</>
				)}
			</div>
		);
	};

	const haulerBaseUrl = urlService.getUrlWithRegulateeTypeAndGuid();
	const HaulerPortalSideNav = () => (
		<aside className="menu-toggle d-print-none">
			<div className="aside-content d-flex flex-column h-100 justify-content-between">
				<nav role="navigation" aria-label="Primary menu">
					<SideNavMenu title="Facilities" link={`/${haulerBaseUrl}/facilities`} />
					<SideNavMenu title="Cleaning history" link={`/${haulerBaseUrl}/cleaningHistory`} />
				</nav>
				{showOrganizationDetails()}
			</div>
		</aside>
	);

	const facilityPortalBaseUrl = urlService.getUrlWithRegulateeTypeAndGuid();
	const FacilityPortalSideNav = () => (
		<aside className="menu-toggle d-print-none">
			<div className="aside-content d-flex flex-column h-100 justify-content-between">
				<nav role="navigation" aria-label="Primary menu">
					<SideNavMenu title="Devices" link={`/${facilityPortalBaseUrl}/Devices`} />
					<SideNavMenu title="Cleaning history" link={`/${facilityPortalBaseUrl}/cleaningHistory`} />
				</nav>
				{showOrganizationDetails()}
			</div>
		</aside>
	);

	const CCCSideNav = () => (
		<aside className="menu-toggle d-print-none">
			<div className="aside-content">
				<nav role="navigation" aria-label="Primary menu">
					<SideNavMenu title="Sites" link={`/backflow/sites`} />
					<SideNavMenu title="Hazards" link={`/backflow/hazards`} />
					{showInDevelopmentFeature && <SideNavMenu title="Tests" link={`/backflow/tests`} />}
					<SideNavMenu
						title={localizationService.getLocalizedString('ccc.notices.gridTitle')}
						link={`/backflow/notices`}
					/>
					{showInDevelopmentFeature && (
						<div>
							<SideNavMenu title="Inspections" link={`/backflow/inspections`} />
							<SideNavMenu title="Installations" link={`/backflow/installations`} />
							<SideNavMenu title="Repairs" link={`/backflow/repairs`} />
							<SideNavMenu title="Letter history" link={`/backflow/letterHistory`} />
							<br />
							<SideNavMenu title="Technician companies" link={`/backflow/companies`} />
							<SideNavMenu title="Technicians" link={`/backflow/technicians`} />
							<SideNavMenu title="Test kits" link={`/backflow/testKits`} />
						</div>
					)}
				</nav>
			</div>
		</aside>
	);

	interface IPPAuthoritySideNavProps {
		reportPackagesCount: any;
		usersCount: any;
		importFileSettingsCount: any;
		user?: any;
	}
	const authorityBaseUrl = '/ipp/authority';
	const authorityReportPackagesBaseUrl = authorityBaseUrl + '/reportPackages';
	const authorityReportSettingsBaseUrl = authorityBaseUrl + '/reportSettings';
	const authorityAccountBaseUrl = authorityBaseUrl + '/authorityAccount';

	const IPPAuthoritySideNav = (props: IPPAuthoritySideNavProps) => (
		<aside className="menu-toggle d-print-none ipp-sidenav-width">
			<div className="aside-content ipp-sidenav">
				<nav role="navigation" aria-label="Primary menu">
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.industries')}
						link={authorityBaseUrl + `/industries`}
					/>
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.reportPackages')}
						id="report-packages"
						link={ReportPackageSideNavOptions(props.reportPackagesCount)}
					/>
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.reportSettings')}
						id="report-settings"
						link={[
							{
								title: localizationService.getLocalizedString('ipp.sideNav.templates'),
								href: authorityReportSettingsBaseUrl + '/reportPackageTemplates'
							},
							{
								title: localizationService.getLocalizedString('ipp.sideNav.reportElements'),
								href: authorityReportSettingsBaseUrl + '/reportElementTypes'
							},
							{
								title: localizationService.getLocalizedString('ipp.sideNav.parameterGroups'),
								href: authorityReportSettingsBaseUrl + '/parameterGroups'
							}
						]}
					/>
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.importFileSettings')}
						id="import-file-settings"
						link={[
							{
								title: localizationService.getLocalizedString('ipp.sideNav.sampleImportTemplate'),
								href: authorityBaseUrl + '/sample-import-templates'
							},
							{
								title: localizationService.getLocalizedString('ipp.sideNav.unitTranslations'),
								href: authorityBaseUrl + '/unit-translations',
								count: props.importFileSettingsCount ? Number(props.importFileSettingsCount.count) : 0,
								countColor: 'ai-action'
							}
						]}
					/>
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.authorityAccount')}
						id="authority-account"
						link={[
							{
								title: localizationService.getLocalizedString('ipp.sideNav.users'),
								href: authorityAccountBaseUrl + '/users'
							},
							{
								title: localizationService.getLocalizedString('ipp.sideNav.settings'),
								href: authorityAccountBaseUrl + '/settings'
							},
							{
								title: localizationService.getLocalizedString('ipp.sideNav.acpConnectorSetting'),
								href: authorityAccountBaseUrl + '/acpConnector',
								hide: !!!props.user.isInternalAccount
							}
						]}
					/>
					<SideNavMenu
						id="pending-registration"
						title={localizationService.getLocalizedString('ipp.sideNav.pendingRegistrations')}
						link={authorityBaseUrl + `/pendingRegistrations`}
						count={props.usersCount ? Number(props.usersCount.count) : 0}
						countColor="ai-action"
					/>
					<SideNavMenu
						title={localizationService.getLocalizedString('ipp.sideNav.auditLogs')}
						link={authorityBaseUrl + `/auditLogs`}
					/>
				</nav>
			</div>
		</aside>
	);

	const downloadPermitlimits = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppDownloadPermitLimits);
		await apiService.getResource(url);
	};
	let { guid } = urlService.getPortalStatus();
	const industryBaseUrl = '/industry/' + guid;
	const industryAccountBaseUrl = industryBaseUrl + `/industryAccount/`;
	const industrySampleBaseUrl = industryBaseUrl + '/samples';
	const industryReportPackageBaseUrl = industryBaseUrl + '/reportPackages';

	interface IPPIndustrySideNavProps {
		reportPackagesCount: any;
		usersCount: any;
		samplesCount: any;
		industry?: IppIndustry;
	}

	const IPPIndustrySideNav = (props: IPPIndustrySideNavProps) => {
		const { industryName, addressLine1, showScheduledReport } = props.industry || {};

		return (
			<aside className="menu-toggle d-print-none ipp-sidenav-width">
				<div className="aside-content ipp-sidenav pb-0 d-flex flex-column h-100 justify-content-between">
					<nav role="navigation" aria-label="Primary menu">
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.dashboard')}
							id="dashboard"
							link={industryBaseUrl + '/dashboard'}
						/>
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.samples')}
							id="samples"
							itemHeaderClass="mt-0"
							link={[
								{
									title: localizationService.getLocalizedString('ipp.sideNav.draft'),
									href: industrySampleBaseUrl + '/draft',
									count:
										props.samplesCount && props.samplesCount.length
											? Number(props.samplesCount[0].count)
											: 0,
									countColor: 'draft-btn'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.readyToReport'),
									href: industrySampleBaseUrl + '/readyToReport',
									count:
										props.samplesCount && props.samplesCount.length
											? Number(props.samplesCount[1].count)
											: 0,
									countColor: 'ready-to-report'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.reported'),
									href: industrySampleBaseUrl + '/reported'
								}
							]}
						/>
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.attachments')}
							link={industryBaseUrl + '/attachment'}
						/>
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.reportPackages')}
							id="report-packages"
							link={[
								{
									title: localizationService.getLocalizedString('ipp.sideNav.scheduled'),
									href: industryReportPackageBaseUrl + '/scheduled',
									count:
										props.reportPackagesCount && props.reportPackagesCount.length
											? Number(props.reportPackagesCount[3].count)
											: 0,
									countColor: 'rejected-btn',
									hide: !Boolean(showScheduledReport)
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.draft'),
									href: industryReportPackageBaseUrl + '/draft',
									count:
										props.reportPackagesCount && props.reportPackagesCount.length
											? Number(props.reportPackagesCount[0].count)
											: 0,
									countColor: 'draft-btn'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.readyToSubmit'),
									href: industryReportPackageBaseUrl + '/readyToSubmit',
									count:
										props.reportPackagesCount && props.reportPackagesCount.length
											? Number(props.reportPackagesCount[1].count)
											: 0,
									countColor: 'readytosubmit-btn'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.pendingAcceptance'),
									href: industryReportPackageBaseUrl + '/pendingAcceptance',
									count:
										props.reportPackagesCount && props.reportPackagesCount.length
											? Number(props.reportPackagesCount[4].count)
											: 0,
									countColor: 'pendingacceptance-btn'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.rejected'),
									href: industryReportPackageBaseUrl + '/rejected',
									count:
										props.reportPackagesCount && props.reportPackagesCount.length
											? Number(props.reportPackagesCount[2].count)
											: 0,
									countColor: 'rejected-btn'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.submitted'),
									href: industryReportPackageBaseUrl + '/submitted'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.repudiated'),
									href: industryReportPackageBaseUrl + '/repudiated'
								},
								...(props.reportPackagesCount &&
								props.reportPackagesCount.length &&
								props.reportPackagesCount[5].count > 0
									? [
											{
												title: localizationService.getLocalizedString('ipp.sideNav.errors'),
												href: industryReportPackageBaseUrl + '/errors',
												count: Number(props.reportPackagesCount[5].count),
												countColor: 'errors-btn'
											}
									  ]
									: [])
							]}
						/>
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.industryAccount')}
							id="industry-account"
							link={[
								{
									title: localizationService.getLocalizedString('ipp.sideNav.users'),
									href: industryAccountBaseUrl + 'users'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.settings'),
									href: industryAccountBaseUrl + 'settings'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.dataProviders'),
									href: industryAccountBaseUrl + 'dataProviders'
								},
								{
									title: localizationService.getLocalizedString('ipp.sideNav.permitLimits'),
									href: '#',
									selectionTextClass: 'd-block w-100',
									clickHandler: (e: any) => {
										e.preventDefault();
										e.stopPropagation();
										downloadPermitlimits();
									}
								}
							]}
						/>
						<SideNavMenu
							title={localizationService.getLocalizedString('ipp.sideNav.pendingRegistrations')}
							link={industryAccountBaseUrl + 'pendingRegistrations'}
							count={props.usersCount ? Number(props.usersCount.count) : 0}
							countColor="ai-action"
						/>
					</nav>
					{(industryName || addressLine1) && (
						<div className="font-size-14px-regular">
							<hr />
							<p className="text-break">
								{industryName}
								{addressLine1 && <>,&nbsp;{addressLine1}</>}
							</p>
						</div>
					)}
				</div>
			</aside>
		);
	};

	const hasToken = tokenService.isTokenValid();
	const isPublicScreen = urlService.isPublicScreen();
	const isInviteScreen = urlService.isHaulerInviteScreen();
	const shouldDisplayNavMenu = hasToken && !isPublicScreen && !isInviteScreen;
	const userProfile = useReduxSelector(state => state.userProfile.userProfile);
	let regulateeName = tokenService.getTokenOrDefault().portalOrganizationTypeName;
	const [industry, setIndustry] = useState<IppIndustry>({});

	const loadIndustryDetails = async () => {
		let token: Token = tokenService.getTokenOrDefault();
		let url = urlService.getAuthorityResourceApiUrl(
			Resource.IppIndustries,
			Number(token.portalOrganizationRegulatoryProgramId)
		);
		let industry = await apiService.getResource<IppIndustry>(url);
		return industry;
	};

	const addScheduledReports = async () => {
		let industry = await loadIndustryDetails();
		setIndustry(industry);
	};

	useEffect(() => {
		if (urlService.isIppIndustryPortal()) {
			addScheduledReports();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [window.location.href]);

	if (shouldDisplayNavMenu) {
		if (String.equalCaseInsensitive(props.program, 'fog') && _.includes(userProfile.issuedPrograms, 'fog')) {
			return <FogSideNav />;
		}
		if (
			String.equalCaseInsensitive(props.program, 'backflow') &&
			_.includes(userProfile.issuedPrograms, 'backflow')
		) {
			return <CCCSideNav />;
		}
		if (
			String.equalCaseInsensitive(props.program, 'hauler') &&
			location.pathname.includes('hauler') &&
			(_.includes(userProfile.issuedPrograms, 'fog') ||
				_.includes(userProfile.issuedPrograms, 'ipp') ||
				_.includes(userProfile.issuedPrograms, 'backflow'))
		) {
			return <HaulerPortalSideNav />;
		}
		if (
			String.equalCaseInsensitive(props.program, 'facility') &&
			location.pathname.includes('facility') &&
			(_.includes(userProfile.issuedPrograms, 'fog') ||
				_.includes(userProfile.issuedPrograms, 'ipp') ||
				_.includes(userProfile.issuedPrograms, 'backflow'))
		) {
			return <FacilityPortalSideNav />;
		}
		if (String.equalCaseInsensitive(props.program, 'ipp') && _.includes(userProfile.issuedPrograms, 'ipp')) {
			if (regulateeName && String.equalCaseInsensitive(regulateeName, OrganizationTypeName.Industry)) {
				return (
					<IPPIndustrySideNav
						reportPackagesCount={reportPackagesCount}
						usersCount={usersCount}
						samplesCount={samplesCount}
						industry={industry}
					/>
				);
			} else {
				return (
					<IPPAuthoritySideNav
						reportPackagesCount={reportPackagesCount}
						usersCount={usersCount}
						importFileSettingsCount={importFileSettingsCount}
						user={userProfile}
					/>
				);
			}
		}
	}
	return null;
};
