import React from 'react';

import * as ApiTypes from '@rcp/types';
import { EditCommentModal } from '../facility-comment/edit-comment';
import { DateUtilService, localizationService } from 'src/services';

interface OwnProps {
	event: ApiTypes.Comment;
}

type Props = OwnProps;

export const TimelineEventComment: React.SFC<Props> = props => {
	const [showEditCommentModal, setShowEditCommentModal] = React.useState(false);

	const onShowCommentLinkClicked = (e: any) => {
		e.preventDefault();
		setShowEditCommentModal(true);
	};

	const toggleEditCommentModal = () => {
		setShowEditCommentModal(!showEditCommentModal);
	};

	const editedOnDiv = () => {
		let lastModifiedDate = DateUtilService.toDisplayDate(props.event.lastModificationDateTimeUtc);
		let lastModifiedTime = DateUtilService.toDisplayTime(props.event.lastModificationDateTimeUtc);
		let lastModifiedUsername = props.event.lastModifierUserFullName as string;
		let editedAtArguments: string[] = [lastModifiedDate, lastModifiedTime, lastModifiedUsername];
		return props.event.lastModificationDateTimeUtc !== props.event.creationDateTimeUtc ? (
			<div className="timeline-comment-edited-datetime">
				{localizationService.getLocalizedString('comments.commentEditedAt', ...editedAtArguments)}
			</div>
		) : null;
	};

	return (
		<div>
			<div>
				{`${props.event.creatorUserFullName} `}
				<a href="#/" onClick={onShowCommentLinkClicked} id={`linkFacilityComment`}>
					{localizationService.getLocalizedString('comments.commentHref')}
				</a>
				{':'}
			</div>
			<p>{props.event.commentText}</p>
			{editedOnDiv()}
			{showEditCommentModal && (
				<EditCommentModal
					showModal={showEditCommentModal}
					setShowModal={toggleEditCommentModal}
					comment={props.event}
				/>
			)}
		</div>
	);
};
