import { debounce } from 'lodash';
import React from 'react';
import { useSwipeable } from 'react-swipeable';
import { Transition } from 'react-transition-group';
import useEscButton from 'react-bottom-drawer/dist/lib/hooks/useEscButton';
import usePreventScroll from 'react-bottom-drawer/dist/lib/hooks/usePreventScroll';
import { TransitionStyles } from 'react-bottom-drawer/dist/lib/styles';
import useGlobalStyles from 'react-bottom-drawer/dist/lib/hooks/useGlobalStyles';
import clsx from 'clsx';
import useResizeObserver from '@react-hook/resize-observer';
import './mobile-drawer.scss';

interface IProps {
	isVisible: boolean;
	onClose: () => void;
	duration?: number;
	hideScrollbars?: boolean;
	unmountOnExit?: boolean;
	mountOnEnter?: boolean;
	className?: string;
	children: React.ReactNode;
	footer?: React.ReactNode;
}

const MobileDrawer = ({
	isVisible,
	children,
	onClose,
	unmountOnExit = true,
	mountOnEnter = true,
	duration = 250,
	hideScrollbars = false,
	className = '',
	footer
}: IProps) => {
	const classNames = useGlobalStyles(duration, hideScrollbars);
	const nodeRef = React.useRef(null);
	const contentRef = React.useRef(null);
	const drawerFooter = React.useRef(null);
	const heightToClose = 100;
	const heightToReduce = 230;
	const reducedDrawerSize = 180;

	const getDrawerContentMaxHeight = () => {
		const maxDrawerHeight = Math.round(window.innerHeight * 0.7 - 25);
		//@ts-ignore
		return Math.min(maxDrawerHeight, contentRef.current?.scrollHeight);
	};

	// Actions to close
	useEscButton(onClose, isVisible);
	usePreventScroll(isVisible, classNames.contentWrapper);

	// Swiping down interaction
	const [drawerContentHeight, setDrawerContentHeight] = React.useState(0);
	const [heightBeforeSwipe, setHeightBeforeSwipe] = React.useState(0);
	const [isReducedDrawer, setIsReducedDrawer] = React.useState(false);
	const swipeHandlers = useSwipeable({
		onSwipedDown: debounce(
			({ velocity }) => {
				if (velocity > 0.7) {
					return onClose();
				}

				setPositionOnSwipe();
			},
			500,
			{ leading: true }
		),
		onSwipedUp: debounce(
			() => {
				setPositionOnSwipe();
			},
			500,
			{ leading: true }
		),
		onSwiping: ({ deltaY }) => {
			const newHeight = deltaY + heightBeforeSwipe;
			if (newHeight < getDrawerContentMaxHeight()) {
				setDrawerContentHeight(newHeight);
			}
		}
	});

	const setPositionOnSwipe = () => {
		if (drawerContentHeight < heightToClose) {
			return onClose();
		} else if (drawerContentHeight < heightToReduce) {
			setDrawerContentHeight(reducedDrawerSize);
			setHeightBeforeSwipe(reducedDrawerSize);
			setIsReducedDrawer(true);
		} else {
			const maxHeight = getDrawerContentMaxHeight();
			setDrawerContentHeight(maxHeight);
			setHeightBeforeSwipe(maxHeight);
			setIsReducedDrawer(false);
		}
	};

	const resizeDrawerOnContentChange = () => {
		if (!isReducedDrawer) {
			const maxHeight = getDrawerContentMaxHeight();
			setDrawerContentHeight(maxHeight);
			setHeightBeforeSwipe(maxHeight);
		}
	};

	if (drawerContentHeight === 0) {
		debounce(resizeDrawerOnContentChange, 100)();
	}

	React.useEffect(resizeDrawerOnContentChange, [children, footer]);

	const getContentStyles = (): React.CSSProperties => {
		return { maxHeight: `${drawerContentHeight}px` };
	};

	return (
		<>
			<Transition
				appear={true}
				in={isVisible}
				timeout={{ appear: 0, enter: 0, exit: duration }}
				unmountOnExit={unmountOnExit}
				mountOnEnter={mountOnEnter}
				nodeRef={nodeRef}>
				{state => (
					<div ref={nodeRef}>
						<div
							className={`${clsx(className, classNames.drawer)} drawer-container`}
							style={{
								...TransitionStyles[state]
							}}>
							<div
								{...swipeHandlers}
								className={clsx(className && `${className}__handle-wrapper`, classNames.handleWrapper)}>
								<div className={clsx(className && `${className}__handle`, classNames.handle)} />
							</div>
							<div
								className={`${clsx(
									className && `${className}__content`,
									classNames.contentWrapper
								)} drawer-content`}
								style={getContentStyles()}
								ref={contentRef}>
								{children}
								{footer && <div className="drawer-footer">{footer}</div>}
							</div>
							<div className="drawer-padding"></div>
						</div>
					</div>
				)}
			</Transition>
		</>
	);
};

export default MobileDrawer;
