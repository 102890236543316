import { UserProfileActionType, UserProfileAction, UserProfileState, initialUserProfileState } from './user-actions';

export const userProfileReducer = (state = initialUserProfileState, action: UserProfileAction): UserProfileState => {
	switch (action.type) {
		case UserProfileActionType.LoadUserProfileRequest:
			return { ...state };
		case UserProfileActionType.LoadUserProfileSuccess:
			return { ...state, userProfile: action.userProfile };
		default:
			return state;
	}
};
