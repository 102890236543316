import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { Dictionary, FeatureSetting as FeatureFlagSetting } from '@rcp/types';
import { apiService, Resource, tokenService, urlService } from 'src/services';

export interface FeatureFlagSettingsState {
	featureFlagSettings: Dictionary<boolean>;
	isLoaded: boolean;
}

export const initialFeatureSettingsState: FeatureFlagSettingsState = {
	featureFlagSettings: {} as Dictionary<boolean>,
	isLoaded: false
};

enum ActionType {
	LoadFeatureFlagSettingsStateSuccess = 'LoadFeatureFlagSettingsStateSuccess'
}

export type FeatureFlagSettingsThunkAction = ThunkAction<any, FeatureFlagSettingsState, any, Action>;

export const loadFeatureFlagSettings = (): FeatureFlagSettingsThunkAction => async (dispatch: any) => {
	if (tokenService.isTokenValid() && (urlService.isAuthorityPortal() || urlService.isFacilityPortal())) {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.Settings);
		let featureFlagSettings = await apiService.getResource<FeatureFlagSetting[]>(`${url}/Features`);

		dispatch({
			type: ActionType.LoadFeatureFlagSettingsStateSuccess,
			featureFlagSettings: featureFlagSettings,
			isLoaded: true
		});
		return;
	}
	dispatch({
		type: ActionType.LoadFeatureFlagSettingsStateSuccess,
		isLoaded: true
	});
};

export const featureSettingsReducer = (state = initialFeatureSettingsState, action: any): FeatureFlagSettingsState => {
	switch (action.type) {
		case ActionType.LoadFeatureFlagSettingsStateSuccess:
			if (action.featureFlagSettings) {
				let newFeatureFlagSettings = Object.assign(
					{},
					...action.featureFlagSettings.map((x: FeatureFlagSetting) => {
						const name = x.name;
						return { [name]: x.isEnabled ? x.isEnabled : false };
					})
				);

				return { ...state, featureFlagSettings: newFeatureFlagSettings, isLoaded: action.isLoaded };
			}
			return { ...state, isLoaded: action.isLoaded };
		default:
			return state;
	}
};
