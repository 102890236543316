import React from 'react';
import { CleaningEvent, Dictionary, HaulerDeviceCleaning, OrganizationTypeName } from '@rcp/types';
import { DateUtilService, localizationService, urlService, apiService, Resource } from '../../../../services';

import HaulerSubmitCleaningModal from '../../../service-provider/hauler/hauler-facilities/hauler-submit-cleaning/submit-cleaning';
import { CleaningModal } from 'src/components';
import FacilitySubmitCleaningModal from 'src/components/service-provider/facility/submit-cleaning.tsx/submit-facility-cleaning';
import { SeattleAuthorityConstants } from 'src/constants';

interface OwnProps {
	pumpOutEventId: number;
	dateField: string;
	queryParameters?: Dictionary<string>;
	organizationId: number;
}

const { Facility, Hauler, Authority } = OrganizationTypeName;
export const FacilitiesCleaningEditActionLink: React.FC<OwnProps> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const [pumpOutEvent, setPumpOutEvent] = React.useState<CleaningEvent | HaulerDeviceCleaning>();

	const getPumpOutDetails = () => {
		let url = `${urlService.getApiBaseUrl()}/fog/${Resource.FogFacilities}/${props.organizationId}/${
			Resource.Cleanings
		}/${props.pumpOutEventId}`;
		apiService.httpGet(url).then(data => {
			setPumpOutEvent(data);
			setShowModal(true);
		});
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		getPumpOutDetails();
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const formatDate = (dateTime?: string) => {
		return dateTime ? DateUtilService.toDisplayDate(dateTime) : dateTime;
	};

	const getCleaningModal = () => {
		if (pumpOutEvent && String.equalCaseInsensitive(pumpOutEvent.creatorOrganizationType, Authority)) {
			return (
				<CleaningModal
					facilityId={props.organizationId as number}
					showModal={showModal || false}
					isEnterCleaning={false}
					cleaning={pumpOutEvent as CleaningEvent}
					isGridPage={true}
					isFacilityPage={false}
					isFacilitiesGrid={true}
					queryParameters={props.queryParameters}
					isTimelineReloadRequired={false}
					dueDateIsRequired={pumpOutEvent.completeDate ? false : true}
					completeDateIsRequired={pumpOutEvent.completeDate ? true : false}
					modalToggleFunction={toggleModal}
				/>
			);
		} else if (pumpOutEvent && String.equalCaseInsensitive(pumpOutEvent.creatorOrganizationType, Hauler)) {
			return (
				<HaulerSubmitCleaningModal
					showModal={showModal}
					onCancel={toggleModal}
					selectedDevices={[]}
					deviceList={[]}
					cleaningEventId={pumpOutEvent.pumpOutEventId}
					facilityId={pumpOutEvent.organizationId}
					isFacilityDetailsPage={false}
					readOnly={true}
					hideCancel={true}
					authorityOrganizationId={pumpOutEvent.authorityOrganizationId}
					title={localizationService.getLocalizedString('haulerPortal.cleaningHistory.submittedCleaning')}
					isSeattleFacility={
						pumpOutEvent.facilityAuthorityOrganizationName
							? String.equalCaseInsensitive(
									pumpOutEvent.facilityAuthorityOrganizationName,
									SeattleAuthorityConstants.authorityName
							  )
							: false
					}
				/>
			);
		} else if (pumpOutEvent && String.equalCaseInsensitive(pumpOutEvent.creatorOrganizationType, Facility)) {
			return (
				<FacilitySubmitCleaningModal
					showModal={showModal}
					onCancel={toggleModal}
					title={localizationService.getLocalizedString('haulerPortal.submitCleaning.submitCleaning')}
					readOnly
					facilityId={props.organizationId}
					cleaningEventId={pumpOutEvent.pumpOutEventId}
				/>
			);
		}
	};

	return (
		<>
			<a href="#/" className="ai-link" id="inspection-type-link" onClick={onEditClicked}>
				{formatDate(props.dateField)}
			</a>
			{pumpOutEvent && showModal && <>{getCleaningModal()}</>}
		</>
	);
};
