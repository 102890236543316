import _ from 'lodash';
import React from 'react';
import { alertService } from 'src/redux';
import { apiService, localizationService, tokenService, urlService, validationService } from 'src/services';
import { PopoverModal, TextInput } from 'src/components/widgets';
import './authentication-component.scss';

interface OwnProps {
	showModal: boolean;
	modalTitle: string;
	userProfileId: number;
	onCancel: () => void;
	getProfileDetails: () => Promise<void>;
}

interface ChangePasswordFormState {
	password: string;
	confirmPassword: string;
	passwordFormatError: string;
	confirmPasswordFormatError: string;
}

type Props = OwnProps;

export const ChangePasswordModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState({} as ChangePasswordFormState);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const closeModal = () => {
		props.onCancel();
		setFormState({} as ChangePasswordFormState);
	};

	const changePasswordBtnClicked = async (event: any) => {
		alertService.clearAllMessages();
		if (!validateBeforeForSave()) {
			return;
		}
		let { password } = { ...formState };
		const payload = { userProfileId: props.userProfileId, password };
		let url = `${urlService.getApiAccountBaseUrl()}/Account/UserProfile/Password?organizationRegulatoryProgramId=${
			tokenService.getTokenOrDefault().portalOrganizationRegulatoryProgramId
		}`;
		try {
			await apiService.httpPatch(url, payload);
			alertService.addSuccess(
				localizationService.getLocalizedString('ipp.accountPortal.messages.passwordUpdated')
			);
			await props.getProfileDetails();
			closeModal();
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };
		validationService.validatePasswordField(newState, 'password', 'passwordFormatError');
		if (newState.confirmPassword !== newState.password) {
			newState.confirmPasswordFormatError = localizationService.getLocalizedString('users.passwordsNotMatch');
		} else {
			_.unset(newState, 'confirmPasswordFormatError');
		}
		let isFormValid = !validationService.hasError(newState, 'passwordFormatError', 'confirmPasswordFormatError');
		if (!isFormValid) {
			setFormState(newState);
		}
		return isFormValid;
	};

	return (
		<>
			<div className="w-100">
				<PopoverModal
					showModal={props.showModal}
					title={props.modalTitle}
					saveButtonText={localizationService.getLocalizedString('ipp.buttons.changePassword')}
					save={changePasswordBtnClicked}
					cancel={closeModal}>
					<p id="temporary-user-message">{localizationService.getLocalizedString('users.passwordHint')}</p>
					<div className="form-row">
						<TextInput
							type="password"
							id="password"
							name="password"
							className="w-100"
							label={localizationService.getLocalizedString('users.newPassword')}
							value={formState.password}
							onChange={changeFormState}
							isRequired={true}
							error={formState.passwordFormatError}
						/>
					</div>
					<div className="form-row">
						<TextInput
							type="password"
							id="confirmPassword"
							name="confirmPassword"
							className="w-100"
							label={localizationService.getLocalizedString('users.confirmNewPassword')}
							value={formState.confirmPassword}
							onChange={changeFormState}
							isRequired={true}
							error={formState.confirmPasswordFormatError}
						/>
					</div>
				</PopoverModal>
			</div>
		</>
	);
};
