import React from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import { localizationService, urlService } from 'src/services';

interface actionProp {
	clickOnIssueEnforcement: () => void;
	clickOnOpenInspection: (isStartInspection: boolean) => void;
	clickOnNewPumpOut: () => void;
	clickOnDeleteFacility: () => void;
	openRecordViolationModal: () => void;
	openScheduleCleaningModal: () => void;
	openAddEventModal: () => void;
	showExtractorCleaning: boolean;
}

export const FacilityActionDropdown: React.SFC<actionProp> = props => {
	return (
		<DropdownMenu>
			<DropdownItem id="issueEnforcement" tag="a" onClick={props.clickOnIssueEnforcement}>
				{localizationService.getLocalizedString('enforcement.issueEnforcement')}
			</DropdownItem>
			<DropdownItem id="newInspectionOption" tag="a" onClick={() => props.clickOnOpenInspection(false)}>
				{localizationService.getLocalizedString('screen.buttons.scheduleInspection')}
			</DropdownItem>
			{props.showExtractorCleaning && (
				<DropdownItem id="newInspectionOption" tag="a" onClick={() => props.openScheduleCleaningModal()}>
					{localizationService.getLocalizedString('extractor.scheduleCleaning')}
				</DropdownItem>
			)}
			{/* <DropdownItem id="newPumpOutOption" tag="a" onClick={props.clickOnNewPumpOut}>
				{localizationService.getLocalizedString('pumpOut.newCleaning')}
			</DropdownItem> */}
			<DropdownItem id="newPumpOutOption" tag="a" onClick={props.openRecordViolationModal}>
				{localizationService.getLocalizedString('screen.buttons.recordViolation')}
			</DropdownItem>
			<DropdownItem tag="a" onClick={props.openAddEventModal}>
				{localizationService.getLocalizedString('events.addEvent')}
			</DropdownItem>
			{urlService.isAdministrator() && (
				<DropdownItem tag="a" onClick={props.clickOnDeleteFacility}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</DropdownItem>
			)}
		</DropdownMenu>
	);
};
