import { faCheckCircle, faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
	ColumnDefinition,
	IppIndustrySample,
	IppProgramSettings,
	IppReportPackageParameter,
	IppSampleResult,
	ValidationResultError
} from '@rcp/types';
import { IppMassLoadingUnit } from '@rcp/types/src';
import { History } from 'history';
import _ from 'lodash';
import React, { Component, createRef, RefObject } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SingleCheckbox, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { TooltipHover } from 'src/components/widgets/tooltip-hover';
import { IppConstants } from 'src/constants';
import { ApplicationState } from 'src/redux';
import {
	loadFloatNumbers,
	loadMassLoadingUnit,
	loadParameters,
	loadSettings,
	SampleState,
	setSampleData
} from 'src/redux/ipp/industry/samples';
import { CustomDateTimePicker } from 'src/components/widgets/custom-date-input';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import IppSampleParameterAddModal, { IppSampleParameterAddModalProps } from './add-modal';

interface DispatchProps {
	setSampleData: (sample: IppIndustrySample) => Promise<boolean | undefined>;
	getFloatNumbers: (floatData: any, cb: (floatNumbers: any) => any) => Promise<boolean | undefined>;
	loadSettings: () => Promise<boolean | undefined>;
	loadMassLoadingUnit: () => Promise<boolean | undefined>;
	loadParameters: (queryString?: string) => Promise<boolean | undefined>;
}

interface Match {
	params: {
		sampleId?: number;
	};
}

interface Props extends DispatchProps {
	selectedParameters?: IppReportPackageParameter[];
	sample?: IppIndustrySample;
	floatNumber?: any;
	programSettings?: IppProgramSettings;
	massLoadingUnit?: IppMassLoadingUnit;
	parameters: IppReportPackageParameter[];
	history: History;
	match: Match;
	disableForm?: () => boolean;
	validationResults?: ValidationResultError[];
	setValidationResults?: React.Dispatch<React.SetStateAction<ValidationResultError[]>>;
}

export interface SampleResult {
	concentrationSampleResultId?: number;
	parameterName?: string;
	parameterId?: number;
	qualifier?: string;
	enteredValue?: number;
	enteredMethodDetectionLimit?: number;
	analysisMethod?: string;
	analysisDateTimeLocal?: string;
	isCalcMassLoading?: boolean;
	massLoadingValue?: number;
	inEdit?: boolean;
	Discontinued?: boolean;
}

interface State {
	sampleResults: any;
	calculateLoading: boolean;
	sort: SortDescriptor[];
	floatNumbers: any;
	showAddParameterModal: boolean;
	parameters: IppReportPackageParameter[];
	selectedParameter: IppReportPackageParameter;
	initialRender: boolean;
}

const { fieldCharLimit } = IppConstants;

class IppSampleResultsComponent extends Component<Props, State> {
	gridRef: RefObject<Grid>;
	columns: JSX.Element[] = [];
	columnsKeys = [
		'parameterName',
		'qualifier',
		'enteredValue',
		'enteredMethodDetectionLimit',
		'analysisMethod',
		'analysisDateTimeLocal',
		'isCalcMassLoading',
		'massLoadingValue',
		'minus'
	];
	parametersCopy: IppReportPackageParameter[] = [];

	constructor(props: Props) {
		super(props);
		this.state = {
			sampleResults: {},
			calculateLoading: false,
			sort: [{ field: 'parameterName', dir: 'asc' }] as SortDescriptor[],
			floatNumbers: {},
			showAddParameterModal: false,
			parameters: [],
			selectedParameter: {},
			initialRender: false
		};
		this.gridRef = createRef();
	}

	componentDidUpdate(prevProps: any, prevState: any) {
		if (
			this.props.sample &&
			this.props.sample.flowEnteredValue &&
			(prevProps.sample.flowUnitName !== this.props.sample.flowUnitName ||
				prevProps.sample.flowEnteredValue !== this.props.sample.flowEnteredValue)
		) {
			this.state.sampleResults &&
				_.map(this.state.sampleResults, async (resultValue, resultKey) => {
					if (resultValue.isCalcMassLoading && resultValue.enteredValue) {
						let dataToCalculate = this.getMassLoadingData(resultValue);
						if (dataToCalculate) {
							const url = urlService.getAuthorityResourcesApiUrl(Resource.IppSampleFloatNumbers);
							let floatNumbers = await apiService.httpPost(url, dataToCalculate);
							this.setState(
								prevState => ({
									sampleResults: {
										...prevState.sampleResults,
										[resultValue.parameterId]: {
											...prevState.sampleResults[resultValue.parameterId],
											massLoadingValue: floatNumbers.product,
											massLoadingUnitName: this.props.massLoadingUnit
												? this.props.massLoadingUnit.name
												: '',
											massLoadingUnitId: this.props.massLoadingUnit
												? this.props.massLoadingUnit.unitId
												: ''
										}
									}
								}),
								() => {
									this.props.setSampleData({
										...this.props.sample,
										ippSampleResults: Object.values({ ...this.state.sampleResults })
									});
								}
							);
						}
					}
				});
		}
		if (
			(this.props.sample &&
				this.props.match.params.sampleId === this.props.sample.sampleId &&
				!this.state.initialRender) ||
			(this.props.sample &&
				prevProps.sample &&
				this.props.sample.ippSampleResults &&
				prevProps.sample.ippSampleResults === undefined) ||
			(this.props.sample &&
				prevProps.sample &&
				this.props.sample.ippSampleResults &&
				prevProps.sample.ippSampleResults &&
				this.props.sample.ippSampleResults.length !== prevProps.sample.ippSampleResults.length)
		) {
			let sampleResultItems = this.props.sample && this.props.sample.ippSampleResults;
			let activeItems = sampleResultItems && sampleResultItems.filter(item => item.isCalcMassLoading === true);

			let columnsDefinitions = this.getColumnDefinitions();
			this.columns = this.generateColumns(columnsDefinitions);
			let ippSampleResults = { ...this.props.sample.ippSampleResults };
			let groupResultsByParameter = _.keyBy(ippSampleResults, result => (result as any).parameterId);
			this.setState(prevState => ({
				sampleResults: groupResultsByParameter,
				initialRender: true,
				calculateLoading:
					activeItems !== undefined
						? activeItems.length && activeItems.length === (sampleResultItems && sampleResultItems.length)
							? true
							: false
						: prevState.calculateLoading
			}));
			const { monitoringPointId, startDateTimeLocal, endDateTimeLocal } = this.props.sample as any;
			this.props.loadParameters(
				`sort=name asc&monitoringPointId=${monitoringPointId}&startDateTime=${startDateTimeLocal}&endDateTime=${endDateTimeLocal}`
			);
		}
		if (
			this.props.sample &&
			prevProps.sample &&
			this.props.sample.ippSampleResults !== prevProps.sample.ippSampleResults
		) {
			let sampleResultItems = this.props.sample && this.props.sample.ippSampleResults;
			let activeItems = sampleResultItems && sampleResultItems.filter(item => item.isCalcMassLoading === true);
			let ippSampleResults = { ...this.props.sample.ippSampleResults };
			let groupResultsByParameter = _.keyBy(ippSampleResults, result => (result as any).parameterId);
			this.setState(prevState => ({
				sampleResults: groupResultsByParameter,
				initialRender: true,
				calculateLoading:
					activeItems !== undefined
						? activeItems.length && activeItems.length === (sampleResultItems && sampleResultItems.length)
							? true
							: false
						: prevState.calculateLoading
			}));
		}
		if (prevState.parameters.length !== prevProps.parameters.length) {
			this.setState({ parameters: prevProps.parameters }, () => {
				this.parametersCopy = this.state.parameters;
			});
		}
	}

	componentDidMount() {
		const { monitoringPointId, startDateTimeLocal, endDateTimeLocal } = this.props.sample as any;
		this.props.loadSettings();
		this.props.loadMassLoadingUnit();
		this.props.loadParameters(
			`sort=name asc&monitoringPointId=${monitoringPointId}&startDateTime=${startDateTimeLocal}&endDateTime=${endDateTimeLocal}`
		);
		let columnsDefinitions = this.getColumnDefinitions();
		this.columns = this.generateColumns(columnsDefinitions);
	}

	onDelete = (parameterId: number) => {
		this.props.setValidationResults && this.props.setValidationResults([]);
		let sampleResults = { ...this.state.sampleResults };
		delete sampleResults[parameterId];

		let sample: IppIndustrySample = { ...this.props.sample };
		sample.ippSampleResults = Object.values(sampleResults);
		this.props.setSampleData(sample);
	};

	getActionButton = (props: any) => {
		const { parameterId } = props.dataItem;
		return (
			<>
				<td className="align-right pr-0">
					<button
						type="button"
						className="btn ai-delete"
						onClick={() => this.onDelete(parameterId)}
						disabled={this.props.disableForm && this.props.disableForm()}>
						<span className={`k-icon k-i-${props.field}`} />
					</button>
				</td>
			</>
		);
	};

	getDefaultShowingColumnDefinitions = (fields: string[]) => {
		return fields.map((field: string) => {
			return { field, title: field, visible: true } as ColumnDefinition;
		});
	};

	getColumnDefinitions = (): ColumnDefinition[] => {
		let defaultColumnsDefinitions = this.getDefaultShowingColumnDefinitions(this.columnsKeys);

		defaultColumnsDefinitions.forEach(item => {
			item.title = localizationService.getLocalizedString(`ipp.samples.step2.results.${item.field}`);
			if (item.field === 'minus') {
				item.title = localizationService.getLocalizedString(`ipp.availableSelectedGrids.${item.field}`);
			}
		});
		return defaultColumnsDefinitions;
	};

	getQualifierDropdown = (props: any) => {
		let { parameterId } = props.dataItem;
		let settings = this.props.programSettings && this.props.programSettings['settings'];
		let activeQualifiers =
			settings && settings.length
				? settings.filter(settings => {
						return settings.settingType === 'ResultQualifierValidValues';
				  })[0].value
				: '';
		let qualifierItems = [...(activeQualifiers || '').split(',')];
		if (qualifierItems.length && !qualifierItems.includes('')) {
			qualifierItems.unshift('');
		}
		let dropDownOptions = qualifierItems.map(item => ({
			label: item,
			value: item
		}));

		return (
			<td>
				<SingleSelectDropdown
					id={`qualifier-${parameterId}`}
					name={`qualifier-${parameterId}`}
					options={dropDownOptions}
					onChange={e => this.handleChange(e, parameterId)}
					value={this.state.sampleResults[parameterId] && this.state.sampleResults[parameterId]['qualifier']}
					className="d-flex justify-content-center align-items-center mb-0 min-width-65"
					selfOrder={true}
					isDisabled={this.props.disableForm && this.props.disableForm()}
				/>
			</td>
		);
	};

	getResultValue = (props: any) => {
		let { parameterId, unitName, enteredValue, qualifier } = props.dataItem;
		let isDisabled = props.dataItem.qualifier === 'ND' || props.dataItem.qualifier === 'NF' ? true : false;
		let { color, message, type } = this.displayConcentrationCompliance(props.dataItem);
		let icon: any;
		if (type === 'good') {
			icon = faCheckCircle;
		} else if (type === 'bad') {
			icon = faExclamationCircle;
		} else {
			icon = faCircle;
		}
		return (
			<td>
				<div className="d-flex justify-content-left" key={parameterId}>
					<TextInput
						id={`enteredValue-${parameterId}`}
						label=""
						hideLabel={true}
						type="number"
						name={`enteredValue-${parameterId}`}
						onChange={e => this.handleChange(e, parameterId)}
						onBlur={e => this.handleBlur(e, parameterId)}
						value={
							(this.state.sampleResults[parameterId] &&
								this.state.sampleResults[parameterId]['enteredValue']) ||
							''
						}
						className="d-flex justify-content-center align-items-center mb-0 min-width-80"
						isDisabled={isDisabled || (this.props.disableForm && this.props.disableForm())}
					/>
					<div className="ml-2 d-flex align-items-center justify-content-start text-nowrap">
						<label id={`result-unit-${parameterId}`}>{unitName}</label>
						{(enteredValue || qualifier === 'ND' || qualifier === 'NF') && (
							<TooltipHover
								id={`enteredValue-tooltip-${parameterId}`}
								icon={icon}
								className="ml-1"
								iconClassName={`circle-icon ${color}`}
								title={message}
								position={Object.keys(this.state.sampleResults).length === 1 ? 'right' : ''}
							/>
						)}
					</div>
				</div>
			</td>
		);
	};

	getMDLValue = (props: any) => {
		let { parameterId } = props.dataItem;

		return (
			<td>
				<TextInput
					id={`mdl-${parameterId}`}
					label=""
					hideLabel={true}
					type="number"
					name={`enteredMethodDetectionLimit-${parameterId}`}
					onChange={e => this.handleChange(e, parameterId)}
					value={
						this.state.sampleResults[parameterId] &&
						this.state.sampleResults[parameterId]['enteredMethodDetectionLimit']
					}
					className="d-flex justify-content-center align-items-center mb-0 min-width-80"
					isDisabled={this.props.disableForm && this.props.disableForm()}
				/>
			</td>
		);
	};

	getAnalysisMethod = (props: any) => {
		let { parameterId } = props.dataItem;

		return (
			<td>
				<TextInput
					id={`analysisMethod-${parameterId}`}
					label=""
					hideLabel={true}
					name={`analysisMethod-${parameterId}`}
					className="d-flex justify-content-center align-items-center mb-0"
					onChange={e => this.handleChange(e, parameterId)}
					value={
						this.state.sampleResults[parameterId] && this.state.sampleResults[parameterId]['analysisMethod']
					}
					isDisabled={this.props.disableForm && this.props.disableForm()}
					remainingInputProps={{ maxLength: fieldCharLimit.shortElementName }}
				/>
			</td>
		);
	};

	getAnalysisDate = (props: any) => {
		let { parameterId } = props.dataItem;

		return (
			<td>
				<CustomDateTimePicker
					id={`analysisDateTimeLocal-${parameterId}`}
					name={`analysisDateTimeLocal-${parameterId}`}
					className="d-flex justify-content-center align-items-center mb-0 date-width"
					onChange={e => this.handleChange(e, parameterId)}
					value={
						this.state.sampleResults[parameterId] &&
						this.state.sampleResults[parameterId]['analysisDateTimeLocal']
					}
					isDisabled={this.props.disableForm && this.props.disableForm()}
				/>
			</td>
		);
	};

	getCalcMassLoadingCheckbox = (props: any) => {
		let { parameterId } = props.dataItem;

		return (
			<td>
				<SingleCheckbox
					id={`isCalcMassLoading-${parameterId}`}
					name={`isCalcMassLoading-${parameterId}`}
					checked={
						(this.state.sampleResults[parameterId] &&
							this.state.sampleResults[parameterId]['isCalcMassLoading']) ||
						false
					}
					className="d-flex justify-content-center align-items-center"
					onChange={e => this.handleChange(e, parameterId)}
					isDisabled={this.props.disableForm && this.props.disableForm()}
				/>
			</td>
		);
	};

	calculateLoadingAllCheckbox = (props: any) => {
		return (
			<>
				<div>
					<label>{props.title}</label>
					{this.props.disableForm && !this.props.disableForm() && (
						<SingleCheckbox
							id={`isCalcMassLoading`}
							name={`isCalcMassLoading`}
							checked={this.state.calculateLoading}
							className={`d-flex justify-content-center align-items-center ${
								_.isEmpty(this.state.sampleResults) ? 'hide' : ''
							}`}
							onChange={e => this.onCalculateLoading(e)}
						/>
					)}
				</div>
			</>
		);
	};

	getMassResultCompliance = (props: any) => {
		let { parameterId, enteredValue } = props.dataItem;
		let { message, color, type } = this.displayMassLoadingCompliance(props.dataItem);
		let icon: any;
		if (type === 'good') {
			icon = faCheckCircle;
		} else if (type === 'bad') {
			icon = faExclamationCircle;
		} else {
			icon = faCircle;
		}
		let flowEnteredValue = undefined;
		let flowUnitName = undefined;
		if (this.props.sample) {
			flowEnteredValue = this.props.sample.flowEnteredValue;
			flowUnitName = this.props.sample.flowUnitName;
		}

		return (
			<td>
				<div id={`massLoadingCompliance-${parameterId}`}>
					{props.dataItem.isCalcMassLoading && (
						<div className="d-flex justify-content-center">
							{this.getMassLoadingValueForDisplay(props.dataItem) !== null &&
								this.getMassLoadingValueForDisplay(props.dataItem) !== undefined &&
								flowEnteredValue &&
								flowUnitName && (
									<>
										<label>{this.getMassLoadingValueForDisplay(props.dataItem)}</label>
										{enteredValue && (
											<TooltipHover
												id={`massResult-tooltip-${parameterId}`}
												icon={icon}
												className="ml-1"
												iconClassName={`circle-icon ${color}`}
												title={message}
												position={
													Object.keys(this.state.sampleResults).length === 1 ? 'left' : ''
												}
											/>
										)}
									</>
								)}
						</div>
					)}
				</div>
			</td>
		);
	};

	handleBlur = (event: any, parameterId: number) => {
		let sampleResult = { ...this.state.sampleResults[parameterId] };
		let dataToCalculate = sampleResult['enteredValue'] && this.getMassLoadingData(sampleResult);
		if (dataToCalculate) {
			this.props.getFloatNumbers(dataToCalculate, floatNumbers => {
				this.setState(
					prevState => ({
						sampleResults: {
							...prevState.sampleResults,
							[parameterId]: {
								...sampleResult,
								massLoadingValue: floatNumbers.product,
								massLoadingUnitName: this.props.massLoadingUnit ? this.props.massLoadingUnit.name : '',
								massLoadingUnitId: this.props.massLoadingUnit ? this.props.massLoadingUnit.unitId : ''
							}
						}
					}),
					() => {
						this.props.setSampleData({
							...this.props.sample,
							ippSampleResults: Object.values({ ...this.state.sampleResults })
						});
					}
				);
			});
		}
	};

	handleChange = (event: any, parameterId: number) => {
		let { name, value } = event.target;
		let key = name.split('-')[0];
		let sampleResult = { ...this.state.sampleResults[parameterId] };

		sampleResult[key] = event.target.type !== 'checkbox' ? value : event.target.checked;

		if (key === 'qualifier' && (sampleResult[key] === 'ND' || sampleResult[key] === 'NF')) {
			sampleResult['enteredValue'] = '';
			sampleResult['massLoadingValue'] = '';
			sampleResult['isCalcMassLoading'] = false;
			sampleResult['massLoadingSampleResultId'] = 0;
		}

		if (event.target.type === 'checkbox' || this.state.calculateLoading) {
			let sampleResultsCalculation = { ...this.state.sampleResults, [parameterId]: { ...sampleResult } };
			let calculationActiveItems = _.filter(sampleResultsCalculation, value => {
				return value.isCalcMassLoading === true;
			});

			this.setState(
				prevState => ({
					...prevState,
					sampleResults: { ...prevState.sampleResults, [parameterId]: { ...sampleResult } },
					calculateLoading:
						calculationActiveItems.length === Object.keys(sampleResultsCalculation).length ? true : false
				}),
				() => {
					let dataToCalculate = sampleResult['enteredValue'] && this.getMassLoadingData(sampleResult);
					if (dataToCalculate) {
						this.props.getFloatNumbers(dataToCalculate, floatNumbers => {
							this.setState(
								prevState => ({
									sampleResults: {
										...prevState.sampleResults,
										[parameterId]: {
											...prevState.sampleResults[parameterId],
											massLoadingValue: floatNumbers.product,
											massLoadingUnitName: this.props.massLoadingUnit
												? this.props.massLoadingUnit.name
												: '',
											massLoadingUnitId: this.props.massLoadingUnit
												? this.props.massLoadingUnit.unitId
												: ''
										}
									}
								}),
								() => {
									this.props.setSampleData({
										...this.props.sample,
										ippSampleResults: Object.values({ ...this.state.sampleResults })
									});
								}
							);
						});
					}
					this.props.setSampleData({
						...this.props.sample,
						ippSampleResults: Object.values({ ...this.state.sampleResults })
					});
				}
			);
		} else {
			this.setState(
				prevState => ({
					...prevState,
					sampleResults: { ...prevState.sampleResults, [parameterId]: sampleResult }
				}),
				() => {
					this.props.setSampleData({
						...this.props.sample,
						ippSampleResults: Object.values({ ...this.state.sampleResults })
					});
				}
			);
		}
	};

	onCalculateLoading = (e: any) => {
		let sampleResults = { ...this.state.sampleResults };
		sampleResults = _.map(sampleResults, value => {
			value.isCalcMassLoading = e.target.checked;
			return value;
		});

		sampleResults = _.pickBy({ ...this.state.sampleResults }, result => result.parameterId);

		this.setState({ calculateLoading: e.target.checked, sampleResults }, () => {
			this.state.sampleResults &&
				_.map(this.state.sampleResults, resultValue => {
					if (
						resultValue.isCalcMassLoading &&
						resultValue.enteredValue &&
						resultValue.qualifier !== 'ND' &&
						resultValue.qualifier !== 'NF'
					) {
						let dataToCalculate = this.getMassLoadingData(resultValue);
						if (dataToCalculate) {
							this.props.getFloatNumbers(dataToCalculate, floatNumbers => {
								this.setState(
									prevState => ({
										sampleResults: {
											...prevState.sampleResults,
											[resultValue.parameterId]: {
												...prevState.sampleResults[resultValue.parameterId],
												massLoadingValue: floatNumbers.product,
												massLoadingUnitName: this.props.massLoadingUnit
													? this.props.massLoadingUnit.name
													: '',
												massLoadingUnitId: this.props.massLoadingUnit
													? this.props.massLoadingUnit.unitId
													: ''
											}
										}
									}),
									() => {
										this.props.setSampleData({
											...this.props.sample,
											ippSampleResults: Object.values({ ...this.state.sampleResults })
										});
									}
								);
							});
						}
					}
				});
		});
	};

	rowRender = (trElement: any, props: any) => {
		let shouldMarkAsRed = false;
		let parameterId: number = props.dataItem.parameterId;
		let invalidParameterIds: number[] = [];
		const { validationResults } = this.props;
		if (validationResults && validationResults.length) {
			for (let i = 0; i < validationResults.length; i++) {
				invalidParameterIds[i] = validationResults[i]['parameterId'] || 0;
			}
			shouldMarkAsRed = invalidParameterIds.includes(parameterId || 0);
		}
		const trProps = { className: `${trElement.props.className} ${shouldMarkAsRed ? 'mark-red' : ''}` };
		return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
	};

	getParameterNameWithTooltip = (rowData: any) => {
		const { validationResults } = this.props;
		let sampleResult: IppSampleResult = rowData.dataItem;
		let parameterId = sampleResult.parameterId;
		let message: string = '';
		let errorResult: ValidationResultError;
		if (validationResults && validationResults.length) {
			errorResult = validationResults.filter((validationResult: ValidationResultError) => {
				return validationResult.parameterId === parameterId;
			})[0];
			message = errorResult ? (errorResult.errorMessage ? errorResult.errorMessage : '') : '';
		}
		if (message) {
			return (
				<td>
					<TooltipHover
						id={`prevent-readytoreport-${parameterId}`}
						icon={faExclamationCircle}
						className="d-inline-block mr-1"
						iconClassName={`ai-required`}
						iconFontClass="font-size-16px-regular"
						title={message}
						position={'right'}
					/>
					<span>{sampleResult.parameterName}</span>
				</td>
			);
		} else {
			return (
				<td>
					<span>{sampleResult.parameterName}</span>
				</td>
			);
		}
	};

	generateColumns = (columnDefinitions: ColumnDefinition[]) => {
		return columnDefinitions
			.filter((i: ColumnDefinition) => i.visible)
			.map((i: ColumnDefinition, index: number) => {
				if (i.field === 'parameterName') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={this.getParameterNameWithTooltip}
							headerClassName="align-center"
						/>
					);
				}
				if (i.field === 'enteredValue') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getResultValue(props)}
							headerClassName="align-center result-value"
						/>
					);
				}
				if (i.field === 'enteredMethodDetectionLimit') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getMDLValue(props)}
							headerClassName="align-center mdl"
						/>
					);
				}
				if (i.field === 'qualifier') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getQualifierDropdown(props)}
							headerClassName="align-center qualifier"
						/>
					);
				}
				if (i.field === 'analysisMethod') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getAnalysisMethod(props)}
							headerClassName="align-center"
						/>
					);
				}
				if (i.field === 'analysisDateTimeLocal') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getAnalysisDate(props)}
							headerClassName="align-center"
						/>
					);
				}
				if (i.field === 'isCalcMassLoading') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getCalcMassLoadingCheckbox(props)}
							headerClassName="align-center d-flex justify-content-center"
							headerCell={this.calculateLoadingAllCheckbox}
						/>
					);
				}
				if (i.field === 'massLoadingValue') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={props => this.getMassResultCompliance(props)}
							headerClassName="align-center mdl"
						/>
					);
				}
				if (i.field === 'minus') {
					return (
						<GridColumn
							key={`column_key${i.field}`}
							field={i.field}
							title={i.title}
							cell={this.getActionButton}
							headerClassName="align-center plus-minus-header-width"
						/>
					);
				}
				return (
					<GridColumn
						key={`column_key${i.field}`}
						field={i.field}
						title={i.title}
						cell={this.localizeCellValue}
						headerClassName="align-center"
					/>
				);
			});
	};

	localizeCellValue = (props: any) => {
		let fieldValue = _.get(props.dataItem, props.field);
		return <td className="align-center">{localizationService.formatValue(fieldValue)}</td>;
	};

	addParameters = () => {
		const { name, defaultUnitName, defaultUnitId, parameterId } = this.state.selectedParameter;
		this.props.setValidationResults && this.props.setValidationResults([]);
		if (name && defaultUnitName && defaultUnitId && parameterId) {
			let newSampleResult = {
				parameterName: name,
				unitName: defaultUnitName,
				unitId: defaultUnitId,
				parameterId: parameterId
			};

			let sample: any = { ...this.props.sample };
			if (sample) {
				let ippSampleResults = sample.ippSampleResults || [];
				sample.ippSampleResults = ippSampleResults.unshift(newSampleResult);
				this.setState(
					prevState => {
						return {
							sampleResults: { [parameterId]: newSampleResult, ...prevState.sampleResults },
							calculateLoading: false
						};
					},
					() => {
						this.props.setSampleData({
							...this.props.sample,
							ippSampleResults: Object.values({ ...this.state.sampleResults })
						});
					}
				);
			}
		}
		this.onToggleAddParametersModal();
	};

	onToggleAddParametersModal = () => {
		this.setState(prevState => ({
			...prevState,
			showAddParameterModal: !prevState.showAddParameterModal,
			selectedParameter: {}
		}));
	};

	fieldChange = (e: any) => {
		const fieldValue: IppReportPackageParameter = e.target.value;
		this.setState({ selectedParameter: fieldValue });
	};

	deleteEmptyParameters = () => {
		this.props.setValidationResults && this.props.setValidationResults([]);
		let sampleResults = _.pickBy(this.state.sampleResults, value => {
			let empty =
				_.isEmpty(value.qualifier) &&
				_.isEmpty(value.enteredValue) &&
				_.isEmpty(value.enteredMethodDetectionLimit) &&
				_.isEmpty(value.analysisMethod) &&
				_.isEmpty(value.analysisDateTimeLocal);
			return !empty;
		});
		let sample: IppIndustrySample = { ...this.props.sample };
		sample.ippSampleResults = Object.values({ ...sampleResults });
		this.props.setSampleData(sample);
	};

	formatString = (str: string, ...val: string[]) => {
		for (let index = 0; index < val.length; index++) {
			str = str.replace(`{${index}}`, val[index]);
		}
		return str;
	};

	getMassLoadingValueForDisplay = (sampleResult: IppSampleResult) => {
		const { qualifier, parameterId, enteredValue } = sampleResult;
		let massLoadingUnitName = (this.props.massLoadingUnit && this.props.massLoadingUnit.name) || '';
		let settings = this.props.programSettings && this.props.programSettings['settings'];
		let isMassLoadingResultToUseLessThanSign = _.find(settings, setting => {
			return setting.settingType === 'MassLoadingResultToUseLessThanSign';
		});
		let isMassLoadingResultToUseLessThanSignValue =
			isMassLoadingResultToUseLessThanSign && isMassLoadingResultToUseLessThanSign.value
				? isMassLoadingResultToUseLessThanSign.value.toLowerCase() === 'true' ||
				  isMassLoadingResultToUseLessThanSign.value.toLowerCase() === 'yes'
				: false;
		if (parameterId && enteredValue) {
			let massLoadingValue = this.state.sampleResults[parameterId]
				? this.state.sampleResults[parameterId].massLoadingValue
				: 0;
			if (qualifier === 'ND' || qualifier === 'NF') {
				return null;
			} else if (!qualifier || qualifier === '>') {
				return (massLoadingValue || 0) + ' ' + massLoadingUnitName;
			} else {
				if (qualifier === '<') {
					if (!isMassLoadingResultToUseLessThanSignValue) {
						return (massLoadingValue || 0) + ' ' + massLoadingUnitName;
					}
					return qualifier + ' ' + (massLoadingValue || 0) + ' ' + massLoadingUnitName;
				}
			}
		}
	};

	displayMassLoadingCompliance = (sampleResult: IppSampleResult) => {
		const { qualifier, parameterName } = sampleResult;
		if (parameterName) {
			let settings = this.props.programSettings && this.props.programSettings['settings'];
			let isMassLoadingResultToUseLessThanSign = _.find(settings, setting => {
				return setting.settingType === 'IsMassLoadingResultToUseLessThanSign';
			});
			let isMassLoadingResultToUseLessThanSignValue =
				isMassLoadingResultToUseLessThanSign && isMassLoadingResultToUseLessThanSign.value
					? isMassLoadingResultToUseLessThanSign.value.toLowerCase() === 'true' ||
					  isMassLoadingResultToUseLessThanSign.value.toLowerCase() === 'yes'
					: false;
			let parameter = _.find(
				this.props.selectedParameters && this.props.selectedParameters.length
					? this.props.selectedParameters
					: this.props.parameters,
				parameter => parameter.name === parameterName
			);
			let massLoadingMaxValue = null;
			let massLoadingMinValue = null;
			if (parameter) {
				massLoadingMaxValue = parameter.massLoadingMaxValue;
				massLoadingMinValue = parameter.massLoadingMinValue;
			}
			let massLoadingValue = Number(sampleResult.massLoadingValue);
			if (massLoadingMaxValue !== null && massLoadingMaxValue !== undefined) {
				if (massLoadingMinValue !== null && massLoadingMinValue !== undefined) {
					//range limit
					if (
						qualifier !== 'ND' &&
						qualifier !== 'NF' &&
						((isMassLoadingResultToUseLessThanSignValue &&
							qualifier === '<' &&
							massLoadingValue <= massLoadingMinValue) ||
							massLoadingValue > massLoadingMaxValue ||
							massLoadingValue < massLoadingMinValue)
					) {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.badOutsideRangeComplianceIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMinValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-danger',
							type: 'bad'
						};
					} else {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceRangeIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMinValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-success',
							type: 'good'
						};
					}
				} else {
					//max limit only
					if (
						qualifier !== 'ND' &&
						qualifier !== 'NF' &&
						!massLoadingMinValue &&
						massLoadingValue > massLoadingMaxValue
					) {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.badAboveMaxComplianceIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-danger',
							type: 'bad'
						};
					} else {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceMaxIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-success',
							type: 'good'
						};
					}
				}
			} else {
				//no limits
				return {
					message: this.formatString(
						localizationService.getLocalizedString(
							'ipp.samples.step2.results.unknownComplianceIconAndMessage'
						),
						parameterName
					),
					color: 'text-success',
					type: 'none'
				};
			}
		}
		return {
			message: '',
			color: 'text-success',
			type: 'none'
		};
	};

	displayConcentrationCompliance = (sampleResult: IppSampleResult) => {
		const { qualifier, enteredValue, parameterName } = sampleResult;
		let parameter = _.find(
			this.props.selectedParameters && this.props.selectedParameters.length
				? this.props.selectedParameters
				: this.props.parameters,
			parameter => parameter.name === parameterName
		);
		let concentrationMaxValue = null;
		let concentrationMinValue = null;

		if (parameter) {
			concentrationMinValue = parameter.concentrationMinValue;
			concentrationMaxValue = parameter.concentrationMaxValue;
		}

		//If a limit exists at all, there will always be a max value. Optionally a min value could exist as well.
		if (
			concentrationMaxValue !== null &&
			concentrationMaxValue !== undefined &&
			enteredValue !== undefined &&
			parameterName !== undefined
		) {
			if (concentrationMinValue != null) {
				//range limit
				if (
					qualifier !== 'ND' &&
					qualifier !== 'NF' &&
					enteredValue !== undefined &&
					((qualifier === '<' &&
						(enteredValue > concentrationMaxValue || enteredValue <= concentrationMinValue)) ||
						(qualifier === '>' &&
							(enteredValue >= concentrationMaxValue || enteredValue < concentrationMinValue)) ||
						enteredValue > concentrationMaxValue ||
						enteredValue < concentrationMinValue)
				) {
					//blank qualifier/exact value
					return {
						message: this.formatString(
							localizationService.getLocalizedString(
								'ipp.samples.step2.results.badOutsideRangeComplianceIconAndMessage'
							),
							parameterName,
							enteredValue.toString(),
							concentrationMinValue.toString(),
							concentrationMaxValue.toString()
						),
						color: 'text-danger',
						type: 'bad'
					};
				} else {
					//If there's no result don't show compliance badge at all
					if (enteredValue !== null || qualifier === 'ND' || qualifier === 'NF') {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceRangeIconAndMessage'
								),
								parameterName,
								enteredValue.toString(),
								concentrationMinValue.toString(),
								concentrationMaxValue.toString()
							),
							color: 'text-success',
							type: 'good'
						};
					} else {
						return {
							message: '',
							color: 'text-success',
							type: 'none'
						};
					}
				}
			} else {
				//max limit only
				if (
					qualifier !== 'ND' &&
					qualifier !== 'NF' &&
					!concentrationMinValue &&
					concentrationMaxValue &&
					enteredValue !== null &&
					((qualifier === '>' && enteredValue >= concentrationMaxValue) ||
						enteredValue > concentrationMaxValue)
				) {
					return {
						message: this.formatString(
							localizationService.getLocalizedString(
								'ipp.samples.step2.results.badAboveMaxComplianceIconAndMessage'
							),
							parameterName,
							'' + enteredValue,
							'' + concentrationMaxValue
						),
						color: 'text-danger',
						type: 'bad'
					};
				} else {
					//If there's no result don't show compliance badge at all
					if (enteredValue !== null || qualifier === 'ND' || qualifier === 'NF') {
						return {
							message: this.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceMaxIconAndMessage'
								),
								parameterName,
								'' + enteredValue,
								'' + concentrationMaxValue
							),
							color: 'text-success',
							type: 'good'
						};
					} else {
						return {
							message: '',
							color: 'text-success',
							type: 'none'
						};
					}
				}
			}
		} else {
			//no limits
			//Don't show compliance icon at all if there isn't a valid result
			if (qualifier !== 'ND' && qualifier !== 'NF' && enteredValue === null) {
				return {
					message: '',
					color: 'text-success',
					type: 'none'
				};
			} else {
				if (parameterName !== undefined) {
					return {
						message: this.formatString(
							localizationService.getLocalizedString(
								'ipp.samples.step2.results.unknownComplianceIconAndMessage'
							),
							parameterName
						),
						color: 'text-success',
						type: 'none'
					};
				}
			}
		}

		return {
			message: '',
			color: 'text-success',
			type: 'none'
		};
	};

	getMassLoadingData = (sampleResult: IppSampleResult) => {
		let settings = this.props.programSettings && this.props.programSettings['settings'];
		let massLoadingCalculationDecimalPlaces = _.find(settings, setting => {
			return setting.settingType === 'MassLoadingCalculationDecimalPlaces';
		});
		let massLoadingConversionFactorPounds = _.find(settings, setting => {
			return setting.settingType === 'MassLoadingConversionFactorPounds';
		});

		if (
			this.props.sample &&
			this.props.sample.flowEnteredValue &&
			this.props.sample.flowUnitName &&
			sampleResult.unitName &&
			sampleResult.isCalcMassLoading &&
			massLoadingCalculationDecimalPlaces &&
			massLoadingConversionFactorPounds
		) {
			const { enteredValue } = sampleResult;
			const { flowEnteredValue, flowUnitName } = this.props.sample;
			const flowUnitConversionFactor = flowUnitName.toLowerCase() === 'mgd' ? 1 : 0.000001;
			const resultUnitName = sampleResult.unitName.toLowerCase();
			const resultUnitConversionFactor = resultUnitName === 'mg/l' ? 1 : 0.001;

			var dataToCalculate = {
				numbers: [
					Number(enteredValue),
					Number(flowEnteredValue),
					Number(massLoadingConversionFactorPounds.value),
					Number(flowUnitConversionFactor),
					Number(resultUnitConversionFactor)
				],
				decimals: Number(massLoadingCalculationDecimalPlaces.value)
			};

			return dataToCalculate;
		}
	};

	render() {
		let addParameterModalProps: IppSampleParameterAddModalProps = {
			showModal: this.state.showAddParameterModal,
			modalTitle: localizationService.getLocalizedString('ipp.samples.step2.addParameters'),
			parameters: this.state.parameters,
			selectedParameter: this.state.selectedParameter,
			onSave: () => this.addParameters(),
			onCancel: () => this.onToggleAddParametersModal(),
			onFieldChange: (e: any) => this.fieldChange(e)
		};
		return (
			<>
				<CollapsibleCard
					className="mt-3"
					accordionType="results"
					accordionHeading={localizationService.getLocalizedString(`ipp.samples.step2.resultsTitle`)}
					accordionHeaderElements={
						this.props.disableForm && !this.props.disableForm() ? (
							<>
								<button
									type="button"
									className="btn ai-action button mr-3"
									onClick={() => this.onToggleAddParametersModal()}>
									{localizationService.getLocalizedString(`ipp.samples.step2.addParameters`)}
								</button>
								<button
									type="button"
									className="btn ai-delete button mr-3"
									onClick={() => this.deleteEmptyParameters()}>
									{localizationService.getLocalizedString(`ipp.samples.step2.deleteEmptyParameters`)}
								</button>
							</>
						) : (
							<></>
						)
					}>
					<div className="table-responsive">
						<Grid
							data={orderBy(Object.values({ ...this.state.sampleResults }), this.state.sort)}
							className="table result-table"
							ref={this.gridRef}
							scrollable="none"
							rowRender={this.rowRender}
							sortable
							sort={this.state.sort}
							onSortChange={e => {
								this.setState({ sort: e.sort });
							}}>
							{this.columns}
						</Grid>
					</div>
				</CollapsibleCard>
				<IppSampleParameterAddModal {...addParameterModalProps} />
			</>
		);
	}
}

const mapStateToProps = (state: ApplicationState): SampleState => {
	return { ...state.ippIndustrySample };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		setSampleData: (sample: IppIndustrySample) => dispatch(setSampleData(sample)),
		getFloatNumbers: (floatData: any, cb: any) => dispatch(loadFloatNumbers(floatData, cb)),
		loadSettings: () => dispatch(loadSettings()),
		loadMassLoadingUnit: () => dispatch(loadMassLoadingUnit()),
		loadParameters: (queryString?: string) => dispatch(loadParameters(queryString))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(IppSampleResultsComponent);
