import {
	InspectionFormTemplate,
	InspectionForm,
	CompleteInspectionEvent,
	ReturnedInspectionsAfterInspectionCompletion,
	MaxInteger
} from '@rcp/types';
import { apiService, Resource, urlService, localizationService, DateUtilService, QueryParameters } from 'src/services';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { alertService } from '../..';
import { PaginatedResult, InspectionEvent, InspectionView, Event } from '@rcp/types';
import _ from 'lodash';
import { loadFogFacility } from '../facility-action-creators';
export interface InspectionsState {
	inspectionView: InspectionView;
	currentInspection: InspectionEvent;
	currentInspectionForms: InspectionForm[];
	facilityInspections: InspectionEvent[];
	authorityInspections: PaginatedResult<InspectionEvent>;
	inspectionFormTemplates: InspectionFormTemplate[];
}

export const initialInspectionsState: InspectionsState = {
	inspectionView: {
		inspectionForms: []
	},
	currentInspection: {},
	currentInspectionForms: [],
	facilityInspections: [],
	authorityInspections: {
		page: 1,
		size: 10,
		total: 0,
		result: []
	},
	inspectionFormTemplates: []
};

enum InspectionActionType {
	LoadInspectionView = 'LoadInspectionView',
	LoadCurrentInspectionForms = 'LoadCurrentInspectionForms',
	LoadCurrentInspection = 'LoadCurrentInspection',
	LoadInspectionFormTemplates = 'LoadInspectionFormTemplates',
	LoadAuthorityInspections = 'LoadAuthorityInspections',
	LoadFacilityInspections = 'LoadFacilityInspections',
	LoadFacilityInspection = 'LoadFacilityInspection',
	SaveFacilityInspection = 'SaveFacilityInspection',
	SaveFacilityInspectionForm = 'SaveFacilityInspectionForm',
	ResetInspectionData = 'ResetInspectionData'
}

export type InspectionsThunkAction = ThunkAction<any, InspectionsState, any, Action>;

export const authoritySaveFacilityInspection = (
	inspection: InspectionEvent,
	queryString: string
): InspectionsThunkAction => async (dispatch, getState) => {
	let facilityId = inspection.organizationId;

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);
	if (inspection.inspectionEventId && inspection.inspectionEventId > 0) {
		await apiService.patchResource(inspectionsUrl + '/' + inspection.inspectionEventId, inspection);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'inspectionForm.inspection')
		);
	} else {
		await apiService.postResource<InspectionEvent>(inspectionsUrl, inspection);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.addSuccess', 'inspectionForm.inspection')
		);
	}

	dispatch(loadAuthorityInspections(queryString));
};

export const saveFacilityInspection = (
	inspection: InspectionEvent,
	fogFacilityId?: number,
	callbackOnSuccess?: () => void
): InspectionsThunkAction => async (dispatch, getState) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError(fogFacilityId);
	let returnedInspection: InspectionEvent;
	const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);
	if (inspection.inspectionEventId && inspection.inspectionEventId > 0) {
		returnedInspection = await apiService.patchResource(
			inspectionsUrl + '/' + inspection.inspectionEventId,
			inspection
		);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'inspectionForm.inspection')
		);
	} else {
		returnedInspection = await apiService.postResource<InspectionEvent>(inspectionsUrl, inspection);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.addSuccess', 'inspectionForm.inspection')
		);
	}

	if (callbackOnSuccess) {
		callbackOnSuccess();
	} else if (facilityId > 0) {
		dispatch(loadFogFacility(facilityId));
		dispatch(loadFacilityInspections(undefined, undefined, facilityId));
	}
	return returnedInspection;
};

export const completeFacilityInspection = (
	inspection: CompleteInspectionEvent,
	callbackOnSuccess?: (nextInspection?: InspectionEvent) => void
): InspectionsThunkAction => async (dispatch, getState) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);

	let returnedInspections: ReturnedInspectionsAfterInspectionCompletion = await apiService.httpPost(
		inspectionsUrl + '/Complete',
		inspection
	);
	let nextInspection = returnedInspections.nextInspection;

	if (callbackOnSuccess) {
		nextInspection ? callbackOnSuccess(nextInspection) : callbackOnSuccess();
	} else {
		if (nextInspection) {
			alertService.addInfo(
				localizationService.getLocalizedString(
					'inspection.scheduledNextInspectionConfirmation',
					nextInspection.inspectionType as string,
					DateUtilService.toDisplayDate(nextInspection.dueDate)
				)
			);
		}
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'inspectionForm.inspection')
		);
	}

	dispatch(loadCurrentInspection(inspection.inspectionEventId));
	return true;
};

export const saveDraftFacilityInspectionWithForms = (
	inspection: CompleteInspectionEvent,
	callbackOnSuccess?: () => void
): InspectionsThunkAction => async (dispatch, getState) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();

	const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);

	await apiService.httpPost(inspectionsUrl + '/SaveDraft', inspection);

	if (callbackOnSuccess) {
		callbackOnSuccess();
	} else {
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'inspectionForm.inspection')
		);
	}

	dispatch(loadCurrentInspection(inspection.inspectionEventId));
	return true;
};

export const saveFacilityInspectionForm = (inspectionForm: InspectionForm): InspectionsThunkAction => async (
	dispatch,
	getState
) => {
	const url = urlService.getAuthorityResourcesApiUrl(Resource.InspectionForms);

	if (inspectionForm.inspectionFormId && inspectionForm.inspectionFormId > 0) {
		await apiService.patchResource(url + '/' + inspectionForm.inspectionFormId, inspectionForm);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.updateSuccess', 'inspectionForm.inspection')
		);
	} else {
		await apiService.postResource<InspectionEvent>(url, inspectionForm);
		alertService.addSuccess(
			localizationService.getLocalizedString('alertMessages.addSuccess', 'inspectionForm.inspection')
		);
	}

	return true;
};
export const deleteInspectionFormAction = async (inspectionFormId: number): Promise<void> => {
	const url = urlService.getAuthorityResourceApiUrl(Resource.InspectionForms, inspectionFormId);

	await apiService.httpDelete(url);
};

export const loadInspectionView = (inspectionPublicId: string): InspectionsThunkAction => async (dispatch: any) => {
	const inspectionViewUrl = urlService.getInspectionPublicUrl(inspectionPublicId);
	const inspectionView = await apiService.httpGet(inspectionViewUrl);
	dispatch({ type: InspectionActionType.LoadInspectionView, inspectionView: inspectionView });
};

export const loadCurrentInspection = (inspectionId: number): InspectionsThunkAction => async (dispatch: any) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	return dispatch(loadFacilityInspection(facilityId, inspectionId));
};

export const loadFacilityInspection = (facilityId: number, inspectionId: number): InspectionsThunkAction => async (
	dispatch: any
) => {
	const facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(
		facilityId,
		Resource.InspectionEvents,
		inspectionId
	);
	let url = facilityInspectionsUrl;
	const inspection = await apiService.httpGet(url);
	dispatch({ type: InspectionActionType.LoadCurrentInspection, currentInspection: inspection });
};

export const loadCompleteRecentInspections = async (
	count: number,
	currentInspectionId: number
): Promise<InspectionEvent[]> => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	let facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);
	facilityInspectionsUrl = `${facilityInspectionsUrl}?inspectionEventId=neq:${currentInspectionId}&completeDate=neq:NULL&sort=completeDate desc&size=${count}`;

	let url = facilityInspectionsUrl;
	let inspections = await apiService.getPaginatedResources<InspectionEvent>(url);
	return inspections.result;
};

export const upComingInspections = async (count: number, currentInspectionId: number): Promise<InspectionEvent[]> => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	let facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);
	facilityInspectionsUrl = `${facilityInspectionsUrl}?inspectionEventId=neq:${currentInspectionId}&completeDate=NULL&sort=dueDate asc&size=${count}`;

	let url = facilityInspectionsUrl;
	let inspections = await apiService.getPaginatedResources<InspectionEvent>(url);
	return inspections.result;
};

export const loadRecentFacilityEvents = async (count: number): Promise<Event[]> => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	let facilityEventsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Events);
	facilityEventsUrl = `${facilityEventsUrl}?completeDate=neq:NULL&sort=completeDate desc&size=${count}`;

	let url = facilityEventsUrl;
	let events = await apiService.getPaginatedResources<Event>(url);
	return events.result;
};

export const loadUpComingFacilityEvents = async (): Promise<Event[]> => {
	let facilityId = urlService.getFogFacilityIdOrThrowError();
	let facilityEventsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Events);
	facilityEventsUrl = `${facilityEventsUrl}?completeDate=NULL&sort=dueDate asc&size=100`;

	let url = facilityEventsUrl;
	let events = await apiService.getPaginatedResources<Event>(url);
	return events.result;
};

export const loadCurrentInspectionForms = (inspectionId: number): InspectionsThunkAction => async (dispatch: any) => {
	let url = `${urlService.getAuthorityResourceApiUrl(Resource.InspectionEvents, inspectionId)}/${
		Resource.InspectionForms
	}`;
	const apiResult = await apiService.httpGet(url);
	dispatch({ type: InspectionActionType.LoadCurrentInspectionForms, currentInspectionForms: apiResult });
};

export const loadAuthorityInspections = (queryString?: string): InspectionsThunkAction => async (dispatch: any) => {
	dispatch({
		type: InspectionActionType.LoadAuthorityInspections,
		authorityInspections: initialInspectionsState.authorityInspections
	});

	const authorityInspectionsUrl = urlService.getAuthorityResourcesApiUrl(Resource.InspectionEvents);
	let url = authorityInspectionsUrl;
	if (queryString) {
		url = `${url}?${queryString}`;
	}
	const inspections = await apiService.httpGet(url);
	dispatch({ type: InspectionActionType.LoadAuthorityInspections, authorityInspections: inspections });
};

export const loadInspectionFormTemplates = (queryString?: string): InspectionsThunkAction => async (dispatch: any) => {
	let url = urlService.getAuthoritySettingResourceApiUrl(Resource.InspectionFormTemplates, queryString);
	const InspectionFormTemplates = await apiService.httpGet(url);
	dispatch({
		type: InspectionActionType.LoadInspectionFormTemplates,
		inspectionFormTemplates: InspectionFormTemplates
	});
};

export const saveInspectionFormTemplates = (
	inspectionFormTemplate: InspectionFormTemplate,
	queryString?: string,
	callbackOnSuccess?: () => void
): InspectionsThunkAction => async (dispatch, getState) => {
	const url = urlService.getAuthoritySettingResourceApiUrl(Resource.InspectionFormTemplates);
	if (inspectionFormTemplate.inspectionFormTemplateId && inspectionFormTemplate.inspectionFormTemplateId > 0) {
		await apiService.patchResource(
			url + '/' + inspectionFormTemplate.inspectionFormTemplateId,
			inspectionFormTemplate
		);
		if (callbackOnSuccess) {
			callbackOnSuccess();
		} else {
			alertService.addSuccess(
				localizationService.getLocalizedString(
					'alertMessages.updateSuccess',
					'screen.labels.inspectionTemplate'
				)
			);
		}
	} else {
		await apiService.postResource<InspectionFormTemplate>(url, inspectionFormTemplate);
		if (callbackOnSuccess) {
			callbackOnSuccess();
		} else {
			alertService.addSuccess(
				localizationService.getLocalizedString('alertMessages.addSuccess', 'screen.labels.inspectionTemplate')
			);
		}
	}

	dispatch(loadInspectionFormTemplates(queryString));
	return true;
};

export const exportAuthorityInspections = (querystring: string): InspectionsThunkAction => async dispatch => {
	const authorityInspectionsUrl = urlService.getAuthorityResourcesApiUrl(Resource.InspectionEvents);
	await apiService.httpGet(`${authorityInspectionsUrl}?${querystring}`);
	alertService.addSuccess(localizationService.getLocalizedString('exportFile.downloadSuccess'));
};

export const loadFacilityInspections = (startDate?: string, endDate?: string, fogFacilityId?: number) => async (
	dispatch: any
) => {
	let facilityId = urlService.getFogFacilityIdOrThrowError(fogFacilityId);
	let incompleteInspectionQueryParams = new QueryParameters().put('size', MaxInteger);
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		incompleteInspectionQueryParams
			.put('dueDate', `gte:${startDate}`)
			.put('dueDate', `lte:${endDate}`)
			.put('completeDate', null);
	}

	const facilityInspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.InspectionEvents);
	const inspectionsResultByDueDate = await apiService.httpGet(
		`${facilityInspectionsUrl}?${incompleteInspectionQueryParams.toQueryString()}`
	);
	const facilityInspectionsByDueDate = inspectionsResultByDueDate.result;

	let facilityInspectionsByCompleteDate: any = [];
	if (!_.isEmpty(startDate) && !_.isEmpty(endDate)) {
		let completedInspectionQueryParams = new QueryParameters()
			.put('size', MaxInteger)
			.put('completeDate', `gte:${startDate}`)
			.put('completeDate', `lte:${endDate}`);
		const inspectionsResultByCompleteDate = await apiService.httpGet(
			`${facilityInspectionsUrl}?${completedInspectionQueryParams.toQueryString()}`
		);
		facilityInspectionsByCompleteDate = inspectionsResultByCompleteDate.result;
	}
	const facilityInspections = _.concat(facilityInspectionsByDueDate, facilityInspectionsByCompleteDate);

	dispatch({ type: InspectionActionType.LoadFacilityInspections, facilityInspections: facilityInspections });
};

export const batchPatchFacilityInspections = (
	querystring: string,
	patchInspectionRequest: InspectionEvent,
	callbackLoading: (status: boolean) => void,
	onSuccess: () => void
) => async (dispatch: any) => {
	let url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.InspectionEvents
	}/BatchPatchInspections?${querystring}`;
	callbackLoading(true);
	apiService
		.httpPatch(url, patchInspectionRequest)
		.then((inspectionCounts: number[]) => {
			callbackLoading(false);

			let incompleteInspectionCount = inspectionCounts[0];
			let completeInspectionCount = inspectionCounts[1];
			if (completeInspectionCount > 0) {
				alertService.addSuccess(
					localizationService.getLocalizedString(
						'alertMessages.xInspectionsRescheduledWithCompleteInspectionCount',
						`${incompleteInspectionCount}`,
						incompleteInspectionCount > 1 ? 's' : '',
						`${completeInspectionCount}`,
						completeInspectionCount > 1 ? 's' : ''
					)
				);
			} else {
				alertService.addSuccess(
					localizationService.getLocalizedString(
						'alertMessages.xInspectionsRescheduledWithoutCompleteInspectionCount',
						`${incompleteInspectionCount}`,
						incompleteInspectionCount > 1 ? 's' : ''
					)
				);
			}
			onSuccess();

			dispatch(loadAuthorityInspections(querystring));
		})
		.catch((e: any) => {
			callbackLoading(false);
			alertService.addError(e.message);
		});
};

export const resetInspectionData = () => async (dispatch: any) => {
	dispatch({ type: InspectionActionType.ResetInspectionData });
};

export const inspectionsReducer = (state = initialInspectionsState, action: any): InspectionsState => {
	switch (action.type) {
		case InspectionActionType.LoadInspectionView:
			return { ...state, inspectionView: action.inspectionView };
		case InspectionActionType.LoadFacilityInspections:
			return { ...state, facilityInspections: action.facilityInspections };
		case InspectionActionType.LoadCurrentInspectionForms:
			return { ...state, currentInspectionForms: action.currentInspectionForms };
		case InspectionActionType.LoadCurrentInspection:
			return { ...state, currentInspection: action.currentInspection };
		case InspectionActionType.LoadInspectionFormTemplates:
			return { ...state, inspectionFormTemplates: action.inspectionFormTemplates };
		case InspectionActionType.LoadAuthorityInspections:
			return { ...state, authorityInspections: action.authorityInspections };
		case InspectionActionType.ResetInspectionData:
			return { ...state, currentInspection: {} };
		default:
			return { ...state };
	}
};
