import React from 'react';
import Header from './header/header';
import { useLocation } from 'react-router-dom';
import { SideNav } from './side-nav';
import { localStorageService, tokenService, urlService } from 'src/services';
import { AlertContainer } from './alert-container';
import { SignatureModal } from './signature-modal';
import { useReduxSelector } from 'src/redux';
import _ from 'lodash';
import './layout.scss';
import './print-layout.scss';
import { PageLoadingContainer } from './page-loading-container';
import { FaRegArrowAltCircleUp } from 'react-icons/fa';

export const Layout = (props: any) => {
	const [program, setProgram] = React.useState('fog');
	const [isScrollable, setIsScrollable] = React.useState(false);
	const location = useLocation();
	urlService.clearTokenOnSignOut();
	let hasToken = tokenService.isTokenValid();
	let isPublicScreen = urlService.isPublicScreen();
	let isAccountScreen = urlService.isAccountScreen();
	let isInviteScreen = urlService.isHaulerInviteScreen();
	let { isAccessDenied } = urlService.getPortalStatus();
	let isHideSideNav = urlService.isHideSideNav();
	let isHideMain = urlService.isHideMain();
	let isMapView = urlService.isMapView();
	const userProfile = useReduxSelector(state => state.userProfile.userProfile);
	const isFeatureFlagLoaded = useReduxSelector(state => state.featureSettings.isLoaded);
	let isIppProgram = () =>
		String.equalCaseInsensitive(program, 'ipp') && _.includes(userProfile.issuedPrograms, 'ipp');
	let dynamicClassName =
		!isAccountScreen &&
		hasToken &&
		!isHideSideNav &&
		!isPublicScreen &&
		!isAccessDenied &&
		!isInviteScreen &&
		!urlService.isCustomFormBuilderPage()
			? 'layoutWithSideNav'
			: 'layoutWithOutSideNav';

	React.useEffect(() => {
		let currentProgram = urlService.getCurrentProgramOrDefault(location.pathname);
		setProgram(currentProgram);
		localStorageService.setLastProgram(currentProgram);
	}, [location.pathname]);

	let setScrollable = (evt: any) => {
		let isScrollable = document.body.clientHeight > window.innerHeight;
		setIsScrollable(isScrollable);
	};

	React.useEffect(() => {
		const top = document.querySelector('#root');
		if (top) {
			top.addEventListener('scroll', setScrollable);
			return () => {
				window.removeEventListener('scroll', () => {});
			};
		}
	}, []);

	return (
		<div className={dynamicClassName + `${isIppProgram() ? ' gridColumnWidthIpp' : ''}`}>
			<AlertContainer />
			<Header program={program} />
			<PageLoadingContainer />
			{isFeatureFlagLoaded && (
				<>
					{hasToken &&
						!isHideSideNav &&
						!isPublicScreen &&
						!isAccessDenied &&
						!urlService.isCustomFormBuilderPage() && <SideNav program={program} />}
					{isHideMain ? (
						<div className="menu-toggle height-100">{props.children}</div>
					) : (
						<main
							className={`menu-toggle ${
								urlService.isCustomFormBuilderPage() || isMapView ? 'page-no-padding' : ''
							} ${isMapView && 'main-map-container'}`}>
							{props.children}
						</main>
					)}
					<SignatureModal />
				</>
			)}
			<a
				href="#root"
				id="back-to-top"
				className="page-scroll"
				title="Back to top"
				style={{ display: isScrollable ? 'block' : 'none' }}>
				<FaRegArrowAltCircleUp />
			</a>
		</div>
	);
};
