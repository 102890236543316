import { ApiError, DataImport, Dictionary, DropDownOption, RegulatoryProgramName } from '@rcp/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { RadioOption } from 'src/components';
import { StepperController } from 'src/components/widgets/stepper/context';
import { alertService } from 'src/redux';
import { apiService, localizationService, Logger, QueryParameters, Resource, urlService } from 'src/services';
import { SelfImportStep1Data } from './step1-data-type';

export const SelfImportSteps = DataImport.SelfImportSteps;

export const StandardImportConfigurationName = 'Standard';

export interface SelfImportState {
	showSidePanel: boolean;
	isSaving: boolean;
	importConfigurationId: number;
	importConfigurationName: string;
	importDataType?: string;
	importEntityPolicy: string;
	importFileId: string;
	fileName?: string;
	isSystem: boolean;
	isDraft: boolean;
	fieldOptions: DropDownOption[];
	fieldMappings: DataImport.ImportFieldMapping[];
	targetFields?: DataImport.ImportField[];
	uploadDataColumns?: string[];
	uploadDataRows?: string[][];
	data: any[];
	error?: string;
	processing: boolean;

	fieldMapping?: DataImport.ImportFieldMapping;
	selectedColumn: string;
	previousColumn: string;
	columnId: string;
}

export const initialSelfImportState: SelfImportState = {
	showSidePanel: false,
	isSaving: false,
	importConfigurationId: -1,
	importConfigurationName: StandardImportConfigurationName,
	importFileId: '',
	importEntityPolicy: 'AddAndUpdate',
	isSystem: true,
	isDraft: false,
	fieldOptions: [],
	fieldMappings: [],
	selectedColumn: '',
	previousColumn: '',
	columnId: '',
	processing: false,
	data: []
};

const selfImportSlice = createSlice({
	name: 'selfImport',
	initialState: {
		current: initialSelfImportState
	},
	reducers: {
		updateSelfImportFieldMapping: (state, { payload }: PayloadAction<DataImport.ImportFieldMapping>) => {
			state.current.fieldMapping = payload;
		},
		updateSelfImportState: (state, { payload }: PayloadAction<any>) => {
			state.current = { ...state.current, ...payload };
		}
	}
});

export const { updateSelfImportState, updateSelfImportFieldMapping } = selfImportSlice.actions;
export const selfImportReducer = selfImportSlice.reducer;

export const resetAllSelfImportStepStates = (stepperContext: StepperController) => {
	let step1State = { ...stepperContext.getStep(SelfImportSteps.STEP1), completed: false, data: null };
	stepperContext.updateStep(SelfImportSteps.STEP1, step1State);
	let step2State = { ...stepperContext.getStep(SelfImportSteps.STEP2), completed: false, data: null };
	stepperContext.updateStep(SelfImportSteps.STEP2, step2State);
	let step3State = { ...stepperContext.getStep(SelfImportSteps.STEP3), completed: false, data: null };
	stepperContext.updateStep(SelfImportSteps.STEP3, step3State);
	let step4State = { ...stepperContext.getStep(SelfImportSteps.STEP4), completed: false, data: null };
	stepperContext.updateStep(SelfImportSteps.STEP4, step4State);
	let step5State = { ...stepperContext.getStep(SelfImportSteps.STEP5), completed: false, data: null };
	stepperContext.updateStep(SelfImportSteps.STEP5, step5State);
};

const resetSelfImportStepsState = (
	stepperContext: StepperController,
	clearData: boolean,
	...steps: DataImport.SelfImportSteps[]
) => {
	for (let step of steps) {
		if (step === SelfImportSteps.STEP1) {
			let step1State = { ...stepperContext.getStep(SelfImportSteps.STEP1), completed: false };
			if (clearData) {
				step1State.data = null;
			}
			stepperContext.updateStep(SelfImportSteps.STEP1, step1State);
		}
		if (step === SelfImportSteps.STEP2) {
			let step2State = { ...stepperContext.getStep(SelfImportSteps.STEP2), completed: false };
			if (clearData) {
				step2State.data = null;
			}
			stepperContext.updateStep(SelfImportSteps.STEP2, step2State);
		}
		if (step === SelfImportSteps.STEP3) {
			let step3State = { ...stepperContext.getStep(SelfImportSteps.STEP3), completed: false };
			if (clearData) {
				step3State.data = null;
			}
			stepperContext.updateStep(SelfImportSteps.STEP3, step3State);
		}
		if (step === SelfImportSteps.STEP4) {
			let step4State = { ...stepperContext.getStep(SelfImportSteps.STEP4), completed: false };
			if (clearData) {
				step4State.data = null;
			}
			stepperContext.updateStep(SelfImportSteps.STEP4, step4State);
		}
		if (step === SelfImportSteps.STEP5) {
			let step5State = { ...stepperContext.getStep(SelfImportSteps.STEP5), completed: false };
			if (clearData) {
				step5State.data = null;
			}
			stepperContext.updateStep(SelfImportSteps.STEP5, step5State);
		}
	}
};

export const clearSelfImportStepsState = (
	stepperContext: StepperController,
	...steps: DataImport.SelfImportSteps[]
) => {
	resetSelfImportStepsState(stepperContext, true, ...steps);
};

export const disableSelfImportStepsState = (
	stepperContext: StepperController,
	...steps: DataImport.SelfImportSteps[]
) => {
	resetSelfImportStepsState(stepperContext, false, ...steps);
};

export const getSelfImportDataType = (stepperContext: StepperController): string | undefined => {
	const step1Data: SelfImportStep1Data = stepperContext.getData(SelfImportSteps.STEP1);
	let dataType = step1Data ? step1Data.dataType : urlService.getUrlQueryParameter('dataType');
	if (_.isEmpty(dataType)) {
		let currentRegulatoryProgram = urlService.getCurrentRegulatoryProgram();
		if (currentRegulatoryProgram === RegulatoryProgramName.FOG) {
			dataType = urlService.getUrlQueryParameter(
				'dataType',
				DataImport.ImportDataTypeValues.FogFacilitiesAndContacts
			) as string;
		} else if (currentRegulatoryProgram === RegulatoryProgramName.Backflow) {
			dataType = urlService.getUrlQueryParameter(
				'dataType',
				DataImport.ImportDataTypeValues.CccSitesAndHazards
			) as string;
		}
	}
	return dataType;
};

export const getImportEntityPolicyOptions = (): RadioOption[] => {
	const options: RadioOption[] = [
		{
			value: DataImport.ImportEntityPolicy.addAndUpdate,
			label: localizationService.getLocalizedString('import.steps.importPolicyOptionAddAndUpdate'),
			id: 'importPolicyOptionAddAndUpdate'
		},
		{
			value: DataImport.ImportEntityPolicy.addOnly,
			label: localizationService.getLocalizedString('import.steps.importPolicyOptionAddOnly'),
			id: 'importPolicyOptionAddOnly'
		},
		{
			value: DataImport.ImportEntityPolicy.updateOnly,
			label: localizationService.getLocalizedString('import.steps.importPolicyOptionUpdateOnly'),
			id: 'importPolicyOptionUpdateOnly'
		}
	];
	return options;
};

export const getImportFieldUpdatePolicyOptions = (): RadioOption[] => {
	const options: RadioOption[] = [
		{
			value: DataImport.FieldUpdatePolicy.alwaysUpdate,
			label: localizationService.getLocalizedString('import.steps.importCellValuePolicyAlwaysUpdate')
		},
		{
			value: DataImport.FieldUpdatePolicy.updateOnlyWhenEmpty,
			label: localizationService.getLocalizedString('import.steps.importCellValuePolicyOnlyUpdateEmpty')
		},
		{
			value: DataImport.FieldUpdatePolicy.neverUpdate,
			label: localizationService.getLocalizedString('import.steps.importCellValuePolicyNeverUpdate')
		}
	];
	return options;
};

export const getLookupAutoTranslatePolicyOptions = (resolvedLookupLabel: string): RadioOption[] => {
	const options: RadioOption[] = [
		{
			value: localizationService.getLocalizedString('constants.yes'),
			label: localizationService.getLocalizedString('import.steps.autoTranslateFileValuesPolicyAddMyValues')
		},
		{
			value: localizationService.getLocalizedString('constants.no'),
			label: localizationService.getLocalizedString(
				'import.steps.autoTranslateFileValuesPolicyMapMyValues',
				resolvedLookupLabel
			)
		}
	];
	return options;
};

export const getSaveImportConfigurationOptions = (): RadioOption[] => {
	const options: RadioOption[] = [
		{
			value: localizationService.getLocalizedString('constants.yes'),
			label: localizationService.getLocalizedString('constants.yes')
		},
		{
			value: localizationService.getLocalizedString('constants.no'),
			label: localizationService.getLocalizedString('constants.no')
		}
	];
	return options;
};

const getFieldOptionEntity = (importEntity: string): string | null => {
	if (importEntity === DataImport.ImportEntity.FogFacility) {
		return localizationService.getLocalizedString('screen.labels.facility');
	}
	if (importEntity === DataImport.ImportEntity.FogContact) {
		return localizationService.getLocalizedString('screen.labels.contact');
	}
	if (importEntity === DataImport.ImportEntity.FogDevice) {
		return localizationService.getLocalizedString('screen.labels.device');
	}
	if (importEntity === DataImport.ImportEntity.FogInspection) {
		return localizationService.getLocalizedString('inspectionForm.inspection');
	}
	if (importEntity === DataImport.ImportEntity.FogHauler) {
		return localizationService.getLocalizedString('haulers.hauler');
	}
	if (importEntity === DataImport.ImportEntity.FogEvent) {
		return localizationService.getLocalizedString('events.event');
	}
	if (importEntity === DataImport.ImportEntity.FogCleaning) {
		return localizationService.getLocalizedString('pumpOut.pumpOut');
	}
	throw new Error(``);
};

export const getImportDomainObjectLabel = (importDataType: string) => {
	if (importDataType === DataImport.ImportDataTypeValues.FogFacilitiesAndContacts) {
		return localizationService.getLocalizedString('import.dataTypes.fogFacilitiesAndContacts');
	}
	if (importDataType === DataImport.ImportDataTypeValues.FogInspections) {
		return localizationService.getLocalizedString('import.dataTypes.fogInspections');
	}
	if (importDataType === DataImport.ImportDataTypeValues.FogDevices) {
		return localizationService.getLocalizedString('import.dataTypes.fogDevice');
	}
	if (importDataType === DataImport.ImportDataTypeValues.FogHaulers) {
		return localizationService.getLocalizedString('import.dataTypes.fogHaulers');
	}
	if (importDataType === DataImport.ImportDataTypeValues.FogEvents) {
		return localizationService.getLocalizedString('import.dataTypes.fogEvents');
	}
	if (importDataType === DataImport.ImportDataTypeValues.FogCleanings) {
		return localizationService.getLocalizedString('import.dataTypes.fogCleanings');
	}
	if (importDataType === DataImport.ImportDataTypeValues.CccSitesAndHazards) {
		return localizationService.getLocalizedString('import.dataTypes.cccSitesAndHazards');
	}
	throw new Error(`Cannot get import domain object label from import data type ${importDataType}`);
};

export const toTargetFieldOptions = (targetFields?: DataImport.ImportField[]): DropDownOption[] => {
	var options: DropDownOption[] = [];
	if (targetFields && targetFields.length > 0) {
		_.forEach(targetFields, (targetField: DataImport.ImportField) => {
			if (!targetField.isHidden) {
				options.push({
					label: targetField.fieldName, //targetField.fieldLabel might have duplicate values, use fieldName which is unique.
					value: targetField.fieldName,
					prefix: getFieldOptionEntity(targetField.importEntity)
				});
			}
		});
	}
	return options;
};

export const getLookupUrl = (targetField: DataImport.ImportField): string => {
	const importEntity = targetField.importEntity;
	if (importEntity === DataImport.ImportEntity.CccSite) {
		return urlService.getAuthorityResourcesApiUrl(Resource.CccSites) + `/Fields/${targetField.fieldName}`;
	}
	if (importEntity === DataImport.ImportEntity.CccHazard) {
		return urlService.getAuthorityResourcesApiUrl(Resource.CccHazards) + `/Fields/${targetField.fieldName}`;
	}
	if (importEntity === DataImport.ImportEntity.CccContact) {
		return urlService.getAuthorityResourcesApiUrl(Resource.CccLinkedContacts) + `/Fields/${targetField.fieldName}`;
	}

	//For fog lookup values
	let queryParams = new QueryParameters().add('importEntity', importEntity);
	if (targetField.isCustomField) {
		queryParams.add('customFieldName', targetField.fieldName);
	}
	let importUrl =
		urlService.getAuthorityResourcesApiUrl('Settings/AuthoritySettings/ImportLookups/') +
		`${targetField.importLookupType}?${queryParams.toQueryString()}`;
	return importUrl;
};

export const composeEditorLookupOptions = (lookups: string[]): DropDownOption[] => {
	let lookupData: DropDownOption[] = [];
	_.forEach(lookups, (lookup: string) => {
		lookupData.push({
			label: lookup,
			value: lookup
		});
	});
	return lookupData;
};

const spaceKey = String.fromCharCode(32);
const toExcelRow = (data: string[], rowNumber: number): Dictionary<string> => {
	var row: Dictionary<string> = {};
	row[spaceKey] = _.toString(rowNumber);
	for (let i = 0; i < data.length; i++) {
		let quotient = Math.floor(i / 26);
		let remainder: number = i % 26;
		let prefix = quotient > 0 ? String.fromCharCode(65 + quotient - 1) : '';
		let key = prefix + String.fromCharCode(65 + remainder);
		row[key] = data[i];
	}
	return row;
};
export const getMapConfigurationTableData = (
	uploadDataColumns?: string[],
	uploadDataRows?: string[][]
): Dictionary<string>[] => {
	let data: Dictionary<string>[] = [];
	if (uploadDataColumns && uploadDataRows) {
		let rowNumber = 1;
		var header = toExcelRow(uploadDataColumns, rowNumber++);
		data.push(header);
		for (let i = 0; i < uploadDataRows.length; i++) {
			var row = toExcelRow(uploadDataRows[i], rowNumber++);
			data.push(row);
		}
	}
	return data;
};

export const getFieldMappingsUrl = (importConfigurationId: number): string => {
	let authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
	return `${authoritySettingsUrl}/ImportConfigurations/${importConfigurationId}/FieldMappings`;
};

const onErrorSaveFieldMapping = (error: ApiError) => {
	alertService.addError(error.message);
};

const onSuccessSaveFieldMapping = (
	prevFieldMappings: DataImport.ImportFieldMapping[],
	savedFieldMapping: DataImport.ImportFieldMapping,
	columnId: string,
	importConfigurationId: number
) => {
	let newFieldMappings = [...prevFieldMappings];
	let fieldMappingIdsShouldDelete: number[] = newFieldMappings
		.filter(
			f =>
				f.fieldName === savedFieldMapping.fieldName &&
				f.importFieldMappingId !== savedFieldMapping.importFieldMappingId
		)
		.map(f => f.importFieldMappingId as number);
	_.remove(
		newFieldMappings,
		f =>
			f.fieldName === savedFieldMapping.fieldName ||
			f.importFieldMappingId === savedFieldMapping.importFieldMappingId
	);
	newFieldMappings.push(savedFieldMapping);
	if (columnId) {
		const alertString = localizationService.getLocalizedString(
			'alertMessages.ColumnUpdatedSuccess',
			columnId,
			savedFieldMapping.fieldName as string
		);
		Logger.info(`${alertString}`);
	}
	if (fieldMappingIdsShouldDelete.length > 0) {
		fieldMappingIdsShouldDelete.forEach(id => {
			apiService.deleteResource(`${getFieldMappingsUrl(importConfigurationId)}/${id}`);
		});
	}
	return newFieldMappings;
};

const onSuccessDeleteFieldMapping = (
	prevFieldMappings: DataImport.ImportFieldMapping[],
	importFieldMappingId: number
) => {
	let newFieldMappings = [...prevFieldMappings];
	_.remove(newFieldMappings, f => f.importFieldMappingId === importFieldMappingId);
	return newFieldMappings;
};

export const closeSideEditor = (selfImportState: SelfImportState, dispatch: any) => {
	if (selfImportState.showSidePanel) {
		//close side editor when user move to preview.
		dispatch(updateSelfImportState({ showSidePanel: false }));
	}
};

const actualSaveConfigurationFieldMapping = async (
	fieldMappingForSave: DataImport.ImportFieldMapping,
	dispatch: any,
	currentFieldMappings: DataImport.ImportFieldMapping[],
	importConfigurationId: number,
	importTargetFields?: DataImport.ImportField[]
): Promise<void> => {
	let fieldMapping = { ...fieldMappingForSave };
	if (importTargetFields && !_.isEmpty(fieldMapping.fieldName)) {
		let targetField = importTargetFields.find(f => f.fieldName === fieldMapping.fieldName);
		if (targetField) {
			fieldMapping.fieldLabel = targetField.fieldLabel;
			fieldMapping.isCustomField = targetField.isCustomField;
			fieldMapping.isNotNull = targetField.isNotNull;
			fieldMapping.importEntity = targetField.importEntity;
			fieldMapping.isRequiredForDataEnter = targetField.isRequiredForDataEnter;
			fieldMapping.fieldType = targetField.fieldType;
			fieldMapping.autoTranslateFileValues =
				fieldMapping.autoTranslateFileValues && targetField.canAutoAddNewValues;
		}
	}

	let fieldMappingsUrl = getFieldMappingsUrl(importConfigurationId);
	if (fieldMapping.importFieldMappingId) {
		let fieldMappingUrl = `${fieldMappingsUrl}/${fieldMapping.importFieldMappingId}`;
		if (_.isEmpty(fieldMapping.fieldName)) {
			Logger.debug(
				`Removed ImportFieldMapping ${fieldMapping.importColumnName}, which id is ${fieldMapping.importFieldMappingId}.`
			);
			return apiService
				.deleteResource(fieldMappingUrl)
				.then(() => {
					let newFiledMappings = onSuccessDeleteFieldMapping(
						currentFieldMappings,
						fieldMapping.importFieldMappingId as number
					);
					dispatch(updateSelfImportState({ fieldMappings: newFiledMappings, fieldMapping: null }));
					const message = localizationService.getLocalizedString(
						'alertMessages.ColumnUnMapped',
						fieldMapping.importColumnName
					);
					Logger.info(message);
				})
				.catch(error => {
					onErrorSaveFieldMapping(error);
				});
		} else {
			Logger.debug(
				`Updated ImportFieldMapping ${fieldMapping.importColumnName}, which id is ${fieldMapping.importFieldMappingId}.`
			);
			return apiService
				.patchResource<DataImport.ImportFieldMapping>(fieldMappingUrl, fieldMapping)
				.then(savedFieldMapping => {
					let newFiledMappings = onSuccessSaveFieldMapping(
						currentFieldMappings,
						savedFieldMapping,
						fieldMapping.importColumnName,
						importConfigurationId
					);
					dispatch(
						updateSelfImportState({ fieldMappings: newFiledMappings, fieldMapping: savedFieldMapping })
					);
				})
				.catch(error => {
					onErrorSaveFieldMapping(error);
				});
		}
	} else {
		if (!_.isEmpty(fieldMapping.fieldName)) {
			Logger.debug(
				`Create new ImportFieldMapping ${fieldMapping.importColumnName} map to ${fieldMapping.fieldName}.`
			);
			return apiService
				.postResource<DataImport.ImportFieldMapping>(fieldMappingsUrl, {
					...fieldMapping
				})
				.then(savedFieldMapping => {
					let newFiledMappings = onSuccessSaveFieldMapping(
						currentFieldMappings,
						savedFieldMapping,
						fieldMapping.importColumnName,
						importConfigurationId
					);
					dispatch(
						updateSelfImportState({ fieldMappings: newFiledMappings, fieldMapping: savedFieldMapping })
					);
				})
				.catch(error => {
					onErrorSaveFieldMapping(error);
				});
		}
	}
};

export const saveConfigurationFieldMapping = async (
	fieldMappingForSave: DataImport.ImportFieldMapping,
	dispatch: any,
	currentFieldMappings: DataImport.ImportFieldMapping[],
	importConfigurationId: number,
	importTargetFields?: DataImport.ImportField[]
): Promise<void> => {
	try {
		await dispatch(updateSelfImportState({ isSaving: true }));
		return await actualSaveConfigurationFieldMapping(
			fieldMappingForSave,
			dispatch,
			currentFieldMappings,
			importConfigurationId,
			importTargetFields
		);
	} finally {
		await dispatch(updateSelfImportState({ isSaving: false }));
	}
};
