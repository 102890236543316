import React, { useEffect } from 'react';
import { apiService, dashboardService, localizationService, navigateTo, urlService, UtilService } from 'src/services';
import {
	ApiError,
	CountListData,
	CountListItem,
	DashboardWidget,
	DashboardWidgetFilter,
	DashboardWidgetFilterCategories,
	FeatureNames
} from '@rcp/types';
import { alertService, useReduxSelector } from 'src/redux';
import _ from 'lodash';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useHistory } from 'react-router';

interface OwnProps {
	dashboardWidget: DashboardWidget;
}
type Props = OwnProps;

export const CountListWidget: React.FC<Props> = props => {
	const [countListItems, setCountListItems] = React.useState<CountListItem[]>([]);
	const history = useHistory();
	const showMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);

	const getGridPageUrl = (filter: DashboardWidgetFilter) => {
		let filterCategory;
		switch (filter.filterCategory) {
			case DashboardWidgetFilterCategories.Extractors:
				filterCategory = DashboardWidgetFilterCategories.Devices;
				break;
			default:
				filterCategory = filter.filterCategory;
		}
		return filterCategory;
	};

	const loadCountListData = (widgetId: number, dashboardWidgetFilters: DashboardWidgetFilter[]) => {
		let url = `${urlService.getApiBaseUrlWithProgram()}/Dashboard/CountList/${widgetId}`;
		apiService
			.getResource<CountListData>(url)
			.then(r => {
				let newCountListItems: CountListItem[] = [];
				_.each(r.countItems, item => {
					let filter = _.find(dashboardWidgetFilters, df => df.filterId === item.filterId);
					if (filter) {
						let newListItem: CountListItem = {
							filterId: filter.filterId,
							filterName: filter.filterName,
							filterCategory: filter.filterCategory,
							count: item.count as number,
							gridPath: dashboardService.getGridPath(filter.filterId as number, getGridPageUrl(filter)),
							mapPath: dashboardService.getMapPath(filter),
							sortOrder: item.sortOrder
						};
						newCountListItems.push(newListItem);
					}
				});
				_.sortBy(newCountListItems, 'sortOrder');
				setCountListItems(newCountListItems);
			})
			.catch((e: ApiError) => {
				alertService.addError(e.message);
			});
	};

	const onClickCount = (item: CountListItem, willNavigateToMap?: boolean) => {
		if (willNavigateToMap) {
			navigateTo(history, item.mapPath);
		} else {
			navigateTo(history, item.gridPath);
		}
	};

	useEffect(() => {
		let dashboardWidgetFilters: DashboardWidgetFilter[] = [];
		if (props.dashboardWidget.dashboardWidgetFilters) {
			dashboardWidgetFilters = UtilService.fromJson(props.dashboardWidget.dashboardWidgetFilters);
		}
		if (props.dashboardWidget.dashboardWidgetId) {
			loadCountListData(props.dashboardWidget.dashboardWidgetId, dashboardWidgetFilters);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.dashboardWidget]);

	const getRows = () => {
		return countListItems.map((item, index) => {
			return (
				<tr className="lookup-row" key={`key_${props.dashboardWidget.dashboardWidgetId}_${index}`}>
					<td id={`filterName_${props.dashboardWidget.dashboardWidgetId}_${index}`}>{item.filterName}</td>

					<td align="right">
						{showMapFeature ? (
							<UncontrolledDropdown>
								<DropdownToggle tag="a" className="cursor-pointer">
									{item.count}
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem onClick={() => onClickCount(item, true)}>
										{localizationService.getLocalizedString('screen.buttons.showOnMap')}
									</DropdownItem>
									<DropdownItem onClick={() => onClickCount(item)}>
										{localizationService.getLocalizedString('screen.buttons.showInTable')}
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						) : (
							<a href="#/" className="ai-link cursor-pointer" onClick={() => onClickCount(item)}>
								{item.count}
							</a>
						)}
					</td>
				</tr>
			);
		});
	};

	return (
		<>
			<div className="table-responsive">
				<table className="table">
					<thead>
						<tr className="lookup-row lookup-header-row font-size-14px-semibold">
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>{getRows()}</tbody>
				</table>
			</div>
		</>
	);
};
