import { IppAASAuthorityInformationState, initialIppAASAuthorityInformationState } from './aas-authority-information-state';
import { FetchIppAASAuthorityInformationDataAction } from './aas-authority-information-actions';
import EIppAASAuthorityInformationActionType from './aas-authority-information-action-types';

export const ippAASAuthorityInformationReducer = (
	state = initialIppAASAuthorityInformationState,
	action: FetchIppAASAuthorityInformationDataAction
): IppAASAuthorityInformationState => {
	switch (action.type) {
		case EIppAASAuthorityInformationActionType.FetchIppAASAuthorityInformationData:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
