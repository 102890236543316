import { LookupRow } from '../lookup-types';
import { LookupType, Unit } from '@rcp/types';
import { ILookupDataConverter, SettingValueOrLookupRow } from './lookup-data-converter';

export class UnitRowLookDataConverter implements ILookupDataConverter {
	lookupType: LookupType;

	constructor(lookupType: LookupType) {
		this.lookupType = lookupType;
	}

	toLookupRowFromAPI = (apiData: Unit): LookupRow => {
		return {
			lookupId: apiData.unitId,
			code: apiData.name,
			codeDescription: apiData.description,
			isActive: apiData.isActive,
			isInUse: apiData.isInUse
		} as LookupRow;
	};

	toAPIUpdateDataFromLookups(existingLookupRows: LookupRow[], rowToUpdate: LookupRow): SettingValueOrLookupRow {
		let unit = {
			unitId: rowToUpdate.lookupId,
			name: rowToUpdate.code,
			description: rowToUpdate.codeDescription,
			isActive: rowToUpdate.isActive
		} as Unit;
		return unit;
	}

	toLookupRowsFromAPI(apiData: Unit[]): LookupRow[] {
		return apiData && apiData.map(i => this.toLookupRowFromAPI(i));
	}

	toAPIAddDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): Unit {
		let unit = {
			name: newValue.code,
			description: newValue.codeDescription,
			isActive: newValue.isActive || true
		} as Unit;
		return unit;
	}

	toAPIDeleteDataFromLookups(existingLookupRows: LookupRow[], newValue: LookupRow): SettingValueOrLookupRow {
		return newValue;
	}
}
