import {
	Frequency,
	CustomFieldDataType,
	Tag,
	AuthorityUser,
	yesNoBooleanOptions,
	ICustomFieldItem,
	Lookup,
	Jurisdiction,
	DropDownOption,
	Hauler,
	RiskScoreValues
} from '@rcp/types';
import { optionsMap, localizationService } from 'src/services';
import { IFacilityItem, FieldTypeEnum } from '../../facility-types';

export interface selectableItem {
	fieldName: string;
	title: string;
	chosen: boolean;
}
export interface CustomField extends ICustomFieldItem, selectableItem {
	options?: any[];
}

export interface FacilityField extends IFacilityItem, selectableItem {}

export interface BulkUpdateFields {
	facilityFields: FacilityField[];
	customFields: CustomField[];
}

enum FieldName {
	inspectionFrequencyId = 'inspectionFrequencyId',
	overriddenComplianceStatus = 'overriddenComplianceStatus',
	tagClassification1 = 'tagClassification1',
	tagClassification2 = 'tagClassification2',
	assignedToUserProfileId = 'assignedToUserProfileId',
	isActive = 'isEnabled',
	mapCategory = 'mapCategoryId',
	receivingPlant = 'receivingPlantId',
	closedDate = 'closedDate',
	cityName = 'cityName',
	jurisdictionId = 'jurisdictionId',
	addressLine1 = 'addressLine1',
	addressLine2 = 'addressLine2',
	zipCode = 'zipCode',
	riskScore = 'riskScore',
	riskScoreNote = 'riskScoreNote',
	contractedHaulerId = 'contractedHaulerId'
}

export const BulkUpdateFieldList: FacilityField[] = [
	{
		fieldName: FieldName.inspectionFrequencyId,
		title: localizationService.getLocalizedString('facility.inspectionFrequencyCode'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.overriddenComplianceStatus,
		title: localizationService.getLocalizedString('inspection.complianceStatus'),
		isRequired: true,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.assignedToUserProfileId,
		title: localizationService.getLocalizedString('facility.assignedTo'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.tagClassification1,
		title: localizationService.getLocalizedString('facility.tagClassification1'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.tagClassification2,
		title: localizationService.getLocalizedString('facility.tagClassification2'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.isActive,
		title: localizationService.getLocalizedString('facility.isEnabled'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown,
		optionValues: yesNoBooleanOptions
	} as FacilityField,
	{
		fieldName: FieldName.mapCategory,
		title: localizationService.getLocalizedString('facility.mapCategory'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.receivingPlant,
		title: localizationService.getLocalizedString('facility.receivingPlant'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.closedDate,
		title: localizationService.getLocalizedString('facility.closedDate'),
		isRequired: false,
		dataType: FieldTypeEnum.Date
	} as FacilityField,
	{
		fieldName: FieldName.cityName,
		title: localizationService.getLocalizedString('facility.cityName'),
		isRequired: false,
		dataType: FieldTypeEnum.String
	} as FacilityField,
	{
		fieldName: FieldName.jurisdictionId,
		title: localizationService.getLocalizedString('facility.state'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.zipCode,
		title: localizationService.getLocalizedString('facility.zipCode'),
		isRequired: false,
		dataType: FieldTypeEnum.String
	} as FacilityField,
	{
		fieldName: FieldName.riskScore,
		title: localizationService.getLocalizedString('facility.riskScore'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField,
	{
		fieldName: FieldName.riskScoreNote,
		title: localizationService.getLocalizedString('facility.riskScoreNotes'),
		isRequired: false,
		dataType: FieldTypeEnum.RichTextEditor
	} as FacilityField,
	{
		fieldName: FieldName.contractedHaulerId,
		title: localizationService.getLocalizedString('facility.contractedHaulerName'),
		isRequired: false,
		dataType: FieldTypeEnum.SingleValueDropDown
	} as FacilityField
].sort((i: FacilityField, j: FacilityField) => {
	if (i.title > j.title) return 1;
	return -1;
});

export const processCustomField = (customField: CustomField) => {
	if (String.equalCaseInsensitive(customField.fieldDataType, CustomFieldDataType.SingleValueDropDown)) {
		if (customField.defaultValueOrOptionalValues) {
			let defaultValues = customField.defaultValueOrOptionalValues.split(',');
			customField.options = defaultValues.map((item: string) => ({ label: item, value: item }));
		}
	}

	return customField;
};

export const setFacilityFrequencyFieldsList = (frequencies: Frequency[], setState: Function, state: any) => {
	const inspectionFrequency = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.inspectionFrequencyId)
	);
	if (inspectionFrequency) {
		inspectionFrequency.optionValues = optionsMap.fromFrequencyList(frequencies).slice();
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setFacilityTagFieldsList = (tag: Tag, setState: Function, state: any) => {
	const classification1Field = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.tagClassification1)
	);
	if (classification1Field) {
		classification1Field.optionValues = optionsMap.fromClassificationList(tag.FacilityClassification1);
	}

	const classification2Field = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.tagClassification2)
	);
	if (classification2Field) {
		classification2Field.optionValues = optionsMap.fromClassificationList(tag.FacilityClassification2);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setAssignedToFieldList = (authorityUsers: AuthorityUser[], setState: Function, state: any) => {
	const assignedToField = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.assignedToUserProfileId)
	);
	if (assignedToField) {
		assignedToField.optionValues = optionsMap.fromUserProfiles(authorityUsers);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setHaulersFieldList = (haulers: Hauler[], setState: Function, state: any) => {
	const haulerField = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.contractedHaulerId)
	);
	if (haulerField) {
		haulerField.optionValues = optionsMap.fromHaulers(haulers);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setMapCategoryFieldList = (mapCategories: Lookup[], setState: Function, state: any) => {
	const mapCategory = BulkUpdateFieldList.find(i => String.equalCaseInsensitive(i.fieldName, FieldName.mapCategory));
	if (mapCategory) {
		mapCategory.optionValues = optionsMap.fromLookups(mapCategories);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setRiskScoreValues = (setState: Function, state: any) => {
	const riskScore = BulkUpdateFieldList.find(i => String.equalCaseInsensitive(i.fieldName, FieldName.riskScore));
	if (riskScore) {
		riskScore.optionValues = Object.values(RiskScoreValues).map(x => {
			return {
				value: x.toString(),
				label: localizationService.getLocalizedString(`facility.riskScore${x.toString()}`)
			};
		}) as DropDownOption[];
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setReceivingPlantFieldList = (receivingPlants: Lookup[], setState: Function, state: any) => {
	const receivingPlant = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.receivingPlant)
	);
	if (receivingPlant) {
		receivingPlant.optionValues = optionsMap.fromLookups(receivingPlants);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setJurisdictionFieldList = (jurisdictions: Jurisdiction[], setState: Function, state: any) => {
	const jurisdictionField = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.jurisdictionId)
	);
	if (jurisdictionField) {
		jurisdictionField.optionValues = optionsMap.fromJurisdiction(jurisdictions);
	}
	setState({ customFields: state.customFields, facilityFields: BulkUpdateFieldList });
};

export const setComplianceStatusFieldList = (complianceStatusList: string[]) => {
	const complianceStatusField = BulkUpdateFieldList.find(i =>
		String.equalCaseInsensitive(i.fieldName, FieldName.overriddenComplianceStatus)
	);
	let complianceStatusOptions = [
		{
			label: localizationService.getLocalizedString('bulkUpdate.removeOverrideStatus'),
			value: 'removeOverrideStatus'
		} as DropDownOption
	].concat(optionsMap.fromStrings(complianceStatusList));

	if (complianceStatusField) {
		complianceStatusField.optionValues = complianceStatusOptions;
	}
};
