import React, { FC, useEffect, useState } from 'react';
import { apiService, localizationService, navigateTo, Resource, tokenService, urlService } from 'src/services';
import CollapsibleCard from '../widgets/collapsible-card/collapsible-card';
import PortalDirectorGrid from './portal-director-grid';
import queryString from 'query-string';
import { Token, RegulatoryProgramName } from '@rcp/types';
import { AlertMessageType, alertService } from 'src/redux';
import { IppTestConstants as Constants } from 'src/constants';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';

const PortalDirector: FC<any> = props => {
	const [fogPortals, setFogPortals] = useState([] as any);
	const [backflowPortals, setBackflowPortals] = useState([] as any);
	const [ippPortals, setIppPortals] = useState([] as any);
	const [allowedPortals, setAllowedPortals] = useState([] as any);
	const [showInviteCodeEntry, setShowInviteCodeEntry] = useState(true);
	const accountInviteUrl = `/account/invite`;
	const loadAllowedPortals = async () => {
		let url = `${urlService.getApiBaseUrl()}/${Resource.AllowedPortals}`;
		try {
			let allowedPortals: any = await apiService.getResource(url);
			allowedPortals = urlService.removePortalsWithRoleCTSSyncUser(allowedPortals);
			setAllowedPortals(allowedPortals);
			if (allowedPortals.length === 0) {
				setShowInviteCodeEntry(false);
			}
		} catch (ex) {
			if (ex.statusCode === Constants.unauthorizedStatusCode) {
				alertService.addError(localizationService.getLocalizedString('errors.notAuthorized'));
				tokenService.clearToken();
				window.location.href = urlService.getLoginUrl('signOut=true');
			} else {
				alertService.addError(ex.message);
			}
		}
	};

	useEffect(() => {
		if (tokenService.getTokenOrDefault().accessToken) {
			loadAllowedPortals();
		} else {
			window.location.href = urlService.getLoginUrl('signOut=true');
		}
	}, []);

	useEffect(() => {
		let fog: any[] = [];
		let backflow: any[] = [];
		let ipp: any[] = [];
		let { IPP, Backflow, FOG } = RegulatoryProgramName;
		allowedPortals &&
			allowedPortals.map((portal: any) => {
				let userIssuedRegulatoryProgramNames = portal.userIssuedPrograms.map((r: any) =>
					r.regulatoryProgramName.toLowerCase()
				);

				if (userIssuedRegulatoryProgramNames.includes(FOG.toLowerCase())) {
					fog.push({
						...portal,
						organizationRegulatoryProgramId: portal.userIssuedPrograms.find((issuedProgram: any) =>
							String.equalCaseInsensitive(issuedProgram.regulatoryProgramName, FOG.toLowerCase())
						).organizationRegulatoryProgramId
					});
				}
				if (userIssuedRegulatoryProgramNames.includes(Backflow.toLowerCase())) {
					backflow.push({
						...portal,
						organizationRegulatoryProgramId: portal.userIssuedPrograms.find((issuedProgram: any) =>
							String.equalCaseInsensitive(issuedProgram.regulatoryProgramName, Backflow.toLowerCase())
						).organizationRegulatoryProgramId
					});
				}
				if (userIssuedRegulatoryProgramNames.includes(IPP.toLowerCase())) {
					ipp.push({
						...portal,
						organizationRegulatoryProgramId: portal.userIssuedPrograms.find((issuedProgram: any) =>
							String.equalCaseInsensitive(issuedProgram.regulatoryProgramName, IPP.toLowerCase())
						).organizationRegulatoryProgramId
					});
				}
				return portal;
			});

		setFogPortals(fog);
		setBackflowPortals(backflow);
		setIppPortals(ipp);

		if (!tokenService.isTokenValid(tokenService.getTokenOrDefault())) {
			navigateTo(props.history, '/signIn');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowedPortals]);

	const gridProps = () => {
		let qs = window.location.search;
		let queryParameters = queryString.parse(qs);
		let token: Token = tokenService.getTokenOrDefault();
		if (token.signInResult) {
			token.ssoFrom = token.signInResult.ssoFrom;
			token.refreshToken = token.signInResult.refreshToken;
			token.correlationId = token.signInResult.correlationId;
			token.userName = token.signInResult.userName;
			token.email = token.signInResult.email;
		}
		if (Object.keys(queryParameters).length > 0) {
			token = { ...token, ...queryParameters };
		}
		return {
			accessToken: (props.location.state && props.location.state.accessToken) || token.accessToken,
			expirationEpochTimeInUtc:
				(props.location.state && props.location.state.expirationEpochTimeInUtc) ||
				token.expirationEpochTimeInUtc,
			refreshToken: (props.location.state && props.location.state.refreshToken) || token.refreshToken,
			correlationId: token.correlationId,
			tokenType: token.tokenType,
			tokenProvider: token.tokenProvider,
			ssoFrom: token.ssoFrom,
			userName: (props && props.userName) || token.userName,
			email: (props && props.userName) || token.email,
			mfaDetails:
				(props.location.state && props.location.state.regulatoryProgramMFATypeDetails) ||
				token.mfaEnabledPrograms
		};
	};
	return (
		<>
			<div className="page">
				<div className="page-header">
					<h1 className="text-left">
						{localizationService.getLocalizedString('ipp.accountPortal.portalDirector')}
					</h1>
				</div>
				<div className="page-wrapper">
					<div className="main">
						{showInviteCodeEntry ? (
							<>
								{fogPortals.length + backflowPortals.length + ippPortals.length > 1 && (
									<p>
										{localizationService.getLocalizedString(
											'ipp.accountPortal.messages.portalDirectorMessage'
										)}
									</p>
								)}
								{Boolean(fogPortals.length) && (
									<CollapsibleCard
										key={fogPortals.organizationId}
										className="mt-3"
										accordionType={`fog-organizationId`}
										accordionHeading={
											<span>
												{localizationService.getLocalizedString('ipp.accountPortal.programFOG')}
											</span>
										}>
										<PortalDirectorGrid gridData={fogPortals} type="fog" {...gridProps()} />
									</CollapsibleCard>
								)}
								{Boolean(backflowPortals.length) && (
									<CollapsibleCard
										key={backflowPortals.organizationId}
										className="mt-3"
										accordionType={`backflow-organizationId`}
										accordionHeading={
											<span>
												{localizationService.getLocalizedString('ipp.accountPortal.programCCC')}
											</span>
										}>
										<PortalDirectorGrid
											gridData={backflowPortals}
											type="backflow"
											{...gridProps()}
										/>
									</CollapsibleCard>
								)}
								{Boolean(ippPortals.length) && (
									<CollapsibleCard
										key={ippPortals.organizationId}
										className="mt-3"
										accordionType={`ipp-organizationId`}
										accordionHeading={
											<span>
												{localizationService.getLocalizedString('ipp.accountPortal.programIPP')}
											</span>
										}>
										<PortalDirectorGrid gridData={ippPortals} type="ipp" {...gridProps()} />
									</CollapsibleCard>
								)}
							</>
						) : (
							<div className="card no-associated-organizations">
								<InlineAlertItem
									message={localizationService.getLocalizedString(
										'alertMessages.userNotAssociatedOrganizations'
									)}
									alertType={AlertMessageType.Error}
								/>
								<p>
									{localizationService.getLocalizedString(
										'authentication.noAssociatedHaulerOrFacilityPortalPart1'
									)}
									<a href={accountInviteUrl} id="invite-code-link">
										{localizationService.getLocalizedString(
											'authentication.noAssociatedHaulerOrFacilityPortalPart2'
										)}
									</a>
								</p>
								<p>
									{localizationService.getLocalizedString(
										'authentication.noAssociatedIndustryOrAuthorityPortal'
									)}
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default PortalDirector;
