import React from 'react';
import { alertService, useReduxDispatch } from 'src/redux';
import { apiService, DateUtilService, localizationService, urlService } from 'src/services';
import _ from 'lodash';
import * as ApiTypes from '@rcp/types';
import { NotFound } from 'src/features/home/not-found';
import { Translate } from 'src/components/widgets/translate/translator';
import { translateService } from 'src/services/translate-service';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { lang } from 'moment';

interface OwnProps {}

interface RouteProps {
	match: {
		params: {
			publicId: string;
		};
	};
}

type Props = OwnProps & RouteProps;

interface FormFields {
	fromEmailAddress: React.ReactNode;
	recipientEmailAddresses: React.ReactNode;
	subject: string;
	content: string;
	sentDateTimeUtc: string;
	ccRecipientEmailAddresses?: React.ReactNode;
}
const initialFormFields: FormFields = {
	fromEmailAddress: <></>,
	recipientEmailAddresses: <></>,
	subject: '',
	content: '',
	sentDateTimeUtc: '',
	ccRecipientEmailAddresses: <></>
};

export const PublicEmailView: React.FC<Props> = props => {
	const dispatch = useReduxDispatch();
	const [emailLog, setEmailLog] = React.useState<ApiTypes.EmailLog>();
	const [formState, setFormState] = React.useState(initialFormFields);

	const language = React.useContext(LanguageContext);

	React.useEffect(() => {
		document.body.className = 'publicPage';
	}, []);

	React.useEffect(() => {
		getEmailLog(language.language.code);
	}, [dispatch, props.match.params.publicId, language]);

	React.useEffect(() => {
		populateEmailContent(language.language.code);
	}, [emailLog]);

	const populateEmailContent = async (languageCode: string) => {
		if (emailLog && emailLog.emailMessage) {
			let emailMessage = emailLog.emailMessage;
			const newState: FormFields = {
				fromEmailAddress: emailMessage.fromEmailAddress ? (
					emailMessage.fromEmailAddress.name ? (
						emailMessage.fromEmailAddress.name
					) : (
						emailMessage.fromEmailAddress.address
					)
				) : (
					<></>
				),
				recipientEmailAddresses: emailMessage.recipientEmailAddresses ? (
					emailMessage.recipientEmailAddresses
						.map<React.ReactNode>(x => emailLink(x))
						.reduce((prev, curr) => [prev, ', ', curr])
				) : (
					<></>
				),
				ccRecipientEmailAddresses:
					emailMessage.ccRecipientEmailAddresses && emailMessage.ccRecipientEmailAddresses.length > 0 ? (
						emailMessage.ccRecipientEmailAddresses
							.map<React.ReactNode>(x => emailLink(x))
							.reduce((prev, curr) => [prev, prev ? ', ' : '', curr], '')
					) : (
						<></>
					),
				subject: await translateService.translateHtml(emailMessage.subject),
				content: await translateService.translateHtml(emailMessage.content),
				sentDateTimeUtc: emailLog.sentDateTimeUtc as string
			};
			var predefinedTranslation = _.get(emailLog.predefinedTranslations, languageCode) as any;
			if (predefinedTranslation) {
				newState.subject = predefinedTranslation.subject;
				newState.content = predefinedTranslation.content;
			} else {
				newState.subject = await translateService.translateHtml(emailMessage.subject);
				newState.content = await translateService.translateHtml(emailMessage.content);
			}
			setFormState(newState);
		}
	};

	const emailLink = (emailAddress: ApiTypes.EmailAddress) => {
		if (emailAddress && emailAddress.name) {
			return <> {`${emailAddress.name} <${emailAddress.address}>`}</>;
		} else {
			return <> {`${emailAddress.address}`}</>;
		}
	};

	const getEmailLog = (languageCode: string) => {
		let url = urlService.getEmailPublicUrl(props.match.params.publicId) + `?language=${languageCode}`;
		apiService
			.httpGet(url)
			.then(data => setEmailLog(data))
			.catch(error => {
				alertService.alertError(error);
			});
	};

	return (
		<>
			{_.isEmpty(emailLog?.emailMessage) ? (
				<NotFound />
			) : (
				<div className="page">
					<div className="page-header">
						<h1></h1>
					</div>
					<div className="page-wrapper">
						<div className="main">
							<section>
								<div className="text-break">
									<strong>
										<Translate>
											{localizationService.getLocalizedString('sendEmail.from')}
										</Translate>
										:&nbsp;
									</strong>
									<span translate="no">{formState.fromEmailAddress}</span> <br />
									<strong>
										<Translate>{localizationService.getLocalizedString('sendEmail.to')}</Translate>
										:&nbsp;
									</strong>
									<span translate="no">{formState.recipientEmailAddresses}</span>
									<br />
									{emailLog &&
									emailLog.emailMessage &&
									emailLog.emailMessage.ccRecipientEmailAddresses &&
									emailLog.emailMessage.ccRecipientEmailAddresses.length > 0 ? (
										<>
											<strong>
												<Translate>
													{localizationService.getLocalizedString('sendEmail.Cc')}
												</Translate>
												:&nbsp;
											</strong>
											<span translate="no">{formState.ccRecipientEmailAddresses}</span>
											<br />
										</>
									) : null}
									<strong>
										<Translate>
											{localizationService.getLocalizedString('sendEmail.subject')}
										</Translate>
										:&nbsp;
									</strong>
									<label
										dangerouslySetInnerHTML={{
											__html: formState.subject
										}}
									/>
									<br />
									<strong>
										<Translate>
											{localizationService.getLocalizedString('sendEmail.sent')}
										</Translate>
										:&nbsp;
									</strong>
									<Translate>{`${DateUtilService.toDisplayDate(
										formState.sentDateTimeUtc
									)} ${DateUtilService.toDisplayTime(formState.sentDateTimeUtc)}`}</Translate>
								</div>
								<div
									className="mail-content"
									dangerouslySetInnerHTML={{
										__html: formState.content
									}}
								/>
							</section>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
