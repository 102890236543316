import React, { Component } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { localizationService } from 'src/services';
import { ComplianceStatus } from '@rcp/types';
import _ from 'lodash';

interface ToolTipProps {
	complianceStatus: string;
	name: string;
	dueDateTime: string;
}

export class ComplianceStatusCell extends Component<GridCellProps, any> {
	constructor(props: GridCellProps) {
		super(props);
		this.state = {
			popoverOpen: false
		};
	}

	toggle = () => {
		this.setState({
			popoverOpen: !this.state.popoverOpen
		});
	};

	onEditClicked = (e: any) => {
		e.preventDefault();
		this.toggle();
	};

	render() {
		let domainObjectId = this.props.dataItem['facilityId'] || this.props.dataItem['inspectionEventId'];

		let targetId = `popover_${domainObjectId}`;

		//TODO: use hard code data for now
		let facilityHintProps = {
			violationType: this.props.dataItem['violationType'],
			complianceStatus: this.props.dataItem['complianceStatus'],
			isOverrideComplianceStatus: this.props.dataItem['isOverrideComplianceStatus'],
			daysOpen: 15,
			nonComplianceDate: 'Mar 18, 2019',
			complianceDueDate: 'Mar 8, 2019',
			lastEnforcementDate: 'Feb 3, 2019',
			numbersOfMoreViolation: 1,
			toggle: this.toggle,
			popoverOpen: this.state.popoverOpen,
			target: targetId
		};

		var complianceStatus = facilityHintProps.complianceStatus;

		const complianceStatusText = complianceStatus;
		//TODO: hard code violationType here
		facilityHintProps.violationType = 'Late Pumpout';

		let className = 'compliance-status-green';
		let cellEle: JSX.Element = <td />;

		if (facilityHintProps.isOverrideComplianceStatus) {
			className = 'compliance-status-custom';
			cellEle = (
				<td>
					<FaCircle size={8} className={`${className} mr-1`} />
					<span id={targetId}>{complianceStatusText}</span>
				</td>
			);
		} else if (String.equalCaseInsensitive(complianceStatus, ComplianceStatus.NonCompliant)) {
			className = 'compliance-status-red';
			cellEle = (
				<td>
					<FaCircle size={8} className={`${className} mr-1`} />
					<span id={targetId}>{complianceStatusText}</span>
					{/*Only non-compliance can show tooltips*/}
				</td>
			);
		} else if (String.equalCaseInsensitive(complianceStatus, ComplianceStatus.Unknown)) {
			className = 'compliance-status-custom';
			cellEle = (
				<td>
					<FaRegCircle size={8} className={`${className} mr-1`} />
					<span id={targetId}>{complianceStatusText}</span>
				</td>
			);
		} else if (String.equalCaseInsensitive(complianceStatus, ComplianceStatus.Compliant)) {
			cellEle = (
				<td>
					<FaCircle size={8} className={`${className} mr-1`} />
					<span id={targetId}>{complianceStatusText}</span>
				</td>
			);
		}

		return <>{cellEle}</>;
	}
}
