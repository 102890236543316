import React from 'react';

interface Props {
	posClass?: string;
	pathClass?: string;
	title?: string;
	size?: number;
}

export const MegaPhone: React.SFC<Props> = props => {
	const pStyle = {
		fill: 'slate'
	};

	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			style={pStyle}>
			<title>{props.title}</title>
			<path
				style={pStyle}
				d="M.72,5.91A.72.72,0,0,0,0,6.63V9.37a.72.72,0,0,0,.72.72.73.73,0,0,0,.64-.4l3.14,1a2.66,2.66,0,0,0-.14.85,2.69,2.69,0,0,0,5.28.74l3.68,1.14V2.74l-12,3.55A.73.73,0,0,0,.72,5.91ZM5.64,11l2.86.89a1.5,1.5,0,0,1-2.95-.39A1.43,1.43,0,0,1,5.64,11Z"
			/>
			<rect style={pStyle} x="14.23" y="1.77" width="1.77" height="12.46" rx="0.56" />
		</svg>
	);
};
