import { IppPackageElement } from '@rcp/types';
import _ from 'lodash';
import React, { FC } from 'react';
import { SingleCheckbox } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { ReportPackageStatus } from 'src/constants';
import { localizationService } from 'src/services';

interface Props {
	reportStatus?: string;
	certificationTypes: IppPackageElement[];
	className: string;
	onChangeCertification?: (event: any, id?: number) => void;
}

const IppPackageItemCertifications: FC<Props> = ({
	reportStatus,
	certificationTypes,
	className,
	onChangeCertification
}) => {
	const getCertificationTypes = () => {
		let allCertificationTypes = _.cloneDeep(certificationTypes);
		if (String.equalCaseInsensitive(reportStatus, ReportPackageStatus.Draft) && allCertificationTypes) {
			return allCertificationTypes;
		} else {
			return allCertificationTypes ? allCertificationTypes.filter(certification => certification.isIncluded) : [];
		}
	};
	return (
		<>
			{getCertificationTypes().map((certification, index) => (
				<CollapsibleCard
					key={certification.reportPackageElementTypeId}
					className={className ? className : ''}
					accordionType={`certification-${index.toString()}`}
					accordionHeading={
						<>
							{String.equalCaseInsensitive(reportStatus, ReportPackageStatus.Draft) && (
								<span>
									<SingleCheckbox
										id={`certification${index}`}
										name="isIncluded"
										className="d-inline-flex"
										labelClass="checkbox-margin"
										checked={certification.isIncluded}
										onChange={(event: any) =>
											onChangeCertification &&
											onChangeCertification(event, certification.reportPackageElementTypeId)
										}
									/>
								</span>
							)}
							<span>
								{certification.reportElementTypeName}
								{certification.isRequired &&
									String.equalCaseInsensitive(reportStatus, ReportPackageStatus.Draft) && (
										<span className="ai-required">{` (${localizationService.getLocalizedString(
											'ipp.reportPackage.package.packageElement.isRequired'
										)})`}</span>
									)}
							</span>
						</>
					}>
					<div className="certification-content">
						<p>{certification.reportElementTypeContent}</p>
					</div>
				</CollapsibleCard>
			))}
		</>
	);
};

export default IppPackageItemCertifications;
