import React, { useContext, useEffect } from 'react';
import { AlertMessage, AlertMessageType, alertService } from 'src/redux';
import { CloseSvg, AlertSuccessSvg, AlertWarningSvg } from 'src/components/svg';
import { ReactComponent as InlineInfo } from 'src/assets/img/inline-info.svg';
import { ReactComponent as InlineWarning } from 'src/assets/img/inline-warning.svg';
import { ReactComponent as InlineAlert } from 'src/assets/img/inline-alert.svg';
import { ReactComponent as InlineSuccess } from 'src/assets/img/inline-success.svg';
import { Translate } from 'src/components/widgets/translate/translator';
import { authoritySettingService } from 'src/services';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { translateService } from 'src/services/translate-service';
import ReactDOMServer from 'react-dom/server';

interface InlineAlertItemProps {
	message: string | JSX.Element;
	alertType: AlertMessageType;
	alertContainerId?: string;
	isMaintenanceMessage?: boolean;
	hasCloseButton?: boolean;
	handleCloseClick?: () => void;
}

export const InlineAlertItem: React.SFC<InlineAlertItemProps> = props => {
	const { language, setLanguage } = useContext(LanguageContext);
	const [message, setMessage] = React.useState('');
	let className = '';
	if (props.alertType === AlertMessageType.Success) {
		className = 'success';
	} else if (props.alertType === AlertMessageType.Info) {
		className = 'information';
	} else if (props.alertType === AlertMessageType.Warning) {
		className = 'warning';
	} else {
		className = 'error';
	}

	const alertIconSVG = (messageType: AlertMessageType) => {
		if (messageType === AlertMessageType.Success) {
			return <InlineSuccess />;
		} else if (messageType === AlertMessageType.Info) {
			return <InlineInfo />;
		} else if (messageType === AlertMessageType.Warning) {
			return <InlineWarning />;
		} else {
			return <InlineAlert />;
		}
	};

	useEffect(() => {
		translateMessage();
	}, [language, message]);

	const translateMessage = async () => {
		let message =
			typeof props.message === 'string'
				? props.message
				: ReactDOMServer.renderToString(props.message as JSX.Element);
		translateService.translateHtmlAndSetMessage(message, setMessage, language);
	};

	return !String.isUndefinedOrEmpty(message) ? (
		<div
			id={props.alertContainerId ? props.alertContainerId : ''}
			className={'ai-inline-alert alert-' + className}
			role="alert">
			<div className="ai-alert-icon">{alertIconSVG(props.alertType)}</div>
			<span className="ai-inline-alert-message" dangerouslySetInnerHTML={{ __html: message }}></span>
			{props.hasCloseButton && (
				<div
					className="ai-alert-icon cursor-pointer"
					onClick={e => props.handleCloseClick && props.handleCloseClick()}>
					<CloseSvg />
				</div>
			)}
		</div>
	) : (
		<></>
	);
};
