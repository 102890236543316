import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import _ from 'lodash';
import { apiService, urlService, Resource, localizationService } from 'src/services';
import { alertService } from '../alert/alert-service';
import { FacilityLink, LinkType, MaxInteger } from '@rcp/types';

export interface FacilityLinkState {
	facilityLinks: FacilityLink[];
}

export const initialFacilityLinksState: FacilityLinkState = {
	facilityLinks: []
};

enum ActionType {
	LoadFacilityLinkRequest = 'loadFacilityLinkRequest',
	LoadFacilityLinkSuccess = 'loadFacilityLinkSuccess',
	SaveFacilityLinkRequest = 'saveFacilityLinkRequest',
	SaveFacilityLinkSuccess = 'saveFacilityLinkSuccess',
	DeleteFacilityLinkRequest = 'deleteFacilityLinkRequest',
	DeleteFacilityLinkSuccess = 'deleteFacilityLinkSuccess'
}

export type FacilityLinksThunkAction = ThunkAction<any, FacilityLinkState, any, Action>;

export const defaultNumberOfLinks = 6;
export const loadFacilityLinks = (
	facilityId: number,
	doesGetAll: boolean,
	linkType?: LinkType,
	ownerId?: number,
	callback?: (links: any[]) => void
): FacilityLinksThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.LoadFacilityLinkRequest });

	let number = doesGetAll === true ? MaxInteger : defaultNumberOfLinks;
	let url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${facilityId}/Links`;

	if (linkType && ownerId) {
		url = `${url}?linkType=${linkType}&ownerId=${ownerId}&size=${number}`;
	}
	let facilityLinks = await apiService.getPaginatedResources<FacilityLink>(url);
	if (callback) {
		callback(facilityLinks.result);
	}
	dispatch({
		type: ActionType.LoadFacilityLinkSuccess,
		facilityLinks: facilityLinks.result
	});
};

export const saveFacilityLinks = (
	facilityId: number,
	link: FacilityLink,
	succeededAlertMessage?: string,
	callback?: Function
): FacilityLinksThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.SaveFacilityLinkRequest });

	let updatedFacilityLink;
	let url: string;
	if (link.linkOwnershipId) {
		url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${facilityId}/Links/${
			link.linkOwnershipId
		}`;
		updatedFacilityLink = await apiService.patchResource<FacilityLink>(url, link);
		alertService.addSuccess(localizationService.getLocalizedString('facility.link.linkUpdateSuccessMessage'));
	} else {
		url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.FogFacilities}/${facilityId}/Links`;
		updatedFacilityLink = await apiService.postResource<FacilityLink>(url, link);
		alertService.addSuccess(localizationService.getLocalizedString('facility.link.linkAddedSuccessMessage'));
	}
	if (callback) {
		callback();
	}
	dispatch({ type: ActionType.SaveFacilityLinkSuccess, facilityLink: updatedFacilityLink });
};

export const canDeleteLink = async (facilityId: number, linkOwnershipId: number): Promise<boolean> => {
	const url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.FogFacilities
	}/${facilityId}/Links/${linkOwnershipId}/candelete`;

	let isValid = await apiService.httpGet(url);
	return isValid;
};

export const deleteFacilityLinks = (
	facilityId: number,
	linkOwnershipId: number,
	succeededAlertMessage?: string,
	callbackOnSuccess?: () => void
): FacilityLinksThunkAction => async (dispatch: any) => {
	dispatch({ type: ActionType.DeleteFacilityLinkRequest });

	const url = `${urlService.getApiBaseUrlWithProgram()}/${
		Resource.FogFacilities
	}/${facilityId}/Links/${linkOwnershipId}`;

	await apiService.deleteResource(url);
	dispatch({ type: ActionType.DeleteFacilityLinkSuccess });
	alertService.addSuccess(localizationService.getLocalizedString('facility.link.deleteSuccessMessage'));

	if (callbackOnSuccess) {
		callbackOnSuccess();
	}
};

export const facilityLinksReducer = (state = initialFacilityLinksState, action: any): FacilityLinkState => {
	switch (action.type) {
		case ActionType.LoadFacilityLinkRequest:
			return { ...state, facilityLinks: [] };
		case ActionType.LoadFacilityLinkSuccess:
			return {
				...state,
				facilityLinks: action.facilityLinks
			};
		case ActionType.SaveFacilityLinkRequest:
			return { ...state };
		case ActionType.SaveFacilityLinkSuccess:
			let savedFacilityLinks = action.facilityLinks;
			let facilityLinks = _.cloneDeep(state.facilityLinks);
			let index = facilityLinks.findIndex(item => item.linkOwnershipId === savedFacilityLinks.linkOwnershipId);
			if (index !== -1) {
				facilityLinks[index] = savedFacilityLinks;
			}
			return { ...state, facilityLinks: facilityLinks };
		case ActionType.DeleteFacilityLinkRequest:
		case ActionType.DeleteFacilityLinkSuccess:
		default:
			return state;
	}
};
