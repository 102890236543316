import React from 'react';

interface Props {
	size?: string | number;
	color?: string;
	className?: string;
}

export const DownloadSvg: React.FC<Props> = props => {
	const _size = props.size ? props.size : 16;
	const _color = props.color ? props.color : '#666';
	const _className = props.className ? props.className : '';
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={_size}
			height={_size}
			viewBox={`0 0 18 18`}
			className={_className}>
			<g id="Group_253" data-name="Group 253">
				<path
					id="Path_382"
					data-name="Path 382"
					d="M16,5.4v9A1.56,1.56,0,0,1,14.48,16H1.56A1.56,1.56,0,0,1,0,14.44H0v-9A1.56,1.56,0,0,1,1.56,3.88H3.5V5.22H1.56a.18.18,0,0,0-.18.18v9a.18.18,0,0,0,.18.18H14.44a.18.18,0,0,0,.18-.18h0v-9a.18.18,0,0,0-.18-.18H12.5V3.84h1.94A1.56,1.56,0,0,1,16,5.4Z"
					fill={_color}
				/>
			</g>
			<path
				id="Rectangle_271"
				data-name="Rectangle 271"
				d="M7.08,9.11V.89A.89.89,0,0,1,8,0H8a.89.89,0,0,1,.89.89V9.11A.89.89,0,0,1,8,10H8A.89.89,0,0,1,7.08,9.11Z"
				fill={_color}
			/>
			<g id="Group_256" data-name="Group 256">
				<path
					id="Rectangle_272"
					data-name="Rectangle 272"
					d="M7.36,11.19l-3-3A.89.89,0,0,1,4.38,7h0A.89.89,0,0,1,5.64,7l3,3a.89.89,0,0,1,0,1.26h0A.89.89,0,0,1,7.36,11.19Z"
					fill={_color}
				/>
				<path
					id="Rectangle_273"
					data-name="Rectangle 273"
					d="M7.38,9.93l3-3a.91.91,0,0,1,1.26,0h0a.89.89,0,0,1,0,1.26l-3,3a.91.91,0,0,1-1.26,0h0A.89.89,0,0,1,7.38,9.93Z"
					fill={_color}
				/>
			</g>
		</svg>
	);
};
