import React, { FC } from 'react';
import {
	AuthoritySettingUrlResolver,
	ListSetting,
	SettingAction,
	TagSetting,
	TagsSetting
} from '../../../components/widgets';
import { localizationService, Logger } from 'src/services';
import _ from 'lodash';
import { DataImport, LookupType, FogFacility, SubLookupType, SettingType, CleaningEvent, Extractor } from '@rcp/types';
import { LookupSetting } from 'src/components/widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import FrequencySettingsComponent from 'src/components/authority/shared/settings/frequencies/frequency-settings';
import UnitSettingsComponent from 'src/components/authority/shared/settings/units/unit-settings';
import InspectionTypeSettingComponent from 'src/components/authority/fog/settings/inspection/inspection-type.component';
import { nameof } from 'ts-simple-nameof';
import { SettingRowValueConverter } from 'src/components/widgets/lookup/data-converters';
import { WasteTypeLookRowDataConverter } from 'src/components/widgets/lookup/data-converters/waste-type-data-converters';
import EventsSettingComponent from 'src/components/authority/shared/settings/events/events-setting';

interface Props {
	fieldMapping: DataImport.ImportFieldMapping;
	lookupLabel: string;
	close: () => void;
}

export const FieldMappingSideLookup: FC<Props> = props => {
	const getSettingLookup = (fieldMapping: DataImport.ImportFieldMapping): JSX.Element => {
		const fieldName = fieldMapping.fieldName as string;
		if (_.toLower(fieldName) === _.toLower(LookupType.ReceivingPlant)) {
			return (
				<LookupSetting
					title={localizationService.getLocalizedString('authoritySetting.receivingPlant.sectionText')}
					settingId="receivingPlant"
					settingLabel={localizationService.getLocalizedString('authoritySetting.receivingPlant.editorLabel')}
					lookupType={LookupType.ReceivingPlant}
					embeddedMode={true}
				/>
			);
		}
		if (_.toLower(fieldName) === _.toLower(LookupType.MapCategory)) {
			return (
				<LookupSetting
					title={localizationService.getLocalizedString('authoritySetting.mapCategory.sectionText')}
					settingId="mapCategory"
					settingLabel={localizationService.getLocalizedString('authoritySetting.mapCategory.editorLabel')}
					lookupType={LookupType.MapCategory}
					embeddedMode={true}
				/>
			);
		}
		if (_.toLower(fieldName) === _.toLower(nameof<FogFacility>(f => f.tagClassification1))) {
			return (
				<TagSetting
					title={localizationService.getLocalizedString('authoritySetting.classification1Section')}
					description={localizationService.getLocalizedString(
						'authoritySetting.classification1SectionDescription'
					)}
					settingId="classification1"
					settingLabel={localizationService.getLocalizedString('authoritySetting.classification1')}
					tagType={SubLookupType.Classification1}
					embeddedMode={true}
				/>
			);
		}
		if (_.toLower(fieldName) === _.toLower(nameof<FogFacility>(f => f.tagClassification2))) {
			return (
				<TagSetting
					title={localizationService.getLocalizedString('authoritySetting.classification2Section')}
					description={localizationService.getLocalizedString(
						'authoritySetting.classification2SectionDescription'
					)}
					settingId="classification2"
					settingLabel={localizationService.getLocalizedString('authoritySetting.classification2')}
					tagType={SubLookupType.Classification2}
					embeddedMode={true}
				/>
			);
		}
		if (_.toLower(fieldName) === _.toLower(nameof<FogFacility>(f => f.tags))) {
			return <TagsSetting embeddedMode={true} />;
		}
		if (_.toLower(fieldName) === _.toLower(nameof<FogFacility>(f => f.complianceStatus))) {
			return (
				<ListSetting
					title={localizationService.getLocalizedString('authoritySetting.complianceStatusSection')}
					description={localizationService.getLocalizedString(
						'authoritySetting.complianceStatusSectionDescription'
					)}
					settingId="complianceStatus"
					settingLabel={localizationService.getLocalizedString('authoritySetting.complianceStatus')}
					hideActiveStatus={true}
					settingAction={
						new SettingAction(
							LookupType.ComplianceStatus,
							new AuthoritySettingUrlResolver(LookupType.ComplianceStatus),
							new SettingRowValueConverter(SettingType.ComplianceStatusValues),
							true
						)
					}
					embeddedMode={true}
				/>
			);
		}
		if (_.toLower(fieldName) === _.toLower('FacilityContactType')) {
			return (
				<LookupSetting
					title={localizationService.getLocalizedString('authoritySetting.contactTypesSection')}
					description={localizationService.getLocalizedString(
						'authoritySetting.contactTypesSectionDescription'
					)}
					settingId="LookupType.ContactType"
					settingLabel={localizationService.getLocalizedString('authoritySetting.contactType')}
					lookupType={LookupType.ContactType}
					embeddedMode={true}
				/>
			);
		}

		if (_.toLower(fieldName) === _.toLower('InspectionEventType')) {
			return <InspectionTypeSettingComponent embeddedMode={true}></InspectionTypeSettingComponent>;
		}

		if (_.toLower(fieldName) === _.toLower('EventTypeName')) {
			return <EventsSettingComponent embeddedMode={true}></EventsSettingComponent>;
		}
		if (_.toLower(fieldName) === _.toLower('InspectionFrequencyCode')) {
			return <FrequencySettingsComponent embeddedMode={true} />;
		}

		if (_.toLower(fieldName) === _.toLower('CleanFrequencyCode')) {
			return <FrequencySettingsComponent embeddedMode={true} />;
		}
		if (_.toLower(fieldName) === _.toLower('ExtractorType')) {
			return (
				<ListSetting
					title={localizationService.getLocalizedString('authoritySetting.deviceTypeSection')}
					description={localizationService.getLocalizedString(
						'authoritySetting.deviceTypeSectionDescription'
					)}
					settingId="extractorType"
					settingLabel={localizationService.getLocalizedString('authoritySetting.deviceTypeLabel')}
					settingAction={new SettingAction(LookupType.ExtractorTypes)}
					embeddedMode={true}
				/>
			);
		}
		if (
			_.toLower(fieldName) === _.toLower(nameof<Extractor>(e => e.trapDepthUnitCode)) ||
			_.toLower(fieldName) === _.toLower(nameof<Extractor>(e => e.trapCapacityUnitCode)) ||
			_.toLower(fieldName) === _.toLower(nameof<CleaningEvent>(c => c.amountPumpedUnit)) ||
			_.toLower(fieldName) === _.toLower(nameof<CleaningEvent>(c => c.trapDepthUnit)) ||
			_.toLower(fieldName) === _.toLower(nameof<CleaningEvent>(c => c.fogUnit)) ||
			_.toLower(fieldName) === _.toLower(nameof<CleaningEvent>(c => c.solidUnit))
		) {
			return <UnitSettingsComponent embeddedMode={true} />;
		}
		if (_.toLower(fieldName) === _.toLower(nameof<CleaningEvent>(c => c.wasteTypeCode))) {
			return (
				<ListSetting
					title={localizationService.getLocalizedString('authoritySetting.wasteType')}
					settingId="wasteType"
					description={localizationService.getLocalizedString('authoritySetting.wasteTypeDescription')}
					settingLabel={localizationService.getLocalizedString('authoritySetting.wasteType')}
					editDescription={true}
					hideActiveStatus={true}
					settingAction={
						new SettingAction(
							LookupType.WasteType,
							undefined,
							new WasteTypeLookRowDataConverter(LookupType.WasteType)
						)
					}
					embeddedMode={true}
				/>
			);
		}

		Logger.error(`Add lookup for field ${fieldName} is unsupported.`);
		return <></>;
	};

	return (
		<div className="side pl-0 pl-2 mt-2">
			<div>
				<FontAwesomeIcon
					icon={faChevronLeft}
					className="preview-email-chevron font-size-18px"
					onClick={props.close}
				/>
			</div>
			<h2 className="side-title my-4">
				{localizationService.getLocalizedString('screen.buttons.add')}&nbsp;
				{_.toLower(props.lookupLabel)}
			</h2>
			<p>{localizationService.getLocalizedString('import.steps.addLookupDescription', props.lookupLabel)}</p>
			{props.fieldMapping.fieldName && getSettingLookup(props.fieldMapping)}
		</div>
	);
};
