import _ from 'lodash';
import React, { Fragment } from 'react';
import { useRootStateSelector } from 'src/redux';
import { localizationService, Resource, urlService } from 'src/services';
import { ContactCardBody, ContactModal } from 'src/features';
import { SiteDisplayContact } from './site-contacts-tab';

interface OwnProps {
	contact: SiteDisplayContact;
	reload: () => void;
}

type Props = OwnProps;
export const SiteContactCard: React.FC<Props> = props => {
	const getReceivingNoticesHazardList = (list: any) => {
		const items = [];
		for (let i = 0; i < list.length; i++) {
			i > 0 && items.push(<Fragment key={`space-${i}`}>,&nbsp;</Fragment>);

			items.push(
				<Fragment key={`site-contact-${i}`}>
					<a href={urlService.getReactAuthorityResourceUrl(Resource.CccHazards, list[i].hazardId)}>
						{list[i].hazardNumber}
					</a>
				</Fragment>
			);
		}
		return items;
	};

	const getHazardsDescription = (displayContact: SiteDisplayContact) => {
		if (displayContact.hazardContacts.length > 0) {
			let receiveNoticesHazardsList = displayContact.hazardContacts.filter((i: any) => i.sendNotices === true);
			if (receiveNoticesHazardsList.length > 0) {
				let hazards = getReceivingNoticesHazardList(receiveNoticesHazardsList);
				return (
					<p id="hazardList">
						{localizationService.getLocalizedString(
							'cccSite.receivingNotices',
							_.toString(receiveNoticesHazardsList.length),
							_.toString(displayContact.hazardContacts.length)
						)}
						&#58;&nbsp;
						{hazards}
					</p>
				);
			} else {
				return (
					<p id="hazardList">
						{localizationService.getLocalizedString(
							'cccSite.receivingNotices',
							'0',
							_.toString(displayContact.hazardContacts.length)
						)}
						&#46;
					</p>
				);
			}
		}
		return <></>;
	};

	const cccSiteContacts = useRootStateSelector(s => s.siteContacts.result);
	const [isToggleEditContactModal, setToggleEditContactModal] = React.useState(false);

	const resetModalStateAndReload = () => {
		setToggleEditContactModal(false);
		props.reload();
	};

	return (
		<div className="tab-col mb-5" id={'contact_card_' + props.contact.contactId}>
			<div className="mb-3">
				<a
					href="#/"
					id={'contact_card_type_' + props.contact.contactId}
					onClick={(e: any) => {
						e.preventDefault();
						setToggleEditContactModal(true);
					}}>
					{props.contact.contactType}
				</a>
				&nbsp;&nbsp;
				{props.contact.siteOnlyContact && (
					<span className="text-muted">{localizationService.getLocalizedString('cccSite.site')} </span>
				)}
				{props.contact.isPrimary && (
					<span className="text-muted">{localizationService.getLocalizedString('cccSite.primary')} </span>
				)}
			</div>

			<ContactCardBody contact={props.contact} />

			{getHazardsDescription(props.contact)}

			{isToggleEditContactModal && (
				<ContactModal
					linkedContact={props.contact}
					associatedContacts={cccSiteContacts}
					close={() => {
						setToggleEditContactModal(false);
					}}
					reload={resetModalStateAndReload}
				/>
			)}
		</div>
	);
};
