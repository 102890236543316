import React from 'react';
import { useHistory } from 'react-router-dom';
import * as ApiTypes from '@rcp/types';
import { InspectionEventModal } from '../inspection-event-modal';
import { getDateContent } from './timeline-helper';
import { urlService, localizationService, apiService, navigateTo, localStorageService } from 'src/services';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DeleteModal } from 'src/components/widgets';
import { alertService } from 'src/redux';

interface OwnProps {
	event: ApiTypes.InspectionEvent;
	index: number;
	todayNow: string;
	save: (inspection: ApiTypes.InspectionEvent) => Promise<any>;
	userProfile?: any;
	loadFacilityInspections: Function;
	loadCurrentFogFacility: Function;
	loadAuthorityInspections: Function;
}
type Props = OwnProps;

export const TimelineEventInspection: React.SFC<Props> = props => {
	const [showInspectionModal, setShowInspectionModal] = React.useState(false);
	const [toggleDeleteInspectionModal, setToggleDeleteInspectionModal] = React.useState(false);

	const history = useHistory();
	const onShowInspectionDetailsLinkClicked = (e: any) => {
		e.preventDefault();
		if (props.event.completeDate) {
			const detailsUrl = getInspectionDetailUrl();
			const facilityPath = urlService.getCurrentFacilityDetailsUrl();
			localStorageService.setLocalStorage(ApiTypes.LocalStorageName.InspectionDetailRedictFromPath, facilityPath);
			navigateTo(history, detailsUrl, e, facilityPath);
		}
	};

	const toggleInspectionModal = () => {
		setShowInspectionModal(!showInspectionModal);
	};

	const getInspectionDetailUrl = () => {
		let inspectionPath: string | undefined = undefined;
		if (props.event.inspectionEventId) {
			let fogFacilityId = props.event.organizationId;
			if (!fogFacilityId) {
				throw new Error('Invalid Inspection');
			}
			inspectionPath = urlService.getFacilityInspectionDetailsUrl(fogFacilityId, props.event.inspectionEventId);
		}
		return inspectionPath;
	};

	const deleteInspection = () => {
		//close confirmation popup
		setToggleDeleteInspectionModal(false);
		if (props.event.inspectionEventId) {
			let facilityId = props.event.organizationId;
			if (!facilityId) {
				throw new Error('Invalid organizationId on inspection');
			}
			const inspectionsUrl = urlService.getFacilityResourceApiUrl(facilityId, 'InspectionEvents');
			const inspectionUrl = `${inspectionsUrl}/${props.event.inspectionEventId}`;
			apiService
				.deleteResource(inspectionUrl)
				.then(() => {
					alertService.addSuccess(
						localizationService.getLocalizedString('alertMessages.removeSuccess', 'Inspection')
					);
					if (urlService.getFogFacilityId() > 0) {
						props.loadFacilityInspections();
						props.loadCurrentFogFacility();
					} else {
						props.loadAuthorityInspections();
					}
				})
				.catch(e => {
					alertService.addError(e.message);
				});
			//close inspection modal
		}
	};

	const navigateToInspectionDetails = (inspectionEventId: number | undefined) => {
		if (inspectionEventId) {
			const facilityPath = urlService.getCurrentFacilityDetailsUrl();
			const inspectionPath = facilityPath + '/inspections/' + inspectionEventId;
			localStorageService.setLocalStorage(ApiTypes.LocalStorageName.InspectionDetailRedictFromPath, facilityPath);
			navigateTo(history, inspectionPath, null, facilityPath);
		}
	};

	return (
		<>
			<UncontrolledDropdown className="action-item-black inspection-dropdown" direction="right">
				<DropdownToggle
					tag="a"
					href="#/"
					className="pr-0 pb-2 pl-0 fit-inspection-link"
					onClick={onShowInspectionDetailsLinkClicked}
					id={`linkFacilityInspection-${props.index}`}>
					{props.event.inspectionType}
				</DropdownToggle>
				<DropdownMenu className="drop-down-position" menuPortalTarget={document.body}>
					<DropdownItem
						id="newInspectionOption"
						tag="a"
						onClick={() => {
							navigateToInspectionDetails(props.event.inspectionEventId);
						}}>
						{localizationService.getLocalizedString('inspection.startInspection')}
					</DropdownItem>
					<DropdownItem
						id="editInspection"
						tag="a"
						onClick={() => {
							setShowInspectionModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</DropdownItem>
					<DropdownItem
						id="deleteInspection"
						tag="a"
						onClick={() => {
							setToggleDeleteInspectionModal(true);
						}}>
						{localizationService.getLocalizedString('screen.buttons.delete')}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
			<span>{getDateContent(props.todayNow, 'inspection', props.event.completeDate, props.event.dueDate)}</span>
			{showInspectionModal && (
				<InspectionEventModal
					isToggle={showInspectionModal}
					toggle={toggleInspectionModal}
					inspection={props.event}
					save={props.save}
				/>
			)}

			{toggleDeleteInspectionModal && (
				<DeleteModal
					key="confirmDeleteInspectionModal"
					title={localizationService.getLocalizedString('inspection.deleteModalTitle')}
					message={localizationService.getLocalizedString('inspection.deleteModalMessage')}
					showModal={toggleDeleteInspectionModal}
					onCancelButtonClick={() => {
						setToggleDeleteInspectionModal(false);
					}}
					onOkayButtonClick={deleteInspection}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</>
	);
};
