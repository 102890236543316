import React from 'react';
import { DateUtilService } from 'src/services';

export const RenderXTick = (tickProps: any) => {
	const { x, y, payload, setting } = tickProps;

	let xLabel = payload.value;
	if (setting.dateFilter && /^\d{4}$/.test(setting.dateFilter)) {
		xLabel = DateUtilService.getMomentInAuthorityTimeZone(xLabel).format('MMM');
	}
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={12} textAnchor="end" fill="#666" transform="rotate(-35)" className="bar-tick-text">
				{xLabel || payload.value}
			</text>
		</g>
	);
};
