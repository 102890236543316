import {
	AddNoticeSteps,
	MergeFieldDto,
	NoticeTemplateType,
	PreviewNotice,
	SettingType,
	SettingValue
} from '@rcp/types';
import _ from 'lodash';
import React, { FC, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import PreviewEmailNoticeModal from 'src/components/widgets/preview-email-notice';
import { useRootStateSelector } from 'src/redux';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import { cleaningNoticeTemplateSlice } from '../../cleaning-notice-template.slice';
import { EmailServerType } from 'src/components/authority/shared/settings/authority/authority';
import { mergeFieldSlice } from 'src/components/authority/shared/settings/notices/merge_field.slice';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { doNotTranslateTags } from 'src/constants';

export const Step5: FC = () => {
	const fogNoticeSettingPath = '/fog/settings/notices';
	let history = useHistory();
	const dispatch = useDispatch();
	const stepperContext = useContext(StepperContext);
	const [logoS3ResourcePath, setLogoS3ResourcePath] = useState();
	const [showModal, setShowModal] = useState(false);
	const [showDiscardModal, setShowDiscardModal] = useState(false);
	const [emailDetail, setEmailDetail] = useState<PreviewNotice>({
		to: 'facility@email.com',
		from: '',
		cc: 'assignedto@email.com',
		templateSubject: '',
		templateContent: ''
	});
	const { STEP1, STEP2, STEP3, STEP4, STEP5 } = AddNoticeSteps;

	const mergeFields: MergeFieldDto[] = useRootStateSelector(s => s.mergeFields.result);
	let cleaningNoticeTemplate = useRootStateSelector(s => s.cleaningNoticeTemplate.selected);

	const onToggleModal = () => {
		setShowModal(!showModal);
	};

	useEffect(() => {
		if (cleaningNoticeTemplate) {
			const updatedStep5 = {
				...stepperContext.getStep(STEP5),
				completed: true,
				data: { resolved: true }
			};
			stepperContext.updateStep(STEP5, updatedStep5);
		}
		const step5Data = stepperContext.getData(STEP5);
		if (!step5Data && cleaningNoticeTemplate) {
			stepperContext.goAt(STEP1);
		}
	}, [cleaningNoticeTemplate]);

	useEffect(() => {
		mergeFieldSlice.setApiUrlPath(
			Resource.SettingNoticeTemplatesMergeFields + '/' + NoticeTemplateType.FogCleaningNotice
		);
		dispatch(mergeFieldSlice.fetchAll());
		fetchLogo();
	}, []);

	const fetchStepperData = () => {
		const step1Data = stepperContext.getData(STEP1);
		const step2Data = stepperContext.getData(STEP2);
		const step3Data = stepperContext.getData(STEP3)
			? _.pick(stepperContext.getData(STEP3).noticeContent, [
					'templateSubject',
					'templateContent',
					'letterTemplateTranslationsJson'
			  ])
			: {};
		const step4Data = stepperContext.getData(STEP4)
			? {
					...stepperContext.getData(STEP4).deliveryOptions
			  }
			: {};
		delete step4Data.violation;
		return { ...step1Data, ...step2Data, ...step3Data, ...step4Data };
	};

	const fetchLogo = async () => {
		const url = `${urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings)}/Logo`;
		apiService.getResource(url).then((response: any) => {
			setLogoS3ResourcePath(response.logoS3ResourcePath);
		});
	};

	const previewEmail = async (e: any) => {
		const { EmailServerSenderDefaultEmailAddress, SystemEmailServerSenderDefaultEmailAddress } = SettingType;
		e.preventDefault();
		let payload = { ...cleaningNoticeTemplate, ...fetchStepperData() };

		let templateContent: string = payload.templateContent;
		let templateSubject: string = payload.templateSubject;
		const logoImg = `<img id="imageAuthorityLogo" src=${logoS3ResourcePath} alt="Authority Logo" className="img-fluid" height="50" />`;
		let mergeFieldRegex = /[^{{]+(?=}\})/g;
		let curMatch;
		let mergedFieldsWithFilter: string[] = [];
		while ((curMatch = mergeFieldRegex.exec(templateContent))) {
			if (_.includes(curMatch[0], '|')) {
				mergedFieldsWithFilter.push(curMatch[0]);
			}
		}
		_.forEach(mergedFieldsWithFilter, f => {
			templateContent = templateContent.replaceAll(`{{${f}}}`, '[Date calculated at time of sending]');
		});
		mergeFields.map(mergeField => {
			let fieldToReplace = `{{${mergeField.prefix} - ${mergeField.label}}}`;
			if (_.toLower(fieldToReplace).includes('logo')) {
				templateContent = templateContent.replaceAll(fieldToReplace, logoImg);
			}
			templateContent = templateContent.replaceAll(fieldToReplace, mergeField.placeholderData || '');
			templateSubject = templateSubject.replaceAll(fieldToReplace, mergeField.placeholderData || '');
		});

		templateContent = templateContent.replaceAll(doNotTranslateTags.start, "<span class='notranslate'>");
		templateContent = templateContent.replaceAll(doNotTranslateTags.end, '</span>');
		templateSubject = templateSubject.replaceAll(doNotTranslateTags.start, '');
		templateSubject = templateSubject.replaceAll(doNotTranslateTags.end, '');

		let fromEmail = '';
		const authoritySettingsUrl = urlService.getAuthoritySettingResourceApiUrl(Resource.AuthoritySettings);
		apiService.getResource<SettingValue[]>(authoritySettingsUrl).then((settings: SettingValue[]) => {
			const emailServerType = settings.find(setting => {
				return String.equalCaseInsensitive(setting.settingType, SettingType.EmailServerType);
			})!.value;
			fromEmail = settings.find(setting => {
				return String.equalCaseInsensitive(
					setting.settingType,
					String.equalCaseInsensitive(emailServerType, EmailServerType.Custom)
						? EmailServerSenderDefaultEmailAddress
						: SystemEmailServerSenderDefaultEmailAddress
				);
			})!.value;
			setEmailDetail({
				...emailDetail,
				templateContent,
				cc: payload.shouldSendToFacilityAssignedToUser ? emailDetail.cc : '',
				from: fromEmail,
				templateSubject
			});

			setShowModal(true);
		});
	};

	const onClickBack = () => {
		stepperContext.goAt(STEP4);
	};

	const saveDetail = (e: any) => {
		e.preventDefault();
		let payload = { ...cleaningNoticeTemplate, ...fetchStepperData() };
		if (cleaningNoticeTemplate && cleaningNoticeTemplate.noticeTemplateScheduleId) {
			payload.isActive = cleaningNoticeTemplate.isActive;
			dispatch(
				cleaningNoticeTemplateSlice.patchOne(
					cleaningNoticeTemplate!.noticeTemplateScheduleId,
					payload,
					false,
					localizationService.getLocalizedString('authoritySetting.noticesSettings.updateSuccess'),
					() => navigateTo(history, fogNoticeSettingPath)
				)
			);
		} else {
			payload.isActive = true;
			dispatch(
				cleaningNoticeTemplateSlice.createOne(
					payload,
					false,
					localizationService.getLocalizedString('authoritySetting.noticesSettings.createSuccess'),
					() => navigateTo(history, fogNoticeSettingPath)
				)
			);
		}
	};

	let discardModalProps: CustomModalProp = {
		showModal: showDiscardModal,
		onOkayButtonClick: () => navigateTo(history, '/fog/settings/notices'),
		onCancelButtonClick: () => setShowDiscardModal(!showDiscardModal),
		message: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardMessage'),
		title: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardTitle'),
		okayButtonText: localizationService.getLocalizedString('authoritySetting.noticesSettings.discard')
	};

	return (
		<>
			<StepperContent
				id="preview-or-save"
				className="ml-4"
				actions={
					<React.Fragment>
						<StepperAction type="button" id="btnBack" className="btn btn-link" onClick={onClickBack}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
						</StepperAction>
						<StepperAction
							type="button"
							id="btnDiscard"
							className="btn btn-link"
							onClick={() => setShowDiscardModal(!showDiscardModal)}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.discard')}
						</StepperAction>
					</React.Fragment>
				}>
				<button
					className="btn ai-secondary mr-2"
					onClick={e => {
						previewEmail(e);
					}}>
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.preview')}
				</button>
				<button
					className="btn ai-save"
					onClick={event => {
						event.preventDefault();
						saveDetail(event);
					}}>
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.save')}
				</button>
			</StepperContent>
			<PreviewEmailNoticeModal
				showModal={showModal}
				onToggle={onToggleModal}
				emailDetails={[emailDetail]}
				hideFooter={true}
			/>
			<CustomModal {...discardModalProps} />
		</>
	);
};
