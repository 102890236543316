import { IppReportPackage } from '@rcp/types';
import React, { FC } from 'react';
import { TextInput, TextAreaInput } from 'src/components/widgets';
import CollapsibleCard from 'src/components/widgets/collapsible-card/collapsible-card';
import { DateUtilService, localizationService } from 'src/services';

interface Props {
	packageDetails?: IppReportPackage;
	className?: string;
}

const IppPackageRejection: FC<Props> = ({ packageDetails, className }) => {
	return (
		<>
			<CollapsibleCard
				accordionType="report-package-rejected-accordion"
				accordionHeading={localizationService.getLocalizedString(`ipp.reportPackage.package.rejection`)}
				className={className ? className : ''}>
				<div id="rejection-details" className="form-row">
					<TextInput
						id="rejected-by"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.package.rejectedReport.rejectedBy'
						)}
						className="col-sm-6"
						name="rejectedBy"
						value={`${packageDetails!.rejectorFirstName || ''} ${packageDetails!.rejectorLastName || ''}`}
						isDisabled={true}
					/>
					<TextInput
						id="rejected-date"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.package.rejectedReport.rejectedDate'
						)}
						className="col-sm-6"
						name="rejectedDate"
						value={
							DateUtilService.toDisplayDate(packageDetails!.rejectionDateTimeLocal) +
							' ' +
							DateUtilService.toDisplayTime(packageDetails!.rejectionDateTimeLocal)
						}
						isDisabled={true}
					/>
				</div>
				<div className="form-row">
					<TextAreaInput
						id="reason"
						name="reason"
						label={localizationService.getLocalizedString(
							'ipp.reportPackage.package.rejectedReport.reason'
						)}
						className="col-sm-6"
						rows={3}
						value={packageDetails && packageDetails.acceptRejectReasons}
						isDisabled={true}
					/>
				</div>
			</CollapsibleCard>
		</>
	);
};

export default IppPackageRejection;
