import React from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import PreviewModal from 'src/components/widgets/preview-email-notice';
import { ColumnField, DeviceCleaningNoticeSteps, Dictionary, FilterType } from '@rcp/types';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { apiService, localizationService, Resource, urlService, navigateTo, UtilService } from 'src/services';
import { DataGrid, GridOption } from 'src/features';
import { useHistory } from 'react-router';
import { recipientSlice } from './recipient-slice';
import { LocalStorageName, RouteProps } from '@rcp/types';
import { alertService, RootState } from 'src/redux';
import { useSelector } from 'react-redux';
import { Notification } from 'src/components/widgets/inline-notification';
import './step4.scss';
import _ from 'lodash';
import { PopoverModal } from 'src/components/widgets';

interface Props extends RouteProps {}

export const Step4: React.FC<Props> = props => {
	const stepperContext = React.useContext(StepperContext);
	const [showModal, setShowModal] = React.useState(false);
	const [showPreviewModal, setShowPreviewModal] = React.useState(false);
	const [numberOfEmailContact, setNumberOfEmailContact] = React.useState(0);
	const [emailDetails, setEmailDetails] = React.useState([] as any[]);
	const [queryParameters, setQueryParameters] = React.useState<Dictionary<string>>({});

	const pageLoading = useSelector((state: RootState) => state.pageLoadingState);

	const history = useHistory();
	const { STEP1, STEP2, STEP3, STEP4 } = DeviceCleaningNoticeSteps;

	const recipients = useSelector((state: RootState) => state.recipients);

	React.useEffect(() => {
		if (recipients.result.length > 0) {
			const numberOfContact = recipients.result.reduce((acc, recipient) => {
				if (!recipient.email) {
					return acc + 1;
				}
				return acc + 0;
			}, 0);
			setNumberOfEmailContact(numberOfContact);
		}
	}, [recipients]);

	React.useEffect(() => {
		let url = `${Resource.Extractors}/${urlService.getFogDeviceId() ? urlService.getFogDeviceId() + '/' : ''}${
			Resource.SendNoticeContacts
		}`;
		recipientSlice.setApiUrlPath(url);
		const updatedStep4 = {
			...stepperContext.getStep(STEP4),
			completed: true,
			data: {}
		};
		stepperContext.updateStep(STEP4, updatedStep4);
	}, []);

	const getQueryParamsForDevices = () => {
		let queryParams = _.pick(urlService.toQueryDictionary(history.location.search), [
			'search',
			'filterId',
			'draftFilterId'
		]);
		return `?${urlService.toQueryString(queryParams)}`;
	};

	const setQueryParametersFromParent = (queryParameters: Dictionary<string>) => {
		const queryParam = urlService.toQueryDictionary(history.location.search);
		setQueryParameters({ ...queryParameters, ...queryParam });
	};

	const sendNotice = () => {
		const deviceId = urlService.getFogDeviceId();
		const { noticeTemplateId, templateName } = stepperContext.getData(STEP1);
		const { shouldSendNoticeToFacilityAssignedToUser } = stepperContext.getData(STEP2);
		const {
			noticeContent: { templateContent, templateSubject, letterTemplateTranslationsJson }
		} = stepperContext.getData(STEP3);
		const notice = {
			shouldSendNoticeToFacilityAssignedToUser,
			templateContent,
			templateSubject,
			extractorId: deviceId,
			templateName,
			noticeTemplateScheduleId: noticeTemplateId,
			letterTemplateTranslationsJson: letterTemplateTranslationsJson
		};
		const url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Extractors}/${deviceId ? deviceId + '/' : ''}${
				Resource.SendNotices
			}${getQueryParamsForDevices()}`
		);
		apiService
			.postResourceObject(url, notice)
			.then(res => {
				if (recipients) {
					alertService.addSuccess(
						localizationService.getLocalizedString(`facility.sendCleaningNoticeSuccess`)
					);
				}
				navigateToDeviceGridOrDetails();
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const previewNotice = (rowData: any) => {
		const contactId = rowData.contactId;
		const deviceId = urlService.getFogDeviceId() || rowData.extractorId;
		const { templateName, noticeTemplateId } = stepperContext.getData(STEP1);
		const { shouldSendNoticeToFacilityAssignedToUser } = stepperContext.getData(STEP2);
		const {
			noticeContent: { templateContent, templateSubject }
		} = stepperContext.getData(STEP3);
		const notice = {
			shouldSendNoticeToFacilityAssignedToUser,
			templateContent,
			templateSubject,
			templateName,
			noticeTemplateScheduleId: noticeTemplateId,
			extractorId: deviceId
		};
		let baseUrl = `${Resource.Extractors}/${deviceId ? deviceId + '/' : ''}${Resource.PreviewNotices}`;

		const url = contactId
			? urlService.getAuthorityResourcesApiUrl(`${baseUrl}/${contactId}${getQueryParamsForDevices()}`)
			: urlService.getAuthorityResourcesApiUrl(`${baseUrl}${getQueryParamsForDevices()}`);

		apiService
			.postResourceObject(url, notice)
			.then((res: any) => {
				const recipientWithEmail = res.filter((recipient: any) => {
					return UtilService.hasValue(recipient.email) && recipient.contactTypeId === rowData.contactTypeId;
				});
				setEmailDetails(recipientWithEmail);
				onTogglePreviewModal();
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	};

	const onToggleModal = () => setShowModal(!showModal);

	const onTogglePreviewModal = () => {
		setShowPreviewModal(!showPreviewModal);
	};

	const navigateToDeviceGridOrDetails = () => {
		const url = `${urlService.getReactBaseUrl()}/${urlService.getProgramOrThrowError()}/${Resource.Devices}/${
			urlService.getFogDeviceId() ? urlService.getFogDeviceId() : ''
		}`;
		navigateTo(history, url);
	};

	const onClickBack = () => stepperContext.goAt(STEP3);

	const previewLinkCell = (props: any) => {
		const data = props.dataItem;
		return (
			<>
				{data.email && pageLoading.loadingCounter === 0 ? (
					<td>
						<a
							href="#/"
							onClick={e => {
								e && e.preventDefault();
								previewNotice(data);
							}}
							className="ai-link cursor-pointer">
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.preview')}
						</a>
					</td>
				) : (
					<td></td>
				)}
			</>
		);
	};

	const gridOptions: GridOption = {
		pageTitle: '',
		prefix: 'extractor.cleaningNotice',
		storageName: LocalStorageName.recipientNotices,
		queryParameters: { sort: 'deviceNumber asc, contactType asc, contactName asc' },
		allColumns: [
			new ColumnField('deviceNumber'),
			new ColumnField('contactType'),
			new ColumnField('contactName'),
			new ColumnField('email'),
			new ColumnField('preview', FilterType.Text, previewLinkCell)
		],
		hideCount: true,
		defaultColumns: ['deviceNumber', 'contactType', 'contactName', 'email', 'preview'],
		doNotApplyMaxTable: true
	};

	return (
		<div className="ml-4 w-100">
			<StepperContent
				actions={
					<React.Fragment>
						<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
							{localizationService.getLocalizedString('extractor.cleaningNotice.back')}
						</StepperAction>
						<StepperAction type="button" id="btn-discard" className="btn btn-link" onClick={onToggleModal}>
							{localizationService.getLocalizedString('extractor.cleaningNotice.discard')}
						</StepperAction>
					</React.Fragment>
				}>
				<p>{localizationService.getLocalizedString('extractor.cleaningNotice.previewDesc')}</p>
				{numberOfEmailContact > 0 && (
					<Notification
						message={localizationService.getLocalizedString(
							'extractor.cleaningNotice.emailWarn',
							numberOfEmailContact + ' ' + (numberOfEmailContact === 1 ? 'contact' : 'contacts')
						)}
						className="recipient-warning d-inline-block p-0"
						icon={<SvgWarning />}
						showCloseButton={false}
					/>
				)}
				<div className="recipient-grid pr-3 mt-2">
					<DataGrid
						history={history}
						restSlice={recipientSlice}
						gridOption={gridOptions}
						setQueryParametersFromParent={setQueryParametersFromParent}
						restState={(state: RootState) => state.recipients}
						match={props.match}
					/>
				</div>
				{recipients.result.length > 0 ? (
					<button
						id="send"
						className="btn ai-action"
						onClick={e => {
							e.preventDefault();
							sendNotice();
						}}>
						{localizationService.getLocalizedString('extractor.cleaningNotice.send')}
					</button>
				) : null}
			</StepperContent>
			{showModal && (
				<PopoverModal
					title={localizationService.getLocalizedString('extractor.cleaningNotice.discardTheNotice')}
					showModal={showModal}
					cancel={onToggleModal}
					saveButtonText={localizationService.getLocalizedString('extractor.cleaningNotice.discard')}
					save={navigateToDeviceGridOrDetails}
					saveButtonClassName="btn ai-action">
					<p>{localizationService.getLocalizedString('extractor.cleaningNotice.discardNoticeDesc')}</p>
				</PopoverModal>
			)}
			{showPreviewModal && (
				<PreviewModal
					showModal={showPreviewModal}
					emailDetails={emailDetails}
					onToggle={onTogglePreviewModal}></PreviewModal>
			)}
		</div>
	);
};
