import _ from 'lodash';
import * as https from 'https';
import fetch from 'node-fetch';
import queryString from 'query-string';
import appSettings from '../settings';
import { ApiError, PaginatedResult, HttpRequestItems, HttpResponseItems } from '@rcp/types';
import { tokenService } from './token-service';
import { dispatchAccessor } from './dispatch-accessor';
import moment from 'moment-timezone';
import { ApplicationState, dataLoadingDuplicateXhrGetCallTolerantNumber, PageLoadingActionType } from 'src/redux';
import { OrganizationTypes, RegulatoryProgramName } from 'src/constants';
import { IppTestConstants as Constants } from 'src/constants';
import { Logger } from '.';

const httpsAgent = new https.Agent({
	rejectUnauthorized: false
});
const defaultHeaders = {
	Accept: 'application/json',
	'Content-Type': 'application/json'
};

const SkippedXhrGetResultType = 'skippedXhrGet';
const SkippedXhrGetResult = { skipped: SkippedXhrGetResultType };
export class ApiService {
	lastError?: ApiError;
	timeoutInMs: number;
	isAnonymous: boolean;
	apiVersion?: string;

	constructor(isAnonymous = false) {
		this.timeoutInMs = !_.isUndefined(_.get(appSettings.setting, 'timeoutInMs'))
			? parseInt(_.get(appSettings.setting, 'timeoutInMs'))
			: 60000;
		this.isAnonymous = isAnonymous;
	}

	isSkippedGetResult(result: any) {
		return result[SkippedXhrGetResultType] === SkippedXhrGetResultType;
	}

	private getAccessToken(): { accessToken: string; tokenProvider?: string; correlationId?: string } {
		let token = tokenService.getTokenOrDefault();
		return {
			accessToken: token.accessToken,
			tokenProvider: token.tokenProvider,
			correlationId: token.correlationId
		};
	}

	checkVersionCompatible(response: any) {
		if (!response.headers) {
			return;
		}
		let apiVersion = response.headers.get('x-version-number');
		if (!apiVersion) {
			return;
		}
		if (!document || !document.querySelector) {
			return;
		}
		let htmlVersionMeta = document.querySelector(`meta[name="version-number"]`);
		if (!htmlVersionMeta) {
			return;
		}
		let htmlVersion = htmlVersionMeta.getAttribute('content');
		if (!htmlVersion) {
			return;
		}
		if (htmlVersion === apiVersion || apiVersion === '1.0.0000.0' || htmlVersion === '1.0.0.0') {
			return;
		}
		console.error(
			`Found version conflict!!! API version (${apiVersion}) is not the same to html version: ${htmlVersion}.`
		);
		this.reloadHtmlPage();
	}

	private reloadHtmlPage() {
		let lastReload = localStorage.getItem('lastReload');
		let lastReloadTime = lastReload ? _.toNumber(lastReload) : 0;
		let nowEpochTime = moment().valueOf();
		let reloadDeltaTimeInMilliseconds = nowEpochTime - lastReloadTime;
		const OneMinute = 60000;
		if (reloadDeltaTimeInMilliseconds > OneMinute) {
			localStorage.setItem('lastReload', `${nowEpochTime}`);
			window.location.reload();
		}
	}

	async handleError(response: any) {
		let contentType = response.headers.get('content-type');
		if (response.status === 404) {
			dispatchAccessor.dispatch({ type: 'loadNotFoundPage' });
		}
		if (!_.isEmpty(contentType) && contentType.indexOf('application/json') !== -1) {
			let jsonError = await response.json();
			let apiError = new ApiError(response.status, response.statusText, response.url, jsonError, '');
			this.lastError = apiError;
			return Promise.reject(apiError);
		}
		let text = await response.text();
		let apiError = new ApiError(response.status, response.statusText, response.url, '', text);
		this.lastError = apiError;
		return Promise.reject(apiError);
	}

	httpGet = async (
		url: string,
		showProgressBar: boolean = true,
		headers = { Accept: 'application/json' },
		maxRetries = 1
	) => {
		return this.httpGetInternal(url, showProgressBar, headers, maxRetries, false);
	};

	private passAccessToken(headers: any) {
		if (headers['Authorization']) {
			Logger.info(`Authorization header already passed.`);
			return;
		}
		let token = this.getAccessToken();
		if (token.tokenProvider) {
			_.set(headers, 'X-ACP-TokenProvider', `${token.tokenProvider}`);
		}
		if (token.correlationId) {
			_.set(headers, 'X-ACP-Correlation-Id', `${token.correlationId}`);
		}
		if (!token.accessToken) {
			Logger.warn(`accessToken should not be empty.`);
		}
		_.set(headers, 'Authorization', `Bearer ${token.accessToken}`);
	}

	private getRequestHeaders(headers: any): any {
		let requestHeader = headers || {};
		let lsRequestHeaderText = localStorage.getItem('requestHeaders');
		if (lsRequestHeaderText) {
			let lsRequestHeader = JSON.parse(lsRequestHeaderText);
			for (let key in lsRequestHeader) {
				requestHeader[key] = lsRequestHeader[key];
			}
		} else if (window && window.location && window.location.search) {
			let queryParameters = queryString.parse(_.toLower(window.location.search));
			let debugParameterValue = queryParameters.debug;
			if (debugParameterValue === '1' || debugParameterValue === 'true') {
				requestHeader['debug'] = 'true';
			}
			let cacheControlParameterValue = queryParameters.cachecontrol; //e.g. cachControl=no-cache
			if (cacheControlParameterValue) {
				requestHeader['cache-control'] = cacheControlParameterValue;
			}
			let computationControlParameterValue = queryParameters.computationcontrol; //e.g. computationControl=disable-data-propagation
			if (computationControlParameterValue) {
				requestHeader['computation-control'] = computationControlParameterValue;
			}
		}
		return requestHeader;
	}

	private async httpGetInternal(
		url: string,
		showProgressBar: boolean,
		headers: any,
		maxRetries: number,
		isRetryRecursiveCall: boolean
	): Promise<any> {
		let self = this;
		let options = {
			url: url,
			method: 'GET',
			headers: self.getRequestHeaders(headers)
		};
		if (!self.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}

		if (showProgressBar && isRetryRecursiveCall === false) {
			let pageLoadState = dispatchAccessor.store.getState() as ApplicationState;
			if (pageLoadState && localStorage.getItem('EnableSkipDuplicateXhrGetCall')) {
				if (pageLoadState.pageLoadingState.getCallUrls[url] >= dataLoadingDuplicateXhrGetCallTolerantNumber) {
					Logger.warn(`Skipped XHR GET duplicated calls on url ${url}`);
					return SkippedXhrGetResult;
				}
			}
			dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'GET', url: url });
		}

		return fetch(url, options)
			.then(res => {
				self.checkVersionCompatible(res);
				if (!res.ok) {
					//retry fetch one time for 503 errors
					if (maxRetries <= 0 || res.status !== 503) {
						return self.handleError(res);
					}
					return this.httpGetInternal(url, showProgressBar, headers, maxRetries - 1, true);
				}
				let contentType = res.headers.get('content-type');
				if (contentType && contentType.indexOf('application/json') !== -1) {
					return res.json();
				} else {
					if (contentType === null) return;
					let fileName = '_downloadFile';
					if (contentType && contentType.indexOf('fileName=') > 0) {
						fileName = contentType.split('fileName=')[1];
					}
					let fileNameFromHeader = res.headers.get('fileName');
					if (fileNameFromHeader) {
						fileName = fileNameFromHeader;
					}

					return res.blob().then(blob => {
						let url = window.URL.createObjectURL(blob as Blob);
						let a = document.createElement('a');
						a.href = url;
						a.download = fileName;
						document.body.appendChild(a);
						a.click();
						a.remove();
						return true;
					});
				}
			})
			.finally(() => {
				if (showProgressBar && isRetryRecursiveCall === false) {
					dispatchAccessor.dispatch({ type: PageLoadingActionType.Done, method: 'GET', url: url });
				}
			});
	}

	async postFormData(url: string, formData: any, headers?: any) {
		let self = this;
		let options = {
			method: 'POST',
			timeout: this.timeoutInMs,
			headers: self.getRequestHeaders(headers ? headers : defaultHeaders),
			body: formData
		};
		if (!this.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}

		dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'POST', url: url });
		return fetch(url, options)
			.then(async res => {
				if (res.ok) {
					return res.json();
				}
				return self.handleError(res);
			})
			.finally(() => {
				dispatchAccessor.dispatch({ type: PageLoadingActionType.Done, method: 'POST', url: url });
			});
	}

	async httpPost(
		url: string,
		body: any,
		headers?: any,
		returnsData?: boolean,
		returnsDataOnNoContent?: boolean,
		signalTimeoutInMs?: number
	) {
		let self = this;
		let options = {
			method: 'POST',
			timeout: this.timeoutInMs,
			headers: self.getRequestHeaders(headers ? headers : defaultHeaders),
			body: JSON.stringify(body)
		};
		if (!this.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}
		dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'POST', url: url });
		const controller = new AbortController();
		let timeoutId: NodeJS.Timeout;
		if (signalTimeoutInMs && signalTimeoutInMs > 0) {
			timeoutId = setTimeout(() => controller.abort(), signalTimeoutInMs);
			options = { ...options, ...{ signal: controller.signal } };
		}
		return fetch(url, options)
			.then(async res => {
				if (returnsDataOnNoContent && res.status === Constants.noContentsSuccessStatusCode) {
					return res;
				}
				if (returnsData) {
					return res;
				}
				if (res.ok) {
					let contentType = res.headers.get('content-type');
					if (contentType && contentType.indexOf('application/json') !== -1) {
						return res.json();
					} else {
						if (contentType === null) return;
						let fileName = '_downloadFile';
						if (contentType && contentType.indexOf('fileName=') > 0) {
							fileName = contentType.split('fileName=')[1];
						}
						let fileNameFromHeader = res.headers.get('fileName');
						if (fileNameFromHeader) {
							fileName = fileNameFromHeader;
						}

						return res.blob().then(blob => {
							let url = window.URL.createObjectURL(blob as Blob);
							let a = document.createElement('a');
							a.href = url;
							a.download = fileName;
							document.body.appendChild(a);
							a.click();
							a.remove();
							return true;
						});
					}
				}
				return self.handleError(res);
			})
			.finally(() => {
				if (timeoutId) {
					clearTimeout(timeoutId);
				}
				dispatchAccessor.dispatch({ type: PageLoadingActionType.Done });
			});
	}

	async httpPut(url: string, body: any, headers = defaultHeaders) {
		let self = this;
		let options = {
			method: 'PUT',
			timeout: this.timeoutInMs,
			headers: self.getRequestHeaders(headers),
			body: JSON.stringify(body)
		};
		if (!this.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}

		dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'PUT', url: url });
		return fetch(url, options)
			.then(res => {
				if (res.ok) {
					return res.json();
				}
				return self.handleError(res);
			})
			.finally(() => {
				dispatchAccessor.dispatch({ type: PageLoadingActionType.Done });
			});
	}

	async httpPatch(
		url: string,
		body: any,
		headers = defaultHeaders,
		returnsData?: boolean,
		returnsDataOnNoContent?: boolean
	) {
		let self = this;
		let options = {
			method: 'PATCH',
			timeout: this.timeoutInMs,
			headers: self.getRequestHeaders(headers),
			body: JSON.stringify(body)
		};
		if (!this.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}

		dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'PATCH', url: url });
		return fetch(url, options)
			.then(res => {
				if (returnsDataOnNoContent && res.status === Constants.noContentsSuccessStatusCode) {
					return res;
				}
				if (returnsData) {
					return res;
				}
				if (res.status === 204) {
					return;
				}
				if (res.ok) {
					return res.json();
				}
				return self.handleError(res);
			})
			.finally(() => {
				dispatchAccessor.dispatch({ type: PageLoadingActionType.Done, method: 'PATCH', url: url });
			});
	}

	async httpDelete(url: string, headers = defaultHeaders, returnData: boolean = false) {
		let self = this;
		let options = {
			method: 'DELETE',
			timeout: this.timeoutInMs,
			headers: self.getRequestHeaders(headers)
		};
		if (!this.isAnonymous) {
			self.passAccessToken(options.headers);
		}
		if (url.startsWith('https')) {
			_.set(options, 'agent', httpsAgent);
		}

		dispatchAccessor.dispatch({ type: PageLoadingActionType.Start, method: 'DELETE', url: url });
		return fetch(url, options)
			.then(res => {
				if (!res.ok) {
					return self.handleError(res);
				}
				if (returnData) {
					return res.json();
				} else {
					return {};
				}
			})
			.finally(() => {
				dispatchAccessor.dispatch({ type: PageLoadingActionType.Done, method: 'DELETE', url: url });
			});
	}

	async downloadByUrl(url: string, showProgressBar?: boolean): Promise<any> {
		return this.httpGet(url, showProgressBar);
	}

	async getPaginatedResources<TResource>(
		url: string,
		showProgressBar?: boolean
	): Promise<PaginatedResult<TResource>> {
		let paginatedResult = await this.httpGet(url, showProgressBar);
		return paginatedResult;
	}

	async getResource<TResource>(url: string, showProgressBar?: boolean): Promise<TResource> {
		let retrievedResource = await this.httpGet(url, showProgressBar);
		return retrievedResource;
	}

	async postResources<TResource>(
		url: string,
		resourceToPost: HttpRequestItems<TResource>
	): Promise<HttpResponseItems<TResource>> {
		let createdItemsResponse = await this.httpPost(url, resourceToPost);
		return createdItemsResponse;
	}

	async postResourceObject<TResource>(url: string, resourceToPost: TResource): Promise<TResource> {
		let createdItemsResponse = await this.httpPost(url, resourceToPost);
		return createdItemsResponse;
	}

	async postResource<TResource>(url: string, resourceToPost: TResource): Promise<TResource> {
		let createdItemsResponse = await this.postResources(url, {
			items: [resourceToPost]
		});
		return createdItemsResponse.items[0];
	}

	async patchResource<TResource>(url: string, valuesToUpdate: any): Promise<TResource> {
		let partialUpdatedResource = await this.httpPatch(url, valuesToUpdate);
		return partialUpdatedResource;
	}

	async deleteResource(url: string): Promise<void> {
		await this.httpDelete(url);
	}

	// IPP Methods
	async getAuthoritySettings<TResource>(url: string, mapping: Object, previousResult: TResource): Promise<TResource> {
		let retrievedResource = await this.httpGet(url);
		let result = { ...previousResult };
		let mappedVal = {};
		if (retrievedResource && retrievedResource.length > 0) {
			for (const item of retrievedResource) {
				const mappedKey = (mapping as any)[item.settingType] as string;
				(mappedVal as any)[mappedKey] = item.value ? item.value : '';
			}
			(result as any).data = mappedVal;
		}
		return result;
	}

	async getAuthorityInformation<TResource>(
		url: string,
		mapping: Object,
		previousResult: TResource
	): Promise<TResource> {
		let retrievedResource = await this.httpGet(url);
		let result = { ...previousResult };
		let mappedVal = {};
		if (retrievedResource && Object.keys(retrievedResource).length > 0) {
			for (const item in retrievedResource) {
				const mappedKey = (mapping as any)[item];
				(mappedVal as any)[mappedKey] = retrievedResource[item] ? retrievedResource[item] : '';
			}

			(result as any).data = mappedVal;
		}
		return result;
	}

	async postAuthoritySettings<TResource>(
		url: string,
		resourceToPost: TResource,
		mapping: Object,
		excludeSettingTypes: string[] = []
	): Promise<any> {
		const reverseMapping = Object.keys(mapping).reduce((acc, key) => {
			(acc as any)[(mapping as any)[key]] = key;
			return acc;
		}, {});
		let result = { items: [] } as HttpRequestItems<TResource>;
		const resourceToPostData = (resourceToPost as any).data;

		if (Object.keys(resourceToPostData).length > 0) {
			let index = 0;
			for (const key in resourceToPostData) {
				if (key) {
					const mappedKey = (reverseMapping as any)[key] as string;
					if (mappedKey) {
						let mappedVal = {} as any;
						const settingType = `${mappedKey.charAt(0).toLowerCase()}${mappedKey.substring(1)}`;
						if (excludeSettingTypes.indexOf(settingType) === -1) {
							mappedVal.settingType = `${mappedKey.charAt(0).toLowerCase()}${mappedKey.substring(1)}`;
							mappedVal.orgTypeName = OrganizationTypes.authority;
							mappedVal.regulatoryProgramName = RegulatoryProgramName.ipp;
							mappedVal.value = (resourceToPostData as any)[key];
							result.items[index++] = mappedVal;
						}
					}
				}
			}
		}
		let createdItemsResponse = await this.httpPost(`${url}`, result);
		return createdItemsResponse;
	}

	async getPendingRegistrationsList<TResource>(
		url: string,
		mapping: Object,
		pageNumber = 1,
		numberOfItemsPerPage = 10,
		sortBy = 'dateRegistered',
		sortOrder = 'desc'
	): Promise<any> {
		let requestUrl = `${url}?pageNo=${pageNumber}&size=${numberOfItemsPerPage}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
		let retrievedResource = await this.httpGet(requestUrl);
		let result = { items: [] as TResource[], total: 0 };
		let mappedVal = {};
		let index = 0;
		if (retrievedResource && Object.keys(retrievedResource).length > 0) {
			for (const item of retrievedResource.items) {
				mappedVal = {} as TResource;
				for (const key in item) {
					const mappedKey = (mapping as any)[key];
					(mappedVal as any)[mappedKey] = item[key] ? item[key] : '';
				}
				(result as any).items[index] = mappedVal;
				++index;
			}
			result.total = retrievedResource.total;
		}
		return result;
	}

	async getPendingRegistrationUser<TResource>(url: string, mapping: Object, userId: string) {
		let requestUrl = `${url}?userId=${userId}`;
		let retrievedResource = await this.httpGet(requestUrl);
		let result = { item: {} as TResource };
		let mappedVal = {};
		if (retrievedResource && Object.keys(retrievedResource).length > 0) {
			for (const key in retrievedResource) {
				const mappedKey = (mapping as any)[key];
				(mappedVal as any)[mappedKey] = retrievedResource[key] ? retrievedResource[key] : '';
			}
			(result as any).item = mappedVal;
		}
		return result;
	}

	async postPendingRegistrationAction<TResource>(
		url: string,
		resourceToPost: TResource,
		userId: string,
		mapping: Object
	): Promise<any> {
		const reverseMapping = Object.keys(mapping).reduce((acc, key) => {
			(acc as any)[(mapping as any)[key]] = key;
			return acc;
		}, {});
		let result = { items: [] } as HttpRequestItems<TResource>;
		const resourceToPostData = (resourceToPost as any).data;

		if (Object.keys(resourceToPostData).length > 0) {
			let mappedVal = {} as any;
			for (const key in resourceToPostData) {
				if (key) {
					const mappedKey = (reverseMapping as any)[key] as string;
					if (mappedKey) {
						mappedVal[mappedKey] = (resourceToPostData as any)[key];
					}
				}
			}
		}
		(result as any)['userId'] = userId;
		let createdItemsResponse = await this.httpPost(`${url}`, result);
		return createdItemsResponse;
	}
}

export const apiService = new ApiService();
