import React from 'react';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import { LookupType, SubLookupType, EventType, NoticeTemplateType } from '@rcp/types';
import { LookupsWidget, SettingAction, EventTypeSettingUrlResolver, LookupState } from '../../../../widgets/lookup';

import { useDispatch, useSelector } from 'react-redux';
import {
	loadOneAuthorityInspectionTypes,
	useReduxSelector,
	loadAuthorityEventCategories,
	loadFrequencyList,
	loadAuthorityInspectionTypes,
	alertService,
	RootState
} from 'src/redux';
import { LookupRowInspectionTypeValueConverter } from 'src/components/widgets/lookup/data-converters/inspection-type-data-converter';
import InspectionEventTypeEditor from './inspection-event-editor';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TextInput } from 'src/components/widgets';
import { MergeFieldDropDown } from 'src/components/widgets/merge-field-dropdown';
import { RichTextEditor } from 'src/components/widgets/rich-text-editor/rich-text-editor';
import _ from 'lodash';

interface Props {
	embeddedMode?: boolean;
}

interface EmailTemplate {
	templateContent?: string;
	templateSubject?: string;
}

const SendEmailTemplate: React.FC = () => {
	const [messageEmailTemplate, setMessageEmailTemplate] = React.useState<EmailTemplate>({});
	const [subjectCursorPosition, setSubjectCursorPosition] = React.useState(0);
	let mergeFields = useSelector((state: RootState) => state.mergeFields.result);

	React.useEffect(() => {
		getTemplate();
	}, []);

	const getTemplate = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.InspectionEmailTemplate}`
		);
		await apiService
			.getResource<EmailTemplate>(url)
			.then(data => setMessageEmailTemplate(data))
			.catch(err => alertService.addError(err.message));
	};

	const updateTemplate = async (updatedMessageTemplate: EmailTemplate) => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${Resource.AuthoritySettings}/${Resource.InspectionEmailTemplate}`
		);
		await apiService
			.patchResource<EmailTemplate>(url, { ...updatedMessageTemplate })
			.then(data => {
				setMessageEmailTemplate(data);
			})
			.catch(err => alertService.addError(err.message));
	};

	const isFormValid = (updatedMessageTemplate: EmailTemplate) => {
		return validationService.validateMergeFields(
			(updatedMessageTemplate.templateSubject || '') + (updatedMessageTemplate.templateContent || ''),
			mergeFields
		);
	};

	async function updateTemplateContent(editorContent: string, type: string) {
		const updatedMessageTemplate: EmailTemplate = {
			...messageEmailTemplate,
			[type]: editorContent
		};
		isFormValid(updatedMessageTemplate) && updateTemplate(updatedMessageTemplate);
	}

	const onChangeMergeField = (e: DropDownListChangeEvent) => {
		if (!e.target.value) {
			return;
		}
		const contentToInsert = '{{' + e.target.value + '}}';
		let templateSubject: string = messageEmailTemplate.templateSubject || '';
		templateSubject = [
			templateSubject.slice(0, subjectCursorPosition),
			contentToInsert,
			templateSubject.slice(subjectCursorPosition)
		].join('');
		setMessageEmailTemplate({
			...messageEmailTemplate,
			templateSubject
		});
		setSubjectCursorPosition(subjectCursorPosition + contentToInsert.length);
	};

	const changeEmailTemplateContent = (e: any) => {
		let newState = { ...messageEmailTemplate };
		let { name, value } = e.target;
		_.set(newState, name, value);
		setMessageEmailTemplate(newState);
	};

	return (
		<div className="row">
			<div className="col-lg-4 settings-info">
				<strong>{localizationService.getLocalizedString('authoritySetting.sendEmailTemplate')}</strong>
				<p>
					{localizationService.getLocalizedString('authoritySetting.sendEmailInspectionTemplateDescription')}
				</p>
			</div>
			<div className="col-lg-8 facility-portal-invitation">
				<section>
					<div className="form-row">
						<TextInput
							id="subject"
							name="templateSubject"
							className="col-sm-7 form-group"
							onChange={changeEmailTemplateContent}
							label={localizationService.getLocalizedString('authoritySetting.noticesSettings.subject')}
							value={messageEmailTemplate.templateSubject}
							onBlur={(e: any) => {
								setSubjectCursorPosition(e.target.selectionStart);
								updateTemplateContent(e.target.value, 'templateSubject');
							}}
						/>
						<div className="col-sm-5 d-inline-block">
							<MergeFieldDropDown
								id="mergeTags"
								name="mergeTags"
								label={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.mergeTags'
								)}
								hintText={localizationService.getLocalizedString(
									'authoritySetting.noticesSettings.mergeTagsHint'
								)}
								onChange={onChangeMergeField}
							/>
						</div>
					</div>
					<div id="facilityInvitationBody" className="mt-2">
						<label>{localizationService.getLocalizedString('authoritySetting.body')}</label>
						<RichTextEditor
							height="500px"
							noticeTemplateType={NoticeTemplateType.InspectionEmailTemplate}
							editorContent={messageEmailTemplate.templateContent}
							useOnlyMergeFieldTool
							saveTemplateData={(editorContent: any) =>
								updateTemplateContent(editorContent, 'templateContent')
							}
							showTableTools
							hidePageNumber
						/>
					</div>
				</section>
			</div>
		</div>
	);
};

const InspectionTypeSettingComponent: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	const [currentInspectionEventType, setCurrentInspectionEventType] = React.useState({} as EventType);
	const [currentStat, setCurrentLookupRow] = React.useState({} as LookupState);

	const eventCategory = useReduxSelector(
		state =>
			state.authorityEventTypes.eventCategories.filter(i =>
				String.equalCaseInsensitive(i.code, SubLookupType.InspectionEvent)
			)[0]
	);

	React.useEffect(() => {
		dispatch(loadAuthorityEventCategories());
		dispatch(loadAuthorityInspectionTypes());
		dispatch(loadFrequencyList());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let dataConverter = new LookupRowInspectionTypeValueConverter(SubLookupType.InspectionEvent);
	let urlResolver = new EventTypeSettingUrlResolver(SubLookupType.InspectionEvent);
	let settingAction = new SettingAction(LookupType.EventType, urlResolver, dataConverter, false);

	const toggleShow = () => {
		if (currentStat.showModal) {
			setCurrentLookupRow({
				showModal: false,
				newLookupRow: {},
				lookupRow: {}
			} as LookupState);
			setCurrentInspectionEventType({});
		} else {
			setCurrentLookupRow({ ...setCurrentLookupRow, showModal: true } as LookupState);
		}
	};

	const onAfterSave = () => {
		toggleShow();
		dispatch(loadAuthorityInspectionTypes());
	};

	const onAddButtonClicked = () => {
		dispatch(loadAuthorityInspectionTypes());
		setCurrentInspectionEventType({
			...currentInspectionEventType,
			eventCategoryId: eventCategory.lookupId
		});
		toggleShow();
	};

	const onUpdateInspectionEventType = async (id: any) => {
		dispatch(loadAuthorityInspectionTypes());
		let queryString = `includeInactive=true`;
		let eventType = await loadOneAuthorityInspectionTypes(id, queryString);
		setCurrentInspectionEventType({ ...eventType });
		toggleShow();
	};

	const getModalContent = () => {
		return (
			<InspectionEventTypeEditor
				isCreate={!currentInspectionEventType.eventTypeId}
				toggleModal={toggleShow}
				urlResolver={urlResolver}
				eventType={currentInspectionEventType}
				callbackAfterSave={onAfterSave}
			/>
		);
	};

	const getInspectionEventTypeSection = () => {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('authoritySetting.inspectionTypeSection')}</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.inspectionTypeDescription')}</p>
				</div>

				<div className="col-lg-8">
					<section>
						<LookupsWidget
							id="inspectionEventType"
							name="inspectionEventType"
							className="col-sm-12"
							label={localizationService.getLocalizedString('authoritySetting.inspectionTypeName')}
							descriptionLabel={localizationService.getLocalizedString(
								'authoritySetting.frequency.frequency'
							)}
							editDescription={false}
							lookupState={currentStat}
							hideActiveStatus={false}
							showDescriptionColum={true}
							onAddButtonClick={onAddButtonClicked}
							hideAddModal={true}
							onEditButtonClick={onUpdateInspectionEventType}
							settingAction={settingAction}
							editorModal={getModalContent()}
							embeddedMode={props.embeddedMode}
						/>
					</section>
				</div>
			</div>
		);
	};

	const getInspectionEventTypeEmbeddedList = () => {
		return (
			<LookupsWidget
				id="inspectionEventType"
				name="inspectionEventType"
				className="col-sm-12"
				label={localizationService.getLocalizedString('authoritySetting.inspectionTypeSection')}
				editDescription={false}
				lookupState={currentStat}
				hideActiveStatus={false}
				showDescriptionColum={true}
				onAddButtonClick={onAddButtonClicked}
				hideAddModal={true}
				onEditButtonClick={onUpdateInspectionEventType}
				settingAction={settingAction}
				editorModal={getModalContent()}
				embeddedMode={props.embeddedMode}
			/>
		);
	};

	return (
		<>
			{props.embeddedMode ? (
				getInspectionEventTypeEmbeddedList()
			) : (
				<>
					{getInspectionEventTypeSection()}
					{urlService.isAdministrator() && <SendEmailTemplate />}
				</>
			)}
		</>
	);
};

export default InspectionTypeSettingComponent;
