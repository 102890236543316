import React, { useEffect, useRef, useState } from 'react';
import { alertService, RootState, useReduxDispatch, useReduxSelector } from 'src/redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { Switch, useHistory } from 'react-router';
import { History } from 'history';
import * as ApiTypes from '@rcp/types';
import {
	dashboardService,
	DateUtilService,
	localizationService,
	navigateTo,
	Resource,
	urlService,
	UtilService,
	validationService
} from 'src/services';
import { Sortable } from '@progress/kendo-react-sortable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { ChartCountListSvg, ChartDonutSvg, CloseSvg, SortIconSvg } from '../../../../svg';
import { ComboBoxDropdown, SingleSelectDropdown, TextInput } from 'src/components/widgets';
import _ from 'lodash';
import classNames from 'classnames';
import * as actionCreators from '../../../../../redux/filter';
import '../dashboard.scss';
import { nameof } from 'ts-simple-nameof';
import { RestApi } from 'src/services/rest.api';
import { dashboardWidgetSlice } from '../dashboard-widgets.slice';
import { useSelector } from 'react-redux';
import { chartService, ColorScheme, DateFilterValues } from './chart-service';
import { DonutChartWidget } from './donut-chart-widget';
import { DashboardWidgetFilterCategories, DropDownOption, Filter } from '@rcp/types';

import { BarChartWidget } from './bar-chart-widget';
import { LineChartWidget } from './line-chart-widget';
import { WidgetTypeButton } from './widget-type-button';
import { FeatureNames } from '@rcp/types';
import { Utils } from 'src/services/utils';

interface Props extends ApiTypes.RouteProps {
	history: History;
}

interface FormState {
	dashboardWidgetId?: number;
	title?: string;
	subtitle?: string;
	widgetType?: ApiTypes.DashboardWidgetTypes;
	filterId?: string;
	filterCategory?: ApiTypes.DashboardWidgetFilterCategories;
	colorScheme?: string;
	titleError?: string;
	filterIdError?: string;
	filterCategoryError?: string;
	widgetTypeError?: string;
	groupByField: string;
	groupByFieldError?: string;
	systemWidgetTemplateId?: number;
	withDateFilter?: boolean;
	withButton?: boolean;
}

const initialFormState: FormState = {
	colorScheme: ColorScheme.Blue,
	groupByField: ''
};

enum DashboardWidgetTab {
	System = 'tab-system',
	Custom = 'tab-custom'
}

const donutColorSchemes = [ColorScheme.Blue, ColorScheme.Red, ColorScheme.Green, ColorScheme.Purple];

const DashboardWidgetDetails: React.FC<Props> = props => {
	const dispatch = useReduxDispatch();
	const history = useHistory();

	const restApi = new RestApi<any>(ApiTypes.ApiEndpoints.Dashboard);
	const [activeTab, setActiveTab] = useState('tab-system');
	const [formState, setFormState] = React.useState(initialFormState);
	const [filters, setFilters] = useState([] as ApiTypes.Filter[]);
	const [filteredFilters, setFilteredFilters] = useState([] as ApiTypes.Filter[]);
	const [allFilters, setAllFilters] = useState([] as ApiTypes.Filter[]);
	const [incidentFilters, setIncidentFilters] = useState([] as ApiTypes.Filter[]);
	const [nonIncidentFilters, setNonIncidentFilters] = useState([] as ApiTypes.Filter[]);
	const [currentDashboardWidgetFilters, setCurrentDashboardWidgetFilters] = React.useState<
		ApiTypes.DashboardWidgetFilter[]
	>([] as ApiTypes.DashboardWidgetFilter[]);
	const [availableGroupByFields, setAvailableGroupByFields] = useState([] as DropDownOption[]);
	const [countListItems, setCountListItems] = React.useState<ApiTypes.CountListItem[]>([]);
	const [previewProperties, setPreviewProperties] = useState('');
	const [isEditMode, setIsEditMode] = useState(false);
	const [systemTemplates, setSystemTemplate] = useState([] as ApiTypes.DashboardSystemWidgetTemplate[]);
	const [dateFilterValue, setDateFilterValue] = useState('');
	const [previewSubtitle, setPreviewSubtitle] = useState('');
	const [isFacilityPortalEnabled, setIsFacilityPortalEnabled] = useState<boolean>(false);
	let dashboardWidgetsState = (state: RootState) => state.dashboardWidgets;
	let { selected, loading } = useSelector(dashboardWidgetsState);

	const showIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);

	const getCountList = async () => {
		let newCountListItems: ApiTypes.CountListItem[] = await Promise.all(
			currentDashboardWidgetFilters.map(async dashboardWidgetFilter => {
				let newCountListItem: ApiTypes.CountListItem = {
					filterName: dashboardWidgetFilter.filterName,
					count: await dashboardService.getCountResultByFilter(
						dashboardWidgetFilter.filterId,
						dashboardWidgetFilter.filterCategory
					),
					gridPath: dashboardService.getGridPath(
						dashboardWidgetFilter.filterId as number,
						dashboardWidgetFilter.filterCategory == ApiTypes.DashboardWidgetFilterCategories.Extractors
							? ApiTypes.DashboardWidgetFilterCategories.Devices
							: dashboardWidgetFilter.filterCategory
					),
					mapPath: dashboardService.getMapPath(dashboardWidgetFilter)
				};
				return newCountListItem;
			})
		);

		setCountListItems(newCountListItems);
	};

	const defaultCountListFilterList = useRef<any>(null);

	const filterCategoryOptions: ApiTypes.DropDownOption[] = [
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.facilities'),
			value: ApiTypes.DashboardWidgetFilterCategories.Facilities
		},
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.inspections'),
			value: ApiTypes.DashboardWidgetFilterCategories.Inspections
		},
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.violations'),
			value: ApiTypes.DashboardWidgetFilterCategories.Violations
		},
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.devices'),
			value: ApiTypes.DashboardWidgetFilterCategories.Devices
		},
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.cleanings'),
			value: ApiTypes.DashboardWidgetFilterCategories.Cleanings
		},
		{
			label: localizationService.getLocalizedString('dashboard.widgetDetails.events'),
			value: ApiTypes.DashboardWidgetFilterCategories.Events
		}
	];

	if (showIncidentFeature) {
		filterCategoryOptions.push({
			label: localizationService.getLocalizedString('dashboard.widgetDetails.incidents'),
			value: ApiTypes.DashboardWidgetFilterCategories.Incidents
		});
	}

	const setFacilityPortalStatus = async () => {
		setIsFacilityPortalEnabled(await Utils.getFacilityPortalStatusForAuthority());
	};

	useEffect(() => {
		if (String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.CountList)) {
			getCountList();
		}
	}, [currentDashboardWidgetFilters]);

	useEffect(() => {
		dashboardService
			.getSystemWidgetTemplates()
			.then(res => {
				setSystemTemplate(res);
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
		setFacilityPortalStatus();
	}, []);

	useEffect(() => {
		let dashboardWidgetId = urlService.getDashboardWidgetIdFromUrl();

		if (dashboardWidgetId && dashboardWidgetId > 0) {
			dispatch(dashboardWidgetSlice.fetchOne(dashboardWidgetId));
			setIsEditMode(true);
		} else {
			dispatch(dashboardWidgetSlice.resetAll());
		}

		dispatch(actionCreators.GetFilters(undefined, true)).then(filters => {
			if (_.isArray(filters)) {
				setAllFilters(filters);
				let incidentFilters: ApiTypes.Filter[] = [];
				let nonIncidentFilters: ApiTypes.Filter[] = [];
				for (var filter of filters) {
					if (String.equalCaseInsensitive(filter.applyTo, ApiTypes.FilterObjectName.Incident)) {
						incidentFilters.push(filter);
					} else {
						nonIncidentFilters.push(filter);
					}
				}
				setIncidentFilters(incidentFilters);
				setNonIncidentFilters(nonIncidentFilters);
				setFilters(filters);
			}
		});
	}, [dispatch]);

	useEffect(() => {
		if (!loading && selected) {
			let newState = { ...formState };
			newState.dashboardWidgetId = selected.dashboardWidgetId;
			newState.title = selected.title;
			newState.subtitle = selected.subtitle;
			newState.widgetType = selected.widgetType;
			newState.systemWidgetTemplateId = selected.systemWidgetTemplateId;
			if (
				String.equalCaseInsensitive(selected.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart) &&
				selected.properties
			) {
				setPreviewProperties(selected.properties);
				let propObject = JSON.parse(selected.properties);
				getAvailableGroupByFields(propObject.lookIn);

				newState.colorScheme = propObject.colorScheme;
				newState.groupByField = propObject.groupBy;
				newState.filterCategory = propObject.lookIn;
			}

			setFormState(newState);

			if (selected.systemWidgetTemplateId && selected.systemWidgetTemplateId > 0) {
				setActiveTab(DashboardWidgetTab.System);
				if (selected.subtitle) {
					setPreviewSubtitle(selected.subtitle);
				}
				if (selected.properties) {
					setPreviewProperties(selected.properties);
				}
				if (selected.dateFilterValue) {
					setDateFilterValue(selected.dateFilterValue);
				}
				if (selected.dateFilterValue && _.isEmpty(selected.subtitle)) {
					setDateFilterSubtitle(selected.dateFilterValue);
				}
			} else {
				setActiveTab(DashboardWidgetTab.Custom);

				if (selected.dashboardWidgetFilters) {
					let dashboardWidgetFilters: ApiTypes.DashboardWidgetFilter[] = UtilService.fromJson(
						selected.dashboardWidgetFilters
					);
					if (dashboardWidgetFilters && dashboardWidgetFilters.length > 0) {
						switch (selected.widgetType) {
							case ApiTypes.DashboardWidgetTypes.BarChart:
							case ApiTypes.DashboardWidgetTypes.DonutChart:
								newState.filterId = dashboardWidgetFilters[0].filterId
									? dashboardWidgetFilters[0].filterId.toString()
									: undefined;
								newState.filterCategory = dashboardWidgetFilters[0].filterCategory;
								setFormState(newState);
								break;
							default:
								break;
						}
					}
					setCurrentDashboardWidgetFilters(dashboardWidgetFilters);
				}
			}
		}
	}, [selected, loading]);

	useEffect(() => {
		let currentDashboardWidgetFiltersIds = currentDashboardWidgetFilters.map(x => x.filterId);

		let filterList: Filter[] = [];
		if (formState.filterCategory && isEditMode && (incidentFilters.length > 0 || nonIncidentFilters.length > 0)) {
			if (String.equalCaseInsensitive(formState.filterCategory, DashboardWidgetFilterCategories.Incidents)) {
				filterList = incidentFilters;
			} else {
				filterList = nonIncidentFilters;
			}
		} else {
			filterList = filters;
		}

		if (String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.CountList)) {
			filterList = filterList.filter(item => !currentDashboardWidgetFiltersIds.includes(item.filterId));
		}
		setFilteredFilters(filterList);
	}, [filters, currentDashboardWidgetFilters]);

	const getAvailableGroupByFields = (filterCategory: ApiTypes.DashboardWidgetFilterCategories | undefined) => {
		chartService
			.getAvailableGroupBys(filterCategory)
			.then((res: DropDownOption[]) => {
				let filteredResponse = res;
				if (!isFacilityPortalEnabled) {
					const facilityPortalColumns = [
						nameof<ApiTypes.FogFacility>(f => f.invitationDateTimeUtc),
						nameof<ApiTypes.FogFacility>(f => f.facilityPortalStatusName)
					];
					filteredResponse = res.filter(data => {
						return facilityPortalColumns.includes(data.value) ? false : true;
					});
				}
				setAvailableGroupByFields(filteredResponse);
			})
			.catch((e: any) => {
				alertService.addError(e.message);
			});
	};

	const unsetIncompatibleFilter = (formState: FormState, newState: FormState, isIncidentFilterCategory: boolean) => {
		if (formState.filterId) {
			let currentFormFilter = allFilters.find(i => i.filterId == formState.filterId);
			if (currentFormFilter) {
				if (
					String.equalCaseInsensitive(currentFormFilter.applyTo, ApiTypes.FilterObjectName.Incident) ==
					isIncidentFilterCategory
				) {
					_.set(
						newState,
						nameof<FormState>(s => s.filterId),
						''
					);
				}
			}
		}
	};

	const onSaveClicked = async () => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			nameof<FormState>(d => d.title),
			nameof<FormState>(d => d.titleError),
			localizationService.getLocalizedString('dashboard.widgetDetails.title')
		);

		if (String.equalCaseInsensitive(newState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart)) {
			validationService.validateRequiredField(
				newState,
				nameof<FormState>(d => d.filterCategory),
				nameof<FormState>(d => d.filterCategoryError)
			);
			validationService.validateRequiredField(
				newState,
				nameof<FormState>(d => d.filterId),
				nameof<FormState>(d => d.filterIdError)
			);
			validationService.validateRequiredField(
				newState,
				nameof<FormState>(d => d.groupByField),
				nameof<FormState>(d => d.groupByFieldError)
			);
		}

		const isFormValid = !validationService.hasError(
			newState,
			nameof<FormState>(d => d.titleError),
			nameof<FormState>(d => d.filterCategoryError),
			nameof<FormState>(d => d.filterIdError),
			nameof<FormState>(d => d.groupByFieldError)
		);

		setFormState(newState);

		if (!isFormValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return;
		}

		if (
			!newState.systemWidgetTemplateId ||
			(newState.systemWidgetTemplateId && newState.systemWidgetTemplateId < 1)
		) {
			validationService.validateRequiredField(
				newState,
				nameof<FormState>(d => d.widgetType),
				nameof<FormState>(d => d.widgetTypeError)
			);

			const isWidgetTypeSelected = !validationService.hasError(
				newState,
				nameof<FormState>(d => d.widgetTypeError)
			);

			if (!isWidgetTypeSelected) {
				alertService.addError(
					localizationService.getLocalizedString('dashboard.widgetDetails.widgetTypeRequired')
				);
				return;
			}
		}

		let newCurrentDashboardWidgetFilters = [...currentDashboardWidgetFilters];

		for (let i = 0; i < newCurrentDashboardWidgetFilters.length; i++) {
			newCurrentDashboardWidgetFilters[i].sortOrder = i + 1;
		}

		let dashboardWidgetToSave: ApiTypes.DashboardWidget = {
			dashboardWidgetId: newState.dashboardWidgetId,
			title: newState.title,
			subtitle: newState.subtitle,
			widgetType: newState.widgetType,
			dashboardWidgetFilters: UtilService.toJson(newCurrentDashboardWidgetFilters),
			systemWidgetTemplateId: newState.systemWidgetTemplateId,
			dateFilterValue: dateFilterValue
		};
		if (String.equalCaseInsensitive(newState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart)) {
			let filterId = newState.filterId ? _.toNumber(newState.filterId) : 0;
			let dashboardWidgetFilter: ApiTypes.DashboardWidgetFilter = {
				filterId: filterId,
				filterCategory: newState.filterCategory,
				filterName: filters.filter(a => a.filterId === filterId)[0].filterName,
				sortOrder: newCurrentDashboardWidgetFilters.length + 1
			};
			dashboardWidgetToSave = {
				...dashboardWidgetToSave,
				properties: previewProperties,
				dashboardWidgetFilters: UtilService.toJson([dashboardWidgetFilter])
			};
		}
		if (newState.systemWidgetTemplateId && newState.systemWidgetTemplateId > 0) {
			dashboardWidgetToSave = {
				...dashboardWidgetToSave,
				properties: previewProperties
			};
		}

		if (newState.dashboardWidgetId) {
			restApi
				.patchOne(newState.dashboardWidgetId, dashboardWidgetToSave)
				.then(() => {
					const url = urlService.getReactResourceUrl(Resource.Dashboard);
					navigateTo(history, url);
					window.setTimeout(function() {
						alertService.addSuccess(
							localizationService.getLocalizedString(
								'alertMessages.updateSuccess',
								localizationService.getLocalizedString('dashboard.dashboardWidget')
							)
						);
					}, 500);
				})
				.catch((e: any) => {
					alertService.addError(e.message);
				});
		} else {
			restApi
				.createOne(dashboardWidgetToSave)
				.then((response: any) => {
					const url = urlService.getReactResourceUrl(Resource.Dashboard);
					navigateTo(history, url);

					window.setTimeout(function() {
						alertService.addSuccess(
							localizationService.getLocalizedString(
								'alertMessages.addSuccess',
								localizationService.getLocalizedString('dashboard.dashboardWidget')
							)
						);
					}, 500);
				})
				.catch((e: any) => {
					alertService.addError(e.message);
				});
		}
	};

	const onAddFilterClicked = async () => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			nameof<FormState>(d => d.filterCategory),
			nameof<FormState>(d => d.filterCategoryError),
			localizationService.getLocalizedString('dashboard.widgetDetails.countListCategory')
		);

		validationService.validateRequiredField(
			newState,
			nameof<FormState>(d => d.filterId),
			nameof<FormState>(d => d.filterIdError),
			localizationService.getLocalizedString('dashboard.widgetDetails.countListFilter')
		);

		let errorFieldNames = [nameof<FormState>(d => d.filterCategoryError), nameof<FormState>(d => d.filterIdError)];

		const isFormValid = !validationService.hasError(newState, ...errorFieldNames);

		if (!isFormValid) {
			setFormState(newState);
			return;
		}
		const filterCategory =
			formState.filterCategory == ApiTypes.DashboardWidgetFilterCategories.Devices
				? ApiTypes.DashboardWidgetFilterCategories.Extractors
				: formState.filterCategory;
		let newCurrentDashboardWidgetFilters = [...currentDashboardWidgetFilters];
		let filterId = formState.filterId ? _.toNumber(formState.filterId) : 0;
		let dashboardWidgetFilter: ApiTypes.DashboardWidgetFilter = {
			filterId: filterId,
			filterCategory: filterCategory,
			filterName: filters.filter(a => a.filterId === filterId)[0].filterName,
			sortOrder: newCurrentDashboardWidgetFilters.length + 1
		};

		newCurrentDashboardWidgetFilters.push(dashboardWidgetFilter);
		setCurrentDashboardWidgetFilters(newCurrentDashboardWidgetFilters);

		newState.filterId = undefined;
		newState.filterCategory = undefined;
		setFilters(allFilters);
		setFormState(newState);
	};

	const onDeleteFilterClicked = (filterId: number) => {
		let newCurrentDashboardWidgetFilters = [...currentDashboardWidgetFilters];
		let newDashboardWidgetFilters = newCurrentDashboardWidgetFilters.filter(x => x.filterId !== filterId);
		setCurrentDashboardWidgetFilters(newDashboardWidgetFilters);
	};

	const onDragOver = (event: any) => {
		setCurrentDashboardWidgetFilters(event.newState);
	};

	const onCancelClicked = () => {
		let dashboardUrl = urlService.getReactResourceUrl(Resource.Dashboard);
		navigateTo(props.history, dashboardUrl);
	};

	const onChangeFormState = (e: any) => {
		let { name, value } = e.target;
		if (
			name == nameof<FormState>(d => d.subtitle) &&
			String.equalCaseInsensitive(activeTab, DashboardWidgetTab.System)
		) {
			setPreviewSubtitle(value);
			if (_.isEmpty(value)) {
				setDateFilterSubtitle(dateFilterValue);
			}
		}
		let newState = { ...formState };
		_.set(newState, name, value);
		if (
			String.equalCaseInsensitive(
				name,
				nameof<FormState>(d => d.filterCategory)
			) &&
			String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart)
		) {
			_.set(
				newState,
				nameof<FormState>(s => s.groupByField),
				''
			);
			setAvailableGroupByFields([]);
			setPreviewProperties('');
			getAvailableGroupByFields(value);
		}

		checkIfFilterIsNotCompatibleWithFilterCategory(name, value, formState, newState);

		let shouldUpdateDonutPreview =
			String.equalCaseInsensitive(newState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart) &&
			[nameof<FormState>(d => d.filterId), nameof<FormState>(d => d.colorScheme)].includes(name);
		if (shouldUpdateDonutPreview) {
			updateDonutPreview(newState);
		}
		setFormState(newState);
	};

	const onChangeGroupBy = (e: any) => {
		const { name, value } = e.target;
		let newState = { ...formState };
		if (String.equalCaseInsensitive(newState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart)) {
			if (_.isEmpty(value)) {
				_.set(newState, name, '');
			} else {
				_.set(newState, name, value);
			}
			setFormState(newState);
			updateDonutPreview(newState);
		} else {
			throw new Error(`onChangeGroupBy does not support widget type ${newState.widgetType}.`);
		}
	};

	const onChangeChart = (value: any) => {
		if (isEditMode) {
			return;
		}
		let newState = {
			...initialFormState,
			title: formState.title,
			subtitle: formState.subtitle,
			systemWidgetTemplateId: undefined
		};
		_.set(newState, 'widgetType', value);
		setPreviewProperties('');
		setCurrentDashboardWidgetFilters([]);
		setFilters(allFilters);
		setFormState(newState);
	};

	const onSelectSystemTemplate = (template: ApiTypes.DashboardSystemWidgetTemplate) => {
		let newState = {
			...initialFormState,
			title: formState.title,
			subtitle: formState.subtitle,
			widgetType: template.widgetType as ApiTypes.DashboardWidgetTypes,
			systemWidgetTemplateId: template.systemWidgetTemplateId,
			dashboardWidgetId: formState.dashboardWidgetId
		};
		setFormState(newState);
		let updatedProps = {
			...JSON.parse(template.properties as string),
			withDateFilter: formState.withDateFilter,
			withButton: formState.withButton
		};
		setPreviewProperties(JSON.stringify(updatedProps));
		if (_.isEmpty(formState.subtitle)) {
			setDefaultDateFilterSubtitle(template.widgetType);
		}
	};

	const setDefaultDateFilterSubtitle = (widgetType: string) => {
		if (widgetType == ApiTypes.DashboardWidgetTypes.BarChart) {
			setDateFilterSubtitle(DateFilterValues.Last30Days);
			setDateFilterValue(DateFilterValues.Last30Days);
		}
		if (widgetType == ApiTypes.DashboardWidgetTypes.LineChart) {
			setDateFilterSubtitle(`${DateUtilService.getAuthorityTimezoneNowAsMoment().year()}`);
			setDateFilterValue(`${DateUtilService.getAuthorityTimezoneNowAsMoment().year()}`);
		}
	};

	const updateDonutPreview = (newState: FormState) => {
		if (newState.groupByField && newState.filterId) {
			let propObject = {
				lookIn: newState.filterCategory,
				groupBy: newState.groupByField,
				withDateFilter: false,
				colorScheme: newState.colorScheme
			};
			setPreviewProperties(JSON.stringify(propObject));
		} else {
			setPreviewProperties('');
		}
	};

	const getFooter = (
		<div className="col text-center mt-5 mb-3">
			<button id="btnSave" className="btn ai-save" onClick={onSaveClicked}>
				{localizationService.getLocalizedString('screen.buttons.save')}
			</button>
			<button id="btnCancel" className="btn ai-white ml-2" onClick={onCancelClicked}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</div>
	);

	const getBaseItemStyle = (isActive: any) => ({
		cursor: 'move',
		position: 'relative',
		paddingLeft: '0px',
		outline: 'none',
		display: 'inline-block'
	});

	const sortableItemUI = (props: any) => {
		const { style, isActive, attributes, dataItem, forwardRef } = props;
		return (
			<div
				ref={forwardRef}
				{...attributes}
				className={'lookup-row d-flex p-2'}
				style={{
					...style,
					...getBaseItemStyle(isActive)
				}}>
				<div className={'mr-3'}>
					<SortIconSvg isActive={isActive} />
				</div>

				<div>{dataItem.filterName}</div>

				<div className="ml-auto">
					<button onClick={() => onDeleteFilterClicked(dataItem.filterId as number)}>
						<FontAwesomeIcon icon={faTrashAlt} className="font-awesome-icon " />
					</button>
				</div>
			</div>
		);
	};

	const SortableEmptyItemUI = () => {
		return <div></div>;
	};

	const checkIfFilterIsNotCompatibleWithFilterCategory = (
		name: any,
		value: any,
		formState: FormState,
		newState: FormState
	) => {
		if (
			String.equalCaseInsensitive(
				name,
				nameof<FormState>(d => d.filterCategory)
			)
		) {
			if (String.equalCaseInsensitive(value, ApiTypes.DashboardWidgetFilterCategories.Incidents)) {
				unsetIncompatibleFilter(formState, newState, false);
				setFilters(incidentFilters);
			} else if (!String.equalCaseInsensitive(value, ApiTypes.DashboardWidgetFilterCategories.Incidents)) {
				unsetIncompatibleFilter(formState, newState, true);
				setFilters(nonIncidentFilters);
			} else if (String.isUndefinedOrEmpty(value)) {
				setFilters(allFilters);
			}
		}
	};

	const widgetPreviewChanged = (widget: ApiTypes.DashboardWidget) => {
		if (widget.dateFilterValue && _.isEmpty(widget.subtitle) && _.isEmpty(formState.subtitle)) {
			setDateFilterSubtitle(widget.dateFilterValue);
			setDateFilterValue(widget.dateFilterValue);
		}
	};

	const setDateFilterSubtitle = (dateFilterValue: string) => {
		let newSubtitle = chartService.getDateFilterDescription(dateFilterValue);
		setPreviewSubtitle(newSubtitle as string);
	};

	const getCountListFilterList = () => {
		return (
			<>
				{currentDashboardWidgetFilters.length > 0 && <div className="lookup-row"></div>}
				<Sortable
					ref={defaultCountListFilterList}
					idField={'filterId'}
					data={currentDashboardWidgetFilters}
					itemUI={sortableItemUI}
					emptyItemUI={SortableEmptyItemUI}
					onDragOver={onDragOver}
				/>
			</>
		);
	};

	const getCountListRowsForPreview = () => {
		if (countListItems.length > 0) {
			return countListItems.map((item, index) => {
				return (
					<tr className="lookup-row" key={`key_${index}`}>
						<td id={`filterName_${index}`}>{item.filterName}</td>
						<td align="right">
							<a target="_blank" rel="noopener noreferrer" href={item.link}>
								{item.count}
							</a>
						</td>
					</tr>
				);
			});
		}

		return [1, 2, 3].map((_idx: number, index) => {
			return (
				<tr className="lookup-row" key={`item-key-${index}`}>
					<td id={`item_filterName_${index}`}>
						{localizationService.getLocalizedString('dashboard.widgetDetails.filter')}
					</td>

					<td align="right">
						<a
							href="#/"
							onClick={e => {
								e.preventDefault();
							}}>
							{0}
						</a>
					</td>
				</tr>
			);
		});
	};

	const getCountListDetails = (
		<div className="mt-3">
			<h2 className="widget-questions">
				{localizationService.getLocalizedString('dashboard.widgetDetails.countListHeader')}
			</h2>
			<p>{localizationService.getLocalizedString('dashboard.widgetDetails.countListDescription')}</p>

			<SingleSelectDropdown
				id={nameof<FormState>(d => d.filterCategory)}
				name={nameof<FormState>(d => d.filterCategory)}
				error={formState.filterCategoryError}
				value={formState.filterCategory}
				isRequired={true}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.countListCategory')}
				onChange={onChangeFormState}
				options={filterCategoryOptions}
				labelClassName="widget-questions"
			/>

			<ComboBoxDropdown
				id={nameof<FormState>(d => d.filterId)}
				name={nameof<FormState>(d => d.filterId)}
				error={formState.filterIdError}
				isRequired={true}
				value={_.toString(formState.filterId)}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.countListFilter')}
				onChange={onChangeFormState}
				options={filteredFilters.map(i => ({
					label: i.filterName,
					value: _.toString(i.filterId)
				}))}
				labelClassName="widget-questions"
			/>
			<div className="col text-center mb-3">
				<button id="btnAdd" className="btn ai-secondary" onClick={onAddFilterClicked}>
					{localizationService.getLocalizedString('dashboard.widgetDetails.addBtnText')}
				</button>
			</div>
			{getCountListFilterList()}
		</div>
	);

	const getDonutChartDetails = (
		<div className="mt-3">
			<SingleSelectDropdown
				id={nameof<FormState>(d => d.filterCategory)}
				name={nameof<FormState>(d => d.filterCategory)}
				error={formState.filterCategoryError}
				isRequired={true}
				value={formState.filterCategory}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.category')}
				onChange={onChangeFormState}
				options={filterCategoryOptions}
				labelClassName="widget-questions"
			/>
			<ComboBoxDropdown
				id={nameof<FormState>(d => d.filterId)}
				name={nameof<FormState>(d => d.filterId)}
				error={formState.filterIdError}
				isRequired={true}
				value={_.toString(formState.filterId)}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.filter')}
				onChange={onChangeFormState}
				options={filteredFilters.map(i => ({
					label: i.filterName,
					value: _.toString(i.filterId)
				}))}
				labelClassName="widget-questions"
			/>
			<ComboBoxDropdown
				id={nameof<FormState>(d => d.groupByField)}
				name={nameof<FormState>(d => d.groupByField)}
				error={formState.groupByFieldError}
				isRequired={true}
				value={formState.groupByField}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.groupBy')}
				onChange={onChangeGroupBy}
				options={availableGroupByFields}
				labelClassName="widget-questions"
			/>
			<label className="widget-questions">
				{localizationService.getLocalizedString('dashboard.widgetDetails.colorScheme')}
			</label>
			<div className="row">
				{donutColorSchemes.map((scheme: ColorScheme, index) => (
					<div className="col" key={'key_' + index}>
						<div className="custom-control custom-radio form-group custom-control-inline mb-0">
							<input
								type="radio"
								className="custom-control-input"
								name="colorScheme"
								value={scheme}
								checked={String.equalCaseInsensitive(formState.colorScheme, scheme)}
								id={'colorScheme_' + scheme.toString()}
								onChange={onChangeFormState}
							/>
							<label className="custom-control-label" htmlFor={'colorScheme_' + scheme.toString()}>
								<div>{scheme.toString()}</div>
							</label>
						</div>
						<div className="d-flex mb-1">
							{chartService
								.getColorScheme(scheme)
								.slice(0, 3)
								.map((color: string, colorIndex) => (
									<div
										key={'key_' + index + '_' + colorIndex}
										style={{
											width: '30px',
											height: '30px',
											backgroundColor: color,
											marginRight: '2px'
										}}></div>
								))}
						</div>
					</div>
				))}
			</div>
		</div>
	);

	const getWidgetDetailsSection = (
		<div className="col px-5 widget-details-section">
			<div className="page-header">
				<h1>
					{formState.dashboardWidgetId && formState.dashboardWidgetId > 0
						? localizationService.getLocalizedString('dashboard.widgetDetails.editPageTitle')
						: localizationService.getLocalizedString('dashboard.widgetDetails.addPageTitle')}
				</h1>
				<button className="close" onClick={onCancelClicked}>
					<CloseSvg />
				</button>
			</div>
			<div>
				<h2 className="widget-questions">
					{localizationService.getLocalizedString('dashboard.widgetDetails.addWidgetHeader')}
				</h2>
				<Nav tabs className="material">
					{(!isEditMode ||
						(isEditMode && formState.systemWidgetTemplateId && formState.systemWidgetTemplateId > 0)) && (
						<NavItem>
							<NavLink
								onClick={() => {
									setActiveTab(DashboardWidgetTab.System);
								}}
								className={
									classNames({
										active: String.equalCaseInsensitive(activeTab, DashboardWidgetTab.System)
									}) + ' cursor-pointer'
								}>
								{localizationService.getLocalizedString('dashboard.widgetDetails.systemWidgets')}
							</NavLink>
						</NavItem>
					)}
					{(!isEditMode || (isEditMode && !formState.systemWidgetTemplateId)) && (
						<NavItem>
							<NavLink
								onClick={() => {
									setActiveTab(DashboardWidgetTab.Custom);
								}}
								className={
									classNames({
										active: String.equalCaseInsensitive(activeTab, DashboardWidgetTab.Custom)
									}) + ' cursor-pointer'
								}>
								{localizationService.getLocalizedString('dashboard.widgetDetails.custom')}
							</NavLink>
						</NavItem>
					)}
				</Nav>

				<div className="mt-1">
					<Switch>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={DashboardWidgetTab.System}>
								{String.equalCaseInsensitive(activeTab, DashboardWidgetTab.System) && (
									<>
										<div className="d-flex flex-wrap">
											{systemTemplates.map(template => (
												<WidgetTypeButton
													label={template.templateName}
													onClick={() => {
														onSelectSystemTemplate(template);
													}}
													isActive={
														template.systemWidgetTemplateId ===
														formState.systemWidgetTemplateId
													}
												/>
											))}
										</div>
									</>
								)}
							</TabPane>
							<TabPane tabId={DashboardWidgetTab.Custom}>
								{String.equalCaseInsensitive(activeTab, DashboardWidgetTab.Custom) && (
									<>
										<div className="d-flex flex-wrap">
											{(!isEditMode ||
												(isEditMode &&
													String.equalCaseInsensitive(
														formState.widgetType,
														ApiTypes.DashboardWidgetTypes.CountList
													))) && (
												<WidgetTypeButton
													label={localizationService.getLocalizedString(
														'dashboard.widgetDetails.countList'
													)}
													onClick={() => {
														onChangeChart(ApiTypes.DashboardWidgetTypes.CountList);
													}}
													isActive={String.equalCaseInsensitive(
														formState.widgetType,
														ApiTypes.DashboardWidgetTypes.CountList
													)}
													isCustom={true}
													iconSource={
														<ChartCountListSvg
															isActive={String.equalCaseInsensitive(
																formState.widgetType,
																ApiTypes.DashboardWidgetTypes.CountList
															)}
														/>
													}
												/>
											)}
											{(!isEditMode ||
												(isEditMode &&
													String.equalCaseInsensitive(
														formState.widgetType,
														ApiTypes.DashboardWidgetTypes.DonutChart
													))) && (
												<WidgetTypeButton
													label={localizationService.getLocalizedString(
														'dashboard.widgetDetails.donutChart'
													)}
													onClick={() => {
														onChangeChart(ApiTypes.DashboardWidgetTypes.DonutChart);
													}}
													isActive={String.equalCaseInsensitive(
														formState.widgetType,
														ApiTypes.DashboardWidgetTypes.DonutChart
													)}
													isCustom={true}
													iconSource={
														<ChartDonutSvg
															isActive={String.equalCaseInsensitive(
																formState.widgetType,
																ApiTypes.DashboardWidgetTypes.DonutChart
															)}
														/>
													}
												/>
											)}
										</div>

										{String.equalCaseInsensitive(
											formState.widgetType,
											ApiTypes.DashboardWidgetTypes.CountList
										) && getCountListDetails}
										{String.equalCaseInsensitive(
											formState.widgetType,
											ApiTypes.DashboardWidgetTypes.DonutChart
										) && getDonutChartDetails}
									</>
								)}
							</TabPane>
						</TabContent>
					</Switch>
				</div>
			</div>
			<h2 className="mt-4 widget-questions">
				{localizationService.getLocalizedString('dashboard.widgetDetails.widgetNameSectionLabel')}
			</h2>
			<TextInput
				id={nameof<FormState>(d => d.title)}
				name={nameof<FormState>(d => d.title)}
				error={formState.titleError}
				value={formState.title}
				isRequired={true}
				onChange={onChangeFormState}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.title')}
			/>

			<TextInput
				id={nameof<FormState>(d => d.subtitle)}
				name={nameof<FormState>(d => d.subtitle)}
				value={formState.subtitle}
				onChange={onChangeFormState}
				label={localizationService.getLocalizedString('dashboard.widgetDetails.subtitle')}
			/>

			{getFooter}
		</div>
	);

	const getWidgetPreviewSection = (
		<div className="col px-5 py-4 preview-section">
			<h1 className="preview-header">
				{localizationService.getLocalizedString('dashboard.widgetDetails.preview')}
			</h1>
			<section className="preview-chart-section">
				<div className="d-flex justify-content-between">
					<h3>
						{formState.title
							? formState.title
							: localizationService.getLocalizedString('dashboard.widgetDetails.title')}
					</h3>
					<div>
						<button className="font-size-18px-bold">&#8230;</button>
					</div>
				</div>
				<small>{activeTab === DashboardWidgetTab.Custom ? formState.subtitle : previewSubtitle}</small>

				{formState.widgetType === undefined && (
					<div className="empty-chart d-table">
						<div className="d-table-cell align-middle text-center">
							{localizationService.getLocalizedString('dashboard.widgetDetails.previewEmptyWidgetText')}
						</div>
					</div>
				)}

				{String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.CountList) && (
					<>
						{countListItems.length > 0 ? (
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr className="lookup-row lookup-header-row font-size-14px-semibold">
											<th></th>
											<th></th>
										</tr>
									</thead>
									<tbody>{getCountListRowsForPreview()}</tbody>
								</table>
							</div>
						) : (
							<div className="empty-chart d-table">
								<div className="d-table-cell align-middle text-center">
									{localizationService.getLocalizedString(
										'dashboard.widgetDetails.selectOptionForWidget'
									)}
								</div>
							</div>
						)}
					</>
				)}

				{String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.DonutChart) && (
					<>
						{!_.isEmpty(previewProperties) ? (
							<div className="populated-chart d-table">
								<DonutChartWidget
									dashboardWidget={{ properties: previewProperties }}
									isCustomWidget={true}
									previewFilterId={formState.filterId ? _.toNumber(formState.filterId) : 0}
								/>
							</div>
						) : (
							<div className="empty-chart d-table">
								<div className="d-table-cell align-middle text-center">
									{localizationService.getLocalizedString(
										'dashboard.widgetDetails.selectOptionForWidget'
									)}
								</div>
							</div>
						)}
					</>
				)}
				{String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.BarChart) &&
					!_.isEmpty(previewProperties) && (
						<div className="populated-chart d-table">
							<BarChartWidget
								dashboardWidget={{ properties: previewProperties, dateFilterValue: dateFilterValue }}
								onchange={widgetPreviewChanged}
							/>
						</div>
					)}
				{String.equalCaseInsensitive(formState.widgetType, ApiTypes.DashboardWidgetTypes.LineChart) &&
					!_.isEmpty(previewProperties) && (
						<div className="populated-chart d-table">
							<LineChartWidget
								dashboardWidget={{ properties: previewProperties, dateFilterValue: dateFilterValue }}
								onchange={widgetPreviewChanged}
							/>
						</div>
					)}
			</section>
		</div>
	);

	return (
		<div className="d-flex justify-content-between height-100">
			{getWidgetDetailsSection}
			{getWidgetPreviewSection}
		</div>
	);
};

export default DashboardWidgetDetails;
