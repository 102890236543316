import React from 'react';
import { urlService, apiService, localizationService } from 'src/services';
import * as LinkoTypes from '@rcp/types';
import _ from 'lodash';

const PrivacyPolicyComponent: React.FunctionComponent = () => {
	const [privacyPolicy, setPrivacyPolicy] = React.useState<LinkoTypes.PrivacyPolicy>();

	React.useEffect(() => {
		let url = `${urlService.getApiBaseUrl()}/PrivacyPolicy`;
		apiService.getResource(url).then((response: any) => {
			setPrivacyPolicy(response);
		});
	}, []);

	return (
		<div className="page">
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('screen.labels.privacyPolicy')}</h1>
			</div>
			<div className="page-wrapper">
				<div className="main">
					<section>
						{privacyPolicy && (
							<span
								id={`privacyPolicyContent_${_.toString(privacyPolicy.privacyPolicyId)}`}
								dangerouslySetInnerHTML={{ __html: privacyPolicy.content }}
							/>
						)}
					</section>
				</div>
			</div>
		</div>
	);
};

export const PrivacyPolicy = PrivacyPolicyComponent;
