import React from 'react';
import { DropDownOption, FacilityBulkNoticeSteps, GeneralNoticeTemplate, LookupType } from '@rcp/types';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { apiService, localizationService, Resource, urlService, validationService } from 'src/services';
import { alertService } from 'src/redux';
import { SingleSelectDropdown } from 'src/components/widgets';

const { STEP1, STEP2, STEP3, STEP4 } = FacilityBulkNoticeSteps;

const initialFormField = {
	noticeTemplateId: '',
	templateNameError: ''
};

export const Step1 = () => {
	const stepperContext = React.useContext(StepperContext);
	const [formField, setFormField] = React.useState(initialFormField);
	const [generalNoticeListDropDown, setGeneralNoticeListDropDown] = React.useState([] as DropDownOption[]);

	React.useEffect(() => {
		const step1Data = stepperContext.getData(STEP1);
		step1Data && setFormField({ ...step1Data, templateNameError: '' });
		let lookupUrlResolver = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/${
			LookupType.GeneralNoticeTemplate
		}?includeInactive=false`;
		apiService
			.getResource<GeneralNoticeTemplate[]>(lookupUrlResolver)
			.then((response: GeneralNoticeTemplate[]) => {
				setGeneralNoticeListDropDown(
					response.map(data => {
						return {
							label: data.templateName,
							value: data.noticeTemplateId
						};
					}) as DropDownOption[]
				);
			})
			.catch(err => {
				alertService.addError(err.message);
			});
	}, []);

	const disableOtherSteps = () => {
		let step2State = { ...stepperContext.getStep(STEP2), completed: false, data: null };
		stepperContext.updateStep(STEP2, step2State);
		let step3State = { ...stepperContext.getStep(STEP3), completed: false, data: null };
		stepperContext.updateStep(STEP3, step3State);
		let step4State = { ...stepperContext.getStep(STEP4), completed: false, data: null };
		stepperContext.updateStep(STEP4, step4State);
	};

	const onChangeFormField = (e: any) => {
		setFormField({ ...formField, [e.target.name]: e.target.value });
		disableOtherSteps();
	};

	const onClickNext = (e: any) => {
		let newState = { ...formField };
		validationService.validateRequiredField(
			newState,
			'noticeTemplateId',
			'templateNameError',
			localizationService.getLocalizedString('bulkNotice.noticeTemplate')
		);
		setFormField(newState);
		const step1Data = stepperContext.getData(STEP1);
		const isDataChanged = step1Data
			? step1Data.noticeTemplateId === newState.noticeTemplateId
				? false
				: true
			: true;
		if (!newState.templateNameError) {
			alertService.clearAllMessages();
			stepperContext.resolve({ ...formField, isDataChanged });
		} else {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}
	};
	return (
		<StepperContent
			id={STEP1}
			className="w-100 mr-4"
			actions={
				<React.Fragment>
					<StepperAction type="button" id="btn-next" className="btn btn-link" onClick={onClickNext}>
						{localizationService.getLocalizedString('bulkNotice.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<SingleSelectDropdown
				id="noticeTemplateId"
				name="noticeTemplateId"
				label={localizationService.getLocalizedString('bulkNotice.noticeTemplate')}
				isRequired
				value={formField.noticeTemplateId}
				onChange={onChangeFormField}
				options={generalNoticeListDropDown}
				error={formField.templateNameError}
			/>
		</StepperContent>
	);
};
