import React from 'react';

interface Props {}

export const IncidentSvg: React.FC<Props> = props => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
			<path
				id="incident"
				d="M1,14.666H15.666L8.333,2Zm8-2H7.666V11.333H9ZM9,10H7.666V7.333H9Z"
				transform="translate(-1 -2)"
				fill="#444"
			/>
		</svg>
	);
};
