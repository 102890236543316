import React from 'react';
import { History } from 'history';
import 'src/components/authority/shared/settings/authority-settings.scss';
import { localizationService, navigateTo, urlService } from 'src/services';
import { Translate } from 'src/components/widgets/translate/translator';

interface OwnProps {
	id: string;
	title: string;
	description: string;
	link: string;
	iconSource: JSX.Element;
	history: History;
	hidden?: boolean;
}

type Props = OwnProps;

export const SettingCard: React.SFC<Props> = props => {
	const onClick = (e: any) => {
		if (isActive()) {
			navigateTo(props.history, props.link, e);
		}
	};

	const isActive = () => {
		return props.link && props.link !== '/#';
	};

	const getSettingCardDescription = () => {
		return isActive() ? props.description : localizationService.getLocalizedString('screen.labels.comingSoon');
	};

	return (
		<div className="col-sm-4 mb-3" hidden={isActive() ? props.hidden : true}>
			<div className="form-row setting-card mr-3" onClick={onClick} id={props.id}>
				<a href={props.link} className="ai-link" onClick={(e: any) => navigateTo(props.history, props.link, e)}>
					{props.iconSource}
				</a>
				<div className="group col mt-n1 ml-3">
					<div className="setting-card-title">
						<a
							href={props.link}
							className="ai-link"
							onClick={(e: any) => navigateTo(props.history, props.link, e)}>
							<Translate>{props.title}</Translate>
						</a>
					</div>
					<p className="setting-card-description">
						<Translate>{getSettingCardDescription()}</Translate>
					</p>
				</div>
			</div>
		</div>
	);
};
