import React from 'react';

interface Props {
	posClass?: string;
	pathClass?: string;
	title?: string;
	size?: number;
	hasSlateFill?: boolean;
	fillColor?: string;
}

export const FacilitySvg: React.FC<Props> = props => {
	const pStyle = {
		fill: props.hasSlateFill ? '#4b6a88' : props.fillColor ? props.fillColor : 'white'
	};

	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? '16'}
			height={props.size ?? '16'}
			viewBox="0 0 16 16"
			style={pStyle}>
			<title>{props.title}</title>
			<path
				style={pStyle}
				d="M4.873.356a28.656 28.656 0 0 1 .377 3.019 2.471 2.471 0 0 1-1.615 2.451l.3 5.58a.564.564 0 0 1-.56.595h-1.5a.564.564 0 0 1-.562-.593l.3-5.58A2.47 2.47 0 0 1 0 3.375 28.571 28.571 0 0 1 .377.356C.452-.12 1.439-.127 1.5.382v3.309c.03.08.354.075.375 0 .033-.59.185-3.262.187-3.323.077-.487 1.048-.487 1.123 0 0 .063.155 2.73.187 3.323.021.075.347.08.375 0V.382c.061-.506 1.05-.5 1.125-.026Zm2.794 6.7-.352 4.338a.562.562 0 0 0 .56.609h1.312a.561.561 0 0 0 .562-.562V.562a.563.563 0 0 0-.562-.561C7.254.001 4 4.183 7.666 7.052Z"
			/>
		</svg>
	);
};
