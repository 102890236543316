import React, { useEffect, useState } from 'react';
import { apiService, localizationService, urlService } from 'src/services';
import { EventType, LookupType, SubLookupType } from '@rcp/types';
import { EventTypeSettingUrlResolver, LookupState, LookupsWidget, SettingAction } from '../../../../widgets/lookup';
import { useDispatch } from 'react-redux';
import { loadAuthorityEventCategories, loadFrequencyList, useReduxSelector } from 'src/redux';
import { LookupRowEventTypeValueConverter } from '../../../../widgets/lookup/data-converters/events-data-converter';
import { AddEventModal } from './add-event-modal';
import { AccessDeniedPage } from 'src/features/home/access-denied';
import { BackArrow } from 'src/components/widgets/back-arrow/back-arrow';

interface Props {
	embeddedMode?: boolean;
}

const EventsSettingComponent: React.FunctionComponent<Props> = props => {
	const dispatch = useDispatch();
	// eslint-disable-next-line
	const [currentEventType, setCurrentEventType] = useState({} as EventType);
	const [currentState, setCurrentLookupRow] = useState({} as LookupState);

	let dataConverter = new LookupRowEventTypeValueConverter(SubLookupType.GENERIC);
	let urlResolver = new EventTypeSettingUrlResolver(SubLookupType.GENERIC);
	let settingAction = new SettingAction(LookupType.EventType, urlResolver, dataConverter, false);

	const eventCategory = useReduxSelector(
		state =>
			state.authorityEventTypes.eventCategories.filter(i =>
				String.equalCaseInsensitive(i.code, SubLookupType.GENERIC)
			)[0]
	);

	useEffect(() => {
		dispatch(loadAuthorityEventCategories());
		dispatch(loadFrequencyList());
	}, []);

	const toggleShow = () => {
		if (currentState.showModal) {
			setCurrentLookupRow({
				showModal: false,
				newLookupRow: {},
				lookupRow: {}
			} as LookupState);
			setCurrentEventType({});
		} else {
			setCurrentLookupRow({ ...setCurrentLookupRow, showModal: true } as LookupState);
		}
	};

	const onAddButtonClicked = () => {
		setCurrentEventType({
			...currentEventType,
			eventCategoryId: eventCategory.lookupId
		});
		toggleShow();
	};

	const onUpdateEventType = async (id: any) => {
		let url = urlResolver.getSettingUrls().updateUrl(id);
		const eventTypeData = await apiService.getResource<EventType>(url);
		setCurrentEventType({ ...eventTypeData });
		toggleShow();
	};

	const getModalContent = () => {
		return (
			<AddEventModal
				toggleModal={toggleShow}
				isCreate={!currentEventType.eventTypeId}
				urlResolver={urlResolver}
				eventType={currentEventType}
			/>
		);
	};

	function getEventsSection() {
		return (
			<div className="row">
				<div className="col-lg-4 settings-info">
					<strong>{localizationService.getLocalizedString('eventType.eventTypes')}</strong>
					<p>{localizationService.getLocalizedString('authoritySetting.eventsDesc')}</p>
				</div>
				<div className="col-lg-8">
					<section>{getEventTypeList()}</section>
				</div>
			</div>
		);
	}

	function getEventTypeList() {
		return (
			<LookupsWidget
				id="events"
				name="Events"
				className="col-sm-12"
				label={localizationService.getLocalizedString('authoritySetting.eventType')}
				descriptionLabel={localizationService.getLocalizedString('eventType.scheduled')}
				editDescription={false}
				lookupState={currentState}
				hideActiveStatus={false}
				showDescriptionColum={true}
				hideAddModal={true}
				onAddButtonClick={onAddButtonClicked}
				hideEditModal={true}
				onEditButtonClick={onUpdateEventType}
				settingAction={settingAction}
				editorModal={getModalContent()}
				embeddedMode={props.embeddedMode}
			/>
		);
	}
	return (
		<>
			{props.embeddedMode ? (
				getEventTypeList()
			) : (
				<div className="page">
					<div className="page-header">
						<BackArrow />
						<h1>{localizationService.getLocalizedString('authoritySetting.events')}</h1>
					</div>
					<div className="page-wrapper">
						<div className="main settings">{getEventsSection()}</div>
					</div>
				</div>
			)}
		</>
	);
};

export default EventsSettingComponent;
