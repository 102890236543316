import * as ApiTypes from '@rcp/types';

export interface IppAASIndustrialPretreatmentSettingsState {
	data: ApiTypes.IppAASIndustrialPretreatmentSettingsData;
	isSaveButtonEnabled:boolean;
	notification:Object;
	fileUploadAttachmentTypes:ApiTypes.IppAttachmentType[];
}

export const initialIppAASIndustrialPretreatmentSettingsState: IppAASIndustrialPretreatmentSettingsState = {
	data: {
			attachmentType:"",
			maxDaysToRepudiateAfterReportEndDate:"",
			resultQualifier:"",
			sampleFlowUnits:"",
			createSampleNameUsing:"",
			complianceDeterminationDate:"",
			massLoadingConversionFactor:"",
			massLoadingResults:"",
			decimalPlacesForLoadingCalculations:"",
			availableAuthorityLicenses:"",
			authorityLicensesInUse:"",
			availableIndustryLicenses:"",
			industryLicensesInUse:"",
			industryUsersCountPerIndustry:"",
			contactNameOnEmails:"",
			contactPhoneOnEmails:"",
			contactEmailAddressOnEmails:""
		},
		fileUploadAttachmentTypes:[],
		isSaveButtonEnabled:true,
		notification:{msg:'', type:'success'}
};
