import React, { useContext } from 'react';
import _ from 'lodash';
import './authentication-component.scss';
import { AlertMessageType, temporaryUserChangePassword, useReduxDispatch } from 'src/redux';
import { validationService, localizationService, urlService } from 'src/services';
import { TextInput } from 'src/components/widgets';
import { InlineAlertItem } from 'src/features/inline-alerts/inline-alert';
import { Translate } from '../widgets/translate/translator';
import { LanguageContext } from '../widgets/translate/translator-context';
import { isNewPasswordPage } from '../widgets/translate/language-select-dropdown';

interface OwnProps {
	email: string;
	oldPassword: string;
}

interface ChangePasswordFormState {
	email: string;
	oldPassword: string;
	password: string;
	confirmPassword: string;
	passwordFormatError: string;
	confirmPasswordFormatError: string;
}

type Props = OwnProps;

export const ChangePassword: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState({} as ChangePasswordFormState);
	const dispatch = useReduxDispatch();
	const { language, setLanguage } = useContext(LanguageContext);

	React.useEffect(() => {
		isNewPasswordPage(true);
	}, []);

	React.useEffect(() => {
		let newState = {} as ChangePasswordFormState;
		newState.email = props.email;
		newState.oldPassword = props.oldPassword;
		setFormState(newState);
	}, [props.email, props.oldPassword]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		const { name, value } = e.target;
		_.set(newState, name, value);
		setFormState(newState);
	};

	const changePasswordBtnClicked = async (event: any) => {
		event.preventDefault();

		if (!validateBeforeForSave()) {
			return;
		}
		let { password, oldPassword } = { ...formState };
		try {
			let signInResult = await dispatch(temporaryUserChangePassword(props.email, oldPassword, password));
			if (signInResult !== false) {
				isNewPasswordPage(false);
				urlService.signInToPortal(signInResult, language);
			}
		} catch (e) {}
	};

	const validateBeforeForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validatePasswordField(newState, 'password', 'passwordFormatError');

		if (newState.confirmPassword !== newState.password) {
			newState.confirmPasswordFormatError = localizationService.getLocalizedString('users.passwordsNotMatch');
		} else {
			_.unset(newState, 'confirmPasswordFormatError');
		}

		let isFormValid = !validationService.hasError(newState, 'passwordFormatError', 'confirmPasswordFormatError');
		if (!isFormValid) {
			setFormState(newState);
		}

		return isFormValid;
	};

	return (
		<div className="signin">
			<h1>
				<Translate>{localizationService.getLocalizedString('users.newPassword')}</Translate>
			</h1>
			<form>
				{formState.passwordFormatError || formState.confirmPasswordFormatError ? (
					<InlineAlertItem
						message={formState.passwordFormatError || formState.confirmPasswordFormatError}
						alertType={AlertMessageType.Error}
						alertContainerId="reset-signin-alert"
					/>
				) : null}
				<InlineAlertItem
					message={localizationService.getLocalizedString('users.temporaryUserLoginHint')}
					alertType={AlertMessageType.Info}
					alertContainerId="temporary-user-message"
				/>

				<div className="form-group">
					<TextInput
						type="password"
						id="password"
						name="password"
						className="w-100"
						label={localizationService.getLocalizedString('users.newPassword')}
						value={formState.password}
						onChange={changeFormState}
						isRequired={true}
						error={formState.passwordFormatError}
					/>
					<div className="form-text mt-2 ai-form-help">
						<Translate>{localizationService.getLocalizedString('users.passwordHint')}</Translate>
					</div>
				</div>
				<div className="form-group">
					<TextInput
						type="password"
						id="confirmPassword"
						name="confirmPassword"
						className="w-100"
						label={localizationService.getLocalizedString('users.confirmNewPassword')}
						value={formState.confirmPassword}
						onChange={changeFormState}
						isRequired={true}
						error={formState.confirmPasswordFormatError}
					/>
				</div>
				<p>
					<Translate>
						{localizationService.getLocalizedString(
							'users.termsAndConditionAgreementPart1',
							'users.setNewPassword'
						)}
					</Translate>
					&nbsp;
					<a href="/TermsAndConditions" target="_blank">
						<Translate>
							{localizationService.getLocalizedString('users.termsAndConditionAgreementPart2')}
						</Translate>
					</a>
					&nbsp;
					<Translate>
						{localizationService.getLocalizedString('users.termsAndConditionAgreementPart3')}
					</Translate>
					&nbsp;
					<a href="/PrivacyPolicy" target="_blank">
						<Translate>
							{localizationService.getLocalizedString('users.termsAndConditionAgreementPart4')}
						</Translate>
					</a>
				</p>

				<button id="signin-btn" className="btn ai-action btn-signin" onClick={changePasswordBtnClicked}>
					<Translate>{localizationService.getLocalizedString('users.setNewPassword')}</Translate>
				</button>
			</form>
		</div>
	);
};
