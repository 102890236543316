import React from 'react';

import * as ApiTypes from '@rcp/types';
import { EquipmentEditModal, EquipmentEditModalProp } from './equipment-editor-modal';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { localizationService } from 'src/services';
import { CustomCard } from '../../../../../widgets/custom-card/custom-card';

export interface EquipmentProp {
	equipment: ApiTypes.Equipment;
	equipmentTypes: ApiTypes.EquipmentType[];
	updateFacilityEquipment: (equipmentId: number, dataToUpdate: ApiTypes.Equipment) => Promise<boolean | undefined>;

	deleteFacilityEquipment: (equipmentId: number, equipmentType: string) => Promise<boolean | undefined>;
}

export class EquipmentCard extends React.Component<EquipmentProp, any> {
	state = {
		deleteFromFacilityModal: false,
		editModal: false
	};

	onToggleDeleteModal = () => {
		this.setState({
			deleteFromFacilityModal: !this.state.deleteFromFacilityModal
		});
	};

	onToggleEditModal = () => {
		this.setState({
			editModal: !this.state.editModal
		});
	};

	onSaveEditModal = async (equipmentToUpdate: ApiTypes.Equipment) => {
		let updateResult = await this.props.updateFacilityEquipment(
			equipmentToUpdate.equipmentId as number,
			equipmentToUpdate
		);
		if (updateResult === true) {
			this.setState({
				equipment: equipmentToUpdate
			});
		}
	};

	onDeleteFromFacility = async () => {
		await this.props.deleteFacilityEquipment(
			this.props.equipment.equipmentId as number,
			this.props.equipment.equipmentType as string
		);
		this.onToggleDeleteModal();
	};

	render() {
		let equipmentModalProp: EquipmentEditModalProp = {
			title: localizationService.getLocalizedString('equipments.editEquipment'),
			showModel: this.state.editModal,
			equipmentTypes: this.props.equipmentTypes,
			equipment: this.props.equipment,
			modalToggleFunction: this.onToggleEditModal,
			onSaveEditModal: this.onSaveEditModal,
			toggleDeleteModal: this.onToggleDeleteModal
		};

		let deleteFromFacilityModalProp: DeleteModalProp = {
			title: localizationService.getLocalizedString('equipments.deleteModalTitle'),
			message: localizationService.getLocalizedString('equipments.deleteModalMessage'),
			showModal: this.state.deleteFromFacilityModal,
			onCancelButtonClick: this.onToggleDeleteModal,
			onOkayButtonClick: this.onDeleteFromFacility,
			isDeleteButton: true,
			okayButtonText: localizationService.getLocalizedString('screen.buttons.delete')
		};

		const equipmentCardBody = (
			<>
				<div className="mb-3 d-flex justify-content-between">
					<strong>{this.props.equipment.equipmentType}</strong>

					<a
						href="#/"
						className="float-right"
						onClick={e => {
							e.preventDefault();
							this.onToggleEditModal();
						}}>
						{' '}
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
				<p className="mt-3">
					{localizationService.getLocalizedString('equipments.quantity')}:{' '}
					{this.props.equipment.equipmentQuantity}
				</p>
			</>
		);

		return (
			<div className="equipment-card-wrapper">
				<CustomCard customCardBody={equipmentCardBody} hideHeader={true} />
				<EquipmentEditModal {...equipmentModalProp} />
				<DeleteModal {...deleteFromFacilityModalProp} />
			</div>
		);
	}
}
