import React from 'react';
import { localizationService, DateUtilService } from 'src/services';
import { PopoverModal } from '../../components/widgets';
import _ from 'lodash';

import './import.scss';
import { DataImportResult } from '@rcp/types';
import { ImportResultLinks } from './import-result-links';
import { InlineAlertItem } from '../inline-alerts/inline-alert';
import { AlertMessageType } from 'src/redux';

interface Props {
	importResult: DataImportResult;
}

export const ImportResult: React.FC<Props> = props => {
	const [showModal, setShowModal] = React.useState(false);
	const showResultModal = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const modalFooterDiv = () => {
		return <></>;
	};

	const importResultErrorContent = () => {
		return (
			<>
				{localizationService.getLocalizedString(
					'cccImportFile.step3ErrorMessagePart1',
					_.toString(props.importResult.numberOfErrorRows),
					props.importResult.numberOfErrorRows > 1 ? 's' : ''
				)}
				&nbsp;
				<a href={props.importResult.errorFileUrl} target="_blank" rel="noopener noreferrer">
					{localizationService.getLocalizedString('cccImportFile.step3ErrorMessagePart2')}
				</a>
				&nbsp;
				{localizationService.getLocalizedString('cccImportFile.step3ErrorMessagePart3')}
			</>
		);
	};
	return (
		<>
			<a href="#/" onClick={showResultModal}>
				{localizationService.getLocalizedString('importHistory.view')}
			</a>

			{showModal && (
				<div className="w-100">
					<PopoverModal
						showModal={showModal}
						title={localizationService.getLocalizedString('importHistory.results')}
						footer={modalFooterDiv()}
						cancel={closeModal}
						className="import-history-grid-modal">
						<div className="w-100 d-flex flex-column justify-content-center import-file">
							<p className="text-center">
								<a href={props.importResult.originalFileUrl} target="_blank" rel="noopener noreferrer">
									{props.importResult.fileName}
								</a>
								&nbsp;
								{localizationService.getLocalizedString(
									'importHistory.importedBy',
									DateUtilService.toDisplayDate(props.importResult.creationDateTimeUtc),
									DateUtilService.toDisplayTime(props.importResult.creationDateTimeUtc),
									props.importResult.uploaderFullName
								)}
							</p>
							{props.importResult.numberOfErrorRows > 0 && (
								<InlineAlertItem
									message={importResultErrorContent()}
									alertType={AlertMessageType.Error}
								/>
							)}

							{props.importResult.numberOfSuccessRows > 0 && (
								<div className="d-flex justify-content-center">
									<InlineAlertItem
										message={localizationService.getLocalizedString(
											'cccImportFile.step3SuccessMessage',
											_.toString(props.importResult.numberOfSuccessRows),
											props.importResult.numberOfSuccessRows > 1 ? 's' : ''
										)}
										alertType={AlertMessageType.Success}
									/>
								</div>
							)}

							<ImportResultLinks
								importId={props.importResult.importId}
								importEntityResults={props.importResult.importHistories}
								className="text-center"
							/>
						</div>
					</PopoverModal>
				</div>
			)}
		</>
	);
};
