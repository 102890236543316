import React, { useState, useEffect, FC } from 'react';
import { ApiError, FacilityPortalAuthorityDetail } from '@rcp/types';
import { PopoverModal } from 'src/components';
import { alertService } from 'src/redux';
import { apiService, localizationService, Resource, urlService } from '../../../../services';
import { Translate } from 'src/components/widgets/translate/translator';

interface OwnProps {
	linkText?: string;
	authorityDetails?: FacilityPortalAuthorityDetail;
}

const initialAuthorityInformation = {
	organizationName: '',
	addressLine1: '',
	addressLine2: '',
	cityName: '',
	state: '',
	zipCode: '',
	phoneNumber: ''
};

export const AuthorityDetailsActionLink: FC<OwnProps> = props => {
	const [showModal, setShowModal] = useState(false);
	const [authorityInformation, setAuthorityInformation] = useState<FacilityPortalAuthorityDetail>(
		initialAuthorityInformation
	);

	useEffect(() => {
		!props.authorityDetails && fetchAuthorityDetails();
	}, []);

	useEffect(() => {
		setAuthorityInformation(props.authorityDetails || {});
	}, [props.authorityDetails]);

	const fetchAuthorityDetails = () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.AuthorityDetails);
		apiService
			.httpGet(url)
			.then(data => setAuthorityInformation(data))
			.catch(error => {
				let err = error as ApiError;
				alertService.addError(err.message);
			});
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setShowModal(true);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const showAuthorityDetails = () => {
		const {
			authorityName,
			addressLine1,
			addressLine2,
			cityName,
			jurisdictionCode,
			zipCode,
			phoneNumber
		} = authorityInformation;
		return (
			<>
				<p id="authority-address">
					{authorityName && <>{authorityName}</>}
					{authorityName && <br />}
					{addressLine1 && <>{addressLine1}</>}
					{addressLine1 && <br />}
					{addressLine2 && <>{addressLine2}</>}
					{addressLine2 && <br />}
					{cityName && <>{cityName + (jurisdictionCode ? ', ' : ' ')}</>}
					{jurisdictionCode && <>{jurisdictionCode + ' '}</>}
					{zipCode && <>{zipCode}</>}
				</p>
				{phoneNumber && (
					<p>
						<Translate>
							{localizationService.getLocalizedString('facilityPortal.cleanings.phoneNumber')}
						</Translate>
						&nbsp;
						<a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
					</p>
				)}
			</>
		);
	};

	return (
		<>
			<a href={'#/'} className="ai-link cursor-pointer" onClick={onEditClicked}>
				{props.linkText}
			</a>
			{showModal && (
				<PopoverModal
					withoutForm={true}
					showModal={showModal}
					title="Contact Your Authority"
					cancel={toggleModal}
					footer={<></>}>
					{showAuthorityDetails()}
				</PopoverModal>
			)}
		</>
	);
};
