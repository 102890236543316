import {
	ContactCardType,
	EntityContact,
	EntityContact_EntityType,
	FacilityNoticeTemplateSchedule,
	SendFacilityNoticeSteps
} from '@rcp/types';
import React, { useEffect } from 'react';
import _ from 'lodash';
import { alertService, loadCurrentFogFacility, loadFacilityContact, RootState, useRootStateSelector } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import { localizationService, urlService } from 'src/services';
import { SingleCheckbox } from 'src/components';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { ReactComponent as SvgWarning } from 'src/assets/img/inline-warning.svg';
import { Notification } from 'src/components/widgets/inline-notification';
import { Utils } from '../../../../../../services/utils';
import './steps.scss';
import { extractFacilityContact } from 'src/features';
import { SendNoticeContactCardContainer } from '../../../../../../features/send-notice-contacts/send-notice-contact-container';

interface FormField extends FacilityNoticeTemplateSchedule {}

const initialFormField: FormField = {
	shouldSendNoticeToFacilityAssignedToUser: false,
	selectedContactIds: []
};

const { STEP1, STEP2, STEP3, STEP4 } = SendFacilityNoticeSteps;

export const Step2: React.FC = () => {
	const stepperContext = React.useContext(StepperContext);
	const facilityContactState = useRootStateSelector(state => state.facilityContacts);
	let facilityDetail = (state: RootState) => state.fogFacility;
	let facility = useSelector(facilityDetail);
	const dispatch = useDispatch();
	const [contactList, setContactList] = React.useState<EntityContact[]>([]);
	const [selectedContactList, setSelectedContactList] = React.useState<EntityContact[]>([]);
	const [formField, setFormField] = React.useState(initialFormField);
	const [numberOfContactWithIncompleteAddress, setNumberOfContactWithIncompleteAddress] = React.useState<number>(0);
	const [isContactLoadingCompleted, setIsContactLoadingCompleted] = React.useState<boolean>(false);
	const step1Data = stepperContext.getData(STEP1);
	const pageLoading = useSelector((state: RootState) => state.pageLoadingState);

	useEffect(() => {
		facilityContactState && prepareFacilityContacts();
	}, [facilityContactState, facility]);

	useEffect(() => {
		const step2Data = stepperContext.getData(STEP2);
		if (step2Data) {
			setFormField({ ...step2Data });
			setSelectedContactList(step2Data.selectedContactList || []);
		}
		dispatch(loadCurrentFogFacility());
	}, []);

	useEffect(() => {
		if (step1Data && step1Data.deviceContactCount === 0) {
			let step3State = { ...stepperContext.getStep(STEP3), completed: false, data: null };
			stepperContext.updateStep(STEP3, step3State);
			let step4State = { ...stepperContext.getStep(STEP4), completed: false, data: null };
			stepperContext.updateStep(STEP4, step4State);
		}
	}, [step1Data]);

	const onChange = (e: any) => {
		let { name, value } = e.target;
		if (e.target.type === 'checkbox') value = e.target.checked;
		setFormField({ ...formField, [name]: value });
	};

	const prepareFacilityContacts = () => {
		let newContacts: EntityContact[] = [];
		let contactsWithIncompleteAddress = [];
		if (!_.isEmpty(facilityContactState.facilityContactList.fogFacilityContacts)) {
			newContacts = newContacts.concat(
				facilityContactState.facilityContactList.fogFacilityContacts.map(obj => ({
					...obj,
					entityType: EntityContact_EntityType.FogFacility,
					sendNoticeIds: obj.jsonCleaningNotices
						? JSON.parse(obj.jsonCleaningNotices as string)
								.filter((a: any) => a.SendNotices == true)
								.map((b: any) => b.DeviceId)
						: []
				}))
			);
		}
		if (!_.isEmpty(facilityContactState.facilityContactList.fogDeviceContacts)) {
			let deviceContacts = facilityContactState.facilityContactList.fogDeviceContacts;
			deviceContacts.forEach((deviceContact, index) => {
				var existingContact = newContacts.find(
					a => a.contactId == deviceContact.contactId && a.contactTypeCode == deviceContact.contactTypeCode
				);
				if (!existingContact) {
					let newContact = deviceContact;
					if (newContact.sendNotices) {
						newContact.sendNoticeIds = [];
						newContact.sendNoticeIds.push(newContact.entityId as number);
					}
					newContact.entityGroupIds = [];
					newContacts.push(newContact);
				}
				if (existingContact) {
					if (!existingContact.sendNoticeIds) {
						existingContact.sendNoticeIds = [];
					}
					if (
						deviceContact.sendNotices &&
						!existingContact.sendNoticeIds.includes(deviceContact.entityId as number)
					) {
						existingContact.sendNoticeIds.push(deviceContact.entityId as number);
					}
					if (existingContact.entityType == EntityContact_EntityType.FogDevice) {
						existingContact.entityGroupIds.push([
							deviceContact.entityContactId as number,
							deviceContact.entityId as number
						]);
					}
				}
			});
		}
		newContacts.sort((a, b) => {
			if (a.contactTypeCode && b.contactTypeCode) {
				if (a.contactTypeCode < b.contactTypeCode) {
					return -1;
				}
				if (a.contactTypeCode > b.contactTypeCode) {
					return 1;
				}
			}
			return 0;
		});

		let primaryContact = newContacts.find(a => a.isPrimary);
		if (primaryContact) {
			newContacts = newContacts.filter(a => !a.isPrimary);
			newContacts.unshift(primaryContact);
		}

		if (!_.isEmpty(facility) && facility) {
			let facilityContact: EntityContact = extractFacilityContact(facility.facility);
			newContacts.unshift(facilityContact);
		}

		newContacts.filter((contact: EntityContact) => {
			let { addressLine1, addressCity, addressJurisdictionId, addressZipCode } = contact;
			if (!addressLine1 || !addressCity || !addressJurisdictionId || !addressZipCode) {
				contactsWithIncompleteAddress.push(contact);
			}
		});
		setNumberOfContactWithIncompleteAddress(contactsWithIncompleteAddress.length);
		setContactList(newContacts);
		setIsContactLoadingCompleted(true);
	};

	const onChangeRecipient = (e: any) => {
		let newRecipient = [...(formField.selectedContactIds as string[])];
		let selectedContacts = [...selectedContactList];
		const selectedRecipientId = e.target.id;
		const selectedRecipient = contactList.find(
			contact =>
				contact.contactId &&
				contact.contactTypeId &&
				contact.contactId + '-' + contact.contactTypeId + '' === e.target.id
		);
		if (e.target.checked) {
			newRecipient.push(selectedRecipientId);
			selectedRecipient && selectedContacts.push(selectedRecipient);
		} else {
			newRecipient.splice(newRecipient.indexOf(selectedRecipientId), 1);
			selectedContacts = selectedContacts.filter(
				contact =>
					contact.contactId &&
					contact.contactTypeId &&
					contact.contactId + '-' + contact.contactTypeId + '' !== selectedRecipientId
			);
		}
		setFormField({ ...formField, selectedContactIds: newRecipient });
		setSelectedContactList(selectedContacts);
	};

	const onClickNext = () => {
		if (!Utils.isDeviceCleaningNotice(step1Data.noticeType)) {
			if (formField.selectedContactIds && formField.selectedContactIds.length > 0) {
				stepperContext.resolve({ ...formField, selectedContactList });
			} else {
				alertService.addError(localizationService.getLocalizedString('facility.noContactSelected'));
			}
		} else {
			stepperContext.resolve({ ...formField });
		}
	};

	const shouldSendEmailToFacilityUser = () =>
		step1Data.deviceContactCount === 0 ? (
			<Notification
				icon={<SvgWarning />}
				className="recipient-warning d-inline-block px-2"
				message={localizationService.getLocalizedString('authoritySetting.noticesSettings.noContactsFound')}
				showCloseButton={false}
			/>
		) : (
			<>
				<p>{localizationService.getLocalizedString('extractor.cleaningNotice.chooseRecipientDesc')}</p>
				<SingleCheckbox
					id="shouldSendNoticeToFacilityAssignedToUser"
					name="shouldSendNoticeToFacilityAssignedToUser"
					checked={formField.shouldSendNoticeToFacilityAssignedToUser}
					onChange={onChange}
					label={localizationService.getLocalizedString(
						'extractor.cleaningNotice.chooseRecipientCheckBoxDesc'
					)}
				/>
			</>
		);

	const getContactCard = () => (
		<>
			{(numberOfContactWithIncompleteAddress > 0 || !contactList.length) &&
				isContactLoadingCompleted &&
				pageLoading.loadingCounter === 0 && (
					<Notification
						icon={<SvgWarning />}
						className="recipient-warning d-inline-block px-2"
						message={localizationService.getLocalizedString(
							`authoritySetting.noticesSettings.${
								!contactList.length
									? 'noContactsFound'
									: numberOfContactWithIncompleteAddress === 1
									? 'incompleteAddressWarning'
									: 'incompleteAddressWarningMultipleContacts'
							}`,
							numberOfContactWithIncompleteAddress + ''
						)}
						showCloseButton={false}
					/>
				)}
			<div className={`${contactList.length ? 'row mt-4' : ''}`}>
				{contactList.map((item: EntityContact, index: number) => (
					<div className="d-flex col-lg-4" key={index}>
						<SingleCheckbox
							id={
								item.contactId && item.contactTypeId
									? item.contactId + '-' + item.contactTypeId + ''
									: String.equalCaseInsensitive(item.contactType, 'Facility')
									? `${item.entityId}-facility`
									: ''
							}
							name="selectedContactId"
							checked={
								item.contactId && item.contactTypeId
									? formField.selectedContactIds!.includes(
											item.contactId + '-' + item.contactTypeId + ''
									  )
									: String.equalCaseInsensitive(item.contactType, 'Facility')
									? formField.selectedContactIds!.includes(`${item.entityId}-facility`)
									: false
							}
							isDisabled={isCheckBoxDisabled(item)}
							onChange={(e: any) => onChangeRecipient(e)}
						/>
						<div className={`facility-notice-card ${isCheckBoxDisabled(item) ? 'disabled-feedback' : ''}`}>
							<SendNoticeContactCardContainer
								entityContact={item}
								key={index}
								id={index}
								reload={() => dispatch(loadFacilityContact(urlService.getFogFacilityId()))}
								contactCardType={ContactCardType.FogFacility}
								showSpan
								switchSendNotice={() => {}}
							/>
						</div>
					</div>
				))}
			</div>
		</>
	);

	const onClickBack = () => stepperContext.goAt(STEP1);

	const isCheckBoxDisabled = (contact: EntityContact) => {
		let { addressLine1, addressCity, addressJurisdictionId, addressZipCode } = contact;
		if (!addressLine1 || !addressCity || !addressJurisdictionId || !addressZipCode) {
			return true;
		}
		return false;
	};

	return (
		<StepperContent
			className="w-100"
			actions={
				<React.Fragment>
					<StepperAction type="button" className="btn btn-link" id="btn-back" onClick={onClickBack}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
					</StepperAction>
					<StepperAction
						disabled={
							Utils.isDeviceCleaningNotice(step1Data.noticeType)
								? step1Data.deviceContactCount === 0
								: !contactList.length
						}
						type="button"
						className="btn btn-link"
						id="btn-next"
						onClick={onClickNext}>
						{localizationService.getLocalizedString('authoritySetting.noticesSettings.next')}
					</StepperAction>
				</React.Fragment>
			}>
			<>
				{!Utils.isDeviceCleaningNotice(step1Data.noticeType)
					? getContactCard()
					: shouldSendEmailToFacilityUser()}
			</>
		</StepperContent>
	);
};
