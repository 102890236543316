import React from 'react';
import { tokenService } from 'src/services';
import { translateService } from 'src/services/translate-service';
import { Translate } from './translate/translator';
import { LanguageContext } from './translate/translator-context';

interface Props {
	id: string;
	name: string;
	label: string | JSX.Element;
	isRequired?: boolean;
	isDisabled?: boolean;
	value?: string;
	error?: string;
	hintText?: string;
	onChange?(e: any): void;
	onBlur?(text: string, field?: string): void;
	onEnterKeyPressed?(text: string, field?: string): void;
	isFullWidth?: boolean;
	rows?: number;
	maxLength?: number;
	className?: string;
	placeholder?: string;
	helpText?: string;
	doNotTranslate?: boolean;
	doNotTranslateValue?: boolean;
}

export const TextAreaInput: React.SFC<Props> = props => {
	const { language } = React.useContext(LanguageContext);
	const [translatedValue, setTranslatedValue] = React.useState<string | number | undefined>(props.value || undefined);

	React.useEffect(() => {
		if (!props.doNotTranslateValue && language && language.code !== 'en' && props.isDisabled) {
			translateService.translateString(props.value).then(res => {
				setTranslatedValue(res);
			});
		} else if (translatedValue !== props.value) {
			setTranslatedValue(props.value);
		}
	}, [props.doNotTranslateValue, props.value, language]);

	const changeValue = (e: any) => {
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const handleKeyPressed = (e: any) => {
		if (e.key === 'Enter') {
			changeValue(e);
			if (props.onEnterKeyPressed) {
				props.onEnterKeyPressed(e);
			}
		}
	};

	const handleOnBlur = (e: any) => {
		changeValue(e);
		if (props.onBlur) {
			props.onBlur(e);
		}
	};

	const handleChange = (e: any) => {
		let { value } = e.target;
		setTranslatedValue(value);

		changeValue(e);
	};

	let defaultFullWidthClass = 'form-group';
	if (props.className) {
		defaultFullWidthClass = props.className;
		defaultFullWidthClass = defaultFullWidthClass + (tokenService.hasAccessToIppPortal() ? ' form-group ' : '');
	}
	return (
		<div
			className={
				(props.isFullWidth ? defaultFullWidthClass : 'col-sm-6 ') +
				(props.isRequired ? ' required' : '') +
				(props.className ? ' ' + props.className : '')
			}>
			{props.isDisabled ? (
				<label className="disabled-feedback" htmlFor={props.id}>
					<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
				</label>
			) : (
				<label htmlFor={props.id}>
					<Translate doNotTranslate={props.doNotTranslate}>{props.label}</Translate>
				</label>
			)}
			<textarea
				id={props.id}
				name={props.name}
				className={'form-control value' + (props.error ? ' is-invalid' : '')}
				value={translatedValue || ''}
				onChange={handleChange}
				onBlur={handleOnBlur}
				onKeyPress={handleKeyPressed}
				required={props.isRequired === true ? true : false}
				disabled={props.isDisabled === true ? true : false}
				autoComplete="off"
				rows={props.rows}
				maxLength={props.maxLength}
				placeholder={props.placeholder || ''}
			/>
			{props.error && (
				<span className="invalid-feedback d-inline-block w-auto mr-1">
					<Translate doNotTranslate={props.doNotTranslate}>{props.error}</Translate>
				</span>
			)}
			{props.hintText && (
				<span className="ai-form-help d-inline-block mt-1">
					<Translate doNotTranslate={props.doNotTranslate}>{props.hintText}</Translate>
				</span>
			)}
		</div>
	);
};
