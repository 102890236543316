import { DropDownOption } from '@rcp/types';
import _ from 'lodash';
import React from 'react';
import { Logger } from 'src/services';

interface Props {
	options: DropDownOption[];
	value: string;
	onValueChange?: (v: string) => void;
}

export const ExpandableOption: React.FC<Props> = props => {
	const [expand, setExpand] = React.useState(false);
	const [value, setValue] = React.useState(props.value);

	const expandOptions = (e: any) => {
		setExpand(!expand);
	};

	const onClickExpandedOption = (e: any, v: string) => {
		setExpand(false);
		if (e) {
			e.preventDefault();
			e.stopPropagation();

			let selectedOption = props.options.find(o => o.value === v);
			if (selectedOption) {
				setValue(selectedOption.value);
			}

			if (props.onValueChange) {
				props.onValueChange(v);
			}
		}
	};
	let currentOption = props.options.find(o => o.value === props.value) as DropDownOption;
	if (!currentOption) {
		Logger.error(`Selected value ${props.value} is not in option list.`);
	}
	return (
		<>
			<span className={`dropdown-toggle ${expand ? 'open' : ''}`} onClick={expandOptions}>
				{currentOption.label}
			</span>
			{expand && (
				<ul className={`expandable-menu`}>
					{props.options.map((option, index) => {
						return (
							<li
								key={`dropdown-${index}`}
								className={`dropdown-item ${option.value === value ? 'selected' : ''}`}
								onClick={e => {
									onClickExpandedOption(e, option.value);
								}}>
								{option.label}
							</li>
						);
					})}
				</ul>
			)}
		</>
	);
};
