import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { Button } from 'reactstrap';
import * as ApiTypes from '@rcp/types';
import { FilterOperator } from '@rcp/types';
import { localizationService } from 'src/services';
import * as actionCreators from '../../redux/filter';
import { alertService, ApplicationState, FilterState, IsFilterNameValid } from 'src/redux';

import { FilterRow, getInitialFilterItem } from './filter-row';
import { FilterDefinition, FilterItem, FilterValidationBody } from '../../services/data-types/filter-types';
import { DeleteModal, DeleteModalProp } from '../../components/widgets';
import _ from 'lodash';
import { filterService } from './filter-service';
import { ReactComponent as SvgInfo } from 'src/assets/img/info-circle-solid.svg';
import { TakeOverModal } from '../../components/widgets/takeover-modal';

export interface OwnProps {
	isCreateNew: boolean;
	title: string;
	widgetCount: number;
	draftFilter?: ApiTypes.DraftFilter;
	showDeleteConfirmationModal?: boolean;
	modalToggleFunction: (isVisible: boolean) => void;
	applyBtnClicked: (
		filterSaved: ApiTypes.Filter | undefined,
		draftFilterSaved: ApiTypes.DraftFilter | undefined
	) => void;
	filterDeleted: () => void;
	filterDefinition?: FilterDefinition;
	reportPackageElementTypeId?: string;
	reportPackageElementType?: string;
	draftFilterApplyTo?: ApiTypes.FilterApplyTo;
}

interface DispatchProps {
	updateSavedFilter: (
		filterId: number,
		applyTo: string,
		filterName: string,
		filterDefinition: FilterDefinition
	) => ApiTypes.Filter;
	createOrUpdateDraftFilter: (
		applyTo: string,
		filterName: string,
		filterDefinition: FilterDefinition
	) => ApiTypes.DraftFilter;
	deleteFilter: (filterId: number) => Promise<void>;
	deleteDraftFilter: (draftFilterId: number) => Promise<void>;
}

interface StateProps extends FilterState {}

type FilterEditModalProp = OwnProps & DispatchProps & StateProps;

class FilterEditModalComp extends Component<FilterEditModalProp, any> {
	isEditing = false;

	constructor(props: FilterEditModalProp) {
		super(props);
		this.state = {
			filterName: '',
			showDeleteConfirmationModal: false,
			filterDefinition: props.filterDefinition || new FilterDefinition([getInitialFilterItem()] as FilterItem[])
		};
	}

	componentDidMount() {
		let filterName = this.state.filterName;
		if (!filterName) {
			if (this.props.filterDefinition && this.props.filterDefinition.filterName) {
				this.setState({
					filterName: this.props.filterDefinition.filterName
				});
			} else {
				filterService.getNextFilterName().then((nextFilterName: string) => {
					this.setState({
						filterName: nextFilterName
					});
				});
			}
		}
		if (this.props.filterDefinition) {
			this.setState({
				filterDefinition: this.props.filterDefinition
			});
			this.isEditing = this.props.filterDefinition !== null;
		}
	}

	clearModalValue = () => {
		this.setState({
			filterName: '',
			filterNameError: false,
			filterDefinition: new FilterDefinition([getInitialFilterItem()] as FilterItem[])
		});
	};

	validateFiltersBeforeSave = async () => {
		let filterDefinition = _.cloneDeep(this.state.filterDefinition);
		filterDefinition.getAllFilterItems().map(filterService.validateFilterItem);
		let isFilterInvalid = filterDefinition.getAllFilterItems().some(filterService.isFilterItemInvalid);
		let filterToValid = new FilterValidationBody(
			this.state.filterName,
			filterDefinition.filterId ? false : true,
			filterDefinition.filterId,
			filterDefinition.draftFilterId,
			filterService.applyTo
		);
		let isFilterNameValid = await IsFilterNameValid(filterToValid);
		let errorState = {
			isFilterInvalid: isFilterInvalid,
			filterNameError: !isFilterNameValid
		};

		this.setState({
			filterDefinition: filterDefinition,
			...errorState
		});
		return errorState;
	};

	onDeleteBtnClicked = () => {
		this.setState({
			showDeleteConfirmationModal: true
		});
	};

	onApplyBtnClicked = async () => {
		let errorState = await this.validateFiltersBeforeSave();
		if (errorState.isFilterInvalid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		} else if (errorState.filterNameError) {
			if (!this.state.filterName) {
				alertService.addError(localizationService.getLocalizedString('screen.filterModal.emptyFilterName'));
			} else {
				alertService.addError(localizationService.getLocalizedString('screen.filterModal.invalidFilterName'));
			}
		} else {
			let filterName = this.state.filterName;
			let filterDefinition = this.state.filterDefinition;
			filterService.htmlEncode(filterDefinition);
			let applyTo = filterService.applyTo;

			//if create a new filter, or current draftFilterId != null,
			if (this.props.isCreateNew || filterDefinition.draftFilterId) {
				let savedDraftFilter = await this.props.createOrUpdateDraftFilter(
					applyTo,
					filterName,
					filterDefinition
				);
				if (savedDraftFilter.draftFilterId) {
					this.clearModalValue();
					!this.props.reportPackageElementTypeId &&
						filterService.updateSavedDraftFilterUrl(savedDraftFilter.draftFilterId);
					this.props.applyBtnClicked(undefined, savedDraftFilter);
					filterService.updateSavedDraftFilterUrl(savedDraftFilter.draftFilterId);
				}
			} else {
				// updating an existing filter
				let savedFilter = await this.props.updateSavedFilter(
					filterDefinition.filterId,
					applyTo,
					filterName,
					filterDefinition
				);
				if (savedFilter.filterId) {
					this.clearModalValue();
					filterService.updateSavedFilterUrl(savedFilter.filterId);
					this.props.applyBtnClicked(savedFilter, undefined);
				}
			}

			this.props.modalToggleFunction(false);
		}
	};

	onCancelBtnClick = () => {
		this.props.modalToggleFunction(false);
		this.clearModalValue();
	};

	onAddFilterItemBtnClicked = () => {
		let filterItem = getInitialFilterItem();
		let filterDefinition = this.state.filterDefinition as FilterDefinition;
		filterDefinition.addOrUpdateFilterItem(filterItem);
		this.setState({
			filterDefinition: filterDefinition
		});
	};

	onFilterNameChanged = (e: any) => {
		this.setState({
			filterName: e.target.value
		});
	};

	deleteFilterItem = (filterItem: FilterItem) => {
		let filterDefinition = this.state.filterDefinition;
		filterDefinition.deleteFilterItemByKey(filterItem.key);
		this.setState({
			filterDefinition: filterDefinition
		});
	};

	updateFilterItem = (filterItem: FilterItem) => {
		let filterDefinition = this.state.filterDefinition as FilterDefinition;
		filterDefinition.addOrUpdateFilterItem(filterItem);
		this.setState({
			filterDefinition: filterDefinition
		});
	};

	cancelDeleteFilterConformationModel = () => {
		this.setState({
			showDeleteConfirmationModal: false
		});
	};

	deleteFilterById = (filterId?: number): Promise<void> => {
		if (!filterId) {
			return Promise.resolve();
		}

		return this.props.deleteFilter(filterId);
	};

	deleteDraftFilterById = (draftFilterId?: number): Promise<void> => {
		if (!draftFilterId) {
			return Promise.resolve();
		}

		return this.props.deleteDraftFilter(draftFilterId);
	};

	okDeleteFilterConfirmationModal = () => {
		let filterDefinition = this.state.filterDefinition as FilterDefinition;
		if (filterDefinition) {
			let promise;
			if (filterDefinition.filterId) {
				promise = this.deleteFilterById(filterDefinition.filterId);
			} else {
				promise = this.deleteDraftFilterById(filterDefinition.draftFilterId).then(() =>
					this.deleteFilterById(filterDefinition.filterId)
				);
			}

			promise
				.then(() => {
					alertService.addSuccess(localizationService.getLocalizedString('screen.filterModal.deleteSuccess'));
					this.clearModalValue();
					this.props.filterDeleted();
				})
				.catch(error => {
					alertService.addError(error);
				});
		}
	};

	getConfirmModal = () => {
		let deleteMessage = localizationService.getLocalizedString('screen.filterModal.confirmDeleteMessage');

		if (this.props.widgetCount > 0) {
			deleteMessage =
				localizationService.getLocalizedString(
					'screen.filterModal.filterInUse',
					this.props.widgetCount.toString(),
					this.props.widgetCount > 1 ? 's' : ''
				) +
				' ' +
				localizationService.getLocalizedString('screen.filterModal.filterWillBeDeleted');
		}

		let deleteFilterModalProps: DeleteModalProp = {
			title: localizationService.getLocalizedString('screen.filterModal.confirmDeleteTitle'),
			message: deleteMessage,
			showModal: this.state.showDeleteConfirmationModal,
			onCancelButtonClick: this.cancelDeleteFilterConformationModel,
			onOkayButtonClick: this.okDeleteFilterConfirmationModal,
			okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
			isDeleteButton: true
		};

		return deleteFilterModalProps;
	};

	render() {
		let footer = (
			<>
				{this.isEditing && (
					<>
						<div
							className={`display-sm display-lg ${
								this.props.widgetCount > 0 ? '' : 'ie-delete-position'
							} ie-margin justify-content-start`}>
							<Button
								id="deleteFilter"
								className="btn ai-secondary-delete"
								onClick={this.onDeleteBtnClicked}>
								{localizationService.getLocalizedString('screen.buttons.delete')}
							</Button>
							{this.props.widgetCount > 0 && (
								<div id="svgDiv" className="filter-use-info ml-1">
									<SvgInfo
										height="15"
										className="align-self-baseline align-self-md-center align-self-lg-center"
									/>{' '}
									<span className="widget-sm-text">
										{localizationService.getLocalizedString(
											'screen.filterModal.filterInUse',
											this.props.widgetCount.toString(),
											this.props.widgetCount > 1 ? 's' : ''
										)}
									</span>
								</div>
							)}
						</div>
					</>
				)}

				<div
					className={`${this.isEditing ? 'ml-auto' : null} ${
						this.props.widgetCount > 0 ? '' : 'ie-save-position'
					}`}>
					<Button color="btn ai-action" id="saveBtn " onClick={this.onApplyBtnClicked}>
						{localizationService.getLocalizedString('screen.buttons.apply')}
					</Button>
					<Button color="btn ai-white ml-2" onClick={this.onCancelBtnClick}>
						{localizationService.getLocalizedString('screen.buttons.cancel')}
					</Button>
				</div>
			</>
		);

		let deleteFilterProps = this.getConfirmModal();
		let filterDefinition = this.state.filterDefinition as FilterDefinition;
		let filterItems = filterDefinition.getAllFilterItems();
		var allDomainObjects = filterService.getAllDomainObject();
		return (
			<TakeOverModal
				title={this.props.title}
				save={this.onApplyBtnClicked}
				cancel={this.onCancelBtnClick}
				footer={footer}
				className="filter-modal">
				<div>
					<div className="form-row">
						<div className="form-group required col-sm-4">
							<label htmlFor="FilterName">
								{' '}
								{localizationService.getLocalizedString('screen.filterModal.filterName')}
							</label>
							<input
								type="text"
								className={`form-control  ${this.state.filterNameError ? ' is-invalid' : ''} `}
								id="FilterName"
								value={this.state.filterName}
								onChange={this.onFilterNameChanged}
							/>
							{this.state.filterNameError && (
								<div className="invalid-feedback">
									{_.isEmpty(this.state.filterName)
										? localizationService.getLocalizedString('screen.filterModal.emptyFilterName')
										: localizationService.getLocalizedString(
												'screen.filterModal.invalidFilterName'
										  )}
								</div>
							)}
						</div>
					</div>

					<div className="filter-group my-3 rounded-lg border border-secondary">
						<div className="filter-group-title mx-2">
							{localizationService.getLocalizedString('screen.filterModal.filterItems')}
						</div>
						<div id="filterRows" className="form-group px-3 py-1">
							{filterItems.map((filterItem, index) => {
								let domainObject = allDomainObjects.find(i => i.name === filterItem.domainObject.name);
								if (domainObject) {
									filterItem.domainObject.properties = domainObject.properties;
								}

								return (
									<FilterRow
										key={`filter-row-${filterItem.key}`}
										rowNumber={index}
										deleteFilter={this.deleteFilterItem}
										updateFilter={this.updateFilterItem}
										filterItem={filterItem}
										reportPackageElementTypeId={this.props.reportPackageElementTypeId}
									/>
								);
							})}
						</div>

						<div className="form-row">
							<div className="form-group col-sm-4 m-3">
								<Button
									color="btn ai-secondary"
									id="addFilterItemBtn"
									onClick={this.onAddFilterItemBtnClicked}>
									{localizationService.getLocalizedString('screen.filterModal.addFilterItem')}
								</Button>
							</div>
						</div>
					</div>
				</div>
				{this.isEditing && <DeleteModal {...deleteFilterProps} key="deleteFilterConfirmModal" />}
			</TakeOverModal>
		);
	}
}

const mapStateToProps = (state: ApplicationState): StateProps => {
	return {
		...state.filters,
		...state.customFieldDefinitions
	};
};
const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		updateSavedFilter: (
			filterId: number,
			applyTo: string,
			filterName: string,
			filterDefinition: FilterDefinition
		) => dispatch(actionCreators.updateFilter(filterId, applyTo, filterName, filterDefinition)),
		createOrUpdateDraftFilter: (applyTo: string, filterName: string, filterDefinition: FilterDefinition) =>
			dispatch(actionCreators.createOrUpdateDraftFilter(applyTo, filterName, filterDefinition)),
		deleteFilter: (filterId: number) => dispatch(actionCreators.DeleteFilterById(filterId)),
		deleteDraftFilter: (draftFilterId: number) => dispatch(actionCreators.DeleteDraftFilterById(draftFilterId))
	};
};

export const FilterEditModal = connect<StateProps, DispatchProps, {}, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FilterEditModalComp);
