import React, { FC } from 'react';
import { StepperAction, StepperContent, StepperContext } from 'src/components/widgets/stepper';
import { apiService, localizationService, navigateTo, Resource, urlService } from 'src/services';
import { AddGeneralNoticeSteps, LookupType } from '@rcp/types';
import { CustomModal, CustomModalProp } from 'src/components/widgets/modal/modal';
import { useHistory } from 'react-router';
import { alertService, RootState } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import { generalNoticeTemplateSlice } from '../../general-notice-template.slice';

const { STEP1, STEP2, STEP3 } = AddGeneralNoticeSteps;

export const Step3: FC = () => {
	const fogNoticeSettingPath = '/fog/settings/notices';
	const history = useHistory();
	const dispatch = useDispatch();
	const stepperContext = React.useContext(StepperContext);
	const [showDiscardModal, setShowDiscardModal] = React.useState(false);

	let generalNoticeTemplateSchedule = (state: RootState) => state.generalNoticeTemplate;
	let { selected: generalNoticeTemplate } = useSelector(generalNoticeTemplateSchedule);

	React.useEffect(() => {
		if (generalNoticeTemplate) {
			const updatedStep3 = {
				...stepperContext.getStep(STEP3),
				completed: true,
				data: { resolved: true }
			};
			stepperContext.updateStep(STEP3, updatedStep3);
		}
		const step3Data = stepperContext.getData(STEP3);
		if (!step3Data && generalNoticeTemplate) {
			stepperContext.goAt(STEP1);
		}
	}, [generalNoticeTemplate]);

	const onClickBack = () => stepperContext.goAt(AddGeneralNoticeSteps.STEP2);

	const onClickDiscard = (e: any) => {
		e.preventDefault();
		setShowDiscardModal(!showDiscardModal);
	};

	const fetchStepperData = () => {
		const step1Data = stepperContext.getData(STEP1);
		const step2Data = stepperContext.getData(STEP2);
		return { ...step1Data, ...step2Data };
	};

	const onClickPreview = async (e: any) => {
		e.preventDefault();
		let payload = { ...generalNoticeTemplate, ...fetchStepperData() };
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.Settings}/${LookupType.GeneralNoticeTemplate}/${Resource.Preview}`
		);
		apiService
			.httpPost(url, payload)
			.then(() => {
				alertService.addSuccess(
					localizationService.getLocalizedString('authoritySetting.noticesSettings.previewSuccess')
				);
			})
			.catch(err => alertService.addError(err.message));
	};

	const onClickSave = (e: any) => {
		e.preventDefault();
		let payload = { ...generalNoticeTemplate, ...fetchStepperData() };
		if (generalNoticeTemplate && generalNoticeTemplate.noticeTemplateId) {
			dispatch(
				generalNoticeTemplateSlice.patchOne(
					generalNoticeTemplate!.noticeTemplateId,
					payload,
					false,
					localizationService.getLocalizedString('authoritySetting.noticesSettings.updateSuccess'),
					() => navigateTo(history, fogNoticeSettingPath)
				)
			);
		} else {
			dispatch(
				generalNoticeTemplateSlice.createOne(
					payload,
					false,
					localizationService.getLocalizedString('authoritySetting.noticesSettings.createSuccess'),
					() => navigateTo(history, fogNoticeSettingPath)
				)
			);
		}
	};

	const customModalProps: CustomModalProp = {
		showModal: showDiscardModal,
		message: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardMessage'),
		title: localizationService.getLocalizedString('authoritySetting.noticesSettings.discardTitle'),
		onCancelButtonClick: () => setShowDiscardModal(!showDiscardModal),
		onOkayButtonClick: () => navigateTo(history, '/fog/settings/notices'),
		okayButtonText: localizationService.getLocalizedString('authoritySetting.noticesSettings.discard')
	};

	return (
		<>
			<StepperContent
				id="preview-and-save"
				className="ml-4"
				actions={
					<React.Fragment>
						<StepperAction type="button" id="btn-back" className="btn btn-link" onClick={onClickBack}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.back')}
						</StepperAction>
						<StepperAction type="button" id="btn-discard" className="btn btn-link" onClick={onClickDiscard}>
							{localizationService.getLocalizedString('authoritySetting.noticesSettings.discard')}
						</StepperAction>
					</React.Fragment>
				}>
				<button className="btn ai-secondary mr-2" onClick={onClickPreview}>
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.preview')}
				</button>
				<button className="btn ai-save" onClick={onClickSave}>
					{localizationService.getLocalizedString('authoritySetting.noticesSettings.save')}
				</button>
			</StepperContent>
			<CustomModal {...customModalProps} />
		</>
	);
};
