import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { WasteType } from '@rcp/types';
import { apiService, Resource, urlService } from 'src/services';

export interface AuthorityWasteTypesState {
	wasteTypes: WasteType[];
}

export const initialAuthorityWasteTypesState: AuthorityWasteTypesState = {
	wasteTypes: []
};

enum ActionType {
	LoadAuthorityWasteTypesSuccess = 'loadAuthorityWasteTypesSuccess'
}

export type AuthorityWasteTypesThunkAction = ThunkAction<any, AuthorityWasteTypesState, any, Action>;

export const loadAuthorityWasteTypes = (): AuthorityWasteTypesThunkAction => async (dispatch: any) => {
	let url = urlService.getAuthorityLookupResourceApiUrl(Resource.WasteTypes, 'includeInactive=true');

	let authorityWasteTypes = await apiService.getResource<WasteType[]>(url);
	dispatch({ type: ActionType.LoadAuthorityWasteTypesSuccess, authorityWasteTypes });
};

export const authorityWasteTypesReducer = (
	state = initialAuthorityWasteTypesState,
	action: any
): AuthorityWasteTypesState => {
	switch (action.type) {
		case ActionType.LoadAuthorityWasteTypesSuccess:
			return { ...state, wasteTypes: action.authorityWasteTypes };
		default:
			return state;
	}
};
