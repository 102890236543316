import React from 'react';
import { DropDownOption } from '@rcp/types';
import _ from 'lodash';
import './segmented-control.scss';

interface Props {
	id: string;
	name: string;
	className?: string;
	value?: any;
	onChange?(e: any): void;
	options: DropDownOption[];
	readonly?: boolean;
}

export const SegmentedControl: React.FC<Props> = props => {
	const changeValue = (selectedValue: string | undefined) => {
		if (String.equalCaseInsensitive(props.value, selectedValue)) {
			selectedValue = undefined;
		}
		setValue(selectedValue);
		if (props.onChange) {
			props.onChange(selectedValue);
		}
	};

	const handleChange = (e: any) => {
		if (props.readonly) {
			return;
		}
		changeValue(e);
	};

	const [options, setOptions] = React.useState<DropDownOption[]>(props.options || []);
	const [value, setValue] = React.useState<string | undefined>(props.value || '');

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	return (
		<div className="segmented-control-button">
			<div id={props.id} className="d-flex flex-row button-bg">
				{options.map(x => {
					return (
						<button
							id={`btn${props.id}${x.value}`}
							type="button"
							disabled={props.readonly}
							className={`button-color-black ${
								String.equalCaseInsensitive(value, x.value) ? 'selected' : ''
							}`}
							onClick={() => handleChange(x.value)}>
							<span className={`${_.toLower(x.value)} ${props.readonly ? 'readonly' : ''}`}></span>
							{x.label}
						</button>
					);
				})}
			</div>
		</div>
	);
};
