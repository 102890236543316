import React from 'react';
import { connect, useSelector } from 'react-redux';

import * as ApiTypes from '@rcp/types';
import { ApplicationState, RootState } from 'src/redux';
import { localizationService, DateUtilService, urlService } from 'src/services';
import { PopoverModal, DeleteModal } from 'src/components/widgets';
import { EmailAddress } from '@rcp/types';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { deleteDeviceEmail, deleteFacilityEmail } from 'src/redux/facility/email';
import './send-email.scss';
import { translateService } from 'src/services/translate-service';

interface OwnProps {
	showModal: boolean;
	setShowModal: () => void;
	emailLog: ApiTypes.EmailLog;
	isDeviceDetailPage?: boolean;
}

interface StateProps {}

interface DispatchProps {
	deleteFacilityEmail: (emailLogId: number) => Promise<boolean | undefined>;
	deleteDeviceEmail: (emailLogId: number, facilityId: number, deviceId: number) => Promise<boolean | undefined>;
}

type Props = StateProps & DispatchProps & OwnProps;
interface FormFields {
	fromEmailAddress: React.ReactNode;
	recipientEmailAddresses: React.ReactNode;
	subject: string;
	content: string;
	sentDateTimeUtc: string;
	ccRecipientEmailAddresses?: React.ReactNode;
}
const initialFormFields: FormFields = {
	fromEmailAddress: <></>,
	recipientEmailAddresses: <></>,
	subject: '',
	content: '',
	sentDateTimeUtc: '',
	ccRecipientEmailAddresses: <></>
};
const ReadEmailModalComponent: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState(initialFormFields);
	const [toggleDeleteEmailModal, setToggleDeleteEmailModal] = React.useState(false);
	let deviceState = (state: RootState) => state.fogDevices;
	let { selected, loading } = useSelector(deviceState);

	React.useEffect(() => {
		if (props.emailLog && props.emailLog.emailMessage) {
			let emailMessage = props.emailLog.emailMessage;
			const newState: FormFields = {
				fromEmailAddress: emailMessage.fromEmailAddress ? (
					emailMessage.fromEmailAddress.name ? (
						emailMessage.fromEmailAddress.name
					) : (
						emailMessage.fromEmailAddress.address
					)
				) : (
					<></>
				),
				recipientEmailAddresses: emailMessage.recipientEmailAddresses ? (
					emailMessage.recipientEmailAddresses
						.map<React.ReactNode>(x => emailLink(x))
						.reduce((prev, curr) => [prev, ', ', curr])
				) : (
					<></>
				),
				ccRecipientEmailAddresses:
					emailMessage.ccRecipientEmailAddresses && emailMessage.ccRecipientEmailAddresses.length > 0 ? (
						emailMessage.ccRecipientEmailAddresses
							.map<React.ReactNode>(x => emailLink(x))
							.reduce((prev, curr) => [prev, prev ? ', ' : '', curr], '')
					) : (
						<></>
					),
				subject: emailMessage.subject,
				content: emailMessage.content,
				sentDateTimeUtc: props.emailLog.sentDateTimeUtc as string
			};
			setFormState(newState);
		}
	}, [props.showModal, props.emailLog]);

	const deleteEmail = async () => {
		setToggleDeleteEmailModal(false);
		if (props.emailLog && props.emailLog.emailLogId) {
			let emailLogId = props.emailLog.emailLogId;
			let entityEmailLogId = props.emailLog.entityEmailLogId;
			selected && props.isDeviceDetailPage
				? await props.deleteDeviceEmail(
						entityEmailLogId,
						selected.facilityId as number,
						selected.extractorId as number
				  )
				: await props.deleteFacilityEmail(emailLogId);
		}
		props.setShowModal();
	};

	const emailLink = (emailAddress: EmailAddress) => {
		if (emailAddress && emailAddress.name) {
			return <> {`${emailAddress.name} <${emailAddress.address}>`}</>;
		} else {
			return <> {`${emailAddress.address}`}</>;
		}
	};

	const closeModal = () => {
		props.setShowModal();
	};

	const modalFooterDiv = () => {
		return (
			<>
				<button
					id="delete-email-button"
					type="button"
					className="btn ai-secondary-delete ml-auto"
					onClick={() => {
						setToggleDeleteEmailModal(true);
					}}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
			</>
		);
	};
	let { emailMessage } = props.emailLog;
	return (
		<div className="w-100">
			<PopoverModal showModal={props.showModal} title="" footer={modalFooterDiv()} cancel={closeModal}>
				<p className="text-break">
					<strong>{localizationService.getLocalizedString('sendEmail.from')}:&nbsp;</strong>
					{formState.fromEmailAddress} <br />
					<strong>{localizationService.getLocalizedString('sendEmail.to')}:&nbsp;</strong>
					{formState.recipientEmailAddresses}
					<br />
					{emailMessage &&
					emailMessage.ccRecipientEmailAddresses &&
					emailMessage.ccRecipientEmailAddresses.length > 0 ? (
						<>
							<strong>{localizationService.getLocalizedString('sendEmail.Cc')}:&nbsp;</strong>
							{formState.ccRecipientEmailAddresses}
							<br />
						</>
					) : null}
					<strong>{localizationService.getLocalizedString('sendEmail.subject')}:&nbsp;</strong>
					<label
						dangerouslySetInnerHTML={{
							__html: formState.subject
						}}
					/>
					<br />
					<strong>{localizationService.getLocalizedString('sendEmail.sent')}:&nbsp;</strong>
					{`${DateUtilService.toDisplayDate(formState.sentDateTimeUtc)} ${DateUtilService.toDisplayTime(
						formState.sentDateTimeUtc
					)}`}
				</p>

				<pre className="mail-content" dangerouslySetInnerHTML={{ __html: formState.content }} />
			</PopoverModal>
			{toggleDeleteEmailModal && (
				<DeleteModal
					key="confirmDeleteEmailModal"
					title={localizationService.getLocalizedString('deleteEmail.deleteModalTitle')}
					message={localizationService.getLocalizedString('deleteEmail.deleteModalMessage')}
					showModal={toggleDeleteEmailModal}
					onCancelButtonClick={() => {
						setToggleDeleteEmailModal(false);
					}}
					onOkayButtonClick={deleteEmail}
					okayButtonText={localizationService.getLocalizedString('deleteEmail.deleteButton')}
					isDeleteButton={true}
				/>
			)}
		</div>
	);
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		deleteFacilityEmail: (emailLogId: number) => dispatch(deleteFacilityEmail(emailLogId)),
		deleteDeviceEmail: (emailLogId: number, facilityId: number, deviceId: number) =>
			dispatch(deleteDeviceEmail(emailLogId, facilityId, deviceId))
	};
};

export const ReadEmailModal = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	null,
	mapDispatchToProps
)(ReadEmailModalComponent);
