import React, { useState } from 'react';
import { TabViewField } from 'src/components/widgets';
import { localizationService, UtilService } from 'src/services';
import { CccSite } from '@rcp/types';
import { CccSiteOverviewTabModal } from './site-overview-tab-modal';

interface OwnProps {
	currentCccSite: CccSite;
}

type Props = OwnProps;

export const CccSiteOverviewTab: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);

	const onEditClicked = (e: any) => {
		e.preventDefault();
		setIsToggle(true);
	};

	// eslint-disable-next-line
	const toggle = () => {
		setIsToggle(!isToggle);
	};

	return (
		<>
			<div className="d-flex flex-row ">
				<div className="form-row w-100">
					<TabViewField
						title={localizationService.getLocalizedString('cccSite.dataSetName')}
						value={UtilService.formatValue(props.currentCccSite.dataSetName)}
					/>

					<TabViewField
						title={localizationService.getLocalizedString('cccSite.siteType')}
						value={UtilService.formatValue(props.currentCccSite.siteType)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccSite.siteUse')}
						value={UtilService.formatValue(props.currentCccSite.siteUse)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccSite.latitude')}
						value={UtilService.formatValue(props.currentCccSite.latitude)}
					/>
					<TabViewField
						title={localizationService.getLocalizedString('cccSite.longitude')}
						value={UtilService.formatValue(props.currentCccSite.longitude)}
					/>
				</div>
				<div className="ml-3">
					<a href="#/" onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				</div>
			</div>
			{isToggle && (
				<CccSiteOverviewTabModal isToggle={isToggle} toggle={toggle} currentCccSite={props.currentCccSite} />
			)}
		</>
	);
};
