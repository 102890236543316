import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { DropDownOption, IppIndustryAttachments, RouteProps, IppSetting } from '@rcp/types';
import { History } from 'history';
import _ from 'lodash';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotFound } from 'src/features/home/not-found';
import { DeleteModal, DeleteModalProp, SingleSelectDropdown, TextAreaInput, TextInput } from 'src/components/widgets';
import { IppConstants } from 'src/constants';
import { alertService, RootState } from 'src/redux';
import { apiService, localizationService, navigateTo, Resource, urlService, validationService } from 'src/services';
import { ippAttachmentDetailSlice } from './attachment-details-slice';
import { ippAttachmentSlice } from './attachments-slice';
import './attachments.scss';

interface Props extends RouteProps {
	history: History;
}

const initialAttachment: IppIndustryAttachments = {
	file: {} as File,
	reportElementTypeId: '',
	description: ''
};

const fileData: IppIndustryAttachments[] = [];

interface ErrorState {
	attachmentTypeError: string;
}

const initialErrorState: ErrorState = {
	attachmentTypeError: ''
};

interface FileErrorState {
	attachmentTypeError: string;
}

const initialFileErrorState: FileErrorState[] = [] as FileErrorState[];

const { fieldCharLimit } = IppConstants;
const supportedFileTypes: string = '.docx,.doc,.xls,.xlsx,.pdf,.tif,.tiff,.jpg,.jpeg,.bmp,.png,.txt,.csv';

const IppAttachmentDetails: FC<Props> = props => {
	let { industryAttachmentId } = props.match.params;
	const dispatch = useDispatch();
	let attachmentDetailState = (state: RootState) => state.ippAttachments;
	let { selected } = useSelector(attachmentDetailState);

	let [state, setState] = useState<IppIndustryAttachments>(initialAttachment);
	const [files, setFiles] = useState<IppIndustryAttachments[]>(fileData);
	const [invalidFileErrorState, setInvalidFileErrorState] = useState(initialFileErrorState);
	const [attachmentTypesDropDownOptions, setAttachmentTypesDropDownOptions] = useState([
		{
			label: '',
			value: ''
		}
	]);
	const [attachmentTypes, setAttachmentTypes] = useState<DropDownOption[]>();
	const [errorState, setErrorState] = useState(initialErrorState);
	const [deleteModal, setDeleteModal] = useState<boolean>(false);
	const [showAddNew, setShowAddNew] = useState(false);
	const [filesRequiredError, setFilesRequiredError] = useState('');
	const [maxFileSize, setMaxFileSize] = useState('');
	const fileRef = useRef<Array<HTMLDivElement>>([]);

	const isFormInAddMode = () => {
		if (window.location.pathname.includes(IppConstants.newFormText)) {
			return true;
		} else {
			return false;
		}
	};

	const isInvalidUrl = () => {
		return !isFormInAddMode() && !Number(industryAttachmentId) ? true : false;
	};

	const fetchAttachmentTypes = async () => {
		const reportElementCategory = 'Attachments';
		let url = urlService.getAuthorityResourcesApiUrl(
			Resource.IppReportPackageReportElementTypes,
			urlService.toQueryString({ ReportElementCategoryName: reportElementCategory })
		);
		let attachmentTypes: any[] = await apiService.getResource(url);
		setAttachmentTypes([...attachmentTypes]);
	};

	const fetchFileSizeSetting = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.MaxAttachmentFileSizeInMB);
		let setting: IppSetting = await apiService.getResource(url);
		setMaxFileSize(setting.value || '');
	};

	useEffect(() => {
		if (isFormInAddMode()) {
			setState(initialAttachment);
		} else {
			Number(industryAttachmentId) && dispatch(ippAttachmentSlice.fetchOne(industryAttachmentId as number));
		}
		fetchAttachmentTypes();
		fetchFileSizeSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		!isFormInAddMode() && selected && setState({ ...selected });
	}, [selected]);

	useEffect(() => {
		let attachmentOptions: DropDownOption[] = [];
		if (attachmentTypes && attachmentTypes.length) {
			attachmentOptions = attachmentTypes.map(item => {
				let options: DropDownOption = {
					label: item.name,
					value: item.reportElementTypeId
				};
				return options;
			});
		}
		attachmentOptions.unshift({
			label: localizationService.getLocalizedString('ipp.attachments.defaultAttachmentType'),
			value: '',
			isHidden: false
		});
		attachmentOptions && setAttachmentTypesDropDownOptions(attachmentOptions);
	}, [attachmentTypes]);

	const onChange = (event: any, formType: string, index?: number) => {
		if (formType === 'update') {
			let { value, name } = event.currentTarget;
			setState({ ...state, [name]: value });
		} else if (formType === 'add') {
			let { value, name } = event.currentTarget;
			let fileData = _.cloneDeep(files);
			(fileData[index as number] as any)[name] = value;
			setFiles([...fileData]);
		}
	};

	const isTotalFileSizeLessThanMaxAllowedSize = (selectedFiles: File[] = []) => {
		let allFiles = [...selectedFiles, ...files.map((file: IppIndustryAttachments) => file.file)];
		if (allFiles.length) {
			let fileSizes = allFiles.map((fileData: any) => fileData.size);
			let totalPayloadSizeInMB = bytesToSize(fileSizes.reduce((a, b) => a + b, 0));
			if (Number(totalPayloadSizeInMB) > Number(maxFileSize)) {
				alertService.addError(
					localizationService.getLocalizedString('ipp.attachments.errors.collectiveFileSize', maxFileSize)
				);
				return false;
			} else {
				alertService.clearAllMessages();
				return true;
			}
		}
		return false;
	};

	const isFormValidForSave = (): boolean => {
		let isFormValid = false;
		let fileErrorState: FileErrorState[] = [];
		if (!files.length) {
			setFilesRequiredError(localizationService.getLocalizedString('ipp.attachments.errors.filesRequired'));
		} else {
			setFilesRequiredError('');
		}
		for (let i = 0; i < files.length; i++) {
			let formData = { ...files[i], ...invalidFileErrorState[i] };
			validationService.validateRequiredField(formData, 'reportElementTypeId', 'attachmentTypeError');
			let fileError: FileErrorState = _.pick(formData, ['attachmentTypeError']);
			isFormValid = fileError.attachmentTypeError ? false : true;
			fileErrorState.push(fileError);
		}
		setInvalidFileErrorState([...fileErrorState]);
		return isFormValid;
	};

	const isFormValidForUpdate = (): boolean => {
		let isFormValid = false;
		let formData = { ...state, ...errorState };
		validationService.validateRequiredField(formData, 'reportElementTypeId', 'attachmentTypeError');
		setErrorState(_.pick(formData, ['attachmentTypeError']));
		isFormValid = validationService.hasError(formData, 'attachmentTypeError') ? false : true;
		return isFormValid;
	};

	const onSaveCallback = (data?: any, type?: string) => {
		let id = data.fileStoreId || data[0].fileStoreId;
		let attachmentDetails: IppIndustryAttachments = { ...data } || data[0];
		let getGuidWithRegulateeName = urlService.getUrlWithRegulateeTypeAndGuid();
		let attachmentDetailsPath = `/${getGuidWithRegulateeName}/attachment/${id}`;
		let attachmentsGridPath = `/${getGuidWithRegulateeName}/attachment`;
		setState(attachmentDetails);
		type === 'add' && setShowAddNew(true);
		type === 'update' && setShowAddNew(false);
		navigateTo(props.history, data.length === 1 || type === 'update' ? attachmentDetailsPath : attachmentsGridPath);
		data.length === 1 && dispatch(ippAttachmentSlice.fetchOne(id));
		resetErrors();
	};

	const onSave = async () => {
		if (isFormValidForSave() && isFormInAddMode()) {
			let payload = [...files];
			let formData = new FormData();
			for (let i: number = 0; i < payload.length; i++) {
				formData.append('file', payload[i].file || '');
				formData.append('reportElementTypeId', (payload[i].reportElementTypeId || '').toString());
				formData.append('description', (payload[i].description || '').toString());
			}
			try {
				let url = urlService.getAuthorityResourcesApiUrl(Resource.IppFileStores);
				let data = await apiService.postFormData(url, formData, {});
				alertService.addSuccess(
					localizationService.getLocalizedString(
						`ipp.attachments.${
							data.length === 1 ? 'addSuccessMessage' : 'addSuccessMessageMultipleAttachments'
						}`
					)
				);
				onSaveCallback(data, 'add');
			} catch (ex) {
				alertService.addError(ex.message);
			}
		} else if (isFormValidForUpdate() && !isFormInAddMode()) {
			let payload = { ...state };
			payload = _.pick(payload, ['file', 'reportElementTypeId', 'description']);
			try {
				dispatch(
					ippAttachmentDetailSlice.patchOne(
						Number(industryAttachmentId),
						payload,
						false,
						localizationService.getLocalizedString('ipp.attachments.updateSuccessMessage'),
						(data: any) => onSaveCallback(data, 'update')
					)
				);
			} catch (ex) {
				alertService.addError(ex.message);
			}
		}
	};

	const isSupportedFileType = (file: File) => {
		let supportedFileExtensions = supportedFileTypes.replace(/[.]/g, '');
		let selectedFileExtension = file.name.slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2);
		return _.toLower(supportedFileExtensions)
			.split(',')
			.includes(_.toLower(selectedFileExtension));
	};

	const onFileChangeHandler = (event: any) => {
		event.preventDefault();
		const inputElement = event.target as HTMLInputElement;
		const chosenFiles = inputElement.files as FileList;
		let selectedFiles = _.cloneDeep(files);
		let errorFileState = _.cloneDeep(invalidFileErrorState);
		if (chosenFiles == null || chosenFiles.length < 1) {
			return;
		}
		if (!isTotalFileSizeLessThanMaxAllowedSize(Array.from(chosenFiles))) {
			return;
		}

		for (let i = 0; i < chosenFiles.length; i++) {
			if (bytesToSize(chosenFiles[i].size) > Number(maxFileSize)) {
				alertService.addError(
					localizationService.getLocalizedString(
						'ipp.attachments.errors.sizeLimitExceeded',
						chosenFiles[i].name,
						maxFileSize
					)
				);
			} else if (!isSupportedFileType(chosenFiles[i])) {
				alertService.addError(
					localizationService.getLocalizedString(
						'ipp.attachments.errors.fileTypeNotSupported',
						chosenFiles[i].name
					)
				);
			} else {
				chosenFiles.length <= 1 && alertService.clearAllMessages();
				setFilesRequiredError('');
				selectedFiles.push({
					file: chosenFiles[i],
					name: '',
					reportElementTypeId: '',
					description: ''
				});
				errorFileState.push({
					attachmentTypeError: ''
				});
			}
		}
		setFiles([...selectedFiles]);
	};

	const bytesToSize = (bytes: number) => {
		var sizeInMB = (bytes / (1024 * 1024)).toFixed(2);
		return Number(sizeInMB);
	};

	useEffect(() => {
		const fileListLength = files.length;
		fileRef.current = fileRef.current.slice(0, fileListLength);
	}, [files]);

	const downloadAttachment = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(
			`${Resource.IppIndustryAttachments}/${state.fileStoreId}?isDownload=true`
		);
		try {
			await apiService.getResource(url);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const onToggleDeleteModal = () => {
		setDeleteModal(!deleteModal);
	};

	const onDelete = () => {
		let url = urlService.getIppIndustryUrl('attachment');
		try {
			dispatch(
				ippAttachmentSlice.deleteOne(
					state.fileStoreId || 0,
					false,
					localizationService.getLocalizedString('ipp.attachments.deleteSuccessMessage'),
					() => navigateTo(props.history, url)
				)
			);
		} catch (ex) {
			alertService.addError(ex.message);
		}
	};

	const resetForm = () => {
		setState(initialAttachment);
		setFiles(fileData);
		setInvalidFileErrorState(initialFileErrorState);
		setErrorState(initialErrorState);
		setShowAddNew(false);
	};

	const resetErrors = () => {
		setInvalidFileErrorState(initialFileErrorState);
		setErrorState(initialErrorState);
	};

	const removeFile = (index: number, size: number = 10) => {
		return (
			<span
				id="remove-file"
				className="cursor-pointer p-1"
				onClick={event => {
					event.stopPropagation();
					let selectedFiles = _.cloneDeep(files);
					selectedFiles.splice(index, 1);
					setFiles(selectedFiles);
				}}>
				<FontAwesomeIcon fontSize={size} fontWeight="light" icon={faTrashAlt} className="font-awesome-icon " />
			</span>
		);
	};

	const deleteAttachmentModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString(`ipp.attachments.deleteHeading`),
		message: localizationService.getLocalizedString('ipp.attachments.deleteConfirmText'),
		showModal: deleteModal,
		onCancelButtonClick: () => onToggleDeleteModal(),
		onOkayButtonClick: onDelete,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	return isInvalidUrl() ? (
		<NotFound />
	) : (
		<>
			<div className="page-header">
				<h1>{localizationService.getLocalizedString('ipp.attachments.detailsPageHeader')}</h1>
				{!isFormInAddMode() && (
					<label
						id="attachment-status"
						className={`${state.usedByReports ? 'reported-btn' : 'draft-btn'} status-btn`}>
						{localizationService.getLocalizedString(
							`ipp.attachments.${state.usedByReports ? 'reported' : 'draft'}`
						)}
					</label>
				)}
			</div>
			<section>
				<div>
					<div className="form-row">
						{isFormInAddMode() ? (
							<div className="ai-file-input pl-1">
								<div className="form-group required mb-0">
									<label htmlFor="attach-file">
										{localizationService.getLocalizedString(
											'ipp.attachments.fileLabel',
											`${maxFileSize}MB`
										)}
									</label>
								</div>
								<div className="file-input-group">
									<label
										className="btn ai-secondary mb-1"
										htmlFor="attach-file"
										aria-describedby="file-input-help">
										{localizationService.getLocalizedString('ipp.attachments.chooseFile')}
									</label>
									{files.length ? (
										files.map((fileData: IppIndustryAttachments, index: number) => {
											return (
												<span key={`file-${index}`}>
													<label
														onClick={() =>
															fileRef.current[index] !== null &&
															fileRef.current[index].scrollIntoView()
														}
														className="font-size-12px-regular file-badge file-name ml-2 p-1 pl-2 mb-1">
														<div
															className="d-inline-flex"
															title={fileData.file && fileData.file.name}>
															<span id="file-badge-name">
																{fileData.file && fileData.file.name}
															</span>
														</div>
														{removeFile(index)}
													</label>
												</span>
											);
										})
									) : (
										<label className="file-name ml-2">
											{localizationService.getLocalizedString('ipp.attachments.noFileChosen')}
										</label>
									)}
								</div>
								<div className="ai-form-help ai-required">{filesRequiredError}</div>
								<input
									type="file"
									id="attach-file"
									onClick={(event: any) => (event.target.value = '')}
									className="file-input"
									onChange={onFileChangeHandler}
									accept={supportedFileTypes}
									multiple
								/>
							</div>
						) : (
							<div className="form-group pl-1">
								<label htmlFor="download-file">
									{localizationService.getLocalizedString('ipp.attachments.columns.name')}
								</label>
								<p id="download-file-label">
									<a
										href="#/"
										onClick={(event: any) => {
											event.preventDefault();
											downloadAttachment();
										}}>
										{state.name}
									</a>
								</p>
							</div>
						)}
					</div>
					{!isFormInAddMode() ? (
						<>
							<div className="form-row">
								<TextInput
									id="original-file-name"
									className="col-sm-6 mb-3"
									name="originalFileName"
									label={localizationService.getLocalizedString(
										'ipp.attachments.columns.originalFileName'
									)}
									value={state.originalFileName}
									isDisabled
									onChange={event => onChange(event, 'update')}
								/>
							</div>
							<div className="form-row">
								<SingleSelectDropdown
									id="available-cts-event-types"
									name="reportElementTypeId"
									label={localizationService.getLocalizedString(
										'ipp.attachments.columns.reportElementTypeName'
									)}
									noEmptyOption={true}
									value={(state.reportElementTypeId && state.reportElementTypeId.toString()) || ''}
									options={attachmentTypesDropDownOptions}
									selfOrder={true}
									onChange={event => onChange(event, 'update')}
									isRequired
									className="col-md-6 mb-3"
									error={errorState.attachmentTypeError}
									isDisabled={state.usedByReports}
								/>
							</div>
							<div className="form-row">
								<TextAreaInput
									id="attachment-description"
									className="col-sm-12 mb-3"
									name="description"
									maxLength={fieldCharLimit.commentsAndDescription}
									label={localizationService.getLocalizedString(
										'ipp.attachments.columns.description'
									)}
									value={state.description}
									rows={2}
									onChange={event => onChange(event, 'update')}
									isDisabled={state.usedByReports}
								/>
							</div>
						</>
					) : (
						files.length > 0 &&
						files.map((fileData: IppIndustryAttachments, index: number) => {
							return (
								<div
									ref={(el: HTMLDivElement) => (fileRef.current[index] = el)}
									key={`fileData-${index}`}
									className="mb-4 file-details-card">
									<span className="font-size-18px-medium">
										<div className="mb-2">
											<span>{fileData.file && fileData.file.name}</span>
											<span className="float-right">{removeFile(index, 13)}</span>
										</div>
									</span>
									<div className="form-row">
										<SingleSelectDropdown
											id="available-cts-event-types"
											name="reportElementTypeId"
											label={localizationService.getLocalizedString(
												'ipp.attachments.columns.reportElementTypeName'
											)}
											noEmptyOption={true}
											value={
												(fileData.reportElementTypeId &&
													fileData.reportElementTypeId.toString()) ||
												''
											}
											options={attachmentTypesDropDownOptions}
											selfOrder={true}
											onChange={event => onChange(event, 'add', index)}
											isRequired
											className="col-md-6 mb-3"
											error={
												invalidFileErrorState[index]
													? invalidFileErrorState[index].attachmentTypeError
													: ''
											}
										/>
									</div>
									<div className="form-row">
										<TextAreaInput
											id="attachment-description"
											className="col-sm-12"
											name="description"
											maxLength={fieldCharLimit.commentsAndDescription}
											label={localizationService.getLocalizedString(
												'ipp.attachments.columns.description'
											)}
											value={fileData.description}
											rows={2}
											onChange={event => onChange(event, 'add', index)}
										/>
									</div>
								</div>
							);
						})
					)}
				</div>
				<div className="d-flex">
					{!isFormInAddMode()
						? !state.usedByReports && (
								<button
									id="delete-btn"
									className="btn ai-delete ml-1 mr-left"
									onClick={() => onToggleDeleteModal()}>
									{localizationService.getLocalizedString(`screen.buttons.delete`)}
								</button>
						  )
						: null}
					<div className="data-provider-buttons ml-auto">
						{!state.usedByReports && (
							<>
								<button id="save-btn" className="btn ai-save" onClick={onSave}>
									{localizationService.getLocalizedString(`ipp.buttons.save`)}
								</button>
							</>
						)}
						{isFormInAddMode() ? (
							<button
								id="cancel-btn"
								className="btn ai-white ml-1"
								onClick={() => navigateTo(props.history, urlService.getIppIndustryUrl('attachment'))}>
								{localizationService.getLocalizedString(`screen.buttons.cancel`)}
							</button>
						) : null}
						{showAddNew && (
							<button
								id="delete-btn"
								className="btn ai-action ml-1"
								onClick={() => {
									let getGuidWithRegulateeName = urlService.getUrlWithRegulateeTypeAndGuid();
									let attachmentDetailsPath = `/${getGuidWithRegulateeName}/attachment/new`;
									resetForm();
									navigateTo(props.history, attachmentDetailsPath);
								}}>
								{localizationService.getLocalizedString(`ipp.buttons.addNew`)}
							</button>
						)}
					</div>
				</div>
			</section>
			<DeleteModal {...deleteAttachmentModalProp} key="deleteAttachment" />
		</>
	);
};

export default IppAttachmentDetails;
