import { Extractor, CleaningEvent } from '@rcp/types';
import React from 'react';
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { DeleteModal, DeleteModalProp } from 'src/components/widgets';
import { localizationService, navigateTo, Resource, urlService } from 'src/services';
import { useDispatch } from 'react-redux';
import { facilityDevicesSlice } from '../facility-devices.slice';
import { fogDevicesSlice } from '../devices.slice';
import { CleaningModal } from 'src/components/authority/fog/cleaning/cleaning-modal';
import { useHistory } from 'react-router';
interface actionProp {
	currentDevice: Extractor;
	history: History;
}

export const DeviceActionDropdown: React.FC<actionProp> = props => {
	const dispatch = useDispatch();
	const [showDeleteModal, setShowDeleteModal] = React.useState(false);
	const [showScheduleCleaningModal, setShowScheduleCleaningModal] = React.useState(false);
	const history = useHistory();

	const onToggleDeleteExtractorModal = () => {
		setShowDeleteModal(!showDeleteModal);
	};

	const onToggleScheduleCleaningExtractorModal = () => {
		setShowScheduleCleaningModal(!showScheduleCleaningModal);
	};

	const onDeleteExtractor = () => {
		let deviceUrl = urlService.getReactResourceUrl(Resource.Devices);
		facilityDevicesSlice.setApiUrlPath(
			Resource.FogFacilities + '/' + props.currentDevice.facilityId + '/' + Resource.Extractors
		);

		dispatch(
			fogDevicesSlice.deleteOne(
				props.currentDevice.extractorId as number,
				undefined,
				localizationService.getLocalizedString('alertMessages.removeSuccess', 'extractor.device'),
				() => {
					return navigateTo(history, deviceUrl);
				}
			)
		);
	};
	let deleteExtractorModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('extractor.deviceDeleteModalTitle'),

		message: localizationService.getLocalizedString('extractor.deviceDeleteModalMessage'),

		showModal: showDeleteModal,
		onCancelButtonClick: onToggleDeleteExtractorModal,
		onOkayButtonClick: onDeleteExtractor,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	return (
		<>
			<UncontrolledDropdown id="actionDropdown" className="action-item-black">
				<DropdownToggle tag="a" className="btn" caret>
					{localizationService.getLocalizedString('screen.labels.action')}
				</DropdownToggle>
				<DropdownMenu>
					{!props.currentDevice.nextCleaningDate && props.currentDevice.isActive && (
						<DropdownItem id="scheduleCleaning" tag="a" onClick={onToggleScheduleCleaningExtractorModal}>
							{localizationService.getLocalizedString('extractor.scheduleCleaning')}
						</DropdownItem>
					)}
					<DropdownItem id="deleteDevice" tag="a" onClick={onToggleDeleteExtractorModal}>
						{localizationService.getLocalizedString('extractor.delete')}
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>

			<DeleteModal {...deleteExtractorModalProp} key="deleteExtractorModal" />
			{showScheduleCleaningModal && (
				<CleaningModal
					isScheduleCleaning={true}
					cleaning={{} as CleaningEvent}
					facilityId={props.currentDevice.facilityId as number}
					device={props.currentDevice}
					isDevicePage={true}
					isTimelineReloadRequired={true}
					dueDateIsRequired={true}
					completeDateIsRequired={false}
					modalToggleFunction={onToggleScheduleCleaningExtractorModal}
					isAddModal={true}
				/>
			)}
		</>
	);
};
