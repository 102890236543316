import { apiService, Resource, urlService, localizationService } from 'src/services';
import { alertService } from '..';
import { Enforcement } from '@rcp/types';

export const saveEnforcement = (enforcement: Enforcement, enforcementFacilityId?: number) => async () => {
	let facilityId = urlService.getFogFacilityIdOrThrowError(enforcementFacilityId);

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const enforcementUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Enforcements);
	if (enforcement.enforcementId && enforcement.enforcementId > 0) {
		await apiService.patchResource<Enforcement>(`${enforcementUrl}/${enforcement.enforcementId}`, enforcement);
	} else {
		await apiService.postResource<Enforcement>(enforcementUrl, enforcement);
	}
	let key = localizationService.getLocalizedString('enforcement.enforcement');
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.savedSuccess', key));
};

export const deleteEnforcement = (enforcement: Enforcement, enforcementFacilityId?: number) => async () => {
	let facilityId = urlService.getFogFacilityIdOrThrowError(enforcementFacilityId);

	if (!facilityId) {
		throw new Error(
			localizationService.getLocalizedString('screen.validationMessage.fieldValueIsRequired', 'organizationId')
		);
	}

	const enforcementUrl = urlService.getFacilityResourceApiUrl(facilityId, Resource.Enforcements);
	await apiService.deleteResource(`${enforcementUrl}/${enforcement.enforcementId}`);
	let key = localizationService.getLocalizedString('enforcement.enforcement');
	alertService.addSuccess(localizationService.getLocalizedString('alertMessages.removeSuccess', key));
};
