import * as ApiTypes from '@rcp/types';

export interface IppAASAuthorityInformationState {
	data: ApiTypes.IppAASAuthorityInformationData;
}

export const initialIppAASAuthorityInformationState: IppAASAuthorityInformationState = {
	data: {
		authorityName: '',
		organizationRegulatoryProgramId: 0,
		address1: '',
		address2: '',
		city: '',
		state: '',
		jurisdictionCode: '',
		zipCode: '',
		phoneNumber: '',
		phoneNumberExt: '',
		faxNumber: '',
		websiteURL: '',
		referenceNumber: '',
		signer: ''
	}
};
