import React, { FC } from 'react';
import { ColumnField, FilterType, IppReportPackage, LocalStorageName, FilterObjectName } from '@rcp/types';
import { IppConstants, ReportPackageStatus } from 'src/constants';
import { GridOption } from 'src/features/data-grid/data-grid';
import { localizationService, navigateTo, urlService } from 'src/services';
import { FilterDomain, FilterDomainName, filterService } from 'src/features/filter/filter-service';
import IppReportPackageReviewsComponent from 'src/components/authority/ipp/report-packages/packages/reviews/reviews';
import { nameof } from 'ts-simple-nameof';

interface Props {
	history: any;
	match: any;
}

const { initialPage } = IppConstants.queryParams;

const IppRepudiatedReportPackage: FC<Props> = (props: Props) => {
	const nameToLink = (item: any) => {
		const dataItem = item.dataItem as IppReportPackage;
		const url = urlService.getIppIndustryUrl(`reportPackage`, dataItem.reportPackageId);
		return (
			<td>
				<a href={url} className="ai-link" onClick={(e: any) => navigateTo(props.history, url, e)}>
					{dataItem.name}
				</a>
			</td>
		);
	};

	const initialGridOption: GridOption = {
		showRefreshButton: true,
		showTabList: false,
		showEditColumnButton: true,
		showFilter: true,
		showSearch: true,
		filterOnlyThisGrid: true,
		prefix: 'ipp.reportPackage.package.columns',
		storageName: LocalStorageName.IppReportPackageRepudiatedGrid,
		searchPlaceholder: '',
		showActions: false,
		gridUrlLocalStorageName: LocalStorageName.IppReportPackageRepudiatedGridFilterUrl,
		resetFilterService: () => {
			filterService.init(
				FilterDomain.IPPREPUDIATEDREPORTPACKAGE,
				FilterDomainName.ippRepudiatedReportPackages,
				FilterObjectName.IppRepudiatedReportPackage,
				LocalStorageName.IppReportPackageRepudiatedGridFilterUrl,
				{ IPPREPUDIATEDREPORTPACKAGE: true }
			);
		},
		enableFilter: true,
		pageTitle: localizationService.getLocalizedString('ipp.reportPackage.package.reportTypes.Repudiated'),
		activeTab: 'tab-list',
		sort: [],
		showEditColumnModal: false,
		showAddModal: false,
		showAddButton: false,
		showScheduleInspectionModal: false,
		queryParameters: {
			ReportStatusName: ReportPackageStatus.Repudiated,
			page: initialPage,
			sort: 'repudiationDateTimeLocal asc'
		},
		anchorElement: null,
		showBulkUpdate: false,
		showExportToSpreadsheet: false,
		disableFilterWarning: true,
		allColumns: [
			new ColumnField('reportPackageId', FilterType.Number),
			new ColumnField('name', FilterType.Text, nameToLink),
			new ColumnField('periodStartDateTimeLocal', FilterType.Date),
			new ColumnField('periodEndDateTimeLocal', FilterType.Date),
			new ColumnField('organizationReferenceNumber'),
			new ColumnField('organizationName'),
			new ColumnField('submissionDateTimeLocal', FilterType.Date),
			new ColumnField('submitterFirstName'),
			new ColumnField('submitterLastName'),
			new ColumnField('repudiationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModificationDateTimeLocal', FilterType.Date),
			new ColumnField('lastModifierFullName'),
			new ColumnField('repudiatorFirstName'),
			new ColumnField('repudiatorLastName'),
			new ColumnField('repudiationReasonName'),
			new ColumnField('repudiationComments'),
			new ColumnField('repudiationReviewerFirstName'),
			new ColumnField('repudiationReviewerLastName'),
			new ColumnField('repudiationReviewComments'),
			new ColumnField(
				nameof<IppReportPackage>(x => x.ctsEventId),
				FilterType.Number
			)
		],

		defaultColumns: [
			'name',
			'periodStartDateTimeLocal',
			'periodEndDateTimeLocal',
			'submissionDateTimeLocal',
			'submitterFirstName',
			'repudiatorFirstName',
			'repudiationDateTimeLocal'
		]
	};

	return (
		<IppReportPackageReviewsComponent
			history={props.history}
			initialGridOption={initialGridOption}
			match={props.match}
		/>
	);
};

export default IppRepudiatedReportPackage;
