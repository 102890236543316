import _ from 'lodash';
import React from 'react';
import {
	PopoverModal,
	TextInput,
	SingleSelectDropdown,
	DeleteModalProp,
	TextAreaInput,
	DeleteModal,
	DateInput,
	ModalAttachments,
	ModalAttachmentState,
	ModalAttachmentService
} from 'src/components/widgets';
import { alertService, useReduxDispatch, RootState, useReduxSelector, ActionModalActionType } from 'src/redux';
import {
	Incident,
	DropDownOption,
	AutoNumberDomainType,
	LookupType,
	Lookup,
	AttachmentType,
	FeatureNames,
	IncidentContributor
} from '@rcp/types';
import {
	validationService,
	localizationService,
	authoritySettingService,
	optionsMap,
	apiService,
	urlService,
	Resource,
	DateUtilService
} from 'src/services';
import { incidentsSlice } from '.';
import { useSelector } from 'react-redux';
import { authorityUsersSlice } from '../../shared/settings';
import { CloseSvg, FacilitySvg } from 'src/components/svg';
import { MapDataSortService } from 'src/services/mapDataSortService';
import { IncidentBatchScheduleInspection } from './incident-batch-schedule-inspection';

interface Props {
	showModal: boolean;
	setShowModal: () => void;
	incident?: Incident;
	isAddModal: boolean;
	deleteIncidentCallback?: () => void;
	saveIncidentCallback?: () => void;
}

interface FormFields {
	incidentId?: number;
	incidentNumber?: string;
	address?: string;
	city?: string;
	latitude?: string;
	longitude?: string;
	reportedDate?: string;
	incidentTypeId?: number;
	incidentTypeCode?: string;
	incidentStatusId?: number;
	incidentStatusCode?: string;
	incidentSourceId?: number;
	incidentSourceCode?: string;
	assetId?: string;
	investigatorId?: number;
	investigatorFullName?: string;
	incidentDescription?: string;
	resolvedDate?: string;
	resolutionDescription?: string;

	incidentNumberError?: string;
	addressError?: string;
	cityError?: string;
	latitudeError?: string;
	longitudeError?: string;
	reportedDateError?: string;
	incidentTypeIdError?: string;
	incidentStatusIdError?: string;
	incidentSourceIdError?: string;
	incidentDescriptionError?: string;
}

const initialFormFields: FormFields = {
	incidentNumber: '',
	address: '',
	city: '',
	latitude: '',
	longitude: '',
	reportedDate: '',
	assetId: '',
	incidentDescription: '',
	resolvedDate: '',
	resolutionDescription: ''
};

export const IncidentModal: React.FC<Props> = props => {
	const [formState, setFormState] = React.useState(initialFormFields);
	const [deleteIncidentModal, setToggleDeleteIncidentModal] = React.useState(false);

	const [fogIncidentTypeOptionValues, setFogIncidentTypeValues] = React.useState([] as DropDownOption[]);
	const [fogIncidentStatusOptionValues, setFogIncidentStatusValues] = React.useState([] as DropDownOption[]);
	const [fogIncidentSourceOptionValues, setFogIncidentSourceValues] = React.useState([] as DropDownOption[]);
	const [authorityUserOptions, setAuthorityUserOptions] = React.useState([] as DropDownOption[]);
	const [selectedKnownFacilities, setSelectedKnownFacilities] = React.useState<IncidentContributor[]>([]);
	const [selectedSuspectedFacilities, setSelectedSuspectedFacilities] = React.useState<IncidentContributor[]>([]);

	const dispatch = useReduxDispatch();
	const { result: authorityUsers } = useSelector((state: RootState) => state.authorityUsers);
	const showMapFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogMapFeature]
	);

	const [modalAttachmentsState, setModalAttachmentState] = React.useState<ModalAttachmentState>({
		attachmentType: AttachmentType.FogIncident,
		ownerId: props.incident ? props.incident.incidentId : undefined,
		initialAttachments: [],
		currentAttachments: []
	});
	const modalAttachmentsService = new ModalAttachmentService(() => {
		return modalAttachmentsState;
	}, setModalAttachmentState);

	const getNewFormFieldState = (incident: Incident) => {
		const newState: FormFields = { ...incident };
		const contributors = JSON.parse(incident.incidentContributorsJson!) as IncidentContributor[];
		setSelectedKnownFacilities(contributors.filter(contributor => contributor.isKnownContributor));
		setSelectedSuspectedFacilities(contributors.filter(contributor => !contributor.isKnownContributor));
		return newState;
	};

	React.useEffect(() => {
		dispatch(authorityUsersSlice.fetchAll());
	}, [dispatch]);

	React.useEffect(() => {
		fetchFogIncidentTypes();
		fetchFogIncidentSources();
		fetchFogIncidentStatuses();
	}, []);

	React.useEffect(() => {
		if (props.showModal === true && props.isAddModal) {
			authoritySettingService
				.getNextAutoNumber(AutoNumberDomainType.Incident)
				.then((nextIncidentAutoNumber: any) => {
					let newState = { ...initialFormFields };
					newState.incidentNumber = nextIncidentAutoNumber as string;
					setFormState(newState);
				});
		}
	}, [props.showModal, props.isAddModal]);

	React.useEffect(() => {
		if (props.incident) {
			const newState: FormFields = getNewFormFieldState(props.incident);
			setFormState(newState);
		}
	}, [props.incident]);

	const fetchFogIncidentTypes = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentType
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentTypes => {
				setFogIncidentTypeValues(optionsMap.fromLookups(fogIncidentTypes));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const fetchFogIncidentSources = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentSource
		}&includeInactive=true`;
		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentSources => {
				setFogIncidentSourceValues(optionsMap.fromLookups(fogIncidentSources));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	const fetchFogIncidentStatuses = () => {
		const url = `${urlService.getApiBaseUrlWithProgram()}/${Resource.Settings}/Lookups?lookupType=${
			LookupType.FogIncidentStatus
		}&includeInactive=true`;

		apiService
			.getResource<Lookup[]>(url)
			.then(fogIncidentStatuses => {
				setFogIncidentStatusValues(optionsMap.fromLookups(fogIncidentStatuses));
			})
			.catch(ex => alertService.addError(ex.message));
	};

	React.useEffect(() => {
		if (authorityUsers) {
			let options = optionsMap.fromUserProfiles(authorityUsers, false);
			setAuthorityUserOptions(options);
		}
	}, [authorityUsers]);

	const changeFormState = (e: any) => {
		let newState = { ...formState };
		let { name, value } = e.target;
		_.set(newState, name, value);

		setFormState(newState);
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(newState, 'incidentNumber', 'incidentNumberError');
		validationService.validateRequiredField(newState, 'address', 'addressError');
		validationService.validateRequiredField(newState, 'city', 'cityError');
		let maxDate = DateUtilService.getAuthorityTimezoneNow();
		validationService.validateRequiredDateField(
			newState,
			'reportedDate',
			'reportedDateError',
			localizationService.getLocalizedString('incidents.reportedDate'),
			maxDate
		);
		validationService.validateRequiredField(newState, 'incidentTypeId', 'incidentTypeIdError');
		validationService.validateRequiredField(newState, 'incidentStatusId', 'incidentStatusIdError');
		validationService.validateRequiredField(newState, 'incidentSourceId', 'incidentSourceIdError');

		validationService.validateRequiredField(newState, 'incidentDescription', 'incidentDescriptionError');

		validationService.validateLatitudeField(newState, 'latitude', 'latitudeError', 'incidents.latitude', false);
		validationService.validateLongitudeField(newState, 'longitude', 'longitudeError', 'incidents.longitude', false);

		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			'incidentNumberError',
			'cityError',
			'addressError',
			'latitudeError',
			'longitudeError',
			'reportedDateError',
			'incidentTypeIdError',
			'incidentStatusIdError',
			'incidentSourceIdError',
			'incidentDescriptionError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
			return false;
		}

		if (!modalAttachmentsService.validateBeforeSave(modalAttachmentsState.currentAttachments)) {
			alertService.addError(
				localizationService.getLocalizedString('screen.validationMessage.exceedUploadSizeLimit')
			);
			return false;
		}
		return true;
	};

	const resetIncidentForm = () => {
		if (props.isAddModal) {
			setFormState(initialFormFields);
		} else {
			if (props.incident) {
				const newState: FormFields = getNewFormFieldState(props.incident);
				setFormState(newState);
			}
		}
	};

	const saveIncidentCallbackOnSuccess = (savedIncident: Incident, isCreation: boolean) => {
		modalAttachmentsService.saveAttachments(savedIncident.incidentId as number, () => {
			alertService.addSuccess(
				localizationService.getLocalizedString(
					isCreation ? 'alertMessages.addSuccess' : 'alertMessages.updateSuccess',
					'incidents.incident'
				)
			);
			props.setShowModal();

			if (props.saveIncidentCallback) {
				props.saveIncidentCallback();
			}
		});
	};

	const saveIncident = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let incidentToAddOrUpdate: Incident = {};

		incidentToAddOrUpdate.incidentId = formState.incidentId ? formState.incidentId : 0;
		incidentToAddOrUpdate.incidentNumber = formState.incidentNumber;
		incidentToAddOrUpdate.address = formState.address;
		incidentToAddOrUpdate.city = formState.city;
		incidentToAddOrUpdate.latitude = formState.latitude;
		incidentToAddOrUpdate.longitude = formState.longitude;
		incidentToAddOrUpdate.reportedDate = formState.reportedDate;
		incidentToAddOrUpdate.incidentTypeId = formState.incidentTypeId;
		incidentToAddOrUpdate.incidentStatusId = formState.incidentStatusId;
		incidentToAddOrUpdate.incidentSourceId = formState.incidentSourceId;
		incidentToAddOrUpdate.assetId = formState.assetId;
		incidentToAddOrUpdate.investigatorId = formState.investigatorId;
		incidentToAddOrUpdate.incidentDescription = formState.incidentDescription;
		incidentToAddOrUpdate.resolvedDate = formState.resolvedDate;
		incidentToAddOrUpdate.resolutionDescription = formState.resolutionDescription;

		let incidentContributors: IncidentContributor[] = [];
		if (selectedKnownFacilities) {
			incidentContributors = [...incidentContributors, ...selectedKnownFacilities];
		}
		if (selectedSuspectedFacilities) {
			incidentContributors = [...incidentContributors, ...selectedSuspectedFacilities];
		}
		incidentToAddOrUpdate.incidentContributorsJson = JSON.stringify(incidentContributors);

		if (props.isAddModal) {
			dispatch(
				incidentsSlice.createOne(incidentToAddOrUpdate, true, undefined, (payload: any) => {
					let createdItems = payload.items as Incident[];
					if (createdItems.length > 0) {
						saveIncidentCallbackOnSuccess(createdItems[0], true);
					}
				})
			);
		} else {
			if (props.incident && props.incident.incidentId) {
				incidentToAddOrUpdate.incidentId = props.incident.incidentId;
				dispatch(
					incidentsSlice.patchOne(
						props.incident.incidentId,
						incidentToAddOrUpdate,
						true,
						undefined,
						updatedIncident => {
							saveIncidentCallbackOnSuccess(updatedIncident, false);
						}
					)
				);
			}
		}
	};

	const cancelSaveIncident = () => {
		resetIncidentForm();
		props.setShowModal();
	};

	const onDeleteIncident = async () => {
		if (props.incident && props.incident.incidentId) {
			dispatch(
				incidentsSlice.deleteOne(
					props.incident.incidentId,
					true,
					localizationService.getLocalizedString('alertMessages.removeSuccess', 'incidents.incident'),
					() => {
						onToggleDeleteIncidentModal();
						if (props.showModal) {
							props.setShowModal();
						}
						if (props.deleteIncidentCallback) {
							props.deleteIncidentCallback();
						}
					}
				)
			);
		}
	};

	const onToggleDeleteIncidentModal = () => {
		setToggleDeleteIncidentModal(!deleteIncidentModal);
	};

	let deleteIncidentModalProp: DeleteModalProp = {
		title: localizationService.getLocalizedString('incidents.deleteIncidentTitle'),
		message: localizationService.getLocalizedString(
			'incidents.deleteIncidentMessage',
			props.incident && props.incident.address ? props.incident.address : '',
			props.incident && props.incident.incidentNumber ? props.incident.incidentNumber : ''
		),
		showModal: deleteIncidentModal,
		onCancelButtonClick: onToggleDeleteIncidentModal,
		onOkayButtonClick: onDeleteIncident,
		okayButtonText: localizationService.getLocalizedString('screen.buttons.delete'),
		isDeleteButton: true
	};

	const onPillRemove = (contributor: IncidentContributor) => {
		if (contributor.isKnownContributor) {
			setSelectedKnownFacilities(selectedKnownFacilities.filter(c => c.entityId !== contributor.entityId));
		} else {
			setSelectedSuspectedFacilities(
				selectedSuspectedFacilities.filter(c => c.entityId !== contributor.entityId)
			);
		}
	};

	const getFacilityPill = (contributor: IncidentContributor) => {
		return (
			<div className="facility-pill align-items-center" key={`ai-tag-${contributor.displayName}`}>
				<div className="pl-2">
					<FacilitySvg fillColor={'#666'} />
				</div>
				<div className="pl-2">{contributor.displayName}</div>
				<div className="pl-2 pr-2 cursor-pointer">
					<span className="close-facility-pill" onClick={() => onPillRemove(contributor)}>
						<CloseSvg size="8" />
					</span>
				</div>
			</div>
		);
	};

	const getIncidentContributorSection = () => {
		return (
			<>
				<div className="form-row">
					<div className="form-group col-6 mb-0">
						<label>{localizationService.getLocalizedString('incidents.suspectedContributors')}:</label>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-6 mb-0">{addFacilitiesUsingMapText()}</div>
				</div>
				<div className="form-row" id="suspectedContributorsContainer">
					{selectedSuspectedFacilities
						.sort((a, b) =>
							MapDataSortService.comparePossiblyUndefinedStrings(a.displayName, b.displayName)
						)
						.map((facility, index: number) => {
							return getFacilityPill(facility);
						})}
				</div>

				<div className="form-row mt-2">
					<div className="form-group col-6 mb-0">
						<label>{localizationService.getLocalizedString('incidents.knownContributors')}:</label>
					</div>
				</div>
				<div className="form-row">
					<div className="form-group col-6 mb-0">{addFacilitiesUsingMapText()}</div>
				</div>
				<div className="form-row mb-3" id="knownContributorsContainer">
					{selectedKnownFacilities
						.sort((a, b) =>
							MapDataSortService.comparePossiblyUndefinedStrings(a.displayName, b.displayName)
						)
						.map((facility, index: number) => {
							return getFacilityPill(facility);
						})}
				</div>
			</>
		);
	};

	const addFacilitiesUsingMapText = () => {
		return <span>{localizationService.getLocalizedString('incidents.addFacilitiesUsingMap')}</span>;
	};

	const modalFooterDiv = (
		<>
			{!props.isAddModal && (
				<button type="button" className="btn ai-secondary-delete" onClick={onToggleDeleteIncidentModal}>
					{localizationService.getLocalizedString('screen.buttons.delete')}
				</button>
			)}
			<button className="btn ai-save ml-auto" onClick={saveIncident}>
				{localizationService.getLocalizedString('screen.buttons.save')}
			</button>
			<button className="btn ai-white" onClick={cancelSaveIncident}>
				{localizationService.getLocalizedString('screen.buttons.cancel')}
			</button>
		</>
	);

	return (
		<div className="w-100">
			<PopoverModal
				showModal={props.showModal}
				title={
					props.isAddModal
						? localizationService.getLocalizedString('incidents.addIncident')
						: localizationService.getLocalizedString('incidents.editIncident')
				}
				save={saveIncident}
				cancel={cancelSaveIncident}
				footer={modalFooterDiv}>
				<TextInput
					id="incidentNumber"
					name="incidentNumber"
					label={localizationService.getLocalizedString('incidents.incidentNumber')}
					value={formState.incidentNumber}
					onChange={changeFormState}
					isRequired={true}
					error={formState.incidentNumberError}
				/>

				<TextInput
					id="address"
					name="address"
					label={localizationService.getLocalizedString('incidents.addressOrCrossStreet')}
					value={formState.address}
					onChange={changeFormState}
					isRequired={true}
					error={formState.addressError}
				/>

				<TextInput
					id="city"
					name="city"
					label={localizationService.getLocalizedString('incidents.city')}
					value={formState.city}
					onChange={changeFormState}
					isRequired={true}
					error={formState.cityError}
				/>
				<div className="form-row">
					<TextInput
						id="latitude"
						name="latitude"
						type="number"
						className="form-group col-6"
						label={localizationService.getLocalizedString('incidents.latitude')}
						value={formState.latitude}
						onChange={changeFormState}
						error={formState.latitudeError}
						remainingInputProps={{ min: -90, max: 90 }}
					/>

					<TextInput
						id="longitude"
						name="longitude"
						type="number"
						className="form-group col-6"
						label={localizationService.getLocalizedString('incidents.longitude')}
						value={formState.longitude}
						onChange={changeFormState}
						error={formState.longitudeError}
						remainingInputProps={{ min: -180, max: 180 }}
					/>
				</div>

				<DateInput
					id="reportedDate"
					name="reportedDate"
					label={localizationService.getLocalizedString('incidents.reportedDate')}
					value={formState.reportedDate}
					error={formState.reportedDateError}
					isRequired={true}
					onChange={changeFormState}
					max={DateUtilService.getAuthorityTimezoneNow()}
				/>

				<SingleSelectDropdown
					id="incidentTypeId"
					name="incidentTypeId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.type')}
					value={formState.incidentTypeId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentTypeOptionValues}
					isRequired
					error={formState.incidentTypeIdError}
				/>

				<SingleSelectDropdown
					id="incidentStatusId"
					name="incidentStatusId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.status')}
					value={formState.incidentStatusId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentStatusOptionValues}
					isRequired
					error={formState.incidentStatusIdError}
				/>

				<SingleSelectDropdown
					id="incidentSourceId"
					name="incidentSourceId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.source')}
					value={formState.incidentSourceId}
					onChange={changeFormState}
					selfOrder
					options={fogIncidentSourceOptionValues}
					isRequired
					error={formState.incidentSourceIdError}
				/>
				<TextAreaInput
					id="incidentDescription"
					name="incidentDescription"
					label={localizationService.getLocalizedString('incidents.description')}
					value={formState.incidentDescription}
					onChange={changeFormState}
					isFullWidth={true}
					isRequired={true}
					error={formState.incidentDescriptionError}
				/>
				<TextInput
					id="assetId"
					name="assetId"
					label={localizationService.getLocalizedString('incidents.assetId')}
					value={formState.assetId}
					onChange={changeFormState}
				/>
				<SingleSelectDropdown
					id="investigatorId"
					name="investigatorId"
					className="form-group input-single-line"
					label={localizationService.getLocalizedString('incidents.investigatorId')}
					value={formState.investigatorId}
					onChange={changeFormState}
					selfOrder
					options={authorityUserOptions}
				/>

				{!props.isAddModal && (
					<>
						<DateInput
							id="resolvedDate"
							name="resolvedDate"
							label={localizationService.getLocalizedString('incidents.resolvedDate')}
							value={formState.resolvedDate}
							onChange={changeFormState}
							max={DateUtilService.getAuthorityTimezoneNow()}
						/>

						<TextAreaInput
							id="resolutionDescription"
							name="resolutionDescription"
							label={localizationService.getLocalizedString('incidents.resolutionDescription')}
							value={formState.resolutionDescription}
							onChange={changeFormState}
							isFullWidth={true}
						/>
					</>
				)}

				<ModalAttachments
					service={modalAttachmentsService}
					className="form-group"
					attachments={modalAttachmentsState.currentAttachments}
				/>

				{showMapFeature && getIncidentContributorSection()}
				<IncidentBatchScheduleInspection
					rootStateSelector={s => s.actionModals.fogInspectionGridBatchOperationModal}
					actionModalState={ActionModalActionType.fogInspectionGridBatchOperationModal}
					selectedSuspectedFacilities={selectedSuspectedFacilities}
					selectedKnownFacilities={selectedKnownFacilities}></IncidentBatchScheduleInspection>
			</PopoverModal>
			<DeleteModal {...deleteIncidentModalProp} key="deleteIncidentModal" />
		</div>
	);
};
