import React from 'react';
import { SingleCheckbox } from 'src/components/widgets';
import { localizationService } from 'src/services';
import './timeline.scss';
import { TimelineSettings, TimelineFilterByEventTypeEnum } from './timeline-type';
import { FeatureNames } from '@rcp/types';
import { useReduxSelector } from 'src/redux';

interface OwnProps {
	timelineFilterByForm: TimelineSettings;
	filterRuleChanged: (filterBy: TimelineFilterByEventTypeEnum) => void;
	closeModal: () => void;
}

type filterActionMenuProp = OwnProps;

export const TimelineFilterActionMenu: React.SFC<filterActionMenuProp> = props => {
	return (
		<div className="dropdown-menu timeline-filter-action-menu-div" aria-labelledby="filterBtn">
			<button
				className="close-dropdown flex-row-reverse"
				aria-label="Close and cancel any changes"
				onClick={props.closeModal}>
				<svg role="img" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
					<rect
						x="3.3"
						y="-1"
						width="1.4"
						height="10"
						rx="0.35"
						transform="translate(-1.65 4) rotate(-45)"></rect>
					<rect
						x="-1"
						y="3.3"
						width="10"
						height="1.4"
						rx="0.35"
						transform="translate(-1.66 4) rotate(-45)"></rect>
				</svg>
			</button>
			<form>
				<SingleCheckbox
					id="filterByCommentsCbx"
					name="filterByCommentsCbx"
					label={localizationService.getLocalizedString('screen.timeline.comments')}
					checked={props.timelineFilterByForm.comments}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.comments)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByPhoneCallsCbx"
					name="filterByPhoneCallsCbx"
					label={localizationService.getLocalizedString('screen.timeline.phoneCalls')}
					checked={props.timelineFilterByForm.phoneCalls}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.phoneCalls)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByEmailsCbx"
					name="filterByEmailsCbx"
					label={localizationService.getLocalizedString('screen.timeline.emails')}
					checked={props.timelineFilterByForm.emails}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.emails)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByInspectionsCbx"
					name="filterByInspectionsCbx"
					label={localizationService.getLocalizedString('screen.labels.inspections')}
					checked={props.timelineFilterByForm.inspections}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.inspections)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByPumpOutEventsCbx"
					name="filterByPumpOutEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.cleanings')}
					checked={props.timelineFilterByForm.pumpOutEvents}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.pumpOutEvents)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByViolationEventsCbx"
					name="filterByViolationEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.violations')}
					checked={props.timelineFilterByForm.violations}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.violations)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByEnforcementEventsCbx"
					name="filterByEnforcementEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.enforcements')}
					checked={props.timelineFilterByForm.enforcements}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.enforcements)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByEventsCbx"
					name="filterByEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.events')}
					checked={props.timelineFilterByForm.events}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.events)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByCleaningEventsCbx"
					name="filterByCleaningNoticeEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.cleaningNotices')}
					checked={props.timelineFilterByForm.sentCleaningNotices}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentCleaningNotices)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByGeneralEventsCbx"
					name="filterByGeneralNoticeEventsCbx"
					label={localizationService.getLocalizedString('screen.labels.generalNotices')}
					checked={props.timelineFilterByForm.sentGeneralNotices}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.sentGeneralNotices)}
					className="dropdown-item"
				/>
				<SingleCheckbox
					id="filterByIncidentEventsCbx"
					name="filterByIncidentEventsCbx"
					label={'Incidents'}
					checked={props.timelineFilterByForm.incidents}
					onChange={() => props.filterRuleChanged(TimelineFilterByEventTypeEnum.incidents)}
					className="dropdown-item"
				/>
			</form>
		</div>
	);
};
