import { faCheckCircle, faCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IppReportPackageParameter, IppSampleResult } from '@rcp/types';
import _ from 'lodash';
import { SampleCompliance } from 'src/constants';
import { localizationService } from 'src/services';

const { Good, Bad, None } = SampleCompliance;

export class SampleUtils {
	static getComplianceMessage = (
		sampleResult: IppSampleResult,
		concentrationMinValue: any,
		concentrationMaxValue: any,
		colorClass: string,
		messageLocalizationKey: string
	) => {
		const { enteredValue, parameterName } = sampleResult;
		let type = colorClass === 'text-danger' ? Bad : colorClass === 'text-success' ? Good : None;
		if (
			messageLocalizationKey === 'badAboveMaxComplianceIconAndMessage' ||
			messageLocalizationKey === 'goodComplianceMaxIconAndMessage'
		) {
			return {
				message: SampleUtils.formatString(
					localizationService.getLocalizedString(`ipp.samples.step2.results.${messageLocalizationKey}`),
					parameterName || '',
					(enteredValue || '').toString(),
					(concentrationMaxValue || '').toString()
				),
				color: colorClass,
				type: type
			};
		}
		return {
			message: SampleUtils.formatString(
				localizationService.getLocalizedString(`ipp.samples.step2.results.${messageLocalizationKey}`),
				parameterName || '',
				(enteredValue || '').toString(),
				(concentrationMinValue || '').toString(),
				(concentrationMaxValue || '').toString()
			),
			color: colorClass,
			type: type
		};
	};

	static getRangeLimit = (sampleResult: IppSampleResult, concentrationMinValue: any, concentrationMaxValue: any) => {
		const { qualifier, enteredValue } = sampleResult;
		if (
			qualifier !== 'ND' &&
			qualifier !== 'NF' &&
			enteredValue !== undefined &&
			((qualifier === '<' && (enteredValue > concentrationMaxValue || enteredValue <= concentrationMinValue)) ||
				(qualifier === '>' &&
					(enteredValue >= concentrationMaxValue || enteredValue < concentrationMinValue)) ||
				enteredValue > concentrationMaxValue ||
				enteredValue < concentrationMinValue)
		) {
			//blank qualifier/exact value
			return SampleUtils.getComplianceMessage(
				sampleResult,
				concentrationMinValue,
				concentrationMaxValue,
				'text-danger',
				'badOutsideRangeComplianceIconAndMessage'
			);
		} else {
			//If there's no result don't show compliance badge at all
			if (enteredValue !== null || qualifier === 'ND' || qualifier === 'NF') {
				return SampleUtils.getComplianceMessage(
					sampleResult,
					concentrationMinValue,
					concentrationMaxValue,
					'text-success',
					'goodComplianceRangeIconAndMessage'
				);
			} else {
				return {
					message: '',
					color: 'text-success',
					type: None
				};
			}
		}
	};

	static getMaxLimit = (sampleResult: IppSampleResult, concentrationMinValue: any, concentrationMaxValue: any) => {
		const { qualifier, enteredValue } = sampleResult;

		if (
			qualifier !== 'ND' &&
			qualifier !== 'NF' &&
			!concentrationMinValue &&
			concentrationMaxValue &&
			enteredValue != null &&
			((qualifier === '>' && enteredValue >= concentrationMaxValue) || enteredValue > concentrationMaxValue)
		) {
			return SampleUtils.getComplianceMessage(
				sampleResult,
				concentrationMinValue,
				concentrationMaxValue,
				'text-danger',
				'badAboveMaxComplianceIconAndMessage'
			);
		} else {
			//If there's no result don't show compliance badge at all
			if (enteredValue !== null || qualifier === 'ND' || qualifier === 'NF') {
				return SampleUtils.getComplianceMessage(
					sampleResult,
					concentrationMinValue,
					concentrationMaxValue,
					'text-success',
					'goodComplianceMaxIconAndMessage'
				);
			} else {
				return {
					message: '',
					color: 'text-success',
					type: None
				};
			}
		}
	};

	static hideCompliance = (sampleResult: IppSampleResult) => {
		const { qualifier, enteredValue, parameterName } = sampleResult;
		if (qualifier !== 'ND' && qualifier !== 'NF' && enteredValue === null) {
			return {
				message: '',
				color: 'text-success',
				type: 'none'
			};
		} else {
			if (parameterName !== undefined) {
				return {
					message: SampleUtils.formatString(
						localizationService.getLocalizedString(
							'ipp.samples.step2.results.unknownComplianceIconAndMessage'
						),
						parameterName
					),
					color: 'text-success',
					type: 'none'
				};
			} else {
				return {
					message: '',
					color: 'text-success',
					type: 'none'
				};
			}
		}
	};

	static displayConcentrationCompliance = (
		sampleResult: IppSampleResult,
		selectedParameters?: IppReportPackageParameter[]
	) => {
		const { enteredValue, parameterName, ippParameter } = sampleResult;
		let parameter = _.find(selectedParameters, parameter => parameter.name === parameterName) || {
			...ippParameter
		};
		let concentrationMaxValue = null;
		let concentrationMinValue = null;
		if (parameter) {
			concentrationMinValue =
				parameter.concentrationMinValue === undefined ? null : parameter.concentrationMinValue;
			concentrationMaxValue =
				parameter.concentrationMaxValue === undefined ? null : parameter.concentrationMaxValue;
		}
		//If a limit exists at all, there will always be a max value. Optionally a min value could exist as well.
		if (concentrationMaxValue !== null && enteredValue !== undefined && parameterName !== undefined) {
			if (concentrationMinValue !== null) {
				//range limit
				return SampleUtils.getRangeLimit(sampleResult, concentrationMinValue, concentrationMaxValue);
			} else {
				//max limit only
				return SampleUtils.getMaxLimit(sampleResult, concentrationMinValue, concentrationMaxValue);
			}
		} else {
			//no limits
			//Don't show compliance icon at all if there isn't a valid result
			return SampleUtils.hideCompliance(sampleResult);
		}
	};

	static displayMassLoadingCompliance = (
		sampleResult: IppSampleResult,
		selectedParameters?: IppReportPackageParameter[],
		isMassLoadingResultToUseLessThanSign?: string
	) => {
		const { qualifier, parameterName, ippParameter } = sampleResult;
		if (parameterName) {
			let isMassLoadingResultToUseLessThanSignValue = isMassLoadingResultToUseLessThanSign
				? String.equalCaseInsensitive(isMassLoadingResultToUseLessThanSign.toString(), 'true')
				: false;
			let parameter = _.find(selectedParameters, parameter => parameter.name === parameterName) || {
				...ippParameter
			};
			let massLoadingMaxValue = null;
			let massLoadingMinValue = null;
			if (parameter) {
				massLoadingMaxValue = parameter.massLoadingMaxValue;
				massLoadingMinValue = parameter.massLoadingMinValue;
			}
			let massLoadingValue = Number(sampleResult.massLoadingValue);
			if (massLoadingMaxValue != null) {
				if (massLoadingMinValue != null) {
					//range limit
					if (
						qualifier !== 'ND' &&
						qualifier !== 'NF' &&
						((isMassLoadingResultToUseLessThanSignValue &&
							qualifier === '<' &&
							massLoadingValue <= massLoadingMinValue) ||
							massLoadingValue > massLoadingMaxValue ||
							massLoadingValue < massLoadingMinValue)
					) {
						return {
							message: SampleUtils.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.badOutsideRangeComplianceIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMinValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-danger',
							type: 'bad'
						};
					} else {
						return {
							message: SampleUtils.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceRangeIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMinValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-success',
							type: 'good'
						};
					}
				} else {
					//max limit only
					if (
						qualifier !== 'ND' &&
						qualifier !== 'NF' &&
						!massLoadingMinValue &&
						massLoadingValue > massLoadingMaxValue
					) {
						return {
							message: SampleUtils.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.badAboveMaxComplianceIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-danger',
							type: 'bad'
						};
					} else {
						return {
							message: SampleUtils.formatString(
								localizationService.getLocalizedString(
									'ipp.samples.step2.results.goodComplianceMaxIconAndMessage'
								),
								parameterName,
								massLoadingValue.toString(),
								massLoadingMaxValue.toString()
							),
							color: 'text-success',
							type: 'good'
						};
					}
				}
			} else {
				//no limits
				return {
					message: SampleUtils.formatString(
						localizationService.getLocalizedString(
							'ipp.samples.step2.results.unknownComplianceIconAndMessage'
						),
						parameterName
					),
					color: 'text-success',
					type: 'none'
				};
			}
		}
		return {
			message: '',
			color: 'text-success',
			type: 'none'
		};
	};

	static formatString = (str: string, ...val: string[]) => {
		for (let index = 0; index < val.length; index++) {
			str = str.replace(`{${index}}`, val[index]);
		}
		return str;
	};

	static getComplianceIcon = (type: string) => {
		if (String.equalCaseInsensitive(type, SampleCompliance.Good)) {
			return faCheckCircle;
		} else if (String.equalCaseInsensitive(type, SampleCompliance.Bad)) {
			return faExclamationCircle;
		} else {
			return faCircle;
		}
	};
}
