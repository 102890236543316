import { RestSlice } from 'src/redux/rest.slice';
import { localizationService, Resource } from 'src/services';
import { FacilityPortalOrganizationDetail } from '@rcp/types';

export const facilitySettingsSlice = new RestSlice<FacilityPortalOrganizationDetail>(
	localizationService.getLocalizedString('facilityPortal.settings.facilities.facilitySettings'),
	Resource.FogFacilities
);

export const facilitySettingsRestSliceReducer = facilitySettingsSlice.rests.reducer;
