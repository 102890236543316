import { RegulatoryProgramName } from '@rcp/types';
import React, { useEffect } from 'react';
import { Translate } from 'src/components/widgets/translate/translator';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { FogHelpUrls, IppHelpUrls } from 'src/constants';
import { localizationService, tokenService, urlService } from 'src/services';
import { translateService } from 'src/services/translate-service';

export const HelpLink: React.FC<{}> = props => {
	const languageContext = React.useContext(LanguageContext);
	const [helpTitle, setHelpTitle] = React.useState('');
	const getHelpUrlBasedOnProgram = (): string => {
		if (String.equalCaseInsensitive(urlService.getProgramOrThrowError(), RegulatoryProgramName.IPP.toLowerCase())) {
			return urlService.isIppAuthorityPortal()
				? IppHelpUrls.authority
				: IppHelpUrls.industry;
		} else if (
			String.equalCaseInsensitive(urlService.getProgramOrThrowError(), RegulatoryProgramName.FOG.toLowerCase())
		) {
			return urlService.isAuthorityPortal()
				? FogHelpUrls.authority
				: urlService.isHaulerPortal()
				? FogHelpUrls.hauler
				: FogHelpUrls.facility;
		} else {
			return window.location.pathname;
		}
	};
	const getHelpUrl = (langCode: string) => {
		let helpUrl = '#';
		let currentToken = tokenService.getTokenOrDefault();
		if (tokenService.isTokenValid(currentToken)) {
			let params = {
				path: getHelpUrlBasedOnProgram(),
				accessToken: currentToken.accessToken
			};
			helpUrl = urlService.getHelpPath() + `?` + urlService.toQueryString(params);
		}
		if (urlService.isFacilityPortal()) {
			return urlService.appendLanguageParameter(helpUrl, langCode);
		}
		return helpUrl;
	};

	return (
		<a
			title={helpTitle}
			href={getHelpUrl(languageContext?.language?.code || 'EN')}
			target="_blank"
			rel="noopener noreferrer">
			<img id="help-img" src="img/icons/help.svg" alt="" />
			<br />
			<span className="header-title">
				<Translate>{localizationService.getLocalizedString('screen.labels.help')}</Translate>
			</span>
		</a>
	);
};
