import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

import {
	localizationService,
	validationService,
	urlService,
	apiService,
	documentService,
	UtilService
} from 'src/services';
import { PopoverModal, SingleCheckbox, TextAreaInput, TextInput } from 'src/components/widgets';
import {
	EmailMessage,
	EmailAddress,
	ContactEmail,
	SendEmailDropDownOption,
	TemplateResponse,
	LetterTemplateCategory,
	EntityContact,
	EntityContact_EntityType,
	FeatureNames,
	FacilityEntityContact
} from '@rcp/types';
import {
	FacilityContactListState,
	alertService,
	ApplicationState,
	FogFacilityState,
	RootState,
	loadFacilityContact,
	useReduxSelector
} from 'src/redux';

import _ from 'lodash';
import { sendDeviceEmail, sendFacilityEmail } from 'src/redux/facility/email';
import { SendEmailMultiSelectDropdown } from './send-email-multi-select-dropdown';

interface OwnProps {
	anchor?: HTMLElement;
	show: boolean;
	toggle: () => void;
	letterTemplateCategory: LetterTemplateCategory;
	inspectionId?: number;
	searchParams?: string;
	selectedEmail?: any[];
	isDeviceDetail?: boolean;
	deviceId?: number;
	facilityId?: number;
}

interface StateProps extends FogFacilityState, FacilityContactListState {}

interface DispatchProps {
	sendFacilityEmail: (emailMessage: EmailMessage) => Promise<boolean | undefined>;
	sendDeviceEmail: (emailMessage: EmailMessage, facilityId: number, deviceId: number) => Promise<boolean | undefined>;
	loadFacilityContact: (facilityId: number, includeDeviceContacts: boolean) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface FormFields {
	recipientEmailAddresses: EmailAddress[];
	subject: string;
	content: string;

	recipientEmailAddressesError?: string;
	subjectError?: string;
	contentError?: string;
	sendMailToSelf?: boolean;
	ccRecipientEmailAddresses: EmailAddress[];
	ccRecipientEmailAddressesError?: string;
}

const initialFormFields: FormFields = {
	recipientEmailAddresses: [],
	subject: '',
	content: '',
	ccRecipientEmailAddresses: [],
	sendMailToSelf: false
};

const FacilitySendEmailComp: React.SFC<Props> = props => {
	const [formState, setFormState] = React.useState(initialFormFields);
	const [recipientEmailOptionValues, setRecipientEmailOptionValues] = React.useState([] as SendEmailDropDownOption[]);
	const [ccRecipientEmailOptionValues, setCCrecipientEmailOptionValues] = React.useState(
		[] as SendEmailDropDownOption[]
	);

	const [contacts, setContacts] = React.useState<EntityContact[]>([]);

	let deviceContacts = useSelector((state: RootState) => state.entityContacts.result);

	let deviceState = (state: RootState) => state.fogDevices;
	let { selected: selectedDevice, loading } = useSelector(deviceState);
	let facilityLastHauler = useSelector((state: RootState) => state.reduxFogFacilities.selected);
	let facilityContactState = useSelector((state: RootState) => state.facilityContacts);

	const sendEmailDropDownOptionComparator = (
		left: SendEmailDropDownOption,
		right: SendEmailDropDownOption
	): boolean => {
		return (
			left.name === right.name &&
			left.email === right.email &&
			left.group === right.group &&
			left.value === right.value &&
			left.selected === right.selected
		);
	};

	const distinctSndEmailDropDownOptions = (options: SendEmailDropDownOption[]) => {
		let distinctOptions = _.uniqWith(options, sendEmailDropDownOptionComparator);
		return [...distinctOptions];
	};

	React.useEffect(() => {
		let facilityId = urlService.getFogFacilityId();
		let viewFacility = facilityId > 0;
		if (facilityId < 1 && selectedDevice && selectedDevice.facilityId) {
			facilityId = selectedDevice.facilityId;
		}
		if (facilityId) {
			props.loadFacilityContact(facilityId, viewFacility);
		}
	}, [selectedDevice]);

	React.useEffect(() => {
		if (props.show) {
			let emailTemplateUrl = '';
			switch (props.letterTemplateCategory) {
				case LetterTemplateCategory.FacilityEmail:
					emailTemplateUrl =
						props.facility && props.facility.facilityId
							? urlService.getFacilityEmailTemplateUrl(1, props.facility.facilityId)
							: '';
					break;
				case LetterTemplateCategory.InspectionEmail:
					emailTemplateUrl = props.inspectionId
						? urlService.getInspectionEmailTemplateUrl(1, props.inspectionId)
						: '';
					break;
				case LetterTemplateCategory.DeviceEmail:
					emailTemplateUrl =
						selectedDevice && selectedDevice.facilityId
							? urlService.getDeviceEmailTemplateUrl(selectedDevice.facilityId)
							: '';
					break;
			}
			if (emailTemplateUrl) {
				apiService
					.getResource<TemplateResponse>(emailTemplateUrl)
					.then((templateResponse: TemplateResponse) => {
						let newFormState = { ...formState } as FormFields;
						if (!_.isEmpty(templateResponse.transformedSubject)) {
							newFormState.subject = templateResponse.transformedSubject as string;
						}
						let modifiedBody = templateResponse.transformedBody.replaceAll('</p><p>', '</p>\r\n\r\n<p>');
						modifiedBody = modifiedBody.replaceAll('<p><br></p>', '');
						modifiedBody = modifiedBody.replaceAll('<br>', '&#13;');
						newFormState.content = documentService.htmlToText(modifiedBody);
						if (
							formState.recipientEmailAddresses.length === 0 &&
							props.selectedEmail &&
							props.selectedEmail.length > 0
						) {
							newFormState.recipientEmailAddresses = [
								...formState.recipientEmailAddresses,
								...props.selectedEmail
							];
						}
						setFormState(newFormState);
					});
			}
		}
	}, [props.facility, props.show, props.inspectionId, props.letterTemplateCategory, props.selectedEmail]);

	React.useEffect(() => {
		if (props.show) {
			getContactsList();
		}
	}, [props.show, selectedDevice, deviceContacts, props.facility, facilityContactState]);

	const getContactsList = () => {
		let newContacts: EntityContact[] = [];
		const deviceId = urlService.getFogDeviceId();
		if (!_.isEmpty(deviceContacts) && deviceId > 0) {
			newContacts = [...deviceContacts];
		} else if (!_.isEmpty(facilityContactState.facilityContactList.fogDeviceContacts)) {
			newContacts = newContacts.concat(facilityContactState.facilityContactList.fogDeviceContacts);
		}
		if (!_.isEmpty(facilityContactState.facilityContactList.fogFacilityContacts)) {
			newContacts = newContacts.concat(facilityContactState.facilityContactList.fogFacilityContacts);
		}

		let lastHaulerContact: EntityContact = {} as EntityContact;
		if (facilityLastHauler && !_.isEmpty(facilityLastHauler.lastHaulerName)) {
			lastHaulerContact = {
				haulerName: facilityLastHauler.lastHaulerName,
				haulerPhoneNumber: facilityLastHauler.lastHaulerPhoneNumber,
				haulerEmail: facilityLastHauler.lastHaulerEmailAddress as string,
				haulerNumber: facilityLastHauler.lastHaulerNumber,
				haulerId: facilityLastHauler.lastHaulerId as number,
				sendNotices: (selectedDevice && selectedDevice.sendCleaningNoticesToLastHauler) as boolean | undefined,
				addressLine1: facilityLastHauler.lastHaulerAddressLine1 as string,
				addressLine2: facilityLastHauler.lastHaulerAddressLine2 as string,
				addressCity: facilityLastHauler.lastHaulerCityName as string,
				addressJurisdictionCode: facilityLastHauler.lastHaulerJurisdictionCode as string,
				addressZipCode: facilityLastHauler.lastHaulerZipCode as string,
				entityType: EntityContact_EntityType.Hauler
			};
		} else if (!_.isEmpty(props.facility)) {
			lastHaulerContact = {
				haulerName: props.facility.lastHaulerName,
				haulerPhoneNumber: props.facility.lastHaulerPhoneNumber,
				haulerEmail: props.facility.lastHaulerEmailAddress as string,
				haulerNumber: props.facility.lastHaulerNumber,
				haulerId: props.facility.lastHaulerId as number,
				addressLine1: props.facility.lastHaulerAddressLine1 as string,
				addressLine2: props.facility.lastHaulerAddressLine2 as string,
				addressCity: props.facility.lastHaulerCityName as string,
				addressJurisdictionCode: props.facility.lastHaulerJurisdictionCode as string,
				addressZipCode: props.facility.lastHaulerZipCode as string,
				entityType: EntityContact_EntityType.Hauler
			};
		}
		newContacts.unshift(lastHaulerContact);

		if ( props.facility.contractedHaulerId) {
			let contractedHaulerContact: EntityContact = {
				haulerName: props.facility.contractedHaulerName,
				haulerPhoneNumber: props.facility.contractedHaulerPhoneNumber,
				haulerEmail: props.facility.contractedHaulerEmailAddress as string,
				haulerNumber: props.facility.contractedHaulerNumber,
				haulerId: props.facility.contractedHaulerId as number,
				addressLine1: props.facility.contractedHaulerAddressLine1 as string,
				addressLine2: props.facility.contractedHaulerAddressLine2 as string,
				addressCity: props.facility.contractedHaulerCityName as string,
				addressJurisdictionCode: props.facility.contractedHaulerJurisdictionCode as string,
				addressZipCode: props.facility.contractedHaulerZipCode as string,
				entityType: EntityContact_EntityType.Hauler
			};
			newContacts.unshift(contractedHaulerContact);
		}

		setContacts(newContacts);
	};

	React.useEffect(() => {
		const getContactName = (contact: EntityContact) => {
			const name =
				contact.firstName && contact.lastName
					? `${contact.firstName} ${contact.lastName}`
					: contact.firstName || contact.lastName;
			return (
				name ||
				contact.companyName ||
				(contact.jsonEmails && getFirstEmail(UtilService.fromJson(contact.jsonEmails as string)))
			);
		};

		const getFirstEmail = (emails: ContactEmail[]) => {
			return emails.length > 0 ? emails[0].email : '';
		};

		const getContactGroupName = (contact: EntityContact) => {
			if (contact.isPrimary === true) {
				return localizationService.getLocalizedString('screen.labels.primary');
			} else if (contact.contactTypeCode) {
				return contact.contactTypeCode;
			}
			return '';
		};

		let optionValues: SendEmailDropDownOption[] = [];

		if (!props.isDeviceDetail && props.facilityContactList.fogFacilityContacts.length > 0) {
			optionValues = props.facilityContactList.fogFacilityContacts
				.map((contact: FacilityEntityContact) => {
					let name = getContactName(contact);
					let firstEmailAddress = contact.jsonEmails
						? getFirstEmail(UtilService.fromJson(contact.jsonEmails as string))
						: '';
					let emailAddress: EmailAddress = { name: name as string, address: firstEmailAddress };
					let emailAddressValue: string = JSON.stringify(emailAddress);
					let groupName: string = getContactGroupName(contact);
					return {
						name: name,
						email: firstEmailAddress,
						group: groupName,
						value: emailAddressValue,
						selected: false
					} as SendEmailDropDownOption;
				})
				.filter((option: SendEmailDropDownOption) => option.email);
		}

		if (!props.isDeviceDetail && props.facility.lastHaulerName && props.facility.lastHaulerEmailAddress) {
			let emailAddress: EmailAddress = {
				name: props.facility.lastHaulerName,
				address: props.facility.lastHaulerEmailAddress
			};
			let emailAddressValue: string = JSON.stringify(emailAddress);
			optionValues.push({
				name: props.facility.lastHaulerName,
				email: props.facility.lastHaulerEmailAddress,
				group: localizationService.getLocalizedString('facility.lastHaulerName'),
				value: emailAddressValue,
				selected: false
			});
		}

		if (
			!props.isDeviceDetail &&
			props.facility.contractedHaulerName &&
			props.facility.contractedHaulerEmailAddress
		) {
			let emailAddress: EmailAddress = {
				name: props.facility.contractedHaulerName,
				address: props.facility.contractedHaulerEmailAddress
			};
			let emailAddressValue: string = JSON.stringify(emailAddress);
			optionValues.push({
				name: props.facility.contractedHaulerName,
				email: props.facility.contractedHaulerEmailAddress,
				group: localizationService.getLocalizedString('facility.contractedHaulerName'),
				value: emailAddressValue,
				selected: false
			});
		}

		if (contacts && contacts.length > 0) {
			optionValues = contacts
				.map((contact: EntityContact) => {
					let name = contact.haulerName || getContactName(contact);
					let firstEmailAddress =
						contact.haulerEmail ||
						(contact.jsonEmails && getFirstEmail(UtilService.fromJson(contact.jsonEmails as string)));
					let emailAddress: EmailAddress = { name: name as string, address: firstEmailAddress as string };
					let emailAddressValue: string = JSON.stringify(emailAddress);
					let groupName: string = getContactGroupName(contact);
					return {
						name: name,
						email: firstEmailAddress,
						group: groupName,
						value: emailAddressValue,
						selected: false
					} as SendEmailDropDownOption;
				})
				.filter((option: SendEmailDropDownOption) => option.email);
		}
		const selectedEmail = props.selectedEmail && props.selectedEmail[0];
		setCCrecipientEmailOptionValues(distinctSndEmailDropDownOptions(optionValues));
		if (!_.isEmpty(selectedEmail)) {
			let foundSelectedEmail = false;
			const selectedOptionValues = optionValues.map((value: SendEmailDropDownOption) => {
				if (String.equalCaseInsensitive(value.email, selectedEmail.address)) {
					foundSelectedEmail = true;
					return { ...value, selected: true };
				}
				return { ...value };
			});
			if (foundSelectedEmail === false) {
				//Not found selected email from email dropdown options, append selected email to the options
				let selectedEmailOption: SendEmailDropDownOption = {
					name: selectedEmail.name,
					email: selectedEmail.address,
					group: '',
					value: selectedEmail.address,
					selected: true
				};
				selectedOptionValues.push(selectedEmailOption);
			}
			setRecipientEmailOptionValues(distinctSndEmailDropDownOptions(selectedOptionValues));
		} else {
			setRecipientEmailOptionValues(distinctSndEmailDropDownOptions(optionValues));
		}
	}, [
		props.facilityContactList,
		props.selectedEmail,
		props.facility.lastHaulerId,
		props.facility.lastHaulerEmailAddress,
		props.facility.lastHaulerName,
		props.facility.contractedHaulerId,
		props.facility.contractedHaulerEmailAddress,
		props.facility.contractedHaulerName,
		contacts
	]);

	const changeFormState = (e: any, type?: string) => {
		let newState = { ...formState };
		let { name, value } = e.target;

		if (
			type &&
			(String.equalCaseInsensitive(type, 'recipientEmailAddresses') ||
				String.equalCaseInsensitive(type, 'ccRecipientEmailAddresses'))
		) {
			if (Array.isArray(value) && [value.length - 1] !== undefined) {
				value = value.map((option: SendEmailDropDownOption) => {
					return UtilService.fromJson(option.value);
				});
			}
			setFormState({
				...formState,
				[type]: value
			});
		} else {
			if (String.equalCaseInsensitive(e.target.type, 'checkbox')) {
				value = e.target.checked;
			}
			_.set(newState, name, value);

			setFormState(newState);
		}
	};

	const isFormValidateForSave = (): boolean => {
		let newState = { ...formState };

		validationService.validateRequiredField(
			newState,
			'recipientEmailAddresses',
			'recipientEmailAddressesError',
			localizationService.getLocalizedString('sendEmail.to')
		);
		validationService.validateRequiredField(
			newState,
			'subject',
			'subjectError',
			localizationService.getLocalizedString('sendEmail.subject')
		);
		validationService.validateRequiredField(
			newState,
			'content',
			'contentError',
			localizationService.getLocalizedString('sendEmail.content')
		);

		setFormState(newState);

		const isFromValid = !validationService.hasError(
			newState,
			'subjectError',
			'recipientEmailAddressesError',
			'contentError',
			'ccRecipientEmailAddressesError'
		);

		if (!isFromValid) {
			alertService.addError(localizationService.getLocalizedString('screen.validationMessage.formValidation'));
		}

		return isFromValid;
	};

	const onSendEmailBtnClick = async (e: any) => {
		if (!isFormValidateForSave()) {
			e.stopPropagation();
			e.preventDefault();
			return;
		}

		let emailToSend: EmailMessage = {
			recipientEmailAddresses: formState.recipientEmailAddresses,
			subject: formState.subject,
			content: formState.content.replace(/\r?\n/g, '<br />').replaceAll('\r', '<br />'),
			ccRecipientEmailAddresses: formState.ccRecipientEmailAddresses,
			sendMailToSelf: formState.sendMailToSelf
		};

		let sendEmailResult;

		if (urlService.getFogDeviceId() > 0 && selectedDevice) {
			sendEmailResult = await props.sendDeviceEmail(
				emailToSend,
				selectedDevice.facilityId as number,
				selectedDevice.extractorId as number
			);
		} else {
			sendEmailResult = await props.sendFacilityEmail(emailToSend);
		}

		if (sendEmailResult === true) {
			setFormState(initialFormFields);
			const restInitialOptions = recipientEmailOptionValues.map(option => {
				option.selected = false;
				return option;
			});
			setRecipientEmailOptionValues(restInitialOptions);
			setCCrecipientEmailOptionValues(restInitialOptions);
			urlService.setUrlQueryString({});
			props.toggle();
		}
	};

	const onCancelBtnClick = () => {
		setFormState(initialFormFields);
		props.toggle();
		urlService.setUrlQueryString({});
	};

	const handleCcEmailErrors = (error: string) => {
		setFormState({
			...formState,
			ccRecipientEmailAddressesError: error
		});
	};

	const footer = () => {
		return (
			<div className="popup-modal-footer ml-auto">
				<Button color="btn ai-action" id="saveBtn" className="popup-modal-btn" onClick={onSendEmailBtnClick}>
					{localizationService.getLocalizedString('screen.buttons.sendEmail')}
				</Button>
				<Button
					color="btn ai-white"
					id="cancelBtn"
					className="popup-modal-btn"
					onClick={() => {
						onCancelBtnClick();
					}}>
					{localizationService.getLocalizedString('screen.buttons.cancel')}
				</Button>
			</div>
		);
	};

	return (
		<PopoverModal
			title={localizationService.getLocalizedString('sendEmail.sendMail')}
			className="custom-send-email"
			showModal={props.show}
			footer={footer()}
			cancel={onCancelBtnClick}>
			<div className="d-sm-flex flex-column">
				<SendEmailMultiSelectDropdown
					name="recipientEmailAddresses"
					label={localizationService.getLocalizedString('sendEmail.to')}
					onChange={changeFormState}
					options={recipientEmailOptionValues}
					isRequired={true}
					error={formState.recipientEmailAddressesError}
					className=" "
					allowCustom={false}
				/>

				<SendEmailMultiSelectDropdown
					name="ccRecipientEmailAddresses"
					label={localizationService.getLocalizedString('sendEmail.Cc')}
					onChange={changeFormState}
					options={ccRecipientEmailOptionValues}
					isRequired={false}
					error={formState.ccRecipientEmailAddressesError}
					className=" "
					allowCustom={true}
					handleError={handleCcEmailErrors}
				/>

				<TextInput
					id="subject"
					name="subject"
					label={localizationService.getLocalizedString('sendEmail.subject')}
					value={formState.subject}
					onChange={changeFormState}
					isRequired={true}
					error={formState.subjectError}
				/>

				<TextAreaInput
					id="content"
					name="content"
					label={localizationService.getLocalizedString('sendEmail.content')}
					value={formState.content}
					onChange={changeFormState}
					isRequired={true}
					error={formState.contentError}
					isFullWidth={true}
					rows={7}
				/>

				<SingleCheckbox
					id="sendMailToSelf"
					name="sendMailToSelf"
					label={localizationService.getLocalizedString('sendEmail.sendMailToSelf')}
					checked={formState.sendMailToSelf}
					onChange={changeFormState}
					className="div-checkbox"
				/>
			</div>
		</PopoverModal>
	);
};

const mapStateToProps = (state: ApplicationState): StateProps => {
	return { ...state.facilityContacts, ...state.fogFacility };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, Action>): DispatchProps => {
	return {
		sendFacilityEmail: (emailMessage: EmailMessage) => dispatch(sendFacilityEmail(emailMessage)),
		sendDeviceEmail: (emailMessage: EmailMessage, facilityId: number, deviceId: number) =>
			dispatch(sendDeviceEmail(emailMessage, facilityId, deviceId)),
		loadFacilityContact: (facilityId: number, loadDeviceContacts: boolean) =>
			dispatch(loadFacilityContact(facilityId, loadDeviceContacts))
	};
};

export const FacilitySendEmail = connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
	mapStateToProps,
	mapDispatchToProps
)(FacilitySendEmailComp);
