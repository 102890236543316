import React, { useContext, useEffect } from 'react';
import { DropDownOption } from '@rcp/types';
import _ from 'lodash';
import { apiService, urlService } from 'src/services';
import { TextInput } from '.';
import { ConverterFactory } from 'src/services/converter-factory';
import { Translate } from './translate/translator';
import { translateService } from 'src/services/translate-service';
import { LanguageContext } from './translate/translator-context';

interface Props {
	id: string;
	name: string;
	label?: string | JSX.Element;
	withoutWrappingDiv?: boolean;
	isRequired?: boolean;
	displayAsRequired?: boolean;
	isDisabled?: boolean;
	className?: string;
	value?: any;
	error?: string;
	autoFocus?: boolean;
	noEmptyOption?: boolean;
	onChange?(e: any): void;
	options: DropDownOption[];
	isRemovePadding?: boolean;
	selfOrder?: boolean;
	placeholder?: string;
	showEmptyOption?: boolean;
	hintText?: string;
	labelClassName?: string;
	readonly?: boolean;
	preventInternalStateChange?: boolean;
	endpoint?: string;
	converter?: string;
	resetOptions?: boolean;
	doNotTranslateLabel?: boolean;
	doNotTranslateOptions?: boolean;
}

export const SingleSelectDropdown: React.FC<Props> = props => {
	const { language, setLanguage } = useContext(LanguageContext);
	const [readOnlyTextInputValue, setReadOnlyTextInputValue] = React.useState<string>('');
	const changeValue = (e: any) => {
		!props.preventInternalStateChange && setValue(e.target.value);
		if (props.onChange) {
			props.onChange(e);
		}
	};

	const handleChange = (e: any) => {
		changeValue(e);
	};

	const handleKeyPressed = (e: any) => {
		if (e.key === 'Enter') {
			changeValue(e);
		}
	};

	const [options, setOptions] = React.useState<DropDownOption[]>(props.options || []);
	const [translatedOptions, setTranslatedOptions] = React.useState<DropDownOption[]>(props.options || []);
	const [value, setValue] = React.useState<string>(props.value || '');

	React.useEffect(() => {
		setValue(props.value || '');
	}, [props.value]);

	React.useEffect(() => {
		props.resetOptions && setOptions([]);
	}, [props.resetOptions]);

	React.useEffect(() => {
		const { endpoint, converter } = props;
		if (endpoint && converter) {
			apiService.httpGet(props.endpoint || '', true).then(data => {
				let newOptions = ConverterFactory.create(props.converter || '', data.result || data || []);
				pushEmptyOptionLabel(newOptions);
				setOptions(newOptions);
			});
		}
	}, [props.endpoint, props.converter]);

	const pushEmptyOptionLabel = (newOptions: DropDownOption[]) => {
		if (props.noEmptyOption !== true && !newOptions.find(i => _.isEmpty(i.label))) {
			newOptions.unshift({
				label: '',
				value: '',
				isHidden: props.isRequired === true
			});
		}
	};

	React.useEffect(() => {
		if (props.options && props.options.length > 0) {
			let newOptions = !props.selfOrder ? _.orderBy(props.options, ['label'], ['asc']) : [...props.options];
			if (props.noEmptyOption !== true && !newOptions.find(i => _.isEmpty(i.label))) {
				//add empty option when props.noEmptyOption is not true and passing options does not contain empty option
				newOptions.unshift({
					label: '',
					value: '',
					isHidden: props.isRequired === true
				});
			} else if (props.noEmptyOption === true) {
				let emptyOptionIndex = newOptions.findIndex(i => _.isEmpty(i.label));
				if (emptyOptionIndex > -1) {
					//remove empty option when props.noEmptyOption is true and passing options contain empty option
					newOptions.splice(emptyOptionIndex, 1);
				}
			}
			if (props.value) {
				let foundOption = _.find(newOptions, (option: DropDownOption) => {
					return _.toString(option.value) === props.value;
				});
				if (foundOption === undefined) {
					//add selected value as a hidden option
					newOptions.push({
						label: _.toString(props.value),
						value: props.value,
						isHidden: true
					});
				}
			}
			setOptions(newOptions);
		}
	}, [props.value, props.isRequired, props.options, props.selfOrder, props.noEmptyOption]);

	React.useEffect(() => {
		if (props.showEmptyOption === false) {
			const optionList = options.slice(1);
			setOptions(optionList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.showEmptyOption]);

	React.useEffect(() => {
		if (!props.doNotTranslateOptions && language.code !== 'en') {
			translateService.translateDropdown(options).then(res => {
				setTranslatedOptions(res);
			});
		} else {
			setTranslatedOptions(options);
		}
	}, [options, props.doNotTranslateOptions]);

	React.useEffect(() => {
		const selectElement = document.querySelector(`#${props.id}`);
		if (selectElement) {
			let selectClientWidth = selectElement.clientWidth;
			let optionElements = document.querySelectorAll(`#${props.id}>option`);
			if (optionElements && optionElements.length > 0) {
				for (let index = 0; index < optionElements.length; index++) {
					let option = optionElements[index];
					if (option.nodeValue && option.clientWidth > selectClientWidth) {
						option.nodeValue = option.nodeValue.substr(selectClientWidth / 16 - 2) + '...';
					}
				}
			}
		}
		if (!_.isEmpty(translatedOptions) && value) {
			let translatedValue =
				translatedOptions.find((option: DropDownOption) => _.toString(option.value) === value)?.label || '';

			setReadOnlyTextInputValue(translatedValue);
		}
	}, [translatedOptions, props.id, value]);

	if (props.withoutWrappingDiv === true) {
		return (
			<select
				id={props.id}
				name={props.name}
				className={props.className || 'custom-select' + (props.error ? ' is-invalid' : '')}
				onChange={handleChange}
				onKeyPress={handleKeyPressed}
				value={value}
				placeholder={props.placeholder}
				required={props.isRequired === true ? true : false}
				disabled={props.isDisabled === true ? true : false}
				autoFocus={props.autoFocus === true}>
				{translatedOptions.map((optionItem: DropDownOption, index: number) =>
					optionItem.isHtmlLabel === true ? (
						<option
							key={props.id + '-' + index}
							hidden={optionItem.isHidden === true ? true : false}
							selected={_.toString(optionItem.value) === value}
							value={optionItem.value}
							title={optionItem.label}
							dangerouslySetInnerHTML={{ __html: optionItem.label }}></option>
					) : (
						<option
							key={props.id + '-' + index}
							hidden={optionItem.isHidden === true ? true : false}
							selected={_.toString(optionItem.value) === value}
							value={optionItem.value}
							title={optionItem.label}>
							{optionItem.label}
						</option>
					)
				)}
			</select>
		);
	}

	let defaultClass =
		props.className ||
		` form-group ${
			urlService.isIppAuthorityPortal() || urlService.isProfilePage() || urlService.isIppServiceIndustryPortal()
				? 'col-sm-6'
				: ''
		} `;
	defaultClass =
		defaultClass +
		(urlService.isIppAuthorityPortal() || urlService.isProfilePage() || urlService.isIppServiceIndustryPortal()
			? ' form-group '
			: ' ');

	if (!props.readonly) {
		return (
			<div
				className={
					defaultClass +
					(props.isRequired === true || props.displayAsRequired === true ? ' required' : '') +
					(props.isRemovePadding ? ' p-0' : '')
				}>
				{props.isDisabled ? (
					<label className={props.labelClassName + ' disabled-feedback'} htmlFor={props.id}>
						<Translate doNotTranslate={props.doNotTranslateLabel}>{props.label}</Translate>
					</label>
				) : (
					<label className={props.labelClassName} htmlFor={props.id}>
						<Translate doNotTranslate={props.doNotTranslateLabel}>{props.label}</Translate>
					</label>
				)}
				<select
					id={props.id}
					name={props.name}
					className={'custom-select' + (props.error ? ' is-invalid' : '')}
					onChange={handleChange}
					onKeyPress={handleKeyPressed}
					value={value}
					required={props.isRequired === true ? true : false}
					disabled={props.isDisabled === true ? true : false}
					autoFocus={props.autoFocus === true}>
					{props.placeholder && <option value="">{props.placeholder}</option>}
					{translatedOptions.map((optionItem: DropDownOption, index: number) => (
						<option
							key={props.id + '-' + index}
							hidden={optionItem.isHidden === true ? true : false}
							selected={_.toString(optionItem.value) === value}
							value={optionItem.value}
							title={optionItem.label}>
							{optionItem.label}
						</option>
					))}
				</select>
				{props.error && (
					<span className="invalid-feedback d-inline">
						<Translate>{props.error}</Translate>
					</span>
				)}
				{props.hintText && (
					<span className="ai-form-help">
						<Translate>{props.error && ' '}</Translate>
						<Translate>{props.hintText}</Translate>
					</span>
				)}
			</div>
		);
	}
	return (
		<TextInput
			id={props.id}
			name={props.name}
			label={props.label}
			className={`col form-group ${
				props.isRequired === true || props.displayAsRequired === true ? 'required' : ''
			}`}
			isDisabled={true}
			value={readOnlyTextInputValue}
			helpText={props.hintText}
			error={props.error}
			doNotTranslate={props.doNotTranslateLabel}
			doNotTranslateValue={props.doNotTranslateOptions}
		/>
	);
};
