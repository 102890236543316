import { faCheck, faList, faPlus, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Dictionary,
	FeatureNames,
	FilterObjectName,
	FogFacility,
	IncidentContributor,
	LocalStorageName
} from '@rcp/types';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { authorityUsersSlice } from 'src/components/authority/shared/settings';
import { FacilitySvg, IncidentSvg } from 'src/components/svg';
import { customFieldQueries } from 'src/features';
import { customFieldSlice, useReduxSelector } from 'src/redux';
import { localizationService, Resource, urlService } from 'src/services';
import { SearchInput } from '../../search-input';
import { TooltipHover } from '../../tooltip-hover';
import { MapSettings } from '../map-service';
import { FloatAddIncident } from './float-add-incident';
import { QuickFilterFacilitiesSettings } from './quick-filter-facilities';
import { QuickFilterIncidentsSettings } from './quick-filter-incidents';

interface Props {
	queryParameters: Dictionary<string> | undefined;
	setQueryParameters: (queryParameters: Dictionary<string> | undefined) => void;
	togglePreference?: () => void;
	mapSettings: MapSettings;
	setMapSettings: (newSettings: MapSettings) => void;
	showClearGeoData: boolean;
	clearGeoData: () => void;
	handleSidebarOpen: () => void;
	handleSidebarClose: () => void;
	facilityRiskMapFooterOverlay: JSX.Element;
	handleIncidentSaved: () => void;
	toggleSelectFacilityForIncidentMode: (toggle: boolean, isKnownFacilities: boolean) => void;
	selectedKnownFacilitiesForIncident: FogFacility[];
	selectedSuspectedFacilitiesForIncident: FogFacility[];
	clearSelectedFacilities: () => void;
	resetIncidentContributorSelection: () => void;
	removeSelectedFacilityForIncident: (contributor: IncidentContributor) => void;
	headerRef: any;
	setNeedResetMapCameraFocus: (value: boolean) => void;
}

export const FacilityRiskMapHeaderOverlay: React.FC<Props> = props => {
	const [facilityFilterId, setFacilityFilterId] = React.useState<number | undefined>(undefined);
	const [facilityDraftFilterId, setFacilityDraftFilterId] = React.useState<number | undefined>(undefined);
	const [incidentFilterId, setIncidentFilterId] = React.useState<number | undefined>(undefined);
	const [incidentDraftFilterId, setIncidentDraftFilterId] = React.useState<number | undefined>(undefined);
	const [showFacilityFilterRightPanel, setShowFacilityFilterRightPanel] = React.useState(false);
	const [showIncidentFilterRightPanel, setShowIncidentFilterRightPanel] = React.useState(false);
	const [showAddIncident, setShowAddIncident] = React.useState(false);
	const [search, setSearch] = React.useState('');
	const showIncidentFeature = useReduxSelector(
		state => state.featureSettings.featureFlagSettings[FeatureNames.ShowFogIncidentFeature]
	);

	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(authorityUsersSlice.fetchAll());
	}, [dispatch]);

	useEffect(() => {
		dispatch(customFieldSlice.fetchAll(customFieldQueries.Fog));
		initializeQueryParameters();
	}, []);

	const initializeQueryParameters = () => {
		let queryParams = urlService.toQueryDictionary();
		if (queryParams.fromGrid) {
			_.unset(queryParams, 'fromGrid');
		} else {
			urlService.mergeLocalUrlCache(queryParams, LocalStorageName.FacilityMap);
			queryParams = urlService.toQueryDictionary();
		}
		if (queryParams.filterId) {
			setFacilityFilterId(_.toNumber(queryParams.filterId));
		}
		if (queryParams.draftFilterId) {
			setFacilityDraftFilterId(_.toNumber(queryParams.draftFilterId));
		}
		if (queryParams.incidentFilterId) {
			setIncidentFilterId(_.toNumber(queryParams.incidentFilterId));
		}
		if (queryParams.incidentDraftFilterId) {
			setIncidentDraftFilterId(_.toNumber(queryParams.incidentDraftFilterId));
		}

		props.setQueryParameters(queryParams);
		setSearch(queryParams.search);
	};

	const applySearch = (searchTerm: string) => {
		let queryParams = { ...props.queryParameters };
		if (searchTerm) {
			queryParams['search'] = searchTerm;
			props.setNeedResetMapCameraFocus(true);
		} else {
			_.unset(queryParams, 'search');
		}
		setSearch(searchTerm);
		props.setQueryParameters(queryParams);
	};

	const onApplyFilter = (savedFilterId: number | undefined, savedDraftFilterId: number | undefined) => {
		let queryParams = { ...props.queryParameters };
		if (savedDraftFilterId) {
			queryParams['draftFilterId'] = _.toString(savedDraftFilterId);
			_.unset(queryParams, 'filterId');
		} else if (savedFilterId) {
			queryParams['filterId'] = _.toString(savedFilterId);
			_.unset(queryParams, 'draftFilterId');
		}

		props.setQueryParameters(queryParams);
		setFacilityDraftFilterId(savedDraftFilterId);
		setFacilityFilterId(savedFilterId);
	};

	const onApplyIncidentFilter = (
		savedIncidentFilterId: number | undefined,
		savedIncidentDraftFilterId: number | undefined
	) => {
		let queryParams = { ...props.queryParameters };

		if (savedIncidentDraftFilterId) {
			queryParams['incidentDraftFilterId'] = _.toString(savedIncidentDraftFilterId);
			_.unset(queryParams, 'incidentFilterId');
		} else if (savedIncidentFilterId) {
			queryParams['incidentFilterId'] = _.toString(savedIncidentFilterId);
			_.unset(queryParams, 'incidentDraftFilterId');
		}

		props.setQueryParameters(queryParams);
		setIncidentDraftFilterId(savedIncidentDraftFilterId);
		setIncidentFilterId(savedIncidentFilterId);
	};

	const onClearFilter = () => {
		let queryParameterKeys = _.keys(props.queryParameters);
		let adhocIncidentQueryParameters = queryParameterKeys.filter(i => i.includes('incident.'));
		let queryParams = urlService.pickParameters(
			{ ...props.queryParameters },
			'search',
			'incidentDraftFilterId',
			'incidentFilterId',
			...adhocIncidentQueryParameters
		);
		props.setQueryParameters(queryParams);
		setFacilityFilterId(undefined);
		setFacilityDraftFilterId(undefined);
	};

	const onClearIncidentFilter = () => {
		let queryParameterKeys = _.keys(props.queryParameters);
		let adhocFacilityQueryParameters = queryParameterKeys.filter(i => {
			var key = _.toLower(i);
			return (
				!key.includes('incident.') &&
				!key.includes('search') &&
				!key.includes('filterid') &&
				!key.includes('draftfilterid')
			);
		});
		let queryParams = urlService.pickParameters(
			{ ...props.queryParameters },
			'search',
			'draftFilterId',
			'filterId',
			...adhocFacilityQueryParameters
		);
		props.setQueryParameters(queryParams);
		setIncidentFilterId(undefined);
		setIncidentDraftFilterId(undefined);
	};

	const onFilterSaved = (filterId: number) => {
		let queryParams = { ...props.queryParameters };
		_.unset(queryParams, 'draftFilterId');
		_.set(queryParams, 'filterId', filterId);
		props.setQueryParameters(queryParams);
		setFacilityFilterId(filterId);
		setFacilityDraftFilterId(undefined);
	};

	const onIncidentFilterSaved = (incidentFilterId: number) => {
		let queryParams = { ...props.queryParameters };
		_.unset(queryParams, 'incidentDraftFilterId');
		_.set(queryParams, 'incidentFilterId', incidentFilterId);
		props.setQueryParameters(queryParams);
		setIncidentFilterId(incidentFilterId);
		setIncidentDraftFilterId(undefined);
	};

	const getGridLink = (resource: string) => {
		let queryParameterKeys = _.keys(props.queryParameters);
		let url = `${resource}?`;

		if (!String.equalCaseInsensitive(resource, Resource.Incidents)) {
			let adhocFacilityQueryParameters = queryParameterKeys.filter(i => {
				var key = _.toLower(i);
				return (
					!key.includes('search') &&
					!key.includes('filterid') &&
					!key.includes('draftfilterid') &&
					!key.includes('incident.')
				);
			});
			let queryParams = urlService.pickParameters({ ...props.queryParameters }, ...adhocFacilityQueryParameters);
			url = `${url}${urlService.toQueryString(queryParams)}`;

			if (facilityFilterId) {
				url = `${url}&filterId=${_.toString(facilityFilterId)}`;
			}
			if (facilityDraftFilterId) {
				url = `${url}&draftFilterId=${_.toString(facilityDraftFilterId)}`;
			}
			if (search) {
				url = `${url}&search=${search}`;
			}
		} else if (String.equalCaseInsensitive(resource, Resource.Incidents)) {
			let adhocIncidentQueryParameters = queryParameterKeys.filter(i => {
				var key = _.toLower(i);
				return (
					!key.includes('search') &&
					!key.includes('filterid') &&
					!key.includes('draftfilterid') &&
					key.includes('incident.')
				);
			});
			let queryParams = urlService.pickParameters({ ...props.queryParameters }, ...adhocIncidentQueryParameters);
			url = `${url}${urlService.toQueryString(queryParams)}`;

			if (incidentFilterId) {
				url = `${url}&filterId=${_.toString(incidentFilterId)}`;
			}
			if (incidentDraftFilterId) {
				url = `${url}&draftFilterId=${_.toString(incidentDraftFilterId)}&`;
			}
			if (search) {
				url = `${url}&search=${search}`;
			}
		}
		url = `${url}&fromMap=true`;
		return url;
	};

	const onFacilityFilterActionMenuBtnClicked = (e: any) => {
		setShowIncidentFilterRightPanel(false);
		setShowFacilityFilterRightPanel(true);
		props.handleSidebarOpen();
	};

	const closeFacilityFilterActionRightPanel = () => {
		setShowFacilityFilterRightPanel(false);
		if (!showAddIncident) {
			props.handleSidebarClose();
		}
	};

	const onIncidentFilterActionMenuBtnClicked = (e: any) => {
		setShowFacilityFilterRightPanel(false);
		setShowIncidentFilterRightPanel(true);
		props.handleSidebarOpen();
	};

	const onAddIncidentActionMenuBtnClicked = () => {
		setShowFacilityFilterRightPanel(false);
		setShowIncidentFilterRightPanel(false);
		setShowAddIncident(true);
		props.handleSidebarOpen();
	};

	const closeIncidentFilterActionRightPanel = () => {
		setShowIncidentFilterRightPanel(false);
		if (!showAddIncident) {
			props.handleSidebarClose();
		}
	};

	const closeAddIncidentActionRightPanel = () => {
		setShowAddIncident(false);
		props.handleSidebarClose();
	};

	const handleIncidentSaved = () => {
		setShowAddIncident(false);
		props.handleIncidentSaved();
	};

	const handleAdHocFilterDeletion = (adHocFilterKey: string) => {
		if (props.queryParameters && props.setQueryParameters) {
			let queryParameters = _.cloneDeep(props.queryParameters);
			if (_.unset(queryParameters, adHocFilterKey)) {
				props.setQueryParameters(queryParameters);
			}
		}
	};

	return (
		<>
			<div className="filter-container" ref={props.headerRef}>
				<div className="form-row">
					<div className="col-auto mb-2">
						<SearchInput
							ariaLabel="Search"
							searchClass="map-search"
							search={applySearch}
							searchTerm={search}
						/>
					</div>
					<div className="col-auto mb-2">
						<button
							className={`btn ai-white ${(facilityDraftFilterId || facilityFilterId) &&
								'filter-selected'}`}
							id="filterFacilityMenuBtn"
							onClick={onFacilityFilterActionMenuBtnClicked}>
							{facilityDraftFilterId || facilityFilterId ? (
								<FontAwesomeIcon icon={faCheck} />
							) : (
								<FacilitySvg fillColor={'#666'} />
							)}
							<span className="px-1">
								{localizationService.getLocalizedString('facility.facilities')}
							</span>
						</button>
					</div>
					<div className="col-auto mb-2">
						<button
							className={`btn ai-white ${(incidentDraftFilterId || incidentFilterId) &&
								'filter-selected'}`}
							id="filterIncidentMenuBtn"
							onClick={onIncidentFilterActionMenuBtnClicked}>
							{incidentDraftFilterId || incidentFilterId ? (
								<FontAwesomeIcon icon={faCheck} />
							) : (
								<IncidentSvg />
							)}
							<span className="px-1">
								{localizationService.getLocalizedString('incidents.incidents')}
							</span>
						</button>
					</div>
					<div className="mb-2 col-auto pl-0 pr-0">
						<UncontrolledDropdown>
							<DropdownToggle tag="button">
								<TooltipHover
									id="gridLinkButton"
									icon={faList}
									className="grid-dropdown"
									title={localizationService.getLocalizedString('map.showInTable')}
									iconFontClass=" font-size-16-regular align-self-center"
									position="auto"
									isWithoutArrow={true}
									attachToDiv={true}
								/>
							</DropdownToggle>
							<DropdownMenu>
								<DropdownItem id="facilityGridLink" tag={Link} to={getGridLink(Resource.FogFacilities)}>
									{localizationService.getLocalizedString('screen.labels.facilities')}
								</DropdownItem>
								<DropdownItem id="inspectionGridLink" tag={Link} to={getGridLink(Resource.Inspections)}>
									{localizationService.getLocalizedString('screen.labels.inspections')}
								</DropdownItem>
								<DropdownItem id="cleaningGridLink" tag={Link} to={getGridLink(Resource.Cleanings)}>
									{localizationService.getLocalizedString('screen.labels.cleanings')}
								</DropdownItem>
								<DropdownItem id="deviceGridLink" tag={Link} to={getGridLink(Resource.Devices)}>
									{localizationService.getLocalizedString('cccDevice.devices')}
								</DropdownItem>
								<DropdownItem id="eventGridLink" tag={Link} to={getGridLink(Resource.Events)}>
									{localizationService.getLocalizedString('events.events')}
								</DropdownItem>
								<DropdownItem id="violationGridLink" tag={Link} to={getGridLink(Resource.Violations)}>
									{localizationService.getLocalizedString('enforcement.violations')}
								</DropdownItem>
								{showIncidentFeature && (
									<DropdownItem id="incidentGridLink" tag={Link} to={getGridLink(Resource.Incidents)}>
										{localizationService.getLocalizedString('incidents.incidents')}
									</DropdownItem>
								)}

								{props.togglePreference && (
									<>
										<div tabIndex={-1} className="dropdown-divider"></div>
										<DropdownItem id="togglePreference" onClick={props.togglePreference}>
											{localizationService.getLocalizedString('screen.labels.preference')}
										</DropdownItem>
									</>
								)}
							</DropdownMenu>
						</UncontrolledDropdown>
					</div>
					{showIncidentFeature && (
						<div className="mb-2 col-auto pl-0 ">
							<UncontrolledDropdown>
								<DropdownToggle tag="button">
									<TooltipHover
										id="addEntityButton"
										icon={faPlus}
										className="grid-dropdown"
										title={localizationService.getLocalizedString('incidents.addIncident')}
										iconFontClass=" font-size-16-regular align-self-center"
										position="auto"
										isWithoutArrow={true}
										attachToDiv={true}
									/>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										id="addIncidentButton"
										tag={Link}
										onClick={onAddIncidentActionMenuBtnClicked}>
										{localizationService.getLocalizedString('incidents.addIncident')}
									</DropdownItem>
									{/* <DropdownItem id="addFacilityButton" tag={Link}>
										{localizationService.getLocalizedString('screen.buttons.addFacility')}
									</DropdownItem> */}
								</DropdownMenu>
							</UncontrolledDropdown>
						</div>
					)}

					{props.showClearGeoData && (
						<div className="mb-2 col-auto pl-0">
							<TooltipHover
								id="clearGeoDataButton"
								icon={faRectangleXmark}
								className="grid-dropdown"
								title={localizationService.getLocalizedString('map.clearGeoData')}
								iconFontClass=" font-size-16-regular align-self-center"
								position="auto"
								isWithoutArrow={true}
								attachToDiv={true}
								onIconClick={props.clearGeoData}
							/>
						</div>
					)}
				</div>
			</div>
			{showAddIncident && (
				<FloatAddIncident
					isAddPanel={true}
					handleClose={closeAddIncidentActionRightPanel}
					facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
					handleIncidentSaved={handleIncidentSaved}
					toggleSelectFacilityForIncidentMode={props.toggleSelectFacilityForIncidentMode}
					selectedKnownFacilities={props.selectedKnownFacilitiesForIncident}
					selectedSuspectedFacilities={props.selectedSuspectedFacilitiesForIncident}
					resetIncidentContributorSelection={props.resetIncidentContributorSelection}
					clearSelectedFacilities={props.clearSelectedFacilities}
					removeSelectedContributor={props.removeSelectedFacilityForIncident}
				/>
			)}
			{showFacilityFilterRightPanel && (
				<QuickFilterFacilitiesSettings
					handleClose={closeFacilityFilterActionRightPanel}
					onApplyFilter={onApplyFilter}
					onClearFilter={onClearFilter}
					onFilterSaved={onFilterSaved}
					facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
					draftFilterId={facilityDraftFilterId}
					filterId={facilityFilterId}
					queryParameters={props.queryParameters || {}}
					setQueryParameters={props.setQueryParameters}
					handleAdHocFilterDeletion={handleAdHocFilterDeletion}
				/>
			)}

			{showIncidentFilterRightPanel && (
				<QuickFilterIncidentsSettings
					handleClose={closeIncidentFilterActionRightPanel}
					onApplyFilter={onApplyIncidentFilter}
					onClearFilter={onClearIncidentFilter}
					onFilterSaved={onIncidentFilterSaved}
					facilityRiskMapFooterOverlay={props.facilityRiskMapFooterOverlay}
					draftFilterId={incidentDraftFilterId}
					filterId={incidentFilterId}
					queryParameters={props.queryParameters || {}}
					setQueryParameters={props.setQueryParameters}
					handleAdHocFilterDeletion={handleAdHocFilterDeletion}
				/>
			)}
		</>
	);
};
