import React from 'react';

import * as ApiTypes from '@rcp/types';
import { getDateContent } from './timeline-helper';
import { RecordViolationForm } from '../../../violation';
import { localizationService } from 'src/services';

interface OwnProps {
	violation: ApiTypes.Violation;
	facilityId?: number;
	index: number;
	todayNow: string;
	violationTimelineType: ViolationTimelineType;
	deleteCallBack: () => void;
	saveCallBack: () => void;
}

export enum ViolationTimelineType {
	NonComplianceDate,
	ComplianceDueDate,
	InComplianceDate
}

type Props = OwnProps;

export const TimelineEventViolation: React.SFC<Props> = props => {
	const [isViolationModalOpened, setIsViolationModalOpened] = React.useState(false);

	const openViolationModel = (e: any) => {
		e.preventDefault();
		setIsViolationModalOpened(true);
	};

	const closeViolationModal = () => {
		setIsViolationModalOpened(false);
	};

	const getViolationEventContent = () => {
		if (props.violationTimelineType === ViolationTimelineType.NonComplianceDate) {
			return ``;
		}

		if (props.violation.violationTypeTypeName) {
			let dateContent = getDateContent(
				props.todayNow,
				'',
				props.violation.inComplianceDate,
				props.violation.complianceDueDate
			);
			return dateContent;
		}

		return '';
	};

	let textPrefix = '';
	if (props.violationTimelineType === ViolationTimelineType.NonComplianceDate) {
		textPrefix = `${localizationService.getLocalizedString(
			'violation.violation'
		)} ${localizationService.getLocalizedString('violation.for')} `;
	} else {
		textPrefix = `${localizationService.getLocalizedString(
			'violation.compliance'
		)} ${localizationService.getLocalizedString('violation.for')} `;
	}
	return (
		<div>
			<div>
				{`${textPrefix}`}
				<a href="#/" onClick={openViolationModel} id={`linkViolation-${props.index}`}>
					{`${props.violation.violationTypeTypeName}`}
				</a>
				{getViolationEventContent()}
			</div>
			{isViolationModalOpened && (
				<RecordViolationForm
					closeModal={closeViolationModal}
					facilityId={props.facilityId}
					sourceId={props.violation.sourceId}
					sourceType={props.violation.sourceType}
					violation={props.violation}
					deleteCallback={props.deleteCallBack}
					saveCallback={props.saveCallBack}
				/>
			)}
		</div>
	);
};
