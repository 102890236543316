import React, { FC, useEffect, useState } from 'react';
import {
	ApiError,
	IppDataSourceCollectionMethod,
	IppDataSourceCtsEventType,
	IppDataSourceMonitoringPoint,
	IppDataSourceParameter,
	IppDataSourceUnit,
	RouteProps
} from '@rcp/types';
import { apiService, localizationService, Resource, urlService } from 'src/services';
import EditableGrid from './editable-grid';
import { History } from 'history';
import { ippMonitoringPointSlice } from './monitoring-point-slice';
import { ippCollectionMethodSlice } from './collection-method-slice';
import { ippCtsEventTypeSlice } from './cts-event-type-slice';
import { ippParameterSlice } from './parameter-slice';
import { ippUnitSlice } from './units-slice';
import { alertService, RootState } from 'src/redux';
import { useDispatch, useSelector } from 'react-redux';
import './translations.scss';
import { IppConstants } from 'src/constants';
import { AccessDeniedPage } from 'src/features/home/access-denied';

interface Props extends RouteProps {
	history: History;
}

const { sortDropDownQueryParam, sortTranslationsQueryParam } = IppConstants.translations;

const Translations: FC<Props> = props => {
	const dispatch = useDispatch();

	let monitoringPointState = (state: RootState) => state.ippMonitoringPoint;
	let dataSourceMonitoringPointState = useSelector(monitoringPointState);

	let collectionMethodState = (state: RootState) => state.ippCollectionMethod;
	let dataSourceCollectionMethodState = useSelector(collectionMethodState);

	let eventTypeState = (state: RootState) => state.ippCtsEventType;
	let dataSourceCtsEventTypeState = useSelector(eventTypeState);

	let parameterState = (state: RootState) => state.ippParameter;
	let dataSourceParameterState = useSelector(parameterState);

	let unitState = (state: RootState) => state.ippUnit;
	let dataSourceUnitState = useSelector(unitState);

	let [dataSourceMonitoringPoints, setDataSourceMonitoringPoints] = useState<IppDataSourceMonitoringPoint[]>();
	let [dataSourceCollectionMethods, setDataSourceCollectionMethods] = useState<IppDataSourceCollectionMethod[]>();
	let [dataSourceCtsEventTypes, setDataSourceCtsEventTypes] = useState<IppDataSourceCtsEventType[]>();
	let [dataSourceParameters, setDataSourceParameters] = useState<IppDataSourceParameter[]>();
	let [dataSourceUnits, setDataSourceUnits] = useState<IppDataSourceUnit[]>();
	let [monitoringPoints, setMonitoringPoints] = useState();
	let [collectionMethods, setCollectionMethods] = useState();
	let [eventTypes, setEventTypes] = useState();
	let [parameters, setParameters] = useState();
	let [units, setUnits] = useState();
	let [isAccessDenied, setIsAccessDenied] = useState(false);

	const checkIfAccessDenied = (error: ApiError) => {
		if (
			error &&
			error.statusCode === 403 &&
			String.equalCaseInsensitive(
				error.message,
				localizationService.getLocalizedString('errors.noPermissionToAccess')
			)
		) {
			alertService.clearAllMessages();
			setIsAccessDenied(true);
		}
	};

	const getMonitoringPoints = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppMonitoringPoints, sortDropDownQueryParam);
		let monitoringPoints: any = await apiService.getResource(url);
		setMonitoringPoints(monitoringPoints);
	};

	const getCollectionMethods = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppCollectionMethods, sortDropDownQueryParam);
		let collectionMethods: any = await apiService.getResource(url);
		setCollectionMethods(collectionMethods);
	};

	const getEventTypes = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.CtsEventTypes + '?isForSample=true');
		let eventTypes: any = await apiService.getResource(url);
		setEventTypes(eventTypes);
	};

	const getParameters = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppParameters, sortDropDownQueryParam);
		let parameters: any = await apiService.getResource(url);
		setParameters(parameters);
	};

	const getUnits = async () => {
		let url = urlService.getAuthorityResourcesApiUrl(Resource.IppUnits, sortDropDownQueryParam);
		let units: any = await apiService.getResource(url);
		units = units.filter((unit: any) => unit.isActive && unit.isAvailableToRegulatee);
		setUnits(units);
	};

	useEffect(() => {
		dispatch(
			ippMonitoringPointSlice.fetchAll(
				`dataSourceId=${props.match.params.dataSourceId}${sortTranslationsQueryParam}`,
				undefined,
				checkIfAccessDenied
			)
		);
		dispatch(
			ippCollectionMethodSlice.fetchAll(
				`dataSourceId=${props.match.params.dataSourceId}${sortTranslationsQueryParam}`,
				undefined,
				checkIfAccessDenied
			)
		);
		dispatch(
			ippCtsEventTypeSlice.fetchAll(
				`dataSourceId=${props.match.params.dataSourceId}${sortTranslationsQueryParam}`,
				undefined,
				checkIfAccessDenied
			)
		);
		dispatch(
			ippParameterSlice.fetchAll(
				`dataSourceId=${props.match.params.dataSourceId}${sortTranslationsQueryParam}`,
				undefined,
				checkIfAccessDenied
			)
		);
		dispatch(
			ippUnitSlice.fetchAll(
				`dataSourceId=${props.match.params.dataSourceId}${sortTranslationsQueryParam}`,
				undefined,
				checkIfAccessDenied
			)
		);
		getMonitoringPoints();
		getCollectionMethods();
		getEventTypes();
		getParameters();
		getUnits();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setDataSourceMonitoringPoints(dataSourceMonitoringPointState.result);
	}, [dataSourceMonitoringPointState.result, dataSourceMonitoringPointState.result.length]);

	useEffect(() => {
		setDataSourceCollectionMethods(dataSourceCollectionMethodState.result);
	}, [dataSourceCollectionMethodState.result, dataSourceCollectionMethodState.result.length]);

	useEffect(() => {
		setDataSourceCtsEventTypes(dataSourceCtsEventTypeState.result);
	}, [dataSourceCtsEventTypeState.result, dataSourceCtsEventTypeState.result.length]);

	useEffect(() => {
		setDataSourceParameters(dataSourceParameterState.result);
	}, [dataSourceParameterState.result, dataSourceParameterState.result.length]);

	useEffect(() => {
		setDataSourceUnits(dataSourceUnitState.result);
	}, [dataSourceUnitState.result, dataSourceUnitState.result.length]);

	return isAccessDenied ? (
		<AccessDeniedPage />
	) : (
		<>
			<div className="d-flex w-100 flex-column flex-lg-row">
				<div className="d-flex flex-column w-100">
					<div className="page-header">
						<h1>
							{localizationService.getLocalizedString(
								'ipp.industryAccount.dataProviders.translations.heading'
							)}
						</h1>
					</div>
					<EditableGrid<IppDataSourceMonitoringPoint>
						history={props.history}
						accordionType="monitoring-points"
						list={dataSourceMonitoringPoints || []}
						dropDownData={monitoringPoints}
						restSlice={ippMonitoringPointSlice}
						match={props.match}
						dropDownId="monitoringPointId"
						dataId="dataSourceMonitoringPointId"
						localizationPrefix="monitoringPoints"
						sortTranslationsQueryParam={sortTranslationsQueryParam}
					/>
					<EditableGrid<IppDataSourceCollectionMethod>
						history={props.history}
						accordionType="collection-methods"
						list={dataSourceCollectionMethods || []}
						dropDownData={collectionMethods}
						restSlice={ippCollectionMethodSlice}
						match={props.match}
						dropDownId="collectionMethodId"
						dataId="dataSourceCollectionMethodId"
						localizationPrefix="collectionMethods"
						sortTranslationsQueryParam={sortTranslationsQueryParam}
					/>
					<EditableGrid<IppDataSourceCtsEventType>
						history={props.history}
						accordionType="sample-types"
						list={dataSourceCtsEventTypes || []}
						dropDownData={eventTypes}
						restSlice={ippCtsEventTypeSlice}
						match={props.match}
						dropDownId="ctsEventTypeId"
						dataId="dataSourceCtsEventTypeId"
						localizationPrefix="sampleTypes"
						sortTranslationsQueryParam={sortTranslationsQueryParam}
					/>
					<EditableGrid<IppDataSourceUnit>
						history={props.history}
						accordionType="unit"
						list={dataSourceUnits || []}
						dropDownData={units}
						restSlice={ippUnitSlice}
						match={props.match}
						dropDownId="unitId"
						dataId="dataSourceUnitId"
						localizationPrefix="units"
						sortTranslationsQueryParam={sortTranslationsQueryParam}
					/>
					<EditableGrid<IppDataSourceParameter>
						history={props.history}
						accordionType="parameters"
						list={dataSourceParameters || []}
						dropDownData={parameters}
						restSlice={ippParameterSlice}
						match={props.match}
						dropDownId="parameterId"
						dataId="dataSourceParameterId"
						localizationPrefix="parameters"
						sortTranslationsQueryParam={sortTranslationsQueryParam}
					/>
				</div>
			</div>
		</>
	);
};

export default Translations;
