import { PackageReviewActionType, PackageReviewAction } from './reviews-actions';
import { PackageReviewState, initialPackageReviewState } from './reviews-state';

export const ippReportPackageReducer = (
	state = initialPackageReviewState,
	action: PackageReviewAction
): PackageReviewState => {
	switch (action.type) {
		case PackageReviewActionType.LoadPackageReviewRequest:
		case PackageReviewActionType.DownloadPackageReviewRequest:
		case PackageReviewActionType.DownloadPackageReviewSuccess:
		case PackageReviewActionType.SubmissionReviewRequest:
		case PackageReviewActionType.SubmissionReviewSuccess:
		case PackageReviewActionType.RepudiationReviewRequest:
		case PackageReviewActionType.RepudiationReviewSuccess:
		case PackageReviewActionType.AcceptRejectReviewRequest:
		case PackageReviewActionType.AcceptRejectReviewSuccess:
			return { ...state };
		case PackageReviewActionType.LoadPackageReviewSuccess:
			return {
				...state,
				reportPackage: action.reportPackage
			};
		default:
			return state;
	}
};
