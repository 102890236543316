import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { RootState } from 'src/redux';
import { urlService, localizationService, validationService } from 'src/services';
import { FacilityLinkForm } from './facility-link-form';
import './facility-link.scss';
import { EditLinkModal } from './facility-link-edit-modal';
import { AuthorityAware, FacilityLink, LinkType } from '@rcp/types';
import { deleteFacilityLinks, loadFacilityLinks, saveFacilityLinks } from 'src/redux/facility/facility-links';
import { DeleteModal } from 'src/components/widgets';
import { AuditAware } from '@rcp/types';

interface Props {
	creatorUserProfileId?: number;
	inspectionId?: number;
	isReadOnly?: boolean;
}

export interface FormState extends AuthorityAware, AuditAware {
	displayText?: string;
	url?: string;
	linkError?: string;
	nameError?: string;
	linkOwnershipId?: number;
}

export const initialFormValue = {
	displayText: '',
	url: '',
	linkError: '',
	nameError: ''
};

export const FacilityLinks: React.SFC<Props> = props => {
	const [isToggle, setIsToggle] = useState(false);
	const [isEditToggle, setIsEditToggle] = useState(false);
	const [isEditFormModal, setEditFormModal] = useState(false);
	const [toggleDeleteLink, settoggleDeleteLinkModal] = useState(false);
	const [selectedFacilityLink, setselectedFacilityLink] = React.useState({} as FacilityLink);
	const [formState, setFormState] = React.useState(initialFormValue as FormState);

	const dispatch = useDispatch();
	const facilityId = urlService.getFogFacilityId();
	let getFacilityLink = (state: RootState) => state.facilityLinks;
	const { facilityLinks } = useSelector(getFacilityLink);

	React.useEffect(() => {
		getAllLinks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	React.useEffect(() => {
		setFormState(initialFormValue);
	}, [isToggle, isEditToggle]);

	const getAllLinks = () => {
		let linkTypeParameter = props.inspectionId ? LinkType.inspectionLink : null;
		dispatch(
			loadFacilityLinks(facilityId, true, linkTypeParameter as LinkType, props.inspectionId, (links: any[]) => {
				if (!(links.length > 0)) {
					setIsEditToggle(false);
					isToggle && toggle();
				}
			})
		);
	};

	const changeFormField = (event: any) => {
		setFormState({
			...formState,
			[event.target.name]: event.target.value
		});
	};

	const onAddClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsToggle(true);
			setIsEditToggle(false);
		}
	};

	const toggle = () => {
		setIsToggle(!isToggle);
	};

	const onEditClicked = (e: any) => {
		e.preventDefault();
		if (!isToggle) {
			setIsEditToggle(true);
			setIsToggle(true);
		}
	};

	const isFormValidateRequire = () => {
		if (
			validationService.validateRequiredField(formState, 'displayText', 'nameError', 'Link Name') &&
			validationService.validateRequiredField(formState, 'url', 'linkError', 'Link')
		) {
			setFormState({ ...formState });
			return true;
		}
		setFormState({ ...formState });
		return false;
	};

	const saveLink = (e: any) => {
		e.preventDefault();
		const regexUrl = /^(http|https|ftp|smtp):\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

		if (isFormValidateRequire()) {
			if (!regexUrl.test(formState.url as string)) {
				setFormState({
					...formState,
					linkError: localizationService.getLocalizedString('screen.validationMessage.invalidUrlFormat')
				});
				return;
			}

			const creatorUserId = props.creatorUserProfileId;
			let linkToPost = {
				creatorUserId: creatorUserId,
				...formState
			};
			if (props.inspectionId) {
				const inspectionLink = {
					linkType: 'InspectionLink',
					ownerId: props.inspectionId
				};
				linkToPost = {
					...linkToPost,
					...inspectionLink
				};
			}

			dispatch(
				saveFacilityLinks(
					urlService.getFogFacilityId(),
					{
						...linkToPost
					},
					'',
					() => {
						getAllLinks();
						if (linkToPost.linkOwnershipId) {
							setEditFormModal(!isEditFormModal);
						} else {
							toggle();
						}
					}
				)
			);
		}
	};

	const cancelEditingTags = () => {
		toggle();
	};

	const onEditLink = (link: FacilityLink) => {
		setFormState({
			...formState,
			...link,
			nameError: '',
			linkError: ''
		});
		setEditFormModal(!isEditFormModal);
	};

	const onDelteLink = (link: FacilityLink) => {
		setselectedFacilityLink(link);
		settoggleDeleteLinkModal(true);
	};

	const deleteLinks = () => {
		dispatch(
			deleteFacilityLinks(
				urlService.getFogFacilityId(),
				selectedFacilityLink.linkOwnershipId as number,
				'',
				() => {
					getAllLinks();
					settoggleDeleteLinkModal(false);
				}
			)
		);
	};

	const hasFacilityLinks = (): boolean => facilityLinks.length > 0;
	return (
		<section>
			<div id="facilitiesLinks" className="d-flex">
				<h3>{localizationService.getLocalizedString('screen.labels.links')}</h3>
				{hasFacilityLinks() && !isToggle && !props.isReadOnly && (
					<a
						href="#/"
						className="font-size-16px ai-edit-link ml-auto mt-n1 link-section"
						onClick={onEditClicked}>
						{localizationService.getLocalizedString('screen.buttons.edit')}
					</a>
				)}
			</div>
			{isToggle && isEditToggle && !props.isReadOnly ? (
				<div>
					<div id="editLinkList" className={` ${hasFacilityLinks() ? ' py-3' : null}`}>
						<table className="table-width">
							{facilityLinks.map((link: FacilityLink, index: number) => {
								return (
									<tr
										className={`link-list ${
											index === facilityLinks.length - 1 ? 'last-links' : ''
										}`}>
										<td>
											<a href={`${link.url}`} target="_blank link-section">
												<span>{link.displayText}</span>
											</a>
										</td>
										<td className="table-icon">
											<button
												onClick={() => {
													onEditLink(link);
												}}>
												<FontAwesomeIcon
													id="editIcon"
													icon={faPencilAlt}
													className="font-awesome-icon fonticon-size link-section "
												/>
											</button>
										</td>
										<td className="table-icon">
											<button
												onClick={() => {
													onDelteLink(link);
												}}>
												<FontAwesomeIcon
													id="deleteIcon"
													icon={faTrashAlt}
													className="font-awesome-icon ml-3 fonticon-size link-section"
													onClick={() => {
														onDelteLink(link);
													}}
												/>
											</button>
										</td>
									</tr>
								);
							})}
						</table>
						<div className="d-flex justify-content-end mt-2">
							<Button type="submit" id="applyBtn" color="btn ai-action" onClick={cancelEditingTags}>
								{localizationService.getLocalizedString('screen.buttons.apply')}
							</Button>
							<Button className="ml-2" id="cancelBtn" color="btn ai-white" onClick={cancelEditingTags}>
								{localizationService.getLocalizedString('screen.buttons.cancel')}
							</Button>
						</div>
					</div>
				</div>
			) : (
				<div id="addLinkList">
					<div className={` ${hasFacilityLinks() ? ' py-3' : null}`}>
						{facilityLinks.map((link: FacilityLink, index: number) => {
							return (
								<div className="ai-edit-link" key={'ai-link-' + index}>
									<a
										className="link-section"
										href={link.url}
										rel="noopener noreferrer"
										target="_blank">
										<span>{link.displayText}</span>
									</a>
								</div>
							);
						})}
						{!isEditToggle && isToggle && (
							<>
								<div className="mt-3">
									<FacilityLinkForm changeFormField={changeFormField} formState={formState} />
								</div>
								<div id="action-btn" className="d-flex justify-content-end mt-2">
									<Button
										type="submit"
										id="applyBtn-add"
										color="btn ai-action"
										onClick={e => saveLink(e)}>
										{localizationService.getLocalizedString('screen.buttons.apply')}
									</Button>
									<Button
										className="ml-2"
										id="cancelBtn"
										color="btn ai-white"
										onClick={cancelEditingTags}>
										{localizationService.getLocalizedString('screen.buttons.cancel')}
									</Button>
								</div>
							</>
						)}
					</div>
					{!isToggle && !props.isReadOnly && (
						<a href="#/" className="ai-add-link link-section" onClick={onAddClicked}>
							{localizationService.getLocalizedString('screen.buttons.addLink')}&#8230;
						</a>
					)}
				</div>
			)}
			{isEditFormModal && (
				<EditLinkModal
					isToggle={isEditFormModal}
					toggle={() => {
						setEditFormModal(!isEditFormModal);
					}}
					changeFormField={changeFormField}
					formState={formState}
					saveLink={saveLink}
				/>
			)}
			{toggleDeleteLink && (
				<DeleteModal
					key="confirmDeleteLink"
					title={localizationService.getLocalizedString('facility.link.deleteLinkTitle')}
					message={localizationService.getLocalizedString('facility.link.linkDeleteMessage')}
					showModal={toggleDeleteLink}
					onCancelButtonClick={() => {
						settoggleDeleteLinkModal(false);
					}}
					onOkayButtonClick={deleteLinks}
					okayButtonText={localizationService.getLocalizedString('screen.buttons.delete')}
					isDeleteButton={true}
				/>
			)}
		</section>
	);
};
