import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LinkoRouteActionType } from 'src/redux/linko-route';
import { alertService } from 'src/redux';
import { dispatchAccessor, localizationService } from 'src/services';

type Props = {
	path?: string;
	message?: string;
};
class NotCompatibleComponent extends Component<Props, any> {
	componentDidUpdate() {
		dispatchAccessor.dispatch({ type: LinkoRouteActionType.restoreInitialState });
		alertService.clearAllMessages();
	}
	componentDidMount() {
		document.title = localizationService.getLocalizedString('screen.browserNotSupported.titleIE');
	}

	render() {
		return (
			<p
				className="browserupgrade"
				dangerouslySetInnerHTML={{
					__html: localizationService.getLocalizedString('screen.browserNotSupported.message')
				}}></p>
		);
	}
}

export const NotCompatible = connect()(NotCompatibleComponent);
