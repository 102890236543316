import React, { useContext, useEffect } from 'react';
import HeaderUser from './header-user';
import { HeaderFeedbackForm } from './head-feedform';
import { tokenService, urlService, localizationService, navigateTo } from 'src/services';
import { closeMainSidebar, triggerMainSidebarHamburgerClick } from '../triggerMainSidebarHamburgerClick';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useReduxSelector } from 'src/redux';
import { RegulatoryProgramName } from '@rcp/types';
import { FogHelpUrls, IppHelpUrls } from 'src/constants';
import { InputInvitationCodeModal } from 'src/components/service-provider/shared/portal-registration/enter-invitation-code-modal';
import { useDispatch } from 'react-redux';
import { Translate } from 'src/components/widgets/translate/translator';
import { LanguageSelectDropdown } from 'src/components/widgets/translate/language-select-dropdown';
import { LanguageContext } from 'src/components/widgets/translate/translator-context';
import { HelpLink } from './help-link';
import { translateService } from 'src/services/translate-service';

interface Props {
	program: string;
}

const Header: React.FC<Props> = props => {
	const dispatch = useDispatch();
	const { language, setLanguage } = useContext(LanguageContext);
	const userProfile = useReduxSelector(state => state.userProfile.userProfile);
	const [showInviteModal, setShowInputModal] = React.useState<boolean>(false);

	const hasToken = tokenService.isTokenValid();
	const isIppAllowed =
		String.equalCaseInsensitive(props.program, 'ipp') && _.includes(userProfile.issuedPrograms, 'ipp');
	const history = useHistory();
	const isLogonUserValid =
		hasToken &&
		((String.equalCaseInsensitive(props.program, 'fog') && _.includes(userProfile.issuedPrograms, 'fog')) ||
			(String.equalCaseInsensitive(props.program, 'backflow') &&
				_.includes(userProfile.issuedPrograms, 'backflow')) ||
			isIppAllowed ||
			urlService.isHaulerPortal() ||
			urlService.isFacilityPortal());

	const getProgramLogo = () => {
		return '/img/icons/aquatic-informatics.svg';
	};

	const redirectToHome = () => {
		if (urlService.isAccountPortal()) {
			navigateTo(history, urlService.getPortalDirectorUrl());
		} else {
			navigateTo(history, urlService.getReactPortalDefaultUrl(props.program));
		}
	};


	const headerRightNavMenus = (
		<>
			{isLogonUserValid && (
				<>
					{(urlService.isFacilityPortal() || urlService.isHaulerPortal()) &&
						!urlService.isHaulerInviteScreen() &&
						urlService.isAdministrator() && (
							<div id="header-invite-code">
								<div className="cursor-pointer" onClick={() => setShowInputModal(true)}>
									<img src="img/icons/invite-code.svg" alt="" />
									<br />
									<span className="header-title">
										<Translate>
											{localizationService.getLocalizedString('screen.labels.inviteCode')}
										</Translate>
									</span>
								</div>
							</div>
						)}
					{!(
						urlService.isIppIndustryPortal() ||
						urlService.isIppAuthorityPortal() ||
						!!urlService.isHaulerInviteScreen()
					) && (
						<div>
							<Link
								to={urlService.getSettingPath()}
								id="linkHeaderSettings"
								onClick={() => {
									if (window.matchMedia('(max-width: 900px)').matches) {
										closeMainSidebar();
									}
								}}>
								<img src="img/icons/settings.svg" alt="" />
								<br />
								<span className="header-title">
									<Translate>
										{localizationService.getLocalizedString('screen.labels.settings')}
									</Translate>
								</span>
							</Link>
						</div>
					)}
					<HeaderFeedbackForm />
					<div>
						<HelpLink />
					</div>
				</>
			)}
			{tokenService.getTokenOrDefault().userName && <HeaderUser program={props.program} />}
			{!urlService.isHaulerInviteScreen() && !urlService.isPortalDirectorPage() && (
				<div className="menu-button">
					<div className="cursor-pointer" onClick={triggerMainSidebarHamburgerClick}>
						<span></span> <span></span> <span></span>
						<span className="header-title">
							<Translate>{localizationService.getLocalizedString('screen.labels.menu')}</Translate>
						</span>
					</div>
				</div>
			)}
		</>
	);

	const getLanguageSelectionComponent = () => {
		return (
			<>
				<LanguageSelectDropdown />
			</>
		);
	};

	return (
		<>
			<header className="menu-toggle d-print-none ie-header">
				<nav role="navigation" aria-label="Secondary menu">
					<div className="logo">
						<img
							alt="Logo"
							height="35"
							src={getProgramLogo()}
							style={{
								cursor: 'pointer'
							}}
							onClick={() => {
								redirectToHome();
							}}
						/>
					</div>
					{getLanguageSelectionComponent()}
					{(tokenService.isTokenValid() && !urlService.isPublicScreen()) || urlService.isAccountScreen()
						? headerRightNavMenus
						: null}
				</nav>
			</header>
			{showInviteModal && (
				<InputInvitationCodeModal showModal={showInviteModal} onCancelButtonClicked={setShowInputModal} />
			)}
		</>
	);
};

export default Header;
