import React from 'react';
import { localizationService } from 'src/services';

interface Props {
	title?: string;
	size?: number;
}

export const PopupCloseSvg: React.SFC<Props> = props => {
	return (
		<svg
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ? props.size : '8'}
			height={props.size ? props.size : '8'}
			viewBox="0 0 8 8">
			<title>{props.title || localizationService.getLocalizedString('screen.buttons.closeCancel')}</title>
			<rect x="3.3" y="-1" width="1.4" height="10" rx="0.35" transform="translate(-1.65 4) rotate(-45)"></rect>
			<rect x="-1" y="3.3" width="10" height="1.4" rx="0.35" transform="translate(-1.66 4) rotate(-45)"></rect>
		</svg>
	);
};
